import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { Helper_Class } from '../../helper_class';
import { Date_Formate } from 'src/assets/js/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-diabeticpopuphistory',
  templateUrl: './diabeticpopuphistory.component.html',
  styleUrls: ['./diabeticpopuphistory.component.scss']
})
export class DiabeticpopuphistoryComponent implements OnInit {
  public Diab_case_hist_id;
  public Life_style_id;
  public medical_history_data = [];
  public medical_concdata;
  public main_concdata;
  public hsitory_pop: boolean;
  public habitual_history_data = [];
  public family_history_data = [];
  public foot_asses_arraydata = [];
  public complication_array_data = [];
  public pain_data=[];

  public cardio_hist_data;
  public neuro_hist_data;
  public nephro_hist_data;
  public retino_hist_data;
  public damage_hist_data;
  public skin_hist_data;
  public hear_hist_data;
  public mobility_hist_data;
  public History;
  public habitual_table: boolean;
  public family_table: boolean;
  public complicate_hide: boolean;
  public Footasses_ment: boolean;
  public client_reg_id;
  public relation_id;
  public sub_rel_id;
  public DietHist_Table_Data = [];
  public Diet_Hist_Btn: boolean;
  public Trackhist_Table_Data = [];
  public Vital_history_data = [];
  public head_history_array = [];
  public cardio_history_array = [];
  public Diet_Track_Btn: boolean;
  public nodataapp: boolean;
  public mens_history_data = [];
  public freq_txt;
  public cycle_txt;
  public stage_men;
  public mens_history_obstricdata = [];
  public last_datetxt;
  public pop_datetxt;
  public edd_data;
  public sexual_history_gynadata = [];
  public sexual_history_obostricdata = [];
  public systemic_hist_data = [];
  public ante_hist_data = [];
  public post_hist_data = [];
  public delivery_arry;
  public anemic_array;
  public del_txt;
  public pain_table:boolean;
  public personaldata;
  public personaldatavalue;
    public app_flow="";
    public doc_reg_id;
    public walkinHistoryList = [];

    constructor(public sanitizer:DomSanitizer,public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<DiabeticpopuphistoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            dialogRef.disableClose = true;
        this.History = data.History;
        if(this.History == "walkin"){
            this.client_reg_id = data.client_reg_id;
            this.relation_id = data.relation_id;
            this.sub_rel_id = data.sub_rel_id;
            this.doc_reg_id = data.doc_reg_id;
        }else{
            this.Diab_case_hist_id = data.Diab_case_hist_id;
            this.Life_style_id =  data.Life_style_id;
            this.History = data.History;
            this.delivery_arry =data.delivery_arry;
            this.anemic_array = data.anemic_array;
            this.app_flow = data.AppFlow;
        }
        
    }

    ngOnInit(): void {
        
        if(Helper_Class.getRet() != undefined && Helper_Class.getRet() != null){
            this.personaldata= Helper_Class.getRet();
        }
        if(this.app_flow == "client" ){
            this.personaldatavalue= Helper_Class.getInfo(); 
        }else{
            if(this.History != "walkin"){
                if(Doc_Helper.getClient_Info() != undefined &&  Doc_Helper.getClient_Info() != null){
                    this.personaldatavalue= Doc_Helper.getClient_Info();
                    this.client_reg_id = this.personaldatavalue.Client_id;
                    this.relation_id = this.personaldatavalue.rel_id;
                    this.sub_rel_id = this.personaldatavalue.sub_id;
                }
            }
        }

        this.pain_table = true;
        if (this.History == "concern_hist") {
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.foot_asses_arraydata = [];
            this.complication_array_data = [];
            this.DietHist_Table_Data = [];
            this.Trackhist_Table_Data = [];
            this.Vital_history_data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.cardio_history_array = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.DiabeticHistory_tabpop();

        } else if (this.History == "Alchol_hist") {
            this.Vital_history_data = [];
            this.medical_history_data = [];
            this.family_history_data = [];
            this.foot_asses_arraydata = [];
            this.complication_array_data = [];
            this.DietHist_Table_Data = [];
            this.pain_data=[];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.walkinHistoryList = [];
            this.Alchol_history();
        } else if (this.History == "Familyhist") {
            this.Vital_history_data = [];
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.foot_asses_arraydata = [];
            this.complication_array_data = [];
            this.DietHist_Table_Data = [];
            this.pain_data=[];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.walkinHistoryList = [];
            this.Get_Family_history();
        } else if (this.History == "pain_hist") {
            this.Vital_history_data = [];
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.foot_asses_arraydata = [];
            this.complication_array_data = [];
            this.DietHist_Table_Data = [];
            this.pain_data=[];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.walkinHistoryList = [];
            this.painHistory();
        } else if (this.History == "Dishist") {
            this.Vital_history_data = [];
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.foot_asses_arraydata = [];
            this.complication_array_data = [];
            this.DietHist_Table_Data = [];
            this.pain_data=[];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.walkinHistoryList = [];
            this.GetDiseaseHistory();
        }  else if (this.History == "Foot_asses") {
            this.Vital_history_data = [];
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.DietHist_Table_Data = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Foot_assesment_history();

        } else if (this.History == "Complcate_hist") {
            this.Vital_history_data = [];
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];

            this.foot_asses_arraydata = [];
            this.DietHist_Table_Data = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Complication_history();

        } else if (this.History == "Diet_planhist") {
            this.Vital_history_data = [];
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Diabetic_diet_plan();
        
        } else if (this.History == "Tracker_hist") {
            this.Vital_history_data = [];
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Diabetic_tracker_plan();

        } else if (this.History == "Vitalhist") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Pediatric_vital_history();

        } else if (this.History == "Headhist") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.cardio_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Pediatric_head_history();

        } else if (this.History == "Cardiohist") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Cardio_head_history();

        } else if (this.History == "gynaMenstrual") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.cardio_history_array = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Getgynacology_menstrual();

        } else if (this.History == "obestricmenstrual") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.cardio_history_array = [];
            this.sexual_history_gynadata = [];
            this.systemic_hist_data = [];
            this.sexual_history_obostricdata = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.obestric_menstrual();

        } else if (this.History == "gynasexual") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.cardio_history_array = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_obostricdata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Gynacology_sexual();

        } else if (this.History == "obestricsexual") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.cardio_history_array = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.systemic_hist_data = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Obestric_sexual();

        } else if (this.History == "Clinical_hist") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.cardio_history_array = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.ante_hist_data = [];
            this.post_hist_data = [];
            this.sexual_history_obostricdata = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.Clinical_systemic_history();

        } else if (this.History == "ante_natal") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.cardio_history_array = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.systemic_hist_data = [];
            this.sexual_history_obostricdata = [];
            this.post_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.antenatal_history();

        } else if (this.History == "post_natal") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.cardio_history_array = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.systemic_hist_data = [];
            this.sexual_history_obostricdata = [];
            this.ante_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.post_natalhistory();
        } else if (this.History == "walkin") {
            this.medical_history_data = [];
            this.habitual_history_data = [];
            this.family_history_data = [];
            this.complication_array_data = [];
            this.foot_asses_arraydata = [];
            this.Trackhist_Table_Data = [];
            this.head_history_array = [];
            this.mens_history_data = [];
            this.cardio_history_array = [];
            this.mens_history_obstricdata = [];
            this.sexual_history_gynadata = [];
            this.systemic_hist_data = [];
            this.sexual_history_obostricdata = [];
            this.ante_hist_data = [];
            this.pain_data=[];
            this.walkinHistoryList = [];
            this.walkinHistory();
        }
    }

    dismiss() {
        this.dialogRef.close();
    }

    Alchol_history() {
    this.hsitory_pop = true;
    this.family_table = true;
    this.Footasses_ment = true;
    this.complicate_hide = true;
    this.Diet_Hist_Btn = true;
    this.Diet_Track_Btn = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/agshist/',
        JSON.stringify({
            life_style_id: this.Life_style_id,
        }),
        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.habitual_history != null) {
                    for (var i = 0; i < obj.habitual_history.length; i++) {
                        if (obj.habitual_history[i].created_date != null) {
                            this.habitual_history_data.push({
                                alc_frequency: obj.habitual_history[i].alc_frequency,
                                alc_qty: obj.habitual_history[i].alc_qty,
                                smoke_frequency: obj.habitual_history[i].smoke_frequency,
                                gutka_frequency: obj.habitual_history[i].gutka_frequency,
                                curr_exer_dur: obj.habitual_history[i].curr_exer_dur,
                                created_date: obj.habitual_history[i].created_date,
                            });

                            if (this.habitual_history_data.length == 0) {
                                this.nodataapp = false;

                            } else {
                                this.nodataapp = true;
                            }
                        }
                    }

                } else {
                    this.habitual_table = true;
                }
            },
            error => {
            }
        )
    }

    //Family history
    Get_Family_history() {
    this.hsitory_pop = true;
    this.habitual_table = true;
    this.Footasses_ment = true;
    this.complicate_hide = true;
    this.Diet_Hist_Btn = true;
    this.Diet_Track_Btn = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/histfam/',
        JSON.stringify({
            life_style_id: this.Life_style_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.family_history != null) {
                    for (var i = 0; i < obj.family_history.length; i++) {
                        if (obj.family_history[i].created_date != null) {
                            this.family_history_data.push({
                                Diabetics: obj.family_history[i].Diabetics,
                                heart_attack: obj.family_history[i].heart_attack,
                                endocrine: obj.family_history[i].endocrine,
                                hypertension: obj.family_history[i].hypertension,
                                hepatitis: obj.family_history[i].hepatitis,
                                hiv: obj.family_history[i].hiv,
                                others: obj.family_history[i].others,
                                created_date: obj.family_history[i].created_date,
                            });
                        }
                        if (this.family_history_data.length == 0) {
                            this.nodataapp = false;
                        }
                        else {
                            this.nodataapp = true;
                        }
                    }
                }
            },
            error => {

            }
        )
    }

    //Disease history
    GetDiseaseHistory() {
    this.hsitory_pop = true;
    this.habitual_table = true;
    this.Footasses_ment = true;
    this.complicate_hide = true;
    this.Diet_Hist_Btn = true;
    this.Diet_Track_Btn = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/dishist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.disease_history != null) {
                    for (var i = 0; i < obj.disease_history.length; i++) {
                        if (obj.disease_history[i].created_date != null) {
                            this.family_history_data.push({
                                Diabetics: obj.disease_history[i].Diabetics,
                                heart_attack: obj.disease_history[i].heart_attack,
                                endocrine: obj.disease_history[i].endocrine,
                                hypertension: obj.disease_history[i].hypertension,
                                hepatitis: obj.disease_history[i].hepatitis,
                                hiv: obj.disease_history[i].hiv,
                                others: obj.disease_history[i].others,
                                created_date: obj.disease_history[i].created_date,
                            });
                        }

                        if (this.family_history_data.length == 0) {
                            this.nodataapp = false;
                        }
                        else {
                            this.nodataapp = true;
                        }
                    }
                }
            },
            error => {

            }
        )
    }

    //pain history
    painHistory() {
    this.hsitory_pop = true;
    this.habitual_table = true;
    this.Footasses_ment = true;
    this.complicate_hide = true;
    this.Diet_Hist_Btn = true;
    this.Diet_Track_Btn = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/painhist/',
    JSON.stringify({
        case_hist_id: this.Diab_case_hist_id,
    }),

    { headers: headers })
    .subscribe(
        response => {
            var obj = response.json();
            if (obj.pain_history != null && obj.pain_history !=undefined) {
                for (var i = 0; i < obj.pain_history.length; i++) {
                    if (obj.pain_history[i].created_date != null) {
                        this.pain_table=false;
                        this.pain_data.push({
                        pain: obj.pain_history[i].pain,
                        location: obj.pain_history[i].location,
                        radiation: obj.pain_history[i].radiation,
                        character: obj.pain_history[i].character,
                        aggrieved: obj.pain_history[i].aggr_factor,
                        created_date: obj.pain_history[i].created_date,
                        });
                        if (this.pain_data.length == 0) {
                            this.nodataapp = false;
                        
                        } else {
                            this.nodataapp = true;
                        }
                    }
                }
                
            } else {
                this.pain_table=true;
                this.nodataapp = false;
            }
        },
        error => {
            
        }
    )
    }

    //Foot assesment
    Foot_assesment_history() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diab/histfoot/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id
        }),

        { headers: headers })
        .subscribe(
            response => {
                

                var obj = response.json();

                if (obj.foot_history != null) {
                    for (var i = 0; i < obj.foot_history.length; i++) {
                        if (obj.foot_history[i].created_date != null) {
                            this.foot_asses_arraydata.push({
                                foot_type: obj.foot_history[i].foot_type,
                                limb_Tibia: obj.foot_history[i].limb_Tibia,
                                limb_pedis: obj.foot_history[i].limb_pedis,
                                limb_numbness: obj.foot_history[i].limb_numbness,
                                deformity: obj.foot_history[i].deformity,
                                skin_dry: obj.foot_history[i].skin_dry,
                                risk_level: obj.foot_history[i].risk_level,
                                created_date: obj.foot_history[i].created_date,
                            });

                            if (this.foot_asses_arraydata.length == 0) {
                                this.nodataapp = false;

                            } else {
                                this.nodataapp = true;
                            }
                        }
                    }
                }
            },
            error => {

            }
        )
    }

    //Complication history
    Complication_history() {
    this.habitual_table = true;
    this.hsitory_pop = true;
    this.family_table = true;

    this.Footasses_ment = true;
    this.Diet_Hist_Btn = true;
    this.Diet_Track_Btn = true;
    function StringBuilder(this:any,value) {
        this.strings = new Array();
        this.append(value);
    }

    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }

    StringBuilder.prototype.clear = function () {
        this.strings.length = 0;
    }

    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diab/comphist/',
        JSON.stringify({
            life_style_id: this.Life_style_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.complication_history != null) {
                    for (var i = 0; i < obj.complication_history.length; i++) {
                        if (obj.complication_history[i].created_date != null) {
                            var cardio = new StringBuilder("");
                            if (obj.complication_history[i].cardio_dis != null) {
                                var card_split = obj.complication_history[i].cardio_dis.split('|');
                                var n = 0;
                                for (var j = 0; j < card_split.length; j++) {
                                    if (card_split[j] != "") {
                                        n = n + 1;
                                        if (n > 1) {
                                            cardio.append(", ");
                                        }
                                        cardio.append(card_split[j]);
                                    }
                                }
                                this.cardio_hist_data = cardio.toString();
                            }

                            var neuro = new StringBuilder("");

                            if (obj.complication_history[i].neuro_dis != null) {
                                var neuro_split = obj.complication_history[i].neuro_dis.split('|');
                                var n = 0;
                                for (var j = 0; j < neuro_split.length; j++) {
                                    if (neuro_split[j] != "") {
                                        n = n + 1;
                                        if (n > 1) {
                                            neuro.append(", ");
                                        }
                                        neuro.append(neuro_split[j]);
                                    }
                                }
                                this.neuro_hist_data = neuro.toString();
                            }

                            var nephro = new StringBuilder("");

                            if (obj.complication_history[i].nephro_dis != null) {
                                var neph_split = obj.complication_history[i].nephro_dis.split('|');
                                var n = 0;
                                for (var j = 0; j < neph_split.length; j++) {
                                    if (neph_split[j] != "") {
                                        n = n + 1;
                                        if (n > 1) {
                                            nephro.append(", ");
                                        }
                                        nephro.append(neph_split[j]);
                                    }
                                }
                                this.nephro_hist_data = nephro.toString();
                            }

                            var retino = new StringBuilder("");

                            if (obj.complication_history[i].retino_dis != null) {
                                var retino_split = obj.complication_history[i].retino_dis.split('|');
                                var n = 0;
                                for (var j = 0; j < retino_split.length; j++) {
                                    if (retino_split[j] != "") {
                                        n = n + 1;
                                        if (n > 1) {
                                            retino.append(", ");
                                        }
                                        retino.append(retino_split[j]);
                                    }
                                }
                                this.retino_hist_data = retino.toString();
                            }

                            var footdamage = new StringBuilder("");

                            if (obj.complication_history[i].foot_dis != null) {
                                var damage_split = obj.complication_history[i].foot_dis.split('|');
                                var n = 0;

                                for (var j = 0; j < damage_split.length; j++) {
                                    if (damage_split[j] != "") {
                                        n = n + 1;
                                        if (n > 1) {
                                            footdamage.append(", ");
                                        }
                                        footdamage.append(damage_split[j]);
                                    }
                                }
                                this.damage_hist_data = footdamage.toString();
                            }

                            var skinstring = new StringBuilder("");
                            if (obj.complication_history[i].skin_dis != null) {
                                var skin_split = obj.complication_history[i].skin_dis.split('|');
                                var n = 0;
                                for (var j = 0; j < skin_split.length; j++) {
                                    if (skin_split[j] != "") {
                                        n = n + 1;
                                        if (n > 1) {
                                            skinstring.append(", ");
                                        }
                                        skinstring.append(skin_split[j]);
                                    }
                                }
                                this.skin_hist_data = skinstring.toString();
                            }

                            var hearstring = new StringBuilder("");
                            if (obj.complication_history[i].hearing_dis != null) {
                                var hear_split = obj.complication_history[i].hearing_dis.split('|');
                                var n = 0;
                                for (var j = 0; j < hear_split.length; j++) {
                                    if (hear_split[j] != "") {
                                        n = n + 1;
                                        if (n > 1) {
                                            hearstring.append(", ");
                                        }
                                        hearstring.append(hear_split[j]);
                                    }
                                }
                                this.hear_hist_data = hearstring.toString();
                            }

                            var mobstring = new StringBuilder("");
                            if (obj.complication_history[i].mobility_dis != null) {
                                var mob_split = obj.complication_history[i].mobility_dis.split('|');
                                var n = 0;
                                for (var j = 0; j < mob_split.length; j++) {
                                    n = n + 1;
                                    if (n > 1) {
                                        if (mob_split[j] != "") {
                                            mobstring.append(", ");
                                        }
                                    }
                                    mobstring.append(mob_split[j]);
                                }
                                this.mobility_hist_data = mobstring.toString();
                            }

                            this.complication_array_data.push({
                                cardio_dis: this.cardio_hist_data,
                                neuro_dis: this.neuro_hist_data,
                                nephro_dis: this.nephro_hist_data,
                                retino_dis: this.retino_hist_data,
                                foot_dis: this.damage_hist_data,
                                skin_dis: this.skin_hist_data,
                                hearing_dis: this.hear_hist_data,
                                mobility_dis: this.mobility_hist_data,
                                created_date: obj.complication_history[i].created_date,
                            });

                            if (this.complication_array_data.length == 0) {
                                this.nodataapp = false;

                            } else {
                                this.nodataapp = true;
                            }
                        }
                    }
                }
            },
            error => {

            }
        )
    }

    //Main conern history
    DiabeticHistory_tabpop() {
    function StringBuilder(this:any,value) {
        this.strings = new Array();
        this.append(value);
    }

    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }

    StringBuilder.prototype.clear = function () {
        this.strings.length = 0;
    }

    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }

    var send = JSON.stringify({
        life_style_id: this.Life_style_id,
        case_hist_id: this.Diab_case_hist_id
    })
    

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diab/histper/',
        JSON.stringify({
            life_style_id: this.Life_style_id,
            case_hist_id: this.Diab_case_hist_id
        }),

        { headers: headers })
        .subscribe(
            response => {
                var obj = response.json();
                
                if (obj.personal_history != null) {
                    for (var i = 0; i < obj.personal_history.length; i++) {
                        if (obj.personal_history[i].created_date != null) {
                            var maincon = new StringBuilder("");

                            for (var j = 0; j < obj.personal_history[i].main_concerns.length; j++) {
                                if (j == 0) {
                                    maincon.append(obj.personal_history[i].main_concerns[j]);

                                } else {
                                    maincon.append(',');
                                    maincon.append(obj.personal_history[i].main_concerns[j]);
                                }
                            }
                            this.main_concdata = maincon.toString();
                            var medical_hist = new StringBuilder("");

                            for (var j = 0; j < obj.personal_history[i].medical_history.length; j++) {
                                if (j == 0) {
                                    medical_hist.append(obj.personal_history[i].medical_history[j]);

                                } else {
                                    medical_hist.append(',');
                                    medical_hist.append(obj.personal_history[i].medical_history[j]);
                                }
                            }
                            this.medical_concdata = medical_hist.toString();

                            this.medical_history_data.push({
                                current_occupation: obj.personal_history[i].current_occupation,
                                bmi: obj.personal_history[i].bmi,
                                stress_factor: obj.personal_history[i].stress_factor,
                                diet_habits: obj.personal_history[i].diet_habits,
                                created_date: obj.personal_history[i].created_date,
                                created_time: obj.personal_history[i].created_time,
                                main_concerns: this.main_concdata,
                                medical_history: this.medical_concdata
                            });

                            if (this.medical_history_data.length == 0) {
                                this.nodataapp = false;

                            } else {
                                this.nodataapp = true;
                            }

                        } else {
                            this.hsitory_pop = true;
                        }
                    }
                }
            },
            error => {

            }
        )
    }

    //Diabetic diet plan history
    Diabetic_diet_plan() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/dphist',
        JSON.stringify({
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            sub_rel_id: this.sub_rel_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                

                var obj = response.json();

                for (var i = 0; i < obj.diet_plan_hist.length; i++) {
                    this.DietHist_Table_Data.push({
                        break_fast: obj.diet_plan_hist[i].break_fast,
                        morning: obj.diet_plan_hist[i].morning,
                        midday: obj.diet_plan_hist[i].midday,
                        lunch: obj.diet_plan_hist[i].lunch,
                        evening: obj.diet_plan_hist[i].evening,
                        dinner: obj.diet_plan_hist[i].dinner,
                        night: obj.diet_plan_hist[i].night,
                        created_date: obj.diet_plan_hist[i].created_date,
                    });

                    if (this.DietHist_Table_Data.length == 0) {
                        this.nodataapp = false;

                    } else {
                        this.nodataapp = true;
                    }
                }
            },
            error => {

            }
        )
    }

    //TRacker history
    Diabetic_tracker_plan() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/dpthist',
        JSON.stringify({
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            sub_rel_id: this.sub_rel_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                

                var obj = response.json();

                for (var i = 0; i < obj.diet_plan_track_hist.length; i++) {
                    this.Trackhist_Table_Data.push({
                        break_fast: obj.diet_plan_track_hist[i].break_fast,
                        morning: obj.diet_plan_track_hist[i].morning,
                        midday: obj.diet_plan_track_hist[i].midday,
                        lunch: obj.diet_plan_track_hist[i].lunch,
                        evening: obj.diet_plan_track_hist[i].evening,
                        dinner: obj.diet_plan_track_hist[i].dinner,
                        night: obj.diet_plan_track_hist[i].night,
                        created_date: obj.diet_plan_track_hist[i].created_date,
                    });

                    if (this.Trackhist_Table_Data.length == 0) {
                        this.nodataapp = false;

                    } else {
                        this.nodataapp = true;
                    }
                }
            },
            error => {

            }
        )
    }

    //Vital history
    Pediatric_vital_history() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pedia/vhist',
        JSON.stringify({
            life_style_id: this.Life_style_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                

                var obj = response.json();
                for (var i = 0; i < obj.vital_history.length; i++) {
                    this.Vital_history_data.push({
                        blood_pressure: obj.vital_history[i].blood_pressure,
                        respiratory_rate: obj.vital_history[i].respiratory_rate,
                        created_date: obj.vital_history[i].vital_created_date,
                    });
                }

                if (this.Vital_history_data.length == 0) {
                    this.nodataapp = false;

                } else {
                    this.nodataapp = true;
                }
            },
            error => {

            }
        )
    }

    //Pediatric head history
    Pediatric_head_history() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pedia/hhmvnh/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.head_history != null) {
                    for (var i = 0; i < obj.head_history.length; i++) {
                        if (obj.head_history[i].head != null || obj.head_history[i].vision != null ||
                            obj.head_history[i].hearing != null || obj.head_history[i].nose != null ||
                            obj.head_history[i].mouth_throat != null || obj.head_history[i].head_circum != null) {

                            this.head_history_array.push({
                                head: obj.head_history[i].head,
                                vision: obj.head_history[i].vision,
                                hearing: obj.head_history[i].hearing,
                                nose: obj.head_history[i].nose,
                                mouth_throat: obj.head_history[i].mouth_throat,
                                head_circum: obj.head_history[i].head_circum,
                                created_date: obj.head_history[i].created_date,
                            });
                        }
                    }

                    if (this.head_history_array.length == 0) {
                        this.nodataapp = false;

                    } else {
                        this.nodataapp = true;
                    }
                }
            },
            error => {

            }
        )
    }

    //Cardio history
    Cardio_head_history() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pedia/cvaschist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.cardvasco_history != null) {
                    for (var i = 0; i < obj.cardvasco_history.length; i++) {
                        if (obj.cardvasco_history[i].chest != null || obj.cardvasco_history[i].admonial != null ||
                            obj.cardvasco_history[i].genitalia != null || obj.cardvasco_history[i].extermities != null ||
                            obj.cardvasco_history[i].neurological != null || obj.cardvasco_history[i].geneal_appearance != null ||
                            obj.cardvasco_history[i].skin != null) {

                            this.cardio_history_array.push({
                                chest: obj.cardvasco_history[i].chest,
                                admonial: obj.cardvasco_history[i].admonial,
                                genitalia: obj.cardvasco_history[i].genitalia,
                                extermities: obj.cardvasco_history[i].extermities,
                                neurological: obj.cardvasco_history[i].neurological,
                                geneal_appearance: obj.cardvasco_history[i].geneal_appearance,
                                skin: obj.cardvasco_history[i].skin,
                                created_date: obj.cardvasco_history[i].created_date,
                            });
                        }
                    }

                    if (this.cardio_history_array.length == 0) {
                        this.nodataapp = false;

                    } else {
                        this.nodataapp = true;
                    }
                }
            },
            error => {

            }
        )
    }

    // gynacology menstrual
    Getgynacology_menstrual() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/gmhist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            flag: "gynaecology"
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.mens_hist != null) {
                    for (var i = 0; i < obj.mens_hist.length; i++) {
                        if (obj.mens_hist[i].created_date != null) {
                            if (obj.mens_hist[i].cycle_length != undefined) {
                                var cycle_data = obj.mens_hist[i].cycle_length.split(' ');
                                this.freq_txt = cycle_data[0];
                                this.cycle_txt = cycle_data[1];
                            }

                            if (obj.mens_hist[i].stage_date != undefined) {
                                this.stage_men = obj.mens_hist[i].stage_date;
                            }

                            this.mens_history_data.push({
                                mens_stage: obj.mens_hist[i].mens_stage,
                                stage_date: this.stage_men,
                                periods: obj.mens_hist[i].periods,
                                last_mens_period: obj.mens_hist[i].last_mens_period,
                                cycle_length: this.cycle_txt,
                                frequency: this.freq_txt,
                                mens_pain: obj.mens_hist[i].mens_pain,
                                pain_timing: obj.mens_hist[i].pain_timing,
                                menorragia: obj.mens_hist[i].menorragia,
                                created_date: obj.mens_hist[i].created_date,
                            });
                        }
                    }
                }
                
                if (this.mens_history_data.length == 0) {
                    this.nodataapp = false;

                } else {
                    this.nodataapp = true;
                }
            },
            error => {

            }
        )
    }

    //obestric menstrual
    obestric_menstrual() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/gmhist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            flag: "obstetrics"
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.mens_hist != null) {
                    for (var i = 0; i < obj.mens_hist.length; i++) {
                        if (obj.mens_hist[i].created_date != null) {
                            var cycle_data = obj.mens_hist[i].cycle_length.split(' ');
                            this.freq_txt = cycle_data[0];
                            this.cycle_txt = cycle_data[1];

                            var last_date = obj.mens_hist[i].last_mens_period.split('-');

                            this.last_datetxt = last_date[2] + "-" + last_date[1] + "-" + last_date[0];

                            if (obj.mens_hist[i].pap_smear_date != null) {
                                var pop_date = obj.mens_hist[i].pap_smear_date.split('-');
                                this.pop_datetxt = pop_date[2] + "-" + pop_date[1] + "-" + pop_date[0];
                            }

                            if (obj.mens_hist[i].edd != null) {
                                this.edd_data = obj.mens_hist[i].edd;
                            }

                            this.mens_history_obstricdata.push({
                                periods: obj.mens_hist[i].periods,
                                last_mens_period: this.last_datetxt,
                                cycle_length: this.cycle_txt,
                                frequency: this.freq_txt,
                                edd: this.edd_data,
                                trimester: obj.mens_hist[i].trimester,
                                pap_smear_date: this.pop_datetxt,
                                pap_smear_result: obj.mens_hist[i].pap_smear_result,
                                created_date: obj.mens_hist[i].created_date,
                            });
                        }
                    }
                }
                if (this.mens_history_obstricdata.length == 0) {
                    this.nodataapp = false;

                } else {
                    this.nodataapp = true;
                }
            },
            error => {

            }
        )
    }

    //sexual for gynacology
    Gynacology_sexual() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/gshist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            flag: "gynaecology"
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.sexual_hist != null) {
                    for (var i = 0; i < obj.sexual_hist.length; i++) {
                        if (obj.sexual_hist[i].created_date != null) {
                            this.sexual_history_gynadata.push({
                                status: obj.sexual_hist[i].status,
                                age_sex_active: obj.sexual_hist[i].age_sex_active,
                                sex_type: obj.sexual_hist[i].sex_type,

                                difficulties: obj.sexual_hist[i].difficulties,
                                pain_type: obj.sexual_hist[i].pain_type,
                                infections: obj.sexual_hist[i].infections,
                                created_date: obj.sexual_hist[i].created_date,
                            });
                        }
                    }
                }

                if (this.sexual_history_gynadata.length == 0) {

                    this.nodataapp = false;
                } else {
                    this.nodataapp = true;
                }
            },
            error => {

            }
        )
    }

    //sexual for gynacology
    Obestric_sexual() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/gshist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            flag: "obstetrics"
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.sexual_hist != null) {
                    for (var i = 0; i < obj.sexual_hist.length; i++) {
                        if (obj.sexual_hist[i].created_date != null) {
                            this.sexual_history_obostricdata.push({
                                status: obj.sexual_hist[i].status,
                                age_sex_active: obj.sexual_hist[i].age_sex_active,
                                sex_type: obj.sexual_hist[i].sex_type,
                                difficulties: obj.sexual_hist[i].difficulties,
                                pain_type: obj.sexual_hist[i].pain_type,
                                infections: obj.sexual_hist[i].infections,
                                created_date: obj.sexual_hist[i].created_date,
                            });
                        }
                    }
                }

                if (this.sexual_history_obostricdata.length == 0) {
                    this.nodataapp = false;

                } else {
                    this.nodataapp = true;
                }
            },
            error => {

            }
        )
    }

    Clinical_systemic_history() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/gcshist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,

        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.systemic_hist != null) {
                    for (var i = 0; i < obj.systemic_hist.length; i++) {
                        if (obj.systemic_hist[i].created_date != null) {
                            this.systemic_hist_data.push({
                                skin: obj.systemic_hist[i].skin,
                                mouth: obj.systemic_hist[i].mouth,
                                nails: obj.systemic_hist[i].nails,
                                heart_rhythm: obj.systemic_hist[i].heart_rhythm,
                                anaemia: obj.systemic_hist[i].anaemia_desc,
                                mood_swing: obj.systemic_hist[i].mood_swing,
                                created_date: obj.systemic_hist[i].created_date,
                            });
                        }
                    }
                }

                if (this.systemic_hist_data.length == 0) {
                    this.nodataapp = false;

                } else {
                    this.nodataapp = true;
                }
            },
            error => {

            }
        )
    }

    //Antenatal history
    antenatal_history() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/gahist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();
                
                if (obj.antenatal_hist != null) {
                    for (var i = 0; i < obj.antenatal_hist.length; i++) {
                        if (this.delivery_arry != undefined) {
                            for (var j = 0; j < this.delivery_arry.length; j++) {
                                if (this.delivery_arry[j].gestation_age_id = obj.antenatal_hist[i].delivery_term) {
                                    this.del_txt = this.delivery_arry[j].description
                                }
                            }
                        }

                        if (obj.antenatal_hist[i].created_date != null) {
                            this.ante_hist_data.push({
                                fetal_movement: obj.antenatal_hist[i].fetal_movement,
                                fetus_growth: obj.antenatal_hist[i].fetus_growth,
                                fetus_lie: obj.antenatal_hist[i].fetus_lie,
                                delivery_term: this.del_txt,
                                created_date: obj.antenatal_hist[i].created_date,
                            });
                        }
                    }
                }

                if (this.ante_hist_data.length == 0) {
                    this.nodataapp = false;

                } else {
                    this.nodataapp = true;
                }
            },
            error => {

            }
        )
    }

    //post natal history
    post_natalhistory() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/gphist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
        }),

        { headers: headers })
        .subscribe(
            response => {
                
                var obj = response.json();

                if (obj.postnatal_hist != null) {
                    for (var i = 0; i < obj.postnatal_hist.length; i++) {
                        if (obj.postnatal_hist[i].created_date != null) {
                            this.post_hist_data.push({
                                anaemic: obj.postnatal_hist[i].anaemia_desc,
                                mood: obj.postnatal_hist[i].mood,
                                puerperium: obj.postnatal_hist[i].puerperium,
                                uterine_evolution: obj.postnatal_hist[i].uterine_evolution,
                                created_date: obj.postnatal_hist[i].created_date,
                            });
                        }
                    }
                }

                if (this.post_hist_data.length == 0) {
                    this.nodataapp = false;

                } else {
                    this.nodataapp = true;
                }
            },
            error => {

            }
        )
    }

    //walkin
    walkinHistory(){
        var send_data;
        if(this.sub_rel_id != "" && this.sub_rel_id.length != 0){
            send_data = JSON.stringify({
                doc_reg_id: this.doc_reg_id,
                client_reg_id: this.client_reg_id,
                relation_id:this.relation_id,
                sub_rel_id:this.sub_rel_id,
            });
        }else{
            send_data = JSON.stringify({
                doc_reg_id: this.doc_reg_id,
                client_reg_id: this.client_reg_id,
                relation_id:this.relation_id,
            });
        }
        
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'appointment/wooh',send_data,   
            { headers: headers })
            .subscribe(
                response => {
                    
                    var obj = response.json();
                    if(obj.history != null && obj.history != undefined){
                        for(var i = 0; i<obj.history.length; i++){
                            var complaints,pres_illness,medical_history,physical_exam,appdate;
                            if(obj.history[i].app_date !=undefined && obj.history[i].app_date !="" && obj.history[i].app_date !=null){
                               appdate = Date_Formate(obj.history[i].app_date)
                            }
                            if(obj.history[i].compaints != undefined && obj.history[i].compaints != "" && obj.history[i].compaints != null){
                                complaints = this.sanitizer.bypassSecurityTrustHtml(obj.history[i].compaints)
                            }
                            if(obj.history[i].pres_illness != undefined && obj.history[i].pres_illness != "" && obj.history[i].pres_illness != null){
                                pres_illness = this.sanitizer.bypassSecurityTrustHtml(obj.history[i].pres_illness)
                            }
                            if(obj.history[i].medical_history != undefined && obj.history[i].medical_history != "" && obj.history[i].medical_history != null){
                                medical_history = this.sanitizer.bypassSecurityTrustHtml(obj.history[i].medical_history)
                            }
                            if(obj.history[i].physical_exam != undefined && obj.history[i].physical_exam != "" && obj.history[i].physical_exam != null){
                                physical_exam = this.sanitizer.bypassSecurityTrustHtml(obj.history[i].physical_exam)
                            }
                            this.walkinHistoryList.push({
                                app_date: appdate,
                                complaints: complaints,
                                pres_illness: pres_illness,
                                medical_history: medical_history,
                                physical_exam: physical_exam,
                            });
                        }
                    }
                    if (this.walkinHistoryList.length == 0) {
                        this.nodataapp = false;
    
                    } else {
                        this.nodataapp = true;
                    }

                },
                error => {
    
                }
            )
    }
}
