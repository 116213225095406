import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pharma-consolidate-bill',
  templateUrl: './pharma-consolidate-bill.component.html',
  styleUrls: ['./pharma-consolidate-bill.component.scss']
})
export class PharmaConsolidateBillComponent implements OnInit {
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printheader') printheader: ElementRef;

  public Filter_txt;
  public From_show: boolean = true;
  public To_show: boolean = true;
  public f_date;
  public t_date;
  public userinfo;
  public appoint_txt;
  public hptl_clinic_id;
  public appointment_list:any = [];
  public currentDate;
  public CurrentDatetime;
  public Appoint_Date;
  public bill_date;
  public Docidname;
  public hospital_name;
  public hospitalAddress;
  public Hospital_location: string;
  public hosp_location:string;
  public hosp_district:string;
  public hosp_state:string;
  public hosp_zip:string;
  public hosp_telephone:string;
  public bill_type;
  public medBills = [];
  public DisplayBills = [];
  public final_amount = "0.00";
  public Advance_bill = "0.00";
  public total_advance = "0.00";
  public balance_show: boolean = true;
  public paid_bill = "0.00";
  public remaing_billbal = "0.00";
  public sendPharmacy:any = [];
  public pharma_id;
  public diag_centre_id;
  public tempPaid = "0.00";
  public totalBillAmount = "0.00";
  public totalPaidAmount = "0.00";
  public TotalBalance = "0.00";
  public selectedAllFlage: boolean = false;
  public patient_name;
  public amount_collected;
  public previous_balshow;
  public balance_data;
  public dog_reg_id;
  public total_collected;
  public billing_print_array:any = [];
  public insuranceFlag: boolean = true;
  public insuranceDec;
  public hospital_logo;
  public mediBillAmount = "0.00";
  public client_id;
  public sub_id;
  public rel_id;
  public tempparray;
  public saveFlag: boolean = true;
  public totalDiscount = "0.00";
  public totalBill = "0.00";
  public totalPaid = "0.00";
  public patientList = []
  public admissionDate = "";
  public admissionTime = "";
  public dischargeDate = "";
  public dischargeTime = "";
  public app_id;
  public inpid;
  public insurer;
  public insCardNo;
  public policyno;
  public CCNno;
  public round_off;
  public print_template;
  public headerstyle;
  public paytypreq;
  public docBillDetails:any=[];
  public medcalDetailsArray:any=[];
  public digDetailsArray:any=[];
  public printlogostyle;
  public bannerimage;

  constructor(public sanitize:DomSanitizer,public doctorservice: MenuViewService, 
    public toastr: ToastrService, public http: Http, public router: Router, 
    public messageservice: MenuViewService) { }

  async ngOnInit() {
    this.userinfo = Helper_Class.getInfo();
    if(this.userinfo.user_type =="front-desk"){
      this.print_template= this.userinfo.bill_print_template;
      this.printlogostyle=Helper_Class.getInfo().bill_print_logo_style;
      this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    } else {
      this.print_template= this.userinfo.hospitals[0].bill_print_template;
      this.printlogostyle=Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
      this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_print_logo;
    }
    
    this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().hospitals[0].bill_print_heading);
    if(this.headerstyle == undefined || this.headerstyle == 'undefined'){
      this.headerstyle="";
    }

    if(this.userinfo.hospitals[0].bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }

    this.hptl_clinic_id = this.userinfo.hosp_id;
    this.bill_type = "pharma";
    this.Filter_txt = "All";
    this.Filter_change();
    this.Get_current_date();
  }

  Filter_change() {
    if (this.Filter_txt == "All") {
      this.From_show = true;
      this.To_show = true;
    } else if (this.Filter_txt == "Date") {
      this.From_show = false;
      this.To_show = false;
    }
  }

  Get_current_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.Appoint_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            this.currentDate = obj.current_date;
            this.bill_date = Date_Formate(obj.current_date);
            this.Appoint_Date = this.CurrentDatetime;
            this.f_date = obj.current_date;
            this.t_date = obj.current_date;
          }
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  OnDateChangedfrom(e) {
    this.f_date = e;
  }

  OnDateChangedto(e) {
    this.t_date = e;
  }

  async filter_click() {
    this.Appointment_change(this.client_id, this.rel_id, this.sub_id, this.tempparray)
  }

  getAppointments(patientList) {
    this.appointment_list = [];
    this.patientList = [];
    if (patientList.target.value.toString() != null && patientList.target.value.toString().length > 2) {
      var headers = new Headers();
      var name = patientList.target.value.toUpperCase();
      var send_data = JSON.stringify({
        hptl_clinic_id: this.hptl_clinic_id,
        first_name: name
      });
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/inpa', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.appointment != null) {
              for (var i = 0; i < obj.appointment.length; i++) {
                obj.appointment[i].middle_name
                if (obj.appointment[i].middle_name != undefined) {
                  this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";

                } else {
                  this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                }

                var sessiondata = null;
                if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                  sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
                }
                var address;
                if (obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                  address = encrypt_decript.Decript(obj.appointment[i].address1) + ", " + encrypt_decript.Decript(obj.appointment[i].address2)
                } else if (obj.appointment[i].address1 != undefined) {
                  address = encrypt_decript.Decript(obj.appointment[i].address1);
                } else {
                  address = "";
                }
                var hospital;
                if (obj.hosp_address2 != undefined) {
                  hospital = obj.hosp_address1 + "," + obj.hosp_address2;

                } else {
                  hospital = obj.hosp_address1;
                }
                var inpatientId = obj.appointment[i].inp_hosp_id != undefined ? obj.appointment[i].inp_hosp_id : "-";
                var zipcode = obj.appointment[i].zipcode != undefined ? encrypt_decript.Decript(obj.appointment[i].zipcode) : "";
                this.hospital_name = obj.hosp_name != undefined ? obj.hosp_name : "";
                this.hospitalAddress = hospital;
                this.hosp_location=obj.hosp_location;
                this.hosp_district = obj.hosp_district;
                this.hosp_state = obj.hosp_state;
                this.hosp_zip = obj.hosp_zip;
                this.hosp_telephone = obj.hosp_telephone;
                this.Hospital_location = obj.hosp_location + ", " + obj.hosp_district + " - " + obj.hosp_state + " " + obj.hosp_country + "  " + obj.hosp_zip;

                this.appointment_list.push({
                  doc_app_id: obj.appointment[i].doc_app_id,
                  doc_reg_id: obj.appointment[i].doc_reg_id,
                  client_reg_id: obj.appointment[i].client_reg_id,
                  relation_id: obj.appointment[i].relation_id,
                  sub_rel_id: obj.appointment[i].sub_rel_id,
                  address: address,
                  location: obj.appointment[i].location,
                  district: obj.appointment[i].district,
                  state: obj.appointment[i].state,
                  country: obj.appointment[i].country,
                  first_name: obj.appointment[i].first_name,
                  middle_name: obj.appointment[i].middle_name,
                  last_name: obj.appointment[i].last_name,
                  hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                  session: sessiondata,
                  city: obj.appointment[i].district,
                  zipcode: zipcode,
                  docid_name: this.Docidname,
                  advance: obj.appointment[i].advance,
                  balance: obj.appointment[i].balance,
                  inpatientId: inpatientId,
                  cgst: "12%",
                  sgst: "12$",
                  admitted_date: obj.appointment[i].admitted_date,
                  admitted_time: obj.appointment[i].admitted_time,
                  discharge_date: obj.appointment[i].discharge_date,
                  discharge_time: obj.appointment[i].discharge_time,
                  inpid: obj.appointment[i].inp_hosp_id,
                  insurer: obj.appointment[i].insurer,
                  insCardNo: obj.appointment[i].ins_card_no,
                  policyNo: obj.appointment[i].policy_no,
                  CNNno: obj.appointment[i].ins_ccn_no,
                });
              }
            }
          },
          error => {
            this.toastr.error("Network error, try again");
          }
        )
    }
  }

  Appointment_change(client_id, rel_id, Sub_id, patientDetails) {
    // this.CurrentDatetime = billdate;
    this.billing_print_array=[];
    this.client_id = client_id;
    this.sub_id = Sub_id;
    this.rel_id = rel_id;
    this.tempparray = patientDetails;
    this.app_id = this.tempparray.doc_app_id;
    this.inpid = patientDetails.inpatientId;
    this.insurer = patientDetails.insurer;
    this.insCardNo = patientDetails.insCardNo;
    this.policyno = patientDetails.policyNo;
    this.CCNno = patientDetails.CNNno;

    var headers = new Headers();
    var send_data;
    if (this.Filter_txt == "Date") {
      send_data = JSON.stringify({
        client_reg_id: client_id,
        relation_id: rel_id,
        sub_rel_id: Sub_id,
        app_id: this.tempparray.doc_app_id,
        from_date: this.f_date,
        to_date: this.t_date,
      });

    } else {
      send_data = JSON.stringify({
        client_reg_id: client_id,
        relation_id: rel_id,
        app_id: this.tempparray.doc_app_id,
        sub_rel_id: Sub_id,
      });
    }

    if (patientDetails.middle_name != undefined) {
      this.patient_name = encrypt_decript.Decript(patientDetails.first_name) + " " + encrypt_decript.Decript(patientDetails.middle_name) + " " + encrypt_decript.Decript(patientDetails.last_name);

    } else {
      if (patientDetails.last_name != undefined) {
        this.patient_name = encrypt_decript.Decript(patientDetails.first_name) + " " + encrypt_decript.Decript(patientDetails.last_name);
      } else {
        this.patient_name = encrypt_decript.Decript(patientDetails.first_name);
      }
    }

    if (patientDetails.balance != undefined) {
      this.amount_collected = true;
      this.balance_show = false;
      this.previous_balshow = false;
      this.balance_data = parseFloat(patientDetails.balance).toFixed();
      // this.final_amount = parseFloat(patientDetails.balance).toFixed();
      this.total_collected = parseFloat(patientDetails.balance).toFixed();
    }

    this.dog_reg_id = patientDetails.doc_reg_id;
    var zipcode = patientDetails.czipcode != undefined ? encrypt_decript.Decript(patientDetails.czipcode) : "";
    this.admissionDate = patientDetails.admitted_date != undefined ? Date_Formate(patientDetails.admitted_date) : "";
    if (patientDetails.admitted_date != undefined) {
      var admisTime = patientDetails.admitted_time.split(':');
      var h = admisTime[0], m = admisTime[1];
      this.admissionTime = (h > 12) ? (h - 12 + ':' + m + ' PM') : (h + ':' + m + ' AM');
    }
    this.dischargeDate = patientDetails.discharge_date != undefined ? Date_Formate(patientDetails.discharge_date) : "";
    if (patientDetails.discharge_time != undefined) {
      if(patientDetails.discharge_time !=":00"){
        var admisTime = patientDetails.discharge_time.split(':');
        var h = admisTime[0], m = admisTime[1];
        this.dischargeTime = (h > 12) ? (h - 12 + ':' + m + ' PM') : (h + ':' + m + ' AM');
      }
    }
    this.billing_print_array.push({
      dr_name: "Dr." + patientDetails.docid_name,
      licence_code: "",
      hptl_name: "",
      created_date: "",
      address: patientDetails.address,
      location: patientDetails.location + ", ",
      city: patientDetails.city + " - " + patientDetails.zipcode + ",",
      state: patientDetails.state + " - " + patientDetails.country + ",",
      telephone: patientDetails.telephone,
      inpatientId: patientDetails.inpatientId,
      appointment_id: patientDetails.doc_app_id,
      client_name: this.patient_name,

      caddress: "",
      clocation: patientDetails.clocation + ", ",
      clientcity: patientDetails.ccity + " - " + zipcode + ",",
      clestare: patientDetails.cstate + " - " + patientDetails.ccountry + ".",
      client_reg_id: patientDetails.client_reg_id,
      advance: patientDetails.advance,
      balance: patientDetails.balance,
    });
    this.medBills = [];
    this.DisplayBills = [];
    this.sendPharmacy = [];
    this.totalBillAmount = "0.00";
    this.totalPaidAmount = "0.00";
    this.TotalBalance = "0.00";
    this.final_amount = "0.00";
    this.totalDiscount = "0.00";
    this.totalPaid = "0.00";
    this.totalBill = "0.00";

    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gbcp', send_data,

      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != null) {
            console.log("BILL DETAILS ((())) "+JSON.stringify(obj))
            this.Advance_bill = obj.balance != undefined ? parseFloat(obj.balance).toFixed(2) : "0.00";
            this.total_advance = obj.advance != undefined ? parseFloat(obj.advance).toFixed(2) : "0.00";
            //this.insuranceFlag = obj.pay_type_desc != undefined ? true : false;
            this.insuranceDec = obj.pay_type_desc != undefined ? obj.pay_type_desc : " ";
          
            // for Pharma bill
            var pharmaBills = obj.pharma_bills;
            if (pharmaBills != undefined) {
              for (var j = 0; j < pharmaBills.length; j++) {
                var billList = pharmaBills[j].bill_details;
                var billDetails = [];
                this.pharma_id = pharmaBills[j].pharma_id;
                var tempPharma = {};
                if (billList.length != 0) {
                  for (var i = 0; i < billList.length; i++) {
                    var concession = billList[i].concession != undefined ? billList[i].concession : "0.00";
                    billDetails.push({
                      name: billList[i].type + "-" + billList[i].name,
                      batch_no: billList[i].batch_no,
                      quantity: billList[i].quantity,
                      amount: billList[i].amount,
                      concession: concession
                    })
                  }
                }
                var bill_amount = pharmaBills[j].bill_amount != undefined ? pharmaBills[j].bill_amount : " ";
                var bill_date = pharmaBills[j].bill_date != undefined ? Date_Formate(pharmaBills[j].bill_date) : "";
                var paid = pharmaBills[j].paid_amount != undefined ? pharmaBills[j].paid_amount : "0.00";
                this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(bill_amount)).toFixed(2);
                this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(paid)).toFixed(2);
                this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(pharmaBills[j].balance)).toFixed(2);
                var discount = pharmaBills[j].discount != undefined ? pharmaBills[j].discount : "0.00";
                tempPharma = {
                  billing_id: pharmaBills[j].billing_id,
                  bill_amount: bill_amount,
                  balance: pharmaBills[j].balance,
                  bill_date: bill_date,
                  paid_amount: paid,
                  checked: false,
                  type: "pharma",
                  billsList: billDetails,
                  discount: discount,
                  fin_year:pharmaBills[j].fin_year,
                  pharma_id: pharmaBills[j].pharma_id
                }
                this.medBills.push(tempPharma);
                if (pharmaBills[j].balance == "0.00") {
                  this.isAllSelected(tempPharma);
                }
              }

              this.DisplayBills = this.medBills;
              this.selectedAllFlage = this.medBills.length == this.sendPharmacy.length ? true : false;
              this.bill_type = "pharma";
            }
          }
        },
        error => {
          this.toastr.error("Network error, try again later");
        }
      )
  }

  isAllSelected(bill) {
    var paid_flag;
    var paidAmount;
    var balance;
    var isexists = false;
    if (bill.type == "pharma") {// send for pharmacy
      if (this.sendPharmacy.length != 0) {
        var index: any = 0;
        for (var j = 0; j < this.sendPharmacy.length; j++) {
          if (this.sendPharmacy[j].order_id == bill.billing_id) {
            index = 1;
          }
        }
        if (index == 1) {
          for (var i = 0; i < this.sendPharmacy.length; i++) {
            if (this.sendPharmacy[i].order_id == bill.billing_id) {
              if (this.sendPharmacy[i].temp_balance != "0.00") {
                this.sendPharmacy.splice(i, 1);
              }

              this.final_amount = (parseFloat(this.final_amount) - parseFloat(bill.balance)).toFixed(2);
              this.mediBillAmount = (parseFloat(this.mediBillAmount) - parseFloat(bill.bill_amount)).toFixed(2);
              this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(bill.discount)).toFixed(2);
              this.totalBill = (parseFloat(this.totalBill) - parseFloat(bill.bill_amount)).toFixed(2);
              this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(bill.paid_amount)).toFixed(2);
              break;
            }
          }
          for (var k = 0; k < this.medBills.length; k++) {
            if (this.medBills[k].billing_id == bill.billing_id) {
              if (this.medBills[k].balance == "0.00") {
                this.medBills[k].checked = true;
              } else {
                this.medBills[k].checked = false;
              }
              break;
            }
          }

        } else {
          var flag;
          if (parseFloat(bill.balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid"
          }
          this.sendPharmacy.push({
            order_id: bill.billing_id,
            pharma_id: bill.pharma_id,
            paid_amount: bill.paid_amount,
            balance: bill.balance,
            temp_balance: bill.balance,
            paid_flag: flag,
            billDetails: bill.billsList,
            fin_year:bill.fin_year,
            advance: bill.advance != undefined ? bill.advance : "0.00"
          });
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
          this.mediBillAmount = (parseFloat(this.mediBillAmount) + parseFloat(bill.bill_amount)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(bill.discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
          for (var k = 0; k < this.medBills.length; k++) {
            if (this.medBills[k].billing_id == bill.billing_id) {
              this.medBills[k].checked = true;
              break;
            }
          }
        }

      } else {
        var flag;
        if (parseFloat(bill.balance) == 0.00) {
          flag = "Fully paid";
        } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
          flag = "un paid";
        } else {
          flag = "Partially paid"
        }
        this.sendPharmacy.push({
          order_id: bill.billing_id,
          pharma_id: bill.pharma_id,
          paid_amount: bill.paid_amount,
          balance: bill.balance,
          paid_flag: flag,
          temp_balance: bill.balance,
          billDetails: bill.billsList,
          fin_year:bill.fin_year,
          advance: bill.advance != undefined ? bill.advance : "0.00"
        });
        this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
        this.mediBillAmount = (parseFloat(this.mediBillAmount) + parseFloat(bill.bill_amount)).toFixed(2);
        this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(bill.discount)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
        for (var k = 0; k < this.medBills.length; k++) {
          if (this.medBills[k].billing_id == bill.billing_id) {
            this.medBills[k].checked = true;
            break;
          }
        }
      }
    } 
 
    if (this.sendPharmacy.length != 0) {
      this.medcalDetailsArray=[];
      for (var x = 0; x < this.sendPharmacy.length; x++) {
        for (var y = 0; y < this.sendPharmacy[x].billDetails.length; y++) {
          this.medcalDetailsArray.push({
            name: this.sendPharmacy[x].billDetails[y].name,
            batch_no:this.sendPharmacy[x].billDetails[y].batch_no,
            quantity:this.sendPharmacy[x].billDetails[y].quantity,
            concession:this.sendPharmacy[x].billDetails[y].oncession,
            amount:this.sendPharmacy[x].billDetails[y].amount,
          })
        }
      }
      console.log("medical 123 "+JSON.stringify(this.medcalDetailsArray))
      // if (this.insuranceFlag != false) {
      //   for (var x = 0; x < this.sendPharmacy.length; x++) {
      //     for (var y = 0; y < this.sendPharmacy[x].billDetails.length; y++) {
      //       this.medcalDetailsArray.push({
      //         name: this.sendPharmacy[x].billDetails[y].name,
      //         batch_no:this.sendPharmacy[x].billDetails[y].batch_no,
      //         quantity:this.sendPharmacy[x].billDetails[y].quantity,
      //         concession:this.sendPharmacy[x].billDetails[y].oncession,
      //         amount:this.sendPharmacy[x].billDetails[y].amount,
      //       })
      //     }
      //   }
      // } else {
      //   for (var x = 0; x < this.sendPharmacy.length; x++) {
      //     for (var y = 0; y < this.sendPharmacy[x].billDetails.length; y++) {
      //       this.medcalDetailsArray.push({
      //         name: this.sendPharmacy[x].order_id,
      //         amount:parseFloat(this.sendPharmacy[x].temp_balance).toFixed(2),
      //       })
      //     }
      //   }
      // }
    }

    this.remaing_billbal = (parseFloat(this.final_amount) - (parseFloat(this.Advance_bill) + parseFloat(this.paid_bill))).toFixed(2);
  }

  allSelected(value) {
    this.selectedAllFlage = value == true ? true : false;
    if (this.bill_type == "pharma") {
      if ((this.medBills.length != this.sendPharmacy.length) || this.sendPharmacy.length == 0) {
        this.sendPharmacy = [];
        // this.final_amount = "0.00";
        for (var k = 0; k < this.medBills.length; k++) {
          // this.isAllSelected(this.medBills[k]);
          var flag;
          if (parseFloat(this.medBills[k].balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(this.medBills[k].balance) == parseFloat(this.medBills[k].bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid"
          }
          this.sendPharmacy.push({
            order_id: this.medBills[k].billing_id,
            pharma_id: this.medBills[k].pharma_id,
            paid_amount: this.medBills[k].paid_amount,
            balance: this.medBills[k].balance,
            paid_flag: flag,
            temp_balance: this.medBills[k].balance,
            fin_year:this.medBills[k].fin_year,
            billDetails: this.medBills[k].billsList,
            advance: this.medBills[k].advance != undefined ? this.medBills[k].advance : "0.00"
          });
          this.medBills[k].checked = true;
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.medBills[k].balance)).toFixed(2);
          this.mediBillAmount = (parseFloat(this.mediBillAmount) + parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(this.medBills[k].discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.medBills[k].paid_amount)).toFixed(2);
        }

      } else {
        //this.sendPharmacy = [];
        for (var i = 0; i < this.sendPharmacy.length; i++) {
          if (this.sendPharmacy[i].temp_balance != "0.00") {
            this.sendPharmacy.splice(i, 1);
          }
        }
        for (var k = 0; k < this.medBills.length; k++) {
          if (this.medBills[k].balance == "0.00") {
            this.medBills[k].checked = true;
          } else {
            this.medBills[k].checked = false;
          }
          this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.medBills[k].balance)).toFixed(2);
          this.mediBillAmount = (parseFloat(this.mediBillAmount) - parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(this.medBills[k].discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.medBills[k].paid_amount)).toFixed(2);
        }
      }

    } 

    if (this.sendPharmacy.length != 0) {
      this.medcalDetailsArray=[];
      for (var x = 0; x < this.sendPharmacy.length; x++) {
        for (var y = 0; y < this.sendPharmacy[x].billDetails.length; y++) {
          this.medcalDetailsArray.push({
            name: this.sendPharmacy[x].billDetails[y].name,
            batch_no:this.sendPharmacy[x].billDetails[y].batch_no,
            quantity:this.sendPharmacy[x].billDetails[y].quantity,
            concession:this.sendPharmacy[x].billDetails[y].oncession,
            amount:this.sendPharmacy[x].billDetails[y].amount,
          })
        }
      }
      console.log("medical "+JSON.stringify(this.medcalDetailsArray))
      // if (this.insuranceFlag != false) {
      //   for (var x = 0; x < this.sendPharmacy.length; x++) {
      //     for (var y = 0; y < this.sendPharmacy[x].billDetails.length; y++) {
      //       this.medcalDetailsArray.push({
      //         name: this.sendPharmacy[x].billDetails[y].name,
      //         batch_no:this.sendPharmacy[x].billDetails[y].batch_no,
      //         quantity:this.sendPharmacy[x].billDetails[y].quantity,
      //         concession:this.sendPharmacy[x].billDetails[y].oncession,
      //         amount:this.sendPharmacy[x].billDetails[y].amount,
      //       })
      //     }
      //   }
      // } else {
      //   for (var x = 0; x < this.sendPharmacy.length; x++) {
      //     for (var y = 0; y < this.sendPharmacy[x].billDetails.length; y++) {
      //       this.medcalDetailsArray.push({
      //         name: this.sendPharmacy[x].order_id,
      //         amount:parseFloat(this.sendPharmacy[x].temp_balance).toFixed(2),
      //       })
      //     }
      //   }
      // }
    }

    this.remaing_billbal = (parseFloat(this.final_amount) - (parseFloat(this.Advance_bill) + parseFloat(this.paid_bill))).toFixed(2);
  }

  checkBalance() {
    if (this.paid_bill != "") {
      // if (parseFloat(this.paid_bill) >= parseFloat(this.final_amount)) {
      //   this.balance_show = false;
      //   this.remaing_billbal = (parseFloat(this.final_amount) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
      // } else {
      this.balance_show = false;
      //  var paid_bal = (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill)).toFixed(2);
      var advanceBalance = (parseFloat(this.Advance_bill)).toFixed(2);
      var paidBalance = (parseFloat(this.paid_bill)).toFixed(2);
      this.remaing_billbal = (parseFloat(this.final_amount) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);

      if (parseFloat(advanceBalance) != 0.00 || parseFloat(paidBalance) != 0.00) {
        if (this.sendPharmacy.length != 0) {
          for (var i = 0; i < this.sendPharmacy.length; i++) {
            if (parseFloat(advanceBalance) != 0.00) {
              if (parseFloat(this.sendPharmacy[i].temp_balance) <= parseFloat(advanceBalance)) {
                this.sendPharmacy[i].paid_amount = "0.00";
                this.sendPharmacy[i].advance = (parseFloat(this.sendPharmacy[i].temp_balance)).toFixed(2);
                this.sendPharmacy[i].balance = "0.00";
                this.sendPharmacy[i].paid_flag = "Fully paid";
                advanceBalance = (parseFloat(advanceBalance) - parseFloat(this.sendPharmacy[i].temp_balance)).toFixed(2);

              } else {
                var getRemindingAmount = (parseFloat(this.sendPharmacy[i].temp_balance) - parseFloat(advanceBalance)).toFixed(2);
                if (parseFloat(getRemindingAmount) <= parseFloat(paidBalance)) {
                  this.sendPharmacy[i].paid_amount = parseFloat(getRemindingAmount).toFixed(2);
                  this.sendPharmacy[i].advance = (parseFloat(advanceBalance)).toFixed(2);
                  this.sendPharmacy[i].balance = "0.00";
                  this.sendPharmacy[i].paid_flag = "Fully paid";
                  paidBalance = (parseFloat(paidBalance) - parseFloat(getRemindingAmount)).toFixed(2);

                } else {
                  var tempPaidBal = (parseFloat(getRemindingAmount) - parseFloat(paidBalance)).toFixed(2)
                  var remaingBal = (parseFloat(this.sendPharmacy[i].temp_balance) - (parseFloat(tempPaidBal) + parseFloat(advanceBalance))).toFixed(2);
                  this.sendPharmacy[i].paid_amount = parseFloat(paidBalance).toFixed(2);
                  this.sendPharmacy[i].balance = remaingBal;
                  this.sendPharmacy[i].advance = (parseFloat(advanceBalance)).toFixed(2);
                  this.sendPharmacy[i].paid_flag = "Partially paid";
                  paidBalance = "0.00";

                }
                advanceBalance = "0.00";
              }

            } else if (parseFloat(paidBalance) != 0.00) {
              if (parseFloat(this.sendPharmacy[i].temp_balance) <= parseFloat(paidBalance)) {
                this.sendPharmacy[i].paid_amount = this.sendPharmacy[i].temp_balance;
                this.sendPharmacy[i].advance = "0.00";
                this.sendPharmacy[i].balance = "0.00";
                this.sendPharmacy[i].paid_flag = "Fully paid";
                paidBalance = (parseFloat(paidBalance) - parseFloat(this.sendPharmacy[i].temp_balance)).toFixed(2);

              } else {
                var remaingBal = (parseFloat(this.sendPharmacy[i].temp_balance) - parseFloat(paidBalance)).toFixed(2);
                this.sendPharmacy[i].paid_amount = paidBalance;
                this.sendPharmacy[i].balance = remaingBal;
                this.sendPharmacy[i].advance = "0.00";
                this.sendPharmacy[i].paid_flag = "Partially paid";
                paidBalance = "0.00";
              }
              advanceBalance = "0.00";
            } else {
              break;
            }
          }
        }
      }

    } else {
      this.paid_bill = "0.00";
    }
  }

  printBill(){
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }

    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Consolidated bill</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      p{
        margin:0;
      }
      
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  Bill_save() {
    var flag = true;

    if (this.sendPharmacy.length == 0) {
      this.toastr.error("Select atleast one bill type");
      flag = false;
    } else if (this.Advance_bill == "0.00" && this.paid_bill == "0.00") {
      this.toastr.error("Please fill paid amount");
      flag = false;
    }

    var advance;
    if (parseFloat(this.final_amount) == (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))) {
      advance = "0.00";
    } else if (parseFloat(this.final_amount) < parseFloat(this.Advance_bill)) {
      advance = ((parseFloat(this.Advance_bill) - parseFloat(this.final_amount))).toFixed(2);
    } else {
      advance = "0.00";
    }

    if (flag == true) {
      console.log("PHARMA BILLS " + JSON.stringify(this.sendPharmacy))
      var sendData = {
        client_reg_id: this.client_id,
        advance_balance: advance,
        hptl_clinic_id: this.hptl_clinic_id,
        appointment_id: this.app_id,
        pharma: this.sendPharmacy,
      }
      this.saveFlag = false;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/ucb/', sendData,

        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj["result"] != null) {
              if (obj["key"] == "1") {
                this.toastr.success("Success");
              } else {
                this.toastr.error("Error");
                this.saveFlag = true;
              }
            }
          },
          error => {
            this.saveFlag = true;
            // this.toastr.error(Message_data.getNetworkMessage());
          }
        )

    }
  }

  getPatientList(patientList) {
    this.patientList = [];
    if (patientList.target.value.toString() != null && patientList.target.value.toString().length > 2) {
      var headers = new Headers();
      var name = encrypt_decript.Encript(patientList.target.value.toString())
      headers.append('Content-Type', 'application/json');
      this.http.post('bill/inpa', JSON.stringify({
        name: patientList.target.value.toString(),
      }), { headers: headers }).subscribe(
        data => {
          // if (data.json().locations != null && data.json().locations.length != 0) {

          // }
        },
        error => {
          this.toastr.error("Unable to get doctor details");
        }
      )
    } else {
      this.patientList = [];
    }
  }
}
