<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Patient list</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" style="margin-bottom:15px;">
            <div class="row">
              <div class="col-5 switch-field">
                <input type="radio" id="radio-five" (click)="searchType('name')" />
                <label for="radio-five"
                [ngClass]=" typeflag == 'name' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Doctor.svg" class="iocinStyle"
                        alt=""></div>
                    <div class="lableStyle">Name</div>
                  </div>
                </label>

                <input type="radio" id="radio-six" (click)="searchType('mobile')" />
                <label for="radio-six"
                [ngClass]=" typeflag == 'mobile' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/diagnosis.svg" class="iocinStyle"
                        alt=""></div>
                    <div class="lableStyle">Mobile</div>
                  </div>
                </label>

                <input type="radio" id="radio-seven" (click)="searchType('patientid');focusInput()"/>
                <label for="radio-seven" [ngClass]=" typeflag == 'patientid' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Dietician_icon_svg.svg"
                        class="iocinStyle" alt=""></div>
                    <div class="lableStyle">Patient id</div>
                  </div>
                </label>  
              </div>   
              <div class="col-6">
                <input class="inputStyle2" type="text" id="filterInput" [(ngModel)]="patientName"
                placeholder={{searchtext}} (keyup)="getPatientListByName(patientName)">
              </div>
            </div>
          </div>
        </div> 
      <div style="width: 100%;display:flex; justify-content: center;">
        <div style="width: 75vw;">                        
          <div class="row">
            <table *ngIf="patientList.length"  class="table-fixed" >
              <thead>
                <tr style="height: 30px;" #scrollContainer (scroll)="onScroll($event)"><!--#scrollContainer (scroll)="onScroll($event)"-->
                  <th style="width:10%;border-bottom: 1px solid #ddd;font-weight: 600;text-align: left;padding-left: 3%;">Patient id</th>
                  <th style="width:30%;border-bottom: 1px solid #ddd;font-weight: 600;text-align: left;padding-left: 10%">Name</th>
                  <th style="width:30%;border-bottom: 1px solid #ddd;font-weight: 600;text-align: left;">Mobile</th>
                  <th style="width:10%;border-bottom: 1px solid #ddd;font-weight: 600;text-align: left;">&nbsp;</th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="row" style="overflow: scroll; overflow-y: hidden; height: 70vh;">
            <p class="nodata col-12" *ngIf="patientList.length == 0">No patient list(s) found</p>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11" class="scrollbar" (scroll)="onScroll($event)">
              <div class="scrollbar-inner">
                <table *ngIf="patientList.length"  class="table-fixed" >
                  <tbody><!--#scrollContainer (scroll)="onScroll($event)"-->
                    <tr *ngFor="let person of patientList; let i = index" style="height: 30px;">
                      <td style="font-size: 12px; text-align: left;padding-left: 3%;width:15%;border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;">{{ person.patient_id }}</td>
                      <td style="font-size: 12px;text-align: left; ;width:10%;border-bottom: 1px solid #ddd;">{{ person.full_name }}</td>
                      <td style="font-size: 12px;text-align: left;padding-left:10%;width:20%;border-bottom: 1px solid #ddd;">{{ person.mobile }}</td>
                      <td style="text-align: left;padding-left: 5%;width:15%;border-bottom: 1px solid #ddd;border-right: 1px solid #ddd;"><img src="../../../assets/img/edit.png" class="billing_edit" style="z-index: 9;" alt="" (click)="editClient(person)"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br><br><br>
          </div>
        </div>
      </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>