import { Component, OnInit } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Message_data } from '../../../assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diagbillcreate',
  templateUrl: './diagbillcreate.component.html',
  styleUrls: ['./diagbillcreate.component.css']
})
export class DiagbillcreateComponent implements OnInit {
  public appoint;
  public amount;
  public finalAmount;
  public inGST: boolean;

  public concession;
  public con;
  public fees;
  public tableFlag: boolean;
  public finalFlag: boolean;
  public amountGST;
  public advance;
  public currentDateTime;
  public currentDate;
  public appointDate;
  public billListArray = [];
  public appID;
  public billDenPlanArray = [];
  public gstRetrivelArray = [];
  public addBillArray = [];

  public GSTArray = [];
  public charges;
  public estimateID = [];
  public remaingBalance;
  public addvance;
  public centralGST;
  public stateGST;
  public clientID;
  public relationID;
  public subRelationID;
  public chargesID;
  public Diag_Location_Data = [];
  public userType;
  public location;
  public date;
  public diagID;
  public diagLocation;
  public diagCenterID;
  public testArray = [];
  
  public diagName;
  public testName;
  public ipaddress;
  public userInfo;
  
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public dateTxt;
  public appDate
  //Get hospital detials
  public clientName: string;
  public previousBillID;
  public balanceFlag: boolean;
  public amountFlag: boolean;
  public previousBalFlag: boolean;
  public balance;
  public total;
  public payTypeArray: any = [];
  public insuranceFlag;
  public payType: string;
  public insurerancyID: string;
  public insurerancListArray: any = [];
  public inpatientFlag:boolean;

  public tranFlag:boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag:boolean;
  public bankName;
  public quantity = 1;
  private sno:number=1;
  public saveFlag:boolean = false;
  public paytypreq:boolean=false;
  public date_time_flag:boolean=false;
  public paymentsarray:any=[];
  public paymentArray:any=[];
  public payType1: string;
  public amountval: number;
  public insurFlag1:boolean=true;
  public cardFlag1:boolean=true;
  public tranFlag1:boolean=true;
  public chequeFlag1:boolean=true;

  constructor(public toastr: ToastrService,public http: Http,public diagnosisservice:MenuViewService,public frontdeskservice: MenuViewService, public adminservice:MenuViewService) {
    this.dateTxt = this.appointDate;
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
  }

  ngOnInit(): void {
    this.saveFlag = false;
    this.dtOptions = {
      pageLength: 5,
      "lengthMenu": [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
      "columnDefs": [
        { "orderable": false, "targets": [0,1,2,3,4,5] },
      ],
      "language": {
      "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
   
    this.finalAmount = "0";
    this.amountGST = "0";
    this.advance = "0.00";
    this.concession = "0";
    this.inGST = false;
    this.gstRetrivelArray = [];
    this.estimateID = [];
    this.previousBalFlag = true;
    this.balanceFlag = true;
   
    this.getDate()
    
    this.ipaddress = Helper_Class.getIPAddress();
    this.userInfo = Helper_Class.getInfo();
    if(this.userInfo.hospitals[0].bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }
   
    if(Helper_Class.getInfo().hospitals[0].bill_date_time_edit == "1"){
      this.date_time_flag = false;
    } else {
      this.date_time_flag = true;
    }
    
    this.getPaymentType();
    this.getInsurers();
    this.getGST();
    this.getBillType();
    console.log("Diagnosis_Helper.getUserID_Type()" + Diagnosis_Helper.getUserID_Type());
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDateTime = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.appointDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            this.currentDate = Date_Formate(obj.current_date)
            this.date = obj.current_date;
            this.appointDate =  this.currentDateTime;
            this.getLocation();
          }
        },error => {});
  }

  getLocation() {//Get location
    this.Diag_Location_Data = [];
    var flag;
    
    if (Diagnosis_Helper.getUserID_Type().user_type != null) {
      if(Diagnosis_Helper.getUserID_Type().user_type == "frontDesk"){
        this.userType = "frontDesk";
        flag = "name";
      }else{
        this.userType = "diag_user";
        flag = "name"; 
      }
    } else {
      flag = "name";
      this.userType = "diagnosis";
    }
    console.log("Diagnosis_Helper.getUserID_Type()" + JSON.stringify(Diagnosis_Helper.getUserID_Type()))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
      JSON.stringify({
        diag_name: Diagnosis_Helper.getUserID_Type().diag_centre_id,
        flag: flag,
        imei: this.ipaddress,
        prov_id: Diagnosis_Helper.getUserID_Type().user_id,
        type: this.userType
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.diag_loc.length; i++) {
            this.Diag_Location_Data.push({
              centre_id: obj.diag_loc[i].diag_centre_id,
              centre_name: obj.diag_loc[i].diag_centre_name,
              centre_location: obj.diag_loc[i].diag_loc,
              view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
            });
          }
          this.location = this.Diag_Location_Data[0].view_data;
          this.getAppID(this.Diag_Location_Data[0].centre_id, this.Diag_Location_Data[0].centre_location,this.currentDateTime);
        },error => {});
  }

  getAppointments(){//On datechange get appointment details
    this.getAppID(this.Diag_Location_Data[0].centre_id, this.Diag_Location_Data[0].centre_location,this.date)
  }

  billAdd() { //Bill add table
    if (this.appoint == undefined) {
      this.toastr.error(Message_data.sltAppId);

    } else if (this.amount == undefined) {
      this.toastr.error(Message_data.enterAmt);

    } else if (this.quantity == undefined) {
      this.toastr.error(Message_data.enterQty);

    }else {
      if (this.finalAmount == "0") {
        this.inGST == false;
      }

      this.tableFlag = true;
      this.finalFlag = true;
      var flag = true;

      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].test_name == this.testName) {
          flag = false;
          break;
        }
      }
      
      if (flag == true) {
        this.saveFlag=true;
        var testtype;
        if (this.testArray.length != 0) {
          for (var i = 0; i < this.testArray.length; i++) {
            if (this.testName == this.testArray[i].Testname) {
              this.chargesID = this.testArray[i].diag_test_id;
              testtype= this.testArray[i].test_type;
            }
          }
        }
        var paydesc;
        if (this.payTypeArray.length != 0) {
          for (var i = 0; i < this.payTypeArray.length; i++) {
            if (this.payType == this.payTypeArray[i].pay_id) {
              paydesc = this.payTypeArray[i].pay_desc;
            }
          }
        }

        this.con = this.concession == "" ? "0" : this.concession;

        this.addBillArray.push({
          sno:this.sno,
          test_name: this.testName,
          type_of_test:testtype,
          amount_txt: parseFloat(this.amount).toFixed(2),
          concession: parseFloat(this.con).toFixed(2),
          fee: parseFloat(this.fees).toFixed(2),
          charge_type: this.testName,
          pay_type: paydesc,
          paytype: this.payType,
          insurer: this.insurerancyID,
          quantity:this.quantity,
        });
        this.sno+=1;
        this.amount = '';
        this.concession = '0';
        this.fees = '';
        this.quantity = 1;
      }

      if (this.finalAmount == "0") {
        this.inGST == false;
      }
      this.calculateGST("0", "0");
    }
  }

  deleteBill(type, amount) {//Delete bill
    if (this.addBillArray.length != 0) {
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].biill_type == type && this.addBillArray[i].amount_txt == amount) {
          this.addBillArray.splice(i, 1);
          this.calculateGST("0", "0");
          break;
        }
      }
      var sno=1;
      for (var i = 0; i < this.addBillArray.length; i++) {
        this.addBillArray[i].sno=sno;
        sno+=1;
      }
    }

    if (this.addBillArray.length == 0) {
      this.tableFlag = false;
      this.finalFlag = false;
      this.saveFlag=false;
    } else {
      this.tableFlag = true;
      this.saveFlag=true;
    }

    if (this.finalAmount == "0") {
      this.inGST == false;
    }
    this.calculateGST("0", "0");
  }

  getBillType() {//Get bill type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      JSON.stringify({
        provider: "diagnosis",
     }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.charges = obj.charges;
        },error => {});
  }

  getAppointmentIdDateBase(date) {
    this.billListArray = [];

    for (var i = 0; i < this.Diag_Location_Data.length; i++) {
      if (this.Diag_Location_Data[i].view_data == this.location) {
        this.diagID = this.Diag_Location_Data[i].centre_id.toString();
        this.diagLocation = this.Diag_Location_Data[i].centre_location.toString();
      }
    }

    this.appDate=(this.date);

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/appbydate',
      JSON.stringify({
        provider: "diagnosis",
        diag_center_id: this.diagID,
        location: this.diagLocation,
        session: "all",
        date:  this.appDate,
        bill: "yes"
      }),
      { headers: headers })
      .subscribe(
        response => {
          
          var obj = JSON.parse(response["_body"]);
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              if (obj.appointments[i].middle_name != null && encrypt_decript.Decript(obj.appointments[i].middle_name) != undefined) {
                this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              } else {
                this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              }
              this.inpatientFlag = obj.appointments[i].inpatient_id != undefined ? true : false;
              this.billListArray.push({
                doc_app_id: obj.appointments[i].diag_appointment_id,
                client_reg_id: obj.appointments[i].client_reg_id,
                relation_id: obj.appointments[i].relation_id,
                sub_rel_id: obj.appointments[i].sub_rel_id,

                first_name: obj.appointments[i].first_name,
                middle_name: obj.appointments[i].middle_name,
                last_name: obj.appointments[i].last_name,
                diagid_name: this.diagName,
              });
            }
          }
        },error => {});
  }

  getAppID(centerid, centerlocation, date) {
    this.billListArray = [];
    this.appDate=(this.date);
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/appbydate',
      JSON.stringify({
        provider: "diagnosis",
        diag_center_id: centerid,
        location: centerlocation,
        session: "all",
        date:this.appDate,
        bill: "yes"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj.appointments != null && obj.appointments.length != 0) {
            for (var i = 0; i < obj.appointments.length; i++) {
              if(obj.appointments[i].first_name != undefined){
                if(obj.appointments[i].last_name != undefined){
                  if (obj.appointments[i].middle_name != undefined && encrypt_decript.Decript(obj.appointments[i].middle_name) != undefined) {
                    this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                  }else{
                    this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                  }
                }else{
                  this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name);
                }
              }
              this.inpatientFlag = obj.appointments[i].inpatient_id != undefined ? true : false;
              this.billListArray.push({
                doc_app_id: obj.appointments[i].diag_appointment_id,
                client_reg_id: obj.appointments[i].client_reg_id,
                relation_id: obj.appointments[i].relation_id,
                sub_rel_id: obj.appointments[i].sub_rel_id,
                first_name: obj.appointments[i].first_name,
                middle_name: obj.appointments[i].middle_name,
                last_name: obj.appointments[i].last_name,
                diagid_name: this.diagName,
              });
              console.log("BILL DETAILS "+JSON.stringify(this.billListArray))
            }
          }else{
            this.toastr.error(Message_data.noAptForBillCreation);
          }
        },
        error => {});
  }

  changeAppID() {
    this.billDenPlanArray = [];
    if (this.billListArray.length != 0) {
      for (var i = 0; i < this.billListArray.length; i++) {
        if (this.billListArray[i].diagid_name.replace(/\s/g, "") == this.appoint.replace(/\s/g, "")) {
          this.appID = this.billListArray[i].doc_app_id;
        }
      }
    }
    // Initializes a new instance of the StringBuilder class and appends the given value if supplied
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp',
      JSON.stringify({
        diag_app_id: this.appID,
        type: "diagnosis",
        country: "IN"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var sb = new StringBuilder("")
          var obj = JSON.parse(response["_body"]);
          
          if (obj.centre_name != null) {
            sb.append(obj.centre_name);
            sb.append("\n");
            sb.append(obj.address1);
            sb.append("\n");
            if (obj.address2 != null && obj.address2 != "") {
              sb.append(obj.address2);
              sb.append("\n");
            }

            sb.append(obj.location);
            sb.append("\n");

            sb.append(obj.city + " , " + obj.state);
            sb.append("\n");

            sb.append(obj.country + "-" + obj.zipcode);
            sb.append("\n");

            sb.append(obj.phone);
            sb.append("\n");

            this.diagCenterID = obj.centre_id;
          }

          if (obj.client_reg_id != null) {
            this.clientID = obj.client_reg_id;
          }

          if (obj.relation_id != null) {
            this.relationID = obj.relation_id;
          }

          if (obj.sub_rel_id != null) {
            this.subRelationID = obj.sub_rel_id;
          }
          if (obj.middle_name != undefined) {
            this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);

          } else {
            this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
          }

          this.testArray = [];

          if (obj.blood_test != null) {
            for (var i = 0; i < obj.blood_test.length; i++) {
              this.testArray.push({
                Groupname: "Blood Test",
                test_type:"Blood",
                Testname: obj.blood_test[i].diag_test_name,
                Pres_test_id: obj.blood_test[i].pres_test_id,
                diag_test_id: obj.blood_test[i].diag_test_id,
                cost: obj.blood_test[i].cost,
              });
            }
          }

          if (obj.urine_test != null) {
            for (var i = 0; i < obj.urine_test.length; i++) {
              this.testArray.push({
                Groupname: "Urine Test",
                test_type:"Urine",
                Testname: obj.urine_test[i].diag_test_name,
                Pres_test_id: obj.urine_test[i].pres_test_id,
                diag_test_id: obj.urine_test[i].diag_test_id,
                cost: obj.urine_test[i].cost,
              });
            }
          }

          if (obj.faeces_test != null) {
            for (var i = 0; i < obj.faeces_test.length; i++) {
              this.testArray.push({
                Groupname: "Feaces Test",
                test_type:"Feaces",
                Testname: obj.faeces_test[i].diag_test_name,
                Pres_test_id: obj.faeces_test[i].pres_test_id,
                diag_test_id: obj.faeces_test[i].diag_test_id,
                cost: obj.faeces_test[i].cost,
              });
            }
          }

          if (obj.scan_test != null) {
            for (var i = 0; i < obj.scan_test.length; i++) {
              this.testArray.push({
                Groupname: "Scan Test",
                test_type:"Scan",
                Testname: obj.scan_test[i].diag_test_name,
                Pres_test_id: obj.scan_test[i].pres_test_id,
                diag_test_id: obj.scan_test[i].diag_test_id,
                cost: obj.scan_test[i].cost,
              });
            }
          }

          if (obj.xray_test != null) {
            for (var i = 0; i < obj.xray_test.length; i++) {
              this.testArray.push({
                Groupname: "X-ray Test",
                test_type:"Xray",
                Testname: obj.xray_test[i].diag_test_name,
                Pres_test_id: obj.xray_test[i].pres_test_id,
                diag_test_id: obj.xray_test[i].diag_test_id,
                cost: obj.xray_test[i].cost,
              });
            }
          }
          if (obj.culture_test != null) {
            for (var i = 0; i < obj.culture_test.length; i++) {
              this.testArray.push({
                Groupname: "Culture",
                test_type:"Culture",
                Testname: obj.culture_test[i].diag_test_name,
                Pres_test_id: obj.culture_test[i].pres_test_id,
                diag_test_id: obj.culture_test[i].diag_test_id,
                cost: obj.culture_test[i].cost,
              });
            }
          }

          if (obj["ultrasound_test"] != null) {
            for (var i = 0; i < obj["ultrasound_test"].length; i++) {
              this.testArray.push({
                Groupname: "Ultra Sound",
                test_type:"Ultra",
                Testname: obj["ultrasound_test"][i].diag_test_name,
                Pres_test_id: obj["ultrasound_test"][i].pres_test_id,
                diag_test_id: obj["ultrasound_test"][i].diag_test_id,
                cost: obj["ultrasound_test"][i].cost,
              });
            }
          }

          if (obj.advance != null) {
            this.advance = obj.advance;
          }

          if (obj.prev_bill_id != null) {
            this.previousBillID = obj.prev_bill_id;
            this.previousBalFlag = true;
          }

          if (obj.balance != "0") {
            this.balanceFlag = true;
            this.amountFlag = true;
            this.balanceFlag = false;
            this.balance = obj.balance;
            this.finalAmount = obj.balance;
            this.total = obj.balance;
          }
        },error => {});
  }

  changeTest(diag_test_name) {//test change
    this.amount = undefined;
    this.fees = "0";
    for (var i = 0; i < this.testArray.length; i++) {
      if (diag_test_name == this.testArray[i].Testname) {
        this.amount = this.testArray[i].cost
      }
    }

    var perce = this.concession / 100;
    var amt = this.amount;
    var amt_cons = this.fees;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }

    this.fees = Math.round(tot);
  }

  changeAmount() {// concession calculation
    var perce = null;
    perce = this.concession / 100;
    var amt = this.amount;
    if(this.quantity > 1){
      amt = amt * this.quantity;
    }

    var amt_cons = this.fees;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.fees = Math.round(tot);
  }

  getGST() {//Get gst
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/tax/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.gstRetrivelArray = obj.taxes;
        },error => {});
  }

  includeGST(check) {// Include GST
    this.calculateGST("0", "0");
  }

  calculateGST(palnid, planmodal) {
    this.finalAmount = "0";
    this.amountGST = "0";
    this.total = "0";
    this.GSTArray = [];

    if (this.billDenPlanArray.length != 0) {
      for (var i = 0; i < this.billDenPlanArray.length; i++) {
        for (var j = 0; j < this.estimateID.length; j++) {
          if (this.estimateID[j] == this.billDenPlanArray[i].den_treatment_plan_id) {
            this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billDenPlanArray[i].estimate)).toFixed(2);
            this.amountGST = (parseFloat(this.amountGST) + parseFloat(this.billDenPlanArray[i].estimate)).toFixed(2);

            if (parseFloat(this.advance) < parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.total = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
              this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
            }

            if (parseFloat(this.advance) > parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.remaingBalance = (parseFloat(this.advance) - parseFloat(this.finalAmount)).toFixed(2);
            }

            this.amountFlag = true;

            if (this.total == "0") {
              this.amountFlag = false;
            }

            if (this.remaingBalance == "0") {
              this.balanceFlag = true;
            }
            break;
          }
        }
      }
    }

    for (var i = 0; i < this.addBillArray.length; i++) {
      this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.addBillArray[i].fee)).toFixed(2);
      this.amountGST = (parseFloat(this.amountGST) + parseFloat(this.addBillArray[i].fee)).toFixed(2);

      if (parseFloat(this.advance) < parseFloat(this.finalAmount)) {
        this.balanceFlag = false;
        this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
      }

      if (parseFloat(this.advance) > parseFloat(this.finalAmount)) {
        this.balanceFlag = false;
        this.remaingBalance = (parseFloat(this.advance) - parseFloat(this.finalAmount)).toFixed(2);
        this.total = "0";
      }

      if (this.total == "0") {
        this.amountFlag = false;
      }

      if (this.remaingBalance == "0") {
        this.balanceFlag = true;
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetrivelArray.length; i++) {
        this.GSTArray.push({
          Gst_Descrip: this.gstRetrivelArray[i].tax_desc + " " + this.gstRetrivelArray[i].tax,
          Gst_amount: (parseFloat(this.amountGST) * (parseFloat(this.gstRetrivelArray[i].tax) / 100)).toFixed(2)
        });
        this.finalAmount = (parseFloat(this.finalAmount) + (parseFloat(this.amountGST) * (parseFloat(this.gstRetrivelArray[i].tax) / 100)));
      }
      this.finalAmount = parseFloat(this.finalAmount).toFixed(2);
    }

    if (this.balance != undefined) {
      this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.balance)).toFixed(2);
    }

    //amount to be collected
    this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);

    if (this.estimateID.length == 0 && this.addBillArray.length == 0) {
      this.inGST = false;
      this.GSTArray = [];
      this.advance = "0"
      this.finalAmount = "0";
      this.amountGST = 0;
      this.total = "0";
      this.remaingBalance = "0";
    }
  }

  changeAdvance() {//add the bill details
    if (this.advance != undefined && this.advance != "") {
      this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
    } else {
      this.remaingBalance = this.finalAmount;
    }

    if (parseFloat(this.advance) > parseFloat(this.finalAmount)) {
      this.remaingBalance = "0";
    }
  }

  saveBill() { //Bill save
    Diagnosis_Helper.setbillcreate(null);
    var flag = true;
    if (this.addBillArray.length == 0) {
      this.toastr.error(Message_data.addbill);
      flag = false;
    }

    if (flag == true) {
      this.saveFlag = false;
      if (this.billListArray.length != 0) {
        for (var i = 0; i < this.billListArray.length; i++) {
          if (this.appoint == this.billListArray[i].diagid_name) {
            this.clientID = this.billListArray[i].client_reg_id;
            this.relationID = this.billListArray[i].relation_id;
            if (this.billListArray[i].sub_rel_id != undefined) {
              this.subRelationID = this.billListArray[i].sub_rel_id;
            }
            this.appID = this.billListArray[i].doc_app_id;
          }
        }
      }

      this.addvance = this.advance != undefined ? this.advance : "0";

      if (this.inGST == true && this.gstRetrivelArray.length != 0) {
        this.centralGST = this.gstRetrivelArray[0].tax;
        this.stateGST = this.gstRetrivelArray[1].tax;
      }

      Diagnosis_Helper.setbillcreate( this.finalAmount);
      var app_type;
      app_type = this.userType == "frontDesk" ? "frontdesk" : "diagnosis";

      var paid_flag;
      if( this.finalAmount == this.remaingBalance ){
        paid_flag = "Un paid";
      }else if (this.finalAmount != "0.00" && (this.addvance != "0.00")   &&  this.finalAmount != this.addvance){
        paid_flag = "Partially paid";
      }else if( this.remaingBalance == "0.00" || this.remaingBalance == "0" ){
        paid_flag = "Fully paid";
      }
      var billcreate_value =   JSON.stringify({
        diag_centre_id: this.diagCenterID,
        appointment_id: this.appID,
        app_type: app_type,
        client_reg_id: this.clientID,
        relation_id: this.relationID,
        sub_rel_id: this.subRelationID,
        bill_amount: encrypt_decript.Encript(Diagnosis_Helper.getbillcreate()).toString(),
        advance: this.addvance,
        balance: this.remaingBalance,
        prev_bal: this.balance,
        created_by: Diagnosis_Helper.getUserID_Type().user_id,
        cgst: this.centralGST,
        sgst: this.stateGST,
        bills: this.addBillArray,
        country: ipaddress.country_code,
        prev_bill_id: this.previousBillID,
        bill_amt:this.finalAmount,
        paid_amount:this.addvance,
        paid_flag : paid_flag,
        inpatient_flag : this.inpatientFlag,
        card_no : this.cardNumber,
        card_holder_name : this.cardHolderName,
        transaction_no : this.transactionID,
        bank_name: this.bankName,
        payments:this.paymentsarray,
      });
  
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/savebill/',billcreate_value,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj["result"] != null) {
              if (obj["result"] == "Successfully generated bill") {
                this.saveFlag = false;
                this.toastr.success(Message_data.billgen);
    
                if(Helper_Class.getIsAdmin() != undefined){
                  this.adminservice.sendMessage("diagBillingList");
                }else{
                  if(this.userType == "frontDesk")
                    this.frontdeskservice.sendMessage("frontdeskbilling");
                  else
                    this.diagnosisservice.sendMessage("billing");
                }
                 
              }else{
                this.saveFlag = true;
              }
            }
          },error => {
            this.saveFlag = true;
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }else{
      this.saveFlag = true;
    }
  }
 
  getPaymentType() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.payTypeArray = obj.payments;
          this.payType = this.payTypeArray[0].pay_id;
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changePayTypeReq() {
    this.insurFlag1 = true;
    this.tranFlag1 = true;
    this.cardFlag1 = true;
    this.chequeFlag1 = true;
    
    if(this.payType1 == "2"){
      this.tranFlag1 = false;
      this.cardFlag1 = false;
      this.insurFlag1=true;
      this.chequeFlag1=true;

    } else if (this.payType1 == "3"){
      this.tranFlag1 = true;
      this.cardFlag1 = true;
      this.insurFlag1=false;
      this.chequeFlag1=true;

    } else if(this.payType1 == "4"){
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;

    } else if(this.payType1 == "5") {
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;

    } else if(this.payType1 == "6"){
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;
      
    } else if(this.payType1 == "7") {
      this.tranFlag1 = true;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=false;
    } 
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insuranceFlag = true;
      this.tranFlag = true;
      this.cardFlag = true;
      this.chequeFlag = true;
      if (this.payType == "3") {
        this.insuranceFlag = false;
      } else if(this.payType == "4"){
        this.tranFlag = false;
      } else if(this.payType == "2"){
        this.tranFlag = false;
        this.cardFlag = false;
      }else if(this.payType == "5"){
        this.chequeFlag = false;
      }else {
        this.insuranceFlag = true;
      }
  }

  getInsurers() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerancListArray = obj.insurers;
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  backClicked() {
    console.log("Helper_Class.getIsAdmin()" + Helper_Class.getIsAdmin());
    if(this.userType == "frontDesk" && Helper_Class.getIsAdmin() == undefined){
      this.frontdeskservice.sendMessage("frontdeskbilling");
    } else if(Helper_Class.getIsAdmin() != undefined){
      this.adminservice.sendMessage("diagBillingList"); 
    } else{
      Diagnosis_Helper.setreceptbilledit(undefined);
      this.diagnosisservice.sendMessage('billing')
    }
  }
    
  OnDateChangedto(e){
    this.date = e;
    this.getLocation();
    this.getAppointmentIdDateBase(this.date);
    this.clearData();
  }

  clearData(){
    this.finalAmount = "0";
    this.amountGST = "0";
    this.advance = "0.00";
    this.concession = "0";
    this.testArray = [];
    this.gstRetrivelArray = [];
    this.estimateID = [];
    this.previousBalFlag = true;
    this.balanceFlag = true;
    this.clientName = "";
    this.amount = "";
    this.fees = "";
  }

  addPaymentDetails(){
    var bamt=0;
    for(var i=0;i<this.payTypeArray.length;i++){
      if(this.payType1 == this.payTypeArray[i].pay_id){
        if(this.paymentsarray.length != 0){
          for(var j=0;j<this.paymentsarray.length;j++){
            bamt+=parseInt(this.paymentsarray[j].amount);
          }
          bamt+=parseFloat(this.amountval.toString());
        } else {
          bamt+=parseFloat(this.amountval.toString());
        }
       
        if(bamt> parseInt(this.advance)){
          this.toastr.error("Amount cannot be more than paid amount")
        } else {
          this.paymentsarray.push({
            pay_id:this.payTypeArray[i].pay_id,
            pay_desc:this.payTypeArray[i].pay_desc,
            amount:this.amountval.toFixed(2)
          })
          this.amountval=0;
        }
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=data.amount;
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
