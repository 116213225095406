import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Helper_Class } from '../../../app/helper_class';
import { ipaddress } from '../../ipaddress';
import { Doc_Helper } from '../Doc_Helper';
//import { FrontDesk_Helper } from '../FrontDesk_Helper';
@Component({
  selector: 'app-bill-hist-view',
  templateUrl: './bill-hist-view.component.html',
  styleUrls: ['./bill-hist-view.component.scss']
})
export class BillHistViewComponent implements OnInit {
  public hospital_view: boolean;
  public hosp_name: any;
  public hoss_location: any;
  public hoss_citypin: any;
  public state_txt: any;
  public contact_no: any;
  public bill_no: string;
  public hosp_id:string;
  public bill_array;
  public userinfo;
  public bill_view_id;
  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<BillHistViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
      this.hospital_view = true;
    }

  ngOnInit() {
    this.userinfo = Helper_Class.getInfo();
    var billdetailview = Helper_Class.getbilllist();
    this.bill_view_id = Doc_Helper.getviewbills();
    
    for (var j = 0; j < billdetailview.hospitals.length; j++) {
      this.hosp_id = billdetailview.hospitals[j].hptl_clinic_id;
    }
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/ghbill/',{
        bill_no: this.bill_view_id,
        hptl_clinic_id: this.hosp_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.bill_array = obj.bills;
        });
  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
