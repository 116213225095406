<div *ngIf="neurologicalFlag =='min'">
    <div class="row">
        <div [hidden]="bodyTypeFlag" class="col-12">
            <mat-label class="matlabel">{{bodyTypeLabel}}</mat-label>
            <select class="ipcss" [(ngModel)]="bodyType">
                <option *ngFor="let btype of bodytTypeList"> {{btype.bodytype_name}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <p class="casesheet_para col-12"><b>Lateral</b></p>
    </div>
    <div class="row">
        <div [hidden]="hnFlag" class="col-12">
            <mat-label class="matlabel">{{hnLabel}}</mat-label>
            <select class="ipcss" [(ngModel)]="headNeck">
                <option *ngFor="let hntype of headNeckList"> {{hntype.name}}</option>
            </select>
        </div>
        <div [hidden]="shoulderFlag" class="col-12">
            <mat-label class="matlabel">{{shoulderLabel}}</mat-label>
            <select class="ipcss" [(ngModel)]="latShoulder">
                <option *ngFor="let shouler of shoulderList"> {{shouler.name}}</option>
            </select>
        </div>
    </div>
</div>
<div *ngIf="neurologicalFlag =='max'">
    <div class="container">
        <div class="row">
            <div [hidden]="bodyTypeFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{bodyTypeLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="bodyType">
                    <option *ngFor="let btype of bodytTypeList"> {{btype.bodytype_name}}</option>
                </select>
            </div>
        </div>
        <!-- Standing -->
        <p class="tervys_heading col-12">Standing</p>
        <div class="row">
            <p class="casesheet_para col-12"><b>Lateral</b></p>
        </div>
        <div class="row">
            <div [hidden]="hnFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{hnLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="headNeck">
                    <option *ngFor="let hntype of headNeckList"> {{hntype.name}}</option>
                </select>
            </div>
            <div [hidden]="shoulderFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{shoulderLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="latShoulder">
                    <option *ngFor="let shouler of shoulderList"> {{shouler.name}}</option>
                </select>
            </div>
            <div [hidden]="tvFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{tvLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="thoracic">
                    <option *ngFor="let thoracic of thoracicList"> {{thoracic.name}}</option>
                </select>
            </div>
            <div [hidden]="lmFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{lmLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="lumbar">
                    <option *ngFor="let lumbar of lumbarList"> {{lumbar.name}}</option>
                </select>
            </div>
            <div [hidden]="ankleFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{ankleLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="ankle">
                    <option *ngFor="let ankle of ankleList"> {{ankle.name}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <p class="casesheet_para col-12"><b>Anterior</b></p>
        </div>
        <div class="row">
            <div [hidden]="shoulderFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{shoulderLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="antShoulder">
                    <option *ngFor="let shouler of shoulderList"> {{shouler.name}}</option>
                </select>
            </div>
            <div [hidden]="elbowsFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{elbowsLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="antElbow">
                    <option *ngFor="let elbow of elbowList"> {{elbow.name}}</option>
                </select>
            </div>
            <div [hidden]="hipFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{hipLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="antHip">
                    <option *ngFor="let hip of hipList"> {{hip.name}}</option>
                </select>
            </div>
            <div [hidden]="kneeFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{kneeLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="antKnee">
                    <option *ngFor="let knee of kneeList"> {{knee.name}}</option>
                </select>
            </div>
            <div [hidden]="afFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{afLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="antAnkleFoot">
                    <option *ngFor="let anfoot of anfootList"> {{anfoot.name}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <p class="casesheet_para"><b>Posterior</b></p>
        </div>
        <div class="row">
            <div [hidden]="hnFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{hnLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="postHeadNeck">
                    <option *ngFor="let headneck of headNeckList"> {{headneck.name}}</option>
                </select>
            </div>
            <div [hidden]="trunkFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{trunkLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="postTrunk">
                    <option *ngFor="let trunk of trunkList"> {{trunk.name}}</option>
                </select>
            </div>
            <div [hidden]="phFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{phLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="postPelvis">
                    <option *ngFor="let pelvis of pelvisList"> {{pelvis.name}}</option>
                </select>
            </div>
            <div [hidden]="kneeFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{kneeLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="postKnee">
                    <option *ngFor="let pknee of postKneeList"> {{pknee.name}}</option>
                </select>
            </div>
            <div [hidden]="afFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <mat-label class="matlabel">{{afLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="postAnkleFoot">
                    <option *ngFor="let anf of postAnfootList"> {{anf.name}}</option>
                </select>
            </div>
        </div>
        <!-- Sitting -->
        <p class="tervys_heading col-12">Sitting</p>
        <div class="row">
            <div [hidden]="antFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{antLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="sitAnterior">
                    <option *ngFor="let sitting of sittingList"> {{sitting.name}}</option>
                </select>
            </div>
            <div [hidden]="latFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{latLabel}}</mat-label>
                <select class="ipcss" [(ngModel)]="sitLateral">
                    <option *ngFor="let sitting of sittingList"> {{sitting.name}}</option>
                </select>
            </div>
        </div>
        <!-- Lying -->
        <p class="tervys_heading col-12"><b>Lying</b></p>
        <div class="row">
            <p class="tervys_heading">Supine</p>
        </div>
        <div class="row">
            <div [hidden]="antFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{antLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="supAnt">
            </div>
            <div [hidden]="csFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{csLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="supCervical">
            </div>
            <div [hidden]="sgFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{sgLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="supShoulder">
            </div>
            <div [hidden]="amFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{amLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="supAbdomen">
            </div>
            <div [hidden]="waFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{waLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="supWaist">
            </div>
            <div [hidden]="asisFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{asisLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="supAsis">
            </div>
            <div [hidden]="lseFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{lseLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="supLumbar">
            </div>
        </div>
        <div class="row">
            <p class="casesheet_para"><b>Prone</b></p>
        </div>
        <div class="row">
            <div [hidden]="antFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{antLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="proAnt">
            </div>
            <div [hidden]="csFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{csLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="proCervical">
            </div>
            <div [hidden]="sgFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{sgLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="proShoulder">
            </div>
            <div [hidden]="psisFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{psisLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="proPsis">
            </div>
            <div [hidden]="glutealsFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{glutealsLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="proGluteals">
            </div>
            <div [hidden]="ptFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{ptLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="proPt">
            </div>
            <div [hidden]="calfFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{calfSupLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="proCalf">
            </div>
        </div>
        <p class="tervys_heading col-12">Fuctional Testing</p>
        <div class="row">
            <div [hidden]="antFlag" class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                <mat-label class="matlabel"><b>Slump Test:</b></mat-label>
            </div>
            <div [hidden]="sensitivityFlag" class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{sensitivityLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="sensitivity">
            </div>
            <div [hidden]="specificityFlag" class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{specificityLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="specificity">
            </div>
        </div>
        <div class="row">
            <div [hidden]="llmFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{llmLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="legLength">
            </div>
            <div [hidden]="twFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{twLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="tandemWalking">
            </div>
            <div [hidden]="brFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{brLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="boneRestrict">
            </div>
            <div [hidden]="strFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{strLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="softTissue">
            </div>
            <div [hidden]="romFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{romLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="rom">
            </div>
            <div [hidden]="rmlFlag" class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3">
                <mat-label class="matlabel">{{rmlLabel}}</mat-label>
                <input class="ipcss" matInput type="text" maxlength="50" [(ngModel)]="restMuscle">
            </div>
        </div>
    </div>
</div>