<div *ngIf="reportexp_flag == 'min'">
  <div *ngIf="blood_report_list.length != 0 || urine_report_list.length != 0 || feaces_report_list.length != 0
              || xray_report_list.length != 0 || scan_report_list.length != 0 || ultrasound_report_list.length != 0
              || documentList.length != 0">
    <p class="tervys_heading"
      *ngIf="blood_report_list.length != 0 || urine_report_list.length != 0 || feaces_report_list.length != 0 || documentList.length != 0">
      <b>Pathology</b>
    </p>
    <div [hidden]="blood_report_list.length == 0 && !documentBlood" style="margin-bottom: 2px;">
      <div class="">
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title ">
                Blood
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row" style="margin-top: 11px;">
              <div *ngFor="let blood_report of blood_report_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <mat-card (click)="report_view(blood_report.Diag_Test_Id,'Blood',blood_report.diag_appointment_id)"
                  class="CardList_border">
                  <mat-card-header></mat-card-header>
                  <mat-card-content>
                    <mat-label class="matlabel">{{blood_report.diag_test_name+" "+"test"}}</mat-label><br>
                  </mat-card-content>
                </mat-card><br>
              </div>
              <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              [hidden]="document.type != 'Blood report'">
              <mat-card (click)="document_view(document)" class="CardList_border">
                <mat-card-header></mat-card-header>
                <mat-card-content>
                  <mat-label class="matlabel">{{"Document : "+document.document_name+" "+document.document_date}}
                  </mat-label>
                  <br>
                </mat-card-content>
              </mat-card><br>
            </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div [hidden]="urine_report_list.length == 0 && !documentUrine" style="margin-bottom: 2px;">
      <div class="">
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title ">
                Urine
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row" style="margin-top: 11px;">
              <div *ngFor="let urine_report of urine_report_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <mat-card (click)="report_view(urine_report.Diag_Test_Id,'Urine',urine_report.diag_appointment_id)"
                  class="CardList_border">
                  <mat-card-header></mat-card-header>
                  <mat-card-content>
                    <mat-label>{{urine_report.diag_test_name+" "+"test"}}</mat-label><br>
                  </mat-card-content>
                </mat-card><br>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div [hidden]="feaces_report_list.length == 0 && !documentFaeces" style="margin-bottom: 2px;">
      <div class="">
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title ">
                Faeces
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row" style="margin-top: 11px;">
              <div *ngFor="let feaces_report of feaces_report_list"
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <mat-card (click)="report_view(feaces_report.Diag_Test_Id,'Faeces',feaces_report.diag_appointment_id)"
                  class="CardList_border">
                  <mat-card-header></mat-card-header>
                  <mat-card-content>
                    <mat-label>{{feaces_report.diag_test_name+" "+"test"}}</mat-label><br>
                  </mat-card-content>
                </mat-card><br>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <p class="tervys_heading"
      *ngIf="xray_report_list.length != 0 || scan_report_list.length != 0 || ultrasound_report_list.length != 0 || documentList.length != 0">
      <b>Radiology</b>
    </p>
    <div [hidden]="xray_report_list.length == 0 && !documentXray" style="margin-bottom: 2px;">
      <div class="">
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title ">
                X-ray
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row" style="margin-top: 11px;">
              <div *ngFor="let xray_report of xray_report_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <mat-card (click)="report_view(xray_report.Diag_Test_Id,'X-ray',xray_report.diag_appointment_id)"
                  class="CardList_border">
                  <mat-card-header></mat-card-header>
                  <mat-card-content>
                    <mat-label>{{xray_report.diag_test_name+" "+"test"}}</mat-label><br>
                  </mat-card-content>
                </mat-card><br>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div [hidden]="scan_report_list.length == 0 && !documentScan" style="margin-bottom: 2px;">
      <div class="">
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title ">
                Scans

              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row" style="margin-top: 11px;">
              <div *ngFor="let scan_report of scan_report_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <mat-card (click)="report_view(scan_report.Diag_Test_Id,'scan',scan_report.diag_appointment_id)"
                  class="CardList_border">
                  <mat-card-header></mat-card-header>
                  <mat-card-content>
                    <mat-label>{{scan_report.diag_test_name+" "+"test"}}</mat-label><br>
                  </mat-card-content>
                </mat-card><br>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div [hidden]="ultrasound_report_list.length == 0 && !documentUltrasound" style="margin-bottom: 2px;">
      <div class="">
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel ">
              <mat-panel-title class="title width">
                Ultra-Sound
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row" style="margin-top: 11px;">
              <div *ngFor="let Ultra_report of ultrasound_report_list"
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <mat-card
                  (click)="report_view(Ultra_report.Diag_Test_Id,'ultra-sound',Ultra_report.diag_appointment_id)"
                  class="List_border">
                  <mat-card-header></mat-card-header>
                  <mat-card-content>
                    <mat-label>{{Ultra_report.diag_test_name+" "+"test"}}</mat-label><br>
                  </mat-card-content>
                </mat-card><br>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div *ngIf="blood_report_list.length == 0 && urine_report_list.length == 0 && feaces_report_list.length == 0 && 
  xray_report_list.length == 0 && scan_report_list.length == 0 && ultrasound_report_list.length == 0 && documentList.length == 0">
    <div class="nodata">No Test Reports Found</div>
  </div>
</div>

<div *ngIf="reportexp_flag == 'max'">
  <a *ngIf="report_detail_flag" (click)="back_Nav()"><img
    src="../../../assets/ui_icons/pres_list_view.svg" style="position: absolute; top: 7px; right: 61px; width: 20px;" /></a>

  <div class="row">
    <div *ngIf="!report_detail_flag" class="Details">
      <div class="row">
        <div class="col-12" style="margin-top: 13px;text-align: right;">
          <a (click)="uploadDocument()"> <span class="excr_confirm matlabel"><i class="fa fa-upload"
                aria-hidden="true"></i>
              <strong style="padding-left: 5px;">Document Upload </strong>
            </span></a>
        </div>
      </div>
      <div *ngIf="blood_report_list.length != 0 || urine_report_list.length != 0 || feaces_report_list.length != 0
      || xray_report_list.length != 0 || scan_report_list.length != 0 || ultrasound_report_list.length != 0 || documentList.length != 0">

        <p class="tervys_heading"
          *ngIf="blood_report_list.length != 0 || urine_report_list.length != 0 || feaces_report_list.length != 0 || documentList.length != 0">
          <b>Pathology</b>
        </p>
        <div [hidden]="blood_report_list.length == 0 && !documentBlood" style="margin-bottom: 5px;">
          <div class="">
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel">
                <mat-expansion-panel-header class="exppanel width">
                  <mat-panel-title class="title ">
                    Blood
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="margin-top: 11px;">
                  <div *ngFor="let blood_report of blood_report_list"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <mat-card (click)="report_view(blood_report.Diag_Test_Id,'Blood',blood_report.diag_appointment_id)"
                      class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label class="matlabel">{{blood_report.diag_test_name+" "+"test"}}</mat-label><br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                  <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                    [hidden]="document.type != 'Blood report'">
                    <mat-card (click)="document_view(document)" class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label class="matlabel">{{"Document : "+document.document_name+" "+document.document_date}}
                        </mat-label>
                        <br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div [hidden]="urine_report_list.length == 0 && !documentUrine" style="margin-bottom: 5px;">
          <div class="">
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel">
                <mat-expansion-panel-header class="exppanel width">
                  <mat-panel-title class="title ">
                    Urine
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="margin-top: 11px;">
                  <div *ngFor="let urine_report of urine_report_list"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <mat-card (click)="report_view(urine_report.Diag_Test_Id,'Urine',urine_report.diag_appointment_id)"
                      class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label>{{urine_report.diag_test_name+" "+"test"}}</mat-label><br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                </div>
                <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  [hidden]="document.type != 'Urine report'">
                  <mat-card (click)="document_view(document)" class="CardList_border">
                    <mat-card-header></mat-card-header>
                    <mat-card-content>
                      <mat-label class="matlabel">{{"Document : "+document.document_name+"
                        "+document.document_date}}</mat-label>
                      <br>
                    </mat-card-content>
                  </mat-card><br>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div [hidden]="feaces_report_list.length == 0 && !documentFaeces" style="margin-bottom: 5px;">
          <div class="">
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel">
                <mat-expansion-panel-header class="exppanel width">
                  <mat-panel-title class="title ">
                    Faeces
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="margin-top: 11px;">
                  <div *ngFor="let feaces_report of feaces_report_list"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <mat-card
                      (click)="report_view(feaces_report.Diag_Test_Id,'Faeces',feaces_report.diag_appointment_id)"
                      class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label>{{feaces_report.diag_test_name+" "+"test"}}</mat-label><br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                  <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                    [hidden]="document.type != 'Stool report'">
                    <mat-card (click)="document_view(document)" class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label class="matlabel">{{"Document : "+document.document_name+"
                          "+document.document_date}}</mat-label>
                        <br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <p class="tervys_heading"
          *ngIf="xray_report_list.length != 0 || scan_report_list.length != 0 || ultrasound_report_list.length != 0 || documentList.length != 0">
          <b>Radiology</b>
        </p>
        <div [hidden]="xray_report_list.length == 0 && !documentXray" style="margin-bottom: 5px;">
          <div class="">
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel">
                <mat-expansion-panel-header class="exppanel width">
                  <mat-panel-title class="title ">
                    X-ray
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="margin-top: 11px;">
                  <div *ngFor="let xray_report of xray_report_list"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <mat-card (click)="report_view(xray_report.Diag_Test_Id,'X-ray',xray_report.diag_appointment_id)"
                      class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label>{{xray_report.diag_test_name+" "+"test"}}</mat-label><br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                  <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                    [hidden]="document.type != 'X-ray report'">
                    <mat-card (click)="document_view(document)" class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label class="matlabel">{{"Document : "+document.document_name+"
                          "+document.document_date}}</mat-label>
                        <br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div [hidden]="scan_report_list.length == 0 && !documentScan" style="margin-bottom: 5px;">
          <div class="">
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel">
                <mat-expansion-panel-header class="exppanel width">
                  <mat-panel-title class="title ">
                    Scans

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="margin-top: 11px;">
                  <div *ngFor="let scan_report of scan_report_list"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <mat-card (click)="report_view(scan_report.Diag_Test_Id,'scans',scan_report.diag_appointment_id)"
                      class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label>{{scan_report.diag_test_name+" "+"test"}}</mat-label><br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                  <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                    [hidden]="document.type != 'Scan report'">
                    <mat-card (click)="document_view(document)" class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label class="matlabel">{{"Document : "+document.document_name+"
                          "+document.document_date}}</mat-label>
                        <br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div [hidden]="ultrasound_report_list.length == 0 && !documentUltrasound" style="margin-bottom: 5px;">
          <div class="">
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel">
                <mat-expansion-panel-header class="exppanel ">
                  <mat-panel-title class="title width">
                    Ultra-Sound
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="margin-top: 11px;">
                  <div *ngFor="let Ultra_report of ultrasound_report_list"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <mat-card
                      (click)="report_view(Ultra_report.Diag_Test_Id,'ultra-sound',Ultra_report.diag_appointment_id)"
                      class="List_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label>{{Ultra_report.diag_test_name+" "+"test"}}</mat-label><br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                  <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                    [hidden]="document.type != 'Ulta-sound report'">
                    <mat-card (click)="document_view(document)" class="CardList_border">
                      <mat-card-header></mat-card-header>
                      <mat-card-content>
                        <mat-label class="matlabel">{{"Document : "+document.document_name+"
                          "+document.document_date}}</mat-label>
                        <br>
                      </mat-card-content>
                    </mat-card><br>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
      <div
        *ngIf="blood_report_list.length == 0 && urine_report_list.length == 0 && feaces_report_list.length == 0 && 
      xray_report_list.length == 0 && scan_report_list.length == 0 && ultrasound_report_list.length == 0 && documentList.length == 0">
        <div class="nodata">No Test Reports Found</div>
      </div>

    </div>
    <div *ngIf="report_detail_flag" class="Details"><br>
      <div *ngIf="!report_detail_dig_flag">
        <div class="row">
          <div class="col-12 col-sm-12 col-xl-5 col-lg-5 col-md-5">
            <mat-card id="cardcontent">
              <mat-card-header id="cardheader">Address Details</mat-card-header>
              <mat-card-content>
                <div class="container">
                  <div class="row">
                    <div class="col-12 p-0">
                      <mat-label class="matlabel"><strong class="hosp_header">{{diag_name}}</strong></mat-label>
                    </div>
                    <div class="col-12 p-0">
                      <mat-label class="matlabel">{{diag_add}},{{diag_loc}}</mat-label>
                    </div>
                    <div class="col-12 p-0">
                      <mat-label class="matlabel">{{diag_cityzip}},</mat-label>
                    </div>
                    <div class="col-12 p-0">
                      <mat-label class="matlabel">{{diag_statecon}}</mat-label>
                    </div>
                    <div class="col-12 p-0">
                      <mat-label class="matlabel">Mobile No:{{diag_teleph}}</mat-label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-xl-7 col-lg-7 col-md-7">
            <mat-card id="cardcontent">
              <mat-card-header id="cardheader">Client Details</mat-card-header>
              <mat-card-content style="padding: 10px 0;">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0">
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Client id :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{clent_id}}</mat-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Name :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{client_another_name}}</mat-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Age :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{client_age}}</mat-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Gender :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{client_gender}}</mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Date :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{date_txt}}</mat-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Referred by :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{diag_refertxt}}</mat-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Collected at :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{time_collect}}</mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div [hidden]="blood_urine_faeces_div">
          <p class="tervys_heading "><b>Clinical Test Results</b></p>
          <div class="row">
            <div class="col-12" *ngFor="let test of second_opinion_mintlist">
              <!-- <mat-label class="matlabel"><strong>{{test.test_name+" test"}}</strong></mat-label> -->
              <table id="card_tbl" class="table table-hover table-dynamic" style="width: 90%;margin-left: 20px;">
                <thead>
                  <tr>
                    <th>Test Name</th>
                    <th>Reading</th>
                    <th>Unit</th>
                    <th>Referred value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="4"><b>{{test.test_name+" test"}}</b></td>
                  </tr>
                  <tr *ngFor="let subtest of second_opinion_subtlist">
                    <td><strong>{{subtest.sub_test_name}}</strong></td>
                    <td>{{subtest.reading}}</td>
                    <td>{{subtest.unit}}</td>
                    <td>
                      <!-- {{subtest.test_range}} -->
                      <div class="matlabel" id="viewhtml" [innerHtml]="subtest.test_range"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div [hidden]="xray_scan_ultra_div" style="margin-top: 5px;">
          <!-- <a (click)="imageSave()" ><span class="cardsave"><img src="../../../assets/img/save.svg" style="width:20px" style="width:20px" /></span> </a> -->
          <div *ngFor="let report of second_opinion_mintlist1">
            <div class="row">
              <div class="col-12 col-xl-12 col-lg-12 col-sm-12">
                <mat-label class="matlabel"><strong class="other_header">Report type :</strong> {{report_type}}
                </mat-label>
              </div>
              <div class="col-12 col-xl-12 col-lg-12 col-sm-12">
                <mat-label class="matlabel"><strong class="other_header">Test name :</strong> {{report.test_name}}
                </mat-label>
              </div>
            </div>
            <div class="row" style="margin-top: 5px;">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="seq_heading">Sequences</div>
                <div class="seq_content">
                  <div class="matlabel" [innerHtml]="report.sequences"></div>
                  <!-- {{report.sequences}} -->
                </div>
                <div class="seq_heading">Impression</div>
                <div class="seq_content">
                  <div class="matlabel" [innerHtml]="report.impression"></div>
                  <!-- {{report.impression}} -->
                </div>
                <!-- <table id="report_radiology_tbl">
                  <tr>
                    <th>Sequences</th>
                  </tr>
                  <tr>
                    <td>{{report.sequences}}</td>
                  </tr><br>
                  <tr>
                    <th>Impression</th>
                  </tr>
                  <tr>
                    <td>{{report.impression}}</td>
                  </tr>
                </table> -->
              </div>
              <div *ngIf="report_img_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <mdb-card>
                  <mdb-card-body class=" p-0 inverted">
                    <div class="bg-white">
                      <div class="d-flex justify-content-between">
                        <div class="d-flex">
                          <div *ngFor="let image of report_img">
                            <img src="{{image.image}}" id="report_img" class="selectImage img-thumbnail m-1"
                              style="width: 100px; height: 70px;" />
                          </div>
                          <!-- <div class="position-relative">
                            <div id="tool_import" class="menu_item import_btn" style="display: none;">
                              <div id="fileinputs_import"></div>
                              <img src="../../../assets/dist/images/add-image.png" />
                            </div>
                          </div> -->
                        </div>
                        <!-- <div></div> -->
                      </div>
                    </div>

                    <div id="svgeditor" class="svgeditor">

                      <div id="svg_editor">
                        <div id="rulers">
                          <div id="ruler_corner"></div>
                          <div id="ruler_x">
                            <div id="ruler_x_cursor"></div>
                            <div>
                              <canvas height="15"></canvas>
                            </div>
                          </div>
                          <div id="ruler_y">
                            <div id="ruler_y_cursor"></div>
                            <div>
                              <canvas width="15"></canvas>
                            </div>
                          </div>
                        </div>

                        <div id="workarea">
                          <div id="svgcanvas" style="position:relative">

                          </div>
                        </div>

                        <div id="menu_bar">
                          <!-- <a class="menu">
                              <div class="menu_title" id="logo">
                                <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M-.1 16.1L16 .04V16.1H-.1z" fill="#ccc"/>
                                  <path d="M0 16.1V.1l16 16H0z" fill="#666"/>
                                </svg>
                              </div>
                              <div class="menu_list">
                                <div id="tool_about" class="menu_item">About this Editor...</div>
                                <div class="separator"></div>
                                <div id="tool_about" class="menu_item">Keyboard Shortcuts...</div>
                              </div>
                            </a> -->
                          <div class="menu">
                            <div id="file_menu">
                              <div class="menu_title">
                                <!-- <div id="tool_open" class="menu_item" style="display: none;" title="Open">
                                  <div id="fileinputs"></div><img src="../../../assets/dist/images/folder.png" />
                                </div> -->
                              </div>
                              <div class="menu_title">
                                <div id="" class="menu_item" title="Export as SVG">
                                  <img (click)="savesend_data()" src="../../../assets/img/save.svg" style="width:20px"
                                    style="width:20px" />
                                </div>
                              </div>
                              <div class="menu_title">
                                <div id="tool_export" class="menu_item" title="Export as PNG">
                                  <img src="../../../assets/dist/images/png.png" />
                                </div>
                              </div>
                              <div class="menu_title">
                                <div id="tool_clear" class="menu_item" title="Clear"><img
                                    src="../../../assets/dist/images/cancel.png" /></div>
                              </div>
                            </div>
                          </div>
                          <div class="menu">
                            <div id="edit_menu">
                              <div class="menu_title">
                                <div class="menu_item" id="tool_undo" title="Undo"><img
                                    src="../../../assets/dist/images/undo.png" /></div>
                              </div>
                              <div class="menu_title">
                                <div class="menu_item" id="tool_redo" title="Redo"><img
                                    src="../../../assets/dist/images/redo.png" /></div>
                              </div>
                              <div class="menu_title">
                                <div class="menu_item action_selected disabled" id="tool_cut" title="Cut"><img
                                    src="../../../assets/dist/images/scissors.png" /></div>
                              </div>
                              <div class="menu_title">
                                <div class="menu_item action_selected disabled" id="tool_copy" title="Copy"><img
                                    src="../../../assets/dist/images/copy.png" /></div>
                              </div>
                              <div class="menu_title">
                                <div class="menu_item action_selected disabled" id="tool_paste" title="Paste"><img
                                    src="../../../assets/dist/images/paste.png" /></div>
                              </div>
                              <div class="menu_title">
                                <div class="menu_item action_selected disabled" id="tool_clone" title="Clone"><img
                                    src="../../../assets/dist/images/rubber-stamp.png" /></div>
                              </div>
                              <div class="menu_title">
                                <div class="menu_item action_selected disabled" id="tool_delete" title="Delete"><img
                                    src="../../../assets/dist/images/delete.png" /></div>
                              </div>
                            </div>
                          </div>
                          <div class="menu">
                            <div id="view_menu">
                              <div class="menu_title">
                                <div class="menu_item push_button_pressed" id="tool_rulers" title="View Rulers"><img
                                    src="../../../assets/dist/images/ruler.png" /></div>
                              </div>
                              <div class="menu_title">
                                <div class="menu_item" id="tool_wireframe" title="View Wireframe"><img
                                    src="../../../assets/dist/images/wireframe.png" /></div>
                              </div>
                              <!-- <div class="menu_title">
                                  <div class="menu_item" id="tool_snap">Snap to Grid</div>
                                </div> -->
                            </div>
                          </div>
                          <div class="menu">
                            <div class="menu_title">
                              <!-- Zoom buttons -->
                              <div id="zoom_panel" class="toolset" title="Change zoom level">
                                <div class="draginput select" id="zoom_label">
                                  <span id="zoomLabel" class="zoom_tool icon_label">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 2 20 20" width="27">
                                      <path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                    </svg>
                                  </span>
                                  <select id="zoom_select">
                                    <option value="6">6%</option>
                                    <option value="12">12%</option>
                                    <option value="16">16%</option>
                                    <option value="25">25%</option>
                                    <option value="50">50%</option>
                                    <option value="75">75%</option>
                                    <option value="100" selected="selected">100%</option>
                                    <option value="150">150%</option>
                                    <option value="200">200%</option>
                                    <option value="300">300%</option>
                                    <option value="400">400%</option>
                                    <option value="600">600%</option>
                                    <option value="800">800%</option>
                                    <option value="1600">1600%</option>
                                  </select>
                                  <div class="caret"></div>
                                  <input id="zoom" size="3" value="100%" type="text" readonly="readonly" />
                                </div>
                              </div>
                            </div>

                          </div>
                          <!-- <div class="menu">
                              <div class="menu_title">File</div>
                              <div class="menu_list" id="file_menu"> 
                                <div id="tool_open" class="menu_item" style="display: none;"><div id="fileinputs"></div>Open SVG...</div>
                                <div id="tool_import" class="menu_item" style="display: none;"><div id="fileinputs_import"></div>Import Image...</div>
                                <div id="tool_save" class="menu_item">Save <span class="shortcut">⌘S</span></div>
                                <div id="tool_clear" class="menu_item">Clear</div>
                                <div id="tool_export" class="menu_item">Export as PNG</div>
                              </div>
                            </div> -->

                          <!-- <div class="menu">
                              <div class="menu_title">Edit</div>
                              <div class="menu_list" id="edit_menu">
                                <div class="menu_item" id="tool_undo">Undo <span class="shortcut">⌘Z</span></div>
                                <div class="menu_item" id="tool_redo">Redo <span class="shortcut">⌘Y</span></div>
                                <div class="separator"></div>
                                <div class="menu_item action_selected disabled" id="tool_cut">Cut <span class="shortcut">⌘X</span></div>
                                <div class="menu_item action_selected disabled" id="tool_copy">Copy <span class="shortcut">⌘C</span></div>
                                <div class="menu_item action_selected disabled" id="tool_paste">Paste <span class="shortcut">⌘V</span></div>
                                <div class="menu_item action_selected disabled" id="tool_clone">Duplicate <span class="shortcut">⌘D</span></div>
                                <div class="menu_item action_selected disabled" id="tool_delete">Delete <span>⌫</span></div>
                              </div>
                            </div> -->

                          <!-- <div class="menu">
                              <div class="menu_title">Object</div>
                              <div class="menu_list"  id="object_menu">
                                <div class="menu_item action_selected disabled" id="tool_move_top">Bring to Front <span class="shortcut">⌘⇧↑</span></div>
                                <div class="menu_item action_selected disabled" id="tool_move_up">Bring Forward <span class="shortcut">⌘↑</span></div>
                                <div class="menu_item action_selected disabled" id="tool_move_down">Send Backward <span class="shortcut">⌘↓</span></div>
                                <div class="menu_item action_selected disabled" id="tool_move_bottom">Send to Back <span class="shortcut">⌘⇧↓</span></div>
                                <div class="separator"></div>
                                <div class="menu_item action_multi_selected disabled" id="tool_group">Group Elements <span class="shortcut">⌘G</span></div>
                                <div class="menu_item action_group_selected disabled" id="tool_ungroup">Ungroup Elements <span class="shortcut">⌘⇧G</span></div>
                                <div class="separator"></div>
                                <div class="menu_item action_path_convert_selected disabled" id="tool_topath">Convert to Path</div>
                                <div class="menu_item action_path_selected disabled" id="tool_reorient">Reorient path</div>
                              </div>
                            </div> -->

                          <!-- <div class="menu">
                              <div class="menu_title">View</div>
                              <div class="menu_list" id="view_menu">
                                  <div class="menu_item push_button_pressed" id="tool_rulers">View Rulers</div>
                                  <div class="menu_item" id="tool_wireframe">View Wireframe</div>
                                  <div class="menu_item" id="tool_snap">Snap to Grid</div>
                                  <div class="separator"></div>
                                  <div class="menu_item" id="tool_source">Source... <span class="shortcut">⌘U</span></div>
                              </div>
                            </div> -->
                          <div>
                            <div class="toggle_full_screen menu_title">
                              <i class="fa fa-arrows-alt"></i>
                            </div>
                          </div>
                        </div>

                        <div id="tools_top" class="tools_panel">

                          <div id="canvas_panel" class="context_panel">

                            <h4 class="clearfix">Canvas</h4>

                            <label data-title="Change canvas width">
                              <input size="3" id="canvas_width" type="text" pattern="[0-9]*" />
                              <span class="icon_label">Width</span>
                            </label>
                            <label data-title="Change canvas height">
                              <input id="canvas_height" size="3" type="text" pattern="[0-9]*" />
                              <span class="icon_label">Height</span>
                            </label>


                            <label data-title="Change canvas color" class="draginput">
                              <span>Color</span>
                              <div id="color_canvas_tools">
                                <div class="color_tool active" id="tool_canvas">
                                  <div class="color_block">
                                    <div id="canvas_bg"></div>
                                    <div id="canvas_color"></div>
                                  </div>
                                </div>
                              </div>
                            </label>

                            <div class="draginput">
                              <span>Sizes</span>
                              <select id="resolution">
                                <option id="selectedPredefined" selected="selected">Custom</option>
                                <option>640x480</option>
                                <option>800x600</option>
                                <option>1024x768</option>
                                <option>1280x960</option>
                                <option>1600x1200</option>
                                <option id="fitToContent" value="content">Fit to Content</option>
                              </select>
                              <div class="caret"></div>
                              <label id="resolution_label">Custom</label>
                            </div>

                          </div>

                          <div id="rect_panel" class="context_panel">
                            <h4 class="clearfix">Rectangle</h4>
                            <label>
                              <input id="rect_x" class="attr_changer" data-title="Change X coordinate" size="3"
                                data-attr="x" pattern="[0-9]*" />
                              <span>X</span>
                            </label>
                            <label>
                              <input id="rect_y" class="attr_changer" data-title="Change Y coordinate" size="3"
                                data-attr="y" pattern="[0-9]*" />
                              <span>Y</span>
                            </label>
                            <label id="rect_width_tool attr_changer" data-title="Change rectangle width">
                              <input id="rect_width" class="attr_changer" size="3" data-attr="width" type="text"
                                pattern="[0-9]*" />
                              <span class="icon_label">Width</span>
                            </label>
                            <label id="rect_height_tool" data-title="Change rectangle height">
                              <input id="rect_height" class="attr_changer" size="3" data-attr="height" type="text"
                                pattern="[0-9]*" />
                              <span class="icon_label">Height</span>
                            </label>
                          </div>

                          <div id="path_panel" class="context_panel clearfix">
                            <h4 class="clearfix">Path</h4>
                            <label>
                              <input id="path_x" class="attr_changer" data-title="Change ellipse's cx coordinate"
                                size="3" data-attr="x" pattern="[0-9]*" />
                              <span>X</span>
                            </label>
                            <label>
                              <input id="path_y" class="attr_changer" data-title="Change ellipse's cy coordinate"
                                size="3" data-attr="y" pattern="[0-9]*" />
                              <span>Y</span>
                            </label>
                          </div>

                          <div id="image_panel" class="context_panel clearfix">
                            <h4>Image</h4>
                            <label>
                              <input id="image_x" class="attr_changer" data-title="Change X coordinate" size="3"
                                data-attr="x" pattern="[0-9]*" />
                              <span>X</span>
                            </label>
                            <label>
                              <input id="image_y" class="attr_changer" data-title="Change Y coordinate" size="3"
                                data-attr="y" pattern="[0-9]*" />
                              <span>Y</span>
                            </label>
                            <label>
                              <input id="image_width" class="attr_changer" data-title="Change image width" size="3"
                                data-attr="width" pattern="[0-9]*" />
                              <span class="icon_label">Width</span>
                            </label>
                            <label>
                              <input id="image_height" class="attr_changer" data-title="Change image height" size="3"
                                data-attr="height" pattern="[0-9]*" />
                              <span class="icon_label">Height</span>
                            </label>
                          </div>

                          <div id="circle_panel" class="context_panel">
                            <h4>Circle</h4>
                            <label id="tool_circle_cx">
                              <span>Center X</span>
                              <input id="circle_cx" class="attr_changer" title="Change circle's cx coordinate" size="3"
                                data-attr="cx" />
                            </label>
                            <label id="tool_circle_cy">
                              <span>Center Y</span>
                              <input id="circle_cy" class="attr_changer" title="Change circle's cy coordinate" size="3"
                                data-attr="cy" />
                            </label>
                            <label id="tool_circle_r">
                              <span>Radius</span>
                              <input id="circle_r" class="attr_changer" title="Change circle's radius" size="3"
                                data-attr="r" />
                            </label>
                          </div>

                          <div id="ellipse_panel" class="context_panel clearfix">
                            <h4>Ellipse</h4>
                            <label id="tool_ellipse_cx">
                              <input id="ellipse_cx" class="attr_changer" data-title="Change ellipse's cx coordinate"
                                size="3" data-attr="cx" pattern="[0-9]*" />
                              <span>X</span>
                            </label>
                            <label id="tool_ellipse_cy">
                              <input id="ellipse_cy" class="attr_changer" data-title="Change ellipse's cy coordinate"
                                size="3" data-attr="cy" pattern="[0-9]*" />
                              <span>Y</span>
                            </label>
                            <label id="tool_ellipse_rx">
                              <input id="ellipse_rx" class="attr_changer" data-title="Change ellipse's x radius"
                                size="3" data-attr="rx" pattern="[0-9]*" />
                              <span>Radius X</span>
                            </label>
                            <label id="tool_ellipse_ry">
                              <input id="ellipse_ry" class="attr_changer" data-title="Change ellipse's y radius"
                                size="3" data-attr="ry" pattern="[0-9]*" />
                              <span>Radius Y</span>
                            </label>
                          </div>

                          <div id="line_panel" class="context_panel clearfix">
                            <h4>Line</h4>
                            <label id="tool_line_x1">
                              <input id="line_x1" class="attr_changer" data-title="Change line's starting x coordinate"
                                size="3" data-attr="x1" pattern="[0-9]*" />
                              <span>Start X</span>
                            </label>
                            <label id="tool_line_y1">
                              <input id="line_y1" class="attr_changer" data-title="Change line's starting y coordinate"
                                size="3" data-attr="y1" pattern="[0-9]*" />
                              <span>Start Y</span>
                            </label>
                            <label id="tool_line_x2">
                              <input id="line_x2" class="attr_changer" data-title="Change line's ending x coordinate"
                                size="3" data-attr="x2" pattern="[0-9]*" />
                              <span>End X</span>
                            </label>
                            <label id="tool_line_y2">
                              <input id="line_y2" class="attr_changer" data-title="Change line's ending y coordinate"
                                size="3" data-attr="y2" pattern="[0-9]*" />
                              <span>End Y</span>
                            </label>
                          </div>

                          <div id="text_panel" class="context_panel">
                            <h4>Text</h4>
                            <label>
                              <input id="text_x" class="attr_changer" data-title="Change text x coordinate" size="3"
                                data-attr="x" pattern="[0-9]*" />
                              <span>X</span>
                            </label>
                            <label>
                              <input id="text_y" class="attr_changer" data-title="Change text y coordinate" size="3"
                                data-attr="y" pattern="[0-9]*" />
                              <span>Y</span>
                            </label>

                            <div class="toolset draginput select twocol" id="tool_font_family">
                              <!-- Font family -->
                              <span>Font</span>
                              <div id="preview_font" style="font-family: Helvetica, Arial, sans-serif;">Helvetica</div>
                              <div class="caret"></div>
                              <input id="font_family" data-title="Change Font Family" size="12" type="hidden" />
                              <select id="font_family_dropdown">
                                <option value="Arvo, sans-serif">Arvo</option>
                                <option value="'Courier New', Courier, monospace">Courier</option>
                                <option value="Euphoria, sans-serif">Euphoria</option>
                                <option value="Georgia, Times, 'Times New Roman', serif">Georgia</option>
                                <option value="Helvetica, Arial, sans-serif" selected="selected">Helvetica</option>
                                <option value="Junction, sans-serif">Junction</option>
                                <option value="'League Gothic', sans-serif">League Gothic</option>
                                <option value="Oswald, sans-serif">Oswald</option>
                                <option value="'Palatino Linotype', 'Book Antiqua', Palatino, serif">Palatino</option>
                                <option value="'Trebuchet MS', Gadget, sans-serif">Trebuchet</option>
                                <option value="'Shadows Into Light', serif">Shadows Into Light</option>
                                <option value="'Simonetta', serif">Simonetta</option>
                                <option value="'Times New Roman', Times, serif">Times</option>
                              </select>
                              <div class="tool_button" id="tool_bold" data-title="Bold Text [B]">B</div>
                              <div class="tool_button" id="tool_italic" data-title="Italic Text [I]">i</div>
                            </div>

                            <label id="tool_font_size" data-title="Change Font Size">
                              <input id="font_size" size="3" value="0" />
                              <span id="font_sizeLabel" class="icon_label">Font Size</span>
                            </label>
                            <!-- Not visible, but still used -->
                            <input id="text" type="text" size="35" />
                          </div>

                          <!-- formerly gsvg_panel -->
                          <div id="container_panel" class="context_panel clearfix">
                          </div>

                          <div id="use_panel" class="context_panel clearfix">
                            <div class="tool_button clearfix" id="tool_unlink_use"
                              data-title="Break link to reference element (make unique)">Break link reference</div>
                          </div>

                          <div id="g_panel" class="context_panel clearfix">
                            <h4>Group</h4>
                            <label>
                              <input id="g_x" class="attr_changer" data-title="Change groups's x coordinate" size="3"
                                data-attr="x" pattern="[0-9]*" />
                              <span>X</span>
                            </label>
                            <label>
                              <input id="g_y" class="attr_changer" data-title="Change groups's y coordinate" size="3"
                                data-attr="y" pattern="[0-9]*" />
                              <span>Y</span>
                            </label>
                          </div>

                          <div id="path_node_panel" class="context_panel clearfix">
                            <h4>Edit Path</h4>

                            <label id="tool_node_x">
                              <input id="path_node_x" class="attr_changer" data-title="Change node's x coordinate"
                                size="3" data-attr="x" />
                              <span>X</span>
                            </label>
                            <label id="tool_node_y">
                              <input id="path_node_y" class="attr_changer" data-title="Change node's y coordinate"
                                size="3" data-attr="y" />
                              <span>Y</span>
                            </label>

                            <div id="segment_type" class="draginput label">
                              <span>Segment Type</span>
                              <select id="seg_type" data-title="Change Segment type">
                                <option id="straight_segments" selected="selected" value="4">Straight</option>
                                <option id="curve_segments" value="6">Curve</option>
                              </select>
                              <div class="caret"></div>
                              <label id="seg_type_label">Straight</label>
                            </div>

                            <!--
                              <label class="draginput checkbox" data-title="Link Control Points">
                                <span>Linked Control Points</span>
                                <div class="push_bottom"><input type="checkbox" id="tool_node_link" checked="checked" /></div>
                              </label>
                            -->

                            <div class="clearfix"></div>
                            <div class="tool_button" id="tool_node_clone" title="Adds a node">Add Node</div>
                            <div class="tool_button" id="tool_node_delete" title="Delete Node">Delete Node</div>
                            <div class="tool_button" id="tool_openclose_path" title="Open/close sub-path">Open Path
                            </div>
                            <!--<div class="tool_button" id="tool_add_subpath" title="Add sub-path"></div>-->
                          </div>

                          <!-- Buttons when a single element is selected -->
                          <div id="selected_panel" class="context_panel">

                            <label id="tool_angle" data-title="Change rotation angle" class="draginput">
                              <input id="angle" class="attr_changer" size="2" value="0" data-attr="transform"
                                data-min="-180" data-max="180" type="text" />
                              <span class="icon_label">Rotation</span>
                              <div id="tool_angle_indicator">
                                <div id="tool_angle_indicator_cursor"></div>
                              </div>
                            </label>

                            <label class="toolset" id="tool_opacity" data-title="Change selected item opacity">
                              <input id="group_opacity" class="attr_changer" data-attr="opacity" data-multiplier="0.01"
                                size="3" value="100" step="5" min="0" max="100" />
                              <span id="group_opacityLabel" class="icon_label">Opacity</span>
                            </label>

                            <div class="toolset" id="tool_blur" data-title="Change gaussian blur value">
                              <label>
                                <input id="blur" size="2" value="0" step=".1" min="0" max="10" />
                                <span class="icon_label">Blur</span>
                              </label>
                            </div>

                            <label id="cornerRadiusLabel" data-title="Change Rectangle Corner Radius">
                              <input id="rect_rx" size="3" value="0" data-attr="rx" class="attr_changer" type="text"
                                pattern="[0-9]*" />
                              <span class="icon_label">Roundness</span>
                            </label>

                            <div class="clearfix"></div>
                            <div id="align_tools">
                              <h4>Align</h4>
                              <div class="toolset align_buttons" id="tool_position">
                                <label>
                                  <div class="col last clear" id="position_opts">
                                    <div class="draginput_cell" id="tool_posleft" title="Align Left">
                                      <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                        height="27">
                                        <path
                                          d="M 2 1 L 2 5 L 14 5 L 14 11 L 2 11 L 2 16 L 20 16 L 20 22 L 2 22 L 2 26 L 1 26 L 1 1 L 2 1 Z" />
                                      </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_poscenter" title="Align Center">
                                      <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                        height="27">
                                        <path
                                          d="M 13 1 L 14 1 L 14 6 L 22 6 L 22 12 L 14 12 L 14 15 L 19 15 L 19 21 L 14 21 L 14 26 L 13 26 L 13 21 L 8 21 L 8 15 L 13 15 L 13 12 L 5 12 L 5 6 L 13 6 L 13 1 Z" />
                                      </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_posright" title="Align Right">
                                      <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                        height="27">
                                        <path
                                          d="M 25 1 L 25 5 L 13 5 L 13 11 L 25 11 L 25 16 L 7 16 L 7 22 L 25 22 L 25 26 L 26 26 L 26 1 L 25 1 Z" />
                                      </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_postop" title="Align Top">
                                      <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                        height="27">
                                        <path
                                          d="M 1 2 L 5 2 L 5 14 L 11 14 L 11 2 L 16 2 L 16 20 L 22 20 L 22 2 L 26 2 L 26 1 L 1 1 L 1 2 Z" />
                                      </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_posmiddle" title="Align Middle">
                                      <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                        height="27">
                                        <path
                                          d="M 26 13 L 26 14 L 21 14 L 21 22 L 15 22 L 15 14 L 12 14 L 12 19 L 6 19 L 6 14 L 1 14 L 1 13 L 6 13 L 6 8 L 12 8 L 12 13 L 15 13 L 15 5 L 21 5 L 21 13 L 26 13 Z" />
                                      </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_posbottom" title="Align Bottom">
                                      <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                        height="27">
                                        <path
                                          d="M 1 25 L 5 25 L 5 13 L 11 13 L 11 25 L 16 25 L 16 7 L 22 7 L 22 25 L 26 25 L 26 26 L 1 26 L 1 25" />
                                      </svg>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>

                          <!-- Buttons when multiple elements are selected -->
                          <div id="multiselected_panel" class="context_panel clearfix">
                            <h4 class="hidable">Multiple Elements</h4>

                            <div class="toolset align_buttons" style="position: relative">
                              <label id="tool_align_relative" style="margin-top: 10px;">
                                <div class="select-input">
                                  <select id="align_relative_to" title="Align relative to ..." class="select-input">
                                    <option id="selected_objects" value="selected">Align to objects</option>
                                    <option id="page" value="page">Align to page</option>
                                  </select>
                                </div>
                              </label>
                              <!-- <h4>.</h4> -->
                              <div class="col last clear" id="align_opts">
                                <div class="draginput_cell p-0" id="tool_alignleft" title="Align Left">
                                  <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27" height="27">
                                    <path
                                      d="M 2 1 L 2 5 L 14 5 L 14 11 L 2 11 L 2 16 L 20 16 L 20 22 L 2 22 L 2 26 L 1 26 L 1 1 L 2 1 Z" />
                                  </svg>
                                </div>
                                <div class="draginput_cell" id="tool_aligncenter" title="Align Center">
                                  <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27" height="27">
                                    <path
                                      d="M 13 1 L 14 1 L 14 6 L 22 6 L 22 12 L 14 12 L 14 15 L 19 15 L 19 21 L 14 21 L 14 26 L 13 26 L 13 21 L 8 21 L 8 15 L 13 15 L 13 12 L 5 12 L 5 6 L 13 6 L 13 1 Z" />
                                  </svg>
                                </div>
                                <div class="draginput_cell" id="tool_alignright" title="Align Right">
                                  <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27" height="27">
                                    <path
                                      d="M 25 1 L 25 5 L 13 5 L 13 11 L 25 11 L 25 16 L 7 16 L 7 22 L 25 22 L 25 26 L 26 26 L 26 1 L 25 1 Z" />
                                  </svg>
                                </div>
                                <div class="draginput_cell" id="tool_aligntop" title="Align Top">
                                  <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27" height="27">
                                    <path
                                      d="M 1 2 L 5 2 L 5 14 L 11 14 L 11 2 L 16 2 L 16 20 L 22 20 L 22 2 L 26 2 L 26 1 L 1 1 L 1 2 Z" />
                                  </svg>
                                </div>
                                <div class="draginput_cell" id="tool_alignmiddle" title="Align Middle">
                                  <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27" height="27">
                                    <path
                                      d="M 26 13 L 26 14 L 21 14 L 21 22 L 15 22 L 15 14 L 12 14 L 12 19 L 6 19 L 6 14 L 1 14 L 1 13 L 6 13 L 6 8 L 12 8 L 12 13 L 15 13 L 15 5 L 21 5 L 21 13 L 26 13 Z" />
                                  </svg>
                                </div>
                                <div class="draginput_cell" id="tool_alignbottom" title="Align Bottom">
                                  <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27" height="27">
                                    <path
                                      d="M 1 25 L 5 25 L 5 13 L 11 13 L 11 25 L 16 25 L 16 7 L 22 7 L 22 25 L 26 25 L 26 26 L 1 26 L 1 25" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>

                          </div>

                          <div id="stroke_panel" class="context_panel clearfix">
                            <div class="clearfix"></div>
                            <h4>Stroke</h4>
                            <div class="toolset" data-title="Change stroke">
                              <label>
                                <input id="stroke_width" size="2" value="5" data-attr="stroke-width" min="0" max="99"
                                  step="1" />
                                <span class="icon_label">Width</span>
                              </label>
                            </div>
                            <div class="stroke_tool draginput">
                              <span>Dash</span>
                              <select id="stroke_style" data-title="Change stroke dash style">
                                <option selected="selected" value="none">—</option>
                                <option value="2,2">···</option>
                                <option value="5,5">- -</option>
                                <option value="5,2,2,2">-·-</option>
                                <option value="5,2,2,2,2,2">-··-</option>
                              </select>
                              <div class="caret"></div>
                              <label id="stroke_style_label">—</label>
                            </div>

                            <label style="display: none;">
                              <span class="icon_label">Stroke Join</span>
                            </label>

                            <label style="display: none;">
                              <span class="icon_label">Stroke Cap</span>
                            </label>
                          </div>

                        </div> <!-- tools_top -->
                        <div id="cur_context_panel">

                        </div>

                        <div id="tools_left" class="tools_panel">
                          <div class="tool_button" id="tool_select" title="Select Tool [V]">
                            <svg viewBox="0 0 24 24" width="24" height="24">
                              <path d="M17.15 20.76l-2.94 1.5-3.68-6-4.41 3V1.24l12.5 12.01-4.41 1.5 2.94 6z" />
                            </svg>
                          </div>
                          <div class="tool_button" id="tool_fhpath" title="Pencil Tool [P]">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"
                              style="transform: scale(-1,1)">
                              <path
                                d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                            </svg>
                          </div>
                          <div class="tool_button" id="tool_line" title="Line Tool [L]">
                            <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                              <path d="M 3 1 L 26 24 L 24 26 L 1 3 L 3 1 Z"></path>
                            </svg>
                          </div>
                          <div class="tool_button" id="tool_rect" title="Square/Rect Tool [R]">
                            <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                              <path d="M 0 8 L 0 24 L 24 24 L 25 8 L 0 8 Z" />
                            </svg>
                          </div>
                          <div class="tool_button" id="tool_ellipse" title="Ellipse/Circle Tool [C]">
                            <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                              <ellipse cx="13" cy="13" rx="13" ry="9"></ellipse>
                            </svg>
                          </div>

                          <!-- <div class="tool_button" title="Shape Tool [C]" id="tool_shapelib">
                              <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 24 24" >
                                <polygon points="14.43,10 12,2 9.57,10 2,10 8.18,14.41 5.83,22 12,17.31 18.18,22 15.83,14.41 22,10"/>
                              </svg>
                              <div class="tool_menu">
                                  
                              </div>
                            </div> -->

                          <div class="tool_button" id="tool_path" title="Path Tool [P]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" width="27" height="27">
                              <path
                                d="M12.2 1.9c0-.36.86 0 .86 0V14a1.3 1.3 0 10.88 0V1.9s.87-.36.87 0c0 6.81 5.22 11.68 5.22 11.68l-3.25 8.2h-6.55l-3.26-8.2s5.22-4.87 5.22-11.68zM7.83 25.26v-2.61h11.32v2.6H7.84z" />
                            </svg>
                          </div>
                          <div class="tool_button" id="tool_text" title="Text Tool [T]">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 2 20 20" width="27"
                              height="27">
                              <path d="M5 4v3h5.5v12h3V7H19V4z" />
                            </svg>
                          </div>

                          <div class="tool_button" id="tool_zoom" title="Zoom Tool [Z]">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 2 20 20" width="27">
                              <path
                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                            </svg>
                          </div>

                          <div class="tool_button" id="tool_eyedropper" title="Eyedropper Tool [Z]">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 2 20 20" width="27"
                              style="transform: scale(-1, 1)">
                              <path
                                d="M20.71 5.63l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42L3 16.25V21h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42zM6.92 19L5 17.08l8.06-8.06 1.92 1.92L6.92 19z" />
                            </svg>
                          </div>

                          <div id="color_tools">
                            <div id="tool_switch" title="Switch stroke and fill colors [X]"></div>
                            <div class="color_tool active" id="tool_fill">
                              <label class="icon_label" title="Change fill color"></label>
                              <div class="color_block">
                                <div id="fill_bg"></div>
                                <div id="fill_color" class="color_block"></div>
                              </div>
                            </div>

                            <div class="color_tool" id="tool_stroke">
                              <label class="icon_label" title="Change stroke color"></label>
                              <div class="color_block">
                                <div id="stroke_bg"></div>
                                <div id="stroke_color" class="color_block" title="Change stroke color"></div>
                              </div>
                            </div>
                          </div>
                        </div> <!-- tools_left -->

                        <div id="tools_bottom" class="tools_panel">
                          <div id="palette" title="Click to change fill color, shift-click to change stroke color">
                          </div>
                        </div>

                        <!-- hidden divs -->
                        <div id="color_picker"></div>

                      </div> <!-- svg_editor -->

                      <div id="svg_source_editor">
                        <div id="svg_source_overlay"></div>
                        <div id="svg_source_container">
                          <div id="save_output_btns">
                            <p id="copy_save_note">Copy the contents of this box into a text editor, then save the file
                              with a .svg extension.</p>
                            <button id="copy_save_done">Done</button>
                          </div>
                          <form>
                            <textarea id="svg_source_textarea" spellcheck="false"></textarea>
                          </form>
                          <div id="tool_source_back" class="toolbar_button">
                            <button id="tool_source_cancel" class="cancel">Cancel</button>
                            <button id="tool_source_save" class="ok">Apply Changes</button>
                          </div>
                        </div>
                      </div>

                      <div id="dialog_box">
                        <div id="dialog_box_overlay"></div>
                        <div id="dialog_container">
                          <div id="dialog_content"></div>
                          <div id="dialog_buttons"></div>
                        </div>
                      </div>

                      <div class="tools_flyout" id="tools_shapelib">
                        <div id="shape_buttons"></div>
                      </div>
                    </div>
                  </mdb-card-body>
                </mdb-card>
                <ul id="cmenu_canvas" class="contextMenu">
                  <li><a data-href="#cut">Cut <span class="shortcut">⌘X;</span></a></li>
                  <li><a data-href="#copy">Copy<span class="shortcut">⌘C</span></a></li>
                  <li><a data-href="#paste">Paste<span class="shortcut">⌘V</span></a></li>
                  <li class="separator"><a data-href="#delete">Delete<span class="shortcut">⌫</span></a></li>
                  <li class="separator"><a data-href="#group">Group<span class="shortcut">⌘G</span></a></li>
                  <li><a data-href="#ungroup">Ungroup<span class="shortcut">⌘⇧G</span></a></li>
                  <li class="separator"><a data-href="#move_front">Bring to Front<span class="shortcut">⌘⇧↑</span></a>
                  </li>
                  <li><a data-href="#move_up">Bring Forward<span class="shortcut">⌘↑</span></a></li>
                  <li><a data-href="#move_down">Send Backward<span class="shortcut">⌘↓</span></a></li>
                  <li><a data-href="#move_back">Send to Back<span class="shortcut">⌘⇧↓</span></a></li>
                </ul>
                <img id="theimage" hidden />
              </div>
            </div>
          </div>
        </div>


        <hr style="border: 1px solid #06438a;">
        <div class="row">
          <div class="col-12">
            <mat-label class="matlabel"><strong class="other_header">Reviewed by :</strong> {{review_by}}</mat-label>
          </div>
          <div class="col-12">
            <mat-label class="matlabel"><strong class="other_header">Signature :</strong><img alt=""
                src="{{review_sign}}" class="sign_img" *ngIf="review_sign != ''" /> </mat-label>
          </div>
        </div>
      </div>
      <div *ngIf="documentView_flag">
        <div class="row">
          <div class="col-12">
            <mat-card id="cardcontent">
              <mat-card-header id="cardheader">Client Details</mat-card-header>
              <mat-card-content style="padding: 10px 0;">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Client id :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{clent_id}}</mat-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Name :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{client_another_name}}</mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Age :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{client_age}}</mat-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Gender :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{client_gender}}</mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                      <div class="row">
                        <div class="col-5">
                          <mat-label class="matlabel">Date :</mat-label>
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel">{{date_txt}}</mat-label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 embed-responsive embed-responsive-16by9" style="margin-top: 10px;">
            <iframe class="controls embed-responsive-item" [src]="document" width="100%" height="500px" frameborder="0" webkitallowfullscreen
              mozallowfullscreen allowfullscreen></iframe>
          </div>

        </div>
      </div>
      <!-- <div *ngIf="report_detail_flag" class="row">
        <a (click)="back_Nav()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
            class="backimgbtn_inpatinfo" /></a>
      </div><br> -->

    </div>
  </div>
  <script src="../../../assets/dist/all.js"></script>
</div>