import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from 'src/assets/js/Message_data';
import { Helper_Class } from '../../helper_class';

@Component({
  selector: 'app-lead-mgmt',
  templateUrl: './lead-mgmt.component.html',
  styleUrls: ['./lead-mgmt.component.scss']
})
export class LeadMgmtComponent implements OnInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  public leadcreateflag:boolean=true;
  public symptoms_list = [];
  public symptom_data = [];
  public symptomlist = [];
  public newsymptoms = [];
  public selected_symptoms = [];
  public dis_txt: any;
  public referredDoctorList: any[];
  public lead_status_arr = []
  public lead_type = []
  public channel_arr = []
  public patientName;
  public patCity;
  public patContact;
  public channel;
  public campDate;
  public leadType;
  public status;
  public remarks;
  public notes;
  public leadArray: any[];
  public filtleadArray: any=[];
  public campaigns_id=0;
  private country;
  public search_filter;
  public from_date;
  public to_date;
  public history_flag: boolean=false;
  public channel_flag:boolean=false;
  public camp_flag:boolean=false;
  public date_flag:boolean=false;
  public currDate:string;
  public repId:string;
  public repNameArray:any=[];

  constructor(private httpClient: HttpClient, private toastr: ToastrService, private chanDet: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.country = Helper_Class.getInfo().country;
    this.history_flag=false;
    this.search_filter="date";
    this.date_flag=true;
    
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by patient name or date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.getLeadStatus()
    this.getLeadType()
    this.getChannel()
    this.getCurrentDate()
    this.getLeadlist()
    this.getRepList()
  }

  generateLead(){
    this.leadcreateflag=false;
  }

  leadHistory(){
    this.history_flag=true;
  }

  backClicked(){
    this.history_flag=false;
  }

  fiterCampaign(){
    if(this.search_filter=="channel"){
      this.channel_flag= true;
      this.date_flag=false;
      this.camp_flag=false;
      this.channel = this.channel_arr[0].channel_id;

    } else if(this.search_filter=="campaign"){
      this.channel_flag= false;
      this.date_flag=false;
      this.camp_flag=true;
      this.leadType = this.lead_type[0].lead_type_id;
      
    } else if(this.search_filter=="date"){
      this.channel_flag= false;
      this.date_flag=true;
      this.camp_flag=false;
      this.from_date=this.currDate;
      this.to_date=this.currDate;

    }
  }

  getCurrentDate() {
    var country = "in"
    var headers = new HttpHeaders()
    this.httpClient.post(ipaddress.getIp + "adm/curdate", { country }, { headers: headers }).subscribe(response => {
      var data = JSON.parse(JSON.stringify(response))
      this.currDate = data.current_date;
      this.campDate = this.currDate;
      this.from_date=this.currDate;
      this.to_date=this.currDate;
    },
      error => {
        this.toastr.error(Message_data.defaultErr);
      })
  }

  // getSymptoms() {
  //   var searchString;
  //   if (this.symptom.indexOf(',') > -1) {
  //     var dataval = this.symptom.split(",");
  //     console.log(this.symptom.indexOf(','));
  //     searchString = dataval[dataval.length - 1].trim();

  //   } else {
  //     searchString = this.symptom;
  //   }

  //   if (searchString.length > 2) {
  //     var headers = new HttpHeaders();
  //     headers.append('Content-Type', 'application/json');
  //     this.httpClient.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
  //       { symp_name: searchString },
  //       { headers: headers })
  //       .subscribe(
  //         response => {
  //           var obj = JSON.parse(JSON.stringify(response));
  //           if (obj.symptoms != null) {
  //             this.symptoms_list = [];
  //             this.symptomlist = [];
  //             this.symptom_data = [];
  //             this.symptoms_list = obj.symptoms;
  //             for (var i = 0; i < obj.symptoms.length; i++) {
  //               this.symptom_data.push(obj.symptoms[i].description);
  //             }

  //             this.symptomlist = this.symptom_data.filter(function (el) {
  //               return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
  //             }.bind(this));
  //           }
  //         },
  //         error => {

  //         })
  //   }
  // }

  // select_symptom(data) {
  //   this.newsymptoms.push(data);
  //   for (var j = 0; j < this.symptoms_list.length; j++) {
  //     if (this.symptoms_list[j].description == data) {
  //       this.selected_symptoms.push(data);
  //       break;
  //     }
  //   }

  //   if (this.symptom.indexOf(',') > -1) {
  //     var dataval = this.symptom.split(",");
  //     dataval.pop();
  //     this.symptom = dataval.join(",");

  //     this.symptom = this.symptom + "," + data;
  //   } else {
  //     this.symptom = data;
  //   }
  //   this.symptomlist = [];
  // }

  // sltReferDoctor(item) {
  //   this.DoctorName = item;
  //   this.referredDoctorList = [];
  // }

  // getReferralDoctorList(e) {
  //   this.referredDoctorList = [];
  //   this.DoctorName = e.target.value.toString();
  //   if (this.DoctorName != "" && this.DoctorName.length > 2) {
  //     var headers = new HttpHeaders();
  //     headers.append('Content-Type', 'application/json');
  //     this.httpClient.post(ipaddress.getIp.toString() + 'search/docbyhns/',
  //       { doc_name: this.DoctorName },
  //       { headers: headers })
  //       .subscribe(
  //         response => {
  //           console.log(response);

  //           var obj = JSON.parse(JSON.stringify(response));
  //           var objReferredDocList = obj.doctors;

  //           if (objReferredDocList != undefined) {
  //             for (var i = 0; i < objReferredDocList.length; i++) {
  //               var docname;
  //               if (objReferredDocList[i].middle_name != undefined) {
  //                 docname = objReferredDocList[i].first_name + " " + objReferredDocList[i].middle_name + " " + objReferredDocList[i].last_name;
  //               } else {
  //                 docname = objReferredDocList[i].first_name + " " + objReferredDocList[i].last_name;
  //               }
  //               this.referredDoctorList.push(docname);
  //             }
  //           }
  //           this.referredDoctorList = this.referredDoctorList.filter(function (this: any, el) {
  //             return el.toLowerCase().indexOf(this.DoctorName.toLowerCase()) > -1;
  //           }.bind(this));
  //           console.log(this.referredDoctorList);

  //         }, error => {
  //           this.toastr.error(Message_data.defaultErr);
  //         });
  //   } else {
  //     this.referredDoctorList = [];
  //   }
  // }

  getLeadStatus() {
    var headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    this.httpClient.post(ipaddress.getIp + "gen/gls",{hptl_clinic_id:Helper_Class.getHospitalId()}, { headers: headers }).subscribe(response => {
      console.log(response);
      var obj = JSON.parse(JSON.stringify(response))
      if (obj != null) {
        var lead_status = obj.lead_status
        console.log(lead_status);
        if (lead_status.length != 0) {
          for (let i = 0; i < lead_status.length; i++) {
            if (lead_status[i].description != undefined && lead_status[i].description != "") {
              var description = lead_status[i].description
            }

            this.lead_status_arr.push({
              lead_stat_desc: description,
              lead_stat_id: lead_status[i].lead_status_id
            })

          }
        }
      }
    })
  }

  getLeadType() {
    var headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    this.httpClient.get(ipaddress.getIp + "gen/glt", { headers: headers }).subscribe(response => {
      console.log(response);
      var obj = JSON.parse(JSON.stringify(response))
      if (obj != null) {
        var lead_type = obj.lead_types
        if (lead_type.length != 0) {
          for (let i = 0; i < lead_type.length; i++) {
            if (lead_type[i].lead_desc != undefined && lead_type[i].lead_desc != "") {
              var lead_desc = lead_type[i].lead_desc
            }

            this.lead_type.push({
              lead_type_id: lead_type[i].lead_type_id,
              lead_desc: lead_desc
            })
          }
        }
      }
    })
  }

  getChannel() {
    var headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    this.httpClient.get(ipaddress.getIp + "gen/glc", { headers: headers }).subscribe(response => {
      var obj = JSON.parse(JSON.stringify(response))
      console.log(obj);
      if (obj != null) {
        var channel = obj.lead_channels
        if (channel.length != 0) {
          for (let i = 0; i < channel.length; i++) {
            if (channel[i].channel_desc != undefined && channel[i].channel_desc != "") {
              var channel_desc = channel[i].channel_desc
            }

            this.channel_arr.push({
              channel_id: channel[i].channel_id,
              channel_desc: channel_desc
            })

          }
        }

      }
    })
  }

  editLead(lead){
    for(var l=0;l<this.leadArray.length;l++){
      if(this.leadArray[l] == lead){
        this.leadArray.splice(l,1);
      }
    }
    var channelid;
    for(var i=0;i<this.channel_arr.length;i++){
      if(this.channel_arr[i].channel_desc == lead.lead_channel){
        channelid = this.channel_arr[i].channel_id;
      }
    }
    var leadtypeid;
    for(var j=0;j<this.lead_type.length;j++){
      if(this.lead_type[j].lead_desc == lead.lead_type){
        leadtypeid =this.lead_type[j].lead_type_id;
      }
    }
   
    var statusid;
    for(var k=0;k<this.lead_status_arr.length;k++){
      if(this.lead_status_arr[k].lead_stat_desc == lead.status){
        statusid = this.lead_status_arr[k].lead_stat_id;
      }
    }
    var leaddate=lead.camp_date.split("-");
    this.campaigns_id= lead.campaigns_id;
    this.patientName = lead.patientname;
    this.status = statusid;
    this.channel = channelid;
    this.notes = lead.notes;
    this.remarks = lead.remarks;
    this.leadType = leadtypeid;
    this.patCity= lead.patCity,
    this.patContact= lead.patContact,
    this.campDate=leaddate[2]+"-"+leaddate[1]+"-"+leaddate[0];
    this.repId=lead.repid;
  }

  saveLeadGeneration() {
    if (this.patientName != undefined && this.status != undefined 
      && this.leadType != undefined ) {
      var send_data = {
        hptl_clinic_id: Helper_Class.getHospitalId(),
        campaigns_id:this.campaigns_id,
        patient_name: this.patientName,
        lead_channel: this.channel,
        lead_type: this.leadType,
        remarks: this.remarks,
        notes: this.notes,
        camp_date: this.campDate,
        status: this.status,
        country:this.country,
        patCity: this.patCity,
        patContact: this.patContact,
        repid:this.repId,
      }
      
      if (send_data.hptl_clinic_id != undefined && send_data.patient_name != undefined 
        && send_data.camp_date != undefined && send_data.lead_channel != undefined 
        && send_data.notes != undefined && send_data.remarks != undefined && send_data.lead_type != undefined) {
        var headers = new HttpHeaders
        headers.append('Content-Type', 'application/json');
        this.httpClient.post(ipaddress.getIp + "gen/slc", send_data, { headers: headers }).subscribe(response => {
          var obj = JSON.parse(JSON.stringify(response))
          this.getLeadlist();
          this.toastr.success(Message_data.saveLeadGenerate())
          this.leadcreateflag=true;
          this.patientName = ""
          this.status = ""
          this.channel = ""
          this.notes = ""
          this.remarks = ""
          this.leadType = ""
          this.campDate = this.campDate
          this.campaigns_id=0;
        })
      }

    } else {
      this.toastr.error(Message_data.defaultErr)
    }
  }

  rerender() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy()
      this.dtTrigger.next()
    });
  }
  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  
  getLeadlist() {
    this.leadArray = []
    var hptl_clinic_id = Helper_Class.getHospitalId();
    var headers = new HttpHeaders()
    headers.append("content-type", "application/json")
    this.httpClient.post(ipaddress.getIp.toString() + "gen/glcd", { hptl_clinic_id }, { headers: headers }).subscribe((result: any) => {
      console.log(result);
      var arr = JSON.parse(JSON.stringify(result))
      console.log(arr);
      var results = arr.lead_campaigns
      
      for (let i = 0; i <= results.length; i++) {
        var camdate;
        if(results[i].camp_date != undefined){
          var cadate = results[i].camp_date.split("-");
          camdate = cadate[2]+"-"+cadate[1]+"-"+cadate[0];

        } else {
          camdate="";
        }
       
        var cdate;
        if(results[i].created_date != undefined){
          var createdate=results[i].created_date.split("-");
          cdate=createdate[2]+"-"+createdate[1]+"-"+createdate[0];

        } else {
          cdate="";
        }

        this.leadArray.push({
          campaigns_id: results[i].campaigns_id,
          patientname: results[i].patient_name,
          patCity: results[i].patCity,
          patContact: results[i].patContact,
          lead_channel: results[i].lead_channel,
          lead_type: results[i].lead_type,
          camp_date: camdate,
          status: results[i].status,
          notes:results[i].notes,
          remarks:results[i].remarks,
          created_date: cdate,
          repid:results[i].repid,
        })
      }
    })
  }

  getRepList(){
    this.repNameArray = []
    var hptl_clinic_id = Helper_Class.getHospitalId();
    var headers = new HttpHeaders()
    headers.append("content-type", "application/json")
    this.httpClient.post(ipaddress.getIp.toString() + "gen/ghrp", { hptl_clinic_id }, { headers: headers }).subscribe((result: any) => {
      console.log(result);
      var arr = JSON.parse(JSON.stringify(result))
      console.log(arr);
      var results = arr.rep_details
      
      for (let i = 0; i <= results.length; i++) {
        this.repNameArray.push({
          repId: results[i].rep_id,
          repName: results[i].repName,
        })
      }
    })
  }

  searchCampaign(){
    this.filtleadArray = []
    var send_data={
      filter:this.search_filter,
      channel:this.channel,
      campaign:this.leadType,
      from_date:this.from_date,
      to_date:this.to_date
    }

    var headers = new HttpHeaders()
    headers.append("content-type", "application/json")
    this.httpClient.post(ipaddress.getIp.toString() + "gen/glcf", send_data, { headers: headers }).subscribe((result: any) => {
      console.log(result);
      var arr = JSON.parse(JSON.stringify(result))
      console.log("Filter campaigns "+JSON.stringify(arr));
      var results = arr.lead_campaigns
      
      for (let i = 0; i <= results.length; i++) {
        var camdate;
        if(results[i].camp_date != undefined){
          var cadate = results[i].camp_date.split("-");
          camdate = cadate[2]+"-"+cadate[1]+"-"+cadate[0];

        } else {
          camdate="";
        }
       
        var cdate;
        if(results[i].created_date != undefined){
          var createdate=results[i].created_date.split("-");
          cdate=createdate[2]+"-"+createdate[1]+"-"+createdate[0];

        } else {
          cdate="";
        }
        
        this.filtleadArray.push({
          campaigns_id: results[i].campaigns_id,
          patientname: results[i].patient_name,
          patCity: results[i].patCity,
          patContact: results[i].patContact,
          lead_channel: results[i].lead_channel,
          lead_type: results[i].lead_type,
          camp_date: camdate,
          status: results[i].status,
          notes:results[i].notes,
          remarks:results[i].remarks,
          created_date: cdate,
          repid:results[i].repid,
        })
      }
    })
  }
}
