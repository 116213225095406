import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common.js';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GynaObestricPopSelectionComponent } from '../gyna-obestric-pop-selection/gyna-obestric-pop-selection.component';
import { Doc_Helper } from '../Doc_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
import { Nurse_Helper } from '../../../app/Nurse_module/Nurse_Helper';
import { Master_Helper } from '../Master_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { ViewMedpresComponent } from '../view-medpres/view-medpres.component';
import { Chart } from 'chart.js';
import { MedPresViewComponent } from '../med-pres-view/med-pres-view.component';

@Component({
  selector: 'app-doctor-app-list',
  templateUrl: './doctor-app-list.component.html',
  styleUrls: ['./doctor-app-list.component.css']
})
export class DoctorAppListComponent implements OnInit {
  @ViewChild('printnoheaderbody') printnoheaderbody: ElementRef;
  public IsSpecilization;
  public gynecologyFlag: boolean = false;
  public pediatricsFlag: boolean = false;
  public cardioFlag: boolean = false;
  public nephrologyFlag: boolean = false;
  public othersFlag: boolean = false;
  public dentaFlag: boolean = false;
  public diabetesFlag: boolean = false;
  public generalFlag: boolean = false;
  public ENTFlag: boolean = false;
  public gastroFlag: boolean = false;

  public submenuFlag: boolean = false;
  public submenuFlag1: boolean = false;

  public nodApp: boolean;
  public homeCare: string | null;
  public CurrentDatetime;
  public appointmentDate;
  public calendardate;
  public flagval: boolean;
  public datePick: string[] = ["docapp"];
  public splName;
  public splArray: any = [];
  public statusColor;
  public appt;
  public subRelationID

  public hospitalInfo: any = [];
  public hospitalId;

  public getAppDate;
  public specializations: any = [];
  //public spl_name=[];
  public age_txt: string | null;
  public appointment_list: any = [];

  public first_name: string | null;
  public url = ipaddress.getIp + "usercontroller/providerdetails";
  public last_name: string | null;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;

  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public spl_id: string | null;
  public case_sheet: string | null;
  public doctor_profile_img: string | null;
  public doc_reg;
  public userinfo: any;
  public user_id: any;
  public obj: any;
  public data: any;
  public urlt = ipaddress.getIp + 'appointment/getdocapp';
  public spl_url;
  public textalign;
  public height;
  public width;

  public hptl_clinic_id;
  public doc_app_id;
  public loading_flag: boolean = true;
  public nodata_flag: boolean = true;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public checklistid: any = [];
  public currentDate;
  public appin_open_count: number = 0;
  public doctor_name;
  public isActive; boolean = false;

  public patient_list: any;
  public appt_List: any = [];
  public nextButtonFlag: boolean = true;
  public sleepGrphData: any = [];
  public sleepGraphValues: any = [];
  public nutritionGrphData: any = [];
  private cancelarray:any=[];
  public docCaseSheetFlag:boolean=true;
  public docCaseSummaryFlag:boolean=true;
  private appid:string;
  private mobile:string;
  private client_id:string;
  private relation_id:string;
  private sub_rel_id:string;
  public pres_flag:boolean=true;
  public show_wpbutton:boolean=false;
  public print_template;
  public preslogo_style;
  public doct_clientname; 
  public client_age; 
  public client_gender;
  public clnt_bp;
  public heighttxt;
  public weight;
  public presdate;
  intervalId: NodeJS.Timeout;
  
  constructor(public dialog: MatDialog, private http: Http, private router: Router, private toastr: ToastrService,
    public doctormessageservice: MenuViewService, public masterCSdata: MasterCSHelperService) {
    Doc_Helper.setClient_Info(null);
    Doc_Helper.setAppFlow("case_sheet");
    Doc_Helper.setHome_care("0");
    Doc_Helper.setDiagAppflow("medical");
    Helper_Class.setInpatientFlow(undefined);
    Nurse_Helper.setINPatient(undefined);
    Helper_nephro_casesheet.nephro_get_ret = undefined;
    Helper_Class.setInpatientFlow(undefined);
    Helper_Class.setAsg(undefined);
  }

  ngOnInit(): void {
    this.isActive = false;
    this.loading_flag = true;
    this.nodata_flag = true;
    this.dtOptions = {
      //pagingType: 'full_numbers',
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 6, 8] },
        { "width": "100px", "targets": 8 },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no etc"
      },
      dom: '<"row mb-3"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1  d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    var hospital_details = Helper_Class.getHospital();

    this.hospitalId = hospital_details[0].hptl_clinic_id;

    Helper_Class.setapp_flow("0");
    for (var i = 0; i < this.userinfo.hospitals.length; i++) {
      this.hospitalInfo[i] = this.userinfo.hospitals[i].hptl_clinic_id;
    }
    this.hospitalId = this.hospitalInfo[0];
    for (var j = 0; j < this.userinfo.specializations.length; j++) {
      this.specializations[j] = this.userinfo.specializations[j].spl_name;
    }
    var moduleidlist = Doc_Helper.getModuleList();
    if(moduleidlist != undefined){
      for(var i=0;i<moduleidlist.length;i++){
        if (moduleidlist[i] == "29") {
          this.docCaseSheetFlag = false;
          Doc_Helper.setCasesheet_flag("1");
        } else if (moduleidlist[i] == "30") {
          this.docCaseSummaryFlag = false;
          Doc_Helper.setCasesumm_flag("1");
        }
      }
    }
    if (Helper_Class.getHospital()[0].pres_print_template != undefined) {
      this.print_template = Helper_Class.getHospital()[0].pres_print_template;
      if(this.print_template =="noheaderbody"){
        this.show_wpbutton = true;
      }
    } 
    if (Helper_Class.getHospital()[0].pres_print_logo != undefined && Helper_Class.getHospital()[0].pres_print_logo != "undefined") {
      this.preslogo_style = Helper_Class.getHospital()[0].pres_print_logo_style;
    }

    this.intervalId =  setInterval(() => {
      this.getCurrentDate();
    }, 60000);
    this.getCurrentDate();
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  close_appointment() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/clsapp/',
      JSON.stringify({
        app_id: this.appid,
        mobile: this.mobile,
        type: "doctor"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null) {
            if (obj.key == "1") {
              this.toastr.success("Successfully closed appointment");
              if(this.print_template =="noheaderbody"){
                this.print();
              }
              
            } else {
              this.toastr.error("Unable to close appointment, try again")
            }
          }
        }
      );
  }

  print(){
    let printContents, popupWin;

    printContents = this.printnoheaderbody.nativeElement.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Prescription</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        .casesheet_container {
          width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        @media all {
          .page-break { display: none; }
          }
          
          @media print {
          .page-break { display: block; page-break-before: always; }
          }
        @media print {
          body{
            -webkit-print-color-adjust: exact;
            -moz-print-color-adjust: exact;
            -ms-print-color-adjust: exact;
            print-color-adjust: exact;
          }
          footer {
            position: fixed;
            bottom: 0;
          }
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
    </footer>
    </html>`
    );
    popupWin.document.close();
    printContents = "";

  }
  spiltdate(date) {
    var get_tmp = date.split("-");
    var date_appt = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    return date_appt;
  }

  OnDateMatePicker(e) {
    if(e == this.CurrentDatetime){
      Doc_Helper.setAppDate("");
      this.appointmentDate = e;
      Doc_Helper.setAppDate(e);
      this.get_doc_apps(this.user_id);
      this.nextButtonFlag = false;
    } else {
      this.get_doc_apps(this.user_id);
      this.nextButtonFlag = true;
    }
  }

  view_prescription(){
    this.doctormessageservice.sendMessage("viewpres");
    // const dialogRef5 = this.dialog.open(MedPresViewComponent, {
    //   width: '1200px',
    //   height: '800px',
    //   data:{client_id: this.client_id,relation_id:this.relation_id,sub_rel_id:this.sub_rel_id}
    // });
  
    // dialogRef5.afterClosed().subscribe(result => {});
  }
  
  isReadonly() { return true; }

  get_doc_apps(user_id): void {
    this.homeCare = "0";
    this.appointment_list = [];
    var sen_data = JSON.stringify({
      home_care: this.homeCare,
      date: this.appointmentDate,
      doc_prov_id: user_id,
      country: "IN",
      type: "doctor"
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'appointment/getdocapp', sen_data, { headers: headers }).subscribe(
      response => {
        var data = response.json();
        Helper_Class.setpatient_information(data);

        if (data != null || data != undefined || data != "") {
          if (data.appointments != null && data.appointments.length != 0) {
            this.appt = data.appointments;
            this.nodApp = false;
            for (var i = 0; i < this.appt.length; i++) {
              var get_age;
              if (this.appt[i].age != null && this.appt[i].age != undefined && this.appt[i].age != " ") {
                get_age = this.appt[i].age;
              } else {
                get_age = "";
              }
              var Patient_name;
              if (this.appt[i].middle_name != undefined && this.appt[i].middle_name != null) // middle name 
              {
                Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].middle_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
              } else {
                Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
              }

              if (this.appt[i].mobile != null) {
                var contactno = encrypt_decript.Decript(this.appt[i].mobile);
              }
              var profile_image = ipaddress.Ip_with_img_address + this.appt[i].profile_image;
              var lastvisit;
              if (this.appt[i].date != null) {
                var last_visit = this.appt[i].date;
                var gettmp = last_visit.split("-");
                lastvisit = gettmp[2] + "-" + gettmp[1] + "-" + gettmp[0];
              }
              var status_txt;
              if (this.appt[i].status == "0") {
                status_txt = "Completed";

              } else if (this.appt[i].status == "1") {
                if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                  if (this.appt[i].f_time != "1") {
                    var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                    var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                    if (get_time > cur_time) {
                      status_txt = "Not visited";
                    } else {
                      status_txt = "Open";
                    }
                  } else {
                    var get_time = new Date(this.appt[i].date);
                    var cur_time = new Date(this.CurrentDatetime);
                    if (get_time >= cur_time) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else {
                  if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                    status_txt = "Open";

                  } else {
                    status_txt = "Not visited";
                  }
                }

              } else if (this.appt[i].status == "2") {
                if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                  var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                  var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                  status_txt = "Confirmed";
                } else {
                  if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                    status_txt = "Open";
                  } else {
                    status_txt = "Not visited";
                  }
                }
              }

              if (this.appt[i].sub_rel_id != null) {
                this.subRelationID = this.appt[i].sub_rel_id;

              } else {
                this.subRelationID = "";
              }
              var dateofbirth;
              var session;
              var token_time_label;
              var location_desc;
              var city;
              var state;
              var country, country_id;
              var zipcode;
              var height;
              var height_measure;
              var weight;
              var weight_measure;
              if (this.appt[i].dob != null) {
                dateofbirth = this.spiltdate(this.appt[i].dob);
              }
              if (this.appt[i].symptoms != null) {
                var symptom = this.appt[i].symptoms
              }

              if (this.appt[i].session != null) {
                session = encrypt_decript.Decript(this.appt[i].session);
              }

              var token_flag: boolean;
              if (this.appt[i].token != null) {
                token_time_label = this.appt[i].token;
                token_flag = true;
              } else {
                token_flag = false;
                var Temptimesplit = this.appt[i].f_time;
                var offTime = Temptimesplit.split(':');
                offTime[0] = offTime[0] % 12;
                var output = offTime.join(':');

                var get_timeq = output.split(":");
                if (get_timeq[0] == "0") {
                  output = "12" + ":" + get_timeq[1];
                }

                token_time_label = Time_Formate_Session(output, encrypt_decript.Decript(this.appt[i].session));
              }

              if (status_txt == 'Open') {
                this.appin_open_count = this.appin_open_count + 1;
              }

              if (this.appt[i].location != undefined) {
                location_desc = this.appt[i].location
              }

              if (this.appt[i].city != undefined) {
                city = this.appt[i].city
              }

              if (this.appt[i].state != undefined) {
                state = this.appt[i].state
              }

              if (this.appt[i].country != undefined) {
                country = this.appt[i].country
              }
              if (this.appt[i].zipcode != undefined) {
                zipcode = this.appt[i].zipcode
              }
              if (this.appt[i].height != undefined) {
                height = this.appt[i].height
              } else {
                height = "";
              }
              if (this.appt[i].height_measure != undefined) {
                height_measure = this.appt[i].height_measure
              }
              if (this.appt[i].weight != undefined) {
                weight = this.appt[i].weight
              } else {
                weight = "";
              }

              if (this.appt[i].weight_measure != undefined) {
                weight_measure = this.appt[i].weight_measure
              }

              var doctor_name;
              if (this.appt[i].doc_middle_name != undefined) {
                doctor_name = this.appt[i].doc_first_name + " " + this.appt[i].doc_middle_name + " " + this.appt[i].doc_last_name;
              
              } else {
                doctor_name = this.appt[i].doc_first_name + " " + this.appt[i].doc_last_name;
              }
              
              var package_based = this.appt[i].package_based == "false" ? "No" : "Yes";
              this.appointment_list.push({
                app_data: this.appt[i],
                patient_name: Patient_name,
                patient_id: this.appt[i].pat_id,
                age: get_age,
                status: status_txt,
                session: session,
                contact: contactno,
                lastvisit: lastvisit,
                profile_image: profile_image,
                spl_id: this.appt[i].specialization_id,
                hptl_clinic_id: this.appt[i].hptl_clinic_id,
                doc_app_id: this.appt[i].doc_app_id,
                gender_data: encrypt_decript.Decript(this.appt[i].gender),
                medicare_name: this.appt[i].medicare_name,
                client_reg_id: this.appt[i].client_reg_id,
                relation_id: this.appt[i].relation_id,
                sub_rel: this.subRelationID,
                spl: this.appt[i].specialization,
                relationship_name: this.appt[i].relationship_name,
                dob_data: dateofbirth,
                tot_app_count: token_time_label,
                token_flag: token_flag,
                symptoms: this.appt[i].symptoms,
                refer_by: this.appt[i].referred_by,
                address1: this.appt[i].address1,
                address2: this.appt[i].address2,
                location: location_desc,
                city: city,
                state: state,
                country: country,
                country_id: this.appt[i].country_id,
                state_id: this.appt[i].state_id,
                city_id: this.appt[i].city_id,
                zipcode: zipcode,
                height: height,
                weight: weight,
                height_measure: height_measure,
                weight_measure: weight_measure,
                temparature: this.appt[i].temparature,
                bp: this.appt[i].bp,
                bmi: this.appt[i].bmi,
                bmr: this.appt[i].bmr,
                pulse: this.appt[i].pulse,
                spo2:this.appt[i].spo2,
                cvs:this.appt[i].cvs,
                resp_rate:this.appt[i].resp_rate,
                cns:this.appt[i].cns,
                abd:this.appt[i].abd,
                pr:this.appt[i].pr,
                // rs:this.appt[i].rs,
                dr_first_name: this.appt[i].doc_first_name,
                dr_middle_name: this.appt[i].doc_middle_name,
                dr_last_name: this.appt[i].doc_last_name,
                app_date: this.appt[i].date,
                package: package_based,
                salutation: this.appt[i].salutation,
                old_casesheet:this.appt[i].old_casesheet,
              });
              Helper_Class.setPatientAppointment(this.appointment_list);
            }
            this.activeRowIndex=0;
            this.Doctor_View(this.appointment_list[0], this.activeRowIndex)
            this.loading_flag = false;
            this.nodata_flag = false;
            
          } else {
            this.appointment_list = [];
            this.nodApp = true;
            this.loading_flag = false;
            this.nodata_flag = true;
          }
        }
console.log("APPOINTMENT DETAILS "+JSON.stringify(this.appointment_list))
      })
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != undefined) {
      this.CurrentDatetime = obj.current_date;

      if (Doc_Helper.getAppDate() != undefined && Doc_Helper.getAppDate() != null && Doc_Helper.getAppDate() != "") {
        this.appointmentDate = Doc_Helper.getAppDate();
      } else {
        this.appointmentDate = this.CurrentDatetime;
      }
      this.nextButtonFlag = false;
      this.get_doc_apps(this.user_id);
    }
  }

  activeRowIndex: number = -1;

  Doctor_View(list, i) {
    this.checklistid=[];
    this.activeRowIndex = i;
    this.isActive = !this.isActive;
    this.appt_List = [];
    this.sleepGrphData = [];

    if(list.status == "Completed"){
      this.pres_flag = false;

    } else {
      this.pres_flag = true;
    }

    this.appid = list.doc_app_id;
    this.mobile = list.contact;
    this.doct_clientname=list.patient_name; 
    this.client_age=list.age; 
    this.client_gender=list.gender_data;
    this.clnt_bp=list.bp;
    if(list.height != undefined){
      this.heighttxt=list.height+" "+list.height_measure;
    }
    if(list.weight != undefined){
      this.weight=list.weight+" "+list.weight_measure;
    }
    var pdate = this.appointmentDate.split("-");
    this.presdate = pdate[2]+"-"+pdate[1]+"-"+pdate[0];

    this.checklistid.push({
      app_id: list.doc_app_id,
      session: list.session,
      app_date: list.app_date,
      app_time: list.time,
      first_name: list.dr_first_name,
      middle_name: list.dr_middle_name,
      last_name: list.dr_last_name,
      mobile: list.contact,
      token: list.token,
    });

    this.patient_list = {
      Age_data: list.age,
      Gender_data: list.gender_data,
      app_id: list.doc_app_id,
      salutation: list.saluration,
      client_name: list.patient_name,
      image: list.profile_image,
      sub_id: list.sub_rel,
      Client_id: list.client_reg_id,
      rel_id: list.relation_id,
      Case_Clnt_dob: list.dob_data,
      relationship_name: list.relationship_name,
      hptl_clinic_id: list.hptl_clinic_id,
      spl_id: list.spl_id,
      spl: list.spl,
      mobile: list.contact,
      symptoms: list.symptoms,
      Appoint_Date: list.lastvisit,
      medicare_name: list.medicare_name,
      refer_by: list.refer_txt,
      address1: list.address1,
      address2: list.address2,
      location: list.location,
      city: list.city,
      state: list.state,
      country: list.country,
      zipcode: list.zipcode,
      hptl_name: list.hosp_name,
      height: list.height,
      weight: list.weight,
      height_measure: list.height_measure,
      weight_measure: list.weight_measure,
      temparature: list.temparature,
      bp: list.bp,
      bmi: list.bmi,
      bmr: list.bmr,
      spo2:list.spo2,
      cvs:list.cvs,
      cns:list.cns,
      abd:list.abd,
      pulse: list.pulse,
      resp_rate:list.resp_rate,
      pr:list.pr,
      patient_id: list.patient_id,
      AppFlow: "Doc_app_list",
      country_id: list.country_id,
      state_id: list.state_id,
      city_id: list.city_id,
    };
    Helper_Class.Set_client_view_app_data(list.app_data);
    Doc_Helper.setClient_Info(this.patient_list);
    Helper_Class.setOldCasesheet(list.old_casesheet)
    var sendata = JSON.stringify({
      app_id: list.doc_app_id,
    });

    this.getASGDET(this.patient_list.Client_id, this.patient_list.rel_id, this.patient_list.sub_id);
    Doc_Helper.setSymptoms(this.patient_list.symptoms);
    Doc_Helper.setClient_Info(this.patient_list);
    this.spl_id = this.patient_list.spl_id;
    this.hptl_clinic_id = this.patient_list.hptl_clinic_id;
    this.doc_app_id = this.doc_app_id;
    Helper_Class.setspl_id(this.spl_id);
    this.IsSpecilization = this.patient_list.spl;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/gld', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.clnt_bp=obj.blood_pressure;
          this.appt_List.push({
            heart_rate: obj.pulse,
            blood_pressure: obj.blood_pressure,
            respiratory_rate: obj.respiratory_rate,
            postprandial: obj.postprandial,
            temparature: obj.temparature,
            spo2: obj.spo2,
            height:obj.height,
            height_measure:obj.height_measure,
            weight:obj.weight,
            weight_measure:obj.weight_measure
          });

          this.sleepGrphData.push({
            awake: obj.awake,
            deep: obj.deep,
            light: obj.light,
            rem: obj.rem
          })

          this.renderChart(obj)
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  moveToCasesheet() {
    var oflag: boolean = false;
    
    if (this.IsSpecilization == "Gynecology") {
      this.gynecologyFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Pediatrics") {
      this.pediatricsFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Cardiology") {
      this.cardioFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Nephrology") {
      this.nephrologyFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Dental") {
      this.dentaFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Diabetes") {
      this.diabetesFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "Gastroenterology") {
      this.gastroFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "General" || this.IsSpecilization == "General surgery") {
      this.generalFlag = true;
      oflag = true;

    } else if (this.IsSpecilization == "ENT") {
      this.ENTFlag = true;
      oflag = true;

    } else {
      if (oflag == false) {
        this.othersFlag = true;
      }
    }

    if (this.gynecologyFlag == true) {
      this.Get_gynaretrieval_data();
    }
    if (this.gynecologyFlag == false) {
      this.getcasesheetDetails();
    }
  }


  getASGDET(Client_id, rel_id, sub_id) {
    var sen_pass;
    if (sub_id != null && sub_id != undefined && sub_id != "") {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: rel_id,
        sub_rel_id: sub_id,
        country: ipaddress.country_code
      });
    } else {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: rel_id,
        country: ipaddress.country_code
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/asgdet', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.setAsg(obj);
        });

  }

  getcasesheetDetails() {
    Helper_Class.setRet(null);
    Helper_Class.setpediaRet(null);

    if (Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != undefined &&
      Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id.length != 0) {
      var send_data = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: "IN",
      });

    } else {
      send_data = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: "IN",
      });
    }

    if (this.pediatricsFlag == true) {
      this.spl_url = 'pedia/chist';

    } else if (this.cardioFlag == true) {
      this.spl_url = 'cardio/chist';

    } else if (this.nephrologyFlag == true) {
      this.spl_url = 'neph/chist';

    } else if (this.dentaFlag == true) {
      this.spl_url = 'dental/perdet/';

    } else if (this.diabetesFlag == true) {
      this.spl_url = 'diab/chist';

    } else if (this.generalFlag == true) {
      this.spl_url = 'gen/chist';

    } else if (this.ENTFlag == true) {
      this.spl_url = 'ent/chist';

    } else if (this.gastroFlag == true) {
      this.spl_url = 'gast/chist';

    } else {
      this.spl_url = 'other/chist';
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.spl_url, send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj["key"] != 0) {
            if (obj != null || obj != "" || obj != undefined) {
              Helper_Class.setRet(obj);
              if (this.pediatricsFlag == true) {
                Helper_Class.setpediaRet(obj);
              }
              if (this.nephrologyFlag == true) {
                Helper_nephro_casesheet.nephro_get_ret = undefined;
                Helper_nephro_casesheet.personal_hist_array = undefined;
                Helper_nephro_casesheet.bmi_hist_array = undefined;
                Helper_nephro_casesheet.vitals_sit_array = undefined;
                Helper_nephro_casesheet.nephro_get_ret = obj;
              }

              if (this.dentaFlag == true) {
                Helper_Class.Set_den_ret1(obj);
                this.Dental_Retrieval_2();
              }
              if (this.gastroFlag == true) {
                if (obj.spl_name != undefined) {
                  Helper_Class.setOtherSpl(obj.spl_name);
                }
              }
              if (this.generalFlag == true) {
                if (obj.spl_name != undefined) {
                  Helper_Class.setOtherSpl(obj.spl_name);
                }
              }
              if (this.dentaFlag != true && this.nephrologyFlag != true) {
                this.doctormessageservice.sendMessage("casesheet_list");
              } else {
                this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
              }
            } else {
              this.doctormessageservice.sendMessage("casesheet");
            }

          } else {
            if (Doc_Helper.getCasesheet_flag() == "1") {
              this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
            } else {
              this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
            }
          }
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        })
  }

  Get_gynaretrieval_data() {
    Helper_Class.setgynaRet(undefined)
    Helper_Class.setRet(undefined)

    if (Doc_Helper.getClient_Info().sub_id.length != 0 && Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != undefined) {
      var sub_id_data = Doc_Helper.getClient_Info().sub_id;
      var sendata = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: sub_id_data,
        country: ipaddress.country_code
      });
    } else {
      sendata = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      })
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'goi/chist', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj["key"] != 0) {
            if (obj != null && obj != undefined && obj != "") {
              Helper_Class.setgynaRet(obj);
              Helper_Class.setRet(obj);
              this.doctormessageservice.sendMessage("casesheet_list");
            }
          } else {
            this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getCaseSheetFields(hptl_id, spl_id, doc_app_id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/gpfhsp/',
      JSON.stringify({
        hosp_id: hptl_id,
        spl_id: spl_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          Helper_Class.setHopitalfieldsRet(null);
          Helper_Class.setHopitalfieldsRet(obj.pages);

          this.get_case_sheet_data(this.doc_app_id)
        },//response
      )
  }

  get_case_sheet_data(doc_app_id) {
    // call cbook
    Doc_Helper.setDischarge_Summary(undefined);
    var sen_pass
    // if (Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id.length != 0) {
    if (Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id) {

      sen_pass = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code
      });

    } else {
      sen_pass = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'gen/cbook', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj["key"] != 0) {
            if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
              Doc_Helper.setDischarge_Summary("S");
              Helper_Class.setcasesumret(obj);
            } else {
              Helper_Class.setcasesumret(undefined);
            }
          } else {
            Helper_Class.setcasesumret(undefined);
          }
          if (this.gynecologyFlag == true) {
            const dialogRef = this.dialog.open(GynaObestricPopSelectionComponent, {
              width: '280px',
              height: '182px'
            });

          } else {
            if(Helper_Class.getRet() != undefined){
              this.doctormessageservice.sendMessage("casesheet_list");
            } else {
              this.doctormessageservice.sendMessage("casesheet");
            }
            
          }
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  Dental_Retrieval_2() {
    if (Doc_Helper.getClient_Info().sub_id.length != 0) {
      var sen_pass = JSON.stringify({
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code,
        den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
      });

    } else {
      var sen_pass = JSON.stringify({
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code,
        den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'dental/patdet/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          //  this.loader = true;
          var obj = response.json();
          Helper_Class.Set_den_ret2(obj);


          this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
        }
      )
  }

  getSpeciality() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'usercontroller/splbydoc/',
      JSON.stringify({
        doc_reg_id: this.user_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if (obj.specialization != null) {
            for (var i = 0; i < obj.specialization.length; i++) {
              this.splArray.push({
                spl_id: obj.specialization[i].spl_id,
                spl_name: obj.specialization[i].spl_name,
              })
            }
            this.splName = this.splArray[0].spl_name;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  checkUncheckAll() {
    this.checklist = this.appointment_list;
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.length == this.appin_open_count) {
        // uncheck all
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          var token = "", time = "";
          if (this.checklist[i].token_flag == true) {
            token = this.checklist[i].tot_app_count
          } else {
            time = this.checklist[i].tot_app_count;
          }
          this.checklistid.push({
            app_id: this.checklist[i].doc_app_id,
            session: this.checklist[i].session,
            app_date: this.checklist[i].app_date,
            app_time: time,
            first_name: this.checklist[i].dr_first_name,
            middle_name: this.checklist[i].dr_middle_name,
            last_name: this.checklist[i].dr_last_name,
            mobile: this.checklist[i].contact,
            token: token,
          });
          this.checklist[i].isSelected = this.masterSelected;
        }
      } else {
        //  check all with array not empty        
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].status == 'Open' || this.checklist[i].status == 'Confirmed') {
            var token = "", time = "";
            if (this.checklist[i].token_flag == true) {
              token = this.checklist[i].tot_app_count
            } else {
              time = this.checklist[i].tot_app_count;
            }
            this.checklistid.push({
              app_id: this.checklist[i].doc_app_id,
              session: this.checklist[i].session,
              app_date: this.checklist[i].app_date,
              app_time: time,
              first_name: this.checklist[i].dr_first_name,
              middle_name: this.checklist[i].dr_middle_name,
              last_name: this.checklist[i].dr_last_name,
              mobile: this.checklist[i].contact,
              token: token,
            });
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      // array empty
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].status == 'Open' || this.checklist[i].status == 'Confirmed') {
          var token = "", time = "";
          if (this.checklist[i].token_flag == true) {
            token = this.checklist[i].tot_app_count
          } else {
            time = this.checklist[i].tot_app_count;
          }
          this.checklistid.push({
            app_id: this.checklist[i].doc_app_id,
            session: this.checklist[i].session,
            app_date: this.checklist[i].app_date,
            app_time: time,
            first_name: this.checklist[i].dr_first_name,
            middle_name: this.checklist[i].dr_middle_name,
            last_name: this.checklist[i].dr_last_name,
            mobile: this.checklist[i].contact,
            token: token,
          });
          this.checklist[i].isSelected = this.masterSelected;
        }
      }

    }
  }

  isAllSelected(list) {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.indexOf(list) !== -1) {
        var index = this.checklistid.indexOf(list);
        this.checklistid.splice(index, 1);
      } else {
        var token = "", time = "";
        if (list.token_flag == true) {
          token = list.tot_app_count
        } else {
          time = list.tot_app_count;
        }
        this.checklistid.push({
          app_id: list.doc_app_id,
          session: list.session,
          app_date: list.app_date,
          app_time: time,
          first_name: list.dr_first_name,
          middle_name: list.dr_middle_name,
          last_name: list.dr_last_name,
          mobile: list.contact,
          token: token,
        });
      }
    } else {
      var token = "", time = "";
      if (list.token_flag == true) {
        token = list.tot_app_count
      } else {
        time = list.tot_app_count;
      }
      this.checklistid.push({
        app_id: list.doc_app_id,
        session: list.session,
        app_date: list.app_date,
        app_time: time,
        first_name: list.dr_first_name,
        middle_name: list.dr_middle_name,
        last_name: list.dr_last_name,
        mobile: list.contact,
        token: token,
      });
    }
    this.appid= list.doc_app_id;
    this.mobile = list.contact;
    this.client_id=list.client_reg_id;
    this.relation_id = list.relation_id;
    this.sub_rel_id = list.sub_rel_id;
  }

  getCheckedItemList() {

    //this.checkedList = this.checkedList;
    if (this.checklist.length == this.checklistid.length) {
      //this.checkedList = `All rows are selected`;
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checklistid.length != 0) {
      //this.checkedList = `selected Id: ${this.checkedList}`;
      this.toastr.success('Thank you...', ``);
    } else {
      this.checklistid = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.appointment_list;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];

  }

  // Function is defined
  hideloader() {
    document.getElementById('loading')
      .style.display = 'none';
  }

  Appointment_confirm() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: "doctor",
          doc_apps: this.checklistid,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
                this.get_doc_apps(this.user_id);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToConfirm);
    }

  }

  cancel() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      
      for (var i = 0; i < this.checklistid.length; i++) {
        this.cancelarray.push(this.checklistid[i].app_id);
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          doc_cancel: this.cancelarray,
          home_care: "0",
          // reason: data.rea_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.ngOnInit();
                // this.get_doc_apps(this.user_id);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }

  create_appointment() {
    this.doctormessageservice.sendMessage("docappcreate");
  }

  write_prescription() {
    this.doctormessageservice.sendMessage("docpresnocase");
    Doc_Helper.setAppFlow('apptList')
  }

  renderChart(values) {

    this.nutritionGrphData = [];

    var myChart = new Chart("piechart_gen", {
      type: 'doughnut',
      data: {
        labels: ['Awake', 'Deep', 'Light', 'Rem'],
        datasets: [{
          label: '# of Votes',
          data: Object.values(this.sleepGrphData[0]),
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
          ]
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'right',
          }
        }
      }
    });

    var myChart = new Chart("appt_activity_chrt", {
      type: 'doughnut',
      data: {
        labels: ['Actual', 'Expected'],
        datasets: [{
          label: '# of Votes',
          data: [values.kcal_burned, 1200],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
          ]
        }]
      },
      options: {
        cutoutPercentage: 80,
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'right',
          }
        }
      }
    });


    this.nutritionGrphData.push({
      carbs: values.carbs,
      fat: values.fat,
      proteins: values.proteins,
      energy: values.energy
    })

    console.log("nutriGrp", this.nutritionGrphData)

    var myChart = new Chart("appt_nutrition_chrt", {
      type: 'pie',
      data: {
        labels: ['Carbs', 'Fat', 'Proteins', 'Energy'],
        datasets: [{
          label: '# of Votes',
          data: Object.values(this.nutritionGrphData[0]),
          backgroundColor: [
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',

          ]
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'right',
          }
        }
      }
    });

  }
}
