import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Date_Formate } from '../../../assets/js/common.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $:any;
@Component({
  selector: 'app-bill-refund',
  templateUrl: './bill-refund.component.html',
  styleUrls: ['./bill-refund.component.scss']
})
export class BillRefundComponent implements OnInit {
  public filterType: string;
  public fromDate: string;
  public toDate: string;
  public dateFlag: boolean;
  public clientFlag: boolean;
  public searchFlag: boolean;
  public billRefundListFlag: boolean;

  public ClientFilterList = [];
  public filteredClientList = [];
  public clientList = [];
  public clntName: string;
  public middleName: string;
  public lastName: string;
  public clientId: string;
  public relationid: string;
  public subRelId: string;

  public billRefundList = [];
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public getDate;
  public hospId;
  private diagcenterid;
  public billtype;

  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  public messageservice:MenuViewService,public dialog:MatDialog) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.billtype="Hospital";
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.diagcenterid = Helper_Class.getInfo().diag_center_id;
    this.filterType="Date";
    this.dateFlag = true;
    this.clientFlag=true;
    this.searchFlag=true;
    this.billRefundListFlag=true;
    this.getCurrentDate();
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      ({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          //City_change
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate=  this.currentDate;
          this.toDate = this.currentDate;
          
          this.filterTypeChange();        
          this.getRefunds();
        },
        error => {});
  }

  getRefunds(){
    var centerid;
    if(this.billtype =="Hospital"){
      centerid=this.hospId
    } else {
      centerid=this.diagcenterid
    }

    var fdate;var tdate;
    if(this.fromDate != undefined){
      fdate = this.fromDate;
    }

    if(this.toDate != undefined){
      tdate = this.toDate;
    }
    var sendDate;
    if( this.dateFlag == false ){
      sendDate = {
        center_id: centerid,
        filter_type: this.filterType,
        from_date:fdate ,
        to_date: tdate,
        bill_type:this.billtype,
      }

    }else{
      sendDate = {
        center_id: centerid,
        filter_type: this.filterType,
        client_reg_id: this.clientId,
        relation_id: this.relationid,
        sub_rel_id: this.subRelId,
        bill_type:this.billtype,
      }
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gref/', sendDate,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.billRefundList=[];
          
          if(obj.refund_list != undefined && obj.refund_list.length !=0){
            this.billRefundListFlag = false;
            for(var i=0;i<obj.refund_list.length;i++){
              var clientname;
              if(obj.refund_list[i].middle_name !=undefined && encrypt_decript.Decript(obj.refund_list[i].first_name != null)){
                clientname=encrypt_decript.Decript(obj.refund_list[i].first_name+" "+encrypt_decript.Decript(obj.refund_list[i].middle_name+" "+encrypt_decript.Decript(obj.refund_list[i].last_name)))
              }else {
                if(obj.refund_list[i].last_name !=  undefined){
                  clientname=encrypt_decript.Decript(obj.refund_list[i].first_name+" "+encrypt_decript.Decript(obj.refund_list[i].last_name));
                }else{
                  clientname=encrypt_decript.Decript(obj.refund_list[i].first_name);
                } 
              }
              var provid;
              if(obj.refund_list[i].provider_id != undefined){
                provid = obj.refund_list[i].provider_id;
              } else {
                provid=this.diagcenterid;
              }
              //var time = obj.refund_list[i].created_time.split(':');           
              this.billRefundList.push({
                bill_id: obj.refund_list[i].bill_id,
                bill_cat: obj.refund_list[i].bill_cat,
                provider_id: provid,
                client_name: clientname,
                bill_amount: encrypt_decript.Decript(obj.refund_list[i].bill_amount),
                refund_amount: obj.refund_list[i].refund_amount,
                created_date: Date_Formate(obj.refund_list[i].created_date) ,
                remarks: obj.refund_list[i].remarks,
                fin_year:obj.refund_list[i].fin_year,
              })  
            } 
            console.log("this.billRefundList" + JSON.stringify(this.billRefundList));          
          } else{
            this.billRefundListFlag = true;
          }
        },
        error => {});
  }

  viewBill(billid, billtype, providerid,fin_year){
    FrontDesk_Helper.setBillsDetails(null);
    var getBillDetails = {
      bill_view_id: billid,
      provider_type: this.billtype,
      provider: providerid,
      type:"refund",
      fin_year:fin_year
    }
    
    FrontDesk_Helper.setBillsDetails(getBillDetails);
  //  this.messageservice.sendMessage("billingDetailsView");
    if(this.billtype == "Hospital"){
      this.messageservice.sendMessage("billingDetailsView");
      
    }else{
      this.messageservice.sendMessage("billingDiagDetailsView");
    } 
  }

  clientFilter() {
    this.ClientFilterList = [];
    this.clientList = [];
    this.middleName="";
    this.clntName = this.clntName.toLocaleUpperCase();
    this.lastName="";
    var clntname = encrypt_decript.Encript(this.clntName).toString();
    console.log("")
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbyadm/',
      ({
        client_name: clntname
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.clients != null) {
            this.clientList = [];
            this.ClientFilterList = obj.clients;

            for (var i = 0; i < this.ClientFilterList.length; i++) { 
              var lName = this.ClientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.ClientFilterList[i].last_name) : "";
              var mName = this.ClientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.ClientFilterList[i].middle_name) : "";

              this.filteredClientList.push({
                name: encrypt_decript.Decript(this.ClientFilterList[i].first_name) + " " + lName,
                first_name:encrypt_decript.Decript(this.ClientFilterList[i].first_name),
                last_name:lName,
                client_id: this.ClientFilterList[i].client_reg_id,
                relation_id: this.ClientFilterList[i].relation_id,
                sub_rel_id : this.ClientFilterList[i].sub_rel_id,
                middle_name : mName,
                
              });
            }
            // this.filteredClientList = this.clientList.filter(function (this:any,el) {
            //   return el.name.toLowerCase().indexOf(this.clnt_name_txt.toLowerCase()) > -1;
            // }.bind(this));
          }
        },
        error => {});
  }

  selectClient(data) {
    this.clntName = data.first_name;
    this.clientId=undefined;
    this.relationid=undefined;
    this.subRelId=undefined;
    for (var i = 0; i < this.ClientFilterList.length; i++) {
      if(this.ClientFilterList[i].client_reg_id == data.client_id && this.ClientFilterList[i].relation_id == data.relation_id){
        if(this.ClientFilterList[i].middle_name != undefined){
          this.middleName = encrypt_decript.Decript(this.ClientFilterList[i].middle_name);
        }
        if(this.ClientFilterList[i].last_name != undefined){
          this.lastName = encrypt_decript.Decript(this.ClientFilterList[i].last_name);
        }
       
        this.clientId=this.ClientFilterList[i].client_reg_id;
        this.relationid= this.ClientFilterList[i].relation_id;
        if(this.ClientFilterList[i].sub_rel_id != undefined && this.ClientFilterList[i].sub_rel_id != null){
          this.subRelId = this.ClientFilterList[i].sub_rel_id;
        }
      }
    }
    
    this.filteredClientList = [];
  }

  filterTypeChange(){
    if(this.filterType =="All"){
      this.dateFlag = true;
      this.clientFlag=true;
      this.searchFlag=true;
      this.clientId=undefined;
      this.relationid=undefined;
      this.subRelId = undefined;
    } else if(this.filterType =="Date"){
      this.dateFlag = false;
      this.clientFlag=true;
      this.searchFlag=false;
      this.clientId=undefined;
    } else if(this.filterType =="Client"){
      this.dateFlag = true;
      this.clientFlag=false;
      this.searchFlag=false;
    }
  }

  create() {
    this.messageservice.sendMessage("createRefund");
  }
}
