<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
              class="saveimgbtn_inpatinfo" />
            <img src="../../../assets/ui_icons/buttons/Allot_button.svg" class="saveimgbtn_inpatinfo "
              [hidden]="allotFlag" (click)="allot()" />
            <img src="../../../assets/ui_icons/buttons/Sample_collected.svg" class=" saveimgbtn_inpatinfo"
              [hidden]="sampleCollectFlag" (click)="sample()" />
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="saveimgbtn_inpatinfo"
              [hidden]="cancelAppFlag" (click)="cancel()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="Details">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="row" style="margin-bottom: 10px;">
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel" [hidden]="relationFalg">Relationship : {{relationType}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel"><b>Name</b> : {{clientName}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel"><b>Age</b> : {{age}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel"><b>Gender</b> : {{clientGender}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="bloodFlag">
                    <mat-label class="matlabel"><b>Blood group</b> : {{clientBlood}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel"><b>Phone</b> : {{clientMobile}}</mat-label>
                  </div>
                  <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel"><b>Website</b> : {{website_txt}}</mat-label>
                  </div> -->
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel"><b>Date :</b>{{date}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel" *ngIf="!referedBy"><b>Referred by :</b>{{diagDoctorName}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel"><b>Centre details :</b>{{centerName}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel"><b></b>{{diagAddress}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel">{{location}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel">{{cityZip}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel">{{state}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel">{{phone}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel">{{website}}</mat-label>
                  </div>
                </div>
              </div>
              <div class="col-12" [hidden]="diagTestArray.length==0" style="margin-top: 10px;margin-bottom: 10px;">
                <div class="dig_table_overflow">
                  <div class="table-responsive" *ngIf="diagTestArray.length != 0">
                    <table [hidden]="diagTestArray.length==0" id="card_tbl" class="table table-hover table-dynamic">
                      <thead>
                        <tr>
                          <th>Test Type</th>
                          <th>Test name</th>
                          <!-- <th>Last Visit </th> -->
                          <!-- <th style="text-align: center !important;">Status </th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let Diagnosis of diagTestArray">
                          <td style="text-align: left;">{{Diagnosis.Groupname}}</td>
                          <td style="text-align: left;">{{Diagnosis.Testname}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <p class="tervys_heading">Appointment details</p>
                <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel">Date
                    <input class="ipcss" type="date" [required]="dateRequiredFlag" [min]="currentDateTime"
                      [(ngModel)]="appointDate" (change)="changeAppoinmentDate(appointDate)" />
                  </mat-label>
                </div>
                <div [hidden]="sessionFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Session<br>
                    <mat-select disableOptionCentering class="ipcss" [(ngModel)]="session"
                      [disabled]="disableSessionFlag">
                      <mat-option *ngFor="let Session of sessionArray" value={{Session}}>{{Session}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Time</mat-label>
                  <div class="row">
                    <div class="col-4">
                      <mat-select disableOptionCentering class="ipcss " [disabled]="time1"
                        [(ngModel)]="fromFirstTimeArray">
                        <mat-option *ngFor="let name of fromTimeArray" value="{{name}}">{{name}}</mat-option>
                      </mat-select>
                    </div>
                    <div class="col-4">
                      <mat-select disableOptionCentering class="ipcss " [disabled]="time2" [(ngModel)]="fromtimeArray">
                        <mat-option *ngFor="let name of toTimingArray" value="{{name}}">{{name}}</mat-option>
                      </mat-select>
                    </div>
                    <div class="col-4" style="padding-right: 0px;">
                      <mat-select disableOptionCentering class="ipcss " [disabled]="time3" [(ngModel)]="timeAmpmHome">
                        <mat-option value="AM">AM</mat-option>
                        <mat-option value="PM">PM</mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>