import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Date_Formate } from '../../../assets/js/common.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { AdvanceCreateComponent } from '../advance-create/advance-create.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $ : any; 

@Component({
  selector: 'app-bill-advances',
  templateUrl: './bill-advances.component.html',
  styleUrls: ['./bill-advances.component.scss']
})
export class BillAdvancesComponent implements OnInit {
  public ClientFilterList = [];
  public clientList = [];
  public clntName: string;
  public middleName: string;
  public lastName: string;
  public clientId: string;
  public advanceCategory: string;
  public advanceCategoryflag:boolean;
  public filterFlag: Boolean;
  public fromDate: string;
  public toDate: string;
  public advanceList = [];
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public getDate;
  public hospId;
  public hospitalLogo;
  public hospitalName;
  public hospitalLocation;
  public hospitalCity;
  public hospitalState;
  public hospitalCountry;
  public hospitalPhNo;
  public hospitalAddr;
  public printTemplate;
  public patientId;


  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  public messageservice:MenuViewService,public dialog:MatDialog) {       
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    this.advanceCategoryflag = true;
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.advanceCategory = "All";
    this.getCurrentDate();
    this.hospitalName = Helper_Class.getInfo().hospitals[0].hptl_name;
    this.hospitalLocation = Helper_Class.getInfo().hospitals[0].location_desc;
    this.hospitalCity = Helper_Class.getInfo().hospitals[0].city_desc;
    this.hospitalState = Helper_Class.getInfo().hospitals[0].state_desc;
    this.hospitalCountry = Helper_Class.getInfo().hospitals[0].country_desc;
    this.hospitalPhNo = Helper_Class.getInfo().hospitals[0].telephone;
    this.hospitalAddr = Helper_Class.getInfo().hospitals[0].address2 != undefined ? Helper_Class.getInfo().hospitals[0].address1 + "," + Helper_Class.getInfo().hospitals[0].address2 : Helper_Class.getInfo().hospitals[0].address1;
    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;
    this.printTemplate = "logowithname";
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
  }

  fnameToUpper(){
    if(this.clntName != undefined){
      this.clntName = this.clntName.toLocaleUpperCase();
    }
  }

  mnameToUpper(){
    if(this.middleName != undefined){
      this.middleName = this.middleName.toLocaleUpperCase();
    }
  }

  lnameToUpper(){
    if(this.lastName != undefined){
      this.lastName = this.lastName.toLocaleUpperCase();
    } 
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
     {
        country: ipaddress.country_code
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          //City_change
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate = this.currentDate;
          this.toDate = this.currentDate;
          this.getAllAdvances();
        },
        error => {});
  }

  clientFilter() {
    this.ClientFilterList = [];
    this.clientList = [];
    this.clntName = this.clntName.toLocaleUpperCase();
    var clntname = this.clntName;

    if( this.clntName == ""){
      this.lastName = "";
      this.clientList = [];
      this.getAllAdvances();
    }else{
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbynamea/',{"client_name": clntname},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.clients != null) {
              this.clientList = [];
              this.ClientFilterList = obj.clients;
              for (var i = 0; i < this.ClientFilterList.length; i++) {
                this.clientList.push({
                  firstName : this.ClientFilterList[i].first_name,
                  lastName : this.ClientFilterList[i].last_name,
                  prov_id : this.ClientFilterList[i].prov_id
                });
              }
            }else{
              this.clientList = [];
            }
          },
          error => {});
    }
  }

  selectClient(data) {
    for (var i = 0; i < this.ClientFilterList.length; i++) {
      if(this.ClientFilterList[i].prov_id == data){
        this.clntName = this.ClientFilterList[i].first_name; 
        this.middleName = this.ClientFilterList[i].middle_name != undefined && this.ClientFilterList[i].middle_name != null && this.ClientFilterList[i].middle_name != ""  ? this.ClientFilterList[i].middle_name : "";
        this.lastName = this.ClientFilterList[i].last_name != undefined && this.ClientFilterList[i].last_name != null && this.ClientFilterList[i].last_name != ""  ? this.ClientFilterList[i].last_name : "";
        this.clientId = this.ClientFilterList[i].prov_id;
      }
    }
    this.clientList = [];
  }

  selectAdvanceCategory(){
    if(this.advanceCategory == "All"){
      this.advanceCategoryflag = true;
    } else{
      this.advanceCategoryflag = false;
      this.fromDate = this.currentDate;
      this.toDate = this.currentDate;
    }
  }

  getAllAdvances(){
    this.clientList = [];
    if(this.advanceCategory == undefined){
      this.advanceCategory = "All";
    }

    this.advanceList = [];
    var sentdata = {
      hptl_clinic_id: this.hospId,
        adv_cat_type: this.advanceCategory
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gaadv/',sentdata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.advances != null) {
            for(var i = 0; i < obj.advances.length; i++){
              var clientname = "";
              if(obj.advances[i].middle_name != undefined && encrypt_decript.Decript(obj.advances[i].middle_name) != null){
                clientname = encrypt_decript.Decript(obj.advances[i].first_name)+" "+encrypt_decript.Decript(obj.advances[i].middle_name)+" "+encrypt_decript.Decript(obj.advances[i].last_name);
              } else {
                clientname = encrypt_decript.Decript(obj.advances[i].first_name)+" "+encrypt_decript.Decript(obj.advances[i].last_name);
              }
              var clientAddr = "";
              // if(obj.advances[i].address2 != undefined && (encrypt_decript.Decript(obj.advances[i].address2) != ""))
              //   clientAddr = encrypt_decript.Decript(obj.advances[i].address1) + "," + encrypt_decript.Decript(obj.advances[i].address2);
              
              if( obj.advances[i].address2 != undefined ){
                clientAddr = encrypt_decript.Decript(obj.advances[i].address1) + "," + encrypt_decript.Decript(obj.advances[i].address2);
              }else{
                clientAddr = encrypt_decript.Decript(obj.advances[i].address1);
              }
            
              var gender;
              if(obj.advances[i].gender != undefined && (encrypt_decript.Decript(obj.advances[i].gender) != "" ))
                gender = encrypt_decript.Decript(obj.advances[i].gender);

              var zcode;
              if(obj.advances[i].zipcode != undefined && (encrypt_decript.Decript(obj.advances[i].zipcode) != "" ))
                zcode = encrypt_decript.Decript(obj.advances[i].zipcode);

              var appID = obj.advances[i].appointment_id != undefined ? obj.advances[i].appointment_id : "";
                var docName;
                if( obj.advances[i].dr_first_name != undefined){
                    if( obj.advances[i].dr_middle_name != undefined ){
                        docName = obj.advances[i].dr_first_name +" "+obj.advances[i].dr_middle_name +" "+obj.advances[i].dr_last_name;
                    }else  {
                      if( obj.advances[i].dr_last_name != undefined ){
                        docName = obj.advances[i].dr_first_name +" "+obj.advances[i].dr_last_name;
                      }else{
                        docName = obj.advances[i].dr_first_name
                      }
                    }
                }else{
                  docName = " ";
                }
              var payType = obj.advances[i].pay_desc != undefined ? obj.advances[i].pay_desc : " ";
              this.advanceList.push({
                patient_id:obj.advances[i].patient_id,
                client: clientname,
                advance: obj.advances[i].advance,
                balance: obj.advances[i].balance,
                created_date: Date_Formate(obj.advances[i].created_date),
                created_time: obj.advances[i].created_time,
                age:obj.advances[i].age,
                gender:gender,
                clientAddr: clientAddr,
                location:obj.advances[i].location,
                city:obj.advances[i].district,
                state:obj.advances[i].state,
                country:obj.advances[i].country,
                app_id : appID,
                zipcode:zcode,
                paymentType : payType
                //doctorName : docName,
              });
            }
          }
        },error => {});
  }

  filter(){
    this.advanceList=[];
    if(this.advanceCategory == undefined){
      this.advanceCategory ="All";
    }
    
    if(this.advanceCategory != undefined && this.advanceCategory =="Date"){
      if(this.fromDate != undefined && this.toDate != undefined){
        this.filterFlag = false;
      } else{
        this.filterFlag = true;
      }
    } else{
      this.filterFlag = false;
    }

    var fdate;
    var tdate;
    if(this.fromDate != undefined && this.toDate != undefined){
      fdate = this.fromDate;
      tdate = this.toDate;
    }

    if(this.filterFlag == true){
      this.toastr.error(Message_data.selectFromAndToDate);
    } else{
      this.advanceList = [];
      var sentdata = {
        hptl_clinic_id: this.hospId,
        client_reg_id: this.clientId,
        adv_cat_type: this.advanceCategory,
        from_date: fdate,
        to_date: tdate,
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/gaadv/',sentdata,
        { headers: headers })
        .subscribe(
          response => {
            this.advanceList=[];
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.advances != null) {
              for(var i=0; i<obj.advances.length;i++){
                var clientname;
                if(obj.advances[i].middle_name != undefined && encrypt_decript.Decript(obj.advances[i].middle_name) != null){
                  clientname = encrypt_decript.Decript(obj.advances[i].first_name)+" "+encrypt_decript.Decript(obj.advances[i].middle_name)+" "+encrypt_decript.Decript(obj.advances[i].last_name);
                } else {
                  if(obj.advances[i].last_name != undefined && encrypt_decript.Decript(obj.advances[i].last_name) != null){
                    clientname = encrypt_decript.Decript(obj.advances[i].first_name)+" "+encrypt_decript.Decript(obj.advances[i].last_name);
                  }else{
                    clientname = encrypt_decript.Decript(obj.advances[i].first_name);
                  }
                }

                var docName;
                if( obj.advances[i].dr_first_name != undefined){
                    if( obj.advances[i].dr_middle_name != undefined ){
                        docName = obj.advances[i].dr_first_name +" "+obj.advances[i].dr_middle_name +" "+obj.advances[i].dr_last_name;
                    }else  {
                      if( obj.advances[i].dr_last_name != undefined ){
                        docName = obj.advances[i].dr_first_name +" "+obj.advances[i].dr_last_name;
                      }else{
                        docName = obj.advances[i].dr_first_name
                      }
                    }
                }else{
                  docName = " ";
                }
  
                var clientAddr = "";
                if( obj.advances[i].address2 != undefined ){
                  clientAddr = encrypt_decript.Decript(obj.advances[i].address1) + "," + encrypt_decript.Decript(obj.advances[i].address2);
                } else{
                  if(obj.advances[i].address1 != undefined){
                    clientAddr = encrypt_decript.Decript(obj.advances[i].address1);
                  }
                }

                var gender;
                if(obj.advances[i].gender != undefined && (encrypt_decript.Decript(obj.advances[i].gender) != "" ))
                gender = encrypt_decript.Decript(obj.advances[i].gender);
  
                var zcode;
                if(obj.advances[i].zipcode != undefined && (encrypt_decript.Decript(obj.advances[i].zipcode) != "" ))
                zcode = encrypt_decript.Decript(obj.advances[i].zipcode);
  
                this.advanceList.push({
                  patient_id:obj.advances[i].patient_id,
                  client: clientname,
                  advance: obj.advances[i].advance,
                  balance: obj.advances[i].balance,
                  created_date: Date_Formate(obj.advances[i].created_date),
                  created_time: obj.advances[i].created_time,
                  age:obj.advances[i].age,
                  gender:gender,
                  clientAddr:clientAddr,
                  location:obj.advances[i].location,
                  city:obj.advances[i].district,
                  state:obj.advances[i].state,
                  country:obj.advances[i].country,
                  zipcode:zcode,
                  app_id : obj.advances[i].appointment_id,
                });
              }
            }
          },
          error => {});
    }
  }

  advCreate() {
    const dialogRef = this.dialog.open(AdvanceCreateComponent, {
      width: '650px',
      height: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  print(advance){
    let printContents, popupWin;
   
    var getBillsList = this.advanceList.filter((x:any) => { return x.app_id == advance.app_id });
    const totalAdvance = (getBillsList.reduce((partialSum, a) => partialSum + parseFloat(a.advance), 0)).toFixed(2);
    const totalBalance = (getBillsList.reduce((partialSum, a) => partialSum + parseFloat(a.balance), 0)).toFixed(2);
    console.log( "getBillsList" + JSON.stringify(getBillsList) );
    var billRows = "";
    for(var i = 0; i < getBillsList.length; i++){
      billRows += `<tr style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> ${ i + 1 } </td> <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> ${getBillsList[i].created_date} </td><td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> ${getBillsList[i].paymentType} </td><td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> ${getBillsList[i].created_time} </td><td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> ${getBillsList[i].advance} </td><td style="border: 1px solid gray;border-collapse: collapse;text-align:center;"> ${getBillsList[i].balance} </td></tr>`;
    }
    var billsTable = `<table style="margin-left: 75px; margin-top: 50px;border: 1px solid gray;width: 70%;border-collapse: collapse;"> <thead><tr style="border: 1px solid gray;border-collapse: collapse; "><th > Si.No  </th><th>Date</th><th>Payment type</th><th>Time</th><th>Advance</th><th>Balance</th></tr></thead> <tbody> ${billRows} <tr> <td  colspan="4" style="border: 1px solid gray;border-collapse: collapse; text-align:center;"> Total advance & Total balance </td> <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> ${totalAdvance} </td> <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> ${totalBalance} </td> </tr> </table>`;
    console.log(billsTable)
    var printContt = '<div #logowithname id="logowithname">';
    var hospitalAddress = hospitalAddress != '' ? '<span>' + this.hospitalAddr + '</span>' : "";
    var telephone = this.hospitalPhNo != undefined ? '<span ><img src="../../../assets/img/phone.png" width="15px" height="15px" />' + this.hospitalPhNo + '.</span>' : "";

    var clientAddr
    var thead = "";
    thead = '<table class="report-container" style="width: 100%;"'+'<thead class="report-header">'+ '<tr>' + '<td style="display: flex;justify-content: center;">'+ '<div>' + '<img alt="image" src = '+ this.hospitalLogo + ' width="100px" height="100px" style="vertical-align: middle;">'+ '</div>'+ '<div style="position: relative;top: 20px;left: 10px;text-align: left;">' + '<p><b>'+ this.hospitalName + '</b>,</p>' + '<span>' + hospitalAddress + '</span><br/>' + '<span>' + this.hospitalLocation + ',</span>'+ '<span>' + this.hospitalCity + ',</span>' + '<span>' + this.hospitalState + ',</span><br/><span>' + this.hospitalCountry + '.</span>' + '<span>' + telephone + ' &nbsp;&nbsp;</span>' + '</div>' + '</td>' + '</tr>' + '</thead>';

    printContt +=  thead + '<tfoot class="report-footer"><tr><td style="height:100px;"></td></tr></tfoot>' + '<tbody class="report-content">' + '<tr>' + '<td class="report-content-cell">' + '<div class="main">' + '<table style="margin-left: 75px;width: 100%;"><tr>' + '<td style="width: 50%;">' + '<h3 class="heading">Patient details</h3><div>' + '<p style="margin: 0;"><strong>Patient name : </strong>' + advance.client +'</p>' + '<p style="margin: 0;"><strong> UHID : </strong>' + advance.patient_id + '</p>' + '<p style="margin: 0;"> <span> <strong> Age : </strong>' + advance.age + '</span></p><p style="margin: 0;"><strong> Gender : </strong>' + advance.gender + '</p></div></td> <td style="width: 50%;"><div class="amount_duecol-12"><span><strong><br />Address</strong></span> <br/>' + '<span>'+ advance.clientAddr +' </span>' +'<br /><span> ' + advance.location + "," + advance.city + ',</span><br/> '+ '<span>' + advance.state + ',' + advance.country + " - " + advance.zipcode + '</span></div>' + '</td></tr></table> '+billsTable+' </div></div>';
    // <p style="margin: 0;"><strong> Payment Type : </strong>' + advance.paymentType + '</p>
//  <table style="margin-left: 75px; margin-top: 50px;border: 1px solid gray;width: 70%;">' + ' <tr style="margin:15px;"><td><div><p><b>Advance (र) : </b> ' +  advance.advance + '</p></div></td>' + '<td style="text-align: center;"><div><p><b>Balance (र) :  </b> '+ advance.balance + '</p></div></td></tr></table>
    printContents = printContt;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <head>
        <title>Order</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        table.report-container {
          page-break-after:always;
      }
      thead.report-header {
          display:table-header-group;
      }
      tfoot.report-footer {
          display:table-footer-group;
      } 
      tfoot > tr > td.multiColumn {
        position: relative;
        z-index: 1;
        border:none !important;
        }
      p{
        margin:0;
      }
      table { page-break-inside:auto }
      tr{ page-break-inside:avoid; page-break-after:auto }
        
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
          ${printContents}
          </body>
        </html>`
    );
    popupWin.document.close();
  }
  
}
