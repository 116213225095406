import { Component, OnInit } from '@angular/core';
import { Helper_Class } from 'src/app/helper_class';
import { ServerApi } from '../../server-api';
import { ipaddress } from '../../ipaddress';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { DietService } from '../diet-view/diet-view.service';
import { Diet_Helper } from '../Diet_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-doc-app-list',
  templateUrl: './doc-app-list.component.html',
  styleUrls: ['./doc-app-list.component.scss']
})
export class DocAppListComponent implements OnInit {
  public userInfo;
  public hospitalData
  public session;
  public doctorName;
  public doctorList = [];
  public appointmentDate;
  public currentDate;
  public currentMaxDate
  public doctor;
  public patientName;
  public sendPatientName;
  public tokenNo;
  public appTime;
  public splitApptime;
  public appointmentList = [];
  dtOptions: DataTables.Settings = {};
  checklist: any;
  public apptOpenCount: number = 0;
  public ipaddress;

  constructor(public serviceAPI: ServerApi,public dietservice:MenuViewService) { }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.hospitalData = this.userInfo.hospitals[0];
    this.userInfo = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();
    this.getCurrentDate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }

  async getCurrentDate() {
    var response = await this.serviceAPI.PostData('adm/curdate', { country: ipaddress.country_code }).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if (obj.current_date != null) {
        this.currentDate = obj.current_date;
        this.appointmentDate = this.currentDate;
        this.getReferralData();
      } 
    }
  }

  async getReferralData() {
    var sendData = {
      country: "IN",
      reg_id: this.userInfo.user_id,
      imei: this.ipaddress,
      type: "dietician",
      is_dietician : "1",
    }
    var response = await this.serviceAPI.PostData('adm/docbyrep', sendData).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if(obj != null){
        for (var i = 0; i < obj.doctors.length; i++) {
          this.doctorName = "";
          var fname,mname,lname;
          if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
            this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
            fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
          } else {
            this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
            fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
          }
          this.doctorList.push({
            Doc_Name: this.doctorName,
            Doc_ID: obj.doctors[i].prov_id,
          });
        }
        this.doctor = this.doctorList[0].Doc_ID;
        this.selectReferredDoctor();
      }
    }
  }
 
  async selectReferredDoctor() {
    this.appointmentList = [];
    var sendData = {
      date: this.appointmentDate,
      doc_prov_id: this.doctor,
      home_care: "0",
      country: ipaddress.country_code,
      hptl_clinic_id: this.hospitalData.hptl_clinic_id,
      type: "dietician",
      is_dietician : "1",
      imei: this.userInfo.user_id,
    }
    var response = await this.serviceAPI.PostData('appointment/getdocapp', sendData).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
    if (obj.appointments != null && obj.appointments.length != 0 && obj.appointments[0] != null) {
      for (var i = 0; i < obj.appointments.length; i++) {
        this.patientName = "";

        if (obj.appointments[i].middle_name != null && encrypt_decript.Decript(obj.appointments[i].middle_name) != "") {
          this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " +
            encrypt_decript.Decript(obj.appointments[i].middle_name) + " " +
            encrypt_decript.Decript(obj.appointments[i].last_name);
        } else {
          if (obj.appointments[i].last_name != undefined) {
            this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " +
              encrypt_decript.Decript(obj.appointments[i].last_name);
          } else {
            this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name);
          }
        }

        this.sendPatientName = "";
        this.tokenNo = "";
        if (obj.appointments[i].f_time == "1" && obj.appointments[i].token != null && obj.appointments[i].token != undefined && obj.appointments[i].token != "") {
          this.tokenNo = obj.appointments[i].token;
        }
        if (obj.appointments[i].f_time == "1" && obj.appointments[i].token != null && obj.appointments[i].token != undefined && obj.appointments[i].token != "") {
          this.sendPatientName = this.patientName;
        }else {
          this.appTime = "";
          this.splitApptime = obj.appointments[i].f_time.split(":");
          if (encrypt_decript.Decript(obj.appointments[i].session).toString() == "Morning") {
            if (this.splitApptime[0] == "12") {
              this.appTime = this.splitApptime[0] + ":" + this.splitApptime[1] + " PM";
            } else {
              this.appTime = this.splitApptime[0] + ":" + this.splitApptime[1] + " AM";
            }
          } else {
            this.appTime = this.splitApptime[0] + ":" + this.splitApptime[1] + " PM";
          }
          this.sendPatientName = this.patientName;
          this.tokenNo = this.appTime;
        }

        if (obj.appointments[i].session != undefined) {
          this.session = encrypt_decript.Decript(obj.appointments[i].session);
        }
        if (obj.appointments[i].status == '1' || obj.appointments[i].status == '2') {
          this.apptOpenCount = this.apptOpenCount + 1;
        }
        var status_txt;
        if (obj.appointments[i].status == "0") {
          status_txt = "Completed";
        } else if (obj.appointments[i].status == "1") {
          if (obj.appointments[i].date == this.currentDate || this.currentDate < obj.appointments[i].date) {
            if (obj.appointments[i].f_time != "1") {
              var get_time = new Date(obj.appointments[i].date + " " + this.currentDate);
              var cur_time = new Date(this.currentDate + " " + this.currentDate);
              if (get_time > cur_time) {
                status_txt = "Not visited";
              } else {
                status_txt = "Open";
              }
            } else {
              var get_time = new Date(obj.appointments[i].date);
              var cur_time = new Date(this.currentDate);
              if (get_time >= cur_time) {
                status_txt = "Open";
              } else {
                status_txt = "Not visited";
              }
            }
          } else {
            if (obj.appointments[i].date > this.currentDate || obj.appointments[i].date == this.currentDate) {
              status_txt = "Open";
            } else {
              status_txt = "Not visited";
            }
          }
        } else if (obj.appointments[i].status == "2") {
          if (obj.appointments[i].date == this.currentDate || this.currentDate < obj.appointments[i].date) {
            var get_time = new Date(obj.appointments[i].date + " " + this.currentDate);
            var cur_time = new Date(this.currentDate + " " + this.currentDate);
            status_txt = "Confirmed";
          } else {
            if (obj.appointments[i].date > this.currentDate || obj.appointments[i].date == this.currentDate) {
              status_txt = "Open";
            } else {
              status_txt = "Not visited";
            }
          }
        }

          this.appointmentList.push({
            client_reg_id:obj.appointments[i].client_reg_id,
            relation_id:obj.appointments[i].relation_id,
            sub_rel_id:obj.appointments[i].sub_rel_id,
            spl:obj.appointments[i].specialization,
            Pro_Image: ipaddress.Ip_with_img_address + obj.appointments[i].profile_image,
            token: this.tokenNo,
            Display: this.sendPatientName,
            Docappid: obj.appointments[i].doc_app_id,
            session: this.session,
            pat_id: obj.appointments[i].pat_id,
            contact: encrypt_decript.Decript(obj.appointments[i].mobile),
            dr_first_name: obj.appointments[i].doc_first_name,
            dr_middle_name: obj.appointments[i].doc_middle_name,
            dr_last_name: obj.appointments[i].doc_last_name,
            app_time: this.appTime,
            app_date: obj.appointments[i].date,
            status: obj.appointments[i].status,
            status_txt: status_txt,
            disease : obj.appointments[i].disease,
            specialization_id : obj.appointments[i].specialization_id,
            life_style_id : obj.appointments[i].life_style_id,
            old_casesheet:obj.appointments[i].old_casesheet,
          });
        }
      }
    }
  }

  viewAppt(list,app_id, session, Appointment_Date) {
    Diet_Helper.setClient_Info(undefined);
    var view_appointments = ({
      doc_app_id: app_id,
      session: session,
      Appointment_Date: Appointment_Date,
      doc_name: this.doctor,
      Client_id:list.client_reg_id,
      rel_id:list.relation_id,
      sub_id:list.sub_rel_id,
      spl:list.spl,
      disease : list.disease,
      specialization_id : list.specialization_id,
      life_style_id : list.life_style_id,
      AppFlow:"DoctorApp",
    });
    Diet_Helper.setClient_Info(view_appointments);
    Helper_Class.setOldCasesheet(list.old_casesheet)
    this.getCasesheetData();   
  }

  async getCasesheetData() {
    // call cbook
    Diet_Helper.setDischarge_Summary(undefined);
    var sen_pass
    if (Diet_Helper.getClient_Info().sub_id != null && Diet_Helper.getClient_Info().sub_id != "" && Diet_Helper.getClient_Info().sub_id) {
      sen_pass = {
        doc_reg_id: this.userInfo.user_id,
        client_reg_id: Diet_Helper.getClient_Info().Client_id,
        relation_id: Diet_Helper.getClient_Info().rel_id,
        sub_rel_id: Diet_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code
      };
    } else {
      sen_pass = {
        doc_reg_id: this.userInfo.user_id,
        client_reg_id: Diet_Helper.getClient_Info().Client_id,
        relation_id: Diet_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      };
    }

    var response = await this.serviceAPI.PostData('gen/cbook', sen_pass).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
        Diet_Helper.setDischarge_Summary("S");
        Helper_Class.setcasesumret(obj);
      }else {
        Helper_Class.setcasesumret(undefined);
      }
    this.dietservice.sendMessage("casesheet");
    }
  }

  selectAppointmentDate(e) {
    this.appointmentDate = e;
    this.selectReferredDoctor();
  }
}
