<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Refunds</h5>
                    </div>
                    <div class="headerButtons">
                        <a><img src="../../../assets/ui_icons/buttons/new_button.svg" (click)="create()"
                                class="saveimgbtn_inpatinfo"></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                        Refunds details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 select_bottom">
                                <mat-label class="matlabel">Bill type<br>
                                    <select required class="ipcss" [(ngModel)]="billtype">
                                        <option>Hospital</option>
                                        <option>Diagnosis</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 select_bottom">
                                <mat-label class="matlabel">Filter by<br>
                                    <select required class="ipcss" [(ngModel)]="filterType"
                                        (change)="filterTypeChange()">
                                        <option>Client</option>
                                        <option>Date</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom"
                                [hidden]="clientFlag">
                                <mat-label class="matlabel">First name<br>
                                    <input [(ngModel)]="clntName" class="ipcss" required maxlength="50"
                                        (keyup)=clientFilter()>
                                    <div class="auto_complete_text" *ngIf="filteredClientList.length > 0">
                                        <ul *ngFor="let item of filteredClientList">
                                            <li>
                                                <a (click)="selectClient(item)">{{item.name}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-label>
                            </div>
                            <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom"
                                [hidden]="clientFlag">
                                <mat-label class="matlabel">Middle name<br>
                                    <input matInput class="ipcss widthbilllist" disabled type="text"
                                        [(ngModel)]="middleName" style="margin-bottom: 10px;" />
                                </mat-label>
                            </div> -->
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom"
                                [hidden]="clientFlag">
                                <mat-label class="matlabel">Last name<br>
                                    <input matInput class="ipcss widthbilllist" disabled type="text" required
                                        [(ngModel)]="lastName" style="margin-bottom: 10px;" />
                                </mat-label>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="dateFlag">
                                <mat-label class="matlabel">From date<br>
                                    <input type="date" class="ipcss_date widthbilllist datecss" required
                                        [(ngModel)]="fromDate" max="{{currentDate}}" matInput>
                                </mat-label>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="dateFlag">
                                <mat-label class="matlabel">To date<br>
                                    <input type="date" class="ipcss_date widthbilllist datecss" required
                                        [(ngModel)]="toDate" max="{{currentDate}}" matInput>
                                </mat-label>
                            </div>
                            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" style="display: flex;
                            margin-top: 25px;" [hidden]="searchFlag">
                                <img style="width: 25px; height: 25px;" (click)="getRefunds()"
                                    src="../../../assets/img/search.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="billRefundList.length!=0" class="nodata">No refund details found</div>
                <div [hidden]="billRefundListFlag" class="dig_table_overflow">
                    <table class="card_tbl table-dynamic">
                        <thead>
                            <tr>
                                <th>Bill no</th>
                                <th>Client name</th>
                                <th>Bill amount</th>
                                <th>Refund amount</th>
                                <th>Refund date</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let bill of billRefundList"
                                (click)="viewBill(bill.bill_id, bill.bill_cat, bill.provider_id,bill.fin_year)">
                                <td>{{bill.bill_id}}</td>
                                <td>{{bill.client_name}}</td>
                                <td>{{bill.bill_amount}}</td>
                                <td>{{bill.refund_amount}}</td> 
                                <td>{{bill.created_date}}</td>
                                <td>{{bill.remarks}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>