<div class="row" style="margin: 0 auto; width: 100%;">
  <div class="col-12" *ngIf="Diag_Location_Data.length > 1">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" style="margin-top: 14px;margin-left: 9px;">
        <mat-label class="matlabel">Diagnosis center<br>
          <select disableOptionCentering class="ipcss " [(ngModel)]="Location_txt"
            (change)="changeDigCenter(Location_txt)">
            <option *ngFor="let loc of Diag_Location_Data" value={{loc.view_data}}>{{loc.view_data}}
            </option>
          </select>
        </mat-label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Tests</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="testCreate()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12">
            <div class="switch-field" *ngIf="ynopt1 != undefined">
              <input type="radio" id="radio-three" (click)="change_type(1,'test')" />
              <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                <div class="tab_cover">
                  <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Outpatient.svg" class="iocinStyle"
                      alt=""></div>
                  <div class="lableStyle">Tests</div>
                </div>
              </label>
              <input type="radio" id="radio-four" (click)="change_type(1,'pack')" />
              <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                <div class="tab_cover">
                  <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Inpatient.svg" class="iocinStyle"
                      alt=""></div>
                  <div class="lableStyle">Packages</div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <br/>
        <div class="row" [hidden]="test_flag">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="nodata" *ngIf="Diag_Test_list_Data.length == 0">No Test(s) Found</p>

            <table *ngIf="Diag_Test_list_Data.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable" id="diaTestTable">
              <thead>
                <tr>
                  <th>Test name</th>
                  <th>Test category</th>
                  <th>Disease category </th>
                  <th>Test type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of Diag_Test_list_Data; let i = index">
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id)">{{person.test_name}}</td>
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id)">{{person.test_category}}
                  </td>
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id)">{{person.dis_category}}
                  </td>
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id)">{{person.test_type_name}}
                  </td>
                  <td style="font-size: 12px;text-align: center;"><img class="deleteimg"
                      (click)="testDelete(person.diag_tests_id)"
                      src="../../../assets/ui_icons/buttons/trash_icon.svg" />

                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row" [hidden]="!test_flag">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="nodata" *ngIf="packageData.length == 0">No Package(s) Found</p>

            <table *ngIf="packageData.length" mdbTable datatable [dtOptions]="dtOptions1"
              class="table table-nowrap table-sm dataTable" id="diaTestTable">
              <thead>
                <tr>
                  <th>Package name</th>
                  <th>Description</th>
                  <th>Price </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let package of packageData; let i = index">
                  <td style="font-size: 12px;" (click)="packageView(package.diag_tests_id)">{{package.package_name}}</td>
                  <td style="font-size: 12px;" (click)="packageView(package.diag_tests_id)">{{package.package_desc}}
                  </td>
                  <td style="font-size: 12px;" (click)="packageView(package.diag_tests_id)">{{package.price}}
                  </td>
                 
                  <td style="font-size: 12px;text-align: center;">
                    <img class="deleteimg" (click)="packageEdit(package)" src="../../../assets/ui_icons/buttons/pencil_icon.svg" /> &nbsp;
                    <img class="deleteimg" (click)="packageDelete(package.diag_packages_id)" src="../../../assets/ui_icons/buttons/trash_icon.svg" />

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>