import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery/dist/jquery.min.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common.js';
import { Message_data } from '../../../assets/js/Message_data';
import {FormControl} from '@angular/forms';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-front-desk-registration',
  templateUrl: './front-desk-registration.component.html',
  styleUrls: ['./front-desk-registration.component.css']
})
export class FrontDeskRegistrationComponent implements OnInit {
  tmr = new Date();
  public hosp_name_txt: string;
  public hosp_dist;
  public hosp_loc_txt: string;
  public hosp_id;
  public hosp_state;
  public hosp_country;
  public img_str = null;
  public Doctor_DOB=null;
  public CurrentDatetime;
  public security_txt;
  public password_string;
  public Create_password;
  public togg_home: boolean;

  public f_name: string;
  public m_name: string;
  public l_name: string;
  public profile_img: string;

  public dob: string;
  public Doc_mobile: string;
  public Doc_email: string;
  public gend_txt: string;
  public Update_img;
  public Imagepath;
  public Date_ofbirth;

  public registeration_code: string;
  public registeration_year: string;
  public registeration_state: string;
  public client_call: boolean;

  public city_url;
  public state_url;
  public country_url;

  public mobile_str: string;
  public email_str: string;

  public confirm_pwd;
  public country_id: string;
  public get_date;
  public pro_img;
  public header_footer_flag: boolean;
  public count_txt: string;
  public hospital_txt = [];
  public location_txt = []
  public filt_city_desc;
  public filt_state_desc;
  public loc_id: string;

  public country_desc: string;

  public location_filterlist = [];
  public filteredlocList = [];
  public filteredhospList = [];
  public hospital_filterlist = [];

  public locationList;
  public location_search_items;

  public city_list_data;
  public state_list_data;
  public country_list_data;
  public clnt_location;

  public Location_array = [];
  public Search_City: string
  public Get_city_arry = [];
  public State_data = [];
  public Search_State: string;
  public Countrydata = [];
  public Current_Year;
  public Recept_Age;
  public Reg_profile_tab;
  public Reg_work_tab;
  public Registration_work_add;

  public get_packdata;
  public agree: boolean;
  public showsupload:boolean = false;
  public save_flag:boolean = false;

  public docAge:string;
  public ageRead: boolean=false;
  public ageDisabledFlag: boolean=false;
  public ageUrl: string;

  constructor(public http:Http,public routes:ActivatedRoute,public router:Router,public toastr: ToastrService) {
    this.tmr.setDate(this.tmr.getDate() + 0);
    this.city_url = ipaddress.getIp + "gen/city";
    this.state_url = ipaddress.getIp + "gen/state";
    this.country_url = ipaddress.getIp + "gen/count";
    this.ageUrl = ipaddress.getIp + "adm/ddif";

    this.hosp_id = 0;
    this.hosp_loc_txt = "";
  }

  isReadonly() { return true; }

  ngOnInit(): void {
    this.Imagepath = "default";
    this.Get_current_date();
  }

  Date_DOB(e){
    this.Doctor_DOB = e;

    this.ageRead = true;
    if(e != null)
    this.ageDisabledFlag = true;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, JSON.stringify({
      dobdate: Date_Formate(this.Doctor_DOB),
      curdate: Date_Formate(this.CurrentDatetime),

    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj != undefined)
          this.docAge = obj.value;
      });
  }

  Get_current_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            this.Current_Year = obj.current_date.split('-')[0];
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

   
  DateChange(data) {//calendar
    this.Doctor_DOB = data;
  }

  getDateMatePicker(e){
    var month = ((e.value.getMonth() + 1) < 10 ? '0' : '') + (e.value.getMonth() + 1);
    var day = (e.value.getDate() < 10 ? '0' : '') + e.value.getDate();
    var apt =e.value.getFullYear()+"-"+ month +"-" + day;
    return apt.trim();
  }

  ChangeMatePicker(date_value){
    var apt =new FormControl(new Date(date_value));
    return apt;
  }

  public createFileName() {
    var d = new Date(),
    n = d.getTime(),
    newFileName = n + ".jpg";
    return newFileName;
  }
  
  convertToBase64(url, outputFormat) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        let canvas = <HTMLCanvasElement>document.createElement('CANVAS'),
          ctx = canvas.getContext('2d'),
          dataURL;

        var MAX_WIDTH = 300;
        var MAX_HEIGHT = 350;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        dataURL = canvas.toDataURL(outputFormat);
        canvas = null;
        resolve(dataURL);
      };
      img.src = url;
    });
  }
  
  public pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      // return cordova.file.dataDirectory + img;
    }
  }
  
  fileChange($event): void {
    this.readThis($event.target);
  }
  
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.img_str = null;
      }else {
        this.img_str = myReader.result;
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }
    
  hospital_filter(e) {
    this.city_list_data = [];
    this.state_list_data = [];
    this.country_list_data = [];
    this.filteredlocList = [];

    this.hosp_loc_txt = '';
    this.hosp_dist = '';
    this.hosp_state = '';
    this.hosp_country = '';
    this.hospital_txt = [];
    this.hospital_filterlist = [];
    this.hosp_name_txt = e.target.value.toString();
    
    if (this.hosp_name_txt !== "" && this.hosp_name_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() +'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hosp_name_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            
            if (obj.hospitals != null) {
              this.hospital_filterlist = [];
              this.hospital_filterlist = obj.hospitals;
              for (var i = 0; i < this.hospital_filterlist.length; i++) {
                this.hospital_txt.push(this.hospital_filterlist[i].hosp_name);
              }
              
              this.filteredhospList = this.hospital_txt.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_name_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.filteredhospList = [];
    }
  }

  select_hospital(data) {
    this.hosp_name_txt = data;
    this.filteredhospList = [];
  }

  locfilter(e) {//location autopopulate
    this.hosp_dist = '';
    this.hosp_state = '';
    this.hosp_country = '';
    this.location_txt = [];
    this.location_filterlist = [];
    var tmp_arr = [];

    if (this.hosp_loc_txt !== "" && this.hosp_loc_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.hosp_loc_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.locations != null) {
              this.location_filterlist = obj.locations;
              this.filteredlocList = this.location_filterlist.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_loc_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },error => {});
    } else {
      this.filteredlocList = [];
    }
  }

  select_location(data) {
    this.hosp_loc_txt = data;
    this.filteredlocList = [];
    this.Get_City(data);
    this.state_list_data = [];
    this.city_list_data = [];
    this.country_list_data = [];
  }

  Get_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.city_list_data = data.json().cities;
          this.hosp_dist = this.city_list_data[0].city_id;
          this.filt_city_desc = this.city_list_data[0].city_desc;
          this.loc_id = this.city_list_data[0].location_id;
          this.City_change(this.city_list_data[0].city_id);
        }
      },error => {});
  }

  City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.state_list_data =[];
            this.state_list_data = data.json().states;
            this.hosp_state = this.state_list_data[0].state_id;
            this.filt_state_desc = this.state_list_data[0].state_desc;
            this.State_change(this.state_list_data[0].state_id);
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
    } else {
      this.state_list_data = [];
      this.city_list_data = [];
      this.country_list_data = [];
    }
  }

  State_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != null) {
            this.country_list_data =[];
            this.country_list_data = data.json().countries;
            this.country_id = this.country_list_data[0].country_id;
            this.hosp_country = this.country_list_data[0].country_desc;
            this.SerCountry_Change();
          }
        },error => {});
    } else {
      this.state_list_data = [];
      this.country_list_data = [];
    }
  }

  SerCountry_Change() {
    this.hosp_id = 0;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospdetails/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt,
        location: this.hosp_loc_txt,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.hosp_id != null) 
            this.hosp_id = obj.hosp_id;
        },error => {});
  }

  Profile_next_click() {//Profile next click
    var chec_valid = true;
    for (var h = 0; h < this.hospital_filterlist.length; h++) {
      if (this.hosp_name_txt == this.hospital_filterlist[h].hosp_name) {
        this.hosp_id = this.hospital_filterlist[h].hosp_id;
      }
    }

    if (this.f_name == undefined || this.f_name == "" || this.l_name == undefined || this.l_name == "" 
      || this.gend_txt == undefined || this.Doc_mobile == undefined || this.Doc_mobile == "" 
      || this.Create_password == undefined || this.Create_password == "" || this.confirm_pwd == undefined 
      || this.confirm_pwd == "" || this.hosp_name_txt == undefined || this.hosp_name_txt == "" 
      || this.hosp_loc_txt == undefined || this.hosp_loc_txt == "" || this.hosp_dist == undefined || this.hosp_dist == "" 
      || this.hosp_state == undefined || this.hosp_state == "" || this.hosp_country == undefined || this.hosp_country == "") {
      this.toastr.error(Message_data.mandatory);
      chec_valid = false;

    } else {
      if (this.Doctor_DOB != undefined) {
        var get_t_date = this.Doctor_DOB;
        var Dobsplit = get_t_date.toString().split('-');
        Helper_Class.set_Doc_reg_dob(Dobsplit[2]);
      }
      
      // if (this.Doctor_DOB != "" && this.registeration_year != undefined && this.registeration_year != "") {
      //   if ((this.registeration_year.length != 4) || (parseInt(this.registeration_year) > parseInt(this.get_date[0]))) {
      //     this.toastr.error(Message_data.validRegtrYear);
      //     chec_valid = false;
      //   } else if ((parseInt(this.registeration_year) - parseInt(Dobsplit[0])) < 20) {
      //     this.toastr.error(Message_data.invalidRegtrOrDOB);
      //     chec_valid = false;
      //   }
      // }
    }

    if (this.Doc_email != undefined) {
      var x = this.Doc_email;
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
      if (!re.test(x)) {
        this.toastr.error(Message_data.validEmail);
      }
    }

    if (this.Create_password != this.confirm_pwd) {
      this.toastr.error(Message_data.pwdNotMatched);
      chec_valid = false;
    }
    if (this.Doc_mobile.length != 10) {
      this.toastr.error(Message_data.validMobileNo);
      chec_valid = false;
    }
    if (this.Recept_Age < 18) {
      this.toastr.error(Message_data.chkUrDOB);
      chec_valid = false;
    } 
    if (this.hosp_id == 0) {
      this.toastr.error(Message_data.hosptlNotFound);
      chec_valid = false;
    } 

    if (chec_valid == true) {
      if (this.Doc_mobile.length != 10) {
        this.toastr.error(Message_data.validMobileNo);
      } else if (this.Create_password != this.confirm_pwd) {
        this.toastr.error(Message_data.pwdNotMatched);
      } else if (this.Recept_Age < 18) {
        this.toastr.error(Message_data.chkUrDOB);
      } else if (this.hosp_id == 0) {
        this.toastr.error(Message_data.hosptlNotFound);
      }
    }
    if (chec_valid == true) {
      this.check_mobile_exits();
    }
  }

  emailCheck() { //check if email exists or not
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/isclient',
      JSON.stringify({
        input: this.Doc_email,
        flag: "6"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.result == true)
            this.toastr.error(Message_data.existEmailID);
          else 
            this.final_update();
        },error => {});
  }

  final_update() {
    var home_value = null;
    home_value = this.togg_home == true ?  "1" : "0";
 
    if(this.Doctor_DOB != undefined){
      var get_tmp = this.Doctor_DOB.split('-');
      var get_f_date = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0]; 
    }
  
    this.Update_img = this.Imagepath == "default" ? "default" : this.Imagepath.split(',')[1];

    var emailstr = this.Doc_email.trim();
    this.mobile_str = encrypt_decript.Encript(this.Doc_mobile).toString();
    this.email_str = encrypt_decript.Encript(emailstr).toString();
    this.password_string = encrypt_decript.Encript(this.Create_password).toString();
    var img_path = this.pro_img, upd_url = undefined;
    if (this.img_str != null) {
      img_path = this.img_str.split(',')[1];
      upd_url = "1";
    } else {
      img_path = "default";
    }
  
    var dobval ;
    if(get_f_date != undefined){
      dobval = Date_Formate(get_f_date);
    }

    //var send_array = null;
    var send_array = {
      upd_url: upd_url,
      profile_image: img_path,
      first_name: this.f_name,
      middle_name: this.m_name,
      last_name: this.l_name,
      gender: this.gend_txt,
      dob:dobval,
      age: this.docAge,
      email: this.Doc_email,
      contact_no: this.Doc_mobile,
      hospital: this.hosp_id,
      language: "English",
      created_date: this.CurrentDatetime,
      password: this.confirm_pwd,
    }
    Helper_Class.set_regsitration_profile_array(send_array);
  }
  
  check_mobile_exits() {//check if mobile exists or not
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/isclient',
      JSON.stringify({
        input: this.Doc_mobile,
        flag: "1"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.result == true){
            this.toastr.error(Message_data.existMobileNo);
          } else {
            if(this.Doc_email != undefined){
              this.emailCheck();
            } else {
              this.final_update();
            }
          }
        },error => {});
  }

  otp_open(otp_data) {
    this.register();
    // var otpval;
    // if(otp_data !="false"){
    //   otpval =encrypt_decript.Decript(otp_data);
    // }else{
    //   otpval = otp_data;
    // }
    // let poup = this.modalCtrl.create('OtpConfirmPage', {
    //   "otp":otpval,
    //   type:"registration"
    // });
    // poup.present();
    // poup.onDidDismiss(data => {
    //   if (data != undefined) {
    //     if (data.result == true) {
    //       this.register();
    //     }
    //   }
    // });

    // let alert1 = this.alertCtrl.create({
    //   title: 'An OTP has been sent to entered mobile number. Please enter OTP in below field',
    //   inputs: [
    //     {
    //       name: 'otp_txt',
    //       placeholder: 'OTP code',
    //       max: '6',
    //     }
    //   ],
    //   buttons: [
    //     {
    //       text: 'Register',
    //       handler: data => {

    //         if (data.otp_txt.toString().trim() == "" && data.otp_txt.length == 0) {
    //           this.toastr.error("Please enter OTP");

    //         } else if (data.otp_txt.length > 6) {
    //           this.toastr.error("You have reached a limit of 6 character");

    //         } else {
    //           if (data.otp_txt == otp_data || data.otp_txt == "qwerty") {
    //             this.Reg_profile_tab = Helper_Class.get_regsitration_profile_array();
    //             this.Reg_work_tab = Helper_Class.get_reg_work_array();
    //             this.Registration_work_add = Helper_Class.get_reg_work_add_array();
    //             this.get_packdata = Helper_Class.get_package_data();
    //             
    //             
    //             
    //             
    //             var send_work = null;
    //             send_work = {
    //               consultation: this.Reg_work_tab
    //             }

    //             var send_url = "usercontroller/regprov/";
    //             for (var key in this.Reg_profile_tab) {
    //               
    //               if (key == "upd_url" && this.Reg_profile_tab[key] != undefined) {
    //                 send_url = "usercontroller/regprovweb/";
    //               }
    //             }

    //             var subscribe_array = {
    //               grit_package_subscribe_id: "18",
    //               country: ipaddress.country_code
    //             }

    //             var finalObj = $.extend(true, this.Reg_profile_tab, send_work);
    //             var finalObj2 = $.extend(true, finalObj, subscribe_array);
    //             var output = null;
    //             output = finalObj2;

    //             // send foot ass
    //             var send_foot = null, getfoot_ass = null, qual;
    //             getfoot_ass = Helper_Class.get_reg_work_add_array();

    //             send_foot = {
    //               address: getfoot_ass,
    //             }
    //             output = $.extend(true, finalObj2, send_foot);

    //             var headers = new Headers();
    //             headers.append('Content-Type', 'application/json');
    //             this.http.post(ipaddress.getIp.toString() + send_url, output,
    //               { headers: headers })
    //               .subscribe(
    //                 response => {
    //                   var obj = response.json();

    //                   if (obj.key == "1") {
    //                     this.toastr.error("Registered successfully");
    //                     var modalPage = this.modalCtrl.create("LoadingPage", {
    //                       "Regapp": "registration"
    //                     });
    //                     modalPage.present();
    //                   }
    //                 },
    //                 error => {
    //                   this.toastr.error(Message_data.getNetworkMessage());
    //                 }
    //               )
    //           } else {
    //             this.toastr.error("Invalid OTP, please try again later");
    //           }
    //         }
    //       }
    //     }
    //   ]
    // });
    // alert1.present();
  }

  register() {
    this.Reg_profile_tab = Helper_Class.get_regsitration_profile_array();
    var send_url = "usercontroller/regusr/";
    for (var key in this.Reg_profile_tab) {
      if (key == "upd_url" && this.Reg_profile_tab[key] != undefined) {
        send_url = "usercontroller/regusrweb/";
      }
    }

    var output = null;
    output = this.Reg_profile_tab;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + send_url, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.result == "Registered successfully") {
            this.save_flag = true;
            this.toastr.success(Message_data.regtrSuccess);
            this.router.navigateByUrl("loginpage");
          } else {
            this.toastr.error(Message_data.unableToRegtr);
          }
        },error => {
        this.toastr.success(Message_data.getNetworkMessage());
        });
  }

  Termsconditionback() {
    // this.app.getActiveNavs()[0].parent.select(2);
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  gender(){
    document.getElementById("genderid").style.border="1px solid #2ca8fe";
  }

  register_next_click() {
    this.Profile_next_click();

    if (this.agree == true) {
      var contact = null;
      this.Reg_profile_tab = Helper_Class.get_regsitration_profile_array();
      for (var key in this.Reg_profile_tab) {
        if (key == "contact_no") {
          contact = this.Reg_profile_tab[key];
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/otp/',
        JSON.stringify({
          mobile: encrypt_decript.Encript(contact).toString(),
          country: ipaddress.country_code
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.otp != null) 
              this.otp_open(obj.otp);
          },error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.toastr.error(Message_data.termsNconditions);
    }
  }
}
