<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header" >
              <h5 class="m-0" class="mainHeadingStyle">Reference videos</h5>
            </div>
            <div class="headerButtons">
             <!-- <a (click)="Bill_update()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                  class="saveimgbtn_inpatinfo" /></a> -->
  
              <!-- <button type="button" mdbBtn color="primary" size="sm" [outline]="true" mdbWavesEffect (click)="clearAll()">Cancel</button>
              <button type="button" mdbBtn color="primary" size="sm" mdbWavesEffect (click)="getCheckedItemList()">Confirm</button>   
           -->
            </div>
          </div>
          
        </mdb-card-header>
        <mdb-card-body>
            <div class="container">
                <div class="row" *ngIf="!is_video_on">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" *ngFor="let video of videopath">
                        <div class="row" style="margin-bottom: 15px;">
                            <div class="col-12" style="text-align: center;">
                                <img src="{{video.img_path}}" (click)="playVideo(video.video,video.name)" class="video_img"/>
                            </div>
                            <div class="col-12 video_name">
                              <div>
                                {{video.name}}
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="nodata" *ngIf = "videopath.length == 0">No videos(s) found</p>
                <div class="row" *ngIf="is_video_on">
                    <p class="tervys_heading">{{video_name}}</p>
                    <div class="col-12 embed-responsive embed-responsive-16by9 d-flex justify-content-center">
                        <video id="myVideo" class="wh_video embed-responsive-item" autoplay controls controlsList="nodownload" >
                            <source src="{{play_video}}" type="video/mp4">
                          </video>
                          <img class="closeicon" (click)="is_video_on = !is_video_on" src="../../../assets/ui_icons//video_cancel_icon.svg" width="16px"/>
                    </div>
                </div>
            </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>