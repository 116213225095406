import { Component,ViewChild, OnInit,HostListener,ViewEncapsulation,ElementRef,OnDestroy, Injector } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import {ipaddress.getIp,ipaddress.Ip_with_img_address,ipaddress.country_code} from '../../../assets/js/ipaddress.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Message_data } from '../../../assets/js/Message_data';
import { Time_Formate_Session } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { Observable, observable, Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.css']
})
export class GeneralReportComponent implements OnInit {

  public general_report = "min";

  public blood_hidden: boolean;
  public bloodaccor: boolean;
  public scan_hidden: boolean;
  public scanaccor: boolean;
  public xray_hidden: boolean;
  public xrayaccor: boolean;
  public ultra_hidden: boolean;
  public ultraaccor: boolean;
  public electro_hidden: boolean;
  public electroaccor: boolean;

  public bloodarray=[];
  public urinearray=[];
  public stoolarray=[];
  public scanarray=[];
  public xrayarray=[];
  public ultraarray=[];
  public loader:boolean;
  public header_footer_flag: boolean;
  public sub_id;
  
  //panel
  public panelOpenState1:boolean;
  public panelOpenState2:boolean;
  public panelOpenState3:boolean;
  public panelOpenState4:boolean;
  public panelOpenState5:boolean;
  public panelOpenState6:boolean;

  //popop
  public report_detail_flag:boolean = false;
  public report_detail_dig_flag:boolean = true;
  public report_detail_pres_flag:boolean = true;

  //details
  public report_pres_id;
  public report_type;
  public report_diag_id;
  public report_test_id;

  public date_txt: string;
  public diag_name: string;
  public diag_add: string;
  public diag_loc: string;
  public diag_cityzip: string;
  public diag_statecon: string;
  public diag_teleph: string;
  public client_age: string;
  public get_age: string;
  public Age_div: boolean;
  public review_sign: string;
  public review_div: boolean;
  public clent_id: string;
  public review_by: string;
  public client_another_name: string;
  public client_name: string;
  public diag_refertxt: string;
  public client_gender: string;
  public time_collect: string;
  public review_txt: string;
  public second_opinion_mintlist = [];
  public second_opinion_subtlist = [];
  public second_opinion_mintlist1 = [];
  public temp_value = null;
  public dicom_doc: boolean = true;
  public report_img =[];
  public report_img_flag: boolean = false;

  public report_detail: string;
  public blood_urine_faeces_div: boolean;
  public xray_scan_ultra_div: boolean;

  public opn_id;
  public opn_type;
  public opn_pres_test_id;
  public opn_app_id;
  public clientflag: boolean;
  public docflag:boolean;
  public diagflag:boolean;
  public relation: any;
    public personaldata;
    public subscription:Subscription;
    public base64data;

  constructor(public sanitizer:DomSanitizer,public dialog: MatDialog,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,public messageservice1: CasesheetService) { 
    this.blood_hidden= true;
    this.bloodaccor= true;
    this.scan_hidden= true;
    this.scanaccor= true;
    this.xray_hidden= true;
    this.xrayaccor= true;
    this.ultra_hidden= true;
    this.ultraaccor= true;
    this.electro_hidden= true;
    this.electroaccor= true;
  }

  ngOnInit(): void {
    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
        if (message) {
            this.general_report = message;
            this.report_detail_flag = false;
        }
    });

      this.personaldata=Doc_Helper.getClient_Info()
      
    this.getAllReports();
  }

  getAllReports(){
    var send_reports;
    if ( this.personaldata.sub_id!="" ||  this.personaldata.sub_id.length !=0 ) {
        
      send_reports = {
        client_reg_id:  this.personaldata.Client_id,
        relation_id:  this.personaldata.rel_id,
        sub_rel_id: this.personaldata.sub_id,
        country: ipaddress.country_code,
        spl_name: this.personaldata.spl,
      }
    } else {
        
      send_reports = {
         
        client_reg_id: this.personaldata.Client_id,
        relation_id:this.personaldata.rel_id,
        country: ipaddress.country_code,
        spl_name:this.personaldata.spl,
      }
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'record/repbyspl',
      JSON.stringify(send_reports),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if(obj != undefined && obj !='undefined'){
            this.loader=true;
            if (obj.blood != null && obj.blood.length != 0) {
                for (var i = 0; i < obj.blood.length; i++) {
                    var get_date = obj.blood[i].pres_date.split('-');
                    this.bloodarray.push({
                        tot_scane_name: "Report prescribed on " + get_date[2] + "-" + get_date[1] + "-" + get_date[0],
                        pres_diag_id: obj.blood[i].pres_diag_id,
                        diag_app_id: obj.blood[i].diag_appointment_id,
                        test_name_desc: obj.blood[i].test_name,
                    });
                }
                this.blood_hidden = false;
                this.bloodaccor = false;
            }

            if (obj.urine != null && obj.urine.length != 0) {
                for (var i = 0; i < obj.urine.length; i++) {
                    var get_date = obj.urine[i].pres_date.split('-');
                    this.urinearray.push({
                        tot_scane_name: "Report prescribed on " + get_date[2] + "-" + get_date[1] + "-" + get_date[0],
                        pres_diag_id: obj.urine[i].pres_diag_id,
                        diag_app_id: obj.urine[i].diag_appointment_id,
                        test_name_desc: obj.urine[i].test_name,
                    });
                }
            }

            if (obj.faeces != null && obj.faeces.length != 0) {
                for (var i = 0; i < obj.faeces.length; i++) {
                    var get_date = obj.faeces[i].pres_date.split('-');
                    this.stoolarray.push({
                        tot_scane_name: "Report prescribed on " + get_date[2] + "-" + get_date[1] + "-" + get_date[0],
                        pres_diag_id: obj.faeces[i].pres_diag_id,
                        diag_app_id: obj.faeces[i].diag_appointment_id,
                        test_name_desc: obj.faeces[i].test_name,
                    });
                }
            }

            if (obj.scans != null && obj.scans.length != 0) {
                for (var i = 0; i < obj.scans.length; i++) {
                    var get_date = obj.scans[i].pres_date.split('-');
                    
                    this.scanarray.push({
                        tot_scan_name: obj.scans[i].test_name + " Report prescribed on " + get_date[2] + "-" + get_date[1] + "-" + get_date[0],
                        pres_diag_id: obj.scans[i].pres_diag_id,
                        diag_app_id: obj.scans[i].diag_appointment_id,
                        test_name_desc: obj.scans[i].test_name,
                        test_id_data: obj.scans[i].test_id
                    });
                }

                this.scan_hidden = false;
                this.scanaccor = false;
            }

            if (obj["x-ray"] != null && obj["x-ray"].length != 0) {
                for (var i = 0; i < obj["x-ray"].length; i++) {
                    var get_date = obj["x-ray"][i].pres_date.split('-');
                    this.xrayarray.push({
                        tot_scane_name: obj["x-ray"][i].test_name + " Report prescribed on " + get_date[2] + "-" + get_date[1] + "-" + get_date[0],
                        pres_diag_id: obj["x-ray"][i].pres_diag_id,
                        diag_app_id: obj["x-ray"][i].diag_appointment_id,
                        test_name_desc: obj["x-ray"][i].test_name,
                        test_id_data: obj["x-ray"][i].test_id
                    });
                }

                this.xray_hidden = false;
                this.xrayaccor = false;
            }

            if (obj["ultra-sound"] != null && obj["ultra-sound"].length != 0) {
                for (var i = 0; i < obj["ultra-sound"].length; i++) {
                    var get_date = obj["ultra-sound"][i].pres_date.split('-');
                    this.ultraarray.push({
                        tot_scane_name: obj["ultra-sound"][i].test_name + " Report prescribed on " + get_date[2] + "-" + get_date[1] + "-" + get_date[0],
                        pres_diag_id: obj["ultra-sound"][i].pres_diag_id,
                        diag_app_id: obj["ultra-sound"][i].diag_appointment_id,
                        test_name_desc: obj["ultra-sound"][i].test_name,
                        test_id_data: obj["ultra-sound"][i].test_id
                    });
                }
                this.ultra_hidden = false;
                this.ultraaccor = false;
            }
          }
        });
  }

  //Report Detail view
  report_view(Pres_id, type, diag_app_id, test_id) {
    this.report_detail_flag = true;
    this.report_detail_dig_flag = false; 
    this.report_detail_pres_flag = false;
    this.report_pres_id = Pres_id;
    this.loader = false;
    this.report_diag_id = diag_app_id;
    this.report_test_id = test_id;
    this.report_type = type;
    this.Get_Report_Detailview("normal"); // view report view



    // let poup = this.modalCtrl.create('ReportViewPopupPage',{
    //   report_pres_id: Pres_id,
    //     report_type: type,
    //     report_diag_id: diag_app_id,
    //     report_test_id: test_id,
    //     doc_view:"yes",
    //     client_reg_id: localStorage.getItem("Client_id"),
    //     relation_id: localStorage.getItem("rel_id"),
    //     sub_rel_id: this.sub_id,
    // });
    // poup.present();
    // poup.onDidDismiss(data => {});

  }

 //Get report details
 Get_Report_Detailview(type) {
  var send_json = null, url = null;
  if (type == "normal") {
      url = 'record/diagreportview/';
      send_json = JSON.stringify({
          type: this.report_type,
          flag: "doctor",
          diag_app_id: this.report_diag_id,
          country: ipaddress.country_code,
          pres_diag_id: this.report_pres_id,
          pres_test_id: this.report_test_id,
      })

  } else {
      url = 'secopn/greport/';
      send_json = JSON.stringify({
          type: this.opn_type,
          pres_test_id: this.opn_pres_test_id,
          opinion_id: this.opn_id,
          country: ipaddress.country_code,
          app_id: this.opn_app_id
      })
  }

  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  this.http.post(ipaddress.getIp.toString() + url, send_json,
      { headers: headers })
      .subscribe(
          response => {
              var obj = JSON.parse(response["_body"]);
                

              if (obj.diag_centre_name != null) {
                  this.loader = true;
                  if (obj.date_entered != null) {

                      var datereport = obj.date_entered.split('-');
                      this.date_txt = datereport[2] + "-" + datereport[1] + "-" + datereport[0]
                  }
                  this.diag_name = obj.diag_centre_name;

                  if (obj.address2 != null) {
                      this.diag_add = obj.address1 + " " + obj.address2;

                  } else {
                      this.diag_add = obj.address1;
                  }
                  
                  this.diag_loc = obj.location;

                  this.diag_cityzip = obj.city + " - " + obj.zipcode;
                  this.diag_statecon = obj.state + " - " + obj.country;

                  this.diag_teleph = obj.telephone;

                  if (obj != null) {
                      this.client_age = obj.age;
                      this.get_age = obj.age;
                      this.Age_div = false;

                  } else {
                      this.Age_div = true;
                  }

                  if (obj.sign != null) {
                      this.review_sign = "data:image/png;base64," + obj.sign;
                  } 

                  if (obj.app_first_name != null) {
                      this.review_div = true;
                  }

                  if (obj.app_middle_name != undefined && obj.app_middle_name != null) {
                      this.review_by = obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name;

                  } else {
                      this.review_by = obj.app_first_name + " " + obj.app_last_name;
                  }

                  this.clent_id = obj.client_reg_id;

                  localStorage.setItem("client_decrypt_first", obj.first_name);
                  localStorage.setItem("client_decrypt_middle", obj.middle_name);
                  localStorage.setItem("client_decrypt_last", obj.last_name);


                  if (obj.middle_name != undefined && obj.middle_name != null) {
                      this.client_another_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                  }
                  else {
                      this.client_another_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                  }

                  this.client_name = encrypt_decript.Encript(obj.first_name).toString(), + " " + encrypt_decript.Encript(obj.middle_name).toString() + " " + encrypt_decript.Encript(obj.last_name).toString();
                  if(obj.doc_middle_name != undefined && obj.doc_middle_name != 'undefined'){
                      this.diag_refertxt = "Dr." + obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
                   
                  } else {
                      this.diag_refertxt = "Dr." + obj.doc_first_name + " " + obj.doc_last_name;
                  }

                  this.client_age = obj.age;
                  this.client_gender = encrypt_decript.Decript(obj.gender);
                  this.time_collect = Time_Formate_Session(obj.time_collected);
                  this.review_txt = "------";

                  this.second_opinion_mintlist = [];
                  this.second_opinion_subtlist = [];
                  this.second_opinion_mintlist1 = [];

                  if (this.report_type == "Urine" || this.report_type == "Blood" || this.report_type == "Faeces") {
                      this.blood_urine_faeces_div = false;
                      this.xray_scan_ultra_div = true;

                      for (var i = 0; i < obj.main_tests.length; i++) {
                          this.second_opinion_mintlist.push({
                              pres_test_id: obj.main_tests[i].pres_test_id,
                              test_id: obj.main_tests[i].test_id,
                              test_name: obj.main_tests[i].test_name,
                          });

                          for (var i = 0; i < obj.main_tests.length; i++) {
                              for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                                  this.temp_value = "";

                                //   if ((this.get_age != "00") && (obj.main_tests[i].subtests[j].new_born != null) && (obj.main_tests[i].subtests[j].new_born.trim() != "") && (parseInt(this.get_age) <= 1)) {// New born
                                //       this.temp_value = obj.main_tests[i].subtests[j].new_born;

                                //   } else if ((this.get_age != "00") && (obj.main_tests[i].subtests[j].child != null) && (obj.main_tests[i].subtests[j].child.trim() != "") && (parseInt(this.get_age) < 13)) {// child
                                //       this.temp_value = obj.main_tests[i].subtests[j].child;

                                //   } else if (this.client_gender != undefined) {// male and female
                                //       if (this.client_gender == "Male") {
                                //           this.temp_value = obj.main_tests[i].subtests[j].male;

                                //       } else if (this.client_gender == "Female") {
                                //           this.temp_value = obj.main_tests[i].subtests[j].female;

                                //       } else {
                                //           this.temp_value = obj.main_tests[i].subtests[j].male;
                                //       }

                                //   } else {// Nothing
                                //       this.temp_value = obj.main_tests[i].subtests[j].male;
                                //   }

                                 this.temp_value = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].subtests[j].range);
                                  this.second_opinion_subtlist.push({
                                      sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                      reading: obj.main_tests[i].subtests[j].reading,
                                      test_range: this.temp_value,
                                      unit: obj.main_tests[i].subtests[j].unit,
                                      subtest_name_txt: obj.main_tests[i].subtests[j].test_name
                                  });
                              }
                          }
                      }

                  } else {
                    this.loadCss();
                    this.loadScript();
                    this.report_img = [];
                      this.blood_urine_faeces_div = true;
                      this.xray_scan_ultra_div = false;
                      for (var i = 0; i < obj.main_tests.length; i++) {
                          if (obj.main_tests[i].image != null) {
                              this.dicom_doc = false;
                              this.report_img_flag = true;
                              this.report_detail = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                              this.report_img.push({
                                image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image});
                          }
                          if (obj.main_tests[i].image1 != null) {
                            this.dicom_doc = false;
                            this.report_img_flag = true;
                            this.report_detail = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                            this.report_img.push({
                              image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image1})
                          }
                          if (obj.main_tests[i].image2 != null) {
                            this.report_img.push({
                              image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image2})
                          }
                          if (obj.main_tests[i].image3 != null) {
                            this.report_img.push({
                              image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image3})
                          }
                          if (obj.main_tests[i].image4 != null) {
                            this.report_img.push({
                              image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image4})
                          }

                          var sequences:any = "";
                          if(obj.main_tests[i].sequences != undefined){
                            sequences = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].sequences);
                          }
                          var impression:any = "";
                          if(obj.main_tests[i].impression != undefined){
                            impression = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].impression);
                          }

                          this.second_opinion_mintlist1.push({
                              pres_test_id: obj.main_tests[i].pres_test_id,
                              test_name: obj.main_tests[i].test_name,
                              sequences: sequences,
                              impression: impression,
                          });
                      }
                  }

              } else {
                  this.loader = true;
                  this.toastr.error(Message_data.getNetworkMessage());
              }
          },
          error => {
              this.loader = true;
              this.toastr.error(Message_data.getNetworkMessage());
          }
      )
  }

  back_Nav(){
    this.report_detail_flag = false; 
    this.report_detail_dig_flag = true; 
    this.report_detail_pres_flag = true;
  }

  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/dist/all.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    
  }

  public loadCss(){
    $("<link/>", {
      rel: "stylesheet",
      type: "text/css",
      href: "assets/dist/all.css"
    }).appendTo("mdb-card");
  }

  savesend_data(){
    if(this.report_detail_flag == true){
      if((document.getElementById("theimage")) != null){
        var img_str = (document.getElementById("theimage")as HTMLImageElement).src;
      }

      var img_path ="";
      if (img_str != null) {
        img_path = (img_str.toString()).split(',')[1];
        
        // upd_url = "1";
      }
      else{
        img_path = "";
      }

      //upload document

      if(img_path != ""){
        var send_data;
        if(this.personaldata.sub_id != "" && this.personaldata.sub_id.length != 0){
          send_data = {
            image: (img_path),
            client_reg_id:this.personaldata.Client_id,
            relation_id:this.personaldata.rel_id,
            sub_rel_id: this.personaldata.sub_id,
            country:ipaddress.country_code,
            image_type:"PNG",
            scan_test:this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }
        else{
           send_data = {
            image: (img_path),
            client_reg_id:this.personaldata.Client_id,
            relation_id:this.personaldata.rel_id,
            country:ipaddress.country_code,
            image_type:"PNG",
            scan_test:this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }

        

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
    
        this.http.post(ipaddress.getIp + 'prescription/udr', (send_data), { headers: headers }).subscribe(
          response => {
            
            if(response["key"] != 0){
              this.toastr.success(Message_data.imgSavedSuccess);
            }else{
            this.toastr.error(Message_data.errOccur);
          }
          })
      }
      else{
        var send_data;
        if(this.personaldata.sub_id != "" && this.personaldata.sub_id.length != 0){
          send_data = {
            image: (img_path),
            client_reg_id:this.personaldata.Client_id,
            relation_id:this.personaldata.rel_id,
            sub_rel_id: this.personaldata.sub_id,
            country:ipaddress.country_code,
            image_type:"PNG",
            scan_test:this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }
        else{
           send_data = {
            image: (img_path),
            client_reg_id:this.personaldata.Client_id,
            relation_id:this.personaldata.rel_id,
            country:ipaddress.country_code,
            image_type:"PNG",
            scan_test:this.report_type,
            pres_test_id: this.report_test_id,
          }
        }

        

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
    
        this.http.post(ipaddress.getIp + 'prescription/udr', (send_data), { headers: headers }).subscribe(
          response => {
            
            if(response["key"] != 0){
              this.toastr.success(Message_data.imgSavedSuccess);
            }else{
              this.toastr.error(Message_data.errOccur);
            }
          })
      }
    }

  }
}
