import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import {FormControl} from '@angular/forms';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';

@Component({
  selector: 'app-doc-receivables-report',
  templateUrl: './doc-receivables-report.component.html',
  styleUrls: ['./doc-receivables-report.component.scss']
})
export class DocReceivablesReportComponent implements OnInit {
  tmr = new Date();
  public header_footer_flag: boolean;
  public pharmacy_id: string;
  public from_date;
  public to_date;
  public curr_date = null;
  public receive_data=[];

  public nodata: boolean;
  public hasdata: boolean;
  public currentDate;
  public userinfo;
  public hospitalID;

  constructor(public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService  ) {
    this.nodata = false;
    this.hasdata = true;
  }

  ngOnInit(): void {
    this.hospitalID = Doc_Helper.getHospitalClinicalId();
    this.get_curr_date();
  }

  get_curr_date() {
    var obj = Master_Helper.getMasterCurrentDate();
    this.tmr = new Date(obj.current_date);
    this.tmr.setDate(this.tmr.getDate() + 10);
    this.currentDate =  this.getDateMatePicker1(this.tmr);//display in html for maximum date
    this.from_date = obj.current_date;
    this.to_date = obj.current_date;
    
    this.getReceivablesData();
  }

  getDateMatePicker1(e){
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt =e.getFullYear()+"-"+ month +"-" + day;
    return apt.trim();
  }

  dateSelect(data,value) {
    if (data != null) {
      if (value == "1")
        this.from_date = data;
      else
        this.to_date = data;
    }
  }

  getReceivablesData() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gprep/", JSON.stringify({           
      hptl_clinic_id: this.hospitalID,
      from_date: this.from_date,
      to_date: this.to_date,}), 
    { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if (dataval != null) {
          this.nodata=true;
          this.hasdata=false;
          this.receive_data=[];
          if(dataval.receivables != undefined){
            for(var i=0;i<dataval.receivables.length;i++){
              this.receive_data.push({
                hsn_no:dataval.receivables[i].hsn_no,
                product_name:dataval.receivables[i].product_name,
                po_code:dataval.receivables[i].po_code,
                po_qty:dataval.receivables[i].po_qty,
                qty_received:dataval.receivables[i].qty_received,
                balance:dataval.receivables[i].balance,
              });
            }
          } else {
            this.receive_data=[];
            this.nodata=false;
          this.hasdata=true;
          }
        }
      },
      error => {});
  }
}
