import { Component, OnInit,HostListener } from '@angular/core';
import { Helper_Class } from '../../helper_class';
// import {ipaddress.getIp,Ip_with_img_address} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { DentalService, TreatmentService } from '../service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer,Subscription } from 'rxjs';
import * as $ from 'jquery/dist/jquery.min.js';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-dental-spl-dental-hist',
  templateUrl: './dental-spl-dental-hist.component.html',
  styleUrls: ['./dental-spl-dental-hist.component.css']
})
export class DentalSplDentalHistComponent implements OnInit {
  public post_txt;
  public rh_hidden;
  public ynoption:boolean=false;
  public  diet_details_array = [];
  public gen_hist;
  public birth_hidden;
  public dentalflag:boolean;
  public Natal_Hist: boolean;
  public drug_mot_hidden: boolean;
  public health_hidden: boolean;
  public dey_hidden: boolean;
  public oral_txt_hyg:boolean;
  public Caries_Div: boolean;
  public intrup_txt:boolean;
    public panelOpenState13;
    public den_hist_int:boolean
 // public ynopt14;
public non_pre_bit_txt;
public gst_hidden;
public biting_txt;
public non_pre_txt;
public pressure_txt;
public individual_txt;
public oral_hab_div:boolean;
public med_prepara;
public quantity_txt;
public food_type;
public dietsession_txt;
public ynopt15:boolean=false;
public dental_txt;
  public biting_hide;
  public panelOpenState14;
  public bit_data;
  public suck_data;
  public suck_hide: boolean;
  public con_prb;
  public freq_txt;
  public child_visit_fields: boolean;
  public den_hist_togg;
  public age_habit;
  public int_txt;
  public dur_txt;
  public  qty_sugar_data: string;  //array declaration
  
  public inten_pre_hide: boolean;
  public Get_client_view_app_data;
  public dental_last_vit_req: boolean;
  public dental_ple_exp_req: boolean;
  public dental_prev_treat_req: boolean;
  public dental_beh_last_vit_req: boolean;
  public dental_sup_un_req: boolean;
  public dental_diet_any_req: boolean;  
  public ple_hidden: boolean;
  public pre_treat_hidden: boolean;
  public beh_last_vit_hidden: boolean;
  public diet_hist_hidden: boolean;
  public qty_sugar_hidden: boolean;
  public diet_any_hidden: boolean;  
  public type_of_habit_array = [];
public den_hist_last_vit;
public den_hist_prev;
public den_hist_ple;
public den_hist_beh_last_vit;
public den_hist_bru_cng;
public den_hist_ot_aid
public den_hist_mod_clean;
public den_hist_sup;
public den_hist_diet_any;
public type_of_habit; 
public subscription:Subscription;
public indi_hide: boolean;
public pre_hide: boolean;
public non_pre_hide: boolean;
public non_pre_bit_hide: boolean;
public bit_hide: boolean;
public disabled: boolean;
public den_hist_swt_score;public sweet_score:boolean=true;

public get_spl_name=Doc_Helper.get_dental_spl_name();
  public last_vit_hidden: boolean;
  public habit_hidden:boolean;
  public habit_hidden1:boolean; 
  public client:boolean = false;

  constructor(public http: Http,public toastr:ToastrService,public messageservice:TreatmentService,public dentalservice:DentalService,
    public masterData:MasterCSHelperService) {    
      this.habit_hidden=true;
      this.habit_hidden1=true;     
      this.gst_hidden = true;
      this.health_hidden = true;
      this.drug_mot_hidden = true;      
      this.ple_hidden = true;
      this.pre_treat_hidden = true;
      this.beh_last_vit_hidden = true;
      this.qty_sugar_hidden = true;
      this.child_visit_fields = true;
      this.indi_hide = true;
      this.pre_hide = true;
      this.non_pre_hide = true;
      this.non_pre_bit_hide = true;
      this.bit_hide = true;
      this.inten_pre_hide = true;
      this.biting_hide = true;
      this.suck_hide = true;
      this.oral_txt_hyg = true;
  }

  async ngOnInit(){
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }

    Doc_Helper.Set_den_spl_dental(null);

    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        Doc_Helper.Set_dent_spl(message.spl);
        this.speciality_change(message.spl);
      } else {
        var la = [];
        la = [];
      }
    });

    if(masterCSData_Helper.getMasterTypeofHabit() == undefined){
      await this.masterData.getTypeofHabit();
      this.get_type_of_habit_array();
    }else{
      this.get_type_of_habit_array();
    }  
  }

  habit_data_chage() {
    this.indi_hide = true;
    this.pre_hide = true;
    this.non_pre_hide = true;
    this.non_pre_bit_hide = true;
    this.bit_hide = true;
    this.inten_pre_hide = true;

    if (this.type_of_habit == "Individual habits") {
      this.indi_hide = false;
    }else if (this.type_of_habit == "Pressure habits") {
      this.pre_hide = false;
    }else if (this.type_of_habit == "Non pressure habit") {
      this.non_pre_hide = false;
      this.non_pre_bit_hide = false
    }else if (this.type_of_habit == "Biting habits ") {
      this.bit_hide = false;
      this.non_pre_bit_hide = false;
    }else if (this.type_of_habit == "Intrinsic pressure habits ") {
      this.inten_pre_hide = false;
    }
  }

  RetrivalFunction() {
    // retival
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
      // var data = message.spl
        this.speciality_change( message.spl);
        Doc_Helper.Set_dent_spl(message.spl)
      } else {
        var la = [];
        la = [];
      }
    });
    var getret1=Helper_Class.Get_den_ret1() 
    var getret2=Helper_Class.Get_den_ret2()
    if(getret1 != undefined) {
      if (getret1.ext_oral_cond != null && getret1.ext_oral_cond.length != 0) {
       }
       }
    if(getret2 != undefined) {
      if(getret2.child_first_visit != null) {
        this.den_hist_togg1=true;
        this.child_visit_fields = false;
        this.last_vit_hidden = false;
        this.den_hist_togg = getret2.child_first_visit;
        this.ple_hidden = false;
        this.pre_treat_hidden = false;
        this.beh_last_vit_hidden = false;
      }
   
      if (getret2.last_visit != null) {
        this.den_hist_last_vit = getret2.last_visit;
      }

      if (getret2.prev_treatment != null) {
        this.den_hist_prev = getret2.prev_treatment;
      }
      if (getret2.visit_exp != null) {
        this.den_hist_ple = getret2.visit_exp;
      }
      if (getret2.behaviour_visit != null) {
        this.den_hist_beh_last_vit = getret2.behaviour_visit;
      }
      if (getret2.hyg_sup_bru != null) {
        this.den_hist_sup = getret2.hyg_sup_bru;
      }
      if (getret2.hyg_mode_clean != null) {
        this.den_hist_mod_clean = getret2.hyg_mode_clean;
      }
      if (getret2.hyg_bru_change != null) {
        this.den_hist_bru_cng = getret2.hyg_bru_change;
      }
      if (getret2.sweet_score != null) {
        this.den_hist_swt_score = getret2.sweet_score;
      }
      if (getret2.diet_history != null) {
        for (var i = 0; i < getret2.diet_history.length; i++) {
          this.diet_details_array.push({
            day: "",
            session: getret2.diet_history[i].session,
            type_food: getret2.diet_history[i].type_food,
            quantity: getret2.diet_history[i].quantity,
            met_prepare: getret2.diet_history[i].met_prepare,
            qty_sugar: getret2.diet_history[i].qty_sugar,
            data: true
          })
        }
      }
      if (getret2.interpretation != null) {
        this.den_hist_int = getret2.interpretation;
      }
      if (getret2.diet_analysis != null) {
        this.den_hist_diet_any = getret2.diet_analysis;
      }
      if (getret2.hyg_other_aids != null) {
        this.den_hist_ot_aid = getret2.hyg_other_aids;
      }
      if (getret2.den_habit_type_id != null) {
        for (var i = 0; i < this.type_of_habit_array.length; i++) {
          if (getret2.den_habit_type_id == this.type_of_habit_array[i].habit_type_id) {
            this.type_of_habit = this.type_of_habit_array[i].habit_name;
            break;
          }
        }

        if (this.type_of_habit == "Intrinsic pressure habits ") {
          if (getret2.oral_condition != null) {
            this.con_prb = getret2.oral_condition;
            this.inten_pre_hide = false;
          }
          if (getret2.oral_condition != null) {
            this.con_prb = getret2.oral_condition;
            this.inten_pre_hide = false;
          }
          if (getret2.frequency != null) {
            this.freq_txt = getret2.frequency;
            this.inten_pre_hide = false;
          }
          if (getret2.oral_intensity != null) {
            this.int_txt = getret2.oral_intensity;
            this.inten_pre_hide = false;
          }
          if (getret2.duration != null) {
            this.dur_txt = getret2.duration;
            this.inten_pre_hide = false;
          }
          if (getret2.age_habit_stop != null) {
            this.age_habit = getret2.age_habit_stop;
            this.inten_pre_hide = false;
          }
        }else if (this.type_of_habit == "Pressure habits") {
          if (getret2.oral_condition) {
            this.pressure_txt = getret2.oral_condition;
            this.pre_hide = false;
          }
        }else if (this.type_of_habit == "Non pressure habit") {
          if (getret2.oral_condition) {
            this.non_pre_txt = getret2.oral_condition;
            this.non_pre_hide = false;
          }
          if (getret2.descrption) {
            this.non_pre_bit_txt = getret2.descrption;
            this.non_pre_bit_hide = false;
          }
        }else if (this.type_of_habit == "Biting habits ") {
          if (getret2.oral_condition) {
            this.biting_txt = getret2.oral_condition;
            this.bit_hide = false;
          }
          if (getret2.descrption) {
            this.non_pre_bit_txt = getret2.descrption;
            this.non_pre_bit_hide = false;
          }
        }else if (this.type_of_habit == "Individual habits") {
          if (getret2.description) {
            this.individual_txt = getret2.description;
            this.indi_hide = false;
          }
        }
      }
    }
  }
  
  Dental_change() {
    this.get_spl_name =Doc_Helper.get_dent_spl();

    if (this.get_spl_name == "Pediatrics") {
      if (this.den_hist_togg == "yes" || (this.den_hist_sup != undefined && this.den_hist_sup.length != 0) || (this.den_hist_diet_any != undefined && this.den_hist_diet_any.length != 0)) {
        this.dental_last_vit_req = true;
        this.dental_ple_exp_req = true;
        this.dental_prev_treat_req = true;
        this.dental_beh_last_vit_req = true;
        this.dental_sup_un_req = true;
        this.dental_diet_any_req = true;
      }
      else {
        this.dental_last_vit_req = false;
        this.dental_ple_exp_req = false;
        this.dental_prev_treat_req = false;
        this.dental_beh_last_vit_req = false;
        this.dental_sup_un_req = false;
        this.dental_diet_any_req = false;
      }
    }
   this.habit_data_chage();
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();

  }
  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }
  @HostListener('window:keyup', ['$event'])
  onkeyup(event: any): void {
    this.send_data();
  }
  
  getColor(v){
    if(v=="Yes"){
      return "green";
    }
    if(v="No"){
      return "red";
    }
  }

  send_data() {
    this.get_spl_name =Doc_Helper.get_dent_spl();
    var endo_treat = "no", child_visit = "no", diet_flag_arry = "no", ortho_treat = "no", any_req = "no", area_spec_dis = undefined, bleed_injury = "no", serious_operation = "no";

    if (this.den_hist_togg == "yes") {
      child_visit = "yes"
    }
    if (this.diet_details_array.length != 0) {
      diet_flag_arry = "yes";
    }
    if (this.get_spl_name == "Orthodontics") {
      var post_ex = "no", pre_txt = "no", fam_flg = "no", oral_falg = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      var  resp_flgtxt = "no", hab_txt = "no", post_ex = "no", pre_txt = "no", fam_flg = "no", birth_flag = "no", oral_falg = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      var maxmand_arrylist = [{den_position_id: "1",},{den_position_id: "2",}];     
      var den_hist_pledata = this.den_hist_ple;
      if (this.ple_hidden == true) {
        den_hist_pledata = "";
      }
      if (this.pre_treat_hidden == true) {
        this.den_hist_prev = "";
      }
      if (this.beh_last_vit_hidden == true) {
        this.den_hist_beh_last_vit = '';
      }
      if (this.oral_txt_hyg == true) {
        this.den_hist_sup = '';
      }
      var dietsession_txtdata = this.dietsession_txt;
      if (this.diet_hist_hidden == true) {
        dietsession_txtdata = '';
      }
      this.gen_hist = {
        overbite_flag: over_biteflg,
        habit_exists: hab_txt,
        physical_status_flag: physical_status_flag,    
      }
    }else if (this.get_spl_name == "Pediatrics") {
      var ped_flag = "no", diet_nalsis_flg = "no", ped_histflg = "no", gen_flag = "no", case_pedflg = "no", condition_txt = "", descrption_data = "", freq_data = "", inten_data = "", dure_dataoral = "", age_what = "", cond_suck = "";
      if (this.type_of_habit == "Intrinsic pressure habits ") {
        oral_habit = "yes"
        condition_txt = this.con_prb;
        descrption_data = "";
        freq_data = this.freq_txt;
        inten_data = this.int_txt;
        dure_dataoral = this.dur_txt;
        age_what = this.age_habit;
      }else if (this.type_of_habit == "Pressure habits") {
        oral_habit = "yes"
        condition_txt = this.pressure_txt;
        descrption_data = "";
        freq_data = "";
        inten_data = "";
        dure_dataoral = "";
        age_what = "";
      }else if (this.type_of_habit == "Non pressure habit") {
        oral_habit = "yes"
        condition_txt = this.non_pre_txt;
        descrption_data = this.non_pre_bit_txt;
        inten_data = "";
        freq_data = "";
        dure_dataoral = "";
        age_what = "";
      }else if (this.type_of_habit == "Biting habits ") {
        oral_habit = "yes"
        condition_txt = this.biting_txt;
        descrption_data = this.non_pre_bit_txt;
        freq_data = "";
        inten_data = "";
        dure_dataoral = "";
        age_what = "";
      }else if (this.type_of_habit == "Individual habits") {
        oral_habit = "yes"
        condition_txt = "";
        descrption_data = this.individual_txt;
        freq_data = "";
        inten_data = "";
        dure_dataoral = "";
        age_what = "";
      }else {
        oral_habit = "no"
        condition_txt = "";
        descrption_data = "";
        freq_data = "";
        inten_data = "";
        dure_dataoral = "";
        age_what = "";
      }

      var over_biteflg = "no", hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", ped_histflg = "no", over_flg = "no", soft_flag = "no", oral_habit = "Yes", type_habit_id = 0, child_went_home = "no";
      if (this.con_prb == "Sucking") {
        cond_suck = this.suck_data;
      }else if (this.con_prb == "Biting") {
        cond_suck = this.bit_data;
      }else {
        cond_suck = "";
      }

      for (var i = 0; i < this.type_of_habit_array.length; i++) {
        if (this.type_of_habit == this.type_of_habit_array[i].habit_name) {
          type_habit_id = this.type_of_habit_array[i].habit_type_id;
          break;
        }
      }
      if (this.den_hist_togg == "yes" || this.den_hist_last_vit != undefined || this.den_hist_ple != undefined || this.den_hist_prev != undefined ||
        this.den_hist_beh_last_vit != undefined || this.den_hist_sup != undefined || this.den_hist_mod_clean != undefined || this.den_hist_ot_aid != undefined) {
        ped_histflg = "yes";
      }
   
      if (this.den_hist_swt_score != undefined  || this.den_hist_diet_any != undefined) {
        diet_nalsis_flg = "yes";
      }

      this.gen_hist = {
        ped_per_hist_flag: ped_histflg,
        case_ped_flag: case_pedflg,  
        analysis_required: any_req,      
        child_health_cond: child_went_home, 
        den_habit_type_id: type_habit_id,
        condition: condition_txt,
        problem: cond_suck,
        description: descrption_data,
        frequency: freq_data,
        duration: dure_dataoral,
        intensity: inten_data,
        age_habit_stop: age_what,
        diet_details: this.diet_details_array,
        diet_flag: diet_flag_arry,
        diet_analysis_flag: diet_nalsis_flg,
        diet_analysis: this.den_hist_diet_any,
        oral_habit_flag: oral_habit,
        child_first_visit: child_visit,
        last_visit: this.den_hist_last_vit,
        visit_exp: this.den_hist_ple,
        prev_treatment: this.den_hist_prev,
        behaviour_visit: this.den_hist_beh_last_vit,
        hyg_sup_bru: this.den_hist_sup,
        hyg_mode_clean: this.den_hist_mod_clean,
        hyg_freq_bru: "",
        hyg_bru_change: this.den_hist_bru_cng,
        hyg_other_aids: this.den_hist_ot_aid,
        ple_sup_desc: "",
        sweet_score: this.den_hist_swt_score,
      }
    }
    Doc_Helper.Set_den_spl_dental(this.gen_hist);
  }

  condition_prb_change() {
    this.suck_hide = true;
    this.biting_hide = true;

    if (this.con_prb == "Sucking") {
      this.suck_hide = false;
    }else if (this.con_prb == "Biting") {
      this.biting_hide = false;
    }
  }

  speciality_change(e){
    this.get_spl_name = "";
    this.get_spl_name =Doc_Helper.get_dent_spl();
    if (this.get_spl_name == "General") {  
      this.oral_txt_hyg = true;
      this.dental_txt = true;
    }else if (this.get_spl_name == "Periodontics") {
      this.oral_txt_hyg = true;
      this.dental_txt = true;
    }else if (this.get_spl_name == "Orthodontics") {
      this.drug_mot_hidden = false;
      this.Get_client_view_app_data=Helper_Class.Get_client_view_app_data();
      if (this.Get_client_view_app_data["age"] != undefined && this.Get_client_view_app_data["age"] != "") {
        if (parseInt(this.Get_client_view_app_data["age"]) >= 6 && parseInt(this.Get_client_view_app_data["age"]) <= 23) {
      
        }
      }
      else {
       
      }
    }else if (this.get_spl_name == "Endodontics") {
      this.oral_txt_hyg = true;
      this.Natal_Hist = true;    
      this.dental_txt = true;
    }else if (this.get_spl_name == "Pediatrics") {
      this.oral_hab_div=true;
      this.pre_hide=false;
      this.dental_txt=false;
      this.habit_hidden1=false;
      this.diet_hist_hidden=false;
      this.diet_any_hidden=false;
      this.habit_hidden=false;
      this.Natal_Hist=true;    
      this.biting_hide=true;     
      this.oral_txt_hyg = false;    
      this.dey_hidden = false;     
      this.drug_mot_hidden = false;
      this.sweet_score=true;
      this.indi_hide=true;
      if (this.Get_client_view_app_data["age"] != undefined && this.Get_client_view_app_data["age"] != "") {
        if (parseInt(this.Get_client_view_app_data["age"]) >= 6 && parseInt(this.Get_client_view_app_data["age"]) <= 23) {
    
        }
      }
      else {
      }
    
      this.oral_txt_hyg = false;    
      this.Natal_Hist = true;
      this.dental_txt = false;  
      this.oral_hab_div = false;

      if (this.Get_client_view_app_data["age"] != undefined && this.Get_client_view_app_data["age"] != "") {
        if (parseInt(this.Get_client_view_app_data["age"]) <= 6) {
      }
        else {
        //  this.post_txt = true;
        }
      }
      else {
        //this.post_txt = false;
      }
    }else if (this.get_spl_name == "Prosthodontics") {    
   
    }else if (this.get_spl_name == "Public health") {   
      this.dental_txt = true;     
      this.oral_txt_hyg = true;
      this.oral_hab_div = true;
      this.child_visit_fields=true;
      this.sweet_score=false;
      this.intrup_txt=false;

      if (this.Get_client_view_app_data["age"] != undefined && this.Get_client_view_app_data["age"] != "") {
        if (parseInt(this.Get_client_view_app_data["age"]) <= 6) {
          this.Natal_Hist = true;
        }
      }else {
        this.Natal_Hist = true;
      }
    }
  } 
  
  option(d){
    this.ynoption=!this.ynoption;
    if(this.ynoption==true){
      switch(d){
        case 15:{this.ynopt15=true;this.den_hist_togg="yes";
        this.den_hist_togg_change();break;}
      }
    }
    if(this.ynoption==false){
      switch(d){
         case 15:{this.ynopt15=false;this.den_hist_togg="no";
         this.den_hist_togg_change();break;}

      }
    }
  }
  
  den_hist_togg_change() {
    if (this.den_hist_togg == "yes"||this.den_hist_togg1 == true || this.den_hist_togg == "Yes") {
      this.child_visit_fields = false;
      this.ple_hidden = false;
      this.pre_treat_hidden = false;
      this.last_vit_hidden = false;
      this.beh_last_vit_hidden = false;
    }else {
      this.child_visit_fields = true;
      this.ple_hidden = true;
      this.pre_treat_hidden = true;
      this.last_vit_hidden = true;
      this.beh_last_vit_hidden = true;
    }
  }

  diet_add_click() {
    var chk_falg = true;
    if (this.get_spl_name == "Pediatrics") {
      if (this.dietsession_txt == undefined || this.food_type == undefined || this.quantity_txt == undefined || this.qty_sugar_data == undefined) {
        chk_falg = false;

      }
    }else if (this.get_spl_name == "Public health") {
      if (this.dietsession_txt == undefined || this.food_type == undefined || this.quantity_txt == undefined) {
        chk_falg = false;
      }
    }

    if (chk_falg == true) {
      this.diet_details_array.push({
        day: "",
        session: this.dietsession_txt,
        type_food: (this.food_type),
        quantity: this.quantity_txt,
        met_prepare: this.med_prepara,
        qty_sugar: this.qty_sugar_data,
        data: false
      })
    }else {
      this.toastr.error(Message_data.mandatory);
    }
  }
  
  get_type_of_habit_array() {
    var obj = masterCSData_Helper.getMasterTypeofHabit();
    if(obj != undefined)
      this.type_of_habit_array = obj.dental_habits;
    this.RetrivalFunction();
  }

   // table delecte action
  diet_delete(sess, type, qnt) {
    for (var i = 0; i < this.diet_details_array.length; i++) {
      if (sess == this.diet_details_array[i].session && this.diet_details_array[i].type_food == type && this.diet_details_array[i].quantity == qnt) {
        this.diet_details_array.splice(i, 1);
        break;
      }
    }
  }

  public den_hist_togg1;
  changechildfirstvisit(e) {
    this.den_hist_togg1  = e;
    if(e == false){
      this.den_hist_togg="no";
      document.getElementById("den_hist_togg1").style.background = "red";
    }
    else{
      this.den_hist_togg="yes";
      this.den_hist_togg_change();
      document.getElementById("den_hist_togg1").style.background = "green";
    }
  }
}
