import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Date_Formate, Ignorecase_data } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { FrontDeskService } from '../../../app/FrontDesk_module/front-desk/front-desk.service';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diag-app-edit',
  templateUrl: './diag-app-edit.component.html',
  styleUrls: ['./diag-app-edit.component.scss']
})
export class DiagAppEditComponent implements OnInit {
  public disableDOBFlag: boolean = false;
  public disableAgeFlag: boolean = false;
  public somePlaceholder;
  public typeFilter;
  public firstNamePatient;
  public diagId;
  public clientDetailsArray = [];
  public clientFirstName;
  public clientMidName;
  public clientLastName;
  public appointmentValue;
  public clientID;
  public conformFlag: boolean;
  public userType: boolean;
  public newUserFlag: boolean;
  public existuserFlag: boolean;
  public searchFlag: boolean;
  public bookNowFlag: boolean;
  public showUltraTestFlag: boolean;
  public showCultureTestFlag: boolean;
  public showBloodTestFlag: boolean;
  public showUrineTestFlag: boolean;
  public showFeaceTestFlag: boolean;
  public showXrayTestFlag: boolean;
  public showScanTestFlag: boolean;
  public showElectroCardioTestFlag: boolean;
  public typeApp;
  public Filter;
  public referedNameArray;
  public locationArray = [];
  public cityArray = [];
  public stateArray = [];
  public countryArray = [];
  public referedDoctorArray = [];
  public salListArray = [];
  public testListArray = [];
  public bloodTestArray = [];
  public urineTestArray = [];
  public feaceTestArray = [];
  public XrayTestArray = [];
  public scanTestArray = [];
  public ultraTestArray = [];
  public CultureTestArray = [];
  public electroCardioTestArray = [];
  public bloodTestlistArray = [];
  public urineTestlistArray = [];
  public faceTestlistArray = [];
  public xrayTestlistArray = [];
  public scanTestlistArray = [];
  public ultraTestlistArray = [];
  public cultureTestlistArray = [];
  public electroCardioTestlistArray = [];
  public testGroupArray = [];
  public sendData: any = {};
  public testNameArray;
  public visitSession;
  public getVisitingSessionArray;
  public diagTest: string;
  public clientAddress1: string;
  public clientAddress2: string;
  public clientLocation: string;
  public clientCity: string;
  public clientState: string;
  public clientCountry: string;
  public clientLocationId: string;
  public clientCityId: string;
  public clientStateId: string;
  public clientcountryId: string;
  public clientZipCode: string;
  public referedBy: string;
  public clientNumber: number;
  public clientSymptom: string;
  public clientDOB: string;
  public clnt_sal: string;
  public BP_01: string;
  public BP_02: string;
  public ageURL: string;
  public appDate: string;
  public locationURL: string;
  public locationNameURL: string;
  public cityURL: string;
  public stateURL: string;
  public countryURL: string;
  public DoctorIDURL: string;
  public dateURL: string;
  public saveURL: string;
  public salURL: string;
  public splURL: string;
  public zipcodeURL: string;
  public docHospitalURL: string;
  public hospId: string;
  public clientId: string;
  public relationId: string;
  public subRelationId: string;
  public clientGender: string;
  public clientAge;
  public clientType: string;
  public currentDate: string;
  public currentTime: string;
  public currentYear: string;
  public homecare: string;
  public appointmentDate;
  public Select = "Select";
  public maxLength: number = 0;

  public concession;
  public amount;
  public inGST: boolean;
  public amountAfternoon;
  public priceTable = [];
  public balance = "0.00";
  public splArray;
  public speclization;
  public sessionFlag: boolean = true
  public timeSession;
  public startTime;
  public endTime;
  public sessionTimeStart: any = [];
  public sessionTimeEnd: any = [];
  public paymentArray: any = [];
  public insuranceFlag;
  public payType: string;
  private pay_desc: string;
  public insuranceId: string;
  public insurancyArray: any = [];
  public ynopt2: boolean;
  public ynopt1: boolean;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public bankName;
  public inpatient: boolean = false;
  public doctorName;
  public doctorRegId;
  public billingListArray = [];
  public selectedDocName;
  public appoint;
  public appID;
  public diagStatus;
  public patinetName;
  public savePrescriptionFlag: boolean;
  public presID;
  public advance = "0.00";
  public remaingBalance = "0.00";
  public test_name;
  // include biapsy
  public biopsyTestlistArray = [];
  public biopsyTestArray = [];
  public showBiopsyTestFlag: boolean;
  public diagTestGroup;
  public test_group;
  public diagtest_flag: boolean = false;
  public selTestGroupArray = [];
  public showTestGroupFlag;
  public userinfo;
  private billid;
  private finyear;
  public pat_type;
  public testexists: boolean;
  private sno:number=1;
  public paytypreq:boolean=false;
  public payType1: string;
  public amountval: number;
  public paymentsarray:any=[];

  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, 
    public http: HttpClient, public dialog: MatDialog, public frontdeskservice: MenuViewService) {
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.typeFilter = "0";
    this.concession = "0";
    this.conformFlag = true;
    this.searchFlag = false;
    this.typeApp = "New";
    this.userType = true;
    this.clientType = "Existing";
    this.existuserFlag = false;
    this.newUserFlag = true;
    this.bookNowFlag = true;
    this.zipcodeURL=ipaddress.getIp +"usercontroller/locbyzipcode";
    this.locationURL = ipaddress.getIp + "usercontroller/loc";
    this.locationNameURL = ipaddress.getIp + "usercontroller/locbyname";
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.salURL = ipaddress.getIp + "gen/sal";
    this.splURL = ipaddress.getIp + "adm/gsplbyhptl";
    this.docHospitalURL = ipaddress.getIp + "search/docbyhs";
    this.saveURL = ipaddress.getIp + "appointment/rapp";
    this.DoctorIDURL = ipaddress.getIp + "search/doctorbyid/";
    this.dateURL = ipaddress.getIp + "adm/curdate";
    this.ageURL = ipaddress.getIp + "adm/ddif";
  }

  ngOnInit(): void {
    var hospital = Helper_Class.getInfo().hospitals;
    this.userinfo = Helper_Class.getInfo();
    this.appID = Helper_Class.getdiagnosisappview().diag_id;
    this.diagStatus = Helper_Class.getdiagnosisappview().status_txt;
    this.diagId = Helper_Class.getdiagnosisappview().centre_id;

    this.showBloodTestFlag = true;
    this.showUrineTestFlag = true;
    this.showFeaceTestFlag = true;
    this.showXrayTestFlag = true;
    this.showScanTestFlag = true;
    this.showUltraTestFlag = true;
    this.showCultureTestFlag = true;
    this.showElectroCardioTestFlag = true;
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.sessionTimeStart.push("0" + i);
      } else {
        this.sessionTimeStart.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.sessionTimeEnd.push("0" + i);
      } else {
        this.sessionTimeEnd.push(i);
      }
    }
    
    if(Helper_Class.getInfo().user_type=="diagnosis"){
      if(hospital[0].bill_pay_type_req =="1"){
        this.paytypreq=false;
      } else {
        this.paytypreq=true;
      }  
      
    } else {
      if(this.userinfo.bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }
    }

    if (Diagnosis_Helper.getCreateDiagAppt() != undefined 
        && Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.diagId = Diagnosis_Helper.getCreateDiagAppt().center_id;
    }
   
    this.ynopt1 = true;
    this.insuranceFlag = true;
    this.savePrescriptionFlag = false;
    this.diagTestGroup = "test";
    this.pat_type ="";
    this.getDiagTestTypes();
    this.getAppDetails();
    this.getPaymentType();
    this.getInsurers();
  }

  //Get payment type
  getInsurers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurancyArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  backOption() {
    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.frontdeskservice.sendMessage("manageappointments");
    } else {
      this.diagnosisservice.sendMessage("appointment");
    }
  }

  getDiagTestTypes() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtests/',
      {
        diag_centre_id: this.diagId
      },
      { headers: headers })
      .subscribe(
        response => {
          this.testListArray = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.test_types != null) {
            this.testListArray = obj.test_types;
          }
          
          this.diagTest="Blood Test";
          this.diagTestChange();
        },
        error => { });
  }

  getTestGroups() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gpkgd/',
      {
        diag_centre_id: this.diagId
      },
      { headers: headers })
      .subscribe(
        response => {
          this.testGroupArray = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.packages != null) {
            this.testGroupArray = obj.packages;
          }
        },
        error => { });
  }

  getAppDetails() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp/',
      {
        diag_app_id: this.appID,
        type: "diagnosis",
        home_care: "0",
        country: "IN",
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.clnt_sal = obj.salutation;
          this.presID = obj.pres_diag_id;
          this.clientFirstName= encrypt_decript.Decript(obj.first_name);
          if(obj.middle_name != undefined){
            this.clientMidName= encrypt_decript.Decript(obj.middle_name);
          }
          this.clientLastName= encrypt_decript.Decript(obj.last_name);
          this.clientNumber = encrypt_decript.Decript(obj.mobile);
          this.clientAge = obj.age;
          this.clientGender = encrypt_decript.Decript(obj.gender);
          this.clientAddress1 = obj.address1;
          if(obj.address2 != undefined){
            this.clientAddress2 = obj.address2;
          }
          this.clientZipCode = obj.zipcode;
          this.clientLocation = obj.location;
          this.clientCity = obj.city;
          this.clientState = obj.state;
          this.clientCountry = obj.country;
          if(obj.spl_name != undefined){
            this.speclization= obj.spl_name;
          }
          if(obj.referred_by){
            this.referedBy = obj.referred_by;
          }
          this.appDate = obj.date;
          this.visitSession = obj.session;
          this.startTime=this.gservice.get_time_form_session(obj.f_time,obj.session);
          this.pat_type=obj.pat_type;
          this.advance = obj.paid_amount;
          // Test Table data
          this.bloodTestArray = [];
          if (obj.blood_test != null) {
            for (var i = 0; i < obj.blood_test.length; i++) {
              this.bloodTestArray.push({
                test_id: obj.blood_test[i].diag_test_id,
                test_name: obj.blood_test[i].diag_test_name,
                diag_type: obj.blood_test[i].diag_type,
              });
            }
          }
         
          if (obj.urine_test != null) {
            for (var i = 0; i < obj.urine_test.length; i++) {
              this.urineTestArray.push({
                test_id: obj.urine_test[i].diag_test_id,
                test_name: obj.urine_test[i].diag_test_name,
                diag_type: obj.urine_test[i].diag_type,
              });
            }
          }
          if (obj.faeces_test != null) {
            for (var i = 0; i < obj.faeces_test.length; i++) {
              this.feaceTestArray.push({
                test_id: obj.faeces_test[i].diag_test_id,
                test_name: obj.faeces_test[i].diag_test_name,
                diag_type: obj.faeces_test[i].diag_type,
              });
            }
          }
          
          if (obj.scan_test != null) {
            for (var i = 0; i < obj.scan_test.length; i++) {
              this.scanTestArray.push({
                test_id: obj.scan_test[i].diag_test_id,
                test_name: obj.scan_test[i].diag_test_name,
                diag_type: obj.scan_test[i].diag_type,
              });
            }
          }
          if (obj.xray_test != null) {
            for (var i = 0; i < obj.xray_test.length; i++) {
              this.XrayTestArray.push({
                test_id: obj.xray_test[i].diag_test_id,
                test_name: obj.xray_test[i].diag_test_name,
                diag_type: obj.xray_test[i].diag_type,
              });
            }
          }
          if (obj["ultrasound_test"] != null) {
            for (var i = 0; i < obj["ultrasound_test"].length; i++) {
              this.ultraTestArray.push({
                test_id: obj.ultrasound_test[i].diag_test_id,
                test_name: obj.ultrasound_test[i].diag_test_name,
                diag_type: obj.ultrasound_test[i].diag_type,
              });
            }
          }
          if (obj.culture_test != null) {
            for (var i = 0; i < obj.culture_test.length; i++) {
              this.CultureTestArray.push({
                test_id: obj.culture_test[i].diag_test_id,
                test_name: obj.culture_test[i].diag_test_name,
                diag_type: obj.culture_test[i].diag_type,
              });
            }
          }
          if (obj.biopsy_test != null) {
            for (var i = 0; i < obj.biopsy_test.length; i++) {
              this.biopsyTestArray.push({
                test_id: obj.biopsy_test[i].diag_test_id,
                test_name: obj.biopsy_test[i].diag_test_name,
                diag_type: obj.biopsy_test[i].diag_type,
              });
            }
          }
          
          var packages = obj.packages;
          if(packages !=undefined){
            for(var i=0;i<packages.length;i++){
              this.selTestGroupArray.push({
                test_id: packages[i].diag_packages_id,
                test_name: packages[i].package_name,
                price: packages[i].price,
              });
            }
          }
         
          var pkg_tests = obj.package_tests;
          if(pkg_tests != undefined) {
            for(var i=0;i<pkg_tests.length;i++){
              if(pkg_tests[i].diag_test_type =="blood"){
                if(this.bloodTestArray.length !=0){
                  for(var k=0;k<this.bloodTestArray.length;k++){
                    if(pkg_tests[i].diag_tests_id == this.bloodTestArray[k].test_id){
                      this.bloodTestArray.splice(k,1);
                    }
                  }
                }
              }

              if(pkg_tests[i].diag_test_type =="urine"){
                if(this.urineTestArray.length !=0){
                  for(var k=0;k<this.urineTestArray.length;k++){
                    if(pkg_tests[i].diag_tests_id == this.urineTestArray[k].test_id){
                      this.urineTestArray.splice(k,1);
                    }
                  }
                }
              }

              if(pkg_tests[i].diag_test_type =="faeces"){
                if(this.feaceTestArray.length !=0){
                  for(var k=0;k<this.feaceTestArray.length;k++){
                    if(pkg_tests[i].diag_tests_id == this.feaceTestArray[k].test_id){
                      this.feaceTestArray.splice(k,1);
                    }
                  }
                }
              }

              if(pkg_tests[i].diag_test_type =="scan"){
                if(this.scanTestArray.length !=0){
                  for(var k=0;k<this.scanTestArray.length;k++){
                    if(pkg_tests[i].diag_tests_id == this.scanTestArray[k].test_id){
                      this.scanTestArray.splice(k,1);
                    }
                  }
                }
              }

              if(pkg_tests[i].diag_test_type =="xray"){
                if(this.XrayTestArray.length !=0){
                  for(var k=0;k<this.XrayTestArray.length;k++){
                    if(pkg_tests[i].diag_tests_id == this.XrayTestArray[k].test_id){
                      this.XrayTestArray.splice(k,1);
                    }
                  }
                }
              }

              if(pkg_tests[i].diag_test_type =="ultrasound"){
                if(this.ultraTestArray.length !=0){
                  for(var k=0;k<this.ultraTestArray.length;k++){
                    if(pkg_tests[i].diag_tests_id == this.ultraTestArray[k].test_id){
                      this.ultraTestArray.splice(k,1);
                    }
                  }
                }
              }

              if(pkg_tests[i].diag_test_type =="culture"){
                if(this.CultureTestArray.length !=0){
                  for(var k=0;k<this.CultureTestArray.length;k++){
                    if(pkg_tests[i].diag_tests_id == this.CultureTestArray[k].test_id){
                      this.CultureTestArray.splice(k,1);
                    }
                  }
                }
              }

              if(pkg_tests[i].diag_test_type =="biopsy"){
                if(this.biopsyTestArray.length !=0){
                  for(var k=0;k<this.biopsyTestArray.length;k++){
                    if(pkg_tests[i].diag_tests_id == this.biopsyTestArray[k].test_id){
                      this.biopsyTestArray.splice(k,1);
                    }
                  }
                }
              }
            }
          }
          
          if(obj.payments != undefined){
            for(var i=0;i<obj.payments.length;i++){
              this.paymentsarray.push({
                pay_id: obj.payments[i].pay_id,
                pay_desc:obj.payments[i].pay_desc,
                amount:obj.payments[i].amount
              })
            }
          }

          if(obj.bills != undefined && obj.bills.length !=0){
            this.remaingBalance =obj.balance;
            this.balance=encrypt_decript.Decript(obj.bill_amount);
            this.billid = obj.bill_id;
            this.finyear=obj.fin_year;
            for(var i=0;i<obj.bills.length;i++){
              var exists = this.isTestAvailable(obj.bills[i].test_id,obj.bills[i].test_cat);
              if(exists == true){
                this.priceTable.push({
                  sno:this.sno,
                  bill_det_id: obj.bills[i].bill_details_id,
                  test_id: obj.bills[i].test_id,
                  test_name: obj.bills[i].test_name,
                  price: obj.bills[i].fee,
                  amount_txt: obj.bills[i].fee,
                  concession: parseFloat(obj.bills[i].concession),
                  fee: parseFloat(obj.bills[i].fee).toFixed(2),
                  charge_type: obj.bills[i].test_name,
                  type_of_test: obj.bills[i].test_cat,
                  pay_type: obj.bills[i].pay_type,
                  paytype: obj.bills[i].pay_id,
                  insurer: this.insuranceId,
                  quantity: 1
                });
                this.sno +=1;
              }
            }
          }
        }, error => {
          this.toastr.error(Message_data.error);
        });
  }

  //This function checks whether the test exists in any of the test array
  isTestAvailable(testid, type){
    var exists;
      if(type =="Blood"){
        if(this.bloodTestArray.length !=0){
          for(var k=0;k<this.bloodTestArray.length;k++){
            if(testid == this.bloodTestArray[k].test_id){
              exists = true;
            }
          }
        }
      }

      if(type =="Urine"){
        if(this.urineTestArray.length !=0){
          for(var k=0;k<this.urineTestArray.length;k++){
            if(testid == this.urineTestArray[k].test_id){
              exists = true;
            }
          }
        }
      }

      if(type =="Feaces"){
        if(this.feaceTestArray.length !=0){
          for(var k=0;k<this.feaceTestArray.length;k++){
            if(testid == this.feaceTestArray[k].test_id){
              exists = true;
            }
          }
        }
      }

      if(type =="Scan"){
        if(this.scanTestArray.length !=0){
          for(var k=0;k<this.scanTestArray.length;k++){
            if(testid == this.scanTestArray[k].test_id){
              exists = true;
            }
          }
        }
      }

      if(type =="Xray"){
        if(this.XrayTestArray.length !=0){
          for(var k=0;k<this.XrayTestArray.length;k++){
            if(testid == this.XrayTestArray[k].test_id){
              exists = true;
            }
          }
        }
      }

      if(type =="Ultra"){
        if(this.ultraTestArray.length !=0){
          for(var k=0;k<this.ultraTestArray.length;k++){
            if(testid == this.ultraTestArray[k].test_id){
              exists = true;
            }
          }
        }
      }

      if(type =="Culture"){
        if(this.CultureTestArray.length !=0){
          for(var k=0;k<this.CultureTestArray.length;k++){
            if(testid == this.CultureTestArray[k].test_id){
              exists = true;
            }
          }
        }
      }

      if(type =="Biopsy"){
        if(this.biopsyTestArray.length !=0){
          for(var k=0;k<this.biopsyTestArray.length;k++){
            if(testid == this.biopsyTestArray[k].test_id){
              exists = true;
            }
          }
        }
      }

      if(type =="Package"){
        if(this.selTestGroupArray.length !=0){
          for(var k=0;k<this.selTestGroupArray.length;k++){
            if(testid == this.selTestGroupArray[k].test_id){
              exists = true;
            }
          }
        }
      }
      return exists;
  }
 
  // concession calculation
  changeAmount(quantity, amount, concession, testId) {
    var perce = null;
    perce = (concession / 100);
    var amt = amount;
    if (quantity > 1) {
      amt = amt * quantity;
    }
    var amt_cons = this.amountAfternoon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAfternoon = Math.round(tot);
    var balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      if (testId == this.priceTable[i].test_id) {
        this.priceTable[i].fee = Math.round(tot);
      }
    }
    this.balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
      if (this.priceTable[i].test_id == testId) {
        this.priceTable[i].quantity = quantity;
      }
    }
    this.changeAdvance();
  }

  changeAdvance() { //add the bill details
    if (this.advance != undefined && this.advance != "") {
      this.remaingBalance = (parseFloat(this.balance) - parseFloat(this.advance)).toFixed(2);
    } else {
      this.advance = "0.00";
      this.remaingBalance = this.balance;
    }
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insuranceFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;

    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;

    } else if (this.payType == "5") {
      this.chequeFlag = false;

    } else {
      this.insuranceFlag = true;
    }
    
    for (var i = 0; i < this.paymentArray.length; i++) {
      if (this.payType == this.paymentArray[i].pay_id) {
        this.pay_desc = this.paymentArray[i].pay_desc;
        break;
      }
    }
  }

  getTestGroup(test) {
    if (test.length >= 3) {
      if (this.testGroupArray.length != 0) {
        if (this.testGroupArray.filter(o => o.package_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testGroupArray.filter(o => o.package_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testGroupArray.length; j++) {
            this.testGroupArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testGroupArray.length; j++) {
              if (this.testGroupArray[j].package_name.includes(chg[i].package_name) == true) {
                this.testGroupArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testGroupArray.length; j++) {
        this.testGroupArray[j].show = false;
      }
    }
  }

  getTest(test) {
    if (test.length >= 3) {
      if (this.testNameArray.length != 0) {
        if (this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testNameArray.length; j++) {
            this.testNameArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testNameArray.length; j++) {
              if (this.testNameArray[j].test_name.includes(chg[i].test_name) == true) {
                this.testNameArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testNameArray.length; j++) {
        this.testNameArray[j].show = false;
      }
    }
  }

  clickTest(e: any, data) {
    if (e == true) {
      if (this.diagTestGroup == "test") {
        for (var i = 0; i < this.testNameArray.length; i++) {
          if ((Ignorecase_data("Blood Test", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.bloodTestlistArray.length; i++) {
              if (this.bloodTestlistArray[i].test_id == data) {
                this.bloodTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Urine Test", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.urineTestlistArray.length; i++) {
              if (this.urineTestlistArray[i].test_id == data) {
                this.urineTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Faeces Test", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.faceTestlistArray.length; i++) {
              if (this.faceTestlistArray[i].test_id == data) {
                this.faceTestlistArray[i].checked = true;
              }
            }
          }

          if ((Ignorecase_data("Scan", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.scanTestlistArray.length; i++) {
              if (this.scanTestlistArray[i].test_id == data) {
                this.scanTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("X-ray", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.xrayTestlistArray.length; i++) {
              if (this.xrayTestlistArray[i].test_id == data) {
                this.xrayTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Ultra Sound", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.ultraTestlistArray.length; i++) {
              if (this.ultraTestlistArray[i].test_id == data) {
                this.ultraTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Electrocardio", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
              if (this.electroCardioTestlistArray[i].test_id == data) {
                this.electroCardioTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Culture", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.cultureTestlistArray.length; i++) {
              if (this.cultureTestlistArray[i].test_id == data) {
                this.cultureTestlistArray[i].checked = true;
              }
            }
          }
        }

      } else {
        for (var i = 0; i < this.testGroupArray.length; i++) {
          if (this.testGroupArray[i].test_id == data) {
            this.testGroupArray[i].checked = false;
          }
        }
      }

    } else {
      if (this.diagTestGroup == "test") {
        for (var i = 0; i < this.testNameArray.length; i++) {
          if (Ignorecase_data("Blood Test", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.bloodTestlistArray.length; i++) {
              if (this.bloodTestlistArray[i].test_id == data) {
                this.bloodTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Urine Test", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.urineTestlistArray.length; i++) {
              if (this.urineTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.urineTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Faeces Test", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.faceTestlistArray.length; i++) {
              if (this.faceTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.faceTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Scan", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.scanTestlistArray.length; i++) {
              if (this.scanTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.scanTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("X-ray", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.xrayTestlistArray.length; i++) {
              if (this.xrayTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.xrayTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Ultra Sound", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.ultraTestlistArray.length; i++) {
              if (this.ultraTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.ultraTestlistArray[i].checked = false;
              }
            }
          }
          if ((Ignorecase_data("Electrocardio", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
              if (this.electroCardioTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.electroCardioTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Culture", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.cultureTestlistArray.length; i++) {
              if (this.cultureTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.cultureTestlistArray[i].checked = false;
              }
            }
          }
        }
      } else {
        for (var i = 0; i < this.testGroupArray.length; i++) {
          if (this.testGroupArray[i].test_id == data) {
            this.testGroupArray[i].checked = false;
          }
        }
      }
    }
  }

  removeTest(testid, type){
    if (type == "Blood") {
      for (var i = 0; i < this.bloodTestArray.length; i++) {
        if (this.bloodTestArray[i].test_id == testid) {
          this.bloodTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.bloodTestlistArray.length; i++) {
        if (this.bloodTestlistArray[i].test_id == testid) {
          this.bloodTestlistArray[i].checked = false;
          break;
        }
      }
      if (this.bloodTestArray.length == 0) {
        this.showBloodTestFlag = true;
      }
      if (Ignorecase_data("Blood Test", this.diagTest) == true) {
        this.testNameArray = this.bloodTestlistArray;
      }
    } else if (type == "Urine") {
      for (var i = 0; i < this.urineTestArray.length; i++) {
        if (this.urineTestArray[i].test_id == testid) {
          this.urineTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.urineTestArray.length == 0) {
        this.showUrineTestFlag = true;
      }
      for (var i = 0; i < this.urineTestlistArray.length; i++) {
        if (this.urineTestlistArray[i].test_id == testid) {
          this.urineTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Urine Test", this.diagTest) == true) {
        this.testNameArray = this.urineTestlistArray;
      }
    } else if (type == "Feaces") {
      for (var i = 0; i < this.feaceTestArray.length; i++) {
        if (this.feaceTestArray[i].test_id == testid) {
          this.feaceTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }

      if (this.feaceTestArray.length == 0) {
        this.showFeaceTestFlag = true;
      }
      for (var i = 0; i < this.faceTestlistArray.length; i++) {
        if (this.faceTestlistArray[i].test_id == testid) {
          this.faceTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
        this.testNameArray = this.faceTestlistArray;
      }
    } else if (type == "Xray") {
      for (var i = 0; i < this.XrayTestArray.length; i++) {
        if (this.XrayTestArray[i].test_id == testid) {
          this.XrayTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.XrayTestArray.length == 0) {
        this.showXrayTestFlag = true;
      }
      for (var i = 0; i < this.xrayTestlistArray.length; i++) {
        if (this.xrayTestlistArray[i].test_id == testid) {
          this.xrayTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("X-ray", this.diagTest) == true) {
        this.testNameArray = this.xrayTestlistArray;
      }
    } else if (type == "Scan") {
      for (var i = 0; i < this.scanTestArray.length; i++) {
        if (this.scanTestArray[i].test_id == testid) {
          this.scanTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.scanTestArray.length == 0) {
        this.showScanTestFlag = true;
      }
      for (var i = 0; i < this.scanTestlistArray.length; i++) {
        if (this.scanTestlistArray[i].test_id == testid) {
          this.scanTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Scan", this.diagTest) == true) {
        this.testNameArray = this.scanTestlistArray;
      }
    } else if (type == "Ultra") {
      for (var i = 0; i < this.ultraTestArray.length; i++) {
        if (this.ultraTestArray[i].test_id == testid) {
          this.ultraTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.ultraTestArray.length == 0) {
        this.showUltraTestFlag = true;
      }
      for (var i = 0; i < this.ultraTestlistArray.length; i++) {
        if (this.ultraTestlistArray[i].test_id == testid) {
          this.ultraTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
        this.testNameArray = this.ultraTestlistArray;
      }
    } else if (type == "Electrocardio") {
      for (var i = 0; i < this.electroCardioTestArray.length; i++) {
        if (this.electroCardioTestArray[i].test_id == testid) {
          this.electroCardioTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.electroCardioTestArray.length == 0) {
        this.showElectroCardioTestFlag = true;
      }
      for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
        if (this.electroCardioTestlistArray[i].test_id == testid) {
          this.electroCardioTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
        this.testNameArray = this.electroCardioTestlistArray;
      }
    } else if (type == "Culture") {
      for (var i = 0; i < this.CultureTestArray.length; i++) {
        if (this.CultureTestArray[i].test_id == testid) {
          this.CultureTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.CultureTestArray.length == 0) {
        this.showCultureTestFlag = true;
      }
      for (var i = 0; i < this.cultureTestlistArray.length; i++) {
        if (this.cultureTestlistArray[i].test_id == testid) {
          this.cultureTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Culture", this.diagTest) == true) {
        this.testNameArray = this.cultureTestlistArray;
      }
    } else if (type == "Biopsy") {
      for (var i = 0; i < this.biopsyTestArray.length; i++) {
        if (this.biopsyTestArray[i].test_id == testid) {
          this.biopsyTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.biopsyTestArray.length == 0) {
        this.showUltraTestFlag = true;
      }
      for (var i = 0; i < this.biopsyTestlistArray.length; i++) {
        if (this.biopsyTestlistArray[i].test_id == testid) {
          this.biopsyTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Biopsy", this.diagTest) == true) {
        this.testNameArray = this.biopsyTestlistArray;
      }

    } else if (type == "Package") {
      for (var i = 0; i < this.selTestGroupArray.length; i++) {
        if (this.selTestGroupArray[i].test_id == testid) {
          this.selTestGroupArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.selTestGroupArray.length == 0) {
        this.showUltraTestFlag = true;
      }
      for (var i = 0; i < this.testGroupArray.length; i++) {
        if (this.testGroupArray[i].diag_packages_id == testid) {
          this.testGroupArray[i].checked = false;
          break;
        }
      }

    }
    this.balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
    }
    this.changeAdvance();
  }

  diagTestChange(){
    if (this.diagTest != undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtestdetails/',
        {
          diag_centre_id: this.diagId,
          test_type: this.diagTest
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.test_details != null) {
              if (Ignorecase_data("Blood Test", this.diagTest) == true) {
                if (this.bloodTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.bloodTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.bloodTestlistArray;
                } else {
                  this.testNameArray = this.bloodTestlistArray;
                }

              } else if (Ignorecase_data("Urine Test", this.diagTest) == true) {
                if (this.urineTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.urineTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.urineTestlistArray;
                } else {
                  this.testNameArray = this.urineTestlistArray;
                }

              } else if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
                if (this.faceTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.faceTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.faceTestlistArray;
                } else {
                  this.testNameArray = this.faceTestlistArray;
                }

              } else if (Ignorecase_data("Scan", this.diagTest) == true) {
                if (this.scanTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.scanTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.scanTestlistArray;
                } else {
                  this.testNameArray = this.scanTestlistArray;
                }

              } else if (Ignorecase_data("X-ray", this.diagTest) == true) {
                if (this.xrayTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.xrayTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.xrayTestlistArray;
                } else {
                  this.testNameArray = this.xrayTestlistArray;
                }

              } else if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
                if (this.ultraTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.ultraTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.ultraTestlistArray;
                } else {
                  this.testNameArray = this.ultraTestlistArray;
                }
              } else if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
                if (this.electroCardioTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.electroCardioTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.electroCardioTestlistArray;
                } else {
                  this.testNameArray = this.electroCardioTestlistArray;
                }
              } else if (Ignorecase_data("Culture", this.diagTest) == true) {
                if (this.cultureTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.cultureTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.cultureTestlistArray;
                } else {
                  this.testNameArray = this.cultureTestlistArray;
                }
              } else if (Ignorecase_data("Biopsy", this.diagTest) == true) {
                if (this.biopsyTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.biopsyTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.biopsyTestlistArray;
                } else {
                  this.testNameArray = this.biopsyTestlistArray;
                }
              }
            }
          },
          error => { });
    } else {
      this.testNameArray = [];
    }
  }

  diagTestGroupChange(){
    if (this.diagTestGroup == "package") {
      if (this.testGroupArray.length == 0) {
        this.getTestGroups();
      }
      this.diagtest_flag = true;

    } else {
      this.getDiagTestTypes();
      this.diagtest_flag = false;
    }
  }

  addTest() {
    var results;
    if (this.diagTestGroup == "test") {
      if (this.bloodTestlistArray.length != 0 || this.urineTestlistArray.length != 0 || this.faceTestlistArray.length != 0 || this.xrayTestlistArray.length != 0 || this.scanTestlistArray.length != 0 || this.ultraTestlistArray.length != 0 || this.cultureTestlistArray.length != 0 || this.biopsyTestlistArray.length != 0) {
        if (Ignorecase_data("Blood Test", this.diagTest) == true) {
          for (var i = 0; i < this.bloodTestlistArray.length; i++) {
            if (this.bloodTestlistArray[i].checked == true) {
              if (this.bloodTestArray.length != 0) {
                results = this.finekeyInArray(this.bloodTestArray, this.bloodTestlistArray[i].test_id);
                if (results != true) {
                  this.bloodTestArray.push({
                    test_id: this.bloodTestlistArray[i].test_id,
                    test_name: this.bloodTestlistArray[i].test_name,
                    diag_type: this.bloodTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.bloodTestArray.push({
                  test_id: this.bloodTestlistArray[i].test_id,
                  test_name: this.bloodTestlistArray[i].test_name,
                  diag_type: this.bloodTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.bloodTestArray, "Blood");

        } else if (Ignorecase_data("Urine Test", this.diagTest) == true) {
          for (var i = 0; i < this.urineTestlistArray.length; i++) {
            if (this.urineTestlistArray[i].checked == true) {
              if (this.urineTestArray.length != 0) {
                results = this.finekeyInArray(this.urineTestArray, this.urineTestlistArray[i].test_id);
                if (results != true) {
                  this.urineTestArray.push({
                    test_id: this.urineTestlistArray[i].test_id,
                    test_name: this.urineTestlistArray[i].test_name,
                    diag_type: this.urineTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.urineTestArray.push({
                  test_id: this.urineTestlistArray[i].test_id,
                  test_name: this.urineTestlistArray[i].test_name,
                  diag_type: this.urineTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.urineTestArray, "Urine");

        } else if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
          for (var i = 0; i < this.faceTestlistArray.length; i++) {
            if (this.faceTestlistArray[i].checked == true) {
              if (this.feaceTestArray.length != 0) {
                results = this.finekeyInArray(this.feaceTestArray, this.faceTestlistArray[i].test_id);
                if (results != true) {
                  this.feaceTestArray.push({
                    test_id: this.faceTestlistArray[i].test_id,
                    test_name: this.faceTestlistArray[i].test_name,
                    diag_type: this.faceTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.feaceTestArray.push({
                  test_id: this.faceTestlistArray[i].test_id,
                  test_name: this.faceTestlistArray[i].test_name,
                  diag_type: this.faceTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.feaceTestArray, "Feaces");

        } else if (Ignorecase_data("X-ray", this.diagTest) == true) {
          for (var i = 0; i < this.xrayTestlistArray.length; i++) {
            if (this.xrayTestlistArray[i].checked == true) {
              if (this.XrayTestArray.length != 0) {
                results = this.finekeyInArray(this.XrayTestArray, this.xrayTestlistArray[i].test_id);

                if (results != true) {
                  this.XrayTestArray.push({
                    test_id: this.xrayTestlistArray[i].test_id,
                    test_name: this.xrayTestlistArray[i].test_name,
                    diag_type: this.xrayTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.XrayTestArray.push({
                  test_id: this.xrayTestlistArray[i].test_id,
                  test_name: this.xrayTestlistArray[i].test_name,
                  diag_type: this.xrayTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.XrayTestArray, "Xray");

        } else if (Ignorecase_data("Scan", this.diagTest) == true) {
          for (var i = 0; i < this.scanTestlistArray.length; i++) {
            if (this.scanTestlistArray[i].checked == true) {
              if (this.scanTestArray.length != 0) {
                results = this.finekeyInArray(this.scanTestArray, this.scanTestlistArray[i].test_id);

                if (results != true) {
                  this.scanTestArray.push({
                    test_id: this.scanTestlistArray[i].test_id,
                    test_name: this.scanTestlistArray[i].test_name,
                    diag_type: this.scanTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.scanTestArray.push({
                  test_id: this.scanTestlistArray[i].test_id,
                  test_name: this.scanTestlistArray[i].test_name,
                  diag_type: this.scanTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.scanTestArray, "Scan");

        } else if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
          for (var i = 0; i < this.ultraTestlistArray.length; i++) {
            if (this.ultraTestlistArray[i].checked == true) {
              if (this.ultraTestArray.length != 0) {
                results = this.finekeyInArray(this.ultraTestArray, this.ultraTestlistArray[i].test_id);

                if (results != true) {
                  this.ultraTestArray.push({
                    test_id: this.ultraTestlistArray[i].test_id,
                    test_name: this.ultraTestlistArray[i].test_name,
                    diag_type: this.ultraTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.ultraTestArray.push({
                  test_id: this.ultraTestlistArray[i].test_id,
                  test_name: this.ultraTestlistArray[i].test_name,
                  diag_type: this.ultraTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.ultraTestArray, "Ultra");

        } else if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
          for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
            if (this.electroCardioTestlistArray[i].checked == true) {
              if (this.electroCardioTestArray.length != 0) {
                results = this.finekeyInArray(this.electroCardioTestArray, this.electroCardioTestlistArray[i].test_id);

                if (results != true) {
                  this.electroCardioTestArray.push({
                    test_id: this.electroCardioTestlistArray[i].test_id,
                    test_name: this.electroCardioTestlistArray[i].test_name,
                    diag_type: this.electroCardioTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.electroCardioTestArray.push({
                  test_id: this.electroCardioTestlistArray[i].test_id,
                  test_name: this.electroCardioTestlistArray[i].test_name,
                  diag_type: this.electroCardioTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.electroCardioTestArray, "Electrocardio");

        } else if (Ignorecase_data("Culture", this.diagTest) == true) {
          for (var i = 0; i < this.cultureTestlistArray.length; i++) {
            if (this.cultureTestlistArray[i].checked == true) {
              if (this.CultureTestArray.length != 0) {
                results = this.finekeyInArray(this.CultureTestArray, this.cultureTestlistArray[i].test_id);

                if (results != true) {
                  this.CultureTestArray.push({
                    test_id: this.cultureTestlistArray[i].test_id,
                    test_name: this.cultureTestlistArray[i].test_name,
                    diag_type: this.cultureTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.CultureTestArray.push({
                  test_id: this.cultureTestlistArray[i].test_id,
                  test_name: this.cultureTestlistArray[i].test_name,
                  diag_type: this.cultureTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.CultureTestArray, "Culture");

        } else if (Ignorecase_data("Biopsy", this.diagTest) == true) {
          for (var i = 0; i < this.biopsyTestlistArray.length; i++) {
            if (this.biopsyTestlistArray[i].checked == true) {
              if (this.biopsyTestArray.length != 0) {
                results = this.finekeyInArray(this.biopsyTestArray, this.biopsyTestlistArray[i].test_id);

                if (results != true) {
                  this.biopsyTestArray.push({
                    test_id: this.biopsyTestlistArray[i].test_id,
                    test_name: this.biopsyTestlistArray[i].test_name,
                    diag_type: this.biopsyTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.biopsyTestArray.push({
                  test_id: this.biopsyTestlistArray[i].test_id,
                  test_name: this.biopsyTestlistArray[i].test_name,
                  diag_type: this.biopsyTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.biopsyTestArray, "Biopsy");
        }
        
        if (this.bloodTestArray.length != 0) {
          this.showBloodTestFlag = false;
        }
        if (this.urineTestArray.length != 0) {
          this.showUrineTestFlag = false;
        }
        if (this.feaceTestArray.length != 0) {
          this.showFeaceTestFlag = false;
        }
        if (this.XrayTestArray.length != 0) {
          this.showXrayTestFlag = false;
        }
        if (this.scanTestArray.length != 0) {
          this.showScanTestFlag = false;
        }
        if (this.ultraTestArray.length != 0) {
          this.showUltraTestFlag = false;
        }
        if (this.electroCardioTestArray.length != 0) {
          this.showElectroCardioTestFlag = false;
        }
        if (this.CultureTestArray.length != 0) {
          this.showCultureTestFlag = false;
        }
        if (this.biopsyTestArray.length != 0) {
          this.showBiopsyTestFlag = false;
        }
      }

    } else {
      for (var i = 0; i < this.testGroupArray.length; i++) {
        if (this.testGroupArray[i].checked == true) {
          if (this.selTestGroupArray.length != 0) {
            results = this.finekeyInArray(this.selTestGroupArray, this.testGroupArray[i].diag_packages_id);
            if (results != true) {
              this.selTestGroupArray.push({
                test_id: this.testGroupArray[i].diag_packages_id,
                test_name: this.testGroupArray[i].package_name,
                price: this.testGroupArray[i].price,
                //diag_type: this.testGroupArray[i].diag_type,
              });
            }
          } else {
            this.selTestGroupArray.push({
              test_id: this.testGroupArray[i].diag_packages_id,
              test_name: this.testGroupArray[i].package_name,
              price: this.testGroupArray[i].price,
              //diag_type: this.testGroupArray[i].diag_type,
            });
          }
        }
      }
      this.addAmount(this.selTestGroupArray, "Package");

      if (this.selTestGroupArray.length != 0) {
        this.showTestGroupFlag = false;
      }
    }

  }

  addAmount(test, type_of_test) {
    if (this.diagTestGroup == "test") {
      var subTest;
      for (var i = 0; i < test.length; i++) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbyid',
          {
            test_id: test[i].test_id,
            type: "diagnosis",
            country: "IN"
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              this.amount = obj.price;
              var perce = this.concession / 100;
              var amt = this.amount;
              var amt_cons = this.amountAfternoon;
              var net_pay = parseFloat(amt) * parseFloat(perce.toString());
              var tot = amt - net_pay;
              if (isNaN(tot)) {
                tot = 0;
              }
              var paydesc;
              if (this.paymentArray.length != 0) {
                for (var i = 0; i < this.paymentArray.length; i++) {
                  if (this.payType == this.paymentArray[i].pay_id) {
                    paydesc = this.paymentArray[i].pay_desc;
                  }
                }
              }
              this.amountAfternoon = Math.round(tot);
              var results;
              if (this.priceTable.length != 0) {
                for (var j = 0; j < this.priceTable.length; j++) {
                  results = this.finekeyInArray(this.priceTable, obj.diag_tests_id);

                  if (results != true) {
                    var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
                    this.priceTable.push({
                      sno:this.sno,
                      test_id: obj.diag_tests_id,
                      test_name: obj.test_name,
                      price: obj.price,
                      amount_txt: amt_txt,
                      concession: parseFloat(this.concession),
                      fee: parseFloat(this.amountAfternoon).toFixed(2),
                      charge_type: obj.test_name,
                      type_of_test: type_of_test,
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insuranceId,
                      quantity: 1
                    });
                    this.sno +=1;
                    this.changeAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id);
                  }
                }
              } else {
                var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
                this.priceTable.push({
                  sno:this.sno,
                  test_id: obj.diag_tests_id,
                  test_name: obj.test_name,
                  price: obj.price,
                  amount_txt: amt_txt,
                  concession: parseFloat(this.concession),
                  fee: parseFloat(this.amountAfternoon).toFixed(2),
                  charge_type: obj.test_name,
                  type_of_test: type_of_test,
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insuranceId,
                  quantity: 1
                });
                this.sno +=1;
                this.changeAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id);
              }
              this.balance = "0.00";
              for (var i = 0; i < this.priceTable.length; i++) {
                this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
              }
              this.changeAdvance();
            });
      }

    } else {
      for (var i = 0; i < test.length; i++) {
        this.amount = test[i].price;
        var perce = this.concession / 100;
        var amt = this.amount;
        var amt_cons = this.amountAfternoon;
        var net_pay = parseFloat(amt) * parseFloat(perce.toString());
        var tot = amt - net_pay;
        if (isNaN(tot)) {
          tot = 0;
        }

        this.amountAfternoon = Math.round(tot);
        this.amountAfternoon = Math.round(tot);

        var results;
        if (this.priceTable.length != 0) {
          for (var j = 0; j < this.priceTable.length; j++) {
            results = this.finekeyInArray(this.priceTable, test[i].test_id);
            if (results != true) {
              var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
              this.priceTable.push({
                sno:this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1
              });
              this.sno +=1;
              this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id);
            }
          }

        } else {
          var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
          this.priceTable.push({
            sno:this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1
          });
          this.sno +=1;
          this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id);
        }
      }
      this.balance = "0.00";
      if (this.priceTable.length != 0) {
        for (var i = 0; i < this.priceTable.length; i++) {
          this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
        }
        this.changeAdvance();
      }
    }
  }

  finekeyInArray(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].test_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  contains(elem) {
    for (var i in this) {
      if (this[i] == elem) return true;
    }
    return false;
  }

  //Get payment type
  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paymentArray = obj.payments;
          this.payType = this.paymentArray[0].pay_id;
          this.pay_desc = this.paymentArray[0].pay_desc;

        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  saveApp() {
    this.sendData = {};
    var blood_array = [];
    var urine_arry = [];
    var scan_arry = [];
    var Faeces_tests = [];
    var Xray_tests = [];
    var ultra_tests = [];
    var culture_test = [];
    var biopsy_test = [];
    var package_test = [];
    var valid_flag = false;
    
    if (this.priceTable.length == 0) {
      this.toastr.error(Message_data.plsAddTestBfrSave);
      valid_flag = true;
    }
    if (valid_flag == false) {
      if (this.savePrescriptionFlag == true) {
        //  this.saveDiagnosisPrescription()
      } else {
        if (this.bloodTestArray.length != 0) {
          for (var i = 0; i < this.bloodTestArray.length; i++) {
            blood_array.push(this.bloodTestArray[i].test_id);
          }
        }

        if (this.urineTestArray.length != 0) {
          for (var i = 0; i < this.urineTestArray.length; i++) {
            urine_arry.push(this.urineTestArray[i].test_id);
          }
        }

        if (this.feaceTestArray.length != 0) {
          for (var i = 0; i < this.feaceTestArray.length; i++) {
            Faeces_tests.push(this.feaceTestArray[i].test_id);
          }
        }

        if (this.XrayTestArray.length != 0) {
          for (var i = 0; i < this.XrayTestArray.length; i++) {
            Xray_tests.push(this.XrayTestArray[i].test_id);
          }
        }

        if (this.scanTestArray.length != 0) {
          for (var i = 0; i < this.scanTestArray.length; i++) {
            scan_arry.push(this.scanTestArray[i].test_id);
          }
        }

        if (this.ultraTestArray.length != 0) {
          for (var i = 0; i < this.ultraTestArray.length; i++) {
            ultra_tests.push(this.ultraTestArray[i].test_id);
          }
        }
        if (this.CultureTestArray.length != 0) {
          for (var i = 0; i < this.CultureTestArray.length; i++) {
            culture_test.push(this.CultureTestArray[i].test_id);
          }
        }
        if (this.biopsyTestArray.length != 0) {
          for (var i = 0; i < this.biopsyTestArray.length; i++) {
            biopsy_test.push(this.biopsyTestArray[i].test_id);
          }
        }
        if (this.selTestGroupArray.length != 0) {
          for (var i = 0; i < this.selTestGroupArray.length; i++) {
            package_test.push(this.selTestGroupArray[i].test_id);
          }
        }
        this.sendData = {
          "Blood Test": blood_array,
          "Urine Test": urine_arry,
          "Faeces Test": Faeces_tests,
          "X-ray": Xray_tests,
          "Scan": scan_arry,
          "Ultra Sound": ultra_tests,
          "Culture": culture_test,
          "Biopsy": biopsy_test,
          "package": package_test,
        }

        this.sendData.diag_centre_id = this.diagId;
        this.sendData.pres_id = this.presID;
        this.sendData.diag_app_id = this.appID;
        this.sendData.provider = "diagnosis";
        this.sendData.bill_id = this.billid;
        this.sendData.bills= this.priceTable;
        this.sendData.country="IN";
        var balance = "0";
        var paid_flag;
        for (var i = 0; i < this.priceTable.length; i++) {
          balance = (parseFloat(balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
        }
        
        if (balance == this.remaingBalance) {
          paid_flag = "Un paid";
        } else if (balance != "0.00" && (this.advance != "0.00") && balance != this.advance) {
          paid_flag = "Partially paid";
        } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
          paid_flag = "Fully paid";
        }

        this.sendData.balance= this.remaingBalance;
        this.sendData.paid_amt= this.advance,
        this.sendData.paid_flag= paid_flag;
        this.sendData.bill_amount= encrypt_decript.Encript(balance).toString();
        this.sendData.card_no= this.cardNumber;
        this.sendData.card_holder_name= this.cardHolderName;
        this.sendData.transaction_no= this.transactionID;
        this.sendData.bank_name= this.bankName;
        this.sendData.fin_year=this.finyear;
        this.sendData.created_by= Diagnosis_Helper.getUserID_Type().user_id;
        this.sendData.payments=this.paymentsarray;

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "bill/udbill", this.sendData, { headers: headers }).subscribe(
          data => {
            var response_data = JSON.parse(JSON.stringify(data));

            if (response_data.key == "1") {
              this.toastr.success(response_data.result);
                var billdetail = {
                  bill_view_id: this.billid,
                  Diag_center_id: this.diagId,
                  flow: "dig_walking",
                  fin_year: this.finyear,
                }
                Diagnosis_Helper.setbilldetailview(billdetail);
                if (Diagnosis_Helper.getCreateDiagAppt() != undefined 
                    && Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
                  this.frontdeskservice.sendMessage("diaBillingDetailsView");
                      
                } else {
                  this.diagnosisservice.sendMessage("diagbilldetailview");
                }

              } else {
                this.toastr.error(response_data.result);
              }
            }, error => {

            }
        );
      }
    }
  }

  addPaymentDetails(){
    var bamt=0;
    for(var i=0;i<this.paymentArray.length;i++){
      if(this.payType1 == this.paymentArray[i].pay_id){
        if(this.paymentsarray.length != 0){
          for(var j=0;j<this.paymentsarray.length;j++){
            bamt+=parseInt(this.paymentsarray[j].amount);
          }
          bamt+=parseFloat(this.amountval.toString());
        } else {
          bamt+=parseFloat(this.amountval.toString());
        }
        
        if(bamt> parseInt(this.advance)){
          this.toastr.error("Amount cannot be more than paid amount")
        } else {
          this.paymentsarray.push({
            pay_id:this.paymentArray[i].pay_id,
            pay_desc:this.paymentArray[i].pay_desc,
            amount:this.amountval.toFixed(2)
          })
          this.amountval=0;
        }
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=data.amount;
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
