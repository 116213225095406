<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white ">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Appointment details</h5>
                        </div>
                        <div class="headerButtons">
                            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
                                width="85px" style="margin-right: 9px;" />
                            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel_click()"
                                width="85px" style="margin-right: 9px;" />
                            <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="appointment_update()"
                                width="85px" style="margin-right: 9px;" />
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div>
                        <app-loader></app-loader>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="cover_div">
                                <div class="header_lable">
                                    patient details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Name
                                                <input type="text" class="ipcss" [(ngModel)]="clientName" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Age
                                                <input type="text" class="ipcss" [(ngModel)]="age" readonly matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Gender
                                                <input type="text" class="ipcss" [(ngModel)]="gender" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Phone number
                                                <input type="text" class="ipcss" [(ngModel)]="phoneNo" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Blood group
                                                <input type="text" class="ipcss" [(ngModel)]="bloodGroup" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Relationship
                                                <input type="text" class="ipcss" [(ngModel)]="relationType" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cover_div" style="margin-top: 15px;">
                                <div class="header_lable">
                                    Medical & Appointment details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Symptom
                                                <input type="text" class="ipcss" [(ngModel)]="symtomes" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Treatment type
                                                <input type="text" class="ipcss" [(ngModel)]="treatementType" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Speciality
                                                <input type="text" class="ipcss" [(ngModel)]="spl" readonly matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Doctor name
                                                <input type="text" class="ipcss" [(ngModel)]="doctorName" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Hospital name
                                                <input type="text" class="ipcss" [(ngModel)]="hospitalName" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Appointment date
                                                <input type="text" class="ipcss" [(ngModel)]="appDate" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Session
                                                <input type="text" class="ipcss" [(ngModel)]="session" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Token number
                                                <input type="text" class="ipcss" [(ngModel)]="tokenNo" readonly
                                                    matInput />
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cover_div" style="margin-top: 15px;">
                                <div class="header_lable">
                                    Observations
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Height
                                                <input type="text" class="ipcss" (change)="calories_data()"
                                                    maxlength="{{heightMesure == 'cms' && (3) || (5)}}"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    required [(ngModel)]="height" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Measure
                                                <select class="ipcss" required [(ngModel)]="heightMesure"
                                                    (change)="calories_data()">
                                                    <option value="cms">cms</option>
                                                    <option value="inch">inch</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Weight
                                                <input type="text" class="ipcss" (change)="calories_data()"
                                                    maxlength="5"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    required [(ngModel)]="weight" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Measure
                                                <select class="ipcss" required [(ngModel)]="weightMesure"
                                                    (change)="calories_data()">
                                                    <option value="kgs">kgs</option>
                                                    <option value="lbs">lbs</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Temperature
                                                <input type="text" class="ipcss" [(ngModel)]="temprature" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">BMI
                                                <input type="text" class="ipcss" [(ngModel)]="bmi" matInput />
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cover_div" style="margin-top: 15px;">
                                <div class="header_lable">
                                    Other bservations
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Chief complaints
                                                <textarea type="text" class="ipcss" [(ngModel)]="chiefCompalaint"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Present illness
                                                <textarea type="text" class="ipcss" [(ngModel)]="presentIllness"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Past medical history
                                                <textarea type="text" class="ipcss" [(ngModel)]="medicalHistory"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Family history
                                                <textarea type="text" class="ipcss" [(ngModel)]="familyHistory"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Physical examination
                                                <textarea type="text" class="ipcss" [(ngModel)]="physicalExamination"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>