<div class="row" style="width: 100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill Create</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
            class="saveimgbtn_inpatinfo " />
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" (click)="saveBill()"
            class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" >
            <mat-label class="matlabel">Diagnosis center<br>
              <select disableOptionCentering class="ipcss" [(ngModel)]="location">
                <option *ngFor="let loc of Diag_Location_Data" value={{loc.view_data}}>{{loc.view_data}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy" [hidden]="date_time_flag">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss_date" id="appt_date" (change)="OnDateChangedto(date)"
                [(ngModel)]="date" max="{{currentDate}}" #matInput style="background: #fff;">
            </mat-label>

          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" >
            <mat-label class="matlabel">Appointment id<br>
              <select required class="ipcss widthappt" [(ngModel)]="appoint" disableOptionCentering
                (change)="changeAppID()">
                <option *ngFor="let billcreate of billListArray" value="{{billcreate.diagid_name}}">
                  {{billcreate.diagid_name}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Patient name<br>
              <input class="ipcss  widthappt" matInput [(ngModel)]="clientName" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Tests<br>
              <select required class="ipcss widthappt" (ngModelChange)="changeTest($event)"
                [(ngModel)]="testName" disableOptionCentering required>
                <option *ngFor="let test of testArray" value="{{test.Testname}}"> {{test.Testname}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <mat-select  id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType" (selectionChange)="changePay()" disableOptionCentering>
                <mat-option *ngFor="let pay of payTypeArray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="insuranceFlag">
            <mat-label class="matlabel">Insurer<br>
              <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerancyID" disableOptionCentering>
                <mat-option *ngFor="let ins of insurerancListArray" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
         
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="tranFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Quantity<br>
              <input type=number class="ipcss" matInput required (keyup)="changeAmount()"
                [(ngModel)]="quantity" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Amount
              <input class="ipcss billcreate" required (change)="changeAmount()" [(ngModel)]="amount" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Concession %
              <input class="ipcss billcreate" (change)="changeAmount()" [(ngModel)]="concession" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Amount after concession
              <input class="ipcss billcreate" disabled="true" [(ngModel)]="fees" />
            </mat-label>
          </div>

          <div class="col-12 col-md-12 col-lg-12 col-xl-12 " style="margin-top: 10px;">
            <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo "
              (click)="billAdd()" style="float: right;" />
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <!-- <p class="nodata" *ngIf="billaddlist.length == 0">No bill(s) Found</p> -->
            <div *ngIf="addBillArray.length" style="margin: 10px 0;">
              <div class="dig_table_overflow">
                <div class="table-responsive">
                  <table class="table table-hover table-dynamic" id="card_tbl">
                    <thead>
                      <tr style="height: 32px;">
                        <th>Sno</th>
                        <th>Test</th>
                        <th [hidden]="paytypreq">Payment type</th>
                        <th>Fees</th>
                        <th>Quantity</th>
                        <th>Concession</th>
                        <th>Amount</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let person of addBillArray" class="tdvalues">
                        <td style="text-align: left;">{{person.sno}}</td>
                        <td style="text-align: left;">{{person.test_name}}</td>
                        <td [hidden]="paytypreq">{{person.pay_type}}</td>
                        <td style="text-align: right;">{{person.amount_txt}}</td>
                        <td style="text-align: right;">{{person.quantity}}</td>
                        <td style="text-align: right;">{{person.concession}}</td>
                        <td style="text-align: right;">{{person.fee}}</td>
                        <td class="delete">
                          <a (click)="deleteBill(person.biill_type,person.amount_txt)"> <img
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" alt=""></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row " style="margin-top: 10px;">
          <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" [hidden]="!paytypreq">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                    <mat-select class="ipcss" [(ngModel)]="payType1" disableOptionCentering (change)="changePayTypeReq()">
                      <mat-option *ngFor="let pay of payTypeArray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insurFlag1">
                  <mat-label class="matlabel">Insurer<br>
                    <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerancyID" disableOptionCentering>
                      <mat-option *ngFor="let ins of insurerancListArray" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="cardFlag1">
                  <mat-label class="matlabel">Card No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" required />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="tranFlag1">
                  <mat-label class="matlabel">Transaction ID<br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" required/>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="chequeFlag1">
                  <mat-label class="matlabel">Cheque No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" required/>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" >
                  <mat-label class="matlabel">Amount<br>
                    <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align"
                      (click)="addPaymentDetails()" style="float: right;margin-top: 20px;"/>
                  </mat-label>
                </div>
              </div>

              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                      <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
          </div>
          </div>
          <div class="col-0 col-sm-9  col-md-5 col-lg-5  col-xl-5">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel ">Include GST &nbsp;&nbsp; </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST" (change)="includeGST(inGST)">
                </mat-checkbox>
              </div>
              <div *ngFor="let gsttax of GSTArray">
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                  </div>
                  <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                    <mat-label style="
                    border-radius: 5px;text-align: right;" class=" ipcss   ">
                      {{gsttax.Gst_amount}}</mat-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7"></div>
          <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="previousBalFlag">
                <mat-label class="matlabel  ">Previous balance </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="previousBalFlag">
                <input class="ipcss   " matInput text-right [(ngModel)]="balance" maxlength="10" disabled />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel ">Total </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss   " style="border-radius: 5px;text-align: right;" matInput text-right
                  disabled="true" [(ngModel)]="finalAmount" />

              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel ">Advance / Paid</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss   " matInput text-right (keyup)="changeAdvance()" [(ngModel)]="advance"
                  maxlength="10" style="border-radius: 5px;text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel " [hidden]="balanceFlag">Balance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss   " [hidden]="balanceFlag" matInput text-right [(ngModel)]="remaingBalance"
                  maxlength="10" style="border-radius: 5px;text-align: right;" disabled />
              </div>
            </div>
          </div>
        </div>

      </mdb-card-body>
    </mdb-card>
  </div>
</div>