<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Medicine tracker</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="closePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="diag_content">
  <div class="row" style="margin:10px;max-height: 250px;">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
      <mat-label class="matlabel" style="margin-top: 7px;">Date
        <input type="date" class="ipcss_date" [(ngModel)]="date" (change)="getDate(date,'Appointment_Date')"
          matInput />
      </mat-label>
    </div>

    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
      <mat-label class="matlabel" style="margin-top: 7px;">Time</mat-label>
      <div class="row">
        <div class="col-3 col-sm-4 col-lg-4 col-md-3 col-xl-4">
          <mat-select disableOptionCentering class="ipcss " [(ngModel)]="startFrom1">
            <mat-option *ngFor="let name of startsFromArray" value={{name}}>{{name}}</mat-option>
          </mat-select>
        </div>
        <div class="col-1" style="margin-left: -13px;margin-right: -14px;">
          <span>:</span>
        </div>
        <div class="col-3 col-sm-4 col-lg-4 col-md-3 col-xl-4">
          <mat-select disableOptionCentering class="ipcss " [(ngModel)]="startFrom2">
            <mat-option *ngFor="let name of endsAtrray" value={{name}}>{{name}}</mat-option>
          </mat-select>
        </div>
        <div class="col-3 col-sm-4 col-lg-4 col-md-3 col-xl-4">
          <mat-select disableOptionCentering class="ipcss " [(ngModel)]="startFrom3">
            <mat-option value="AM">AM</mat-option>
            <mat-option value="PM">PM</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
      <mat-label class="matlabel" style="margin-top: 7px;">Intake<br>
        <mat-select disableOptionCentering class="ipcss " [(ngModel)]="visitSession"
          (ngModelChange)="changeVisit($event)">
          <mat-option *ngFor="let session of visitingData" value={{session.description}}>
            {{session.description}}</mat-option>
        </mat-select>
      </mat-label>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="    padding-right: 38px;">
      <div class="nodata" *ngIf="Nodata">
        <span>No medicine found</span>
      </div>
      <div *ngIf="medicineHistArray.length !=0">
        <div class="table-responsive">
          <table id="tbl" cellpadding="5" cellspacing="0" border="0">
            <thead>
              <tr>
                <th class="pad_mar" style="width: 70%;">Medicine name</th>
                <th class="pad_mar" style="width: 20%;">Quantity</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let medicine of medicineHistArray; let i=index;">
                <td data-th="Medicine name" style="text-align: left;padding-left: 30px;">{{medicine.drug_name}}</td>
                <td class="pad_mar1">
                  <input class="ipcss " style="width: 70px;" [(ngModel)]="medicine.quantity" maxlength="5" matInput />
                </td>
                <td>{{medicine.short_form}}</td>
                <td data-th="Delete"><img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                    (click)="deleteDrug(medicine.drug_id,i)" class="editDelicon"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <hr class="hr"> -->
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button (click)="saveMedTrack()" id="add_btn" mat-button>
    <img class="addimgbtn_icd" src="../../../assets/ui_icons/buttons/save_button.svg" />
  </button>
  <button (click)="history()" id="add_btn" mat-button>
    <img class="addimgbtn_icd" src="../../../assets/ui_icons/buttons/history.svg" />
  </button>
</div>