<div *ngIf="apptFlag == 'min'">
    <table id="card_tbl" class="table table-hover table-dynamic">
        <tr>
            <th style="padding-left: 8px !important;">Patient name</th>
            <th>Age</th>
            <th style="text-align: center;">Status </th>
        </tr>
        <tr *ngFor="let list of appointment_list | slice: 0:2">
            <td style="font-size: 11px !important;text-align: left;">{{list.patient_name}}</td>
            <td style="font-size: 11px !important;">{{list.age}}</td>
            <td style="font-size: 11px !important;">{{list.status}}</td>
        </tr>
    </table>
</div>

<div *ngIf="apptFlag == 'max'">
    
    <div style="float: right;margin-bottom: 10px; position: relative;" >
        <img *ngIf="show_list" style="width: 18px !important; position: absolute; top: -38px; right: 39px;"
            src="../../../assets/ui_icons/video_icons.svg" (click)="vedio()"/>
    </div>
    <div class="splitter">
        <as-split direction="vertical">
            <as-split-area [size]="30">
                <div class="personaldetails">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <tr>
                                    <th class="tervys_heading">Patient name</th>
                                    <th class="tervys_heading">Age</th>
                                    <th class="tervys_heading">Contact </th>
                                    <th class="tervys_heading">Last visit </th>
                                    <th class="tervys_heading">Status </th>
                                    <th class="tervys_heading">Action</th>
                                </tr>
                                <tr *ngFor="let list of appointment_list">
                                    <td class="matlabel" style="text-align: left;">{{list.patient_name}}</td>
                                    <td class="matlabel">{{list.age}}</td>
                                    <td class="matlabel">{{list.contact}}</td>
                                    <td class="matlabel">{{list.lastvisit}}</td>
                                    <td class="matlabel">
                                        <div style="position: relative;top: 9px;">
                                            <img *ngIf="list.status == 'Completed'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/Completed_icon.svg" />
                                            <img *ngIf="list.status == 'Cancel'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/cancel_icon.svg" />
                                            <img *ngIf="list.status == 'Not visited'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/not_visited_icon.svg" />
                                            <img *ngIf="list.status == 'Confirmed'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/confirmed_icon.svg" />
                                            <img *ngIf="list.status == 'Open'" width="30px" height="32px"
                                                src="../../../assets/ui_icons/opened_icon.svg" />
                                            <p>{{list.status}}</p>
                                        </div>
                                    </td>
                                    <td><a (click)='Doctor_View(list)'><img src="../../../assets/ui_icons/search_icon_new.svg"
                                                class="editdelicon" /></a></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

            </as-split-area>
            <as-split-area [size]="70">
                <div class="container" *ngIf="show_list">
                    <div class="row">
                        <div class="col-6">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Personal
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Appointment for
                                                <input class="ipcss " matInput type="text" [(ngModel)]="rel_type"
                                                    [readonly]="isReadonly()" />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Patient id
                                                <input class="ipcss " matInput type="text" [(ngModel)]="pat_id"
                                                    [readonly]="isReadonly()" />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">First name
                                                <input class="ipcss " matInput type="text" [(ngModel)]="f_name"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Middle name
                                                <input class="ipcss " matInput type="text" [(ngModel)]="m_name"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Last name
                                                <input class="ipcss " matInput type="text" [(ngModel)]="l_name"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Age
                                                <input class="ipcss " matInput type="text" [(ngModel)]="age_txt"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Gender
                                                <input class="ipcss " matInput type="text" [(ngModel)]="gen_txt"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Blood group
                                                <input class="ipcss " matInput type="text" [(ngModel)]="bld_txt"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Father/Guardian
                                                <input class="ipcss " matInput type="text" [(ngModel)]="gur_txt"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Income
                                                <input class="ipcss " matInput type="text" [(ngModel)]="inc_txt"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Occupation
                                                <input class="ipcss " matInput type="text" [(ngModel)]="occ_txt"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Contact number
                                                <input class="ipcss " matInput type="text" [(ngModel)]="cont_txt"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-12">
                                            <mat-label class="matlabel">Patient address
                                                <textarea class="ipcss " matInput type="text" [(ngModel)]="pat_info"
                                                    [readonly]="isReadonly()"></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="cover_div" style="padding-bottom: 39px;">
                                <div class="header_lable">
                                    Hospital details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Symptoms
                                                <input type="text" class="ipcss" [(ngModel)]="sym_txt"
                                                    (keyup)="getSymptoms()" matInput [matAutocomplete]="auto4" />
                                                <mat-autocomplete #auto4="matAutocomplete">
                                                    <mat-option (click)="select_symptom(symptom)"
                                                        *ngFor="let symptom of symptomlist" value="{{symptom}}">
                                                        {{symptom}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                        </div>

                                        <div class="col-12 ">
                                            <mat-label class="matlabel">Appointment details
                                                <textarea class="ipcss " [(ngModel)]="app_info"
                                                    [readonly]="isReadonly()" style="height:70px !important"></textarea>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 ">
                                            <mat-label class="matlabel">Hospital details
                                                <textarea class="ipcss " [(ngModel)]="hosp_info"
                                                    [readonly]="isReadonly()" style="height:70px !important"></textarea>
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Payment mode
                                                <textarea class="ipcss " [(ngModel)]="pay_type"
                                                    [readonly]="isReadonly()"></textarea>
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Specialization
                                                <input class="ipcss " matInput type="text" [(ngModel)]="spl_txt"
                                                    [readonly]="isReadonly()" />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Referred by
                                                <input class="ipcss " matInput type="text" [(ngModel)]="refer_txt"
                                                    [readonly]="hasData" />
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Observation
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Temperature
                                                <input class="ipcss " matInput type="text" [(ngModel)]="temparature"
                                                    [readonly]="hasData" />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Blood pressure
                                                <input class="ipcss " matInput type="text" [(ngModel)]="txt_bp"
                                                    [readonly]="hasData" />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">SPO2
                                                <input class="ipcss " matInput type="text" [(ngModel)]="txt_spo"
                                                    [readonly]="hasData" />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Pulse
                                                <input class="ipcss " matInput type="text" [(ngModel)]="pulse_txt" />
                                            </mat-label>
                                        </div>
                                        <div [hidden]="ortho_flag" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">CVS
                                                <input class="ipcss " matInput type="text" [(ngModel)]="cvs_txt" />
                                            </mat-label>
                                        </div>
                                        <div [hidden]="ortho_flag" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">RS
                                                <input class="ipcss " matInput type="text" [(ngModel)]="rs_txt" />
                                            </mat-label>
                                        </div>
                                        <div [hidden]="ortho_flag" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Kidneys
                                                <input class="ipcss " matInput type="text" [(ngModel)]="kidney_txt" />
                                            </mat-label>
                                        </div>
                                        <div [hidden]="pulm_flag" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">PFT
                                                <input class="ipcss " matInput type="text" [(ngModel)]="pft_txt" />
                                            </mat-label>
                                        </div>
                                        <div class="" [hidden]="ortho_flag"
                                            class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Type of arthritis</mat-label>
                                            <select class="ipcss" [(ngModel)]="arthritis_type">
                                                <option value="Seropositive R.A">Seropositive R.A</option>
                                                <option value="Seronegative R.A">Seronegative R.A</option>
                                                <option value="Monoarticular R.A">Monoarticular R.A</option>
                                                <option value="Polyarticular R.A">Polyarticular R.A</option>
                                                <option value="Reactive-A">Reactive-A</option>
                                                <option value="Gout">Gout</option>
                                            </select>
                                        </div>
                                        <div [hidden]="ortho_flag" class="col-12">
                                            <mat-label class="matlabel">Deformities
                                                <text-area class="ipcss " matInput [(ngModel)]="deformities">
                                                </text-area>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Bmi
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Height
                                                <input class="ipcss " matInput (change)="bmi_data()"
                                                    maxlength="{{height_measure == 'cms' && (3) || (5)}}"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    [(ngModel)]="height_txt" [readonly]="hasData" />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Height measure</mat-label>
                                            <select class="ipcss" (change)="bmi_data()" [(ngModel)]="height_measure"
                                                [disabled]="hasData">
                                                <option value="cms">cms</option>
                                                <option value="inch">inch</option>
                                            </select>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Weight
                                                <input class="ipcss " matInput type="text" (change)="bmi_data()"
                                                    maxlength="5"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    [(ngModel)]="weight_txt" [readonly]="hasData" />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">Weight measure</mat-label>
                                            <select class="ipcss" (change)="bmi_data()" [(ngModel)]="weight_measure"
                                                [disabled]="hasData">
                                                <option value="kgs">kgs</option>
                                                <option value="lbs">lbs</option>
                                            </select>
                                        </div>

                                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-label class="matlabel">BMI
                                                <input class="ipcss " matInput type="text" [(ngModel)]="txt_bmi"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </as-split-area>
        </as-split>
    </div>
</div>
