<app-header></app-header>
<div class="banner-section-bg d-flex flex-column justify-content-center">
   <div class="px-5 ">
      <h1 class="banner-head"> Clinical Decision System</h1>
   </div>
</div>
<div class="pt-5 pb-5">
   <div class="container">
      <div class="row">
         <div class="col-12 col-md-3">
            <img src="../../../assets/images/solutions/clinical-decision.png" class="hosp-img">
         </div>
         <div class="col-12 col-md-7">
            <p class="para">Tervys clinical decision system utilizes artificial intelligence (AI) to support medical
               professionals in the clinical decision-making process. It is designed to help physicians, nurses, and
               other healthcare professionals make better-informed decisions when diagnosing and treating patients.
               <br>
               <br>
               This system processes large amounts of patient data such as medical records, lab results, imaging studies another clinical information  using advanced algorithms and machine learning to generate recommendations based on evidence-based guidelines, best practices and the latest medical research.
            </p>
         </div>
      </div>
   </div>
</div>
<div class="wcu-section pt-5 pb-5" id="wcuSection">
   <div class="container">
      <div class="row justify-content-center">
         <div class="text-center pt-3 pb-3">
            <h1 class="wcu-heading" style="font-size: 30px;">Key components of clinical decision system</h1>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/data-process.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Data processing</h2>
               <p class="wcu-para">The system uses algorithms to analyze patient data, identifying relevant patterns and relationships for the patient's condition.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/data-input.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Data input</h2>
               <p class="wcu-para">The system requires access to various patient data, including medical history, lab results, imaging studies, vital signs, and more, collected from electronic health records (EHRs), wearable devices, and other sources.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/decision-making.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3"> Decision support</h2>
               <p class="wcu-para">The system generates recommendations based on analyzed data, assisting clinicians in making more informed decisions regarding diagnosis, treatment, and patient management.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/workflow.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Clinical workflows integration</h2>
               <p class="wcu-para">The system must integrate seamlessly with existing clinical workflows, such as EHRs and order entry systems, ensuring that recommendations are readily accessible and can be implemented efficiently.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/loop.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Feedback loop</h2>
               <p class="wcu-para">The system should include a feedback loop to continuously refine and enhance the accuracy and effectiveness of its recommendations, involving outcome tracking and algorithm adjustments based on real-world data.</p>
            </div>
         </div>
         <!-- <div class="text-center pt-3 pb-3">
            <p class="para">Our AI based clinical decision systems enhance accuracy and consistency in clinical decision-making, reduce medical errors, and improve patient outcomes. However, challenges remain in implementation, including ensuring data privacy and security, addressing bias and fairness, and integrating with existing clinical workflows.</p>
         </div> -->
      </div>
   </div>
</div>

<app-footer></app-footer>