<div *ngIf="personalFlag == 'min'" class="block1_grid">
   <div *ngIf="!Dental_max_flag">
      <mat-label class="matlabel" [hidden]="name_hidden">{{name_label}}<br>
         <input type="text" class="ipcss widthappt" matInput [readonly]="client" [(ngModel)]="cardio_name" />
      </mat-label>
      <mat-label class="matlabel" [hidden]="age_hidden">{{age_label}}<br>
         <input class="ipcss widthappt" matInput type="text" required
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3" [(ngModel)]="cardio_age" />
      </mat-label>
      <mat-label class="matlabel" [hidden]="curoccup_hidden">{{current_label}}<br>
         <input class="ipcss widthappt" matInput type="text" maxlength="25" required [(ngModel)]="current_occupation" />
      </mat-label>
      <!-- <mat-label class="matlabel" [hidden]="Gender_hidden">{{gender_label}}<br>
      <input class="ipcss widthappt" matInput type="text" [readonly]="client" [(ngModel)]="cardio_gender" />
      </mat-label> -->
      <!-- <mat-label class="matlabel" [hidden]="height_hidden">{{height_label}}<br>
         <input style="width: 100%;" required class="ipcss" matInput type="text" (change)="calories_data()"
            maxlength="4" onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="height_txt" />
      </mat-label> -->
   </div>
   <mat-label class="matlabel" [hidden]="chief_hidden">Chief complaints<br>
      <textarea matInput *ngIf="!chief_flag" class="ipcss obsttxtareaheight widthappt"
         [(ngModel)]="chief_complients_type" (click)="chief_popup()"></textarea>
      <!-- <select *ngIf="chief_flag" class="ipcss widthappt" disableOptionCentering="true" multiple>
         <button mat-raised-button id="close_chief" (click)="close_chief()">close</button>
         <option color="primary" *ngFor="let list_array of chief_complients" value={{list_array.label}}>
            <mat-checkbox multiple [checked]="list_array.checked"
               (change)="chief_change(list_array.label,list_array.value,$event.checked)">{{list_array.label}}
            </mat-checkbox>&nbsp;
         </option>
      </select> -->
   </mat-label>
   <mat-label class="matlabel" [hidden]="present_hidden">History of present illness<br>
      <textarea matInput class="ipcss widthappt" maxlength="250" [(ngModel)]="present_ill"></textarea>
   </mat-label>
   <mat-label class="matlabel" [hidden]="duration_hidden">Duration of present illness<br>
      <textarea matInput class="ipcss widthappt" maxlength="50" [(ngModel)]="duration_present_ill"></textarea>
   </mat-label>
</div>

<div *ngIf="personalFlag == 'max'">
   <!-- <div>
      <div id="maxview"> -->
   <div *ngIf="!Dental_max_flag">
      <div *ngIf="cardio_flag">
         <div class="row">
            <div class="col-6">
               <div class="cover_div">
                  <div class="header_lable">
                     Personal
                  </div>
                  <div class="content_cover">
                     <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 " [hidden]="name_hidden">
                           <mat-label class="matlabel" [hidden]="name_hidden">{{name_label}}
                              <input type="text" class="ipcss ip" matInput [readonly]="client"
                                 [(ngModel)]="cardio_name"  (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-6  col-sm-12  col-md-6 col-lg-6 col-xl-6">
                           <mat-label class="matlabel" [hidden]="curoccup_hidden">{{current_label}}
                              <input class="widthappt ipcss" style="width: 100% !important;" matInput type="text"
                                 maxlength="25" required [(ngModel)]="current_occupation" [readonly]="client" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-6  col-sm-6  col-md-3 col-lg-3 col-xl-3 " [hidden]="age_hidden">
                           <mat-label class="matlabel" [hidden]="age_hidden">{{age_label}}
                              <input class="age ipcss" style="width: 100% !important;" matInput type="text" required
                                 onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                                 [(ngModel)]="cardio_age" [readonly]="client" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-6  col-sm-6  col-md-3 col-lg-2 col-xl-3 " [hidden]="Gender_hidden">
                           <mat-label class="matlabel">{{gender_label}}
                              <input style="width: 100% !important;" class="age ipcss" matInput type="text"
                                 [readonly]="client" [(ngModel)]="cardio_gender" (blur)="send_data()" />
                           </mat-label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-6">
               <div class="cover_div">
                  <div class="header_lable">
                     Dietary habits
                  </div>
                  <div class="content_cover">
                     <div class="row">
                        <div class="col-12">
                           <mat-label class="matlabel" [hidden]="dietary_hidden">{{dietary_label}}<br>
                              <textarea class=" ipcss" maxlength="250" required [(ngModel)]="diet_habits"
                                 [readonly]="client" style="height: 78px !important;" (blur)="send_data()"></textarea>
                           </mat-label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-12">
               <div class="cover_div">
                  <div class="header_lable">
                     Body type
                  </div>
                  <div class="content_cover">
                     <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="bnature_hidden">
                           <mat-label class="matlabel">{{bnature_label}}
                              <input class="ipcss ip" matInput type="text" [readonly]="client" [(ngModel)]="body_nature"
                                 [readonly]="client" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 " [hidden]="bodytype_hidden">
                           <mat-label class="matlabel">{{bodytype_label}}
                              <select class="ipcss age" [(ngModel)]="body_type" [disabled]="client" (change)="send_data()">
                                 <option value="{{btype.bodytype_name}}" *ngFor="let btype of bodyttype_array">
                                    {{btype.bodytype_name}}</option>
                              </select>
                           </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="anymed_hidden">
                           <mat-label class="matlabel">{{anymed_label}}
                              <select class="ipcss " required [(ngModel)]="anymed_txt" (change)="med_change() ; send_data()"
                                 [disabled]="client">
                                 <option value="Yes">Yes</option>
                                 <option selected value="No">No</option>
                              </select>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 " [hidden]="med_hidden">
                           <mat-label class="matlabel">{{med_label}}
                              <input class="ipcss " #matInput type="text" maxlength="100" [disabled]="med_flag" required
                                 [(ngModel)]="med_txt" [readonly]="client" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div *ngIf="Diabetes_flag">
         <div class="row">
            <div class="col-12">
               <div class="cover_div">
                  <div class="header_lable">
                     Personal
                  </div>
                  <div class="content_cover">
                     <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" [hidden]="name_hidden">
                           <mat-label class="matlabel" [hidden]="name_hidden">{{name_label}}
                              <input type="text" class="ipcss ip" matInput [readonly]="client"
                                 [(ngModel)]="cardio_name" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel" [hidden]="curoccup_hidden">{{current_label}}
                              <input class="widthappt ipcss" style="width: 100% !important;" matInput type="text"
                                 maxlength="25" required [(ngModel)]="current_occupation" [readonly]="client" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 " [hidden]="age_hidden">
                           <mat-label class="matlabel" [hidden]="age_hidden">{{age_label}}
                              <input class="age ipcss" style="width: 100% !important;" matInput type="text" required
                                 onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                                 [(ngModel)]="cardio_age" [readonly]="client" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 " [hidden]="Gender_hidden">
                           <mat-label class="matlabel">{{gender_label}}
                              <input style="width: 100% !important;" class="age ipcss" matInput type="text"
                                 [readonly]="client" [(ngModel)]="cardio_gender" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel" [hidden]="stress_hidden">{{stress_label}}<br>
                              <textarea class="ipcss widthappt" maxlength="250" required [(ngModel)]="stress_factortxt"
                                 [readonly]="client" style="height: 79px !important;" (blur)="send_data()"></textarea>
                           </mat-label>

                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" [hidden]="bnature_hidden">
                           <mat-label class="matlabel">{{bnature_label}}
                              <input class="ipcss ip" matInput type="text" [readonly]="client" [(ngModel)]="body_nature"
                                 [readonly]="client" (blur)="send_data()"/>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 " [hidden]="bodytype_hidden">
                           <mat-label class="matlabel">{{bodytype_label}}
                              <select class="ipcss age" [(ngModel)]="body_type" [disabled]="client" (change)="send_data()">
                                 <option value="{{btype.bodytype_name}}" *ngFor="let btype of bodyttype_array">
                                    {{btype.bodytype_name}}</option>
                              </select>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" [hidden]="anymed_hidden">
                           <mat-label class="matlabel">{{anymed_label}}
                              <select class="ipcss " required [(ngModel)]="anymed_txt" (change)="med_change(); send_data()"
                                 [disabled]="client">
                                 <option value="Yes">Yes</option>
                                 <option selected value="No">No</option>
                              </select>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" [hidden]="med_hidden ">
                           <mat-label class="matlabel">{{med_label}}
                              <input class="ipcss " #matInput type="text" maxlength="100" [disabled]="med_flag" required (blur)="send_data()"
                                 [(ngModel)]="med_txt" [readonly]="client" />
                           </mat-label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- <div class="col-6">
               <div class="cover_div">
                  <div class="header_lable">
                     BMI & Vitals
                  </div>
                  <div class="content_cover">
                     <div class="row">
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="height_hidden">
                           <mat-label class="matlabel">{{height_label}}<br>
                              <input style="width: 100%;" class="ipcss" matInput type="text" (change)="calories_data()"
                                 maxlength="4" onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                 [(ngModel)]="height_txt" [readonly]="client" />
                           </mat-label>

                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6 " [hidden]="height_hidden">
                           <mat-label class="matlabel">{{height_measurelabel}}<br>
                              <select class="ipcss" required (change)="calories_data()"
                                 [(ngModel)]="height_measure" [disabled]="client">
                                 <option value="cms">cms</option>
                                 <option value="inch">inch</option>
                              </select>
                           </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6 " [hidden]="weight_hidden">
                           <mat-label class="matlabel">{{weight_label}}<br>
                              <input class="ipcss" style="width: 100%;" matInput type="text" (change)="calories_data()"
                                 maxlength="5" onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                 [(ngModel)]="weight_txt" [readonly]="client" />
                           </mat-label>

                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="weight_hidden">
                           <mat-label class="matlabel">{{weight_measurelabel}}<br>
                              <select class="ipcss " (change)="calories_data()" required [(ngModel)]="weight_measure"
                                 [disabled]="client">
                                 <option value="kgs">kgs</option>
                                 <option value="lbs">lbs</option>
                              </select>
                           </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6 " [hidden]="bmi_hidden">
                           <mat-label class="matlabel">{{bmi_label}}
                              <input class="ipcss bmi_lenght" matInput type="text" [readonly]="client"
                                 [(ngModel)]="txt_bmi" [readonly]="client" />
                           </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6 " [hidden]="bmr_hidden">
                           <mat-label class="matlabel">{{bmr_label}}
                              <input class="ipcss" style="width: 100%;" matInput [readonly]="client"
                                 [(ngModel)]="txt_bmr" [readonly]="client" />
                           </mat-label>
                        </div>

                     </div>
                  </div>
               </div>

            </div> -->
            <div class="col-12" style="margin-top: 10px;">
               <div class="cover_div">
                  <div class="header_lable">
                     Main concerns
                  </div>
                  <div class="content_cover">
                     <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" [hidden]="maincon_hidden">
                           <mat-label class="matlabel "> Concerns<br>
                              <textarea #matInput class="ipcss" [(ngModel)]="Maincon_txt" required
                                 (click)="mainconcernpopup()"  onkeypress="return false;" (blur)="send_data()"></textarea>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel " [hidden]="pastmedical">Past medical history<br>
                              <textarea #matInput class="ipcss " [(ngModel)]="past_medical" (click)="medical_popup()"
                              readonly="true" (blur)="send_data()"></textarea>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel" [hidden]="religious_belielabe">{{Religious_label}}<br>
                              <textarea #matInput class="ipcss" [(ngModel)]="religious_belief" (click)="religious()"
                              readonly="true" (blur)="send_data()"></textarea>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-xl-3 col-lg-3">
                           <mat-label class="matlabel" [hidden]="dietary_hidden">{{dietary_label}}<br>
                              <textarea class=" ipcss" maxlength="250" required [(ngModel)]="diet_habits"
                                 [readonly]="client" (blur)="send_data()"></textarea>
                           </mat-label>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-12 my-auto" *ngIf="!cardio_flag">
            <a [hidden]="client" style="float: right;margin-top: 21px;"><img
                  src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo"
                  (click)="history()" /></a>
         </div>
      </div>
   </div>

   <div *ngIf="Dental_max_flag">
      <div class="row">
         <div class="col-6">
            <div class="cover_div" style="padding-bottom: 36px;">
               <div class="header_lable">
                  Concerns
               </div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-12" [hidden]="chief_hidden">
                        <mat-label class="matlabel">Chief complaints<br>
                           <textarea matInput class="ipcss " [(ngModel)]="chief_complients_type" (click)="chief_popup()"
                              [readonly]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                     </div>
                     <div class="col-12" [hidden]="present_hidden">
                        <mat-label class="matlabel">History of present illness<br>
                           <textarea matInput class="ipcss" maxlength="250" [(ngModel)]="present_ill"
                              [readonly]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                     </div>
                     <div class="col-12" [hidden]="duration_hidden">
                        <mat-label class="matlabel">Duration of present illness<br>
                           <textarea matInput class="ipcss" maxlength="50" [(ngModel)]="duration_present_ill"
                              [readonly]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                     </div>
                     <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="Dental_flag">
                        <mat-label class="matlabel">Treatment specialities<br>
                           <select class="ipcss" [(ngModel)]="treat_spl" disableOptionCentering
                              (change)="treat_spl_function(treat_spl)" [disabled]="client" (change)="send_data()">
                              <option Selected value="Select">Select</option>
                              <option *ngFor="let treat of treat_spl_array" value={{treat.den_spl_id}}>
                                 {{treat.description}}</option>
                           </select>
                        </mat-label>
                     </div>
                     <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="ref_hidden">
                        <mat-label class="matlabel">Referral/Assign<br>
                           <select class="ipcss" [(ngModel)]="ref_text" (change)="ref_data_chage() ; send_data()" [disabled]="client">
                              <option Selected value="Select">Select</option>
                              <option value="Assign">Assign</option>
                              <option value="Referral">Referral</option>
                           </select>
                        </mat-label>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div [hidden]="ref_dta" class="col-6">
            <div class="cover_div">
               <div class="header_lable">
                  Refer/Assign
               </div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-12">
                        <div class="row">
                           <div class=" col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 my-auto" style="text-align: center;">
                              <img id="refer_first" src="../../../assets/img/default.jpg" class="refer_profimage"
                                 draggable="false">
                           </div>
                           <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8 my-auto">
                              <div class="row">
                                 <div class="col-12 ">
                                    <mat-label class="matlabel">Speciality<br>
                                       <select class="ipcss " [(ngModel)]="spl_text" disableOptionCentering (change)="send_data()"
                                          [disabled]="client">
                                          <option *ngFor="let treat of treat_spl_array" value={{treat.description}}>
                                             {{treat.description}}
                                          </option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12 ">
                                    <mat-label class="matlabel">Doctor name
                                       <input type="text" class="ipcss " required id="country" [(ngModel)]="doc_name" (change)="send_data()"
                                          (keyup)="spl_search($event)" matInput [matAutocomplete]="auto" />
                                       <mat-autocomplete #auto="matAutocomplete">
                                          <mat-option id="optionfont" (click)="doc_name_select(item.doc_reg_id)"
                                             *ngFor="let item of doc_name_array" value="{{item}}">
                                             <span>{{item.first_name+"
                                                "}}</span><span
                                                *ngIf="item.middle_name != undefined">{{item.middle_name+"
                                                "}}</span><span>{{item.last_name+" "}}</span>
                                          </mat-option>
                                       </mat-autocomplete>
                                    </mat-label>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div [hidden]="all_data_hide" class="col-6">
            <div class="cover_div">
               <div class="header_lable">
                  History
               </div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-12">
                        <mat-label class="matlabel" [hidden]="med_allergy_hidden">Medical allergy<br>
                           <textarea matInput class="ipcss age" maxlength="500" [(ngModel)]="med_allergy"
                              [readonly]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                     </div>

                     <div class="col-12" [hidden]="past_dent_hidden">
                        <mat-label class="matlabel">Past dental history<br>
                           <textarea matInput class="ipcss age" maxlength="500" [(ngModel)]="past_den_hist"
                              [readonly]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                     </div>
                     <div class="col-12" [hidden]="tak_drug_hidden">
                        <mat-label class="matlabel">Taking any drugs<br>
                           <textarea matInput class="ipcss age" maxlength="50" [(ngModel)]="drug_history"
                              [readonly]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                     </div>

                     <div class="col-12" [hidden]="other_med_hidden">
                        <mat-label class="matlabel">Other medical history<br>
                           <textarea matInput class="ipcss age" maxlength="300" [(ngModel)]="other_med_hist"
                              [readonly]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div [hidden]="concern_headiing" class="col-6">
            <div class="cover_div">
               <div class="header_lable">
                  Brushing & Flossing
               </div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="brush_data_hidden">
                        <mat-label class="matlabel">Brushing type<br>
                           <select class="ipcss age" [(ngModel)]="brush_typ" (change)="brush_change_event() ; send_data()"
                              disableOptionCentering [disabled]="client">
                              <option *ngFor="let brush of brush_type_array" value={{brush.brush_id}}>
                                 {{brush.description}}
                              </option>
                           </select>
                        </mat-label>
                     </div>
                     <div class="col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3" [hidden]="brush_data_hidden">
                        <mat-label class="matlabel">Frequency<br>
                           <input type="text" class="ipcss age" matInput maxlength="1" disabled="{{brush_freq_disable}}"
                              [(ngModel)]="brush_freq" [disabled]="client" (blur)="send_data()"/>
                        </mat-label>
                     </div>
                     <div class="col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel" [hidden]="brush_data_hidden"><br>
                           <p>(Per day)</p>
                        </mat-label>
                     </div>
                     <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="brush_material_hidden">
                        <mat-label class="matlabel">Materials<br>
                           <select disableOptionCentering class="ipcss age" [(ngModel)]="brush_mate" (blur)="send_data()"
                              [disabled]="client">
                              <option disabled>Select</option>
                              <option *ngFor="let brush_mate of brush_mate_array" value={{brush_mate.brush_mat_id}}>
                                 {{brush_mate.description}}</option>
                           </select>
                        </mat-label>
                     </div>
                     <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="brush_change_hidden">
                        <mat-label class="matlabel">Changes<br>
                           <textarea matInput maxlength="75" matInput class="ipcss age" [(ngModel)]="brush_change"
                              [readonly]="client" (blur)="send_data()"></textarea>
                        </mat-label>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>