import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress';
import { Diagnosis_Helper } from 'src/app/Diagnosis_module/Diagnosis_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;

@Component({
  selector: 'app-receptionist-mgmtapplist',
  templateUrl: './receptionist-mgmtapplist.component.html',
  styleUrls: ['./receptionist-mgmtapplist.component.css']
})
export class ReceptionistMgmtapplistComponent implements OnInit {
  public getAppDate;
  public userInfo;
  public session;
  public doctorName;
  public doctorList = [];
  public appointmentDate;
  public currentDate;
  public doctor;
  public doctorId;
  public patientName;
  public displayPatientName;
  public tokenNo;
  public appTime;
  public splitAppTime;
  dtOptions: DataTables.Settings = {};
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public checklistid: any = [];
  public apptOpenCount: number = 0;
  public ipaddress;
  public appType;
  public doctorFlag: boolean;
  public diagLocationList = [];
  public datePick;
  public diagAppointmentList = [];
  public status;
  public clientName;
  public fromTime;
  public location;
  public sendDiagCenterId;
  public ynopt1 = "doctor";
  public appointmentList = [];
  public userId;
  public doctorArr: any;
  public showDoctor;
  public showDiag;
  public showDietician;
  public diagCentreId;
  public cancelResone;
  public showcancel:boolean = true;

  constructor(public toastr: ToastrService, public frontdeskservice: MenuViewService, public http: Http) {
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();
    this.showDoctor = this.userInfo.doctor;
    this.showDiag = this.userInfo.diagnosis;
    this.showDietician = this.userInfo.dietician;
    this.getCurrentDate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if (this.showDoctor == '1') {
      this.appType = "doctor";
      this.ynopt1 = "doctor";
      this.userTypeSelect("doctor");
    } else if (this.showDiag == '1') {
      this.appType = "Dignosis";
      this.ynopt1 = "diagnosis";
      this.userTypeSelect("Dignosis");
    } else if (this.showDietician == '1') {
      this.appType = "dietician";
      this.ynopt1 = "dietician";
      this.userTypeSelect("dietician");
    }
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            if (FrontDesk_Helper.getDate() == undefined)
              this.appointmentDate = this.currentDate;
            else
              this.appointmentDate = FrontDesk_Helper.getDate();
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeDoctor(e) {
    this.doctor = e.Doc_Name;
    if (e.type == "front-desk") {
      FrontDesk_Helper.setdoctor(undefined);
      FrontDesk_Helper.setdoctor(e);
      this.doctorId = e.Doc_ID;
      this.getDoctorList();
    } else if (e.type == "dietician") {
      this.userId = e.Doc_ID;
      FrontDesk_Helper.setDietician(undefined);
      FrontDesk_Helper.setDietician(e);
      this.getDieticianPatientList();
    }
  }

  getDoctorList() {
    this.appointmentList = [];
    this.apptOpenCount = 0;
    this.getAppDate = undefined;
    this.getAppDate = this.appointmentDate;
    this.showcancel=true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'appointment/getdocapp',
      JSON.stringify({
        date: this.getAppDate,
        doc_prov_id: this.doctorId,
        home_care: "0",
        country: ipaddress.country_code,
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        type: "front-desk",
        imei: this.userInfo.user_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj.appointments != null && obj.appointments.length != 0 && obj.appointments[0] != null) {
            if(this.currentDate == this.appointmentDate){
              this.showcancel = false;
            }
            
            for (var i = 0; i < obj.appointments.length; i++) {
              this.patientName = "";

              if (obj.appointments[i].middle_name != null && encrypt_decript.Decript(obj.appointments[i].middle_name) != "") {
                this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " +
                  encrypt_decript.Decript(obj.appointments[i].middle_name) + " " +
                  encrypt_decript.Decript(obj.appointments[i].last_name);
              } else {
                if (obj.appointments[i].last_name != undefined) {
                  this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " +
                    encrypt_decript.Decript(obj.appointments[i].last_name);
                } else {
                  this.patientName = encrypt_decript.Decript(obj.appointments[i].first_name);
                }
              }

              this.displayPatientName = "";
              this.tokenNo = "";
              if (obj.appointments[i].f_time == "1" && obj.appointments[i].token != null && obj.appointments[i].token != undefined && obj.appointments[i].token != "") {
                this.tokenNo = obj.appointments[i].token;
              }
              if (obj.appointments[i].f_time == "1" && obj.appointments[i].token != null && obj.appointments[i].token != undefined && obj.appointments[i].token != "") {
                this.displayPatientName = this.patientName;
              } else {
                this.appTime = "";
                this.splitAppTime = obj.appointments[i].f_time.split(":");

                if (encrypt_decript.Decript(obj.appointments[i].session).toString() == "Morning") {
                  if (this.splitAppTime[0] == "12") {
                    this.appTime = this.splitAppTime[0] + ":" + this.splitAppTime[1] + " PM";
                  } else {
                    this.appTime = this.splitAppTime[0] + ":" + this.splitAppTime[1] + " AM";
                  }
                } else {
                  this.appTime = this.splitAppTime[0] + ":" + this.splitAppTime[1] + " PM";
                }

                this.displayPatientName = this.patientName;
                this.tokenNo = this.appTime;
              }

              if (obj.appointments[i].session != undefined) {
                this.session = encrypt_decript.Decript(obj.appointments[i].session);
              }
              if (obj.appointments[i].status == '1' || obj.appointments[i].status == '2') {
                this.apptOpenCount = this.apptOpenCount + 1;
              }
              var status_txt;
              if (obj.appointments[i].status == "0") {
                status_txt = "Completed";
              } else if (obj.appointments[i].status == "1") {
                if (obj.appointments[i].date == this.currentDate || this.currentDate < obj.appointments[i].date) {
                  if (obj.appointments[i].f_time != "1") {
                    var get_time = new Date(obj.appointments[i].date + " " + this.currentDate);
                    var cur_time = new Date(this.currentDate + " " + this.currentDate);
                    if (get_time > cur_time) {
                      status_txt = "Not visited";
                    } else {
                      status_txt = "Open";
                    }
                  } else {
                    var get_time = new Date(obj.appointments[i].date);
                    var cur_time = new Date(this.currentDate);
                    if (get_time >= cur_time) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else {
                  if (obj.appointments[i].date > this.currentDate || obj.appointments[i].date == this.currentDate) {
                    status_txt = "Open";
                  } else {
                    status_txt = "Not visited";
                  }
                }
              } else if (obj.appointments[i].status == "2") {
                if (obj.appointments[i].date == this.currentDate || this.currentDate < obj.appointments[i].date) {
                  var get_time = new Date(obj.appointments[i].date + " " + this.currentDate);
                  var cur_time = new Date(this.currentDate + " " + this.currentDate);
                  status_txt = "Confirmed";
                } else {
                  if (obj.appointments[i].date > this.currentDate || obj.appointments[i].date == this.currentDate) {
                    status_txt = "Open";
                  } else {
                    status_txt = "Not visited";
                  }
                }
              }
              var package_based = obj.appointments[i].package_based == "false" ? "No" : "Yes";
              this.appointmentList.push({
                profile_image: ipaddress.Ip_with_img_address + obj.appointments[i].profile_image,
                token: this.tokenNo,
                patient_name: this.displayPatientName,
                doc_app_id: obj.appointments[i].doc_app_id,
                session: this.session,
                patient_id: obj.appointments[i].pat_id,
                contact: encrypt_decript.Decript(obj.appointments[i].mobile),
                dr_first_name: obj.appointments[i].doc_first_name,
                dr_middle_name: obj.appointments[i].doc_middle_name,
                dr_last_name: obj.appointments[i].doc_last_name,
                app_time: this.appTime,
                app_date: obj.appointments[i].date,
                status: obj.appointments[i].status,
                status_txt: status_txt,
                package: package_based,
              });
            }

          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getDoctors(type) {
    this.appointmentList = [];
    this.doctorList = [];
    var send_data = {
      country: "IN",
      reg_id: this.userInfo.user_id,
      imei: this.ipaddress,
      type: type,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyrep', JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              this.doctorName = "";
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
                
              } else {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: this.doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: type,
              });
            }

            this.doctorArr = [];
            if (this.ynopt1 == "doctor") {
              if (FrontDesk_Helper.getdoctor() != undefined && FrontDesk_Helper.getdoctor().type == "front-desk") {
                this.doctor = FrontDesk_Helper.getdoctor().Doc_Name;
                this.doctorId = FrontDesk_Helper.getdoctor().Doc_ID;
                this.doctorArr = FrontDesk_Helper.getdoctor();
                this.changeDoctor(FrontDesk_Helper.getdoctor());

              } else {
                this.doctor = this.doctorList[0].Doc_Name;
                this.userId = this.doctorList[0].Doc_ID;
                this.doctorArr = this.doctorList[0];
                this.changeDoctor(this.doctorArr);
              }

            } else if (this.ynopt1 == "dietician") {
              if (FrontDesk_Helper.getDietician() != undefined && FrontDesk_Helper.getDietician().type == "dietician") {
                this.doctor = FrontDesk_Helper.getDietician().Doc_Name;
                this.userId = FrontDesk_Helper.getDietician().Doc_ID;
                this.doctorArr = FrontDesk_Helper.getDietician();
                this.changeDoctor(FrontDesk_Helper.getDietician());

              } else {
                this.doctor = this.doctorList[0].Doc_Name;
                this.userId = this.doctorList[0].Doc_ID;
                this.doctorArr = this.doctorList[0];
                this.changeDoctor(this.doctorArr);
              }
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getDieticianPatientList() {
    this.appointmentList = [];
    this.apptOpenCount = 0;
    this.getAppDate = undefined;
    this.getAppDate = this.appointmentDate;
    this.showcancel = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/npapps',
      JSON.stringify({
        prov_id: this.userId,
        country: ipaddress.country_code.toString(),
        date: this.appointmentDate,
        type: "dietician",
      }),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(response["_body"]);
          if (data.appointments != null) {
            var appolist = data.appointments;

            if (appolist.length != "0") {
              if(this.currentDate == this.appointmentDate){
                this.showcancel = false;
              }
              for (var i = 0; i < appolist.length; i++) {
                if (appolist[i].status == "0") {
                  var status_txt = "Completed";
                } else if (appolist[i].status == "1") {
                  if (appolist[i].date == this.currentDate || this.currentDate < appolist[i].date) {
                    if (appolist[i].f_time != "1") {
                      var get_time = new Date(appolist[i].date + " " + this.currentDate);
                      var cur_time = new Date(this.currentDate + " " + this.currentDate);
                      status_txt = get_time > cur_time ? "Not visited" : "Open";
                    } else {

                      var get_time = new Date(appolist[i].date);
                      var cur_time = new Date(this.currentDate);
                      status_txt = (get_time > cur_time || get_time == cur_time) ? "Open" : "Not visited";
                    }
                  } else {
                    status_txt = (appolist[i].date > this.currentDate || appolist[i].date == this.currentDate) ? "Open" : "Not visited";
                  }
                } else if (appolist[i].status == "2") {
                  if (appolist[i].date == this.currentDate || this.currentDate < appolist[i].date) {
                    var get_time = new Date(appolist[i].date + " " + this.currentDate);
                    var cur_time = new Date(this.currentDate + " " + this.currentDate);
                    status_txt = "Confirmed";
                  } else {
                    status_txt = (appolist[i].date > this.currentDate || appolist[i].date == this.currentDate) ? "Open" : "Not visited";
                  }
                }

                if (appolist[i].token != null) {
                  var token_time_label = "Token no : " + appolist[i].token;

                } else {
                  var Temptimesplit = appolist[i].f_time;
                  var offTime = Temptimesplit.split(':');
                  offTime[0] = offTime[0] % 12;
                  var output = offTime.join(':');

                  var get_timeq = output.split(":");
                  if (get_timeq[0] == "0") {

                    output = "12" + ":" + get_timeq[1];
                  }
                  token_time_label = "Time : " + Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session));
                }

                var Patient_name = null;
                if (appolist[i].middle_name != undefined && appolist[i].middle_name != null) {
                  if (encrypt_decript.Decript(appolist[i].middle_name) != "") {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].middle_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  } else {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  }
                } else {
                  Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                }

                var sub_rel = appolist[i].sub_rel_id != null ? appolist[i].sub_rel_id : "0";
                var get_age = appolist[i].age != null ? appolist[i].age : "";
                var add_data = (appolist[i].address2 != null && appolist[i].address2 != "") ? appolist[i].address1 + " " + appolist[i].address2 : appolist[i].address1;
                var zipcode_data = (appolist[i].rel_zipcode != null) ? encrypt_decript.Decript(appolist[i].rel_zipcode) : appolist[i].zipcode;
                if (status_txt == 'Open' || status_txt == 'Confirmed') {
                  this.apptOpenCount = this.apptOpenCount + 1;
                }

                var mobile;
                if (appolist[i].mobile != null) {
                  mobile = encrypt_decript.Decript(appolist[i].mobile);
                }
                var gender = appolist[i].gender != undefined ? encrypt_decript.Decript(appolist[i].gender) : "";
                this.appointmentList.push({
                  index: i + 1,
                  profile_image: ipaddress.Ip_with_img_address + appolist[i].profile_image,
                  patient_id: appolist[i].patient_id,
                  patient_name: Patient_name,
                  token: token_time_label,
                  tokenNo: appolist[i].token,
                  time: Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session)),
                  session: encrypt_decript.Decript(appolist[i].session),
                  contact: mobile,
                  status_txt: status_txt,
                  status: appolist[i].status,
                  doc_app_id: appolist[i].app_id,
                  client_reg_id: appolist[i].client_reg_id,
                  relation_id: appolist[i].relation_id,
                  sub_rel: sub_rel,
                  rel_count: appolist[i].country_id,
                  rel_stat: appolist[i].state_id,
                  rel_city: appolist[i].city_id,
                  rel_loc: appolist[i].location_id,
                  rel_loc_desc: appolist[i].location,
                  loc_age: get_age,
                  gender_data: gender,
                  dob_data: appolist[i].dob,
                  life_style_id: appolist[i].life_style_id,
                  address: add_data,
                  state_desc: appolist[i].state_desc,
                  city_desc: appolist[i].city_desc,
                  country_desc: appolist[i].country_desc,
                  rel_zipcode: zipcode_data,
                  app_date: appolist[i].date,
                  dr_first_name: appolist[i].dr_first_name,
                  dr_middle_name: appolist[i].dr_middle_name,
                  dr_last_name: appolist[i].dr_last_name,
                  user_type: appolist[i].user_type,
                  visit_purpose: appolist[i].visit_purpose,
                  first_name: appolist[i].first_name,
                  middle_name: appolist[i].middle_name,
                  last_name: appolist[i].last_name,
                  relation: appolist[i].relation,
                  symptoms: appolist[i].symptoms,
                  hosp_name: appolist[i].hosp_name,
                  hosp_address1: appolist[i].hosp_address1,
                  hosp_address2: appolist[i].hosp_address2,
                  hosp_location: appolist[i].hosp_location,
                  hosp_city: appolist[i].hosp_city,
                  hosp_state: appolist[i].hosp_state,
                  hosp_zipcode: appolist[i].hosp_zipcode,
                  hosp_country: appolist[i].hosp_country,
                  diet_reg_id: appolist[i].diet_reg_id,
                  gender: appolist[i].gender,
                  salutation: appolist[i].salutation,
                });
              }
              console.log("this.appointmentList" + JSON.stringify(this.appointmentList));
            }
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  viewAppointment(list) {
    if (this.appType == "doctor") {
      var view_appointments = {
        doc_app_id: list.doc_app_id,
        session: list.session,
        Appointment_Date: list.app_date,
        doc_name: this.doctor,
      }
      FrontDesk_Helper.setreceptionistcreatepage(view_appointments);
      this.frontdeskservice.sendMessage("receptionistdoctordetailview");

    } else if (this.appType == "dietician") {
      var temo: any;
      if (list.user_type == "temp") {
        temo = {
          user_details: list,
          user_type: "temp"
        }

      } else {
        temo = {
          doc_app_id: list.doc_app_id,
          session: list.session,
          Appointment_Date: list.app_date,
          doc_name: this.doctor,
        }
      }
      FrontDesk_Helper.setreceptionistcreatepage(temo);
      this.frontdeskservice.sendMessage("dietappointmentsdetailed");
    }
  }

  create() {
    if (this.appType == "Dignosis") {
      var sendData = {
        center_id: this.userInfo.diag_center_id,
        flow: "frontdesk",
      }
      Diagnosis_Helper.setCreateDiagAppt(sendData);
      this.frontdeskservice.sendMessage("diaAppCreate");
    } else if (this.appType == "dietician") {
      FrontDesk_Helper.setreceptionistcreatepage(null)
      var dietview_appointments = {
        user_id: this.userInfo.user_id
      };
      FrontDesk_Helper.setreceptionistcreatepage(dietview_appointments);
      this.frontdeskservice.sendMessage("dietappointmentscreate");
    } else {
      FrontDesk_Helper.setreceptionistcreatepage(null)
      var view_appointments = ({
        doc_reg_id: this.doctorId
      })
      FrontDesk_Helper.setreceptionistcreatepage(view_appointments)
      this.frontdeskservice.sendMessage("appcreate");
    }
  }

  selectAppointmentDate(e) {
    this.appointmentDate = e;
    if (this.appType == "doctor") {
      FrontDesk_Helper.setDate(undefined);
      FrontDesk_Helper.setDate(this.appointmentDate);
      this.getDoctorList();
    } else if (this.appType == "dietician") {
      FrontDesk_Helper.setDate(undefined);
      FrontDesk_Helper.setDate(this.appointmentDate);
      this.getDieticianPatientList();
    } else {
      this.diagCentreId = this.userInfo.diag_centre_id
      this.getDiagAppointment();
    }
  }

  checkUncheckAll() {
    this.checklist = this.appointmentList;
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.length == this.apptOpenCount) {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          var token = "", time = "";
          if (this.checklist[i].tokenNo != null && this.checklist[i].tokenNo != "" && this.checklist[i].tokenNo != undefined) {
            token = this.checklist[i].tokenNo
          } else {
            time = this.checklist[i].time;
          }
          var usertype;
          if (this.checklist[i].user_type != undefined) {
            usertype = this.checklist[i].user_type;
          }
          this.checklistid.push({
            index: this.checklist[i].index,
            app_id: this.checklist[i].doc_app_id,
            session: this.checklist[i].session,
            app_date: this.checklist[i].app_date,
            app_time: time,
            first_name: this.checklist[i].dr_first_name,
            middle_name: this.checklist[i].dr_middle_name,
            last_name: this.checklist[i].dr_last_name,
            mobile: this.checklist[i].contact,
            token: token,
            user_type: usertype,
          });
          this.checklist[i].isSelected = this.masterSelected;
        }

      } else {
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].status == '1' || this.checklist[i].status == '2') {
            var token = "", time = "";
            if (this.checklist[i].tokenNo != null && this.checklist[i].tokenNo != "" && this.checklist[i].tokenNo != undefined) {
              token = this.checklist[i].tokenNo
            } else {
              time = this.checklist[i].time;
            }
            var usertype;
            if (this.checklist[i].user_type != undefined) {
              usertype = this.checklist[i].user_type;
            }
            this.checklistid.push({
              index: this.checklist[i].index,
              app_id: this.checklist[i].doc_app_id,
              session: this.checklist[i].session,
              app_date: this.checklist[i].app_date,
              app_time: time,
              first_name: this.checklist[i].dr_first_name,
              middle_name: this.checklist[i].dr_middle_name,
              last_name: this.checklist[i].dr_last_name,
              mobile: this.checklist[i].contact,
              token: token,
              user_type: usertype,
            });
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }

    } else {
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].status == '1' || this.checklist[i].status == '2') {
          var token = "", time = "";
          if (this.checklist[i].tokenNo != null && this.checklist[i].tokenNo != "" && this.checklist[i].tokenNo != undefined) {
            token = this.checklist[i].tokenNo
          } else {
            time = this.checklist[i].time;
          }
          var usertype;
          if (this.checklist[i].user_type != undefined) {
            usertype = this.checklist[i].user_type;
          }
          this.checklistid.push({
            index: this.checklist[i].index,
            app_id: this.checklist[i].doc_app_id,
            session: this.checklist[i].session,
            app_date: this.checklist[i].app_date,
            app_time: time,
            first_name: this.checklist[i].dr_first_name,
            middle_name: this.checklist[i].dr_middle_name,
            last_name: this.checklist[i].dr_last_name,
            mobile: this.checklist[i].contact,
            token: token,
            user_type: usertype,
          });
          this.checklist[i].isSelected = this.masterSelected;
        }
      }
    }
  }

  isAllSelected(list) {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var index = this.checklistid.map((o) => o.index).indexOf(list.index);
      //    var index = this.checklistid.findIndex( x => this.checklistid.index == list.index);
      console.log("index" + index);
      if (index != -1) {
        var index = this.checklistid.indexOf(list);
        this.checklistid.splice(index, 1);
      } else {
        var token = "", time = "";
        if (list.tokenNo != null && list.tokenNo != "" && list.tokenNo != undefined) {
          token = list.tokenNo
        } else {
          time = list.time;
        }
        var usertype;
        if (list.user_type != undefined) {
          usertype = list.user_type;
        }
        this.checklistid.push({
          index: list.index,
          app_id: list.doc_app_id,
          session: list.session,
          app_date: list.app_date,
          app_time: time,
          first_name: list.dr_first_name,
          middle_name: list.dr_middle_name,
          last_name: list.dr_last_name,
          mobile: list.contact,
          token: token,
          user_type: usertype,
        });
      }

    } else {
      var token = "", time = "";
      if (list.tokenNo != null && list.tokenNo != "" && list.tokenNo != undefined) {
        token = list.tokenNo
      } else {
        time = list.time;
      }
      var usertype;
      if (list.user_type != undefined) {
        usertype = list.user_type;
      }
      this.checklistid.push({
        index: list.index,
        app_id: list.doc_app_id,
        session: list.session,
        app_date: list.app_date,
        app_time: time,
        first_name: list.dr_first_name,
        middle_name: list.dr_middle_name,
        last_name: list.dr_last_name,
        mobile: list.contact,
        token: token,
        user_type: usertype,
      });
    }
    console.log("checklistid" + JSON.stringify(this.checklistid))
  }

  getCheckedItemList() {
    if (this.checklist.length == this.checklistid.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checklistid.length != 0) {
      this.toastr.success('Thank you...', ``);
    } else {
      this.checklistid = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  appointmentConfirm() {
    console.log("this.checklistid" + JSON.stringify(this.checklistid));
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: this.appType,
          doc_apps: this.checklistid,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
                if (this.appType == "doctor") {
                  this.getDoctorList();
                } else if (this.appType == "dietician") {
                  this.getDieticianPatientList()
                } else {
                  this.getDiagAppointment();
                }

              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.toastr.error(Message_data.sltApptToConfirm);
    }
  }

  clearAll() {
    this.checklist = this.appointmentList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  sendCancel() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var cancel = [];
      for (var i = 0; i < this.checklistid.length; i++) {
        cancel.push(this.checklistid[i].app_id);
      }

      var send_data: any;
      if (this.appType == "doctor") {
        send_data = {
          doc_cancel: cancel,
          home_care: "0",
          reason: this.cancelResone,
          type: "doctor",
        }
      } else if (this.appType == "dietician") {
        send_data = {
          diet_cancel: this.checklistid,
          home_care: "0",
          type: "dietician",
          reason: this.cancelResone
        }
      }
      console.log("send_data" + JSON.stringify(send_data));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify(send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                $("#staticBackdrop").modal("hide");
                this.toastr.success(Message_data.appcancel);
                this.ngOnInit();
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }

  cancel() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      $("#staticBackdrop").modal("show");
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }

  userTypeSelect(page) {
    this.appType = page
    if (this.appType == "doctor") {
      this.getDoctors('front-desk');
      this.doctorFlag = true;
    } else if (this.appType == "Dignosis") {
      this.getLocation();
      this.doctorFlag = false;
    } else {
      this.getDoctors("dietician");
      this.doctorFlag = true;
    }
  }

  getLocation() {
    this.diagLocationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'diagnosiscontroller/diagloc/',
      JSON.stringify({
        diag_name: this.userInfo.diag_centre_name,
        flag: "name",
        imei: Helper_Class.getIPAddress(),
        prov_id: this.userInfo.user_id,
        type: "diagnosis"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.diag_loc.length; i++) {
            this.diagLocationList.push({
              centre_id: obj.diag_loc[i].diag_centre_id,
              centre_name: obj.diag_loc[i].diag_centre_name,
              centre_location: obj.diag_loc[i].diag_loc,
              view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
            });
          }
          for (var i = 0; i < this.diagLocationList.length; i++) {
            this.sendDiagCenterId = this.diagLocationList[i].centre_id;
            Diagnosis_Helper.setDiag_centre_id(this.sendDiagCenterId);
          }
          this.diagCentreId = this.diagLocationList[0].centre_id;
          this.getDiagAppointment();
          this.location = this.diagLocationList[0].view_data
        },
        error => { });
  }

  getDiagAppointment() {
    this.diagAppointmentList = [];
    this.getAppDate = undefined;
    this.getAppDate = this.datePick;
    this.datePick = this.appointmentDate;
    this.showcancel=true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/diagapps/',
      JSON.stringify({
        diag_center_id: this.diagCentreId,
        date: this.appointmentDate,
        imei: Helper_Class.getIPAddress(),
        prov_id: this.userInfo.user_id,
        type: "diagnosis"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              if(this.currentDate == this.appointmentDate){
                this.showcancel = false;
              }
              this.status = "";
              this.clientName = "";
              this.fromTime = "";
              var sessiontext = "";

              if (obj.appointments[i].middle_name != null && encrypt_decript.Decript(obj.appointments[i].middle_name) != "") {
                this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              } else {
                if (obj.appointments[i].first_name != "" && obj.appointments[i].first_name != undefined) {
                  if (obj.appointments[i].last_name != "" && obj.appointments[i].last_name != undefined) {
                    this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                  } else {
                    this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name);
                  }
                }
              }

              if (obj.appointments[i].appointment_date != null) {
                var last_visit = obj.appointments[i].appointment_date;
                var gettmp = last_visit.split("-");
                var Date_value = gettmp[2] + "-" + gettmp[1] + "-" + gettmp[0];
              }
              if (obj.appointments[i].status == "1") {
                var get_time = new Date(obj.appointments[i].appointment_date + " " + this.currentDate);
                var cur_time = new Date((this.datePick).toString());

                if (get_time > cur_time)
                  this.status = "Open";
                else if (get_time < cur_time)
                  this.status = " Open";
                else
                  this.status = "Open";
              }
              if (obj.appointments[i].from_time != null) {
                this.fromTime = Time_Formate(obj.appointments[i].from_time);
                this.status = "Time alloted";
              }
              if (obj.appointments[i].status == "2") {
                this.status = "Sample collected";
              }
              if (obj.appointments[i].status == "3") {
                this.status = "Report yet to be finalized";
              }
              if (obj.appointments[i].status == "0") {
                this.status = "Closed";
              }
              if (obj.appointments[i].is_canclled == "1") {
                this.status = "Cancelled";
              }
              if (obj.appointments[i].session != undefined) {
                sessiontext = obj.appointments[i].session;
              }
              this.diagAppointmentList.push({
                App_id: obj.appointments[i].diag_appointment_id,
                App_date: Date_value,
                Relation: obj.appointments[i].relation,
                Clnt_Name: this.clientName,
                Time: this.fromTime,
                Session: sessiontext,
                status: this.status
              });
            }
          }
        },
        error => { });
  }

  changeLocation(data) {
    if (this.diagLocationList != undefined) {
      for (var i = 0; i < this.diagLocationList.length; i++) {
        if (this.diagLocationList[i].view_data == data) {
          this.diagCentreId = this.diagLocationList[i].centre_id;
          this.getDiagAppointment();
          this.sendDiagCenterId = this.diagLocationList[i].centre_id;
        }
      }
    }
  }

  viewDiagAppointment(App_id, status) {
    var type_user = {
      user_id: this.userInfo.user_id,
      user_type: "frontDesk",
      diag_centre_id: Helper_Class.getInfo().diag_centre_name,
      diag_id: App_id,
    }
    var set = {
      diag_id: App_id,
      status_txt: status,
      flow: "frontdesk"
    }
    Helper_Class.setdiagnosisappview(set);
    Diagnosis_Helper.setUserID_Type(type_user);
    this.frontdeskservice.sendMessage("diaAppDetail");
  }

  changeType(id, value) {
    if (value == "doctor") {
      this.ynopt1 = "doctor";
      this.userTypeSelect('doctor')
    } else if (value == "diagnosis") {
      this.ynopt1 = 'diagnosis';
      this.userTypeSelect('Dignosis')
    } else if (value == "dietician") {
      this.ynopt1 = "dietician";
      this.userTypeSelect('dietician')
    }
  }

  compareById(object1: any, object2: any) {
    return object1 && object2 && object1.Doc_ID === object2.Doc_ID;
  }

  editAppointment(diagid, status) {
    var sendData = {
      center_id: this.userInfo.diag_center_id,
      flow: "frontdesk",
    }
    Diagnosis_Helper.setCreateDiagAppt(sendData);

    var diagnosis = {
      diag_id: diagid,
      status_txt: status,
      centre_id: this.userInfo.diag_center_id,
    }
    Helper_Class.setdiagnosisappview(diagnosis);
    this.frontdeskservice.sendMessage("appedit");
  }
}
