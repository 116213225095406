import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Doc_Helper } from '../Doc_Helper'
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

declare var $: any;

@Component({
  selector: 'app-bill-create',
  templateUrl: './bill-create.component.html',
  styleUrls: ['./bill-create.component.css']
})
export class BillCreateComponent implements OnInit {
  public save_billcreate: any;
  public bill_palnche;
  public appoint_txt;
  public charge_type: string
  public charge_id: string;
  public amount_txt;
  public final_amount;
  public inGST: boolean;
  public msgflag: boolean = false;
  public profile_image: string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;
  public notifications;
  public notifyflag: boolean = false;

  public concession_txt;
  public con_txt;
  public session: string;
  public amount_aftcon;
  public table_div: boolean;
  public final_div: boolean;
  public gst_amount_txt;
  public Advance_bill;
  public CurrentDatetime;
  public Appoint_Date;
  public appointment_list: any = [];
  public Docidname;
  public Bill_appoint_id;
  public hospital_name;
  public middle_appoin: string;
  public billden_plan: any = [];
  public gst_retrive_list: any = [];
  public billaddlist: any = [];

  public gst_data_list: any = [];
  public chk_treat_arr: any = [];
  public charge_name;
  public serviceType = [];
  public estimate_id: any = [];
  public paytypelist: any = [];
  public insurerlist: any = [];
  public paymentsarray:any=[];
  public pay_type: string;
  public insurer_id: string;
  public insur_flag: boolean;
  public remaing_billbal;
  public addvancetxt;
  public totalcolletxt;
  public cent_gst;
  public sta_gst;
  public doc_client_reg_id;
  public doc_relation_id;
  public doc_sub_rel_id;
  public Charge_id;
  public charge_desc;
  public hptl_clinic_id: string;
  public header_footer_flag: boolean;
  public addbtn_flag: boolean;
  public bill_date;
  public current_amount;
  public billdate;
  public diet_followtable: boolean;
  public Gsthidden: boolean = false;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public doctor_profile_img!: string | null;

  public currentDate;
  public userinfo;
  public tempRoundoff = "0.00";
  //mdbTable
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public user_id;

  //Get apointment details
  public patient_name: string;
  public prev_bill_id;
  public balance_show: boolean;
  public amount_collected: boolean;
  public previous_balshow: boolean;
  public balance_data;
  public total_collected;
  public round_off;
  public paid_bill;
  public sign;
  public tran_flag:boolean;
  public Transaction_id;
  public card_flag: boolean;
  public cardNumber;
  public cardHolderName;
  public cheque_flag:boolean;
  public bankName;
  public quantity = 1;
  public show_save_btn:boolean = true;
  public appFlow;
  public flowFrom;
  public bill_qty_flag:boolean=false;
  public bill_cons_flag:boolean=false;
  public paytypreq:boolean=false;
  public date_time_flag:boolean=false;
  private rowid:number=0;
  public payType1;
  public amountval:number;

  constructor(public doctorservice: MenuViewService, public toastr: ToastrService, public http: Http, public router: Router, 
    public messageservice: MenuViewService,public masterData:MasterHelperService,public dietService:MenuViewService) {
    this.addbtn_flag = true;
    this.insur_flag = true;
    this.tran_flag = true;
    this.card_flag = true;
    this.cheque_flag = true;
    this.billdate = new FormControl(new Date());
    this.final_amount = "0";
    this.gst_amount_txt = "0";
    this.Advance_bill = "0.00";
    this.paid_bill = "0.00";
    this.round_off = "0.00";
    this.concession_txt = 0;
    this.inGST = false;
    //this.Get_current_date();
    this.gst_retrive_list = [];
    this.billaddlist = [];
    this.pay_type = "Cash";
    this.estimate_id = [];
    this.previous_balshow = true;
    this.balance_show = true;
  }

  ngOnInit(): void {
    this.show_save_btn = true;
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.flowFrom = "doctor";
    this.dtOptions = {
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3, 4, 5] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    var hospitaldet = Helper_Class.getHospital();
    this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    this.doctor_profile_img = this.userinfo.profile_image;
    this.first_name = Helper_Class.getProfilename();
    this.appFlow = Helper_Class.getBillingFlow();

    if(this.userinfo.hospitals[0].bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }
    if(this.userinfo.hospitals[0].bill_date_time_edit == "1"){
      this.date_time_flag = false;
    } else {
      this.date_time_flag = true;
    }
    if(Helper_Class.getInfo().hospitals[0].bill_cons_flag == 0){
      this.bill_cons_flag =true;
    } else {
      this.bill_cons_flag = false;
    }

    if(Helper_Class.getInfo().hospitals[0].bill_qty_flag == 0){
      this.bill_qty_flag =true;
    } else {
      this.bill_qty_flag = false;
    }

    if(this.appFlow != undefined){
      this.user_id = this.appFlow.user_id;
      this.flowFrom = "dietician";
    }
    this.amount_change();
    this.getPaymentType();
    this.Get_Gst_Data();
    this.hospital_name = hospitaldet[0].hptl_name;
    this.bill_date = this.Appoint_Date;
    if (this.CurrentDatetime == null) {
      this.Get_current_date();
    } else {
      this.getAppointments(this.billdate);
    }
    this.getInsurers();
    this.Get_Bill_Type();
  }

  backClicked() {
    if(this.appFlow != undefined){
      this.dietService.sendMessage("billList");
    }else{
      this.messageservice.sendMessage("billing");
    }
  }

  //Bill add table
  Bill_add_click() {
    this.diet_followtable = true;
    this.addbtn_flag = true;

    if (this.appoint_txt == undefined) {
      this.toastr.error(Message_data.sltAppId);

    } else if (this.charge_type == undefined) {
      this.toastr.error(Message_data.sltBillType);

    } else if (this.quantity == undefined) {
      this.toastr.error(Message_data.enterQty);

    }else if (this.amount_txt == undefined || this.amount_txt == '') {
      this.toastr.error(Message_data.enterAmt);

    } else {
      if (this.final_amount == "0") {
        this.inGST == false;
      }
      this.table_div = true;
      this.final_div = true;

      var flag = true;

      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].biill_type == this.charge_type && this.billaddlist[i].paytype == this.pay_type) {
          flag = false;
          break;
        }
      }

      if (flag == true) {
        if (this.charge_name.length != 0) {
          for (var i = 0; i < this.charge_name.length; i++) {
            if (this.charge_type == this.charge_name[i].description) {
              this.charge_id = this.charge_name[i].charge_id;
            }
          }
        }
        var paydesc;
        if (this.paytypelist.length != 0) {
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.pay_type == this.paytypelist[i].pay_id) {
              paydesc = this.paytypelist[i].pay_desc;
            }
          }
        }

        this.con_txt = this.concession_txt == "" ? 0 : this.concession_txt;
        this.rowid=this.rowid+1;
       
        if(this.paytypreq == true){
          this.billaddlist.push({
            sno:this.rowid,
            biill_type: this.charge_type,
            amount_txt: parseFloat(this.amount_txt).toFixed(2),
            concession: parseFloat(this.con_txt).toFixed(2),
            fee: parseFloat(this.amount_aftcon).toFixed(2),
            charge_type: this.charge_id,
            insurer: this.insurer_id,
            quantity:this.quantity,
          });

        } else {
          this.billaddlist.push({
            sno:this.rowid,
            biill_type: this.charge_type,
            amount_txt: parseFloat(this.amount_txt).toFixed(2),
            concession: parseFloat(this.con_txt).toFixed(2),
            fee: parseFloat(this.amount_aftcon).toFixed(2),
            charge_type: this.charge_id,
            pay_type: paydesc,
            paytype: this.pay_type,
            insurer: this.insurer_id,
            quantity:this.quantity,
          });
        }
        this.charge_type=''
        this.amount_txt = '';
        this.concession_txt = 0;
        this.amount_aftcon = '';
        this.quantity = 1;
      }

      if (this.final_amount == "0") {
        this.inGST == false;
      }

      this.Calculate_gst("0", "0");
    }
  }
  //Delete bill
  Delete_bill(type, amount) {
    if (this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].biill_type == type && this.billaddlist[i].amount_txt == amount) {
          this.billaddlist.splice(i, 1);
          this.Calculate_gst("0", "0");
          break;
        }
      }
    }
    this.rowid=0;
    for (var i = 0; i < this.billaddlist.length; i++) {
      if (this.billaddlist[i].status != '2') {
        this.rowid +=1;
        this.billaddlist[i].sno=this.rowid;
      }
    }
    if (this.billaddlist.length == 0) {
      this.table_div = false;
      this.final_div = false;
    } else {
      this.table_div = true;
    }

    if (this.final_amount == "0") {
      this.inGST == false;
    }

    this.Calculate_gst("0", "0");
  }

  //Get bill type
  Get_Bill_Type() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      JSON.stringify({
        hptl_clinic_id: this.hptl_clinic_id,
        provider: this.flowFrom,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          this.charge_name = obj.charges;
          this.amount_txt = this.charge_name[0].amount;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  //Get payment type
  async getPaymentType() {
    if(Master_Helper.getMasterPaymentType() == undefined){
      await this.masterData.getPaymentType();
    }
    var obj = Master_Helper.getMasterPaymentType();
    if(obj != undefined){
      this.paytypelist = obj.payments;
      this.pay_type = this.paytypelist[0].pay_id;
    }
  }

  pay_change() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insur_flag = true;
    this.tran_flag = true;
    this.card_flag = true;
    this.cheque_flag = true;
    if (this.pay_type == "3") {
      this.insur_flag = false;
    } else if(this.pay_type == "4"){
      this.tran_flag = false;
    } else if(this.pay_type == "2"){
      this.tran_flag = false;
      this.card_flag = false;
    }else if(this.pay_type == "5"){
      this.cheque_flag = false;
    }else {
      this.insur_flag = true;
    }
  }

  //Get payment type
  async getInsurers() {
    if(Master_Helper.getMasterInsurers() == undefined){
      await this.masterData.getInsurers()
    }
    var obj = Master_Helper.getMasterInsurers();
    if(obj != undefined)
      this.insurerlist = obj.insurers;
  }

  charge_change(charge) {
    this.charge_type = charge;
    this.serviceType = [];
    for (var i = 0; i < this.charge_name.length; i++) {
      if (this.charge_name[i].description == charge) {
        this.amount_txt = this.charge_name[i].amount;
        this.amount_aftcon = this.charge_name[i].amount;
      }
    }
    this.addbtn_flag = false;
  }

  getServiceType(serviceType){
    this.serviceType = [];
    if(serviceType.length >= 3){
      if(this.charge_name.length != 0){
        if(this.charge_name.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()))){
          var chg = this.charge_name.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()));
          for(var i= 0;i < chg.length; i++){
            this.serviceType.push(chg[i].description);
          }
        }else{
          this.serviceType = [];
        }
      }
    }else{

    }
  }

  getAppointments(billdate) {
    this.CurrentDatetime = billdate;
    this.appointment_list = [];
    var headers = new Headers();
    var send_data = JSON.stringify({
      hptl_clinic_id: this.hptl_clinic_id,
      doc_reg_id : this.user_id,
      date: billdate,
      type: this.flowFrom
    });
    
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getapp', send_data,

      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.appointment != null) {
            for (var i = 0; i < obj.appointment.length; i++) {
              if (obj.appointment[i].middle_name != undefined) {
                this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name);
              } else {
                this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name);
              }

              var sessiondata = null;
              if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
              }

              this.appointment_list.push({
                doc_app_id: obj.appointment[i].doc_app_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                sub_rel_id: obj.appointment[i].sub_rel_id,

                first_name: obj.appointment[i].first_name,
                middle_name: obj.appointment[i].middle_name,
                last_name: obj.appointment[i].last_name,
                hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                session: sessiondata,
                docid_name: this.Docidname,
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  Appointmnet_change(e) {
    this.appoint_txt = e;
    this.hospital_name = "";
    this.patient_name = "";
    this.billden_plan = [];
    for (var i = 0; i < this.appointment_list.length; i++) {
      if (this.appointment_list[i].doc_app_id == this.appoint_txt) {
        this.doc_sub_rel_id = this.appointment_list[i].sub_rel_id;
      }
    }
    this.Get_Bill_Type();
    this.doc_sub_rel_id = Doc_Helper.getSub_id()
    
    if (this.doc_sub_rel_id != null || this.doc_sub_rel_id != undefined || this.doc_sub_rel_id != "") {
      var send_pass = JSON.stringify({
        appointment_id: this.appoint_txt,
        doc_reg_id: this.user_id,
        sub_rel_id: this.doc_sub_rel_id,
        type: this.flowFrom
      });
    }else {
      var send_pass = JSON.stringify({
        appointment_id: this.appoint_txt,
        doc_reg_id: this.user_id,
        type: this.flowFrom
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getappdet', send_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj.first_name != undefined) {
            var saldata;
            if (obj.salutation != undefined) {
              saldata = obj.salutation;
            } else {
              saldata = "";
            }

            this.hospital_name = obj.hosp_name;
            if (obj.middle_name != null && obj.middle_name != "" && obj.middle_name != undefined) {
              this.middle_appoin = encrypt_decript.Decript(obj.middle_name)
            }

            if (obj.middle_name != undefined && obj.middle_name != null && obj.middle_name != "") {
              this.patient_name = saldata + " " + encrypt_decript.Decript(obj.first_name) + " " + this.middle_appoin + " " + encrypt_decript.Decript(obj.last_name);
            } else {
              this.patient_name = saldata + " " + encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }

            if (obj.phases != null) {
              var rowcount = 1;
              for (var i = 0; i < obj.phases.length; i++) {
                this.billden_plan.push({
                  treatment_desc: obj.phases[i].treatment_desc,
                  estimate: obj.phases[i].estimate,
                  den_treatment_plan_id: obj.phases[i].den_treatment_plan_id,
                  rowid: rowcount,
                  checked: false,
                });
                rowcount++;
              }
            }

            if (obj.advance != null) {
              this.Advance_bill = parseFloat(obj.advance).toFixed(2);
            }

            if (obj.prev_bill_id != null) {
              this.prev_bill_id = obj.prev_bill_id;
            }

            if (obj.balance != "0") {
              this.amount_collected = true;
              this.balance_show = false;
              this.previous_balshow = false;
              this.balance_data = parseFloat(obj.balance).toFixed();
              this.final_amount = parseFloat(obj.balance).toFixed();
              this.total_collected = parseFloat(obj.balance).toFixed();
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  amount_change() {
    var perce !: number | null;
    perce = this.concession_txt / 100;
    var amt = this.amount_txt;
    var amt_cons = this.amount_aftcon;
    if(this.quantity > 1){
      amt = amt * this.quantity;
    }
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amount_aftcon = Math.round(tot);
  }

  //Get gst
  async Get_Gst_Data() {
    if(Master_Helper.getMasterGSTData() == undefined){
      await this.masterData.getGstData();
    }
    var obj = Master_Helper.getMasterGSTData();
    if(obj != undefined)
      this.gst_retrive_list = obj.taxes;
  }

  // Include GST
  include_gst_click(check) {
    this.Calculate_gst("0", "0");
  }

  Calculate_gst(palnid, planmodal) {
    //this.Gsthidden=true;
    this.final_amount = "0";
    this.gst_amount_txt = "0";
    this.total_collected = "0";
    this.current_amount = "0";
    this.gst_data_list = [];

    if (this.billden_plan.length != 0) {
      for (var i = 0; i < this.billden_plan.length; i++) {
        for (var j = 0; j < this.estimate_id.length; j++) {
          if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id) {
            this.current_amount = (parseFloat(this.current_amount) + parseFloat(this.billden_plan[i].estimate)).toFixed(2);
            this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.billden_plan[i].estimate)).toFixed(2);
            this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.billden_plan[i].estimate)).toFixed(2);

            if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.total_collected = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
              this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
            }

            if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.remaing_billbal = (parseFloat(this.Advance_bill) - parseFloat(this.final_amount)).toFixed(2);
            }

            this.amount_collected = true;

            if (this.total_collected == "0") {
              this.amount_collected = false;
            }

            if (this.remaing_billbal == "0") {
              this.balance_show = true;
            }
            break;
          }
        }
      }
    }

    for (var i = 0; i < this.billaddlist.length; i++) {
      this.current_amount = (parseFloat(this.current_amount) + parseFloat(this.billaddlist[i].fee)).toFixed(2);
      this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.billaddlist[i].fee)).toFixed(2);
      this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.billaddlist[i].fee)).toFixed(2);

      if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
        this.balance_show = false;
        this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
      }

      if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
        this.balance_show = false;
        this.remaing_billbal = (parseFloat(this.Advance_bill) - parseFloat(this.final_amount)).toFixed(2);
        this.total_collected = "0";
      }

      if (this.total_collected == "0") {
        this.amount_collected = false;
      }

      if (this.remaing_billbal == "0") {
        this.balance_show = true;
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.gst_data_list.push({
          Gst_Descrip: this.gst_retrive_list[i].tax_desc + " " + this.gst_retrive_list[i].tax,
          Gst_amount: (parseFloat(this.gst_amount_txt) * (parseFloat(this.gst_retrive_list[i].tax) / 100)).toFixed(2)
        });
        this.final_amount = (parseFloat(this.final_amount) + (parseFloat(this.gst_amount_txt) * (parseFloat(this.gst_retrive_list[i].tax) / 100)));
      }
      this.final_amount = parseFloat(this.final_amount).toFixed(2);
    }

    if (this.balance_data != undefined) {
      this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.balance_data)).toFixed(2);
    }

    //amount to be collected
    this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);

    if (this.estimate_id.length == 0 && this.billaddlist.length == 0) {
      this.inGST = false;
      this.gst_data_list = [];
      this.Advance_bill = "0"
      this.final_amount = "0";
      this.gst_amount_txt = 0;
      this.total_collected = "0";
      this.remaing_billbal = "0";
    }

    if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
      this.remaing_billbal = "0";
    }
    this.advance_change();
  }

 
  //add the bill details
  advance_change() {
    this.Advance_bill = this.Advance_bill == "" ? "0.00" : this.Advance_bill;
    this.remaing_billbal = ((parseFloat(this.final_amount) - parseFloat(this.round_off)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
  }

  addRound(data) {
    this.sign = data;
    this.round_off = this.round_off == "" ? "0.00" : this.round_off;
    if (data == "puls") {
      this.remaing_billbal = ((parseFloat(this.final_amount) + parseFloat(this.round_off)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
    } else {
      this.remaing_billbal = ((parseFloat(this.final_amount) - parseFloat(this.round_off)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
    }
    this.tempRoundoff = this.round_off;
  }

  paid_change() {
    this.paid_bill = this.paid_bill == "" ? "0.00" : this.paid_bill;
    this.remaing_billbal = ((parseFloat(this.final_amount) - parseFloat(this.round_off)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
  }

  //Bill click
  bill_click(planid, planmodal, rowid) {
    if (this.estimate_id.length != 0) {
      if (this.estimate_id.indexOf(planid) > -1) {
        for (var i = 0; i < this.estimate_id.length; i++) {
          if (this.estimate_id[i] == planid) {
            this.estimate_id.splice(i, 1);
            this.Calculate_gst("0", "0");
          }
        }
      } else {
        this.estimate_id.push(planid)
        this.Calculate_gst("0", "0");
      }
    } else {
      this.estimate_id.push(planid);
      this.Calculate_gst("0", "0");
    }

    for (var i = 0; i < this.billden_plan.length; i++) {
      for (var j = 0; j < this.estimate_id.length; j++) {
        if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id) {
          this.billden_plan[i].checked = true;
        }
      }
    }
  }

  //Bill save
  Bill_save() {
    var flag = true;
    var paytype;
    var instype;

    if (this.billaddlist.length == 0 && this.estimate_id.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    } else {
      for (var i = 0; i < this.billaddlist.length; i++) {
        paytype = this.billaddlist[i].paytype;
        instype = this.billaddlist[i].insurer;
      }
    }

    if (flag == true) {
      this.show_save_btn = false;
      if (this.appointment_list.length != 0) {
        for (var i = 0; i < this.appointment_list.length; i++) {
          if (this.appoint_txt == this.appointment_list[i].doc_app_id) {
            this.doc_client_reg_id = this.appointment_list[i].client_reg_id;
            this.doc_relation_id = this.appointment_list[i].relation_id;
            if (this.appointment_list[i].sub_rel_id != undefined) {
              this.doc_sub_rel_id = this.appointment_list[i].sub_rel_id;
            }
          }
        }
      }

      this.addvancetxt = this.Advance_bill != undefined ? this.Advance_bill : "0";
      this.totalcolletxt = this.total_collected != undefined ? this.total_collected : "0";

      if (this.inGST == true && this.gst_retrive_list.length != 0) {
        this.cent_gst = this.gst_retrive_list[0].tax;
        this.sta_gst = this.gst_retrive_list[1].tax;
      }

      if (this.estimate_id.length != 0) {
        var estimateid = this.estimate_id
      }

      var paid_flag;
      if( this.final_amount == this.remaing_billbal ){
        paid_flag = "Un paid";
      }else if (this.final_amount != "0.00" && (this.addvancetxt != "0.00")   &&  this.final_amount != this.addvancetxt){
        paid_flag = "Partially paid";
      }else if( this.remaing_billbal == "0.00" || this.remaing_billbal == "0" ){
        paid_flag = "Fully paid";
      }

      Doc_Helper.setfinalamountbillcreate(this.final_amount)
      var doctp_id = Helper_Class.getInfo().user_id;
      
      if (this.doc_sub_rel_id != null && this.doc_sub_rel_id != undefined && this.doc_sub_rel_id != "" && this.doc_sub_rel_id.length != 0) {
        this.save_billcreate = JSON.stringify({
          appointment_id: this.appoint_txt.toString(),
          app_type: this.flowFrom,
          hptl_clinic_id: this.hptl_clinic_id.toString(),
          doc_reg_id: this.user_id.toString(),
          bill_amount: encrypt_decript.Encript(Doc_Helper.getfinalamountbillcreate()).toString(),
          paid_amount:this.paid_bill,
          paid_flag : paid_flag,
          created_by: this.user_id.toString(),
          bills: this.billaddlist,
          country: ipaddress.country_code.toString(),
          estimates: estimateid,
          client_reg_id: this.doc_client_reg_id.toString(),
          relation_id: this.doc_relation_id.toString(),
          sub_rel_id: this.doc_sub_rel_id,
          advance: this.addvancetxt.toString(),
          balance: this.remaing_billbal.toString(),
          round_off: this.round_off.toString(),
          cgst: this.cent_gst,
          sgst: this.sta_gst,
          prev_bal: this.balance_data,
          prev_bill_id: this.prev_bill_id,
          pay_type: paytype.toString(),
          insurer: instype,
          userid: doctp_id,
          card_no : this.cardNumber,
          card_holder_name : this.cardHolderName,
          transaction_no : this.Transaction_id,
          bank_name: this.bankName,
          payments:this.paymentsarray,
        })

      } else {
        this.save_billcreate = JSON.stringify({
          appointment_id: this.appoint_txt,
          app_type: this.flowFrom,
          hptl_clinic_id: this.hptl_clinic_id,
          doc_reg_id: this.user_id,
          bill_amount: encrypt_decript.Encript(Doc_Helper.getfinalamountbillcreate()).toString(),
          paid_amount:this.paid_bill,
          paid_flag : paid_flag,
          created_by: this.user_id,
          bills: this.billaddlist,
          country: ipaddress.country_code,
          estimates: estimateid,
          client_reg_id: this.doc_client_reg_id,
          relation_id: this.doc_relation_id,
          // sub_rel_id: this.doc_sub_rel_id,
          advance: this.addvancetxt,
          balance: this.remaing_billbal,
          round_off: this.round_off.toString(),
          cgst: this.cent_gst,
          sgst: this.sta_gst,
          prev_bal: this.balance_data,
          prev_bill_id: this.prev_bill_id,
          pay_type: paytype,
          insurer: instype,
          userid: doctp_id,
          card_no : this.cardNumber,
          card_holder_name : this.cardHolderName,
          transaction_no : this.Transaction_id,
          bank_name: this.bankName,
          payments:this.paymentsarray,
        })
      }

      Doc_Helper.setbillid_create(this.billaddlist)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/savebill/', this.save_billcreate,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            
            if (obj["result"] != null) {
              this.show_save_btn = false;
              if (obj["result"] == "Successfully generated bill") {
                this.toastr.success(Message_data.billgen);
                if(this.appFlow != undefined){
                  this.dietService.sendMessage("billList");
                }else{
                  this.doctorservice.sendMessage("billing");
                }
              } else {
                this.show_save_btn = true;
                this.toastr.error(Message_data.getBillUngen());
              }
            }else{
              this.show_save_btn = true;
            }
          },
          error => {
            this.show_save_btn = true;
          });
    }else{
      this.show_save_btn = true;
    }
  }

  Get_current_date() {
    var obj = Master_Helper.getMasterCurrentDate();

    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      var get_date = obj.current_date.split('-');
      this.currentDate = obj.current_date;
      this.bill_date = obj.current_date;
      this.Appoint_Date = this.CurrentDatetime;
      Doc_Helper.setBillFromDate(null);
      Doc_Helper.setBillToDate(null);
      Doc_Helper.setBillFromDate(obj.current_date);
      Doc_Helper.setBillToDate(obj.current_date);
    }
    this.getAppointments(this.bill_date);
  }

  OnDateChangedto(e) {
    this.bill_date = e;
    this.getAppointments(e);
  }

  checkUncheckAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].patient_id);
    }
    if (this.checklist.length == this.checkedList.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkedList.length != 0) {
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  addPaymentDetails(){
    for(var i=0;i<this.paytypelist.length;i++){
      if(this.payType1 == this.paytypelist[i].pay_id){
        this.paymentsarray.push({
          pay_id:this.paytypelist[i].pay_id,
          pay_desc:this.paytypelist[i].pay_desc,
          amount:this.amountval.toFixed(2)
        })
        this.amountval=0;
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=data.amount;
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }
}





