import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-pharma-sales-report-doc',
  templateUrl: './pharma-sales-report.component.html',
  styleUrls: ['./pharma-sales-report.component.scss']
})
export class PharmaSalesReportComponent implements OnInit {
  public pharmacistID: string;
  public reportType: string;
  public fromDate;
  public toDate;
  public currDate = null;
  public salesArray = [];
  public noData: boolean;
  public hasData: boolean;
  tmr = new Date();
  public userInfo;
  public currentDate;
  public payType: string;
  public totalAmount = "0.00";
  public title = 'Sales report';
  public header = [];
  public payTypeListArrray: any = [];
  public pagetrue;
  public backbutton;
  public pharmacistformadmin;

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, private activatedRoute: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.noData = false;
    this.hasData = true;
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.pagetrue = Helper_Class.getbillpagetrue();
    if (this.pagetrue == 'pharmacybill') {
      this.backbutton = true;
      this.pharmacistformadmin = Helper_Class.gethspidfrmadm();
      this.pharmacistID = this.pharmacistformadmin.pharma_id

    }
    console.log("------------------------>", this.pharmacistID)
    this.getDate();
    this.getPaymentType();
    this.reportType = "OTC";
    this.header = ['Bill no', 'Buyer', 'Create By', 'Purchase type', 'Bill date', 'Amount', "Payment status"];
  }

  backpage() {
    window.history.back();
    this.router.navigate(['/doctor-home'], { relativeTo: this.activatedRoute })
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.getSalesData('otc');
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getSalesData(data) {
    var reptype;
    var send_data;
    reptype = data == "otc" ? this.reportType : "excel";
    //if (Helper_Class.getIsAdmin() != undefined) {
      send_data = {
        hptl_clinic_id: this.userInfo.hospitals[0].hptl_clinic_id,
        type: reptype,
        from_date: this.fromDate,
        to_date: this.toDate,
        paytype: this.payType,
      };
    // } else {
    //   send_data = {
    //     pharmacist_id: this.pharmacistID,
    //     type: reptype,
    //     from_date: this.fromDate,
    //     to_date: this.toDate,
    //     paytype: this.payType,
    //   };
    // }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gsrep/", send_data,
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          if (dataval != null) {
            this.noData = true;
            this.hasData = false;
            this.salesArray = [];
            if (dataval.orders != undefined) {
              for (var i = 0; i < dataval.orders.length; i++) {
                var name;
                if (this.reportType == "OTC") {
                  name = dataval.orders[i].deliver_to;
                } else {
                  if (dataval.orders[i].deliver_to != undefined) {
                    name = dataval.orders[i].deliver_to;
                  } else {
                    if (dataval.orders[i].middle_name != undefined) {
                      name = encrypt_decript.Decript(dataval.orders[i].first_name) + " " + encrypt_decript.Decript(dataval.orders[i].middle_name) + " " + encrypt_decript.Decript(dataval.orders[i].last_name);
                    } else {
                      name = encrypt_decript.Decript(dataval.orders[i].first_name) + " " + encrypt_decript.Decript(dataval.orders[i].last_name);
                    }
                  }
                }
                var orddate = dataval.orders[i].order_date.split("-");
                var amount = dataval.orders[i].amount != undefined ? (parseFloat(dataval.orders[i].amount)).toFixed(2) : "0.00";
                var balance = dataval.orders[i].balance != undefined ? (parseFloat(dataval.orders[i].balance)).toFixed(2) : "0.00";
                if (dataval.orders[i].paid_flag != undefined && dataval.orders[i].paid_flag != null && dataval.orders[i].paid_flag != "") {
                  var payFlag = dataval.orders[i].paid_flag.replace("_", " ");
                  payFlag = payFlag.charAt(0).toUpperCase() + payFlag.substr(1).toLowerCase();
                }
                this.salesArray.push({
                  order_id: dataval.orders[i].order_id,
                  name: name,
                  created_by: dataval.orders[i].created_by,
                  order_type: dataval.orders[i].purchase_type,
                  order_date: orddate[2] + "-" + orddate[1] + "-" + orddate[0],
                  amount: parseFloat(amount).toFixed(2),
                  balance: parseFloat(balance).toFixed(2),
                  paid: payFlag,
                })
              }
              this.totalAmount = (parseFloat(dataval.total_amount)).toFixed(2);
            } else {
              this.salesArray = [];
              this.noData = false;
              this.hasData = true;
            }
          }
        }, error => { });
  }

  getExcelData(type) {
    if (this.salesArray.length != 0) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Sales report');
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 15;
      worksheet.getColumn(5).width = 20;
      let headerRow = worksheet.addRow(this.header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      })
      for (let x1 of this.salesArray) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }
      var finaldata = ['', '', '', '', 'Total', this.totalAmount];
      let finalrow = worksheet.addRow(finaldata);
      let totalval = finalrow.getCell(4);
      totalval.font = {
        bold: true,
        color: { argb: '000000' },
        size: 12
      }
      let totalamt = finalrow.getCell(5);
      totalamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
      totalamt.font = {
        bold: true,
        color: { argb: '000000' },
        size: 12
      }
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "Sales_report" + '-' + new Date().valueOf() + '.xlsx');
      });
    } else {
      this.toastr.error(Message_data.exportExcelFailed);
    }
  }

  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          this.payTypeListArrray.push({
            pay_id: "All",
            pay_desc: "All",
          })
          var obj = response.json();
          for (var i = 0; i < obj.payments.length; i++) {
            this.payTypeListArrray.push({
              pay_id: obj.payments[i].pay_id,
              pay_desc: obj.payments[i].pay_desc,
            })
          }
          //this.payTypeListArrray = obj.payments;
          this.payType = this.payTypeListArrray[0].pay_id;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
}

