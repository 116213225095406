<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Nurse's</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/allow_button.svg" (click)="permission()"
              class="saveimgbtn_inpatinfo " />
          </div>
        </div>
      </mdb-card-header>
      
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-4 select_bottom">
            <mat-label class="matlabel">Hospital name<br>
              <select required class="ipcss " [(ngModel)]="hospital_list" disableOptionCentering>
                <option *ngFor="let name of hosp_list" value={{name.hosp_name}}>{{name.hosp_name}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-4 select_bottom">
            <mat-label class="matlabel">Location<br>
              <select required class="ipcss " [(ngModel)]="location_list" disableOptionCentering>
                <option *ngFor="let name of hosp_list" value={{name.location}}>{{name.location}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-2 col-sm-5 col-md-2 col-lg-2 col-xl-2 select_bottom" style="padding: 20px 4px;">
            <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
              (click)="filter_click()" />
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <p class="nodata" [hidden]="nodapp">No nurse(s) Found</p>
          <div class="table-responsive">
            <table [hidden]="listshow" class="table table-sm" id="permission_table">
              <tr>
                <th>
                  Nurse
                </th>
                <th>
                  Selection
                </th>
              </tr>
              <tr *ngFor="let nurse of Nurse_New_List; let i = index">
                <td style="font-size: 12px; text-align: left !important;">{{nurse.Nurse_Name+" "+nurse.Doctor_Names}}
                </td>
                <td style="font-size: 12px;text-align: center;">
                  <mat-checkbox [hidden]="cancel_checkbox" color="primary" [(ngModel)]="nurse.nurse_check"
                    value="nurse.Nurse_ID"
                    (change)="multi_cancel_App(nurse.hptl_id,nurse.Nurse_ID,nurse.nurse_check,$event.checked)"
                    class="left"></mat-checkbox>
                </td>
              </tr>
            </table>
          </div>
          <br><br><br>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>