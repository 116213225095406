<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Inward</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo" (click)="save()">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
                        <mat-label class="matlabel">Bill Number
                            <input type="text" class="ipcss" [(ngModel)]="orderNo" matInput />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
                        <img src="../../assets/img/search.png" (click)="searchByOrderNo()" class="seacrhicon" width="25"
                            height="auto" class="searchIcon" />
                    </div>
                    <div class="col-6" *ngIf="viewOrderFlag">
                        <div class="cover_div">
                            <div class="header_lable">
                                Billing details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel"> <b>{{OrderByName}}</b></mat-label><br>
                                        <mat-label class="matlabel">Bill number :{{orderNumber}}</mat-label><br>
                                        <mat-label class="matlabel">Bill type :{{PurchaseType}}</mat-label><br>
                                        <mat-label class="matlabel" [hidden]="preferredTimeFlag">Preferred time
                                            :{{totalTime}}</mat-label><br>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel"> Bill date :{{orderDate}}</mat-label><br>
                                        <mat-label class="matlabel">Bill time :{{orderTime}}</mat-label><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="viewOrderFlag">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                        <mat-label class="matlabel">Payment type<br>
                            <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                                (selectionChange)="pay_change()" disableOptionCentering>
                                <mat-option *ngFor="let pay of payTypeArray" value={{pay.pay_id}}>{{pay.pay_desc}}
                                </mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="insurFlag">
                        <mat-label class="matlabel">Insurer<br>
                            <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID"
                                disableOptionCentering>
                                <mat-option *ngFor="let ins of insurerArray" value={{ins.insurer_id}}>
                                    {{ins.insurer_desc}}</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                        <mat-label class="matlabel">Card No <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                        <mat-label class="matlabel">Holder name<br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="tranFlag">
                        <mat-label class="matlabel">Transaction ID<br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                        <mat-label class="matlabel">Cheque No <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                        <mat-label class="matlabel">Bank Name <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                        </mat-label>
                    </div>
                    <div class="col-12" style="margin-top: 15px;">
                        <div class="dig_table_overflow" style="margin-bottom: 20px;">
                            <div class="table-resposive">
                                <table cellspacing="0" cellpadding="0" *ngIf="drugDetailsArray.length"
                                    class="table table-nowrap table-sm pharmacycurrentorder">
                                    <thead class="tableheading">
                                        <tr>
                                            <th>Medicine type</th>
                                            <th>Medicine name</th>
                                            <th style="width: 12%;">Batch</th>
                                            <th style="width: 8%;">Quantity</th>
                                            <th>Price</th>
                                            <th> Discount (%)</th>
                                            <th>CGST</th>
                                            <th>SGST</th>
                                            <th> Amount</th>
                                            <th> action</th>
                                            <!-- [hidden]="!orderType" -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr *ngFor="let medpre of drugDetailsArray">
                                            <td style="text-align: left;">{{medpre.type_name}}</td>
                                            <td style="text-align: left;">{{medpre.drug_name}}</td>
                                            <td>
                                                <p [hidden]="medpre.batch_hide">{{medpre.batch_no}}</p>
                                                <!-- <select [(ngModel)]="medpre.batch_no" class="ipcss " required
                                                        style="margin-top: 10px;" (change)="retrieveBatch(medpre)">
                                                        <option *ngFor="let batch of medpre.batchnos" value={{batch}}>
                                                            {{batch}}</option>
                                                    </select> -->
                                            </td>
                                            <td>
                                                <input type="text" matInput class="ipcss " [(ngModel)]="medpre.quantity"
                                                    (focus)="clearField(medpre)"
                                                    style="width: 65%;margin: 0 auto !important;" maxlength="3"
                                                    [hidden]="confirmFlag" class="ipcss "
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    (keyup)="CalculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.prod_gst)">
                                                <p [hidden]="!confirmFlag">{{medpre.quantity}} </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.discount}}
                                                </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}}
                                                </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}}
                                                </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                                            </td>
                                            <td style="text-align: center;">
                                                <!-- <a (click)="deleteMedicine(medpre.drug_id)">
                                                    <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                        width="20" height="20" /></a> -->
                                                        <img *ngIf="medpre.checked == false" (click)="isAllSelected(medpre,'select')" src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"  width="15px" />
                                                        <img *ngIf="medpre.checked == true" (click)="isAllSelected(medpre,'unselect')" src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"  width="15px" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="viewOrderFlag">
                    <div class="col-12">
                        <table class="price_table" style="float:right ;">
                            <tr style="position: relative;">
                                <td>
                                    <mat-label>
                                        <b>Refund amount</b>
                                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                            style="width: 10px; height: 10px;" />
                                    </mat-label>
                                </td>
                                <td>
                                    <mat-label class="matlabel">
                                        <p class="bill_amt_style" style="text-align: center;">{{totalvalue}}</p>
                                    </mat-label>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>