import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diag-test-home',
  templateUrl: './diag-test-home.component.html',
  styleUrls: ['./diag-test-home.component.css']
})
export class DiagTestHomeComponent implements OnInit {
  public User_type;
  public Diag_Location_Data:any = [];
  public Diag_Test_list_Data = [];
  public Location_txt;
  public createcentre_id;
  public header_footer_flag: boolean;
  public loader: boolean;
  public user_diag;
  public user_name;
  public userinfo;
  public ipaddress;
  public location;
  public ynopt1:boolean = true;
  public test_flag: boolean=false;
  public center_id:string;
  public packageData:any=[];
  //mdbTable
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};

  constructor(public diagnosisservice:MenuViewService,public toastr:ToastrService,public gservice:CommonDataService, public http:Http,public dialog:MatDialog) {
  }

  ngOnInit(): void { 
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [4] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by test name or category or type"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.dtOptions1 = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by package name or price or description"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.ipaddress=Helper_Class.getIPAddress();
    this.userinfo=Helper_Class.getInfo();
    this.getLocation();
  }
  
  getLocation() { //Get location
    this.Diag_Location_Data = [];

    if (Diagnosis_Helper.getUserID_Type().user_type != null) {
      this.User_type = "diag_user";
      this.user_name =Diagnosis_Helper.getUserID_Type().user_id;
      this.user_diag = undefined;
      
    }else {
      this.User_type = "diagnosis";
      this.user_name = Diagnosis_Helper.getUserID_Type().user_id;
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
      JSON.stringify({
        diag_name: this.user_name,
        flag: "id",
        imei: this.ipaddress,
        prov_id:Diagnosis_Helper.getUserID_Type().user_id,
        type:this.User_type,
      }),
      { headers: headers })
      .subscribe(
        response => {
          
          var obj =response.json();
          for (var i = 0; i < obj.diag_loc.length; i++) {
            this.Diag_Location_Data.push({
              centre_id: obj.diag_loc[i].diag_centre_id,
              centre_name: obj.diag_loc[i].diag_centre_name,
              centre_location: obj.diag_loc[i].diag_loc,
              view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
            });
          }

          this.center_id=this.Diag_Location_Data[0].centre_id;
          this.getTestList();
          
          this.Location_txt = this.Diag_Location_Data[0].view_data;
        },error => {
          this.toastr.error(Message_data.error);
        });
  }

  changeDigCenter(data) { //based on location det appointmnets
    if (this.Diag_Location_Data != undefined) {
      for (var i = 0; i < this.Diag_Location_Data.length; i++) {
        if (this.Diag_Location_Data[i].view_data == data) {
          this.center_id=this.Diag_Location_Data[i].centre_id;
          this.getTestList();
          
        }
      }
    }
  }

  getTestList() {//Get tests
    this.loader = false
    this.Diag_Test_list_Data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/ltbyid/',
      JSON.stringify({
        diag_id: this.center_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.diag_tests != null) {
            this.loader = true
            this.Diag_Test_list_Data = obj.diag_tests;
          }
        },error => {});
  }
  
  testDelete(test_id) {//delete tests
    if (test_id != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/cbyid/',
        JSON.stringify({
          diag_id: test_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj != null && obj.key == "1") {
              this.toastr.success(Message_data.testCancelledSuccess);
              if (this.Diag_Location_Data != undefined) {
                for (var i = 0; i < this.Diag_Location_Data.length; i++) {
                  if (this.Diag_Location_Data[i].view_data == this.Location_txt) {
                    this.center_id = this.Diag_Location_Data[i].centre_id;
                    this.getTestList();
                  }
                }
              }
              this.diagnosisservice.sendMessage('tests');
            }
            else {
              if (obj.result != null)
                this.toastr.success(obj.result);
              else
                this.toastr.error(Message_data.testNotDeleted);
            }
          },error => {
            this.toastr.error(Message_data.error);
          });
    }
  }

  testCreate() { //create test
    if(this.test_flag == false){
      if (this.Location_txt != undefined) {
        var diag_test={
          diagnosis_id: this.center_id,
        }
        Diagnosis_Helper.setdiagnosistest(diag_test);
        this.diagnosisservice.sendMessage('diagtestadd');
      } else {
        this.toastr.error(Message_data.sltDiagCenter);
      }
    } else {
      if (this.Location_txt != undefined) {
        var send_data = {
          diagnosis_id: this.center_id,
          is_new:"1",
        };

        Diagnosis_Helper.setdiagnosistest(send_data);
        this.diagnosisservice.sendMessage('diagPackageCreate');
      } else {
        this.toastr.error(Message_data.sltDiagCenter);
      }
    }
    
  }
  
  testView(test_id) {//diag test view
    if (this.Diag_Location_Data != undefined) {
      for (var i = 0; i < this.Diag_Location_Data.length; i++) {
        if (this.Diag_Location_Data[i].view_data == this.Location_txt) {
          this.createcentre_id = this.Diag_Location_Data[i].centre_id;
        }
      }
    }

    if (this.Location_txt != undefined) {
      var diag_test={
        diagnosis_id: this.createcentre_id,
         Diag_test_id: test_id,
         
      }
      Diagnosis_Helper.setdiagnosistest(diag_test);
      this.diagnosisservice.sendMessage('diagtestadd');
    } else {
      this.toastr.error(Message_data.sltDiagCenter);
    }
  }

  change_type(id, value) {
    if (value == "test") {
      this.ynopt1 = true;
      this.test_flag=false;
      this.getTestList();
    }else {
      this.ynopt1 = false;
      this.test_flag=true;
      this.getPackageList();
      //this.filterClick();
    }
  }

  getPackageList(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() +"diagnosiscontroller/gpkgd",
      { 
        diag_centre_id: this.center_id 
      },{headers:headers}).subscribe(response=>{
      console.log(response);
      var obj = response.json();
      if(obj.packages != undefined && obj.packages.length !=0){
        this.packageData=obj.packages
      }
      
    },error => {});
  }

  packageView(data){

  }

  packageEdit(data){
    var send_data = {
      diagnosis_id: this.center_id,
      is_new:"0",
      diag_package_id:data.diag_packages_id,
      pkg_name: data.package_name,
      pkg_desc: data.package_desc,
      pkg_price: data.price,
    };

    Diagnosis_Helper.setdiagnosistest(send_data);
    this.diagnosisservice.sendMessage('diagPackageCreate');
  }

  packageDelete(data){
    this.loader = false
    this.Diag_Test_list_Data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dpkgd/',
      JSON.stringify({
        diag_packages_id: data
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.key != null && obj.key =="1") {
            this.toastr.success(obj.result);
            this.getPackageList();
          } else {
            this.toastr.error("Something went wrong, try again later")
          }
        },error => {});
  }
}
