import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery/dist/jquery.min.js';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';

@Component({
  selector: 'app-outsource-centre',
  templateUrl: './outsource-centre.component.html',
  styleUrls: ['./outsource-centre.component.scss']
})
export class OutsourceCentreComponent implements OnInit {
  public hospNameTxt:string;
  public hospZip:string;
  public hospLoctionTxt:string;
  public hospDist:string;
  public hospState:string;
  public hospCountry:string;
  public hospLoctionId:string;
  private cityURL:string;
  private stateURL:string;
  private countryURL:string;
  private zipcode_url:string;
  public city_id:string;
  public hospAddress1:string;
  public hospAddress2:string;
  public hospContact:string;
  public hopWebsite:string;
  public country_id:string;
  public IsExists:boolean;
  public hosp_id:string;
  public filtStateDesc:string;
  public loc_id:string;
  public filtCityDesc:string;
  public locationFilterArray:any=[];
  public hospitalFilterArray:any=[];
  public hospitalArray:any=[];
  public filteredHospArray:any=[];
  public filteredLocList:any=[];
  public cityListArray:any=[];
  public stateListArray:any=[];
  public countryListArray:any=[];

  constructor(public http: HttpClient, public routes: ActivatedRoute, 
    public router: Router, public toastr: ToastrService,public messageservice:MenuViewService) {
    
  }

  ngOnInit(): void {
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.zipcode_url = ipaddress.getIp + "usercontroller/locbyzipcode";
    //work
    this.hospNameTxt = "";
    this.hospLoctionTxt = "";
    this.hospDist = "";
    this.hospState = "";
    this.hospCountry = "";
    this.hospZip = "";
    this.hospAddress1 = "",
    this.hospAddress2 = "";
    this.hospContact = "";
    this.hopWebsite = '';
  }

  saveCentreDetails(){
    var flag=false;
    if(this.hospNameTxt == undefined){
      flag= true;
      this.toastr.error("Enter centre name")
    }
    if(this.hospLoctionTxt == undefined){
      flag= true;
      this.toastr.error("Enter location details")
    }
    if(this.hospContact == undefined){
      flag= true;
      this.toastr.error("Enter contact number")
    }

    if(flag ==false){
      var send_data={
        diag_centre_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
        centre_name: this.hospNameTxt,
        address1:this.hospAddress1,
        address2:this.hospAddress2,
        location_id:this.loc_id,
        city_id:this.hospDist,
        state_id:this.hospState,
        country_id:this.country_id,
        zipcode:this.hospZip,
        mobile:this.hospContact,
        website:this.hopWebsite,
        country:ipaddress.country_code,
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/socd',
       send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            this.toastr.success("Outsource centre added to the list");
            this.messageservice.sendMessage("out-centre-list");
          },error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });

    }
  }

  filterHospital(e) {
    this.cityListArray = [];
    this.stateListArray = [];
    this.countryListArray = [];
    this.filteredLocList = [];
    this.hospLoctionTxt = '';
    this.hospDist = '';
    this.hospState = '';
    this.hospCountry = '';
    this.hospZip = '';
    this.hospAddress1 = '';
    this.hospAddress2 = '';
    this.hospContact = '';
    this.hopWebsite = '';
    this.hospitalArray = [];
    this.hospitalFilterArray = [];
    var tmp_arr = [];
    this.hospNameTxt = e.target.value.toString();
    if (this.hospNameTxt !== "" && this.hospNameTxt.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagnosis/',
        {diag_name: this.hospNameTxt},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.diagnosis != null) {
              this.hospitalFilterArray = obj.diagnosis;
              for (var i = 0; i < this.hospitalFilterArray.length; i++) {
                this.hospitalArray.push(this.hospitalFilterArray[i].diag_name);
              }
              this.filteredHospArray = this.hospitalArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hospNameTxt.toLowerCase()) > -1;
              }.bind(this));
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.filteredHospArray = [];
    }
  }

  selectHospital(data) {
    this.hospNameTxt = data;
    this.filteredHospArray = [];
  }
  
  locFilter(e) {//location autopopulate
    this.hospDist = '';
    this.hospState = '';
    this.hospCountry = '';
    this.hospZip = '';
    this.hospAddress1 = '';
    this.hospAddress2 = '';
    this.hospContact = '';
    this.hopWebsite = '';
    this.hosp_id = "0";
    this.locationFilterArray = [];
    this.hospLoctionTxt = e.target.value.toString();
    if (this.hospLoctionTxt !== "" && this.hospLoctionTxt.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
       {loc_name: this.hospLoctionTxt},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.locations != null) {
              this.filteredLocList = [];
              this.locationFilterArray = obj.locations;
              this.filteredLocList = this.locationFilterArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hospLoctionTxt.toLowerCase()) > -1;
              }.bind(this));
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.filteredLocList = [];
    }
  }

  selectLocation(data) {
    this.hospLoctionTxt = data;
    this.filteredLocList = [];
    this.getCity(data);
    this.stateListArray = [];
    this.cityListArray = [];
    this.countryListArray = [];
  }

  getCity(data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, { loc_name: data }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.cities != null && dataval.cities.length != 0) {
          this.cityListArray = dataval.cities;
          this.hospDist = this.cityListArray[0].city_id;
          this.filtCityDesc = this.cityListArray[0].city_desc;
          this.loc_id = this.cityListArray[0].location_id;
          this.hospZip = this.cityListArray[0].zipcode;
          this.changeCity(this.cityListArray[0].city_id);
        }
      }, error => { });
  }

  changeCity(data) {
    if (data != null) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, { city_id: data }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data))
          if (dataval.states != null) {
            this.stateListArray = [];
            this.stateListArray = dataval.states;
            this.hospState = this.stateListArray[0].state_id;
            this.filtStateDesc = this.stateListArray[0].state_desc;
            this.changeState(this.stateListArray[0].state_id);
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    } else {
      this.stateListArray = [];
      this.cityListArray = [];
      this.countryListArray = [];
    }
  }

  changeState(data) {
    if (data != null) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, { state_id: data }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data))
          if (dataval.countries != null && dataval.countries.length != null) {
            this.countryListArray = [];
            this.countryListArray = dataval.countries;
            this.country_id = this.countryListArray[0].country_id;
            this.hospCountry = this.countryListArray[0].country_desc;
            this.changeCountry();
          }
        }, error => { });
    } else {
      this.stateListArray = [];
      this.countryListArray = [];
    }
  }

  changeCountry(){
    for (var i = 0; i < this.cityListArray.length; i++) {
      if (this.cityListArray[i].city_desc == this.hospDist) {
        this.city_id = this.cityListArray[i].city_id;
      }
    }
    if (this.hospNameTxt != undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/isdiagexist/',
        {
          diag_name: this.hospNameTxt,
          location: this.hospLoctionTxt,
          city: this.city_id
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.is_exists != null) {
              if (obj.is_exists == true) {
                this.IsExists = true;
                this.toastr.error(Message_data.diagCenterAlrdyRegInLoc);
              } else {
                this.IsExists = false;
                var headers = new HttpHeaders();
                headers.append('Content-Type', 'application/json');
                this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagdetailsbyname/',
                  {
                    diag_name: this.hospNameTxt,
                    location: this.hospLoctionTxt,
                  },
                  { headers: headers })
                  .subscribe(
                    response => {
                      var obj = JSON.parse(JSON.stringify(response));
                      console.log("GET DIAG DATA ((())) "+JSON.stringify(obj))
                      if (obj.diag_id != null) {
                        this.hosp_id = obj.diag_id;
                        this.hospAddress1 = obj.address1;
                        this.hospAddress2 = obj.address2;
                        if (obj.telephone != null)
                          this.hospContact = obj.telephone;
                        if (obj.website != null)
                          this.hopWebsite = obj.website;
                      }
                    }, error => {
                      this.toastr.error(Message_data.getNetworkMessage());
                    });
              }
            } else {
              this.IsExists = false;
              this.hosp_id = "0";
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }

  zipcode_change(){
    if (this.hospZip.length > 4) {
      this.locationFilterArray = [];
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.zipcode_url, {zipcode: this.hospZip}, 
        { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data))
          if (obj.locations != null && obj.locations.length != 0) {
            if (obj.locations.length == 1) {
              this.hospLoctionId = obj.locations[0].location_id;
              this.hospLoctionTxt = obj.locations[0].description;
              this.hospZip = obj.locations[0].pincode;
              this.getCity(this.hospLoctionId);
            } else {
              this.locationFilterArray = obj.locations;
              
            }
          }
        },
        error => {
          this.toastr.error("Unable to fetch location details, please try later");
        }
      )
    } else {
      this.locationFilterArray = [];
    }
  }
}
