import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { FormControl } from '@angular/forms';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate } from 'src/assets/js/common';
@Component({
  selector: 'app-pharma-receivable-report',
  templateUrl: './pharma-receivable-report.component.html',
  styleUrls: ['./pharma-receivable-report.component.css']
})
export class PharmaReceivableReportComponent implements OnInit {
  tmr = new Date();
  public pharmacyID: string;
  public fromDate;
  public toDate;
  public receiveData = [];
  public noData: boolean;
  public hasData: boolean;
  public currentDate;
  public userInfo;
  public pharmacistID;
  public type = "all";
  public MedicineNameArray = [];
  public medicineArray = [];
  public MedicneData = [];
  public productName;
  public productFlag:boolean = false;
  public suplierFlag:boolean = false;
  public supllierName;
  public productID;
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.noData = false;
    this.hasData = true;
  }
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.getDate();
  }
  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate() + 10);
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.changeProductType()
          this.getReceivablesData();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }
  getDateMatePicker(e) {
    var month = ((e.value.getMonth() + 1) < 10 ? '0' : '') + (e.value.getMonth() + 1);
    var day = (e.value.getDate() < 10 ? '0' : '') + e.value.getDate();
    var apt = e.value.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }
  ChangeMatePicker(date_value) {
    var apt = new FormControl(new Date(date_value));
    return apt;
  }
  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }
  getReceivablesData() {
    var send;
    if( this.type == "supplier" ){
      send = {
        pharmacy_id: this.pharmacyID,
        from_date: this.fromDate,
        to_date: this.toDate,
        type : this.type,
        supp_id : this.supplierID
      }
    }else if( this.type == "product" ){
      send = {
        pharmacy_id: this.pharmacyID,
        from_date: this.fromDate,
        to_date: this.toDate,
        type : this.type,
        product_id : this.productID
      }
    } else {
      send = {
        pharmacy_id: this.pharmacyID,
        from_date: this.fromDate,
        to_date: this.toDate,
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gprep/", JSON.stringify(send),
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          if (dataval != null) {
            this.noData = true;
            this.hasData = false;
            this.receiveData = [];
            if (dataval.receivables != undefined) {
              for (var i = 0; i < dataval.receivables.length; i++) {
                var poDate = dataval.receivables[i].po_date != undefined ? Date_Formate(dataval.receivables[i].po_date) : "";
                this.receiveData.push({
                  hsn_no: dataval.receivables[i].hsn_no,
                  product_name: dataval.receivables[i].product_name,
                  po_code: dataval.receivables[i].po_code,
                  po_qty: dataval.receivables[i].po_qty,
                  mrp : dataval.receivables[i].mrp,
                  poDate : poDate,
                  qty_received: dataval.receivables[i].qty_received,
                  balance: dataval.receivables[i].balance,
                  supName : dataval.receivables[i].name,
                  costPrice : dataval.receivables[i].cost_price,
                  salsePrice : dataval.receivables[i].sales_price,
                })
              }
            } else {
              this.receiveData = [];
              this.noData = false;
              this.hasData = true;
            }
          }
        },
        error => { });
  }
  changeProductType(){
    this.productFlag = false; 
    this.suplierFlag = false
      if( this.type == "supplier" ){
          this.suplierFlag = true;
      }else if( this.type == "product" ){
          this.productFlag = true
      }
  }
  changeMedicineName(e) {
    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type : "medicine"
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.med_details != null) {
              
              this.MedicneData = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
               // this.MedicneData.push(medname);
                this.MedicneData.push({ name : medname, quantity : obj.med_details[i].quantity, med_id: obj.med_details[i].med_id});
              }
              // this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
              //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
              // }.bind(this));
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }
 
  selectMedicineItem(item) {
    this.productID = item.med_id;
    this.MedicineNameArray = [];
  }
  public supplierArray = [];
  public supplierID;
  changeSupplier(e){
    var senddetails = JSON.stringify({
      name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gsupp', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            this.supplierArray = response.json().suppliers;
           console.log("OBJ" + JSON.stringify(this.supplierArray));
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }
  selectSupplierName(supplier){
      this.supplierID = supplier.supp_id;
  }
}
