<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Old casesheet</h5>
    <div mat-dialog-actions >
      <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" style="float:right !important;margin-left: 440px;" id="close_pdf" />
    </div>
  </div>
  <div mat-dialog-content >
    <div class="container">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 10px;">
            <iframe id="myIframe" class="controls" [src]="pdfurl" width="100%" height="800px"
               frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
         </div>
    </div>
  </div>