import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { PharmaPrintService } from '../pharmacy-print.service';
import { Message_data } from 'src/assets/js/Message_data';
import moment from 'moment';
import { AdminViewService } from 'src/app/Admin_module/admin-view/admin-view.service';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;
@Component({
  selector: 'app-ordercreate',
  templateUrl: './ordercreate.component.html',
  styleUrls: ['./ordercreate.component.css']
})
export class OrdercreateComponent implements OnInit {
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('billid') billid: ElementRef;
  dtOptions: DataTables.Settings = {};
  public amountBDGST;
  public prodDiscount: any = "0.00";
  public amountDicount = "0.00";
  public orderDate: string;
  public orderTime: string;
  public drugDetailsArray: any = [];
  public batchArray = [];
  public doctorName = "";
  public totalAmount: any;
  public todayDate: string;
  public paidAmount = "0.00";
  public remainBalance = "0.00";
  public imageFile;
  public saveBtn: boolean;
  public printBtn: boolean;
  public MedicneData = [];
  public PurchaseType: string;
  public MedicineNameArray = [];
  public medicineArray = [];
  public drugArray = [];
  public discSalesVolArray = [];
  public disctransVolArray = [];
  public discregsalesvol = [];
  public discountDatasArray = [];
  public discountType: string;
  public discountURl: string;
  public discoutFlag: boolean;
  public medicineName: string;
  public batchNo: string;
  public saveOrderURL: string;
  public name: string;
  public sendData;
  public perUnit;
  public image;
  public ReferedDoctorArray = [];
  public ReferedNameArray = [];
  public patientArray: any = [];
  public getData;
  public pharmacistID;
  public pharmacyID;
  public ipaddress;
  public pharmaLogo;
  public billTemplate;
  public stateGST;
  public centralGST;
  public bgImage: string;
  public location;
  public city;
  public state;
  public country;
  public pharmacyName;
  public dlNO;
  public gstNO;
  public pharmaAddress1;
  public pharmaAddress2;
  public displayDisplay: boolean;
  public tranFlag: boolean;
  public TransactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public payTypeListArrray: any = []; 
  public insurerArray: any = [];
  public payType: string;
  public insurerID: string;
  public insurFlag1:boolean=true;
  public cardFlag1: boolean=true;
  public tranFlag1: boolean=true;
  public chequeFlag1: boolean=true;
  public userName;
  public billID = undefined;
  public tempBatch;
  public recItemID;
  public batch_required = { "border-color": "rgb(250, 40, 2)" };
  public batchFlag: boolean = false;
  public saveFlag: boolean = true;
  public quantityDisable: boolean = true;
  public productReturn;
  public genericDisplayFlag:boolean = false;
  public payDes;
  public paytypreq: boolean=false;
  public date_time_flag:boolean=false;
  public payType1:string;
  public amountval:number;
  private tamt:number=0;
  public paymentsarray:any=[];
  public relation_id: string;
  public client_reg_id: string;
  public patient_id: any = '';
  public sub_rel_id:string;

  constructor(public pharmacyservice: PharmacyService, public toastr: ToastrService, public gservice: CommonDataService, public http: Http, public router: Router, public dialog: MatDialog, public printService: PharmaPrintService, public adminService: MenuViewService) {
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.printBtn = true;
    this.saveBtn = true;
    this.saveOrderURL = ipaddress.getIp + "pharmacontrol/sporder";
    this.PurchaseType = "Store pickup"
    this.discountURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getData = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();
    this.pharmacistID = this.getData.pharmacy;
    this.pharmacyID = this.getData.hospitals[0].hptl_clinic_id;
    this.pharmacyName = this.getData.hospitals[0].hptl_name;
    this.location = this.getData.hospitals[0].location_desc;
    this.city = this.getData.hospitals[0].city_desc;
    this.state = this.getData.hospitals[0].state_desc;
    this.country = this.getData.hospitals[0].country_desc;
    if(this.getData.hospitals[0].bill_pay_type_req =="1"){
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }
    this.pharmaAddress1 = this.getData.hospitals[0].address1 != undefined && this.getData.hospitals[0].address1 != null && this.getData.hospitals[0].address1 != "" && this.getData.hospitals[0].address1 != "undefined" ? this.getData.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.getData.hospitals[0].address2 != undefined && this.getData.hospitals[0].address2 != null && this.getData.hospitals[0].address2 != "" && this.getData.hospitals[0].address2 != "undefined" ? this.getData.hospitals[0].address2 : undefined;
    this.dlNO = this.getData.hospitals[0].dl_no != undefined && this.getData.hospitals[0].dl_no != null && this.getData.hospitals[0].dl_no != "" ? this.getData.hospitals[0].dl_no : undefined;
    this.gstNO = this.getData.hospitals[0].gst_no != undefined && this.getData.hospitals[0].gst_no != null && this.getData.hospitals[0].gst_no != "" ? this.getData.hospitals[0].gst_no : undefined;
    this.pharmaLogo = ipaddress.Ip_with_img_address + this.getData.hospitals[0].bill_logo;
    this.billTemplate = this.getData.hospitals[0].bill_template;
    this.bgImage = this.getData.hospitals[0].bg_image != undefined ? this.getData.hospitals[0].bg_image : undefined;
    this.userName;
    this.productReturn = this.getData.hospitals[0].product_return;
    if (this.getData.middle_name != undefined)
      this.userName = this.getData.first_name + " " + this.getData.middle_name + " " + this.getData.last_name;
    else {
      if (this.getData.last_name != undefined)
        this.userName = this.getData.first_name + " " + this.getData.last_name;
      else
        this.userName = this.getData.first_name;
    }
    if(Helper_Class.getInfo().hospitals[0].bill_date_time_edit == "1"){
      this.date_time_flag = false;
    } else {
      this.date_time_flag = true;
    }

    this.CurrentDate();
    this.getDiscTypes();
    this.getDiscountDetails();
    this.getPaymentType();
    this.addNewDrugDetails();
    var Moduleidlist;
    if (pharmacy_helper.getModuleList() != null) {
      Moduleidlist = pharmacy_helper.getModuleList(); 
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "99") {
          this.genericDisplayFlag = true;
        }
      }
    }
  }

  backButton(){
    if (Helper_Class.getIsAdmin() != undefined) {
      this.adminService.sendMessage("pharmaBillingList")
    } else {
      this.pharmacyservice.sendMessage('pharmacycurentinformation');
    }
  }

  addNewDrugDetails() {
    var index = 0;
    if (this.drugDetailsArray.length == 0) {
      this.drugDetailsArray.push({
        drug_id: "",
        type_name: "",
        drug_name: "",
        drug_disp_name: "",
        quantity: "",
        tempquantity: "",
        amount: "",
        price: "",
        discount: "",
        per_unit: "",
        prod_disc: "",
        batch_no: "",
        amt_bdgst: "",
        cgst: "",
        sgst: "",
        expiry_date: "",
        rec_item_id: "",
        index: index,
        gen_name : "",
        batch_required: { "border-color": "rgb(250, 40, 2)" },
        batcharray: "",
        show_batcharray: false,
        quantityDisable: true,
      });
    } else {
      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_name != "" && this.drugDetailsArray[i].batch_no != "" && this.drugDetailsArray[i].quantity != "") {
          index = this.drugDetailsArray[i].index + 1;
          this.drugDetailsArray.push({
            drug_id: "",
            type_name: "",
            drug_name: "",
            drug_disp_name: "",
            quantity: "",
            tempquantity: "",
            amount: "",
            price: "",
            discount: "",
            per_unit: "",
            prod_disc: "",
            batch_no: "",
            amt_bdgst: "",
            cgst: "",
            sgst: "",
            expiry_date: "",
            rec_item_id: "",
            index: index,
            gen_name : "",
            batch_required: { "border-color": "rgb(250, 40, 2)" },
            batcharray: "",
            show_batcharray: false,
            quantityDisable: true,
          });
        } else {
          this.toastr.error(Message_data.checkDrugListEmpty);
        }
        break;
      }
    }
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);
  }

  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.payTypeListArrray = obj.payments;
          this.payType = this.payTypeListArrray[0].pay_id;
          this.payDes = this.payTypeListArrray[0].pay_desc;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  getInsurers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  changePatientName(e) {
    var type_name = "name";
    var senddetails = JSON.stringify({
      first_name: e.target.value.toLocaleUpperCase(),
      type: type_name,
      hptl_clinic_id: Helper_Class.getHospitalId(),
      provtype: Helper_Class.getInfo().user_type,
      pattype:"out-patient"
    })
    console.log("senddetails value", senddetails)
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      this.name = this.name.toLocaleUpperCase();
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/cid', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj patient name" + JSON.stringify(obj));

            if (obj.clients != null) {
              this.patientArray = [];

              for (var i = 0; i < obj.clients.length; i++) {
                var patName = obj.clients[i].first_name + " " + obj.clients[i].last_name;

                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  mobile: obj.clients[i].mobile,
                  patient_gender: obj.clients[i].gender,
                  patient_age: obj.clients[i].age,
                })
              }
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  selectPatient(patient) {
    console.log(patient);
    this.relation_id = patient.relation_id;
    this.client_reg_id = patient.client_reg_id;
    this.sub_rel_id = patient.sub_rel_id;
    this.patient_id = patient.patient_id;
    this.name = patient.patient_name;
    console.log("selectPatient name", this.relation_id, this.client_reg_id)
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.payTypeListArrray.forEach(element => {
      if( element.pay_id == this.payType ){
        this.payDes = element.pay_desc;
      }
    });
    if (this.payType == "3")
      this.insurFlag = false;
    else if (this.payType == "4")
      this.tranFlag = false;
    else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.tranFlag1 = false;
    } else if (this.payType == "6") {
      this.chequeFlag1 = false;
    } else {
      this.insurFlag = true;
    }
  }

  changePayType() {
    //document.getElementById("payType1")!.style.borderColor = '#2ca8fe';
    this.insurFlag1 = true;
    this.tranFlag1 = true;
    this.cardFlag1 = true;
    this.chequeFlag1 = true;
    this.payTypeListArrray.forEach(element => {
      if( element.pay_id == this.payType ){
        this.payDes = element.pay_desc;
      }
    });

    if (this.payType1 == "2") {
      this.tranFlag1 = false;
      this.cardFlag1 = false;
    } else if (this.payType1 == "3")
      this.insurFlag1 = false;
    else if (this.payType1 == "4" || this.payType1 == "5" || this.payType1 == "6")
      this.tranFlag1 = false;
    else if (this.payType1 == "7") {
      this.chequeFlag1 = false;
    } else {
      this.insurFlag1 = true;
    }
  }

  changeMedicineName(e,type) {
    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type : type
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log( "obj" + JSON.stringify(obj));
           
              if (obj.med_details != null) {
                this.MedicineNameArray = [];
                this.medicineArray = [];
                this.MedicneData = [];
                for (var i = 0; i < obj.med_details.length; i++) {
                  var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                 // this.MedicneData.push(medname);
                  this.MedicneData.push({ name : medname, quantity : obj.med_details[i].quantity, generic : obj.med_details[i].generic_name});
                  this.medicineArray.push({
                    med_id: obj.med_details[i].med_id,
                    med_name: obj.med_details[i].med_name,
                    med_type_id: obj.med_details[i].med_type_id,
                    type_name: obj.med_details[i].type_name,
                    short_name: obj.med_details[i].short_name,
                    short_form: obj.med_details[i].short_form,
                    show_short_form: obj.med_details[i].show_short_form,
                    show_intake: obj.med_details[i].show_intake,
                    medname: medname,
                    cgst: obj.med_details[i].cgst,
                    sgst: obj.med_details[i].sgst,
                  })
             
                // this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
                //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
                // }.bind(this));
              }
            }
            
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  selectGeneiric(item, drug_detailsarray){

  }

  selectMedicineItem(item, drug_detailsarray) {
    for (var i = 0; i < this.medicineArray.length; i++) {
      if (item.name == this.medicineArray[i].medname) {
        drug_detailsarray.drug_name = item.name;
        drug_detailsarray.gen_name = item.generic;
        drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
        drug_detailsarray.drug_id = this.medicineArray[i].med_id;
        this.medicineName = this.medicineArray[i].med_name;
        drug_detailsarray.cgst = this.medicineArray[i].cgst;
        drug_detailsarray.sgst = this.medicineArray[i].sgst;
      }
    }
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }

  getItemPrices(drug_detailsarray) {
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: drug_detailsarray.drug_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/ipnopres/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if (dataval != undefined && dataval.length != 0) {
          drug_detailsarray.batcharray = [];
          var index = 0;
          for (var i = 0; i < dataval.pharma_price.length; i++) {
            this.drugArray.push({
              price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
              per_unit: dataval.per_unit,
              batch_no: dataval.pharma_price[i].batch_no,
              quantity: dataval.pharma_price[i].avail_qty,
              discount: dataval.pharma_price[i].prod_disc,
              cgst: data.json().pharma_price[i].prod_cgst,
              sgst: data.json().pharma_price[i].prod_sgst,
              expiry_date: data.json().pharma_price[i].expiry_date,
              rec_item_id: data.json().pharma_price[i].rec_item_id,
              index: index,
            });
            index++;
          }
          for (var j = 0; j < dataval.batch_nos.length; j++) {
            var res = this.drugArray.filter(o => o.rec_item_id == dataval.batch_nos[j].rec_item_id);
            if (res.length != 0) {
              if (res[0].batch_no == dataval.batch_nos[j].batch_no) {
                var color = this.checkExpiryDate(res[0].expiry_date);
                drug_detailsarray.batcharray.push({
                  index: res[0].index,
                  batch_no: dataval.batch_nos[j].batch_no,
                  rec_item_id: dataval.batch_nos[j].rec_item_id,
                  mrp: res[0].price_txt,
                  quantity: res[0].quantity,
                  expiry_date: res[0].expiry_date,
                  color: color,
                });
              }

            }
          }
        }
      }, error => { }
    );
  }

  selectedBatch(batch, drug_detailsarray, index) {
    var checkList = this.fineDrugList(batch.batch_no, drug_detailsarray.drug_id);
    if (checkList != true) {
      this.batchFlag = false;
      drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
      drug_detailsarray.batch_no = batch.batch_no;
      if (drug_detailsarray.batch_no != "")
        drug_detailsarray.quantityDisable = false;
      for (var i = 0; i < this.drugArray.length; i++) {
        if (batch.batch_no == this.drugArray[i].batch_no) {
          if (batch.index == this.drugArray[i].index) {
            if (this.tempBatch != undefined) {
              drug_detailsarray.price = this.drugArray[i].price_txt;
              // drug_detailsarray.quantity = this.drugArray[i].quantity;
              // drug_detailsarray.tempquantity = this.drugArray[i].quantity;
            } else {
              drug_detailsarray.price = this.drugArray[i].price_txt;
              // drug_detailsarray.quantity = this.drugArray[i].quantity;
              // drug_detailsarray.tempquantity = this.drugArray[i].quantity;
              drug_detailsarray.per_unit = this.drugArray[i].per_unit;
              drug_detailsarray.cgst = this.drugArray[i].cgst;
              drug_detailsarray.sgst = this.drugArray[i].sgst;
              drug_detailsarray.discount = this.drugArray[i].discount;
              drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
              drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id
            }
            this.calculateamount(drug_detailsarray);
          }
        }
      }
      drug_detailsarray.show_batcharray = false;
      document.getElementById(index).style.display = 'none';
      document.getElementById("pharma_tbl_overlay").style.overflowY = "auto";
      document.getElementById("tbl_med").style.overflowY = "auto";
      
    } else {
      this.toastr.error("Medicine with same batch no already added");
    }

  }

  fineDrugList(batch, drugID) {
    var getFlag = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if ((this.drugDetailsArray[i].drug_id == drugID) && (this.drugDetailsArray[i].batch_no == batch)) {
        getFlag = 1;
      }
    }
    return getFlag == 1 ? true : false;
  }

  isReadonly() { return true; }
  CurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.todayDate = obj.current_date;
          this.orderDate = this.todayDate;
          this.orderTime = Time_Formate(obj.current_time);
        },
      )
  }

  calculateAmount(drug_detailsarray) {
    if (drug_detailsarray.quantity != "") {
      var priceval = parseFloat(drug_detailsarray.price);
      if (drug_detailsarray.quantity != undefined) {
        var pricecal: any = priceval * drug_detailsarray.quantity;
      }

      if (drug_detailsarray.discount != null) {
        var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
        drug_detailsarray.amount = pricecal - disc;
      } else {
        disc = 0;
      }

      var pricegst;
      pricegst = pricecal - disc;
      drug_detailsarray.amount = pricegst.toFixed(2);
      //this.prodAmount = pricecal;
    }
    this.calculateDiscount();
  }

  calculateamount(drug_detailsarray) {
    if(parseInt(drug_detailsarray.discount)>100){
      this.toastr.error("Discount can not be more than 100%");
      drug_detailsarray.discount="0";

    } else {
      if (drug_detailsarray.quantity != "") {
        var pricecal
        var priceval: any = parseFloat(drug_detailsarray.price);
        if (drug_detailsarray.quantity != undefined) {
          pricecal = (parseFloat(priceval) * parseFloat(drug_detailsarray.quantity)).toFixed(2);
        }
        if (drug_detailsarray.discount != null) {
          var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
          drug_detailsarray.amount = pricecal - disc;
        } else {
          disc = 0;
        }
        var pricegst;
        pricegst = (parseFloat(pricecal) - parseFloat(disc)).toFixed(2);
        drug_detailsarray.amount = pricegst;
        this.calculateDiscount();
      }
    }
  }

  createRow(){
    this.addNewDrugDetails();
  }
  
  quantity(drug_detailsarray) {
    if (parseInt(drug_detailsarray.quantity) > parseInt(drug_detailsarray.tempquantity)) {
      drug_detailsarray.quantity = drug_detailsarray.tempquantity;
      this.calculateamount(drug_detailsarray);
      this.toastr.error("Quantity Exits to avilable quantity");
    }
    var prec_tx = 0;
    if (this.drugDetailsArray.length > 1) {
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
      }
    } else {
      prec_tx = prec_tx + parseFloat(drug_detailsarray.amount)
    }
    this.totalAmount = prec_tx.toFixed(2);
    this.printBtn = false;
    this.saveBtn = false;
    this.displayDisplay = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
    this.calculateamount(drug_detailsarray);
    this.MedicneData=[];
   
  }

  calculateDiscount() {
    var tamtbg = 0.00;
    var amtval = 0.00;

    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      tamtbg = parseFloat(tamtbg + this.drugDetailsArray[i].amt_bdgst);
      amtval = amtval + parseFloat(this.drugDetailsArray[i].amount);
    }
    this.totalAmount = amtval.toFixed(2);
    if (this.discountType == "3") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discregsalesvol.length; j++) {
        if (parseInt(this.amountBDGST) >= this.discregsalesvol[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.discregsalesvol[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);
          this.amountDicount =  (Math.round(parseFloat(this.amountDicount))).toFixed(2);
        }
      }
    } else if (this.discountType == "4") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discSalesVolArray.length; j++) {
        if (parseInt(this.amountBDGST) >= this.discSalesVolArray[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.discSalesVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);
          this.amountDicount =  (Math.round(parseFloat(this.amountDicount))).toFixed(2);
        }
      }
    } else if (this.discountType == "5") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.disctransVolArray.length; j++) {
        if (parseInt(this.amountBDGST) >= this.disctransVolArray[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.disctransVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);
          this.amountDicount =  (Math.round(parseFloat(this.amountDicount))).toFixed(2);
        }
      }
    } else {
      this.amountDicount = amtval.toFixed(2);
      this.amountDicount =  (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    }
    this.remainBalance = parseFloat(this.amountDicount).toFixed(2);
    this.changeTotal();
  }

  changeTotal() {
    if (this.prodDiscount == "") {
      this.displayDisplay = false;
      this.prodDiscount = "0.00";

    } else {
      this.displayDisplay = true;
    }
    this.remainBalance = (parseFloat(this.amountDicount) - (parseFloat(this.prodDiscount) + parseFloat(this.paidAmount))).toFixed(2);
  }

  deleteMedicine(drug_id, index) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null) {
        if (this.drugDetailsArray[i].drug_id == drug_id) {
          if (this.drugDetailsArray[i].index == index) {
            this.drugDetailsArray.splice(i, 1);
          }
        }
      }
    }

    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null)
        prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
    }
    this.totalAmount = prec_tx;
    this.amountDicount = prec_tx.toString();
    this.amountDicount =  (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    this.remainBalance = parseFloat(this.amountDicount).toFixed(2);
    
    if (this.drugDetailsArray.length == 0) {
      this.addNewDrugDetails();
    }
  }

  changeAdvance() {
    if (this.paidAmount != undefined && this.paidAmount != "") {
      this.remainBalance = (parseFloat(this.amountDicount) - (parseFloat(this.paidAmount) + parseFloat(this.prodDiscount))).toFixed(2);
      
    } else {
      this.paidAmount = "0.00";
      this.remainBalance = this.amountDicount;
    }
  }

  paidBlur() {
    if (this.paidAmount != "") {
      this.paidAmount = (parseFloat(this.paidAmount)).toFixed(2);
    }
  }
  
  dissBlur() {
    if (this.prodDiscount != "") {
      this.prodDiscount = (parseFloat(this.prodDiscount)).toFixed(2);
    }
  }

  saveOrder(e) {
    var saveflag = false;
    if (this.doctorName == "" || this.name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      var paid_flag;
      var instype;
      this.saveFlag = false;
      instype = this.insurerID;
      if (this.amountDicount == this.remainBalance) {
        paid_flag = "Un paid";
      } else if ( this.amountDicount != "0.00" && (this.paidAmount != "0.00") && this.amountDicount != this.paidAmount) {
        paid_flag = "Partially paid";
      } else if (this.remainBalance == "0.00" || this.remainBalance == "0") {
        paid_flag = "Fully paid";
      }
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
          this.drugDetailsArray.splice(i, 1);
        }
      }
      var paytype;
      if(this.paytypreq == true){
        paytype = this.payType1

      } else {
        if(this.paymentsarray.length !=0){
          paytype = this.paymentsarray[0].pay_id;
          this.payDes = this.paymentsarray[0].pay_desc
        }
      }
      var save_order_data = {
        pharma_id: this.pharmacyID,
        purchase_type_id: "1",
        client_reg_id:this.client_reg_id,
        relation_id:this.relation_id,
        sub_rel_id:this.sub_rel_id,
        medicines: this.drugDetailsArray,
        country: ipaddress.country_code,
        name: this.name,
        doc_name: this.doctorName,
        pres_image: this.image,
        disc_type: this.discountType,
        disc_amt: parseFloat(this.prodDiscount).toFixed(2),
        bill_amt: parseFloat(this.amountDicount).toFixed(2),
        paid_amt: parseFloat(this.paidAmount).toFixed(2),
        balance: parseFloat(this.remainBalance).toFixed(2),
        actual_amt : parseFloat(this.totalAmount).toFixed(2),
        paid_flag: paid_flag,
        order_date: this.orderDate,
        pay_type: paytype,
        insurer: instype,
        card_holder_name: this.cardHolderName,
        transaction_no: this.TransactionID,
        bank_name: this.bankName,
        created_by: this.userName,
        payments:this.paymentsarray,
      };
      console.log("save_order_data" + JSON.stringify(save_order_data));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.saveOrderURL, JSON.stringify(save_order_data), { headers: headers }).subscribe(
        data => {
          if (data.json().key !== null && data.json().key == "1") {
            this.billID = data.json().order_id;
            this.toastr.success(Message_data.billgen);
            this.saveFlag = true;
            if (Helper_Class.getIsAdmin() != undefined) {
              this.adminService.sendMessage("pharmaBillingList")
            } else {
              this.pharmacyservice.sendMessage('pharmacycurentinformation');
            }
            if (e == 'print') {
              this.print();
            }
          } else {
            this.saveFlag = true;
            this.toastr.error(Message_data.orderNotPlaced);
          }
        }, error => { this.saveFlag = true; });
    }
  }

  printArea() {
    var saveflag = false;
    if (this.doctorName == "" || this.name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      this.saveOrder('print');
    }
  }
  
  print() {
    if (this.billID != undefined) {
      var pharmaAddr = this.pharmaAddress2 != undefined ? this.pharmaAddress1 + "," + this.pharmaAddress2 : this.pharmaAddress1;
      var template;
      if (this.billTemplate != undefined && this.billTemplate == "banner") {
        template = '<div #banner id="banner">'
      } else if (this.billTemplate != undefined && this.billTemplate == "noheader") {
        template = '<div #noheader id="noheader">'
      } else if (this.billTemplate != undefined && this.billTemplate == "logowithname") {
        template = '<div #logowithname id="logowithname"style="background-image: url({{bg_image}}); background-position: center;">';
      } else {
        template = '<div #billprint id="billprint">';
      }
      
      var printData = {
        template: this.billTemplate,
        content: template,
        pharma_logo: this.pharmaLogo,
        pharma_name: this.pharmacyName,
        pharma_addr: pharmaAddr,
        location: this.location,
        city: this.city,
        state: this.state,
        country: this.country,
        dl_no: this.dlNO,
        gst_no: this.gstNO,
        patient_name: this.name,
        bill_no: this.billID,
        PurchaseType: this.PurchaseType,
        doc_name: this.doctorName,
        order_date: Date_Formate(this.orderDate),
        order_time: this.orderTime,
        drugList: this.drugDetailsArray,
        discount: this.prodDiscount,
        total: parseFloat(this.amountDicount).toFixed(2),
        
      //  total: parseFloat(this.totalAmount).toFixed(2),
        paid: parseFloat(this.paidAmount).toFixed(2),
        balance: parseFloat(this.remainBalance).toFixed(2),
        order_type: true,
        show_discount: this.displayDisplay,
        pay_type : this.payDes,
        pay_flag:this.paytypreq,
        payments:this.paymentsarray,
      }
      this.printService.print(printData);
    }
  }

  fileChange(data) {
    this.readThis(data.target);
  }
  
  readThis(inputValue: any): void {
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP'];
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      this.imageFile = myReader.result.toString();
      this.image = this.imageFile.split(',')[1];
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  getReferralData(e) {
    this.ReferedDoctorArray = [];
    this.ReferedNameArray = [];
    if (this.doctorName != undefined && this.doctorName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          doc_name: this.doctorName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.ReferedNameArray = obj.doctors;
            if (this.ReferedNameArray != undefined) {
              for (var i = 0; i < this.ReferedNameArray.length; i++) {
                var docname;
                if (this.ReferedNameArray[i].middle_name != undefined) {
                  docname = this.ReferedNameArray[i].first_name + " " + this.ReferedNameArray[i].middle_name + " " + this.ReferedNameArray[i].last_name;
                } else {
                  docname = this.ReferedNameArray[i].first_name + " " + this.ReferedNameArray[i].last_name;
                }
                this.ReferedDoctorArray.push(docname);
              }
            }
            this.ReferedDoctorArray = this.ReferedDoctorArray.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.doctor_txt.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.network);
          }
        )
    }
    else {
      this.ReferedDoctorArray = [];
    }
  }

  Select_refer_doctor(item) {
    this.doctorName = item;
    this.ReferedDoctorArray = [];
  }
  
  getDiscountDetails() {
    this.discregsalesvol = [];
    this.discSalesVolArray = [];
    this.disctransVolArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpdis/",
      JSON.stringify({
        pharma_id: this.pharmacyID,
        imei: this.ipaddress
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.discregsalesvol = obj.regsal_vol;
          this.discSalesVolArray = obj.sales_vol;
          this.disctransVolArray = obj.trans_vol;
        });
  }

  getDiscTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.discountURl, { headers: headers }).subscribe(
      data => {
        this.discountDatasArray = [];
        var distypes = data.json();
        this.discountDatasArray = distypes.disc_types;
        this.discountType = this.discountDatasArray[0].disc_type_id;
      }, error => { });
  }

  discountChange(distype) {
    if (distype != "1" && distype != "2") {
      this.discoutFlag = false;
      if (this.discregsalesvol != undefined && this.discregsalesvol.length == 0) {
        this.getDiscountDetails();
      }
    } else {
      this.discoutFlag = true;
    }
    this.calculateDiscount();
  }

  changeOrderDate(e) {
    this.orderDate = e;
  }
  
  getCORD(e, i) {
    this.batchFlag = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';
    document.getElementById(i).style.top = y + 'px';
    document.getElementById("pharma_tbl_overlay").style.overflowY = "hidden";
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   if (this.drugDetailsArray[0].drug_id!="") {
  //     if (this.drugDetailsArray.length >= 1) {
  //       for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //         document.getElementById('drug_' + this.drugDetailsArray[i].index).style.display = 'none';
  //       }
  //     }
  //   } else {
  //     this.batchFlag = false;
  //   }
  // }

  checkExpiryDate(expiryDate) {
    var exp = "";
    if (expiryDate.split("-").length != 1)
      exp = expiryDate.split("-");
    var d = new Date();
    var a = moment(exp[1] + "-" + exp[0]);
    var b = moment(d);
    var intervals: any = ['years', 'months', 'weeks', 'days'];
    var out = [];

    for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out.push(diff + ' ' + intervals[i]);
    }

    console.log("out ==> " + out);
    var expD: any = out.toString().split(",");
    var yy = expD[0].split(/\s+/g);
    console.log("yy ==> " + yy);
    var mmm = expD[1].split(/\s+/g);
    console.log("mmm ==> " + mmm);
    var subtract = moment().add(mmm[0], mmm[1].toString()).format('YYYY-MM');
    var sub1 = moment(subtract).add(yy[0], yy[1].toString()).format('YYYY-MM');
    var final = moment().add(this.productReturn, 'months').format('YYYY-MM');
    console.log("subtract ==> " + subtract + "   sub1 ==> " + sub1 + "final ==> " + final);
    var color;
    if (sub1 <= final) {
      color = "red";
    } else {
      color = "black";
    }
 
    return color;
  }

  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  paidFocus(){
    this.paidAmount = "";
  }

  addPaymentDetails(){
    this.tamt+=this.amountval;
    if(this.tamt > parseInt(this.paidAmount)){
      this.toastr.error(Message_data.getGreaterAmount());
      this.tamt -=this.amountval;
    } else {
      var flag=false;
      
      if((this.payType != undefined && this.payType =="2") && ((this.cardNumber!= undefined &&this.cardNumber.length<4) || this.cardNumber== undefined)) {
        this.toastr.error("Enter last four digits of card")
        flag = true;
        this.tamt-=this.amountval;

      } else if((this.payType1 != undefined && this.payType1 =="2") && ((this.cardNumber!= undefined &&this.cardNumber.length<4) || this.cardNumber== undefined)) {
        this.toastr.error("Enter last four digits of card")
        flag = true;
        this.tamt-=this.amountval;
      }
      if(flag == false) {
        for(var i=0;i<this.payTypeListArrray.length;i++){
          if(this.payType1 == this.payTypeListArrray[i].pay_id){
            this.paymentsarray.push({
              pay_id:this.payTypeListArrray[i].pay_id,
              pay_desc:this.payTypeListArrray[i].pay_desc,
              amount:this.amountval.toFixed(2),
              trans_id:this.TransactionID,
              insurer:this.insurerID,
              card_no:this.cardNumber,
            })
            this.amountval=0;
            this.TransactionID=undefined;
            this.insurerID=undefined;
            this.cardNumber=undefined;
          }
        }
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=parseInt(data.amount);
    
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.tamt -=this.paymentsarray[i].amount;
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.tamt -=this.paymentsarray[i].amount;
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
