<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" >
        <div class="headerCover">
          <div class="headerTilte app_list_header" >
            <h5 class="m-0" class="mainHeadingStyle">Manage schedule</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <mat-tab-group [disableRipple]=true mat-align-tabs="start" [selectedIndex]="0" animationDuration="0ms">
          <mat-tab  label="Doctor" style="margin-left:7px;">
            <div class="row">
              <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                <p class="nodata" *ngIf="Get_Manage_arry.length == 0">No doctor(s) found</p>
                <table *ngIf="Get_Manage_arry.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                  <thead>
                    <tr>
                      <th style="width: 102px;">Reg id</th>
                      <th>Name</th>
                      <th style="width: 80px;">Work days</th>
                      <th>Session</th>
                      <th>Specialization</th>
                      <th>Status</th>
                      <!-- Future <th>Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let person of Get_Manage_arry" class="tdvalue">
                      <td >{{person.prov_id}}</td>
                      <td style="text-align: left;">{{person.full_name}}</td>
                      <td >{{person.days}} </td>
                      <td >{{person.day_session}}</td>
                      <td  >{{person.specialization}}</td>
                      <td >{{person.active}}</td>
                      <!-- Future <td  > <a (click)="delete_dotor(person.prov_id)" style="cursor:pointer"><img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon"/></a></td> -->

                      <!-- <td class="delete" >
                        <a (click)="Delete_bill(person.biill_type,person.amount_txt)"> <img
                            src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" alt=""></a>
                      </td>                  -->
                    </tr>
                  </tbody>
                </table>          
              </div>
            </div>
           </mat-tab>
          <mat-tab  label="Nurse" >
            <div class="row chwidth">
              <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                <p class="nodata" *ngIf="Get_Managenurse_arry.length == 0">No nurse(s) found</p>
                <table *ngIf="Get_Managenurse_arry.length" mdbTable datatable [dtOptions]="dtOptions"
                  class="table table-nowrap table-sm dataTable manage_table">
                  <thead>
                    <tr>
                      <th>Registration id</th>
                      <th>Name</th>
                      <th>Work days</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let person of Get_Managenurse_arry">
                      <td>{{person.nurse_prov_id}}</td>
                      <td style="text-align: left;">{{person.nurse_full_name}}</td>
                      <td >{{person.nurse_days}} </td>
                      <td >{{person.nurse_active}}</td>
                      <!-- <td  >{{person.fee}}</td> -->
                      <td ><a ng-click="Nurse_delete(person.nurse_prov_id)"><img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon"/></a></td>
                    </tr>
                  </tbody>
                </table>          
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Physiotherapist">
            <div class="row chwidth">
              <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                <p class="nodata" *ngIf="Get_Managephysio_arry.length == 0">No physiotherapist(s) found</p>
                <table *ngIf="Get_Managephysio_arry.length" mdbTable datatable [dtOptions]="dtOptions"
                  class="table table-nowrap table-sm dataTable manage_table">
                  <thead>
                    <tr>
                      <th>Registration id</th>
                      <th>Name</th>
                      <th >Work days</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let person of Get_Managephysio_arry">
                      <td>{{person.physio_prov_id}}</td>
                      <td style="text-align: left;">{{person.physio_full_name}}</td>
                      <td>{{person.physio_days}} </td>
                      <td class="{{person.active_style}}">{{person.physio_active}}</td>
                      <td>                   
                        <a ng-click="Physio_Delete(person.physio_prov_id)" style="cursor:pointer"><img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon"/></a></td>
                    </tr>
                  </tbody>
                </table>          
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>