import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Http, Headers } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { ScanDetected } from 'ngx-scanner-detection';
@Component({
  selector: 'app-recept-patient-list',
  templateUrl: './recept-patient-list.component.html',
  styleUrls: ['./recept-patient-list.component.css']
})
export class ReceptPatientListComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;
  public pagelist;
  public patientList = [];
  public sendMiddleName;
  public pageSize;
  public pageNavigate: boolean;
  public currentPage;
  public clientCount;
  public filterBy;
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public noOfPages: any;
  dtOptions: DataTables.Settings = {};
  public userInfo;
  public ipaddress;
  public currentLimit = 1;
  searchby: any;
  public typeflag: string;
  public patientName: string;
  public searchtext: string;
  patientid: boolean;
  public filteredArr: any[];

  constructor(public datepipe: DatePipe, public http: Http, public toastr: ToastrService, public https: HttpClient,
    public frontdeskservice: MenuViewService) {
    this.userInfo = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();
    this.pageNavigate = true;
    this.pagelist = "50";
    this.currentPage = 0;
    this.pageSize = 50;
    this.filterBy = "Patient Id";
    FrontDesk_Helper.setClientCount(null);
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 50,
      "lengthMenu": [[50, 100, 200, -1], [50, 100, 200, "All"]],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.typeflag = "name";
    this.searchtext = "search by name";
    this.changePageList();
  }

  changePageList() {
    this.pageSize = this.pagelist;
    this.currentPage = 1;
    this.getNoPatients();
    if (this.pagelist == "50") {
      if (this.clientCount > 50) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    } else if (this.pagelist == "100") {
      if (this.clientCount > 100) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    } else if (this.pagelist == "200") {
      if (this.clientCount > 200) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    }
  }

  pageLimitnext() {
    this.currentPage = this.currentPage + 1;
    this.pageSize = this.pagelist;
    this.filterBy = this.filterBy;
    this.getNoPatients();
    this.prevPageCount = false;

    if (this.currentPage == this.noOfPages)
      this.nextPageCount = true;
  }

  pageLimitPrevious() {//previous limit
    this.pageSize = this.pagelist;
    this.filterBy = this.filterBy;
    if (this.currentPage != 1)
      this.currentPage = this.currentPage - 1;

    this.getNoPatients();
    this.nextPageCount = false;
    if (this.currentPage == "1")
      this.prevPageCount = true;
  }

  getNoPatients() {//get no of patients
    if (FrontDesk_Helper.getClientCount() == null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gcount',
        JSON.stringify({
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.pageNavigate = obj.client_count > 50 ? false : true;
            this.clientCount = obj.client_count;
            FrontDesk_Helper.setClientCount(this.clientCount);
            if (Helper_Class.getPatientList() == undefined) {
              this.getPatientList();
            } else {
              this.patientList = Helper_Class.getPatientList();
            }
          },
          error => { });
    } else {
      this.clientCount = FrontDesk_Helper.getClientCount();
      if (Helper_Class.getPatientList() == undefined) {
        this.getPatientList();
      } else {
        this.patientList = Helper_Class.getPatientList();
      }
    }
  }

  getPatientList() {//get patient list
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gac',
      JSON.stringify({
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        country: ipaddress.country_code,
        imei: this.ipaddress,
        limit: 200,
        filterby: this.filterBy,
        type: "front-desk",
        start_no: this.currentLimit,
        prov_id: this.userInfo.user_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          //this.patientList = [];
          if (obj.clients != null) {
            for (var i = 0; i < obj.clients.length; i++) {
              if (obj.clients[i].middle_name != undefined && obj.clients[i].middle_name != "") {
                this.sendMiddleName = encrypt_decript.Decript(obj.clients[i].middle_name);
              } else {
                this.sendMiddleName = "";
              }
              var lname;
              if (obj.clients[i].last_name != undefined && obj.clients[i].last_name != "") {
                lname = encrypt_decript.Decript(obj.clients[i].last_name);
              } else {
                lname = "";
              }
              this.patientList.push({
                client_reg_id: obj.clients[i].client_reg_id,
                relation_id: obj.clients[i].relation_id,
                sub_rel_id: obj.clients[i].sub_rel_id,
                patient_id: obj.clients[i].patient_id,
                full_name: encrypt_decript.Decript(obj.clients[i].first_name) + " " + this.sendMiddleName + " " + lname,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                relationship_name: obj.clients[i].relationship_name,
              });
            }
            Helper_Class.setPatientList(this.patientList);
          }
        },
        error => { });
  }

  numberOfPages() {
    this.noOfPages = Math.ceil(this.clientCount / this.pagelist);
    return Math.ceil(this.clientCount / this.pagelist);
  }

  editClient(client) {
    var send_data = {
      client_id: client.client_reg_id,
      relation_id: client.relation_id,
      sub_rel_id: client.sub_rel_id,
      relationship_name: client.relationship_name,
    }
    Helper_Class.setClientData(send_data);
    this.frontdeskservice.sendMessage("manageclient");
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    console.log("scroll called")
    const element = this.scrollContainer.nativeElement;
    var stop = element.scrollTop;
    var oset = element.offsetHeight;
    var cval = stop + oset + 2;

    if (cval >= element.scrollHeight) {
      this.currentLimit += 200;
      this.getPatientList();
      console.log("reached end of scroll ", this.currentLimit)
    }
  }

  searchType(data) {
    this.searchby = data;
    if (data == "name") {
      this.typeflag = "name";
      this.patientName = "";
      this.searchtext = "search by name";

    } else if (data == "patientid") {
      this.typeflag = "patientid";
      this.patientName = "";
      this.searchtext = "search by patient id";
      this.patientid = true;

    } else {
      this.typeflag = "mobile";
      this.patientName = "";
      this.searchtext = "search by mobile";
    }
  }

  focusInput() {
    this.patientName = "";
    document.getElementById("filterInput").focus();
    this.input.nativeElement.focus();
  }

  getPatientListByName(name) {
    var send_data;
    if (this.typeflag == "patientid" && name.length > 8) {
      send_data = {
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        type: "hospital",
        name: this.patientName.toLocaleUpperCase(),
        filter: this.typeflag,
      }
      this.getPatients(send_data);

    } else if (name.length > 2 && this.searchby != "patientid") {
      send_data = {
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        type: "hospital",
        name: this.patientName.toLocaleUpperCase(),
        filter: this.typeflag,
      }
      this.getPatients(send_data);
    } else {
      this.patientList = Helper_Class.getPatientList();
      this.filteredArr = Helper_Class.getPatientList();
    }
  }

  getPatients(senddata){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.https.post(ipaddress.getIp.toString() + 'gen/gpbht', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("verify this " + JSON.stringify(obj))
          
          if (obj.clients != null) {
            this.patientList = [];
            this.filteredArr = [];
            for (var i = 0; i < obj.clients.length; i++) {
              var name;
              if (obj.clients[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
              } else {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
              }

              this.patientList.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                sub_rel_id: obj.clients[i].sub_rel_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
              });

              this.filteredArr.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                sub_rel_id: obj.clients[i].sub_rel_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
              });
            }
          }
        },
        error => { });
  }
}

