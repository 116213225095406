import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { ipaddress.getIp, ipaddress.Ip_with_img_address, ipaddress.country_code } from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Time_Formate_Session, Time_Formate } from '../../../assets/js/common';
import { TreatmentService } from '../service';
import { Subscription } from 'rxjs';
import { Helper_Class } from '../../helper_class';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { MatDialog } from '@angular/material/dialog';
import { VideocallComponent } from '../videocall/videocall.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-appoinmentview',
  templateUrl: './appoinmentview.component.html',
  styleUrls: ['./appoinmentview.component.css']
})
export class AppoinmentviewComponent implements OnInit {
  public apptFlag = 'min';
  subscription: Subscription;

  public f_name: string;
  public m_name: string;
  public l_name: string;
  public rel_type: string;
  public age_txt: string;
  public gen_txt: string;
  public bld_txt: string;
  public gur_txt: string;
  public inc_txt: string;
  public occ_txt: string;
  public cont_txt: string;
  public sym_txt: string;
  public treat_type: string;
  public spl_txt;
  public spl_id: string;
  public hosp_info: string;
  public app_info: string;
  public Client_reg: string;
  public doctor_id: string;
  public relation_id_data: string;
  public sub_rel_iddata: string;
  public footer_div: boolean;
  public CurrentDatetime: string;
  public pulse_txt: string;
  public cvs_txt: string;
  public rs_txt: string;
  public kidney_txt: string;
  public spo_txt: string;
  public arthritis_type: string;
  public deformities: string;
  public treatment: string;
  public home_care: string;
  public pay_type: string;
  public pat_info: string;
  public refer_txt: string;

  public case_sumbtn: Boolean;
  public writerx_btn: boolean;
  public hospital_details: boolean;
  public header_footer_flag: boolean;
  public Confirm_btn: boolean;
  public update_btn:boolean;
  public loader: boolean;
  public ortho_flag: boolean;

  public height_txt: any;
  public weight_txt: any;
  public txt_bmi: any;
  public weight_measure: string;
  public height_measure: string;
  public temparature: string;
  public txt_bp:string;
  public txt_spo: string;
  public pft_txt:string;
  public pat_id: string;
  
  public updflag:Boolean;
  public hasData: boolean;
  public general_hidden: boolean;

  public module_list = [];
  public Moduleidlist = []; 
  public symptom_data=[];
  public symptomlist=[];
  public symptoms_list=[];
  public selected_symptoms=[];
  public newsymp=[];

  public userinfo;
  public user_id;
  public hospital_info = [];
  public hospital_id;
  public specializations = [];
  public Appointment_Date;
  public appointment_list = [];
  public appt;
  public nodapp: boolean;
  public sub_rel
  public send_data;
  public show_list: boolean = false;
  public spl_txtname;
  public retreive_url: string;
  public app_clnt_fname: string;
  public app_clnt_mname: string;
  public app_clnt_lname: string;
  public app_age: string;
  public app_mobile: string;
  public app_gender: string;
  public app_blood_group: string;
  public app_relation: string;
  public app_father: string;
  public app_occup: string;
  public current_date;
  public current_time;
  public newAppointment_splitter: boolean = false;
  public pulm_flag:boolean;
  public patient_data;
  public show_splitter:boolean = false;

  constructor(public naviagationService:MenuViewService,public messageservice: TreatmentService, public messageservice1: CasesheetService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,public dialog: MatDialog,) {
    // localStorage.setItem("app_flow", "case_sheet");    // }
    Doc_Helper.setAppFlow("case_sheet");

    this.home_care = localStorage.getItem("home_care");
    this.case_sumbtn = true;
    this.loader = false;
    this.update_btn=true;
    this.general_hidden = true;
    this.ortho_flag = true;
    
    if (localStorage.getItem("sub_module_list") != null) {
      this.module_list = localStorage.getItem("sub_module_list").split(',');

      for (var i = 0; i < this.module_list.length; i++) {
        this.Moduleidlist.push(this.module_list[i]);
      }
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i] == "32") {
          this.case_sumbtn = false;
        }      
      }
    }
    this.height_measure="cms";
    this.weight_measure="kgs";
    this.hasData=false;
    //caseheet_flag 0 --> set doctor view page (modulelist) 
    if(Doc_Helper.getCasesheet_flag() == "0"){
      this.apptFlag = "max";
    }
  }

  ngOnInit(): void {
    this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.apptFlag = message;
      }
    });

    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.patient_data = Doc_Helper.getClient_Info();

    for (var i = 0; i < this.userinfo.hospitals.length; i++) {
      this.hospital_info[i] = this.userinfo.hospitals[i].hptl_clinic_id;
    }
    this.hospital_id = this.hospital_info[0];
    for (var j = 0; j < this.userinfo.specializations.length; j++) {
      this.specializations[j] = this.userinfo.specializations[j].spl_name;
    }
    this.pat_id = this.userinfo.patient_id;

    this.home_care = Helper_Class.getapp_flow();
    if (this.home_care == "1") {
      this.hospital_details = true;

    } else {
      this.hospital_details = false;
    }
    this.getCurrentDate();
  }

  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            this.Appointment_Date = this.CurrentDatetime;
          }
          this.get_doc_apps(this.user_id);
        },
        error => {
          
        }
      )
  }

  isReadonly() { return true; }

  get_app() {
    // Initializes a new instance of the StringBuilder class
    // and appends the given value if supplied
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp/',
     this.send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.first_name != null) {
            this.loader = true;

            if(obj.payment_type=="Online" && obj.payment_id != undefined){
              this.pay_type = obj.payment_type+" - paid";

            } if(obj.payment_type=="Online" && obj.payment_id == undefined){
              this.pay_type = obj.payment_type+" - yet to pay";

            } else if(obj.payment_type=="Cash"){
              this.pay_type = obj.payment_type;
            }

            this.f_name = encrypt_decript.Decript(obj.first_name);
            if (obj.middle_name != null) {
              this.m_name = encrypt_decript.Decript(obj.middle_name);
            }
            this.l_name = encrypt_decript.Decript(obj.last_name);

            if (obj.status == "1") {
              if (obj.token_count == "false") {
                this.Confirm_btn = false;
                this.update_btn=true;
              }
            }
            
            this.Client_reg = obj.client_reg_id;
            this.relation_id_data = obj.relation_id;

            if (obj.sub_rel_id != null) {
              this.sub_rel_iddata = obj.sub_rel_id;
            }

            if (obj.dob != null) {
              localStorage.setItem("Case_Clnt_dob", obj.dob);
            }

            this.rel_type = obj.relation;
            if(obj.age==0){
              this.age_txt = "<1";
            } else {
              this.age_txt = obj.age;
            }
            
            this.gen_txt = encrypt_decript.Decript(obj.gender);
            localStorage.setItem("client_gender", obj.gender);
            localStorage.setItem("Age_data", this.age_txt);
            if (obj.blood_desc != null) {
              this.bld_txt = obj.blood_desc;
            }

            if (obj.guardian != null) {
              this.gur_txt = encrypt_decript.Decript(obj.guardian);
            }

            if (obj.income != null) {
              this.inc_txt = encrypt_decript.Decript(obj.income);
            }

            if (obj.occupation != null) {
              this.occ_txt = encrypt_decript.Decript(obj.occupation);
            }
            this.cont_txt = encrypt_decript.Decript(obj.mobile);

            if (obj.symptoms != null) {
              this.sym_txt = obj.symptoms;
            }
            this.treat_type = obj.medicare_name;

            if (obj.status == "1") {
              this.Confirm_btn = false;
              this.update_btn = false;
              this.case_sumbtn = true;
              this.writerx_btn = true; 
              this.hasData = false;

            } else if (obj.status == "2") {
              this.Confirm_btn = true;
              this.update_btn = false;
              this.hasData = false;
              this.writerx_btn = false; 
              for (var i = 0; i < this.Moduleidlist.length; i++) {
                if (this.Moduleidlist[i] == "32") {
                  this.case_sumbtn = false;
                }      
              }

            } else {
              this.update_btn = true;
              this.hasData = true;
              this.case_sumbtn = true;
            }

            if (obj.medicare_name != null) {
              // localStorage.setItem("Medicare_name", obj.medicare_name);
            }

            if (obj.dr_middle_name != null && obj.dr_middle_name != undefined) {
              localStorage.setItem("doctor_name", obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name);

            } else {
              localStorage.setItem("doctor_name", obj.dr_first_name + " " + obj.dr_last_name);
            }
            localStorage.setItem("Locatiion", obj.location);

            if (obj.address2 != null && obj.address2 != "") {
              localStorage.setItem("address_data", obj.address1 + " " + obj.address2);

            } else {
              localStorage.setItem("address_data", obj.address1);
            }

            localStorage.setItem("city_zip", obj.city + " - " + obj.zipcode);
            localStorage.setItem("state_country", obj.state + " , " + obj.country);

            if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name) != undefined) {
              localStorage.setItem("client_name", encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name));

            } else {
              localStorage.setItem("client_name", encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name));
            }

            if (obj.hcm_country_id != null) {
              localStorage.setItem("clt_country", obj.hcm_country_id)
            }

            if (obj.hcm_state_id != null) {
              localStorage.setItem("clt_state", obj.hcm_state_id)
            }

            if (obj.hcm_city_id != null) {
              localStorage.setItem("clt_city", obj.hcm_city_id)
            }

            if (obj.hcm_location_id != null) {
              localStorage.setItem("clt_location", obj.hcm_location_id)
            }

            if (obj.location != null) {
              localStorage.setItem("clt_location_desc", obj.location)
            }
            var sb = new StringBuilder("")

            if (obj.hptl_name != null) {
              sb.append(obj.hptl_name);
              sb.append("\n");
              sb.append(obj.hosp_location);
              sb.append("\n");
              sb.append(obj.hosp_city);
              sb.append("\n");
              this.hosp_info = sb.toString();
            }

            var clntsb = new StringBuilder("")
            var address;
            if(obj.rel_address1 != undefined){
              if(obj.rel_address2 != undefined){
                address = encrypt_decript.Decript(obj.rel_address1)+" "+encrypt_decript.Decript(obj.rel_address2);
  
              } else {
                address = encrypt_decript.Decript(obj.rel_address1);
              }
            }
            
            if (obj.rel_address1 != undefined) {
              clntsb.append(address);
              clntsb.append("\n");
              clntsb.append(obj.location_desc);
              clntsb.append("\n");
              clntsb.append(obj.city_desc);
              clntsb.append("\n");
              clntsb.append(obj.state_desc);
              clntsb.append("\n");
              this.pat_info = clntsb.toString();
               
            } else {
              clntsb.append(obj.location_desc);
              clntsb.append("\n");
              clntsb.append(obj.city_desc);
              clntsb.append("\n");
              clntsb.append(obj.state_desc);
              clntsb.append("\n");
              this.pat_info = clntsb.toString();
            }

            var Appoint_data = new StringBuilder("");
            if (obj.app_type == "1") {
              Appoint_data.append("Appointment Type : New");
              Appoint_data.append("\n");

            } else {
              Appoint_data.append("Appointment Type : Follow-ups");
              Appoint_data.append("\n");
            }

            var get_date = obj.date.split('-');
            Appoint_data.append("Date : " + get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
            Appoint_data.append("\n");
            Appoint_data.append("Session : " + encrypt_decript.Decript(obj.session).toString());
            Appoint_data.append("\n");
            if (obj.token != null) {
              Appoint_data.append("Token no : " + obj.token);
              Appoint_data.append("\n");
            
            } else {
              var Temptimesplit = obj.f_time;
              var offTime = Temptimesplit.split(':');
              offTime[0] = offTime[0] % 12;
              var output = offTime.join(':');

              var get_time = output.split(":");
              if (get_time[0] == "0") {
                output = "12" + ":" + get_time[1];
              }
              Appoint_data.append("Time : " +
                Time_Formate_Session(output, encrypt_decript.Decript(obj.session)));
              Appoint_data.append("\n");
            }
            this.app_info = Appoint_data.toString();

            if (obj.date < this.CurrentDatetime) {
              this.toastr.error(Message_data.pastApptCantbeEdited);
              this.footer_div = true;
              this.case_sumbtn = true;

            } else if (obj.date > this.CurrentDatetime) {
              this.toastr.error(Message_data.futureapp);
              this.footer_div = true;
              this.case_sumbtn = true;

            } else if (obj.status == "0") {
              this.toastr.error(Message_data.completedApptCantedit);
              this.footer_div = true;
              this.case_sumbtn = true;

            } else if (obj.status == "1") {
              if (obj.token_count == "false") {
                this.Confirm_btn = false;
                this.case_sumbtn = true;
              }
            }

            this.temparature=obj.temparature;
            this.height_txt=obj.height;
            if(obj.height_measure != undefined && obj.height_measure != null){
              this.height_measure =obj.height_measure;
            }
            if(obj.weight_measure != undefined && obj.weight_measure !=null){
              this.weight_measure = obj.weight_measure;
            }

            this.weight_txt = obj.weight;
            this.txt_bmi = obj.bmi;
            this.txt_bp=obj.bp;
            this.txt_spo=obj.spo;
            if(obj.pft != undefined){
              this.pulm_flag = false;
              this.pft_txt=obj.pft;
            }else{
              this.pulm_flag = true;
            }
            this.pulse_txt=obj.pulse;
            if(obj.cvs != undefined){
              this.cvs_txt = obj.cvs;

            } else {
              this.cvs_txt="";
            }
            
            if(obj.rs !=undefined){
              this.rs_txt = obj.rs;

            } else {
              this.rs_txt = "";
            }

            if(obj.kidney != undefined){
              this.kidney_txt = obj.kidney;

            } else {
              this.kidney_txt = "";
            }
            
            if(obj.arthritis_type != undefined){
              this.arthritis_type = obj.arthritis_type;

            } else {
              this.arthritis_type = "";
            }
            
            if(obj.deformities != undefined){
              this.deformities = obj.deformities;  
            } else {
              this.deformities = "";
            }
            
            this.doctor_id = obj.doc_id;
            this.spl_id = obj.spl_id;
            this.spl_txt = obj.spl_name;
            if(obj.ref_by != undefined && obj.ref_by != ""){
              this.refer_txt= "Dr."+obj.ref_by;

            } else {
              this.refer_txt=null;
            }


          } else {
            this.loader = true;
            this.toastr.error(Message_data.unableToGetApptDet);
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  get_doc_apps(user_id): void {

    if (Doc_Helper.getClient_Info().AppFlow == "Walkin") {
      this.appointment_list = [];
      var sen_data = JSON.stringify({
        home_care: this.home_care,
        date: this.Appointment_Date,
        doc_prov_id: user_id,
        country: "IN",
        type: "doctor"
      });
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + 'appointment/getdocapp', sen_data, { headers: headers }).subscribe(
        response => {
          var data = response.json();
          
          if (data != null || data != undefined || data != "") {
            if (data.appointments != null) {
              this.appt = data.appointments;
              this.nodapp = false;
              for (var i = 0; i < this.appt.length; i++) {
                var get_age;
                if (this.appt[i].age != null && this.appt[i].age != undefined && this.appt[i].age != " ") {
                  get_age = this.appt[i].age;
                } else {
                  get_age = "";
                }
                var Patient_name;
                if (this.appt[i].middle_name != undefined && this.appt[i].middle_name != null) // middle name 
                {
                  Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].middle_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
                } else {
                  Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
                }
                // if(this.appt[i].pat_id != null){
                //   var patient_id = this.appt[i].pat_id;
                // }
                if (this.appt[i].mobile != null) {
                  var contactno = encrypt_decript.Decript(this.appt[i].mobile);
                }
                var profile_image = ipaddress.Ip_with_img_address + this.appt[i].profile_image;
                var lastvisit;
                if (this.appt[i].date != null) {
                  var last_visit = this.appt[i].date;
                  var gettmp = last_visit.split("-");
                  lastvisit = gettmp[2] + "-" + gettmp[1] + "-" + gettmp[0];
                }
                
                var status_txt;
                if (this.appt[i].status == "0") {
                  status_txt = "Completed";
                } else if (this.appt[i].status == "1") {
                  if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                    if (this.appt[i].f_time != "1") {
                      var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                      var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                      if (get_time > cur_time) {
                        status_txt = "Not visited";
                      } else {
                        status_txt = "Open";

                      }
                    } else {
                      var get_time = new Date(this.appt[i].date);
                      var cur_time = new Date(this.CurrentDatetime);
                      if (get_time >= cur_time) {
                        status_txt = "Open";
                      } else {
                        status_txt = "Not visited";
                      }
                    }
                  } else {
                    if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                      status_txt = "Open"; 
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else if (this.appt[i].status == "2") {
                  if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                    var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                    var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                    status_txt = "Confirmed";
                  } else {
                    if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                }
               
                if (this.appt[i].sub_rel_id != null) {
                  this.sub_rel = this.appt[i].sub_rel_id;

                } else {
                  this.sub_rel = "";
                }
                var dateofbirth
                if (this.appt[i].dob != null) {
                  dateofbirth = this.spiltdate(this.appt[i].dob);
                }
                if (this.appt[i].symptoms != null) {
                  var symptom = this.appt[i].symptoms
                }

                this.appointment_list.push({
                  patient_name: Patient_name,
                  patient_id: this.appt[i].pat_id,
                  age: get_age,
                  status: status_txt,
                  contact: contactno,
                  lastvisit: lastvisit,
                  profile_image: profile_image,
                  spl_id: this.appt[i].specialization_id,
                  hptl_clinic_id: this.appt[i].hptl_clinic_id,
                  doc_app_id: this.appt[i].doc_app_id,
                  gender_data: encrypt_decript.Decript(this.appt[i].gender),
                  medicare_name: this.appt[i].medicare_name,
                  client_reg_id: this.appt[i].client_reg_id,
                  relation_id: this.appt[i].relation_id,
                  sub_rel: this.sub_rel,
                  spl: this.appt[i].specialization,
                  relationship_name: this.appt[i].relationship_name,
                  dob_data: dateofbirth,
                  token: this.appt[i].token,
                  symptoms: symptom
                });
                // Helper_Class.setPatientAppointment(this.appointment_list)
                


              }

            }
            else {
              this.appointment_list = [];
              this.nodapp = true;
            }
          }
        });
    }
    else {
      var data = Helper_Class.getpatient_information();
      

      if (data != null || data != undefined || data != "") {
        if (data.appointments != null) {
          this.appt = data.appointments;
          this.nodapp = false;
          for (var i = 0; i < this.appt.length; i++) {
            var get_age;
            if (this.appt[i].age != null && this.appt[i].age != undefined && this.appt[i].age != " ") {
              get_age = this.appt[i].age;
            } else {
              get_age = "";
            }
            var Patient_name;
            if (this.appt[i].middle_name != undefined && this.appt[i].middle_name != null) // middle name 
            {
              Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].middle_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
            } else {
              Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
            }
            // if(this.appt[i].pat_id != null){
            //   var patient_id = this.appt[i].pat_id;
            // }
            if (this.appt[i].mobile != null) {
              var contactno = encrypt_decript.Decript(this.appt[i].mobile);
            }
            var profile_image = ipaddress.Ip_with_img_address + this.appt[i].profile_image;
            var lastvisit;
            if (this.appt[i].date != null) {
              var last_visit = this.appt[i].date;
              var gettmp = last_visit.split("-");
              lastvisit = gettmp[2] + "-" + gettmp[1] + "-" + gettmp[0];
            }
            var status_txt;
            if (this.appt[i].status == "0") {
              status_txt = "Completed";
            } else if (this.appt[i].status == "1") {
              if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                if (this.appt[i].f_time != "1") {
                  var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                  var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                  if (get_time > cur_time) {
                    status_txt = "Not visited";
                  } else {
                    status_txt = "Open";
                  }
                } else {
                  var get_time = new Date(this.appt[i].date);
                  var cur_time = new Date(this.CurrentDatetime);
                  if (get_time >= cur_time) {
                    status_txt = "Open";
                  } else {
                    status_txt = "Not visited";
                  }
                }
              } else {
                if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                  status_txt = "Open";
                } else {
                  status_txt = "Not visited";
                }
              }
            } else if (this.appt[i].status == "2") {
              if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                status_txt = "Confirmed";
              } else {
                if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                  status_txt = "Open";
                } else {
                  status_txt = "Not visited";
                }
              }
            }

            if (this.appt[i].sub_rel_id != null) {
              this.sub_rel = this.appt[i].sub_rel_id;

            } else {
              this.sub_rel = "";
            }
            var dateofbirth
            if (this.appt[i].dob != null) {
              dateofbirth = this.spiltdate(this.appt[i].dob);
            }
            if (this.appt[i].symptoms != null) {
              var symptom = this.appt[i].symptoms
            }

            this.appointment_list.push({
              patient_name: Patient_name,
              patient_id: this.appt[i].pat_id,
              age: get_age,
              status: status_txt,
              contact: contactno,
              lastvisit: lastvisit,
              profile_image: profile_image,
              spl_id: this.appt[i].specialization_id,
              hptl_clinic_id: this.appt[i].hptl_clinic_id,
              doc_app_id: this.appt[i].doc_app_id,
              gender_data: encrypt_decript.Decript(this.appt[i].gender),
              medicare_name: this.appt[i].medicare_name,
              client_reg_id: this.appt[i].client_reg_id,
              relation_id: this.appt[i].relation_id,
              sub_rel: this.sub_rel,
              spl: this.appt[i].specialization,
              relationship_name: this.appt[i].relationship_name,
              dob_data: dateofbirth,
              token: this.appt[i].token,
              symptoms: symptom
            });
            // Helper_Class.setPatientAppointment(this.appointment_list)
            


          }

        }
        else {
          this.appointment_list = [];
          this.nodapp = true;
        }
      }

    }
  }

  spiltdate(date) {
    var get_tmp = date.split("-");
    var date_appt = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    return date_appt;
  }

  getColor(color) {
    if (color == "Confirmed") {
      return "green";
    } else if (color == "Not visited") {
      return "red";
    } else if (color == "Completed") {
      return "gray";
    } else {
      return "violet";
    }
  }

  Doctor_View(list) {
    this.send_data = [];
    this.show_list = true;
    this.send_data = {
      doc_app_id: this.patient_data.app_id,
      type: "doctor",
      home_care: Doc_Helper.getHome_care(),
      country: ipaddress.country_code,
    }
    this.appGetdate();
    this.get_app();
    this.naviagationService.filter('');
    this.naviagationService.filter('detail_view');
  }

  getSpeciality() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/splbydoc/',
      JSON.stringify({
        doc_reg_id: this.userinfo.user_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if (obj.specialization != null) {
            for (var i = 0; i < obj.specialization.length; i++) {
              this.spl_txt.push({
                spl_id: obj.specialization[i].spl_id,
                spl_name: obj.specialization[i].spl_name,
              })
            }
            this.spl_txtname = this.spl_txt[0].spl_name;
          }
        },
        error => {

          //   let toast = this.toastCtrl.create({
          //     message: Message_data.getNetworkMessage(),
          //     duration: 2000,
          //     position: 'middle',
          //   });
          //   toast.present();
        }
      )
  }

  ChangeName() {
    document.getElementById("pname").style.color = "#2ca8fe";
  }

  //doctor_view_list
  get_appdetail() {
    var headers = new Headers;
    headers.append('Content-Type', 'application/json');
    this.http.post(this.retreive_url, JSON.stringify(this.send_data), { headers: headers }).subscribe(
      data => {
        
        // String Builder
        function StringBuilder(this: any, value) {
          this.strings = new Array("");
          this.append(value);
        }
        // Appends the given value to the end of this instance.
        StringBuilder.prototype.append = function (value) {
          if (value) {
            this.strings.push(value);
          }
        }
        // Clears the string buffer
        StringBuilder.prototype.clear = function () {
          this.strings.length = 1;
        }
        // Converts this instance to a String.
        StringBuilder.prototype.toString = function () {
          return this.strings.join("");
        }

        // Data set
        if (data.json().length != 0 && data.json() != null) // check weather data
        {
          this.app_clnt_fname = encrypt_decript.Decript(data.json().first_name);

          if (data.json().hcm_footer != null) {
            Doc_Helper.setHospLogo(null)
            Doc_Helper.setHospLogo(ipaddress.Ip_with_img_address + data.json().hptl_logo);
          }

          if (data.json().hcm_footer != null) {
            Doc_Helper.setHospFooter(null)
            Doc_Helper.setHospFooter(ipaddress.Ip_with_img_address + data.json().hcm_footer);

          } else {
            // Doc_Helper.setHospFooter(null);
          }

          if (data.json().hptl_pres_logo != undefined) {
            Doc_Helper.setHospLogo(null)
            localStorage.removeItem("hptl_pres_logo");
            Doc_Helper.setHospLogo(ipaddress.Ip_with_img_address + data.json().hptl_pres_logo)
            // localStorage.setItem("hptl_pres_logo",ipaddress.Ip_with_img_address+data.json().hptl_pres_logo);
          }

          if (data.json().bg_image != undefined) {
            Doc_Helper.setHospBgImage(null)
            Doc_Helper.setHospBgImage(ipaddress.Ip_with_img_address + data.json().bg_image);

          }
          // else {
          //   Doc_Helper.setHospBgImage(null);
          // }
          if (data.json().print_template != undefined) {
            Doc_Helper.setHospital_print_template(null)
            Doc_Helper.setHospital_print_template(data.json().print_template)
          }

          // localStorage.setItem("hospital_name", obj.hptl_name);
          // localStorage.setItem("print_template", data.json().print_template);

          // Button hide and show

         // Observation Data 
        }

      }, error => { }
    );
    
  }

  appGetdate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      response => {
        var data = response.json();
        this.current_date = data.current_date;
        this.current_time = data.current_time;
        var Date_splitter = this.current_date.split('-');
      },
      error => { }
    )
  }

  bmi_data() {
    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.txt_bmi = "";

    } else {
      if (this.height_txt != undefined) {
        if (this.height_measure == "cms") {
          this.height_txt = this.height_txt.replace(/[^0-9]/g, '');

        } else {
          this.height_txt = this.height_txt.replace('/[^-0-9\.]/g', '');

          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.height_measure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }

        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.weight_measure == "kgs" && this.height_measure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);


      } else if (this.weight_measure == "kgs" && this.height_measure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.txt_bmi = Math.round(to);


      } else if (this.weight_measure == "lbs" && this.height_measure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

      } else if (this.weight_measure == "lbs" && this.height_measure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);       
      }
    }
  }

  getSymptoms(){
    var searchString;
    if (this.sym_txt.indexOf(',') > -1) { 
      var dataval = this.sym_txt.split(","); 
      searchString = dataval[dataval.length-1];
    } else {
      searchString = this.sym_txt;
    }

    if(searchString.length >2){
      var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
          JSON.stringify({
            symp_name: searchString
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.symptoms != null) {
                this.symptoms_list = [];
                this.symptomlist = [];
                this.symptom_data = [];
                this.symptoms_list = obj.symptoms;
                for (var i = 0; i < obj.symptoms.length; i++) {
                  this.symptom_data.push(obj.symptoms[i].description);
                }
  
                this.symptomlist = this.symptom_data.filter(function (el) {
                  return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
                }.bind(this));
                
              }
            },
            error => {
              
            })
    }
  }

  select_symptom(data){
    for(var j=0;j<this.symptoms_list.length;j++){
      if(this.symptoms_list[j].description == data){
        this.selected_symptoms.push(data);
        break;
      }
    }
    
    if (this.sym_txt.indexOf(',') > -1) { 
      var dataval = this.sym_txt.split(","); 
      dataval.pop();
      this.sym_txt = dataval.join(",");
     
      this.sym_txt = this.sym_txt+","+data;
    } else {
      this.sym_txt = data;
    }
    this.symptomlist=[];    
  }
  
vedio(){
 
  const dialogRef1 = this.dialog.open(VideocallComponent, {
    width: '100%',
    height: '100%',
    data: {
      login_id: this.doctor_id,
      callee_id: this.Client_reg,
    }
  });
  dialogRef1.afterClosed().subscribe(result => {
    
    
   
  });
}

}

