import { Component, OnInit, HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-pediatrics-pregnancy-delivery',
  templateUrl: './pediatrics-pregnancy-delivery.component.html',
  styleUrls: ['./pediatrics-pregnancy-delivery.component.css']
})
export class PediatricsPregnancyDeliveryComponent implements OnInit {
  
  public pediapregdelFlag ="min";
  subscription:Subscription;
  public minflag:boolean=true;

  public medhist_array=[];
  public Medical_hist_array=[];
  public Medical_hist_flag:boolean=false;
  public Matenal_illness_flag:boolean=false;
  public matillness_array=[];
 
  public send_medical_array=[];
  public Maternal_array=[];
  public send_maternal_array=[];
  public header_footer_flag:boolean;
  public Mothers_age;
  public mother_agetxt;
  public noof_preg;
  public Delivery_type;
  public del_txt;
  public no_preg;
  public Gest_age;
  public gest_agedata;
  public surgical_hist;
  public Allergy_txt;
  public Feed_history;
  public Medical_hist;
  public Matenal_illness;
  public mother_disabled:boolean;
  public gestage_disabled:boolean;
  public delivery_disabled:boolean;
  public noof_pregdisabled:boolean;
  public maternal_disabled:boolean;
  public motherage_hidden:boolean;
  public gestation_agehidden:boolean;
  public delivery_hidden:boolean
  public noof_preghidden:boolean;
  public allergy_hidden:boolean;
  public surgical_hidden:boolean;
  public feeding_hidden:boolean;
  public maternal_hidden:boolean;
  public past_medicalhidden:boolean;

  public patient_list;
  public userinfo;
  public user_id;
  public client:boolean;

  constructor(public dialog:MatDialog,public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,
    private router:Router,public toastr:ToastrService,public masterCSdata:MasterCSHelperService) { 
    this.motherage_hidden=true;
    this.gestation_agehidden=true;
    this.delivery_hidden=true;
    this.noof_preghidden=true;
    this.allergy_hidden=true;
    this.surgical_hidden=true;
    this.feeding_hidden=true;
    this.maternal_hidden=true;
    this.past_medicalhidden=true;
  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.pediapregdelFlag = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.patient_list = Helper_Class.getInfo();
      this.client = true;
    }else{
      this.patient_list = Doc_Helper.getClient_Info();
      this.client = false;
    }
    
    var getHopitalfieldsRet=Helper_Class.getHopitalfieldsRet();
    if(getHopitalfieldsRet!=undefined){
      for(var i=0; i<getHopitalfieldsRet.length; i++){
        if(getHopitalfieldsRet[i].page_name=="Pregnancy & Delivery"){
          if(getHopitalfieldsRet[i].fields!=undefined){
            if(getHopitalfieldsRet[i].fields!=undefined){
              for(var j=0; j<getHopitalfieldsRet[i].fields.length;j++){
                if(getHopitalfieldsRet[i].fields[j].field_name=="Mother’s age at pregnancy"){
                  this.motherage_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Gestational age (weeks)"){
                  this.gestation_agehidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Delivery type"){
                  this.delivery_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="No of pregnancy"){
                  this.noof_preghidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Allergies"){
                  this.allergy_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Surgical history"){
                  this.surgical_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Feeding history"){
                  this.feeding_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Maternal illnesses"){
                  this.maternal_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Past medical history"){
                  this.past_medicalhidden=false;
                }
              }
            }
          }
        }
      }
    }

    if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
      await this.masterCSdata.getMedicalHistory();
      this.Get_Medical_history();
    }else{
      this.Get_Medical_history();
    }
  }
  
  //send data
  @HostListener('window:click', ['$event'])
    onclick(event: any): void {
        if(this.client == false){
            this.send_data();
        }
  }
  @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
        if(this.client == false){
            this.send_data();
        }  }


  send_data() {
    var Pedia_pregnancy = null;
    this.mother_agetxt = this.Mothers_age != undefined ? this.Mothers_age : undefined;
    this.gest_agedata = this.Gest_age != undefined ? this.Gest_age : undefined;
    this.del_txt = this.Delivery_type != undefined ? this.Delivery_type : undefined;
    this.no_preg = this.noof_preg != undefined ? this.noof_preg : undefined;

    Pedia_pregnancy = {
      mother_age: this.mother_agetxt,
      no_of_pregnancy: this.no_preg,
      delivery_type:this.del_txt,
      gestational_age:this.gest_agedata,
      surgical_history:this.surgical_hist,
      allergies:this.Allergy_txt,
      feeding_habit:this.Feed_history,
      medical_history:this.send_medical_array,
      maternal_illness:this.send_maternal_array,
    }
    Helper_Class.setPediadelivery(Pedia_pregnancy);
  }

  async Get_Medical_history() { //Get medical history data
    this.Medical_hist_array = [];
    var obj = masterCSData_Helper.getMasterMedicalHistory();
    if (obj.med_hist != null) {
      for (var i = 0; i < obj.med_hist.length; i++) {
        this.Medical_hist_array.push({
          type: 'checkbox',
          // multiple:true,
          value: obj.med_hist[i].med_hist_id,
          label: obj.med_hist[i].description,
          checked: false
        });
        if(obj.med_hist[i].med_hist_id == 32){
          this.Medical_hist = obj.med_hist[i].description;
        }
      }
    }
    if(masterCSData_Helper.getMasterMaternalIllness() == undefined){
      await this.masterCSdata.getMaternalIllness();
      this.Get_maternal_illness();
    }else{
      this.Get_maternal_illness();
    }
  }

  medical_change(selected) {
    this.Medical_hist="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_medical_array = selected;
  
    if (this.send_medical_array.length != 0) {
      var n = 0;
      for (var j = 0; j < this.send_medical_array.length; j++) {
        for (var i = 0; i < this.Medical_hist_array.length; i++) {
          if (this.send_medical_array[j] == this.Medical_hist_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Medical_hist_array[i].label);
          }
        }
      }
      this.Medical_hist = selected_data.toString();
    }
  }

  medical_popup(){
    if(this.client == false){
      this.Medical_hist_flag =true;

      for (var j = 0; j < this.Medical_hist_array.length; j++) {
            this.Medical_hist_array[j].checked = false;
      }
    
      if (this.send_medical_array != undefined && this.send_medical_array.length != 0) {
        for (var i = 0; i < this.send_medical_array.length; i++) {
          for (var j = 0; j < this.Medical_hist_array.length; j++) {
            if (this.send_medical_array[i] == this.Medical_hist_array[j].value) {
              this.Medical_hist_array[j].checked = true;
            }
          }
        }
      }
      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
                title: "Medical History",
                main_array: this.Medical_hist_array,
                selected_list:this.send_medical_array,
            }
      });
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.medical_change(result);
          }
      });
    }
  }

  Get_maternal_illness() {
    this.Maternal_array = [];
    var obj = masterCSData_Helper.getMasterMaternalIllness();
    if (obj.maternal_illnesses != null) {
      for (var i = 0; i < obj.maternal_illnesses.length; i++) {
        this.Maternal_array.push({
          type: 'checkbox',
          // multiple:true,
          value: obj.maternal_illnesses[i].ped_maternal_illnes_id,
          label: obj.maternal_illnesses[i].description,
          checked: false
        });
      }
    }
    this.get_client_data();
  }

  Maternal_change(selected) {
    this.Matenal_illness="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_maternal_array = selected;
   
    if (this.send_maternal_array.length != 0) {
      var n = 0;
      for (var j = 0; j < this.send_maternal_array.length; j++) {
        for (var i = 0; i < this.Maternal_array.length; i++) {
          if (this.send_maternal_array[j] == this.Maternal_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Maternal_array[i].label);
          }
        }
      }
      this.Matenal_illness = selected_data.toString();
    }
  }

  maternal_pop(){
    if(this.client == false){
      this.Matenal_illness_flag=true;
      for (var j = 0; j < this.Maternal_array.length; j++) {
        this.Maternal_array[j].checked = false;
      }
      if (this.send_maternal_array != undefined && this.send_maternal_array.length != 0) {
        for (var i = 0; i < this.send_maternal_array.length; i++) {
          for (var j = 0; j < this.Maternal_array.length; j++) {
            if (this.send_maternal_array[i] == this.Maternal_array[j].value) {
              this.Maternal_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
                title: "Maternal Illnesses",
                main_array: this.Maternal_array,
                selected_list:this.send_maternal_array,
              }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.Maternal_change(result);
          }
      });
    }
  }

  //Common data for all casesheets
  get_client_data() {
    var obj = Helper_Class.getAsg();
    Helper_Class.setpediaasg(obj);
    if (obj.medical_history != null) {
      for (var j = 0; j < obj.medical_history.length; j++) {
        this.send_medical_array.push(obj.medical_history[j]);
        this.medhist_array=this.send_medical_array;
      }
      this.medical_change(this.send_medical_array);
    }
    this.Get_pregnancy_retrivel();
  }

  Get_pregnancy_retrivel() {//get pediatrics pregnancy & delivery retrivel
    var getpediaRet= Helper_Class.getpediaRet();
    if (getpediaRet != undefined) {
      if (getpediaRet.mother_age != null) {
        this.Mothers_age=getpediaRet.mother_age;
        this.mother_disabled=true;
      }
      if (getpediaRet.gestational_age != null) {
        this.Gest_age=getpediaRet.gestational_age;
        this.gestage_disabled=true;
      }
      if (getpediaRet.delivery_type != null) {
        this.Delivery_type=getpediaRet.delivery_type;
        this.delivery_disabled=true;
      }
      if (getpediaRet.no_of_pregnancy != null) {
        this.noof_preg=getpediaRet.no_of_pregnancy;
        this.noof_pregdisabled=true;
      }
      if (getpediaRet.allergies != null) {
        this.Allergy_txt=getpediaRet.allergies;
      }
      if (getpediaRet.surgical_history != null) {
        this.surgical_hist=getpediaRet.surgical_history;
      }
      if (getpediaRet.feeding_habit != null) {
        this.Feed_history=getpediaRet.feeding_habit;
      }
     
      if (getpediaRet.medical_history != null && getpediaRet.medical_history !=undefined) {
        for (var j = 0; j < getpediaRet.medical_history.length; j++) {
          this.send_medical_array.push(
            getpediaRet.medical_history[j].med_hist_id
          )
          this.medhist_array=this.send_medical_array;
          this.medical_change(this.send_medical_array);
        }
      }

      if (getpediaRet.maternal_illness != null) {
        for (var j = 0; j < getpediaRet.maternal_illness.length; j++) {
          this.send_maternal_array.push(getpediaRet.maternal_illness[j].ped_maternal_illnes_id);
          this.matillness_array=this.send_maternal_array;
        }
        this.Maternal_change(this.send_maternal_array)
      }
      this.send_data();
    }
  }  
}
