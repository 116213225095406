import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { customjs } from '../../../assets/js/custom';
import { Subscription } from 'rxjs';
import { ChangePasswordComponent } from '../../../app/common-module/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress';
import { Admin_Helper } from '../../../app/Admin_module/Admin_Helper';
import { MasterHelperService } from '../../../app/Doctor_module/MasterHelper.service';
import { MasterCSHelperService } from '../../../app/Doctor_module/casesheet/masterCSData.service';
import { Message_data } from '../../../assets/js/Message_data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Diet_Helper } from '../../../app/Diet_module/Diet_Helper';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

declare var $ : any; 
@Component({
  selector: 'app-front-desk',
  templateUrl: './front-desk.component.html',
  styleUrls: ['./front-desk.component.scss']
})
export class FrontDeskComponent implements OnInit {
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;
  subscription: Subscription;

  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public profileImage;
  public firstName:string;
  public lastName:string;
  public selectedMenu;
  public displayPage;
  public currentDatetime;

  public settings:boolean = false;
  public permissionSubmenuFlag: boolean = false;
  public reportsSubmenuFlag:boolean = false;
  public minimizedAsied: boolean = false;
  public inpatientFlag: boolean = false;
  public manageAppointmentFlag: boolean = true;
  public viewPrescritionFlag: boolean = true;
  public billingChargesFlag: boolean = true;
  public billCreateFlag: boolean = true;
  public billEditFlag: boolean = true;
  public advanceFlag: boolean = true;
  public patientListFlag: boolean = true;
  public wardMasterFlag: boolean = true;
  public refund:boolean = true;
  public consolidatedBillingFlag: boolean =  true
  public inpatientMenuFlag:boolean = true;
  public consultantFlag:boolean = true;
  public billingFlag:boolean = false;
  public permissionsFlag:boolean = false;
  public reportsFlag:boolean = false;
  public hospBillsReportFlag:boolean = true;
  public diagBillsReportFlag:boolean = true;
  public inpatReportFlag: boolean = true;
  public reference_video: boolean = true;
  constructor(public http:HttpClient,public router:Router,public toastr:ToastrService,
    public messageservice:MenuViewService,public dialog:MatDialog,public masterData:MasterHelperService,public masterCSdata:MasterCSHelperService) { 
       FrontDesk_Helper.setBillFlag(undefined);
      Admin_Helper.setDiagBill(undefined);
      this.masterData.callMasterData();
      this.masterCSdata.clearValue();
    }

  ngOnInit(): void {
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
          this.menuNav(message);
      } 
    });

    this.getCurrentDate();
    this.userInfo= Helper_Class.getInfo();
    this.firstName=this.userInfo.first_name;
    this.lastName=this.userInfo.last_name;
    this.profileImage= ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.hospitalName=this.userInfo.hptl_name;
    this.hospitalLogo= ipaddress.Ip_with_img_address + this.userInfo.hptl_logo;
    this.minimizedAsied = false;
    // dynamic menu display
    var Moduleidlist;
    if (Helper_Class.getmodulelist() != null) {
       Moduleidlist = Helper_Class.getmodulelist();
       console.log("Moduleidlist" + JSON.stringify(Moduleidlist));
      this.manageAppointmentFlag = true;
      this.viewPrescritionFlag = true;
      this.billingChargesFlag = true;
      this.billCreateFlag = true;
      this.billEditFlag = true;
      this.advanceFlag = true;
      this.patientListFlag = true;
      this.wardMasterFlag = true;
      this.refund = true;
      this.consultantFlag = true;
      this.inpatientMenuFlag = true;
      this.permissionsFlag = true;
      this.hospBillsReportFlag = true;
      this.diagBillsReportFlag = true;
      this.inpatReportFlag = true;

      var bill:any = {};
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "15") {
          this.manageAppointmentFlag = false;
        }else if (Moduleidlist[i] == "38") {
          this.inpatientMenuFlag = false;
        }else if (Moduleidlist[i] == "44") {
          this.consultantFlag = false;
        }else if (Moduleidlist[i] == "52") {
          this.viewPrescritionFlag = false;
        } else if (Moduleidlist[i] == "53") {
          this.billingChargesFlag = false;
        }else if (Moduleidlist[i] == "56") {
          this.advanceFlag = false;
        } else if (Moduleidlist[i] == "57") {
          this.refund = false;
        }else if (Moduleidlist[i] == "58") {
          this.patientListFlag = false;
        } else if (Moduleidlist[i] == "59") {
          this.wardMasterFlag = false;
        } else if (Moduleidlist[i] == "60") {
          this.permissionsFlag = false;
        }else if (Moduleidlist[i] == "77") {
          this.consolidatedBillingFlag= false;
        } else if (Moduleidlist[i] == "65") {
          this.hospBillsReportFlag = false;
        }else if (Moduleidlist[i] == "66") {
          this.diagBillsReportFlag = false;
        }else if (Moduleidlist[i] == "68") {
          this.inpatReportFlag = false;
        }else if (Moduleidlist[i] == "28") { // Bill View
          Helper_Class.setBillView(null);
          Helper_Class.setBillView("1");
        }else if (Moduleidlist[i] == "54") { // Bill Create
          this.billCreateFlag = false;
          bill.create = Moduleidlist[i];
          FrontDesk_Helper.setBillFlag(bill);
          Helper_Class.setBillCreate(null);
          Helper_Class.setBillCreate("1");
        } else if (Moduleidlist[i] == "55") { // Bill Edit
          this.billEditFlag = false;
          bill.edit = Moduleidlist[i];
          FrontDesk_Helper.setBillFlag(bill);
          Helper_Class.setBillEdit(null);
          Helper_Class.setBillEdit("1");
        } else if (Moduleidlist[i] == "85") { // Bill Print
          Helper_Class.setBillPrint(null);
          Helper_Class.setBillPrint("1");
        } else if (Moduleidlist[i] == "46") {
          this.reference_video = false;
        }
      }

      if(this.hospBillsReportFlag == true && this.diagBillsReportFlag == true && this.inpatReportFlag == true){
        this.reportsFlag = true;
      }
    }
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      {country: ipaddress.country_code.toString()},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.current_date != null) 
            this.currentDatetime = obj.current_date;
          this.setDoctor('manageappointments');
        },error => {});
  }

  setAppoinmentHelper(e){
    if(e == "billing"){
      FrontDesk_Helper.setBillFromDate(this.currentDatetime);
      FrontDesk_Helper.setBillToDate(this.currentDatetime);
    }
  }

  setReport(reports){
    Helper_Class.setReportType(undefined);
    Helper_Class.setReportType(reports);
  }

  logout(name) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      {
        type: "front-desk",
        user: this.userInfo.user_id
      },
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },
        error => {});
  }

  menuNav(page){
    let page_nmae : string = page;
    this.selectedMenu = page;
    this.reportsSubmenuFlag = false;
    this.billingFlag = false;
    this.inpatientFlag = false;
    this.permissionSubmenuFlag = false;
    switch (page_nmae) {
      case 'home': 
        this.displayPage = "home";
      break;
      case 'manageappointments':  this.displayPage = page; break;
      case 'appcreate': this.displayPage = page;break ;
      case 'receptionistdoctordetailview': this.displayPage = page;break ;
      case 'receptviewprescription': this.displayPage = page;break ;
      case 'prescriptionlist': this.displayPage = page; break;
      case 'frontdeskbilling': 
      var send = {
        flow : "front-desk",
        id : this.userInfo.user_id
        }
        Helper_Class.setadminBillingFlow(send);
        this.displayPage = page; break;
      case 'receptbillcreate': this.displayPage = page; break;
      case 'receptbilledit':  this.displayPage = page; break;
      case 'receptbilldetail':  this.displayPage = page; break;
      case 'registration':  this.displayPage = page; break;
      case 'patient_list':  this.displayPage = page;break;
      case 'nurse_ward': this.permissionSubmenuFlag = false;this.displayPage = page; break;
      case 'nurse_patient': this.permissionSubmenuFlag = false;this.displayPage = page; break;
      case 'nurse_doctor': this.permissionSubmenuFlag = false;this.displayPage = page; break;
      case 'permission': 
                        this.permissionSubmenuFlag = !this.permissionSubmenuFlag;
                        if(this.minimizedAsied == true){
                          this.minimizing();
                        }
                        break;
      case 'billing':  
                      this.billingFlag = !this.billingFlag; if(this.minimizedAsied == true){
                        this.minimizing();
                      };
                      break;
      case 'advances':  this.displayPage = page;break;
      case 'bills':  this.displayPage = page;break;
      case 'billingDiagDetailsView':  this.displayPage = page;break;
      case 'billingDetailsView':  this.displayPage = page;break;
      case 'charges':  this.displayPage = page;break;
      case 'refund':  this.displayPage = page;break;
      case 'createRefund':  this.displayPage = page;break;
      case 'inpatient':  
                        this.inpatientFlag = !this.inpatientFlag; if(this.minimizedAsied == true){
                          this.minimizing();
                        };
                        break;
      case 'dischargeList':  this.displayPage = page;break;
      case 'Patients':  this.displayPage = page;break;
      case 'inPatientCreate':  this.displayPage = page;break;
      case 'consultant':  this.displayPage = page;break;
      case 'diaBillCreate':  this.displayPage = page;break;
      case 'diaAppDetail':  this.displayPage = page;break;
      case 'diaAppCreate':  this.displayPage = page;break;
      case 'wardmaster':  this.displayPage = page;break;  
      case 'viewIpatient': this.displayPage = page;break;  
      case 'diaBillingDetailsView': this.displayPage = page;break; 
      case 'ipatientBillCreate' : this.displayPage = page;break; 
      case 'consolidatedBills' : this.displayPage = page;break;  
      case 'dietappointments' : Diet_Helper.setAppFlow(undefined);this.displayPage = page;break; 
      case 'dietappointmentsdetailed' : this.displayPage = page;break;  
      case 'dietappointmentscreate' : this.displayPage = page;break;  
      case 'reports' :this.reportsSubmenuFlag = !this.reportsSubmenuFlag; 
                  if(this.minimizedAsied == true){
                    this.minimizing();
                  };
                  break;
      case 'hosp_bill_reports' : this.reportsSubmenuFlag = false;this.displayPage = page;break;  
      case 'diag_bill_reports' : this.reportsSubmenuFlag = false;this.displayPage = page;break;  
      case 'inpat_reports' : this.reportsSubmenuFlag = false;this.displayPage = page;break; 
      case 'reference_video' :this.displayPage = page;break; 
      case 'listPackage' :this.displayPage = page;break; 
      case 'createPackage' :this.displayPage = page;break; 
      case 'inpdischargeview' :this.displayPage = page;break; 
    }
    customjs.SelectMenuFun(this.selectedMenu,"front");
  }

  menuModelAction(action){
    if(action == 'profile'){
      //this.menuNav('home');

    }else if(action == 'changePassword'){
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {}
      });
    }else{
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
       {
          type: "front-desk",
          user: this.userInfo.user_id
        },
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut);
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => {});
    }
    $('#exampleModal').modal('hide'); 
  }

  minimizing(){
    this.minimizedAsied = !this.minimizedAsied;
    if(this.minimizedAsied ==  true){
      this.asied_width.nativeElement.setAttribute('style','width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style','width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style','padding: 12px 0 0 3px;}');
      this.permissionSubmenuFlag = false;
      this.reportsSubmenuFlag = false;
    }else{
      this.asied_width.nativeElement.setAttribute('style','width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style','width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style','padding: 17px 0 0 14px;}');
    }
  }

  onResize(event){
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimizedAsied = false;
  }

  openMenumodel(){
    this.minimizedAsied = false;
    customjs.openMobileMenuFun();
  }

  setDoctor(e){
    FrontDesk_Helper.setDate(undefined);
    if(e == "prescriptionlist"){
      FrontDesk_Helper.setDocPrescription(undefined);
      FrontDesk_Helper.setDate(this.currentDatetime);
    }else if(e == "manageappointments"){
      FrontDesk_Helper.setdoctor(undefined);
      FrontDesk_Helper.setDietician(undefined);
      FrontDesk_Helper.setDate(this.currentDatetime);
    }
  }
}
