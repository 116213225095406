import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import Quill from 'quill';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { ConvertTimeformat, Date_Formate, tConv24, Time_Formate } from '../../../assets/js/common';

import { CalendarOptions } from '@fullcalendar/angular';

@Component({
  selector: 'app-ot-booking',
  templateUrl: './ot-booking.component.html',
  styleUrls: ['./ot-booking.component.scss']
})
export class OtBookingComponent implements OnInit {
  public eventList: any = [];
  calendarOptions: CalendarOptions;

  handleDateClick(arg) {
    
  }

  editorStyle = { height: '250px' };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline',], // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
    ],

  };
  tools = {
    // imageResize: {},
    // syntax: {
    //   highlight: text => hljs.highlightAuto(text).value
    // },
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      // ["blockquote", "code-block"],

      // [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      // [{ script: "sub" }, { script: "super" }], // superscript/subscript
      // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      // [{ direction: "rtl" }], // text direction
      // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      // ["clean"], // remove formatting button
      // ["link", "image", "video"] // link and image, video
    ]
  };


  public userInfo;
  public hospitalDetails;
  public rowCount;
  public showCreateDetailFlag: boolean = false;
  public showCalendarViewFlag: boolean = true;
  public otListFlag: boolean = false;
  dtOptions: DataTables.Settings = {};
  public updateFlag: boolean;
  public otList: any = [];
  public otDataList: any = [];
  public spl_array: any = [];
  public surgeon_list: any = [];
  public surgery_list: any = [];
  public anaest_types: any = [];
  public anaest_doctors: any = [];

  public spl_id;
  public spl;
  public otId;
  public surgery_id: string;
  public anaesttype: string;
  public surgeryOut: string;
  public otNotes: string;
  public ansProc: string;
  public surgeryProc: string;
  public patName: string;
  public bookDate;
  public start_time1;
  public start_time2;
  public start_time3;
  public end_time1;
  public end_time2;
  public end_time3;
  public surgeons;
  public doc_reg_id;
  public surgery_proc;
  public surgery_date;
  public consultants;
  public surgery_min = [];
  public surgery_hrs = [];
  public inpatientList: any = [];
  public anaest_doc: string[] = [];
  public surg_doc: string[] = [];
  public consult_doc: string[] = [];
  public isPatUpd: boolean;
  public inPatientId;
  public patFirstName;
  public inPatientId2;
  public consultant;
  public viewValue;

  constructor(public http: HttpClient, public toastr: ToastrService) {
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.surgery_hrs.push("0" + i);
      } else {
        this.surgery_hrs.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.surgery_min.push("0" + i);
      } else {
        this.surgery_min.push(i);
      }
    }
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.hospitalDetails = Helper_Class.getHospitalId();

    this.patFirstName = Doc_Helper.getFirstname();
    this.inPatientId2 = Doc_Helper.getInpatientId();
    this.getOperationTheatres();
    this.getSurgeons();
    this.getAnaestesiaTypes();
    this.getAnaestisiaDoctors();
    this.getSpecializations();
    this.getOtData();

    this.surg_doc = [];
    this.anaest_doc = [];

    // if (Doc_Helper.getFirstname() != null) {
    // this.bookDate = this.inpatientList[0].bookDate;
    this.isPatUpd = false;

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    setTimeout(() => {
      this.calendarOptions = {
        themeSystem: 'bootstrap5',
        initialView: 'dayGridMonth',
        contentHeight: 600,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth, timeGridWeek, timeGridDay' // user can switch between the two
        },
        // dateClick: this.handleDateClick.bind(this), // bind is important!
        events: this.eventList

      };
    }, 2000);
  }

  changeOTNotes = (event) => {
    this.otNotes = event.html;
  }

  changeAnaeste = (event) => {
    this.ansProc = event.html;
  }

  goBack() {
    this.showCreateDetailFlag = false;
    this.showCalendarViewFlag = true;
    this.otListFlag = false;
    this.updateFlag = false;
    this.surg_doc = [];
    this.anaest_doc = [];
  }

  getOperationTheatres() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inpat/got", { hptl_clinic_id: this.hospitalDetails },
      { headers: headers }).subscribe(
        data => {
          this.otList = [];
          this.otList = JSON.parse(JSON.stringify(data)).ot_details;
        });
  }

  getInpatientsInOnit(patname, patid) {
    this.patName = patname;
    console.log("this.patName", this.patName);
    console.log("this.surgeon", this.surgeon_list);

    if (this.patName != "" && this.patName.length > 2) {
      var send_data = {
        first_name: encrypt_decript.Encript(this.patName.toLocaleUpperCase()).toString(),
        hptl_clinic_id: this.hospitalDetails,
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inpat/gipn", send_data,
        { headers: headers }).subscribe(
          data => {
            this.inpatientList = [];
            var jobj = JSON.parse(JSON.stringify(data)).inpatients;
            if (jobj != undefined) {
              for (var i = 0; i < jobj.length; i++) {
                var cname;
                if (jobj[i].middle_name != undefined) {
                  cname = encrypt_decript.Decript(jobj[i].first_name) + " " + encrypt_decript.Decript(jobj[i].middle_name) + " " + encrypt_decript.Decript(jobj[i].last_name);
                } else {
                  cname = encrypt_decript.Decript(jobj[i].first_name) + " " + encrypt_decript.Decript(jobj[i].last_name);
                }
                var surgtime, surgtime1, surgtime2;
                if (jobj[i].surgery_time != undefined) {
                  surgtime = jobj[i].surgery_time.split(":")
                  surgtime1 = surgtime[0];
                  surgtime2 = surgtime[1];
                }

                if (jobj[i].inpatient_id == patid) {
                  this.inpatientList.push({
                    inpatient_id: jobj[i].inpatient_id,
                    name: cname,
                    client_reg_id: jobj[i].client_reg_id,
                    doc_reg_id: jobj[i].doc_reg_id,
                    surgery_id: jobj[i].surgery_id,
                    surgery_proc: jobj[i].surgery_proc,
                    surgery_date: jobj[i].surgery_date,
                    start_time1: surgtime1,
                    start_time2: surgtime2,
                    surgeon: jobj[i].surgeon,
                    anaest_doc: jobj[i].anaest_doc,
                    anaesttype: jobj[i].anaesttype,
                    consultants: jobj[i].consultants,
                  })
                }
              }
              console.log("this.patName" + JSON.stringify(this.inpatientList));

              this.start_time1 = this.inpatientList[0].start_time1;
              this.start_time2 = this.inpatientList[0].start_time2;
              this.start_time3 = this.inpatientList[0].start_time3;
              this.surgeons = this.inpatientList[0].surgeon;
              this.doc_reg_id = this.inpatientList[0].doc_reg_id;
              this.surgery_id = this.inpatientList[0].surgery_id;
              this.surgery_proc = this.inpatientList[0].surgery_proc;
              this.surgery_date = this.inpatientList[0].surgery_date;
              this.anaest_doc = this.inpatientList[0].anaest_doc;
              this.anaesttype = this.inpatientList[0].anaesttype;
              this.consultants = this.inpatientList[0].consultants;
              this.inPatientId = this.inpatientList[0].inpatient_id;

              if (this.surgeon_list.length != 0 && this.inpatientList[0].surgeon != undefined) {
                this.surg_doc = this.inpatientList[0].surgeon.split(",");

                console.log("this.surg_doc", this.surg_doc)
              }

              if (this.anaest_doctors.length != 0 && this.inpatientList[0].anaest_doc != undefined) {
                this.anaest_doc = this.inpatientList[0].anaest_doc.split(",");

                console.log("this.anaest_doc", this.anaest_doc)
              }

              if (this.surgeon_list.length != 0 && this.inpatientList[0].consultants != undefined) {
                this.consultant = this.inpatientList[0].consultants.split(",");

                console.log("this.anaest_doc", this.anaest_doc)
              }
            }
          });

    }

  }

  getInpatients(e) {
    this.patName = e.target.value.toString();
    console.log("this.patName", this.patName);
    if (this.patName != "" && this.patName.length > 2) {
      var send_data = {
        first_name: encrypt_decript.Encript(this.patName.toLocaleUpperCase()).toString(),
        hptl_clinic_id: this.hospitalDetails,
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inpat/gipn", send_data,
        { headers: headers }).subscribe(
          data => {
            this.inpatientList = [];
            var jobj = JSON.parse(JSON.stringify(data)).inpatients;
            console.log("jobj", jobj);

            if (jobj != undefined) {
              for (var i = 0; i < jobj.length; i++) {
                var cname;
                if (jobj[i].middle_name != undefined) {
                  cname = encrypt_decript.Decript(jobj[i].first_name) + " " + encrypt_decript.Decript(jobj[i].middle_name) + " " + encrypt_decript.Decript(jobj[i].last_name);
                } else {
                  cname = encrypt_decript.Decript(jobj[i].first_name) + " " + encrypt_decript.Decript(jobj[i].last_name);
                }
                var surgtime, surgtime1, surgtime2;
                if (jobj[i].surgery_time != undefined) {
                  surgtime = jobj[i].surgery_time.split(":")
                  surgtime1 = surgtime[0];
                  surgtime2 = surgtime[1];
                }

                this.inpatientList.push({
                  inpatient_id: jobj[i].inpatient_id,
                  name: cname,
                  client_reg_id: jobj[i].client_reg_id,
                  doc_reg_id: jobj[i].doc_reg_id,
                  surgery_id: jobj[i].surgery_id,
                  surgery_proc: jobj[i].surgery_proc,
                  surgery_date: jobj[i].surgery_date,
                  start_time1: surgtime1,
                  start_time2: surgtime2,
                  surgeon: jobj[i].surgeon,
                  anaest_doc: jobj[i].anaest_doc,
                  anaesttype: jobj[i].anaesttype,
                  consultants: jobj[i].consultants,
                })

                console.log("getInpatients(e)", this.inpatientList)
              }
            }
          });
    }

  }

  selectInpatients(inpat) {
    this.patName = inpat.name;
    this.bookDate = inpat.bookDate;
    this.start_time1 = inpat.start_time1;
    this.start_time2 = inpat.start_time2;
    this.start_time3 = inpat.start_time3;
    this.surgeons = inpat.surgeon;
    this.doc_reg_id = inpat.doc_reg_id;
    this.surgery_id = inpat.surgery_id;
    this.surgery_proc = inpat.surgery_proc;
    this.surgery_date = inpat.surgery_date;
    this.anaest_doc = inpat.anaest_doc;
    this.anaesttype = inpat.anaesttype;
    this.consultants = inpat.consultants;
    this.inPatientId = inpat.inpatient_id;

    console.log("this.surgeons", this.surgeons)

    // if (inpat.surgeon != undefined) {
    //   if (this.surgeon_list.length != 0 && inpat.surgeon != undefined) {
    //     this.surg_doc = this.surgeons.split(",");
    //     this.consultant = this.surgeons;
    //   }
    // }

    if (inpat.surgeon != undefined) {
      this.surg_doc = inpat.surgeon.split(",");
      if (this.surgeon_list.length != 0 && inpat.surgeon != undefined) {
        var surgdoc = inpat.surgeon.split(",");
        for (var i = 0; i < surgdoc.length; i++) {
          this.surg_doc.push(surgdoc[i])
        }
      }
    }

    if (inpat.consultant != undefined) {
      this.consult_doc = inpat.consultant.split(",");
      if (this.surgeon_list.length != 0 && inpat.consultant != undefined) {
        var consultdoc = inpat.consultant.split(",");
        for (var i = 0; i < consultdoc.length; i++) {
          this.consult_doc.push(consultdoc[i])
        }
      }
    }

    if (inpat.anaest_doc != undefined) {
      this.anaest_doc = inpat.anaest_doc.split(",");
      if (this.anaest_doctors.length != 0 && inpat.anaest_doc != undefined) {
        var andoc = inpat.anaest_doc.split(",");
        for (var i = 0; i < andoc.length; i++) {
          this.anaest_doc.push(andoc[i])
        }
      }
    }


  }

  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/gsplbyhptl", { hptl_clinic_id: this.hospitalDetails },
      { headers: headers }).subscribe(
        data => {
          this.spl_array = [];
          var obj = JSON.parse(JSON.stringify(data));

          if (obj.specializations != null) {
            this.spl_array = obj.specializations;
            this.spl_id = this.spl_array[0].spl_id;
            this.spl = this.spl_array[0].spl_name;
            // this.getDoctorList(this.spl_id)
            this.getSurgeries();
            this.getSurgeons();
          }
        },
        error => {
          this.toastr.error("Unable to fetch country details, try again later");
        })
  }

  getSurgeons() {
    var send_data = {
      hospital_id: this.hospitalDetails
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'search/docbyhns', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          // console.log("Surgeons "+JSON.stringify(obj))

          if (obj != null) {
            this.surgeon_list = [];
            for (var i = 0; i < obj.doctors.length; i++) {
              var docname;
              if (obj.doctors[i].middle_name != undefined) {
                docname = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;

              } else {
                docname = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
              }
              this.surgeon_list.push({
                docname: docname,
                prov_id: obj.doctors[i].prov_id,

              })
            }
            this.getInpatientsInOnit(this.patFirstName, this.inPatientId2);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  Change_Specialization(e) {
    this.spl_id = e;
    for (var i = 0; i < this.spl_array.length; i++) {
      if (this.spl_array[i].spl_id == this.spl_id) {
        this.spl = this.spl_array[i].spl_name;
        this.spl_id = this.spl_array[i].spl_id;
        this.getSurgeries();
      }
    }
  }

  getSurgeries() {
    var send_data = {
      spl_id: this.spl_id,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'cardio/medproc', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          // console.log("getSurgeries "+JSON.stringify(obj))

          if (obj != null) {
            this.surgery_list = [];
            for (var i = 0; i < obj.med_procedures.length; i++) {
              this.surgery_list.push({
                surgery_id: obj.med_procedures[i].procedure_id,
                surgery_name: obj.med_procedures[i].description,
              })
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getAnaestesiaTypes() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "inpat/gat",
      { headers: headers }).subscribe(
        data => {
          this.anaest_types = [];
          var obj = JSON.parse(JSON.stringify(data));
          this.anaest_types = obj.anaesthesia_types;
        },
        error => {
          this.toastr.error("Unable to fetch country details, try again later");
        })
  }

  getAnaestisiaDoctors() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inpat/gad", { hptl_clinic_id: this.hospitalDetails },
      { headers: headers }).subscribe(
        data => {
          this.anaest_doctors = [];
          var obj = JSON.parse(JSON.stringify(data));
          this.anaest_doctors = obj.anaesthesia_doctors;
          console.log("ANAESTESIA EFFECTS " + JSON.stringify(this.anaest_doctors))

        },
        error => {
          this.toastr.error("Unable to fetch country details, try again later");
        })
  }

  saveDetails(type) {

    var start_time;
    if (this.start_time1 != undefined && this.start_time2 != undefined) {
      start_time = ConvertTimeformat("", this.start_time1 + ":" + this.start_time2 + " " + this.start_time3);
    } else {
      start_time = "";
    }

    var end_time;
    if (this.end_time1 != undefined && this.end_time2 != undefined) {
      end_time = ConvertTimeformat("", this.end_time1 + ":" + this.end_time2 + " " + this.end_time3);
    } else {
      end_time = "";
    }

    var send_data;
    send_data = {
      ot_id: this.otId,
      inpatient_id: this.inPatientId,
      booking_date: this.surgery_date,
      from_time: start_time,
      to_time: end_time,
      is_patient_updated: this.isPatUpd,
      country: ipaddress.country_code.toString(),
      surgery_id: this.surgery_id,
      surgery_proc: this.surgeryProc,
      surgery_date: this.surgery_date,
      surgeon: this.surg_doc,
      anaest_doc: this.anaest_doc
    }

    console.log("saveDetails", send_data);

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/sotb', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('save', JSON.stringify(obj));
          if (obj.key == "1") {
            this.toastr.success(obj.result);
            // this.getOtList();
          } else {
            this.toastr.error(obj.result);
          }
        });

  }

  createBooking() {
    this.showCreateDetailFlag = true;
    this.showCalendarViewFlag = false;
    this.otListFlag = false;
  }

  getOtData() {
    this.otDataList;
    var send_data = {
      hptl_clinic_id: this.hospitalDetails,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gotd', send_data,
      { headers: headers })
      .subscribe(
        response => {
          this.otDataList = [];
          this.eventList = [];
          var obj = JSON.parse(JSON.stringify(response)).otbooking_details;
          if (obj != undefined) {

            // console.log("this.otDataList ", obj);
            for (var i = 0; i < obj.length; i++) {
              var patName = encrypt_decript.Decript(obj[i].first_name)

              var fromTimeList, fromTime, toTimeList, toTime;
              if (obj[i].from_time != undefined) {
                fromTimeList = obj[i].from_time.split(":")
                fromTime = fromTimeList[2];
              }
              if (obj[i].to_time != undefined) {
                toTimeList = obj[i].to_time.split(":")
                toTime = fromTimeList[2];
              }

              this.eventList.push({
                title: patName,
                start: obj[i].booking_date + 'T' + obj[i].from_time,
                end: obj[i].booking_date + 'T' + obj[i].to_time,
              })

              var bdate;
              if(obj[i].booking_date != undefined){
                var bookdate = obj[i].booking_date.split("-");
                bdate = bookdate[2]+"-"+bookdate[1]+"-"+bookdate[0];
              }
              this.otDataList.push({
                patFirstName: patName,
                ot_name: obj[i].ot_name,
                booking_date: bdate,
                booking_id: obj[i].booking_id,
                from_time: fromTime,
                to_time: toTime,
                profile_image: obj[i].profile_image
              })

            }
            console.log("this.eventList", this.eventList);
            console.log("this.otDataList ", this.otDataList);
          }
        });
  }

  getOtList(ot) {
    // this.wardViewdata = [];
    var send_data = {
      hptl_clinic_id: this.hospitalDetails,
      booking_id: ot.booking_id,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gotbd', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != undefined && obj != null && obj != "") {

            console.log("OT Details", obj);
            this.showCreateDetailFlag = true;
            this.surgeons = obj.surgeon;
            // this.doc_reg_id = this.inpatientList[0].doc_reg_id;
            this.surgery_id = obj.surgery_id;
            this.surgery_proc = obj.surgery_proc;
            this.surgery_date = obj.surgery_date;
            this.anaest_doc = obj.anaest_doc;
            // this.anaesttype = this.inpatientList[0].anaesttype;
            // this.consultants = this.inpatientList[0].consultants;
            this.inPatientId = obj.inpatient_id;
            this.otId = obj.ot_id;

            if (this.surgeon_list.length != 0 && obj.surgeon != undefined) {
              this.surg_doc = obj.surgeon.split(",");

              console.log("this.surg_doc", this.surg_doc)
            }

            if (this.anaest_doctors.length != 0 && obj.anaest_doc != undefined) {
              this.anaest_doc = obj.anaest_doc.split(",");

              console.log("this.anaest_doc", this.anaest_doc)
            }

            if (obj.is_patient_updated == '1') {
              this.isPatUpd = true;
            } else {
              this.isPatUpd = false;
            }

            if (obj.middle_name != undefined) {
              this.patName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);

            } else {
              this.patName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }

            if (obj.from_time != undefined) {
              var fromTimeList = obj.from_time.split(':');

              this.start_time2 = fromTimeList[1];
              if (fromTimeList[0] > 12) {
                this.start_time3 = 'PM';
                this.start_time1 = ('0' + (fromTimeList[0] - 12));
              } else {
                this.start_time3 = 'AM'
                this.start_time1 = fromTimeList[0];
              }
              // this.start_time3 = fromTimeList[2];
              console.log("fromTimeList", fromTimeList)
            }

            if (obj.to_time != undefined) {
              var toTimeList = obj.to_time.split(':');

              this.end_time2 = toTimeList[1];
              if (toTimeList[0] > 12) {
                this.end_time3 = 'PM';
                this.end_time1 = ('0' + (toTimeList[0] - 12));
                console.log("toTimeList", toTimeList[0])
              } else {
                this.end_time3 = 'AM'
                this.end_time1 = toTimeList[0];
              }

            }
            // if (this.surgeon_list.length != 0 && this.inpatientList[0].consultants != undefined) {
            //   this.consultant = this.inpatientList[0].consultants.split(",");

            //   console.log("this.anaest_doc", this.anaest_doc)
            // }
          }
        });
  }

  // viewWardDetail(ward){
  //   this.showCreateDetailFlag = true;
  //   this.bedTableCount = [];
  //   this.wardName = ward.ward_name;
  //   this.floor = ward.floor;
  //   this.nursingCharges = ward.nursing_charges;
  //   this.wardId = ward.ward_id;
  //   this.updateFlag = this.wardId != undefined && this.wardId != null ? true:false;
  //   var bedDetails = ward.bed_details;
  //   for(var i= 0; i < bedDetails.length; i++){
  //     this.bedTableCount.push({
  //       ward_bed_id:bedDetails[i].ward_bed_id,
  //       bed_no:bedDetails[i].bed_no,
  //       bed_type:bedDetails[i].bed_type,
  //       rate:bedDetails[i].rate,
  //     });
  //   }

  // }

  calendarView() {
    this.showCalendarViewFlag = true;
    this.showCreateDetailFlag = false;
    this.otListFlag = false;
  }

  changeView(e) {
    this.viewValue = e.target.value;
    console.log(this.viewValue);

    if (this.viewValue == "Calendar") {
      this.showCalendarViewFlag = true;
      this.showCreateDetailFlag = false;
      this.otListFlag = false;
    } else {
      this.showCalendarViewFlag = false;
      this.showCreateDetailFlag = false;
      this.otListFlag = true;
    }
  }


}
