import { Component, OnInit,ViewChild ,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-insurancebill',
  templateUrl: './insurancebill.component.html',
  styleUrls: ['./insurancebill.component.scss']
})
export class InsurancebillComponent implements OnInit {
  @ViewChild('printsection') printContainer: ElementRef;
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;

  public Filter_txt;
  public From_show: boolean = true;
  public To_show: boolean = true;
  public f_date;
  public t_date;
  public userinfo;
  public appoint_txt;
  public hptl_clinic_id;
  public appointment_list:any = [];
  public insuranceBill_list:any = [];
  public billAmt_List = [];
  public currentDate;
  public CurrentDatetime;
  public Appoint_Date;
  public bill_date;
  public Docidname;
  public hospital_name;
  public hospitalAddress;
  Hospital_location: string;
  public bill_type;
  public docBills = [];
  public medBills = [];
  public diagBills = [];
  public DisplayBills = [];
  public final_amount = "0.00";
  public Advance_bill = "0.00";
  public total_advance = "0.00";
  public balance_show: boolean = true;
  public paid_bill = "0.00";
  public remaing_billbal = "0.00";
  public sendDoctor = [];
  public sendPharmacy = [];
  public sendDiagnosis = [];
  public pharma_id;
  public diag_centre_id;
  public tempPaid = "0.00";
  public totalBillAmount = "0.00";
  public totalPaidAmount = "0.00";
  public TotalBalance = "0.00";
  public selectedAllFlage: boolean = false;
  public patient_name;
  public amount_collected;
  public previous_balshow;
  public balance_data;
  public dog_reg_id;
  public total_collected;
  public billing_print_array = [];
  public insuranceFlag: boolean = false;
  public insuranceDec;
  public hospital_logo;
  public mediBillAmount = "0.00";
  public client_id;
  public sub_id;
  public rel_id;
  public tempparray;
  public saveFlag: boolean = true;
  public totalDiscount = "0.00";
  public totalBill = "0.00";
  public totalPaid = "0.00";
  public patientList = []
  public admissionDate = "";
  public admissionTime = "";
  public dischargeDate = "";
  public dischargeTime = "";
  public app_id;
  public inpid;
  public insurer;
  public insCardNo;
  public policyno;
  public CCNno;
  public serviceNo;
  public panno;
  public uhid;
  public age;
  public gender;
  public diagnosis;
  public treat_proc;
  public patname;
  public billno;
  public docname;
  public total:number=0;
  public amountinword:string;
  public printTemplate: string;
  public hptlbillLogo:string;
  public billlogo_style:string;
  public billDetails:any=[];
  public consultarray:any=[];
  public insuranceBillFlag: boolean = true;
  public showandhideFlag: boolean = false;
  public hptlName;
  public address;
  public doctorLocation;
  public doctorCity;
  public doctorState;
  public doctorCountry;
  public doctorTelephone;

  constructor(public doctorservice: MenuViewService, public toastr: ToastrService, public http: Http, public router: Router, public messageservice: MenuViewService) { }

  async ngOnInit() {
    this.userinfo = Helper_Class.getInfo();
    var hospitaldet = Helper_Class.getHospital();
    this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    this.hptlbillLogo = ipaddress.Ip_with_img_address + this.userinfo.bill_print_logo;
    this.billlogo_style=this.userinfo.bill_print_logo_style;
    this.printTemplate = this.userinfo.bill_print_template;
    
    this.hptlName=this.userinfo.hospitals[0].hptl_name;
    if (this.userinfo.hospitals[0].address2 != undefined && this.userinfo.hospitals[0].address1 != undefined) {
      this.address = this.userinfo.hospitals[0].address1 + ", " + this.userinfo.hospitals[0].address2;
    } else if (this.userinfo.hospitals[0].address1 != undefined) {
      this.address = this.userinfo.hospitals[0].address1;
    } 
    
    this.doctorLocation=this.userinfo.hospitals[0].location_desc;
    this.doctorCity=this.userinfo.hospitals[0].city_desc;
    this.doctorState=this.userinfo.hospitals[0].state_desc;
    this.doctorCountry=this.userinfo.hospitals[0].country_desc;
    this.doctorTelephone=this.userinfo.hospitals[0].telephone;

    // get the appointment list
    this.bill_type = "doctor";
    this.Filter_txt = "All";
    this.Filter_change();
    this.Get_current_date();
  }

  Filter_change() {
    if (this.Filter_txt == "All") {
      this.From_show = true;
      this.To_show = true;
    } else if (this.Filter_txt == "Date") {
      this.From_show = false;
      this.To_show = false;
    }
  }

  Get_current_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.Appoint_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            this.currentDate = obj.current_date;
            this.Appoint_Date = this.CurrentDatetime;
            this.f_date = obj.current_date;
            this.t_date = obj.current_date;
          }
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  OnDateChangedfrom(e) {
    this.f_date = e;
  }

  OnDateChangedto(e) {
    this.t_date = e;
  }

  async filter_click() {
    if(this.appoint_txt != undefined){
      this.Appointmnet_change(this.client_id, this.rel_id, this.sub_id, this.tempparray)
    } else {
      this.toastr.error("enter patient details")
    }
  }

  getAppointments(patientList) {
    this.appointment_list = [];
    this.insuranceBill_list = [];
    this.patientList = [];
    if (patientList.target.value.toString() != null && patientList.target.value.toString().length > 2) {
      var headers = new Headers();
      var name = patientList.target.value.toUpperCase();
      var send_data = JSON.stringify({
        hptl_clinic_id: this.hptl_clinic_id,
        first_name: name
      });
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/inpa', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("INPATIENTS ", JSON.stringify(obj))

            if (obj.appointment != null) {
              for (var i = 0; i < obj.appointment.length; i++) {
                obj.appointment[i].middle_name
                if (obj.appointment[i].middle_name != undefined) {
                  this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";

                } else {
                  this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                }

                var sessiondata = null;
                if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                  sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
                }
                var address;
                if (obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                  address = encrypt_decript.Decript(obj.appointment[i].address1) + ", " + encrypt_decript.Decript(obj.appointment[i].address2)
                } else if (obj.appointment[i].address1 != undefined) {
                  address = encrypt_decript.Decript(obj.appointment[i].address1);
                } else {
                  address = "";
                }
                var hospital;
                if (obj.hosp_address2 != undefined) {
                  hospital = obj.hosp_address1 + "," + obj.hosp_address2;

                } else {
                  hospital = obj.hosp_address1;
                }
                var inpatientId = obj.appointment[i].inpat_id != undefined ? obj.appointment[i].inpat_id : "-";
                var zipcode = obj.appointment[i].zipcode != undefined ? encrypt_decript.Decript(obj.appointment[i].zipcode) : "";
                this.hospital_name = obj.hosp_name != undefined ? obj.hosp_name : "";
                this.hospitalAddress = hospital;
                this.Hospital_location = obj.hosp_location + ", " + obj.hosp_district + " - " + obj.hosp_state + " " + obj.hosp_country + "  " + obj.hosp_zip;

                var clgender;
                if(obj.appointment[i].gender != undefined){
                  clgender = encrypt_decript.Decript(obj.appointment[i].gender);
                  if(clgender =="Male"){
                    clgender="M"
                  } else if(clgender =="Female"){
                    clgender="F"
                  } else {
                    clgender="N"
                  }
                }

                var docname;
                if(obj.appointment[i].dr_middle_name != undefined){
                  docname = "Dr."+obj.appointment[i].dr_first_name+" "+obj.appointment[i].dr_middle_name+" "+obj.appointment[i].dr_last_name;
                } else {
                  docname = "Dr."+obj.appointment[i].dr_first_name+" "+obj.appointment[i].dr_last_name;
                }
                if(obj.appointment[i].doc_qualif != undefined){
                  docname = docname+" "+obj.appointment[i].doc_qualif;
                }
                var clntname;
                if(obj.appointment[i].middle_name != undefined){
                  clntname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name)
                } else {
                  clntname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name)
                }

                this.appointment_list.push({
                  doc_app_id: obj.appointment[i].doc_app_id,
                  doc_reg_id: obj.appointment[i].doc_reg_id,
                  doc_name:docname,
                  client_reg_id: obj.appointment[i].client_reg_id,
                  relation_id: obj.appointment[i].relation_id,
                  sub_rel_id: obj.appointment[i].sub_rel_id,
                  address: address,
                  location: obj.appointment[i].location,
                  district: obj.appointment[i].district,
                  state: obj.appointment[i].state,
                  country: obj.appointment[i].country,
                  name:clntname,
                  first_name: encrypt_decript.Decript(obj.appointment[i].first_name),
                  middle_name: obj.appointment[i].middle_name,
                  last_name: obj.appointment[i].last_name,
                  hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                  session: sessiondata,
                  city: obj.appointment[i].district,
                  zipcode: zipcode,
                  docid_name: this.Docidname,
                  advance: obj.appointment[i].advance,
                  balance: obj.appointment[i].balance,
                  inpatientId: inpatientId,
                  cgst: "12%",
                  sgst: "12$",
                  admitted_date: obj.appointment[i].admitted_date,
                  admitted_time: obj.appointment[i].admitted_time,
                  discharge_date: obj.appointment[i].discharge_date,
                  discharge_time: obj.appointment[i].discharge_time,
                  inpid: obj.appointment[i].inp_hosp_id,
                  insurer: obj.appointment[i].insurer,
                  insCardNo: obj.appointment[i].ins_card_no,
                  policyNo: obj.appointment[i].policy_no,
                  CNNno: obj.appointment[i].ins_ccn_no,
                  hosp_tax_no: obj.hosp_tax_no,
                  hosp_pan_no: obj.hosp_pan_no,
                  inpat_id:obj.appointment[i].inpat_id,
                  age:obj.appointment[i].age,
                  gender: clgender,
                  diagnosis: obj.appointment[i].discharge_diagnosis,
                  treat_proc:obj.appointment[i].treat_proc,
                  consultant:obj.appointment[i].consultants,
                });
              }
            }
          },
          error => {
            this.toastr.error("Network error, try again");
          }
        )
    }
  }

  Appointmnet_change(client_id, rel_id, Sub_id, patientDetails) {
    console.log("APPOINTMENT DETAILS "+JSON.stringify(patientDetails))
    // this.CurrentDatetime = billdate;
    this.insuranceBillFlag = false;
    this.showandhideFlag = true;
    this.client_id = client_id;
    this.sub_id = Sub_id;
    this.rel_id = rel_id;
    this.tempparray = patientDetails;
    this.app_id = this.tempparray.doc_app_id;
    this.patname = patientDetails.name;
    this.inpid = patientDetails.inpid;
    this.uhid= patientDetails.inpat_id;
    this.insurer = patientDetails.insurer;
    this.insCardNo = patientDetails.insCardNo;
    this.policyno = patientDetails.policyNo;
    this.CCNno = patientDetails.CNNno;
    this.serviceNo=patientDetails.hosp_tax_no;
    this.panno=patientDetails.hosp_pan_no;
    this.admissionDate = patientDetails.admitted_date;
    this.admissionTime = patientDetails.admitted_time;
    this.dischargeDate = patientDetails.discharge_date;
    this.dischargeTime = patientDetails.discharge_time;
    this.age=patientDetails.age;
    this.gender=patientDetails.gender;
    this.diagnosis=patientDetails.diagnosis;
    this.treat_proc= patientDetails.treat_proc;
    this.docname= patientDetails.doc_name;
    this.consultarray.push({name:patientDetails.doc_name});
    if(patientDetails.consultant != undefined){
      var cname;
      for(var i=0;i<patientDetails.consultant.length;i++){
        if(patientDetails.consultant[i].middle_name != undefined){
          cname = "Dr."+patientDetails.consultant[i].first_name+" "+patientDetails.consultant[i].middle_name+" "+patientDetails.consultant[i].last_name;
        } else {
          cname = "Dr."+patientDetails.consultant[i].first_name+" "+patientDetails.consultant[i].last_name;
        }
        if(patientDetails.consultant[i].qualification != undefined){
          cname = cname+" "+patientDetails.consultant[i].qualification;
        }
        this.consultarray.push({name:cname});
      }
    }
    
    var headers = new Headers();
    var send_data;
    if (this.Filter_txt == "Date") {
      send_data = JSON.stringify({
        client_reg_id: client_id,
        relation_id: rel_id,
        sub_rel_id: Sub_id,
        app_id: this.tempparray.doc_app_id,
        from_date: this.f_date,
        to_date: this.t_date,
      });

    } else {
      send_data = JSON.stringify({
        client_reg_id: client_id,
        relation_id: rel_id,
        app_id: this.tempparray.doc_app_id,
        sub_rel_id: Sub_id,
      });
    }

    if (patientDetails.middle_name != undefined) {
      this.patient_name = encrypt_decript.Decript(patientDetails.first_name) + " " + encrypt_decript.Decript(patientDetails.middle_name) + " " + encrypt_decript.Decript(patientDetails.last_name);

    } else {
      if (patientDetails.last_name != undefined) {
        this.patient_name = encrypt_decript.Decript(patientDetails.first_name) + " " + encrypt_decript.Decript(patientDetails.last_name);
      } else {
        this.patient_name = encrypt_decript.Decript(patientDetails.first_name);
      }
    }

    if (patientDetails.balance != undefined) {
      this.amount_collected = true;
      this.balance_show = false;
      this.previous_balshow = false;
      this.balance_data = parseFloat(patientDetails.balance).toFixed();
      // this.final_amount = parseFloat(patientDetails.balance).toFixed();
      this.total_collected = parseFloat(patientDetails.balance).toFixed();
    }

    this.dog_reg_id = patientDetails.doc_reg_id;
    var zipcode = patientDetails.czipcode != undefined ? encrypt_decript.Decript(patientDetails.czipcode) : "";
    this.admissionDate = patientDetails.admitted_date != undefined ? Date_Formate(patientDetails.admitted_date) : "";
    if (patientDetails.admitted_date != undefined) {
      var admisTime = patientDetails.admitted_time.split(':');
      var h = admisTime[0], m = admisTime[1];
      this.admissionTime = (h > 12) ? (h - 12 + ':' + m + ' PM') : (h + ':' + m + ' AM');
    }
    this.dischargeDate = patientDetails.discharge_date != undefined ? Date_Formate(patientDetails.discharge_date) : "";
    if (patientDetails.discharge_time != undefined) {
      var admisTime = patientDetails.discharge_time.split(':');
      var h = admisTime[0], m = admisTime[1];
      this.dischargeTime = (h > 12) ? (h - 12 + ':' + m + ' PM') : (h + ':' + m + ' AM');
    }
   
    this.docBills = [];
    this.totalBill = "0.00";

    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gbfcp', send_data,

      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("BILLING DETAILS "+JSON.stringify(obj))
          if (obj != null) {
            var doctorBills = obj.doc_bills;
            var index=1;
            
            // for doctor bill
            if (doctorBills != undefined) {
              for (var j = 0; j < doctorBills.length; j++) {
                var billList = doctorBills[j].bill_details;
                this.billno= doctorBills[j].billing_id;
                var bdate= doctorBills[j].bill_date.split("-");
                this.bill_date=bdate[2]+"-"+bdate[1]+"-"+bdate[0];
                this.billDetails = [];
                if (billList.length != 0) {
                  for (var i = 0; i < billList.length; i++) {
                    this.billDetails.push({
                      index:index,
                      name: billList[i].name+" X "+billList[i].quantity,
                      fee: billList[i].fee,
                    })
                    this.total += parseInt(billList[i].fee);
                    index +=1;
                  }
                }
              }
            }
            if(obj.medicinetotal != undefined){
              this.billDetails.push({
                index:index,
                name:"Medicines",
                fee:parseInt(obj.medicinetotal).toFixed(2),
              });
              index +=1;
              this.total += parseInt(obj.medicinetotal);
            }

            if(obj.diagtotal != undefined){
              this.billDetails.push({
                index:index,
                name:"Pathology & Radiology",
                fee:parseInt(obj.diagtotal).toFixed(2),
              });
              this.total += parseInt(obj.diagtotal);
            }

            this.totalBill = this.total.toFixed(2);
          }
          console.log("bill details", this.billDetails)
        },
        error => {
          this.toastr.error("Network error, try again later");
        }
      )
  }


  print_area() {
    let printContents, popupWin;
    if(this.printTemplate !=undefined && this.printTemplate=="banner"){
      printContents =this.printbanner.nativeElement.innerHTML;

    } else if(this.printTemplate !=undefined && this.printTemplate=="logowithname"){
      printContents =this.printlogowithname.nativeElement.innerHTML;

    } else {
      printContents =this.printnoheader.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Insurance bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        .casesheet_container {
          width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`
    );
    popupWin.document.close();
  }
}
