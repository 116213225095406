<div *ngIf="personalFlag == 'min'">
   <div>
      <mat-label class="matlabel" [hidden]="name_hidden">{{name_label}}
         <input type="text" class="ipcss ip" matInput maxlength="25" required [(ngModel)]="name" />
      </mat-label>
      <mat-label class="matlabel" [hidden]="curoccup_hidden">{{current_label}}
         <input type="text" class="ipcss ip" matInput maxlength="25" [(ngModel)]="current_occupation"
            [readonly]="isReadonly()" />
      </mat-label>
      <mat-label class="matlabel" [hidden]="age_hidden">{{age_label}}
         <input type="text" class="ipcss ip" matInput maxlength="25" required [(ngModel)]="age"
            [readonly]="isReadonly()" />
      </mat-label>
      <mat-label class="matlabel">{{gender_label}}
         <input type="text" class="ipcss ip" matInput maxlength="25" required [(ngModel)]="gender"
            [readonly]="isReadonly()" />
      </mat-label>
   </div>
</div>

<div *ngIf="personalFlag == 'max'">
   <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
         <div class="cover_div">
            <div class="header_lable">
               Personal
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="name_hidden">
                     <mat-label class="matlabel" [hidden]="name_hidden">{{name_label}}
                        <input type="text" class="ipcss ip" matInput maxlength="25" required [(ngModel)]="name"
                           [readonly]="client" (change)="send_data()"/>
                     </mat-label>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="Gender_hidden">
                     <mat-label class="matlabel">{{gender_label}}
                        <input type="text" class="ipcss ip" matInput [(ngModel)]="gender" [readonly]="isReadonly()" (change)="send_data()"/>
                     </mat-label>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="age_hidden">
                     <mat-label class="matlabel" [hidden]="age_hidden">{{age_label}}
                        <input type="text" class="ipcss ip" matInput [(ngModel)]="age" (change)="send_data()"/>
                     </mat-label>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                     <mat-label class="matlabel" [hidden]="curoccup_hidden">{{current_label}}
                        <input type="text" class="ipcss ip" matInput maxlength="25"  (change)="send_data()"
                           [(ngModel)]="current_occupation" [readonly]="client" required/>
                     </mat-label>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
         <div class="cover_div">
            <div class="header_lable">
               Diet
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class="col-12">
                     <mat-label class="matlabel" [hidden]="dietary_hidden">{{dietary_label}}<br>
                        <textarea class="habitsta ipcss" maxlength="250" [(ngModel)]="diet_habits" (change)="send_data()"
                           style="height: 85px !important;"></textarea> 
                     </mat-label>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
         <div class="cover_div">
            <div class="header_lable">
               Observation
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="bnature_hidden">
                     <mat-label class="matlabel">{{bnature_label}}
                        <input class="ipcss ip" matInput type="text" [readonly]="isReadonly()" (change)="send_data()"
                           [(ngModel)]="body_nature" />
                     </mat-label>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="bodytype_hidden">
                     <mat-label class="matlabel">{{bodytype_label}}
                        <select class="ipcss age" [(ngModel)]="body_type" [disabled]="client" (change)="send_data()">
                           <option value="{{btype.bodytype_name}}" *ngFor="let btype of bodyttype_array">
                              {{btype.bodytype_name}}</option>
                        </select>
                     </mat-label>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
         <div class="cover_div">
            <div class="header_lable">
               Medicine
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="anymed_hidden">
                     <mat-label class="matlabel">{{anymed_label}}
                        <select class="ipcss " required [(ngModel)]="anymed_txt" (change)="med_change();send_data()" 
                           [disabled]="client">
                           <option value="Yes">Yes</option>
                           <option selected value="No">No</option>
                        </select>
                     </mat-label>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="med_hidden ">
                     <mat-label class="matlabel">{{med_label}}
                        <input class="ipcss " #matInput type="text" maxlength="100" [disabled]="med_flag" required
                           [(ngModel)]="med_txt" [readonly]="client" (change)="send_data()"/>
                     </mat-label>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12">
         <div class="cover_div">
            <div class="header_lable">
               Concerns
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="maincon_hidden">
                     <mat-label class="matlabel "> {{Maincon_label}}<br>
                        <textarea readonly #matInput class="ipcss" required [(ngModel)]="Maincon_txt"
                           (click)="mainconcernpopup()"></textarea>
                     </mat-label>
                  </div>
                  <div [hidden]="medhist_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                     <mat-label class="matlabel ">{{medhist_label}}<br>
                        <textarea readonly #matInput class="ipcss " [(ngModel)]="past_medical"
                           (click)="medical_popup()"></textarea>
                     </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                     <mat-label class="matlabel" [hidden]="religious_belielabe">{{Religious_label}}<br>
                        <textarea readonly #matInput class="ipcss" [(ngModel)]="religious_belief"
                           (click)="religious()"></textarea>
                     </mat-label>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div [hidden]="feelstress_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
         <div class="cover_div">
            <div class="header_lable">
               Stress
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="feelstress_hidden">
                     <mat-label class="matlabel" [hidden]="feelstress_hidden">{{feelstress_label}}<br>
                        <select required [(ngModel)]="feelstress" (change)="stress_change();send_data();" [disabled]="client">
                           <option>Yes</option>
                           <option>No</option>
                        </select>
                     </mat-label>
                  </div>
                  <div class="col-12" [hidden]="stress_hidden">
                     <mat-label class="matlabel" [hidden]="stress_hidden">{{stress_label}}<br>
                        <textarea class="ipcss widthappt" maxlength="250" rows="1" required [(ngModel)]="stress" (change)="send_data()"
                           [disabled]="stress_flag"></textarea>
                     </mat-label>
                  </div>
                  <div class="col-12" [hidden]="symptom_hidden">
                     <mat-label class="matlabel" [hidden]="symptom_hidden">{{symptom_label}}<br>
                        <textarea class="ipcss widthappt" maxlength="250" rows="1" required [(ngModel)]="symptom" (change)="send_data()"
                           [disabled]="stress_flag"></textarea>
                     </mat-label>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div [hidden]="hosp_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
         <div class="cover_div">
            <div class="header_lable">
               Previous hospital details
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="hosp_hidden">
                     <mat-label class="matlabel" [hidden]="hosp_hidden">{{hosp_label}}<br>
                        <select required [(ngModel)]="hosp" [readonly]="client" (change)="enableHosp();send_data()">
                           <option>Yes</option>
                           <option>No</option>
                        </select>
                     </mat-label>

                  </div>
                  <div class="col-12" [hidden]="hospdet_hidden">
                     <mat-label class="matlabel" [hidden]="hospdet_hidden">{{hospdet_label}}<br>
                        <textarea class="ipcss widthappt" maxlength="250" rows="1" required [(ngModel)]="hospdet" (change)="send_data()"
                           [disabled]="prevhosp_flag"></textarea>
                     </mat-label>

                  </div>
                  <div class="col-12" [hidden]="treat_hidden">
                     <mat-label class="matlabel" [hidden]="treat_hidden">{{treat_label}}<br>
                        <textarea class="ipcss widthappt" maxlength="250" rows="1" required [(ngModel)]="treat" (change)="send_data()"
                           [disabled]="prevhosp_flag"></textarea>
                     </mat-label>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div [hidden]="rightarm_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
         <div class="cover_div">
            <div class="header_lable">
               Blood pressure
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class=" col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="rightarm_hidden">
                     <mat-label class="matlabel">{{rightarm_label}}</mat-label>
                     <div class="row" [hidden]="rightarm_hidden">
                        <div class=" col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="rightarm_hidden">
                           <input matInput class="ipcss" maxlength="3" placeholder="mm Hg" [(ngModel)]="rightarm_dia" (change)="send_data()"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client" />
                           <!-- </mat-label> -->
                        </div>
                        <div class=" col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="rightarm_hidden">
                           <input matInput class="ipcss" maxlength="3" placeholder="mm Hg" [(ngModel)]="rightarm_sys" (change)="send_data()"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client" />
                        </div>
                     </div>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="leftarm_hidden">
                     <mat-label class="matlabel">{{leftarm_label}}<br></mat-label>
                     <div class="row" [hidden]="leftarm_hidden">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="leftarm_hidden">
                           <input matInput class="ipcss" maxlength="3" placeholder="mm Hg" [(ngModel)]="leftarm_dia" (change)="send_data()"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client" />
                           <!-- </mat-label> -->
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="leftarm_hidden">
                           <input matInput class="ipcss" maxlength="3" placeholder="mm Hg" [(ngModel)]="leftarm_sys" (change)="send_data()"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div [hidden]="rightarm_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
         <div class="cover_div">
            <div class="header_lable">
               Blood glucose
            </div>
            <div class="content_cover">
               <div class="row">
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="fasting_hidden">
                     <mat-label class="matlabel">{{fasting_label}}<br>
                        <input matInput class="ipcss" style="width: 100%;" maxlength="3" placeholder="mg/dl" [(ngModel)]="fasting" (change)="send_data()"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client" />
                     </mat-label>
                  </div>
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                     <mat-label class="matlabel">{{prandial_label}}<br>
                        <input matInput class="ipcss" style="width: 100%;" maxlength="3" placeholder="mg/dl" [(ngModel)]="prandial" (change)="send_data()"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client" />
                     </mat-label>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class=" col-12 " *ngIf="historytab">
         <a style="float: right;"><img src="../../../assets/ui_icons/buttons/history.svg" class="saveimgbtn_inpatinfo"
               (click)="History_tab_click()" /></a>
      </div>
   </div>
</div>