<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Supplier product list</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo" (click)="suppler_masterclick()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Supplier / distributor name<br>
                            <select disableOptionCentering class="ipcss " [(ngModel)]="suppID" required (change)="getProductCount()">
                                <option disabled>Select</option>
                                <option *ngFor="let supplier of supplierList" value={{supplier.supp_id}}>{{supplier.name}}</option>
                            </select>
                        </mat-label>
                    </div>
                </div>
                <div *ngIf="productMasterAddArray.length == 0" class="nodata">No products found</div>
                <div class="row" *ngIf="productMasterAddArray.length != 0">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <mat-label class="matlabel">Filter products
                                        <input type="text" class="ipcss " [(ngModel)]="productName" maxlength="50"
                                            (keyup)=product_filter() matInput />
                                    </mat-label>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="float: right;">
                                    <mat-label class="matlabel">Show<br>
                                        <select disableOptionCentering class="ipcss perpage" [(ngModel)]="pagelist"
                                            (change)="pagelistChange()">
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive">
                            <table style="font-size: 13px;" id="product_table"
                                [hidden]="productMasterAddArray.length==0" class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th>HSN no</th>
                                        <th>Product name</th>
                                        <th>Product type</th>
                                        <th>Manufacturer</th>
                                        <th>Qty/Unit</th>
                                        <th>MRP</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let productmaster of productMasterAddArray">
                                        <td class="align_left">{{productmaster.hsn_no}}</td>
                                        <td class="align_left">{{productmaster.name}}</td>
                                        <td class="align_left">{{productmaster.product_type_desc}}</td>
                                        <td class="align_left">{{productmaster.mfg_name}}</td>
                                        <td>{{productmaster.per_unit}}</td>
                                        <td>{{productmaster.MRP}}</td>
                                        <td style="font-size: 13px;">
                                            <mdb-checkbox [default]="true" [(ngModel)]="productmaster.checked"
                                                name="list_name" value="{{productmaster.product_id}}"
                                                (change)="isAllSelected(productmaster.product_id, productmaster.checked)"></mdb-checkbox>
                                            <!-- <a (click)="productmaster_edit(productmaster.Index)" id="view" style="color: #2c7fe1;"><i class="fas fa-edit"></i></a> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div [hidden]="pageNavigate || productMasterAddArray.length==0" class="col-12">
                        <div class="row">
                            <div class="col-3 col-lg-4 col-md-3 col-xl-4"></div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images"
                                    (click)="pageLimitPrevious()" src="../../../assets/img/pagnation_previous.png" />
                            </div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <span class="current_font">{{currentPage}}/{{numberOfPages()}}</span>
                            </div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images"
                                    (click)="pageLimitnext()" src="../../../assets/img/pagination_next.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>