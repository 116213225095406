import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-nurse-doc-app-list',
  templateUrl: './nurse-doc-app-list.component.html',
  styleUrls: ['./nurse-doc-app-list.component.scss']
})
export class NurseDocAppListComponent implements OnInit {
  public checkedList: any;
  public masterSelected: boolean = false;
  public appOpenCount: number = 0;
  public cancelCheckFlag: boolean;
  public cancelFooter: boolean;
  public currentDatetime = null;
  public appDate = null;
  public nodapp: boolean;
  public homeCare: string;
  public caseSheet: string;
  public caseSummery: string;
  public appointmentArray = [];
  public loader: boolean;
  public nurseRegID;
  public session;
  public checkListArray = [];
  public checkListIDArray: any = [];
  public event = {
    month: '1990-02-19',
    timeStarts: '07:43',
    timeEnds: '1990-02-20'
  }
  public appointmentDate: string;
  dtOptions: DataTables.Settings = {};
  constructor(public http: Http, public toastr: ToastrService, public location: Location, public messageService: MenuViewService) { }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.cancelCheckFlag = true;
    this.cancelFooter = true;
    this.nodapp = true;
    this.homeCare = "0";
    this.nurseRegID = Helper_Class.getInfo().user_id;
    this.getCurrentDate();
  }
  Date_click(value) {
    this.appointmentDate = value;
    this.get_doc_apps(this.nurseRegID);
  }
  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.appointmentDate = obj.current_date;
            this.get_doc_apps(this.nurseRegID);
          }
        },
        error => {
        }
      )
  }
  get_doc_apps(data): void {
    this.loader = false;
    this.appointmentArray = [];
    var data = null;
    if (this.caseSheet != null || this.caseSummery != null) {
      this.homeCare = undefined
    }
    var get_tmp = this.appointmentDate.split('-');
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/allappsnur',
      JSON.stringify({
        nurse_reg_id: this.nurseRegID,
        date: this.appointmentDate,
        session: "all",
        doctor: "all",
        country: ipaddress.country_code.toString(),
      }),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(response["_body"]);
          if (data.appointments != null) {
            this.loader = true;
            var appolist = data.appointments;
            if (appolist.length != "0") {
              for (var i = 0; i < appolist.length; i++) {
                var status_txt;
                if (appolist[i].status == "0") {
                  status_txt = "Completed";
                } else if (appolist[i].status == "1") {
                  if (appolist[i].date == this.currentDatetime || this.currentDatetime < appolist[i].date) {
                    if (appolist[i].f_time != "1") {
                      var get_time = new Date(appolist[i].date + " " + this.currentDatetime);
                      var cur_time = new Date(this.currentDatetime + " " + this.currentDatetime);
                      if (get_time > cur_time) {
                        status_txt = "Not visited";
                      } else {
                        status_txt = "Open";
                      }
                    } else {
                      var get_time = new Date(appolist[i].date);
                      var cur_time = new Date(this.currentDatetime);
                      if (get_time >= cur_time) {
                        status_txt = "Open";
                      } else {
                        status_txt = "Not visited";
                      }
                    }
                  } else {
                    if (appolist[i].date > this.currentDatetime || appolist[i].date == this.currentDatetime) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else if (appolist[i].status == "2") {
                  if (appolist[i].date == this.currentDatetime || this.currentDatetime < appolist[i].date) {
                    var get_time = new Date(appolist[i].date + " " + this.currentDatetime);
                    var cur_time = new Date(this.currentDatetime + " " + this.currentDatetime);
                    status_txt = "Confirmed";
                  } else {
                    if (appolist[i].date > this.currentDatetime || appolist[i].date == this.currentDatetime) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                }
                var token_flag: boolean;
                if (appolist[i].token != null) {
                  var token_time_label = "Token no : " + appolist[i].token;
                  token_flag = true;
                } else {
                  token_flag = false;
                  var Temptimesplit = appolist[i].f_time;
                  var offTime = Temptimesplit.split(':');
                  offTime[0] = offTime[0] % 12;
                  var output = offTime.join(':');
                  var get_timeq = output.split(":");
                  if (get_timeq[0] == "0") {
                    output = "12" + ":" + get_timeq[1];
                  }
                  token_time_label = "Time : " + Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session));
                }
                var Patient_name = null;
                if (appolist[i].middle_name != undefined && appolist[i].middle_name != null) {
                  if (encrypt_decript.Decript(appolist[i].middle_name) != "") {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].middle_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  } else {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  }
                } else {
                  Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                }
                if (appolist[i].sub_rel_id != null) {
                  var sub_rel = appolist[i].sub_rel_id;
                } else {
                  sub_rel = "0";
                }
                if (appolist[i].age != null) {
                  var get_age = appolist[i].age;
                } else {
                  get_age = "";
                }
                if (appolist[i].address2 != null && appolist[i].address2 != "") {
                  var add_data = appolist[i].address1 + " " + appolist[i].address2;
                } else {
                  add_data = appolist[i].address1;
                }
                if (appolist[i].rel_zipcode != null) {
                  var zipcode_data = encrypt_decript.Decript(appolist[i].rel_zipcode);
                } else {
                  zipcode_data = appolist[i].zipcode;
                }
                if (appolist[i].mobile != null) {
                  var contactno = encrypt_decript.Decript(appolist[i].mobile);
                }
                var doc_name;
                if (appolist[i].dr_middle_name != undefined) {
                  doc_name = appolist[i].dr_first_name + " " + appolist[i].dr_middle_name + " " + appolist[i].dr_last_name;
                } else {
                  doc_name = appolist[i].dr_first_name + " " + appolist[i].dr_last_name;
                }
                this.appointmentArray.push({
                  app_data: appolist[i],
                  hptl_clinic_id: appolist[i].hptl_clinic_id,
                  patient_name: Patient_name,
                  tot_app_count: token_time_label,
                  session: encrypt_decript.Decript(appolist[i].session),
                  status: status_txt,
                  profile_image: ipaddress.Ip_with_img_address + appolist[i].profile_image,
                  doc_app_id: appolist[i].doc_app_id,
                  client_reg_id: appolist[i].client_reg_id,
                  relation_id: appolist[i].relation_id,
                  sub_rel: sub_rel,
                  rel_count: appolist[i].country_id,
                  rel_stat: appolist[i].state_id,
                  rel_city: appolist[i].city_id,
                  rel_loc: appolist[i].location_id,
                  rel_loc_desc: appolist[i].location,
                  loc_age: get_age,
                  gender_data: encrypt_decript.Decript(appolist[i].gender),
                  dob_data: appolist[i].dob,
                  spl: appolist[i].specialization,
                  spl_id: appolist[i].specialization_id,
                  token_flag: token_flag,
                  medicare_name: appolist[i].medicare_name,
                  hospital_name: appolist[i].hptl_name,
                  address: add_data,
                  state_desc: appolist[i].state_desc,
                  city_desc: appolist[i].city_desc,
                  country_desc: appolist[i].country_desc,
                  rel_zipcode: zipcode_data,
                  dr_first_name: appolist[i].dr_first_name,
                  dr_middle_name: appolist[i].dr_middle_name,
                  dr_last_name: appolist[i].dr_last_name,
                  app_date: appolist[i].date,
                  doc_name: doc_name,
                  contact: contactno,
                });
              }
              this.nodapp = true;
            } else {
              this.nodapp = false;
            }
          } else {
            this.loader = true;
            this.toastr.error(Message_data.getNetworkMessage());
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
  Doctor_View(doc_app_id) {
    Nurse_Helper.setDoctorAppoinmentList(null);
    var app_id = {
      app_id: doc_app_id,
    }
    Nurse_Helper.setDoctorAppoinmentList(doc_app_id);
    this.messageService.sendMessage("DoctorAppDetail");
  }
  clearAll() {
    this.checkListArray = this.appointmentArray;
    for (var i = 0; i < this.checkListArray.length; i++) {
      this.checkListArray[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }
  checkUncheckAll() {
    this.checkListArray = this.appointmentArray;
    if (this.checkListIDArray != "" && this.checkListIDArray.length != 0) {
      if (this.checkListIDArray.length == this.appOpenCount) {
        this.checkListIDArray = [];
        for (var i = 0; i < this.checkListArray.length; i++) {
          var token = "", time = "";
          if (this.checkListArray[i].token_flag == true) {
            token = this.checkListArray[i].tot_app_count
          } else {
            time = this.checkListArray[i].tot_app_count;
          }
          this.checkListIDArray.push({
            app_id: this.checkListArray[i].doc_app_id,
            session: this.checkListArray[i].session,
            app_date: this.checkListArray[i].app_date,
            app_time: time,
            first_name: this.checkListArray[i].dr_first_name,
            middle_name: this.checkListArray[i].dr_middle_name,
            last_name: this.checkListArray[i].dr_last_name,
            mobile: this.checkListArray[i].contact,
            token: token,
          });
          this.checkListArray[i].isSelected = this.masterSelected;
        }
      } else {
        this.checkListIDArray = [];
        for (var i = 0; i < this.checkListArray.length; i++) {
          if (this.checkListArray[i].status == 'Open' || this.checkListArray[i].status == 'Confirmed') {
            var token = "", time = "";
            if (this.checkListArray[i].token_flag == true) {
              token = this.checkListArray[i].tot_app_count
            } else {
              time = this.checkListArray[i].tot_app_count;
            }
            this.checkListIDArray.push({
              app_id: this.checkListArray[i].doc_app_id,
              session: this.checkListArray[i].session,
              app_date: this.checkListArray[i].app_date,
              app_time: time,
              first_name: this.checkListArray[i].dr_first_name,
              middle_name: this.checkListArray[i].dr_middle_name,
              last_name: this.checkListArray[i].dr_last_name,
              mobile: this.checkListArray[i].contact,
              token: token,
            });
            this.checkListArray[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      for (var i = 0; i < this.checkListArray.length; i++) {
        if (this.checkListArray[i].status == 'Open' || this.checkListArray[i].status == 'Confirmed') {
          var token = "", time = "";
          if (this.checkListArray[i].token_flag == true) {
            token = this.checkListArray[i].tot_app_count
          } else {
            time = this.checkListArray[i].tot_app_count;
          }
          this.checkListIDArray.push({
            app_id: this.checkListArray[i].doc_app_id,
            session: this.checkListArray[i].session,
            app_date: this.checkListArray[i].app_date,
            app_time: time,
            first_name: this.checkListArray[i].dr_first_name,
            middle_name: this.checkListArray[i].dr_middle_name,
            last_name: this.checkListArray[i].dr_last_name,
            mobile: this.checkListArray[i].contact,
            token: token,
          });
          this.checkListArray[i].isSelected = this.masterSelected;
        }
      }
    }
  }
  isAllSelected(list) {
    if (this.checkListIDArray != "" && this.checkListIDArray.length != 0) {
      if (this.checkListIDArray.indexOf(list) !== -1) {
        var index = this.checkListIDArray.indexOf(list);
        this.checkListIDArray.splice(index, 1);
      } else {
        var token = "", time = "";
        if (list.token_flag == true) {
          token = list.tot_app_count
        } else {
          time = list.tot_app_count;
        }
        this.checkListIDArray.push({
          app_id: list.doc_app_id,
          session: list.session,
          app_date: list.app_date,
          app_time: time,
          first_name: list.dr_first_name,
          middle_name: list.dr_middle_name,
          last_name: list.dr_last_name,
          mobile: list.contact,
          token: token,
        });
      }
    } else {
      var token = "", time = "";
      if (list.token_flag == true) {
        token = list.tot_app_count
      } else {
        time = list.tot_app_count;
      }
      this.checkListIDArray.push({
        app_id: list.doc_app_id,
        session: list.session,
        app_date: list.app_date,
        app_time: time,
        first_name: list.dr_first_name,
        middle_name: list.dr_middle_name,
        last_name: list.dr_last_name,
        mobile: list.contact,
        token: token,
      });
    }
  }
  getCheckedItemList() {
    if (this.checkListArray.length == this.checkListIDArray.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkListIDArray.length != 0) {
      this.toastr.success('Thank you...', ``);
    } else {
      this.checkListIDArray = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }
  Appointment_confirm() {
    if (this.checkListIDArray != "" && this.checkListIDArray.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: "doctor",
          doc_apps: this.checkListIDArray,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
                this.get_doc_apps(this.nurseRegID);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToConfirm);
    }
  }
  cancel() {
    if (this.checkListIDArray != "" && this.checkListIDArray.length != 0) {
      var cancel = [];
      for (var i = 0; i < this.checkListIDArray.length; i++) {
        cancel.push(this.checkListIDArray[i].app_id);
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          doc_cancel: cancel,
          home_care: "0",
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.ngOnInit();
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }
}
