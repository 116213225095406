import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-uploadtab',
  templateUrl: './uploadtab.component.html',
  styleUrls: ['./uploadtab.component.css']
})
export class UploadtabComponent implements OnInit {
  public diabUploadFlag ="min";
  subscription:Subscription;
  public uploadmin:boolean=true
  public Fasting_Onehour;
  public Fasting_Halfhour;
  public Postprandial;
  public Temperature;
  public BP_01;
  public BP_02;
  public Fasting_Onehalfhour;
  public Ketones;
  public Proteins;
  public Glucose;
  public HbA1c;
  public Heart_Beat;
  public Respiratory_rate;
  public Cholesterol_HDL;
  public Cholesterol_LDL;
  public Cholesterol_Trigs;
  public Cholesterol_VLDL;

  public fasting: boolean;
  public ogit_txt: boolean;
  public fast_one_disable: boolean;
  public fast_onehalf_disable: boolean;
  public header_footer_flag: boolean;
  public glocose_label: boolean;
 public glocose_hidden: boolean;
  public ketone_label;
  public ketone_hidden: boolean;
  public protin_label;
  public protin_hidden: boolean;
  public heart_label;
  public heart_hidden: boolean;
  public  respi_label;
  public respi_hidden: boolean;
  public trig_label;
  public trig_hidden: boolean;
  public vldl_label;
  public vldl_hidden: boolean;
  public getdata:any;

  constructor(public toastr:ToastrService ,public http:Http, public messageservice:CasesheetService) {
    this.fasting = true;
    this.ogit_txt = false;
    this.fast_one_disable = true;
    this.fast_onehalf_disable = true;
   }

  ngOnInit(): void {
    if(Doc_Helper.getAppFlow() == "client" ){
      this.getdata=Helper_Class.getInfo();
    }else{
      this.getdata=Doc_Helper.getClient_Info();
    }
    
    Doc_Helper.setReading(null);
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.diabUploadFlag = message;
      }
    });

    var fast_tab= Helper_Class.getHopitalfieldsRet();
    for (var i = 0; i < fast_tab.length; i++) {
      if (fast_tab[i].page_name == "Upload readings") {
        if (fast_tab[i].fields != undefined) {
          if (fast_tab[i].fields != undefined) {
            for (var j = 0; j < fast_tab[i].fields.length; j++) {
              if (fast_tab[i].fields[j].field_name == "Glucose") {
                this.glocose_label = fast_tab[i].fields[j].field_name;
                this.glocose_hidden = false;
              }else if (fast_tab[i].fields[j].field_name == "Ketones") {
                this.ketone_label = fast_tab[i].fields[j].field_name;
                this.ketone_hidden = false;
              }else if (fast_tab[i].fields[j].field_name == "Proteins") {
                this.protin_label = fast_tab[i].fields[j].field_name;
                this.protin_hidden = false;
              }else if (fast_tab[i].fields[j].field_name == "Heart beat") {
                this.heart_label = fast_tab[i].fields[j].field_name;
                this.heart_hidden = false;
              }else if (fast_tab[i].fields[j].field_name == "Respiratory rate") {
                this.respi_label = fast_tab[i].fields[j].field_name;
                this.respi_hidden = false;
              }else if (fast_tab[i].fields[j].field_name == "Trigs") {
                this.trig_label = fast_tab[i].fields[j].field_name;
                this.trig_hidden = false;
              }else if (fast_tab[i].fields[j].field_name == "VLDL") {
                this.vldl_label = fast_tab[i].fields[j].field_name;
                this.vldl_hidden = false;
              }
            }
          }
        }
      }
    }
    this.Temperature="";
  }

  ogtt_chn() {
    if (this.ogit_txt == true) {
      this.fasting = false;
      this.fast_one_disable = false;
      this.fast_onehalf_disable = false;
      this.Fasting_Onehour = "";
      this.Fasting_Halfhour = "";
    }
  }

  formatNumber(e: any, separador: string = '.', decimais: number = 1) {
    this.Temperature="";
    let a:any = e.value.split('');
    let ns:string = '';
    a.forEach((c:any) => { if (!isNaN(c)) ns = ns + c; });
    ns = parseInt(ns).toString();
    if (ns.length < (decimais+1)) { ns = ('0'.repeat(decimais+1) + ns); ns = ns.slice((decimais+1)*-1); }
    let ans = ns.split('');
    let r = '';
    for (let i=0; i < ans.length; i++) if (i == ans.length - decimais) r = r + separador + ans[i]; else r = r + ans[i];
    e.value = r;
  }
  
  fast_chn() {
    if (this.fasting == true) {
      this.ogit_txt = false;
      this.fast_one_disable = true;
      this.fast_onehalf_disable = true;
    }
  }

  save_reading() {
    var Send_data = null;
    var Red_valid_flag = false;
    var bloodpressure=null;

    if (Red_valid_flag == false) {
      if (this.Fasting_Halfhour == undefined || this.Fasting_Halfhour == "" ||
        this.Postprandial == undefined || this.Postprandial == "" ||
        this.Temperature == undefined || this.Temperature == "" ||
        this.BP_01 == undefined || this.BP_01 == "" || this.BP_02 == undefined || this.BP_02 == "") {
        Red_valid_flag = true;
      } else {
        if (this.Fasting_Halfhour.toString().trim() == "") {
          Red_valid_flag = true;
        } else if (this.Postprandial.toString().trim() == "") {
          Red_valid_flag = true;
        } else if (this.BP_01.toString().trim() == "") {
          Red_valid_flag = true;
        } else if (this.BP_02.toString().trim() == "") {
          Red_valid_flag = true;
        }
      }
    } else if (Red_valid_flag == false && this.ogit_txt == true) {
      if (this.Fasting_Onehour == undefined || this.Fasting_Onehour == null ||
        this.Fasting_Onehalfhour == undefined || this.Fasting_Onehalfhour == null) {
        Red_valid_flag = true;
      } else {
        if (this.Fasting_Onehour.toString().trim() == "") {
          Red_valid_flag = true;
        } else if (this.Fasting_Onehalfhour.toString().trim() == "") {
          Red_valid_flag = true;
        }
      }
    }

    if (Red_valid_flag == false && (this.Ketones != undefined && (this.Glucose == undefined || this.Glucose == "")) || (this.Proteins != undefined && (this.Glucose == undefined || this.Glucose == ""))) {
      Red_valid_flag = true;
    } else {
      var diab_case_hist_id = undefined;
      var diab_hist=Helper_Class.getRet()
      if (diab_hist!= undefined) {
        if (diab_hist.case_hist_id != null) {
          diab_case_hist_id =diab_hist.case_hist_id;
        }
      }

      if (this.getdata.sub_id != 0) {
        var sub_rel = this.getdata.sub_id;
      } else {
        sub_rel = undefined;
      }

      var lif_stl_id = undefined;
      if (diab_hist != undefined) {
        if (diab_hist.life_style_id != null) {
          lif_stl_id =diab_hist.life_style_id;
        }
      }

      if (this.ketone_hidden == true) {
        this.Ketones = null;
      }

      if (this.protin_hidden == true) {
        this.Proteins = null;
      }

      if (this.heart_hidden == true) {
        this.Heart_Beat = null;
      }

      if (this.respi_hidden == true) {
        this.Respiratory_rate = null;
      }

      if (this.trig_hidden == true) {
        this.Cholesterol_Trigs = null;
      }

      if (this.vldl_hidden == true) {
        this.Cholesterol_VLDL = null;
      }

      if (this.BP_01 == undefined) {
        bloodpressure = null;
      } else{
        this.BP_01 + "/" + this.BP_02
      }

      Send_data = {
        chk_flag: Red_valid_flag,
        fasting: this.Fasting_Halfhour,
        onehour: this.Fasting_Onehour,
        onehalfhour: this.Fasting_Onehalfhour,
        twohours: this.Postprandial,
        HbA1c: this.HbA1c,
        glucose: this.Glucose,
        ketones: this.Ketones,
        proteins: this.Proteins,
        blood_pressure: bloodpressure,
        temparature: this.Temperature,
        heart_rate: this.Heart_Beat,
        respiratory_rate: this.Respiratory_rate,
        hdl: this.Cholesterol_HDL,
        ldl: this.Cholesterol_LDL,
        trigs: this.Cholesterol_Trigs,
        vldl: this.Cholesterol_VLDL,
        case_hist_id: diab_case_hist_id,
        client_reg_id:this.getdata.Client_id,
        relation_id:this.getdata.rel_id,
        sub_rel_id: sub_rel,
        life_style_id: lif_stl_id,
        country: "IN"
      };

      Helper_Class.set_diab_reading_data(Send_data);
      
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diab/reading', Send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj =response.json()
            Doc_Helper.setReading(obj);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.readingUpdSuccess);
                this.Fasting_Halfhour = "";
                this.Fasting_Onehour = "";
                this.Fasting_Onehalfhour = "";
                this.Postprandial = "";
                this.HbA1c = "";
                this.Glucose = "";
                this.Ketones = "";
                this.Proteins = "";
                this.BP_01 = "";
                this.BP_02 = "";
                this.Temperature = "";
                this.Heart_Beat = "";
                this.Respiratory_rate = "";
                this.Cholesterol_HDL = "";
                this.Cholesterol_LDL = "";
                this.Cholesterol_Trigs = "";
                this.Cholesterol_VLDL = "";
              } else {
                this.toastr.error(Message_data.readingUpdNotSuccess);
              }
            }
          },
          error => {})
    }
  }
}
