import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { Date_Formate } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diag-reports',
  templateUrl: './diag-reports.component.html',
  styleUrls: ['./diag-reports.component.scss']
})
export class DiagReportsComponent implements OnInit {
  public splArray;
  public speclization;
  public filterByText;
  public patientFlag:boolean = true;
  public specialityFlag:boolean = true;
  public timeWiseFlag:boolean = true;
  public fromDateFlag:boolean = false;
  public toDateFlag:boolean = false;
  public clientFirstName;
  public clientLastName;
  public fromDate;
  public toDate;
  public specialityListArray = [];
  public testwiseDataList = [];
  public pathology;
  public radiology;
  public currentDate;
  dtOptions: DataTables.Settings = {};
  public srchSpecialityFlag:boolean = true;
  public srcTestFlag:boolean = true;

  constructor(public diagnosisservice:MenuViewService,public toastr:ToastrService,public gservice:CommonDataService, public  http:HttpClient,public dialog:MatDialog) {
    this.filterByText = "Select";
    this.getSpeciality();
    this.getdate();
  }

  ngOnInit(): void {
    this.dtOptions = {
      //pagingType: 'full_numbers',
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by test name or count"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }

  getSpeciality() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getspecialization/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.splArray = obj.specialization;
        },error => {});
  }

  getdate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", ({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        this.currentDate = response.current_date;
        this.fromDate = this.currentDate;
        this.toDate = this.currentDate;
      },error => {});
  }

  selectedFilter(data){
    this.speclization = "";
    this.fromDate = this.currentDate;
    this.toDate = this.currentDate;
    this.clientFirstName = "";
    this.clientLastName = "";
    this.specialityListArray = [];
    this.testwiseDataList = [];
    this.srchSpecialityFlag = true;
    this.srcTestFlag = true;

    if(data == "Patient"){
      this.patientFlag = false;
      this.specialityFlag = true;
      this.timeWiseFlag = true;
      this.fromDateFlag = true;
      this.toDateFlag = true;

    }else if(data == "Speciality"){
      this.patientFlag = true;
      this.specialityFlag = false;
      this.timeWiseFlag = true;
      this.fromDateFlag = false;
      this.toDateFlag = false;

    }else if(data == "TestWise"){
      this.patientFlag = true;
      this.specialityFlag = true;
      this.timeWiseFlag = false;
      this.fromDateFlag = false;
      this.toDateFlag = false;
    }
  }

  clickDate(value,date){
    if(value == 'fromDate')
      this.fromDate = date;
    else
      this.toDate = date;
  }

  toUpperCaseFirstName(){
    if(this.clientFirstName != undefined){
      this.clientFirstName = this.clientFirstName.toLocaleUpperCase();
    }
  }

  toUpperCaseLastName(){
    if(this.clientLastName != undefined){
      this.clientLastName = this.clientLastName.toLocaleUpperCase();
    }
  }

  searchBy(){
    var send_data;var fname,lname;
    this.specialityListArray = [];
    this.testwiseDataList = [];
    this.srchSpecialityFlag = true;
    this.srcTestFlag = true;
    if(this.filterByText == "Patient"){
      this.srchSpecialityFlag = true;
      if(this.clientFirstName!= null){
        fname = encrypt_decript.Encript(this.clientFirstName.trim()).toString();
      }

      if(this.clientLastName!= null && this.clientLastName !=""){
        lname = encrypt_decript.Encript(this.clientLastName.trim()).toString();
      }

      send_data = {
        fname:fname,
        lname:lname,
        diag_center_id:Diagnosis_Helper.getDiag_centre_id(),
        report_type:"client"
      }

    } else if(this.filterByText == "Speciality"){
      this.srchSpecialityFlag = true;
      send_data = {
        spl_id : this.speclization,
        start_date:this.fromDate,
        end_date:this.toDate,
        diag_center_id:Diagnosis_Helper.getDiag_centre_id(),
        report_type:"specialization"
      }

    } else if(this.filterByText == "TestWise"){
      this.srcTestFlag = true;

      send_data = {
        start_date:this.fromDate,
        end_date:this.toDate,
        diag_center_id:Diagnosis_Helper.getDiag_centre_id(),
        report_type:"test"
      }
    }   

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gdr', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          var obj = data.report_details;
          if(obj != undefined && obj.length != 0){
            for(var i = 0; i < obj.length; i++){
              var fname,lname,referred_by;
              this.pathology = "";
              this.radiology = "";
              if(obj[i].appointment_date != undefined){
                if(obj[i].first_name != undefined){
                  fname = encrypt_decript.Decript(obj[i].first_name).toString();
                }else{
                  fname = "";
                }
                if(obj[i].last_name != undefined){
                  lname = encrypt_decript.Decript(obj[i].last_name).toString();
                }else{
                  lname = "";
                }
                for (var key in obj[i]) {
                  if (key == "scan_test"){
                    this.radiology = (obj[i].scan_test)
                  }
  
                  if(key == "xray_test"){
                    if(this.radiology != ''){
                      this.radiology = this.radiology + ","+(obj[i].xray_test);
                    }else{
                      this.radiology = (obj[i].xray_test);
                    }
                  }
  
                  if(key == "xray_test"){
                    if(this.radiology != ''){
                      this.radiology = this.radiology + ","+(obj[i].xray_test);
                    }else{
                      this.radiology = (obj[i].xray_test);
                    }
                  }
  
                  if(key == "ultra_test"){
                    if(this.radiology != ''){
                      this.radiology = this.radiology + ","+(obj[i].ultra_test);
                    }else{
                      this.radiology = (obj[i].ultra_test);
                    }
                  }
                  
                  if(key == "blood_test"){
                    this.pathology = (obj[i].blood_test);
                  }
  
                  if(key == "urine_test"){
                    if(this.pathology != ''){
                      this.pathology = this.pathology +"," +(obj[i].urine_test);
                    }else{
                      this.pathology = (obj[i].urine_test);
                    }
                  }
  
                  if(key == "faeces_test"){
                    if(this.pathology != ''){
                      this.pathology = this.pathology + ","+ (obj[i].faeces_test);
                    }else{
                      this.pathology = (obj[i].faeces_test);
                    }
                  }
                }
  
                if(obj[i].referred_by != undefined && obj[i].referred_by != "null"){
                  referred_by = obj[i].referred_by;
                }else{
                  referred_by = "";
                }
  
                this.specialityListArray.push({
                  date:Date_Formate(obj[i].appointment_date),
                  patient_name:fname + " " + lname,
                  referred_by: referred_by,
                  radialogy:this.radiology,
                  pathology:this.pathology
                });
                
              }
              if(obj[i].test_name != undefined){
                  this.testwiseDataList.push(
                    {
                    test_name: obj[i].test_name,
                    test_count: obj[i].test_count
                  })
              }
            }
          }else{
            if(this.filterByText == "Patient" || this.filterByText == "Speciality"){
              this.srchSpecialityFlag = false; 
            }else{
              this.srcTestFlag = false;
            }
          }
        });
  }
}
