import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Date_Formate, Time_Formate } from '../../../assets/js/common.js';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { Helper_Class } from 'src/app/helper_class';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-purchaseorderview',
  templateUrl: './purchaseorderview.component.html',
  styleUrls: ['./purchaseorderview.component.css']
})
export class PurchaseorderviewComponent implements OnInit {
  @ViewChild('divprint') divprint: ElementRef;
  poDate: string;
  addBtn: boolean = false;
  getPrList = [];
  prDate: any;
  prTxt: any;
  purcaOrderIndex: any;
  prReq: boolean = true;
  productReqest: boolean = false;
  noPrReq: boolean;
  public orderAddress: string;
  vendor: any;
  public purchaseOrderArray = [];
  purchaseOrderArray1 = [];
  purchaseRequestRetrivelArray = [];
  poBaseTXT: any;
  orderProduct: any;
  orderUOM: any;
  totalAmount: any;
  finalamount: any;
  sendPurchaseOrderRequestarry = [];
  purchaseOrderRequestArray = [];
  quanorder: any = null;
  unitPrice: any;
  productorderName: any;
  gpcodeList = [];
  organizations = [];
  prList = [];
  supplerMasterArray = [];
  getItemArray = [];
  productTypeListArray = [];
  productArray = [];
  cancelFlag: boolean;
  vendorFlag: boolean;
  PObaseFlag: boolean;
  printFlag: boolean;
  public pharName: string;
  public Address1: string;
  public Address2: string;
  public location: string;
  public city: string;
  public state: string;
  public country: string;
  public telephone: string;
  public hospImg: string;
  public productID: string;
  public productName: string;
  public vendorID: string;
  public hsnNO: string;
  public productType: string
  public paid: string;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public pharmacyID;
  public pharmacistID;
  public userID;
  public getInfo;
  public ipaddress;
  public supplierData = [];
  public saveFlag: boolean = true;

  constructor(public messageservice: PharmacyService, public toastr: ToastrService, public gservice: CommonDataService, public http: Http, public router: Router, public dialog: MatDialog) {
    this.prReq = true;
    this.noPrReq = true;
    this.purcaOrderIndex = 0;
  }

  ngOnInit() {
    this.paid = pharmacy_helper.getpoid();
    this.getInfo = Helper_Class.getInfo();
    this.userID = this.getInfo.user_id;
    this.pharmacyID = this.getInfo.hospitals[0].hptl_clinic_id;
    this.ipaddress = Helper_Class.getIPAddress();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by PR no or date"
      },
      "paging": false,
      "dom": "lfrti",
    };
    //this.SupplierLoad();
    this.product_retrieval();

    if (this.paid.length != 0) {
      this.PODetails();
      this.cancelFlag = false;
      this.vendorFlag = true;
      this.PObaseFlag = true;
      this.printFlag = false;

    } else {
      this.addBtn = true;
      this.vendorFlag = false;
      this.PObaseFlag = false;
      this.cancelFlag = true;
      this.printFlag = true;
    }
  }

  get_type() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "pharmacontrol/mtypes/").subscribe(
      data => {
        this.productTypeListArray = [];
        if (data.json().med_details != null) {
          this.productTypeListArray = data.json().med_details;
        }
      },
      error => { });
  }

  getVendor() {
    if(this.vendor != undefined && this.vendor.length >2){
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gsupp/", JSON.stringify({
        pharmacist_id: this.userID,
        pharmacy_id: this.pharmacyID,
        imei: this.ipaddress,
        name: this.vendor,
      }), { headers: headers }).subscribe(
        data => {
          var suppler_index = 0;
          var obj = data.json();
          this.supplierData = data.json().suppliers;
          if (this.supplierData.length != 0) {
            for (var i = 0; i < this.supplierData.length; i++) {
              if (this.supplierData[i].address2 != null && this.supplierData[i].address2 != undefined) {
                var add_txt = this.supplierData[i].address1 + "," + this.supplierData[i].address2;
              
              } else {
                add_txt = this.supplierData[i].address1;
              }
              this.supplerMasterArray.push({
                Index: suppler_index,
                name: this.supplierData[i].name,
                mfg_code: this.supplierData[i].supp_code,
                address: add_txt,
                city_desc: this.supplierData[i].city_desc,
                state_desc: this.supplierData[i].state_desc,
                country_desc: this.supplierData[i].country_desc,
                telephone: this.supplierData[i].telephone,
                mobile: this.supplierData[i].mobile,
                supp_id: this.supplierData[i].supp_id,
                city: this.supplierData[i].city,
                state: this.supplierData[i].state,
                country: this.supplierData[i].country,
              });
              suppler_index++;
            }
            //this.vendor = this.supplierData[0].name;
            //this.vendoraddress_change(this.vendor);
          }
        },
        error => { });
    }
  }

  product_retrieval() {
    this.gpcodeList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpcbyphar/", JSON.stringify({
      pharmacist_id: this.userID,
      pharmacy_id: this.pharmacyID,
      imei: this.ipaddress,
      start_no: "0",
      limit: "1000"
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().products != null) {
          this.gpcodeList = data.json().products;
        }
      },
      error => { });
  }

  purchase_orderdelete(id, qty) {
    if (this.purchaseOrderArray.length != 0) {
      for (var i = 0; i < this.purchaseOrderArray.length; i++) {
        if (this.purchaseOrderArray[i].product_id == id && this.purchaseOrderArray[i].quantity == qty) {
          for (var j = 0; j < this.sendPurchaseOrderRequestarry.length; j++) {
            if (this.purchaseOrderArray[i].purchase_request_id == this.sendPurchaseOrderRequestarry[j].purchase_request_id) {
              this.sendPurchaseOrderRequestarry.splice(j, 1);
            }
          }
          this.purchaseOrderArray1.splice(i, 1);
          this.purchaseOrderArray.splice(i, 1);
        }
      }
    }
    var totamount = 0;
    for (var i = 0; i < this.purchaseOrderArray.length; i++) {
      totamount = parseFloat(totamount.toString()) + parseFloat(this.purchaseOrderArray[i].tot_amount);
    }
    var fin_amt = 0;
    this.totalAmount = parseFloat(totamount.toString()) + parseFloat(fin_amt.toString()).toFixed(2);
  }

  AddClick() {
    this.purchaseOrderRequestArray = [];
    var qty_data = 0;
    var amount_txt = this.quanorder * this.unitPrice;
    this.supplerMasterArray.forEach(element => {
      element.supp_id;
      if (element.name == this.vendor) {
        this.vendorID = element.supp_id
      }
    });

    if (this.productName == undefined && this.quanorder == undefined) {
      this.toastr.error(Message_data.fillPrdAndQty);
    } else {
      this.purchaseOrderArray.push({
        product_id: this.productID,
        product_name: this.productName,
        uom_desc: this.orderUOM,
        quantity: this.quanorder,
        unit_price: parseFloat(this.unitPrice).toFixed(2),
        supp_id: this.vendorID,
        hsn_no: this.hsnNO,
        order_uom: this.orderUOM,
        total_amount: "0.00",
      })
    }

    if (this.poBaseTXT == "Purchase Request") {
      if (this.purchaseOrderArray.length != 0) {
        var flag_cgh = true;
        for (var j = 0; j < this.purchaseOrderArray.length; j++) {
          if (this.purchaseOrderArray[j].product_id == this.productID) {
            flag_cgh = false;
            this.purchaseOrderRequestArray.push({
              product_id: this.purchaseOrderArray[j].product_id,
              quantity: this.quanorder,
            });
            var qty_data = parseFloat(this.purchaseOrderArray[j].quantity);// + parseFloat(this.quanorder_txt);
            var tot_amt = parseFloat(qty_data.toString()) * parseFloat(this.unitPrice);
            this.purchaseOrderArray.splice(j, 1);
            this.purchaseOrderArray1.splice(j, 1);
            this.purchaseOrderArray.push({
              Index: this.purcaOrderIndex,
              product_id: this.productID,
              product_name: this.productName,
              uom_desc: this.orderUOM,
              quantity: qty_data,
              unit_price: parseFloat(this.unitPrice).toFixed(2),
              total_amount: tot_amt.toFixed(2),
              supp_id: this.vendor.supp_id,
              po_base: this.poBaseTXT,
              address: this.orderAddress,
              product_code: this.orderProduct,
              order_uom: this.orderUOM,
              purchase_request_id: this.prTxt,
            });
            this.purchaseOrderArray1.push({
              product_id: this.productID,
              quantity: qty_data,
              unit_price: parseFloat(this.unitPrice).toFixed(2),
              total_amount: parseFloat(tot_amt.toString()).toFixed(2),
              purchase_order_id: this.paid
            });
            break;
          }
        }

        if (flag_cgh == true) {
          var tot_amt = parseFloat(this.quanorder) * parseFloat(this.unitPrice);
          this.purchaseOrderArray.push({
            Index: this.purcaOrderIndex,
            product_id: this.productID,
            product_name: this.productName,
            uom_desc: this.orderUOM,
            quantity: this.quanorder,
            unit_price: parseFloat(this.unitPrice).toFixed(2),
            total_amount: tot_amt.toFixed(2),
            supp_id: this.vendor.supp_id,
            po_base: this.poBaseTXT,
            address: this.orderAddress,
            product_code: this.orderProduct,
            order_uom: this.orderUOM,
            purchase_request_id: this.prTxt,
          });
          this.purchaseOrderArray1.push({
            product_id: this.productID,
            quantity: this.quanorder,
            unit_price: parseFloat(this.unitPrice).toFixed(2),
            total_amount: parseFloat(tot_amt.toString()).toFixed(2),
            purchase_order_id: this.paid
          });
          this.purchaseOrderRequestArray.push({
            product_id: this.productID,
            quantity: this.quanorder,
          });
        }

      } else {
        var tot_amt = parseFloat(this.quanorder) * parseFloat(this.unitPrice);
        this.purchaseOrderArray.push({
          Index: this.purcaOrderIndex,
          product_id: this.productID,
          product_name: this.productName,
          uom_desc: this.orderUOM,
          quantity: this.quanorder,
          unit_price: parseFloat(this.unitPrice).toFixed(2),
          total_amount: tot_amt.toFixed(2),
          supp_id: this.vendor.supp_id,
          po_base: this.poBaseTXT,
          address: this.orderAddress,
          product_code: this.orderProduct,
          order_uom: this.orderUOM,
          purchase_request_id: this.prTxt,
        });
        this.purchaseOrderArray1.push({
          product_id: this.productID,
          quantity: this.quanorder,
          unit_price: parseFloat(this.unitPrice).toFixed(2),
          total_amount: parseFloat(tot_amt.toString()).toFixed(2),
          purchase_order_id: this.paid
        });
        this.purchaseOrderRequestArray.push({
          product_id: this.productID,
          quantity: this.quanorder,
        });
      }

    } else if (this.poBaseTXT == "No Purchase Request") {
      for (var i = 0; i < this.gpcodeList.length; i++) {
        if (this.gpcodeList[i].name == this.productorderName) {
          this.purchaseOrderArray.push({
            Index: this.purcaOrderIndex,
            product_id: this.gpcodeList[i].product_id,
            product_name: this.gpcodeList[i].name,
            uom_code: this.gpcodeList[i].uom_code,
            uom_desc: this.gpcodeList[i].uom_desc,
            quantity: this.quanorder,
            unit_price: parseFloat(this.unitPrice).toFixed(2),
            total_amount: amount_txt.toFixed(2),
            supp_id: this.vendor.supp_id,
            po_base: this.poBaseTXT,
            address: this.orderAddress,
            product_code: this.orderProduct,
            order_uom: this.orderUOM,
            purchase_request_id: this.prTxt,
            pr_date: this.prDate,
          });
          this.purchaseOrderArray1.push({
            product_id: this.gpcodeList[i].product_id,
            quantity: this.quanorder,
            unit_price: parseFloat(this.unitPrice).toFixed(2),
            total_amount: parseFloat(amount_txt.toString()).toFixed(2),
            purchase_order_id: this.paid
          });
        }
      }
      this.productName="";
    }
    //Tax
    this.sendPurchaseOrderRequestarry.push({
      purchase_request_id: this.prTxt,
      pr_items: this.purchaseOrderRequestArray,
    });
    var totamount = 0;
    var fin_amt = 0;
    this.totalAmount = parseFloat(totamount.toString()) + parseFloat(fin_amt.toString());
    this.purcaOrderIndex++;
    this.prTxt = undefined;
    this.productorderName = "";
    this.orderProduct = "";
    this.orderUOM = "";
    this.unitPrice = "";
    this.quanorder = "";
  }

  clearProdName() {
    this.productName = "";
  }
  
  get_product_details() {
    for (var i = 0; i < this.supplerMasterArray.length; i++) {
      if (this.supplierData[i].name == this.vendor) {
        this.vendorID = this.supplierData[i].supp_id
        break;
      }
    }
    var headers = new Headers();
    var data1 = JSON.stringify(
      {
        pharmacist_id: this.userID,
        pharmacy_id: this.pharmacyID,
        prod_code: this.orderProduct,
        prod_name: this.productName,
        supp_id: this.vendorID,
        prod_type: this.productType,
      })
      console.log( "data1" + JSON.stringify(data1));
    if( this.productName.length > 2 ){
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gpcbypharn/", data1, { headers: headers }).subscribe(
          data => {
            this.productArray = [];
            //products
            var dataval = data.json();
            console.log( "dataval" + JSON.stringify(dataval));
            if (data.json() != undefined && data.json().products != null) {
              this.productArray = data.json().products;
            }
          });
    }
    
  }

  get_product_name(data) {
    this.productType = data.product_type_id; 
    this.productName = data.name;
    this.orderUOM = data.uom_desc;
    this.productID = data.product_id;
    this.orderProduct = data.hsn_no;
    this.unitPrice = data.cost_price;
    this.hsnNO = data.hsn_no;
    this.productArray = [];
  }

  PODetails() {
    if (this.paid != null && this.paid != undefined && this.paid != "") {
      var poidvalue = this.paid;
    }
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpo/", JSON.stringify({ purchase_order_id: poidvalue }), { headers: headers }).subscribe(
      data => {
        if (data.json().supp_id != null) {
          // for (var i = 0; i < this.supplierData.length; i++) {
          //   if (data.json().supp_id == this.supplierData[i].supp_id) {
          //     this.vendor = this.supplierData[i].name;
          //     break;
          //   }
          // }
          this.vendorID = data.json().supp_id;
          this.vendor = data.json().name;
          
          this.printFlag = false;
          var podate = data.json().po_date.split('-');
          this.poDate = podate[2] + "-" + podate[1] + "-" + podate[0];
          if (data.json().logo != undefined) {
            this.hospImg = ipaddress.Ip_with_img_address + data.json().logo;
          } else {
            this.hospImg = "assets/imgs/HospitalIcon.png";
          }
          this.pharName = data.json().pharmacy_name;
          this.Address1 = data.json().phar_address1;
          if (data.json().phar_address2 != undefined) {
            this.Address2 = data.json().phar_address2;
          } else {
            this.Address2 = "";
          }
          this.location = data.json().phar_location;
          this.city = data.json().phar_city;
          this.state = data.json().phar_state;
          this.country = data.json().phar_country;
          this.telephone = data.json().phar_telephone;
          var purchaseadd = new StringBuilder("");
          if (data.json().address1 != null) {
            purchaseadd.append(data.json().address1);
            purchaseadd.append("\n");
            if (data.json().address2 != null && data.json().address2 != undefined) {
              purchaseadd.append(data.json().address2);
              purchaseadd.append("\n");
            }
            purchaseadd.append(data.json().city_desc);
            purchaseadd.append("\n");
            purchaseadd.append(data.json().state_desc);
            purchaseadd.append("\n");
            purchaseadd.append(data.json().country_desc);
            purchaseadd.append("\n");
            purchaseadd.append("Mobile :" + data.json().mobile);
            purchaseadd.append("\n");
            purchaseadd.append("Telephone :" + data.json().telephone);
            purchaseadd.append("\n");
            this.orderAddress = purchaseadd.toString();
          }
          if (data.json().po_base != undefined) {
            this.poBaseTXT = data.json().po_base;
          }
          // purchase order list table and GST table view
          this.purchaseOrderArray = [];
          if (data.json().purchase_items != null) {
            var mfgid;
            if (this.vendor != undefined) {
              mfgid = this.vendor.supp_id;
            }
            for (var i = 0; i < data.json().purchase_items.length; i++) {
              this.purchaseOrderArray.push({
                product_id: data.json().purchase_items[i].product_id,
                product_name: data.json().purchase_items[i].product_name,
                uom_code: data.json().purchase_items[i].uom_code,
                uom_desc: data.json().purchase_items[i].uom_desc,
                quantity: data.json().purchase_items[i].quantity,
                unit_price: data.json().purchase_items[i].unit_price,
                purchase_request_id: data.json().purchase_items[i].purchase_request_id,
                supp_id: mfgid,
                po_base: this.poBaseTXT,
                address: this.orderAddress,
                hsn_no: this.orderProduct,
                order_uom: this.orderUOM,
                total_amount: "0.00",
              });
              this.purchaseOrderRequestArray.push({
                product_id: data.json().purchase_items[i].product_id,
                quantity: data.json().purchase_items[i].quantity,
              });
              this.purchaseOrderArray1.push({
                product_id: data.json().purchase_items[i].product_id,
                quantity: data.json().purchase_items[i].quantity,
                unit_price: data.json().purchase_items[i].unit_price,
                total_amount: "0.00",
                purchase_order_id: this.paid
              });
            }
          }
          this.sendPurchaseOrderRequestarry.push({
            purchase_request_id: this.paid,
            pr_items: this.purchaseOrderRequestArray,
          });
          var prec_tx = 0.0;
          for (var i = 0; i < this.purchaseOrderArray.length; i++) {
            prec_tx = parseFloat(prec_tx.toString()) + parseFloat(this.purchaseOrderArray[i].tot_amount);
          }
          this.finalamount = 0;
          var x = parseFloat(prec_tx.toString()) + parseFloat(this.finalamount);
          x = Math.floor(x * 100) / 100;
          this.totalAmount = x.toFixed(2);
          if (data.json().purchase_requests != null) {
            for (var i = 0; i < data.json().purchase_requests.length; i++) {
              this.purchaseRequestRetrivelArray.push({
                purchase_request_id: data.json().purchase_requests[i].purchase_request_id,
                created_date: data.json().purchase_requests[i].created_date,
              })
            }
          }
        }
      },
      error => { })
  }

  cancelClick() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/cpo/", JSON.stringify({ purchase_order_id: this.paid }), { headers: headers }).subscribe(
      data => {
        if (data.json().key != null && data.json().key == "1") {
          this.toastr.success(Message_data.poCancelSuccess);
          this.messageservice.sendMessage("pharmacyresponse");
        }
      },
      error => { })
  }

  array_exits(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].test_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  saveClick() {
    var flag = true;
    if (this.sendPurchaseOrderRequestarry.length != 0) {
      var pur_id_tmp_arr = [];
      for (var i = 0; i < this.sendPurchaseOrderRequestarry.length; i++) {
        if (this.array_exits(this.sendPurchaseOrderRequestarry, this.sendPurchaseOrderRequestarry[i].purchase_request_id)) {
          //do nothing
        } else {
          pur_id_tmp_arr.push(this.sendPurchaseOrderRequestarry[i].purchase_request_id);
        }
      }
      var main_arr = [];
      for (var i = 0; i < pur_id_tmp_arr.length; i++) {
        var add_new_tmp = [];
        for (var j = 0; j < this.sendPurchaseOrderRequestarry.length; j++) {
          if (pur_id_tmp_arr[i] == this.sendPurchaseOrderRequestarry[j].purchase_request_id) {
            add_new_tmp.push(this.sendPurchaseOrderRequestarry[j].pr_items);
          }
        }
        var myString = JSON.stringify(add_new_tmp);
        var sillyString = myString.substr(1).slice(0, -1);
        main_arr.push({
          purchase_request_id: pur_id_tmp_arr[i],
          pr_items: sillyString,
        });
      }
    }
    if (this.purchaseOrderArray.length == 0) {
      flag = false;
      this.saveFlag = true;
    }
    if (flag == true) {
      this.saveFlag = false;
      var mfg_txt = undefined;
      if (this.supplierData != undefined) {
        for (var i = 0; i < this.supplierData.length; i++) {
          if (this.vendor == this.supplierData[i].name) {
            mfg_txt = this.supplierData[i].supp_id;
            break;
          }
        }
      }
      var upturl = "";
      if (this.paid.length != 0) {
        upturl = "pharmacontrol/upo/"
      } else {
        upturl = "pharmacontrol/spo/"
      }
      if (this.paid != '') {
        var data_poid = this.paid;
      }
      if (main_arr != null || main_arr != undefined) {
        main_arr = main_arr;
      }
      var sendda = JSON.stringify({
        supp_id: mfg_txt,
        purchase_requests: main_arr,
        ordered_items: this.purchaseOrderArray,
        pharmacist_id: this.userID,
        po_base: this.poBaseTXT,
        purchase_order_id: data_poid,
        country: ipaddress.country_code,
      });
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + upturl, sendda, { headers: headers }).subscribe(
        data => {
          if (data.json().key != null && data.json().key == "1") {
            this.saveFlag = false;
            this.toastr.success(Message_data.poSaveSuccess);
            this.messageservice.sendMessage("pharmacyresponse");
          } else {
            this.saveFlag = true;
            this.toastr.error(Message_data.unableToSave);
          }
        },
        error => { this.saveFlag = true; });
    }
  }

  pr_change(e, dataval) {
    var obj2 = e;
    var obj = dataval;
    if (e.checked != false) {
      this.prDate = undefined;
      this.productorderName = undefined;
      this.orderUOM = undefined;
      this.orderProduct = undefined;
      this.unitPrice = undefined;
      this.quanorder = undefined;
      this.organizations = [];
      for (var i = 0; i < this.prList.length; i++) {
        if (dataval.purchase_request_id == this.prList[i].purchase_request_id) {
          this.prDate = Date_Formate(this.prList[i].created_date);
          this.prList[i].checked = true;
          break;
        }
      }
      var get_item_list = [];
      var supp_id = null;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      for (var i = 0; i < this.supplierData.length; i++) {
        if (this.vendor == this.supplierData[i].name) {
          supp_id = this.supplierData[i].supp_id;
          break;
        }
      }
      this.http.post(ipaddress.getIp + "pharmacontrol/gprid/", JSON.stringify({
        pr_id: dataval.purchase_request_id,
        pharma_id: this.pharmacyID,
        suppid: supp_id
      }), { headers: headers }).subscribe(
        data => {
          get_item_list = data.json().item_details;
          if (data.json().item_details != undefined) {
            for (var i = 0; i < get_item_list.length; i++) {
              this.purchaseOrderArray.push({
                product_id: get_item_list[i].product_id,
                product_name: get_item_list[i].product_name,
                uom_desc: get_item_list[i].uom_desc,
                quantity: get_item_list[i].quantity,
                unit_price: parseFloat(get_item_list[i].cost_price).toFixed(2),
                supp_id: this.vendor.supp_id,
                po_base: this.poBaseTXT,
                address: this.orderAddress,
                hsn_no: get_item_list[i].hsn_no,
                purchase_request_id: dataval.purchase_request_id,
                total_amount: "0.00",
              })
            }
            for (var i = 0; i < this.getItemArray.length; i++) {
              this.organizations.push({
                name: this.getItemArray[i].product_name,
                hsn_no: this.getItemArray[i].hsn_no,
                product_id: this.getItemArray[i].product_id
              });
            }
          }
          this.sendPurchaseOrderRequestarry.push({
            purchase_request_id: dataval.purchase_request_id,
            pr_items: this.purchaseOrderRequestArray,
          });
        },
        error => { });
    } else {
      //need to work on removing items on unselect
    }
  }

  purchase_edit(id, qty) {
    for (var i = 0; i < this.purchaseOrderArray.length; i++) {
      if (this.purchaseOrderArray[i].product_id == id && this.purchaseOrderArray[i].quantity == qty) {
        this.productID = this.purchaseOrderArray[i].product_id;
        this.orderProduct = this.purchaseOrderArray[i].hsn_no;
        this.productName = this.purchaseOrderArray[i].product_name;
        this.orderUOM = this.purchaseOrderArray[i].uom_desc;
        this.unitPrice = this.purchaseOrderArray[i].unit_price;
        this.quanorder = this.purchaseOrderArray[i].quantity;
        this.purchaseOrderArray.splice(i, 1);
      }
    }
  }

  po_basechange(e) {
    this.sendPurchaseOrderRequestarry = [];
    this.organizations = [];
    this.purchaseOrderArray = [];
    var supp_id = null;
    for (var i = 0; i < this.supplierData.length; i++) {
      if (this.vendor == this.supplierData[i].name) {
        supp_id = this.supplierData[i].supp_id;
        break;
      }
    }
    if (this.poBaseTXT == "Purchase Request") {
      this.prReq = false;
      this.noPrReq = false;
      this.productReqest = false;
      //PR number
      this.getPrList = []
      this.prList = [];
      this.get_type();
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gprs/", JSON.stringify({
        pharmacist_id: this.userID,
        supp_id: supp_id,
      }), { headers: headers }).subscribe(
        data => {
          this.getPrList = data.json().purchase_requests;
          if (this.getPrList != undefined) {
            for (var i = 0; i < this.getPrList.length; i++) {
              var dataval = this.getPrList[i].created_date.split('-');
              this.prList.push({
                purchase_request_id: this.getPrList[i].purchase_request_id,
                created_date: dataval[2] + "-" + dataval[1] + "-" + dataval[0],
                checked: false,
              });
            }
          }
        },
        error => { });
    } else if (this.poBaseTXT == "No Purchase Request") {
      this.get_type();
      this.prReq = true;
      this.productReqest = true;
      this.noPrReq = false;
      for (var i = 0; i < this.gpcodeList.length; i++) {
        this.organizations.push({
          name: this.gpcodeList[i].name,
          hsn_no: this.gpcodeList[i].hsn_no,
          product_id: this.gpcodeList[i].product_id
        });
      }
    } else {
      this.prReq = true;
      this.noPrReq = true;
    }
  }

  product_namechange() {
    this.orderUOM = "";
    this.orderProduct = "";
    this.unitPrice = "";
    this.quanorder = "";
    if (this.poBaseTXT == "Purchase Request") {
      for (var i = 0; i < this.getItemArray.length; i++) {
        if (this.productorderName == this.getItemArray[i].product_name) {
          this.orderUOM = this.getItemArray[i].uom_desc;
          this.orderProduct = this.getItemArray[i].hsn_no;
          this.unitPrice = this.getItemArray[i].cost_price;
          this.quanorder = this.getItemArray[i].quantity;
          break;
        }
      }
    } else {
      for (var i = 0; i < this.gpcodeList.length; i++) {
        if (this.productorderName == this.gpcodeList[i].name) {
          this.orderUOM = this.gpcodeList[i].uom_desc;
          this.orderProduct = this.gpcodeList[i].hsn_no;
          this.unitPrice = this.gpcodeList[i].cost_price;
          break;
        }
      }
    }
  }

  vendoraddress_change(vendor) {
    this.vendor = vendor.name;
    this.purchaseOrderArray1 = [];
    this.prReq = true;
    this.noPrReq = true;
    this.organizations = [];
    this.purchaseOrderArray = [];
    this.orderUOM = undefined;
    this.orderProduct = undefined;
    this.unitPrice = undefined;
    this.quanorder = undefined;
    this.orderAddress = undefined;
    this.poBaseTXT = undefined;
    this.prTxt = undefined;
    this.prDate = undefined;
    for (var i = 0; i < this.supplerMasterArray.length; i++) {
      if (this.vendor == this.supplerMasterArray[i].name) {
        var split_data = this.supplerMasterArray[i].address.split(",");
        if (split_data[1] != " ") {
          this.orderAddress = split_data[0] + ", " + this.supplerMasterArray[i].city_desc + ", " + this.supplerMasterArray[i].state_desc + ", " + this.supplerMasterArray[i].country_desc;
        } else {
          this.orderAddress = split_data[0] + ", " + split_data[1] + ", " + this.supplerMasterArray[i].city_desc + ", " + this.supplerMasterArray[i].state_desc + ", " + this.supplerMasterArray[i].country_desc;
        }
      }
    }
  }

  backClicked() {
    this.messageservice.sendMessage("pharmacyresponse");
  }

  print_area() {
    let printContents, popupWin;
    printContents = document.getElementById('divprint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Prescription</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        .casesheet_container {
          width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`
    );
    popupWin.document.close();
  }

  back_Nav() {
    //  this.router.navigateByUrl("/pharmahome")
  }
}
