<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Inter department transfer</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" (click)="saveOrder()" />
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
              <mat-label class="matlabel">Department<br>
                <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="department">
                  <mat-option *ngFor="let dept of dept_array" value={{dept.dept_id}}>{{dept.dept_desc}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Receiver Name<br>
                  <input type="text" class="ipcss" [(ngModel)]="rec_name" #matInput />
                </mat-label>
              </div>
          </div>
  
          <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
            <div id="pharma_tbl_overlay" class="dig_table_overflow table-responsive" style="height: 350px;">
              <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px;">
                <thead>
                  <tr>
                    <th style="width: 45px;">Si.No</th>
                    <th style="width: 200px;" *ngIf="genericDisplayFlag">Generic name</th>
                    <th style="width: 200px;">Medicine name</th>
                    <th style="width: 110px;">Batch no</th>
                    <th style="width: 100px;">Issued qty</th>
                    <th style="width: 100px;">Returned qty</th>
                    <th style="width: 50px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let drug of drugDetailsArray;let i = index" class="tdvalues">
                    <th >{{i + 1}} </th>
                    <td *ngIf="genericDisplayFlag">
                      <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                        (keyup)="changeMedicineName($event,'generic')" maxlength="50" [(ngModel)]="drug.gen_name" matInput  [matAutocomplete]="auto3" 
                       />
                       
                      <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                        <mat-option (click)="selectMedicineItem(medicine,drug)" *ngFor="let medicine of MedicneData" value="{{medicine.name}}">
                          <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                        </mat-option>
                      </mat-autocomplete>
                    </td>
                    <td>
                      <input type="text" class="ipcss order_width getMedcien" required maxlength="50" (keyup)="changeMedicineName($event,'medicine')" maxlength="50" [(ngModel)]="drug.drug_name" matInput  [matAutocomplete]="auto2" />
                      <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                        <mat-option (click)="selectMedicineItem(medicine,drug)" *ngFor="let medicine of MedicneData"  value="{{medicine.name}}">
                          <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                        </mat-option>
                      </mat-autocomplete>
                    </td>
                    <td>
                      <input type="text" class="ipcss order_width" [ngStyle]="drug.batch_required" required
                        [(ngModel)]="drug.batch_no" matInput maxlength="50" readonly autocomplete="on"
                        (click)="getCORD($event,'drug_'+drug.index);drug.show_batcharray = !drug.show_batcharray" />
                      <div [id]="'drug_'+drug.index" class="auto_complete_text1" *ngIf="drug.batcharray.length != 0"
                        style="display: none;">
                        <table style="width: 100%;">
                           <thead>
                            <tr>
                            <th> Batch no</th>
                            <th> Qty </th>
                            <th> MRP </th>
                            <th> Exp Date </th></tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let batch of drug.batcharray" (click)="selectedBatch(batch,drug,'drug_'+drug.index)">
                            <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                            <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                            <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                            <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                          </tr>
                        </tbody>
                        </table>
                      </div>
                    </td>
                    <td>
                      <input type="text" class="ipcss order_width" [disabled]="drug.quantityDisable"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                        [(ngModel)]="drug.quantity" (keyup)="addNewDrugDetails()" #matInput />
                    </td>
                    <td>
                      <input type="text" class="ipcss order_width" [disabled]="drug.quantityDisable"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" 
                        [(ngModel)]="drug.retqty" #matInput />
                    </td>
                    <td style="text-align: center;">
                      <a (click)="deleteMedicine(drug.drug_id,drug.index)">
                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>