import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate, Date_Formate } from '../../../assets/js/common.js';
import { DomSanitizer } from '@angular/platform-browser';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../helper_class';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import htmlToPdfmake from 'html-to-pdfmake';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare const require: any;

@Component({
    selector: 'app-diagnosis-report-detailview',
    templateUrl: './diagnosis-report-detailview.component.html',
    styleUrls: ['./diagnosis-report-detailview.component.scss']
})
export class DiagnosisReportDetailviewComponent implements OnInit {
    @ViewChild('printlogowithname') public printlogowithname: ElementRef = {} as ElementRef;
    @ViewChild('printbanner') public printbanner: ElementRef = {} as ElementRef;
    @ViewChild('printnoheader') public printnoheader: ElementRef = {} as ElementRef;
    @ViewChild('printnoheadsidebar') public printnoheadsidebar: ElementRef = {} as ElementRef;
    
    pdfTable!: ElementRef;
    public tableReportTypeFlag: boolean;
    public paraReportTypeFlag: boolean;

    public reportType;
    public diagAppID;
    public presTestID;
    public presDiagID;

    public clientAnotherName;
    public clientGender;
    public clientAge;
    public reviewSign;
    public signFlag: boolean;
    public sigSectionFlag: boolean = false;
    public reviewFlag: boolean;
    public reviewBy;
    public sampleCollectedTime;
    public reportIMG;
    public dicomDotor: boolean = true;
    public sequences;
    public impression;
    public viewTestArray = [];
    public diagTestReadingArray = [];
    public centerName;
    public diagAddress;
    public diagLocation;
    public diagCityZip;
    public diagStateCon;
    public diagTelephone;
    public clientID;
    public diagReferedBy;
    public date;
    public time;
    public testArray = [];
    public sequence;
    public Impression;
    public ReportFlag;
    public approvelFlag: boolean;
    public uploadFlag: boolean;
    public loader: boolean;
    public reportDetails;
    public testName: string;
    public referFlag: boolean = false;
    public innerFrame;
    public iFrame;
    public pdfObj;
    public content;
    public suntestNameFlag: boolean = false;
    public testMethod;
    public printFlag: boolean;
    public printTemplate;
    public hospitalFooter;
    public printfFlag;
    public bgImage;
    public hospImage;
    public hospitalLogo;
    public imageString;
    public patientID: any;
    public subtestMethodFlag: boolean;
    public createdBy;
    public printArray = [];
    public culture;
    public cultureFlag: boolean = false;
    public testrangeFlag: boolean = false;
    public userInfo;
    public hospitalDetails;
    public hospitalName;
    public hptlLogo;
    public location;
    public city;
    public state;
    public country;

    constructor(public santizer: DomSanitizer, public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) {
    }

    ngOnInit(): void {
        this.userInfo = Helper_Class.getInfo();
        this.hospitalDetails = this.userInfo.hospitals;
        this.hospitalName = this.hospitalDetails[0].hptl_name;
        this.hptlLogo = ipaddress.Ip_with_img_address + this.hospitalDetails[0].logo;
        this.location = this.hospitalDetails[0].location;
        this.city = this.hospitalDetails[0].city;
        this.state = this.hospitalDetails[0].state;
        this.country = this.hospitalDetails[0].country;
        if(this.hospitalDetails[0].test_range =="1"){
            this.testrangeFlag =true;
        } else {
            this.testrangeFlag =false;
        }
        this.reportType = Diagnosis_Helper.getReportView().type; //navParams.get("type");  
        this.diagAppID = Diagnosis_Helper.getReportView().diag_app_id;  //navParams.get("diag_app_id");
        this.presTestID = Diagnosis_Helper.getReportView().pres_test_id; //navParams.get("pres_test_id");
        this.presDiagID = Diagnosis_Helper.getReportView().pres_diag_id; // navParams.get("pres_diag_id");
        this.ReportFlag = Diagnosis_Helper.getReportView().Diag_report_flag; //navParams.get("Diag_report_flag");
        this.testName = Diagnosis_Helper.getReportView().test_name; //navParams.get("test_name");

        if (this.ReportFlag == "Approval") {
            this.approvelFlag = false;
            this.uploadFlag = true;
        } else {
            this.approvelFlag = true;
            this.uploadFlag = false;
        }

        if (this.reportType == "blood" || this.reportType == "urine" || this.reportType == "faeces") {
            this.tableReportTypeFlag = false;
            this.paraReportTypeFlag = true;
        } else {// Scan == X-ray == Ultra sound
            this.tableReportTypeFlag = true;
            this.paraReportTypeFlag = false;
            this.cultureFlag = this.reportType == "Culture" ? true : false;
        }
        this.printFlag = Helper_Class.getHospitalInfo().report_print == "1" ? true : false;
        this.clientGender = "0";
        this.clientAge = "0";

        if (Doc_Helper.getHospital_logo() != undefined){
            this.hospImage = Doc_Helper.getHospital_logo();
        }
        this.hospImage = Doc_Helper.getHospital_logo();

        if (Doc_Helper.getHospFooter() != undefined) {
            this.hospitalFooter = Doc_Helper.getHospFooter();
            this.printfFlag = false;
        } else {
            this.printfFlag = true;
        }

        this.getBase64Image(this.hospImage);
        this.printTemplate = Doc_Helper.getHospital_print_template() != undefined && Doc_Helper.getHospital_print_template() != "undefined" ? Doc_Helper.getHospital_print_template() : "noheader";
        if (Doc_Helper.getHospital_pres_logo() != undefined && Doc_Helper.getHospital_pres_logo() != "undefined")
            this.hospitalLogo = Doc_Helper.getHospital_pres_logo();

        if (Doc_Helper.getHospital_bg_image() != undefined)
            this.bgImage = Doc_Helper.getHospital_bg_image();

        this.getRetrieval();
    }

    getRetrieval() {//Get report details
        this.loader = false;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'record/diagreportview/',
            JSON.stringify({
                flag: "diagnosis",
                country: ipaddress.country_code,
                diag_app_id: this.diagAppID,
                type: this.reportType,
                pres_test_id: this.presTestID
            }),
            { headers: headers })
            .subscribe(
                response => {
                    var obj = JSON.parse(response["_body"]);
                    if (obj != null) {
                        this.centerName = obj.diag_centre_name;
                        this.diagAddress = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;

                        if (obj.date_entered != undefined)
                            this.date = Date_Formate(obj.date_entered);

                        if (obj.time_entered != undefined)
                            this.time = Time_Formate(obj.time_entered);

                        this.diagLocation = obj.location;
                        this.diagCityZip = obj.city + "-" + obj.zipcode;
                        this.diagStateCon = obj.state + "-" + obj.country;
                        this.diagTelephone = obj.telephone;
                        this.clientID = obj.client_reg_id;
                        this.patientID = obj.patient_id;

                        if (obj.first_name != null) {
                            if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "")
                                this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                            else
                                this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                        }

                        if (obj.referred_by != undefined && obj.referred_by != "" && obj.referred_by != null && obj.referred_by != "null") {
                            this.referFlag = true;
                            this.diagReferedBy = obj.referred_by;
                        }

                        if (obj.gender != null)
                            this.clientGender = encrypt_decript.Decript(obj.gender);

                        if (obj.age != null)
                            this.clientAge = obj.age == 0 ? "<1" : obj.age;
                        else
                            this.clientAge = "";

                        if (obj.sign != undefined && obj.sign != "") {
                            this.reviewSign = "data:image/png;base64," + obj.sign;
                            this.signFlag = false;
                        } else {
                            this.signFlag = true;
                        }

                        if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
                            this.sigSectionFlag = true;

                        this.reviewFlag = obj.app_first_name != null ? false : true;
                        this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;

                        if (obj.time_collected != null)
                            this.sampleCollectedTime = Time_Formate(obj.time_collected);

                        if (obj.main_tests != null && obj.main_tests.length != 0) {
                            this.printArray = [];
                            if (obj.main_tests[0].image != null) {
                                this.reportDetails = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                                this.reportIMG = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                                this.dicomDotor = false;
                            }
                            this.viewTestArray = [];

                            for (var i = 0; i < obj.main_tests.length; i++) {
                                this.presTestID = obj.main_tests[i].pres_test_id;
                                this.sequences = obj.main_tests[i].sequences;
                                this.impression = obj.main_tests[i].impression;

                                if (obj.main_tests[i].created_by != undefined)
                                    this.createdBy = obj.main_tests[i].created_by;

                                if (obj.main_tests[i].test_method != undefined && obj.main_tests[i].test_method != null 
                                    && obj.main_tests[i].test_method != "" && obj.main_tests[i].test_method != "null")
                                    this.testMethod = obj.main_tests[i].test_method;

                                var test_name;
                                test_name = obj.main_tests[i].test_name;
                                if (obj.main_tests[i].subtests != null) {
                                    this.viewTestArray = [];
                                    for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                                        var temp_value = "";
                                        var test_method = "";
                                        this.subtestMethodFlag = false;
                                        this.suntestNameFlag = obj.main_tests[i].subtests[j].sub_test_name == test_name ? false : true;
                                        
                                        if(this.testrangeFlag ==true){
                                            if(this.clientGender =="Male" && this.clientAge<1){
                                                temp_value = obj.main_tests[i].subtests[j].newborn;
                                            } else if(this.clientGender =="Male" && (this.clientAge>1 &&this.clientAge<13)){
                                                temp_value = obj.main_tests[i].subtests[j].child;
                                            } else if(this.clientGender =="Male" && this.clientAge>13){
                                                temp_value = obj.main_tests[i].subtests[j].male;
                                            } else if(this.clientGender =="Female" && this.clientAge>13){
                                                temp_value = obj.main_tests[i].subtests[j].female;
                                            }
                                            
                                        } else {
                                            if (obj.main_tests[i].subtests[j].range != undefined)
                                                temp_value = obj.main_tests[i].subtests[j].range;
                                        }

                                        if (obj.main_tests[i].subtests[j].test_method != undefined) {
                                            test_method = obj.main_tests[i].subtests[j].test_method;
                                            this.subtestMethodFlag = true;
                                        } else {
                                            this.subtestMethodFlag = false;
                                        }

                                        this.viewTestArray.push({
                                            sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                            reading: obj.main_tests[i].subtests[j].reading,
                                            unit: obj.main_tests[i].subtests[j].sub_uom,
                                            test_range: temp_value,
                                            test_method: obj.main_tests[i].subtests[j].test_method,
                                        });
                                    }
                                }

                                this.diagTestReadingArray.push({
                                    test_name: obj.main_tests[i].test_name,
                                    category:obj.main_tests[i].test_category,
                                    sub_test_list: this.viewTestArray
                                });

                                //printoption
                                this.printArray.push({
                                    test_name: obj.main_tests[i].test_name,
                                    category:obj.main_tests[i].test_category,
                                    sub_test_list: this.viewTestArray
                                });

                                if (obj.main_tests[i].image != null) {
                                    this.dicomDotor = false;
                                    this.reportDetails = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                    this.reportIMG = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                }

                                if (obj.main_tests[i].sequences != undefined) {
                                    this.sequence = obj.main_tests[i].sequences;
                                }

                                if (obj.main_tests[i].impression != undefined) {
                                    this.Impression = obj.main_tests[i].impression;
                                }

                                if (obj.main_tests[i].culture != undefined) {
                                    this.culture = obj.main_tests[i].culture;
                                }
                            }
                        }
                    }
                },
                error => {
                    this.toastr.error(Message_data.getNetworkMessage());
                });
    }

    generatePdfFromHtml(pdf, html, callback) {
        pdf.html(html, {
            'callback': function (pdf) {
                if (callback && typeof callback === 'function') {
                    callback(pdf);
                }
            }
        });
    }

    uploadReport() {//report upload
        var send_data;
        if (this.reportType == "scans") {
            send_data = {
                pres_diag_id: this.presDiagID,
                diag_app_id: this.diagAppID,
                test_id: this.presTestID,
                user_id: Diagnosis_Helper.getUserID_Type().user_id,
                country: ipaddress.country_code,
                test_type: this.reportType,
                sequences: this.sequence,
                impression: this.Impression,
            }
        } else {
            send_data = {
                pres_diag_id: this.presDiagID,
                diag_app_id: this.diagAppID,
                test_id: this.presTestID,
                user_id: Diagnosis_Helper.getUserID_Type().user_id,
                country: ipaddress.country_code,
                test_type: this.reportType,
                observation: this.sequence,
                impression: this.Impression,
            };
        }

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/udreport/", JSON.stringify(send_data), { headers: headers }).subscribe(
            data => {
                if (data.json().key == "1") {
                    this.toastr.success(Message_data.rptApprovedSuccess);
                    this.messageservice.sendMessage("completed")
                } else {
                    if (data.json().result != null)
                        this.toastr.error(data.json().result);
                    else
                        this.toastr.success(Message_data.rptApprovedNotSuccess);
                }
            });
    }

    //Get zoom image
    clickDocument() {
        // this.navCtrl.push('ReportZoomPage', {

        //     report_image: this.report_detail
        // })
    }

    rejectReport() {
        var send_data = {
            pres_diag_id: this.presDiagID,
            diag_app_id: this.diagAppID,
            test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.reportType,
            reject_reason: "modify report as discussed",
            type: "reject"
        }

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/stapp/", JSON.stringify(send_data), { headers: headers }).subscribe(
            data => {
                if (data.json().key == "1") {
                    this.toastr.error(Message_data.rptRejectedSuccess);
                } else {
                    if (data.json().result != null)
                        this.toastr.error(data.json().result);
                    else
                        this.toastr.error(Message_data.unableToRejectRpt);
                }
            });
    }

    approveReport() {//Reportapprove
        var send_data;

        if (this.reportType == "blood" || this.reportType == "urine" || this.reportType == "faeces") {
            send_data = {
                pres_diag_id: this.presDiagID,
                diag_app_id: this.diagAppID,
                test_id: this.presTestID,
                user_id: Diagnosis_Helper.getUserID_Type().user_id,
                country: ipaddress.country_code,
                test_type: this.reportType,
                type: "approve"
            }
        } else {
            if (this.reportType == "scans") {
                send_data = {
                    pres_diag_id: this.presDiagID,
                    diag_app_id: this.diagAppID,
                    test_id: this.presTestID,
                    user_id: Diagnosis_Helper.getUserID_Type().user_id,
                    country: ipaddress.country_code,
                    test_type: this.reportType,
                    sequences: this.sequence,
                    impression: this.Impression,
                    type: "approve"
                };
            } else {
                send_data = {
                    pres_diag_id: this.presDiagID,
                    diag_app_id: this.diagAppID,
                    test_id: this.presTestID,
                    user_id: Diagnosis_Helper.getUserID_Type().user_id,
                    country: ipaddress.country_code,
                    test_type: this.reportType,
                    observation: this.sequence,
                    impression: this.Impression,
                    type: "approve"
                }
            }
        }

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/stapp/", JSON.stringify(send_data), { headers: headers }).subscribe(
            data => {

                if (data.json().key == "1") {
                    this.toastr.error(Message_data.rptApprovedSuccess);
                } else {
                    if (data.json().result != null)
                        this.toastr.error(data.json().result);
                    else
                        this.toastr.error(Message_data.rptApprovedNotSuccess);
                }
            });
    }

    back_Nav() {
        this.messageservice.sendMessage("completed");
    }

    getBase64Image(data) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'gen/gb64/', {
            imagepath: data
        },
        { headers: headers })
        .subscribe(
            response => {
                var data = JSON.parse(JSON.stringify(response));
                this.imageString = "data:image/jpeg;base64," + data.imagestr;
            });
    }

    print() {
        var footerimg = this.hospitalFooter;
        var footerflag = this.printfFlag;
        var display;

        display = footerflag == true ? "display:none;" : "display:block;";
        var backimg = this.bgImage;
        let printContents, popupWin;
        if (this.printTemplate != undefined && this.printTemplate == "banner") {
            printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

        } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
            printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

        } else if (this.printTemplate != undefined && this.printTemplate == "printnoheadsidebar") {
            printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

        } else {
            printContents = this.printnoheader.nativeElement.innerHTML;
            var printContents1 = this.printnoheader.nativeElement.innerHTML;
        }
       
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
        <head>
          <title>Report</title>
          <link rel="stylesheet" media="screen" href="">
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .casesheet_container {
            width: 211px;
            padding: 1px;
            background: #277196;
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            top: 21px;
            color: #FFFFFF;
          }
          .border_style{
            border:solid 1px;
            border-color: #488aff;
          }
          .width_style{
            margin-top:4px;
            width:600px;
          }
          @media all {
            .page-break { display: none; }
            }
            
            @media print {
            .page-break { display: block; page-break-before: always; }
            }
          @media print {
            body{
              -webkit-print-color-adjust: exact;
              -moz-print-color-adjust: exact;
              -ms-print-color-adjust: exact;
              print-color-adjust: exact;
            }
            footer {
              position: fixed;
              bottom: 0;
            }
          }
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
        
      </footer>
      </html>`
        );
        popupWin.document.close();
    }
}


