<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Charge types</h5>
                    </div>
                    <div class="headerButtons">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " style="display: flex;align-items: center;">
                        <div class="cover_div" style="padding-bottom: 13px; height: fit-content;">
                            <div class="header_lable">
                                Charge types
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 select_bottom">
                                        <mat-label class="matlabel">Charge category<br>
                                            <select required [(ngModel)]="chargeCategory" class="ipcss"  >
                                                <option selected>All</option>
                                                <option >Doctor</option>
                                                <option >Lab & Radiology</option>
                                                <option >Dietician</option>
                                                <!-- <option >Pharmacy</option> -->
                                              </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 select_bottom" >
                                        <mat-label class="matlabel">Charge description<br>
                                            <input type="text" class="ipcss" required [(ngModel)]="chargeDesc" matInput>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 select_bottom" >
                                        <mat-label class="matlabel">Rate<br>
                                            <input type="text" class="ipcss" required [(ngModel)]="chargeRate" maxlength="9" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="display: grid;">
                        <mat-label class="matlabel"></mat-label>
                        <img class="" style="width: 25px; height: 25px;" (click)="save()"  src="../../../assets/ui_icons/arrow_green.svg" />
                    </div>
                    <!-- bill create -->
                    <div [hidden]="chargeList.length == 0"  class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8">
                        <div [hidden]="chargeList.length == 0" class="dig_table_overflow">
                            <table class="card_tbl table-dynamic">
                              <thead>
                                <tr>
                                    <th>Charge category</th>
                                    <th>Charge description</th>
                                    <th>Rate</th>
                                    <th>Select</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let charge of chargeList">
                                    <td class="align_left">{{charge.charge_cat}}</td>
                                    <td class="align_left">{{charge.charge_desc}}</td>
                                    <td class="align_right">{{charge.charge_rate}}</td>
                                    <td>
                                      <a (click)="editCharge(charge.charge_id)"><img class="" style="width: 20px; height: 20px;"   src="../../../assets/ui_icons/pencil_icon_New.svg" /> </a>
                                      <a (click)="deleteCharge(charge.charge_id)"><img class="" style="width: 20px; height: 20px;"   src="../../../assets/ui_icons/trash_icon.svg" /> </a>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>