import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Date_Formate } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper'
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { DietService } from '../../../app/Diet_module/diet-view/diet-view.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

var $ :any;
@Component({
  selector: 'app-doctorbilllistpage',
  templateUrl: './doctorbilllistpage.component.html',
  styleUrls: ['./doctorbilllistpage.component.css']
})
export class DoctorbilllistpageComponent implements OnInit {
  public app_bill_list = [];
  public totalamount:string;
  public totalbalance: string;
  City_location;
  public fdate;
  public tdate;
  public isMobile: boolean;
  public get_date;
  public currentYear;
  public currentTime;
  @Input() max: any;
  tomorrow = new Date();
  public msgflag: boolean = false;
  public profile_image: string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;
  public notifications;
  public notifyflag: boolean = false;
  public curr_date = null;
  public billing_lsit_array: any = [];
  public hospital_array: any = [];
  public hptl_clinic_id !: string | null;
  public no_bills: boolean;
  public bill_table: boolean = true;
  public header_footer_flag: boolean;
  public loader: boolean;
  public hospital_flag: boolean;
  public docflag: boolean;
  public create_dc_flag: boolean;

  f_date;
  t_date;
  public currentDate;

  public Filter_txt: string;
  public City_show: boolean;
  public firstname_show: boolean;
  public From_show: boolean;
  public Location_show: boolean;
  public To_show: boolean;
  public patient_show: boolean;
  public mobile_show: boolean;
  //public first_name: string;
  // public last_name: string;
  public patient_id: string;
  public mobile_num: string;
  public city_array = [];
  public hosloc_array = [];
  public city_desc: string;
  public location_desc: string;
  public hospital_name;
  public doctor_profile_img !: string | null;
  public f_date_flag: boolean;
  public t_date_flag: boolean;
  public userinfo: any;
  public user_id: any;
  public hospital_details;

  //mdbTable
  dtOptions: DataTables.Settings = {};;
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public printFlag:boolean;
  public getipaddress = Helper_Class.getIPAddress();
  public appFlow;
  public flowFrom;
  public appType = "all";
  public doctorList:any = [];
  public doctorid: string;
  public is_admin: boolean=false;
  private billtype:string;

  constructor(public toastr: ToastrService,public http: Http, public router: Router,
    public messageservice:MenuViewService, public masterData:MasterHelperService,public dietService:MenuViewService,) {
    localStorage.setItem("clientinfo_visible_flag", "False");
    this.tomorrow.setDate(this.tomorrow.getDate() + 0);
    this.no_bills = true;
    this.hospital_flag = true;
    this.loader = false;
    var userinfo = (Helper_Class.getInfo())
    var user = userinfo.provider;
    this.docflag = user == "doctor" ? false : true;
    this.City_show = true;
    this.firstname_show = true;
    this.Location_show = true;
    this.From_show = false;
    this.To_show = false;
    this.patient_show = true;
    this.mobile_show = true;
    this.Filter_txt = "Date";
  }

  spiltdate(date) {
    var get_tmp = date.split("-");
    var date_appt = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    return date_appt;
  }

  ngOnInit(): void {
    this.f_date = Doc_Helper.getBillFromDate();
    this.t_date = Doc_Helper.getBillToDate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [ 4, 5,] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by bill no or patient name or date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.flowFrom = "doctor";
    this.userinfo = Helper_Class.getInfo();
    this.hospital_details = Helper_Class.getHospitalInfo();
    this.doctor_profile_img = this.userinfo.profile_image;
    this.hospital_name = this.hospital_details.hosp_name;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.hospital_details.hptl_logo;

    this.hospital_array = Helper_Class.getHospital();
    for (var i = 0; i < this.hospital_array.length; i++) {
      this.hptl_clinic_id = this.hospital_array[i].hptl_clinic_id;
    }
   
    if (this.hospital_array.length == 1) {
      this.hospital_flag = true;
    } else {
      this.hospital_flag = false;
    }
    this.printFlag = Helper_Class.getHospitalInfo().doc_bill_generate == "1" ? true : false;
    this.appFlow = Helper_Class.getBillingFlow();
    this.billtype= Doc_Helper.getIpCreateBill();
    
    if(this.appFlow != undefined){
      this.user_id = this.appFlow.user_id;
      this.flowFrom = "dietician";
      this.printFlag = true;
    }
    this.get_curr_date();
    
    if(this.userinfo.admin == "1" ){
      this.is_admin=true;
      this.user_id = "All";
      this.getDoctors() ;

    } else {
      this.user_id = this.userinfo.user_id;
    }
    this.get_bill_list();
  }

  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: "IN",
      doc_reg_id: this.userinfo.user_id,
      type: this.userinfo.user_type,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyhosp', JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj != null) {
            this.doctorList.push({
              Doc_Name: "All",
              Doc_ID: "All",
              type: this.userinfo.user_type,
            });

            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = "";
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
              } else {
                doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }
            this.doctorid=this.doctorList[0];
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeDoctor(e){
    this.user_id=e.Doc_ID;
    this.get_bill_list();
  }

  Filter_change() {
    if (this.Filter_txt == "Location") {
      this.City_show = false;
      this.Location_show = false;
      this.firstname_show = true;
      this.From_show = true;
      this.To_show = true;
      this.patient_show = true;
      this.mobile_show = true;
      this.getCityDetails();
      this.is_admin=false;
      this.user_id = this.userinfo.user_id;

    } else if (this.Filter_txt == "Date") {
      this.City_show = true;
      this.Location_show = true;
      this.firstname_show = true;
      this.From_show = false;
      this.To_show = false;
      this.patient_show = true;
      this.mobile_show = true;
      if(this.userinfo.admin == "1" ){
        this.is_admin=true;
        if(this.doctorList.length ==0){
          this.getDoctors();
        }
      }

    } else if (this.Filter_txt == "Name") {
      this.firstname_show = false;
      this.Location_show = true;
      this.City_show = true;
      this.From_show = true;
      this.To_show = true;
      this.patient_show = true;
      this.mobile_show = true;
      this.is_admin=false;
      this.user_id = this.userinfo.user_id;

    } else if (this.Filter_txt == "Patient ID") {
      this.City_show = true;
      this.Location_show = true;
      this.firstname_show = true;
      this.From_show = true;
      this.To_show = true;
      this.patient_show = false;
      this.mobile_show = true;
      this.is_admin=false;
      this.user_id = this.userinfo.user_id;

    } else if (this.Filter_txt == "Mobile") {
      this.City_show = true;
      this.Location_show = true;
      this.firstname_show = true;
      this.From_show = true;
      this.To_show = true;
      this.patient_show = true;
      this.mobile_show = false;
      this.is_admin=false;
      this.user_id = this.userinfo.user_id;
    }
  }

  // Get city
  async getCityDetails() {
    this.city_array = [];
    if(Master_Helper.getMasterCityDetails() == undefined){
      await this.masterData.getCityDetails();
    }
    var obj = Master_Helper.getMasterCityDetails();
    if(obj != undefined)
      this.city_array = obj.cities;
  }

  //Get location based on city
  City_change(i) {
    this.hosloc_array = [];
    this.city_desc = i;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/loc/',
      JSON.stringify({
        reg_id: this.userinfo.user_id,
        city: this.city_desc,
        type: "doctor"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.hosloc_array = obj.locations;
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  backClicked() {
    this.router.navigateByUrl("/doctorpage");
  }

  editBill(bill_no, app_id, finyear) {
    var inpatient = Doc_Helper.getIpCreateBill() == "ipbill" ? "yes" : "no";
    var billedit = ({
      doc_reg_id: this.userinfo.user_id,
      bill_view_id: bill_no,
      app_id : app_id,
      is_inaptient: inpatient,
      fin_year:finyear,
    })
    Doc_Helper.setreceptbilledit(billedit);
    Doc_Helper.setdoctorbillno(bill_no);
    Doc_Helper.sethptl_clinic_id(this.hptl_clinic_id)  
    this.messageservice.sendMessage("billedit");
  }

  get_curr_date() {
    var obj = Master_Helper.getMasterCurrentDate();
    this.curr_date = obj.current_date;
    this.currentDate = Date_Formate((this.curr_date));
    this.get_date = obj.current_date.split('-');
    var currentDate = this.get_date[2] + "-" + this.get_date[1] + "-" + this.get_date[0]
    this.currentYear = this.get_date[0];
    this.currentTime = obj.current_time;
    this.currentDate = obj.current_date;
    this.f_date = this.currentDate;
    this.t_date = this.currentDate;
  }

  ChangeMatePicker(date_value) {
    var apt = new FormControl(new Date(date_value));
    return apt;
  }

  get_bill_list() {
    var inpat;
    if(this.billtype == "ipbill"){
      inpat="yes"
    } else {
      inpat="no";
    }
    var appType = this.appType == "New" ? true : this.appType == "follow" ? false : undefined;
    this.billing_lsit_array = [];
    var fieldvalue = {
      center_id: this.hptl_clinic_id,
      provider_id: this.user_id,
      from_date: this.f_date,
      to_date: this.t_date,
      imei: this.getipaddress,
      app_type: this.flowFrom,
      is_new : appType,
      inpatient:inpat,
    }
  
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbills',
      JSON.stringify({
        fieldvalue
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.loader = true;
          if (obj.bills != null) {
            this.set_bill_list(obj);
            Helper_Class.setbilllist(obj);
          } else {
            this.no_bills = false;
            this.bill_table = true;
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
          this.loader = true;
        }
      )
  }

  // view bills1
  view_bill(bill_id,finyear,docid) {
    if(this.appFlow != undefined){
      Doc_Helper.setviewbills(bill_id)
      Doc_Helper.setFinYear(finyear);
      Doc_Helper.setBillDocId(docid);
      this.dietService.sendMessage("billdetail");
      
    }else{
      Doc_Helper.setviewbills(bill_id);
      Doc_Helper.setFinYear(finyear);
      Doc_Helper.setBillDocId(docid);
      this.messageservice.sendMessage("billdetail");
    }
  }

  filter_click() {
    this.loader = false;
    var inpat;
    if(this.billtype == "ipbill"){
      inpat="yes"
    } else {
      inpat="no";
    }
    if (this.f_date_flag == true) {
      var from_date = this.f_date;

    } else {
      from_date = this.f_date
    }

    if (this.t_date_flag == true) {
      var to_date = this.t_date;
    } else {
      to_date = this.t_date;
    }

    this.billing_lsit_array = [];
    var fieldvalue;
    var appType = this.appType == "New" ? true : this.appType == "follow" ? false : undefined;
    if (this.Filter_txt == "Patient ID") {
      fieldvalue = {
        center_id: this.hptl_clinic_id,
        provider_id: this.user_id,
        patient_id: this.patient_id,
        app_type: this.flowFrom,
        is_new : appType,
        inpatient:inpat,
      }

    } else if (this.Filter_txt == "Location") {
      fieldvalue = {
        center_id: this.hptl_clinic_id,
        provider_id: this.user_id,
        city: this.city_desc,
        location: this.location_desc,
        app_type: this.flowFrom,
        is_new : appType,
        inpatient:inpat,
      }

    } else if (this.Filter_txt == "Name") {
      fieldvalue = {
        center_id: this.hptl_clinic_id,
        provider_id: this.user_id,
        first_name: this.first_name,
        last_name: this.last_name,
        app_type: this.flowFrom,
        is_new : appType,
        inpatient:inpat,
      }

    } else if (this.Filter_txt == "Date") {
      fieldvalue = {
        center_id: this.hptl_clinic_id,
        provider_id: this.user_id,
        from_date: from_date,
        to_date: to_date,
        app_type: this.flowFrom,
        is_new : appType,
        inpatient:inpat,
      }

    } else if (this.Filter_txt == "Mobile") {
      fieldvalue = {
        center_id: this.hptl_clinic_id,
        provider_id: this.user_id,
        mobile: this.mobile_num,
        app_type: this.flowFrom,
        is_new : appType,
        inpatient:inpat,
      }
    }
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbills/',
      JSON.stringify({
        fieldvalue
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.loader = true;

          if (obj.bills != null) {
            this.set_bill_list(obj);
            Helper_Class.setbilllist(obj)
          } else {
            this.no_bills = false;
            this.bill_table = true;
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
          this.loader = true;
        }
      )
  }
  
  set_bill_list(obj) {
    if (obj.bills != null) {
      this.billing_lsit_array = [];
      for (var i = 0; i < obj.bills.length; i++) {
        var clnt_nmae !: string | null;
        if (obj.bills[i].middle_name != undefined && obj.bills[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name).toString() + " " + encrypt_decript.Decript(obj.bills[i].middle_name).toString() + " " + encrypt_decript.Decript(obj.bills[i].last_name).toString();

        } else {
          clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name).toString() + " " + encrypt_decript.Decript(obj.bills[i].last_name).toString();
        }
        var get_date = obj.bills[i].date.split('-');
        this.totalamount = obj.totalamount;
        this.totalbalance = obj.totalbalance;
        
        this.billing_lsit_array.push({
          bill_id: obj.bills[i].bill_id,
          date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          salutation: obj.bills[i].salutation,
          client_name: clnt_nmae,
          pay_type: obj.bills[i].pay_type,
          amount: encrypt_decript.Decript(obj.bills[i].amount).toString(),
          amount_only: encrypt_decript.Decript(obj.bills[i].amount).toString(),
          doc_app_id : obj.bills[i].doc_app_id,
          fin_year:obj.bills[i].fin_year,
          doc_reg_id:obj.bills[i].doc_reg_id,
        });
        localStorage.setItem("client_name", clnt_nmae)
      }
      var tot = 0;
      for (var i = 0; i < this.billing_lsit_array.length; i++) {
        tot = tot + parseFloat(this.billing_lsit_array[i].amount_only);
      }

      if (this.billing_lsit_array.length == 0) {
        this.no_bills = false;
        this.bill_table = true;

      } else {
        this.no_bills = true;
        this.bill_table = false;
      }
    } 
  }

  //Redirect to bill create page
  bill_create_click() {
    //this.router.navigateByUrl("/billcreate");
    if(Doc_Helper.getIpCreateBill() == "ipbill" ){
      var inpatientBill = {
        app_id : "",
        flow: "inpatien-create"
    }
    Helper_Class.setInpatientBillEdit(inpatientBill);
        Helper_Class.setInpatientBillCreate("doctor");
      this.messageservice.sendMessage("ipbillcreate");
    }else{
      if(this.appFlow != undefined){
        this.dietService.sendMessage("billCreate");
      }else{
        this.messageservice.sendMessage("billcreate");
      }
    }
  }

  OnDateChangedfrom(e) {
    Doc_Helper.setBillFromDate(null);
    this.f_date = e;   
    this.f_date_flag = true;
    Doc_Helper.setBillFromDate(e);
  }

  OnDateChangedto(e) {
    Doc_Helper.setBillToDate(e);
    this.t_date = e;
    this.t_date_flag = true;
    Doc_Helper.setBillToDate(e);
  }

  checkUncheckAll() {
    this.checklist = this.billing_lsit_array;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })   
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].patient_id);
    }    
    if (this.checklist.length == this.checkedList.length) {
      
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkedList.length != 0) {
      
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.billing_lsit_array;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  deleteBill(bill_no, finyear) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/cbill',
      JSON.stringify({
        bill_no:bill_no,
        hptl_clinic_id: this.hptl_clinic_id,
        userid: this.userinfo.user_id,
        app_type:"doctor",
        country:"IN",
        del_by:"doctor",
        fin_year:finyear,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
         
          if(obj.key=="1"){
            this.toastr.success(Message_data.billCancelledSuccess);
            this.get_bill_list();
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
          this.loader = true;
        }
      )
  }
}
