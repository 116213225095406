<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Payments</h5>
          </div>
          <div class="headerButtons">
            <a> <img (click)="print()" src="../../../assets/img/printer.svg" width="25px" height="25px" /> </a>
            <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" class="addimgbtn_icd" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="Details">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" *ngIf="provtypeArray.length >1">
              <mat-label class="matlabel">Type<br>
                <select class="ipcss" *ngIf="provtypeArray !=undefined" [(ngModel)]="provtype">
                  <option *ngFor="let prov of provtypeArray" value={{prov}}> {{prov}}
                  </option>
                </select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
              <mat-label class="matlabel">Filter by<br>
                <select required [(ngModel)]="filterType" class="ipcss" (change)="filterTypeChange()">
                  <option selected>UHID</option>
                  <option selected>Name</option>
                  <option selected>Mobile</option>
                  <option>Date</option>
                  <option>Bill number</option>
                </select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom" [hidden]="clientFlag">
              <mat-label class="matlabel">First name<br>
                <input matInput class="ipcss" type="text" required [(ngModel)]="clntName" style="margin-bottom: 10px;"
                  (keyup)=clientFilter() />
                <div class="auto_complete_text" *ngIf="clientList.length != 0">
                  <ul *ngFor="let item of clientList">
                    <li>
                      <a (click)="selectClient(item)">{{item.name}}</a>
                    </li>
                  </ul>
                </div>
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="clientFlag">
              <mat-label class="matlabel">Last name<br>
                <input matInput class="ipcss widthbilllist" type="text" disabled required [(ngModel)]="lastName"
                  style="margin-bottom: 10px;" />
              </mat-label>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="dateFlag">
              <mat-label class="matlabel">From date<br>
                <input type="date" class="ipcss_date widthbilllist datecss" required [(ngModel)]="fromDate"
                  max="{{currentDate}}" matInput>
              </mat-label>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="dateFlag">
              <mat-label class="matlabel">To date<br>
                <input type="date" class="ipcss_date widthbilllist datecss" required [(ngModel)]="toDate"
                  max="{{currentDate}}" matInput>
              </mat-label>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="billFlag">
              <mat-label class="matlabel">{{filterType}}<br>
                <input type="text" class="ipcss" required [(ngModel)]="billingId" matInput>
              </mat-label>
            </div>
            <div class="col-1  p-0">
              <mat-label class="matlabel">
                <a (click)="searchBills()"><img src="../../../assets/ui_icons/search_icon.svg"
                    class="searchButton" /></a>
              </mat-label>
            </div>
          </div>
          <div class="row" [hidden]="patdet_flag">
            <div class="cover_div" style="padding-bottom: 13px;margin-top: 10px !important;">
              <div class="header_lable">
                Patient details
              </div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" style="margin-top: 7px;">Name:
                      {{clnt_name}}</mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <mat-label class="matlabel" style="margin-top: 7px;">Age: {{clnt_age}}
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <mat-label class="matlabel" style="margin-top: 7px;">Mobile: {{clnt_mobile}}
                    </mat-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" [hidden]="nodata">
            <div class="col-12">
              <p class="nodata">No data found</p>
            </div>
          </div>
          <div class="row" style="margin-top:15px;padding: 0 10px 0 10px;">
            <div [hidden]="billList.length == 0" class="dig_table_overflow">
              <div class="table-responsive">
                <table id="tbl" class="table table-hover table-dynamic" style="font-size: 11px;overflow: scroll;">
                  <thead>
                    <tr>
                      <th>Bill no</th>
                      <th style="width: 140px;">Date</th>
                      <th style="width: 150px;">Patient</th>
                      <th style="width: 100px;">Bill amount</th>
                      <th style="width: 100px;">Paid</th>
                      <th style="width: 100px;">Balance</th>
                      <th style="width: 75px;">Payable </th>
                      <th style="width: 100px;">Pay type </th>
                      <th style="width: 150px;">Remarks </th>
                      <th style="width: 150px;">Status </th>
                      <th>&nbsp; </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of billList">
                      <td>{{data.billno}}</td>
                      <td style="width: 120px;">{{data.date}}</td>
                      <td style="width: 150px;">{{data.pname}}</td>
                      <td style="width: 100px;">{{data.billamount}}</td>
                      <td style="width: 100px;">{{data.paid_amt}}</td>
                      <td style="width: 100px;">{{data.balance}}</td>
                      <td style="width: 75px;"><input type="text" class="ipcss paywidth" [readonly]="data.paidflag"
                          [(ngModel)]="data.payamount" maxlength="7"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                      </td>
                      <td style="width: 100px;">
                        <select class="ipcss" *ngIf="paymentArray !=undefined" [(ngModel)]="data.pay_id"
                          [disabled]="data.paidflag">
                          <option *ngFor="let pay of paymentArray" value={{pay.pay_id}}> {{pay.pay_desc}}
                          </option>
                        </select>
                      </td>
                      <td style="width: 150px;"><input type="text" class="ipcss paywidth1" [readonly]="data.paidflag"
                          [(ngModel)]="data.remarks" maxlength="100" matInput />
                      </td>
                      <td style="width: 150px;">{{data.payflag}}</td>
                      <td>
                        <img src="../../../assets/img/Second_opinion_tabs_icons/Observation.png" width="20px"
                          height="20px" (click)="showBillHistory(mymodal,data.billno)" *ngIf="data.showimage" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ng-template #mymodal let-modal>
            <div [hidden]="billList.length == 0" class="dig_table_overflow">
              <div class="table-responsive">
                <table id="tbl" class="table table-hover table-dynamic" style="font-size: 11px;">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Paid on</th>
                      <th>remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pay of parpayarray">
                      <td>{{pay.amount}}</td>
                      <td>{{pay.created_date}}</td>
                      <td>{{pay.remarks}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-template>
        </div>

        <div [hidden]="true">
          <div>
            <table style="border:'0';width:100%" #printlogowithname id="printlogowithname">
              <thead>
                <tr>
                  <th style="width:100%;height: 100px;"></th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <td width="100%">
                    <table style="border:'0';width:100%">
                      <tr>
                        <td style="width:100%;height: 100px;">&nbsp;</td>
                      </tr>
                    </table>
              </tfoot>
              <tbody>
                <tr>
                  <td width="100%">
                    <div class="bill_border">
                      <div>
                        <div>
                          <table style="width: 100%; margin-top: -100px;">
                            <tr>
                              <td style="display: flex;justify-content: center;">
                                <div>
                                  <img alt="image" src={{hospital_logo}} width="100px" height="100px"
                                    style="vertical-align: middle;">
                                </div>
                                <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                                  <span><b>{{hosp_name}}</b>,</span><br />
                                  <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                                  <span>{{hosp_csz}}</span><br />
                                  <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png"
                                      width="15px" height="15px" />{{hosp_tel}}.</span><br>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <table style="width: 90%; margin: 0 auto;">
                          <thead>
                            <tr>
                              <th>Bill no</th>
                              <th>Date</th>
                              <th>Bill amount</th>
                              <th>Paid</th>
                              <th>Balance</th>
                              <th>Payable </th>
                              <th>Pay type </th>
                              <th>Status </th>
                              <th>&nbsp; </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr *ngFor="let data of billList">
                              <td>{{data.billno}}</td>
                              <td>{{data.date}}</td>
                              <td>{{data.billamount}}</td>
                              <td>{{data.paid_amt}}</td>
                              <td>{{data.balance}}</td>
                              <td>{{data.payamount}}</td>
                              <td>{{data.pay_id}}</td>
                              <td>{{data.payflag}}</td>
                              <td>
                                <img src="../../../assets/img/Second_opinion_tabs_icons/Observation.png" width="20px"
                                  height="20px" (click)="showBillHistory(mymodal,data.billno)" *ngIf="data.showimage" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                      </div>
                    </div>
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table style="border:'0';width:100%" #printbanner id="printbanner">
              <thead>
                <tr>
                  <img alt="image" src={{hospital_logo}}>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <td width="100%">
                    <table style="border:'0';width:100%">
                      <tr>
                        <td style="width:100%;">&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tfoot>

              <tbody style="background-color:whitesmoke;">
                <tr>
                  <td width="100%">
                    <table style="width: 90%; margin: 0 auto;">
                      <thead>
                        <tr>
                          <th>Bill no</th>
                          <th>Date</th>
                          <th>Bill amount</th>
                          <th>Paid</th>
                          <th>Balance</th>
                          <th>Payable </th>
                          <th>Pay type </th>
                          <th>Status </th>
                          <th>&nbsp; </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let data of billList">
                          <td>{{data.billno}}</td>
                          <td>{{data.date}}</td>
                          <td>{{data.billamount}}</td>
                          <td>{{data.paid_amt}}</td>
                          <td>{{data.balance}}</td>
                          <td>{{data.payamount}}</td>
                          <td>{{data.pay_id}}</td>
                          <td>{{data.payflag}}</td>
                          <td>
                            <img src="../../../assets/img/Second_opinion_tabs_icons/Observation.png" width="20px"
                              height="20px" (click)="showBillHistory(mymodal,data.billno)" *ngIf="data.showimage" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <div>
              <table style="width: 90%; margin: 0 auto;">
                <thead>
                  <tr>
                    <th>Bill no</th>
                    <th>Date</th>
                    <th>Bill amount</th>
                    <th>Paid</th>
                    <th>Balance</th>
                    <th>Payable </th>
                    <th>Pay type </th>
                    <th>Status </th>
                    <th>&nbsp; </th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let data of billList">
                    <td>{{data.billno}}</td>
                    <td>{{data.date}}</td>
                    <td>{{data.billamount}}</td>
                    <td>{{data.paid_amt}}</td>
                    <td>{{data.balance}}</td>
                    <td>{{data.payamount}}</td>
                    <td>{{data.pay_id}}</td>
                    <td>{{data.payflag}}</td>
                    <td>
                      <img src="../../../assets/img/Second_opinion_tabs_icons/Observation.png" width="20px"
                        height="20px" (click)="showBillHistory(mymodal,data.billno)" *ngIf="data.showimage" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>