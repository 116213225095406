<div *ngIf="pediaPersonalFlag == 'min'">
    <div class="row">
        <!-- <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="name_hidden" >Name<br>
                <input matInput class="ipcss "  readonly required [(ngModel)]="name_txt">
            </mat-label>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="age_hidden" >Age<br>
                <input matInput class="ipcss "  [readonly]="agefield"  required [(ngModel)]="Age_txt">
            </mat-label>
        </div> -->
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="maincon_hidden">Main concerns<br>
                <textarea matInput  *ngIf="!main_concern_flag" class="ipcss obsttxtareaheight " required [(ngModel)]="concern_txt" rows="1"></textarea>
                <!-- <textarea matInput  *ngIf="!main_concern_flag" class="ipcss obsttxtareaheight " required [(ngModel)]="concern_txt" (click)="concern_pop()" rows="1"></textarea>
                <mat-select  *ngIf="main_concern_flag" class="ipcss" disableOptionCentering="true" multiple>
                    <span class="closeoption"  (click)="closeconcern_popup()"><img src="../../../assets/img/close_icon.png" style="    margin: 6px 5px;" width="20px" height="20px" id="close_icd" /></span>
                    <mat-option [selected]="concern_array.checked"  color="primary" *ngFor="let concern_array of Main_Concern_Data" value={{concern_array.label}}>
                        <mat-checkbox multiple [checked]="concern_array.checked"  (change)="concern_array_click(concern_array.label,concern_array.value,$event.checked)">{{concern_array.label}}</mat-checkbox>&nbsp;
                    </mat-option>
                </mat-select> -->
            </mat-label>
        </div>
        <div [hidden]="child_hidden" class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="child_hidden">Child habits<br>
                <textarea   *ngIf="!habit_in_flag" matInput class="ipcss obsttxtareaheight "  [(ngModel)]="child_habits" (click)="habit_popup()" rows="1"></textarea>
                <mat-select  *ngIf="habit_in_flag" class="ipcss " disableOptionCentering="true">
                    <span class="closeoption"  (click)="closehabit_popup()"><img src="../../../assets/img/close_icon.png" style="    margin: 6px 5px;" width="20px" height="20px" id="close_icd" /></span>

                    <mat-option  color="primary" *ngFor="let habit_array of Child_habit_arry" value={{habit_array.label}}>
                        <mat-checkbox [checked]="habit_array.checked"  (change)="habit_array_click(habit_array.label,habit_array.value,$event.checked)">{{habit_array.label}}</mat-checkbox>
                    </mat-option>
                </mat-select>
            </mat-label>
        </div>
    </div>
</div>

<div *ngIf="pediaPersonalFlag == 'max'">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        Personal
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div [hidden]="name_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="name_hidden" >Name<br>
                                    <input  matInput class="ipcss " [readonly]="client" required [(ngModel)]="name_txt" (blur)="send_data()">
                                </mat-label>
                            </div>
                            <div [hidden]="age_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="age_hidden" >Age<br>
                                    <input  matInput class="ipcss"  [readonly]="agefield && client"  required [(ngModel)]="Age_txt" (blur)="send_data()">
                                </mat-label>
                            </div>
                            <div [hidden]="maincon_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="maincon_hidden">Main concerns<br>
                                    <textarea matInput  [readonly]="client" required class="ipcss obsttxtareaheight "  [(ngModel)]="concern_txt" rows="1" (blur)="send_data()" maxlength="245"></textarea>
                                    <!-- <textarea matInput  [readonly]="client" required class="ipcss obsttxtareaheight "  [(ngModel)]="concern_txt" (click)="concern_pop()" rows="1" (blur)="send_data()"></textarea> -->
                                </mat-label>
                            </div>
                            <div [hidden]="child_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="child_hidden">Child habits<br>
                                    <textarea  [readonly]="client" matInput class="ipcss obsttxtareaheight "  [(ngModel)]="child_habits" (click)="habit_popup()" (blur)="send_data()" rows="1"></textarea>
                                </mat-label>
                            </div>
                            <div [hidden]="complicate_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="complicate_hidden">Complications<br>
                                    <textarea [readonly]="client" matInput class="ipcss obsttxtareaheight " id="myInput" rows="1" maxlength="150" (blur)="send_data()" [(ngModel)]="complication_txt"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="social_head" class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        Social history
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div [hidden]="living_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="living_hidden">Living conditions<br>
                                    <textarea [readonly]="client" matInput class="ipcss obsttxtareaheight " rows="1" maxlength="150" [(ngModel)]="Living_condition" (blur)="send_data()"></textarea>
                                </mat-label>
                            </div>
                            <div [hidden]="economic_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="economic_hidden">Economic situations<br>
                                    <textarea  [readonly]="client" matInput class="ipcss obsttxtareaheight " id="myInput" rows="1" maxlength="150" [(ngModel)]="economic_situation" (blur)="send_data()"></textarea>
                                </mat-label>
                            </div>
                            <div [hidden]="religious_affhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="religious_affhidden">Religious affiliations<br>
                                    <textarea [readonly]="client" matInput class="ipcss obsttxtareaheight " id="myInput" rows="1" maxlength="150" [(ngModel)]="religious_affiliation" (blur)="send_data()"></textarea>
                                </mat-label>
                            </div>
                            <div [hidden]="ethnic_basedhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" [hidden]="ethnic_basedhidden">Ethnicity based disease (if any)<br>
                                    <textarea [readonly]="client"  matInput class="ipcss obsttxtareaheight " rows="1" maxlength="150" [(ngModel)]="ethnic_based" (blur)="send_data()"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>