<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill details</h5>
          </div>
          <div class="headerButtons">
            <a (click)="print_area()"><img src="../../../assets/img/printer.svg" width="25px" height="25px" /></a>
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
            <a *ngIf="show_save_btn" (click)="Bill_save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                class="saveimgbtn_inpatinfo" style="margin-left: 7px;" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="date_time_flag">
            <mat-label class="matlabel">Bill date<br>
              <input type="date" required class="ipcss inpat_width" [(ngModel)]="bill_date" matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="date_time_flag">
            <mat-label class="matlabel">Bill time<br></mat-label>
            <div class="row">
                <div class="col-3" style="padding-right:0;">
                    <select type="date" class="ipcss inpat_width"
                        [(ngModel)]="bill_time1">
                        <option *ngFor="let hrs of bill_hrs" value="{{hrs}}">{{hrs}}
                        </option>
                    </select>
                </div>
                <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                    : </div>
                <div class="col-3" style="padding-left:0;">
                    <select type="date" class="ipcss inpat_width"
                        [(ngModel)]="bill_time2">
                        <option *ngFor="let min of bill_min" value="{{min}}">{{min}}
                        </option>
                    </select>
                </div>
                <div class="col-4">
                    <select type="date" class="ipcss inpat_width"
                        [(ngModel)]="bill_time3">
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
            </div>
        </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy" *ngIf="!inpatientEdit">
            <mat-label class="matlabel">Patient<br>
              <input type="text" required class="ipcss" [(ngModel)]="appoint_txt" name="loc_name"
                (keyup)="getAppointments($event)" type="text" aria-label="Number" matInput [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option id="optionfont" *ngFor="let location of appointment_list"
                  (click)="Appointmnet_change(location.docid_name)" [value]="location.docid_name">
                  {{location.docid_name}}
                </mat-option>
              </mat-autocomplete>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Hospital name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="hospital_name" />
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Patient name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="patient_name" />
            </mat-label>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Service type<br>
              <input type="text" class="ipcss inpat_width" [(ngModel)]="charge_type"
                (keyup)="getServiceType(charge_type)" required matInput [disabled]="inpatientEdit" />
              <div class="auto_complete_text1">
                <ul *ngFor="let charge of serviceType">
                  <li>
                    <a (click)="charge_change(charge)">{{charge}}</a>
                  </li>
                </ul>
              </div>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="pay_type"
                (selectionChange)="pay_change()" disableOptionCentering [disabled]="inpatientEdit">
                <mat-option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="insur_flag">
            <mat-label class="matlabel">Insurer<br>
              <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurer_id" disableOptionCentering>
                <mat-option *ngFor="let ins of insurerlist" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bill_qty_flag">
            <mat-label class="matlabel">Quantity<br>
              <input type=number class="ipcss billing_text_align widthbillcreate" matInput required
                (keyup)="amount_change()" [(ngModel)]="quantity" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">

            <mat-label class="matlabel">Amount<br>
              <input type=number class="ipcss billing_text_align widthbillcreate" matInput required
                (keyup)="amount_change()" [(ngModel)]="amount_txt" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bill_qty_flag" [hidden]="bill_cons_flag">
            <mat-label class="matlabel">Concession(%)<br>
              <input type=number class="ipcss billing_text_align  billing_amt_ip" matInput (keyup)="amount_change()"
                [(ngModel)]="concession_txt" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bill_qty_flag" [hidden]="bill_cons_flag">
            <mat-label class="matlabel">After concession
              <input type=number class="ipcss billing_text_align billing_amt_ip" [(ngModel)]="amount_aftcon" />
            </mat-label>
          </div>
          <div class="col-12 col-md-12 col-lg-12 col-xl-12 " *ngIf="!inpatientEdit">
            <mat-label class="addvalue matlabel">
              <a (click)="Bill_add_click()"><img src="../../../assets/ui_icons/buttons/add_button.svg"
                  class="saveimgbtn_inpatinfo" /></a>
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <div class="table-responsive" [hidden]="billaddlist.length == 0">
              <table id="bill_create" class="table table-nowrap table-sm billcreae_table">
                <thead class="tableheading">
                  <tr style="height: 32px;">
                    <th>Sno</th>
                    <th>Service</th>
                    <th>Payment type</th>
                    <th [hidden]="bill_qty_flag">Quantity</th>
                    <th>Fees</th>
                    <th [hidden]="bill_cons_flag">Concession(%)</th>
                    <th [hidden]="bill_cons_flag">Amount</th>
                    <th class="delete">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of billaddlist" class="tdvalues">
                    <td style="text-align: center;">{{person.sno}}</td>
                    <td style="text-align: left;">{{person.bill_type}}</td>
                    <td>{{person.pay_type}}</td>
                    <td style="text-align: right !important;" [hidden]="bill_qty_flag">{{person.quantity}}</td>
                    <td style="text-align: right;">{{person.amount_txt}} </td>

                    <td style="text-align: right !important;" [hidden]="bill_cons_flag">{{person.concession}}</td>
                    <td style="text-align: right !important;" [hidden]="bill_cons_flag">{{person.fee}}</td>
                    <td class="delete">
                      <a (click)="Delete_bill(person.biill_type,person.amount_txt)"> <img
                          src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" alt=""></a>
                    </td>
                  </tr>
                  <!-- <tr *ngIf="medcalDetailsArray.length != 0" class="tdvalues">
                    <td style="text-align: left;">Medicine</td>
                    <td></td>
                    <td></td>
                    <td style="text-align: right;">{{medicinBal}} </td>
                    <td style="text-align: right !important;"></td>
                    <td style="text-align: right !important;">{{medicinBal}}</td>
                    <td class="delete">
                     
                    </td>
                  </tr>
                  <tr *ngIf="digDetailsArray.length != 0"  class="tdvalues">
                    <td colspan="6" style="text-align: left;">Pathology & Radiology </td>
                  </tr>
                  <tr  *ngFor="let digArray of digDetailsArray; let i = index;" class="tdvalues">
                    <td style="text-align: left;">{{digArray.bill_type}} </td>
                    <td>{{digArray.pay_type}}</td>
                    <td>{{digArray.quantity}}</td>
                    <td style="text-align: right;">
                        <input class="ipcss" matInput text-right id="{{'digID_'+i}}"  maxlength="10" style="margin:0" [(ngModel)]="digArray.amount_txt" style="text-align:right;" (keyup)="digAmount(digArray.digID,digArray.amount_txt)"  />

                      </td>
                    <td style="text-align: right !important;">{{digArray.concession}}</td>
                    <td style="text-align: right !important;">{{digArray.fee}}</td>
                    <td class="delete">
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div [hidden]="billden_plan.length==0">
          <p class="casesheet_para">Treatement plan</p>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- // <p class="nodata" *ngIf="billden_plan.length == 0">No Appointment(s) Found</p>     -->

          <table *ngIf="billden_plan.length" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable billcreae_table">
            <thead>
              <tr>
                <th>Plan</th>
                <th class="tbl_txt_center">Estimate</th>
                <th class="tbl_txt_center">Select</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of billden_plan; let i = index">
                <td>{{person.treatment_desc}}</td>
                <td class="tbl_txt_center">{{person.estimate}}</td>
                <td class="tbl_txt_center">
                  <!-- <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST" (change)="include_gst_click(inGST)">
                    </mat-checkbox> -->
                  <mat-checkbox color="primary" (change)="bill_click(person.den_treatment_plan_id,bill_palnche,i)"
                    [checked]="person.checked">
                  </mat-checkbox>
                </td>

                <!-- <td><img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td> -->

              </tr>
            </tbody>
          </table>

        </div>
        <div>
          <div class="row ">
            <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
              <div class="cover_div" [hidden]="!paytypreq">
                <div class="row">
                  <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                    <mat-label class="matlabel">Payment type<br>
                      <select class="ipcss " [(ngModel)]="payType1" disableOptionCentering>
                        <option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                      </select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" >
                    <mat-label class="matlabel">Amount<br>
                      <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput required maxlength="7" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                    <mat-label class="matlabel ">
                      <img src="../../../assets/ui_icons/buttons/next_button.svg" class="amt_button add_align"
                        (click)="addPaymentDetails()" />
                    </mat-label>
                  </div>
                </div>
  
                <div class="row">
                  <table>
                    <tr *ngFor="let pay of paymentsarray">
                      <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                      <td style="width: 150px !important;">{{pay.amount}}</td>
                      <td>
                        <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                        <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-0 col-sm-9  col-md-5 col-lg-5  col-xl-5">
              <div class="row">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Include GST &nbsp;&nbsp; </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST"
                    (change)="include_gst_click(inGST)">
                  </mat-checkbox>
                </div>
                <div *ngFor="let gsttax of gst_data_list">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                    </div>
                    <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{gsttax.Gst_amount}}</mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7"></div>
            <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
              <div class="row">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                  <mat-label class="matlabel  heightmsr">Previous balance </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                  <input class="ipcss  heightmsr bill_txt" matInput text-right [(ngModel)]="balance_data" maxlength="10"
                    disabled />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel">Current bill </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                    [(ngModel)]="current_amount" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Total </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                    [(ngModel)]="final_amount" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Discount</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" style="display: flex;
                align-items: center;">
                  <!-- <span class="roundoffButton"><img src="../../../assets/ui_icons/minus_icon.svg" alt="" (click)="addRound('minus')"></span><span class="roundoffButton"><img src="../../../assets/ui_icons/plus_icon.svg" style="margin: 0 0 0 5px;" alt="" (click)="addRound('puls')"></span> -->
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right [(ngModel)]="round_off"
                    maxlength="10" (keyup)="addRound('minus')" style="display: inline-block !important; width: 100%;" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Advance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="advance_change()"
                    [(ngModel)]="Advance_bill" maxlength="10" readonly />
                </div>
                <!-- <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Previously Paid</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="paid_change()"
                    [(ngModel)]="pre_paid_bill" maxlength="10" disabled/>
                </div> -->
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Paid</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="paid_change()"
                    [(ngModel)]="paid_bill" maxlength="10" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr" [hidden]="balance_show">Balance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss billcreatech heightmsr bill_txt" [hidden]="balance_show" matInput text-right
                    [(ngModel)]="remaing_billbal" maxlength="10" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- print -->
        <div [hidden]="true">
          <div id="bill-print-section">

            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <table class="report-container">
                  <thead class="report-header">
                    <tr>
                      <th class="report-header-cell">
                        <div class="header-info">
                          <div>
                            <table style="width: 100%;">
                              <tr>
                                <td style="display: flex;justify-content: center;">
                                  <div>
                                    <img alt="image" src={{hospital_logo}} width="100px" height="100px"
                                      style="vertical-align: middle;">
                                  </div>
                                  <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                                    <span><b>{{hospital_name}}</b>,</span><br />
                                    <span *ngIf="hospitalAddress != ''">{{hospitalAddress}}</span><br />
                                    <span>{{Hospital_location}}</span><br />
                                    <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img
                                        src="../../../assets/img/phone.png" width="15px"
                                        height="15px" />{{billprint.telephone}}.</span><br>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <!-- DON't DELETE <table style="width: 100%;">
                              <tr>
                                <td style="text-align: center;">
                                  <p style="font-size: 24px;"><strong>{{hospital_name}}</strong></p>
                                  <p>{{hospitalAddress}} {{Hospital_location}}</p>

                                </td>
                              </tr>
                            </table> -->
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tfoot class="report-footer">
                    <tr>
                      <td class="report-footer-cell">
                        <div class="footer-info">
                          <table style="width:100%">
                            <tr>
                              <td>
                                <p style="font-size: 13px;width: 100%; text-align: center;"><b><i>This is digitally
                                      generated bill</i></b></p>
                                <br />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                  <tbody class="report-content">
                    <tr>
                      <td class="report-content-cell">
                        <div class="main">
                          <!-- Doctor details  [hidden]="main_balshow"-->
                          <div class="article">
                            <div style="width: 90%; margin: 0 auto;">
                              <h3 style="margin:10px 0 ;" class="heading">Patient details</h3>
                              <table style="width: 100%;">
                                <tr>
                                  <td style="width: 60%; vertical-align: top;">
                                    <div>
                                      <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                                      <br />
                                      <span><strong> Inpatient id : </strong>{{billprint.inpatientId}}</span>
                                      <br />
                                      <span><strong>Address : </strong></span>
                                      <span *ngIf="billprint.address != ''">{{billprint.address}}</span>
                                      <br />
                                      <span>{{billprint.location}}{{billprint.city}}</span>
                                      <br />
                                      <span>{{billprint.state}}</span>
                                      <br />
                                      <span>
                                        <img src="../../../assets/img/phone.png" width="15px" height="15px" />
                                        {{billprint.telephone}}
                                      </span>
                                    </div>


                                  </td>
                                  <td style="width: 40%;vertical-align: top;">
                                    <div>
                                      <span>Bill date :{{bill_date}} </span>
                                      <br />


                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>


                            <!-- Patient details -->



                            <!-- Bill list particulars -->
                            <div *ngIf="billaddlist.length != 0" style="width: 90%; margin: 0 auto;">
                              <h3 class="heading">Bill particulars</h3>
                              <div class="row">
                                <table style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Service</b>
                                      </th>
                                      <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                        Payment
                                        type</th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                                        [hidden]="bill_cons_flag">
                                        <b>Quantity</b>
                                      </th>

                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Fee</b>
                                      </th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                                        [hidden]="bill_cons_flag">
                                        <b>Concession
                                          (%)</b>
                                      </th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                                        [hidden]="bill_cons_flag">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of billaddlist">
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;"
                                        data-th="Type">{{billprinttable.bill_type}}</td>
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                        data-th="Type">{{billprinttable.pay_type}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Fee" [hidden]="bill_cons_flag">{{billprinttable.quantity}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Fee">{{billprinttable.amount_txt}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}
                                      </td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Amount" class="fee_txt" [hidden]="bill_cons_flag">
                                        {{billprinttable.fee}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Total calculation table [hidden]="totalshow" -->
                            <span class="printsame" style="width: 90%; margin: 10px 0 auto;">
                              <table style="float: right;
                              margin-right: 60px;
                              margin-top: 8px;
                              width: 75%; page-break-after: always;
                              page-break-inside: avoid;
                          ">

                                <tr *ngFor="let gsttax of gst_data_list">
                                  <td style="width: 50%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">{{gsttax.Gst_Descrip}} </th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र"
                                    class="fee_txt">
                                    {{gsttax.Gst_amount}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 50%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Current Bill (र) </th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र"
                                    class="fee_txt">
                                    {{current_amount}}</td>
                                </tr>

                                <tr [hidden]="previous_balshow">
                                  <td style="width: 50%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Previous balance र" class="fee_txt">{{balance_data}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 50%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{final_amount}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 50%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Discount (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{round_off}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 50%;background-color: #ffffff"></td>
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Advance / Paid र" class="fee_txt">
                                    {{Advance_bill}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 50%;background-color: #ffffff"></td>
                                  <th style="text-align: left;"> Paid (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Advance / Paid र" class="fee_txt">
                                    {{paid_bill}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 50%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance"
                                    class="fee_txt">
                                    {{remaing_billbal}}</td>
                                </tr>
                              </table>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>


              </div>

            </div>


          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>