import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharma-referral',
  templateUrl: './pharma-referral.component.html',
  styleUrls: ['./pharma-referral.component.scss']
})
export class PharmaReferralComponent implements OnInit {
  public referalTableFlag: boolean;
  public referalArray = [];
  public name: string = null;
  public contNumber: string = null;
  public message = '';
  public refType: string = null;
  public url: string = null;
  public maxChars = 150;
  public chars = 0;
  public userInfo;
  public pharmacistID;
  public refAdd: boolean = true;
  public saveFlag: boolean = true;
  constructor(public messageservice: PharmacyService, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.referalTableFlag = true;
    this.url = ipaddress.getIp.toString() + "adm/sref";
  }
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
  }
  addReferral() {
    if (this.name != null && this.contNumber != null) {
      this.refAdd = false;
      if (this.referalArray.length != 0) {
        for (var i = 0; i < this.referalArray.length; i++) {
          if (this.referalArray[i].name == this.name && this.referalArray[i].mobile == this.contNumber) {
            this.toastr.error(Message_data.contactAlrdyAdded);
            break;
          } else {
            this.referalArray.push({
              name: this.name,
              mobile: this.contNumber,
            });
            break;
          }
        }
      } else {
        this.referalArray.push({
          name: this.name,
          mobile: this.contNumber,
        });
      }
    }
    this.name = null;
    this.contNumber = null;
    this.referalTableFlag = false;
  }
  editReferral(rname, rmobile) {
    for (var i = 0; i < this.referalArray.length; i++) {
      if (this.referalArray[i].name == rname && this.referalArray[i].mobile == rmobile) {
        this.name = this.referalArray[i].name;
        this.contNumber = this.referalArray[i].mobile;
        this.referalArray.splice(i, 1);
        break;
      }
    }
  }
  deleteReferral(rname, rmobile) {
    for (var i = 0; i < this.referalArray.length; i++) {
      if (this.referalArray[i].name == rname && this.referalArray[i].mobile == rmobile) {
        this.referalArray.splice(i, 1);
        break;
      }
    }
    this.referalTableFlag = this.referalArray.length != 0 ? false : true;
  }
  saveReferral() {
    this.saveFlag = true;
    if (this.message == null) {
      this.toastr.error(Message_data.enterSentMsg);
    } else {
      this.saveFlag = false;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.url, {
        refdata: this.referalArray,
        message: this.message,
        reftype: this.refType,
        userid: this.pharmacistID,
      },
        { headers: headers }).subscribe(
          data => {
            var dataval = data.json();
            if (dataval.key == "1") {
              this.saveFlag = false;
              this.toastr.success(Message_data.upldReferral);
              if (this.refType == "Doctor") {
              } else if (this.refType == "Pharmacy") {
                this.messageservice.sendMessage("pharmacyhome")
              }
            } else {
              this.saveFlag = true;
              this.toastr.error(Message_data.unableUpldReferral);
            }
          }, error => { this.saveFlag = true; });
    }
  }
}
