<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Create refunds</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()"
                            class="addimgbtn_icd" [hidden]="saveFlag" />
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()"
                            class="addimgbtn_icd" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="Details">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 select_bottom">
                            <mat-label class="matlabel">Bill type<br>
                                <select required class="ipcss" [(ngModel)]="billtype">
                                    <option>Hospital</option>
                                    <option>Diagnosis</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                            <mat-label class="matlabel">Filter by<br>
                                <select required [(ngModel)]="filterType" class="ipcss" (change)="filterTypeChange()">
                                    <option selected>Date</option>
                                    <option>Client</option>
                                    <option>Bill number</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom" [hidden]="clientFlag">
                            <mat-label class="matlabel">Name<br>
                                <input matInput class="ipcss" type="text" required [(ngModel)]="clntName"
                                    style="margin-bottom: 10px;" (keyup)=clientFilter() />
                                <div class="auto_complete_text" *ngIf="clientList.length != 0">
                                    <ul *ngFor="let item of clientList">
                                        <li>
                                            <a (click)="selectClient(item)">{{item.name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom" [hidden]="clientFlag">
                            <mat-label class="matlabel">Middle name<br>
                                <input matInput class="ipcss widthbilllist" type="text" disabled
                                    [(ngModel)]="middleName" style="margin-bottom: 10px;" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="clientFlag">
                            <mat-label class="matlabel">Last name<br>
                                <input matInput class="ipcss widthbilllist" type="text" disabled required
                                    [(ngModel)]="lastName" style="margin-bottom: 10px;" />
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="dateFlag">
                            <mat-label class="matlabel">From date<br>
                                <input type="date" class="ipcss_date widthbilllist datecss" required
                                    [(ngModel)]="fromDate" max="{{currentDate}}" matInput>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="dateFlag">
                            <mat-label class="matlabel">To date<br>
                                <input type="date" class="ipcss_date widthbilllist datecss" required
                                    [(ngModel)]="toDate" max="{{currentDate}}" matInput>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="billFlag">
                            <mat-label class="matlabel">Bill number<br>
                                <input type="text" class="ipcss" required [(ngModel)]="billingId" matInput>
                            </mat-label>
                        </div>
                        <div class="col-1  p-0">
                            <mat-label class="matlabel">
                                <a (click)="searchBills()"><img src="../../../assets/img/search.png"
                                        class="searchButton" /></a>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6" [hidden]="billListFlag">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Bill details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6" [hidden]="billListFlag">
                                            <mat-label class="matlabel">Bill number<br>
                                                <select class="ipcss" [(ngModel)]="billingId"
                                                    (change)="getBillDetails()">
                                                    <option>Select</option>
                                                    <option *ngFor="let bill of billList" value={{bill.bill_id}}>
                                                        {{bill.bill_id}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-6 " [hidden]="billListFlag">
                                            <mat-label class="matlabel">Bill amount<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="billAmount"
                                                    matInput>
                                            </mat-label>
                                        </div>
                                        <br>
                                        <div class="col-6" [hidden]="billListFlag">
                                            <mat-label class="matlabel">Refund amount<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="refundAmount"
                                                    matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-6" [hidden]="billListFlag">
                                            <mat-label class="matlabel">Remaks<br>
                                                <textarea class="ipcss" name="" id="" cols="30" rows="10"
                                                    [(ngModel)]="billRemarks" required></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6" [hidden]="billListFlag">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Client details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom"
                                            [hidden]="billListFlag">
                                            <mat-label class="matlabel">First name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="fName" matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom"
                                            [hidden]="billListFlag">
                                            <mat-label class="matlabel">Middle name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="mName" matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom"
                                            [hidden]="billListFlag">
                                            <mat-label class="matlabel">Last name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="lName" matInput>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div [hidden]="billDetails.length == 0" class="dig_table_overflow">
                                <table class="card_tbl table-dynamic">
                                    <thead>
                                        <tr>
                                            <th>Charge</th>
                                            <th>Rate</th>
                                            <th>Concession</th>
                                            <th>Amount</th>
                                            <th>Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let bill of billDetails">
                                            <td class="align_left">{{bill.charge_desc}}</td>
                                            <td class="align_right">{{bill.amount_txt}}</td>
                                            <td class="align_right">{{bill.concession}}</td>
                                            <td class="align_right"> {{bill.fee}}</td>
                                            <td><input type="checkbox" (click)="calculateRefund(bill.charge_type)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>