export class Helper_nephro_casesheet {
  //physical tab
  static neph_age: any;
  static neph_gender;
  static neph_occ: any;
  static neph_phy_act: any;
  static skin_array: any;
  static skin_du_array: any;
  static skin_flag:string;
  static hear_array: any;
  static hear_du_array: any;
  static hear_flag:string;
  static abd_array: any;
  static abd_du_array: any;
  static abdomen_flag:string;
  static eye_array: any;
  static eye_du_array: any;
  static eye_flag:string;
  static cardio_array: any;
  static cardio_du_array: any;
  static cardio_flag: string;
  static res_array: any;
  static res_du_array: any;
  static resp_flag:string;
  static neph_other: any;
  static cau_dis_array: any;
  static med_hist_array: any;
  static complications_array: any;
  static neph_stress: any;
  //habits tab
  static alc_data: any;
  static qty_array: any;
  static mix_type_array: any;
  static alc_duration_drop;
  static alc_frq_drop;
  static smoke_data: any;
  static smoke_duration_drop;
  static smoke_frq_drop;
  static gutka_data: any;
  static gutka_duration_drop: any;
  static gutka_frq_drop: any;
  static exe_data: any;
  static exe_duration_drop;
  static exe_route_drop;
  static diet_habits;
  //bmi & vitals tab
  static neph_ht: any;
  static neph_ht_measure: any;
  static neph_wt: any;
  static neph_wt_measure: any;
  static blood_pressure_sit: any;
  static heart_beat_sit: any;
  static res_rate_sit: any;
  static blood_pressure_stand: any;
  static heart_beat_stand: any;
  static res_rate_stand: any;
  //family hist tab
  static neph_diab: any;
  static neph_heart_attack: any;
  static neph_stroke: any;
  static neph_arterial: any;
  static neph_endo: any;
  static neph_auto: any;
  static neph_hyper: any;
  static neph_hemo: any;
  static neph_tuber: any;
  static neph_hiv: any;
  static neph_hep: any;
  static neph_glacoma: any;
  static neph_family_other: any;
  //diagnosis outcome tab
  static neph_disease: any;
  static neph_icdcode: any;
  static neph_icddes: any;
  static neph_treat: any;
  static neph_stage: any;
  static neph_dia_type: any;
  static neph_dia_freq: any;
  static image_path: any;

  //treatment plan
  static exercise_array: any;
  static yoga_array: any;
  static duration_drop: any;
  static freq_drop: any;
  static diet_follow_array: any;
  static diet_avoid_array: any;
  static next_vit: any;
  static hosp_name: any;
  static spl_array: any;
  static neph_ref: any;
  static disease_cause_flag: any;
  static medical_history_flag: any;
  static comp_history_flag:any;
  
  // prognosis array
  static neph_prg_array: any;

  //diet plan
  static diet_plan_array: any;
  static diet_plan_chk_falg: any;

  // get retrival data
  static nephro_get_ret: any;

  static prescription: any;
  static diag_prescription: any;


  // history array
  static personal_hist_array: any;
  static Habitual_hist_array: any;
  static family_hist_array:any;
  static bmi_hist_array: any;
  static vitals_sit_array: any;
  static diet_hist_array:any;
  static tracker_hist_array:any;
  static neph_remarks:any;

  static bmr;

  // physiio
  static physio_therapy: any;
  static hptl_clinic_id: any;
  static physio_id :any;
  static client_reg_id: any;
  static relation_id :any;
  static sub_rel_id: any;
  static created_by: any;
}