<div *ngIf="medPresFlag == 'min'">
   <div *ngIf="medInpatientViewPrec">
      <p *ngIf="viewpreslist_array.length == 0">No prescription details found</p>
      <div class="dig_table_overflow" style="height: 200px;" *ngIf="viewpreslist_array.length != 0">
         <div class="table-responsive">
            <table id="tbl" class="table table-hover table-dynamic">
               <thead>
                  <tr>
                     <th style="padding-left: 8px !important;width: 100px;">Date</th>
                     <th>Prescription</th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let prescription of viewpreslist_array | slice: 0 : 2">
                     <td *ngIf="!header_footer_flag" style="font-size: 11px !important;">{{prescription.date}}</td>
                     <td style="font-size: 11px !important;">{{"Dr. " + prescription.tot_doct_name}} prescribed for
                        {{prescription.disease}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   </div>
   <div *ngIf="!medInpatientViewPrec">
      <p *ngIf="med_pres_list.length == 0">No prescription details found</p>
      <div class="dig_table_overflow" style="height: 200px;" *ngIf="med_pres_list.length != 0">
         <div class="table-responsive">
            <table id="tbl" class="table table-hover table-dynamic">
               <thead>
                  <tr>
                     <th style="padding-left: 8px !important;width: 100px;">Date</th>
                     <th>Prescription</th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let prescription of med_pres_list | slice: 0 : 2">
                     <td *ngIf="!header_footer_flag" style="font-size: 11px !important;">{{prescription.date}}</td>
                     <td style="font-size: 11px !important;">{{"Dr. " + prescription.doctorname}} prescribed for
                        {{prescription.disease}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   </div>
</div>

<div *ngIf="medPresFlag == 'max'">
   <div *ngIf="!addmed" class="splitter">
      <div style="float: right;margin-bottom: 10px;">
         <img *ngIf="!add_MP" style="width: 23px !important; position: absolute; top: 5px; right: 33px;"
            src="../../../assets/ui_icons/minussybl.svg" (click)="addMedPrescription()" />
         <a style="margin:0 5px;" *ngIf="backFlag" (click)="backInpatiantInfo()"><img
               src="../../../assets/ui_icons/buttons/Back_button.svg" class="backimgbtn_inpatinfo" /></a>

         <a *ngIf="list_button_flag" (click)="spiltarea('list_view')"><img
               src="../../../assets/ui_icons/pres_list_view.svg" class="backimgbtn_inpatinfo"
               style="position: absolute; top: 7px; right: 61px; width: 20px;" /></a>
         <a *ngIf="!print_view_flag && printFlag" (click)="print_Viewprescription()"><span class="cardsave"
               style="right: 84px;"><img src="../../../assets/img/printer.svg" style="width:25px" /></span> </a>

      </div>

      <div *ngIf="medInpatientViewPrec">
         <div class="container">
            <div class="row" style="max-height: 250px;">
               <div *ngIf="viewpreslist_array.length ==0">
                  <p class="nodata">No prescription data found</p>
               </div>
               <div *ngIf="viewpreslist_array.length !=0">
                  <div *ngFor="let viewlist of viewpreslist_array">
                     <!-- <div class="cover_div"> -->
                     <div class="row">
                        <div class="col-6">
                           <mat-label class="matlabel">Doctor name: {{viewlist.tot_doct_name}}</mat-label>
                        </div>
                        <div class="col-6">
                           <mat-label class="matlabel" style="float:right;">{{viewlist.view_date}}</mat-label>
                        </div>
                        <div class="col-6">
                           <mat-label class="matlabel">disease: {{viewlist.disease}}</mat-label>
                        </div>
                        <div class="col-12">

                           <div class="col-12">
                              <div class="dig_table_overflow">
                                 <table id="tbl" class="table table-hover table-dynamic">
                                    <thead>
                                       <tr>
                                          <th>Medcine name</th>
                                          <th>Duration</th>
                                          <th>Intake</th>

                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let viewmed of viewlist.medicines;  let i=index">
                                          <td style="text-align: left;padding-left: 30px;">{{viewmed.drug}}</td>
                                          <td style="text-align: left;padding-left: 30px;">{{viewmed.duration}}</td>
                                          <td style="text-align: left;padding-left: 30px;">{{viewmed.catagory }}</td>

                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           <!-- <table style="border: 1px solid #06438a;width: 100%;height: 41px;border: 1px solid #06438a;margin: 5px auto;">
                                        <tr *ngFor="let viewmed of viewlist.medicines">
                                            <td style="text-align: left;padding-left: 5px;">{{viewmed.drug}}</td>
                                            <td style="text-align: left;padding-left: 5px;">{{viewmed.catagory}}</td>
                                        </tr>
                                    </table> -->
                        </div>
                     </div>
                     <!-- </div> -->
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div *ngIf="!medInpatientViewPrec">
         <p class="nodata" *ngIf="med_pres_list.length == 0">No prescription details found</p>
         <div *ngIf="presListView">
            <div class="personaldetails">
               <div class="block1">
                
                  <div class="dig_table_overflow">
                     <table id="tbl" class="table table-hover table-dynamic" *ngIf="med_pres_list.length != 0">
                        <thead>
                           <tr>
                              <th style="padding-left: 9px !important;">Date</th>
                              <th>Prescription</th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let prescription of med_pres_list">
                              <td *ngIf="!header_footer_flag">{{prescription.date}}</td>
                              <td style="text-align: left;padding-left: 20px;">{{"Dr. " + prescription.doctorname}}
                                 prescribed for {{prescription.disease}}</td>
                              <td *ngIf="medPresFlag == 'max'"><a
                                    (click)="prescriptionGetvalue(prescription.pres_drug_id)"><img
                                       src="../../../assets/ui_icons/search_icon.svg" class="editDelicon" /></a></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
         <div *ngIf="!presListView">
            <p *ngIf="med_view_list "><strong>Prescription details</strong></p>
            <div class="personaldetails">
               <div *ngIf="med_view_list">
                  <div class="row">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="!hospflag">
                        <mat-label class="matlabel"><b>{{hptl_name}}</b></mat-label><br>
                        <mat-label class="matlabel">{{address}}</mat-label><br>
                        <mat-label class="matlabel">{{doc_loc}},{{doc_city}}</mat-label><br>
                        <mat-label class="matlabel">{{doc_state}},{{doc_country}}</mat-label><br>
                        <mat-label class="matlabel">{{doc_telephone }}</mat-label><br>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div *ngIf="docnameflag" class="med_heading">Doctor :<span
                              class="med_content">{{dr_total_name}}, {{doctor_qualify_data}}</span></div>
                        <div class="med_heading">Date :<span class="med_content">{{date_prescription}}</span></div>

                     </div>
                  </div>

                  <hr class="borderStylehr">
                  <div class="row">
                     <div class="col-12">
                        <div class="med_heading">Patient :<span class="med_content">{{total_name}}</span></div>
                        <div class="med_heading">Disease :<span class="med_content">{{disease_name}}
                              {{disease_describtion}}</span></div>
                        <div class="med_heading" *ngIf="investigation != ''">Investigation :<span
                              class="med_content">{{investigation}}</span></div>
                        <div class="med_heading" *ngIf="next_txt1 != ''">Next visit :<span
                              class="med_content">{{next_txt1}}</span></div>
                        <div class="med_heading" [hidden]="instr_flag">{{'Instructions' |translate}}:<div
                              class="matlabel" [innerHtml]="instructions"></div>
                        </div>
                     </div>
                  </div>

                  <div class="col-12">
                     <div class="dig_table_overflow">
                        <table id="tbl" class="table table-hover table-dynamic">
                           <thead>
                              <tr>
                                 <th>Medcine name</th>
                                 <th>Dosage</th>
                                 <th>Duration</th>
                                 <th>Intake</th>
                                 <th>Note</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let medicene of listProducts;  let i=index">
                                 <td style="text-align: left;padding-left: 30px;">{{medicene.short_name}} {{medicene.drug_name}}</td>
                                 <td style="text-align: left;padding-left: 30px;">{{medicene.every_six}}</td>
                                 <td>{{medicene.days}} {{medicene.period}}</td>
                                 <td style="text-align: left;padding-left: 30px;">{{medicene.dure_txt_table}}
                                    {{medicene.drug_intake }}</td>
                                 <td style="text-align: left;padding-left: 30px;">{{medicene.remarks}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>

                  <div *ngIf="!pharma_control">
                     <div class="details1" *ngFor="let pharma of pharcy_details">
                        <mat-label><b>{{pharma.pharmacy_name}}</b></mat-label>
                        <mat-label>{{pharma.phar_addr1}}, {{pharma.phar_addr2}}</mat-label>
                        <mat-label>{{pharma.phar_loc}}</mat-label>
                        <mat-label>{{pharma.phar_city}} - {{pharma.phar_zipcode}}</mat-label>
                        <mat-label>{{pharma.phar_state}}, {{pharma.phar_cnty}}</mat-label>
                        <mat-label>{{pharma.telephone}}</mat-label>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <!-- <as-split direction="vertical" *ngIf="med_pres_list.length != 0">
            <as-split-area [size]="areas[0].size" style="margin-top: -42px;">
               <div class="block1 floatright">
               </div><br>
               
            </as-split-area>
            <as-split-area [size]="areas[1].size" *ngIf="medPresFlag == 'max'">
               
            </as-split-area>
         </as-split> -->
      </div>

   </div>
   <!-- medical  -->
   <div *ngIf="addmed">
      <a (click)="print_area()" *ngIf="printFlag"><span class="cardsave" style="right: 60px;"><img
               src="../../../assets/img/printer.svg" style="width:25px" /></span> </a>
      <!-- (click)="savePrescription('noprint')" (click)="save_data();"-->
      <a [hidden]="!saveflag" (click)="savePrescription('print')" ><span class="cardsave"><img src="../../../assets/img/save.svg"
               style="width:20px" style="width:25px" /></span> </a>
      <!-- <a (click)="openwithdig_pre()"><span class="cardsave" style="right: 75px;"><img
               src="../../../assets/ui_icons/diagnosis_icon.svg" style="width:20px" style="width:20px" /></span> </a> -->
      <div id="pdffile">
         <div class="personaldetails">
            <mat-accordion class="mataccordion">
               <mat-expansion-panel class="expandpanel" [expanded]="!frommedical" *ngIf="!inpatiant_save">
                  <mat-expansion-panel-header class="exppanel width">
                     <mat-panel-title class="title">
                        Diagnosis prescription
                     </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <!-- diagnosis content start -->
                  <div>
                     <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel">Country</mat-label>
                           <select class="ipcss" [(ngModel)]="search_country"
                              (ngModelChange)="countryChange(search_country, '1')">
                              <option [ngValue]=null>select</option>
                              <option *ngFor="let country of clnt_country_list" value="{{country.description}}">
                                 {{country.description}}
                              </option>
                           </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel">State</mat-label>
                           <select class="ipcss" [(ngModel)]="search_state"
                              (ngModelChange)="stateChange(search_state, '1')">
                              <option [ngValue]=null>select</option>
                              <option *ngFor="let state of clnt_state_list" value="{{state.description}}">
                                 {{state.description}}
                              </option>
                           </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel">District / City</mat-label>
                           <select class="ipcss" [(ngModel)]="search_city"
                              (ngModelChange)="cityChange(search_city, '1')">
                              <option [ngValue]=null>select</option>
                              <option *ngFor="let city of clnt_city_list" value="{{city.description}}">
                                 {{city.description}}</option>
                           </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel">Location</mat-label>
                           <input type="text" class="ipcss" [(ngModel)]="search_location" name="loc_name"
                              (keyup)="location_change($event)" type="text" aria-label="Number" matInput
                              [matAutocomplete]="auto" />
                           <mat-autocomplete #auto="matAutocomplete">
                              <mat-option id="optionfont" (click)="location_selected(location)"
                                 *ngFor="let location of clnt_location_list" [value]="location.description">
                                 {{location.description}}
                              </mat-option>
                           </mat-autocomplete>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel">Diagnosis center<br>
                              <mat-select disableOptionCentering class="ipcss" [(ngModel)]="sercah_center">
                                 <mat-option *ngFor="let diag of diag_detail_List" (click)="serch_centerchange(diag)"
                                    value="{{diag.diag_centre_name}}"> {{diag.diag_centre_name}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                           <mat-label class="matlabel">Diagnosis address<br>
                              <textarea class="ipcss" [(ngModel)]="obs_add_diag" name="obs_add_diag"
                                 matInput></textarea>
                           </mat-label>
                        </div>
                     </div>

                     <div class="nodata" *ngIf="test_flag">No data found</div>
                     <div class="row">
                        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                           <div class="row">
                              <div class="col-12 col-sm-12 col-xl-9 col-lg-9 col-md-6">
                                 <div class="row">
                                    <div *ngFor="let testtype of Blood_test"
                                       class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                                       <div class="diagnosis_testgrid testtype">
                                          <p class="titletest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="diag_test_content_cover">
                                             <div *ngFor="let subtest of testtype.sub_tests" id="testlbel">
                                                <span *ngIf="subtest.checked == true" class="check_img"><img
                                                      (click)="ChangeTestType(false,subtest.test_id,subtest.test_name,testtype.test_type_name)"
                                                      src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                      style="cursor: pointer;" width="12px" /></span>

                                                <span *ngIf="subtest.checked == false" class="check_img"><img
                                                      (click)="ChangeTestType(true,subtest.test_id,subtest.test_name,testtype.test_type_name)"
                                                      src="../../../assets/ui_icons/yellow_box.svg"
                                                      style="cursor: pointer;" width="12px" /></span>
                                                {{subtest.test_name}}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-sm-12 col-xl-3 col-lg-3 col-md-6">
                                 <div *ngFor="let testtype of Blood_test">
                                    <div *ngIf="!bloodtest && testtype.test_type_name=='Blood Test'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!scantest && testtype.test_type_name=='Scan'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestsname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div *ngIf="!ultratest && testtype.test_type_name=='Ultra Sound'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestuname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!xraytest && testtype.test_type_name=='X-ray'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="row">
                                                <div class="matmat" *ngFor="let s of subtestxname">
                                                   <div class="testtypep">{{s.test_name}}
                                                      <span>
                                                         <img
                                                            (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                            src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                            class="dig_close" alt="">
                                                      </span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!biopsytest && testtype.test_type_name=='Biopsy'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestbname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!urinetest && testtype.test_type_name=='Urine Test'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtesturname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!faecestest && testtype.test_type_name=='Faeces Test'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestfname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div *ngIf="!culturetest && testtype.test_type_name=='Culture'">
                                       <div class="grouped-tags">
                                          <p class="title_subtest"><strong>{{testtype.test_type_name}}</strong></p>
                                          <div class="mat1">
                                             <div class="matmat" *ngFor="let s of subtestcname">
                                                <div class="testtypep">{{s.test_name}}
                                                   <span>
                                                      <img
                                                         (click)="closeSelectedTest(testtype.test_type_name,s.test_id,s.test_name)"
                                                         src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                         class="dig_close" alt="">
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row col-sm-12 col-xl-12 ">
                        <mat-label class="matlabel wt">
                           <mat-checkbox color="primary" labelPosition="before" [(ngModel)]="test_now">Would u like to
                              test now</mat-checkbox>
                        </mat-label>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">{{'Next visit' |translate}}<br>
                           <input type="date" class="ipcss_date widthappt" id="appt_date"
                              (change)="OnDateMatePicker(next_datetxt)" [(ngModel)]="next_datetxt"
                              min={{currentDate}} #matInput style="width: 140px;">
                        </mat-label>
                     </div>
                  </div>
                  <div class="row">
                     <mat-label class="matlabel">
                        <mat-checkbox labelPosition="before" [(ngModel)]="book_app_now" (change)="checkNextVisit()"> {{'Would u like to book appointment for next visit? ' |translate}} </mat-checkbox>
                     </mat-label><br>
                  </div>
                  <!-- diagnosis content end -->
               </mat-expansion-panel>
               <mat-expansion-panel class="expandpanel" [expanded]="frommedical">
                  <mat-expansion-panel-header class="exppanel width">
                     <mat-panel-title class="title">
                        Medical prescription
                     </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="hr">
                  <div style="float: right;margin-bottom: 10px; position: relative;">
                     <a style="margin-right: 5px;    position: absolute; top: -30px; right: -19px; " *ngIf="backFlag"
                        (click)="backInpatiantInfo()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                           class="backimgbtn_inpatinfo" /></a>
                     <a style="margin-right: 5px;  position: absolute;  top: -30px; right: 78px;" *ngIf="backFlag"
                        (click)="Medical_prescription_save(null)"><img
                           src="../../../assets/ui_icons/buttons/save_button.svg" class="backimgbtn_inpatinfo" /></a>
                  </div>

                  <div class="row" *ngIf="bp_flag || weight_flag || height_flag || temparature_flag">
                     <div class="col-12">
                        <div class="cover_div">
                           <div class="header_lable">
                              Vitals
                           </div>
                           <div class="content_cover">
                              <div class="row">
                                 <mat-label class="matlabel col-4" *ngIf="bp_flag">{{'BP'|translate}}: {{clnt_bp}}
                                    &nbsp;&nbsp;&nbsp;{{'Pulse'|translate}}: {{clnt_pulse}}
                                 </mat-label>
                                 <mat-label class="matlabel  col-4" *ngIf="height_flag">{{'Height'|translate}}:
                                    {{height}}
                                    &nbsp;&nbsp;&nbsp;<span *ngIf="weight_flag">{{'Weight'|translate}}:
                                       {{weight}}</span>
                                 </mat-label>
                                 <mat-label class="matlabel  col-4" *ngIf="temparature_flag">
                                    {{'Temperature'|translate}}: {{temparature}}
                                 </mat-label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div class="cover_div">
                           <div class="header_lable">
                              Investigation details
                           </div>
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-12">
                                    <mat-label class="matlabel">{{'Language'|translate}}<br>
                                       <mat-select disableOptionCentering class="ipcss" [(ngModel)]="language"
                                          (selectionChange)="languageChange()">
                                          <mat-option value="English">English</mat-option>
                                          <mat-option value="Tamil">Tamil</mat-option>
                                          <mat-option value="Telugu">Telugu</mat-option>
                                          <mat-option value="Kannada">Kannada</mat-option>
                                          <mat-option value="Malayalam">Malayalam</mat-option>
                                          <mat-option value="Oriya">Oriya</mat-option>
                                          <mat-option value="Bengali">Bengali</mat-option>
                                          <mat-option value="Hindi">Hindi</mat-option>
                                       </mat-select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12 ">
                                    <mat-label class="matlabel">
                                       <mat-radio-group class="radiobtngrp" color="primary" labelPosition="before">
                                          <mat-radio-button value="autoRx" (change)="fillType($event.value)">
                                             {{'Auto Rx'|translate}}
                                          </mat-radio-button>&nbsp;
                                          <mat-radio-button value="refill" (change)="fillType($event.value)">
                                             {{'Refill'|translate}}
                                          </mat-radio-button>&nbsp;
                                          <mat-radio-button value="previous" (click)="fillType('previous')">
                                             {{'Previous Rx'|translate}}
                                          </mat-radio-button>
                                       </mat-radio-group>
                                    </mat-label>
                                 </div>
                                 <div class="col-12">
                                    <div class="row">
                                       <div class="col-12">
                                          <mat-label class="matlabel">{{'Diagnosis'|translate}}
                                             <input type="text" class="ipcss noappt_width" [(ngModel)]="dis_txt"
                                                required (keyup)="getSymptoms()" matInput maxlength="50"
                                                autocomplete="off" />
                                             <div class="auto_complete_text" *ngIf="symptomlist.length != 0">
                                                <ul *ngFor="let symptom of symptomlist">
                                                   <li>
                                                      <a (click)="select_symptom(symptom)">{{symptom}}</a>
                                                   </li>
                                                </ul>
                                             </div>
                                          </mat-label>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-12">
                                    <mat-label class="matlabel">{{'Investigation' |translate}}<br>
                                       <textarea class="ipcss txtarea" id="myInput" rows="1" maxlength="250"
                                          [(ngModel)]="oberse_med"></textarea>
                                    </mat-label>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                        <div class="cover_div">
                           <div class="header_lable">
                              Medicine details
                           </div>
                           <div class="content_cover" style="position: relative;">
                              <a (click)="getmedicine()" class="seacrhicon"><img
                                    src="../../../assets/ui_icons/tablet_icon.svg" height="30px" width="30px" /></a>
                             
                              <div class="row" *ngIf="medicineFlag">
                                 <div class="col-xl-2"> </div>
                                 <div class="col-md-12 col-lg-12 col-xl-8" style="margin-top: 15px;">
                                    <div>
                                       <table style="width: 75%; margin: 0 auto;" *ngIf="mediceList.length != 0"
                                          mdbTable datatable [dtOptions]="dtOptions"
                                          class="table table-nowrap table-sm dataTable billlisttable">
                                          <thead>
                                             <tr>
                                                <th style="width: 45% !important;">Medicine name</th>
                                                <th>Quantity</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             <tr *ngFor="let person of mediceList; let i = index"
                                                (click)="setMedicien(person.med_id)">
                                                <td style="font-size: 12px;text-align: left;">{{person.type_name}}
                                                   {{person.med_name}} </td>
                                                <td style="font-size: 12px;text-align: right;">{{person.quantity}}</td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div class="col-xl-2"> </div>
                              </div>

                              <div class="row" *ngIf="!medicineFlag">
                                 <div class="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-3">
                                    <mat-checkbox color="primary" [(ngModel)]="new_med" (change)="showMedType(new_med)">
                                    </mat-checkbox>
                                    <mat-label class="matlabel">
                                       {{'New medicine'|translate}}
                                    </mat-label>
                                 </div>

                                 <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-9">
                                    <mat-label class="matlabel" [hidden]="newmed_flag">{{'Medicine type' |translate}}
                                       <input type="text" class="ipcss " required maxlength="50"
                                          [(ngModel)]="medtypetxt" (keyup)="Get_medicine_type($event)"
                                          (focus)="clearmedtype()" aria-label="Number" matInput autocomplete="on"
                                          [matAutocomplete]="auto2" style="max-width: 175px;" />
                                       <mat-autocomplete #auto2="matAutocomplete">
                                          <mat-option id="optionfont" (click)="select_medicine_type(medtype)"
                                             *ngFor="let medtype of Medical_prescription_array"
                                             value={{medtype.med_name}}>
                                             {{medtype.med_name}}
                                          </mat-option>
                                       </mat-autocomplete>
                                    </mat-label>
                                 </div>
                                 <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="genericDisplayFlag">
                                    <mat-label class="matlabel">Generic name
                                       <input type="text" class="ipcss " required maxlength="50"  [(ngModel)]="ganericName" (keyup)="Medicine_name_change($event,'generic')" aria-label="Number" matInput [matAutocomplete]="auto6" />
                                       <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                                          <mat-option id="optionfont" (click)="select_medicine_item(medicine)"
                                             *ngFor="let medicine of Medicne_data" [value]="medicine.name">
                                             {{medicine.name}}
                                          </mat-option>
                                       </mat-autocomplete>
                                    </mat-label>
                                 </div>
                                 <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <mat-label class="matlabel">{{'Medicine name'|translate}}
                                       <input type="text" class="ipcss " required maxlength="50"
                                          [(ngModel)]="medicine_nametxt" (keyup)="Medicine_name_change($event,'medicine')"
                                          aria-label="Number" matInput [matAutocomplete]="auto6" />
                                       <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                                          <mat-option id="optionfont" (click)="select_medicine_item(medicine)"
                                             *ngFor="let medicine of Medicne_data" [value]="medicine.name">
                                             {{medicine.name}}
                                          </mat-option>
                                       </mat-autocomplete>
                                    </mat-label>
                                 </div>

                                 <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" [hidden]="daydur_show">
                                    <mat-label class="matlabel">{{'Duration'|translate}}</mat-label>
                                    <div class="row">
                                       <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                          <input type="text" class="ipcss" matInput required maxlength="5"
                                             onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                             [(ngModel)]="day_txt" />
                                       </div>

                                       <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6" [hidden]="daydur_show">
                                          <mat-select disableOptionCentering class="ipcss "
                                             (selectionChange)="Duration()" [hidden]="daydur_show"
                                             [(ngModel)]="daydur_txt">
                                             <mat-option disabled>Select</mat-option>
                                             <mat-option value="day(s)">day(s)</mat-option>
                                             <mat-option value="week(s)">week(s)</mat-option>
                                             <mat-option value="month(s)">month(s)</mat-option>
                                          </mat-select>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3" [hidden]="intake_show">
                                    <mat-label class="matlabel">{{'Intake'|translate}}<br>
                                       <mat-select disableOptionCentering class="ipcss " required
                                          (selectionChange)="intake_change()" [(ngModel)]="afterfood_txt">
                                          <mat-option value="Select">Select</mat-option>
                                          <mat-option value="Empty stomach">Empty stomach</mat-option>
                                          <mat-option value="After food">After food</mat-option>
                                          <mat-option value="Before food">Before food</mat-option>
                                          <mat-option value="With food">With Food</mat-option>
                                       </mat-select>
                                    </mat-label>
                                 </div>

                                 <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1" [hidden]="dure_show">
                                    <mat-label [hidden]="dure_show" class="matlabel">{{'Min'|translate}}
                                       <input type="text" class="ipcss " matInput [required]="dure_require"
                                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                                          [(ngModel)]="dure_txt" />
                                    </mat-label>
                                 </div>
                                 <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1" [hidden]="!dure_show">

                                 </div>
                                 <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3" [hidden]="mix_show">
                                    <mat-label class="matlabel">{{'Mixed with'|translate}}<br>
                                       <mat-select disableOptionCentering class="ipcss " [(ngModel)]="mixing_txt">
                                          <mat-option value="Select">Select</mat-option>
                                          <mat-option *ngFor="let mixing of mixtype" value={{mixing}}>{{mixing}}</mat-option>
                                       </mat-select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                    <mat-label class="matlabel">{{'Intake session'|translate}}<br>
                                       <mat-select disableOptionCentering class="ipcss " required
                                          [(ngModel)]="intake_txt">
                                          <mat-option disabled>Select</mat-option>
                                          <mat-option *ngFor="let intake of Get_Intake_array"
                                             (click)="sesssion_basechange()" value={{intake.description}}>
                                             {{intake.description}}</mat-option>
                                       </mat-select>
                                    </mat-label>
                                 </div>

                                 <div [hidden]="morn_eventxt" class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                    <div [hidden]="mor_lable">
                                       <mat-label class="matlabel input">{{'Mor'|translate}}
                                          <input type="text" class="ipcss " matInput [required]="morning_req"
                                             [(ngModel)]="mor" [disabled]="morning_dis" maxlength="5"
                                             onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)" />
                                       </mat-label>
                                    </div>

                                    <div [hidden]="quan_lable">
                                       <mat-label class="matlabel">{{'Qty'|translate}}
                                          <input type="text" class="ipcss " matInput [required]="morning_req"
                                             maxlength="5"
                                             onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                             [disabled]="morning_dis" [(ngModel)]="mor" />
                                       </mat-label>
                                    </div>
                                 </div>

                                 <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 " [hidden]="after_eventxt">
                                    <span class="iocn_position">-</span>
                                    <mat-label class="matlabel">{{'Aft'|translate}}
                                       <input type="text" class="ipcss " matInput [disabled]="afternoon_dis"
                                          maxlength="5"
                                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                          [required]="afternoon_req" [(ngModel)]="aft" />
                                    </mat-label>
                                 </div>

                                 <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="eve_eventxt">
                                    <span class="iocn_position ">-</span>
                                    <mat-label class="matlabel">{{'Eve'|translate}}
                                       <input type="text" class="ipcss " matInput [disabled]="even_dis"
                                          [required]="evening_req" maxlength="5"
                                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                          [(ngModel)]="eve" />
                                    </mat-label>
                                 </div>

                                 <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="night_eventxt">
                                    <span class="iocn_position">-</span>
                                    <mat-label class="matlabel">{{'Ngt'|translate}}
                                       <input type="text" class="ipcss " matInput [disabled]="ngt_dis"
                                          [required]="night_req" maxlength="5"
                                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                          [(ngModel)]="ngt" />
                                    </mat-label>
                                 </div>

                                 <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <mat-label class="matlabel">{{'Note'|translate}}<br>
                                       <textarea class="ipcss widthappt" id="myInput" maxlength="250"
                                          [(ngModel)]="remark_txt"></textarea>
                                    </mat-label>
                                 </div>
                                 <div *ngIf="Med_addbtn" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-auto"
                                    style="text-align: right;">
                                    <a (click)="Medicalpres_add()"><img
                                          src="../../../assets/ui_icons/buttons/add_button.svg"
                                          class="saveimgbtn_inpatinfo" /></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-12" style="margin: 20px 0;">
                        <div class="col-12 dig_table_overflow" [hidden]="Medicine_table">
                           <div class="table-responsive">
                              <table id="tbl" *ngIf="listProducts.length !=0 ">
                                 <thead>
                                    <tr>
                                       <th style="width: 11%;">{{'Medicine type'|translate}}</th>
                                       <th style="width: 16%;">{{'Medicine name'|translate}}</th>
                                       <th style="width: 7%;">{{'Duration'|translate}}</th>
                                       <th style="width: 12%;">{{'Intake session'|translate}}</th>
                                       <th style="width: 10%;">{{'Frequency'|translate}}</th>
                                       <th style="width: 15%;">{{'Intake'|translate}}</th>
                                       <th style="width: 22%;">{{'Note'|translate}}</th>
                                       <th>{{'Action'|translate}}</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr *ngFor="let product of listProducts">
                                       <td style="text-align: left;">{{product.short_name}}</td>
                                       <td style="text-align: left;">{{product.drug_name}}</td>
                                       <td style="text-align: left;">{{product.days}} {{product.period |translate}}</td>
                                       <td>{{product.every_six}}</td>
                                       <td style="text-align: left;">{{product.frequency |translate}}</td>
                                       <td style="text-align: left;">{{product.dure_txt_table}} {{product.drug_intake
                                          |translate}} {{product.mixval}}</td>
                                       <td style="text-align: left;">{{product.remarks}}</td>
                                       <td>
                                          <a (click)="editMedicalPres(product.drug_id,product.rowid)">
                                             <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                class="editDelicon1" /></a>&nbsp;&nbsp;
                                          <a (click)="Delete_medicalpres(product.med_typetxt,product.drug_name)"><img
                                                src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                class="editDelicon1" /></a>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div class="cover_div">
                           <div class="header_lable">
                              Pharmacy details
                           </div>
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-12">
                                    <mat-label class="matlabel">{{'Preferred pharmacy' |translate}}
                                       <input type="text" class="ipcss" maxlength="50" [(ngModel)]="prfer_pharmatxt"
                                          (keyup)="Prefer_pharmacy_change($event)" aria-label="Number" matInput
                                          [matAutocomplete]="auto1" />
                                       <mat-autocomplete #auto1="matAutocomplete">
                                          <mat-option id="optionfont" (click)="Pharma_medicine_name(prfer_pharmatxt)"
                                             *ngFor="let pharma of Pharmanamelist" [value]="pharma">
                                             {{pharma}}
                                          </mat-option>
                                       </mat-autocomplete>
                                    </mat-label>
                                 </div>
                                 <div class="col-12">
                                    <mat-label class="matlabel">{{'Preferred pharmacy address' |translate}}<br>
                                       <textarea class=" ipcss  txtarea" disabled="true" id="myInput" rows="1" [(ngModel)]="prefer_add"></textarea>
                                    </mat-label>
                                 </div>
                                 <div class="col-12">
                                    <div>
                                       <mat-label class="matlabel">
                                          <mat-checkbox labelPosition="before" [(ngModel)]="order_now"> {{'Would you like to place order now' |translate}} </mat-checkbox>
                                       </mat-label><br>
                                    </div>
                                    <div *ngIf="showInst">
                                       <mat-label class="matlabel"><strong>{{"Dr."+doctorname}} 
                                          <span style="font-size: 10px;">{{qualification}}</span></strong></mat-label>
                                       <br>
                                       <mat-label class="matlabel"> ({{licence_code}})</mat-label><br>
                                       <mat-label class="matlabel">{{'Find your prescription online at'|translate}}
                                          www.tervys.com
                                       </mat-label><br>
                                       <mat-label class="matlabel"><b><i>This is digitally generated Prescription</i></b>
                                       </mat-label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                        <div class="cover_div" style="padding-bottom: 17px;">
                           <div class="header_lable">
                              Instructions
                           </div>
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-12" *ngIf="showInst">
                                    <quill-editor id="editor" [(ngModel)]="instructions" [style]="editorStyle"
                                       [modules]="modules" placeholder="Enter Text"
                                       (onSelectionChanged)="onSelectionChanged($event)"
                                       (onContentChanged)="onContentChanged($event)"></quill-editor>
                                 </div>
                                 <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">{{'Next visit' |translate}}<br>
                                       <input type="date" class="ipcss_date widthappt" id="appt_date"
                                          (change)="OnDateMatePicker(next_datetxt)" [(ngModel)]="next_datetxt"
                                          min={{currentDate}} #matInput style="width: 140px;">
                                    </mat-label>
                                 </div>
                                 
                              </div>
                              <div class="row">
                                 <mat-label class="matlabel">
                                    <mat-checkbox labelPosition="before" [(ngModel)]="book_app_now" (change)="checkNextVisit()"> {{'Would u like to book appointment for next visit? ' |translate}} </mat-checkbox>
                                 </mat-label><br>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </mat-expansion-panel>
            </mat-accordion>

            <div class="row col-sm-12 col-xl-12" *ngIf="print_medidiag">
               <mat-label class="matlabel wt">
                  <mat-checkbox color="primary" labelPosition="before" [(ngModel)]="print_same_age">Would u like print
                     medical and diagnosis prescription in same page</mat-checkbox>
               </mat-label>
            </div>
         </div>
      </div>
   </div>
   <!-- expand  -->
   <div [hidden]="true">
      <div #printlogowithname id="printlogowithname"
         style="background-image: url({{bg_image}}); background-position: center;">
         <div *ngIf="!print_same_age">
            <div *ngIf="listProducts.length != 0 && print_view_flag">
               <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                     <td style="width: 450px; vertical-align: top;">
                        <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                        <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                        <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                        <p style="margin-left: 110px;">{{doct_city}}</p>
                        <p style="margin-left: 110px;">{{doct_state}}</p>
                     </td>
                     <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                        {{product.drug_intake|translate}} {{product.mixval}} </td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
            <div *ngIf="print_diag">
               <div class="page-break" *ngIf="listProducts.length != 0"></div>
               <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                     <td style="width: 450px; vertical-align: top;">
                        <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                        <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                        <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                        <p style="margin-left: 110px;">{{doct_city}}</p>
                        <p style="margin-left: 110px;">{{doct_state}}</p>
                     </td>
                     <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}</p>
                        <p><b>{{'Gender'|translate}}:</b> {{client_gender}} </p>
                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_diag">
                  <p><b>Diagnostic Test</b></p>
                  <div *ngIf="pritn_blood_flag">
                     <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{blood_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_scan_flag">
                     <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{scan_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_us_flag">
                     <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{us_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_xray_flag">
                     <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{xray_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_bio_flag">
                     <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{bio_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ut_flag">
                     <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ut_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ft_flag">
                     <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ft_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_culture_flag">
                     <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{culture_test_content}}
                     </p>
                  </div>
                  <!-- <div style="margin-left: 50px;">
                     <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                  </div> -->
                  <br />
                  <div style="margin-left: 50px;">
                     <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                           {{qualification}}</strong>
                     </p>
                     <p style="font-size: 13px">({{licence_code}})</p>
                     <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                     </p>
                     <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                     </p>
                     <br />
                  </div>
               </div>
            </div>
         </div>
         <div *ngIf="print_same_age">
            <div>
               <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                     <td style="width: 450px; vertical-align: top;">
                        <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                        <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                        <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                        <p style="margin-left: 110px;">{{doct_city}}</p>
                        <p style="margin-left: 110px;">{{doct_state}}</p>
                     </td>
                     <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency | translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                        {{product.drug_intake|translate}} {{product.mixval}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
                  </tr>
               </table>
            </div>
            <div style="margin-left: 50px;" *ngIf="print_diag">
               <p><b>Diagnostic Test</b></p>
               <div *ngIf="pritn_blood_flag">
                  <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{blood_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_scan_flag">
                  <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{scan_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_us_flag">
                  <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{us_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_xray_flag">
                  <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{xray_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_bio_flag">
                  <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{bio_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ut_flag">
                  <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ut_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ft_flag">
                  <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ft_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_culture_flag">
                  <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{culture_test_content}}
                  </p>
               </div>
            </div>
            <div style="margin-left: 50px;">
               <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="print_instruction"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 50px;" *ngIf="treatflag">
               <p><b>{{'Treatment' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="treatment"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 50px;">
               <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
            </div>
            <br />
            <div>
               <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
               </p>
               <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
               <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
               <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
               <br />
            </div>
         </div>
         <div *ngIf="!print_view_flag">
            <div *ngIf="listProducts.length != 0">
               <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                     <td style="width: 450px; vertical-align: top;">
                        <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                        <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                        <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                        <p style="margin-left: 110px;">{{doct_city}}</p>
                        <p style="margin-left: 110px;">{{doct_state}}</p>
                     </td>
                     <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency | translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                        {{product.drug_intake|translate}} {{product.mixval}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
         </div>
      </div>

      <div #printbanner id="printbanner" style="background-image: url({{bg_image}}); background-position: center;">
         <div *ngIf="!print_same_age">
            <div *ngIf="listProducts.length != 0 && print_view_flag">
               <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                     <td>
                        <img alt="image" src={{hptl_pres_logo}} style={{preslogo_style}}>
                     </td>
                  </tr>
                  <tr>
                     <td colspan="2">
                       <div [innerHTML]="headerstyle"></div>
                     </td>
                 </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency|translate}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                           {{product.drug_intake|translate}} {{product.mixval}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
            <div *ngIf="print_diag">
               <div class="page-break" *ngIf="listProducts.length != 0"></div>
               <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                     <td>
                        <img alt="image" src={{hptl_pres_logo}} width="750px" height="250px">
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}</p>
                        <p><b>{{'Gender'|translate}}:</b> {{client_gender}} </p>
                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_diag">
                  <p><b>Diagnostic Test</b></p>
                  <div *ngIf="pritn_blood_flag">
                     <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{blood_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_scan_flag">
                     <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{scan_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_us_flag">
                     <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{us_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_xray_flag">
                     <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{xray_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_bio_flag">
                     <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{bio_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ut_flag">
                     <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ut_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ft_flag">
                     <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ft_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_culture_flag">
                     <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{culture_test_content}}
                     </p>
                  </div>
                  <!-- <div style="margin-left: 50px;">
                     <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                  </div> -->
                  <br />
               </div>
               <div style="margin-left: 50px;">
                  <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                        {{qualification}}</strong>
                  </p>
                  <p style="font-size: 13px">({{licence_code}})</p>
                  <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
         </div>
         <div *ngIf="print_same_age">
            <div>
               <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                     <td>
                        <img alt="image" src={{hptl_pres_logo}} [style]="preslogo_style">
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                           {{product.drug_intake|translate}} {{product.mixval}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>
            </div>
            <div style="margin-left: 50px;" *ngIf="print_diag">
               <p><b>Diagnostic Test</b></p>
               <div *ngIf="pritn_blood_flag">
                  <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{blood_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_scan_flag">
                  <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{scan_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_us_flag">
                  <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{us_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_xray_flag">
                  <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{xray_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_bio_flag">
                  <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{bio_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ut_flag">
                  <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ut_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ft_flag">
                  <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ft_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_culture_flag">
                  <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{culture_test_content}}
                  </p>
               </div>
            </div>
            <div style="margin-left: 50px;">
               <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="print_instruction"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 50px;" *ngIf="treatflag">
               <p><b>{{'Treatment' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="treatment"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 50px;">
               <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
            </div>
            <br />
            <div>
               <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
               </p>
               <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
               <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
               <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
               <br />
            </div>
         </div>
         <div *ngIf="!print_view_flag">
            <div *ngIf="listProducts.length != 0">
               <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                     <td>
                        <img alt="image" src={{hptl_pres_logo}} [style]="preslogo_style">
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                           {{product.drug_intake|translate}} {{product.mixval}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
         </div>
      </div>

      <div #printnoheader id="printnoheader" style="background-image: url({{bg_image}}); background-position: center;">
         <div *ngIf="!print_same_age">
            <div *ngIf="listProducts.length != 0 && print_view_flag">
               <table style="margin-left: 50px; margin-top:220px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency | translate}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                           {{product.drug_intake|translate}} {{product.mixval}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
               </div>
               <br />
               <div style="margin-left: 50px;">
                  <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="font-size: 13px">({{licence_code}})</p>
                  <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                  <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
                  <br />
               </div>
            </div>
            <div *ngIf="print_diag">
               <div class="page-break" *ngIf="listProducts.length != 0"></div>
               <table style="margin-left: 50px; margin-top:220px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}</p>
                        <p><b>{{'Gender'|translate}}:</b> {{client_gender}} </p>
                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_diag">
                  <p><b>Diagnostic Test</b></p>
                  <div *ngIf="pritn_blood_flag">
                     <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{blood_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_scan_flag">
                     <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{scan_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_us_flag">
                     <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{us_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_xray_flag">
                     <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{xray_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_bio_flag">
                     <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{bio_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ut_flag">
                     <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ut_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ft_flag">
                     <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ft_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_culture_flag">
                     <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{culture_test_content}}
                     </p>
                  </div>
                  <!-- <div style="margin-left: 50px;">
                     <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                  </div> -->
                  <br />

               </div>
               <div style="margin-left: 50px;">
                  <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="font-size: 13px">({{licence_code}})</p>
                  <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                  <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
                  <br />
               </div>
            </div>
         </div>
         <div *ngIf="print_same_age">
            <div>
               <table style="margin-left: 50px; margin-top:220px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                        {{product.drug_intake|translate}} {{product.mixval}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
                  </tr>
               </table>
            </div>
            <div style="margin-left: 50px;" *ngIf="print_diag">
               <p><b>Diagnostic Test</b></p>
               <div *ngIf="pritn_blood_flag">
                  <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{blood_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_scan_flag">
                  <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{scan_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_us_flag">
                  <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{us_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_xray_flag">
                  <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{xray_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_bio_flag">
                  <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{bio_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ut_flag">
                  <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ut_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ft_flag">
                  <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ft_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_culture_flag">
                  <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{culture_test_content}}
                  </p>
               </div>
            </div>
            <div style="margin-left: 50px;">
               <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="print_instruction"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 50px;" *ngIf="treatflag">
               <p><b>{{'Treatment' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="treatment"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 50px;">
               <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
            </div>
            <br />
            <div>
               <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
               </p>
               <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
               <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
               <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
               <br />
            </div>
         </div>
         <div *ngIf="!print_view_flag">
            <div *ngIf="listProducts.length != 0">
               <table style="margin-left: 50px; margin-top:220px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency | translate}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                           {{product.drug_intake|translate}} {{product.mixval}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
               </div>
               <br />
               <div style="margin-left: 50px;">
                  <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="font-size: 13px">({{licence_code}})</p>
                  <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                  <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
                  <br />
               </div>
            </div>
         </div>
      </div>
      <div #printnoheadsidebar id="printnoheadsidebar"
         style="background-image: url({{bg_image}}); background-position: center;">
         <div *ngIf="!print_same_age">
            <div *ngIf="listProducts.length != 0 && print_view_flag">
               <table style="margin-left: 220px; margin-top:350px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 220px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 220px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency | translate}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                           {{product.drug_intake|translate}} {{product.mixval}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 220px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 220px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 220px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 220px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 220px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
            <div *ngIf="print_diag">
               <div class="page-break" *ngIf="listProducts.length != 0"></div>
               <table style="margin-left: 220px; margin-top:220px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}</p>
                        <p><b>{{'Gender'|translate}}:</b> {{client_gender}} </p>
                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 220px;" *ngIf="print_diag">
                  <p><b>Diagnostic Test</b></p>
                  <div *ngIf="pritn_blood_flag">
                     <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{blood_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_scan_flag">
                     <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{scan_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_us_flag">
                     <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{us_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_xray_flag">
                     <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{xray_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_bio_flag">
                     <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{bio_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ut_flag">
                     <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ut_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ft_flag">
                     <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ft_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_culture_flag">
                     <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{culture_test_content}}
                     </p>
                  </div>
                  <!-- <div style="margin-left: 220px;">
                     <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                  </div> -->
                  <br />

               </div>
               <div style="margin-left: 220px;">
                  <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="font-size: 13px">({{licence_code}})</p>
                  <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>

            </div>
         </div>
         <div *ngIf="print_same_age">
            <div>
               <table style="margin-left: 220px; margin-top:350px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 220px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 220px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency | translate}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                           {{product.drug_intake|translate}} {{product.mixval}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>
            </div>
            <div style="margin-left: 220px;" *ngIf="print_diag">
               <p><b>Diagnostic Test</b></p>
               <div *ngIf="pritn_blood_flag">
                  <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{blood_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_scan_flag">
                  <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{scan_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_us_flag">
                  <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{us_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_xray_flag">
                  <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{xray_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_bio_flag">
                  <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{bio_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ut_flag">
                  <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ut_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ft_flag">
                  <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ft_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_culture_flag">
                  <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{culture_test_content}}
                  </p>
               </div>
            </div>
            <div style="margin-left: 220px;">
               <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="print_instruction"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 220px;" *ngIf="treatflag">
               <p><b>{{'Treatment' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="treatment"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 220px;">
               <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
            </div>
            <br />
            <div>
               <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
               </p>
               <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
               <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
               <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
               <br />
            </div>
         </div>
         <div *ngIf="print_view_flag">
            <div *ngIf="listProducts.length != 0">
               <table style="margin-left: 220px; margin-top:350px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 220px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 220px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency | translate}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                           {{product.drug_intake|translate}} {{product.mixval}}</td>
                        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 220px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 220px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 220px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 220px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 220px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
         </div>
         <!-- <footer [hidden]="printf_flag">
              <img src={{footer_image}}>
            </footer> -->
      </div>
   </div>
</div>