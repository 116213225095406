<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Patient details</h5>
                    </div>
                    <!-- *ngIf="bookFlag" -->
                    <div class="headerButtons">
                        <a style="margin:0 5px;" (click)="back()">
                            <img src="../../../assets/ui_icons/buttons/Back_button.svg"
                                class="backimgbtn_inpatinfo" /></a>
                        <a><img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
                                (click)="updatePatientDetails()"/></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="" style="margin-top: -10px;">
                        <div class="container-fluid" style="margin-left: -20px;">
                            <div class="row" style="margin-right: -50px;">
                                <div class="col-9">
                                    <div class="cover_div" style="padding-bottom: 13px;">
                                        <div class="header_lable">
                                            Profile details
                                        </div>
                                        <div class="content_cover">
                                            <div class="row">
                                                <!-- <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3"
                                                    [hidden]="searchFlag">
                                                    <mat-label class="matlabel">Patient id
                                                        <input class="ipcss widthappt" [(ngModel)]="clientId" readonly
                                                            matInput />
                                                    </mat-label>
                                                </div> -->
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Salutation
                                                        <br>
                                                        <select class="ipcss widthappt" [(ngModel)]="clntSal"
                                                            (ngModelChange)="changeSalutation($event)">
                                                            <option value="select" Selected>Select</option>
                                                            <option *ngFor="let sal of clntSalList"
                                                                value="{{sal.sal_id}}">{{sal.sal_desc}}</option>
                                                        </select>
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">First name
                                                        <input class="ipcss widthappt" [(ngModel)]="clntFirstName"
                                                            (blur)="fnameToUpper()" matInput />
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Middle name
                                                        <input class="ipcss widthappt" [(ngModel)]="clntMiddleName"
                                                            (blur)="mnameToUpper()" matInput />
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Last name
                                                        <input class="ipcss widthappt" [(ngModel)]="clntLastName"
                                                            (blur)="lnameToUpper()" matInput />
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">DOB
                                                        <input type="date" class="ipcss_date noappt_width heightdob"
                                                            (change)="selectDob(clntDOB)" [(ngModel)]="clntDOB" #matInput>
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Age
                                                        <input [(ngModel)]="clntAge" [readonly]="ageRead"
                                                            [required]="!ageRead" 
                                                            class="ipcss widthappt" required matInput>
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Mobile
                                                        <input [(ngModel)]="clntContNumber" maxlength="10" required
                                                            class="ipcss widthappt" matInput>
                                                    </mat-label>
                                                </div>
                    
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Gender<br>
                                                        <select [(ngModel)]="clntGender" class="ipcss widthappt"
                                                            (ngModelChange)="changeGender($event)">
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Transgender">Transgender</option>
                                                        </select>
                                                    </mat-label>
                                                </div>
                                                
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Address1
                                                        <input type="text" class="ipcss widthappt"
                                                            [(ngModel)]="clntAddress1" required matInput />
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Address2
                                                        <input type="text" class="ipcss widthappt"
                                                            [(ngModel)]="clntAddress2" matInput />
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Zipcode
                                                        <input type="text" class="ipcss widthappt"
                                                            [(ngModel)]="clntZipcode" (keyup)=zipcode_change()
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Location<br>
                                                        <input type="text" class="ipcss widthappt" required
                                                            maxlength="50" [(ngModel)]="clntLocation"
                                                            (keyup)="changeLocation(clntLocation)" matInput />
                                                        <div class="auto_complete_text" *ngIf="clntLocationList.length != 0">
                                                            <ul *ngFor="let location of clntLocationList">
                                                                <li>
                                                                    <a
                                                                        (click)="selectLocation(location)">{{location.description}}</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </mat-label>
                                                </div>

                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">District / City<br>
                                                        <mat-select required class="ipcss widthappt"
                                                            [(ngModel)]="clntCity" disableOptionCentering
                                                            (ngModelChange)="changeCity(clntCity, '0')">
                                                            <mat-option *ngFor="let city of clntCityList"
                                                                value="{{city.city_desc}}">{{city.city_desc}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-label>
                                                </div>

                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">State<br>
                                                        <mat-select required class="ipcss widthappt"
                                                            [(ngModel)]="clntState" disableOptionCentering
                                                            (ngModelChange)="changeState(clntState, '0')">
                                                            <mat-option *ngFor="let state of clntStateList"
                                                                value={{state.state_desc}}>{{state.state_desc}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-label>
                                                </div>

                                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                    <mat-label class="matlabel">Country<br>
                                                        <mat-select required [(ngModel)]="clntCountry"
                                                            class="ipcss widthappt" disableOptionCentering>
                                                            <mat-option *ngFor="let country of clntCountryList"
                                                                value={{country.country_desc}}>
                                                                {{country.country_desc}}</mat-option>
                                                        </mat-select>
                                                    </mat-label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card> <br><br><br>
    </div>
</div>