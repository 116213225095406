import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Date_Formate } from 'src/assets/js/common';

@Injectable({ providedIn: 'root' })
export class PharmaPrintService {
    print(printData){
        let printContents, popupWin;
        
        var printContt = printData.content;
        var pharmaAddr = printData.pharma_addr;
        var hospitalAddress = hospitalAddress != '' ? '<span>' + pharmaAddr + '</span>' : "";
        if(printData.dl_no != undefined)
          var dl_no = '<span> DL No : ' + printData.dl_no + ',</span>';
        if(printData.gst_no != undefined)
          var gst_no = '<span> GST : ' + printData.gst_no + ',</span>';
       
        var thead = "";
        if(printData.template == "logowithname")
            thead = '<table class="report-container" style="width: 100%;">'+'<thead class="report-header">'+ '<tr>' + '<td style="display: flex;justify-content: center;">'+ '<div>' + '<img alt="image" src = '+ printData.pharma_logo + ' width="100px" height="100px" style="vertical-align: middle;">'+ '</div>'+ '<div style="position: relative;top: 20px;left: 10px;text-align: left;">' + '<p><b>'+ printData.pharma_name + '</b>,</p>' + '<span>' + pharmaAddr + '</span><br/>' + '<span>' + printData.location + ',</span>'+ '<span>' + printData.city + ',</span>' + '<span>' + printData.state + ',</span><span>' + printData.country + '.</span><br/>' + '<p><span>' + dl_no + ' &nbsp;&nbsp;</span><span>' + gst_no + '</span></p>' + '</div>' + '</td>' + '</tr>' + '</thead>';
        else if(printData.template == "noheader")
            thead = '<table class="report-container" style="width: 100%;">'+'<thead class="report-header">'+ '<tr>' + '<td style="height:130px;"></td>' + '</tr>' + '</thead>';
        else if(printData.template == "banner")
            thead = '<table class="report-container" style="width: 100%;">'+'<thead class="report-header">'+ '<tr>' + '<td><img alt="image" src = ' + printData.pharma_logo  + ' width="750px"></td>' + '</tr>' + '</thead>';
        else
            thead = '<table class="report-container" style="width: 100%;">'+'<thead class="report-header">'+ '<tr>' + '<td style="display: flex;justify-content: center;">'+ '<div style="width:100px;height:100px">' + '</div>'+ '<div style="position: relative;top: 20px;left: 10px;text-align: left;">' + '<p><b>'+ printData.pharmacy_name + '</b>,</p>' + '<span>' + pharmaAddr + '</span><br/>' + '<span>' + printData.location + ',</span>'+ '<span>' + printData.city + ',</span>' + '<span>' + printData.state + ',</span><span>' + printData.country + '.</span><br/>' + '<p><span>' + dl_no + ' &nbsp;&nbsp;</span><span>' + gst_no + '</span></p>' + '</div>' + '</td>' + '</tr>' + '</thead>';

        printContt +=  thead + '<tfoot class="report-footer"><tr><td style="height:100px;"></td></tr></tfoot>' + '<tbody class="report-content">' + '<tr>' + '<td class="report-content-cell">' + '<div class="main">' + '<table style="margin-left: 75px;margin-top: 20px;">' + '<td>' + '<p>Name : '+ printData.patient_name + '</p>' + '<p> Bill no : '+ printData.bill_no +'</p>' + '<p>Bill type : ' + printData.PurchaseType + '</p><p>Payment type : ' + printData.pay_type + '</p> </td> <td style="width: 250px;"></td><td>' + '<p>Doctor : '+ printData.doc_name + '</p>' +'<p>Bill date :' + (printData.order_date) +'</p> '+ '<p>Bill time :'+ printData.order_time + '</p>' + '</td></table><br />';
          // doctor bill list
        var drugContent = "";
        var drugList = "";
        if (printData.drugList.length != 0) {
          for (var j = 0; j < printData.drugList.length; j++) {
              var getOrder = printData.drugList[j];
              var si = j + 1
              drugList += '<tr><td style="border:1px solid black;">' + si + '</td><td style="border:1px solid black;">' + getOrder.drug_disp_name + '</td><td style="border:1px solid black;"><p style="float: right; margin-right: 10px;">' + getOrder.quantity + '</p></td><td style="border:1px solid black;"><p style="float: right; margin-right: 10px;">' + getOrder.batch_no + '</p></td><td style="border:1px solid black;"><p style="float: right; margin-right: 10px;">' + getOrder.expiry_date + '</p></td><td style="border:1px solid black;"><p style="float: right; margin-right: 10px;">' + getOrder.price + '</p></td><td style="border:1px solid black;"><p style="float: right; margin-right:10px;">' + getOrder.discount + '</p></td><td style="border:1px solid black;"><p style="float: right; margin-right: 10px;">'+ getOrder.cgst + '</p></td><td style="border:1px solid black;"><p style="float: right; margin-right: 10px;">'+ getOrder.sgst +'</p></td><td style="border:1px solid black;"><p style="float: right; margin-right: 10px;">'+ getOrder.amount +'</p></td></tr>';
          }
        }
        drugContent += printData.drugList.length != 0 ? '<table cellspacing="0" width="85%"style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;"><tr style="border:1px solid black;"><th style="border:1px solid black;">Si.No</th><th style="border:1px solid black;">Medicine name</th><th style="border:1px solid black;">Quantity</th><th style="border:1px solid black;">Batch No</th><th style="border:1px solid black;">Expiry date</th><th style="border:1px solid black;">Price</th><th style="border:1px solid black;"> Disc(%)</th><th style="border:1px solid black;">CGST(%)</th><th style="border:1px solid black;">SGST(%)</th><th style="border:1px solid black;"> Amount</th></tr><tbody>' + drugList + '</tbody> </table>' : " ";
        printContt += drugContent;

        var discount = "";
        if(printData.show_discount == true){
            discount = '<tr><td style="text-align: right; width: 80%;"><b>Discount</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"style="width: 15px; height: 15px;" /></td> <td><p style="float: right;margin-right: 10px;">' + printData.discount +'</p></td></tr>';
        }

        if(printData.order_type == false){
          if(printData.payments != undefined){
            var printpayment;
            for (let y = 0; y < printData.payments.length; y++) {
              printContt += printpayment = '<table style="margin-left:75px"><tr><td style="width: 150px !important;vertical-align: top;"><b>' + printData.payments[y].pay_desc + '</b></td><td>' + printData.payments[y].amount + '</td></tr></table>'
            }
            printContt += '<table width="85%" style="margin-left: 80px; margin-right: 50px;margin-top:5px"><tr><td style="text-align: right; width: 80%;"><b>Total</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td><p style="float: right;margin-right: 10px;">' + printData.total + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Paid</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">' + printData.paid + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Balance</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">' + printData.balance + '</p></td></tr></table></div></td></tr></tbody></table>';
          
          }else{
            var printpayment;
            for (let y = 0; y < printData.payments.length; y++) {
              printContt += printpayment = '<table style="margin-left:75px"><tr><td style="width: 150px !important;vertical-align: top;"><b>' + printData.payments[y].pay_desc + '</b></td><td>' + printData.payments[y].amount + '</td></tr></table>'
            }
            printContt += '<table width="85%" style="margin-left: 80px; margin-right: 50px;"><tr><td style="text-align: right; width: 80%;"><b>Total</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td><p style="float: right;margin-right: 10px;">' + printData.total + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Paid</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">' + printData.paid + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Balance</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">' + printData.balance + '</p></td></tr></table></div></td></tr></tbody></table>';
          }
            //printContt += '<br/><table width="85%" style="margin-left: 76px; margin-right: 50px;">'+ discount+'<tr><td style="text-align: right; width: 80%;"><b>Total</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td><p style="float: right;margin-right: 10px;">'+printData.total +'</p></td></tr></tbody></table></div>';
        }else{
          if(printData.payments != undefined){
            var printpayment;
            for (let y = 0; y < printData.payments.length; y++) {
              printContt += printpayment = '<table style="margin-left:70px"><tr><td style="width: 150px !important;vertical-align: top;"><b>' + printData.payments[y].pay_desc + '</b></td><td>' + printData.payments[y].amount + '</td></tr></table>'
            }
            printContt += '<table width="85%" style="margin-left: 80px; margin-right: 50px;margin-top:5px"><tr><td style="text-align: right; width: 80%;"><b>Total</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td><p style="float: right;margin-right: 10px;">' + printData.total + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Paid</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">' + printData.paid + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Balance</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">' + printData.balance + '</p></td></tr></table></div></td></tr></tbody></table>';
          }else{
            var printpayment;
            for (let y = 0; y < printData.payments.length; y++) {
              printContt += printpayment = '<table style="margin-left:40px"><tr><td style="width: 150px !important;vertical-align: top;"><b>' + printData.payments[y].pay_desc + '</b></td><td>' + printData.payments[y].amount + '</td></tr></table>'
            }
            printContt += '<table width="85%" style="margin-left: 80px; margin-right: 50px;"><tr><td style="text-align: right; width: 80%;"><b>Total</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td><p style="float: right;margin-right: 10px;">' + printData.total + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Paid</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">' + printData.paid + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Balance</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">' + printData.balance + '</p></td></tr></table></div></td></tr></tbody></table>';
          }

          //printContt += '<br/><table width="85%" style="margin-left: 76px; margin-right: 50px;">'+ discount+'<tr><td style="text-align: right; width: 80%;"><b>Total</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td><p style="float: right;margin-right: 10px;">'+printData.total +'</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Paid</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">'+printData.paid + '</p></td></tr><tr><td style="text-align: right; width: 80%;"><b>Balance</b><img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 15px; height: 15px;" /></td><td class="billamttext"><p style="float: right;margin-right: 10px;">'+printData.balance+'</p></td></tr></table></div></td></tr></tbody></table></div>';
        }

        printContents = printContt;
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <head>
            <title>Order</title>
            <link rel="stylesheet" media="screen" href="">
            <style>
            @page { size: auto;  margin: 0mm; }
            .table_class {
              background-color: rgb(108, 180, 248);
            }
            table.report-container {
              page-break-after:always;
          }
          thead.report-header {
              display:table-header-group;
          }
          tfoot.report-footer {
              display:table-footer-group;
          } 
          tfoot > tr > td.multiColumn {
            position: relative;
            z-index: 1;
            border:none !important;
            }
          p{
            margin:0;
          }
          table { page-break-inside:auto }
          tr{ page-break-inside:avoid; page-break-after:auto }
            
            </style>
          </head>
              <body onload="window.print();window.onfocus=function(){ window.close();}">
              ${printContents}
              </body>
            </html>`
        );
        popupWin.document.close();
    }
}