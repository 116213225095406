<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Edit bill</h5>
          </div>
          <div class="headerButtons">
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
            <a *ngIf="updateFlag" (click)="Bill_update()"><img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="margin-bottom: 15px;">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Bill number<br>
              <input class="ipcss  widthappt" matInput disabled="true" [(ngModel)]="billId" />
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Hospital name<br>
              <input class="ipcss  widthappt" matInput [(ngModel)]="hospitalName" />
            </mat-label>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Patient name<br>
              <input class="ipcss  widthappt" matInput [(ngModel)]="patientName" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Service type<br>
              <input type="text" class="ipcss inpat_width" [(ngModel)]="billType"
              (keyup)="getServiceType(billType)" required matInput/>
              <div class="auto_complete_text1">
                  <ul *ngFor="let charge of serviceType">
                      <li>
                          <a (click)="charge_change(charge)">{{charge}}</a>
                      </li>
                  </ul>
              </div>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <select class="ipcss " [(ngModel)]="payType" (change)="pay_change()" disableOptionCentering>
                <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insurFlag">
            <mat-label class="matlabel">Insurer<br>
              <select required class="ipcss " [(ngModel)]="insurerId" disableOptionCentering>
                <option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="transactionFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Quantity<br>
              <input type=number class="ipcss billing_text_align" matInput required (keyup)="amount_change()"
                [(ngModel)]="quantity" maxlength="3" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Amount<br>
              <input class="ipcss billing_text_align " matInput required (keyup)="amount_change()"
                [(ngModel)]="amount" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Concession (%)<br>
              <input class="ipcss  billing_text_align" matInput (keyup)="amount_change()"
                [(ngModel)]="concession" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">After concession
              <input class="ipcss billing_text_align " [(ngModel)]="amountAftCon" />
            </mat-label>
          </div>
          <div class="col-12 col-md-12 col-lg-12 col-xl-12 ">
            <mat-label class="addvalue matlabel add_btn">
              <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                (click)="Bill_add_click()" style="float: right;" />
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <table *ngIf="billAddList.length" class="table table-nowrap table-sm dataTable  bill_edit_table">
              <thead class="tableheading">
                <tr style="height: 23px;">
                  <!-- <th>Sno</th> -->
                  <th>Sno</th>
                  <th>Type</th>
                  <th [hidden]="paytypreq">Payment type</th>
                  <th class="delete">Fees</th>
                  <th>Quantity</th>
                  <th>Concession</th>
                  <th class="delete">Amount</th>
                  <th class="delete" style="width: 10px;">Delete</th>
                </tr>
              </thead>
              <tbody  *ngFor="let person of billAddList" >
                <tr *ngIf="person.status != '2'" class="tdvalues">
                  <td style="text-align: left;">{{person.sno}}</td>
                  <td style="text-align: left;">{{person.biill_type}}</td>
                  <td style="text-align: left;" [hidden]="paytypreq">{{person.pay_type}}</td>
                  <td style="text-align: right;">{{person.amount_txt}} </td>
                  <td style="text-align: center;">
                    <input type=number class="ipcss billing_text_align1" matInput required (keyup)="amount_change_edit(person)"
                    [(ngModel)]="person.quantity" maxlength="3" />
                  </td>
                  <td style="text-align: right;">{{person.concession}}</td>
                  <td class="billlistleft" style="text-align: right;">{{person.fee}}</td>
                  <td class="delete">
                    <a (click)="Delete_bill(person.biill_type,person.amount_txt,person)"> <img
                        src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" alt=""></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" [hidden]="!paytypreq">
              <div class="row">
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                    <select class="ipcss " [(ngModel)]="payType1" 
                      disableOptionCentering>
                      <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" >
                  <mat-label class="matlabel">Amount<br>
                    <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput required maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/next_button.svg" class="amt_button add_align"
                      (click)="addPaymentDetails()" />
                  </mat-label>
                </div>
              </div>

              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                      <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-0 col-sm-2  col-md-2 col-lg-2 col-xl-1"></div>
          <div class="col-0 col-sm-6  col-md-3 col-lg-3  col-xl-4">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Include GST &nbsp;&nbsp; </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST" (change)="include_gst_click(inGST)">
                </mat-checkbox>
              </div>
              <div *ngFor="let gsttax of gstDataList">
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                  </div>
                  <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{gsttax.Gst_amount}}</mat-label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="prevbalanceFlag">
                <mat-label class="matlabel  heightmsr">Previous balance </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="prevbalanceFlag">
                <input class="ipcss  heightmsr bill_txt" matInput text-right [(ngModel)]="prevbalance" maxlength="10"
                  disabled />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Total </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="finalAmount" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Discount</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt" matInput text-right (keyup)="addRound('minus')"
                  [(ngModel)]="discount" maxlength="10" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Advance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="advance_change()"
                  [(ngModel)]="advanceBill" maxlength="10" readonly />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Paid</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt" matInput text-right (keyup)="paid_change()"
                  [(ngModel)]="paidBill" maxlength="10" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr" >Balance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right
                  [(ngModel)]="remainingBalance" maxlength="10" />
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>