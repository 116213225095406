import { ReturnStatement } from "@angular/compiler";

export class Helper_Class {
    static loginfo: any;
    static profile_info: any;
    static location;
    static sga_data: any;
    static datevalue: any;
    static profilename: any;
    static diagnosis: any;
    //static date_selection:any;
    static isvalid: boolean;
    static hospitalinfo: any;
    static cpainloc_array: any;
    static cpaincar_array: any;
    static cpainrad_array: any;
    static cpainagrel_array: any;
    static cpainasympt_array: any;
    static cpaincause_array: any;
    static mucus_array: any;
    static btrig_array: any;
    static bonset_array: any;
    static palp_array: any;
    static physio_case_hist_id: any;
    static life_style_id: any;
    static casesheet_hist: any;
    static billpagetrue: any;
    static hspidfrmadm: any;
    static clientdata: any;
    static patientdata: any;
    static hospusers: any;
    static accessmodules: any;
    static hospdept: any;
    static frontdeskmodule: any;
    static frontdesksubmodule: any;
    static outsource:any;
    static parusertype:any;
    static conform:any;
    static oldcasesheet:any;
    static pharmabill:any;

    static setbillprintgen(value:any){
        this.pharmabill=null;
        this.pharmabill=value;
    }
    static getbillprintgen(){
        return this.pharmabill;
    }
    static setOldCasesheet(value:any){
        this.oldcasesheet=null;
        this.oldcasesheet=value;
    }
    static getOldCasesheet(){
        return this.oldcasesheet;
    }
    static setConcentForm(value:any){
        this.conform=null;
        this.conform=value;
    }
    static getConcentForm(){
        return this.conform;
    }
    
    static setPartialUser(value: any) {
        this.parusertype = null;
        this.parusertype = value;
    }
    static getPartialUser() {
        return this.parusertype;
    }
    
    static setOutsource(value: any) {
        this.outsource = null;
        this.outsource = value;
    }
    static getOutsource() {
        return this.outsource;
    }

    static setPatientList(value: any) {
        this.patientdata = null;
        this.patientdata = value;
    }
    static getPatientList() {
        return this.patientdata;
    }

    static setClientData(value: any) {
        this.clientdata = null;
        this.clientdata = value;
    }
    static getClientData() {
        return this.clientdata;
    }

    static sethspidfrmadm(value: any) {
        this.hspidfrmadm = null;
        this.hspidfrmadm = value;
    }
    static gethspidfrmadm() {
        return this.hspidfrmadm;
    }

    static setbillpagetrue(value: any) {
        this.billpagetrue = null;
        this.billpagetrue = value;
    }
    static getbillpagetrue() {
        return this.billpagetrue;
    }

    static setUserTypes(value: any) {
        this.hospusers = null;
        this.hospusers = value;
    }
    static getUserTypes() {
        return this.hospusers;
    }
    
    static setAccessModules(value: any) {
        this.accessmodules = null;
        this.accessmodules = value;
    }
    static getAccessModules() {
        return this.accessmodules;
    }
    
    static setDepartments(value: any) {
        this.hospdept = null;
        this.hospdept = value;
    }
    static getDepartments() {
        return this.hospdept
    }
    
    static setmodulelist(value: any) {
        this.frontdeskmodule = null;
        this.frontdeskmodule = value;

    }
    static getmodulelist() {
        return this.frontdeskmodule
    }
    
    static setsubmodulelist(value: any) {
        this.frontdesksubmodule = null;
        this.frontdesksubmodule = value;

    }
    static getsubmodulelist() {
        return this.frontdesksubmodule
    }
    static getInfo() {
        return this.loginfo;
    }
    static setInfo(value: any) {
        this.loginfo = null;
        this.loginfo = value;
    }
    static setProfile_info(value: any) {
        this.profile_info = null;
        this.profile_info = value;
    }
    static getProfile_info() {
        return this.profile_info;
    }
    static SetlocationInfo(value: any) {
        this.location = null;
        this.location = value;
    }
    static getlocationInfo() {
        return this.location;
    }
    static getSga() {
        return this.sga_data;
    }
    static setSga(value: any) {
        this.sga_data = null;
        this.sga_data = value;
    }
    static setdatevalue(value: any) {
        this.datevalue = null;
        this.datevalue = value;
    }
    static getdatevalue() {
        return this.datevalue;
    }

    static setProfilename(value: any) {
        this.profilename = null;
        this.profilename = value;
    }
    static getProfilename() {
        return this.profilename;
    }

    static setDiagProfilename(value: any) {
        this.diagnosis = null;
        this.diagnosis = value;
    }
    static getDiagProfilename() {
        return this.diagnosis;
    }

    static setHospitalInfo(value: any) {
        this.hospitalinfo = null;
        this.hospitalinfo = value;
    }
    static getHospitalInfo() {
        return this.hospitalinfo;
    }

    static Get_page_fields: any;
    static getHopitalfieldsRet() {
        return this.Get_page_fields;
    }
    static setHopitalfieldsRet(value: any) {
        this.Get_page_fields = null;
        this.Get_page_fields = value;
    }
    static setCasesheetPersonal(value: any) {
        this.casesheet_hist = null;
        this.casesheet_hist = value;

    }
    static getCasesheetPersonal() {
        return this.casesheet_hist;
    }
    static client_age: any;

    static getClntAge() {
        return this.client_age;
    }

    static setClntAge(value) {
        this.client_age = null;
        this.client_age = value;
    }

    static client_gender: any;
    static getClntGender() {
        return this.client_gender;
    }
    static setClntGender(value: any) {
        this.client_gender = null;
        this.client_gender = value;
    }
    // set / Get  retrival data

    static retrive_data: any;
    static getRet() {
        return this.retrive_data;
    }
    static setRet(value: any) {
        this.retrive_data = null;
        this.retrive_data = value;
    }
    static getCaseHistId() {
        return this.physio_case_hist_id;
    }
    static setCaseHistId(value: any) {
        this.physio_case_hist_id = null;
        this.physio_case_hist_id = value;
    }
    static getLifeStyleId() {
        return this.life_style_id;
    }
    static setLifeStyleId(value) {
        this.life_style_id = null;
        this.life_style_id = value;
    }
    static asg_data: any;
    static getAsg() {
        return this.asg_data;
    }
    static setAsg(value: any) {
        this.asg_data = null;
        this.asg_data = value;
    }
    static patient_info: any;
    static setpatient_information(value: any) {
        this.patient_info = null;
        this.patient_info = value;

    }
    static getpatient_information() {
        return this.patient_info;
    }

    static specializations;

    static setSpecializations(value: any) {
        this.specializations = null;
        this.specializations = value;
    }

    static getSpecializations() {
        return this.specializations;
    }
    static Qualification;
    static setQualification(value: any) {
        this.Qualification = null;
        this.Qualification = value;
    }

    static getQualification() {
        return this.Qualification;
    }

    static PatientAppointment;

    static setPatientAppointment(value: any) {
        this.PatientAppointment = null;
        this.PatientAppointment = value;
    }
    static getPatientAppointment() {
        return this.PatientAppointment;
    }
    static spl_id;
    static setspl_id(value: any) {
        this.spl_id = null;
        this.spl_id = value;
    }
    static getspl_id() {
        return this.spl_id;
    }
    static habitual;
    static sethabitual(value: any) {
        this.habitual = null;
        this.habitual = value;
    }
    static gethabitual() {
        return this.habitual;
    }
    static dietvalue
    static setDietplan(value: any) {
        this.dietvalue = null;
        this.dietvalue = value;

    }
    static getDietplan() {
        return this.dietvalue;
    }
    static familyinfo: any;
    static setDiabFam(value: any) {
        this.familyinfo = null;
        this.familyinfo = value;
    }
    static getDiabFam() {
        return this.familyinfo
    }
    static clinical: any;
    static setclinicalexam(value: any) {
        this.clinical = null;
        this.clinical = value;
    }
    static getclinicalexam() {
        return this.clinical;
    }

    static diab_trat: any;
    static setDiabTreat(value: any) {
        this.diab_trat = null;
        this.diab_trat = value;
    }

    static getDiabTreat() {

        return this.diab_trat;
    }
    static med_pres_id;

    static set_med_pres_id(value: any) {
        this.med_pres_id = null;
        this.med_pres_id = value;
    }

    static get_med_pres_id() {
        return this.med_pres_id;
    }

    static diag_pres_id;
    static set_diag_pres_id(value: any) {
        this.diag_pres_id = null;
        this.diag_pres_id = value;
    }
    static get_diag_pres_id() {
        return this.diag_pres_id;
    }

    static gynaRet;
    static setgynaRet(value: any) {
        this.gynaRet = null;
        this.gynaRet = value;
    }
    static getgynaRet() {
        return this.gynaRet;
    }

    static obestric;
    static setobestric(value: any) {
        this.obestric = null;
        this.obestric = value;
    }
    static getobestric() {
        return this.obestric;
    }

    setgynasurgical
    static gynasurgical;
    static setgynasurgical(value: any) {
        this.gynasurgical = null;
        this.gynasurgical = value;
    }
    static getgynasurgical() {
        return this.gynasurgical;
    }

    static DiabHist;
    static setDiabHist(value: any) {
        this.DiabHist = null;
        this.DiabHist = value;
    }
    static getDiabHist() {
        return this.DiabHist;
    }

    static gynaasg;
    static setgynaasg(value: any) {
        this.gynaasg = null;
        this.gynaasg = value;
    }
    static getgynaasg() {
        return this.gynaasg;
    }

    static diagoutcome;
    static setdiagoutcome(value: any) {
        this.diagoutcome = null;
        this.diagoutcome = value;
    }
    static getdiagoutcome() {
        return this.diagoutcome;
    }

    static clinicexam;
    static setclinicexam(value: any) {
        this.clinicexam = null;
        this.clinicexam = value;
    }
    static getclinicexam() {
        return this.clinicexam;
    }

    static gynapersonal;
    static setgynapersonal(value: any) {
        this.gynapersonal = null;
        this.gynapersonal = value;
    }
    static getgynapersonal() {
        return this.gynapersonal;
    }

    static PersonalHist;
    static setPersonalHist(value: any) {
        this.PersonalHist = null;
        this.PersonalHist = value;
    }
    static getPersonalHist() {
        return this.PersonalHist;
    }

    static Diet;
    static setDiet(value: any) {
        this.Diet = null;
        this.Diet = value;
    }
    static getDiet() {
        return this.Diet;
    }

    static DiabCompl;
    static setDiabCompl(value: any) {
        this.DiabCompl = null;
        this.DiabCompl = value;
    }
    static getDiabCompl() {
        return this.DiabCompl;
    }

    static DiabFoot;
    static setDiabFoot(value: any) {
        this.DiabFoot = null;
        this.DiabFoot = value;
    }
    static getDiabFoot() {
        return this.DiabFoot;
    }

    static PediaHist;
    static setPediaHist(value: any) {
        this.PediaHist = null;
        this.PediaHist = value;
    }
    static getPediaHist() {
        return this.PediaHist;
    }

    static pediaRet;
    static setpediaRet(value: any) {
        this.pediaRet = null;
        this.pediaRet = value;
    }
    static getpediaRet() {
        return this.pediaRet;
    }

    static Pediadelivery;
    static setPediadelivery(value: any) {
        this.Pediadelivery = null;
        this.Pediadelivery = value;
    }
    static getPediadelivery() {
        return this.Pediadelivery;
    }

    static Pediaheadcardio;
    static setPediaheadcardio(value: any) {
        this.Pediaheadcardio = null;
        this.Pediaheadcardio = value;
    }
    static getPediaheadcardio() {
        return this.Pediaheadcardio;
    }

    static pediaasg;
    static setpediaasg(value: any) {
        this.pediaasg = null;
        this.pediaasg = value;
    }
    static getpediaasg() {
        return this.pediaasg;
    }

    static pediadevelop;
    static setpediadevelop(value: any) {
        this.pediadevelop = null;
        this.pediadevelop = value;
    }
    static getpediadevelop() {
        return this.pediadevelop;
    }

    static pediaimmunization;
    static Setpediaimmunization(value: any) {
        this.pediaimmunization = null;
        this.pediaimmunization = value;
    }
    static getpediaimmunization() {
        return this.pediaimmunization;
    }

    static client_view_app_data;
    static Set_client_view_app_data(value: any) {
        this.client_view_app_data = null;
        this.client_view_app_data = value;
    }
    static Get_client_view_app_data() {
        return this.client_view_app_data;
    }

    static OtherSpl;
    static setOtherSpl(value: any) {
        this.OtherSpl = null;
        this.OtherSpl = value;
    }
    static getOtherSpl() {
        return this.OtherSpl;
    }

    static ClinicalHist;
    static setClinicalHist(value: any) {
        this.ClinicalHist = null;
        this.ClinicalHist = value;
    }
    static getClinicalHist() {
        return this.ClinicalHist;
    }

    static den_ret2;
    static Set_den_ret2(value: any) {
        this.den_ret2 = null;
        this.den_ret2 = value;
    }
    static Get_den_ret2() {
        return this.den_ret2;
    }

    static den_ret1;
    static Set_den_ret1(value: any) {
        this.den_ret1 = null;
        this.den_ret1 = value;
    }
    static Get_den_ret1() {
        return this.den_ret1;
    }

    static den_cli_exam;
    static Set_den_cli_exam(value: any) {
        this.den_cli_exam = null;
        this.den_cli_exam = value;
    }
    static Get_den_cli_exam() {
        return this.den_cli_exam;
    }

    // static send_dental_basic_data;
    // static Set_send_dental_basic_data(value:any){
    //     this.send_dental_basic_data=null;
    //     this.send_dental_basic_data=value;
    // }
    // static Get_send_dental_basic_data(){
    //     return this.send_dental_basic_data;
    // }

    static perio_chart_data;
    static Set_perio_chart_data(value: any) {
        this.perio_chart_data = null;
        this.perio_chart_data = value;
    }
    static Get_perio_chart_data() {
        return this.perio_chart_data;
    }

    static diabfoot;
    static setdiabfoot(value: any) {
        this.diabfoot = null;
        this.diabfoot = value;
    }
    static getdiabfoot() {
        return this.diabfoot;
    }

    static complication;
    static setcomplication(value: any) {
        this.complication = null;
        this.complication = value;
    }
    static getcomplication() {
        return this.complication;
    }

    static diab_reading_data;
    static set_diab_reading_data(value: any) {
        this.diab_reading_data = null;
        this.diab_reading_data = value;
    }
    static get_diab_reading_data() {
        return this.diab_reading_data;
    }

    static spl;
    static Set_den_spl(value: any) {
        this.spl = null;
        this.spl = value;
    }
    static get_den_spl() {
        return this.spl;
    }

    // static dental_reff;
    // static Set_dental_reff_data(value:any){
    //     this.dental_reff=null;
    //     this.dental_reff=value;
    // }
    // static Get_dental_reff_data(){ 
    //     return this.dental_reff;
    // }

    // static genden_spl;
    // static get_dent_spl(){
    //     return this.genden_spl;
    // }
    // static set_dent_spl(value:any){
    //     this.genden_spl=null;
    //     this.genden_spl=value;
    // }

    // static per_hist:any;

    // static Set_den_per_hist(value:any){
    //     this.per_hist=null;
    //     this.per_hist=value;
    // }
    // static Get_den_per_hist(){
    //     return this.per_hist;
    // }

    // static den_treat_plan;
    // static Set_den_treat_plan(value:any){
    //     this.den_treat_plan=null;
    //     this.den_treat_plan=value;
    // }
    // static Get_den_treat_plan(){
    //     return   this.den_treat_plan;
    // }

    static material_data: any;
    static Set_material_data(value: any) {
        this.material_data = null;
        this.material_data = value;
    }
    static Get_material_data() {
        return this.material_data;
    }

    static den_spl_natal;
    static Set_den_spl_natal(value: any) {
        this.den_spl_natal = null;
        this.den_spl_natal = value;
    }
    static Get_den_spl_natal() {
        return this.den_spl_natal;
    }

    static casesumret;
    static setcasesumret(value: any) {
        this.casesumret = null;
        this.casesumret = value;
    }
    static getcasesumret() {
        return this.casesumret;
    }

    // static dental_spl;
    // static Set_den_spl_dental(value:any){
    //     this.dental_spl = null;
    //     this.dental_spl= value;
    // }
    // static Get_den_spl_dental() {
    //     return this.den_spl_natal;
    // }
    static den_spl_birth;
    static Set_den_spl_birth(value: any) {
        this.den_spl_birth = null;
        this.den_spl_birth = value;
    }
    static Get_den_spl_birth() {
        return this.den_spl_birth;
    }
    // static den_spl_general;
    // static Set_den_spl_general(value:any){
    //     this.den_spl_general = null;
    //     this.den_spl_general = value;
    // }
    // static Get_den_spl_general() {
    //     return this.den_spl_general;
    // }

    static den_spl_gums;
    static Set_den_spl_gums(value: any) {
        this.den_spl_gums = null;
        this.den_spl_gums = value;
    }
    static Get_den_spl_gums() {
        return this.den_spl_gums;
    }
    static billlist;
    static setbilllist(value: any) {
        this.billlist = null;
        this.billlist = value;
    }
    static getbilllist() {
        return this.billlist;
    }

    static PageFlow;
    static setPageFlow(value: any) {
        this.PageFlow = null;
        this.PageFlow = value;
    }
    static getPageFlow() {
        return this.PageFlow;
    }

    static Key;
    static setKey(value: any) {
        this.Key = null;
        this.Key = value;
    }
    static getKey() {
        return this.Key;
    }

    static IV;
    static setIV(value: any) {
        this.IV = null;
        this.IV = value;
    }
    static getIV() {
        return this.IV;
    }

    static app_flow;
    static setapp_flow(value: any) {
        this.app_flow = null;
        this.app_flow = value;
    }
    static getapp_flow() {
        return this.app_flow;
    }

    static Opnid;
    static setOpnid(value: any) {
        this.Opnid = null;
        this.Opnid = value;
    }
    static getOpnid() {
        return this.Opnid;
    }

    static OpnRet;
    static setOpnRet(value: any) {
        this.OpnRet = null;
        this.OpnRet = value;
    }
    static getOpnRet() {
        return this.OpnRet;
    }
    static regsitration_profile_array;
    static set_regsitration_profile_array(value: any) {
        this.regsitration_profile_array = null;
        this.regsitration_profile_array = value;
    }
    static get_regsitration_profile_array() {
        return this.regsitration_profile_array;
    }
    static Doc_reg_dob;
    static set_Doc_reg_dob(value: any) {
        this.OpnRet = null;
        this.OpnRet = value;
    }
    static get_Doc_reg_dob() {
        return this.OpnRet;
    }

    static registration_qual_array;
    static setregistration_qual_array(value: any) {
        this.registration_qual_array = null;
        this.registration_qual_array = value;
    }
    static getregistration_qual_array() {
        return this.registration_qual_array;
    }

    static reg_work_array;
    static set_reg_work_array(value: any) {
        this.reg_work_array = null;
        this.reg_work_array = value;
    }
    static get_reg_work_array() {
        return this.reg_work_array;
    }

    static reg_work_add_array;
    static set_reg_work_add_array(value: any) {
        this.reg_work_add_array = null;
        this.reg_work_add_array = value;
    }
    static get_reg_work_add_array() {
        return this.reg_work_add_array;
    }

    //client
    static Modules;
    static setModules(value: any) {
        this.Modules = null;
        this.Modules = value;
    }
    static getModules() {
        return this.Modules;
    }

    static ClientInfo;
    static setClientInfo(value: any) {
        this.ClientInfo = null;
        this.ClientInfo = value;
    }
    static getClientInfo() {
        return this.ClientInfo;
    }

    static Homecare;
    static setHomecare(value: any) {
        this.Homecare = null;
        this.Homecare = value;
    }
    static getHomecare() {
        return this.Homecare;
    }
    static relation;
    static setrelation(value: any) {
        this.relation = null;
        this.relation = value;
    }
    static getrelation() {
        return this.relation;
    }
    static clientbasicinfo;
    static setclientbasicinfo(value: any) {
        this.clientbasicinfo = null;
        this.clientbasicinfo = value;
    }
    static getclientbasicinfo() {
        return this.clientbasicinfo;
    }

    static pharmorder;
    static setpharmacyorderdetails(value: any) {
        this.pharmorder = null;
        this.pharmorder = value;
    }
    static getpharmacyorderdetails() {
        return this.pharmorder;
    }
    static pharmacydetila;
    static setprescriptiondetails(value: any) {
        this.pharmacydetila = null;
        this.pharmacydetila = value;
    }
    static getprescriptiondetails() {
        return this.pharmacydetila;
    }
    static reportlistrw;
    static setreportlist(value: any) {
        this.reportlistrw = null;
        this.reportlistrw = value;
    }
    static getreportlist() {
        return this.reportlistrw;
    }

    // static pharmacy1
    // static setpharmacylist(value:any){
    //     this.pharmacy1= null;
    //     this.pharmacy1 = value;
    // }
    // static getpharmacylist(){
    //     return this.pharmacy1;
    // }

    static pharmacyvalue;
    static setquantitychangevalue(value: any) {
        this.pharmacyvalue = null;
        this.pharmacyvalue = value;
    }
    static getquantitychangevalue() {
        return this.pharmacyvalue;
    }

    static order;
    static setorderconfirm(value: any) {
        this.order = null;
        this.order = value;
    }
    static getorderconfirm() {
        return this.order;
    }
    static currentdetails;
    static setcurrentdetails(value: any) {
        this.currentdetails = null;
        this.currentdetails = value;
    }
    static getcurrentdetails() {
        return this.currentdetails;
    }
    static homedeliveryvalue;
    static sethomedelivery(value: any) {
        this.homedeliveryvalue = null;
        this.homedeliveryvalue = value;

    }
    static gethomedelivery() { return this.homedeliveryvalue; }

    static tracker1;
    static settablettracker1(value: any) {
        this.tracker1 = null;
        this.tracker1 = value;
    }
    static gettablettracker1() {
        return this.tracker1;
    }

    static storedetails;
    static setstorepickup(value: any) {
        this.storedetails = null;
        this.storedetails = value;
    }
    static getstorepickup() {
        return this.storedetails;
    }

    static relations;
    static setrelationlist(value: any) {
        this.relations = null;
        this.relations = value;
    }
    static getrelationlist() {
        return this.relations;
    }

    static finddonor;
    static setfinddonors(value: any) {
        this.finddonor = null;
        this.finddonor = value;
    }
    static getfinddonors() {
        return this.finddonor;
    }

    static order1;
    static setdetails(value: any) {
        this.order1 = null;
        this.order1 = value;
    }
    static getdetails() {
        return this.order1;
    }
    static opinioncheck;
    static setopinionchecked(value: any) {
        this.opinioncheck = null;
        this.opinioncheck = value;
    }
    static getopinionchecked() {
        return this.opinioncheck;
    }
    static retrivaldata;
    static setrelationlistdata(value: any) {
        this.retrivaldata = null;
        this.retrivaldata = value;
    }
    static getrelationlistdata() {
        return this.retrivaldata;

    }
    static opinionlist;
    static setopnionlistarray(value: any) {
        this.opinionlist = null;
        this.opinionlist = value;
    }
    static getopinionlistarray() {
        return this.opinionlist
    }
    static opinion;
    static setopnionlist(value: any) {
        this.opinion = null;
        this.opinion = value;
    }
    static getopinionlist() {
        return this.opinion
    }
    static setDocSearch1;
    static setDocSearch(value: any) {
        this.setDocSearch1 = null;
        this.setDocSearch1 = value;
    }
    static getDocSearch() {
        return this.setDocSearch1
    }

    static tablettracker;
    static settablettracker(value: any) {
        this.tablettracker = null;
        this.tablettracker = value;

    }
    static gettablettracker() {
        return this.tablettracker;
    }

    static setappoint_client_data1;
    static setappoint_client_data(value: any) {
        this.setappoint_client_data1 = null;
        this.setappoint_client_data1 = value
    }
    static getappoint_client_data() {
        return this.setappoint_client_data1
    }
    static setgeneral1;
    static setgeneral(value: any) {
        this.setgeneral1 = null;
        this.setgeneral1 = value;

    }
    static getgeneral() {
        return this.setgeneral1;
    }

    static secopndoctorlist;
    static setsplnextdoclistpage(value: any) {
        this.secopndoctorlist = null;
        this.secopndoctorlist = value;
    }
    static getsplnextdoclistpage() {
        return this.secopndoctorlist;
    }
    static basicdetails;
    static setbasicclientappcreate(value: any) {
        this.basicdetails = null;
        this.basicdetails = value;
    }
    static getbasicclientappcreate() {
        return this.basicdetails;
    }

    static package_data;
    static set_package_data(value: any) {
        this.package_data = null;
        this.package_data = value;

    }
    static get_package_data() {
        return this.package_data;
    }

    static pharmaInfo;
    static setpharmaInfo(value: any) {
        this.pharmaInfo = null;
        this.pharmaInfo = value;

    }
    static getpharmaInfo() {
        return this.pharmaInfo;
    }

    static work_array;
    static set_work_array(value: any) {
        this.work_array = null;
        this.work_array = value;

    }
    static get_work_array() {
        return this.work_array;
    }
    static work_add_array;
    static set_work_add_array(value: any) {
        this.work_add_array = null;
        this.work_add_array = value;

    }
    static get_work_add_array() {
        return this.work_add_array;
    }

    static profile_array;
    static setprofile_array(value: any) {
        this.profile_array = null;
        this.profile_array = value;

    }
    static getprofile_array() {
        return this.profile_array;
    }

    static inventory_upload;
    static set_inventory_upload(value: any) {
        this.inventory_upload = null;
        this.inventory_upload = value;

    }
    static get_inventory_upload() {
        return this.inventory_upload;
    }

    static order_ids;
    static setpharmacyorderids(value: any) {
        this.order_ids = null;
        this.order_ids = value;
    }
    static getpharmacyorderids() {
        return this.order_ids;
    }


    static orderview;
    static setorderview(value) {
        this.orderview = null;
        this.orderview = value;
    }
    static getorderview() {
        return this.orderview;
    }
    static pharmacy_current;
    static setcurrentinformation_pharrmacy(value) {
        this.pharmacy_current = null;
        this.pharmacy_current = value;
    }
    static getcurrentinformation_pharrmacy() {
        return this.pharmacy_current
    }
    static pharmacyvalues;
    static setpastinformationpharamcy(value) {
        this.pharmacyvalues = null;
        this.pharmacyvalues = value;
    }
    static getpastinformationpharamcy() {
        return this.pharmacyvalues
    }

    static frontdeskspl_name;
    static setspecalization_frontdesk(value: any) {
        this.frontdeskspl_name = null;
        this.frontdeskspl_name = value
    }
    static getspecalization_frontdesk() {
        return this.frontdeskspl_name
    }
    static viewpreceiptionlist;
    static setreceptviewprecription(value: any) {
        this.viewpreceiptionlist = null;
        this.viewpreceiptionlist = value;
    }
    static getreceptviewprecription() {
        return this.viewpreceiptionlist
    }
    static receptcount;
    static setClientCount(value: any) {
        this.receptcount = null;
        this.receptcount = value;
    }
    static getClientCount() {
        return this.receptcount
    }
    static data;
    static setdoctor(value: any) {
        this.data = null;
        this.data = value;
    }
    static getdoctor() {
        return this.data
    }
    static frontdeskinfo;
    static setfrontdeskinfo(value: any) {
        this.frontdeskinfo = null;
        this.frontdeskinfo = value;
    } static getfrontdeskinfo() {
        return this.frontdeskinfo
    }

    static temperature;
    static setTemparature(value: any) {
        this.frontdeskinfo = null;
        this.frontdeskinfo = value;
    }
    static getTemparature() {
        return this.temperature
    }

    static symptoms;
    static setsymptoms(value: any) {
        this.symptoms = null;
        this.symptoms = value;
    }
    static getsymptoms() {
        return this.symptoms;
    }


    static diagnosisappview;
    static setdiagnosisappview(value: any) {
        this.diagnosisappview = null;
        this.diagnosisappview = value;
    }
    static getdiagnosisappview() {
        return this.diagnosisappview
    }

    static diagtest;
    static setdiagnosistest(value: any) {
        this.diagtest = null;
        this.diagtest = value;
    }
    static getdiagnosistest() {
        return this.diagtest
    }

    static diagbilldetailview;
    static setbilldetailview(value: any) {
        this.diagbilldetailview = null;
        this.diagbilldetailview = value
    }
    static getbilldetailview() {
        return this.diagbilldetailview
    }

    static setnephroreadingvalues;
    static setnephroreading(value: any) {
        this.setnephroreadingvalues = null;
        this.setnephroreadingvalues = value
    }
    static getnephroreading() {
        return this.setnephroreadingvalues
    }

    static receptionistinfo;
    static setreceptionistcreatepage(value: any) {
        this.receptionistinfo = null;
        this.receptionistinfo = value
    }
    static getreceptionistcreatepage() {
        return this.receptionistinfo
    }

    static clientidinfo
    static setClientId(value: any) {
        this.clientidinfo = null;
        this.clientidinfo = value
    }
    static getClientId() {
        return this.clientidinfo
    }

    static hospital1
    static setHospitalId(value: any) {
        this.hospital1 = null;
        this.hospital1 = value;
    }
    static getHospitalId() {
        return this.hospital1;
    }

    static receptbilldetail;
    static setreceptbilldetailview(value: any) {
        this.receptbilldetail = null;
        this.receptbilldetail = value;
    }
    static getreceptbilldetailview() {
        return this.receptbilldetail
    }

    static receptbilleditinfo;
    static setreceptbilledit(value: any) {
        this.receptbilleditinfo = null;
        this.receptbilleditinfo = value;
    }
    static getreceptbilledit() {
        return this.receptbilleditinfo
    }

    static hospital;
    static setHospital(value: any) {
        this.hospital = null;
        this.hospital = value;
    }
    static getHospital() {
        return this.hospital
    }

    static ipaddr1;
    static setIPAddress(value: any) {
        this.ipaddr1 = null;
        this.ipaddr1 = value;
    }
    static getIPAddress() {
        return this.ipaddr1;
    }

    static getAdmin;
    static setdiagnosisadmin(value: any) {
        this.getAdmin = null;
        this.getAdmin = value;
    }
    static getdiagnosisadmin() {
        return this.getAdmin
    }
    static setprofile_qual;
    static setprofile_qual_array(value: any) {
        this.setprofile_qual = null;
        this.setprofile_qual = value;
    }
    static getprofile_qual_array() {
        return this.setprofile_qual
    }

    //setUserType
    static UserType;
    static setUserType(value: any) {
        this.UserType = null;
        this.UserType = value;
    }
    static getUserType() {
        return this.UserType
    }


    //Client helpe class
    static hosptial_details;
    static setclient_hospitalDetails(value: any) {
        this.hosptial_details = null;
        this.hosptial_details = value;
    }

    static getclient_hospitalDetails() {
        return this.hosptial_details
    }

    //Diagnosis user set 
    static diag_user;
    static setdiag_user_type(value: any) {
        this.diag_user = null;
        this.diag_user = value;
    }

    static getdiag_user_type() {
        return this.diag_user;
    }

    //setEntDiagnosis
    static EntDiagnosis;
    static getEntDiagnosis() {
        return this.EntDiagnosis;
    }
    static setEntDiagnosis(value) {
        this.EntDiagnosis = null;
        this.EntDiagnosis = value;
    }
    //setEntClinical
    static EntClinical;
    static getEntClinical() {
        return this.EntClinical;
    }
    static setEntClinical(value) {
        this.EntClinical = null;
        this.EntClinical = value;
    }

    //setTreatmentList
    static TreatmentList;
    static getTreatmentList() {
        return this.TreatmentList;
    }
    static setTreatmentList(value) {
        this.TreatmentList = null;
        this.TreatmentList = value;
    }

    //secOpn
    static secOpn;
    static getsecOpn() {
        return this.secOpn;
    }
    static setsecOpn(value) {
        this.secOpn = null;
        this.secOpn = value;
    }

    // /setSurgicalHist
    static SurgicalHist;
    static getSurgicalHist() {
        return this.SurgicalHist;
    }
    static setSurgicalHist(value) {
        this.SurgicalHist = null;
        this.SurgicalHist = value;
    }
    // front desk print flag 
    static printFlag;
    static getPrintFlag() {
        return this.printFlag;
    }
    static setPrintFlag(value) {
        this.printFlag = null;
        this.printFlag = value;
    }

    //setSearchType
    static SearchType;
    static getSearchType() {
        return this.SearchType;
    }
    static setSearchType(value) {
        this.SearchType = null;
        this.SearchType = value;
    }

    //setDoctorProvID
    static DoctorProvID;
    static getDoctorProvID() {
        return this.DoctorProvID;
    }
    static setDoctorProvID(value) {
        this.DoctorProvID = null;
        this.DoctorProvID = value;
    }

    //setfrontDesk
    static frontdeskFlow;
    static getInpatientFlow() {
        return this.frontdeskFlow;
    }
    static setInpatientFlow(value) {
        this.frontdeskFlow = null;
        this.frontdeskFlow = value;
    }
    //Front desk inpatient create
    static frontdeskBilling;
    static getInpatientBillCreate() {
        return this.frontdeskBilling;
    }
    static setInpatientBillCreate(value) {
        this.frontdeskBilling = null;
        this.frontdeskBilling = value;
    }
    // inpatient consaledted bill edit in front desk
    static frontdeskBillingEdit;
    static getInpatientBillEdit() {
        return this.frontdeskBillingEdit;
    }
    static setInpatientBillEdit(value) {
        this.frontdeskBillingEdit = null;
        this.frontdeskBillingEdit = value;
    }

    //setConsultant
    static Consultant;
    static getConsultant() {
        return this.Consultant;
    }
    static setConsultant(value) {
        this.Consultant = null;
        this.Consultant = value;
    }
    //setIsAdmin
    static IsAdmin;
    static getIsAdmin() {
        return this.IsAdmin;
    }
    static setIsAdmin(value) {
        this.IsAdmin = null;
        this.IsAdmin = value;
    }
    // admin side repotes type
    static reportType;
    static getReportType() {
        return this.reportType;
    }
    static setReportType(value) {
        this.reportType = null;
        this.reportType = value;
    }

    // get billing list flow for diet 
    static BillingFlow;
    static getBillingFlow() {
        return this.BillingFlow;
    }
    static setBillingFlow(value) {
        this.BillingFlow = null;
        this.BillingFlow = value;
    }
    // get setDiagReadingList in diet module
    static DiagReadingList;
    static getDiagReadingList() {
        return this.DiagReadingList;
    }
    static setDiagReadingList(value) {
        this.DiagReadingList = null;
        this.DiagReadingList = value;
    }

    // get setDiagReadingList in diet module
    static DiabReadingsLifeStyle;
    static getDiabReadingsLifeStyle() {
        return this.DiabReadingsLifeStyle;
    }
    static setDiabReadingsLifeStyle(value) {
        this.DiabReadingsLifeStyle = null;
        this.DiabReadingsLifeStyle = value;
    }


    // get setDiagReadingList in diet module
    static adminBillingFlow;
    static getadminBillingFlow() {
        return this.adminBillingFlow;
    }
    static setadminBillingFlow(value) {
        this.adminBillingFlow = null;
        this.adminBillingFlow = value;
    }
    // set view bill
    static BillView;
    static getBillView() {
        return this.BillView;
    }
    static setBillView(value) {
        this.BillView = null;
        this.BillView = value;
    }
    // set create bill
    static BillCreate;
    static getBillCreate() {
        return this.BillCreate;
    }
    static setBillCreate(value) {
        this.BillCreate = null;
        this.BillCreate = value;
    }
    // set create bill
    static BillEdit;
    static getBillEdit() {
        return this.BillEdit;
    }
    static setBillEdit(value) {
        this.BillEdit = null;
        this.BillEdit = value;
    }
    // set create print
    static BillPrint;
    static getBillPrint() {
        return this.BillPrint;
    }
    static setBillPrint(value) {
        this.BillPrint = null;
        this.BillPrint = value;
    }
    // set create print
    static Package;
    static GetPackage() {
        return this.Package;
    }
    static setPackage(value) {
        this.Package = null;
        this.Package = value;
    }
    // set create print
    static LoginType;
    static getLoginType() {
        return this.LoginType;
    }
    static setLoginType(value) {
        this.LoginType = null;
        this.LoginType = value;
    }
    // set create print
    static CreatePackageFlow;
    static getCreatePackageFlow() {
        return this.CreatePackageFlow;
    }
    static setCreatePackageFlow(value) {
        this.CreatePackageFlow = null;
        this.CreatePackageFlow = value;
    }

    // set Recepies Edit
    static RecepiesEdit;
    static getRecepiesEdit() {
        return this.RecepiesEdit;
    }
    static setRecepiesEdit(value) {
        this.RecepiesEdit = null;
        this.RecepiesEdit = value;
    }

    // set bill delete
    static billDelete;
    static getBillDelete() {
        return this.billDelete;
    }
    static setBillDelete(value) {
        this.billDelete = null;
        this.billDelete = value;
    }
}