import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { AdvanceCreateComponent } from './advance-create.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


@NgModule({
    imports:[MatDialogModule,CommonModule,MatInputModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule,MatAutocompleteModule],
    declarations:[AdvanceCreateComponent],
    exports:[AdvanceCreateComponent]
})
export class AdvanceCreateModule{   
   
}