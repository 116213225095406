import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $:any;
@Component({
  selector: 'app-billing-charges',
  templateUrl: './billing-charges.component.html',
  styleUrls: ['./billing-charges.component.scss']
})
export class BillingChargesComponent implements OnInit {
  public chargeId: string;
  public chargeCategory: string;
  public chargeDesc: string;
  public chargeRate: string;
  public chargeList:any= [];
  public hospId;

  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  public messageservice:MenuViewService,public dialog:MatDialog) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.getChargeTypes();
    this.chargeCategory = "All";
  }

  save(){
    var flag = false;
    var chargeid;
    chargeid = this.chargeId == undefined ? "0" : this.chargeId;
    if(this.chargeList.length !=0){
      for(var i=0;i<this.chargeList.length;i++){
        if(this.chargeList[i].charge_desc.toUpperCase() == this.chargeDesc.toUpperCase()){
          this.toastr.error("Charge type already exists")
          flag = true;
        }
      }
    } 

    if(flag == false) {
      if(this.chargeDesc != "" && this.chargeDesc != undefined && this.chargeRate != "" && this.chargeDesc != this.chargeRate){
        var sendData = {
          hptl_clinic_id: this.hospId,
          charge_cat: this.chargeCategory,
          charge_desc: this.chargeDesc,
          amount: this.chargeRate,
          bill_charge_id: chargeid,
          country: "IN",
        }
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'bill/scharge/',sendData,
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              
              if(obj != undefined && obj.key == "1"){
                this.toastr.success(Message_data.chargesSavedSuccess);
                this.getChargeTypes();
                this.chargeId = undefined;
                this.chargeCategory = "All";
                this.chargeDesc = "";
                this.chargeRate = "";
              } else{
                this.toastr.error(Message_data.unableToAdvPaySave);
              }
            });
      }else{
        this.toastr.error(Message_data.mandatory);
      }
    }
  }

  getChargeTypes(){
    this.chargeList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gchgtypes/',
      {
        hptl_clinic_id: this.hospId,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          for (var i = 0; i < obj.charges.length; i++) {
            this.chargeList.push({
              charge_id: obj.charges[i].charge_id,
              charge_cat: obj.charges[i].charge_cat,
              charge_desc: obj.charges[i].charge_desc,
              charge_rate: obj.charges[i].amount,
            });
          }
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  editCharge(chargeid) {//edit charges
    for (var i = 0; i < this.chargeList.length; i++) {
      if (this.chargeList[i].charge_id == chargeid) {
        this.chargeId=this.chargeList[i].charge_id;
        this.chargeCategory=this.chargeList[i].charge_cat;
        this.chargeDesc=this.chargeList[i].charge_desc;
        this.chargeRate=this.chargeList[i].charge_rate;
        this.chargeList.splice(i,1);
      }
    }
  }

  deleteCharge(id){
    this.chargeList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/dchg/',
      {
        provider : Helper_Class.getInfo().user_id,
        hptl_clinic_id: this.hospId,
        charge_id : id
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.success("Charge type deleted successfully")
          this.getChargeTypes()
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
}
