<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
  <div class="row">
    <div class="col-12">
      <div class="row" style="margin-top: 15px;">
        <div class="col-1" style="width: fit-content;">
          <mat-label class="matlabel" style="position: relative;top: 6px;">Date : </mat-label>
        </div>
        <div class="col-8" style="padding-left: 0;">
          <input type="date" class="ipcss_date widthappt" id="appt_date" (change)="Date_click(appointmentDate)"
            [(ngModel)]="appointmentDate" max="{{currentDatetime}}" #matInput style="width: 140px;">
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Appointment list</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel()" width="85px"
                style="margin-right: 9px;" />
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <!-- spinnner element is 
                        defined with id loading -->
          <!-- *ngIf="appointment_list.length == 0" *ngIf="loading_flag"-->
          <div>
            <app-loader></app-loader>
          </div>
          <!-- <p class="nodata" *ngIf="nodata_flag">No appointment(s) found</p> -->
          <div *ngIf="appointmentArray.length == 0" class="nodata">No appointments(s) found</div>
          <table *ngIf="appointmentArray.length != 0" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Token / Time</th>
                <th>Session</th>
                <th>Status</th>
                <th style="width: 5%;">
                  <mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1"
                    (change)="checkUncheckAll()"></mdb-checkbox>
                </th>
              </tr>
              <!--  [(ngModel)]="masterSelected"  -->
            </thead>
            <tbody>
              <tr *ngFor="let list of appointmentArray; let i = index"
                (click)='Doctor_View(list.doc_app_id,list.client_reg_id,list.relation_id,list.sub_rel,list.spl,list.rel_count,list.rel_stat,list.rel_city,list.rel_loc,list.loc_age,list.gender_data,list.dob_data,list.patient_name,list.medicare_name,list.hospital_name,list.rel_loc_desc,list.address,list.state_desc,list.city_desc,list.country_desc,list.rel_zipcode,list.hptl_clinic_id,list.app_data,list.spl_id)'>
                <td><img src="{{list.profile_image}}" onerror="this.src='./././assets/img/default.jpg'" height="40px"
                    width="40px" class="img-fluid z-depth-1 rounded-circle mr-1 nurseProfile"></td>
                <td style="font-size: 12px;text-align: left;">{{ list.patient_name }}</td>
                <td style="font-size: 12px;">{{ list.tot_app_count }}</td>
                <td style="font-size: 12px;">{{ list.session }}</td>
                <td style="font-size: 12px;">
                  <div style="position: relative;top: 9px;">
                    <img *ngIf="list.status == 'Completed'" width="30px" height="auto"
                      src="../../../assets/ui_icons/Completed_icon.svg" />
                    <img *ngIf="list.status == 'Cancelled'" width="30px" height="auto"
                      src="../../../assets/ui_icons/cancel_icon.svg" />
                    <img *ngIf="list.status == 'Not visited'" width="30px" height="auto"
                      src="../../../assets/ui_icons/not_visited_icon.svg" />
                    <img *ngIf="list.status == 'Confirmed'" width="30px" height="auto"
                      src="../../../assets/ui_icons/confirmed_icon.svg" />
                    <img *ngIf="list.status == 'Open'" width="30px" height="32px"
                      src="../../../assets/ui_icons/opened_icon.svg" />
                    <img *ngIf="list.status == 'Rejected'" width="30px" height="32px"
                      src="../../../assets/ui_icons/opened_icon.svg" />
                    <img *ngIf="list.status == 'Awaiting for rating'" width="30px" height="32px"
                      src="../../../assets/ui_icons/opened_icon.svg" />
                    <p>{{list.status}}</p>
                  </div>
                </td>
                <td style="font-size: 12px;width: 5%;">
                  <mdb-checkbox [default]="true" [(ngModel)]="list.isSelected" name="list_name" value="{{list.id}}"
                    (change)="isAllSelected(list.doc_app_id)"
                    [disabled]="list.status != 'Open' && list.status != 'Confirmed'"></mdb-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <br><br><br>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>