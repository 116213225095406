import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharma-sales-report',
  templateUrl: './pharma-sales-report.component.html',
  styleUrls: ['./pharma-sales-report.component.css']
})
export class PharmaSalesReportComponent implements OnInit {
  @ViewChild('printpharsalesRepo') printpharsalesRepo:ElementRef;
  @ViewChild('totalamount') totalamount: ElementRef;
  @ViewChild('cashamount') cashamount: ElementRef;
  @ViewChild('cardamount') cardamount: ElementRef;
  @ViewChild('insureamount') insureamount: ElementRef;
  @ViewChild('upiamount') upiamount: ElementRef;
  public pharmacistID: string;
  public reportType: string;
  public fromDate;
  public toDate;
  public currDate = null;
  public salesArray = [];
  public noData: boolean;
  public hasData: boolean;
  tmr = new Date();
  public userInfo;
  public currentDate;
  public payType: string;
  public totalAmount = "0.00";
  public upiAmount = "0.00";
  public cashAmount = "0.00"
  public cardAmount="0.00"
  public insureAmount="0.00"
  public title = 'Sales report';
  public header = [];
  public payTypeListArrray: any = [];
  public forcoverdiv:boolean= true;
  public backbutton:boolean=false;
  public amountcard:boolean=false;
  private userName;
  public ishide:boolean=false;
  public userList;
  public userFlag=false;
  public userId="All";
  public PatientType="All";

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.noData = false;
    this.hasData = true;
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userName;
     
    if (this.userInfo.middle_name != undefined)
    this.userName = this.userInfo.first_name + " " + this.userInfo.middle_name + " " + this.userInfo.last_name;
  else {
    if (this.userInfo.last_name != undefined)
      this.userName = this.userInfo.first_name + " " + this.userInfo.last_name;
    else
      this.userName = this.userInfo.first_name;
  }
    this.pharmacistID = Helper_Class.getIsAdmin() != undefined ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_id;
    console.log("type of user",this.userInfo.user_type)
    if(this.userInfo.user_type == "Admin"){
      this.userFlag=true;
    }
    this.getDate();
    this.getusername()
    this.reportType = "All";
    this.header = ['Bill no', 'Buyer', 'Create By', 'Purchase type', 'Bill date', 'Amount', "Payment status"];
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.getPaymentType();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getusername() {
    console.log("username in")
    var senddata={
      hptl_clinic_id:this.pharmacistID,
      type:'pharma'
    }
  //   if(this.reportType="HBR"){
  //   var senddata = {
  //     hptl_clinic_id: this.hptlClinicId,
  //     type:'hospital'
  //   }
  // }
  // else if(this.reportType="DBR")
  // {
  //   var senddata = {
  //     hptl_clinic_id: this.hptlClinicId,
  //     type:'diagnosis'
  //   }
  // }else{
  //   console.log("usertypenone")
  // }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'misr/grbh', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("user name--", obj)
          if (obj.reps != null) {
            this.userList = [];
            for (var i = 0; i < obj.reps.length; i++) {
              var user_name;
              if (obj.reps[i].middle_name != undefined || obj.reps[i].middle_name != null) {
                user_name = obj.reps[i].first_name + " " + obj.reps[i].middle_name + " " + obj.reps[i].last_name;
              } else {
                user_name = obj.reps[i].first_name + " " + obj.reps[i].last_name;
              }

              this.userList.push({
                id: obj.reps[i].user_id,
                name: user_name,
              })

              // console.log('...........', this.doctorList)
            }
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
  }

  selectedText() {
  }

  getSalesData(data) {
    var reptype;
    var send_data;
    
    reptype = data == "otc" ? this.reportType : "excel";
    if (Helper_Class.getIsAdmin() != undefined) {
      console.log("admin")
      send_data = {
        hptl_clinic_id: this.pharmacistID,
        type: reptype,
        from_date: this.fromDate,
        to_date: this.toDate,
        paytype:this.payType,
        order_type :this.PatientType,
        createdby:this.userId,
        pay_type_req:this.userInfo.hospitals[0].bill_pay_type_req,
      };
    } else {
      console.log("non-admin")
      send_data = {
        pharmacist_id: this.userName,
        type: reptype,
        from_date: this.fromDate,
        to_date: this.toDate,
        paytype:this.payType,
        order_type :this.PatientType,
        pay_type_req:this.userInfo.hospitals[0].bill_pay_type_req,
      };
    }
    console.log(send_data)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gsrep/", send_data,
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          console.log("dataval",dataval)
          if (dataval != null) {
            this.noData = true;
            this.hasData = false;
            this.salesArray = [];
            if (dataval.orders != undefined) {
              for (var i = 0; i < dataval.orders.length; i++) {
                var name;
                if (this.reportType == "OTC") {
                  name = dataval.orders[i].deliver_to;
                } else {
                  if (dataval.orders[i].deliver_to != undefined) {
                    name = dataval.orders[i].deliver_to;
                  } else {
                    if (dataval.orders[i].middle_name != undefined) {
                      name = encrypt_decript.Decript(dataval.orders[i].first_name) + " " + encrypt_decript.Decript(dataval.orders[i].middle_name) + " " + encrypt_decript.Decript(dataval.orders[i].last_name);
                    } else {
                      name = encrypt_decript.Decript(dataval.orders[i].first_name) + " " + encrypt_decript.Decript(dataval.orders[i].last_name);
                    }
                  }
                }
                var orddate = dataval.orders[i].order_date.split("-");
                var amount = dataval.orders[i].amount != undefined ? (parseFloat(dataval.orders[i].amount)).toFixed(2) : "0.00";
                var balance = dataval.orders[i].balance != undefined ? (parseFloat(dataval.orders[i].balance)).toFixed(2) : "0.00";
                if (dataval.orders[i].paid_flag != undefined && dataval.orders[i].paid_flag != null && dataval.orders[i].paid_flag != "") {
                  var payFlag = dataval.orders[i].paid_flag.replace("_", " ");
                  payFlag = payFlag.charAt(0).toUpperCase() + payFlag.substr(1).toLowerCase();
                }
                this.salesArray.push({
                  order_id: dataval.orders[i].order_id,
                  name: name,
                  created_by: dataval.orders[i].created_by,
                  order_type: dataval.orders[i].purchase_type,
                  order_date: orddate[2] + "-" + orddate[1] + "-" + orddate[0],
                  amount: parseFloat(amount).toFixed(2),
                  balance: parseFloat(balance).toFixed(2),
                  paid: payFlag,
                })
                this.forcoverdiv=false;
                this.amountcard=true;
                this.backbutton=true;
                this.ishide=true;
              }
              if (dataval.total_amount != undefined) {
                this.totalAmount = (parseFloat(dataval.total_amount)).toFixed(2);
                
              }

              if (dataval.cash_amount != undefined) {
                this.cashAmount = (parseFloat(dataval.cash_amount)).toFixed(2);
              }
              if (dataval.card_amount != undefined) {
                this.cardAmount = (parseFloat(dataval.card_amount)).toFixed(2);
              }
              if (dataval.ins_amount != undefined) {
                this.insureAmount = (parseFloat(dataval.ins_amount)).toFixed(2);
              }
              if (dataval.upi_amount != undefined) {
                this.upiAmount = (parseFloat(dataval.upi_amount)).toFixed(2);
              } 
            } else {
              this.salesArray = [];
              this.noData = false;
              this.hasData = true;
            }
          }
        }, error => { });
  }

  getExcelData(type) {
    if (this.salesArray.length != 0) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Sales report');
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 15;
      worksheet.getColumn(5).width = 20;
      let headerRow = worksheet.addRow(this.header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      })
      for (let x1 of this.salesArray) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }
      // var finaldata = ['', '', '', '', 'Total', this.totalAmount];
      // let finalrow = worksheet.addRow(finaldata);
      // let totalval = finalrow.getCell(4);
      // totalval.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }
      // let totalamt = finalrow.getCell(5);
      // totalamt.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'right'
      // }
      // totalamt.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }
      // workbook.xlsx.writeBuffer().then((data) => {
      //   let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      //   fs.saveAs(blob, "Sales_report" + '-' + new Date().valueOf() + '.xlsx');
      // });

      var cashdata = ['', '', '', '', 'Cash', this.cashAmount];
        let cashrow = worksheet.addRow(cashdata);
        let cashval = cashrow.getCell(5);
        cashval.alignment={
          vertical: 'middle',
          horizontal: 'right'
          }
        cashval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cashamt = cashrow.getCell(6);
        cashamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cashamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var carddata = ['', '', '', '', 'Card', this.cardAmount];
        let cardrow = worksheet.addRow(carddata);
        let cardval = cardrow.getCell(5);
        cardval.alignment={
          vertical: 'middle',
          horizontal: 'right'
          }
        cardval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let cardamt = cardrow.getCell(6);
        cardamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        cardamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var insdata = ['', '', '', '', 'Insurance', this.insureAmount];
        let insrow = worksheet.addRow(insdata);
        let insval = insrow.getCell(5);
        insval.alignment={
          vertical: 'middle',
          horizontal: 'right'
          }
        insval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let insamt = insrow.getCell(6);
        insamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }

        insamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var upidata = ['', '', '', '', 'UPI', this.upiAmount];
        let upirow = worksheet.addRow(upidata);
        let upival = upirow.getCell(5);
        upival.alignment={
        vertical: 'middle',
        horizontal: 'right'
        }
        upival.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        let upiamt = upirow.getCell(6);
        upiamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        upiamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }

        var finaldata = ['', '', '', '', 'Total', this.totalAmount];
        let finalrow = worksheet.addRow(finaldata);
        let totalval = finalrow.getCell(5);
        totalval.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        totalval.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
        let totalamt = finalrow.getCell(6);
        totalamt.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        }
        totalamt.font = {
          bold: true,
          color: { argb: '000000' },
          size: 12
        }
      

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "Sales_report" + '-' + new Date().valueOf() + '.xlsx');
      });





    } else {
      this.toastr.error(Message_data.exportExcelFailed);
    }
  }

  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          this.payTypeListArrray.push({
            pay_id:"All",
            pay_desc:"All",
          })
          var obj = response.json();
          for(var i=0;i<obj.payments.length;i++){
            this.payTypeListArrray.push({
              pay_id:obj.payments[i].pay_id,
              pay_desc:obj.payments[i].pay_desc,
            })
          }
          //this.payTypeListArrray = obj.payments;
          this.payType = this.payTypeListArrray [0].pay_id;
          this.getSalesData('otc');
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }


  print_area() {
    let printContents, popupWin,totalamt,cashamt,cardamt,insureamt,upiamt;
    
      printContents = this.printpharsalesRepo.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
      totalamt = this.totalamount.nativeElement.innerHTML;
      cashamt = this.cashamount.nativeElement.innerHTML
      cardamt = this.cardamount.nativeElement.innerHTML;
      insureamt = this.insureamount.nativeElement.innerHTML;
      upiamt = this.upiamount.nativeElement.innerHTML;
     
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
     // .table_class {
      //   background-color: rgb(108, 180, 248);
      // }
      // .block1_grid{
       
      //   margin: 7px auto;
      //   width: 90%;
      // }
      // .block1{
      //   margin:3px 0;
      // }
    popupWin.document.write(`
    <head>
      <title>Reports</title>
      
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
<style>
      @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
     
      th{
        padding:0%;
      }
      .tbl{
        padding:5% !important;
        border: 1px solid #c7c7e9bf !important;
        border-radius: 10px !important;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      }
      body{
        padding-top:10%;
      }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">

        <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0">
        <thead>
          <tr>
            <th colspan="3">${totalamt}</th>
            <th colspan="2">${cashamt}</th>
            <th colspan="2">${cardamt}</th>
            <th colspan="2">${upiamt}</th>
            <th colspan="2">${insureamt}</th>
         </tr>
       </thead>
      </table>

        <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
        ${printContents}
        </table>
      
        </body>
      </html>`
    );
    popupWin.document.close();
  }

  todisplaycoverdiv(){
    this.forcoverdiv=true;
    this.amountcard=false;
    this.backbutton=false;  
    this.ishide=false;
    this.salesArray=[];
    this.hasData=true;
  }
}
