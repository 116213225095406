import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener, ViewChild} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { PreviousprescriptionComponent } from '../previousprescription/previousprescription.component';
import {MatSort} from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { Message_data } from 'src/assets/js/Message_data';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-previous-prescription-popup',
  templateUrl: './previous-prescription-popup.component.html',
  styleUrls: ['./previous-prescription-popup.component.css']
})
export class PreviousPrescriptionPopupComponent implements OnInit {
  public element: any;
  public docid: string;
  public every_six: string;
  public dure_txt: string;
  public dure_write: string;
  public afterfood_txt: string;
  public intake_txt: string;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any;
  public dure_flag: boolean;
  public nodataapp: boolean;
  public saveflag: boolean=true;

  public sortdata=[];
  public listProducts=[];
  public selectedList=[];
  public userinfo;
  @ViewChild(MatSort) sort: MatSort;



  constructor(public http:Http,public dialogRef: MatDialogRef<PreviousPrescriptionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr:ToastrService) { 
      dialogRef.disableClose = true;
      this.element = el.nativeElement;
     // this.previousprescription.add(this);
     this.userinfo=Helper_Class.getInfo();
      this.docid = this.userinfo.user_id;
       //this.navParams.get("doc_reg_id"); doc id
      this.dure_flag = false;
      this.nodataapp=true;
    }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',

      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      //"order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [ 3, 5,6] },
        { "targets": [0, 2, 3, 4, 5], "searchable": false }

      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        // searchPlaceholder: "Search patients with name or mobile no or patient id"
      },
      dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10">>'
    };
    this.getPreviousPrescriptions();
  }

  getPreviousPrescriptions(){
    this.listProducts=[];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/prevmp/',{doc_reg_id:this.docid},
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
         
          if(obj.drug_list.length != 0){
            var rowcount=1;
            
            for(var i=0;i<obj.drug_list.length;i++){
              if(obj.drug_list[i].show_short_form =="1"){
                if(obj.drug_list[i].intake =="1"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+"-0-0";

                } else if(obj.drug_list[i].intake =="2"){
                  this.every_six="0 - "+obj.drug_list[i].afternoon+obj.drug_list[i].short_form+" - 0";

                } else if(obj.drug_list[i].intake =="3"){
                  this.every_six="0 - 0 - "+obj.drug_list[i].night+obj.drug_list[i].short_form;

                } else if(obj.drug_list[i].intake =="4"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - "+obj.drug_list[i].afternoon+obj.drug_list[i].short_form+" - 0";

                } else if(obj.drug_list[i].intake =="5"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - 0 - "+obj.drug_list[i].night+obj.drug_list[i].short_form;

                } else if(obj.drug_list[i].intake =="6"){
                  this.every_six="0 - "+obj.drug_list[i].afternoon+obj.drug_list[i].short_form+" - "+obj.drug_list[i].night+obj.drug_list[i].short_form;

                } else if(obj.drug_list[i].intake =="7"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - "+obj.drug_list[i].afternoon+obj.drug_list[i].short_form+" - "+obj.drug_list[i].night+obj.drug_list[i].short_form;

                } else if(obj.drug_list[i].intake =="8"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - "+obj.drug_list[i].afternoon+obj.drug_list[i].short_form+" - "+obj.drug_list[i].evening+obj.drug_list[i].short_form+" - "+obj.drug_list[i].night+obj.drug_list[i].short_form;

                } else if(obj.drug_list[i].intake =="9"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - Every 6 hours";

                } else if(obj.drug_list[i].intake =="10"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - Every 8 hours";

                } else if(obj.drug_list[i].intake =="11"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - Every 12 hours ";

                } else if(obj.drug_list[i].intake =="12"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - Once a day";

                } else if(obj.drug_list[i].intake =="13"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" SOS - if required";

                } else if(obj.drug_list[i].intake =="14"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - "+obj.drug_list[i].afternoon+obj.drug_list[i].short_form+" - "+obj.drug_list[i].evening+obj.drug_list[i].short_form+" - "+obj.drug_list[i].night+obj.drug_list[i].short_form +" Alternative days";

                } else if(obj.drug_list[i].intake =="15"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - "+obj.drug_list[i].afternoon+obj.drug_list[i].short_form+" - "+obj.drug_list[i].evening+obj.drug_list[i].short_form+" - "+obj.drug_list[i].night+obj.drug_list[i].short_form +" Once a week";

                } else if(obj.drug_list[i].intake =="16"){
                  this.every_six=obj.drug_list[i].morning+obj.drug_list[i].short_form+" - "+obj.drug_list[i].afternoon+obj.drug_list[i].short_form+" - "+obj.drug_list[i].evening+obj.drug_list[i].short_form+" - "+obj.drug_list[i].night+obj.drug_list[i].short_form +" Once in two weeks";

                } else if(obj.drug_list[i].intake =="17"){
                  this.every_six=obj.drug_list[i].morning+" STAT";
                } 

                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.dure_write = obj.drug_list[i].time_duration + " " + "mins";

                } else {
                  this.dure_write = "";
                  this.dure_flag= true;
                }

                if(this.afterfood_txt == undefined){
                  this.afterfood_txt="";
                }
                var frequency;
                if(obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks"){
                  frequency = this.intake_txt;

                } else {
                  if (obj.drug_list[i].days == "1" && obj.drug_list[i].day_dur == "day") {
                    frequency ="";
                  } else {
                    frequency = "Daily";
                  }
                }

                this.listProducts.push({
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].day_dur,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.every_six,
                  dure_txt_table: this.dure_write,
                  time_duration: obj.drug_list[i].time_duration,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  night: obj.drug_list[i].night,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  rowid:rowcount,
                  short_name: obj.drug_list[i].short_name,
                  checked:false,
                 
                });
  
              } else {
                if(obj.drug_list[i].intake =="1"){
                  this.every_six=obj.drug_list[i].morning+"- 0 - 0";

                } else if(obj.drug_list[i].intake =="2"){
                  this.every_six="0 - "+obj.drug_list[i].afternoon+" - 0";

                } else if(obj.drug_list[i].intake =="3"){
                  this.every_six="0 - 0 - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="4"){
                  this.every_six=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - 0";

                } else if(obj.drug_list[i].intake =="5"){
                  this.every_six=obj.drug_list[i].morning+" - 0 - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="6"){
                  this.every_six="0 - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="7"){
                  this.every_six=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="8"){
                  this.every_six=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].evening+" - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="9"){
                  this.every_six=obj.drug_list[i].morning+" - Every 6 hours";

                } else if(obj.drug_list[i].intake =="10"){
                  this.every_six=obj.drug_list[i].morning+" - Every 8 hours";

                } else if(obj.drug_list[i].intake =="11"){
                  this.every_six=obj.drug_list[i].morning+" - Every 12 hours";

                } else if(obj.drug_list[i].intake =="12"){
                  this.every_six=obj.drug_list[i].morning+" - Once a day";

                } else if(obj.drug_list[i].intake =="13"){
                  this.every_six=obj.drug_list[i].morning+" SOS - if required";

                } else if(obj.drug_list[i].intake =="14"){
                  this.every_six=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].evening+" - "+obj.drug_list[i].night +" Alternative days";

                } else if(obj.drug_list[i].intake =="15"){
                  this.every_six=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].evening+" - "+obj.drug_list[i].night +" Once a week";

                } else if(obj.drug_list[i].intake =="16"){
                  this.every_six=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].evening+" - "+obj.drug_list[i].night +" Once in two weeks";

                } else if(obj.drug_list[i].intake =="17"){
                  this.every_six=obj.drug_list[i].morning+" STAT";
                }  
                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.dure_write = obj.drug_list[i].time_duration + " " + "mins";

                } else {
                  this.dure_write = "";
                  this.dure_flag= true;
                }

                if(this.afterfood_txt == undefined){
                  this.afterfood_txt="";
                }
                var frequency;
                if(obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks"){
                  frequency = this.intake_txt;

                } else {
                  frequency ="Daily";
                }

                this.listProducts.push({
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].day_dur,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.every_six,
                  dure_txt_table: this.dure_write,
                  time_duration: obj.drug_list[i].time_duration,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  night: obj.drug_list[i].night,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  rowid:rowcount,
                  short_name: obj.drug_list[i].short_name,
                  checked:false,
                
                });
              }
              rowcount ++;
            }

          } else {
            this.nodataapp=false;
          }  
          
        },
        error => {

        }
        
      ) 
  }

  selectMed(product, productid,rowid,ischecked){
    if(product.checked == true){
      if(this.selectedList.length !=0){
        var flag=false;
        for(var j=0;j<this.selectedList.length;j++){
          if(this.selectedList[j].drug_id == product.drug_id){
            flag=true;
          } 
        }
        if(flag == true){
          this.toastr.warning(Message_data.medAlreadySlt);
          this.selectedList.push(product);  
        } else {
          this.selectedList.push(product);  
        }

      } else {
        this.selectedList.push(product);
      }     
    } else {
      for(var j=0;j<this.selectedList.length;j++){
        if(this.selectedList[j].drug_id == product.drug_id  
          && this.selectedList[j].rowid == product.rowid){
          this.selectedList.splice(j,1);
        }
      }
    }    
    if(this.selectedList.length != 0) {
      this.saveflag = false;
    }
    else{
      this.saveflag=true;
    }
    
  }

  addtoprescription(){
    //this.dialogRef.close();
    // this.medpres.previousprescriptionMethod(this.selectedList);
    // this.previousprescription.close('modalpage');
    //this.viewCtrl.dismiss(this.selectedList);
  //   this.selectedList;
  //  this.dialogRef.close();
  //this.dialogRef.close(this.selectedList);
    
  }

  ClosePopup(){
  this.dialogRef.close();
  }
  //sort table
  sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("tbl");
    switching = true;
    //Set the sorting direction to ascending:
    dir = "asc"; 
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
      //start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /*Loop through all table rows (except the
      first, which contains table headers):*/
      for (i = 1; i < (rows.length - 1); i++) {
        //start by saying there should be no switching:
        shouldSwitch = false;
        /*Get the two elements you want to compare,
        one from current row and one from the next:*/
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /*check if the two rows should switch place,
        based on the direction, asc or desc:*/
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch= true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /*If a switch has been marked, make the switch
        and mark that a switch has been done:*/
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        //Each time a switch is done, increase this count by 1:
        switchcount ++;      
      } else {
        /*If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again.*/
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

//filter med name
  searchMedName(){
    
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("searchmed_name");
    filter = input.value.toUpperCase();
    table = document.getElementById("tbl");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }       
    }
  }
}
