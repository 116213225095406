import { Component,ViewChild, OnInit,HostListener,ViewEncapsulation,ElementRef,OnDestroy, Injector, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-ent-clinical-exam',
  templateUrl: './ent-clinical-exam.component.html',
  styleUrls: ['./ent-clinical-exam.component.scss']
})
export class EntClinicalExamComponent implements OnInit {

  public clinicalExam ="min";
  public header_footer_flag: Boolean;
  public ota_left: boolean;
  public ota_right: boolean;
  public ota_day_txt: any;
  public ota_day_dur: any;
  public ota_severity: any;
  public ota_discharge: any;
  public oto_left: boolean;
  public oto_right: boolean;
  public oto_day_txt: any;
  public oto_day_dur: any;
  public oto_severity: any;
  public oto_discharge: any;
  public hear_left: boolean;
  public hear_right: boolean;
  public hear_day_txt: any;
  public hear_day_dur: any;
  public hear_freq: any;
  public hear_severity: any;
  public tin_left: boolean;
  public tin_right: boolean;
  public tin_txt: any;
  public tin_sel: any;
  public Freq_sel: any;
  public diz_true: boolean;
  public diz_sway: boolean;
  public diz_assloc: boolean;
  public diz_assveg: boolean;
  public dizz_txt: any;
  public dizz_sel: any;
  public mast_left: boolean;
  public mast_right: boolean;
  public mast_txt: any;
  public mast_sel: any;
  public ear_notes: any;
  public block_left: boolean;
  public block_right: boolean;
  public block_txt: any;
  public block_sel: any;
  public ota_severity_sel: any;
  public seve_Freq_sel: any;
  public dis_left: boolean;
  public dis_right: boolean;
  public dis_txt: any;
  public dis_sel: any;
  public dis_type: any;
  public epitax_left: boolean;
  public epitax_right: boolean;
  public epi_txt: any;
  public epi_sel: any;
  public dis_sel_type: any;
  public nasal_left: boolean;
  public nasal_right: boolean;
  public nasal_txt: any;
  public nasal_sel: any;
  public nose_pick: boolean;
  public front_left: boolean;
  public front_right: boolean;
  public front_txt: any;
  public front_sel: any;
  public maxi_left: boolean;
  public maxi_right: boolean;
  public maxi_txt: any;
  public maxi_sel: any;
  public root_left: boolean;
  public root_right: boolean;
  public root_txt: any;
  public root_sel: any;
  public temp_left: boolean;
  public temp_right: boolean;
  public temp_txt: any;
  public temp_sel: any;
  public pari_left: boolean;
  public pari_right: boolean;
  public pari_txt: any;
  public pari_sel: any;
  public post_dis_type: any;
  public post_txt: any;
  public post_sel: any;
  public clear_left: boolean;
  public clear_right: boolean;
  public clear_txt: any;
  public clear_sel: any;
  public snez_txt: any;
  public snez_sel: any;
  public skin_txt: any;
  public skin_sel: any;
  public bron_txt: any;
  public bron_sel: any;
  public bron_ear_notes: any;
  public apht_txt: any;
  public apht_sel: any;
  public dys_solid: boolean;
  public dys_liquid: boolean;
  public dys_txt: any;
  public dys_sel: any;
  public odyno_solid: boolean;
  public odyno_liquid: boolean;
  public ody_txt: any;
  public ody_sel: any;
  public hoar_dur_txt: any;
  public hoar_dur_sel: any;
  public noisy_txt_dur: any;
  public noisy_sel_dur: any;
  public strid_txt_dur: any;
  public strid_sel_dur: any;
  public ster_txt_dur: any;
  public ster_sel_dur: any;
  public snorin_txt_dur: any;
  public snorin_sel_dur: any;
  public mouth_txt_dur: any;
  public mouth_sel_dur: any;
  public gred_txt_dur: any;
  public gred_sel_dur: any;
  public oxy_good: boolean;
  public oxy_fair: boolean;
  public oxy_bad: boolean;
  public tong_nor: boolean;
  public tong_ulc: boolean;
  public tong_coat: boolean;
  public retro_norm: boolean;
  public retro_ulc: boolean;
  public retro_grow: boolean;
  public lip_norm: boolean;
  public lip_ulc: boolean;
  public palte_norm: boolean;
  public palte_ulc: boolean;
  public fauces_norm: boolean;
  public fauces_ulc: boolean;

  public jd_left: boolean;
  public jd_right: boolean;
  public ils_left: boolean;
  public ils_right: boolean;
  public vc_left: boolean;
  public vc_right: boolean;
  public fvc_left: boolean;
  public fvc_right: boolean;
  public grow_ton: boolean;
  public grow_ver: boolean;
  public grow_flo: boolean;
  public grow_rmt: boolean;
  public fa_left: boolean;
  public fa_right: boolean;
  public va_left: boolean;
  public va_right: boolean;
  public pf_left: boolean;
  public pf_right: boolean;

  public mig_unilate: boolean;
  public mig_uni_dur: boolean;
  public mig_uni_dur_day: boolean;
  public mig_bilate: boolean;
  public mig_bi_dur: boolean;
  public mig_bi_dur_day: boolean;

  public va_epi: string;
  public Select = "Select";
  public Ota_hidden:boolean;
  public ota_dur_hidden:boolean;
  public block_hidden:boolean;
  public ota_disc_hidden:boolean;
  public ota_severe_hidden:boolean;
  public maxi_sel_8:boolean;
  public maxi_txt_8;
  public mig_bi_dur_hidden:boolean;
  public mig_bilate_hidden:boolean;
  public mig_uni_dur_hidden:boolean;
  public mig_unilate_hidden:boolean;

  subscription: Subscription;
  public client:boolean;

  constructor(public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) { 
    
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.clinicalExam = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }
    this.getEntClinicDetails()
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   this.send_data();
  // }

  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }

  send_data(){
    var ent_clinic={
      ota_left:this.ota_left,
      ota_right:this.ota_right,
      ota_day_txt:this.ota_day_txt,
      ota_day_dur:this.ota_day_dur,
      ota_severity:this.ota_severity,
      ota_discharge:this.ota_discharge,
      oto_left:this.oto_left,
      oto_right:this.oto_right,
      oto_day_txt:this.oto_day_txt,
      oto_day_dur:this.oto_day_dur,
      oto_severity:this.oto_severity,
      oto_discharge:this.oto_discharge,
      hear_left:this.hear_left,
      hear_right:this.hear_right,
      hear_day_txt:this.hear_day_txt,
      hear_day_dur:this.hear_day_dur,
      hear_freq:this.hear_freq,
      hear_severity:this.hear_severity,
      tin_left:this.tin_left,
      tin_right:this.tin_right,
      tin_txt:this.tin_txt,
      tin_sel:this.tin_sel,
      Freq_sel:this.Freq_sel,
      diz_true:this.diz_true,
      diz_sway:this.diz_sway,
      diz_assloc:this.diz_assloc,
      diz_assveg:this.diz_assveg,
      dizz_txt:this.dizz_txt,
      dizz_sel:this.dizz_sel,
      mast_left:this.mast_left,
      mast_right:this.mast_right,
      mast_txt:this.mast_txt,
      mast_sel:this.mast_sel,
      ear_notes:this.ear_notes,
      block_left:this.block_left,
      block_right:this.block_right,
      block_txt:this.block_txt,
      block_sel:this.block_sel,
      ota_severity_sel:this.ota_severity_sel,
      seve_Freq_sel:this.seve_Freq_sel,
      dis_left:this.dis_left,
      dis_right:this.dis_right,
      dis_txt:this.dis_txt,
      dis_sel:this.dis_sel,
      dis_type:this.dis_type,
      epitax_left:this.epitax_left,
      epitax_right:this.epitax_right,
      epi_txt:this.epi_txt,
      epi_sel:this.epi_sel,
      dis_sel_type:this.dis_sel_type,
      nasal_left:this.nasal_left,
      nasal_right:this.nasal_right,
      nasal_txt:this.nasal_txt,
      nasal_sel:this.nasal_sel,
      nose_pick:this.nose_pick,
      front_left:this.front_left,
      front_right:this.front_right,
      front_txt:this.front_txt,
      front_sel:this.front_sel,
      maxi_left:this.maxi_left,
      maxi_right:this.maxi_right,
      maxi_txt:this.maxi_txt,
      maxi_sel:this.maxi_sel,
      root_left:this.root_left,
      root_right:this.root_right,
      root_txt:this.root_txt,
      root_sel:this.root_sel,
      temp_left:this.temp_left,
      temp_right:this.temp_right,
      temp_txt:this.temp_txt,
      temp_sel:this.temp_sel,
      pari_left:this.pari_left,
      pari_right:this.pari_right,
      pari_txt:this.pari_txt,
      pari_sel:this.pari_sel,
      post_dis_type:this.post_dis_type,
      post_txt:this.post_txt,
      post_sel:this.post_sel,
      clear_left:this.clear_left,
      clear_right:this.clear_right,
      clear_txt:this.clear_txt,
      clear_sel:this.clear_sel,
      snez_txt:this.snez_txt,
      snez_sel:this.snez_sel,
      skin_txt:this.skin_txt,
      skin_sel:this.skin_sel,
      bron_txt:this.bron_txt,
      bron_sel:this.bron_sel,
      bron_ear_notes:this.bron_ear_notes,
      apht_txt:this.apht_txt,
      apht_sel:this.apht_sel,
      dys_solid:this.dys_solid,
      dys_liquid:this.dys_liquid,
      dys_txt:this.dys_txt,
      dys_sel:this.dys_sel,
      odyno_solid:this.odyno_solid,
      odyno_liquid:this.odyno_liquid,
      ody_txt:this.ody_txt,
      ody_sel:this.ody_sel,
      hoar_dur_txt:this.hoar_dur_txt,
      hoar_dur_sel:this.hoar_dur_sel,
      noisy_txt_dur:this.noisy_txt_dur,
      noisy_sel_dur:this.noisy_sel_dur,
      strid_txt_dur:this.strid_txt_dur,
      strid_sel_dur:this.strid_sel_dur,
      ster_txt_dur:this.ster_txt_dur,
      ster_sel_dur:this.ster_sel_dur,
      snorin_txt_dur:this.snorin_txt_dur,
      snorin_sel_dur:this.snorin_sel_dur,
      mouth_txt_dur:this.mouth_txt_dur,
      mouth_sel_dur:this.mouth_sel_dur,
      gred_txt_dur:this.gred_txt_dur,
      gred_sel_dur:this.gred_sel_dur,
      oxy_good:this.oxy_good,
      oxy_fair:this.oxy_fair,
      oxy_bad:this.oxy_bad,
      tong_nor:this.tong_nor,
      tong_ulc:this.tong_ulc,
      tong_coat:this.tong_coat,
      retro_norm:this.retro_norm,
      retro_ulc:this.retro_ulc,
      retro_grow:this.retro_grow,
      lip_norm:this.lip_norm,
      lip_ulc:this.lip_ulc,
      palte_norm:this.palte_norm,
      palte_ulc:this.palte_ulc,
      fauces_norm:this.fauces_norm,
      fauces_ulc:this.fauces_ulc,
      jd_left:this.jd_left,
      jd_right:this.jd_right,
      ils_left:this.ils_left,
      ils_right:this.ils_right,
      vc_left:this.vc_left,
      vc_right:this.vc_right,
      fvc_left:this.fvc_left,
      fvc_right:this.fvc_right,
      grow_ton:this.grow_ton,
      grow_ver:this.grow_ver,
      grow_flo:this.grow_flo,
      grow_rmt:this.grow_rmt,
      fa_left:this.fa_left,
      fa_right:this.fa_right,
      va_left:this.va_left,
      va_right:this.va_right,
      va_epi:this.va_epi,
      pf_left:this.pf_left,
      pf_right:this.pf_right,
      mig_unilate:this.mig_unilate,
      mig_uni_dur:this.mig_uni_dur,
      mig_uni_dur_day:this.mig_uni_dur_day,
      mig_bilate:this.mig_bilate,
      mig_bi_dur:this.mig_bi_dur,
      mig_bi_dur_day:this.mig_bi_dur_day,
    }
    Helper_Class.setEntClinical(ent_clinic);
  }

  getEntClinicDetails(){
    if (Helper_Class.getRet() != undefined) {
      this.ota_left= Helper_Class.getRet().ota_left;
      this.ota_right= Helper_Class.getRet().ota_right;
      this.ota_day_txt= Helper_Class.getRet().ota_day_txt;
      this.ota_day_dur= Helper_Class.getRet().ota_day_dur;
      this.ota_severity= Helper_Class.getRet().ota_severity;
      this.ota_discharge= Helper_Class.getRet().ota_discharge;
      this.oto_left= Helper_Class.getRet().oto_left;
      this.oto_right= Helper_Class.getRet().oto_right;
      this.oto_day_txt= Helper_Class.getRet().oto_day_txt;
      this.oto_day_dur= Helper_Class.getRet().oto_day_dur;
      this.oto_severity= Helper_Class.getRet().oto_severity;
      this.oto_discharge= Helper_Class.getRet().oto_discharge;
      this.hear_left= Helper_Class.getRet().hear_left;
      this.hear_right= Helper_Class.getRet().hear_right;
      this.hear_day_txt= Helper_Class.getRet().hear_day_txt;
      this.hear_day_dur= Helper_Class.getRet().hear_day_dur;
      this.hear_freq= Helper_Class.getRet().hear_freq;
      this.hear_severity= Helper_Class.getRet().hear_severity;
      this.tin_left= Helper_Class.getRet().tin_left;
      this.tin_right= Helper_Class.getRet().tin_right;
      this.tin_txt= Helper_Class.getRet().tin_txt;
      this.tin_sel= Helper_Class.getRet().tin_sel;
      this.Freq_sel= Helper_Class.getRet().Freq_sel;
      this.diz_true= Helper_Class.getRet().diz_true;
      this.diz_sway= Helper_Class.getRet().diz_sway;
      this.diz_assloc= Helper_Class.getRet().diz_assloc;
      this.diz_assveg= Helper_Class.getRet().diz_assveg;
      this.dizz_txt= Helper_Class.getRet().dizz_txt;
      this.dizz_sel= Helper_Class.getRet().dizz_sel;
      this.mast_left= Helper_Class.getRet().mast_left;
      this.mast_right= Helper_Class.getRet().mast_right;
      this.mast_txt= Helper_Class.getRet().mast_txt;
      this.mast_sel= Helper_Class.getRet().mast_sel;
      this.ear_notes= Helper_Class.getRet().ear_notes;
      this.block_left= Helper_Class.getRet().block_left;
      this.block_right= Helper_Class.getRet().block_right;
      this.block_txt= Helper_Class.getRet().block_txt;
      this.block_sel= Helper_Class.getRet().block_sel;
      this.ota_severity_sel= Helper_Class.getRet().ota_severity_sel;
      this.seve_Freq_sel= Helper_Class.getRet().seve_Freq_sel;
      this.dis_left= Helper_Class.getRet().dis_left;
      this.dis_right= Helper_Class.getRet().dis_right;
      this.dis_txt= Helper_Class.getRet().dis_txt;
      this.dis_sel= Helper_Class.getRet().dis_sel;
      this.dis_type= Helper_Class.getRet().dis_type;
      this.epitax_left= Helper_Class.getRet().epitax_left;
      this.epitax_right= Helper_Class.getRet().epitax_right;
      this.epi_txt= Helper_Class.getRet().epi_txt;
      this.epi_sel= Helper_Class.getRet().epi_sel;
      this.dis_sel_type= Helper_Class.getRet().dis_sel_type;
      this.nasal_left= Helper_Class.getRet().nasal_left;
      this.nasal_right= Helper_Class.getRet().nasal_right;
      this.nasal_txt= Helper_Class.getRet().nasal_txt;
      this.nasal_sel= Helper_Class.getRet().nasal_sel;
      this.nose_pick= Helper_Class.getRet().nose_pick;
      this.front_left= Helper_Class.getRet().front_left;
      this.front_right= Helper_Class.getRet().front_right;
      this.front_txt= Helper_Class.getRet().front_txt;
      this.front_sel= Helper_Class.getRet().front_sel;
      this.maxi_left= Helper_Class.getRet().maxi_left;
      this.maxi_right= Helper_Class.getRet().maxi_right;
      this.maxi_txt= Helper_Class.getRet().maxi_txt;
      this.maxi_sel= Helper_Class.getRet().maxi_sel;
      this.root_left= Helper_Class.getRet().root_left;
      this.root_right= Helper_Class.getRet().root_right;
      this.root_txt= Helper_Class.getRet().root_txt;
      this.root_sel= Helper_Class.getRet().root_sel;
      this.temp_left= Helper_Class.getRet().temp_left;
      this.temp_right= Helper_Class.getRet().temp_right;
      this.temp_txt= Helper_Class.getRet().temp_txt;
      this.temp_sel= Helper_Class.getRet().temp_sel;
      this.pari_left= Helper_Class.getRet().pari_left;
      this.pari_right= Helper_Class.getRet().pari_right;
      this.pari_txt= Helper_Class.getRet().pari_txt;
      this.pari_sel= Helper_Class.getRet().pari_sel;
      this.post_dis_type= Helper_Class.getRet().post_dis_type;
      this.post_txt= Helper_Class.getRet().post_txt;
      this.post_sel= Helper_Class.getRet().post_sel;
      this.clear_left= Helper_Class.getRet().clear_left;
      this.clear_right= Helper_Class.getRet().clear_right;
      this.clear_txt= Helper_Class.getRet().clear_txt;
      this.clear_sel= Helper_Class.getRet().clear_sel;
      this.snez_txt= Helper_Class.getRet().snez_txt;
      this.snez_sel= Helper_Class.getRet().snez_sel;
      this.skin_txt= Helper_Class.getRet().skin_txt;
      this.skin_sel= Helper_Class.getRet().skin_sel;
      this.bron_txt= Helper_Class.getRet().bron_txt;
      this.bron_sel= Helper_Class.getRet().bron_sel;
      this.bron_ear_notes= Helper_Class.getRet().bron_ear_notes;
      this.apht_txt= Helper_Class.getRet().apht_txt;
      this.apht_sel= Helper_Class.getRet().apht_sel;
      this.dys_solid= Helper_Class.getRet().dys_solid;
      this.dys_liquid= Helper_Class.getRet().dys_liquid;
      this.dys_txt= Helper_Class.getRet().dys_txt;
      this.dys_sel= Helper_Class.getRet().dys_sel;
      this.odyno_solid= Helper_Class.getRet().odyno_solid;
      this.odyno_liquid= Helper_Class.getRet().odyno_liquid;
      this.ody_txt= Helper_Class.getRet().ody_txt;
      this.ody_sel= Helper_Class.getRet().ody_sel;
      this.hoar_dur_txt= Helper_Class.getRet().hoar_dur_txt;
      this.hoar_dur_sel= Helper_Class.getRet().hoar_dur_sel;
      this.noisy_txt_dur= Helper_Class.getRet().noisy_txt_dur;
      this.noisy_sel_dur= Helper_Class.getRet().noisy_sel_dur;
      this.strid_txt_dur= Helper_Class.getRet().strid_txt_dur;
      this.strid_sel_dur= Helper_Class.getRet().strid_sel_dur;
      this.ster_txt_dur= Helper_Class.getRet().ster_txt_dur;
      this.ster_sel_dur= Helper_Class.getRet().ster_sel_dur;
      this.snorin_txt_dur= Helper_Class.getRet().snorin_txt_dur;
      this.snorin_sel_dur= Helper_Class.getRet().snorin_sel_dur;
      this.mouth_txt_dur= Helper_Class.getRet().mouth_txt_dur;
      this.mouth_sel_dur= Helper_Class.getRet().mouth_sel_dur;
      this.gred_txt_dur= Helper_Class.getRet().gred_txt_dur;
      this.gred_sel_dur= Helper_Class.getRet().gred_sel_dur;
      this.oxy_good= Helper_Class.getRet().oxy_good;
      this.oxy_fair= Helper_Class.getRet().oxy_fair;
      this.oxy_bad= Helper_Class.getRet().oxy_bad;
      this.tong_nor= Helper_Class.getRet().tong_nor;
      this.tong_ulc= Helper_Class.getRet().tong_ulc;
      this.tong_coat= Helper_Class.getRet().tong_coat;
      this.retro_norm= Helper_Class.getRet().retro_norm;
      this.retro_ulc= Helper_Class.getRet().retro_ulc;
      this.retro_grow= Helper_Class.getRet().retro_grow;
      this.lip_norm= Helper_Class.getRet().lip_norm;
      this.lip_ulc= Helper_Class.getRet().lip_ulc;
      this.palte_norm= Helper_Class.getRet().palte_norm;
      this.palte_ulc= Helper_Class.getRet().palte_ulc;
      this.fauces_norm= Helper_Class.getRet().fauces_norm;
      this.fauces_ulc= Helper_Class.getRet().fauces_ulc;
      this.jd_left= Helper_Class.getRet().jd_left;
      this.jd_right= Helper_Class.getRet().jd_right;
      this.ils_left= Helper_Class.getRet().ils_left;
      this.ils_right= Helper_Class.getRet().ils_right;
      this.vc_left= Helper_Class.getRet().vc_left;
      this.vc_right= Helper_Class.getRet().vc_right;
      this.fvc_left= Helper_Class.getRet().fvc_left;
      this.fvc_right= Helper_Class.getRet().fvc_right;
      this.grow_ton= Helper_Class.getRet().grow_ton;
      this.grow_ver= Helper_Class.getRet().grow_ver;
      this.grow_flo= Helper_Class.getRet().grow_flo;
      this.grow_rmt= Helper_Class.getRet().grow_rmt;
      this.fa_left= Helper_Class.getRet().fa_left;
      this.fa_right= Helper_Class.getRet().fa_right;
      this.va_left= Helper_Class.getRet().va_left;
      this.va_right= Helper_Class.getRet().va_right;
      this.va_epi= Helper_Class.getRet().va_epi;
      this.pf_left= Helper_Class.getRet().pf_left;
      this.pf_right= Helper_Class.getRet().pf_right;
      this.mig_unilate= Helper_Class.getRet().mig_unilate;
      this.mig_uni_dur= Helper_Class.getRet().mig_uni_dur;
      this.mig_uni_dur_day= Helper_Class.getRet().mig_uni_dur_day;
      this.mig_bilate= Helper_Class.getRet().mig_bilate;
      this.mig_bi_dur= Helper_Class.getRet().mig_bi_dur;
      this.mig_bi_dur_day= Helper_Class.getRet().mig_bi_dur_day;
    }
    this.send_data();
  }
}
