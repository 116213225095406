<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                      <h5 class="m-0" class="mainHeadingStyle">Pending</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="uploadData()"
                        class="saveimgbtn_inpatinfo" />
                    </div>
                  </div>
               
            </mdb-card-header>
            <mdb-card-body>
                <div class="row" style="margin: 0px;">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="locationInfomation.length>1">
                        <mat-label class="matlabel">Location
                            <select class="ipcss " [(ngModel)]="diagLocation">
                             <!-- (ngModelChange)="get_client(diag_loc,diag_user_type)" -->
                                <option disabled [ngValue]="Select">Select</option>
                                <option *ngFor="let loc of locationInfomation" [ngValue]="loc">{{loc.diag_loc}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Client Name
                                    <input type="text" class="ipcss" [(ngModel)]="clientName"
                                    (keyup)="getClient(diagLocation,diagUserType)" matInput />
                                    <div class="auto_complete_text" *ngIf="clientNameArray.length != 0">
                                        <ul *ngFor="let clientName of clientNameArray">
                                            <li>
                                                <a (click)="changeClient(clientName)">{{clientName.id + " - " +clientName.name}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <select class="ipcss " [(ngModel)]="client_name"
                                        (ngModelChange)="client_change(client_name)">
                                        <option [ngValue]="undefined" selected>Select</option>
                                        <option *ngFor="let name of client_name_arr" [ngValue]="name">
                                            {{name.id+"-"+name.name}}</option>
                                    </select> -->
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Prescribed Test
                                    <select class="ipcss " [(ngModel)]="presTest"
                                        (ngModelChange)="changeClientTest(presTest)">
                                        <option [ngValue]="undefined" selected>Select</option>
                                        <option *ngFor="let test of testDataArray" [ngValue]="test">{{test.Data}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Test
                                    <select class="ipcss " [(ngModel)]="subTestName"
                                        (ngModelChange)="changeSubTest(subTestName)">
                                        <option [ngValue]="undefined" selected>Select</option>
                                        <option *ngFor="let sub of subTestArray" [ngValue]="sub">{{sub.test_name}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="subTestView">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <mat-card *ngFor="let sub of SubtestRangeArray" class="matcardtest">
                            <mat-card-content>
                                <div class="row" style="margin-top: 12px;">
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">{{sub.sub_test_name}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <input style="margin-top:18px;" class="ipcss" [(ngModel)]="sub.test_range_value"
                                            matInput>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">
                                            <!-- Range:{{sub.test_range +" "+sub.unit}} -->
                                            <div class="row">
                                                <div class="col-8">
                                                    <div *ngIf="sub.test_range != undefined" [innerHtml] = "sub.test_range | safeHtml" ></div>
                                                </div>
                                                <div class="col-4">
                                                    <div>{{sub.unit}}</div>
                                                </div>
                                            </div>
                                        </mat-label>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div *ngIf="subTestView2" class="row" style="padding: 10px;">
                    <div *ngIf="!show" class="col-12">
                        <mat-label class="matlable">Observation
                            <quill-editor id="editor" [(ngModel)]="sequence" [style]="editorStyle" [modules]="modules"
                                (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                                (onSelectionChanged)="onSelectionChanged($event)"
                                (onContentChanged)="onContentChanged($event)"></quill-editor>
                            <div>
                                <!-- Preview -->
                                <quill-view-html hidden id="viewhtml" [content]="sequence"></quill-view-html>
                            </div>
                        </mat-label>
                    </div>
                    <div *ngIf="show" class="col-12">
                        <mat-label class="matlable">Sequences
                            <!-- <textarea class="ipcss" [(ngModel)]="sequence" matInput></textarea> -->
                            <quill-editor id="editor1" [(ngModel)]="sequence" [style]="editorStyle" [modules]="modules"
                                (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                                (onSelectionChanged)="onSelectionChanged($event)"
                                (onContentChanged)="onContentChanged($event)"></quill-editor>
                            <div>
                                <!-- Preview -->
                                <quill-view-html hidden id="viewhtml1" [content]="sequence"></quill-view-html>
                            </div>

                        </mat-label>
                    </div>
                    <div class="col-12" style="margin-top: 10px;">
                        <mat-label class="matlable">Impression
                            <!-- <textarea class="ipcss" [(ngModel)]="impression" matInput></textarea> -->
                            <quill-editor id="editor2" [(ngModel)]="impression" [style]="editorStyle"
                                [modules]="modules" (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                                (onSelectionChanged)="onSelectionChanged($event)"
                                (onContentChanged)="onContentChanged2($event)"></quill-editor>
                            <div>
                                <!-- Preview -->
                                <quill-view-html hidden id="viewhtml2" [content]="impression"></quill-view-html>
                            </div>
                        </mat-label>
                    </div>
                    <div [hidden]="chooseFileFlag" class="col-12" style="margin-top: 10px;">
                        <p class="tervys_heading"><strong>Document Upload </strong></p>
                        <input type="file" name="filUpload" (change)="fileChange($event)" class="u1" />
                    </div>
                </div>
                <div *ngIf="show_culture_test" class="row" style="padding: 10px;">
                    <div class="col-12" style="margin-top: 10px;">
                        <mat-label class="matlable">Report details
                            <!-- <textarea class="ipcss" [(ngModel)]="impression" matInput></textarea> -->
                            <quill-editor [(ngModel)]="culture" [style]="editorStyle"
                                [modules]="modules" (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                                (onSelectionChanged)="onSelectionChanged($event)"
                                (onContentChanged)="onContentChanged2($event)"></quill-editor>
                            <div>
                            </div>
                        </mat-label>
                    </div>
                </div>

            </mdb-card-body>
        </mdb-card>
    </div>
</div>