import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { ConvertTimeformat, seesion_nameid, Time_Formate_Session, Time_Formate, Session_Return, Day_Return, day_nameid } from '../../../assets/js/common';
import { session_based_time_fun } from '../../../assets/js/session_based_time';
import { Router } from '@angular/router';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-nurse-profile',
  templateUrl: './nurse-profile.component.html',
  styleUrls: ['./nurse-profile.component.scss']
})
export class NurseProfileComponent implements OnInit {
  public profileTab: any;
  public profileWorkTab: any;
  public profileWorkAdd: any;
  public profileQual: any;
  public sendURL = "usercontroller/updprov/";
  public mobileNo = null;
  public emailID = null;
  public homeCare = null;
  public homeCareVal: boolean;
  public imgString: string;
  public treatementArray = [];
  public specilizationArray = [];
  public subSpecilitiesArray = [];
  public startsFromArray = [];
  public endsAtArray = [];
  public homeCareConsultArray = [];
  public homeCareFlag;
  public toggHomeFlag: boolean;
  public homeCareSession: string;
  public startTime1: string;
  public startTime2: string;
  public startTime3: string;
  public endTime1: string;
  public endTime2: string;
  public endTime3: string;
  public homeCareFee: string;
  public firstName: string;
  public middleName: string;
  public profileImg: string;
  public lastName: string;
  public DOB: string;
  public mobile: string;
  public email: string;
  public gender: string;
  public registerationCode: string;
  public registerationYear: string;
  public registerationState: string;
  public clientCall: boolean;
  public secondOpinion: boolean;
  public presAddress1: string;
  public presAddress2: string;
  public presLocation: string;
  public presCity: string;
  public presState: string;
  public presCountry: string;
  public presZipcode: string;
  public presTelephone: string;
  public permAddress1: string;
  public permAddress2: string;
  public permLocation: string;
  public permCity;
  public permState: string;
  public permCountry: string;
  public permZipcode: string;
  public permTelephone: string;
  public presCityList;
  public presStateList;
  public presCountryList;
  public cityURL;
  public stateURL;
  public countryURL;
  public send_subspl_array = [];
  public filt_city_desc;
  public presLocationID: string;
  public permLocationID: string;
  public mobileNumber: string;
  public emailContent: string;
  public permStateList;
  public permCountryList;
  public permCityList;
  public workAarry = [];
  public filtStateDesc;
  public countryID: string;
  public hospitalName: string;
  public hospitalLocation: string;
  public hospitalID;
  public hospitalAddress1: string;
  public hospitalAddress2: string;
  public hospitalWebsite: string;
  public hospitalContactNumber: string;
  public selectPresCity;
  public permFlag: boolean;
  public sameasAbove: boolean;
  public preClientAddressArray = [];
  public proImg;
  lastImage: string = null;
  base64Image: any;
  public loader: boolean;
  public userinfo;
  public qualificationArray: any = [];
  tabs2Pharms: any;
  public qualificationDegree: string;
  public qualificationClg: string;
  public qualificationUni: string;
  public qualificationYear: string;
  public hospDist;
  public hospState;
  public hospCountry;
  public hospZIp: string;
  public hospFees: string;
  public hospDays: string;
  public hospSession: string;
  public startFrom1: string;
  public startFrom2: string;
  public startFrom3: string;
  public endAt1: string;
  public endAt2: string;
  public endAt3: string;
  public hopsConsult: boolean;
  public appTokenFlag: boolean;
  public appTimeFalg: boolean;
  public count: string;
  public hospitalArray = [];
  public locationArray: string;
  public countryDiscription: string;
  public locationFilterArray = [];
  public filteredlocListArray = [];
  public filteredhospList = [];
  public hospitalFilterListArray = [];
  public locationListArray = [];
  public cityArray;
  public stateArray;
  public countryArray;
  public appType: string;
  public cancelWork: any;
  constructor(public http: Http, public toastr: ToastrService, public location: Location, public messageService: MenuViewService, public router: Router) {
    this.imgString = null;
    this.homeCareSession = "";
    this.homeCareFee = "";
    this.toggHomeFlag = false;
    this.loader = false;
    this.sameasAbove = false;
    this.permFlag = false;
    this.startTime1 = "06";
    this.startTime2 = "00";
    this.startTime3 = "AM";
    this.endTime1 = "08";
    this.endTime2 = "00";
    this.endTime3 = "AM";
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.homeCareFlag = true;
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.startsFromArray.push("0" + i);
      } else {
        this.startsFromArray.push(i);
      }
    }
    for (var j = 0; j <= 55; j += 5) {
      if (j < 10) {
        this.endsAtArray.push("0" + j);
      } else {
        this.endsAtArray.push(j);
      }
    }
  }
  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo();
    this.getSubSpecilities();
    this.getTreatement();
    this.getSpecialization();
    this.profileInfo();
  }
  profileInformation() {
    var cli_call = null;
    if (this.clientCall == true) {
      cli_call = "1";
    }
    else {
      cli_call = "0";
    }
    var sec_opn = null;
    if (this.secondOpinion == true) {
      sec_opn = "1"
    }
    else {
      sec_opn = "0"
    }
    var home_value = null;
    if (this.toggHomeFlag == true) {
      home_value = "1"
    }
    else {
      home_value = "0"
    }
    var present_address2 = "", homecare_val = true;
    if (this.presAddress2 != undefined) {
      present_address2 = this.presAddress2;
    }
    var permanent_address2 = "";
    if (this.permAddress2 != undefined) {
      permanent_address2 = this.permAddress2;
    }
    this.preClientAddressArray = [];
    if (this.sameasAbove == true) {
      if (this.presAddress1 == undefined || this.presAddress1 == "") {
        homecare_val = false;
      }
      if (this.presLocation == undefined || this.presLocation == "") {
        homecare_val = false;
      }
      if (this.selectPresCity == undefined || this.selectPresCity == "") {
        homecare_val = false;
      }
      if (this.presState == undefined || this.presState == "") {
        homecare_val = false;
      }
      if (this.presCountry == undefined || this.presCountry == "") {
        homecare_val = false;
      }
      if (this.presTelephone == undefined || this.presTelephone == "") {
        homecare_val = false;
      }
      this.preClientAddressArray.push({
        address1: this.presAddress1,
        address2: present_address2,
        location: this.presLocationID,
        city: this.selectPresCity,
        state: this.presState,
        country: this.presCountry,
        zipcode: this.presZipcode,
        telephone: this.presTelephone,
        sameascurrent: "1",
        type: "pres",
      });
    } else {
      if (this.presAddress1 == undefined || this.presAddress1 == "") {
        homecare_val = false;
      }
      if (this.presLocation == undefined || this.presLocation == "") {
        homecare_val = false;
      }
      if (this.selectPresCity == undefined || this.selectPresCity == "") {
        homecare_val = false;
      }
      if (this.presState == undefined || this.presState == "") {
        homecare_val = false;
      }
      if (this.presCountry == undefined || this.presCountry == "") {
        homecare_val = false;
      }
      if (this.presTelephone == undefined || this.presTelephone == "") {
        homecare_val = false;
      }
      if (this.permAddress1 == undefined || this.permAddress1 == "") {
        homecare_val = false;
      }
      if (this.permLocationID == undefined || this.permLocationID == "") {
        homecare_val = false;
      }
      if (this.permCity == undefined || this.permCity == "") {
        homecare_val = false;
      }
      if (this.permState == undefined || this.permState == "") {
        homecare_val = false;
      }
      if (this.permCountry == undefined || this.permCountry == "") {
        homecare_val = false;
      }
      if (this.permTelephone == undefined || this.permTelephone == "") {
        homecare_val = false;
      }
      this.preClientAddressArray.push({
        address1: this.presAddress1,
        address2: present_address2,
        location: this.presLocationID,
        city: this.selectPresCity,
        state: this.presState,
        country: this.presCountry,
        zipcode: this.presZipcode,
        telephone: this.presTelephone,
        sameascurrent: "0",
        type: "pres",
      },
        {
          address1: this.permAddress1,
          address2: permanent_address2,
          location: this.permLocationID,
          city: this.permCity,
          state: this.permState,
          country: this.permCountry,
          zipcode: this.permZipcode,
          telephone: this.permTelephone,
          sameascurrent: "0",
          type: "perm",
        });
    }
    this.mobileNumber = encrypt_decript.Encript(this.mobile).toString();
    this.emailContent = encrypt_decript.Encript(this.email).toString();
    var img_path = this.proImg, upd_url = "0";
    if (this.imgString != null) {
      img_path = this.imgString.split(',')[1];
      upd_url = "1";
    } else {
      upd_url = undefined;
    }
    var send_array = null;
    if (home_value == "1") {
      send_array = {
        home_care_val: homecare_val,
        upd_url: upd_url,
        profile_image: img_path,
        serv_provider_reg_id: localStorage.getItem("user_id"),
        mobile: this.mobileNumber,
        language: "",
        provider: "nurse",
        home_address: this.preClientAddressArray,
        homecare_consult: this.homeCareConsultArray,
        home_care: home_value,
        email: this.emailContent,
      }
    } else {
      send_array = {
        home_care_val: homecare_val,
        upd_url: upd_url,
        profile_image: img_path,
        serv_provider_reg_id: localStorage.getItem("user_id"),
        mobile: this.mobileNumber,
        language: "",
        provider: "nurse",
        home_care: home_value,
        email: this.emailContent,
      }
    }
    Helper_Class.setprofile_array(send_array);
    this.workingInformation();
  }
  qualificationInfromation() {
    var qual_arr = null;
    qual_arr = {
      qualification: this.qualificationArray
    }
    Helper_Class.setprofile_qual_array(qual_arr);
    this.initateToUpdate();
  }
  workingInformation() {
    var work_arry1 = [];
    var work_address_array = [];
    for (var i = 0; i < this.workAarry.length; i++) {
      if (this.workAarry[i].hospital_id == "0") {
        var address2_txt = undefined
        if (this.workAarry[i].address2 != undefined) {
          address2_txt = this.workAarry[i].address2;
        } else {
          address2_txt = "";
        }
        work_address_array.push({
          name: this.workAarry[i].name,
          address1: this.workAarry[i].address1,
          address2: address2_txt,
          city: this.workAarry[i].city,
          state: this.workAarry[i].state,
          zipcode: this.workAarry[i].zipcode,
          country: this.workAarry[i].country,
          telephone: this.workAarry[i].mobilenum,
          website: this.workAarry[i].hospital_website,
          location: this.workAarry[i].location,
        });
        var hosp_nursename = this.workAarry[i].name;
        var hosp_nurseloc = this.workAarry[i].location;
      }
      if (this.workAarry[i].consult_time_id != "0") {
        var consult_time_id = this.workAarry[i].consult_time_id;
      } else {
        consult_time_id = "0";
      }
      work_arry1.push({
        hospital_id: this.workAarry[i].hospital_id,
        available_from: ConvertTimeformat("", this.workAarry[i].available_from),
        available_to: ConvertTimeformat("", this.workAarry[i].available_to),
        fee: this.workAarry[i].fee,
        day: this.workAarry[i].day,
        day_session: this.workAarry[i].day_session,
        token: this.workAarry[i].token,
        app_duration: this.workAarry[i].app_duration,
        consultant: this.workAarry[i].consultant,
        consultation_time_id: consult_time_id,
        hosp_name: hosp_nursename,
        location: hosp_nurseloc,
      });
    }
    Helper_Class.set_work_array(work_arry1);
    Helper_Class.set_work_add_array(work_address_array);
    this.qualificationInfromation();
  }
  getSubSpecilities() {
    this.subSpecilitiesArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/denspl',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.den_spl.length; i++) {
            this.subSpecilitiesArray.push({
              type: 'checkbox',
              value: obj.den_spl[i].den_spl_id,
              label: obj.den_spl[i].description,
              checked: false
            });
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
  getTreatement() {
    this.treatementArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getmedicare',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.medicare != null) {
            for (var i = 0; i < obj.medicare.length; i++) {
              this.treatementArray.push({
                type: 'checkbox',
                value: obj.medicare[i].medicare_id,
                label: obj.medicare[i].medicare_name,
                checked: false
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
  getSpecialization() {
    this.specilizationArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getspecialization',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.specialization.length; i++) {
            this.specilizationArray.push({
              type: 'checkbox',
              value: obj.specialization[i].spl_id,
              label: obj.specialization[i].spl_name,
              checked: false
            });
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
  clickHome() {
    if (this.homeCareFlag == true) {
      this.homeCareFlag = false;
    } else {
      this.homeCareFlag = true;
    }
  }
  deleteHomeConsult(session_data, fee_data) {
    for (var i = 0; i < this.homeCareConsultArray.length; i++)
      if (session_data == this.homeCareConsultArray[i].session) {
        this.homeCareConsultArray.splice(i, 1);
      }
  }
  clickHomeCare() {
    if (this.homeCareSession == "" || this.homeCareFee == "") {
      this.toastr.error(Message_data.mandatory);
    }
    else {
      var f_time = ConvertTimeformat("", this.startTime1 + ":" + this.startTime2 + " " + this.startTime3);
      var t_time = ConvertTimeformat("", this.endTime1 + ":" + this.endTime2 + " " + this.endTime3);
      var ret_value = session_based_time_fun(f_time, t_time, this.homeCareSession);
      if (ret_value == true) {
        this.homeCareConsultArray.push({
          day_session: seesion_nameid(this.homeCareSession),
          session: this.homeCareSession,
          available_from: ConvertTimeformat("", this.startTime1 + ":" + this.startTime2 + " " + this.startTime3),
          available_to: ConvertTimeformat("", this.endTime1 + ":" + this.endTime2 + " " + this.endTime3),
          available_from1: this.startTime1 + ":" + this.startTime2 + " " + this.startTime3,
          available_to1: this.endTime1 + ":" + this.endTime2 + " " + this.endTime3,
          home_care_fee: this.homeCareFee,
          homecare_consult_id: "0"
        });
        this.homeCareSession = "";
        this.startTime1 = "06";
        this.startTime2 = "00";
        this.startTime3 = "AM";
        this.endTime1 = "08";
        this.endTime2 = "00";
        this.endTime3 = "AM";
        this.homeCareFee = "";
      }
      else {
        this.toastr.error(Message_data.invalidTime);
      }
    }
  }
  profileInfo() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/providerdetails',
      JSON.stringify({
        nurse: this.userinfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          Helper_Class.setProfile_info(obj);
          if (obj.profile_image != null) {
            this.loader = true;
            this.proImg = obj.profile_image
            try {
              if (this.proImg == "default") {
                this.profileImg = "assets/imgs/default.jpg";
              } else {
                this.profileImg = ipaddress.Ip_with_img_address + obj.profile_image;
              }
              var img = document.getElementById("profile_img") as HTMLImageElement;
              img.src = ipaddress.Ip_with_img_address + obj.profile_image;
            }
            catch (error) {
            }
            try {
              var img1 = document.getElementById("profile_img1") as HTMLImageElement;
              img1.src = ipaddress.Ip_with_img_address + obj.profile_image;
            }
            catch (error) {
            }
          } else {
            this.loader = true;
            this.toastr.error(Message_data.getNetworkMessage());
          }
          if (obj.first_name != null) {
            this.firstName = obj.first_name;
          }
          if (obj.middle_name != null) {
            this.middleName = obj.middle_name;
          }
          if (obj.last_name != null) {
            this.lastName = obj.last_name;
          }
          if (obj.dob != null) {
            this.DOB = obj.dob;
          }
          if (obj.mobile != null) {
            this.mobile = encrypt_decript.Decript(obj.mobile);
          }
          if (obj.email != null) {
            this.email = encrypt_decript.Decript(obj.email);
          }
          if (obj.gender != null) {
            this.gender = obj.gender;
          }
          if (obj.registeration_code != null) {
            this.registerationCode = obj.registeration_code;
          }
          if (obj.registeration_year != null) {
            this.registerationYear = obj.registeration_year;
          }
          if (obj.home_care != null) {
            if (obj.home_care == "1") {
              this.toggHomeFlag = true;
              this.homeCareFlag = false;
            } else {
              this.toggHomeFlag = false;
              this.homeCareFlag = true;
            }
          }
          if (obj.registeration_state != null) {
            this.registerationState = obj.registeration_state;
          }
          if (obj.homecare_consult != null && obj.homecare_consult.length != 0) {
            for (var k = 0; k < obj.homecare_consult.length; k++) {
              this.homeCareConsultArray.push({
                day_session: obj.homecare_consult[k].day_session,
                session: Session_Return(obj.homecare_consult[k].day_session),
                available_from1: Time_Formate(Helper_Class.getProfile_info().homecare_consult[k].available_from),
                available_to1: Time_Formate(Helper_Class.getProfile_info().homecare_consult[k].available_to),
                available_from: Helper_Class.getProfile_info().homecare_consult[k].available_from,
                available_to: Helper_Class.getProfile_info().homecare_consult[k].available_to,
                home_care_fee: obj.homecare_consult[k].home_care_fee,
                homecare_consult_id: obj.homecare_consult[k].homecare_consult_id,
              })
            }
          }
          if (obj.home_address != null) {
            if (obj.home_address.length != 0) {
              for (var l = 0; l < obj.home_address.length; l++) {
                if (obj.home_address[l].type == "pres") {
                  this.presAddress1 = obj.home_address[l].address1;
                  this.presAddress2 = obj.home_address[l].address2;
                  this.presCity = obj.home_address[l].city_desc;
                  this.presLocationID = obj.home_address[l].location;
                  this.presCountry = obj.home_address[l].country_desc;
                  this.presLocation = obj.home_address[l].location_desc;
                  this.presState = obj.home_address[l].state_desc;
                  this.presTelephone = obj.home_address[l].telephone;
                  this.presZipcode = obj.home_address[l].zipcode;
                  this.getPresentCity(obj.home_address[l].location_desc);
                } else {
                  this.permAddress1 = obj.home_address[l].address1;
                  this.permAddress2 = obj.home_address[l].address2;
                  this.permCity = obj.home_address[l].city_desc;
                  this.permLocationID = obj.home_address[l].location;
                  this.permCountry = obj.home_address[l].country_desc;
                  this.permLocation = obj.home_address[l].location_desc;
                  this.permState = obj.home_address[l].state_desc;
                  this.permTelephone = obj.home_address[l].telephone;
                  this.permZipcode = obj.home_address[l].zipcode;
                  this.getPermanentCity(obj.home_address[l].location_desc);
                }
              }
              if (obj.home_address.length == 1) {
                this.sameasAbove = true;
                this.permFlag = true;
              }
            }
          }
          if (Helper_Class.getProfile_info() != null) {
            for (var i = 0; i < Helper_Class.getProfile_info().consultation.length; i++) {
              var token_name = undefined, time_dure = undefined;
              if (Helper_Class.getProfile_info().consultation[i].token != "0") {
                token_name = Helper_Class.getProfile_info().consultation[i].token;
                time_dure = "0";
              } else {
                token_name = "0";
                time_dure = Helper_Class.getProfile_info().consultation[i].app_duration;
              }
              this.workAarry.push({
                hospital_id: Helper_Class.getProfile_info().consultation[i].hospital_clinic_id,
                name: Helper_Class.getProfile_info().consultation[i].name,
                location: Helper_Class.getProfile_info().consultation[i].location,
                city: Helper_Class.getProfile_info().consultation[i].city,
                state: Helper_Class.getProfile_info().consultation[i].state,
                country: Helper_Class.getProfile_info().consultation[i].country,
                zipcode: Helper_Class.getProfile_info().consultation[i].zipcode,
                address1: Helper_Class.getProfile_info().consultation[i].address1,
                address2: Helper_Class.getProfile_info().consultation[i].address2,
                mobilenum: Helper_Class.getProfile_info().consultation[i].telephone,
                website: Helper_Class.getProfile_info().consultation[i].hospital_website,
                fee: Helper_Class.getProfile_info().consultation[i].fee,
                day_desc: Day_Return(Helper_Class.getProfile_info().consultation[i].day),
                day: Helper_Class.getProfile_info().consultation[i].day,
                session_desc: Session_Return(Helper_Class.getProfile_info().consultation[i].day_session),
                day_session: Helper_Class.getProfile_info().consultation[i].day_session,
                available_from: Time_Formate(Helper_Class.getProfile_info().consultation[i].available_from),
                available_to: Time_Formate(Helper_Class.getProfile_info().consultation[i].available_to),
                consultant: Helper_Class.getProfile_info().consultation[i].consultant,
                token: token_name,
                app_duration: time_dure,
                consult_time_id: Helper_Class.getProfile_info().consultation[i].doc_cons_time_id,
              })
            }
            if (this.workAarry.length != 0) {
              var work_arry1 = [];
              for (var i = 0; i < this.workAarry.length; i++) {
                if (this.workAarry[i].consult_time_id != "0") {
                  var consult_time_id = this.workAarry[i].consult_time_id;
                } else {
                  consult_time_id = "0";
                }
                work_arry1.push({
                  hospital_id: this.workAarry[i].hospital_id,
                  available_from: ConvertTimeformat("", this.workAarry[i].available_from),
                  available_to: ConvertTimeformat("", this.workAarry[i].available_to),
                  fee: this.workAarry[i].fee,
                  day: this.workAarry[i].day,
                  day_session: this.workAarry[i].day_session,
                  token: this.workAarry[i].token,
                  app_duration: this.workAarry[i].app_duration,
                  consultant: this.workAarry[i].consultant,
                  consultation_time_id: consult_time_id,
                  hosp_name: this.workAarry[i].name,
                  location: this.workAarry[i].location,
                });
              }
              Helper_Class.set_work_array(work_arry1);
              Helper_Class.set_work_add_array(work_arry1);
            }
            this.qualificationArray = [];
            for (var i = 0; i < obj.qualification.length; i++) {
              var clg_name = null;
              if (obj.qualification[i].institute != null) {
                clg_name = obj.qualification[i].institute;
              }
              else {
                clg_name = "-";
              }
              this.qualificationArray.push({
                degree: obj.qualification[i].degree_name,
                institute: clg_name,
                university: obj.qualification[i].university,
                year: obj.qualification[i].graduation_year,
              })
            }
            var qual_arr = null;
            qual_arr = {
              qualification: this.qualificationArray
            }
            Helper_Class.setprofile_qual_array(qual_arr);
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
  getPresentCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.presCityList = data.json().cities;
          this.selectPresCity = this.presCityList[0].city_id;
          this.filt_city_desc = this.presCityList[0].city_desc;
          this.presLocationID = this.presCityList[0].location_id;
          this.presZipcode = this.presCityList[0].zipcode;
          this.changeCity(this.presCityList[0].city_id);
        }
      },
      error => {
        this.toastr.error(Message_data.getNetworkMessage());
      })
  }
  changeCity(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null && data.json().states.length != 0) {
            this.presStateList = data.json().states;
            this.presState = this.presStateList[0].state_id;
            this.filtStateDesc = this.presStateList[0].state_desc;
            this.changeState(this.presStateList[0].state_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
    } else {
      this.presStateList = [];
      this.presCityList = [];
      this.presCountryList = [];
    }
  }
  changeState(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.presCountryList = data.json().countries;
            this.countryID = this.presCountryList[0].country_id;
            this.presCountry = this.presCountryList[0].country_id;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
    } else {
      this.presStateList = [];
      this.presCountryList = [];
    }
  }
  getPermanentCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.permCityList = data.json().cities;
          this.permCity = this.permCityList[0].city_id;
          this.filt_city_desc = this.permCityList[0].city_desc;
          this.permLocationID = this.permCityList[0].location_id;
          this.permZipcode = this.permCityList[0].zipcode;
          this.changePermCity(this.permCityList[0].city_id);
        }
      },
      error => {
        this.toastr.error(Message_data.getNetworkMessage());
      })
  }
  changePermCity(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null && data.json().states.length != 0) {
            this.permStateList = data.json().states;
            this.permState = this.permStateList[0].state_id;
            this.filtStateDesc = this.permStateList[0].state_desc;
            this.changePermanentState(this.permStateList[0].state_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
    } else {
      this.permStateList = [];
      this.permCityList = [];
      this.permCountryList = [];
    }
  }
  changePermanentState(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.permCountryList = data.json().countries;
            this.countryID = this.permCountryList[0].country_id;
            this.permCountry = this.permCountryList[0].country_id;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
    } else {
      this.permStateList = [];
      this.permCountryList = [];
    }
  }
  clickUpdate() {
    this.profileInformation();
  }
  initateToUpdate() {
    this.profileTab = Helper_Class.getprofile_array();
    this.profileWorkTab = Helper_Class.get_work_array();
    this.profileWorkAdd = Helper_Class.get_work_add_array();
    this.profileQual = Helper_Class.getprofile_qual_array();
    var homecareArray = [];
    if (this.profileTab.upd_url == "1") {
      this.sendURL = "usercontroller/updprovweb/";
    }
    var x = encrypt_decript.Decript(this.profileTab.email).toString();
    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
    if (!re.test(x) || encrypt_decript.Decript(this.profileTab.email).toString().trim() == "") {
      this.toastr.error(Message_data.validEmail);
    } else if (encrypt_decript.Decript(this.profileTab.mobile).toString().trim() == "" || encrypt_decript.Decript(this.profileTab.mobile).length != 10) {
      this.toastr.error(Message_data.validMobileNo);
    } else if (this.profileTab.home_care == "1" && this.profileTab.homecare_val == false) {
      this.toastr.error(Message_data.enterHomeCareMandatory);
    } else if (this.profileTab.home_care == "1" && this.profileTab.homecare_val == true && homecareArray.length == 0) {
      this.toastr.error(Message_data.addHomeCareConsult);
    } else if (this.profileWorkTab == undefined || this.profileWorkTab.length == 0) {
      this.toastr.error(Message_data.verifyWrkLocation);
    } else if (this.profileQual == undefined || this.profileQual.length == 0) {
      this.toastr.error(Message_data.verfiyQualf);
    } else if (this.profileTab.mobile != Helper_Class.getProfile_info().mobile) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
        JSON.stringify({
          mobile: this.mobileNo,
          flag: "1"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true) {
              this.toastr.error(Message_data.existMobileNo);
            } else {
              this.emailCheck();
            }
          },
          error => {
          }
        )
    } else {
      this.emailCheck();
    }
  }
  emailCheck() {
    if (this.profileTab.email != Helper_Class.getProfile_info().email) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
        JSON.stringify({
          email: this.emailID,
          flag: "1"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true) {
              this.toastr.error(Message_data.existEmailID);
            } else {
              this.finalUpdate();
            }
          },
          error => {
          }
        )
    } else {
      this.finalUpdate();
    }
  }
  finalUpdate() {
    var send_work = null;
    send_work = {
      consultation: this.profileWorkTab
    }
    var finalObj = $.extend(true, this.profileTab, send_work);
    var finalObj1 = $.extend(true, finalObj, this.profileQual);
    var output = null;
    output = finalObj1;
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_work_add_array();
    if (getfoot_ass != undefined && getfoot_ass.length != 0) {
      send_foot = {
        address: getfoot_ass,
      }
      output = $.extend(true, finalObj1, send_foot);
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.sendURL, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null && obj.key == "1") {
            this.toastr.success(Message_data.profileUpdSuccess);
            this.router.navigateByUrl("/loginpage");
          } else {
            this.toastr.error(Message_data.profileUpdFailed);
          }
        },
        error => {
        }
      )
  }
  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: "diagnosis",
        user: this.userinfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },
        error => {
          this.toastr.error(Message_data.network);
        }
      )
  }
  clickQualification() {
    if (this.qualificationDegree == "" || this.qualificationUni == "" || this.qualificationYear == "") {
      this.toastr.error(Message_data.mandatory);
    } else {
      this.qualificationArray.push({
        degree: this.qualificationDegree,
        institute: this.qualificationClg,
        university: this.qualificationUni,
        year: this.qualificationYear,
      })
      this.qualificationDegree = "";
      this.qualificationUni = "";
      this.qualificationYear = "";
      this.qualificationClg = "";
    }
  }
  quaDelete(degree_da, year_da) {
    for (var i = 0; i < this.qualificationArray.length; i++) {
      if (degree_da == this.qualificationArray[i].degree) {
        this.qualificationArray.splice(i, 1);
      }
    }
  }
  appToken() {
    this.count = '';
    if (this.appTokenFlag == true) {
      this.appTimeFalg = false;
      this.appType = "Count";
    }
  }
  appTime() {
    this.count = '';
    if (this.appTimeFalg == true) {
      this.appTokenFlag = false;
      this.appType = "Minutes";
    }
  }
  deleteWork(id, session, end_time) {
    for (var i = 0; i < this.workAarry.length; i++) {
      if (id == this.workAarry[i].consult_time_id
        && session == this.workAarry[i].session_desc
        && end_time == this.workAarry[i].available_to) {
        this.workAarry.splice(i, 1);
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cancelWork, JSON.stringify({
      cons_time_id: id,
      flag: "nurse"
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().key !== null && data.json().key == "1") {
          this.toastr.error(Message_data.deleteWorkLoca);
        } else {
          this.toastr.error(Message_data.unDeleteWorkLoca);
        }
      }, error => {
      }
    );
  }
  hospitalFilter() {
    this.cityArray = [];
    this.stateArray = [];
    this.countryArray = [];
    this.filteredlocListArray = [];
    this.locationListArray = [];
    this.hospitalLocation = '';
    this.hospDist = '';
    this.hospState = '';
    this.hospCountry = '';
    this.hospZIp = '';
    this.hospitalAddress1 = '';
    this.hospitalAddress2 = '';
    this.hospitalContactNumber = '';
    this.hospitalWebsite = '';
    this.hospitalArray = [];
    this.hospitalFilterListArray = [];
    var tmp_arr = [];
    if (this.hospitalName !== "" && this.hospitalName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hospitalName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.hospitals != null) {
              this.filteredhospList = [];
              this.hospitalFilterListArray = obj.hospitals;
              for (var i = 0; i < this.hospitalFilterListArray.length; i++) {
                this.hospitalArray.push(this.hospitalFilterListArray[i].hosp_name);
              }
              this.filteredhospList = this.hospitalArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hosp_name_txt.toLowerCase()) > -1;
              }.bind(this));
              if (this.hospitalArray.length == 0) {
                this.toastr.error(Message_data.noHosptlFound);
              }
            } else {
              this.toastr.error(Message_data.noHosptlFound);
            }
          },
          error => {
          }
        )
    } else {
      this.filteredhospList = [];
    }
  }
  getLocationBasedonhospital() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/hloc/',
      JSON.stringify({
        hosp_name: this.hospitalName
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hosp_locations != null) {
            this.locationListArray = obj.hosp_locations;
          }
        },
        error => {
        }
      )
  }
  selectHospital(data) {
    this.hospitalName = data;
    this.filteredhospList = [];
    if (this.hospitalName != undefined) {
      this.getLocationBasedonhospital();
    }
  }
  changeLoc() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/haddr/',
      JSON.stringify({
        hosp_name: this.hospitalName,
        location: this.hospitalLocation
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hptl_clinic_id != null) {
            this.hospitalID = obj.hptl_clinic_id;
            this.hospitalAddress1 = obj.address1;
            this.hospitalAddress2 = obj.address2;
            this.hospDist = obj.city;
            this.hospState = obj.state;
            this.hospCountry = obj.country;
            this.hospitalContactNumber = obj.telephone;
            this.hospitalWebsite = obj.website;
            this.hospZIp = obj.zipcode;
          }
        },
        error => {
        }
      )
  }
  fileChange($event): void {
    this.readThis($event.target);
  }
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.imgString = null;
      } else {
        this.imgString = myReader.result.toString();
        $('#profile_img').attr('src', myReader.result.toString());
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }
  locfilter() {
    this.hospDist = '';
    this.hospState = '';
    this.hospCountry = '';
    this.hospZIp = '';
    this.hospitalAddress1 = '';
    this.hospitalAddress2 = '';
    this.hospitalContactNumber = '';
    this.hospitalWebsite = '';
    this.hospitalID = "0";
    this.locationFilterArray = [];
    if (this.hospitalLocation !== "" && this.hospitalLocation.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.hospitalLocation
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              this.locationFilterArray = obj.locations;
              this.filteredlocListArray = this.locationFilterArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hosp_loc_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => {
          }
        )
    } else {
      this.filteredlocListArray = [];
    }
  }
  select_location(data) {
    this.hospitalLocation = data;
    this.filteredlocListArray = [];
    this.stateArray = [];
    this.cityArray = [];
    this.countryArray = [];
    this.getCity(data);
  }
  getCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.cityArray = data.json().cities;
          this.hospDist = this.cityArray[0].city_id;
          this.filt_city_desc = this.cityArray[0].city_desc;
          this.locationArray = this.cityArray[0].location_id;
          this.hospZIp = this.cityArray[0].zipcode;
          this.cityChange(this.cityArray[0].city_id);
        }
      },
      error => {
      })
  }
  cityChange(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.stateArray = data.json().states;
            this.hospState = this.stateArray[0].state_id;
            this.filtStateDesc = this.stateArray[0].state_desc;
            this.stateChange(this.stateArray[0].state_id);
          }
        },
        error => {
        })
    } else {
      this.stateArray = [];
      this.cityArray = [];
      this.countryArray = [];
    }
  }
  stateChange(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != null) {
            this.countryArray = data.json().countries;
            this.countryID = this.countryArray[0].country_id;
            this.hospCountry = this.countryArray[0].country_desc;
            this.changeSerCountry();
          }
        },
        error => {
        })
    } else {
      this.stateArray = [];
      this.countryArray = [];
    }
  }
  changeSerCountry() {
    this.hospitalID = "0";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospdetails/',
      JSON.stringify({
        hosp_name: this.hospitalName,
        location: this.hospitalLocation,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hosp_id != null) {
            this.hospitalID = obj.hosp_id;
            this.hospitalAddress1 = obj.address1;
            this.hospitalAddress2 = obj.address2;
            if (obj.telephone != null) {
              this.hospitalContactNumber = obj.telephone;
            }
            if (obj.website != null) {
              this.hospitalWebsite = obj.website;
            }
          }
        },
        error => {
        }
      )
  }
  clickWork() {
    var cosult_id = null;
    if (this.hopsConsult == true) {
      cosult_id = 1;
    } else {
      cosult_id = 0;
    }
    var token_name = "0", time_dure = "0";
    if (this.appTokenFlag == true) {
      token_name = this.count;
      time_dure = "0";
    } else {
      token_name = "0";
      time_dure = this.count;
    }
    var fee = 0;
    if (this.hospFees != "") {
      fee = parseInt(this.hospFees);
    }
    if (this.hospitalName == "" || this.hospitalLocation == "" ||
      this.hospDist == undefined || this.hospState == "" || this.hospCountry == "" ||
      this.hospZIp == "" || this.hospitalAddress1 == "" || this.hospitalContactNumber == "" ||
      this.hospDays == "" || this.hospSession == "") {
      this.toastr.error(Message_data.mandatory);
    } else {
      var f_time = ConvertTimeformat("", this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3);
      var t_time = ConvertTimeformat("", this.endAt1 + ":" + this.endAt2 + " " + this.endAt3);
      var ret_value = session_based_time_fun(f_time, t_time, this.hospSession);
      if (ret_value == true) {
        this.workAarry.push({
          hospital_id: this.hospitalID,
          name: this.hospitalName,
          location: this.hospitalLocation,
          city: this.hospDist,
          state: this.hospState,
          country: this.hospCountry,
          zipcode: this.hospZIp,
          address1: this.hospitalAddress1,
          address2: this.hospitalAddress2,
          mobilenum: this.hospitalContactNumber,
          website: this.hospitalWebsite,
          fee: fee,
          day_desc: this.hospDays,
          day: day_nameid(this.hospDays),
          session_desc: this.hospSession,
          day_session: seesion_nameid(this.hospSession),
          available_from: this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3,
          available_to: this.endAt1 + ":" + this.endAt2 + " " + this.endAt3,
          consultant: cosult_id,
          appointment: this.appTokenFlag + "" + this.appTimeFalg,
          token: token_name,
          app_duration: time_dure,
          consult_time_id: "0"
        })
        this.hospitalName = "";
        this.hospitalLocation = "";
        this.hospDist = "";
        this.hospState = "";
        this.hospCountry = "";
        this.hospZIp = "";
        this.hospitalAddress1 = "",
          this.hospitalAddress2 = "";
        this.hospitalContactNumber = "";
        this.hospitalWebsite = '';
        this.hospFees = "";
        this.hospDays = "";
        this.hospSession = "";
        this.hopsConsult = false;
        this.count = "";
        this.startFrom1 = "06";
        this.startFrom2 = "00";
        this.startFrom3 = "AM";
        this.endAt1 = "08";
        this.endAt2 = "00";
        this.endAt3 = "AM";
      } else {
        this.toastr.error(Message_data.invalidTime);
      }
    }
  }
}
