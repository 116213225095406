<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Prescriptions</h5>
                    </div>
                    <div class="headerButtons">
                        <a *ngIf="showdetails_flag" (click)="backClicked()"><img
                                src="../../../assets/ui_icons/buttons/Back_button.svg"
                                class="saveimgbtn_inpatinfo" /></a>

                        <a *ngIf="!showdetails_flag" style="margin-right: 10px;" (click)="print_area()">
                            <img style="width: 30px;" src="../../../assets/img/printer.svg" /></a>

                        <a *ngIf="!showdetails_flag" (click)="backToList()">
                            <img src="../../../assets/ui_icons/buttons/Back_button.svg"
                                class="saveimgbtn_inpatinfo" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="Details" [hidden]="!showdetails_flag"
                    style="padding:10px 32px; min-height: 200px !important; width:100% !important">
                    <div class="row">
                        <p *ngIf="med_pres_list.length == 0" class="nodata">No prescription details found</p>
                        <div class="dig_table_overflow" *ngIf="med_pres_list.length != 0">
                            <div class="table-responsive">
                                <table id="tbl" class="table table-hover table-dynamic">
                                    <thead>
                                        <tr>
                                            <th style="padding-left: 8px !important;width: 100px;">Date</th>
                                            <th>Prescription</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let prescription of med_pres_list">
                                            <td style="font-size: 11px !important;">
                                                {{prescription.date}}</td>
                                            <td style="font-size: 11px !important;">{{"Dr. " + prescription.doctorname}}
                                                prescribed
                                                for {{prescription.disease}}</td>
                                            <td>
                                                <img src="../../../assets/ui_icons/search_icon.svg" width="20px"
                                                    (click)="getPresDetails(prescription)" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="showdetails_flag"
                    style="padding:10px 32px; min-height: 800px !important; width:100% !important">
                    <div class="row">
                        <div class="row">
                            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8" *ngIf="!hospitalFlag">
                                <div class="cover_div" style="padding-bottom: 13px;">
                                    <div class="header_lable">
                                        Hospital details
                                    </div>
                                    <div class="content_cover">
                                        <div class="row">
                                            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                                <div class="">
                                                    <img alt="image" src={{hospLogo}} class="hosp_image_class">
                                                </div>
                                                <mat-label class="matlabel"><b>{{hptlName}}</b></mat-label><br>
                                                <mat-label class="matlabel">{{address}}</mat-label><br>
                                                <mat-label
                                                    class="matlabel">{{doctorLocation}},{{doctorCity}}</mat-label><br>
                                                <mat-label
                                                    class="matlabel">{{doctorState}},{{doctorCountry}}</mat-label><br>
                                                <mat-label class="matlabel">{{doctorTelephone }}</mat-label><br>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel"><b>{{'Doctor'}} : {{doctorName}}
                                                        {{doctorQualification}}</b> <br>
                                                </mat-label>
                                                <mat-label class="matlabel"><b>{{'Date'}} :
                                                    </b>{{prescriptionDate}}</mat-label><br>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <div class="cover_div" style="padding-bottom: 13px;">
                                    <div class="header_lable">
                                        Patient details
                                    </div>
                                    <div class="content_cover">
                                        <mat-label class="matlabel"><b>{{'Name'}}:</b>{{patientName}}</mat-label><br>
                                        <mat-label class="matlabel"><b>{{'Gender'}} :</b> {{gender}}</mat-label><br>
                                        <mat-label class="matlabel"><b>{{'Age'}} :</b> {{age}}</mat-label><br>
                                        <mat-label class="matlabel" *ngIf="bpFlag"><b>{{'BP'}}:</b> {{clntBp}}
                                            &nbsp;&nbsp;&nbsp;{{'Pulse'}}:
                                            {{clntPulse}} <br></mat-label>
                                        <mat-label class="matlabel" *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}}
                                            &nbsp;&nbsp;&nbsp;<span *ngIf="weightFlag"><b>{{'Weight'}}:
                                                </b>{{weight}}</span>
                                        </mat-label><br>
                                        <mat-label class="matlabel" *ngIf="temparatureFlag"><b>{{'Temperature'}}:
                                            </b>{{temparature}}
                                        </mat-label>
                                        <mat-label class="matlabel" *ngIf="orthoFlag"><b>{{'CVS'}}:</b> {{clntCvs}}
                                            &nbsp;&nbsp;&nbsp;
                                            <b>{{'RS'}}:</b>
                                            {{clntRs}} &nbsp;&nbsp;&nbsp;<b>{{'Kidney'}}:</b>
                                            {{clntKidney}}<br></mat-label>
                                        <mat-label class="matlabel" *ngIf="orthoFlag"></mat-label>
                                        <mat-label class="matlabel" *ngIf="pulmFlag"><b>{{'PFT'}}:
                                                {{pft}}</b></mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-left: 10px;margin-top: 5px;" *ngIf="complaints!=undefined">
                            <mat-label class="matlabel"><b>{{'Complaints'}} :</b> {{complaints}}<br>
                            </mat-label>
                        </div>
                        <div style="margin-left: 10px;margin-top: 5px;" *ngIf="investigation!=undefined">
                          <mat-label class="matlabel"><b>{{'Investigation'}} :</b> {{investigation}}<br>
                          </mat-label>
                      </div>
                      
                        <div style="margin-left: 10px;" *ngIf="diseaseName!=undefined">
                            <mat-label class="matlabel"><b>{{'Diagnosis' }} :</b> {{diseaseName}} {{diseaseDescription}}
                                <br>
                            </mat-label>
                        </div>
                        <div *ngIf="listProducts.length !=0" class="medicine_details">
                            <div class="table-responsive dig_table_overflow">
                                <table *ngIf="listProducts.length"
                                    class="table table-nowrap table-sm dataTable billlisttable">
                                    <thead>
                                        <tr>
                                            <th>{{'Medicine type'}}</th>
                                            <th>{{'Medicine name'}}</th>
                                            <th>{{'Duration'}}</th>
                                            <th>{{'Intake session'}}</th>
                                            <th>{{'Frequency'}}</th>
                                            <th>{{'Intake'}}</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let product of listProducts; let i = index">
                                            <td style="font-size: 12px;text-align: left;">{{ product.med_typetxt }}</td>
                                            <td style="font-size: 12px;text-align: left;">{{ product.drug_name }}</td>
                                            <td style="font-size: 12px;">{{product.days}} {{product.period }}</td>
                                            <td style="font-size: 12px;">{{product.every_six}}</td>
                                            <td style="font-size: 12px;">{{product.frequency }}</td>
                                            <td style="font-size: 12px;text-align: left;">{{product.dure_txt_table}}
                                                ({{product.drug_intake }})
                                            </td>
                                            <td style="font-size: 12px;text-align: left;">{{product.remarks }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style="margin-left: 10px;margin-top:5px;" [hidden]="instructionFlag">
                                <mat-label class="matlabel"><b>{{'Instructions' }}: </b></mat-label>
                                <div class="matlabel" [innerHtml]="instructions"></div>
                            </div>
                            <div style="margin-left: 10px;" *ngIf="nextVisit!=undefined">
                                <mat-label class="matlabel"><b>{{'Next visit' }}: </b>{{nextVisit}}</mat-label>
                            </div>
                        </div>
                        <div *ngIf="pharmacyFlag">
                            <div *ngFor="let pharma of pharmacyDetailList" class="row">
                                <div class="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2" style="position: relative;">
                                    <img alt="image" src="{{pharma.phar_logo}}" class="hosp_image_class"
                                        style="position: absolute;top: 13px;left: 51px;margin: 0;"><br>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <mat-label class="matlabel"><b>{{pharma.pharmacy_name}}</b></mat-label><br>
                                    <mat-label class="matlabel">{{pharma.phar_addr1}},
                                        {{pharma.phar_addr2}}</mat-label><br>
                                    <mat-label class="matlabel">{{pharma.phar_loc}}</mat-label><br>
                                    <mat-label class="matlabel">{{pharma.phar_city}} -
                                        {{pharma.phar_zipcode}}</mat-label><br>
                                    <mat-label class="matlabel">{{pharma.phar_state}},
                                        {{pharma.phar_cnty}}</mat-label><br>
                                    <mat-label class="matlabel">{{pharma.telephone}}</mat-label><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div [hidden]="true">
                    <div #printlogowithname id="printlogowithname">
                      <table style="margin-left:50px;margin-top:70px;">
                        <tr>
                          <td style="width: 450px; vertical-align: top;">
                            <!-- width="100px" height="100px" -->
                            <img alt="image" src={{hospLogo}} [style]="preslogo_style"
                              onerror="this.src='./././assets/img/HospitalIcon.png'">
                            <p style="margin-top: -100px;margin-left: 110px;">{{hptlName}}</p>
                            <p style="margin-left: 110px;">{{address}},{{doctorLocation}}</p>
                            <p style="margin-left: 110px;">{{doctorCity}}</p>
                            <p style="margin-left: 110px;">{{doctorState}},{{doctorCountry}}</p>
                            <p style="margin-left: 110px;">{{doctorTelephone}}</p>
                          </td>
                          <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                          <td style="width: 300px;vertical-align: top;">
                            <p><b>{{'Doctor'}} : {{doctorName}} {{doctorQualification}}</b></p>
                            <p><b>{{'Date'}} : </b>{{prescriptionDate}}</p>
                          </td>
                        </tr>
                      </table>
                      <br /><br />
                      <table style="margin-left: 50px;">
                        <tr>
                          <td style="width: 450px;">
                            <p><b>{{'Name'}} :</b> {{patientName}}</p>
                            <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                            <p><b>{{'Age'}} :</b> {{age}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'}}:
                              </b>{{gender}} </p>
                          </td>
                          <td>
                            <p *ngIf="bpFlag">{{'BP'}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'}}: {{clntPulse}}</p>
                            <p *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<b>{{'Weight'}}: </b>
                              {{weight}}</p>
                            <p *ngIf="temparatureFlag"><b>{{'Temperature'}}: </b>{{temparature}} </p>
                            <p *ngIf="orthoFlag">{{'CVS'}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'}}: {{clntRs}}</p>
                            <p *ngIf="orthoFlag">{{'Kidney'}}: {{clntKidney}}</p>
                            <p *ngIf="pulmFlag">{{'PFT'}}: {{pft}}</p>
                          </td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;" *ngIf="complaints != undefined">
                        <p><b>Complaints: </b>{{complaints}}</p>
                     </div>
                     
                     <div style="margin-left: 50px;" *ngIf="investigation != undefined">
                        <p><b>Investigation: </b>{{investigation}}</p> 
                     </div>
                   
                     <div style="margin-left: 50px;" *ngIf="diseaseName != undefined">
                        <p><b>{{'Diagnosis' |translate}}: </b>{{diseaseName}}</p> 
                     </div>
                      <br />
                      <table style="margin-left: 20px; border: 1px solid black;border-collapse: collapse; width: 95%;" >
                        <tr *ngIf="medstyle">
                          <th style="width: 275px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle1">
                          <th style="width: 250px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle2">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle3">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngFor="let product of listProducts">
                          <td style="border: 1px solid black;border-collapse: collapse;">
                            {{product.short_name}} {{product.drug_name}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.every_six}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                            {{product.period}}</td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.frequency}}
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                            <table>
                              <tr>
                                <td [hidden]="dur_time_flag">{{product.dure_txt_table}} - </td>
                                <td [hidden]="dur_flag">{{product.drug_intake}}</td>
                              </tr>
                            </table>
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;">
                        <p *ngIf="instructions!=undefined"><b>{{'Instructions' }}: </b>
                          <span>
                            <div class="matlabel" [innerHtml]="instructions"></div>
                          </span>
                        </p>
                      </div>
                      <br />
                      <div style="margin-left: 50px;" *ngIf="treatFlag">
                        <p *ngIf="treatment!=undefined"><b>{{'Treatment' }}: </b>
                          <span>
                            <div class="matlabel" [innerHtml]="treatment"></div>
                          </span>
                        </p>
                      </div>
                      <div style="margin-left: 50px;">
                        <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
                      </div>
                      <br />
                      <div>
                        <p style="margin-left: 50px;font-size: 13px;"> <strong>{{'Dr '+doctorName}}</strong> </p>
                        <p style="margin-left: 50px;font-size: 13px">({{licenceCode}})</p>
                        <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                        <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
                        <br />
                      </div>
                    </div>
                    <div #printbanner id="printbanner">
                      <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                        <tr>
                          <td>
                            <!-- width="750px" height="200px" -->
                            <img alt="image" src={{hptlPresLogo}} [style]="preslogo_style" >
                          </td>
                        </tr>
                      </table>
                      <br /><br />
                      <table style="margin-left: 50px; margin-top:-20px;">
                        <tr>
                          <td style="width: 500px;">
                            <p><b>{{'Name'}} :</b> {{patientName}}</p>
                            <p style="margin-top: -5px;"><b>{{'Mobile'}} :</b> {{mobile}}</p>
                            <p style="margin-top: -5px;"><b>{{'Age'}} :</b> {{age}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'}}:
                              </b>{{gender}} </p>
                          </td>
                          <td style="width: 300px;vertical-align: top;">
                            <p ><b>{{'Date'}} : </b>{{prescriptionDate}}</p>
                            <p style="margin-top: -5px;" *ngIf="bpFlag">{{'BP'}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'}}: {{clntPulse}}</p>
                            <p style="margin-top: -5px;" *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}} &nbsp;&nbsp;&nbsp;{{'Weight'}}:
                              {{weight}}</p>
                            <p style="margin-top: -5px;" *ngIf="temparatureFlag"><b>{{'Temperature'}}: </b>{{temparature}} </p>
                            <p style="margin-top: -5px;" *ngIf="orthoFlag">{{'CVS'}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'}}: {{clntRs}}</p>
                            <p style="margin-top: -5px;" *ngIf="orthoFlag">{{'Kidney'}}: {{clntKidney}}</p>
                            <p style="margin-top: -5px;" *ngIf="pulmFlag">{{'PFT'}}: {{pft}}</p>
                          </td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;" *ngIf="complaints != undefined">
                        <p><b>Complaints: </b>{{complaints}}</p>
                     </div>
                     
                     <div style="margin-left: 50px;" *ngIf="investigation != undefined">
                        <p><b>Investigation: </b>{{investigation}}</p> 
                     </div>
                   
                     <div style="margin-left: 50px;" *ngIf="diseaseName != undefined">
                        <p><b>{{'Diagnosis' |translate}}: </b>{{diseaseName}}</p> 
                     </div>
                      <br />
                      <table style="margin-left: auto;margin-right: auto; border: 1px solid black;border-collapse: collapse; width: 88%;" >
                        <tr *ngIf="medstyle">
                          <th style="width: 275px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle1">
                          <th style="width: 250px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle2">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle3">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngFor="let product of listProducts">
                          <td style="border: 1px solid black;border-collapse: collapse;">
                            {{product.short_name}} {{product.drug_name}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.every_six}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                            {{product.period}}</td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.frequency}}
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                            <table>
                              <tr>
                                <td [hidden]="dur_time_flag">{{product.dure_txt_table}} - </td>
                                <td [hidden]="dur_flag">{{product.drug_intake}}</td>
                              </tr>
                            </table>
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;">
                        <p *ngIf="instructions!=undefined"><b>{{'Instructions' }}: </b>
                          <span>
                            <div style="margin-top: -10px;" class="matlabel" [innerHtml]="instructions"></div>
                          </span>
                        </p>
                      </div>
                      <div style="margin-left: 50px;" *ngIf="treatFlag">
                        <p *ngIf="treatment!=undefined"><b>{{'Treatment' }}: </b>
                          <span>
                            <div style="margin-top: -10px;" class="matlabel" [innerHtml]="treatment"></div>
                          </span>
                        </p>
                      </div>
                      <div style="margin-left: 50px;margin-top: -5px;">
                        <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
                      </div>
                      <br />
                      <div>
                        <p style="margin-left: 50px;font-size: 13px;"> <strong>{{'Dr '+doctorName}} ({{licenceCode}})</strong> </p>
                        <!-- <p style="margin-left: 50px;font-size: 13px"></p> -->
                        <p style="margin-left: 50px;font-size: 13px;margin-top: -5px;">Find your prescription online at <i>www.tervys.com</i></p>
                        <p style="margin-left: 50px;font-size: 13px;margin-top: -5px;"><b><i>This is digitally generated prescription</i></b></p>
                        <br />
                      </div>
                    </div>
                    <div #printbannerfullpage id="printbannerfullpage">
                      <table style="margin-left:10px;margin-right: 10px;">
                        <tr>
                          <td>
                            <!-- width="750px" height="200px" -->
                            <img alt="image" src={{hptlPresLogo}} [style]="preslogo_style" >
                          </td>
                        </tr>
                      </table>
                      <br /><br />
                      <table style="margin-left: 50px; ">
                        <tr>
                          <td style="width: 500px;">
                            <p><b>{{'Name'}} :</b> {{patientName}}</p>
                            <p style="margin-top: -5px;"><b>{{'Mobile'}} :</b> {{mobile}}</p>
                            <p style="margin-top: -5px;"><b>{{'Age'}} :</b> {{age}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'}}:
                              </b>{{gender}} </p>
                          </td>
                          <td style="width: 300px;vertical-align: top;">
                            <p ><b>{{'Date'}} : </b>{{prescriptionDate}}</p>
                            <p style="margin-top: -5px;" *ngIf="bpFlag">{{'BP'}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'}}: {{clntPulse}}</p>
                            <p style="margin-top: -5px;" *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}} &nbsp;&nbsp;&nbsp;{{'Weight'}}:
                              {{weight}}</p>
                            <p style="margin-top: -5px;" *ngIf="temparatureFlag"><b>{{'Temperature'}}: </b>{{temparature}} </p>
                            <p style="margin-top: -5px;" *ngIf="orthoFlag">{{'CVS'}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'}}: {{clntRs}}</p>
                            <p style="margin-top: -5px;" *ngIf="orthoFlag">{{'Kidney'}}: {{clntKidney}}</p>
                            <p style="margin-top: -5px;" *ngIf="pulmFlag">{{'PFT'}}: {{pft}}</p>
                          </td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;" *ngIf="complaints != undefined">
                        <p><b>Complaints: </b>{{complaints}}</p>
                     </div>
                     
                     <div style="margin-left: 50px;" *ngIf="investigation != undefined">
                        <p><b>Investigation: </b>{{investigation}}</p> 
                     </div>
                   
                     <div style="margin-left: 50px;" *ngIf="diseaseName != undefined">
                        <p><b>{{'Diagnosis' |translate}}: </b>{{diseaseName}}</p> 
                     </div>
                      <br />
                      <table style="margin-left: auto;margin-right: auto; border: 1px solid black;border-collapse: collapse; width: 88%;" >
                        <tr *ngIf="medstyle">
                          <th style="width: 275px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle1">
                          <th style="width: 250px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle2">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle3">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngFor="let product of listProducts">
                          <td style="border: 1px solid black;border-collapse: collapse;">
                            {{product.short_name}} {{product.drug_name}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.every_six}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                            {{product.period}}</td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.frequency}}
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                            <table>
                              <tr>
                                <td [hidden]="dur_time_flag">{{product.dure_txt_table}} - </td>
                                <td [hidden]="dur_flag">{{product.drug_intake}}</td>
                              </tr>
                            </table>
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;">
                        <p *ngIf="instructions!=undefined"><b>{{'Instructions' }}: </b>
                          <span>
                            <div style="margin-top: -10px;" class="matlabel" [innerHtml]="instructions"></div>
                          </span>
                        </p>
                      </div>
                      <div style="margin-left: 50px;" *ngIf="treatFlag">
                        <p *ngIf="treatment!=undefined"><b>{{'Treatment' }}: </b>
                          <span>
                            <div style="margin-top: -10px;" class="matlabel" [innerHtml]="treatment"></div>
                          </span>
                        </p>
                      </div>
                      <div style="margin-left: 50px;margin-top: -5px;">
                        <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
                      </div>
                      <br />
                      <div style="margin-left: 300px;margin-top:100;text-align: center;" >
                        <p style="font-size: 13px;"> <strong>{{"Dr."+doctorName}}</strong>
                        </p>
                        <p style="font-size: 13px;">({{licenceCode}})</p>
                        
                     </div>
                     <div style="margin-left: 50px;margin-top:50px;">
                        <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                        </p>
                        <p style="margin-left: 50px;font-size: 13px;margin-top:50px;"><b><i>This is digitally generated prescription</i></b>
                        </p>
                        <br />
                     </div>
                    </div>
                    <div #printnoheader id="printnoheader">
                      <table [style]="preslogo_style">
                        <tr>
                          <td style="width: 500px;">
                            <p><b>{{'Name'}} :</b> {{patientName}}</p>
                            <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                            <p><b>{{'Age'}} :</b> {{age}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'}}:
                              </b>{{gender}} </p>
                          </td>
                          <td style="width: 300px;vertical-align: top;">
                            <p><b>{{'Date'}} : </b>{{prescriptionDate}}</p>
                            <p *ngIf="bpFlag">{{'BP'}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'}}: {{clntPulse}}</p>
                            <p *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}} &nbsp;&nbsp;&nbsp;{{'Weight'}}:
                              {{weight}}</p>
                            <p *ngIf="temparatureFlag"><b>{{'Temperature'}}: </b>{{temparature}} </p>
                            <p *ngIf="orthoFlag">{{'CVS'}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'}}: {{clntRs}}</p>
                            <p *ngIf="orthoFlag">{{'Kidney'}}: {{clntKidney}}</p>
                            <p *ngIf="pulmFlag">{{'PFT'}}: {{pft}}</p>
                          </td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;" *ngIf="complaints != undefined">
                        <p><b>Complaints: </b>{{complaints}}</p>
                     </div>
                     
                     <div style="margin-left: 50px;" *ngIf="investigation != undefined">
                        <p><b>Investigation: </b>{{investigation}}</p> 
                     </div>
                   
                     <div style="margin-left: 50px;" *ngIf="diseaseName != undefined">
                        <p><b>{{'Diagnosis' |translate}}: </b>{{diseaseName}}</p> 
                     </div>
                      <br />
                      <table style="margin-left: 20px; border: 1px solid black;border-collapse: collapse; width: 95%;" >
                        <tr *ngIf="medstyle">
                          <th style="width: 275px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle1">
                          <th style="width: 250px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle2">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle3">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngFor="let product of listProducts">
                          <td style="border: 1px solid black;border-collapse: collapse;">
                            {{product.short_name}} {{product.drug_name}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.every_six}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                            {{product.period}}</td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.frequency}}
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                            <table>
                              <tr>
                                <td [hidden]="dur_time_flag">{{product.dure_txt_table}} - </td>
                                <td [hidden]="dur_flag">{{product.drug_intake}}</td>
                              </tr>
                            </table>
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;">
                        <p *ngIf="instructions!=undefined"><b>{{'Instructions' }}: </b>
                          <span>
                            <div class="matlabel" [innerHtml]="instructions"></div>
                          </span>
                        </p>
                      </div>
                      <br />
                      <div style="margin-left: 50px;" *ngIf="treatFlag">
                        <p *ngIf="treatment!=undefined"><b>{{'Treatment' }}: </b>
                          <span>
                            <div class="matlabel" [innerHtml]="treatment"></div>
                          </span>
                        </p>
                      </div>
                      <div style="margin-left: 50px;">
                        <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
                      </div>
                      <br />
                      <div>
                        <p style="margin-left: 50px;font-size: 13px;"> <strong>{{'Dr '+doctorName}}</strong> </p>
                        <p style="margin-left: 50px;font-size: 13px">({{licenceCode}})</p>
                        <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                        <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
                        <br />
                      </div>
                    </div>
                    <div #fourcolumnnoheader id="fourcolumnnoheader">
                      <table style="border: 1px solid #403f3f;margin-left: 10px !important;margin-top: 200px !important;width:97%">
                        <tr>
                          <td>{{'UHID'}}:{{clntUHID}} </td>
                          <td colspan="2">{{'Name'}} : {{patientName}}</td>
                          <td>{{'Age'}}/{{'Gender'}} : {{age}}/{{gender}}</td>
                        </tr>
                        <tr>
                          <td>{{'Type'}}:{{apptype}}</td>
                          <td>{{'Mobile'}} : {{mobile}}</td>
                          <td>{{'Consultant'}} : {{doctorName}}</td>
                          <td><span *ngIf="temparatureFlag">{{'Temp'}}: {{temparature}}</span></td>
                        </tr>
                        <tr>
                          <td>{{'Date'}} : {{prescriptionDate}}</td>
                          <td>{{'Created by'}} : {{doctorName}}</td>
                          <td>{{'Height'}}: {{height}}</td>
                          <td>{{'Weight'}}: {{weight}}</td>
                        </tr>
                        <tr>
                          <td>{{'Time'}} : {{prescriptionTime}}</td>
                          <td><span *ngIf="bpFlag">{{'BP'}}: {{clntBp}}</span></td>
                          <td><span *ngIf="spo2Flag">{{'BP'}}: {{clntSpo2}}</span></td>
                          <td><span *ngIf="prFlag">{{'PR'}}: {{clntPr}}</span></td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;" *ngIf="complaints != undefined">
                        <p><b>Complaints: </b>{{complaints}}</p>
                     </div>
                     
                     <div style="margin-left: 50px;" *ngIf="investigation != undefined">
                        <p><b>Investigation: </b>{{investigation}}</p> 
                     </div>
                   
                     <div style="margin-left: 50px;" *ngIf="diseaseName != undefined">
                        <p><b>{{'Diagnosis' |translate}}: </b>{{diseaseName}}</p> 
                     </div>
                      <br />
                      <table style="margin-left: 20px; border: 1px solid black;border-collapse: collapse; width: 95%;" >
                        <tr *ngIf="medstyle">
                          <th style="width: 275px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle1">
                          <th style="width: 250px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle2">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngIf="medstyle3">
                          <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                          <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                          <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                          <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                        </tr>
                        <tr *ngFor="let product of listProducts">
                          <td style="border: 1px solid black;border-collapse: collapse;">
                            {{product.short_name}} {{product.drug_name}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.every_six}}
                          </td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                            {{product.period}}</td>
                          <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                            {{product.frequency}}
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                            <table>
                              <tr>
                                <td [hidden]="dur_time_flag">{{product.dure_txt_table}} - </td>
                                <td [hidden]="dur_flag">{{product.drug_intake}}</td>
                              </tr>
                            </table>
                          </td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
                        </tr>
                      </table>
                      <div style="margin-left: 50px;">
                        <p *ngIf="instructions!=undefined"><b>{{'Instructions' }}: </b>
                          <span>
                            <div class="matlabel" [innerHtml]="instructions"></div>
                          </span>
                        </p>
                      </div>
                      <br />
                      <div style="margin-left: 50px;" *ngIf="treatFlag">
                        <p *ngIf="treatment!=undefined"><b>{{'Treatment' }}: </b>
                          <span>
                            <div class="matlabel" [innerHtml]="treatment"></div>
                          </span>
                        </p>
                      </div>
                      <div style="margin-left: 50px;">
                        <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
                      </div>
                      <br />
                      <div>
                        <p style="margin-left: 50px;font-size: 13px;"> <strong>{{'Dr '+doctorName}}</strong> </p>
                        <p style="margin-left: 50px;font-size: 13px">({{licenceCode}})</p>
                        <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                        <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
                        <br />
                      </div>
                    </div>
                    <div #printnoheaderwithname id="printnoheaderwithname">
                      <div *ngIf="listProducts.length != 0" style={{preslogo_style}}>
                        <table style={{preslogo_style}}>
                           <tr>
                              <td style="width: 500px;vertical-align: top;">&nbsp;</td>
                              <td style="width: 300px;vertical-align: top;">
                                 <p style="margin-left:157px;">{{prescriptionDate}}</p>
                              </td>
                           </tr>
                           <tr> 
                              <td style="width: 500px;vertical-align: top;">
                                 <p style="margin-left:-20px;margin-top: 29px !important;">{{patientName}}</p>
                                 <p style="margin-left:-20px;margin-top: -5px !important;">{{age}} / {{gender}} </p>
                                 <p style="margin-left:-83px;"><b>{{'Mobile'}} :</b> {{mobile}}</p>
                              </td>
                              <td style="width: 300px;vertical-align: top;">
                                 <p style="margin-left:170px;margin-top: 34px !important;">{{weight}}</p>
                                 <p *ngIf="heightFlag" style="margin-left:120px;"><b>{{'Height'}}: </b>{{height}}</p>
                                 <p *ngIf="bpFlag" style="margin-left:120px;">{{'BP'}}: {{clntBp}} &nbsp;{{'Pulse'|translate}}: {{clntPulse}}</p>
                                 <p *ngIf="temparatureFlag" style="margin-left:115px;"><b>{{'Temperature'}}: </b>{{temparature}} </p>
                              </td>
                           </tr>
                        </table>
                        <div style="margin-left: -30px;" *ngIf="complaints != undefined">
                           <p><b>Complaints: </b>{{complaints}}</p>
                        </div>
                        
                        <div style="margin-left: -30px;" *ngIf="investigation != undefined">
                           <p><b>Investigation: </b>{{investigation}}</p> 
                        </div>
                        
                        <div style="margin-left: -30px;" *ngIf="diseaseName != undefined">
                           <p><b>{{'Diagnosis' |translate}}: </b>{{diseaseName}}</p> 
                        </div>
                        <div style="margin-left: -30px;" >
                           <p style="font-size: 20px;">Rx</p> 
                        </div>
                        <table style="margin-left: -10px; border: 1px solid black;border-collapse: collapse; width: 95%;" >
                              <tr *ngIf="medstyle">
                                <th style="width: 225px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                                <th style=" width: 175px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                                <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                                <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                                <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                                <th style="width: 100px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                              </tr>
                              <tr *ngIf="medstyle1">
                                <th style="width: 250px;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b></th>
                                <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                                <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                                <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                                <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                                <th style="width: 100px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                              </tr>
                              <tr *ngIf="medstyle2">
                                <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                                <th style=" width: 200px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                                <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                                <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                                <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                                <th style="width: 100px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                              </tr>
                              <tr *ngIf="medstyle3">
                                 <th style="width: 200px !important;border: 1px solid black !important;border-collapse: collapse !important;"><b>{{'Medicine'}}</b></th>
                                <th style=" width: 125px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
                                <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
                                <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
                                <th style="width: 75px;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                                <th style="width: 100px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
                              </tr>
                              <tr *ngFor="let product of listProducts">
                                <td style="border: 1px solid black;border-collapse: collapse;">
                                  {{product.short_name}} {{product.drug_name}}
                                </td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                  {{product.every_six}}
                                </td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                                  {{product.period}}</td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                  {{product.frequency}}
                                </td>
                                <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                                  <table>
                                    <tr>
                                      <td [hidden]="dur_time_flag">{{product.dure_txt_table}} {{product.drug_intake}}</td>
                                    </tr>
                                  </table>
                                </td>
                                <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
                              </tr>
                           </table>
            
                           <div style="margin-left: -25px;" *ngIf="instructions!=''">
                            <p *ngIf="instructions!=''"><b>{{'Instructions' }}: </b>
                              <span>
                                <div class="matlabel" [innerHtml]="instructions"></div>
                              </span>
                            </p>
                          </div>
            
                        <div style="margin-left: -25px;" *ngIf="nextVisit!=undefined">
                          <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
                        </div>
                        <br />
                        <div style="margin-top:50;margin-left: -25px;" >
                          <p style="font-size: 13px;"> <strong>{{'Dr '+doctorName}}</strong></p>
                          <p style="font-size: 13px;">Reg no {{licenceCode}}</p>
                        </div>
                        <div style="margin-left: -15px;margin-top:20px;">
                          <p style="margin-left: -15px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                          </p>
                          <p style="margin-left: -15px;font-size: 13px;margin-top:30px;"><b><i>This is digitally generated prescription</i></b>
                          </p>
                          <br />
                        </div>
                     </div>
                    </div>
                  </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>





















<!-- <div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Previous prescriptions</h5>
    <div style="float: right;margin-right: 10px;" [hidden]="showdetails_flag">
        <img src="../../../assets/ui_icons/buttons/back_icon.svg" width="20px" height="20px" (click)="backToList()" />
    </div>
    <div style="float: right;margin-right: 5px;">
        <img src="../../../assets/img/close_icon.png" width="20px" height="20px" (click)="ClosePopup()"/>
    </div>
</div>
<div mat-dialog-content class="refill_content">
    <div class="Details"[hidden]="!showdetails_flag" style="padding:10px 32px; min-height: 200px !important; width:100% !important">
        <div class="row" >
            <p *ngIf="med_pres_list.length == 0" class="nodata">No prescription details found</p>
            <div class="dig_table_overflow" *ngIf="med_pres_list.length != 0">
                <div class="table-responsive">
                    <table id="tbl" class="table table-hover table-dynamic">
                        <thead>
                            <tr>
                                <th style="padding-left: 8px !important;width: 100px;">Date</th>
                                <th>Prescription</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prescription of med_pres_list">
                                <td style="font-size: 11px !important;">
                                    {{prescription.date}}</td>
                                <td style="font-size: 11px !important;">{{"Dr. " + prescription.doctorname}} prescribed
                                    for {{prescription.disease}}</td>
                                <td>
                                    <img src="../../../assets/ui_icons/search_icon.svg" width="20px"
                                        (click)="getPresDetails(prescription)" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="showdetails_flag" style="padding:10px 32px; min-height: 800px !important; width:100% !important">
        <div class="row" >
            <div class="row">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8" *ngIf="!hospitalFlag">
                    <div class="cover_div" style="padding-bottom: 13px;">
                        <div class="header_lable">
                            Hospital details
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                    <div class="">
                                        <img alt="image" src={{hospLogo}} class="hosp_image_class">
                                    </div>
                                    <mat-label class="matlabel"><b>{{hptlName}}</b></mat-label><br>
                                    <mat-label class="matlabel">{{address}}</mat-label><br>
                                    <mat-label class="matlabel">{{doctorLocation}},{{doctorCity}}</mat-label><br>
                                    <mat-label class="matlabel">{{doctorState}},{{doctorCountry}}</mat-label><br>
                                    <mat-label class="matlabel">{{doctorTelephone }}</mat-label><br>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <mat-label class="matlabel"><b>{{'Doctor'}} : {{doctorName}}
                                            {{doctorQualification}}</b> <br>
                                    </mat-label>
                                    <mat-label class="matlabel"><b>{{'Date'}} :
                                        </b>{{prescriptionDate}}</mat-label><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div class="cover_div" style="padding-bottom: 13px;">
                        <div class="header_lable">
                            Patient details
                        </div>
                        <div class="content_cover">
                            <mat-label class="matlabel"><b>{{'Name'}}:</b>{{patientName}}</mat-label><br>
                            <mat-label class="matlabel"><b>{{'Gender'}} :</b> {{gender}}</mat-label><br>
                            <mat-label class="matlabel"><b>{{'Age'}} :</b> {{age}}</mat-label><br>
                            <mat-label class="matlabel" *ngIf="bpFlag"><b>{{'BP'}}:</b> {{clntBp}}
                                &nbsp;&nbsp;&nbsp;{{'Pulse'}}:
                                {{clntPulse}} <br></mat-label>
                            <mat-label class="matlabel" *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}}
                                &nbsp;&nbsp;&nbsp;<span *ngIf="weightFlag"><b>{{'Weight'}}: </b>{{weight}}</span>
                            </mat-label><br>
                            <mat-label class="matlabel" *ngIf="temparatureFlag"><b>{{'Temperature'}}:
                                </b>{{temparature}}
                            </mat-label>
                            <mat-label class="matlabel" *ngIf="orthoFlag"><b>{{'CVS'}}:</b> {{clntCvs}}
                                &nbsp;&nbsp;&nbsp;
                                <b>{{'RS'}}:</b>
                                {{clntRs}} &nbsp;&nbsp;&nbsp;<b>{{'Kidney'}}:</b> {{clntKidney}}<br></mat-label>
                            <mat-label class="matlabel" *ngIf="orthoFlag"></mat-label>
                            <mat-label class="matlabel" *ngIf="pulmFlag"><b>{{'PFT'}}: {{pft}}</b></mat-label>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-left: 10px;margin-top: 5px;" *ngIf="investigation!=undefined">
                <mat-label class="matlabel"><b>{{'Investigation'}} :</b> {{investigation}}<br>
                </mat-label>
            </div>
            <div style="margin-left: 10px;">
                <mat-label class="matlabel"><b>{{'Diagnosis' }} :</b> {{diseaseName}} {{diseaseDescription}} <br>
                </mat-label>
            </div>
            <div *ngIf="listProducts.length !=0" class="medicine_details">
                <div class="table-responsive dig_table_overflow">
                    <table *ngIf="listProducts.length" class="table table-nowrap table-sm dataTable billlisttable">
                        <thead>
                            <tr>
                                <th>{{'Medicine type'}}</th>
                                <th>{{'Medicine name'}}</th>
                                <th>{{'Duration'}}</th>
                                <th>{{'Intake session'}}</th>
                                <th>{{'Frequency'}}</th>
                                <th>{{'Intake'}}</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of listProducts; let i = index">
                                <td style="font-size: 12px;text-align: left;">{{ product.med_typetxt }}</td>
                                <td style="font-size: 12px;text-align: left;">{{ product.drug_name }}</td>
                                <td style="font-size: 12px;">{{product.days}} {{product.period }}</td>
                                <td style="font-size: 12px;">{{product.every_six}}</td>
                                <td style="font-size: 12px;">{{product.frequency }}</td>
                                <td style="font-size: 12px;text-align: left;">{{product.dure_txt_table}}
                                    ({{product.drug_intake }})
                                </td>
                                <td style="font-size: 12px;text-align: left;">{{product.remarks }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="margin-left: 10px;margin-top:5px;" [hidden]="instructionFlag">
                    <mat-label class="matlabel"><b>{{'Instructions' }}: </b></mat-label>
                    <div class="matlabel" [innerHtml]="instructions"></div>
                </div>
                <div style="margin-left: 10px;" *ngIf="nextVisit!=undefined">
                    <mat-label class="matlabel"><b>{{'Next visit' }}: </b>{{nextVisit}}</mat-label>
                </div>
            </div>
            <div *ngIf="pharmacyFlag">
                <div *ngFor="let pharma of pharmacyDetailList" class="row">
                    <div class="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2" style="position: relative;">
                        <img alt="image" src="{{pharma.phar_logo}}" class="hosp_image_class"
                            style="position: absolute;top: 13px;left: 51px;margin: 0;"><br>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel"><b>{{pharma.pharmacy_name}}</b></mat-label><br>
                        <mat-label class="matlabel">{{pharma.phar_addr1}}, {{pharma.phar_addr2}}</mat-label><br>
                        <mat-label class="matlabel">{{pharma.phar_loc}}</mat-label><br>
                        <mat-label class="matlabel">{{pharma.phar_city}} - {{pharma.phar_zipcode}}</mat-label><br>
                        <mat-label class="matlabel">{{pharma.phar_state}}, {{pharma.phar_cnty}}</mat-label><br>
                        <mat-label class="matlabel">{{pharma.telephone}}</mat-label><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->