import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { Time_Formate } from '../../../assets/js/common';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-fd-inpatient',
  templateUrl: './fd-inpatient.component.html',
  styleUrls: ['./fd-inpatient.component.scss']
})
export class FdInpatientComponent implements OnInit {
  public docRegId;
  public hospitalId;
  public splName: string;
  public patientList:any[] = [];
  public userInfo:any;
  public userid:any;
  public firstName:string;
  public lastName:string;
  dtOptions: any = {};
  public patientType;
  public display;
  public doctorList = [];
  public doctor;
  public doctorId;
  public doctorName;
  public ipaddress;
  public splUrl;
  public splList = [];
  public masterSelected: boolean = false;
  public checklistid: any = [];
  public checklist: any = [];
  public patientCount:any;
  public dislist:any=[];
  public sur_flag:boolean=true;
  public create_flag: boolean=true;

  constructor(private http:HttpClient,public toastr:ToastrService,public messageservice:MenuViewService) { 
    Helper_Class.setInpatientFlow(undefined);
    Helper_Class.setSpecializations(undefined);
    Doc_Helper.setFirstname(null);
    Doc_Helper.setMiddlename(null);
    Doc_Helper.setLastname(null);
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("New");
    Doc_Helper.setMobile_no(null);
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      iDisplayLength: 2,
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        {"type": "date", "orderable": false, "targets": [0] },
        {"targets":4, "type":"date-eu"},
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patients by Name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.splUrl = ipaddress.getIp + "adm/gsplbyhptl";
    this.userInfo= Helper_Class.getInfo();
    this.userid=this.userInfo.user_id;
    this.docRegId = this.userid;
    this.hospitalId = Helper_Class.getHospitalId();
    this.patientType = "self";
    this.ipaddress = Helper_Class.getIPAddress();
    this.getDoctors();
    this.getSpecializations();
    if(Helper_Class.getInfo().dis_summ_create =="1"){
      this.create_flag = false;
    }
  }

  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.splUrl, { hptl_clinic_id: this.userInfo.hptl_clinic_id },
      { headers: headers }).subscribe(
        data => {
          this.splList = [];
          var obj = JSON.parse(JSON.stringify(data));
          if (obj.specializations != null) 
            this.splList = obj.specializations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  getPatients() {
    this.patientList = [];
    var data = null;
    var send_data = {
      doc_reg_id: this.doctor,
      hptl_clinic_id:this.hospitalId,
      country: ipaddress.country_code.toString(),
      imei: Helper_Class.getIPAddress(),
      type: "doctor",
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      (send_data),
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          if(data.patients != undefined && data.patients.length !=0){
            for(var i=0;i<data.patients.length;i++){
              var values= data.patients[i];
              var pname,fname,lname,mname;
              if(values.middle_name != undefined ){
                if(values.middle_name !=""){
                  pname = encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.middle_name)+" "+encrypt_decript.Decript(values.last_name);
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.last_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname="";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.last_name);
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }

              var ward_text;
              var wardname = "";
              if(values.ward_name != undefined){
                wardname = values.ward_name;
              }

              var bedno = "";
              if(values.bed_no != undefined && values.bed_no != ""){
                bedno = values.bed_no;
                ward_text = wardname + " - Bed: " + bedno;
              }else{
                ward_text = wardname
              }
                          
              var surdate="";
              if(values.surgery_date != undefined){
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2]+"-"+sdate[1]+"-"+sdate[0];
              } else {
                sdate = "";
              }
              // var disflag;
              // if(values.discharge_date !=undefined){
              //   disflag = true;
              // } else {
              //   disflag = false;
              // }
              var cdate;
              if(values.created_date != undefined){
                var cdval = values.created_date.split('-');
                cdate= cdval[2]+"-"+cdval[1]+"-"+cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if(values.created_time != undefined){
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              
              this.patientList.push({
                client_reg_id : values.client_reg_id,
                patient_name: pname,
                admit_date:cdate,
                admit_time: ctime,
                ward_name: wardname,
                bed_no: bedno,
                mobile:values.mobile,
                profile_image:ipaddress.Ip_with_img_address.toString()+values.profile_image,
                first_name:fname,
                last_name:lname,
                middle_name:mname,
                ward_text: ward_text,
                inpatient_id:values.inpatient_id,
                hosp_inp_id:values.hosp_inp_id,
                speciality:values.speciality,
                surgery_date:surdate,
                disflag:false
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  changePatientType(){
    this.getPatients();
  }

  getDoctors() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyrep',
      {
        country: "IN",
        reg_id:this.userInfo.user_id,
        imei:  this.ipaddress,
        type: "front-desk",
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if(obj != null){
            this.doctorList.push({
              Doc_Name: "All",
              Doc_ID: "All",
            });

            for (var i = 0; i < obj.doctors.length; i++) {
              this.doctorName = "";
              var fname,mname,lname;
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
              } else {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
              }
              this.doctorList.push({
                Doc_Name: this.doctorName,
                Doc_ID: obj.doctors[i].prov_id,
              });
            }
            this.doctor = this.doctorList[0].Doc_ID;
            this.changeDoctor(this.doctor);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  create(){
    Doc_Helper.setFirstname(null);
    Doc_Helper.setMiddlename(null);
    Doc_Helper.setLastname(null);
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("New");
    Doc_Helper.setMobile_no(null);
    Doc_Helper.setInpatientFlow("front-desk");
    Helper_Class.setInpatientFlow("front-desk");
    Helper_Class.setSpecializations(this.splList);
    this.messageservice.sendMessage("viewIpatient");
  }

  viewPatient(inpatient_id,mobile, regid,array,fname,mname,lname,speciality){
    Doc_Helper.setFirstname(fname);
    Doc_Helper.setMiddlename(mname);
    Doc_Helper.setLastname(lname);
    Doc_Helper.setClient_id(regid);
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("Existing");
    Doc_Helper.setMobile_no(mobile);
    Doc_Helper.setInpatientId(inpatient_id);
    Doc_Helper.setInpatientFlow("list");
    var spa_id
    for( var i=0;i<this.splList.length; i++){
        if(this.splList[i].spl_name == speciality){
            spa_id = this.splList[i].spl_id;
        }
    }
    var specializations = [{
      spl_id: spa_id,
      spl_name: speciality
    }]
    Helper_Class.setInpatientFlow("front-desk");
    Helper_Class.setSpecializations(specializations);
    this.messageservice.sendMessage("viewIpatient");
  }

  changeDoctor(e) {
    FrontDesk_Helper.setdoctor(undefined);
    FrontDesk_Helper.setdoctor(e);
    this.doctor = e;
    this.getPatients();   
  }

  checkUncheckAll(data) {
    this.masterSelected = data.checked;
    for (var i = 0; i < this.patientList.length; i++) {
        this.patientList[i].selected = this.masterSelected;
    } 
  }

  isInpatSelected(list) {
    if(list.selected == true){
      for(var i=0;i<this.patientList.length;i++){
        if(list.inpatient_id == this.patientList[i].inpatient_id){
          this.patientList[i].selected=false;
        }
      }

    } else {
      for(var i=0;i<this.patientList.length;i++){
        if(list.inpatient_id == this.patientList[i].inpatient_id){
          this.patientList[i].selected=true;
        }
      }
    }
  }

  closeInpatient(){
    if(this.patientList.length !=0){
      this.dislist=[];
      for(var i=0;i<this.patientList.length;i++){
        if(this.patientList[i].selected == true){
          this.dislist.push(this.patientList[i].inpatient_id)
        }
      }
      
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inpat/cip',
        ({inpat_ids:this.dislist}),
        { headers: headers })
        .subscribe(
          response => {
            var data = JSON.parse(JSON.stringify(response));
            if(data.key =="1"){
              this.toastr.success("Successfully closed inpatient record(s) ");
              this.getPatients();
            } else {
              this.toastr.success("Unable to closed inpatient record(s), try later");
            }
            
          });
    }
  }
}
