import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service'
import { Router } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { FilterpageComponent } from '../filterpage/filterpage.component';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { Date_Formate } from '../../../assets/js/common';

@Component({
  selector: 'app-interdept-trans-list',
  templateUrl: './interdept-trans-list.component.html',
  styleUrls: ['./interdept-trans-list.component.scss']
})
export class InterdeptTransListComponent implements OnInit {
  orderListArray = [];
  noDataCurrent: boolean = false;
  public pharmacyID: string;
  public textAlign;
  public height;
  public width;
  public userinfo;
  public imei;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any;
  public AppType;
  public inpatientFlag;
  public ynopt1: boolean = true;
  public createFlag:boolean = false;
  public currentDate;
  public fromDate;
  public toDate;
  constructor(public messageservice: PharmacyService, public gservice: CommonDataService, public http: Http, public router: Router, public dialog: MatDialog,public toastr:ToastrService) {
  }

  ngOnInit(): void {
    this.imei = Helper_Class.getIPAddress();
    this.userinfo = Helper_Class.getInfo();
    this.getCurrentDate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by department or transfer date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',{country: ipaddress.country_code},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.pharmacyBranchList();
        })
  }

  selectDate(data,value) {
    if (data != null) {
      if (value == "1"){
        this.fromDate = data;
      }else{
        this.toDate = data;
      }
    }
  }

  pharmacyBranchList() {
    var send_data = JSON.stringify({
      pharma_reg_id: this.userinfo.user_id,
      imei: this.imei
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/locbyregid/", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          if (obj.pharma != null) {
            this.pharmacyID = obj.pharma[0].pharma_id;
            localStorage.setItem("pharma_id", obj.pharma[0].pharma_id);
          }
          this.getDeptTransfers(this.userinfo.hospitals[0].hptl_clinic_id)
        });
  }

  getDeptTransfers(pharmacyBranchId) {
    this.orderListArray = [];
    this.orderListArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var fdate;
    if(this.fromDate != undefined){
      fdate = this.fromDate;
    } else {
      fdate = this.currentDate;
    }
    var tdate;
    if(this.toDate != undefined){
      tdate = this.toDate;
    } else {
      tdate = this.currentDate;
    }
    var send = {
      pharma_id: pharmacyBranchId,
      start_date : fdate,
      end_date : tdate,
    }
    this.http.post(ipaddress.getIp + "pharmacontrol/gidt", JSON.stringify(send), { headers: headers }).subscribe(
      response => {
        var obj = response.json();
        if (obj.transfers != null) {
          this.noDataCurrent = false;
          for (var i = 0; i < obj.transfers.length; i++) {
            this.orderListArray.push({
              order_id: obj.transfers[i].transfer_id,
              rec_name: obj.transfers[i].rec_name,
              order_date: this.DateFormate(obj.transfers[i].issued_date),
              department: obj.transfers[i].department,
              fin_year: obj.transfers[i].fin_year,
            });
          }
        } else {
          this.noDataCurrent = true;
        }
      });
  }

  DateFormate(data) {
    var Splitter = data.split("-");
    return Splitter[2] + "-" + Splitter[1] + "-" + Splitter[0];
  }

  orderView(Order_id, recname, flag,finyear) {
    var order_View = {
      orderid: Order_id,
      rec_name:recname,
      department: flag,
      fin_year:finyear,
      type:"update",
    }
    pharmacy_helper.setorderview(order_View);
    this.messageservice.sendMessage('interdept');
  }

  optionsPopover() {
    const dialogRef = this.dialog.open(FilterpageComponent, {
      width: '25%',
      height: '180px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != undefined) {
        this.getDeptTransfers(data)
      }
    });
  }

  back_Nav() {
    this.messageservice.sendMessage("pharmahome");
  }

  createOrder() {
    var order_View = {
      type:"create",
    }
    pharmacy_helper.setorderview(order_View);
    this.messageservice.sendMessage("interdept");
  }

}
