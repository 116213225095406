// time format based on session 
function Time_Formate_Session(timetxt, session) {
    var time = timetxt.split(':');

    if (session == "Morning") {
        if (time[0] == "12") {
            return time[0] + ":" + time[1] + " PM";
        }
        else {
            return time[0] + ":" + time[1] + " AM";
        }
    }
    else {
        return time[0] + ":" + time[1] + " PM";
    }
}
exports.Time_Formate_Session = Time_Formate_Session;

//12 hours to 24 hours1

function ConvertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
}
exports.ConvertTimeformat = ConvertTimeformat;

//=================================== to get session Return1 
function Session_Return(text) {

    if (text == "1") {
        return "Morning";
    }
    else if (text == "2") {
        return "Afternoon";
    }
    else if (text == "3") {
        return "Evening";
    }
}
exports.Session_Return = Session_Return;

//=================================== to get seesion_nameid 

function seesion_nameid(text) {

    if (text == "Morning") {
        return 1;
    }
    else if (text == "Afternoon") {
        return 2;
    }
    else if (text == "Evening") {
        return 3;
    }
}
exports.seesion_nameid = seesion_nameid;

//====================================== to get day_nameid

function day_nameid(text) {

    if (text == "Sunday") {
        return 1;
    }
    else if (text == "Monday") {
        return 2;
    }
    else if (text == "Tuesday") {
        return 3;
    }
    else if (text == "Wednesday") {
        return 4;
    }
    else if (text == "Thursday") {
        return 5;
    }
    else if (text == "Friday") {
        return 6;
    }
    else if (text == "Saturday") {
        return 7;
    }
    else if (text == "Mon - Sat") {
        return 8;
    }
    else if (text == "All Days") {
        return 9;
    }

}
exports.day_nameid = day_nameid;

//========================  to get seesion_ampm
function seesion_ampm(text) {

    if (text == "Morning") {
        return "am";
    }
    else if (text == "Afternoon") {
        return "pm";
    }
    else if (text == "Evening") {
        return "pm";
    }
}
exports.seesion_ampm = seesion_ampm;

//======================= to get day return

function Day_Return(text) {

    if (text == "1") {
        return "Sunday";
    }
    else if (text == "2") {
        return "Monday";
    }
    else if (text == "3") {
        return "Tuesday";
    }
    else if (text == "4") {
        return "Wednesday";
    }
    else if (text == "5") {
        return "Thursday";
    }
    else if (text == "6") {
        return "Friday";
    }
    else if (text == "7") {
        return "Saturday";
    }
    else if (text == "8") {
        return "Mon - Sat";
    }
    else if (text == "9") {
        return "All Days";
    }

}

exports.Day_Return = Day_Return;

//Nurse day return

function Nurse_Day_Return(text) {

    if (text == "1") {
        return "Mon - Sat";
    }
    else if (text == "2") {
        return "All Days";
    }

}




exports.Nurse_Day_Return = Nurse_Day_Return;

//======================================format time 
function Time_Formate(text) {

    var time = text.split(':');

    if (time[0] >= 12) {

        if (time[0] == 12) {
            return time[0] + ":" + time[1] + " PM";

        }
        else {
            var hours = time[0] - 12;

            if (hours < 10) {


                return "0" + hours + ":" + time[1] + " PM";
            }
            else {
                return hours + ":" + time[1] + " PM";
            }
        }

    }
    else {
        return time[0] + ":" + time[1] + " AM"
    }
}
exports.Time_Formate=Time_Formate;
//=======================================  date format

function formattedDate(d = new Date) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    // return `${month}/${day}/${year}`;
    return `${year}-${month}-${day}`;

  }

exports.formattedDate = formattedDate;


    function Date_Formate(data) {
    var Splitter = data.split("-");

    return Splitter[2] + "-" + Splitter[1] + "-" + Splitter[0];
}
exports.Date_Formate = Date_Formate;
function Ignorecase_data(str_1, str_2) {

    return str_1.toUpperCase() == str_2.toUpperCase();
}

exports.Ignorecase_data = Ignorecase_data;

 function first_uppercase(string) {

    return string.charAt(0).toUpperCase() + string.slice(1);
}

exports.first_uppercase = first_uppercase;



function session_based_time_fun(t1, t2, ses) {

   

    var date1 = new Date("03/13/2014" + ' ,' + t1 + ":00");
    var date2 = new Date("03/13/2014" + ' ,' + t2 + ":00");

    var options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    var timeString1 = date1.toLocaleString('en-US', options);
    var timeString2 = date2.toLocaleString('en-US', options);

    var ll1 = timeString1.split(' ');
    var ll2 = timeString2.split(' ');

    var t1_spt = timeString1.split(':');
    var t2_spt = timeString2.split(':');


    var fromvalue = t1_spt[0] + "00";
    var tovalue = t2_spt[0] + "00";

    //var f_time = t1.split(':');
    var chk_flag = false;
    if (ses == "Morning") {

        
        if (ll1[1] == "PM" && ll2[1] == "PM") {
            chk_flag = false;
        }
        else if (ll1[1] == "PM") {
            chk_flag = false;
        }
        else if (ll2[1] == "PM" && t2_spt[0] != "12") {
            chk_flag = false;
        }
        else {


            var morningfrom = 1200;
            var morningTo = 1200;
            var result = compareRangeMorning(fromvalue, morningfrom, morningTo);
            if (result == true) {
                var result1 = compareRangeMorning(tovalue, morningfrom, morningTo);
                if (result1 == true) {
                    var res = compareValues(fromvalue, tovalue);
                    if (res == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                }
                else {
                    chk_flag = false;
                }

                if (t1_spt[0] < 12 && t2_spt[0] <= 12) {
                    chk_flag = true;
                }
            }
        }
    }
    else if (ses == "Afternoon") {

        if (ll1[1] == "AM" || ll2[1] == "AM") {
            chk_flag = false;
        }
        else {
            var afternoonfrom = 1200;
            var afternoonTo = 400;
            var result = compareRangeNoon(fromvalue, afternoonfrom, afternoonTo);
           

            if (result == true) {

                var res = compareValuesNoon(fromvalue, tovalue);
               
                if (res == true) {
                    var toresult = compareRangeNoon(tovalue, afternoonfrom, afternoonTo);
                    if (toresult == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                    //	System.out.println("compareRangeNoon");

                }
                else {
                    //	System.out.println("compareRangeNoon 123");
                    chk_flag = false;
                }

            }
            else {
                chk_flag = false;
            }
        }

        //if (t1_spt[0] >= 12 && t2_spt[0] <= 16) {
        //    chk_flag = true;
        //}

    }
    else {
        if (ll1[1] == "AM" || ll2[1] == "AM") {
            chk_flag = false;
        }
        else {
           

            //if (t1_spt[0] > 16 && t2_spt[0] <= 24) {
            //    chk_flag = true;
            //}

            var eveningfrom = 400;
            var eveningTo = 1100;

            var result = compareRangeEvening(fromvalue, eveningfrom, eveningTo);
            if (result == true) {
                var result1 = compareRangeEvening(tovalue, eveningfrom, eveningTo);
                if (result1 == true) {
                    var res = compareValues(fromvalue, tovalue);
                    if (res == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                }
                else {
                    chk_flag = false;
                }
            }
            else {
                chk_flag = false;
            }
        }
    }

    return chk_flag;

}

function compareRangeMorning(value, from, to) {
    var result;
    if (parseInt(value) <= parseInt(from) && parseInt(value) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}

function compareRangeEvening(value, from, to) {
    var result;
    if (parseInt(value) >= parseInt(from) && parseInt(value) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}

function compareRangeNoon(value, from, to) {

    var result = false;
    if (parseInt(value) >= 100 && parseInt(value) <= 400) {
        //MessageBox.Show("inside " + value + "   " + from + "  " + to);
        if (parseInt(value) <= parseInt(from) && parseInt(value) <= parseInt(to)) {
            ///	System.out.println("inside fdfa");
            result = true;
        }
    }
    else if (parseInt(value) >= parseInt(from) && parseInt(value) >= parseInt(to)) {
        // MessageBox.Show("inside123 " + value + "   " + from + "  " + to);
        result = true;
    }
    else {
        result = false;
    }
    //System.out.println("inside " + result);
    return result;
}


function compareValuesNoon(from, to) {
    var result = false;

    if (parseInt(from) >= 100 && parseInt(from) <= 400) {

        if (parseInt(from) <= parseInt(to)) {
            result = true;
        }
    }
    else if (parseInt(from) >= parseInt(to)) {
        result = true;
    }
    else {

        result = false;
    }
    return result;
}

function compareValues(from, to) {
    var result;
    if (parseInt(from) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}


exports.session_based_time_fun = session_based_time_fun;

function diag_dayid(text) {
    if (text == "Mon - Sat") {
        return 1;

    } else if (text == "All Days") {
        return 2;
    }
}
exports.diag_dayid = diag_dayid;


function Nurse_Day_id(text) {
    if (text == "Mon - Sat") {
      return 1;

    } else if (text == "All Days") {
      return 2;
    }
}
exports.Nurse_Day_id = Nurse_Day_id;

function tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
};
exports.tConv24 = tConv24;

function capitalize(s){
    return s[0].toUpperCase() + s.slice(1);
}
exports.capitalize = capitalize;

function Time_Formate_Table(timetxt) {
    var time = timetxt.split('-');

    return time[2] + "/" + time[1] + "/" + time[0];
}
exports.Time_Formate_Table = Time_Formate_Table;