<div mat-dialog-title class="popup_border">
    <h5 class="header_txt">Medicine Details</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" width="20px" (click)="ClosePopup()" height="20px" id="close_prev"/>
    </div>
</div>
<div mat-dialog-content class="refill_content">
    <div class="row">
        <div [hidden]="listProducts.length != 0" class="nodata col-10">No medicines found</div>
        <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12 div_align">
            <div *ngFor="let viewpres of listProducts" class="ch">
                  <div class="popup_inner_boxstyle row" style="padding: 5px;">
                    <div class="col-1 my-auto">
                      <mat-checkbox class="col-1" color="primary" [(ngModel)]="viewpres.checked" (change)="selectedPrescription(viewpres.medicines,$event.checked)"></mat-checkbox>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8  my-auto">                         
                      <div *ngFor="let meds of  viewpres.medicines">
                        <mat-label class="matlabel">{{meds.drug}} <br/> {{meds.catagory}}</mat-label>
                      </div>
                    </div>
                    <div class="col-3 my-auto d-flex justify-content-end">
                      <mat-label class="matlabel" style="text-align: right;">Date:{{viewpres.pres_date}}</mat-label>
                    </div>
                </div>
              </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button [hidden]="saveflag" id="add_btn" [mat-dialog-close]="selectedList" cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/ok_button.svg" class="addimgbtn_icd" />
</button>
</div>
