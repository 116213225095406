import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';

@Component({
  selector: 'app-diag-sample-list',
  templateUrl: './diag-sample-list.component.html',
  styleUrls: ['./diag-sample-list.component.scss']
})
export class DiagSampleListComponent implements OnInit {
  public save_btn:boolean;
  public sampledate:string;
  public filtduration:string;
  public currentDate:string;
  public sampleList:any=[];
  public selectedList:any=[];
  dtOptions: DataTables.Settings = {};
  
  constructor(public diagnosisservice:MenuViewService,public toastr:ToastrService,
    public gservice:CommonDataService,public http:HttpClient,public dialog:MatDialog) {
    
  }

  ngOnInit(): void {
    this.filtduration="select";
    this.getdate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by test name or count"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

  }

  getdate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", ({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        this.currentDate = response.current_date;
        this.sampledate = this.currentDate;
        this.getSampleList(this.sampledate)
      },error => {});
  }

  getSampleList(date){
    var durval;
    if(this.filtduration !='select'){
      durval = this.filtduration
    }
    
    var send_data={
      country: ipaddress.country_code,
      diag_center_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
      duration:durval,
      date:date,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        if(response.sample_list != undefined){
          for(var i=0;i<response.sample_list.length;i++){
            var cname;
            if(response.sample_list[i].middle_name != undefined){
              cname = encrypt_decript.Decript(response.sample_list[i].first_name)+" "+encrypt_decript.Decript(response.sample_list[i].middle_name)+" "+encrypt_decript.Decript(response.sample_list[i].last_name);
            } else {
              cname = encrypt_decript.Decript(response.sample_list[i].first_name)+" "+encrypt_decript.Decript(response.sample_list[i].last_name);
            }
            var disableflag;
            if(response.sample_list[i].sample_collect_time != undefined){
              disableflag = true;
            } else {
              disableflag = false;
            }
            
            var cdate;
            var dateval;
            if(response.sample_list[i].collected_date != undefined){
              cdate = response.sample_list[i].collected_date.split("-");
              dateval=cdate[2]+"-"+cdate[1]+"-"+cdate[0];
              
            } else {
              dateval="";
            }
            
            this.sampleList.push({
              patient_id:response.sample_list[i].patient_id,
              prestestid:response.sample_list[i].prestestid,
              diagtestid:response.sample_list[i].diagtestid,
              test_type_name:response.sample_list[i].test_type_name,
              test_name:response.sample_list[i].test_name,
              collected_date:dateval,
              collect_time:response.sample_list[i].sample_collect_time,
              pat_name:cname,
              disableflag:disableflag,
            })
          }
        }
        
      },error => {});
  }

  saveSamples() {
    this.selectedList=[];
    for(var i=0;i<this.sampleList.length;i++){
      if(this.sampleList[i].isSelected == true){
        this.selectedList.push({
          pres_diag_id:this.sampleList[i].diagtestid,
          test_type:this.sampleList[i].test_type_name,
          pres_test_id:this.sampleList[i].prestestid,
        })
      }
    }
    var send_data={
      user_id:Helper_Class.getInfo().user_id,
      country:ipaddress.country_code,
      tests:this.selectedList
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/sscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        if(response.key =="1"){
          this.toastr.success("Successfully saved sample collections")
        }

      },error => {})
  }
}
