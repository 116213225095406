import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { ServerApi } from 'src/app/server-api';
import moment from 'moment';
import { PharmacyService } from '../pharmacy/pharmacy.service';
@Component({
  selector: 'app-pharma-returns',
  templateUrl: './pharma-returns.component.html',
  styleUrls: ['./pharma-returns.component.scss']
})
export class PharmaReturnsComponent implements OnInit {
  public userInfo;
  public pharmacistID;
  public pharmacyID;
  public LoadLocationArray;
  public locationList;
  public location;
  public stockData = [];
  dtOptions: DataTables.Settings = {};
  public checklist = [];
  public checklistid = [];
  public appin_open_count;
  public masterSelected:boolean = false;
  public nodata_flag: boolean = true;
  public productReturn;
  public expiryDate;
  constructor(public serviceAPI: ServerApi, public toastr: ToastrService,private messageServive : PharmacyService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by medicine, supplier or batch"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    this.productReturn = this.userInfo.hospitals[0].product_return;
    this.expiryDate = moment().add(this.productReturn, 'months').format('MM-YYYY');
   // console.log("date" + expiryDate);
    this.get_stock();
  }
  async get_stock() {
    var send_data;
    send_data = {
      pharmacy_id: this.pharmacyID,
      pharmacist_id: this.pharmacistID,
      product_return: this.productReturn,
      expiry_date : this.expiryDate
    }
    var response = await this.serviceAPI.PostData('pharmacontrol/gpstock', send_data).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      //  this.stockData = obj.stock_onhand;
      this.appin_open_count = obj.stock_onhand.length;
      for (var i = 0; i < obj.stock_onhand.length; i++) {
        this.stockData.push({
          id: i + 1,
          product_id: obj.stock_onhand[i].product_id,
          medicine: obj.stock_onhand[i].short_name + "-" + obj.stock_onhand[i].name,
          genericName: obj.stock_onhand[i].generic_name,
          uomDesc: obj.stock_onhand[i].uom_desc,
          mfgName: obj.stock_onhand[i].mfg_name,
          batchNo: obj.stock_onhand[i].batch_no,
          exp_date: obj.stock_onhand[i].exp_date,
          qty_onhand: obj.stock_onhand[i].qty_onhand,
          cost_price: obj.stock_onhand[i].cost_price,
          sales_price: obj.stock_onhand[i].sales_price,
          mrp: obj.stock_onhand[i].mrp,
          mfg_date: obj.stock_onhand[i].mfg_date,
          checked: false,
        });
      }
      this.nodata_flag = true;
      console.log("this.stockData" + JSON.stringify(this.stockData));
    }
  }
  checkUncheckAll(action) {
    if (action == "select") {
      if (this.checklistid.length == 0) {
        for (var i = 0; i < this.stockData.length; i++) {
        
          this.checklistid.push({
            index: this.stockData[i].id,
            product_id: this.stockData[i].product_id,
            batch_no: this.stockData[i].batchNo,
            qty_return: this.stockData[i].qty_onhand,
            cost_price: this.stockData[i].cost_price,
            sales_price: this.stockData[i].sales_price,
            mrp: this.stockData[i].mrp,
            mfg_date: this.stockData[i].mfg_date,
            mfg_name : this.stockData[i].mfgName,
            expiry_date: this.stockData[i].exp_date,
          });
          this.stockData[i].checked = true;
        }
      }else {
        for (var i = 0; i < this.stockData.length; i++) {
          for (var j = 0; j < this.checklistid.length; j++) {
            if( this.stockData[i].id == this.checklistid[j].index ){
              this.checklistid.push({
                index: this.stockData[i].id,
                product_id: this.stockData[i].product_id,
                batch_no: this.stockData[i].batchNo,
                qty_return: this.stockData[i].qty_onhand,
                cost_price: this.stockData[i].cost_price,
                sales_price: this.stockData[i].sales_price,
                mrp: this.stockData[i].mrp,
                mfg_date: this.stockData[i].mfg_date,
                mfg_name : this.stockData[i].mfgName,
                expiry_date: this.stockData[i].exp_date,
              });
              this.stockData[i].checked = true;
              break;
            }
          }
        }
      }
      this.masterSelected = true;
      
    } else {
      this.checklistid = [];
      for (var i = 0; i < this.stockData.length; i++) {
        this.stockData[i].checked = false;
      }
      this.masterSelected = false;
    }
    console.log("this.stockData" + JSON.stringify(this.stockData.length));
    console.log("this.checklistid" + JSON.stringify(this.checklistid));
  }
  isAllSelected(list, action) {
    for (var i = 0; i < this.stockData.length; i++) {
      if (this.stockData[i].id == list.id) {
        if (action == "select") {
          this.checklistid.push({
            index: list.id,
            product_id: list.product_id,
            batch_no: list.batchNo,
            qty_return: list.qty_onhand,
            cost_price: list.cost_price,
            sales_price: list.sales_price,
            mrp: list.mrp,
            mfg_date: list.mfg_date,
            mfg_name : list.mfgName,
            expiry_date: list.exp_date,
          });
          this.stockData[i].checked = true;
        } else {
          for (var j = 0; j < this.checklistid.length; j++) {
            if (this.checklistid[j].index == list.id) {
              this.checklistid.splice(j,1);
              this.stockData[i].checked = false;
              break;
            }
          }
        }
        break;
      }
    }
    console.log("this.stockData" + JSON.stringify(this.stockData));
    console.log("this.checklistid" + JSON.stringify(this.checklistid));
  }
  quantity(list){
    if( list.checked == true ){
      for( var i = 0;i < this.checklistid.length; i++){
          if( this.checklistid[i].index == list.id ){
            this.checklistid[i].qty_return = list.qty_onhand;
          }
      }
    }
    console.log("this.checklistid" + JSON.stringify(this.checklistid));
  }
  async saveOrder(){
     if(this.checklistid.length != 0 ){
      var send_data;
      send_data = {
        pharma_id: this.pharmacyID,
        products: this.checklistid,
        country : "IN"
      }
      console.log("send data" + JSON.stringify(send_data));
      var response = await this.serviceAPI.PostData('pharmacontrol/sprt', send_data).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("obj" + JSON.stringify(obj));
        if( obj.key == 1 ){
          this.messageServive.sendMessage("pharmacycurentinformation");
          this.toastr.success("Product return as been intiated successfully");
        }
      }
     }else{
      this.toastr.error("Please select atleast one product to return");
     }
  }
}
