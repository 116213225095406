import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import * as $ from 'jquery/dist/jquery.min.js';
import { Neprology_graph } from '../../../assets/js/Neprology_Graph';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { animationFrameScheduler, Subscription } from 'rxjs';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-discharge-summary',
  templateUrl: './discharge-summary.component.html',
  styleUrls: ['./discharge-summary.component.css']
})
export class DischargeSummaryComponent implements OnInit {

  public dischargesummary = "min";
  public lineChartType: string = 'line';
  public graphlabels;
  public header_footer_flag: boolean;
  public isMobile: boolean;
  public patient_name: string;
  public patient_identifier: string;
  public gender: string;
  public age: string;
  public admis_date: string;
  public dis_date: string;
  public doctor_name: string;
  public mobile_no: string;
  public symptoms: string;
  public complaints: string;
  public medical_hist: string;
  public physical_exam: string;

  public loader: boolean;
  public pres_pop_flag: string;
  public mobile: string;
  public next_data: string;
  public inpatient_id: string;

  public dis_diag: string;
  public hosp_course: string;
  public hospital_followup: string;

  public hosp_name: string;
  public hosp_addr: string;
  public hosp_csz: string;
  public hosp_tel: string;
  public hosp_web: string;
  public hosp_web_flag: boolean;
  public hosp_count: string;
  public druglist = [];

  //gyno_case_summary
  public Visit_purpose;
  public Main_concern;
  public Disease_txt;
  public Present_illness;
  public Menstrual_periods;
  public Icd_txt;
  public gyna_list = [];
  public home_care;
  public obo_gyna;
  public spl_flag: boolean;

  //flag values
  public Is_Gynecology;
  public Is_specilization;
  public Gynecology_flag: boolean = false;
  public Pediatrics_flag: boolean = false;
  public Cardio_flag: boolean = false;
  public Nephrology_flag: boolean = false;
  public Others_flag: boolean = false;
  public Dental_flag: boolean = false;
  public Diabetes_flag: boolean = false;

  //diab_case_summary
  public Client_id;
  public rel_id;
  public sub_id;
  public specilization_txt;
  public Diab_Graph_div;
  public Disease_type: string;
  public Case_dietary: string;
  public Case_concern: string;
  public Case_cardio: string;
  public Case_cardio_flag: boolean = false;
  public Case_mobility: string;
  public Case_mobility_flag: boolean = false;
  public Case_nephro: string;
  public Case_nephro_flag: boolean = false;
  public Case_retino: string;
  public Case_retino_flag: boolean = false;
  public Complications_flag: boolean;
  public medicdiabetictable = [];
  public pedmedicdiabetictable = [];

  public time_txt: string;
  public diab_medshow: boolean = false;
  public diagdiabtable = [];
  public diagdiabtable1 = [];
  public diag_test_flag: boolean = true;
  public diab_diagshow: boolean;
  public bldnew: string;
  public scannew: string;
  public urinew: string;
  public fanew: string;
  public xnew: string;
  public ulnew: string;
  public ICD_Code: string;
  public case_right: string;
  public case_right_flag: boolean = false;
  public foot_ass_flag: boolean = false;

  public Case_left: string;
  public Case_left_flag: boolean = false;

  public doc_app_id;

  //nephro_case_summary
  public medicalnephtable = [];
  public diet_habits_txt: string;
  public neph_dis: string;
  public neph_icd: string;
  public icd_desr: string;
  public treat_txt: string;
  public dys_type: string;
  public dia_freq: string;
  public skin_txt: string;
  public hear_txt: string;
  public abdom_txt: string;
  public ret_txt: string;
  public card_txt: string;
  public pum_txt: string;
  public dis_cause: string;
  Diab_Graph_div_flag: boolean = true;
  public medical_nephtable: boolean = false;

  //pediatrics_case_summary
  public child_name;
  public mother_age;
  public noof_preg;
  public delivery_type;
  public gestation_age;
  public complication_txt;
  public living_condition;
  public feeding_habits;
  public allergies_txt;
  public diseases_txt;
  public icd_txt;
  public pedmed_flag: boolean = false;

  //General_case_summary
  public f_name: string;
  public name_flag: boolean;
  public rel_type: string;
  public age_txt: string;
  public age_flag: boolean;
  public gen_txt: string;
  public gen_flag: boolean;
  public occ_txt: string;
  public occ_flag: boolean;
  public stress_factor: string;
  public stress_factor_flag: boolean;
  public phys_act: string;
  public phys_act_flag: boolean;
  public sga_score: string;
  public sga_score_flag: boolean;
  public height_txt: string;
  public height_flag: boolean;
  public height_meas: string;
  public weight_txt: string;
  public weight_flag: boolean;
  public weight_meas: string;
  public bmi_txt: string;
  public bmi_flag: boolean;
  public med_hist: string;
  public med_hist_flag: boolean;
  public alc_qty: string;
  public alc_qty_flag: boolean;
  public smk_freq: string;
  public smk_freq_flag: boolean;
  public gutk_freq: string;
  public gutk_freq_flag: boolean;
  public exe_dur: string;
  public exe_dur_flag: boolean;
  public alc_freq: string;
  public alc_freq_flag: boolean;
  public habtiual_info_flag: boolean;

  public userinfo;
  public patient_list;
  public user_id;
  public ds_flag;
  subscription: Subscription;
  public currentDate;
  public confirm_btn: boolean;

  constructor(private http: Http, private routes: ActivatedRoute, private router: Router, public toastr: ToastrService,
    public messageservice: CasesheetService) {
  }

  getDischargeSummaryDetails() {
    var mobile = encrypt_decript.Encript(this.mobile);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", { inpatient_id: this.patient_list.inpatient_id },
      { headers: headers })
      .subscribe(
        response => {
          var values = response.json();
          
          if (values["key"] != 0) {
            this.ds_flag = true;
            this.loader = true;

            if (values != undefined) {
              if (values.middle_name != undefined) {
                this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);

              } else {
                this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
              }

              this.patient_identifier = values.patient_id;
              this.gender = encrypt_decript.Decript(values.gender);
              this.age = values.age;
              var dateval = values.admission_date.split('-');

              this.admis_date = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
              if (values.dr_mname != undefined) {
                this.doctor_name = values.dr_fname + " " + values.dr_mname + " " + values.dr_lname;

              } else {
                this.doctor_name = values.dr_fname + " " + values.dr_lname;
              }

              this.symptoms = values.symptoms;
              this.complaints = values.complaints;

              this.medical_hist = values.medical_history;
              this.physical_exam = values.physical_exam;
              this.inpatient_id = values.inpatient_id;

              this.dis_diag = values.discharge_diagnosis;
              this.hosp_course = values.hospital_course;
              this.hospital_followup = values.follow_summary;

              var nextdate;
              if (values.next_visit != undefined) {
                nextdate = values.next_visit.split('-');
                this.next_data = nextdate[2] + "-" + nextdate[1] + "-" + nextdate[0];
              }

              if (values.drug_list != undefined) {
                for (var i = 0; i < values.drug_list.length; i++) {
                  var master_tablet_data = values.drug_list[i];
                  var medicine_dosage_data = "";

                  if (master_tablet_data.intake_desc == "Every 6 hours") {
                    medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + '-' + master_tablet_data.morning + master_tablet_data.short_form + '-' + master_tablet_data.morning + master_tablet_data.short_form + master_tablet_data.short_form + '-' + master_tablet_data.morning + master_tablet_data.short_form;

                  } else {
                    medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + '-' + master_tablet_data.afternoon + master_tablet_data.short_form + '-' + master_tablet_data.evening + master_tablet_data.short_form;
                    
                  }

                  var master_timeDuration = "";
                  if (master_tablet_data.drug_intake == "With food") {
                    master_timeDuration = master_tablet_data.drug_intake;

                  } else {
                    if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                      master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;

                    } else {
                      master_timeDuration = master_tablet_data.drug_intake;
                    }
                  }

                  this.druglist.push({
                    medicine_name: master_tablet_data.short_name + " " + master_tablet_data.drug_name,
                    medicine_dosage: medicine_dosage_data,
                    medicine_intakedays: master_tablet_data.days + ' days ',
                    medicine_food: master_timeDuration
                  })
                }
              }

              this.hosp_name = values.hptl_name;
              var addval;
              if (values.address2 != undefined) {
                addval = values.address1 + "," + values.address2;

              } else {
                addval = values.address1;
              }
              this.hosp_addr = addval;
              this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
              this.hosp_count = values.country;
              if (values.telephone != undefined) {
                this.hosp_tel = values.telephone;

              } else {
                this.hosp_tel = "-";
              }

              if (values.website != undefined) {
                this.hosp_web = values.website;
                this.hosp_web_flag = false;

              } else {
                this.hosp_web_flag = true;
              }
            }
          } else {
            this.ds_flag = false;
            this.loader = true;
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      this.dischargesummary = message;
    });
    this.confirm_btn = false;
    if(Doc_Helper.getAppFlow() == "client" ){
      this.patient_list = Helper_Class.getInfo();
      this.userinfo = Helper_Class.getclient_hospitalDetails();
    }else{
      this.userinfo = Helper_Class.getInfo();
      this.patient_list = Doc_Helper.getClient_Info();
    }

    if(Helper_Class.getInpatientFlow() == "nurse"){
      this.user_id = this.patient_list.user_id;
    }else{
      this.user_id = this.userinfo.user_id;
    }

    this.getCurrentDate();

    this.mobile = this.patient_list.mobile;
    this.Is_specilization = this.patient_list.spl;
    var oflag: boolean = false;

    //case_summary
    this.Get_Case_details();

    if (this.mobile != undefined) {
      this.getDischargeSummaryDetails();
    }

    // //case_summary
    // this.Get_Case_details();

    this.home_care = Helper_Class.getapp_flow();
    if (this.patient_list.spl == "General") {
      this.spl_flag = true;
    } else {
      this.spl_flag = false;
    }

    if (this.patient_list.Age_data != null) {
      if (this.patient_list.Age_data != "0") {
        this.age_txt = this.patient_list.Age_data;
      } else {
        this.age_txt = "<1";
      }
    } else {
      this.age_txt = "";
    }
    Helper_Class.setClntGender(this.patient_list.Gender_data);

    //diab_case_summary
    this.Client_id = this.patient_list.Client_id;
    this.rel_id = this.patient_list.rel_id;
    this.sub_id = this.patient_list.sub_id;
    this.specilization_txt = this.patient_list.spl;
    this.doc_app_id = this.patient_list.app_id;
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    
    if (obj.current_date != null) {
      this.dis_date = obj.current_date;
      this.currentDate = obj.current_date;
    }
  }

  Get_Case_details() {
    var getcasesumret = Helper_Class.getcasesumret();
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var visit_purposetxt = new StringBuilder("");
    
    //general_
    if (getcasesumret != undefined && getcasesumret.first_name != null) {
      this.Others_flag = true;
      this.name_flag = true;
      if (getcasesumret.middle_name != null && encrypt_decript.Decript(getcasesumret.middle_name) != undefined) {
        this.f_name = encrypt_decript.Decript(getcasesumret.first_name) + " " + encrypt_decript.Decript(getcasesumret.middle_name) + " " + encrypt_decript.Decript(getcasesumret.last_name);
      }
      else {
        this.f_name = encrypt_decript.Decript(getcasesumret.first_name) + " " + encrypt_decript.Decript(getcasesumret.last_name);
      }
      this.gen_txt = encrypt_decript.Decript(getcasesumret.gender);
      if (this.gen_txt.length != 0) {
        this.gen_flag = true;
      }
      else {
        this.gen_flag = false;
      }
      Helper_Class.setClntGender(this.gen_txt);
      if (getcasesumret.age != undefined) {
        if (getcasesumret.age == "0") {
          this.age_txt = "<1";
        } else {
          this.age_txt = getcasesumret.age;
          this.age_flag = true;
        }
        Helper_Class.setClntAge(this.age_txt);
      }
      // else if(this.patient_list.Age_data != 'undefined' ){
      //   if(this.patient_list.Age_data !="0"){
      //     Helper_class.setClntAge(this.patient_list.Age_data);
      //     this.age_txt=this.patient_list.Age_data;
      //   } else {
      //     Helper_class.setClntAge("<1");
      //   }

      // } else {
      //   Helper_class.setClntAge("");
      //   this.age_txt="";
      // }

      this.occ_txt = getcasesumret.occupation;
      if (this.occ_txt != undefined && this.occ_txt.length != 0) {
        this.occ_flag = true;
      }
      else {
        this.occ_flag = false;
      }
      if (getcasesumret.stress_factor != null) {
        this.stress_factor = getcasesumret.stress_factor;
        this.stress_factor_flag = true;
      }
      else {
        this.stress_factor_flag = false;
      }

      if (getcasesumret.physical_activity != null) {
        this.phys_act = getcasesumret.physical_activity;
        this.phys_act_flag = true;
      }
      else {
        this.phys_act_flag = false;
      }

      if (getcasesumret.sga_score != null) {
        this.sga_score = getcasesumret.sga_score;
        this.sga_score_flag = true;
      }
      else {
        this.sga_score_flag = false;
      }

      if (getcasesumret.height != null) {
        this.height_txt = getcasesumret.height;
        this.height_flag = true;
      }
      else {
        this.height_flag = false;
      }

      if (getcasesumret.height_measure != null) {
        this.height_meas = getcasesumret.height_measure;
      }

      if (getcasesumret.weight != null) {
        this.weight_txt = getcasesumret.weight;
        this.weight_flag = true;
      }
      else {
        this.weight_flag = false;
      }

      if (getcasesumret.weight_measure != null) {
        this.weight_meas = getcasesumret.weight_measure;

        if (getcasesumret.weight_measure == "kgs" && getcasesumret.height_measure == "inch") {
          var pound = getcasesumret.weight * 2.20462;
          var Height_txt = parseFloat(getcasesumret.height)
          var dat = pound / (Height_txt * Height_txt);
          var to = dat * 703;
          this.bmi_txt = Math.round(to).toString();

        } else if (getcasesumret.weight_measure == "kgs" && getcasesumret.height_measure == "cms") {
          var meter = parseFloat(getcasesumret.height) / 100.00;
          var to = parseFloat(getcasesumret.weight) / (meter * meter);
          this.bmi_txt = Math.round(to).toString();

        } else if (getcasesumret.weight_measure == "lbs" && getcasesumret.height_measure == "inch") {
          var Height_txt1 = getcasesumret.height;
          var dat = getcasesumret.weight / (Height_txt1 * Height_txt1);
          var to = dat * 703;
          this.bmi_txt = Math.round(to).toString();

        } else if (getcasesumret.weight_measure == "lbs" && getcasesumret.height_measure == "cms") {
          var Height_txt1 = getcasesumret.height;
          var inch = Height_txt1 * 0.393701;
          var dat = getcasesumret.weight / (inch * inch);
          var to = dat * 703;
          this.bmi_txt = Math.round(to).toString();
        }
      }

      var med_hist = new StringBuilder("");

      if (getcasesumret.medical_history != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.medical_history.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.medical_history[i] != "") {
              med_hist.append(",");
              med_hist.append("\n");
            }
          }
          med_hist.append(getcasesumret.medical_history[i]);
        }
        this.med_hist = med_hist.toString();
        if (this.med_hist.length != 0) {
          this.med_hist_flag = true;
        }
        else {
          this.med_hist_flag = false;
        }
      }

      if (getcasesumret.alc_frequency != null) {
        this.alc_freq = getcasesumret.alc_frequency;
        this.alc_freq_flag = true;
      }
      else {
        this.alc_freq_flag = false;
      }

      if (getcasesumret.alc_qty_desc != null) {
        this.alc_qty = getcasesumret.alc_qty_desc;
        this.alc_qty_flag = true;
      }
      else {
        this.alc_qty_flag = false;
      }

      if (getcasesumret.smoke_frequency != null) {
        this.smk_freq = getcasesumret.smoke_frequency;
        this.smk_freq_flag = true;
      }
      else {
        this.smk_freq_flag = false;
      }

      if (getcasesumret.gutka_frequency != null) {
        this.gutk_freq = getcasesumret.gutka_frequency;
        this.gutk_freq_flag = true;
      }
      else {
        this.gutk_freq_flag = false;
      }

      // exe duration
      if (getcasesumret.exer_duration != null) {
        this.exe_dur = getcasesumret.exer_duration;
        this.exe_dur_flag = true;
      }
      else {
        this.exe_dur_flag = false;
      }

      if (this.alc_freq_flag == true || this.alc_qty_flag == true || this.smk_freq_flag == true || this.gutk_freq_flag == true || this.exe_dur_flag == true) {
        this.habtiual_info_flag = true;
      }
      else {
        this.habtiual_info_flag = false;
      }
    } else {
      if (Helper_Class.Get_client_view_app_data().middle_name != undefined) {
        this.f_name = encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().first_name) + " " + encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().middle_name) + " " + encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().last_name);
      }
      else {
        this.f_name = encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().first_name) + " " + encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().last_name);
      }
      this.gen_txt = encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().gender);
      this.age_txt = Helper_Class.getClntAge();
    }


    //gyno
    if (getcasesumret != undefined && getcasesumret.gynaecology != undefined) {
      this.Gynecology_flag = true;
      if (getcasesumret.gynaecology.visit_purpose != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.visit_purpose.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.visit_purpose[i] != "") {
              visit_purposetxt.append(",");
              visit_purposetxt.append("\n");
            }
          }
          visit_purposetxt.append(getcasesumret.gynaecology.visit_purpose[i]);
        }
        this.Visit_purpose = visit_purposetxt.toString();
      }

      var main_concentxt = new StringBuilder("");
      if (getcasesumret.gynaecology.main_concern != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.main_concern.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.main_concern[i] != "") {
              main_concentxt.append(",");
              main_concentxt.append("\n");
            }
          }
          main_concentxt.append(getcasesumret.gynaecology.main_concern[i]);
        }
        this.Main_concern = main_concentxt.toString();
      }

      var dis_data = new StringBuilder("");
      if (getcasesumret.gynaecology.disease != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.disease.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.disease[i] != "") {
              dis_data.append(",");
              dis_data.append("\n");
            }
          }
          dis_data.append(getcasesumret.gynaecology.disease[i]);
        }
        this.Disease_txt = dis_data.toString();
      }

      var present_data = new StringBuilder("");
      if (getcasesumret.gynaecology.present_illness != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.present_illness.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.present_illness[i] != "" && getcasesumret.gynaecology.present_illness[i] != null) {
              present_data.append(",");
              present_data.append("\n");
            }
          }
          present_data.append(getcasesumret.gynaecology.present_illness[i]);
        }
        this.Present_illness = present_data.toString();
      }

      var periods_data = new StringBuilder("");
      if (getcasesumret.gynaecology.periods != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.periods.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.periods[i] != "") {
              periods_data.append(",");
              periods_data.append("\n");
            }
          }
          periods_data.append(getcasesumret.gynaecology.periods[i]);
        }
        this.Menstrual_periods = periods_data.toString();
      }

      if (getcasesumret.gynaecology.icd10 != null) {
        for (var i = 0; i < getcasesumret.gynaecology.icd10.length; i++) {
          if (getcasesumret.gynaecology.icd10[i].icd_code != undefined
            && getcasesumret.gynaecology.icd10[i].icd_desc != undefined) {
            this.Icd_txt = getcasesumret.gynaecology.icd10[i].icd_code + " - " + getcasesumret.gynaecology.icd10[i].icd_desc;
          }
        }
      }

      //surgieries
      if (getcasesumret.gynaecology.Surgeries != null) {
        this.gyna_list = [];
        for (var i = 0; i < getcasesumret.gynaecology["Surgeries"].length; i++) {
          if (getcasesumret.gynaecology["Surgeries"][i]["D&C"] != null) {
            this.gyna_list.push({
              Surgeries: "D&C",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["D&C"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["Hysteroscopy"] != null) {
            this.gyna_list.push({
              Surgeries: "Hysteroscopy",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["Hysteroscopy"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["Ovarian_Surgery"] != null) {
            this.gyna_list.push({
              Surgeries: "Ovarian surgery",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["Ovarian_Surgery"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["infertility_surgery"] != null) {
            this.gyna_list.push({
              Surgeries: "Infertility surgery",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["infertility_surgery"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["right_cyst_removed"] != null) {
            this.gyna_list.push({
              Surgeries: "Right cyst removed",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["right_cyst_removed"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["left_cyst_removed"] != null) {
            this.gyna_list.push({
              Surgeries: "Left cyst removed",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["left_cyst_removed"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["right_ovary_removed"] != null) {
            this.gyna_list.push({
              Surgeries: "Right ovary removed",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["right_ovary_removed"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["left_ovary_removed"] != null) {
            this.gyna_list.push({
              Surgeries: "Left ovary removed",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["left_ovary_removed"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["Tuboplasty"] != null) {
            this.gyna_list.push({
              Surgeries: "Tuboplasty",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["Tuboplasty"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["tubal_ligation"] != null) {
            this.gyna_list.push({
              Surgeries: "Tubal ligation",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["tubal_ligation"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["hysterectomy_abdominal"] != null) {
            this.gyna_list.push({
              Surgeries: "Hysterectomy - abdominal",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["hysterectomy_abdominal"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["hysterectomy_vaginal"] != null) {
            this.gyna_list.push({
              Surgeries: "Hysterectomy - vaginal",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["hysterectomy_vaginal"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["bladder_repair"] != null) {
            this.gyna_list.push({
              Surgeries: "Bladder repair",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["bladder_repair"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["myomectomy"] != null) {
            this.gyna_list.push({
              Surgeries: "Myomectomy",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["myomectomy"],
            })
          }

          if (getcasesumret.gynaecology["Surgeries"][i]["others"] != null) {
            this.gyna_list.push({
              Surgeries: "others",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["others"],
            })
          }
        }
      }
    }

    //diab
    if (getcasesumret != undefined && getcasesumret.diabetes != undefined) {
      this.Diabetes_flag = true;

      if (getcasesumret.diabetes.disease_type != null) {
        this.Disease_type = getcasesumret.diabetes.disease_type;
      }

      if (getcasesumret.diabetes.foot_assessment != null) {
        var footright = new StringBuilder("");
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.foot_assessment.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.foot_assessment[i].foot_type == "right") {
              if (getcasesumret.diabetes.foot_assessment[i].risk_level != "") {
                footright.append(",");
                footright.append("\n");
              }
            }
          }

          if (getcasesumret.diabetes.foot_assessment[i].foot_type == "right") {
            footright.append(getcasesumret.diabetes.foot_assessment[i].risk_level);
          }
        }
        this.case_right = footright.toString();
      }
      if (this.case_right.length != 0) {
        this.case_right_flag = true;
      }
      else {
        this.case_right_flag = false;
      }


      if (getcasesumret.diabetes.foot_assessment != null) {
        var footleft = new StringBuilder("");
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.foot_assessment.length; i++) {
          if (getcasesumret.diabetes.foot_assessment[i].foot_type == "left") {
            n = n + 1;
          }
          if (n > 1) {
            if (getcasesumret.diabetes.foot_assessment[i].foot_type == "left") {
              if (getcasesumret.diabetes.foot_assessment[i].risk_level != "") {
                footleft.append(",");
                footleft.append("\n");
              }
            }
          }

          if (getcasesumret.diabetes.foot_assessment[i].foot_type == "left") {
            footleft.append(getcasesumret.diabetes.foot_assessment[i].risk_level);
          }
        }
        this.Case_left = footleft.toString();
      }
      if (this.Case_left.length != 0) {
        this.Case_left_flag = true;
      }
      else {
        this.Case_left_flag = false;
      }

      if (this.case_right_flag == true || this.Case_left_flag == true) {
        this.foot_ass_flag = true;
      }
      else {
        this.foot_ass_flag = false;
      }

      if (getcasesumret.diabetes.icd_codes != null) {
        var icd = new StringBuilder("");

        if (getcasesumret.diabetes.icd_codes != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.diabetes.icd_codes.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.diabetes.icd_codes[i] != "") {
                icd.append(",");
                icd.append("\n");
              }
            }
            if (getcasesumret.diabetes.icd_codes[i].icd10_code != undefined
              && getcasesumret.diabetes.icd_codes[i].icd10_desc != undefined) {
              icd.append(getcasesumret.diabetes.icd_codes[i].icd10_code + " - " + getcasesumret.diabetes.icd_codes[i].icd10_desc);
            }

          }
          this.ICD_Code = icd.toString();
        }
      }

      if (getcasesumret.diabetes.diet_habits != null) {
        this.Case_dietary = getcasesumret.diabetes.diet_habits;
      }

      var concern_txt = new StringBuilder("");

      if (getcasesumret.diabetes.main_concerns != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.main_concerns.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.main_concerns[i] != "") {
              concern_txt.append(",");
              concern_txt.append("\n");
            }
          }
          concern_txt.append(getcasesumret.diabetes.main_concerns[i]);
        }
        this.Case_concern = concern_txt.toString();
      }

      var cardio_list = new StringBuilder("");
      if (getcasesumret.diabetes.cardio_dis != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.cardio_dis.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.cardio_dis[i] != "") {
              cardio_list.append(",");
              cardio_list.append("\n");
            }
          }
          cardio_list.append(getcasesumret.diabetes.cardio_dis[i].description);
        }
        this.Case_cardio = cardio_list.toString();
      }
      if (this.Case_cardio.length != 0) {
        this.Case_cardio_flag = true;
      }
      else {
        this.Case_cardio_flag = false;
      }


      var mobil_list = new StringBuilder("");
      if (getcasesumret.diabetes.mobility != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.mobility.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.mobility[i] != "") {
              mobil_list.append(",");
              mobil_list.append("\n");
            }
          }
          mobil_list.append(getcasesumret.diabetes.mobility[i].description);
        }
        this.Case_mobility = mobil_list.toString();
      }
      if (this.Case_mobility.length != 0) {
        this.Case_mobility_flag = true;
      }
      else {
        this.Case_mobility_flag = false;
      }

      var neph_list = new StringBuilder("");

      if (getcasesumret.diabetes.neprho_dis != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.neprho_dis.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.neprho_dis[i] != "") {
              neph_list.append(",");
              neph_list.append("\n");
            }
          }
          neph_list.append(getcasesumret.diabetes.neprho_dis[i].description);
        }
        this.Case_nephro = neph_list.toString();
      }
      if (this.Case_nephro.length != 0) {
        this.Case_nephro_flag = true;
      }
      else {
        this.Case_nephro_flag = false;
      }

      var retion_list = new StringBuilder("");
      if (getcasesumret.diabetes.retino_dis != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.retino_dis.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.retino_dis[i] != "") {
              retion_list.append(",");
              retion_list.append("\n");
            }
          }
          retion_list.append(getcasesumret.diabetes.retino_dis[i].description);
        }
        this.Case_retino = retion_list.toString();
      }
      if (this.Case_retino.length != 0) {
        this.Case_retino_flag = true;
      }
      else {
        this.Case_retino_flag = false;
      }

      if (this.Case_cardio_flag == true || this.Case_nephro_flag == true || this.Case_retino_flag == true || this.Case_mobility_flag == true) {
        this.Complications_flag = true;
      }
      else {
        this.Complications_flag = false;

      }

      this.medicdiabetictable = [];
      if (getcasesumret.diabetes.med_prescriptions != null) {
        for (var i = 0; i < getcasesumret.diabetes.med_prescriptions.length; i++) {
          if (getcasesumret.diabetes.med_prescriptions[i].intake_desc == "Every 6 hours") {
            if (getcasesumret.diabetes.med_prescriptions[i].drug_intake == "With Food") {
              this.time_txt = "-"

            } else {
              this.time_txt = getcasesumret.diabetes.med_prescriptions[i].time_duration + " " + "mins"
            }

          } else {
            if (getcasesumret.diabetes.med_prescriptions[i].drug_intake == "With Food") {
              if (getcasesumret.diabetes.med_prescriptions[i].time_duration != "0") {
                this.time_txt = getcasesumret.diabetes.med_prescriptions[i].time_duration
                this.time_txt = "-"
              }

            } else {
              if (getcasesumret.diabetes.med_prescriptions[i].time_duration != "0") {
                this.time_txt = getcasesumret.diabetes.med_prescriptions[i].time_duration + " " + "mins"

              } else {
                this.time_txt = "-"
              }
            }

          }

          this.medicdiabetictable.push({
            drug_type_name: getcasesumret.diabetes.med_prescriptions[i].drug_type_name,
            drug_name: getcasesumret.diabetes.med_prescriptions[i].drug_name,
            days: getcasesumret.diabetes.med_prescriptions[i].days,
            quan_tx: getcasesumret.diabetes.med_prescriptions[i].intake_desc + " " + getcasesumret.diabetes.med_prescriptions[i].morning + " - " + getcasesumret.diabetes.med_prescriptions[i].afternoon + " - " + getcasesumret.diabetes.med_prescriptions[i].evening,
            with_time: this.time_txt,
            drug_intake: getcasesumret.diabetes.med_prescriptions[i].drug_intake,
          });
        }
      }
      if (this.medicdiabetictable.length != 0) {
        this.diab_medshow = true;

      } else {
        this.diab_medshow = false;
      }

      if (getcasesumret.diabetes.tests != null) {
        for (var i = 0; i < getcasesumret.diabetes.tests.length; i++) {
          this.diagdiabtable.push(
            getcasesumret.diabetes.tests[i]
          )
        }

        if (this.diagdiabtable.length != 0) {
          this.diab_diagshow = false;

        } else {
          this.diab_diagshow = true;
        }
      }

      for (var i = 0; i < this.diagdiabtable.length; i++) {
        if (this.diagdiabtable[i] == "blood_tests") {
          if (getcasesumret.diabetes.blood_tests != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes.blood_tests.length; j++) {
              if (this.diagdiabtable[i] == "blood_tests") {
                this.bldnew = "Blood Test"
              }

              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.blood_tests[j].test_name);
            }

            this.diagdiabtable1.push({
              testself: this.bldnew,
              test_name: sb.toString()
            });
          }

        } else if (this.diagdiabtable[i] == "scan_tests") {
          if (getcasesumret.diabetes.scan_tests != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes.scan_tests.length; j++) {
              if (this.diagdiabtable[i] == "scan_tests") {
                this.scannew = "Scan Test"
              }
              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.scan_tests[j].test_name);
            }

            this.diagdiabtable1.push({
              testself: this.scannew,
              test_name: sb.toString()
            });
          }

        } else if (this.diagdiabtable[i] == "urine_tests") {
          if (getcasesumret.diabetes.urine_tests != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes.urine_tests.length; j++) {
              if (this.diagdiabtable[i] == "urine_tests") {
                this.urinew = "Urine Test"
              }
              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.urine_tests[j].test_name);
            }

            this.diagdiabtable1.push({
              testself: this.urinew,
              test_name: sb.toString()
            });
          }

        } else if (this.diagdiabtable[i] == "faeces_tests") {
          if (getcasesumret.diabetes.faeces_tests != null) {
            var sb = new StringBuilder("");
            var n = 0;

            for (var j = 0; j < getcasesumret.diabetes.faeces_tests.length; j++) {
              if (this.diagdiabtable[i] == "faeces_tests") {
                this.fanew = "Faeces Test"
              }

              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.faeces_tests[j].test_name);
            }

            this.diagdiabtable1.push({
              testself: this.fanew,
              test_name: sb.toString()
            });
          }

        } else if (this.diagdiabtable[i] == "xray_tests") {
          if (getcasesumret.diabetes["xray_tests"] != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes["xray_tests"].length; j++) {
              if (this.diagdiabtable[i] == "xray_tests") {
                this.xnew = "Xray Test"
              }

              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.xray_tests[j].test_name);
            }

            this.diagdiabtable1.push({
              testself: this.xnew,
              test_name: sb.toString()
            });
          }

        } else if (this.diagdiabtable[i] == "ultra-sound_tests") {
          if (getcasesumret.diabetes["ultra-sound_tests"] != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes["ultra-sound_tests"].length; j++) {
              if (this.diagdiabtable[i] == "ultra-sound_tests") {
                this.ulnew = "Ultra Sound"
              }

              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes["ultra-sound_tests"][j].test_name);
            }

            this.diagdiabtable1.push({
              testself: this.ulnew,
              test_name: sb.toString()
            });
          }
        }
      }
      if (this.diagdiabtable1.length != 0) {
        this.diag_test_flag = false;
      }
      else {
        this.diag_test_flag = true;
      }

      //Graph Data
      // if (getcasesumret.diabetes["bgdate"] != null && getcasesumret.diabetes["bgdate"].length != 0) {
      //             this.Diab_Graph_div = {
      //                 "display": "block"
      //             }

      //             var BGdate_list = new Array();
      //             var UGdate_list = new Array();
      //             var Fasting_list = new Array();
      //             var OneHour_list = new Array();
      //             var Onehalf_list = new Array();
      //             var TwoHours_list = new Array();
      //             var Urine_Glucose_List = new Array();
      //             var Ketones_List = new Array();
      //             var Proteins_List = new Array();

      //             if (getcasesumret.diabetes["bgdate"] != null) {
      //                 for (var i = 0; i < getcasesumret.diabetes["bgdate"].length; i++) {
      //                     BGdate_list.push(getcasesumret.diabetes["bgdate"][i]);
      //                 }
      //             }

      //             if (getcasesumret.diabetes["ugdate"] != null) {
      //                 for (var i = 0; i < getcasesumret.diabetes["ugdate"].length; i++) {
      //                     UGdate_list.push(getcasesumret.diabetes["ugdate"][i]);
      //                 }
      //             }

      //             if (getcasesumret.diabetes["fasting"] != null) {
      //                 for (var i = 0; i < getcasesumret.diabetes["fasting"].length; i++) {
      //                     Fasting_list.push(getcasesumret.diabetes["fasting"][i]);
      //                 }
      //             }

      //             if (getcasesumret.diabetes["onehour"] != null) {

      //                 for (var i = 0; i < getcasesumret.diabetes["onehour"].length; i++) {
      //                     OneHour_list.push(getcasesumret.diabetes["onehour"][i]);
      //                 }
      //             }

      //             if (getcasesumret.diabetes["onehalf"] != null) {
      //                 for (var i = 0; i < getcasesumret.diabetes["onehalf"].length; i++) {
      //                     Onehalf_list.push(getcasesumret.diabetes["onehalf"][i]);
      //                 }
      //             }

      //             if (getcasesumret.diabetes["twohours"] != null) {
      //                 for (var i = 0; i < getcasesumret.diabetes["twohours"].length; i++) {
      //                     TwoHours_list.push(getcasesumret.diabetes["twohours"][i]);
      //                 }
      //             }

      //             if (getcasesumret.diabetes["urineglucose"] != null) {
      //                 for (var i = 0; i < getcasesumret.diabetes["urineglucose"].length; i++) {
      //                     Urine_Glucose_List.push(getcasesumret.diabetes["urineglucose"][i]);
      //                 }
      //             }

      //             if (getcasesumret.diabetes["ketones"] != null) {
      //                 for (var i = 0; i < getcasesumret.diabetes["ketones"].length; i++) {
      //                     Ketones_List.push(getcasesumret.diabetes["ketones"][i]);
      //                 }
      //             }

      //             if (getcasesumret.diabetes["proteins"] != null) {
      //                 for (var i = 0; i < getcasesumret.diabetes["proteins"].length; i++) {
      //                     Proteins_List.push(getcasesumret.diabetes["proteins"][i]);
      //                 }
      //             }
      //             // if (Fasting_list.length == 0) {
      //             //     $("#Blood_Unavailable").show();
      //             //     $("#Blood_Glucose_Chart").hide();

      //             // } else {
      //             //     $("#Blood_Unavailable").hide();
      //             //     $("#Blood_Glucose_Chart").show();
      //             // }


      //             // this.bloodGraph(Fasting_list, OneHour_list, Onehalf_list, TwoHours_list, BGdate_list);
      //             

      //             // this.uineGraph(Urine_Glucose_List, Ketones_List, Proteins_List, UGdate_list);
      //             // if (Urine_Glucose_List.length != 0 || Ketones_List.length != 0 || Proteins_List.length != 0) {
      //             //     $("#Urine_Charts").show();
      //             //     $("#Urine_Unavailable").hide();

      //             // } else {
      //             //     $("#Urine_Unavailable").show();
      //             //     $("#Urine_Charts").hide();
      //             // }


      //         } else {
      //             this.Diab_Graph_div = {
      //                 "display": "none"
      //             }
      //         }

    } else {
      this.loader = true;
    }

    //nephrology
    if (getcasesumret != undefined && getcasesumret != undefined) {
      //Graph Data
      if (getcasesumret.diag_readings != undefined) {
        for (let value of getcasesumret.diag_readings) {
          // $scope.Nepro_Graph = true;
          this.Diab_Graph_div_flag = false;
          $("#eGFR_Chart_Unavail").hide();
          $("#eGFR_Chart").show();
          $("#Serum_Chart_Unavail").hide();
          $("#Serum_Chart").show();
          $("#Albuminuria_Unavail").hide();
          $("#Albuminuria_Chart").show();
          $("#Proteinuria_Unavail").hide();
          $("#Proteinuria_Chart").show();

          var Datelist = new Array();
          var eGFR_list = new Array();
          var Serum_Creatinine_list = new Array();
          var Albuminuria_list = new Array();
          var Proteinuria_list = new Array();
          var Serum_Measure_list = new Array();
          var Albumin_Measure_List = new Array();

          Datelist.push(value.created_date);
          eGFR_list.push(value.egfr);
          Serum_Creatinine_list.push(value.serum_creatinine);
          Albuminuria_list.push(value.albumin);
          Proteinuria_list.push(value.protein);

          Neprology_graph.Neprograph(Datelist, eGFR_list, Serum_Creatinine_list, Albuminuria_list, Proteinuria_list);
        }
      } else {
        $("#eGFR_Chart_Unavail").show();
        $("#eGFR_Chart").hide();

        $("#Serum_Chart_Unavail").show();
        $("#Serum_Chart").hide();

        $("#Albuminuria_Unavail").show();
        $("#Albuminuria_Chart").hide();

        $("#Proteinuria_Unavail").show();
        $("#Proteinuria_Chart").hide();
      }

      if (getcasesumret.nephrology != undefined) {
        this.Nephrology_flag = true;
        if (getcasesumret.nephrology.main_data != undefined) {
          for (var i = 0; i < getcasesumret.nephrology.main_data.length; i++) {
            var dietlist = new StringBuilder("");

            if (getcasesumret.nephrology.main_data[i].diet_habits != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].diet_habits != "") {
                  dietlist.append(",");
                  dietlist.append("\n");
                }
              }

              dietlist.append(getcasesumret.nephrology.main_data[i].diet_habits);
            }

            this.diet_habits_txt = dietlist.toString();

            var nephdisstr = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].disease != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].disease != "") {
                  nephdisstr.append(",");
                  nephdisstr.append("\n");
                }
              }
              nephdisstr.append(getcasesumret.nephrology.main_data[i].disease);
            }

            this.neph_dis = nephdisstr.toString();

            var icdcodelist = new StringBuilder("");
            var icddislist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].icd_code != null
              && getcasesumret.nephrology.main_data[i].icd_desc != null) {

              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].icd_code != "") {
                  icdcodelist.append(",");
                  icdcodelist.append("\n");
                }
              }

              icdcodelist.append(getcasesumret.nephrology.main_data[i].icd_code);

              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].icd_desc != "") {
                  icddislist.append(",");
                  icddislist.append("\n");
                }
              }
              icddislist.append(getcasesumret.nephrology.main_data[i].icd_desc);
            }

            this.neph_icd = icdcodelist.toString();
            this.icd_desr = icddislist.toString(); //icd desc

            var nephtreatlist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].treatment != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].treatment != "") {
                  nephtreatlist.append(",");
                  nephtreatlist.append("\n");
                }
              }
              nephtreatlist.append(getcasesumret.nephrology.main_data[i].treatment);
            }

            this.treat_txt = nephtreatlist.toString();
            var nephdialist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].dialysis_type != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].dialysis_type != "") {
                  nephdialist.append(",");
                  nephdialist.append("\n");
                }
              }
              nephdialist.append(getcasesumret.nephrology.main_data[i].dialysis_type);
            }

            this.dys_type = nephdialist.toString(); //dialysis_type
            var nephdiafreqlist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].dialysis_freq != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].dialysis_freq != "") {
                  nephdiafreqlist.append(",");
                  nephdiafreqlist.append("\n");
                }
              }
              nephdiafreqlist.append(getcasesumret.nephrology.main_data[i].dialysis_freq);
            }

            this.dia_freq = nephdiafreqlist.toString();
            var nephsinlist = new StringBuilder("");
            if (getcasesumret.nephrology.skin_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.skin_concerns != "") {
                  nephsinlist.append(",");
                  nephsinlist.append("\n");
                }
              }
              nephsinlist.append(getcasesumret.nephrology.skin_concerns);
            }

            this.skin_txt = nephsinlist.toString();
            var nephhearlist = new StringBuilder("");
            if (getcasesumret.nephrology.hearing_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.hearing_concerns != "") {
                  nephhearlist.append(",");
                  nephhearlist.append("\n");
                }
              }
              nephhearlist.append(getcasesumret.nephrology.hearing_concerns);
            }

            this.hear_txt = nephhearlist.toString();
            var abdomaenlist = new StringBuilder("");
            if (getcasesumret.nephrology.abdomen_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.abdomen_concerns != "") {
                  abdomaenlist.append(",");
                  abdomaenlist.append("\n");
                }
              }
              abdomaenlist.append(getcasesumret.nephrology.abdomen_concerns);
            }

            this.abdom_txt = abdomaenlist.toString();
            var retinolist = new StringBuilder("");
            if (getcasesumret.nephrology.retino_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.retino_concerns != "") {
                  retinolist.append(",");
                  retinolist.append("\n");
                }
              }
              retinolist.append(getcasesumret.nephrology.retino_concerns);
            }

            this.ret_txt = retinolist.toString();
            var carslist = new StringBuilder("");
            if (getcasesumret.nephrology.cardio_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.cardio_concerns != "") {
                  carslist.append(",");
                  carslist.append("\n");
                }
              }
              carslist.append(getcasesumret.nephrology.cardio_concerns);
            }

            this.card_txt = carslist.toString();
            var pulmlist = new StringBuilder("");
            if (getcasesumret.nephrology.respiratory_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.respiratory_concerns != "") {
                  pulmlist.append(",");
                  pulmlist.append("\n");
                }
              }
              pulmlist.append(getcasesumret.nephrology.respiratory_concerns);
            }

            this.pum_txt = pulmlist.toString();
            var disclist = new StringBuilder("");
            if (getcasesumret.nephrology.disease_causes != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.disease_causes != "") {
                  disclist.append(",");
                  disclist.append("\n");
                }
              }
              disclist.append(getcasesumret.nephrology.disease_causes);
            }
            this.dis_cause = disclist.toString();

            //Nephrology medical Prescription
            var medicalnephtable = [];
            if (getcasesumret.nephrology.med_prescriptions != null && getcasesumret.nephrology.med_prescriptions != undefined) {
              getcasesumret.nephrology.med_prescriptions.forEach(function (value) {
                if (value.intake_desc == "Every 6 hours") {
                  if (value.drug_intake == "With Food") {
                    var time_txt = "-"

                  } else {
                    time_txt = value.time_duration + " " + "mins"
                  }
                }
                else {
                  if (value.drug_intake == "With Food") {
                    if (value.time_duration != "0") {
                      time_txt = value.time_duration
                      time_txt = "-"
                    }

                  } else {
                    if (value.time_duration != "0") {
                      time_txt = value.time_duration + " " + "mins"

                    } else {
                      time_txt = "-"
                    }
                  }
                }

                medicalnephtable.push({
                  drug_type_name: value.drug_type_name,
                  drug_name: value.drug_name,
                  days: value.days,
                  quan_tx: value.intake_desc + " " + value.morning + " - " + value.afternoon + " - " + value.evening,
                  with_time: time_txt,
                  drug_intake: value.drug_intake,
                });
              });

            }
            if (this.medicalnephtable.length != 0) {
              this.medical_nephtable = true;

            }
            else {
              this.medical_nephtable = false;
            }

          }
        }
      }
    }

    //pediatrics
    if (getcasesumret != undefined && getcasesumret.pediatrics != undefined) {
      this.Pediatrics_flag = true;
      if (getcasesumret.pediatrics != null) {
        if (getcasesumret.pediatrics.name != null) {
          this.child_name = getcasesumret.pediatrics.name;
        }

        if (getcasesumret.pediatrics.mother_age != null) {
          this.mother_age = getcasesumret.pediatrics.mother_age;
        }

        if (getcasesumret.pediatrics.no_of_pregnancy != null) {
          this.noof_preg = getcasesumret.pediatrics.no_of_pregnancy;
        }

        if (getcasesumret.pediatrics.delivery_type != null) {
          this.delivery_type = getcasesumret.pediatrics.delivery_type;
        }

        if (getcasesumret.pediatrics.gestational_age != null) {
          this.gestation_age = getcasesumret.pediatrics.gestational_age;
        }

        var complication_string = new StringBuilder("");
        if (getcasesumret.pediatrics.complications != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.complications.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.complications[i] != "") {
                complication_string.append(",");
                complication_string.append("\n");
              }
            }
            complication_string.append(getcasesumret.pediatrics.complications[i]);
          }
          this.complication_txt = complication_string.toString();
        }

        var living_conditionst = new StringBuilder("");

        if (getcasesumret.pediatrics.living_conditions != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.living_conditions.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.living_conditions[i] != "") {
                living_conditionst.append(",");
                living_conditionst.append("\n");
              }
            }
            living_conditionst.append(getcasesumret.pediatrics.living_conditions[i]);
          }
          this.living_condition = living_conditionst.toString();
        }

        var feed_string = new StringBuilder("");
        if (getcasesumret.pediatrics.feeding_habits != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.feeding_habits.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.feeding_habits[i] != "") {
                feed_string.append(",");
                feed_string.append("\n");
              }
            }
            feed_string.append(getcasesumret.pediatrics.feeding_habits[i]);
          }
          this.feeding_habits = feed_string.toString();
        }

        var allergy_string = new StringBuilder("");
        if (getcasesumret.pediatrics.allergies != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.allergies.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.allergies[i] != "") {
                allergy_string.append(",");
                allergy_string.append("\n");
              }
            }
            allergy_string.append(getcasesumret.pediatrics.allergies[i]);
          }
          this.allergies_txt = allergy_string.toString();
        }

        var dis_string = new StringBuilder("");

        if (getcasesumret.pediatrics.disease != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.disease.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.disease[i] != "") {
                dis_string.append(",");
                dis_string.append("\n");
              }
            }
            dis_string.append(getcasesumret.pediatrics.disease[i]);
          }
          this.diseases_txt = dis_string.toString();
        }

        if (getcasesumret.pediatrics.icd10 != null) {
          for (var i = 0; i < getcasesumret.pediatrics.icd10.length; i++) {
            if (getcasesumret.pediatrics.icd10[i].icd_code != undefined
              && getcasesumret.pediatrics.icd10[i].icd_desc != undefined) {
              this.icd_txt = getcasesumret.pediatrics.icd10[i].icd_code + " - " + getcasesumret.pediatrics.icd10[i].icd_desc;
            }
          }
        }

        this.pedmedicdiabetictable = [];
        if (getcasesumret.pediatrics.med_prescriptions != null) {
          for (var i = 0; i < getcasesumret.pediatrics.med_prescriptions.length; i++) {
            if (getcasesumret.pediatrics.med_prescriptions[i].intake_desc == "Every 6 hours") {
              if (getcasesumret.pediatrics.med_prescriptions[i].drug_intake == "With Food") {
                this.time_txt = "-"

              } else {
                this.time_txt = getcasesumret.pediatrics.med_prescriptions[i].time_duration + " " + "mins"
              }
            } else {
              if (getcasesumret.pediatrics.med_prescriptions[i].drug_intake == "With Food") {
                if (getcasesumret.diabetes.med_prescriptions[i].time_duration != "0") {
                  this.time_txt = getcasesumret.pediatrics.med_prescriptions[i].time_duration
                  this.time_txt = "-"
                }

              } else {
                if (getcasesumret.pediatrics.med_prescriptions[i].time_duration != undefined) {
                  this.time_txt = getcasesumret.pediatrics.med_prescriptions[i].time_duration + " " + "mins"
                }
              }
            }

            this.pedmedicdiabetictable.push({
              drug_type_name: getcasesumret.pediatrics.med_prescriptions[i].drug_type_name,
              drug_name: getcasesumret.pediatrics.med_prescriptions[i].drug_name,
              days: getcasesumret.pediatrics.med_prescriptions[i].days,
              quan_tx: getcasesumret.pediatrics.med_prescriptions[i].intake_desc + " " + getcasesumret.pediatrics.med_prescriptions[i].morning + " - " + getcasesumret.pediatrics.med_prescriptions[i].afternoon + " - " + getcasesumret.pediatrics.med_prescriptions[i].evening,
              with_time: this.time_txt,
              drug_intake: getcasesumret.pediatrics.med_prescriptions[i].drug_intake,
            });
          }
        }
        if (this.pedmedicdiabetictable.length != 0) {
          this.pedmed_flag = true;
        }
        else {
          this.pedmed_flag = false;
        }
      }
    }
  }

  NextDate(e) {
    this.next_data = e;
  }

  saveDischargeSummary() {
    var dateval;
    var newdate;
    if (this.next_data != undefined) {
      dateval = this.next_data.split('-');
      newdate = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
    }

    var medpres;
    if (Helper_Class.get_med_pres_id() != null) {
      medpres = Helper_Class.get_med_pres_id();
    }
    var diagpres;
    if (Helper_Class.get_diag_pres_id() != null) {
      diagpres = Helper_Class.get_diag_pres_id();
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/sdissum', JSON.stringify({
      inpatient_id: this.inpatient_id,
      discharge_diagnosis: this.dis_diag,
      hopital_course: this.hosp_course,
      follow_summary: this.hospital_followup,
      next_visit: newdate,
      pres_drug_id: medpres,
      pres_diag_id: diagpres,
    }),
      { headers: headers })
      .subscribe(
        response => {
          var data = response.json();
          
          if (data.result != "0") {
            this.confirm_btn = true;
            this.toastr.success(Message_data.disSummarySavedSuccess);
          } else {
            this.toastr.error(Message_data.unabltToSaveDisSummary);
          }
        })
  }

  bloodGraph(get_fast_dat, get_one_dat, get_onehalf_dat, get_two_dat, graphDate) {
  }

  uineGraph(urine, ketone, prote, graphDate) {
    

    var dataFirst = {
      label: "Urine Glucose",
      data: urine,
      lineTension: 0.3,
      fill: false,
      borderColor: 'rgb(8,96,0)',
      backgroundColor: 'transparent',
      pointBorderColor: 'rgb(8,96,0)',
      pointBackgroundColor: 'lightgreen',
      pointRadius: 5,
      pointHoverRadius: 15,
      pointHitRadius: 30,
      pointBorderWidth: 2,
      pointStyle: 'rect'
    };

    var dataSecond = {
      label: "Ketone",
      data: ketone,
      lineTension: 0.3,
      fill: false,
      borderColor: 'rgb(255,50,50)',
      backgroundColor: 'transparent',
      pointBorderColor: 'rgb(255,50,50)',
      pointBackgroundColor: 'lightgreen',
      pointRadius: 5,
      pointHoverRadius: 15,
      pointHitRadius: 30,
      pointBorderWidth: 2
    };

    var dataThree = {
      label: "Protein",
      data: prote,
      lineTension: 0.3,
      fill: false,
      borderColor: 'rgb(0,47,135)',
      backgroundColor: 'transparent',
      pointBorderColor: 'rgb(0,47,135)',
      pointBackgroundColor: 'lightgreen',
      pointRadius: 5,
      pointHoverRadius: 15,
      pointHitRadius: 30,
      pointBorderWidth: 2
    };

    var speedData = {
      labels: graphDate,
      datasets: [dataFirst, dataSecond, dataThree]
    };

    var chartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    // var barChart = new Chart(this.urineCanvas.nativeElement, {
    //     type: 'line',
    //     data: speedData,
    //     options: chartOptions
    // });
  }

}
