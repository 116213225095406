import { Component, OnInit,HostListener, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
// import {ipaddress.getIp,ipaddress.Ip_with_img_address} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { TreatmentService } from '../service';
import { ToastrService } from 'ngx-toastr';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Observable, Observer,Subscription } from 'rxjs';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-dental-report',
  templateUrl: './dental-report.component.html',
  styleUrls: ['./dental-report.component.css']
})
export class DentalReportComponent implements OnInit {

  public dentalreporFlag = 'min';
  public header_footer_flag: boolean;
  public report_list=[];
  public reportnodata: boolean;
  public userinfo;
  subscription:Subscription;
  constructor(public http: Http,public toastr:ToastrService,public messageservice:CasesheetService) {     }
 
  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.dentalreporFlag = message;
      }
    });

    this.userinfo=Doc_Helper.getClient_Info();
 
    var sub_rel=this.userinfo.sub_id;
    if (this.userinfo.sub_id != null) {
      var send_data=JSON.stringify({
        client_reg_id:this.userinfo.Client_id,
        relation_id: this.userinfo.rel_id,
        country:"IN",
      })
     
  
    } else {
      send_data=JSON.stringify({
      
        client_reg_id:this.userinfo.Client_id,
        relation_id: this.userinfo.rel_id,
        sub_rel_id: sub_rel,
        country:"IN",

      })
    }
  
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'record/repbyden', send_data,
      { headers: headers })
      .subscribe(
        response => {
         
          var obj =response.json();
          
          
          if(obj.dental_reports.length != 0){
            
            this.report_list=[];
            for (var i = 0; i < obj.dental_reports.length; i++) {
              this.report_list.push({
                image_path:ipaddress.Ip_with_img_address+"/"+obj.dental_reports[i].image_path,
                test_name:obj.dental_reports[i].test_name,
                pres_date:obj.dental_reports[i].pres_date,
                observation:obj.dental_reports[i].observation,
                impression:obj.dental_reports[i].impression,
              })
            }
          } else{
            this.reportnodata=true;
          }
          
        },
        error => {
          
        }
      )  
   
}

 




  report_view(imagepath){
    // var modalPage = this.modalCtrl.create("ReportZoomPage", {
    //   report_image: imagepath,
    //   secopn: "SecOpn"
    // });
    // modalPage.present();
  }


}
