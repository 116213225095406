<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Consolidated Bill</h5>
                    </div>
                    <div class="headerButtons">
                        <a (click)="printBill()"><img src="../../../assets/img/printer.svg" width="25px"
                                height="25px" /></a>
                        <a (click)="Bill_save()" *ngIf="saveFlag"><img
                                src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                                style="margin-left: 7px;" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div">
                    <div class="header_lable">
                        Inpatient details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy">
                                <!-- (click)="location_selected(location) -->
                                <mat-label class="matlabel">Client Name<br>
                                    <input type="text" class="ipcss" [(ngModel)]="appoint_txt" name="loc_name"
                                        (keyup)="getAppointments($event)" type="text" aria-label="Number" matInput
                                        [matAutocomplete]="auto" />
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option id="optionfont" *ngFor="let location of appointment_list"
                                            (click)="Appointment_change(location.client_reg_id,location.relation_id,location.sub_rel_id,location)"
                                            [value]="location.docid_name">
                                            {{location.docid_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                                <mat-label class="matlabel">Filter by<br>
                                    <select required class="ipcss" [(ngModel)]="Filter_txt" required
                                        (change)="Filter_change()" disableOptionCentering>
                                        <option value="Date">Date</option>
                                        <option value="All">All</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="To_show">
                                <mat-label class="matlabel">From<br>
                                    <input type="date" class="ipcss widthbilllist datecss"
                                        (change)="OnDateChangedfrom(f_date)" [hidden]="From_show" [(ngModel)]="f_date"
                                        #matInput max="{{currentDate}}">
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" [hidden]="To_show">
                                <mat-label class="matlabel">To<br>
                                    <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                                        (change)="OnDateChangedto(t_date)" [hidden]="To_show" [(ngModel)]="t_date"
                                        max="{{currentDate}}" matInput>
                                </mat-label>

                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center">
                                <mat-label class="matlabel">
                                    <a (click)="filter_click()"><img
                                            src="../../../assets/ui_icons/buttons/search_button.svg"
                                            class="saveimgbtn_inpatinfo topvalue" /></a>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="billTypeCover">
                    <img class="billTypeImg" id="hospBillIMG" (click)="billType('doctor')"
                        src="../../../assets/ui_icons/hospital_bill_selected_icon.png" alt="">
                    <img class="billTypeImg" id="pharBillIMG" (click)="billType('pharma')"
                        src="../../../assets/ui_icons/pharmacy_bill_non_selected_icon.png" alt="">
                    <img class="billTypeImg" id="diagBillIMG" (click)="billType('dignosis')"
                        src="../../../assets/ui_icons/diagnosis_bill_non_selected_icon.png" alt="">
                </div> -->
                <div class="billAreaCover">

                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <div class="coverRow">
                                    <div class="row" style="text-align: center;">
                                        <div class="col-1 my-auto ConHeading" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                            aria-controls="flush-collapseOne">Bill ID</div>
                                        <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                            aria-controls="flush-collapseOne">Created Date</div>
                                        <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                            aria-controls="flush-collapseOne">Bill Amount</div>
                                        <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                            aria-controls="flush-collapseOne">Discount</div>
                                        <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                            aria-controls="flush-collapseOne">Paid Amount</div>
                                        <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                            aria-controls="flush-collapseOne">Balance</div>
                                        <div class="col-1 my-auto">
                                            <img *ngIf="selectedAllFlage == false" (click)="allSelected(true)"
                                                src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                                width="15px" />

                                            <img *ngIf="selectedAllFlage == true" (click)="allSelected(false)"
                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                style="cursor: pointer;" width="15px" />
                                        </div>
                                    </div>
                                </div>
                            </h2>
                        </div>
                        <div class="accordion-item" *ngFor="let bills of DisplayBills;let i = index">
                            <h2 class="accordion-header" id="flush-heading{{i}}">
                                <div class="coverRow">
                                    <div class="row" style="text-align: center;">
                                        <div class="col-1 my-auto ConContent " data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                                            aria-controls="flush-collapse{{i}}">{{bills.billing_id}}</div>
                                        <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                                            aria-controls="flush-collapse{{i}}">{{bills.bill_date}}</div>
                                        <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                                            aria-controls="flush-collapse{{i}}">{{bills.bill_amount}}</div>
                                        <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                                            aria-controls="flush-collapse{{i}}">{{bills.discount}}</div>
                                        <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                                            aria-controls="flush-collapse{{i}}">{{bills.paid_amount}}</div>
                                        <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                                            aria-controls="flush-collapse{{i}}">{{bills.balance}}</div>
                                        <div class="col-1 my-auto ConContent">

                                            <img *ngIf="bills.checked == false" (click)="isAllSelected(bills)"
                                                src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                                width="15px" />

                                            <img *ngIf="bills.checked == true" (click)="isAllSelected(bills)"
                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                style="cursor: pointer;" width="15px" />
                                            <!-- <mdb-checkbox [default]="true" [(ngModel)]="bills.checked" name="list_name" (change)="isAllSelected(bills)"
                                                  value="{{bills.checked}}" [disabled]="bills.balance == '0.00' || bills.balance == '0'"></mdb-checkbox> -->
                                        </div>

                                    </div>
                                </div>
                            </h2>
                            <div id="flush-collapse{{i}}" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading{{0}}" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" style="border-top: 1px solid #84ccf5;">
                                    <table class="table" *ngIf="bills.type == 'doctor' || bills.type == 'diagnosis' ">
                                        <thead class="table-light">
                                            <tr>
                                                <th> S.No </th>
                                                <th> Product Name </th>
                                                <th> Quantity </th>
                                                <th> Amount </th>
                                                <th> Total Amount </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let billslist of bills.billsList;let j = index">
                                                <td> {{j+1}} </td>
                                                <td> {{billslist.name}} </td>
                                                <td> {{billslist.quantity}} </td>
                                                <td style="text-align: right;padding-right:20px"> {{billslist.amount}}
                                                </td>
                                                <td style="text-align: right;padding-right:20px"> {{billslist.fee}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table" *ngIf="bills.type == 'pharma'">
                                        <thead class="table-light">
                                            <tr>
                                                <th> S.No </th>
                                                <th> Product Name </th>
                                                <th> Batch No </th>
                                                <th> Quantity </th>
                                                <th> Amount </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let billslist of bills.billsList;let j = index">
                                                <td> {{j+1}} </td>
                                                <td> {{billslist.name}} </td>
                                                <td> {{billslist.batch_no}} </td>
                                                <td> {{billslist.quantity}} </td>
                                                <td style="text-align: right;padding-right:20px"> {{billslist.amount}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7">
                    </div>
                    <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
                        <div class="row">

                            <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                <mat-label class="matlabel heightmsr">Total </mat-label>
                            </div>
                            <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                                    [(ngModel)]="final_amount" style="text-align: right;" />
                            </div>

                            <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                <mat-label class="matlabel heightmsr">Advance</mat-label>
                            </div>
                            <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right
                                    [(ngModel)]="Advance_bill" maxlength="10" disabled="true"
                                    style="text-align: right;" />
                            </div>
                            <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                <mat-label class="matlabel heightmsr">Paid</mat-label>
                            </div>
                            <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right
                                    (blur)="checkBalance()" [(ngModel)]="paid_bill" maxlength="10"
                                    style="text-align: right;" />
                            </div>
                            <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                <mat-label class="matlabel heightmsr">Balance</mat-label>
                            </div>
                            <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right
                                    [(ngModel)]="remaing_billbal" maxlength="10" disabled="true"
                                    style="text-align: right;" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- print -->
                <div [hidden]="true">
                    <div #printlogowithname id="printlogowithname">
                        <div class="bill_border">
                            <div *ngFor="let billprint of billing_print_array">
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style="width:100%;height: 100px;"></th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <td width="100%">
                                                    <table style="border:'0';width:100%">
                                                        <tr>
                                                            <td style="width:100%;height: 100px;">
                                                                <table style="width: 100%;">
                                                                    <tr>
                                                                        <td
                                                                            style="display: flex;justify-content: center;">
                                                                            <div>
                                                                                <img alt="image" src={{hospital_logo}}
                                                                                    width="100px" height="100px"
                                                                                    style="vertical-align: middle;">
                                                                            </div>
                                                                            <div
                                                                                style="position: relative;top: 10px;left: 10px;text-align: left;">
                                                                                <span><b>{{hospital_name}}</b>,</span><br />
                                                                                <span>{{hospitalAddress}}</span><br />
                                                                                <span>{{hosp_location}}</span>
                                                                                <span>{{hosp_district}}</span><br />
                                                                                <span>{{hosp_state}}
                                                                                    {{hosp_zip}}</span><span
                                                                                    *ngIf="hosp_telephone != undefined"><img
                                                                                        src="../../../assets/img/phone.png"
                                                                                        width="15px"
                                                                                        height="15px" />{{hosp_telephone}}.</span><br>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr>
                                                <td class="report-content-cell">
                                                    <div class="main">
                                                        <div style="width: 90%; margin: 0 auto;">
                                                            <h3 style="margin:10px 0 ;" class="heading">Patient details
                                                            </h3>
                                                            <table style="width: 100%;">
                                                                <tr>
                                                                    <td style="width: 60%; vertical-align: top;">
                                                                        <div>
                                                                            <span><strong>Patient name :
                                                                                </strong>{{billprint.client_name}}
                                                                            </span>
                                                                            <br />
                                                                            <span><strong> Inpatient id :
                                                                                </strong>{{billprint.inpatientId}}</span>
                                                                            <br />
                                                                            <span><strong>Address : </strong></span>
                                                                            <span
                                                                                *ngIf="billprint.address != ''">{{billprint.address}}</span>
                                                                            <br />
                                                                            <span>{{billprint.location}}{{billprint.city}}</span>
                                                                            <br />
                                                                            <span>{{billprint.state}}</span>
                                                                            <br />
                                                                            <span
                                                                                *ngIf="billprint.telephone != undefined">
                                                                                <img src="../../../assets/img/phone.png"
                                                                                    width="15px" height="15px" />
                                                                                {{billprint.telephone}}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td style="width: 40%;vertical-align: top;">
                                                                        <div>
                                                                            <span>Bill date :{{bill_date}} </span>
                                                                            <br />
                                                                            <span>DOA :{{admissionDate}}
                                                                                {{admissionTime}} </span>
                                                                            <br />
                                                                            <span>DOD :{{dischargeDate}}
                                                                                {{dischargeTime}}</span>
                                                                            <br />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                        <!-- Madical Bills particulars -->
                                                        <div *ngIf="medcalDetailsArray.length != 0"
                                                            style="width: 90%; margin: 0 auto;">
                                                            <h3 class="heading">Medicine bills </h3>
                                                            <div class="row" *ngIf="insuranceFlag">
                                                                <table
                                                                    style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Service</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                                                Payment
                                                                                type</th>
                                                                            <th
                                                                                style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Fee</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Concession
                                                                                    (%)</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Amount</b>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let billprinttable of medcalDetailsArray">
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;"
                                                                                data-th="Type">
                                                                                {{billprinttable.bill_type}}</td>
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Type">
                                                                                {{billprinttable.pay_type}}</td>
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Type">
                                                                                {{billprinttable.quantity}}</td>
                                                                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Fee">
                                                                                {{billprinttable.amount_txt}}</td>
                                                                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Concession">
                                                                                {{billprinttable.concession}}</td>
                                                                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Amount" class="fee_txt">
                                                                                {{billprinttable.fee}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="row" *ngIf="!insuranceFlag">
                                                                <table
                                                                    style=" width: 100%; border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                style="width: 75%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Bill No</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Amount</b>
                                                                            </th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let billprinttable of medcalDetailsArray">
                                                                            <td
                                                                                style="width: 75%;text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;">
                                                                                {{billprinttable.name}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;text-align: right;border: 1px solid black;border-collapse: collapse;">
                                                                                {{billprinttable.amount}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <!-- radiology Bills particulars -->
                                                        <div style="width: 90%; margin: 0 auto;"
                                                            *ngIf="digDetailsArray.length !=0">
                                                            <h3 class="heading">Pathologic & Radiologic</h3>
                                                            <div class="row">
                                                                <table
                                                                    style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:100%;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                style="width: 30%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Test Name</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                <b>Quantity</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Fee</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Concession (%)</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Amount</b>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let billprinttable of digDetailsArray">
                                                                            <td
                                                                                style="width: 30%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.name}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.quantity}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.fee}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.concession}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.amount}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <!-- Total calculation  -->
                                                        <div style="width: 100%;">
                                                            <table>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff;">
                                                                    </td>
                                                                    <th style="text-align: left;">Bill amount (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Bill amount" class="fee_txt">
                                                                        {{final_amount}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff">
                                                                    </td>
                                                                    <th style="text-align: left;">Advance (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Advance / Paid र" class="fee_txt">
                                                                        {{Advance_bill}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff">
                                                                    </td>
                                                                    <th style="text-align: left;"> Paid (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Advance / Paid र" class="fee_txt">
                                                                        {{paid_bill}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff;">
                                                                    </td>
                                                                    <th style="text-align: left;">Balance (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Balance" class="fee_txt">
                                                                        {{remaing_billbal}}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <br />
                                                        <br /><br />
                                                        <br />
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <p style="margin-left: 350px;font-size: 13px">
                                                                        <b><i>This is digitally generated bill</i></b>
                                                                    </p>
                                                                    <br />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #printbanner id="printbanner">
                        <div class="bill_border">
                            <div *ngFor="let billprint of billing_print_array">
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style="width:100%;height: 100px;">
                                                    <table style="width: 100%;">
                                                        <tr>
                                                            <td style="display: flex;justify-content: center;">
                                                                <div>
                                                                    <img alt="image" src={{hospital_logo}}
                                                                        style={{printlogostyle}}>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <td width="100%">
                                                    <table style="border:'0';width:100%">
                                                        <tr>
                                                            <td style="width:100%;height: 100px;">&nbsp;</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr>
                                                <td class="report-content-cell">
                                                    <div class="main">
                                                        <div style="width: 90%; margin: 0 auto;">
                                                            <h3 style="margin:10px 0 ;" class="heading">Patient details
                                                            </h3>
                                                            <table style="width: 100%;">
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <div [innerHTML]="headerstyle"></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 60%; vertical-align: top;">
                                                                        <div>
                                                                            <span><strong>Patient name :
                                                                                </strong>{{billprint.client_name}}
                                                                            </span>
                                                                            <br />
                                                                            <span><strong> Inpatient id :
                                                                                </strong>{{billprint.inpatientId}}</span>
                                                                            <br />
                                                                            <span><strong>Address : </strong></span>
                                                                            <span
                                                                                *ngIf="billprint.address != ''">{{billprint.address}}</span>
                                                                            <br />
                                                                            <span>{{billprint.location}}{{billprint.city}}</span>
                                                                            <br />
                                                                            <span>{{billprint.state}}</span>
                                                                            <br />
                                                                            <span
                                                                                *ngIf="billprint.telephone != undefined">
                                                                                <img src="../../../assets/img/phone.png"
                                                                                    width="15px" height="15px" />
                                                                                {{billprint.telephone}}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td style="width: 40%;vertical-align: top;">
                                                                        <div>
                                                                            <span>Bill date :{{bill_date}} </span>
                                                                            <br />
                                                                            <span>DOA :{{admissionDate}}
                                                                                {{admissionTime}} </span>
                                                                            <br />
                                                                            <span>DOD :{{dischargeDate}}
                                                                                {{dischargeTime}}</span>
                                                                            <br />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                        <!-- Madical Bills particulars -->
                                                        <div *ngIf="medcalDetailsArray.length != 0" style="width: 90%; margin: 0 auto;">
                                                            <h3 class="heading">Medicine bills </h3>
                                                            <div class="row" >
                                                                <table
                                                                    style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Product</b>
                                                                            </th>
                                                                            <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                                                Batch no</th>
                                                                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Quantity</b>
                                                                            </th>
                                                                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Concession (%)</b>
                                                                            </th>
                                                                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Amount</b>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let billprinttable of medcalDetailsArray">
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;"
                                                                                data-th="Type">{{billprinttable.name}}</td>
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Type">{{billprinttable.batch_no}}</td>
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Type">{{billprinttable.quantity}}</td>
                                                                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Concession">{{billprinttable.concession}}</td>
                                                                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Amount" class="fee_txt">{{billprinttable.amount}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="row" *ngIf="!insuranceFlag">
                                                                <table
                                                                    style=" width: 100%; border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                style="width: 75%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Bill No</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Amount</b>
                                                                            </th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let billprinttable of medcalDetailsArray">
                                                                            <td
                                                                                style="width: 75%;text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;">
                                                                                {{billprinttable.name}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;text-align: right;border: 1px solid black;border-collapse: collapse;">
                                                                                {{billprinttable.amount}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div> -->
                                                        </div>

                                                        <!-- Total calculation  -->
                                                        <div style="width: 100%;">
                                                            <table>
                                                                <!-- <tr *ngFor="let gsttax of gst_data_list">
                                    <td style="width: 50%;background-color: #ffffff;"></td>
                                    <th style="text-align: left;">{{gsttax.Gst_Descrip}} </th>
                                    <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र"
                                      class="fee_txt">{{gsttax.Gst_amount}}</td>
                                  </tr>  -->
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff;">
                                                                    </td>
                                                                    <th style="text-align: left;">Bill amount (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Bill amount" class="fee_txt">
                                                                        {{final_amount}}</td>
                                                                </tr>
                                                                <!-- <tr>
                                    <td style="width: 71.5%;background-color: #ffffff;"></td>
                                    <th style="text-align: left;">Discount (र)</th>
                                    <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                      class="fee_txt">{{round_off}}</td>
                                  </tr> -->
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff">
                                                                    </td>
                                                                    <th style="text-align: left;">Advance (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Advance / Paid र" class="fee_txt">
                                                                        {{Advance_bill}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff">
                                                                    </td>
                                                                    <th style="text-align: left;"> Paid (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Advance / Paid र" class="fee_txt">
                                                                        {{paid_bill}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff;">
                                                                    </td>
                                                                    <th style="text-align: left;">Balance (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Balance" class="fee_txt">
                                                                        {{remaing_billbal}}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <br />
                                                        <br /><br />
                                                        <br />
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <p style="margin-left: 350px;font-size: 13px">
                                                                        <b><i>This is digitally generated bill</i></b>
                                                                    </p>
                                                                    <br />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #printnoheader id="printnoheader">
                        <div class="bill_border">
                            <div *ngFor="let billprint of billing_print_array">
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style="width:100%;height: 100px;"></th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <td width="100%">
                                                    <table style="border:'0';width:100%">
                                                        <tr>
                                                            <td style="width:100%;height: 100px;">&nbsp;</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr>
                                                <td class="report-content-cell">
                                                    <table style="width: 100%; margin-top: 100px;">
                                                        <tr>
                                                            <td style="display: flex;justify-content: center;">
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <div class="main">
                                                        <div style="width: 90%; margin: 0 auto;">
                                                            <h3 style="margin:10px 0 ;" class="heading">Patient details
                                                            </h3>
                                                            <table style="width: 100%;">
                                                                <tr>
                                                                    <td style="width: 60%; vertical-align: top;">
                                                                        <div>
                                                                            <span><strong>Patient name :
                                                                                </strong>{{billprint.client_name}}
                                                                            </span>
                                                                            <br />
                                                                            <span><strong> Inpatient id :
                                                                                </strong>{{billprint.inpatientId}}</span>
                                                                            <br />
                                                                            <span><strong>Address : </strong></span>
                                                                            <span
                                                                                *ngIf="billprint.address != ''">{{billprint.address}}</span>
                                                                            <br />
                                                                            <span>{{billprint.location}}{{billprint.city}}</span>
                                                                            <br />
                                                                            <span>{{billprint.state}}</span>
                                                                            <br />
                                                                            <span
                                                                                *ngIf="billprint.telephone != undefined">
                                                                                <img src="../../../assets/img/phone.png"
                                                                                    width="15px" height="15px" />
                                                                                {{billprint.telephone}}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td style="width: 40%;vertical-align: top;">
                                                                        <div>
                                                                            <span>Bill date :{{bill_date}} </span>
                                                                            <br />
                                                                            <span>DOA :{{admissionDate}}
                                                                                {{admissionTime}} </span>
                                                                            <br />
                                                                            <span>DOD :{{dischargeDate}}
                                                                                {{dischargeTime}}</span>
                                                                            <br />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                        <!-- Madical Bills particulars -->
                                                        <div *ngIf="medcalDetailsArray.length != 0"
                                                            style="width: 90%; margin: 0 auto;">
                                                            <h3 class="heading">Medicine bills </h3>
                                                            <div class="row" *ngIf="insuranceFlag">
                                                                <table
                                                                    style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Product</b>
                                                                            </th>
                                                                            <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                                                Batch no</th>
                                                                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Quantity</b>
                                                                            </th>
                                                                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Concession (%)</b>
                                                                            </th>
                                                                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Amount</b>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let billprinttable of medcalDetailsArray">
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;"
                                                                                data-th="Type">{{billprinttable.name}}</td>
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Type">{{billprinttable.batch_no}}</td>
                                                                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Type">{{billprinttable.quantity}}</td>
                                                                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Concession">{{billprinttable.concession}}</td>
                                                                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                                                data-th="Amount" class="fee_txt">{{billprinttable.amount}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="row" *ngIf="!insuranceFlag">
                                                                <table
                                                                    style=" width: 100%; border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                style="width: 75%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Bill No</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Amount</b>
                                                                            </th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let billprinttable of medcalDetailsArray">
                                                                            <td
                                                                                style="width: 75%;text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;">
                                                                                {{billprinttable.name}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;text-align: right;border: 1px solid black;border-collapse: collapse;">
                                                                                {{billprinttable.amount}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <!-- radiology Bills particulars -->
                                                        <div style="width: 90%; margin: 0 auto;"
                                                            *ngIf="digDetailsArray.length !=0">
                                                            <h3 class="heading">Pathologic & Radiologic</h3>
                                                            <div class="row">
                                                                <table
                                                                    style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:100%;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                style="width: 30%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Test Name</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                <b>Quantity</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Fee</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Concession (%)</b>
                                                                            </th>
                                                                            <th
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                                                                <b>Amount</b>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let billprinttable of digDetailsArray">
                                                                            <td
                                                                                style="width: 30%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.name}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.quantity}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.fee}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.concession}}
                                                                            </td>
                                                                            <td
                                                                                style="width: 15%;border: 1px solid black;border-collapse: collapse;text-align: right;">
                                                                                {{billprinttable.amount}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <!-- Total calculation  -->
                                                        <div style="width: 100%;">
                                                            <table>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff;">
                                                                    </td>
                                                                    <th style="text-align: left;">Bill amount (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Bill amount" class="fee_txt">
                                                                        {{final_amount}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff">
                                                                    </td>
                                                                    <th style="text-align: left;">Advance (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Advance / Paid र" class="fee_txt">
                                                                        {{Advance_bill}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff">
                                                                    </td>
                                                                    <th style="text-align: left;"> Paid (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Advance / Paid र" class="fee_txt">
                                                                        {{paid_bill}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 71.5%;background-color: #ffffff;">
                                                                    </td>
                                                                    <th style="text-align: left;">Balance (र)</th>
                                                                    <td style="text-align: right;width: 100px;margin-right: 10px;"
                                                                        data-th="Balance" class="fee_txt">
                                                                        {{remaing_billbal}}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <br />
                                                        <br /><br />
                                                        <br />
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <p style="margin-left: 350px;font-size: 13px">
                                                                        <b><i>This is digitally generated bill</i></b>
                                                                    </p>
                                                                    <br />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>