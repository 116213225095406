import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Date_Formate } from '../../../assets/js/common';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import moment from 'moment';
@Component({
  selector: 'app-pharma-inventory-receivables',
  templateUrl: './pharma-inventory-receivables.component.html',
  styleUrls: ['./pharma-inventory-receivables.component.css']
})
export class PharmaInventoryReceivablesComponent implements OnInit {
  public countryID: any;
  public mgfID: any;
  public getPurchaseArray = [];
  public inventory: any = [];
  public pharmacyLocationArray: any;
  public locationIP = "";
  public manufacturer: any;
  public gst_no:string;
  public product_data: any;
  public pharmacistID;
  public invoiceNO: number;
  public poID: any;
  public purchasePoID;
  public batchNO: any;
  public locationArray: any = [];
  public locationName;
  public hsnNO;
  public packageCodeArray: any = [];
  public productArray = [];
  public receivedQuantity;
  public freeQuantity;
  public discount;
  public recvDate;
  public uom;
  public productName;
  public productID;
  public orderProduct;
  public unitPrice;
  public userInfo;
  public userID;
  public pharmacyID;
  public currentDate;
  public suppID;
  public inventoryLength;
  public currentMonthYear;
  public package_data;
  public tax_data;
  public uom_list_data;
  public invoiceAmount:string;
  public invDate: string;

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: PharmacyService) {
    this.getDate();
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userID = this.userInfo.user_id;
    this.pharmacistID = this.userID;
    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.productOrder();
    this.getPackages();
    this.getPharmacy();
    this.freeQuantity = "0";
  }

  productOrder() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpos/", JSON.stringify({ pharmacist_id: this.pharmacistID }), { headers: headers }).subscribe(
      data => {
        var obj = JSON.stringify(data);
        if (data.json().purchase_orders != null) {
          this.product_data = data.json().purchase_orders;
        }
      },
      error => { });
  }

  getPackages() {
    this.http.get(ipaddress.getIp + "pharmacontrol/pkgcode/").subscribe(
      data => {
        if (data.json().product_packages != null) {
          this.package_data = data.json().product_packages;
        }
      },
      error => {});
  }

  getPurchaseDetails() {
    this.inventory = [];
    this.purchasePoID = "";
    this.purchasePoID = this.poID;
    if (this.poID != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gpo/", JSON.stringify({ purchase_order_id: this.poID }), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            var obj = data.json();
            this.mgfID = obj.mfg_id;
            this.suppID = obj.supp_id;
            this.manufacturer = obj.name;
            this.gst_no=obj.gst_no;
            this.countryID = obj.country;
            var index = 0;
            for (var i = 0; i < obj.purchase_items.length; i++) {
              this.inventory.push({
                purchase_request_id: obj.purchase_items[i].purchase_request_id,
                product_id: obj.purchase_items[i].product_id,
                hsn_no: obj.purchase_items[i].hsn_no,
                product_name: obj.purchase_items[i].product_name,
                uom_code: obj.purchase_items[i].uom_code,
                uom_desc: obj.purchase_items[i].uom_desc,
                quantity: obj.purchase_items[i].quantity,
                unit_price: obj.purchase_items[i].unit_price,
                sales_price: "0",
                cost_price : "0",
                pharma_id: this.pharmacyID,
                cgst: obj.purchase_items[i].cgst,
                sgst: obj.purchase_items[i].sgst,
                location: this.locationName,
                expiry_date:"",//this.currentMonthYear,
                index: index,
                checked : false,
              });
              index++;
            }
            this.inventoryLength = this.inventory.length;
          }
        },
      )
    }
  }

  fillOtherDetails() {
    if (this.productName != undefined) {
      for (var i = 0; i < this.getPurchaseArray.length; i++) {
        if (this.productName == this.getPurchaseArray[i].product_name) {
          this.receivedQuantity = this.getPurchaseArray[i].quantity;
          if (this.getPurchaseArray[i].uom_desc != undefined) {
            if (this.uom != undefined) {
              this.uom.uom_desc = this.getPurchaseArray[i].uom_desc;
            }
          }
        }
      }
    }
  }

  getPharmacy() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().pharma_locations != null) {
            this.pharmacyLocationArray = data.json().pharma_locations;
            this.locationArray = this.pharmacyLocationArray;
            this.locationName = this.locationArray[0].location;
          }
        },
        error => { });
  }

  deleteItemReceive(data) {
    if (this.inventory.length != 0) {
      for (var i = 0; i < this.inventory.length; i++) {
        if (this.inventory[i].product_id == data.product_id && this.inventory[i].quantity == data.quantity) {
          if (this.inventory[i].index == data.index)
            this.inventory.splice(i, 1);
        }
      }
    }
  }

  editItemReceive(id, qty) {
    for (var i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].product_id == id && this.inventory[i].quantity == qty) {
        this.productID = this.inventory[i].product_id;
        this.orderProduct = this.inventory[i].hsn_no;
        this.productName = this.inventory[i].product_name;
        this.uom = this.inventory[i].uom_desc;
        this.unitPrice = this.inventory[i].unit_price;
        this.receivedQuantity = this.inventory[i].quantity;
        this.packageCodeArray = this.inventory[i].pack_code,
          this.discount = this.inventory[i].discount,
          this.inventory.splice(i, 1);
      }
    }
  }

  getProductDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpcbypharn/", 
    JSON.stringify({
      pharmacist_id: this.userID,
      prod_name: this.productName,
      mfg_id: this.mgfID,
      pharmacy_id: this.pharmacyID
    }), { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(data["_body"]);
        if (dataval != undefined && dataval.products != null) {
          this.productArray = dataval.products;
        }
      });
  }

  getProductName(data) {
    this.productName = data.name;
    this.uom = data.uom_desc;
    this.productID = data.product_id;
    this.orderProduct = data.product_code;
    this.unitPrice = data.cost_price;
    this.hsnNO = data.hsn_no;
    this.productArray = [];
  }
  
  save_data() {
    var save_flag = true;
    if (this.poID == undefined) {
      save_flag = false;
    }
    if (this.invoiceNO == undefined) {
      save_flag = false;
    }
    if(this.inventory.length != 0){
      var res = this.inventory.filter(o => o.expiry_date_flag == false);
      if(res.length != 0){
        save_flag = false;
        this.toastr.error("Check given expiry date in format mm-yyyy");
      }
    }
    if (save_flag != false) {
      if (this.inventory.length != 0) {
        var inventorySendArray =  this.inventory.filter((x) => { return x.checked == true });
        console.log("inventorySendArray" + JSON.stringify(inventorySendArray));
        var send_data = JSON.stringify({
          invoice_no: this.invoiceNO,
          amount:this.invoiceAmount,
          purchase_order_id: this.purchasePoID,
          country: ipaddress.country_code,
          mfg_id: this.mgfID,
          supp_id: this.suppID,
          recieved_date: this.recvDate,
          invoice_date:this.invDate,
          product_details: inventorySendArray
        });
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "pharmacontrol/upprod/", send_data, { headers: headers }).subscribe(
          data => {
            var obj = JSON.parse(data["_body"]);
            if (obj != undefined && obj.key == "1") {
              this.toastr.success(Message_data.saveSuccess);
              this.messageservice.sendMessage("pharmacycurentinformation");
            } else if (obj != undefined && obj.key == "2") {
              this.toastr.success(Message_data.invoiceExist);
            } else {
              this.toastr.error(Message_data.unableUpldInventory);
            }
          })
      } else {
        this.toastr.error(Message_data.addInventory);
      }
    } else {
      this.toastr.error(Message_data.addMandatory);
    }
  }
  edit(data) {
    this.inventory.splice(data, 1);
  }
  selectLocation() {
    this.locationName = this.locationName;
  }
  clickReceviedDate(date, dataval) {
    dataval.qty_recieved_date1 = (date);
    dataval.qty_recieved_date = Date_Formate(date);
  }
  clearProdName() {
    this.productName = "";
  }
  importFromExcel() {
    var send = { pharma_fac_id: this.locationArray.pharma_facility_id }
    Helper_Class.set_inventory_upload(send);
    this.messageservice.sendMessage("inventory_upload");
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.currentDate = obj.current_date;
          this.recvDate = this.currentDate;
          var mY = this.currentDate.split("-");
          this.currentMonthYear = mY[0] + "-" + mY[1];
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
  addInventory(inventory) {
    var index;
    if (inventory.length == 1) {
      for (var i = (this.inventory.length - 1); i < this.inventory.length; i++) {
        index = this.inventory[i].index + 1;
      }
      this.inventory.push({
        purchase_request_id: inventory[0].purchase_request_id,
        product_id: inventory[0].product_id,
        hsn_no: inventory[0].hsn_no,
        product_name: inventory[0].product_name,
        uom_code: inventory[0].uom_code,
        uom_desc: inventory[0].uom_desc,
        quantity: inventory[0].quantity,
        unit_price: inventory[0].unit_price,
        pharma_id: this.pharmacyID,
        cgst: inventory[0].cgst,
        sgst: inventory[0].sgst,
        location: this.locationName,
        expiry_date:"",//this.currentMonthYear,
        index: index,
        checked : false,
      });
    } else {
      for (var j = 0; j < this.inventoryLength; j++) {
        for (var i = (this.inventory.length - 1); i < this.inventory.length; i++) {
          index = this.inventory[i].index + 1;
        }
        this.inventory.push({
          purchase_request_id: inventory[j].purchase_request_id,
          product_id: inventory[j].product_id,
          hsn_no: inventory[j].hsn_no,
          product_name: inventory[j].product_name,
          uom_code: inventory[j].uom_code,
          uom_desc: inventory[j].uom_desc,
          quantity: inventory[j].quantity,
          unit_price: inventory[j].unit_price,
          pharma_id: this.pharmacyID,
          cgst: inventory[j].cgst,
          sgst: inventory[j].sgst,
          location: this.locationName,
          expiry_date:"",//this.currentMonthYear,
          index: index,
          checked : false,
        });
      }
    }
  }

  batchNo(inventory) {
    var bool: boolean = false;
    for (var i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].index != inventory.index) {
        if (this.inventory[i].product_id == inventory.product_id) {
          if (this.inventory[i].batch_no == inventory.batch_no) {
            this.toastr.error(Message_data.sameBatchNo);
            bool = true;
          }
        }
      }
      if (bool == true) {
        if (this.inventory[i].index == inventory.index) {
          this.inventory[i].batch_no = "";
        }
      }
    }
  }

  changeExpiryDate(e,data){
    // console.log("ExpiryDate  === > "+e);
    // var len = e.length;
    // var flag:boolean = false;
    // if(len <= 2 && len != 0){
    //   e = this.checkValue(e, 12);    
    //   if(e.length > 1 )
    //     flag = true;
    // } 
    // if(len === 2 && flag == true) {
    //   e += '/';
    // }
    // data.expiry_date = e;

    if(length>(length = data.expiry_date.length)) return;
       var mapped = data.expiry_date.split("").map(function(this,char,i){
            switch(i){
                case 0:
                   return char=="1" || char=="0"?char:"";
                  break;
                 case 1:
                   return !isNaN(parseInt(char,10))?(this[0]=="0"?char:(+char<3?char:"")):"";
                 break;
                 case 2:
                   return "-";
                 break;
                 case 3:
                 case 4:
                 case 5:
                 case 6:
                   return !isNaN(parseInt(char,10))?char:"";
                 break;
                 default:
                   return "";
                 break;
            }
    },data.expiry_date);
    if(mapped.length === 2) mapped.push("-");
    data.expiry_date = mapped.join("");
  }

  validateExpiryDate(date,data){
    var m =[];
    m = date.split("-");
    if(m[0] == "00" || m[0] == "0" || m[0].length < 2){
      if(m[1] == "0000" || m[1] == "0" || m[1].length < 4){
        this.toastr.error("Check Expiry year and date");
        data.expiry_date_flag = false;

      }else{
        this.toastr.error("Check Expiry date");
        data.expiry_date_flag = false;
      }

    }else if(m[1] == "0000" || m[1] == "0" || m[1].length < 4){
      this.toastr.error("Check Expiry year and date");
      data.expiry_date_flag = false;

    } else{
      data.expiry_date_flag = true;
    }
  }

  checkValue(str, max) {
    if (str.charAt(0) !== '0' || str == '00') {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return str;
  }
  
  isAllSelected(list, action) {
    list.checked = action == "select" ? true : false;

    console.log("this.inventor" + JSON.stringify(this.inventory))
  }

  setSalesPrice(data){
    for(var i=0;i<this.inventory.length;i++){
      if(this.inventory[i].product_id == data.product_id){
        this.inventory[i].unit_price = data.sales_price;
      }
    }
  }
}
