import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Time_Formate_Session } from '../../../assets/js/common.js';
import { MatDialog } from '@angular/material/dialog';
import { Message_data } from 'src/assets/js/Message_data';
import { Physio_Helper } from '../Physio_Helper';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-appointments',
  templateUrl: './physio-appointments.component.html',
  styleUrls: ['./physio-appointments.component.scss']
})
export class PhysioAppointmentsComponent implements OnInit {
  @ViewChild('dateTime') dateTime;
  public currentDate = null;
  public homecare: string;
  public appointmentList = [];
  public appointmentDate: string;
  public userInfo;
  public event = {
    month: '1990-02-19',
    timeStarts: '07:43',
    timeEnds: '1990-02-20'
  }
  dtOptions: DataTables.Settings = {};
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public checkListId: any = [];
  public apptOpenCount:number = 0;
  public therapyList;

  constructor(public dialog: MatDialog, public http: Http, public router: Router, public toastr: ToastrService, public physioservice: PhysioService) {
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 4, 5, 7,9] },
        { "width": "100px", "targets": 8 },
       ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no or session"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.homecare = Physio_Helper.getHomecare();
    this.appointmentDate = Physio_Helper.getAppDate();
    this.getCurrentDate();
    this.getCaseSheetFields(this.userInfo.hospitals[0].hptl_clinic_id);
    this.getTherapyHistory();
  }

  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.appointmentDate = this.currentDate;
            this.getDocAppointmentList(this.userInfo.user_id);
          }
        }, error => {});
  }

  selectAppointmentDate(e) {
    Physio_Helper.setAppDate("");
    this.appointmentDate = e;
    Physio_Helper.setAppDate(e);
    this.getDocAppointmentList(this.userInfo.user_id);
  }

  getDocAppointmentList(data): void {
    this.appointmentList = [];
    var data = null;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/npapps',
      JSON.stringify({
        home_care: this.homecare,
        date: this.appointmentDate,
        prov_id: this.userInfo.user_id,
        imei: Helper_Class.getIPAddress(),
        country: "IN",
        type: "physio"
      }),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(response["_body"]);
          Physio_Helper.setpatient_information(data);
            if (data.appointments != null) {
              var appolist = data.appointments;
              if (appolist.length != "0") {
                for (var i = 0; i < appolist.length; i++) {
                  if (appolist[i].status == "0") {
                    var status_txt = "Completed";
                  }
                  else if (appolist[i].status == "1") {
                    if (appolist[i].date == this.currentDate || this.currentDate < appolist[i].date) {
                      if (appolist[i].f_time != "1") {
                        var get_time = new Date(appolist[i].date + " " + this.currentDate);
                        var cur_time = new Date(this.currentDate + " " + this.currentDate);
                        if (get_time > cur_time) {
                          status_txt = "Not visited";
                        } else {
                          status_txt = "Open";
                        }
                      } else {
                        var get_time = new Date(appolist[i].date);
                        var cur_time = new Date(this.currentDate);
                        if (get_time > cur_time || get_time == cur_time) {
                          status_txt = "Open";
                        } else {
                          status_txt = "Not visited";
                        }
                      }
                    } else {
                      if (appolist[i].date > this.currentDate || appolist[i].date == this.currentDate) {
                        status_txt = "Open";
                      } else {
                        status_txt = "Not visited";
                      }
                    }
                  } else if (appolist[i].status == "2") { 
                    if (appolist[i].date == this.currentDate || this.currentDate < appolist[i].date) {
                      var get_time = new Date(appolist[i].date + " " + this.currentDate);
                      var cur_time = new Date(this.currentDate + " " + this.currentDate);
                      status_txt = "Confirmed";
                    } else {
                      if (appolist[i].date > this.currentDate || appolist[i].date == this.currentDate) {
                        status_txt = "Open";
                      } else {
                        status_txt = "Not visited";
                      }
                    }
                  }
                  if (appolist[i].token != null) {
                    var token_time_label = "Token no : " + appolist[i].token;
                  } else {
                    var Temptimesplit = appolist[i].f_time;
                    var offTime = Temptimesplit.split(':');
                    offTime[0] = offTime[0] % 12;
                    var output = offTime.join(':');
                    var get_timeq = output.split(":");
                    if (get_timeq[0] == "0") {
                      output = "12" + ":" + get_timeq[1];
                    }
                    token_time_label = Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session));
                  }
                  var Patient_name = null;
                  if (appolist[i].middle_name != undefined && appolist[i].middle_name != null){ 
                    if (encrypt_decript.Decript(appolist[i].middle_name) != ""){ 
                      Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].middle_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                    }else {
                      Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                    }
                  }else {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  }
                  if (appolist[i].sub_rel_id != null) {
                    var sub_rel = appolist[i].sub_rel_id;
                  }
                  else {
                    sub_rel = "0";
                  }
                  var get_age;
                  if (appolist[i].age != null) {
                    get_age = appolist[i].age;
                  }
                  else {
                    get_age = "";
                  }
                  if (appolist[i].address2 != null && appolist[i].address2 != "") {
                    var add_data = appolist[i].address1 + " " + appolist[i].address2;
                  }
                  else {
                    add_data = appolist[i].address1;
                  }
                  if (appolist[i].rel_zipcode != null) {
                    var zipcode_data = encrypt_decript.Decript(appolist[i].rel_zipcode);
                  }
                  else {
                    zipcode_data = appolist[i].zipcode;
                  }
                  var session = appolist[i].session == "1" ? "Morning" : appolist[i].session == "2" ? "Afternoon" : "Evening" 
                  this.appointmentList.push({
                    app_data: appolist[i],
                    hptl_clinic_id: appolist[i].hptl_clinic_id,
                    patient_name: Patient_name,
                    tot_app_count: token_time_label,
                    session: session,
                    status: status_txt,
                    symptoms:appolist[i].symptoms,
                    profile_image: ipaddress.Ip_with_img_address + appolist[i].profile_image,
                    doc_app_id: appolist[i].app_id,
                    client_reg_id: appolist[i].client_reg_id,
                    relation_id: appolist[i].relation_id,
                    sub_rel: sub_rel,
                    rel_count: appolist[i].country_id,
                    rel_stat: appolist[i].state_id,
                    rel_city: appolist[i].city_id,
                    rel_loc: appolist[i].location_id,
                    rel_loc_desc: appolist[i].location,
                    loc_age: get_age,
                    gender_data: encrypt_decript.Decript(appolist[i].gender),
                    dob_data: appolist[i].dob,
                    address: add_data,
                    state_desc: appolist[i].state_desc,
                    city_desc: appolist[i].city_desc,
                    country_desc: appolist[i].country_desc,
                    rel_zipcode: zipcode_data,
                    contact : encrypt_decript.Decript(appolist[i].mobile),
                  });
                }
              } 
            } else {
              this.toastr.error(Message_data.getNetworkMessage());
            }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  viewAppt(list) {
      Physio_Helper.setClient_Info(null);
      var patient_list:any = [];
      patient_list = {
        Age_data: list.age,
        Gender_data:list.gender_data,
        app_id: list.doc_app_id,
        client_name: list.patient_name,
        image: list.profile_image,
        sub_id: list.sub_rel,
        Client_id: list.client_reg_id,
        rel_id: list.relation_id,
        Case_Clnt_dob: list.dob_data,
        relationship_name:list.relationship_name,
        hptl_clinic_id:list.hptl_clinic_id,
        spl_id: list.spl_id,
        spl: list.spl,
        mobile:list.contact,
        symptoms:list.symptoms,
        Appoint_Date: list.lastvisit,
        refer_by:list.refer_txt,
        address1:list.address1,
        address2:list.address2,
        location:list.location,
        city:list.city,
        state:list.state,
        country:list.country,
        zipcode:list.zipcode,
        hptl_name:list.hospital_name,
        height:list.height,
        weight:list.weight,
        height_measure:list.height_measure,
        weight_measure:list.weight_measure,
        patient_id:list.patient_id,
        AppFlow: "Physio_app_list",
        country_id:list.country_id,
        state_id:list.state_id,
        city_id:list.city_id,
      };
    Physio_Helper.setClient_Info(patient_list);
    this.getCommonData();
    this.getCaseSheet();
  }

  getCaseSheet(){
    var send_data;
    if (Physio_Helper.getClient_Info().sub_id != null && Physio_Helper.getClient_Info().sub_id != undefined
    && Physio_Helper.getClient_Info().sub_id != "" && Physio_Helper.getClient_Info().sub_id.length != 0) {
    send_data = JSON.stringify({
      doc_reg_id: this.userInfo.user_id,
      client_reg_id: Physio_Helper.getClient_Info().Client_id,
      relation_id: Physio_Helper.getClient_Info().rel_id,
      sub_rel_id: Physio_Helper.getClient_Info().sub_id,
      country: ipaddress.country_code
    });
    } else {
      send_data = JSON.stringify({
        doc_reg_id: this.userInfo.user_id,
        client_reg_id: Physio_Helper.getClient_Info().Client_id,
        relation_id: Physio_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/chist',send_data,{ headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.case_hist_id != null) {
            Physio_Helper.setRet(obj);
          }
          this.physioservice.sendMessage("casesheet");
        })
  }

  getCommonData() {
    Physio_Helper.setAsg(undefined);
    if (Physio_Helper.getClient_Info().sub_id != null && Physio_Helper.getClient_Info().sub_id != undefined
    && Physio_Helper.getClient_Info().sub_id != "" && Physio_Helper.getClient_Info().sub_id.length != 0) {
      var sub_id_data = Physio_Helper.getClient_Info().sub_id;
    } else {
      sub_id_data = undefined;
    }
    if (sub_id_data != undefined) {
      var sen_pass = JSON.stringify({
      client_reg_id: Physio_Helper.getClient_Info().Client_id,
      relation_id: Physio_Helper.getClient_Info().rel_id,
      sub_rel_id: sub_id_data,
        country: ipaddress.country_code
      });
    } else {
      var sen_pass = JSON.stringify({
        client_reg_id: Physio_Helper.getClient_Info().Client_id,
        relation_id: Physio_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/asgdet', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          Physio_Helper.setAsg(obj);
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getTherapyHistory() {
    this.therapyList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/gtherapy',
    JSON.stringify({
      hptl_clinic_id:JSON.parse(this.userInfo.hospitals[0].hptl_clinic_id),
      status: "1",
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.therapies != null) {
            for (var i = 0; i < obj.therapies.length; i++) {
              this.therapyList.push({
                type: 'checkbox',
                value: obj.therapies[i].therapy_id,
                label: obj.therapies[i].therapy,
                checked: false
              });
            }
            if(this.therapyList.length !=0){
              Physio_Helper.setTherapies(this.therapyList);
            }
          }
        },
        error => {
        }
      )
  }

  getCaseSheetFields(hptl_id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gpfhspp/',
      JSON.stringify({
        hosp_id: hptl_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          Physio_Helper.setHopitalfieldsRet(obj.pages);
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  checkUncheckAll() {
    this.checklist = this.appointmentList;
    if (this.checkListId != "" && this.checkListId.length != 0) {
      if (this.checkListId.length == this.apptOpenCount) {
        this.checkListId = [];
        for (var i = 0; i < this.checklist.length; i++) {
          this.checklist[i].isSelected = this.masterSelected;
        }
      } else {
        this.checkListId = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].status == 'Open' || this.checklist[i].status == 'Confirmed') {
            var doc_id = this.checklist[i].doc_app_id;
            this.checkListId.push(doc_id);
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].status == 'Open' || this.checklist[i].status == 'Confirmed') {
          var doc_id = this.checklist[i].doc_app_id;
          this.checkListId.push(doc_id);
          this.checklist[i].isSelected = this.masterSelected;
        }
      }
    }
  }

  isAllSelected(appid) {
    if (this.checkListId != "" && this.checkListId.length != 0) {
      if (this.checkListId.indexOf(appid) !== -1) {
        var index = this.checkListId.indexOf(appid);
        this.checkListId.splice(index, 1);
      } else {
        this.checkListId.push(appid);
      }
    } else {
      this.checkListId.push(appid);
    }
  }

  getCheckedItemList() {
    if (this.checklist.length == this.checkListId.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkListId.length != 0) {
      this.toastr.success('Thank you...', ``);
    } else {
      this.checkListId = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    } 
  }

  clearAll() {
    this.checklist = this.appointmentList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  } 

  confirmAppointment() {
    if (this.checkListId != "" && this.checkListId.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: "physio",
          doc_app_ids: this.checkListId,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
                this.getDocAppointmentList(this.userInfo.user_id);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToConfirm);
    }
  }
  
  cancel() {
    if (this.checkListId != "" && this.checkListId.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          np_app_id: this.checkListId,
          home_care: "0",
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.ngOnInit();
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }
}
