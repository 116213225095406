import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common';
import { Chart } from 'chart.js';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-physio-prof-dash',
  templateUrl: './physio-prof-dash.component.html',
  styleUrls: ['./physio-prof-dash.component.scss']
})
export class PhysioProfDashComponent implements OnInit {
  public menu:boolean = false;
  public userInfo:any;
  public userId:any;
  public hospitalDetails;
  public hospitallogo;
  public hospitalName;
  public currentDate = null;
  public chartType: string;
  public fromDate;
  public toDate;
  public totalapp2: string;
  public totalapp3: string;
  public totalapp4: string;
  public totalapp1: string;
  public LocationCanvas: any;
  public noGrpah: boolean;
  public genmyChart: any;
  public ageCanvas: any;
  public ageGraphFlag: boolean;
  public totalapp;
  public pendingapp;
  public newpatientapp;
  public appointmentType;
  public hptlClinicId;

  constructor(private http:Http,public toastr:ToastrService) { 
    this.appointmentType = "Today";
  }

  ngOnInit(): void {
    this.userInfo= Helper_Class.getInfo();
    this.hospitalDetails=Helper_Class.getHospitalInfo();
    this.hospitalName=this.hospitalDetails.hosp_name;
    this.hospitallogo= ipaddress.Ip_with_img_address + this.hospitalDetails.hptl_logo;
    this.hptlClinicId =  this.userInfo.hospitals[0].hptl_clinic_id;
    this.userId=this.userInfo.user_id;
    this.getCurrentDate();
    window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);
    function disableF5(e) {
        if ((e.which || e.keyCode) == 116) e.preventDefault(); 
    };
  }

  getDateFormate(d){
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  changeAppointmentType(){
    var e;
     if(this.appointmentType =="Today"){
       e = this.currentDate;
     } 
     else if(this.appointmentType == "Weekly"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 7);
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "Fort Night"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 14);
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "Monthly"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 30)
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "3 Months"){
       var d =new Date();
       d.setDate(d.getDate() - 92)
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "6 Months"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 183)
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "Yearly"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 365)
       e = this.getDateFormate(d);
     }
     else{}
     this.fromDate = Date_Formate(e);
     this.genderBase();
     this.ageBase();
     this.locationBase();
  }

  changeChart() {
    this.genderBase();
    this.ageBase();
    this.locationBase();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.currentDate = obj.current_date;
          this.fromDate = Date_Formate(this.currentDate),
          this.toDate = Date_Formate(this.currentDate),
          this.chartType = "Pie";
          this.genderBase();
          this.ageBase();
          this.locationBase();
        },error => {
          this.toastr.error(Message_data.NoAppointments());
        });
  }

  genderBase() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var dataArrayFinal1 = new Array();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconbygen/',
      JSON.stringify({
        reg_id: this.hptlClinicId,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
            }
            if (obj.appointments.length == 0) {
              this.noGrpah = true;
            } else {
              this.noGrpah = false;
            }
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            var dashsearch_array = [];
            if (obj.appointments != null) {
              for (var i = 0; i < obj.appointments.length; i++) {
                dashsearch_array.push(obj.appointments[i].data);
              }
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            this.totalapp2 = "No of appointment :" + Appointment_total;
            this.totalapp3 = "No of appointment :" + Appointment_total;
            this.totalapp4 = "No of appointment :" + Appointment_total;
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
              dataArrayFinal1[j] = temp;
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            if (this.chartType == "Pie") {
              var ctx = document.getElementById("piechart_gen");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("piechart_gen");
              if (this.genmyChart) this.genmyChart.destroy();
              this.genmyChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
            } else if (this.chartType == "Line") {
              var ctx = document.getElementById("piechart_gen");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              if (this.genmyChart) this.genmyChart.destroy();
              var ctx = document.getElementById("piechart_gen");
              this.genmyChart = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
              this.genmyChart.update();
            } else if (this.chartType == "Bar") {
              var ctx = document.getElementById("piechart_gen");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var data = {
                labels: name,
                datasets: [
                  {
                    label: name,
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }
                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    stacked: true,
                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    }
                  }]
                }
              };
              if (this.genmyChart) this.genmyChart.destroy();
              this.genmyChart = Chart.Bar("piechart_gen", {
                data: data,
                options: option
              });
            }
          } else {
           this.toastr.error(Message_data.getNetworkMessage());
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
  }

  ageBase() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconbyage/',
      JSON.stringify({
        reg_id: this.hptlClinicId,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
              var get_age_value = obj.appointments[i].data;
            }
            var dashsearch_array = [];
            for (var i = 0; i < obj.appointments.length; i++) {
              dashsearch_array.push(obj.appointments[i].data);
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              }else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            if (get_age_value != null) {
              this.totalapp1 = "No of appointments :" + get_age_value;
              this.ageGraphFlag = false;
            } else {
              this.totalapp1 = "No of appointments :" + get_age_value;
              this.ageGraphFlag = true;
            }
            this.totalapp2 = "No of appointments :" + Appointment_total;
            this.totalapp3 = "No of appointments :" + Appointment_total;
            this.totalapp4 = "No of appointments :" + Appointment_total;
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            if (this.chartType == "Pie") {
              var ctx = document.getElementById("AgeCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("AgeCanvas");
              if (this.ageCanvas) this.ageCanvas.destroy();
              this.ageCanvas = new Chart(ctx, {
                type: 'doughnut',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
            } else if (this.chartType == "Line") {
              var ctx = document.getElementById("AgeCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("AgeCanvas");
              if (this.ageCanvas) this.ageCanvas.destroy();
              this.ageCanvas = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
            } else if (this.chartType == "Bar") {
              var ctx = document.getElementById("AgeCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var data = {
                labels: name,
                datasets: [
                  {
                    label: name,
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }
                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    stacked: true,
                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    }
                  }]
                }
              };
              if (this.ageCanvas) this.ageCanvas.destroy();
              this.ageCanvas = Chart.Bar("AgeCanvas", {
                data: data,
                options: option
              });
            }
          }
        },
        error => {
          //this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  locationBase() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconloc/',
      JSON.stringify({
        reg_id: this.hptlClinicId,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
            }
            var dashsearch_array = [];
            if (obj.appointments != null) {
              for (var i = 0; i < obj.appointments.length; i++) {
                dashsearch_array.push(obj.appointments[i].data);
              }
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              }
              else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            this.totalapp2 = "No of appointments :" + Appointment_total;
            this.totalapp3 = "No of appointments :" + Appointment_total;
            this.totalapp4 = "No of appointments :" + Appointment_total;
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            if (this.chartType == "Pie") {
              var ctx = document.getElementById("LocationCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("LocationCanvas");
              if (this.LocationCanvas) this.LocationCanvas.destroy();
              this.LocationCanvas = new Chart(ctx, {
                type: 'doughnut',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
            } else if (this.chartType == "Line") {
              var ctx = document.getElementById("LocationCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("LocationCanvas");
              if (this.LocationCanvas) this.LocationCanvas.destroy();
              this.LocationCanvas = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
            } else if (this.chartType == "Bar") {
              var ctx = document.getElementById("LocationCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var data = {
                labels: name,
                datasets: [
                  {
                    label: name,
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }
                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    stacked: true,
                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    }
                  }]
                }
              };
              if (this.LocationCanvas) this.LocationCanvas.destroy();
              this.LocationCanvas = Chart.Bar("LocationCanvas", {
                data: data,
                options: option
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
}
