<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
                    </div>
                    <div class="headerButtons">
                        <a *ngIf="saveFlag" (click)="save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                                class="saveimgbtn_inpatinfo" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="Details" style="margin-top: -12px;padding: 0 30px;">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel" style="font-weight: 600;">Appointment Type :
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <div class="switch-field">
                                            <input type="radio" id="radio-one"
                                                (click)="changeType('app','New','yes')" />
                                            <label for="radio-one"
                                                [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">New</label>
                                            <input type="radio" id="radio-two"
                                                (click)="changeType('app','Follow-up','no')" />
                                            <label for="radio-two"
                                                [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">Follow-up</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7" style="margin: 15px 0 15px 0;">
                                <div class="row">
                                    <div class="col-5">
                                        <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                                    </div>
                                    <div class="col-7">
                                        <div class="switch-field">
                                            <input type="radio" id="radio-three"
                                                (click)="changeType('client','New','yes')" />
                                            <label for="radio-three"
                                                [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">New</label>
                                            <input type="radio" id="radio-four"
                                                (click)="changeType('client','Existing','no')" />
                                            <label for="radio-four"
                                                [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">Existing</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cover_div" style="padding-bottom: 13px;margin-bottom: 25px;">
                                <div class="header_lable">
                                    Appointment Details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2"
                                            *ngIf="doctorFlag">
                                            <mat-label class="matlabel">Doctor<br>
                                                <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)"
                                                    [(ngModel)]="doctor">
                                                    <option *ngFor="let doctors of doctorList"
                                                        value={{doctors.Doc_Name}}>{{doctors.Doc_Name}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2">
                                            <mat-label class="matlabel">Date<br>
                                                <input type="date" class="ipcss_date widthappt" id="appt_date"
                                                    (change)="getApptByDate(appointmentDate)"
                                                    [(ngModel)]="appointmentDate" min={{currentDate}} #matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2" *ngIf="appTypeTokenFlag && visitingSessionData.lenght != 0">
                                            <mat-label class="matlabel"> Visiting session<br>
                                                <mat-select required class="ipcss " [(ngModel)]="visitSession">
                                                    <mat-option *ngFor="let session of visitingSessionData" value="{{session.description}}"
                                                        (click)="visitSessionChange(visitSession, appointmentDate)">
                                                        {{session.description}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2" *ngIf="timeListData.lenght != 0 && !appTypeTokenFlag">
                                            <mat-label class="matlabel">Visiting session<br>
                                                <mat-select required class="ipcss " [(ngModel)]="visitSession">
                                                    <mat-option *ngFor="let session of timeListData" value="{{session.session}}" (click)="visitSessionChange(visitSession, appointmentDate)"> {{session.session}}</mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2" *ngIf="!appTypeTokenFlag && timeList.length != 0">
                                            <mat-label class="matlabel">Appointment Time
                                                <select required class="ipcss widthappt" [(ngModel)]="visitTime" required *ngIf="!appTypeTokenFlag">
                                                    <option *ngFor="let time of timeList" value="{{time}}">{{time}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2" *ngIf="appTypeTokenFlag">
                                            <mat-label class="matlabel">
                                                <p *ngIf="appTypeTokenFlag">Appointment type : Token</p>
                                            </mat-label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="cover_div" style="padding-bottom: 13px;margin-bottom: 25px;">
                                <div class="header_lable">
                                    Patient Details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                                            *ngIf="userTypeFlag">
                                            <mat-label class="matlabel">Relationship
                                                <select disableOptionCentering class="ipcss noappt_width"
                                                    name="Relationship" [(ngModel)]="relationship"
                                                    (ngModelChange)="changeRelation($event)">
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let relation of relationList"
                                                        value="{{relation.relationship_name}}">
                                                        {{relation.relationship_name}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                            <mat-label class="matlabel">Salutation
                                                <select disableOptionCentering class="ipcss noappt_width" required
                                                    [(ngModel)]="clntSal" (ngModelChange)="changeSalutation($event)">
                                                    
                                                    <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">
                                                        {{sal.sal_desc}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">First name
                                                <input type="text" class="ipcss noappt_width"
                                                    (change)="acitvateSavebtn()" [(ngModel)]="clntFName"
                                                    (blur)="fnameToUpper()" required matInput
                                                    [matAutocomplete]="auto5" />
                                                <mat-autocomplete #auto5="matAutocomplete" [hidden]="nameFlag">
                                                    <mat-option (click)="selectClient(client.name)"
                                                        *ngFor="let client of clientList" value="{{client.name}}">
                                                        {{client.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Middle name
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clntMName"
                                                    (blur)="mnameToUpper()" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Sur name
                                                <input type="text" required class="ipcss noappt_width"
                                                    [(ngModel)]="clntLName"  (change)="acitvateSavebtn()" (blur)="lnameToUpper()" matInput />
                                            </mat-label>
                                        </div>
                                        <div *ngIf="userTypeFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div class="row">
                                                <div class="col-10">
                                                    <mat-label class="matlabel" *ngIf="userTypeFlag">Mobile no
                                                        <input type="text" class="ipcss noappt_width"
                                                            [(ngModel)]="clntContactNo" maxlength="10"
                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                            required (click)="fucusMobile()" (blur)="checkClient()"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                                <div class="col-2  p-0">
                                                    <mat-label class="matlabel" *ngIf="userTypeFlag">
                                                        <a (click)="getUserDetails()"><img src="../../../assets/img/search.png" class="seacrhicon" /></a>
                                                    </mat-label>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!userTypeFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" *ngIf="!userTypeFlag">Mobile no
                                                <input type="text" class="ipcss noappt_width"
                                                    (change)="acitvateSavebtn()" [(ngModel)]="clntContactNo"
                                                    maxlength="10" required
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    (focus)="clearMobile()" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">DOB
                                                <input type="date" class="ipcss_date noappt_width"
                                                    [disabled]="dobDisabledFlag" (change)="selectDob(clntDob)"
                                                    [(ngModel)]="clntDob" max="{{currentDate}}" #matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Age
                                                <input type="text" class="ipcss noappt_width"
                                                    [disabled]="ageDisabledFlag" (keyup)="changeAge()"
                                                    [(ngModel)]="clntAge"  matInput />
                                            </mat-label>
                                        </div>
                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" *ngIf="userTypeFlag">Gender
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clntGender"
                                                    matInput />
                                            </mat-label>
                                            <div class="select_bottom">
                                                <mat-label class="matlabel" *ngIf="!userTypeFlag">Gender
                                                    <select disableOptionCentering (change)="acitvateSavebtn()"
                                                        (ngModelChange)="changeGender($event)" class="ipcss noappt_width"
                                                        [(ngModel)]="clntGender">
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Transgender">Transgender</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Date
                                                <input type="date" class="ipcss_date widthbillcreate" id="appt_date"
                                                    (change)="selectAppointmentDate(appointmentDate)" required
                                                    min="{{currentDate}}" [(ngModel)]="appointmentDate" #matInput
                                                    style="background: #fff;" pattern="\d{1,2}/\d{1,2}/\d{4}">
                                            </mat-label>
                                        </div> -->
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Purpose of visit
                                                <input type="text" class="ipcss noappt_width" required
                                                    [(ngModel)]="dietTypeDesc" (keyup)="getTypes()" matInput />
                                                <div class="auto_complete_text" *ngIf="dietTypeList.length != 0">
                                                    <ul *ngFor="let diet of dietTypeList">
                                                        <li>
                                                            <a
                                                                (click)="selectPurposeOfVisit(diet)">{{diet.description}}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Symptoms
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clntSymptom"
                                                    (keyup)="getSymptoms()" matInput />
                                                <div class="auto_complete_text" *ngIf="symptomList.length != 0">
                                                    <ul *ngFor="let symptom of symptomList">
                                                        <li>
                                                            <a (click)="selectSymptom(symptom)">{{symptom}}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </mat-label>
                                        </div>

                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Referred doctor
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="referredDoctor"
                                                    maxlength="25" matInput (keyup)="getRefer()" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="splFlag">
                                            <mat-label class="matlabel">Speciality
                                                <mat-select class="ipcss" [(ngModel)]="speclization" required>
                                                    <mat-option *ngFor="let spl of specializationList" value={{spl.spl_id}}>
                                                        {{spl.spl_name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel" [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Personal
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row pad_top_15">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                                        *ngIf="userTypeFlag">
                                        <mat-label class="matlabel">Relationship
                                            <select disableOptionCentering class="ipcss noappt_width"
                                                name="Relationship" [(ngModel)]="relationship"
                                                (ngModelChange)="changeRelation($event)">
                                                <option disabled>Select</option>
                                                <option *ngFor="let relation of relationList"
                                                    value="{{relation.relationship_name}}">
                                                    {{relation.relationship_name}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">Salutation
                                            <select disableOptionCentering class="ipcss noappt_width"
                                                [(ngModel)]="clntSal" (ngModelChange)="changeSalutation($event)">
                                                <option value="select" selected>Select</option>
                                                <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">
                                                    {{sal.sal_desc}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">First Name
                                            <input type="text" class="ipcss noappt_width" (change)="acitvateSavebtn()"
                                                [(ngModel)]="clntFName" (blur)="fnameToUpper()" required matInput
                                                [matAutocomplete]="auto5" />
                                            <mat-autocomplete #auto5="matAutocomplete" [hidden]="nameFlag">
                                                <mat-option (click)="selectClient(client.name)"
                                                    *ngFor="let client of clientList" value="{{client.name}}">
                                                    {{client.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Middle Name
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntMName"
                                                (blur)="mnameToUpper()" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Last Name
                                            <input type="text" required class="ipcss noappt_width"
                                                [(ngModel)]="clntLName" [required]="lastNameReqFlag"
                                                (change)="acitvateSavebtn()" (blur)="lnameToUpper()" matInput />
                                        </mat-label>
                                    </div>
                                    <div *ngIf="userTypeFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div class="row">
                                            <div class="col-10">
                                                <mat-label class="matlabel" *ngIf="userTypeFlag">Mobile No
                                                    <input type="text" class="ipcss noappt_width"
                                                        [(ngModel)]="clntContactNo" maxlength="10"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        required (click)="fucusMobile()" (blur)="checkClient()"
                                                        matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-2  p-0">
                                                <mat-label class="matlabel" *ngIf="userTypeFlag">
                                                    <a (click)="getUserDetails()"><img
                                                            src="../../../assets/img/search.png"
                                                            class="seacrhicon" /></a>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!userTypeFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel" *ngIf="!userTypeFlag">Mobile No
                                            <input type="text" class="ipcss noappt_width" (change)="acitvateSavebtn()"
                                                [(ngModel)]="clntContactNo" maxlength="10" required
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                (focus)="clearMobile()" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">DOB
                                            <input type="date" class="ipcss_date noappt_width"
                                                [disabled]="dobDisabledFlag" (change)="selectDob(clntDob)"
                                                [(ngModel)]="clntDob" max="{{currentDate}}" required #matInput>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Age
                                            <input type="text" class="ipcss noappt_width" [disabled]="ageDisabledFlag"
                                                (keyup)="changeAge()" [(ngModel)]="clntAge" [required]="ageRequireFlag"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel" *ngIf="userTypeFlag">Gender
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntGender"
                                                matInput />
                                        </mat-label>
                                        <div class="select_bottom">
                                            <mat-label class="matlabel" *ngIf="!userTypeFlag">Gender
                                                <select disableOptionCentering (change)="acitvateSavebtn()"
                                                    (ngModelChange)="changeGender($event)" class="ipcss noappt_width"
                                                    [(ngModel)]="clntGender">
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Transgender">Transgender</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Date
                                            <input type="date" class="ipcss_date widthbillcreate" id="appt_date"
                                                (change)="selectAppointmentDate(appointmentDate)" required
                                                min="{{currentDate}}" [(ngModel)]="appointmentDate" #matInput
                                                style="background: #fff;" pattern="\d{1,2}/\d{1,2}/\d{4}">
                                        </mat-label>
                                    </div> --
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Purpose of visit
                                            <input type="text" class="ipcss noappt_width" required
                                                [(ngModel)]="dietTypeDesc" (keyup)="getTypes()" matInput />
                                            <div class="auto_complete_text" *ngIf="dietTypeList.length != 0">
                                                <ul *ngFor="let diet of dietTypeList">
                                                    <li>
                                                        <a (click)="selectPurposeOfVisit(diet)">{{diet.description}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Symptoms
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntSymptom"
                                                (keyup)="getSymptoms()" matInput />
                                            <div class="auto_complete_text" *ngIf="symptomList.length != 0">
                                                <ul *ngFor="let symptom of symptomList">
                                                    <li>
                                                        <a (click)="selectSymptom(symptom)">{{symptom}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Referred doctor
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="referredDoctor"
                                                maxlength="25" matInput (keyup)="getRefer()" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="splFlag">
                                        <mat-label class="matlabel">Speciality
                                            <mat-select class="ipcss" [(ngModel)]="speclization" required>
                                                <mat-option *ngFor="let spl of specializationList" value={{spl.spl_id}}>
                                                    {{spl.spl_name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Occupation
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntOccupation"
                                                maxlength="25" matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>-->
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel" >
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Address details
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row pad_top_15">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Address1
                                            <input type="text" class="ipcss noappt_width" (keyup) = "address()" [(ngModel)]="clntAddress1"
                                                [disabled]="userTypeFlag" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Address2
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntAddress2"
                                                [disabled]="userTypeFlag" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">Country
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntCountry"
                                                [disabled]="userTypeFlag" [hidden]="existUserFlag" matInput />
                                            <mat-select disableOptionCentering class="ipcss noappt_width"
                                                [(ngModel)]="clntCountry" [hidden]="newUserFlag"
                                                (ngModelChange)="changeCountry(clntCountry, '0')">
                                                <mat-option Selected>Select</mat-option>
                                                <mat-option *ngFor="let country of clntCountryList"
                                                    value="{{country.description}}">{{country.description}}</mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">State
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntState"
                                                [disabled]="userTypeFlag" [hidden]="existUserFlag" matInput />
                                            <mat-select disableOptionCentering class="ipcss noappt_width"
                                                [(ngModel)]="clntState" [hidden]="newUserFlag"
                                                (ngModelChange)="changeState(clntState, '0')">
                                                <mat-option Selected>Select</mat-option>
                                                <mat-option *ngFor="let state of clntStateList"
                                                    value="{{state.description}}">
                                                    {{state.description}}</mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">District / City
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntCity"
                                                [disabled]="userTypeFlag" [hidden]="existUserFlag" matInput />
                                            <mat-select disableOptionCentering class="ipcss noappt_width"
                                                [(ngModel)]="clntCity" [hidden]="newUserFlag"
                                                (ngModelChange)="changeCity(clntCity, '0')">
                                                <mat-option Selected>Select</mat-option>
                                                <mat-option *ngFor="let city of clntCityList"
                                                    value="{{city.description}}">
                                                    {{city.description}}</mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Location
                                            <input type="text" class="ipcss noappt_width" [disabled]="userTypeFlag"
                                                [(ngModel)]="clntLocation" (keyup)="changeLocation(clntLocation)"
                                                matInput [matAutocomplete]="auto" required />
                                            <mat-autocomplete #auto="matAutocomplete" [hidden]="newUserFlag">
                                                <mat-option (click)="selectLocation(location)"
                                                    *ngFor="let location of clntLocationList"
                                                    value="{{location.description}}">
                                                    {{location.description}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Zipcode
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntZipcode"
                                                [disabled]="!userTypeFlag" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" *ngIf="tempUser">
                                        <mat-label class="matlabel">Package
                                          <mat-select class="ipcss" [(ngModel)]="healthPackageID" required>
                                            <mat-option *ngFor="let package of healthPackage" value={{package.health_pkg_id}}>
                                              {{package.package_type_desc}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-label>
                                      </div>
                                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" *ngIf="tempUser">
                                        <div style="font-weight: 600;font-family: arial;"> Subscribe to Tervys app
                                          <img *ngIf="subscribeFlag == true" (click)="selectSubscribe('no')"
                                            src="../../../assets/ui_icons/tick_mark.svg" width="12px"
                                            style="cursor: pointer;width: 25px;margin: 0 0px 0 9px;">
                                          <img *ngIf="subscribeFlag == false" (click)="selectSubscribe('yes')"
                                            src="../../../assets/ui_icons/cross_mark.svg" width="12px"
                                            style="cursor: pointer;width: 25px; margin: 0 0px 0 9px;">
                                        </div>
                                      </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion> 
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>