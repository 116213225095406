import { ReturnStatement } from "@angular/compiler";

export class Admin_Helper{

    //setDiagBill
    static DiagBill;
    static setDiagBill(value:any){
        this.DiagBill=null;
        this.DiagBill=value;
    } 
    static getDiagBill(){
        return this.DiagBill;
    }
}