<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Create order</h5>
          </div>
          <div class="headerButtons">
            <img printSectionId="print" (click)="print_area()" src="../../../assets/img/printer.svg"
              class="saveimgbtn_inpatinfo" style="margin-left:5px;height: 25px;width: 25px;">
              <img  src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" (click)="backButton()" />
            <img *ngIf="save_flag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" (click)="saveMedicalPrescription('noprint')" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">User type<br>
              <select required class="ipcss " [(ngModel)]="usertype" (change)="getAppointments()" disableOptionCentering>
                <option *ngFor="let user of userArray" value="{{user.user_type}}" >
                  {{user.user_type_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Doctor<br>
              <input type="text" class="ipcss" maxlength="50" (keyup)="getReferralData($event)" maxlength="50"
                [(ngModel)]="doctorName" autocomplete="on" matInput [matAutocomplete]="auto1" />
              <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                <mat-option (click)="Select_refer_doctor(item.doc_reg_id)" *ngFor="let item of ReferedDoctorArray"
                  value="{{item.docname}}">
                  {{item.docname}}
                </mat-option>
              </mat-autocomplete>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Client<br>
              <select required class="ipcss " [(ngModel)]="appointText" (change)="changeAppointmnetID()"
                disableOptionCentering>
                <option *ngFor="let billcreate of billingCreateArray" value="{{billcreate.doc_app_id}}">
                  {{billcreate.docid_name}}</option>
              </select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="date_time_flag">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss_date" max="{{todayDate}}" (change)="changeOrderDate(orderDate)"
                [(ngModel)]="orderDate" #matInput>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="date_time_flag">
            <mat-label class="matlabel">Bill time <br>
              <input class="ipcss  widthbillcreate" type="text" disabled matInput [(ngModel)]="orderTime" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Payment type<br>
              <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                (selectionChange)="changePay()" disableOptionCentering>
                <mat-option *ngFor="let pay of payTypeListArray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="insurFlag">
            <mat-label class="matlabel">Insurer<br>
              <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID" disableOptionCentering>
                <mat-option *ngFor="let ins of insurerListArray" value={{ins.insurer_id}}>{{ins.insurer_desc}}
                </mat-option>
              </mat-select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="tranFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="TransactionID" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
            <label class="matlabel" for="customFile">Prescription file choosen</label>
            <input type="file" class="ipcss form-control " id="customFile" />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
            <mat-label class="matlabel">Discount type<br>
              <select disableOptionCentering class="ipcss " required [(ngModel)]="discType"
                (change)="discountChange(discType)">
                <option disabled>Select</option>
                <option *ngFor="let disc of discountDatas" value="{{disc.disc_type_id}}">{{disc.description}}
                </option>
              </select>
            </mat-label>
          </div>
        </div>

        <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
          <div id="pharma_inpattbl_overlay" class="dig_table_overflow table-responsive" style="height: 210px;">
            <table id="tbl_inpatmed" class="table table-hover table-dynamic" style="font-size: 11px;">
              <thead>
                <tr>
                  <th style="width: 45px;">Si.No</th>
                  <th style="width: 250px;" *ngIf="genericDisplayFlag">Generic name</th>
                  <th style="width: 250px;">Medicine name</th>
                  <th style="width: 70px;">Batch</th>
                  <th style="width: 70px;">Qty</th>
                  <th style="width: 90px;">Price</th>
                  <th style="width: 70px;">Discount</th>
                  <th style="width: 100px;">Amount</th>
                  <th style="width: 130px;">Duration</th>
                  <th style="width: 133px;">{{'Intake'}}</th>
                  <th style="width: 50px;">{{'Min'}}</th>
                  <th>{{'Intake session'}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let drug of drugDetailsArray;let i = index" class="tdvalues">
                  <th style="width: 45px;">{{ i + 1}}</th>
                  <td *ngIf="genericDisplayFlag">
                    <input type="text" class="ipcss order_width getMedcien"  maxlength="50"
                      (keyup)="changeMedicineName($event,'generic')" maxlength="50" [(ngModel)]="drug.gen_name" matInput  [matAutocomplete]="auto3" 
                     />
                    <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicine(medicine,drug)" *ngFor="let medicine of medicineArray" value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                      (keyup)="changeMedicineName($event,'medicine')" maxlength="50" [id]="'medName'+drug.index" [(ngModel)]="drug.drug_name" matInput
                      [matAutocomplete]="auto2" autocomplete="on" />
                    <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicine(medicine,drug)"
                        *ngFor="let medicine of medicineArray" value="{{medicine.name}}">
                          <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div>    
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" [ngStyle]="drug.batch_required" required
                      [(ngModel)]="drug.batch_no" matInput maxlength="50" autocomplete="on"
                      (click)="getCORD($event,'drug_'+drug.index);drug.show_batcharray = !drug.show_batcharray" readonly/>
                    <div [id]="'drug_'+drug.index" class="auto_complete_text1" *ngIf="drug.batcharray.length != 0"
                      style="display: none;">
                      <table style="width: 100%;">
                        <thead>
                         <tr>
                         <th> Batch no</th>
                         <th> Qty </th>
                         <th> MRP </th>
                         <th> Exp Date </th></tr>
                     </thead>
                     <tbody>
                       <tr *ngFor="let batch of drug.batcharray" (click)="selectedBatch(batch,drug,'drug_'+drug.index)">
                         <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                         <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                         <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                         <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                       </tr>
                     </tbody>
                     </table>
                    </div>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" [disabled]="drug.quantityDisable"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.quantity" (keyup)="quantity(drug)" (keyup.enter)="addRow()" #matInput />
                  </td>
                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" disabled
                      [(ngModel)]="drug.price" #matInput />
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" maxlength="2" [(ngModel)]="drug.discount"
                      (keyup)="calculateAmount(drug)" #matInput [readonly]="!discountFlag" />
                  </td>
                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" [(ngModel)]="drug.amount"
                      #matInput disabled />
                  </td>
                  <td>
                    <div style="display: flex;">
                      <input type="text" style="width: 40px;" class="ipcss order_width" matInput required maxlength="5"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="drug.day_txt" (keyup.enter)="addRow()"/>
                        &nbsp;
                      <mat-select disableOptionCentering class="ipcss  order_width" (selectionChange)="Duration()"
                        [(ngModel)]="drug.daydur_txt"  style="width: 90px;" >
                        <mat-option disabled>Select</mat-option>
                        <mat-option value="day(s)">day(s)</mat-option>
                        <mat-option value="week(s)">week(s)</mat-option>
                        <mat-option value="month(s)">month(s)</mat-option>
                      </mat-select>
                    </div>
                  </td>
                  <td>
                    <mat-select disableOptionCentering class="ipcss order_width" required
                      (selectionChange)="changeIntake(drug)" [(ngModel)]="drug.afterfood_txt"
                      [disabled]="drug.intake_show">
                      <mat-option value="Select">Select</mat-option>
                      <mat-option value="Empty stomach">Empty stomach</mat-option>
                      <mat-option value="After food">After food</mat-option>
                      <mat-option value="Before food">Before food</mat-option>
                      <mat-option value="With food">With Food</mat-option>
                    </mat-select>
                  </td>
                  <td>
                    <input type="text" class="ipcss  order_width" matInput [required]="drug.dure_require"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                      [(ngModel)]="drug.dure_txt" [disabled]="drug.dure_show" />
                  </td>
                  <td>
                    <div style="display: flex;">
                      <mat-select disableOptionCentering class="ipcss order_width" required [(ngModel)]="drug.intake_txt" style="width: 164px;">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let intake of getIntakeArray" (click)="sesssionBaseChange(drug)"
                          value={{intake.description}}>
                          {{intake.description}}</mat-option>
                      </mat-select>&nbsp;
                  
                      <div class="divClass">
                        <input type="text" [hidden]="drug.mor_lable" type="text" class="inputClass order_width" matInput
                        [required]="morningRequset" [(ngModel)]="drug.mor" [disabled]="drug.morning_dis" maxlength="5"
                        onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)" (blur)="focusFunction($event,drug)"> 
                        <input type="text" [hidden]="drug.quan_lable" type="text" class="inputClass order_width" matInput
                          [required]="drug.morning_req" maxlength="5"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [disabled]="drug.morning_dis" [(ngModel)]="drug.mor" (blur)="focusFunction($event,drug)">
                        <span [hidden]="drug.after_eventxt"> - </span>
                          <input type="text" class="inputClass order_width" matInput [disabled]="drug.afternoon_dis" maxlength="5" [hidden]="drug.after_eventxt"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [required]="drug.afternoon_req" [(ngModel)]="drug.aft" (blur)="focusFunction($event,drug)"> <span [hidden]="drug.eve_eventxt"> - </span>
                          <input type="text" [hidden]="drug.eve_eventxt" class="inputClass order_width" matInput [disabled]="drug.even_dis"
                          [required]="drug.evening_req" maxlength="5" (blur)="focusFunction($event,drug)"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [(ngModel)]="drug.eve"> <span [hidden]="drug.night_eventxt"> - </span>
                          <input type="text" [hidden]="drug.night_eventxt" class="inputClass order_width" matInput [disabled]="drug.ngt_dis"
                          [required]="drug.night_req" maxlength="5" (blur)="focusFunction($event,drug)"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [(ngModel)]="drug.ngt">
                      </div>                      
                    </div>
                  </td>
                  <td style="text-align: center;">
                    <a (click)="deleteMedicine(drug.drug_id,drug.index)">
                      <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7"></div>
          <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
            <div class="row" style="margin: 10px 0px;">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="discountFlag">
                <mat-label class="matlabel  heightmsr">Discount </mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />

              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="discountFlag">
                <input type="text" class="ipcss " style="text-align: right;" [(ngModel)]="prodDiscount"
                  (keyup)="changeTotal()" (blur)="dissBlur()">
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel">Bill amount</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>

              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="totalAmount" style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel">Total </mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>

              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="amountDiscount" style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">paid </mat-label>
              </div>

              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right style="text-align: right;"
                  [(ngModel)]="paidText" (keyup)="changeAdvance()" (focus)="paidFocus()" (blur)="paidBlur()" />

                <!-- <input type="text" class="paid_style ipcss " style="text-align: right;"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="paidText"
                    (keyup)="advance_change()"> -->
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Balance</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />

              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input type="text" class="ipcss " style="background-color: #e7e7e7;
                  border-radius: 5px;text-align: right;" [(ngModel)]="remaingBalance" readonly>
              </div>

            </div>
          </div>
        </div>

        <div id="print" hidden>
          <div>
            <div class="bill_border">
              <div>
                <table style="text-align: center;width: 100%;">
                  <tr>
                    <td>
                      <p style="font-size: 24px;"><strong>{{pharmacyName}}</strong></p>
                      <p style="margin-top: -10px;">{{pharmacyAddress}} {{pharmacyCSZ}} {{pharmacyCount}}</p>
                      <p>Tel: {{pharmacyMobile}}</p>
                    </td>
                  </tr>
                </table>
                <table style="width: 88%;text-align: left;margin: 0 auto;">
                  <tr>
                    <td style="text-align: left;">
                      <p>Name :{{clientName}}</p>
                      <p #billid id="billid"></p>
                      <p>Bill type :{{PurchaseType}}</p>
                    </td>
                    <td style="width: 250px;"></td>
                    <td style="text-align: left;">
                      <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                      <p>Bill time :{{orderTime}}</p>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                </table>
                <br />

                <table cellspacing="0" width="85%"
                  style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                  <tr style="border:1px solid black;">
                    <!-- <th style="border:1px solid black;">Med type</th> -->
                    <th style="border:1px solid black;">Medicine name</th>
                    <th style="border:1px solid black;">Quantity</th>
                    <th style="border:1px solid black;">Batch No</th>
                    <th style="border:1px solid black;">Price</th>
                    <th style="border:1px solid black;"> Disc(%)</th>
                    <th style="border:1px solid black;">CGST(%)</th>
                    <th style="border:1px solid black;">SGST(%)</th>
                    <th style="border:1px solid black;"> Amount</th>
                  </tr>

                  <tr *ngFor="let medpre of drugDetailsArray">
                    <!-- <td style="border:1px solid black;">{{medpre.type_name}}</td> -->
                    <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                    </td>

                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                    </td>
                  </tr>
                </table>
                <table width="85%" style="margin-left: 50px; margin-right: 50px;">
                  <tr *ngIf="displayDescription">
                    <td style="text-align: right; width: 80%;">
                      <b>Discount</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 15px; height: 15px;" />
                    </td>
                    <td>
                      <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: right; width: 80%;">
                      <b>Total</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 15px; height: 15px;" />
                    </td>
                    <td>
                      <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Printing bill-->
        <div [hidden]="true">
          <div>
            <div #billprint id="billprint">
              <div class="bill_border">
                <div>
                  <table style="margin-top: 100px;text-align: center;">
                    <td style="width: 150px;"></td>
                    <td>
                      <p style="font-size: 24px;"><strong>{{pharmacyName}}</strong></p>
                      <p style="margin-top: -10px;">{{pharmacyAddress}} {{pharmacyCSZ}} {{pharmacyCount}}</p>
                      <p>Tel: {{pharmacyMobile}}</p>
                    </td>
                  </table>

                  <table style="margin-left: 75px; margin-top: 20px;">
                    <td>
                      <p>Name :{{clientName}}</p>
                      <p #billid id="billid"></p>
                      <p>Bill type :{{PurchaseType}}</p>
                    </td>
                    <td style="width: 250px;"></td>
                    <td>
                      <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                      <p>Bill time :{{orderTime}}</p>
                      <p>&nbsp;</p>
                    </td>
                  </table>
                  <br />

                  <table cellspacing="0" width="85%"
                    style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                    <tr style="border:1px solid black;">
                      <!-- <th style="border:1px solid black;">Med type</th> -->
                      <th style="border:1px solid black;">Medicine name</th>
                      <th style="border:1px solid black;">Quantity</th>
                      <th style="border:1px solid black;">Batch No</th>
                      <th style="border:1px solid black;">Price</th>
                      <th style="border:1px solid black;"> Disc(%)</th>
                      <th style="border:1px solid black;">CGST(%)</th>
                      <th style="border:1px solid black;">SGST(%)</th>
                      <th style="border:1px solid black;"> Amount</th>
                    </tr>

                    <tr *ngFor="let medpre of drugDetailsArray">
                      <!-- <td style="border:1px solid black;">{{medpre.type_name}}</td> -->
                      <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                      </td>

                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                      </td>
                    </tr>
                  </table>
                  <table width="85%" style="margin-left: 50px; margin-right: 50px;">
                    <tr *ngIf="displayDescription">
                      <td style="text-align: right; width: 80%;">
                        <b>Discount</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td>
                        <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: right; width: 80%;">
                        <b>Total</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td>
                        <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                      </td>
                    </tr>

                  </table>
                </div>
              </div>
            </div>

            <div #noheader id="noheader">
              <div class="bill_border">
                <div>
                  <table style="margin-left: 75px; margin-top: 200px;">
                    <td>
                      <p>Name :{{clientName}}</p>
                      <p #billid id="billid"></p>
                      <p>Bill type :{{PurchaseType}}</p>
                    </td>
                    <td style="width: 250px;"></td>
                    <td>
                      <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                      <p>Bill time :{{orderTime}}</p>
                      <p>&nbsp;</p>
                    </td>
                  </table>
                  <br />
                  <table cellspacing="0" width="85%"
                    style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                    <tr style="border:1px solid black;">
                      <th style="border:1px solid black;">Medicine name</th>
                      <th style="border:1px solid black;">Quantity</th>
                      <th style="border:1px solid black;">Batch No</th>
                      <th style="border:1px solid black;">Price</th>
                      <th style="border:1px solid black;"> Disc(%)</th>
                      <th style="border:1px solid black;">CGST(%)</th>
                      <th style="border:1px solid black;">SGST(%)</th>
                      <th style="border:1px solid black;"> Amount</th>
                    </tr>

                    <tr *ngFor="let medpre of drugDetailsArray">

                      <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                      </td>

                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                      </td>
                    </tr>
                  </table>
                  <table width="85%" style="margin-left: 50px; margin-right: 50px;">
                    <tr *ngIf="displayDescription">
                      <td style="text-align: right; width: 80%;">
                        <b>Discount</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td>
                        <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: right; width: 80%;">
                        <b>Total</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td>
                        <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: right; width: 80%;">
                        <b>Paid</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td class="billamttext">
                        <p style="float: right;margin: 15px 10px 0 0;">{{paidText}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: right; width: 80%;">
                        <b>Balance</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td class="billamttext">
                        <p style="float: right;margin: 15px 10px 0 0;">{{remaingBalance}}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div #banner id="banner">
              <div class="bill_border">
                <div>
                  <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                    <td style="width: 150px;"></td>
                    <td>
                      <img alt="image" src={{pharmaLogo}} width="750px">
                    </td>
                  </table>
                  <table style="margin-left: 75px;margin-top: 20px;">
                    <td>
                      <p>Name :{{clientName}}</p>
                      <p #billid id="billid"></p>
                      <p>Bill type :{{PurchaseType}}</p>
                    </td>
                    <td style="width: 250px;"></td>
                    <td>
                      <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                      <p>Bill time :{{orderTime}}</p>

                    </td>
                  </table>
                  <br />

                  <table cellspacing="0" width="85%"
                    style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                    <tr style="border:1px solid black;">

                      <th style="border:1px solid black;">Medicine name</th>
                      <th style="border:1px solid black;">Quantity</th>
                      <th style="border:1px solid black;">Batch No</th>
                      <th style="border:1px solid black;">Price</th>
                      <th style="border:1px solid black;"> Disc(%)</th>
                      <th style="border:1px solid black;">CGST(%)</th>
                      <th style="border:1px solid black;">SGST(%)</th>
                      <th style="border:1px solid black;"> Amount</th>
                    </tr>

                    <tr *ngFor="let medpre of drugDetailsArray">

                      <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                      </td>

                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                      </td>
                      <td style="border:1px solid black;">
                        <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                      </td>
                    </tr>
                  </table>
                  <table width="85%" style="margin-left: 50px; margin-right: 50px;">
                    <tr *ngIf="displayDescription">
                      <td style="text-align: right; width: 80%;">
                        <b>Discount</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td>
                        <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: right; width: 80%;">
                        <b>Total</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td>
                        <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: right; width: 80%;">
                        <b>Paid</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td class="billamttext">
                        <p style="float: right;margin: 15px 10px 0 0;">{{paidText}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: right; width: 80%;">
                        <b>Balance</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 15px; height: 15px;" />
                      </td>
                      <td class="billamttext">
                        <p style="float: right;margin: 15px 10px 0 0;">{{remaingBalance}}</p>
                      </td>
                    </tr>
                  </table>

                </div>
              </div>
            </div>

            <div #logowithname id="logowithname"
              style="background-image: url({{bgImage}}); background-position: center;">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header">
                  <tr>
                    <td style="display: flex;justify-content: center;">
                      <div>
                        <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                          style="vertical-align: middle;">
                      </div>
                      <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                        <p><b>{{pharmaName}}</b>,</p>
                        <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                          *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                        <span>{{location}},</span> <span>{{city}},</span>
                        <span>{{state}},</span><span>{{country}}.</span><br>
                        <p><span>DL No : {{dlNO}} &nbsp;&nbsp;</span><span>GST : {{GSTNo}}</span></p>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{clientName}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Disc(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>

                          <tr *ngFor="let medpre of drugDetailsArray">
                            <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                            <td style="border:1px solid black;">
                              <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                            </td>
                            <td style="border:1px solid black;">
                              <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                            </td>
                            <td style="border:1px solid black;">
                              <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                            </td>
                            <td style="border:1px solid black;">
                              <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                            </td>
                            <td style="border:1px solid black;">
                              <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                            </td>
                            <td style="border:1px solid black;">
                              <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                            </td>
                            <td style="border:1px solid black;">
                              <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                            </td>
                          </tr>
                        </table>
                        <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                          <tr *ngIf="displayDescription">
                            <td style="text-align: right; width: 80%;">
                              <b>Discount</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Total</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Paid</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{paidText}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Balance</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{remaingBalance}}</p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>