import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { Http, Headers } from '@angular/http';
import { InpatMedprescViewComponent } from '../inpat-medpresc-view/inpat-medpresc-view.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-inpat-discharge-summary',
  templateUrl: './inpat-discharge-summary.component.html',
  styleUrls: ['./inpat-discharge-summary.component.css']
})
export class InpatDischargeSummaryComponent implements OnInit {
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('dischargesummary') dischargesummary: ElementRef;
  @ViewChild('printheader') printheader: ElementRef;

  public currentDate;
  tomorrow = new Date();
  public header_footer_flag: boolean;
  public patient_name: string;
  public patient_identifier: string;
  public gender: string;
  public age: string;
  public admis_date: string;
  public doctor_name: string;
  public mobile_no: string;
  public symptoms: string;
  public complaints: string;
  public medical_hist: string;
  public physical_exam: string;

  public loader: boolean;
  public pres_pop_flag: string;
  public mobile: string;
  public next_data;
  public next_data1;
  public inpatient_id: string;

  public dis_diag: string;
  public hosp_course: string;
  public hospital_followup: string;
  public confirm_btn: boolean;
  public druglist = [];


  public hosp_name: string;
  public hosp_addr: string;
  public hosp_csz: string;
  public hosp_tel: string;
  public hosp_web: string;
  public hosp_web_flag: boolean;
  public hosp_count: string;

  public userinfo: any;
  public user_id: any;
  public first_name: string;
  public last_name: string;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;
  public profile_image: string;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public dis_date;
  public dis_date1;
  public personal_information: any = [];
  public print_drug: boolean;
  public Treatmetnt_done;
  public investigation;
  public ot_notes;
  public emergency_notes;
  public updateFlag: boolean = false;
  public presID;
  public drungID;
  public discharge;
  public retainValue: boolean = false;
  public hptl_pres_logo;
  public print_template;
  public headerstyle;
  public footerstyle;

  public anaest_types: any = [];
  public anaest_effects: any = [];
  public anaest_doctors: any = [];
  public consultarray: any = [];
  public anaest_procedure: string;
  public anaesttype: string;
  public anaest_effect: string;
  public anaest_doc: string;
  public surgeon_list: any = [];
  public surgery_date: string;
  public req_sur_flag: boolean;
  public surgery_proc: string;
  public surgery_time: string;
  public inpId: string;
  public admDate;
  public flagvalue;
  public surgeon: any = [];
  public anaestetist: any = [];
  public anaestetype: string;
  public anaest_proc: string;
  public anaest_findings: string;
  public inpno: string;
  public admis_time: string;
  public dis_time: string;
  public client_address: string;
  public sum_course_flag: boolean = false;
  public inv_flag: boolean = false;
  public dis_adv_flag: boolean = false;
  public dis_diag_flag: boolean = false;
  public treat_flag: boolean = false;
  public prev_med_flag: boolean = false;
  public phy_exam_flag: boolean = false;
  public otnote_flag: boolean = true;
  public comp_flag: boolean = false;
  public tittle_a: boolean = false;
  public tittle_b: boolean = false;
  reportlistarray: any = [];
  public centerid: any;
  //Editor
  @ViewChild('editor', { static: false }) editor: ElementRef;
  editorStyle = {
    height: '300px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline',], // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
    ],

  };
  cns: any;
  rstxt: any;
  cvs: any;
  resp_rate: any;
  pulse: any;
  blood_pressure: any;
  temparature: any;
  weight_measure: any;
  weight: any;
  height_measure: any;
  height: any;

  constructor(public sanitize: DomSanitizer, public dialog: MatDialog, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public messageService: MenuViewService) {

  }

  ngOnInit(): void {
    if(Helper_Class.getInfo().user_type=="doctor") {
      this.centerid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    } else {
      this.centerid = Helper_Class.getInfo().hptl_clinic_id;
    }
    
    this.getCurrentDate();
    this.getfullreportdata();
    this.mobile = Doc_Helper.getMobile_no();
    this.inpatient_id = Doc_Helper.getInpatientId();
    this.confirm_btn = false;

    console.log("Doc_Helper.getAdmission_id()" + JSON.stringify(Doc_Helper.getAdmission_id()));

    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;

    if (Doc_Helper.getAdmission_id() != undefined && Doc_Helper.getAdmission_id().flag == "edit") {
      this.confirm_btn = true;
      this.updateFlag = true;
      this.getRetrievalData();

    } else {
      if (this.mobile != undefined || this.inpatient_id != undefined) {
        this.getDischargeSummaryDetails();
      }
      Helper_Class.setPageFlow("dischargesum");
    }
    this.personal_information = Doc_Helper.getClient_Info();
    this.flagvalue = this.personal_information.flag;
    if (Helper_Class.getInfo().user_type == "front-desk") {
      this.print_template = Helper_Class.getInfo().dis_sum_print_template;
      this.hptl_pres_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().dis_sum_logo;
      this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().dis_sum_heading);
      this.footerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().dis_sum_footer);

    } else {
      this.print_template = Helper_Class.getHospital()[0].dis_sum_print_template;
      this.hptl_pres_logo = ipaddress.Ip_with_img_address + Helper_Class.getHospital()[0].dis_sum_logo;
      this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].dis_sum_heading);
      this.footerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].dis_sum_footer);
    }
  }

  setDischargeDate() {
    var disdata = this.dis_date.split("-");
    this.dis_date1 = disdata[2] + "-" + disdata[1] + "-" + disdata[0];
  }

  getRetrievalData() {
    this.consultarray=[];
    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
          var values = response.json();
          console.log("obj from all weighht....." + JSON.stringify(values));
          if (values != undefined || values != null) {
            this.loader = true;
            if (values.middle_name != undefined) {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);

            } else {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
            }

            this.inpId = values.inp_hosp_id;
            this.patient_identifier = values.patient_id;
            this.gender = encrypt_decript.Decript(values.gender);
            if (this.gender == "Male") {
              this.gender = "M";
            } else if (this.gender == "Female") {
              this.gender = "F";
            }
            this.age = values.age;
            var dateval = values.admission_date.split('-');
            this.admDate = values.admission_date;
            this.admis_date = dateval[2] + "-" + dateval[1] + "-" + dateval[0];

            var docqualif;
            if (values.dr_qualif != undefined) {
              docqualif = values.dr_qualif
            } else {
              docqualif = "";
            }
            if (values.dr_mname != undefined) {
              this.doctor_name = "Dr." + values.dr_fname + " " + values.dr_mname + " " + values.dr_lname + " " + docqualif;

            } else {
              this.doctor_name = "Dr." + values.dr_fname + " " + values.dr_lname + " " + docqualif;
            }
            //this.consultarray.push({ name: this.doctor_name });
            if (values.consultants != undefined) {
              var cname;
              for (var i = 0; i < values.consultants.length; i++) {
                if (values.consultants[i].middle_name != undefined) {
                  cname = "Dr." + values.consultants[i].first_name + " " + values.consultants[i].middle_name + " " + values.consultants[i].last_name;
                } else {
                  cname = "Dr." + values.consultants[i].first_name + " " + values.consultants[i].last_name;
                }
                if (values.consultants[i].qualification != undefined) {
                  cname = cname + " " + values.consultants[i].qualification;
                }
                this.consultarray.push({ name: cname });
              }
            }
            this.symptoms = values.symptoms;
            this.complaints = values.complaints;
            this.medical_hist = values.medical_history;
            this.physical_exam = values.physical_exam;
            this.inpatient_id = values.inpatient_id;
            this.hosp_name = values.hptl_name;
            this.medical_hist = values.medical_history;
            this.physical_exam = values.physical_exam;
            this.discharge = values.discharge_id;
            this.dis_diag = values.discharge_diagnosis;
            this.hosp_course = values.hospital_course;
            this.Treatmetnt_done = values.treat_proc;
            this.investigation = values.investigation;
            this.ot_notes = values.ot_notes;
            this.emergency_notes = values.emergencies;
            this.hospital_followup = values.follow_summary;
            this.presID = values.pres_drug_id;
            this.dis_date = values.discharge_date;
            var disdata = values.discharge_date.split("-");
            this.dis_date1 = disdata[2] + "-" + disdata[1] + "-" + disdata[0];
            // newly added
            this.height = values.height;
            this.height_measure = values.height_measure;
            this.weight = values.weight;
            this.weight_measure = values.weight_measure;
            this.temparature = values.temparature;
            this.blood_pressure = values.blood_pressure;
            this.pulse = values.pulse;
            this.resp_rate = values.resp_rate;
            this.cvs = values.cvs;
            this.rstxt = values.rstxt;
            this.cns = values.cns;
            
            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
            this.hosp_count = values.country;
            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }

            if (values.website != undefined) {
              this.hosp_web = values.website;
              this.hosp_web_flag = false;

            } else {
              this.hosp_web_flag = true;
            }

            if (values.next_visit != undefined && values.next_visit != '' && values.next_visit != null) {
              this.next_data = values.next_visit;
              var ndata = values.next_visit.split("-");
              this.next_data1 = ndata[2] + "-" + ndata[1] + "-" + ndata[0];
            }

            for (var i = 0; i < values.drug_list.length; i++) {
              var durval;
              if (values.drug_list[i].time_duration != undefined) {
                durval = values.drug_list[i].time_duration + " mins";

              } else {
                durval = "";
              }
              var shortName = values.drug_list[i].short_name != undefined ? values.drug_list[i].short_name : "";
              this.druglist.push({
                medicine_name: shortName + " " + values.drug_list[i].drug_name,
                medicine_dosage: values.drug_list[i].every_six,
                medicine_intakedays: values.drug_list[i].days + ' '+values.drug_list[i].day_dur,
                medicine_food: durval + " " + values.drug_list[i].drug_intake
              });
            }

            if (values.surgery_date != undefined) {
              this.surgery_date = values.surgery_date;
              this.req_sur_flag = true;
              this.surgery_proc = values.surgery_proc;
              if (values.surgeons != undefined) {
                for (var i = 0; i < values.surgeons.length; i++) {
                  var name;
                  if (values.surgeons[i].middle_name != undefined) {
                    name = "Dr. " + values.surgeons[i].first_name + " " + values.surgeons[i].middle_name + " " + values.surgeons[i].last_name + " " + values.surgeons[i].qualification;
                  } else {
                    name = "Dr. " + values.surgeons[i].first_name + " " + values.surgeons[i].last_name + " " + values.surgeons[i].qualification;
                  }
                  this.surgeon_list.push({ name: name });
                }
              }

              if (values.anaest_doc != undefined) {
                for (var i = 0; i < values.anaest_doc.length; i++) {
                  var name;
                  if (values.anaest_doc[i].middle_name != undefined) {
                    name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].middle_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;
                  } else {
                    name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;
                  }
                  this.anaestetist.push({ name: name });
                }
              }
            }

            if (values.surgery_time != undefined) {
              this.surgery_time = values.surgery_time;
            }
            this.dis_diag = values.discharge_diagnosis;
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  view_prescription() {
    const dialogRef5 = this.dialog.open(InpatMedprescViewComponent, {
      width: '700px',
      height: '300px',
      data: { inpatient_id: this.inpatient_id }
    });

    dialogRef5.afterClosed().subscribe(result => {

    });
  }

  Write_prescription() {
    var inpat_pres: any = []
    inpat_pres = {
      Gender_data: this.personal_information.Gender_data,
      Age_data: this.personal_information.Age_data,
      app_id: this.personal_information.app_id,
      Client_id: this.personal_information.Client_id,
      rel_id: "1",
      sub_id: "",
      Case_Clnt_dob: this.personal_information.Case_Clnt_dob,
      spl_id: this.personal_information.spl_id,
      hptl_clinic_id: this.personal_information.hptl_clinic_id,
      mobile: this.personal_information.mobile,
      spl: this.personal_information.spl,
      symptoms: this.personal_information.symptoms,
      medicare_name: this.personal_information.medicare_name,
      client_name: this.personal_information.client_name,
      addmed: "write",
      AppFlow: "InpatMedPres_dis",
      Appoint_Date: this.personal_information.Appoint_Date,
      address1: this.personal_information.address1,
      address2: this.personal_information.address2,
      location: this.personal_information.location,
      city: this.personal_information.city,
      state: this.personal_information.state,
      country: this.personal_information.country,
      zipcode: this.personal_information.zipcode,
      ref_by: this.personal_information.ref_by,
      hptl_name: this.personal_information.hptl_name,
      pres_id: this.presID,
      inpatient_id: this.inpatient_id,
      discharge_id: this.discharge,
    };
    Doc_Helper.setClient_Info(inpat_pres);
    this.messageService.sendMessage("medprescription");
  }

  getDischargeSummaryDetails() {
    this.consultarray=[];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdissum",
      JSON.stringify({
        mobile: this.mobile.toString(),
        inpatient_id: this.inpatient_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var values = response.json();
          console.log("DISCHARGE SUMMARY " + JSON.stringify(values))
          if (values != undefined || values != null) {
            this.loader = true;
            if (values.middle_name != undefined) {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
            } else {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
            }
            this.inpId = values.inp_hosp_id;
            this.patient_identifier = values.patient_id;
            this.gender = encrypt_decript.Decript(values.gender);
            if (this.gender == "Male") {
              this.gender = "M";
            } else if (this.gender == "Female") {
              this.gender = "F";
            }
            this.age = values.age;

            if (values.admission_date != undefined) {
              var dateval = values.admission_date.split('-');
              this.admis_date = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
              this.admDate = values.admission_date;

              var dtval = values.admission_time.split(':')
              if (parseInt(dtval[0]) > 12) {
                this.admis_time = (parseInt(dtval[0]) - 12) + ":" + dtval[1] + " PM";

              } else if (parseInt(dtval[0]) == 12) {
                this.admis_time = dtval[0] + ":" + dtval[1] + " PM";
              } else {
                this.admis_time = dtval[0] + ":" + dtval[1] + " AM";
              }
            }

            this.presID = values.pres_drug_id;
            if (values.discharge_date != undefined) {
              this.dis_date = values.discharge_date;
              var disdata = values.discharge_date.split("-");
              this.dis_date1 = disdata[2] + "-" + disdata[1] + "-" + disdata[0];
            }

            if (values.discharge_time != "") {
              var dtval = values.discharge_time.split(':')
              if (parseInt(dtval[0]) > 12) {
                this.dis_time = (parseInt(dtval[0]) - 12) + ":" + dtval[1] + " PM";

              } else if (parseInt(dtval[0]) == 12) {
                this.dis_time = dtval[0] + ":" + dtval[1] + " PM";

              } else {
                this.dis_time = dtval[0] + ":" + dtval[1] + " AM";
              }
            }

            var docqualif;
            if (values.dr_qualif != undefined) {
              docqualif = values.dr_qualif
            } else {
              docqualif = "";
            }
            if (values.dr_mname != undefined) {
              this.doctor_name = "Dr." + values.dr_fname + " " + values.dr_mname + " " + values.dr_lname + " " + docqualif;

            } else {
              this.doctor_name = "Dr." + values.dr_fname + " " + values.dr_lname + " " + docqualif;
            }
            //this.consultarray.push({ name: this.doctor_name });
            if (values.consultants != undefined) {
              var cname;
              for (var i = 0; i < values.consultants.length; i++) {
                if (values.consultants[i].middle_name != undefined) {
                  cname = "Dr." + values.consultants[i].first_name + " " + values.consultants[i].middle_name + " " + values.consultants[i].last_name;
                } else {
                  cname = "Dr." + values.consultants[i].first_name + " " + values.consultants[i].last_name;
                }
                if (values.consultants[i].qualification != undefined) {
                  cname = cname + " " + values.consultants[i].qualification;
                }
                this.consultarray.push({ name: cname });
              }
            }

            this.symptoms = values.symptoms;
            if (values.complaints != undefined) {
              this.complaints = values.complaints;
              this.comp_flag = true;
            }

            if (values.medical_history != undefined && values.medical_history != "") {
              this.medical_hist = values.medical_history;
              this.prev_med_flag = true;
            }

            if (values.physical_exam != undefined && values.physical_exam != "") {
              this.physical_exam = values.physical_exam;
              this.phy_exam_flag = true;
            }

            if (values.discharge_diagnosis != undefined) {
              this.dis_diag = values.discharge_diagnosis;
              this.dis_diag_flag = true;
            }

            if (values.hosp_course != undefined) {
              this.hosp_course = values.hosp_course;
              this.sum_course_flag = true;
            }

            if (values.emergencies != undefined) {
              this.emergency_notes = values.emergencies;
            }

            if (values.treat_proc != undefined) {
              this.Treatmetnt_done = values.treat_proc;
              this.treat_flag = true;
            }

            if (values.hospital_followup != undefined) {
              this.hospital_followup = values.hospital_followup;
              this.dis_adv_flag = true;
            }

            if (values.inp_inv != undefined) {
              this.investigation = values.inp_inv;
              this.inv_flag = true;
            }

            if (values.ot_notes != undefined) {
              this.ot_notes = values.ot_notes;
              this.otnote_flag = true;
            }

            if (this.dis_diag != undefined || this.investigation != undefined) {
              this.updateFlag = true;
              this.confirm_btn = true;
            }

            this.inpatient_id = values.inpatient_id;
            this.hosp_name = values.hptl_name;

            if (values.surgery_date != undefined) {
              var surgdate = values.surgery_date.split("-");
              this.surgery_date = surgdate[2] + "-" + surgdate[1] + "-" + surgdate[0] + " " + values.surgery_time;
              this.surgery_proc = values.surgery_proc;
              if (values.surgeons != undefined) {
                for (var i = 0; i < values.surgeons.length; i++) {
                  var name;
                  if (values.surgeons[i].middle_name != undefined) {
                    name = "Dr. " + values.surgeons[i].first_name + " " + values.surgeons[i].middle_name + " " + values.surgeons[i].last_name + " " + values.surgeons[i].qualification;
                  } else {
                    name = "Dr. " + values.surgeons[i].first_name + " " + values.surgeons[i].last_name + " " + values.surgeons[i].qualification;
                  }
                  this.surgeon_list.push({ name: name });
                }
              }

              if (values.anaest_doc != undefined) {
                for (var i = 0; i < values.anaest_doc.length; i++) {
                  var name;
                  if (values.anaest_doc[i].middle_name != undefined) {
                    name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].middle_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;
                  } else {
                    name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;
                  }
                  this.anaestetist.push({ name: name });
                }
              }
              this.anaestetype = values.anaesttype;
              this.anaest_proc = values.anaest_proc;
              this.anaest_findings = values.anaest_findings;
            }

            this.Treatmetnt_done = values.treat_proc;
            this.hospital_followup = values.hospital_followup;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;
            } else {
              addval = values.address1;
            }

            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
            this.hosp_count = values.country;
            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }

            if (values.website != undefined) {
              this.hosp_web = values.website;
              this.hosp_web_flag = false;

            } else {
              this.hosp_web_flag = true;
            }
            if(values.height != undefined) {
              this.height = values.height+" "+values.height_measure;
              this.height_measure = values.height_measure;
            } else {
              this.height="";
              this.height_measure="";
            }
            if(values.weight != undefined) {
              this.weight = values.weight+" "+values.weight_measure;
              this.weight_measure = values.weight_measure;
            } else {
              this.weight = "";
              this.weight_measure = "";
            }
            
            this.temparature = values.temparature;
            if(values.blood_pressure != undefined) {
              this.blood_pressure = values.blood_pressure+" mmHg";
            }
            if(values.pulse != undefined) {
              this.pulse = values.pulse+" bpm";
            } 
            if(values.resp_rate != undefined){
              this.resp_rate = values.resp_rate+" bpm";
            }
            this.cvs = values.cvs;
            this.rstxt = values.rstxt;
            this.cns = values.cns;
          }

          if (values.next_visit != undefined && values.next_visit != '' && values.next_visit != null) {
            this.next_data = values.next_visit;
            var nextdate = values.next_visit.split('-');
            this.next_data1 = nextdate[2] + "-" + nextdate[1] + "-" + nextdate[0];
          }

          if (Doc_Helper.getMedForDischarge() != undefined) {
            var med = Doc_Helper.getMedForDischarge();
            for (var i = 0; i < med.length; i++) {
              var durval;
              if (med.time_duration != undefined) {
                durval = med.time_duration + " mins";

              } else {
                durval = "";
              }

              var shortName = med[i].short_name != undefined ? med[i].short_name : "";
              this.druglist.push({
                medicine_name: shortName + " " + med[i].drug_name,
                medicine_dosage: med[i].every_six,
                medicine_intakedays: med[i].days + ' '+med[i].day_dur,
                medicine_food: durval + " " + med[i].drug_intake
              });
            }

          } else {
            var druglist = values.drug_list;
            for (var i = 0; i < druglist.length; i++) {
              var durval;
              if (druglist.time_duration != undefined) {
                durval = druglist.time_duration + " mins";

              } else {
                durval = "";
              }

              var shortName = druglist[i].short_name != undefined ? druglist[i].short_name : "";
              this.druglist.push({
                medicine_name: shortName + " " + druglist[i].drug_name,
                medicine_dosage: druglist[i].every_six,
                medicine_intakedays: druglist[i].days + ' '+druglist[i].day_dur,
                medicine_food: durval + " " + druglist[i].drug_intake
              });
            }
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  NextDate(e) {
    this.next_data = e;
    // this.next_data=e.value.getDate()+"-"+ ((e.value.getMonth()) + 1) +"-" +e.value.getFullYear();
  }

  saveDischargeSummary() {
    var dateval;
    var newdate;
    if (this.next_data != undefined) {
      newdate = this.next_data;
    }

    var medpres;
    if (Helper_Class.get_med_pres_id() != null) {
      medpres = Helper_Class.get_med_pres_id();
    }
    var diagpres;
    if (Helper_Class.get_diag_pres_id() != null) {
      diagpres = Helper_Class.get_diag_pres_id();
    }

    var save_data = JSON.stringify({
      inpatient_id: this.inpatient_id,
      discharge_diagnosis: this.dis_diag,
      hopital_course: this.hosp_course,
      follow_summary: this.hospital_followup,
      next_visit: newdate,
      pres_drug_id: medpres,
      symptoms: this.symptoms,
      treat_proc: this.Treatmetnt_done,
      investigation: this.investigation,
      ot_notes: this.ot_notes,
      emergencies: this.emergency_notes,
      discharge_date: this.dis_date,
      discharge_time: this.dis_time,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/sdissum', save_data,
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(response["_body"]);

          if (data.key != "0") {
            this.confirm_btn = true;
            this.toastr.success(Message_data.disSummarySavedSuccess);
            this.print_area();
          } else {
            this.confirm_btn = false;
            this.toastr.error(Message_data.unabltToSaveDisSummary);
          }
        })
  }

  goToPatientlist() {
    if (this.flagvalue != undefined && this.flagvalue == "inpat") {
      this.messageService.sendMessage("viewIpatient");

    } else {
      if (Doc_Helper.getAdmission_id().flag == "edit") {
        this.messageService.sendMessage("patient_dashboard");
      } else {
        this.messageService.sendMessage("inPatientViewInfo");
      }
    }
  }

  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            get_date = obj.current_date.split('-');
            this.currentDate = obj.current_date;
            this.next_data = obj.current_date;
            this.dis_date = obj.current_date;

            var disdata = this.dis_date.split("-");
            this.dis_date1 = disdata[2] + "-" + disdata[1] + "-" + disdata[0];
            var dtval = obj.current_time.split(':')
            if (parseInt(dtval[0]) > 12) {
              this.dis_time = (parseInt(dtval[0]) - 12) + ":" + dtval[1] + " PM";

            } else if (parseInt(dtval[0]) == 12) {
              this.dis_time = dtval[0] + ":" + dtval[1] + " PM";

            } else {
              this.dis_time = dtval[0] + ":" + dtval[1] + " AM";
            }
          }
        },
        error => {

        }
      )
  }

  print_area() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", { inpatient_id: this.inpatient_id },
      { headers: headers })
      .subscribe(
        response => {
          var values = JSON.parse(response["_body"]);
          if (values != undefined) {
            this.loader = true;

            if (values.middle_name != undefined) {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);

            } else {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
            }

            this.patient_identifier = values.patient_id;
            this.gender = encrypt_decript.Decript(values.gender);
            this.age = values.age;
            var dateval = values.admission_date.split('-');

            this.admis_date = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
            //this.dis_date=localStorage.getItem("current_date");
            if (values.dr_mname != undefined) {
              this.doctor_name = values.dr_fname + " " + values.dr_mname + " " + values.dr_lname;

            } else {
              this.doctor_name = values.dr_fname + " " + values.dr_lname;
            }

            this.symptoms = values.symptoms;
            this.complaints = values.complaints;

            this.medical_hist = values.medical_history;
            this.physical_exam = values.physical_exam;

            this.dis_diag = values.discharge_diagnosis;
            this.hosp_course = values.hospital_course;
            this.hospital_followup = values.follow_summary;

            if (values.next_visit != undefined && values.next_visit != '' && values.next_visit != null) {
              var nextdate = values.next_visit.split('-');
              this.next_data = values.next_visit;
              this.next_data1 = nextdate[2] + "-" + nextdate[1] + "-" + nextdate[0];
            }

            this.druglist = [];
            if (values.drug_list != undefined) {
              this.print_drug = true;
              for (var i = 0; i < values.drug_list.length; i++) {
                var master_tablet_data = values.drug_list[i];
                var medicine_dosage_data = "";

                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + '-' + master_tablet_data.morning + master_tablet_data.short_form + ' - ' + master_tablet_data.morning + master_tablet_data.short_form + master_tablet_data.short_form + ' - ' + master_tablet_data.morning + master_tablet_data.short_form;

                } else if (master_tablet_data.intake_desc == "SOS") {
                  medicine_dosage_data = "SOS - if required";

                } else if (master_tablet_data.intake_desc == "Thrice a day") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + " - " + master_tablet_data.afternoon + master_tablet_data.short_form + " - " + master_tablet_data.night + master_tablet_data.short_form;

                } else if (master_tablet_data.intake_desc == "Four times a day") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + " - " + master_tablet_data.afternoon + master_tablet_data.short_form + " - " + master_tablet_data.evening + master_tablet_data.short_form + " - " + master_tablet_data.night + master_tablet_data.short_form;

                } else if (master_tablet_data.intake_desc == "Once a week") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + " - " + master_tablet_data.afternoon + master_tablet_data.short_form + " - " + master_tablet_data.evening + master_tablet_data.short_form + " - " + master_tablet_data.night + master_tablet_data.short_form;

                } else if (master_tablet_data.intake_desc == "Once a day") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + " - per day";

                } else {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + ' - ' + master_tablet_data.afternoon + master_tablet_data.short_form + ' - ' + master_tablet_data.night + master_tablet_data.short_form;
                }

                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;

                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;

                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }

                this.druglist.push({
                  medicine_name: master_tablet_data.short_name + " " + master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' '+master_tablet_data.day_dur,
                  medicine_food: master_timeDuration
                })
              }
            } else {
              this.print_drug = false;
            }


            this.hosp_name = values.hptl_name;
            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
            this.hosp_count = values.country;
            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }

            if (values.website != undefined) {
              this.hosp_web = values.website;
              this.hosp_web_flag = false;

            } else {
              this.hosp_web_flag = true;
            }
            // this.print();

          } else {
            this.toastr.error(Message_data.defaultErr);
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.network);
        }
      )
  }

  print() {
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <head>
        <title>Discharge summary</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
          @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .block1_grid{
            margin: 7px auto;
            width: 90%;
          }
          .block1{
            margin:3px 0;
          }
        </style>
      </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  onContentChanged = (event) => {
    this.dis_diag = event.html;
  }

  onContentChangedTreatment = (event) => {
    this.Treatmetnt_done = event.html;
  }

  onContentChangedHospitalSummary = (event) => {
    this.hosp_course = event.html;
  }

  onContentChangedInvestigation = (event) => {
    this.investigation = event.html;
  }

  onContentChangedOtNotes = (event) => {
    this.ot_notes = event.html;
  }

  onContentChangedEmergency = (event) => {
    this.emergency_notes = event.html;
  }

  onContentChangedOTNotes = (event) => {
    this.ot_notes = event.html;
  }

  onContentChangedDischargeAdvice = (event) => {
    this.hospital_followup = event.html;
  }

  updateDischarge() {
    var newdate;
    if (this.next_data != undefined) {
      newdate = this.next_data;
    }
    var medpres;
    if (Helper_Class.get_med_pres_id() != null) {
      medpres = Helper_Class.get_med_pres_id();
    } else {
      medpres = this.presID;
    }

    var diagpres;
    if (Helper_Class.get_diag_pres_id() != null) {
      diagpres = Helper_Class.get_diag_pres_id();
    }

    var save_data = JSON.stringify({
      inpatient_id: this.inpatient_id,
      discharge_diagnosis: this.dis_diag,
      hopital_course: this.hosp_course,
      follow_summary: this.hospital_followup,
      next_visit: newdate,
      pres_drug_id: medpres,
      symptoms: this.symptoms,
      treat_proc: this.Treatmetnt_done,
      investigation: this.investigation,
      ot_notes: this.ot_notes,
      emergencies: this.emergency_notes,
      discharge_date: this.dis_date,
      pres_diag_id: diagpres,
    });
    this.updateFlag = false;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/udissum', save_data,
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(response["_body"]);
          if (data.key != "0") {
            this.updateFlag = true;
            this.toastr.success(Message_data.disSummarySavedSuccess);
            this.messageService.sendMessage("patient_dashboard");
          } else {
            this.updateFlag = true;
            this.toastr.error(Message_data.unabltToSaveDisSummary);
          }
        })
  }
  
  getfullreportdata() {
    var send_data = {
      hptl_clinic_id: this.centerid,
    };

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp.toString() + 'inpat/gidc', send_data, { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("unknown send data", send_data);
          console.log("Get unknown data", obj);
          console.log("get from backend" + JSON.stringify(obj));
          this.reportlistarray = {
            discharge_heading: obj.discharge_heading,
            diagnosis_req: obj.diagnosis_req,
            diagnosis_desc: obj.diagnosis_desc,
            treatment_req: obj.treatment_req,
            treatment_desc: obj.treatment_desc,
            summary_course_req: obj.summary_course_req,
            summary_course_desc: obj.summary_course_desc,
            investigation_req: obj.investigation_req,
            investigation_desc: obj.investigation_desc,
            emerygency_req: obj.emerygency_req,
            emergency_desc: obj.emergency_desc,
            discharge_advice_req: obj.discharge_advice_req,
            discharge_advice_desc: obj.discharge_advice_desc,
            chief_complaints_req: obj.chief_complaints_req,
            chief_complaints_desc: obj.chief_complaints_desc,
            prev_med_hist_req: obj.prev_med_hist_req,
            prev_med_hist_desc: obj.prev_med_hist_desc,
            physical_exam_req: obj.physical_exam_req,
            physical_exam_desc: obj.physical_exam_desc,
            vitals_req: obj.vitals_req,
            height_req: obj.height_req,
            weight_req: obj.weight_req,
            temparature_req: obj.temparature_req,
            bp_req: obj.bp_req,
            bp_desc: obj.bp_desc,
            pulse_req: obj.pulse_req,
            pulse_desc: obj.pulse_desc,
            rr_req: obj.rr_req,
            rr_desc: obj.rr_desc,
            cvs_req: obj.cvs_req,
            cvs_desc: obj.cvs_desc,
            rs_req: obj.rs_req,
            rs_desc: obj.rs_desc,
            cns_req: obj.cns_req,
            cns_desc: obj.cns_desc,
            surgery_req: obj.surgery_req,
            surgery_procedure_req: obj.surgery_procedure_req,
            surgery_procedure_desc: obj.surgery_procedure_desc,
            surgery_date_req: obj.surgery_date_req,
            surgery_date_desc: obj.surgery_date_desc,
            anaestetist_req: obj.anaestetist_req,
            anaestetist_desc: obj.anaestetist_desc,
            anaestesia_req: obj.anaestesia_req,
            anaestesia_desc: obj.anaestesia_desc,
            family_hist_desc: obj.family_hist_desc,
            family_hist_req: obj.family_hist_req,
            present_illness_req: obj.present_illness_req,
            present_illness_desc: obj.present_illness_desc,
            surgeon_req: obj.surgeon_req,
            surgeon_desc: obj.surgeon_desc,
            anaestesia_procedure_req: obj.anaestesia_procedure_req,
            anaestesia_procedure_desc: obj.anaestesia_procedure_desc,
            heading_style:obj.heading_style,
            ot_notes_req:obj.ot_notes_req,
            ot_notes_desc:obj.ot_notes_desc,
          };
        },

        error => {
          this.toastr.warning("error in fetch role");
        }
      );
  }
}
