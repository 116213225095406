import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from '../../ipaddress';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
@Component({
  selector: 'app-pharma-stock-status',
  templateUrl: './pharma-stock-status.component.html',
  styleUrls: ['./pharma-stock-status.component.css']
})
export class PharmaStockStatusComponent implements OnInit {
  public locationList = [];
  public LoadLocationArray = [];
  public pharmacyFacID: any;
  public location: any;
  public locIP = null;
  public stockData: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  dtOptions: DataTables.Settings = {};
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search product name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    this.pharmaLocation();
  }
  pharmaLocation() {
    var send_data;
    if (Helper_Class.getIsAdmin() != undefined) {
      send_data = JSON.stringify({
        hptl_clinic_id: this.pharmacyID,
      });
    } else {
      send_data = JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/", send_data, { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (data.json().pharma_locations != null) {
          this.locationList = data.json().pharma_locations;
          for (var i = 0; i < this.locationList.length; i++) {
            this.LoadLocationArray.push({
              location_id: this.locationList[i].pharma_facility_id + " - " + this.locationList[i].location,
              pharma_facility_id: this.locationList[i].pharma_facility_id,
            });
            if (i == 0) {
              this.get_stock(this.locationList[i].pharma_facility_id);
            }
          }
          if (this.LoadLocationArray.length != 0) {
            this.location = this.LoadLocationArray[0].location_id;
          }
        }
      },
      error => { });
  }
  get_stock(pharmacy_fac_id) {
    var send_data;
    if (Helper_Class.getIsAdmin() != undefined) {
      send_data = JSON.stringify({
        hptl_clinic_id: this.pharmacyID,
      });
    } else {
      send_data = JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpstock/", send_data,
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if (obj.key != 0) {
            if (obj.stock_onhand != null) {
              this.stockData = obj.stock_onhand;
            }
          }
        },
        error => { });
  }
  exportToExcel() {
    var excel_data = [];
    var header = ['Medicine', 'Generic name', 'UOM', 'Supplier', 'Batch', 'Expiry date', 'Available qty']
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Stock status report');
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })
    for (let stock of this.stockData) {
      excel_data.push({
        name: stock.name,
        generic_name: stock.generic_name,
        uom: stock.uom_desc,
        mfg_name: stock.supplier,
        batch_no: stock.batch_no,
        exp_date: stock.exp_date,
        qty_onhand: stock.qty_onhand
      })
    }
    for (let x1 of excel_data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(7);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "stock_status_report" + '-' + new Date().valueOf() + '.xlsx');
    });
  }
  save_data() {
  }
}
