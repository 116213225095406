import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Observable, observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DietpopupwindowComponent } from '../dietpopupwindow/dietpopupwindow.component';
import { DietplanEditComponent } from '../dietplan-edit/dietplan-edit.component';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DietplanhistorypopupComponent } from '../dietplanhistorypopup/dietplanhistorypopup.component';
import { HabitualService } from '../service/habitualservice';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { dietDisease } from '../service/dite-disease.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Date_Formate } from 'src/assets/js/common';
import moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-new-dietplan',
  templateUrl: './new-dietplan.component.html',
  styleUrls: ['./new-dietplan.component.scss'],

})
export class NewDietplanComponent implements OnInit {
  public dietPlanFlag = "min";
  public foot_cate = [];
  public mor_foot_type = [];
  public mor_subfoot_type = [];
  public cal_txt: string;
  public tot_cal = "0.00";
  subscription: Subscription;
  public Sga_overalltxt: string;
  //public col_dtaa;
  public userinfo: any;
  public user_id: any;
  public rel_id = "1";
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public client_reg_id;
  public relation_id;
  public sub_rel_id;
  public patient_list;
  public opd;
  public client: boolean = false;
  public diseaseType;
  public splId;
  public splName;
  // NEW DESING ARRAY
  public displayArray: any = [];
  public morningFoodItem = [];
  public breadfastFoodItem = [];
  public midDayFoodItem = [];
  public lunchFoodItem = [];
  public eveningFoodItem = [];
  public dinnerFoodItem = [];
  public nightFoodItem = [];
  public morningFoods;
  public BreakfastFoods;
  public MidDayFoods;
  public LunchFoods;
  public EveningFoods;
  public DinnerFoods;
  public NightFoods;
  public mainDishFlag: boolean = false;
  public contanier;
  public foodImages = [];
  public diet_mrng_arry = [];
  public break_fast_arrydata = [];
  public mid_day_arrydata = [];
  public lunch_arrydata = [];
  public evening_arrydata = [];
  public dinner_arrydata = [];
  public night_arrydata = [];
  public dietitianflage: boolean = false;
  public diet_plan_all = "0";
  public obj: any = [];
  public ynopt1: boolean = false;
  public currentDiet: boolean;
  public templateDiet: boolean;
  public dietType;
  public deaciseName;
  public selctedDay;
  public selctedDate;

  public previous_day;
  public tempMorningArray;
  public tempBreakfastArray;
  public tempMid_dayArray;
  public tempLunchArray;
  public tempEveningArray;
  public tempDinnerArray;
  public tempNightArray;
  public dayChangeFlag: boolean = true;
  public pdfFlag: boolean = false;
  public dateFlag: boolean = false;
  public pdfURL;
  public appointmentType;
  public sendFromDate;
  public sendToDate;
  public toDate;
  public fromDate;
  public currentDate;
  public currentTime;
  public currentYear;
  public week;
  public previousFlag;
  public nextFlag;
  public showArray = [];

  constructor(public habitualservice: HabitualService, public messageservice: CasesheetService, public dialog: MatDialog, public http: Http,
    public router: Router, public route: ActivatedRoute, public toastr: ToastrService, public masterCSdata: MasterCSHelperService, public dietDisease: dietDisease, public sanitizer: DomSanitizer) {
    this.cal_txt = "0";
    this.tot_cal = "0";
  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.appointmentType = "Weekly";
        this.dietPlanFlag = message;
        if (this.dietPlanFlag == "max") {
          this.ColoriesRequiredFun();
          this.getSpecilizationType(Doc_Helper.getClient_Info().spl);
          setTimeout(() => {
            $("#Sunday-tab").addClass("active");
            $("#Sunday").addClass("active");
          }, 500);
          console.log("check status" + JSON.stringify(Doc_Helper.getDietDiease()));
          if (Doc_Helper.getDietDiease().changeFlag == "1" || Doc_Helper.getDietDiease().changeFlag == "") {
            this.obj = [];
            this.get_casesheet_data(undefined);
          }
        }
      }
    });

    this.subscription = this.habitualservice.getMessage().subscribe(message => {
      if (message) {
        this.cal_txt = message;
        this.KcalCalories(this.cal_txt)
      }
    });

    if (Doc_Helper.getAppFlow() == "client") {
      this.patient_list = Helper_Class.getInfo();
      this.userinfo = Helper_Class.getclient_hospitalDetails();
      this.user_id = this.userinfo.user_id;
      this.client = true;

    } else {
      this.patient_list = Doc_Helper.getClient_Info();
      this.userinfo = Helper_Class.getInfo();
      this.user_id = this.userinfo.user_id;
      this.client = false;
    }

    this.dietPlanFlag = this.patient_list.AppFlow == "Dietician" ? "max" : "min";
    this.dietitianflage = this.patient_list.AppFlow == "Dietician" ? true : false;
    this.hospital_details = Helper_Class.getHospitalInfo();
    this.hospital_name = this.hospital_details.hosp_name;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.hospital_details.hptl_logo;
    var spl = this.patient_list.spl;
    this.obj = [];

    if (masterCSData_Helper.getMasterSpecilizationType() == undefined) {
      await this.masterCSdata.getSpecilizationType();
      this.getSpecilizationType(spl)
    } else {
      this.getSpecilizationType(spl);
    }
    this.getCurrentDate();
  }

  KcalCalories(cal_txt) {
    this.cal_txt = cal_txt;
    var Cal_req = this.cal_txt;
    var Cal_req_00 = parseInt(Cal_req);
    var Cal_max = Cal_req_00 + 500;
    var Tot_Cal = this.tot_cal;
    var Tot_Cal_00 = 0;
    if (Tot_Cal != "") {
      Tot_Cal_00 = parseInt(Tot_Cal);
    }
  }

  getSpecilizationType(splName) {//Get specilization
    var obj = masterCSData_Helper.getMasterSpecilizationType();
    for (var i = 0; i < obj.specialization.length; i++) {
      if (obj.specialization[i].spl_name == splName) {
        this.splId = obj.specialization[i].spl_id;
        this.splName = obj.specialization[i].spl_name
      }
    }
  }

  ColoriesRequiredFun() {
    if (Helper_Class.gethabitual() != undefined && (Doc_Helper.getVitals() != undefined && Doc_Helper.getVitals().bmr != undefined)) {
      if (Helper_Class.gethabitual().exercise_routine_des == "Light") {
        var get_txt = Doc_Helper.getVitals().bmr * 1.375;
        this.cal_txt = Math.round(get_txt).toString();
      } else if (Helper_Class.gethabitual().exercise_routine_des == "Moderate") {
        var get_txt = Doc_Helper.getVitals().bmr * 1.55;
        this.cal_txt = Math.round(get_txt).toString();
      } else if (Helper_Class.gethabitual().exercise_routine_des == "Heavy") {
        var get_txt = Doc_Helper.getVitals().bmr * 1.725;
        this.cal_txt = Math.round(get_txt).toString();
      } else if (Helper_Class.gethabitual().exercise_routine_des == "Very Heavy") {
        var get_txt = Doc_Helper.getVitals().bmr * 1.9;
        this.cal_txt = Math.round(get_txt).toString();
      } else {
        var get_txt = parseFloat(Doc_Helper.getVitals().bmr) * 1.375;
        this.cal_txt = Math.round(get_txt).toString();
      }
    }
  }

  async getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    await this.http.post(ipaddress.getIp + "adm/curdate",
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }), { headers: headers }).toPromise().then(
        data => {
          var obj = data.json();
          this.currentDate = Date_Formate(obj.current_date);
          this.currentTime = obj.current_time;
          this.currentYear = obj.current_date.split('-')[0];
        });
  }

  changeAppointmentType() {
    var e;
    this.sendFromDate = Date_Formate(this.currentDate);
    this.dateFlag = false;
    let days = [];
    if (this.appointmentType == "Today") {
      e = this.currentDate;

    } else if (this.appointmentType == "Weekly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 6);
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Fort Night") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 13);
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Monthly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 29)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "3 Months") {
      var d = new Date();
      d.setDate(d.getDate() + 92)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "6 Months") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 183)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Yearly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 365)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Date") {
      this.dateFlag = true;
      this.sendFromDate = Date_Formate(this.currentDate);
    }
    this.sendToDate = e;
    this.getRange(this.sendFromDate, this.sendToDate, "days");
  }

  getRange(startDate, endDate, type) {
    this.week = 1;
    var getDates = [];
    let fromDate = moment((startDate));
    let toDate = moment((endDate));
    let diff = toDate.diff(fromDate, type)
    var docChecked = true;
    var clientCheked = false;
    this.displayArray = [];
    for (let i = 0; i <= diff; i++) {
      var index = this.displayArray.findIndex(x => x.date == moment(startDate).add(i, type).format('YYYY-MM-DD'));
      if (index == -1 || this.displayArray.length == 0) {
        this.displayArray.push({
          day: moment(startDate).add(i, type).format('dddd'),
          dayLable: moment(startDate).add(i, type).format('dddd'),
          date: moment(startDate).add(i, type).format('YYYY-MM-DD'),
          isDisable: false,
          session: [{
            type: "Morning",
            lableName: "Morning",
            foodItems: [],
            plateContent: "",
            mainDish: false,
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Breakfast",
            lableName: "Breakfast",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Mid-day",
            lableName: "Mid-day Snack",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Lunch",
            lableName: "Lunch",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Evening",
            lableName: "Evening Snack",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Dinner",
            lableName: "Dinner",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Night",
            lableName: "Night",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          ]
        },
        )
      }
    }
    var temp = [];
    var week = 1;
    this.displayArray.forEach((element, index) => {
      if ((index != 0) && ((index % 7) == 0)) {
        week++
      }
      element.weekNo = week;
    });
    this.dayClick(this.displayArray[0].day, this.displayArray[0].date, false);
    this.setNav();
  }

  setNav() {
    var lastWeek = this.displayArray.findLast(x => true);
    if (lastWeek == 1) {
      this.previousFlag = false;
      this.nextFlag = false;

    } else {
      this.previousFlag = (this.week != 1 && lastWeek.weekNo != 1) ? true : false;
      this.nextFlag = (lastWeek.weekNo != 1 && this.week != lastWeek.weekNo) ? true : false;
    }
  }

  selectToDate(e) {
    this.toDate = e;
    this.sendToDate = Date_Formate(e);
  }

  selectFromDate(e) {
    this.fromDate = e;
    this.sendFromDate = Date_Formate(e);
  }

  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  copyDietPlan(action) {
    this.week = action == "next" ? this.week + 1 : this.week - 1;
    this.setNav();
  }

  get_casesheet_data(cal_txt) {
    var sen_pass;
    var cal_txt = cal_txt;
    var disarray = [];
    disarray.push(Doc_Helper.getDietDiease().diseaseName)

    if ((Doc_Helper.getDietDiease().changeFlag == "1" || Doc_Helper.getDietDiease().changeFlag == "")) {
      this.obj = [];
      if (this.patient_list.sub_id != null && this.patient_list.sub_id != undefined &&
        this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0) {
        sen_pass = JSON.stringify({
          client_reg_id: this.patient_list.Client_id,
          relation_id: this.patient_list.rel_id,
          sub_rel_id: this.patient_list.sub_id,
          country: "IN",
          disease: disarray,
          spl_id: this.splId,
        })

      } else {
        sen_pass = JSON.stringify({
          client_reg_id: this.patient_list.Client_id,
          relation_id: this.patient_list.rel_id,
          country: "IN",
          disease: disarray,
          spl_id: this.splId,
        })
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/dplann',
        sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            this.obj = response.json();
            //this.selctedDay = "Sunday";
            this.dietType = "diet_plan";
            this.pdfFlag = this.obj.doc_url != undefined ? true : false;
            if (this.obj.doc_url != undefined) {
              this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address + "/" + this.obj.doc_url + "#toolbar=0");
            }

            var sendData = {
              diseaseName: Doc_Helper.getDietDiease().diseaseName,
              changeFlag: "0",
            }
            Doc_Helper.setDietDiease(sendData);
            this.changeAppointmentType();
          },
          error => { }
        )
    }
  }

  retrivelData(loadingData) {
    console.log("check array" + JSON.stringify(this.obj));
    this.diet_mrng_arry = [];
    this.break_fast_arrydata = [];
    this.mid_day_arrydata = [];
    this.lunch_arrydata = [];
    this.evening_arrydata = [];
    this.dinner_arrydata = [];
    this.night_arrydata = [];
    var family = Helper_Class.getRet();
    var obj;
    if (this.obj.diet_plan != undefined && this.obj.template_plan != undefined) {
      this.currentDiet = true;
      this.templateDiet = true;
      this.diet_plan_all = "1";
      if (loadingData == "template_plan") {
        obj = this.obj.template_plan;
        this.ynopt1 = false;
      } else {
        obj = this.obj.diet_plan;
        this.ynopt1 = true;
      }

    } else if (this.obj.diet_plan != undefined && this.obj.template_plan == undefined) {
      this.currentDiet = true;
      this.templateDiet = false;
      obj = this.obj.diet_plan;
      this.ynopt1 = true;
      this.diet_plan_all = "1";

    } else if (this.obj.template_plan != undefined && this.obj.diet_plan == undefined) {
      this.currentDiet = false;
      this.templateDiet = true;
      obj = this.obj.template_plan;
      this.ynopt1 = false;

    } else {
      this.currentDiet = true;
      this.templateDiet = false;
      this.ynopt1 = true;
    }

    this.tot_cal = obj.cal_intake != undefined ? obj.cal_intake : "0";
    this.Sga_overalltxt = obj.sga_overall_rating != null ? obj.sga_overall_rating : "";
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    if (obj.dplan_morning != null && obj.dplan_morning.length != 0) {
      if (obj.dplan_morning != null) {
        var tempFoodItems = {};
        for (var i = 0; i < obj.dplan_morning.length; i++) {
          var dayMorning = obj.dplan_morning[i].day != undefined ? obj.dplan_morning[i].day : " ";
          var tempWeight = obj.dplan_morning[i].weight.split(" ");
          var calsWight = tempWeight[0];
          var wightMesure = tempWeight[1];
          tempFoodItems = {
            food_item_id: obj.dplan_morning[i].food_item_id,
            description: obj.dplan_morning[i].description,
            food_type_id: obj.dplan_morning[i].food_type_id,
            food_sub_type: obj.dplan_morning[i].food_sub_type,
            quantity: obj.dplan_morning[i].quantity != undefined ? obj.dplan_morning[i].quantity : "0.00",
            measure: obj.dplan_morning[i].measure != undefined ? obj.dplan_morning[i].measure : "0.00",
            weight: obj.dplan_morning[i].weight != undefined ? obj.dplan_morning[i].weight : "0.00",
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj.dplan_morning[i].energy != undefined ? obj.dplan_morning[i].energy : "0.00",
            proteins: obj.dplan_morning[i].proteins != undefined ? obj.dplan_morning[i].proteins : "0.00",
            carbohydrates: obj.dplan_morning[i].carbohydrates != undefined ? obj.dplan_morning[i].carbohydrates : "0.00",
            fat: obj.dplan_morning[i].fat != undefined ? obj.dplan_morning[i].fat : "0.00",
            calcium: obj.dplan_morning[i].calcium != undefined ? obj.dplan_morning[i].calcium : "0.00",
            iron: obj.dplan_morning[i].iron != undefined ? obj.dplan_morning[i].iron : "0.00",
            vitaminC: obj.dplan_morning[i].vitaminC != undefined ? obj.dplan_morning[i].vitaminC : "0.00",
            vitaminA: obj.dplan_morning[i].vitaminA != undefined ? obj.dplan_morning[i].vitaminA : "0.00",
            vitaminB12: obj.dplan_morning[i].vitaminB12 != undefined ? obj.dplan_morning[i].vitaminB12 : "0.00",
            fiber: obj.dplan_morning[i].fiber != undefined ? obj.dplan_morning[i].fiber : "0.00",
            folic: obj.dplan_morning[i].folic != undefined ? obj.dplan_morning[i].folic : "0.00",
            h2o: obj.dplan_morning[i].h2o != undefined ? obj.dplan_morning[i].h2o : "0.00",
            session: "Morning",
            image: obj.dplan_morning[i].image,
          };

          if (obj.dplan_morning[i].day != undefined) {
            this.mainDishFlag = obj.dplan_morning[i].main_course != "1" ? false : true;
            this.selctedFood(obj.dplan_morning[i].day, obj.dplan_morning[i].date, tempFoodItems);
          } else {
            for (var j = 0; j < days.length; j++) {
              this.mainDishFlag = obj.dplan_morning[i].main_course != "1" ? false : true;
              this.selctedFood(days[j], this.selctedDate, tempFoodItems);
            }
          }
        }
      }
    }
    if (obj.dplan_breakfast != null && obj.dplan_breakfast.length != 0) {
      for (var i = 0; i < obj.dplan_breakfast.length; i++) {
        var dayMorning = obj.dplan_breakfast[i].day != undefined ? obj.dplan_breakfast[i].day : " ";
        var tempWeight = obj.dplan_breakfast[i].weight.split(" ");
        var calsWight = tempWeight[0];
        var wightMesure = tempWeight[1];
        tempFoodItems = {
          food_item_id: obj.dplan_breakfast[i].food_item_id,
          description: obj.dplan_breakfast[i].description,
          food_type_id: obj.dplan_breakfast[i].food_type_id,
          food_sub_type: obj.dplan_breakfast[i].food_sub_type,
          quantity: obj.dplan_breakfast[i].quantity != undefined ? obj.dplan_breakfast[i].quantity : "0.00",
          measure: obj.dplan_breakfast[i].measure != undefined ? obj.dplan_breakfast[i].measure : "0.00",
          weight: obj.dplan_breakfast[i].weight != undefined ? obj.dplan_breakfast[i].weight : "0.00",
          cals_weight: calsWight,
          wightMesure: wightMesure,
          energy: obj.dplan_breakfast[i].energy != undefined ? obj.dplan_breakfast[i].energy : "0.00",
          proteins: obj.dplan_breakfast[i].proteins != undefined ? obj.dplan_breakfast[i].proteins : "0.00",
          carbohydrates: obj.dplan_breakfast[i].carbohydrates != undefined ? obj.dplan_breakfast[i].carbohydrates : "0.00",
          fat: obj.dplan_breakfast[i].fat != undefined ? obj.dplan_breakfast[i].fat : "0.00",
          calcium: obj.dplan_breakfast[i].calcium != undefined ? obj.dplan_breakfast[i].calcium : "0.00",
          iron: obj.dplan_breakfast[i].iron != undefined ? obj.dplan_breakfast[i].iron : "0.00",
          vitaminC: obj.dplan_breakfast[i].vitaminC != undefined ? obj.dplan_breakfast[i].vitaminC : "0.00",
          vitaminA: obj.dplan_breakfast[i].vitaminA != undefined ? obj.dplan_breakfast[i].vitaminA : "0.00",
          vitaminB12: obj.dplan_breakfast[i].vitaminB12 != undefined ? obj.dplan_breakfast[i].vitaminB12 : "0.00",
          fiber: obj.dplan_breakfast[i].fiber != undefined ? obj.dplan_breakfast[i].fiber : "0.00",
          folic: obj.dplan_breakfast[i].folic != undefined ? obj.dplan_breakfast[i].folic : "0.00",
          h2o: obj.dplan_breakfast[i].h2o != undefined ? obj.dplan_breakfast[i].h2o : "0.00",
          session: "Breakfast",
          image: obj.dplan_breakfast[i].image,
        };

        if (obj.dplan_breakfast[i].day != undefined) {
          this.mainDishFlag = obj.dplan_breakfast[i].main_course != "1" ? false : true;
          this.selctedFood(obj.dplan_breakfast[i].day, obj.dplan_breakfast[i].date, tempFoodItems);
        } else {
          for (var j = 0; j < days.length; j++) {
            this.mainDishFlag = obj.dplan_breakfast[i].main_course != "1" ? false : true;
            this.selctedFood(days[j], this.selctedDate, tempFoodItems);
          }
        }
      }
    }
    //Mid day
    if (obj.dplan_midday != null && obj.dplan_midday.length != 0) {
      for (var i = 0; i < obj.dplan_midday.length; i++) {
        var dayMorning = obj.dplan_midday[i].day != undefined ? obj.dplan_midday[i].day : " ";
        var tempWeight = obj.dplan_midday[i].weight.split(" ");
        var calsWight = tempWeight[0];
        var wightMesure = tempWeight[1];
        tempFoodItems = {
          food_item_id: obj.dplan_midday[i].food_item_id,
          description: obj.dplan_midday[i].description,
          food_type_id: obj.dplan_midday[i].food_type_id,
          food_sub_type: obj.dplan_midday[i].food_sub_type,
          quantity: obj.dplan_midday[i].quantity != undefined ? obj.dplan_midday[i].quantity : "0.00",
          measure: obj.dplan_midday[i].measure != undefined ? obj.dplan_midday[i].measure : "0.00",
          weight: obj.dplan_midday[i].weight != undefined ? obj.dplan_midday[i].weight : "0.00",
          cals_weight: calsWight,
          wightMesure: wightMesure,
          energy: obj.dplan_midday[i].energy != undefined ? obj.dplan_midday[i].energy : "0.00",
          proteins: obj.dplan_midday[i].proteins != undefined ? obj.dplan_midday[i].proteins : "0.00",
          carbohydrates: obj.dplan_midday[i].carbohydrates != undefined ? obj.dplan_midday[i].carbohydrates : "0.00",
          fat: obj.dplan_midday[i].fat != undefined ? obj.dplan_midday[i].fat : "0.00",
          calcium: obj.dplan_midday[i].calcium != undefined ? obj.dplan_midday[i].calcium : "0.00",
          iron: obj.dplan_midday[i].iron != undefined ? obj.dplan_midday[i].iron : "0.00",
          vitaminC: obj.dplan_midday[i].vitaminC != undefined ? obj.dplan_midday[i].vitaminC : "0.00",
          vitaminA: obj.dplan_midday[i].vitaminA != undefined ? obj.dplan_midday[i].vitaminA : "0.00",
          vitaminB12: obj.dplan_midday[i].vitaminB12 != undefined ? obj.dplan_midday[i].vitaminB12 : "0.00",
          fiber: obj.dplan_midday[i].fiber != undefined ? obj.dplan_midday[i].fiber : "0.00",
          folic: obj.dplan_midday[i].folic != undefined ? obj.dplan_midday[i].folic : "0.00",
          h2o: obj.dplan_midday[i].h2o != undefined ? obj.dplan_midday[i].h2o : "0.00",
          session: "Mid-day",
          image: obj.dplan_midday[i].image,
        };

        if (obj.dplan_midday[i].day != undefined) {
          this.mainDishFlag = obj.dplan_midday[i].main_course != "1" ? false : true;
          this.selctedFood(obj.dplan_midday[i].day, obj.dplan_midday[i].date, tempFoodItems);
        } else {
          for (var j = 0; j < days.length; j++) {
            this.mainDishFlag = obj.dplan_midday[i].main_course != "1" ? false : true;
            this.selctedFood(days[j], this.selctedDate, tempFoodItems);
          }
        }
      }
    }
    //Lunch
    if (obj.dplan_lunch != null && obj.dplan_lunch.length != 0) {
      for (var i = 0; i < obj.dplan_lunch.length; i++) {
        var dayMorning = obj.dplan_lunch[i].day != undefined ? obj.dplan_lunch[i].day : " ";
        var tempWeight = obj.dplan_lunch[i].weight.split(" ");
        var calsWight = tempWeight[0];
        var wightMesure = tempWeight[1];
        tempFoodItems = {
          food_item_id: obj.dplan_lunch[i].food_item_id,
          description: obj.dplan_lunch[i].description,
          food_type_id: obj.dplan_lunch[i].food_type_id,
          food_sub_type: obj.dplan_lunch[i].food_sub_type,
          quantity: obj.dplan_lunch[i].quantity != undefined ? obj.dplan_lunch[i].quantity : "0.00",
          measure: obj.dplan_lunch[i].measure != undefined ? obj.dplan_lunch[i].measure : "0.00",
          weight: obj.dplan_lunch[i].weight != undefined ? obj.dplan_lunch[i].weight : "0.00",
          cals_weight: calsWight,
          wightMesure: wightMesure,
          energy: obj.dplan_lunch[i].energy != undefined ? obj.dplan_lunch[i].energy : "0.00",
          proteins: obj.dplan_lunch[i].proteins != undefined ? obj.dplan_lunch[i].proteins : "0.00",
          carbohydrates: obj.dplan_lunch[i].carbohydrates != undefined ? obj.dplan_lunch[i].carbohydrates : "0.00",
          fat: obj.dplan_lunch[i].fat != undefined ? obj.dplan_lunch[i].fat : "0.00",
          calcium: obj.dplan_lunch[i].calcium != undefined ? obj.dplan_lunch[i].calcium : "0.00",
          iron: obj.dplan_lunch[i].iron != undefined ? obj.dplan_lunch[i].iron : "0.00",
          vitaminC: obj.dplan_lunch[i].vitaminC != undefined ? obj.dplan_lunch[i].vitaminC : "0.00",
          vitaminA: obj.dplan_lunch[i].vitaminA != undefined ? obj.dplan_lunch[i].vitaminA : "0.00",
          vitaminB12: obj.dplan_lunch[i].vitaminB12 != undefined ? obj.dplan_lunch[i].vitaminB12 : "0.00",
          fiber: obj.dplan_lunch[i].fiber != undefined ? obj.dplan_lunch[i].fiber : "0.00",
          folic: obj.dplan_lunch[i].folic != undefined ? obj.dplan_lunch[i].folic : "0.00",
          h2o: obj.dplan_lunch[i].h2o != undefined ? obj.dplan_lunch[i].h2o : "0.00",
          session: "Lunch",
          image: obj.dplan_lunch[i].image,
        };

        if (obj.dplan_lunch[i].day != undefined) {
          this.mainDishFlag = obj.dplan_lunch[i].main_course != "1" ? false : true;
          this.selctedFood(obj.dplan_lunch[i].day, obj.dplan_lunch[i].date, tempFoodItems);
        } else {
          for (var j = 0; j < days.length; j++) {
            this.mainDishFlag = obj.dplan_lunch[i].main_course != "1" ? false : true;
            this.selctedFood(days[j], this.selctedDate, tempFoodItems);
          }
        }
      }
    }
    //Evening nacks
    if (obj.dplan_evening != null && obj.dplan_evening.length != 0) {
      for (var i = 0; i < obj.dplan_evening.length; i++) {
        var dayMorning = obj.dplan_evening[i].day != undefined ? obj.dplan_evening[i].day : " ";
        var tempWeight = obj.dplan_evening[i].weight.split(" ");
        var calsWight = tempWeight[0];
        var wightMesure = tempWeight[1];
        tempFoodItems = {
          food_item_id: obj.dplan_evening[i].food_item_id,
          description: obj.dplan_evening[i].description,
          food_type_id: obj.dplan_evening[i].food_type_id,
          food_sub_type: obj.dplan_evening[i].food_sub_type,
          quantity: obj.dplan_evening[i].quantity != undefined ? obj.dplan_evening[i].quantity : "0.00",
          measure: obj.dplan_evening[i].measure != undefined ? obj.dplan_evening[i].measure : "0.00",
          weight: obj.dplan_evening[i].weight != undefined ? obj.dplan_evening[i].weight : "0.00",
          cals_weight: calsWight,
          wightMesure: wightMesure,
          energy: obj.dplan_evening[i].energy != undefined ? obj.dplan_evening[i].energy : "0.00",
          proteins: obj.dplan_evening[i].proteins != undefined ? obj.dplan_evening[i].proteins : "0.00",
          carbohydrates: obj.dplan_evening[i].carbohydrates != undefined ? obj.dplan_evening[i].carbohydrates : "0.00",
          fat: obj.dplan_evening[i].fat != undefined ? obj.dplan_evening[i].fat : "0.00",
          calcium: obj.dplan_evening[i].calcium != undefined ? obj.dplan_evening[i].calcium : "0.00",
          iron: obj.dplan_evening[i].iron != undefined ? obj.dplan_evening[i].iron : "0.00",
          vitaminC: obj.dplan_evening[i].vitaminC != undefined ? obj.dplan_evening[i].vitaminC : "0.00",
          vitaminA: obj.dplan_evening[i].vitaminA != undefined ? obj.dplan_evening[i].vitaminA : "0.00",
          vitaminB12: obj.dplan_evening[i].vitaminB12 != undefined ? obj.dplan_evening[i].vitaminB12 : "0.00",
          fiber: obj.dplan_evening[i].fiber != undefined ? obj.dplan_evening[i].fiber : "0.00",
          folic: obj.dplan_evening[i].folic != undefined ? obj.dplan_evening[i].folic : "0.00",
          h2o: obj.dplan_evening[i].h2o != undefined ? obj.dplan_evening[i].h2o : "0.00",
          session: "Evening",
          image: obj.dplan_evening[i].image,
        };

        if (obj.dplan_evening[i].day != undefined) {
          this.mainDishFlag = obj.dplan_evening[i].main_course != "1" ? false : true;
          this.selctedFood(obj.dplan_evening[i].day, obj.dplan_evening[i].date, tempFoodItems);
        } else {
          for (var j = 0; j < days.length; j++) {
            this.mainDishFlag = obj.dplan_evening[i].main_course != "1" ? false : true;
            this.selctedFood(days[j], this.selctedDate, tempFoodItems);
          }
        }
      }
    }
    //Dinner nacks
    if (obj.dplan_dinner != null && obj.dplan_dinner.length != 0) {
      for (var i = 0; i < obj.dplan_dinner.length; i++) {
        var dayMorning = obj.dplan_dinner[i].day != undefined ? obj.dplan_dinner[i].day : " ";
        var tempWeight = obj.dplan_dinner[i].weight.split(" ");
        var calsWight = tempWeight[0];
        var wightMesure = tempWeight[1];
        tempFoodItems = {
          food_item_id: obj.dplan_dinner[i].food_item_id,
          description: obj.dplan_dinner[i].description,
          food_type_id: obj.dplan_dinner[i].food_type_id,
          food_sub_type: obj.dplan_dinner[i].food_sub_type,
          quantity: obj.dplan_dinner[i].quantity != undefined ? obj.dplan_dinner[i].quantity : "0.00",
          measure: obj.dplan_dinner[i].measure != undefined ? obj.dplan_dinner[i].measure : "0.00",
          weight: obj.dplan_dinner[i].weight != undefined ? obj.dplan_dinner[i].weight : "0.00",
          cals_weight: calsWight,
          wightMesure: wightMesure,
          energy: obj.dplan_dinner[i].energy != undefined ? obj.dplan_dinner[i].energy : "0.00",
          proteins: obj.dplan_dinner[i].proteins != undefined ? obj.dplan_dinner[i].proteins : "0.00",
          carbohydrates: obj.dplan_dinner[i].carbohydrates != undefined ? obj.dplan_dinner[i].carbohydrates : "0.00",
          fat: obj.dplan_dinner[i].fat != undefined ? obj.dplan_dinner[i].fat : "0.00",
          calcium: obj.dplan_dinner[i].calcium != undefined ? obj.dplan_dinner[i].calcium : "0.00",
          iron: obj.dplan_dinner[i].iron != undefined ? obj.dplan_dinner[i].iron : "0.00",
          vitaminC: obj.dplan_dinner[i].vitaminC != undefined ? obj.dplan_dinner[i].vitaminC : "0.00",
          vitaminA: obj.dplan_dinner[i].vitaminA != undefined ? obj.dplan_dinner[i].vitaminA : "0.00",
          vitaminB12: obj.dplan_dinner[i].vitaminB12 != undefined ? obj.dplan_dinner[i].vitaminB12 : "0.00",
          fiber: obj.dplan_dinner[i].fiber != undefined ? obj.dplan_dinner[i].fiber : "0.00",
          folic: obj.dplan_dinner[i].folic != undefined ? obj.dplan_dinner[i].folic : "0.00",
          h2o: obj.dplan_dinner[i].h2o != undefined ? obj.dplan_dinner[i].h2o : "0.00",
          session: "Dinner",
          image: obj.dplan_dinner[i].image,
        };

        if (obj.dplan_dinner[i].day != undefined) {
          this.mainDishFlag = obj.dplan_dinner[i].main_course != "1" ? false : true;
          this.selctedFood(obj.dplan_dinner[i].day, obj.dplan_dinner[i].date, tempFoodItems);
        } else {
          for (var j = 0; j < days.length; j++) {
            this.mainDishFlag = obj.dplan_dinner[i].main_course != "1" ? false : true;
            this.selctedFood(days[j], this.selctedDate, tempFoodItems);
          }
        }
      }
    }
    //Night
    if (obj.dplan_night != null && obj.dplan_night.length != 0) {
      for (var i = 0; i < obj.dplan_night.length; i++) {
        var dayMorning = obj.dplan_night[i].day != undefined ? obj.dplan_night[i].day : " ";
        var tempWeight = obj.dplan_night[i].weight.split(" ");
        var calsWight = tempWeight[0];
        var wightMesure = tempWeight[1];
        tempFoodItems = {
          food_item_id: obj.dplan_night[i].food_item_id,
          description: obj.dplan_night[i].description,
          food_type_id: obj.dplan_night[i].food_type_id,
          food_sub_type: obj.dplan_night[i].food_sub_type,
          quantity: obj.dplan_night[i].quantity != undefined ? obj.dplan_night[i].quantity : "0.00",
          measure: obj.dplan_night[i].measure != undefined ? obj.dplan_night[i].measure : "0.00",
          weight: obj.dplan_night[i].weight != undefined ? obj.dplan_night[i].weight : "0.00",
          cals_weight: calsWight,
          wightMesure: wightMesure,
          energy: obj.dplan_night[i].energy != undefined ? obj.dplan_night[i].energy : "0.00",
          proteins: obj.dplan_night[i].proteins != undefined ? obj.dplan_night[i].proteins : "0.00",
          carbohydrates: obj.dplan_night[i].carbohydrates != undefined ? obj.dplan_night[i].carbohydrates : "0.00",
          fat: obj.dplan_night[i].fat != undefined ? obj.dplan_night[i].fat : "0.00",
          calcium: obj.dplan_night[i].calcium != undefined ? obj.dplan_night[i].calcium : "0.00",
          iron: obj.dplan_night[i].iron != undefined ? obj.dplan_night[i].iron : "0.00",
          vitaminC: obj.dplan_night[i].vitaminC != undefined ? obj.dplan_night[i].vitaminC : "0.00",
          vitaminA: obj.dplan_night[i].vitaminA != undefined ? obj.dplan_night[i].vitaminA : "0.00",
          vitaminB12: obj.dplan_night[i].vitaminB12 != undefined ? obj.dplan_night[i].vitaminB12 : "0.00",
          fiber: obj.dplan_night[i].fiber != undefined ? obj.dplan_night[i].fiber : "0.00",
          folic: obj.dplan_night[i].folic != undefined ? obj.dplan_night[i].folic : "0.00",
          h2o: obj.dplan_night[i].h2o != undefined ? obj.dplan_night[i].h2o : "0.00",
          session: "Night",
          image: obj.dplan_night[i].image,
        };

        if (obj.dplan_night[i].day != undefined) {
          this.mainDishFlag = obj.dplan_night[i].main_course != "1" ? false : true;
          this.selctedFood(obj.dplan_night[i].day, obj.dplan_night[i].date, tempFoodItems);

        } else {
          for (var j = 0; j < days.length; j++) {
            this.mainDishFlag = obj.dplan_night[i].main_course != "1" ? false : true;
            this.selctedFood(days[j], this.selctedDate, tempFoodItems);
          }
        }
      }
    }
    setTimeout(() => {
      $("#Sunday-tab").addClass("active");
      $("#Sunday").addClass("active");
      setTimeout(() => {

      }, 100);
    }, 300);
    console.log("console display array" + JSON.stringify(this.displayArray));
  }

  dietplanhistory(e) {
    const dialogRef1 = this.dialog.open(DietplanhistorypopupComponent, {
      width: '75%',
      height: '500px',
      data: e
    });
    dialogRef1.afterClosed().subscribe(result => {
    });
  }

  dayClick(days, date, flag) {
    $(".nav-link").removeClass("active");
    $("#" + date + "-tab").addClass("active");
    $(".tab-pane").removeClass("active");
    $("#" + date).addClass("active");
    this.previous_day = this.selctedDay;
    this.selctedDay = days;
    this.selctedDate = date;
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].date == date) {
        this.showArray = [];
        this.showArray.push(this.displayArray[i]);
        break;
      }
    }
    this.selctedDayFun(date);
  }

  selctedDayFun(date) {
    this.tot_cal = "0.00";
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].date == date) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
            this.tot_cal = (parseFloat(this.tot_cal) + parseFloat(this.displayArray[i].session[j].foodItems[k].energy)).toFixed(2);
          }
          var day_totcal;
          if (this.cal_txt != "0") {
            day_totcal = (parseFloat(this.tot_cal) / parseFloat(this.cal_txt)) * 100;

          } else {
            day_totcal = 0;
          }

          this.set_progress(day_totcal)
          if (day_totcal > 0 && day_totcal <= 30) {
            $("#totalCal").css("color", "#fa1c14");

          } else if (day_totcal > 30 && day_totcal <= 60) {
            $("#totalCal").css("color", "#f5a142");

          } else if (day_totcal > 60 && day_totcal <= 80) {
            $("#totalCal").css("color", "#1be088");

          } else {
            $("#totalCal").css("color", "#fa1c14");
          }
        }
        break;
      }
    }
  }

  resetBackGround(day) {
    this.tot_cal = "0.00";
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        $('#progress').empty();
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
            $('#' + day + "_" + this.displayArray[i].session[j].type).css('background-image', 'url()');
          }
        }
        break;
      }
    }
  }

  foottype(itemname, session) {
    if (itemname != undefined && itemname != "" && itemname.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/gdbn',
        {
          item_name: itemname
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("GDBNNNNN value-----" + JSON.stringify(obj));
            var food_items = obj.food_items;
            if (food_items.length != 0) {
              var tempFoodItems = [];
              this.displayArray;
              this.morningFoodItem = [];
              this.breadfastFoodItem = [];
              this.midDayFoodItem = [];
              this.lunchFoodItem = [];
              this.eveningFoodItem = [];
              this.dinnerFoodItem = [];
              this.nightFoodItem = [];
              for (var i = 0; i < food_items.length; i++) {
                if (food_items[i].weight != undefined) {
                  var tempWeight = food_items[i].weight.split(" ");
                  var calsWight = tempWeight[0];
                  var wightMesure = tempWeight[1] != undefined ? tempWeight[1] : "--";
                }
                // var tempWeight = food_items[i].weight.split(" ");
                // var calsWight = tempWeight[0];
                // var wightMesure = tempWeight[1];
                tempFoodItems.push({
                  food_item_id: food_items[i].food_item_id,
                  description: food_items[i].description,
                  food_type_id: food_items[i].food_type_id,
                  food_sub_type: food_items[i].food_sub_type,
                  quantity: food_items[i].quantity,
                  displayContent: food_items[i].description + " (" + food_items[i].quantity + " " + food_items[i].measure + " - " + food_items[i].weight + " )",
                  measure: food_items[i].measure,
                  weight: food_items[i].weight,
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: food_items[i].energy,
                  proteins: food_items[i].proteins != undefined ? food_items[i].proteins : "0.00",
                  carbohydrates: food_items[i].carbohydrates != undefined ? food_items[i].carbohydrates : "0.00",
                  fat: food_items[i].fat != undefined ? food_items[i].fat : "0.00",
                  calcium: food_items[i].calcium != undefined ? food_items[i].calcium : "0.00",
                  iron: food_items[i].iron != undefined ? food_items[i].iron : "0.00",
                  vitaminC: food_items[i].vitaminC != undefined ? food_items[i].vitaminC : "0.00",
                  vitaminA: food_items[i].vitaminA != undefined ? food_items[i].vitaminA : "0.00",
                  vitaminB12: food_items[i].vitaminB12 != undefined ? food_items[i].vitaminB12 : "0.00",
                  fiber: food_items[i].fiber != undefined ? food_items[i].fiber : "0.00",
                  folic: food_items[i].folic != undefined ? food_items[i].folic : "0.00",
                  h2o: food_items[i].h2o != undefined ? food_items[i].h2o : "0.00",
                  session: session,
                  image: food_items[i].image,
                });
              }
              switch (session) {
                case 'Morning':
                  this.morningFoodItem = tempFoodItems;
                  break;
                case 'Breakfast':
                  this.breadfastFoodItem = tempFoodItems;
                  break;
                case 'Mid-day':
                  this.midDayFoodItem = tempFoodItems;
                  break;
                case 'Lunch':
                  this.lunchFoodItem = tempFoodItems;
                  break;
                case 'Evening':
                  this.eveningFoodItem = tempFoodItems;
                  break;
                case 'Dinner':
                  this.dinnerFoodItem = tempFoodItems;
                  break;
                case 'Night':
                  this.nightFoodItem = tempFoodItems;
                  break;
              }
            }
          },
          error => { }
        )
    }
  }

  selctedFood(day, date, foodArray) {
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        var session = this.displayArray[i].session;
        if (session.length != 0) {
          for (var j = 0; j < session.length; j++) {
            if (session[j].type == foodArray.session) {
              var mainDish = this.mainDishFlag != true ? false : true;
              if (this.displayArray[i].session[j].mainDish == false) {
                this.displayArray[i].session[j].mainDish = this.mainDishFlag != true ? false : true;
              }
              var index = this.displayArray[i].session[j].foodItems.findIndex(x => x.food_item_id == foodArray.food_item_id);
              if (index == -1) {
                var disArray = {
                  food_item_id: foodArray.food_item_id,
                  description: foodArray.description,
                  food_type_id: foodArray.food_type_id,
                  food_sub_type: foodArray.food_sub_type,
                  quantity: foodArray.quantity,
                  temp_cals_weight: foodArray.cals_weight,
                  wightMesure: foodArray.wightMesure,
                  measure: foodArray.measure,
                  weight: foodArray.weight,
                  cals_weight: foodArray.cals_weight,
                  energy: foodArray.energy,
                  proteins: foodArray.proteins,
                  carbohydrates: foodArray.carbohydrates,
                  fat: foodArray.fat,
                  calcium: foodArray.calcium,
                  iron: foodArray.iron,
                  vitaminC: foodArray.vitaminC,
                  vitaminA: foodArray.vitaminA,
                  vitaminB12: foodArray.vitaminB12,
                  displayContent: foodArray.description + " (" + foodArray.quantity + " " + foodArray.measure + " - " + foodArray.weight + " )",
                  fiber: foodArray.fiber,
                  folic: foodArray.folic,
                  h2o: foodArray.h2o,
                  checked: true,
                  session: foodArray.session,
                  day: day,
                  date: date,
                  mainDish: mainDish,
                  foodImges: ipaddress.Ip_with_img_address + "/" + foodArray.image,
                };
                this.displayArray[i].session[j].foodItems.push(disArray);
                this.morningFoods = "";
                this.BreakfastFoods = "";
                this.MidDayFoods = "";
                this.LunchFoods = "";
                this.EveningFoods = "";
                this.DinnerFoods = "";
                this.NightFoods = "";
                if (this.selctedDay == day) {
                  this.tot_cal = (parseFloat(this.tot_cal) + parseFloat(foodArray.energy)).toFixed(2);
                }

                this.calculatingFoodData(day, foodArray.session)
                this.getSendData(foodArray.session, "add", disArray);
              } else {
                this.toastr.error("food item already added")
              }
              break;
            }
          }
        }
        break;
      }
    }
    this.mainDishFlag = false;
  }

  isSelected(selectedArray, action) {
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == selectedArray.day) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          if (this.displayArray[i].session[j].type == selectedArray.session) {
            for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
              if (this.displayArray[i].session[j].foodItems[k].food_item_id == selectedArray.food_item_id) {
                if (action == "add") {
                  if (this.mainDishFlag == true) {
                    this.displayArray[i].session[j].foodItems[k].mainDish = true;
                    this.displayArray[i].session[j].mainDish = true;
                  }
                  this.tot_cal = (parseFloat(this.tot_cal) + parseFloat(this.displayArray[i].session[j].foodItems[k].energy)).toFixed(2);
                  this.displayArray[i].session[j].foodItems[k].checked = true;
                  this.getSendData(selectedArray.session, "add", this.displayArray[i].session[j].foodItems[k]);
                } else {
                  if (this.displayArray[i].session[j].foodItems[k].mainDish == true) {
                    this.mainDishFlag = false;
                    this.displayArray[i].session[j].mainDish = false;
                    this.displayArray[i].session[j].foodItems[k].mainDish = false
                  }
                  this.tot_cal = (parseFloat(this.tot_cal) - parseFloat(this.displayArray[i].session[j].foodItems[k].energy)).toFixed(2);
                  this.displayArray[i].session[j].foodItems[k].checked = false;
                  this.getSendData(selectedArray.session, "remove", this.displayArray[i].session[j].foodItems[k]);
                  setTimeout(() => {
                    this.displayArray[i].session[j].foodItems.splice(k, 1);
                  }, 200);

                }
                this.calculatingFoodData(selectedArray.day, selectedArray.session)
                break;
              }
            }
            break;
          }
        }
        break;
      }
    }
    this.mainDishFlag = false;
  }

  mainDish(selectedArray, day) {
    this.mainDishFlag = true;
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          if (this.displayArray[i].session[j].type == selectedArray.type) {
            this.displayArray[i].session[j].mainDish = true;
            break;
          }
        }
        break;
      }
    }
  }

  calculatingFoodData(day, session) {
    this.foodImages = [];
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          if (this.displayArray[i].session[j].type == session) {
            var fooditems = this.displayArray[i].session[j].foodItems;
            var energy = "0.00";
            var Protien = "0.00";
            var Ca = "0.00";
            var Fe = "0.00";
            var fiber = "0.00";
            var vaitamina = "0.00";
            var Fat = "0.00";
            var h2o = "0.00";
            var Folic = "0.00";
            var Carbohydrates = "0.00";
            var Vitaminc = "0.00";
            var vitaminb12 = "0.00";
            var htmlContent = "";
            var count = 1;
            $('#' + day + "_" + session).css('background-image', 'url()');
            var getCheckItems = 0;
            for (var k = 0; k < fooditems.length; k++) {
              if (fooditems[k].checked == true) {
                if (getCheckItems == 0) {
                  energy = fooditems[k].energy != undefined ? (this.findString(fooditems[k].energy) != true ? parseFloat(fooditems[k].energy).toFixed(2) : fooditems[k].energy) : "0.00";
                  Protien = fooditems[k].proteins != undefined ? (this.findString(fooditems[k].proteins) != true ? parseFloat(fooditems[k].proteins).toFixed(2) : fooditems[k].proteins) : "0.00";
                  Ca = fooditems[k].calcium != undefined ? (this.findString(fooditems[k].calcium) != true ? parseFloat(fooditems[k].calcium).toFixed(2) : fooditems[k].calcium) : "0.00";
                  Fe = fooditems[k].Fe != undefined ? (this.findString(fooditems[k].Fe) != true ? parseFloat(fooditems[k].Fe).toFixed(2) : fooditems[k].Fe) : "0.00";
                  fiber = fooditems[k].iron != undefined ? (this.findString(fooditems[k].iron) != true ? parseFloat(fooditems[k].iron).toFixed(2) : fooditems[k].iron) : "0.00";
                  vaitamina = fooditems[k].vaitamina != undefined ? (this.findString(fooditems[k].vaitamina) != true ? parseFloat(fooditems[k].vaitamina).toFixed(2) : fooditems[k].vaitamina) : "0.00";
                  Fat = fooditems[k].fat != undefined ? (this.findString(fooditems[k].fat) != true ? parseFloat(fooditems[k].fat).toFixed(2) : fooditems[k].fat) : "0.00";
                  h2o = fooditems[k].h2o != undefined ? (this.findString(fooditems[k].h2o) != true ? parseFloat(fooditems[k].h2o).toFixed(2) : fooditems[k].h2o) : "0.00";
                  Folic = fooditems[k].folic != undefined ? (this.findString(fooditems[k].folic) != true ? parseFloat(fooditems[k].folic).toFixed(2) : fooditems[k].folic) : "0.00";
                  Carbohydrates = fooditems[k].carbohydrates != undefined ? (this.findString(fooditems[k].carbohydrates) != true ? parseFloat(fooditems[k].carbohydrates).toFixed(2) : fooditems[k].carbohydrates) : "0.00";
                  Vitaminc = fooditems[k].vitaminC != undefined ? (this.findString(fooditems[k].vitaminC) != true ? parseFloat(fooditems[k].vitaminC).toFixed(2) : fooditems[k].vitaminC) : "0.00";
                  vitaminb12 = fooditems[k].vitaminB12 != undefined ? (this.findString(fooditems[k].vitaminB12) != true ? parseFloat(fooditems[k].vitaminB12).toFixed(2) : fooditems[k].vitaminB12) : "0.00";
                } else {
                  energy = (parseFloat(energy) + parseFloat(fooditems[k].energy)).toFixed(2);
                  Protien = (parseFloat(this.findString(Protien) != true ? Protien : "0.00") + parseFloat(this.findString(fooditems[k].proteins) != true ? fooditems[k].proteins : "0.00")).toFixed(2);
                  Ca = (parseFloat(this.findString(Ca) != true ? Ca : "0.00") + parseFloat(this.findString(fooditems[k].calcium) != true ? fooditems[k].calcium : "0.00")).toFixed(2);
                  Fe = (parseFloat(this.findString(Fe) != true ? Fe : "0.00") + parseFloat(this.findString(fooditems[k].iron) != true ? fooditems[k].iron : "0.00")).toFixed(2);
                  fiber = (parseFloat(this.findString(fiber) != true ? fiber : "0.00") + parseFloat(this.findString(fooditems[k].fiber) != true ? fooditems[k].fiber : "0.00")).toFixed(2);
                  vaitamina = (parseFloat(this.findString(vaitamina) != true ? vaitamina : "0.00") + parseFloat(this.findString(fooditems[k].vitaminA) != true ? fooditems[k].vitaminA : "0.00")).toFixed(2);
                  Fat = (parseFloat(this.findString(Fat) != true ? Fat : "0.00") + parseFloat(this.findString(fooditems[k].fat) != true ? fooditems[k].fat : "0.00")).toFixed(2);
                  h2o = (parseFloat(this.findString(h2o) != true ? h2o : "0.00") + parseFloat(this.findString(fooditems[k].h2o) != true ? fooditems[k].h2o : "0.00")).toFixed(2);
                  Folic = (parseFloat(this.findString(Folic) != true ? Folic : "0.00") + parseFloat(this.findString(fooditems[k].folic) != true ? fooditems[k].folic : "0.00")).toFixed(2);
                  Carbohydrates = (parseFloat(this.findString(Carbohydrates) != true ? Carbohydrates : "0.00") + parseFloat(this.findString(fooditems[k].carbohydrates) != true ? fooditems[k].carbohydrates : "0.00")).toFixed(2);
                  Vitaminc = (parseFloat(this.findString(Vitaminc) != true ? Vitaminc : "0.00") + parseFloat(this.findString(fooditems[k].vitaminC) != true ? fooditems[k].vitaminC : "0.00")).toFixed(2);
                  vitaminb12 = (parseFloat(this.findString(vitaminb12) != true ? vitaminb12 : "0.00") + parseFloat(this.findString(fooditems[k].vitaminB12) != true ? fooditems[k].vitaminB12 : "0.00")).toFixed(2);
                }
                getCheckItems++;
                var mainDish = fooditems[k].mainDish != true ? false : true;
                if (mainDish == true) {
                  $('#' + this.selctedDate + "_" + session).css('background-image', 'url(' + fooditems[k].foodImges + ')');
                } else {
                  if (count <= 12)
                    htmlContent += '<img src="' + fooditems[k].foodImges + '" class="pos p' + count + '">';
                  count = count + 1;
                }
              }
            }
            this.displayArray[i].session[j].energy = energy;
            this.displayArray[i].session[j].Protien = Protien;
            this.displayArray[i].session[j].Ca = Ca;
            this.displayArray[i].session[j].Fe = Fe;
            this.displayArray[i].session[j].fiber = fiber;
            this.displayArray[i].session[j].vaitamina = vaitamina;
            this.displayArray[i].session[j].Fat = Fat;
            this.displayArray[i].session[j].h2o = h2o;
            this.displayArray[i].session[j].Folic = Folic;
            this.displayArray[i].session[j].Carbohydrates = Carbohydrates;
            this.displayArray[i].session[j].Vitaminc = Vitaminc;
            this.displayArray[i].session[j].vitaminb12 = vitaminb12;
            this.displayArray[i].session[j].plateContent = htmlContent;

            var day_totcal;
            if (this.cal_txt != "0") {
              day_totcal = (parseFloat(this.tot_cal) / parseFloat(this.cal_txt)) * 100;
            } else {
              day_totcal = 0;
            }

            this.setProgress(day_totcal)
            if (day_totcal > 0 && day_totcal <= 30) {
              // morning_color=document.getElementsByClassName("circlecal").style.color
              $("#totalCal").css("color", "#fa1c14");
              //color="#fa1c14";
            }
            else if (day_totcal > 30 && day_totcal <= 60) {
              $("#totalCal").css("color", "#f5a142");
              //color="#1be088";
            }
            else if (day_totcal > 60 && day_totcal <= 80) {
              $("#totalCal").css("color", "#1be088");
              // color="#f5a142";
            }
            else {
              $("#totalCal").css("color", "#fa1c14");
              // color="#fa1c14";
            }
            break;
          }
        }
        break;
      }
    }
  }

  set_progress(_num) {
    $('#progress').empty();
    var el_1_width = 0;
    var el_2_width = 0;
    var el_3_width = 0;
    var el_4_width = 0;
    if (_num > 30) { el_1_width = 30; } else { el_1_width = _num; }
    if (_num > 60) { el_2_width = 30; } else { el_2_width = _num - el_1_width; }
    if (_num > 80) { el_3_width = 30; } else { el_3_width = _num - el_1_width - el_2_width; }
    if (_num > 100) { el_4_width = 8; }
    var new_font_clor = '';
    if (_num < 55) { new_font_clor = 'color:black'; }

    $('#progress').append('<div class="progress-el" style="border-radius: 7px;background-color:#fa1c14;width:' + el_1_width + '%;">&nbsp;</div>');
    $('#progress').append('<div class="progress-el" style="background-color:#f5a142;width:' + el_2_width + '%;">&nbsp;</div>');
    $('#progress').append('<div class="progress-el" style="background-color:#19a923;width:' + el_3_width + '%;">&nbsp;</div>');
    $('#progress').append('<div class="progress-el" style="border-radius: 7px;background-color:#fa1c14;width:' + el_4_width + '%;">&nbsp;</div>');
  }

  getSendData(session, action, array) {
    Helper_Class.setDiet(null);
    var sga_send = null;
    var sub_rel = null;
    var tcal_to_txt = null;
    tcal_to_txt = this.tot_cal != "" && this.tot_cal != null && this.tot_cal != undefined ? this.tot_cal : "0.00";
    var get_ret = this.patient_list.spl == "Gynecology" ? Helper_Class.getgynaRet() : Helper_Class.getRet();
    var get_life_sty_id = get_ret != undefined && get_ret.life_style_id != null ? get_ret.life_style_id : undefined;
    var mainDish = array.mainDish != true ? "0" : "1";
    var sendArray = {
      food_item_id: array.food_item_id,
      quantity: array.quantity,
      measure: array.measure,
      weight: array.weight,
      cals_weight: array.cals_weight,
      food_sub_type: array.food_sub_type,
      description: array.description,
      energy: array.energy,
      proteins: array.proteins,
      carbohydrates: array.carbohydrates,
      fat: array.fat,
      calcium: array.calcium,
      vitaminC: array.vitaminC,
      vitaminA: array.vitaminA,
      vitaminB12: array.vitaminB12,
      h2o: array.h2o,
      main_course: mainDish,
      is_doctor: "1",
      day: array.day
    }
    switch (session) {
      case 'Morning':
        if (action == "add") {

          this.diet_mrng_arry.push(sendArray);
        } else {
          for (var i = 0; i < this.diet_mrng_arry.length; i++) {
            if (this.diet_mrng_arry[i].food_item_id == array.food_item_id && this.diet_mrng_arry[i].day == array.day) {
              this.diet_mrng_arry.splice(i, 1);
              if (action == "update") {
                this.diet_mrng_arry.push(sendArray);
              }
            }
          }
          if (this.diet_mrng_arry.length == 0) {
            this.diet_mrng_arry.push({
              flag: "No"
            });
          }
        }
        break;
      case 'Breakfast':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.break_fast_arrydata.push(sendArray);
        } else {
          for (var i = 0; i < this.break_fast_arrydata.length; i++) {
            if (this.break_fast_arrydata[i].food_item_id == array.food_item_id && this.break_fast_arrydata[i].day == array.day) {
              this.break_fast_arrydata.splice(i, 1);
              if (action == "update") {
                this.break_fast_arrydata.push(sendArray);
              }
            }
          }
          if (this.break_fast_arrydata.length == 0) {
            this.break_fast_arrydata.push({
              flag: "No"
            });
          }
        }
        break;
      case 'Mid-day':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.mid_day_arrydata.push(sendArray);
        } else {
          for (var i = 0; i < this.mid_day_arrydata.length; i++) {
            if (this.mid_day_arrydata[i].food_item_id == array.food_item_id && this.mid_day_arrydata[i].day == array.day) {
              this.mid_day_arrydata.splice(i, 1);
              if (action == "update") {
                this.mid_day_arrydata.push(sendArray);
              }
            }
          }
          if (this.mid_day_arrydata.length == 0) {
            this.mid_day_arrydata.push({
              flag: "No"
            });
          }
        }
        break;
      case 'Lunch':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.lunch_arrydata.push(sendArray);
        } else {
          for (var i = 0; i < this.lunch_arrydata.length; i++) {
            if (this.lunch_arrydata[i].food_item_id == array.food_item_id && this.lunch_arrydata[i].day == array.day) {
              this.lunch_arrydata.splice(i, 1);
              if (action == "update") {
                this.lunch_arrydata.push(sendArray);
              }
            }
          }
          if (this.lunch_arrydata.length == 0) {
            this.lunch_arrydata.push({
              flag: "No"
            });
          }
        }
        break;
      case 'Evening':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.evening_arrydata.push(sendArray);
        } else {
          for (var i = 0; i < this.evening_arrydata.length; i++) {
            if (this.evening_arrydata[i].food_item_id == array.food_item_id && this.evening_arrydata[i].day == array.day) {
              this.evening_arrydata.splice(i, 1);
              if (action == "update") {
                this.evening_arrydata.push(sendArray);
              }
            }
          }
          if (this.evening_arrydata.length == 0) {
            this.evening_arrydata.push({
              flag: "No"
            });
          }
        }
        break;
      case 'Dinner':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.dinner_arrydata.push(sendArray);
        } else {
          for (var i = 0; i < this.dinner_arrydata.length; i++) {
            if (this.dinner_arrydata[i].food_item_id == array.food_item_id && this.dinner_arrydata[i].day == array.day) {
              this.dinner_arrydata.splice(i, 1);
              if (action == "update") {
                this.dinner_arrydata.push(sendArray);
              }
            }
          }
          if (this.dinner_arrydata.length == 0) {
            this.dinner_arrydata.push({
              flag: "No"
            });
          }
        }
        break;
      case 'Night':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.night_arrydata.push(sendArray);
        } else {
          for (var i = 0; i < this.night_arrydata.length; i++) {
            if (this.night_arrydata[i].food_item_id == array.food_item_id && this.night_arrydata[i].day == array.day) {
              this.night_arrydata.splice(i, 1);
              if (action == "update") {
                this.night_arrydata.push(sendArray);
              }
            }
          }
          if (this.night_arrydata.length == 0) {
            this.night_arrydata.push({
              flag: "No"
            });
          }
        }
        break;
    }
    var senddess = this.templateDiet == true ? this.deaciseName : undefined;
    if (this.patient_list.sub_id != null && this.patient_list.sub_id != undefined && this.patient_list.sub_id != "") {

      sga_send = {
        is_doctor: "1",
        overall_rating: this.Sga_overalltxt,
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        sub_rel_id: this.patient_list.sub_id,
        country: "IN",
        cal_required: this.cal_txt,
        cal_intake: tcal_to_txt,
        life_style_id: get_life_sty_id,
        dplan_morning: this.diet_mrng_arry,
        dplan_breakfast: this.break_fast_arrydata,
        dplan_midday: this.mid_day_arrydata,
        dplan_lunch: this.lunch_arrydata,
        dplan_evening: this.evening_arrydata,
        dplan_dinner: this.dinner_arrydata,
        dplan_night: this.night_arrydata,
        diet_plan: this.diet_plan_all,
        disease_desc: senddess,
      }
    } else {
      sga_send = {
        is_doctor: "1",
        overall_rating: this.Sga_overalltxt,
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        country: "IN",
        cal_required: this.cal_txt,
        cal_intake: tcal_to_txt,
        life_style_id: get_life_sty_id,
        dplan_morning: this.diet_mrng_arry,
        dplan_breakfast: this.break_fast_arrydata,
        dplan_midday: this.mid_day_arrydata,
        dplan_lunch: this.lunch_arrydata,
        dplan_evening: this.evening_arrydata,
        dplan_dinner: this.dinner_arrydata,
        dplan_night: this.night_arrydata,
        diet_plan: this.diet_plan_all,
        disease_desc: senddess,
      }
    }
    console.log("sga_send" + JSON.stringify(sga_send));
    Helper_nephro_casesheet.diet_plan_chk_falg = "1";
    Helper_Class.setDietplan(sga_send);
    Helper_Class.setDiet(sga_send);
  }

  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
      this.dietType = "diet_plan";
    } else {
      this.ynopt1 = false;
      this.dietType = "template_plan";
    }
    this.changeAppointmentType();
  }

  openDietPlan() {
    const dialogRef = this.dialog.open(DietpopupwindowComponent, {
      width: '70%',
      height: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.Sga_overalltxt = result;
    });
  }

  changequantity(weight, day, selectedArray, sessiontype) {
    var checked;
    var dicrption = "";
    console.log("chhhhqqqqqqqqqq--------" + JSON.stringify(weight))
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          if (this.displayArray[i].session[j].type == sessiontype) {
            for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
              if (this.displayArray[i].session[j].foodItems[k].food_item_id == selectedArray.food_item_id) {
                var energy, proteins_change, carbohydrates_change, fat_change, calcium_change, vitaminC_change, vitaminA_change, vitaminB12_change, fiber_change, folic_change, h2o_change;
                this.displayArray[i].session[j].foodItems[k].food_item_id = selectedArray.food_item_id;
                energy = ((this.displayArray[i].session[j].foodItems[k].energy / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                proteins_change = ((this.displayArray[i].session[j].foodItems[k].proteins / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                carbohydrates_change = (this.displayArray[i].session[j].foodItems[k].carbohydrates / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight);
                fat_change = (this.displayArray[i].session[j].foodItems[k].fat / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight);
                calcium_change = ((this.displayArray[i].session[j].foodItems[k].calcium / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                vitaminC_change = ((this.displayArray[i].session[j].foodItems[k].vitaminC / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                vitaminA_change = ((this.displayArray[i].session[j].foodItems[k].vitaminA / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                vitaminB12_change = ((this.displayArray[i].session[j].foodItems[k].vitaminB12 / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                fiber_change = ((this.displayArray[i].session[j].foodItems[k].fiber / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                folic_change = ((this.displayArray[i].session[j].foodItems[k].folic / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                h2o_change = ((this.displayArray[i].session[j].foodItems[k].h2o / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (weight));
                this.displayArray[i].session[j].foodItems[k].weight = parseInt(weight).toString() + " " + this.displayArray[i].session[j].foodItems[k].wightMesure;
                this.displayArray[i].session[j].foodItems[k].energy = parseInt(energy).toString();
                this.displayArray[i].session[j].foodItems[k].temp_cals_weight = parseInt(weight).toString();;
                this.displayArray[i].session[j].foodItems[k].cals_weight = parseInt(weight).toString();;
                this.displayArray[i].session[j].foodItems[k].proteins = parseInt(proteins_change).toString();;
                this.displayArray[i].session[j].foodItems[k].carbohydrates = parseInt(carbohydrates_change).toString();;
                this.displayArray[i].session[j].foodItems[k].fat = parseInt(fat_change).toString();;
                this.displayArray[i].session[j].foodItems[k].calcium = parseInt(calcium_change).toString();;
                this.displayArray[i].session[j].foodItems[k].vitaminC = parseInt(vitaminC_change).toString();;
                this.displayArray[i].session[j].foodItems[k].vitaminA = parseInt(vitaminA_change).toString();;
                this.displayArray[i].session[j].foodItems[k].vitaminB12 = parseInt(vitaminB12_change).toString();;
                this.displayArray[i].session[j].foodItems[k].fiber = parseInt(fiber_change).toString();;
                this.displayArray[i].session[j].foodItems[k].folic = parseInt(folic_change).toString();;
                this.displayArray[i].session[j].foodItems[k].h2o = parseInt(h2o_change).toString();;
                this.getSendData(this.displayArray[i].session[j].type, "update", this.displayArray[i].session[j].foodItems[k]);
                this.calculatingFoodData(day, this.displayArray[i].session[j].type);
                console.log("chhhhqqqqqqqqqq--------" + JSON.stringify(this.displayArray))
                this.selectedDay(day)
              }
            }
            break;
          }
        }
      }
    }
  }

  assingData(action) {
    if (action == "yes") {
      for (var i = 0; i < this.displayArray.length; i++) {
        if (this.displayArray[i].day == this.previous_day) {
          for (var j = 0; j < this.displayArray[i].session.length; j++) {

            switch (this.displayArray[i].session[j].type) {
              case 'Morning':
                this.tempMorningArray = this.displayArray[i].session[j].foodItems;
                break;
              case 'Breakfast':
                this.tempBreakfastArray = this.displayArray[i].session[j].foodItems;
                break;
              case 'Mid-day':
                this.tempMid_dayArray = this.displayArray[i].session[j].foodItems;
                break;
              case 'Lunch':
                this.tempLunchArray = this.displayArray[i].session[j].foodItems;
                break;
              case 'Evening':
                this.tempEveningArray = this.displayArray[i].session[j].foodItems;
                break;
              case 'Dinner':
                this.tempDinnerArray = this.displayArray[i].session[j].foodItems;
                break;
              case 'Night':
                this.tempNightArray = this.displayArray[i].session[j].foodItems;
                break;
            }
          }
          break;
        }
      }
      for (var i = 0; i < this.displayArray.length; i++) {
        if (this.displayArray[i].day != this.previous_day) {
          for (var j = 0; j < this.displayArray[i].session.length; j++) {
            if (this.displayArray[i].session[j].foodItems.length == 0) {
              switch (this.displayArray[i].session[j].type) {
                case 'Morning':
                  this.displayArray[i].session[j].foodItems = this.tempMorningArray;

                  break;
                case 'Breakfast':
                  this.displayArray[i].session[j].foodItems = this.tempBreakfastArray;
                  break;
                case 'Mid-day':
                  this.displayArray[i].session[j].foodItems = this.tempMid_dayArray;
                  break;
                case 'Lunch':
                  this.displayArray[i].session[j].foodItems = this.tempLunchArray;
                  break;
                case 'Evening':
                  this.displayArray[i].session[j].foodItems = this.tempEveningArray;
                  break;
                case 'Dinner':
                  this.displayArray[i].session[j].foodItems = this.tempDinnerArray;
                  break;
                case 'Night':
                  this.displayArray[i].session[j].foodItems = this.tempNightArray;

                  break;
              }
              for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
                this.displayArray[i].session[j].foodItems[k].day = this.displayArray[i].day;
                this.getSendData(this.displayArray[i].session[j].type, "add", this.displayArray[i].session[j].foodItems[k]);
                this.calculatingFoodData(this.displayArray[i].day, this.displayArray[i].session[j].type);
              }
            }
          }
        }
      }

    }
    this.dayChangeFlag = false;
    $("#staticBackdrop").modal("hide");
  }

  getPdf() {
    $("#pdfModel").modal("show");
  }

  findString(a) {
    if (a.indexOf('<') > -1) {
      return true;
    } else {
      return false;
    }
  }

  selectedDay(day) {
    this.tot_cal = "0.00";
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
            this.tot_cal = (parseFloat(this.tot_cal) + parseFloat(this.displayArray[i].session[j].foodItems[k].energy)).toFixed(2);
          }
          var day_totcal;
          if (this.cal_txt != "0") {
            day_totcal = (parseFloat(this.tot_cal) / parseFloat(this.cal_txt)) * 100;
          } else {
            day_totcal = 0;
          }

          this.setProgress(day_totcal)
          if (day_totcal > 0 && day_totcal <= 30) {
            $("#totalCal").css("color", "#fa1c14");

          } else if (day_totcal > 30 && day_totcal <= 60) {
            $("#totalCal").css("color", "#f5a142");

          } else if (day_totcal > 60 && day_totcal <= 80) {
            $("#totalCal").css("color", "#1be088");

          } else {
            $("#totalCal").css("color", "#fa1c14");
          }
        }
        break;
      }
    }
  }

  setProgress(_num) {
    $('#progress').empty();
    var el_1_width = 0;
    var el_2_width = 0;
    var el_3_width = 0;
    var el_4_width = 0;
    if (_num > 30) { el_1_width = 30; } else { el_1_width = _num; }
    if (_num > 60) { el_2_width = 30; } else { el_2_width = _num - el_1_width; }
    if (_num > 80) { el_3_width = 30; } else { el_3_width = _num - el_1_width - el_2_width; }
    if (_num > 100) { el_4_width = 8; }
    var new_font_clor = '';
    if (_num < 55) { new_font_clor = 'color:black'; }
    $('#progress').append('<div class="progress-el" style="border-radius: 7px;background-color:#fa1c14;width:' + el_1_width + '%;">&nbsp;</div>');
    $('#progress').append('<div class="progress-el" style="background-color:#f5a142;width:' + el_2_width + '%;">&nbsp;</div>');
    $('#progress').append('<div class="progress-el" style="background-color:#19a923;width:' + el_3_width + '%;">&nbsp;</div>');
    $('#progress').append('<div class="progress-el" style="border-radius: 7px;background-color:#fa1c14;width:' + el_4_width + '%;">&nbsp;</div>');
  }
}
