import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../../app/helper_class';

@Component({
  selector: 'app-diag-test-add',
  templateUrl: './diag-test-add.component.html',
  styleUrls: ['./diag-test-add.component.css']
})
export class DiagTestAddComponent implements OnInit {

  public diagnosisapp_id;
  public test_category_data=[];
  public dis_category_data=[];
  public Subtype_data=[];
  public diag_subtest_id;
  public Diag_Subtest_name;
  public diagSubUOM;
  public male_min;
  public male_max;
  public female_min;
  public female_max;
  public child_min;
  public child_max;
  public Diag_child_data;
  public born_min;
  public born_max;
  public Diag_newborn_data;
  public Diag_subtest_list_data=[];
  public Send_sublist_data=[];
  public Diag_test_id;
  public Diag_TestName;
  public diag_test_categ;
  public diag_dis_categ;
  public dis_test_categ;
  public Diag_test_subtype;
  public Diag_Uof;
  public Diag_test_cost;
  public test_price;
  public diag_add_test_id;
  public diag_cat_id;
  public diag_dis_id;
  public diag_subcat_id;
  public save_btn:boolean;
  public update_btn:boolean;
  public header_footer_flag:boolean;
  public loader:boolean;
  public unithidden:boolean;
  public diagsubhidden:boolean = true;
  public gettestdata;
  public Select = "Select";
  public addTest_flag:boolean = true;
  public subtest_flag:boolean = true;
  public test_method;
  public test_method_flag:boolean;
  public addSubtest_checked:boolean = false;
  public main_test_uom_flag:boolean = true;
  public ref_flag:boolean=false;
  public male_ref;
  public female_ref;
  public child_ref;
  public newborn_ref;

  //mdbTable
  dtOptions: DataTables.Settings = {};
  public range;
  modules = {
    toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // [{ 'header': 1 }, { 'header': 2 }], // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
    [{ 'direction': 'rtl' }], // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'], // remove formatting button
    // ['link', 'image'], // link and image, video
    // ['table']
    ]
    };
    editorStyle = {
      height : '300px;',
    };

  constructor(public diagnosisservice:MenuViewService,public toastr:ToastrService,public gservice:CommonDataService,public http:Http,public dialog:MatDialog) {
    this.unithidden = false;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0,1,3,4,5,6,7] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if(Helper_Class.getInfo().hospitals[0].test_range =="1"){
      this. ref_flag=true;
      
    } else {
      this. ref_flag= false;
    }

    this.gettestdata=Diagnosis_Helper.getdiagnosistest();
      this.Diag_test_id =this.gettestdata.Diag_test_id;
      this.diagnosisapp_id=this.gettestdata.diagnosis_id
    if (this.Diag_test_id != undefined) {
      this.save_btn = true; // Save & Update btn
      this.update_btn=false;
      this.getTestDetails(this.Diag_test_id)
    } else {
      this.save_btn = false; // Save & Update btn
      this.update_btn=true;
      this.getDiseaseCategory('0');
      this.getTestCategory('0');
      this.getSubtypes('0');
    }
  }
  
  subTypeChange(){
    if(this.Diag_test_subtype =="Blood Test" 
      || this.Diag_test_subtype =="Urine Test" 
      ||this.Diag_test_subtype =="Faeces Test"){
        this.addTest_flag = false;
        this.unithidden = false;
        this.diagsubhidden = false;
    } else {
      this.addTest_flag = true;
      this.unithidden =true;
      this.diagsubhidden = true;
    }
  }

  getDiseaseCategory(categ_id){//get disease cateegory
    this.dis_category_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/ddcat',   
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.dis_category_data = obj.test_categories;    

          if (categ_id != "0" && categ_id!=undefined) {
            for (var i = 0; i < this.dis_category_data.length; i++) {
              if (this.dis_category_data[i].diag_dis_cat_id == categ_id) {
                this.diag_dis_categ = this.dis_category_data[i].description;
                break;
              }
            }
          }
        },error => {});
  }

  getTestCategory(categ_id){//get disease cateegory
    this.test_category_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/dtcat',   
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.test_category_data = obj.test_categories;    

          if (categ_id != "0" && categ_id!=undefined) {
            for (var i = 0; i < this.test_category_data.length; i++) {
              if (this.test_category_data[i].diag_test_cat_id == categ_id) {
                this.diag_test_categ = this.test_category_data[i].description;
                break;
              }
            }
          }
        },error => {});
  }

  getSubtypes(subtest_id){//Get subtype
    this.Subtype_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/dttypes',  
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.Subtype_data = obj.diag_test_types;      

          if (subtest_id != null) {
            for (var i = 0; i < this.Subtype_data.length; i++){
              if (this.Subtype_data[i].diag_test_type_id == subtest_id) {
                this.Diag_test_subtype = this.Subtype_data[i].test_type_name;
                this.subTypeChange();
                break;
              }
            }
          }
        },error => {});
  }
  
  Subtest_Select_Click(subtest_data){
    for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
      if (this.Diag_subtest_list_data[i].sub_test_name == subtest_data) {
        this.diag_subtest_id = this.Diag_subtest_list_data[i].sub_test_id;
        this.Diag_Subtest_name = this.Diag_subtest_list_data[i].sub_test_name;
        this.diagSubUOM = this.Diag_subtest_list_data[i].sub_uom;
        this.range = this.Diag_subtest_list_data[i].range;
        this.male_ref=this.Diag_subtest_list_data[i].maleref;
        this.female_ref=this.Diag_subtest_list_data[i].femaleref;
        this.child_ref=this.Diag_subtest_list_data[i].childref;
        this.newborn_ref=this.Diag_subtest_list_data[i].newbornref;
        this.test_method = this.Diag_subtest_list_data[i].test_method;
        this.Diag_subtest_list_data.splice(i, 1);
        break;
      }
    }
  }
  
  Subtest_Delete_Click(subtest_data){//subtest delete
    for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
      if (this.Diag_subtest_list_data[i].sub_test_name == subtest_data) {
        this.Diag_subtest_list_data[i].status = 0;
        break;
      }
    }
  }

  test_add(){//add tests 
    var Valid_flag = false;

    if (this.Diag_Subtest_name == undefined || this.Diag_Subtest_name == "") {
      if(this.Diag_TestName == undefined || this.Diag_TestName == "")
        Valid_flag = true;
      else
        this.Diag_Subtest_name = this.Diag_TestName;
    } 

    if (this.diagSubUOM == undefined || this.diagSubUOM == "") {
      if(this.Diag_Uof == undefined || this.Diag_Uof == ""){
        Valid_flag = true;
      }else{
        this.diagSubUOM = this.Diag_Uof;
      }
    } 

    if (this.male_ref == undefined && this.male_ref == "") {
      Valid_flag = true;
    }

    if (this.female_ref == undefined && this.female_ref == "") {
      Valid_flag = true;
    }
    
    if (Valid_flag == false) {
      var Exist_flag = false;
      if (this.Diag_subtest_list_data.length != 0) {
        Exist_flag = false;
      } else {
        for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
          if (this.Diag_subtest_list_data[i].sub_test_name == this.Diag_Subtest_name) {
            Exist_flag = true;
              break;
          }
        }
      }

      var sub_id = this.diag_subtest_id != undefined ? this.diag_subtest_id : "0";
      if (Exist_flag == false) {
        this.Diag_subtest_list_data.push({
            sub_test_id: sub_id,
            sub_test_name: this.Diag_Subtest_name,
            sub_uom:this.diagSubUOM,
            range:this.range,
            maleref:this.male_ref,
            femaleref:this.female_ref,
            childref:this.child_ref,
            newbornref:this.newborn_ref,
            test_method:this.test_method,
            status:1,
        });

        this.Diag_Subtest_name = undefined;
        this.diagSubUOM= undefined;
        this.male_min = undefined;
        this.male_max = undefined;
        this.female_min = undefined;
        this.female_max = undefined;
        this.child_min = undefined;
        this.child_max = undefined;
        this.born_min = undefined;
        this.born_max = undefined;
        this.range = undefined;
        this.Diag_Subtest_name = "";
        this.diagSubUOM="";
        this.range = '';
        this.male_min = "";
        this.male_max = "";
        this.female_min = "";
        this.female_max = "";
        this.child_min = "";
        this.child_max = "";
        this.born_min = "";
        this.born_max = "";
        this.male_ref="";
        this.female_ref="";
        this.child_ref="";
        this.newborn_ref="";
        this.test_method="";

      } else {
        this.toastr.error(Message_data.dataAlreadyExist);
      }
      this.diag_subtest_id= undefined;
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  getTestDetails(diag_test_id){//retrieve tests
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbyid/',
    JSON.stringify({test_id:diag_test_id}),
      { headers: headers })
      .subscribe(
        response => {
          this.loader=true;
          var obj = JSON.parse(response["_body"]);
          
          if (obj.diag_tests_id != null) {
            this.Diag_TestName = obj.test_name;
            if(obj.uom != undefined){
              this.Diag_Uof = obj.uom;
              this.unithidden = false;
            } else {
              this.unithidden = true;
            }
            this.Diag_test_cost = obj.price;

            if (obj.subtests != undefined) {
              if(obj.subtests.length == 1){
                if(this.Diag_TestName == obj.subtests[0].sub_test_name){
                  this.diagsubhidden = true;
                  this.addTest_flag = true;
                  this.subtest_flag = true;
                  this.addSubtest_checked = false;
                  this.main_test_uom_flag=true;
                  this.Diag_subtest_list_data = obj.subtests;
                  this.range = obj.subtests[0].range;
                  this.male_ref=obj.subtests[0].male;
                  this.female_ref=obj.subtests[0].female;
                  this.child_ref=obj.subtests[0].child;
                  this.newborn_ref=obj.subtests[0].newborn;
                  this.Subtest_Select_Click(obj.subtests[0].sub_test_name);

                }else{
                  this.diagsubhidden = false;
                  this.addTest_flag = false;
                  this.main_test_uom_flag=false;
                  this.subtest_flag = false;
                  this.addSubtest_checked = true;
                  if (obj.subtests.length != 0 && obj.subtests[0].sub_test_name != null) {
                      this.Diag_subtest_list_data = obj.subtests;
                  }
                }

              }else{
                this.diagsubhidden = false;
                this.addTest_flag = false;
                this.main_test_uom_flag=false;
                this.subtest_flag = false;
                this.addSubtest_checked = true;
                if (obj.subtests.length != 0 && obj.subtests[0].sub_test_name != null) {
                    this.Diag_subtest_list_data = obj.subtests;
                }
              }

            } else {
              this.diagsubhidden = true;
              this.addTest_flag = true;
              this.subtest_flag = true;
              this.addSubtest_checked = false;
            }

            if (obj.test_method != undefined && obj.test_method != null && obj.test_method != "") {
              this.test_method = obj.test_method;
            }

            this.getDiseaseCategory(obj.diag_dis_cat_id);
            this.getTestCategory(obj.diag_test_cat_id);
            this.getSubtypes(obj.diag_test_type_id);
          } 
        }, error => {});
  }

  saveTest(flag){//save tests
    if(this.Diag_test_subtype =="Blood Test" || this.Diag_test_subtype =="Urine Test" || this.Diag_test_subtype == "Faeces Test"){
      if(this.main_test_uom_flag == true){
        this.test_add();
      }
    }

    var Valid_final_flag = false;
    if (this.Diag_TestName == undefined || this.Diag_TestName == "") {
        Valid_final_flag = true;
    } else if (this.diag_test_categ == undefined || this.diag_test_categ == "" || this.diag_test_categ == null) {
        Valid_final_flag = true;
    } else if (this.Diag_test_subtype == undefined || this.Diag_test_subtype == "" || this.Diag_test_subtype == null) {
        Valid_final_flag = true;
    } 

    if(this.Diag_test_subtype =="Blood Test" || this.Diag_test_subtype =="Urine Test" || this.Diag_test_subtype == "Faeces Test"){
      if(this.main_test_uom_flag == false){
        if(this.Diag_test_subtype =="Blood Test" || this.Diag_test_subtype =="Urine Test" || this.Diag_test_subtype == "Faeces Test"){
          if (this.Diag_subtest_list_data.length == 0) {
            this.toastr.error(Message_data.addSubTest);
              Valid_final_flag = true;
          }
        }
      }
    }

    if (Valid_final_flag == false) {
      if (this.Diag_test_cost != undefined || this.Diag_test_cost != "") {
          this.test_price = this.Diag_test_cost;
      }

      this.diag_add_test_id = flag == "Save" ? "0" : this.Diag_test_id;

      if (this.Diag_subtest_list_data.length != 0) {
        this.Send_sublist_data = [];
        for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
          this.Send_sublist_data.push(this.Diag_subtest_list_data[i]);
        }
      } else {
        this.Send_sublist_data = undefined;
      }

      for(var i=0; i<this.test_category_data.length; i++){
        if(this.test_category_data[i].description==this.diag_test_categ){
          this.diag_cat_id=this.test_category_data[i].diag_test_cat_id;
        }
      }

      for(var i=0; i<this.dis_category_data.length; i++){
        if(this.dis_category_data[i].description==this.diag_dis_categ){
          this.diag_dis_id=this.dis_category_data[i].diag_dis_cat_id;
        }
      }
      
      for(var i=0; i<this.Subtype_data.length; i++){
        if(this.Subtype_data[i].test_type_name==this.Diag_test_subtype){
          this.diag_subcat_id=this.Subtype_data[i].diag_test_type_id;
        }
      }

      var save_diagnosistestdata =   JSON.stringify({
        diag_tests_id:this.diag_add_test_id,
        diag_centre_id:this.diagnosisapp_id,
        test_name:this.Diag_TestName,
        test_cat_id:this.diag_cat_id,
        dis_cat_id:this.diag_dis_id,
        test_type_id:this.diag_subcat_id,
        uom:this.Diag_Uof,
        price:this.Diag_test_cost,
        subtests:this.Send_sublist_data,
        test_method:this.test_method
      })
      
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/sdtests',save_diagnosistestdata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null && obj.key == "1") {
              this.toastr.success(obj.result);
              this.diagnosisservice.sendMessage("tests");
            } else {
              this.toastr.error(Message_data.testDetNotSaved);
            }
          },error => {});
    } else {
      if(this.main_test_uom_flag == true){
        this.Diag_subtest_list_data = [];
      }
      this.toastr.error(Message_data.mandatory)
    }
  }

  addSubtest(e){
    if(e == true){
      this.subtest_flag = false;
      this.main_test_uom_flag = false;
    }else{
      this.subtest_flag = true;
      this.main_test_uom_flag = true;
    }
  }

  backClicked() {  //back
    this.diagnosisservice.sendMessage('tests')
  }

  onSelectionChanged = (event) => {}
  onContentChanged = (event) => {
    this.range = event.html;
  }

  editorCreated(quill: any) {}    

  onSelectionChanged1 = (event) => {}
  onContentChanged1 = (event) => {
    this.test_method = event.html;
  }

  editorCreated1(quill: any) {}    
}
