import { Component, OnInit } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery/dist/jquery.min.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat,seesion_nameid, session_based_time_fun,Date_Formate, day_nameid } from '../../../assets/js/common.js';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-nurse-registration',
  templateUrl: './nurse-registration.component.html',
  styleUrls: ['./nurse-registration.component.scss']
})
export class NurseRegistrationComponent implements OnInit {

  public img_str = null;
  public Doctor_DOB;
  public Start_time :string;
  public end_time : String;
  public time_session: string;
  public Ends_at_from: string;
  public Ends_at_to:string;
  public endtime_session:string;
  public CurrentDatetime;
  public security_txt;
  public password_string;
  public Create_password;

  public starts_from_array = [];
  public ends_at_array = [];
  public End_from_array = [];
  public end_to_array = [];
  public homecare_consult_array = [];

  public Homecare_show;
  tab2Params: any;
  public togg_home: boolean;
  public homecare_session;

  public homecare_fee;
  public f_name: string;
  public m_name: string;
  public l_name: string;
  public profile_img: string;

  public dob: string;
  public Doc_mobile: string;
  public Doc_email: string;
  public gend_txt: string;
  public Update_img;
  public Imagepath;
  public Date_ofbirth;

  public registration_code;
  public registration_year: string;
  public registration_state: string;
  public client_call: boolean;
  public second_opinion: boolean;
  public den_spl_ids: string;
  public pres_address1: string;
  public pres_address2: string;
  public pres_location_txt: string;
  public pres_city: string;
  public pres_state: string;
  public pres_country: string;
  public pres_zipcode: string;
  public pres_telephone: string;
  public perm_address1: string;
  public perm_address2: string;
  public perm_location_txt: string;
  public perm_city: string;
  public perm_state: string;
  public perm_country: string;
  public perm_zipcode: string;
  public perm_telephone: string;
  public pay_cash: boolean;
  public pay_online: boolean;
  public pay_ins: boolean;
  public pres_city_list_data;
  public pres_state_list_data;
  public pres_country_list_data;
  public city_url;
  public state_url;
  public country_url;
  public pres_location_filterlist = [];
  public pres_loc_txt = [];
  public pres_location_filter_List = [];
  public Get_present_city_arry = [];
  public filt_city_desc;
  public pres_loc_id: string;
  public perm_loc_id: string;
  public mobile_str: string;
  public email_str: string;

  //perm address
  public perm_loc_txt = [];
  public perm_location_filterlist = [];
  public prem_location_txt: string;
  public perm_location_filter_List = [];
  public perm_state_list_data;
  public perm_country_list_data;
  public perm_city_list_data;
  public confirm_pwd;
  public filt_state_desc;
  public country_id: string;
  public hosp_name_txt: string;
  public hosp_loc_txt: string;
  public hosp_id;
  public hosp_address1: string;
  public hosp_address2: string;
  public hop_website: string;
  public hosp_contact_num: string;
  public pres_city_select;
  public spl_col;
  public subspl_col;
  public perm_div: boolean;
  public sameasabove_check: boolean;
  public PreClient_address = [];
  public get_date;
  public pro_img;
  public homecare_morningtool: boolean;
  public homecare_afternoontool: boolean;
  public homecare_eveningtool: boolean;
  public header_footer_flag: boolean;
  public homecaretable: boolean;
  //worklocation
  public work_arry = [];
  public hosp_dist;
  public hosp_state;
  public hosp_country;
  public hosp_zip: string;
  public hosp_fee: string;
  public hosp_days: string;
  public hosp_session: string;
  public start_from1: string;
  public start_from2: string;
  public start_from3: string;
  public end_at1: string;
  public end_at2: string;
  public end_at3: string;
  public hops_consult;
  public app_token;
  public app_time: boolean;
  // public count_txt: string;
  public hospital_txt = [];
  public location_txt = []
  public loc_id: string;
  public country_desc: string;

  public location_filterlist = [];
  public filteredlocList = [];
  public filteredhospList = [];
  public hospital_filterlist = [];

  public locationList;
  public location_search_items;
  public city_list_data;
  public location_list_data = [];
  public state_list_data;
  public country_list_data;
  public clnt_location;

  public Location_array = [];
  public Search_City: string
  public Get_city_arry = [];
  public State_data = [];
  public Search_State: string;
  public Countrydata = [];
  public app_type_lbl: string;

  //Qualification
  public qualification_array = [];
  tabs2Pharms: any;
  public qualification_degree: string;
  public qualification_clg: string;
  public qualification_uni: string;
  public qualification_year: string;

  //terms and condition
  public Reg_profile_tab;
  public Reg_work_tab;
  public Registration_work_add;
  public registration_qual;
  public get_packdata;
  public agree:boolean;
  public dobYear;
  public save_flag:boolean = true;

  public docAge:string;
  public ageRead: boolean=false;
  public ageDisabledFlag: boolean=false;
  public ageUrl: string;

  constructor(public dropdown_dialog: MatDialog,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr: ToastrService) { 
      //Profile
    this.city_url = ipaddress.getIp + "gen/city";
    this.state_url = ipaddress.getIp + "gen/state";
    this.country_url = ipaddress.getIp + "gen/count";
    this.ageUrl = ipaddress.getIp + "adm/ddif";

    this.homecaretable = true;
    this.sameasabove_check = true;
    this.perm_div = true;
    this.time_session = "AM";
    this.endtime_session = "AM";
    this.Imagepath = "default";
    this.Homecare_show = true;
    this.subspl_col = true;
    this.homecare_morningtool = true;
    this.homecare_afternoontool = true;
    this.homecare_eveningtool = true;
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    if(this.CurrentDatetime == undefined)
      this.getCurrentDate();
    else
      this.get_date = this.CurrentDatetime.split('-');

    //Starts time
    for (var i = 1; i <= 12; i++) {
      if (i < 10) 
        this.starts_from_array.push("0" + i);
      else
        this.starts_from_array.push(i);
    }

    this.Start_time = "06";
    this.end_time = "00";
    this.time_session = "AM";
    this.Ends_at_from = "08";
    this.Ends_at_to = "00";
    this.endtime_session = "AM";

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10)
        this.ends_at_array.push("0" + i);
      else
        this.ends_at_array.push(i);
    }

    //End time
    for (var i = 1; i <= 12; i++) {
      if (i < 10)
        this.End_from_array.push("0" + i);
      else 
        this.End_from_array.push(i);
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10)
        this.end_to_array.push("0" + i);
      else
        this.end_to_array.push(i);
    }
  }

  getCurrentDate(){
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            get_date = obj.current_date.split('-');
            this.get_date = this.CurrentDatetime.split('-');
          }
        },error => {});
  }

  checkemail(){
    if(this.Doc_email !=undefined){
      var x = this.Doc_email.trim();
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
      if (!re.test(x)) {
        this.toastr.error(Message_data.validEmail);
        this.Doc_email = undefined;
      } else{
        this.Doc_email = this.Doc_email.trim();
      }
    }
  }

  trimpass(){
    this.Create_password = this.Create_password.trim();
  }
  
  trimpass1(){
    if(this.confirm_pwd != undefined && this.confirm_pwd != null){
      this.confirm_pwd = this.confirm_pwd.trim();
    }
  }

  Acitvate_savebtn(){
    if(this.f_name != undefined && this.l_name != undefined && this.Create_password != undefined && 
      this.registration_code != undefined && this.registration_year != undefined && this.f_name != null 
      && this.l_name != null && this.Create_password != null && this.registration_code != null 
      && this.registration_year != null&& this.f_name != "" && this.l_name != "" 
      && this.Create_password != "" && this.registration_code != "" && this.registration_year != ""){
      this.save_flag = false;
      
    }else{
      this.save_flag = true;
    }
  }

  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.img_str = null;
      } else {
        this.img_str = myReader.result;
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  Sameas_Change(e) { //sameas Event
    this.sameasabove_check = e;
    this.perm_div = this.sameasabove_check == true ? true : false;
  }

  
  home_click() {//Based on home care hide and show
    this.Homecare_show = this.togg_home == true ? false : true;
  }

  session_change1() {
    if (this.hosp_session == "Morning") {
      this.homecare_morningtool = false;
      this.homecare_afternoontool = true;
      this.homecare_eveningtool = true;
      this.start_from3 = "AM";
      this.end_at3="AM";
      this.start_from1 = "06";
      this.start_from2 = "00";
      this.end_at1 = "08";
      this.end_at2 = "00";
    } else if (this.hosp_session == "Afternoon") {
      this.homecare_afternoontool = false;
      this.homecare_morningtool = true;
      this.homecare_eveningtool = true;
      this.start_from3 = "PM";
      this.end_at3="PM";
      this.start_from1 = "12";
      this.start_from2 = "00";
      this.end_at1 = "02";
      this.end_at2 = "00";
    } else if (this.hosp_session == "Evening") {
      this.homecare_eveningtool = false;
      this.homecare_afternoontool = true;
      this.homecare_morningtool = true;
      this.start_from3 = "PM";
      this.end_at3="PM";
      this.start_from1 = "06";
      this.start_from2 = "00";
      this.end_at1 = "08";
      this.end_at2 = "00";
    } else {
      this.homecare_morningtool = true;
      this.homecare_afternoontool = true;
      this.homecare_eveningtool = true;
    }
  }  

  session_change() {
    if (this.homecare_session == "Morning") {
      this.homecare_morningtool = false;
      this.homecare_afternoontool = true;
      this.homecare_eveningtool = true;
      this.time_session = "AM";
      this.endtime_session="AM";
      this.Start_time = "06";
      this.end_time = "00";
      this.Ends_at_from = "08";
      this.Ends_at_to = "00";
    } else if (this.homecare_session == "Afternoon") {
      this.homecare_afternoontool = false;
      this.homecare_morningtool = true;
      this.homecare_eveningtool = true;
      this.time_session = "PM";
      this.endtime_session="PM";
      this.Start_time = "12";
      this.end_time = "00";
      this.Ends_at_from = "02";
      this.Ends_at_to = "00";
    } else if (this.homecare_session == "Evening") {
      this.homecare_eveningtool = false;
      this.homecare_afternoontool = true;
      this.homecare_morningtool = true;
      this.time_session = "PM";
      this.endtime_session="PM";
      this.Start_time = "06";
      this.end_time = "00";
      this.Ends_at_from = "08";
      this.Ends_at_to = "00";
    } else {
      this.homecare_morningtool = true;
      this.homecare_afternoontool = true;
      this.homecare_eveningtool = true;
    }
  }

  pres_location_fun(e) {// present address - location
    this.pres_country_list_data = [];
    this.pres_state_list_data = [];
    this.pres_city_list_data = [];
    this.pres_location_filter_List = [];

    this.pres_city_select = '';
    this.pres_state = '';
    this.pres_country = '';
    this.pres_zipcode = '';

    this.pres_loc_txt = [];
    this.pres_location_filterlist = [];
    this.pres_location_txt = e.target.value.toString();
    if (this.pres_location_txt !== "" && this.pres_location_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.pres_location_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              this.pres_location_filterlist = obj.locations;
              this.pres_location_filter_List = this.pres_location_filterlist.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.pres_location_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },error => {
              this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.pres_location_filter_List = [];
    }
  }

  select_pres_location(data) {
    this.pres_location_txt = data;
    this.pres_location_filter_List = [];
    this.Get_Present_City(data);
    this.pres_state_list_data = [];
    this.pres_city_list_data = [];
    this.pres_country_list_data = [];
  }
  
  Get_Present_City(data) {// present address - city
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.pres_city_list_data = data.json().cities;
          this.pres_city_select = this.pres_city_list_data[0].city_id;
          this.filt_city_desc = this.pres_city_list_data[0].city_desc;
          this.pres_loc_id = this.pres_city_list_data[0].location_id;
          this.pres_zipcode = this.pres_city_list_data[0].zipcode;
          this.Pres_City_change(this.pres_city_list_data[0].city_id);
        }
      },error => {
          this.toastr.error(Message_data.defaultErr);
      });
  }

  Pres_City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null && data.json().states.length != 0) {
            this.pres_state_list_data = data.json().states;
            this.pres_state = this.pres_state_list_data[0].state_id;
            this.filt_state_desc = this.pres_state_list_data[0].state_desc;
            this.Present_state_change(this.pres_state_list_data[0].state_id);
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        });
    } else {
      this.pres_state_list_data = [];
      this.pres_city_list_data = [];
      this.pres_country_list_data = [];
    }
  }

  Present_state_change(data) {// present address - state
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.pres_country_list_data = data.json().countries;
            this.country_id = this.pres_country_list_data[0].country_id;
            this.pres_country = this.pres_country_list_data[0].country_id;
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        });
    } else {
      this.pres_state_list_data = [];
      this.pres_country_list_data = [];
    }
  }
 
  perm_location_fun(e) { //permanent address- location
    this.perm_country_list_data = [];
    this.perm_state_list_data = [];
    this.perm_city_list_data = [];
    this.perm_location_filter_List = [];

    this.perm_city = '';
    this.perm_country = '';
    this.perm_state = '';
    this.perm_zipcode = '';

    this.perm_loc_txt = [];
    this.perm_location_filterlist = [];
    if (this.perm_location_txt !== "" && this.perm_location_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.perm_location_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              this.perm_location_filterlist = obj.locations;
              this.perm_location_filter_List = this.perm_location_filterlist.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.perm_location_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },error => {
              this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.perm_location_filter_List = [];
    }
  }

  select_perm_location(data) {
    this.perm_location_txt = data;
    this.perm_location_filter_List = [];

    this.Get_Permanent_City(data);
    this.perm_state_list_data = [];
    this.perm_city_list_data = [];
    this.perm_country_list_data = [];
  }
    
  Get_Permanent_City(data) {//permanent address- city
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.perm_city_list_data = data.json().cities;
          this.perm_city = this.perm_city_list_data[0].city_id;
          this.filt_city_desc = this.perm_city_list_data[0].city_desc;
          this.perm_loc_id = this.perm_city_list_data[0].location_id;
          this.perm_zipcode = this.perm_city_list_data[0].zipcode;
          this.Perm_City_change(this.perm_city_list_data[0].city_id);
        }
      },error => {
          this.toastr.error(Message_data.defaultErr);
      });
  }

  Perm_City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null && data.json().states.length != 0) {
            this.perm_state_list_data = data.json().states;
            this.perm_state = this.perm_state_list_data[0].state_id;
            this.filt_state_desc = this.perm_state_list_data[0].state_desc;
            this.Permanent_state_change(this.perm_state_list_data[0].state_id);
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        })
    } else {
      this.perm_state_list_data = [];
      this.perm_city_list_data = [];
      this.perm_country_list_data = [];
    }
  }
  
  Permanent_state_change(data) { //permanent address- state
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.perm_country_list_data = data.json().countries;
            this.country_id = this.perm_country_list_data[0].country_id;
            this.perm_country = this.perm_country_list_data[0].country_id;
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        })
    } else {
      this.perm_state_list_data = [];
      this.perm_country_list_data = [];
    }
  }
  
  homecare_btn_click() { // Homecare consulation in table
    //this.homecare_consult_array = [];
    var ret_value = true

    if (this.homecare_session == undefined || (this.homecare_fee == undefined || this.homecare_fee == null)) {
      this.toastr.error(Message_data.mandatory);
    } else {
      if(this.Start_time == this.Ends_at_from){
        this.toastr.error(Message_data.enterValidTime);
      }else{
        var f_time = ConvertTimeformat("", this.Start_time + ":" + this.end_time + " " + this.time_session);
        var t_time = ConvertTimeformat("", this.Ends_at_from + ":" + this.Ends_at_to + " " + this.endtime_session);
        var ret_value = session_based_time_fun(f_time, t_time, this.homecare_session);
  
        if (ret_value == true) {
          this.homecare_consult_array.push({
            day_session: seesion_nameid(this.homecare_session),
            session: this.homecare_session,
            available_from: ConvertTimeformat("", this.Start_time + ":" + this.end_time + " " + this.time_session),
            available_to: ConvertTimeformat("", this.Ends_at_from + ":" + this.Ends_at_to + " " + this.endtime_session),
            available_from1: this.Start_time + ":" + this.end_time + " " + this.time_session,
            available_to1: this.Ends_at_from + ":" + this.Ends_at_to + " " + this.endtime_session,
            home_care_fee: this.homecare_fee,
            homecare_consult_id: "0"
          });
  
          this.homecaretable = this.homecare_consult_array.length != 0 ? false : true;
          this.homecare_session = "Select";
          this.Start_time = "06";
          this.end_time = "00";
          this.time_session = "AM";
          this.Ends_at_from = "08";
          this.Ends_at_to = "00";
          this.endtime_session = "AM";
          this.homecare_fee = null;
        } 
      }
    }
  }
  
  home_consult_delete(session_data, avail_from, avail_to) {//Home care delete
    for (var i = 0; i < this.homecare_consult_array.length; i++){
      if (session_data == this.homecare_consult_array[i].session && avail_from == this.homecare_consult_array[i].available_from1 && avail_to == this.homecare_consult_array[i].available_to1) {
        this.homecare_consult_array.splice(i, 1);
      }
    }
    this.homecaretable = this.homecare_consult_array.length != 0 ? false : true;
  }
  
  Profile_next_click() {//Profile next click
    var chec_valid = true;
    if (this.f_name == undefined || this.f_name == "" || this.l_name == undefined || this.l_name == "" 
      || this.gend_txt == undefined || this.Doc_mobile == undefined || this.Doc_mobile == "" 
      || this.registration_code == undefined || this.registration_code == "" 
      || this.registration_year == undefined || this.registration_year == "" || this.registration_code == undefined 
      || this.registration_state == "" || this.Create_password == undefined || this.Create_password == "" 
      || this.confirm_pwd == undefined || this.confirm_pwd == "" ) {
      this.toastr.error(Message_data.mandatory);
      chec_valid = false;
    } else if (this.togg_home == true && (this.pres_address1 == undefined || this.pres_address1 == "" || this.pres_location_txt == undefined || this.pres_location_txt == "" ||
      this.pres_city_select == undefined || this.pres_state == undefined || this.pres_country == undefined || this.pres_telephone == undefined || this.pres_telephone == "")) {
      this.toastr.error(Message_data.enterPresentAddrMandatory);
      chec_valid = false;
    } else if (this.togg_home == true && (this.sameasabove_check == undefined || this.sameasabove_check == false) && (this.perm_address1 == undefined || this.perm_address1 == "" || this.perm_location_txt == undefined || this.perm_location_txt == "" ||
      this.perm_city == undefined || this.perm_state == undefined || this.perm_country == undefined || this.perm_telephone == undefined || this.perm_telephone == "")) {
      this.toastr.error(Message_data.enterPermenantAddrMandatory);
      chec_valid = false;
    } else if (this.Doc_mobile.length < 10) {
      this.toastr.error(Message_data.validMobileNo);
      chec_valid = false;
    } else if (this.Doc_email != undefined) {
      var x = this.Doc_email.trim();
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
      if (!re.test(x)) {
        chec_valid = false;
        this.toastr.error(Message_data.validEmail);
      }
    }
    
    if (this.Create_password != this.confirm_pwd) {
      this.toastr.error(Message_data.pwdNotMatched);
      chec_valid = false;
    }

    if (this.Doctor_DOB != undefined) {
      var get_t_date = this.Doctor_DOB;
      var Dobsplit = get_t_date.toString().split('-');
    }

    // if (this.Doctor_DOB != "" && this.registration_year != undefined && this.registration_year != "") {
    //   if ((this.registration_year.length != 4) || (parseInt(this.registration_year) > parseInt(this.get_date[0]))) {
    //     this.toastr.error(Message_data.validRegtrYear);
    //     chec_valid = false;
    //   } else if ((parseInt(this.registration_year) - parseInt(Dobsplit[0])) < 22) {
    //     this.toastr.error(Message_data.invalidRegtrOrDOB);
    //     chec_valid = false;
    //   }
    // }

    if (chec_valid == true) {
      this.check_mobile_exits();
    }
  }

  final_update() {
    var cli_call = null;
    cli_call = this.client_call == true ? "1" : "0";

    var sec_opn = null;
    sec_opn = this.second_opinion == true ?  "1" : "0";

    var home_value = null;
    home_value = this.togg_home == true ?  "1" : "0";

    var pcash;
    pcash = this.pay_cash == true ?  "1" : "0";

    var ponline;
    ponline = this.pay_online == true ?  "1" : "0";

    var pins;
    pins = this.pay_ins == true ?  "1" : "0";

    var present_address2 = "";
    if (this.pres_address2 != undefined) {
      present_address2 = this.pres_address2;
    }
    
    var permanent_address2 = "";
    if (this.perm_address2 != undefined) {
      permanent_address2 = this.perm_address2;
    }

    if(this.Doctor_DOB != undefined){
      var get_tmp = this.Doctor_DOB.split('-');
      var get_f_date = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0]; 
    }
    
    this.PreClient_address = [];
    if (this.sameasabove_check == true && this.togg_home != undefined && this.togg_home == true) {
      this.PreClient_address.push({
        address1: this.pres_address1,
        address2: present_address2,
        location: this.pres_loc_id,
        city: this.pres_city_select,
        state: this.pres_state,
        country: this.pres_country,
        zipcode: this.pres_zipcode,
        telephone: this.pres_telephone,
        sameascurrent: "1",
        type: "pres",
      });
    } else {
      this.PreClient_address.push({
        address1: this.pres_address1,
        address2: present_address2,
        location: this.pres_loc_id,
        city: this.pres_city_select,
        state: this.pres_state,
        country: this.pres_country,
        zipcode: this.pres_zipcode,
        telephone: this.pres_telephone,
        sameascurrent: "0",
        type: "pres",
      }, {
          address1: this.perm_address1,
          address2: permanent_address2,
          location: this.perm_loc_id,
          city: this.perm_city,
          state: this.perm_state,
          country: this.perm_country,
          zipcode: this.perm_zipcode,
          telephone: this.perm_telephone,
          sameascurrent: "0",
          type: "perm",
        });
    }

    this.Update_img = this.Imagepath == "default" ? "default" : this.Imagepath.split(',')[1];

    if(this.Doc_email != undefined){
      var emailstr = this.Doc_email.trim();
    }
    
    this.mobile_str = encrypt_decript.Encript(this.Doc_mobile.toString()).toString();
    this.email_str = encrypt_decript.Encript(emailstr).toString();
    this.password_string = encrypt_decript.Encript(this.Create_password).toString();
    var img_path = this.pro_img, upd_url = undefined;
    if (this.profile_img != null) {
      img_path = this.profile_img.split(',')[1];
      upd_url = "1";
    } else {
      img_path = "default";
    }

    if (this.PreClient_address != undefined && this.PreClient_address.length == 0) {
      this.PreClient_address = undefined;
    }

    if (this.homecare_consult_array != undefined && this.homecare_consult_array.length == 0) {
      this.homecare_consult_array = undefined;
    }

    if (this.togg_home == undefined || this.togg_home == false) {
      this.homecare_consult_array = undefined;
      this.PreClient_address = undefined;
    }

    var dobval ;
    if(get_f_date != undefined){
      dobval = Date_Formate(get_f_date);
    }

    var send_array = null;
    send_array = {
      serv_provider_id: "2",
      upd_url: upd_url,
      profile_image: img_path,
      first_name: this.f_name,
      middle_name: this.m_name,
      last_name: this.l_name,
      gender: this.gend_txt,
      dob: dobval,
      age:this.docAge,
      email: this.email_str,
      mobile: this.mobile_str,
      registration_code: this.registration_code,
      registration_state: this.registration_state,
      registration_year: this.registration_year,
      language: "English",
      created_date: this.CurrentDatetime,
      password: this.password_string,
      verification_call: "0",
      client_call: cli_call,
      home_care: home_value,
      second_opinion: sec_opn,
      home_address: this.PreClient_address,
      homecare_consult: this.homecare_consult_array,
    }
    Helper_Class.set_regsitration_profile_array(send_array);
    this.next_click_wloc();
  }

  emailCheck() {
    var email_di = encrypt_decript.Encript(this.Doc_email).toString();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
      JSON.stringify({
        email: email_di.toString(),
        flag: "1"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result == true)
            this.toastr.error(Message_data.existEmailID);
          else
            this.final_update();
        },error => {});
  }

  check_mobile_exits() {
    if(this.Doc_mobile != undefined){
      var email_di = encrypt_decript.Encript(this.Doc_mobile.toString()).toString();
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
      JSON.stringify({
        mobile: email_di.toString(),
        flag: "1"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result == true) {
            this.toastr.error(Message_data.existMobileNo);
          } else {
            if(this.Doc_email !=undefined && this.Doc_email.trim() != undefined){
              this.emailCheck();
            } else {
              this.final_update();
            }
          }
        },error => {});
  }

  work_btn_click() {// add table
    var flag = false;

    if (this.hosp_name_txt == "") {
      this.toastr.error(Message_data.mandatory)
      flag = true;
    }
    
    if(this.start_from1 == this.end_at1){
      this.toastr.error(Message_data.enterValidTime)
      flag = true;
    }

    if (flag == false) {
      if (this.hospital_txt.length != 0) {
        var cosult_id = null;
        cosult_id = this.hops_consult == true ? 1 : 0;

        if (this.hosp_name_txt == "" || this.hosp_loc_txt == "" ||
          this.hosp_dist == undefined || this.hosp_state == "" || this.hosp_country == "" ||
          this.hosp_zip == "" || this.hosp_address1 == "" || this.hosp_contact_num == "" ||
          this.hosp_days == "" || this.hosp_session == "") {
          this.toastr.error(Message_data.mandatory)
        }else {
          var token_name = undefined, time_dure = undefined;
          var f_time = ConvertTimeformat("", this.start_from1 + ":" + this.start_from2 + " " + this.start_from3);
          var t_time = ConvertTimeformat("", this.end_at1 + ":" + this.end_at2 + " " + this.end_at3);
          var ret_value = session_based_time_fun(f_time, t_time, this.hosp_session);
          if (ret_value == true) {
            this.work_arry.push({
              hospital_id: this.hosp_id,
              name: this.hosp_name_txt,
              location: this.hosp_loc_txt,
              city: this.hosp_dist,
              state: this.hosp_state,
              country: this.hosp_country,
              zipcode: this.hosp_zip,
              address1: this.hosp_address1,
              address2: this.hosp_address2,
              mobilenum: this.hosp_contact_num,
              website: this.hop_website,
              fee: this.hosp_fee,
              day_desc: this.hosp_days,
              day: day_nameid(this.hosp_days),
              session_desc: this.hosp_session,
              day_session: seesion_nameid(this.hosp_session),
              available_from: this.start_from1 + ":" + this.start_from2 + " " + this.start_from3,
              available_to: this.end_at1 + ":" + this.end_at2 + " " + this.end_at3,
              consultant: cosult_id,
              appointment: this.app_token + "" + this.app_time,
              token: token_name,
              app_duration: time_dure,
              consult_time_id: "0"
            });

            this.hosp_days = "";
            this.hosp_session = "";
            this.hops_consult = true;
            this.start_from1 = "06";
            this.start_from2 = "00";
            this.start_from3 = "AM";
            this.end_at1 = "08";
            this.end_at2 = "00";
            this.end_at3 = "AM";
          }else {
            this.toastr.error(Message_data.invalidTime);
          }
        }
      }else {
        this.toastr.error(Message_data.validHosptl);
      }
    }
  }
 
  work_delete(name_data, day, session, from_time) { //table delecte action
    for (var i = 0; i < this.work_arry.length; i++) {
      if (name_data == this.work_arry[i].name && day == this.work_arry[i].day_desc 
        && session == this.work_arry[i].session_desc && from_time == this.work_arry[i].available_from) {
        this.work_arry.splice(i, 1);
      }
    }
  }

  clearHospital(){
    this.hosp_name_txt=null;
    this.hosp_id=undefined;
    this.hospital_filterlist=[];
  }
  
  hospital_filter(e) {
    this.city_list_data = [];
    this.state_list_data = [];
    this.country_list_data = [];
    this.filteredlocList = [];
    this.location_list_data = [];

    this.hosp_loc_txt = '';
    this.hosp_dist = '';
    this.hosp_state = '';
    this.hosp_country = '';
    this.hosp_zip = '';
    this.hosp_address1 = '';
    this.hosp_address2 = '';
    this.hosp_contact_num = '';
    this.hop_website = '';
    this.hosp_name_txt =e.target.value;
    
    var tmp_arr = [];
    if (this.hosp_name_txt !== "" && this.hosp_name_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hosp_name_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.hospital_txt = [];
            this.hospital_filterlist = [];
            this.filteredhospList=[];
            var obj = response.json();
            
            if (obj.hospitals != null) {
              this.hospital_filterlist = obj.hospitals;
              for (var i = 0; i < this.hospital_filterlist.length; i++) {
                this.hospital_txt.push(this.hospital_filterlist[i].hosp_name);
              }
              this.filteredhospList = this.hospital_txt.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_name_txt.toLowerCase()) > -1;
              }.bind(this));
              if (this.hospital_txt.length == 0) {
                this.toastr.error(Message_data.noHosptlFound);
              }
            }else {
              this.toastr.error(Message_data.noHosptlFound);
            }
          },error => {});
    } else {
      this.filteredhospList = [];
    }
  }

  getLocationBasedonhospital() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/hloc/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hosp_locations != null) {
            this.location_list_data = obj.hosp_locations;
          }
        },error => {});
  }

  select_hospital(data) {
    this.hosp_name_txt = data;
    this.filteredhospList = [];
    if (this.hosp_name_txt != undefined) {
      this.getLocationBasedonhospital();
    }
  }

  Loc_change() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/haddr/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt,
        location: this.hosp_loc_txt
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hptl_clinic_id != null) {
            this.hosp_id = obj.hptl_clinic_id;
            this.hosp_address1 = obj.address1;
            this.hosp_address2 = obj.address2;
            this.hosp_dist = obj.city;
            this.hosp_state = obj.state;
            this.hosp_country = obj.country;
            this.hosp_contact_num = obj.telephone;
            this.hop_website = obj.website;
            this.hosp_zip = obj.zipcode;
          }
        },error => {});
  }
  
  locfilter() {//location autopopulate
    this.hosp_dist = '';
    this.hosp_state = '';
    this.hosp_country = '';
    this.hosp_zip = '';
    this.hosp_address1 = '';
    this.hosp_address2 = '';
    this.hosp_contact_num = '';
    this.hop_website = '';
    this.hosp_id = "0";
    this.location_txt = [];
    this.location_filterlist = [];
    var tmp_arr = [];
    if (this.hosp_loc_txt !== "" && this.hosp_loc_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.hosp_loc_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              this.location_filterlist = obj.locations;
              this.filteredlocList = this.location_filterlist.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_loc_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },error => {});
    } else {
      this.filteredlocList = [];
    }
  }

  select_location(data) {
    this.hosp_loc_txt = data;
    this.filteredlocList = [];
    this.Get_City(data);
    this.state_list_data = [];
    this.city_list_data = [];
    this.country_list_data = [];
  }

  Get_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.city_list_data = data.json().cities;
          this.hosp_dist = this.city_list_data[0].city_id;
          this.filt_city_desc = this.city_list_data[0].city_desc;
          this.loc_id = this.city_list_data[0].location_id;
          this.hosp_zip = this.city_list_data[0].zipcode;
          this.City_change(this.city_list_data[0].city_id);
        }
      },error => {});
  }

  City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.state_list_data = data.json().states;
            this.hosp_state = this.state_list_data[0].state_id;
            this.filt_state_desc = this.state_list_data[0].state_desc;
            this.State_change(this.state_list_data[0].state_id);
          }
        },error => {});
    } else {
      this.state_list_data = [];
      this.city_list_data = [];
      this.country_list_data = [];
    }
  }

  State_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != null) {
            this.country_list_data = data.json().countries;
            this.country_id = this.country_list_data[0].country_id;
            this.hosp_country = this.country_list_data[0].country_desc;
            this.SerCountry_Change();
          }
        },error => {});
    } else {
      this.state_list_data = [];
      this.country_list_data = [];
    }
  }

  SerCountry_Change() {
    this.hosp_id = "0";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospdetails/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt,
        location: this.hosp_loc_txt,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.hosp_id != null) {
            this.hosp_id = obj.hosp_id;
            this.hosp_address1 = obj.address1;
            this.hosp_address2 = obj.address2;
            if (obj.telephone != null) {
              this.hosp_contact_num = obj.telephone;
            }
            if (obj.website != null) {
              this.hop_website = obj.website;
            }
          }
        },error => {});
  }

  qualification_btn_click() {//qualification
    if (this.qualification_degree == "" || this.qualification_uni == "" ) {
      this.toastr.error(Message_data.mandatory)
    } else {
      var flag = true;
      var currentTime = new Date()
      var cuurrent_year = currentTime.getFullYear();
      // var reg_year_editText = this.qualificationYear;
      // var reg = parseInt(reg_year_editText);

      if (parseInt(this.docAge) < 22) {
        this.toastr.error(Message_data.validYrOfCompletion)
        flag = false;
      } else if (parseInt(this.qualification_year) > cuurrent_year) {
        this.toastr.error(Message_data.validYrOfCompletion)
        flag = false;
      }

      if (flag == true) {
        this.qualification_array.push({
          degree: this.qualification_degree,
          institute: this.qualification_clg,
          university: this.qualification_uni,
          year: this.qualification_year,
        })
        //Resets the form 
        this.qualification_degree = "";
        this.qualification_uni = "";
        this.qualification_year = "";
        this.qualification_clg = "";
      }
    }
  }

  qua_delete(degree_da, year_da) {//delete action
    for (var i = 0; i < this.qualification_array.length; i++) {
      if (degree_da == this.qualification_array[i].degree) {
        this.qualification_array.splice(i, 1);
      }
    }
  }

  Qualification_next_click() {
    if (this.qualification_array.length == 0) {
      this.toastr.error(Message_data.addQualf);
    } else {
      var qual_arr = null;
      qual_arr = {
        qualification: this.qualification_array
      }
      Helper_Class.setregistration_qual_array(qual_arr);
    }
    this.intitateToRegister();
  }

  intitateToRegister(){
    if(this.agree==true) {
      var headers = new Headers();
      this.Reg_profile_tab = Helper_Class.get_regsitration_profile_array();
      var mob = encrypt_decript.Encript(this.Doc_mobile.toString()).toString();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/otp/',
        JSON.stringify({
          mobile: mob,
          country: ipaddress.country_code,
          flag:"2",
          registration:true
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.otp != null)
              this.otp_open(obj.otp);
          },error => {
              this.toastr.error(Message_data.defaultErr);
          });
      } else{
        this.toastr.error(Message_data.termsNconditions);
      }
  }

  register_next_click() {
    this.Profile_next_click();
  }

  next_click_wloc() {
   //this.work_arry
    if (this.work_arry.length == 0) {
      this.toastr.error(Message_data.addWrkLocation);
    }else {
      var work_arry1 = [];
      var work_address_array = [];
      for (var i = 0; i < this.work_arry.length; i++) {
        if (this.work_arry[i].hospital_id == "0") {
          var address2_txt = undefined;
          address2_txt = this.work_arry[i].address2 != undefined ? this.work_arry[i].address2 : "";

          var website_txt = undefined;
          website_txt = this.work_arry[i].hospital_website != undefined ? this.work_arry[i].hospital_website : "";

          work_address_array.push({
            name: this.work_arry[i].name,
            address1: this.work_arry[i].address1,
            address2: address2_txt,
            city: this.work_arry[i].city,
            state: this.work_arry[i].state,
            zipcode: this.work_arry[i].zipcode,
            country: this.work_arry[i].country,
            telephone: this.work_arry[i].mobilenum,
            website: this.work_arry[i].hospital_website,
            location: this.work_arry[i].location,
          });

          var hosp_nursename = this.work_arry[i].name;
          var hosp_nurseloc = this.work_arry[i].location;
        }

        var consult_time_id = this.work_arry[i].consul_id != "0" ? this.work_arry[i].consult_time_id : "0";
        work_arry1.push({
          hospital_id: this.work_arry[i].hospital_id,
          available_from: ConvertTimeformat("", this.work_arry[i].available_from),
          available_to: ConvertTimeformat("", this.work_arry[i].available_to),
          fee: this.work_arry[i].fee,
          day: this.work_arry[i].day,
          day_session: this.work_arry[i].day_session,
          token: this.work_arry[i].token,
          app_duration: this.work_arry[i].app_duration,
          consultant: this.work_arry[i].consultant,
          consultation_time_id: consult_time_id,
          hosp_name: hosp_nursename,
          location: hosp_nurseloc,
        });
      }
      Helper_Class.set_reg_work_array(work_arry1);
      Helper_Class.set_reg_work_add_array(work_address_array);
      this.Qualification_next_click();
    }
  }

  set_image_data() {
    if (this.img_str == "../../assets/img/default.jpg") {
      this.profile_img = "default";
    }else {
      if (this.img_str != undefined){
        this.profile_img = this.img_str.split(',')[1];
      }else{
        this.profile_img = "default";
      }
    }
    this.register();
  }

  otp_open(otp_data) {
    this.set_image_data();
    // var otpval;
    // if(otp_data !="false"){
    //   otpval =encrypt_decript.Decript(otp_data);

    // }else{
    //   otpval = otp_data;
    // }

    // let poup = this.modalCtrl.create('OtpConfirmPage', {
    //   "otp":otpval,
    //   type:"registration"
    // });

    // poup.present();
    // poup.onDidDismiss(data => {
    //   if (data != undefined) {
    //     if (data.result == true) {
    //       this.register();
    //     }
    //   }
    // });
    // let alert1 = this.alertCtrl.create({
    //   title: 'An OTP has been sent to entered mobile number. Please enter OTP in below field',
    //   inputs: [
    //     {
    //       name: 'otp_txt',
    //       placeholder: 'OTP code',
    //       max: '6',
    //     }
    //   ],
    //   buttons: [
    //     {
    //       text: 'Register',

    //       handler: data => {
    //         if (data.otp_txt.toString().trim() == "" && data.otp_txt.length == 0) {
    //           this.toastr.error("Please enter OTP");
    //         }
    //         else if (data.otp_txt.length > 6) {
    //           this.toastr.error("You have reached a limit of 6 character");
    //         }
    //         else {
    //           if (data.otp_txt == otp_data || data.otp_txt == "qwerty") {             
    //             this.Reg_profile_tab = Helper_class.get_regsitration_profile_array();
    //             this.Reg_work_tab = Helper_class.get_reg_work_array();
    //             this.Registration_work_add = Helper_class.get_reg_work_add_array();
    //             this.registration_qual = Helper_class.getregistration_qual_array();
    //             this.get_packdata = Helper_class.get_package_data();
    //             
    //             
    //             
    //             
    //             

    //             var send_work = null;
    //             send_work = {
    //               consultation: this.Reg_work_tab
    //             }

    //             var send_url = "usercontroller/regprov/";
    //             for (var key in this.Reg_profile_tab) {
    //               
    //               if (key == "upd_url" && this.Reg_profile_tab[key] != undefined) {
    //                 send_url = "usercontroller/regprovweb/";
    //               }
    //             }
                
    //             var subscribe_array = {
    //               grit_package_subscribe_id: "12",
    //               country: ipaddress.country_code
    //             }

    //             var finalObj = $.extend(true, this.Reg_profile_tab, send_work);
    //             var finalObj1 = $.extend(true, finalObj, this.registration_qual);
    //             var finalObj2 = $.extend(true, finalObj1, subscribe_array);
    //             
    //             var output = null;
    //             output = finalObj2;

    //             // send foot ass
    //             var send_foot = null, getfoot_ass = null, qual;
    //             getfoot_ass = Helper_class.get_reg_work_add_array();

    //             if (this.Registration_work_add.length != 0) {

    //               send_foot = {
    //                 address: getfoot_ass,
    //               }
    //               output =  $.extend (true,finalObj2,send_foot);

    //               

    //             }


    //             

    //             var headers = new Headers();
    //             headers.append('Content-Type', 'application/json');
    //             this.http.post(ipaddress.getIp.toString() + send_url, output,
    //               { headers: headers })
    //               .subscribe(
    //                 response => {
    //                   var obj = JSON.parse(response["_body"]);

    //                   if(obj.key=="1"){
    //                     this.toastr.error(Message_data.regtrSuccess);
                       
    //                     var modalPage = this.modalCtrl.create("LoadingPage", {
    //                         "Regapp": "registration"
    //                     });
    //                     modalPage.present();

    //                   } else{
    //                     this.toastr.error(Message_data.unableToRegtr);
    //                   }
    //                 },
    //                 error => {
    //                     this.toastr.error(Message_data.defaultErr);
    //                 }
    //               )

    //           } else {
    //             this.toastr.error("Invalid OTP, please try again later");
    //           }           
    //         }
    //       }
    //     }
    //   ]
    // });
    // alert1.present();
  }

  register(){
    this.Reg_profile_tab = Helper_Class.get_regsitration_profile_array();
    this.Reg_work_tab = Helper_Class.get_reg_work_array();
    this.Registration_work_add = Helper_Class.get_reg_work_add_array();
    this.registration_qual = Helper_Class.getregistration_qual_array();
    var send_work = null;
    send_work = {
      consultation: this.Reg_work_tab
    }

    var send_url = "usercontroller/regprov/";
    for (var key in this.Reg_profile_tab) {
      if (key == "upd_url" && this.Reg_profile_tab[key] != undefined) {
        send_url = "usercontroller/regprovweb/";
      }
    }
    
    var subscribe_array = {
      grit_package_subscribe_id: "12",
      country: ipaddress.country_code
    }

    var finalObj = $.extend(true, this.Reg_profile_tab, send_work);
    var finalObj1 = $.extend(true, finalObj, this.registration_qual);
    var finalObj2 = $.extend(true, finalObj1, subscribe_array);
    var output = null;
    output = finalObj2;

    // send foot ass
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_reg_work_add_array(); 

    if (this.Registration_work_add.length != 0) {
      send_foot = {
        address: getfoot_ass,
      }
      output =  $.extend (true,finalObj2,send_foot);
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + send_url, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj.key=="1"){
            this.toastr.success(Message_data.regtrSuccess);
            this.router.navigateByUrl("/loginpage");
          } else{
            this.toastr.error(Message_data.unableToRegtr);
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  Date_DOB(e){
    this.Doctor_DOB = e;

    this.ageRead = true;
    if(e != null)
    this.ageDisabledFlag = true;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, JSON.stringify({
      dobdate: Date_Formate(this.Doctor_DOB),
      curdate: Date_Formate(this.CurrentDatetime),

    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj != undefined)
          this.docAge = obj.value;
      });
  }

  Date_qulification(e){
    this.qualification_year = e;
  }
}
