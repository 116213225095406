import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-doc-stock-report',
  templateUrl: './doc-stock-report.component.html',
  styleUrls: ['./doc-stock-report.component.scss']
})
export class DocStockReportComponent implements OnInit {
  public Locationlist = [];
  public LoadLocationlist = [];
  public location: any;
  public stockArray: any = [];
  public userinfo;
  public hospitaID;
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) { }
  ngOnInit(): void {
    this.hospitaID = Doc_Helper.getHospitalClinicalId();
    this.get_stock();
  }
  get_stock() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpstock/",
      JSON.stringify({
        hptl_clinic_id: this.hospitaID,
      }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().stock_onhand != null) {
            this.stockArray = data.json().stock_onhand;
          }
        },
        error => { });
  }
}
