import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { pharmacy_helper } from '../Pharmcy_Helper';
import moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-interdept-trans',
  templateUrl: './interdept-trans.component.html',
  styleUrls: ['./interdept-trans.component.scss']
})
export class InterdeptTransComponent implements OnInit {
  public pharmacyID: string;
  public userInfo;
  public ipaddress: string;
  public department: string;
  public medicineName;
  public dept_array:any=[];
  public drugArray = [];
  public MedicneData;
  public MedicineNameArray;
  public medicineArray;
  public drugDetailsArray:any=[];
  public tempBatch;
  public batchFlag: boolean = false;
  public genericDisplayFlag:boolean = false;
  public getData;
  public pharmacistID;
  public location;
  public city;
  public state;
  public country;
  public pharmacyName;
  public dlNO;
  public gstNO;
  public pharmaAddress1;
  public pharmaAddress2;
  public displayDisplay: boolean;
  public dtOptions;
  public productReturn;
  public todayDate: string;
  public rec_name: string;
  public saveOrderURL: string;
  public fetchURL: string;
  public orderdet:any;
  public transfer_id:string;

  constructor(public pharmacyservice: PharmacyService, public toastr: ToastrService, 
    public gservice: CommonDataService, public http: HttpClient, public router: Router, 
    public dialog: MatDialog) {
      this.userInfo = Helper_Class.getInfo();
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
      this.ipaddress = Helper_Class.getIPAddress();
      this.saveOrderURL = ipaddress.getIp + "pharmacontrol/sidt";
      this.fetchURL = ipaddress.getIp + "pharmacontrol/gidti";
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getData = Helper_Class.getInfo();
    console.log("PHARMA DETAILS "+JSON.stringify(this.getData))
    this.ipaddress = Helper_Class.getIPAddress();
    this.pharmacistID = this.getData.user_id;
    this.pharmacyID = this.getData.hospitals[0].hptl_clinic_id;
    this.orderdet = pharmacy_helper.getorderview();

    this.CurrentDate();
    this.addNewDrugDetails();
    var Moduleidlist;
    if (pharmacy_helper.getModuleList() != null) {
      Moduleidlist = pharmacy_helper.getModuleList(); 
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "99") {
          this.genericDisplayFlag = true;
        }
      }
    }
    if(Helper_Class.getDepartments() == undefined){
      this. getDepartments();
    } else {
      this.dept_array = Helper_Class.getDepartments();
    }
    
    if(this.orderdet.type =="update"){
      this. getTransferDetails();
    }
  }

  CurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.todayDate = obj.current_date;
        },
      )
  }

  getDepartments(){
    var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gdbh/', {hptl_clinic_id:this.pharmacyID},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("DEPARTMENTS "+JSON.stringify(obj))
            if(obj.departments != undefined && obj.departments.length !=0){
              for(var i=0;i<obj.departments.length;i++){
                this.dept_array.push({
                  dept_id: obj.departments[i].department_id,
                  dept_desc: obj.departments[i].description,
                })
              }
              this.department = this.dept_array[0].dept_id;
              Helper_Class.setDepartments(this.dept_array);
            }
          }, error => { 
            console.log("error while getting departments "+error);
           })
  }

  changeMedicineName(e,type) {
    var senddetails = {
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type : type
    }
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log( "obj" + JSON.stringify(obj));
           
              if (obj.med_details != null) {
                this.MedicineNameArray = [];
                this.medicineArray = [];
                this.MedicneData = [];
                for (var i = 0; i < obj.med_details.length; i++) {
                  var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                 // this.MedicneData.push(medname);
                  this.MedicneData.push({ name : medname, quantity : obj.med_details[i].quantity, generic : obj.med_details[i].generic_name});
                  this.medicineArray.push({
                    med_id: obj.med_details[i].med_id,
                    med_name: obj.med_details[i].med_name,
                    med_type_id: obj.med_details[i].med_type_id,
                    type_name: obj.med_details[i].type_name,
                    short_name: obj.med_details[i].short_name,
                    short_form: obj.med_details[i].short_form,
                    show_short_form: obj.med_details[i].show_short_form,
                    show_intake: obj.med_details[i].show_intake,
                    medname: medname,
                    cgst: obj.med_details[i].cgst,
                    sgst: obj.med_details[i].sgst,
                  })
              }
            }
            
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  selectMedicineItem(item, drug_detailsarray) {
    for (var i = 0; i < this.medicineArray.length; i++) {
      if (item.name == this.medicineArray[i].medname) {
        drug_detailsarray.drug_name = item.name;
        drug_detailsarray.gen_name = item.generic;
        drug_detailsarray.type_name = this.medicineArray[i].short_name;
        drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
        drug_detailsarray.drug_id = this.medicineArray[i].med_id;
        this.medicineName = this.medicineArray[i].med_name;
        drug_detailsarray.cgst = this.medicineArray[i].cgst;
        drug_detailsarray.sgst = this.medicineArray[i].sgst;
      }
    }
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }

  getItemPrices(drug_detailsarray) {
    var send_data = {
      pharma_id: this.pharmacyID,
      drug_ids: drug_detailsarray.drug_id
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/ipnopres/", send_data, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval != undefined && dataval.length != 0) {
          drug_detailsarray.batcharray = [];
          var index = 0;
          for (var i = 0; i < dataval.pharma_price.length; i++) {
            this.drugArray.push({
              price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
              per_unit: dataval.per_unit,
              batch_no: dataval.pharma_price[i].batch_no,
              quantity: dataval.pharma_price[i].avail_qty,
              discount: dataval.pharma_price[i].prod_disc,
              cgst: dataval.pharma_price[i].prod_cgst,
              sgst: dataval.pharma_price[i].prod_sgst,
              expiry_date: dataval.pharma_price[i].expiry_date,
              rec_item_id: dataval.pharma_price[i].rec_item_id,
              index: index,
            });
            index++;
          }
          for (var j = 0; j < dataval.batch_nos.length; j++) {
            var res = this.drugArray.filter(o => o.rec_item_id == dataval.batch_nos[j].rec_item_id);
            if (res.length != 0) {
              if (res[0].batch_no == dataval.batch_nos[j].batch_no) {
                var color = this.checkExpiryDate(res[0].expiry_date);
                drug_detailsarray.batcharray.push({
                  index: res[0].index,
                  batch_no: dataval.batch_nos[j].batch_no,
                  rec_item_id: dataval.batch_nos[j].rec_item_id,
                  mrp: res[0].price_txt,
                  quantity: res[0].quantity,
                  expiry_date: res[0].expiry_date,
                  color: color,
                });
              }

            }
          }
        }
      }, error => { }
    );
  }

  checkExpiryDate(expiryDate) {
    var exp = "";
    if (expiryDate.split("-").length != 1)
      exp = expiryDate.split("-");
    var d = new Date();
    var a = moment(exp[1] + "-" + exp[0]);
    var b = moment(d);
    var intervals: any = ['years', 'months', 'weeks', 'days'];
    var out = [];

    for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out.push(diff + ' ' + intervals[i]);
    }

    console.log("out ==> " + out);
    var expD: any = out.toString().split(",");
    var yy = expD[0].split(/\s+/g);
    console.log("yy ==> " + yy);
    var mmm = expD[1].split(/\s+/g);
    console.log("mmm ==> " + mmm);
    var subtract = moment().add(mmm[0], mmm[1].toString()).format('YYYY-MM');
    var sub1 = moment(subtract).add(yy[0], yy[1].toString()).format('YYYY-MM');
    var final = moment().add(this.productReturn, 'months').format('YYYY-MM');
    console.log("subtract ==> " + subtract + "   sub1 ==> " + sub1 + "final ==> " + final);
    var color;
    if (sub1 <= final) {
      color = "red";
    } else {
      color = "black";
    }
    return color;
  }

  getCORD(e, i) {
    this.batchFlag = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';
    document.getElementById(i).style.top = y + 'px';
    document.getElementById("pharma_tbl_overlay").style.overflowY = "hidden";
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    if (this.batchFlag == false) {
      if (this.drugDetailsArray.length >= 1) {
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          document.getElementById('drug_' + this.drugDetailsArray[i].index).style.display = 'none';
        }
      }
    } else {
      this.batchFlag = false;
    }
  }

  selectedBatch(batch, drug_detailsarray, index) {
    var checkList = this.fineDrugList(batch.batch_no, drug_detailsarray.drug_id);
    if (checkList != true) {
      this.batchFlag = false;
      drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
      drug_detailsarray.batch_no = batch.batch_no;
      if (drug_detailsarray.batch_no != "")
        drug_detailsarray.quantityDisable = false;
      for (var i = 0; i < this.drugArray.length; i++) {
        if (batch.batch_no == this.drugArray[i].batch_no) {
          if (batch.index == this.drugArray[i].index) {
            if (this.tempBatch != undefined) {
              drug_detailsarray.price = this.drugArray[i].price_txt;
             
            } else {
              drug_detailsarray.price = this.drugArray[i].price_txt;
              drug_detailsarray.per_unit = this.drugArray[i].per_unit;
              drug_detailsarray.cgst = this.drugArray[i].cgst;
              drug_detailsarray.sgst = this.drugArray[i].sgst;
              drug_detailsarray.discount = this.drugArray[i].discount;
              drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
              drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id
            }
          }
        }
      }
      drug_detailsarray.show_batcharray = false;
      document.getElementById(index).style.display = 'none';
      document.getElementById("pharma_tbl_overlay").style.overflowY = "auto";
      document.getElementById("tbl_med").style.overflowY = "auto";
    } else {
      this.toastr.error("Medicine with same batch no already added");
    }
  }

  fineDrugList(batch, drugID) {
    var getFlag = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if ((this.drugDetailsArray[i].drug_id == drugID) && (this.drugDetailsArray[i].batch_no == batch)) {
        getFlag = 1;
      }
    }
    return getFlag == 1 ? true : false;
  }

  addNewDrugDetails() {
    this.MedicneData=[];
    var index = 0;
    if (this.drugDetailsArray.length == 0) {
      this.drugDetailsArray.push({
        drug_id: "",
        type_name: "",
        drug_name: "",
        drug_disp_name: "",
        quantity: "",
        tempquantity: "",
        amount: "",
        price: "",
        discount: "",
        per_unit: "",
        prod_disc: "",
        batch_no: "",
        amt_bdgst: "",
        cgst: "",
        sgst: "",
        expiry_date: "",
        rec_item_id: "",
        index: index,
        gen_name : "",
        batch_required: { "border-color": "rgb(250, 40, 2)" },
        batcharray: "",
        retqty:"",
        show_batcharray: false,
        quantityDisable: true,
      });
    } else {
      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_name != "" && this.drugDetailsArray[i].batch_no != "" && this.drugDetailsArray[i].quantity != "") {
          index = this.drugDetailsArray[i].index + 1;
          this.drugDetailsArray.push({
            drug_id: "",
            type_name: "",
            drug_name: "",
            drug_disp_name: "",
            quantity: "",
            tempquantity: "",
            amount: "",
            price: "",
            discount: "",
            per_unit: "",
            prod_disc: "",
            batch_no: "",
            amt_bdgst: "",
            cgst: "",
            sgst: "",
            expiry_date: "",
            rec_item_id: "",
            index: index,
            gen_name : "",
            batch_required: { "border-color": "rgb(250, 40, 2)" },
            batcharray: "",
            retqty:"",
            show_batcharray: false,
            quantityDisable: true,
          });
        } else {
          //this.toastr.error(Message_data.checkDrugListEmpty);
        }
        break;
      }
    }
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);
  }

  deleteMedicine(drug_id, index) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null) {
        if (this.drugDetailsArray[i].drug_id == drug_id) {
          if (this.drugDetailsArray[i].index == index) {
            this.drugDetailsArray.splice(i, 1);
          }
        }
      }
    }
    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null)
        prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
    }
    
    if (this.drugDetailsArray.length == 0) {
      this.addNewDrugDetails();
    }
  }

  saveOrder(){
    var products=[];
    for(var i=0;i<this.drugDetailsArray.length;i++){
      if(this.drugDetailsArray[i].drug_id !=""){
        products.push(this.drugDetailsArray[i]);
      }
    }
    console.log("DRUG DETAILS "+JSON.stringify(products))
    var save_order_data = {
      transfer_id:this.transfer_id,
      pharma_id: this.pharmacyID,
      country: ipaddress.country_code,
      rec_name: this.rec_name,
      dept_id:this.department,
      issued_by:this.pharmacistID,
      order_id:this.orderdet.orderid,
      fin_year:this.orderdet.fin_year,
      medicines: products,
    };

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.saveOrderURL, save_order_data, { headers: headers }).subscribe(
      data => {
        var dataval =JSON.parse(JSON.stringify(data));
        if (dataval.key !== null && dataval.key == "1") {
          this.toastr.success("Successfully saved transfer details");
          this.pharmacyservice.sendMessage("interdeptlist");
                   
        } else {
          this.toastr.error(Message_data.orderNotPlaced);
        }
      }, error => { });
  }

  getTransferDetails(){
    var send_data={
      pharma_id: this.pharmacyID,
      order_id:this.orderdet.orderid,
      fin_year:this.orderdet.fin_year,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.fetchURL, send_data, { headers: headers }).subscribe(
      data => {
        var dataval =JSON.parse(JSON.stringify(data));
        this.transfer_id = dataval.transfer_id;
        this.department = dataval.dept_id;
        this.rec_name = dataval.rec_name;
        
        if(dataval != undefined && dataval.drug_details.length != 0){
          var count=1;
          this.drugDetailsArray=[];
          
          for(var i=0;i<dataval.drug_details.length;i++){
            this.drugDetailsArray.push({
              drug_id: dataval.drug_details[i].drug_id,
              type_name: dataval.drug_details[i].short_name,
              drug_name: dataval.drug_details[i].drug_name,
              drug_disp_name: dataval.drug_details[i].drug_name,
              quantity: dataval.drug_details[i].quantity,
              tempquantity: dataval.drug_details[i].quantity,
              batch_no: dataval.drug_details[i].batch_no,
              gen_name : dataval.drug_details[i].gen_name,
              amount: "",
              price: "",
              discount: "",
              per_unit: "",
              prod_disc: "",
              amt_bdgst: "",
              cgst: "",
              sgst: "",
              expiry_date: "",
              retqty: dataval.drug_details[i].retqty,
              rec_item_id: dataval.drug_details[i].rec_item_id,
              index: count,
              batcharray:[],
            })
            count++;
          }
          console.log("DRUG DETAILS "+JSON.stringify(this.drugDetailsArray))
        }
        this.addNewDrugDetails();
      }, error => { });
  }
}
