<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Product master</h5>
                    </div>
                    <div class="headerButtons">
                        <img style="float: right;" src="../../../assets/ui_icons/buttons/save_button.svg" 
                        class="saveimgbtn_inpatinfo" (click)="saveData()">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="margin-top: 0;">
                    <div class="header_lable">
                        Product details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">HSN No
                                    <input type="text" class="ipcss " required [(ngModel)]="hsnNO" [disabled]="newFlag" maxlength="10"  matInput onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)"/>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Product name
                                    <!-- <input type="text" class="ipcss " required [(ngModel)]="product_name" [hidden]="new_flag" matInput  />  [hidden]="exist_flag"-->
                                    <input type="text" class="ipcss " [(ngModel)]="productName" (keyup)="productNameFilter($event)" matInput [matAutocomplete]="auto10" />
                                    <mat-autocomplete #auto10="matAutocomplete">
                                        <mat-option (click)="selectItem(item)" *ngFor="let item of filteredList" value={{item}}> {{item}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Short name
                                    <input type="text" class="ipcss " [(ngModel)]="shortName" matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Generic name
                                    <input type="text" class="ipcss " [(ngModel)]="genericName" matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Medicare type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="medicareMedID" required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let medicare of mdeicareTypeArray"
                                            value={{medicare.medicare_id}}>
                                            {{medicare.medicare_name}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Product type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="productTypeID" required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let pro_type of productTypeArray" value={{pro_type.med_code}}>
                                            {{pro_type.med_name}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Category type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="categoryCtgID">
                                        <option disabled>Select</option>
                                        <option *ngFor="let category of categoryTypeArray"
                                            value={{category.category_id}}>
                                            {{category.category_name}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">UOM<br>
                                    <select disableOptionCentering class="ipcss" [(ngModel)]="uomCode" required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let uom of uomListArray" value={{uom.uom_code}}>
                                            {{uom.uom_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-11">
                                        <mat-label class="matlabel">Manufacturer<br>
                                            <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="getManufacturer()"
                                                maxlength="50" [(ngModel)]="mfg_name" matInput [matAutocomplete]="auto" />
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <mat-option (click)="manufacturer_change(manufacturer)" *ngFor="let manufacturer of manufacList" value={{manufacturer.name}}>
                                                {{manufacturer.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <!-- <select disableOptionCentering class="ipcss"
                                                [(ngModel)]="manufacturerMfgID">
                                                <option disabled>Select</option>
                                                <option *ngFor="let manufacturer of manufacList"
                                                    value={{manufacturer.mfg_id}}>{{manufacturer.name}}</option>
                                            </select> -->
                                        </mat-label>
                                    </div>
                                    <div class="col-1" style="padding: 0;">
                                        <img (click)="createNew('Manufacturer')" class="manuf_add"
                                            src="../../../assets/ui_icons/Add_icon.svg" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-11">
                                        <mat-label class="matlabel">Supplier / distributor name<br>
                                            <!-- <select disableOptionCentering class="ipcss " [(ngModel)]="suppID" required>
                                                <option disabled>Select</option>
                                                <option *ngFor="let supplier of supplierList"
                                                    value={{supplier.supp_id}}>{{supplier.name}}</option>
                                            </select> -->
                                            <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="getSuppliers()"
                                                maxlength="50" [(ngModel)]="supp_name" matInput [matAutocomplete]="auto1" />
                                            <mat-autocomplete #auto1="matAutocomplete">
                                                <mat-option (click)="supplier_change(supplier)" *ngFor="let supplier of supplierList" value={{supplier.name}}>
                                                {{supplier.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-1" style="padding: 0;">
                                        <img (click)="createNew('Supplier')" class="manuf_add"
                                            src="../../../assets/ui_icons/Add_icon.svg" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Schedule type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="scheduleType">
                                        <option disabled>Select</option>
                                        <option *ngFor="let schedule of scheduleList"
                                            value={{schedule.schedule_type_id}}>{{schedule.category}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel">Rack no<br>
                                            <select disableOptionCentering class="ipcss " [(ngModel)]="rackid" (change)="getBins()">
                                                <option disabled>Select</option>
                                                <option *ngFor="let rack of rackList"
                                                    value={{rack.rackid}}>{{rack.rackname}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel">Bin no<br>
                                            <select disableOptionCentering class="ipcss " [(ngModel)]="binid">
                                                <option disabled>Select</option>
                                                <option *ngFor="let bins of binList"
                                                    value={{bins.binid}}>{{bins.binname}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                               
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                
                            </div> -->
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel">Quantity per unit
                                            <input type="text" class="ipcss" [(ngModel)]="quantity" 
                                                matInput required />
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel">MRP
                                            <input type="text" class="ipcss" [(ngModel)]="MRP" required matInput />
                                        </mat-label>
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel">Cost price
                                            <input type="text" class="ipcss" [(ngModel)]="costPrice" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel">Selling price
                                            <input type="text" class="ipcss " [(ngModel)]="sellingPrice" matInput />
                                        </mat-label>
                                    </div>
                                    
                                </div>
                            </div> -->
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel">CGST %
                                            <input type="text" class="ipcss" [(ngModel)]="prodCenteralGST"
                                                maxlength="4" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel">SGST %
                                            <input type="text" class="ipcss" [(ngModel)]="prodStateGST" maxlength="4"
                                                matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Minimum stock
                                    <input type="text" class="ipcss " [(ngModel)]="min_qty" matInput 
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <mat-label class="matlabel">Filter products
                                        <input type="text" class="ipcss" [(ngModel)]="productNameTxt" maxlength="50"
                                            (keyup)="productFilter()" matInput />
                                    </mat-label>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="float: right;">
                                    <mat-label class="matlabel">Per page<br>
                                        <select disableOptionCentering class="ipcss perpage" [(ngModel)]="pageList"
                                            (change)="pagelistChange()">
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <table style="font-size: 13px;" id="product_table" [hidden]="productMasterAddArray.length==0"
                            class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th>HSN no</th>
                                    <th>Product name</th>
                                    <th>Product type</th>
                                    <th>Supplier</th>
                                    <th>schedule type</th>
                                    <th>Qty/Unit</th>
                                    <th>MRP</th>
                                    <th>Min qty</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let productmaster of productMasterAddArray">
                                    <td class="align_left">{{productmaster.hsn_no}}</td>
                                    <td class="align_left">{{productmaster.name}}</td>
                                    <td class="align_left">{{productmaster.product_type_desc}}</td>
                                    <td class="align_left">{{productmaster.supp_name}}</td>
                                    <td class="align_left">
                                        <span
                                            *ngIf="productmaster.schedule_type_desc != undefined ? productmaster.schedule_type_desc : ''">{{productmaster.schedule_type_desc}}</span>
                                    </td>
                                    <td>{{productmaster.per_unit}}</td>
                                    <td>{{productmaster.MRP}}</td>
                                    <td>{{productmaster.min_qty}}</td>
                                    <td style="font-size: 13px;">
                                        <a (click)="editProductMaster(productmaster.Index)" id="view"
                                            style="color: #2c7fe1;"><i class="fas fa-edit"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div [hidden]="page_navigate || productMasterAddArray.length==0" class="col-12">
                        <div class="row">
                            <div class="col-3 col-lg-4 col-md-3 col-xl-4"></div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images"
                                    (click)="pageLimitPrevious()" src="../../../assets/img/pagnation_previous.png" />
                            </div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <span class="current_font">{{currentPage}}/{{numberOfPages()}}</span>
                            </div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images"
                                    (click)="pageLimitnext()" src="../../../assets/img/pagination_next.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>