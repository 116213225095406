<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Profile Information</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="update()" class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row" style="margin: 0px;">
                    <div class="Details">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel " [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Personal information
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                        <div class="row">
                                            <div class="col-12">
                                                <img id="profile_img1" class="profileimg"
                                                    src="../../assets/img/default.jpg" style="width: 140px;" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <input type="file" class="pimg" name="filUpload" id="file"
                                                    (change)="fileChange($event)" style="width: 100% !important;">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">First Name
                                                    <input type="text" class="ipcss " disabled="true"
                                                        [(ngModel)]="clientFirstName" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Middle Name(optional)
                                                    <input type="text" class="ipcss " disabled="true"
                                                        [(ngModel)]="clientMidName" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Last Name
                                                    <input type="text" class="ipcss " disabled="true"
                                                        [(ngModel)]="clientLastName" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <mat-label class="matlabel">Gender<br>
                                                            <input type="text" class="ipcss " disabled="true"
                                                                [(ngModel)]="gender" matInput />
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-6">
                                                        <mat-label class="matlabel">DOB
                                                            <input class="ipcss " disabled="true" [(ngModel)]="dob"
                                                                matInput>
                                                        </mat-label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Phone Number
                                                    <input type="text" class="ipcss" [(ngModel)]="mobile" maxlength="15"
                                                        myNumberOnly required matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Email
                                                    <input type="text" class="ipcss " [(ngModel)]="email" maxlength="50"
                                                        required matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-9 col-md-12 col-lg-9 col-xl-9">
                                                <mat-label class="matlabel">Home Sample
                                                    <mat-button-toggle id="toggid" class="togglebtn"
                                                        [(ngModel)]="homeSample" [checked]="homeSampleFlag"
                                                        (change)="changeHomeSample($event.source.checked)">
                                                        <span *ngIf="homeSampleFlag">No</span>
                                                        <span *ngIf="!homeSampleFlag">Yes</span>
                                                    </mat-button-toggle>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="docreg_heading_withborder">Registration details
                                </p>
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Licence code
                                            <input type="text" class="ipcss " [(ngModel)]="regCode" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Year
                                            <input type="text" class="ipcss  " [(ngModel)]="regYear" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">State / Country
                                            <input type="text" class="ipcss " [(ngModel)]="regState" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion><br>
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel" [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Work location
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Hospital / Clinic name<br>
                                            <input type="text" class="ipcss " required maxlength="50"
                                                [(ngModel)]="hospitalName" (keyup)="filterHospital($event)" matInput
                                                [matAutocomplete]="auto10" />
                                            <mat-autocomplete #auto10="matAutocomplete">
                                                <mat-option (click)="selectHospital(item)" *ngFor="let item of hosArray"
                                                    value={{item}}>
                                                    {{item}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Location<br>
                                            <input type="text" class="ipcss " required maxlength="50"
                                                [(ngModel)]="hospitalLocation" (keyup)="locFilter($event)" matInput
                                                [matAutocomplete]="auto20" />
                                            <mat-autocomplete #auto20="matAutocomplete">
                                                <mat-option (click)="selectLocation(item)"
                                                    *ngFor="let item of filteredlocList" value={{item}}>
                                                    {{item}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">District / City<br>
                                            <select class="ipcss " [(ngModel)]="hospitaldistric" required
                                                (ngModelChange)="changeCity($event)">
                                                <option disabled value="">Select</option>
                                                <option *ngFor="let city of cityListArray" value={{city.city_id}}>
                                                    {{city.city_desc}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">State<br>
                                            <select class="ipcss " [(ngModel)]="hospitalState" required
                                                (ngModelChange)="changeState($event)">
                                                <option disabled value="">Select</option>
                                                <option *ngFor="let state of stateListArray" value={{state.state_id}}>
                                                    {{state.state_desc}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Country<br>
                                            <select class="ipcss " (ngModelChange)="changeSerCountry()" required
                                                [(ngModel)]="hospitalCountry">
                                                <option value="">Select</option>
                                                <option *ngFor="let country of countryListArray"
                                                    value={{country.country_desc}}>{{country.country_desc}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Zip code<br>
                                            <input class="ipcss " [(ngModel)]="hospitalZip" disabled="true" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Address 1<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hospitalAddress1"
                                                required maxlength="50">
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Address 2<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hospitalAddres2"
                                                maxlength="50" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Contact number<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hosptialNumber"
                                                required maxlength="14"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Website<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hopitalWebsite"
                                                maxlength="100" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Day<br>
                                            <select disableOptionCentering class="ipcss " [(ngModel)]="hospitalDays"
                                                required>
                                                <option disabled>Select</option>
                                                <option value="Mon - Sat">Mon - Sat</option>
                                                <option value="All Days">All Days</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Starts from</mat-label>
                                        <div class="row">
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="startFrom1"
                                                    class="ipcss _start_end">
                                                    <option *ngFor="let name of startArray" value={{name}}>
                                                        {{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="startFrom2"
                                                    class="ipcss _start_end">
                                                    <option *ngFor="let name of endArray" value={{name}}>
                                                        {{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="startFrom3"
                                                    class="ipcss _start_end">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Ends at</mat-label>
                                        <div class="row">
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="endAt1"
                                                    class="ipcss _start_end">
                                                    <option *ngFor="let name of startArray" value={{name}}>
                                                        {{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="endAt2"
                                                    class="ipcss _start_end">
                                                    <option *ngFor="let name of endArray" value={{name}}>
                                                        {{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="endAt3"
                                                    class="ipcss _start_end">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div>
                                        <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg"
                                            (click)="getWork()" class="saveimgbtn_inpatinfo" />
                                    </div>
                                </div>
                                <div class="row">
                                    <table id="card_tbl" [hidden]="workArray.length == 0"
                                        class="table table-hover table-dynamic">
                                        <thead>
                                            <tr>
                                                <th>Hospital / Clinic name</th>
                                                <th>Location</th>
                                                <th>Day</th>
                                                <th>Starts from</th>
                                                <th>Ends at</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let work of  workArray">
                                                <td>{{work.name}}</td>
                                                <td>{{work.location}}</td>
                                                <td>{{work.day_desc}}</td>
                                                <td>{{work.available_from}}</td>
                                                <td>{{work.available_to}}</td>
                                                <td>
                                                    <a
                                                        (click)="deleteWork(work.name,work.day_desc,work.available_from,work.available_to)"><img src="../../../assets/ui_icons/trash_icon.svg" alt="" style="width: 25px; height: 25px;"></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion><br>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>