import{Component, OnInit,ElementRef,ViewEncapsulation,OnDestroy,Inject} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../../app/ipaddress';
import { Helper_Class } from '../../helper_class';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdffilepopup',
  templateUrl: './pdffilepopup.component.html',
  styleUrls: ['./pdffilepopup.component.scss']
})
export class PdffilepopupComponent implements OnInit {
  public pdfurl:any;
  public pdf_flag:boolean=true;

  constructor(public santizer: DomSanitizer, public dialogRef: MatDialogRef<PdffilepopupComponent>,
    public el: ElementRef, public toastr:ToastrService) { 

  }

  ngOnInit(): void {
    this.pdfurl = this.santizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address+Helper_Class.getOldCasesheet());
    if(this.pdfurl != undefined){
      this.pdf_flag = false;
    }
  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
