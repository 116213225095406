<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Advance bill List</h5>
                    </div>
                    <div class="headerButtons">
                        <a><img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo" (click)="advCreate()"></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                        Patient details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
                                <mat-label class="matlabel">First name<br>
                                    <input matInput class="ipcss widthbilllist" type="text" required
                                        [(ngModel)]="clntName" style="margin-bottom: 10px;" (keyup)=clientFilter()
                                        (blur)="fnameToUpper()" />
                                    <div class="auto_complete_text" *ngIf="clientList.length != 0">
                                        <ul *ngFor="let item of clientList">
                                            <li>
                                                <a (click)="selectClient(item.prov_id)">{{item.firstName}}&nbsp;{{item.lastName}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
                                <mat-label class="matlabel">Middle name<br>
                                    <input matInput class="ipcss widthbilllist" type="text" [(ngModel)]="middleName"
                                        style="margin-bottom: 10px;" (blur)="mnameToUpper()" />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
                                <mat-label class="matlabel">Last name <br>
                                    <input matInput class="ipcss widthbilllist" type="text" required
                                        [(ngModel)]="lastName" style="margin-bottom: 10px;" (blur)="lnameToUpper()" />
                                </mat-label>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                <mat-label class="matlabel">Advance category<br>
                                    <select class="ipcss widthch1" [(ngModel)]="advanceCategory" required
                                        (change)="selectAdvanceCategory()">
                                        <option selected>All</option>
                                        <option>Date</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="advanceCategoryflag">
                                <mat-label class="matlabel">From date<br>
                                    <input type="date" class="ipcss_date widthbilllist datecss" required
                                        [(ngModel)]="fromDate" max="{{currentDate}}" matInput>
                                </mat-label>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="advanceCategoryflag">
                                <mat-label class="matlabel">To date<br>
                                    <input type="date" class="ipcss_date widthbilllist datecss" required
                                        [(ngModel)]="toDate" max="{{currentDate}}" matInput>
                                </mat-label>
                            </div>
                            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                <img class="saveimgbtn_inpatinfo searchButton" (click)="filter()"
                                    src="../../../assets/img/search.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="advanceList.length==0" class="dig_table_overflow">
                    <table class="card_tbl table-dynamic">
                        <thead>
                            <tr>
                                <th>Appointment ID</th>
                                <th>Client</th>
                                <th>Advance</th>
                                <th>Balance</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let advance of advanceList">
                                <td style="text-align: center;">{{advance.app_id}}</td>
                                <td class="align_left">{{advance.client}}</td>
                                <td class="align_right">{{advance.advance}}</td>
                                <td class="align_right">{{advance.balance}}</td>
                                <td>{{advance.created_date}}</td>
                                <td>{{advance.created_time}}</td>
                                <td>
                                    <img (click)="print(advance)" src="../../../assets/img/printer.svg"
                                        class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>