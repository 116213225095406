import { Component, OnInit } from '@angular/core';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import * as $ from 'jquery/dist/jquery.min.js';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-frontdesk-home',
  templateUrl: './frontdesk-home.component.html',
  styleUrls: ['./frontdesk-home.component.css']
})
export class FrontdeskHomeComponent implements OnInit {
  public sendProfileTab: any;
  public updateUrl = "usercontroller/updusr/";
  public mobileNumber = null;
  public emailId = null;
  public imgFileStr;
  public profileImg;
  public mobileStr;
  public emailStr;
  public mobile;
  public countryId;
  public email;

  public firstName;
  public middleName;
  public lastName;
  public gender;
  public dob;
  public hospitalListData = [];
  public locationListData = [];
  public locationList = [];
  public hospitalList = [];
  public hospitalMasterData = [];
  public cityList;
  public stateList;
  public countryList;
  public hospitalName: string;
  public hospitalDist;
  public hospitalLocation: string;
  public hospitalId;
  public hospitalState;
  public hospitalCountry;
  public cityUrl;
  public stateUrl;
  public countryUrl;
  public profileImage;
  public userInfo;

  constructor(public http:HttpClient,public toastr:ToastrService) { 
    this.hospitalId=0;
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
  }


  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.profileImage = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.profileInfo();
    this.sendData()
  }

  isReadonly() { return true; }

  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
       this.toastr.show(Message_data.maxImgSize);
        $("#file").val('');
        this.imgFileStr = null;
      }else {
        this.imgFileStr = myReader.result;
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  changeHospital(e) {
    this.cityList = [];
    this.stateList = [];
    this.countryList = [];
    this.locationList = [];
    this.hospitalListData = [];
    this.hospitalMasterData = [];
    this.hospitalLocation = '';
    this.hospitalDist = '';
    this.hospitalState = '';
    this.hospitalCountry = '';

    if (this.hospitalName != "" && this.hospitalName.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        {hosp_name: this.hospitalName },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            if (obj.hospitals != null) {
              this.hospitalMasterData = [];
              this.hospitalMasterData = obj.hospitals;
              for (var i = 0; i < this.hospitalMasterData.length; i++) {
                this.hospitalListData.push(this.hospitalMasterData[i].hosp_name);
              }
              this.hospitalList = this.hospitalListData.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_name_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => {});
    } else {
      this.hospitalList = [];
    }
  }

  selectHospital(data) {
    this.hospitalName = data;
    this.hospitalList = [];
  }

  changeLocation(e) {//location autopopulate
    this.hospitalDist = '';
    this.hospitalState = '';
    this.hospitalCountry = '';
    this.locationListData = [];
    this.hospitalLocation = e.target.value.toString();

    if (this.hospitalLocation !== "" && this.hospitalLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        {loc_name: this.hospitalLocation},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            if (obj.locations != null) {
              this.locationListData = obj.locations;
              this.locationList = this.locationListData.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_loc_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => {});
    } else {
      this.locationList = [];
    }
  }

  selectLocation(data) {
    this.hospitalLocation = data;
    this.locationList = [];
    this.getCity(data);
    this.stateList = [];
    this.cityList = [];
    this.countryList = [];
  }

  getCity(data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, { loc_name: data }, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj.cities != null && obj.cities.length != 0) {
          this.cityList = obj.cities;
          this.hospitalDist = this.cityList[0].city_id;
          this.changeCity(this.cityList[0].city_id);
        }
      },
      error => {});
  }

  changeCity(data) {
    if (data != null) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateUrl, { city_id: data }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj.states != null) {
            this.stateList = obj.states;
            this.hospitalState = this.stateList[0].state_id;
            this.changeState(this.stateList[0].state_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    } else {
      this.stateList = [];
      this.cityList = [];
      this.countryList = [];
    }
  }

  changeState(data) {
    if (data != null) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryUrl, { state_id: data }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj.countries != null && obj.countries.length != null) {
            this.countryList = obj.countries;
            this.countryId = this.countryList[0].country_id;
            this.hospitalCountry = this.countryList[0].country_desc;
            this.changeCountry();
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    } else {
      this.stateList = [];
      this.countryList = [];
    }
  }

  changeCountry() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospdetails/',
      {
        hosp_name: this.hospitalName,
        location: this.hospitalLocation,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.hosp_id != null)
            this.hospitalId = obj.hosp_id;
        },
        error => {});
  }

  update() {
    this.sendData();
    this.sendProfileTab = Helper_Class.getprofile_array();
    if (key == "mobile" && this.sendProfileTab[key] == undefined && this.sendProfileTab[key] == "") {
     this.toastr.error(Message_data.enterMobileno);
    } else if (key == "email" && this.sendProfileTab[key] == undefined && this.sendProfileTab[key] == "") {
     this.toastr.error(Message_data.enterEmail);
    } else if (key == "hospital" && this.sendProfileTab[key] == undefined && this.sendProfileTab[key] == "") {
     this.toastr.error(Message_data.enterHosp);
    } else {
      for (var key in this.sendProfileTab) {
        if (key == "upd_url" && this.sendProfileTab[key] != undefined) {
          this.updateUrl = "usercontroller/updusrweb/";
        } else if (key == "contact_no" && this.sendProfileTab[key] != undefined) {
          this.mobileNumber = this.sendProfileTab[key];
        } else if (key == "email" && this.sendProfileTab[key] != undefined) {
          this.emailId = this.sendProfileTab[key];
        }
      }

      var x = this.emailId;
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;

      if (!re.test(x) || this.emailId == "") {
       this.toastr.error(Message_data.validEmail);
      } else if (this.mobileNumber == "" || this.mobileNumber.length != 10) {
       this.toastr.error(Message_data.validMobileNo);
      } else if (this.mobileNumber != Helper_Class.getProfile_info().mobile) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'usercontroller/isclient',
          {
            input: this.mobileNumber,
            flag: "6"
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));

              if (obj.result == true)
               this.toastr.error(Message_data.existMobileNo);
              else 
                this.emailCheck();
            },
            error => {
             this.toastr.error(Message_data.getNetworkMessage());
            });
      } else {
        this.emailCheck();
      }
    }
  }

  emailCheck() {
    if (this.emailId != Helper_Class.getProfile_info().email) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/isclient',
        {
          input: this.emailId,
          flag: "6"
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));

            if (obj.result == true) 
             this.toastr.error(Message_data.existEmailID);
            else
              this.finalUpdate();
          },
          error => {
           this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.finalUpdate();
    }
  }

  finalUpdate() {
    var output = null;
    output = this.sendProfileTab;

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.updateUrl, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.key != null && obj.key == "1") {
           this.toastr.success(Message_data.profileUpdSuccess);
          } else {
           this.toastr.error(Message_data.profileUpdFailed);
          }
        },
        error => {
         this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  profileInfo() { // profile retrivel data
    var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/usrdet',
        {user_id: this.userInfo.user_id},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            Helper_Class.setProfile_info(obj);
            if (obj.profile_image != null) {
              this.profileImg = obj.profile_image
              try {
                var img = document.getElementById("profile_img") as HTMLImageElement;
                img.src = ipaddress.Ip_with_img_address + obj.profile_image;
              }
              catch (error) {}

              try {
                var img1 = document.getElementById("profile_img1") as HTMLImageElement;
                img1.src = ipaddress.Ip_with_img_address + obj.profile_image;
              }
              catch (error) {}
            }else {
              this.toastr.error(Message_data.getNetworkMessage());
            }

            if (obj.first_name != null) {
              this.firstName = obj.first_name;
            }
            if (obj.middle_name != null) {
              this.middleName = obj.middle_name;
            }
            if (obj.last_name != null) {
              this.lastName = obj.last_name;
            }
            if (obj.dob != null) {
              this.dob = obj.dob;
            }
            if (obj.contact_no != null) {
              this.mobile = obj.contact_no;
            }
            if (obj.email != null) {
              this.email = obj.email;
            }
            if (obj.gender != null) {
              this.gender = obj.gender;
            }

            this.hospitalName = obj.hptl_name;
            if (obj.country != null) {
              this.hospitalLocation = obj.location;
              
              this.getCity(this.hospitalLocation);
              this.sendData();
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
  }
  
  sendData() {
    var img_path = this.profileImg, upd_url = "0";
    if (this.imgFileStr != null) {
      img_path = this.imgFileStr.split(',')[1];
      upd_url = "1";
    }else {
      upd_url = undefined;
    }

    if(this.mobile != null)
      this.mobileStr = encrypt_decript.Encript(this.mobile).toString();

    if(this.email != null)
      this.emailStr = encrypt_decript.Encript(this.email).toString();
   
    var send_array = null;
    send_array = {
      upd_url: upd_url,
      profile_image: img_path,
      user_id: this.userInfo.user_id,
      contact_no: this.mobile,
      email: this.email,
      hospital:this.hospitalId,
    }
    Helper_Class.setprofile_array(send_array);
  }
}
