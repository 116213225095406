import { Component, OnInit,ElementRef, ViewChild, } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import {Helper_Class} from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { DoctorViewService } from './doctor-view.service';
import { Observable, Observer,Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../common-module/change-password/change-password.component';
import { TermsConditionComponent } from '../terms-condition/terms-condition.component';
import { ipaddress } from '../../ipaddress';
import { MasterHelperService } from '../MasterHelper.service';
import { Master_Helper } from '../Master_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from 'src/assets/js/Message_data';
import { Diet_Helper } from 'src/app/Diet_module/Diet_Helper';

declare var $ : any; 
@Component({
  selector: 'app-doctor-view',
  templateUrl: './doctor-view.component.html',
  styleUrls: ['./doctor-view.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class DoctorViewComponent implements OnInit {
  public open_flag:boolean = true;
  public hospital_logo;
  public hospital_name;
  public userinfo;
  public hospital_details;
  public profile_image;
  public first_name:string;
  public last_name:string;

  public messages;
  public msgflag:boolean=false;
 
  public notifications;
  public notifyflag:boolean=false;
  public settings:boolean = false;
  public submenu_flag: boolean = false;
  public show_submenu;
  public minimized_asied: boolean = false;
  public selectedMenu;
  appointment_data: boolean = true;
  billing_icon: boolean = true;
  Secondopinion_icon: boolean = true;
  Nurse_permssionicon: boolean = true;
  Dashboard_professional: boolean = true;
  Dashboard_basic: boolean = true;
  Homecare_icon: boolean = true;
  Case_sheeticon: boolean = true;
  case_summaryicon: boolean = true;
  noapp_data: boolean = true;
  inpat_data: boolean = true;
  mis_report: boolean = true;
  reference_video: boolean = true;

  public displayPage;
  public notificationCount;
  public schdule_submenu_flag: boolean = false;

  public CurrentDatetime;
  public tomorrow;
  public Appointment_Date;
  public currentDate;
  
  public reportsFlag:boolean = false;
  notification_flag: boolean = false;
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;
  @ViewChild('scrol_traget') public myScrollContainer: ElementRef;
  subscription: Subscription;
  public default_hosp_logo;
  public consolidatedFlag:boolean = true;
  constructor(public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,
    public messageservice:DoctorViewService,public dialog:MatDialog,public masterData:MasterHelperService,public masterCSdata:MasterCSHelperService) { 
      this.default_hosp_logo = ipaddress.Ip_with_img_address + "/gritrest/images/logos/hospital/default.png";
      this.masterData.callMasterData();
      this.masterCSdata.clearValue();
      Diet_Helper.setDietFlow(undefined);
  }

  check_scroll(){
    this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  }
  
  ngOnInit(){
    this.getCurrentDate();
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
          this.menuNav(message);
      } 
    });
    this.messageservice.listen().subscribe((m:any) => {
      if(m == "detail_view")
      {
        //this.check_scroll();
      }
    });
    this.userinfo = Helper_Class.getInfo();
    this.first_name =this.userinfo.first_name;
    this.last_name = this.userinfo.last_name;
    this.profile_image = ipaddress.Ip_with_img_address + this.userinfo.profile_image;
    this.hospital_details = Helper_Class.getHospitalInfo();
    this.hospital_name = Doc_Helper.getHospital_name();
    localStorage.setItem("hospital_name",this.hospital_name);
    this.hospital_logo = Doc_Helper.getHospital_logo();
    this.submenu_flag = false;
    this.minimized_asied = false;
    this.schdule_submenu_flag = false;
    
    // dynamic menu display
    var Moduleidlist;

    if (Doc_Helper.getModuleList() != null) {
      Moduleidlist = Doc_Helper.getModuleList();
      this.appointment_data = true;
      this.billing_icon = true;
      this.Secondopinion_icon = true;
      this.Nurse_permssionicon = true;
      this.Dashboard_professional = true;
      this.Dashboard_basic = true;
      this.Homecare_icon = true;
      this.Case_sheeticon = true;
      this.case_summaryicon = true;
      this.noapp_data = true;
      this.inpat_data = true;
      this.mis_report = true;
      this.reference_video = true;
      Doc_Helper.setCasesheet_flag("0");
      Doc_Helper.setCasesumm_flag("0");

      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "15") {
          this.appointment_data = false;
        } else if (Moduleidlist[i] == "17") {
          this.billing_icon = false;
        } else if (Moduleidlist[i] == "20") {
          this.Secondopinion_icon = false;
        } else if (Moduleidlist[i] == "18") {
          this.Nurse_permssionicon = false;
        } else if (Moduleidlist[i] == "13") {
          this.Dashboard_professional = false;
        } else if (Moduleidlist[i] == "12") {
          this.Dashboard_basic = false;
        } else if (Moduleidlist[i] == "6") {
          this.Homecare_icon = false;
        } else if (Moduleidlist[i] == "29") {
          this.Case_sheeticon = false;
          Doc_Helper.setCasesheet_flag("1");
        } else if (Moduleidlist[i] == "30") {
          this.case_summaryicon = false;
          Doc_Helper.setCasesumm_flag("1");
        } else if (Moduleidlist[i] == "37") {
          this.noapp_data = false;
        } else if (Moduleidlist[i] == "38") {
          this.inpat_data = false;
        }else if (Moduleidlist[i] == "45") {
          this.mis_report = false;
        }else if (Moduleidlist[i] == "46") {
          this.reference_video = false;
        }else if (Moduleidlist[i] == "77") {
          this.consolidatedFlag = false;
        }
      }
    }
    this.get_notification_list();   
  }

  setAppoinmentHelper(e){
    if(e == 'appinment'){
      Doc_Helper.setAppDate(this.CurrentDatetime);
    }else if(e == "billing" || e == "ipbilling"){
      Doc_Helper.setBillFromDate(this.CurrentDatetime);
      Doc_Helper.setBillToDate(this.CurrentDatetime);
    }else if(e == "homecare"){
      Doc_Helper.setHomecareDate(this.CurrentDatetime);
    }else if(e == "doctor_availability"){
      Doc_Helper.setDocAvailabitiyDate(this.CurrentDatetime);
    }
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    
    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      this.tomorrow = new Date(obj.current_date);
      this.tomorrow.setDate(this.tomorrow.getDate() + 2);
    }
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  get_notification_list() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/nc',
    {
      type: "doctor",
      user_id: this.userinfo.user_id,
    },
      { headers: headers })
      .subscribe(
        response => {
          var obj =response.json()
            if (obj.notifications != null) {
              if (obj.notifications.length != 0) {
                this.notificationCount = obj.notifications;
                this.notification_flag = true;
              }
              else{
                this.notificationCount = "0";
              }
            }
        },
        error => {});
  }

  open_setting(){
    this.settings = !this.settings;
  }

  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: "doctor",
        user: this.userinfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },
        error => {});
  }

  menuNav(page){
    let page_nmae : string = page;
    this.selectedMenu = page;
    this.submenu_flag = false;
    this.schdule_submenu_flag = false;
    this.reportsFlag = false;
    Doc_Helper.setImageFlag(false);
    switch (page_nmae) {
      case 'home': 
        this.displayPage = "dashboard";
      break;
      case 'patient': 
        this.submenu_flag = !this.submenu_flag;
        if(this.minimized_asied == true){
          this.minimizing();
        }
      break;
      case 'in_patient':  this.submenu_flag = false;this.displayPage = page; break;
      case 'patient_bill':  this.submenu_flag = false;break;
      case 'patient_dashboard': this.submenu_flag = false; this.displayPage = page;break;
      case 'appointment_list': this.displayPage = page; break;
      case 'walkin': this.displayPage = page;break ;
      case 'permission': this.displayPage = page;break ;
      case 'billing':  Doc_Helper.setIpCreateBill("opbill"); this.displayPage = page; break ;
      case 'secondopinion': this.displayPage = page;break ;
      case 'homecare': this.displayPage = page;break ;
      case 'schedule': 
        this.schdule_submenu_flag = !this.schdule_submenu_flag;
        if(this.minimized_asied == true){
          this.minimizing();
        }
      break ;
      case 'calender': this.displayPage = page;break;
      case 'doctor_availability': this.displayPage = page;break;
      case 'manage_schedule': this.displayPage = page;break;
      case 'notification': this.displayPage = page;break;
      case 'inPatientViewInfo': this.displayPage = page;break ;
      case 'cashSheetPersonal': this.displayPage = page;break ;
      case 'billcreate': this.displayPage = page;break ;
      case 'billdetail': this.displayPage = page;break ;
      case 'billedit': this.displayPage = page;break ;
      case 'inPatientDS': this.displayPage = page;break ;
      case 'inpatient_Discharge_view': this.displayPage = page;break ;
      case 'inpatient_guno_Discharge_view': this.displayPage = page;break ;     
      case 'billlist': this.displayPage = page;break ;
      case 'help': this.displayPage = page;break ;
      case 'casesheet': this.displayPage = page;break ;
      case 'secondopinion_casesheet': this.displayPage = page;break ;
      case 'profile_update': this.displayPage = page;break ;
      case 'medprescription': this.displayPage = page;break ;
      case 'ModalPage': this.displayPage = page;break ;
      case 'casesheet_list':this.displayPage = page;break;
      case 'cardio_casesheet_view':this.displayPage = page;break;
      case 'appointment_view':this.displayPage = page;break;
      case 'reference_video': this.displayPage = page;break ;
      case 'reportsMenu': 
        this.reportsFlag = !this.reportsFlag;
        if(this.minimized_asied == true){
          this.minimizing();
        }
        break ;
        case 'salseReport': this.displayPage = page;break ; 
        case 'receivablesReport': this.displayPage = page;break ; 
        case 'stockReport': this.displayPage = page;break ; 
        case 'inpatientbilling' : this.displayPage = page; break;
        case 'ipbilling' : 
          Doc_Helper.setIpCreateBill("ipbill");
        this.displayPage = page; break;
        case 'ipbillcreate' : this.displayPage = page; break;
        case 'ipcard' : this.displayPage = page; break;
        case 'consolidatedBills' : this.displayPage = page; break;
        
    }
    customjs.SelectMenuFun(this.selectedMenu,"doctor");   
  }

  minimizing(){
    this.minimized_asied = !this.minimized_asied;
    if(this.minimized_asied ==  true){
      this.asied_width.nativeElement.setAttribute('style','width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style','width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style','padding: 12px 0 0 3px;}');
      this.submenu_flag = false;
    }else{
      this.asied_width.nativeElement.setAttribute('style','width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style','width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style','padding: 17px 0 0 14px;}');
    }
  }

  onResize(event){
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimized_asied = false;
  }

  openMenumodel(){
    this.minimized_asied = false;
    customjs.openMobileMenuFun();
  }
  
  MenuModelAction(action){
    if(action == 'profile_update'){
      this.menuNav(action);
    }else if(action == 'changePassword'){
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {type:"doctor"}
      }); 
    }else if(action == 'terms_conditions'){
      const dialogRef = this.dialog.open(TermsConditionComponent, {
        width: '50%',
        height: '500px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result["key"] == 1){
        }else{
          this.toastr.error(Message_data.defaultErr);
        }
      });
    }else{
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "doctor",
          user: this.userinfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut);
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => {});
    }
    $('#exampleModal').modal('hide'); 
  }
}
