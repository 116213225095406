import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import {Time_Formate} from '../../../assets/js/common';

declare var $:any;
@Component({
  selector: 'app-billng-detail-view',
  templateUrl: './billng-detail-view.component.html',
  styleUrls: ['./billng-detail-view.component.scss']
})
export class BillngDetailViewComponent implements OnInit {
  public billId: string;
  public providerType: string;
  public provider: string;
  public billingPrintView = [];
  public cgstFlag;
  public sgstFlag;
  public billingDetialedView;
  public billPrintconcse: boolean;
  public totalFlag: boolean;
  public billAdvanceFlag: boolean;
  public prevBalance: string;
  public prevBalanceFlag: boolean;
  public mainBalanceFlag: boolean;
  public estimatePrintData;
  public treatmentPlanFlag: boolean;
  public charge = [];
  public hospAddress: boolean;
  public sendAddress: string;
  public clientAddress: string;
  public billAmount: string;
  public totalBill: string;
  public cgst: string;
  public sgst: string;
  public billingDetailList = [];
  public clientName: string;
  public doctorName: string;
  dtOptions: DataTables.Settings = {};
  public type;
  private finyear;

  constructor(public http:HttpClient,public toastr:ToastrService,  public messageservice:MenuViewService) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,] },
      ],
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    var getBillingdetails = FrontDesk_Helper.getBillsDetails();
    this.billId = getBillingdetails.bill_view_id;
    this.providerType = getBillingdetails.provider_type;
    this.provider= getBillingdetails.provider;
    this.finyear = getBillingdetails.fin_year;
    this.type = getBillingdetails.type != undefined ? getBillingdetails.type : "";
    this.hospAddress = true;
    this.billPrintconcse = true;
    this.treatmentPlanFlag = true;
    this.cgstFlag = true;
    this.sgstFlag = true;
    this.prevBalanceFlag = true;
    this.getBillDetailedView();
  }

  getBillDetailedView() {
    var headers = new HttpHeaders();
    var senddata = {
      doc_reg_id: this.provider,
      billing_id: this.billId,
      app_type: this.providerType,
      fin_year:this.finyear,
    }
    
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.bills != null) {
            if (obj.cgst != null) {
              this.cgstFlag = false;
              this.sgstFlag = false;
            }

            this.billingDetialedView = obj.bills;
            this.billPrintconcse = this.billingDetialedView != null && this.billingDetialedView[0].charge_type != null ? false : true;

            if (obj.bill_amount == undefined && obj.bill_amount == "")
              this.totalFlag = true;

            if (obj.advance == undefined || obj.advance == "") 
              this.billAdvanceFlag = true;

            if (obj.prev_balance != null) {
              this.prevBalanceFlag = false;
              this.prevBalance = parseFloat(obj.prev_balance).toFixed(2);
            } else {
              this.prevBalanceFlag = true;
            }

            if (obj.balance == undefined || obj.balance == "") 
              this.mainBalanceFlag = true;

            this.estimatePrintData = obj.estimates;
            this.treatmentPlanFlag = this.estimatePrintData != "" && this.estimatePrintData != undefined ? false : true;
            this.charge = [];
            this.hospAddress = obj.hptl_name != null ? false : true;

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(obj.created_time);

            }

            if (obj.first_name != undefined) {
              this.sendAddress = obj.address2 != null && obj.address2 != "" ? obj.address1 + ", " + obj.address2 + "," : obj.address1 + ",";

              if (obj.caddress1 != null) 
                this.clientAddress = obj.caddress2 != null && obj.caddress2 != "" ? encrypt_decript.Decript(obj.caddress1).toString() + " " + encrypt_decript.Decript(obj.caddress2).toString() + "," : encrypt_decript.Decript(obj.caddress1).toString() + ",";

              var tot_amt = "0";
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                  }
                }
              }

              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                    }
                  }
                }
              }

              this.billAmount = tot_amt;
              this.totalBill = tot_amt;
              if (obj.cgst != null) {
                this.cgst = (parseFloat(this.billAmount) * (parseFloat(obj.cgst) / 100)).toFixed(2);
                this.sgst = (parseFloat(this.billAmount) * (parseFloat(obj.sgst) / 100)).toFixed(2);
              }

              this.billAmount = encrypt_decript.Decript(obj.bill_amount);
              this.clientName = obj.middle_name != null && obj.middle_name != "" ? encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString() : encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();             
              this.doctorName = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;

              this.billingDetailList.push({
                dr_name: "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name,
                licence_code: " Reg. No: " + obj.licence_code,
                hptl_name: obj.hptl_name,
                address: this.sendAddress,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                created_date: datetxt,
                appointment_id: obj.appointment_id,
                client_name: this.clientName,
                advance: parseFloat(obj.advance).toFixed(2),
                balance: parseFloat(Math.ceil(obj.balance).toString()).toFixed(2),
                caddress: this.clientAddress,
                clocation: obj.clocation + ", ",
                clientcity: obj.ccity + " - " + encrypt_decript.Decript(obj.czipcode) + ",",
                clestare: obj.cstate + " - " + obj.ccountry + ".",
                client_reg_id: obj.client_reg_id,
              });
            }
          } else {
            this.toastr.error(Message_data.network);
          }
        },error => {
          this.toastr.error(Message_data.network);
        });
  }

  back(){
    if(this.type == "refund")
      this.messageservice.sendMessage("refund");
    else
      this.messageservice.sendMessage("frontdeskbilling");
  }
}
