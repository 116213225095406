import { Component, OnInit } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Time_Formate } from '../../../assets/js/common';

@Component({
  selector: 'app-inpat-patient-list',
  templateUrl: './inpat-patient-list.component.html',
  styleUrls: ['./inpat-patient-list.component.css']
})
export class InpatPatientListComponent implements OnInit {
  public cancel_checkbox: boolean;
  public cancel_foooter: boolean;
  public doc_reg_id;
  public hospital_id;
  public spl_name: string;
  public header_footer_flag: boolean;
  public ismobile: boolean;
  public loader: boolean;
  public nodapp: boolean;
  public patientList:any[] =[];
  public userinfo:any;
  public user_id:any;
  public first_name:string;
  public last_name:string;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public messages;
  public msgflag:boolean=false;
  public notifications;
  public notifyflag:boolean=false;
  public profile_image:string;
  public submenu_flag:boolean = true;
  public submenu_flag1:boolean =false;
  dtOptions: any = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist:any;
  checkedList:any;
  public patientType;
  public display;
  public hospital_array;
  public hptl_clinic_id;
  public hospitalFlag:boolean;
  public ynopt1:boolean = true;
  public provider;

  constructor(private http:HttpClient,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public messageservice:MenuViewService) { 
    this.cancel_checkbox = true; // hide cancel checkbox
    this.cancel_foooter = true; // hide cancel_foooter
    Doc_Helper.setClient_id(null);
    Helper_Class.setInpatientFlow(undefined);
    Doc_Helper.setClient_type(undefined);
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      iDisplayLength: 2,
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        {"type": "date", "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by Name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.userinfo= Helper_Class.getInfo();
    this.user_id=this.userinfo.user_id;
    this.doc_reg_id = this.user_id;
    this.hospital_array = Helper_Class.getHospital();
    this.hospital_id = this.hospital_array[0].hptl_clinic_id;
    this.hospitalFlag = this.hospital_array.length > 1 ? true : false;
    this.cancel_checkbox = true; // cancel checkbox
    this.cancel_foooter = true; // hide cancel_foooter
    this.patientType = "self";
    this.provider = Helper_Class.getConsultant();
    this.getPatients();   
  }

  cancel_app_click() {
    this.cancel_checkbox = true; // hide cancel checkbox
    this.cancel_foooter = true; // hide cancel_foooter
  }

  getPatients() {
    this.loader = false;
    this.patientList = [];
    var data = null;

    var send_data;
    send_data = {
      doc_reg_id: this.user_id,
      hptl_clinic_id:this.hospital_id,
      country: ipaddress.country_code.toString(),
      imei: Helper_Class.getIPAddress(),
      type: "doctor",
      spl_name:this.spl_name,
      inpat_type: this.patientType
    };

    if(this.provider != undefined){
      send_data.client_base = this.provider;
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',(send_data),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(JSON.stringify(response));
          console.log("INPATIENT LIST "+JSON.stringify(data))
          if(data.patients != undefined && data.patients.length !=0){
            this.nodapp=false;
            for(var i=0;i<data.patients.length;i++){
              var values= data.patients[i];
              var pname,fname,lname,mname;
              if(values.middle_name != undefined ){
                if(values.middle_name !=""){
                  pname = encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.middle_name)+" "+encrypt_decript.Decript(values.last_name);
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.last_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname="";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.last_name);
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }
              var ward_text;
              var wardname="";
              if(values.ward_name != undefined){
                wardname = values.ward_name;
              }

              var bedno="";
              if(values.bed_no != undefined && values.bed_no != ""){
                bedno = values.bed_no;
                ward_text = wardname +" - Bed: "+bedno;
              }else{
                ward_text = wardname
              }

              var cdate;
              if(values.created_date != undefined){
                var cdval = values.created_date.split('-');
                cdate= cdval[2]+"-"+cdval[1]+"-"+cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if(values.created_time != undefined){
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              this.patientList.push({
                'client_reg_id': values.client_reg_id,
                patient_name: pname,
                admit_date: cdate,
                admit_time: ctime,
                ward_name: wardname,
                bed_no: bedno,
                mobile:values.mobile,
                profile_image:ipaddress.Ip_with_img_address.toString()+values.profile_image,
                first_name:fname,
                last_name:lname,
                middle_name:mname,
                ward_text: ward_text,
                inpatient_id:values.inpatient_id,
                hosp_inp_id:values.hosp_inp_id,
                relation_id:values.relation_id,
                relationship_name:values.relationship_name,
              })
            }
            
            this.loader = true;

          } else {
            this.loader = true;
            this.nodapp = true;
          }
        },
        error => {
          this.loader = true;
        });
  }

  changePatientType(type){
    this.patientType = type;
    this.getPatients();
  }

  create_patient(){
    Doc_Helper.setFirstname(null);
    Doc_Helper.setMiddlename(null);
    Doc_Helper.setLastname(null);
    Helper_Class.setInpatientFlow("doctor");
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("New");
    Doc_Helper.setMobile_no(null);

    this.messageservice.sendMessage("inPatientViewInfo");
  }

  view_patient(inpatient_id,mobile, regid,array,fname,mname,lname,relid,relname){
    Doc_Helper.setFirstname(fname);
    Doc_Helper.setMiddlename(mname);
    Doc_Helper.setLastname(lname);
    Doc_Helper.setClient_id(regid);
    Doc_Helper.setRel_id(relid);
    Doc_Helper.setrelationName(relname);
    
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("Existing");
    Doc_Helper.setMobile_no(mobile);
    Doc_Helper.setInpatientId(inpatient_id);
    Doc_Helper.setInpatientFlow("list");
    Helper_Class.setInpatientFlow("doctor");
    this.messageservice.sendMessage("inPatientViewInfo");
  }

  //remove duplicates
  remove_duplicates(arr) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i]] = true;
    }
    arr = [];
    for (let key in obj) {
      arr.push(key);
    }
    return arr;
  }

  checkUncheckAll() {
    this.checklist = this.patientList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }
  
  isAllSelected() {
    this.masterSelected = this.checklist.every(function(item:any) {
        return item.isSelected == true;
    });
  }
  
  getCheckedItemList(){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected)
      this.checkedList.push(this.checklist[i].patient_id);
    }
    if(this.checklist.length == this.checkedList.length) {
      this.toastr.success('Thank you...', 'All rows are selected!');  
    }else if(this.checkedList.length != 0 ) {
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);  
    }else {
      this.checkedList = `No row seleted`;
      this.toastr.success('Thank you...', 'No row seleted!');  
    }
  }
  
  clearAll() {
    this.checklist = this.patientList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
      this.patientType = "self";
      this.getPatients();
    }else {
      this.ynopt1 = false;
      this.patientType = "others";
      this.getPatients();
    }
  }
}
