import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate, Date_Formate} from '../../../assets/js/common.js';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagnosis-report-edit',
  templateUrl: './diagnosis-report-edit.component.html',
  styleUrls: ['./diagnosis-report-edit.component.scss']
})
export class DiagnosisReportEditComponent implements OnInit {
    public reportDetail;
    public tableViewReportArray: boolean;
    public paraViewReportArray: boolean;

    public updateFlag: boolean;

    public reportType;
    public diagAppID;
    public presTestID;
    public presDiagID;

    public clientAnotherName;
    public clientGender;
    public clientAge;
    public reviewSign;
    public signFlag: boolean;
    public revieSigSection: boolean;
    public reviewFlag: boolean;
    public reviewBy;
    public sampleCollectTime;
    public reportImges;
    public dicomDoctor: boolean;
    public sequences;
    public impression;
    public getretrivelArray = [];
    public diagTestReadingArray = [];
    public diagName;
    public diagAddress;
    public diagLocation;
    public diagCityZip;
    public diagStateCon;
    public diagTelephone;
    public clentID;
    public referedBy;
    public date;
    public time;
    public Sequence;
    public Impression;
    public reportFlag;
    public Approvebtn: boolean;
    public Uploadbtn: boolean;
    public loader: boolean;
    public reportDetails;
    public testName: string;
    public subTestNameFlag:boolean = false;
    public testMethod;  
    public testsName;
    public subTestFlag:boolean;
    public createdBy;
    public culture;
    public cultureFlag:boolean = false;
    htmlString: string = '';
    modules = {
        toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // [{ 'header': 1 }, { 'header': 2 }], // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
        [{ 'direction': 'rtl' }], // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'], // remove formatting button
        // ['link', 'image'], // link and image, video
        // ['table']
        ]
    };
    editorStyle = {
        height : '300px;',
    };

    constructor(public messageservice:MenuViewService,public gservice:CommonDataService,public toastr:ToastrService, public  http:Http,public  router:Router) {
    }

    ngOnInit(): void {
        this.reportType = Diagnosis_Helper.getReportUpload().type; //navParams.get("type");
        this.diagAppID = Diagnosis_Helper.getReportUpload().diag_app_id; // navParams.get("diag_app_id");
        this.presTestID = Diagnosis_Helper.getReportUpload().pres_test_id;  //navParams.get("pres_test_id");
        this.presDiagID = Diagnosis_Helper.getReportUpload().pres_diag_id; // navParams.get("pres_diag_id");
        this.reportFlag = Diagnosis_Helper.getReportUpload().Diag_report_flag; // navParams.get("Diag_report_flag");
        this.testName = Diagnosis_Helper.getReportUpload().test_name; // navParams.get("test_name");
        this.loader = true;
        this.getReportDetails();

        if (this.reportFlag == "Approval") {
            this.Approvebtn = false;
            this.Uploadbtn = true;
        } else {
            this.Approvebtn = true;
            this.Uploadbtn = false;
        }

        if (this.reportType == "blood" || this.reportType == "urine" || this.reportType == "faeces") {
            this.tableViewReportArray = false;
            this.paraViewReportArray = true;

        } else {// Scan == X-ray == Ultra sound
            this.tableViewReportArray = true;
            this.paraViewReportArray = false;
            this.updateFlag = this.reportType == "Approval" ? false : true;
            this.cultureFlag = this.reportType == "Culture" ? true : false;
        }
        this.clientGender = "0";
        this.clientAge = "0";
    }

    getReportDetails() {//Get report details
        this.loader = false;
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'record/diagreportview/',
            {
                flag: "diagnosis",
                country: ipaddress.country_code,
                diag_app_id: this.diagAppID,
                type: this.reportType,
                pres_test_id: this.presTestID
            },
            { headers: headers })
            .subscribe(
                response => {
                    
                    var obj =response.json();
                    if (obj != null) {
                        this.loader = true;
                        this.diagName = obj.diag_centre_name;
                        this.diagAddress = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;

                        if (obj.date_entered != undefined)
                            this.date = Date_Formate(obj.date_entered);

                        if (obj.time_entered != undefined) 
                            this.time = Time_Formate(obj.time_entered);

                        this.diagLocation = obj.location;
                        this.diagCityZip = obj.city + "-" + obj.zipcode;
                        this.diagStateCon = obj.state + "-" + obj.country;
                        this.diagTelephone = obj.telephone;
                        this.clentID = obj.client_reg_id;

                        if (obj.first_name != null) {
                            if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "") 
                                this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                            else 
                                this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                        }

                        if (obj.doc_middle_name != undefined && obj.doc_middle_name != null) 
                            this.referedBy = obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
                        else
                            this.referedBy = obj.doc_first_name != undefined && obj.doc_first_name != null ? obj.doc_first_name + " " + obj.doc_last_name : "";

                        if (obj.gender != null)
                            this.clientGender = encrypt_decript.Decript(obj.gender);

                        if (obj.age != null)
                            this.clientAge = obj.age == 0 ? "<1" : obj.age;

                        if (obj.sign != undefined && obj.sign != "") {
                            this.reviewSign = "data:image/png;base64," + obj.sign;
                            this.signFlag = false;
                        } else 
                            this.signFlag = true;

                        if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
                            this.revieSigSection = true;

                        this.reviewFlag = obj.app_first_name != null ? false : true;
                        this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;

                        if (obj.time_collected != null) 
                            this.sampleCollectTime = Time_Formate(obj.time_collected);

                        if (obj.main_tests != null && obj.main_tests.length != 0) {
                            if (obj.main_tests[0].image != null) {
                                this.reportDetails = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                                this.reportImges = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                                this.dicomDoctor = false;
                            }else{
                                this.dicomDoctor = true;
                            }

                            for (var i = 0; i < obj.main_tests.length; i++) {
                                if(obj.main_tests[i].test_name == this.testName){
                                    this.presTestID = obj.main_tests[i].pres_test_id;
                                    this.sequences = obj.main_tests[i].sequences;
                                    this.impression = obj.main_tests[i].impression;
                                    this.getretrivelArray = [];

                                    if(obj.main_tests[i].test_method != undefined)
                                        this.testMethod = obj.main_tests[i].test_method;

                                    if(obj.main_tests[i].created_by != undefined)
                                        this.createdBy = obj.main_tests[i].created_by;

                                    this.testsName = obj.main_tests[i].test_name;

                                    if (obj.main_tests[i].subtests != null) {
                                        for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                                            var temp_value = "";
                                            var test_method = "";

                                            this.subTestNameFlag = obj.main_tests[i].subtests[j].sub_test_name == this.testsName ? false : true;

                                            if(obj.main_tests[i].subtests[j].range != undefined)
                                                temp_value = obj.main_tests[i].subtests[j].range;

                                            if(obj.main_tests[i].subtests[j].test_method != undefined){
                                                test_method = obj.main_tests[i].subtests[j].test_method;
                                                this.subTestFlag = true;
                                            }else{
                                                this.subTestFlag = false;
                                            }

                                            this.getretrivelArray.push({
                                                sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                                reading: obj.main_tests[i].subtests[j].reading,
                                                unit: obj.main_tests[i].subtests[j].sub_uom,
                                                test_range: temp_value,
                                                sub_test_list_id: obj.main_tests[i].subtests[j].sub_test_list_id,
                                            });
                                        }
                                    }

                                    this.diagTestReadingArray.push({
                                        test_name: obj.main_tests[i].test_name,
                                        sub_test_list: this.getretrivelArray
                                    });

                                }

                                if (obj.main_tests[i].image != undefined) {
                                    this.dicomDoctor = false;
                                    this.reportDetails = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                    this.reportImges = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                }else{
                                    this.dicomDoctor = true;
                                }

                                if(obj.main_tests[i].sequences != undefined)
                                    this.Sequence = obj.main_tests[i].sequences;

                                if(obj.main_tests[i].impression != undefined)
                                    this.Impression = obj.main_tests[i].impression;

                                if(obj.main_tests[i].culture != undefined)
                                    this.culture = obj.main_tests[i].culture;
                            }
                        }
                    }
                },error => {
                    this.toastr.error(Message_data.getNetworkMessage());
                });
    }

    updateReport() {
        var send_object
        if(this.cultureFlag == true){
            send_object = {
                diag_app_id: this.diagAppID,
                test_type: this.reportType,
                test_id: this.presTestID,
                culture: this.culture,
                sub_tests: this.getretrivelArray,
            };
        }else{
            send_object = {
                diag_app_id: this.diagAppID,
                test_type: this.reportType,
                test_id: this.presTestID,
                sequences: this.Sequence,
                impression: this.Impression,
                sub_tests: this.getretrivelArray,
            };
        }

        
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/udreport/", send_object, { headers: headers }).subscribe(
            data => {
                var obj = data.json();
                
                if (obj != null && obj.key == "1") {
                    this.toastr.success(Message_data.rptUploaddSuccess);
                    this.messageservice.sendMessage("rejected");
                } else {
                    this.toastr.error(Message_data.unableToUpdRpt);
                }
            });
    }

    back_Nav() {
        this.messageservice.sendMessage("rejected");
    }

    Document_click() {
    }

    onSelectionChanged = (event) => {}
    onContentChanged = (event) => {
        this.Sequence = event.html;
        
    }
    onContentChanged2 = (event) => {
        this.Impression = event.html;
    }
    editorCreated(quill: any) {}    
}
