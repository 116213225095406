<div class="Details">
    <div class="header">
        <img *ngIf="!medisaveFlag && !cardview_flag && !client && !inpatientFlag" class="video"
            src="../../../assets/ui_icons/video_icons.svg" (click)="video()" />
        <a *ngIf="!medisaveFlag && !cardview_flag && !client && !inpatientFlag" (click)="openRx()"><img data-bs-toggle="modal"
                data-bs-target="#noRxModal" src="../../../assets/ui_icons/buttons/No_Rx_button.svg" class="saveimgbtn"
                style="margin: 0;margin-right: 10px;" /></a>
        <img *ngIf="oldcaseFlag && !cardview_flag && !client" src="../../../assets/ui_icons/buttons/scan_button.svg"
            (click)="viewOldCasesheet()" width="85px" />
        <img *ngIf="!medisaveFlag && !cardview_flag && !client" src="../../../assets/ui_icons/buttons/Back_button.svg"
            (click)="backFunction()" width="85px" />
    </div>
    <div class="row" style="margin: 0px;">
        <div *ngIf="cardview_flag" id="casesheet_slider_cover">
            <owl-carousel-o *ngIf="casesheet_flag == '1'" [options]="customOptions">
                <ng-template carouselSlide *ngFor="let user of cards;let i = index">
                    <div class="slide">
                        <div class="cu_slider_cover">
                            <!-- <mdb-card class="m-2 view rgba-white-slight waves-light card"
                                (click)="viewRecord(user, i,'card_'+i)"> -->

                            <div class="card_border1" (click)="viewRecord(user, i,'card_'+i)">
                                <div [ngSwitch]="user">
                                    <div *ngSwitchCase="'Personal'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/personal.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Medical Prescription'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/medical.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Diagnosis Prescription'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/diagnosis.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Habitual History'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/habitual.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Family Details'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/slider/family_details.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Update Readings'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/update.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Readings'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/readings.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Foot Assessment'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/slider/foot_assessment.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Complications'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/complications.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Treatment Plan'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/treatment_plan.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Diet Plan'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/diet_plan.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Discharge / Case Summary'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/discharge_summary.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Surgical'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/general_surgery.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Obstetrics'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/obstetric_history.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Clinical Exam'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/clinical_examination.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Pregnancy & Delivery'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/pregnency_delivery.svg" alt="Card image cap">
                                    </div>

                                    <div *ngSwitchCase="'Vitals'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/vitals.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Head & Cardiovascular'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/Head_Cardiovascular.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Development'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/pediatric_development.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Immunization'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/immunization.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Physical'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/personal.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Reports'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/reports.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Examination'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/clinical_examination.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Speciality'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/dental_speciality.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Material Order'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/dental_material.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Surgery'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/general_surgery.svg" alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Diet Tracking'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/Diet_tracker.svg" alt="Card image cap">
                                    </div>
                                </div>
                            </div>
                            <a tabindex="0">
                                <div class="mask">
                                </div>
                            </a>
                            <!-- </mdb-card> -->
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
            <div *ngIf="casesheet_flag != '1'" class="sliderTwo">
                <owl-carousel-o [options]="customOptions1">
                    <ng-template carouselSlide *ngFor="let user of cards;let i = index">
                        <div class="slide">
                            <div class="cu_slider_cover">
                                <!-- <mdb-card class="m-2 view rgba-white-slight waves-light card"
                                (click)="viewRecord(user, i,'card_'+i)"> -->

                                <div class="card_border1" (click)="viewRecord(user, i,'card_'+i)">
                                    <div [ngSwitch]="user">
                                        <div *ngSwitchCase="'Personal'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/personal.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Medical Prescription'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/medical.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Diagnosis Prescription'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/diagnosis.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Habitual History'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/habitual.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Family Details'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/family_details.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Update Readings'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/update.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Readings'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/readings.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Foot Assessment'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/foot_assessment.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Complications'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/complications.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Treatment Plan'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/treatment_plan.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Diet Plan'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/diet_plan.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Discharge / Case Summary'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/discharge_summary.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Surgical'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/general_surgery.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Obstetrics'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/obstetric_history.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Clinical Exam'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/clinical_examination.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Pregnancy & Delivery'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/pregnency_delivery.svg"
                                                alt="Card image cap">
                                        </div>

                                        <div *ngSwitchCase="'Vitals'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/vitals.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Head & Cardiovascular'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/Head_Cardiovascular.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Development'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/pediatric_development.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Immunization'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/immunization.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Physical'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/personal.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Reports'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/reports.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Examination'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/clinical_examination.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Speciality'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/dental_speciality.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Material Order'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/dental_material.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Surgery'">
                                            <img class="img-fluid"
                                                src="../../../assets/images/img_new/slider/general_surgery.svg"
                                                alt="Card image cap">
                                        </div>
                                        <div *ngSwitchCase="'Diet Tracking'">
                                            <img class="img-fluid" src="../../../assets/images/img_new/slider/Diet_tracker.svg"
                                                alt="Card image cap">
                                        </div>
                                    </div>
                                </div>
                                <a tabindex="0">
                                    <div class="mask">
                                    </div>
                                </a>
                                <!-- </mdb-card> -->
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
        <div class="">
            <div class="col-12 container-fluid p-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" [id]="'cardindex_'+i"
                        *ngFor="let user of cards;let i = index">
                        <!-- Card -->

                        <mdb-card class="mb-4 view card_border" [id]="'card_'+i" mdbwaveseffect style="height: 290px;">
                            <mdb-card-header class="text-center primary-color white-text">
                                <div [ngSwitch]="user">
                                    <div *ngSwitchCase="'Personal'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/personal.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Medical Prescription'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/medical.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Diagnosis Prescription'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/diagnosis.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Habitual History'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/habitual.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Family Details'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/family_details.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Update Readings'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/update.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Readings'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/readings.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Foot Assessment'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/foot_assessment.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Complications'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/complications.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Treatment Plan'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/treatment_plan.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Diet Plan'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/diet_plan.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Discharge / Case Summary'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/discharge_summary.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Surgical'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/general_surgery.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Obstetrics'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/Obestetric_history.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Clinical Exam'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/clinical_examination.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Pregnancy & Delivery'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/pregnency_delivery.svg"
                                            class="details_card" alt="">
                                    </div>

                                    <div *ngSwitchCase="'Vitals'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/bmiandvitals.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Head & Cardiovascular'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/Head_Cardiovascular.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Development'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/development.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Immunization'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/immunization.svg" class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Physical'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/personal.svg" class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Reports'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/reports.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Examination'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/clinical_examination.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Speciality'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/dental_speciality.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Material Order'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/dental_material.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Surgery'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/general_surgery.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Diet Tracking'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/Diet_tracker_white.svg"
                                            class="details_card" alt="">
                                    </div>
                                </div>

                                <h5 class="m-0" style="font-size: 14px; text-align: left;padding: 2px 0px 0px 32px;">
                                    <span *ngIf="(user == 'Medical Prescription') || (user == 'Diagnosis Prescription')"><span
                                        *ngIf='!openMedical'>{{ user }}</span><span *ngIf='openMedical'>New prescription</span>
                                    </span> 
                                    <span *ngIf="(user != 'Medical Prescription') && (user != 'Diagnosis Prescription')">{{user }}</span> 
                                </h5>

                                <div *ngIf="cardview_flag">
                                    <a *ngIf="Diabetes_flag && !medisaveFlag && !client && save_flag" (click)="save_diab()"><span
                                            class="cardsave"><img src="../../../assets/img/save.svg"
                                                style="width:25px" /></span> </a>

                                    <a *ngIf="cardio_flag && !medisaveFlag && !client && save_flag" (click)="save_cardio()"><span
                                            class="cardsave"><img src="../../../assets/img/save.svg"
                                                style="width:25px" /></span> </a>

                                    <a *ngIf="gastro_flag && !medisaveFlag && !client && save_flag" (click)="save_gastro()"><span
                                        class="cardsave"><img src="../../../assets/img/save.svg" style="width:25px" /></span> </a>

                                    <a *ngIf="Gynecology_flag && !medisaveFlag && !client && save_flag" (click)="save_gyno()"><span
                                            class="cardsave"><img src="../../../assets/img/save.svg" style="width:25px" /></span> 
                                    </a>

                                    <a *ngIf="Pediatrics_flag && !medisaveFlag && !client && save_flag" (click)="save_pedia()"><span
                                            class="cardsave"><img src="../../../assets/img/save.svg" style="width:25px" /></span> 
                                    </a>

                                    <a *ngIf="Nephrology_flag && !medisaveFlag && !client && save_flag" (click)="save_nephro()">
                                        <span class="cardsave"><img src="../../../assets/img/save.svg" style="width:25px" /></span> 
                                    </a>

                                    <a *ngIf="Others_flag && !medisaveFlag && !client && save_flag" (click)="save_others()">
                                        <span class="cardsave"><img src="../../../assets/img/save.svg" style="width:25px" /></span> 
                                    </a>

                                    <a *ngIf="Dental_flag && !medisaveFlag && !client && save_flag" (click)="save_dental()"><span
                                            class="cardsave"><img src="../../../assets/img/save.svg"
                                                style="width:25px" /></span> </a>

                                    <a *ngIf="General_flag && !medisaveFlag && !client && save_flag" (click)="save_general()"><span
                                        class="cardsave"><img src="../../../assets/img/save.svg" style="width:25px" /></span> 
                                    </a>
                                    <a *ngIf="ENT_flag && !medisaveFlag && !client && save_flag" (click)="save_ent()"><span
                                            class="cardsave"><img src="../../../assets/img/save.svg"
                                                style="width:25px" /></span> </a>

                                    
                                    <a (click)="opencard(userlist.patient_id,user,i,'card_'+i,false)"
                                        class="white-text">
                                        <span class="closeicon"><img src="../../../assets/img/close_icon.png"
                                                alt="Card image cap" style="width:25px"> </span>
                                        <!-- <mdb-icon fas icon="cancel"></mdb-icon> -->
                                    </a>
                                </div>
                            </mdb-card-header>
                            <!--Card content-->
                            <mdb-card-body>
                                <div *ngIf="speclization_arry == 'Cardiology'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-casesheet-personal></app-casesheet-personal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">
                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Clinical Exam'">
                                        <app-clinical-exam></app-clinical-exam>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                       <app-new-dietplan></app-new-dietplan>
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <div *ngIf="speclization_arry == 'Gastroenterology'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-casesheet-personal></app-casesheet-personal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">
                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Clinical Exam'">
                                        <app-gastro-clinical-exam></app-gastro-clinical-exam>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                       <app-new-dietplan></app-new-dietplan>
                                    </div> 
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div> 
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <!-- Gynecology -->
                                <div *ngIf="speclization_arry == 'Gynecology'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-gyn-personal></app-gyn-personal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">

                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Clinical Exam'">
                                        <app-gyn-clinical-exam></app-gyn-clinical-exam>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                        <!-- <app-card-dietplan></app-card-dietplan> -->
                                        <app-new-dietplan></app-new-dietplan>
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Obstetrics'">
                                        <app-obstetric-history></app-obstetric-history>
                                    </div>
                                    <div *ngIf="user == 'Surgical'">
                                        <app-gyn-surgical-history></app-gyn-surgical-history>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <!-- Pediatrics -->
                                <div *ngIf="speclization_arry == 'Pediatrics'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-pediatrics-personal></app-pediatrics-personal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">

                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Head & Cardiovascular'">
                                        <app-pediatrics-head-cardiovascular></app-pediatrics-head-cardiovascular>
                                    </div>

                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Immunization'">
                                        <app-pediatrics-immunization></app-pediatrics-immunization>
                                    </div>
                                    <div *ngIf="user == 'Development'">
                                        <app-pediatrics-development></app-pediatrics-development>
                                    </div>
                                    <div *ngIf="user == 'Pregnancy & Delivery'">
                                        <app-pediatrics-pregnancy-delivery></app-pediatrics-pregnancy-delivery>
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-pediatrics-vitals></app-pediatrics-vitals>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <!-- Nephrology -->
                                <div *ngIf="speclization_arry == 'Nephrology'">
                                    <div *ngIf="user == 'Physical'">
                                        <app-nephrology-physical></app-nephrology-physical>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">

                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                        <app-new-dietplan></app-new-dietplan>
                                        <!-- <app-card-dietplan></app-card-dietplan> -->
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-nephrology-diagnosis></app-nephrology-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Readings'">
                                        <app-nephrology-diag-graph></app-nephrology-diag-graph>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-nephrology-bmi-vitals></app-nephrology-bmi-vitals>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <!-- Dental -->
                                <div *ngIf="speclization_arry == 'Dental'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-casesheet-personal></app-casesheet-personal>
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">

                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Examination'">
                                        <app-dentalexamination></app-dentalexamination>
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <!-- <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div> -->
                                    <div *ngIf="user == 'Speciality'">
                                        <app-dental-specality></app-dental-specality>
                                    </div>
                                    <div *ngIf="user == 'Material Order'">
                                        <app-materialorder></app-materialorder>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-dentalplantab></app-dentalplantab>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-dental-report></app-dental-report>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>

                                </div>

                                <!-- Diabetes -->
                                <div *ngIf="speclization_arry == 'Diabetes'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-casesheet-personal></app-casesheet-personal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">

                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                        <!-- <app-card-dietplan></app-card-dietplan> -->
                                        <app-new-dietplan></app-new-dietplan>
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Update Readings'">
                                        <app-uploadtab></app-uploadtab>
                                    </div>
                                    <div *ngIf="user == 'Readings'">
                                        <app-readingtab></app-readingtab>
                                    </div>
                                    <div *ngIf="user == 'Foot Assessment'">
                                        <app-footassessment></app-footassessment>
                                    </div>
                                    <div *ngIf="user == 'Complications'">
                                        <app-complication></app-complication>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <!-- General -->
                                <div *ngIf="speclization_arry == 'General'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-general-personal></app-general-personal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">

                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                        <app-new-dietplan></app-new-dietplan>
                                        <!-- <app-card-dietplan></app-card-dietplan> -->
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <!-- General Surgery-->
                                <div *ngIf="speclization_arry == 'General surgery'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-general-personal></app-general-personal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">

                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                        <app-new-dietplan></app-new-dietplan>
                                        <!-- <app-card-dietplan></app-card-dietplan> -->
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Surgery'">
                                        <app-general-surgery></app-general-surgery>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <!-- ENT -->
                                <div *ngIf="speclization_arry == 'ENT'">
                                    <div *ngIf="user == 'Personal'">
                                        <app-entpersonal></app-entpersonal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diagnosis Prescription'">

                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                        <app-new-dietplan></app-new-dietplan>
                                        <!-- <app-card-dietplan></app-card-dietplan> -->
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Clinical Exam'">
                                        <app-ent-clinical-exam></app-ent-clinical-exam>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>

                                <!-- Others -->
                                <div *ngIf="Others_flag">
                                    <div *ngIf="user == 'Personal'">
                                        <app-others-personal></app-others-personal>
                                    </div>
                                    <div *ngIf="user == 'Medical Prescription'">
                                        <app-med-diag-prescription></app-med-diag-prescription>
                                    </div>

                                    <div *ngIf="user == 'Diagnosis Prescription'">
                                        <app-diag-prescription></app-diag-prescription>
                                    </div>
                                    <div *ngIf="user == 'Clinical Exam'">
                                        <app-others-clinical-exam></app-others-clinical-exam>
                                    </div>
                                    <div *ngIf="user == 'Diet Plan'">
                                        <app-new-dietplan></app-new-dietplan>
                                        <!-- <app-card-dietplan></app-card-dietplan> -->
                                    </div>
                                    <div *ngIf="user == 'Vitals'">
                                        <app-vitals></app-vitals>
                                    </div>
                                    <div *ngIf="user == 'Habitual History'">
                                        <app-habitualinfo></app-habitualinfo>
                                    </div>
                                    <div *ngIf="user == 'Family Details'">
                                        <app-family-history></app-family-history>
                                    </div>
                                    <div *ngIf="user == 'Treatment Plan'">
                                        <app-diagnosis></app-diagnosis>
                                    </div>
                                    <div *ngIf="user == 'Reports'">
                                        <app-reports></app-reports>
                                    </div>
                                    <div *ngIf="user == 'Discharge / Case Summary' && d_flag == true">
                                        <app-discharge-summary></app-discharge-summary>
                                    </div>
                                    <div *ngIf="user == 'Surgery' && speclization_arry == 'Orthopedic'">
                                        <app-general-surgery></app-general-surgery>
                                    </div>
                                    <div *ngIf="user == 'Diet Tracking'">
                                        <app-casesheet-diet-tracking></app-casesheet-diet-tracking>
                                    </div>
                                </div>
                            </mdb-card-body>
                            <a *ngIf="!cardview_flag" (click)="opencard(userlist.patient_id,user,i,'card_'+i,true)">
                                <!-- <a> -->
                                <div class="mask waves-effect waves-light" mdbWavesEffect></div>
                            </a>
                        </mdb-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="noRxModal" tabindex="-1"
        aria-labelledby="noRxModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content" style="border-radius: 20px !important;top: 166px;">
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 d-flex align-items-end">
                                <p class="noRx_content">Are you sure of closing this appointment without any
                                    prescription?</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: none !important;">
                    <div class="closeoption"><span (click)="close_appointment('yes')" class="noRx_yes_no">Yes</span>
                    </div>
                    <div class="closeoption"><span (click)="close_appointment('no')" class="noRx_yes_no">No</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>