<app-header></app-header>
<div class="banner-section-bg d-flex flex-column justify-content-center">
   <div class="px-5 ">
      <h1 class="banner-head text-center pt-5"> Nutrition Management System</h1>
   </div>
</div>
<div class="pt-5 pb-5">
   <div class="container">
      <div class="row">
         <div class="col-12 col-md-4">
            <img src="../../../assets/images/solutions/schedule.png" class="hosp-img">
         </div>
         <div class="col-12 col-md-7">
            <p class="para">Tervys AI based nutrition management system utilizes artificial intelligence algorithms to
               assist individuals in managing their diet and nutrition. It helps users make informed decisions about
               what, when, and how much to eat based on their specific health goals and nutritional needs.

               <br>
               <br>
               The system processes extensive nutrition-related data, including food composition, individual dietary preferences, and health metrics like weight, BMI, and blood glucose levels. It then uses advanced algorithms and machine learning to analyze this data and generate tailored recommendations for the individual.
            </p>
         </div>
      </div>
   </div>
</div>
<div class="wcu-section pt-5 pb-5" id="wcuSection">
   <div class="container">
      <div class="row justify-content-center">
         <div class="text-center pt-3 pb-3">
            <h1 class="wcu-heading" style="font-size: 30px;">Key features and benefits of Tervys AI nutrition management system
            </h1>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/data-input.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Data input</h2>
               <p class="wcu-para">The system requires access to diverse data on the individual's diet, including food logs, dietary preferences, and health metrics.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/data-process.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Data processing</h2>
               <p class="wcu-para">The system uses algorithms to analyze data, identifying patterns and relationships relevant to the individual's nutritional needs.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/dietplan.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3"> Dietary planning</h2>
               <p class="wcu-para">The system generates personalized meal plans based on analyzed data, considering the individual's dietary preferences, health goals, and nutritional needs.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/nutrition-track.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Nutritional tracking</h2>
               <p class="wcu-para">The system helps individuals track their food intake and nutrient intake, providing
                  feedback on their progress towards their health goals.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/wearables.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Wearable device integration</h2>
               <p class="wcu-para">The system integrates with wearable devices like fitness trackers, smartwatches, and glucose monitors to provide real-time feedback.</p>
            </div>
         </div>
         <!-- <div class="text-center pt-3 pb-3">
            <p class="para">Our AI nutrition management system enhances adherence to dietary recommendations, improves management of chronic conditions like diabetes and heart disease, and promotes better overall health outcomes. However, challenges include ensuring data privacy and security, addressing bias and fairness, and ensuring recommendations are evidence-based and aligned with current nutritional guidelines.</p>
         </div> -->
      </div>
   </div>
</div>

<app-footer></app-footer>