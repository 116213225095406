import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-outsource-test-map',
  templateUrl: './outsource-test-map.component.html',
  styleUrls: ['./outsource-test-map.component.scss']
})
export class OutsourceTestMapComponent implements OnInit {
  public diagid:string;
  public test_flag: boolean;

  public culturetest: boolean;
  public faecestest: boolean;
  public urinetest: boolean;
  public biopsytest: boolean;
  public xraytest: boolean;
  public ultratest: boolean;
  public scantest: boolean;
  public bloodtest: boolean;
  public checked:boolean;



  public subtestcname:any=[];
  public subtestfname:any=[];
  public subtesturname:any=[];
  public subtestbname:any=[];
  public subtestxname:any=[];
  public subtestuname:any=[];
  public subtestsname:any=[];
  public subtestname:any=[];
  public testtypename:any=[];
  public all_tests:any=[];

  public blood_test_head;
  public blood_test_content;
  public scan_test_head;
  public scan_test_content;
  public us_test_head;
  public us_test_content;
  public xray_test_head;
  public xray_test_content;
  public bio_test_content;
  public bio_test_head;
  public ut_test_content;
  public ut_test_head;
  public ft_test_head;
  public ft_test_content;
  public culture_test_head;
  public culture_test_content;
  public diag_centre_id:string;
  public oursource:any=[];

  public blood_test:any=[];
  public urine_test:any=[];
  public feace_test:any=[];
  public scan_test:any=[];
  public xray_test:any=[];
  public ultra_test:any=[];
  public culture_test:any=[];
  public biopsy_test:any=[];

  constructor(public messageservice:MenuViewService ,public toastr: ToastrService,public http: HttpClient) {  

  }

  ngOnInit(): void {
    this.diag_centre_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.oursource=Helper_Class.getOutsource();

    this.getDiagTests();
  }

  getDiagTests(){
    this.all_tests = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/',
      {
        diag_centre_id: this.diag_centre_id,
        outsource:true,
        outsourceid:this.oursource.outsource_id
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.diag_tests.length == 0) {
            this.test_flag = true;
          } else {
            this.test_flag = false;
          }

          this.all_tests = obj.diag_tests;
          for (var k = 0; k < this.all_tests.length; k++) {
            this.testtypename[k] = this.all_tests[k].test_type_name;
            for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
              this.all_tests[k].sub_tests[j] = {
                test_id: this.all_tests[k].sub_tests[j].test_id,
                test_name: this.all_tests[k].sub_tests[j].test_name,
                checked: false
              }
            }
          }
          
          if(obj.blood !=undefined && obj.blood.length !=0){
            this.blood_test = obj.blood;
            for(var b=0;b<obj.blood.length;b++){
              for (var k = 0; k < this.all_tests.length; k++) {
                for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
                  if(obj.blood[b].test_id == this.all_tests[k].sub_tests[j].test_id){
                    this.all_tests[k].sub_tests[j].checked = true;
                    this.all_tests[k].sub_tests[j].price = obj.blood[b].price;
                  }
                }
              }
            }
          }

          if(obj.urine !=undefined && obj.urine.length !=0){
            this.urine_test = obj.urine;
            for(var b=0;b<obj.urine.length;b++){
              for (var k = 0; k < this.all_tests.length; k++) {
                for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
                  if(obj.urine[b].test_id == this.all_tests[k].sub_tests[j].test_id){
                    this.all_tests[k].sub_tests[j].checked = true;
                    this.all_tests[k].sub_tests[j].price = obj.blood[b].price;
                  }
                }
              }
            }
          }

          if(obj.faeces !=undefined && obj.faeces.length !=0){
            this.feace_test = obj.faeces;
            for(var b=0;b<obj.faeces.length;b++){
              for (var k = 0; k < this.all_tests.length; k++) {
                for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
                  if(obj.faeces[b].test_id == this.all_tests[k].sub_tests[j].test_id){
                    this.all_tests[k].sub_tests[j].checked = true;
                    this.all_tests[k].sub_tests[j].price = obj.blood[b].price;
                  }
                }
              }
            }
          }

          if(obj.scan !=undefined && obj.scan.length !=0){
            this.scan_test = obj.scan;
            for(var b=0;b<obj.scan.length;b++){
              for (var k = 0; k < this.all_tests.length; k++) {
                for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
                  if(obj.scan[b].test_id == this.all_tests[k].sub_tests[j].test_id){
                    this.all_tests[k].sub_tests[j].checked = true;
                    this.all_tests[k].sub_tests[j].price = obj.blood[b].price;
                  }
                }
              }
            }
          }

          if(obj.xray !=undefined && obj.xray.length !=0){
            this.xray_test = obj.xray;
            for(var b=0;b<obj.xray.length;b++){
              for (var k = 0; k < this.all_tests.length; k++) {
                for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
                  if(obj.xray[b].test_id == this.all_tests[k].sub_tests[j].test_id){
                    this.all_tests[k].sub_tests[j].checked = true;
                    this.all_tests[k].sub_tests[j].price = obj.blood[b].price;
                  }
                }
              }
            }
          }

          if(obj.ultra !=undefined && obj.ultra.length !=0){
            this.ultra_test = obj.ultra;
            for(var b=0;b<obj.ultra.length;b++){
              for (var k = 0; k < this.all_tests.length; k++) {
                for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
                  if(obj.ultra[b].test_id == this.all_tests[k].sub_tests[j].test_id){
                    this.all_tests[k].sub_tests[j].checked = true;
                    this.all_tests[k].sub_tests[j].price = obj.blood[b].price;
                  }
                }
              }
            }
          }

          if(obj.culture !=undefined && obj.culture.length !=0){
            this.culture_test = obj.culture;
            for(var b=0;b<obj.culture.length;b++){
              for (var k = 0; k < this.all_tests.length; k++) {
                for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
                  if(obj.culture[b].test_id == this.all_tests[k].sub_tests[j].test_id){
                    this.all_tests[k].sub_tests[j].checked = true;
                    this.all_tests[k].sub_tests[j].price = obj.blood[b].price;
                  }
                }
              }
            }
          }

          if(obj.biopsy !=undefined && obj.biopsy.length !=0){
            this.biopsy_test = obj.biopsy;
            for(var b=0;b<obj.biopsy.length;b++){
              for (var k = 0; k < this.all_tests.length; k++) {
                for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
                  if(obj.biopsy[b].test_id == this.all_tests[k].sub_tests[j].test_id){
                    this.all_tests[k].sub_tests[j].checked = true;
                    this.all_tests[k].sub_tests[j].price = obj.blood[b].price;
                  }
                }
              }
            }
          }
        },
        error => { }
      )
  }

  ChangeTestType(event, id, data, type,subtest) {
    if (event == true) {
      for (var k = 0; k < this.all_tests.length; k++) {
        this.testtypename[k] = this.all_tests[k].test_type_name;
        if (this.testtypename[k] == type) {
          for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
            if (this.all_tests[k].sub_tests[j].test_id == id) {
              this.all_tests[k].sub_tests[j].checked = true;
            }
          }
        }
      }

      if (type == "Blood Test") {
        this.bloodtest = false;
        this.blood_test.push({
          test_id: id,
          test_name: data,
          price:subtest.price,
        });
        
      } else if (type == "Scan") {
        this.scantest = false;
        this.scan_test.push({
          test_id: id,
          test_name: data,
          price:subtest.price,
        });
        
      } else if (type == "Ultra Sound") {
        this.ultratest = false;
        this.ultra_test.push({
          test_id: id,
          test_name: data,
          price:subtest.price,
        });

      } else if (type == "X-ray") {
        this.xraytest = false;
        this.xray_test.push({
          test_id: id,
          test_name: data,
          price:subtest.price,
        });
        
      } else if (type == "Biopsy") {
        this.biopsytest = false;
        this.biopsy_test.push({
          test_id: id,
          test_name: data,
          price:subtest.price,
        });
        
      } else if (type == "Urine Test") {
        this.urinetest = false;
        this.urine_test.push({
          test_id: id,
          test_name: data,
          price:subtest.price,
        });
        
      } else if (type == "Faeces Test") {
        this.faecestest = false;
        this.feace_test.push({
          test_id: id,
          test_name: data,
          price:subtest.price,
        });
                
      } else if (type == "Culture") {
        this.culturetest = false;
        this.culture_test.push({
          test_id: id,
          test_name: data,
          price:subtest.price,
        });
      }

    } else if (event == false) {
      for (var k = 0; k < this.all_tests.length; k++) {
        this.testtypename[k] = this.all_tests[k].test_type_name;
        if (this.testtypename[k] == type) {
          for (var j = 0; j < this.all_tests[k].sub_tests.length; j++) {
            if (this.all_tests[k].sub_tests[j].test_id == id) {
              this.all_tests[k].sub_tests[j].checked = false;
            }
          }
        }
      }
      for (var h = 0; h < this.blood_test.length; h++) {
        if (this.blood_test[h].test_id == id) {
          this.blood_test.splice(h, 1);
        }
      }

      for (var h = 0; h < this.scan_test.length; h++) {
        if (this.scan_test[h].test_id == id) {
          this.scan_test.splice(h, 1);
        }
      }

      for (var h = 0; h < this.ultra_test.length; h++) {
        if (this.ultra_test[h].test_id == id) {
          this.ultra_test.splice(h, 1);
        }
      }
      for (var h = 0; h < this.xray_test.length; h++) {
        if (this.xray_test[h].test_id == id) {
          this.xray_test.splice(h, 1);
        }
      }

      for (var h = 0; h < this.biopsy_test.length; h++) {
        if (this.biopsy_test[h].test_id == id) {
          this.biopsy_test.splice(h, 1);
        }
      }

      for (var h = 0; h < this.urine_test.length; h++) {
        if (this.urine_test[h].test_id == id) {
          this.urine_test.splice(h, 1);
        }
      }

      for (var h = 0; h < this.feace_test.length; h++) {
        if (this.feace_test[h].test_id == id) {
          this.feace_test.splice(h, 1);
        }
      }
      for (var h = 0; h < this.culture_test.length; h++) {
        if (this.culture_test[h].test_id == id) {
          this.culture_test.splice(h, 1);
        }
      }
    }

  }

  saveTestDetails(){
    var sen_pass = {
      diagnosis_id: this.diag_centre_id,
      outsourceid:this.oursource.outsource_id,
      "blood": this.blood_test,
      "urine": this.urine_test,
      "faeces": this.feace_test,
      "scan": this.scan_test,
      "x-ray": this.xray_test,
      "ultra": this.ultra_test,
      "culture": this.culture_test,
      "biopsy": this.biopsy_test,
    }   
  }
}
