import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { Date_Formate } from '../../../assets/js/common.js';
import { Helper_Class } from 'src/app/helper_class';
import { send } from 'process';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit {
  public clntSal;
  public clientId;
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clntDOB;
  public clntAge;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clntGender;
  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public clntZipcode;
  public clntLocation;
  public clntLocationId;
  private locationNameByUrl: string;
  private cityUrl: string;
  private stateUrl: string;
  private countryUrl: string;
  private currentDateUrl: string;
  private zipcode_url: string;
  private ageUrl:string;
  private salUrl: string;
  private paturl:string;
  private updurl:string;
  public clntLocationList:any=[];
  public clntCityList:any=[];
  public clntStateList:any=[];
  public clntSalList:any=[];
  public clntCityId;
  public clntCity;
  public clntStateId;
  public clntState;
  public clntCountryList;
  public clntCountry;
  public clntCountryId;
  public currentDatetime;
  public currentTime;
  public ageRead: boolean = false;
  public ageDisabledFlag: boolean;
  private clientid:string;
  private relid:string;
  private subrelid:string;
  private upd_data;
  private clientdata;

  constructor(public frontdeskservice: MenuViewService, public http: HttpClient, public toastr: ToastrService) {
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.salUrl = ipaddress.getIp + "gen/sal";
    this.zipcode_url = ipaddress.getIp + "usercontroller/locbyzipcode";
    this.paturl = ipaddress.getIp + "usercontroller/clientdet";
    this.updurl = ipaddress.getIp + "usercontroller/ucbr";
    this.ageRead = false;
    this.ageDisabledFlag = false;
  }

  ngOnInit(): void {
    this.getSalutations();
    this.getCurrentDate();
    this.clientdata = Helper_Class.getClientData();
    this.clientId = this.clientdata.client_id;
    this.relid = this.clientdata.relation_id;
    this.subrelid = this.clientdata.sub_rel_id;
  }

  getSalutations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salUrl, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clntSalList.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
          this.clntSal = "select";
          this.changeSalutation(this.clntSal);
        }
      });
  }

  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.clntGender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clntGender = "Female";
    else if (e == "5")
      this.clntGender = "Transgender";
    else
      this.clntGender = "";
  }

  selectDob(e) {
    this.ageRead = true;
    this.clntDOB = e;
    if (e != null)
      this.ageDisabledFlag = true;
   
    var send_data={
      dobdate: Date_Formate(this.clntDOB),
      curdate: Date_Formate(this.currentDatetime),
    }
  
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, send_data, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined)
          this.clntAge = obj.value;
      });
  }

  changeGender(e) {
    if (this.clntAge != undefined && this.clntAge != null && this.clntAge != "") {
      if (e == "Male") {
        if (this.clntAge <= 15)
          this.clntSal = "6";
        else
          this.clntSal = "1";
      } else if (e == "Female") {
        if (this.clntAge <= 15)
          this.clntSal = "7";
        else
          this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }

    } else {
      if (e == "Male") {
        this.clntSal = "1";
      } else if (e == "Female") {
        this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }
    }
  }

  zipcode_change() {
    if (this.clntZipcode.length > 4) {
      this.clntLocationList = [];
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.zipcode_url, {
        zipcode: this.clntZipcode,
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data))
          if (obj.locations != null && obj.locations.length != 0) {
            if (obj.locations.length == 1) {
              this.clntLocationId = obj.locations[0].location_id;
              this.clntLocation = obj.locations[0].description;
              this.clntZipcode = obj.locations[0].pincode;
              this.getCities(this.clntLocation, 0);
            } else {
              this.clntLocationList = obj.locations;
              console.log("../location" + JSON.stringify(this.clntLocationList))
            }
          }
        },
        error => {
          this.toastr.error("Unable to fetch location details, please try later");
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  changeLocation(locat_desc) {
    this.clntLocation = locat_desc;
    this.clntLocationList = [];

    if (this.clntLocation != null && this.clntLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, {
        name: this.clntLocation,
        //city_id: this.clntCityId,
      }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0)
            this.clntLocationList = dataval.locations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location) {
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.clntLocation = this.clntLocationList[i].description;
        this.clntZipcode = this.clntLocationList[i].pincode;
      }
    }
    this.clntLocationList = [];
    this.getCities(this.clntLocation, '0');
  }

  getCities(location, flag) {
    this.clntCityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, { loc_name: this.clntLocation, zipcode: this.clntZipcode },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.cities != null) {
            this.clntCityList = dataval.cities;
            if (this.clntCityList.length == 1) {
              for (var i = 0; i < this.clntCityList.length; i++) {
                if (this.clntCityList[i].city_id == this.clntCityId) {
                  this.clntCityId = this.clntCityList[i].city_id;
                  this.clntCity = this.clntCityList[i].city_desc;
                  this.getStates(this.clntCityId, 0)

                  break;
                } else {
                  this.clntCityId = this.clntCityList[0].city_id;
                  this.clntCity = this.clntCityList[0].city_desc;
                  this.getStates(this.clntCityId, 0)
                }
              }

            } else {
              this.clntCityId = this.clntCityList[0].city_id;
              this.clntCity = this.clntCityList[0].city_desc;
              this.getStates(this.clntCityId, 0)
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].city_desc == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.clntCity = this.clntCityList[i].city_desc;
        this.getStates(this.clntCityId, 0)
      }
    }
  }

  getStates(city, flag) {
    this.clntStateList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.states != null) {
            this.clntStateList = dataval.states;
            if (this.clntStateList.length == 1) {
              for (var i = 0; i < this.clntStateList.length; i++) {
                if (this.clntStateList[i].state_id == this.clntStateId) {
                  this.clntStateId = this.clntStateList[i].state_id;
                  this.clntState = this.clntStateList[i].state_desc;
                  this.getCountries(this.clntStateId, '0');
                  break;
                } else {
                  this.clntStateId = this.clntStateList[0].state_id;
                  this.clntState = this.clntStateList[0].state_desc;
                  this.getCountries(this.clntStateId, '0');
                }
              }
              
            } else {
              this.clntStateId = this.clntStateList[0].state_id;
              this.clntState = this.clntStateList[0].state_desc;
              this.getCountries(this.clntStateId, '0');
            }
          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].stage_desc == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.clntState = this.clntStateList[i].stage_desc;
        this.getCountries(this.clntStateId, '0');
      }
    }
  }
  
  getCountries(stateid, flag) {
    this.clntCountryList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.countryUrl, { state_id: stateid }, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.countries != null) {
          this.clntCountryList = dataval.countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.clntCountry = this.clntCountryList[c].country_desc;
              this.clntCountryId = this.clntCountryList[c].country_id;
              //this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, { country: "IN" }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        this.currentTime = dataval.current_time;//time basiiss
        this.currentDatetime = dataval.current_date;
        this.getPatientDetails(this.clientdata);
      },
      error => { });
  }

  fnameToUpper() {
    if (this.clntFirstName != null)
      this.clntFirstName = this.clntFirstName.toLocaleUpperCase();
  }

  mnameToUpper() {
    if (this.clntMiddleName != undefined)
      this.clntMiddleName = this.clntMiddleName.toLocaleUpperCase();
  }

  lnameToUpper() {
    if (this.clntLastName != undefined)
      this.clntLastName = this.clntLastName.toLocaleUpperCase();
  }

  getPatientDetails(data){
    var send_data={
      client_id: data.client_id,
      relation_id:data.relation_id,
      relationship:data.relationship_name,
      sub_rel_id:data.sub_rel_id,
      country:"IN",
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.paturl,send_data, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          console.log("CLIENT DATA "+JSON.stringify(obj))
          this.clntSal= obj.salutation;
          this.clntFirstName = encrypt_decript.Decript(obj.first_name);
          if(obj.middle_name != undefined){
            this.clntMiddleName = encrypt_decript.Decript(obj.middle_name);
          }
          if(obj.last_name != undefined){
            this.clntLastName = encrypt_decript.Decript(obj.last_name);
          }
          if(obj.gender != undefined){
            this.clntGender = encrypt_decript.Decript(obj.gender);
            this.changeGender(this.clntGender);
          }

          if(obj.age != undefined){
            this.clntAge = obj.age;
          }

          if(obj.dob != null){
            this.clntDOB = obj.dob;
            this.selectDob(obj.dob);
          }

          if(obj.mobile != undefined){
            this.clntContNumber = encrypt_decript.Decript(obj.mobile);
          }
          if(obj.emerg_contact1 != undefined){
            this.clntEmergNumber = obj.emerg_contact1;
          }
          if(obj.emerg_contact2 != undefined){
            this.clntEmergNumber1 = obj.emerg_contact2;
          }
          if(obj.address1 != undefined){
            this.clntAddress1 = encrypt_decript.Decript(obj.address1);
          }
          if(obj.address2 != undefined){
            this.clntAddress2 = encrypt_decript.Decript(obj.address2);
          }
          if(obj.zipcode != undefined){
            this.clntZipcode= encrypt_decript.Decript(obj.zipcode);
          }
          if(obj.location != undefined){
            this.clntLocation = obj.location_desc;
            this.clntLocationId = obj.location;
            this.getCities(this.clntLocationId,0);
            this.clntCity = obj.city_desc;
            this.clntCityId = obj.city;
            
            this.clntState = obj.state_desc;
            this.clntStateId = obj.state;

            this.clntCountry = obj.country_desc;
            this.clntCountryId = obj.country;
          }      
        }
      });
  }

  updatePatientDetails(){
    var senddadta={
      client_reg_id:this.clientId,
      relation_id:this.relid,
      sub_rel_id:this.subrelid,
      salutation:this.clntSal,
      first_name:this.clntFirstName,
      middle_name:this.clntMiddleName,
      last_name:this.clntLastName,
      dob:this.clntDOB,
      age:this.clntAge,
      mobile:this.clntContNumber,
      gender:this.clntGender,
      address1:this.clntAddress1,
      address2:this.clntAddress2,
      location:this.clntLocationId,
      city:this.clntCityId,
      state:this.clntStateId,
      country:this.clntCountryId,
      zipcode:this.clntZipcode,
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.updurl,senddadta, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj.key == "1") {
          this.toastr.success(obj.result);
          this.frontdeskservice.sendMessage("patient_list");
        } else {
          this.toastr.error(obj.result);
        }
      });
  }

  back(){
    this.frontdeskservice.sendMessage("patient_list");
  }
}
