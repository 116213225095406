<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Order Detail View</h5>
          </div>
          <div class="headerButtons">
            <img printSectionId="print" (click)="printArea()" src="../../../assets/img/printer.svg" *ngIf="printFlag"
              class="saveimgbtn_inpatinfo" style="margin-left:5px;height: 25px;width: 25px;">
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" *ngIf="!cancelBtn"
              class="saveimgbtn_inpatinfo" (click)="cancelProduct()">
            <img src="../../../assets/ui_icons/buttons/save_button.svg" *ngIf="!confirmBtn" class="saveimgbtn_inpatinfo"
              (click)="confirmProduct()">
            <img src="../../../assets/ui_icons/buttons/Delivery_button.svg" *ngIf="!deliverBtn"
              class="saveimgbtn_inpatinfo" (click)="deliverProduct()">
            <img src="../../../assets/ui_icons/buttons/view_RX_button.svg" *ngIf="!presFlag"
              class="saveimgbtn_inpatinfo" (click)="viewPrescription()">
            <a (click)="back_Nav()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div>
          <div class="cover_div" style="padding-bottom: 13px;">
            <div class="header_lable">
              Billing details
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel"> <b>{{orderByName}}</b></mat-label><br>
                  <mat-label class="matlabel">Bill number :{{orderNum}}</mat-label><br>
                  <mat-label class="matlabel">Bill type :{{purchaseType}}</mat-label><br>
                  <mat-label class="matlabel" [hidden]="preferredTimeFlag">Preferred time :{{totTime}}</mat-label><br>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel"> Bill date :{{orderDate}}</mat-label><br>
                  <mat-label class="matlabel">Bill time :{{orderTime}}</mat-label><br>
                </div>
              </div>
            </div>
          </div>
          <div class="cover_div" style="padding-bottom: 13px;" [hidden]="addDelivery">
            <div class="header_lable">
              Address details
            </div>
            <div class="content_cover">
              <div class="row" [hidden]="addDelivery">
                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <table [hidden]="addDelivery" id="patienttable" class="table table-hover table-dynamic">
                    <b>Address to deliver</b>
                    <p style="margin-left: 10px;">
                      {{name}}
                      <br />
                      {{address1+","+address2}}
                      <br />
                      {{locationDesc}}
                      <br /> {{cityDesc+ " - " +zipcode}}
                      <br /> {{stateDesc+ " - " +countryDesc}}
                      <br /> {{contactNo}}
                      <br />
                    </p>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="cover_div" style="padding-bottom: 13px;" [hidden]="storeDiv">
            <div class="header_lable">
              Delivery details
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2">
                  <mat-label class="matlabel" [hidden]="storeDiv">Validity Date<br>
                    <input type="text" class=" ipcss" [readonly]="orderType" [(ngModel)]="validityDays" #matInput />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2" [hidden]="storeDiv">
                  <mat-label class="matlabel" [hidden]="storeDiv">Validity Time<br>
                    <input type="text" class=" ipcss" [disabled]="orderType" [(ngModel)]="availableTo" #matInput />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2" [disabled]="discountValue" [hidden]="isAdmin">
                  <mat-label class="matlabel" [disabled]="discountValue">Discount type<br>
                    <select disableOptionCentering class="ipcss " [disabled]="discountValue" [(ngModel)]="discType"
                      (change)="discountChange()">
                      <option *ngFor="let loc of discountDatasArray" value={{loc.disc_type_id}}>{{loc.description}}
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="!paytypreq">
                  <mat-label class="matlabel">Payment type<br>
                    <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                      (selectionChange)="changePay()" disableOptionCentering>
                      <mat-option *ngFor="let pay of paytypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="insurFlag">
                  <mat-label class="matlabel">Insurer<br>
                    <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID" disableOptionCentering>
                      <mat-option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}
                      </mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                  <mat-label class="matlabel">Card No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                  <mat-label class="matlabel">Holder name<br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="tranFlag">
                  <mat-label class="matlabel">Transaction ID<br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                  <mat-label class="matlabel">Cheque No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                  <mat-label class="matlabel">Bank Name <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                  </mat-label>
                </div>
                <div [hidden]="delDiv" class="col-12">
                  <table class="tablewidth">
                    <tr>
                      <td>
                        <mat-label class="matlabel">Delivery date </mat-label>
                      </td>
                      <td>
                        <input type="text" [(ngModel)]="deldate" class="ipcss " matInput>
                      </td>
                    </tr>
                  </table>
                  <table class="tablewidth">
                    <tr>
                      <td>
                        <mat-label class="matlabel">Delivery time</mat-label>
                      </td>
                      <td>
                        <input type="text" [(ngModel)]="deliverTime" class="ipcss " matInput>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top:2px" *ngIf="orderType">
            <div class="col-12" *ngIf="billEdit">
              <img style="float:right;" class="pluseIcon" src="../../../assets/ui_icons/Add_icon.svg"
                (click)="addNewDrugDetails()" />
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="margin-top: 15px;">
              <div class="table-resposive  dig_table_overflow" style="margin-bottom: 20px;">
                <table cellspacing="0" cellpadding="0" *ngIf="drugDetailsArray.length"
                  class="table table-nowrap table-sm pharmacycurrentorder">
                  <thead class="tableheading">
                    <tr>
                      <th style="width: 45px;">Si.No</th>
                      <th>Medicine name</th>
                      <th style="width: 12%;">Batch no</th>
                      <th style="width: 8%;"> Quantity</th>
                      <th style="width: 12%;"> Expiry date</th>
                      <th style="width: 10%;"> Price</th>
                      <th style="width: 10%;"> Discount (%)</th>
                      <th style="width: 10%;"> CGST</th>
                      <th style="width: 5%;"> SGST</th>
                      <th style="width: 5%;"> Amount</th>
                      <th  *ngIf="orderType && billEdit" > action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let medpre of drugDetailsArray;let i = index">
                      <th >{{i + 1}} </th>
                      <td style="text-align: left;">
                        <div *ngIf="medpre.fromRetrivel == true">{{medpre.drug_disp_name}}</div>
                        <div *ngIf="medpre.fromRetrivel == false">

                          <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                            (keyup)="changeMedicineName($event)" maxlength="50" [(ngModel)]="medpre.drug_name" matInput
                            [matAutocomplete]="auto2" />
                          
                            <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                            <mat-option (click)="selectMedicineItem(medicine,medpre)"
                              *ngFor="let medicine of MedicineNameArray" value="{{medicine}}">
                              {{medicine}}
                            </mat-option>
                          </mat-autocomplete>
                        </div>
                      </td>
                      <td>
                        <div *ngIf="!orderType">
                          {{medpre.batch_no}}
                        </div>
                       
                        <div *ngIf="orderType">
                          <input type="text" class="ipcss order_width" [ngStyle]="medpre.batch_required" required
                            [(ngModel)]="medpre.batch_no" matInput maxlength="50" readonly autocomplete="on"
                            (click)="getCORD($event,'drug_'+medpre.index);medpre.show_batcharray = !medpre.show_batcharray" />
                          
                            <div [id]="'drug_'+medpre.index" class="auto_complete_text1"
                            *ngIf="medpre.batcharray.length != 0" style="display: none;">
                            <table style="width: 325px">
                              <thead>
                                <tr>
                                  <th> Batch no</th>
                                  <th> Qty </th>
                                  <th> MRP </th>
                                  <th> Exp Date </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let batch of medpre.batcharray"
                                  (click)="selectedBatch(batch,medpre,'drug_'+medpre.index)">
                                  <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                                  <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                                  <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                                  <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <!-- <p [hidden]="medpre.batch_hide">{{medpre.batch_no}}</p>
                        <select [(ngModel)]="medpre.batch_no" [hidden]="!medpre.batch_hide" class="ipcss " required
                          style="margin-top: 10px;" (change)="retrieveBatch(medpre)">
                          <option *ngFor="let batch of medpre.batchnos" value={{batch}}> {{batch}}</option>
                        </select> -->
                      </td>
                      <td>
                        <!-- (focus)="clearField(medpre)" -->
                        <input type="text" matInput class="ipcss " [(ngModel)]="medpre.quantity"
                           style="width: 65%;margin: 0 auto !important;" maxlength="5"
                          [hidden]="confirmBtn" class="ipcss "
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          (blur)="comparedrug(medpre.drug_id,medpre)"
                          (keyup)="calculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.index)">
                        
                          <p [hidden]="!confirmBtn">{{medpre.quantity}} </p>
                      </td>
                      <td>
                        <p style="margin-top: 10px !important;">{{medpre.expiry_date}} </p>
                      </td>
                      <td *ngIf="!confirmFlag">
                        <input type="text" [(ngModel)]="medpre.price" maxlength="10"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="!discFlag"
                            style="width: 75%;margin: 0 auto !important;" 
                            (keyup)="calculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.index)">
                      </td>
                      <td *ngIf="!confirmFlag">
                        <input type="text" [(ngModel)]="medpre.discount" maxlength="2" 
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="!discFlag"
                          style="width: 75%;margin: 0 auto !important;" 
                          (keyup)="calculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.index)">
                      </td>
                      <td *ngIf="confirmFlag">
                        <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                      </td>
                      <td *ngIf="confirmFlag">
                        <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                      </td>
                      <td>
                        <p style="float: right; margin-right: 10px;margin-top: 10px !important;">{{medpre.cgst}} </p>
                      </td>
                      <td>
                        <p style="float: right; margin-right: 10px;margin-top: 10px !important;">{{medpre.sgst}} </p>
                      </td>
                      <td>
                        <p style="float: right; margin-right: 10px;margin-top: 10px !important;">{{medpre.amount}}</p>
                      </td>
                      <td style="text-align: center;" *ngIf="orderType && billEdit">
                        <a (click)="deleteMedicine(medpre.drug_id,medpre.index)">
                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7">
              <div class="row" [hidden]="paytypreq" >
                <table>
                  <tr>
                    <th>Pay type</th>
                    <th>Card #</th>
                    <th>Transaction #</th>
                    <th>Amount</th>
                  </tr>
                  <tr *ngFor="let pay of payments">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.card_no}}</td>
                    <td style="width: 150px !important;">{{pay.trans_id}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
              <table class="price_table">
                <tr>
                  <td class="billamtlabel">
                    <mat-label>
                      <b>Bill amount</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <input type="text" class="ipcss" readonly
                      style="border:none !important;text-align: right !important;" [(ngModel)]="totalValue1">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;">{{totalValue1}}</mat-label>
                  </td>
                </tr>
                <tr [hidden]="discFlag">
                  <td class="billamtlabel">
                    <mat-label class="matlabel">
                      <b>Discount</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <input type="text" class="ipcss" style="text-align: right !important;" [(ngModel)]="prodDisc"
                      (keyup)="changeTotal()">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;">{{prodDisc}}</mat-label>
                  </td>
                </tr>
                <tr [hidden]="viewFlag">
                  <td class="billamtlabel">
                    <mat-label class="matlabel" style="margin-left: 70px">
                      <b>Paid</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <input type="number" class="ipcss" style="text-align: right !important;" [(ngModel)]="paid" matInput
                      (keyup)="changeAdvance()" (blur)="paidText()">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;">{{paid}}</mat-label>
                  </td>
                </tr>
               
                <tr [hidden]="viewFlag">
                  <td class="billamtlabel">
                    <mat-label class="matlabel" style="margin-left: 70px">
                      <b>Balance</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <input type="text" class="ipcss" style="border:none !important;text-align: right !important;" readonly [(ngModel)]="remaingBalance">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;"
                      *ngIf="remaingBalance != 'NaN'  "> {{remaingBalance}}</mat-label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Printing bill-->
          <div [hidden]="true">
            <div>
              <div #billprint id="billprint">
                <div class="bill_border">
                  <div>
                    <table style="margin-top: 100px;text-align: center;">
                      <td style="width: 150px;"></td>
                      <td>
                        <p style="font-size: 24px;"><strong>{{hospName}}</strong></p>
                        <p style="margin-top: -10px;">{{hospitalAddress}} {{hospCSZ}} {{hospitalCount}}</p>
                        <p>Tel: {{hospTel}}</p>
                      </td>
                    </table>
                    <table style="margin-left: 75px; margin-top: 20px;">
                      <td>
                        <p>Name :{{orderByName}}</p>
                        <p>Bill number :{{orderNum}}</p>
                        <p>Bill type :{{purchaseType}}</p>
                      </td>
                      <td style="width: 250px;"></td>
                      <td>
                        <p>Doctor : {{drName}}</p>
                        <p>Bill date :{{orderDate}}</p>
                        <p>Bill time :{{orderTime}}</p>
                        <p>&nbsp;</p>
                      </td>
                    </table>
                    <br />
                    <table [hidden]="addDelivery" style="margin-left: 75px; border: thick 1px; ">
                      <tr>
                        <td col-12 col-md-12 col-lg-12 col-xl-12>
                          <b>Address to deliver</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            {{name}}
                            <br />
                            {{address1}}
                            <br />
                            {{locationDesc}}
                            <br /> {{cityDesc+ " - " +zipcode}}
                            <br /> {{stateDesc+ " - " +countryDesc}}
                            <br /> {{contactNo}}
                            <br />
                          </p>
                        </td>
                      </tr>
                    </table>
                    <br />
                    <br />
                    <table cellspacing="0" width="85%"
                      style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                      <tr style="border:1px solid black;">
                        <!-- <th style="border:1px solid black;">Med type</th> -->
                        <th style="border:1px solid black;">Si.no</th>
                        <th style="border:1px solid black;">Medicine name</th>
                        <th style="border:1px solid black;">Batch no</th>
                        <th style="border:1px solid black;">Quantity</th>
                        <th style="border:1px solid black;">Expiry date</th>
                        <th style="border:1px solid black;">Price</th>
                        <th style="border:1px solid black;"> Disc(%)</th>
                        <th style="border:1px solid black;">CGST(%)</th>
                        <th style="border:1px solid black;">SGST(%)</th>
                        <th style="border:1px solid black;"> Amount</th>
                      </tr>
                      <tr *ngFor="let medpre of drugDetailsArray; let i = index">
                        <!-- <td style="border:1px solid black;">{{medpre.type_name}}</td> -->
                        <td style="border:1px solid black;">{{ i + 1 }}</td>
                        <td style="border:1px solid black;">{{medpre.drug_name}}</td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                        </td>
                      </tr>
                    </table>
                    <table width="85%" style="margin-left: 50px; margin-right: 50px;">
                      <tr [hidden]="discFlag">
                        <td style="text-align: right; width: 80%;">
                          <b>Discount</b>
                          <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                            style="width: 15px; height: 15px;" />
                        </td>
                        <td>
                          <p style="float: right;margin-right: 10px;">{{prodDisc}}</p>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: right; width: 80%;">
                          <b>Bill amount</b>
                          <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                            style="width: 15px; height: 15px;" />
                        </td>
                        <td>
                          <p style="float: right;margin-right: 10px;">{{amountdisc}}</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div #noheader id="noheader">
                <div class="bill_border">
                  <div>
                    <table style="margin-left: 75px; margin-top: 200px;">
                      <td>
                        <p>Name : {{orderByName}}</p>
                        <p>Bill number : {{orderNum}}</p>
                        <p>Bill type : {{purchaseType}}</p>
                      </td>
                      <td style="width: 250px;"></td>
                      <td>
                        <p>Doctor : {{drName}}</p>
                        <p>Bill date : {{orderDate}}</p>
                        <p>Bill time : {{orderTime}}</p>
                        <p>&nbsp;</p>
                      </td>
                    </table>
                    <br />
                    <table [hidden]="addDelivery" style="margin-left: 75px; border: thick 1px; ">
                      <tr>
                        <td col-12 col-md-12 col-lg-12 col-xl-12>
                          <b>Address to deliver</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            {{name}}
                            <br />
                            {{address1}}
                            <br />
                            {{locationDesc}}
                            <br /> {{cityDesc+ " - " +zipcode}}
                            <br /> {{stateDesc+ " - " +countryDesc}}
                            <br /> {{contactNo}}
                            <br />
                          </p>
                        </td>
                      </tr>
                    </table>
                    <br />
                    <br />
                    <table cellspacing="0" width="85%"
                      style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                      <tr style="border:1px solid black;">
                        <th style="border:1px solid black;">Si.No</th>
                        <th style="border:1px solid black;">Med type</th>
                        <th style="border:1px solid black;">Medicine name</th>
                        <th style="border:1px solid black;">Batch no</th>
                        <th style="border:1px solid black;">Quantity</th>
                        <th style="border:1px solid black;">Expiry date</th>
                        <th style="border:1px solid black;">Price</th>
                        <th style="border:1px solid black;"> Disc(%)</th>
                        <th style="border:1px solid black;">CGST(%)</th>
                        <th style="border:1px solid black;">SGST(%)</th>
                        <th style="border:1px solid black;"> Amount</th>
                      </tr>
                      <tr *ngFor="let medpre of drugDetailsArray; let i = index ">
                        <td style="border:1px solid black;">{{i + 1}}</td>
                        <td style="border:1px solid black;">{{medpre.drug_name}}</td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                        </td>
                      </tr>
                    </table>
                    <table width="85%" style="margin-left: 50px; margin-right: 50px;">
                      <tr>
                        <td style="text-align: right; width: 80%;">
                          <b>Total</b>
                          <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                            style="width: 15px; height: 15px;" />
                        </td>
                        <td>
                          <p style="float: right;margin-right: 10px;">{{totalValue1}}</p>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: right; width: 80%;">
                          <b>Paid</b>
                          <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                            style="width: 15px; height: 15px;" />
                        </td>
                        <td class="billamttext" *ngIf="orderType">
                          <p style="float: right;margin-right: 10px;">{{paid}}</p>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: right; width: 80%;">
                          <b>Balance</b>
                          <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                            style="width: 15px; height: 15px;" />
                        </td>
                        <td class="billamttext" *ngIf="orderType">
                          <p style="float: right;margin-right: 10px;"
                            *ngIf="remaingBalance == 'NaN' ? '' : remaingBalance">{{remaingBalance}}</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div #banner id="banner">
                <table class="report-container" style="width: 100%;">
                  <thead class="report-header">
                    <tr>
                      <th class="report-header-cell">
                        <div class="header-info">
                          <img alt="image" src={{pharmaLogo}}>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tfoot class="report-footer">
                    <tr>
                      <td style="height:100px;"></td>
                    </tr>
                  </tfoot>
                  <tbody class="report-content">
                    <tr>
                      <td class="report-content-cell">
                        <div class="main">
                          <table style="margin-left: 48px;margin-top:20px;width: 100%;">
                            <td style="width: 50%;">
                              <p>Name :{{orderByName}}</p>
                              <p>Bill number :{{orderNum}}</p>
                              <p>Bill type :{{purchaseType}}</p>
                            </td>
                            <td style="width: 50%;">
                              <p>Doctor : {{drName}}</p>
                              <p>Bill date :{{orderDate}}</p>
                              <p>Bill time :{{orderTime}}</p>
                              <p>&nbsp;</p>
                            </td>
                          </table>
                          <br />
                          <table [hidden]="addDelivery" style="margin-left: 48px; border: thick 1px; ">
                            <tr>
                              <td col-12 col-md-12 col-lg-12 col-xl-12>
                                <b>Address to deliver</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  {{name}}
                                  <br />
                                  {{address1}}
                                  <br />
                                  {{locationDesc}}
                                  <br /> {{cityDesc+ " - " +zipcode}}
                                  <br /> {{stateDesc+ " - " +countryDesc}}
                                  <br /> {{contactNo}}
                                  <br />
                                </p>
                              </td>
                            </tr>
                          </table>
                          <br />
                          <br />
                          <table cellspacing="0" width="90%"
                            style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                            <tr style="border:1px solid black;">
                              <th style="border:1px solid black;">Si.No</th>
                              <th style="border:1px solid black;">Med type</th>
                              <th style="border:1px solid black;">Medicine name</th>
                              <th style="border:1px solid black;">Batch no</th>
                              <th style="border:1px solid black;">Quantity</th>
                              <th style="border:1px solid black;">Expiry date</th>
                              <th style="border:1px solid black;">Price</th>
                              <th style="border:1px solid black;"> Disc(%)</th>
                              <th style="border:1px solid black;">CGST(%)</th>
                              <th style="border:1px solid black;">SGST(%)</th>
                              <th style="border:1px solid black;"> Amount</th>
                            </tr>
                            <tbody>
                              <tr *ngFor="let medpre of drugDetailsArray;let i = index;">
                                <td style="border:1px solid black;">{{i + 1}}</td>
                                <td style="border:1px solid black;">{{medpre.type_name}}</td>
                                <td style="border:1px solid black;">{{medpre.drug_name}}</td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table width="90%" style="margin-left: 50px; margin-right: 50px;">
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Total</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td>
                                <p style="float: right;margin: 15px 10px 0 0;">{{totalValue1}}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Paid</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td class="billamttext" *ngIf="orderType">
                                <p style="float: right;margin: 15px 10px 0 0;">{{paid}}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Balance</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td class="billamttext" *ngIf="orderType">
                                <p style="float: right;margin: 15px 10px 0 0;"
                                  *ngIf="remaingBalance == 'NaN' ? '' : remaingBalance">{{remaingBalance}}</p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div #logowithname id="logowithname">
                <table class="report-container" style="width: 100%;">
                  <thead class="report-header">
                    <tr>
                      <td style="display: flex;justify-content: center;">
                        <div>
                          <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                            style="vertical-align: middle;">
                        </div>
                        <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                          <p><b>{{pharmacyName}}</b>,</p>
                          <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                            *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                          <span>{{location}},</span> <span>{{city}},</span>
                          <span>{{state}},</span><span>{{country}}.</span><br>
                          <p><span *ngIf="dlNO != undefined">DL No : {{dlNO}} &nbsp;&nbsp;</span><span
                              *ngIf="GSTNumber != undefined">GST : {{GSTNumber}}</span></p>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tfoot class="report-footer">
                    <tr>
                      <td style="height:100px;"></td>
                    </tr>
                  </tfoot>
                  <tbody class="report-content">
                    <tr>
                      <td class="report-content-cell">
                        <div class="main">
                          <table style="margin-left: 48px;margin-top:20px;width: 100%;">
                            <td style="width: 50%;">
                              <p>Name :{{orderByName}}</p>
                              <p>Bill number :{{orderNum}}</p>
                              <p>Bill type :{{purchaseType}}</p>
                            </td>
                            <td style="width: 50%;">
                              <p>Doctor : {{drName}}</p>
                              <p>Bill date :{{orderDate}}</p>
                              <p>Bill time :{{orderTime}}</p>
                              <p>&nbsp;</p>
                            </td>
                          </table>
                          <br />
                          <table [hidden]="addDelivery" style="margin-left: 48px; border: thick 1px; ">
                            <tr>
                              <td col-12 col-md-12 col-lg-12 col-xl-12>
                                <b>Address to deliver</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>
                                  {{name}}
                                  <br />
                                  {{address1}}
                                  <br />
                                  {{locationDesc}}
                                  <br /> {{cityDesc+ " - " +zipcode}}
                                  <br /> {{stateDesc+ " - " +countryDesc}}
                                  <br /> {{contactNo}}
                                  <br />
                                </p>
                              </td>
                            </tr>
                          </table>
                          <br />
                          <table cellpadding='0' cellspacing='0' width="90%"
                            style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                            <tr>
                              <th style="border:1px solid black;">Si.No</th>
                              <th style="border:1px solid black;">Med type</th>
                              <th style="border:1px solid black;">Medicine name</th>
                              <th style="border:1px solid black;">Batch no</th>
                              <th style="border:1px solid black;">Quantity</th>
                              <th style="border:1px solid black;">Expiry date</th>
                              <th style="border:1px solid black;">Price</th>
                              <th style="border:1px solid black;"> Disc(%)</th>
                              <th style="border:1px solid black;">CGST(%)</th>
                              <th style="border:1px solid black;">SGST(%)</th>
                              <th style="border:1px solid black;"> Amount</th>
                            </tr>
                            <tbody>
                              <tr *ngFor="let medpre of drugDetailsArray; let i = index;">
                                <td style="border:1px solid black;">{{i + 1}}</td>
                                <td style="border:1px solid black;">{{medpre.type_name}}</td>
                                <td style="border:1px solid black;width: 240px;">{{medpre.drug_name}}</td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                                </td>
                                <td style="border:1px solid black;">
                                  <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table width="90%" style="margin-left: 50px; margin-right: 50px;">
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Total</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td>
                                <p style="float: right;margin: 15px 10px 0 0;">{{totalValue1}}</p>
                              </td>
                            </tr>
                            <tr *ngIf="orderType">
                              <td style="text-align: right; width: 80%;" *ngIf="orderType">
                                <b>Paid</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td class="billamttext">
                                <p style="float: right;margin: 15px 10px 0 0;">{{paid}}</p>
                              </td>
                            </tr>
                            <tr *ngIf="orderType">
                              <td style="text-align: right; width: 80%;">
                                <b>Balance</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td class="billamttext">
                                <p style="float: right;margin: 15px 10px 0 0;"
                                  *ngIf="remaingBalance == 'NaN' ? '' : remaingBalance">{{remaingBalance}}</p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>