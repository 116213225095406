import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common';
import { FormControl } from '@angular/forms';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diagbilllist',
  templateUrl: './diagbilllist.component.html',
  styleUrls: ['./diagbilllist.component.css']
})
export class DiagbilllistComponent implements OnInit {
  public currDate = null;
  public billListArray: any=[];
  public noBillsFlag: boolean;
  public billTable: boolean;
  public fromDate;
  public toDate;
  public diagID;
  public finalBill;
  public ipaddress;
  //Get location
  public DiagLocationArray = [];
  public userType;
  public location;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public currentDate;
  public getDate;
  public currentYear;
  public currentTime;
  public userInfo
  public printFlag: boolean;
  public amount;
  public billEditFlag:boolean = false;
  public inpatientFlag;
  public ynopt1:boolean = true;
  public diagName;

  constructor(public toastr: ToastrService, public http: Http, public diagnosisservice: MenuViewService,public adminservice:MenuViewService) {
    this.noBillsFlag = true;
    this.billTable = false;
    Diagnosis_Helper.setreceptbilledit(undefined);
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [4] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by bill no or patient name or date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.printFlag = Helper_Class.getBillCreate() == "1" ? true : false;
    this.billEditFlag = Helper_Class.getBillEdit() == "1" ? true : false;
    this.getdate();
  }

  getdate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.currDate = obj.current_date;
          this.currentDate = Date_Formate((this.currDate));
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate = this.currDate;
          this.toDate = this.currDate;
          this.getLocation();
        }, error => { });
  }
  
  getLocation() {
    var send;
    if(Helper_Class.getIsAdmin() != undefined){
      send = {
        diag_name: this.userInfo.diag_centre_name,
        flag: "name",
        imei: this.ipaddress,
        prov_id: this.userInfo.diag_center_id,
        type: "diagnosis"
      } 
    }else{
      send = {
        diag_name: this.userInfo.hospitals[0].hptl_name,
        flag: "name",
        imei: this.ipaddress,
        prov_id: this.userInfo.user_id,
        type: "diagnosis"
      }
      this.userType = Diagnosis_Helper.getUserID_Type().user_type != null ? "diag_user" : "diagnosis";
    }
    this.DiagLocationArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
      JSON.stringify(send),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          for (var i = 0; i < obj.diag_loc.length; i++) {
            this.DiagLocationArray.push({
              centre_id: obj.diag_loc[i].diag_centre_id,
              centre_name: obj.diag_loc[i].diag_centre_name,
              centre_location: obj.diag_loc[i].diag_loc,
              view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
            });
          }
          this.diagName = this.DiagLocationArray[0].centre_name;
          this.location = this.DiagLocationArray[0].view_data;
         // this.getBillList(this.DiagLocationArray[0].centre_id);
          this.change_type("", "yes")
        }, error => { });
  }

  getBillList(centerID) {
    this.billListArray = [];
    var fieldvalue = {
      provider_id: centerID,
      from_date: this.fromDate,
      to_date: this.toDate,
      app_type: "diagnosis",
      center_id:centerID,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbills',
      JSON.stringify({
        fieldvalue
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.bills != null) {
            this.setBillList(obj);
          } else {
            this.noBillsFlag = false;
            this.billTable = true;
          }
        }, error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  viewBills(billID, finyear) {// view bills1
    for (var i = 0; i < this.DiagLocationArray.length; i++) {
      if (this.DiagLocationArray[i].view_data == this.location) {
        this.diagID = this.DiagLocationArray[i].centre_id.toString();
      }
    }
    if(Helper_Class.getIsAdmin() != undefined){
      this.diagID = this.userInfo.diag_center_id;
    }
    var billdetail = {
      bill_view_id: billID,
      Diag_center_id: this.diagID,
      flow: "billlist",
      fin_year:finyear,
    }
    Diagnosis_Helper.setbilldetailview(billdetail);
    Diagnosis_Helper.setCreateDiagAppt(billdetail);
    if(Helper_Class.getIsAdmin() != undefined){
      this.adminservice.sendMessage("diagBillingView");
    }else{
      this.diagnosisservice.sendMessage("diagbilldetailview")
    }
  }
  filterClick() {// filter search bills
    var from_date = this.fromDate;
    var to_date = this.toDate;
    this.billListArray = [];
    var inpatient = this.ynopt1 == true ? "no" : "yes"; 
    for (var i = 0; i < this.DiagLocationArray.length; i++) {
      if (this.DiagLocationArray[i].view_data == this.location) {
        this.diagID = this.DiagLocationArray[i].centre_id.toString();
      }
    }
    var send;
    if(Helper_Class.getIsAdmin() != undefined){
      send = {
        provider_id: this.userInfo.diag_center_id,
        from_date: from_date,
        to_date: to_date,
        app_type: "diagnosis",
        inpatient : inpatient,
        center_id:this.userInfo.diag_center_id,
      }
    }else{
      send = {
        provider_id: this.diagID,
        from_date: from_date,
        to_date: to_date,
        app_type: "diagnosis",
        inpatient : inpatient
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbills/',
      JSON.stringify({ fieldvalue : send}),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.login == null) {
            if (obj.bills != null) {
              this.setBillList(obj);
            } else {
              this.noBillsFlag = false;
              this.billTable = true;
            }
          }
        }, error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
  setBillList(obj) {
    if (obj.bills != null) {
      this.billListArray = [];
      for (var i = 0; i < obj.bills.length; i++) {
        var clnt_nmae = null;
        if (obj.bills[i].middle_name != undefined && obj.bills[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name).toString() + " " + encrypt_decript.Decript(obj.bills[i].middle_name).toString() + " " + encrypt_decript.Decript(obj.bills[i].last_name).toString();
        } else {
          if (obj.bills[i].last_name != undefined && obj.bills[i].last_name != null) {
            clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name) + " " + encrypt_decript.Decript(obj.bills[i].last_name);
          } else {
            clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name);
          }
        }
        this.amount = encrypt_decript.Decript(obj.bills[i].amount).toString();
        var amount_value = this.amount != null && this.amount != undefined && this.amount != "" ? this.amount : "0.00";
        if (obj.bills[i].amount != null)
          var get_date = obj.bills[i].date;
        this.billListArray.push({
          bill_id: obj.bills[i].bill_id,
          date: Date_Formate(get_date),
          client_name: clnt_nmae,
          amount: amount_value,
          amount_only: amount_value,
          fin_year: obj.bills[i].fin_year,
        });
      }
      var tot = 0;
      for (var i = 0; i < this.billListArray.length; i++) {
        tot = tot + parseFloat(this.billListArray[i].amount_only);
        this.finalBill = tot.toFixed(2);
      }
      if (this.billListArray.length == 0) {
        this.noBillsFlag = false;
        this.billTable = true;
      } else {
        this.noBillsFlag = true;
        this.billTable = false;
      }
    }
  }

  navBillCreate() {//Redirect to bill create page
    var type_user = {
      user_id: this.userInfo.user_id,
      user_type: "diagnosis",
      diag_centre_id: this.diagName,
    }
    Diagnosis_Helper.setUserID_Type(type_user);
    this.diagnosisservice.sendMessage('diagbillcreate'); 
  }

  spiltDate(date) {
    var get_tmp = date.split("-");
    var date_appt = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    return date_appt;
  }

  ChangeMatePicker(dateVel) {
    var apt = new FormControl(new Date(dateVel));
    return apt;
  }

  OnDateChangedfrom(e) {
    this.fromDate = e;
  }

  OnDateChangedto(e) {  
    this.toDate = e;
  }

  editBill(billNo, appID,finyear,bill) {
    var diag_centre_name;
    for (var i = 0; i < this.DiagLocationArray.length; i++) {
      if (this.DiagLocationArray[i].view_data == this.location) {
        this.diagID = this.DiagLocationArray[i].centre_id.toString();
        diag_centre_name = this.DiagLocationArray[i].centre_name;
      }
    } 
    if(Helper_Class.getIsAdmin() != undefined  ){
      this.diagID = this.userInfo.diag_center_id;
    }
    var billedit = {
      doc_reg_id: this.userInfo.user_id,
      bill_view_id: billNo,
      app_id: appID,
      Diag_center_id: this.diagID,
      patient_name: bill.client_name,
      diag_centre_name: diag_centre_name,
      flow: "edit",
      fin_year:finyear,
    }
    Diagnosis_Helper.setreceptbilledit(billedit);
    if(Helper_Class.getIsAdmin() != undefined){
      this.adminservice.sendMessage('diagBillingEdit');
    }else{
      this.diagnosisservice.sendMessage('billedit');
    }
  }
  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
      this.filterClick();
    }else {
      this.ynopt1 = false;
      this.filterClick();
    }
  }
}
