import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagnosis-report-upload',
  templateUrl: './diagnosis-report-upload.component.html',
  styleUrls: ['./diagnosis-report-upload.component.scss']
})
export class DiagnosisReportUploadComponent implements OnInit {
  //Pending
  reportView: boolean;
  updateReport: boolean;
  subTestValue: any;
  testObj: any = [];
  type: any;
  preTestID: any;
  sendInfo: any = [];
  testType: any;
  sequence: string;
  impression: any;
  culture:any;
  allow: boolean;
  updateArray: any = [];
  updateListArray: any;
  sendObj: any = [];
  testArray: any = [];
  reportArray: any = [];
  subTestID: any;
  subTestInfo: any;
  testInfo: any;
  sendObject: any = [];
  imgStr: any;
  locationArray: any = [];
  selecteLocation: any;
  diagLocation: any;
  subTestView2: boolean;
  subTestView: boolean;
  presTest: any;
  public subTestName;
  Clnt_App_ID: any;
  clientName1: any;
  clientName: any;
  subTestArray: any = [];
  testID: any;
  testDataArray: any = [];
  getClientArray: any;
  clientNameArray: any = [];
  clientDe: any;
  clientData: any;
  sendData: any = [];
  date: any = [];
  currentDate: any;
  selectedCenterArray: any = [];
  locationInfomation: any;
  public userID = "diagnosis";
  public chooseFileFlag: boolean;
  public UserType;
  public gender;
  public age;
  public SubtestRangeArray = [];
  public show = false;
  public clientRegID: string;
  public relationID: string;
  public subRelID: string;
  public fileType: string;

  public userInfo;
  public Select = "Select";
  public diagUserType;
  public created_by;
  public show_culture_test:boolean;

  //Editor
  @ViewChild('editor', {static: false}) editor: ElementRef;
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // [{ 'header': 1 }, { 'header': 2 }], // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
     // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
    [{ 'direction': 'rtl' }], // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'], // remove formatting button
    // ['link', 'image'], // link and image, video
    // ['table']
    ],
    };
    editorStyle = {
      height : '300px;',
    };

  constructor(public messageservice:MenuViewService,public service:CommonDataService,public toastr:ToastrService, public  http:Http,public  router:Router) {
    this.gender = "0";
    this.age = "00";
    this.show = this.type == "Scan" ? true : false;
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.created_by = this.userInfo.middle_name != undefined && this.userInfo.middle_name != null &&this.userInfo.middle_name != "" ? this.userInfo.first_name + " " + this.userInfo.middle_name + " " + this.userInfo.last_name : this.userInfo.first_name + " " + this.userInfo.last_name;
    this.diagUserType = Diagnosis_Helper.getUserID_Type().user_type;
    this.subTestView = false;
    this.subTestView2 = false;
    this.show_culture_test = false;
    
    this.chooseFileFlag = true;
    this.getLocation();
    this.getDate();
    this.service.get_diag_location();
    console.log("Diagnosis_Helper.getUserID_Type()" + JSON.stringify(Diagnosis_Helper.getUserID_Type()));
  }

  getLocation() {
    this.clientNameArray = [];
    this.UserType = Diagnosis_Helper.getUserID_Type().user_type != null ? "diag_user" : "diagnosis";
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/diagloc/", JSON.stringify({
      diag_name: Diagnosis_Helper.getUserID_Type().diag_centre_id,
      flag: "name",
      imei: Helper_Class.getIPAddress(),
      prov_id: Diagnosis_Helper.getUserID_Type().user_id,
      type: this.UserType,
    }), { headers: headers }).subscribe(
      data => {
          if (data.json().diag_loc != null) {
            this.locationInfomation = data.json().diag_loc;
            this.selectedCenterArray = [];

            for (var i = 0; i < this.locationInfomation.length; i++) {
              this.selectedCenterArray = [{
                name: this.locationInfomation[0].diag_centre_name,
                id: this.locationInfomation[0].diag_centre_id,
                address1: this.locationInfomation[0].address1,
                address2: this.locationInfomation[0].address2,
                loc: this.locationInfomation[0].diag_loc,
                state: this.locationInfomation[0].state,
                city: this.locationInfomation[0].city,
                country: this.locationInfomation[0].country,
                zipcode: this.locationInfomation[0].zipcode,
                telephone: this.locationInfomation[0].telephone
              }];
              this.diagLocation = this.locationInfomation[0];

              if (this.selecteLocation == this.locationInfomation[i].diag_centre_id) {
                this.selectedCenterArray = [{
                  name: this.locationInfomation[i].diag_centre_name,
                  id: this.locationInfomation[i].diag_centre_id,
                  address1: this.locationInfomation[i].address1,
                  address2: this.locationInfomation[i].address2,
                  loc: this.locationInfomation[i].diag_loc,
                  state: this.locationInfomation[i].state,
                  city: this.locationInfomation[i].city,
                  country: this.locationInfomation[i].country,
                  zipcode: this.locationInfomation[i].zipcode,
                  telephone: this.locationInfomation[i].telephone
                }];
                this.diagLocation = this.locationInfomation[i];
              }
            }
            
            if (this.selecteLocation == undefined) {
              for (var i = 0; i < this.locationInfomation.length; i++) {
                this.locationArray = {
                  diag_centre_id: this.locationInfomation[0].diag_centre_id,
                  diag_centre_name: this.locationInfomation[0].diag_centre_name,
                  loc: this.locationInfomation[0].diag_loc
                };
              }
              //this.get_client(this.loc_arr, Diagnosis_Helper.getUserID_Type().user_type);
            }
          }
      },error => {
      this.toastr.error(Message_data.getNetworkMessage());
    });
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          var new_day = this.currentDate.split('-');
          var current_date_data = new_day[2] + "-" + new_day[1] + "-" + new_day[0]
          this.date = [{
            day: current_date_data
          }];
        }
      },error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });

    this.testArray = [];
    this.sendObject = [];
    this.reportArray = [];
   
  }

  getClient(i, data) {
    this.selecteLocation = i.diag_centre_id;
    var utype;
    if(this.userInfo.user_type =="diagnosis"){
      utype="diagnosis";
    } else {
      utype="diag_user";
    }
    if(this.clientName.length >= 3){
      this.sendData = {
        diag_reg_id: Diagnosis_Helper.getUserID_Type().user_id,
        location: i.diag_loc,
        first_name:this.clientName.toLocaleUpperCase(),
        diag_user:utype
      }
      this.clientNameArray = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/getdiaapp/", JSON.stringify(
        this.sendData
      ), { headers: headers }).subscribe(
        data => {

          if (data.json().appointment_id != null) {
            this.clientData = data.json().appointment_id;
              for (var i = 0; i < this.clientData.length; i++) {
                if( this.clientData[i].first_name != undefined){
                  if( this.clientData[i].last_name != undefined){ 
                    if( encrypt_decript.Decript(this.clientData[i].first_name) != "" && encrypt_decript.Decript(this.clientData[i].last_name) != ""){
                      this.clientDe = encrypt_decript.Decript(this.clientData[i].first_name)+" "+encrypt_decript.Decript(this.clientData[i].last_name)
                    }
                  }else{
                    this.clientDe = encrypt_decript.Decript(this.clientData[i].first_name)
                  }
                }else{
                  this.clientDe = "";
                }
                if((this.clientData[i].last_name) != undefined && (this.clientData[i].first_name)){
                  this.clientDe = encrypt_decript.Decript(this.clientData[i].first_name)+" "+encrypt_decript.Decript(this.clientData[i].last_name);
                }
    
                this.clientNameArray.push({
                  name: this.clientDe,
                  id: this.clientData[i].app_id,
                  client_reg_id: this.clientData[i].client_reg_id,
                  rel_id: this.clientData[i].relation_id,
                  sub_rel_id: this.clientData[i].sub_rel_id
                });
              }
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    }
  }

  changeClient(info) {
    this.clientName1 = info;
    this.clientName = info.id + " - "+ info.name;
    this.clientNameArray = [];
    this.subTestView = false;
    this.subTestView2 = false;
    this.show_culture_test = false;
    this.presTest = undefined;
    this.subTestName = undefined;
    this.testID = info.id;
    this.clientRegID = info.client_reg_id;
    this.relationID = info.rel_id;
    this.subRelID = info.sub_rel_id;
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "prescription/diapresdetappid/", 
    JSON.stringify({ appointment_id: info.id }), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          this.getClientArray = data.json();
          if (data.json().gender != null) {
            this.gender = encrypt_decript.Decript(data.json().gender.toString());
          }
          if (data.json().age != null) {
            this.age = data.json().age;
          }
          this.testDataArray = [];
          this.subTestArray = [];
          this.getClientArray.tests.forEach(element => {
            if(element == "blood_test") {
              this.testDataArray.push({
                Data: "Blood Test",
                Normal: element
              });
            }else if (element == "urine_test") {
              this.testDataArray.push({
                Data: "Urine Test",
                Normal: element
              });
            }else if (element == "scan_test") {
              this.testDataArray.push({
                Data: "Scan Test",
                Normal: element
              });
            }else if (element == "faeces_test") {
              this.testDataArray.push({
                Data: "Faeces Test",
                Normal: element
              });
            }else if (element == "xray_test") {
              this.testDataArray.push({
                Data: "Xray Test",
                Normal: element
              });
            }else if (element == "ultrasound_test") {
              this.testDataArray.push({
                Data: "Ultra Sound",
                Normal: element
              });
            }else if (element == "culture_test") {
              this.testDataArray.push({
                Data: "Culture",
                Normal: element
              });
            }else if (element == "biopsy_test") {
              this.testDataArray.push({
                Data: "Biopsy",
                Normal: element
              });
            }else {
              this.testDataArray.push({
                Data: element,
                Normal: element
              });
            }
          });
        }
      },error => {
        this.toastr.error(Message_data.getNetworkMessage());
      })
  }

  changeClientTest(test) {
    this.subTestName = undefined;
    this.testInfo = test;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "prescription/dtestsbyappid/", JSON.stringify({ appointment_id: this.testID, group: test.Normal }), { headers: headers }).subscribe(
      data => {
        if (data.json().tests != null) {
          this.subTestArray = data.json().tests; this.subTestArray = data.json().tests;
        }
      },error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  changeSubTest(subtest) {
    this.SubtestRangeArray = [];
    this.subTestValue = subtest;
    this.subTestInfo = subtest;

    if (subtest != null) {
      if (this.presTest.Normal == "blood_test" || this.presTest.Normal == "urine_test" || this.presTest.Normal == "faeces_test") {
        this.chooseFileFlag = true;
        this.subTestView2 = false;
        this.show_culture_test = false;
        this.Clnt_App_ID = this.clientName1.id;

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "prescription/dsubtests/",
          JSON.stringify({
            app_id: this.Clnt_App_ID,
            group: this.presTest.Normal,
            pres_diag_id: subtest.test_id
          }), { headers: headers }).subscribe(
            data => {
              if (data.json() != undefined && data.json() != null) {
                if (data.json().sub_tests != null) {
                  for (var i = 0; i < data.json().sub_tests.length; i++) {
                    var temp_value = "";
                    this.SubtestRangeArray.push({
                      sub_test_name: data.json().sub_tests[i].sub_test_name,
                      test_range: data.json().sub_tests[i].range,
                      unit: data.json().sub_tests[i].unit,
                      sub_test_id_data: data.json().sub_tests[i].sub_test_id,
                    });
                  }
                  this.subTestView = true;
                }
              }
            }, error => {
              this.toastr.error(Message_data.getNetworkMessage());
            });
      } else {
        this.show = this.presTest.Normal == "scan_test" ? true : false;
        this.chooseFileFlag = false;
        this.subTestView = false;
        if(this.presTest.Normal == "culture_test"){
          this.subTestView2 = false;
          this.show_culture_test = true;
        }else{
          this.subTestView2 = true;
          this.show_culture_test = false;
        }
      }
    }
  }

  //UPLOAD FILE 
  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    var fileval =file.type.split('/');
    this.fileType = fileval[1] =="jpeg" ? "jpg" : fileval[1];

    if(file.type =="image/jpeg" || file.type =="image/png" || file.type =="video/mp4"){
      myReader.onloadend = (e) => {
        var fileSize = Math.round(inputValue.files[0].size / 1024);
        if (fileSize >= 1024) {
          this.toastr.error(Message_data.maxOnembImg);
          $("#file").val('');
          this.imgStr = null;
        }else {
          this.imgStr = myReader.result;
          $('#profile_img').attr('src', myReader.result as string);
        }
      }
      myReader.readAsDataURL(inputValue.files[0]);
    } else {
      this.toastr.error(Message_data.appSupportsJPGorPng);
    }
  }

  uploadData() {
    var flag:boolean = true;
    if (this.testInfo != undefined) {
      this.testArray = [];
      this.subTestArray.forEach(element => {
        this.subTestID = element.test_id;
      });

      this.Clnt_App_ID = this.clientName1.id;
      this.sendObject = {
        diag_app_id: this.Clnt_App_ID,
        group: this.testInfo.Normal,
        app_id: this.subTestInfo.test_id,
        client_reg_id: this.clientRegID,
        created_by:this.created_by,
        relation_id: this.relationID,
        sub_rel_id: this.subRelID,
        country: ipaddress.country_code,
        test_id: this.subTestID
      };

      var imgpath;
      if (this.imgStr != null) {
        imgpath = this.imgStr.split(',')[1];
      }

      this.reportArray = {
        image_type: this.fileType,
        image: imgpath,
      };

      if (this.testInfo.Normal == "blood_test" || this.testInfo.Normal == "urine_test" || this.testInfo.Normal == "faeces_test") {
        for (var i = 0; i < this.SubtestRangeArray.length; i++) {
          if(this.SubtestRangeArray[i].test_range_value != undefined && this.SubtestRangeArray[i].test_range_value != null && this.SubtestRangeArray[i].test_range_value != ""){
            this.testArray.push({
              test_id: this.SubtestRangeArray[i].sub_test_id_data,
              reading: this.SubtestRangeArray[i].test_range_value
            });
          }else{
            flag = false;
          }
        }

        if (this.testInfo.Normal == "blood_test") {
          this.testObj = { "blood_test": this.testArray }
        } else if (this.testInfo.Normal == "urine_test") {
          this.testObj = { "urine_test": this.testArray }
        } else if (this.testInfo.Normal == "faeces_test") {
          this.testObj = { "faeces_test": this.testArray }
        }
      } else {
        this.testArray = [];
        if (this.presTest.Normal == "scan_test") {
          this.testArray.push({
            test_id: this.subTestValue.test_id,
            impression: this.impression,
            sequences: this.sequence
          });
        } else if (this.presTest.Normal == "culture_test") {
          this.testArray.push({
            test_id: this.subTestValue.test_id,
            culture:this.culture,
          });
        } else {
          this.testArray.push({
            test_id: this.subTestValue.test_id,
            impression: this.impression,
            observation: this.sequence
          });
        }

        if (this.testInfo.Normal == "scan_test") {
          this.testObj = { "scan_test": this.testArray }
        } else if (this.testInfo.Normal == "xray_test") {
          this.testObj = { "xray_test": this.testArray }
        } else if (this.testInfo.Normal == "ultrasound_test") {
          this.testObj = { "ultrasound_test": this.testArray }
        }else if (this.testInfo.Normal == "culture_test") {
          this.testObj = { "culture_test": this.testArray }
        }else if (this.testInfo.Normal == "biopsy_test") {
          this.testObj = { "biopsy_test": this.testArray }
        }
      }

      this.sendObj = Object.assign({}, this.sendObject, this.reportArray, this.testObj);
      if(flag == true){
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "prescription/savediagreport/", JSON.stringify(this.sendObj), { headers: headers }).subscribe(
          data => {
            var obj = JSON.parse(data["_body"]);
            
            if (obj.key!=null && obj.key == "1") {
              this.toastr.success(Message_data.rptUploaddSuccess);
             // this.clientName1 = undefined;
              this.presTest = undefined;
              this.subTestName = undefined;
              this.SubtestRangeArray = [];
              
              if(this.subTestView2 ==true){
                this.subTestView2 = false;
              }

              if(this.show_culture_test == true){
                this.show_culture_test = false;
              }
  
              if(this.impression != undefined){
                this.impression=undefined;
              }
  
              if(this.sequence !=undefined){
                this.sequence = undefined;
              }

              if(this.culture !=undefined){
                this.culture = undefined;
              }
  
              if(this.imgStr != null){
                $("#file").val('');
                this.imgStr = null;
              }
            } else {
              this.toastr.error(Message_data.unableToUploadRpt);
            }
          });
      }else{
        this.toastr.error(Message_data.enterTestReading);
      }
    } else {
      this.toastr.error(Message_data.fillAllFields);
    }
  }

  changeLocation(id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gtedit/", JSON.stringify({ diag_centre_id: id.diag_centre_id }), { headers: headers }).subscribe(
      data => {
        if (data.json().test_edit != null) {
          this.updateListArray = data.json().test_edit;
          this.updateListArray.forEach(element => {

            this.updateArray.push({
              first_name: encrypt_decript.Decript(element.first_name),
              last_name: encrypt_decript.Decript(element.last_name),
              id: element.diag_app_id,
              type: element.test_type,
              test_id: element.pres_test_id
            })
          });
        }
      },error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }



  reportUploadView() {
    this.updateReport = false;
    this.reportView = true;
  }

  reportModifyView() {
    this.updateReport = true;
    this.reportView = false;
  }

  onSelectionChanged = (event) => {}
  onContentChanged = (event) => {
    this.sequence = event.html;
    
  }
  onContentChanged2 = (event) => {
    this.impression = event.html;
  }
  editorCreated(quill: any) {}    
}

