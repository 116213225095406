<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Stock status</h5>
          </div>
          <div class="headerButtons">
            <!-- <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save_data()"
                class="saveimgbtn_inpatinfo" /> -->
          </div>
        </div>

      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Location<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="location">
                <option disabled>Select</option>
                <option (click)="get_stock(pro_type.pharma_facility_id)" *ngFor="let pro_type of LoadLocationArray"
                  value={{pro_type.location_id}}>{{pro_type.location_id}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel"><br>
              <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                (click)="exportToExcel()" />
            </mat-label>
          </div>
          <div class="col-12" *ngIf="stockData.length != 0">
            <table *ngIf="stockData.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th >Medicine</th>
                  <th>Supplier</th>
                  <th>Batch</th>
                  <th>Rec'd date</th>
                  <th>Expiry date</th>
                  <th>Available qty</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let stock of stockData">
                  <td class="align_left">{{stock.short_name}} - {{stock.name}}</td>
                  <td class="align_left">{{stock.supplier}}</td>
                  <td class="align_left">{{stock.batch_no}}</td>
                  <td class="align_center">{{stock.received_date}}</td>
                  <td>{{stock.exp_date}}</td>
                  <td class="align_right">{{stock.qty_onhand}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12" *ngIf="stockData.length == 0">
            <p class="nodata">No data found</p>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>