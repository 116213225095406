import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress';
declare var $:any;
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-recept-bill-create',
  templateUrl: './recept-bill-create.component.html',
  styleUrls: ['./recept-bill-create.component.css']
})
export class ReceptBillCreateComponent implements OnInit {
  public appointmentId;
  public sendData;
  public billDate;
  public chargeType: string;
  public chargeId: string;
  public amount;
  public finalAmount;
  public inGST: boolean;
  public ipaddress;
  public concession;
  public sendConcession;
  public session: string;
  public amountAftCon;
  public gstAmount;
  public advanceBill;
  public currentDate;
  public appointDate;
  public billingList = [];
  public doctorName;
  public hospitalName;
  public middleName: string;
  public billTreatmentPlan = [];
  public gstRetriveList = [];
  public billAddList = [];
  public doctorList = [];
  public gstDataList = [];
  public payTypeList = [];
  public insurerList = [];
  public chargeName;
  public estimateId = [];
  public remainingBalance;
  public sendAdvanceAmount;
  public sendTotalCollected;
  public cgst;
  public sgst;
  public clientRegId;
  public relationId;
  public subRelId;
  public hptlClinicId: string;
  public docRegId: string;
  public payType: string;
  public insurerId: string;
  public insurFlag: boolean;
  public doctorFullName: string;
  public userInfo;
  dtOptions: DataTables.Settings = {};
  public patientName: string;
  public prevBillId;
  public showBalanceFlag: boolean;
  public amountCollected: boolean;
  public showPrevBalanceFlag: boolean;
  public prevBalance;
  public totalCollected;
  public transId;
  public discount ="0.00";
  public paidBill = "0.00";
  public sign;
  public tempRoundoff = "0.00";
  public transactionFlag:boolean;
  public transactionId;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag:boolean;
  public bankName;
  public serviceType = [];
  public quantity = 1;
  public saveFlag:boolean = true;
  public getBils;
  public bill_qty_flag: boolean=false;
  public bill_cons_flag: boolean=false;
  public paytypreq: boolean=false;
  public payType1;
  public amountval:number;
  public paymentsarray:any=[];
  public tamt:number=0;
  private sno:number=1;
  public cardreqflag:boolean=false;
  public transreqflag:boolean=false;
  public chequereqflag:boolean=false;
  public insreqflag:boolean=false;

  public cardreqflag1:boolean=false;
  public transreqflag1:boolean=false;
  public chequereqflag1:boolean=false;
  public insreqflag1:boolean=false;

  public insurFlag1:boolean=true;
  public tranFlag1: boolean=true;
  public cardFlag1: boolean=true;
  public chequeFlag1: boolean=true;
  
  constructor(public toastr: ToastrService, public http: Http,public service: MenuViewService) {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;

    this.insurFlag1 = true;
    this.tranFlag1 = true;
    this.cardFlag1 = true;
    this.chequeFlag1 = true;
  }

  ngOnInit(): void {
    this.saveFlag = true;
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.payType = "Cash";
    this.userInfo = Helper_Class.getInfo();
    if(this.userInfo.bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }
    
    this.ipaddress = Helper_Class.getIPAddress();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getDoctors();
    this.getPaymentType();
    this.getInsurers();
    this.changeAmount();
   // this.getPaymentType();
    this.finalAmount = "0";
    this.gstAmount = "0";
    this.advanceBill = "0.00";
    this.concession = 0;
    this.inGST = false;
    this.gstRetriveList = [];
    this.billAddList = [];
    this.getGstData();
    this.estimateId = [];
    this.showPrevBalanceFlag = true;
    this.showBalanceFlag = true;
    this.appointDate = this.appointDate;
    if (this.currentDate == null) 
      this.getCurrentDate();
    else
      this.getAppointments();
    this.getInsurers();
    this.getBillType();
    if( Helper_Class.getBillingFlow() != undefined ){
      this.getBils = Helper_Class.getBillingFlow(); 
    }
    console.log("USER INFORMATION "+JSON.stringify(this.userInfo))
    if(this.userInfo.bill_qty_flag == 0){
      this.bill_qty_flag =true;
    } else {
      this.bill_qty_flag = false;
    }

    if(this.userInfo.bill_cons_flag == 0){
      this.bill_cons_flag =true;
    } else {
      this.bill_cons_flag = false;
    }
  }

  getDoctors() {//Get doctors
    var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "front-desk"
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/docbyrep',
      JSON.stringify({
        country: ipaddress.country_code,
        reg_id: this.userInfo.user_id,
        imei: this.ipaddress,
        type: type,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.doctors.length; i++) {
            this.doctorFullName = "";
            if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
              this.doctorFullName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
            } else {
              this.doctorFullName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
            }

            this.doctorList.push({
              doc_name: this.doctorFullName,
              doc_id: obj.doctors[i].prov_id
            });
          }
        },error => {});
  }

  back() {
    if(Helper_Class.getLoginType() == "Admin"){
      this.service.sendMessage('bill_list');
    } else {
      this.service.sendMessage('frontdeskbilling');
    }
  }       

  addBill() { //Bill add table
    var flag = true;
    if (this.appointmentId == undefined){
      this.toastr.error(Message_data.sltAppId);
      flag = false;

    } else if (this.chargeType == undefined) {
      flag = false;
      this.toastr.error(Message_data.sltBillType);

    } else if (this.amount == undefined || this.amount == ''){
      this.toastr.error(Message_data.enterAmt);
      flag = false;

    } else if (this.quantity == undefined) {
      this.toastr.error(Message_data.enterQty);
      flag = false;

    } if(this.payType =="2"){
      if(this.cardNumber== undefined || this.cardNumber ==""){
        flag = false;
      }
      if(this.transactionId ==undefined || this.transactionId ==""){
        flag = false;
      }
      if(flag == false){
        this.toastr.error(Message_data.getMandatory());
      }
    } else if(this.payType =="4" || this.payType =="5" || this.payType =="6"){
      if(this.transactionId ==undefined || this.transactionId ==""){
        flag = false;
        this.toastr.error(Message_data.getMandatory());
      }

    } else if(this.payType =="7"){
      if(this.cardNumber== undefined || this.cardNumber ==""){
        flag = false;
      }
      if(this.bankName ==undefined || this.bankName ==""){
        flag = false;
      }
      if(flag == false){
        this.toastr.error(Message_data.getMandatory());
      }
    } else {
      if(this.finalAmount == "0") 
      this.inGST == false;
    }

    for (var i = 0; i < this.billAddList.length; i++) {
      if (this.billAddList[i].biill_type == this.chargeType && this.billAddList[i].paytype == this.payType) {
        flag = false;
        break;
      }
    }

    if (flag == true) {
      if (this.chargeName.length != 0) {
        for (var i = 0; i < this.chargeName.length; i++) {
          if (this.chargeType == this.chargeName[i].description) {
            this.chargeId = this.chargeName[i].charge_id;
          }
        }
      }
      var paydesc;
      if (this.payTypeList.length != 0) {
        for (var i = 0; i < this.payTypeList.length; i++) {
          if (this.payType == this.payTypeList[i].pay_id) {
            paydesc = this.payTypeList[i].pay_desc;
          }
        }
      }

      if (this.concession == "") {
        this.sendConcession = 0;

      } else {
        this.sendConcession = this.concession;
      }

      if(this.paytypreq == true){
        this.billAddList.push({
          sno:this.sno,
          biill_type: this.chargeType,
          amount_txt: parseFloat(this.amount).toFixed(2),
          concession: parseFloat(this.sendConcession).toFixed(2),
          fee: parseFloat(this.amountAftCon).toFixed(2),
          charge_type: this.chargeId,
          insurer:this.insurerId,
          ref_code: this.transId,
          quantity:this.quantity,
        });

      } else {
        this.billAddList.push({
          sno:this.sno,
          biill_type: this.chargeType,
          amount_txt: parseFloat(this.amount).toFixed(2),
          concession: parseFloat(this.sendConcession).toFixed(2),
          fee: parseFloat(this.amountAftCon).toFixed(2),
          charge_type: this.chargeId,
          pay_type:paydesc,
          paytype:this.payType,
          insurer:this.insurerId,
          ref_code: this.transId,
          quantity:this.quantity,
        });
      }
      
      this.sno+=1;
      this.amount = '';
      this.concession = 0;
      this.amountAftCon = '';
      this.quantity = 1;
      this.chargeType="";
    }

    if (this.finalAmount == "0")
      this.inGST == false;

    this.calculateGst("0", "0");
  }
  
  deleteBill(type, amount) {//Delete bill
    if (this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].biill_type == type && this.billAddList[i].amount_txt == amount) {
          this.billAddList.splice(i, 1);
          this.calculateGst("0", "0");
          break;
        }
      }
    }

    if (this.finalAmount == "0")
      this.inGST == false;

    this.calculateGst("0", "0");
  }

  getBillType() {//Get bill type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      JSON.stringify({
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        provider: "doctor",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.chargeName = obj.charges;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getPaymentType() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.payTypeList = obj.payments;
          this.payType = this.payTypeList[0].pay_id;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changePayType() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    
    if(this.payType == "2"){
      this.transactionFlag = false;
      this.cardFlag = false;
      this.cardreqflag=true;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if (this.payType == "3"){
      this.cardreqflag=false;
      this.insurFlag = false;
      this.cardreqflag=false;
      this.transreqflag= false;
      this.chequereqflag = false;
      this.insreqflag = true;

    } else if(this.payType == "4"){
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "5") {
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "6"){
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "7") {
      this.chequeFlag = false;
      this.cardreqflag=false;
      this.transreqflag= false;
      this.chequereqflag = true;
      this.insreqflag = false;
    } 
  }

  changePayTypeReq() {
    this.insurFlag1 = true;
    this.tranFlag1 = true;
    this.cardFlag1 = true;
    this.chequeFlag1 = true;
    
    if(this.payType1 == "2"){
      this.tranFlag1 = false;
      this.cardFlag1 = false;
      this.insurFlag1=true;
      this.chequeFlag1=true;
      this.cardreqflag1=true;
      this.transreqflag1= true;
      this.chequereqflag1 = false;
      this.insreqflag1 = false;

    } else if (this.payType1 == "3"){
      this.tranFlag1 = true;
      this.cardFlag1 = true;
      this.insurFlag1=false;
      this.chequeFlag1=true;
      this.cardreqflag1=false;
      this.transreqflag1= false;
      this.chequereqflag1 = false;
      this.insreqflag1 = true;

    } else if(this.payType1 == "4"){
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;
      this.cardreqflag1=false;
      this.transreqflag1= true;
      this.chequereqflag1 = false;
      this.insreqflag1 = false;

    } else if(this.payType1 == "5") {
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;
      this.cardreqflag1=false;
      this.transreqflag1= true;
      this.chequereqflag1 = false;
      this.insreqflag1 = false;

    } else if(this.payType1 == "6"){
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;
      this.cardreqflag1=false;
      this.transreqflag1= true;
      this.chequereqflag1 = false;
      this.insreqflag1 = false;

    } else if(this.payType1 == "7") {
      this.tranFlag1 = true;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=false;
      this.cardreqflag1=false;
      this.transreqflag1= false;
      this.chequereqflag1 = true;
      this.insreqflag1 = false;
    } 
  }

  getInsurers() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeChargeType(charge) {
    this.chargeType = charge;
    this.serviceType = [];
      for (var i = 0; i < this.chargeName.length; i++) {
        if (this.chargeName[i].description == this.chargeType) {
          this.amount = this.chargeName[i].amount;
          this.amountAftCon = this.chargeName[i].amount;
        }
      }
  }

  getAppointments() {
    this.billingList = [];
    var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getapp',
      JSON.stringify({
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        date: this.currentDate,
        doc_reg_id: this.docRegId,
        type: type,
        rep_admin : "yes"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.appointment != null) {
            for (var i = 0; i < obj.appointment.length; i++) {
              if (obj.appointment[i].middle_name != undefined) {
                this.doctorName = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name);
              } else {
                this.doctorName = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name);
              }
              var sessiondata = null;
              if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
              }
              this.billingList.push({
                doc_reg_id: this.docRegId,
                doc_app_id: obj.appointment[i].doc_app_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                sub_rel_id: obj.appointment[i].sub_rel_id,
                first_name: obj.appointment[i].first_name,
                middle_name: obj.appointment[i].middle_name,
                last_name: obj.appointment[i].last_name,
                hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                session: sessiondata,
                docid_name: this.doctorName,
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeAppointmentId() {
    this.hospitalName = "";
    this.patientName = "";
    this.billTreatmentPlan = [];

    for (var i = 0; i < this.billingList.length; i++) {
      if (this.appointmentId == this.billingList[i].doc_app_id) {
        this.docRegId = this.billingList[i].doc_reg_id;
      }
    }

    this.getBillType();
    var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getappdet',
      JSON.stringify({
        appointment_id: this.appointmentId,
        doc_reg_id: this.docRegId,
        type: type
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.first_name != undefined) {
            var saldata;
            saldata = obj.salutation != undefined ? obj.salutation : "";
            this.hospitalName = obj.hosp_name;
            if (obj.middle_name != null && obj.middle_name != "" && obj.middle_name != undefined) {
              this.middleName = encrypt_decript.Decript(obj.middle_name);
            }
            if (obj.middle_name != undefined && obj.middle_name != "") {
              this.patientName = saldata + " " + encrypt_decript.Decript(obj.first_name) + " " + this.middleName + " " + encrypt_decript.Decript(obj.last_name);
            } else {
              this.patientName = saldata + " " + encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }

            if (obj.phases != null) {
              for (var i = 0; i < obj.phases.length; i++) {
                this.billTreatmentPlan.push({
                  treatment_desc: obj.phases[i].treatment_desc,
                  estimate: obj.phases[i].estimate,
                  den_treatment_plan_id: obj.phases[i].den_treatment_plan_id,
                });
              }
            }

            if (obj.advance != null) 
              this.advanceBill = parseFloat(obj.advance).toFixed(2);

            if (obj.prev_bill_id != null) 
              this.prevBillId = obj.prev_bill_id;

            if (obj.balance != "0") {
              this.amountCollected = true;
              this.showBalanceFlag = false;
              this.showPrevBalanceFlag = false;
              this.prevBalance = parseFloat(obj.balance).toFixed(2);
              this.finalAmount = parseFloat(obj.balance).toFixed(2);
              this.totalCollected = parseFloat(obj.balance).toFixed(2);
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeAmount() {// concession calculation
    var perce = null;
    perce = this.concession / 100;
    var amt = this.amount;
    if(this.quantity > 1){
      amt = amt * this.quantity;
    }
    var amt_cons = this.amountAftCon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAftCon = Math.round(tot);
  }

  getGstData() { //Get gst
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/tax/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.gstRetriveList = obj.taxes;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  includeGst(check) { // Include GST
    this.calculateGst("0", "0");
  }

  calculateGst(palnid, planmodal) {
    this.finalAmount = "0";
    this.gstAmount = "0";
    this.totalCollected = "0";
    this.gstDataList = [];

    if (this.billTreatmentPlan.length != 0) {
      for (var i = 0; i < this.billTreatmentPlan.length; i++) {
        for (var j = 0; j < this.estimateId.length; j++) {
          if (this.estimateId[j] == this.billTreatmentPlan[i].den_treatment_plan_id) {
            this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billTreatmentPlan[i].estimate)).toFixed(2);
            this.gstAmount = (parseFloat(this.gstAmount) + parseFloat(this.billTreatmentPlan[i].estimate)).toFixed(2);
            if (parseFloat(this.advanceBill) < parseFloat(this.finalAmount)) {
              this.showBalanceFlag = false;
              this.totalCollected = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill)).toFixed(2);
              this.remainingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill)).toFixed(2);
            }
            if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount)) {
              this.showBalanceFlag = false;
              this.remainingBalance = (parseFloat(this.advanceBill) - parseFloat(this.finalAmount)).toFixed(2);
            }
            this.amountCollected = true;

            if (this.totalCollected == "0")
              this.amountCollected = false;

            if (this.remainingBalance == "0") 
              this.showBalanceFlag = true;

            break;
          }
        }
      }
    }

    for (var i = 0; i < this.billAddList.length; i++) {
      this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billAddList[i].fee)).toFixed(2);
      this.gstAmount = (parseFloat(this.gstAmount) + parseFloat(this.billAddList[i].fee)).toFixed(2);

      if (parseFloat(this.advanceBill) < parseFloat(this.finalAmount)) {
        this.showBalanceFlag = false;
        this.remainingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill)).toFixed(2);
      }

      if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount)) {
        this.showBalanceFlag = false;
        this.remainingBalance = (parseFloat(this.advanceBill) - parseFloat(this.finalAmount)).toFixed(2);
        this.totalCollected = "0";
      }

      if (this.totalCollected == "0")
        this.amountCollected = false;

      if (this.remainingBalance == "0")
        this.showBalanceFlag = true;
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetriveList.length; i++) {
        this.gstDataList.push({
          Gst_Descrip: this.gstRetriveList[i].tax_desc + " " + this.gstRetriveList[i].tax,
          Gst_amount: (parseFloat(this.gstAmount) * (parseFloat(this.gstRetriveList[i].tax) / 100)).toFixed(2)
        });
        this.finalAmount = (parseFloat(this.finalAmount) + (parseFloat(this.gstAmount) * (parseFloat(this.gstRetriveList[i].tax) / 100)));
      }
      this.finalAmount = parseFloat(this.finalAmount).toFixed(2);
    }

    if (this.prevBalance != undefined)
      this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.prevBalance)).toFixed(2);

    //amount to be collected
    this.remainingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill)).toFixed(2);
    if (this.estimateId.length == 0 && this.billAddList.length == 0) {
      this.inGST = false;
      this.gstDataList = [];
      this.advanceBill = "0"
      this.finalAmount = "0";
      this.gstAmount = 0;
      this.totalCollected = "0";
      this.remainingBalance = "0";
    }

    if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount))
      this.remainingBalance = "0";
  }

  changeAdvance() {//add the bill details
    this.advanceBill = this.advanceBill == "" ? "0.00" : this.advanceBill;
    this.remainingBalance = ((parseFloat(this.finalAmount) - parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill))).toFixed(2);
  }

  addRound(data) {
    this.sign = data;
    this.discount = this.discount == "" ? "0.00" : this.discount;
    if (data == "puls") {
      this.remainingBalance = ((parseFloat(this.finalAmount) + parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill))).toFixed(2);
    } else {
      this.remainingBalance = ((parseFloat(this.finalAmount) - parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill))).toFixed(2);
    }
    this.tempRoundoff = this.discount;
  }

  changePaid() {
    this.paidBill = this.paidBill == "" ? "0.00" : this.paidBill;
    this.remainingBalance = ((parseFloat(this.finalAmount) - parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill))).toFixed(2);
  }

  save() {
    var flag = true;
    var paytype;
    var instype;

    if (this.billAddList.length == 0 && this.estimateId.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    } else {
      for (var i = 0; i < this.billAddList.length; i++) {
        paytype = this.billAddList[i].paytype;
        instype = this.billAddList[i].insurer;
      }
    }

    if (this.billAddList.length == 0 && this.estimateId.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    }

    if (this.billAddList.length == 0 && this.estimateId.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    }

    if (flag == true) {
      this.saveFlag = false;
      if (this.billingList.length != 0) {
        for (var i = 0; i < this.billingList.length; i++) {
          if (this.appointmentId == this.billingList[i].doc_app_id) {
            this.clientRegId = this.billingList[i].client_reg_id;
            this.relationId = this.billingList[i].relation_id;
            if (this.billingList[i].sub_rel_id != undefined) {
              this.subRelId = this.billingList[i].sub_rel_id;
            }
          }
        }
      }

      this.sendAdvanceAmount = this.advanceBill != undefined ? this.advanceBill : "0.00";
      this.sendTotalCollected = this.totalCollected != undefined ? this.totalCollected : "0";

      if (this.inGST == true && this.gstRetriveList.length != 0) {
        this.cgst = this.gstRetriveList[0].tax;
        this.sgst = this.gstRetriveList[1].tax;
      }

      var paid_flag;
      if( this.finalAmount == this.remainingBalance ){
        paid_flag = "Un paid";
      }else if (this.finalAmount != "0.00" && (this.sendAdvanceAmount != "0.00")   &&  this.finalAmount != this.sendAdvanceAmount){
        paid_flag = "Partially paid";
      }else if( this.remainingBalance == "0.00" || this.remainingBalance == "0" ){
        paid_flag = "Fully paid";
      }
      var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
      var userid = Helper_Class.getInfo().user_id;
      var balance = parseInt(this.remainingBalance) < 0 ? "0.00" : this.remainingBalance.toString();
      var finalamt = FrontDesk_Helper.setfrontdeskbillcreatesent_bill(this.finalAmount);
      this.sendData = JSON.stringify({
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        appointment_id: this.appointmentId,
        app_type: type,
        doc_reg_id: this.docRegId,
        bill_amount: encrypt_decript.Encript(this.finalAmount).toString(),
        paid_amount:this.paidBill,
        paid_flag : paid_flag,
        created_by: this.docRegId,
        bills: this.billAddList,
        country: ipaddress.country_code,
        estimates: this.estimateId,
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
        sub_rel_id: this.subRelId,
        advance: this.sendAdvanceAmount,
        balance: balance,
        round_off: this.discount.toString(),
        cgst: this.cgst,
        sgst: this.sgst,
        prev_bal: this.prevBalance,
        prev_bill_id: this.prevBillId,
        pay_type: paytype,
        insurer: instype,
        card_no : this.cardNumber,
        card_holder_name : this.cardHolderName,
        transaction_no : this.transactionId,
        bank_name: this.bankName,
        userid: userid,
        payments:this.paymentsarray,
      });
      
      var headers = new Headers();
      var url = 'bill/savebill/'
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + url, this.sendData,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json()
            if (obj["result"] != null) {
              if (obj["result"] == "Successfully generated bill") {
                this.saveFlag = false;
                this.toastr.success(Message_data.getBillGen());;
                this.service.sendMessage('frontdeskbilling');
              }else{
                this.saveFlag = true;
              }
            }
          },
          error => {
            this.saveFlag = true;
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }else{
      this.saveFlag = true;
    }
  }
  
  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.appointDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            this.billDate = this.appointDate;
          }
          this.getAppointments();
        },error => {});
  }

  getServiceType(serviceType){
    this.serviceType = [];
    if(serviceType.length >= 3){
      if(this.chargeName.length != 0){
        if(this.chargeName.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()))){
          var chg = this.chargeName.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()));
          for(var i= 0;i < chg.length; i++){
            this.serviceType.push(chg[i].description);
          }
        }else{
          this.serviceType = [];
        }
      }
    }
  }

  addPaymentDetails(){
    this.tamt+=this.amountval;

    if(this.tamt > parseInt(this.finalAmount)){
      this.toastr.error(Message_data.getGreaterAmount());
      this.tamt -=this.amountval;
    } else {
      var flag=false;
      
      if((this.payType != undefined && this.payType =="2") && ((this.cardNumber!= undefined &&this.cardNumber.length<4) || this.cardNumber== undefined)) {
        this.toastr.error("Enter last four digits of card")
        flag = true;
        this.tamt-=this.amountval;

      } else if((this.payType1 != undefined && this.payType1 =="2") && ((this.cardNumber!= undefined &&this.cardNumber.length<4) || this.cardNumber== undefined)) {
        this.toastr.error("Enter last four digits of card")
        flag = true;
        this.tamt-=this.amountval;
      }
      if(flag == false) {
        for(var i=0;i<this.payTypeList.length;i++){
          if(this.payType1 == this.payTypeList[i].pay_id){
            this.paymentsarray.push({
              pay_id:this.payTypeList[i].pay_id,
              pay_desc:this.payTypeList[i].pay_desc,
              amount:this.amountval.toFixed(2),
              trans_id:this.transactionId,
              insurer:this.insurerId,
              card_no:this.cardNumber,
            })
            this.amountval=0;
            this.transactionId=undefined;
            this.insurerId=undefined;
            this.cardNumber=undefined;
          }
        }
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=parseInt(data.amount);
    
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.tamt -=this.paymentsarray[i].amount;
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.tamt -=this.paymentsarray[i].amount;
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
