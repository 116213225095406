<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Manage appointments</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel()" width="85px"
              style="margin-right: 9px;" [hidden]="showcancel"/>
            <img src="../../../assets/ui_icons/buttons/confirm_button.svg" (click)="appointmentConfirm()"
              width="85px" [hidden]="showcancel" />
            <img class="imgbtn" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="create()"
              style="margin-left: 9px ;" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" style="margin-bottom: 15px;">
            <div class="row">
              <div class="col-12">
                <div class="switch-field" *ngIf="ynopt1 != undefined">
                  <input *ngIf="showDoctor == '1'" type="radio" id="radio-three" (click)="changeType(1,'doctor')" />
                  <label *ngIf="showDoctor == '1'" for="radio-three"
                    [ngClass]="ynopt1 == 'doctor' ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Doctor.svg" class="iocinStyle"
                          alt=""></div>
                      <div class="lableStyle">Doctor</div>
                    </div>
                  </label>
                  <input *ngIf="showDiag == '1'" type="radio" id="radio-four" (click)="changeType(1,'diagnosis')" />
                  <label *ngIf="showDiag == '1'" for="radio-four"
                    [ngClass]="ynopt1 == 'diagnosis' ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/diagnosis.svg" class="iocinStyle"
                          alt=""></div>
                      <div class="lableStyle">Diagnosis</div>
                    </div>
                  </label>
                  <input *ngIf="showDietician == '1'" type="radio" id="radio-five"
                    (click)="changeType(1,'dietician')" />
                  <label *ngIf="showDietician == '1'" for="radio-five"
                    [ngClass]="ynopt1 == 'dietician' ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Dietician_icon_svg.svg"
                          class="iocinStyle" alt=""></div>
                      <div class="lableStyle">Dietician</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom" *ngIf="doctorFlag">
            <mat-label class="matlabel">Doctor<br>
              <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)" [(ngModel)]="doctorArr"
                [compareWith]="compareById">
                <option *ngFor="let doctors of doctorList" [ngValue]="doctors" [selected]="doctors === doctorArr">
                  {{doctors.Doc_Name}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom" *ngIf="!doctorFlag">
            <mat-label class="matlabel">Diagnosis center<br>
              <select disableOptionCentering class="ipcss" [(ngModel)]="location" (change)="changeLocation(location)">
                <option *ngFor="let loc of diagLocationList" value={{loc.view_data}}>{{loc.view_data}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss_date" id="appt_date" (change)="selectAppointmentDate(appointmentDate)"
                [(ngModel)]="appointmentDate" #matInput style="width: 140px;">
            </mat-label>
          </div>
        </div>
        <div class="row" *ngIf="doctorFlag">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin: 10px 0 0 0px;">
            <p class="nodata" *ngIf="appointmentList.length == 0">No Appointments(s) Found</p>
            <table *ngIf="appointmentList.length != 0" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable billlisttable">
              <thead>
                <tr>
                  <th>Profile image</th>
                  <th>Patient id</th>
                  <th>Name</th>
                  <th>Token/Time</th>
                  <th>Contact no</th>
                  <th>Session</th>
                  <th>Package</th>
                  <th>Status </th>
                  <th style="width: 5%;">
                    <mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1"
                      (change)="checkUncheckAll()"></mdb-checkbox>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of appointmentList; let i = index" (click)="viewAppointment(person)">
                  <td><img src="{{person.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                      class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                  <td style="font-size: 12px;">{{ person.patient_id }}</td>
                  <td style="font-size: 12px;text-align: left;">{{ person.patient_name }}</td>
                  <td style="font-size: 12px;">{{ person.token }}</td>
                  <td style="font-size: 12px;">{{ person.contact }}</td>
                  <td style="font-size: 12px;">{{ person.session }}</td>
                  <td style="font-size: 12px;">{{ person.package }}</td>
                  <td style="font-size: 12px;">
                    <div style="position: relative;top: 9px;">
                      <img *ngIf="person.status_txt == 'Completed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/Completed_icon.svg" />
                      <img *ngIf="person.status_txt == 'Cancel'" width="30px" height="auto"
                        src="../../../assets/ui_icons/cancel_icon.svg" />
                      <img *ngIf="person.status_txt == 'Not visited'" width="30px" height="auto"
                        src="../../../assets/ui_icons/not_visited_icon.svg" />
                      <img *ngIf="person.status_txt == 'Confirmed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/confirmed_icon.svg" />
                      <img *ngIf="person.status_txt == 'Open'" width="30px" height="32px"
                        src="../../../assets/ui_icons/opened_icon.svg" />
                      <p>{{person.status_txt}}</p>
                    </div>
                  <td style="font-size: 12px;width: 5%;">
                    <mdb-checkbox [default]="true" [(ngModel)]="person.isSelected" name="list_name"
                      value="{{person.id}}" (change)="isAllSelected(person)"
                      [disabled]="person.status != '1' && person.status != '2'"></mdb-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <br><br><br>
          </div>
        </div>
        <div *ngIf="!doctorFlag">
          <div class="row">
            <div class="col-12" style="margin: 10px 0 0 0px;">
              <p class="nodata" [hidden]='diagAppointmentList.length!=0'>No Appointment(s) Found</p>
              <table *ngIf="diagAppointmentList.length" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th> session </th>
                    <th> Status </th>
                    <th style="width: 5%;">&nbsp;</th>
                    <th style="width: 5%;">
                      <mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1"
                        (change)="checkUncheckAll()"></mdb-checkbox>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of diagAppointmentList" >
                    <td style="text-align: left;" (click)='viewDiagAppointment(list.App_id,list.status)'>{{ list.Clnt_Name}}</td>
                    <td (click)='viewDiagAppointment(list.App_id,list.status)'> {{list.App_date}}</td>
                    <td (click)='viewDiagAppointment(list.App_id,list.status)'>{{list.Session}}</td>
                    <td style="font-size: 12px;" (click)='viewDiagAppointment(list.App_id,list.status)'>
                      <div style="position: relative;top: 9px;">
                        <img *ngIf="list.status == 'Completed'" width="30px" height="auto"
                          src="../../../assets/ui_icons/Completed_icon.svg" />
                        <img *ngIf="list.status == 'Cancel'" width="30px" height="auto"
                          src="../../../assets/ui_icons/cancel_icon.svg" />
                        <img *ngIf="list.status == 'Sample collected'" width="30px" height="auto"
                          src="../../../assets/ui_icons/sample_collected.svg" />
                        <img *ngIf="list.status == 'Confirmed'" width="30px" height="auto"
                          src="../../../assets/ui_icons/confirmed_icon.svg" />
                        <img *ngIf="list.status == 'Open'" width="30px" height="32px"
                          src="../../../assets/ui_icons/opened_icon.svg" />
                        <img *ngIf="list.status == 'Closed'" width="30px" height="32px"
                          src="../../../assets/ui_icons/Closed_icon.svg" />
                        <img *ngIf="list.status == 'Report yet to be finalized'" width="30px" height="32px"
                          src="../../../assets/ui_icons/report_yet_to_be_finalized.svg" />
                        <img *ngIf="list.status == 'Time alloted'" width="30px" height="32px"
                          src="../../../assets/ui_icons/time_not_alloted.svg" />
                        <p>{{list.status}}</p>
                      </div>
                    </td>
                    <td style="font-size: 12px;width: 5%;">
                      <img src="../../../assets/img/edit.png" class="app_edit" (click)="editAppointment(list.App_id,list.status)">
                    </td>
                    <td style="font-size: 12px;width: 5%;">
                      <mdb-checkbox [default]="true" [(ngModel)]="list.isSelected" name="list.Clnt_Name"
                        value="{{list.Clnt_Name}}" (change)="isAllSelected(list)"
                        [disabled]="list.status != 'Open' && list.status != 'Confirmed'"></mdb-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-keyboard="false" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <!-- style="position: fixed; bottom: 16%; right: 6%; margin: 0px; width: 33%;" -->
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="titleStyle">
                <h5 class="modal-title" id="staticBackdropLabel">Reasone for cancellation</h5>
                <span class="closeicon" data-bs-dismiss="modal" aria-label="Close" style="float: right;"><img
                    src="../../../assets/img/close_icon.png" alt="Card image cap" style="width:19px"> </span>
              </div>
              <div class="modal-body">
                <textarea class="ipcss noappt_width" style="height:108px !important;" [(ngModel)]="cancelResone"
                  maxlength="500"></textarea>
              </div>
              <div class="modal-footer">
                <img src="../../../assets/ui_icons/diet_plan/send_button.svg" (click)="sendCancel()"
                  alt="Card image cap" style="width:85px; height: 25px;">
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
  <div [hidden]="true">
    <div #printbanner id="printbanner">
      <!-- <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
        <tr>
          <td>
            <img alt="image" src={{hptlPresLogo}} [style]="preslogo_style" >
          </td>
        </tr>
      </table>
      <br /><br />
      <table style="margin-left: 50px; margin-top:20px;">
        <tr>
          <td style="width: 500px;">
            <p><b>{{'Name'}} :</b> {{patientName}}</p>
            <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
            <p><b>{{'Age'}} :</b> {{age}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'}}:
              </b>{{gender}} </p>
          </td>
          <td style="width: 300px;vertical-align: top;">
            <p><b>{{'Date'}} : </b>{{prescriptionDate}}</p>
            <p *ngIf="bpFlag">{{'BP'}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'}}: {{clntPulse}}</p>
            <p *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}} &nbsp;&nbsp;&nbsp;{{'Weight'}}:
              {{weight}}</p>
            <p *ngIf="temparatureFlag"><b>{{'Temperature'}}: </b>{{temparature}} </p>
            <p *ngIf="orthoFlag">{{'CVS'}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'}}: {{clntRs}}</p>
            <p *ngIf="orthoFlag">{{'Kidney'}}: {{clntKidney}}</p>
            <p *ngIf="pulmFlag">{{'PFT'}}: {{pft}}</p>
          </td>
        </tr>
      </table>
      <div style="margin-left: 50px;" *ngIf="complaints != undefined">
        <p><b>Complaints: </b>{{complaints}}</p>
      </div>
     
      <div style="margin-left: 50px;" *ngIf="investigation != undefined">
        <p><b>Investigation: </b>{{investigation}}</p> 
      </div>
   
      <div style="margin-left: 50px;" *ngIf="diagnosis != undefined">
        <p><b>{{'Diagnosis' |translate}}: </b>{{diagnosis}}</p> 
      </div>
    </div> -->
  </div>
</div>