<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Stock edit</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save_data()" [disabled]="isDisabled"
              class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Location<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="location">
                <option disabled>Select</option>
                <option (click)="get_stock(pro_type.pharma_facility_id)" *ngFor="let pro_type of loadLocationList"
                  value={{pro_type.location_id}}>{{pro_type.location_id}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12" *ngIf="stock.length != 0">
            <table *ngIf="stock.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th style="width: 30%;">Name</th>
                  <!-- <th>Short name</th> -->
                  <!-- <th style="width: 20% !important;text-align: left !important;">Generic name</th> -->
                  <!-- <th>Medicare name</th> -->
                  <!-- <th>Product type</th> -->
                  <!-- <th style="width: 10%;">UOM</th> -->
                  <th style="width: 20%;">Supplier</th>
                  <th style="width: 15%;">Batch</th>
                  <th style="text-align: left !important;width: 10%;">Receive qty</th>
                  <th style="width: 10%;">Free qty</th>
                  <th >MRP</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let stock of stock">
                  <td class="align_left">{{stock.short_name}} - {{stock.name}}</td>
                  <!-- <td class="align_left"> {{stock.short_name}}</td> -->
                  <!-- <td class="align_left">{{stock.generic_name}}</td> -->
                  <!-- <td class="align_left">{{stock.medicare_name}}</td> -->
                  <!-- <td class="align_left">{{stock.product_type_desc}}</td> -->
                  <!-- <td class="align_left">{{stock.uom_desc}}</td> -->
                  <td class="align_left">{{stock.mfg_name}}</td>
                  <td class="align_left">
                    <!-- {{stock.batch_no}}  -->
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.batch_no" style="text-align:right;" />
                  </td>
                  <td>
                    <!-- {{stock.exp_date}} -->
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.receive_qty" style="text-align:right;" />
                  </td>
                  <td class="align_left">
                    <!-- {{stock.qty_onhand}} -->
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.free_qty" style="text-align:right;" />
                  </td>
                  <td class="align_left">
                    <!-- {{stock.qty_onhand}} -->
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0" [(ngModel)]="stock.mrp"
                      style="text-align:right;" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12" *ngIf="stock.length == 0">
            <p class="nodata">No data found</p>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>