import { Component, OnInit, ViewChild } from '@angular/core';
// import { ipaddress.getIp, ipaddress.Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { Doc_Helper } from '../Doc_Helper';
import { Message_data } from 'src/assets/js/Message_data';
import { DisableRightClickService } from './disable-rightclick.service';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-reference-video',
  templateUrl: './reference-video.component.html',
  styleUrls: ['./reference-video.component.scss']
})
export class ReferenceVideoComponent implements OnInit {

  public userinfo;
  public videopath = [];
  public is_video_on:boolean =false;
  public play_video;
  public video_name;

  constructor(private rightClickDisable: DisableRightClickService,private http: Http, private router: Router, public doctormessageservice: MenuViewService) {
    
  }

  ngOnInit(): void {
    this.rightClickDisable.disableRightClick();
    this.userinfo = Helper_Class.getInfo();

    var send_data = JSON.stringify({
      // doc_reg_id:this.userinfo.user_id,
      
    })
    var sendData;
    if( this.userinfo.user_type == "front-desk" ){
      sendData = {
        type: "front-desk",
      }
    } else if(this.userinfo.provider == "pharmacy") {
      sendData = {
        type: "pharma",
      }
    } else if(this.userinfo.client != null) {
      sendData = {
        type: "client",
      }
    } else if(this.userinfo.provider == "diagnosis") {
      sendData = {
        type: "diagnosis",
      }
    } else if(this.userinfo.provider == "dietician") {
      sendData = {
        type: "dietician",
      }
    } else if(this.userinfo.provider == "nurse") {
      sendData = {
        type: "nurse",
      }
    } else if(this.userinfo.provider == "physio") {
      sendData = {
        type: "physio",
      }
    }else{ // doctor
      sendData = {
        type:this.userinfo.provider,
        spl_id:this.userinfo.specializations[0].spl_id,
      }
    }   

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'gen/vpath', JSON.stringify(sendData), { headers: headers }).subscribe(
      response => {
        var obj = response.json();
        this.videopath = []
        if(obj.video_paths != undefined){
          for(var i= 0; i< obj.video_paths.length; i++){
            this.videopath.push({
              name:obj.video_paths[i].name,
              img_path:ipaddress.Ip_with_img_address + obj.video_paths[i].image_path,
              video:ipaddress.Ip_with_img_address + obj.video_paths[i].video_path
            });
          }
        }

      })
  }

  playVideo(video,name){
    this.is_video_on = true;
    this.play_video = video;
    this.video_name = name;
  }

}
