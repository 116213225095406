import { Component, OnInit} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import {Message_data} from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diabeticsreading',
  templateUrl: './diabeticsreading.component.html',
  styleUrls: ['./diabeticsreading.component.css']
})
export class DiabeticsreadingComponent implements OnInit {
  public currentTime: any;
  public currentDate: any;
  public vldl: any;
  public trigs: any;
  public ldl: any;
  public hdl: any;
  public protien: any;
  public ketones: any;
  public glucose: any;
  public HbA1c: any;
  public postprandial: any;
  public oneAndHalf: any;
  public oneHour: any;
  public halfhour: any;
  public selectedId: any;
  public profileFlag: boolean = false;
  public clientViewFlag: boolean;
  public clientId: any;
  public profileImg: any;
  public clientArrayData: any;
  public clientArray: any=[];
  public searchValue: any;
  public ogitFlag: boolean;
  public fasting: boolean;
  public fastingDisFlag: boolean;
  public fastingHalfDisFlag: boolean;
  public saveFlag: boolean;
  public oneHourRequired: boolean;
  public oneHalfRequired: boolean;
  public glocoseFlag: boolean;
  public firstName;
  public Filter;
  public somePlaceholder;
  public firstNameFlag: boolean;
  public userInfo;
  public userId;
  public Select = "Select";
  constructor(public toastr: ToastrService,public http: Http,public diagservice:MenuViewService ) {
    this.saveFlag = true;
    this.firstNameFlag = false;
    this.Filter = "First name";
  }
  filterByPatients() {
    if (this.Filter == "First name") {
      this.somePlaceholder = "Search by first name";
      this.firstNameFlag = false;
    } else if (this.Filter == "Mobile") {
      this.somePlaceholder = "Search by mobile number";
      this.firstNameFlag = true;
    } 
  }
  ngOnInit(): void {
   
    this.userInfo=Helper_Class.getInfo();
    this.userId= Diagnosis_Helper.getUserID_Type().userId;
    this.getDate();
  }

  ogttChn() {
    if (this.ogitFlag == true) {
      this.fasting = false;
      this.fastingDisFlag = false;
      this.fastingHalfDisFlag = false;
      this.oneHourRequired = true;
      this.oneHalfRequired = true;
    } else {
      this.oneHourRequired = false;
      this.oneHalfRequired = false;
    }
  }

  fastChn() {
    if (this.fasting == true) {
      this.ogitFlag = false;
      this.fastingDisFlag = false;
      this.fastingHalfDisFlag = false;
      this.oneHour = "";
      this.oneAndHalf = "";
    }
  }

  getDate() {//Get current data
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "adm/curdate", JSON.stringify({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          this.currentTime = data.json().current_time;
        }
      });
  }

  getReadings() {
    if (this.searchValue != undefined) {
      this.firstName = encrypt_decript.Encript(this.searchValue).toString();
    }

    if (this.searchValue != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/glidn',
        JSON.stringify({
          first_name: this.firstName,
          filter:this.Filter,
          imei: Helper_Class.getIPAddress(),
          prov_id: this.userId,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj =response.json();
              this.clientArray=[];

              if (obj.lifestyles != null) {
                this.clientViewFlag = true
                this.clientArrayData = obj.lifestyles;
                for(var i=0;i<this.clientArrayData.length;i++){
                  var mname ;
                  mname = this.clientArrayData[i].middle_name != undefined ? encrypt_decript.Decript(this.clientArrayData[i].middle_name) : "";
                  
                  this.clientArray.push({
                    life_style_id:this.clientArrayData[i].life_style_id,
                    client_reg_id: this.clientArrayData[i].client_reg_id,
                    relation_id:this.clientArrayData[i].relation_id,
                    sub_rel_id: this.clientArrayData[i].sub_rel_id,
                    name: encrypt_decript.Decript(this.clientArrayData[i].first_name)+" "+mname+" "+encrypt_decript.Decript(this.clientArrayData[i].last_name),
                    profile_image:this.clientArrayData[i].profile_image
                  })
                }
                
                if (obj.lifestyles == "") {
                  this.toastr.error(Message_data.noClientFound);
                  this.clientViewFlag = false;
                }
              }
            //}
          },
          error => {
           this.toastr.error(Message_data.getNetworkMessage());
          }
        )
    } else {
     this.toastr.error(Message_data.mandatory);
    }
  }

  changeId(id) {
    if (id != undefined) {
      this.selectedId = id;
      this.profileImg = ipaddress.Ip_with_img_address + id.profile_image;
      this.profileFlag = true;
      this.saveFlag = false;
    } else {
      this.profileFlag = false;
      this.saveFlag = true;
    }
  }

  save_readings() {
    var flag = true;
    if (this.ogitFlag == true) {
      if (this.oneHour == undefined && this.oneAndHalf == undefined) {
        flag = false;
        this.toastr.error(Message_data.mandatory);
      }
    }

    if (flag == true && (this.ketones != undefined && this.ketones != "") || (this.protien != undefined && this.protien != "")) {
      if (this.glucose == undefined) {
        flag = false;
        this.toastr.error(Message_data.mandatory);
      }
    }

    var get_data= JSON.stringify({
      life_style_id: this.selectedId.life_style_id,
      fasting: this.halfhour,
      oneHour: this.oneHour,
      onehalfhour: this.oneAndHalf,
      twohours: this.postprandial,
      HbA1c: this.HbA1c,
      glucose: this.glucose,
      ketones: this.ketones,
      proteins: this.protien,
      hdl: this.hdl,
      ldl: this.ldl,
      trigs: this.trigs,
      vldl: this.vldl,
      created_date: this.currentDate,
      created_time: this.currentTime,
      country: ipaddress.country_code,
      prov_id: this.userInfo.user_id,

    })
    

    if (flag == true && this.halfhour != undefined && this.postprandial != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/diabread/",get_data, { headers: headers }).subscribe(
        data => {
          if (data.json().key == "1") {
           this.toastr.success(data.json().result);
           this.clearData();
          } else {
           this.toastr.error(data.json().result);
          }
        },
        error => {
         this.toastr.error(Message_data.getNetworkMessage());
        });
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  KetoneChange() {
    this.glocoseFlag = ((this.ketones != undefined && this.ketones != "") || (this.protien != undefined && this.protien != "")) ? true : false;
  }

  backClicked() {
    this.diagservice.sendMessage('home')
  }

  upper(){
    this.searchValue = this.searchValue.toLocaleUpperCase();
  }

  clearData(){
    this.Filter="First name";
    this.saveFlag = true;
    this.firstNameFlag=false;
    this.searchValue = undefined;
    this.clientArray = [];
    this.profileImg = "";
    this.fasting = false;
    this.ogitFlag = false;
    this.halfhour = "";
    this.oneHour = "";
    this.oneAndHalf = "";
    this.postprandial = "";
    this.HbA1c = "";
    this.glucose = "";
    this.ketones = "";
    this.protien = "";
    this.hdl = "";
    this.ldl = "";
    this.trigs = "";
    this.vldl = "";
    this.clientViewFlag = false;
  }
}
