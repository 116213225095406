import { Component, OnInit, ViewChild,ElementRef, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from '../../ipaddress';
import { Doc_Helper } from '../Doc_Helper';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-med-pres-view',
  templateUrl: './med-pres-view.component.html',
  styleUrls: ['./med-pres-view.component.scss']
})
export class MedPresViewComponent implements OnInit {
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printnoheadsidebar') printnoheadsidebar: ElementRef;
  @ViewChild('printbannerfullpage') printbannerfullpage: ElementRef;
  @ViewChild('printnoheaderwithname') printnoheaderwithname: ElementRef;

  public showdetails_flag: boolean;
  public docname_flag: boolean;
  private userinfo;
  private prescriptionUrl:string;
  public med_pres_list: any = [];
  public med_pres_list_data: any = [];
  public age;
  public docRegId;
  public pharmacyDetailList = [];
  public pharmacyFlag: boolean;
  public doctorQualification;
  public hospLogo;
  public hptlName;
  public address;
  public doctorLocation;
  public doctorCity;
  public doctorState;
  public doctorCountry;
  public doctorTelephone;
  public prescriptionPresId;
  public prescriptionFlag: boolean = false;
  public show: boolean;
  public patientName: string;
  public doctorName: string;
  public prescriptionDate: string;
  public diseaseName: string;
  public diseaseDescription: string;
  public subRelId: string;
  public relationId: string;
  public relationName: string;
  public hasPharmaFlag: boolean = false;
  public pharmacyName: string;
  public pharmacyAddr1: string;
  public pharmacyAddr2: string;
  public pharmacyLocation: string;
  public pharmacyCity: string;
  public pharmacyZipcode: string;
  public pharmacyState: string;
  public pharmacyCountry: string;
  public telephone: string;
  public pharmaId: string;
  public storePickUpFlag: boolean = false;
  public delopt: boolean = false;
  public homeDeliveryFlag: boolean = false;
  public validityDays;
  public workTime;
  public sendPresIdData;
  public appointment: boolean = false;

  public balanceQuantity: number = 0;
  public hospitalFlag: boolean;
  public listProducts = [];
  public everySix: string;
  public duration: string;
  public durationFlag: boolean;
  public intake: string;
  public instructions;
  public instructionFlag: boolean;
  public gender: string;
  public licenceCode: string;
  public printTemplate: string;
  public language: string;
  public clntBp: string;
  public clntPulse: string;
  public clntCvs: string;
  public clntRs: string;
  public clntKidney: string;
  public diagnosis: string;
  public treatment;
  public nextVisit: string;
  public hptlPresLogo:string;
  public investigation: string;
  public complaints:string;

  public orthoFlag:boolean = false;
  public bpFlag: boolean = false;
  public treatFlag: boolean = false;
  public pulmFlag: boolean = false;
  public backFlag:boolean=false;
  public getViewPresData;
  public pft;

  public pharmacyLogo;
  public height;
  public weight;
  public temparature;
  public heightFlag:boolean;
  public weightFlag:boolean;
  public temparatureFlag:boolean;
  public printFlag:boolean;
  public dur_flag: boolean= false;
  public dur_time_flag: boolean= false;
  public notes_flag: boolean= false;
  public print_view_flag: boolean = true;
  public spo2Flag: boolean=false;
  public prFlag:boolean=false;
  public userInfo;
  public mobile;
  public preslogo_style;
  public med_style;
  public dosage_style;
  public dur_style;
  public freq_style;
  public indur_style;
  public note_style;
  public apptype:string;
  public clntSpo2: string;
  public clntPr: string;
  public clntUHID:string;
  public prescriptionTime: string;
  public tablestyle;
  public print_template: string;
  public medstyle: boolean = false;
  public medstyle1: boolean = false;
  public medstyle2: boolean = false;
  public medstyle3: boolean = false;

  constructor(public sanitizer:DomSanitizer,public toastr: ToastrService, 
    public http: Http, public router: Router, public el: ElementRef,
    public doctormessageservice: MenuViewService) {

  }

  ngOnInit(): void {
    this.prescriptionUrl = "prescription/mpdoc";
    this.userinfo = Helper_Class.getInfo();
    this.age = Doc_Helper.getClient_Info().client_age;
    this.getPrescriptionList();
    this.showdetails_flag=true;
    if (this.userinfo.hospitals[0].pres_print_template != undefined) {
      this.print_template = this.userinfo.hospitals[0].pres_print_template;
    } else {
      this.print_template = "noheader";
    }
    this.preslogo_style = this.userinfo.hospitals[0].pres_print_logo_style; 

    if(this.showdetails_flag==true){
      this.tablestyle="padding:10px 32px; min-height: 200px !important; width:300px !important";
    }
  }

  backToList(){
    this.showdetails_flag = true;
    this.backFlag=false;
  }

  backClicked(){
    this.doctormessageservice.sendMessage("appointment_list");
  }

  getPrescriptionList() {
    this.med_pres_list = [];
    var agerange;
    if (this.age == "<1") {
      agerange = "<1";
    } else if (this.age == "1" || this.age == "2" || this.age == "3") {
      agerange = "between 1 and 3 ";
    } else if (this.age == "4" || this.age == "5") {
      agerange = "between 4 and 5 ";
    } else if (this.age == "6" || this.age == "7") {
      agerange = "between 6 and 7 ";
    } else if (this.age == "8" || this.age == "9" || this.age == "10") {
      agerange = "between 8 and 10 ";
    } else if (this.age == "11" || this.age == "12" || this.age == "13") {
      agerange = "between 11 and 13 ";
    } else if (this.age == "14" || this.age == "15") {
      agerange = "between 14 and 15 ";
    } else if (this.age == "16" || this.age == "17" || this.age == "18") {
      agerange = "between 16 and 18 ";
    } else if (this.age == "19" || this.age == "20" || this.age == "21") {
      agerange = "between 19 and 21 ";
    } else {
      agerange = "between 21 and 120 ";
    }

    var sendata;
    if (Doc_Helper.getClient_Info().Client_id != null && Doc_Helper.getClient_Info().sub_id != undefined &&
      Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id.length != 0) {
      sendata = JSON.stringify
        ({
          client_id: Doc_Helper.getClient_Info().Client_id,
          relation_id: Doc_Helper.getClient_Info().rel_id,
          sub_rel_id: Doc_Helper.getClient_Info().sub_id,
          spl_name: Doc_Helper.getClient_Info().spl,
        });

    } else {
      sendata = JSON.stringify
        ({
          client_id: Doc_Helper.getClient_Info().Client_id,
          relation_id: Doc_Helper.getClient_Info().rel_id,
          spl_name: Doc_Helper.getClient_Info().spl,
        });
    }

    if (this.med_pres_list.length == 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/mpbyclntid/',sendata,
        { headers: headers }).subscribe(
          response => {
            var data = response.json();

            if (data != null) {
              this.med_pres_list_data = data.prescriptions;
              if (this.med_pres_list_data != null) {
                this.showdetails_flag=true;
                for (var i = 0; i < this.med_pres_list_data.length; i++) {
                  var new_datalist = this.med_pres_list_data[i];
                  this.docname_flag = false;
                  var Docname = "";
                  if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                    Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;

                  } else {
                    Docname = new_datalist.first_name + " " + new_datalist.last_name;
                  }

                  var App_date = "";
                  if (new_datalist.date != null) {
                    App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
                  }

                  this.med_pres_list.push({
                    doctorname: Docname,
                    date: App_date,
                    disease: new_datalist.disease,
                    pres_drug_id: this.med_pres_list_data[i].pres_drug_id,
                    docname_flag: this.docname_flag,
                    remarks: this.med_pres_list_data[i].note
                  });
                }
              }
              // this.med_pres_listdata = this.med_pres_list.length == 0 ? true : false;
            }
          },
          error => { }
        )
    }
  }

  getPresDetails(prescription){
    this.pharmacyDetailList=[];
    var send_data = {
      pres_id: prescription.pres_drug_id,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.prescriptionUrl,
      JSON.stringify(
        send_data
      ),
      { headers: headers }).subscribe(
        data => {
          this.listProducts=[];
          this.prescriptionFlag = true;
          this.backFlag = true;
          var obj = JSON.parse(data["_body"]);
          console.log("PRES DETAILS "+JSON.stringify(obj))
          if (obj !=null && obj.length != 0) {
            this.showdetails_flag=false;
            this.age = obj.age;
            if(obj.gender != undefined){
              this.gender=encrypt_decript.Decript(obj.gender);
            }
            
            if(obj.print_template != undefined){
              this.printTemplate =obj.print_template;
            } else {
              this.printTemplate ="noheader";
            }
            
            this.language = obj.language;
            this.investigation = obj.oberse_med;
            this.complaints = obj.complaints;
            this.licenceCode = obj.licence_code;
            this.mobile = encrypt_decript.Decript(obj.mobile);
            
            if(obj.instructions != undefined && obj.instructions != "" && obj.instructions !=null){
              this.instructionFlag = false;
              this.instructions = this.sanitizer.bypassSecurityTrustHtml(obj.instructions);
            }else{
              this.instructionFlag = true;
              this.instructions = "";
            }

            if (obj.first_name != null && obj.first_name != undefined) {
              if (obj.middle_name != null && obj.middle_name != "") {
                this.doctorName = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
              } else {
                this.doctorName = obj.first_name + ' ' + obj.last_name;
              }
            }

            if (obj.qualification != null) {
              this.doctorQualification = obj.qualification;
            }

            if (obj.cl_middle_name != null && obj.cl_middle_name != "" && encrypt_decript.Decript(obj.cl_middle_name) != undefined) {
              this.patientName = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            } else {
              this.patientName = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            }
            if(obj.salutation != undefined){
              this.patientName = obj.salutation+". "+this.patientName
            }
            if (obj.doc_reg_id != null) {
              this.docRegId = obj.doc_reg_id;
            }

            if(obj.hptl_pres_logo != undefined){
              this.hptlPresLogo = ipaddress.Ip_with_img_address+obj.hptl_pres_logo;
            }

            this.hospLogo = obj.hosp_logo != undefined ? ipaddress.Ip_with_img_address+obj.hosp_logo : "assets/imgs/hospital.png";
           
            if ((obj.hptl_name != null && obj.hptl_name != undefined) && (obj.address1 != null && obj.address1 != undefined)) {
              this.hospitalFlag = false;
              if (obj.hptl_name != null) {
                this.hptlName = obj.hptl_name;
              }

              if ((obj.address1 != null && obj.address1 != undefined) && (obj.address2 != null && obj.address2 != undefined)) {
                this.address = (obj.address1.toString()) + ' ' + (obj.address2.toString() + ', ');
              } else {
                if (obj.address1 != null && obj.address1 != undefined) {
                  this.address = (obj.address1.toString()) + ', ';
                }
              }

              if (obj.location != null) {
                this.doctorLocation = obj.location;
              }

              if (obj.city != null && obj.zipcode != null) {
                this.doctorCity = obj.city + " - " + obj.zipcode;
              }

              if (obj.state != null) {
                this.doctorState = obj.state;
              }

              if (obj.country != null) {
                this.doctorCountry = obj.country;
              }

              if (obj.telephone != null) {
                this.doctorTelephone = obj.telephone;
              }
            } else {
              this.hospitalFlag = true;
            }

            if (obj.pharmacy_id != null) // Suggested Pharmacy
            {
              this.pharmacyFlag = true;
              if (obj.pharmacy_id != null) {
                this.pharmaId = obj.pharmacy_id;
              }
              if (obj.pharmacy_name != null) {
                this.pharmacyName = obj.pharmacy_name;
                if (Helper_Class.getapp_flow() == "order") {
                  this.hasPharmaFlag = true;
                }
              } else {
                this.hasPharmaFlag = false;
              }

              if (obj.par_address1 != null) {
                this.pharmacyAddr1 = obj.par_address1;
              }

              if (obj.par_address2 != null) {
                this.pharmacyAddr2 = obj.par_address2;
              }

              if (obj.par_location != null) {
                this.pharmacyLocation = obj.par_location;
              }

              if (obj.par_city != null) {
                this.pharmacyCity = obj.par_city;
              }

              if (obj.par_zipcode != null) {
                this.pharmacyZipcode = obj.par_zipcode;
              }

              if (obj.par_state != null) {
                this.pharmacyState = obj.par_state;
              }

              if (obj.par_country != null) {
                this.pharmacyCountry = obj.par_country;
              }

              if (obj.par_telephone != null) {
                this.telephone = obj.par_telephone;
              }

              if(obj.phar_logo != undefined){
                this.pharmacyLogo = ipaddress.Ip_with_img_address+obj.hosp_logo;
              } else{
                this.pharmacyLogo ="assets/imgs/hospital.png";
              }

              this.pharmacyDetailList.push({
                pharmacy_name: this.pharmacyName,
                phar_addr1: this.pharmacyAddr1,
                phar_addr2: this.pharmacyAddr2,
                phar_loc: this.pharmacyLocation,
                phar_city: this.pharmacyCity,
                phar_zipcode: this.pharmacyZipcode,
                phar_state: this.pharmacyState,
                phar_cnty: this.pharmacyCountry,
                telephone: this.telephone,
                phar_logo: this.pharmacyLogo
              })
            }
            
            if (obj.store_pickup != null || (obj.store_pickup != null && obj.store_pickup == "1")) {
              this.storePickUpFlag = true;
              this.delopt = true;
            }

            if (obj.home_delivery != null || (obj.home_delivery != null && obj.home_delivery == "1")) {
              this.homeDeliveryFlag = true;
              this.delopt = true;
            }

            if (obj.store_pickup_validity_days != null) {
              this.validityDays = obj.store_pickup_validity_days;
            }

            if(obj.pres_date != undefined){
              this.prescriptionDate = obj.pres_date.split('-')[2] + "-" + obj.pres_date.split('-')[1] + "-" + obj.pres_date.split('-')[0];
            }
            
            this.diseaseName = obj.disease;
            this.diseaseDescription = obj.dis_desc;

            if (obj.sub_rel_id != null) {
              this.subRelId = obj.sub_rel_id;
            }

            if (obj.relation_id != null) {
              this.relationId = obj.relation_id;
            }

            if (obj.relationship_name != null) {
              this.relationName = obj.relationship_name;
            }

            if (obj.available_from != null) {
              this.workTime = "Timings : " + obj.available_from + " - " + obj.available_to;
            }           

            if(obj.blood_pressure != undefined && obj.blood_pressure != ""){
              this.clntBp=obj.blood_pressure;
              this.bpFlag = true;
            }else{
              this.bpFlag = false;
            }
            if(obj.apptype != undefined && obj.apptype != ""){
              this.apptype=obj.apptype;
            }
            if(obj.clntUHID != undefined && obj.clntUHID != ""){
              this.clntUHID=obj.clntUHID;
            }
            
            if(obj.clntPr != undefined && obj.clntPr != ""){
              this.clntPr=obj.clntPr;
              this.prFlag = true;
            }else{
              this.prFlag = false;
            }

            if(obj.clntSpo2 != undefined && obj.clntSpo2 != ""){
              this.clntSpo2=obj.clntSpo2;
              this.spo2Flag = true;
            }else{
              this.spo2Flag = false;
            }

            if(obj.cvs != undefined && obj.cvs.trim() != "" && obj.cvs != null && (obj.cvs.trim()).length != 0){
              this.clntCvs=obj.cvs;
              this.orthoFlag = true;
            }else{
              this.orthoFlag = false;
            }

            if(obj.kidney !=undefined && obj.kidney != ""){
              this.clntKidney = obj.kidney;
              this.orthoFlag = true;
            }else{
              this.orthoFlag = false;
            }

            if(obj.rs !=undefined && obj.rs != ""){
              this.clntRs=obj.rs;
              this.orthoFlag = true;
            }else{
              this.orthoFlag = false;
            }

            if(obj.pft !=undefined && obj.pft != ""){
              this.pft=obj.pft;
              this.pulmFlag = true;
            }else{
              this.pulmFlag = false;
            }

            if(obj.height != undefined && obj.height != null && obj.height != ""){
              this.height = obj.height + obj.height_measure;
              this.heightFlag = true;
            }else{
              this.heightFlag = false;
            }
            if(obj.weight != undefined && obj.weight != null && obj.weight != ""){
              this.weight = obj.weight + obj.weight_measure;
              this.weightFlag = true;
            }else{
              this.weightFlag = false;
            }
            if(obj.temparature != undefined && obj.temparature != null){
              this.temparature = obj.temparature;
              this.temparatureFlag = true;
            }else{
              this.temparatureFlag = false;
            }

            if(obj.treatment !=undefined  && obj.treatment != ""){
              this.treatment = this.sanitizer.bypassSecurityTrustHtml(obj.treatment);
              this.treatFlag = true;
            }else{
              this.treatFlag = false;
            }

            this.diagnosis=obj.disease;
            if(obj.next_visit != undefined){
              var nextdate = obj.next_visit.split('-');
              this.nextVisit=nextdate[2]+"-"+nextdate[1]+"-"+nextdate[0];
            }
            
            for(var i=0;i<obj.drug_list.length;i++){
              if(obj.drug_list[i].show_short_form =="1"){
                var shortform=obj.drug_list[i].short_form;
                if(obj.drug_list[i].intake =="1"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - 0 - 0";
                } else if(obj.drug_list[i].intake =="2"){
                  this.everySix="0 - "+obj.drug_list[i].afternoon+shortform+" - 0";
                } else if(obj.drug_list[i].intake =="3"){
                  this.everySix="0 - 0 - "+obj.drug_list[i].night+shortform;
                } else if(obj.drug_list[i].intake =="4"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - "+obj.drug_list[i].afternoon+shortform+" - 0";
                } else if(obj.drug_list[i].intake =="5"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - 0 - "+obj.drug_list[i].night+shortform;

                } else if(obj.drug_list[i].intake =="6"){
                  this.everySix="0 - "+obj.drug_list[i].afternoon+shortform+" - "+obj.drug_list[i].night+shortform;

                } else if(obj.drug_list[i].intake =="7"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - "+obj.drug_list[i].afternoon+shortform+" - "+obj.drug_list[i].night+shortform;

                } else if(obj.drug_list[i].intake =="8"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - "+obj.drug_list[i].afternoon+shortform+" - "+obj.drug_list[i].evening+shortform+" - "+obj.drug_list[i].night+shortform;

                } else if(obj.drug_list[i].intake =="9"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - Every 6 hours";

                } else if(obj.drug_list[i].intake =="10"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - Every 8 hours";

                } else if(obj.drug_list[i].intake =="11"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - Every 12 hours ";

                } else if(obj.drug_list[i].intake =="12"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - Once a day";

                } else if(obj.drug_list[i].intake =="13"){
                  this.everySix=obj.drug_list[i].morning+shortform+" SOS - if required";

                } else if(obj.drug_list[i].intake =="14"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - "+obj.drug_list[i].afternoon+shortform+" - "+obj.drug_list[i].evening+shortform+" - "+obj.drug_list[i].night+shortform +" Alternative days";

                } else if(obj.drug_list[i].intake =="15"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - "+obj.drug_list[i].afternoon+shortform+" - "+obj.drug_list[i].evening+shortform+" - "+obj.drug_list[i].night+shortform +" Once a week";

                } else if(obj.drug_list[i].intake =="16"){
                  this.everySix=obj.drug_list[i].morning+shortform+" - "+obj.drug_list[i].afternoon+shortform+" - "+obj.drug_list[i].evening+shortform+" - "+obj.drug_list[i].night+shortform +" Once in two weeks";

                } else if(obj.drug_list[i].intake =="17"){
                  this.everySix=obj.drug_list[i].morning+shortform+" STAT";
                } 

                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.duration = obj.drug_list[i].time_duration + " " + "mins";

                } else {
                  this.duration = "";
                  this.durationFlag= true;
                }

                var frequency;
                if(obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks"){
                  frequency = this.intake;

                } else {
                  frequency ="Daily";
                }

                this.listProducts.push({
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  short_name: obj.drug_list[i].short_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].period,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.everySix,
                  dure_txt_table: this.duration,
                  time_duration: obj.drug_list[i].time_duration,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  remarks: obj.drug_list[i].remarks
                });
  
              } else {
                if(obj.drug_list[i].intake =="1"){
                  this.everySix=obj.drug_list[i].morning+" - 0 - 0";

                } else if(obj.drug_list[i].intake =="2"){
                  this.everySix="0 - "+obj.drug_list[i].afternoon+" - 0";

                } else if(obj.drug_list[i].intake =="3"){
                  this.everySix="0 - 0 - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="4"){
                  this.everySix=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - 0";

                } else if(obj.drug_list[i].intake =="5"){
                  this.everySix=obj.drug_list[i].morning+" - 0 - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="6"){
                  this.everySix="0 - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="7"){
                  this.everySix=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="8"){
                  this.everySix=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].evening+" - "+obj.drug_list[i].night;

                } else if(obj.drug_list[i].intake =="9"){
                  this.everySix=obj.drug_list[i].morning+" - Every 6 hours";

                } else if(obj.drug_list[i].intake =="10"){
                  this.everySix=obj.drug_list[i].morning+" - Every 8 hours";

                } else if(obj.drug_list[i].intake =="11"){
                  this.everySix=obj.drug_list[i].morning+" - Every 12 hours";

                } else if(obj.drug_list[i].intake =="12"){
                  this.everySix=obj.drug_list[i].morning+" - Once a day";

                } else if(obj.drug_list[i].intake =="13"){
                  this.everySix=obj.drug_list[i].morning+" SOS - if required";

                } else if(obj.drug_list[i].intake =="14"){
                  this.everySix=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].evening+" - "+obj.drug_list[i].night +" Alternative days";

                } else if(obj.drug_list[i].intake =="15"){
                  this.everySix=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].evening+" - "+obj.drug_list[i].night +" Once a week";

                } else if(obj.drug_list[i].intake =="16"){
                  this.everySix=obj.drug_list[i].morning+" - "+obj.drug_list[i].afternoon+" - "+obj.drug_list[i].evening+" - "+obj.drug_list[i].night +" Once in two weeks";

                } else if(obj.drug_list[i].intake =="17"){
                  this.everySix=obj.drug_list[i].morning+" STAT";
                }  

                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.duration = obj.drug_list[i].time_duration + " " + "mins";

                } else {
                  this.duration = "";
                  this.durationFlag= true;
                }

                var frequency;
                if(obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks"){
                  frequency = this.intake;

                } else {
                  frequency ="Daily";
                }

                this.listProducts.push({
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  short_name: obj.drug_list[i].short_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].period,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.everySix,
                  dure_txt_table: this.duration,
                  time_duration: obj.drug_list[i].time_duration,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  remarks: obj.drug_list[i].remarks
                });
              }
            }

            if (this.balanceQuantity == 0) {
              this.hasPharmaFlag = false; // Normal flow
            }

            if (Helper_Class.getapp_flow() != null && this.balanceQuantity == 0) {
              if (Helper_Class.getapp_flow() == "order") {
                this.hasPharmaFlag = true;

              }
            }
          }
        },
        error => {});
  }

  print_area() {
    let printContents, popupWin;

    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
      printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "bannerfullpage") {
      printContents = this.printbannerfullpage.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "noheaderwithname") {
      printContents = this.printnoheaderwithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }

    console.log("PRINT CONTENT "+JSON.stringify(printContents))
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Prescription</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
          table_class {
          background-color: rgb(108, 180, 248);
        }
        .casesheet_container {
           width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        @media all {
          .page-break { display: none; }
          }
            
          @media print {
          .page-break { display: block; page-break-before: always; }
          }
        @media print {
          body{
            -webkit-print-color-adjust: exact;
            -moz-print-color-adjust: exact;
            -ms-print-color-adjust: exact;
            print-color-adjust: exact;
          }
          footer {
            position: fixed;
            bottom: 0;
          }
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
    </footer>
    </html>`
    );
    popupWin.document.close();
    printContents = "";
  }
}
