import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
import { Date_Formate } from 'src/assets/js/common';
import { PharmaPrintService } from '../pharmacy-print.service';
import { Message_data } from 'src/assets/js/Message_data';
import moment from 'moment';
import { pharmacy_helper } from '../Pharmcy_Helper';
declare var $: any;

@Component({
  selector: 'app-inpatient-write-rx',
  templateUrl: './inpatient-write-rx.component.html',
  styleUrls: ['./inpatient-write-rx.component.scss']
})
export class InpatientWriteRxComponent implements OnInit {
  public amtbdGST;
  public prodAmount;
  public prodDiscount: any = 0.00;
  public amountDiscount = "0.00";
  preferredTimeFlag: boolean;
  orderDate: string;
  orderTime: string;
  public drugDetailsArray:any = [];
  taxArray = [];
  taxpercentageArray = [];
  dureShowFlag;
  public doctorName = "";
  taxAmount: string;
  finalAmount: any;
  totalAmount: any;
  todayDate: string;
  paidText = "0.00";
  remaingBalance = "0.00";
  shortName: string;

  name: any = "";
  address1: any;
  address2: any;

  public imageString;
  saveFlag: boolean;
  printFlag: boolean;
  public medicineArray = [];

  public pharmacyName: string;
  public pharmacyAddress: string;
  public pharmacyCSZ: string;
  public pharmacyMobile: string;
  public pharmacyCount: string;
  public PurchaseType: string;

  public medicalPrescriptionArray = [];
  public MedicineNameArray = [];
  public medicineListArray = [];
  public medicineDataArray = [];
  public drugArray = [];
  public discSalesVolArray = [];
  public discTransVolArray = [];
  public discregSalesVolArray = [];
  public discountDatas = [];
  public discType: string;

  public discURL: string;
  discountFlag: boolean;

  public medicareName: string;
  public medTypeTxt: string;
  public drugType: string;
  public medicineName: string;
  public medID: string;
  public batchNumber: string;
  public saveOrderURl: string;
  public clientName: string = "";

  public sendData;
  public price;
  public qty;
  public disc;
  public gst;
  public amt;
  public perUnit;
  public imageStr;
  public ReferedDoctorArray = [];
  public referedName = [];
  public getData;
  public pharmacistID;
  public pharmacyID;
  public ipaddress;
  //mdbTable
  dtOptions: DataTables.Settings = {};

  public pharmaLogo;
  public billTemplate;
  public stateGST;
  public centerGST;
  public bgImage: string;
  public location;
  public city;
  public state;
  public country;
  public pharmaName;
  public dlNO;
  public GSTNo;
  public pharmaAddress1;
  public pharmaAddress2;
  public billID = undefined;

  public displayDescription: boolean;
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('billid') billid: ElementRef;

  public tranFlag: boolean;
  public TransactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public payTypeListArray: any = [];
  public insurerListArray: any = [];
  public payType: string;
  public insurerID: string;
  public hospitalID;
  public docRegID;
  public billingCreateArray: any = [];
  public docIDWithName;

  public appointText;
  public showSaveFlag: boolean = true;
  public rowID: string;

  public addMedFlag: boolean = false;
  public currentDatetime;
  public appDate: string;
  public dayDuration;
  public dctHospital: string;
  public doctName: string;
  public doctLocation: string;
  public doctAddress: string;
  public doctCity: string;
  public doctState: string;
  public doctClientName: string;

  public engFlag: boolean;
  public tamilFlag: boolean;
  public teluguFlag: boolean;
  public kannadaFlag: boolean;
  public malayFlag: boolean;
  public orthoFlag: boolean;
  public hospitalInfoArray;

  public symptomsFlag: boolean;
  public medTypeText: string;

  public drugMedID: string;
  public getIntakeArray = [];
  public medDetailListArray = [];
  public intake: string;
  public mor: string;
  public aft: string;
  public eve: string;
  public ngt: string;
  public everySix: string;
  public dureText: string;
  public dureWrite: string;
  public listProducts = [];
  public dayText: string;
  public afterfoodText: string;
  public medicineTableFlag: boolean = true;
  public prferPharma: string;
  public preferAdd: string;
  public ClientID;
  public relationID;
  public subRelationID;
  public appID;

  public discout;
  public pharmaID;

  public morLable: boolean = true;
  public afterEvent: boolean = true;
  public morID: boolean;
  public afterID: boolean;
  public intake_id;

  public morningRequset: boolean;
  public morningDiaplayFlag: boolean;
  public afternoonReq: boolean;
  public evenDisplayFlag: boolean;
  public afternoonDisplayFlag: boolean;
  public evening_req: boolean;
  public nightReq: boolean;
  public nightDis: boolean;
  public dureFlag: boolean;

  public shortForm;
  public show;
  public nightEvent: boolean = true;
  public mornEventxt: boolean;
  public intakeShow: boolean;
  public eveID: boolean;
  public ngtDisCount: boolean;
  public dureRequire: boolean;

  public hptlName;
  public address;
  public subRelID: string;
  public telephone: string;

  public appointment: boolean = false;
  public symptoms: string;
  public language: string;

  public daydurationShow: boolean;
  public currentDate;
  public symptomData = [];
  public symptomListArray = [];
  public symptomsListArray = [];
  public selectedSymptoms = [];
  public newSymptomsArray = [];
  public symptomsText = "";
  public presID;
  public tempBatch;
  public expiryDate;
  public recItemID;
  public batchRequired = {"border-color": "rgb(250, 40, 2)"};
  public showBatchArray:boolean = false;
  public quantityDisable:boolean = true;
  public save_flag:boolean = true;
  public productReturn;
  public genericDisplayFlag:boolean = false;
  public date_time_flag:boolean=false;
  public payDes;
  public userArray=[];
  public usertype: string;

  constructor(public pharmacyservice: PharmacyService, public toastr: ToastrService, public gservice: CommonDataService, public http: Http,public router: Router, public dialog: MatDialog, public printService: PharmaPrintService) {
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.printFlag = true;
    this.saveFlag = true;
    this.saveOrderURl = ipaddress.getIp + "pharmacontrol/sporder";
    this.PurchaseType = "Store pickup"
    this.discURL = ipaddress.getIp.toString() + "pharmacontrol/gdis";
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.userArray=[{user_type:"1",user_type_desc:"Active"},{user_type:"0",user_type_desc:"Discharged"}];
    document.getElementById("pharma_inpattbl_overlay").style.overflowX = "auto";
    this.usertype=this.userArray[0].user_type;

    this.loadScript();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.getData = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();
    this.pharmacistID = this.getData.pharmacy;
    this.pharmacyID = this.getData.hospitals[0].hptl_clinic_id;
    this.pharmaName = this.getData.hospitals[0].hptl_name;
    this.location = this.getData.hospitals[0].location_desc;
    this.city = this.getData.hospitals[0].city_desc;
    this.state = this.getData.hospitals[0].state_desc;
    this.country = this.getData.hospitals[0].country_desc;
    this.pharmaAddress1 = this.getData.hospitals[0].address1 != undefined && this.getData.hospitals[0].address1 != null && this.getData.hospitals[0].address1 != "" && this.getData.hospitals[0].address1 != "undefined" ? this.getData.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.getData.hospitals[0].address2 != undefined && this.getData.hospitals[0].address2 != null && this.getData.hospitals[0].address2 != "" && this.getData.hospitals[0].address2 != "undefined" ? this.getData.hospitals[0].address2 : undefined;

    this.dlNO = this.getData.hospitals[0].dl_no;
    this.GSTNo = this.getData.hospitals[0].gst_no;

    this.medicareName = this.getData.Medicare_name != undefined && this.getData.Medicare_name != "undefined" ? this.getData.Medicare_name : "allopathy";
    this.pharmaLogo = ipaddress.Ip_with_img_address + this.getData.hospitals[0].bill_logo;
    this.billTemplate = this.getData.hospitals[0].bill_template;
    this.bgImage = this.getData.hospitals[0].bg_image != undefined ? this.getData.hospitals[0].bg_image : undefined;
    this.productReturn = this.getData.hospitals[0].product_return;

    this.CurrentDate();
    this.getPharmacyDetails();
    this.getDiscTypes();
    this.getDiscountDetails();
    this.getPaymentType();
    this.getIntakeSession();
    this.dayDuration = "day(s)";
    this.Duration();
    this.afterfoodText = "After food";
    this.addNewDrugDetails();
    this.changeIntake(this.drugDetailsArray);
    var Moduleidlist;
    if (pharmacy_helper.getModuleList() != null) {
      Moduleidlist = pharmacy_helper.getModuleList(); 
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "99") {
          this.genericDisplayFlag = true;
        }
      }
    }
    this.doctorName="All";
    this.docRegID="All";
    if(Helper_Class.getInfo().hospitals[0].bill_date_time_edit == "1"){
      this.date_time_flag = false;
    } else {
      this.date_time_flag = true;
    }
    this.hospitalID = Helper_Class.getInfo().hosp_id;
    this.Select_refer_doctor("All");
  }

  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/dist/inpatient-write-rx.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  addNewDrugDetails(){
    var index = 0;
    if(this.drugDetailsArray.length == 0){ 
      this.drugDetailsArray.push({
        drug_id: "",
        type_name: "",
        drug_name: "",
        drug_disp_name: "",
        quantity: "",
        tempquantity: "",
        amount: "",
        price: "",
        discount: "",
        per_unit: "",
        prod_disc: "",
        batch_no: "",
        amt_bdgst: "",
        cgst: "",
        sgst: "",
        expiry_date: "",
        rec_item_id:"",
        index:index,     
        batch_required: {"border-color": "rgb(250, 40, 2)"},
        batcharray:"",
        day_txt:"",
        daydur_txt:"day(s)",
        afterfood_txt:"After food",
        dure_txt:"30",
        intake_txt:"Thrice a day",
        mor:"",
        aft:"",
        eve:"",
        ngt:"",
        every_six:"1 - 1 - 1",
        mor_lable:false,
        quan_lable:true,
        show_batcharray:false,
        quantityDisable:true,
      });
      
    }else{
      for(var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++){
        if( this.drugDetailsArray[i].drug_name != "" && this.drugDetailsArray[i].batch_no != "" &&  this.drugDetailsArray[i].quantity != "" && this.drugDetailsArray[i].day_txt != ""){
          index = this.drugDetailsArray[i].index + 1;
          this.drugDetailsArray.push({
            drug_id: "",
            type_name: "",
            drug_name: "",
            drug_disp_name: "",
            quantity: "",
            tempquantity: "",
            amount: "",
            price: "",
            discount: "",
            per_unit: "",
            prod_disc: "",
            batch_no: "",
            amt_bdgst: "",
            cgst: "",
            sgst: "",
            expiry_date: "",
            rec_item_id:"",
            index:index,     
            batch_required: {"border-color": "rgb(250, 40, 2)"},
            batcharray:"",
            day_txt:"",
            daydur_txt:"day(s)",
            afterfood_txt:"After food",
            dure_txt:"30",
            intake_txt:"Thrice a day",
            mor:"",
            aft:"",
            eve:"",
            ngt:"",
            every_six:"1 - 1 - 1",
            mor_lable:false,
            quan_lable:true,
            show_batcharray:false,
            quantityDisable:true,

          });
        }else{
          this.toastr.error(Message_data.checkDrugListEmpty);
        }
        break;
      }
    }
    setTimeout(function () {
      $( ".getMedcien" ).last().focus();
    }, 500);   
 }

  //Get payment type
  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.payTypeListArray = obj.payments;
          this.payType = this.payTypeListArray[0].pay_id;
          this.payDes = this.payTypeListArray[0].pay_desc;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  //Get payment type
  getInsurers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerListArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.payTypeListArray.forEach(element => {
      if( element.pay_id == this.payType ){
        this.payDes = element.pay_desc;
      }
    });
    if (this.payType == "3") {
      this.insurFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }

  changeIntake(drug_detailsarray){//Intake change
    if (drug_detailsarray.afterfood_txt == "After food" || drug_detailsarray.afterfood_txt == "Before food") {
      drug_detailsarray.dure_show = false;
      drug_detailsarray.dure_require = true;
      drug_detailsarray.dure_txt = "30";
    } else {
      drug_detailsarray.dure_show = true;
      drug_detailsarray.dure_require = false;
      drug_detailsarray.dure_txt = undefined;
    }
  }

  changeMedicineName(e,type) {//Get medicine name
    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type : type
    });

    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));
            if (obj.med_details != null) {
              this.MedicineNameArray = [];
              this.medicineListArray = [];
              this.medicineArray = [];

              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                this.medicineArray.push({ name : medname, quantity : obj.med_details[i].quantity, generic : obj.med_details[i].generic_name});
                this.medicineListArray.push({
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  med_code: obj.med_details[i].med_code,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  quantity : obj.med_details[i].quantity
                })
              }
             
              // this.MedicineNameArray = this.medicineArray.filter(function (this: any, el) {
              //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
              // }.bind(this));
              console.log("this.medicineListArray" + JSON.stringify(this.medicineArray));
            }
          },
          error => { }
        )
    } else {
      this.MedicineNameArray = [];
    }
  }

  selectMedicine(item,drug_detailsarray) {
    for (var i = 0; i < this.medicineListArray.length; i++) {
      if (item.name == this.medicineListArray[i].medname) {
        drug_detailsarray.gen_name = item.generic;
        drug_detailsarray.short_name = this.medicineListArray[i].short_name;
        drug_detailsarray.drug_name = item.name;
        drug_detailsarray.drug_disp_name = this.medicineListArray[i].short_name + " " + this.medicineListArray[i].med_name;
        drug_detailsarray.drug_id = this.medicineListArray[i].med_id;
        this.medicineName = this.medicineListArray[i].med_name;
        drug_detailsarray.cgst = this.medicineListArray[i].cgst;
        drug_detailsarray.sgst = this.medicineListArray[i].sgst;
        drug_detailsarray.type_name = this.medicineListArray[i].type_name;
        drug_detailsarray.drug_typetxt = this.medicineListArray[i].med_type_id;
        drug_detailsarray.short_form = this.medicineListArray[i].short_form;
        drug_detailsarray.show = this.medicineListArray[i].show_short_form;
        drug_detailsarray.show_intake = this.medicineListArray[i].show_intake;
        drug_detailsarray.day_txt="1";
        this.medicalPrescriptionArray = this.medicineListArray;
       // this.sesssionBaseChange(drug_detailsarray);
        this.getIntakeName(drug_detailsarray)
      }
    }
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
    this.hideBatchArray(drug_detailsarray);
  }
  getIntakeName(drug_detailsarray) {
    var send_data = {
      product_id: drug_detailsarray.drug_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("this.getIntakeArray" + JSON.stringify(this.getIntakeArray));
          for (var i = 0; i < this.getIntakeArray.length; i++) {
            if (obj.intake == this.getIntakeArray[i].drug_int_id) {
              drug_detailsarray.intake_txt = this.getIntakeArray[i].description;
              this.intake_id = this.getIntakeArray[i].drug_int_id;
            }
          }
          this.sesssionBaseChange(drug_detailsarray);

        })
  }
  hideBatchArray(drug_detailsarray){
    if(this.drugDetailsArray.length > 1){
      for(var i = 0; i < this.drugDetailsArray.length; i++){
        if(drug_detailsarray.index == this.drugDetailsArray[i].index)
          document.getElementById('drug_'+drug_detailsarray.index).style.display = 'block';
        else
          document.getElementById('drug_'+drug_detailsarray.index).style.display = 'none';

      }
    }
  }

  getItemPrices(drug_detailsarray) {
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: drug_detailsarray.drug_id
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/ipnopres/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if (dataval != undefined && dataval.length != 0) {
          drug_detailsarray.batcharray = [];
          if( this.tempBatch == undefined){ 
            this.qty = "";
            this.amt = "";
            this.price = "";
          }
          var index = 0;

          for (var i = 0; i < dataval.pharma_price.length; i++) {
            this.drugArray.push({
              price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
              per_unit: dataval.per_unit,
              batch_no: dataval.pharma_price[i].batch_no,
              quantity: dataval.pharma_price[i].avail_qty,
              discount: dataval.pharma_price[i].prod_disc,
              cgst: data.json().pharma_price[i].prod_cgst,
              sgst: data.json().pharma_price[i].prod_sgst,
              expiry_date: data.json().pharma_price[i].expiry_date,
              rec_item_id:data.json().pharma_price[i].rec_item_id,
              index:index,
            });
            index ++;
          }
          //batch array
          for(var j = 0; j < dataval.batch_nos.length; j++){
            var res = this.drugArray.filter(o => o.rec_item_id == dataval.batch_nos[j].rec_item_id);
            if(res.length != 0){
              if(res[0].batch_no == dataval.batch_nos[j].batch_no ){
                var color = this.checkExpiryDate(res[0].expiry_date);
                drug_detailsarray.batcharray.push({
                  index: res[0].index,
                  batch_no:dataval.batch_nos[j].batch_no,
                  rec_item_id:dataval.batch_nos[j].rec_item_id,
                  mrp:res[0].price_txt,
                  expiry_date: res[0].expiry_date,
                  quantity:res[0].quantity,
                  color:color,
                });
              }
            }
        }
        }
      }, error => { }
    );
  }

  selectedBatch(batch,drug_detailsarray,index) {
    var checkList = this.fineDrugList(batch.batch_no, drug_detailsarray.drug_id);
    if( checkList != true){
      this.showBatchArray = false;
    drug_detailsarray.batch_required = { "border-color": "#2ca8fe",};
    drug_detailsarray.batch_no = batch.batch_no;   
    if(drug_detailsarray.batch_no != "") 
      drug_detailsarray.quantityDisable = false;
    
    for (var i = 0; i < this.drugArray.length; i++) {
      if (batch.batch_no == this.drugArray[i].batch_no) {
        if(batch.index == this.drugArray[i].index){
          if(this.tempBatch != undefined ){
            drug_detailsarray.price = this.drugArray[i].price_txt;
            // drug_detailsarray.quantity = this.drugArray[i].quantity;
            // drug_detailsarray.tempquantity = this.drugArray[i].quantity;
          }else{
            drug_detailsarray.price = this.drugArray[i].price_txt;
            // drug_detailsarray.quantity = this.drugArray[i].quantity;
            // drug_detailsarray.tempquantity = this.drugArray[i].quantity;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            drug_detailsarray.cgst = this.drugArray[i].cgst;
            drug_detailsarray.sgst = this.drugArray[i].sgst;
            drug_detailsarray.discount = this.drugArray[i].discount;
            drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id
          }
          this.calculateAmount(drug_detailsarray);
        }
      }
    }
    drug_detailsarray.show_batcharray = false;
    this.loadScript();
    document.getElementById(index).style.display = 'none';
    // document.getElementById("pharma_overlay").style.overflow = "auto";
    document.getElementById("pharma_inpattbl_overlay").style.overflowY = "auto";
    document.getElementById("pharma_inpattbl_overlay").style.overflowX = "auto";
    }else{
      this.toastr.error("Medicine with same batch no already added");
    }
    // document.getElementById("tbl_med").style.overflowY = "auto";
    console.log( "this.drugDetailsArray" + JSON.stringify(this.drugDetailsArray));
  }
  fineDrugList(batch,drugID){
      var getFlag = 0;
      for( var i = 0; i < this.drugDetailsArray.length; i++ ){
        if((this.drugDetailsArray[i].drug_id == drugID) && (this.drugDetailsArray[i].batch_no == batch)){
          getFlag = 1;
        }
      }
    return getFlag == 1 ? true : false;
  }
  isReadonly() { return true; }//Read only

  CurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          //order_details
          var obj = JSON.parse(response["_body"]);
          this.todayDate = obj.current_date;
          this.orderDate = this.todayDate;
          this.orderTime = obj.current_time;
          this.currentDate = obj.current_date;
        },
      )
  }

  getPharmacyDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "pharmacontrol/gphar", JSON.stringify({
      pharma_id: this.pharmacyID,
    }),
      { headers: headers }).subscribe(
        response => {

          var data = JSON.parse(response["_body"]);
          if (data != undefined) {
            this.pharmacyName = data.pharmacy_name;
            this.pharmacyAddress = data.address1 + ", " + data.address2;
            this.pharmacyCSZ = data.city + ", " + data.state + " - " + data.zipcode;
            this.pharmacyCount = data.country;
            this.pharmacyMobile = data.telephone;
          }
        },
      )
  }

  calculateAmount(drug_detailsarray) {
    if (drug_detailsarray.quantity != "") {
      var priceval = parseFloat(drug_detailsarray.price);
      if (drug_detailsarray.quantity != undefined) {
        var pricecal: any = priceval * drug_detailsarray.quantity;
      }

      if (drug_detailsarray.discount != null) {
        var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
        drug_detailsarray.amount = pricecal - disc;
      } else {
        disc = 0;
      }

      var pricegst;
      pricegst = pricecal - disc;
      drug_detailsarray.amount = pricegst.toFixed(2);
      this.prodAmount = pricecal;
    }
    this.calculateDiscount();
  }

  quantity(drug_detailsarray){
    if( parseInt(drug_detailsarray.quantity)  > parseInt(drug_detailsarray.tempquantity)){
      drug_detailsarray.quantity = drug_detailsarray.tempquantity;
      this.toastr.error("Quantity Exits to avilable quantity");
    }
    this.calculateAmount(drug_detailsarray);
    var prec_tx:any = "0.00";
    if(this.drugDetailsArray.length > 1){
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        prec_tx = parseFloat(prec_tx) + parseFloat(this.drugDetailsArray[i].amount);
      }
    }else{
      prec_tx = parseFloat(prec_tx) + parseFloat(drug_detailsarray.amount);
    }
    this.totalAmount = parseFloat(prec_tx).toFixed(2);
    this.printFlag = false;
    this.saveFlag = false;
    this.displayDescription = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
  } 

  addRow(){
    this.addNewDrugDetails();
    this.medicineArray=[];
  }
  
  calculatePrice(drugid, typename, drugname, quantity, price, per_unit, discount) {
    if (quantity != "") {
      var priceval = parseFloat(price);
      var pricecal: any = priceval * quantity;

      if (this.disc != null) {
        var disc: any = Math.floor(pricecal * (this.disc / 100)).toFixed(2);
      }

      var pricegst;
      // if (gst != undefined) {
      //   pricegst = (pricecal - disc) + ((pricecal - disc) * gst / 100);

      // } else {
      pricegst = pricecal - disc;

      // }
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (drugid == this.drugDetailsArray[i].drug_id) {
          this.drugDetailsArray[i].quantity = quantity;
          this.drugDetailsArray[i].amount = pricegst.toFixed(2);
          this.drugDetailsArray[i].price = price;
          this.drugDetailsArray[i].discount = discount;
        }
      }

      var totamount = 0;
      for (var j = 0; j < this.drugDetailsArray.length; j++) {
        totamount += parseFloat(this.drugDetailsArray[j].amount);
      }

      //Tax
      this.taxArray = [];
      if (this.taxpercentageArray != null) {
        for (var i = 0; i < this.taxpercentageArray.length; i++) {
          var taxtxt = ((totamount * this.taxpercentageArray[i].tax) / 100).toFixed(2);
          this.taxArray.push({
            tax_des: this.taxpercentageArray[i].tax_des,
            tax_id: taxtxt,
          })
        }
      }

      this.finalAmount = 0;
      for (var i = 0; i < this.taxArray.length; i++) {
        this.taxAmount = this.taxArray[i].tax_id;
        this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.taxAmount)).toFixed(2);
      }

      var x = totamount + parseFloat(this.finalAmount);
      x = Math.round(x * 100) / 100;
      this.totalAmount = x.toFixed(2);
    }
  }

  addMedicines() {
    this.drugDetailsArray.push({
      drug_id: this.medID,
      type_name: this.medTypeTxt,
      drug_name: this.medicineName,
      drug_disp_name: this.shortName + " " + this.medicineName,
      quantity: this.qty,
      tempquantity: this.qty,
      amount: this.amt,
      price: this.price,
      discount: this.disc,
      per_unit: this.perUnit,
      //  prod_gst: this.gst_txt,
      prod_disc: this.disc,
      batch_no: this.batchNumber,
      amt_bdgst: this.prodAmount,
      cgst: this.centerGST,
      sgst: this.stateGST,
      expiry_date: this.expiryDate,
      rec_item_id:this.recItemID,
    });

    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
    }
    this.totalAmount = prec_tx.toFixed(2);
    this.medTypeTxt = "";
    this.medicineName = "";
    this.qty = "";
    this.disc = "";
    this.centerGST = "";
    this.stateGST = "";
    this.amt = "";
    this.price = "";
    this.expiryDate = "";
    this.recItemID = "";
    this.batchRequired = { "border-color": "rgb(250, 40, 2)",}
    this.printFlag = false;
    this.saveFlag = false;
    this.displayDescription = this.prodDiscount == "" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
    this.calculateDiscount();
  }

  calculateDiscount() {
    var tamtbg = 0.00;
    var amtval = 0.00;

    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      tamtbg = parseFloat(tamtbg + this.drugDetailsArray[i].amt_bdgst);
      amtval = amtval + parseFloat(this.drugDetailsArray[i].amount);
    }
    this.totalAmount = amtval.toFixed(2);
    if (this.discType == "3") {
      this.amtbdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discregSalesVolArray.length; j++) {
        if (parseInt(this.amtbdGST) >= this.discregSalesVolArray[j].volume) {
          var disamt = parseInt(this.amtbdGST) * (this.discregSalesVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDiscount = (this.totalAmount - this.prodDiscount).toFixed(2);
          this.amountDiscount =  (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
        }
      }
    } else if (this.discType == "4") {
      this.amtbdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discSalesVolArray.length; j++) {
        if (parseInt(this.amtbdGST) >= this.discSalesVolArray[j].volume) {
          var disamt = parseInt(this.amtbdGST) * (this.discSalesVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDiscount = (this.totalAmount - this.prodDiscount).toFixed(2);
          this.amountDiscount =  (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
        }
      }
    } else if (this.discType == "5") {
      this.amtbdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discTransVolArray.length; j++) {
        if (parseInt(this.amtbdGST) >= this.discTransVolArray[j].volume) {
          var disamt = parseInt(this.amtbdGST) * (this.discTransVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDiscount = (this.totalAmount - this.prodDiscount).toFixed(2);
          this.amountDiscount =  (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
        }
      }
    } else {
      this.amountDiscount = amtval.toFixed(2);
      this.amountDiscount =  (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
    }
    this.remaingBalance = amtval.toFixed(2);
  }

  changeTotal() {
    if (this.prodDiscount == "") {
      this.displayDescription = false;
      this.prodDiscount = "0.00";
    } else {
      // this.prod_disc = parseFloat(this.prod_disc);
      this.displayDescription = true;
    }
    // this.amountDiscount = (parseFloat(this.totalAmount) - parseFloat(this.prodDiscount)).toFixed(2);
    // this.amountDiscount =  (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
    this.remaingBalance = (parseFloat(this.amountDiscount) - (parseFloat(this.prodDiscount) + parseFloat(this.paidText))).toFixed(2);
   //this.remaingBalance = (parseFloat(this.totalAmount) - (parseFloat(this.prodDiscount) + parseFloat(this.paidText))).toFixed(2);
  }

  deleteMedicine(drug_id,index) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if(this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null){
        if (this.drugDetailsArray[i].drug_id == drug_id) {
          if(this.drugDetailsArray[i].index == index){
          this.deleteMedicalpres(this.drugDetailsArray[i].med_typetxt, this.drugDetailsArray[i].drug_name);
          this.drugDetailsArray.splice(i, 1);
          }
        }
      }
    }

    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if(this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null)
        prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
    }
    this.totalAmount = prec_tx;
    this.amountDiscount = prec_tx.toFixed(2);
    this.amountDiscount =  (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
    this.remaingBalance = this.amountDiscount;
    // this.remaingBalance = prec_tx.toFixed(2);
  }

  changeAdvance = function (this: any) {//add the bill details
    if (this.paidText != undefined && this.paidText != "" ) {
      this.remaingBalance = (parseFloat(this.amountDiscount) - (parseFloat(this.paidText) + parseFloat(this.prodDiscount))).toFixed(2);
      this.paidText = parseFloat(this.paidText).toString();
    } else {
      this.paidText = "0.00";
      this.remaingBalance = this.amountDiscount;
    }
  }

  paidBlur() {
    if (this.paidText != "") {
      this.paidText = (parseFloat(this.paidText)).toFixed(2);
    }
  }

  dissBlur() {
    if (this.prodDiscount != "") {
      this.prodDiscount = (parseFloat(this.prodDiscount)).toFixed(2);
    }
  }

  saveOrder(e) {
    //var saveflag = false;
    var saveflag;
    if (this.doctorName == "" || this.clientName == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      var paid_flag;
      var paytype;
      var instype;
      this.save_flag = true; 
      paytype = this.payType;
      instype = this.insurerID;
      if (this.amountDiscount == this.remaingBalance) {
        paid_flag = "Un paid";
      } else if (this.amountDiscount != "0.00" && (this.paidText != "0.00") && this.amountDiscount != this.paidText) {
        paid_flag = "Partially paid";
      } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
        paid_flag = "Fully paid";
      }
console.log("DRUG DETAILS "+JSON.stringify(this.drugDetailsArray))
      var drug_detailsarray:any = [];
      for(var i= 0; i < this.drugDetailsArray.length; i++){

        if(this.drugDetailsArray[i].drug_id != "" 
          || this.drugDetailsArray[i].drug_id != undefined 
          || this.drugDetailsArray[i].drug_id != null){
          drug_detailsarray.push({
            drug_id: this.drugDetailsArray[i].drug_id,
            type_name: this.drugDetailsArray[i].type_name,
            drug_name: this.drugDetailsArray[i].drug_name,
            drug_disp_name: this.drugDetailsArray[i].drug_disp_name,
            quantity: this.drugDetailsArray[i].quantity,
            amount: this.drugDetailsArray[i].amount,
            price: this.drugDetailsArray[i].price,
            discount: this.drugDetailsArray[i].discount,
            per_unit: this.drugDetailsArray[i].per_unit,
            prod_disc: this.drugDetailsArray[i].disc_txt,
            batch_no: this.drugDetailsArray[i].batch_no,
            amt_bdgst: this.prodAmount,
            cgst: this.drugDetailsArray[i].cgst,
            sgst: this.drugDetailsArray[i].sgst,
            expiry_date: this.drugDetailsArray[i].expiry_date,
            rec_item_id:this.drugDetailsArray[i].rec_item_id,
          });
        }
      }
     
      var save_order_data = {
        pharma_id: this.pharmacyID,
        purchase_type_id: "1",
        medicines: drug_detailsarray,
        country: ipaddress.country_code,
        name: this.clientName,
        doc_name: this.doctorName,
        pres_image: this.imageStr,
        disc_type: this.discType,
        disc_amt: parseFloat(this.prodDiscount).toFixed(2) ,
        bill_amt: parseFloat(this.amountDiscount).toFixed(2),
        paid_amt: parseFloat(this.paidText).toFixed(2),
        balance: parseFloat(this.remaingBalance).toFixed(2),
        paid_flag : paid_flag,
        order_date: this.orderDate,
        pay_type: paytype,
        insurer: instype,
        card_holder_name: this.cardHolderName,
        transaction_no: this.TransactionID,
        bank_name: this.bankName,
        pres_drug_id: this.presID,
      };
     console.log("save_order_data" + JSON.stringify(save_order_data));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.saveOrderURl, JSON.stringify(save_order_data), { headers: headers }).subscribe(
        data => {
          //var obj = data.json();
          console.log("SAVE DATA RETURN "+JSON.stringify(data.json()))
          if (data.json().key !== null && data.json().key == "1") {
            this.toastr.success(Message_data.billgen);
            this.billID = data.json().order_id;
            this.save_flag = false;
            this.pharmacyservice.sendMessage('pharmacycurentinformation');
            if (e == 'print') {
              this.print();
            }
          } else {
            this.save_flag = false;
            this.toastr.error(Message_data.orderNotPlaced);
          }
        }, error => { this.save_flag = false;});
    }
  }

  backButton(){
    this.pharmacyservice.sendMessage('pharmacycurentinformation');
  }

  print_area() {
    var saveflag = false;
    if (this.doctorName == "" || this.clientName == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      this.saveOrder('print');
    }
  }

  print() {
    if (this.billID != undefined) {
      var pharmaAddr = this.pharmaAddress2 != undefined ? this.pharmaAddress1 + "," + this.pharmaAddress2 : this.pharmaAddress1;
      var template;
      if (this.billTemplate != undefined && this.billTemplate == "banner") {
        template = '<div #banner id="banner">'
      } else if (this.billTemplate != undefined && this.billTemplate == "noheader") {
        template = '<div #noheader id="noheader">'
      } else if (this.billTemplate != undefined && this.billTemplate == "logowithname") {
        template = '<div #logowithname id="logowithname"style="background-image: url({{bg_image}}); background-position: center;">';
      } else {
        template = '<div #billprint id="billprint">';
      }
      var printData = {
        template: this.billTemplate,
        content: template,
        pharma_logo: this.pharmaLogo,
        pharma_name: this.pharmaName,
        pharma_addr: pharmaAddr,
        location: this.location,
        city: this.city,
        state: this.state,
        country: this.country,
        dl_no: this.dlNO,
        gst_no: this.GSTNo,
        patient_name: this.clientName,
        bill_no: this.billID,
        PurchaseType: this.PurchaseType,
        doc_name: this.doctorName,
        order_date: Date_Formate(this.orderDate),
        order_time: this.orderTime,
        drugList: this.drugDetailsArray,
        discount: this.prodDiscount,
        total: this.totalAmount,
        paid: this.paidText,
        balance: this.remaingBalance,
        order_type: true,
        show_discount: this.displayDescription,
        pay_type : this.payDes
      }
      this.printService.print(printData);
    }
  }

  fileChange(data) {
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP'];

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      this.imageString = myReader.result.toString();
      this.imageStr = this.imageString.split(',')[1];
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  getReferralData(e) {
    this.ReferedDoctorArray = [];
    this.referedName = [];

    if (this.doctorName != undefined && this.doctorName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/dbyp',
        JSON.stringify({
          center_id: this.pharmacyID,
          doc_name: this.doctorName,
          type: "pharma"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.referedName = obj.doctors;
            if (this.referedName != undefined) {
              for (var i = 0; i < this.referedName.length; i++) {
                var docname;
                if (this.referedName[i].middle_name != undefined) {
                  docname = this.referedName[i].first_name + " " + this.referedName[i].middle_name + " " + this.referedName[i].last_name;
                } else {
                  if (this.referedName[i].last_name != undefined) {
                    docname = this.referedName[i].first_name + " " + this.referedName[i].last_name;
                  } else {
                    docname = this.referedName[i].first_name
                  }
                }
                this.ReferedDoctorArray.push({
                  docname: docname,
                  doc_reg_id: this.referedName[i].prov_id,
                  hptl_clinic_id: this.referedName[i].hptl_clinic_id,
                });
              }
            }
          },
          error => {
            this.toastr.error(Message_data.network);
          });
    }
    else {
      this.ReferedDoctorArray = [];
    }
  }

  Select_refer_doctor(id) {
    var item1 = this.doctorName;
    for (var i = 0; i < this.referedName.length; i++) {
      if (this.referedName[i].prov_id == id) {
        this.hospitalID = this.referedName[i].hptl_clinic_id;
        this.docRegID = this.referedName[i].prov_id;
        break;
      }
    }
    this.ReferedDoctorArray = [];
    this.getAppointments();
  }

  getDiscountDetails() {
    this.discregSalesVolArray = [];
    this.discSalesVolArray = [];
    this.discTransVolArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpdis/",
      JSON.stringify({
        pharma_id: this.pharmacyID,
        imei: this.ipaddress
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.discregSalesVolArray = obj.regsal_vol;
          this.discSalesVolArray = obj.sales_vol;
          this.discTransVolArray = obj.trans_vol;
        });
  }

  getDiscTypes() {
    var headers = new Headers();

    headers.append('Content-Type', 'application/json');
    this.http.get(this.discURL, { headers: headers }).subscribe(
      data => {
        this.discountDatas = [];
        var distypes = data.json();
        this.discountDatas = distypes.disc_types;
        this.discType = this.discountDatas[0].disc_type_id;
      }, error => {});
  }

  discountChange(distype) {
    this.disc = "";
    if (distype != "1" && distype != "2") {
      this.discountFlag = false;
      if (this.discregSalesVolArray != undefined && this.discregSalesVolArray.length == 0) {
        this.getDiscountDetails();
      }
    } else {
      this.discountFlag = true;
    }
    this.calculateDiscount();
  }

  changeOrderDate(e) {
    this.orderDate = e;
  }

  getAppointments() {
    this.billingCreateArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      JSON.stringify({
        doc_reg_id: this.docRegID,
        hptl_clinic_id: this.hospitalID,
        country: ipaddress.country_code.toString(),
        imei: FrontDesk_Helper.getIPAddress(),
        type: "pharma",
        inpat_type: "pharma",
        usertype:this.usertype,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if (obj.patients != null) {
            for (var i = 0; i < obj.patients.length; i++) {
              var patientName;
              if (obj.patients[i].middle_name != undefined) {
                this.docIDWithName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                patientName = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
              } else {
                if (obj.patients[i].last_name != undefined) {
                  this.docIDWithName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                  patientName = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                } else {
                  this.docIDWithName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name);
                  patientName = encrypt_decript.Decript(obj.patients[i].first_name);
                }
              }

              var sessiondata = null;
              if (obj.patients[i].session != undefined && obj.patients[i].session != undefined && obj.patients[i].session != null) {
                sessiondata = encrypt_decript.Decript(obj.patients[i].session);
              }

              this.billingCreateArray.push({
                doc_reg_id: obj.patients[i].doc_reg_id,
                doc_app_id: obj.patients[i].doc_app_id,
                client_reg_id: obj.patients[i].client_reg_id,
                relation_id: obj.patients[i].relation_id,
                sub_rel_id: obj.patients[i].sub_rel_id,
                first_name: obj.patients[i].first_name,
                middle_name: obj.patients[i].middle_name,
                last_name: obj.patients[i].last_name,
                hptl_clinic_id: this.hospitalID,
                session: sessiondata,
                docid_name: this.docIDWithName,
                patientName: patientName,
              });
            }
          }
        });
  }

  changeAppointmnetID() {
    for (var i = 0; i < this.billingCreateArray.length; i++) {
      if (this.billingCreateArray[i].doc_app_id == this.appointText) {
        this.ClientID = this.billingCreateArray[i].client_reg_id;
        this.relationID = this.billingCreateArray[i].relation_id;
        this.subRelationID = this.billingCreateArray[i].sub_rel_id;
        this.appID = this.billingCreateArray[i].doc_app_id;
        this.clientName = this.billingCreateArray[i].patientName;
        this.docRegID = this.billingCreateArray[i].doc_reg_id;
        break;
      }
    }
  }

  Duration() {
    var duration = this.dayText + " " + this.dayDuration;
    return duration
  }

  //Get intake session
  getIntakeSession() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'prescription/dintypes/',
      { headers: headers })
      .subscribe(
        response => {

          var obj = response.json();
          this.getIntakeArray = obj.drug_intake;
          this.intake = "Thrice a day";
        },
        error => {
        }
      )
  }

  sesssionBaseChange(drug_detailsarray) {
    if (drug_detailsarray.show_intake == undefined) {
      this.toastr.error(Message_data.selectMedicine);
    }
    if (drug_detailsarray.intake_txt == "Morning only" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "0";
          drug_detailsarray.eve = "0";
          drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.drug_detailsarray = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;
      
      drug_detailsarray.drug_detailsarray = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = false;

      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;
    } else if (drug_detailsarray.intake_txt == "Afternoon only" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "0";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "0";
          drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "0";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.morning_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Evening only" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "0";
          drug_detailsarray.aft = "0";
          drug_detailsarray.eve = "1";
          drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "0";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "5";
            drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = false;

      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Night only" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "0";
          drug_detailsarray.aft = "0";
          drug_detailsarray.eve = "0";
          drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "0";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.morning_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Morning & Afternoon" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "0";
          drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Morning & Evening" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "0";
          drug_detailsarray.eve = "1";
          drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "5";
            drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Morning & Night" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "0";
        drug_detailsarray.eve = "0";
        drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Afternoon & Night" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "0";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "0";
          drug_detailsarray.ngt = "1";
      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "0";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Afternoon & Evening" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.mor = "0";
        drug_detailsarray.aft = "1";
        drug_detailsarray.eve = "1";
        drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "0";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "5";
            drug_detailsarray.ngt = "";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Evening" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "1";
          drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "5";
            drug_detailsarray.ngt = "";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Thrice a day" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "0";
          drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

      
    } else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Night" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "0";
          drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Morning,Afternoon,Evening & Night" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "1";
          drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "5";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Four times a day" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "1";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "1";
          drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "5";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Afternoon,Evening & Night" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "0";
          drug_detailsarray.aft = "1";
          drug_detailsarray.eve = "1";
          drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "0";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "5";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Evening & Night" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.mor = "0";
        drug_detailsarray.aft = "0";
        drug_detailsarray.eve = "1";
        drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "0";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "5";
            drug_detailsarray.ngt = "5";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if ((drug_detailsarray.intake_txt == "Every 6 hours" || drug_detailsarray.intake_txt == "Every 8 hours"
      || drug_detailsarray.intake_txt == "Every 12 hours") && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.mor = "1";
        drug_detailsarray.aft = "0";
        drug_detailsarray.eve = "0";
        drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.quan_lable = false;
      drug_detailsarray.morning_dis = false;

      drug_detailsarray.afternoon_dis = true; 
      drug_detailsarray.even_dis = true; 
      drug_detailsarray.ngt_dis = true; 


      this.morID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Stat") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.mor = "1";
        drug_detailsarray.aft = "0";
        drug_detailsarray.eve = "0";
        drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true; 
      drug_detailsarray.even_dis = true; 
      drug_detailsarray.ngt_dis = true; 

      this.morID = true;
      this.afterID = true;
      this.eveID = true;
      drug_detailsarray.intake_show = true;
      drug_detailsarray.dure_show = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = true;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Once a day") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.mor = "1";
        drug_detailsarray.aft = "0";
        drug_detailsarray.eve = "0";
        drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true; 
      drug_detailsarray.even_dis = true; 
      drug_detailsarray.ngt_dis = true; 

      this.morID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;
      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "SOS") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.mor = "1";
        drug_detailsarray.aft = "0";
        drug_detailsarray.eve = "0";
        drug_detailsarray.ngt = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "0";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "0";


      } else {
        drug_detailsarray.mor = "0";
        drug_detailsarray.aft = "0";
        drug_detailsarray.eve = "0";
        drug_detailsarray.ngt = "0";
      }

      drug_detailsarray.quan_lable = false;
     
      this.morID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;
      
      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
     
      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true; 
      drug_detailsarray.even_dis = true; 
      drug_detailsarray.ngt_dis = true; 

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Alternative day" || drug_detailsarray.intake_txt == "Once a week"
      || drug_detailsarray.intake_txt == "Once in two weeks" || drug_detailsarray.intake_txt == "Once a month") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.mor = "1";
        drug_detailsarray.aft = "1";
        drug_detailsarray.eve = "0";
        drug_detailsarray.ngt = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
            drug_detailsarray.mor = "5";
            drug_detailsarray.aft = "5";
            drug_detailsarray.eve = "0";
            drug_detailsarray.ngt = "5";
      }
      drug_detailsarray.drug_detailsarray = false;
      
      this.morID = false;
      drug_detailsarray.mor_lable = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false; 
      drug_detailsarray.even_dis = false; 
      drug_detailsarray.ngt_dis = false; 

      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;
    }
  }

  addMedicalPres(drug_detailsarray) { //Add medicine table
    for(var k = 0; k < drug_detailsarray.length; k++){
      var flag = false;
      if (drug_detailsarray[k].type_name == undefined || drug_detailsarray[k].type_name == "") {
        this.toastr.error(Message_data.enterMedType);
        flag = true;
      }
      if (drug_detailsarray[k].drug_name == undefined || drug_detailsarray[k].drug_name == "") {
        this.toastr.error(Message_data.enterMedName);
        flag = true;
      } else if (drug_detailsarray[k].intake_txt != "Stat" && (drug_detailsarray[k].day_txt == undefined || drug_detailsarray[k].day_txt == "")) {
        this.toastr.error(Message_data.enterNoOfDays);
        flag = true;
      } else if (drug_detailsarray[k].intake_txt == undefined) {
        this.toastr.error(Message_data.enterIntkSess);
        flag = true;
      }

      if (drug_detailsarray[k].show_intake == "1") {
        if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning only" && (drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "")) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Afternoon only" && (drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "")) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Evening only" && (drug_detailsarray[k].eve == undefined || drug_detailsarray[k].eve == "")) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Night only" && (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == "")) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning & Afternoon" &&
          ((drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "") || (drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning & Evening" &&
          ((drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "") || (drug_detailsarray[k].eve == undefined || drug_detailsarray[k].eve == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning & Night" &&
          ((drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "") || (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Afternoon & Evening" &&
          ((drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "") || (drug_detailsarray[k].eve == undefined || drug_detailsarray[k].eve == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Afternoon & Night" &&
          ((drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "") || (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning,Afternoon & Evening" &&
          ((drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "") || (drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "")
            || (drug_detailsarray[k].eve == undefined || drug_detailsarray[k].eve == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning,Afternoon & Night" &&
          ((drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "") || (drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "")
            || (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning,Afternoon,Evening & Night" &&
          ((drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "") || (drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "")
            || (drug_detailsarray[k].eve == undefined || drug_detailsarray[k].eve == "") || (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Afternoon,Evening & Night" &&
          ((drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "") || (drug_detailsarray[k].eve == undefined || drug_detailsarray[k].eve == "")
            || (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Evening & Night" &&
          ((drug_detailsarray[k].eve == undefined || drug_detailsarray[k].eve == "") || (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Thrice a day" &&
          ((drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "") || (drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "")
            || (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Four times a day" &&
          ((drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "") || (drug_detailsarray[k].aft == undefined || drug_detailsarray[k].aft == "")
            || (drug_detailsarray[k].eve == undefined || drug_detailsarray[k].eve == "") || (drug_detailsarray[k].ngt == undefined || drug_detailsarray[k].ngt == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && (drug_detailsarray[k].intake_txt == "Every 6 hours" || drug_detailsarray[k].intake_txt == "Every 8 hours"
          || drug_detailsarray[k].intake_txt == "Every 12 hours" || drug_detailsarray[k].intake_txt == "Once a day" || drug_detailsarray[k].intake_txt == "Stat")
          && (drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "")) {
          this.toastr.error(Message_data.enterIntake);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt == "Stat" && (drug_detailsarray[k].mor == undefined || drug_detailsarray[k].mor == "")) {
          this.toastr.error(Message_data.enterIntake);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && (drug_detailsarray[k].intake_txt == "Alternative day" || drug_detailsarray[k].intake_txt == "Once a week"
          || drug_detailsarray[k].intake_txt == "Once in two weeks") && (drug_detailsarray[k].mor == "0" && drug_detailsarray[k].aft == "0" && drug_detailsarray[k].eve == "0")) {
          this.toastr.error(Message_data.enterIntake);
          flag = true;
        }
      }

      if (flag == false) {
        for (var i = 0; i < this.medicalPrescriptionArray.length; i++) {
          if (this.medicalPrescriptionArray[i].med_type_id == drug_detailsarray[k].type_name) {
            drug_detailsarray.drug_typetxt = this.medicalPrescriptionArray[i].med_type_id;
          }
        }

        for (var i = 0; i < this.getIntakeArray.length; i++) {
          if (drug_detailsarray[k].intake_txt == this.getIntakeArray[i].description) {
            this.intake_id = this.getIntakeArray[i].drug_int_id;
          }
        }

        this.medicineTableFlag = false;
        if (drug_detailsarray[k].drug_id == null) {
          drug_detailsarray[k].drug_id = "0";
        }

        var aftfood;
        if (drug_detailsarray[k].show == "1") {
          var morntxt;
          if (parseFloat(drug_detailsarray[k].mor) != 0) {
            morntxt = drug_detailsarray[k].mor + drug_detailsarray[k].short_form;
          } else {
            morntxt = "0"
          }

          var afttxt;
          if (parseFloat(drug_detailsarray[k].aft) != 0) {
            afttxt = drug_detailsarray[k].aft + drug_detailsarray[k].short_form;
          } else {
            afttxt = "0"
          }

          var evetxt;
          if (parseFloat(drug_detailsarray[k].eve) != 0) {
            evetxt = drug_detailsarray[k].eve + drug_detailsarray[k].short_form;
          } else {
            evetxt = "0"
          }

          var ngttxt;
          if (parseFloat(this.ngt) != 0) {
            ngttxt = drug_detailsarray[k].ngt + drug_detailsarray[k].short_form;
          } else {
            ngttxt = "0"
          }

          if (drug_detailsarray[k].show_intake == "0") {
            this.everySix = drug_detailsarray[k].intake_txt;
          } else {
            if (drug_detailsarray[k].intake_txt == "Every 6 hours") {
              this.everySix = morntxt + " - Every 6 hours";
            } else if (drug_detailsarray[k].intake_txt == "Every 8 hours") {
              this.everySix = morntxt + " - Every 8 hours";
            } else if (drug_detailsarray[k].intake_txt == "Every 12 hours") {
              this.everySix = morntxt + " - Every 12 hours";
            } else if (drug_detailsarray[k].intake_txt == "SOS") {
              if (drug_detailsarray[k].drug_typetxt == "1" || drug_detailsarray[k].drug_typetxt == "7" || drug_detailsarray[k].drug_typetxt == "10"
                || drug_detailsarray[k].drug_typetxt == "23" || drug_detailsarray[k].drug_typetxt == "30") {
                this.everySix = morntxt + " SOS - if required";
              } else {
                this.everySix = morntxt + " SOS - if required";
              }
            } else if (drug_detailsarray[k].intake_txt == "Thrice a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Once a week") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Four times a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Alternative day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Once a day") {
              this.everySix = morntxt + " - per day";
            } else if (drug_detailsarray[k].intake_txt == "Stat") {
              this.everySix = morntxt + " - Stat";
            } else {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            }
          }

          if (drug_detailsarray[k].intake_txt != "Stat" && (drug_detailsarray[k].dure_txt != undefined && drug_detailsarray[k].dure_txt != "")) {
            this.dureWrite = this.dureText + " " + "mins";
          } else {
            this.dureWrite = "";
            this.dureFlag = true;
          }

          if (drug_detailsarray[k].afterfood_txt == undefined) {
            this.afterfoodText = "";
          }

          var frequency;
          if (drug_detailsarray[k].intake_txt == "Alternative day" || drug_detailsarray[k].intake_txt == "Once a week" || drug_detailsarray[k].intake_txt == "Once in two weeks") {
            frequency = drug_detailsarray.intake_txt;
          } else {
            if (drug_detailsarray[k].intake_txt == "Stat" || drug_detailsarray[k].intake_txt == "SOS") {
              frequency = "";
            } else {
              if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
                frequency = "";
              } else {
                frequency = "Daily";
              }
            }
          }

          if (drug_detailsarray[k].intake_txt == "Stat") {
            period = "";
            this.dayText = "";
          } else {
            var period
            if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
              period = "day";
            } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
              period = "days";
            } else if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "week(s)") {
              period = "week";
            } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "week(s)") {
              period = "weeks";
            } else if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "month(s)") {
              period = "month";
            } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "month(s)") {
              period = "months";
            } else {
              period = "";
            }
          }

          if (drug_detailsarray[k].intake_txt != "Stat" && (drug_detailsarray[k].afterfood_txt != undefined && drug_detailsarray[k].afterfood_txt != 'Select')) {
            aftfood = drug_detailsarray[k].afterfood_txt;
          } else {
            aftfood = "";
          }

          this.listProducts.push({
            med_typetxt: drug_detailsarray[k].type_name,
            drug_name: drug_detailsarray[k].drug_name,
            days: drug_detailsarray[k].day_txt,
            period: period,
            drug_intake: aftfood,
            intake: this.intake_id,
            every_six: this.everySix,
            dure_txt_table: this.dureWrite,
            time_duration: drug_detailsarray[k].dure_txt,
            morning: drug_detailsarray[k].mor,
            afternoon: drug_detailsarray[k].aft,
            evening: drug_detailsarray[k].eve,
            night: drug_detailsarray[k].ngt,
            drug_id: drug_detailsarray[k].drug_id,
            drug_type_id: drug_detailsarray[k].drug_typetxt,
            display_intake : drug_detailsarray[k].intake_txt,
            frequency: frequency,
            show_intake: drug_detailsarray[k].show_intake,
            rowid: drug_detailsarray[k].index,
          });
        } else {
          var morntxt;
          if (parseFloat(drug_detailsarray[k].mor) != 0) {
            morntxt = drug_detailsarray[k].mor + drug_detailsarray[k].short_form;
          } else {
            morntxt = "0"
          }

          var afttxt;
          if (parseFloat(drug_detailsarray[k].aft) != 0) {
            afttxt = drug_detailsarray[k].aft + drug_detailsarray[k].short_form;
          } else {
            afttxt = "0"
          }

          var evetxt;
          if (parseFloat(drug_detailsarray[k].eve) != 0) {
            evetxt = drug_detailsarray[k].eve + drug_detailsarray[k].short_form;
          } else {
            evetxt = "0"
          }

          var ngttxt;
          if (parseFloat(drug_detailsarray[k].ngt) != 0) {
            ngttxt = drug_detailsarray[k].ngt + drug_detailsarray[k].short_form;
          } else {
            ngttxt = "0"
          }
          if (drug_detailsarray[k].show_intake == "0") {
            this.everySix = drug_detailsarray[k].intake_txt;

          } else {
            if (drug_detailsarray[k].intake_txt == "Every 6 hours") {
              this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " - Every 6 hours";
            } else if (drug_detailsarray[k].intake_txt == "Every 8 hours") {
              this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " - Every 8 hours";
            } else if (drug_detailsarray[k].intake_txt == "Every 12 hours") {
              this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " - Every 12 hours";
            } else if (drug_detailsarray[k].intake_txt == "SOS") {
              if (drug_detailsarray[k].drug_typetxt == "1" || drug_detailsarray[k].drug_typetxt == "7" || drug_detailsarray[k].drug_typetxt == "10"
                || drug_detailsarray[k].drug_typetxt == "23" || drug_detailsarray[k].drug_typetxt == "30") {
                this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " SOS - if required";
              } else {
                this.everySix = morntxt + " " + this.shortForm + " SOS - if required";
              }

            } else if (drug_detailsarray[k].intake_txt == "Thrice a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Once a week") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Four times a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Alternative day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Once a day") {
              this.everySix = morntxt + " - per day";
            } else if (drug_detailsarray[k].intake_txt == "Stat") {
              this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " - Stat";
            } else {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            }
          }

          if (drug_detailsarray[k].dure_txt != undefined && drug_detailsarray[k].dure_txt != "") {
            this.dureWrite = drug_detailsarray[k].dure_txt + " " + "mins";
          } else {
            this.dureWrite = "";
            this.dureFlag = true;
          }

          var frequency;
          if (drug_detailsarray[k].intake_txt == "Alternative day" || drug_detailsarray[k].intake_txt == "Once a week" || drug_detailsarray[k].intake_txt == "Once in two weeks") {
            frequency = drug_detailsarray[k].intake_txt;

          } else {
            if (drug_detailsarray[k].intake_txt == "Stat" || drug_detailsarray[k].intake_txt == "SOS") {
              frequency = "";
            } else {
              if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
                frequency = "";
              } else {
                frequency = "Daily";
              }
            }
          }

          var period
          if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
            period = "day";
          } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
            period = "days";
          } else if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "week(s)") {
            period = "week";
          } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "week(s)") {
            period = "weeks";
          } else if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "month(s)") {
            period = "month";
          } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "month(s)") {
            period = "months";
          } else {
            period = "";
          }

          if (drug_detailsarray[k].afterfood_txt != undefined && drug_detailsarray[k].afterfood_txt != 'Select') {
            aftfood = drug_detailsarray[k].afterfood_txt;
          } else {
            aftfood = "";
          }

          this.listProducts.push({
            med_typetxt: drug_detailsarray[k].type_name,
            drug_name: drug_detailsarray[k].drug_name,
            days: drug_detailsarray[k].day_txt,
            period: period,
            drug_intake: aftfood,
            intake: this.intake_id,
            every_six: this.everySix,
            dure_txt_table: this.dureWrite,
            time_duration: drug_detailsarray[k].dure_txt,
            morning: drug_detailsarray[k].mor,
            afternoon: drug_detailsarray[k].aft,
            evening: drug_detailsarray[k].eve,
            night: drug_detailsarray[k].ngt,
            drug_id: drug_detailsarray[k].drug_id,
            drug_type_id: drug_detailsarray[k].drug_typetxt,
            frequency: frequency,
            display_intake : drug_detailsarray[k].intake_txt,
            show_intake: drug_detailsarray[k].show_intake,
            rowid: drug_detailsarray[k].rowid,
          });
        }
        this.medTypeText = "";
        this.medicineName = "";
        
      }
    }
  }

  getSymptoms() {
    var searchString;
    if (this.discout.indexOf(',') > -1) {
      var dataval = this.discout.split(",");
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.discout;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.symptoms != null) {
              this.symptomsListArray = [];
              this.symptomListArray = [];
              this.symptomData = [];
              this.symptomsListArray = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptomData.push(obj.symptoms[i].description);
              }

              this.symptomListArray = this.symptomData.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));

            }
          },
          error => {

          })
    }
  }

  selectSymptom(data) {
    this.newSymptomsArray.push(data);
    for (var j = 0; j < this.symptomsListArray.length; j++) {
      if (this.symptomsListArray[j].description == data) {
        this.selectedSymptoms.push(data);
        break;
      }
    }

    if (this.discout.indexOf(',') > -1) {
      var dataval = this.discout.split(",");
      dataval.pop();
      this.discout = dataval.join(",");

      this.discout = this.discout + "," + data;
    } else {
      this.discout = data;
    }
    this.symptomListArray = [];
  }

  deleteMedicalpres(medtype, medname) {
    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].med_typetxt == medtype && this.listProducts[i].drug_name == medname) {
        this.listProducts.splice(i, 1);
        break;
      }
    }
    if (this.listProducts.length != 0) {
      this.medicineTableFlag = false;

    }
    else {
      this.medicineTableFlag = true;

    }
  }
  
  async saveMedicalPrescription(e) {
    await this.addMedicalPres(this.drugDetailsArray);
    var flag = true;

    if (this.discout == ""  && this.discout != undefined) {
      this.toastr.error(Message_data.enterDisease);
      flag = false;
    }
    if (this.listProducts.length == 0) {
      this.toastr.error(Message_data.addMedDetails);
      flag = false;
    }

    if (this.discout != undefined ) {
      var new_symptoms = [];
      if (this.discout.indexOf(',') > -1) {
        var dataval = this.discout.trim().split(",");
        for (var i = 0; i < dataval.length; i++) {
          if (this.newSymptomsArray.length != 0) {
            for (var j = 0; j < this.newSymptomsArray.length; j++) {
              if (dataval[i] != "") {
                if (this.newSymptomsArray[j] != dataval[i]) {
                  new_symptoms.push(dataval[i]);
                }
              }
            }
          } else {
            if (dataval[i] != "") {
              new_symptoms.push(this.discout.trim());
            }
          }
        }
      } else {
        if (this.newSymptomsArray.length != 0) {
          for (var j = 0; j < this.newSymptomsArray.length; j++) {
            if (this.newSymptomsArray[j] != this.discout.trim()) {
              new_symptoms.push(this.discout.trim());
            }
          }
        } else {
          new_symptoms.push(this.discout.trim());
        }
      }
      
    } else {
      this.discout = "";
    }

    if (flag == true) {
      var sen_pass;
      sen_pass = {
        client_reg_id: this.ClientID,
        relation_id: this.relationID,
        appointment_id: this.appID,
        sub_rel_id: this.subRelationID,
        country:ipaddress.country_code,
        date: this.currentDate,
        disease: this.discout,
        medicines: this.listProducts,
        pharmacy_id: this.pharmacyID,
        newsymptoms: new_symptoms,
        doc_reg_id: this.docRegID,
      };

      if (this.subRelationID != undefined) {
        sen_pass.sub_rel_id = this.subRelationID;
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', JSON.stringify(sen_pass),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("json stringfy = "+JSON.stringify(obj))
            if (obj.status == "1") {
              this.presID = obj.pres_id;
              this.saveOrder(e);
            }
          },
          error => {
          }
        )
    }
  }

  lineQuantity(array){
         var prec_tx = 0;
      for( var i = 0; i < this.drugDetailsArray.length; i++){
          if( this.drugDetailsArray[i].drug_id == array.drug_id ){
             //this.drug_detailsarray[i].amount = (parseFloat(array.quantity) * parseFloat(array.price)).toFixed(2);
             if (array.quantity != "") {
              var priceval = parseFloat(array.price);
              if (array.quantity != undefined) {
                var pricecal: any = priceval * array.quantity;
              }
        
              if (array.discount != 0) {
                var disc: any = (pricecal * (array.discount / 100)).toFixed(2);
                this.drugDetailsArray[i].amount = pricecal - disc;
              } else {
                disc = 0;
              }
        
              var pricegst;
              pricegst = pricecal - disc;
              this.drugDetailsArray[i].amount = pricegst.toFixed(2);
            }
          }
          prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
      } 
      this.totalAmount = prec_tx.toFixed(2);
      this.calculateDiscount();  
  }

  getCORD(e,i){
    this.showBatchArray = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';
    document.getElementById(i).style.top = y + 'px';
    this.loadScript();

    // document.getElementById("pharma_overlay").style.overflow = "hidden";
    document.getElementById("pharma_inpattbl_overlay").style.overflowY = "hidden";
    document.getElementById("pharma_inpattbl_overlay").style.overflowX = "hidden";
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    if(this.showBatchArray == false){
      if(this.drugDetailsArray.length >= 1){
        for(var i = 0; i < this.drugDetailsArray.length; i++){
            document.getElementById('drug_'+this.drugDetailsArray[i].index).style.display = 'none';
        }
      }
      // document.getElementById("pharma_overlay").style.overflow = "auto";
      document.getElementById("pharma_inpattbl_overlay").style.overflowY = "auto";
      document.getElementById("pharma_inpattbl_overlay").style.overflowX = "auto";
    }else{
      this.showBatchArray = false;
    }
  }

  focusFunction(e,drug){
    if( drug.intake_txt == "Alternative day" || drug.intake_txt == "Once a week"
    || drug.intake_txt == "Once in two weeks" || drug.intake_txt == "Once a month"){
      if (e.target.value == '') {
        this.toastr.error(Message_data.fillIntk);
        e.target.focus();
     } 
    }
    else {
      if(e.target.value == '' || e.target.value == '0' ) {
        this.toastr.error(Message_data.fillIntk);
        e.target.focus();
     } 
    } 
  }

  checkExpiryDate(expiryDate){
    var exp = "";
    if(expiryDate.split("-").length != 1)
      exp = expiryDate.split("-");
    var d = new Date();
    var a = moment(exp[1] + "-" +exp[0]);
    var b = moment(d);
    var intervals:any = ['years','months','weeks','days'];
    var out = [];

    for(var i=0; i< intervals.length; i++){
        var diff = a.diff(b, intervals[i]);
        b.add(diff, intervals[i]);
        out.push(diff + ' ' + intervals[i]);
    }

    console.log("out ==> " +out);
    var expD:any = out.toString().split(",");
    var yy = expD[0].split(/\s+/g);
    console.log("yy ==> " +yy);
    var mmm = expD[1].split(/\s+/g);
    console.log("mmm ==> " +mmm);
    var subtract = moment().add(mmm[0],mmm[1].toString()).format('YYYY-MM');
    var sub1 = moment(subtract).add(yy[0],yy[1].toString()).format('YYYY-MM');
    var final = moment().add(this.productReturn,'months').format('YYYY-MM');
      console.log("subtract ==> " + subtract + "   sub1 ==> " +sub1 + "final ==> "+final);
    var color;
    if(sub1 <= final){
          color = "red";
        }else{
          color = "black";
        }
    // if(yy[0] == 0 || yy[0] == 1){
    //   if(mmm[0] <= this.productReturn){
    //     color = "red";
    //   }else{
    //     color = "black";
    //   }
    // }else{
    //   color = "black";
    // }

    return color;

   // return out.join(', ');
    // if(exp[1] == d.getFullYear()){
    //   if(exp[0] >= d.getMonth()){
    //     console.log("check1 " +exp[0] + " >= " + d.getMonth());
    //     var es = new Date(exp[1] + "-" +exp[0]);
    //     es.setDate(es.getDate() - 92)
    //     var r = this.getDateFormate(es);
    //     var sax = r.split("-");
    //     console.log("exp before 3 ==> " + r);
    //     if(r[1] >= )

    //   }else if(exp[0] == d.getMonth()){
    //     console.log("check2 " +exp[0] + " >= " + d.getMonth());
    //   }else if(exp[0] <= d.getMonth()){
    //     console.log("check3 " +exp[0] + " >= " + d.getMonth());
    //   }
    //   d.setDate(d.getDate() - 92)
    //   e = this.getDateFormate(d);
    //   console.log("ecp ==> " +e);
    // }else{
    //   console.log("no ==> " +exp);
    // }
  }

  getDateFormate(d){
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  paidFocus(){
    this.paidText = "";
  }
}
