import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener, ViewChild} from '@angular/core';
import { Http, Headers } from '@angular/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate} from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-second-opinion-presview-popup',
  templateUrl: './second-opinion-presview-popup.component.html',
  styleUrls: ['./second-opinion-presview-popup.component.css']
})
export class SecondOpinionPresviewPopupComponent implements OnInit {

  public url_prescription = ipaddress.getIp.toString()+"prescription/medpresdet";
  public prescription_pres_id;
  public data_prescription: any;
  public tablet_tracker = [];
  public total_name: string;
  public dr_total_name: string;
  public middlename: string;
  public date_prescription;
  public disease_name: string;
  public disease_describtion: string;
  public pres_id: string;
  public header_footer_flag:boolean;

  constructor(public http:Http,public dialogRef: MatDialogRef<SecondOpinionPresviewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr:ToastrService) { 
      dialogRef.disableClose = true;
      this.pres_id = data.med_id;
      this.prescriptionGetvalue();
    }

  ngOnInit(): void {
  }

  prescriptionGetvalue() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.url_prescription, JSON.stringify(
      { pres_id: this.pres_id }),
      { headers: headers }).subscribe(
        data => {
          this.data_prescription = data.json();
          var obj = JSON.parse(data["_body"]);

          if (obj.middle_name != null && obj.middle_name != "") {
            this.total_name = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
          }else {
            this.total_name = obj.first_name + ' ' + obj.last_name;
          }

          if (obj.cl_middle_name != null && encrypt_decript.Decript(obj.cl_middle_name) != undefined) {
            this.dr_total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name);
          }else {
            this.dr_total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
          }
          this.date_prescription = Date_Formate(obj.pres_date);
          this.disease_name = obj.disease;
          this.disease_describtion = obj.dis_desc;

          for (var i = 0; i < obj.drug_list.length; i++) {
            var master_tablet_data = obj.drug_list[i];
            var medicene_dosage_data = "";
            if (master_tablet_data.intake_desc == "Every 6 hours") {
              medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning;
            }else {
              medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.afternoon + '-' + master_tablet_data.evening;;
            }

            var master_timeDuration = "";
            if (master_tablet_data.drug_intake == "With food") {
              master_timeDuration = master_tablet_data.drug_intake;
            }else {
              if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
              }else {
                master_timeDuration = master_tablet_data.drug_intake;
              }
            }
            this.tablet_tracker.push({
              medicene_name: master_tablet_data.drug_type_name + ' - ' + master_tablet_data.drug_name,
              medicene_dosage: medicene_dosage_data,
              medicene_intakedays: master_tablet_data.days + ' days ',
              medicene_food: master_timeDuration
            });
          }
        },
        error => {});
  }

  ClosePopup(){
    this.dialogRef.close();
  }

}
