<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white;">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Drug Upload</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/xl_import.svg" (click)="importFromExcel()"
                            class="saveimgbtn_inpatinfo" alt="">
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save_data()"
                            class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                        Inventory details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Pharmacy Location<br>
                                    <select disableOptionCentering class="ipcss" [(ngModel)]="locationName"
                                        (change)="selectLocation()">
                                        <option disabled>Select</option>
                                        <option *ngFor="let loc of pharmacyLocationArray" value={{loc.location}}>
                                            {{loc.location}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Purchase Order No<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="poID"
                                        (change)="getPurchaseDetails()">
                                        <option disabled>Select</option>
                                        <option *ngFor="let po of product_data" value={{po.purchase_order_id}}>
                                            {{po.purchase_order_id}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Invoice No<br>
                                    <input type="text" class="ipcss " [(ngModel)]="invoiceNO" required maxlength="25"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Invoice date<br>
                                    <input type="date" class="ipcss " [(ngModel)]="invDate" disable="true" matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Invoice amount<br>
                                    <input type="text" class="ipcss " [(ngModel)]="invoiceAmount" required maxlength="10"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Supplier / Distributor name<br>
                                    <input type="text" class="ipcss " [(ngModel)]="manufacturer" disable="true"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">GST number<br>
                                    <input type="text" class="ipcss" readonly [(ngModel)]="gst_no" disable="true"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Received date<br>
                                    <input type="date" class="ipcss " [(ngModel)]="recvDate" disable="true" matInput />
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" [hidden]="inventory.length == 0" style="margin-top:15px">
                    <div class="col-12">
                        <img style="float:right;" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
                            (click)="addInventory(inventory)" />
                    </div>
                </div>
                <div class="row" style="margin-top:15px;padding: 0 10px 0 10px;">
                    <div [hidden]="inventory.length == 0" class="dig_table_overflow">
                        <div class="table-responsive">
                            <table id="tbl" class="table table-hover table-dynamic" style="font-size: 11px;">
                                <thead>
                                    <tr>
                                        <th>Product name</th>
                                        <!-- <th> Package</th> -->
                                        <th>Batch no</th>
                                        <!-- <th>UOM</th> -->
                                        <th>Rec qty </th>
                                        <th>Free qty </th>
                                        <th>CP</th>
                                        <th>SP</th>
                                        <th>MRP</th>
                                        <th>CGST</th>
                                        <th>SGST</th>
                                        <th>Discount</th>
                                        <th>Mfg date</th>
                                        <!-- <th>Receive date</th> -->
                                        <th>Expiry date</th>
                                        <th> Action </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of inventory">
                                        <td>{{data.product_name}}</td>
                                        <!-- <td class="d-flex align-items-center" style="justify-content: center;">
                                            <select disableOptionCentering class="ipcss pharma_invt_width" [(ngModel)]="data.package_code" style="margin-top: 2px;">
                                                <option *ngFor="let code of package_data" value={{code.pack_code}} >
                                                    {{code.pack_name}}
                                                </option>
                                            </select>
                                        </td> -->
                                        <td>
                                            <input type="text" class="ipcss pharma_invt_width"  [(ngModel)]="data.batch_no" maxlength="15" (change)="batchNo(data)" required>
                                        </td>
                                        <!-- <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.uom_desc" maxlength="15" matInput /></td> -->
                                        <td><input type="text" class="ipcss pharma_invt_width"  [(ngModel)]="data.quantity" maxlength="4" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                                        </td>
                                        <td><input type="text" class="ipcss pharma_invt_width"
                                                [(ngModel)]="data.free_qty" maxlength="4"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                matInput />
                                        </td>
                                        
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.cost_price" required></td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.sales_price" required (keyup)="setSalesPrice(data)"></td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.unit_price" required ></td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.cgst" maxlength="2"  onkeypress="return event.charCode >= 48 && event.charCode <= 57"  matInput />
                                        </td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.sgst"
                                                maxlength="2"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                matInput />
                                        </td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.discount" maxlength="2"  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                matInput />
                                        </td>
                                        <td><input style="margin-top: 13px;" class="ipcss pharma_invt_width" [(ngModel)]="data.mfg_date" matInput /></td>
                                        <!-- <td>
                                            <input type="date" class="ipcss" [(ngModel)]="data.qty_recieved_date1" style="width: 100px!important;
                                            font-size: 9px !important;" (change)="ReceviedDate_click(data.qty_recieved_date1,data)" max="{{currentDate}}" matInput />
                                        </td> -->
                                        <!-- <td><input style="margin-top: 13px;" class="ipcss pharma_invt_width" type="text"
                                                [(ngModel)]="data.expiry_date" matInput /></td>
                                        <td> -->
                                        <td><input style="margin-top: 13px;" class="ipcss pharma_invt_width" type="tel" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                             (keyup)="changeExpiryDate(data.expiry_date,data)" (change)="validateExpiryDate(data.expiry_date,data)"
                                                [(ngModel)]="data.expiry_date" matInput maxlength="7" placeholder="mm-yyyy"/></td>
                                        <td>
                                            <img *ngIf="data.checked == false" (click)="isAllSelected(data,'select')" src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"  width="15px" />
                                            <img *ngIf="data.checked == true" (click)="isAllSelected(data,'unselect')"  src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"  width="15px" />
                                            <!-- <a (click)="deleteItemReceive(data)" class="curser_pointer">
                                                <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                                    height="auto" class="editDelicon" alt="">
                                            </a> -->
                                            <!-- &nbsp;&nbsp;&nbsp;
                                                <a (click)="itemreceive_edit(data.product_id,data.quantity)" class="curser_pointer">
                                                <img src="../../assets/imgs/edit.png" class="edit_style" alt="">
                                                </a> -->


                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>