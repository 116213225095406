<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Subjective global assessment</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="onNoClick()" width="20px" height="20px" id="close_icd" />
    </div>
</div>

<div mat-dialog-content class="diet_content">
    <div class="container">
        <div class="row" style="width: 100%;margin: 15px auto;">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Usual weight
                    <br><input type="text" class="ipcss" matInput [(ngModel)]="sga_usual_weight"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" (keyup)="getchange1()" />
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Measure
                    <br>
                    <select class="ipcss" [(ngModel)]="sga_usual_measure" (change)="getchange1()">
                        <option value="kgs">kgs</option>
                        <option value="lbs">lbs</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Current weight
                    <br><input type="text" class="ipcss" matInput [(ngModel)]="sga_current_weight"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" (keyup)="getchange1()" />
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Measure
                    <br>
                    <select class="ipcss" [(ngModel)]="sga_current_measure" disabled>
                        <option value="kgs">kgs</option>
                        <option value="lbs">lbs</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Amount of weight loss
                    <br><input type="text" class="ipcss" matInput [(ngModel)]="amount_loss" disabled="true" />
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">% of weight loss
                    <br><input type="text" class="ipcss" matInput [(ngModel)]="weight_loss" disabled="true" />
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Weight change past 6 months
                    <br>
                    <select class="ipcss" [(ngModel)]="change_six_months" disableOptionCentering
                        (change)="weight_changesix()" class="ipcss">
                        <option value="0-5%">0-5%</option>
                        <option value="5-10%">5-10%</option>
                        <option value=">10%">>10%</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Weight change past 2 weeks
                    <br>
                    <select class="ipcss" [(ngModel)]="change_two_weeks" disableOptionCentering
                        (change)="weight_changepast()" class="ipcss">
                        <option value="No change - normal weight">No change - normal weight</option>
                        <option value="Increase (within 5%)">Increase (within 5%)</option>
                        <option value=" Increase (5-10%)">Increase (5-10%)</option>
                        <option value=" Increase (>10%)">Increase (>10%)</option>
                        <option value="No change - below usual weight ">No change - below usual weight</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Changes in dietary intake
                    <br>
                    <select class="ipcss" [(ngModel)]="change_diet_intake" disableOptionCentering
                        (change)="dietary_change()">
                        <option value="No change-adequate">No change-adequate</option>
                        <option value="No change-inadequate">No change-inadequate</option>
                        <option value="Change-Suboptimal diet">Change-Suboptimal diet</option>
                        <option value="Change-Full liquid">Change-Full liquid</option>
                        <option value="Change-Hypocaloric liquid">Change-Hypocaloric liquid</option>
                        <option value="Change-Starvation">Change-Starvation</option>
                        <option value="Intake borderline-increasing">Intake borderline-increasing</option>
                        <option value="Intake borderline-decreasing">Intake borderline-decreasing</option>
                        <option value="Intake poor-no change">Intake poor-no change</option>
                        <option value="Intake poor-increasing">Intake poor-increasing</option>
                        <option value="Intake poor-decreasing">Intake poor-decreasing</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Duration of change
                    <br>
                    <select class="ipcss" [(ngModel)]="chn_diet_int_dur" disableOptionCentering
                        (change)="duration_change()">
                        <option value="less than 2 weeks">less than 2 weeks</option>
                        <option value="Less than a month">Less than a month</option>
                        <option value="2-6 months">2-6 months</option>
                        <option value="6-12 months">6-12 months</option>
                        <option value="more than a year">more than a year</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Gastrointestinal symptoms
                    <br>
                    <select class="ipcss" [(ngModel)]="gi_symptoms" disableOptionCentering (change)="gisym_onchage()">
                        <option value="None">None</option>
                        <option value="Nausea">Nausea</option>
                        <option value="Vomiting">Vomiting</option>
                        <option value="Diarrhoea">Diarrhoea</option>
                        <option value="Anorexia">Anorexia</option>
                    </select>
                </mat-label>
            </div>
            <div [hidden]="giin_text" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Freqency
                    <br>
                    <select class="ipcss" [(ngModel)]="frequency" disableOptionCentering
                        (change)="gi_frequencychange()">
                        <option value="None-intermittent">None-intermittent</option>
                        <option value="Some-daily >2 weeks">Some-daily >2 weeks</option>
                        <option value="All-daily >2 weeks">All-daily >2 weeks</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Functional capacity
                    <br>
                    <select class="ipcss" [(ngModel)]="fun_capacity" disableOptionCentering
                        (change)="capacity_change()">
                        <option value="Optimal">Optimal</option>
                        <option value="Ambulatory/Suboptimal">Ambulatory/Suboptimal</option>
                        <option value="Bed/Chair-ridden">Bed/Chair-ridden</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Functional capacity - change
                    <br>
                    <select class="ipcss" [(ngModel)]="change_fun_capacity" disableOptionCentering
                        (change)="functional_capacity()">
                        <option value="Improved">Improved</option>
                        <option value="No change">No change</option>
                        <option value="Regressed">Regressed</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12">
                <p class="tervys_heading ">Subcutaneous fat</p>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Under the eye
                    <br>
                    <select class="ipcss" [(ngModel)]="under_eye" disableOptionCentering (change)="under_eye_fun()">
                        <option value="Slightly bulging area">Slightly bulging area</option>
                        <option value="Hollowed look">Hollowed look</option>
                        <option value="depression">depression</option>
                        <option value="dark circles">dark circles</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Triceps
                    <br>
                    <select class="ipcss" [(ngModel)]="triceps" disableOptionCentering (change)="triceps_change()">
                        <option value="Large space between fingers">Large space between fingers</option>
                        <option value="very little space between fingers">Very little space between fingers
                        </option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Biceps
                    <br>
                    <select class="ipcss" [(ngModel)]="biceps" disableOptionCentering (change)="bicep_change()">
                        <option value="Large space between fingers">Large space between fingers</option>
                        <option value="very little space between fingers">Very little space between fingers
                        </option>
                    </select>
                </mat-label>
            </div>

            <div class="col-12">
                <p class="tervys_heading">Muscle wasting</p>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Temple
                    <br>
                    <select class="ipcss" [(ngModel)]="temple" disableOptionCentering (change)="temple_change()">
                        <option value="Well-defined muscle/flat">Well-defined muscle/flat</option>
                        <option value="Slight depression">Slight depression</option>
                        <option value="Hollowing">Hollowing</option>
                        <option value="Depression">Depression</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Collarbone
                    <br>
                    <select class="ipcss" [(ngModel)]="collarbone" disableOptionCentering
                        (change)="collarbone_change()">
                        <option value="Not visible in Males">Not visible in Males</option>
                        <option value="May be visible but not prominent in females">May be visible but not
                            prominent in
                            females</option>
                        <option value="Some protrusion">Some protrusion</option>
                        <option value="Prominent bone">Prominent bone</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Shoulder
                    <br>
                    <select class="ipcss" [(ngModel)]="shoulder" disableOptionCentering (change)="shoulder_change()">
                        <option value="Rounded">Rounded</option>
                        <option value="No square look">No square look</option>
                        <option value="Square look">Square look</option>
                        <option value="Bones prominent">Bones prominent</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Scapula/ribs
                    <br>
                    <select class="ipcss" [(ngModel)]="ribs" disableOptionCentering (change)="scapula_change()">
                        <option value="Bones not prominent">Bones not prominent</option>
                        <option value="Mild depressions">Mild depressions</option>
                        <option value="Square look">Square look</option>
                        <option value="Bones prominent">Bones prominent</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Quadriceps
                    <br>
                    <select class="ipcss" [(ngModel)]="quadriceps" disableOptionCentering (change)="quadra_change()">
                        <option value="Well rounded or no depression">Well rounded or no depression</option>
                        <option value="Mild depressions">Mild depressions</option>
                        <option value="Depression or Thin">Depression or Thin</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Calf
                    <br>
                    <select class="ipcss" [(ngModel)]="calf" disableOptionCentering (change)="calf_change()">
                        <option value="Well developed">Well developed</option>
                        <option value="Thin or no muscle definition">Thin or no muscle definition</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Knee
                    <br>
                    <select class="ipcss" [(ngModel)]="knee" disableOptionCentering (change)="knee_change()">
                        <option value="Bones not prominent">Bones not prominent</option>
                        <option value="Bones  prominent">Bones prominent</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Interosseous muscle between
                    <br> thumb and forefinger
                    <br>
                    <select class="ipcss" [(ngModel)]="thumb_finger" name="thumb_finger" disableOptionCentering
                        (change)="inter_change()">
                        <option value="Muscle protrudes">Muscle protrudes</option>
                        <option value="Flat or depressed area">Flat or depressed area</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Oedema
                    <br>
                    <select class="ipcss" name="oedema" [(ngModel)]="oedema" disableOptionCentering
                        (change)="oedema_change()">
                        <option value="None">None</option>
                        <option value="Mild to moderate">Mild to moderate</option>
                        <option value="Severe">Severe</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Ascites
                    <br>
                    <select class="ipcss" [(ngModel)]="ascites" disableOptionCentering (change)="ascities_change()">
                        <option value="None">None</option>
                        <option value="Mild to moderate">Mild to moderate</option>
                        <option value="Severe">Severe</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Nourishment level
                    <br><input [(ngModel)]="overall_txt" type="text" class="ipcss" matInput />
                </mat-label>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
        <button (click)="done_click()" id="add_btn" [mat-dialog-close]="overall_txt" cdkFocusInitial mat-button>
            <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
        </button>
</div>