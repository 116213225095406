<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header" >
            <h5 class="m-0" class="mainHeadingStyle">Bill details</h5>
          </div>
          <div class="headerButtons">
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo"  /></a>
          <a *ngIf="show_save_btn" (click)="Bill_save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
              class="saveimgbtn_inpatinfo" style="margin-left: 7px;"/></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"[hidden]="date_time_flag">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss_date" id="appt_date" (change)="OnDateChangedto(bill_date)" [(ngModel)]="bill_date" max="{{currentDate}}" #matInput style="background: #fff;">
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy">
            <mat-label class="matlabel">Appointment id<br>
              <mat-select required id="appt_id" class="ipcss widthbillcreate" style="border-color: rgb(250, 40, 2);"
                [(ngModel)]="appoint_txt" disableOptionCentering>
                <mat-option *ngFor="let billcreate of appointment_list" value="{{billcreate.doc_app_id}}"
                  (click)="Appointmnet_change(billcreate.doc_app_id)">{{billcreate.docid_name}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Patient name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="patient_name" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Service type<br>
              <input type="text" class="ipcss inpat_width" [(ngModel)]="charge_type"
              (keyup)="getServiceType(charge_type)" required matInput/>
              <div class="auto_complete_text1">
                  <ul *ngFor="let charge of serviceType">
                      <li>
                          <a (click)="charge_change(charge)">{{charge}}</a>
                      </li>
                  </ul>
              </div>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <mat-select  id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="pay_type" (selectionChange)="pay_change()" disableOptionCentering>
                <mat-option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="insur_flag">
            <mat-label class="matlabel">Insurer<br>
              <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurer_id" disableOptionCentering>
                <mat-option *ngFor="let ins of insurerlist" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="card_flag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
         
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="card_flag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="tran_flag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="Transaction_id" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cheque_flag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cheque_flag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bill_qty_flag">
            <mat-label class="matlabel">Quantity<br>
              <input type=number class="ipcss billing_text_align widthbillcreate" matInput required (keyup)="amount_change()"
                [(ngModel)]="quantity" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Amount<br>
              <input type=number class="ipcss billing_text_align widthbillcreate" matInput required (keyup)="amount_change()"
                [(ngModel)]="amount_txt" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Concession(%)<br>
              <input  type=number class="ipcss billing_text_align  billing_amt_ip" matInput (keyup)="amount_change()" [(ngModel)]="concession_txt" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">After concession
              <input  type=number class="ipcss billing_text_align billing_amt_ip" [(ngModel)]="amount_aftcon" />
            </mat-label>
          </div>
          <div class="col-12 col-md-12 col-lg-12 col-xl-12 ">
            <mat-label class="addvalue matlabel">
              <a (click)="Bill_add_click()"><img src="../../../assets/ui_icons/buttons/add_button.svg"
                  class="saveimgbtn_inpatinfo" /></a>
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <div class="table-responsive" *ngIf="billaddlist.length">
              <table id="bill_create" class="table table-nowrap table-sm billcreae_table">
                <thead class="tableheading"> 
                  <tr style="height: 32px;">
                    <th>Sno</th>
                    <th>Service</th>
                    <th>Payment type</th>
                    <th>Fees</th>
                    <th [hidden]="bill_qty_flag">Quantity</th>
                    <th [hidden]="bill_cons_flag">Concession(%)</th>
                    <th>Amount</th>
                    <th class="delete">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of billaddlist" class="tdvalues">
                    <td style="text-align: center;">{{person.sno}}</td>
                    <td style="text-align: left;">{{person.biill_type}}</td>
                    <td style="text-align: left;">{{person.pay_type}}</td>
                    <td style="text-align: right;">{{person.amount_txt}} </td>
                    <td style="text-align: center;" [hidden]="bill_qty_flag">{{person.quantity}}</td>
                    <td style="text-align: right !important;" [hidden]="bill_cons_flag">{{person.concession}}</td>
                    <td  style="text-align: right !important;">{{person.fee}}</td>
                    <td class="delete" >
                      <a (click)="Delete_bill(person.biill_type,person.amount_txt)"> <img
                          src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" alt=""></a>
                    </td>                 
                  </tr>
                </tbody>
              </table> 
            </div>
                     
          </div>
        </div>
        <div [hidden]="billden_plan.length==0">
          <p class="casesheet_para">Treatement plan</p>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <table *ngIf="billden_plan.length" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable billcreae_table">
            <thead>
              <tr>
                <th>Plan</th>
                <th class="tbl_txt_center">Estimate</th>
                <th class="tbl_txt_center">Select</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of billden_plan; let i = index">
                <td>{{person.treatment_desc}}</td>
                <td class="tbl_txt_center">{{person.estimate}}</td>
                <td class="tbl_txt_center">
                  <mat-checkbox  color="primary" (change)="bill_click(person.den_treatment_plan_id,bill_palnche,i)" 
                    [checked]="person.checked">
                  </mat-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <br><br><br>
        </div>
        <div class="row ">
          <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" [hidden]="!paytypreq">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                    <mat-select class="ipcss" [(ngModel)]="payType1" disableOptionCentering>
                      <mat-option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" >
                  <mat-label class="matlabel">Amount<br>
                    <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align"
                      (click)="addPaymentDetails()" style="float: right;"/>
                  </mat-label>
                </div>
              </div>

              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                      <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-0 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
          <div class="col-0 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Include GST &nbsp;&nbsp; </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST" (change)="include_gst_click(inGST)">
                </mat-checkbox>
              </div>
              <div *ngFor="let gsttax of gst_data_list">
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                  </div>
                  <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{gsttax.Gst_amount}}</mat-label>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                <mat-label class="matlabel  heightmsr">Previous balance </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                <input class="ipcss  heightmsr bill_txt" matInput text-right [(ngModel)]="balance_data" maxlength="10"
                  disabled />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel">Current bill </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="current_amount" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Total </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="final_amount" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Discount</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" style="display: flex;align-items: center;">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right 
                  [(ngModel)]="round_off" maxlength="10"  (keyup)="addRound('minus')" style="display: inline-block !important;
                  width: 100%;margin: 0px 0px 4px 9px;"/> 
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Advance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="advance_change()"
                  [(ngModel)]="Advance_bill" maxlength="10" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Paid</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="paid_change()"
                  [(ngModel)]="paid_bill" maxlength="10" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr" [hidden]="balance_show">Balance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" [hidden]="balance_show" matInput text-right
                  [(ngModel)]="remaing_billbal" maxlength="10" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7"></div>
          <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                <mat-label class="matlabel  heightmsr">Previous balance </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                <input class="ipcss  heightmsr bill_txt" matInput text-right [(ngModel)]="balance_data" maxlength="10"
                  disabled />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel">Current bill </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="current_amount" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Total </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="final_amount" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Discount</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" style="display: flex;
              align-items: center;">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right 
                  [(ngModel)]="round_off" maxlength="10"  (keyup)="addRound('minus')" style="display: inline-block !important;
                  width: 100%;
                  margin: 0px 0px 4px 9px;"/> 
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Advance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="advance_change()"
                  [(ngModel)]="Advance_bill" maxlength="10" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Paid</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="paid_change()"
                  [(ngModel)]="paid_bill" maxlength="10" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr" [hidden]="balance_show">Balance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" [hidden]="balance_show" matInput text-right
                  [(ngModel)]="remaing_billbal" maxlength="10" />
              </div>
            </div>
          </div>
        </div> -->
      </mdb-card-body>
    </mdb-card>
  </div>
</div>