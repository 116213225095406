<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Report View</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" style="padding-right: 6px;"
                            class="saveimgbtn_inpatinfo" (click)="back_Nav()">
                        <img *ngIf="printFlag" printSectionId="print" (click)="print()"
                            src="../../../assets/img/printer.svg" class="saveimgbtn_inpatinfo"
                            style="width: 25px !important;height: 25px !important;" ngxPrint>
                        <!-- <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="Uploadbtn" class="saveimgbtn_inpatinfo" (click)="ReportUpload()" /> -->
                    </div>
                </div>

            </mdb-card-header>
            <mdb-card-body>
                <div class='row'>
                    <div class='row'>
                        <div class='col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6 '>
                            <mat-card class='card_addr'>
                                <mat-card-title style='margin: 0px !important;' class='card_title tervys_heading'>
                                    Address Details</mat-card-title>
                                <mat-card-content style='padding: 10px 24px;'>
                                    <div class='col-md-12 col-lg-12 col-sm-12 col-xl-12 '>
                                        <mat-label class='matlabel'><strong>{{centerName}}</strong>
                                        </mat-label>
                                    </div>
                                    <div class='col-md-12 col-lg-12 col-sm-12 col-xl-12 '>
                                        <mat-label class='matlabel'>{{diagAddress}},{{diagLocation}},</mat-label>
                                    </div>
                                    <div class='col-md-12 col-lg-12 col-sm-12 col-xl-12 '>
                                        <mat-label class='matlabel'>{{diagCityZip}},{{diagStateCon}}</mat-label>
                                    </div>
                                    <div class='col-md-12 col-lg-12 col-sm-12 col-xl-12 '>
                                        <mat-label class='matlabel'>Contact No:{{diagTelephone}}</mat-label>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class='col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6 '>
                            <mat-card class='card_addr'>
                                <mat-card-title style='margin: 0 !important;' class='card_title tervys_heading'>
                                    Client Details</mat-card-title>
                                <mat-card-content style='padding: 10px 24px;'>
                                    <div class='row'>
                                        <div class='col-6'>
                                            <mat-label class='matlabel'><strong>Date :</strong> {{date}}
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel"><strong>Time :</strong> {{time}}</mat-label>
                                        </div>
                                        <div class='col-6'>
                                            <mat-label class='matlabel'><strong>Patient id :</strong> {{patientID}}
                                            </mat-label>
                                        </div>
                                        <div class='col-6'>
                                            <mat-label class='matlabel'><strong>Name :</strong>
                                                {{clientAnotherName}}
                                            </mat-label>
                                        </div>
                                        <div class='col-6' *ngIf='referFlag'>
                                            <mat-label class='matlabel'><strong>Referred by :</strong>
                                                {{diagReferedBy}}
                                            </mat-label>
                                        </div>
                                        <div class='col-6'>
                                            <mat-label class='matlabel'><strong>Age :</strong> {{clientAge}}
                                            </mat-label>
                                        </div>
                                        <div class='col-6'>
                                            <mat-label class='matlabel'><strong>Gender :</strong> {{clientGender}}
                                            </mat-label>
                                        </div>
                                        <div class='col-6'>
                                            <mat-label class='matlabel'><strong>Collected at :</strong>
                                                {{sampleCollectedTime}}</mat-label>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>

                    <div [hidden]='tableReportTypeFlag'>
                        <p class="tervys_heading clinical col-12"><b style="text-transform: capitalize;">{{reportType}}
                                Test Results</b></p>
                        <div class='row'>
                            <!-- <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                    <mat-label class='matlabel'><b>Report Type: {{report_type}}</b></mat-label>
                                </div> -->
                            <!-- <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <mat-label *ngIf="subtestname_flag" class='matlabel'><b>{{testName}}</b>
                                </mat-label>
                            </div> -->
                            <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <div class="dig_table_overflow">
                                    <div class="table-responsive">
                                        <table id='card_tbl' class='table table-hover table-dynamic'>
                                            <thead>
                                                <tr>
                                                    <th style="width:250px;text-align: left;">Test Name</th>
                                                    <th style="width:200px;text-align: center;">Reading</th>
                                                    <th style="width:150px;text-align: center;">Unit</th>
                                                    <th style="text-align: left;">Referred value</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let test of diagTestReadingArray">
                                                <tr>
                                                    <td colspan="4" style="text-align: left;font-weight: 400;">
                                                        <b>{{test.category}} - {{test.test_name}}</b>
                                                    </td>
                                                </tr>

                                                <tr *ngFor='let subtest of test.sub_test_list'>
                                                    <td style='text-align: left;margin-bottom: 15px !important'>
                                                        <div style="margin-bottom: 15px !important;">
                                                            <strong>
                                                                <p>{{subtest.sub_test_name}}</p>
                                                            </strong>
                                                            <span *ngIf="subtest.testMethod != undefined"
                                                                class="testMethod">( <div
                                                                    [innerHtml]="subtest.testMethod"></div> )</span>
                                                        </div>
                                                    </td>
                                                    <td>{{subtest.reading}}</td>
                                                    <td>{{subtest.unit}}</td>
                                                    <td style=" text-align: left;">
                                                        <div [innerHtml]='subtest.test_range | safeHtml'
                                                            style="margin-bottom: 15px !important;"></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [hidden]='paraReportTypeFlag'>
                        <div class='row' style="margin-left: 20px;margin-right: 20px;">
                            <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style='margin-top:13px;'>
                                <mat-label class='matlabel'><strong>Report type :</strong> {{reportType}}
                                </mat-label>
                            </div>
                            <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style='margin-top:5px;'>
                                <!-- <mat-label class='matlabel'><strong>Test name :</strong> {{testName}}</mat-label> -->
                                <mat-label class='matlabel'><strong>Test name :</strong> {{testName}}</mat-label>
                            </div>
                            <div *ngIf="!cultureFlag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
                                style='margin-top:5px;'>
                                <p class='tervys_heading clinical'>Sequences</p>
                            </div>
                            <div *ngIf="!cultureFlag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <quill-view-html id='viewhtml' [content]='sequence'></quill-view-html>
                            </div>
                            <div *ngIf="!cultureFlag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <p class='tervys_heading clinical'>Impression</p>
                            </div>
                            <div *ngIf="!cultureFlag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <quill-view-html id='viewhtml' [content]='Impression'></quill-view-html>
                            </div>
                            <div *ngIf="cultureFlag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <p class='tervys_heading clinical'>Report details</p>
                            </div>
                            <div *ngIf="cultureFlag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <quill-view-html id='viewhtml' [content]='culture'></quill-view-html>
                            </div>
                            <div [hidden]='dicomDotor' class='col-12'>
                                <mat-card *ngIf="!cultureFlag">
                                    <mat-card-content>
                                        <img alt='report image' src='{{reportIMG}}' (click)='clickDocument()'
                                            class='report_img' />
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="testMethod != undefined && !subtestMethodFlag" class="row" style="margin-top: 10px;">
                        <div class="col-1" style="min-width: fit-content;">
                            <mat-label class="matlabel"><b>Test method :</b></mat-label>
                        </div>
                        <div class="col-10">
                            <div *ngIf="testMethod != undefined && !subtestMethodFlag" class="col-12"
                                [innerHtml]="testMethod | safeHtml">
                            </div>
                        </div>
                    </div>

                    <hr [hidden]="reviewFlag || createdBy == undefined"
                        style="border: 1px solid #51b0e7;margin-top: 10px;">
                    <div class="row" style='padding: 0px 22px;'>
                        <table>
                            <tr>
                                <td style="width: 90%;text-align: right;">
                                    <div class="row" [hidden]="createdBy == undefined">
                                        <div [hidden]="createdBy == undefined" class="col-12">
                                            <mat-label class="matlabel"><strong>Lab technician
                                                    :</strong> {{createdBy}}
                                            </mat-label>
                                        </div>
                                        <div class="col-12" style="margin-top: 10px;">
                                            <mat-label class="matlabel"><strong>Signature
                                                    :</strong>
                                            </mat-label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <!-- print -->
                <div [hidden]='true' id="printID">
                    <div #printlogowithname id="printlogowithname" style="background-image: url({{bgImage}}); background-position: center;">
                        <table style="border:'0';width:100%">
                            <thead>
                                <tr>
                                    <th style="width:100%;height: 100px;"></th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <td width="100%">
                                        <table style="border:'0';width:100%">
                                            <tr>
                                                <td style="width:100%;height: 100px;">&nbsp;</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td width="100%">
                                        <table class="report-container"
                                            style="width: 100%;margin-left:50px;margin-top:70px;">
                                            <thead class="report-header">
                                                <tr>
                                                    <td style="display: flex;justify-content: center;">
                                                        <div>
                                                            <img alt="image" src={{hptlLogo}} width="100px"
                                                                height="100px" style="vertical-align: middle;">
                                                        </div>
                                                        <div style="position: relative;top: 20px;left: 10px;">
                                                            <p><b>{{hospitalName}}</b>,</p>
                                                            <span>{{location}},</span> <span>{{city}},</span>
                                                            <span>{{state}},</span><span>{{country}}.</span><br>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tfoot class="report-footer">
                                                <tr>
                                                    <td style="height:100px;"></td>
                                                </tr>
                                            </tfoot>
                                            <tbody class="report-content">
                                                <tr>
                                                    <td class="report-content-cell">
                                                        <div class="main">
                                                            <table style="border: none !important;width: 100%;">
                                                                <tr>
                                                                    <td
                                                                        style="width: 500px;text-align: left; border: none !important">
                                                                        <p style="line-height: 1.5;"><strong>Patient id
                                                                                :</strong>{{patientID}}</p>
                                                                        <p style="line-height: 1.5;"><strong>Name
                                                                                :</strong>{{clientAnotherName}}</p>
                                                                        <p style="line-height: 1.5;"><strong>Age
                                                                                :</strong>{{clientAge}}
                                                                        </p>
                                                                        <p style="line-height: 1.5;"><strong>Gender
                                                                                :</strong>{{clientGender}}</p>
                                                                    </td>
                                                                    <td
                                                                        style="width: 300px;vertical-align: top;text-align: left; border: none !important">
                                                                        <p style="line-height: 1.5;"><strong>Date
                                                                                :</strong>{{date}}
                                                                        </p>
                                                                        <p style="line-height: 1.5;"><strong>Time
                                                                                :</strong>{{time}}
                                                                        </p>
                                                                        <p style="line-height: 1.5;"><strong>Referred by
                                                                                :</strong>{{diagReferedBy}}</p>
                                                                        <p style="line-height: 1.5;"><strong>Collected
                                                                                at
                                                                                :</strong>{{sampleCollectedTime}}</p>
                                                                    </td>Test Name
                                                                </tr>
                                                            </table>
                                                            <div [hidden]='tableReportTypeFlag'>
                                                                <div class='row'>
                                                                    <div class='col-print-12'
                                                                        style="text-align: center;background: #d0eaf7;width:90%;">
                                                                        <mat-label class='matlabel'
                                                                            style="font-size: 17px;"><b>Clinical
                                                                                Laboratory Reports</b></mat-label>
                                                                    </div><br><br>
                                                                    <div class='col-print-12'>
                                                                        <table cellpadding='0' cellspacing='0' style=" width:100%;
                                                                border-collapse: collapse;">
                                                                            <tr>
                                                                                <th
                                                                                    style="width:200px;text-align: left;">
                                                                                    Test Name</th>
                                                                                <th
                                                                                    style="width:150px;text-align: center;">
                                                                                    Reading</th>
                                                                                <th
                                                                                    style="width:100px;text-align: center;">
                                                                                    Unit</th>
                                                                                <th>Reference value</th>
                                                                            </tr>
                                                                            <tbody *ngFor="let test of printArray">
                                                                                <tr>
                                                                                    <td style="padding: 5px 0 5px 20px;text-align: left;"
                                                                                        colspan="4">
                                                                                        <!-- <b>{{test.test_name}}</b> -->
                                                                                        <b>{{test.category}} -
                                                                                            {{test.test_name}}</b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr
                                                                                    *ngFor='let subtest of test.sub_test_list'>
                                                                                    <td
                                                                                        style='padding-left: 20px;text-align: left;margin-bottom: 15px !important'>
                                                                                        <div
                                                                                            style="margin-bottom: 15px !important;">
                                                                                            <p>{{subtest.sub_test_name}}
                                                                                            </p>
                                                                                            <span
                                                                                                *ngIf="subtest.testMethod != undefined"
                                                                                                style="display: flex;">(
                                                                                                <div
                                                                                                    [innerHtml]="subtest.testMethod">
                                                                                                </div>
                                                                                                )</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style='text-align: center;'>
                                                                                        {{subtest.reading}}
                                                                                    </td>
                                                                                    <td style='text-align: center;'>
                                                                                        {{subtest.unit}}
                                                                                    </td>
                                                                                    <td style=" text-align: left;">
                                                                                        <div class="test_range"
                                                                                            style="margin-bottom: 15px !important;"
                                                                                            [innerHtml]='subtest.test_range | safeHtml'>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div [hidden]='paraReportTypeFlag'>
                                                                <div class='row'
                                                                    style="margin-left: 20px;margin-right: 20px;">
                                                                    <div class='col-print-12' style='margin-top:13px;'>
                                                                        <mat-label class='matlabel'><strong>Report type
                                                                                :</strong>
                                                                            {{reportType}}
                                                                        </mat-label>
                                                                    </div>
                                                                    <div class='col-print-12' style='margin-top:5px;'>
                                                                        <mat-label class='matlabel'><strong>Test name
                                                                                :</strong>
                                                                            {{testName}}
                                                                        </mat-label>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'
                                                                        style='margin-top:5px;'>
                                                                        <p class='tervys_heading clinical'>
                                                                            <b>Sequences</b></p>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='sequence'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'>
                                                                        <p class='tervys_heading clinical'>
                                                                            <b>Impression</b></p>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'
                                                                        style='margin-bottom: 12px;'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='Impression'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div *ngIf="cultureFlag" class='col-print-12'>
                                                                        <p class='tervys_heading clinical'>Report
                                                                            details</p>
                                                                    </div>
                                                                    <div *ngIf="cultureFlag" class='col-print-12'
                                                                        style='margin-bottom: 12px;'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='culture'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div [hidden]='dicomDotor' class='col-print-6'>
                                                                        <div *ngIf="!cultureFlag">
                                                                            <div class='tervys_heading'>Attachment</div>
                                                                            <div>
                                                                                <img alt='report image'
                                                                                    src='{{reportIMG}}'
                                                                                    (click)='clickDocument()'
                                                                                    class='report_img' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr [hidden]='sigSectionFlag'
                                                                style='border: 1px solid #000;'>
                                                            <div class="row" style='padding: 0px 22px;'>
                                                                <table>
                                                                    <tr>
                                                                        <td style="width: 90%;text-align: right;">
                                                                            <div class="row"
                                                                                [hidden]="createdBy == undefined">
                                                                                <div [hidden]="createdBy == undefined"
                                                                                    class="col-12">
                                                                                    <mat-label
                                                                                        class="matlabel"><strong>Lab
                                                                                            technician
                                                                                            :</strong> {{createdBy}}
                                                                                    </mat-label>
                                                                                </div>
                                                                                <div class="col-12"
                                                                                    style="margin-top: 10px;">
                                                                                    <mat-label
                                                                                        class="matlabel"><strong>Signature
                                                                                            :</strong>
                                                                                    </mat-label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div #printbanner id="printbanner" style="background-image: url({{bgImage}}); background-position: center;">
                        <table style="border:'0';width:100%">
                            <thead>
                                <tr>
                                    <th style="width:100%;height: 100px;"></th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <td width="100%">
                                        <table style="border:'0';width:100%; margin-bottom: 0;">
                                            <tr>
                                                <td style="width:100%;height: 100px;">&nbsp;
                                                    <img src={{hospitalFooter}} width="100%" height="200px">
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td width="100%">
                                        <div style="width: 100%;height: auto;display: contents;">
                                            <table class=paging style="width:100%;margin-top: -100px;">
                                                <thead style="display:table-header-group;">
                                                    <tr>
                                                        <td style="width: 100%;">
                                                            <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
                                                            <div style="display: flex;margin-left: 20px;padding-bottom: 10px;">
                                                                <div style="width: 500px;text-align: left; border: none !important">
                                                                    <p><strong>Patient id:</strong>{{patientID}}</p>
                                                                    <p><strong>Name :</strong>{{clientAnotherName}}</p>
                                                                    <p><strong>Age :</strong>{{clientAge}}</p>
                                                                    <p><strong>Gender :</strong>{{clientGender}}</p>
                                                                </div>
                                                                <div
                                                                    style="width: 300px;text-align: left; border: none !important">
                                                                    <p><strong>Date :</strong>{{date}}</p>
                                                                    <p><strong>Time :</strong>{{time}}</p>
                                                                    <p><strong>Referred by :</strong>{{diagReferedBy}}
                                                                    </p>
                                                                    <p><strong>Collected at :</strong>{{sampleCollectedTime}}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div class="main">
                                                                <div [hidden]='tableReportTypeFlag'>
                                                                    <div class='row'>
                                                                        <div class='col-print-12'
                                                                            style="text-align: center;background: #d0eaf7;width:90%;">
                                                                            <mat-label class='matlabel'
                                                                                style="font-size: 17px;">
                                                                                <b>Clinical Laboratory Reports</b>
                                                                            </mat-label>
                                                                        </div><br><br>
                                                                        <div class='col-print-12'>
                                                                            <table cellpadding='0' cellspacing='0'
                                                                                style="width:100%;border: 1p solid #747575;">
                                                                                <tr>
                                                                                    <th style="width:200px;text-align: left;">
                                                                                        Test Name
                                                                                    </th>
                                                                                    <th style="width:150px;text-align: center;">
                                                                                        Reading
                                                                                    </th>
                                                                                    <th style="width:130px;text-align: center;">
                                                                                        Unit
                                                                                    </th>
                                                                                    <th>Reference value</th>
                                                                                </tr>
                                                                                <tbody *ngFor="let test of printArray">
                                                                                    <tr>
                                                                                        <td style='padding: 5px 0 5px 20px;text-align: left;'
                                                                                            colspan="4">
                                                                                            <!-- <b>{{test.test_name}}</b> -->
                                                                                            <b>{{test.category}} -
                                                                                                {{test.test_name}}</b>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr *ngFor='let subtest of test.sub_test_list'
                                                                                        style="border: 1px solid #6e7070;">
                                                                                        <td style='padding-left: 20px;text-align: left;'>
                                                                                            <div style="margin-bottom: 15px !important;">
                                                                                                <p>{{subtest.sub_test_name}}
                                                                                                </p>
                                                                                                <span *ngIf="subtest.testMethod != undefined"
                                                                                                    style="display: flex;">(
                                                                                                    <div
                                                                                                        [innerHtml]="subtest.testMethod">
                                                                                                    </div> )</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style='text-align: center;'>
                                                                                            {{subtest.reading}}
                                                                                        </td>
                                                                                        <td style='text-align: center;'>
                                                                                            {{subtest.unit}}
                                                                                        </td>
                                                                                        <td
                                                                                            style=" text-align: left;padding-left: 20px !important;">
                                                                                            <div class="test_range"
                                                                                                style="margin-bottom: 15px !important;"
                                                                                                [innerHtml]='subtest.test_range | safeHtml'>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div [hidden]='paraReportTypeFlag'>
                                                                    <div class='row'
                                                                        style="margin-left: 20px;margin-right: 20px;">
                                                                        <div class='col-print-12'
                                                                            style='margin-top:13px;'>
                                                                            <mat-label class='matlabel'><strong>Report type :</strong>
                                                                                {{reportType}}
                                                                            </mat-label>
                                                                        </div>
                                                                        <div class='col-print-12'
                                                                            style='margin-top:5px;'>
                                                                            <mat-label class='matlabel'><strong>Test name :</strong>
                                                                                {{testName}}
                                                                            </mat-label>
                                                                        </div>
                                                                        <div *ngIf="!cultureFlag" class='col-print-12'
                                                                            style='margin-top:5px;'>
                                                                            <p class='tervys_heading clinical'>
                                                                                <b>Sequences</b></p>
                                                                        </div>
                                                                        <div *ngIf="!cultureFlag" class='col-print-12'>
                                                                            <quill-view-html id='viewhtml'
                                                                                [content]='sequence'>
                                                                            </quill-view-html>
                                                                        </div>
                                                                        <div *ngIf="!cultureFlag" class='col-print-12'>
                                                                            <p class='tervys_heading clinical'>
                                                                                <b>Impression</b></p>
                                                                        </div>
                                                                        <div *ngIf="!cultureFlag" class='col-print-12'
                                                                            style='margin-bottom: 12px;'>
                                                                            <quill-view-html id='viewhtml'
                                                                                [content]='Impression'>
                                                                            </quill-view-html>
                                                                        </div>
                                                                        <div *ngIf="cultureFlag" class='col-print-12'>
                                                                            <p class='tervys_heading clinical'>Report details</p>
                                                                        </div>
                                                                        <div *ngIf="cultureFlag" class='col-print-12'
                                                                            style='margin-bottom: 12px;'>
                                                                            <quill-view-html id='viewhtml'
                                                                                [content]='culture'>
                                                                            </quill-view-html>
                                                                        </div>
                                                                        <div [hidden]='dicomDotor' class='col-print-6'>
                                                                            <div *ngIf="!cultureFlag">
                                                                                <div class='tervys_heading'>Attachment
                                                                                </div>
                                                                                <div>
                                                                                    <img alt='report image' src='{{reportIMG}}' (click)='clickDocument()'
                                                                                        class='report_img' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr [hidden]='sigSectionFlag' style='border: 1px solid #000;'>
                                                                <div class="row" style='padding: 0px 22px;'>
                                                                    <table>
                                                                        <tr>
                                                                            <td style="width: 90%;text-align: right;">
                                                                                <div class="row" [hidden]="createdBy == undefined">
                                                                                    <div [hidden]="createdBy == undefined" class="col-12">
                                                                                        <mat-label class="matlabel"><strong>Lab technician:</strong> {{createdBy}}
                                                                                        </mat-label>
                                                                                    </div>
                                                                                    <div class="col-12" style="margin-top: 10px;">
                                                                                        <mat-label class="matlabel"><strong>Signature:</strong>
                                                                                        </mat-label>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <!-- <tr>
                                                        <td>
                                                            <div class="empty-footer"></div>
                                                        </td>
                                                    </tr> -->
                                                </tfoot>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div #printnoheader id="printnoheader" style="background-image: url({{bgImage}}); background-position: center;">
                        <table style="border:'0';width:100%">
                            <thead>
                                <tr>
                                    <th style="width:100%;height: 100px;"></th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <td width="100%">
                                        <table style="border:'0';width:100%">
                                            <tr>
                                                <td style="width:100%;height: 100px;">&nbsp;</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td width="100%">
                                        <table class="report-container" style="width: 100%;margin-left: 50px;">
                                            <thead style="display:table-header-group;">
                                                <tr>
                                                    <td style="width: 100%;">
                                                        <div style="height:130px"></div>
                                                        <div
                                                            style="display: flex;margin-left: 20px;padding-bottom: 10px;">
                                                            <div
                                                                style="width: 500px;text-align: left; border: none !important">
                                                                <p><strong>Patient id :</strong>{{patientID}}</p>
                                                                <p><strong>Name :</strong>{{clientAnotherName}}</p>
                                                                <p><strong>Age :</strong>{{clientAge}}</p>
                                                                <p><strong>Gender :</strong>{{clientGender}}</p>
                                                            </div>
                                                            <div
                                                                style="width: 300px;text-align: left; border: none !important">
                                                                <p><strong>Date :</strong>{{date}}</p>
                                                                <p><strong>Time :</strong>{{time}}</p>
                                                                <p><strong>Referred by :</strong>{{diagReferedBy}}</p>
                                                                <p><strong>Collected at
                                                                        :</strong>{{sampleCollectedTime}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tfoot class="report-footer">
                                                <tr>
                                                    <td style="height:100px;"></td>
                                                </tr>
                                            </tfoot>
                                            <tbody class="report-content">
                                                <tr>
                                                    <td class="report-content-cell">
                                                        <div class="main">
                                                            <div [hidden]='tableReportTypeFlag'>
                                                                <div class='row'>
                                                                    <div class='col-print-12'
                                                                        style="text-align: center;background: #d0eaf7;width:90%;">
                                                                        <mat-label class='matlabel'
                                                                            style="font-size: 17px;"><b>Clinical
                                                                                Laboratory Reports</b></mat-label>
                                                                    </div><br><br>
                                                                    <div class='col-print-12'>
                                                                        <table cellpadding='0' cellspacing='0'
                                                                            style=" width:95%; border-collapse: collapse;    margin-bottom: 20px; margin-right: 5%;">
                                                                            <tr>
                                                                                <th
                                                                                    style="width:200px;text-align: left;">
                                                                                    Test Name</th>
                                                                                <th
                                                                                    style="width:150px;text-align: center;">
                                                                                    Reading</th>
                                                                                <th
                                                                                    style="width:100px;text-align: center;">
                                                                                    Unit</th>
                                                                                <th>Reference value</th>
                                                                            </tr>
                                                                            <tbody *ngFor="let test of printArray">
                                                                                <tr>
                                                                                    <td style='padding: 5px 0 5px 20px;text-align: left;'
                                                                                        colspan="4">
                                                                                        <!-- <b>{{test.test_name}}</b> -->
                                                                                        <b>{{test.category}} -
                                                                                            {{test.test_name}}</b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr
                                                                                    *ngFor='let subtest of test.sub_test_list'>
                                                                                    <td
                                                                                        style='padding-left: 20px;text-align: left;margin-bottom: 15px !important'>
                                                                                        <div
                                                                                            style="margin-bottom: 15px !important;">
                                                                                            <p>{{subtest.sub_test_name}}
                                                                                            </p>
                                                                                            <span
                                                                                                *ngIf="subtest.testMethod != undefined"
                                                                                                style="display: flex;">(
                                                                                                <div
                                                                                                    [innerHtml]="subtest.testMethod">
                                                                                                </div>
                                                                                                )</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style='text-align: center;'>
                                                                                        {{subtest.reading}}
                                                                                    </td>
                                                                                    <td style='text-align: center;'>
                                                                                        {{subtest.unit}}
                                                                                    </td>
                                                                                    <td
                                                                                        style=" text-align: left;padding-left: 20px !important;">
                                                                                        <div class="test_range"
                                                                                            style="margin-bottom: 15px !important;"
                                                                                            [innerHtml]='subtest.test_range | safeHtml'>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div [hidden]='paraReportTypeFlag'>
                                                                <div class='row'
                                                                    style="margin-left: 20px;margin-right: 20px;">
                                                                    <div class='col-print-12' style='margin-top:13px;'>
                                                                        <mat-label class='matlabel'><strong>Report type
                                                                                :</strong>
                                                                            {{reportType}}
                                                                        </mat-label>
                                                                    </div>
                                                                    <div class='col-print-12' style='margin-top:5px;'>
                                                                        <mat-label class='matlabel'><strong>Test name
                                                                                :</strong>
                                                                            {{testName}}
                                                                        </mat-label>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'
                                                                        style='margin-top:5px;'>
                                                                        <p class='tervys_heading clinical'>
                                                                            <b>Sequences</b></p>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='sequence'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'>
                                                                        <p class='tervys_heading clinical'>
                                                                            <b>Impression</b></p>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'
                                                                        style='margin-bottom: 12px;'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='Impression'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div *ngIf="cultureFlag" class='col-print-12'>
                                                                        <p class='tervys_heading clinical'>Report
                                                                            details</p>
                                                                    </div>
                                                                    <div *ngIf="cultureFlag" class='col-print-12'
                                                                        style='margin-bottom: 12px;'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='culture'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div [hidden]='dicomDotor' class='col-print-6'>
                                                                        <div *ngIf="!cultureFlag">
                                                                            <div class='tervys_heading'>Attachment</div>
                                                                            <div>
                                                                                <img alt='report image'
                                                                                    src='{{reportIMG}}'
                                                                                    (click)='clickDocument()'
                                                                                    class='report_img' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr [hidden]='sigSectionFlag'
                                                                style='border: 1px solid #000;'>
                                                            <div class="row" style='padding: 0px 22px;'>
                                                                <table>
                                                                    <tr>
                                                                        <td style="width: 90%;text-align: right;">
                                                                            <div class="row"
                                                                                [hidden]="createdBy == undefined">
                                                                                <div [hidden]="createdBy == undefined"
                                                                                    class="col-12">
                                                                                    <mat-label
                                                                                        class="matlabel"><strong>Lab
                                                                                            technician
                                                                                            :</strong> {{createdBy}}
                                                                                    </mat-label>
                                                                                </div>
                                                                                <div class="col-12"
                                                                                    style="margin-top: 10px;">
                                                                                    <mat-label
                                                                                        class="matlabel"><strong>Signature
                                                                                            :</strong>
                                                                                    </mat-label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div #printnoheadsidebar id="printnoheadsidebar" style="background-image: url({{bgImage}}); background-position: center;">
                        <table style="border:'0';width:100%">
                            <thead>
                                <tr>
                                    <th style="width:100%;height: 100px;"></th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <td width="100%">
                                        <table style="border:'0';width:100%">
                                            <tr>
                                                <td style="width:100%;height: 100px;">&nbsp;</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td width="100%">
                                        <table class="report-container" style="margin-left: 220px;">
                                            <thead class="report-header">
                                                <tr>
                                                    <td style="height:220px;"></td>
                                                </tr>
                                            </thead>
                                            <tfoot class="report-footer">
                                                <tr>
                                                    <td style="height:100px;"></td>
                                                </tr>
                                            </tfoot>
                                            <tbody class="report-content">
                                                <tr>
                                                    <td class="report-content-cell">
                                                        <div class="main">
                                                            <table style="border: none !important;width: 100%;">
                                                                <tr>
                                                                    <td
                                                                        style="width: 500px;text-align: left; border: none !important">
                                                                        <p style="line-height: 1.5;"><strong>Patient id
                                                                                :</strong>{{patientID}}</p>
                                                                        <p style="line-height: 1.5;"><strong>Name
                                                                                :</strong>{{clientAnotherName}}</p>
                                                                        <p style="line-height: 1.5;"><strong>Age
                                                                                :</strong>{{clientAge}}</p>
                                                                        <p style="line-height: 1.5;"><strong>Gender
                                                                                :</strong>{{clientGender}}</p>
                                                                    </td>
                                                                    <td
                                                                        style="width: 300px;vertical-align: top;text-align: left; border: none !important">
                                                                        <p style="line-height: 1.5;"><strong>Date
                                                                                :</strong>{{date}}
                                                                        </p>
                                                                        <p style="line-height: 1.5;"><strong>Time:</strong>{{time}}
                                                                        </p>
                                                                        <p style="line-height: 1.5;"><strong>Referred by:</strong>{{diagReferedBy}}</p>
                                                                        <p style="line-height: 1.5;"><strong>Collected at:</strong>{{sampleCollectedTime}}</p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <div [hidden]='tableReportTypeFlag'>
                                                                <div class='row'>
                                                                    <div class='col-print-12'
                                                                        style="text-align: center;background: #d0eaf7;width:90%;">
                                                                        <mat-label class='matlabel'
                                                                            style="font-size: 17px;"><b>Clinical Laboratory Reports</b></mat-label>
                                                                    </div><br><br>
                                                                    <div class='col-print-12'>
                                                                        <table cellpadding='0' cellspacing='0'
                                                                            style=" width:100%;border-collapse: collapse;">
                                                                            <tr>
                                                                                <th style="width:200px;text-align: left;">Test Name</th>
                                                                                <th style="width:150px;text-align: center;">Reading</th>
                                                                                <th style="width:100px;text-align: center;">Unit</th>
                                                                                <th>Reference value</th>
                                                                            </tr>
                                                                            <tbody *ngFor="let test of printArray">
                                                                                <tr>
                                                                                    <td style='padding: 5px 0 5px 20px;text-align: left;'
                                                                                        colspan="4">
                                                                                        <!-- <b>{{test.test_name}}</b> -->
                                                                                        <b>{{test.category}} - {{test.test_name}}</b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr *ngFor='let subtest of test.sub_test_list'>
                                                                                    <td style='padding-left: 20px;text-align: left;margin-bottom: 15px !important'>
                                                                                        <div
                                                                                            style="margin-bottom: 15px !important;">
                                                                                            <p>{{subtest.sub_test_name}}
                                                                                            </p>
                                                                                            <span *ngIf="subtest.testMethod != undefined" style="display: flex;">(
                                                                                                <div
                                                                                                    [innerHtml]="subtest.testMethod">
                                                                                                </div>)
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style='text-align: center;'>
                                                                                        {{subtest.reading}}
                                                                                    </td>
                                                                                    <td style='text-align: center;'>
                                                                                        {{subtest.unit}}
                                                                                    </td>
                                                                                    <td
                                                                                        style=" text-align: left;padding-left: 20px !important;">
                                                                                        <div class="test_range"
                                                                                            style="margin-bottom: 15px !important;"
                                                                                            [innerHtml]='subtest.test_range | safeHtml'>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div [hidden]='paraReportTypeFlag'>
                                                                <div class='row'
                                                                    style="margin-left: 20px;margin-right: 20px;">
                                                                    <div class='col-print-12' style='margin-top:13px;'>
                                                                        <mat-label class='matlabel'><strong>Report type:</strong>
                                                                            {{reportType}}
                                                                        </mat-label>
                                                                    </div>
                                                                    <div class='col-print-12' style='margin-top:5px;'>
                                                                        <mat-label class='matlabel'><strong>Test name:</strong>
                                                                            {{testName}}
                                                                        </mat-label>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'
                                                                        style='margin-top:5px;'>
                                                                        <p class='tervys_heading clinical'>
                                                                            <b>Sequences</b></p>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='sequence'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'>
                                                                        <p class='tervys_heading clinical'>
                                                                            <b>Impression</b></p>
                                                                    </div>
                                                                    <div *ngIf="!cultureFlag" class='col-print-12'
                                                                        style='margin-bottom: 12px;'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='Impression'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div *ngIf="cultureFlag" class='col-print-12'>
                                                                        <p class='tervys_heading clinical'>Report
                                                                            details</p>
                                                                    </div>
                                                                    <div *ngIf="cultureFlag" class='col-print-12'
                                                                        style='margin-bottom: 12px;'>
                                                                        <quill-view-html id='viewhtml'
                                                                            [content]='culture'>
                                                                        </quill-view-html>
                                                                    </div>
                                                                    <div [hidden]='dicomDotor' class='col-print-6'>
                                                                        <div *ngIf="!cultureFlag">
                                                                            <div class='tervys_heading'>Attachment</div>
                                                                            <div>
                                                                                <img alt='report image' src='{{reportIMG}}' (click)='clickDocument()' class='report_img' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr [hidden]='sigSectionFlag'
                                                                style='border: 1px solid #000;'>
                                                            <div class="row" style='padding: 0px 22px;'>
                                                                <table>
                                                                    <tr>
                                                                        <td style="width: 90%;text-align: right;">
                                                                            <div class="row" [hidden]="createdBy == undefined">
                                                                                <div [hidden]="createdBy == undefined" class="col-12">
                                                                                    <mat-label
                                                                                        class="matlabel"><strong>Lab technician:</strong> {{createdBy}}
                                                                                    </mat-label>
                                                                                </div>
                                                                                <div class="col-12" style="margin-top: 10px;">
                                                                                    <mat-label
                                                                                        class="matlabel"><strong>Signature:</strong>
                                                                                    </mat-label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>