import { Component, OnInit, ViewChild } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Message_data } from '../../../assets/js/Message_data';
declare var $ : any; 
import { Subscription } from 'rxjs';
import { PhysioService } from '../physio/physio.service';
import { PhysioCasesheetService } from './physio-casesheet.service';
import { Physio_Helper } from '../Physio_Helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-casesheet',
  templateUrl: './physio-casesheet.component.html',
  styleUrls: ['./physio-casesheet.component.scss']
})
export class PhysioCasesheetComponent implements OnInit {
  public cards = [];
  public cardViewFlag:boolean = false;
  public userInfo;
  public userList;
  public selectedUser: any;
  public min = "min";
  public max = "max";
  public minMaxFlag:boolean = false;
  @ViewChild("slickModal")
  slickModal!: SlickCarouselComponent;
  activeIndex = -1;
  fadeInOut = false;
  isShow = false;
  customOptions: OwlOptions = {
   autoplay: false,
   autoplaySpeed: 1500,
   loop: true,
   pullDrag: false,
   dots: false,
   navSpeed: 750,
   navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
   responsive: {
     0: {
       items: 4
     },
     400: {
       items: 6
     },
     740: {
       items: 8
     },
     940: {
       items: 10
     }
   },
   nav: true,
  }
  subscription: Subscription;
  public medSaveFlag:boolean = false;
  public physioPersonal: any;
  public physioClinical:any;
  public physioNeuro: any;
  public physioDiagnosis: any;
  public physioPediatric:any;
  public dietMrngList: any;
  public breakFastList: any;
  public midDayList: any;
  public lunchList: any;
  public eveningList: any;
  public dinnerList: any;
  public nightList: any;
  public personalFlag: boolean;
  public clinicalFlag: boolean;
  public diagnosisFlag: boolean;

  constructor(public physioservice:PhysioService,public messageservice:PhysioCasesheetService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) {
    this.messageservice.sendCasesheetMessage("min");
    this.cards = ["History","Medical Prescription","Habitual History","Clinical Exam","Neurological","Treatment Plan","Diet Plan","Appointments"];
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userList = Physio_Helper.getClient_Info();
  }

  getRecord(id: any) {
    this.selectedUser = [];
    const res = this.userList.filter((d: { id: string; }) => d.id === id);
    this.selectedUser = res[0];
  }

  openCard(id,card,index,card_id,flag){
    this.minMaxFlag = flag;
    if(this.minMaxFlag == true){
      this.messageservice.sendCasesheetMessage("max");
      this.cardViewFlag = true;
      if( card == 'Medical Prescription'){
        this.medSaveFlag = true;
      }else{
        this.medSaveFlag = false;
      }
      for(var i=0; i< this.cards.length; i++){
        document.getElementById("cardindex_"+i).className = "col-md-12 m-auto user-view";
        if(card == this.cards[i]){
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "auto";
        }
        else{
          document.getElementById("card_"+i).style.display = "none";
        }
      }
    }
    if(this.minMaxFlag == false){
      this.messageservice.sendCasesheetMessage("min");
      this.cardViewFlag = false;
      this.medSaveFlag = false;
      for(var i=0; i< this.cards.length; i++){
        document.getElementById("cardindex_"+i).className = "col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4";
        if(card == this.cards[i]){
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "290px";
        }
        else{
          document.getElementById("card_"+i).style.display = "flex";
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "290px";
        }
      }
    }
  }

  viewRecord(card, index: any,card_id) {
      this.messageservice.sendCasesheetMessage("max");
      this.cardViewFlag = true;
      if( card == 'Medical Prescription'){
        this.medSaveFlag = true;
      }else{
        this.medSaveFlag = false;
      }
      for(var i=0; i< this.cards.length; i++){
        document.getElementById("cardindex_"+i).className = "col-md-12 m-auto user-view";
        if(card == this.cards[i]){
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "auto";
          document.getElementById("card_"+i).style.display = "flex";
        }
        else{
          document.getElementById("card_"+i).style.display = "none";
        }
      }
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  openRx(){
    $('#noRxModal').modal('show');
  }

  closeAppointment(e){
    if(e == "yes"){
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/clsapp/', 
        JSON.stringify({
          app_id:this.userList.app_id, 
          mobile: this.userList.mobile,
          type:"doctor"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
              this.toastr.success(Message_data.apptClosedSuccess);
              this.physioservice.sendMessage("appointment");
              } else {
                this.toastr.error(Message_data.unableToCloseAppt);
              }
            }
          }
        );
    }else{
    }
    $('#noRxModal').modal('hide');
  }
  
  save(){
    var url = null
    this.personalFlag =true;
    this.clinicalFlag =true;
    this.diagnosisFlag =true;
    var habitual = Physio_Helper.getHabitual();
    this.physioPersonal = Physio_Helper.getDiabHist();
    this.physioClinical = Physio_Helper.getPhysioClinical();
    this.physioNeuro = Physio_Helper.getPhysioNeuro();
    this.physioDiagnosis = Physio_Helper.getDiabTreat();
    var get_sga_data = Physio_Helper.getSga();
    var get_diet_data = Physio_Helper.getDiet();
    var takingmed = undefined;
    var sfactor = undefined;
    var hdisorder = undefined;
    var hospvisit = undefined;
    var murdet = undefined;
    var painfactor = undefined;
    var breathfactor = undefined;
    for (var key in this.physioPersonal) {
      if (key == "case_hist_id" && this.physioPersonal[key] != undefined) {
        url = "1";
      } else if(key == "main_concern" && (this.physioPersonal[key] == undefined || this.physioPersonal[key] == null)){
        this.personalFlag = false;
      } else if(key == "height" && (this.physioPersonal[key] == undefined || this.physioPersonal[key] == "")){
        this.personalFlag = false;
      } else if(key == "weight" && (this.physioPersonal[key] == undefined || this.physioPersonal[key] =="")){
        this.personalFlag = false; 
      } 
    }
    if(this.physioClinical != undefined){
      for (var key in this.physioClinical) {
        if (key == "pain"){
          painfactor = this.physioClinical[key];
        } else if (painfactor == "Yes" ){
          if (key == "location" && this.physioClinical[key] == undefined ) {
            this.clinicalFlag = false;
          } else if (key == "character" && this.physioClinical[key] == undefined ) {
            this.clinicalFlag = false;
          } else if (key == "radiation" && this.physioClinical[key] == undefined ) {
            this.clinicalFlag = false;
          } else if (key == "duration" && this.physioClinical[key] == undefined ) {
            this.clinicalFlag = false;
          } else if (key == "aggreviate" && this.physioClinical[key] == undefined ) {
            this.clinicalFlag = false;
          } else if (key == "relieve" && this.physioClinical[key] == undefined ) {
            this.clinicalFlag = false;
          } else if (key == "assosymp" && this.physioClinical[key] == undefined ) {
            this.clinicalFlag = false;
          } else if (key == "intensity" && this.physioClinical[key] == undefined ) {
            this.clinicalFlag = false;
          } 
        }   
      }
    } 
    if(this.physioDiagnosis != undefined){ 
       if(this.physioDiagnosis["prognosis"] == undefined){
        this.diagnosisFlag = false;
      } else if(this.physioDiagnosis["investigation"] == undefined){
        this.diagnosisFlag = false;
      } else {
        this.diagnosisFlag = true;
      }
    }   
    if(this.personalFlag == false){
      this.toastr.error(Message_data.personalCardMandatory);
    } else if(this.clinicalFlag == false){
      this.toastr.error(Message_data.clinicalCardMandatory);
    } else if(this.diagnosisFlag == false){
      this.toastr.error(Message_data.treatmentCardMandatory);
    }
    var person_clinical = $.extend(true, this.physioPersonal, this.physioClinical, habitual);
    var per_clinic_neuro = $.extend(true, person_clinical, this.physioNeuro);
    var per_clinic_neuro_ped = $.extend(true, per_clinic_neuro, this.physioPediatric);
    var final_array = $.extend(true, per_clinic_neuro_ped, this.physioDiagnosis);
    if (url != null) {
      url = ipaddress.getIp.toString() + 'physio/ucasehist';
    } else {
      url = ipaddress.getIp.toString() + 'physio/scasehist';
    }
    var diet_plan_url = "lifestyle/sdplan";
    for (var key in get_diet_data) {
      if (key == "dplan_morning") {
        this.dietMrngList = get_diet_data[key];
      }
      if (key == "dplan_breakfast") {
        this.breakFastList = get_diet_data[key]
      }
      if (key == "dplan_midday") {
        this.midDayList = get_diet_data[key];
      }
      if (key == "dplan_lunch") {
        this.lunchList = get_diet_data[key];
      }
      if (key == "dplan_evening") {
        this.eveningList = get_diet_data[key];
      }
      if (key == "dplan_dinner") {
        this.dinnerList = get_diet_data[key];
      }
      if (key == "dplan_night") {
        this.nightList = get_diet_data[key];
      }
      if (key == "diet_plan" && get_diet_data[key] == "1") {
        diet_plan_url = "lifestyle/updplan";
      }
    }
    if(this.personalFlag == true && this.clinicalFlag == true && this.diagnosisFlag == true){
      var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(url, JSON.stringify(final_array),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj["key"] != null && obj["key"] == "1") {
                Physio_Helper.setDiabHist(null);
                Physio_Helper.setPhysioClinical(null);
                Physio_Helper.setPhysioNeuro(null);
                Physio_Helper.setDiabTreat(null);
                var dietplan = null;
                if (get_sga_data != undefined) {
                  dietplan = $.extend(true, get_sga_data, get_diet_data);
                } else {
                  dietplan = get_diet_data;
                }
                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                this.http.post(ipaddress.getIp.toString() + diet_plan_url, dietplan,
                  { headers: headers })
                  .subscribe(
                    response => {
                      var diet_obj = JSON.parse(response["_body"]);
                      this.toastr.success(Message_data.caseHistSavedSuccess);
                      this.physioservice.sendMessage("appointment");
                    },
                    error => {
                      this.toastr.error(Message_data.getNetworkMessage());
                    }
                  )
              } else {
                this.toastr.error(Message_data.caseHistNotSaved);
              }
            });
    }
  }
}
