<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Outsource tests</h5>
                    </div>
                    <div class="headerButtons">
                        <img class="saveimgbtn_inpatinfo" (click)="saveTestDetails()"
                            src="../../../assets/ui_icons/buttons/save_button.svg" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="nodata" *ngIf="test_flag">No data found</div>
                <div class="row">
                    <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div class="row" style="margin-bottom: 20px;margin-left: 5px;">
                            {{oursource.centre_name}}
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-xl-9 col-lg-9 col-md-6">
                                <div class="row">
                                    <div *ngFor="let testtype of all_tests"
                                        class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                                        <div class="diagnosis_testgrid testtype">
                                            <p class="titletest"><strong>{{testtype.test_type_name}}</strong></p>
                                            <div class="diag_test_content_cover">
                                                <div *ngFor="let subtest of testtype.sub_tests" id="testlbel">
                                                    <table>
                                                        <tr>
                                                            <td >
                                                                <span *ngIf="subtest.checked == true" class="check_img">
                                                                    <img (click)="ChangeTestType(false,subtest.test_id,subtest.test_name,testtype.test_type_name,subtest)"
                                                                        src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                                                                </span>
            
                                                                <span *ngIf="subtest.checked == false" class="check_img">
                                                                    <img (click)="ChangeTestType(true,subtest.test_id,subtest.test_name,testtype.test_type_name,subtest)"
                                                                        src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                                                                </span>
                                                                {{subtest.test_name}}
                                                            </td>
                                                            <td ><input class="ipcss widthappt" matInput type="text" maxlength="7" [(ngModel)]="subtest.price" /></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>