import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { Helper_Class } from 'src/app/helper_class';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {

  public header_txt;
  public agree:boolean = false;
  public result;

  constructor(public http:Http, public toastr:ToastrService ,public el: ElementRef,public dialogRef: MatDialogRef<TermsConditionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {   
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  submit_tandm(){
    if (this.agree == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "usercontroller/utac", 
      JSON.stringify({ 
        user_type: "doctor",
        user_id:Helper_Class.getInfo().user_id
      }), 
      { headers: headers }).subscribe(
        data => {
          this.result = data.json();
          if(this.result["key"] == "1"){
            this.toastr.success(Message_data.congrtsAcceptTerms,'',{timeOut: 3000});
            this.dialogRef.close(this.result);
          }
        })
    }else{
      this.toastr.error(Message_data.termsNconditions);
    }
  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
