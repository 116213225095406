 import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../helper_class';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharma-stock-status-admin',
  templateUrl: './pharma-stock-status-admin.component.html',
  styleUrls: ['./pharma-stock-status-admin.component.scss']
})
export class PharmaStockStatusAdminComponent implements OnInit {
  public locationArray = [];
  public loadLocationList = [];
  public pharmacyFacID: any;
  public location: any;
  public locationIP = null;
  public stock: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  dtOptions: DataTables.Settings = {};
  isDisabled: boolean = false;
  constructor(public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
       // { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search supplier or batch no"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.pharmaLocation();
  }
  pharmaLocation() {
    var send_data;
    if (Helper_Class.getIsAdmin() != undefined) {
      send_data = JSON.stringify({
        hptl_clinic_id: this.pharmacyID,
      });
    } else {
      send_data = JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/", send_data, { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (data.json().pharma_locations != null) {
          this.locationArray = data.json().pharma_locations;
          for (var i = 0; i < this.locationArray.length; i++) {
            this.loadLocationList.push({
              location_id: this.locationArray[i].pharma_facility_id + " - " + this.locationArray[i].location,
              pharma_facility_id: this.locationArray[i].pharma_facility_id,
            });
            if (i == 0) {
              this.get_stock(this.locationArray[i].pharma_facility_id);
            }
          }
          if (this.loadLocationList.length != 0) {
            this.location = this.loadLocationList[0].location_id;
          }
        }
      },
      error => { });
  }
  get_stock(pharmacy_fac_id) {
    var send_data;
    if (Helper_Class.getIsAdmin() != undefined) {
      send_data = JSON.stringify({
        hptl_clinic_id: this.pharmacyID,
        is_admin: "1"
      });
    } else {
      send_data = JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID,
        is_admin: "1"
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpstock/", send_data,
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if (obj.key != 0) {
            if (obj.stock_onhand != null) {
              this.stock = obj.stock_onhand;
            }
          }
        },
        error => { });
  }
  save_data() {
    if (this.isDisabled == false) {
      this.isDisabled = true;
      var update = this.stock;
      var send_data = JSON.stringify({
        items: this.stock,
      });
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/spstock/", send_data,
        { headers: headers }).subscribe(
          data => {
            var obj = data.json();
            if (obj.key != 0) {
              this.toastr.success(Message_data.stockUpdSuccess);
            }
            this.isDisabled = false;
          },
          error => { });
    } else {
      this.toastr.error(Message_data.plsWaitUpdStock);
    }
  }
}
