<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Access rights</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                            (click)="saveAccessRights()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">User type<br>
                            <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="usertype" (selectionChange)="getUserList()">
                                <mat-option *ngFor="let user of usertypelist"
                                    value={{user.type_id}}>{{user.type_desc}}</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">{{usertypedesc}}<br>
                            <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="userid" (selectionChange)="getModules()">
                                <mat-option *ngFor="let user of userList"
                                    value={{user.user_id}}>{{user.name}}</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                </div>

                <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
                    <div id="pharma_tbl_overlay" class="dig_table_overflow table-responsive" style="height: 350px;">
                        <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px;">
                            <thead>
                                <tr>
                                    <th style="width: 45px;">SNo</th>
                                    <th style="width: 200px;">Module</th>
                                    <th style="width: 100px;">Create</th>
                                    <th style="width: 100px;">Edit</th>
                                    <th style="width: 100px;">View</th>
                                    <th style="width: 100px;">Delete</th>
                                    <th style="width: 100px;">Print</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let module of modulelist;let i = index" class="tdvalues">
                                    <th>{{i + 1}} </th>
                                    <td style="text-align: left;">{{module.module_desc}}</td>
                                    <td><mat-checkbox color="primary" [(ngModel)]="module.create"></mat-checkbox></td>
                                    <td><mat-checkbox color="primary" [(ngModel)]="module.edit"></mat-checkbox></td>
                                    <td><mat-checkbox color="primary" [(ngModel)]="module.view"></mat-checkbox></td>
                                    <td><mat-checkbox color="primary" [(ngModel)]="module.delete"></mat-checkbox></td>
                                    <td><mat-checkbox color="primary" [(ngModel)]="module.print"></mat-checkbox></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>