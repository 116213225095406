import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import moment from 'moment';

@Component({
  selector: 'app-access-rights',
  templateUrl: './access-rights.component.html',
  styleUrls: ['./access-rights.component.scss']
})
export class AccessRightsComponent implements OnInit {
  public usertype:string;
  public usertypelist:any=[];
  private hptl_clinic_id: string;
  private diag_centre_id:string;
  private pharma_id:string;
  public userList:any=[];
  public userid:string;
  public usertypedesc:string;
  public modulelist:any=[];
  private tempModules:any=[];
  private packageid:string;

  constructor(public gservice: CommonDataService, public http: HttpClient, public router: Router, public toastr: ToastrService) { 

  }

  ngOnInit(): void {
    this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    this.diag_centre_id = Helper_Class.getInfo().diag_centre_id;
    this.pharma_id = Helper_Class.getInfo().pharma_id;
    this.packageid = Helper_Class.getInfo().hptl_package;
    
    if(Helper_Class.getDepartments() == undefined){
      this. getUserTypes();
    } else {
      this.usertypelist = Helper_Class.getUserTypes();
    }
  }

  getUserTypes(){
    var send_data ={
      hptl_clinic_id: this.hptl_clinic_id,
      package_id:this.packageid,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/gubh",send_data, { headers: headers }).subscribe(
      data => {
        var obj =JSON.parse(JSON.stringify(data)); 
        this.usertypelist = [];
        
        if (obj.user_types != undefined && obj.user_types.length != 0) {
          this.usertypelist = obj.user_types;
          this.usertype=this.usertypelist[0].type_id;   
          this.tempModules = obj.modules;                          
        }
        
        this.getUserList();
      }, error => {
        this.toastr.error(Message_data.unableToFetchUsrtype);
      });
  }

  getUserList(){
    this.modulelist=[];
    if(this.tempModules.length !=0){
      for(var i=0;i<this.tempModules.length;i++){
        if(this.tempModules[i].user_type_id == this.usertype){
          this.modulelist.push({
            module_id:this.tempModules[i].module_id,
            module_desc:this.tempModules[i].module_desc,
            create:false,
            edit:false,
            view:false,
            delete:false,
            print:false,
          })
        }
      }
    }

    for(var i=0;i<this.usertypelist.length;i++){
      if(this.usertype == this.usertypelist[i].type_id){
        this.usertypedesc=this.usertypelist[i].type_desc+"s"
      }
    }
    var center_id;
    if(this.usertype =="4"){
      center_id = this.diag_centre_id;

    } else if(this.usertype =="5"){
      center_id = this.pharma_id;

    } else {
      center_id = this.hptl_clinic_id;
    }
    var senddata = {
      center_id: center_id,
      type: this.usertype
    }

    var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/guth', senddata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            // this.userList=[];
            // this.modulelist=[];
            // if(obj.modules != null){
            //   for(var j=0;j<obj.modules.length;j++){
            //     this.modulelist.push({
            //       module_id:obj.modules[j].module_id,
            //       module_desc:obj.modules[j].description,
            //       create:false,
            //       edit:false,
            //       view:false,
            //       delete:false,
            //       print:false,
            //     })
            //   }
            //   Helper_Class.setAccessModules(this.modulelist)
            // }
            
            if (obj.users != null) {
              this.userList = [];
              this.userList.push({
                user_id: "All",
                name: "All",
              })

              for (var i = 0; i < obj.users.length; i++) {
                var user_name;
                if (obj.users[i].middle_name != undefined || obj.users[i].middle_name != null) {
                  user_name = obj.users[i].first_name + " " + obj.users[i].middle_name + " " + obj.users[i].last_name;
                } else {
                  user_name = obj.users[i].first_name + " " + obj.users[i].last_name;
                }

                this.userList.push({
                  user_id: obj.users[i].user_id,
                  name: user_name,
                })
              }
              this.userid=this.userList[0].user_id;
              this.getModules();
            }
            
          }, error => {
            this.toastr.error(Message_data.unableToFetchDocDet);
          });
  }

  getModules(){
    var center_id;
    if(this.usertype =="4"){
      center_id = this.diag_centre_id;

    } else if(this.usertype =="5"){
      center_id = this.pharma_id;

    } else {
      center_id = this.hptl_clinic_id;
    }

    var send_data={
      user_type:this.usertype,
      center_id:center_id,
      user_id:this.userid
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gumh', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response))           ;
          // this.modulelist=[];
          // if(obj.modules != null){
          //   for(var j=0;j<obj.modules.length;j++){
          //     this.modulelist.push({
          //       module_id:obj.modules[j].module_id,
          //       module_desc:obj.modules[j].description,
          //       create:false,
          //       edit:false,
          //       view:false,
          //       delete:false,
          //       print:false,
          //     })
          //   }
          // }
          
          if(obj.usermodules != null){
            for(var i=0;i<this.modulelist.length;i++){
              for(var j=0;j<obj.usermodules.length;j++){
                if(this.modulelist[i].module_id == obj.usermodules[j].module_id){
                  this.modulelist[i].create =obj.usermodules[j].create;
                  this.modulelist[i].edit =obj.usermodules[j].edit;
                  this.modulelist[i].view =obj.usermodules[j].view;
                  this.modulelist[i].delete =obj.usermodules[j].delete;
                  this.modulelist[i].print =obj.usermodules[j].print;
                }
              }
            }
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
  }

  saveAccessRights(){
    var center_id;
    var center_id;
    if(this.usertype =="4"){
      center_id = this.diag_centre_id;

    } else if(this.usertype =="5"){
      center_id = this.pharma_id;

    } else {
      center_id = this.hptl_clinic_id;
    }

    var users=[];
    if(this.userid=="All"){
      for(var i=0;i<this.userList.length;i++){
        users.push(this.userList[i].user_id);
      }
    } else {
      users.push(this.userid)
    }
    var send_data={
      user_type:this.usertype,
      center_id:center_id,
      users:users,
      modules:this.modulelist,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/sumth', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response))           ;
          if (obj.key != null && obj.key =="1") {
            this.toastr.success(Message_data.saveSuccess);
          }
          
        }, error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
}
