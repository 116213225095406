<div *ngIf="pediaImmFlag == 'min'">
  <mat-label class="matlabel">Age group<br>
    <select disableOptionCentering class="ipcss" [(ngModel)]="Age_group" (change)="send_data()">
      <option disabled>Select</option>
      <option *ngFor="let vaccine of Age_grouparray" value="vaccine.description">{{vaccine.description}}
      </option>
    </select>
  </mat-label>
  <mat-label *ngIf="mor_lable" class="matlabel">Vaccine name
    <input type="text" class="ipcss" matInput required [(ngModel)]="Vaccine_name" (blur)="send_data()"/>
  </mat-label>
</div>

<div class="d-block d-sm-block d-md-none d-lg-none  d-xl-none">
  <p class="nodata">Please View this screen in Bigger Screen</p>
</div>
<div class="d-none d-sm-none d-md-block d-lg-block  d-xl-block">
  <div *ngIf="pediaImmFlag == 'max'">
    <!-- <div >
      <a >
        <span class="cardsave" style="right: 60px;">
            <img src="../../../assets/img/printer.svg" style="width:25px" />
        </span> 
      </a>
    </div> -->
    <div class="table-responsive" style="border: 1px solid #51b0e7;">
      <table id="immun_tbl" class="table table-hover table-dynamic" *ngIf="Main_Vaccine_Data != undefined">
        <thead>
          <tr>
            <th>Age</th>
            <th>Vaccine</th>
            <th>Due date</th>
            <th>Given date</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let key of keys">
            <td >{{key}}</td>
            <td >
              <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]">
                <div class="child_vaccine_td" style="text-align:left;">
                  {{key1.vaccine_name}}
                </div>
              </div>
            </td>
            <td >
              <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]; let i=index">
                <div class="child_vaccine_td">
                  <span *ngIf="key1.due_flag == null">
                    <input type="date" [disabled]="client" class="ipcss_date" (change)="calendar_click1(key,key1.due_date1,i,'due')" [(ngModel)]="key1.due_date1" (blur)="send_data()">
                  </span>
                  <span *ngIf="key1.due_flag!=null">
                    <input class="ipcss" matInput disabled [(ngModel)]="key1.due_date" (blur)="send_data()">
                  </span>
                </div>
              </div>
            </td>
            <td >
              <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]; let i=index">
                <div class="child_vaccine_td">
                  <span *ngIf="key1.given_flag==null">
                    <input type="date" class="ipcss_date" [disabled]="client"
                      (change)="calendar_click(key,i,'given',key1.given_date1,key1.ped_vaccine_age_id,key1.due_date)" [(ngModel)]="key1.given_date1" (blur)="send_data()">
                  </span>
                  <span *ngIf="key1.given_flag!=null">
                    <input class="ipcss " matInput disabled [(ngModel)]="key1.given_date" (blur)="send_data()"
                      ng-model-options="{ allowInvalid: true}">
                  </span>
                </div>
              </div>
            </td>
            <td style="width:30%">
              <span *ngIf="key1.given_flag==null || key1.given_flag==undefined">
                <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]; let i=index">
                  <div class="child_vaccine_td">
                    <input class="ipcss" [disabled]="client" matInput (click)="remarkclick(key,i)" (blur)="send_data()"
                    [(ngModel)]="key1.remarks" rows="1" maxlength="150">
                  </div>
                </div>
              </span>
              <span *ngIf="key1.given_flag!=null || key1.given_flag !=undefined">
                <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]; let i=index">
                  <div class="child_vaccine_td">
                    <input class="ipcss" [disabled]="client" matInput disabled [(ngModel)]="key1.remarks" rows="1" maxlength="150" (blur)="send_data()"
                    ng-model-options="{ allowInvalid: true}">
                  </div>
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <a [hidden]="client" (click)="Vaccine_add()"><img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo" style="float: right;"/></a>
    </div>
  </div>
  <br><br>
</div>
<div [hidden]="true">
  <div #printbanner id="printbanner" style="background-image: url({{bg_image}}); background-position: center;">
    <div *ngIf="Main_Vaccine_Data != undefined">
      <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
          <tr>
            <td>
                <img alt="image" src={{hptl_pres_logo}} [style]="preslogo_style">
            </td>
          </tr>
      </table>
      <br />
      <table id="immun_tbl" class="table table-hover table-dynamic" *ngIf="Main_Vaccine_Data != undefined">
        <thead>
          <tr>
            <th>Age</th>
            <th>Vaccine</th>
            <th>Due date</th>
            <th>Given date</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let key of keys">
            <td >{{key}}</td>
            <td >
              <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]">
                <div class="child_vaccine_td" style="text-align:left;">
                  {{key1.vaccine_name}}
                </div>
              </div>
            </td>
            <td >
              <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]; let i=index">
                <div class="child_vaccine_td">
                  <!-- <span *ngIf="key1.due_flag == null">
                    <input type="date" [disabled]="client" class="ipcss_date" (change)="calendar_click1(key,key1.due_date1,i,'due')" [(ngModel)]="key1.due_date1" (blur)="send_data()">
                  </span> -->
                  {{key1.due_date}}
                  <!-- <span *ngIf="key1.due_flag!=null">
                    <input class="ipcss" matInput disabled [(ngModel)]="key1.due_date" (blur)="send_data()">
                  </span> -->
                </div>
              </div>
            </td>
            <td >
              <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]; let i=index">
                <div class="child_vaccine_td">
                  <!-- <span *ngIf="key1.given_flag==null">
                    <input type="date" class="ipcss_date" [disabled]="client"
                      (change)="calendar_click(key,i,'given',key1.given_date1,key1.ped_vaccine_age_id,key1.due_date)" [(ngModel)]="key1.given_date1" (blur)="send_data()">
                  </span> -->
                  <span *ngIf="key1.given_flag!=null">
                    {{key1.given_date}}
                    <!-- <input class="ipcss " matInput disabled [(ngModel)]="key1.given_date" (blur)="send_data()"
                      ng-model-options="{ allowInvalid: true}"> -->
                  </span>
                </div>
              </div>
            </td>
            <td style="width:30%">
              <!-- <span *ngIf="key1.given_flag==null || key1.given_flag==undefined">
                <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]; let i=index">
                  <div class="child_vaccine_td">
                    <span>{{key1.remarks}}</span>
                    <input class="ipcss" [disabled]="client" matInput (click)="remarkclick(key,i)" (blur)="send_data()"
                    [(ngModel)]="key1.remarks" rows="1" maxlength="150">
                  </div>
                </div>
              </span> -->
              <span *ngIf="key1.given_flag!=null || key1.given_flag !=undefined">
                <div class="vaccine_td" *ngFor="let key1 of Main_Vaccine_Data[key]; let i=index">
                  <div class="child_vaccine_td">
                    <span>{{key1.remarks}}</span>
                    <!-- <input class="ipcss" [disabled]="client" matInput disabled [(ngModel)]="key1.remarks" rows="1" maxlength="150" (blur)="send_data()"
                    ng-model-options="{ allowInvalid: true}"> -->
                  </div>
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
 </div>
</div>