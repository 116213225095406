<div *ngIf="familyHistFlag=='min'">
   <div class="container">
      <div class="row">
         <div class="col-7 margin-bottomvalue">
            <mat-label class="matlabel ">Diabetics</mat-label>
         </div>
         <div class="col-2  margin-bottomvalue">
            <mat-button-toggle id="toggid1" class="togglebtn" [(ngModel)]="togg_diab1" ngDefaultControl
               [checked]="togg_diab1" (change)="changediab($event.source.checked); send_data()" [disabled]="client">
               <span class="familytab" *ngIf="!togg_diab1"><img class="family_toggle_img"
                     src="../../../assets/ui_icons/cross_mark.svg"></span>
               <span class="familytab" *ngIf="togg_diab1"><img class="family_toggle_img"
                     src="../../../assets/ui_icons/tick_mark.svg"></span>
            </mat-button-toggle>
         </div>

         <div class="col-7">
            <mat-label class="matlabel ">Heart attack</mat-label>
         </div>
         <div class="col-2 margin-bottomvalue">
            <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="togg_heart_att1" ngDefaultControl
               [checked]="togg_heart_att1" (change)="changeheart($event.source.checked); send_data()" [disabled]="client">
               <span class="familytab" *ngIf="!togg_heart_att1"><img class="family_toggle_img"
                     src="../../../assets/ui_icons/cross_mark.svg"></span>
               <span class="familytab" *ngIf="togg_heart_att1"><img class="family_toggle_img"
                     src="../../../assets/ui_icons/tick_mark.svg"></span>
            </mat-button-toggle>
         </div>

         <div class="col-7 margin-bottomvalue">
            <mat-label class="matlabel ">Stroke</mat-label>
         </div>
         <div class="col-2 margin-bottomvalue">
            <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="stroke1" ngDefaultControl
               [checked]="stroke1" (change)="changestroke($event.source.checked); send_data()" [disabled]="client">
               <span class="familytab" *ngIf="!stroke1"><img class="family_toggle_img"
                     src="../../../assets/ui_icons/cross_mark.svg"></span>
               <span class="familytab" *ngIf="stroke1"><img class="family_toggle_img"
                     src="../../../assets/ui_icons/tick_mark.svg"></span>
            </mat-button-toggle>
         </div>
         <div class="col-7 margin-bottomvalue">
            <mat-label class="matlabel ">Arterial</mat-label>
         </div>
         <div class="col-2">
            <mat-button-toggle id="toggid4" class="togglebtn" [(ngModel)]="togg_arter1" ngDefaultControl
               [checked]="togg_arter1" (change)="changearter($event.source.checked); send_data()" [disabled]="client">
               <span class="familytab" *ngIf="!togg_arter1"><img class="family_toggle_img"
                     src="../../../assets/ui_icons/cross_mark.svg"></span>
               <span class="familytab" *ngIf="togg_arter1"><img class="family_toggle_img"
                     src="../../../assets/ui_icons/tick_mark.svg"></span>
            </mat-button-toggle>
         </div>
      </div>
   </div>
</div>

<div *ngIf="familyHistFlag=='max'">
   <div class="personaldetails">
      <div class="row">
         <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Relation<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="relation" [compareWith]="compareById"
                (change)="RelationChange()">
                <option *ngFor="let data of relation_datas" [ngValue]="data"
                  [Selected]="data === relation">{{data.relation_name}}</option>
              </select>
            </mat-label>
          </div>
      </div>

      <div class="row">
         <div class="col-4">
            <div class="cover_div">
               <div class="content_cover">
                  <div class="row" style="justify-content: center;">
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel ">Diabetics</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid1" class="togglebtn" [(ngModel)]="togg_diab1" ngDefaultControl
                           [checked]="togg_diab1" (change)="changediab($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_diab1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_diab1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
      
                     <div class="col-7">
                        <mat-label class="matlabel ">Heart attack</mat-label>
                     </div>
                     <div class="col-2 margin-bottomvalue">
                        <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="togg_heart_att1" ngDefaultControl
                           [checked]="togg_heart_att1" (change)="changeheart($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_heart_att1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_heart_att1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
      
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel ">Stroke</mat-label>
                     </div>
                     <div class="col-2 margin-bottomvalue">
                        <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="stroke1" ngDefaultControl
                           [checked]="stroke1" (change)="changestroke($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!stroke1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="stroke1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel ">Arterial</mat-label>
                     </div>
                     <div class="col-2">
                        <mat-button-toggle id="toggid4" class="togglebtn" [(ngModel)]="togg_arter1" ngDefaultControl
                           [checked]="togg_arter1" (change)="changearter($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_arter1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_arter1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-4" style="position: relative;left: -18px;">
            <div class="cover_div">
               <div class="content_cover">
                  <div class="row" style="justify-content: center;">
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel ">Endocrine</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid5" class="togglebtn" [(ngModel)]="togg_endo1" ngDefaultControl
                           [checked]="togg_endo1" (change)="changeendro($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_endo1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_endo1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
      
                     <div class="col-7">
                        <mat-label class="matlabel ">Autoimmune</mat-label>
                     </div>
                     <div class="col-2 margin-bottomvalue">
                        <mat-button-toggle id="toggid6" class="togglebtn" [(ngModel)]="togg_auto1" ngDefaultControl
                           [checked]="togg_auto1" (change)="changeautoimmune($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_auto1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_auto1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
      
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel ">Hypertension</mat-label>
                     </div>
                     <div class="col-2 margin-bottomvalue">
                        <mat-button-toggle id="toggid7" class="togglebtn" [(ngModel)]="togg_hyper1" ngDefaultControl
                           [checked]="togg_hyper1" (change)="changehypertension($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_hyper1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_hyper1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
      
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel ">Hemophilia</mat-label>
                     </div>
                     <div class="col-2 margin-bottomvalue">
                        <mat-button-toggle id="toggid8" class="togglebtn" [(ngModel)]="togg_hemo1" ngDefaultControl
                           [checked]="togg_hemo1" (change)="changehemophilia($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_hemo1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_hemo1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-4" style="position: relative;left: -36px;">
            <div class="cover_div">
               <div class="content_cover">
                  <div class="row" style="justify-content: center;">
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel ">Tuberculosis</mat-label>
                     </div>
                     <div class="col-2 margin-bottomvalue">
                        <mat-button-toggle id="toggid9" class="togglebtn" [(ngModel)]="togg_tuber1" ngDefaultControl
                           [checked]="togg_tuber1" (change)="changetuber($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_tuber1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_tuber1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
      
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel ">HIV</mat-label>
                     </div>
                     <div class="col-2 margin-bottomvalue">
                        <mat-button-toggle id="toggid10" class="togglebtn" [(ngModel)]="togg_hiv1" ngDefaultControl
                           [checked]="togg_hiv1" (change)="changehiv($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_hiv1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_hiv1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
      
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Hepatitis</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid11" class="togglebtn" [(ngModel)]="togg_hepat1" ngDefaultControl
                           [checked]="togg_hepat1" (change)="changehepatitis($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_hepat1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_hepat1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
      
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Glaucoma</mat-label>
                     </div>
                     <div class="col-2 margin-bottomvalue">
                        <mat-button-toggle id="toggid12" class="togglebtn" [(ngModel)]="togg_gla1" ngDefaultControl
                           [checked]="togg_gla1" (change)="changegla($event.source.checked); send_data()" [disabled]="client">
                           <span class="familytab" *ngIf="!togg_gla1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="togg_gla1"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="row" style="margin-top: 5px;">
         <div class="col-12 col-sm-12 col-lg-4 col-md-4 col-xl-4">
            <mat-label class="matlabel">Others<br>
               <textarea style="height: 170px  !important;" class="habitstawidth ipcss" [(ngModel)]="family_other" (blur)="send_data()">
               </textarea>
            </mat-label>
         </div>
         <div class="col-1 my-auto" style="text-align: center;padding:0;">
            <img src="../../../assets/img/pagination_next.png" class="arrow" (click)="addFamilyDetails()">
         </div>

         <div class="col-12 col-sm-12 col-lg-7 col-md-7 col-xl-7">
            <div class="dig_table_overflow" style="width: 93%;height: 170px  !important;margin-top: 22px;">
               <div class="table-responsive">
                   <table class="table table-hover table-dynamic" id="famtbl">
                       <thead>
                           <tr>
                               <th style="width: 5%;">Relations</th>
                               <th>Comorbidities</th>
                               <th></th>
                       </thead>
                       <tbody>
                           <tr *ngFor="let relDet of family_hist_data">
                               <td style="font-size: 12px;text-align: left !important;">{{relDet.relation_name}}</td>
                               <td style="font-size: 12px;">
                                 <span *ngIf="relDet.diabetics == 'yes'">Diabetics,</span>
                                 <span *ngIf="relDet.heart_attack == 'yes'">Heart attack,</span>
                                 <span *ngIf="relDet.stroke == 'yes'">Stroke,</span>
                                 <span *ngIf="relDet.arterial == 'yes'">Arterial,</span>

                                 <span *ngIf="relDet.endocrine == 'yes'">Endocrine,</span>
                                 <span *ngIf="relDet.autoimmune == 'yes'">Autoimmune,</span>
                                 <span *ngIf="relDet.hypertension == 'yes'">Hypertension,</span>
                                 <span *ngIf="relDet.tuberculosis == 'yes'">Tuberculosis,</span>

                                 <span *ngIf="relDet.hemophilia == 'yes'">Hemophilia,</span>
                                 <span *ngIf="relDet.hiv == 'yes'">HIV,</span>
                                 <span *ngIf="relDet.hepatitis == 'yes'">Hepatitis,</span>
                                 <span *ngIf="relDet.glaucoma == 'yes'">Glaucoma,</span>
                                 <span *ngIf="relDet.others != null">{{relDet.others}}</span>
                              </td>
                              <td>
                                 <img src="../../../assets/ui_icons/trash_icon.svg" (click)="deletList(family_hist_data)" style="height: 25px; width: 25px;" alt="">
                              </td>
                           </tr>
                       </tbody>
                   </table>
               </div>
           </div>
         </div>


         <!-- <div class=" col-12 "  *ngIf="!client">
            <img src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo"
                  (click)="history()" style="float: right;margin-right: 33px;"/>
         </div> -->
      </div>

      <!-- <div class="row">
         <div class="col-12">
            <img style="float: right;margin-right: 32px;" src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo" (click)="addFamilyDetails()">
         </div>
      </div>
      <div class="row">
         <div class="col-12">
            <div class="dig_table_overflow" style="width: 97%;">
               <div class="table-responsive">
                   <table class="table table-hover table-dynamic" id="famtbl">
                       <thead>
                           <tr>
                               <th style="width: 5%;">Relations</th>
                               <th>Comorbidities</th>
                       </thead>
                       <tbody>
                           <tr *ngFor="let relDet of family_hist_data">
                               <td style="font-size: 12px;text-align: left !important;">{{relDet.relation_name}}</td>
                               <td style="font-size: 12px;">
                                 <span *ngIf="relDet.diabetics == 'yes'">Diabetics,</span>
                                 <span *ngIf="relDet.heart_attack == 'yes'">Heart attack,</span>
                                 <span *ngIf="relDet.stroke == 'yes'">Stroke,</span>
                                 <span *ngIf="relDet.arterial == 'yes'">Arterial,</span>

                                 <span *ngIf="relDet.endocrine == 'yes'">Endocrine,</span>
                                 <span *ngIf="relDet.autoimmune == 'yes'">Autoimmune,</span>
                                 <span *ngIf="relDet.hypertension == 'yes'">Hypertension,</span>
                                 <span *ngIf="relDet.tuberculosis == 'yes'">Tuberculosis,</span>

                                 <span *ngIf="relDet.hemophilia == 'yes'">Hemophilia,</span>
                                 <span *ngIf="relDet.hiv == 'yes'">HIV,</span>
                                 <span *ngIf="relDet.hepatitis == 'yes'">Hepatitis,</span>
                                 <span *ngIf="relDet.glaucoma == 'yes'">Glaucoma,</span>
                                 <span *ngIf="relDet.others != null">{{relDet.others}}</span>
                              </td>
                           </tr>
                       </tbody>
                   </table>
               </div>
           </div>
         </div>
      </div> -->

   </div>
</div>
<div class="col-4  col-sm-1  col-lg-1 col-md-1 col-xl-1">

</div>