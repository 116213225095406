<div *ngIf="dietPlanFlag == 'min'">
    <div class="details row">
       <div class="col-12" style="margin: 5px 0;">
          <div class="diet_lable_cover">
             <mat-label class="dite_input" [(ngModel)]="caloriesRequired">
                Caloried required
                <span style="float: right;padding: 0 10px 0 0;">{{caloriesRequired}}</span>
             </mat-label>
             <div class="progress progress-xxs">
                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77" aria-valuemin="0"
                   aria-valuemax="100" [ngStyle]="{'border-bottom':'1px solid green','width':'0'}"></div>
             </div>
          </div>
       </div>
       <div class="col-12" style="margin: 5px 0;">
          <div class="diet_lable_cover">
             <mat-label class="dite_input" [(ngModel)]="totalCalories">
                Total calories(Kcal)
                <span style="float: right;padding: 0 10px 0 0;">{{totalCalories}}</span>
             </mat-label>
             <div class="progress progress-xxs">
                <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                   aria-valuemax="100" [ngStyle]="{'width':'0'}"></div>
             </div>
          </div>
       </div>
       <div class="col-12" style="margin: 5px 0;">
          <div class="diet_lable_cover">
             <mat-label class="dite_input" id="sga" [(ngModel)]="sgaOverallScore">
                SGA score
                <span style="float: right;padding: 0 10px 0 0;">{{(sgaOverallScore == "") ? 0 : sgaOverallScore}}</span>
             </mat-label>
             <div class="progress progress-xxs">
                <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23" aria-valuemin="0"
                   aria-valuemax="100" [ngStyle]="{'width':''}"></div>
             </div>
          </div>
       </div>
    </div>
 </div>
 <div *ngIf="dietPlanFlag == 'max'">
    <div class="row">
       <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin: 5px 0;">
          <div class="diet_lable_cover">
             <mat-label class="dite_input" [(ngModel)]="caloriesRequired">
                Caloried required
                <span style="float: right;padding: 0 10px 0 0;">{{caloriesRequired}}</span>
             </mat-label>
             <div class="progress progress-xxs">
                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77" aria-valuemin="0"
                   aria-valuemax="100" [ngStyle]="{'border-bottom':'1px solid green','width':''}"></div>
             </div>
          </div>
       </div>
       <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin: 5px 0;">
          <div class="diet_lable_cover">
             <mat-label class="dite_input" [(ngModel)]="totalCalories">
                Total calories(Kcal)
                <span style="float: right;padding: 0 10px 0 0;">{{totalCalories}}</span>
             </mat-label>
             <div class="progress progress-xxs">
                <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                   aria-valuemax="100" [ngStyle]="{'width':''}"></div>
             </div>
          </div>
       </div>
       <div class="col-9 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin: 5px 0;">
          <div class="diet_lable_cover">
             <mat-label class="dite_input" id="sga" [(ngModel)]="sgaOverallScore">
                SGA score
                <span style="float: right;padding: 0 10px 0 0;">{{(sgaOverallScore == "") ? 0 : sgaOverallScore}}</span>
             </mat-label>
             <div class="progress progress-xxs">
                <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23" aria-valuemin="0"
                   aria-valuemax="100" [ngStyle]="{'width':''}"></div>
             </div>
          </div>
       </div>
       <div class="col-3 col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <img src="../assets/global/images/settings-icon.png" (click)="openModal()" class=""
             style="position: relative;top: 8px;" width="22px" height="22px" />
       </div>
       <div class="dite_content_cover">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
             <li class="nav-item" role="presentation">
                <button class="nav-link active" id="morning-tab" data-bs-toggle="tab" data-bs-target="#morning"
                   type="button" role="tab" aria-controls="morning" aria-selected="true">Morning snacks</button>
             </li>
             <li class="nav-item" role="presentation">
                <button class="nav-link" id="breakfast-tab" data-bs-toggle="tab" data-bs-target="#breakfast"
                   type="button" role="tab" aria-controls="breakfast" aria-selected="false">Breakfast</button>
             </li>
             <li class="nav-item" role="presentation">
                <button class="nav-link" id="mid_day-tab" data-bs-toggle="tab" data-bs-target="#mid_day" type="button"
                   role="tab" aria-controls="mid_day" aria-selected="false">Mid-day-snacks</button>
             </li>
             <li class="nav-item" role="presentation">
                <button class="nav-link" id="lunch-tab" data-bs-toggle="tab" data-bs-target="#lunch" type="button"
                   role="tab" aria-controls="lunch" aria-selected="false">Lunch</button>
             </li>
             <li class="nav-item" role="presentation">
                <button class="nav-link" id="Evening-tab" data-bs-toggle="tab" data-bs-target="#Evening" type="button"
                   role="tab" aria-controls="Evening" aria-selected="false">Evening snacks</button>
             </li>
             <li class="nav-item" role="presentation">
                <button class="nav-link" id="dinner-tab" data-bs-toggle="tab" data-bs-target="#dinner" type="button"
                   role="tab" aria-controls="dinner" aria-selected="false">Dinner</button>
             </li>
             <li class="nav-item" role="presentation">
                <button class="nav-link" id="night_snacks-tab" data-bs-toggle="tab" data-bs-target="#night_snacks"
                   type="button" role="tab" aria-controls="night_snacks" aria-selected="false">Night snacks</button>
             </li>
          </ul>
          <div class="tab-content" id="myTabContent">
             <div class="tab-pane fade show active" id="morning" role="tabpanel" aria-labelledby="morning-tab">
                <div class="row" style="margin-top: 20px;">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div class="row">
                               <div class="col-6">
                                  <mat-label class="matlabel">Category<br>
                                     <select [(ngModel)]="mornFoodCategory" id="mornFCategory"
                                        (change)="changeFoodCategory('Morning')" disableOptionCentering
                                        class="ipcss widthbillcreate">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategoryList" [value]="food.description">
                                           {{food.description}}
                                        </option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6 select_bottomy">
                                  <mat-label class="matlabel">Food type<br>
                                     <select [(ngModel)]="morFoodType" id="mornfoodtype" class="ipcss widthbillcreate"
                                        (change)="changeFoodType('Morning')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of morFoodTypeList" [value]="food">{{food}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6 select_bottomy">
                                  <mat-label class="matlabel">Food sub type<br>
                                     <select [(ngModel)]="morSubFoodType" id="mornsubfoodtype"
                                        class="ipcss widthbillcreate" (change)="changeSubFoodType(morSubFoodType,'Morning')"
                                        disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of morSubfoodTypeList" [value]="food.description">
                                           {{food.description}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6 select_bottomy">
                                  <mat-label class="matlabel">Quantity<br>
                                     <select [(ngModel)]="morQuantity" id="mornQuantity" class="ipcss"
                                        (change)="changeQuantity(morQuantity,'Morning')" required>
                                        <option disabled>Select </option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>24</option>
                                        <option>26</option>
                                        <option>28</option>
                                        <option>30</option>
                                        <option>32</option>
                                        <option>40</option>
                                        <option>42</option>
                                        <option>48</option>
                                        <option>60</option>
                                        <option>1/8</option>
                                        <option>1/4</option>
                                        <option>1/3</option>
                                        <option>1/2</option>
                                        <option>3/4</option>
                                        <option>11/2</option>
                                        <option>21/2</option>
                                        <option>21/8</option>
                                        <option>91/2</option>
                                        <option>2/3</option>
                                        <option>41/4</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Measure<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="morMeasure"
                                        minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Weight<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="morWeight"
                                        minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Energy<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="morEnegry"
                                        minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6 select_bottomy">
                                  <mat-label class="matlabel">Condition<br>
                                     <select [(ngModel)]="morFoodCond" class="ipcss widthbillcreate"
                                        disableOptionCentering>
                                        <option value="or">or</option>
                                        <option value="and">and</option>
                                        <option value="with">with</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-12">
                                  <div  style="margin-bottom: 10px;"></div>
                                  <div >                                    
                                     <a><img (click)="addDietMorningFood() "
                                           src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                           style="float: right;" /></a>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div style="float: right;">
                               <img class="pencil_refresh_icon" (click)="editFoodContent('mor')"
                                  src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" />
                               <img class="pencil_refresh_icon" (click)="refreshFoodContent('mor')"
                                  src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" />
                            </div>
                            <textarea class="ipcss widthappt result_area"
                               style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                               disabled [innerHTML]="dietMorning"></textarea>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <!-- //Breakfast -->
             <div class="tab-pane fade show" id="breakfast" role="tabpanel" aria-labelledby="morning-tab">
                <div class="row" style="margin-top: 20px;">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div class="row">
                               <div class="col-6">
                                  <mat-label class="matlabel">Category<br>
                                     <select [(ngModel)]="bfFoodCategory" id="bfCategory"
                                        (change)="changeFoodCategory('breakfast')" required disableOptionCentering
                                        class="ipcss widthbillcreate">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategoryList" [value]="food.description">
                                           {{food.description}}
                                        </option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food type<br>
                                     <select [(ngModel)]="bfFoodType" id="bffoodtype" class="ipcss widthbillcreate"
                                        required (change)="changeFoodType('breakfast')"
                                        disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of bfFoodTypeList" [value]="food">{{food}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food sub type<br>
                                     <select [(ngModel)]="bfSubFoodType" id="bfsubfoodtype" class="ipcss widthbillcreate"
                                        (change)="changeSubFoodType(bfSubFoodType,'breakfast')" disableOptionCentering
                                        required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of bfSubFoodTypeList" [value]="food.description">
                                           {{food.description}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Quantity<br>
                                     <select [(ngModel)]="bfQuantity" id="bfQuantity" class="ipcss"
                                        (change)="changeQuantity(bfQuantity,'breakfast')" required>
                                        <option disabled>Select </option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>24</option>
                                        <option>26</option>
                                        <option>28</option>
                                        <option>30</option>
                                        <option>32</option>
                                        <option>40</option>
                                        <option>42</option>
                                        <option>48</option>
                                        <option>60</option>
                                        <option>1/8</option>
                                        <option>1/4</option>
                                        <option>1/3</option>
                                        <option>1/2</option>
                                        <option>3/4</option>
                                        <option>11/2</option>
                                        <option>21/2</option>
                                        <option>21/8</option>
                                        <option>91/2</option>
                                        <option>2/3</option>
                                        <option>41/4</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Measure<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="bfMeasure" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Weight<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="bfWeight" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Energy<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="bfEnergy" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Condition<br>
                                     <select [(ngModel)]="breakfastCond" class="ipcss widthbillcreate"
                                        disableOptionCentering>
                                        <option>Select</option>
                                        <option value="or">or</option>
                                        <option value="and">and</option>
                                        <option value="with">with</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-12">
                                  <div  style="margin-bottom: 10px;"></div>
                                  <div >
                                     <a><img (click)="addDietBreakfastFood()"
                                           src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                           style="float: right;" /></a>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div style="float: right;">
                               <img class="pencil_refresh_icon" (click)="editFoodContent('break')"
                                  src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" />
                               <img class="pencil_refresh_icon" (click)="refreshFoodContent('break')"
                                  src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" />
                            </div>
                            <textarea class="ipcss widthappt result_area"
                               style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                               disabled [innerHTML]="dietBreakfast"></textarea>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <!-- //Mid-Day-Snacks -->
             <div class="tab-pane fade show" id="mid_day" role="tabpanel" aria-labelledby="morning-tab">
                <div class="row" style="margin-top: 20px;">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div class="row">
                               <div class="col-6">
                                  <mat-label class="matlabel">Category<br>
                                     <select [(ngModel)]="middayFoodCategory" id="middayCategory"
                                        (change)="changeFoodCategory('midday')" required disableOptionCentering
                                        class="ipcss widthbillcreate">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategoryList" [value]="food.description">
                                           {{food.description}}
                                        </option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food type<br>
                                     <select [(ngModel)]="middayFoodType" id="middayfoodtype"
                                        class="ipcss widthbillcreate" required
                                        (change)="changeFoodType('midday')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of middayFoodTypeList" [value]="food">{{food}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food sub type<br>
                                     <select [(ngModel)]="middaySubFoodType" id="middaysubfoodtype"
                                        class="ipcss widthbillcreate" (change)="changeSubFoodType(middaySubFoodType,'midday')"
                                        disableOptionCentering required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of middaySubfoodTypeList" [value]="food.description">
                                           {{food.description}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Quantity<br>
                                     <select [(ngModel)]="middayQuantity" id="middayQuantity" class="ipcss"
                                        (change)="changeQuantity(middayQuantity,'midday')" required>
                                        <option disabled>Select </option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>24</option>
                                        <option>26</option>
                                        <option>28</option>
                                        <option>30</option>
                                        <option>32</option>
                                        <option>40</option>
                                        <option>42</option>
                                        <option>48</option>
                                        <option>60</option>
                                        <option>1/8</option>
                                        <option>1/4</option>
                                        <option>1/3</option>
                                        <option>1/2</option>
                                        <option>3/4</option>
                                        <option>11/2</option>
                                        <option>21/2</option>
                                        <option>21/8</option>
                                        <option>91/2</option>
                                        <option>2/3</option>
                                        <option>41/4</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Measure<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="middayMeasure" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Weight<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="middayWeight" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Energy<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="middayEnergy" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Condition<br>
                                     <select [(ngModel)]="middayCond" class="ipcss widthbillcreate"
                                        disableOptionCentering>
                                        <option>Select</option>
                                        <option value="or">or</option>
                                        <option value="and">and</option>
                                        <option value="with">with</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-12">
                                  <div  style="margin-bottom: 10px;"></div>
                                  <div >                                    
                                     <a><img (click)="addDietMiddayFood()" src="../../../assets/ui_icons/buttons/add_button.svg"
                                           class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div style="float: right;">
                               <img class="pencil_refresh_icon" (click)="editFoodContent('midday')"
                                  src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" />
                               <img class="pencil_refresh_icon" (click)="refreshFoodContent('midday')"
                                  src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" />
                            </div>
                            <textarea class="ipcss widthappt result_area"
                               style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                               disabled [innerHTML]="dietMidday"></textarea>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <!-- //lunch -->
             <div class="tab-pane fade show" id="lunch" role="tabpanel" aria-labelledby="morning-tab">
                <div class="row" style="margin-top: 20px;">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div class="row">
                               <div class="col-6">
                                  <mat-label class="matlabel">Category<br>
                                     <select [(ngModel)]="lunchFoodCategory" id="lunchCategory"
                                        (change)="changeFoodCategory('lunch')" required disableOptionCentering
                                        class="ipcss widthbillcreate">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategoryList" [value]="food.description">
                                           {{food.description}}
                                        </option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food type<br>
                                     <select [(ngModel)]="lunchFoodType" id="lunchfoodtype" class="ipcss widthbillcreate"
                                        required (change)="changeFoodType('lunch')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of lunchFoodTypeList" [value]="food">{{food}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food sub type<br>
                                     <select [(ngModel)]="lunchSubFoodType" id="lunchsubfoodtype"
                                        class="ipcss widthbillcreate" (change)="changeSubFoodType(lunchSubFoodType,'lunch')"
                                        disableOptionCentering required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of lunchSubFoodTypeList" [value]="food.description">
                                           {{food.description}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Quantity<br>
                                     <select [(ngModel)]="lunchQuantity" id="lunchQuantity" class="ipcss"
                                        (change)="changeQuantity(lunchQuantity,'lunch')" required>
                                        <option disabled>Select </option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>24</option>
                                        <option>26</option>
                                        <option>28</option>
                                        <option>30</option>
                                        <option>32</option>
                                        <option>40</option>
                                        <option>42</option>
                                        <option>48</option>
                                        <option>60</option>
                                        <option>1/8</option>
                                        <option>1/4</option>
                                        <option>1/3</option>
                                        <option>1/2</option>
                                        <option>3/4</option>
                                        <option>11/2</option>
                                        <option>21/2</option>
                                        <option>21/8</option>
                                        <option>91/2</option>
                                        <option>2/3</option>
                                        <option>41/4</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Measure<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="lunchMeasure" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Weight<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="lunchWeight" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Energy<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="lunchEnergy" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Condition<br>
                                     <select [(ngModel)]="lunchCond" class="ipcss widthbillcreate"
                                        disableOptionCentering>
                                        <option>Select</option>
                                        <option value="or">or</option>
                                        <option value="and">and</option>
                                        <option value="with">with</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-12">
                                  <div  style="margin-bottom: 10px;"></div>
                                  <div >
                                        <a><img (click)="addDietLunchFood()" src="../../../assets/ui_icons/buttons/add_button.svg"
                                           class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div style="float: right;">
                               <img class="pencil_refresh_icon" (click)="editFoodContent('lunch')"
                                  src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" />
                               <img class="pencil_refresh_icon" (click)="refreshFoodContent('lunch')"
                                  src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" />
                            </div>
                            <textarea class="ipcss widthappt result_area"
                               style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                               disabled [innerHTML]="dietLunch"></textarea>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <!-- //evening Snacks -->
             <div class="tab-pane fade show" id="Evening" role="tabpanel" aria-labelledby="morning-tab">
                <div class="row" style="margin-top: 20px;">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div class="row">
                               <div class="col-6">
                                  <mat-label class="matlabel">Category<br>
                                     <select [(ngModel)]="eveFoodCategory" id="eveCategory" (change)="changeFoodCategory('eve')"
                                        required disableOptionCentering class="ipcss widthbillcreate">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategoryList" [value]="food.description">
                                           {{food.description}}
                                        </option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food type<br>
                                     <select [(ngModel)]="eveFoodType" id="evefoodtype" class="ipcss widthbillcreate"
                                        required (change)="changeFoodType('eve')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of eveFoodTypeList" [value]="food">{{food}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food sub type<br>
                                     <select [(ngModel)]="eveSubFoodType" id="evesubfoodtype" class="ipcss widthbillcreate"
                                        (change)="changeSubFoodType(eveSubFoodType,'eve')" disableOptionCentering required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of eveSubFoodTypeList" [value]="food.description">
                                           {{food.description}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Quantity<br>
                                     <select [(ngModel)]="eveQuantity" id="eveQuantity" class="ipcss"
                                        (change)="changeQuantity(eveQuantity,'eve')" required>
                                        <option disabled>Select </option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>24</option>
                                        <option>26</option>
                                        <option>28</option>
                                        <option>30</option>
                                        <option>32</option>
                                        <option>40</option>
                                        <option>42</option>
                                        <option>48</option>
                                        <option>60</option>
                                        <option>1/8</option>
                                        <option>1/4</option>
                                        <option>1/3</option>
                                        <option>1/2</option>
                                        <option>3/4</option>
                                        <option>11/2</option>
                                        <option>21/2</option>
                                        <option>21/8</option>
                                        <option>91/2</option>
                                        <option>2/3</option>
                                        <option>41/4</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Measure<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="eveMeasure"
                                        minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Weight<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="eveWeight"
                                        minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Energy<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="eveEnergy"
                                        minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Condition<br>
                                     <select [(ngModel)]="eveCond" class="ipcss widthbillcreate" disableOptionCentering>
                                        <option>Select</option>
                                        <option value="or">or</option>
                                        <option value="and">and</option>
                                        <option value="with">with</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                  <div  style="margin-bottom: 10px;"></div>
                                  <div >                                    
                                     <a><img (click)="addDietEveFood()" src="../../../assets/ui_icons/buttons/add_button.svg"
                                           class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div style="float: right;">
                               <img class="pencil_refresh_icon" (click)="editFoodContent('eve')"
                                  src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" />
                               <img class="pencil_refresh_icon" (click)="refreshFoodContent('eve')"
                                  src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" />
                            </div>
                            <textarea class="ipcss widthappt result_area"
                               style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                               disabled [innerHTML]="dietEvening"></textarea>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <!-- //dinner Snacks -->
             <div class="tab-pane fade show" id="dinner" role="tabpanel" aria-labelledby="morning-tab">
                <div class="row" style="margin-top: 20px;">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div class="row">
                               <div class="col-6">
                                  <mat-label class="matlabel">Category<br>
                                     <select [(ngModel)]="dinnerFoodCategory" id="dinnerCategory"
                                        (change)="changeFoodCategory('dinner')" required disableOptionCentering
                                        class="ipcss widthbillcreate">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategoryList" [value]="food.description">
                                           {{food.description}}
                                        </option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food type<br>
                                     <select [(ngModel)]="dinnerFoodType" id="dinnerfoodtype"
                                        class="ipcss widthbillcreate" required
                                        (change)="changeFoodType('dinner')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of dinnerFoodTypeList" [value]="food">{{food}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food sub type<br>
                                     <select [(ngModel)]="dinnerSubFoodType" id="dinnersubfoodtype"
                                        class="ipcss widthbillcreate" (change)="changeSubFoodType(dinnerSubFoodType,'dinner')"
                                        disableOptionCentering required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of dinnerSubfoodTypeList" [value]="food.description">
                                           {{food.description}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Quantity<br>
                                     <select [(ngModel)]="dinnerQuantity" id="dinnerQuantity" class="ipcss"
                                        (change)="changeQuantity(dinnerQuantity,'dinner')" required>
                                        <option disabled>Select </option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>24</option>
                                        <option>26</option>
                                        <option>28</option>
                                        <option>30</option>
                                        <option>32</option>
                                        <option>40</option>
                                        <option>42</option>
                                        <option>48</option>
                                        <option>60</option>
                                        <option>1/8</option>
                                        <option>1/4</option>
                                        <option>1/3</option>
                                        <option>1/2</option>
                                        <option>3/4</option>
                                        <option>11/2</option>
                                        <option>21/2</option>
                                        <option>21/8</option>
                                        <option>91/2</option>
                                        <option>2/3</option>
                                        <option>41/4</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Measure<br>
                                     <input type="text" name="name" required id="formqnty" class="ipcss"
                                        [(ngModel)]="dinnerMeasure" minlength="3" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Weight<br>
                                     <input type="text" name="name" required id="formqnty" class="ipcss"
                                        [(ngModel)]="dinnerWeight" minlength="3" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Energy<br>
                                     <input type="text" name="name" required id="formqnty" class="ipcss"
                                        [(ngModel)]="dinnerEnergy" minlength="3" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Condition<br>
                                     <select [(ngModel)]="dinnerCond" class="ipcss widthbillcreate"
                                        disableOptionCentering>
                                        <option>Select</option>
                                        <option value="or">or</option>
                                        <option value="and">and</option>
                                        <option value="with">with</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-12">
                                  <div  style="margin-bottom: 10px;"></div>
                                  <div >                                    
                                     <a><img (click)="addDietDinnerFood()" src="../../../assets/ui_icons/buttons/add_button.svg"
                                           class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div style="float: right;">
                               <img class="pencil_refresh_icon" (click)="editFoodContent('dinner')"
                                  src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" />
                               <img class="pencil_refresh_icon" (click)="refreshFoodContent('dinner')"
                                  src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" />
                            </div>
                            <textarea class="ipcss widthappt result_area"
                               style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                               disabled [innerHTML]="dietDinner"></textarea>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <!-- //night Snacks -->
             <div class="tab-pane fade show" id="night_snacks" role="tabpanel" aria-labelledby="morning-tab">
                <div class="row" style="margin-top: 20px;">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div class="row">
                               <div class="col-6">
                                  <mat-label class="matlabel">Category<br>
                                     <select [(ngModel)]="nightFoodCategory" id="nightCategory"
                                        (change)="changeFoodCategory('night')" required disableOptionCentering
                                        class="ipcss widthbillcreate">
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of foodCategoryList" [value]="food.description">
                                           {{food.description}}
                                        </option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food type<br>
                                     <select [(ngModel)]="nightFoodType" id="nightfoodtype" class="ipcss widthbillcreate"
                                        required (change)="changeFoodType('night')" disableOptionCentering>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of nightFoodTypeList" [value]="food">{{food}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Food sub type<br>
                                     <select [(ngModel)]="nightSubFoodType" id="nightsubfoodtype"
                                        class="ipcss widthbillcreate" (change)="changeSubFoodType(nightSubFoodType,'night')"
                                        disableOptionCentering required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let food of nightSubfoodTypeList" [value]="food.description">
                                           {{food.description}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Quantity<br>
                                     <select [(ngModel)]="nightQuantity" id="nightQuantity" class="ipcss"
                                        (change)="changeQuantity(nightQuantity,'night')" required>
                                        <option disabled>Select </option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>24</option>
                                        <option>26</option>
                                        <option>28</option>
                                        <option>30</option>
                                        <option>32</option>
                                        <option>40</option>
                                        <option>42</option>
                                        <option>48</option>
                                        <option>60</option>
                                        <option>1/8</option>
                                        <option>1/4</option>
                                        <option>1/3</option>
                                        <option>1/2</option>
                                        <option>3/4</option>
                                        <option>11/2</option>
                                        <option>21/2</option>
                                        <option>21/8</option>
                                        <option>91/2</option>
                                        <option>2/3</option>
                                        <option>41/4</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Measure<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="nightMeasure" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Weight<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="nightWeight" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Energy<br>
                                     <input type="text" name="name" id="formqnty" class="ipcss"
                                        [(ngModel)]="nightEnergy" minlength="3" required="" aria-required="true">
                                  </mat-label>
                               </div>
                               <div class="col-6">
                                  <mat-label class="matlabel">Condition<br>
                                     <select [(ngModel)]="nightCond" class="ipcss widthbillcreate"
                                        disableOptionCentering>
                                        <option>Select</option>
                                        <option value="or">or</option>
                                        <option value="and">and</option>
                                        <option value="with">with</option>
                                     </select>
                                  </mat-label>
                               </div>
                               <div class="col-12">
                                  <div style="margin-bottom: 10px;"></div>
                                  <div >                                    
                                     <a><img (click)="addDietNightFood()" src="../../../assets/ui_icons/buttons/add_button.svg"
                                           class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="cover_div">
                         <div class="content_cover">
                            <div style="float: right;">
                               <img class="pencil_refresh_icon" (click)="editFoodContent('night')"
                                  src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" />
                               <img class="pencil_refresh_icon" (click)="refreshFoodContent('night')"
                                  src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" />
                            </div>
                            <textarea class="ipcss widthappt result_area"
                               style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                               disabled [innerHTML]="dietNight"></textarea>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <a (click)="dietPlanHistory('diettracker')"><img src="../../../assets/ui_icons/buttons/history.svg"
                class="histimgbtn_planhist" /></a>
          <a (click)="dietPlanHistory('patient')"><img src="../../../assets/ui_icons/buttons/wrong.svg"
                class="histimgbtn_trackhist" /></a>
       </div>
    </div>
 </div>