<div class="row">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
               </div>
               <div class="headerButtons">
                  <a *ngIf="saveFlag" (click)="create_appointment(false)"><img
                        src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
                  <a (click)="create_appointment(true)" *ngIf="this.listProducts.length != 0"><span class="cardsave" style="right: 60px;"><img src="../../../assets/img/printer.svg" style="width:25px" /></span> </a>
               </div>
            </div>

         </mdb-card-header>
         <mdb-card-body>
            <div class="row">
               <div class="Details" style="margin-top: -12px;margin-bottom: -25px;">
                  <div class="row">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" style="margin: 15px 0 15px 0;">
                        <div class="row">
                           <div class="col-6 col-sm-6 col-md-8 col-lg-3 col-xl-6 col-xxl-6">
                              <mat-label class="matlabel"
                                 style="font-family: arial;font-weight: 600;font-size: 13px !important;">Show patients</mat-label>
                           </div>
                           <div class="col-6 col-sm-6 col-md-2 col-lg-6 col-xl-6 col-xxl-6 margin-bottomvalue">
                              <img *ngIf="showpatient == true" (click)="showHidePatients(true)"
                                 src="../../../assets/ui_icons/tick_mark.svg" style="cursor: pointer;width: 24px;margin: 0 0px 0 9px;">
                              <img *ngIf="showpatient == false" (click)="showHidePatients(false)"
                                 src="../../../assets/ui_icons/cross_mark.svg" style="cursor: pointer;width: 24px; margin: 0 0px 0 9px;">
                           </div>
                           
                        </div>
                     </div>

                     <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" style="margin: 15px 0 15px 0;">
                        <div class="row">
                           <div class="col-6">
                              <mat-label class="matlabel" style="font-weight: 600;">Appointment Type : </mat-label>
                           </div>
                           <div class="col-6">
                              <div class="switch-field">
                                 <input type="radio" id="radio-one" (click)="change_type('app',1,'yes')" />
                                 <label for="radio-one" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">New</label>
                                 <input type="radio" id="radio-two" (click)="change_type('app',1,'no')" />
                                 <label for="radio-two"
                                    [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">Follow-up</label>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;">
                        <div class="row" *ngIf="existUserFlag">
                           <div class="col-4">
                              <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                           </div>
                           <div class="col-4">
                              <div class="switch-field">
                                 <input type="radio" id="radio-three" (click)="change_type('client',1,'yes')" />
                                 <label for="radio-three"
                                    [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">New</label>
                                 <input type="radio" id="radio-four" (click)="change_type('client',1,'no')" />
                                 <label for="radio-four"
                                    [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">Existing</label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="container-fluid" style="margin-left: -20px;">
                     <div class="row align-items-center"  style="margin-right: -50px;">
                        <div class="col-3" [hidden]="searchFlag">
                           <div class="cover_div" style="padding-bottom: 13px;">
                             <div class="header_lable">Patient</div>
                             <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" >
                               <div class="row">
                                 <div class="col-8">
                                   <div class="switch-field">
                                     <input type="radio" id="radio-five" (click)="searchType('name')" />
                                     <label for="radio-five" [ngClass]="searchTypeFlag ? 'radio_active':'radio_inactive'">Name</label>
                                     <input type="radio" id="radio-six" (click)="searchType('mobile')"/>
                                     <label for="radio-six" [ngClass]="!searchTypeFlag ? 'radio_active':'radio_inactive'">Mobile</label>
                                   </div>
                                 </div>
                               </div>
                             </div>
                             <div class="content_cover">
                               <div>
                                 <input class="inputStyle2" type="text" id="filterInput" [(ngModel)]="patientName" placeholder={{searchtext}} 
                                 (keyup)="getPatientListByName(patientName)">
                                 <div class="scrollbar" #scrollContainer>
                                   <div class="scrollbar-inner">
                                     <table *ngIf="patientList.length">
                                       <tbody>
                                         <tr *ngFor="let person of filteredArr; let i = index"
                                           (click)='retrieveClient(person)'>
                                           <td style="font-size: 12px;text-align: left; margin-top: 5px; margin-bottom: 5px;">{{ person.full_name }} <br /> {{
                                             person.mobile }} </td>
                                         </tr>
                                       </tbody>
                                     </table>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>

                         <div [ngClass]="appclass ? 'col-9':'col-12'">
                           <div class="cover_div" style="padding-bottom: 13px;">
                              <div class="scrollbar1">
                                 <mat-accordion class="mataccordion">
                                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                                       <mat-expansion-panel-header class="exppanel width">
                                          <mat-panel-title class="title">
                                             Personal
                                          </mat-panel-title>
                                       </mat-expansion-panel-header>
                                       <hr class="hr">
                                       <div class="row pad_top_15">
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" *ngIf="user_type">
                                             <mat-label class="matlabel">Relationship
                                                <select disableOptionCentering class="ipcss noappt_width" name="Relationship"
                                                   [(ngModel)]="Relationship" (ngModelChange)="Relationship_change($event)">
                                                   <option disabled>Select</option>
                                                   <option *ngFor="let relation of rel_list_data"
                                                      value="{{relation.relationship_name}}">
                                                      {{relation.relationship_name}}</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                             <mat-label class="matlabel">Salutation
                                                <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clnt_sal"
                                                   (ngModelChange)="changeSalutation(clnt_sal)">
                                                   <option value="select" selected>Select</option>
                                                   <option *ngFor="let sal of clnt_sal_list" value="{{sal.sal_id}}">
                                                      {{sal.sal_desc}}</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">First Name
                                                <input type="text" class="ipcss noappt_width" (change)="Acitvate_savebtn()"
                                                   (click)="focus_fname()" [(ngModel)]="clnt_fname" (blur)="fname_toUpper()" required
                                                   matInput [matAutocomplete]="auto5" />
                                                <mat-autocomplete #auto5="matAutocomplete" [hidden]="name_flag">
                                                   <mat-option (click)="select_client(client.name)" *ngFor="let client of clientarray"
                                                      value="{{client.name}}">
                                                      {{client.name}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">Middle Name
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_mname"
                                                   (click)="focus_mname()" (blur)="mname_toUpper()" matInput />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">Last Name
                                                <input type="text" required class="ipcss noappt_width" [(ngModel)]="clnt_lname" required (change)="Acitvate_savebtn()" (click)="focus_lname()" (blur)="lname_toUpper()" matInput />
                                             </mat-label>
                                          </div>
               
                                          <div *ngIf="user_type" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <!-- <div class="row">
                                                <div class="col-10">
                                                   <mat-label class="matlabel" *ngIf="user_type">Mobile No
                                                      <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_cont_number"
                                                         maxlength="10"
                                                         onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                                         (click)="fucusMobile()" (blur)="checkClient()" matInput />
                                                   </mat-label>
                                                </div>
                                                <div class="col-2  p-0">
                                                   <mat-label class="matlabel" *ngIf="user_type">
                                                      <a (click)="getUserDetails()"><img src="../../../assets/img/search.png"
                                                            class="seacrhicon" /></a>
                                                   </mat-label>
                                                </div>
                                             </div> -->
                                             <mat-label class="matlabel" *ngIf="user_type">Mobile No
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_cont_number"
                                                   maxlength="10"
                                                   onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                                   (click)="fucusMobile()" (blur)="checkClient()" matInput />
                                             </mat-label>
                                          </div>
               
                                          <div *ngIf="!user_type" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel" *ngIf="!user_type">Mobile No
                                                <input type="text" class="ipcss noappt_width" (change)="Acitvate_savebtn()"
                                                   [(ngModel)]="clnt_cont_number" maxlength="10" required
                                                   onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                   (focus)="clearMobile()" matInput />
                                             </mat-label>
                                          </div>
               
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">DOB
                                                <input type="date" class="ipcss_date noappt_width" [disabled]="disable_dob"
                                                   (change)="Date_click(clnt_dob)" [(ngModel)]="clnt_dob" max="{{currentDate}}"
                                                   required #matInput>
               
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">Age
                                                <!-- [disabled]="disable_age" -->
                                                <input type="text" class="ipcss noappt_width" [disabled]="disable_age"
                                                   (keyup)="ChangeAge()" [(ngModel)]="clnt_age" [required]="require_flag" matInput />
                                             </mat-label>
                                          </div>
                           
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel" *ngIf="user_type">Gender
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_gender" matInput />
                                             </mat-label>
                                             <div class="select_bottom">
                                                <mat-label class="matlabel" *ngIf="!user_type">Gender
                                                   <select disableOptionCentering (change)="Acitvate_savebtn()"
                                                      (ngModelChange)="changeGender($event)" class="ipcss noappt_width"
                                                      [(ngModel)]="clnt_gender">
                                                      <option value="Male">Male</option>
                                                      <option value="Female">Female</option>
                                                      <option value="Transgender">Transgender</option>
                                                   </select>
                                                </mat-label>
                                             </div>
                                          </div>
               
                                          <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                             <mat-label class="matlabel">Specialization
                                                <mat-select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="spl_id">
                                                   <mat-option Selected>Select</mat-option>
                                                   <mat-option *ngFor="let spl of spl_array" (click)="getCasesheetFields(spl.spl_id)"
                                                      value="{{spl.spl_id}}">
                                                      {{spl.spl_name}}</mat-option>
                                                </mat-select>
                                             </mat-label>
                                          </div>
               
                                          <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">Advice
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="advice" matInput />
                                             </mat-label>
                                          </div>
   
                                          <div [hidden]="surgery_show" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                             <div class="row">
                                                <div class="col-12 col-md-6 col-lg-3 col-sm-6 col-xl-3 select_bottom">
                                                   <mat-label class="matlabel">Surgery
                                                      <select disableOptionCentering class="ipcss noappt_width"
                                                         [(ngModel)]="surgery_id">
                                                         <option disabled>Select</option>
                                                         <option *ngFor="let surgery of surgeryarray"
                                                            click="surgery_change(surgery.procedure_id)"
                                                            value="{{surgery.procedure_id}}"> {{surgery.description}}
                                                         </option>
                                                      </select>
                                                   </mat-label>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3 col-sm-6 col-xl-3 select_bottom">
                                                   <mat-label class="matlabel">Surgery type
                                                      <select disableOptionCentering class="ipcss noappt_width"
                                                         [(ngModel)]="surgery_type">
                                                         <option value="invasive">Invasive</option>
                                                         <option value="non-invasive">Non-Invasive</option>
                                                      </select>
                                                   </mat-label>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-sm-6 col-xl-6  select_bottom">
                                                   <mat-label class="matlabel">Investigation
                                                      <textarea class="ipcss noappt_width" [(ngModel)]="sug_investigation"
                                                         maxlength="500"></textarea>
                                                   </mat-label>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-sm-6 col-xl-6  select_bottom">
                                                   <mat-label class="matlabel">Surgery procedure
                                                      <textarea class="ipcss noappt_width" [(ngModel)]="sug_procedure"
                                                         maxlength="500"></textarea>
                                                   </mat-label>
                                                </div>
                                                <div class="col-12">
                                                   <mdb-card>
                                                      <mdb-card-body class=" p-0 inverted">
                                                         <div class="bg-white" *ngIf="sugeryImges">
                                                            <div class="d-flex justify-content-between">
                                                               <div class="d-flex">
                                                                  <div *ngFor="let image of report_img">
                                                                     <img src="{{image.image}}" id="report_img"
                                                                        class="selectImage img-thumbnail m-1"
                                                                        style="width: 100px; height: 70px;" />
                                                                  </div>
                                                                  <!-- <div class="position-relative">
                                                                                 <div id="tool_import" class="menu_item import_btn" style="display: none;">
                                                                                   <div id="fileinputs_import"></div>
                                                                                   <img src="../../../assets/dist/images/add-image.png" />
                                                                                 </div>
                                                                               </div> -->
                                                               </div>
                                                               <!-- <div></div> -->
                                                            </div>
                                                         </div>
                                                         <div id="svgeditor" class="svgeditor">
               
                                                            <div id="svg_editor">
                                                               <div id="rulers">
                                                                  <div id="ruler_corner"></div>
                                                                  <div id="ruler_x">
                                                                     <div id="ruler_x_cursor"></div>
                                                                     <div>
                                                                        <canvas height="15"></canvas>
                                                                     </div>
                                                                  </div>
                                                                  <div id="ruler_y">
                                                                     <div id="ruler_y_cursor"></div>
                                                                     <div>
                                                                        <canvas width="15"></canvas>
                                                                     </div>
                                                                  </div>
                                                               </div>
               
                                                               <div id="workarea">
                                                                  <div id="svgcanvas" style="position:relative">
               
                                                                  </div>
                                                               </div>
               
                                                               <div id="menu_bar">
               
                                                                  <div class="menu">
                                                                     <div id="file_menu">
                                                                        <div class="menu_title">
                                                                           <!-- <div id="tool_open" class="menu_item" style="display: none;" title="Open">
                                                                                       <div id="fileinputs"></div><img src="../../../assets/dist/images/folder.png" />
                                                                                     </div> -->
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div id="" class="menu_item" title="Export as SVG">
                                                                              <img (click)="savesend_data()"
                                                                                 src="../../../assets/img/save.svg" style="width:20px"
                                                                                 style="width:20px" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div id="tool_export" class="menu_item"
                                                                              title="Export as PNG">
                                                                              <img src="../../../assets/dist/images/png.png" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div id="tool_clear" class="menu_item" title="Clear"><img
                                                                                 src="../../../assets/dist/images/cancel.png" />
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="menu">
                                                                     <div id="edit_menu">
                                                                        <div class="menu_title">
                                                                           <div class="menu_item" id="tool_undo" title="Undo"><img
                                                                                 src="../../../assets/dist/images/undo.png" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div class="menu_item" id="tool_redo" title="Redo"><img
                                                                                 src="../../../assets/dist/images/redo.png" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div class="menu_item action_selected disabled"
                                                                              id="tool_cut" title="Cut"><img
                                                                                 src="../../../assets/dist/images/scissors.png" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div class="menu_item action_selected disabled"
                                                                              id="tool_copy" title="Copy"><img
                                                                                 src="../../../assets/dist/images/copy.png" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div class="menu_item action_selected disabled"
                                                                              id="tool_paste" title="Paste"><img
                                                                                 src="../../../assets/dist/images/paste.png" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div class="menu_item action_selected disabled"
                                                                              id="tool_clone" title="Clone"><img
                                                                                 src="../../../assets/dist/images/rubber-stamp.png" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div class="menu_item action_selected disabled"
                                                                              id="tool_delete" title="Delete"><img
                                                                                 src="../../../assets/dist/images/delete.png" />
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="menu">
                                                                     <div id="view_menu">
                                                                        <div class="menu_title">
                                                                           <div class="menu_item push_button_pressed" id="tool_rulers"
                                                                              title="View Rulers"><img
                                                                                 src="../../../assets/dist/images/ruler.png" />
                                                                           </div>
                                                                        </div>
                                                                        <div class="menu_title">
                                                                           <div class="menu_item" id="tool_wireframe"
                                                                              title="View Wireframe"><img
                                                                                 src="../../../assets/dist/images/wireframe.png" />
                                                                           </div>
                                                                        </div>
                                                                        <!-- <div class="menu_title">
                                                                                       <div class="menu_item" id="tool_snap">Snap to Grid</div>
                                                                                     </div> -->
                                                                     </div>
                                                                  </div>
                                                                  <div class="menu">
                                                                     <div class="menu_title">
                                                                        <!-- Zoom buttons -->
                                                                        <div id="zoom_panel" class="toolset" title="Change zoom level">
                                                                           <div class="draginput select" id="zoom_label">
                                                                              <span id="zoomLabel" class="zoom_tool icon_label">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg" height="24"
                                                                                    viewBox="2 2 20 20" width="27">
                                                                                    <path
                                                                                       d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                                                                 </svg>
                                                                              </span>
                                                                              <select id="zoom_select">
                                                                                 <option value="6">6%</option>
                                                                                 <option value="12">12%</option>
                                                                                 <option value="16">16%</option>
                                                                                 <option value="25">25%</option>
                                                                                 <option value="50">50%</option>
                                                                                 <option value="75">75%</option>
                                                                                 <option value="100" selected="selected">
                                                                                    100%</option>
                                                                                 <option value="150">150%
                                                                                 </option>
                                                                                 <option value="200">200%
                                                                                 </option>
                                                                                 <option value="300">300%
                                                                                 </option>
                                                                                 <option value="400">400%
                                                                                 </option>
                                                                                 <option value="600">600%
                                                                                 </option>
                                                                                 <option value="800">800%
                                                                                 </option>
                                                                                 <option value="1600">1600%
                                                                                 </option>
                                                                              </select>
                                                                              <div class="caret"></div>
                                                                              <input id="zoom" size="3" value="100%" type="text"
                                                                                 readonly="readonly" />
                                                                           </div>
                                                                        </div>
                                                                     </div>
               
                                                                  </div>
               
                                                                  <div>
                                                                     <div class="toggle_full_screen menu_title">
                                                                        <i class="fa fa-arrows-alt"></i>
                                                                     </div>
                                                                  </div>
                                                               </div>
               
                                                               <div id="tools_top" class="tools_panel">
               
                                                                  <div id="canvas_panel" class="context_panel">
               
                                                                     <h4 class="clearfix">Canvas</h4>
               
                                                                     <label data-title="Change canvas width">
                                                                        <input size="3" id="canvas_width" type="text"
                                                                           pattern="[0-9]*" />
                                                                        <span class="icon_label">Width</span>
                                                                     </label>
                                                                     <label data-title="Change canvas height">
                                                                        <input id="canvas_height" size="3" type="text"
                                                                           pattern="[0-9]*" />
                                                                        <span class="icon_label">Height</span>
                                                                     </label>
               
               
                                                                     <label data-title="Change canvas color" class="draginput">
                                                                        <span>Color</span>
                                                                        <div id="color_canvas_tools">
                                                                           <div class="color_tool active" id="tool_canvas">
                                                                              <div class="color_block">
                                                                                 <div id="canvas_bg"></div>
                                                                                 <div id="canvas_color"></div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </label>
               
                                                                     <div class="draginput">
                                                                        <span>Sizes</span>
                                                                        <select id="resolution">
                                                                           <option id="selectedPredefined" selected="selected">Custom
                                                                           </option>
                                                                           <option>640x480</option>
                                                                           <option>800x600</option>
                                                                           <option>1024x768</option>
                                                                           <option>1280x960</option>
                                                                           <option>1600x1200</option>
                                                                           <option id="fitToContent" value="content">Fit to
                                                                              Content</option>
                                                                        </select>
                                                                        <div class="caret"></div>
                                                                        <label id="resolution_label">Custom</label>
                                                                     </div>
               
                                                                  </div>
               
                                                                  <div id="rect_panel" class="context_panel">
                                                                     <h4 class="clearfix">Rectangle</h4>
                                                                     <label>
                                                                        <input id="rect_x" class="attr_changer"
                                                                           data-title="Change X coordinate" size="3" data-attr="x"
                                                                           pattern="[0-9]*" />
                                                                        <span>X</span>
                                                                     </label>
                                                                     <label>
                                                                        <input id="rect_y" class="attr_changer"
                                                                           data-title="Change Y coordinate" size="3" data-attr="y"
                                                                           pattern="[0-9]*" />
                                                                        <span>Y</span>
                                                                     </label>
                                                                     <label id="rect_width_tool attr_changer"
                                                                        data-title="Change rectangle width">
                                                                        <input id="rect_width" class="attr_changer" size="3"
                                                                           data-attr="width" type="text" pattern="[0-9]*" />
                                                                        <span class="icon_label">Width</span>
                                                                     </label>
                                                                     <label id="rect_height_tool" data-title="Change rectangle height">
                                                                        <input id="rect_height" class="attr_changer" size="3"
                                                                           data-attr="height" type="text" pattern="[0-9]*" />
                                                                        <span class="icon_label">Height</span>
                                                                     </label>
                                                                  </div>
               
                                                                  <div id="path_panel" class="context_panel clearfix">
                                                                     <h4 class="clearfix">Path</h4>
                                                                     <label>
                                                                        <input id="path_x" class="attr_changer"
                                                                           data-title="Change ellipse's cx coordinate" size="3"
                                                                           data-attr="x" pattern="[0-9]*" />
                                                                        <span>X</span>
                                                                     </label>
                                                                     <label>
                                                                        <input id="path_y" class="attr_changer"
                                                                           data-title="Change ellipse's cy coordinate" size="3"
                                                                           data-attr="y" pattern="[0-9]*" />
                                                                        <span>Y</span>
                                                                     </label>
                                                                  </div>
               
                                                                  <div id="image_panel" class="context_panel clearfix">
                                                                     <h4>Image</h4>
                                                                     <label>
                                                                        <input id="image_x" class="attr_changer"
                                                                           data-title="Change X coordinate" size="3" data-attr="x"
                                                                           pattern="[0-9]*" />
                                                                        <span>X</span>
                                                                     </label>
                                                                     <label>
                                                                        <input id="image_y" class="attr_changer"
                                                                           data-title="Change Y coordinate" size="3" data-attr="y"
                                                                           pattern="[0-9]*" />
                                                                        <span>Y</span>
                                                                     </label>
                                                                     <label>
                                                                        <input id="image_width" class="attr_changer"
                                                                           data-title="Change image width" size="3" data-attr="width"
                                                                           pattern="[0-9]*" />
                                                                        <span class="icon_label">Width</span>
                                                                     </label>
                                                                     <label>
                                                                        <input id="image_height" class="attr_changer"
                                                                           data-title="Change image height" size="3" data-attr="height"
                                                                           pattern="[0-9]*" />
                                                                        <span class="icon_label">Height</span>
                                                                     </label>
                                                                  </div>
               
                                                                  <div id="circle_panel" class="context_panel">
                                                                     <h4>Circle</h4>
                                                                     <label id="tool_circle_cx">
                                                                        <span>Center X</span>
                                                                        <input id="circle_cx" class="attr_changer"
                                                                           title="Change circle's cx coordinate" size="3"
                                                                           data-attr="cx" />
                                                                     </label>
                                                                     <label id="tool_circle_cy">
                                                                        <span>Center Y</span>
                                                                        <input id="circle_cy" class="attr_changer"
                                                                           title="Change circle's cy coordinate" size="3"
                                                                           data-attr="cy" />
                                                                     </label>
                                                                     <label id="tool_circle_r">
                                                                        <span>Radius</span>
                                                                        <input id="circle_r" class="attr_changer"
                                                                           title="Change circle's radius" size="3" data-attr="r" />
                                                                     </label>
                                                                  </div>
               
                                                                  <div id="ellipse_panel" class="context_panel clearfix">
                                                                     <h4>Ellipse</h4>
                                                                     <label id="tool_ellipse_cx">
                                                                        <input id="ellipse_cx" class="attr_changer"
                                                                           data-title="Change ellipse's cx coordinate" size="3"
                                                                           data-attr="cx" pattern="[0-9]*" />
                                                                        <span>X</span>
                                                                     </label>
                                                                     <label id="tool_ellipse_cy">
                                                                        <input id="ellipse_cy" class="attr_changer"
                                                                           data-title="Change ellipse's cy coordinate" size="3"
                                                                           data-attr="cy" pattern="[0-9]*" />
                                                                        <span>Y</span>
                                                                     </label>
                                                                     <label id="tool_ellipse_rx">
                                                                        <input id="ellipse_rx" class="attr_changer"
                                                                           data-title="Change ellipse's x radius" size="3"
                                                                           data-attr="rx" pattern="[0-9]*" />
                                                                        <span>Radius X</span>
                                                                     </label>
                                                                     <label id="tool_ellipse_ry">
                                                                        <input id="ellipse_ry" class="attr_changer"
                                                                           data-title="Change ellipse's y radius" size="3"
                                                                           data-attr="ry" pattern="[0-9]*" />
                                                                        <span>Radius Y</span>
                                                                     </label>
                                                                  </div>
               
                                                                  <div id="line_panel" class="context_panel clearfix">
                                                                     <h4>Line</h4>
                                                                     <label id="tool_line_x1">
                                                                        <input id="line_x1" class="attr_changer"
                                                                           data-title="Change line's starting x coordinate" size="3"
                                                                           data-attr="x1" pattern="[0-9]*" />
                                                                        <span>Start X</span>
                                                                     </label>
                                                                     <label id="tool_line_y1">
                                                                        <input id="line_y1" class="attr_changer"
                                                                           data-title="Change line's starting y coordinate" size="3"
                                                                           data-attr="y1" pattern="[0-9]*" />
                                                                        <span>Start Y</span>
                                                                     </label>
                                                                     <label id="tool_line_x2">
                                                                        <input id="line_x2" class="attr_changer"
                                                                           data-title="Change line's ending x coordinate" size="3"
                                                                           data-attr="x2" pattern="[0-9]*" />
                                                                        <span>End X</span>
                                                                     </label>
                                                                     <label id="tool_line_y2">
                                                                        <input id="line_y2" class="attr_changer"
                                                                           data-title="Change line's ending y coordinate" size="3"
                                                                           data-attr="y2" pattern="[0-9]*" />
                                                                        <span>End Y</span>
                                                                     </label>
                                                                  </div>
               
                                                                  <div id="text_panel" class="context_panel">
                                                                     <h4>Text</h4>
                                                                     <label>
                                                                        <input id="text_x" class="attr_changer"
                                                                           data-title="Change text x coordinate" size="3" data-attr="x"
                                                                           pattern="[0-9]*" />
                                                                        <span>X</span>
                                                                     </label>
                                                                     <label>
                                                                        <input id="text_y" class="attr_changer"
                                                                           data-title="Change text y coordinate" size="3" data-attr="y"
                                                                           pattern="[0-9]*" />
                                                                        <span>Y</span>
                                                                     </label>
               
                                                                     <div class="toolset draginput select twocol"
                                                                        id="tool_font_family">
                                                                        <!-- Font family -->
                                                                        <span>Font</span>
                                                                        <div id="preview_font"
                                                                           style="font-family: Helvetica, Arial, sans-serif;">
                                                                           Helvetica</div>
                                                                        <div class="caret"></div>
                                                                        <input id="font_family" data-title="Change Font Family"
                                                                           size="12" type="hidden" />
                                                                        <select id="font_family_dropdown">
                                                                           <option value="Arvo, sans-serif">Arvo
                                                                           </option>
                                                                           <option value="'Courier New', Courier, monospace">
                                                                              Courier</option>
                                                                           <option value="Euphoria, sans-serif">
                                                                              Euphoria
                                                                           </option>
                                                                           <option value="Georgia, Times, 'Times New Roman', serif">
                                                                              Georgia</option>
                                                                           <option value="Helvetica, Arial, sans-serif"
                                                                              selected="selected">Helvetica
                                                                           </option>
                                                                           <option value="Junction, sans-serif">
                                                                              Junction
                                                                           </option>
                                                                           <option value="'League Gothic', sans-serif">
                                                                              League Gothic</option>
                                                                           <option value="Oswald, sans-serif">
                                                                              Oswald
                                                                           </option>
                                                                           <option
                                                                              value="'Palatino Linotype', 'Book Antiqua', Palatino, serif">
                                                                              Palatino</option>
                                                                           <option value="'Trebuchet MS', Gadget, sans-serif">
                                                                              Trebuchet</option>
                                                                           <option value="'Shadows Into Light', serif">
                                                                              Shadows Into Light</option>
                                                                           <option value="'Simonetta', serif">
                                                                              Simonetta
                                                                           </option>
                                                                           <option value="'Times New Roman', Times, serif">
                                                                              Times</option>
                                                                        </select>
                                                                        <div class="tool_button" id="tool_bold"
                                                                           data-title="Bold Text [B]">B</div>
                                                                        <div class="tool_button" id="tool_italic"
                                                                           data-title="Italic Text [I]">i</div>
                                                                     </div>
               
                                                                     <label id="tool_font_size" data-title="Change Font Size">
                                                                        <input id="font_size" size="3" value="0" />
                                                                        <span id="font_sizeLabel" class="icon_label">Font
                                                                           Size</span>
                                                                     </label>
                                                                     <!-- Not visible, but still used -->
                                                                     <input id="text" type="text" size="35" />
                                                                  </div>
               
                                                                  <!-- formerly gsvg_panel -->
                                                                  <div id="container_panel" class="context_panel clearfix">
                                                                  </div>
               
                                                                  <div id="use_panel" class="context_panel clearfix">
                                                                     <div class="tool_button clearfix" id="tool_unlink_use"
                                                                        data-title="Break link to reference element (make unique)">
                                                                        Break link reference</div>
                                                                  </div>
               
                                                                  <div id="g_panel" class="context_panel clearfix">
                                                                     <h4>Group</h4>
                                                                     <label>
                                                                        <input id="g_x" class="attr_changer"
                                                                           data-title="Change groups's x coordinate" size="3"
                                                                           data-attr="x" pattern="[0-9]*" />
                                                                        <span>X</span>
                                                                     </label>
                                                                     <label>
                                                                        <input id="g_y" class="attr_changer"
                                                                           data-title="Change groups's y coordinate" size="3"
                                                                           data-attr="y" pattern="[0-9]*" />
                                                                        <span>Y</span>
                                                                     </label>
                                                                  </div>
               
                                                                  <div id="path_node_panel" class="context_panel clearfix">
                                                                     <h4>Edit Path</h4>
               
                                                                     <label id="tool_node_x">
                                                                        <input id="path_node_x" class="attr_changer"
                                                                           data-title="Change node's x coordinate" size="3"
                                                                           data-attr="x" />
                                                                        <span>X</span>
                                                                     </label>
                                                                     <label id="tool_node_y">
                                                                        <input id="path_node_y" class="attr_changer"
                                                                           data-title="Change node's y coordinate" size="3"
                                                                           data-attr="y" />
                                                                        <span>Y</span>
                                                                     </label>
               
                                                                     <div id="segment_type" class="draginput label">
                                                                        <span>Segment Type</span>
                                                                        <select id="seg_type" data-title="Change Segment type">
                                                                           <option id="straight_segments" selected="selected"
                                                                              value="4">
                                                                              Straight
                                                                           </option>
                                                                           <option id="curve_segments" value="6">
                                                                              Curve
                                                                           </option>
                                                                        </select>
                                                                        <div class="caret"></div>
                                                                        <label id="seg_type_label">Straight</label>
                                                                     </div>
               
                                                                     <!--
                                                                                   <label class="draginput checkbox" data-title="Link Control Points">
                                                                                     <span>Linked Control Points</span>
                                                                                     <div class="push_bottom"><input type="checkbox" id="tool_node_link" checked="checked" /></div>
                                                                                   </label>
                                                                                 -->
               
                                                                     <div class="clearfix"></div>
                                                                     <div class="tool_button" id="tool_node_clone" title="Adds a node">
                                                                        Add Node</div>
                                                                     <div class="tool_button" id="tool_node_delete"
                                                                        title="Delete Node">Delete Node</div>
                                                                     <div class="tool_button" id="tool_openclose_path"
                                                                        title="Open/close sub-path">Open Path
                                                                     </div>
                                                                     <!--<div class="tool_button" id="tool_add_subpath" title="Add sub-path"></div>-->
                                                                  </div>
               
                                                                  <!-- Buttons when a single element is selected -->
                                                                  <div id="selected_panel" class="context_panel">
               
                                                                     <label id="tool_angle" data-title="Change rotation angle"
                                                                        class="draginput">
                                                                        <input id="angle" class="attr_changer" size="2" value="0"
                                                                           data-attr="transform" data-min="-180" data-max="180"
                                                                           type="text" />
                                                                        <span class="icon_label">Rotation</span>
                                                                        <div id="tool_angle_indicator">
                                                                           <div id="tool_angle_indicator_cursor">
                                                                           </div>
                                                                        </div>
                                                                     </label>
               
                                                                     <label class="toolset" id="tool_opacity"
                                                                        data-title="Change selected item opacity">
                                                                        <input id="group_opacity" class="attr_changer"
                                                                           data-attr="opacity" data-multiplier="0.01" size="3"
                                                                           value="100" step="5" min="0" max="100" />
                                                                        <span id="group_opacityLabel" class="icon_label">Opacity</span>
                                                                     </label>
               
                                                                     <div class="toolset" id="tool_blur"
                                                                        data-title="Change gaussian blur value">
                                                                        <label>
                                                                           <input id="blur" size="2" value="0" step=".1" min="0"
                                                                              max="10" />
                                                                           <span class="icon_label">Blur</span>
                                                                        </label>
                                                                     </div>
               
                                                                     <label id="cornerRadiusLabel"
                                                                        data-title="Change Rectangle Corner Radius">
                                                                        <input id="rect_rx" size="3" value="0" data-attr="rx"
                                                                           class="attr_changer" type="text" pattern="[0-9]*" />
                                                                        <span class="icon_label">Roundness</span>
                                                                     </label>
               
                                                                     <div class="clearfix"></div>
                                                                     <div id="align_tools">
                                                                        <h4>Align</h4>
                                                                        <div class="toolset align_buttons" id="tool_position">
                                                                           <label>
                                                                              <div class="col last clear" id="position_opts">
                                                                                 <div class="draginput_cell" id="tool_posleft"
                                                                                    title="Align Left">
                                                                                    <svg viewBox="0 0 27 27"
                                                                                       xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                       height="27">
                                                                                       <path
                                                                                          d="M 2 1 L 2 5 L 14 5 L 14 11 L 2 11 L 2 16 L 20 16 L 20 22 L 2 22 L 2 26 L 1 26 L 1 1 L 2 1 Z" />
                                                                                    </svg>
                                                                                 </div>
                                                                                 <div class="draginput_cell" id="tool_poscenter"
                                                                                    title="Align Center">
                                                                                    <svg viewBox="0 0 27 27"
                                                                                       xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                       height="27">
                                                                                       <path
                                                                                          d="M 13 1 L 14 1 L 14 6 L 22 6 L 22 12 L 14 12 L 14 15 L 19 15 L 19 21 L 14 21 L 14 26 L 13 26 L 13 21 L 8 21 L 8 15 L 13 15 L 13 12 L 5 12 L 5 6 L 13 6 L 13 1 Z" />
                                                                                    </svg>
                                                                                 </div>
                                                                                 <div class="draginput_cell" id="tool_posright"
                                                                                    title="Align Right">
                                                                                    <svg viewBox="0 0 27 27"
                                                                                       xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                       height="27">
                                                                                       <path
                                                                                          d="M 25 1 L 25 5 L 13 5 L 13 11 L 25 11 L 25 16 L 7 16 L 7 22 L 25 22 L 25 26 L 26 26 L 26 1 L 25 1 Z" />
                                                                                    </svg>
                                                                                 </div>
                                                                                 <div class="draginput_cell" id="tool_postop"
                                                                                    title="Align Top">
                                                                                    <svg viewBox="0 0 27 27"
                                                                                       xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                       height="27">
                                                                                       <path
                                                                                          d="M 1 2 L 5 2 L 5 14 L 11 14 L 11 2 L 16 2 L 16 20 L 22 20 L 22 2 L 26 2 L 26 1 L 1 1 L 1 2 Z" />
                                                                                    </svg>
                                                                                 </div>
                                                                                 <div class="draginput_cell" id="tool_posmiddle"
                                                                                    title="Align Middle">
                                                                                    <svg viewBox="0 0 27 27"
                                                                                       xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                       height="27">
                                                                                       <path
                                                                                          d="M 26 13 L 26 14 L 21 14 L 21 22 L 15 22 L 15 14 L 12 14 L 12 19 L 6 19 L 6 14 L 1 14 L 1 13 L 6 13 L 6 8 L 12 8 L 12 13 L 15 13 L 15 5 L 21 5 L 21 13 L 26 13 Z" />
                                                                                    </svg>
                                                                                 </div>
                                                                                 <div class="draginput_cell" id="tool_posbottom"
                                                                                    title="Align Bottom">
                                                                                    <svg viewBox="0 0 27 27"
                                                                                       xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                       height="27">
                                                                                       <path
                                                                                          d="M 1 25 L 5 25 L 5 13 L 11 13 L 11 25 L 16 25 L 16 7 L 22 7 L 22 25 L 26 25 L 26 26 L 1 26 L 1 25" />
                                                                                    </svg>
                                                                                 </div>
                                                                              </div>
                                                                           </label>
                                                                        </div>
                                                                     </div>
                                                                  </div>
               
                                                                  <!-- Buttons when multiple elements are selected -->
                                                                  <div id="multiselected_panel" class="context_panel clearfix">
                                                                     <h4 class="hidable">Multiple Elements</h4>
               
                                                                     <div class="toolset align_buttons" style="position: relative">
                                                                        <label id="tool_align_relative" style="margin-top: 10px;">
                                                                           <div class="select-input">
                                                                              <select id="align_relative_to"
                                                                                 title="Align relative to ..." class="select-input">
                                                                                 <option id="selected_objects" value="selected">Align
                                                                                    to
                                                                                    objects
                                                                                 </option>
                                                                                 <option id="page" value="page">
                                                                                    Align to
                                                                                    page</option>
                                                                              </select>
                                                                           </div>
                                                                        </label>
                                                                        <!-- <h4>.</h4> -->
                                                                        <div class="col last clear" id="align_opts">
                                                                           <div class="draginput_cell p-0" id="tool_alignleft"
                                                                              title="Align Left">
                                                                              <svg viewBox="0 0 27 27"
                                                                                 xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                 height="27">
                                                                                 <path
                                                                                    d="M 2 1 L 2 5 L 14 5 L 14 11 L 2 11 L 2 16 L 20 16 L 20 22 L 2 22 L 2 26 L 1 26 L 1 1 L 2 1 Z" />
                                                                              </svg>
                                                                           </div>
                                                                           <div class="draginput_cell" id="tool_aligncenter"
                                                                              title="Align Center">
                                                                              <svg viewBox="0 0 27 27"
                                                                                 xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                 height="27">
                                                                                 <path
                                                                                    d="M 13 1 L 14 1 L 14 6 L 22 6 L 22 12 L 14 12 L 14 15 L 19 15 L 19 21 L 14 21 L 14 26 L 13 26 L 13 21 L 8 21 L 8 15 L 13 15 L 13 12 L 5 12 L 5 6 L 13 6 L 13 1 Z" />
                                                                              </svg>
                                                                           </div>
                                                                           <div class="draginput_cell" id="tool_alignright"
                                                                              title="Align Right">
                                                                              <svg viewBox="0 0 27 27"
                                                                                 xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                 height="27">
                                                                                 <path
                                                                                    d="M 25 1 L 25 5 L 13 5 L 13 11 L 25 11 L 25 16 L 7 16 L 7 22 L 25 22 L 25 26 L 26 26 L 26 1 L 25 1 Z" />
                                                                              </svg>
                                                                           </div>
                                                                           <div class="draginput_cell" id="tool_aligntop"
                                                                              title="Align Top">
                                                                              <svg viewBox="0 0 27 27"
                                                                                 xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                 height="27">
                                                                                 <path
                                                                                    d="M 1 2 L 5 2 L 5 14 L 11 14 L 11 2 L 16 2 L 16 20 L 22 20 L 22 2 L 26 2 L 26 1 L 1 1 L 1 2 Z" />
                                                                              </svg>
                                                                           </div>
                                                                           <div class="draginput_cell" id="tool_alignmiddle"
                                                                              title="Align Middle">
                                                                              <svg viewBox="0 0 27 27"
                                                                                 xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                 height="27">
                                                                                 <path
                                                                                    d="M 26 13 L 26 14 L 21 14 L 21 22 L 15 22 L 15 14 L 12 14 L 12 19 L 6 19 L 6 14 L 1 14 L 1 13 L 6 13 L 6 8 L 12 8 L 12 13 L 15 13 L 15 5 L 21 5 L 21 13 L 26 13 Z" />
                                                                              </svg>
                                                                           </div>
                                                                           <div class="draginput_cell" id="tool_alignbottom"
                                                                              title="Align Bottom">
                                                                              <svg viewBox="0 0 27 27"
                                                                                 xmlns="http://www.w3.org/2000/svg" width="27"
                                                                                 height="27">
                                                                                 <path
                                                                                    d="M 1 25 L 5 25 L 5 13 L 11 13 L 11 25 L 16 25 L 16 7 L 22 7 L 22 25 L 26 25 L 26 26 L 1 26 L 1 25" />
                                                                              </svg>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="clearfix"></div>
               
                                                                  </div>
               
                                                                  <div id="stroke_panel" class="context_panel clearfix">
                                                                     <div class="clearfix"></div>
                                                                     <h4>Stroke</h4>
                                                                     <div class="toolset" data-title="Change stroke">
                                                                        <label>
                                                                           <input id="stroke_width" size="2" value="5"
                                                                              data-attr="stroke-width" min="0" max="99" step="1" />
                                                                           <span class="icon_label">Width</span>
                                                                        </label>
                                                                     </div>
                                                                     <div class="stroke_tool draginput">
                                                                        <span>Dash</span>
                                                                        <select id="stroke_style"
                                                                           data-title="Change stroke dash style">
                                                                           <option selected="selected" value="none">—
                                                                           </option>
                                                                           <option value="2,2">···</option>
                                                                           <option value="5,5">- -</option>
                                                                           <option value="5,2,2,2">-·-</option>
                                                                           <option value="5,2,2,2,2,2">-··-
                                                                           </option>
                                                                        </select>
                                                                        <div class="caret"></div>
                                                                        <label id="stroke_style_label">—</label>
                                                                     </div>
               
                                                                     <label style="display: none;">
                                                                        <span class="icon_label">Stroke Join</span>
                                                                     </label>
               
                                                                     <label style="display: none;">
                                                                        <span class="icon_label">Stroke Cap</span>
                                                                     </label>
                                                                  </div>
               
                                                               </div> <!-- tools_top -->
                                                               <div id="cur_context_panel">
               
                                                               </div>
               
                                                               <div id="tools_left" class="tools_panel">
                                                                  <div class="tool_button" id="tool_select" title="Select Tool [V]">
                                                                     <svg viewBox="0 0 24 24" width="24" height="24">
                                                                        <path
                                                                           d="M17.15 20.76l-2.94 1.5-3.68-6-4.41 3V1.24l12.5 12.01-4.41 1.5 2.94 6z" />
                                                                     </svg>
                                                                  </div>
                                                                  <div class="tool_button" id="tool_fhpath" title="Pencil Tool [P]">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" height="24"
                                                                        viewBox="0 0 24 24" width="24" style="transform: scale(-1,1)">
                                                                        <path
                                                                           d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                                                                     </svg>
                                                                  </div>
                                                                  <div class="tool_button" id="tool_line" title="Line Tool [L]">
                                                                     <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                        width="24" height="24">
                                                                        <path d="M 3 1 L 26 24 L 24 26 L 1 3 L 3 1 Z">
                                                                        </path>
                                                                     </svg>
                                                                  </div>
                                                                  <div class="tool_button" id="tool_rect" title="Square/Rect Tool [R]">
                                                                     <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                        width="24" height="24">
                                                                        <path d="M 0 8 L 0 24 L 24 24 L 25 8 L 0 8 Z" />
                                                                     </svg>
                                                                  </div>
                                                                  <div class="tool_button" id="tool_ellipse"
                                                                     title="Ellipse/Circle Tool [C]">
                                                                     <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                        width="24" height="24">
                                                                        <ellipse cx="13" cy="13" rx="13" ry="9">
                                                                        </ellipse>
                                                                     </svg>
                                                                  </div>
                                                                  <div class="tool_button" id="tool_path" title="Path Tool [P]">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"
                                                                        width="27" height="27">
                                                                        <path
                                                                           d="M12.2 1.9c0-.36.86 0 .86 0V14a1.3 1.3 0 10.88 0V1.9s.87-.36.87 0c0 6.81 5.22 11.68 5.22 11.68l-3.25 8.2h-6.55l-3.26-8.2s5.22-4.87 5.22-11.68zM7.83 25.26v-2.61h11.32v2.6H7.84z" />
                                                                     </svg>
                                                                  </div>
                                                                  <div class="tool_button" id="tool_text" title="Text Tool [T]">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" height="24"
                                                                        viewBox="2 2 20 20" width="27" height="27">
                                                                        <path d="M5 4v3h5.5v12h3V7H19V4z" />
                                                                     </svg>
                                                                  </div>
               
                                                                  <div class="tool_button" id="tool_zoom" title="Zoom Tool [Z]">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" height="24"
                                                                        viewBox="2 2 20 20" width="27">
                                                                        <path
                                                                           d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                                                     </svg>
                                                                  </div>
               
                                                                  <div class="tool_button" id="tool_eyedropper"
                                                                     title="Eyedropper Tool [Z]">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" height="24"
                                                                        viewBox="2 2 20 20" width="27" style="transform: scale(-1, 1)">
                                                                        <path
                                                                           d="M20.71 5.63l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42L3 16.25V21h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42zM6.92 19L5 17.08l8.06-8.06 1.92 1.92L6.92 19z" />
                                                                     </svg>
                                                                  </div>
               
                                                                  <div id="color_tools">
                                                                     <div id="tool_switch" title="Switch stroke and fill colors [X]">
                                                                     </div>
                                                                     <div class="color_tool active" id="tool_fill">
                                                                        <label class="icon_label" title="Change fill color"></label>
                                                                        <div class="color_block">
                                                                           <div id="fill_bg"></div>
                                                                           <div id="fill_color" class="color_block"></div>
                                                                        </div>
                                                                     </div>
               
                                                                     <div class="color_tool" id="tool_stroke">
                                                                        <label class="icon_label" title="Change stroke color"></label>
                                                                        <div class="color_block">
                                                                           <div id="stroke_bg"></div>
                                                                           <div id="stroke_color" class="color_block"
                                                                              title="Change stroke color"></div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div> <!-- tools_left -->
               
                                                               <div id="tools_bottom" class="tools_panel">
                                                                  <div id="palette"
                                                                     title="Click to change fill color, shift-click to change stroke color">
                                                                  </div>
                                                               </div>
               
                                                               <!-- hidden divs -->
                                                               <div id="color_picker"></div>
               
                                                            </div> <!-- svg_editor -->
               
                                                            <div id="svg_source_editor">
                                                               <div id="svg_source_overlay"></div>
                                                               <div id="svg_source_container">
                                                                  <div id="save_output_btns">
                                                                     <p id="copy_save_note">Copy the contents of this
                                                                        box
                                                                        into a text editor, then save the file
                                                                        with a .svg extension.</p>
                                                                     <button id="copy_save_done">Done</button>
                                                                  </div>
                                                                  <form>
                                                                     <textarea id="svg_source_textarea" spellcheck="false"></textarea>
                                                                  </form>
                                                                  <div id="tool_source_back" class="toolbar_button">
                                                                     <button id="tool_source_cancel" class="cancel">Cancel</button>
                                                                     <button id="tool_source_save" class="ok">Apply
                                                                        Changes</button>
                                                                  </div>
                                                               </div>
                                                            </div>
               
                                                            <div id="dialog_box">
                                                               <div id="dialog_box_overlay"></div>
                                                               <div id="dialog_container">
                                                                  <div id="dialog_content"></div>
                                                                  <div id="dialog_buttons"></div>
                                                               </div>
                                                            </div>
               
                                                            <div class="tools_flyout" id="tools_shapelib">
                                                               <div id="shape_buttons"></div>
                                                            </div>
                                                         </div>
               
                                                         <ul id="cmenu_canvas" class="contextMenu">
                                                            <li><a data-href="#cut">Cut <span class="shortcut">⌘X;</span></a>
                                                            </li>
                                                            <li><a data-href="#copy">Copy<span class="shortcut">⌘C</span></a>
                                                            </li>
                                                            <li><a data-href="#paste">Paste<span class="shortcut">⌘V</span></a>
                                                            </li>
                                                            <li class="separator"><a data-href="#delete">Delete<span
                                                                     class="shortcut">⌫</span></a></li>
                                                            <li class="separator"><a data-href="#group">Group<span
                                                                     class="shortcut">⌘G</span></a></li>
                                                            <li><a data-href="#ungroup">Ungroup<span class="shortcut">⌘⇧G</span></a>
                                                            </li>
                                                            <li class="separator"><a data-href="#move_front">Bring to
                                                                  Front<span class="shortcut">⌘⇧↑</span></a>
                                                            </li>
                                                            <li><a data-href="#move_up">Bring Forward<span
                                                                     class="shortcut">⌘↑</span></a></li>
                                                            <li><a data-href="#move_down">Send Backward<span
                                                                     class="shortcut">⌘↓</span></a></li>
                                                            <li><a data-href="#move_back">Send to Back<span
                                                                     class="shortcut">⌘⇧↓</span></a></li>
                                                         </ul>
                                                         <img id="theimage" hidden />
                                                      </mdb-card-body>
                                                   </mdb-card>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </mat-expansion-panel>
                                 </mat-accordion>
                                 <mat-accordion class="mataccordion" [hidden]="other_hidden">
                                    <mat-expansion-panel class="expandpanel">
                                       <mat-expansion-panel-header class="exppanel width">
                                          <mat-panel-title class="title">
                                             Other profile details
                                          </mat-panel-title>
                                       </mat-expansion-panel-header>
                                       <hr class="hr">
                                       <div class="row pad_top_15">
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">Blood group</mat-label>
                                             <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_blood" matInput />
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">Father / Guardian Name
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_fg_name" maxlength="50"
                                                   matInput />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">Occupation
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_occupation"
                                                   maxlength="25" matInput />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">Address1
                                                <input type="text" required class="ipcss noappt_width" [(ngModel)]="clnt_address1"
                                                   [disabled]="user_type" matInput />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">Address2
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_address2"
                                                   [disabled]="user_type" matInput />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                             <mat-label class="matlabel">Country
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_country"
                                                   [disabled]="user_type" [hidden]="exist_user" matInput />
                                                <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clnt_country"
                                                   [hidden]="new_user" (ngModelChange)="countryChange(clnt_country, '1')">
                                                   <option Selected>Select</option>
                                                   <option *ngFor="let country of clnt_country_list" value="{{country.description}}">
                                                      {{country.description}}</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                             <mat-label class="matlabel">State
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_state"
                                                   [disabled]="user_type" [hidden]="exist_user" matInput />
                                                <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clnt_state"
                                                   [hidden]="new_user" (ngModelChange)="stateChange(clnt_state, '1')">
                                                   <option Selected>Select</option>
                                                   <option *ngFor="let state of clnt_state_list" value="{{state.description}}">
                                                      {{state.description}}</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                             <mat-label class="matlabel">District / City
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_city"
                                                   [disabled]="user_type" [hidden]="exist_user" matInput />
                                                <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clnt_city"
                                                   [hidden]="new_user" (ngModelChange)="cityChange(clnt_city, '1')">
                                                   <option Selected>Select</option>
                                                   <option *ngFor="let city of clnt_city_list" value="{{city.description}}">
                                                      {{city.description}}</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">Location
                                                <input type="text" class="ipcss noappt_width" [disabled]="user_type"
                                                   [(ngModel)]="clnt_location" (keyup)="location_change(clnt_location)" matInput
                                                   [matAutocomplete]="auto" required />
                                                <mat-autocomplete #auto="matAutocomplete" [hidden]="new_user">
                                                   <mat-option (click)="location_selected(location)"
                                                      *ngFor="let location of clnt_location_list" value="{{location.description}}">
                                                      {{location.description}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">Zipcode
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_zipcode"
                                                   [disabled]="user_type" matInput />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">Referred by
                                                <input type="text" class="ipcss noappt_width" maxlength="50" [(ngModel)]="ref_doc"
                                                   (keyup)="Get_referal($event,'by')" aria-label="Number" matInput
                                                   [matAutocomplete]="auto3" />
                                                <mat-autocomplete #auto3="matAutocomplete">
                                                   <mat-option id="optionfont" *ngFor="let item of Refered_doctor"
                                                      (click)="SelectedRefDoctor(item)" value="{{item}}">
                                                      {{item}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </mat-label>
                                          </div>
               
                                       </div>
                                    </mat-expansion-panel>
                                 </mat-accordion>
                                 <mat-accordion class="mataccordion" [hidden]="other_hidden">
                                    <mat-expansion-panel class="expandpanel">
                                       <mat-expansion-panel-header class="exppanel width">
                                          <mat-panel-title class="title">
                                             Vitals & COVID
                                          </mat-panel-title>
                                       </mat-expansion-panel-header>
                                       <hr class="hr">
                                       <div class="row pad_top_15">
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel"><b>has COVID-19 symptoms?</b>
                                                <select disableOptionCentering class="ipcss noappt_width" name="Relationship"
                                                   [(ngModel)]="covid_type" (ngModelChange)="covidtypeSelect()">
                                                   <option value="yes">Yes</option>
                                                   <option value="no">No</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div [hidden]="!covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">Duration
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="covid_duration" matInput />
                                             </mat-label>
                                          </div>
                                          <div [hidden]="!covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel"><b>Symptoms</b></mat-label>
                                             <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;">
                                                <table style="margin-top: -5px;">
                                                   <tr *ngFor="let symptoms of covidSympList">
                                                      <td class="test">
                                                         <mat-checkbox color="primary"
                                                            (change)="symptom_click($event.checked,symptoms.symp_id)"
                                                            [(ngModel)]="symptoms.checked"></mat-checkbox>
                                                      </td>
                                                      <td style="text-align: left !important;font-size: 12px;">
                                                         <span>{{symptoms.symp_desc}}</span>
                                                      </td>
                                                   </tr>
                                                </table>
                                             </div>
                                          </div>
               
                                          <div [hidden]="!covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel"><b>Comorbities</b></mat-label>
                                             <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;">
                                                <table style="margin-top: -5px;">
                                                   <tr *ngFor="let comorb of comorbList">
                                                      <td class="test">
                                                         <mat-checkbox color="primary"
                                                            (change)="comorb_click($event.checked,comorb.comorb_id)"
                                                            [(ngModel)]="comorb.checked"></mat-checkbox>
                                                      </td>
                                                      <td style="text-align: left !important;font-size: 12px;">
                                                         <span>{{comorb.comorb_desc}}</span>
                                                      </td>
                                                   </tr>
                                                </table>
                                             </div>
                                          </div>
               
                                          <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel">Height
                                                <input matInput class="ipcss noappt_width" (change)="calories_data()"
                                                   maxlength="{{hmeasure == 'cms' && (3) || (5)}}"
                                                   onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                   [(ngModel)]="height_txt">
                                             </mat-label>
                                          </div>
                                          <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 select_bottom">
                                             <mat-label class="matlabel">Measure
                                                <select class="ipcss noappt_width" (change)="calories_data()" [(ngModel)]="hmeasure"
                                                   disableOptionCentering>
                                                   <option selected value="cms">cms</option>
                                                   <option value="inch">inch</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel">Weight
                                                <input matInput class="ipcss noappt_width" (change)="calories_data()" maxlength="5"
                                                   onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                   [(ngModel)]="weight_txt">
                                             </mat-label>
                                          </div>
                                          <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 select_bottom">
                                             <mat-label class="matlabel">Measure
                                                <select class="ipcss noappt_width" (change)="calories_data()" [(ngModel)]="wmeasure"
                                                   disableOptionCentering>
                                                   <option selected value="kgs">kgs</option>
                                                   <option value="lbs">lbs</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel">BMI
                                                <input matInput class="ipcss noappt_width" [(ngModel)]="bmi_txt" disabled>
                                             </mat-label>
                                          </div>
                                          <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel">Temperature
                                                <input matInput class="ipcss noappt_width" [required]="tempreq_flag"
                                                   [(ngModel)]="temp_txt">
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">Blood pressure</mat-label>
                                             <div class="row">
                                                <div class="col-6">
                                                   <input matInput class="ipcss noappt_width" [required]="bp_01req_flag"
                                                      placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_01"
                                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </div>
                                                <div class="col-6" style="position: relative;">
                                                   <span class="slash_icon">/</span>
                                                   <input matInput class="ipcss noappt_width" [required]="bp_02req_flag"
                                                      placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_02"
                                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel">Saturation(SpO2)
                                                <input matInput class="ipcss noappt_width" [required]="spo2req_flag"
                                                   [(ngModel)]="spo_txt">
                                             </mat-label>
                                          </div>
                                          <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel">Pulse
                                                <input matInput class="ipcss noappt_width" [required]="pulsereq_flag"
                                                   [(ngModel)]="pulse_txt">
                                             </mat-label>
                                          </div>
                                          <div [hidden]="ortho_flag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel">CVS
                                                <input matInput class="ipcss noappt_width" [(ngModel)]="cvs_txt">
                                             </mat-label>
                                          </div>
                                          <div [hidden]="ortho_flag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel" [hidden]="ortho_flag">RS
                                                <input matInput class="ipcss noappt_width" [(ngModel)]="rs_txt">
                                             </mat-label>
                                          </div>
                                          <div [hidden]="ortho_flag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel" [hidden]="ortho_flag">Kidneys
                                                <input matInput class="ipcss noappt_width" [(ngModel)]="kidney_txt">
                                             </mat-label>
                                          </div>
                                          <div [hidden]="ortho_flag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 select_bottom">
                                             <mat-label class="matlabel" [hidden]="ortho_flag">Type of arthritis
                                                <select id="genderid" disableOptionCentering class="ipcss noappt_width"
                                                   [(ngModel)]="arthritis_type">
                                                   <option value="Seropositive R.A">Seropositive R.A</option>
                                                   <option value="Seronegative R.A">Seronegative R.A</option>
                                                   <option value="Monoarticular R.A">Monoarticular R.A</option>
                                                   <option value="Polyarticular R.A">Polyarticular R.A</option>
                                                   <option value="Reactive-A">Reactive-A</option>
                                                   <option value="Gout">Gout</option>
                                                </select>
                                             </mat-label>
                                          </div>
               
                                          <div [hidden]="ortho_flag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 select_bottom">
                                             <mat-label class="matlabel" [hidden]="ortho_flag">Surgery type
                                                <select id="genderid" disableOptionCentering class="ipcss noappt_width"
                                                   [(ngModel)]="surgery_type">
                                                   <option disabled>Select</option>
                                                   <option value="Invasive">Invasive</option>
                                                   <option value="Non-Invasive">Non-Invasive</option>
                                                   <option value="Emergency">Emergency</option>
                                                </select>
                                             </mat-label>
                                          </div>
               
                                          <div [hidden]="ortho_flag" class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                                             <mat-label class="matlabel" [hidden]="ortho_flag">Deformities
                                                <textarea class="ipcss" [(ngModel)]="deformities"></textarea>
                                             </mat-label>
                                          </div>
               
                                          <div [hidden]="pulm_flag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel" [hidden]="pulm_flag">PFT
                                                <input matInput class="ipcss noappt_width" [(ngModel)]="pft_txt">
                                             </mat-label>
                                          </div>
               
                                          <div [hidden]="!covid_flag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel">Respiratory rate
                                                <input matInput class="ipcss noappt_width" [required]="respratereq_flag"
                                                   [(ngModel)]="resp_txt">
                                             </mat-label>
                                          </div>
               
                                          <div [hidden]="!covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">CT Scan report</mat-label>
                                             <div class="row">
                                                <div [hidden]="blood_preshidden" class="col-6">
                                                   <input type="text" class="ipcss noappt_width " [required]="ctscan1ereq_flag"
                                                      placeholder="0-25" maxlength="3" [(ngModel)]="scan_01"
                                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
               
                                                </div>
                                                <div [hidden]="blood_preshidden" class="col-6">
                                                   <select disableOptionCentering class="ipcss noappt_width"
                                                      [required]="ctscan2ereq_flag" [(ngModel)]="scan_02">
                                                      <option value="5">5</option>
                                                      <option value="10">10</option>
                                                      <option value="15">15</option>
                                                      <option value="20">20</option>
                                                      <option value="25">25</option>
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div [hidden]="!covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                             <mat-label class="matlabel">RT-PCR result
                                                <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="rtpcr_result">
                                                   <option value="Negative">Negative</option>
                                                   <option value="Positive">Positive</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                       </div>
                                       <div [hidden]="!covid_flag" class="row">
                                          <div class="col-12" style="padding: 12px 16px;">
                                             <img style="float: right;margin-bottom: 10pz;"
                                                src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                                (click)="addObservations()" />
                                          </div>
                                       </div>
                                       <div class="row" [hidden]="!covid_flag">
                                          <div class="col-12">
                                             <div class="table-responsive" style="border: none;" [hidden]="observetable">
                                                <table *ngIf="observList.length != 0" id="card_tbl"
                                                   class="table table-hover table-dynamic" cellpadding="5" cellspacing="0">
                                                   <tr>
                                                      <th>Temperature</th>
                                                      <th>Blood pressure</th>
                                                      <th>Saturation</th>
                                                      <th>pulse</th>
                                                      <th>Respiratory rate</th>
                                                      <th>RT-PCR result</th>
                                                   </tr>
                                                   <tr *ngFor="let observ of observList">
                                                      <td>{{observ.temp}}</td>
                                                      <td>{{observ.bp}}</td>
                                                      <td>{{observ.sop2}}</td>
                                                      <td>{{observ.pulse}}</td>
                                                      <td>{{observ.resprate}}</td>
                                                      <td>{{observ.rtpcr}}</td>
                                                   </tr>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </mat-expansion-panel>
                                 </mat-accordion>
                                 <mat-accordion class="mataccordion" [hidden]="other_hidden">
                                    <mat-expansion-panel class="expandpanel">
                                       <mat-expansion-panel-header class="exppanel width">
                                          <mat-panel-title class="title">
                                             Other Observations
                                          </mat-panel-title>
                                       </mat-expansion-panel-header>
                                       <hr class="hr">
                                       <div class="row">
                                          <div [hidden]="covid_flag" class="col-md-6 col-lg-6 col-sm-12 col-xl-6"
                                             style="margin-top: 10px;">
                                             <mat-label class="matlabel">Chief complaints
                                                <!-- <textarea class="ipcss noappt_width" [(ngModel)]="chief_comp"></textarea> -->
                                                <quill-editor #editor_chief id="editor_chief" [(ngModel)]="chief_comp"
                                                   [style]="editorStyle" [modules]="modules" placeholder="Enter Text"
                                                   (onContentChanged)="onContentChanged_chief_comp($event)">
                                                </quill-editor>
                                                <div class="auto_complete_text1" *ngIf="chiefComplaintsList.length != 0">
                                                   <ul *ngFor="let chiefComplaints of chiefComplaintsList">
                                                      <li>
                                                         <a (click)="select_chiefComplaints(chiefComplaints)">{{chiefComplaints}}</a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </mat-label>
                                             <!-- <div hidden [innerHtml]="chief_comp1"></div> -->
                                          </div>
                                          <div [hidden]="covid_flag" class="col-md-6 col-lg-6 col-sm-12 col-xl-6"
                                             style="margin-top: 10px;">
                                             <mat-label class="matlabel">Present illness
                                                <!-- <textarea class="ipcss noappt_width" [(ngModel)]="pres_ill"></textarea> -->
                                                <quill-editor id="editor_pres" [(ngModel)]="pres_ill" [style]="editorStyle"
                                                   [modules]="modules" placeholder="Enter Text"
                                                   (onContentChanged)="onContentChanged_pres_ill($event)"></quill-editor>
                                                <div class="auto_complete_text2" *ngIf="presentIllenessList.length != 0">
                                                   <ul *ngFor="let presentIlleness of presentIllenessList">
                                                      <li>
                                                         <a (click)="select_presentIlleness(presentIlleness)">{{presentIlleness}}</a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </mat-label>
                                          </div>
                                          <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6" style="margin-top: 15px;">
                                             <mat-label class="matlabel">Past Medical History
                                                <!-- <textarea class="ipcss noappt_width" [(ngModel)]="med_hist"></textarea> -->
                                                <quill-editor id="editor_med" [(ngModel)]="med_hist" [style]="editorStyle"
                                                   [modules]="modules" placeholder="Enter Text"
                                                   (onContentChanged)="onContentChanged_med_hist($event)"></quill-editor>
                                                <div class="auto_complete_text3" *ngIf="medicalHistoryList.length != 0">
                                                   <ul *ngFor="let medicalHistory of medicalHistoryList">
                                                      <li>
                                                         <a (click)="select_medicalHistory(medicalHistory)">{{medicalHistory}}</a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </mat-label>
                                          </div>
                                          <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6" style="margin-top: 15px;">
                                             <mat-label class="matlabel">Family history
                                                <!-- <textarea class="ipcss noappt_width" [(ngModel)]="family_hist"></textarea> -->
                                                <quill-editor id="editor" [(ngModel)]="family_hist" [style]="editorStyle"
                                                   [modules]="modules" placeholder="Enter Text"
                                                   (onContentChanged)="onContentChanged_family_hist($event)"></quill-editor>
                                                <div class="auto_complete_text4" *ngIf="familyHistoryList.length != 0">
                                                   <ul *ngFor="let familyHistory of familyHistoryList">
                                                      <li>
                                                         <a (click)="select_familyHistory(familyHistory)">{{familyHistory}}</a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </mat-label>
                                          </div>
                                          <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                                             <mat-label class="matlabel">Physical examination
                                                <quill-editor id="editor" [(ngModel)]="physical_exam" [style]="editorStyle"
                                                   [modules]="modules" placeholder="Enter Text"
                                                   (onContentChanged)="onContentChanged_physical_exam($event)"></quill-editor>
                                                <div class="auto_complete_text5" *ngIf="physicalExamList.length != 0">
                                                   <ul *ngFor="let physicalExam of physicalExamList">
                                                      <li>
                                                         <a (click)="select_physicalExam(physicalExam)">{{physicalExam}}</a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </mat-label>
                                          </div>
                                          <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                                             <mat-label class="matlabel">Treatment
                                                <!-- <textarea class="ipcss noappt_width" [(ngModel)]="treatment"></textarea> -->
                                                <quill-editor id="editor" [(ngModel)]="treatment" [style]="editorStyle"
                                                   [modules]="modules" placeholder="Enter Text"
                                                   (onContentChanged)="onContentChanged_treatment($event)"></quill-editor>
                                                <div class="auto_complete_text6" *ngIf="treatmentList.length != 0">
                                                   <ul *ngFor="let treatment of treatmentList">
                                                      <li>
                                                         <a (click)="select_treatment(treatment)">{{treatment}}</a>
                                                      </li>
                                                   </ul>
                                                </div>
                                             </mat-label>
                                          </div>
                                          <div class="col-12" [hidden]="historyFlag">
                                             <img (click)="history()" src="../../../assets/ui_icons/buttons/History_button.svg"
                                                class="saveimgbtn_inpatinfo" style="float: right;" />
                                          </div>
                                       </div>
                                       <p class="tervys_heading">Doctor referral</p>
                                       <div class="row">
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">Hospital Name
                                                <input type="text" class="ipcss noappt_width" maxlength="50" [(ngModel)]="hosp_name"
                                                   (keyup)="hospital_filter($event)" aria-label="Number" matInput
                                                   [matAutocomplete]="auto1" />
                                                <mat-autocomplete #auto1="matAutocomplete">
                                                   <mat-option id="optionfont" *ngFor="let item of filteredhospList"
                                                      (click)="select_hospital(item)" [value]="item">
                                                      {{item}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                             <mat-label class="matlabel">Speciality
                                                <select class="ipcss noappt_width" [(ngModel)]="speclization_txt">
                                                   <option *ngFor="let spl of spl_type_array" value="{{spl.spl_name}}">
                                                      {{spl.spl_name}}</option>
                                                </select>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">Referred to
                                                <input type="text" class="ipcss noappt_width" maxlength="50" [(ngModel)]="refer_txt"
                                                   (keyup)="Get_referal($event,'to')" matInput [matAutocomplete]="auto2" />
                                                <mat-autocomplete #auto2="matAutocomplete">
                                                   <mat-option id="optionfont" *ngFor="let item of Refered_doctor"
                                                      (click)="Select_refer_doctor(item)" [value]="item">
                                                      {{item}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </mat-label>
                                          </div>
                                       </div>
                                    </mat-expansion-panel>
                                 </mat-accordion>
                                 <mat-accordion class="mataccordion" [hidden]="other_hidden">
                                    <mat-expansion-panel class="expandpanel">
                                       <mat-expansion-panel-header class="exppanel width">
                                          <mat-panel-title class="title">
                                             Medical prescription
                                          </mat-panel-title>
                                       </mat-expansion-panel-header>
                                       <div style="position: absolute;right: 40px;top: 9px;font-weight: 600;font-family: arial;"> No
                                          Prescription
                                          <img *ngIf="noPres == true" (click)="noPrecription('no')"
                                             src="../../../assets/ui_icons/tick_mark.svg" width="12px"
                                             style="cursor: pointer;width: 15px;margin: 0 0px 0 9px;">
                                          <img *ngIf="noPres == false" (click)="noPrecription('yes')"
                                             src="../../../assets/ui_icons/cross_mark.svg" width="12px"
                                             style="cursor: pointer;width: 15px; margin: 0 0px 0 9px;">
                                       </div>
                                       <hr class="hr">
                                       <div class="row" *ngIf="noPres == false">
                                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                             <div class="cover_div">
                                                <div class="header_lable">
                                                   Investigation details
                                                </div>
                                                <div class="content_cover">
                                                   <div class="row">
                                                      <div class="col-12">
                                                         <mat-label class="matlabel">{{'Language'|translate}}<br>
                                                            <mat-select disableOptionCentering class="ipcss" [(ngModel)]="language"
                                                               (selectionChange)="languageChange()">
                                                               <mat-option value="English">English</mat-option>
                                                               <mat-option value="Tamil">Tamil</mat-option>
                                                               <mat-option value="Telugu">Telugu</mat-option>
                                                               <mat-option value="Kannada">Kannada</mat-option>
                                                               <mat-option value="Malayalam">Malayalam</mat-option>
                                                               <mat-option value="Oriya">Oriya</mat-option>
                                                               <mat-option value="Bengali">Bengali</mat-option>
                                                               <mat-option value="Hindi">Hindi</mat-option>
                                                            </mat-select>
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                         <mat-label class="matlabel">Diagnosis
                                                            <!-- <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_symptom"
                                                               (keyup)="getSymptoms()" matInput />
                                                          
                                                            <mat-autocomplete #auto4="matAutocomplete" *ngIf="symptomlist.length != 0">
                                                               <mat-option (click)="select_symptom(symptom)"
                                                                  *ngFor="let symptom of symptomlist"
                                                                  value="{{symptom}}">
                                                                  {{symptom}}
                                                               </mat-option>
                                                            </mat-autocomplete> -->
                                                            <!-- <div class="auto_complete_text" *ngIf="symptomlist.length != 0">
                                                               <ul *ngFor="let symptom of symptomlist">
                                                                  <li>
                                                                     <a (click)="select_symptom(symptom)">{{symptom}}</a>
                                                                  </li>
                                                               </ul>
                                                            </div> -->
                                                            <input type="text" class="ipcss " required maxlength="50"
                                                               [(ngModel)]="clnt_symptom" (keyup)="getSymptoms()" aria-label="Number" matInput autocomplete="on" [matAutocomplete]="auto4" style="max-width: 175px;" />
                                                            <mat-autocomplete #auto4="matAutocomplete">
                                                               <mat-option id="optionfont" (click)="select_symptom(symptom)"
                                                                  *ngFor="let symptom of symptomlist" value={{symptom}}>
                                                                  {{symptom}}
                                                               </mat-option>
                                                            </mat-autocomplete>
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12">
                                                         <mat-label class="matlabel">{{'Investigation' |translate}}<br>
                                                            <textarea class="ipcss txtarea" id="myInput" rows="1" maxlength="250"
                                                               [(ngModel)]="oberse_med"></textarea>
                                                         </mat-label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
               
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                                             <div class="cover_div">
                                                <div class="header_lable">
                                                   Medicine details
                                                </div>
                                                <div class="content_cover" style="position: relative;">
                                                   <a (click)="getmedicine()" class="tablate"><img
                                                         src="../../../assets/ui_icons/tablet_icon.svg" height="30px"
                                                         width="30px" /></a>
               
                                                   <div class="row" *ngIf="medicineFlag">
                                                      <div class="col-xl-2"> </div>
                                                      <div class="col-md-12 col-lg-12 col-xl-8" style="margin-top: 15px;">
                                                         <div>
                                                            <table style="width: 75%; margin: 0 auto;" *ngIf="mediceList.length != 0"
                                                               mdbTable datatable [dtOptions]="dtOptions"
                                                               class="table table-nowrap table-sm dataTable billlisttable">
                                                               <thead>
                                                                  <tr>
                                                                     <th style="width: 45% !important;">Medicine name</th>
                                                                     <th>Quantity</th>
                                                                  </tr>
                                                               </thead>
                                                               <tbody>
                                                                  <tr *ngFor="let person of mediceList; let i = index"
                                                                     (click)="setMedicien(person.med_id)">
                                                                     <td style="font-size: 12px;text-align: left;">{{person.type_name}}
                                                                        {{person.med_name}} </td>
                                                                     <td style="font-size: 12px;text-align: right;">{{person.quantity}}
                                                                     </td>
                                                                  </tr>
                                                               </tbody>
                                                            </table>
                                                         </div>
                                                      </div>
                                                      <div class="col-xl-2"> </div>
                                                   </div>
                                                   <div class="row" *ngIf="!medicineFlag">
                                                      <div class="col-12 col-sm-12 col-md-5 col-lg-3 col-xl-3">
                                                         <mat-checkbox color="primary" [(ngModel)]="no_med" (change)="noMedSelected()">
                                                         </mat-checkbox>
                                                         <mat-label class="matlabel">
                                                            {{'No medicines'|translate}}
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-3">
                                                         <mat-checkbox color="primary" [(ngModel)]="new_med"
                                                            (change)="showMedType(new_med)">
                                                         </mat-checkbox>
                                                         <mat-label class="matlabel">
                                                            {{'New medicine'|translate}}
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-9">
                                                         <mat-label class="matlabel" [hidden]="newmed_flag">{{'Medicine type'
                                                            |translate}}
                                                            <input type="text" class="ipcss " required maxlength="50"
                                                               [(ngModel)]="medtypetxt" (keyup)="Get_medicine_type($event)"
                                                               (focus)="clearmedtype()" aria-label="Number" matInput autocomplete="on"
                                                               [matAutocomplete]="auto2" style="max-width: 175px;" />
                                                            <mat-autocomplete #auto2="matAutocomplete">
                                                               <mat-option id="optionfont" (click)="select_medicine_type(medtype)"
                                                                  *ngFor="let medtype of Medical_prescription_array"
                                                                  value={{medtype.med_name}}>
                                                                  {{medtype.med_name}}
                                                               </mat-option>
                                                            </mat-autocomplete>
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="genericDisplayFlag"> 
                                                         <mat-label class="matlabel">Generic name
                                                            <input type="text" class="ipcss " required maxlength="50"  [(ngModel)]="ganericName" (keyup)="Medicine_name_change($event,'generic')" aria-label="Number" matInput [matAutocomplete]="auto6" />
                                                            <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                                                               <mat-option id="optionfont" (click)="select_medicine_item(medicine)"
                                                                  *ngFor="let medicine of Medicne_data" [value]="medicine.name">
                                                                  {{medicine.name}}
                                                               </mat-option>
                                                            </mat-autocomplete>
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                         <mat-label class="matlabel">{{'Medicine name'|translate}}
                                                            <input type="text" class="ipcss " required maxlength="50"
                                                               [(ngModel)]="medicine_nametxt" (keyup)="Medicine_name_change($event,'medicine')"
                                                               aria-label="Number" matInput [matAutocomplete]="auto6" />
                                                            <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                                                               <mat-option id="optionfont" (click)="select_medicine_item(medicine)"
                                                                  *ngFor="let medicine of Medicne_data" [value]="medicine.name">
                                                                  {{medicine.name}}
                                                               </mat-option>
                                                            </mat-autocomplete>
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" [hidden]="daydur_show">
                                                         <mat-label class="matlabel">{{'Duration'|translate}}</mat-label>
                                                         <div class="row">
                                                            <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                                               <input type="text" class="ipcss" matInput required maxlength="5"
                                                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                  [(ngModel)]="day_txt" />
                                                            </div>
               
                                                            <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6"
                                                               [hidden]="daydur_show">
                                                               <mat-select disableOptionCentering class="ipcss "
                                                                  (selectionChange)="Duration()" [hidden]="daydur_show"
                                                                  [(ngModel)]="daydur_txt">
                                                                  <mat-option disabled>Select</mat-option>
                                                                  <mat-option value="day(s)">day(s)</mat-option>
                                                                  <mat-option value="week(s)">week(s)</mat-option>
                                                                  <mat-option value="month(s)">month(s)</mat-option>
                                                               </mat-select>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 col-xxl-2" [hidden]="intake_show">
                                                         <mat-label class="matlabel">{{'Intake'|translate}}<br>
                                                            <mat-select disableOptionCentering class="ipcss " required
                                                               (selectionChange)="intake_change()" [(ngModel)]="afterfood_txt">
                                                               <mat-option value="Select">Select</mat-option>
                                                               <mat-option value="Empty stomach">Empty stomach</mat-option>
                                                               <mat-option value="After food">After food</mat-option>
                                                               <mat-option value="Before food">Before food</mat-option>
                                                               <mat-option value="With food">With Food</mat-option>
                                                            </mat-select>
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1" [hidden]="dure_show">
                                                         <mat-label [hidden]="dure_show" class="matlabel">{{'Min'|translate}}
                                                            <input type="text" class="ipcss " matInput
                                                               onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                               maxlength="3" [(ngModel)]="dure_txt" />
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1" [hidden]="!dure_show">
               
                                                      </div>
                                                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3" [hidden]="mix_show">
                                                         <mat-label class="matlabel">{{'Mixed with'|translate}}<br>
                                                            <mat-select disableOptionCentering class="ipcss " [(ngModel)]="mixing_txt">
                                                               <mat-option value="Select">Select</mat-option>
                                                               <mat-option *ngFor="let mixing of mixtype" value={{mixing}}>{{mixing}}</mat-option>
                                                            </mat-select>
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                                                         <mat-label class="matlabel">{{'Intake session'|translate}}<br>
                                                            <mat-select disableOptionCentering class="ipcss " required
                                                               [(ngModel)]="intake_txt">
                                                               <mat-option disabled>Select</mat-option>
                                                               <mat-option *ngFor="let intake of Get_Intake_array"
                                                                  (click)="sesssion_basechange()" value={{intake.description}}>
                                                                  {{intake.description}}</mat-option>
                                                            </mat-select>
                                                         </mat-label>
                                                      </div>
               
                                                      <div [hidden]="morn_eventxt" class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                                         <div [hidden]="mor_lable">
                                                            <mat-label class="matlabel input">{{'Mor'|translate}}
                                                               <input type="text" class="ipcss " matInput [required]="morning_req"
                                                                  [(ngModel)]="mor" [disabled]="morning_dis" maxlength="5"
                                                                  onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)" />
                                                            </mat-label>
                                                         </div>
               
                                                         <div [hidden]="quan_lable">
                                                            <mat-label class="matlabel">{{'Qty'|translate}}
                                                               <input type="text" class="ipcss " matInput [required]="morning_req"
                                                                  maxlength="5"
                                                                  onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                                  [disabled]="morning_dis" [(ngModel)]="mor" />
                                                            </mat-label>
                                                         </div>
                                                      </div>
               
                                                      <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 " [hidden]="after_eventxt">
                                                         <span class="iocn_position">-</span>
                                                         <mat-label class="matlabel">{{'Aft'|translate}}
                                                            <input type="text" class="ipcss " matInput [disabled]="afternoon_dis"
                                                               maxlength="5"
                                                               onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                               [required]="afternoon_req" [(ngModel)]="aft" />
                                                         </mat-label>
                                                      </div>
               
                                                      <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="eve_eventxt">
                                                         <span class="iocn_position ">-</span>
                                                         <mat-label class="matlabel">{{'Eve'|translate}}
                                                            <input type="text" class="ipcss " matInput [disabled]="even_dis"
                                                               [required]="evening_req" maxlength="5"
                                                               onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                               [(ngModel)]="eve" />
                                                         </mat-label>
                                                      </div>
               
                                                      <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="night_eventxt">
                                                         <span class="iocn_position">-</span>
                                                         <mat-label class="matlabel">{{'Ngt'|translate}}
                                                            <input type="text" class="ipcss " matInput [disabled]="ngt_dis"
                                                               [required]="night_req" maxlength="5"
                                                               onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                               [(ngModel)]="ngt" />
                                                         </mat-label>
                                                      </div>
               
                                                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                         <mat-label class="matlabel">{{'Note'|translate}}<br>
                                                            <textarea class="ipcss widthappt" id="myInput" maxlength="250"
                                                               [(ngModel)]="remark_txt"></textarea>
                                                         </mat-label>
                                                      </div>
                                                      <div *ngIf="Med_addbtn"
                                                         class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-auto"
                                                         style="text-align: right;">
                                                         <a (click)="Medicalpres_add()"><img
                                                               src="../../../assets/ui_icons/buttons/add_button.svg"
                                                               class="saveimgbtn_inpatinfo" /></a>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
               
                                          <div class="col-12" style="margin: 20px 0;">
                                             <div class="col-12 dig_table_overflow" [hidden]="Medicine_table">
                                                <div class="table-responsive">
                                                   <table id="tbl" *ngIf="listProducts.length !=0 ">
                                                      <thead>
                                                         <tr>
                                                            <th style="width: 11%;">{{'Medicine type'|translate}}</th>
                                                            <th style="width: 16%;">{{'Medicine name'|translate}}</th>
                                                            <th style="width: 7%;">{{'Duration'|translate}}</th>
                                                            <th style="width: 12%;">{{'Intake session'|translate}}</th>
                                                            <th style="width: 10%;">{{'Frequency'|translate}}</th>
                                                            <th style="width: 15%;">{{'Intake'|translate}}</th>
                                                            <th style="width: 22%;">{{'Note'|translate}}</th>
                                                            <th>{{'Action'|translate}}</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                         <tr *ngFor="let product of listProducts">
                                                            <td style="text-align: left;">{{product.med_typetxt}}</td>
                                                            <td style="text-align: left;">{{product.drug_name}}</td>
                                                            <td style="text-align: left;">{{product.days}} {{product.period
                                                               |translate}}</td>
                                                            <td>{{product.every_six}}</td>
                                                            <td style="text-align: left;">{{product.frequency |translate}}</td>
                                                            <td style="text-align: left;">{{product.dure_txt_table}}
                                                               {{product.drug_intake|translate}} {{product.mixval}}</td>
                                                            <td style="text-align: left;">{{product.remarks}}</td>
                                                            <td>
                                                               <a (click)="editMedicalPres(product.drug_id,product.rowid)">
                                                                  <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                                     class="editDelicon1" /></a>&nbsp;&nbsp;
                                                               <a (click)="Delete_medicalpres(product.med_typetxt,product.drug_name)"><img
                                                                     src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                     class="editDelicon1" /></a>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                             <div class="cover_div">
                                                <div class="header_lable">
                                                   Pharmacy details
                                                </div>
                                                <div class="content_cover">
                                                   <div class="row">
                                                      <div class="col-12">
                                                         <mat-label class="matlabel">{{'Preferred pharmacy' |translate}}
                                                            <input type="text" class="ipcss" maxlength="50"
                                                               [(ngModel)]="prfer_pharmatxt" (keyup)="Prefer_pharmacy_change($event)"
                                                               aria-label="Number" matInput [matAutocomplete]="auto1" />
                                                            <mat-autocomplete #auto1="matAutocomplete">
                                                               <mat-option id="optionfont"
                                                                  (click)="Pharma_medicine_name(prfer_pharmatxt)"
                                                                  *ngFor="let pharma of Pharmanamelist" [value]="pharma">
                                                                  {{pharma}}
                                                               </mat-option>
                                                            </mat-autocomplete>
               
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12">
                                                         <mat-label class="matlabel">{{'Preferred pharmacy address' |translate}}<br>
                                                            <textarea class=" ipcss  txtarea" disabled="true" id="myInput" rows="1"
                                                               [(ngModel)]="prefer_add"></textarea>
                                                         </mat-label>
                                                      </div>
                                                      <div class="col-12">
                                                         <div>
                                                            <mat-label class="matlabel">
                                                               <mat-checkbox labelPosition="before" [(ngModel)]="order_now"> {{'Would you like to place order now'|translate}} </mat-checkbox>
                                                            </mat-label><br>
                                                            <mat-label class="matlabel"><strong>{{"Dr."+doctorname}} <span
                                                                     style="font-size: 10px;">{{qualification}}</span></strong>
                                                            </mat-label>
                                                            <br>
                                                            <mat-label class="matlabel"> Registration no: {{licence_code}}</mat-label>
                                                            <br>
                                                         </div>
                                                         <div>
                                                            <mat-label class="matlabel">{{'Find your prescription online at'|translate}}
                                                               www.tervys.com
                                                            </mat-label><br>
                                                            <mat-label class="matlabel"><b><i>This is digitally generated Prescription</i></b>
                                                            </mat-label>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
               
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                                             <div class="cover_div" style="padding-bottom: 17px;">
                                                <div class="header_lable">
                                                   Instructions
                                                </div>
                                                <div class="content_cover">
                                                   <div class="row">
                                                      <div class="col-12">
                                                         <quill-editor id="editor" [(ngModel)]="instructions" [style]="editorStyle"
                                                            [modules]="modules" placeholder="Enter Text"
                                                            (onSelectionChanged)="onSelectionChanged($event)"
                                                            (onContentChanged)="onContentChanged($event)"></quill-editor>
                                                      </div>
                                                      <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                                         <mat-label class="matlabel">{{'Next visit' |translate}}<br>
                                                            <input type="date" class="ipcss_date widthappt" id="appt_date"
                                                               (change)="OnDateMatePicker(next_datetxt)" [(ngModel)]="next_datetxt"
                                                               min={{currentDate}} #matInput style="width: 140px;">
                                                         </mat-label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </mat-expansion-panel>
                                 </mat-accordion>
                                 <div class="row" style="margin:25px 0">
                                    <div class="col-6 col-sm-6 col-md-8 col-lg-3 col-xl-3 col-xxl-3">
                                       <mat-label class="matlabel "
                                          style="font-family: arial;font-weight: 600;font-size: 13px !important;">Would you like to
                                          view casesheet</mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-2 col-lg-4 col-xl-3 col-xxl-2 margin-bottomvalue">
                                       <img *ngIf="viewCaseSheet == true" (click)="changeViewCaseSheet(false)"
                                          src="../../../assets/ui_icons/tick_mark.svg"
                                          style="cursor: pointer;width: 24px;margin: 0 0px 0 9px;">
                                       <img *ngIf="viewCaseSheet == false" (click)="changeViewCaseSheet(true)"
                                          src="../../../assets/ui_icons/cross_mark.svg"
                                          style="cursor: pointer;width: 24px; margin: 0 0px 0 9px;">
                                    </div>
                                 </div>
                                 <div [hidden]="true">
                                    <div #printlogowithname id="printlogowithname"
                                       style="background-image: url({{bg_image}}); background-position: center;">
                                       <div>
                                          <div *ngIf="listProducts.length != 0">
                                             <table style="margin-left:50px;margin-top:70px;">
                                                <tr>
                                                   <td style="width: 450px; vertical-align: top;">
                                                      <img alt="image" src={{hosp_img}} width="100px" height="100px">
                                                      <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                                                      <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                                                      <p style="margin-left: 110px;">{{doct_city}}</p>
                                                      <p style="margin-left: 110px;">{{doct_state}}</p>
                                                   </td>
                                                   <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                                                   <td style="width: 300px;vertical-align: top;">
                                                      <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                                                      <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                                                      <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                                                   </td>
                                                </tr>
                                             </table>
                                             <br /><br />
                                             <table style="margin-left: 50px; margin-top:20px;">
                                                <tr>
                                                   <td style="width: 500px;">
                                                      <p><b>{{'Name'|translate}} :</b> {{patient_name}}</p>
                                                      <p><b>{{'Age'|translate}} :</b> {{client_age}}
                                                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                                                         </b>{{client_gender}} </p>
                                                      <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b>
                                                         {{oberse_med}}</p>
                                                      <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>
               
                                                   </td>
                                                   <td style="width: 300px;vertical-align: top;">
                                                      <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                                                      <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                                                      <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                                                         &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                                                         </b>{{clnt_pulse}}</p>
                                                      <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}}
                                                         &nbsp;&nbsp;&nbsp;<span *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                                                            {{weight}}</span></p>
                                                      <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}}
                                                      </p>
                                                      <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                                                         &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                                                         {{clnt_rs}}</p>
                                                      <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                                                   </td>
                                                </tr>
                                             </table>
                                             <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                                                <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
                                             </div>
                                             <br />
                                             <table *ngIf="listProducts.length != 0"
                                                style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                                                <tr>
                                                   <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Medicine'|translate}}</b>
                                                   </th>
                                                   <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Dosage'|translate}}</b>
                                                   </th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Duration'|translate}}</b>
                                                   </th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Frequency'|translate}}</b>
                                                   </th>
                                                   <th style="border: 1px solid black;border-collapse: collapse;">&nbsp;</th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Note'|translate}}</b>
                                                   </th>
                                                </tr>
                                                <tr *ngFor="let product of listProducts">
                                                   <td style="border: 1px solid black;border-collapse: collapse;">
                                                      {{product.med_typetxt}}-{{product.drug_name}}
                                                   </td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.every_six}}
                                                   </td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.days}}
                                                      {{product.period|translate}}</td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.frequency
                                                      |
                                                      translate}}</td>
                                                   <td style="border: 1px solid black;border-collapse: collapse;">
                                                      {{product.dure_txt_table}}
                                                      {{product.drug_intake|translate}} {{product.mixval}}</td>
                                                   <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}
                                                   </td>
                                                </tr>
                                             </table>
               
                                             <div style="margin-left: 50px;">
                                                <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                                                   <span>
                                                      <div class="matlabel" [innerHtml]="print_instruction"></div>
                                                   </span>
                                                </p>
                                             </div>
               
                                             <div style="margin-left: 50px;" *ngIf="treatflag">
                                                <p><b>{{'Treatment' |translate}}: </b>
                                                   <span>
                                                      <div class="matlabel" [innerHtml]="treatment"></div>
                                                   </span>
                                                </p>
                                             </div>
               
                                             <div style="margin-left: 50px;">
                                                <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                                             </div>
                                             <br />
                                             <div>
                                                <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                                                      {{qualification}}</strong>
                                                </p>
                                                <p style="margin-left: 50px;font-size: 13px">Registration no: {{licence_code}}</p>
                                                <p style="margin-left: 50px;font-size: 13px">Find your prescription online at
                                                   <i>www.tervys.com</i>
                                                </p>
                                                <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated
                                                         prescription</i></b>
                                                </p>
                                                <br />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div #printbanner id="printbanner"
                                       style="background-image: url({{bg_image}}); background-position: center;">
                                       <div>
                                          <div *ngIf="listProducts.length != 0">
                                             <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                                                <tr>
                                                   <td>
                                                      <img alt="image" src={{hptl_pres_logo}} width="750px" height="250px">
                                                   </td>
                                                </tr>
                                             </table>
                                             <br /><br />
                                             <table style="margin-left: 50px; margin-top:20px;">
                                                <tr>
                                                   <td style="width: 500px;">
                                                      <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                                                      <p><b>{{'Age'|translate}} :</b> {{client_age}}
                                                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                                                         </b>{{client_gender}} </p>
                                                      <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b>
                                                         {{oberse_med}}</p>
                                                      <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>
               
                                                   </td>
                                                   <td style="width: 300px;vertical-align: top;">
                                                      <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                                                      <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                                                      <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                                                         &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                                                         </b>{{clnt_pulse}}</p>
                                                      <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}}
                                                         &nbsp;&nbsp;&nbsp;<span *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                                                            {{weight}}</span></p>
                                                      <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}}
                                                      </p>
                                                      <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                                                         &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                                                         {{clnt_rs}}</p>
                                                      <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                                                   </td>
                                                </tr>
                                             </table>
                                             <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                                                <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
                                             </div>
                                             <br />
                                             <table *ngIf="listProducts.length != 0"
                                                style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                                                <tr>
                                                   <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Medicine'|translate}}</b>
                                                   </th>
                                                   <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Dosage'|translate}}</b>
                                                   </th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Duration'|translate}}</b>
                                                   </th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Frequency'|translate}}</b>
                                                   </th>
                                                   <th style="border: 1px solid black;border-collapse: collapse;">&nbsp;</th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Note'|translate}}</b>
                                                   </th>
                                                </tr>
                                                <tr *ngFor="let product of listProducts">
                                                   <td style="border: 1px solid black;border-collapse: collapse;">
                                                      {{product.med_typetxt}}-{{product.drug_name}}
                                                   </td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.every_six}}
                                                   </td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.days}}
                                                      {{product.period|translate}}</td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.frequency
                                                      |
                                                      translate}}</td>
                                                   <td style="border: 1px solid black;border-collapse: collapse;">
                                                      {{product.dure_txt_table}}
                                                      {{product.drug_intake|translate}} {{product.mixval}}</td>
                                                   <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}
                                                   </td>
                                                </tr>
                                             </table>
               
                                             <div style="margin-left: 50px;">
                                                <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                                                   <span>
                                                      <div class="matlabel" [innerHtml]="print_instruction"></div>
                                                   </span>
                                                </p>
                                             </div>
               
                                             <div style="margin-left: 50px;" *ngIf="treatflag">
                                                <p><b>{{'Treatment' |translate}}: </b>
                                                   <span>
                                                      <div class="matlabel" [innerHtml]="treatment"></div>
                                                   </span>
                                                </p>
                                             </div>
               
                                             <div style="margin-left: 50px;">
                                                <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                                             </div>
                                             <br />
                                             <div>
                                                <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                                                      {{qualification}}</strong>
                                                </p>
                                                <p style="margin-left: 50px;font-size: 13px">Registration no: {{licence_code}}</p>
                                                <p style="margin-left: 50px;font-size: 13px">Find your prescription online at
                                                   <i>www.tervys.com</i>
                                                </p>
                                                <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated
                                                         prescription</i></b>
                                                </p>
                                                <br />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div #printnoheader id="printnoheader"
                                       style="background-image: url({{bg_image}}); background-position: center;">
                                       <div>
                                          <div *ngIf="listProducts.length != 0">
                                             <table style="margin-left: 50px; margin-top:220px;">
                                                <tr>
                                                   <td style="width: 500px;">
                                                      <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                                                      <p><b>{{'Age'|translate}} :</b> {{client_age}}
                                                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                                                         </b>{{client_gender}} </p>
                                                      <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b>
                                                         {{oberse_med}}</p>
                                                      <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>
               
                                                   </td>
                                                   <td style="width: 300px;vertical-align: top;">
                                                      <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                                                      <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                                                      <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                                                         &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                                                         </b>{{clnt_pulse}}</p>
                                                      <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}}
                                                         &nbsp;&nbsp;&nbsp;<span *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                                                            {{weight}}</span></p>
                                                      <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}}
                                                      </p>
                                                      <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                                                         &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                                                         {{clnt_rs}}</p>
                                                      <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                                                   </td>
                                                </tr>
                                             </table>
                                             <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                                                <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
                                             </div>
                                             <br />
                                             <table *ngIf="listProducts.length != 0"
                                                style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                                                <tr>
                                                   <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Medicine'|translate}}</b>
                                                   </th>
                                                   <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Dosage'|translate}}</b>
                                                   </th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Duration'|translate}}</b>
                                                   </th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Frequency'|translate}}</b>
                                                   </th>
                                                   <th style="border: 1px solid black;border-collapse: collapse;">&nbsp;</th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Note'|translate}}</b>
                                                   </th>
                                                </tr>
                                                <tr *ngFor="let product of listProducts">
                                                   <td style="border: 1px solid black;border-collapse: collapse;">
                                                      {{product.med_typetxt}}-{{product.drug_name}}
                                                   </td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.every_six}}
                                                   </td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.days}}
                                                      {{product.period|translate}}</td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.frequency
                                                      |
                                                      translate}}</td>
                                                   <td style="border: 1px solid black;border-collapse: collapse;">
                                                      {{product.dure_txt_table}}
                                                      {{product.drug_intake|translate}} {{product.mixval}}</td>
                                                   <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}
                                                   </td>
                                                </tr>
                                             </table>
               
                                             <div style="margin-left: 50px;">
                                                <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                                                   <span>
                                                      <div class="matlabel" [innerHtml]="print_instruction"></div>
                                                   </span>
                                                </p>
                                             </div>
               
                                             <div style="margin-left: 50px;" *ngIf="treatflag">
                                                <p><b>{{'Treatment' |translate}}: </b>
                                                   <span>
                                                      <div class="matlabel" [innerHtml]="treatment"></div>
                                                   </span>
                                                </p>
                                             </div>
               
                                             <div style="margin-left: 50px;">
                                                <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                                             </div>
                                             <br />
                                             <div style="margin-left: 50px;">
                                                <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                                                </p>
                                                <p style="font-size: 13px">Registration no: {{licence_code}}</p>
                                                <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                                                <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
                                                <br />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div #printnoheadsidebar id="printnoheadsidebar"
                                       style="background-image: url({{bg_image}}); background-position: center;">
                                       <div>
                                          <div *ngIf="listProducts.length != 0">
                                             <table style="margin-left: 220px; margin-top:350px;">
                                                <tr>
                                                   <td style="width: 500px;">
                                                      <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                                                      <p><b>{{'Age'|translate}} :</b> {{client_age}}
                                                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                                                         </b>{{client_gender}} </p>
                                                      <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b>
                                                         {{oberse_med}}</p>
                                                      <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>
               
                                                   </td>
                                                   <td style="width: 300px;vertical-align: top;">
                                                      <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                                                      <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                                                      <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                                                         &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                                                         </b>{{clnt_pulse}}</p>
                                                      <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}}
                                                         &nbsp;&nbsp;&nbsp;<span *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                                                            {{weight}}</span></p>
                                                      <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}}
                                                      </p>
                                                      <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                                                         &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                                                         {{clnt_rs}}</p>
                                                      <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                                                   </td>
                                                </tr>
                                             </table>
                                             <div style="margin-left: 220px;" *ngIf="print_view_flag && listProducts.length != 0">
                                                <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
                                             </div>
                                             <br />
                                             <table *ngIf="listProducts.length != 0"
                                                style="margin-left: 220px; border: 1px solid black;border-collapse: collapse;">
                                                <tr>
                                                   <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Medicine'|translate}}</b>
                                                   </th>
                                                   <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Dosage'|translate}}</b>
                                                   </th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Duration'|translate}}</b>
                                                   </th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Frequency'|translate}}</b>
                                                   </th>
                                                   <th style="border: 1px solid black;border-collapse: collapse;">&nbsp;</th>
                                                   <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                                                      <b>{{'Note'|translate}}</b>
                                                   </th>
                                                </tr>
                                                <tr *ngFor="let product of listProducts">
                                                   <td style="border: 1px solid black;border-collapse: collapse;">
                                                      {{product.med_typetxt}}-{{product.drug_name}}
                                                   </td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.every_six}}
                                                   </td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.days}}
                                                      {{product.period|translate}}</td>
                                                   <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                      {{product.frequency
                                                      |
                                                      translate}}</td>
                                                   <td style="border: 1px solid black;border-collapse: collapse;">
                                                      {{product.dure_txt_table}}
                                                      {{product.drug_intake|translate}} {{product.mixval}}</td>
                                                   <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}
                                                   </td>
                                                </tr>
                                             </table>
               
                                             <div style="margin-left: 220px;">
                                                <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                                                   <span>
                                                      <div class="matlabel" [innerHtml]="print_instruction"></div>
                                                   </span>
                                                </p>
                                             </div>
               
                                             <div style="margin-left: 220px;" *ngIf="treatflag">
                                                <p><b>{{'Treatment' |translate}}: </b>
                                                   <span>
                                                      <div class="matlabel" [innerHtml]="treatment"></div>
                                                   </span>
                                                </p>
                                             </div>
               
                                             <div style="margin-left: 220px;">
                                                <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                                             </div>
                                             <br />
                                             <div>
                                                <p style="margin-left: 220px;font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                                                      {{qualification}}</strong>
                                                </p>
                                                <p style="margin-left: 220px;font-size: 13px">Registration no: {{licence_code}}</p>
                                                <p style="margin-left: 220px;font-size: 13px">Find your prescription online at
                                                   <i>www.tervys.com</i>
                                                </p>
                                                <p style="margin-left: 220px;font-size: 13px"><b><i>This is digitally generated
                                                         prescription</i></b>
                                                </p>
                                                <br />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>