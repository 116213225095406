<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill Details</h5>
          </div>
          <div class="headerButtons">
            <a *ngIf="printFlag">
              <img  src="../../../assets/img/printer.svg" (click)="printArea()" style="width: 25px  !important;height: auto !important; " />
            </a>
            <!-- <a ><button  printSectionId="billprint" (click)="printArea()" ngxPrint *ngIf="printFlag"
                class="" mat-icon-button>  <img src="../../../assets/img/printer.svg" style="width: 25px  !important;height: auto !important; top: -3px; right: 30px;" class="printimagecss"  />
                </button></a> -->
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
              class="saveimgbtn_inpatinfo " />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div *ngFor="let billprint of billPrintArray">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <div [hidden]="hospitalAddFlag">
                <table>
                  <tr>
                    <td style="text-align: left !important;"><b>{{billprint.diag_centre_name}}</b></td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.address}}</td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.location}}{{billprint.city}}</td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.state}}</td>
                  </tr>
                  <tr>
                    <td class="changefont"> <img class="phone_img" src="../../../assets/img/phone.png" width="15px"
                        height="15px" />
                      {{billprint.telephone}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <table>
                <tr>
                  <th style="text-align: left !important;">Bill date : </th>
                  <td>
                    {{billprint.created_date}}
                  </td>
                </tr>
                <tr>
                  <th style="text-align: left !important;font-size: 12px;">Bill number : </th>
                  <td style="text-align: left;">
                    {{billID}}
                  </td>
                </tr>
                <!-- <tr [hidden]="main_balshow">
                  <th style="text-align: left !important;">Bill amount (र) :</th>
                  <td style="text-align: left !important;">
                    {{billprint.balance}}
                  </td>
                </tr> -->
              </table>
            </div>
          </div>
          <div class="row chwidth" style="margin-top: 12px;">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="subheadingvalue">Patient details</p>
              <table>
                <tr>
                  <th style="text-align: left !important;" class="thvalue">Patient name : </th>
                  <td>{{billprint.client_name}}</td>
                </tr>
                <tr>
                  <th style="text-align: left !important;" class="thvalue">Mobile : </th>
                  <td>{{billprint.mobile}}</td>
                </tr>
                <tr>
                  <th style="text-align: left !important;" class="thvalue">UHID : </th>
                  <td>
                    {{billprint.client_reg_id}}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="subheadingvalue">Address</p>
              <!-- <p class="tervys_heading" style="font-weight: 500;"><b>Address:</b></p> -->
              <table>
                <tr>
                  <td class="thvalue">{{billprint.caddress}}</td>
                </tr>
                <tr>
                  <td class="thvalue">{{billprint.clocation}}{{ billprint.clientcity}}</td>
                </tr>
                <tr>
                  <td class="thvalue">{{billprint.clestare}}</td>
                </tr>
                <tr>
                  <td class="thvalue">{{billprint.ccountry}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row" style="margin-top: 12px;">
            <div class="col-12">
              <p class=" col-md-3 col-lg-3 col-xl-3 col-sm-3" *ngIf="billingDetails.length !=0" class="tervys_heading"
                style="font-weight: 500;">Bill particulars</p>
              <!-- <p  class="tervys_heading"><b>Bill particulars</b></p> -->
            </div>
            <div class="dig_table_overflow">
              <div class="table-responsive" *ngIf="billingDetails.length" style="padding: 0;">
                <table id="card_tbl" class="table table-nowrap table-sm">
                  <thead class="thvalues">
                    <tr style="height: 30px;">
                      <th>Sno</th>
                      <th>Type</th>
                      <th *ngIf="paytypreq">Payment type</th>
                      <th>Fee</th>
                      <th>Quantity</th>
                      <th>Concession</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="height: 30px;" *ngFor="let person of billingDetails">
                      <td style="font-size: 12px;" class="txtalign">{{person.sno}}</td>
                      <td style="font-size: 12px;" class="txtalign">{{person.test_name}}</td>
                      <td style="font-size: 12px;" class="txtalign" *ngIf="paytypreq">{{person.pay_type}}</td>
                      <td style="font-size: 12px;" class="txtalignright">{{person.fee}}</td>
                      <td style="font-size: 12px;text-align: center;">{{person.quantity}}</td>
                      <td style="font-size: 12px;" class="txtalignright">{{person.concession}}</td>
                      <td class="billlistleft" style="font-size: 12px;" class="txtalignright">{{person.finalfee}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-8">
              <div class="col-4 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="!paytypreq">
                <table class="table table-hover table-dynamic" style="margin-top: 20px;">
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 200px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 200px !important;">{{pay.amount}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px;">
              <div class="row">
                <!-- <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 alignRight" [hidden]="totalShow">
                  <mat-label class="matlabel ">Total र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6" [hidden]="totalShow">
                  <p class="txtalignright"><span class="">{{totalAmount}}</span></p>
                </div> -->
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 alignRight" [hidden]="centralGST">
                  <mat-label class="matlabel">Central GST {{billprint.cgst}}% </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6" [hidden]="centralGST">
                  <p class="txtalignright"><span class="">{{centralGST}} </span></p>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 alignRight" [hidden]="stateGST">
                  <mat-label class="matlabel">State GST {{billprint.cgst}} %</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6" [hidden]="stateGST">
                  <p class="txtalignright"><span class="">{{stateGST}} </span></p>
                </div>
                <!-- <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 alignRight" [hidden]="prevBalFlag">
                  <mat-label class="matlabel"> Previous balance र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6" [hidden]="prevBalFlag">
                  <p class="txtalignright"><span class="">{{prevBlance}}</span></p>
                </div> -->
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 alignRight" [hidden]="totalShow">
                  <mat-label class="matlabel "> Bill amount र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6" [hidden]="totalShow">
                  <p class="txtalignright"><span class="">{{total}} </span></p>
                </div>
                <!-- <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 alignRight" [hidden]="advanceFlag">
                  <mat-label class="matlabel">Advance र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6" [hidden]="advanceFlag">
                  <p class="txtalignright"><span class="">{{billprint.advance}} </span></p>
                </div> -->
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6" [hidden]="advanceFlag">
                  <mat-label class="matlabel">Paid र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6" [hidden]="advanceFlag">
                  <p class="txtalignright"><span class="">{{paid}} </span></p>
                </div>
                <!-- <div class="col-4 col-sm-8 col-md-5 col-lg-6 col-xl-6" [hidden]="totalshow">
                  <mat-label class="matlabel">Total र </mat-label>
                </div>
                <div class="col-7 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="totalshow">
                  <span class="changelefttotalbill">{{total_bill1}}</span>
                </div> -->
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 alignRight">
                  <mat-label class="matlabel">Balance र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-6">
                  <p class="txtalignright"><span class="">{{billprint.balance}}</span></p>
                </div>
                <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="totalshow">
                        <mat-label class="matlabel">Bill amount र  </mat-label>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="totalshow">
                        <td><span >{{total_bill1}}</span></td>
                      </div> -->
                <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel heightmsr">Total </mat-label>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true" [(ngModel)]="final_amount" />
                      </div><div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel heightmsr">Advance / Paid</mat-label>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <input class="ipcss  heightmsr bill_txt" matInput text-right (keyup)="advance_change()"
                                [(ngModel)]="Advance_bill" maxlength="10" />
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel heightmsr" [hidden]="balance_show">Balance</mat-label>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <input class="ipcss billcreatech heightmsr bill_txt" [hidden]="balance_show" matInput text-right
                              [(ngModel)]="remaing_billbal" maxlength="10" />
                      </div> -->
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="true">
          <div #noheader id="noheader">
            <div class="bill_border" style="margin: 0 15px;">
              <div *ngFor="let billprint of billPrintArray">
                <!-- Patient details -->
                <table style="margin-left: 50px; width: 100%; margin-top: 200px;">
                  <tr>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                          <br />
                          <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                          <br />
                          <span><strong> UHID : </strong>{{billprint.client_reg_id}}</span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress"><strong>Bill number : </strong>{{billID}} </span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress"><strong>Bill date : </strong>{{billprint.created_date}} </span>
                        </div>
                      </div>
                    </td>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4">
                          <span [hidden]="noaddress"><strong>Address</strong></span>
                          <br />
                          <span [hidden]="noaddress"> {{billprint.caddress}}</span>
                          <span [hidden]="!noaddress"><strong>Bill number : </strong>{{billID}} </span>
                          <br />
                          <span [hidden]="noaddress">{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <span [hidden]="!noaddress"><strong>Bill date : </strong>{{billprint.created_date}} </span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress">{{billprint.clestare}}</span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress">{{billprint.ccountry}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <!-- Bill particulars -->
                <div>
                  <h3 class="heading" style="margin-left: 50px;">Bill particulars</h3>
                  <div class="row">
                    <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                      <tr>
                        <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b>
                        </th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Test name</b>
                        </th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Payment type</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Quantity</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Concession</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                      </tr>
                      <tr *ngFor="let billprinttable of billingDetails">
                        <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.sno}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.test_name}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.pay_type}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee">{{billprinttable.fee}}</td>
                        <td style="width: 150px; text-align: center; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee">{{billprinttable.quantity}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Concession">{{billprinttable.concession}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Amount" class="fee_txt">{{billprinttable.finalfee}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <br />
                <!-- Total calculation table -->
                <div class="col-12">
                  <div class="col-7 col-sm-4 col-md-7 col-lg-7 col-xl-7" style="margin-left: 50px !important;">
                    <div *ngIf="!paytypreq">
                      <table class="table table-hover table-dynamic" style="margin-top: 20px;">
                        <tr *ngFor="let pay of paymentsarray">
                          <td style="width: 200px !important;">{{pay.pay_desc}}</td>
                          <td style="width: 200px !important;">{{pay.amount}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px;">
                    <div style="width: 100%;">
                      <table  style="float: right;">
                        <!-- <tr [hidden]="totalShow">
                          <td style="width: 510px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Total (र) </th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र" class="fee_txt">
                            {{totalAmount}}</td>
                        </tr> -->
                        <tr [hidden]="centralGST">
                          <td style="width: 510px;background-color: #ffffff;"></td>
                          <th style="text-align: left;"><strong>Central GST </strong> % </th>
                          <td style="text-align: right; width: 100px;margin-right: 10px;" data-th="Central GST"
                            class="fee_txt">
                            {{centralGST}}</td>
                        </tr>
                        <tr [hidden]="stateGST">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;"><strong>State GST </strong> % </th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="State GST"
                            class="fee_txt">
                            {{stateGST}}</td>
                        </tr>
                        <!-- <tr [hidden]="prevBalFlag">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Previous balance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Previous balance र"
                            class="fee_txt">{{prevBlance}}</td>
                        </tr> -->
                        <tr [hidden]="totalShow">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Bill amount (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                            class="fee_txt">
                            {{total}}</td>
                        </tr>
                        <!-- <tr [hidden]="advanceFlag">
                          <td style="width: 300px;background-color: #ffffff"></td>
                          <th style="text-align: left;">Advance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                            class="fee_txt">
                            {{billprint.advance}}</td>
                        </tr> -->
                        <tr [hidden]="advanceFlag">
                          <td style="width: 300px;background-color: #ffffff"></td>
                          <th style="text-align: left;">Paid (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                            class="fee_txt">
                            {{paid}}</td>
                        </tr>
                        <tr>
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Balance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance" class="fee_txt">
                            {{billprint.balance}}</td>
                        </tr>
                      </table><br /><br /><br />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <!-- //Treate ment plan table -->
              <br />
              <br />
              <br /><br />
            </div>
            <br />
          </div>
          <div #banner id="banner">
            <div class="bill_border">
              <div *ngFor="let billprint of billPrintArray">
                <!-- Doctor details -->
                <div>
                  <table style="width: 100%;">
                      <tr>
                        <td style="display: flex;justify-content: center;">
                          <div>
                            <img alt="image" src={{hospitalLogo}} [style]="print_style">
                          </div>
                        </td>
                      </tr>
                  </table>
                </div>
                <br />
                <!-- <h3 class="heading">Patient details</h3> -->
                <!-- Patient details -->
                <table style="margin-left: 50px; width: 100%;">
                  <tr>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                          <br />
                          <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                          <br />
                          <span><strong> UHID : </strong>{{billprint.client_reg_id}}</span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress"><strong>Bill number : </strong>{{billID}} </span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress"><strong>Bill date : </strong>{{billprint.created_date}} </span>
                        </div>
                      </div>
                    </td>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4">
                          <span [hidden]="noaddress"><strong>Address</strong></span>
                          <br />
                          <span [hidden]="noaddress"> {{billprint.caddress}}</span>
                          <span [hidden]="!noaddress"><strong>Bill number : </strong>{{billID}} </span>
                          <br />
                          <span [hidden]="noaddress">{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <span [hidden]="!noaddress"><strong>Bill date : </strong>{{billprint.created_date}} </span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress">{{billprint.clestare}}</span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress">{{billprint.ccountry}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <!-- Bill particulars -->
                <div>
                  <h3 class="heading" style="margin-left: 50px;">Bill particulars</h3>
                  <div class="row">
                    <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                      <tr>
                        <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b>
                        </th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Test name</b>
                        </th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;" *ngIf="paytypreq"><b>Payment type</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Quantity</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Concession</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                      </tr>
                      <tr *ngFor="let billprinttable of billingDetails">
                        <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                        data-th="Type">{{billprinttable.sno}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.test_name}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type" *ngIf="paytypreq">{{billprinttable.pay_type}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee">{{billprinttable.fee}}</td>
                        <td style="width: 150px; text-align: center; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee">{{billprinttable.quantity}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Concession">{{billprinttable.concession}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Amount" class="fee_txt">{{billprinttable.finalfee}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <br />
                <!-- Total calculation table -->
                <div class="col-12">
                  <div class="col-7 col-sm-4 col-md-7 col-lg-7 col-xl-7" style="margin-left: 50px !important;">
                    <div *ngIf="!paytypreq">
                      <table class="table table-hover table-dynamic" style="margin-top: 20px;">
                        <tr *ngFor="let pay of paymentsarray">
                          <td style="width: 200px !important;">{{pay.pay_desc}}</td>
                          <td style="width: 200px !important;">{{pay.amount}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px;">
                    <div style="width: 100%;">
                      <table  style="float: right;">
                        <!-- <tr [hidden]="totalShow">
                          <td style="width: 510px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Total (र) </th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र" class="fee_txt">
                            {{totalAmount}}</td>
                        </tr> -->
                        <tr [hidden]="centralGST">
                          <td style="width: 510px;background-color: #ffffff;"></td>
                          <th style="text-align: left;"><strong>Central GST </strong> % </th>
                          <td style="text-align: right; width: 100px;margin-right: 10px;" data-th="Central GST"
                            class="fee_txt">
                            {{centralGST}}</td>
                        </tr>
                        <tr [hidden]="stateGST">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;"><strong>State GST </strong> % </th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="State GST"
                            class="fee_txt">
                            {{stateGST}}</td>
                        </tr>
                        <!-- <tr [hidden]="prevBalFlag">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Previous balance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Previous balance र"
                            class="fee_txt">{{prevBlance}}</td>
                        </tr> -->
                        <tr [hidden]="totalShow">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Bill amount (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                            class="fee_txt">
                            {{total}}</td>
                        </tr>
                        <!-- <tr [hidden]="advanceFlag">
                          <td style="width: 300px;background-color: #ffffff"></td>
                          <th style="text-align: left;">Advance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                            class="fee_txt">
                            {{billprint.advance}}</td>
                        </tr> -->
                        <tr [hidden]="advanceFlag">
                          <td style="width: 300px;background-color: #ffffff"></td>
                          <th style="text-align: left;">Paid (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                            class="fee_txt">
                            {{paid}}</td>
                        </tr>
                        <tr>
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Balance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance" class="fee_txt">
                            {{billprint.balance}}</td>
                        </tr>
                      </table><br /><br /><br />
                    </div>
                  </div>
                </div>
               
              </div>
              <br />
              <!-- //Treate ment plan table -->
              <br />
              <br />
              <br /><br />
            </div>
            <br />
          </div>
          <div #logowithname id="logowithname">
            <div class="bill_border" style="margin: 0 15px;">
              <div *ngFor="let billprint of billPrintArray">
                <!-- Doctor details -->
                <div>
                  <table style="width: 100%;">
                    <tr>
                      <td style="display: flex;justify-content: center;">
                        <div>
                          <img alt="image" src={{hospitalLogo}} [style]="print_style">
                        </div>
                        <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                          <span><b>{{billprint.diag_centre_name}}</b>,</span><br />
                          <span *ngIf="billprint.address != ''">{{billprint.address}}</span><br />
                          <span>{{billprint.location}}</span> <span>{{billprint.city}}</span><br />
                          <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img src="../../../assets/img/phone.png" width="15px"
                              height="15px" />{{billprint.telephone}}.</span><br>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
                <table style="margin-left: 50px;    width: 100%;">
                  <tr>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                          <br />
                          <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                          <br />
                          <span><strong> UHID : </strong>{{billprint.client_reg_id}}</span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress"><strong>Bill number : </strong>{{billID}} </span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress"><strong>Bill date : </strong>{{billprint.created_date}} </span>
                        </div>
                      </div>
                    </td>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4">
                          <span [hidden]="noaddress"><strong>Address</strong></span>
                          <br />
                          <span [hidden]="noaddress"> {{billprint.caddress}}</span>
                          <span [hidden]="!noaddress"><strong>Bill number : </strong>{{billID}} </span>
                          <br />
                          <span [hidden]="noaddress">{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <span [hidden]="!noaddress"><strong>Bill date : </strong>{{billprint.created_date}} </span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress">{{billprint.clestare}}</span>
                          <br [hidden]="noaddress"/>
                          <span [hidden]="noaddress">{{billprint.ccountry}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <!-- Bill particulars -->
                <div>
                  <h3 class="heading" style="margin-left: 50px;">Bill particulars</h3>
                  <div class="row">
                    <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                      <tr>
                        <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b>
                        </th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Test name</b>
                        </th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Payment type</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Quantity</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Concession</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                      </tr>
                      <tr *ngFor="let billprinttable of billingDetails">
                        <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.sno}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.test_name}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.pay_type}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee">{{billprinttable.fee}}</td>
                        <td style="width: 150px; text-align: center; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee">{{billprinttable.quantity}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Concession">{{billprinttable.concession}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Amount" class="fee_txt">{{billprinttable.finalfee}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <br />
                <!-- Total calculation table -->
                <div class="row">
                  <div class="col-7 col-sm-4 col-md-7 col-lg-7 col-xl-7" style="margin-left: 50px !important;">
                    <div *ngIf="!paytypreq">
                      <table class="table table-hover table-dynamic" style="margin-top: 20px;">
                        <tr *ngFor="let pay of paymentsarray">
                          <td style="width: 200px !important;">{{pay.pay_desc}}</td>
                          <td style="width: 200px !important;">{{pay.amount}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px;">
                    <div style="width: 100%;">
                      <table  style="float: right;">
                        <!-- <tr [hidden]="totalShow">
                          <td style="width: 510px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Total (र) </th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र" class="fee_txt">
                            {{totalAmount}}</td>
                        </tr> -->
                        <tr [hidden]="centralGST">
                          <td style="width: 510px;background-color: #ffffff;"></td>
                          <th style="text-align: left;"><strong>Central GST </strong> % </th>
                          <td style="text-align: right; width: 100px;margin-right: 10px;" data-th="Central GST"
                            class="fee_txt">
                            {{centralGST}}</td>
                        </tr>
                        <tr [hidden]="stateGST">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;"><strong>State GST </strong> % </th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="State GST"
                            class="fee_txt">
                            {{stateGST}}</td>
                        </tr>
                        <!-- <tr [hidden]="prevBalFlag">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Previous balance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Previous balance र"
                            class="fee_txt">{{prevBlance}}</td>
                        </tr> -->
                        <tr [hidden]="totalShow">
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Bill amount (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                            class="fee_txt">
                            {{total}}</td>
                        </tr>
                        <!-- <tr [hidden]="advanceFlag">
                          <td style="width: 300px;background-color: #ffffff"></td>
                          <th style="text-align: left;">Advance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                            class="fee_txt">
                            {{billprint.advance}}</td>
                        </tr> -->
                        <tr [hidden]="advanceFlag">
                          <td style="width: 300px;background-color: #ffffff"></td>
                          <th style="text-align: left;">Paid (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                            class="fee_txt">
                            {{paid}}</td>
                        </tr>
                        <tr>
                          <td style="width: 300px;background-color: #ffffff;"></td>
                          <th style="text-align: left;">Balance (र)</th>
                          <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance" class="fee_txt">
                            {{billprint.balance}}</td>
                        </tr>
                      </table><br /><br /><br />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <!-- //Treate ment plan table -->
              <br />
              <br />
              <br /><br />
            </div>
            <br />
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>