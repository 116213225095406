<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill details</h5>
          </div>
          <div class="headerButtons">
            <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" /></a> &nbsp;
          <a *ngIf="saveFlag" (click)="save()"><img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Doctor<br>
              <select required class="ipcss " (change)="getAppointments()" [(ngModel)]="docRegId"
                disableOptionCentering>
                <option *ngFor="let doctors of doctorList" value="{{doctors.doc_id}}">{{doctors.doc_name}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Appointment id<br>
              <select required class="ipcss " [(ngModel)]="appointmentId" (change)="changeAppointmentId()"
                disableOptionCentering>
                <option *ngFor="let billcreate of billingList" value="{{billcreate.doc_app_id}}">
                  {{billcreate.docid_name}}</option>
              </select>
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Hospital name<br>
              <input class="ipcss  " matInput [(ngModel)]="hospitalName" />
            </mat-label>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Patient name<br>
              <input class="ipcss  " matInput [(ngModel)]="patientName" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Service type<br>
              <input type="text" class="ipcss inpat_width" [(ngModel)]="chargeType"
              (keyup)="getServiceType(chargeType)" required matInput/>
              <div class="auto_complete_text1">
                  <ul *ngFor="let charge of serviceType">
                      <li>
                          <a (click)="changeChargeType(charge)">{{charge}}</a>
                      </li>
                  </ul>
              </div>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <select class="ipcss " [(ngModel)]="payType" (change)="changePayType()"
                disableOptionCentering>
                <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="insurFlag">
            <mat-label class="matlabel" [hidden]="insurFlag">Insurer<br>
              <select required class="ipcss " [(ngModel)]="insurerId" disableOptionCentering [required]="insreqflag">
                <option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="cardreqflag">
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="transactionFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" [required]="transreqflag"/>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="chequereqflag"/>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" [required]="chequereqflag"/>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bill_qty_flag">
            <mat-label class="matlabel">Quantity<br>
              <input type=number class="ipcss billing_text_align" matInput required (keyup)="changeAmount()"
                [(ngModel)]="quantity" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Fee<br>
              <input class="ipcss billing_text_align  " matInput required (keyup)="changeAmount()"
                [(ngModel)]="amount" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bill_cons_flag">
            <mat-label class="matlabel">Concession (%)<br>
              <input class="ipcss  " matInput (keyup)="changeAmount()" [(ngModel)]="concession" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bill_cons_flag">
            <mat-label class="matlabel">After concession
              <input class="ipcss billing_text_align " [(ngModel)]="amountAftCon" />
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-label class="matlabel ">
              <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align"
                (click)="addBill()" style="float: right;"/>
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <div class="table-responsive" *ngIf="billAddList.length">
              <table  class="table table-nowrap table-sm billcreae_table bill_edit_table">
                <thead class="tableheading"> 
                  <tr style="height: 32px;">
                    <th>Sno</th>
                    <th>Service</th>
                    <th [hidden]="paytypreq">Payment type</th>
                    <th>Fee</th>
                    <th [hidden]="bill_qty_flag">Quantity</th>
                    <th [hidden]="bill_cons_flag">Concession %</th>
                    <th>Amount</th>
                    <th class="delete">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of billAddList" class="tdvalues">
                    <td >{{person.sno}}</td>
                    <td >{{person.biill_type}}</td>
                    <td [hidden]="paytypreq">{{person.pay_type}}</td>
                    <td style="text-align: right;">{{person.amount_txt}} </td>
                    <td style="text-align: center;" [hidden]="bill_qty_flag">{{person.quantity}}</td>
                    <td [hidden]="bill_cons_flag">{{person.concession}}</td>
                    <td class="billlistleft" style="text-align: right !important;">{{person.fee}}</td>
                    <td class="delete" >
                      <a (click)="deleteBill(person.biill_type,person.amount_txt)"> <img
                          src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" alt=""></a>
                    </td>                 
                  </tr>
                </tbody>
              </table> 
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" [hidden]="!paytypreq">
              <div class="row">
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                    <select class="ipcss " [(ngModel)]="payType1" 
                      disableOptionCentering (change)="changePayTypeReq()">
                      <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insurFlag1">
                  <mat-label class="matlabel">Insurer<br>
                    <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerId" disableOptionCentering>
                      <mat-option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="cardFlag1">
                  <mat-label class="matlabel">Card No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" required />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="tranFlag1">
                  <mat-label class="matlabel">Transaction ID<br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" required/>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="chequeFlag1">
                  <mat-label class="matlabel">Cheque No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" required/>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-3" >
                  <mat-label class="matlabel">Amount<br>
                    <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput required maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/next_button.svg" class="amt_button add_align"
                      (click)="addPaymentDetails()" />
                  </mat-label>
                </div>
              </div>

              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                      <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-0 col-sm-2  col-md-2 col-lg-2 col-xl-2"></div>
          <div class="col-0 col-sm-6  col-md-3 col-lg-3  col-xl-3">
            <div>
              <div class="row">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Include GST &nbsp;&nbsp; </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST" (change)="includeGst(inGST)">
                  </mat-checkbox>
                </div>
                <div *ngFor="let gsttax of gstDataList">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                    </div>
                    <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{gsttax.Gst_amount}}</mat-label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="showPrevBalanceFlag">
                  <mat-label class="matlabel  heightmsr">Previous balance </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="showPrevBalanceFlag">
                  <input class="ipcss  heightmsr bill_txt" matInput text-right [(ngModel)]="prevBalance" maxlength="10"
                    disabled />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Total </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                    [(ngModel)]="finalAmount" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr bill_txt">Discount </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" style="display: flex;
                align-items: center;">
                  <!-- <span class="roundoffButton"><img src="../../../assets/ui_icons/minus_icon.svg" alt="" (click)="addRound('minus')"></span><span class="roundoffButton"><img src="../../../assets/ui_icons/plus_icon.svg" style="margin: 0 0 0 5px;" alt="" (click)="addRound('puls')"></span> -->
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right 
                    [(ngModel)]="discount" maxlength="10" (keyup)="addRound('minus')" style="display: inline-block !important;
                    width: 100%;
                    margin: 0px 0px 4px 9px;"/> 
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Advance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="changeAdvance()"
                    [(ngModel)]="advanceBill" maxlength="10" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Paid</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="changePaid()"
                    [(ngModel)]="paidBill" maxlength="10" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr" [hidden]="showBalanceFlag">Balance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss billcreatech heightmsr bill_txt" [hidden]="showBalanceFlag" matInput text-right
                    [(ngModel)]="remainingBalance" maxlength="10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>