import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Date_Formate } from 'src/assets/js/common';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagnosis-report-approval',
  templateUrl: './diagnosis-report-approval.component.html',
  styleUrls: ['./diagnosis-report-approval.component.scss']
})
export class DiagnosisReportApprovalComponent implements OnInit {
  approvelListArrray: any = [];
  getListArray: any = [];
  public locationArray = [];
  public userType;
  public location;
  public clientGender;
  public clientAge;
  public tableTypeTestFlag: boolean;
  public diagApprovelType;
  public diagType;
  public diagID;
  public diagLocation: boolean;
  dtOptions: DataTables.Settings = {};
  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) {
    this.clientGender = "0";
    this.clientAge = "00";
    this.tableTypeTestFlag = true;
    this.diagLocation = true;
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[1, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or test type or test name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if (Diagnosis_Helper.getUserID_Type().user_type != null) {
      this.getRetrivel(Helper_Class.getHospitalInfo().hptl_clinic_id);
      this.diagLocation = false;
    } else {
      this.diagLocation = true;
      this.getLocation();
    }
  }
  getLocation() { //Get location
    this.locationArray = [];
    if (Diagnosis_Helper.getUserID_Type().user_type != null) {
      this.userType = "diag_user";
      this.diagID = Helper_Class.getHospitalInfo().hptl_clinic_id;
    } else {
      this.userType = "diagnosis";
      this.diagID = Diagnosis_Helper.getUserID_Type().user_id;
    }
    var send_data = {
      diag_name: this.diagID,
      flag: "id",
      imei: Helper_Class.getIPAddress(),
      prov_id: Diagnosis_Helper.getUserID_Type().user_id,
      type: this.userType
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
      JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.diag_loc.length; i++) {
            this.locationArray.push({
              centre_id: obj.diag_loc[i].diag_centre_id,
              centre_name: obj.diag_loc[i].diag_centre_name,
              centre_location: obj.diag_loc[i].diag_loc,
              view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
            });
          }
          this.location = this.locationArray[0].view_data;
          this.getRetrivel(this.locationArray[0].centre_id);
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  changeLocation(info) {
    if (this.locationArray != undefined) {
      for (var i = 0; i < this.locationArray.length; i++) {
        if (this.locationArray[i].view_data == info) {
          this.getRetrivel(this.locationArray[i].centre_id);
        }
      }
    }
  }
  getRetrivel(info) {
    var send_data = {
      diag_centre_id: info,
      prov_id: Diagnosis_Helper.getUserID_Type().user_id,
      type: this.userType,
      imei: Helper_Class.getIPAddress()
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gtapp", JSON.stringify(send_data), { headers: headers }).subscribe(
      data => {
        this.approvelListArrray = [];
        if (data.json().test_approval != null) {
          var firstname, lastname;
          this.getListArray = data.json().test_approval;
          for (var i = 0; i < this.getListArray.length; i++) {
            if (this.getListArray[i].first_name != undefined) {
              if (this.getListArray[i].last_name != undefined) {
                if (encrypt_decript.Decript(this.getListArray[i].first_name) != "" && encrypt_decript.Decript(this.getListArray[i].last_name) != "") {
                  firstname = encrypt_decript.Decript(this.getListArray[i].first_name);
                  lastname = encrypt_decript.Decript(this.getListArray[i].last_name);
                }
              }
              else {
                firstname = encrypt_decript.Decript(this.getListArray[i].first_name);
                lastname = "";
              }
            } else {
              firstname = "";
              lastname = "";
            }
            var date = this.getListArray[i].created_date != undefined ? Date_Formate(this.getListArray[i].created_date) : "";
            this.approvelListArrray.push({
              f_name: firstname,
              l_name: lastname,
              test_name: this.getListArray[i].test_type,
              app_id: this.getListArray[i].diag_app_id,
              test_id: this.getListArray[i].pres_test_id,
              test_type: this.getListArray[i].test_type,
              diag_id: this.getListArray[i].pres_diag_id,
              createdDate : date,
            });
          }
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }
  viewReportDetails(testID, diagAppID, testType, diagID, testName) {
    Diagnosis_Helper.setReportApproval(null);
    if (testType == "Blood Test") {
      this.diagApprovelType = "blood";
    } else if (testType == "Urine Test") {
      this.diagApprovelType = "urine";
    } else if (testType == "Faeces Test") {
      this.diagApprovelType = "faeces";
    } else if (testType == "X-ray") {
      this.diagApprovelType = "X-ray";
    } else if (testType == "Scan") {
      this.diagApprovelType = "scans";
    } else if (testType == "Ultra Sound") {
      this.diagApprovelType = "Ultra-Sound";
    } else if (testType == "Culture") {
      this.diagApprovelType = "Culture";
    }else if (testType == "Biopsy") {
      this.diagApprovelType = "Biopsy";
    }
    this.diagType = "Approval";
    var report = {
      type: this.diagApprovelType,
      diag_app_id: diagAppID,
      pres_test_id: testID,
      pres_diag_id: diagID,
      Diag_report_flag: this.diagType,
      user: "diagnosis",
      test_name: testName
    };
    Diagnosis_Helper.setReportApproval(report);
    this.messageservice.sendMessage("report_approval_detailed");
  }
}
