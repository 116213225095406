<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white" style="border-bottom: 0 !important;" >
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Billing detail view</h5>
            </div>
            <div class="headerButtons">
              <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                  class="saveimgbtn_inpatinfo" /></a>
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div *ngFor="let billprint of billingDetailList">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                <div [hidden]="hospAddress">
                  <table>
                    <tr>
                      <td style="text-align: left !important;font-size: 12px;"><b>{{billprint.hptl_name}}</b></td>
                    </tr>
                    <tr>
                      <td class="changefont">{{billprint.address}}</td>
                    </tr>
                    <tr>
                      <td class="changefont">{{billprint.location}}{{billprint.city}}</td>
                    </tr>
                    <tr>
                      <td class="changefont">{{billprint.state}}</td>
                    </tr>
                    <tr>
                      <td class="changefont"> <img class="phone_img" height="15px" width="15px"
                          src="../../../assets/img/phone.png" /> {{billprint.telephone}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <table>
                  <tr>
                    <th style="text-align: left !important;font-size: 12px;">Bill date : </th>
                    <td>
                      {{billprint.created_date}}
                    </td>
                  </tr>
                  <tr [hidden]="mainBalanceFlag">
                    <th style="text-align: left !important;font-size: 12px;">Amount due र  :</th>
                    <td style="text-align: left !important;font-size: 12px;">
                      {{billprint.balance}}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row chwidth">
              <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="tervys_heading ">Patient details</p>
                <table>
                  <tr>
                    <th style="text-align: left !important;font-size: 12px;" class="thvalue">Patient name : </th>
                    <td style="text-align: left;font-size: 12px;">
                      {{billprint.client_name}}
                    </td>
                  </tr>
                  <tr>
                    <th style="text-align: left !important;font-size: 12px;
                    " class="thvalue">UHID : </th>
                    <td style="text-align: left;font-size: 12px;">
                      {{billprint.client_reg_id}}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="tervys_heading">Address</p>
                <!-- <p class="tervys_heading" style="font-weight: 500;"><b>Address:</b></p> -->
                <table>
                  <tr>
                    <td class="thvalue" style="font-size: 12px;">{{billprint.caddress}}</td>
                  </tr>
                  <tr>
                    <td class="thvalue" style="font-size: 12px;">{{billprint.clocation}}{{ billprint.clientcity}}</td>
                  </tr>
                  <tr>
                    <td class="thvalue" style="font-size: 12px;">{{billprint.clestare}}</td>
                  </tr>
                  <tr>
                    <td class="thvalue" style="font-size: 12px;">{{billprint.ccountry}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row">
                <!-- [hidden]="bill_printconcise" -->
              <div class="col-12"  *ngIf="billingPrintView.length !=0" >
                <p class=" col-md-3 col-lg-3 col-xl-3 col-sm-3" *ngIf="billingPrintView.length !=0"
                  class="tervys_heading" style="font-weight: 500;">Bill particulars</p>
              </div>
              <div class="table-responsive" *ngIf="billingDetialedView.length">
                <table id="card_tbl" class="table table-nowrap table-sm">
                  <thead>
                    <tr style="height: 30px;">
                      <th>Service type</th>
                      <th>Payment type</th>
                      <th>Fee</th>
                      <th>Concession (%)</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="height: 30px;" *ngFor="let person of billingDetialedView">
                      <!-- <td><img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td> -->
                      <td style="font-size: 12px;" class="txtalign">{{person.test_name}}</td>
                      <td style="font-size: 12px;" class="txtalign">{{person.pay_type}}</td>
                      <td style="font-size: 12px;" class="txtalignright">{{person.fee}}</td>
                      <!-- <td style="font-size: 12px;text-align: center;">{{person.amount_txt}} </td> -->
                      <td style="font-size: 12px;" class="txtalignright">{{person.concession}}</td>
                      <td class="billlistleft" style="font-size: 12px;" class="txtalignright">{{person.fee}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div [hidden]="treatmentPlanFlag">
              <p class="casesheet_para">Treatement plan</p>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="treatmentPlanFlag">
              <!-- // <p class="nodata" *ngIf="billden_plan.length == 0">No Appointment(s) Found</p>     -->
              <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th>Plan</th>
                    <th class="tbl_txt_center">Estimate</th>
                    <th class="tbl_txt_center">Select</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of estimatePrintData let i = index">
                    <td>{{person.treatment_desc}}</td>
                    <td class="tbl_txt_center">{{person.estimate}}</td> 
                  </tr>
                </tbody>
              </table>
              <br><br><br>
            </div>
            <div class="row">
              <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-8"></div>
              <div class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px;padding-right: 19px;">
                <div class="row">
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="totalFlag">
                    <mat-label class="matlabel ">Total र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="totalFlag">
                    <p class="txtalignright"><span class="">{{totalBill}}</span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="cgstFlag">
                    <mat-label class="matlabel">Central GST {{billprint.cgst}}% </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="cgstFlag">
                    <p class="txtalignright"><span class="">{{cgst}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="sgstFlag">
                    <mat-label class="matlabel">State GST {{billprint.cgst}} %</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="sgstFlag">
                    <p class="txtalignright"><span class="">{{sgst}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="prevBalanceFlag">
                    <mat-label class="matlabel"> Previous balance र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="prevBalanceFlag">
                    <p class="txtalignright"><span class="">{{prevBalance}}</span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="totalFlag">
                    <mat-label class="matlabel "> Bill amount र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="totalFlag">
                    <p class="txtalignright"><span class="">{{billAmount}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="billAdvanceFlag">
                    <mat-label class="matlabel">Advance / paid र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="billAdvanceFlag">
                    <p class="txtalignright"><span class="">{{billprint.advance}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight">
                    <mat-label class="matlabel">Balance र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4">
                    <p class="txtalignright"><span class="">{{billprint.balance}}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>