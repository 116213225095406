import { Component, OnInit, HostListener, ElementRef, ViewChild, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Observable, Observer, Subscription } from 'rxjs';
import { ipaddress } from '../../ipaddress';
import { ChangePasswordComponent } from '../../common-module/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { NurseService } from './nurse.service';
import { MasterHelperService } from 'src/app/Doctor_module/MasterHelper.service';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;
@Component({
  selector: 'app-nurse-view',
  templateUrl: './nurse-view.component.html',
  styleUrls: ['./nurse-view.component.scss']
})
export class NurseViewComponent implements OnInit {
  public max = "max";
  public openFlag: boolean = true;
  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public hospitalDetails;
  public profileImage;
  public firstName;
  public lastName;
  public messages;
  public msgFlag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;
  public settings: boolean = false;
  public subMenuFlag: boolean = false;
  public dashBoardSubMenuFlag: boolean = false;
  public showSubMenuFlag;
  public minimizedAsied: boolean = false;
  public selectedMenu;
  public appointmentData: boolean;
  public displayPage;
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;
  public DiagBill: boolean;
  public reportHidden: boolean;
  public repappHidden: boolean;
  public Dashboardhidden: boolean;
  public Menuappointments: boolean;
  public menuReports: boolean;
  public reportApproval: boolean;
  public user: boolean;
  public testData: boolean;
  public NepappHidden: boolean;
  public diabappHidden: boolean;
  public userHidden: boolean;
  subscription: Subscription;
  public reference_video: boolean = false;
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute,
    public router: Router, public toastr: ToastrService, public dialog: MatDialog,
    public messageservice: MenuViewService, public masterData: MasterHelperService) {
    this.menuNav("appointment");
    this.masterData.callMasterData();
  }
  ngOnInit(): void {
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
        this.menuNav(message);
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.firstName = this.userInfo.first_name;
    this.lastName = this.userInfo.last_name;
    this.profileImage = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.hospitalDetails = this.userInfo.hospitals;
    this.hospitalName = this.hospitalDetails[0].hptl_name;
    this.hospitalLogo = ipaddress.Ip_with_img_address + this.hospitalDetails[0].logo;
    this.subMenuFlag = false;
    this.dashBoardSubMenuFlag = false;
    this.minimizedAsied = false;
    var Moduleidlist;
    if (Helper_Class.getmodulelist() != null) {
       Moduleidlist = Helper_Class.getmodulelist();
       console.log("Moduleidlist" + JSON.stringify(Moduleidlist));
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "46") { // reference video
          this.reference_video = false;
        }
      }
    }

  }
  open_setting() {
    this.settings = !this.settings;
  }
  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: "diagnosis",
        user: this.userInfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },
        error => {
          this.toastr.error(Message_data.network);
        }
      )
  }
  menuNav(page) {
    let page_nmae: string = page;
    this.selectedMenu = page;
    this.subMenuFlag = false;
    switch (page_nmae) {
      case 'profile': this.displayPage = page; break;
      case 'appointment': this.displayPage = page; break;
      case 'appoinmentDetailView': this.displayPage = page; break;
      case 'assignList': this.displayPage = page; break;
      case 'assignDetailView': this.displayPage = page; break;
      case 'doctorAppList': this.displayPage = page; break;
      case 'doctorList': this.displayPage = page; break;
      case 'inpatient': this.displayPage = page; break;
      case 'inPatientViewInfo': this.displayPage = page; break;
      case 'medicine_tracker': this.displayPage = page; break;
      case 'DoctorAppDetail': this.displayPage = page; break;
      case 'viewIpatient': this.displayPage = page; break;
      case 'casesheet': this.displayPage = page; break;
      case 'casesheet_list': this.displayPage = page; break;
      case 'cardio_casesheet_view': this.displayPage = page; break;
    }
    customjs.SelectMenuFun(this.selectedMenu, "diag");
  }
  minimizing() {
    this.minimizedAsied = !this.minimizedAsied;
    if (this.minimizedAsied == true) {
      this.asied_width.nativeElement.setAttribute('style', 'width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 12px 0 0 3px;}');
      this.subMenuFlag = false;
    } else {
      this.asied_width.nativeElement.setAttribute('style', 'width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 17px 0 0 14px;}');
    }
  }
  onResize(event) {
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimizedAsied = false;
  }
  openMenumodel() {
    this.minimizedAsied = false;
    customjs.openMobileMenuFun();
  }
  MenuModelAction(action) {
    if (action == 'profile') {
      this.menuNav(action);
    } else if (action == 'changePassword') {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {}
      });
    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "diagnosis",
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut)
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => {
            this.toastr.error(Message_data.network);
          }
        )
    }
    $('#exampleModal').modal('hide');
  }
}
