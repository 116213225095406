import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Time_Formate_Session } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-nurse-appointments',
  templateUrl: './nurse-appointments.component.html',
  styleUrls: ['./nurse-appointments.component.scss']
})
export class NurseAppointmentsComponent implements OnInit {
  @ViewChild('dateTime') dateTime;
  masterSelected: boolean = false;
  public openCount: number = 0;
  public cancelCheckFlag: boolean;
  public cancelFoooter: boolean;
  public checkedList: any;
  public currentDatetime = null;
  public AppointDate = null;
  public selectFilter: string;
  public cancelArray = [];
  public noApp: boolean;
  public homeCare: string;
  public caseSheet: string;
  public caseSummery: string;
  public optionList: boolean;
  public appointmentArray = [];
  public loader: boolean;
  public getAppDAte;
  public physioReg;
  public hospitalArray = [];
  public splArray = [];
  public splName;
  public session;
  public appointmentDate: string;
  public userInfo: any = [];
  public userID;
  public checkListArray = [];
  public checkListIDArray: any = [];
  public event = {
    month: '1990-02-19',
    timeStarts: '07:43',
    timeEnds: '1990-02-20'
  }
  dtOptions: DataTables.Settings = {};
  constructor(public http: Http, public toastr: ToastrService, public location: Location, public messageService: MenuViewService) {
    this.session = "Morning";
    this.cancelCheckFlag = true; // hide cancel checkbox
    this.cancelFoooter = true; // hide cancel_foooter
    this.noApp = true; // hide
    this.homeCare = "1";
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 4, 5, 7, 9] },
        { "width": "100px", "targets": 8 },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.userID = this.userInfo.user_id;
    this.getCurrentDate();
  }
  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.appointmentDate = obj.current_date;
            this.getAppointments(this.userID);
          }
        }, error => { });
  }
  cancelAppointment() {
    this.cancelCheckFlag = true; // hide cancel checkbox
    this.cancelFoooter = true; // hide cancel_foooter
  }
  optionsPopover() {
    this.optionList = false;
    this.cancelCheckFlag = false; // show cancel checkbox
    this.cancelFoooter = false; // show cancel_foooter
    this.getAppointments("cancel"); // cancel multiple apps pass "cancel"
  }
  cancelMultiApp(app_id, check) {// multiple cancellation
    if (check == true) {
      this.cancelArray.push(app_id);
    } else {
      for (var i = 0; i < this.cancelArray.length; i++) {
        if (app_id == this.cancelArray[i]) {
          this.cancelArray.splice(this.cancelArray.indexOf(app_id), 1);
          break;
        }
      }
    }
  }
  checkUncheckAll() {
    this.checkListArray = this.appointmentArray;
    if (this.checkListIDArray != "" && this.checkListIDArray.length != 0) {
      if (this.checkListIDArray.length == this.openCount) {
        // uncheck all
        this.checkListIDArray = [];
        for (var i = 0; i < this.checkListArray.length; i++) {
          this.checkListArray[i].isSelected = this.masterSelected;
        }
      } else {
        //  check all with array not empty        
        this.checkListIDArray = [];
        for (var i = 0; i < this.checkListArray.length; i++) {
          if (this.checkListArray[i].status == 'Open' || this.checkListArray[i].status == 'Confirmed') {
            var doc_id = this.checkListArray[i].doc_app_id;
            this.checkListIDArray.push(doc_id);
            this.checkListArray[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      // array empty
      for (var i = 0; i < this.checkListArray.length; i++) {
        if (this.checkListArray[i].status == 'Open' || this.checkListArray[i].status == 'Confirmed') {
          var doc_id = this.checkListArray[i].doc_app_id;
          this.checkListIDArray.push(doc_id);
          this.checkListArray[i].isSelected = this.masterSelected;
        }
      }
    }
  }
  Date_click(value) {
    this.appointmentDate = value;
    this.getAppointments(this.userID);
  }
  getAppointments(data): void {
    this.loader = false;
    this.appointmentArray = [];
    if (this.caseSheet != null || this.caseSummery != null) {
      this.homeCare = undefined
    }
    var get_tmp = this.appointmentDate.split('-');
    this.getAppDAte = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/npapps',
      JSON.stringify({
        prov_id: this.userID,
        country: ipaddress.country_code.toString(),
        home_care: this.homeCare,
        date: this.appointmentDate,
        imei: this.userInfo.imei_no,
        type: "nurse"
      }),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(response["_body"]);
          if (data.appointments != null) {
            this.loader = true;
            var appolist = data.appointments;
            if (appolist.length != "0") {
              for (var i = 0; i < appolist.length; i++) {
                if (appolist[i].status == "0") {
                  var status_txt = "Completed";
                }
                else if (appolist[i].status == "1") {
                  if (appolist[i].date == this.currentDatetime || this.currentDatetime < appolist[i].date) {
                    if (appolist[i].f_time != "1") {
                      var get_time = new Date(appolist[i].date + " " + this.currentDatetime);
                      var cur_time = new Date(this.currentDatetime + " " + this.currentDatetime);
                      if (get_time > cur_time) {
                        status_txt = "Not visited";
                      }
                      else {
                        status_txt = "Open";
                      }
                    } else {
                      // token
                      var get_time = new Date(appolist[i].date);
                      var cur_time = new Date(this.currentDatetime);
                      if (get_time > cur_time || get_time == cur_time) {
                        status_txt = "Open";
                      } else {
                        status_txt = "Not visited";
                      }
                    }
                  } else {
                    if (appolist[i].date > this.currentDatetime || appolist[i].date == this.currentDatetime) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else if (appolist[i].status == "2") { //$scope.appolist[i].f_time!="1" && 
                  if (appolist[i].date == this.currentDatetime || this.currentDatetime < appolist[i].date) {
                    var get_time = new Date(appolist[i].date + " " + this.currentDatetime);
                    var cur_time = new Date(this.currentDatetime + " " + this.currentDatetime);
                    status_txt = "Confirmed";
                  } else {
                    if (appolist[i].date > this.currentDatetime || appolist[i].date == this.currentDatetime) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                }
                if (appolist[i].token != null) {
                  var token_time_label = "Token no : " + appolist[i].token;
                } else {
                  var Temptimesplit = appolist[i].f_time;
                  var offTime = Temptimesplit.split(':');
                  offTime[0] = offTime[0] % 12;
                  var output = offTime.join(':');
                  var get_timeq = output.split(":");
                  if (get_timeq[0] == "0") {
                    // output="12:00:00";
                    output = "12" + ":" + get_timeq[1];
                  }
                  token_time_label = "Time : " + Time_Formate_Session(output, encrypt_decript.Decript(appolist[i].session));
                }
                var Patient_name = null;
                if (appolist[i].middle_name != undefined && appolist[i].middle_name != null) // middle name 
                {
                  if (encrypt_decript.Decript(appolist[i].middle_name) != "") // middle name empty
                  {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].middle_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  }
                  else {
                    Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                  }
                }
                else {
                  Patient_name = encrypt_decript.Decript(appolist[i].first_name) + " " + encrypt_decript.Decript(appolist[i].last_name);
                }
                if (appolist[i].sub_rel_id != null) {
                  var sub_rel = appolist[i].sub_rel_id;
                }
                else {
                  sub_rel = "0";
                }
                if (appolist[i].age != null) {
                  var get_age = appolist[i].age;
                }
                else {
                  get_age = "";
                }
                if (appolist[i].address2 != null && appolist[i].address2 != "") {
                  var add_data = appolist[i].address1 + " " + appolist[i].address2;
                }
                else {
                  add_data = appolist[i].address1;
                }
                if (appolist[i].rel_zipcode != null) {
                  var zipcode_data = encrypt_decript.Decript(appolist[i].rel_zipcode);
                }
                else {
                  zipcode_data = appolist[i].zipcode;
                }
                if (status_txt == 'Open') {
                  this.openCount = this.openCount + 1;
                }
                this.appointmentArray.push({
                  app_data: appolist[i],
                  hptl_clinic_id: appolist[i].hptl_clinic_id,
                  patient_name: Patient_name,
                  tot_app_count: token_time_label,
                  session: encrypt_decript.Decript(appolist[i].session),
                  status: status_txt,
                  profile_image: ipaddress.Ip_with_img_address + appolist[i].profile_image,
                  doc_app_id: appolist[i].app_id,
                  client_reg_id: appolist[i].client_reg_id,
                  relation_id: appolist[i].relation_id,
                  sub_rel: sub_rel,
                  rel_count: appolist[i].country_id,
                  rel_stat: appolist[i].state_id,
                  rel_city: appolist[i].city_id,
                  rel_loc: appolist[i].location_id,
                  rel_loc_desc: appolist[i].location,
                  loc_age: get_age,
                  //gender_data: encrypt_decript.Decript(appolist[i].gender),
                  dob_data: appolist[i].dob,
                  // spl: appolist[i].specialization,
                  // spl_id: appolist[i].specialization_id,
                  // medicare_name: appolist[i].medicare_name,
                  // hospital_name: appolist[i].hptl_name,
                  address: add_data,
                  state_desc: appolist[i].state_desc,
                  city_desc: appolist[i].city_desc,
                  country_desc: appolist[i].country_desc,
                  rel_zipcode: zipcode_data,
                });
              }
              this.noApp = true; // show
            } else {
              this.noApp = false; // show
            }
          } else {
            this.loader = true;
            this.toastr.error(Message_data.getNetworkMessage());
          }
        }, error => {
          this.loader = true;
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  Doctor_View(doc_app_id, cnt_id, rel_id, sub_id, spl, count, stat, cit, loc, age, gen, dob, patient_name, medicare_name, hosp_name, loc_txt, address,
    state_desc, city_desc, country_desc, rel_zipcode, hptl_clinic_id, app_data, spl_id) {
    Nurse_Helper.setClient_Info(null);
    var patient_list: any = [];
    Helper_Class.Set_client_view_app_data(app_data);
    Nurse_Helper.setClient_Info(app_data);
    patient_list = {
      Age_data: age,
      Gender_data: gen,
      app_id: doc_app_id,
      client_name: patient_name,
      Medicare_name: medicare_name,
      hospital_name: medicare_name,
      doctor_name: hosp_name,
      Client_id: app_data.client_reg_id,
      rel_id: app_data.relation_id,
      sub_id: app_data.sub_rel_id,
    };
    Nurse_Helper.setClient_Info(patient_list);
    this.messageService.sendMessage("appoinmentDetailView");
  }
  clearAll() {
    this.checkListArray = this.appointmentArray;
    for (var i = 0; i < this.checkListArray.length; i++) {
      this.checkListArray[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }
  isAllSelected(appid) {
    if (this.checkListIDArray != "" && this.checkListIDArray.length != 0) {
      if (this.checkListIDArray.indexOf(appid) !== -1) {
        var index = this.checkListIDArray.indexOf(appid);
        this.checkListIDArray.splice(index, 1);
      } else {
        this.checkListIDArray.push(appid);
      }
    } else {
      this.checkListIDArray.push(appid);
    }
  }
  removeDuplicates(arr) {//remove duplicates
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i]] = true;
    }
    arr = [];
    for (let key in obj) {
      arr.push(key);
    }
    return arr;
  }
  cancel() {
    if (this.checkListIDArray != "" && this.checkListIDArray.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          np_cancel: this.checkListIDArray,
          home_care: "0",
          type: "nurse",
          reason: "Busy"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.getAppointments(this.userID);
              }
            }
          }, error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }
}