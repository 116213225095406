import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate, Date_Formate } from '../../../assets/js/common.js';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
    selector: 'app-diagnosis-report-approval-detailedview',
    templateUrl: './diagnosis-report-approval-detailedview.component.html',
    styleUrls: ['./diagnosis-report-approval-detailedview.component.scss']
})
export class DiagnosisReportApprovalDetailedviewComponent implements OnInit {
    public tableViewTestFlag: boolean;
    public paraViewReportTypeFlag: boolean;
    public dynamicHeading;
    public updateButtonFlag: boolean;
    public reportType;
    public diagAppID;
    public presTestID;
    public diagID;
    public clientName;
    public clientGender;
    public clientAge;
    public reviewSign;
    public signFlag: boolean;
    public revieSigSection: boolean;
    public reviewFlag: boolean;
    public reviewBy;
    public sampleCollectedTime;
    public report_img;
    public doctorFlag: boolean;
    public sequences;
    public impression;
    public tempTestList = [];
    public DiagTestReadingArray = [];
    public diagName;
    public diagAdd;
    public diagLocation;
    public diagCityZip;
    public diagStateCon;
    public diagTelephone;
    public clentID;
    public diagReferBy;
    public date;
    public time;
    public testArray = [];
    public sequence;
    public Impression;
    public reportFlag;
    public approvelFlag: boolean;
    public uploadFlag: boolean;
    public loader: boolean;
    public reportDetail;
    public testName: string;
    public testMethod;
    public subtestFlag: boolean = false;
    public subTest;
    public subTestMedFlag: boolean;
    public createdBy;
    public detailedViewFlag: boolean = false;
    public culture;
    public cultureFlag: boolean = false;
    public patientID;
    constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) {
    }
    ngOnInit(): void {
        this.reportType = Diagnosis_Helper.getReportApproval().type; //navParams.get("type");  
        this.diagAppID = Diagnosis_Helper.getReportApproval().diag_app_id;  //navParams.get("diag_app_id");
        this.presTestID = Diagnosis_Helper.getReportApproval().pres_test_id; //navParams.get("pres_test_id");
        this.diagID = Diagnosis_Helper.getReportApproval().pres_diag_id; // navParams.get("pres_diag_id");
        this.reportFlag = Diagnosis_Helper.getReportApproval().Diag_report_flag; //navParams.get("Diag_report_flag");
        this.testName = Diagnosis_Helper.getReportApproval().test_name; //navParams.get("test_name");
        this.loader = true;
        if (this.reportFlag == "Approval") {
            this.approvelFlag = false;
            this.uploadFlag = true;
        } else {
            this.approvelFlag = true;
            this.uploadFlag = false;
        }
        if (this.reportType == "blood" || this.reportType == "urine" || this.reportType == "faeces") {
            this.tableViewTestFlag = false;
            this.paraViewReportTypeFlag = true;
        } else {// Scan == X-ray == Ultra sound
            this.tableViewTestFlag = true;
            this.paraViewReportTypeFlag = false;
            this.dynamicHeading = this.reportType == "scans" ? "Sequences" : "Observation";
            this.updateButtonFlag = this.reportType == "Approval" ? false : true;
            this.cultureFlag = this.reportType == "Culture" ? true : false;
        }
        this.clientGender = "0";
        this.clientAge = "0";
        this.getRetrivel();
    }
    getRetrivel() { //Get report details
        this.loader = false;
        this.tempTestList = [];
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'record/diagreportview/',
            JSON.stringify({
                flag: "diagnosis",
                country: ipaddress.country_code,
                diag_app_id: this.diagAppID,
                type: this.reportType,
                pres_test_id: this.presTestID
            }),
            { headers: headers })
            .subscribe(
                response => {
                    var obj = JSON.parse(response["_body"]);
                    if (obj != null) {
                        this.detailedViewFlag = true;
                        this.loader = true;
                        this.diagName = obj.diag_centre_name;
                        this.diagAdd = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;
                        if (obj.date_entered != undefined)
                            this.date = Date_Formate(obj.date_entered);
                        if (obj.time_entered != undefined)
                            this.time = Time_Formate(obj.time_entered);
                        this.diagLocation = obj.location;
                        this.diagCityZip = obj.city + "-" + obj.zipcode;
                        this.diagStateCon = obj.state + "-" + obj.country;
                        this.diagTelephone = obj.telephone;
                        this.clentID = obj.client_reg_id;
                        this.patientID = obj.patient_id;
                        if (obj.first_name != null) {
                            if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "")
                                this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                            else
                                this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                        }
                        if (obj.doc_first_name != undefined) {
                            if (obj.doc_middle_name != undefined && obj.doc_middle_name != null)
                                this.diagReferBy = "Dr." + obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
                            else
                                this.diagReferBy = "Dr." + obj.doc_first_name + " " + obj.doc_last_name;
                        } else {
                            this.diagReferBy = "-";
                        }
                        if (obj.gender != null)
                            this.clientGender = encrypt_decript.Decript(obj.gender);
                        if (obj.age != null)
                            this.clientAge = obj.age == 0 ? "<1" : obj.age;
                        else
                            this.clientAge = "";
                        if (obj.sign != undefined && obj.sign != "") {
                            this.reviewSign = "data:image/png;base64," + obj.sign;
                            this.signFlag = false;
                        } else {
                            this.signFlag = true;
                        }
                        if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
                            this.revieSigSection = true;
                        this.reviewFlag = obj.app_first_name != null ? false : true;
                        this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;
                        if (obj.time_collected != null)
                            this.sampleCollectedTime = Time_Formate(obj.time_collected);
                        if (obj.main_tests != null && obj.main_tests.length != 0) {
                            if (obj.main_tests[0].image != undefined && obj.main_tests[0].image != "") {
                                this.reportDetail = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                                this.report_img = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                                this.doctorFlag = false;
                            } else {
                                this.doctorFlag = true;
                            }
                            for (var i = 0; i < obj.main_tests.length; i++) {
                                this.presTestID = obj.main_tests[i].pres_test_id;
                                this.sequences = obj.main_tests[i].sequences;
                                this.impression = obj.main_tests[i].impression;
                                if (obj.main_tests[i].created_by != undefined) {
                                    this.createdBy = obj.main_tests[i].created_by;
                                }
                                if (obj.main_tests[i].test_method != undefined) {
                                    this.testMethod = obj.main_tests[i].test_method;
                                }
                                this.subTest = obj.main_tests[i].test_name;
                                if (obj.main_tests[i].subtests != null) {
                                    for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                                        var temp_value = "";
                                        var test_method = "";
                                        this.subtestFlag = obj.main_tests[i].subtests[j].sub_test_name == this.subTest ? false : true;
                                        if (obj.main_tests[i].subtests[j].range != undefined)
                                            temp_value = obj.main_tests[i].subtests[j].range;
                                        if (obj.main_tests[i].subtests[j].test_method != undefined) {
                                            test_method = obj.main_tests[i].subtests[j].test_method;
                                            this.subTestMedFlag = true;
                                        } else {
                                            this.subTestMedFlag = false;
                                        }
                                        this.tempTestList.push({
                                            sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                            reading: obj.main_tests[i].subtests[j].reading,
                                            unit: obj.main_tests[i].subtests[j].sub_uom,
                                            test_range: temp_value,
                                            test_method: test_method,
                                        });
                                    }
                                }
                                this.DiagTestReadingArray.push({
                                    test_name: obj.main_tests[i].test_name,
                                    sub_test_list: this.tempTestList
                                });
                                for (var i = 0; i < obj.main_tests.length; i++) {
                                    if (obj.main_tests[i].image != undefined && obj.main_tests[i].image != "") {
                                        this.doctorFlag = false;
                                        this.reportDetail = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                        this.report_img = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                    } else {
                                        this.doctorFlag = true;
                                    }
                                    if (obj.main_tests[i].sequences != undefined && obj.main_tests[i].impression != undefined) {
                                        this.testArray.push({
                                            pres_test_id: obj.main_tests[i].pres_test_id,
                                            test_name: obj.main_tests[i].test_name,
                                            sequences: obj.main_tests[i].sequences,
                                            impression: obj.main_tests[i].impression,
                                            culture: obj.main_tests[i].culture,
                                        });
                                        for (var i = 0; i < this.testArray.length; i++) {
                                            this.sequence = this.testArray[i].sequences;
                                            this.Impression = this.testArray[i].impression;
                                            this.culture = this.testArray[i].culture;
                                        }
                                    } else if (obj.main_tests[i].culture != undefined) {
                                        this.testArray.push({
                                            pres_test_id: obj.main_tests[i].pres_test_id,
                                            test_name: obj.main_tests[i].test_name,
                                            culture: obj.main_tests[i].culture,
                                        });
                                        for (var i = 0; i < this.testArray.length; i++) {
                                            this.culture = this.testArray[i].culture;
                                            this.testName = this.testArray[i].test_name;
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                error => {
                    this.toastr.error(Message_data.getNetworkMessage());
                    this.loader = true;
                });
    }
    uploadReport() {//report upload
        var send_data;
        if (this.reportType == "scans") {
            send_data = {
                pres_diag_id: this.diagID,
                diag_app_id: this.diagAppID,
                test_id: this.presTestID,
                user_id: Diagnosis_Helper.getUserID_Type().user_id,
                country: ipaddress.country_code,
                test_type: this.reportType,
                sequences: this.sequence,
                impression: this.Impression,
            }
        } else {
            send_data = {
                pres_diag_id: this.diagID,
                diag_app_id: this.diagAppID,
                test_id: this.presTestID,
                user_id: Diagnosis_Helper.getUserID_Type().user_id,
                country: ipaddress.country_code,
                test_type: this.reportType,
                observation: this.sequence,
                impression: this.Impression,
            };
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/udreport/", JSON.stringify(send_data), { headers: headers }).subscribe(
            data => {
                if (data.json().key == "1") {
                    this.toastr.success(Message_data.rptApprovedSuccess);
                    this.messageservice.sendMessage("report_approval");
                } else {
                    if (data.json().result != null)
                        this.toastr.error(data.json().result);
                    else
                        this.toastr.error(Message_data.rptApprovedNotSuccess);
                }
            });
    }
    //Get zoom image
    clickDocument() {
        // this.navCtrl.push('ReportZoomPage', {
        //     report_image: this.reportDetail
        // })
    }
    rejectReport() {
        var send_data = {
            pres_diag_id: this.diagID,
            diag_app_id: this.diagAppID,
            test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.reportType,
            reject_reason: "modify report as discussed",
            type: "reject"
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/stapp/", JSON.stringify(send_data), { headers: headers }).subscribe(
            data => {
                if (data.json().key == "1") {
                    this.toastr.success(Message_data.rptRejectedSuccess);
                    this.messageservice.sendMessage("report_approval");
                } else {
                    if (data.json().result != null)
                        this.toastr.error(data.json().result);
                    else
                        this.toastr.error(Message_data.unableToRejectRpt);
                }
            });
    }
    approveReport() {//Reportapprove
        var send_data;
        if (this.reportType == "blood" || this.reportType == "urine" || this.reportType == "faeces") {
            send_data = {
                pres_diag_id: this.diagID,
                diag_app_id: this.diagAppID,
                test_id: this.presTestID,
                user_id: Diagnosis_Helper.getUserID_Type().user_id,
                country: ipaddress.country_code,
                test_type: this.reportType,
                type: "approve"
            }
        } else {
            if (this.reportType == "scans") {
                send_data = {
                    pres_diag_id: this.diagID,
                    diag_app_id: this.diagAppID,
                    test_id: this.presTestID,
                    user_id: Diagnosis_Helper.getUserID_Type().user_id,
                    country: ipaddress.country_code,
                    test_type: this.reportType,
                    sequences: this.sequence,
                    impression: this.Impression,
                    type: "approve"
                };
            } else {
                send_data = {
                    pres_diag_id: this.diagID,
                    diag_app_id: this.diagAppID,
                    test_id: this.presTestID,
                    user_id: Diagnosis_Helper.getUserID_Type().user_id,
                    country: ipaddress.country_code,
                    test_type: this.reportType,
                    observation: this.sequence,
                    impression: this.Impression,
                    type: "approve"
                }
            }
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/stapp/", JSON.stringify(send_data), { headers: headers }).subscribe(
            data => {
                if (data.json().key == "1") {
                    this.toastr.success(Message_data.rptApprovedSuccess);
                    this.messageservice.sendMessage("report_approval");
                } else {
                    if (data.json().result != null)
                        this.toastr.error(data.json().result);
                    else
                        this.toastr.error(Message_data.rptApprovedNotSuccess);
                }
            });
    }
    backNAV() {
        this.messageservice.sendMessage("report_approval");
    }
}
