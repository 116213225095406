import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Date_Formate } from 'src/assets/js/common';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagnosis-complete-report',
  templateUrl: './diagnosis-complete-report.component.html',
  styleUrls: ['./diagnosis-complete-report.component.scss']
})
export class DiagnosisCompleteReportComponent implements OnInit {
  public userType;
  public clientNameArray = [];
  public locationArray: any;
  public infoArray: any;
  public location;
  public centreLocation;
  public diagLocArray = [];
  public sendData: any = [];
  public currentData;
  date: any = [];
  public clientArray: any;
  updateListArray: any;
  updateDataArray: any = [];
  public type;
  public reportType;
  public reportFlag;
  public Select = "Select";
  public height;
  public info;
  dtOptions: DataTables.Settings = {};
  
  constructor(public messageservice: MenuViewService, public service: CommonDataService, public toastr: ToastrService, public http: Http) {
    Diagnosis_Helper.setReportView(undefined);
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patients with name or test type or test name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getDate();
    this.getLocation();
  }

  getLocation() {
    this.clientNameArray = [];
    this.userType = Diagnosis_Helper.getUserID_Type().user_type != null ? "diag_user" : "diagnosis";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/diagloc/", JSON.stringify({
      diag_name: Diagnosis_Helper.getUserID_Type().user_id,
      flag: "id",
      imei: localStorage.getItem("ipaddress"),
      prov_id: Diagnosis_Helper.getUserID_Type().user_id,
      type: this.userType,
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().diag_loc != null) {
          this.locationArray = data.json().diag_loc;
          this.infoArray = [];
          for (var i = 0; i < this.locationArray.length; i++) {
            this.infoArray = [{
              name: this.locationArray[0].diag_centre_name,
              id: this.locationArray[0].diag_centre_id,
              address1: this.locationArray[0].address1,
              address2: this.locationArray[0].address2,
              loc: this.locationArray[0].diag_loc,
              state: this.locationArray[0].state,
              city: this.locationArray[0].city,
              country: this.locationArray[0].country,
              zipcode: this.locationArray[0].zipcode,
              telephone: this.locationArray[0].telephone,
            }];
            this.info = this.locationArray[0].diag_centre_name + "-" + this.locationArray[0].diag_loc;
          }
          this.location = this.locationArray[0];
          this.changeLocation(this.locationArray[0]);
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().current_date != null) {
          this.currentData = data.json().current_date;
          var new_day = this.currentData.split('-');
          var current_date_data = new_day[2] + "-" + new_day[1] + "-" + new_day[0]
          this.date = [{
            day: current_date_data
          }];
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  getClient(i, data) {
    this.centreLocation = i.diag_centre_id;
    if (data != null) {
      this.getLocation();
      this.sendData = {
        diag_reg_id: Diagnosis_Helper.getUserID_Type().user_id,
        diag_user: "diag_user",
        location: i.diag_loc
      }
    } else {
      i.forEach(element => {
        this.sendData = {
          diag_reg_id: Diagnosis_Helper.getUserID_Type().user_id,
          location: element.loc
        }
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/getdiaapp/", JSON.stringify(
      this.sendData
    ), { headers: headers }).subscribe(
      data => {
        if (data.json().appointment_id != null) {
          this.clientArray = data.json().appointment_id;
          var firstname, lastname;
          this.clientNameArray = [];
          this.clientArray.forEach(element => {
            if (element.first_name != undefined) {
              if (element.last_name != undefined) {
                if (encrypt_decript.Decript(element.first_name) != "" && encrypt_decript.Decript(element.last_name) != "") {
                  firstname = encrypt_decript.Decript(element.first_name);
                  lastname = encrypt_decript.Decript(element.last_name);
                }
              } else {
                firstname = encrypt_decript.Decript(element.first_name);
                lastname = "";
              }
            } else {
              firstname = "";
              lastname = "";
            }
            this.clientNameArray.push({
              name: firstname + " " + lastname,
              id: element.app_id,
              rel_id: element.relation_id,
              sub_rel_id: element.sub_rel_id
            });
          });
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  changeLocation(id) {
    if (id.diag_centre_id != undefined) {
      this.updateDataArray = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gtedit/", JSON.stringify({
        diag_centre_id: id.diag_centre_id,
        type: "complete"
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().test_edit != null) {
            this.updateListArray = data.json().test_edit;
            this.updateListArray.forEach(element => {
              var firstname, lastname, status;
              if (element.first_name != undefined) {
                if (element.last_name != undefined) {
                  if (encrypt_decript.Decript(element.first_name) != "" && encrypt_decript.Decript(element.last_name) != "") {
                    firstname = encrypt_decript.Decript(element.first_name);
                    lastname = encrypt_decript.Decript(element.last_name);
                  }
                } else {
                  firstname = encrypt_decript.Decript(element.first_name);
                  lastname = "";
                }
              } else {
                firstname = "";
                lastname = "";
              }
              if (element.status == "0") {
                status = "Completed";
              } else {
                status = "Awaiting For Approval";
              }
              var date = element.approved_date != undefined ? Date_Formate(element.approved_date) : "";
              this.updateDataArray.push({
                first_name: firstname,
                last_name: lastname,
                diag_app_id: element.diag_app_id,
                pres_diag_id: element.pres_diag_id,
                test_type: element.test_type,
                pres_test_id: element.pres_test_id,
                test_name: element.test_name,
                status: status,
                approvedDate :  date
              })
            });
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    }
  }

  viewReportDetails(testtype, diag_app_id, pres_test_id, pres_diag_id, testname) {
    if (testtype == "Blood Test") {
      this.reportType = "blood";
    } else if (testtype == "Urine Test") {
      this.reportType = "urine";
    } else if (testtype == "Faeces Test") {
      this.reportType = "faeces";
    } else if (testtype == "X-ray") {
      this.reportType = "X-ray";
    } else if (testtype == "Scan") {
      this.reportType = "scans";
    } else if (testtype == "Ultra Sound") {
      this.reportType = "Ultra-Sound";
    } else if (testtype == "Culture") {
      this.reportType = "Culture";
    }
    this.reportFlag = "Modify";
    var report = {
      AppFlow: "reportview",
      type: this.reportType,
      diag_app_id: diag_app_id,
      report_diag_id: diag_app_id,
      pres_test_id: pres_test_id,
      pres_diag_id: pres_diag_id,
      Diag_report_flag: this.reportFlag,
      test_name: testname,
    };
    Diagnosis_Helper.setReportView(report);
    this.messageservice.sendMessage("report_upload_view");
  }
}
