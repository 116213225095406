<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Report Approval Details</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backNAV()"
                            class="saveimgbtn_inpatinfo" />
                        <img src="../../../assets/ui_icons/buttons/Reject_button.svg" (click)="rejectReport()"
                            class="saveimgbtn_inpatinfo" />
                        <img src="../../../assets/ui_icons/buttons/approve_button.svg" (click)="approveReport()"
                            class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div *ngIf="detailedViewFlag">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                            <mat-card class="card_addr">
                                <mat-card-title style="margin: 0px !important;" class="card_title tervys_heading">
                                    Address
                                    Details</mat-card-title>
                                <mat-card-content style="padding: 10px 24px;">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                        <mat-label class="matlabel"><strong>{{diagName}}</strong>
                                        </mat-label>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                        <mat-label class="matlabel">{{diagAdd}},{{diagLocation}},</mat-label>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                        <mat-label class="matlabel">{{diagCityZip}},{{diagStateCon}}</mat-label>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                        <mat-label class="matlabel">Mobile No:{{diagTelephone}}</mat-label>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                            <mat-card class="card_addr">
                                <mat-card-title style="margin: 0 !important;" class="card_title tervys_heading">Client
                                    Details</mat-card-title>
                                <mat-card-content style="padding: 10px 24px;">
                                    <div class="row">
                                        <div class="col-6">
                                            <mat-label class="matlabel"><strong>Date :</strong> {{date}}</mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel"><strong>Time :</strong> {{time}}</mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class='matlabel'><strong>Patient id :</strong> {{patientID}}
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel"><strong>Name :</strong> {{clientName}}
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel"><strong>Referred by :</strong> {{diagReferBy}}
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel"><strong>Age :</strong> {{clientAge}}
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel"><strong>Gender :</strong> {{clientGender}}
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel"><strong>Collected at :</strong>
                                                {{sampleCollectedTime}}</mat-label>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div [hidden]="tableViewTestFlag" style="margin-top: 37px;">
                        <p class="tervys_heading clinical col-12"><b>{{testName}} Results</b></p>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label *ngIf="subtestFlag" class="matlabel"><b>Test Name: {{subTest}}</b>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="dig_table_overflow">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-dynamic" id="card_tbl">
                                            <thead>
                                                <tr>
                                                    <th *ngIf="!subtestFlag">Test Name</th>
                                                    <th *ngIf="subtestFlag">Subtest Name</th>
                                                    <th>Reading</th>
                                                    <th>Unit</th>
                                                    <th>Referred value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let subtest of tempTestList">
                                                    <td style='text-align: left;'>
                                                        <strong>
                                                            <p>{{subtest.sub_test_name}}</p>
                                                        </strong>
                                                        <span *ngIf="subTestMedFlag" class="test_method">( <div
                                                                [innerHtml]="subtest.test_method"></div> )</span>
                                                    </td>
                                                    <td>{{subtest.reading}}</td>
                                                    <td>{{subtest.unit}}</td>
                                                    <td style='text-align: left;'>
                                                        <div [innerHtml]="subtest.test_range | safeHtml"
                                                            style="position: relative;top: 6px;"></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="paraViewReportTypeFlag">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:13px;">
                                <mat-label class="matlabel"><strong>Report type :</strong> {{reportType}}</mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:5px;">
                                <mat-label class="matlabel"><strong>Test name :</strong> {{testName}}</mat-label>
                            </div>
                            <div *ngIf="!cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style="margin-top:5px;">
                                <p class="tervys_heading clinical">Sequences</p>
                            </div>
                            <div *ngIf="!cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="matlabel" style="padding-left: 7px;" [innerHtml]="sequence | safeHtml">
                                </div>
                            </div>
                            <div *ngIf="!cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p class="tervys_heading clinical">Impression</p>
                            </div>
                            <div *ngIf="!cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="matlabel" style="padding-left: 7px;" [innerHtml]="impression | safeHtml">
                                </div>
                            </div>
                            <div *ngIf="cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p class="tervys_heading clinical">Report details</p>
                            </div>
                            <div *ngIf="cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="matlabel" style="padding-left: 7px;" [innerHtml]="culture | safeHtml">
                                </div>
                            </div>
                            <div [hidden]="doctorFlag" class="col-12">
                                <mat-card *ngIf="!cultureFlag">
                                    <mat-card-title class="tervys_heading">Attachment</mat-card-title>
                                    <mat-card-content>
                                        <img alt="report image" src="{{report_img}}" (click)="clickDocument()"
                                            class="report_img" />
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="testMethod != undefined && !subTestMedFlag" class="row" style="margin-top: 10px;">
                        <div class="col-1" style="min-width: fit-content;">
                            <mat-label class="matlabel"><b>Test Method :</b></mat-label>
                        </div>
                        <div class="col-10">
                            <div *ngIf="testMethod != undefined && !subTestMedFlag" class="col-12"
                                [innerHtml]="testMethod | safeHtml"></div>
                        </div>
                    </div>
                    <hr [hidden]="revieSigSection || createdBy == undefined" style="border: 1px solid #51b0e7;">
                    <div class="row" style="margin-top:5px;">
                        <div class="col-6">
                            <div class="row" [hidden]="createdBy == undefined">
                                <div [hidden]="createdBy == undefined"
                                    class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                                    <mat-label class="matlabel"><strong>Lab technician :</strong> {{createdBy}}
                                    </mat-label>
                                </div>
                                <div class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                    <mat-label class="matlabel"><strong>Signature :</strong>
                                        <!-- <img alt="" class="sign_img" /> -->
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row" [hidden]="revieSigSection">
                                <div [hidden]="reviewFlag" class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                                    <mat-label class="matlabel"><strong>(Chief) Pathologist :</strong> {{reviewBy}}
                                    </mat-label>
                                </div>
                                <div [hidden]="signFlag" class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                    <mat-label class="matlabel"><strong>Signature :</strong>
                                    </mat-label>
                                    <img alt="" src="{{reviewSign}}" class="sign_img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>