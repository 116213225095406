<div class="row" style="margin: 0 auto;">
  <div class="Details">
     <mat-tab-group [disableRipple]=true mat-align-tabs="start" [selectedIndex]="0">
        <mat-tab style="margin-left:7px;">
          <ng-template mat-tab-label>
            <img src="../../../assets/ui_icons/second_opinion/Pending_icon.svg" width="18px" height="auto"/>
            &nbsp; Pending
          </ng-template>
           <mdb-card style="width: 99% !important;margin-left: 3px;">
              <mdb-card-header class="bg-white" style="padding:7px 20px;border-bottom:none !important">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="m-0" class="mainHeadingStyle">Appointment list</h5>
                  <div>
                  </div>
                </div>
              </mdb-card-header>
              <mdb-card-body>
                <p class="nodata" *ngIf="secon_op_newlist.length == 0">No appointment(s) found</p>
                <table *ngIf="secon_op_newlist.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Age</th>
                      <th>Gender</th>
                      <th>Date </th>
                      <th>Location</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of secon_op_newlist; let i = index" (click)='view_click(list.opin_id,list.name,list.age,list.gender)'>
                      <td><img src="{{list.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';" height="40px" width="40px" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                      <td class="SP_table_content" style="text-align: left;">{{ list.mem_name }}</td>
                      <td class="SP_table_content">{{ list.age }}</td>
                      <td class="SP_table_content">{{ list.gender }}</td>
                      <td class="SP_table_content">{{ list.date_second }}</td>
                      <td class="SP_table_content">{{ list.location }}</td>
                      <td > 
                        <div style="position: relative;top: 9px;">
                          <img *ngIf="list.status == 'Cancelled'" width="30px" height="auto" src="../../../assets/ui_icons/second_opinion/cancelled.svg" />
                          <img *ngIf="list.status == 'Waiting for review'"  width="30px" height="auto" src="../../../assets/ui_icons/second_opinion/waiting_for_review_icon.svg" />
                          <img *ngIf="list.status == 'Review completed'"  width="30px" height="auto" src="../../../assets/ui_icons/second_opinion/review_completed.svg" />
                          <img *ngIf="list.status == 'Time alloted'"  width="30px" height="auto" src="../../../assets/ui_icons/second_opinion/time_alloted_icon.svg" />
                          <p class="p_sc">{{list.status}}</p>
                        </div>
                        <!-- <div style="width: 40%;text-align: center;background-color: rgb(221, 170, 93);margin: 0 auto;"  [style.backgroundColor]="getBColor(list.status)">
                          <mat-label id="appt_status1" [ngStyle]="{'height':height}" [style.color]="getColor(list.status)" style="padding: 6px 9px 6px 9px;"><Span>{{list.status}}</Span></mat-label>
                        </div> -->
                    </td>
                    </tr>
                  </tbody>
                </table>
                <br><br><br>

              </mdb-card-body>
            </mdb-card>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <img src="../../../assets/ui_icons/second_opinion/Completed_icon.svg" width="20px" height="auto"/>
            &nbsp; Completed
          </ng-template>

           <mdb-card style="width: 99% !important;margin-left: 3px;">
              <mdb-card-header class="bg-white" style="padding:7px 20px;border-bottom:none !important">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="m-0" style="font-size: 17px;">Appointment list</h5>
                  <div>
                  </div>
                </div>
              </mdb-card-header>
              <mdb-card-body>
                <p class="nodata" *ngIf="secon_op_newlist1.length == 0">No appointment(s) found</p>
                <table *ngIf="secon_op_newlist1.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Date </th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of secon_op_newlist1; let i = index" (click)='view_click(list.opin_id,list.name,list.age,list.gender)'>
                      <td class="SP_table_content" style="text-align: left;">{{ list.mem_name }}</td>
                      <td class="SP_table_content">{{ list.date_second }}</td>
                      <td> 
                        <div style="position: relative;top: 9px;">
                        <img *ngIf="list.status == 'Cancelled'" width="30px" height="auto" src="../../../assets/ui_icons/second_opinion/cancelled.svg" />
                        <img *ngIf="list.status == 'Waiting for review'"  width="30px" height="auto" src="../../../assets/ui_icons/second_opinion/waiting_for_review_icon.svg" />
                        <img *ngIf="list.status == 'Review completed'"  width="30px" height="auto" src="../../../assets/ui_icons/second_opinion/review_completed.svg" />
                        <img *ngIf="list.status == 'Time alloted'"  width="30px" height="auto" src="../../../assets/ui_icons/second_opinion/time_alloted_icon.svg" />
                        <p class="p_sc">{{list.status}}</p>
                        </div>
                        <!-- <div style="width: 40%;text-align: center;background-color: rgb(221, 170, 93);margin: 0 auto;"  [style.backgroundColor]="getBColor(list.status)">  
                          <mat-label id="appt_status1" [ngStyle]="{'height':height}" [style.color]="getColor(list.status)" style="padding: 6px 9px 6px 9px;"><Span>{{list.status}}</Span></mat-label>
                        </div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br><br><br>

              </mdb-card-body>
            </mdb-card>
        </mat-tab>
    </mat-tab-group>

  </div>
</div>