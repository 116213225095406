<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
  <div class="row">
    <div class="col-12">
      <div class="row" style="margin-top: 15px;">
        <div class="col-1" style="width: fit-content;">
          <mat-label class="matlabel" style="position: relative;top: 6px;">Date : </mat-label>
        </div>
        <div class="col-8" style="padding-left: 0;">
          <input type="date" class="ipcss_date widthappt" id="appt_date"
            (change)="selectAppointmentDate(appointmentDate)" [(ngModel)]="appointmentDate" max="{{currentDate}}"
            #matInput style="width: 140px;">
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Appointment list</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel()" width="85px"
                style="margin-right: 9px;" />
              <img src="../../../assets/ui_icons/buttons/confirm_button.svg" (click)="confirmAppointment()"
                width="85px" />
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div>
            <app-loader></app-loader>
          </div>
          <p class="nodata" *ngIf="appointmentList.length == 0">No appointment(s) found</p>
          <table *ngIf="appointmentList.length" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th style="width: 70px;">Age</th>
                <th>Mobile</th>
                <th>Session</th>
                <th>Token / Time</th>
                <th>Symptoms</th>
                <th>Status</th>
                <th style="width: 5%;">
                  <mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1"
                    (change)="checkUncheckAll()"></mdb-checkbox>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of appointmentList; let i = index" (click)='viewAppt(person)'>
                <td><img src="{{person.profile_image}}" height="40px" width="40px"
                    class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                <td style="font-size: 12px;text-align: left;">{{ person.patient_name }}</td>
                <td style="font-size: 12px;">{{ person.loc_age }}</td>
                <td style="font-size: 12px;">{{ person.contact }}</td>
                <td style="font-size: 12px;">{{person.session}}</td>
                <td style="font-size: 12px;text-align: center;">{{ person.tot_app_count }}</td>
                <td style="font-size: 12px;text-align: left;">{{ person.symptoms }}</td>
                <td style="font-size: 12px;">
                  <div style="position: relative;top: 9px;">
                    <img *ngIf="person.status == 'Completed'" width="30px" height="auto"
                      src="../../../assets/ui_icons/Completed_icon.svg" />
                    <img *ngIf="person.status == 'Cancel'" width="30px" height="auto"
                      src="../../../assets/ui_icons/cancel_icon.svg" />
                    <img *ngIf="person.status == 'Not visited'" width="30px" height="auto"
                      src="../../../assets/ui_icons/not_visited_icon.svg" />
                    <img *ngIf="person.status == 'Confirmed'" width="30px" height="auto"
                      src="../../../assets/ui_icons/confirmed_icon.svg" />
                    <img *ngIf="person.status == 'Open'" width="30px" height="32px"
                      src="../../../assets/ui_icons/opened_icon.svg" />
                    <p>{{person.status}}</p>
                  </div>
                </td>
                <td style="font-size: 12px;width: 5%;">
                  <mdb-checkbox [default]="true" [(ngModel)]="person.isSelected" name="list_name" value="{{person.id}}"
                    (change)="isAllSelected(person.doc_app_id)"
                    [disabled]="person.status != 'Open' && person.status != 'Confirmed'"></mdb-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <br><br><br>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>