import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-inward',
  templateUrl: './inward.component.html',
  styleUrls: ['./inward.component.scss']
})
export class InwardComponent implements OnInit {
  public orderNo;
  public pharmacyID;
  public userInfo;
  public drugDetailsArray = [];
  public confirmFlag: boolean;
  preferredTimeFlag: boolean;
  dure_show: boolean;
  dtOptions: DataTables.Settings = {};
  OrderByName: String;
  OrderDate: String;
  PrepareTime: String;
  orderNumber: string;
  totalTime: string;
  orderDate: string;
  orderTime: string;
  todayDate: string;
  PurchaseType: string;
  addDeliveryFlag: boolean;
  presFlag: boolean;
  public orderType: boolean
  public ipaddress;
  public totalvalue;
  public viewOrderFlag: boolean = false;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public payTypeArray: any = [];
  public insurerArray: any = [];
  public payType: string;
  public insurerID: string;
  public saveFlag: boolean = true;
  constructor(public pharmacyservice: PharmacyService, public toastr: ToastrService, public gservice: CommonDataService, public http: HttpClient, public router: Router, public dialog: MatDialog) {
    this.confirmFlag = false;
    this.presFlag = false;
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
  }
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    this.ipaddress = Helper_Class.getIPAddress();
    this.addDeliveryFlag = true;
    this.CurrentDate();
    this.getPaymentType();
  }
  searchByOrderNo() {
    this.drugDetailsArray = [];
    this.totalvalue = 0;
    var send_data = {
      order_id: this.orderNo,
      pharmacy_id: this.pharmacyID,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/vieworderdetails/", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != null && obj != undefined) {
            this.viewOrderFlag = true;
            var orderdate = obj.order_date != undefined ? obj.order_date.split('-') : "";
            this.OrderDate = orderdate[2] + "-" + orderdate[1] + "-" + orderdate[0];
            this.PrepareTime = Time_Formate(obj.order_time);
            this.OrderByName = obj.middle_name != undefined ? encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name) : encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            this.presFlag = obj.pres_drug_id != undefined ? false : true;
            this.orderNumber = obj.drug_purchase_id != undefined ? obj.drug_purchase_id : "";
            if (obj.description != undefined) {
              this.PurchaseType = obj.description;
            }
            if (obj.preferred_from_time != undefined) {
              this.totalTime = (obj.preferred_from_time) + " " + (obj.preferred_to_time);
              this.preferredTimeFlag = false;
            } else {
              this.preferredTimeFlag = true;
            }
            if (obj.order_date != undefined) {
              this.orderDate = Date_Formate(obj.order_date);
            }
            if (obj.order_time != undefined) {
              this.orderTime = Time_Formate(obj.order_time);
            }
            if (obj.drug_details != null) {
              for (var i = 0; i < obj.drug_details.length; i++) {
                var priceval = (parseFloat(obj.drug_details[i].price) / parseFloat(obj.drug_details[i].per_unit)).toFixed(2);
                var amountval = (parseFloat(obj.drug_details[i].quantity) * parseFloat(priceval)).toFixed(2);
                this.drugDetailsArray.push({
                  drug_id: obj.drug_details[i].drug_id,
                  type_name: obj.drug_details[i].type_name,
                  drug_name: obj.drug_details[i].drug_name,
                  quantity: obj.drug_details[i].quantity,
                  amount: amountval,
                  price: priceval,
                  batch_no: obj.drug_details[i].batch_no,
                  batch_hide: this.orderType,
                  cgst: obj.drug_details[i].cgst,
                  sgst: obj.drug_details[i].sgst,
                  checked : false
                })
                // this.totalvalue = (parseFloat(this.totalvalue) + parseFloat(amountval)).toFixed(2);
              }
            }
            var addval = obj.phar_address2 != undefined ? obj.phar_address1 + "," + obj.phar_address2 : obj.phar_address1;
          } else {
            this.viewOrderFlag = false;
          }
        });
  }
  isReadonly() { return true; }
  CurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", {
      country: ipaddress.country_code,
    },
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.todayDate = obj.current_date;
        });
  }
  CalculatePrice(drugid, typename, drugname, quantity, price, per_unit, discount, gst) {
    this.totalvalue = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (drugid == this.drugDetailsArray[i].drug_id) {
        var amountval = (parseFloat(this.drugDetailsArray[i].quantity) * parseFloat(this.drugDetailsArray[i].price)).toFixed(2);
        this.drugDetailsArray[i].quantity = quantity;
        this.drugDetailsArray[i].amount = amountval;
        this.drugDetailsArray[i].price = price;
        this.drugDetailsArray[i].discount = discount;
      }
      //this.totalvalue = (parseFloat(this.totalvalue) + parseFloat(this.drugDetailsArray[i].amount)).toFixed(2);
    }
  }
  clearField(data) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == data.drug_id) {
        this.drugDetailsArray[i].quantity = "";
      }
    }
  }
  // deleteMedicine(drug_id) {
  //   for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //     if (this.drugDetailsArray[i].drug_id == drug_id) {
  //       this.drugDetailsArray.splice(i, 1);
  //     }
  //   }
  //   var prec_tx = 0;
  //   for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //     prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
  //   }
  //   this.totalvalue = prec_tx.toFixed(2);
  // }
  save() {
    var checkFlag: boolean = true;
    
    var drugDetails = this.drugDetailsArray.filter((x:any ) => { return x.checked == true });
    if (drugDetails.length == 0) {
      checkFlag = false;
    }
    console.log("drug" + JSON.stringify(drugDetails));
    console.log("totalvalue" + JSON.stringify(this.totalvalue));
    if (checkFlag == true) {
      var send_data = {
        pharma_id: this.pharmacyID,
        order_id: this.orderNo,
        amount: this.totalvalue,
        initiated_by: this.userInfo.user_id,
        drug_details: drugDetails,
        imei: this.ipaddress,
        country: ipaddress.country_code,
        pay_type: this.payType,
        insurer: this.insurerID,
        card_holder_name: this.cardHolderName,
        transaction_no: this.transactionID,
        bank_name: this.bankName,
        checked: false
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/sminv", send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.key == "1") {
              this.saveFlag = false;
              this.toastr.success(obj.result);
              this.pharmacyservice.sendMessage("pharmacycurentinformation");
            } else {
              this.saveFlag = true;
              this.toastr.error(obj.result);
            }
          }, error => { this.saveFlag = true; })
    } else {
      this.toastr.error(Message_data.noDrugDetFoundToSave);
    }
  }
  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeArray = obj.payments;
          this.payType = this.payTypeArray[0].pay_id;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
  getInsurers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurerArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
  isAllSelected(list, action) {
    list.checked = action == "select" ? true : false;
    this.totalvalue = this.drugDetailsArray.filter(function(options){ return options.checked == true }).reduce(function(a,b){
      return a + parseInt(b.amount);
    },0);
    console.log("this.drugDetailsArray" + JSON.stringify(this.totalvalue))
  }
  pay_change() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insurFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }
}
