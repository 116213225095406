import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharmapurchaseordermst',
  templateUrl: './pharmapurchaseordermst.component.html',
  styleUrls: ['./pharmapurchaseordermst.component.css']
})
export class PharmapurchaseordermstComponent implements OnInit {
  purchaseItem: any = [];
  productDataIN: any;
  allow1: boolean;
  taxAllow: boolean;
  orderData: any;
  notAllow: any;
  orderListFlag: boolean;
  purchaseOrderRequestArray: any = [];
  public totalAmount: number
  countryID: any;
  orderUOM: any;
  uomDesc: any;
  productName: any;
  mainArray: any = [];
  taxVal: any;
  finalResult: any = [];
  finalAmount: number;
  tax: any;
  taxListArray: any;
  public poid: string;
  tot: any;
  total_amount: number;
  unit_price: any;
  product_id: any;
  purchase_request_data: any;
  vendorData1: any;
  vendorData: void;
  mgfID: any;
  uom: any;
  HSNNumber: any;
  quantity: any;
  price: any;
  purchase1: any = [];
  data1: any;
  prNumber: any;
  purchaseRequestID: any;
  vendorID: any;
  a: boolean;
  supplierData: any;
  pharmacyVendorData: any;
  prDate: any;
  requestData: any;
  productData: any;
  allow: boolean;
  public vendor: any = [];
  public purchaseAddressArray: string;
  public poBase: string;
  public purchaseArray: any = [];
  public getIDs;
  pharmacistID;
  details: boolean;
  orderArray: any = [];
  po: any;
  poDate: any;
  orderList: any;
  locationIP = "exam";
  public poID;
  public purchaseIndex = this.purchaseArray.length;
  public ipaddress;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  public getinfo;
  public pharmacyID;
  constructor(public toastr: ToastrService, public http: Http, public router: Router, public messageservice: PharmacyService) {
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getinfo = Helper_Class.getInfo()
    this.pharmacistID = this.getinfo.user_id;
    for (var i = 0; i < this.getinfo.hospitals.length; i++) {
      this.pharmacyID = this.getinfo.hospitals[0].hptl_clinic_id;
    }
    this.ipaddress = Helper_Class.getIPAddress();
    this.get_list();
    this.product_order();
    this.get_vendor();
    this.get_address();
    this.get_product_tax();
    this.orderListFlag = true;
  }
  purchase_view() {
    this.vendor = "";
    this.purchaseAddressArray = "";
    this.poBase = "";
    this.details = true;
    this.orderListFlag = false;
    this.allow = false;
    this.taxAllow = false;
    this.a = false;
    this.notAllow = false;
    var data = null;
    pharmacy_helper.setpoid("");
    this.messageservice.sendMessage("orderview");
  }
  get_list() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpol/", JSON.stringify({ pharmacist_id: this.pharmacistID, imei: this.ipaddress }), { headers: headers }).subscribe(
      data => {
        if (data.json().purchase_orders != null) {
          this.orderList = data.json().purchase_orders;
          this.orderList.forEach(element => {
            this.po = element.po_date.split('-')
            this.poDate = this.po[2] + "-" + this.po[1] + "-" + this.po[0];
            this.orderArray.push({
              po_date: this.poDate,
              po_id: element.purchase_order_id
            });
            var po_id = element.purchase_order_id;
            Helper_Class.setpharmacyorderids(po_id);
          });
        }
      },
      error => { });
  }
  view_details(data) {
    this.details = true;
    this.orderListFlag = false;
    this.notAllow = true;
    pharmacy_helper.setpoid(data);
    this.messageservice.sendMessage("orderview");
  }
  purchase_confirm() {
    if (this.vendor == undefined || this.purchaseAddressArray == undefined || this.poBase == undefined || this.productName == undefined) {
      this.toastr.error(Message_data.fillAllFields);
    } else {
      this.finalResult = [];
      if (this.purchaseArray.lenghth != 0) {
        this.a = true;
      } else {
        this.a = false;
      }
      if (this.poBase == "Purchase Request") {
        this.purchaseArray.push({
          Index: this.purchaseIndex,
          product_id: this.product_id,
          product_name: this.productName,
          uom_code: this.uom,
          uom_desc: this.uomDesc,
          quantity: this.quantity,
          unit_price: this.price,
          total_amount: this.total_amount,
          mfg_id: this.mgfID,
          po_base: this.poBase,
          address: this.vendor.address,
          hsn_no: this.HSNNumber,
          order_uom: this.orderUOM,
          purchase_request_id: this.prNumber
        });
        this.purchaseIndex++;
        this.purchase1.push({
          product_id: this.product_id,
          quantity: this.quantity,
          unit_price: this.price,
          total_amount: this.total_amount,
          purchase_order_id: this.prNumber
        });
        this.purchaseOrderRequestArray.push({
          product_id: this.product_id,
          quantity: this.quantity
        });
        this.tax();
      } else if (this.poBase == "No Purchase Request") {
        this.purchaseArray.push({
          Index: this.purchaseIndex,
          product_id: this.product_id,
          product_name: this.productName,
          uom_code: this.uom,
          uom_desc: this.uomDesc,
          quantity: this.quantity,
          unit_price: this.price,
          total_amount: this.total_amount,
          mfg_id: this.mgfID,
          po_base: this.poBase,
          address: this.purchaseAddressArray,
          hsn_no: this.HSNNumber,
          order_uom: this.orderUOM,
        });
        this.purchaseIndex++;
        this.purchase1.push({
          product_id: this.product_id,
          quantity: this.quantity,
          unit_price: this.price,
          total_amount: this.total_amount,
        });
        this.tax();
      }
    }
    if (this.vendor == undefined || this.purchaseAddressArray == undefined || this.poBase == undefined) {
      this.toastr.error(Message_data.fillAllFields);
    }
  }
  purchase_cancel() {
    this.finalResult = [];
    this.orderListFlag = true;
    this.details = false;
    this.get_list();
  }
  get_po(data) {
    if (data == "Purchase Request") {
      this.allow = true;
      this.allow1 = true;
    } else if (data == "No Purchase Request") {
      this.allow = false;
      this.allow1 = true;
      this.get_product_data(this.vendor);
    }
  }
  product_order() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gprs/", JSON.stringify({ pharmacist_id: this.pharmacistID }), { headers: headers }).subscribe(
      data => {
        if (data.json().purchase_requests != null) {
          this.requestData = data.json().purchase_requests;
        }
      },
      error => { });
  }
  get_date(date) {
    this.prNumber = date
    this.requestData.forEach(element => {
      element.created_date
      if (element.purchase_request_id == date) {
        this.prDate = element.created_date;
      }
    });
    this.get_product();
  }
  get_vendor() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gman/",
      JSON.stringify({
        pharmacist_id: this.pharmacistID,
        pharmacy_id: this.pharmacyID,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().supplier_details != null) {
            this.pharmacyVendorData = data.json().supplier_details;
          }
        });
  }
  vendor_change(data) {
    this.pharmacyVendorData.forEach(vendor => {
      if (vendor.name == data) {
        this.supplierData.forEach(element => {
          if (element.name == vendor.name) {
            this.purchaseAddressArray = element.address1;
          }
        });
      }
    });
    this.vendorData1 = data;
    this.get_address();
    this.get_mfg();
    this.poBase = "Select";
  }
  get_address() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gsupp/",
      JSON.stringify({
        pharmacist_id: this.pharmacistID,
        pharmacy_id: this.pharmacyID,
      }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().product_codes != null) {
            this.supplierData = data.json().product_codes;
            this.supplierData.forEach(element => {
              if (element.name == this.vendor) {
                this.countryID = element.country;
              }
            });
          }
        },
        error => { });
  }
  get_mfg() {
    this.pharmacyVendorData.forEach(element => {
      if (element.name == this.vendor) {
        this.vendorID = element.mfg_id;
      }
    });
  }
  get_product() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gprid/", JSON.stringify({ pr_id: this.prNumber, mfg_id: this.vendorID, }), { headers: headers }).subscribe(
      data => {
        if (data.json().item_details != null) {
          this.purchase_request_data = data.json().item_details;
          this.purchaseItem = [];
          this.purchase_request_data.forEach(element => {
            this.purchaseItem.push({
              name: element.product_name,
              hsn_no: element.hsn_no,
              uom: element.uom_desc,
              price: element.cost_price,
              quantity: element.quantity,
            });
          });
        }
      },
      error => { });
  }
  get_productcode(data) {
    if (this.poBase == "Purchase Request") {
      this.purchase_request_data.forEach(element => {
        if (data == element.product_name) {
          this.HSNNumber = element.hsn_no;
          this.uom = element.uom_code;
          this.price = element.cost_price;
          this.quantity = element.quantity;
          this.product_id = element.product_id;
          this.total_amount = this.price * this.quantity;
          this.uomDesc = element.uom_desc;
          this.orderUOM = element.uom_desc;
        }
      });
    } else {
      this.load_data(data);
    }
  }
  delete(i) {
    this.purchaseArray.splice(i, 1);
    this.tax();
  }
  get_product_tax() {
    this.http.get(ipaddress.getIp + "pharmacontrol/ptax/").subscribe(
      data => {
        if (data.json().product_taxes != null) {
          this.taxListArray = data.json().product_taxes;
        }
      })
  }
  purchase_add() {
    this.mainArray = [];
    this.mainArray.push({
      purchase_request_id: this.prNumber,
      pr_items: this.productName
    });
    if (this.poBase == "Purchase Request") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/spo/", JSON.stringify({ mfg_id: this.vendorID, purchase_requests: this.mainArray, ordered_items: this.purchaseArray, pharmacist_id: this.pharmacistID, po_base: this.poBase, purchase_order_id: this.product_id, country: this.countryID }), { headers: headers }).subscribe(
        data => { });
    } else if (this.poBase == "No Purchase Request") {
      var headers1 = new Headers();
      headers1.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/spo/", JSON.stringify({ mfg_id: this.vendorID, ordered_items: this.purchaseArray, pharmacist_id: this.pharmacistID, po_base: this.poBase, purchase_order_id: this.product_id, country: this.countryID }), { headers: headers1 }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          if (obj != undefined && obj.key == "1") {
            this.toastr.error(Message_data.dateSaveSuccess);
            this.router.navigateByUrl('/clienthome')
          }
          else {
            this.toastr.error(Message_data.unableToSave);
          }
        });
    }
  }
  get_product_data(data1) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpcbyphar/", JSON.stringify({ pharmacist_id: this.pharmacistID }), { headers: headers }).subscribe(
      data => {
        if (data.json().products != null) {
          this.productDataIN = data.json().products;
          this.purchaseItem = [];
          this.productDataIN.forEach(element => {
            if (element.mfg_name == data1) {
              this.purchaseItem.push({
                name: element.name,
                hsn_no: element.hsn_no,
                uom: element.uom_desc,
                price: element.cost_price,
                quantity: element.per_unit,
              });
            }
          });
        }
      });
  }
  get_order_details(data) {//Detail List View
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpo/", JSON.stringify({ purchase_order_id: data }), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          this.orderData = data.json();
          this.vendor = this.orderData.name;
          this.purchaseAddressArray = this.orderData.address1;
          this.poBase = this.orderData.po_base;
          this.purchaseArray = [];
          this.orderData.purchase_items.forEach(element => {
            this.prNumber = element.purchase_request_id;
            this.productName = element.product_name;
            this.a = true;
            this.taxAllow = true;
            this.allow1 = false;
            this.HSNNumber = element.product_id;
            this.purchaseArray.push({
              Index: this.purchaseIndex,
              product_id: element.product_id,
              product_name: this.productName,
              uom_code: element.uom_code,
              uom_desc: element.uom_desc,
              quantity: element.quantity,
              unit_price: element.unit_price,
              total_amount: (element.unit_price * element.quantity),
              mfg_id: this.orderData.mgf_id,
              po_base: this.poBase,
              address: this.purchaseAddressArray,
              hsn_no: this.HSNNumber,
              order_uom: this.orderUOM,
              purchase_request_id: this.prNumber
            });
            this.purchaseIndex++;
            this.purchase1.push({
              product_id: this.product_id,
              quantity: this.quantity,
              unit_price: this.price,
              total_amount: this.total_amount,
              purchase_order_id: this.prNumber
            });
            this.purchaseOrderRequestArray.push({
              product_id: this.product_id,
              quantity: this.quantity
            });
          });
          this.tax();
        }
      });
  }
  load_data(data) {
    this.productDataIN.forEach(element => {
      if (element.name == data) {
        this.HSNNumber = element.hsn_no;
        this.uom = element.uom_code;
        this.price = element.cost_price;
        this.quantity = element.per_unit;
        this.product_id = element.product_id;
        this.total_amount = this.price * this.quantity;
        this.uomDesc = element.uom_desc;
        this.orderUOM = element.uom_desc;
      }
    });
  }
  back_Nav() {
    this.messageservice.sendMessage("pharmacyhome");
  }
}
