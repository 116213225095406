<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <div class="row" style="margin-top: 10px;margin-left: 9px;">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <mat-label class="matlabel">Diagnosis Centre
                    <select disableOptionCentering class="ipcss " [(ngModel)]="location">
                        <option disabled>Select</option>
                        <option (click)="changeLocation(loc.view_data)" *ngFor="let loc of locationArray"  value="{{loc.view_data}}">{{loc.view_data}}</option>
                    </select>
                </mat-label>
            </div>
        </div>
    </div>
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Users</h5>
                    </div>
                    <div class="headerButtons">
                        <img class="saveimgbtn_inpatinfo" (click)="createUser()" src="../../../assets/ui_icons/buttons/new_button.svg" />
                    </div>
                </div>

            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12">
                        <div [hidden]='userArray.length!=0' class="nodata">No data found</div>
                        <div [hidden]='userArray.length==0'>
                            <table *ngIf="userArray.length" mdbTable datatable [dtOptions]="dtOptions"
                                class="table table-nowrap table-sm dataTable">
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>User Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let Diaguser of userArray">
                                        <td style="text-align: left;" (click)="viewUser(Diaguser.diag_users_id)">
                                            {{Diaguser.first_name +" "}}
                                            <span
                                                [hidden]="Diaguser.middle_name == null && Diaguser.middle_name == undefined">{{Diaguser.middle_name+"
                                                "}}</span>
                                            {{Diaguser.last_name}}
                                        </td>
                                        <td style="text-align: left;" (click)="viewUser(Diaguser.diag_users_id)">
                                            {{Diaguser.user_type}}</td>
                                        <td><img class="edit_del_icon" (click)="deleteUser(Diaguser.diag_users_id)"
                                                src="../../../assets/ui_icons/buttons/trash_icon.svg" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>