<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Drug Upload</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="inventory.length!=0" src="../../../assets/ui_icons/buttons/update_button.svg"
              (click)="saveData()" class="saveimgbtn_inpatinfo" />
            <img class="saveimgbtn_inpatinfo" (click)="back_Nav()" src="../../../assets/ui_icons/buttons/Back_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Pharmacy Location<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="pharmacyLocation">
                <option disabled>Select</option>
                <option *ngFor="let loc of locationArray" value={{loc.pharma_facility_id}}>{{loc.location}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Purchase Order No<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="poNO" (change)="getManufacturer()">
                <option disabled>Select</option>
                <option *ngFor="let po of productArray" value={{po.purchase_order_id}}>{{po.purchase_order_id}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Invoice No<br>
              <input type="text" class="ipcss widthappt" [(ngModel)]="invoiceNO" required maxlength="25" matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Supplier<br>
              <input type="text" class="ipcss widthappt" [(ngModel)]="manufacturer" disable="true" matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Column Order<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="pharColumn"
                (change)="columnOrder(pharColumn)">
                <option *ngFor="let column of inventoryColumnArray" [ngValue]="column">
                  {{column}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <!-- <mat-label class="matlabel" style="margin-top: 7px;">Select excel file<br>
              <input type="file" (change)="readExcel($event)" name="filUpload" #excelreader id="file"
                class="upload_cls" />
            </mat-label> -->
            <label class="matlabel" for="file">Select excel file</label>
            <input type="file" (change)="readExcel($event)" name="filUpload" #excelreader id="file" class="ipcss" />
          </div>
        </div>
        <div class="row">
          <div [hidden]="data_flag">
            <img src="../../../assets/img/captcha_refresh.png" (click)="clear_data()" class="refresh_btn"
              alt="clear data">
          </div>
        </div>
        <div class="row">
          <table class="table table-hover table-dynamic" style="font-size: 11px; ">
            <thead>
              <tr>
                <th *ngFor="let newcolumn of realignColumnsArray">{{newcolumn}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of columnArray">
                <td>{{data[0]}}</td>
                <td>{{data[1]}}</td>
                <td *ngIf="data.length >2">{{data[2]}}</td>
                <td *ngIf="data.length >3">{{data[3]}}</td>
                <td *ngIf="data.length >4">{{data[4]}}</td>
                <td *ngIf="data.length >5">{{data[5]}}</td>
                <td *ngIf="data.length >6">{{data[6]}}</td>
                <td *ngIf="data.length >7">{{data[7]}}</td>
                <td *ngIf="data.length >8">{{data[8]}}</td>
                <td *ngIf="data.length >9">{{data[9]}}</td>
                <td *ngIf="data.length >10">{{data[10]}}</td>
                <td *ngIf="data.length >11">{{data[11]}}</td>
                <td *ngIf="data.length >12">{{data[12]}}</td>
                <td *ngIf="data.length >13">{{data[13]}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>