import { Component, OnInit, HostListener, OnChanges, ViewChild, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { MatDialog } from '@angular/material/dialog';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-entpersonal',
  templateUrl: './entpersonal.component.html',
  styleUrls: ['./entpersonal.component.scss']
})
export class EntpersonalComponent implements OnInit {
  public personalFlag = "min";
  public case_hist_id: string;
  public life_style_id: string;
  public Main_concern_array = [];
  
  public bodyttype_array = [];
  public Medical_history_array=[];
  public Religious_belief_array=[];
  public Maincheck=[];
  public Medical_Check=[];
  public Religious_check=[];
  public Maincon_txt;
  public medicaldata;

  public diet_habits: string;
  public name: string;
  public age: any;
  public current_occupation: string;
  public gender: string;
  public height_txt: any;
  public height_measure: string;
  public weight_txt: any;
  public weight_measure: string;
  public txt_bmi: any;
  public txt_bmr: any;
  public exer_run: string;
  public cal_txt: any;
  public body_type: string;
  public body_nature: string;
  public rweight_txt: string;
  public anymed_txt: string;
  public med_txt: string;
  public name_label: string;
  public age_label: string;
  public current_label: string;
  public gender_label: string;
  public height_label: string;
  public height_measurelabel: string;
  public weight_label: string;
  public weight_measurelabel: string;
  public bmi_label: string;
  public bmr_label: string;
  public dietary_label: string;

  public bodytype_label: string;
  public bnature_label: string;
  public rweight_label: string;
  public anymed_label: string;
  public med_label: string;
  public brancial_label: string;
  public radial_label: string;
  public carotid_label: string;
  public femoral_label: string;
  public popliteal_label: string;
  public abdom_aorta: string;
  public branch_rhythm: string;
  public radial_rhythm: string;
  public carotid_rhythm: string;
  public femoral_rhythm: string;
  public popliteal_rhythm: string;
  public posterior_rhythm: string;
  public dorsalis_rhythm: string;
  public occupation_txt: string;
  public alcholhist: string;
  public family_hidden:boolean;
  public family_label:string;
  public Maincon_label: string;
  public medhist_label: string;
  public Religious_label: string;
  public rightarm_label: string;
  public leftarm_label: string;
  public fasting_label: string;
  public prandial_label: string;
  public medhistflag :string;
  public relbelflag : string;
  public past_medical: string;
  public religious_belief: string;
  public rightarm_dia: string;
  public rightarm_sys: string;
  public leftarm_dia: string;
  public leftarm_sys: string;
  public fasting: string;
  public prandial: string;

  public name_hidden: boolean;
  public age_hidden: boolean;
  public curoccup_hidden: boolean;
  public Gender_hidden: boolean;
  public height_hidden: boolean;
  public weight_hidden: boolean;
  public bmi_hidden: boolean;
  public bmr_hidden: boolean;
  public dietary_hidden: boolean;

  public bodytype_hidden: boolean;
  public bnature_hidden: boolean;
  public rweight_hidden: boolean;
  public anymed_hidden: boolean;
  public med_hidden: boolean;
  public brancial_hidden: boolean;
  public radial_hidden: boolean;
  public carotid_hidden: boolean;
  public femoral_hidden: boolean;
  public popliteal_hidden: boolean;
  public abdom_aorta_hidden: boolean;
  public branch_rhythm_hidden: boolean;
  public radial_rhythm_hidden: boolean;
  public carotid_rhythm_hidden: boolean;
  public femoral_rhythm_hidden: boolean;
  public popliteal_rhythm_hidden: boolean;
  public posterior_rhythm_hidden: boolean;
  public dorsalis_rhythm_hidden: boolean;
  public loader: boolean;
  public med_flag: boolean;
  public historytab: boolean;
  public header_footer_flag: boolean;
  public agefield: boolean;
  public feelstress_hidden: boolean;
  public stress_hidden: boolean;
  public symptom_hidden: boolean;
  public hosp_hidden: boolean;
  public hospdet_hidden: boolean;
  public treat_hidden: boolean;
  public maincon_hidden: boolean;
  public medhist_hidden: boolean;
  public religious_hidden: boolean;
  public rightarm_hidden:boolean;
  public leftarm_hidden: boolean;
  public fasting_hidden: boolean;

  public religious_belielabe:boolean;
  public personaldata;
  public userinfo;
  subscription:Subscription;
  public obj1;
  public Main_concern_array1 = [];
  public main_concern_flag: boolean = false;
  public medhist_value_array1 = [];
  public medhist_flag: boolean = false;
  public Religious_belief_array1 = [];
  public religiousflag: boolean = false;
  public prevhosp_flag:boolean;
  public treat;
  public treat_label;
  public hospdet;
  public hospdet_label;
  public hosp_flag:boolean;
  public hosp;
  public stress_flag:boolean;
  public symptom;
  public symptom_label;
  public feelstress_flag:boolean;
  public feelstress;
  public feelstress_label;
  public hosp_label;
  public stress;
  public stress_label;
  public client:boolean;
  public user_id;

  constructor(public messageservice1:CasesheetService,public bmiservice:BmiandbmrService,public dialog:MatDialog,public http:Http,
    public router:Router,public toastr:ToastrService,public masterCSdata:MasterCSHelperService) { 
    this.name_hidden = true;
    this.age_hidden = true;
    this.curoccup_hidden = true;
    this.Gender_hidden = true;
    this.height_hidden = true;
    this.weight_hidden = true;
    this.bmi_hidden = true;
    this.bmr_hidden = true;
    this.dietary_hidden = true;

    this.bodytype_hidden = true;
    this.bnature_hidden = true;
    this.rweight_hidden = true;
    this.anymed_hidden = true;
    this.med_hidden = true;
    this.brancial_hidden = true;
    this.radial_hidden = true;
    this.carotid_hidden = true;
    this.femoral_hidden = true;
    this.popliteal_hidden = true;
    this.abdom_aorta_hidden = true;
    this.branch_rhythm_hidden = true;
    this.radial_rhythm_hidden = true;
    this.carotid_rhythm_hidden = true;
    this.femoral_rhythm_hidden = true;
    this.popliteal_rhythm_hidden = true;
    this.posterior_rhythm_hidden = true;
    this.maincon_hidden = true;
    this.medhist_hidden = true;
    this.religious_hidden = true;
    this.rightarm_hidden = true;
    this.leftarm_hidden = true;
    this.fasting_hidden = true;
    this.dorsalis_rhythm_hidden = true;
    this.feelstress_hidden = true;
    this.hospdet_hidden = true;
    this.treat_hidden = true;
    this.symptom_hidden = true;
    this.hosp_hidden = true;
    this.stress_hidden = true;
    this.med_flag = true;
    this.historytab = true;
    this.agefield = true;   
    this.txt_bmr = undefined;
    this.txt_bmi = undefined;
    this.height_measure = "cms";
    this.weight_measure = "kgs";
  }

  async ngOnInit() {
    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.personalFlag = message;  
      }
    });

    this.subscription = this.bmiservice.getMessage().subscribe(message =>{
      if(message != undefined){
        if(message.flag == "vitals"){
          if(message.bodynatute != undefined){
            this.body_nature = message.bodynatute;
          }
        }
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.personaldata = Helper_Class.getInfo();
      this.client = true;
      this.userinfo = Helper_Class.getclient_hospitalDetails();
    }else{
      this.personaldata = Doc_Helper.getClient_Info();
      this.userinfo = Helper_Class.getInfo();
      this.client = false;
    }

    if(Helper_Class.getInpatientFlow() == "nurse"){
      this.user_id = this.personaldata.user_id;
    }else{
      this.user_id = this.userinfo.user_id;
    }

    this.gender = this.personaldata.Gender_data;
    if(this.personaldata.Age_data != undefined){
      this.age = this.personaldata.Age_data;
    }
    
    this.name =this.personaldata.client_name;
    if(masterCSData_Helper.getMasterMainConcern() == undefined){
      await this.masterCSdata.getMainconcern();
      this.Get_main_concern();
    }else{
      this.Get_main_concern();
    }

    if(this.personaldata.height != null){
        this.height_txt = this.personaldata.height;
        this.height_measure = this.personaldata.height_measure;
        this.weight_txt = this.personaldata.weight;
        this.weight_measure = this.personaldata.weight_measure;
        this.txt_bmi =this.personaldata.bmi
        this.calories_data();
    }

    for (var i = 0; i < Helper_Class.getHopitalfieldsRet().length; i++) {
      if (Helper_Class.getHopitalfieldsRet()[i].page_name == "EntPersonal") {
        if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
          if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
            for (var j = 0; j < Helper_Class.getHopitalfieldsRet()[i].fields.length; j++) {
              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Current occupation") {
                this.current_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.curoccup_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Name") {
                this.name_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.name_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Age") {
                this.age_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.age_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Gender") {
                this.gender_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.Gender_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Height") {
                this.height_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.height_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Height measure") {
                this.height_measurelabel = "Measure";
                this.height_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Weight") {
                this.weight_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.weight_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Weight measure") {
                this.weight_measurelabel = "Measure";
                this.weight_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "BMI") {
                this.bmi_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bmi_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "BMR") {
                this.bmr_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bmr_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Dietary habits") {
                this.dietary_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.dietary_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Body type") {
                this.bodytype_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bodytype_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Body nature") {
                this.bnature_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bnature_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Recent weight gain") {
                this.rweight_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.rweight_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Taking any medication") {
                this.anymed_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.anymed_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Medicines") {
                this.med_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.med_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Main concenrns") {
                this.Maincon_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.maincon_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Past medical history") {
                this.medhist_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.medhist_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Religious or other beliefs") {
                this.Religious_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.religious_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Right arm") {
                this.rightarm_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.rightarm_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Left arm") {
                this.leftarm_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.leftarm_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Fasting") {
                this.fasting_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.fasting_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Postprandial") {
                this.prandial_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.fasting_hidden = false;
              }             
            }
          }
        }
      }
    }
  }

  isReadonly() {
    if(Doc_Helper.getAppFlow() == "client" ){
      return true;
    }else{
      return false;
    }
  }

  med_change(){
    if(this.anymed_txt =="Yes"){
      this.med_flag=false;
    } else {
      this.med_flag=true;
      this.med_txt="";
    }
  }

  //Get main concern data
  async Get_main_concern() {
    this.Main_concern_array = [];
    var obj = masterCSData_Helper.getMasterMainConcern();
    if(obj != undefined){
      if (obj.main_concerns != null) {
        for (var i = 0; i < obj.main_concerns.length; i++) {
          this.Main_concern_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.main_concerns[i].main_concerns_id,
            label: obj.main_concerns[i].description,
            checked: false
          });
        }
      }
    }
    //Medical history
    if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
      await this.masterCSdata.getMedicalHistory();
      this.Get_medical_history();
    }else{
      this.Get_medical_history();
    }
  }

  //Get past medical history
  async Get_medical_history() {
    this.Medical_history_array = [];
    var obj = masterCSData_Helper.getMasterMedicalHistory();
    if(obj != undefined){
      if (obj.med_hist != null) {
        for (var i = 0; i < obj.med_hist.length; i++) {
          this.Medical_history_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.med_hist[i].med_hist_id,
            label: obj.med_hist[i].description,
            checked: false
          });
          if(obj.med_hist[i].med_hist_id == 32){
            this.past_medical = obj.med_hist[i].description;
          }
        }
      }
    }
    if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
      await this.masterCSdata.getReligiousOtherBeliefs();
      this.Get_Religious_other_beliefs();
    }else{
      this.Get_Religious_other_beliefs();
    }
  }

  //Get Religious or other beliefs
  Get_Religious_other_beliefs() {
    this.Religious_belief_array = [];
    var obj = masterCSData_Helper.getMasterReligiousOtherBeliefs();
    if(obj != undefined){
      if (obj.religious_beliefs != null) {
        for (var i = 0; i < obj.religious_beliefs.length; i++) {
          this.Religious_belief_array.push({
            type: 'checkbox',
            value: obj.religious_beliefs[i].religious_belief_id,
            label: obj.religious_beliefs[i].description,
            checked: false
          });
        }
      }
    }
    this.getBodyTypes();
  }

  main_click(selected) {
    this.Maincon_txt = "";

    function StringBuilder(this:any , value)   {
       this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Maincheck = selected;
    if (this.Maincheck.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Maincheck.length; j++) {
        for (var i = 0; i < this.Main_concern_array.length; i++) {
          if (this.Maincheck[j] == this.Main_concern_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Main_concern_array[i].label);
          }
        }
      }
      this.Maincon_txt = selected_data.toString();
    }
  }

  mainconcernpopup() {
    if(this.client == false){
      this.main_concern_flag = true;
      for (var j = 0; j < this.Main_concern_array.length; j++) {
        this.Main_concern_array[j].checked = false;
      }
  
      if (this.Maincheck != undefined && this.Maincheck.length != 0) {
        for (var i = 0; i < this.Maincheck.length; i++) {
          for (var j = 0; j < this.Main_concern_array.length; j++) {
            if (this.Maincheck[i] == this.Main_concern_array[j].value) {
              this.Main_concern_array[j].checked = true;
            }
          }
        }
      }  

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Main Concerns",
          main_array: this.Main_concern_array,
          selected_list:this.Maincheck,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
            this.main_click(result);
            this.send_data();
          }
      });
    }
  }

  medical_popup() {
    if(this.client == false){
        this.medhist_flag = true;
        for (var j = 0; j < this.Medical_history_array.length; j++) {
          this.Medical_history_array[j].checked = false;
        }

        if (this.Medical_Check != undefined && this.Medical_Check.length != 0) {
          for (var i = 0; i < this.Medical_Check.length; i++) {
            for (var j = 0; j < this.Medical_history_array.length; j++) {
              if (this.Medical_Check[i] == this.Medical_history_array[j].value) {
                this.Medical_history_array[j].checked = true;
              }
            }
          }
        }

        const dialogRef = this.dialog.open(DropdownModelpageComponent, {
          width: '500px',
          height: '400px',
          data:{
            title: "Past Medical History",
            main_array: this.Medical_history_array,
            selected_list:this.Medical_Check,
          } 
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != undefined){
            this.meddiab_click(result);
            this.send_data();
            }
        });
    }
  }

  meddiab_click(selected) {
    this.past_medical = "";
    
    function StringBuilder(this: any,value){
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    if (selected != "" && selected != null) {
      this.Medical_Check = selected;
    }

    if (this.Medical_Check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Medical_Check.length; j++) {
        for (var i = 0; i < this.Medical_history_array.length; i++) {
          if (this.Medical_Check[j] == this.Medical_history_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Medical_history_array[i].label);
          }
        }
      }
      this.past_medical = selected_data.toString();
     
    }
  }

  relclick(selected) {
    this.religious_belief = "";
    
    function StringBuilder(this: any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Religious_check = selected;

    if (this.Religious_check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Religious_check.length; j++) {
        for (var i = 0; i < this.Religious_belief_array.length; i++) {
          if (this.Religious_check[j] == this.Religious_belief_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Religious_belief_array[i].label);
          }
        }
      }
      this.religious_belief = selected_data.toString();
    }
  }

  religious() {
    if(this.client == false){
      this.religiousflag = true;
      for (var j = 0; j < this.Religious_belief_array.length; j++) {
        this.Religious_belief_array[j].checked = false;
      }

      if (this.Religious_check != undefined && this.Religious_check.length != 0) {
        for (var i = 0; i < this.Religious_check.length; i++) {
          for (var j = 0; j < this.Religious_belief_array.length; j++) {
            if (this.Religious_check[i] == this.Religious_belief_array[j].value) {
              this.Religious_belief_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Religious or Other Beliefs",
          main_array: this.Religious_belief_array,
          selected_list:this.Religious_check,
        } 
      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.relclick(result);
          this.send_data();
          }
      });
    }
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   if(this.client == false){
  //     this.send_data();
  //   }
  // }

  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   if(this.client == false){
  //     this.send_data();
  //   }  }

  send_data(){
    var gen_hist = null, case_hist_id = null;
    case_hist_id = this.case_hist_id != null ? this.case_hist_id : undefined;
    var sub_rel = this.personaldata.sub_id != null && this.personaldata.sub_id !="" && this.personaldata.sub_id.length != "0" ? this.personaldata.sub_id : undefined;
    var lif_stl_id = undefined;
    if (this.life_style_id != null) {
      lif_stl_id = this.life_style_id;
    }
    this.occupation_txt = this.current_occupation != undefined && this.current_occupation != null && this.current_occupation != "" ? encrypt_decript.Encript(this.current_occupation.trim()).toString() : null; 
    this.medicaldata = this.Medical_Check != null ? this.Medical_Check : null;
   
    this.relbelflag = this.Religious_check.length != 0 ? "yes" : "no";

    gen_hist = {
      country: ipaddress.country_code,
      case_hist_id: case_hist_id,
      current_occupation: this.occupation_txt,
      life_style_id: lif_stl_id,
      app_id: this.personaldata.app_id,
      doc_reg_id: this.user_id,
      client_reg_id: this.personaldata.Client_id,
      relation_id: this.personaldata.rel_id,
      sub_rel_id: sub_rel,
      gender: this.gender,
      age: this.age,
      body_nature:this.body_nature,
      body_type: this.body_type,
      medication: this.anymed_txt,
      medicines: this.med_txt,
      diet_habits: this.diet_habits,
      
      main_concern: this.Maincheck,
      medical_history: this.medicaldata,
      medical_history_flag : this.medhistflag,
      rel_belief: this.Religious_check,
      rel_belief_flag : this.relbelflag,
      bp_right_arm_dia:this.rightarm_dia,
      bp_right_arm_sys:this.rightarm_sys,
      bp_left_arm_dia: this.leftarm_dia,
      bp_left_arm_sys: this.leftarm_sys,
      glucose_fasting: this.fasting,
      glucose_postprandial:this.prandial,
    }
    Helper_Class.setPersonalHist(gen_hist);
  }
  
  //Get bmi and bmr and calories data
  calories_data() {
    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.txt_bmr = "";
      this.txt_bmi = "";
    } else {
      if (this.height_txt != undefined) {
        if (this.height_measure == "cms") {
          this.height_txt = this.height_txt.replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt.replace('/[^-0-9\.]/g', '');
          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.toastr.show(Message_data.heightMSValue);
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.height_measure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.weight_measure == "kgs" && this.height_measure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "kgs" && this.height_measure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "lbs" && this.height_measure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "lbs" && this.height_measure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      }

      var SelectedText1 = this.exer_run;
      if (SelectedText1 == "Light") {
        var get_txt = this.txt_bmr * 1.375;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Moderate") {
        var get_txt = this.txt_bmr * 1.55;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Heavy") {
        var get_txt = this.txt_bmr * 1.725;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Very Heavy") {
        var get_txt = this.txt_bmr * 1.9;
        this.cal_txt = Math.round(get_txt);
      } else {
        var get_txt = parseFloat(this.txt_bmr) * 1.375;
        this.cal_txt = Math.round(get_txt);
      }

      if(this.txt_bmi<18.5){
        this.body_nature="Under weight";
      } else if(this.txt_bmi>=18.5 && this.txt_bmi<=24.9){
        this.body_nature="Normal weight";
      } else if(this.txt_bmi>=25 && this.txt_bmi<=29.9){
        this.body_nature="Over weight";
      } else{
        this.body_nature="Obase";
      }     
    }
  }
  
  getBodyTypes() {
    this.bodyttype_array = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/btypes',
      JSON.stringify({
        gender: this.personaldata.Gender_data,
        // gender: "Male",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          this.bodyttype_array = obj.body_types;
          this.body_type = this.bodyttype_array[0].bodytype_name;
          this.getCasesheetDetails() ;
        },
        error => {});
  }
  
  getCasesheetDetails() {//retrieve cardio details
    var obj = Helper_Class.getRet();
    if (obj !=null && obj.case_hist_id != null) {
      Helper_Class.setRet(obj);
      //set family history to helper class
      Helper_Class.setCaseHistId(obj.case_hist_id);
      Helper_Class.setLifeStyleId(obj.life_style_id);
      
      this.name = this.personaldata.client_name != undefined ? this.personaldata.client_name : obj.cardio_name;
      this.gender = this.personaldata.Gender_data != undefined ? this.personaldata.Gender_data : obj.gender;
      if(obj.age){
        this.age = obj.age
      } else {
        if(this.personaldata.Age_data != undefined){
          this.age = this.personaldata.Age_data;
        }
      }
      
      if(this.age == undefined){
        this.agefield=false;
      }
      
      //Current occupation
      if (obj.current_occupation != null && obj.current_occupation !="" && obj.current_occupation != undefined) {
        this.current_occupation = encrypt_decript.Decript(obj.current_occupation);
      }else{
        this.current_occupation = encrypt_decript.Decript(this.personaldata.Gender_data.current_occupation);
      }

      if (obj.case_hist_id != null) {
        this.case_hist_id = obj.case_hist_id;
      }

      if (obj.life_style_id != null) {
        this.life_style_id = obj.life_style_id;
      }

      //Height
      if (obj.height != null) {
        this.height_txt = obj.height;
        this.height_measure = obj.height_measure;
      }

      //Weight
      if (obj.weight != null) {
        this.weight_txt = obj.weight;
        this.weight_measure = obj.weight_measure;
      }

      //Bmi and mnr
      if (obj.bmi != null) {
        this.txt_bmi = obj.bmi;
        this.txt_bmr = obj.bmr;
        this.body_nature = obj.body_nature;
      }

      if(obj.body_type != "" || obj.body_type != null){
        this.body_type = obj.body_type;
      }
      if(obj.medication != null || obj.medication != undefined){
        this.anymed_txt = obj.medication;
      }
      if(obj.medicines != null || obj.medicines != undefined){
        this.med_txt = obj.medicines;
      }
      
      this.rightarm_dia = obj.bp_right_arm_dia;
      this.rightarm_sys = obj.bp_right_arm_sys;
      this.leftarm_dia = obj.bp_left_arm_dia;
      this.leftarm_sys = obj.bp_left_arm_sys;
      this.fasting = obj.glucose_fasting;
      this.prandial = obj.glucose_postprandial;

      // Main Concerns
      if (obj.main_concerns != null) {
        for (var j = 0; j < obj.main_concerns.length; j++) {
          this.Maincheck.push(obj.main_concerns[j].main_concerns_id);
        }
        this.main_click(this.Maincheck);
      }

      // Religious or other beliefs
      if (obj.religious_beliefs != null) {
        for (var j = 0; j < obj.religious_beliefs.length; j++) {
          this.Religious_check.push(obj.religious_beliefs[j].religious_belief_id);
        }
        this.relclick(this.Religious_check);
      }
      
      // Past Medical History
      if (obj.medical_history != null && obj.medical_history.length !=0) {
        for (var j = 0; j < obj.medical_history.length; j++) {
          this.Medical_Check.push(obj.medical_history[j].med_hist_id);
        }
        this.meddiab_click(this.Medical_Check);
      }

      //Diet habits
      if (obj.diet_habits != null) {
        this.diet_habits = obj.diet_habits;
      }

      if(obj.spl_name !=undefined){
        Helper_Class.setOtherSpl(obj.spl_name);
      }
    } else {
      if(this.personaldata.client_name != undefined){
        this.name = this.personaldata.client_name;
      }

      if(this.personaldata.Gender_data != undefined){
        this.gender = this.personaldata.Gender_data;
      } 
      
      //Current occupation
      if (this.personaldata.current_occupation != undefined && this.personaldata.current_occupation != 'undefined' &&
      this.personaldata.current_occupation != null && this.personaldata.current_occupation != "") {
        this.current_occupation = encrypt_decript.Decript(this.personaldata.current_occupation);
      }
    }
    this.send_data();
  }

  History_tab_click(){}//future

  enableHosp(){
    this.prevhosp_flag = this.hosp =="Yes" ? false : true;
  }
  
  stress_change(){
    if(this.feelstress =="Yes" && this.client == false){
      this.stress_flag = false;
    }else{
      this.stress_flag=true;
      this.stress="";
      this.symptom="";
    }
  }
}
