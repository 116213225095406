<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Create Appointment</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo"
                            (click)="backOption()" />
                        <img src="../../../assets/ui_icons/buttons/Book_now.svg" class="saveimgbtn_inpatinfo"
                            (click)="saveApp()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12 col-sm-12" style="margin: 15px 0 15px 0;">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-label class="matlabel" style="font-weight: 600;">Appointment Type :
                                        </mat-label>
                                    </div>
                                    <div class="col-5">
                                        <div class="switch-field">
                                            <input type="radio" id="radio-one"
                                                (click)="changeClientType('patient',1,'yes')" />
                                            <label for="radio-one"
                                                [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">Out-patient</label>
                                            <input type="radio" id="radio-two"
                                                (click)="changeClientType('patient',1,'no')" />
                                            <label for="radio-two"
                                                [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">In-patient</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="showclient">
                        <div class="row">
                            <div class="col-12 col-sm-12" style="margin: 15px 0 15px 0;">
                                <div class="row">
                                    <div class="col-3">
                                        <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <div class="switch-field">
                                            <input type="radio" id="radio-three"
                                                (click)="changeClientType('client',1,'yes')" [disabled]="inpatient" />
                                            <label for="radio-three"
                                                [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">New</label>
                                            <input type="radio" id="radio-four"
                                                (click)="changeClientType('client',1,'no')" [disabled]="inpatient" />
                                            <label for="radio-four"
                                                [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">Existing</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="tervys_heading col-12">Profile</p>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="inpatient">
                        <mat-label class="matlabel">Doctor<br>
                            <input type="text" class="ipcss" maxlength="50" (keyup)="getReferralData($event)"
                                maxlength="50" [(ngModel)]="doctorName" matInput [matAutocomplete]="auto1" />
                            <mat-autocomplete #auto1="matAutocomplete">
                                <mat-option (click)="getReferDoctor(item.doc_reg_id)"
                                    *ngFor="let item of referedDoctorArray" value="{{item.docname}}">
                                    {{item.docname}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" *ngIf="inpatient">
                        <mat-label class="matlabel">Appointment id<br>
                            <select required class="ipcss " [(ngModel)]="appoint" (change)="changeAppointmnetId()"
                                disableOptionCentering>
                                <option *ngFor="let billcreate of billingListArray" value="{{billcreate.doc_app_id}}">
                                    {{billcreate.docid_name}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div [hidden]="searchFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="!inpatient">
                        <mat-label class="matlabel">Search by
                            <select class="ipcss" (change)="filterByPatients()" [(ngModel)]="Filter" required>
                                <option [ngValue]="Select">Select</option>
                                <option value="name">First name</option>
                                <option value="mobile">Mobile</option>
                                <option value="patient_id">Patient Id</option>
                            </select>
                        </mat-label>
                    </div>
                    <div [hidden]="searchFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="!inpatient">
                        <mat-label class="matlabel">&nbsp;</mat-label>
                        <div class="row">
                            <div class="col-8">
                                <input class="ipcss" [(ngModel)]="firstNamePatient" (change)="changeName()"
                                    (blur)="searchUpper()" [placeholder]="somePlaceholder" [maxlength]="maxLength"
                                    matInput>
                            </div>
                            <div class="col-2">
                                <a (click)="changePatientName(firstNamePatient)"><img
                                        src="../../../assets/ui_icons/search_icon.svg" class="seacrhicon" /></a>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="searchFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="!inpatient">
                        <mat-label class="matlabel">Patient
                            <select [(ngModel)]="clientID" class="ipcss" (change)="changeClientId(clientID)" required>
                                <option [ngValue]="Select">Select</option>
                                <option *ngFor="let clientid of clientDetailsArray" value={{clientid.patient_id}}>
                                    {{clientid.patient_id}}
                                </option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Salutation
                            <select class="ipcss" [(ngModel)]="clnt_sal" (ngModelChange)="changeSalutation($event)">
                                <option ngValue="select">select</option>
                                <option *ngFor="let sal of salListArray" value="{{sal.sal_id}}">{{sal.sal_desc}}
                                </option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">First name
                            <input type="text" class="ipcss" [(ngModel)]="clientFirstName" (blur)="toUpperFirstName()"
                                (keypress)="checkAlphaNumeric($event)" required matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Middle name
                            <input type="text" class="ipcss" [(ngModel)]="clientMidName" (blur)="toUpperMidName()"
                                (keypress)="checkAlphaNumeric($event)" matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Last name
                            <input type="text" class="ipcss" [(ngModel)]="clientLastName" (blur)="toUpperLastName()"
                                (keypress)="checkAlphaNumeric($event)" required matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Mobile
                            <input type="text" class="ipcss" [(ngModel)]="clientNumber" maxlength="10"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" required matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">DOB
                            <input type="date" class="ipcss" max="{{currentDate}}" [(ngModel)]="clientDOB" [disabled]="disableDOBFlag"
                                (change)="clickDate(clientDOB)" required matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Age
                            <input type="text" class="ipcss" [disabled]="disableAgeFlag" [(ngModel)]="clientAge"
                                [required]="!ageRead" required (keyup)="ChangeAge()" matInput>
                        </mat-label>
                    </div>
                    <div [hidden]="!userType" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Gender
                            <input type="text" class="ipcss" [(ngModel)]="clientGender" matInput>
                        </mat-label>
                    </div>
                    <div [hidden]="userType" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Gender
                            <select class="ipcss" [(ngModel)]="clientGender" (ngModelChange)="changeGender($event)">
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Transgender">Transgender</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Address1
                            <input type="text" class="ipcss" [(ngModel)]="clientAddress1" required [disabled]="userType"
                                matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Address2
                            <input type="text" class="ipcss" [(ngModel)]="clientAddress2" [disabled]="userType"
                                matInput>
                        </mat-label>
                    </div>
                   
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                        <mat-label class="matlabel">Zipcode
                            <input type="text" class="ipcss widthappt" [(ngModel)]="clientZipCode"
                                (keyup)=zipcode_change() required matInput />
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                        <mat-label class="matlabel">Location<br>
                            <input type="text" class="ipcss" required maxlength="50" [(ngModel)]="clientLocation"
                                (keyup)="changeLocation(clientLocation)" matInput />
                            <div class="auto_complete_text" *ngIf="locationArray.length != 0">
                                <ul *ngFor="let location of locationArray">
                                    <li>
                                        <a (click)="selectLocation(location)">{{location.description}}</a>
                                    </li>
                                </ul>
                            </div>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                        <mat-label class="matlabel">District / City<br>
                            <input matInput class="ipcss widthappt" [(ngModel)]="clientCity" [disabled]="!userType"
                                [hidden]="existuserFlag">
                            <mat-select required class="ipcss widthappt" [(ngModel)]="clientCity" [hidden]="newUserFlag"
                                disableOptionCentering (ngModelChange)="changeCity(clientCity, '0')">
                                <mat-option *ngFor="let city of cityArray" value="{{city.city_desc}}">{{city.city_desc}}
                                </mat-option>
                            </mat-select>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                        <mat-label class="matlabel">State<br>
                            <input type="text" matInput class="ipcss widthappt" [(ngModel)]="clientState"
                                [disabled]="existuserFlag" [hidden]="!userType">
                            <mat-select required class="ipcss widthappt" [(ngModel)]="clientState"
                                disableOptionCentering [hidden]="newUserFlag"
                                (ngModelChange)="changeState(clientState, '0')">
                                <mat-option *ngFor="let state of stateArray"
                                    value={{state.state_desc}}>{{state.state_desc}}
                                </mat-option>
                            </mat-select>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                        <mat-label class="matlabel">Country<br>
                            <input type="text" class="ipcss" [(ngModel)]="clientCountry" [disabled]="!userType"
                                [hidden]="existuserFlag">
                            <mat-select required [(ngModel)]="clientCountry" class="ipcss"
                                [hidden]="newUserFlag" disableOptionCentering>
                                <mat-option *ngFor="let country of countryArray" value={{country.country_desc}}>
                                    {{country.country_desc}}</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Specialization<br>
                            <select class="ipcss " [(ngModel)]="speclization">
                                <option Selected>Select</option>
                                <option *ngFor="let spl of splArray" value="{{spl.spl_id}}">{{spl.spl_name}}
                                </option>
                            </select>
                        </mat-label>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Referred by<br>
                            <input type="text" class="ipcss " maxlength="50" [(ngModel)]="referedBy" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Booking date
                            <input type="date" class="ipcss" max="9999-12-31" [(ngModel)]="appDate" (click)="clickAppDate(appDate)"
                                min="{{currentDate}}">
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Booking Session
                            <select class="ipcss" [(ngModel)]="visitSession" (change)="selectSession()">
                                <option *ngFor="let session of getVisitingSessionArray"> {{session.description}}
                                </option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="sessionFlag">
                        <mat-label class="matlabel">Time</mat-label>
                        <div class="row">
                            <div class="col-6" style="padding-right: 0px;">
                                <select class="ipcss  " [(ngModel)]="startTime">
                                    <option *ngFor="let name of sessionTimeStart" value="{{name}}">{{name}}</option>
                                </select>
                            </div>
                            <div class="col-6" [hidden]="sessionFlag" style="padding-right: 0px;">
                                <select class="ipcss " [(ngModel)]="endTime">
                                    <option *ngFor="let name of sessionTimeEnd" value="{{name}}">{{name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                        <mat-label class="matlabel">Test base
                            <select class="ipcss" [(ngModel)]="diagTestGroup" (change)="diagTestGroupChange()">
                                <option value="test">Tests</option>
                                <option value="package">Packages</option>
                            </select>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="diagtest_flag">
                        <mat-label class="matlabel">Test Type
                            <select class="ipcss" required [(ngModel)]="diagTest" (change)="diagTestChange()">
                                <option [ngValue]="Select">Select</option>
                                <option *ngFor="let x of testListArray">{{x}}</option>
                            </select>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="paytypreq">
                        <mat-label class="matlabel">Payment type<br>
                            <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                                (selectionChange)="changePay()" disableOptionCentering>
                                <mat-option *ngFor="let pay of paymentArray" value={{pay.pay_id}}>{{pay.pay_desc}}
                                </mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="insuranceFlag">
                        <mat-label class="matlabel">Insurer<br>
                            <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insuranceId"
                                disableOptionCentering>
                                <mat-option *ngFor="let ins of insurancyArray" value={{ins.insurer_id}}>
                                    {{ins.insurer_desc}}</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
                        <mat-label class="matlabel">Card No <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                        </mat-label>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
                        <mat-label class="matlabel">Holder name<br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                        </mat-label>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="tranFlag">
                        <mat-label class="matlabel">Transaction ID<br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
                        </mat-label>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
                        <mat-label class="matlabel">Cheque No <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                        </mat-label>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
                        <mat-label class="matlabel">Bank Name <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                        </mat-label>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:5px;">
                    <p class="tervys_heading">Diagnosis Test</p>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="diagtest_flag">
                        <mat-label class="matlabel">Test</mat-label>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="test_name"
                            (keyup)="getTest(test_name)" required matInput />
                        <div class="scroll_checkbox test_box" id="Div8">
                            <table>
                                <tr *ngFor="let tests of testNameArray">
                                    <td *ngIf="!tests.show">
                                        <mat-checkbox color="primary" (change)="clickTest(tests.checked,tests.test_id)"
                                            [(ngModel)]="tests.checked">
                                        </mat-checkbox>
                                    </td>
                                    <td *ngIf="!tests.show" style="text-align:left;"><span
                                            class="test">{{tests.test_name}}</span></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="!diagtest_flag">
                        <mat-label class="matlabel">Test group</mat-label>
                        <input type="text" class="ipcss inpat_width" [(ngModel)]="test_group"
                            (keyup)="getTestGroup(test_group)" required matInput />
                        <div class="scroll_checkbox test_box" id="Div8">
                            <table>
                                <tr *ngFor="let tests of testGroupArray">
                                    <td *ngIf="!tests.show">
                                        <mat-checkbox color="primary"
                                            (change)="clickTest(tests.checked,tests.diag_packages_id)"
                                            [(ngModel)]="tests.checked">
                                        </mat-checkbox>
                                    </td>
                                    <td *ngIf="!tests.show" style="text-align:left;"><span
                                            class="test">{{tests.package_name}}</span></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel"></mat-label>
                        <img src="../../../assets/ui_icons/arrow_green.svg" class="arrow_imageprescript"
                            style="margin-top: 85px;" (click)="addTest()" />
                    </div>
                    <!-- bill create -->
                    <div *ngIf="priceTable.length != 0" class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8"
                        style="margin-top: 24px;margin-left: -50px !important;">
                        <div class="dig_table_overflow">
                            <div class="table-responsive" *ngIf="priceTable.length != 0">
                                <table class="table table-hover table-dynamic" id="tbl">
                                    <thead>
                                        <tr>
                                            <th>Sno</th>
                                            <th>Description</th>
                                            <th *ngIf="paytypreq">Pay type</th>
                                            <th>Amount</th>
                                            <th>Quantity</th>
                                            <th style="width:12%;" >Concession (%)</th>
                                            <th style="width:12%;">After Concession</th>
                                            <th [hidden]="!isoutsource">Outsource</th>
                                            <th style="width:13%;">Priority</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let price of priceTable">
                                            <td style="text-align: left;">{{price.sno}}</td>
                                            <td style="text-align: left;">{{price.test_name}}</td>
                                            <td style="text-align: left;" *ngIf="paytypreq">{{price.pay_type}}</td>
                                            <td>
                                                <input style="text-align: right;" class="ipcss"
                                                    [(ngModel)]="price.amount_txt"
                                                    (keyup)="changeAmount(price.quantity,price.amount_txt,price.concession,price.test_id)"
                                                    matInput />
                                            </td>
                                            <td style="text-align: center;">
                                                <input style="text-align: right;" class="ipcss" type="number"
                                                    [(ngModel)]="price.quantity" value="1"
                                                    (keyup)="changeAmount(price.quantity,price.amount_txt,price.concession,price.test_id)"
                                                    matInput />
                                            </td>
                                            <td>
                                                <input style="text-align: right;" class="ipcss"
                                                    [(ngModel)]="price.concession"
                                                    (keyup)="changeAmount(price.quantity,price.amount_txt,price.concession,price.test_id)"
                                                    matInput />
                                            </td>
                                            <td>
                                                <input style="text-align: right;" class="ipcss" disabled="true"
                                                    [(ngModel)]="price.fee" matInput />
                                            </td>
                                            <td>
                                                <select class="ipcss" *ngIf="price.outarray !=undefined" [(ngModel)]="price.outsource" (change)="calculateOutPrice()">
                                                    <option *ngFor="let out of price.outarray" value={{out.out_id}}> {{out.out_desc}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="ipcss" [(ngModel)]="price.priority"> 
                                                    <option  value="0"> Normal </option>
                                                    <option  value="1"> High </option>
                                                </select>
                                            </td>
                                            <td>
                                                <img (click)="removeTest(price.test_id,price.type_of_test)"
                                                    src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                                    height="15px" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
                        <div class="cover_div" *ngIf="!paytypreq">
                            <div class="row">
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                <mat-label class="matlabel">Payment type<br>
                                  <mat-select class="ipcss" [(ngModel)]="payType1" disableOptionCentering (selectionChange)="changePayTypeReq()">
                                    <mat-option *ngFor="let pay of paymentArray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                                  </mat-select>
                                </mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insurFlag1">
                                <mat-label class="matlabel">Insurer<br>
                                  <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insuranceId" disableOptionCentering>
                                    <mat-option *ngFor="let ins of insurancyArray" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
                                  </mat-select>
                                </mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="cardFlag1">
                                <mat-label class="matlabel">Card No <br>
                                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" required />
                                </mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="tranFlag1">
                                <mat-label class="matlabel">Transaction ID<br>
                                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" required/>
                                </mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="chequeFlag1">
                                <mat-label class="matlabel">Cheque No <br>
                                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" required/>
                                </mat-label>
                              </div>
                              <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" >
                                <mat-label class="matlabel">Amount<br>
                                  <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput maxlength="7" />
                                </mat-label>
                              </div>
                              <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                                <mat-label class="matlabel ">
                                  <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align"
                                    (click)="addPaymentDetails()" style="float: right;margin-top: 20px;"/>
                                </mat-label>
                              </div>
                            </div>
              
                            <div class="row">
                              <table>
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;">{{pay.amount}}</td>
                                  <td>
                                    <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                                    <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                                  </td>
                                </tr>
                              </table>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-0 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div> -->
                    <div class="col-0 col-sm-6 col-md-3 col-lg-3 col-xl-5">
                        <div class="row" *ngIf="priceTable.length != 0">
                            <div class="col-12">
                                <div class="row" style="float: right;">
                                    <div class="col-2" style="width: 193px;">
                                        <mat-label class="matlabel" style="float: right;padding: 5px 0 0 0;">Total Amount
                                        </mat-label>
                                    </div>
                                    <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-5" style="padding-right: 34px;">
                                        <input type="number" class="ipcss" [(ngModel)]="balance" style="text-align: right;"
                                            disabled matInput>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row" style="float: right;">
                                    <div class="col-2" style="width: 193px;">
                                        <mat-label class="matlabel" style="float: right;padding: 5px 0 0 0;">Paid
                                        </mat-label>
                                    </div>
                                    <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-5" style="padding-right: 34px;">
                                        <input class="ipcss" matInput text-right (keyup)="changeAdvance()" [(ngModel)]="advance"
                                            maxlength="10" style="border-radius: 5px;text-align: right;" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row" style="float: right;">
                                    <div class="col-2" style="width: 193px;">
                                        <mat-label class="matlabel" style="float: right;padding: 5px 0 0 0;">Balance
                                        </mat-label>
                                    </div>
                                    <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-5" style="padding-right: 34px;">
                                        <input class="ipcss" matInput text-right [(ngModel)]="remaingBalance" maxlength="10"
                                            style="border-radius: 5px;text-align: right;" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>