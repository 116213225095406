import { Component, OnInit, HostListener, ViewEncapsulation, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog } from '@angular/material/dialog';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-gastro-clinical-exam',
  templateUrl: './gastro-clinical-exam.component.html',
  styleUrls: ['./gastro-clinical-exam.component.scss']
})
export class GastroClinicalExamComponent implements OnInit {
  public clinicalFlag = "min";
  public tcchestpain;
  public tcanysym;
  public persoanldata: any;
  public femoralrate_required;
  carotwcont_required;
  subscription: Subscription;
  public medical_Check = [];
  public medical_history_array: any = [];
  public medical_history_array1 = [];
  public cpainloc_array: any = [];
  public cpaincar_array: any = [];
  public cpainrad_array: any = [];
  public cpainagrel_array: any = [];
  public cpainasympt_array: any = [];
  public cpaincause_array: any = [];
  public btrig_array: any = [];
  public bonset_array: any = [];
  public palp_array: any = [];
  public mucus_array: any = [];
  public Characer_pain: any = [];
  public client_reg_id;
  public relation_id;
  public sub_rel_id;
  public client;
  public case_hist_id;
  public life_style_id;

  public spl_id;
  public url;

  public mainconcen_label: string;
  public main_flag: boolean = true;
  public main_concern: string;

  public prev_hist_label: string;
  public prev_hist_flag: boolean = true;
  public prev_hist: string;

  public stress_label: string;
  public stress_flag: boolean = true;
  public stress_fac_flag: boolean = true;
  public stress_factor_flag: boolean = true;
  public stress_factor: string;
  public stress: string;
  public stress_factor_label: string;
  public symptom_label: string;
  public symptom: string;

  public take_med_label: string;
  public take_med_flag: boolean = true;
  public take_med: string;

  public take_med_det_label: boolean = true;
  public take_med_det_flag: boolean = true;
  public take_med_det: string;

  public headache_label: string;
  public headache_flag: boolean = true;
  public headache: string;

  public giddiness_label: string;
  public giddiness_flag: boolean = true;
  public giddiness: string;

  public convulsions_label: string;
  public convulsions_flag: boolean = true;
  public convulsions: string;

  public tremor_label: string;
  public tremor_flag: boolean = true;
  public tremor: string;

  public ear_label: string;
  public ear_flag: boolean = true;
  public ear: string;

  public hoarseness_label: string;
  public hoarseness_flag: boolean = true;
  public hoarseness: string;

  public epistaxis_label: string;
  public epistaxis_flag: boolean = true;
  public epistaxis: string;

  public dysphagia_label: string;
  public dysphagia_flag: boolean = true;
  public dysphagia: string;

  public nose_label: string;
  public nose_flag: boolean = true;
  public nose: string;

  public eye_label: string;
  public eye_flag: boolean = true;
  public eye: string;

  public cold_label: string;
  public cold_flag: boolean = true;
  public cold: string;

  public chest_pain_label: string;
  public chest_pain_flag: boolean = true;
  public chest_pain: string;

  public chest_pain_loc_label: string;
  public chest_pain_loc_flag: boolean = true;
  public loc_required: boolean = false;
  public chest_pain_loc: string;

  public chest_pain_char_label: string;
  public chest_pain_char_flag: boolean = true;
  public char_required: boolean = false;
  public chest_pain_char: string;

  public chest_pain_rad_label: string;
  public chest_pain_rad_flag: boolean = true;
  public rad_required: boolean = false;
  public chest_pain_rad: string;

  public chest_pain_dur_label: string;
  public chest_pain_dur_flag: boolean = true;
  public dur_required: boolean = false;
  public chest_pain_dur: string;

  public chest_pain_aggr_label: string;
  public chest_pain_aggr_flag: boolean = true;
  public aggravat_required: boolean = false;
  public chest_pain_aggr: string;

  public chest_pain_reliev_label: string;
  public chest_pain_reliev_flag: boolean = true;
  public relieve_required: boolean = false;
  public chest_pain_reliev: string;

  public chest_pain_asymp_label: string;
  public chest_pain_asymp_flag: boolean = true;
  public assosymp_required: boolean = false;
  public chest_pain_asymp: string;

  public chest_pain_cause_label: string;
  public chest_pain_cause_flag: boolean = true;
  public paincause_required: boolean = false;
  public chest_pain_cause: string;

  public chest_pain_intense_label: string;
  public chest_pain_intense_flag: boolean = true;
  public intensity_required: boolean = false;
  public chest_pain_intense: string;


  public mens_period_label: string;
  public mens_period_flag: boolean = true;
  public mens_period: string;

  public last_mens_period_label: string;
  public last_mens_period_flag: boolean = true;
  public last_mens_period: string;
  public mens_frequency: string;

  public cycle_label: string;
  public cycle_flag: boolean = true;
  public cycle: string;

  public mens_pain_label: string;
  public mens_pain_flag: boolean = true;
  public mens_pain: string;

  public mens_pain_time_label: string;
  public mens_pain_time_flag: boolean = true;
  public mens_pain_time: string;

  public mens_pain_char_label: string;
  public mens_pain_char_flag: boolean = true;
  public mens_pain_char: string;

  public skin_label: string;
  public skin_flag: boolean = true;
  public skin: string;

  public skin_abnorm_label: string;
  public skin_abnorm_flag: boolean = true;
  public skin_abnorm_char: string;

  public mouth_label: string;
  public mouth_flag: boolean = true;
  public mouth: string;

  public nails_label: string;
  public nails_flag: boolean = true;
  public nails: string;

  public lungs_label: string;
  public lungs_flag: boolean = true;
  public lungs: string;

  public dizziness_label: string;
  public dizziness_flag: boolean = true;
  public dizziness: string;

  public palpitation_label: string;
  public palpitation_flag: boolean = true;
  public palpitation: string;

  public palpitation_type_label: string;
  public palpitation_type_flag: boolean = true;
  public palpitation_type: string;

  public cough_label: string;
  public cough_flag: boolean = true;
  public cough: string;

  public expectoration_label: string;
  public expectoration_flag: boolean = true;
  public expectoration: string;

  public haemoptysis_label: string;
  public haemoptysis_flag: boolean = true;
  public haemoptysis: string;

  public weezing_label: string;
  public weezing_flag: boolean = true;
  public weezing: string;

  public dyspnoea_label: string;
  public dyspnoea_flag: boolean = true;
  public dyspnoea: string;

  public orthohnoea_label: string;
  public orthohnoea_flag: boolean = true;
  public orthohnoea: string;

  public pain_label: string;
  public pain_flag: boolean = true;
  public pain: string;

  public radiation_label: string;
  public radiation_flag: boolean = true;
  public radiation: string;

  public nature_label: string;
  public nature_flag: boolean = true;
  public nature: string;

  public situation_label: string;
  public situation_flag: boolean = true;
  public situation: string;

  public relation_label: string;
  public relation_flag: boolean = true;
  public relation: string;

  public vomiting_label: string;
  public vomiting_flag: boolean = true;
  public vomiting: string;

  public nausea_label: string;
  public nausea_flag: boolean = true;
  public nausea: string;

  public haematemesis_label: string;
  public haematemesis_flag: boolean = true;
  public haematemesis: string;

  public belching_label: string;
  public belching_flag: boolean = true;
  public belching: string;

  public bowel_abrnorm_label: string;
  public bowel_abrnorm_flag: boolean = true;
  public bowel_abrnorm: string;

  public blood_stool_label: string;
  public blood_stool_flag: boolean = true;
  public blood_stool: string;

  public haemorrhoids_label: string;
  public haemorrhoids_flag: boolean = true;
  public haemorrhoids: string;

  public frequency_label: string;
  public frequency_flag: boolean = true;
  public frequency: string;

  public nocturia_label: string;
  public nocturia_flag: boolean = true;
  public nocturia: string;

  public dysuria_label: string;
  public dysuria_flag: boolean = true;
  public dysuria: string;

  public haematuria_label: string;
  public haematuria_flag: boolean = true;
  public haematuria: string;

  public discharge_label: string;
  public discharge_flag: boolean = true;
  public discharge: string;

  public gen_fatigue_label: string;
  public gen_fatigue_flag: boolean = true;
  public gen_fatigue: string;

  public weigh_change_label: string;
  public weigh_change_flag: boolean = true;
  public weigh_change: string;

  public fever_label: string;
  public fever_flag: boolean = true;
  public fever: string;

  public night_sweats_label: string;
  public night_sweats_flag: boolean = true;
  public night_sweats: string;

  public jaundice_label: string;
  public jaundice_flag: boolean = true;
  public jaundice: string;

  public vaginal_discharge_label: string;
  public vaginal_discharge_flag: boolean = true;
  public vaginal_discharge: string;

  public surgery_label: string;
  public surgery_flag: boolean = true;
  public surgerydet_flag: boolean = true;
  public surgeryreq_flag: boolean = false;
  public surgery: string;
  public surger_details: string;

  public hypertension_label: string;
  public hypertension_flag: boolean = true;
  public hypertension: string;

  public diabetics_label: string;
  public diabetics_flag: boolean = true;
  public diabetics: string;

  public show_mens_flag: boolean = false;
  public time_paindisabled: boolean;
  public cycle_disabled: boolean;
  public cycle_freqdisabled: boolean;
  public menstrual_paindisbaled: boolean;
  public character_painrequire;
  public time_painrequire;
  public currentDate;
  public gaspainlocarray: any = [];
  public gaspainnaturearray: any = [];
  public gaspainradarray: any = [];
  public tremorarray: any = [];
  public hoarsearray: any = [];
  public epistaxisarray: any = [];
  public dysphagiaarray: any = [];
  public eyearray: any = [];

  public nerv_other: string;
  public ent_other: string;
  public cardio_other: string;
  public resp_other: string;
  public giap_other: string;
  public genito_other: string;
  public mens_other: string;

  constructor(public dropdown_dialog: MatDialog, public dialog: MatDialog, public messageservice: CasesheetService, public http: Http,
    public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public masterCSdata: MasterCSHelperService) {

    this.stress = "No";
    this.take_med = "No";
    this.chest_pain = "No";
    this.surgery = "No";
    this.take_med_det_flag = true;
    this.paincause_required = false;
    this.intensity_required = false;
    this.assosymp_required = false;
    this.relieve_required = false;
    this.aggravat_required = false;
    this.surgerydet_flag = true;
    this.dur_required = false;
    this.rad_required = false;
    this.char_required = false;
    this.loc_required = false;

    this.gaspainlocarray = [{ loc_id: 1, loc_desc: "Upper right" }, { loc_id: 2, loc_desc: "Upper middle" }, { loc_id: 3, loc_desc: "Middle right" }, { loc_id: 4, loc_desc: "Middle" }, { loc_id: 5, loc_desc: "Middle right" }, { loc_id: 6, loc_desc: "Lower right" }, { loc_id: 7, loc_desc: "Lower middle" }, { loc_id: 7, loc_desc: "Lower left" }];
    this.gaspainnaturearray = [{ pnature_id: 1, pnature_desc: "Cramping" }, { pnature_id: 2, pnature_desc: "Dull" }, { pnature_id: 3, pnature_desc: "Aching" }];
    this.gaspainradarray = [{ prad_id: 1, prad_desc: "Left to right" }, { prad_id: 2, prad_desc: "Right to left" }, { prad_id: 3, prad_desc: "Bottom up" }, { prad_id: 4, prad_desc: "Upper to lower" }, { prad_id: 5, prad_desc: "To lower back" }];
    this.tremorarray = [{ trem_id: 1, trem_desc: "Arms" }, { trem_id: 2, trem_desc: "Head" }, { trem_id: 3, trem_desc: "Vocal Cords" }, { trem_id: 4, trem_desc: "Trunk" }, { trem_id: 5, trem_desc: "Legs" }];
    this.hoarsearray = [{ hoarse_id: 1, hoarse_desc: "Weak" }, { hoarse_id: 2, hoarse_desc: "Breathy" }, { hoarse_id: 3, hoarse_desc: "Scratchy" }, { hoarse_id: 4, hoarse_desc: "Husky" }];
    this.epistaxisarray = [{ epistax_id: 1, epistax_desc: "Anterior" }, { epistax_id: 2, epistax_desc: "Posterior" }];
    this.dysphagiaarray = [{ dysphag_id: 1, dysphag_desc: "Oropharyngeal" }, { dysphag_id: 2, dysphag_desc: "Esophageal" }, { dysphag_id: 3, dysphag_desc: "Esophagogastric" }, { dysphag_id: 4, dysphag_desc: "Paraesophageal" }];
    this.eyearray = [{ eye_id: 1, eye_desc: "Normal" }, { eye_id: 2, eye_desc: "Xanthelasma" }, { eye_id: 3, eye_desc: "Primary biliary cirrhosis" }, { eye_id: 4, eye_desc: "Florid xanthelasma" }, { eye_id: 5, eye_desc: "Jaundice" }, { eye_id: 6, eye_desc: "Asymptomatic peripheral corneal opacification" }];

  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.clinicalFlag = message;
      }
    });

    if (Doc_Helper.getAppFlow() == "client") {
      this.persoanldata = Helper_Class.getInfo();
      this.client = true;

    } else {
      this.persoanldata = Doc_Helper.getClient_Info();
      this.client = false;
    }

    this.client_reg_id = this.persoanldata.client_id;
    this.relation_id = this.persoanldata.relation_id;
    this.sub_rel_id = this.persoanldata.sub_id,
      this.spl_id = this.persoanldata.spl_id;
    if (Helper_Class.getRet() != undefined) {
      if (Helper_Class.getRet().case_hist_id != null) {
        this.case_hist_id = Helper_Class.getRet().case_hist_id;
      }
      if (Helper_Class.getRet().life_style_id != null) {
        this.life_style_id = Helper_Class.getRet().life_style_id;
      }
    }

    if (masterCSData_Helper.getMasterChestpainLocationDetails() == undefined) {
      await this.masterCSdata.getChestpainLocationDetails();
      this.getChestpainLocationDetails();
    } else {
      this.getChestpainLocationDetails();
    }

    for (var i = 0; i < Helper_Class.getHopitalfieldsRet().length; i++) {
      if (Helper_Class.getHopitalfieldsRet()[i].page_name == "Gastroclinical") {
        if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
          if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
            for (var j = 0; j < Helper_Class.getHopitalfieldsRet()[i].fields.length; j++) {
              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Main concenrns") {
                this.mainconcen_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.main_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Past medical history") {
                this.prev_hist_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.prev_hist_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Stress factors") {
                this.stress_label = "Do you feel stressed";
                this.stress_factor_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.symptom_label = "Symtoms";
                this.stress_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Taking any medication") {
                this.take_med_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.take_med_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Medicines") {
                this.take_med_det_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.take_med_det_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Headache") {
                this.headache_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.headache_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Giddiness") {
                this.giddiness_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.giddiness_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Convulsions") {
                this.convulsions_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.convulsions_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Tremor") {
                this.tremor_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.tremor_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Ear") {
                this.ear_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.ear_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Hoarseness") {
                this.hoarseness_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.hoarseness_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Epistaxis") {
                this.epistaxis_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.epistaxis_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Dysphagia") {
                this.dysphagia_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.dysphagia_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Frequent cold") {
                this.cold_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.cold_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Eye") {
                this.eye_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.eye_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Nose") {
                this.nose_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.nose_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Do you feel pain in the chest") {
                this.chest_pain_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Location") {
                this.chest_pain_loc_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_loc_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Character of pain") {
                this.chest_pain_char_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_char_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Radiation") {
                this.chest_pain_rad_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_rad_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Duration") {
                this.chest_pain_dur_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_dur_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Aggravating factors") {
                this.chest_pain_aggr_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_aggr_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Relieving factors") {
                this.chest_pain_reliev_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_reliev_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Associated Symptoms") {
                this.chest_pain_asymp_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_asymp_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Cause of pain") {
                this.chest_pain_cause_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_cause_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Intensity of pain") {
                this.chest_pain_intense_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.chest_pain_intense_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Cough") {
                this.cough_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.cough_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Expectoration") {
                this.expectoration_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.expectoration_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Haemoptysis") {
                this.haemoptysis_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.haemoptysis_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Weezing") {
                this.weezing_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.weezing_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Palpitation") {
                this.palpitation_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.palpitation_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Pain") {
                this.pain_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.pain_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Nature") {
                this.nature_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.nature_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Situation") {
                this.situation_label = "Location";
                this.situation_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Gastro radiation") {
                this.radiation_label = "Radiation";
                this.radiation_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Relation") {
                this.relation_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.relation_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Nausea") {
                this.nausea_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.nausea_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Vomiting") {
                this.vomiting_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.vomiting_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Haematemesis") {
                this.haematemesis_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.haematemesis_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Belching or gas") {
                this.belching_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.belching_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Bowel Abnormality") {
                this.bowel_abrnorm_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bowel_abrnorm_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Blood stool") {
                this.blood_stool_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.blood_stool_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Haemorrhoids") {
                this.haemorrhoids_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.haemorrhoids_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Frequency") {
                this.frequency_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.frequency_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Nocturia") {
                this.nocturia_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.nocturia_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Dysuria") {
                this.dysuria_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.dysuria_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Haematuria") {
                this.haematuria_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.haematuria_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Discharge") {
                this.discharge_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.discharge_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Mesnstrual periods") {
                this.mens_period_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.mens_period_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Last mesnstrual period") {
                this.last_mens_period_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.last_mens_period_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Cycle frequency") {
                this.cycle_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.cycle_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Menstrual pain") {
                this.mens_pain_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.mens_pain_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Timing of pain") {
                this.mens_pain_time_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.mens_pain_time_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Character of pain") {
                this.mens_pain_char_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.mens_pain_char_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Skin") {
                this.skin_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.skin_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Skin abnormalities") {
                this.skin_abnorm_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.skin_abnorm_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Eyes") {
                this.eye_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.eye_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Mouth") {
                this.mouth_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.mouth_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Nails") {
                this.nails_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.nails_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "General fatigue") {
                this.gen_fatigue_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.gen_fatigue_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Weight Change") {
                this.weigh_change_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.weigh_change_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Fever") {
                this.fever_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.fever_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Night sweats") {
                this.night_sweats_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.night_sweats_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Jaundice") {
                this.jaundice_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.jaundice_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Surgery") {
                this.surgery_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.surgery_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Hypertension") {
                this.hypertension_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.hypertension_flag = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Diabetics") {
                this.diabetics_label = "Diabetic";
                this.diabetics_flag = false;
              }
            }
          }
        }
      }
    }
    this.display_data();
    this.medication_change();
    this.pain_change();
    this.Get_character_ofpain();
    
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }

  stress_change(e) {
    if (e == "Yes") {
      this.stress_fac_flag = false;
    } else {
      this.stress_fac_flag = true;
      this.stress = "";
      this.symptom = "";
    }
  }

  medication_change() {
    if (this.take_med == "Yes") {
      this.take_med_det_flag = false;
    } else {
      this.take_med_det_flag = true;
      this.take_med_det = "";
    }
  }

  surgery_change() {
    if (this.surgery == "Yes") {
      this.surgerydet_flag = false;
      this.surgerydet_flag = false;
      this.surgeryreq_flag = true;

    } else {
      this.surgerydet_flag = true;
      this.surgerydet_flag = true;
      this.surgeryreq_flag = false;
      this.surger_details = "";
    }
  }

  pain_change() {
    if (this.chest_pain == "Yes") {
      this.pain_flag = false;
      this.paincause_required = true;
      this.intensity_required = true;
      this.assosymp_required = true;
      this.relieve_required = true;
      this.aggravat_required = true;
      this.dur_required = true;
      this.rad_required = true;
      this.char_required = true;
      this.loc_required = true;

    } else {
      this.pain_flag = true;
      this.chest_pain_cause = null;
      this.chest_pain_intense = null;
      this.chest_pain_asymp = null;
      this.chest_pain_reliev = null;
      this.chest_pain_aggr = null;
      this.chest_pain_dur = null;
      this.chest_pain_rad = null;
      this.chest_pain_char = null;
      this.chest_pain_loc = null;
      this.paincause_required = false;
      this.intensity_required = false;
      this.assosymp_required = false;
      this.relieve_required = false;
      this.aggravat_required = false;
      this.dur_required = false;
      this.rad_required = false;
      this.char_required = false;
      this.loc_required = false;
    }
  }

  //Get chest pain locations
  async getChestpainLocationDetails() {
    this.cpainloc_array = [];
    var obj = masterCSData_Helper.getMasterChestpainLocationDetails();

    if (obj != undefined) {
      for (var i = 0; i < obj.pain_locations.length; i++) {
        this.cpainloc_array.push({
          location_id: obj.pain_locations[i].location_id,
          location_desc: obj.pain_locations[i].location_desc,
        })
      }
    }
    if (masterCSData_Helper.getMasterChestpainCharacters() == undefined) {
      await this.masterCSdata.getChestpainCharacters();
      this.getChestpainCharacters();
    } else {
      this.getChestpainCharacters();
    }
  }

  //Get chest pain characters
  async getChestpainCharacters() {
    this.cpaincar_array = [];
    var obj = masterCSData_Helper.getMasterChestpainCharacters();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_characters.length; i++) {
        this.cpaincar_array.push({
          character_id: obj.pain_characters[i].character_id,
          character_desc: obj.pain_characters[i].character_desc,
        })
      }
    }
    if (masterCSData_Helper.getMasterChestpainRadiations() == undefined) {
      await this.masterCSdata.getChestpainRadiations();
      this.getChestpainRadiations();
    } else {
      this.getChestpainRadiations();
    }
  }

  //Get chest pain radiation
  async getChestpainRadiations() {
    this.cpainrad_array = [];
    var obj = masterCSData_Helper.getMasterChestpainRadiations();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_radiations.length; i++) {
        this.cpainrad_array.push({
          radiation_id: obj.pain_radiations[i].radiation_id,
          radiation_desc: obj.pain_radiations[i].radiation_desc,
        })
      }
    }
    if (masterCSData_Helper.getMasterChestpainAgrelFactors() == undefined) {
      await this.masterCSdata.getChestpainAgrelFactors();
      this.getChestpainAgrelFactors();
    } else {
      this.getChestpainAgrelFactors();
    }
  }

  //Get chest pain agrav releaf factors
  async getChestpainAgrelFactors() {
    this.cpainagrel_array = [];
    var obj = masterCSData_Helper.getMasterChestpainAgrelFactors();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_agrels.length; i++) {
        this.cpainagrel_array.push({
          agrrelfact_id: obj.pain_agrels[i].agrrelfact_id,
          agrrelfact_desc: obj.pain_agrels[i].agrrelfact_desc,
        });
      }
      //this.chest_pain_aggr = this.cpainagrel_array[0].agrrelfact_desc;
      //this.chest_pain_reliev = this.cpainagrel_array[0].agrrelfact_desc;
    }
    if (masterCSData_Helper.getMasterChestpainAssociatedSymptoms() == undefined) {
      await this.masterCSdata.getChestpainAssociatedSymptoms();
      this.getChestpainAssociatedSymptoms();
    } else {
      this.getChestpainAssociatedSymptoms();
    }
  }

  //Get chest pain associated symptoms
  async getChestpainAssociatedSymptoms() {
    this.cpainasympt_array = [];
    var obj = masterCSData_Helper.getMasterChestpainAssociatedSymptoms();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_asympts.length; i++) {
        this.cpainasympt_array.push({
          asssympt_id: obj.pain_asympts[i].asssympt_id,
          asssympt_desc: obj.pain_asympts[i].asssympt_desc,
        })
      }
      //this.chest_pain_asymp = this.cpainasympt_array[0].asssympt_desc;
    }
    if (masterCSData_Helper.getMasterChestpainCauses() == undefined) {
      await this.masterCSdata.getChestpainCauses();
      this.getChestpainCauses();
    } else {
      this.getChestpainCauses();
    }
  }

  //Get chest pain causes
  async getChestpainCauses() {
    this.cpaincause_array = [];
    var obj = masterCSData_Helper.getMasterChestpainCauses();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_causes.length; i++) {
        this.cpaincause_array.push({
          cause_id: obj.pain_causes[i].cause_id,
          cause_desc: obj.pain_causes[i].cause_desc,
        })
      }
      this.chest_pain_cause = this.cpaincause_array[0].cause_desc;
    }
  }

  //Get character of pain
  Get_character_ofpain() {
    var obj = masterCSData_Helper.getMasterCharacterofpain();
    if (obj == undefined) {
      obj = this.masterCSdata.getCharacterofpain();
    }

    if (obj != undefined) {
      for (var i = 0; i < obj.mens_pains.length; i++) {
        this.Characer_pain = obj.mens_pains;
      }
    }
  }

  Date_Formate(data) {
    var Splitter = data.split("-");
    return Splitter[2] + "-" + Splitter[1] + "-" + Splitter[0];
  }

  menstrualpain_change(data) {
    if (data == "Yes") {
      this.time_painrequire = true;
      this.character_painrequire = true;
      this.time_paindisabled = false;
      //this.character_paindisabled = false;
    } else {
      this.time_painrequire = false;
      this.character_painrequire = false;
      this.time_paindisabled = true;
      //this.character_paindisabled = true;
      //this.pain_timing = undefined;
      //this.character_pain = undefined;
    }
  }

  Menstrual_OnChange(data) {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = 0 + dd
    }

    if (mm < 10) {
      mm = 0 + mm
    }

    var today1 = yyyy + '-' + mm + '-' + dd;
    var Selecteddate = this.Date_Formate(data).toString().split('-');
    var Selected_Date = Selecteddate[2].toString() + "-" + Selecteddate[1].toString() + "-" + Selecteddate[0].toString();

    var curr_date = new Date(today);
    curr_date.setDate(curr_date.getDate() + 7);

    var startDay = new Date(today1);
    var Endday = new Date(Selected_Date);

    var millisecondsPerDay = 1000 * 60 * 60 * 24;

    var millisBetween1 = curr_date.getTime() - Endday.getTime();
    var days2 = millisBetween1 / millisecondsPerDay;
    var Days1 = Math.floor(days2);
    var Weeks1 = (Days1 / 7)
    var Weeks_New1 = Math.floor(Weeks1);

    function toYYYYMMDD(d) {
      var yyyy = d.getFullYear().toString();
      var mm = (d.getMonth() + 101).toString().slice(-2);
      var dd = (d.getDate() + 100).toString().slice(-2);
      return dd + "-" + mm + "-" + yyyy;
    }
  }

  send_data() {
    var clinical_data = {
      stress_factor: this.stress_factor,
      stress: this.stress,
      symptom: this.symptom,
      main_concern: this.main_concern,
      prev_hist: this.prev_hist,
      take_med: this.take_med,
      take_med_det: this.take_med_det,
      headache: this.headache,
      giddiness: this.giddiness,
      convulsions: this.convulsions,
      tremor: this.tremor,
      ear: this.ear,
      hoarseness: this.hoarseness,
      epistaxis: this.epistaxis,
      dysphagia: this.dysphagia,
      cold: this.cold,
      eye: this.eye,
      nose: this.nose,
      chest_pain: this.chest_pain,
      chest_pain_loc: this.chest_pain_loc,
      chest_pain_char: this.chest_pain_char,
      chest_pain_rad: this.chest_pain_rad,
      chest_pain_dur: this.chest_pain_dur,
      chest_pain_aggr: this.chest_pain_aggr,
      chest_pain_reliev: this.chest_pain_reliev,
      chest_pain_asymp: this.chest_pain_asymp,
      chest_pain_intense: this.chest_pain_intense,
      chest_pain_cause: this.chest_pain_cause,
      cough: this.cough,
      expectoration: this.expectoration,
      haemoptysis: this.haemoptysis,
      weezing: this.weezing,
      palpitation: this.palpitation,
      dyspnoea: this.dyspnoea,
      orthohnoea: this.orthohnoea,
      pain: this.pain,
      nature: this.nature,
      situation: this.situation,
      radiation: this.radiation,
      relation: this.relation,
      nausea: this.nausea,
      vomiting: this.vomiting,
      haematemesis: this.haematemesis,
      belching: this.belching,
      bowel_abrnorm: this.bowel_abrnorm,
      blood_stool: this.blood_stool,
      haemorrhoids: this.haemorrhoids,
      frequency: this.frequency,
      nocturia: this.nocturia,
      dysuria: this.dysuria,
      haematuria: this.haematuria,
      discharge: this.discharge,
      mens_period: this.mens_period,
      last_mens_period: this.last_mens_period,
      cycle: this.cycle,
      mens_frequency: this.mens_frequency,
      mens_pain: this.mens_pain,
      mens_pain_time: this.mens_pain_time,
      mens_pain_char: this.mens_pain_char,
      gen_fatigue: this.gen_fatigue,
      weigh_change: this.weigh_change,
      fever: this.fever,
      night_sweats: this.night_sweats,
      jaundice: this.jaundice,
      hypertension: this.hypertension,
      diabetics: this.diabetics,
      surgery: this.surgery,
      surger_details: this.surger_details,
      nerv_other:this.nerv_other,
      ent_other: this.ent_other,
      resp_other:this.resp_other,
      giap_other:this.giap_other,
      genito_other: this.genito_other,
      mens_other:this.mens_other,
    }
    Helper_Class.setclinicalexam(clinical_data);
  }

  display_data() {
    var data;
    if (Helper_Class.getRet() != undefined) {
      data = Helper_Class.getRet();
    }
    console.log("CLINICAL DATA " + JSON.stringify(data))
    if (data != undefined) {
      this.stress_factor = data.stress_factor;
      this.stress = data.stress;
      this.symptom = data.symptom;
      if (data.stress_factor == "Yes") {
        this.stress_fac_flag = false;
      } else {
        this.stress_fac_flag = true;
      }
      this.main_concern = data.main_concern;
      this.prev_hist = data.prev_hist;
      this.take_med = data.take_med;
      if (data.take_med == "Yes") {
        this.take_med_det_flag = false;
      } else {
        this.take_med_det_flag = true;
      }
      this.take_med_det = data.take_med_det;
      this.headache = data.headache;
      this.giddiness = data.giddiness;
      this.convulsions = data.convulsions;
      this.tremor = data.tremor;
      this.ear = data.ear;
      this.hoarseness = data.hoarseness;
      this.epistaxis = data.epistaxis;
      this.dysphagia = data.dysphagia;
      this.cold = data.cold;
      this.eye = data.eye;
      this.nose = data.nose;
      this.chest_pain = data.chest_pain;
      this.chest_pain_loc = data.chest_pain_loc;
      this.chest_pain_char = data.chest_pain_char;
      this.chest_pain_rad = data.chest_pain_rad;
      this.chest_pain_dur = data.chest_pain_dur;
      this.chest_pain_aggr = data.chest_pain_aggr;
      this.chest_pain_reliev = data.chest_pain_reliev;
      this.chest_pain_asymp = data.chest_pain_asymp;
      this.chest_pain_intense = data.chest_pain_intense;
      this.chest_pain_cause = data.chest_pain_cause;
      this.cough = data.cough;
      this.expectoration = data.expectoration;
      this.haemoptysis = data.haemoptysis;
      this.weezing = data.weezing;
      this.palpitation = data.palpitation;
      this.dyspnoea = data.dyspnoea;
      this.orthohnoea = data.orthohnoea;
      this.pain = data.pain;
      this.nature = data.nature;
      this.situation = data.situation;
      this.radiation = data.radiation;
      this.relation = data.relation;
      this.nausea = data.nausea;
      this.vomiting = data.vomiting;
      this.haematemesis = data.haematemesis;
      this.belching = data.belching;
      this.bowel_abrnorm = data.bowel_abrnorm;
      this.blood_stool = data.blood_stool;
      this.haemorrhoids = data.haemorrhoids;
      this.frequency = data.frequency;
      this.nocturia = data.nocturia;
      this.dysuria = data.dysuria;
      this.haematuria = data.haematuria;
      this.discharge = data.discharge;
      this.gen_fatigue = data.gen_fatigue;
      this.weigh_change = data.weigh_change;
      this.fever = data.fever;
      this.night_sweats = data.night_sweats;
      this.jaundice = data.jaundice;
      this.hypertension = data.hypertension;
      this.diabetics = data.diabetics;
      this.mens_period= data.mens_period;
      this.last_mens_period= data.last_mens_period;
      this.cycle= data.cycle;
      this.mens_frequency= data.mens_frequency;
      this.mens_pain= data.mens_pain;
      this.mens_pain_time= data.mens_pain_time;
      this.mens_pain_char= data.mens_pain_char;
      this.nerv_other = data.nerv_other,
      this.ent_other=data.ent_other,
      this.resp_other=data.resp_other,
      this.giap_other=data.giap_other,
      this.genito_other=data.genito_other,
      this.mens_other=data.mens_other,
      this.surgery = data.surgery;
      this.surger_details = data.surger_details;
      if (data.surgery == "Yes") {
        this.surgerydet_flag = false;
      } else {
        this.surgerydet_flag = true;
      }

    }
    this.send_data();
  }
}
