<div class="row" style="margin:0 auto; width:100%">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white " style="padding:7px 20px;">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Packages</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
                     class="saveimgbtn_inpatinfo " />
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn"
                     (click)="savePackage('Save')" class="saveimgbtn_inpatinfo" />
                  <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="update_btn"
                     (click)="savePackage('update')" class="saveimgbtn_inpatinfo" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div>
               <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                     <mat-label class="matlabel">Package name</mat-label>
                     <input type="text" class="ipcss" [(ngModel)]="pkg_name" aria-label="Number" matInput />
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                     <mat-label class="matlabel">Package description</mat-label>
                     <input type="text" class="ipcss" [(ngModel)]="pkg_desc" aria-label="Number" matInput />
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                     <mat-label class="matlabel">Price</mat-label>
                     <input type="text" class="ipcss" [(ngModel)]="pkg_price" aria-label="Number" matInput />
                  </div>
               </div>
               <br />
               <div class="row">
                  <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                     <div class="row">
                        <div class="col-12 col-sm-12 col-xl-10 col-lg-10 col-md-6">
                           <div class="row">
                              <div *ngFor="let testtype of diag_test"
                                 class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                                 <div class="diagnosis_testgrid testtype">
                                    <p class="titletest"><strong>{{testtype.test_type_name}}</strong></p>
                                    <div class="diag_test_content_cover">
                                       <div *ngFor="let subtest of testtype.sub_tests" id="testlbel">
                                          <span *ngIf="subtest.checked == true" class="check_img"><img
                                                (click)="ChangeTestType(false,subtest.test_id,subtest.test_name,testtype.test_type_name)"
                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                style="cursor: pointer;" width="12px" /></span>

                                          <span *ngIf="subtest.checked == false" class="check_img"><img
                                                (click)="ChangeTestType(true,subtest.test_id,subtest.test_name,testtype.test_type_name)"
                                                src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                                width="12px" /></span>
                                          {{subtest.test_name}}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>