<!-- web page -->
<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Discharge summary</h5>
          </div>
          <div class="headerButtons">
            <a (click)="goToPatientlist()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
            <!-- [hidden]="!confirm_btn && !updateFlag" -->
            <a> <img (click)="print()" src="../../../assets/img/printer.svg" width="25px" height="25px" /> </a>
            <a style="margin-right: 5px;" (click)="view_prescription()"><img
                src="../../../assets/ui_icons/buttons/view_RX_button.svg" class="viewrximgbtn_inpatinfo" /></a>
            <a style="margin-right: 5px;" (click)="Write_prescription()"><img
                src="../../../assets/ui_icons/buttons/write_RX_Button.svg" class="writerximgbtn_inpatinfo" /></a>
            <a [hidden]="confirm_btn" (click)="saveDischargeSummary()"><img
                src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>

            <a *ngIf="updateFlag" (click)="updateDischarge()"><img
                src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo" /></a>
            <!-- <a style="margin-right: 5px;" [hidden]="update_btn" (click)="Write_prescription()"><img src="../../../assets/ui_icons/buttons/write_RX_Button.svg" class="writerximgbtn_inpatinfo"/></a> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="Details">
            <div class="row">
              <div class="col-6" style="float: right;">
                <div class="col-6">
                  <mat-label class="matlabel"><b class="">{{hosp_name}}</b></mat-label>
                </div>
                <div class="col-6">
                  <mat-label class="matlabel">{{hosp_addr}}</mat-label>
                </div>
                <div class="col-6">
                  <mat-label class="matlabel">{{hosp_csz}}</mat-label>
                </div>
                <div class="col-6">
                  <mat-label class="matlabel">{{hosp_tel}}</mat-label>
                </div>
              </div>
            </div>
            <div class="row" style="border: 1px solid #c5d7e6">
              <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-xl-4 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">Patient Name 4:</b></mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-8 col-md-6">
                    <mat-label class="matlabel">{{patient_name}}</mat-label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-xl-4 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">Patient Identifier :</b></mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-8 col-md-6">
                    <mat-label class="matlabel">{{patient_identifier}}</mat-label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-xl-4 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">Patient Gender :</b></mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                    <mat-label class="matlabel">{{gender}}</mat-label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-xl-4 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">Patient Age :</b></mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                    <mat-label class="matlabel">{{age}}</mat-label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-xl-4 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">Admission Date :</b></mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                    <mat-label class="matlabel">{{admis_date}}</mat-label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-xl-4 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">Discharge Date :</b></mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                    <input type="date" class="ipcss inpat_width" [(ngModel)]="dis_date" min="{{admDate}}"
                      max="{{currentDate}}" matInput (click)="setDischargeDate()" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                    <mat-label class="matlabel">Consultant(s) :</mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                    <table class="table1">
                      <tr *ngFor="let name of consultarray">
                        <td style="text-align: left;">{{name.name}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="border: 1px solid #c5d7e6;margin-top: 5px;margin-bottom: 5px;"
              *ngIf="surgery_date != undefined">
              <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                <div class="row" *ngIf="reportlistarray.surgery_date_req == 1">
                  <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                    <mat-label class="matlabel">{{reportlistarray.surgery_date_desc}} :</mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                    <mat-label class="matlabel">{{surgery_date}}</mat-label>
                  </div>
                </div>
                <div *ngIf="reportlistarray.surgeon_req == 1" class="row">
                  <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                    <mat-label class="matlabel">{{reportlistarray.surgeon_desc}} :
                    </mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                    <mat-label class="matlabel">
                      <table>
                        <tr *ngFor="let surgeon of surgeon_list;">
                          <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                        </tr>
                      </table>
                    </mat-label>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                <div *ngIf="reportlistarray.anaestetist_req == 1" class="row">
                  <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                    <mat-label class="matlabel">{{reportlistarray.anaestetist_desc}} :</mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                    <mat-label class="matlabel">
                      <table>
                        <tr *ngFor="let anaest of anaestetist;">
                          <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                        </tr>
                      </table>
                    </mat-label>
                  </div>
                </div>
                <div *ngIf="reportlistarray.anaestesia_req == 1" class="row">
                  <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                    <mat-label class="matlabel">{{reportlistarray.anaestesia_desc}} :</mat-label>
                  </div>
                  <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                    <mat-label class="matlabel">{{anaestetype}}</mat-label>
                  </div>
                </div>
              </div>
              <div *ngIf="reportlistarray.surgery_procedure_req == 1" class="row">
                <div class="col-sm-6 col-lg-3 col-xl-2 col-md-6">
                  <mat-label class="matlabel">{{reportlistarray.surgery_procedure_desc}} :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-3 col-xl-8 col-md-6 nopadding">
                  <mat-label class="matlabel">{{surgery_proc}}</mat-label>
                </div>
              </div>
              <div class="row" *ngIf="anaest_proc != undefined && reportlistarray.surgery_procedure_req == 1 ">
                <div class="col-sm-6 col-lg-3 col-xl-2 col-md-6">
                  <mat-label class="matlabel">{{reportlistarray.anaestesia_procedure_desc}} :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-8 col-md-6 nopadding">
                  <mat-label class="matlabel">{{anaest_proc}}</mat-label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="row" *ngIf="complaints != undefined && reportlistarray.chief_complaints_req == 1 ">
                  <div class="col-sm-6 col-lg-2 col-xl-2 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.chief_complaints_desc}} :</b>
                    </mat-label>
                  </div>
                  <div style="margin-top:10px;text-align: justify;" [innerHTML]="complaints"></div>
                </div>
                <div class="row" *ngIf="medical_hist != undefined && reportlistarray.prev_med_hist_req == 1">
                  <div class="col-sm-6 col-lg-2 col-xl-4 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.prev_med_hist_desc}} :</b>
                    </mat-label>
                  </div>
                  <div style="margin-top:10px;text-align: justify;" [innerHTML]="medical_hist"></div>
                </div>
                <div class="row" *ngIf="physical_exam != undefined && reportlistarray.physical_exam_req == 1">
                  <div class="col-sm-6 col-lg-2 col-xl-2 col-md-6">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.physical_exam_desc}} :</b>
                    </mat-label>
                  </div>
                  <div style="margin-top:10px;text-align: justify;" [innerHTML]="physical_exam"></div>
                </div>
              </div>
            </div>
            <!-- Newly added -->
            <div *ngIf="reportlistarray.vitals_req == 1" class="row" style="border: 1px solid #c5d7e6">
              <div *ngIf="reportlistarray.height_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">height :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{height}} {{height_measure}}</mat-label>
                </div>
              </div>
              <div *ngIf="reportlistarray.weight_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">Weight :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{weight}} {{weight_measure}}</mat-label>
                </div>
              </div>
              <div *ngIf="reportlistarray.bp_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">{{reportlistarray.bp_desc}} :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{blood_pressure}}</mat-label>
                </div>
              </div>
              <div *ngIf="reportlistarray.temparature_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">Temp :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{temparature}}</mat-label>
                </div>
              </div>
              <div *ngIf="reportlistarray.pulse_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">{{reportlistarray.pulse_desc}} :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{pulse}}</mat-label>
                </div>
              </div>
              <div *ngIf="reportlistarray.rr_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">{{reportlistarray.rr_desc}} :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{resp_rate}} </mat-label>
                </div>
              </div>
              <div *ngIf="reportlistarray.cvs_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">{{reportlistarray.cvs_desc}} :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{cvs}}</mat-label>
                </div>
              </div>
              <div *ngIf="reportlistarray.rs_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">{{reportlistarray.rs_desc}} :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{rstxt}}</mat-label>
                </div>
              </div>
              <div *ngIf="reportlistarray.cns_req == 1" class="row col-sm-6 col-lg-4 col-xl-4 col-md-6">
                <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                  <mat-label class="matlabel">{{reportlistarray.cns_desc}} :</mat-label>
                </div>
                <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                  <mat-label class="matlabel">{{cns}}</mat-label>
                </div>
              </div>
            </div>
            <!-- <p>{{ reportlistarry.diagnosis_req }}</p>
            <p>{{ reportlistarry.treatment_req }}</p> -->

            <div class="row">
              <div class="col-6" *ngIf="reportlistarray.diagnosis_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.diagnosis_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="dis_diag" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChanged($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="dis_diag"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-6" *ngIf="reportlistarray.treatment_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.treatment_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="Treatmetnt_done" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedTreatment($event)"></quill-editor>
                    <div>
                      <quill-view-html hidden id="viewhtml1" [content]="Treatmetnt_done"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-6" *ngIf="reportlistarray.summary_course_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.summary_course_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="hosp_course" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedHospitalSummary($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="hosp_course"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.investigation_req == 1">
                <div class="row">
                  <div class="col-12 margin_10 ">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.investigation_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="investigation" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedInvestigation($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="investigation"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.ot_notes_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.ot_notes_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="ot_notes" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text"
                      (onContentChanged)="onContentChangedOTNotes($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="ot_notes"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.emerygency_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.emergency_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="emergency_notes" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedEmergency($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="emergency_notes"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.discharge_advice_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.discharge_advice_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="hospital_followup" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text"
                      (onContentChanged)="onContentChangedDischargeAdvice($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="hospital_followup"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">Next visit</mat-label>
                  </div>
                  <div class="col-3 col-md-3 col-xl-3 col-lg-3">
                    <input type="date" class="ipcss_date" id="appt_date" (change)="NextDate(next_data)"
                      [(ngModel)]="next_data" min="{{currentDate}}" #matInput>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row" style="margin-top: 10px;" *ngIf="druglist.length !=0">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">Medicines to follow </mat-label>
                  </div>
                  <div class="col-12">
                    <table class="tbl" style="width: 100%;" *ngIf="druglist.length !=0">
                      <tr style="background: #277196;color:#FFFFFF">
                        <th style="width: 200px;"><b>Medicine</b></th>
                        <th style="width: 100px;"><b>Dosage</b></th>
                        <th style="width: 150px;"><b>Days</b></th>
                      </tr>
                      <tr *ngFor="let product of druglist">
                        <td>{{product.medicine_name}}</td>
                        <td style="text-align: center">{{product.medicine_dosage}}</td>
                        <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>


<!-- end of print section -->
<div [hidden]="true">
  <div #printlogowithname id="printlogowithname">
    <table style="border:'0';width:100%">
      <thead>
        <tr>
          <th style="width:100%;">
            <div>
              <table style="width: 100%; ">
                <tr>
                  <td style="display: flex;justify-content: center;">
                    <div>
                      <img alt="image" src={{hospital_logo}} width="100px" height="100px"
                        style="vertical-align: middle;">
                    </div>
                    <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                      <span><b>{{hosp_name}}</b>,</span><br />
                      <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                      <span>{{hosp_csz}}</span><br />
                      <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
                          height="15px" />{{hosp_tel}}.</span><br>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td width="100%">
            <table style="border:'0';width:100%">
              <tr>
                <td style="width:100%;height: 100px;">&nbsp;</td>
              </tr>
            </table>
      </tfoot>
      <tbody>
        <tr>
          <td width="100%">
            <div class="bill_border">
              <div>
                <!-- Doctor details -->
                <table style="width: 90%; margin: 0 auto;">
                  <tr>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{patient_name}} </span>
                          <br />
                          <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                          <br />
                          <span><strong> UHID : </strong>{{patient_identifier}}</span>
                          <br />
                          <span><strong> Consultant(s) : </strong>
                            <table class="table1" style="margin-left: 50px;">
                              <tr *ngFor="let name of consultarray">
                                <td style="text-align: left;">{{name.name}}</td>
                              </tr>
                            </table>
                          </span>
                        </div>
                      </div>
                    </td>

                    <td style="width: 50%;" valign="top">
                      <div class="row" style="margin-left: 60px;">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                          <span><strong>Date of admission: </strong>{{admis_date}} {{admis_time}}</span><br />
                          <span><strong>Date of discharge : </strong>{{dis_date1}} {{dis_time}}</span><br />
                          <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>

                <div class="block1_grid">
                  <div style="margin-bottom: 5px;" *ngIf="dis_diag_flag">
                    <mat-label class="matlabel" style="font-weight: 600;">Diagnosis :</mat-label>
                    <div style="text-align: justify;" [innerHTML]="dis_diag">
                    </div>
                  </div>

                  <div style="margin-bottom: 5px;" *ngIf="treat_flag">
                    <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done : </mat-label>
                    <div style="text-align: justify;" [innerHTML]="Treatmetnt_done"></div>
                  </div>

                  <div style="margin-bottom: 5px;" *ngIf="comp_flag">
                    <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                    <div style="text-align: justify;" [innerHTML]="complaints"></div>
                  </div>

                  <div style="margin-bottom: 5px;" *ngIf="prev_med_flag">
                    <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History : </mat-label>
                    <div style="text-align: justify;" [innerHTML]="medical_hist">
                    </div>
                  </div>

                  <div style="margin-bottom: 5px;" *ngIf="phy_exam_flag">
                    <mat-label class="matlabel" style="font-weight: 600;">Physical Examination : </mat-label>
                    <div style="text-align: justify;" [innerHTML]="physical_exam">
                    </div>
                  </div>
                </div>

                <div style="margin-bottom: 5px;" *ngIf="inv_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                  <div style="text-align: justify;" [innerHTML]="investigation">
                  </div>
                </div>
                <div class="block1_grid">
                  <div style="margin-bottom: 5px;" *ngIf="otnote_flag">
                    <mat-label class="matlabel" style="font-weight: 600;">OT Notes : </mat-label>
                    <div style="text-align: justify;" [innerHTML]="ot_notes">
                    </div>
                  </div>

                  <div style="margin-bottom: 5px;" *ngIf="sum_course_flag">
                    <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital : </mat-label>
                    <div style="text-align: justify;" [innerHTML]="hosp_course">
                    </div>
                  </div>

                  <div style="margin-bottom: 5px;" *ngIf="dis_adv_flag">
                    <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice : </mat-label>
                    <div style="text-align: justify;" [innerHTML]="hospital_followup">
                    </div>
                  </div>
                </div>
                <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                  <div class="col-12">
                    <table id="tbl" cellpadding="5" cellspacing="0">
                      <tr>
                        <th>Medicine</th>
                        <th>Dosage</th>
                        <th>Days</th>
                      </tr>
                      <tr *ngFor="let medicine of druglist">
                        <td>{{medicine.medicine_name}}</td>
                        <td>{{medicine.medicine_dosage}}</td>
                        <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <br />
                <div style="width: 90%; margin: 0 auto;">
                  <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data1}}</mat-label>
                  <br />
                  <br />
                  <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{hosp_tel}}</mat-label>
                </div>
              </div>
            </div>
            <br />
            <table style="width: 100%; margin-top: 80px;">
              <tr>
                <td style="width: 50%;"></td>
                <td style="width: 50%;">
                  <p style="text-align: center;font-size: 13px"><b>( {{doctor_name}} )</b></p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- print page  start-->
  <div #printbanner id="printbanner">
    <table style="border:'0';width:100%">
      <thead>
        <tr>
          <th style="width:100%;">
            <table style="margin-left:10px;margin-right: 10px;">
              <tr>
                <td>
                  <img alt="image" src={{hptl_pres_logo}} width="750px">
                </td>
              </tr>
            </table>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td width="100%">
            <table style="border:'0';width:100%">
              <tr>
                <td style="width:100%;height: 100px;">&nbsp;</td>
              </tr>
            </table>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr>
          <td width="100%">
            <div class="Details">
              <table style="width: 90%; margin: 0 auto;">
                <tr>
                  <td style="{{reportlistarray.heading_style}}" colspan="2">
                    {{reportlistarray.discharge_heading}}
                  </td>
                </tr>
                <tr>
                  <td style="width: 50%;">
                    <div class="row">
                      <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                        <span><strong>Patient name : </strong>{{patient_name}} </span>
                        <br />
                        <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                        <br />
                        <span><strong> UHID : </strong>{{patient_identifier}}</span>
                        <br />
                        <span><strong> Consultant(s) : </strong>
                          <table class="table1" style="margin-left: 50px;">
                            <tr *ngFor="let name of consultarray">
                              <td style="text-align: left;">{{name.name}}</td>
                            </tr>
                          </table>
                        </span>
                      </div>
                    </div>
                  </td>

                  <td style="width: 50%;" valign="top">
                    <div class="row" style="margin-left: 60px;">
                      <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                        <span><strong>Date of admission: </strong>{{admis_date}} {{admis_time}}</span><br />
                        <span><strong>Date of discharge : </strong>{{dis_date1}} {{dis_time}}</span><br />
                        <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>

              <div class="block1_grid">
                <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag && reportlistarray.diagnosis_req == 1">
                  <mat-label class="matlabel" style="font-weight: 600;">{{ reportlistarray.diagnosis_desc}} :</mat-label>
                  <div style="margin-top:-15px !important;text-align: justify;" [innerHTML]="dis_diag">
                  </div>
                </div>

                <div style="margin-bottom: 10px;" *ngIf="treat_flag && reportlistarray.treatment_req == 1">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.treatment_desc}} :
                  </mat-label>
                  <div style="margin-top:-15px !important;text-align: justify;" [innerHTML]="Treatmetnt_done"></div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="comp_flag && reportlistarray.chief_complaints_req == 1">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.chief_complaints_desc}} :
                  </mat-label>
                  <div style="margin-top:-15px !important;text-align: justify;" [innerHTML]="complaints"></div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="prev_med_flag && reportlistarray.prev_med_hist_req == 1">
                  <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.prev_med_hist_desc}} :
                  </mat-label>
                  <div style="margin-top:-15px !important;text-align: justify;" [innerHTML]="medical_hist">
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag && reportlistarray.physical_exam_req == 1">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.physical_exam_desc}} :
                  </mat-label>
                  <div style="margin-top:-15px !important;text-align: justify;" [innerHTML]="physical_exam">
                  </div>
                </div>
              </div>
              <table *ngIf="reportlistarray.vitals_req == 1" style="border: 1px solid #c5d7e6; width: 90%; border-collapse: collapse; margin-top: 10px; margin-left: auto; margin-right: auto;">
                <tr>
                  <td *ngIf="reportlistarray.height_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">Height:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ height }}</mat-label>
                  </td>
                  <td *ngIf="reportlistarray.weight_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">Weight:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ weight }}</mat-label>
                  </td>
                  <td *ngIf="reportlistarray.bp_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">{{ reportlistarray.bp_desc }}:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ blood_pressure }}</mat-label>
                  </td>
                </tr>
                <tr>
                  <td *ngIf="reportlistarray.temparature_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">Temp:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ temparature }}</mat-label>
                  </td>
                  <td *ngIf="reportlistarray.pulse_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">{{ reportlistarray.pulse_desc }}:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ pulse }}</mat-label>
                  </td>
                  <td *ngIf="reportlistarray.rr_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">{{ reportlistarray.rr_desc }}:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ resp_rate }}</mat-label>
                  </td>
                </tr>
                <tr>
                  <td *ngIf="reportlistarray.cvs_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">{{ reportlistarray.cvs_desc }}:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ cvs }}</mat-label>
                  </td>
                  <td *ngIf="reportlistarray.rs_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">{{ reportlistarray.rs_desc }}:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ rstxt }}</mat-label>
                  </td>
                  <td *ngIf="reportlistarray.cns_req == 1" style="padding: 8px; border: 1px solid #c5d7e6;border: none;">
                    <mat-label class="matlabel">{{ reportlistarray.cns_desc }}:</mat-label>
                    <mat-label class="matlabel" style="margin-left: 8px;">{{ cns }}</mat-label>
                  </td>
                </tr>
              </table>
              <div class="block1_grid">
                <div style="margin-bottom: 10px;" *ngIf="reportlistarray.investigation_req == 1">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.investigation_desc}} :
                  </mat-label>
                  <div style="margin-top:-15px !important;text-align: justify;" [innerHTML]="investigation">
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                  <div style="margin-bottom: 5px;">
                    <span *ngIf="reportlistarray.surgery_date_req == 1"><strong>{{reportlistarray.surgery_date_desc}} :
                      </strong>{{surgery_date}} </span><br />
                    <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                    <span *ngIf="reportlistarray.surgeon_req == 1"><strong>{{reportlistarray.surgeon_desc}} : </strong>
                      <table>
                        <tr *ngFor="let surgeon of surgeon_list;">
                          <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                        </tr>
                      </table>
                    </span><br />
                    <span *ngIf="reportlistarray.anaestetist_req == 1"><strong>{{reportlistarray.anaestetist_desc}}:
                      </strong>
                      <table>
                        <tr *ngFor="let anaest of anaestetist;">
                          <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                        </tr>
                      </table>
                    </span><br />
                    <span *ngIf="reportlistarray.anaestesia_req == 1"><strong>{{reportlistarray.anaestesia_desc}} :
                      </strong>{{anaestetype}} </span><br />
                    <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                    <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="reportlistarray.ot_notes_req == 1 && ot_notes != null">
                  <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.ot_notes_desc}} : </mat-label>
                  <div style="margin-top:-15px;text-align: justify;" [innerHTML]="ot_notes">
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="reportlistarray.summary_course_req == 1">
                  <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.summary_course_desc}} : </mat-label>
                  <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="reportlistarray.discharge_advice_req == 1">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.discharge_advice_desc}} :
                  </mat-label>
                  <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hospital_followup">
                  </div>
                </div>
              </div>

              <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                <div class="col-12">
                  <table id="tbl" cellpadding="5" cellspacing="0">
                    <tr>
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Days</th>
                    </tr>
                    <tr *ngFor="let medicine of druglist">
                      <td>{{medicine.medicine_name}}</td>
                      <td>{{medicine.medicine_dosage}}</td>
                      <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
              <div style="width: 90%; margin: 0 auto;">
                <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data1}}</mat-label>
                <br />
                <br />
                <mat-label class="matlabel"> <b>In case of emergency contact:</b> {{hosp_tel}}</mat-label>
              </div>
            </div>
            <br />
            <table style="width: 100%; margin-top: 80px;">
              <tr>
                <td style="width: 50%;"></td>
                <td style="width: 50%;">
                  <p style="text-align: center;font-size: 13px"><b>({{doctor_name}} )</b></p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div #printnoheader id="printnoheader">
    <div>
      <table style="border:'0';width:100%">
        <thead>
          <tr>
            <th style="width:100%;height: 100px;">

            </th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td width="100%">
              <table style="border:'0';width:100%">
                <tr>
                  <td style="width:100%;height: 100px;">&nbsp;</td>
                </tr>
              </table>
        </tfoot>
        <tbody>
          <tr>
            <td width="100%">
              <table style="margin-left:10px;margin-top:-100px;margin-right: 10px;">
                <tr>
                  <td>
                    <div style="height: 100px;">&nbsp;</div>
                  </td>
                </tr>
              </table>
              <br /><br />
              <table style="width: 90%; margin: 0 auto;">
                <tr>
                  <td style="width: 50%;">
                    <div class="row">
                      <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                        <span><strong>Patient name 3: </strong>{{patient_name}} </span>
                        <br />
                        <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                        <br />
                        <span><strong> UHID : </strong>{{patient_identifier}}</span>
                        <br />
                        <span><strong> Consultant(s) : </strong>
                          <table class="table1" style="margin-left: 50px;">
                            <tr *ngFor="let name of consultarray">
                              <td style="text-align: left;">{{name.name}}</td>
                            </tr>
                          </table>
                        </span>
                      </div>
                    </div>
                  </td>

                  <td style="width: 50%;" valign="top">
                    <div class="row" style="margin-left: 60px;">
                      <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                        <span><strong>Date of admission: </strong>{{admis_date}} {{admis_time}}</span><br />
                        <span><strong>Date of discharge : </strong>{{dis_date1}} {{dis_time}}</span><br />
                        <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>

              <div class="block1_grid">
                <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis :</mat-label>
                  <div style="margin-top:1px;text-align: justify;" [innerHTML]="dis_diag">
                  </div>
                </div>

                <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done : </mat-label>
                  <div style="margin-top:1px;text-align: justify;" [innerHTML]="Treatmetnt_done"></div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                  <div style="text-align: justify;" [innerHTML]="complaints"></div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History : </mat-label>
                  <div style="text-align: justify;" [innerHTML]="medical_hist">
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">Physical Examination : </mat-label>
                  <div style="text-align: justify;" [innerHTML]="physical_exam">
                  </div>
                </div>
              </div>

              <div class="block1_grid">
                <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                  <div style="margin-top:1px;text-align: justify;" [innerHTML]="investigation">
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                  <mat-label class="matlabel" style="font-weight: 600;">OT Notes : </mat-label>
                  <div style="margin-bottom: 5px;">
                    <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                    <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                    <span><strong>Surgeon : </strong>
                      <table>
                        <tr *ngFor="let surgeon of surgeon_list;">
                          <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                        </tr>
                      </table>
                    </span><br />
                    <span><strong>Anaestetist :2 </strong>
                      <table>
                        <tr *ngFor="let anaest of anaestetist;">
                          <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                        </tr>
                      </table>
                    </span><br />
                    <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                    <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                    <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                  <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital : </mat-label>
                  <div style="margin-top:5px;text-align: justify;" [innerHTML]="hosp_course">
                  </div>
                </div>
                <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice : </mat-label>
                  <div style="margin-top:5px;text-align: justify;" [innerHTML]="hospital_followup">
                  </div>
                </div>
              </div>

              <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                <div class="col-12">
                  <table id="tbl" cellpadding="5" cellspacing="0">
                    <tr>
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Days</th>
                    </tr>
                    <tr *ngFor="let medicine of druglist">
                      <td>{{medicine.medicine_name}}</td>
                      <td>{{medicine.medicine_dosage}}</td>
                      <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
              <div style="width: 90%; margin: 0 auto;">
                <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data1}}</mat-label>
                <br />
                <br />
                <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{hosp_tel}}</mat-label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <table style="width: 100%; margin-top: 80px;">
      <tr>
        <td style="width: 50%;"></td>
        <td style="width: 50%;">
          <p style="text-align: center;font-size: 13px"><b>( {{doctor_name}} )</b></p>
        </td>
      </tr>
    </table>
  </div>
</div>