<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'doctor' || loginType == 'consultant'">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('home');"></a>
                    </h1>
                </div>
                <div class="doc_menu_list">
                    <div class="doc_menu menu_active" id="home" (click)="menuNav('home');" [hidden]='docDashboardFlag'>
                        <div class="menu_icon">
                            <i class="icon-menu">
                                <img src="../../assets/global/images/dashboard.svg" />
                            </i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied != undefined">
                            <span>Home</span>
                        </div>
                    </div>
                    <div class="doc_menu" id="patient" (click)="menuNav('patient');" [hidden]='docInpatientFlag'>
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>In-Patient</span>
                            <span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                        <ul *ngIf="docInpatSubmenuFlag" class="nav-children">
                            <li (click)="menuNav('in_patient');" class="mb-1">
                                <a id="nav-a">
                                    <i class="fa fa-angle-right"></i> Patients
                                </a>
                            </li>
                            <li (click)="setAppoinmentHelper('ipbilling');menuNav('ipbilling');" class="mb-1">
                                <a>
                                    <i class="fa fa-angle-right"></i> Billing
                                </a>
                            </li>
                            <li (click)="menuNav('patient_dashboard');" class="mb-1">
                                <a>
                                    <i class="fa fa-angle-right"></i> Dashboard
                                </a>
                            </li>
                            <li [hidden]="consolidatedFlag" (click)="menuNav('consolidatedBills');" class="mb-1">
                                <a>
                                    <i class="fa fa-angle-right"></i> Consolidated Bills
                                </a>
                            </li>
                        </ul>
                        <!-- </div> -->
                    </div>
                    <div class="doc_menu" id="appointment_list"
                        (click)="setAppoinmentHelper('appinment');menuNav('appointment_list');"
                        [hidden]="docAppointmentListFlag">
                        <div class="menu_icon"><i class="icon-menu">
                                <img src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Appointments</span>
                            <span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                    </div>
                    <div class="doc_menu" id="walkin" (click)="menuNav('walkin');" [hidden]="docWalkinFlag">
                        <div class="menu_icon"> <i class="icon-menu">
                                <img src="../../assets/global/images/doctor.svg" /></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Walk-in</span><span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                    </div>
                    <div class="doc_menu" id="prescriptionlist" (click)="setDoctor('prescriptionlist');menuNav('prescriptionlist');" [hidden]="viewPrescritionFlag">
                        <div class="menu_icon"> <i class="icon-menu">
                                <img src="../../assets/global/images/doctor.svg" /></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Prescriptions</span><span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                    </div>

                    <!-- <div [hidden]="viewPrescritionFlag" class="menu" id="prescriptionlist"
                        (click)="setDoctor('prescriptionlist');menuNav('prescriptionlist');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);"
                                class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Prescriptions</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div> -->
                    <div class="doc_menu" id="permission" (click)="menuNav('permission');"
                        [hidden]="docNursePermssionFlag">
                        <div class="menu_icon"><i class="icon-menu">
                                <img src="../../assets/global/images/permission.svg" /></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Permissions</span><span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                    </div>
                    <div class="doc_menu" id="billing" (click)="setAppoinmentHelper('billing');menuNav('billing');"
                        [hidden]="docBillingFlag">
                        <div class="menu_icon"> <i class="icon-menu">
                                <img src="../../assets/global/images/billing.svg" /></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Billing</span><span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                    </div>
                    <div class="doc_menu" id="secondopinion" (click)="menuNav('secondopinion');"
                        [hidden]="docSecondopinionFlag">
                        <div class="menu_icon"> <i class="icon-menu">
                                <img src="../../assets/global/images/secondopinion.svg" /></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Second opinion</span><span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                    </div>
                    <div class="doc_menu" id="homecare" (click)="setAppoinmentHelper('homecare');menuNav('homecare');"
                        [hidden]="docHomecareFlag">
                        <div class="menu_icon"> <i class="icon-menu">
                                <img src="../../assets/global/images/home.svg" /></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Homecare</span><span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                    </div>
                    <div class="doc_menu" id="schedule" (click)="menuNav('schedule');">
                        <div class="menu_icon"> <i class="icon-menu">
                                <img src="../../assets/global/images/Schedule.svg" /></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Schedule</span><span style="float: right;padding-right: 7px;">
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                        <ul *ngIf="docScheduleSubmenuFlag" class="nav-children">
                            <li (click)="menuNav('calender');" class="mb-1"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> My
                                    Calender</a></li>
                            <li (click)="setAppoinmentHelper('doctor_availability');menuNav('doctor_availability');"
                                class="mb-1">
                                <a><i class="fa fa-angle-right"></i> Doctor Availability</a>
                            </li>
                            <li (click)="menuNav('manage_schedule');" class="mb-1"><a><i class="fa fa-angle-right"></i>
                                    View schedule</a></li>
                        </ul>
                    </div>
                    <div class="doc_menu" id="notification" (click)="menuNav('notification');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/notification.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Notification</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="help" (click)="menuNav('help');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/help.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="reference_video" (click)="menuNav('reference_video');"
                        [hidden]="docReferenceVideoFlag">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Reference video</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="reportsMenu" (click)="menuNav('reportsMenu');" [hidden]="docMisReport">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/mis_report_icon.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>MIS reports</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="docReportsFlag" class="nav-children">
                            <li (click)="setAppoinmentHelper('salseReport');menuNav('salseReport');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Sales reports</a></li>
                            <li (click)="setAppoinmentHelper('stockReport');menuNav('stockReport');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Stock reports</a></li>
                            <li (click)="setAppoinmentHelper('receivablesReport');menuNav('receivablesReport');"
                                class="mb-1"><a><i class="fa fa-angle-right"></i> Receivable reports</a></li>

                        </ul>
                    </div>
                </div>
            </section>
        </div>

        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}"
                                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        <div class="hospital_name"><span
                                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span>
                        </div>
                        <div class="headerCover">
                            <div class="bell" (click)="menuNav('notification');"><i class="far fa-bell"></i><span
                                    class="notificatio_count" *ngIf="notificationFlag">{{notificationCount}}</span>
                            </div>
                            <!-- <div class="share"><i class="far fa-paper-plane"></i></div>   -->
                            <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                    src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
                            <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">Dr.
                                {{firstName}}&nbsp;{{lastName}}</div>
                        </div>

                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>

                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('profile_update');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                Profile</span> </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                Password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('terms_conditions');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Privacy & Data
                                                protection </span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Log Out</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg" id="card_id" #scrol_traget>
                        <!-- <h1>{{displayPage}}</h1> -->
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchCase="'dashboard'">
                                <app-doctorhome></app-doctorhome>
                            </div>

                            <div *ngSwitchCase="'in_patient'">
                                <app-inpat-patient-list></app-inpat-patient-list>
                            </div>
                            <div *ngSwitchCase="'inPatientViewInfo'">
                                <app-inpat-patient-info></app-inpat-patient-info>
                            </div>
                            <div *ngSwitchCase="'appointment_list'">
                                <app-doctor-app-list></app-doctor-app-list>
                            </div>
                            <div *ngSwitchCase="'walkin'">
                                <app-noappointment></app-noappointment>
                            </div>
                            <div *ngSwitchCase="'billing'">
                                <app-doctorbilllistpage></app-doctorbilllistpage>
                            </div>
                            <div *ngSwitchCase="'billcreate'">
                                <app-bill-create></app-bill-create>
                            </div>
                            <div *ngSwitchCase="'billdetail'">
                                <app-bill-detail-view></app-bill-detail-view>
                            </div>
                            <div *ngSwitchCase="'billedit'">
                                <app-bill-edit></app-bill-edit>
                            </div>
                            <div *ngSwitchCase="'inPatientDS'">
                                <app-inpat-discharge-summary></app-inpat-discharge-summary>
                            </div>
                            <div *ngSwitchCase="'patient_dashboard'">
                                <app-inpat-dashboard></app-inpat-dashboard>
                            </div>
                            <div *ngSwitchCase="'viewIpatient'">
                                <app-inpat-patient-info></app-inpat-patient-info>
                            </div>
                            <!-- <div *ngSwitchCase="'patient_ward'">
                                <app-inpatient-ward></app-inpatient-ward>
                            </div>
                            <div *ngSwitchCase="'patient_patwise'">
                                <app-inpatient-patientwise></app-inpatient-patientwise>
                            </div> -->
                            <div *ngSwitchCase="'inpatient_Discharge_view'">
                                <app-inpat-discharge-view></app-inpat-discharge-view>
                            </div>
                            <div *ngSwitchCase="'inpatient_guno_Discharge_view'">
                                <app-gynac-dis-summary></app-gynac-dis-summary>
                            </div>
                            <div *ngSwitchCase="'notification'">
                                <app-doctor-notification></app-doctor-notification>
                            </div>
                            <div *ngSwitchCase="'help'">
                                <app-help></app-help>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-casesheet></app-casesheet>
                            </div>
                            <div *ngSwitchCase="'calender'">
                                <app-doctor-schedule-list-page></app-doctor-schedule-list-page>
                            </div>
                            <div *ngSwitchCase="'doctor_availability'">
                                <app-doctor-available-nonavailable-scedule-page>
                                </app-doctor-available-nonavailable-scedule-page>
                            </div>
                            <div *ngSwitchCase="'manage_schedule'">
                                <app-doctor-manage-schedule-main></app-doctor-manage-schedule-main>
                            </div>
                            <div *ngSwitchCase="'homecare'">
                                <app-homecare></app-homecare>
                            </div>
                            <div *ngSwitchCase="'secondopinion'">
                                <app-second-opinion></app-second-opinion>
                            </div>
                            <div *ngSwitchCase="'secondopinion_casesheet'">
                                <app-second-opinion-casesheet></app-second-opinion-casesheet>
                            </div>
                            <div *ngSwitchCase="'profile_update'">
                                <app-doctor-profile-update></app-doctor-profile-update>
                            </div>
                            <div *ngSwitchCase="'permission'">
                                <app-permission></app-permission>
                            </div>
                            <div *ngSwitchCase="'medprescription'">
                                <app-med-diag-prescription></app-med-diag-prescription>
                            </div>
                            <div *ngSwitchCase="'ModalPage'">
                                <app-dropdown-modelpage></app-dropdown-modelpage>
                            </div>
                            <div *ngSwitchCase="'casesheet_list'">
                                <app-casesheet-list></app-casesheet-list>
                            </div>
                            <div *ngSwitchCase="'cardio_casesheet_view'">
                                <app-cardio-casesheet-view></app-cardio-casesheet-view>
                            </div>
                            <div *ngSwitchCase="'appointment_view'">
                                <app-appoinmentview></app-appoinmentview>
                            </div>

                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'salseReport'">
                                <app-doc-sales-report></app-doc-sales-report>
                            </div>
                            <div *ngSwitchCase="'receivablesReport'">
                                <app-doc-receivables-report></app-doc-receivables-report>
                            </div>
                            <div *ngSwitchCase="'stockReport'">
                                <app-doc-stock-report></app-doc-stock-report>
                            </div>
                            <div *ngSwitchCase="'ipbillcreate'">
                                <app-ipatient-bill-create></app-ipatient-bill-create>
                            </div>
                            <div *ngSwitchCase="'ipbilling'">
                                <app-doctorbilllistpage></app-doctorbilllistpage>
                            </div>
                            <div *ngSwitchCase="'ipcard'">
                                <app-inpatien-card></app-inpatien-card>
                            </div>
                            <div *ngSwitchCase="'consolidatedBills'">
                                <app-consolidated-bills></app-consolidated-bills>
                            </div>
                            <div *ngSwitchCase="'docappcreate'">
                                <app-doc-app-create></app-doc-app-create>
                            </div>
                            <div *ngSwitchCase="'Sales_report_pharma'">
                                <app-pharma-sales-report-doc></app-pharma-sales-report-doc>
                            </div>
                            <div *ngSwitchCase="'diag_bill_reports'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'hosp_bill_reports'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'docpresnocase'">
                                <app-med-diag-pres-nocase></app-med-diag-pres-nocase>
                            </div>
                            <div *ngSwitchCase="'prescriptionlist'">
                                <app-recept-prescription-list></app-recept-prescription-list>
                            </div>
                            <div *ngSwitchCase="'receptviewprescription'">
                                <app-receptviewprescription></app-receptviewprescription>
                            </div>
                            <div *ngSwitchCase="'editpres'">
                                <app-edit-prescription></app-edit-prescription>
                            </div>
                            <div *ngSwitchCase="'viewpres'">
                                <app-med-pres-view></app-med-pres-view>
                            </div>
                            <div *ngSwitchDefault="'dashboard'">
                                <app-doctorhome></app-doctorhome>
                            </div>
                            
                        </div>

                        <!-- <div id="content_div" (sendData)="menuNav($event)"></div> -->
                    </div>
                </div>
            </section>

            <footer id="footer_section">
                <div class="footer_left">Copyright © 2023 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>

    </div>

</div>

<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'front-desk'">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('manageappointments');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="scrollbar">
                        <div class="scrollbar-inner">
                            <div [hidden]="manageAppointmentFlag" class="menu menu_active" id="manageappointments"
                                (click)="setDoctor('manageappointments');menuNav('manageappointments');">
                                <div class="menu_icon"> <i class="icon-menu">
                                        <img src="../../assets/global/images/dashboard.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>Manage appointments</span>
                                </div>
                            </div>
                            <div [hidden]="viewPrescritionFlag" class="menu" id="prescriptionlist"
                                (click)="setDoctor('prescriptionlist');menuNav('prescriptionlist');">
                                <div class="menu_icon"> <i style="transform: rotate(-55deg);"
                                        class="fa fa-puzzle-piece"></i>
                                </div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>Prescription</span><span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span>
                                </div>
                            </div>
                            <div class="menu" id="billing" (click)="setAppoinmentHelper('billing');menuNav('billing');">
                                <div class="menu_icon"><i class="icon-menu">
                                    <img src="../../assets/global/images/appointment.svg" /></i>
                                </div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>Billing</span><span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span>
                                </div>
                                <ul *ngIf="billingFlag" class="nav-children">
                                    <li (click)="menuNav('frontdeskbilling');" class="mb-1">
                                        <a id="nav-a"><i class="fa fa-angle-right"></i> Bill list</a></li>
                                    <li [hidden]="advanceFlag" (click)="menuNav('advances');" class="mb-1">
                                        <a><i class="fa fa-angle-right"></i> Advances</a></li>
                                    <li [hidden]="billingChargesFlag" (click)="menuNav('charges');" class="mb-1">
                                        <a><i class="fa fa-angle-right"></i> Charge types</a></li>
                                    <li [hidden]="refund" (click)="menuNav('refund'); " class="mb-1">
                                        <a><i class="fa fa-angle-right"></i> Refunds</a></li>
                                    <li [hidden]="consolidatedBillingFlag" (click)="menuNav('frontConsolidatedBills');" class="mb-1">
                                        <a><i class="fa fa-angle-right"></i> Consolidated bill</a>
                                    </li>
                                    <li [hidden]="partialPaymentFlag" (click)="menuNav('partialPayments');setAppFlow('front-desk')" class="mb-1">
                                        <a><i class="fa fa-angle-right"></i> Partial Payments</a></li>
                                    <li [hidden]="insuranceBillFlag" (click)="menuNav('insuranceBill');" class="mb-1">
                                        <a><i class="fa fa-angle-right"></i> Insurance bill</a>
                                    </li>
                                </ul>
                            </div>
                            <div [hidden]="patientListFlag" class="menu" id="patient_list"
                                (click)="menuNav('patient_list');">
                                <div class="menu_icon"> <i class="icon-menu">
                                    <img src="../../assets/global/images/doctor.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>Patient list</span><span style="float: right;padding-right: 7px;"> 
                                        <i class="fa fa-angle-right"></i></span>
                                </div>
                            </div>
                            <div [hidden]="permissionsFlag" class="menu" id="permission"
                                (click)="menuNav('permission')">
                                <div class="menu_icon"><i class="icon-menu">
                                    <img src="../../assets/global/images/permission.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>Permission</span><span style="float: right;padding-right: 7px;"> 
                                        <i class="fa fa-angle-right"></i></span>
                                </div>
                                <ul *ngIf="permissionSubmenuFlag" class="nav-children">
                                    <li (click)="menuNav('nurse_ward');"><a id="nav-a">
                                        <i class="fa fa-angle-right"></i> Nurse by ward</a></li>
                                    <li (click)="menuNav('nurse_patient');"><a>
                                        <i class="fa fa-angle-right"></i> Nurse by patient</a></li>
                                    <li (click)="menuNav('nurse_doctor');"><a>
                                        <i class="fa fa-angle-right"></i> Nurse by doctor</a></li>
                                </ul>
                            </div>
                            <div [hidden]="inpatientMenuFlag" class="menu" id="inpatient"
                                (click)="setAppoinmentHelper('inpatient');menuNav('inpatient');">
                                <div class="menu_icon"><i class="icon-menu"><img
                                            src="../../assets/global/images/Inpatients_icon.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span> Inpatient</span><span style="float: right;padding-right: 7px;">
                                        <i class="fa fa-angle-right"></i></span>
                                </div>
                                <ul *ngIf="inpatientFlag" class="nav-children">
                                    <li (click)="menuNav('Patients');"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                            Patients</a></li>
                                    <li (click)="menuNav('dischargeList');"><a id="nav-a"><i
                                                class="fa fa-angle-right"></i> Discharge list</a></li>
                                    <!-- <li (click)="menuNav('OTBooking');" class="mb-1"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> OT Booking</a></li> -->
                                </ul>
                            </div>
                            <div [hidden]="consultantFlag" class="menu" id="consultant"
                                (click)="menuNav('consultant');">
                                <div class="menu_icon"> <i class="icon-menu"><img
                                            src="../../assets/global/images/doctor.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>
                                        Consultant</span><span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span></div>
                            </div>
                            <div [hidden]="wardMasterFlag" class="menu" id="wardmaster"
                                (click)="menuNav('wardmaster');">
                                <div class="menu_icon"> <i class="icon-menu"><img
                                            src="../../assets/global/images/doctor.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>Ward master</span><span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span>
                                </div>
                            </div>
                            <!-- <div [hidden]="wardMasterFlag" class="menu" id="wardmaster" (click)="menuNav('OTmaster');">
                                <div class="menu_icon"> <i class="icon-menu"><img
                                            src="../../assets/global/images/doctor.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>OT Master</span><span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span>
                                </div>
                            </div> -->
                            <div [hidden]="leadMgmtFlag" class="menu" id="leadmgmt" (click)="menuNav('leadmgmt');">
                                <div class="menu_icon"> <i class="icon-menu"><img
                                            src="../../assets/global/images/doctor.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>Lead management</span>
                                    <span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span>
                                </div>
                            </div>
                            <div [hidden]="doctor_referral" class="menu" id="docref" (click)="menuNav('docref');">
                                <div class="menu_icon"> <i class="icon-menu"><img
                                            src="../../assets/global/images/reference_video.png" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>
                                        Doctor referral</span><span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span></div>
                            </div>
                            <div [hidden]="reference_video" class="menu" id="reference_video"
                                (click)="menuNav('reference_video');">
                                <div class="menu_icon"> <i class="icon-menu"><img
                                            src="../../assets/global/images/reference_video.png" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>
                                        Reference video</span><span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span></div>
                            </div>
                            <div [hidden]="reportsFlag" class="menu" id="reports" (click)="menuNav('reports');">
                                <div class="menu_icon"><i class="icon-menu"><img
                                            src="../../assets/global/images/Inpatients_icon.svg" /></i></div>
                                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                                    <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                                            class="fa fa-angle-right"></i></span>
                                </div>
                                <ul *ngIf="reportsSubmenuFlag" class="nav-children">
                                    <li [hidden]="hospBillsReportFlag"
                                        (click)="menuNav('hosp_bill_reports');setReport('HBR');" class="mb-1">
                                        <a id="nav-a"><i class="fa fa-angle-right"></i> Hospital bills reports</a>
                                    </li>
                                    <li [hidden]="diagBillsReportFlag"
                                        (click)="menuNav('diag_bill_reports');;setReport('DBR');" class="mb-1"><a
                                            id="nav-a">
                                            <i class="fa fa-angle-right"></i> Diagnosis bills reports</a></li>
                                    <li [hidden]="inpatReportFlag" (click)="menuNav('inpat_reports');;setReport('IPR');"
                                        class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Inpatient reports
                                        </a>
                                    </li>
                                    <!-- newly added report begins -->
                                    <li [hidden]="salesreportdoctorReportFlag"
                                        (click)="menuNav('sales_report_doctor');;setReport('SRD');" class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Sales report - doctor wise
                                        </a>
                                    </li>

                                    <li [hidden]="Bill_refundflag"
                                        (click)="menuNav('bill_refund');;setReport('Bill_refund');" class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Bill refund
                                        </a>
                                    </li>

                                    <li [hidden]="billDueFlag" (click)="menuNav('bill_due');;setReport('Bill_due');"
                                        class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Bill due
                                        </a>
                                    </li>

                                    <li [hidden]="bedOccupancyFlag"
                                        (click)="menuNav('bed_occupancy');;setReport('bed_occupancy');" class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Bed occupancy ratio
                                        </a>
                                    </li>

                                    <li [hidden]="abcAnalysisFlag"
                                        (click)="menuNav('abc_analysis');;setReport('abc_analysis');" class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> ABC analysis
                                        </a>
                                    </li>

                                    <li [hidden]="purchaseOrderFlag"
                                        (click)="menuNav('purchase_order');;setReport('sum_of_purchaseOrder');"
                                        class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Sum of purchase order report
                                        </a>
                                    </li>

                                    <li [hidden]="productFlag" (click)="menuNav('product');;setReport('product');"
                                        class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Product
                                        </a>
                                    </li>

                                    <li [hidden]="productReceivablesFlag"
                                        (click)="menuNav('product_receivables');;setReport('product_receivables');"
                                        class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Product receivables
                                        </a>
                                    </li>

                                    <li [hidden]="labtestFlag"
                                        (click)="menuNav('lab_test_wise');;setReport('lab_test_report');" class="mb-1">
                                        <a id="nav-a">
                                            <i class="fa fa-angle-right"></i> Lab test wise report
                                        </a>
                                    </li>

                                    <li [hidden]="notvistedpatreportflag"
                                        (click)="menuNav('notvisited_report');setReport('NVR');" class="mb-1">
                                        <a id="nav-a"><i class="fa fa-angle-right"></i> Not visited patient</a>
                                    </li>
                                    <li [hidden]="consolidatedreportflag"
                                        (click)="menuNav('consolidat_report');setReport('CR');" class="mb-1">
                                        <a id="nav-a"><i class="fa fa-angle-right"></i> Consolidate report</a>
                                    </li>
                                    <!-- newly added report end -->
                                </ul>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="menu" id="listPackage" (click)="menuNav('listPackage');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg"/></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Package</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}"
                                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        <div class="hospital_name"><span
                                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span>
                        </div>
                        <div class="headerCover">
                            <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <img src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
                            </div>
                            <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                {{firstName}}&nbsp;{{lastName}}</div>
                        </div>

                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('profile_update');">
                                            <i class="far fa-user"></i> <span class="header_model_menu">My
                                                profile</span>
                                        </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Logout</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'manageappointments'">
                                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
                            </div>
                            <div *ngSwitchCase="'home'">
                                <app-frontdesk-home></app-frontdesk-home>
                            </div>
                            <div *ngSwitchCase="'manageappointments'">
                                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
                            </div>
                            <div *ngSwitchCase="'appcreate'">
                                <app-recept-app-create-page></app-recept-app-create-page>
                            </div>
                            <div *ngSwitchCase="'receptionistdoctordetailview'">
                                <app-receptionistdoctordetailview></app-receptionistdoctordetailview>
                            </div>
                            <div *ngSwitchCase="'registration'">
                                <app-front-desk-registration></app-front-desk-registration>
                            </div>
                            <div *ngSwitchCase="'receptviewprescription'">
                                <app-receptviewprescription></app-receptviewprescription>
                            </div>
                            <div *ngSwitchCase="'prescriptionlist'">
                                <app-recept-prescription-list></app-recept-prescription-list>
                            </div>
                            <div *ngSwitchCase="'frontdeskbilling'">
                                <app-recept-bill-list></app-recept-bill-list>
                            </div>
                            <div *ngSwitchCase="'receptbillcreate'">
                                <app-recept-bill-create></app-recept-bill-create>
                            </div>
                            <div *ngSwitchCase="'receptbilledit'">
                                <app-recept-bill-edit></app-recept-bill-edit>
                            </div>
                            <div *ngSwitchCase="'receptbilldetail'">
                                <app-recept-bill-detailview></app-recept-bill-detailview>
                            </div>
                            <div *ngSwitchCase="'patient_list'">
                                <app-recept-patient-list></app-recept-patient-list>
                            </div>
                            <div *ngSwitchCase="'nurse_ward'">
                                <app-nurse-ward></app-nurse-ward>
                            </div>
                            <div *ngSwitchCase="'nurse_patient'">
                                <app-nurse-patient></app-nurse-patient>
                            </div>
                            <div *ngSwitchCase="'nurse_doctor'">
                                <app-nurse-doctor></app-nurse-doctor>
                            </div>
                            <div *ngSwitchCase="'advances'">
                                <app-bill-advances></app-bill-advances>
                            </div>
                            <!-- <div *ngSwitchCase="'bills'">
                                <app-bill-list></app-bill-list>
                            </div> -->
                            <div *ngSwitchCase="'billingDetailsView'">
                                <app-billng-detail-view></app-billng-detail-view>
                            </div>
                            <div *ngSwitchCase="'billingDiagDetailsView'">
                                <app-billing-diag-view></app-billing-diag-view>
                            </div>
                            <div *ngSwitchCase="'charges'">
                                <app-billing-charges></app-billing-charges>
                            </div>
                            <div *ngSwitchCase="'refund'">
                                <app-bill-refund></app-bill-refund>
                            </div>
                            <div *ngSwitchCase="'createRefund'">
                                <app-bill-refund-create></app-bill-refund-create>
                            </div>
                            <div *ngSwitchCase="'dischargeList'">
                                <app-inpatient-diacharge-list></app-inpatient-diacharge-list>
                            </div>
                            <div *ngSwitchCase="'Patients'">
                                <app-fd-inpatient></app-fd-inpatient>
                            </div>
                            <div *ngSwitchCase="'consultant'">
                                <app-consultant></app-consultant>
                            </div>
                            <div *ngSwitchCase="'diaBillCreate'">
                                <app-diagbillcreate></app-diagbillcreate>
                            </div>
                            <div *ngSwitchCase="'diaAppDetail'">
                                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
                            </div>
                            <div *ngSwitchCase="'diaAppCreate'">
                                <app-diag-app-create></app-diag-app-create>
                            </div>
                            <div *ngSwitchCase="'diaBillingDetailsView'">
                                <app-diagbilldetailview></app-diagbilldetailview>
                            </div>
                            <div *ngSwitchCase="'wardmaster'">
                                <app-ward-master></app-ward-master>
                            </div>
                            <div *ngSwitchCase="'OTmaster'">
                                <app-operation-theatre></app-operation-theatre>
                            </div>
                            <div *ngSwitchCase="'OTBooking'">
                                <app-ot-booking></app-ot-booking>
                            </div>
                            <div *ngSwitchCase="'viewIpatient'">
                                <app-inpat-patient-info></app-inpat-patient-info>
                            </div>
                            <div *ngSwitchCase="'ipatientBillCreate'">
                                <app-ipatient-bill-create></app-ipatient-bill-create>
                            </div>
                            <div *ngSwitchCase="'frontConsolidatedBills'">
                                <app-consolidated-bills></app-consolidated-bills>
                            </div>
                            <div *ngSwitchCase="'dietappointments'">
                                <app-diet-appointments></app-diet-appointments>
                            </div>
                            <div *ngSwitchCase="'dietappointmentsdetailed'">
                                <app-diet-appointments-detailed-view></app-diet-appointments-detailed-view>
                            </div>
                            <div *ngSwitchCase="'dietappointmentscreate'">
                                <app-diet-appointments-creation></app-diet-appointments-creation>
                            </div>
                            <div *ngSwitchCase="'hosp_bill_reports'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'diag_bill_reports'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'inpat_reports'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'createPackage'">
                                <app-create-package></app-create-package>
                            </div>

                            <div *ngSwitchCase="'listPackage'">
                                <app-list-package></app-list-package>
                            </div>
                            <div *ngSwitchCase="'inpdischargeview'">
                                <app-inp-discharge-view></app-inp-discharge-view>
                            </div>
                            <div *ngSwitchCase="'inPatientDS'">
                                <app-inpat-discharge-summary></app-inpat-discharge-summary>
                            </div>
                            <div *ngSwitchCase="'docref'">
                                <app-doc-ref-list></app-doc-ref-list>
                            </div>
                            <div *ngSwitchCase="'docrefcreate'">
                                <app-doc-ref-create></app-doc-ref-create>
                            </div>
                            <div *ngSwitchCase="'medprescription'">
                                <app-med-diag-prescription></app-med-diag-prescription>
                            </div>
                            <div *ngSwitchCase="'leadmgmt'">
                                <app-lead-mgmt></app-lead-mgmt>
                            </div>
                            <div *ngSwitchCase="'partialPayments'">
                                <app-partial-payment></app-partial-payment>
                            </div>
                            <div *ngSwitchCase="'insuranceBill'">
                                <app-insurancebill></app-insurancebill>
                            </div>
                            <div *ngSwitchCase="'manageclient'">
                                <app-patient-details></app-patient-details>
                            </div>
                            <div *ngSwitchCase="'sales_report_doctor'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'bill_refund'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'bill_due'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'bed_occupancy'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'abc_analysis'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'purchase_order'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'product'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'product_receivables'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'lab_test_wise'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'notvisited_report'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>    
                            <div *ngSwitchCase="'consolidat_report'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'appedit'">
                                <app-diag-app-edit></app-diag-app-edit>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2023 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>

<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'Admin'">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNavAdmin('sales_report','');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="menu" id="billingList" (click)="menuNavAdmin('billingList','');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Billing</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="submenu_flag" class="nav-children">
                            <li (click)="menuNavAdmin('bill_list','')" class="mb-1"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i>
                                    Doctor billing</a></li>
                            <li (click)="menuNavAdmin('pharmaBillingList','');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i>
                                    Pharmacy billing</a></li>
                            <li (click)="menuNavAdmin('diagBillingList','');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i>
                                    Diagnosis billing</a></li>
                            <li [hidden]="adminConsolidatedFlag" (click)="menuNavAdmin('consolidatedBills','');"
                                class="mb-1"><a><i class="fa fa-angle-right"></i> Consolidated bills</a></li>
                        </ul>
                    </div>

                    <div class="menu" id="reports" (click)="menuNavAdmin('reports','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src=" ../../assets/global/images/mis_report_icon.svg" /></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="submenuFlag1" class="nav-children">
                            <li [hidden]="salesReportFlag" (click)="menuNavAdmin('sales_report','');"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i>
                                    Pharma sales report</a></li>
                            <li [hidden]="salesReportGstFlag" (click)="menuNavAdmin('salesGst','salesGst');"
                                class="mb-1"><a><i class="fa fa-angle-right"></i>
                                    Sales report GST</a></li>
                            <li [hidden]="stockReceivableFlag"
                                (click)="menuNavAdmin('stockReceivable','stockReceivable');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Stock receivables</a></li>
                            <li [hidden]="schdForDrugInspFlag" (click)="menuNavAdmin('gsd','gsd');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Schedule register</a></li>
                            <li [hidden]="salesForDrugInspFlag" (click)="menuNavAdmin('SDI','SDI');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Sales for drug inspector</a></li>
                            <li [hidden]="hospBillsReportFlag" (click)="menuNavAdmin('HBR','HBR');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Hospital bills report</a></li>
                            <li [hidden]="diagBillsReportFlag" (click)="menuNavAdmin('DBR','DBR');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Diagnosis bills report</a></li>
                            <li [hidden]="inwardReportFlag" (click)="menuNavAdmin('IR','IR');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Inward reports</a></li>
                            <li [hidden]="inpatReportFlag" (click)="menuNavAdmin('IPR','IPR');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Inpatient reports</a></li>
                            <li [hidden]="stockReportFlag" (click)="menuNavAdmin('stock_report','');" class="mb-1"><a><i
                                        class="fa fa-angle-right"></i> Inpatient reports</a></li>
                            <li [hidden]="notvistedpatreportflag"
                                (click)="menuNavAdmin('notvisited_reportadmin','NVR');" class="mb-1">
                                <a id="nav-a"><i class="fa fa-angle-right"></i> Not visited patient</a>
                            </li>            
                                <li [hidden]="consolidatedreportflag"
                                (click)="menuNavAdmin('consolidat_reportadmin','CR');" class="mb-1">
                                <a id="nav-a"><i class="fa fa-angle-right"></i> Consolidate report</a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="menu" id="authorize_list" (click)="menuNavAdmin('authorize_list','');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> 
                            <span> Access & authorize</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i>
                            </span>
                        </div>
                    </div> -->

                    <!-- <div  class="menu menu_active" id="authorize_list" (click)="menuNavAdmin('authorize_list','');">
                        <div class="menu_icon" ><i class="icon-menu"><img src="../../assets/global/images/dashboard.svg"/></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied && minimizedAsied !== undefined" ><span></span>
                            <span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div> -->
                    <div class="menu" id="listPackage" *ngIf="packageCreateFlag"
                        (click)="menuNavAdmin('listPackage','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Package</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" *ngIf="packageCreateFlag" id="clientPackageList"
                        (click)="menuNavAdmin('clientPackageList','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title"> <span>Subscribed user </span><span
                                style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="stockEditFlag" class="menu" id="stock_edit" (click)="menuNavAdmin('stock_edit','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                                edit</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="leadMgmtFlag" class="menu" id="leadmgmt" (click)="menuNavAdmin('leadmgmt','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Lead management</span>
                            <span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}"
                                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        <div class="hospital_name"><span
                                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span>
                        </div>
                        <div class="headerCover">
                            <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
                            <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                {{firstName}}&nbsp;{{lastName}}</div>
                        </div>

                        
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <!-- <div class="menu_div" (click)="menuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                            profile</span> </div> -->
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Logout</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'sales_report'">
                                <app-pharma-sales-report></app-pharma-sales-report>
                            </div>
                            <div *ngSwitchCase="'sales_report'">
                                <app-pharma-sales-report></app-pharma-sales-report>
                            </div>
                            <div *ngSwitchCase="'stock_report'">
                                <app-pharma-stock-status></app-pharma-stock-status>
                            </div>
                            <div *ngSwitchCase="'stock_edit'">
                                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
                            </div>
                            <div *ngSwitchCase="'bill_list'">
                                <app-recept-bill-list></app-recept-bill-list>
                            </div>
                            <div *ngSwitchCase="'salesGst'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'stockReceivable'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'gsd'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'recreceivableReport'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'SDI'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'HBR'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'DBR'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'IR'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'IPR'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'consolidatedBills'">
                                <app-consolidated-bills></app-consolidated-bills>
                            </div>
                            <div *ngSwitchCase="'billingEdit'">
                                <app-recept-bill-edit></app-recept-bill-edit>
                            </div>
                            <div *ngSwitchCase="'billingCreate'">
                                <app-recept-bill-create></app-recept-bill-create>
                            </div>
                            <div *ngSwitchCase="'inPatientCreate'">
                                <app-ipatient-bill-create></app-ipatient-bill-create>
                            </div>
                            <div *ngSwitchCase="'billingDetailsView'">
                                <app-recept-bill-detailview></app-recept-bill-detailview>
                            </div>
                            <div *ngSwitchCase="'diagBillingList'">
                                <app-diagbilllist></app-diagbilllist>
                            </div>
                            <div *ngSwitchCase="'diagBillingView'">
                                <app-diagbilldetailview></app-diagbilldetailview>
                            </div>
                            <div *ngSwitchCase="'diagBillingEdit'">
                                <app-diagbilledit></app-diagbilledit>
                            </div>
                            <div *ngSwitchCase="'pharmaBillingList'">
                                <app-pharmacypastinformation></app-pharmacypastinformation>
                            </div>
                            <div *ngSwitchCase="'pharmaBillingView'">
                                <app-pharmacymodule-orderdetiledview></app-pharmacymodule-orderdetiledview>
                            </div>
                            <div *ngSwitchCase="'createPackage'">
                                <app-create-package></app-create-package>
                            </div>
                            <div *ngSwitchCase="'listPackage'">
                                <app-list-package></app-list-package>
                            </div>
                            <div *ngSwitchCase="'clientPackageList'">
                                <app-user-package-list></app-user-package-list>
                            </div>
                            <div *ngSwitchCase="'adminReceptbillcreate'">
                                <app-recept-bill-create></app-recept-bill-create>
                            </div>
                            <div *ngSwitchCase="'diagbillcreate'">
                                <app-diagbillcreate></app-diagbillcreate>
                            </div>
                            <div *ngSwitchCase="'createUserPackage'">
                                <app-create-package></app-create-package>
                            </div>
                            <div *ngSwitchCase="'leadmgmt'">
                                <app-lead-mgmt></app-lead-mgmt>
                            </div>
                            <div *ngSwitchCase="'notvisited_reportadmin'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'consolidat_reportadmin'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'authorize_list'">
                                <app-access-rights></app-access-rights>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2023 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>

<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'diagnosis'">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('appointment');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="menu menu_active" id="appointment" [hidden]="appointmentData"
                        (click)="menuNav('appointment');">
                        <div class="menu_icon"> <i class="icon-menu">
                            <img src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Appointments</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <!-- <div class="menu" id="tests" [hidden]="test" (click)="menuNav('samplecollect');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Sample collection</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div> -->
                    <div class="menu" id="tests" [hidden]="test" (click)="menuNav('tests');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Tests & packages</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>

                    <div class="menu" id="report_upload" [hidden]="reportFlag" (click)="menuNav('report_upload');">
                        <div class="menu_icon"><i class="icon-menu">
                            <img src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Test report</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="testSubMenuFlag" class="nav-children">
                            <li (click)="menuNav('pending');" class="mb-1"><a id="nav-a">
                                <i class="fa fa-angle-right"></i> Pending</a></li>
                            <li (click)="menuNav('rejected');" class="mb-1"><a>
                                <i class="fa fa-angle-right"></i> Rejected</a></li>
                            <li (click)="menuNav('completed');" class="mb-1"><a>
                                <i class="fa fa-angle-right"></i> Completed</a></li>
                        </ul>
                    </div>

                    <!-- <div class="menu" id="outsource" [hidden]="sourceFlag" (click)="menuNav('outsource');">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Outsource</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="outsubFlag" class="nav-children">
                            <li (click)="menuNav('out-centre-list');" class="mb-1"><a id="nav-a">
                                <i class="fa fa-angle-right"></i> Centre</a></li>
                            <li (click)="menuNav('out-reports');" class="mb-1"><a>
                                <i class="fa fa-angle-right"></i> Reports</a></li>
                            <li (click)="menuNav('out-payments');" class="mb-1"><a>
                                <i class="fa fa-angle-right"></i> Payments</a></li>
                        </ul>
                    </div> -->

                    <div class="menu" id="report_approval" [hidden]="repAppFlag" (click)="menuNav('report_approval');">
                        <div class="menu_icon"> <i class="icon-menu">
                            <img src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Report approval</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>

                    <div class="menu" id="diabetics_readings" [hidden]="diabFlag"
                        (click)="menuNav('diabetics_readings');">
                        <div class="menu_icon"> <i class="icon-menu">
                            <img src="../../assets/global/images/billing.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Diabetics readings</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="billing" (click)="setAppoinmentHelper('billing');menuNav('billing');">
                        <div class="menu_icon"><i class="icon-menu">
                            <img src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Billing</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="billingFlag" class="nav-children">
                            <li (click)="menuNav('billinglist');" class="mb-1"><a id="nav-a">
                                <i class="fa fa-angle-right"></i> Bill list</a></li>
                            <li [hidden]="partialPaymentFlag" (click)="menuNav('partialPayments');setAppFlow('diagnosis')" class="mb-1"> 
                                <a><i class="fa fa-angle-right"></i> Partial Payments</a></li>
                        </ul>
                    </div>

                    <div class="menu" id="users" [hidden]="userHidden" (click)="menuNav('users');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/home.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Users</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="dashboard" (click)="menuNav('dashboard');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Dashboard</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="dashSubMenuFlag" class="nav-children">
                            <li (click)="menuNav('patient_list');" class="mb-1"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i>
                                    Patient list</a></li>
                            <li (click)="menuNav('reports');" class="mb-1"><a><i class="fa fa-angle-right"></i>
                                    Reports</a></li>
                        </ul>
                    </div>

                    <div [hidden]="reference_video" class="menu" id="reference_video"
                        (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title"> <span> Reference video</span><span
                                style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="help" (click)="menuNav('help');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/help.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}"
                                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        
                        <div class="hospital_name">{{hospitalName}}</div>
                        <div class="headerCover">
                            <div class="bell"></div>
                            <div class="share"></div>
                            <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <img src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
                            </div>
                            <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                {{firstName}}&nbsp;{{lastName}}</div>
                        </div>

                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'">
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('diagnosis_home');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                profile</span> </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Logout</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchCase="'appointment'">
                                <app-diagnmosis-appointments></app-diagnmosis-appointments>
                            </div>
                            <div *ngSwitchCase="'appedit'">
                                <app-diag-app-edit></app-diag-app-edit>
                            </div>
                            <div *ngSwitchCase="'tests'">
                                <app-diag-test-home></app-diag-test-home>
                            </div>
                            <div *ngSwitchCase="'nephrology_readings'">
                                <app-nephrologyreadings></app-nephrologyreadings>
                            </div>
                            <div *ngSwitchCase="'diabetics_readings'">
                                <app-diabeticsreading></app-diabeticsreading>
                            </div>
                            <div *ngSwitchCase="'billinglist'">
                                <app-diagbilllist></app-diagbilllist>
                            </div>
                            <div *ngSwitchCase="'pending'">
                                <app-diagnosis-report-upload></app-diagnosis-report-upload>
                            </div>
                            <div *ngSwitchCase="'rejected'">
                                <app-diagnosis-modify-report></app-diagnosis-modify-report>
                            </div>
                            <div *ngSwitchCase="'report_upload_edit'">
                                <app-diagnosis-report-edit></app-diagnosis-report-edit>
                            </div>
                            <div *ngSwitchCase="'completed'">
                                <app-diagnosis-complete-report></app-diagnosis-complete-report>
                            </div>
                            <div *ngSwitchCase="'report_upload_view'">
                                <app-diagnosis-report-detailview></app-diagnosis-report-detailview>
                            </div>
                            <div *ngSwitchCase="'diagnosisview'">
                                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
                            </div>
                            <div *ngSwitchCase="'nephrologyreadingviewpage'">
                                <app-nephrologyreadingviewpage></app-nephrologyreadingviewpage>
                            </div>
                            <div *ngSwitchCase="'diagnosis_home'">
                                <app-diagnosis-home></app-diagnosis-home>
                            </div>
                            <div *ngSwitchCase="'users'">
                                <app-diagnosis-user-list></app-diagnosis-user-list>
                            </div>
                            <div *ngSwitchCase="'users_view'">
                                <app-diagnosis-user-view></app-diagnosis-user-view>
                            </div>
                            <div *ngSwitchCase="'report_approval'">
                                <app-diagnosis-report-approval></app-diagnosis-report-approval>
                            </div>
                            <div *ngSwitchCase="'report_approval_detailed'">
                                <app-diagnosis-report-approval-detailedview>
                                </app-diagnosis-report-approval-detailedview>
                            </div>
                            <div *ngSwitchCase="'help'">
                                <app-diagnosis-help></app-diagnosis-help>
                            </div>
                            <div *ngSwitchCase="'diagtestadd'">
                                <app-diag-test-add></app-diag-test-add>
                            </div>
                            <div *ngSwitchCase="'diabeticsreading'">
                                <app-diabeticsreading></app-diabeticsreading>
                            </div>
                            <div *ngSwitchCase="'diagbillcreate'">
                                <app-diagbillcreate></app-diagbillcreate>
                            </div>
                            <div *ngSwitchCase="'diagbilldetailview'">
                                <app-diagbilldetailview></app-diagbilldetailview>
                            </div>
                            <div *ngSwitchCase="'diag_app_create'">
                                <app-diag-app-create></app-diag-app-create>
                            </div>
                            <div *ngSwitchCase="'patient_list'">
                                <app-diag-patient-list></app-diag-patient-list>
                            </div>
                            <div *ngSwitchCase="'reports'">
                                <app-diag-reports></app-diag-reports>
                            </div>
                            <div *ngSwitchCase="'billedit'">
                                <app-diagbilledit></app-diagbilledit>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'createPackage'">
                                <app-create-package></app-create-package>
                            </div>
                            <div *ngSwitchCase="'listPackage'">
                                <app-list-package></app-list-package>
                            </div>
                            <div *ngSwitchCase="'clientPackageList'">
                                <app-user-package-list></app-user-package-list>
                            </div>
                            <div *ngSwitchCase="'capp-diag-packagereateUserPackage'">
                                <app-create-package></app-create-package>
                            </div>
                            <div *ngSwitchCase="'diagPackageCreate'">
                                <app-diag-package-create></app-diag-package-create>
                            </div>
                            <div *ngSwitchCase="'samplecollect'">
                                <app-diag-sample-list></app-diag-sample-list>
                            </div>
                            <div *ngSwitchDefault="'appointment'">
                                <app-diagnmosis-appointments></app-diagnmosis-appointments>
                            </div>
                            <div *ngSwitchCase="'out-centre'">
                                <app-outsource-centre></app-outsource-centre>
                            </div>
                            <div *ngSwitchCase="'out-reports'">
                                <app-outsource-reports></app-outsource-reports>
                            </div>
                            <div *ngSwitchCase="'out-payments'">
                                <app-outsource-payments></app-outsource-payments>
                            </div>
                            <div *ngSwitchCase="'out-centre-list'">
                                <app-outsource-centre-list></app-outsource-centre-list>
                            </div>
                            <div *ngSwitchCase="'out-test-map'">
                                <app-outsource-test-map></app-outsource-test-map>
                            </div>
                            <div *ngSwitchCase="'partialPayments'">
                                <app-partial-payment></app-partial-payment>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2023 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>

<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'nurse'">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('appointment');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="menu menu_active" id="appointment" [hidden]="appointmentData"
                        (click)="menuNav('appointment');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Appointments</span>
                        </div>
                    </div>
                    <div class="menu" id="appointment" [hidden]="appointmentData" (click)="menuNav('assignList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/Schedule.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Assignments</span>
                        </div>
                    </div>
                    <div class="menu" id="appointment" [hidden]="appointmentData" (click)="menuNav('doctorAppList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Doctor appointment</span>
                        </div>
                    </div>
                    <div class="menu" id="appointment" [hidden]="appointmentData" (click)="menuNav('doctorList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Doctor list</span>
                        </div>
                    </div>
                    <div class="menu" id="inpatient" (click)="menuNav('inpatient');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Inpatient</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}" alt="user image">
                        </div>
                        <!-- <i class="far fa-bell"></i> 
                        <i class="far fa-paper-plane"></i>-->
                        <div class="hospital_name">{{hospitalName}}</div>
                        <div class="headerCover">
                            <div class="bell"></div>
                            <div class="share"></div>
                            <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                    _ngcontent-ore-c119="" src="{{profileImage}}"
                                    onerror="this.src='./././assets/img/default.jpg'"> </div>
                            <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                {{firstName}}&nbsp;{{lastName}}</div>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                profile</span> </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Logout</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'appointment'">
                                <app-nurse-appointments></app-nurse-appointments>
                            </div>
                            <div *ngSwitchCase="'appointment'">
                                <app-nurse-appointments></app-nurse-appointments>
                            </div>
                            <div *ngSwitchCase="'appoinmentDetailView'">
                                <app-nurse-appointment-detailspage></app-nurse-appointment-detailspage>
                            </div>
                            <div *ngSwitchCase="'assignList'">
                                <app-nurse-assign-list></app-nurse-assign-list>
                            </div>
                            <div *ngSwitchCase="'assignDetailView'">
                                <app-nurse-assign-view></app-nurse-assign-view>
                            </div>
                            <div *ngSwitchCase="'doctorAppList'">
                                <app-nurse-doc-app-list></app-nurse-doc-app-list>
                            </div>
                            <div *ngSwitchCase="'doctorList'">
                                <app-nurse-doc-list></app-nurse-doc-list>
                            </div>
                            <div *ngSwitchCase="'profile'">
                                <app-nurse-profile></app-nurse-profile>
                            </div>
                            <div *ngSwitchCase="'inpatient'">
                                <app-medicine-tracker></app-medicine-tracker>
                            </div>
                            <!-- <div *ngSwitchCase="'inPatientViewInfo'">
                                <app-nurse-inpatient-info></app-nurse-inpatient-info>
                            </div> -->
                            <div *ngSwitchCase="'medicine_tracker'">
                                <app-medicine-tracker></app-medicine-tracker>
                            </div>
                            <div *ngSwitchCase="'viewIpatient'">
                                <app-inpat-patient-info></app-inpat-patient-info>
                            </div>
                            <div *ngSwitchCase="'DoctorAppDetail'">
                                <app-nurse-doc-app-view></app-nurse-doc-app-view>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-casesheet></app-casesheet>
                            </div>
                            <div *ngSwitchCase="'casesheet_list'">
                                <app-casesheet-list></app-casesheet-list>
                            </div>
                            <div *ngSwitchCase="'cardio_casesheet_view'">
                                <app-cardio-casesheet-view></app-cardio-casesheet-view>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2023 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>

<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'physio'">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('appointment');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div [hidden]="homecareFlag" class="menu menu_active" id="appointment"
                        (click)="menuNav('appointment');appointments('app')">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Appointments</span>
                        </div>
                    </div>
                    <div class="menu" [hidden]="eventIconFlag" id="homecare"
                        (click)="menuNav('homecare');appointments('home')">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/home.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Homecare</span>
                        </div>
                    </div>
                    <div class="menu" id="assignments" [hidden]="eventIconFlag" (click)="menuNav('assignmentList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/Schedule.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Assignments</span>
                        </div>
                    </div>
                    <div class="menu" id="therapies" [hidden]="isAdmin" (click)="menuNav('therapies');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Therapies</span>
                        </div>
                    </div>
                    <div [hidden]="walkinFlag" class="menu" id="walkin" (click)="menuNav('walkin');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Walkin</span>
                        </div>
                    </div>
                    <div class="menu" id="billing" [hidden]="isAdmin" (click)="menuNav('billing');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/billing.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Billing</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}" alt="user image">
                        </div>
                        <!-- <i class="far fa-bell"></i> 
                        <i class="far fa-paper-plane"></i>-->
                        <div class="hospital_name">{{hospitalName}}</div>
                        <div class="bell"></div>
                        <div class="share"></div>
                        <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <img src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
                        </div>
                        <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{firstName}}&nbsp;{{lastName}}</div>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                profile</span> </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Logout</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <!-- <div *ngSwitchDefault="'appointment'">
                                <app-physio-appointments></app-physio-appointments>
                            </div> -->
                            <div *ngSwitchDefault="'Home'">
                                <app-physio-prof-dash></app-physio-prof-dash>
                            </div>
                            <div *ngSwitchCase="'appointment'">
                                <app-physio-appointments></app-physio-appointments>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-physio-casesheet></app-physio-casesheet>
                            </div>

                            <div *ngSwitchCase="'assignmentList'">
                                <app-physio-assign-list></app-physio-assign-list>
                            </div>
                            <div *ngSwitchCase="'assignmentDetailPage'">
                                <app-physio-assign-view></app-physio-assign-view>
                            </div>
                            <div *ngSwitchCase="'therapies'">
                                <app-physio-therapies></app-physio-therapies>
                            </div>
                            <div *ngSwitchCase="'userList'">
                                <app-physio-user-list></app-physio-user-list>
                            </div>
                            <div *ngSwitchCase="'userViewPage'">
                                <app-physio-user-view></app-physio-user-view>
                            </div>
                            <div *ngSwitchCase="'Home'">
                                <app-physio-prof-dash></app-physio-prof-dash>
                            </div>
                            <div *ngSwitchCase="'billing'">
                                <app-physio-billlist></app-physio-billlist>
                            </div>
                            <div *ngSwitchCase="'walkin'">
                                <app-walking></app-walking>
                            </div>
                            <div *ngSwitchCase="'billdetails'">
                                <app-physio-bill-details></app-physio-bill-details>
                            </div>
                            <!-- <div *ngSwitchCase="'billCreate'">
                                <app-physio-bill-create></app-physio-bill-create>
                            </div> -->
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2023 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>

<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'dietician'">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('diet_dashboard');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="menu menu_active" id="appointment" (click)="menuNav('appointment');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Appointments</span>
                        </div>
                    </div>
                    <div class="menu" id="manageappointments" (click)="menuNav('manageappointments');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> <span>Doctor
                                appointments</span></div>
                    </div>
                    <div class="menu" id="patientList" (click)="menuNav('patientList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/permission.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Patient list</span>
                        </div>
                    </div>
                    <div class="menu" id="readings" (click)="menuNav('readings');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/permission.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> <span>Diab
                                Readings</span></div>
                    </div>
                    <div class="menu" id="billing" (click)="menuNav('billList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/billing.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Billing</span>
                        </div>
                    </div>
                    <div class="menu" id="notification" (click)="menuNav('notification');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/notification.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Notification</span>
                        </div>
                    </div>
                    <div [hidden]="reference_video" class="menu" id="reference_video"
                        (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title"> <span> Reference video</span><span
                                style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}"
                                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        <div class="hospital_name"><span
                                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span>
                        </div>
                        <div class="headerCover">
                            <div class="bell" (click)="menuNav('notification');"><i class="far fa-bell"></i><span
                                    class="notificatio_count" *ngIf="notificationFlag">{{notificationCount}}</span>
                            </div>
                            <!-- <div class="share"><i class="far fa-paper-plane"></i></div>   -->
                            <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                    src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
                            <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                {{firstName}}&nbsp;{{lastName}}</div>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                profile</span> </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Logout</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchCase="'manageappointments'">
                                <app-doc-app-list></app-doc-app-list>
                            </div>

                            <div *ngSwitchCase="'profile'">
                                <app-profile-update></app-profile-update>
                            </div>
                            <div *ngSwitchCase="'dietPlan'">
                                <app-dietplan></app-dietplan>
                            </div>
                            <div *ngSwitchCase="'dietPlancard'">
                                <app-dietplan></app-dietplan>
                            </div>
                            <div *ngSwitchCase="'appointment'">
                                <app-diet-appointments></app-diet-appointments>
                            </div>
                            <div *ngSwitchCase="'appt_create'">
                                <app-diet-appointments-creation></app-diet-appointments-creation>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-diet-casesheet></app-diet-casesheet>
                            </div>
                            <div *ngSwitchCase="'notification'">
                                <app-doctor-notification></app-doctor-notification>
                            </div>
                            <div *ngSwitchCase="'patientList'">
                                <app-diet-patient-list></app-diet-patient-list>
                            </div>
                            <div *ngSwitchCase="'patientTracking'">
                                <app-diet-tracking></app-diet-tracking>
                            </div>
                            <div *ngSwitchCase="'billList'">
                                <app-doctorbilllistpage></app-doctorbilllistpage>
                            </div>
                            <div *ngSwitchCase="'billCreate'">
                                <app-bill-create></app-bill-create>
                            </div>
                            <div *ngSwitchCase="'billdetail'">
                                <app-bill-detail-view></app-bill-detail-view>
                            </div>
                            <div *ngSwitchCase="'readings'">
                                <app-diet-patient-list></app-diet-patient-list>
                            </div>
                            <div *ngSwitchCase="'diabReadings'">
                                <app-diab-readings></app-diab-readings>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchDefault="'diet_dashboard'">
                                <app-doctorhome></app-doctorhome>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2023 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>