<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Current order</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="createFlag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="createOrder()" />
            <img style="width: 20px !important;" src="../../../assets/img/filter_icon.png" (click)="optionsPopover()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" style="margin-bottom: 15px;">
            <div class="row">
              <div class="col-12">
                <div class="switch-field" *ngIf="ynopt1 != undefined">
                  <input type="radio" id="radio-three" (click)="changeType(1,'yes')" />
                  <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Outpatient.svg" class="iocinStyle"
                          alt=""></div>
                      <div class="lableStyle">Out-patient</div>
                    </div>
                  </label>
                  <input type="radio" id="radio-four" (click)="changeType(1,'no')" />
                  <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Inpatient.svg" class="iocinStyle"
                          alt=""></div>
                      <div class="lableStyle">In-patient</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel" style="margin-top: 7px;">From
              <input type="date" class="ipcss_date " (change)="selectDate(fromDate,'1')" [(ngModel)]="fromDate"
              max="{{currentDate}}" #matInput style="width: 140px;">
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel" style="margin-top: 7px;">To
              <input type="date" class="ipcss_date " (change)="selectDate(toDate,'2')" [(ngModel)]="toDate"
                max="{{currentDate}}" #matInput style="width: 140px;">
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel"><br>
              <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                (click)="currentOrder(pharmacyID)" />
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <p class="nodata" *ngIf="orderListArray.length == 0">No currentOrder(s) found</p>
            <table *ngIf="orderListArray.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable pharmacycurrentorder">
              <thead class="tableheading">
                <tr>
                  <th>Bill No</th>
                  <th>Name</th>
                  <th>Delivery type </th>
                  <th>Order date </th>
                  <th> Status </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let list of orderListArray" class="tdvalues"
                  (click)='orderView(list.drug_purchase_id, list.home_delivery, list.flag,list.fin_year)'>
                  <td style="font-size: 12px;">{{list.drug_purchase_id}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{list.tot_name}}</td>
                  <td style="font-size: 12px;text-align: left !important;"> {{list.home_delivery}}</td>
                  <td style="font-size: 12px;">{{list.order_date}}</td>
                  <td style="font-size: 12px;">
                    <div style="position: relative;top: 9px;">
                      <img *ngIf="list.status == 'Completed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/Completed_icon.svg" />
                      <img *ngIf="list.status == 'Cancelled'" width="30px" height="auto"
                        src="../../../assets/ui_icons/cancel_icon.svg" />
                      <img *ngIf="list.status == 'Confirmed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/confirmed_icon.svg" />
                      <img *ngIf="list.status == 'Open'" width="30px" height="32px"
                        src="../../../assets/ui_icons/opened_icon.svg" />
                      <img *ngIf="list.status == 'Rejected'" width="30px" height="auto"
                        src="../../../assets/ui_icons/confirmed_icon.svg" />
                      <img *ngIf="list.status == 'Awaiting for rating'" width="30px" height="32px"
                        src="../../../assets/ui_icons/awaiting_for_rating.svg" />
                      <img *ngIf="list.status == 'Review completed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/second_opinion/review_completed.svg" />
                      <p>{{list.status}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>