import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { MatDialog } from '@angular/material/dialog';
import { BillHistViewComponent } from '../bill-hist-view/bill-hist-view.component';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../..//app/helper_class';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';
import {Time_Formate} from '../../../assets/js/common';
declare var $:any;

@Component({
  selector: 'app-recept-bill-detailview',
  templateUrl: './recept-bill-detailview.component.html',
  styleUrls: ['./recept-bill-detailview.component.css']
})
export class ReceptBillDetailviewComponent implements OnInit {
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('logowithnamenopayconsqty') logowithnamenopayconsqty: ElementRef;
  
  public billId: string;
  public billingPrintView:any = [];
  public payType: string;
  public payFlag: boolean;
  public docRegId: string;
  public billDetailedView;
  public cgstFlag;
  public sgstFlag;
  public billingDetailedViewData;
  public billingPrintViewFlag: boolean;
  public totalFlag: boolean;
  public discountFlag:boolean;
  public advanceFlag: boolean;
  public paidFlag: boolean;
  public prevBalance: string;
  public prevBalanceFlag: boolean;
  public mainBalanceFlag: boolean;
  public estimatePrintData;
  public treatementPlanFlag: boolean;
  public charge = [];
  public hospAddress: boolean;
  public sendAddress: string;
  public clientAddress: string;
  public billAmount: string;
  public totalBill: string;
  public cgst: string;
  public sgst: string;
  public billingDetailList:any = [];
  public paymentsarray:any=[];
  public clientName: string;
  public docName: string;
  public printFlag:boolean = false; 
  
  public paid;
  public discount;
  dtOptions: DataTables.Settings = {};
  public hospitalLogo;
  public printtemplate:string;
  public printlogostyle;
  public bill_qty_flag:boolean=false;
  public bill_head:string;
  public bill_cons_flag:boolean=false;
  public bill_pay_flag: boolean=false;
  public paytypreq: boolean=false;
  public finyear:string;
  public headerstyle;
  public ageval:string;
  public genderval:string;
  public surgery:string;

  constructor(public sanitize:DomSanitizer,public toastr: ToastrService, public http: Http,public service: MenuViewService,public dialog:MatDialog,public adminservice:MenuViewService) {
    this.payFlag = true;
    this.billDetailedView = FrontDesk_Helper.getreceptbilldetailview()
    this.billId = this.billDetailedView.bill_view_id;
    this.docRegId = this.billDetailedView.doc_reg_id;
    this.finyear = this.billDetailedView.fin_year;
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.hospAddress = true;
    this.billingPrintViewFlag = true;
    this.treatementPlanFlag = true;
    this.cgstFlag = true;
    this.sgstFlag = true;
    this.prevBalanceFlag = true;
    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;
    this.printtemplate = Helper_Class.getInfo().bill_print_template;
    this.printlogostyle=Helper_Class.getInfo().bill_print_logo_style;
    this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().bill_print_heading);
    
    if(this.headerstyle == undefined || this.headerstyle == 'undefined'){
      this.headerstyle="";
    }

    if(Helper_Class.getInfo().bill_qty_flag ==0){
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }
    
    if(Helper_Class.getInfo().bill_cons_flag ==0){
      this.bill_cons_flag = true;
    } else {
      this.bill_cons_flag = false;
    }
    if(Helper_Class.getInfo().bill_pay_flag ==0){
      this.bill_pay_flag = true;
    } else {
      this.bill_pay_flag = false;
    }
    
    if(Helper_Class.getInfo().bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }

    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.getBillDetailedView();
  }

  getBillDetailedView() {
    var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/',
      JSON.stringify({
        doc_reg_id: this.docRegId,
        billing_id: this.billId,
        spl_name: "dental",
        app_type: type,
        fin_year:  this.finyear, 
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          console.log("Bill details "+JSON.stringify(obj))
          if (obj.bills != null) {
            if (obj.cgst != null) {
              this.cgstFlag = false;
              this.sgstFlag = false;
            }

            this.billingDetailedViewData = obj.bills;
            this.billingPrintViewFlag = this.billingDetailedViewData != null && this.billingDetailedViewData[0].charge_type != null ?  false : true;
            var sno=1;
            for (var i = 0; i < this.billingDetailedViewData.length; i++) {
              this.billingPrintView.push({
                sno:sno,
                bill_type: this.billingDetailedViewData[i].charge_desc,
                amount_txt: parseFloat(this.billingDetailedViewData[i].amount_txt).toFixed(2),
                concession: this.billingDetailedViewData[i].concession,
                fee: parseFloat(this.billingDetailedViewData[i].fee).toFixed(2),
                charge_type: this.billingDetailedViewData[i].charge_type,
                pay_type: this.billingDetailedViewData[i].pay_type,
                quantity:this.billingDetailedViewData[i].quantity,
              })
              sno+=1;
            }
            if(obj.surgery != undefined){
              this.surgery = obj.surgery;
            }
            if (obj.bill_amount == undefined && obj.bill_amount == "") 
              this.totalFlag = true;

            if (obj.advance == undefined || obj.advance == "") 
              this.advanceFlag = true;

            if (obj.prev_balance != null) {
              this.prevBalanceFlag = false;
              this.prevBalance =parseFloat(obj.prev_balance).toFixed(2);
            } else {
              this.prevBalanceFlag = true;
            }

            if (obj.balance == undefined || obj.balance == "")
              this.mainBalanceFlag = true;

            this.estimatePrintData = obj.estimates;
            this.treatementPlanFlag = this.estimatePrintData != "" && this.estimatePrintData != undefined ? false : true;

            this.charge = [];
            this.hospAddress = obj.hptl_name != null ?  false : true;

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(obj.created_time);
            }
            if(obj.age != undefined){
              this.ageval = obj.age;

            } else {
              this.ageval="";
            }
            if(obj.gender != undefined){
              this.genderval = obj.gender;

            } else {
              this.genderval="";
            } 

            if (obj.first_name != undefined) {
              this.sendAddress = obj.address2 != null && obj.address2 != "" && obj.address2 != undefined ?   obj.address1 + ", " + obj.address2 + "," :  obj.address1 + ",";
              
              if (encrypt_decript.Decript(obj.caddress1) !="NA") {
                this.clientAddress = obj.caddress2 != null && obj.caddress2 != "" ? encrypt_decript.Decript(obj.caddress1).toString() + " " + encrypt_decript.Decript(obj.caddress2).toString() : encrypt_decript.Decript(obj.caddress1).toString();
              } 

              var tot_amt = "0";
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                  }
                  if (obj.bills[i].pay_type != null) {
                    this.payType = obj.bills[i].pay_type;
                    this.payFlag = false;
                  }
                }
              }

              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                    }
                  }
                }
              }

              this.billAmount = tot_amt;
              this.totalBill = tot_amt;
              if (obj.cgst != null) {
                this.cgst = (parseFloat(this.billAmount) * (parseFloat(obj.cgst) / 100)).toFixed(2);
                this.sgst = (parseFloat(this.billAmount) * (parseFloat(obj.sgst) / 100)).toFixed(2);
              }
              
              this.paid =  obj.paid_amount != undefined ? parseFloat(obj.paid_amount).toFixed(2) : '0.00';
              this.discount = parseFloat(obj.round_off).toFixed(2);
              if (obj.middle_name != null && obj.middle_name != undefined) {
                this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              } else {
                this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              }

              this.docName = obj.dr_middle_name == undefined ?  "Dr." + obj.dr_first_name + " " + obj.dr_last_name : "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
              var advanceval,paidval,balval;
              var advval;
              if(obj.advance !=undefined){
                advval = obj.advance;
              } else {
                advval=0.00;
              }
              if(advval != "0.00" && obj.adv_balance != undefined && obj.paid_amount == 0.00){
                advanceval =(parseFloat(advval)+parseFloat(obj.adv_balance)).toFixed(2) 
                balval= (parseFloat(this.billAmount)-advanceval).toFixed(2)
                paidval = 0.00 
               
              } else if(advval != "0.00" && obj.adv_balance == undefined && obj.paid_amount == 0.00){
                advanceval =parseFloat(advval).toFixed(2) 
                balval= (parseFloat(this.billAmount)-advanceval).toFixed(2)
                paidval = 0.00 
                
              } else if(advval != "0.00" && obj.adv_balance == undefined && obj.paid_amount != 0){
                advanceval =parseFloat(advval).toFixed(2) 
                paidval =parseFloat(obj.paid_amount).toFixed(2) 
                balval= (parseFloat(this.billAmount)-(parseFloat(paidval)+parseFloat(advanceval))).toFixed(2)
               
              } else if(advval == "0.00" && obj.adv_balance == undefined && obj.paid_amount != 0){
                paidval =parseFloat(obj.paid_amount).toFixed(2) 
                balval= (parseFloat(this.billAmount)-paidval).toFixed(2)
                advanceval=0.00;
                
              } else if(advval == "0.00" && obj.adv_balance == undefined && obj.paid_amount == 0){
                balval= parseFloat(this.billAmount).toFixed(2);
                advanceval=0.00;
                paidval=0.00
                
              } else {
                balval= parseFloat(this.billAmount).toFixed(2);
                advanceval=0.00;
                paidval=0.00
              }

              if(this.discount != undefined){
                this.discountFlag=false;
                balval = parseFloat(balval)-parseFloat(this.discount)
              }
              
              var cname;
              if(obj.salutation != undefined) {
                cname = obj.salutation+" "+this.clientName;

              } else {
                cname = this.clientName;
              }
              var genshort;
              if(this.genderval !=undefined && encrypt_decript.Decript(this.genderval) =="Male"){
                genshort = "M";
              } else {
                genshort="F";
              }
              this.billingDetailList.push({
                dr_name: "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name,
                licence_code: " Reg. No: " + obj.licence_code,
                hptl_name: obj.hptl_name,
                address: this.sendAddress,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                created_date: datetxt,
                appointment_id: obj.appointment_id,
                client_name: cname,
                pay_type: obj.pay_type,
                advance: advanceval,
                balance: balval,
                caddress: this.clientAddress,
                clocation: obj.clocation + ", ",
                clientcity: obj.ccity + " - " + encrypt_decript.Decript(obj.czipcode) + ",",
                clestare: obj.cstate + " - " + obj.ccountry + ".",
                uhid: obj.client_hosp_id,
                tot_amt : this.billAmount,
                mobile:encrypt_decript.Decript(obj.mobile),
                inpatid:obj.inp_hosp_id,
                age:this.ageval,
                gender:genshort,
              });

              if(obj.payments != undefined){
                this.paymentsarray = obj.payments;
              }
            }
          } 
        },
        error => {});
  }

  print() {
    let printContents, popupWin;
    if (this.printtemplate != undefined && this.printtemplate == "banner") {
      printContents = document.getElementById('banner').innerHTML;
      
    } else if (this.printtemplate != undefined && this.printtemplate == "noheader") {
      printContents = document.getElementById('noheader').innerHTML;

    } else if (this.printtemplate != undefined && this.printtemplate == "logowithnamenopayconsqty") {
      printContents = document.getElementById('logowithnamenopayconsqty').innerHTML;

    } else {
      printContents = document.getElementById('logowithname').innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Bill</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  back() {
    if(Helper_Class.getIsAdmin() != undefined){
      this.adminservice.sendMessage("bill_list");
    }else{
      this.service.sendMessage('frontdeskbilling');
    }
  }

  history(){
    const dialogRef = this.dialog.open(BillHistViewComponent, {
      width: '700px',
      height: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}
