import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { DomSanitizer } from '@angular/platform-browser';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from 'html2canvas';
import * as pdfMake from "pdfmake/build/pdfmake";

const htmlToPdfmake = require("html-to-pdfmake");

@Component({
  selector: 'app-receptviewprescription',
  templateUrl: './receptviewprescription.component.html',
  styleUrls: ['./receptviewprescription.component.css']
})
export class ReceptviewprescriptionComponent implements OnInit {
  @ViewChild('printsection') printContainer: ElementRef;
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('fourcolumnnoheader') fourcolumnnoheader: ElementRef;
  @ViewChild('printbannerfullpage') printbannerfullpage: ElementRef;
  @ViewChild('printnoheaderwithname') printnoheaderwithname: ElementRef;

  @ViewChild('pdfTable') pdfTable: ElementRef;

  public docRegId;
  public pharmacyDetailList = [];
  public pharmacyFlag: boolean;
  public doctorQualification;
  public hospLogo;
  public hptlName;
  public address;
  public doctorLocation;
  public doctorCity;
  public doctorState;
  public doctorCountry;
  public doctorTelephone;
  public prescriptionUrl: string;
  public prescriptionPresId;
  public prescriptionFlag: boolean = false;
  public show: boolean;
  public patientName: string;
  public doctorName: string;
  public prescriptionDate: string;
  public diseaseName: string;
  public diseaseDescription: string;
  public subRelId: string;
  public relationId: string;
  public relationName: string;
  public hasPharmaFlag: boolean = false;
  public pharmacyName: string;
  public pharmacyAddr1: string;
  public pharmacyAddr2: string;
  public pharmacyLocation: string;
  public pharmacyCity: string;
  public pharmacyZipcode: string;
  public pharmacyState: string;
  public pharmacyCountry: string;
  public telephone: string;
  public pharmaId: string;
  public storePickUpFlag: boolean = false;
  public delopt: boolean = false;
  public homeDeliveryFlag: boolean = false;
  public validityDays;
  public workTime;
  public sendPresIdData;
  public appointment: boolean = false;

  public balanceQuantity: number = 0;
  public hospitalFlag: boolean;
  public listProducts = [];
  public everySix: string;
  public duration: string;
  public durationFlag: boolean;
  public intake: string;
  public instructions;
  public instructionFlag: boolean;
  public age: string;
  public gender: string;
  public licenceCode: string;
  public printTemplate: string;
  public language: string;
  public clntBp: string;
  public clntPulse: string;
  public clntCvs: string;
  public clntRs: string;
  public clntKidney: string;
  public diagnosis: string;
  public treatment;
  public nextVisit: string;
  public hptlPresLogo;
  public investigation: string;

  public orthoFlag: boolean = false;
  public bpFlag: boolean = false;
  public treatFlag: boolean = false;
  public pulmFlag: boolean = false;
  public getViewPresData;
  public pft;

  public pharmacyLogo;
  public height;
  public weight;
  public temparature;
  public heightFlag: boolean;
  public weightFlag: boolean;
  public temparatureFlag: boolean;
  public printFlag: boolean;
  public dur_flag: boolean = false;
  public dur_time_flag: boolean = false;
  public notes_flag: boolean = false;
  public spo2Flag: boolean = false;
  public prFlag: boolean = false;
  public userInfo;
  public mobile;
  public preslogo_style;
  public med_style;
  public dosage_style;
  public dur_style;
  public freq_style;
  public indur_style;
  public note_style;
  public apptype: string;
  public clntSpo2: string;
  public clntPr: string;
  public clntUHID: string;
  public prescriptionTime: string;
  public medstyle: boolean = false;
  public medstyle1: boolean = false;
  public medstyle2: boolean = false;
  public medstyle3: boolean = false;
  public complaints:string;

  constructor(public sanitizer: DomSanitizer, public datepipe: DatePipe, public gservice: CommonDataService,
    public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public frontdeskservice: MenuViewService) {
    this.instructionFlag = true;
    this.durationFlag = false;
    this.orthoFlag = false;
    this.bpFlag = false;
    this.treatFlag = false;
    this.pulmFlag = false;
    this.prescriptionUrl = "prescription/mpdoc";
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.getViewPresData = FrontDesk_Helper.getreceptviewprecription();
    this.prescriptionPresId = this.getViewPresData.pres_id;
    if (this.userInfo.user_type == "front-desk") {
      this.printFlag = Helper_Class.getPrintFlag().admin_pres_print == "1" ? true : false;
      this.hptlPresLogo = ipaddress.Ip_with_img_address + this.userInfo.pres_print_logo;
      this.preslogo_style = this.userInfo.pres_print_logo_style;

    } else {
      this.printFlag = true;
      this.hptlPresLogo = ipaddress.Ip_with_img_address + this.userInfo.hospitals[0].pres_print_logo;
      this.preslogo_style = this.userInfo.hospitals[0].pres_print_logo_style;
    }

    this.sendPresIdData = {
      pres_id: this.prescriptionPresId,
    }
    this.getPrescriptionDetails(this.prescriptionUrl);

    
    this.dur_time_flag = this.userInfo.pres_dur_min_flag;

    if (this.userInfo.pres_dur_min_flag == "0") {
      this.dur_time_flag = true;
    } else {
      this.dur_time_flag = false;
    }

    if (this.userInfo.pres_duration_flag == "0") {
      this.dur_flag = true;


    } else {
      this.dur_flag = false;
    }

    if (this.userInfo.pres_notes_flag == "0") {
      this.notes_flag = true;
    }

    if (this.dur_flag == true && this.notes_flag == true) {
      this.medstyle = true;
      this.medstyle1 = false;
      this.medstyle2 = false;
      this.medstyle3 = false;

    } else if (this.dur_flag == false && this.notes_flag == true) {
      this.medstyle = false;
      this.medstyle1 = true;
      this.medstyle2 = false;
      this.medstyle3 = false;

    } else if (this.dur_flag == true && this.notes_flag == false) {
      this.medstyle = false;
      this.medstyle1 = false;
      this.medstyle2 = true;
      this.medstyle3 = false;

    } else {
      this.medstyle = false;
      this.medstyle1 = false;
      this.medstyle2 = false;
      this.medstyle3 = true;
    }
  }

  getPrescriptionDetails(url) {
    FrontDesk_Helper.setfrontdeskprescriptiondetails(null)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + url,
      JSON.stringify(
        this.sendPresIdData
      ),
      { headers: headers }).subscribe(
        data => {
          this.prescriptionFlag = true;
          var obj = JSON.parse(data["_body"]);
          console.log("PRES DETAILS " + JSON.stringify(obj))
          if (obj != null && obj.length != 0) {
            this.age = obj.age;
            if (obj.gender != undefined) {
              this.gender = encrypt_decript.Decript(obj.gender);
            }

            if (obj.print_template != undefined) {
              this.printTemplate = obj.print_template;
            } else {
              this.printTemplate = "noheader";
            }
            this.language = obj.language;
            this.investigation = obj.oberse_med;
            this.licenceCode = obj.licence_code;
            this.mobile = encrypt_decript.Decript(obj.mobile);

            if (obj.instructions != undefined && obj.instructions != "" && obj.instructions != null) {
              this.instructionFlag = false;
              this.instructions = this.sanitizer.bypassSecurityTrustHtml(obj.instructions);
            } else {
              this.instructionFlag = true;
              this.instructions = "";
            }

            if (obj.first_name != null && obj.first_name != undefined) {
              if (obj.middle_name != null && obj.middle_name != "") {
                this.doctorName = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
              } else {
                this.doctorName = obj.first_name + ' ' + obj.last_name;
              }
            }

            if (obj.qualification != null) {
              this.doctorQualification = obj.qualification;
            }

            if (obj.cl_middle_name != null && obj.cl_middle_name != "" && encrypt_decript.Decript(obj.cl_middle_name) != undefined) {
              this.patientName = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            } else {
              this.patientName = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            }
            if (obj.salutation != undefined) {
              this.patientName = obj.salutation + ". " + this.patientName
            }
            if (obj.doc_reg_id != null) {
              this.docRegId = obj.doc_reg_id;
            }

            if (obj.hptl_pres_logo != undefined) {
              this.hptlPresLogo = ipaddress.Ip_with_img_address + obj.hptl_pres_logo;
            }
            if (obj.bg_image != undefined) {
              FrontDesk_Helper.setbgimage(null)
              FrontDesk_Helper.setbgimage(obj.bg_image)
            }
            
            if(obj.complaints != undefined){
              this.complaints = obj.complaints;
            }

            this.hospLogo = obj.hosp_logo != undefined ? ipaddress.Ip_with_img_address + obj.hosp_logo : "assets/imgs/hospital.png";

            if ((obj.hptl_name != null && obj.hptl_name != undefined) && (obj.address1 != null && obj.address1 != undefined)) {
              this.hospitalFlag = false;
              if (obj.hptl_name != null) {
                this.hptlName = obj.hptl_name;
              }

              if ((obj.address1 != null && obj.address1 != undefined) && (obj.address2 != null && obj.address2 != undefined)) {
                this.address = (obj.address1.toString()) + ' ' + (obj.address2.toString() + ', ');
              } else {
                if (obj.address1 != null && obj.address1 != undefined) {
                  this.address = (obj.address1.toString()) + ', ';
                }
              }

              if (obj.location != null) {
                this.doctorLocation = obj.location;
              }

              if (obj.city != null && obj.zipcode != null) {
                this.doctorCity = obj.city + " - " + obj.zipcode;
              }

              if (obj.state != null) {
                this.doctorState = obj.state;
              }

              if (obj.country != null) {
                this.doctorCountry = obj.country;
              }

              if (obj.telephone != null) {
                this.doctorTelephone = obj.telephone;
              }
            } else {
              this.hospitalFlag = true;
            }

            if (obj.pharmacy_id != null) // Suggested Pharmacy
            {
              this.pharmacyFlag = true;
              if (obj.pharmacy_id != null) {
                this.pharmaId = obj.pharmacy_id;
              }
              if (obj.pharmacy_name != null) {
                this.pharmacyName = obj.pharmacy_name;
                if (Helper_Class.getapp_flow() == "order") {
                  this.hasPharmaFlag = true;
                }
              } else {
                this.hasPharmaFlag = false;
              }

              if (obj.par_address1 != null) {
                this.pharmacyAddr1 = obj.par_address1;
              }

              if (obj.par_address2 != null) {
                this.pharmacyAddr2 = obj.par_address2;
              }

              if (obj.par_location != null) {
                this.pharmacyLocation = obj.par_location;
              }

              if (obj.par_city != null) {
                this.pharmacyCity = obj.par_city;
              }

              if (obj.par_zipcode != null) {
                this.pharmacyZipcode = obj.par_zipcode;
              }

              if (obj.par_state != null) {
                this.pharmacyState = obj.par_state;
              }

              if (obj.par_country != null) {
                this.pharmacyCountry = obj.par_country;
              }

              if (obj.par_telephone != null) {
                this.telephone = obj.par_telephone;
              }

              if (obj.phar_logo != undefined) {
                this.pharmacyLogo = ipaddress.Ip_with_img_address + obj.hosp_logo;
              } else {
                this.pharmacyLogo = "assets/imgs/hospital.png";
              }

              this.pharmacyDetailList.push({
                pharmacy_name: this.pharmacyName,
                phar_addr1: this.pharmacyAddr1,
                phar_addr2: this.pharmacyAddr2,
                phar_loc: this.pharmacyLocation,
                phar_city: this.pharmacyCity,
                phar_zipcode: this.pharmacyZipcode,
                phar_state: this.pharmacyState,
                phar_cnty: this.pharmacyCountry,
                telephone: this.telephone,
                phar_logo: this.pharmacyLogo
              })
            }

            if (obj.store_pickup != null || (obj.store_pickup != null && obj.store_pickup == "1")) {
              this.storePickUpFlag = true;
              this.delopt = true;
            }

            if (obj.home_delivery != null || (obj.home_delivery != null && obj.home_delivery == "1")) {
              this.homeDeliveryFlag = true;
              this.delopt = true;
            }

            if (obj.store_pickup_validity_days != null) {
              this.validityDays = obj.store_pickup_validity_days;
            }

            if (obj.pres_date != undefined) {
              this.prescriptionDate = obj.pres_date.split('-')[2] + "-" + obj.pres_date.split('-')[1] + "-" + obj.pres_date.split('-')[0];
            }

            this.diseaseName = obj.disease;
            this.diseaseDescription = obj.dis_desc;

            if (obj.sub_rel_id != null) {
              this.subRelId = obj.sub_rel_id;
            }

            if (obj.relation_id != null) {
              this.relationId = obj.relation_id;
            }

            if (obj.relationship_name != null) {
              this.relationName = obj.relationship_name;
            }

            if (obj.available_from != null) {
              this.workTime = "Timings : " + obj.available_from + " - " + obj.available_to;
            }

            if (obj.blood_pressure != undefined && obj.blood_pressure != "") {
              this.clntBp = obj.blood_pressure;
              this.bpFlag = true;
            } else {
              this.bpFlag = false;
            }
            if (obj.apptype != undefined && obj.apptype != "") {
              this.apptype = obj.apptype;
            }
            if (obj.clntUHID != undefined && obj.clntUHID != "") {
              this.clntUHID = obj.clntUHID;
            }

            if (obj.clntPr != undefined && obj.clntPr != "") {
              this.clntPr = obj.clntPr;
              this.prFlag = true;
            } else {
              this.prFlag = false;
            }

            if (obj.clntSpo2 != undefined && obj.clntSpo2 != "") {
              this.clntSpo2 = obj.clntSpo2;
              this.spo2Flag = true;
            } else {
              this.spo2Flag = false;
            }

            if (obj.cvs != undefined && obj.cvs.trim() != "" && obj.cvs != null && (obj.cvs.trim()).length != 0) {
              this.clntCvs = obj.cvs;
              this.orthoFlag = true;
            } else {
              this.orthoFlag = false;
            }

            if (obj.kidney != undefined && obj.kidney != "") {
              this.clntKidney = obj.kidney;
              this.orthoFlag = true;
            } else {
              this.orthoFlag = false;
            }

            if (obj.rs != undefined && obj.rs != "") {
              this.clntRs = obj.rs;
              this.orthoFlag = true;
            } else {
              this.orthoFlag = false;
            }

            if (obj.pft != undefined && obj.pft != "") {
              this.pft = obj.pft;
              this.pulmFlag = true;
            } else {
              this.pulmFlag = false;
            }

            if (obj.height != undefined && obj.height != null && obj.height != "") {
              this.height = obj.height + obj.height_measure;
              this.heightFlag = true;
            } else {
              this.heightFlag = false;
            }
            if (obj.weight != undefined && obj.weight != null && obj.weight != "") {
              this.weight = obj.weight + obj.weight_measure;
              this.weightFlag = true;
            } else {
              this.weightFlag = false;
            }
            if (obj.temparature != undefined && obj.temparature != null) {
              this.temparature = obj.temparature;
              this.temparatureFlag = true;
            } else {
              this.temparatureFlag = false;
            }

            if (obj.treatment != undefined && obj.treatment != "") {
              this.treatment = this.sanitizer.bypassSecurityTrustHtml(obj.treatment);
              this.treatFlag = true;
            } else {
              this.treatFlag = false;
            }

            this.diagnosis = obj.disease;
            if (obj.next_visit != undefined) {
              var nextdate = obj.next_visit.split('-');
              this.nextVisit = nextdate[2] + "-" + nextdate[1] + "-" + nextdate[0];
            }

            for (var i = 0; i < obj.drug_list.length; i++) {
              if (obj.drug_list[i].show_short_form == "1") {
                var shortform = obj.drug_list[i].short_form;
                if (obj.drug_list[i].intake == "1") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - 0 - 0";
                } else if (obj.drug_list[i].intake == "2") {
                  this.everySix = "0 - " + obj.drug_list[i].afternoon + shortform + " - 0";
                } else if (obj.drug_list[i].intake == "3") {
                  this.everySix = "0 - 0 - " + obj.drug_list[i].night + shortform;
                } else if (obj.drug_list[i].intake == "4") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - 0";
                } else if (obj.drug_list[i].intake == "5") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - 0 - " + obj.drug_list[i].night + shortform;

                } else if (obj.drug_list[i].intake == "6") {
                  this.everySix = "0 - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].night + shortform;

                } else if (obj.drug_list[i].intake == "7") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].night + shortform;

                } else if (obj.drug_list[i].intake == "8") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;

                } else if (obj.drug_list[i].intake == "19") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;

                } else if (obj.drug_list[i].intake == "9") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - Every 6 hours";

                } else if (obj.drug_list[i].intake == "10") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - Every 8 hours";

                } else if (obj.drug_list[i].intake == "11") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - Every 12 hours ";

                } else if (obj.drug_list[i].intake == "12") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - Once a day";

                } else if (obj.drug_list[i].intake == "13") {
                  this.everySix = obj.drug_list[i].morning + shortform + " SOS - if required";

                } else if (obj.drug_list[i].intake == "14") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform + " Alternative days";

                } else if (obj.drug_list[i].intake == "15") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform + " Once a week";

                } else if (obj.drug_list[i].intake == "20") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform + " twice a weeks";

                } else if (obj.drug_list[i].intake == "16") {
                  this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform + " Once in two weeks";

                } else if (obj.drug_list[i].intake == "17") {
                  this.everySix = obj.drug_list[i].morning + shortform + " STAT";
                }

                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.duration = obj.drug_list[i].time_duration + " " + "mins";

                } else {
                  this.duration = "";
                  this.durationFlag = true;
                }

                var frequency;
                if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                  frequency = this.intake;

                } else {
                  frequency = "Daily";
                }

                this.listProducts.push({
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  short_name: obj.drug_list[i].short_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].period,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.everySix,
                  dure_txt_table: this.duration,
                  time_duration: obj.drug_list[i].time_duration,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  remarks: obj.drug_list[i].remarks

                });

              } else {
                if (obj.drug_list[i].intake == "1") {
                  this.everySix = obj.drug_list[i].morning + " - 0 - 0";

                } else if (obj.drug_list[i].intake == "2") {
                  this.everySix = "0 - " + obj.drug_list[i].afternoon + " - 0";

                } else if (obj.drug_list[i].intake == "3") {
                  this.everySix = "0 - 0 - " + obj.drug_list[i].night;

                } else if (obj.drug_list[i].intake == "4") {
                  this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - 0";

                } else if (obj.drug_list[i].intake == "5") {
                  this.everySix = obj.drug_list[i].morning + " - 0 - " + obj.drug_list[i].night;

                } else if (obj.drug_list[i].intake == "6") {
                  this.everySix = "0 - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].night;

                } else if (obj.drug_list[i].intake == "7") {
                  this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].night;

                } else if (obj.drug_list[i].intake == "8") {
                  this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                } else if (obj.drug_list[i].intake == "19") {
                  this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                } else if (obj.drug_list[i].intake == "9") {
                  this.everySix = obj.drug_list[i].morning + " - Every 6 hours";

                } else if (obj.drug_list[i].intake == "10") {
                  this.everySix = obj.drug_list[i].morning + " - Every 8 hours";

                } else if (obj.drug_list[i].intake == "11") {
                  this.everySix = obj.drug_list[i].morning + " - Every 12 hours";

                } else if (obj.drug_list[i].intake == "12") {
                  this.everySix = obj.drug_list[i].morning + " - Once a day";

                } else if (obj.drug_list[i].intake == "13") {
                  this.everySix = obj.drug_list[i].morning + " SOS - if required";

                } else if (obj.drug_list[i].intake == "14") {
                  this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + " Alternative days";

                } else if (obj.drug_list[i].intake == "15") {
                  this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + " Once a week";

                } else if (obj.drug_list[i].intake == "20") {
                  this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + " Twice a week";

                } else if (obj.drug_list[i].intake == "16") {
                  this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + " Once in two weeks";

                } else if (obj.drug_list[i].intake == "17") {
                  this.everySix = obj.drug_list[i].morning + " STAT";
                }

                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.duration = obj.drug_list[i].time_duration + " " + "mins";

                } else {
                  this.duration = "";
                  this.durationFlag = true;
                }

                var frequency;
                if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                  frequency = this.intake;

                } else {
                  frequency = "Daily";
                }

                this.listProducts.push({
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  short_name: obj.drug_list[i].short_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].period,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.everySix,
                  dure_txt_table: this.duration,
                  time_duration: obj.drug_list[i].time_duration,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  remarks: obj.drug_list[i].remarks
                });
              }
            }

            if (this.balanceQuantity == 0) {
              this.hasPharmaFlag = false; // Normal flow
            }

            if (Helper_Class.getapp_flow() != null && this.balanceQuantity == 0) {
              if (Helper_Class.getapp_flow() == "order") {
                this.hasPharmaFlag = true;

              }
            }
          }
        },
        error => { });
  }

  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  print() {
    let printContents, popupWin;
    if (this.printTemplate != undefined && this.printTemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML;
    } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML;
    } else if (this.printTemplate != undefined && this.printTemplate == "fourcolumnnoheader") {
      printContents = this.fourcolumnnoheader.nativeElement.innerHTML;

    } else if (this.printTemplate != undefined && this.printTemplate == "bannerfullpage") {
      printContents = this.printbannerfullpage.nativeElement.innerHTML;

    } else if (this.printTemplate != undefined && this.printTemplate == "noheaderwithname") {
      printContents = this.printnoheaderwithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }
   
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Prescription</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        .casesheet_container {
          width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`
    );
    popupWin.document.close();
  }

  back() {
    this.frontdeskservice.sendMessage("prescriptionlist");
  }


  // exportPDF() {
  //   let pdfTable1 
  //   pdfTable1 = this.pdfTable.nativeElement.innerHTML;
  //   var html = htmlToPdfmake(pdfTable1);
  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).download(); 

  // }

 
  exportPDF() {
    let printContents
    if (this.printTemplate != undefined && this.printTemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML;
    } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML;
    } else if (this.printTemplate != undefined && this.printTemplate == "fourcolumnnoheader") {
      printContents = this.fourcolumnnoheader.nativeElement.innerHTML;

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }

    var html = htmlToPdfmake(printContents);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download();
  }

  // public downloadAsPdf(): void {
  //   let printContents
  //   if (this.printTemplate != undefined && this.printTemplate == "banner") {
  //     printContents = this.printbanner.nativeElement.innerHTML;
  //   } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
  //     printContents = this.printlogowithname.nativeElement.innerHTML;
  //   } else if (this.printTemplate != undefined && this.printTemplate == "fourcolumnnoheader") {
  //     printContents = this.fourcolumnnoheader.nativeElement.innerHTML;

  //   } else {
  //     printContents = this.printnoheader.nativeElement.innerHTML;
  //   }


  //   const width = 40;
  //   const height = 40 + 40;
  //   let orientation = '';
  //   let imageUnit = 'pt';
  //   if (width > height) {
  //     orientation = 'l';
  //   } else {
  //     orientation = 'p';
  //   }
  //   domToImage
  //     .toPng(printContents.nativeElement, {
  //       width: width,
  //       height: height
  //     })
  //     .then(result => {
  //       let jsPdfOptions = {
  //         orientation: orientation,
  //         unit: imageUnit,
  //         format: [width + 50, height + 220]
  //       };
  //       const pdf = new jsPDF();
  //       pdf.setFontSize(48);
  //       pdf.setTextColor('#2585fe');
  //       // pdf.text(this.pdfName.value ? this.pdfName.value.toUpperCase() : 'Untitled dashboard'.toUpperCase(), 25, 75);
  //       pdf.setFontSize(24);
  //       pdf.setTextColor('#131523');
  //       pdf.text('Report date: ' + moment().format('ll'), 25, 115);
  //       pdf.addImage(result, 'PNG', 25, 185, width, height);
  //       pdf.save('file_name' + '.pdf');
  //     })
  //     .catch(error => {
  //     });
  // }

//   toPdf() {
//     const dashboard = document.getElementById('printbanner');

//     const dashboardHeight = dashboard.clientHeight;
//     const dashboardWidth = dashboard.clientWidth;
//     const options = { background: 'white', width: dashboardWidth, height: dashboardHeight };

//     domToImage.toPng(dashboard, options).then((imgData) => {
//          const doc = new jsPDF(dashboardWidth > dashboardHeight ? 'l' : 'p', 'mm', [dashboardWidth, dashboardHeight]);
//          const imgProps = doc.getImageProperties(imgData);
//          const pdfWidth = doc.internal.pageSize.getWidth();
//          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//          doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
//          doc.save('Dashboard for hyperpanels.pdf');
//     });
// }

generatePDF() {
  const pdf = new jsPDF();

  let printContents, popupWin;
    if (this.printTemplate != undefined && this.printTemplate == "banner") {
      printContents = this.printbanner.nativeElement;
    } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
      printContents = this.printlogowithname.nativeElement;
    } else if (this.printTemplate != undefined && this.printTemplate == "fourcolumnnoheader") {
      printContents = this.fourcolumnnoheader.nativeElement;

    } else {
      printContents = this.printnoheader.nativeElement;
    }

  const content = this.printbanner.nativeElement;

  html2canvas(content, {allowTaint:true, useCORS: false} ).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = 210; // A4 width in mm
    const pageHeight = (canvas.height * imgWidth) / canvas.width;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    let heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save('generated-pdf.pdf');
  });
}

}
