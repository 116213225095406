import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharmapurchaserequest',
  templateUrl: './pharmapurchaserequest.component.html',
  styleUrls: ['./pharmapurchaserequest.component.css']
})
export class PharmapurchaserequestComponent implements OnInit {
  public getData;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  productID: any;
  productArray: any;
  allow: boolean;
  pharmacyLocationID: any;
  productTypeList: any;
  vendorID: any;
  pharmacyVendorArray: any = [];
  pharmacyLocationArray: any;
  pharmauserID: any;
  public vendor: string;
  public HSNNumber: string;
  public productName: string;
  public uom: string;
  public pharmacyLocation: string;
  public quantity: any;
  public purchaseReq: any = [];
  pharmacyID: string;
  pharmacistID: string;
  public productType: string;
  public prodType: string;
  public purchaseIndex = this.purchaseReq.length;
  public saveFlag: boolean = true;

  constructor(public toastr: ToastrService, public messageservice: PharmacyService, public gservice: CommonDataService, public http: Http, public router: Router) {
  }

  ngOnInit(): void {
    this.getData = Helper_Class.getInfo();
    this.pharmacistID = this.getData.user_id;
    for (var i = 0; i < this.getData.hospitals.length; i++) {
      this.pharmacyID = this.getData.hospitals[0].hptl_clinic_id;
    }

    this.getPharmacy();
    this.getVendor();
    this.getType();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],

      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by vendor, product, or location"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }

  purchaseRequest() {
    if (this.vendor == undefined || this.vendor == "" || this.productName == undefined || this.productName == "" || this.pharmacyLocation == undefined || this.pharmacyLocation == "" || this.quantity == undefined || this.quantity == "") {
      this.toastr.error(Message_data.mandatory);
    } else {
      if (this.purchaseIndex != 0) {
        this.allow = true;
      } else {
        this.allow = false;
      }
      for (var i = 0; i < this.pharmacyVendorArray.length; i++) {
        if (this.vendor == this.pharmacyVendorArray.name) {
          this.vendorID = this.pharmacyVendorArray[i].supp_id;
        }
      }
      this.purchaseReq.push({
        Index: this.purchaseIndex,
        mfg_id: this.vendorID,
        mfg_name: this.vendor,
        hsn_no: this.HSNNumber,
        product_id: this.productID,
        quantity: this.quantity,
        product_name: this.productName,
        uom: this.uom,
        location: this.pharmacyLocationID,
        loc_txt: this.pharmacyLocation,
        prod_supp_id: this.vendorID,
      });
      this.purchaseIndex++;
      this.HSNNumber = "";
      this.quantity = "";
      this.uom = "";
      this.productName = "";
      if (this.purchaseIndex != 0) {
        this.allow = true;
      } else {
        this.allow = false;
      }
    }
  }

  getPharmacy() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/",
      {
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.getData.user_id,
      },
      { headers: headers }).subscribe(
        data => {
          if (data.json().pharma_locations != null) {
            this.pharmacyLocationArray = data.json().pharma_locations;
          }
        },
        error => {
        }
      )
  }

  selected_vendor(vendor){
    this.vendor = vendor.name;
    this.vendorID = vendor.supp_id;
    this.pharmacyVendorArray=[];
  }

  getVendor() {
    if(this.vendor!= undefined && this.vendor.length >2){
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gsup/", JSON.stringify({ pharmacy_id: this.pharmacyID,supp_name:this.vendor }), { headers: headers }).subscribe(
        data => {
          if (data.json().supplier_details != null) {
            this.pharmacyVendorArray = data.json().supplier_details;
          }
        },
      )
    }
  }

  getProductDetails() {
    // this.pharmacyVendorArray.forEach(element => {
    //   element.supp_id;
    //   if (element.name == vendor) {
    //     this.vendorID = element.supp_id
    //   }
    // });
    // this.pharmacyVendorArray=[];
    if (this.productName != undefined && this.productName.length > 2) {
      var data_val = {
        pharmacy_id: this.pharmacyID,
        prod_type: this.productType,
        prod_name: this.productName,
        supp_id: this.vendorID,
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gpcbypharn/",
        JSON.stringify(data_val), { headers: headers }).subscribe(
          data => {
            //var dataval = JSON.parse(JSON.stringify(data));
            if (data.json().products != null) {
              this.productArray = data.json().products;
            }
          },
        )
    }
  }

  clearProdcode() {
    this.HSNNumber = "";
    this.productArray = [];
  }

  clearProdName() {
    this.productName = "";
    this.productArray = [];
  }

  getType() {
    this.http.get(ipaddress.getIp + "pharmacontrol/mtypes/").subscribe(
      data => {
        if (data.json().med_details != null) {
          this.productTypeList = data.json().med_details;
        }
      },
      error => {
      }
    )
  }

  getProductName(data) {
    this.productName = data.name;
    this.uom = data.uom_desc;
    this.productID = data.product_id;
    this.HSNNumber = data.hsn_no;
    this.productArray = [];
  }

  requestSave() {
    this.saveFlag = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var data_value_save = {
      products: this.purchaseReq,
      pharmacist_id: this.pharmacistID,
      pharmacy_id: this.pharmacyID,
      country: 91
    }
    this.http.post(ipaddress.getIp + "pharmacontrol/spr/",
      JSON.stringify(data_value_save), { headers: headers }).subscribe(
        data => {
          var obj = data.json();

          if (obj != undefined && obj.key == "1") {
            this.saveFlag = false;
            this.toastr.success(Message_data.purchaseReqCreatedSuccess);
            this.messageservice.sendMessage("pharmacycurentinformation");
          } else {
            this.saveFlag = true;
            this.toastr.error(Message_data.unableToSave);
          }
        }, error => { this.saveFlag = true; });
  }

  edit(data) {
    this.purchaseReq.splice(data, 1);
  }

  backNav() {
    this.messageservice.sendMessage("pharmacyhome");
  }
}
