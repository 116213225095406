import { Component, OnInit,HostListener, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Http, Headers, JsonpModule } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { jsonpFactory } from '@angular/http/src/http_module';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { flatten } from '@angular/compiler';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-complication',
  templateUrl: './complication.component.html',
  styleUrls: ['./complication.component.css']
})
export class ComplicationComponent implements OnInit {
  @Input() diabCompFlag = "min";
  public complicatioflagmin:boolean=true
  subscription:Subscription;
  public Cardiovascular_array = [];
  public cadio_array1=[];
  public Nephropathy_array1=[];
  public Neuropathy_array1=[];
  public skin_array1=[];
  public mobility_array1=[];
  public Retinopathy_arry1 = [];
  public hearing_array1=[];

  public Neuropathy_array = [];
  public clinic_histhidden:boolean;

  public Nephropathy_array = [];
  public Retinopathy_arry = [];
  public medhist_flag1:boolean=false;
  public neuro_flag:boolean=false;
  public neph_flag:boolean=false;
  public footflag :boolean=false;
  public skinflag :boolean=false;
  public ret_flag :boolean=false;
  public hearflag :boolean=false;
  public mobi_flag :boolean=false;

  public Foot_arry = [];
  public Skin_arry = [];
  public Hearing_arry = [];
  public Mobile_arry = [];
  public Cardio_check = [];
  public Neuro_check = [];
  public Retino_check = [];
  public Foot_check = [];
  public Foot_arry1=[];
  public skin_Check_nephlist = [];
  public hear_Check_nephlist = [];
  public Mobility_check = [];
  public Medical_Check_nephlist = [];
  public family;
  public Neuropathy;
  public cardio_label;
  public neuropathy_label;
  public nephropathy_label;
  public retinopathy_label;
  public footdamage_label;
  public skincon_label;
  public hearing_label;
  public mobility_label;
  public cardio_txt;
  public nephropathy;
  public retino_pathy;
  public foot_damage;
  public skin_txt;
  public hearing_txt;
  public mobility_txt;
  public personaldata;
  public diab_case_hist_id;
  public life_style_id;
  public client:boolean = false;

  constructor(public dialog:MatDialog,public toastr:ToastrService,public http:Http,public messageservice:CasesheetService
    ,public masterCSdata:MasterCSHelperService) { }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.diabCompFlag = message;
      } 
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }
    
    var comp_help=Helper_Class.getHopitalfieldsRet();
    for(var i = 0; i < comp_help.length; i++){
      if(comp_help[i].page_name=="Complications"){
        if(comp_help[i].fields!=undefined){
          if(comp_help[i].fields!=undefined){
            for(var j=0; j<comp_help[i].fields.length;j++){
              if(comp_help[i].fields[j].field_name=="Cardiovascular"){
                this.cardio_label=comp_help[i].fields[j].field_name;
              }
              if(comp_help[i].fields[j].field_name=="Neuropathy"){
                this.neuropathy_label=comp_help[i].fields[j].field_name;
              }
              if(comp_help[i].fields[j].field_name=="Nephropathy"){
                this.nephropathy_label=comp_help[i].fields[j].field_name;
              }
              if(comp_help[i].fields[j].field_name=="Retinopathy"){
                this.retinopathy_label=comp_help[i].fields[j].field_name;
              }
              if(comp_help[i].fields[j].field_name=="Foot damage"){
                this.footdamage_label=comp_help[i].fields[j].field_name;
              }
              if(comp_help[i].fields[j].field_name=="Skin conditions"){
                this.skincon_label=comp_help[i].fields[j].field_name;
              }
              if(comp_help[i].fields[j].field_name=="Hearing"){
                this.hearing_label=comp_help[i].fields[j].field_name;
              }
              if(comp_help[i].fields[j].field_name=="Mobility concerns"){
                this.mobility_label=comp_help[i].fields[j].field_name;
              }
            }
          }
        }
      }
    }
    if(masterCSData_Helper.getMasterCardiovasular() == undefined){
      await this.masterCSdata.getCardiovascular();
      this.get_Cardiovascular();
    }else{
      this.get_Cardiovascular();
    }

    if(masterCSData_Helper.getMasterNeuro() == undefined){
      await this.masterCSdata.getNeuropathy();
      this.get_Neuropathy();
    }else{
      this.get_Neuropathy();
    }
  }
  
  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();

  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }

  send_data() {
    var compli_data =null;
    var card_flg;
    var neuro_flg;
    var neph_flg;
    var retino_flg;
    var foot_flg;
    var skin_flag;
    var hear_flag;
    var mobility_flg;
    card_flg = this.Cardio_check.length != 0 ? "yes" : "no";
    neuro_flg = this.Neuro_check.length != 0 ? "yes" : "no";
    neph_flg = this.Medical_Check_nephlist.length != 0 ? "yes" : "no";
    retino_flg = this.Retino_check.length != 0 ? "yes" : "no";
    foot_flg = this.Foot_check.length != 0 ? "yes" : "no";
    skin_flag = this.skin_Check_nephlist.length != 0 ? "yes" : "no";
    hear_flag = this.hear_Check_nephlist.length != 0 ? "yes" : "no";
    mobility_flg = this.Mobility_check.length != 0 ? "yes" : "no";

    compli_data = {
      mobility_comp: this.Mobility_check,
      hear_comp: this.hear_Check_nephlist,
      skin_comp: this.skin_Check_nephlist,
      foot_comp: this.Foot_check,
      retino_comp: this.Retino_check,
      neprho_comp: this.Medical_Check_nephlist,
      neuro_comp: this.Neuro_check,
      cardio_comp: this.Cardio_check,
      cardio_flag: card_flg,
      neuro_flag: neuro_flg,
      neprho_flag:neph_flg,
      retino_flag:retino_flg,
      foot_flag:foot_flg,
      skin_flag:skin_flag,
      hear_flag:hear_flag,
      mobility_flag:mobility_flg
    }
    Helper_Class.setcomplication(compli_data);
  }

  medical_popup(){   
    if(this.client == false){
      for (var j = 0; j < this.Cardiovascular_array.length; j++) {
        this.Cardiovascular_array[j].checked = false;
      }
 
      if (this.Cardio_check != undefined && this.Cardio_check.length != 0) {
        for (var i = 0; i < this.Cardio_check.length; i++) {
          for (var j = 0; j < this.Cardiovascular_array.length; j++) {
            if (this.Cardio_check[i] == this.Cardiovascular_array[j].value) {
              this.Cardiovascular_array[j].checked = true;
            }
          }
        }
      }
      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Cardiovascular",
          main_array: this.Cardiovascular_array,
          selected_list:this.Cardio_check,
      }});
    
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
          this.cardio_click(result);
        }
      });
    }
  }
   
  neuro(){   
    if(this.client == false){
      for (var j = 0; j < this.Neuropathy_array.length; j++) {
        this.Neuropathy_array[j].checked = false;
      }
  
      if (this.Neuro_check != undefined && this.Neuro_check.length != 0) {
        for (var i = 0; i < this.Neuro_check.length; i++) {
          for (var j = 0; j < this.Neuropathy_array.length; j++) {
            if (this.Neuro_check[i] == this.Neuropathy_array[j].value) {
              this.Neuropathy_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Neuropathy",
          main_array: this.Neuropathy_array,
          selected_list:this.Neuro_check,
      }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
          this.neuro_click(result);
        }
      });
    }
  }

  neph(){   
    if(this.client == false){
      for (var j = 0; j < this.Nephropathy_array.length; j++) {
        this.Nephropathy_array[j].checked = false;
      }
 
      if (this.Medical_Check_nephlist != undefined && this.Medical_Check_nephlist.length != 0) {
        for (var i = 0; i < this.Medical_Check_nephlist.length; i++) {
          for (var j = 0; j < this.Nephropathy_array.length; j++) {
            if (this.Medical_Check_nephlist[i] == this.Nephropathy_array[j].value) {
              this.Nephropathy_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Nephropathy",
          main_array: this.Nephropathy_array,
          selected_list:this.Medical_Check_nephlist,
      }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
          this.medneph_click(result);
        }
      });
    }
  }

  skin(){
    if(this.client == false){
      for (var j = 0; j < this.Skin_arry.length; j++) {
        this.Skin_arry[j].checked = false;
      }
  
      if (this.skin_Check_nephlist != undefined && this.skin_Check_nephlist.length != 0) {
        for (var i = 0; i < this.skin_Check_nephlist.length; i++) {
          for (var j = 0; j < this.Skin_arry.length; j++) {
            if (this.skin_Check_nephlist[i] == this.Skin_arry[j].value) {
              this.Skin_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Skin Conditions",
          main_array: this.Skin_arry,
          selected_list:this.skin_Check_nephlist,
      }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
          this.skin_click(result);
        }
      });
    }
  }

  retino_pathy1(){
    if(this.client == false){
      for (var j = 0; j < this.Retinopathy_arry.length; j++) {
        this.Retinopathy_arry[j].checked = false;
      }
      if (this.Retinopathy_arry != undefined && this.Retino_check.length != 0) {
        for (var i = 0; i < this.Retino_check.length; i++) {
          for (var j = 0; j < this.Retinopathy_arry.length; j++) {
            if (this.Retino_check[i] == this.Retinopathy_arry[j].value) {
              this.Retinopathy_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Retinopathy",
          main_array: this.Retinopathy_arry,
          selected_list:this.Retino_check,
      }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
          this.ret_click(result);
        }
      });
    }
  }

  hear(){  
    if(this.client == false){
      for (var j = 0; j < this.Hearing_arry.length; j++) {
        this.Hearing_arry[j].checked = false;
      }

      if (this.hear_Check_nephlist != undefined && this.hear_Check_nephlist.length != 0) {
        for (var i = 0; i < this.hear_Check_nephlist.length; i++) {
          for (var j = 0; j < this.Hearing_arry.length; j++) {
            if (this.hear_Check_nephlist[i] == this.Hearing_arry[j].value) {
              this.Hearing_arry[j].checked = true;
            }
          }
        }
      }
      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Hearing",
          main_array: this.Hearing_arry,
          selected_list:this.hear_Check_nephlist,
      }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
          this.hear_click(result);
        }
      });
    }
  }

  mobi_flag1(){
    if(this.client == false){
      for (var j = 0; j < this.Mobile_arry.length; j++) {
        this.Mobile_arry[j].checked = false;
      }

      if (this.Mobility_check != undefined && this.Mobility_check.length != 0) {
        for (var i = 0; i < this.Mobility_check.length; i++) {
          for (var j = 0; j < this.Mobile_arry.length; j++) {
            if (this.Mobility_check[i] == this.Mobile_arry[j].value) {
              this.Mobile_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Mobility Concerns",
          main_array: this.Mobile_arry,
          selected_list:this.Mobility_check,
      }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
          this.mob_click(result);
        }
      });
    }
  }

  neuro_click(selected) {
    this.Neuropathy="";
    
    function StringBuilder(this: any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Neuro_check = selected;
    
    if (this.Neuro_check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Neuro_check.length; j++) {
        for (var i = 0; i < this.Neuropathy_array.length; i++) {
          if (this.Neuro_check[j] == this.Neuropathy_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Neuropathy_array[i].label);
          }
        }
      }
      this.Neuropathy = selected_data.toString();
    }
  }

  cardio_click(selected) {
    this.cardio_txt="";
    
    function StringBuilder(this:any , value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Cardio_check = selected;
    if (this.Cardio_check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Cardio_check.length; j++) {
        for (var i = 0; i < this.Cardiovascular_array.length; i++) {
          if (this.Cardio_check[j] == this.Cardiovascular_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Cardiovascular_array[i].label);
          }
        }
      }
      this.cardio_txt = selected_data.toString();
    }
  }

  medneph_click(selected) {
    this.nephropathy="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Medical_Check_nephlist = selected;
    
    if (this.Medical_Check_nephlist.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Medical_Check_nephlist.length; j++) {
        for (var i = 0; i < this.Nephropathy_array.length; i++) {
          if (this.Medical_Check_nephlist[j] == this.Nephropathy_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Nephropathy_array[i].label);
          }
        }
      }
      this.nephropathy = selected_data.toString();
    }
  }

  // Retino select 
  ret_click(selected) {
    this.retino_pathy="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Retino_check = selected;
    if (this.Retino_check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Retino_check.length; j++) {
        for (var i = 0; i < this.Retinopathy_arry.length; i++) {
          if (this.Retino_check[j] == this.Retinopathy_arry[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Retinopathy_arry[i].label);
          }
        }
      }
      this.retino_pathy = selected_data.toString();
    }
  }

  foot_click(selected) {
    this.foot_damage="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Foot_check = selected;
    if (this.Foot_arry.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Foot_check.length; j++) {
        for (var i = 0; i < this.Foot_arry.length; i++) {
          if (this.Foot_check[j] == this.Foot_arry[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Foot_arry[i].label);
          }
        }
      }
      this.foot_damage = selected_data.toString();
    }
  }

  // skin select
  skin_click(selected) {
    this.skin_txt="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.skin_Check_nephlist = selected;

    if (this.skin_Check_nephlist.length != 0) {
      var n = 0;
      for (var j = 0; j < this.skin_Check_nephlist.length; j++) {
        for (var i = 0; i < this.Skin_arry.length; i++) {
          if (this.skin_Check_nephlist[j] == this.Skin_arry[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Skin_arry[i].label);
          }
        }
      }
      this.skin_txt = selected_data.toString();
    }
  }

  // hearing select
  hear_click(selected) {
    this.hearing_txt="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.hear_Check_nephlist = selected;

    if (this.hear_Check_nephlist.length != 0) {
      var n = 0;
      for (var j = 0; j < this.hear_Check_nephlist.length; j++) {
        for (var i = 0; i < this.Hearing_arry.length; i++) {
          if (this.hear_Check_nephlist[j] == this.Hearing_arry[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Hearing_arry[i].label);
          }
        }
      }
      this.hearing_txt = selected_data.toString();
    }
  }

  // mobile select
  mob_click(selected) {
    // this.Mobility_check = selectedValue;
    this.mobility_txt="";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Mobility_check = selected;
    
    if (this.Mobility_check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.Mobility_check.length; j++) {
        for (var i = 0; i < this.Mobile_arry.length; i++) {
          if (this.Mobility_check[j] == this.Mobile_arry[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Mobile_arry[i].label);
          }
        }
      }
      this.mobility_txt = selected_data.toString();
    }
  }

  // Get Mobility concerns
  get_Mobility() {
    var obj = masterCSData_Helper.getMasterMobility();
    if(obj != undefined){
      if (obj.mobility_diseases != null) {
        for (var i = 0; i < obj.mobility_diseases.length; i++) {
          this.Mobile_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.mobility_diseases[i].mobility_dis_id,
            label: obj.mobility_diseases[i].description,
            checked: false
          });
        }
      }
    }
    this.Get_complication_data_retrivel();
  }

  // Get Hearing  data
  async get_Hearing() {
    var obj = masterCSData_Helper.getMasterHearing();
    if(obj != undefined){
      if (obj.hearing_diseases != null) {
        for (var i = 0; i < obj.hearing_diseases.length; i++) {
          this.Hearing_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.hearing_diseases[i].diab_hearing_id,
            label: obj.hearing_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterMobility() == undefined){
      await this.masterCSdata.getMobility();
      this.get_Mobility();
    }else{
      this.get_Mobility();
    }
  }

  // Get Skin  data
  async get_Skin() {
    var obj = masterCSData_Helper.getMasterSkin();
    if(obj != undefined){
      if (obj.skin_diseases != null) {
            for (var i = 0; i < obj.skin_diseases.length; i++) {
              this.Skin_arry.push({
                type: 'checkbox',
                // multiple:true,
                value: obj.skin_diseases[i].skin_dis_id,
                label: obj.skin_diseases[i].description,
                checked: false
              });
            }
      }
    }
    if(masterCSData_Helper.getMasterHearing() == undefined){
      await this.masterCSdata.getHearing();
      this.get_Hearing();
    }else{
      this.get_Hearing();
    }
  }

  async get_Foot() {// Get Foot data
    var obj = masterCSData_Helper.getMasterFoot();
    if(obj != undefined){
      if (obj.foot_diseases != null) {
        for (var i = 0; i < obj.foot_diseases.length; i++) {
          this.Foot_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.foot_diseases[i].foot_dis_id,
            label: obj.foot_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterSkin() == undefined){
      await this.masterCSdata.getSkin();
      this.get_Skin();
    }else{
      this.get_Skin();
    }
  }

  // Get Retinopathy data
  async get_Retinopathy() {
    var obj = masterCSData_Helper.getMasterRetino();
    if(obj != undefined){
      if (obj.eye_diseases != null) {
        for (var i = 0; i < obj.eye_diseases.length; i++) {
          this.Retinopathy_arry.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.eye_diseases[i].eye_dis_id,
            label: obj.eye_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterFoot() == undefined){
      await this.masterCSdata.getFoot();
      this.get_Foot();
    }else{
      this.get_Foot();
    }
  }

  //Get Neuropathy data
  async get_Nephropathy() {
    var obj = masterCSData_Helper.getMasterNephro();
    if(obj != undefined){
      if (obj.nephro_diseases != null) {
        for (var i = 0; i < obj.nephro_diseases.length; i++) {
          this.Nephropathy_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.nephro_diseases[i].nephro_id,
            label: obj.nephro_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterRetino() == undefined){
      await this.masterCSdata.getRetinopathy();
      this.get_Retinopathy();
    }else{
      this.get_Retinopathy();
    }
  }

  footdamage(){
    if(this.client == false){
      for (var j = 0; j < this.Foot_arry.length; j++) {
        this.Foot_arry[j].checked = false;
      }
 
      if (this.Foot_check != undefined && this.Foot_check.length != 0) {
        for (var i = 0; i < this.Foot_check.length; i++) {
          for (var j = 0; j < this.Foot_arry.length; j++) {
            if (this.Foot_check[i] == this.Foot_arry[j].value) {
              this.Foot_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Foot Damage",
          main_array: this.Foot_arry,
          selected_list:this.Foot_check,
      }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
          this.foot_click(result);
        }
      });
    }
  }

  //Get Neuropathy data
  async get_Neuropathy() {
    var obj = masterCSData_Helper.getMasterNeuro();
    if(obj != undefined){
      if (obj.neuro_diseases != null) {
        for (var i = 0; i < obj.neuro_diseases.length; i++) {
          this.Neuropathy_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.neuro_diseases[i].neuro_id,
            label: obj.neuro_diseases[i].description,
            checked: false
          });
        }
        if(masterCSData_Helper.getMasterNephro() == undefined){
          await this.masterCSdata.getNephropathy();
          this.get_Nephropathy();
        }else{
          this.get_Nephropathy();
        }
      }
    }

  }

  //Get Cardiovascular data
  get_Cardiovascular() {
    var obj = masterCSData_Helper.getMasterCardiovasular();
    if(obj != undefined){
      if (obj.cardio_diseases != null) {
        for (var i = 0; i < obj.cardio_diseases.length; i++) {
          this.Cardiovascular_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.cardio_diseases[i].cardio_id,
            label: obj.cardio_diseases[i].description,
            checked: false
          });
        }
      }
    }
  }
  
  //Get complication
  Get_complication_data_retrivel() {
    var casesheet_case_hist_id;
    this.family=Helper_Class.getRet();
    if(this.family != undefined){
      if(this.family.case_hist_id !=null){
        casesheet_case_hist_id =this.family.case_hist_id;
      }
      if (this.family.life_style_id != null) {
        this.life_style_id = this.family.life_style_id;
      }
      
      if (this.family.neuro_dis != null 
        && this.family.neuro_dis!= undefined 
        && this.family.neuro_dis.length != 0) {
        for (var i = 0; i < this.family.neuro_dis.length; i++) {
          this.Neuro_check.push(this.family.neuro_dis[i].diab_neuro_dis_id);
          this.Neuropathy_array1.push(this.family.neuro_dis[i].diab_neuro_dis_id)
        }
        this.neuro_click(this.Neuro_check);
      }
      if (this.family.cardio_dis != null 
        && this.family.cardio_dis != undefined 
        && this.family.cardio_dis.length != 0) {
        for (var i = 0; i < this.family.cardio_dis.length; i++) {
          this.Cardio_check.push(this.family.cardio_dis[i].diab_cardio_dis_id);
          this.cadio_array1.push(this.family.cardio_dis[i].diab_cardio_dis_id)
        }
        this.cardio_click(this.Cardio_check);
      }
      if (this.family.neprho_dis != null 
        && this.family.neprho_dis != undefined 
        && this.family.neprho_dis.length != 0) {
        for (var i = 0; i < this.family.neprho_dis.length; i++) {
          this.Medical_Check_nephlist.push(this.family.neprho_dis[i].diab_nephro_dis_id)
          this.Nephropathy_array1.push(this.family.neprho_dis[i].diab_nephro_dis_id)
        }
        this.medneph_click(this.Medical_Check_nephlist)
      }
      if (this.family.retino_dis != null 
        && this.family.retino_dis != undefined 
        && this.family.retino_dis.length != 0) {
        for (var i = 0; i < this.family.retino_dis.length; i++) {
          this.Retino_check.push(this.family.retino_dis[i].diab_retino_dis_id);
          this.Retinopathy_arry1.push(this.family.retino_dis[i].diab_retino_dis_id)
        }
        this.ret_click(this.Retino_check)
      }
      if (this.family.foot_dis != null 
        && this.family.foot_dis != undefined 
        && this.family.foot_dis.length != 0) {
        for (var i = 0; i < this.family.foot_dis.length; i++) {
          this.Foot_check.push(this.family.foot_dis[i].diab_foot_dis_id);
          this.Foot_arry1.push(this.family.foot_dis[i].diab_foot_dis_id)
        }
        this.foot_click(this.Foot_check)
      }
      if (this.family.skin_dis != null 
        && this.family.skin_dis != undefined 
        && this.family.skin_dis.length != 0) {
        for (var i = 0; i < this.family.skin_dis.length; i++) {
          this.skin_Check_nephlist.push(this.family.skin_dis[i].diab_skin_dis_id);
          this.skin_array1.push(this.family.skin_dis[i].diab_skin_dis_id)
        }
        this.skin_click(this.skin_Check_nephlist)
      }
      if (this.family.mobility!= null 
        && this.family.mobility != undefined 
        && this.family.mobility.length != 0) {
        for (var i = 0; i < this.family.mobility.length; i++) {
          this.Mobility_check.push(this.family.mobility[i].diab_mobility_dis_id);
          this.mobility_array1.push(this.family.mobility[i].diab_mobility_dis_id);
        }
        this.mob_click(this.Mobility_check)
      }
      if (this.family.hearing_dis!= null 
        && this.family.hearing_dis != undefined 
        && this.family.hearing_dis.length != 0) {
        for (var i = 0; i < this.family.hearing_dis.length; i++) {
          this.hear_Check_nephlist.push(this.family.hearing_dis[i].diab_hearing_id);
          this.hearing_array1.push(this.family.hearing_dis[i].diab_hearing_id)
        }
        this.hear_click(this.hear_Check_nephlist)
      }
    }
  }

   //comlication history
  Complication_click() {}
  playvideo(data){}
  
  history(){ 
    this.personaldata="Complcate_hist"
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
        Diab_case_hist_id: this.family.case_hist_id,
    Life_style_id:  this.family.life_style_id,
    History: this.personaldata,
    }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
