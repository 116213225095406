import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Pediatric_Graph } from '../../../assets/js/Pedia_popup';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-pediatric-graph-popup',
  templateUrl: './pediatric-graph-popup.component.html',
  styleUrls: ['./pediatric-graph-popup.component.scss']
})
export class PediatricGraphPopupComponent implements OnInit {

  public vital_history_data=[];
  public life_style_id;
  public graph_id;
  public month_id;
  public header_text;
  public dob;
  public gender;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<PediatricGraphPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {       
      dialogRef.disableClose = true; 
      this.life_style_id=data.life_style;
      this.header_text="Vital Graph";
      this.dob = data.dob
      this.graph_id=data.graph_id;
      this.month_id=data.month;
      this.gender = data.gender;
      this.Get_Pediatric_Graph_Data(this.life_style_id,this.dob)

   }

  ngOnInit(): void {
  }

  Get_Pediatric_Graph_Data(lifes_ID, Clnt_DOB){
 
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pedia/vhist',
      JSON.stringify({
  
        life_style_id: lifes_ID
  
      }),
  
      { headers: headers })
      .subscribe(
        response => {
  
          var obj = JSON.parse(response["_body"]);
          var Datelist = new Array();
          var Height_Datelist = new Array();
          var BP_list = new Array();
          var Heart_Rate_list = new Array();
          var Respiratory_list = new Array();
          var Temparature_list = new Array();
          var Height_list = new Array();
          var HeightMeasure_List = new Array();
          var Weight_list = new Array();
          var WeightMeasure_List = new Array();
          var At_Birth_List = new Array();
          var Age_List = new Array();
          
          if (obj.vital_history != null && obj.vital_history.length != 0) {
             
              for (var i = 0; i <obj.vital_history.length; i++) {
                  if (obj.vital_history[i].vital_created_date != null) {
                      this.vital_history_data.push({
                          blood_pres: obj.vital_history[i].blood_pressure,
                          respi_rate: obj.vital_history[i].respiratory_rate,
                          created_date: obj.vital_history[i].vital_created_date,
                      });
  
                      if (this.vital_history_data.length != 0) {
                        obj.vital_histbtn = true;
  
                      } else {
                        obj.vital_histbtn = false;
                      }
                      Datelist.push(obj.vital_history[i].vital_created_date.toString());
  
                      if (obj.vital_history[i].heart_rate != null) {
                          Heart_Rate_list.push(obj.vital_history[i].heart_rate.toString());
                      }
                      else {
                          Heart_Rate_list.push("");
                      }
  
                      if (obj.vital_history[i].temparature != null) {
                          Temparature_list.push(obj.vital_history[i].temparature.toString());
                      }
                      else {
                          Temparature_list.push("");
                      }
  
                      if (obj.vital_history[i].blood_pressure != null) {
                          BP_list.push(obj.vital_history[i].blood_pressure.toString());
                      }
                      else {
                          BP_list.push("-");
                      }
  
                      if (obj.vital_history[i].respiratory_rate != null) {
                          Respiratory_list.push(obj.vital_history[i].respiratory_rate.toString());
                      }
                      else {
                          Respiratory_list.push("-");
                      }
                  }
  
                  if (obj.vital_history[i].hw_created_date != null) {
                      Height_Datelist.push(obj.vital_history[i].hw_created_date.toString());
  
                      if (obj.vital_history[i].height_measure.toString() == "inch") {
                          Height_list.push((parseInt(obj.vital_history[i].height.toString()) * 100));
                          HeightMeasure_List.push(obj.vital_history[i].height_measure.toString());
                      }
                      else {
                          Height_list.push(obj.vital_history[i].height.toString());
                          HeightMeasure_List.push(obj.vital_history[i].height_measure.toString());
                      }
  
                      if (obj.vital_history[i].weight_measure.toString() == "lbs") {
                          Weight_list.push(Math.round(parseFloat((obj.vital_history[i].weight.toString() / 2.20462, 2).toString())));
                          WeightMeasure_List.push(obj.vital_history[i].weight_measure.toString());
                      }
                      else {
                          Weight_list.push(obj.vital_history[i].weight.toString());
                          WeightMeasure_List.push(obj.vital_history[i].weight_measure.toString());
                      }
  
                      At_Birth_List.push(obj.vital_history[i].at_birth.toString());
  
  
                  }
  
  
  
                  if (obj.vital_history[i].age != null) {
                      Age_List.push(obj.vital_history[i].age.toString());
                  }
                  else {
                      Age_List.push("");
                  }
  
                  
              }
               Pediatric_Graph(Datelist, Height_list, Weight_list, Heart_Rate_list, Temparature_list, At_Birth_List, Age_List, Height_Datelist, Clnt_DOB, localStorage.getItem("Case_Clnt_Gender"), "New",this.month_id);
  
          }
       
        
        },
        error => {
          //  this.fun_toast(Message_data.getNetworkMessage());
        }
      )
  }
  
  ClosePopup(){
    this.dialogRef.close();
  }
  
}
