<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Create order</h5>
          </div>
          <div class="headerButtons">
            <img  src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" (click)="backButton()" />
            <img *ngIf="saveFlag" printSectionId="print" (click)="printArea()" src="../../../assets/img/printer.svg" class="saveimgbtn_inpatinfo" style="margin-left:5px;height: 25px;width: 25px;">
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" (click)="saveOrder('noprint')" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel"> Name <br>
              <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                 (keyup)="changePatientName($event)" maxlength="50" [(ngModel)]="name" matInput
                 [matAutocomplete]="auto3" />
           </mat-label>
           <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
              <mat-option (click)="selectPatient(patient)" *ngFor="let patient of patientArray" value="{{patient.patient_name}}">
                 <div>{{patient.patient_name}}</div>
              </mat-option>
           </mat-autocomplete>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel"> Name <br>
               <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                  (keyup)="changePatientName($event)" maxlength="50" [(ngModel)]="name" matInput
                  [matAutocomplete]="auto3" />
            </mat-label>
            <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
               <mat-option (click)="selectPatient(patient)" *ngFor="let patient of patientArray" value="{{patient.patient_name}}">
                  <div>{{patient.patient_name}} ({{patient.patient_gender}} - {{patient.patient_age}} - {{patient.mobile}})</div>
               </mat-option>
            </mat-autocomplete>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Doctor<br>
              <input type="text" class="ipcss" maxlength="50" (keyup)="getReferralData($event)" maxlength="50"
                [(ngModel)]="doctorName" matInput [matAutocomplete]="auto1" />
              <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                <mat-option (click)="Select_refer_doctor(item)" *ngFor="let item of ReferedDoctorArray"
                  value="{{item}}">
                  {{item}}
                </mat-option>
              </mat-autocomplete>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="date_time_flag">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss" max="{{todayDate}}" (change)="changeOrderDate(orderDate)"
                [(ngModel)]="orderDate" #matInput>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="!paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                (selectionChange)="changePay()" disableOptionCentering>
                <mat-option *ngFor="let pay of payTypeListArrray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="insurFlag">
            <mat-label class="matlabel">Insurer<br>
              <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID" disableOptionCentering>
                <mat-option *ngFor="let ins of insurerArray" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="tranFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="TransactionID" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>
          <!-- <div class="col-4 my-auto">
            <div class="billsdetail" style="display: flex;">
              <mat-label class="matlabel"> Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</mat-label>&nbsp;&nbsp;&nbsp;
              <mat-label class="matlabel">{{orderTime}}</mat-label><br>
            </div>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
            <mat-label class="matlabel">Discount type<br>
              <select disableOptionCentering class="ipcss " required [(ngModel)]="discountType" (change)="discountChange(discountType)">
                <option disabled>Select</option>
                <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">{{disc.description}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
            <label class="matlabel" for="customFile">Prescription file choosen</label>
            <input type="file" class="ipcss form-control" id="customFile" />
          </div>
        </div>

        <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
          <div id="pharma_tbl_overlay" class="dig_table_overflow table-responsive" style="height: 210px;">
            <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px;">
              <thead>
                <tr>
                  <th style="width: 45px;">Si.No</th>
                  <th style="width: 250px;" *ngIf="genericDisplayFlag">Generic name</th>
                  <th style="width: 250px;">Medicine name</th>
                  <th style="width: 150px;">Batch no</th>
                  <th style="width: 70px;">Quantity</th>
                  <th style="width: 90px;">Price</th>
                  <th style="width: 70px;">Discount</th>
                  <th style="width: 150px;">Amount</th>
                  <th style="width: 20px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let drug of drugDetailsArray;let i = index" class="tdvalues">
                  <th >{{i + 1}} </th>
                  <td *ngIf="genericDisplayFlag">
                    <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                      (keyup)="changeMedicineName($event,'generic')" maxlength="50" [(ngModel)]="drug.gen_name" matInput  [matAutocomplete]="auto3" 
                     />
                     
                    <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicineItem(medicine,drug)" *ngFor="let medicine of MedicneData" value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width getMedcien" required maxlength="50" (keyup)="changeMedicineName($event,'medicine')" maxlength="50" [(ngModel)]="drug.drug_name" matInput  [matAutocomplete]="auto2" />
                    <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicineItem(medicine,drug)" *ngFor="let medicine of MedicneData"  value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" [ngStyle]="drug.batch_required" required
                      [(ngModel)]="drug.batch_no" matInput maxlength="50" readonly autocomplete="on"
                      (click)="getCORD($event,'drug_'+drug.index);drug.show_batcharray = !drug.show_batcharray" />
                    <div [id]="'drug_'+drug.index" class="auto_complete_text1" *ngIf="drug.batcharray.length != 0"
                      style="display: none;">
                      <table style="width: 100%;">
                         <thead>
                          <tr>
                          <th> Batch no</th>
                          <th> Qty </th>
                          <th> MRP </th>
                          <th> Exp Date </th></tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let batch of drug.batcharray" (click)="selectedBatch(batch,drug,'drug_'+drug.index)">
                          <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                          <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                          <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                          <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                        </tr>
                      </tbody>
                      </table>
                    </div>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" 
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.quantity" (keyup)="quantity(drug)" (keyup.enter)="createRow()" #matInput />
                  </td>
                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" 
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      [(ngModel)]="drug.price" #matInput (keyup)="calculateamount(drug)" (keyup.enter)="createRow()"/>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" maxlength="3" [(ngModel)]="drug.discount"
                      (keyup)="calculateamount(drug)" #matInput [readonly]="!discoutFlag" (keyup.enter)="createRow()"/>
                  </td>
                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" [(ngModel)]="drug.amount"
                      #matInput disabled />
                  </td>
                  <td style="text-align: center;">
                    <a (click)="deleteMedicine(drug.drug_id,drug.index)">
                      <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
          <div id="pharma_tbl_overlay" class="dig_table_overflow table-responsive" style="height: 210px;">
            <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px;">
              <thead>
                <tr>
                  <th style="width: 45px;">Si.No</th>
                  <th style="width: 250px;" *ngIf="genericDisplayFlag">Generic name</th>
                  <th style="width: 250px;">Medicine name</th>
                  <th style="width: 150px;">Batch no</th>
                  <th style="width: 70px;">Quantity</th>
                  <th style="width: 90px;">Price</th>
                  <th style="width: 70px;">Discount</th>
                  <th style="width: 150px;">Amount</th>
                  <th style="width: 20px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let drug of drugDetailsArray;let i = index" class="tdvalues">
                  <th >{{i + 1}} </th>
                  <td *ngIf="genericDisplayFlag">
                    <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                      (keyup)="changeMedicineName($event,'generic')" maxlength="50" [(ngModel)]="drug.gen_name" matInput  [matAutocomplete]="auto3" 
                     />
                     
                    <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicineItem(medicine,drug)" *ngFor="let medicine of MedicneData" value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width getMedcien" required maxlength="50" (keyup)="changeMedicineName($event,'medicine')" maxlength="50" [(ngModel)]="drug.drug_name" matInput  [matAutocomplete]="auto2" />
                    <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicineItem(medicine,drug)" *ngFor="let medicine of MedicneData"  value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" [ngStyle]="drug.batch_required" required
                      [(ngModel)]="drug.batch_no" matInput maxlength="50" readonly autocomplete="on"
                      (click)="getCORD($event,'drug_'+drug.index);drug.show_batcharray = !drug.show_batcharray" />
                    <div [id]="'drug_'+drug.index" class="auto_complete_text1" *ngIf="drug.batcharray.length != 0"
                      style="display: none;">
                      <table style="width: 100%;">
                         <thead>
                          <tr>
                          <th> Batch no</th>
                          <th> Qty </th>
                          <th> MRP </th>
                          <th> Exp Date </th></tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let batch of drug.batcharray" (click)="selectedBatch(batch,drug,'drug_'+drug.index)">
                          <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                          <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                          <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                          <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                        </tr>
                      </tbody>
                      </table>
                    </div>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" [disabled]="drug.quantityDisable"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.quantity" (keyup)="quantity(drug)" (keyup.enter)="createRow()" #matInput />
                  </td>
                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" 
                      [(ngModel)]="drug.price" #matInput (keyup)="calculateamount(drug)"/>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" maxlength="3" [(ngModel)]="drug.discount"
                      (keyup)="calculateamount(drug)" #matInput [readonly]="!discoutFlag" />
                  </td>
                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" [(ngModel)]="drug.amount"
                      #matInput disabled />
                  </td>
                  <td style="text-align: center;">
                    <a (click)="deleteMedicine(drug.drug_id,drug.index)">
                      <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->

        <div class="row">
          <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" [hidden]="paytypreq">
              <div class="row">
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                    <select class="ipcss " [(ngModel)]="payType1" 
                      disableOptionCentering (change)="changePayType()">
                      <option *ngFor="let pay of payTypeListArrray" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insurFlag1">
                  <mat-label class="matlabel">Insurer<br>
                    <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID" disableOptionCentering>
                      <mat-option *ngFor="let ins of insurerArray" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="cardFlag1">
                  <mat-label class="matlabel">Card No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" required />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="tranFlag1">
                  <mat-label class="matlabel">Transaction ID<br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="TransactionID" required/>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="chequeFlag1">
                  <mat-label class="matlabel">Cheque No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" required/>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-3 col-xl-3" >
                  <mat-label class="matlabel">Amount<br>
                    <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput required maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/next_button.svg" class="amt_button add_align"
                      (click)="addPaymentDetails()" />
                  </mat-label>
                </div>
              </div>

              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                      <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>

          </div>
          <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
            <div class="row" style="margin: 10px 0px;">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="discoutFlag">
                <mat-label class="matlabel  heightmsr">Discount </mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="discoutFlag">
                <input type="text" class="ipcss " style="text-align: right;" [(ngModel)]="prodDiscount"
                  (keyup)="changeTotal()"  (blur)="dissBlur()">
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel">Bill amount</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="totalAmount" style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel">Total </mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="amountDicount" style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">paid </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right style="text-align: right;"
                  [(ngModel)]="paidAmount" (keyup)="changeAdvance()" (focus)="paidFocus()" (blur)="paidBlur()" />
                <!-- <input type="text" class="paid_style ipcss " style="text-align: right;"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="paidText"
                    (keyup)="advance_change()"> -->
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Balance</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input type="text" class="ipcss " style="background-color: #e7e7e7;
                  border-radius: 5px;text-align: right;" [(ngModel)]="remainBalance" readonly>
              </div>
            </div>
          </div>
        </div>
        <!-- Printing bill-->
        <div [hidden]="true">
          <div>
            <div #billprint id="billprint">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                    <td style="display: flex;justify-content: center;">
                      <div>
                      </div>
                      <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                        <p><b>{{pharmacyName}}</b>,</p>
                        <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                          *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                        <span>{{location}},</span> <span>{{city}},</span>
                        <span>{{state}},</span><span>{{country}}.</span><br>
                        <p><span *ngIf="dlNO != undefined">DL No : {{dlNO}} &nbsp;&nbsp;</span><span
                            *ngIf="gstNO != undefined">GST : {{gstNO}}</span></p>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tfoot class="report-footer">
                  <tr>
                    <td style="height:100px;"></td>
                  </tr>
                </tfoot>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{name}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Doctor : {{doctorName}}</p>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Expiry date</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Disc(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let medpre of drugDetailsArray">
                              <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td>
                              <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                                <tr *ngIf="displayDisplay">
                                  <td style="text-align: right; width: 80%;">
                                    <b>Discount</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td>
                                    <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Total</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td>
                                    <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Paid</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td class="billamttext">
                                    <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Balance</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td class="billamttext">
                                    <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div #noheader id="noheader">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                    <td style="height:130px;"></td>
                  </tr>
                </thead>
                <tfoot class="report-footer">
                  <tr>
                    <td style="height:100px;"></td>
                  </tr>
                </tfoot>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{name}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Doctor : {{doctorName}}</p>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Expiry date</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Disc(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let medpre of drugDetailsArray">
                              <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td>
                              <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                                <tr *ngIf="displayDisplay">
                                  <td style="text-align: right; width: 80%;">
                                    <b>Discount</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td>
                                    <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Total</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td>
                                    <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Paid</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td class="billamttext">
                                    <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Balance</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td class="billamttext">
                                    <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div #banner id="banner">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                    <td style="width: 150px;"></td>
                    <td>
                      <img alt="image" src={{pharmaLogo}} width="750px">
                    </td>
                  </tr>
                </thead>
                <tfoot class="report-footer">
                  <tr>
                    <td style="height:100px;"></td>
                  </tr>
                </tfoot>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{name}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Doctor : {{doctorName}}</p>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Expiry date</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Disc(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let medpre of drugDetailsArray">
                              <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br/>
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td>
                              <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                                <tr *ngIf="displayDisplay">
                                  <td style="text-align: right; width: 80%;">
                                    <b>Discount</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td>
                                    <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Total</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td>
                                    <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Paid</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td class="billamttext">
                                    <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Balance</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td class="billamttext">
                                    <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!-- <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                          <tr *ngIf="displayDisplay">
                            <td style="text-align: right; width: 80%;">
                              <b>Discount</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Total</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Paid</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Balance</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                            </td>
                          </tr>
                        </table> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div #logowithname id="logowithname"
              style="background-image: url({{bgImage}}); background-position: center;">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header">
                  <tr>
                    <td style="display: flex;justify-content: center;">
                      <div>
                        <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                          style="vertical-align: middle;">
                      </div>
                      <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                        <p><b>{{pharmacyName}}</b>,</p>
                        <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                          *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                        <span>{{location}},</span> <span>{{city}},</span>
                        <span>{{state}},</span><span>{{country}}.</span><br>
                        <p><span *ngIf="dlNO != undefined">DL No : {{dlNO}} &nbsp;&nbsp;</span><span
                            *ngIf="gstNO != undefined">GST : {{gstNO}}</span></p>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tfoot class="report-footer">
                  <tr>
                    <td style="height:100px;"></td>
                  </tr>
                </tfoot>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{name}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Doctor : {{doctorName}}</p>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Expiry date</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Disc(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let medpre of drugDetailsArray">
                              <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td>
                              <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                                <tr *ngIf="displayDisplay">
                                  <td style="text-align: right; width: 80%;">
                                    <b>Discount</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td>
                                    <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Total</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td>
                                    <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Paid</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td class="billamttext">
                                    <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="text-align: right; width: 80%;">
                                    <b>Balance</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 15px; height: 15px;" />
                                  </td>
                                  <td class="billamttext">
                                    <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!-- <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                          <tr *ngIf="displayDisplay">
                            <td style="text-align: right; width: 80%;">
                              <b>Discount</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Total</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Paid</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Balance</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                            </td>
                          </tr>
                        </table> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>