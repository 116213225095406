import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../../app/helper_class';

@Component({
  selector: 'app-diag-package-create',
  templateUrl: './diag-package-create.component.html',
  styleUrls: ['./diag-package-create.component.scss']
})
export class DiagPackageCreateComponent implements OnInit {
  public save_btn:boolean=true;
  public update_btn:boolean=true;
  show = true
  view = false
  public select_country: any;
  // public subtestID:any
  public diag_testtype_name: any;
  public test_name: any = [];
  public testTypeName: any = [];
  public urine_test: any = []
  public faeces_test: any = []
  public scan_test: any = []
  public xray_test: any = []
  public ultra_test: any = []
  public culture: any= []
  public biopsy: any = []
  public tests: any
  public diag_packages_id: number = 0
  type_name: any;
  test_id: any
  typename: any
  public blood_test: any[] = []
  public checked: boolean = false;
  data: any;
  service: any;
  package_id: any;
  subtest: any;
  typenames: any;
  typeName: any;
  bloodtest: any;
  id: any;
  testname: any;

  public diag_test = [];
  public testtypename: any = [];

  public test_flag: boolean=false;

  public pkg_name: string;
  public pkg_desc: string;
  public pkg_price: string;
  private diag_centre_id;

  constructor(public diagnosisservice:MenuViewService,public toastr:ToastrService,
    public gservice:CommonDataService,public http:HttpClient,public dialog:MatDialog) {

  }

  ngOnInit(): void {
    this.getDiagnosisTests();
    
  }

  getPackageDetails(packageid){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gpkgbd/',
      {
        package_id: packageid
      },
      { headers: headers }).subscribe(response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("PACKAGE RETRIEVE "+JSON.stringify(obj))
          if(obj.blood_test != undefined){
            this.blood_test = obj.blood_test;
            for(var i=0;i<this.diag_test.length;i++){
              if(this.diag_test[i].test_type_name =="Blood Test"){
                for(var j=0;j<this.diag_test[i].sub_tests.length;j++){
                  for(var k=0;k<obj.blood_test.length;k++){
                    if(this.diag_test[i].sub_tests[j].test_id == obj.blood_test[k]){
                      this.diag_test[i].sub_tests[j].checked=true;
                    }
                  }
                }
              }
            }
          }

          if(obj.urine_test != undefined){
            this.urine_test = obj.urine_test;
            for(var i=0;i<this.diag_test.length;i++){
              if(this.diag_test[i].test_type_name =="Urine Test"){
                for(var j=0;j<this.diag_test[i].sub_tests.length;j++){
                  for(var k=0;k<obj.urine_test.length;k++){
                    if(this.diag_test[i].sub_tests[j].test_id == obj.urine_test[k]){
                      this.diag_test[i].sub_tests[j].checked=true;
                    }
                  }
                }
              }
            }
          }

          if(obj.faeces_test != undefined){
            this.faeces_test = obj.faeces_test;
            for(var i=0;i<this.diag_test.length;i++){
              if(this.diag_test[i].test_type_name =="Faeces Test"){
                for(var j=0;j<this.diag_test[i].sub_tests.length;j++){
                  for(var k=0;k<obj.faeces_test.length;k++){
                    if(this.diag_test[i].sub_tests[j].test_id == obj.faeces_test[k]){
                      this.diag_test[i].sub_tests[j].checked=true;
                    }
                  }
                }
              }
            }
          }

          if(obj.scan_test != undefined){
            this.scan_test = obj.scan_test;
            for(var i=0;i<this.diag_test.length;i++){
              if(this.diag_test[i].test_type_name =="Scan"){
                for(var j=0;j<this.diag_test[i].sub_tests.length;j++){
                  for(var k=0;k<obj.scan_test.length;k++){
                    if(this.diag_test[i].sub_tests[j].test_id == obj.scan_test[k]){
                      this.diag_test[i].sub_tests[j].checked=true;
                    }
                  }
                }
              }
            }
          }

          if(obj.ultrasound_test != undefined){
            this.ultra_test = obj.ultrasound_test;
            for(var i=0;i<this.diag_test.length;i++){
              if(this.diag_test[i].test_type_name =="Ultra Sound"){
                for(var j=0;j<this.diag_test[i].sub_tests.length;j++){
                  for(var k=0;k<obj.ultrasound_test.length;k++){
                    if(this.diag_test[i].sub_tests[j].test_id == obj.ultrasound_test[k]){
                      this.diag_test[i].sub_tests[j].checked=true;
                    }
                  }
                }
              }
            }
          }

          if(obj.xray_test != undefined){
            this.xray_test = obj.xray_test;
            for(var i=0;i<this.diag_test.length;i++){
              if(this.diag_test[i].test_type_name =="X-ray"){
                for(var j=0;j<this.diag_test[i].sub_tests.length;j++){
                  for(var k=0;k<obj.xray_test.length;k++){
                    if(this.diag_test[i].sub_tests[j].test_id == obj.xray_test[k]){
                      this.diag_test[i].sub_tests[j].checked=true;
                    }
                  }
                }
              }
            }
          }

          if(obj.culture_test != undefined){
            this.culture = obj.culture_test;
            for(var i=0;i<this.diag_test.length;i++){
              if(this.diag_test[i].test_type_name =="Culture"){
                for(var j=0;j<this.diag_test[i].sub_tests.length;j++){
                  for(var k=0;k<obj.culture_test.length;k++){
                    if(this.diag_test[i].sub_tests[j].test_id == obj.culture_test[k]){
                      this.diag_test[i].sub_tests[j].checked=true;
                    }
                  }
                }
              }
            }
          }

          if(obj.biopsy_test != undefined){
            this.biopsy = obj.biopsy_test;
            for(var i=0;i<this.diag_test.length;i++){
              if(this.diag_test[i].test_type_name =="Biopsy"){
                for(var j=0;j<this.diag_test[i].sub_tests.length;j++){
                  for(var k=0;k<obj.biopsy_test.length;k++){
                    if(this.diag_test[i].sub_tests[j].test_id == obj.biopsy_test[k]){
                      this.diag_test[i].sub_tests[j].checked=true;
                    }
                  }
                }
              }
            }
          }
        },
        error => { }
      )
  }

  savePackage(data){
    var params = {
      diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id,
      diag_packages_id: this.diag_packages_id,
      package_name: this.pkg_name,
      price: this.pkg_price,
      package_desc: this.pkg_desc, 
      "blood_test": this.blood_test,
      "urine_test": this.urine_test,
      "faeces_test": this.faeces_test,
      "scan_test": this.scan_test,
      "xray_test": this.xray_test,
      "culture_test": this.culture,
      "biopsy_test": this.biopsy,
      "ultrasound_test": this.ultra_test
    }
    console.log("JSON.stringify+JS"+JSON.stringify(params));
    var headers = new HttpHeaders()
    this.http.post(ipaddress.getIp + "diagnosiscontroller/sdpkg",
      params, { headers: headers }).subscribe(response => {
        this.data =JSON.parse(JSON.stringify(response)) 
        if(this.data.key =="1"){
          this.toastr.success(this.data.result);
          this.diagnosisservice.sendMessage('tests')
        } else {
          this.toastr.error("Something went wrong, try again later")
        }
      })
  }

  backClicked() {  //back
    this.diagnosisservice.sendMessage('tests')
  }

  ChangeTestType(event, id, data, type) {
    if (event == true) {
      for (var k = 0; k < this.diag_test.length; k++) {
        this.testtypename[k] = this.diag_test[k].test_type_name;
        if (this.testtypename[k] == type) {
          for (var j = 0; j < this.diag_test[k].sub_tests.length; j++) {
            if (this.diag_test[k].sub_tests[j].test_id == id) {
              this.diag_test[k].sub_tests[j].checked = true;
            }
          }
        }
      }
      this.save_btn=false;
      if (type == "Blood Test") {
        this.blood_test.push(id);

      } else if (type == "Scan") {
        this.scan_test.push(id);

      } else if (type == "Ultra Sound") {
        this.ultra_test.push(id);

      } else if (type == "X-ray") {
        this.xray_test.push(id);

      } else if (type == "Biopsy") {
        this.biopsy.push(id);

      } else if (type == "Urine Test") {
        this.urine_test.push(id);

      } else if (type == "Faeces Test") {
        this.faeces_test.push(id);

      } else if (type == "Culture") {
        this.culture.push(id);
      } 

    } else if (event == false) {
      for (var k = 0; k < this.diag_test.length; k++) {
        this.testtypename[k] = this.diag_test[k].test_type_name;
        if (this.testtypename[k] == type) {
          for (var j = 0; j < this.diag_test[k].sub_tests.length; j++) {
            if (this.diag_test[k].sub_tests[j].test_id == id) {
              this.diag_test[k].sub_tests[j].checked = false;
            }
          }
        }
      }
      this.save_btn=true;
      for (var k = 0; k < this.diag_test.length; k++) {
        for (var j = 0; j < this.diag_test[k].sub_tests.length; j++) {
          if ( this.diag_test[k].sub_tests[j].checked == true) {
            this.save_btn = false;
            
          }
        }
      }

      if (type == "Blood Test") {
        this.blood_test.forEach(test => {
          if(test == id){
            let index = this.blood_test.indexOf(id)
            this.blood_test.splice(index,1);
          }
        });
       
      } else if (type == "Scan") {
        this.scan_test.forEach(test => {
          if(test == id){
            let index = this.scan_test.indexOf(id)
            this.scan_test.splice(index,1);
          }
        });

      } else if (type == "Ultra Sound") {
        this.ultra_test.forEach(test => {
          if(test == id){
            let index = this.ultra_test.indexOf(id)
            this.ultra_test.splice(index,1);
          }
        });

      } else if (type == "X-ray") {
        this.xray_test.forEach(test => {
          if(test == id){
            let index = this.xray_test.indexOf(id)
            this.xray_test.splice(index,1);
          }
        });

      } else if (type == "Biopsy") {
        this.biopsy.forEach(test => {
          if(test == id){
            let index = this.biopsy.indexOf(id)
            this.biopsy.splice(index,1);
          }
        });

      } else if (type == "Urine Test") {
        this.urine_test.forEach(test => {
          if(test == id){
            let index = this.urine_test.indexOf(id)
            this.urine_test.splice(index,1);
          }
        });

      } else if (type == "Faeces Test") {
        this.faeces_test.forEach(test => {
          if(test == id){
            let index = this.faeces_test.indexOf(id)
            this.faeces_test.splice(index,1);
          }
        });

      } else if (type == "Culture") {
        this.culture.forEach(test => {
          if(test == id){
            let index = this.culture.indexOf(id)
            this.culture.splice(index,1);
          }
        });
      }
    }
  }

  getDiagnosisTests(){
    this.diag_test = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/',
      {
        diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.diag_tests.length == 0) {
            this.test_flag = true;
          } else {
            this.test_flag = false;
          }

          this.diag_test = obj.diag_tests;
          for (var k = 0; k < this.diag_test.length; k++) {
            this.testtypename[k] = this.diag_test[k].test_type_name;
            for (var j = 0; j < this.diag_test[k].sub_tests.length; j++) {
              this.diag_test[k].sub_tests[j] = {
                test_id: this.diag_test[k].sub_tests[j].test_id,
                test_name: this.diag_test[k].sub_tests[j].test_name,
                checked: false
              }
            }
          }

          if(Diagnosis_Helper.getdiagnosistest().is_new != undefined && Diagnosis_Helper.getdiagnosistest().is_new =="0" ){
            this.getPackageDetails(Diagnosis_Helper.getdiagnosistest().diag_package_id);
            this.pkg_name = Diagnosis_Helper.getdiagnosistest().pkg_name;
            this.pkg_desc = Diagnosis_Helper.getdiagnosistest().pkg_desc;
            this.pkg_price = Diagnosis_Helper.getdiagnosistest().pkg_price;
            this.diag_packages_id = Diagnosis_Helper.getdiagnosistest().diag_package_id
          }
          console.log("TEST DETAILS "+JSON.stringify(this.diag_test))
        },
        error => { }
      )
  }
}
