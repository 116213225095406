import { Component, OnInit, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import {ipaddress.getIp,ipaddress.Ip_with_img_address,country_code} from '../../../assets/js/ipaddress.js';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NurseService } from '../nurse-view/nurse.service';
import { Nurse_Helper } from '../Nurse_Helper';
import { ipaddress } from '../../ipaddress';
import { Doc_Helper } from 'src/app/Doctor_module/Doc_Helper';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
// import { InpatientService } from '../inpatient-view/inpatient.service';
// import { Inpatient_Helper } from '../inpatient_Helper';
@Component({
  selector: 'app-medicine-tracker',
  templateUrl: './medicine-tracker.component.html',
  styleUrls: ['./medicine-tracker.component.scss']
})
export class MedicineTrackerComponent implements OnInit {
  public selectType;
  public hospitalArray;
  public userInfo;
  public wardArray;
  public CurrentDatetime;
  public ward;
  public type;
  public typeID;
  public hideWardFlag: boolean = false;
  public patientList = [];
  public isWardFalg: boolean;
  public splURL;
  public splArray = [];
  constructor(public http: HttpClient, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public messageservice: MenuViewService) {
    this.type = "Nurse";
    this.selectType = "Ward";
    Nurse_Helper.setINPatient(undefined);
    this.splURL = ipaddress.getIp + "adm/gsplbyhptl";
    Helper_Class.setInpatientFlow(undefined);
    Helper_Class.setSpecializations(undefined);
    Doc_Helper.setFirstname(null);
    Doc_Helper.setMiddlename(null);
    Doc_Helper.setLastname(null);
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setMobile_no(null);
    Doc_Helper.setClient_type(undefined);
  }
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    //this.hospitaldet = Helper_Class.getHospitalId();
    this.hospitalArray = Helper_Class.getHospital();
    // this.getWardData();
    this.getByWard();
    this.getSpecializations();
  }
  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.splURL, ({ hptl_clinic_id: this.hospitalArray[0].hptl_clinic_id }),
      { headers: headers }).subscribe(
        response => {
          this.splArray = [];
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.specializations != null) {
            this.splArray = obj.specializations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        })
  }
  getByWard() {
    var send_data = {
      type: this.type,
      user_id: this.userInfo.user_id,
      // hptl_clinic_id:this.hospitaldet,
      hptl_clinic_id: this.hospitalArray[0].hptl_clinic_id,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gwnp', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != undefined && obj != null && obj != "") {
            this.wardArray = obj.wards;
            this.ward = this.wardArray[0].ward_id;
            this.typeID = this.ward;
            this.isWardFalg = true;
            this.getByPatient();
          }
        });
  }
  getByPatient() {
    var send_data = {
      type: this.type,
      type_id: this.typeID,
      is_ward: this.isWardFalg,
      // hptl_clinic_id:this.hospitaldet,
      hptl_clinic_id: this.hospitalArray[0].hptl_clinic_id,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/ginpnp', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != undefined && obj != null && obj != "") {
            this.patientList = [];
            for (var i = 0; i < obj.patients.length; i++) {
              var patient_name, mobile, profile_image, first_name, middle_name, last_name;
              if (obj.patients[i].middle_name != undefined && obj.patients[i].middle_name != null) {
                first_name = encrypt_decript.Decript(obj.patients[i].first_name);
                middle_name = encrypt_decript.Decript(obj.patients[i].middle_name);
                last_name = encrypt_decript.Decript(obj.patients[i].last_name);
                patient_name = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
              } else {
                first_name = encrypt_decript.Decript(obj.patients[i].first_name);
                last_name = encrypt_decript.Decript(obj.patients[i].last_name);
                patient_name = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
              }
              if (obj.patients[i].mobile != undefined && obj.patients[i].mobile != null) {
                mobile = encrypt_decript.Decript(obj.patients[i].mobile);
              }
              if (obj.patients[i].profile_image != undefined && obj.patients[i].profile_image != null) {
                profile_image = ipaddress.Ip_with_img_address + obj.patients[i].profile_image;
              }
              this.patientList.push({
                client_reg_id: obj.patients[i].client_reg_id,
                patient_name: patient_name,
                first_name: first_name,
                last_name: last_name,
                middle_name: middle_name,
                mobile: mobile,
                image: profile_image,
                inpatient_id: obj.patients[i].inpatient_id,
                relation_id: obj.patients[i].relation_id,
                created_by: this.userInfo.user_id,
                creator_type: this.type,
                inpat_id: obj.patients[i].inpat_id,
                spl_id: obj.patients[i].spl_id,
                flow: "nurse"
              })
            }
          }
        });
  }
  selectBy(e) {
    if (e == 'Patient') {
      this.typeID = this.userInfo.user_id;
      this.hideWardFlag = true;
      this.isWardFalg = false;
      this.getByPatient();
    } else {
      this.typeID = this.ward;
      this.isWardFalg = true;
      this.hideWardFlag = false;
      this.getByPatient();
    }
  }
  changeByWard(e) {
    this.typeID = e;
    this.hideWardFlag = false;
    this.isWardFalg = true;
    this.getByPatient();
  }
  viewPatient(patient) {
    Doc_Helper.setFirstname(patient.first_name);
    Doc_Helper.setMiddlename(patient.middle_name);
    Doc_Helper.setLastname(patient.last_name);
    Doc_Helper.setClient_id(patient.client_reg_id);
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("Existing");
    Doc_Helper.setMobile_no(encrypt_decript.Encript(patient.mobile).toString());
    Doc_Helper.setInpatientId(patient.inpat_id);
    Doc_Helper.setInpatientFlow("list");
    // var spa_id
    // for( var i=0;i<this.spl_array.length; i++){
    //     if(this.spl_array[i].spl_name == patient.spl_id){
    //        spa_id = this.spl_array[i].spl_id;
    //     }
    // }
    // var specializations = [{
    //   spl_id: spa_id,
    //   spl_name: speciality
    // }]
    Nurse_Helper.setINPatient(patient);
    Helper_Class.setInpatientFlow("nurse");
    Helper_Class.setSpecializations(this.splArray);
    //  this.router.navigateByUrl("/inpatient_information");
    this.messageservice.sendMessage("viewIpatient");
    // this.messageservice.sendMessage("inPatientViewInfo");
  }
}
