import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate } from '../../../assets/js/common';
declare var $: any;
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-advance-create',
  templateUrl: './advance-create.component.html',
  styleUrls: ['./advance-create.component.scss']
})
export class AdvanceCreateComponent implements OnInit {
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  public ClientFilterList = [];
  public clntFirstName: string;
  public clntMiddleName: string;
  public clntLastName: string;
  public getClientRegId: string;
  public clntContNumber: string;
  public clntGender: string;
  public clntAge: string;
  public clntAddress1: string;
  public clntAddress2: string;
  public clntLocation: string;
  public clntCity: string;
  public clntState: string;
  public clntCountry: string;
  public clntZipcode: string;
  public advance:string="";
  public hospitalId;
  public advanceFlag = false;
  public paytypelist = [];
  public payType: string;
  public payDesc: string;
  public transactionId;
  public cardNumber;
  public cardHolderName;
  public bankName;
  public hospitalLogo;
  public hospitalName;
  public hospitalLocation;
  public hospitalCity;
  public hospitalState;
  public hospitalCountry;
  public hospitalPhNo;
  public hospitalAddr;
  public printTemplate;
  public patientId;
  public transactionFlag: boolean = true;
  public cardFlag: boolean = true;
  public chequeFlag: boolean = true;
  public cardreqflag:boolean=false;
  public transreqflag:boolean=false;
  public chequereqflag:boolean=false;
  public insreqflag:boolean=false;
  public insurFlag: boolean;
  public appointmentListArray: any = [];
  public patientList: any = [];
  public clientRegID;
  public appID;
  public clientName;
  public insurerId;
  public advanceArray = [];
  public insurerList = [];
  public totalAdvance:any = "0.00";
  public preslogo_style:string;

  constructor(public gservice: CommonDataService, public http: HttpClient, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public messageservice: MenuViewService, public dialog: MatDialog, public dialogRef: MatDialogRef<AdvanceCreateComponent>) {
  }

  ngOnInit(): void {
    this.getPaymentType();
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.hospitalName = Helper_Class.getInfo().hospitals[0].hptl_name;
    this.hospitalLocation = Helper_Class.getInfo().hospitals[0].location_desc;
    this.hospitalCity = Helper_Class.getInfo().hospitals[0].city_desc;
    this.hospitalState = Helper_Class.getInfo().hospitals[0].state_desc;
    this.hospitalCountry = Helper_Class.getInfo().hospitals[0].country_desc;
    this.hospitalPhNo = Helper_Class.getInfo().hospitals[0].telephone;
    this.hospitalAddr = Helper_Class.getInfo().hospitals[0].address2 != undefined ? Helper_Class.getInfo().hospitals[0].address1 + "," + Helper_Class.getInfo().hospitals[0].address2 : Helper_Class.getInfo().hospitals[0].address1;
    //this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;
    if (Helper_Class.getInfo().user_type == "front-desk") {
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().pres_print_logo;
      this.preslogo_style = Helper_Class.getInfo().bill_print_logo_style;

    } else {
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].pres_print_logo;
      this.preslogo_style = Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
    }
    this.printTemplate = Helper_Class.getInfo().bill_print_template;
    this.hospitalId = Helper_Class.getInfo().hptl_clinic_id;
  }

  getPaymentType() { //Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paytypelist = obj.payments;
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.paytypelist[i].pay_desc == "Insurance")
              this.paytypelist.splice(i, 1);
          }
          this.payType = this.paytypelist[0].pay_id;
          this.payDesc = this.paytypelist[0].pay_desc;
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changePayType() { 
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if(this.payType == "2"){
      this.transactionFlag = false;
      this.cardFlag = false;
      this.cardreqflag=true;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if (this.payType == "3"){
      this.cardreqflag=false;
      this.insurFlag = false;
      this.cardreqflag=false;
      this.transreqflag= false;
      this.chequereqflag = false;
      this.insreqflag = true;

    } else if(this.payType == "4"){
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "5") {
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "6"){
      this.transactionFlag = false;
      this.cardreqflag=false;
      this.transreqflag= true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if(this.payType == "7") {
      this.chequeFlag = false;
      this.cardreqflag=false;
      this.transreqflag= false;
      this.chequereqflag = true;
      this.insreqflag = false;
    } 
    var res = this.paytypelist.filter(o => o.pay_id == this.payType);
    this.payDesc = res[0].pay_desc;
  }

  getAppointments(patientList) {
    this.appointmentListArray = [];
    this.patientList = [];
    if (patientList.target.value.toString() != null && patientList.target.value.toString().length > 2) {
      var headers = new HttpHeaders();
      var name = patientList.target.value.toUpperCase();
      var send_data = {
        hptl_clinic_id: this.hospitalId,
        first_name: name
      };
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/inpa', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));

            if (obj.appointment != null) {
              var Docidname, middleName = "";
              for (var i = 0; i < obj.appointment.length; i++) {
                obj.appointment[i].middle_name
                if (obj.appointment[i].middle_name != undefined) {
                  Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                  middleName = encrypt_decript.Decript(obj.appointment[i].middle_name);
                } else {
                  Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                }

                var sessiondata = null;
                if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                  sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
                }
                var address;
                if (obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                  address = encrypt_decript.Decript(obj.appointment[i].address1) + ", " + encrypt_decript.Decript(obj.appointment[i].address2)
                } else if (obj.appointment[i].address1 != undefined) {
                  address = encrypt_decript.Decript(obj.appointment[i].address1);
                } else {
                  address = "";
                }
                var gender;
                if (obj.appointment[i].gender != undefined && obj.appointment[i].gender != undefined) {
                  gender = encrypt_decript.Decript(obj.appointment[i].gender)
                }
                var hospital;
                if (obj.hosp_address2 != undefined) {
                  hospital = obj.hosp_address1 + "," + obj.hosp_address2;

                } else {
                  hospital = obj.hosp_address1;
                }
                var inpatientId = obj.appointment[i].inpat_id != undefined ? obj.appointment[i].inpat_id : "-";
                var zipcode = obj.appointment[i].zipcode != undefined ? encrypt_decript.Decript(obj.appointment[i].zipcode) : "";
                this.hospitalName = obj.hosp_name != undefined ? obj.hosp_name : "";
                this.appointmentListArray.push({
                  doc_app_id: obj.appointment[i].doc_app_id,
                  doc_reg_id: obj.appointment[i].doc_reg_id,
                  client_reg_id: obj.appointment[i].client_reg_id,
                  relation_id: obj.appointment[i].relation_id,
                  sub_rel_id: obj.appointment[i].sub_rel_id,
                  docid_name: Docidname,
                  firtName: encrypt_decript.Decript(obj.appointment[i].first_name),
                  lastName: encrypt_decript.Decript(obj.appointment[i].last_name),
                  middleName: middleName,
                  address: address,
                  clntLocation: obj.appointment[i].location,
                  clntCity: obj.appointment[i].district,
                  clntState: obj.appointment[i].state,
                  clntCountry: obj.appointment[i].country,
                  age: obj.appointment[i].age,
                  gender: gender,
                  patient_id: obj.appointment[i].patient_id,
                  clntZipcode: encrypt_decript.Decript(obj.appointment[i].zipcode),
                });
              }
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )
    }
  }

   save() {
    if (this.advanceArray.length == 0) {
      this.toastr.error(Message_data.plsEnterAdvAmt);
    } else {
      var senddata = {
        hptl_clinic_id: this.hospitalId,
        client_reg_id: this.clientRegID,
        app_id: this.appID,
        bills : this.advanceArray,
        country: "IN",
      }
      this.advanceFlag = false;
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/sadv/', senddata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj != undefined && obj.key == "1") {
              this.toastr.success(Message_data.advPaySavedSuccess);
              this.print();
              this.dialogRef.close();
            } else {
              this.toastr.error(Message_data.unableToAdvPaySave);
              this.advanceFlag = true;
            }
          });
    }
  }

  print() {
    let printContents, popupWin;
    if (this.printTemplate != undefined && this.printTemplate == "logowithname"){
      printContents = this.logowithname.nativeElement.innerHTML;
    } else if(this.printTemplate != undefined && this.printTemplate == "banner"){
      printContents = this.printbanner.nativeElement.innerHTML;
    } else {
      printContents = this.noheader.nativeElement.innerHTML;
    }

    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Bill</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  close() {
    this.dialogRef.close();
  }

  changeAppointment(ClientID, relationID, subRelationID, appID, appointment) {
    this.clientRegID = ClientID;
    this.advanceFlag = true;
    this.appID = appID;
    this.clntFirstName = appointment.firtName;
    this.clntLastName = appointment.lastName;
    this.clntMiddleName = appointment.middleName != "" ? appointment.middleName : "";
    this.clntLocation = appointment.clntLocation;
    this.clntCity = appointment.clntCity;
    this.clntState = appointment.clntState;
    this.clntCountry = appointment.clntCountry;
    this.patientId = appointment.patient_id;
    this.clntAge = appointment.age;
    this.clntGender = appointment.gender;
    this.clntAddress1 = appointment.address;
    this.appointmentListArray = [];
  }

  addAdvance() {
    
    if (this.advance == "") {
      this.toastr.error(Message_data.plsEnterAdvAmt);
    } else {
        this.advanceArray.push({
          amount : parseFloat(this.advance).toFixed(2),
          pay_type : this.payType ,
          card_no : this.cardNumber ,
          card_holder_name : this.cardHolderName,
          transaction_no :this.transactionId ,
          bank_name : this.bankName,
          pay_desc : this.payDesc
        });
        this.totalAdvance = (parseFloat(this.totalAdvance) + parseFloat(this.advance)).toFixed(2);
        this.payType= undefined;
        this.cardNumber = undefined;
        this.cardHolderName = undefined;
        this.transactionId = undefined;
        this.cardNumber = undefined;
        this.bankName = undefined;
        this.advance = undefined;
    }
  }
}
