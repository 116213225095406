import { Component, OnInit } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
// import {ipaddress.getIp,ipaddress.Ip_with_img_address,ipaddress.country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate, first_uppercase } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-inpat-dashboard',
  templateUrl: './inpat-dashboard.component.html',
  styleUrls: ['./inpat-dashboard.component.css']
})
export class InpatDashboardComponent implements OnInit {

  public userinfo:any;
  public user_id:any;
  public first_name:string;
  public last_name:string;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public messages;
  public msgflag:boolean=false;
  public notifications;
  public notifyflag:boolean=false;
  public profile_image:string;

  public City_dashboard;
  public City_location;
  // public From_date;
  // public To_date;
  public First_name;
  public patient_appointlist;
  public appointmentpatinet_list:any = [];
  public sub_id: string;
  public clnt_name: string;
  public no_patientfound: boolean;
  public Get_City_array:any = [];
  public Get_hosloc:any = [];
  public Filter_txt: string;
  public city_data;
  public loc_data;
  public name;
  public from;
  public to;
  public name1;
  public header_footer_flag: boolean;
  dataInicial: Date;
  maxDate: string;
  public loader:boolean;
  public hospital_id:any;

  public City_show:boolean;
  public firstname_show:boolean;
  public From_show:boolean;
  public Location_show:boolean;
  public To_show:boolean;

  From_date: String;
  To_date: String;
  public submenu_flag:boolean =true;
  public submenu_flag1:boolean =false;

  dtOptions: DataTables.Settings = {};;
  persons: any = [];
  masterSelected: boolean = false;
  checklist:any;
  checkedList:any;
  public patientType;
  public ynopt1:boolean = true;
  constructor(private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public messageservice:MenuViewService) { 
    this.Filter_txt = "In-patient";
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[1, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 2, 3] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Search by name or location or contact no"
      },
      dom: '<"row mt-1 mb-2"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    var hosp:any = Helper_Class.getHospital();
    this.hospital_id = hosp[0].hptl_clinic_id;
    this.userinfo= Helper_Class.getInfo();
    this.user_id=this.userinfo.user_id;

    this.City_show = true;
    this.firstname_show = true;
    this.From_show = true;
    this.Location_show = true;
    this.To_show = true;
    this.patientType = "self";
    // this.Get_City();
    this.Get_patient_list();
  }

  Get_patient_list() {
   
    this.appointmentpatinet_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/clndet/',
      JSON.stringify({
        doc_reg_id: this.user_id,
        hptl_clinic_id:this.hospital_id,
        country: ipaddress.country_code,
        type: "doctor",
        filter:this.Filter_txt,
        inpat_type: this.patientType
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.patient_list != null) {
            this.patient_appointlist = obj.patient_list;
            for (var i = 0; i < this.patient_appointlist.length; i++) {
              this.sub_id = this.patient_appointlist[i].sub_rel_id != undefined ? this.patient_appointlist[i].sub_rel_id : "0";
              this.clnt_name = this.patient_appointlist[i].middle_name != null && encrypt_decript.Decript(this.patient_appointlist[i].middle_name) != "" ? encrypt_decript.Decript(this.patient_appointlist[i].first_name) + " " + encrypt_decript.Decript(this.patient_appointlist[i].middle_name) + encrypt_decript.Decript(this.patient_appointlist[i].last_name) : encrypt_decript.Decript(this.patient_appointlist[i].first_name) + " " + encrypt_decript.Decript(this.patient_appointlist[i].last_name);
              var gender = encrypt_decript.Decript(this.patient_appointlist[i].gender);
              this.appointmentpatinet_list.push({
                full_name: this.clnt_name,
                loca: this.patient_appointlist[i].location_desc,
                city: this.patient_appointlist[i].city_desc,
                mobile_no: encrypt_decript.Decript(this.patient_appointlist[i].mobile),
                pro_image: ipaddress.Ip_with_img_address + this.patient_appointlist[i].profile_image,
                client_id: this.patient_appointlist[i].relation + "-" + this.patient_appointlist[i].client_reg_id + "-" + this.sub_id,
                relation_id: this.patient_appointlist[i].relation,
                Client_reg_id: this.patient_appointlist[i].client_reg_id,
                sub_id: this.sub_id,
                admission_id: this.patient_appointlist[i].admission_id,
                hptl_clinic_id : this.patient_appointlist[i].hptl_clinic_id,
                speciality : this.patient_appointlist[i].speciality,
                first_name : this.patient_appointlist[i].first_name,
                last_name : this.patient_appointlist[i].last_name,
                location : this.patient_appointlist[i].location,
                gender : gender,
                age : this.patient_appointlist[i].age
              });           
            }
            this.no_patientfound = this.patient_appointlist.length == 0 ? true : false;
          }
        },
        error => {}
      )
  }

  Filter_change() {
    this.appointmentpatinet_list = [];
    this.Get_patient_list();
  }

  //Dash board patinet view
  Patient_view(admission_id) {
    if(this.Filter_txt == "Discharged"){
      Doc_Helper.setAdmission_id(admission_id);
      this.messageservice.sendMessage("inpatient_Discharge_view");
    }
  }

  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
      this.patientType = "self";
      this.Get_patient_list();
    }else {
      this.ynopt1 = false;
      this.patientType = "others";
      this.Get_patient_list();
    }
  }
  editDischarge(list){
    Doc_Helper.setAdmission_id(null)
    var send = {
      admission_id : list.admission_id,
      flag : "edit"
    }
    var getClientInfo = {
      addmed: "write",
      AppFlow: "InpatMedPres_dis",
      rel_id: "1",
      sub_id: "",
      Gender_data: list.Gender_data,
      Age_data: list.Gender_data,
      app_id: list.admission_id,
      Client_id: list.Client_reg_id,
      spl_id: list.speciality,
      hptl_clinic_id: list.hptl_clinic_id,
      mobile: list.mobile_no,
      client_name: list.full_name,
    }
    Doc_Helper.setClient_Info(getClientInfo);
    Doc_Helper.setAdmission_id(send);
    this.messageservice.sendMessage("inPatientDS");
  }
}
