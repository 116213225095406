import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../helper_class';
import { Message_data } from 'src/assets/js/Message_data';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pharma-supplier-payment',
  templateUrl: './pharma-supplier-payment.component.html',
  styleUrls: ['./pharma-supplier-payment.component.scss']
})
export class PharmaSupplierPaymentComponent implements OnInit {
  public locationArray = [];
  public loadLocationList = [];
  public pharmacyFacID: any;
  public location: any;
  public locationIP = null;
  public invoicedetails: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  public suppId;
  public fromDate;
  public toDate;
  public currentDate;
  public currDate;
  public totalAmount;
  public pendingAmount;
  private tmr = new Date();
  public supplierList:any=[];
  dtOptions: DataTables.Settings = {};
  isDisabled: boolean = false;
  public cardFlag:boolean;
  public chequeFlag:boolean;
  public transactionFlag:boolean;
  public insurFlag:boolean;
  public showpayflag:boolean=true;
  public showimage: boolean=false;
  public showarray: boolean=true;
  public paid_amount:number=0;
  public payTypeList:any=[];
  public insurerList:any=[];
  public payHistList:any=[];
  public payType;
  public insurerId;
  public cardHolderName;
  public transactionId;
  public cardNumber
  public bankName;
  private closeResult;

  constructor(public http: HttpClient, public routes: ActivatedRoute, 
    public router: Router, public toastr: ToastrService,private modalService: NgbModal) {

   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
       // { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search invoice, date, or due date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.pharmaLocation();
  }

  pharmaLocation() {
    var send_data;
    if (Helper_Class.getIsAdmin() != undefined) {
      send_data = {
        hptl_clinic_id: this.pharmacyID,
      };

    } else {
      send_data = {
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID
      };
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/", send_data, { headers: headers }).subscribe(
      data => {
        var obj =JSON.parse(JSON.stringify(data));
        if (obj.pharma_locations != null) {
          this.locationArray = obj.pharma_locations;
          for (var i = 0; i < this.locationArray.length; i++) {
            this.loadLocationList.push({
              location_id: this.locationArray[i].pharma_facility_id + " - " + this.locationArray[i].location,
              pharma_facility_id: this.locationArray[i].pharma_facility_id,
            });
            if (i == 0) {
              //this.getInvoiceDetails(this.locationArray[i].pharma_facility_id);
            }
          }
          if (this.loadLocationList.length != 0) {
            this.location = this.loadLocationList[0].location_id;
          }
          this.getSuppliers();
        }
      },
      error => { });
  }

  getSuppliers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gsup/",{
        pharmacy_id: this.pharmacyID,
      }, { headers: headers }).subscribe(
        data => {
          this.supplierList = [];
          var suppliers = JSON.parse(JSON.stringify(data))
          if (suppliers.supplier_details != null && suppliers.supplier_details.length != 0) {
            this.supplierList = suppliers.supplier_details;
            this.suppId = suppliers.supplier_details[0].supp_id;
          }
          this.getDate();
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
  }

  getDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.currDate= obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.getInvoiceDetails();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getInvoiceDetails(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gidps', {
      pharma_id:this.pharmacyID,
      supp_id:this.suppId,
      from_date:this.fromDate,
      to_date:this.toDate,
    },
      { headers: headers })
      .subscribe(
        response => {
          this.invoicedetails=[];
          var obj = JSON.parse(JSON.stringify(response));
          if(obj != undefined && obj.invoice_details.length !=0){
            this.totalAmount= (obj.inv_amount).toFixed(2);
            this.pendingAmount=(obj.balance).toFixed(2);
            for(var i=0;i<obj.invoice_details.length;i++){
              var balance;
              var paidflag;
              if(obj.invoice_details[i].payment != undefined){
                balance=parseInt(obj.invoice_details[i].invoice_amount)-parseInt(obj.invoice_details[i].payment);
                paidflag = obj.invoice_details[i].paid_flag;
              } else {
                balance=parseInt(obj.invoice_details[i].invoice_amount);
                paidflag="Unpaid";
              }
              
              var invdate = obj.invoice_details[i].invoice_date.split("-");
              var duedate = obj.invoice_details[i].inv_due_date.split("-");
              var dueflag;
              if((obj.invoice_details[i].inv_due_date <= this.currDate)&& balance !=0){
                dueflag = 1;

              } else {
                dueflag = 0;
              }
              var disableflag;
              if(balance !=0){
                disableflag = false;

              } else {
                disableflag = true;
              }

              if(obj.pay_history != undefined){
                this.payHistList = obj.pay_history;
                this.showimage=true;
              }

              this.invoicedetails.push({
                receivables_id: obj.invoice_details[i].receivables_id,
                supp_id:obj.invoice_details[i].supp_id,
                name:obj.invoice_details[i].name,
                invoice_no:obj.invoice_details[i].invoice_no,
                invoice_date: invdate[2]+"-"+invdate[1]+"-"+invdate[0],
                inv_due_date: duedate[2]+"-"+duedate[1]+"-"+duedate[0],
                invoice_amount: obj.invoice_details[i].invoice_amount,
                payment:obj.invoice_details[i].payment,
                balance:balance,
                paid_flag:paidflag,
                dueflag:dueflag,
                paid_amount:"0",
                disableflag:disableflag,
              })
            }
          }
        },
      error => {
        this.toastr.error(Message_data.defaultErr);
      });
  }

  showPaumentHistory(content:any){
    this.showarray=!this.showarray;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  calculatePaidAmount(invoice){
    this.getPaymentType();
    this.getInsurers();

    for(var i=0;i<this.invoicedetails.length;i++){
      this.showpayflag=false;
      if(this.invoicedetails[i].receivables_id == invoice.receivables_id){
        if(invoice.paid_amount == ""){
          this.invoicedetails[i].paid_amount="0";
        }

        var bal;
        if(invoice.payment != undefined){
          bal = parseInt(this.invoicedetails[i].invoice_amount)-(parseInt(invoice.payment+invoice.paid_amount));
          this.paid_amount += parseInt(this.invoicedetails[i].paid_amount);

        } else {
          bal = parseInt(this.invoicedetails[i].invoice_amount)-(parseInt(invoice.paid_amount));
          this.paid_amount += parseInt(this.invoicedetails[i].paid_amount);
        }
        this.invoicedetails[i].balance=bal;
        
        if(bal == 0 ){
          this.invoicedetails[i].paid_flag="Fully paid";
        } else if(bal == this.invoicedetails[i].invoice_amount){
          this.invoicedetails[i].paid_flag="Unpaid";
        } else {
          this.invoicedetails[i].paid_flag="Partially paid";
        }
      }
    }
    
  }

  getExcelData(data){
    var excel_data = [];
    var header = ['Supplier', 'Invoice', 'Invoice date', 'Invoice amount', 'Paid amount', 'Balance', 'Status']
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Stock status report');
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })
    for (let invoice of this.invoicedetails) {
      excel_data.push({
        name: invoice.name,
        invoice_no: invoice.invoice_no,
        invoice_date: invoice.invoice_date,
        invoice_amount: invoice.invoice_amount,
        paid_amount: invoice.paid_amount,
        balance: invoice.balance,
        paid_flag: invoice.paid_flag
      })
    }
    for (let x1 of excel_data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(7);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "supplier_payments" + '-' + new Date().valueOf() + '.xlsx');
    });
  }

  save_data(){
    var send_data={
      supplier_id:this.suppId,
      pharma_id:this.pharmacyID,
      amount:this.paid_amount,
      pay_type: this.payType,
      insurer: this.insurerId,
      card_no : this.cardNumber,
      card_holder_name : this.cardHolderName,
      transaction_no : this.transactionId,
      bank_name: this.bankName,
      payments:this.invoicedetails,
      country:"IN",
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/sspp', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if(obj.key =="1"){
            this.toastr.success("Payment details update successfully");
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  pay_change(){
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3")
      this.insurFlag = false;
    else if(this.payType == "4" || this.payType == "5" || this.payType == "6")
      this.transactionFlag = false;
    else if(this.payType == "2"){
      this.transactionFlag = false;
      this.cardFlag = false;
    }else if(this.payType == "7")
      this.chequeFlag = false;
    else 
      this.insurFlag = true;
  }

  getInsurers() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getPaymentType() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeList = obj.payments;
          this.payType = this.payTypeList[0].pay_id;
          this.pay_change();
        },
        error => {
         this.toastr.error(Message_data.defaultErr);
        });
  }
}
