import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FilterpageComponent } from './filterpage/filterpage.component';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { OrdercreateComponent } from './ordercreate/ordercreate.component';
import { PharmaHomeComponent } from './pharma-home/pharma-home.component';
import { PharmacycurrentinformationComponent } from './pharmacycurrentinformation/pharmacycurrentinformation.component';
import { PharmacypastinformationComponent } from './pharmacypastinformation/pharmacypastinformation.component';
import { PharmacymoduleOrderdetiledviewComponent } from './pharmacymodule-orderdetiledview/pharmacymodule-orderdetiledview.component';
import { PharmaSupplierPaymentComponent } from './pharma-supplier-payment/pharma-supplier-payment.component';


const routes: Routes = [

  // {path:'pharmareg',component:PharmaRegistrationComponent},
  { path: 'pharmahome', component: PharmaHomeComponent },
  { path: 'pharmacy', component: PharmacyComponent },
  // {path:'pharmaproduct',component:PharmaProductMstComponent},
  // {path:'pharmainventory',component:PharmaInventoryReceivablesComponent},
  // {path:'pharmainventoryupload',component:PharmaInventoryUploadComponent},
  // {path:'pharmastockstatus',component:PharmaStockStatusComponent},
  // {path:'pharmasalesreport',component:PharmaSalesReportComponent},
  // {path:'pharmareceivablereport',component:PharmaReceivableReportComponent},
  // {path:'pharmahelp',component:PharmaHelpComponent},
  // {path:'purchaseorderdetailview',component:PurchaseorderviewComponent},
  // {path:'pharmasuppliermst',component:PharmaSupplierMstComponent},
  // {path:'purchaserequest',component:PharmapurchaserequestComponent},
  // {path:'purchaseorder',component:PharmapurchaseordermstComponent},
  { path: 'currentpharmacydetails', component: PharmacycurrentinformationComponent },
  { path: 'pastpharmacydetails', component: PharmacypastinformationComponent },
  { path: 'ordercreate', component: OrdercreateComponent },
  { path: 'filterpage', component: FilterpageComponent },
  { path: 'pharmacymoduleorderdetailview', component: PharmacymoduleOrderdetiledviewComponent },
  { path: 'supppay',component: PharmaSupplierPaymentComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PharmacyRoutingModule { }
