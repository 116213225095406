import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Date_Formate, Ignorecase_data } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diag-app-create',
  templateUrl: './diag-app-create.component.html',
  styleUrls: ['./diag-app-create.component.scss']
})
export class DiagAppCreateComponent implements OnInit {
  public disableDOBFlag: boolean = false;
  public disableAgeFlag: boolean = false;
  public somePlaceholder;
  public typeFilter;
  public firstNamePatient;
  public diagId;
  public clientDetailsArray:any = [];
  public clientFirstName;
  public clientMidName;
  public clientLastName;
  public appointmentValue;
  public clientID;
  public conformFlag: boolean;
  public userType: boolean;
  public newUserFlag: boolean;
  public existuserFlag: boolean;
  public searchFlag: boolean;
  public bookNowFlag: boolean;
  public showUltraTestFlag: boolean;
  public showCultureTestFlag: boolean;
  public showBloodTestFlag: boolean;
  public showUrineTestFlag: boolean;
  public showFeaceTestFlag: boolean;
  public showXrayTestFlag: boolean;
  public showScanTestFlag: boolean;
  public showElectroCardioTestFlag: boolean;
  public typeApp;
  public Filter;
  public referedNameArray;
  public locationArray:any = [];
  public cityArray:any = [];
  public stateArray:any = [];
  public countryArray:any = [];
  public referedDoctorArray:any = [];
  public salListArray:any = [];
  public testListArray:any = [];
  public bloodTestArray:any = [];
  public urineTestArray:any = [];
  public feaceTestArray:any = [];
  public XrayTestArray:any = [];
  public scanTestArray:any = [];
  public ultraTestArray:any = [];
  public CultureTestArray:any = [];
  public electroCardioTestArray:any = [];
  public bloodTestlistArray:any = [];
  public urineTestlistArray:any = [];
  public faceTestlistArray:any = [];
  public xrayTestlistArray:any = [];
  public scanTestlistArray:any = [];
  public ultraTestlistArray:any = [];
  public cultureTestlistArray:any = [];
  public electroCardioTestlistArray:any = [];
  public testGroupArray:any = [];
  public sendData: any = {};
  public testNameArray;
  public visitSession;
  public getVisitingSessionArray;
  public diagTest: string;
  public clientAddress1: string = null!;
  public clientAddress2: string = null!;
  public clientLocation: string = null!;
  public clientCity: string = null!;
  public clientState: string = null!;
  public clientCountry: string = null!;
  public clientLocationId: string = null!;
  public clientCityId: string = null!;
  public clientStateId: string = null!;
  public clientcountryId: string = null!;
  public clientZipCode: string = null!;
  public referedBy: string = null!;
  public clientNumber: number;
  public clientSymptom: string;
  public clientDOB: string = null!;
  public clnt_sal: string;
  public BP_01: string;
  public BP_02: string;
  public ageURL: string;
  public appDate: string;
  public locationURL: string;
  public locationNameURL: string;
  public cityURL: string;
  public stateURL: string;
  public countryURL: string;
  public DoctorIDURL: string;
  public dateURL: string;
  public saveURL: string;
  public salURL: string;
  public splURL: string;
  public zipcodeURL: string;
  public docHospitalURL: string;
  public hospId: string;
  public clientId: string;
  public relationId: string;
  public subRelationId: string;
  public clientGender: string;
  public clientAge;
  public clientType: string;
  public currentDate: string;
  public currentTime: string;
  public currentYear: string;
  public homecare: string;
  public appointmentDate;
  public Select = "Select";
  public maxLength: number = 0;

  public concession;
  public amount;
  public inGST: boolean;
  public amountAfternoon;
  public priceTable:any = [];
  public balance = "0.00";
  public splArray;
  public speclization;
  public sessionFlag: boolean = true
  public timeSession;
  public startTime;
  public endTime;
  public sessionTimeStart: any = [];
  public sessionTimeEnd: any = [];
  public paymentArray: any = [];
  public insuranceFlag;
  public payType: string;
  private pay_desc: string;
  public insuranceId: string;
  public insurancyArray: any = [];
  public ynopt2: boolean;
  public ynopt1: boolean;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public bankName;
  public inpatient: boolean = false;
  public doctorName;
  public doctorRegId;
  public billingListArray:any = [];
  public selectedDocName;
  public appoint;
  public appID;
  public patinetName;
  public savePrescriptionFlag: boolean;
  public presID;
  public advance = "0.00";
  public remaingBalance = "0.00";
  public test_name;
  // include biapsy
  public biopsyTestlistArray:any = [];
  public biopsyTestArray:any = [];
  public showBiopsyTestFlag: boolean;
  public diagTestGroup;
  public test_group;
  public diagtest_flag: boolean = false;
  public selTestGroupArray:any = [];
  public paymentsarray:any=[];
  public paytypelist:any=[];
  public payType1: string;
  public amountval: number;
  public showTestGroupFlag;
  public userinfo;
  public pat_type;
  private sno:number=1;
  public paytypreq:boolean=false;
  public isoutsource:boolean=false;
  public ageRead: boolean = false;
  public outarray:any=[];
  private outpayarray:any=[];
  public priority = "0";
  public showclient:boolean=true;
  private nodob:boolean=false;
  public insurFlag1:boolean=true;
  public cardFlag1:boolean=true;
  public tranFlag1:boolean=true;
  public chequeFlag1:boolean=true;

  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, 
    public http: HttpClient, public dialog: MatDialog, public frontdeskservice: MenuViewService) {
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.typeFilter = "0";
    this.concession = "0";
    this.conformFlag = true;
    this.searchFlag = false;
    this.typeApp = "New";
    this.userType = true;
    this.clientType = "Existing";
    this.existuserFlag = false;
    this.newUserFlag = true;
    this.bookNowFlag = true;
    this.zipcodeURL=ipaddress.getIp +"usercontroller/locbyzipcode";
    this.locationURL = ipaddress.getIp + "usercontroller/loc";
    this.locationNameURL = ipaddress.getIp + "usercontroller/locbyname";
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.salURL = ipaddress.getIp + "gen/sal";
    this.splURL = ipaddress.getIp + "adm/gsplbyhptl";
    this.docHospitalURL = ipaddress.getIp + "search/docbyhs";
    this.saveURL = ipaddress.getIp + "appointment/rapp";
    this.DoctorIDURL = ipaddress.getIp + "search/doctorbyid/";
    this.dateURL = ipaddress.getIp + "adm/curdate";
    this.ageURL = ipaddress.getIp + "adm/ddif";
  }

  ngOnInit(): void {
    var hospital = Helper_Class.getInfo().hospitals;
    this.userinfo = Helper_Class.getInfo();
    this.Filter = "name"
    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.clientcountryId = this.userinfo.country;//localStorage.getItem("hptl_country");
      this.clientStateId = this.userinfo.state;//localStorage.getItem("hptl_state");
      this.clientCityId = this.userinfo.city; //localStorage.getItem("hptl_city");
      this.clientLocationId = this.userinfo.location //localStorage.getItem("hptl_location");

    } else {
      this.clientcountryId = hospital[0].country;//localStorage.getItem("hptl_country");
      this.clientStateId = hospital[0].state;//localStorage.getItem("hptl_state");
      this.clientCityId = hospital[0].city; //localStorage.getItem("hptl_city");
      this.clientLocationId = hospital[0].location //localStorage.getItem("hptl_location");
    }

    this.pat_type="out-patient";
    this.ageRead = false;
    this.changeClientType('client', 1, 'no');
    this.diagId = Diagnosis_Helper.getCreateDiagAppt().center_id;  //this.navParams.get("center_id");
    this.showBloodTestFlag = true;
    this.showUrineTestFlag = true;
    this.showFeaceTestFlag = true;
    this.showXrayTestFlag = true;
    this.showScanTestFlag = true;
    this.showUltraTestFlag = true;
    this.showCultureTestFlag = true; 
    this.showElectroCardioTestFlag = true;

    if(Helper_Class.getInfo().user_type=="diagnosis"){
      if(Helper_Class.getInfo().hospitals[0].bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }    
      
    } else {
      if(Helper_Class.getInfo().bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }
    }

    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.diagId = Diagnosis_Helper.getCreateDiagAppt().center_id;
    }
    this.ynopt1 = true;
    this.insuranceFlag = true;
    this.savePrescriptionFlag = false;
    this.diagTestGroup = "test";
    
    this.getCurrDateTime();
    this.getSalutations();
    this.getSpeciality();
    this.getDiagTestTypes();
    this.getPaymentType();
    this.getInsurers();
  }

  backOption() {
    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.frontdeskservice.sendMessage("manageappointments");
    } else {
      this.diagnosisservice.sendMessage("appointment");
    }
  }

  filterByPatients() {//based client select placeholder will be change
    if (this.Filter == "name") {
      this.somePlaceholder = "Search by first name";
      this.typeFilter = "0";
      this.firstNamePatient = "";
      this.clientDetailsArray = [];
      this.clientFirstName = "";
      this.clientMidName = "";
      this.clientLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 50;

    } else if (this.Filter == "mobile") {
      this.somePlaceholder = "Search by mobile number";
      this.typeFilter = "1";
      this.firstNamePatient = "";
      this.clientDetailsArray = [];
      this.clientFirstName = "";
      this.clientMidName = "";
      this.clientLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 10;
      
    } else if (this.Filter == "patient_id") {
      this.somePlaceholder = "Search by patient id";
      this.typeFilter = "2";
      this.firstNamePatient = "";
      this.clientDetailsArray = [];
      this.clientFirstName = "";
      this.clientMidName = "";
      this.clientLastName = "";
      this.appointmentValue = undefined;
      this.maxLength = 50;
    }
  }

  getDiagTestTypes() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtests/',
      {
        diag_centre_id: this.diagId
      },
      { headers: headers })
      .subscribe(
        response => {
          this.testListArray = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.test_types != null) {
            this.testListArray = obj.test_types;
          }
          this.diagTest="Blood Test";
          this.diagTestChange();

          if(obj.outsource != undefined){
            this.isoutsource=true;
          }
        },
        error => { });
  }

  getTestGroups() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gpkgd/',
      {
        diag_centre_id: this.diagId
      },
      { headers: headers })
      .subscribe(
        response => {
          this.testGroupArray = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.packages != null) {
            this.testGroupArray = obj.packages;
          }
        },
        error => { });
  }

  searchUpper() {
    if (this.Filter == "name")
      this.firstNamePatient = this.firstNamePatient.toLocaleUpperCase();
  }

  toUpperFirstName() {
    if (this.clientFirstName != null)
      this.clientFirstName = this.clientFirstName.toLocaleUpperCase();
  }

  toUpperMidName() {
    if (this.clientMidName != undefined)
      this.clientMidName = this.clientMidName.toLocaleUpperCase();
  }

  toUpperLastName() {
    if (this.clientLastName != undefined)
      this.clientLastName = this.clientLastName.toLocaleUpperCase();
  }

  getCurrDateTime() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.dateURL, { country: ipaddress.country_code }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data))
        this.currentDate = Date_Formate(dataval.current_date);
        this.currentTime = dataval.current_time;
        this.currentYear = dataval.current_date.split('-')[0];
        this.appointmentDate = this.currentDate;
        this.appDate = dataval.current_date;
        this.currentDate = dataval.current_date;
        var curtime = this.currentTime.split(":");
        
        if(parseInt(curtime[0])<12){
          this.getVisitingSessionArray = [{ "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
          this.visitSession = this.getVisitingSessionArray[0].description;
          this.selectSession();

        } else if(parseInt(curtime[0])>=12 && parseInt(curtime[0])<16){
          this.getVisitingSessionArray = [{ "description": "Afternoon" }, { "description": "Evening" }];
          this.visitSession = this.getVisitingSessionArray[0].description;
          this.selectSession();

        } else if(parseInt(curtime[0])>=16){
          this.getVisitingSessionArray = [{ "description": "Evening" }];
          this.visitSession = this.getVisitingSessionArray[0].description;
          this.selectSession();
        }
      },
      error => { });
  }

  changeName() {
    this.clientDetailsArray = [];
  }

  selectUserType() {
    if (this.clientType == "New") {
      this.userType = false;
      this.newUserFlag = false;
      this.existuserFlag = true;
      this.searchFlag = true;
      this.clientId = "0";
      this.relationId = "1";
      this.clientGender = "Male";
      this.clientFirstName = null;
      this.clientMidName = null;
      this.clientLastName = null;
      this.clientGender = "";

      this.clientAddress1 = "NA";
      this.clientAddress2 = null;
      this.clientNumber = null;
      this.clientSymptom = "";
      this.clientDOB = null;
      this.referedBy = "";

      this.clientZipCode=this.userinfo.hospitals[0].zipcode;
      this.clientLocationId=this.userinfo.hospitals[0].location;
      this.clientLocation=this.userinfo.hospitals[0].location_desc;
      this.selectLocation(this.clientLocation)
      this.clientCityId=this.userinfo.hospitals[0].city;
      this.clientCity=this.userinfo.hospitals[0].city_desc;
      this.clientStateId=this.userinfo.hospitals[0].state;  
      this.clientState=this.userinfo.hospitals[0].state_desc; 
      this.clientcountryId = this.userinfo.hospitals[0].country;
      this.clientCountry = this.userinfo.hospitals[0].country_desc;

      if (this.countryArray.length == 0) {
        this.getCountries(this.clientStateId,"0");
      } 
      this.clnt_sal = "select";

    } else {
      this.userType = true;
      this.newUserFlag = true;
      this.existuserFlag = false;
      this.searchFlag = false;
      this.clientFirstName = null;
      this.clientMidName = null;
      this.clientLastName = null;
      this.clientNumber = null;
    }
  }

  getSalutations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salURL, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.salListArray.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
          this.clnt_sal = "select";
          this.changeSalutation(this.clnt_sal);
        }
      });
  }

  getSpeciality() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getspecialization/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.splArray = obj.specialization;
        },
        error => { });
  }

  zipcode_change(){
    if(this.clientZipCode.length >4){
      this.locationArray = [];
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.zipcodeURL, {
        zipcode: this.clientZipCode,
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data))
          if (obj.locations != null && obj.locations.length != 0) {
            if( obj.locations.length ==1){
              this.clientLocationId = obj.locations[0].location_id;
              this.clientLocation = obj.locations[0].description;
              this.clientZipCode = obj.locations[0].pincode;
              this.getCities(this.clientLocation,0);
            } else {
              this.locationArray = obj.locations;
              console.log("../location" + JSON.stringify(this.locationArray))
            } 
          }
        },
        error => {
          this.toastr.error("Unable to fetch location details, please try later");
        }
      )
    } else {
      this.locationArray = [];
    }
  }

  changeLocation(locat_desc) {
    this.clientLocation = locat_desc;
    this.locationArray = [];
   
    if (this.clientLocation != null && this.clientLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameURL, {
        name: this.clientLocation,
        //city_id: this.clientCityId,
      }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0)
            this.locationArray = dataval.locations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.locationArray = [];
    }
  }

  selectLocation(location) {
    this.clientLocation = location;
    for (var i = 0; i < this.locationArray.length; i++) {
      if (this.locationArray[i].location_id == location.location_id) {
        this.clientLocationId = this.locationArray[i].location_id;
        this.clientLocation = this.locationArray[i].description;
        this.clientZipCode = this.locationArray[i].pincode;
      }
    }
    this.locationArray = [];
    this.getCities(this.clientLocation,'0');
  }

  getCities(state, flag) {
    this.cityArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, {loc_name: this.clientLocation,zipcode: this.clientZipCode },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.cities != null) {
            this.cityArray = dataval.cities;
            if(this.cityArray.length ==1){
              for (var i = 0; i < this.cityArray.length; i++) {
                if (this.cityArray[i].city_id == this.clientCityId) {
                  this.clientCityId = this.cityArray[i].city_id;
                  this.clientCity = this.cityArray[i].city_desc;
                  this.getStates(this.clientCityId,0)
  
                  break;
                } else {
                  this.clientCityId = this.cityArray[0].city_id;
                  this.clientCity = this.cityArray[0].city_desc;
                  this.getStates(this.clientCityId,0)
                }
              }
            } else {
              this.clientCityId = this.cityArray[0].city_id;
              this.clientCity = this.cityArray[0].city_desc;
              this.getStates(this.clientCityId,0)
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    for (var i = 0; i < this.cityArray.length; i++) {
      if (this.cityArray[i].city_desc == clnt_city) {
        this.clientCityId = this.cityArray[i].city_id;
        this.clientCity = this.cityArray[i].city_desc;
        this.getStates(this.clientCityId,'0');
      }
    }
  }

  getStates(city, flag) {
    this.stateArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateURL, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.states != null) {
            this.stateArray = dataval.states;
            for (var i = 0; i < this.stateArray.length; i++) {
              if (this.stateArray[i].state_id == this.clientStateId) {
                this.clientStateId = this.stateArray[i].state_id;
                this.clientState = this.stateArray[i].state_desc;
                this.getCountries(this.clientStateId,'0');
                break;
              } else {
                this.clientStateId = this.stateArray[0].state_id;
                this.clientState = this.stateArray[0].state_desc;
                this.getCountries(this.clientStateId,'0');
              }
            }
          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.stateArray.length; i++) {
      if (this.stateArray[i].stage_desc == clnt_state) {
        this.clientStateId = this.stateArray[i].state_id;
        this.clientState = this.stateArray[i].stage_desc;
        this.getCountries(this.clientStateId,'0');
      }
    }
  }

  getCountries(stateid, flag) {
    this.countryArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.countryURL, { state_id: stateid },{ headers: headers }).subscribe(
      data => {
        this.countryArray = [];
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.countries != null) {
          this.countryArray = dataval.countries;
          for (var c = 0; c < this.countryArray.length; c++) {
            if (this.countryArray[c].country_id == this.clientcountryId) {
              this.clientCountry = this.countryArray[c].country_desc;
              this.clientcountryId = this.countryArray[c].country_id;
              //this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getLocation(location) {
    this.clientLocation = location;
    for (var i = 0; i < this.locationArray.length; i++) {
      if (this.locationArray[i].location_id == location.location_id) {
        this.clientLocationId = this.locationArray[i].location_id;
        this.clientLocation = this.locationArray[i].description;
        this.clientZipCode = this.locationArray[i].pincode;
      }
    }
    this.locationArray = [];
  }

  changePatientName(name) {//based client search filters patient  names
    var type_name;
    if (name != undefined && name != "") {
      if (this.Filter == "name")
        type_name = "name";
      else if (this.Filter == "mobile")
        type_name = "mobile";
      else
        type_name = "patient_id";

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/cid',
        {
          first_name: name,
          type: type_name,
          hosp_diag: "diagnosis"
        },
        { headers: headers })
        .subscribe(
          response => {
            this.clientDetailsArray = [];
            var obj = JSON.parse(JSON.stringify(response));

            if (obj.clients != null && obj.clients.length != 0)
              this.clientDetailsArray = obj.clients;
            else
              this.toastr.error(Message_data.clientNotFound);

            console.log("PATIENT DETAILS " + JSON.stringify(obj))
            if (obj.clients.length == 1) {
              this.clientID = this.clientDetailsArray[0].patient_id;
            } else {
              for (var i = 0; i < obj.clients.length; i++) {
                if (obj.clients[i].patient_id != undefined) {
                  this.clientID = obj.clients[i].patient_id;
                }
              }
            }
            if (this.clientID != undefined) {
              this.changeClientId(this.clientID);
            }
          }, error => {
            this.toastr.error(Message_data.network);
          });
    } else {
      this.toastr.error(Message_data.fillText);
    }
  }

  changeClientId(client) {// get the the client detials based on patient id
    this.clientFirstName = "";
    this.clientMidName = undefined;
    this.clientLastName = "";
    for (var i = 0; i < this.clientDetailsArray.length; i++) {
      if (this.clientDetailsArray[i].patient_id == client) {
        this.clientFirstName = this.clientDetailsArray[i].first_name;
        this.clientMidName = this.clientDetailsArray[i].middle_name;
        this.clientLastName = this.clientDetailsArray[i].last_name;
        this.clientId = this.clientDetailsArray[i].client_reg_id;
        this.relationId = this.clientDetailsArray[i].relation_id;
        this.subRelationId = this.clientDetailsArray[i].sub_rel_id;
        this.retrieveClient();
      }
    }
  }

  ChangeAge() {
    if (this.clientAge != "") {
      this.disableDOBFlag = true;
      this.clientDOB = "";
    } else {
      this.disableDOBFlag = false;
    }
    this.nodob = true;
    var dateval = this.currentDate.split("-");
    this.clientDOB = (parseInt(dateval[0]) - parseInt(this.clientAge))+"-01-01";
    this.disableDOBFlag = false;
  }

  clickDate(data) {
    this.clientDOB = data;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageURL, {
      dobdate: Date_Formate(this.clientDOB),
      curdate: Date_Formate(this.currentDate),
    }, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));

        if (obj != undefined) {
          this.clientAge = obj.value;
          this.disableAgeFlag = true;
        }
      });
  }

  retrieveClient() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      {
        user_id: this.clientId,
        relation_id: this.relationId,
        sub_rel_id: this.subRelationId,
        country: ipaddress.country_code
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.client_id != null) {
            Helper_Class.setClientId(obj.client_id);
            // Editable Fields
            this.clientFirstName = encrypt_decript.Decript(obj.first_name);

            if (obj.middle_name != null) {
              this.clientMidName = encrypt_decript.Decript(obj.middle_name);
            }
            if (obj.salutation != null) {
              for (var i = 0; i < this.salListArray.length; i++) {
                if (this.salListArray[i].sal_desc == obj.salutation) {
                  this.clnt_sal = this.salListArray[i].sal_id;
                }
              }
            }
            this.clientLastName = encrypt_decript.Decript(obj.last_name);
            this.clientGender = encrypt_decript.Decript(obj.gender);
            //this.clientDOB = obj.dob != undefined ? Date_Formate(obj.dob) : null;
            if (obj.dob != 'null') {
              this.clientDOB = obj.dob;
              this.selectDob(this.clientDOB)

            } else {
              this.clientDOB = null!;
              //this.dobDisabledFlag = true;
              this.ageRead = false;
            }
            if (obj.age != undefined) {
              this.clientAge = obj.age;
            }
            this.clientNumber = encrypt_decript.Decript(obj.mobile);

            if (obj.address1 != null && obj.address1 != "") {
              this.clientAddress1 = encrypt_decript.Decript(obj.address1);
            }

            if (obj.address2 != null && obj.address2 != "") {
              this.clientAddress2 = encrypt_decript.Decript(obj.address2);
            }

            this.clientLocation = obj.location_desc;
            this.clientLocationId = obj.location;
            this.clientCountry = obj.country_desc;
            this.clientcountryId = obj.country;
            this.clientState = obj.state_desc;
            this.clientStateId = obj.state;
            this.clientCity = obj.city_desc;
            for (var k = 0; k < this.cityArray.length; k++) {
              if (obj.city_desc == this.cityArray[k].city_desc) {
                this.clientCity = this.cityArray[k].city_desc;
              }
            }
            this.clientZipCode = encrypt_decript.Decript(obj.zipcode);
          }
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  selectDob(e) {
    if (this.clientDOB != undefined){
      this.ageRead = true;
      this.clientDOB = e;
      //this.ageDisabledFlag = true;

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "adm/ddif", {
        dobdate: Date_Formate(this.clientDOB),
        curdate: Date_Formate(this.currentDate),
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj != undefined){
            this.clientAge = obj.value;
          }
        });
    } else {
      this.ageRead = false;
    }
  }

  finekeyInArray(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].test_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  contains(elem) {
    for (var i in this) {
      if (this[i] == elem) return true;
    }
    return false;
  }

  getAgeValue(data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageURL, {
      dobdate: data,
      curdate: this.currentDate,
    }, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));

        if (obj != undefined) {
          this.clientAge = obj.value;
        }
      })
  }

  diagTestChange() {
    if (this.diagTest != undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtestdetails/',
        {
          diag_centre_id: this.diagId,
          test_type: this.diagTest
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.test_details != null) {
              if (Ignorecase_data("Blood Test", this.diagTest) == true) {
                if (this.bloodTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.bloodTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.bloodTestlistArray;
                } else {
                  this.testNameArray = this.bloodTestlistArray;
                }

              } else if (Ignorecase_data("Urine Test", this.diagTest) == true) {
                if (this.urineTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.urineTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.urineTestlistArray;
                } else {
                  this.testNameArray = this.urineTestlistArray;
                }

              } else if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
                if (this.faceTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.faceTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.faceTestlistArray;
                } else {
                  this.testNameArray = this.faceTestlistArray;
                }

              } else if (Ignorecase_data("Scan", this.diagTest) == true) {
                if (this.scanTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.scanTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.scanTestlistArray;
                } else {
                  this.testNameArray = this.scanTestlistArray;
                }

              } else if (Ignorecase_data("X-ray", this.diagTest) == true) {
                if (this.xrayTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.xrayTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.xrayTestlistArray;
                } else {
                  this.testNameArray = this.xrayTestlistArray;
                }

              } else if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
                if (this.ultraTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.ultraTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.ultraTestlistArray;
                } else {
                  this.testNameArray = this.ultraTestlistArray;
                }
              } else if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
                if (this.electroCardioTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.electroCardioTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.electroCardioTestlistArray;
                } else {
                  this.testNameArray = this.electroCardioTestlistArray;
                }
              } else if (Ignorecase_data("Culture", this.diagTest) == true) {
                if (this.cultureTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.cultureTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.cultureTestlistArray;
                } else {
                  this.testNameArray = this.cultureTestlistArray;
                }
              } else if (Ignorecase_data("Biopsy", this.diagTest) == true) {
                if (this.biopsyTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.biopsyTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.biopsyTestlistArray;
                } else {
                  this.testNameArray = this.biopsyTestlistArray;
                }
              }
            }
          },
          error => { });
    } else {
      this.testNameArray = [];
    }
  }

  clickTest(e: any, data) {
    if (e == true) {
      if (this.diagTestGroup == "test") {
        for (var i = 0; i < this.testNameArray.length; i++) {
          if ((Ignorecase_data("Blood Test", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.bloodTestlistArray.length; i++) {
              if (this.bloodTestlistArray[i].test_id == data) {
                this.bloodTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Urine Test", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.urineTestlistArray.length; i++) {
              if (this.urineTestlistArray[i].test_id == data) {
                this.urineTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Faeces Test", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.faceTestlistArray.length; i++) {
              if (this.faceTestlistArray[i].test_id == data) {
                this.faceTestlistArray[i].checked = true;
              }
            }
          }

          if ((Ignorecase_data("Scan", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.scanTestlistArray.length; i++) {
              if (this.scanTestlistArray[i].test_id == data) {
                this.scanTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("X-ray", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.xrayTestlistArray.length; i++) {
              if (this.xrayTestlistArray[i].test_id == data) {
                this.xrayTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Ultra Sound", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.ultraTestlistArray.length; i++) {
              if (this.ultraTestlistArray[i].test_id == data) {
                this.ultraTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Electrocardio", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
              if (this.electroCardioTestlistArray[i].test_id == data) {
                this.electroCardioTestlistArray[i].checked = true;
              }
            }
          }
          if ((Ignorecase_data("Culture", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.cultureTestlistArray.length; i++) {
              if (this.cultureTestlistArray[i].test_id == data) {
                this.cultureTestlistArray[i].checked = true;
              }
            }
          }
        }

      } else {
        for (var i = 0; i < this.testGroupArray.length; i++) {
          if (this.testGroupArray[i].test_id == data) {
            this.testGroupArray[i].checked = false;
          }
        }
      }

    } else {
      if (this.diagTestGroup == "test") {
        for (var i = 0; i < this.testNameArray.length; i++) {
          if (Ignorecase_data("Blood Test", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.bloodTestlistArray.length; i++) {
              if (this.bloodTestlistArray[i].test_id == data) {
                this.bloodTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Urine Test", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.urineTestlistArray.length; i++) {
              if (this.urineTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.urineTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Faeces Test", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.faceTestlistArray.length; i++) {
              if (this.faceTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.faceTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Scan", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.scanTestlistArray.length; i++) {
              if (this.scanTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.scanTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("X-ray", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.xrayTestlistArray.length; i++) {
              if (this.xrayTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.xrayTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Ultra Sound", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.ultraTestlistArray.length; i++) {
              if (this.ultraTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.ultraTestlistArray[i].checked = false;
              }
            }
          }
          if ((Ignorecase_data("Electrocardio", this.diagTest) == true) && (this.testNameArray[i].test_id == data)) {
            for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
              if (this.electroCardioTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.electroCardioTestlistArray[i].checked = false;
              }
            }
          }
          if (Ignorecase_data("Culture", this.diagTest) == true && this.testNameArray[i].test_id == data) {
            for (var i = 0; i < this.cultureTestlistArray.length; i++) {
              if (this.cultureTestlistArray[i].test_id == this.testNameArray[i].test_id) {
                this.cultureTestlistArray[i].checked = false;
              }
            }
          }
        }
      } else {
        for (var i = 0; i < this.testGroupArray.length; i++) {
          if (this.testGroupArray[i].test_id == data) {
            this.testGroupArray[i].checked = false;
          }
        }
      }
    }
  }

  addTest() {
    var results;
    if (this.diagTestGroup == "test") {
      if (this.bloodTestlistArray.length != 0 || this.urineTestlistArray.length != 0 || this.faceTestlistArray.length != 0 || this.xrayTestlistArray.length != 0 || this.scanTestlistArray.length != 0 || this.ultraTestlistArray.length != 0 || this.cultureTestlistArray.length != 0 || this.biopsyTestlistArray.length != 0) {
        if (Ignorecase_data("Blood Test", this.diagTest) == true) {
          for (var i = 0; i < this.bloodTestlistArray.length; i++) {
            if (this.bloodTestlistArray[i].checked == true) {
              if (this.bloodTestArray.length != 0) {
                results = this.finekeyInArray(this.bloodTestArray, this.bloodTestlistArray[i].test_id);

                if (results != true) {
                  this.bloodTestArray.push({
                    test_id: this.bloodTestlistArray[i].test_id,
                    test_name: this.bloodTestlistArray[i].test_name,
                    diag_type: this.bloodTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.bloodTestArray.push({
                  test_id: this.bloodTestlistArray[i].test_id,
                  test_name: this.bloodTestlistArray[i].test_name,
                  diag_type: this.bloodTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.bloodTestArray, "Blood");

        } else if (Ignorecase_data("Urine Test", this.diagTest) == true) {
          for (var i = 0; i < this.urineTestlistArray.length; i++) {
            if (this.urineTestlistArray[i].checked == true) {
              if (this.urineTestArray.length != 0) {
                results = this.finekeyInArray(this.urineTestArray, this.urineTestlistArray[i].test_id);
                if (results != true) {
                  this.urineTestArray.push({
                    test_id: this.urineTestlistArray[i].test_id,
                    test_name: this.urineTestlistArray[i].test_name,
                    diag_type: this.urineTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.urineTestArray.push({
                  test_id: this.urineTestlistArray[i].test_id,
                  test_name: this.urineTestlistArray[i].test_name,
                  diag_type: this.urineTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.urineTestArray, "Urine");

        } else if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
          for (var i = 0; i < this.faceTestlistArray.length; i++) {
            if (this.faceTestlistArray[i].checked == true) {
              if (this.feaceTestArray.length != 0) {
                results = this.finekeyInArray(this.feaceTestArray, this.faceTestlistArray[i].test_id);
                if (results != true) {
                  this.feaceTestArray.push({
                    test_id: this.faceTestlistArray[i].test_id,
                    test_name: this.faceTestlistArray[i].test_name,
                    diag_type: this.faceTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.feaceTestArray.push({
                  test_id: this.faceTestlistArray[i].test_id,
                  test_name: this.faceTestlistArray[i].test_name,
                  diag_type: this.faceTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.feaceTestArray, "Feaces");

        } else if (Ignorecase_data("X-ray", this.diagTest) == true) {
          for (var i = 0; i < this.xrayTestlistArray.length; i++) {
            if (this.xrayTestlistArray[i].checked == true) {
              if (this.XrayTestArray.length != 0) {
                results = this.finekeyInArray(this.XrayTestArray, this.xrayTestlistArray[i].test_id);

                if (results != true) {
                  this.XrayTestArray.push({
                    test_id: this.xrayTestlistArray[i].test_id,
                    test_name: this.xrayTestlistArray[i].test_name,
                    diag_type: this.xrayTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.XrayTestArray.push({
                  test_id: this.xrayTestlistArray[i].test_id,
                  test_name: this.xrayTestlistArray[i].test_name,
                  diag_type: this.xrayTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.XrayTestArray, "Xray");

        } else if (Ignorecase_data("Scan", this.diagTest) == true) {
          for (var i = 0; i < this.scanTestlistArray.length; i++) {
            if (this.scanTestlistArray[i].checked == true) {
              if (this.scanTestArray.length != 0) {
                results = this.finekeyInArray(this.scanTestArray, this.scanTestlistArray[i].test_id);

                if (results != true) {
                  this.scanTestArray.push({
                    test_id: this.scanTestlistArray[i].test_id,
                    test_name: this.scanTestlistArray[i].test_name,
                    diag_type: this.scanTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.scanTestArray.push({
                  test_id: this.scanTestlistArray[i].test_id,
                  test_name: this.scanTestlistArray[i].test_name,
                  diag_type: this.scanTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.scanTestArray, "Scan");

        } else if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
          for (var i = 0; i < this.ultraTestlistArray.length; i++) {
            if (this.ultraTestlistArray[i].checked == true) {
              if (this.ultraTestArray.length != 0) {
                results = this.finekeyInArray(this.ultraTestArray, this.ultraTestlistArray[i].test_id);

                if (results != true) {
                  this.ultraTestArray.push({
                    test_id: this.ultraTestlistArray[i].test_id,
                    test_name: this.ultraTestlistArray[i].test_name,
                    diag_type: this.ultraTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.ultraTestArray.push({
                  test_id: this.ultraTestlistArray[i].test_id,
                  test_name: this.ultraTestlistArray[i].test_name,
                  diag_type: this.ultraTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.ultraTestArray, "Ultra");

        } else if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
          for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
            if (this.electroCardioTestlistArray[i].checked == true) {
              if (this.electroCardioTestArray.length != 0) {
                results = this.finekeyInArray(this.electroCardioTestArray, this.electroCardioTestlistArray[i].test_id);

                if (results != true) {
                  this.electroCardioTestArray.push({
                    test_id: this.electroCardioTestlistArray[i].test_id,
                    test_name: this.electroCardioTestlistArray[i].test_name,
                    diag_type: this.electroCardioTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.electroCardioTestArray.push({
                  test_id: this.electroCardioTestlistArray[i].test_id,
                  test_name: this.electroCardioTestlistArray[i].test_name,
                  diag_type: this.electroCardioTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.electroCardioTestArray, "Electrocardio");

        } else if (Ignorecase_data("Culture", this.diagTest) == true) {
          for (var i = 0; i < this.cultureTestlistArray.length; i++) {
            if (this.cultureTestlistArray[i].checked == true) {
              if (this.CultureTestArray.length != 0) {
                results = this.finekeyInArray(this.CultureTestArray, this.cultureTestlistArray[i].test_id);

                if (results != true) {
                  this.CultureTestArray.push({
                    test_id: this.cultureTestlistArray[i].test_id,
                    test_name: this.cultureTestlistArray[i].test_name,
                    diag_type: this.cultureTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.CultureTestArray.push({
                  test_id: this.cultureTestlistArray[i].test_id,
                  test_name: this.cultureTestlistArray[i].test_name,
                  diag_type: this.cultureTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.CultureTestArray, "Culture");

        } else if (Ignorecase_data("Biopsy", this.diagTest) == true) {
          for (var i = 0; i < this.biopsyTestlistArray.length; i++) {
            if (this.biopsyTestlistArray[i].checked == true) {
              if (this.biopsyTestArray.length != 0) {
                results = this.finekeyInArray(this.biopsyTestArray, this.biopsyTestlistArray[i].test_id);

                if (results != true) {
                  this.biopsyTestArray.push({
                    test_id: this.biopsyTestlistArray[i].test_id,
                    test_name: this.biopsyTestlistArray[i].test_name,
                    diag_type: this.biopsyTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.biopsyTestArray.push({
                  test_id: this.biopsyTestlistArray[i].test_id,
                  test_name: this.biopsyTestlistArray[i].test_name,
                  diag_type: this.biopsyTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.biopsyTestArray, "Biopsy");
        }
        //this.test_name = true;
        if (this.bloodTestArray.length != 0) {
          this.showBloodTestFlag = false;
        }
        if (this.urineTestArray.length != 0) {
          this.showUrineTestFlag = false;
        }
        if (this.feaceTestArray.length != 0) {
          this.showFeaceTestFlag = false;
        }
        if (this.XrayTestArray.length != 0) {
          this.showXrayTestFlag = false;
        }
        if (this.scanTestArray.length != 0) {
          this.showScanTestFlag = false;
        }
        if (this.ultraTestArray.length != 0) {
          this.showUltraTestFlag = false;
        }
        if (this.electroCardioTestArray.length != 0) {
          this.showElectroCardioTestFlag = false;
        }
        if (this.CultureTestArray.length != 0) {
          this.showCultureTestFlag = false;
        }
        if (this.biopsyTestArray.length != 0) {
          this.showBiopsyTestFlag = false;
        }
      }

    } else {
      for (var i = 0; i < this.testGroupArray.length; i++) {
        if (this.testGroupArray[i].checked == true) {
          if (this.selTestGroupArray.length != 0) {
            results = this.finekeyInArray(this.selTestGroupArray, this.testGroupArray[i].diag_packages_id);
            if (results != true) {
              this.selTestGroupArray.push({
                test_id: this.testGroupArray[i].diag_packages_id,
                test_name: this.testGroupArray[i].package_name,
                price: this.testGroupArray[i].price,
                //diag_type: this.testGroupArray[i].diag_type,
              });
            }
          } else {
            this.selTestGroupArray.push({
              test_id: this.testGroupArray[i].diag_packages_id,
              test_name: this.testGroupArray[i].package_name,
              price: this.testGroupArray[i].price,
              //diag_type: this.testGroupArray[i].diag_type,
            });
          }
        }
      }
      this.addAmount(this.selTestGroupArray, "Package");

      if (this.selTestGroupArray.length != 0) {
        this.showTestGroupFlag = false;
      }
    }
  }

  addAmount(test, type_of_test) {
    if (this.diagTestGroup == "test") {
      var subTest;
      
      for (var i = 0; i < test.length; i++) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbyid',
          {
            test_id: test[i].test_id,
            type: "diagnosis",
            country: "IN"
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              this.amount = obj.price;
              var perce = this.concession / 100;
              var amt = this.amount;
              var amt_cons = this.amountAfternoon;
              var net_pay = parseFloat(amt) * parseFloat(perce.toString());
              var tot = amt - net_pay;
              if (isNaN(tot)) {
                tot = 0;
              }
              var paydesc;
              if (this.paymentArray.length != 0) {
                for (var i = 0; i < this.paymentArray.length; i++) {
                  if (this.payType == this.paymentArray[i].pay_id) {
                    paydesc = this.paymentArray[i].pay_desc;
                  }
                }
              }
              this.amountAfternoon = Math.round(tot);
              var results;
              if (this.priceTable.length != 0) {
                for (var j = 0; j < this.priceTable.length; j++) {
                  results = this.finekeyInArray(this.priceTable, obj.diag_tests_id);

                  if (results != true) {
                    var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
                    this.priceTable.push({
                      sno:this.sno,
                      test_id: obj.diag_tests_id,
                      test_name: obj.test_name,
                      price: obj.price,
                      amount_txt: amt_txt,
                      concession: parseFloat(this.concession),
                      fee: parseFloat(this.amountAfternoon).toFixed(2),
                      charge_type: obj.test_name,
                      type_of_test: type_of_test,
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insuranceId,
                      quantity: 1,
                      outarray:obj.outsources,
                      priority: this.priority,
                    });
                    this.changeAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id);
                    this.sno+=1;
                  }
                  
                }

              } else {
                var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
                this.priceTable.push({
                  sno:this.sno,
                  test_id: obj.diag_tests_id,
                  test_name: obj.test_name,
                  price: obj.price,
                  amount_txt: amt_txt,
                  concession: parseFloat(this.concession),
                  fee: parseFloat(this.amountAfternoon).toFixed(2),
                  charge_type: obj.test_name,
                  type_of_test: type_of_test,
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insuranceId,
                  quantity: 1,
                  outarray:obj.outsources,
                  priority: this.priority,
                });
                this.sno+=1;
                this.changeAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id);
              }
              this.balance = "0.00";
              for (var i = 0; i < this.priceTable.length; i++) {
                this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
              }
              this.changeAdvance();
            });
      }

    } else {
      for (var i = 0; i < test.length; i++) {
        this.amount = test[i].price;
        var perce = this.concession / 100;
        var amt = this.amount;
        var amt_cons = this.amountAfternoon;
        var net_pay = parseFloat(amt) * parseFloat(perce.toString());
        var tot = amt - net_pay;
        if (isNaN(tot)) {
          tot = 0;
        }

        this.amountAfternoon = Math.round(tot);
        this.amountAfternoon = Math.round(tot);

        var results;
        if (this.priceTable.length != 0) {
          for (var j = 0; j < this.priceTable.length; j++) {
            results = this.finekeyInArray(this.priceTable, test[i].test_id);
            if (results != true) {
              var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
              this.priceTable.push({
                sno:this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
              });
              this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id);
              this.sno+=1;
            }
          }

        } else {
          var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
          this.priceTable.push({
            sno:this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1
          });
          this.sno+=1;
          this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id);
        }
      }
      this.balance = "0.00";
      if (this.priceTable.length != 0) {
        for (var i = 0; i < this.priceTable.length; i++) {
          this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
        }
        this.changeAdvance();
      }
    }
  }

  removeTest(testid, type) {
    if (type == "Blood") {
      for (var i = 0; i < this.bloodTestArray.length; i++) {
        if (this.bloodTestArray[i].test_id == testid) {
          this.bloodTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.bloodTestlistArray.length; i++) {
        if (this.bloodTestlistArray[i].test_id == testid) {
          this.bloodTestlistArray[i].checked = false;
          break;
        }
      }
      if (this.bloodTestArray.length == 0) {
        this.showBloodTestFlag = true;
      }
      if (Ignorecase_data("Blood Test", this.diagTest) == true) {
        this.testNameArray = this.bloodTestlistArray;
      }
    } else if (type == "Urine") {
      for (var i = 0; i < this.urineTestArray.length; i++) {
        if (this.urineTestArray[i].test_id == testid) {
          this.urineTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.urineTestArray.length == 0) {
        this.showUrineTestFlag = true;
      }
      for (var i = 0; i < this.urineTestlistArray.length; i++) {
        if (this.urineTestlistArray[i].test_id == testid) {
          this.urineTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Urine Test", this.diagTest) == true) {
        this.testNameArray = this.urineTestlistArray;
      }
    } else if (type == "Feace") {
      for (var i = 0; i < this.feaceTestArray.length; i++) {
        if (this.feaceTestArray[i].test_id == testid) {
          this.feaceTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }

      if (this.feaceTestArray.length == 0) {
        this.showFeaceTestFlag = true;
      }
      for (var i = 0; i < this.faceTestlistArray.length; i++) {
        if (this.faceTestlistArray[i].test_id == testid) {
          this.faceTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
        this.testNameArray = this.faceTestlistArray;
      }
    } else if (type == "Xray") {
      for (var i = 0; i < this.XrayTestArray.length; i++) {
        if (this.XrayTestArray[i].test_id == testid) {
          this.XrayTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.XrayTestArray.length == 0) {
        this.showXrayTestFlag = true;
      }
      for (var i = 0; i < this.xrayTestlistArray.length; i++) {
        if (this.xrayTestlistArray[i].test_id == testid) {
          this.xrayTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("X-ray", this.diagTest) == true) {
        this.testNameArray = this.xrayTestlistArray;
      }
    } else if (type == "Scan") {
      for (var i = 0; i < this.scanTestArray.length; i++) {
        if (this.scanTestArray[i].test_id == testid) {
          this.scanTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.scanTestArray.length == 0) {
        this.showScanTestFlag = true;
      }
      for (var i = 0; i < this.scanTestlistArray.length; i++) {
        if (this.scanTestlistArray[i].test_id == testid) {
          this.scanTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Scan", this.diagTest) == true) {
        this.testNameArray = this.scanTestlistArray;
      }

    } else if (type == "Ultra") {
      for (var i = 0; i < this.ultraTestArray.length; i++) {
        if (this.ultraTestArray[i].test_id == testid) {
          this.ultraTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.ultraTestArray.length == 0) {
        this.showUltraTestFlag = true;
      }
      for (var i = 0; i < this.ultraTestlistArray.length; i++) {
        if (this.ultraTestlistArray[i].test_id == testid) {
          this.ultraTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
        this.testNameArray = this.ultraTestlistArray;
      }

    } else if (type == "Electrocardio") {
      for (var i = 0; i < this.electroCardioTestArray.length; i++) {
        if (this.electroCardioTestArray[i].test_id == testid) {
          this.electroCardioTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.electroCardioTestArray.length == 0) {
        this.showElectroCardioTestFlag = true;
      }
      for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
        if (this.electroCardioTestlistArray[i].test_id == testid) {
          this.electroCardioTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
        this.testNameArray = this.electroCardioTestlistArray;
      }

    } else if (type == "Culture") {
      for (var i = 0; i < this.CultureTestArray.length; i++) {
        if (this.CultureTestArray[i].test_id == testid) {
          this.CultureTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.CultureTestArray.length == 0) {
        this.showCultureTestFlag = true;
      }
      for (var i = 0; i < this.cultureTestlistArray.length; i++) {
        if (this.cultureTestlistArray[i].test_id == testid) {
          this.cultureTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Culture", this.diagTest) == true) {
        this.testNameArray = this.cultureTestlistArray;
      }

    } else if (type == "Biopsy") {
      for (var i = 0; i < this.biopsyTestArray.length; i++) {
        if (this.biopsyTestArray[i].test_id == testid) {
          this.biopsyTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.biopsyTestArray.length == 0) {
        this.showUltraTestFlag = true;
      }
      for (var i = 0; i < this.biopsyTestlistArray.length; i++) {
        if (this.biopsyTestlistArray[i].test_id == testid) {
          this.biopsyTestlistArray[i].checked = false;
          break;
        }
      }
      if (Ignorecase_data("Biopsy", this.diagTest) == true) {
        this.testNameArray = this.biopsyTestlistArray;
      }

    } else if (type == "Package") {
      for (var i = 0; i < this.selTestGroupArray.length; i++) {
        if (this.selTestGroupArray[i].test_id == testid) {
          this.selTestGroupArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      if (this.selTestGroupArray.length == 0) {
        this.showUltraTestFlag = true;
      }
      for (var i = 0; i < this.testGroupArray.length; i++) {
        if (this.testGroupArray[i].diag_packages_id == testid) {
          this.testGroupArray[i].checked = false;
          break;
        }
      }
    }
    var sno=1;this.sno=1;
    for (var i = 0; i < this.priceTable.length; i++) {
      this.priceTable[i].sno=sno;
      sno+=1;
      this.sno+=1;
    }
    this.balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
    }
    this.changeAdvance();
    this.calculateOutPrice();
  }

  clickAppDate(data) {
    this.appDate = data;
    var dataval = Date_Formate(this.currentDate);

    if (data != dataval) {
      this.getVisitingSessionArray = [{ "description": "Select" }, { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
      this.visitSession = this.getVisitingSessionArray[0].description;
    }
  }

  selectSession() {
    var curtime = this.currentTime.split(":");
    for (var i = parseInt(curtime[1]); i <= 59; i += 1) {
      if (i < 10) {
        this.sessionTimeEnd.push("0" + i);
      } else {
        this.sessionTimeEnd.push(i);
      }
    }

    if (this.visitSession == "Morning") {
      this.sessionTimeStart=[];
      for (var i = parseInt(curtime[0]); i < 12; i++) {
        if (i < 10) {
          this.sessionTimeStart.push("0" + i);
        } else {
          this.sessionTimeStart.push(i);
        }
      }
      this.timeSession = "AM";
      this.startTime = curtime[0];
      this.endTime = curtime[1];
      
    } else if (this.visitSession == "Evening") {
      this.sessionTimeStart=[];
      var timeval;
      if(parseInt(curtime[0])>16){
        timeval = parseInt(curtime[0]);
        
      } else {
        timeval = "04";
      }
   
      for (var i = parseInt(timeval); i < 24; i++) {
        this.sessionTimeStart.push(i);
      }
      this.timeSession = "PM";
      this.startTime = curtime[0];
      this.endTime = curtime[1];
      
    } else {
      this.sessionTimeStart=[];
      var timeval;
      if(parseInt(curtime[0])>=12){
        timeval = parseInt(curtime[0]);
        
      } else {
        timeval = "12";
      }
      for (var i = parseInt(timeval); i <= 16; i++) {
        this.sessionTimeStart.push(i);
      }
      this.timeSession = "PM";
      this.startTime = curtime[0];
      this.endTime = curtime[1];
    }
    this.sessionFlag = false;
    this.enableBooking();
  }

  enableBooking() {
    if (this.visitSession != undefined) {
      var flag = false;
      if (this.clientId != undefined && this.bloodTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.urineTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.feaceTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.XrayTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.scanTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.ultraTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.CultureTestArray.length != 0) {
        flag = true;
      }
      if (this.clientId != undefined && this.electroCardioTestArray.length != 0) {
        flag = true;
      }
      if (flag == true) {
        this.bookNowFlag = false;
      }
    }
  }

  // concession calculation
  changeAmount(quantity, amount, concession, testId) {
    var perce = null;
    perce = (concession / 100);
    var amt = amount;
    if (quantity > 1) {
      amt = amt * quantity;
    }
    var amt_cons = this.amountAfternoon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAfternoon = Math.round(tot);
    var balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      if (testId == this.priceTable[i].test_id) {
        this.priceTable[i].fee = Math.round(tot);
      }
    }
    this.balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
      if (this.priceTable[i].test_id == testId) {
        this.priceTable[i].quantity = quantity;
      }
    }
    this.changeAdvance();
  }

  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.clientGender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clientGender = "Female";
    else if (e == "5")
      this.clientGender = "Transgender";
    else if (e == "8")
      this.clientGender = "Female";
    else if (e == "10")
      this.clientGender = "Male";
    else {
      this.clientGender = "";
    }
  }

  //Get payment type
  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paymentArray = obj.payments;
          if(this.paytypreq == true){
            this.payType = this.paymentArray[0].pay_id;
            this.pay_desc = this.paymentArray[0].pay_desc;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  changePayTypeReq() {
    this.insurFlag1 = true;
    this.tranFlag1 = true;
    this.cardFlag1 = true;
    this.chequeFlag1 = true;
    
    if(this.payType1 == "2"){
      this.tranFlag1 = false;
      this.cardFlag1 = false;
      this.insurFlag1=true;
      this.chequeFlag1=true;

    } else if (this.payType1 == "3"){
      this.tranFlag1 = true;
      this.cardFlag1 = true;
      this.insurFlag1=false;
      this.chequeFlag1=true;

    } else if(this.payType1 == "4"){
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;

    } else if(this.payType1 == "5") {
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;

    } else if(this.payType1 == "6"){
      this.tranFlag1 = false;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=true;

    } else if(this.payType1 == "7") {
      this.tranFlag1 = true;
      this.cardFlag1 = true;
      this.insurFlag1=true;
      this.chequeFlag1=false;
    } 
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insuranceFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;

    } else {
      this.insuranceFlag = true;
    }
    for (var i = 0; i < this.paymentArray.length; i++) {
      if (this.payType == this.paymentArray[i].pay_id) {
        this.pay_desc = this.paymentArray[i].pay_desc;
        break;
      }
    }
  }

  //Get payment type
  getInsurers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurancyArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  changeGender(e) {
    if (this.clientAge != undefined && this.clientAge != null && this.clientAge != "") {
      if (e == "Male") {
        if (this.clientAge <= 15)
          this.clnt_sal = "6";
        else
          this.clnt_sal = "1";
      } else if (e == "Female") {
        if (this.clientAge <= 15)
          this.clnt_sal = "7";
        else
          this.clnt_sal = "2";
      } else if (e == "Transgender")
        this.clnt_sal = "5";
      else {
        this.clnt_sal = "";
      }
    } else {
      if (e == "Male") {
        this.clnt_sal = "1";
      } else if (e == "Female") {
        this.clnt_sal = "2";
      } else if (e == "Transgender")
        this.clnt_sal = "5";
      else {
        this.clnt_sal = "";
      }
    }
  }

  changeClientType(type, id, value) {
    if (type == "patient") {
      if (value == "yes") {
        this.inpatient = false;
        this.ynopt1 = true;
        this.pat_type="out-patient";
        this.showclient=true;

      } else {
        this.inpatient = true;
        this.ynopt1 = false;
        this.pat_type="in-patient";
        this.clientType = "Existing";
        this.showclient=false;
      }

    } else {
      if (value == "yes") {
        this.ynopt2 = true;
        this.clientType = "New";
        this.selectUserType();
      }
      else {
        this.ynopt2 = false;
        this.clientType = "Existing";
        this.selectUserType();
      }
    }
  }

  getReferralData(e) {
    this.referedDoctorArray = [];
    this.referedNameArray = [];
    if (this.doctorName != undefined && this.doctorName.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/dbyp',
        {
          center_id: this.diagId,
          doc_name: this.doctorName,
          type: "diag"
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            this.referedNameArray = obj.doctors;
            if (this.referedNameArray != undefined) {
              for (var i = 0; i < this.referedNameArray.length; i++) {
                var docname;
                if (this.referedNameArray[i].middle_name != undefined) {
                  docname = this.referedNameArray[i].first_name + " " + this.referedNameArray[i].middle_name + " " + this.referedNameArray[i].last_name;
                } else {
                  if (this.referedNameArray[i].last_name != undefined) {
                    docname = this.referedNameArray[i].first_name + " " + this.referedNameArray[i].last_name;
                  } else {
                    docname = this.referedNameArray[i].first_name
                  }
                }
                this.referedDoctorArray.push({
                  docname: docname,
                  doc_reg_id: this.referedNameArray[i].prov_id,
                  hptl_clinic_id: this.referedNameArray[i].hptl_clinic_id,
                });
              }
            }
          },
          error => {
            this.toastr.error(Message_data.network);
          });
    }
    else {
      this.referedDoctorArray = [];
    }
  }

  getReferDoctor(id) {
    var item1 = this.doctorName;
    for (var i = 0; i < this.referedNameArray.length; i++) {
      if (this.referedNameArray[i].prov_id == id) {
        this.hospId = this.referedNameArray[i].hptl_clinic_id;
        this.doctorRegId = this.referedNameArray[i].prov_id;
        break;
      }
    }
    // this.doctorName = item;
    // this.doctorRegId = 
    this.referedDoctorArray = [];
    this.getAppointments();
  }

  getAppointments() {
    this.billingListArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      {
        doc_reg_id: this.doctorRegId,
        hptl_clinic_id: this.hospId,
        country: ipaddress.country_code.toString(),
        imei: Diagnosis_Helper.getIPAddress(),
        type: "doctor",
        inpat_type: "self",
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.patients != null) {
            for (var i = 0; i < obj.patients.length; i++) {
              var patientName;
              if (obj.patients[i].middle_name != undefined) {
                this.selectedDocName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                patientName = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
              } else {
                if (obj.patients[i].last_name != undefined) {
                  this.selectedDocName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                  patientName = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                } else {
                  this.selectedDocName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name);
                  patientName = encrypt_decript.Decript(obj.patients[i].first_name);
                }

              }
              var sessiondata = null;
              if (obj.patients[i].session != undefined && obj.patients[i].session != undefined && obj.patients[i].session != null) {
                sessiondata = encrypt_decript.Decript(obj.patients[i].session);
              }
              this.billingListArray.push({
                doc_reg_id: this.doctorRegId,
                doc_app_id: obj.patients[i].doc_app_id,
                client_reg_id: obj.patients[i].client_reg_id,
                relation_id: obj.patients[i].relation_id,
                sub_rel_id: obj.patients[i].sub_rel_id,
                first_name: obj.patients[i].first_name,
                middle_name: obj.patients[i].middle_name,
                last_name: obj.patients[i].last_name,
                hptl_clinic_id: this.hospId,
                session: sessiondata,
                docid_name: this.selectedDocName,
                patientName: patientName
              });
            }
          }
        },
        error => { });
  }

  changeAppointmnetId() {
    for (var i = 0; i < this.billingListArray.length; i++) {
      if (this.billingListArray[i].doc_app_id == this.appoint) {
        this.clientId = this.billingListArray[i].client_reg_id;
        this.relationId = this.billingListArray[i].relation_id;
        this.subRelationId = this.billingListArray[i].sub_rel_id;
        this.appID = this.billingListArray[i].doc_app_id;
        this.patinetName = this.billingListArray[i].patientName;
        this.retrieveClient();
        break;
      }
    }
  }

  saveDiagnosisPrescription() {
    var blood_array = [];
    var urine_arry = [];
    var scan_arry = [];
    var Faeces_tests = [];
    var Xray_tests = [];
    var ultra_tests = [];
    var culture_test = [];
    var biopsy_test = [];
    var valid_flag = false;
    if (this.bloodTestArray.length != 0) {
      for (var i = 0; i < this.bloodTestArray.length; i++) {
        blood_array.push(this.bloodTestArray[i].test_id);
      }
    }
    if (this.urineTestArray.length != 0) {
      for (var i = 0; i < this.urineTestArray.length; i++) {
        urine_arry.push(this.urineTestArray[i].test_id);
      }
    }
    if (this.feaceTestArray.length != 0) {
      for (var i = 0; i < this.feaceTestArray.length; i++) {
        Faeces_tests.push(this.feaceTestArray[i].test_id);
      }
    }
    if (this.scanTestArray.length != 0) {
      for (var i = 0; i < this.scanTestArray.length; i++) {
        scan_arry.push(this.scanTestArray[i].test_id);
      }
    }
    if (this.XrayTestArray.length != 0) {
      for (var i = 0; i < this.XrayTestArray.length; i++) {
        Xray_tests.push(this.XrayTestArray[i].test_id);
      }
    }
    if (this.ultraTestArray.length != 0) {
      for (var i = 0; i < this.ultraTestArray.length; i++) {
        ultra_tests.push(this.ultraTestArray[i].test_id);
      }
    }
    if (this.CultureTestArray.length != 0) {
      for (var i = 0; i < this.CultureTestArray.length; i++) {
        culture_test.push(this.CultureTestArray[i].test_id);
      }
    }
    if (this.biopsyTestArray.length != 0) {
      for (var i = 0; i < this.biopsyTestArray.length; i++) {
        biopsy_test.push(this.biopsyTestArray[i].test_id);
      }
    }
    var sen_pass;
    if (this.subRelationId != "" && this.subRelationId != undefined) {
      sen_pass = {
        appointment_id: this.appID,
        client_reg_id: this.clientId,
        relation_id: this.relationId,
        sub_rel_id: this.subRelationId,
        date: this.currentDate,
        diagnosis: "diag",
        disease: "",
        diagnosis_id: this.diagId,
        country:ipaddress.country_code,
        //   spl_id:this.patient_list.spl_id,
        "Blood Test": blood_array,
        "Urine Test": urine_arry,
        "Faeces Test": Faeces_tests,
        "Scan": scan_arry,
        "X-ray": Xray_tests,
        "Ultra Sound": ultra_tests,
        "Culture": culture_test,
        "Biopsy": biopsy_test,
      };

    } else {
      sen_pass = {
        appointment_id: this.appID,
        client_reg_id: this.clientId,
        relation_id: this.relationId,
        date: this.currentDate,
        diagnosis: "diag",
        disease: "",
        country:ipaddress.country_code,
        diagnosis_id: this.diagId,
        //  spl_id:this.patient_list.spl_id,
        "Blood Test": blood_array,
        "Urine Test": urine_arry,
        "Faeces Test": Faeces_tests,
        "Scan": scan_arry,
        "X-ray": Xray_tests,
        "Ultra Sound": ultra_tests,
        "Culture": culture_test,
        "Biopsy": biopsy_test,
      };
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj["pres_id"] != null) {
            Helper_Class.set_diag_pres_id(obj["pres_id"]);
            this.presID = obj.pres_id;
            this.savePrescriptionFlag = false;
            this.saveApp()
          } else {
            this.toastr.error(Message_data.unableToSavePrescription);
          }
        },
        error => { });
  }

  changeAdvance() { //add the bill details
    if (this.advance != undefined && this.advance != "") {
      this.remaingBalance = (parseFloat(this.balance) - parseFloat(this.advance)).toFixed(2);
    } else {
      this.advance = "0.00";
      this.remaingBalance = this.balance;
    }
  }

  getTest(test) {
    if (test.length >= 3) {
      if (this.testNameArray.length != 0) {
        if (this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testNameArray.length; j++) {
            this.testNameArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testNameArray.length; j++) {
              if (this.testNameArray[j].test_name.includes(chg[i].test_name) == true) {
                this.testNameArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testNameArray.length; j++) {
        this.testNameArray[j].show = false;
      }
    }
  }

  getTestGroup(test) {
    if (test.length >= 3) {
      if (this.testGroupArray.length != 0) {
        if (this.testGroupArray.filter(o => o.package_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testGroupArray.filter(o => o.package_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testGroupArray.length; j++) {
            this.testGroupArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testGroupArray.length; j++) {
              if (this.testGroupArray[j].package_name.includes(chg[i].package_name) == true) {
                this.testGroupArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testGroupArray.length; j++) {
        this.testGroupArray[j].show = false;
      }
    }
  }

  checkAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  diagTestGroupChange() {
    if (this.diagTestGroup == "package") {
      if (this.testGroupArray.length == 0) {
        this.getTestGroups();
      }
      this.diagtest_flag = true;

    } else {
      this.getDiagTestTypes();
      this.diagtest_flag = false;
    }
  }
  
  saveApp() {
    this.sendData = {};
    var blood_array = [];
    var urine_arry = [];
    var scan_arry = [];
    var Faeces_tests = [];
    var Xray_tests = [];
    var ultra_tests = [];
    var culture_test = [];
    var biopsy_test = [];
    var package_test = [];
    var valid_flag = false;
    if (this.visitSession != "Select") {
      this.visitSession = this.visitSession;
    } else {
      this.toastr.error(Message_data.sltSession);
      valid_flag = true;
    }
    if (this.clientAddress1 == "" || this.clientAddress1 == null || this.clientAddress1 == undefined) {
      this.toastr.error(Message_data.enterPatAddr);
      valid_flag = true;
    }
    if (this.clientNumber == undefined || this.clientNumber == null) {
      this.toastr.error(Message_data.enterMobileno);
      valid_flag = true;
    }
    if (this.clientFirstName == "" || this.clientFirstName == undefined || this.clientFirstName == null) {
      this.toastr.error(Message_data.enterFName);
      valid_flag = true;
    }
    if (this.clientLastName == "" || this.clientLastName == undefined || this.clientLastName == null) {
      this.toastr.error(Message_data.enterLName);
      valid_flag = true;
    }
    
    if (this.clientZipCode == undefined) {
      this.toastr.error(Message_data.enterZipcode);
      valid_flag = true;
    }

    if (this.priceTable.length == 0) {
      this.toastr.error(Message_data.plsAddTestBfrSave);
      valid_flag = true;
    }

    if (this.priceTable.length == 0) {
      this.toastr.error(Message_data.plsAddTestBfrSave);
      valid_flag = true;
    }
    if(this.clnt_sal == 'select'){
      this.toastr.error('Select salutation'); 
      valid_flag = true; 
    }
    if (valid_flag == false) {
      if (this.savePrescriptionFlag == true) {
        //  this.saveDiagnosisPrescription()
      } else {
        if (this.bloodTestArray.length != 0) {
          for (var i = 0; i < this.bloodTestArray.length; i++) {
            blood_array.push(this.bloodTestArray[i].test_id);
          }
        }

        if (this.urineTestArray.length != 0) {
          for (var i = 0; i < this.urineTestArray.length; i++) {
            urine_arry.push(this.urineTestArray[i].test_id);
          }
        }

        if (this.feaceTestArray.length != 0) {
          for (var i = 0; i < this.feaceTestArray.length; i++) {
            Faeces_tests.push(this.feaceTestArray[i].test_id);
          }
        }

        if (this.XrayTestArray.length != 0) {
          for (var i = 0; i < this.XrayTestArray.length; i++) {
            Xray_tests.push(this.XrayTestArray[i].test_id);
          }
        }

        if (this.scanTestArray.length != 0) {
          for (var i = 0; i < this.scanTestArray.length; i++) {
            scan_arry.push(this.scanTestArray[i].test_id);
          }
        }

        if (this.ultraTestArray.length != 0) {
          for (var i = 0; i < this.ultraTestArray.length; i++) {
            ultra_tests.push(this.ultraTestArray[i].test_id);
          }
        }
        if (this.CultureTestArray.length != 0) {
          for (var i = 0; i < this.CultureTestArray.length; i++) {
            culture_test.push(this.CultureTestArray[i].test_id);
          }
        }
        if (this.biopsyTestArray.length != 0) {
          for (var i = 0; i < this.biopsyTestArray.length; i++) {
            biopsy_test.push(this.biopsyTestArray[i].test_id);
          }
        }
        if (this.selTestGroupArray.length != 0) {
          for (var i = 0; i < this.selTestGroupArray.length; i++) {
            package_test.push(this.selTestGroupArray[i].test_id);
          }
        }
        var test_outsource=[];
        for(var l=0;l<this.priceTable.length;l++){
          if(this.priceTable[l].outsource != undefined || this.priceTable[l].priority != undefined){
            test_outsource.push({
              test_type:this.priceTable[l].type_of_test,
              test_id:this.priceTable[l].test_id,
              outsource:this.priceTable[l].outsource,
              priority: this.priceTable[l].priority,
            })
          }
        }

        var priority_List = [];
        for(var i = 0; i < this.priceTable.length; i++){
          priority_List.push({
            test_type: this.priceTable[i].type_of_test,
            test_id: this.priceTable[i].test_id,
            priority: this.priceTable[i].priority,
          })
        }

        this.sendData = {
          "Blood Test": blood_array,
          "Urine Test": urine_arry,
          "Faeces Test": Faeces_tests,
          "X-ray": Xray_tests,
          "Scan": scan_arry,
          "Ultra Sound": ultra_tests,
          "Culture": culture_test,
          "Biopsy": biopsy_test,
          "Package": package_test,
          "outsource":test_outsource,
          "priority": priority_List,
        }

        var zcode;
        if (this.clientZipCode != undefined) {
          zcode = encrypt_decript.Encript(this.clientZipCode).toString();
        }

        var addr1;
        if (this.clientAddress1 != null) {
          addr1 = encrypt_decript.Encript(this.clientAddress1).toString()

        } else {
          addr1 = null;
        }

        var addr2;
        if (this.clientAddress2 != null) {
          addr2 = encrypt_decript.Encript(this.clientAddress2).toString()
        } else {
          addr2 = null;
        }

        var address = [];
        address.push({
          address1: addr1,
          address2: addr2,
          location: this.clientLocationId,
          city: this.clientCityId,
          state: this.clientStateId,
          country: this.clientcountryId,
          zipcode: zcode,
          type: "pres"
        });

        var mname;
        if (this.clientMidName != undefined) {
          mname = encrypt_decript.Encript(this.clientMidName).toString();
        }
        var lname;
        if (this.clientLastName != undefined) {
          lname = encrypt_decript.Encript(this.clientLastName).toString();
        }
        var fname;
        if (this.clientFirstName != undefined) {
          fname = encrypt_decript.Encript(this.clientFirstName).toString();
        }
        var gender;
        if (this.clientGender != undefined) {
          gender = encrypt_decript.Encript(this.clientGender).toString();
        }

        this.sendData.client_reg_id = this.clientId;
        this.sendData.relation_id = this.relationId;
        this.sendData.sub_rel_id = this.subRelationId;
        this.sendData.salutation = this.clnt_sal;

        this.sendData.first_name = fname;
        this.sendData.middle_name = mname;
        this.sendData.last_name = lname;
        if (this.clientDOB != undefined) {
          this.sendData.dob = this.clientDOB;//.split("-")[2]+"-"+this.clientDOB.split("-")[1]+"-"+this.clientDOB.split("-")[0];
        }

        this.sendData.age = this.clientAge;
        this.sendData.gender = gender;

        this.sendData.mem_mobile = encrypt_decript.Encript(this.clientNumber).toString();
        this.sendData.app_type = this.clientType;

        this.sendData.address = address;
        this.sendData.referred_by = this.referedBy;

        this.sendData.diag_centre_id = this.diagId;
        this.sendData.pres_id = undefined;
        this.sendData.appointment_id = this.inpatient == true ? this.appID : undefined;
        this.sendData.provider = "diagnosis";
        this.sendData.date = this.appDate;//.split('-')[2] + "-" + this.appDate.split('-')[1] + "-" + this.appDate.split('-')[0];
        this.sendData.session = this.visitSession;
        this.sendData.spl_id = this.speclization;
        this.sendData.time = this.startTime + ":" + this.endTime;
        this.sendData.pat_type=this.pat_type;
        this.sendData.country=ipaddress.country_code;

        console.log("DIAG APP BOOKING " + JSON.stringify(this.sendData))

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "appointment/dbapp", this.sendData, { headers: headers }).subscribe(
          data => {
            var response_data = JSON.parse(JSON.stringify(data));

            if (response_data.status == "1") {
              this.toastr.success(response_data.result);
              if (response_data.diag_app_id != undefined) {
                var balance = "0";
                for (var i = 0; i < this.priceTable.length; i++) {
                  balance = (parseFloat(balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
                }
                var paid_flag;
                if (balance == this.remaingBalance) {
                  paid_flag = "Un paid";
                } else if (balance != "0.00" && (this.advance != "0.00") && balance != this.advance) {
                  paid_flag = "Partially paid";
                } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
                  paid_flag = "Fully paid";
                }
                var outpayarray=[];
                
                var billcreate_value = {
                  diag_centre_id: this.diagId,
                  client_reg_id: response_data.client_reg_id,
                  relation_id: this.relationId,
                  sub_rel_id: this.subRelationId,
                  appointment_id: response_data.diag_app_id,
                  balance: this.remaingBalance,
                  paid_amt: this.advance,
                  paid_flag: paid_flag,
                  bill_amount: encrypt_decript.Encript(balance).toString(),
                  created_by: Diagnosis_Helper.getUserID_Type().user_id,
                  app_type: "diagnosis",
                  bills: this.priceTable,
                  country: ipaddress.country_code,
                  citizenship: "indian",
                  card_no: this.cardNumber,
                  card_holder_name: this.cardHolderName,
                  transaction_no: this.transactionID,
                  bank_name: this.bankName,
                  pres_id: this.presID,
                  payments:this.paymentsarray,
                  outpay:this.outpayarray,
                }
                console.log("billcreate_value" + JSON.stringify(billcreate_value));
                var headers = new HttpHeaders();
                headers.append('Content-Type', 'application/json');
                this.http.post(ipaddress.getIp.toString() + 'bill/savebill/', billcreate_value,
                  { headers: headers })
                  .subscribe(
                    response => {
                      var response_data = JSON.parse(JSON.stringify(response));
                      if (response_data.key == "1") {
                        var billdetail = {
                          bill_view_id: response_data.bill_no,
                          Diag_center_id: this.diagId,
                          flow: "dig_walking",
                        }
                        Diagnosis_Helper.setbilldetailview(billdetail);
                        if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
                          this.frontdeskservice.sendMessage("diaBillingDetailsView");

                        } else {
                          this.diagnosisservice.sendMessage("diagbilldetailview");
                        }
                      }
                    })
              }

            } else {
              this.toastr.error(response_data.result);
            }
          }, error => {
          }
        );
      }
    }
  }

  calculateOutPrice(){
    this.outpayarray=[];
    if(this.priceTable.length !=0){
      for(var p=0;p<this.priceTable.length;p++){
        var outarray = this.priceTable[p].outarray;
        for(var oa=0;oa<outarray.length;oa++){
          if(this.priceTable[p].outsource == outarray[oa].out_id){
            if(this.outpayarray.length !=0){
              if(this.isExists(this.outpayarray,this.priceTable[p].outsource) == true){
                for(var i=0;i<this.outpayarray.length;i++){
                  if(this.outpayarray[i].outsource == this.priceTable[p].outsource){
                    var bill_amt=parseFloat(this.outpayarray[i].amount)+(parseFloat(this.priceTable[p].fee)*((parseFloat(outarray[oa].perc_amt)/100)));
                    this.outpayarray[i].amount = bill_amt;
                    break;
                  } 
                }

              } else {
                this.outpayarray.push({
                  outsource:this.priceTable[p].outsource,
                  amount:parseFloat(this.priceTable[p].fee)*((parseFloat(outarray[oa].perc_amt)/100))
                })
                break;
              }
              
            } else {
              this.outpayarray.push({
                outsource:this.priceTable[p].outsource,
                amount:parseFloat(this.priceTable[p].fee)*((parseFloat(outarray[oa].perc_amt)/100))
              })
            }
          }
        }
      }
    }
  }

  isExists(data,val){
    var exitval;
    for(var i=0;i<data.length;i++){
      if(data[i].outsource == val){
        exitval= true;
        break;
      }
    }
    return exitval;
  }

  addPaymentDetails(){
    var bamt=0;
    for(var i=0;i<this.paymentArray.length;i++){
      if(this.payType1 == this.paymentArray[i].pay_id){
        if(this.paymentsarray.length != 0){
          for(var j=0;j<this.paymentsarray.length;j++){
            bamt+=parseInt(this.paymentsarray[j].amount);
          }
          bamt+=parseFloat(this.amountval.toString());
        } else {
          bamt+=parseFloat(this.amountval.toString());
        }
        
        if(bamt> parseInt(this.advance)){
          this.toastr.error("Amount cannot be more than paid amount")
        } else {
          var flag=false;
          if((this.payType != undefined && this.payType =="2") && ((this.cardNumber!= undefined &&this.cardNumber.length<4) || this.cardNumber== undefined)) {
            this.toastr.error("Enter last four digits of card")
            flag = true;
    
          } else if((this.payType1 != undefined && this.payType1 =="2") && ((this.cardNumber!= undefined &&this.cardNumber.length<4) || this.cardNumber== undefined)) {
            this.toastr.error("Enter last four digits of card")
            flag = true;
          }
          if(flag == false) {
            for(var i=0;i<this.paymentArray.length;i++){
              if(this.payType1 == this.paymentArray[i].pay_id){
                this.paymentsarray.push({
                  pay_id:this.paymentArray[i].pay_id,
                  pay_desc:this.paymentArray[i].pay_desc,
                  amount:this.amountval.toFixed(2),
                  trans_id:this.transactionID,
                  insurer:this.insuranceId,
                  card_no:this.cardNumber,
                })
                this.amountval=0;
                this.transactionID=undefined;
                this.insuranceId=undefined;
                this.cardNumber=undefined;
              }
            }
          }
          this.amountval=0;
        }
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=data.amount;
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
