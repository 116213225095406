import { ReturnStatement } from "@angular/compiler";

export class Master_Helper {
    //setRelation
    static Relation;
    static setMasterRelationData(value: any) {
        this.Relation = null;
        this.Relation = value;
    }
    static getMasterRelationData() {
        return this.Relation;
    }

    //setMasterCurrentDate
    static CurrentDate;
    static setMasterCurrentDate(value: any) {
        this.CurrentDate = null;
        this.CurrentDate = value;
    }
    static getMasterCurrentDate() {
        return this.CurrentDate;
    }

    //setMasterToken
    static Token;
    static setMasterToken(value: any) {
        this.Token = null;
        this.Token = value;
    }
    static getMasterToken() {
        return this.Token;
    }

    //setMasterHospital
    static Hospital;
    static setMasterHospital(value: any) {
        this.Hospital = null;
        this.Hospital = value;
    }
    static getMasterHospital() {
        return this.Hospital;
    }

    //setMasterSalutation
    static Salutation;
    static setMasterSalutation(value: any) {
        this.Salutation = null;
        this.Salutation = value;
    }
    static getMasterSalutation() {
        return this.Salutation;
    }

    //setMasterSpecialization
    static Specialization;
    static setMasterSpecialization(value: any) {
        this.Specialization = null;
        this.Specialization = value;
    }
    static getMasterSpecialization() {
        return this.Specialization;
    }

    //setMasterGeneralSurgeries
    static GeneralSurgeries;
    static setMasterGeneralSurgeries(value: any) {
        this.GeneralSurgeries = null;
        this.GeneralSurgeries = value;
    }
    static getMasterGeneralSurgeries() {
        return this.GeneralSurgeries;
    }

    //setMasterCountries
    static Countries;
    static setMasterCountries(value: any) {
        this.Countries = null;
        this.Countries = value;
    }
    static getMasterCountries() {
        return this.Countries;
    }

    //setMasterStates
    static States;
    static setMasterStates(value: any) {
        this.States = null;
        this.States = value;
    }
    static getMasterStates() {
        return this.States;
    }

    //setMasterCities
    static Cities;
    static setMasterCities(value: any) {
        this.Cities = null;
        this.Cities = value;
    }
    static getMasterCities() {
        return this.Cities;
    }

    //setMasterLocation
    static Location;
    static setMasterLocation(value: any) {
        this.Location = null;
        this.Location = value;
    }
    static getMasterLocation() {
        return this.Location;
    }

    //setMasterCovidSymptoms
    static CovidSymptoms;
    static setMasterCovidSymptoms(value: any) {
        this.CovidSymptoms = null;
        this.CovidSymptoms = value;
    }
    static getMasterCovidSymptoms() {
        return this.CovidSymptoms;
    }

    //setMasterComorbidities
    static Comorbidities;
    static setMasterComorbidities(value: any) {
        this.Comorbidities = null;
        this.Comorbidities = value;
    }
    static getMasterComorbidities() {
        return this.Comorbidities;
    }

    //setMasterWardData
    static WardData;
    static setMasterWardData(value: any) {
        this.WardData = null;
        this.WardData = value;
    }
    static getMasterWardData() {
        return this.WardData;
    }

    //setMasterDoctorProfileInfo
    static DoctorProfileInfo;
    static setMasterDoctorProfileInfo(value: any) {
        this.DoctorProfileInfo = null;
        this.DoctorProfileInfo = value;
    }
    static getMasterDoctorProfileInfo() {
        return this.DoctorProfileInfo;
    }

    //setMasterHospitalList
    static HospitalList;
    static setMasterHospitalList(value: any) {
        this.HospitalList = null;
        this.HospitalList = value;
    }
    static getMasterHospitalList() {
        return this.HospitalList;
    }

    //getMasterPaymentType
    static PaymentType;
    static setMasterPaymentType(value: any) {
        this.PaymentType = null;
        this.PaymentType = value;
    }
    static getMasterPaymentType() {
        return this.PaymentType;
    }

    //setMasterGSTData
    static GSTData;
    static setMasterGSTData(value: any) {
        this.GSTData = null;
        this.GSTData = value;
    }
    static getMasterGSTData() {
        return this.GSTData;
    }

    //setMasterInsurers
    static Insurers;
    static setMasterInsurers(value: any) {
        this.Insurers = null;
        this.Insurers = value;
    }
    static getMasterInsurers() {
        return this.Insurers;
    }

    //setMasterCityDetails
    static CityDetails;
    static setMasterCityDetails(value: any) {
        this.CityDetails = null;
        this.CityDetails = value;
    }
    static getMasterCityDetails() {
        return this.CityDetails;
    }

    //setMasterTreatement
    static Treatement;
    static setMasterTreatement(value: any) {
        this.Treatement = null;
        this.Treatement = value;
    }
    static getMasterTreatement() {
        return this.Treatement;
    }

    //setMasterSubspecilities
    static Subspecilities;
    static setMasterSubspecilities(value: any) {
        this.Subspecilities = null;
        this.Subspecilities = value;
    }
    static getMasterSubspecilities() {
        return this.Subspecilities;
    }

    //setMasterIdProofType
    static IdProofType;
    static setMasterIdProofType(value: any) {
        this.IdProofType = null;
        this.IdProofType = value;
    }
    static getMasterIdProofType() {
        return this.IdProofType;
    }
}