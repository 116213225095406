<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment List</h5>
          </div>
          
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" *ngIf="diagAppListDate.length !=0" (click)="cancel()" width="85px"
              style="margin-right: 9px;" />
            <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="createAppointment()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="margin-bottom: 10px;margin-left: 9px;margin-top: -10px !important;">
          <div [hidden]="locationFlag" class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4">
            <mat-label class="matlabel" [hidden]="locationFlag">Diagnosis center<br>
              <select disableOptionCentering class="ipcss" [(ngModel)]="location" (change)="changeLocation(location)">
                <option *ngFor="let loc of diagLocationArray" value={{loc.view_data}}>{{loc.view_data}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Date
              <input type="date" class="ipcss_date" id="appt_date" (change)="OnDateMatePicker(billdate)"
                [(ngModel)]="billdate" #matInput>
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="nodata" [hidden]='diagAppListDate.length!=0'>No Appointment(s) Found</p>
            <table *ngIf="diagAppListDate.length !=0" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th> session </th>
                  <th> Package </th>
                  <th> status </th>
                  <th style="width: 5%;">&nbsp;</th>
                  <th style="width: 5%;">
                    <mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1"
                      (change)="checkUncheckAll()"></mdb-checkbox>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let list of diagAppListDate" (click)='viewApp(list.App_id,list.status)'>
                  <td style="text-align: left;">{{ list.Clnt_Name}}</td>
                  <td> {{list.App_date}}</td>
                  <td>{{list.Session}}</td>
                  <td>{{list.package}}</td>
                  <td style="font-size: 12px;">
                    <div style="position: relative;top: 9px;">
                      <img *ngIf="list.status == 'Completed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/Completed_icon.svg" />
                      <img *ngIf="list.status == 'Cancel'" width="30px" height="auto"
                        src="../../../assets/ui_icons/cancel_icon.svg" />
                      <img *ngIf="list.status == 'Sample collected'" width="30px" height="auto"
                        src="../../../assets/ui_icons/sample_collected.svg" />
                      <img *ngIf="list.status == 'Confirmed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/confirmed_icon.svg" />
                      <img *ngIf="list.status == 'Open'" width="30px" height="32px"
                        src="../../../assets/ui_icons/opened_icon.svg" />
                      <img *ngIf="list.status == 'Closed'" width="30px" height="32px"
                        src="../../../assets/ui_icons/Closed_icon.svg" />
                      <img *ngIf="list.status == 'Report yet to be finalized'" width="30px" height="32px"
                        src="../../../assets/ui_icons/report_yet_to_be_finalized.svg" />
                      <img *ngIf="list.status == 'Time alloted'" width="30px" height="32px"
                        src="../../../assets/ui_icons/time_not_alloted.svg" />
                      <p>{{list.status}}</p>
                    </div>
                  </td> 
                  <td style="font-size: 12px;width: 5%;">
                    <img src="../../../assets/img/edit.png" class="app_edit" (click)="editAppointment(list.App_id,list.status)">
                  </td>
                  <td style="font-size: 12px;width: 5%;">
                    <mdb-checkbox [default]="true" [(ngModel)]="list.isSelected" name="list.Clnt_Name"
                      value="{{list.Clnt_Name}}" (change)="isAllSelected(list.App_id)"
                      [disabled]="list.status != 'Time alloted' && list.status != 'Open'"></mdb-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
