<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="border-bottom: 0 !important;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill details</h5>
          </div>
          <div class="headerButtons">
            <a class="print_area" *ngIf="printFlag"><button printSectionId="print" (click)="print_area()"
                [useExistingCss]="true" ngxPrint class="borderdiv billdetailviewleft" mat-icon-button>
                <img src="../../../assets/img/printer.svg" style="width: 25px !important;height: 25px !important;"
                  class="printimagecss" /></button></a>
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
            <a *ngIf="this.flowFrom != 'dietician'" (click)="history()"><img
                src="../../../assets/ui_icons/buttons/History_Bill_icon.svg" class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div *ngFor="let billprint of billing_print_array">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <div [hidden]="hosp_address">
                <table>
                  <tr>
                    <td style="text-align: left !important;font-size: 12px;"><b>{{billprint.hptl_name}}</b></td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.address}}</td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.location}}{{billprint.city}}</td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.state}}</td>
                  </tr>
                  <tr>
                    <td class="changefont"> <img class="phone_img" height="15px" width="15px"
                        src="../../../assets/img/phone.png" /> {{billprint.telephone}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <table>
                <tr>
                  <th style="text-align: left !important;font-size: 12px;">Bill date : </th>
                  <td>
                    {{billprint.created_date}}
                  </td>
                </tr>
                <tr>
                  <th style="text-align: left !important;font-size: 12px;">Bill number : </th>
                  <td style="text-align: left;">
                    {{bill_view_id}}
                  </td>
                </tr>
                <!-- <tr>
                  <th style="text-align: left !important;" [hidden]="pay_typeflag">PaymentType : </th>
                  <td  style="text-align: left !important;">
                    {{billprint.pay_type}}
                  </td>
                </tr> -->
                <tr [hidden]="main_balshow">
                  <!-- <th style="text-align: left !important;font-size: 12px;">Bill amount (र) :</th>
                  <td style="text-align: left !important;font-size: 12px;">
                    {{total_bill}}
                  </td> -->
                </tr>
              </table>
            </div>

          </div>
          <div class="row chwidth">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="tervys_heading ">Patient details</p>
              <table>
                <tr>
                  <th style="text-align: left !important;font-size: 12px;" class="thvalue">Patient name : </th>
                  <td style="text-align: left;font-size: 12px;">{{billprint.client_name}}</td>
                </tr>
                <tr>
                  <th style="text-align: left !important;font-size: 12px;" class="thvalue">Mobile : </th>
                  <td style="text-align: left;font-size: 12px;">{{billprint.mobile}}</td>
                </tr>
                <tr>
                  <th style="text-align: left !important;font-size: 12px;" class="thvalue">UHID : </th>
                  <td style="text-align: left;font-size: 12px;">{{billprint.client_reg_id}}</td>
                </tr>
              </table>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="tervys_heading">Address</p>
              <table>
                <tr>
                  <td class="thvalue" style="font-size: 12px;">{{billprint.caddress}}</td>
                </tr>
                <tr>
                  <td class="thvalue" style="font-size: 12px;">{{billprint.clocation}}{{ billprint.clientcity}}</td>
                </tr>
                <tr>
                  <td class="thvalue" style="font-size: 12px;">{{billprint.clestare}}</td>
                </tr>
                <tr>
                  <td class="thvalue" style="font-size: 12px;">{{billprint.ccountry}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12" [hidden]="bill_printconcise">
              <p class=" col-md-3 col-lg-3 col-xl-3 col-sm-3" *ngIf="billing_print_view.length !=0"
                class="tervys_heading" style="font-weight: 500;">Bill particulars</p>
            </div>

            <div class="table-responsive" *ngIf="billing_print_view1.length">
              <table id="card_tbl" class="table table-nowrap table-sm">
                <thead>
                  <tr style="height: 30px;">
                    <th>Sno</th>
                    <th>Service type</th>
                    <th [hidden]="paytypreq">Payment type</th>
                    <th>Fee</th>
                    <th [hidden]="bill_qty_flag">Quantity</th>
                    <th>Concession (%)</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 30px;" *ngFor="let person of billing_print_view1">
                    <td style="font-size: 12px;" class="txtalign">{{person.index}}</td>
                    <td style="font-size: 12px;" class="txtalign">{{person.charge_desc}}</td>
                    <td style="font-size: 12px;" class="txtalign" [hidden]="paytypreq">{{person.pay_type}}</td>
                    <td style="font-size: 12px;" class="txtalignright">{{person.amount_txt}}</td>
                    <td style="font-size: 12px;" style="text-align:center;" [hidden]="bill_qty_flag">{{person.quantity}}
                    </td>
                    <td style="font-size: 12px;" class="txtalignright">{{person.concession}}</td>
                    <td class="billlistleft" style="font-size: 12px;" class="txtalignright">{{person.fee}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div [hidden]="treat_printid">
            <p class="casesheet_para">Treatement plan</p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="treat_printid">
            <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th class="tbl_txt_center">Estimate</th>
                  <th class="tbl_txt_center">Select</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of estimate_dataprint let i = index">
                  <td>{{person.treatment_desc}}</td>
                  <td class="tbl_txt_center">{{person.estimate}}</td>
                   </tr>
              </tbody>
            </table> <br><br><br>
          </div>
          <div class="row">
            <table>
              <tr>
                <td style="vertical-align: top;width: 66%;">
                  <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-8">
                    <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                      <tr *ngFor="let pay of paymentsarray">
                        <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                        <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td style="width: 34%;">
                  <div style="padding-top: 20px;padding-right: 19px;">
                    <div class="row">
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="totalshow">
                        <mat-label class="matlabel ">Total र </mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="totalshow">
                        <p class="txtalignright"><span class="">{{total_bill1}}</span></p>
                      </div>
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="cenra_gst">
                        <mat-label class="matlabel">Central GST {{billprint.cgst}}% </mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="cenra_gst">
                        <p class="txtalignright"><span class="">{{cent_gst}} </span></p>
                      </div>
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="stat_gst">
                        <mat-label class="matlabel">State GST {{billprint.cgst}} %</mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="stat_gst">
                        <p class="txtalignright"><span class="">{{sta_gst}} </span></p>
                      </div>
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="prev_balshow">
                        <mat-label class="matlabel"> Previous balance र</mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="prev_balshow">
                        <p class="txtalignright"><span class="">{{prev_bal}}</span></p>
                      </div>
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="totalshow">
                        <mat-label class="matlabel "> Bill amount र</mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="totalshow">
                        <p class="txtalignright"><span class="">{{total_bill}} </span></p>
                      </div>
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="totalshow">
                        <mat-label class="matlabel "> Discount र</mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="totalshow">
                        <p class="txtalignright"><span class="">{{roundoff}} </span></p>
                      </div>
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="billadvance_style">
                        <mat-label class="matlabel">Advance र</mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="billadvance_style">
                        <p class="txtalignright"><span class="">{{billprint.advance}} </span></p>
                      </div>
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="billadvance_style">
                        <mat-label class="matlabel">Paid र</mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="billadvance_style">
                        <p class="txtalignright"><span class="">{{paid}} </span></p>
                      </div>
                      <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight">
                        <mat-label class="matlabel">Balance र </mat-label>
                      </div>
                      <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4">
                        <p class="txtalignright"><span class="">{{billprint.balance}}</span></p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- print -->
        <div [hidden]="true">
          <div #printlogowithname id="printlogowithname">
            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <!-- Doctor details -->
                <table style="border:'0';width:100%">  
                  <thead>  
                   <tr>  
                    <th style="width:100%;height: 100px;">
                      <table style="width: 100%;">
                        <tr>
                          <td style="display: flex;justify-content: center;">
                            <div>
                              <img alt="image" src={{hospital_logo}} width="100px" height="100px"
                                style="vertical-align: middle;">
                            </div>
                            <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                              <span><b>{{billprint.hptl_name}}</b>,</span><br />
                              <span *ngIf="billprint.address != ''">{{billprint.address}}</span><br />
                              <span>{{billprint.location}}</span> <span>{{billprint.city}}</span><br />
                              <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img
                                  src="../../../assets/img/phone.png" width="15px"
                                  height="15px" />{{billprint.telephone}}.</span><br>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </th>  
                  </tr>  
                 </thead>  
                 <tfoot> 
                  <tr>  
                   <td width="100%">  
                    <table style="border:'0';width:100%">  
                      <tr>  
                        <td style="width:100%;height: 100px;">&nbsp;</td>  
                     </tr>  
                   </table>  
                   </td>
                  </tr>
                 </tfoot> 
                 <tbody>  
                   <tr>  
                     <td width="100%">  
                     </td>
                   </tr>
                 </tbody>
                </table>
                <br />
                <table style="width: 90%; margin: 0 auto;">
                  <tr>
                    <td colspan="2">
                      <div [innerHTML]="headerstyle"></div>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%;vertical-align:top">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                          <br />
                          <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                          <br *ngIf="billprint.inpatid != undefined" />
                          <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                          <br />
                          <span><strong> UHID : </strong>{{billprint.client_reg_id}}</span>
                          <br />
                          <span><strong>Bill date : </strong>{{billprint.created_date}} </span>
                          <br />
                          <span><strong>Bill number : </strong>{{bill_view_id}} </span>
                        </div>
                      </div>
                    </td>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4">
                          <span><strong>Address</strong></span>
                          <br />
                          <span> {{billprint.caddress}}</span>
                          <br />
                          <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <br />
                          <span>{{billprint.clestare}}</span>
                          <br />
                          <span>{{billprint.ccountry}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
  
                <!-- Bill particulars -->
                <div [hidden]="bill_printconcise" style="margin-left: 45px;margin-right: 10px; width: 800px;">
                  <h3 class="heading">Bill particulars</h3>
                  <div class="row">
                    <table style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                      <tr>
                        <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Payment type</th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                          [hidden]="bill_qty_flag">Quantity</th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                          [hidden]="bill_cons_flag"><b>Concession
                            (%)</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                      </tr>
                      <tr *ngFor="let billprinttable of billing_print_view">
                        <td style="width: 50px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="index">{{billprinttable.index}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.bill_type}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.pay_type}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee">{{billprinttable.amount_txt}}</td>
                        <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <br />
                <div [hidden]="treat_printid" style="margin-left: 45px; width: 800px;">
                  <h3 class="heading">Bill particulars</h3>
                  <div class="main_class row">
                    <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                      <tr>
                        <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                        <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                      </tr>
                      <tr *ngFor="let estimate of estimate_dataprint">
                        <td style="width: 350px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Plan">{{estimate.treatment_desc}}</td>
                        <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Estimate">{{estimate.estimate}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
  
                <br />
                <!-- Total calculation table -->
                <div style="width: 100%;margin-left: -8px;">
                  <table>
                    <tr>
                      <td style="vertical-align: top;width: 66%;">
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                          <tr *ngFor="let pay of paymentsarray">
                            <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                            <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                          </tr>
                        </table>
                      </td>
                      <td style="width: 34%;">
                        <table style="float: right;margin-right: 18px;">
                          <tr [hidden]="totalshow">
                            <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;width: 200px;">Total (र) </th>
                            <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र" class="fee_txt">
                              {{total_bill1}}</td>
                          </tr>
                          <tr [hidden]="cenra_gst">
                            <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;width: 200px;"><strong>Central GST {{billing_print_view.cgst}}</strong> % </th>
                            <td style="text-align: right; width: 100px;margin-right: 10px;" data-th="Central GST"
                              class="fee_txt">
                              {{cent_gst}}</td>
                          </tr>
                          <tr [hidden]="stat_gst">
                            <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;width: 200px;"><strong>State GST {{billing_print_view.cgst}}</strong> % </th>
                            <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="State GST" class="fee_txt">
                              {{sta_gst}}</td>
                          </tr>
                          <tr [hidden]="prev_balshow">
                            <!-- <td style="width: 50px;background-color: #3ab76e;"></td> -->
                            <th style="text-align: left;width: 200px;">Previous balance (र)</th>
                            <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Previous balance र"
                              class="fee_txt">{{prev_bal}}</td>
                          </tr>
                          <tr [hidden]="totalshow">
                            <!-- <td style="width: 10px;background-color: #c63030;"></td> -->
                            <th style="text-align: left;">Bill amount (र)</th>
                            <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                              class="fee_txt">
                              {{total_bill}}</td>
                          </tr>
                          <tr>
                            <!-- <td style="width: 100px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;">Discount</th>
                            <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                              class="fee_txt">
                              {{roundoff}}</td>
                          </tr>
                          <tr [hidden]="billadvance_style">
                            <!-- <td style="width: 100px;background-color: #ffffff"></td> -->
                            <th style="text-align: left;">Advance (र)</th>
                            <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{billprint.advance}}</td>
                          </tr>
                          <tr [hidden]="billadvance_style">
                            <!-- <td style="width: 100px;background-color: #ffffff"></td> -->
                            <th style="text-align: left;"> Paid (र)</th>
                            <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{paid}}</td>
                          </tr>
                          <tr>
                            <!-- <td style="width: 100px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;">Balance (र)</th>
                            <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance" class="fee_txt">
                              {{billprint.balance}}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <br /><br /><br />
                </div>
              </div>
              <br />
              <br /><br />
            </div>
            <br />
            <table>
              <tr>
                <td>
                  <p style="margin-left: 350px;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                  <br />
                </td>
              </tr>
            </table>
          </div>
          <div #printbanner id="printbanner">
            <table style="border:'0';width:100%">  
              <thead>  
               <tr>  
                <th style="width:100%;height: 100px;">
                  <table style="width: 100%;">
                    <tr>
                      <td style="display: flex;justify-content: center;">
                        <div>
                          <img alt="image" src={{hospital_logo}} style={{printlogostyle}}>
                        </div>
                      </td>
                    </tr>
                  </table>
                </th>  
              </tr>  
             </thead>  
             <tfoot> 
              <tr>  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;height: 100px;">&nbsp;</td>  
                 </tr>  
               </table>  
               </td>
              </tr>
             </tfoot> 
             <tbody>  
               <tr>  
                 <td width="100%">  
                  <div *ngFor="let billprint of billing_print_array">
                    <table style="width: 90%; margin: 0 auto;">
                      <tr>
                        <td colspan="2">
                          <div [innerHTML]="headerstyle"></div>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 50%;">
                          <div class="row">
                            <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                              <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                              <br />
                              <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                              <br *ngIf="billprint.inpatid != undefined" />
                              <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                              <br />
                              <span><strong> UHID : </strong>{{billprint.client_reg_id}}</span>
                              <br />
                              <span><strong>Bill date : </strong>{{billprint.created_date}} </span>
                              <br />
                              <span><strong>Bill number : </strong>{{bill_view_id}} </span>
      
                            </div>
                          </div>
                        </td>
      
                        <td style="width: 50%;vertical-align:top">
                          <div class="row">
                            <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4">
                              <span><strong>Address</strong></span>
                              <br />
                              <span> {{billprint.caddress}}</span>
                              <br />
                              <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                              <br />
                              <span>{{billprint.clestare}}</span>
                              <br />
                              <span>{{billprint.ccountry}}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
      
                    <!-- Bill particulars -->
                    <div [hidden]="bill_printconcise" style="margin-left: 45px;margin-right: 10px; width: 800px;">
                      <h3 class="heading">Bill particulars</h3>
                      <div class="row">
                        <table style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                          <tr>
                            <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                            <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                            <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Payment type</th>
                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                              [hidden]="bill_qty_flag">Quantity</th>
                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                              [hidden]="bill_cons_flag"><b>Concession
                                (%)</b></th>
                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                          </tr>
                          <tr *ngFor="let billprinttable of billing_print_view">
                            <td style="width: 50px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                              data-th="index">{{billprinttable.index}}</td>
                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                              data-th="Type">{{billprinttable.bill_type}}</td>
                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                              data-th="Type">{{billprinttable.pay_type}}</td>
                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                              data-th="Fee">{{billprinttable.amount_txt}}</td>
                            <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                              data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                              data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <br />
                    <div [hidden]="treat_printid" style="margin-left: 45px; width: 800px;">
                      <h3 class="heading">Bill particulars</h3>
                      <div class="main_class row">
                        <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                          <tr>
                            <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                            <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                          </tr>
                          <tr *ngFor="let estimate of estimate_dataprint">
                            <td style="width: 350px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                              data-th="Plan">{{estimate.treatment_desc}}</td>
                            <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                              data-th="Estimate">{{estimate.estimate}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
      
                    <br />
                    <!-- Total calculation table -->
                    <div style="width: 100%;margin-left: -8px;">
                      <table>
                        <tr>
                          <td style="vertical-align: top;width: 66%;">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                              <tr *ngFor="let pay of paymentsarray">
                                <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                              </tr>
                            </table>
                          </td>
                          <td style="width: 34%;">
                            <table style="float: right;margin-right: 18px;">
                              <tr [hidden]="totalshow">
                                <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;width: 200px;">Total (र) </th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र" class="fee_txt">
                                  {{total_bill1}}</td>
                              </tr>
                              <tr [hidden]="cenra_gst">
                                <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;width: 200px;"><strong>Central GST {{billing_print_view.cgst}}</strong> % </th>
                                <td style="text-align: right; width: 100px;margin-right: 10px;" data-th="Central GST"
                                  class="fee_txt">
                                  {{cent_gst}}</td>
                              </tr>
                              <tr [hidden]="stat_gst">
                                <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;width: 200px;"><strong>State GST {{billing_print_view.cgst}}</strong> % </th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="State GST" class="fee_txt">
                                  {{sta_gst}}</td>
                              </tr>
                              <tr [hidden]="prev_balshow">
                                <!-- <td style="width: 50px;background-color: #3ab76e;"></td> -->
                                <th style="text-align: left;width: 200px;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Previous balance र"
                                  class="fee_txt">{{prev_bal}}</td>
                              </tr>
                              <tr [hidden]="totalshow">
                                <!-- <td style="width: 10px;background-color: #c63030;"></td> -->
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{total_bill}}</td>
                              </tr>
                              <tr>
                                <!-- <td style="width: 100px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;">Discount</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{roundoff}}</td>
                              </tr>
                              <tr [hidden]="billadvance_style">
                                <!-- <td style="width: 100px;background-color: #ffffff"></td> -->
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{billprint.advance}}</td>
                              </tr>
                              <tr [hidden]="billadvance_style">
                                <!-- <td style="width: 100px;background-color: #ffffff"></td> -->
                                <th style="text-align: left;"> Paid (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paid}}</td>
                              </tr>
                              <tr>
                                <!-- <td style="width: 100px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance" class="fee_txt">
                                  {{billprint.balance}}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <br /><br /><br />
                    </div>
                    <br />
                    <br /><br />
                    <br />
                    <table>
                      <tr>
                        <td>
                          <p style="margin-left: 350px;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                          <br />
                        </td>
                      </tr>
                    </table>
                  </div>
                 </td>
               </tr>
             </tbody>
            </table>
          </div>
          <div #printnoheader id="printnoheader">
            <div *ngFor="let billprint of billing_print_array">
              <table style="width: 100vh;">
                <thead>
                  <tr>
                    <td>
                      <div style="height: 100px;">&nbsp;</div>
                    </td>
                  </tr>
                </thead>
                <tfoot> 
                  <tr>  
                   <td width="100%">  
                    <table style="border:'0';width:100%">  
                      <tr>  
                        <td style="width:100%;height: 100px;">&nbsp;</td>  
                     </tr>  
                   </table>  
                   </td>
                  </tr>
                 </tfoot> 
                <tbody>
                  <tr>
                    <td>
                      <div class="content" style="width: 100%;">
                        <div [innerHTML]="headerstyle"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                          <br />
                          <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                          <br />
                          <br *ngIf="billprint.inpatid != undefined" />
                          <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                          <span><strong> UHID : </strong>{{billprint.client_reg_id}}</span>
                          <br />
                          <span><strong>Bill date : </strong>{{billprint.created_date}} </span>
                          <br />
                          <span><strong>Bill number : </strong>{{bill_view_id}} </span>

                        </div>
                      </div>
                    </td>

                    <td style="width: 50%;vertical-align:top">
                      <div class="row">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4">
                          <span><strong>Address</strong></span>
                          <br />
                          <span> {{billprint.caddress}}</span>
                          <br />
                          <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <br />
                          <span>{{billprint.clestare}}</span>
                          <br />
                          <span>{{billprint.ccountry}}</span>
                        </div>
                      </div>
                    </td>
                    <!-- Bill particulars -->
                    <div [hidden]="bill_printconcise" style="margin-left: 45px;margin-right: 10px; width: 800px;">
                      <h3 class="heading">Bill particulars</h3>
                      <div class="row">
                        <table style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                          <tr>
                            <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                            <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                            <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Payment type</th>
                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                              [hidden]="bill_qty_flag">Quantity</th>
                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"
                              [hidden]="bill_cons_flag"><b>Concession
                                (%)</b></th>
                            <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                          </tr>
                          <tr *ngFor="let billprinttable of billing_print_view">
                            <td style="width: 50px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                              data-th="index">{{billprinttable.index}}</td>
                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                              data-th="Type">{{billprinttable.bill_type}}</td>
                            <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                              data-th="Type">{{billprinttable.pay_type}}</td>
                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                              data-th="Fee">{{billprinttable.amount_txt}}</td>
                            <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                              data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                            <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                              data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <br />
                    <div [hidden]="treat_printid" style="margin-left: 45px; width: 800px;">
                      <h3 class="heading">Bill particulars</h3>
                      <div class="main_class row">
                        <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                          <tr>
                            <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                            <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                          </tr>
                          <tr *ngFor="let estimate of estimate_dataprint">
                            <td style="width: 350px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                              data-th="Plan">{{estimate.treatment_desc}}</td>
                            <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                              data-th="Estimate">{{estimate.estimate}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <br />
                    
                    <!-- Total calculation table -->
                    <div style="width: 100%;margin-left: -8px;">
                      <table>
                        <tr>
                          <td style="vertical-align: top;width: 66%;">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                              <tr *ngFor="let pay of paymentsarray">
                                <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                              </tr>
                            </table>
                          </td>
                          <td style="width: 34%;">
                            <table style="float: right;margin-right: 18px;">
                              <tr [hidden]="totalshow">
                                <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;width: 200px;">Total (र) </th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र" class="fee_txt">
                                  {{total_bill1}}</td>
                              </tr>
                              <tr [hidden]="cenra_gst">
                                <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;width: 200px;"><strong>Central GST {{billing_print_view.cgst}}</strong> % </th>
                                <td style="text-align: right; width: 100px;margin-right: 10px;" data-th="Central GST"
                                  class="fee_txt">
                                  {{cent_gst}}</td>
                              </tr>
                              <tr [hidden]="stat_gst">
                                <!-- <td style="width: 110px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;width: 200px;"><strong>State GST {{billing_print_view.cgst}}</strong> % </th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="State GST" class="fee_txt">
                                  {{sta_gst}}</td>
                              </tr>
                              <tr [hidden]="prev_balshow">
                                <!-- <td style="width: 50px;background-color: #3ab76e;"></td> -->
                                <th style="text-align: left;width: 200px;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Previous balance र"
                                  class="fee_txt">{{prev_bal}}</td>
                              </tr>
                              <tr [hidden]="totalshow">
                                <!-- <td style="width: 10px;background-color: #c63030;"></td> -->
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{total_bill}}</td>
                              </tr>
                              <tr>
                                <!-- <td style="width: 100px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;">Discount</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{roundoff}}</td>
                              </tr>
                              <tr [hidden]="billadvance_style">
                                <!-- <td style="width: 100px;background-color: #ffffff"></td> -->
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{billprint.advance}}</td>
                              </tr>
                              <tr [hidden]="billadvance_style">
                                <!-- <td style="width: 100px;background-color: #ffffff"></td> -->
                                <th style="text-align: left;"> Paid (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paid}}</td>
                              </tr>
                              <tr>
                                <!-- <td style="width: 100px;background-color: #ffffff;"></td> -->
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance" class="fee_txt">
                                  {{billprint.balance}}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <br /><br /><br />
                    </div>
                    <br />
                    <br /><br />
                    <br />
                    <table>
                      <tr>
                        <td>
                          <p style="margin-left: 350px;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                          <br />
                        </td>
                      </tr>
                    </table>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>