import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Doc_Helper } from '../Doc_Helper'
import { Date_Formate,ConvertTimeformat, Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
declare var $: any;

@Component({
  selector: 'app-ipatient-bill-create',
  templateUrl: './ipatient-bill-create.component.html',
  styleUrls: ['./ipatient-bill-create.component.scss']
})
export class IpatientBillCreateComponent implements OnInit {
  public save_billcreate: any;
  tomorrow = new Date();
  public bill_palnche;
  public appoint_txt;
  public charge_type: string
  public charge_id: string;
  public amount_txt;
  public final_amount;
  public inGST: boolean;
  public msgflag: boolean = false;
  public profile_image: string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;
  public notifications;
  public notifyflag: boolean = false;

  public concession_txt;
  public con_txt;
  public session: string;
  public amount_aftcon;
  public table_div: boolean;
  public final_div: boolean;
  public gst_amount_txt;
  public Advance_bill;
  public CurrentDatetime;
  public Appoint_Date;
  public appointment_list: any = [];
  public Docidname;
  public Bill_appoint_id;
  public hospital_name;
  public middle_appoin: string;
  public billden_plan: any = [];
  public gst_retrive_list: any = [];
  public billaddlist: any = [];

  public gst_data_list: any = [];
  public chk_treat_arr: any = [];
  public charge_name;
  public estimate_id: any = [];
  public paytypelist: any = [];
  public insurerlist: any = [];
  public pay_type: string;
  public insurer_id: string;
  public insur_flag: boolean;
  public remaing_billbal = "0.00";
  public addvancetxt;
  public totalcolletxt;
  public cent_gst;
  public sta_gst;
  public doc_client_reg_id;
  public doc_relation_id;
  public doc_sub_rel_id;
  public Charge_id;
  public charge_desc;
  public hptl_clinic_id: string;
  public header_footer_flag: boolean;
  public addbtn_flag: boolean;
  public bill_date;
  public current_amount = "0.00";
  public billdate;
  public diet_followtable: boolean;
  public Gsthidden: boolean = false;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public doctor_profile_img!: string | null;
  public cenra_gst;
  public stat_gst;
  public prev_bal: string;
  public total_bill: string;
  public total_bill1: string;

  public currentDate;
  public userinfo;
  public tempRoundoff = "0.00";
  //mdbTable
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public user_id;

  //Get apointment details
  public patient_name: string;
  public prev_bill_id;
  public balance_show: boolean;
  public amount_collected: boolean;
  public previous_balshow: boolean;
  public balance_data;
  public total_collected;
  public round_off;
  public paid_bill;
  public sign;

  public billing_print_array;
  public medcalDetailsArray:any = [];
  public digDetailsArray:any = [];

  public hospitalAddress;
  public Hospital_location;

  public diagnosisAmount = "0.00";
  public medicine_amount = "0.00";
  public medicinBal = "0.00";

  public dog_reg_id;
  public serviceType:any = [];
  public inpatientEdit: boolean  = false;
  public quantity = "1";
  public hospitalBills = "0.00";
  public saveBillURL;
  public bill_id;
  public pre_paid_bill = "0.00";
  public pre_bill_amount = "0.00";
  public pharmaPaid = "0.00";
  public diagPaid = "0.00";
  public show_save_btn:boolean = true;
  public bill_cons_flag:boolean=true;
  public bill_qty_flag:boolean=true;
  public date_time_flag:boolean=false;
  public paytypreq:boolean=false;
  private rowid:number=0;
  public bill_time1;
  public bill_time2;
  public bill_time3;
  public bill_hrs:any=[];
  public bill_min:any=[];
  //public payTypeList:any=[];
  public paymentsarray:any=[];
  public currentTime;
  public payType1;
  public amountval:number;
  public tamt:number=0;

  constructor(public doctorservice: MenuViewService, public toastr: ToastrService, public http: Http, public router: Router, public messageservice: MenuViewService,public masterData:MasterHelperService) {
    this.addbtn_flag = true;
    this.insur_flag = true;
    this.tomorrow.setDate(this.tomorrow.getDate() + 0);
    this.billdate = new FormControl(new Date());
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;

    this.bill_time1 = "";
    this.bill_time2 = "00";
    this.amount_txt=0.00;
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.bill_hrs.push("0" + i);
      } else {
        this.bill_hrs.push(i);
      }
    }

    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) {
        this.bill_min.push("0" + i);
      } else {
        this.bill_min.push(i);
      }
    }
  }

  ngOnInit(): void {
    this.show_save_btn = true;
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    var hospitaldet = Helper_Class.getHospital();
    this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    this.pay_type = "Cash";
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;
    this.dtOptions = {
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3, 4, 5] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.doctor_profile_img = this.userinfo.profile_image;
    this.amount_change();
    this.getPaymentType();
    var value

    this.final_amount = "0";
    this.gst_amount_txt = "0";
    this.Advance_bill = "0.00";
    this.paid_bill = "0.00";
    this.round_off = "0.00";
    this.concession_txt = 0;
    this.inGST = false;
    this.Get_current_date();
    this.gst_retrive_list = [];
    this.billaddlist = [];
    this.Get_Gst_Data();
    this.estimate_id = [];
    this.previous_balshow = true;
    this.balance_show = true;
    this.getInsurers();
    this.Get_Bill_Type();
    this.saveBillURL = "bill/savebill/";

    if(Helper_Class.getInfo().bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }

    if(Helper_Class.getInfo().hospitals[0].bill_cons_flag == 0){
      this.bill_cons_flag =true;
    } else {
      this.bill_cons_flag = false;
    }

    if(Helper_Class.getInfo().hospitals[0].bill_qty_flag == 0){
      this.bill_qty_flag =true;
    } else {
      this.bill_qty_flag = false;
    }
    if(Helper_Class.getInfo().bill_date_time_edit != undefined){
      if(Helper_Class.getInfo().bill_date_time_edit == "1"){
        this.date_time_flag = false;
      } else {
        this.date_time_flag = true;
      }
    } else {
      if(Helper_Class.getInfo().hospitals[0].bill_date_time_edit == "1"){
        this.date_time_flag = false;
      } else {
        this.date_time_flag = true;
      }
    }
    console.log("BILL DATE TIME "+this.date_time_flag)
  }

  backClicked() {
    if (Helper_Class.getInpatientBillCreate() == "front-desk") {
      this.messageservice.sendMessage("frontdeskbilling");
    } else {
      Doc_Helper.setIpCreateBill("ipbill");
      this.doctorservice.sendMessage("billing");
    }
  }

  //Bill add table
  Bill_add_click() {
    this.diet_followtable = true;
    this.addbtn_flag = true;

    if (this.appoint_txt == undefined) {
      this.toastr.error(Message_data.sltAppId);
    } else if (this.charge_type == undefined) {
      this.toastr.error(Message_data.sltBillType);
    } else if (this.amount_txt == undefined || this.amount_txt == '') {
      this.toastr.error(Message_data.enterAmt);
    } else {
      if (this.final_amount == "0") {
        this.inGST == false;
      }
      this.table_div = true;
      this.final_div = true;
      var flag = true;
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].biill_type == this.charge_type && this.billaddlist[i].paytype == this.pay_type) {
          flag = false;
          break;
        }
      }

      if (flag == true) {
        if (this.charge_name.length != 0) {
          for (var i = 0; i < this.charge_name.length; i++) {
            if (this.charge_type == this.charge_name[i].description) {
              this.charge_id = this.charge_name[i].charge_id;
            }
          }
        }
        var paydesc;
        if (this.paytypelist.length != 0) {
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.pay_type == this.paytypelist[i].pay_id) {
              paydesc = this.paytypelist[i].pay_desc;
            }
          }
        }
        this.con_txt = this.concession_txt == "" ? 0 : this.concession_txt;
        var paydesc;
        if (this.paytypelist.length != 0) {
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.pay_type == this.paytypelist[i].pay_id) {
              paydesc = this.paytypelist[i].pay_desc;
            }
          }
        }
        this.rowid=this.rowid+1;
        if(this.paytypreq == true){
          this.billaddlist.push({
            sno:this.rowid,
            bill_type: this.charge_type,
            amount_txt: parseFloat(this.amount_txt).toFixed(2),
            concession: parseFloat(this.con_txt).toFixed(2),
            fee: parseFloat(this.amount_aftcon).toFixed(2),
            charge_type: this.charge_id,
            insurer: this.insurer_id,
            quantity : this.quantity,
          });
        } else {
          this.billaddlist.push({
            sno:this.rowid,
            bill_type: this.charge_type,
            amount_txt: parseFloat(this.amount_txt).toFixed(2),
            concession: parseFloat(this.con_txt).toFixed(2),
            fee: parseFloat(this.amount_aftcon).toFixed(2),
            charge_type: this.charge_id,
            pay_type: paydesc,
            paytype: this.pay_type,
            insurer: this.insurer_id,
            quantity : this.quantity,
          });
        }
        

        this.amount_txt = '';
        this.concession_txt = 0;
        this.amount_aftcon = '';
        this.quantity = "1";
        this.charge_type=undefined;
      }

      if (this.final_amount == "0") {
        this.inGST == false;
      }
      this.hospitalBills = "0.00";
      if(this.billaddlist.length != 0 ){
          for( var i = 0; i < this.billaddlist.length; i++){
            this.hospitalBills =  (parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)).toFixed(2);
          }
      }
      this.Calculate_gst("0", "0");
    }
  }
  
  Delete_bill(type, amount) {//Delete bill
    if (this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].biill_type == type && this.billaddlist[i].amount_txt == amount) {
          this.billaddlist.splice(i, 1);
          //this.Calculate_gst("0", "0");
          break;
        }
      }
    }
    this.rowid=0;
    for (var i = 0; i < this.billaddlist.length; i++) {
      if (this.billaddlist[i].status != '2') {
        this.rowid +=1;
        this.billaddlist[i].sno=this.rowid;
      }
    }
    
    this.hospitalBills = "0.00";
    if(this.billaddlist.length != 0 ){
        for( var i = 0; i < this.billaddlist.length; i++){
          this.hospitalBills =  (parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)).toFixed(2);
        }
    }
    if (this.billaddlist.length == 0) {
      this.table_div = false;
      this.final_div = false;
    } else {
      this.table_div = true;
    }

    if (this.final_amount == "0") {
      this.inGST == false;
    }
    this.Calculate_gst("0", "0");
  }

  Get_Bill_Type() { //Get bill type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      JSON.stringify({
        hptl_clinic_id: this.hptl_clinic_id,
        provider: "doctor",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          this.charge_name = obj.charges;
          // this.charge_type = this.charge_name[0].description;
          //this.amount_txt = this.charge_name[0].amount;
          //this.charge_change();
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  //Get payment type
  getPaymentType() {
    var obj = Master_Helper.getMasterPaymentType();
    this.paytypelist = obj.payments;
    
    this.pay_type = this.paytypelist[0].pay_id;
  }

  pay_change() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    if (this.pay_type == "3") {
      this.insur_flag = false;

    } else {
      this.insur_flag = true;
    }
  }

  //Get payment type
  getInsurers() {
    var obj = Master_Helper.getMasterInsurers();
    this.insurerlist = obj.insurers;
  }

  charge_change(charge) {
    this.charge_type = charge;
    this.serviceType = []; for (var i = 0; i < this.charge_name.length; i++) {
      if (this.charge_name[i].description == this.charge_type) {
        this.amount_txt = this.charge_name[i].amount;
        this.amount_aftcon = this.charge_name[i].amount;
      }
    }
    this.addbtn_flag = false;
  }

  getServiceType(serviceType) {
    this.serviceType = [];
    if (serviceType.length >= 3) {
      if (this.charge_name.length != 0) {
        if (this.charge_name.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()))) {
          var chg = this.charge_name.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()));
          for (var i = 0; i < chg.length; i++) {
            this.serviceType.push(chg[i].description);
          }
        } else {
          this.serviceType = [];
        }
      }
    } else {

    }
  }

  getAppointments(patientList) {
    // this.CurrentDatetime = billdate;
    this.appointment_list = [];
    if (patientList.target.value.toString() != null && patientList.target.value.toString().length > 2) {
      var headers = new Headers();
      var name =   patientList.target.value.toUpperCase();
      var send_data = JSON.stringify({
        hptl_clinic_id: this.hptl_clinic_id,
        first_name : name
      });
      headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/inpa', send_data ,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.appointment != null) {
            for (var i = 0; i < obj.appointment.length; i++) {
              obj.appointment[i].middle_name
              var date = obj.appointment[i].admitted_date != undefined ?  " (" + Date_Formate(obj.appointment[i].admitted_date) + ")" : " ";
              if (obj.appointment[i].middle_name != undefined) {
                this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + date;
              } else {
                this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + date;
              }

              var sessiondata = null;
              if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
              }
              var address;
              if (obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                address = encrypt_decript.Decript(obj.appointment[i].address1) + ", " + encrypt_decript.Decript(obj.appointment[i].address2)
              } else if (obj.appointment[i].address1 != undefined) {
                address = encrypt_decript.Decript(obj.appointment[i].address1);
              } else {
                address = "";
              }
              var hospital;
              if (obj.hosp_address2 != undefined) {
                hospital = obj.hosp_address1 + "," + obj.hosp_address2;

              } else {
                hospital = obj.hosp_address1;
              }
              var inpatientId = obj.appointment[i].inpat_id != undefined ? obj.appointment[i].inpat_id : "-";
              var zipcode = obj.appointment[i].zipcode != undefined ? encrypt_decript.Decript(obj.appointment[i].zipcode) : "";
              this.hospital_name = obj.hosp_name != undefined ? obj.hosp_name : "";
              this.hospitalAddress = hospital;
              this.Hospital_location = obj.hosp_location + ", " + obj.hosp_district + " - " + obj.hosp_state + " " + obj.hosp_country + "  " + obj.hosp_zip;
          
              this.appointment_list.push({
                doc_app_id: obj.appointment[i].doc_app_id,
                doc_reg_id: obj.appointment[i].doc_reg_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                sub_rel_id: obj.appointment[i].sub_rel_id,
                address: address,
                location: obj.appointment[i].location,
                district: obj.appointment[i].district,
                state: obj.appointment[i].state,
                country: obj.appointment[i].country,
                first_name: obj.appointment[i].first_name,
                middle_name: obj.appointment[i].middle_name,
                last_name: obj.appointment[i].last_name,
                hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                session: sessiondata,
                city: obj.appointment[i].district,
                zipcode: zipcode,
                docid_name: this.Docidname,
                advance: obj.appointment[i].advance,
                balance: obj.appointment[i].balance,
                inpatientId: inpatientId,
                cgst: "12%",
                sgst: "12$",
                admitted_date : obj.appointment[i].admitted_date,
                admitted_time : obj.appointment[i].admitted_time,
                discharge_date : obj.appointment[i].discharge_date,
                discharge_time : obj.appointment[i].discharge_time,
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
    }

  }

  Appointmnet_change(e) {
    //this.appoint_txt = e;
    var patientname = e.split("-");
    this.patient_name = "";
    this.billden_plan = [];
    this.billaddlist = [];
    this.medcalDetailsArray = [];
    this.digDetailsArray = [];

    this.billing_print_array = [];
    for (var i = 0; i < this.appointment_list.length; i++) {
      if (this.appointment_list[i].doc_app_id == parseFloat(patientname[0])) {
        if (this.appointment_list[i].middle_name != undefined) {
          this.patient_name = encrypt_decript.Decript(this.appointment_list[i].first_name) + " " + encrypt_decript.Decript(this.appointment_list[i].middle_name) + " " + encrypt_decript.Decript(this.appointment_list[i].last_name);
        } else {
          if (this.appointment_list[i].last_name != undefined) {
            this.patient_name = encrypt_decript.Decript(this.appointment_list[i].first_name) + " " + encrypt_decript.Decript(this.appointment_list[i].last_name);
          } else {
            this.patient_name = encrypt_decript.Decript(this.appointment_list[i].first_name);
          }
        }
        if (this.appointment_list[i].advance != undefined) {
          this.Advance_bill = parseFloat(this.appointment_list[i].advance).toFixed(2);
        }
        if (this.appointment_list[i].balance != undefined) {
          this.amount_collected = true;
          this.balance_show = false;
          this.previous_balshow = false;
          this.balance_data = parseFloat(this.appointment_list[i].balance).toFixed();
          this.total_collected = parseFloat(this.appointment_list[i].balance).toFixed();
        }
        this.dog_reg_id = this.appointment_list[i].doc_reg_id;
        var zipcode = this.appointment_list[i].czipcode != undefined ? encrypt_decript.Decript(this.appointment_list[i].czipcode) : "";
        this.billing_print_array.push({
          dr_name: "Dr." + this.appointment_list[i].docid_name,
          licence_code: "",
          hptl_name: "",
          created_date: "",
          address: this.appointment_list[i].address,
          location: this.appointment_list[i].location + ", ",
          city: this.appointment_list[i].city + " - " + this.appointment_list[i].zipcode + ",",
          state: this.appointment_list[i].state + " - " + this.appointment_list[i].country + ",",
          telephone: this.appointment_list[i].telephone,
          inpatientId: this.appointment_list[i].inpatientId,
          appointment_id: this.appointment_list[i].doc_app_id,
          client_name: this.patient_name,
          caddress: "",
          clocation: this.appointment_list[i].clocation + ", ",
          clientcity: this.appointment_list[i].ccity + " - " + zipcode + ",",
          clestare: this.appointment_list[i].cstate + " - " + this.appointment_list[i].ccountry + ".",
          client_reg_id: this.appointment_list[i].client_reg_id,
          advance: this.appointment_list[i].advance,
          balance: this.appointment_list[i].balance,
          relation_id: this.appointment_list[i].relation_id,
          sub_rel_id: this.appointment_list[i].sub_rel_id,
        });
      }
    }
    this.medicinBal = "0.00";
    this.diagnosisAmount = "0.00";
  }
  
  digAmount(id, value) {
    for (var i = 0; i < this.digDetailsArray.length; i++) {
      if (this.digDetailsArray[i].digID == id) {
        var val = value != "" ? value : "0.00";
        var previous = this.digDetailsArray[i].previous_value;
        this.current_amount = (parseFloat(this.current_amount) - parseFloat(this.digDetailsArray[i].previous_value)).toFixed(2);
        this.current_amount = (parseFloat(this.current_amount) + parseFloat(val)).toFixed(2);
        this.total_bill1 = this.current_amount;
        this.total_bill = this.current_amount;
        this.final_amount = this.current_amount;
        if (value != "") {
          this.digDetailsArray[i].amount_txt = val;
          this.digDetailsArray[i].fee = val;
          this.digDetailsArray[i].previous_value = val;
        } else {
          this.digDetailsArray[i].amount_txt = "0.00";
          this.digDetailsArray[i].fee = "0.00";
          this.digDetailsArray[i].previous_value = "0.00";
        }
      }
    }
  }

  amount_change() {
    var perce !: number | null;
    perce = this.concession_txt / 100;
    var amt = this.amount_txt;
    var amt_cons = this.amount_aftcon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amount_aftcon = Math.round(tot) * parseInt(this.quantity);
  }

  //Get gst
  async Get_Gst_Data() {
    var obj = Master_Helper.getMasterGSTData();
    if(Master_Helper.getMasterGSTData() == undefined){
      await this.masterData.getGstData();
    }
    var obj = Master_Helper.getMasterGSTData();
    if(obj != undefined)    
      this.gst_retrive_list = obj.taxes;
    
  }

  // Include GST
  include_gst_click(check) {
    this.Calculate_gst("0", "0");
  }

  Calculate_gst(palnid, planmodal) {
    this.final_amount = "0";
    this.gst_amount_txt = "0";
    this.total_collected = "0";
    this.current_amount = "0";
    this.gst_data_list = [];

    if (this.billden_plan.length != 0) {
      for (var i = 0; i < this.billden_plan.length; i++) {
        for (var j = 0; j < this.estimate_id.length; j++) {
          if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id) {
            this.current_amount = (parseFloat(this.current_amount) + parseFloat(this.billden_plan[i].estimate)).toFixed(2);
            this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.billden_plan[i].estimate)).toFixed(2);
            this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.billden_plan[i].estimate)).toFixed(2);

            if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.total_collected = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
              this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
            }

            if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.remaing_billbal = (parseFloat(this.Advance_bill) - parseFloat(this.final_amount)).toFixed(2);
            }

            this.amount_collected = true;
            if (this.total_collected == "0") {
              this.amount_collected = false;
            }
            if (this.remaing_billbal == "0") {
              this.balance_show = true;
            }
            break;
          }
        }
      }
    }

    if(this.pre_bill_amount != undefined && this.inpatientEdit == true){
      this.pre_bill_amount = (parseFloat(this.pre_bill_amount) + parseFloat(this.pharmaPaid) + parseFloat(this.diagPaid)).toFixed(2);
      this.current_amount = (parseFloat(this.current_amount) + parseFloat(this.pre_bill_amount)).toFixed(2);
      this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.pre_bill_amount)).toFixed(2);
      this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.pre_bill_amount)).toFixed(2);
      this.remaing_billbal = "0.00";
      this.paid_bill = this.Advance_bill != undefined ? (parseFloat(this.pre_bill_amount) - parseFloat(this.Advance_bill)).toFixed(2) : parseFloat(this.pre_bill_amount).toFixed(2)

      if (this.total_collected == "0") {
        this.amount_collected = false;
      }

      if (this.remaing_billbal == "0") {
        this.balance_show = true;
      }
    }else{
      if (this.billaddlist.length != 0) {
        this.current_amount = (parseFloat(this.current_amount) + parseFloat(this.hospitalBills)).toFixed(2);
        this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.hospitalBills)).toFixed(2);
        this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.hospitalBills)).toFixed(2);
  
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
        }
  
        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (parseFloat(this.Advance_bill) - parseFloat(this.final_amount)).toFixed(2);
          this.total_collected = "0";
        }
  
        if (this.total_collected == "0") {
          this.amount_collected = false;
        }
  
        if (this.remaing_billbal == "0") {
          this.balance_show = true;
        }
      }
      if (this.medcalDetailsArray.length != 0) {
        this.current_amount = (parseFloat(this.current_amount) + parseFloat(this.medicinBal)).toFixed(2);
        this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.medicinBal)).toFixed(2);
        this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.medicinBal)).toFixed(2);
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
        }
  
        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (parseFloat(this.Advance_bill) - parseFloat(this.final_amount)).toFixed(2);
          this.total_collected = "0";
        }
  
        if (this.total_collected == "0") {
          this.amount_collected = false;
        }
  
        if (this.remaing_billbal == "0") {
          this.balance_show = true;
        }
      }
      if (this.digDetailsArray.length != 0) {
        this.current_amount = (parseFloat(this.current_amount) + parseFloat(this.diagnosisAmount)).toFixed(2);
        this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.diagnosisAmount)).toFixed(2);
        this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.diagnosisAmount)).toFixed(2);
  
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
        }
  
        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (parseFloat(this.Advance_bill) - parseFloat(this.final_amount)).toFixed(2);
          this.total_collected = "0";
        }
  
        if (this.total_collected == "0") {
          this.amount_collected = false;
        }
  
        if (this.remaing_billbal == "0") {
          this.balance_show = true;
        }
      }
   }
    
    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.gst_data_list.push({
          Gst_Descrip: this.gst_retrive_list[i].tax_desc + " " + this.gst_retrive_list[i].tax,
          Gst_amount: (parseFloat(this.gst_amount_txt) * (parseFloat(this.gst_retrive_list[i].tax) / 100)).toFixed(2)
        });
        this.final_amount = (parseFloat(this.final_amount) + (parseFloat(this.gst_amount_txt) * (parseFloat(this.gst_retrive_list[i].tax) / 100)));
      }
      this.final_amount = parseFloat(this.final_amount).toFixed(2);
    }

    if (this.balance_data != undefined) {
      this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.balance_data)).toFixed(2);
    }

    //amount to be collected
    this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
    if (this.estimate_id.length == 0 && this.billaddlist.length == 0 && this.medcalDetailsArray.length == 0 && this.digDetailsArray.length == 0 ) {
      this.inGST = false;
      this.gst_data_list = [];
      this.Advance_bill = "0"
      this.final_amount = "0";
      this.gst_amount_txt = 0;
      this.total_collected = "0";
      this.remaing_billbal = "0";
    }

    if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
      this.remaing_billbal = "0";
    }
    this.advance_change();
  }

  //add the bill details
  advance_change() {
    this.Advance_bill = this.Advance_bill == "" ? "0.00" : this.Advance_bill;
    this.remaing_billbal = ((parseFloat(this.final_amount) - parseFloat(this.round_off)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
  }

  addRound(data) {
    this.sign = data;
    this.round_off = this.round_off == "" ? "0.00" : this.round_off;
    if (data == "puls") {

      this.remaing_billbal = ((parseFloat(this.final_amount) + parseFloat(this.round_off)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
      // this.remaing_billbal = (parseFloat(this.remaing_billbal) + parseFloat(this.round_off)).toFixed(2);

    } else {
      this.remaing_billbal = ((parseFloat(this.final_amount) - parseFloat(this.round_off)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
      //  this.remaing_billbal = (parseFloat(this.remaing_billbal) - parseFloat(this.round_off)).toFixed(2);

    }
    this.tempRoundoff = this.round_off;
  }

  paid_change() {
    this.paid_bill = this.paid_bill == "" ? "0.00" : this.paid_bill;
    this.remaing_billbal = ((parseFloat(this.final_amount) - parseFloat(this.round_off)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
  }

  //Bill click
  bill_click(planid, planmodal, rowid) {
    if (this.estimate_id.length != 0) {
      if (this.estimate_id.indexOf(planid) > -1) {
        for (var i = 0; i < this.estimate_id.length; i++) {
          if (this.estimate_id[i] == planid) {
            this.estimate_id.splice(i, 1);
            this.Calculate_gst("0", "0");
          }
        }
      } else {
        this.estimate_id.push(planid)
        this.Calculate_gst("0", "0");
      }

    } else {
      this.estimate_id.push(planid);
      this.Calculate_gst("0", "0");
    }

    for (var i = 0; i < this.billden_plan.length; i++) {
      for (var j = 0; j < this.estimate_id.length; j++) {
        if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id) {
          this.billden_plan[i].checked = true;
        }
      }
    }
  }

  //Bill save
  Bill_save() {
    var flag = true;
    var paytype;
    var instype;
    var patientname = this.appoint_txt.split("-");

    if (this.billaddlist.length == 0 && this.estimate_id.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    } else {
      for (var i = 0; i < this.billaddlist.length; i++) {
        paytype = this.billaddlist[i].paytype;
        instype = this.billaddlist[i].insurer;
      }
    }
    paytype = this.pay_type;

    if (flag == true) {
      this.show_save_btn = false;
      if (this.billing_print_array.length != 0) {
        for (var i = 0; i < this.billing_print_array.length; i++) {
          
            this.doc_client_reg_id = this.billing_print_array[i].client_reg_id;
            this.doc_relation_id = this.billing_print_array[i].relation_id;
            if (this.billing_print_array[i].sub_rel_id != undefined) {
              this.doc_sub_rel_id = this.billing_print_array[i].sub_rel_id;
            }
         
        }
      }

      var bill_time;
      if (this.bill_time1 != undefined && this.bill_time2 != undefined) {
        bill_time = ConvertTimeformat("", this.bill_time1 + ":" + this.bill_time2 + " " + this.bill_time3);

      } 

      this.addvancetxt = this.Advance_bill != undefined ? this.Advance_bill : "0";
      this.totalcolletxt = this.total_collected != undefined ? this.total_collected : "0";

      if (this.inGST == true && this.gst_retrive_list.length != 0) {
        this.cent_gst = this.gst_retrive_list[0].tax;
        this.sta_gst = this.gst_retrive_list[1].tax;
      }
      if (this.estimate_id.length != 0) {
        var estimateid = this.estimate_id
      }
      var finaleBillAmount = (parseFloat(this.final_amount) - (parseFloat(this.medicinBal) + parseFloat(this.diagnosisAmount))).toFixed(2);
      Doc_Helper.setfinalamountbillcreate(finaleBillAmount);
      var doctp_id = Helper_Class.getInfo().user_id;
      var finalDiagArray:any = []
      var results;
      if (this.digDetailsArray.length != 0) {
        for (i = 0; i < this.digDetailsArray.length; i++) {
          if(this.digDetailsArray[i].bill_id != undefined  ){
            if (finalDiagArray.length != 0) {
              results = this.array_exits(finalDiagArray, this.digDetailsArray[i].bill_id);
              if (results != true) {
                finalDiagArray.push({
                  bill_id: this.digDetailsArray[i].bill_id,
                  paid_amount: parseFloat(this.digDetailsArray[i].bill_amount).toFixed(2),
                  center_id: this.digDetailsArray[i].center_id
                })
              }
            } else {
              finalDiagArray.push({
                bill_id: this.digDetailsArray[i].bill_id,
                paid_amount: parseFloat(this.digDetailsArray[i].bill_amount).toFixed(2),
                center_id: this.digDetailsArray[i].center_id
              })
            }
          }
        }
      }
      var check1 = finalDiagArray;
      var finalMedArray:any = []
      var result2;
      if (this.medcalDetailsArray.length != 0) {
        for (i = 0; i < this.medcalDetailsArray.length; i++) {
           if(this.medcalDetailsArray[i].bill_id != undefined){
            if (finalMedArray.length != 0) {
              result2 = this.array_exits(finalMedArray, this.medcalDetailsArray[i].bill_id);
              if (result2 != true && this.medcalDetailsArray[i].bill_id != undefined) {
                finalMedArray.push({
                  bill_id: this.medcalDetailsArray[i].bill_id,
                  paid_amount: parseFloat(this.medcalDetailsArray[i].bill_amount).toFixed(2),
                  center_id: this.medcalDetailsArray[i].center_id
                })
              }
            } else {
              finalMedArray.push({
                bill_id: this.medcalDetailsArray[i].bill_id,
                paid_amount: parseFloat(this.medcalDetailsArray[i].bill_amount).toFixed(2),
                center_id: this.medcalDetailsArray[i].center_id
              })
            }
           } 
        }
      }
      var check = finalMedArray;
      var billId = Helper_Class.getInpatientBillEdit().flow == "inpatien-edit" ? Helper_Class.getInpatientBillEdit().bill_id : undefined;
      var updater_type = Helper_Class.getInpatientBillEdit().flow == "inpatien-edit" ? Helper_Class.getInpatientBillEdit().updater_type : undefined;
      var doctor_reg_o;
      var advance;
      var totalAdvance;
      if( parseFloat(finaleBillAmount) == (parseFloat(this.paid_bill) + parseFloat(this.addvancetxt))){
        advance = "0.00";
        totalAdvance = parseFloat(this.addvancetxt).toFixed(2);
      }else if( parseFloat(finaleBillAmount) < parseFloat(this.addvancetxt) ){
        advance = ((parseFloat(this.Advance_bill) - parseFloat(finaleBillAmount))).toFixed(2);
        totalAdvance = (parseFloat(this.addvancetxt) - parseFloat(advance)).toFixed(2);
      }else{
        advance = "0.00";
        totalAdvance = (parseFloat(this.Advance_bill)).toFixed(2);
      }
      var balance = parseInt(this.remaing_billbal) < 0 ? "0.00" : this.remaing_billbal.toString();
      
      if (this.doc_sub_rel_id != null && this.doc_sub_rel_id != undefined && this.doc_sub_rel_id != "" && this.doc_sub_rel_id.length != 0) {
        this.save_billcreate = JSON.stringify({
          appointment_id: parseFloat(patientname).toString(),
          app_type: "doctor",
          hptl_clinic_id: this.hptl_clinic_id.toString(),
          doc_reg_id: this.dog_reg_id.toString(),
          bill_amount: encrypt_decript.Encript(Doc_Helper.getfinalamountbillcreate()).toString(),
          created_by: this.user_id.toString(),
          bills: this.billaddlist,
          country: ipaddress.country_code.toString(),
          estimates: estimateid,
          client_reg_id: this.doc_client_reg_id.toString(),
          relation_id: this.doc_relation_id.toString(),
          sub_rel_id: this.doc_sub_rel_id,
          advance_balance: advance,
          advance: totalAdvance,
          balance: balance,
          round_off: this.round_off.toString(),
          cgst: this.cent_gst,
          sgst: this.sta_gst,
          prev_bal: this.balance_data,
          prev_bill_id: this.prev_bill_id,
          pay_type: paytype.toString(),
          insurer: instype,
          userid: doctp_id,
          paid_amount: this.paid_bill,
          bill_id: billId,
          updater_type : updater_type,
          bill_date:this.bill_date,
          bill_time:bill_time,
        })
      }
      else {
        this.save_billcreate = JSON.stringify({
          appointment_id: parseFloat(patientname).toString(),
          app_type: "doctor",
          hptl_clinic_id: this.hptl_clinic_id,
          doc_reg_id: this.dog_reg_id,
          bill_amount: encrypt_decript.Encript(Doc_Helper.getfinalamountbillcreate()).toString(),
          created_by: this.user_id,
          bills: this.billaddlist,
          country: ipaddress.country_code,
          estimates: estimateid,
          client_reg_id: this.doc_client_reg_id,
          relation_id: this.doc_relation_id,
          // sub_rel_id: this.doc_sub_rel_id,
          advance_balance: advance,
          advance: totalAdvance,
          balance: balance,
          round_off: this.round_off.toString(),
          cgst: this.cent_gst,
          sgst: this.sta_gst,
          prev_bal: this.balance_data,
          prev_bill_id: this.prev_bill_id,
          pay_type: paytype,
          insurer: instype,
          userid: doctp_id,
          paid_amount: this.paid_bill,
          bill_id: billId,
          updater_type : updater_type,
          bill_date:this.bill_date,
          bill_time:bill_time,
        })
      }
      
      Doc_Helper.setbillid_create(this.billaddlist)

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + this.saveBillURL, this.save_billcreate,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj["result"] != null) {
              if (obj["key"] == "1") {
                this.show_save_btn = false;
                this.toastr.success(Message_data.billgen);
                if (Helper_Class.getInpatientBillCreate() == "front-desk") {
                  this.messageservice.sendMessage("frontdeskbilling");
                } else {
                  Doc_Helper.setIpCreateBill("ipbill");
                  this.doctorservice.sendMessage("billing");
                }
              } else {
                this.show_save_btn = true;
                this.toastr.error(Message_data.getBillUngen());
              }
            }
          },
          error => {
            this.show_save_btn = true;
            // this.toastr.error(Message_data.getNetworkMessage());
          }
        )
    }else{
      this.show_save_btn = true;
    }
  }

  Get_current_date() {
    var obj = Master_Helper.getMasterCurrentDate();

    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      this.currentTime = obj.current_time;
      var timevalue =Time_Formate(this.currentTime)
      var tval = timevalue.split(" ")
      var tval1= tval[0].split(":");
      this.bill_time1=tval1[0];
      this.bill_time2=tval1[1];
      this.bill_time3 = tval[1];
      var get_date = obj.current_date.split('-');
      this.Appoint_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
      this.currentDate = obj.current_date;
      this.bill_date = obj.current_date;
      this.Appoint_Date = this.CurrentDatetime;
      Doc_Helper.setBillFromDate(null);
      Doc_Helper.setBillToDate(null);
      Doc_Helper.setBillFromDate(obj.current_date);
      Doc_Helper.setBillToDate(obj.current_date);
    }
  }

  billDateChange(date) {
    if(date > this.currentDate){
      this.toastr.error("Select current or past date");
      this.bill_date= undefined;
      
    } else {
      this.bill_date = date;
    }
  }

  checkUncheckAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].patient_id);
    }
    //this.checkedList = this.checkedList;
    if (this.checklist.length == this.checkedList.length) {
      //this.checkedList = `All rows are selected`;
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkedList.length != 0) {
      //this.checkedList = `selected Id: ${this.checkedList}`;
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  print_area() {
    var flag = true;
    if (this.billaddlist.length == 0 && this.estimate_id.length == 0) {
      this.toastr.error(Message_data.getAddBill());
    } else {
      if(this.inpatientEdit != true){ // prevent save while print from consolidated edit bill
        this.Bill_save();
      }
      let printContents, popupWin;
      printContents = document.getElementById('bill-print-section')!.innerHTML;
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        * {
        font-size: 1em !important;
        line-height: 1 !important;
      }
      @media print {
        .printsame {
          clear: both;
          page-break-inside: avoid;
        }
      }
        table.report-container {
          page-break-after:always;
          }
          thead.report-header {
              display:table-header-group;
          }
          tfoot.report-footer {
              display:table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          } 
          .alignRight{
            text-align: right;
        }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10{width:83%; float:left;}
          .col-print-11{width:92%; float:left;}
          .col-print-12{width:100%; float:left;}
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
        </html>`
      );
      popupWin.document.close();
    }

  }

  array_exits(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].bill_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }
  
  addPaymentDetails(){
    this.tamt+=this.amountval;

    if(this.tamt > parseInt(this.final_amount)){
      this.toastr.error(Message_data.getGreaterAmount());
      this.tamt -=this.amountval;
    } else {
      for(var i=0;i<this.paytypelist.length;i++){
        if(this.payType1 == this.paytypelist[i].pay_id){
          this.paymentsarray.push({
            pay_id:this.paytypelist[i].pay_id,
            pay_desc:this.paytypelist[i].pay_desc,
            amount:this.amountval.toFixed(2)
          })
          this.amountval=0;
        }
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=parseInt(data.amount);
    
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.tamt -=this.paymentsarray[i].amount;
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.tamt -=this.paymentsarray[i].amount;
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
