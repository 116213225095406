import { Component, OnInit , HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-pediatrics-personal',
  templateUrl: './pediatrics-personal.component.html',
  styleUrls: ['./pediatrics-personal.component.css']
})
export class PediatricsPersonalComponent implements OnInit {

  public pediaPersonalFlag ="min";
  public pediamin:boolean=true;
  subscription:Subscription;
  
  public childhabit_array = [];
  public main_concern_flag:boolean=false;
  public child_hidden_flag:boolean=false;
  public concer_array=[];
  public habit_in_flag:boolean=false;
  public dietfollowadd = [];
  public dietavoidwadd = [];
  public name;
  public age;
  public birth_arry = [];
  public Main_Vaccine_Data = [];
  public Vaccine_Array = [];
  public Developement_Data;
  public Devel_Cog_1_2_month_Data = [];
  public Devel_Cog_3_month_Data = [];
  public Devel_Cog_4_5_month_Data = [];
  public Devel_Cog_6_7_month_Data = [];
  public Devel_Cog_8_9_month_Data = [];
  public Devel_Cog_10_12_month_Data = [];
  public Devel_Cog_15_month_Data = [];
  public Devel_Cog_18_month_Data = [];
  public Devel_Cog_24_month_Data = [];
  public Devel_Cog_3_year_Data = [];
  public Devel_Cog_4_year_Data = [];
  public Devel_Cog_5_year_Data = [];

  public Devel_Lang_1_2_month_Data = [];
  public Devel_Lang_3_month_Data = [];
  public Devel_Lang_4_5_month_Data = [];
  public Devel_Lang_6_7_month_Data = [];
  public Devel_Lang_8_9_month_Data = [];
  public Devel_Lang_10_12_month_Data = [];
  public Devel_Lang_15_month_Data = [];
  public Devel_Lang_18_month_Data = [];
  public Devel_Lang_24_month_Data = [];
  public Devel_Lang_3_year_Data = [];
  public Devel_Lang_4_year_Data = [];
  public Devel_Lang_5_year_Data = [];
  public three_playing;
  public threeyearplaying_remarks;
  public three_sleep;
  public threeyear_sleep_remarks;
  public threeyearmoral_remarks;
  public threeyearphyscho_remarks;
  public three_moral;
  public three_physco;
  public three_dental;
  public threeyearDention_remarks;
  public twoyear_sleep;
  public two_yearsleepremarks;
  public twoyear_play;
  public two_yearplayremarks;
  public twoyear_toilet;
  public twoyeartoilet_remarks;
  public twoyearphysco_remarks;
  public twoyear_physcio;
  public twoyear_moral;
  public twoyearmoral_remarks;
  public oneyear_sleepremarks;
  public twoyearDention_remarks;
  public twoyear_dent;
  public sleep_pattren;
  public cond_txt;
  public dental_caries;
  public oneyearobject_remarks;
  public object_perfom;
  public oneyeardental_cariesremarks;
  public oneyearDecide_teethremarks;
  public header_footer_flag: boolean;
  public main_infant = [];
  public Main_Concern_Data = [];
  public send_concern_array = [];
  public Child_habit_arry = [];
  public send_habit_array = [];
  public Age_txt;
  public gender_txt;
  public name_txt;
  public complication_txt;
  public Living_condition;
  public economic_situation;
  public religious_affiliation;
  public ethnic_based;
  public pedia_case_hist_id;
  public concern_txt;
  public child_habits;
  public life_style_id;
  public habit_falg;
  public CurrentDatetime: string;

  public Age_Based;
  public name_hidden: boolean;
  public age_hidden: boolean;
  public maincon_hidden: boolean;
  public child_hidden: boolean;
  public complicate_hidden: boolean;
  public living_hidden: boolean
  public economic_hidden: boolean
  public religious_affhidden: boolean;
  public ethnic_basedhidden: boolean;
  public social_head: boolean;
  public loader: boolean;
  public Mothers_age;
  public Gest_age;
  public Delivery_type;
  public noof_preg;
  public Allergy_txt;
  public surgical_hist;
  public Feed_history;
  public send_medical_array = [];
  public Medical_hist;
  public send_maternal_array = [];
  public Matenal_illness;
  public head;
  public hearing;
  public vision;
  public nose;
  public mouth;
  public circumference;
  public chest;
  public abdominal;
  public genitalia;
  public extremities;
  public neurologic;
  public skin;
  public gen_appearnce;
  public threeyeartoilet_remarks;
  public three_toilet;
  public cog_mianarry = [];
  public language_mainarry = [];
  public remark_txt;
  public pedia_personalhead: boolean;
  public prognosis_array = [];
  public agefield:boolean;
  public clnt_dob:any;

  public patient_list;
  public userinfo;
  public user_id;
  public client:boolean;

  constructor(public dialog:MatDialog,public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,
    private router:Router,public toastr:ToastrService,public masterCSdata:MasterCSHelperService) {
   }

  async ngOnInit(){
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.pediaPersonalFlag = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.patient_list = Helper_Class.getInfo();
      this.client = true;
    }else{
      this.patient_list = Doc_Helper.getClient_Info();
      this.client = false;
    }

    this.userinfo = Helper_Class.getInfo();
    this.getCurrentDate();
    this.patient_list = Doc_Helper.getClient_Info();

    if(Helper_Class.getInpatientFlow() == "nurse"){
      this.user_id = this.patient_list.user_id;
    }else{
      this.user_id = this.userinfo.user_id;
    }

    if (this.patient_list.Age_data != undefined && this.patient_list.Age_data != "undefined") {
      this.Age_txt = this.patient_list.Age_data;
      this.agefield=true;
    }else {
      this.Age_txt = "";
      this.agefield=false;
    }

    this.name_txt = this.patient_list.client_name;
    this.gender_txt = this.patient_list.Gender_data;

    this.name_hidden = true;
    this.age_hidden = true;
    this.maincon_hidden = true;
    this.child_hidden = true;
    this.complicate_hidden = true;
    this.living_hidden = true;
    this.economic_hidden = true;
    this.religious_affhidden = true;
    this.ethnic_basedhidden = true;
    this.pedia_personalhead = true;
    this.social_head = true;
    var getHopitalfieldsRet=Helper_Class.getHopitalfieldsRet();
    for (var i = 0; i < getHopitalfieldsRet.length; i++) {
      if (getHopitalfieldsRet[i].page_name == "Personal") {
        if (getHopitalfieldsRet[i].fields != undefined) {
          for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
            if (getHopitalfieldsRet[i].fields[j].field_name == "Name") {
              this.name_hidden = false;
            }

            if (getHopitalfieldsRet[i].fields[j].field_name == "Age") {
              this.age_hidden = false;
            }

            if (getHopitalfieldsRet[i].fields[j].field_name == "Main concenrns") {
              this.maincon_hidden = false;
            }

            if (getHopitalfieldsRet[i].fields[j].field_name == "Child habits") {
              this.child_hidden = false;
            }

            if (getHopitalfieldsRet[i].fields[j].field_name == "Complications") {
              this.complicate_hidden = false;
            }

            if (this.name_hidden == false || this.age_hidden == false || this.maincon_hidden == false || this.child_hidden == false || this.complicate_hidden == false) {
              this.pedia_personalhead = false;
            }

            if (getHopitalfieldsRet[i].fields[j].field_name == "Living conditions") {
              this.living_hidden = false;
            }

            if (getHopitalfieldsRet[i].fields[j].field_name == "Economic situations") {
              this.economic_hidden = false;
            }

            if (getHopitalfieldsRet[i].fields[j].field_name == "Religious affiliations") {
              this.religious_affhidden = false;
            }

            if (getHopitalfieldsRet[i].fields[j].field_name == "Ethnicity based disease") {
              this.ethnic_basedhidden = false;
            }

            if (this.living_hidden == false || this.economic_hidden == false || this.religious_affhidden == false || this.ethnic_basedhidden == false) {
              this.social_head = false;
            }
          }
        }
      }
    }

    if(masterCSData_Helper.getMasterPediaConcerns() == undefined){
      await this.masterCSdata.getPediaConcerns();
      this.Get_Concerns();
    }else{
      this.Get_Concerns();
    }
  }

  getCurrentDate(){
    var get_date;
    var obj = Master_Helper.getMasterCurrentDate();
    
    if (obj.current_date != null) {
        this.CurrentDatetime = obj.current_date;
    }
  }

  age_calculation() {//Get age calculation
    var Client_Age = this.patient_list.Case_Clnt_dob;

    if (Client_Age != undefined) {
      var age = this.Get_Age_Final(Client_Age, this.CurrentDatetime)
      this.Age_txt = age;
    }
  }

  Get_Age_Final(Client_DOB, Current_Date) {
    var DOBsplit = Client_DOB.split('-');
    var Datesplit = Current_Date.split('-');

    var yearNow = Datesplit[0];
    var monthNow = Datesplit[1];
    var dateNow = Datesplit[2];

    var yearDob = DOBsplit[0];
    var monthDob = DOBsplit[1];
    var dateDob = DOBsplit[2];

    var yearAge;
    yearAge = yearNow - yearDob;

    if (monthNow >= monthDob) {
      var monthAge = monthNow - monthDob;
    } else {
      yearAge--;
      var monthAge = (12 + parseInt(monthNow)) - parseInt(monthDob);
    }

    if (dateNow >= dateDob) {
      var dayAge = dateNow - dateDob;
    } else {
      monthAge--;
      var dayAge = 31 + parseInt(dateNow) - parseInt(dateDob);
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    
    if(yearAge.length== undefined){
      var ageinyear ="00";
    } else if(yearAge.length==1){
      var ageinyear ="0"+yearAge;
    } else {
      ageinyear = yearAge;
    }
    
    if(monthAge.toString().length == undefined){
      var ageinmonth= "00";
    } else if(monthAge.toString().length ==1){
      var ageinmonth= "0"+monthAge;
    } else {
      ageinmonth = monthAge.toString();
    }
    if(dayAge.toString().length ==1){
      var ageindays ="00"+dayAge;
    } else  if(dayAge.toString().length ==2){
      var ageindays ="0"+dayAge;
    } else {
      ageindays =dayAge.toString();
    }
    if(ageinmonth =="NaN"){
      ageinmonth="00";
    }
    if(ageindays =="NaN"){
      ageindays ="00";
    }
    var ageString =ageinyear + "-" + ageinmonth+"-"+ageindays;
    return ageString;
  }

  //Send data for save
  send_data() {
    var Pedia_personal = null, case_hist_id = null;
    case_hist_id = this.pedia_case_hist_id != null ? this.pedia_case_hist_id : undefined;
    var sub_rel = this.patient_list.sub_id != undefined && this.patient_list.sub_id != null && this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0 ? this.patient_list.sub_id : undefined;

    if (this.send_habit_array != undefined && this.send_habit_array != null) {
      this.habit_falg = "yes"
    } else {
      this.habit_falg = "no"
      this.send_habit_array = undefined;
    }

    var lif_stl_id = undefined;
    if (this.life_style_id != null) {
      lif_stl_id = this.life_style_id;
    }
    
    this.name = this.name_txt != undefined ? this.name_txt : "";
    this.age = this.Age_txt != undefined ? this.Age_txt : "";

    if (this.patient_list.sub_id != ""  && this.patient_list.sub_id.length != 0) {
      var sub_rel = this.patient_list.sub_id;
      if(this.send_habit_array != undefined && this.send_habit_array != null){
        Pedia_personal = {
          case_hist_id: case_hist_id,
          life_style_id: lif_stl_id,
          app_id: this.patient_list.app_id,
          doc_reg_id: this.user_id,
          client_reg_id: this.patient_list.Client_id,
          relation_id: this.patient_list.rel_id,
          sub_rel_id: sub_rel,
          name: this.name,
          country: ipaddress.country_code,
          age: this.Age_txt,
          complications: this.complication_txt,
          mainconcerns: this.concern_txt,
          habits: this.send_habit_array,
          living_conditions: this.Living_condition,
          economic_situation: this.economic_situation,
          religious_affiliation: this.religious_affiliation,
          ethnic_based_dis: this.ethnic_based,
          habits_flag: this.habit_falg
        }
      }else{
        Pedia_personal = {
          case_hist_id: case_hist_id,
          life_style_id: lif_stl_id,
          app_id: this.patient_list.app_id,
          doc_reg_id: this.user_id,
          client_reg_id: this.patient_list.Client_id,
          relation_id: this.patient_list.rel_id,
          sub_rel_id: sub_rel,
          name: this.name,
          country: ipaddress.country_code,
          age: this.Age_txt,
          complications: this.complication_txt,
          mainconcerns: this.concern_txt,
          habits: this.send_habit_array,
          living_conditions: this.Living_condition,
          economic_situation: this.economic_situation,
          religious_affiliation: this.religious_affiliation,
          ethnic_based_dis: this.ethnic_based,
          //habits_flag: this.habit_falg
        }
      }
    } else {
        if(this.send_habit_array.length != 0){
          Pedia_personal = {
            case_hist_id: case_hist_id,
            life_style_id: lif_stl_id,
            app_id: this.patient_list.app_id,
            doc_reg_id: this.user_id,
            client_reg_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            name: this.name,
            country: ipaddress.country_code,
            age: this.Age_txt,
            complications: this.complication_txt,
            mainconcerns: this.concern_txt,
            habits: this.send_habit_array,
            living_conditions: this.Living_condition,
            economic_situation: this.economic_situation,
            religious_affiliation: this.religious_affiliation,
            ethnic_based_dis: this.ethnic_based,
            habits_flag: this.habit_falg
          }
        }else{      
          Pedia_personal = {
            case_hist_id: case_hist_id,
            life_style_id: lif_stl_id,
            app_id: this.patient_list.app_id,
            doc_reg_id: this.user_id,
            client_reg_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            name: this.name,
            country: ipaddress.country_code,
            age: this.Age_txt,
            complications: this.complication_txt,
            mainconcerns: this.concern_txt,
            habits: this.send_habit_array,
            living_conditions: this.Living_condition,
            economic_situation: this.economic_situation,
            religious_affiliation: this.religious_affiliation,
            ethnic_based_dis: this.ethnic_based,
            //habits_flag: this.habit_falg
          }
        }
    }
    console.log("CASE SHEET PERSONAL "+JSON.stringify(Pedia_personal))
    Helper_Class.setPediaHist(Pedia_personal);
    Helper_Class.setClntAge(this.age);
    Helper_Class.setClntGender(this.gender_txt);
  }

  async Get_Concerns() {//Get concerns data
    this.Main_Concern_Data = [];
    var obj = masterCSData_Helper.getMasterPediaConcerns();
    if (obj.concerns != null) {
      for (var i = 0; i < obj.concerns.length; i++) {
        this.Main_Concern_Data.push({
          type: 'checkbox',
          value: obj.concerns[i].ped_concerns_id,
          label: obj.concerns[i].description,
          checked: false
        });
      }
    }
    if(masterCSData_Helper.getMasterChildhabits() == undefined){
      await this.masterCSdata.getChildhabits();
      this.Get_Child_habits();   
    }else{
      this.Get_Child_habits();   
    }
  }

  concern_change(selected) {
    this.concern_txt = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_concern_array = selected;

    if (this.send_concern_array.length != 0) {
      var n = 0;

      for (var j = 0; j < this.send_concern_array.length; j++) {
        for (var i = 0; i < this.Main_Concern_Data.length; i++) {
          if (this.send_concern_array[j] == this.Main_Concern_Data[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Main_Concern_Data[i].label);
          }
        }
      }
      this.concern_txt = selected_data.toString();

    } else {
      this.send_concern_array = null;
    }
  }

  concern_pop() {
    if(this.client == false){
      this.main_concern_flag = !this.main_concern_flag;
      for (var j = 0; j < this.Main_Concern_Data.length; j++) {
        this.Main_Concern_Data[j].checked = false;
      }

      if (this.send_concern_array != undefined && this.send_concern_array.length != 0) {
        for (var i = 0; i < this.send_concern_array.length; i++) {
          for (var j = 0; j < this.Main_Concern_Data.length; j++) {
            if (this.send_concern_array[i] == this.Main_Concern_Data[j].value) {
              this.Main_Concern_Data[j].checked = true;
            }
          }
        }
      }
      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
          width: '500px',
          height: '400px',
          data:{
          title: "Concerns",
          main_array: this.Main_Concern_Data,
          selected_list:this.send_concern_array,
      }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.concern_change(result);
          }
      });
    }
  }

  //Get child habits
  Get_Child_habits() {
    this.Child_habit_arry = [];
    var obj = masterCSData_Helper.getMasterChildhabits();
    if (obj.habits != undefined) {
      for (var i = 0; i < obj.habits.length; i++) {
        this.Child_habit_arry.push({
          type: 'checkbox',
          value: obj.habits[i].ped_habit_id,
          label: obj.habits[i].description,
          checked: false
        });
      }
    }
    this.getPediatricData();
  }

  habit_change(selected) {
    this.child_habits = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_habit_array = selected;

    if (this.send_habit_array.length != 0) {
      var n = 0;

      for (var j = 0; j < this.send_habit_array.length; j++) {
        for (var i = 0; i < this.Child_habit_arry.length; i++) {
          if (this.send_habit_array[j] == this.Child_habit_arry[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Child_habit_arry[i].label);
          }
        }
      }
      this.child_habits = selected_data.toString();
    }
  }

  habit_popup() {
    if(this.client == false){
      this.habit_in_flag = !this.habit_in_flag;
      for (var j = 0; j < this.Child_habit_arry.length; j++) {
        this.Child_habit_arry[j].checked = false;
      }

      if (this.send_habit_array != undefined && this.send_habit_array.length != 0) {
        for (var i = 0; i < this.send_habit_array.length; i++) {
          for (var j = 0; j < this.Child_habit_arry.length; j++) {
            if (this.send_habit_array[i] == this.Child_habit_arry[j].value) {
              this.Child_habit_arry[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
        title: "Child habits",
        main_array: this.Child_habit_arry,
        selected_list:this.send_habit_array,
    }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.habit_change(result);
          }
      });
    }

  }

  habit_array_click(data,id,e){
    if(e == true){
      this.childhabit_array.push(id)
    }
    else{
      if(this.childhabit_array.length != 0){
        for( var k=0; k<this.childhabit_array.length; k++){
          if(id == this.childhabit_array[k])
          {
            this.childhabit_array.splice(k);
          }
        }
      }
    }
    this.habit_change(this.childhabit_array);
  }

  closehabit_popup(){
    this.habit_in_flag=!this.habit_in_flag;
  }

  //Get retrieval
  getPediatricData() {
    this.pedia_case_hist_id = null;
    var obj = Helper_Class.getpediaRet();

    if (obj.case_hist_id != null) {
        if (obj.case_hist_id != null) {
          this.pedia_case_hist_id = obj.case_hist_id;
        }
        if(obj.age != undefined && obj.age !="undefined"){
          this.Age_txt = obj.age;
        } else {
          if(this.patient_list.Age_data != undefined && this.patient_list.Age_data != "undefined"){
            this.Age_txt = this.patient_list.Age_data;
            this.agefield=true;
          } else {
            this.Age_txt = "";
            this.agefield=false;
          }
        }
        if (obj.life_style_id != null) {
          this.life_style_id = obj.life_style_id;
        }
        this.concern_txt = obj.main_concerns;
        // if (obj.concerns != null) {
        //   for (var j = 0; j < obj.concerns.length; j++) {
        //       this.send_concern_array.push(obj.concerns[j].ped_concerns_id);
        //   }
        //   this.concer_array = this.send_concern_array;
        //   this.concern_change(this.send_concern_array)
        // }

        var getpediaRet=Helper_Class.getpediaRet();
        if (getpediaRet.habits != null) {
          for (var j = 0; j < getpediaRet.habits.length; j++) {
            this.send_habit_array.push(getpediaRet.habits[j].ped_habit_id);
            this.childhabit_array = this.send_habit_array;
          }
          this.habit_change(this.send_habit_array)
        }


        if (obj.complications != null) {
          this.complication_txt = obj.complications;
        }

        if (obj.living_conditions != null) {
          this.Living_condition = obj.living_conditions;
        }

        if (obj.economic_situation != null) {
          this.economic_situation = obj.economic_situation;
        }

        if (obj.religious_affiliation != null) {
          this.religious_affiliation = obj.religious_affiliation;
        }

        if (obj.ethnic_based_dis != null) {
          this.ethnic_based = obj.ethnic_based_dis;
        }
      }
  }

  
  concern_array_click(data,id,e){
    if(e == true){
      this.concer_array.push(id)
    }
    else{
      if(this.concer_array.length != 0){
        for( var k=0; k<this.concer_array.length; k++){
          if(id == this.concer_array[k])
          {
            this.concer_array.splice(k);
          }
        }
      }
    }
    this.concern_change(this.concer_array)
  }

  closeconcern_popup(){
    this.main_concern_flag = !this.main_concern_flag;
  }
}
