import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { ClientCancelAppointmentComponent } from 'src/app/client/client-cancel-appointment/client-cancel-appointment.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-nurse-doc-app-view',
  templateUrl: './nurse-doc-app-view.component.html',
  styleUrls: ['./nurse-doc-app-view.component.scss']
})
export class NurseDocAppViewComponent implements OnInit {
  public clientName: string;
  public age;
  public gender: string;
  public phoneNo: string;
  public bloodGroup: string;
  public relationType: string;
  public symtomes: string;
  public treatementType: string;
  public spl: string;
  public doctorName: string;
  public hospitalName: string;
  public appDate: string;
  public session: string;
  public docAppID: string;
  public tokenNo: string;
  public appID: any;
  public chiefCompalaint: string;
  public presentIllness: string;
  public medicalHistory: string;
  public familyHistory: string;
  public physicalExamination: string;
  public height;
  public heightMesure: string;
  public weight;
  public weightMesure: string;
  public temprature: string;
  public bmi;
  public encHeight;
  public encWeight;
  public tokenFlag: boolean
  public currentDatetime: string;
  public confirmflag: boolean;
  public loader: boolean;
  constructor(public dialog: MatDialog, public http: Http, public toastr: ToastrService, public messageService: MenuViewService) {
    this.tokenFlag = true;
    this.loader = false;
  }
  ngOnInit(): void {
    this.appID = Nurse_Helper.getDoctorAppoinmentList();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
    this.get_app();
  }
  backClicked() {
    this.messageService.sendMessage("doctorAppList");
  }
  isReadonly() { return true; }
  get_app() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp/',
      JSON.stringify({
        doc_app_id: this.appID,
        type: "doctor",
        home_care: "0",
        country: ipaddress.country_code,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj != null) {
            this.loader = true;
            if (obj.middle_name != undefined || obj.middle_name != null) {
              this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
            } else {
              this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }
            this.age = obj.age;
            this.gender = encrypt_decript.Decript(obj.gender);
            this.phoneNo = encrypt_decript.Decript(obj.mobile);
            this.bloodGroup = obj.blood_desc;
            this.relationType = obj.relation;
            this.symtomes = "";
            this.treatementType = obj.medicare_name;
            this.spl = obj.specialization;
            if (obj.dr_middle_name != undefined || obj.dr_middle_name != null) {
              this.doctorName = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            } else {
              this.doctorName = obj.dr_first_name + " " + obj.dr_last_name;
            }
            this.hospitalName = obj.hptl_name;
            this.appDate = Date_Formate(obj.date);
            this.session = encrypt_decript.Decript(obj.session);
            if (obj.token_count != undefined && obj.token_count == "true") {
              this.tokenFlag = false;
              this.tokenNo = obj.token;
            }
            if (obj.height != undefined) {
              this.height = obj.height;
              this.heightMesure = obj.height_measure;
            }
            if (obj.weight != undefined) {
              this.weight = obj.weight;
              this.weightMesure = obj.weight_measure;
            }
            if (obj.temparature != undefined) {
              this.temprature = obj.temparature;
            }
            if (obj.bmi != undefined) {
              this.bmi = obj.bmi;
            }
            if (obj.complaints != undefined) {
              this.chiefCompalaint = obj.complaints;
            }
            if (obj.present_illness != undefined) {
              this.presentIllness = obj.present_illness;
            }
            if (obj.medical_history != undefined) {
              this.medicalHistory = obj.medical_history;
            }
            if (obj.family_history != undefined) {
              this.familyHistory = obj.family_history;
            }
            if (obj.physical_exam != undefined) {
              this.physicalExamination = obj.physical_exam;
            }
          }
        });
  }
  cancel_click() {
    const dialogRef = this.dialog.open(ClientCancelAppointmentComponent, {
      width: '21%',
      height: '180px',
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data.toString().trim() == "" && data.length == 0) {
        this.toastr.error(Message_data.appcancelreason);
      } else if (data.length > 150) {
        this.toastr.error(Message_data.maxlen150);
      } else {
        var cancel_list = [];
        cancel_list.push(
          this.appID
        );
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
          JSON.stringify({
            doc_cancel: cancel_list,
            home_care: "0",
            reason: data
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.key != null) {
                if (obj.key == "1") {
                  this.toastr.success(Message_data.appcancel);
                  this.messageService.sendMessage("doctorAppList");
                }
              }
            },
            error => {
              this.toastr.error(Message_data.getNetworkMessage());
            }
          )
      }
    });
  }
  appointment_update() {
    var appflag = true;
    var error = "Please fill ";
    if (this.height == undefined || this.height == "") {
      error = error + "Height, ";
      appflag = false;
    }
    if (this.heightMesure == undefined || this.heightMesure == "") {
      error = error + "Height measure,";
      appflag = false;
    }
    if (this.weight == undefined || this.weight == "") {
      error = error + "Weight,";
      appflag = false;
    }
    if (this.weightMesure == undefined || this.weightMesure == "") {
      error = error + "Weight measure,";
      appflag = false;
    }
    if (this.temprature == undefined || this.temprature == "") {
      error = error + "Temparature,";
      appflag = false;
    }
    if (appflag == false) {
      this.toastr.error(error);
    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/updapp',
        JSON.stringify({
          height: this.height,
          height_measure: this.heightMesure,
          weight: this.weight,
          weight_measure: this.weightMesure,
          temparature: this.temprature,
          bmi: this.bmi,
          complaints: this.chiefCompalaint,
          present_illness: this.presentIllness,
          medical_history: this.medicalHistory,
          family_history: this.familyHistory,
          physical_exam: this.physicalExamination,
          doc_appointment_id: this.appID
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.apptUpdSuccess);
                this.messageService.sendMessage("doctorAppList");
              }
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )
    }
  }
  calories_data() {
    if ((this.height != undefined && this.height.length != 0)
      || (this.weight != undefined && this.weight.length != 0)
      || (this.chiefCompalaint != undefined && this.chiefCompalaint.length != 0)
      || (this.familyHistory != undefined && this.familyHistory.length != 0)
      || (this.presentIllness != undefined && this.presentIllness.length != 0)
      || (this.medicalHistory != undefined && this.medicalHistory.length != 0)
      || (this.physicalExamination != undefined && this.physicalExamination.length != 0)) {
      this.confirmflag = false;
      if (this.height != undefined && this.height.length != 0) {
        this.encHeight = encrypt_decript.Encript(this.height);
      }
      if (this.weight != undefined && this.weight.length != 0) {
        this.encWeight = encrypt_decript.Encript(this.weight);
      }
    } else {
      this.confirmflag = true;
    }
    if (this.height == undefined || this.height == "" || this.weight == undefined || this.weight == "") {
      this.bmi = "";
    } else {
      if (this.height != undefined) {
        if (this.heightMesure == "cms") {
          this.height = this.height.replace(/[^0-9]/g, '');
        } else {
          this.height = this.height.replace('/[^-0-9\.]/g', '');
          if (this.height.indexOf('.') !== -1) {
            if (this.height.split('.').length > 2) {
              this.height = this.height.height.slice(0, -1);
            }
          }
        }
        if (this.heightMesure == "cms") {
          if (parseFloat(this.height) > 300) {
            this.height = this.height.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height) > 100) {
            this.height = this.height.slice(0, -1);
          }
        }
      }
      if (this.weightMesure == "kgs" && this.heightMesure == "inch") {
        var pound = this.weight * 2.20462;
        var Height_txt = parseFloat(this.height)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
      } else if (this.weightMesure == "kgs" && this.heightMesure == "cms") {
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);
      } else if (this.weightMesure == "lbs" && this.heightMesure == "inch") {
        var Height_txt = parseFloat(this.height);
        var dat = this.weight / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
      } else if (this.weightMesure == "lbs" && this.heightMesure == "cms") {
        var Height_txt = parseFloat(this.height);
        var inch = Height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
      }
    }
  }
}
