<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" *ngIf="locationArray!= undefined && locationArray.length>1" style="margin-left: 9px;margin-top: 13px;">
        <mat-label class="matlabel">Diagnosis Centre
          <mat-select class="ipcss" [(ngModel)]="info">
            <mat-option value="{{loc.diag_centre_name+'-'+loc.diag_loc}}"
              *ngFor="let loc of locationArray; let i = index" (click)="changeLocation(loc)">
              {{loc.diag_centre_name+"-"+loc.diag_loc}}
            </mat-option>
          </mat-select>
        </mat-label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Completed</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12">
            <p class="nodata" [hidden]='updateDataArray.length!=0'>No Appointment(s) Found</p>
            <table *ngIf="updateDataArray.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th style="width: 20%;">Name</th>
                  <th style="width: 10%;">Test Type</th>
                  <th style="width: 30%;"> Test Name </th>
                  <th style="width: 20%;"> Approved date </th>
                  <th style="width: 20%;"> Status </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let app of updateDataArray"
                  (click)="viewReportDetails(app.test_type,app.diag_app_id,app.pres_test_id,app.pres_diag_id,app.test_name)">
                  <td style="text-align: left;">{{ app.first_name }} {{app.last_name}}</td>
                  <td style="text-align: left;"> {{app.test_type}}</td>
                  <td style="text-align: left;"> {{app.test_name}}</td>
                  <td style="text-align: center;"> {{app.approvedDate}}</td>
                  <td style="font-size: 12px;">
                    <div style="position: relative;top: 9px;">
                      <img *ngIf="app.status == 'Completed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/Completed_icon.svg" />
                      <img *ngIf="app.status == 'Awaiting For Approval'" width="30px" height="auto"
                        src="../../../assets/ui_icons/awaiting_approval.svg" />
                      <p>{{app.status}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>