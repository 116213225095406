<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header" >
                      <h5 class="m-0" class="mainHeadingStyle">Diabetics readings</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="saveFlag"
                        class="saveimgbtn_inpatinfo " (click)="save_readings()">
                    </div>
                  </div>
               
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Search
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Search by<br>
                                            <select disableOptionCentering class="ipcss " [(ngModel)]="Filter"
                                                (change)="filterByPatients()">
                                                <option value="First name">First name</option>
                                                <option value="Mobile">Mobile</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel" [hidden]="firstNameFlag">First name
                                            <input class="ipcss " type="text" [(ngModel)]="searchValue"
                                                (blur)="upper()" placeholder={{somePlaceholder}} maxlength="50"
                                                matInput />
                                        </mat-label>
                                        <mat-label class="matlabel" [hidden]="!firstNameFlag">Mobile
                                            <input class="ipcss " [(ngModel)]="searchValue"
                                                placeholder={{somePlaceholder}} maxlength="10" matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 my-auto d-flex justify-content-center">
                        <img src="../../../assets/ui_icons/arrow_green.svg" width="15px" height="auto"
                            (click)="getReadings()">
                    </div>
                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                        <div class="cover_div" style="min-height: 87px;">
                            <div class="header_lable">
                                Search details
                            </div>
                            <div class="content_cover">
                                <div class="row" *ngIf="clientViewFlag">
                                    <div class="col-8">
                                        <mat-label class="matlabel" *ngIf="clientViewFlag"> <br>
                                            <select class="ipcss" [(ngModel)]="clientId"
                                                (ngModelChange)="changeId(clientId)">
                                                <option [ngValue]="Select">Select</option>
                                                <option *ngFor="let client of clientArray" [ngValue]="client">
                                                    {{client.name}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-4">
                                        <mat-label *ngIf="profileFlag">
                                            <img src="{{ogitFlag}}" class="profileimg" alt="">
                                            <br>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Blood glucose
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-4">
                                                <mat-label class="matlabel">Blood glucose</mat-label>
                                            </div>
                                            <div class="col-4">
                                                <mat-checkbox color="primary" matInput (change)="fastChn()" [(ngModel)]="fasting"
                                                labelPosition="before">Fasting</mat-checkbox>
                                            </div>
                                            <div class="col-4">
                                                <mat-checkbox color="primary" matInput [(ngModel)]="ogitFlag" (change)="ogttChn()"
                                                labelPosition="before">OGTT</mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" style="width: fit-content;">
                                        <mat-label class="matlabel">Fasting / 1/2 hour<br>
                                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="8"
                                                [(ngModel)]="halfhour" required onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">1 hour<br>
                                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="8"
                                                [required]="oneHourRequired" disabled="{{fastingDisFlag}}"
                                                [(ngModel)]="oneHour" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">1 1/2 hours<br>
                                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="8"
                                                [required]="oneHalfRequired" disabled="{{fastingHalfDisFlag}}"
                                                [(ngModel)]="oneAndHalf">
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Postprandial<br>
                                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="8"
                                                [(ngModel)]="postprandial" required placeholder="mg/dl" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">HbA1c<br>
                                            <input type="text" class="ipcss " placeholder="mg/dl" [(ngModel)]="HbA1c" maxlength="8" />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <div class="cover_div" style="padding-bottom: 33px;">
                            <div class="header_lable">
                                Urine glucose
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Glucose<br>
                                            <input type="text" class="ipcss " matInput placeholder="mg/dl" [(ngModel)]="glucose" maxlength="8"
                                                [required]="glocoseFlag" placeholder="mg/dl" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Ketones<br>
                                            <input type="text" class="ipcss " matInput placeholder="mgol/L" maxlength="8"
                                                [(ngModel)]="ketones" (change)="KetoneChange()" placeholder="mg/dl" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Protien<br>
                                            <input type="text" class="ipcss " matInputplaceholder="mg/dl" [(ngModel)]="protien" maxlength="8"
                                                (change)="KetoneChange()" placeholder="mg/dl" />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <div class="cover_div" style="padding-bottom: 33px;">
                            <div class="header_lable">
                                Cholesterol
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">HDL<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hdl" placeholder="mg/dl" maxlength="8"/>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">LDL<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="ldl" placeholder="mg/dl" maxlength="8" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Trigs <br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="trigs" maxlength="8"
                                                placeholder="mg/dl" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">VLDL<br>
                                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="3"
                                                [(ngModel)]="vldl"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>