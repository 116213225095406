import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
import { MatDialog } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { Message_data } from 'src/assets/js/Message_data';
declare var $:any;
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-billing-diag-view',
  templateUrl: './billing-diag-view.component.html',
  styleUrls: ['./billing-diag-view.component.scss']
})
export class BillingDiagViewComponent implements OnInit {
  public billingId: string;
  public diagCenterId;
  public providerType;
  public billingPrintView = [];
  
  public cgstFlag;
  public sgstFlag;
  public billingDetialedView;
  public billPrintconcse: boolean;
  public totalFlag: boolean;
  public billAdvanceFlag: boolean;
  public prevBalance: boolean;
  public prevBalanceFlag: boolean;
  public mainBalanceFlag: boolean;
  public estimatePrintData;
  public treatmentPlanFlag: boolean;
  public charge = [];
  public hospAddress: boolean;
  public sendAddress: string;
  public clientAddress: string;
  public billAmount: string;
  public totalBill: string;
  public cgst: string;
  public sgst: string;
  public billingDetailList = [];
  public clientName: string;
  public doctorName: string;
  dtOptions: DataTables.Settings = {};
  private finyear;
  
  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  public messageservice:MenuViewService,public dialog:MatDialog) { }
 
  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,] },
      ],
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    var getBillingdetails = FrontDesk_Helper.getBillsDetails();
    this.billingId = getBillingdetails.bill_view_id;
    this.providerType = getBillingdetails.provider_type;
    this.diagCenterId= getBillingdetails.provider;
    this.finyear = getBillingdetails.fin_year;
    this.getBillDetailedView();
  }

  getBillDetailedView() {
    var headers = new HttpHeaders();
    var send = {
      doc_reg_id: this.diagCenterId,
      billing_id: this.billingId,
      app_type: "diagnosis",
      fin_year:this.finyear,
    }
    
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/', send,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.bills != null) {
            if (obj.cgst != null) {
              this.cgstFlag = false;
              this.sgstFlag = false;
            }

            this.billingDetialedView = obj.bills;
            this.billPrintconcse = this.billingDetialedView != null && this.billingDetialedView[0].test_name != null ? false : true;

            if (obj.bill_amount == undefined && obj.bill_amount == "") 
              this.totalFlag = true;

            if (obj.advance == undefined || obj.advance == "") 
              this.billAdvanceFlag = true;

            if (obj.prev_balance != null) 
              this.prevBalance = obj.prev_balance;
            else 
              this.prevBalanceFlag = true;

            if (obj.balance == undefined || obj.balance == "") 
              this.mainBalanceFlag = true;

            this.estimatePrintData = obj.estimates;
            this.treatmentPlanFlag = this.estimatePrintData != "" && this.estimatePrintData != undefined ? false : true;
            this.charge = [];
            this.hospAddress = obj.diag_centre_name != null ? false : true;

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            }

            if (obj.first_name != undefined) {
              this.sendAddress = obj.address2 != null && obj.address2 != "" ? obj.address1 + ", " + obj.address2 + "," : obj.address1 + ","; 
              if (obj.caddress1 != null) {
                this.clientAddress = obj.caddress2 != null && obj.caddress2 != "" ? encrypt_decript.Decript(obj.caddress1).toString() + " " + encrypt_decript.Decript(obj.caddress2).toString() + "," : encrypt_decript.Decript(obj.caddress1).toString() + ",";
              }

              var tot_amt = "0";
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                  }
                }
              }

              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                    }
                  }
                }
              }

              this.billAmount = tot_amt;
              this.totalBill = tot_amt;
              if (obj.cgst != null) {
                this.cgst = (parseFloat(this.billAmount) * (parseFloat(obj.cgst) / 100)).toFixed(2);
                this.sgst = (parseFloat(this.billAmount) * (parseFloat(obj.sgst) / 100)).toFixed(2);
              }

              this.billAmount = encrypt_decript.Decript(obj.bill_amount);
              this.clientName = obj.middle_name != null && obj.middle_name != "" ? encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString() : encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();             
              this.doctorName = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;

              this.billingDetailList.push({
                dr_name: "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name,
                licence_code: " Reg. No: " + obj.licence_code,
                diag_centre_name: obj.diag_centre_name,
                address: this.sendAddress,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                created_date: datetxt,
                appointment_id: obj.appointment_id,
                client_name: this.clientName,
                advance: obj.advance,
                balance: obj.balance,
                caddress: this.clientAddress,
                clocation: obj.clocation + ", ",
                clientcity: obj.ccity + " - " + encrypt_decript.Decript(obj.czipcode) + ",",
                clestare: obj.cstate + " - " + obj.ccountry + ".",
                client_reg_id: obj.client_reg_id,
              });
            }
          } else {
            this.toastr.error(Message_data.network);
          }
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  back(){
    this.messageservice.sendMessage("frontdeskbilling");
  }
}
