import { Component, OnInit, Input, ViewChild, ElementRef,ChangeDetectorRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers, JSONPConnection } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat, Date_Formate, tConv24, Time_Formate } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog } from '@angular/material/dialog';
import { InpatMedprescViewComponent } from '../inpat-medpresc-view/inpat-medpresc-view.component';
import { ipaddress } from '../../ipaddress';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { GynaObestricPopSelectionComponent } from '../gyna-obestric-pop-selection/gyna-obestric-pop-selection.component';
import { InpatMedicineTrackerComponent } from '../../../app/Nurse_module/inpat-medicine-tracker/inpat-medicine-tracker.component';
import { Nurse_Helper } from '../../../app/Nurse_module/Nurse_Helper';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { userInfo } from 'os';
declare var $: any;
import { ScanDetected } from 'ngx-scanner-detection';

@Component({
  selector: 'app-inpat-patient-info',
  templateUrl: './inpat-patient-info.component.html',
  styleUrls: ['./inpat-patient-info.component.css'],
})
export class InpatPatientInfoComponent implements OnInit {
  @ViewChild('idprint') idprint: ElementRef;
  @Input() pattern: string | RegExp;
  editorStyle = {
    height: '210px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
  };
  public patient_name;
  public userinfo: any;
  public user_id: any;
  public first_name;
  public last_name;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;
  public profile_image;
  public covid_type: string;
  public header_footer_flag: Boolean;
  public isMobile: boolean;
  public fabButtonOpened: boolean;

  public treate_list_data: any = [];
  public data;
  public clnt_subrel_id;
  public clnt_sal;
  public clnt_saldesc;
  public clnt_fname;
  public clnt_mname;
  public clnt_lname;
  public clnt_age: any = '';
  public clnt_gender;
  public clnt_blood;
  public clnt_blood_id;
  public clnt_fg_name;
  public clnt_income;
  public clnt_occupation;
  public clnt_address1;
  public clnt_address2;
  public clnt_location;
  public clnt_city;
  public clnt_state;
  public clnt_country;
  public clnt_location_id;
  public clnt_city_id;
  public clnt_state_id;
  public clnt_country_id;
  public clnt_zipcode;
  public clnt_cont_number;
  public clnt_symptom;
  public clnt_dob = null;
  public ward_id;
  public bed_no;
  public send_bed_no;
  public emer_contact;
  public bp_txt;

  public treatement_type;
  public treatement_type_obj;
  public relationship_obj;
  public opiniontype_obj;
  public clnt_location_list: any = [];
  public clnt_city_list: any = [];
  public clnt_state_list: any = [];
  public clnt_country_list: any = [];
  public ward_list: any = [];
  public clnt_sal_list: any = [];

  public App_type;
  public Relationship;
  public client_data_list: any = [];
  public doctor_search_list;
  public appoint_client_data;

  public doctor_search_div: Boolean;
  public opinion_type_Div: Boolean;
  public appoint_Type_Div: Boolean;
  public search_Opinion_div: Boolean;
  public homecarevalue;

  public clnt_url;
  public user_type: boolean;
  public mobile_type: boolean;
  public new_user: boolean;
  public exist_user: boolean;
  public Client_type;
  public search_hide: boolean;

  public height_txt;
  public hmeasure: string;
  public weight_txt;
  public wmeasure: string;
  public temp_txt;
  public bmi_txt;
  public encheight;
  public encweight;
  public chief_comp;
  public pres_ill;
  public med_hist;
  public family_hist;
  public physical_exam;
  public client_reg_id;
  public Confirm_btn: boolean;

  public CurrentDatetime;
  public get_date;
  public tokenumber;
  public spl_id;
  public spl_array: any = [];
  public splvalue;
  public spl;
  public app_id;
  public admission_id;
  public hospital_id;
  public life_style_id;
  public get_locname_url;
  public update_btn: boolean;

  public refer_txt;
  public Refered_doctor: any = [];
  public Refered_name: any = [];
  public submenu_flag: boolean = true;
  public submenu_flag1: boolean = false;

  public dob_flag: boolean;
  public currentYear;
  public currentTime;
  public currentDate;
  public pastadmdate;
  public covid_flag: boolean;
  public readonlyFlag: boolean;

  public observList: any = [];
  public covidSympList: any = [];
  public comorbList: any = [];
  public finalcovidsymp: any = [];
  public finalcomorbList: any = [];
  public BP_01: string;
  public BP_02: string;
  public spo_txt: string;
  public pulse_txt: string;
  public resp_txt: string;
  public cvs_txt: string;
  public rstxt: string;
  public cns_txt: string;
  public abd_txt: string;
  public covid_duration: string;

  public observetable: boolean;
  public scan_01: string;
  public scan_02: string;
  public rtpcr_result: string;
  public bmr_txt: string;
  public blood_disabled: boolean;
  public rs_hidden: boolean;
  public cns_hidden: boolean;
  public abd_hidden: boolean;
  public cvs_hidden: boolean;

  public h_location;
  public h_address1;
  public h_address2;
  public h_city;
  public h_country;
  public h_state;
  public h_zipcode;

  public blood_preshidden: boolean;

  public symptom_data: any = [];
  public symptomlist: any = [];
  public symptoms_list: any = [];
  public selected_symptoms: any = [];

  public newsymptoms_flag: boolean = false;
  public newsymptoms: any = [];

  public discharge_date;
  public discharge_time1;
  public discharge_time2;
  public discharge_time3 = 'AM';
  public dischage_min: any = [];
  public dischage_hrs: any = [];

  public disable_dob: boolean;
  public disable_age: boolean;
  public require_flag: boolean;
  public h_city_id;
  public h_state_id;
  public h_country_id;

  public clnt_marital;
  public pre_hosp;
  public pre_hospital_details;
  public allergy;
  public allergyFlag;
  public drugDetailsFlag: boolean = false;
  public engDetailsFlag: boolean = false;
  public foodDetailsFlag: boolean = false;
  public drug_value;
  public drug_details;
  public env_details;
  public env_value;
  public food_details;
  public food_value;
  public bedList: any = [];
  public bedOccList: any = [];
  public selectBed;

  public printFlag;
  public hosp_addr;
  public hosp_csz;
  public hosp_count;
  public hosp_tel;
  public inpatientId;
  public curr_date;
  public coviedSytemmsText = '';
  public comorb_desc = '';
  public doctor_name;
  public wardName;
  public disPrint;
  public timePrint;

  public preHospFlag;
  public drugdetailsFlag;
  public envDetailsFlag;

  public hospitalAddress;
  public Hospital_location;
  public non_inpatient_flow: boolean = false;
  public doc_url;
  public doctor_list: any = [];
  public docname_list: any = [];
  public doc_id;
  public nurse_flow: boolean = false;
  public casesheet: boolean = false;

  public visiting_doctor;
  public visiting_doc_name;
  public docName;
  public visiting_remarks;
  public is_consultent = '0';
  public visiting_doc_id;
  public visitignArrray: any = [];
  public maritalStatusArray: any = [];
  public module_data: any = [];
  public both_modul_list: any = [];
  public submenu_drawer_modul_list: any = [];

  //casesheet
  public Gynecology_flag;
  public Is_specilization;
  public Pediatrics_flag;
  public Cardio_flag;
  public Nephrology_flag;
  public Dental_flag;
  public Diabetes_flag;
  public Others_flag;
  public spl_url;
  public stroke1;
  public doc_billable;
  public admission_date;
  public admission_time;
  public admission_time1;
  public admission_time2;
  public admission_time3;

  public ynopt1: boolean = true;
  public ynopt2: boolean = true;
  public ynopt3: boolean = true;
  public ynopt4: boolean = true;
  public ynopt5: boolean = true;
  public itemsInRow = 4;
  public itemInRowRange: any = [];
  public show_bed_details: boolean = false;
  public show_bednos: boolean = false;
  public idProofType;
  public idProofTypeList: any = [];
  public idProofId;
  public idProofDesc;
  public idProofLabel;
  public idProofNo;
  public idProofNoFlag: boolean = false;
  public select = 'Select';
  public income;
  public unit;
  public mlcCase = 'No';
  public mlcCaseNo;
  public mlcCaseNoFlag: boolean = false;
  public admDisReason;
  public modeOfPayment;
  public inpatId: string;
  public vitalsFlag: boolean = true;
  public observationFlag: boolean = true;
  public disReason;
  public req_surgery = 'No';
  public req_sur_flag: boolean = true;
  public surgery_date: string;
  public surgery_time1: string;
  public surgery_time2: string;
  public surgery_time3: string;
  public surgery_proc: string;
  public allergies: string;
  public inp_smoke: string;
  public inp_alcohol: string;
  public show_doc: boolean = true;
  public medication: string;
  public med_details: string;
  public medication_flag: boolean = false;
  public med_requed: boolean = false;

  public anaest_types: any = [];
  public anaest_effects: any = [];
  public anaest_doctors: any = [];
  public anaest_procedure: string;
  public anaesttype: string;
  public anaest_effect: string;
  public anaest_doc: string[] = [];
  public surg_doc: string[] = [];
  public consult_doc: string[] = [];
  public surgeon_list: any = [];
  public surgery_list: any = [];
  public surg_diagosis: string;
  public inp_inv: string;
  public anaest_findings: string;
  public surgery_id: string;

  public insuranceName: string;
  public insCardNo: string;
  public policyNumber: string;
  public ccnNumber: string;
  public insure_flag: boolean = true;
  private relation_id: string;
  private relation_name: string;
  public locationUrl: string;
  public locationNameByUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public zipcode_url: string;
  public userTypeFlag: boolean;
  public existUserFlag: boolean;
  public tpa_name: string;
  private nodob: boolean = false;
  public user_details: any;
  public full_details: any;
  public gender: string;
  public mobile_no: string;
  public surgery_time_full: string;
  public admission_time_full: string;

  //Senthil Modified
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 2;
  barcodeheight = 50;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
   
  constructor(public dialog: MatDialog,public http: Http,public routes: ActivatedRoute,
    public router: Router,public toastr: ToastrService,public messageservice: MenuViewService,
    public cashsheet: CasesheetService,public frontservice: MenuViewService,
    public nurseservice: MenuViewService,public masterData: MasterHelperService,
    private cdRef: ChangeDetectorRef) {

    Doc_Helper.setMedForDischarge(undefined);
    Helper_Class.setAsg(undefined);
    this.get_locname_url = ipaddress.getIp + 'usercontroller/locbyname';
    this.doc_url = ipaddress.getIp + 'search/docbyhs';
    this.maritalStatusArray = [
      'Single',
      'Bachelor',
      'Spinster',
      'Married',
      'Divorced',
      'Seperated',
      'Widowed',
    ];
    this.homecarevalue = '0';
    this.user_type = false;
    this.mobile_type = true;
    this.exist_user = false;
    this.new_user = true;
    this.update_btn = true;
    this.hmeasure = 'cms';
    this.wmeasure = 'kgs';
    this.Client_type = 'Existing';
    this.userTypeFlag = false;
    this.existUserFlag = true;

    this.search_hide = false;
    this.readonlyFlag = true;
    this.covid_type = 'no';
    this.scan_02 = '25';
    this.covid_flag = false;
    this.observetable = true;
    this.temp_txt = '';
    this.BP_01 = '';
    this.BP_02 = '';
    this.spo_txt = '';
    this.pulse_txt = '';
    this.resp_txt = '';
    this.scan_01 = '';
    this.rtpcr_result = 'Positive';
    this.observetable = true;
    this.require_flag = true;
    this.clnt_address1 = 'NA';

    this.discharge_time1 = '';
    this.discharge_time2 = '00';
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.dischage_hrs.push('0' + i);
      } else {
        this.dischage_hrs.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.dischage_min.push('0' + i);
      } else {
        this.dischage_min.push(i);
      }
    }

    this.locationUrl = ipaddress.getIp + 'usercontroller/loc';
    this.locationNameByUrl = ipaddress.getIp + 'usercontroller/locbyname';
    this.cityUrl = ipaddress.getIp + 'gen/city';
    this.stateUrl = ipaddress.getIp + 'gen/state';
    this.countryUrl = ipaddress.getIp + 'gen/count';
    this.zipcode_url = ipaddress.getIp + 'usercontroller/locbyzipcode';

    this.treatement_type = Doc_Helper.getMedicare_name();
    var hosp: any = Helper_Class.getHospital();
    this.hospital_id = hosp[0].hptl_clinic_id;
    Helper_Class.setapp_flow('0');
    Doc_Helper.setAppFlow('Inpatient');
    Doc_Helper.setClient_Info(null);
    this.itemInRowRange = Array.from(Array(this.itemsInRow).keys());
  }

  async ngOnInit() {
    $(document).ready(function () {
      $('input').attr('autocomplete', 'off');
    });

    if (Helper_Class.getInpatientFlow() == 'front-desk') {
      if (Helper_Class.getInfo().dis_summ_create == '1') {
        this.non_inpatient_flow = false;
      } else {
        this.non_inpatient_flow = true;
      }
      this.nurse_flow = false;
      this.casesheet = true;
    } else if (Helper_Class.getInpatientFlow() == 'nurse') {
      this.non_inpatient_flow = true;
      this.nurse_flow = true;
      this.casesheet = false;
    } else {
      this.non_inpatient_flow = false;
      this.nurse_flow = false;
      this.casesheet = false;
    }

    this.clnt_url =
      Doc_Helper.getInpatientFlow() == 'list'
        ? ipaddress.getIp + 'inpat/gpd/'
        : ipaddress.getIp + 'usercontroller/cdetnoapph/';

    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.hospital_details = this.userinfo.hospitals[0];
    this.hospital_name = this.hospital_details.hptl_name;
    this.hospital_id = this.hospital_details.hptl_clinic_id;
    this.clnt_location_id = this.hospital_details.location;
    this.clnt_city_id = this.hospital_details.city;
    this.clnt_state_id = this.hospital_details.state;
    this.clnt_country_id = this.hospital_details.country;

    this.Confirm_btn = false;
    this.clnt_marital = 'single';
    this.pre_hosp = 'no';
    this.changestroke(true);
    this.userTypeSelect('Existing');

    if (this.userinfo.middle_name != undefined) {
      this.docName =
        this.userinfo.first_name +
        ' ' +
        this.userinfo.middle_name +
        ' ' +
        this.userinfo.last_name;
    } else {
      if (this.userinfo.last_name != undefined) {
        this.docName = this.userinfo.first_name + ' ' + this.userinfo.last_name;
      } else {
        this.docName = this.userinfo.first_name;
      }
    }

    if (Helper_Class.getInpatientFlow() == 'front-desk') {
      this.show_doc = false;
      if (Helper_Class.getInfo().dis_summ_create == '1') {
        this.non_inpatient_flow = false;
      } else {
        this.non_inpatient_flow = true;
      }
    } else {
      this.doc_id = this.user_id;
      this.show_doc = true;
      this.visiting_doc_name = this.docName;
    }

    if (
      Helper_Class.getInpatientFlow() == 'front-desk' ||
      Helper_Class.getInpatientFlow() == 'nurse'
    ) {
      this.getSpecializations();
    } else if (Helper_Class.getInpatientFlow() == 'doctor') {
      this.spl_array = this.userinfo.specializations;
      this.spl_id = this.spl_array[0].spl_id;
      this.spl = this.spl_array[0].spl_name;
    }

    if (Master_Helper.getMasterWardData() != undefined) {
      this.ward_list = Master_Helper.getMasterWardData().wards;
    } else {
      await this.masterData.getWardData();
      this.ward_list = Master_Helper.getMasterWardData().wards;
    }
    this.getCurrentDate();
    this.getCurrentToken();
    this.getHospitalDetails();
    this.getSalutations();
    this.getCovidSymptoms();
    this.getComorbidities();
    this.getIdProofType();
    this.changeAllery('main', 1, 'no');
    this.changeAllery('drug', 1, 'no');
    this.changeAllery('evn', 1, 'no');
    this.changeAllery('food', 1, 'no');

    this.getAnaestesiaTypes();
    this.getAnaestisiaEffects();
    this.getAnaestisiaDoctors();

    if (Doc_Helper.getClient_type() != undefined &&Doc_Helper.getClient_type() == 'New') {
      this.Client_type = Doc_Helper.getClient_type();
      this.user_type = true;
      this.mobile_type = false;
      this.new_user = false;
      this.exist_user = true;
      this.client_reg_id = '0';
      this.clnt_gender = 'Male';
      this.search_hide = false;
      this.dob_flag = false;
      this.readonlyFlag = false;
      this.ynopt1 = true;

    } else {
      this.new_user = true;
      this.mobile_type = true;
      this.dob_flag = true;
      this.Client_type = 'Existing';
      this.exist_user = false;
      this.search_hide = true;
      this.readonlyFlag = true;
      this.ynopt1 = false;
    }

    if (Doc_Helper.getMobile_no() != null) {
      this.clnt_cont_number = encrypt_decript.Decript(
        Doc_Helper.getMobile_no()
      );
    }

    if (Doc_Helper.getFirstname() != null) {
      this.clnt_fname = Doc_Helper.getFirstname();
    }

    if (Doc_Helper.getMiddlename() != null) {
      this.clnt_mname = Doc_Helper.getMiddlename();
    } else {
      this.clnt_mname = undefined;
    }

    if (Doc_Helper.getLastname() != null) {
      this.clnt_lname = Doc_Helper.getLastname();
    }

    if (Doc_Helper.getRel_id() != null) {
      this.relation_id = Doc_Helper.getRel_id();
    }

    if (Doc_Helper.getrelationName() != null) {
      this.relation_name = Doc_Helper.getrelationName();
    }

    if (this.nurse_flow == true) {
      if (Nurse_Helper.getINPatient().client_reg_id != null) {
        this.client_reg_id = Nurse_Helper.getINPatient().client_reg_id;
      }
    }

    if (this.clnt_cont_number != undefined || this.clnt_cont_number != null) {
      this.getUserDetails();
    }
    this.search_hide = Boolean(Doc_Helper.getSearchFlag());
    this.medication = 'no';
    this.medication_flag = true;
  }

  paymentTypeChange() {
    if (this.modeOfPayment == 'Insurance') {
      this.insure_flag = false;
    } else {
      this.insure_flag = true;
    }
  }

  changeInvest = (event) => {
    this.inp_inv = event.html;
  };

  changeDiagosis = (event) => {
    this.surg_diagosis = event.html;
  };

  changeChiefComp = (event) => {
    this.chief_comp = event.html;
  };

  changePresIllness = (event) => {
    this.pres_ill = event.html;
  };

  changeMedicalHistory = (event) => {
    this.med_hist = event.html;
  };

  changeFamilyHistory = (event) => {
    this.family_hist = event.html;
  };

  changePhysicalExam = (event) => {
    this.physical_exam = event.html;
  };
  concentForm(){

  }

  getAnaestesiaTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .get(ipaddress.getIp + 'inpat/gat', { headers: headers })
      .subscribe(
        (data) => {
          this.anaest_types = [];
          this.anaest_types = JSON.parse(data['_body']).anaesthesia_types;
          console.log('ANAESTHESIA TYPES ' + JSON.stringify(this.anaest_types));
        },
        (error) => {
          this.toastr.error('Unable to fetch country details, try again later');
        }
      );
  }

  getAnaestisiaEffects() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .get(ipaddress.getIp + 'inpat/gase', { headers: headers })
      .subscribe(
        (data) => {
          this.anaest_effects = [];
          this.anaest_effects = JSON.parse(data['_body']).anaes_effects;
          console.log(
            'ANAESTESIA EFFECTS ' + JSON.stringify(this.anaest_effects)
          );
        },
        (error) => {
          this.toastr.error('Unable to fetch country details, try again later');
        }
      );
  }

  getAnaestisiaDoctors() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'inpat/gad',
        JSON.stringify({ hptl_clinic_id: this.hospital_id }),
        { headers: headers }
      )
      .subscribe(
        (data) => {
          this.anaest_doctors = [];
          this.anaest_doctors = JSON.parse(data['_body']).anaesthesia_doctors;
          console.log('ANAESTESIA EFFECTS' + JSON.stringify(this.anaest_doctors));
        },
        (error) => {
          this.toastr.error('Unable to fetch country details, try again later');
        }
      );
  }

  getSpecializations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'adm/gsplbyhptl',
        JSON.stringify({ hptl_clinic_id: this.hospital_id }),
        { headers: headers }
      )
      .subscribe(
        (data) => {
          this.spl_array = [];
          var obj = JSON.parse(data['_body']);
          console.log('SPECIALIZATIONS ' + JSON.stringify(obj));
          if (obj.specializations != null) {
            this.spl_array = obj.specializations;
            this.spl_id = this.spl_array[0].spl_id;
            this.spl = this.spl_array[0].spl_name;
            this.getDoctorList(this.spl_id);
            this.getSurgeries();
            this.getSurgeons();
          }
        },
        (error) => {
          this.toastr.error('Unable to fetch country details, try again later');
        }
      );
  }

  surgeryRequired() {
    if (this.req_surgery == 'No') {
      this.req_sur_flag = true;
    } else {
      this.req_sur_flag = false;
      if (this.surgeon_list.length == 0) {
        this.getSurgeries();
      }
    }
  }

  medicationChange() {
    if (this.medication == 'no') {
      this.medication_flag = true;
      this.med_requed = false;
    } else {
      this.medication_flag = false;
      this.med_requed = true;
    }
  }

  addObservations() {
    if (
      this.temp_txt.length != 0 &&
      this.BP_01.length != 0 &&
      this.BP_02.length != 0 &&
      this.spo_txt.length != 0 &&
      this.resp_txt.length != 0 &&
      this.scan_01.length != 0
    ) {
      this.BP_01 = this.BP_01 != null ? this.BP_01 : '00';
      this.BP_02 = this.BP_02 != null ? this.BP_02 : '00';

      this.observList.push({
        temp: this.temp_txt,
        bp: this.BP_01 + '/' + this.BP_02,
        sop2: this.spo_txt,
        pulse: this.pulse_txt,
        resprate: this.resp_txt,
        ct_scan: this.scan_01 + '/' + this.scan_02,
        rtpcr: this.rtpcr_result,
        status: '1',
      });
      this.observetable = false;
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  getDoctorList(e) {
    if (
      Helper_Class.getInpatientFlow() == 'front-desk' ||
      Helper_Class.getInpatientFlow() == 'nurse'
    ) {
      for (var i = 0; i < this.spl_array.length; i++) {
        if (e == this.spl_array[i].spl_id) {
          var splname = this.spl_array[i].spl_name;
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          this.doc_url,
          JSON.stringify({
            hptl_clinic_id: this.hospital_id,
            spl_name: splname,
          }),
          { headers: headers }
        )
        .subscribe(
          (data) => {
            this.doctor_list = [];
            this.docname_list = [];
            var dataobj = JSON.parse(data['_body']);
            this.doctor_list = dataobj.doctors;
            if (this.doctor_list != undefined) {
              for (var i = 0; i < this.doctor_list.length; i++) {
                var docname;
                if (this.doctor_list[i].middle_name != undefined) {
                  docname =
                    this.doctor_list[i].first_name +
                    ' ' +
                    this.doctor_list[i].middle_name +
                    ' ' +
                    this.doctor_list[i].last_name;
                } else {
                  docname =
                    this.doctor_list[i].first_name +
                    ' ' +
                    this.doctor_list[i].last_name;
                }
                this.docname_list.push({
                  docname: docname,
                  prov_id: this.doctor_list[i].prov_id,
                });
              }

              if (Helper_Class.getInpatientFlow() == 'front-desk') {
                if (Doc_Helper.getClient_type() == 'New') {
                  this.doc_id = this.docname_list[0].prov_id;
                }
              }
            }
            this.Change_Specialization(this.spl_id);
          },
          (error) => {
            this.toastr.error(Message_data.unableToFetchData);
          }
        );
    }
  }

  async getCovidSymptoms() {
    if (Master_Helper.getMasterCovidSymptoms() == undefined) {
      await this.masterData.getCovidSymptoms();
    }
    var obj = Master_Helper.getMasterCovidSymptoms();
    if (obj != undefined) {
      for (var i = 0; i < obj.covid_symptoms.length; i++) {
        this.covidSympList.push({
          symp_id: obj.covid_symptoms[i].covid_symp_id,
          symp_desc: obj.covid_symptoms[i].description,
        });
      }
    }
  }

  async getComorbidities() {
    if (Master_Helper.getMasterComorbidities() == undefined) {
      await this.masterData.getComorbidities();
    }
    var obj = Master_Helper.getMasterComorbidities();
    if (obj != undefined) {
      for (var i = 0; i < obj.comorbidities.length; i++) {
        this.comorbList.push({
          comorb_id: obj.comorbidities[i].comorbidity_id,
          comorb_desc: obj.comorbidities[i].comorbidity,
        });
      }
    }
  }

  covidtypeSelect(e) {
    this.covid_flag = this.covid_type == 'yes' ? true : false;
  }

  allergyChange(e) {
    if (this.allergy == 'yes') {
      this.allergyFlag = true;
    } else {
      this.allergyFlag = false;
      this.drugDetailsFlag = false;
      this.engDetailsFlag = false;
      this.foodDetailsFlag = false;
    }
  }

  allergyType(type, value) {
    if (type == 'drug') {
      this.drugDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'evn') {
      this.engDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    } else {
      this.foodDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    }
  }

  symptom_click(e: any, data) {
    if (e == true) {
      if (this.covidSympList.length != 0) {
        for (var i = 0; i < this.covidSympList.length; i++) {
          if (this.covidSympList[i].symp_id == data) {
            this.covidSympList[i].checked = true;
            this.finalcovidsymp.push(this.covidSympList[i].symp_id);
          }
        }
      }
    } else {
      if (this.covidSympList.length != 0) {
        for (var i = 0; i < this.covidSympList.length; i++) {
          if (this.covidSympList[i].symp_id == data) {
            this.covidSympList[i].checked = false;
          }
        }
      }

      if (this.finalcovidsymp.length != 0) {
        for (var j = 0; j < this.finalcovidsymp.length; j++) {
          if (data == this.finalcovidsymp[j]) {
            this.finalcovidsymp.splice(j, 1);
          }
        }
      }
    }
  }

  comorb_click(e: any, data) {
    if (e == true) {
      if (this.comorbList.length != 0) {
        for (var i = 0; i < this.comorbList.length; i++) {
          if (this.comorbList[i].comorb_id == data) {
            this.comorbList[i].checked = true;
            this.finalcomorbList.push(this.comorbList[i].comorb_id);
          }
        }
      }
    } else {
      if (this.comorbList.length != 0) {
        for (var i = 0; i < this.comorbList.length; i++) {
          if (this.comorbList[i].comorb_id == data) {
            this.comorbList[i].checked = false;
          }
        }
      }

      if (this.finalcomorbList.length != 0) {
        for (var j = 0; j < this.finalcomorbList.length; j++) {
          if (data == this.finalcomorbList[j]) {
            this.finalcomorbList.splice(j, 1);
          }
        }
      }
    }
  }

  Change_Specialization(e) {
    this.spl_id = e;
    for (var i = 0; i < this.spl_array.length; i++) {
      if (this.spl_array[i].spl_id == this.spl_id) {
        this.spl = this.spl_array[i].spl_name;
        this.spl_id = this.spl_array[i].spl_id;
        this.getSurgeries();
      }
    }
  }

  async getSalutations() {
    if (Master_Helper.getMasterSalutation() == undefined) {
      await this.masterData.getSalutations();
    }
    var salutation = Master_Helper.getMasterSalutation();
    if (salutation != undefined) {
      for (var i = 0; i < salutation.salutations.length; i++) {
        this.clnt_sal_list.push({
          sal_id: salutation.salutations[i].salutation_id,
          sal_desc: salutation.salutations[i].salutation_desc,
        });
      }
    }
    this.clnt_sal = this.clnt_sal_list[0].sal_id;
    //this.clnt_sal = "select";
    this.changeSalutation(this.clnt_sal);
  }

  openFabButton() {
    this.fabButtonOpened = this.fabButtonOpened == false ? true : false;
  }

  getHospitalDetails() {
    this.h_location = Master_Helper.getMasterHospital().location;
    this.h_address1 = Master_Helper.getMasterHospital().address1;
    this.h_address2 = Master_Helper.getMasterHospital().address2;
    this.h_city = Master_Helper.getMasterHospital().city;
    this.h_state = Master_Helper.getMasterHospital().state;
    this.h_country = Master_Helper.getMasterHospital().country;
    this.h_zipcode = Master_Helper.getMasterHospital().zipcode;
    this.h_city_id = Master_Helper.getMasterHospital().city_id;
    this.h_state_id = Master_Helper.getMasterHospital().state_id;
    this.h_country_id = Master_Helper.getMasterHospital().country_id;
  }

  userTypeSelect(e) {
    this.Client_type = e;
    if (this.Client_type == 'New') {
      this.userTypeFlag = true;
      this.existUserFlag = false;

      this.user_type = true;
      this.new_user = false;
      this.exist_user = true;
      this.client_reg_id = '0';
      this.clnt_gender = 'Male';
      this.search_hide = true;
      this.mobile_type = false;
      this.dob_flag = false;
      this.clnt_gender = 'Male';
      this.dob_flag = false;
      this.clnt_fname = null;
      this.clnt_mname = null;
      this.clnt_lname = null;
      this.clnt_age = '';
      this.clnt_gender = '';
      this.clnt_blood = '';
      this.clnt_blood_id = '';
      this.clnt_fg_name = '';
      this.clnt_income = '';
      this.clnt_occupation = '';
      this.clnt_address1 = 'NA';
      this.clnt_address2 = null;

      this.clnt_cont_number = null;
      this.clnt_symptom = '';
      this.clnt_dob = null;

      this.height_txt = '';
      this.hmeasure = 'cms';
      this.weight_txt = '';
      this.wmeasure = 'kgs';
      this.bmi_txt = '';
      this.temp_txt = '';
      this.bmi_txt;
      this.encheight;
      this.encweight;
      this.chief_comp = '';
      this.pres_ill = '';
      this.med_hist = '';
      this.family_hist = '';
      this.physical_exam = '';
      this.refer_txt = '';
      this.covid_type = 'no';
      this.scan_02 = '25';
      this.rtpcr_result = 'Positive';
      this.covid_flag = false;
      this.observList = [];
      this.BP_01 = '';
      this.BP_02 = '';
      this.spo_txt = '';
      this.pulse_txt = '';
      this.resp_txt = '';
      this.clnt_symptom = '';
      this.disable_age = false;
      this.disable_dob = false;
      this.readonlyFlag = false;
      for (var i = 0; i < this.comorbList.length; i++) {
        this.comorbList[i].checked = false;
      }
      for (var i = 0; i < this.covidSympList.length; i++) {
        this.covidSympList[i].checked = false;
      }

      this.clnt_sal = this.clnt_sal_list[0].sal_id;
      this.idProofType = [];
      this.idProofNo = '';
      this.idProofLabel = '';
      this.idProofDesc = '';
      this.idProofNoFlag = false;
      this.idProofId = '';
      this.mlcCase = 'No';
      this.mlcCaseNo = '';
      this.mlcCaseNoFlag = false;
      this.modeOfPayment;
      this.admDisReason;
      this.unit = '';
    } else {
      this.userTypeFlag = false;
      this.existUserFlag = true;

      this.readonlyFlag = true;
      this.user_type = false;
      this.new_user = true;
      this.exist_user = false;
      this.search_hide = false;
      this.mobile_type = true;
      this.dob_flag = true;
      this.clnt_fname = null;
      this.clnt_mname = null;
      this.clnt_lname = null;
      this.clnt_cont_number = null;
      this.clnt_age = '';
      this.clnt_gender = '';
      this.clnt_blood = '';
      this.clnt_blood_id = '';
      this.clnt_fg_name = '';
      this.clnt_income = '';
      this.clnt_occupation = '';
      this.clnt_address1 = 'NA';
      this.clnt_address2 = null;
      this.height_txt = '';
      this.hmeasure = 'cms';
      this.weight_txt = '';
      this.wmeasure = 'kgs';
      this.bmi_txt = '';
      this.temp_txt = '';
      this.bmi_txt;
      this.encheight;
      this.encweight;
      this.pres_ill = '';
      this.med_hist = '';
      this.family_hist = '';
      this.physical_exam = '';
      this.covid_type = 'no';
      this.scan_02 = '25';
      this.rtpcr_result = 'Positive';
      this.covid_flag = false;
      this.observList = [];
      this.user_type = true;
      this.new_user = true;
      this.exist_user = false;
      this.dob_flag = true;
      this.BP_01 = '';
      this.BP_02 = '';
      this.spo_txt = '';
      this.pulse_txt = '';
      this.resp_txt = '';
      this.refer_txt = '';
      this.clnt_symptom = '';
      this.clnt_dob = null;

      this.disable_age = false;
      this.disable_dob = false;
      this.clnt_location_id = this.hospital_details.location;
      this.clnt_city_id = this.hospital_details.city;
      this.clnt_state_id = this.hospital_details.state;
      this.clnt_country_id = this.hospital_details.country;

      for (var i = 0; i < this.comorbList.length; i++) {
        this.comorbList[i].checked = false;
      }
      for (var i = 0; i < this.covidSympList.length; i++) {
        this.covidSympList[i].checked = false;
      }
      this.idProofType = [];
      this.idProofNo = '';
      this.idProofLabel = '';
      this.idProofDesc = '';
      this.idProofNoFlag = false;
      this.idProofId = '';
      this.mlcCase = 'No';
      this.mlcCaseNo = '';
      this.mlcCaseNoFlag = false;
      this.modeOfPayment;
      this.admDisReason;
      this.unit = '';
      //this.getCountries("0");
    }
  }

  fname_toUpper() {
    if (this.clnt_fname != undefined) {
      this.clnt_fname = this.clnt_fname.toLocaleUpperCase();
    }
  }

  mname_toUpper() {
    if (this.clnt_mname != undefined) {
      this.clnt_mname = this.clnt_mname.toLocaleUpperCase();
    }
  }

  lname_toUpper() {
    if (this.clnt_lname != undefined) {
      this.clnt_lname = this.clnt_lname.toLocaleUpperCase();
    }
  }

  getUserDetails() {
    var fname, mname, lname, mnumber;
    this.inpatientId = undefined;
    if (
      this.clnt_fname != undefined &&
      this.clnt_fname != undefined &&
      this.clnt_fname != null
    ) {
      fname = encrypt_decript.Encript(this.clnt_fname).toString();
    }
    if (
      this.clnt_mname != '' &&
      this.clnt_mname != undefined &&
      this.clnt_mname != null
    ) {
      mname = encrypt_decript.Encript(this.clnt_mname).toString();
    }
    if (
      this.clnt_lname != undefined &&
      this.clnt_lname != undefined &&
      this.clnt_lname != null
    ) {
      lname = encrypt_decript.Encript(this.clnt_lname).toString();
    }

    if (this.clnt_cont_number != null) {
      mnumber = encrypt_decript.Encript(this.clnt_cont_number).toString();
    }

    var senddata;
    if (this.nurse_flow == true) {
      senddata = JSON.stringify({
        inpat_id: Doc_Helper.getInpatientId(),
        mobile: mnumber,
        client_reg_id: this.client_reg_id,
        fname: fname,
        mname: mname,
        lname: lname,
        country: 'IN',
      });
    } else {
      senddata = JSON.stringify({
        inpat_id: Doc_Helper.getInpatientId(),
        mobile: mnumber,
        fname: fname,
        mname: mname,
        lname: lname,
        country: 'IN',
        hptl_id: this.hospital_id,
        type: this.Client_type,
        is_doc: 1,
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.clnt_url, senddata, { headers: headers }).subscribe(
      (response) => {
        var data = JSON.parse(response['_body']);
        console.log("INPATIENT DETAILS "+JSON.stringify(data))
        this.user_details = data;
        if(data.mobile != undefined){
          this.mobile_no = encrypt_decript.Decript(data.mobile);
        }
        if (data.client_reg_id != undefined) {
          this.life_style_id = data.life_style_id;
          this.client_reg_id = data.client_reg_id;
          Doc_Helper.setClient_id(this.client_reg_id);
          Doc_Helper.setRel_id('1');
          Doc_Helper.setClient_gender(encrypt_decript.Decript(data.gender));
          this.inpatientId = data.admission_id;
          this.clnt_sal = data.salutation;
          this.clnt_saldesc = data.salutation_desc;
          this.clnt_fname = encrypt_decript.Decript(data.first_name);

          if (data.middle_name != null) {
            this.clnt_mname = encrypt_decript.Decript(data.middle_name);
          }

          this.clnt_lname = encrypt_decript.Decript(data.last_name);
          if (this.clnt_mname != undefined) {
            Doc_Helper.setClient_name(
              this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname
            );
            this.patient_name =this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
          } else {
            Doc_Helper.setClient_name(this.clnt_fname + ' ' + this.clnt_lname);
            this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
          }

          if (data.admission_date != undefined)
            this.admission_date = data.admission_date;
          if (data.mobile)

          if (data.admission_time != undefined) {
            this.admission_time = Time_Formate(data.admission_time);
            var timePrint1 = tConv24(data.admission_time);
            var adm_time = timePrint1.split(' ');
            var time1 = adm_time[0];
            var hr_min1 = time1.split(':');
            this.admission_time3 = adm_time[1];
            this.admission_time1 = hr_min1[0];
            this.admission_time2 = hr_min1[1];
          }

          if (data.spl_id != undefined) {
            Helper_Class.setspl_id(data.spl_id);
            this.spl_id = data.spl_id;
            this.getDoctorList(this.spl_id);
            for (var i = 0; i < this.spl_array.length; i++) {
              if (this.spl_id == this.spl_array[i].spl_id) {
                this.spl = this.spl_array[i].spl_name;
              }
            }
            this.getSurgeries();
          }

          if (data.dr_middle_name != undefined) {
            this.doctor_name =data.dr_first_name +' ' +data.dr_middle_name +' ' +data.dr_last_name;
          } else {
            this.doctor_name = data.dr_first_name + ' ' + data.dr_last_name;
          }
          this.visiting_doc_name = this.doctor_name;
          this.is_consultent;

          if (data.dob != undefined &&data.dob != '' && data.dob != null &&data.dob != 'null') {
            var ddata = data.dob.split('-');
            this.clnt_dob = data.dob;
            if (this.spl_id == '10') {
              this.OnDateChangeDOB(this.clnt_dob);
            }
          } else {
            this.disable_dob = true;
          }

          if (data.age != undefined && data.age != '' && data.age != null) {
            this.clnt_age = data.age;
          } else {
            this.clnt_age = '';
          }
          if (data.marital_status != undefined) {
            this.clnt_marital = data.marital_status;
          }
          Doc_Helper.setClient_age(this.clnt_age);

          this.pre_hosp = data.prev_hosp;
          this.preHospFlag = data.prev_hosp == 'Yes' ? true : false;

          this.pre_hospital_details = data.prev_hosp_det != undefined ? data.prev_hosp_det : '';
          this.allergyFlag = data.allergy == 'yes' ? true : false;
          this.allergy = data.allergy;
          this.ynopt2 = data.allergy == 'yes' ? true : false;
          if (data.gender != null && data.gender != '0: undefined') {
            this.clnt_gender = encrypt_decript.Decript(data.gender);           
            this.gender =this.clnt_gender[0];
            if(this.gender[0]=="T") {
              this.gender = "Tgen";
            }
          }

          this.clnt_blood = data.blood_desc;
          this.clnt_blood_id = data.blood_group;

          if (data.guardian != 'null') {
            this.clnt_fg_name = data.guardian;
          }

          if (data.emerg_contact != 'null') {
            this.emer_contact = data.emerg_contact;
          } else {
            this.emer_contact = '';
          }

          if (data.ward_id != undefined) {
            this.ward_id = data.ward_id;
            this.getBedList(this.ward_id);
          }

          if (data.bed_no != 'null' && data.bed_no != null &&
            data.bed_no != '' && data.bed_no != undefined) {
            this.send_bed_no = data.bed_no;
            this.bed_no = data.bed_no;
          }

          if (data.family_income != null) {
            this.clnt_income = encrypt_decript.Decript(data.family_income);
          }

          if (data.id_proof_id != null) {
            var res = this.idProofTypeList.filter((o) => o.proof_id == data.id_proof_id);
            if (res.length != 0) {
              this.idProofLabel = res[0].description;
              this.idProofNoFlag = true;
              this.idProofType = {
                proof_id: res[0].proof_id,
                description: res[0].description,
              };
              this.changeIdProofType(this.idProofType);
            }
          }

          if (data.id_proof_det != null) {
            this.idProofNo = data.id_proof_det;
          }

          if (data.inpat_id != null) {
            this.inpatId = data.inpat_id;
          }
          this.clnt_income = data.income != null ? data.income : '';
          this.mlcCase = data.mlc_type != null ? data.mlc_type : 'No';
          this.modeOfPayment =
            data.payment_mode != null ? data.payment_mode : '';
          if (data.payment_mode != null && data.payment_mode == 'Insurance') {
            this.insure_flag = false;
            this.insuranceName = data.insuranceName;
            this.insCardNo = data.insCardNo;
            this.policyNumber = data.policyNumber;
            this.ccnNumber = data.ccnNumber;
            this.tpa_name = data.tpa_name;
          }

          this.admDisReason =
            data.admission_reason != null ? data.admission_reason : '';
          this.disReason =
            data.discharge_reason != null ? data.discharge_reason : '';
          this.unit = data.unit != null ? data.unit : '';

          if (data.mlc_details != null) {
            this.mlcCaseNoFlag = this.mlcCase == 'Yes' ? true : false;
            this.mlcCaseNo = data.mlc_details;
          }

          this.height_txt = data.height;
          this.hmeasure = data.hmeasure;
          this.weight_txt = data.weight;
          this.wmeasure = data.wmeasure;
          this.bmi_txt = data.bmi;
          this.chief_comp = data.complaints;
          this.pres_ill = data.present_illness;
          this.med_hist = data.medical_history;
          this.family_hist = data.family_history;
          this.physical_exam = data.physical_exam;
          this.app_id = data.app_id;
          this.admission_id = data.admission_id;
          this.bp_txt = data.bp;
          this.temp_txt = data.temparature;
          this.clnt_symptom = data.symptoms;
          this.clnt_blood = data.blood_group;
          this.cvs_txt = data.cvs;
          this.rstxt = data.rstxt;
          this.refer_txt = data.ref_by != null && data.ref_by != undefined ? data.ref_by : '';

          if (data.occupation != null) {
            this.clnt_occupation = data.occupation;
          }

          if (data.address1 != null) {
            this.clnt_address1 = encrypt_decript.Decript(data.address1);
          }

          if (data.address2 != null) {
            this.clnt_address2 = encrypt_decript.Decript(data.address2);
          }

          this.clnt_location = data.location_desc;
          this.clnt_city = data.city_desc;
          this.clnt_state = data.state_desc;
          this.clnt_country = data.country_desc;

          this.clnt_location_id = data.location;
          this.clnt_city_id = data.city;
          this.clnt_state_id = data.state;
          this.clnt_country_id = data.country;

          if (data.zipcode != undefined) {
            this.clnt_zipcode = encrypt_decript.Decript(data.zipcode);
          }

          if (data.mobile != undefined) {
            this.clnt_cont_number = encrypt_decript.Decript(data.mobile);
          }

          var addval;
          if (data.address2 != undefined) {
            addval = this.clnt_address1 + ',' + this.clnt_address2;
          } else {
            addval = this.clnt_address1;
          }

          this.hosp_addr = addval;
          this.hosp_csz =this.clnt_location +', ' +this.clnt_city +' - ' +
            this.clnt_state +'  ' +this.clnt_zipcode;
          this.hosp_count = this.clnt_location;

          if (data.telephone != undefined) {
            this.hosp_tel = data.telephone;
          } else {
            this.hosp_tel = '-';
          }

          var hospital;
          if (data.hosp_address2 != undefined) {
            hospital = data.hosp_address1 + ',' + data.hosp_address2;
          } else {
            hospital = data.hosp_address1;
          }
          this.hospitalAddress = hospital;
          this.Hospital_location = data.hosp_loc_desc + ', ' +
            data.hosp_city_desc + ' - ' + data.hosp_state_desc + '  ' + data.hosp_zipcode;

          if (data.bp != undefined) {
            var bpre = data.bp.split('/');
            this.BP_01 = bpre[0];
            this.BP_02 = bpre[1];
          }

          if (data.spo2 != undefined) {
            this.spo_txt = data.spo2;
          }
          if (data.pulse != undefined) {
            this.pulse_txt = data.pulse;
          }
          if (data.cvs != undefined) {
            this.cvs_txt = data.cvs;
          }
          if (data.rs != undefined) {
            this.rstxt = data.rs;
          }
          if (data.cns != undefined) {
            this.cns_txt = data.cns;
          }
          if (data.abd != undefined) {
            this.abd_txt = data.abd;
          }

          this.covid_type = data.is_covid;
          this.covid_duration = data.covid_dur;

          if (this.covid_type == 'yes') {
            this.covid_flag = true;
          } else {
            this.covid_type = 'no';
            this.covid_flag = false;
          }

          this.finalcovidsymp = [];
          this.finalcomorbList = [];
          if (data.covid_symp != undefined) {
            this.finalcovidsymp = data.covid_symp;
          }
          if (data.covid_comorb != undefined) {
            this.finalcomorbList = data.covid_comorb;
          }
          if (data.covid_observ != undefined) {
            this.observList = data.covid_observ;
            this.observetable = false;
          }

          if (this.finalcovidsymp != undefined && this.finalcovidsymp.length != 0) {
            if (this.covidSympList.length != 0) {
              for (var i = 0; i < this.covidSympList.length; i++) {
                for (var j = 0; j < this.finalcovidsymp.length; j++) {
                  if (this.covidSympList[i].symp_id == this.finalcovidsymp[j]) {
                    this.covidSympList[i].checked = true;
                    if (this.coviedSytemmsText == '') {
                      this.coviedSytemmsText = this.covidSympList[i].symp_desc;
                    } else {
                      this.coviedSytemmsText =
                        this.coviedSytemmsText +
                        ', ' +
                        this.covidSympList[i].symp_desc;
                    }
                  }
                }
              }
            }
          }

          if (this.finalcomorbList != undefined && this.finalcomorbList.length != 0) {
            if (this.comorbList.length != 0) {
              for (var i = 0; i < this.comorbList.length; i++) {
                for (var j = 0; j < this.finalcomorbList.length; j++) {
                  if (this.comorbList[i].comorb_id == this.finalcomorbList[j]) {
                    this.comorbList[i].checked = true;
                    if (this.comorb_desc == '') {
                      this.comorb_desc = this.comorbList[i].comorb_desc;
                    } else {
                      this.comorb_desc =
                        this.comorb_desc +
                        ', ' +
                        this.comorbList[i].comorb_desc;
                    }
                  }
                }
              }
            }
          }

          if (data.ward_id != undefined) {
            for (var i = 0; i < this.ward_list.length; i++) {
              if (data.ward_id == this.ward_list[i].ward_id) {
                this.wardName = this.ward_list[i].ward_name;
              }
            }
          }

          if (data.discharge_date != undefined) {
            this.discharge_date = data.discharge_date;
            this.disPrint = Date_Formate(this.discharge_date);
          }

          if (data.discharge_time != undefined) {
            this.timePrint = tConv24(data.discharge_time);
            var discharge_time = this.timePrint.split(' ');
            var time = discharge_time[0];
            var hr_min = time.split(':');

            this.discharge_time3 = discharge_time[1];
            this.discharge_time1 = hr_min[0];
            this.discharge_time2 = hr_min[1];
          }

          this.req_surgery = data.req_surgery;
          if (this.req_surgery != undefined && this.req_surgery == 'Yes') {
            this.req_sur_flag = false;
            //this.getSurgeries();
            if (this.surgeon_list.length == 0) {
              
              this.getSurgeons();
            }
            this.surgery_id = data.surgery_id;
          }

          if (data.surgery_date != undefined) {
            this.surgery_date = data.surgery_date;
          }

          if (data.surgeon != undefined) {
            this.surgery_proc = data.surgery_proc;
            this.surg_doc = data.surgeon.split(',');
            if (this.surgeon_list.length != 0 && data.surgeon != undefined) {
              var surgdoc = data.surgeon.split(',');
              for (var i = 0; i < surgdoc.length; i++) {
                this.surg_doc.push(surgdoc[i]);
              }
            }
          }

          if (data.consultant != undefined) {
            this.consult_doc = data.consultant.split(',');
            if (this.surgeon_list.length != 0 && data.consultant != undefined) {
              var consultdoc = data.consultant.split(',');
              for (var i = 0; i < consultdoc.length; i++) {
                this.consult_doc.push(consultdoc[i]);
              }
            }
          }

          if (data.anaest_doc != undefined) {
            this.anaest_doc = data.anaest_doc.split(',');
            if (this.anaest_doctors.length != 0 && data.anaest_doc != undefined) {
              var andoc = data.anaest_doc.split(',');
              for (var i = 0; i < andoc.length; i++) {
                this.anaest_doc.push(andoc[i]);
              }
            }
          }

          if (data.surgery_time != undefined) {
            this.timePrint = tConv24(data.surgery_time);
            var surgery_time = this.timePrint.split(' ');
            var time = surgery_time[0];
            var hr_min = time.split(':');
            this.surgery_time3 = surgery_time[1];
            this.surgery_time1 = hr_min[0];
            this.surgery_time2 = hr_min[1];
            this.surgery_time_full = this.surgery_time1 + ':' + this.surgery_time2 + ':' + this.surgery_time3;
          }

          if (data.ref_by != undefined) {
            Doc_Helper.setRefer_by(data.ref_by);
          }

          Doc_Helper.setApp_id(this.app_id);
          if (this.inpatientId != undefined) {
            this.update_btn = false;
            this.Confirm_btn = true;
            this.printFlag = true;
          } else {
            this.update_btn = true;
            this.Confirm_btn = false;
            this.printFlag = false;
          }

          if (data.drug_details != undefined) {
            this.allergyType('drug', 'yes');
            this.drug_value = 'yes';
            this.drug_details = data.drug_details;
            this.drugdetailsFlag = true;
            this.ynopt3 = true;
          } else {
            this.drugdetailsFlag = false;
            this.drug_value = 'no';
            this.ynopt3 = false;
          }

          if (data.env_details != undefined) {
            this.allergyType('evn', 'yes');
            this.env_value = 'yes';
            this.env_details = data.env_details;
            this.envDetailsFlag = true;
            this.ynopt4 = true;
          } else {
            this.envDetailsFlag = false;
            this.env_value = 'no';
            this.ynopt4 = false;
          }

          if (data.food_details != undefined) {
            this.allergyType('food', 'yes');
            this.food_value = 'yes';
            this.food_details = data.food_details;
            this.envDetailsFlag = true;
            this.ynopt5 = true;
          } else {
            this.envDetailsFlag = false;
            this.food_value = 'no';
            this.ynopt5 = false;
          }

          this.doc_id = data.doc_reg_id;
          // visiting hospital
          //this.visiting_doc_id = data.doc_reg_id;
          if (data.doctor_visits != undefined) {
            var doc_visitng = data.doctor_visits;
            for (var i = 0; i < doc_visitng.length; i++) {
              var billable = doc_visitng[i].is_billable == '1' ? 'Yes' : 'No';
              var docName;
              if (doc_visitng[i].middle_name != undefined) {
                docName =
                  doc_visitng[i].first_name +
                  ' ' +
                  doc_visitng[i].middle_name +
                  ' ' +
                  doc_visitng[i].last_name;
              } else {
                if (doc_visitng[i].last_name != undefined) {
                  docName =
                    doc_visitng[i].first_name + ' ' + doc_visitng[i].last_name;
                } else {
                  docName = doc_visitng[i].first_name;
                }
              }
              this.visitignArrray.push({
                visiting_doc_name: docName,
                remarks: doc_visitng[i].remarks,
                billable: billable,
                date: Date_Formate(doc_visitng[i].created_date),
                time: this.tConvert(doc_visitng[i].created_time),
              });
            }
          }

          if (
            data.height == '' &&
            data.weight == '' &&
            data.bmi == '' &&
            data.temparature == '' &&
            data.bp == '' &&
            data.bp == '/' &&
            data.is_covid == 'no'
          ) {
            this.vitalsFlag = false;
          }

          if (
            this.med_hist == '' &&
            this.family_hist == '' &&
            this.physical_exam == '' &&
            this.pre_hospital_details == '' &&
            this.allergyFlag == false
          ) {
            this.observationFlag = false;
          }

          this.medication = data.take_med;
          if (data.take_med != undefined && data.take_med == 'yes') {
            this.medication_flag = false;
          }
          this.med_details = data.med_details;
          this.inp_smoke = data.smoking;
          this.inp_alcohol = data.alcohol;
          this.anaesttype = data.anaesttype;
          this.anaest_procedure = data.anaest_proc;
          this.anaest_effect = data.anaest_effect;
          this.surg_diagosis = data.discharge_diagnosis;
          this.inp_inv = data.inp_inv;
          this.anaest_findings = data.anaest_findings;
          this.surgery_id = data.surgery_id;
          this.tpa_name = data.tpa_name;
        } else {
          this.toastr.error(Message_data.noDataFound);
        }
        this.getBedDetails();
      },
      (error) => {
        this.toastr.error(Message_data.unableToFetchData);
      }
    );
  }

  getSurgeons() {
    var send_data = {
      hospital_id: this.hospital_id,
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp + 'search/docbyhns', JSON.stringify(send_data), {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();

          if (obj != null) {
            this.surgeon_list = [];
            for (var i = 0; i < obj.doctors.length; i++) {
              var docname;
              if (obj.doctors[i].middle_name != undefined) {
                docname =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
              } else {
                docname =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
              }
              this.surgeon_list.push({
                docname: docname,
                prov_id: obj.doctors[i].prov_id,
              });
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  getSurgeries() {
    var send_data = {
      spl_id: this.spl_id,
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp + 'gen/medproc', JSON.stringify(send_data), {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj != null) {
            this.surgery_list = [];
            for (var i = 0; i < obj.med_procedures.length; i++) {
              this.surgery_list.push({
                surgery_id: obj.med_procedures[i].procedure_id,
                surgery_name: obj.med_procedures[i].description,
              });
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  tConvert(time) {
    let hour = time.split(':')[0];
    let min = time.split(':')[1];
    let part = hour > 12 ? 'pm' : 'am';

    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;

    return `${hour}:${min} ${part}`;
  }

  getCurrentDate() {
    this.CurrentDatetime = Master_Helper.getMasterCurrentDate().current_date;
    this.currentDate = Master_Helper.getMasterCurrentDate().current_date;
    this.currentTime = Master_Helper.getMasterCurrentDate().current_time;
    this.get_date =
      Master_Helper.getMasterCurrentDate().current_date.split('-');
    this.currentYear = this.get_date[0];
    Helper_Class.setKey(
      Master_Helper.getMasterCurrentDate().provider +
        Master_Helper.getMasterCurrentDate().clientid +
        Master_Helper.getMasterCurrentDate().messageid
    );
    Helper_Class.setIV(Master_Helper.getMasterCurrentDate().random);
  }

  getCurrentToken() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'appointment/dtoken',
        JSON.stringify({
          doc_reg_id: this.user_id,
        }),
        { headers: headers }
      )
      .subscribe((response) => {
        var obj = response.json();
        if (obj != undefined) {
          this.tokenumber = obj.tokem;
        }
      });
  }
  
  create_admission() {
      var create_flag = true;
    if (
      this.clnt_symptom != '' &&
      this.clnt_symptom != undefined &&
      this.clnt_symptom != null
    ) {
      var new_symptoms: any = [];
      new_symptoms = this.filterNew(this.clnt_symptom, this.newsymptoms);
      var new_symptoms: any = [];
    } else {
      this.clnt_symptom = '';
    }

    if (this.Client_type == 'New') {
      if (
        this.clnt_cont_number == undefined ||
        this.clnt_fname == undefined ||
        this.clnt_lname == undefined ||
        this.clnt_age == undefined ||
        this.clnt_gender == undefined ||
        this.clnt_location_id == undefined ||
        this.spl_id == undefined
      ) {
        create_flag = false;
        this.toastr.error(Message_data.mandatory);
      }

      if (this.require_flag == true) {
        if (this.clnt_age == '') {
          create_flag = false;
        }
      }

      if (this.disable_dob == false) {
        if (this.clnt_dob == null) {
          create_flag = false;
        }
      }
    } else {
      if (this.clnt_cont_number == undefined || this.spl_id == undefined) {
        create_flag = false;
      }

      if (this.client_reg_id == '0') {
        create_flag = false;
      }
    }

    if (this.clnt_age == '') {
      create_flag = false;
    }
    if(this.clnt_gender!= undefined){
      this.clnt_gender = this.clnt_gender[0];
      if(this.clnt_gender[0]=="T")
        this.clnt_gender = "Tgen";
    }

    if (this.clnt_sal == 'select') {
      this.toastr.error(Message_data.sltSalutation);
      create_flag = false;
    }

    if (this.clnt_age == '') {
      this.toastr.error(Message_data.ageMandatory);
      create_flag = false;
    }

    if (this.idProofType != '') {
      if (this.idProofNo == undefined) {
        create_flag = false;
        this.toastr.error(Message_data.sltIdproof);
      }
    } else {
      this.idProofId = undefined;
      this.idProofNo = undefined;
    }

    if (this.modeOfPayment == 'select') {
      this.modeOfPayment = '';
    }
    if (this.modeOfPayment == 'Insurance') {
      if (this.insuranceName == undefined) {
        create_flag = false;
      }
      if (this.policyNumber == undefined) {
        create_flag = false;
      }
    }

    if (this.Client_type != 'New' && this.client_reg_id == '0') {
      this.toastr.error('Search details for existing patient');
    } else if (create_flag == false) {
      this.toastr.error(Message_data.mandatory);
    }

    if (this.admDisReason == 'select') {
      this.admDisReason = '';
    }

    if (create_flag == true) {
      this.patient_name = null;
      if (this.clnt_mname != undefined && this.clnt_mname != null) {
        if (this.clnt_mname != '') {
          this.patient_name =
            this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
        } else {
          this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
        }
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
      Doc_Helper.setClient_name(this.patient_name);
      Doc_Helper.setClient_gender(this.gender);
      if (this.clnt_age != '') {
        if (this.clnt_age == '0') {
          this.clnt_age = '';
        }
        Doc_Helper.setClient_age(this.clnt_age);
      } else {
        Doc_Helper.setClient_age('');
      }
      var get_date = Date_Formate(this.currentDate);
      var curr_datetime = new Date(
        Date_Formate(get_date) + ' ' + this.currentTime
      );
      var curr_session;
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        curr_session = 'Morning';
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        curr_session = 'Afternoon';
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
        curr_session = 'Evening';
      }

      curr_session = 'Evening';
      var apptype;
      if (this.App_type == 'New') {
        apptype = '1';
      } else {
        apptype = '2';
      }

      var midname;
      if (this.clnt_mname != undefined) {
        midname = encrypt_decript.Encript(this.clnt_mname).toString();
      }

      var addr2;
      if (this.clnt_address2 != undefined) {
        addr2 = encrypt_decript.Encript(this.clnt_address2).toString();
      } else {
        addr2 = undefined;
      }

      var address: any = [];
      address.push({
        address1: encrypt_decript.Encript(this.clnt_address1).toString(),
        address2: addr2,
        location: this.clnt_location_id,
        city: this.clnt_city_id,
        state: this.clnt_state_id,
        country: this.clnt_country_id,
        zipcode: encrypt_decript.Encript(this.clnt_zipcode).toString(),
        type: 'pres',
      });

      var bpval;
      if (this.BP_01 != null) {
        bpval = this.BP_01 + '/' + this.BP_02;
      }

      var discharge_time;
      if (
        this.discharge_time1 != undefined &&
        this.discharge_time2 != undefined
      ) {
        discharge_time = this.discharge_time1 + ':' + this.discharge_time2;
      } else {
        discharge_time = '';
      }

      var surgery_time;
      if (this.surgery_time1 != undefined && this.surgery_time2 != undefined) {
        surgery_time = ConvertTimeformat(
          '',
          this.surgery_time1 +
            ':' +
            this.surgery_time2 +
            ' ' +
            this.surgery_time3
        );
        this.surgery_time_full = this.surgery_time1 + ':' + this.surgery_time2 + ':' + this.surgery_time3;
      } else {
        surgery_time = '';
      }

      var adm_time;
      if (this.admission_time1 != undefined &&
        this.admission_time2 != undefined) {
        adm_time = ConvertTimeformat('',this.admission_time1 +':' +this.admission_time2 +' ' +this.admission_time3);
        this.admission_time_full =  this.admission_time1 + ':' + this.admission_time2 + ' ' +  this.admission_time3;
      } else {
        adm_time = '';
      }

      var user_id = this.getUserId();
      this.refer_txt = this.refer_txt != '' ? this.refer_txt : undefined;
      var save_data = JSON.stringify({
        prov_id: user_id,
        client_id: this.client_reg_id,
        relation_id: '1',
        salutation: this.clnt_sal,
        first_name: encrypt_decript.Encript(this.clnt_fname).toString(),
        middle_name: midname,
        last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
        age: this.clnt_age,
        dob: this.clnt_dob,
        gender: encrypt_decript.Encript(this.clnt_gender).toString(),
        mem_mobile: encrypt_decript.Encript(this.clnt_cont_number).toString(),
        medicare_id: '1',
        specialization_id: this.spl_id,
        hptl_id: this.hospital_id,
        date: this.CurrentDatetime,
        time: '1',
        token: this.tokenumber,
        session: encrypt_decript.Encript(curr_session).toString(),
        symptoms: this.clnt_symptom,
        newsymptoms: new_symptoms,
        app_type: '1',
        height: this.height_txt,
        height_measure: this.hmeasure,
        weight: this.weight_txt,
        weight_measure: this.wmeasure,
        bmi: this.bmi_txt,
        temparature: this.temp_txt,
        bp: bpval,
        complaints: this.chief_comp,
        present_illness: this.pres_ill,
        medical_history: this.med_hist,
        family_history: this.family_hist,
        physical_exam: this.physical_exam,
        home_care: this.homecarevalue,
        blood_group: this.clnt_blood,
        occupation: this.clnt_occupation,
        income: this.clnt_income,
        guardian: this.clnt_fg_name,
        citizenship: encrypt_decript.Encript('Indian').toString(),
        nationality: encrypt_decript.Encript('Indian').toString(),
        address: address,
        emergency_contact: this.emer_contact,
        ward_id: this.ward_id,
        bed_no: this.send_bed_no,
        flag: 'mobile',
        ref_by: this.refer_txt,
        covid_symp: this.finalcovidsymp,
        covid_comorb: this.finalcomorbList,
        covid_observ: this.observList,
        is_covid: this.covid_type,
        covid_dur: this.covid_duration,
        location: this.h_location,
        address1: this.h_address1,
        address2: this.h_address2,
        city: this.h_city,
        state: this.h_state,
        country: this.h_country,
        zipcode: this.h_zipcode,
        refer_by: this.refer_txt,
        discharge_date: this.discharge_date,
        discharge_time: discharge_time,
        marital_status: this.clnt_marital, // single or married
        prev_hosp: this.pre_hosp, // yes or no
        prev_hosp_det: this.pre_hospital_details,
        allergy: this.allergy, // yes or no
        drug_details: this.drug_details,
        env_details: this.env_details,
        food_details: this.food_details,
        id_proof_id: this.idProofId,
        id_proof_det: this.idProofNo,
        unit: this.unit,
        mlc_type: this.mlcCase,
        mlc_details: this.mlcCaseNo,
        payment_mode: this.modeOfPayment,
        admission_reason: this.admDisReason,
        discharge_reason: this.disReason,
        surgery_date: this.surgery_date,
        surgery_time: surgery_time,
        admission_date: this.admission_date,
        admission_time: adm_time,
        surgery_proc: this.surgery_proc,
        req_surgery: this.req_surgery,
        surgeon: this.surg_doc,
        consultant: this.consult_doc,
        take_med: this.medication,
        med_details: this.med_details,
        smoking: this.inp_smoke,
        alcohol: this.inp_alcohol,
        anaest_doc: this.anaest_doc,
        anaesttype: this.anaesttype,
        anaest_proc: this.anaest_procedure,
        anaest_effect: this.anaest_effect,
        surg_diagosis: this.surg_diagosis,
        inp_inv: this.inp_inv,
        anaest_findings: this.anaest_findings,
        surgery_id: this.surgery_id,
        insuranceName: this.insuranceName,
        tpa_name: this.tpa_name,
        insCardNo: this.insCardNo,
        policyNumber: this.policyNumber,
        ccnNumber: this.ccnNumber,
        spo2: this.spo_txt,
        pulse: this.pulse_txt,
        cvs: this.cvs_txt,
        rs: this.rstxt,
        cns: this.cns_txt,
        abd: this.abd_txt,
      });

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inpat/nadm', save_data, {
          headers: headers,
        })
        .subscribe((response) => {
          var obj = response.json();
          if (obj.amission_id != undefined) {
            this.inpatId =  obj.inpat_id;
            this.inpatientId =  obj.uh_id;  
            this.cdRef.detectChanges();   
            if(this.inpatId != undefined){
              setTimeout(()=>{
                this.printIdCard()

                if (Helper_Class.getInpatientFlow() == 'front-desk') {             
                  this.frontservice.sendMessage('Patients');
    
                } else {
                  this.messageservice.sendMessage('in_patient');
                } 
              },2000);
            }
            this.toastr.success(Message_data.addmissionSavedSuccess);
            Doc_Helper.setApp_id(this.app_id);
            Doc_Helper.setAdmission_id(obj.admission_id);

            if (this.Client_type == 'New') {
              this.client_reg_id = obj.client_id;
              Doc_Helper.setClient_id(obj.client_id);
              Doc_Helper.setRel_id('1');

              this.Client_type = 'Existing';
            }
            
            this.update_btn = false;
            this.Confirm_btn = true;
          } else {
            this.toastr.error(obj.result);
          }
        });
    }
  }

  //update appointment
  update_admission() {
    var create_flag = true;
    if (this.clnt_sal == 'select') {
      create_flag = false;
    }
    if (
      this.idProofDesc != undefined &&
      this.idProofDesc != null &&
      this.idProofDesc.length != 0
    ) {
      if (
        this.idProofNo == '' &&
        this.idProofNo == undefined &&
        this.idProofNo == null
      ) {
        create_flag = false;
      }
    }

    if (this.modeOfPayment == 'select') {
      this.modeOfPayment = '';
    }

    if (this.modeOfPayment == 'Insurance') {
      if (this.insuranceName == undefined) {
        create_flag = false;
      }
      if (this.policyNumber == undefined) {
        create_flag = false;
      }
      this.toastr.error(Message_data.mandatory);
    }

    if (this.admDisReason == 'select') {
      this.admDisReason = '';
    }

    if (this.send_bed_no == undefined || this.send_bed_no == '') {
      create_flag = false;
    }
    if (this.req_surgery == 'Yes') {
      if (
        this.surgery_date == undefined ||
        this.surgery_time1 == undefined ||
        this.surgery_time2 == undefined ||
        this.surgery_time3 == undefined
      ) {
        create_flag = false;
        this.toastr.error('Enter surgery date and time');
      }
    }
    if (create_flag == false) {
      if (this.clnt_sal == 'select') {
        this.toastr.error(Message_data.sltSalutation);
      } else if (this.send_bed_no == undefined || this.send_bed_no == '') {
        this.toastr.error('Allot bed for update');
      }
    } else {
      var bpval;
      if (this.BP_01 != null) {
        bpval = this.BP_01 + '/' + this.BP_02;
      }

      var midname;
      if (this.clnt_mname != undefined) {
        midname = encrypt_decript.Encript(this.clnt_mname).toString();
      }

      var addr2;
      if (this.clnt_address2 != undefined) {
        addr2 = encrypt_decript.Encript(this.clnt_address2).toString();
      } else {
        addr2 = undefined;
      }

      var get_date = Date_Formate(this.currentDate);

      var curr_datetime = new Date(
        Date_Formate(get_date) + ' ' + this.currentTime
      );
      var curr_session;
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        curr_session = 'Morning';
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        curr_session = 'Afternoon';
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
        curr_session = 'Evening';
      }

      curr_session = 'Evening';
      if (this.clnt_address1 != null && this.clnt_address1 != undefined) {
        var address1 = this.clnt_address1;
      }
      if (addr2 != null && addr2 != undefined) {
        var address2 = addr2;
      }
      var address: any = [];
      address.push({
        address1: encrypt_decript.Encript(this.clnt_address1).toString(),
        address2: addr2,
        location: this.clnt_location_id,
        city: this.clnt_city_id,
        state: this.clnt_state_id,
        country: this.clnt_country_id,
        zipcode: encrypt_decript.Encript(this.clnt_zipcode).toString(),
        type: 'pres',
      });

      var discharge_time;
      if (
        this.discharge_time1 != undefined &&
        this.discharge_time2 != undefined
      ) {
        discharge_time = ConvertTimeformat(
          '',
          this.discharge_time1 +
            ':' +
            this.discharge_time2 +
            ' ' +
            this.discharge_time3
        );
      } else {
        discharge_time = '';
      }

      var surgery_time;
      if (this.surgery_time1 != undefined && this.surgery_time2 != undefined) {
        surgery_time = ConvertTimeformat(
          '',
          this.surgery_time1 +
            ':' +
            this.surgery_time2 +
            ' ' +
            this.surgery_time3
        );
      } else {
        surgery_time = '';
      }

      var adm_time;
      if (
        this.admission_time1 != undefined &&
        this.admission_time2 != undefined
      ) {
        adm_time = ConvertTimeformat(
          '',
          this.admission_time1 +
            ':' +
            this.admission_time2 +
            ' ' +
            this.admission_time3
        );
        this.admission_time_full =  this.admission_time1 + ':' + this.admission_time2 + ' ' +  this.admission_time3;
      } else {
        adm_time = undefined;
      }

      var midname;
      if (this.clnt_mname != undefined) {
        midname = encrypt_decript.Encript(this.clnt_mname).toString();
      }
      if (this.anaest_doc.length != 0) {
        var anaedoc: any = [];
        for (var i = 0; i < this.anaest_doc.length; i++) {
          anaedoc.push(this.anaest_doc[i]);
        }
      }
      anaedoc = this.removeDuplicates(anaedoc);

      if (this.surg_doc.length != 0) {
        var surdoc: any = [];
        for (var i = 0; i < this.surg_doc.length; i++) {
          surdoc.push(this.surg_doc[i]);
        }
      }
      surdoc = this.removeDuplicates(surdoc);

      if (this.consult_doc.length != 0) {
        var consultdoc: any = [];
        for (var i = 0; i < this.consult_doc.length; i++) {
          consultdoc.push(this.consult_doc[i]);
        }
      }
      consultdoc = this.removeDuplicates(consultdoc);

      var user_id = this.getUserId();
      var send_data;
      if (this.nurse_flow == true) {
        send_data = {
          prov_id: this.doc_id,
          specialization_id: this.spl_id,
          life_style_id: this.life_style_id,
          temparature: this.temp_txt,
          height: this.height_txt,
          height_measure: this.hmeasure,
          weight: this.weight_txt,
          weight_measure: this.wmeasure,
          bmi: this.bmi_txt,
          bp: bpval,
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          admission_id: this.admission_id,
          discharge_date: this.discharge_date,
          discharge_time: discharge_time,
          ward_id: this.ward_id,
          bed_no: this.send_bed_no,
          marital_status: this.clnt_marital, // single or married
          prev_hosp: this.pre_hosp, // yes or no
          prev_hosp_det: this.pre_hospital_details,
          allergy: this.allergy, // yes or no
          drug_details: this.drug_details,
          env_details: this.env_details,
          food_details: this.food_details,
          created_by: Nurse_Helper.getINPatient().created_by,
          creator_type: Nurse_Helper.getINPatient().creator_type,
          surgery_date: this.surgery_date,
          surgery_time: surgery_time,
          admission_date: this.admission_date,
          admission_time: adm_time,
          surgery_proc: this.surgery_proc,
          req_surgery: this.req_surgery,
          surgeon: surdoc,
          consultant: consultdoc,
          first_name: encrypt_decript.Encript(this.clnt_fname).toString(),
          middle_name: midname,
          last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
          client_reg_id: this.client_reg_id,
          take_med: this.medication,
          med_details: this.med_details,
          smoking: this.inp_smoke,
          alcohol: this.inp_alcohol,
          anaest_doc: anaedoc,
          anaesttype: this.anaesttype,
          anaest_proc: this.anaest_procedure,
          anaest_effect: this.anaest_effect,
          surg_diagosis: this.surg_diagosis,
          inp_inv: this.inp_inv,
          anaest_findings: this.anaest_findings,
          surgery_id: this.surgery_id,
          insuranceName: this.insuranceName,
          tpa_name: this.tpa_name,
          insCardNo: this.insCardNo,
          policyNumber: this.policyNumber,
          ccnNumber: this.ccnNumber,
          spo2: this.spo_txt,
          pulse: this.pulse_txt,
          cvs: this.cvs_txt,
          rs: this.rstxt,
          cns: this.cns_txt,
          abd: this.abd_txt,
        };
      } else {
        send_data = {
          prov_id: this.doc_id,
          specialization_id: this.spl_id,
          life_style_id: this.life_style_id,
          temparature: this.temp_txt,
          height: this.height_txt,
          height_measure: this.hmeasure,
          weight: this.weight_txt,
          weight_measure: this.wmeasure,
          bmi: this.bmi_txt,
          bp: bpval,
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          admission_id: this.admission_id,
          discharge_date: this.discharge_date,
          discharge_time: discharge_time,
          ward_id: this.ward_id,
          bed_no: this.send_bed_no,
          marital_status: this.clnt_marital, // single or married
          prev_hosp: this.pre_hosp, // yes or no
          prev_hosp_det: this.pre_hospital_details,
          allergy: this.allergy, // yes or no
          drug_details: this.drug_details,
          env_details: this.env_details,
          food_details: this.food_details,
          id_proof_id: this.idProofId,
          id_proof_det: this.idProofNo,
          unit: this.unit,
          mlc_type: this.mlcCase,
          mlc_details: this.mlcCaseNo,
          payment_mode: this.modeOfPayment,
          admission_reason: this.admDisReason,
          discharge_reason: this.disReason,
          surgery_date: this.surgery_date,
          surgery_time: surgery_time,
          admission_date: this.admission_date,
          admission_time: adm_time,
          surgery_proc: this.surgery_proc,
          req_surgery: this.req_surgery,
          consultant: consultdoc,
          surgeon: this.removeDuplicates(surdoc),
          first_name: encrypt_decript.Encript(this.clnt_fname).toString(),
          middle_name: midname,
          last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
          client_reg_id: this.client_reg_id,
          take_med: this.medication,
          med_details: this.med_details,
          smoking: this.inp_smoke,
          alcohol: this.inp_alcohol,
          anaest_doc: this.removeDuplicates(anaedoc),
          anaesttype: this.anaesttype,
          anaest_proc: this.anaest_procedure,
          anaest_effect: this.anaest_effect,
          surg_diagosis: this.surg_diagosis,
          inp_inv: this.inp_inv,
          anaest_findings: this.anaest_findings,
          surgery_id: this.surgery_id,
          insuranceName: this.insuranceName,
          tpa_name: this.tpa_name,
          insCardNo: this.insCardNo,
          policyNumber: this.policyNumber,
          ccnNumber: this.ccnNumber,
          spo2: this.spo_txt,
          pulse: this.pulse_txt,
          cvs: this.cvs_txt,
          rs: this.rstxt,
          cns: this.cns_txt,
          abd: this.abd_txt,
          created_by: this.userinfo.user_id,
        };
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'inpat/updaadm/',
          JSON.stringify(send_data),
          { headers: headers }
        )
        .subscribe((response) => {
          var obj = response.json();

          if (obj.key == '1') {
            this.toastr.success(obj.result);
            var inpat = {
              prov_id: this.user_id,
              client_id: this.client_reg_id,
              life_style_id: this.life_style_id,
              relation_id: '1',
              app_id: this.app_id,
              salutation: this.clnt_sal,
              first_name: encrypt_decript.Encript(this.clnt_fname).toString(),
              middle_name: midname,
              last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
              age: this.clnt_age,
              dob: this.clnt_dob,
              gender: encrypt_decript.Encript(this.clnt_gender).toString(),
              mem_mobile: encrypt_decript
                .Encript(this.clnt_cont_number)
                .toString(),
              medicare_id: '1',
              specialization_id: this.spl_id,
              hptl_id: this.hospital_id,
              date: this.CurrentDatetime,
              Appoint_Date: Date_Formate(this.CurrentDatetime),
              time: '1',
              token: this.tokenumber,
              session: encrypt_decript.Encript(curr_session).toString(),
              symptoms: this.clnt_symptom,
              app_type: '2',
              temparature: this.temp_txt,
              height: this.height_txt,
              height_measure: this.hmeasure,
              weight: this.weight_txt,
              weight_measure: this.wmeasure,
              bmi: this.bmi_txt,
              bp: bpval,
              complaints: this.chief_comp,
              present_illness: this.pres_ill,
              medical_history: this.med_hist,
              family_history: this.family_hist,
              physical_exam: this.physical_exam,
              admission_id: this.admission_id,
              home_care: this.homecarevalue,
              blood_group: this.clnt_blood,
              occupation: this.clnt_occupation,
              income: this.clnt_income,
              guardian: this.clnt_fg_name,
              citizenship: encrypt_decript.Encript('Indian').toString(),
              nationality: encrypt_decript.Encript('Indian').toString(),
              address: address,
              address1: address1,
              address2: address2,
              emergency_contact: this.emer_contact,
              ward_id: this.ward_id,
              bed_no: this.bed_no,
              flag: 'mobile',
              refer_by: this.refer_txt,
              covid_symp: this.finalcovidsymp,
              covid_comorb: this.finalcomorbList,
              covid_observ: this.observList,
              is_covid: this.covid_type,
              covid_dur: this.covid_duration,
              location: this.clnt_location,
              city: this.clnt_city,
              state: this.clnt_state,
              country: this.clnt_country,
              zipcode: this.clnt_zipcode,
            };
            Doc_Helper.setClient_Info(inpat);
            if (Helper_Class.getInpatientFlow() == 'front-desk') {
              this.frontservice.sendMessage('Patients');
            }
          } else {
            this.toastr.error(obj.result);
          }
        });
    }
  }

  Write_prescription() {
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != '') {
        this.patient_name =
          this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
    } else {
      this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
    }
    var bpval;
    if (this.BP_01 != null) {
      bpval = this.BP_01 + '/' + this.BP_02;
    }

    var inpat_pres: any = [];
    inpat_pres = {
      prov_id: this.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      salutation: this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hospital_id,
      mobile: this.clnt_cont_number.toString(),
      spl: this.spl,
      temparature: this.temp_txt,
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms: this.clnt_symptom,
      medicare_name: this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      AppFlow: 'InpatMedPres',
      // ref_by:this.refer_txt,
      covid_symp: this.finalcovidsymp,
      covid_comorb: this.finalcomorbList,
      covid_observ: this.observList,
      is_covid: this.covid_type,
      covid_dur: this.covid_duration,
      location: this.h_location,
      address1: this.h_address1,
      address2: this.h_address2,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      refer_by: this.refer_txt,
    };
    Doc_Helper.setClient_Info(inpat_pres);
    Doc_Helper.setDiagAppflow('medical');
    this.messageservice.sendMessage('medprescription');
  }

  Write_prescription_forgyno() {
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != '') {
        this.patient_name =
          this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
    } else {
      this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
    }
    var bpval;
    if (this.BP_01 != null) {
      bpval = this.BP_01 + '/' + this.BP_02;
    }

    var inpat_pres: any = [];
    inpat_pres = {
      prov_id: this.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      salutation: this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hospital_id,
      mobile: this.clnt_cont_number.toString(),
      spl: this.spl,
      temparature: this.temp_txt,
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms: this.clnt_symptom,
      medicare_name: this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      AppFlow: 'InpatMedPres',
      // ref_by:this.refer_txt,
      covid_symp: this.finalcovidsymp,
      covid_comorb: this.finalcomorbList,
      covid_observ: this.observList,
      is_covid: this.covid_type,
      covid_dur: this.covid_duration,
      location: this.h_location,
      address1: this.h_address1,
      address2: this.h_address2,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      refer_by: this.refer_txt,
    };
    Doc_Helper.setGynoMedPres(inpat_pres);
  }

  validateMobile() {
    if (this.clnt_cont_number.length != 10) {
      this.toastr.error('Enter valid mobile number');
      this.clnt_cont_number = undefined;
    }
  }

  zipcode_change() {
    if (this.clnt_zipcode.length > 4) {
      this.clnt_location_list = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          this.zipcode_url,
          {
            zipcode: this.clnt_zipcode,
          },
          { headers: headers }
        )
        .subscribe(
          (data) => {
            var obj = data.json();
            if (obj.locations != null && obj.locations.length != 0) {
              if (obj.locations.length == 1) {
                this.clnt_location_id = obj.locations[0].location_id;
                this.clnt_location = obj.locations[0].description;
                this.clnt_zipcode = obj.locations[0].pincode;
                this.getCities(this.clnt_location, 0);
              } else {
                this.clnt_location_list = obj.locations;
                console.log('../location' + JSON.stringify(this.clnt_location_list));
              }
            }
          },
          (error) => {
            this.toastr.error(
              'Unable to fetch location details, please try later'
            );
          }
        );
    } else {
      this.clnt_location_list = [];
    }
  }

  getCities(state, flag) {
    this.clnt_city_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        this.cityUrl,
        { loc_name: this.clnt_location, zipcode: this.clnt_zipcode },
        { headers: headers }
      )
      .subscribe((data) => {
        var dataval = data.json();
        if (dataval.cities != null) {
          this.clnt_city_list = dataval.cities;
          if (this.clnt_city_list.length == 1) {
            for (var i = 0; i < this.clnt_city_list.length; i++) {
              if (this.clnt_city_list[i].city_id == this.clnt_city_id) {
                this.clnt_city_id = this.clnt_city_list[i].city_id;
                this.clnt_city = this.clnt_city_list[i].city_desc;
                this.getStates(this.clnt_city_id, 0);

                break;
              } else {
                this.clnt_city_id = this.clnt_city_list[0].city_id;
                this.clnt_city = this.clnt_city_list[0].city_desc;
                this.getStates(this.clnt_city_id, 0);
              }
            }
          } else {
            this.clnt_city_id = this.clnt_city_list[0].city_id;
            this.clnt_city = this.clnt_city_list[0].city_desc;
            this.getStates(this.clnt_city_id, 0);
          }
        }
      });
  }

  changeCity(clnt_city, flag) {
    this.clnt_city_list = [];
    for (var i = 0; i < this.clnt_city_list.length; i++) {
      if (this.clnt_city_list[i].city_desc == clnt_city) {
        this.clnt_city_id = this.clnt_city_list[i].district_id;
        this.clnt_city = this.clnt_city_list[i].city_desc;
        this.getStates(this.clnt_city_id, 0);
      }
    }
  }

  getLocations(city) {
    this.clnt_location_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(this.locationUrl, { city_id: city }, { headers: headers })
      .subscribe((data) => {
        var dataval = data.json();
        if (dataval.locations != null && dataval.locations.length != 0) {
          this.clnt_location_list = dataval.locations;
          for (var i = 0; i < this.clnt_location_list.length; i++) {
            if (
              this.clnt_location_list[i].location_id == this.clnt_location_id
            ) {
              this.clnt_location_id = this.clnt_location_list[i].location_id;
              this.clnt_location = this.clnt_location_list[i].description;
              this.clnt_zipcode = this.clnt_location_list[i].pincode;
              this.clnt_location_list = [];
              break;
            }
          }
        }
      });
  }

  changeLocation(locat_desc) {
    this.clnt_location = locat_desc;
    this.clnt_location_list = [];

    if (this.clnt_location != null && this.clnt_location.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          this.locationNameByUrl,
          {
            name: this.clnt_location,
            //city_id: this.clntCityId,
          },
          { headers: headers }
        )
        .subscribe(
          (data) => {
            var dataval = data.json();
            if (dataval.locations != null && dataval.locations.length != 0)
              this.clnt_location_list = dataval.locations;
          },
          (error) => {
            this.toastr.error(Message_data.unableToFetchData);
          }
        );
    } else {
      this.clnt_location_list = [];
    }
  }

  selectLocation(location) {
    this.clnt_location = location;
    for (var i = 0; i < this.clnt_location_list.length; i++) {
      if (this.clnt_location_list[i].location_id == location.location_id) {
        this.clnt_location_id = this.clnt_location_list[i].location_id;
        this.clnt_location = this.clnt_location_list[i].description;
        this.clnt_zipcode = this.clnt_location_list[i].pincode;
      }
    }
    this.clnt_location_list = [];
    this.getCities(this.clnt_location, '0');
    if (this.Client_type == 'Existing') {
      this.existUserFlag = true;
    }
  }

  getStates(city, flag) {
    this.clnt_state_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(this.stateUrl, { city_id: city }, { headers: headers })
      .subscribe((data) => {
        var dataval = data.json();
        if (dataval.states != null) {
          this.clnt_state_list = dataval.states;
          if (this.clnt_state_list.length == 1) {
            for (var i = 0; i < this.clnt_state_list.length; i++) {
              if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
                this.clnt_state_id = this.clnt_state_list[i].state_id;
                this.clnt_state = this.clnt_state_list[i].state_desc;
                this.getCountries(this.clnt_state_id, '0');
                break;
              } else {
                this.clnt_state_id = this.clnt_state_list[0].state_id;
                this.clnt_state = this.clnt_state_list[0].state_desc;
                this.getCountries(this.clnt_state_id, '0');
              }
            }
          } else {
            this.clnt_state_id = this.clnt_state_list[0].state_id;
            this.clnt_state = this.clnt_state_list[0].state_desc;
            this.getCountries(this.clnt_state_id, '0');
          }
        }
      });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clnt_state_list.length; i++) {
      if (this.clnt_state_list[i].stage_desc == clnt_state) {
        this.clnt_state_id = this.clnt_state_list[i].state_id;
        this.clnt_state = this.clnt_state_list[i].stage_desc;
        this.getCountries(this.clnt_state_id, '0');
      }
    }
  }

  getCountries(stateid, flag) {
    this.clnt_country_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(this.countryUrl, { state_id: stateid }, { headers: headers })
      .subscribe(
        (data) => {
          this.clnt_country_list = [];
          var dataval = data.json();
          if (dataval.countries != null) {
            this.clnt_country_list = dataval.countries;
            for (var c = 0; c < this.clnt_country_list.length; c++) {
              if (
                this.clnt_country_list[c].country_id == this.clnt_country_id
              ) {
                this.clnt_country = this.clnt_country_list[c].country_desc;
                this.clnt_country_id = this.clnt_country_list[c].country_id;
                //this.getStates(this.clntCountryId, flag);
                break;
              }
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.unableToFetchData);
        }
      );
  }

  // callback...
  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  };

  goToPatientlist() {
    if (Helper_Class.getInpatientFlow() == 'front-desk') {
      this.frontservice.sendMessage('Patients');
    } else if (Helper_Class.getInpatientFlow() == 'nurse') {
      this.nurseservice.sendMessage('inpatient');
    } else {
      this.messageservice.sendMessage('in_patient');
    }
  }
  goToConcentForm(){
    this.messageservice.sendMessage('concent_form');
    var object = {
      hospital_details:this.hospital_details,
      user_details:this.user_details
    };
    Helper_Class.setConcentForm(object); 
  }

  calories_data() {
    if (
      (this.height_txt != undefined && this.height_txt.length != 0) ||
      (this.weight_txt != undefined && this.weight_txt.length != 0) ||
      (this.chief_comp != undefined && this.chief_comp.length != 0) ||
      (this.family_hist != undefined && this.family_hist.length != 0) ||
      (this.pres_ill != undefined && this.pres_ill.length != 0) ||
      (this.med_hist != undefined && this.med_hist.length != 0) ||
      (this.physical_exam != undefined && this.physical_exam.length != 0)
    ) {
      if (this.app_id == undefined) {
        this.Confirm_btn = false;
      }

      if (this.height_txt != undefined && this.height_txt.length != 0) {
        this.encheight = encrypt_decript.Encript(this.height_txt.toString());
      }
      if (this.weight_txt != undefined && this.weight_txt.length != 0) {
        this.encweight = encrypt_decript.Encript(this.weight_txt.toString());
      }
    } else {
      this.Confirm_btn = true;
    }

    if (
      this.height_txt == undefined ||
      this.height_txt == '' ||
      this.weight_txt == undefined ||
      this.weight_txt == ''
    ) {
      this.bmi_txt = '';
    } else {
      if (this.height_txt != undefined) {
        if (this.hmeasure == 'cms') {
          this.height_txt = this.height_txt.toString().replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt
            .toString()
            .replace('/[^-0-9.]/g', '');
          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.hmeasure == 'cms') {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.wmeasure == 'kgs' && this.hmeasure == 'inch') {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt);
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);
      } else if (this.wmeasure == 'kgs' && this.hmeasure == 'cms') {
        var meter = parseFloat(this.height_txt) / 100.0;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.bmi_txt = Math.round(to);
      } else if (this.wmeasure == 'lbs' && this.hmeasure == 'inch') {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);
      } else if (this.wmeasure == 'lbs' && this.hmeasure == 'cms') {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);
      }
    }
  }

  dischargeSummary() {
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != '') {
        this.patient_name =
          this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
    } else {
      this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
    }
    var bpval;
    if (this.BP_01 != null) {
      bpval = this.BP_01 + '/' + this.BP_02;
    }
    var inpat_pres = {
      prov_id: this.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      salutation: this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hospital_id,
      mobile: this.clnt_cont_number.toString(),
      spl: this.spl,
      temparature: this.temp_txt,
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms: this.clnt_symptom,
      medicare_name: this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      AppFlow: 'InpatMedPres',
      // ref_by:this.refer_txt,
      covid_symp: this.finalcovidsymp,
      covid_comorb: this.finalcomorbList,
      covid_observ: this.observList,
      is_covid: this.covid_type,
      covid_dur: this.covid_duration,
      location: this.h_location,
      address1: this.h_address1,
      address2: this.h_address2,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      refer_by: this.refer_txt,
      flag: 'inpat',
    };
    Doc_Helper.setClient_Info(inpat_pres);
    Doc_Helper.setMobile_no(encrypt_decript.Encript(this.clnt_cont_number));
    this.messageservice.sendMessage('inPatientDS');
    Helper_Class.set_med_pres_id(null);
    Helper_Class.set_diag_pres_id(null);
  }

  view_prescription() {
    const dialogRef5 = this.dialog.open(InpatMedprescViewComponent, {
      width: '700px',
      height: '300px',
      data: { inpatient_id: this.admission_id },
    });

    dialogRef5.afterClosed().subscribe((result) => {});
  }

  getReferralData(e) {
    this.Refered_doctor = [];
    this.Refered_name = [];
    this.refer_txt = e.target.value.toString();
    if (this.refer_txt != '' && this.refer_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'search/docbyhns/',
          JSON.stringify({
            doc_name: this.refer_txt,
          }),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = response.json();
            this.Refered_name = obj.doctors;

            if (this.Refered_name != undefined) {
              for (var i = 0; i < this.Refered_name.length; i++) {
                var docname;
                if (this.Refered_name[i].middle_name != undefined) {
                  docname =
                    this.Refered_name[i].first_name +
                    ' ' +
                    this.Refered_name[i].middle_name +
                    ' ' +
                    this.Refered_name[i].last_name;
                } else {
                  docname =
                    this.Refered_name[i].first_name +
                    ' ' +
                    this.Refered_name[i].last_name;
                }
                this.Refered_doctor.push(docname);
              }
            }
            var name = this.refer_txt;
            this.Refered_doctor = this.Refered_doctor.filter(
              function (el) {
                return el.toLowerCase().indexOf(name.toLowerCase()) > -1;
              }.bind(this)
            );
          },
          (error) => {
            this.toastr.error(Message_data.defaultErr);
          }
        );
    } else {
      this.Refered_doctor = [];
    }
  }

  getVisitingDoc(e) {
    this.visiting_doctor = [];
    var docName = [];
    this.visiting_doc_name = e.target.value.toString();
    if (this.visiting_doc_name != '' && this.visiting_doc_name.length > 2) {
      var sendData = {
        doc_name: this.visiting_doc_name,
        hospital_id: this.hospital_id,
      };
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'search/docbyhns/',
          JSON.stringify(sendData),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = response.json();
            this.docName = obj.doctors;
            if (this.docName != undefined) {
              for (var i = 0; i < this.docName.length; i++) {
                var docname;
                if (this.docName[i].middle_name != undefined) {
                  docname =
                    this.docName[i].first_name +
                    ' ' +
                    this.docName[i].middle_name +
                    ' ' +
                    this.docName[i].last_name;
                } else {
                  docname =
                    this.docName[i].first_name +
                    ' ' +
                    this.docName[i].last_name;
                }
                this.visiting_doctor.push({
                  name: docname,
                  id: this.docName[i].prov_id,
                  type: this.docName[i].doctor_type,
                });
              }
            }
            var name = this.visiting_doc_name;
          },
          (error) => {
            this.toastr.error(Message_data.defaultErr);
          }
        );
    } else {
      this.visiting_doctor = [];
    }
  }

  Select_refer_doctor(item) {
    this.refer_txt = item;
    this.Refered_doctor = [];
  }

  Select_visiting_doctor(item) {
    for (var i = 0; i < this.visiting_doctor.length; i++) {
      if (this.visiting_doctor[i].id == item) {
        this.is_consultent =
          this.visiting_doctor[i].type != 'doctor' ? '1' : '0';
        this.visiting_doc_id = this.visiting_doctor[i].id;
      }
    }
  }

  addVisiting() {
    var providername;
    if (this.userinfo.middle_name != undefined) {
      providername =
        this.userinfo.first_name +
        ' ' +
        this.userinfo.middle_name +
        ' ' +
        this.userinfo.last_name;
    } else {
      if (this.userinfo.last_name != undefined) {
        providername = this.userinfo.first_name + ' ' + this.userinfo.last_name;
      } else {
        providername = this.userinfo.first_name;
      }
    }
    var billable = this.doc_billable == '0' ? 'No' : 'Yes';
    this.visitignArrray.push({
      visiting_doc_name: this.visiting_doc_name,
      remarks: this.visiting_remarks,
      billable: billable,
      date: Date_Formate(this.CurrentDatetime),
      time: Time_Formate(this.currentTime),
    });

    var visitingsend = {
      inpatient_id: this.inpatientId,
      doc_reg_id: this.visiting_doc_id,
      is_consultant: this.is_consultent,
      is_billable: this.doc_billable,
      remarks: this.visiting_remarks,
      created_by: providername,
      hosp_clinic_id: this.hospital_id,
    };

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'inpat/sdv',
        JSON.stringify(visitingsend),
        { headers: headers }
      )
      .subscribe((data) => {
        var obj = data.json();
        if (obj != undefined) {
          if (obj.key == '1') {
            this.toastr.success('Successfully updated visit details');
          } else {
            this.toastr.error('Unable to update visit details, try later');
          }
        }
      });
  }

  OnDateChangeDOB(e) {
    this.clnt_dob = e;
    if (e != null) {
      this.disable_age = true;
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'adm/ddif',
        JSON.stringify({
          dobdate: Date_Formate(this.clnt_dob),
          curdate: Date_Formate(this.CurrentDatetime),
          spl: this.spl_id,
        }),
        { headers: headers }
      )
      .subscribe((data) => {
        var obj = data.json();
        if (obj != undefined) {
          this.clnt_age = obj.value;
        }
      });
  }

  checkchar(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  getSymptoms() {
    var searchString;
    if (this.clnt_symptom.indexOf(',') > -1) {
      var dataval = this.clnt_symptom.split(',');
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.clnt_symptom;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'adm/gsymbn/',
          JSON.stringify({
            symp_name: searchString,
          }),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = JSON.parse(response['_body']);
            if (
              obj.symptoms != null &&
              obj.symptoms != undefined &&
              obj.symptoms != '' &&
              obj.symptoms.length != 0
            ) {
              this.newsymptoms_flag = false;
              this.symptoms_list = [];
              this.symptomlist = [];
              this.symptom_data = [];
              this.symptoms_list = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptom_data.push(obj.symptoms[i].description);
              }
              this.symptomlist = this.symptom_data.filter(
                function (el) {
                  return (
                    el.toLowerCase().indexOf(searchString.toLowerCase()) > -1
                  );
                }.bind(this)
              );
            } else {
              this.symptomlist = [];
              this.newsymptoms_flag = true;
            }
          },
          (error) => {}
        );
    }
  }

  select_symptom(data) {
    this.newsymptoms.push(data);
    for (var j = 0; j < this.symptoms_list.length; j++) {
      if (this.symptoms_list[j].description == data) {
        this.selected_symptoms.push(data);
        break;
      }
    }

    if (this.clnt_symptom.indexOf(',') > -1) {
      var dataval = this.clnt_symptom.split(',');
      dataval.pop();
      this.clnt_symptom = dataval.join(',');
      this.clnt_symptom = this.clnt_symptom + ',' + data;
    } else {
      this.clnt_symptom = data;
    }
    this.symptomlist = [];
  }

  ChangeAge() {
    if (this.clnt_age != '') {
      this.disable_dob = true;
      this.clnt_dob = null;
    } else {
      this.disable_dob = false;
    }
    this.nodob = true;
    var dateval = this.currentDate.split('-');
    this.clnt_dob = parseInt(dateval[0]) - parseInt(this.clnt_age) + '-01-01';
    this.disable_dob = false;
  }

  changeSalutation(e) {
    if (e == '1' || e == '6') this.clnt_gender = 'Male';
    else if (e == '2' || e == '3' || e == '7') this.clnt_gender = 'Female';
    else if (e == '4') {
      this.clnt_gender = 'Female';
      this.clnt_marital = 'Married';
    } else if (e == '5') this.clnt_gender = 'Transgender';
    else {
      this.clnt_gender = '';
    }
  }

  filterNew(event, newEvent) {
    if (event.trim() != '' && event != undefined && event != null) {
      var new_symptoms: any = [];
      if (event.indexOf(',') > -1) {
        var dataval1 = event.trim().split(',');
        var dataval = dataval1.sort();
        if (newEvent.length != 0) {
          new_symptoms = dataval.filter((val) => !newEvent.includes(val));
        } else {
          for (var i = 0; i < dataval.length; i++) {
            if (
              dataval[i].trim() != '' &&
              dataval[i].trim() != undefined &&
              dataval[i].trim() != null
            ) {
              new_symptoms.push(dataval[i].trim());
            }
          }
        }
      } else {
        var dataval1 = event.trim().split(',');
        var dataval = dataval1.sort();
        if (newEvent.length != 0) {
          new_symptoms = dataval.filter((val) => !newEvent.includes(val));
        } else {
          for (var i = 0; i < dataval.length; i++) {
            if (
              dataval[i].trim() != '' &&
              dataval[i].trim() != undefined &&
              dataval[i].trim() != null
            ) {
              new_symptoms.push(dataval[i].trim());
            }
          }
        }
      }
      return new_symptoms;
    }
  }

  getDoctorBothModule() {
    var send_data = {
      doc_reg_id: this.doc_id,
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/gdm', JSON.stringify(send_data), {
        headers: headers,
      })
      .subscribe((response) => {
        var obj = response.json();
        if (obj.both_modules != null && obj.both_modules != undefined) {
          for (var i = 0; i < obj.both_modules.length; i++) {
            this.module_data = [];
            obj.both_modules[i].module_id;
            this.module_data.push(obj.both_modules[i].module_id);
            this.both_modul_list.push(this.module_data);
          }
        }
        if (obj.home_modules != null) {
          for (var j = 0; j < obj.home_modules.length; j++) {
            this.module_data = [];
            this.module_data.push(obj.home_modules[j].module_id);
            this.both_modul_list.push(this.module_data);
          }
        }
        if (obj.menudrawer_modules != null) {
          for (var k = 0; k < obj.menudrawer_modules.length; k++) {
            this.module_data = [];
            this.module_data.push(obj.menudrawer_modules[k].module_id);
            this.both_modul_list.push(this.module_data);
          }
        }
        if (obj.submenudrawer_modules != null) {
          for (var k = 0; k < obj.submenudrawer_modules.length; k++) {
            this.module_data = [];
            this.module_data.push(obj.submenudrawer_modules[k].module_id);
            this.submenu_drawer_modul_list.push(this.module_data);
          }
        }
        Doc_Helper.setModuleList(this.both_modul_list);
        for (var i = 0; i < this.both_modul_list.length; i++) {
          if (this.both_modul_list[i] == '29') {
            Doc_Helper.setCasesheet_flag('1');
          } else if (this.both_modul_list[i] == '30') {
            Doc_Helper.setCasesumm_flag('1');
            // localStorage.setItem("casesumm_flag","1");
          }
        }
      });
  }

  Casesheet() {
    if (Helper_Class.getInpatientFlow() == 'nurse') {
      this.getDoctorBothModule();
    }
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != '') {
        this.patient_name =
          this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
    } else {
      this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
    }

    this.BP_01 = this.BP_01 != null ? this.BP_01 : '00';
    this.BP_02 = this.BP_02 != null ? this.BP_02 : '00';

    var user_id = this.getUserId();

    var walkinViewpres = {
      user_id: user_id,
      Age_data: this.clnt_age,
      Gender_data: this.clnt_gender,
      app_id: this.app_id,
      client_name: this.patient_name,
      //image: list.profile_image,
      sub_id: '',
      Client_id: this.client_reg_id,
      rel_id: this.relation_id,
      relation_id: this.relation_id,
      Case_Clnt_dob: this.clnt_dob,
      relationship_name: this.relation_name,
      hptl_clinic_id: this.hospital_id,
      spl_id: this.spl_id,
      spl: this.spl,
      //  mobile:list.contact,
      symptoms: this.clnt_symptom,
      //   Appoint_Date: list.lastvisit,
      medicare_name: this.treatement_type,
      inpatient_id: this.admission_id,
      address1: this.h_address1,
      address2: this.h_address2,
      location: this.h_location,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      hptl_name: Doc_Helper.getHospital_name(),
      height: this.height_txt,
      weight: this.weight_txt,
      height_measure: this.hmeasure,
      weight_measure: this.wmeasure,
      temparature: this.temp_txt,
      bp: this.BP_01 + '/' + this.BP_02,
      bmi: this.bmi_txt,
      bmr: this.bmr_txt,
      pulse: this.pulse_txt,
      patient_id: this.admission_id,
      AppFlow: 'Inpatient',
      country_id: this.h_country_id,
      state_id: this.h_state_id,
      city_id: this.h_city_id,
    };
    Doc_Helper.setClient_Info(walkinViewpres);

    this.check_specialization();
    // this.messageservice.sendMessage("medprescription");
  }

  check_specialization() {
    this.getASGDET(this.client_reg_id, '1', '');
    var oflag: boolean = false;
    var oflag: boolean = false;
    this.Gynecology_flag = false;
    Helper_Class.setapp_flow('0');
    this.Is_specilization = this.spl;
    if (this.Is_specilization == 'Gynecology') {
      this.Gynecology_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Pediatrics') {
      this.Pediatrics_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Cardiology') {
      this.Cardio_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Nephrology') {
      this.Nephrology_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Dental') {
      this.Dental_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Diabetes') {
      this.Diabetes_flag = true;
      oflag = true;
    } else {
      if (oflag == false) {
        this.Others_flag = true;
      }
    }

    if (this.Gynecology_flag == true) {
      this.Get_gynaretrieval_data();
    }
    if (this.Gynecology_flag == false) {
      this.getpatientcasesheetDetails();
    }
  }

  getASGDET(Client_id, rel_id, sub_id) {
    var sen_pass;
    if (sub_id != null && sub_id != undefined && sub_id != '') {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: rel_id,
        sub_rel_id: sub_id,
        country: ipaddress.country_code,
      });
    } else {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: rel_id,
        country: ipaddress.country_code,
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'gen/asgdet', sen_pass, {
        headers: headers,
      })
      .subscribe((response) => {
        var obj = response.json();
        Helper_Class.setAsg(obj);
      });
  }

  Get_gynaretrieval_data() {
    Helper_Class.setgynaRet(undefined);
    Helper_Class.setRet(undefined);

    var user_id = this.getUserId();
    var sendata = JSON.stringify({
      doc_reg_id: user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: Doc_Helper.getRel_id(),
      country: ipaddress.country_code,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'goi/chist', sendata, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj['key'] != 0) {
            if (obj != null && obj != undefined && obj != '') {
              Helper_Class.setgynaRet(obj);
              Helper_Class.setRet(obj);
              if (Helper_Class.getInpatientFlow() == 'nurse') {
                this.nurseservice.sendMessage('casesheet_list');
              } else {
                this.messageservice.sendMessage('casesheet_list');
              }
            }
          } else {
            this.getPatientCaseSheetFields(
              Doc_Helper.getClient_Info().hptl_clinic_id,
              Doc_Helper.getClient_Info().spl_id,
              Doc_Helper.getClient_Info().doc_app_id
            );
          }
        },
        (error) => {}
      );
  }

  getpatientcasesheetDetails() {
    Helper_Class.setRet(null);
    Helper_Class.setpediaRet(null);

    var user_id = this.getUserId();
    var send_data = JSON.stringify({
      doc_reg_id: user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: Doc_Helper.getRel_id(),
      country: 'IN',
    });

    if (this.Pediatrics_flag == true) {
      this.spl_url = 'pedia/chist';
    } else if (this.Cardio_flag == true) {
      this.spl_url = 'cardio/chist';
    } else if (this.Nephrology_flag == true) {
      this.spl_url = 'neph/chist';
    } else if (this.Dental_flag == true) {
      this.spl_url = 'dental/perdet/';
    } else if (this.Diabetes_flag == true) {
      this.spl_url = 'diab/chist';
    } else {
      this.spl_url = 'other/chist';
    }
    var headers = new Headers();

    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + this.spl_url, send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();

          if (obj['key'] != 0) {
            if (obj != null || obj != '' || obj != undefined) {
              Helper_Class.setRet(obj);
              if (this.Pediatrics_flag == true) {
                Helper_Class.setpediaRet(obj);
              }
              if (this.Nephrology_flag == true) {
                Helper_nephro_casesheet.nephro_get_ret = obj;
              }
              if (this.Dental_flag == true) {
                Helper_Class.Set_den_ret1(obj);
                this.Dental_Retrieval_2();
              }
              if (this.Dental_flag == false && this.Nephrology_flag == false) {
                if (Helper_Class.getInpatientFlow() == 'nurse') {
                  this.nurseservice.sendMessage('casesheet_list');
                } else {
                  this.messageservice.sendMessage('casesheet_list');
                }
              } else {
                this.getPatientCaseSheetFields(
                  this.hospital_id,
                  this.spl_id,
                  Doc_Helper.getApp_id()
                );
              }
            }
          } else {
            if (Doc_Helper.getCasesheet_flag() == '1') {
              this.getPatientCaseSheetFields(
                this.hospital_id,
                this.spl_id,
                Doc_Helper.getApp_id()
              );
            } else {
              this.getPatientCaseSheetFields(
                this.hospital_id,
                this.spl_id,
                Doc_Helper.getApp_id()
              );
            }
          }
        }, //response
        (error) => {}
      );
  }

  Dental_Retrieval_2() {
    var sen_pass = JSON.stringify({
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: Doc_Helper.getRel_id(),
      country: ipaddress.country_code,
      den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dental/patdet/', sen_pass, {
        headers: headers,
      })
      .subscribe((response) => {
        var obj = response.json();
        Helper_Class.Set_den_ret2(obj);
      });
  }

  getPatientCaseSheetFields(hptl_id, spl_id, doc_app_id) {
    var getFields = JSON.stringify({
      hosp_id: hptl_id,
      spl_id: spl_id,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/gpfhsp/', getFields, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();

          Helper_Class.setHopitalfieldsRet(obj.pages);
          this.get_case_sheet_data(Doc_Helper.getApp_id());
        },
        (error) => {
          this.toastr.error(Message_data.defaultErr);
        }
      );
  }

  get_case_sheet_data(doc_app_id) {
    Doc_Helper.setDischarge_Summary(undefined);
    var user_id = this.getUserId();

    var sen_pass = JSON.stringify({
      doc_reg_id: user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: Doc_Helper.getRel_id(),
      country: ipaddress.country_code,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'gen/cbook', sen_pass, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();

          if (obj['key'] != 0) {
            if (
              obj != null &&
              obj.length != 0 &&
              obj != undefined &&
              obj != ''
            ) {
              Doc_Helper.setDischarge_Summary('S');
              Helper_Class.setcasesumret(obj);
            } else {
              Helper_Class.setcasesumret(undefined);
            }
          } else {
            Helper_Class.setcasesumret(undefined);
          }

          if (this.Gynecology_flag == true) {
            const dialogRef = this.dialog.open(
              GynaObestricPopSelectionComponent,
              {
                width: '280px',
                height: '182px',
              }
            );
          } else {
            if (Helper_Class.getInpatientFlow() == 'nurse') {
              this.nurseservice.sendMessage('casesheet');
            } else {
              this.messageservice.sendMessage('casesheet');
            }
          }
        },
        (error) => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      );
  }

  changeGender(e) {
    if (
      this.clnt_age != undefined &&
      this.clnt_age != null &&
      this.clnt_age != ''
    ) {
      if (e == 'Male') {
        if (this.clnt_age <= 15) this.clnt_sal = '6';
        else this.clnt_sal = '1';
      } else if (e == 'Female') {
        if (this.clnt_age <= 15) this.clnt_sal = '7';
        else this.clnt_sal = '2';
      } else if (e == 'Transgender') this.clnt_sal = '5';
      else {
        this.clnt_sal = '';
      }
    } else {
      if (e == 'Male') {
        this.clnt_sal = '1';
      } else if (e == 'Female') {
        this.clnt_sal = '2';
      } else if (e == 'Transgender') this.clnt_sal = '5';
      else {
        this.clnt_sal = '';
      }
    }
  }

  print_area() {
    let printContents, popupWin;
    // this.admDisReason = this.admDisReason != "Select" ?  this.admDisReason : "";
    // this.modeOfPayment = this.modeOfPayment != "Select" ?  this.modeOfPayment : "";
    printContents = document.getElementById('inpatientInfoPrint')!.innerHTML;
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
            
          table.table td {
            padding: 0px 15px !important;
            border: 1px solid #000 !important;
            height: 30px !important;
            font-size: 12px !important;
          }
          .table th {
            height: 25px;
            color: #000;
            padding: 8px 0px;
            background: #fff;
            border: 1px solid #000 !important;
          }
          .align_left{
            width: 50%;
            padding-left: 10px;
          }
          .pRow{
            padding-left: 20px;
          }
          .rowCover{
            display: inline-block;
            width:100%
          }
          .vitalLable{
            display: inline-block;
            width: 47%;
            padding: 0 12px;
          }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }
  
  printIdCard() {
        let printContents, popupWin;
        printContents = document.getElementById('idprint').innerHTML;
        popupWin = window.open(
          '',
          '_blank',
          'top=20,left=10,height=100%,width=auto'
        );
        popupWin.document.open();
        popupWin.document.write(`
              <head>
                <title>Inpatient</title>
                <link rel="stylesheet" media="screen" href="">
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
                <style>
                @page { size: auto;  margin: 0mm; }
                .table_class {
                  background-color: rgb(108, 180, 248);
                }
                
                </style>
              </head>
                  <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
                </html>`);
        popupWin.document.close();
  }

  getBedList(ward_id) {
    this.bedList = [];
    this.show_bed_details = this.bedList.length != 0 ? true : false;
    this.bed_no = undefined;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'inpat/gbw',
        JSON.stringify({
          hptl_clinic_id: this.hospital_id,
          ward_id: ward_id,
        }),
        { headers: headers }
      )
      .subscribe((data) => {
        var obj = data.json();
        var flag = this.isEmptyObject(obj);
        if (obj != undefined && obj != null && obj != '' && !flag) {
          if (obj.bed_details.length != 0) {
            this.show_bed_details = obj.bed_details != 0 ? true : false;

            for (var i = 0; i < obj.bed_details.length; i++) {
              var pat_name,fontWeight = '',flag: boolean;
              pat_name =obj.bed_details[i].name == 'Vacant' ? '#000' : 'darkgreen';
              fontWeight = obj.bed_details[i].name == 'Vacant' ? '400' : '600';
              flag = obj.bed_details[i].name == 'Vacant' ? true : false;
              this.bedList.push({
                bed_no:obj.bed_details[i].bed_no != undefined? obj.bed_details[i].bed_no: '--',
                pat_name: obj.bed_details[i].name,
                bed:obj.bed_details[i].bed_no + ' - ' + obj.bed_details[i].name,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                color: pat_name,
                fontWeight: fontWeight,
                flag: flag,
              });
            }
          }
        } else {
          this.show_bed_details = this.bedList.length != 0 ? true : false;
        }
      });
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  changeBed(e) {
    if (e.flag == true) {
      this.bed_no = e.bed_no;
      this.send_bed_no = e.bed_no;
    } else {
      this.toastr.error('Please select vacant bed');
    }
  }

  changestroke(e) {
    this.stroke1 = e;
    if (e == false) {
      this.doc_billable = '0';
    } else {
      this.doc_billable = '1';
    }
  }

  view_Medtracker() {
    const dialogRef5 = this.dialog.open(InpatMedicineTrackerComponent, {
      width: '700px',
      height: 'auto',
      data: {
        relation_id: '1',
        // sub_rel_id: sub_rel_id,
        trackerFlag: 'medicine',
      },
    });

    dialogRef5.afterClosed().subscribe((result) => {
      //this.dis_txt = result;
    });
  }

  getUserId() {
    var user_id;
    if (
      Helper_Class.getInpatientFlow() == 'front-desk' ||
      Helper_Class.getInpatientFlow() == 'nurse'
    ) {
      user_id = this.doc_id;
    } else {
      user_id = this.user_id;
    }
    return user_id;
  }

  change_type(id, value) {
    if (value == 'yes') {
      this.ynopt1 = true;
      this.Client_type = 'New';
      this.userTypeSelect('New');
    } else {
      this.ynopt1 = false;
      this.Client_type = 'Existing';
      this.userTypeSelect('Existing');
    }
  }

  changeAllery(type, id, value) {
    if (type == 'main') {
      if (value == 'yes') {
        this.ynopt2 = true;
        this.allergy = 'yes';
        this.allergyChange('New');
      } else {
        this.ynopt2 = false;
        this.allergy = 'no';
        this.allergyChange('Existing');
      }
    } else if (type == 'drug') {
      if (value == 'yes') {
        this.ynopt3 = true;
        this.drug_value = 'yes';
        this.allergyType('drug', 'Yes');
      } else {
        this.ynopt3 = false;
        this.drug_value = 'no';
        this.allergyType('drug', 'No');
      }
    } else if (type == 'evn') {
      if (value == 'yes') {
        this.ynopt4 = true;
        this.env_value = 'yes';
        this.allergyType('evn', 'Yes');
      } else {
        this.ynopt4 = false;
        this.env_value = 'no';
        this.allergyType('evn', 'No');
      }
    } else {
      if (value == 'yes') {
        this.ynopt5 = true;
        this.food_value = 'yes';
        this.allergyType('food', 'Yes');
      } else {
        this.ynopt5 = false;
        this.food_value = 'no';
        this.allergyType('food', 'No');
      }
    }
  }

  async getIdProofType() {
    if (Master_Helper.getMasterIdProofType() == undefined) {
      await this.masterData.getIdProofType();
    }
    var obj = Master_Helper.getMasterIdProofType();
    if (obj != undefined) {
      console.log('id proof ==> ' + JSON.stringify(obj));
      for (var i = 0; i < obj.id_proofs.length; i++) {
        this.idProofTypeList.push({
          proof_id: obj.id_proofs[i].proof_id,
          description: obj.id_proofs[i].description,
        });
      }
    }
  }

  compareById(object1: any, object2: any) {
    console.log('object1 ==> ' + JSON.stringify(object1));
    console.log('object2 ==> ' + JSON.stringify(object2));
    return object1 && object2 && object1.proof_id === object2.proof_id;
  }

  changeIdProofType(event) {
    this.idProofNoFlag = true;
    this.idProofId = event.proof_id;
    this.idProofDesc = event.description;
    this.idProofLabel = this.idProofDesc;
  }

  getBedDetails() {
    if (Doc_Helper.getInpatientId() != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp + 'inpat/gbdinp',
          JSON.stringify({
            hptl_clinic_id: this.hospital_id,
            inpatient_id: Doc_Helper.getInpatientId(),
          }),
          { headers: headers }
        )
        .subscribe((data) => {
          this.bedOccList = [];
          var obj = data.json();
          if (obj.bed_details != undefined && obj.bed_details.length != 0) {
            for (var i = 0; i < obj.bed_details.length; i++) {
              var bdate = obj.bed_details[i].occup_date.split('-');
              this.bedOccList.push({
                ward: obj.bed_details[i].ward_name,
                bedno: obj.bed_details[i].bed_no,
                date: bdate[2] + '-' + bdate[1] + '-' + bdate[0],
                status: obj.bed_details[i].status,
              });
            }
          }
          this.show_bednos = this.bedOccList.length != 0 ? true : false;
        });
    }
  }

  removeDuplicates(arr) {
    var tmp: any = [];
    if (arr != undefined) {
      for (var i = 0; i < arr.length; i++) {
        if (tmp.indexOf(arr[i]) == -1) {
          tmp.push(arr[i]);
        }
      }
    }
    return tmp;
  }

  validate(event: any) {
    const inputValue = event.target.value;
    const charCode = event.key.charCodeAt(0);

    if ((charCode >= 48 && charCode <= 57) || 
      charCode === 46 || charCode === 67 || charCode === 70 ||  
      event.key === 'Backspace' || event.key === 'Delete' 
    ) {
      const dotIndex = inputValue.indexOf('.');

      if (charCode === 46) {
        if (dotIndex !== -1 || inputValue === '') {
          event.preventDefault(); 
        }
      } else if (dotIndex !== -1) {
        if (event.key === 'Backspace' || event.key === 'Delete') {
          const decimalPart = inputValue.substr(dotIndex + 2);
          if (decimalPart.length === 1) {
            event.preventDefault();
            event.target.value = inputValue.substr(0, dotIndex); 
          }
        } else if (inputValue.substr(dotIndex + 2).length >= 1) {
          event.preventDefault(); 
        }
      }
    } else {
      event.preventDefault(); 
    }
  }
}
