import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import { RouterModule, Routes } from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';

import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import { FormControl } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import { ToastrModule ,ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import { CommonModule } from '@angular/common';
import {TabsModule } from 'ngx-bootstrap/tabs';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { QuillModule } from 'ngx-quill';
import { TranslateModule } from '@ngx-translate/core';
import {NgxPrintModule} from 'ngx-print';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { PhysioComponent } from './physio/physio.component';
import { PhysioAppointmentsComponent } from './physio-appointments/physio-appointments.component';
import { PhysioCasesheetComponent } from './physio-casesheet/physio-casesheet.component';
import { PhysioHistoryComponent } from './physio-history/physio-history.component';
import { PhysioHabitualComponent } from './physio-habitual/physio-habitual.component';
import { PhysioClinicalComponent } from './physio-clinical/physio-clinical.component';
import { PhysioNeurologicalComponent } from './physio-neurological/physio-neurological.component';
import { PhysioTreatmentPlanComponent } from './physio-treatment-plan/physio-treatment-plan.component';
import { PhysioDietPlanComponent } from './physio-diet-plan/physio-diet-plan.component';
import { PhysioMedPrescriptionComponent } from './physio-med-prescription/physio-med-prescription.component';
import { PhysioAppointmentDetailviewComponent } from './physio-appointment-detailview/physio-appointment-detailview.component';
import { PhysioAssignListComponent } from './physio-assign-list/physio-assign-list.component';
import { PhysioAssignViewComponent } from './physio-assign-view/physio-assign-view.component';
import { PhysioTherapiesComponent } from './physio-therapies/physio-therapies.component';
import { PhysioUserListComponent } from './physio-user-list/physio-user-list.component';
import { PhysioUserViewComponent } from './physio-user-view/physio-user-view.component';
import { PhysioProfDashComponent } from './physio-prof-dash/physio-prof-dash.component';
import { WalkingComponent } from './walking/walking.component';
import { PhysioBilllistComponent } from './physio-billlist/physio-billlist.component';
import { PhysioBillDetailsComponent } from './physio-bill-details/physio-bill-details.component';
 //import { PhysioBillCreateComponent } from './physio-bill-create/physio-bill-create.component';
@NgModule({
  declarations: [ 
    PhysioComponent, 
    PhysioAppointmentsComponent,
    PhysioCasesheetComponent,
    PhysioHistoryComponent,
    PhysioHabitualComponent,
    PhysioClinicalComponent,
    PhysioNeurologicalComponent,
    PhysioTreatmentPlanComponent,
    PhysioDietPlanComponent,
    PhysioMedPrescriptionComponent,
    PhysioAppointmentDetailviewComponent,
    PhysioAssignListComponent,
    PhysioAssignViewComponent,
    PhysioTherapiesComponent,
    PhysioUserListComponent,
    PhysioUserViewComponent,
    PhysioProfDashComponent,
    WalkingComponent,
    PhysioBilllistComponent,
    PhysioBillDetailsComponent,
    
    // PhysioBillCreateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSliderModule,
    RouterModule,
    MatSelectModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatListModule,  
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCardModule,
    MatDialogModule,
    MatNativeDateModule,
    CarouselModule,
    QuillModule.forRoot(),
    TranslateModule,
    // EditorModule,
    DataTablesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
   
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'     
    }),
    ToastNoAnimationModule.forRoot(),
  ],
  providers: [DatePipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports:[MatDialogModule,MatIconModule,FormsModule,
    PhysioAppointmentsComponent,
    PhysioCasesheetComponent,
    PhysioProfDashComponent,
    PhysioAssignListComponent,
    PhysioAssignViewComponent,
    PhysioTherapiesComponent,
    PhysioUserListComponent,
    PhysioUserViewComponent,
    PhysioBilllistComponent,
    WalkingComponent,
    PhysioBillDetailsComponent,
    PhysioAssignViewComponent
   // PhysioBillCreateComponent,
  ]
})
export class PhysioModule { }
