import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diagbilldetailview',
  templateUrl: './diagbilldetailview.component.html',
  styleUrls: ['./diagbilldetailview.component.css']
})
export class DiagbilldetailviewComponent implements OnInit {
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  public billID: string;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public header_footer_flag: boolean;
  public loader: boolean;
  public diagID;
  public billDetails;
  public printFlag: boolean;
  public centralGST;
  public stateGST;
  public billingDetails = [];
  public billPrintConcseFlag: boolean;
  public totalShow: boolean;
  public advanceFlag: boolean;
  public prevBlance: boolean;
  public prevBalFlag: boolean;
  public estimate;
  public treatmentID: boolean;
  public hospitalAddFlag: boolean;
  public address: string;
  public clientaddress: string;
  public total: string;
  public totalAmount: string;
  public billPrintArray:any = [];
  public clientMidName: string;
  public doctorName: string;
  public hospitalLogo;
  public paid;
  public printtemplate: string;
  private finyear:string;
  public noaddress:boolean=false;
  public mobile;
  public print_style: string;
  public paymentsarray:any=[];
  public paytypreq:boolean=false;

  constructor(public toastr: ToastrService, public http: Http, public diagnosisservice: MenuViewService, public frontdeskservice: MenuViewService ,public adminservice:MenuViewService) {
  }
  
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.billDetails = Diagnosis_Helper.getbilldetailview();
    this.billID = this.billDetails.bill_view_id;
    this.finyear = this.billDetails.fin_year;
    
    if( Helper_Class.getLoginType() == "diagnosis" ){
      this.diagID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().bill_print_template;
      this.printFlag = Helper_Class.getInfo().hospitals[0].bill_print == "1" ? true : false;
      this.print_style=Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
      
    } else{
      this.diagID = Helper_Class.getInfo().diag_center_id;
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().bill_print_template;
      this.print_style=Helper_Class.getInfo().bill_print_logo_style;
      this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;
    }

    this.hospitalAddFlag = true;
    this.billPrintConcseFlag = true;
    this.treatmentID = true;
    this.centralGST = true;
    this.stateGST = true;
    this.prevBalFlag = true;
    
    if(Helper_Class.getInfo().user_type=="diagnosis"){
      if(Helper_Class.getInfo().hospitals[0].bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }    
      
    } else {
      if(Helper_Class.getInfo().bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }
    }
    this.getBillDetails();
  }

  getBillDetails() {
    this.loader = false;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/',
      JSON.stringify({
        doc_reg_id: this.diagID,
        billing_id: this.billID,
        app_type: "diagnosis",
        fin_year:this.finyear,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.bills != null) {
            this.loader = true;
            var cgst;
            if (obj.cgst != null) {
              this.centralGST = false;
              this.stateGST = false;
            }
            if (obj.bills != null && obj.bills.length != 0) {
              var sno=0;
              for (var i = 0; i < obj.bills.length; i++) {
                var amount;
                if (obj.bills[i].fee != undefined && obj.bills[i].quantity)
                  amount = (parseFloat(obj.bills[i].fee) / parseFloat(obj.bills[i].quantity)).toFixed(2);
                else
                  amount = "";

                sno +=1;
                this.billingDetails.push({
                  sno:sno,
                  test_name: obj.bills[i].test_name,
                  fee: obj.bills[i].amount_txt,
                  pay_type: obj.bills[i].pay_type,
                  concession: obj.bills[i].concession,
                  quantity: obj.bills[i].quantity,
                  finalfee: parseFloat(obj.bills[i].fee).toFixed(2)
                });
              }
            }
            
            this.billPrintConcseFlag = obj.bills != null && obj.bills[0].test_name != null ? false : true;
            this.totalShow = obj.bill_amount == undefined && obj.bill_amount == "" ? true : false;
            this.advanceFlag = obj.advance == undefined || obj.advance == "" ? true : false;
            if (obj.prev_balance != null) {
              this.prevBlance = obj.prev_balance;
            } else {
              this.prevBalFlag = true;
            }
            this.estimate = obj.estimates;
            this.treatmentID = this.estimate != "" && this.estimate != undefined ? false : true;
            this.hospitalAddFlag = obj.diag_centre_name != null ? false : true;
            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            }
            if (obj.first_name != undefined) {
              this.address = obj.address2 != null && obj.address2 != "" && obj.address2 != undefined ? obj.address1 + ", " + obj.address2 + "," : obj.address1 + ",";
              if(encrypt_decript.Decript(obj.caddress1) == "NA" ){
                this.noaddress= true;
              } else {
                this.noaddress=false;
              }
              
              if (obj.caddress1 != null) {
                if (obj.caddress2 != null && obj.caddress2 != "" && obj.caddress2 != "null" && obj.caddress2 != undefined) {
                  this.clientaddress = encrypt_decript.Decript(obj.caddress1) + "," + encrypt_decript.Decript(obj.caddress2) + ",";
                } else {
                  this.clientaddress = encrypt_decript.Decript(obj.caddress1);
                }
              }
              var tot_amt = "0";
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseInt(obj.bills[i].fee)).toFixed(2);
                  //  tot_amt = (parseInt(tot_amt) + parseInt(obj.bills[i].fee) - ((parseInt(obj.bills[i].fee) * parseInt(obj.bills[i].concession)) / 100)).toFixed(2);
                  }
                }
              }
              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                    }
                  }
                }
              }
              this.total = tot_amt;
              this.totalAmount = tot_amt;
              if (obj.cgst != null) {
                this.centralGST = (parseFloat(this.total) * (parseFloat(obj.cgst) / 100)).toFixed(2);
                this.stateGST = (parseFloat(this.total) * (parseFloat(obj.sgst) / 100)).toFixed(2);
              }
              this.total = encrypt_decript.Decript(obj.bill_amount);
              this.paid = obj.paid_amount != undefined ? parseFloat(obj.paid_amount).toFixed(2) : '0.00';
              if (obj.middle_name != null && obj.middle_name != "") {
                this.clientMidName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              } else {
                this.clientMidName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              }
              this.doctorName = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
              if(obj.mobile != undefined){
                this.mobile = encrypt_decript.Decript(obj.mobile);
              }
              var cname;
              if(obj.salutation != undefined){
                cname = obj.salutation+" "+this.clientMidName;
              } else {
                cname = this.clientMidName;
              }
              var ageval;
              if(obj.age != undefined){
                ageval = obj.age;
  
              } else {
                ageval="";
              }
              var genshort;
              
              if(obj.gender != undefined){
                if(encrypt_decript.Decript(obj.gender) =="Male"){
                  genshort = "M";
                } else {
                  genshort="F";
                }
              } else {
                genshort="";
              }
              
              this.billPrintArray.push({
                dr_name: "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name,
                licence_code: " Reg. No: " + obj.licence_code,
                diag_centre_name: obj.diag_centre_name,
                address: this.address,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                created_date: datetxt,
                appointment_id: obj.appointment_id,
                client_name: cname,
                advance: obj.advance,
                balance: obj.balance,
                caddress: this.clientaddress,
                clocation: obj.clocation + ", ",
                clientcity: obj.ccity + " - " + encrypt_decript.Decript(obj.czipcode) + ",",
                clestare: obj.cstate + " - " + obj.ccountry + ".",
                client_reg_id: obj.client_hosp_id,
                mobile:this.mobile,
                age:ageval,
                gender:genshort,
              });

              if(obj.payments != undefined){
                this.paymentsarray = obj.payments;
              }
            }
          }
        }, error => { });
  }

  printArea() {
    let printContents, popupWin;
    if (this.printtemplate != undefined && this.printtemplate == "banner") {
      printContents = document.getElementById('banner').innerHTML;

    } else if (this.printtemplate != undefined && this.printtemplate == "noheader") {
      printContents = document.getElementById('noheader').innerHTML;

    } else {
      printContents = document.getElementById('logowithname').innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Bill</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  backClicked() {
    if(Helper_Class.getIsAdmin() != undefined){
        this.adminservice.sendMessage("diagBillingList");
    }else{
      if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk")
      this.frontdeskservice.sendMessage("manageappointments");
      else if (Diagnosis_Helper.getCreateDiagAppt().flow == "dignosis")
        this.diagnosisservice.sendMessage("appointment");
      else if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdeskbilling")
        this.frontdeskservice.sendMessage("frontdeskbilling");
      else
        this.diagnosisservice.sendMessage('billing');
    }
    
  }
}
