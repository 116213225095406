<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Personal information</h5>
                    </div>
                    <div class="headerButtons">
                        <a style="margin-right: 5px;" [hidden]="update_btn || nurse_flow" (click)="print_area()"><img
                                src="../../../assets/img/printer.svg" style="height:25px;" /></a>
                        <a style="margin-right: 5px;" (click)="goToPatientlist()"><img
                                src="../../../assets/ui_icons/buttons/Back_button.svg"
                                class="backimgbtn_inpatinfo" /></a>
                        <a style="margin-right: 5px;" [hidden]="update_btn || casesheet" (click)="Casesheet()">
                            <img src="../../../assets/ui_icons/buttons/case sheet.svg"
                                class="writerximgbtn_inpatinfo" /></a>
                        <a [hidden]="!nurse_flow" style="margin-right: 5px;" (click)="view_Medtracker()"><img
                                src="../../../assets/ui_icons/buttons/medicine_tracker_button_icon.svg"
                                class="viewrximgbtn_inpatinfo" /></a>
                        <a [hidden]="!nurse_flow" style="margin-right: 5px;" (click)="view_prescription()"><img
                                src="../../../assets/ui_icons/buttons/view_RX_button.svg"
                                class="viewrximgbtn_inpatinfo" /></a>
                        <a [hidden]="update_btn || non_inpatient_flow" (click)="dischargeSummary()"><img
                                src="../../../assets/ui_icons/buttons/Discharge_icon.svg"
                                class="disimgbtn_inpatinfo" /></a>
                        <a style="margin-right: 5px;" [hidden]="update_btn" (click)="update_admission()"><img
                                src="../../../assets/ui_icons/buttons/update_button.svg"
                                class="updateimgbtn_inpatinfo" /></a>
                        <a style="margin-right: 5px;" [hidden]="Confirm_btn" (click)="create_admission()"><img
                                src="../../../assets/ui_icons/buttons/save_button.svg"
                                class="saveimgbtn_inpatinfo" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="Details" style="margin-top: -10px;">
                        <div class="row" *ngIf="update_btn">
                            <div class="col-2">
                                <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                            </div>
                            <div class="col-6">
                                <div class="switch-field" *ngIf="ynopt1 != undefined">
                                    <input type="radio" id="radio-three" (click)="change_type(1,'yes')" />
                                    <label for="radio-three"
                                        [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">New</label>
                                    <input type="radio" id="radio-four" (click)="change_type(1,'no')" />
                                    <label for="radio-four"
                                        [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">Existing</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Personal Details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Salutation<br>
                                                <select disableOptionCentering class="ipcss inpat_width"
                                                    [(ngModel)]="clnt_sal" (ngModelChange)="changeSalutation($event)">
                                                    <!-- <option value="select" Selected>Select</option> -->
                                                    <option *ngFor="let sal of clnt_sal_list" value="{{sal.sal_id}}">
                                                        {{sal.sal_desc}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">First name<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_fname"
                                                    (blur)="fname_toUpper()" required />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Middle name<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_mname"
                                                    (blur)="mname_toUpper()" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Last name<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_lname"
                                                    (blur)="lname_toUpper()" required matInput />
                                            </mat-label>
                                        </div>
                                        <div *ngIf="mobile_type" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <div class="row">
                                                <div class="col-12" [hidden]="update_btn">
                                                    <mat-label class="matlabel" *ngIf="mobile_type">Mobile no<br>
                                                        <input type="text" class="ipcss walkin_mobilewidth"
                                                            [(ngModel)]="clnt_cont_number"
                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                            maxlength="10" matInput style="width:100%;"
                                                            [readonly]="readonlyFlag" />
                                                    </mat-label>
                                                </div>
                                                <div class="col-10" [hidden]="!update_btn">
                                                    <mat-label class="matlabel" *ngIf="mobile_type">Mobile no<br>
                                                        <input type="text" class="ipcss walkin_mobilewidth"
                                                            [(ngModel)]="clnt_cont_number"
                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                            maxlength="10" matInput style="width:100%;"
                                                            [readonly]="!readonlyFlag" />
                                                    </mat-label>
                                                </div>
                                                <div class="col-2 p-0" [hidden]="!update_btn">
                                                    <mat-label class="matlabel" *ngIf="mobile_type"><br>
                                                        <a (click)="getUserDetails()"><img
                                                                src="../../assets/img/search.png" class="seacrhicon"
                                                                width="25" height="auto" /></a>
                                                    </mat-label>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!mobile_type" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel" *ngIf="!mobile_type">Mobile no<br>
                                                <input type="text" class="ipcss inpat_width" (blur)="validateMobile()"
                                                    [(ngModel)]="clnt_cont_number"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    maxlength="10" required matInput [readonly]="readonlyFlag" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">DOB
                                                <input type="date" class="ipcss_date noappt_width"
                                                    [disabled]="disable_dob" (change)="OnDateChangeDOB(clnt_dob)"
                                                    [(ngModel)]="clnt_dob" max="{{currentDate}}" required #matInput>

                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Age
                                                <input type="text" class="ipcss noappt_width" [disabled]="disable_age"
                                                    (keyup)="ChangeAge()" [(ngModel)]="clnt_age"
                                                    [required]="require_flag" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-label class="matlabel">Blood group<br>
                                                        <input type="text" class="ipcss inpat_width"
                                                            [(ngModel)]="clnt_blood" [disabled]="!user_type" matInput
                                                            [readonly]="readonlyFlag" />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="user_type" class="col-6">
                                                    <mat-label class="matlabel">Gender<br>
                                                        <input type="text" class="ipcss inpat_width"
                                                            [(ngModel)]="clnt_gender" matInput
                                                            [readonly]="readonlyFlag" />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="!user_type" class="col-6">
                                                    <mat-label class="matlabel" [hidden]="!user_type">Gender<br>
                                                        <select class="ipcss inpat_width" [(ngModel)]="clnt_gender"
                                                            [readonly]="readonlyFlag"
                                                            (ngModelChange)="changeGender($event)">
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Transgender">Transgender</option>
                                                        </select>
                                                    </mat-label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Marital status
                                                <!-- <select class="ipcss inpat_width" [(ngModel)]="clnt_marital"
                                                    [readonly]="readonlyFlag">
                                                    <option value="single">Single</option>
                                                    <option value="married">Married</option>
                                                </select> -->
                                                <mat-select disableOptionCentering class="ipcss"
                                                    [(ngModel)]="clnt_marital">
                                                    <mat-option *ngFor="let marstatus of maritalStatusArray" [value]="marstatus">
                                                        {{marstatus}}</mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Care taker<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_fg_name"
                                                    maxlength="50" matInput [readonly]="readonlyFlag" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Emergency contact<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="emer_contact"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    maxlength="10" matInput [readonly]="readonlyFlag" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Occupation<br>
                                                <input type="text" class="ipcss inpat_width"
                                                    [(ngModel)]="clnt_occupation" maxlength="25" matInput
                                                    [readonly]="readonlyFlag" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Id proof type<br>
                                                <select disableOptionCentering class="ipcss inpat_width"
                                                    [(ngModel)]="idProofType"
                                                    (ngModelChange)="changeIdProofType($event)"
                                                    [compareWith]="compareById" [readonly]="readonlyFlag">
                                                    <option [ngValue]=null disabled>Select</option>
                                                    <option *ngFor="let idProof of idProofTypeList"
                                                        [selected]="idProof === idProofType" [ngValue]="idProof">
                                                        {{idProof.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="idProofNoFlag">
                                            <mat-label class="matlabel">{{idProofLabel}} no<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="idProofNo"
                                                    maxlength="15" matInput required />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Income<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_income"
                                                    maxlength="25" matInput [readonly]="readonlyFlag" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Address&nbsp;1<br>
                                                <input type="text" required class="ipcss inpat_width" [(ngModel)]="clnt_address1"
                                                    [disabled]="!user_type" matInput [readonly]="readonlyFlag" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Address&nbsp;2<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="clnt_address2"
                                                    [disabled]="!user_type" matInput [readonly]="readonlyFlag" />
                                            </mat-label>
                                        </div>
                                        
                                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                            <mat-label class="matlabel">Zipcode
                                              <input type="text" class="ipcss widthappt" [(ngModel)]="clnt_zipcode"
                                                (keyup)=zipcode_change() matInput />
                                            </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                            <mat-label class="matlabel">Location<br>
                                              <input type="text" class="ipcss widthappt" required maxlength="50"
                                                [(ngModel)]="clnt_location" (keyup)="changeLocation(clnt_location)" matInput />
                                              <div class="auto_complete_text" *ngIf="clnt_location_list.length != 0">
                                                <ul *ngFor="let location of clnt_location_list">
                                                  <li>
                                                    <a (click)="selectLocation(location)">{{location.description}}</a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </mat-label>
                                          </div>
                  
                                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                            <mat-label class="matlabel">District / City<br>
                                              <input matInput class="ipcss widthappt" [(ngModel)]="clnt_city" [disabled]="userTypeFlag"
                                              [hidden]="!existUserFlag">
                                              <mat-select required class="ipcss widthappt" [(ngModel)]="clnt_city" [hidden]="existUserFlag"
                                                disableOptionCentering (ngModelChange)="changeCity(clnt_city, '0')">
                                                <mat-option *ngFor="let city of clnt_city_list"
                                                  value="{{city.city_desc}}">{{city.city_desc}}
                                                </mat-option>
                                              </mat-select>
                                            </mat-label>
                                          </div>
                  
                                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                            <mat-label class="matlabel">State<br>
                                              <input type="text" matInput class="ipcss widthappt" [(ngModel)]="clnt_state"
                                              [disabled]="userTypeFlag" [hidden]="!existUserFlag">
                                              <mat-select required class="ipcss widthappt" [(ngModel)]="clnt_state" disableOptionCentering
                                                [hidden]="existUserFlag" (ngModelChange)="changeState(clnt_state, '0')">
                                                <mat-option *ngFor="let state of clnt_state_list"
                                                  value={{state.state_desc}}>{{state.state_desc}}
                                                </mat-option>
                                              </mat-select>
                                            </mat-label>
                                          </div>
                  
                                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                            <mat-label class="matlabel">Country<br>
                                              <input type="text" class="ipcss widthappt" [(ngModel)]="clnt_country"
                                              [disabled]="userTypeFlag" [hidden]="!existUserFlag">
                                              <mat-select required [(ngModel)]="clnt_country" class="ipcss widthappt"
                                                [hidden]="existUserFlag" disableOptionCentering>
                                                <mat-option *ngFor="let country of clnt_country_list" value={{country.country_desc}}>
                                                  {{country.country_desc}}</mat-option>
                                              </mat-select>
                                            </mat-label>
                                          </div>

                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Symptoms
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_symptom"
                                                    (keyup)="getSymptoms()" matInput [readonly]="readonlyFlag" />
                                                <div class="auto_complete_text" style="margin-top: 3px !important;">
                                                    <ul *ngFor="let symptom of symptomlist">
                                                        <li>
                                                            <a (click)="select_symptom(symptom)">{{symptom}}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Referred by<br>
                                                <input type="text" class="ipcss inpat_width" maxlength="50"
                                                    [(ngModel)]="refer_txt" (keyup)="getReferralData($event)"
                                                    aria-label="Number" matInput [matAutocomplete]="auto2"
                                                    [readonly]="readonlyFlag" />
                                                <mat-autocomplete #auto2="matAutocomplete">
                                                    <mat-option id="optionfont" *ngFor="let item of Refered_doctor"
                                                        (click)="Select_refer_doctor(item)" value="{{item}}">
                                                        {{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Admission details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Admission date<br>
                                                <input type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="admission_date" matInput />
                                            </mat-label>
                                        </div>

                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Admission time<br></mat-label>
                                            <div class="row">
                                                <div class="col-3" style="padding-right:0;">
                                                    <select type="date" class="ipcss inpat_width"
                                                        [(ngModel)]="admission_time1">
                                                        <option *ngFor="let hrs of dischage_hrs" value="{{hrs}}">{{hrs}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-1"
                                                    style="padding:0;position: relative;top: 5px;left: 8px;"> : </div>
                                                <div class="col-3" style="padding-left:0;">
                                                    <select type="date" class="ipcss inpat_width"
                                                        [(ngModel)]="admission_time2">
                                                        <option *ngFor="let min of dischage_min" value="{{min}}">{{min}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-5">
                                                    <select type="date" class="ipcss inpat_width"
                                                        [(ngModel)]="admission_time3">
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Admission reason
                                                <select type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="admDisReason">
                                                    <option value="Select" disabled>Select</option>
                                                    <option value="At request">At request</option>
                                                    <option value="Referred">Referred</option>
                                                    <option value="Referred">MLC</option>
                                                    <!-- <option value="Expired">Expired</option> -->
                                                </select>
                                            </mat-label>
                                        </div>

                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">MLC case<br>
                                                <select type="date" class="ipcss inpat_width" [(ngModel)]="mlcCase"
                                                    (change)="mlcCaseNoFlag = mlcCase == 'Yes' ? true : false;">
                                                    <option value="select" disabled>select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="mlcCaseNoFlag">
                                            <mat-label class="matlabel">MLC case no<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="mlcCaseNo"
                                                    maxlength="25" matInput required />
                                            </mat-label>
                                        </div>
                                        <!--                                         
                                        <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="!update_btn">
                                            <mat-label class="matlabel">Admission reason
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="admDisReason" maxlength="25" matInput disabled />
                                            </mat-label>
                                        </div> -->
                                        <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Specialization<br>
                                                <select disableOptionCentering class="ipcss inpat_width"
                                                    [(ngModel)]="spl_id" (change)="getDoctorList(spl_id)" required>
                                                    <option Selected>Select</option>
                                                    <option *ngFor="let spl of spl_array" value="{{spl.spl_id}}">{{spl.spl_name}}</option>
                                                </select>
                                            </mat-label>
                                        </div>

                                        <div [hidden]="show_doc" class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                            <mat-label class="matlabel">Doctor<br>
                                                <select class="ipcss widthappt" [(ngModel)]="doc_id" required>
                                                    <option *ngFor="let doctor of docname_list"
                                                        value="{{doctor.prov_id}}">{{doctor.docname}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Consultants<br>
                                                <mat-select class="ipcss widthappt" [(ngModel)]="consult_doc" multiple >
                                                    <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                                                        {{doctor.docname}}</mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div>

                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Mode of payment<br>
                                                <select type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="modeOfPayment" (change)="paymentTypeChange()">
                                                    <option value="Select" disabled>Select</option>
                                                    <option value="Insurance">Insurance</option>
                                                    <option value="Non-Insurance">Non-Insurance</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                                            <mat-label class="matlabel">TPA<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="tpa_name"
                                                    maxlength="50" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                                            <mat-label class="matlabel">Insurance name<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="insuranceName"
                                                    maxlength="25" required matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                                            <mat-label class="matlabel">Card number<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="insCardNo"
                                                    maxlength="25" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                                            <mat-label class="matlabel">Policy number<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="policyNumber"
                                                    maxlength="25" required matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="insure_flag">
                                            <mat-label class="matlabel">CCN number<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="ccnNumber"
                                                    maxlength="25" matInput />
                                            </mat-label>
                                        </div>
                                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Unit<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="unit"
                                                    maxlength="25" matInput />
                                            </mat-label>
                                        </div> -->
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Required surgery<br>
                                                <select type="date" class="ipcss inpat_width" [(ngModel)]="req_surgery"
                                                    (change)="surgeryRequired()">
                                                    <option value="select" disabled>select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Unit<br>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="unit"
                                                    maxlength="25" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Ward<br>
                                                <select disableOptionCentering class="ipcss inpat_width"
                                                    [(ngModel)]="ward_id" [readonly]="readonlyFlag"
                                                    (ngModelChange)="getBedList($event)">
                                                    <!-- <option Selected>Select</option> -->
                                                    <option *ngFor="let ward of ward_list" value="{{ward.ward_id}}">
                                                        {{ward.ward_name}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                                            style="width: fit-content;">
                                            <mat-label class="matlabel">Bed number<br></mat-label>
                                            <div class="btn-group">
                                                <input [disabled]="!show_bed_details" type="button"
                                                    style="width: 210px !important;border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important;text-align: left;"
                                                    class="btn-secondary" [(ngModel)]="bed_no" />
                                                <button [disabled]="!show_bed_details" type="button"
                                                    style="width: 20px !important;border-top-right-radius: 4px !important;border-bottom-right-radius: 4px !important;"
                                                    class="btn-secondary dropdown-toggle dropdown-toggle-split"
                                                    id="dropdownMenuReference" data-bs-toggle="dropdown"
                                                    aria-expanded="false" data-bs-reference="parent">
                                                    <span class="visually-hidden">Toggle Dropdown</span>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference"
                                                    style="padding: 0;">
                                                    <li *ngIf="this.bedList.length !=0" style="border: 1px solid #b5afaf;">
                                                        <div class="container">
                                                            <div class="row" style=" width: 550px; height: auto;" *ngIf="bedList.length >= 2">
                                                                <div class="col-3 p-0" *ngFor="let bed of bedList">
                                                                    <div (click)="changeBed(bed)"
                                                                        style="text-align: center;">
                                                                        <div class="bed_bed_no">{{bed.bed_no}}</div>
                                                                        <div><img src="{{bed.bed_img}}" class="bed_img">
                                                                        </div>
                                                                        <div class="bed_bed_name d-flex justify-content-center"
                                                                            [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                                                            {{bed.pat_name}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row" style="width: 300px; height: auto;"
                                                                *ngIf="bedList.length < 2">
                                                                <div class="col-6 p-0" *ngFor="let bed of bedList">
                                                                    <div (click)="changeBed(bed)"
                                                                        style="text-align: center;">
                                                                        <div class="bed_bed_no">{{bed.bed_no}}</div>
                                                                        <div><img src="{{bed.bed_img}}" class="bed_img">
                                                                        </div>
                                                                        <div class="bed_bed_name d-flex justify-content-center"
                                                                            [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                                                            {{bed.pat_name}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-group">
                                                <button [hidden]="!show_bednos" type="button"
                                                    style="width: 30px !important;border:none !important;"
                                                    id="dropdownMenuReference" data-bs-toggle="dropdown"
                                                    aria-expanded="false" data-bs-reference="parent">
                                                    <img src="../../../assets/ui_icons/bed_icon.svg" class="bedbutton" />
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference"
                                                    style="padding: 0;">
                                                    <li *ngIf="this.bedOccList.length !=0"
                                                        style="border: 1px solid #b5afaf;">
                                                        <div class="container">
                                                            <div class="row" style=" width: 550px; height: auto;">
                                                                <table class="table table-hover table-dynamic" id="tbl">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Ward</th>
                                                                            <th>Bed no</th>
                                                                            <th>Allotted on</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let bed of bedOccList">
                                                                            <td class="test">
                                                                                <span [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.ward}}</span>
                                                                            </td>
                                                                            <td style="font-size: 12px;">
                                                                                <span [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.bedno}}</span>
                                                                            </td>
                                                                            <td style="font-size: 12px;">
                                                                                <span [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.date}}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 " style="height: 150px !important;">
                                                <div class="row">
                                                    <div class="col-12 margin_10">
                                                        <mat-label class="matlabel"><b
                                                                class="label_bold">Investigation</b></mat-label>
                                                    </div>
                                                    <div class="col-12">
                                                        <quill-editor id="editor1" [(ngModel)]="inp_inv"
                                                            [styles]="{height: 'calc(40vh - 100px)'}" [modules]="modules"
                                                            placeholder="Enter investigation"
                                                            (onContentChanged)="changeInvest($event)"></quill-editor>
                                                        <div>
                                                            <!-- Preview -->
                                                            <quill-view-html hidden id="viewhtml1"
                                                                [content]="inp_inv"></quill-view-html>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                                <div class="row">
                                                    <div class="col-12 margin_10">
                                                        <mat-label class="matlabel">
                                                            <b class="label_bold">Diagnosis</b></mat-label>
                                                    </div>
                                                    <div class="col-12">
                                                        <quill-editor id="editor1" [(ngModel)]="surg_diagosis"  [styles]="{height: 'calc(40vh - 100px)'}" [modules]="modules"
                                                            placeholder="Enter Text" (onContentChanged)="changeDiagosis($event)">
                                                        </quill-editor>
                                                      <div >
                                                        <!-- Preview -->
                                                        <quill-view-html hidden id="viewhtml1"
                                                            [content]="surg_diagosis"></quill-view-html>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" [hidden]="req_sur_flag">
                        <div class="cover_div">
                            <div class="header_lable">
                                Surgery details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Surgery<br>
                                            <select class="ipcss widthappt" [(ngModel)]="surgery_id">
                                                <option *ngFor="let surgery of surgery_list" value="{{surgery.surgery_id}}">
                                                    {{surgery.surgery_name}}
                                                </option>
                                            </select>
                                        </mat-label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Surgery date<br>
                                            <input type="date" class="ipcss inpat_width" [(ngModel)]="surgery_date"
                                                min="{{currentDate}}" matInput />
                                        </mat-label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Surgery time<br></mat-label>
                                        <div class="row">
                                            <div class="col-3" style="padding-right:0;">
                                                <select type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="surgery_time1">
                                                    <option *ngFor="let hrs of dischage_hrs" value="{{hrs}}">{{hrs}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                                                : </div>
                                            <div class="col-3" style="padding-left:0;">
                                                <select type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="surgery_time2">
                                                    <option *ngFor="let min of dischage_min" value="{{min}}">{{min}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-5">
                                                <select type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="surgery_time3">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Surgeon<br>
                                            <mat-select class="ipcss widthappt" [(ngModel)]="surg_doc" multiple >
                                                <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                                                    {{doctor.docname}}</mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                            <mat-label class="matlabel">Surgery procedure
                                                <textarea class="ipcss inpat_width" [(ngModel)]="surgery_proc"
                                                    matInput></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="cover_div">
                            <div class="header_lable">Vitals</div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-3">
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-label class="matlabel">Height<br>
                                                    <input matInput type="text" class="ipcss inpat_width"
                                                        (change)="calories_data()" maxlength="6" [(ngModel)]="height_txt" (keydown)=(validate($event))>
                                                </mat-label>
                                            </div>
                                            <div class="col-6">
                                                <mat-label class="matlabel">Measure<br>
                                                    <select class="ipcss inpat_width" (change)="calories_data()"
                                                        [(ngModel)]="hmeasure" disableOptionCentering>
                                                        <option Selected value="cms">cms</option>
                                                        <option value="inch">inch</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-label class="matlabel">Weight<br>
                                                    <input type="text" matInput class="ipcss inpat_width"
                                                        (change)="calories_data()" maxlength="6"
                                                        [(ngModel)]="weight_txt" (keydown)=(validate($event))>
                                                </mat-label>
                                            </div>
                                            <div class="col-6">
                                                <mat-label class="matlabel">Measure<br>
                                                    <select class="ipcss inpat_width" (change)="calories_data()"
                                                        [(ngModel)]="wmeasure" disableOptionCentering>
                                                        <option Selected value="kgs">kgs</option>
                                                        <option value="lbs">lbs</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-label class="matlabel">BMI<br>
                                                    <input matInput class="ipcss inpat_width" [(ngModel)]="bmi_txt" disabled>
                                                </mat-label>
                                            </div>
                                            <div class="col-6">
                                                <mat-label class="matlabel">Temperature<br>
                                                    <input matInput class="ipcss inpat_width" [(ngModel)]="temp_txt"
                                                        maxlength="5"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </mat-label> 
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                        <mat-label class="matlabel">Blood pressure</mat-label>
                                        <div class="row">
                                            <div class="col-6">
                                                <input class="ipcss inpat_width" [disabled]="blood_disabled"
                                                    placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_01"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    matInput>
                                            </div>
                                            <div class="col-6" style="position: relative;">
                                                <span class="slash_icon">/</span>
                                                <input matInput class="ipcss inpat_width" [disabled]="blood_disabled"
                                                    placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_02"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    matInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                        <div class="row">
                                            <div class="col-2">
                                                <mat-label class="matlabel">Saturation(SPO2)</mat-label>
                                                <input matInput class="ipcss inpat_width" [(ngModel)]="spo_txt">
                                            </div>
                                            <div class="col-2" >
                                                <mat-label class="matlabel">Pulse</mat-label>
                                                <input matInput class="ipcss inpat_width" [(ngModel)]="pulse_txt">
                                            </div>
                                            <div [hidden]="cvs_hidden" class="col-2">
                                                <mat-label class="matlabel">CVS<br>
                                                    <input matInput class="ipcss " placeholder="cvs" [(ngModel)]="cvs_txt">
                                                </mat-label>
                                            </div>
                                            <div [hidden]="rs_hidden" class="col-2">
                                                <mat-label class="matlabel">RS<br>
                                                    <input matInput class="ipcss " placeholder="rs" [(ngModel)]="rstxt">
                                                </mat-label>
                                            </div>
                                            <div [hidden]="cns_hidden" class="col-2">
                                                <mat-label class="matlabel">CNS<br>
                                                    <input matInput class="ipcss " placeholder="cns" [(ngModel)]="cns_txt">
                                                </mat-label>
                                            </div>
                                            <div [hidden]="abd_hidden" class="col-2">
                                                <mat-label class="matlabel">ABD<br>
                                                    <input matInput class="ipcss " placeholder="ABD" [(ngModel)]="abd_txt">
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div [hidden]="pr_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">P/R<br>
                                            <input matInput class="ipcss " placeholder="p/r" [(ngModel)]="pr_txt">
                                        </mat-label>
                                    </div> -->
                                    <!-- <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel"><b>COVID-19 symptoms?</b>
                                                <select disableOptionCentering class="ipcss inpat_width"
                                                    [(ngModel)]="covid_type" (ngModelChange)="covidtypeSelect($event)">
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div [hidden]="!covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Duration
                                                <input type="text" class="ipcss inpat_width"
                                                    [(ngModel)]="covid_duration" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="!covid_flag">
                                            <mat-label class="matlabel">Saturation (SPO2)</mat-label>
                                            <input matInput class="ipcss inpat_width" [(ngModel)]="spo_txt">
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="!covid_flag">
                                            <mat-label class="matlabel">Pulse</mat-label>
                                            <input matInput class="ipcss inpat_width" [(ngModel)]="pulse_txt">
                                        </div>
                                        <div [hidden]="!covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel"><b>Symptoms</b></mat-label>
                                            <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;">
                                                <table style="margin-top: -5px;">
                                                    <tr *ngFor="let symptoms of covidSympList">
                                                        <td class="test">
                                                            <mat-checkbox color="primary"
                                                                (change)="symptom_click($event.checked,symptoms.symp_id)"
                                                                [(ngModel)]="symptoms.checked"></mat-checkbox>
                                                        </td>
                                                        <td style="text-align: left !important;font-size: 12px;">
                                                            <span>{{symptoms.symp_desc}}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div [hidden]="!covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel"><b>Comorbities</b></mat-label>
                                            <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;">
                                                <table style="margin-top: -5px;">
                                                    <tr *ngFor="let comorb of comorbList">
                                                        <td class="test">
                                                            <mat-checkbox color="primary"
                                                                (change)="comorb_click($event.checked,comorb.comorb_id)"
                                                                [(ngModel)]="comorb.checked"></mat-checkbox>
                                                        </td>
                                                        <td style="text-align: left !important;font-size: 12px;">
                                                            <span>{{comorb.comorb_desc}}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="!covid_flag">
                                            <mat-label class="matlabel">Respiratory rate</mat-label>
                                            <input matInput class="ipcss inpat_width" [(ngModel)]="resp_txt">
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="!covid_flag">
                                            <mat-label class="matlabel">CT Scan report</mat-label>
                                            <div class="row">
                                                <div [hidden]="blood_preshidden"
                                                    class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                                    <input matInput class="ipcss inpat_width"
                                                        [disabled]="blood_disabled" placeholder="0-25" maxlength="3"
                                                        [(ngModel)]="scan_01"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                </div>
                                                <div [hidden]="blood_preshidden" class="Icon_col col-1">
                                                    <p class="blood_para">/</p>
                                                </div>
                                                <div [hidden]="blood_preshidden"
                                                    class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                                    <select class="ipcss" [(ngModel)]="scan_02">
                                                        <option value="5">5</option>
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="20">20</option>
                                                        <option value="25">25</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="!covid_flag">
                                            <mat-label class="matlabel">RT-PCR result</mat-label>
                                            <select class="ipcss" [(ngModel)]="rtpcr_result">
                                                <option>Negative</option>
                                                <option>Positive</option>
                                            </select>
                                        </div>
                                        <div [hidden]="!covid_flag" class="col-12">
                                            <img src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="backimgbtn_inpatinfo" (click)="addObservations()"
                                                style="float: right;" />
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div class="table-responsive" style="border: none;"
                                                    [hidden]="observetable">
                                                    <table *ngIf="observList.length != 0" id="card_tbl"
                                                        class="table table-hover table-dynamic" cellpadding="5"
                                                        cellspacing="0">
                                                        <tr>
                                                            <th>Temperature</th>
                                                            <th>Blood pressure</th>
                                                            <th>Saturation</th>
                                                            <th>pulse</th>
                                                            <th>Respiratory rate</th>
                                                            <th>RT-PCR result</th>
                                                        </tr>
                                                        <tr *ngFor="let observ of observList">
                                                            <td>{{observ.temp}}</td>
                                                            <td>{{observ.bp}}</td>
                                                            <td>{{observ.sop2}}</td>
                                                            <td>{{observ.pulse}}</td>
                                                            <td>{{observ.resprate}}</td>
                                                            <td>{{observ.rtpcr}}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="cover_div">
                            <div class="header_lable">
                                Observation
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <div class="row">
                                            <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                    <b class="label_bold">Chief complaints</b>
                                                </mat-label>
                                            </div>
                                            <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="chief_comp" [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                                                    placeholder="Enter Text" (onContentChanged)="changeChiefComp($event)"></quill-editor>
                                                <div>
                                                    <!-- Preview -->
                                                    <quill-view-html hidden id="chiefcomp1"
                                                        [content]="chief_comp"></quill-view-html>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <div class="row">
                                            <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                    <b class="label_bold">Present illness</b>
                                                </mat-label>
                                            </div>
                                            <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="pres_ill" [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                                                    placeholder="Enter Text" (onContentChanged)="changePresIllness($event)"></quill-editor>
                                                <div>
                                                    <!-- Preview -->
                                                    <quill-view-html hidden id="presill1"
                                                        [content]="pres_ill"></quill-view-html>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                                        <div class="row">
                                            <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                    <b class="label_bold">Past medical history</b>
                                                </mat-label>
                                            </div>
                                            <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="med_hist" [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                                                    placeholder="Enter past medical history" (onContentChanged)="changeMedicalHistory($event)"></quill-editor>
                                                <div>
                                                    <!-- Preview -->
                                                    <quill-view-html hidden id="medhist1"
                                                        [content]="med_hist"></quill-view-html>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                                        <div class="row">
                                            <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                    <b class="label_bold">Family history</b>
                                                </mat-label>
                                            </div>
                                            <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="family_hist" [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                                                    placeholder="Enter family history" (onContentChanged)="changeFamilyHistory($event)"></quill-editor>
                                                <div>
                                                    <!-- Preview -->
                                                    <quill-view-html hidden id="famhist1"
                                                        [content]="family_hist"></quill-view-html>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                                        <div class="row">
                                            <div class="col-12 margin_10">
                                                <mat-label class="matlabel">
                                                    <b class="label_bold">Physical examination</b>
                                                </mat-label>
                                            </div>
                                            <div class="col-12">
                                                <quill-editor id="editor1" [(ngModel)]="physical_exam" [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                                                    placeholder="Enter physical examination" (onContentChanged)="changePhysicalExam($event)"></quill-editor>
                                                <div>
                                                    <!-- Preview -->
                                                    <quill-view-html hidden id="medhist1"
                                                        [content]="physical_exam"></quill-view-html>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div [hidden]="covid_flag" class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <mat-label class="matlabel">Chief complaints
                                            <textarea class="ipcss inpat_width" [(ngModel)]="chief_comp" matInput></textarea>
                                        </mat-label>
                                    </div> 
                                    <div [hidden]="covid_flag" class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <mat-label class="matlabel">Present illness
                                            <textarea class="ipcss inpat_width" [(ngModel)]="pres_ill" matInput></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <mat-label class="matlabel">Past medical history
                                            <textarea class="ipcss inpat_width" [(ngModel)]="med_hist" matInput></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <mat-label class="matlabel">Family history
                                            <textarea class="ipcss inpat_width" [(ngModel)]="family_hist" matInput></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <mat-label class="matlabel">Physical examination
                                            <textarea class="ipcss inpat_width" [(ngModel)]="physical_exam" matInput></textarea>
                                        </mat-label>
                                    </div>-->
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Pre-hospitalization</mat-label>
                                                <select class="ipcss" [(ngModel)]="pre_hosp">
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                                <mat-label class="matlabel">Hospital details
                                                    <textarea class="ipcss inpat_width" maxlength="250"
                                                        [(ngModel)]="pre_hospital_details" matInput></textarea>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Smoking
                                            <select class="ipcss inpat_width" [(ngModel)]="inp_smoke">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </mat-label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Drinking alcohol
                                            <select class="ipcss inpat_width" [(ngModel)]="inp_alcohol">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                                        <div class="row">
                                            <div [hidden]="covid_flag"
                                                class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 ">
                                                <mat-label class="matlabel">Taking any medication
                                                    <select class="ipcss inpat_width" [(ngModel)]="medication"
                                                        (change)="medicationChange()">
                                                        <option value="Select" disabled>Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-8 col-xl-8"
                                                [hidden]="medication_flag">
                                                <mat-label class="matlabel">Medication details<br>
                                                    <input type="text" class="ipcss inpat_width"
                                                        [(ngModel)]="med_details" [required]="med_requed"
                                                        maxlength="150" matInput />
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-12" style="margin-top: 5px !important;" style="margin-top: 10px;">
                                        <div class="row">
                                            <div class="col-2">
                                                <mat-label class="matlabel" style="font-weight: 600;">Allergies :
                                                </mat-label>
                                            </div>
                                            <div class="col-6">
                                                <div class="switch-field" *ngIf="ynopt2 != undefined">
                                                    <input type="radio" id="radio-one"
                                                        (click)="changeAllery('main',1,'yes')" />
                                                    <label for="radio-one"
                                                        [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">Yes</label>
                                                    <input type="radio" id="radio-two"
                                                        (click)="changeAllery('main',1,'no')" />
                                                    <label for="radio-two"
                                                        [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" style="margin-top: 5px !important;">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 "
                                                style="margin: 27px 0 0px 0;" *ngIf="allergyFlag">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <mat-label class="matlabel" style="font-weight: 600;">Drug
                                                            allergy : </mat-label>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="switch-field" *ngIf="ynopt3 != undefined">
                                                            <input type="radio" id="radio-five"
                                                                (click)="changeAllery('drug',1,'yes')" />
                                                            <label for="radio-five"
                                                                [ngClass]="ynopt3 ? 'radio_active':'radio_inactive'">Yes</label>
                                                            <input type="radio" id="radio-six"
                                                                (click)="changeAllery('drug',1,'no')" />
                                                            <label for="radio-six"
                                                                [ngClass]="!ynopt3 ? 'radio_active':'radio_inactive'">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                                style="margin: 15px 0 15px 0;" *ngIf="drugDetailsFlag">
                                                <mat-label class="matlabel">Details
                                                    <textarea class="ipcss inpat_width" maxlength="250"
                                                        [(ngModel)]="drug_details" matInput></textarea>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 "
                                                style="margin: 27px 0 0px 0;" *ngIf="allergyFlag">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <mat-label class="matlabel" style="font-weight: 600;">
                                                            Environmental allergy : </mat-label>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="switch-field" *ngIf="ynopt4 != undefined">
                                                            <input type="radio" id="radio-seven"
                                                                (click)="changeAllery('evn',1,'yes')" />
                                                            <label for="radio-seven"
                                                                [ngClass]="ynopt4 ? 'radio_active':'radio_inactive'">Yes</label>
                                                            <input type="radio" id="radio-eight"
                                                                (click)="changeAllery('evn',1,'no')" />
                                                            <label for="radio-eight"
                                                                [ngClass]="!ynopt4 ? 'radio_active':'radio_inactive'">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                                style="margin: 15px 0 15px 0;" *ngIf="engDetailsFlag && allergyFlag">
                                                <mat-label class="matlabel">Details
                                                    <textarea class="ipcss inpat_width" maxlength="250"
                                                        [(ngModel)]="env_details" matInput></textarea>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 "
                                                style="margin: 27px 0 0px 0;" *ngIf="allergyFlag">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <mat-label class="matlabel" style="font-weight: 600;">Food
                                                            allergy : </mat-label>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="switch-field" *ngIf="ynopt5 != undefined">
                                                            <input type="radio" id="radio-nine"
                                                                (click)="changeAllery('food',1,'yes')" />
                                                            <label for="radio-nine"
                                                                [ngClass]="ynopt5 ? 'radio_active':'radio_inactive'">Yes</label>
                                                            <input type="radio" id="radio-ten"
                                                                (click)="changeAllery('food',1,'no')" />
                                                            <label for="radio-ten"
                                                                [ngClass]="!ynopt5 ? 'radio_active':'radio_inactive'">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                                style="margin: 15px 0 15px 0;" *ngIf="foodDetailsFlag && allergyFlag">
                                                <mat-label class="matlabel">Details
                                                    <textarea class="ipcss inpat_width" maxlength="250"
                                                        [(ngModel)]="food_details" matInput></textarea>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" [hidden]="req_sur_flag">
                        <div class="cover_div">
                            <div class="header_lable">
                                Anesthesia
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Anaestetist
                                            <mat-select class="ipcss widthappt" [(ngModel)]="anaest_doc" multiple >
                                                <mat-option *ngFor="let doctor of anaest_doctors" value="{{doctor.doc_reg_id}}">
                                                    {{doctor.name}}</mat-option>
                                            </mat-select>
                                            <!-- <select class="ipcss inpat_width" [(ngModel)]="anaest_doc">
                                                <option *ngFor="let doctor of anaest_doctors"
                                                    value="{{doctor.doc_reg_id}}">
                                                    {{doctor.name}}</option>
                                            </select> -->
                                        </mat-label>
                                    </div>
                                    <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Anaestesia type
                                            <select class="ipcss inpat_width" [(ngModel)]="anaesttype">
                                                <option *ngFor="let type of anaest_types" value="{{type.type_id}}">
                                                    {{type.description}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <mat-label class="matlabel">Procedure
                                            <textarea class="ipcss inpat_width1" [(ngModel)]="anaest_procedure"
                                                matInput></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                                        <mat-label class="matlabel">Other findings
                                            <textarea class="ipcss inpat_width1" [(ngModel)]="anaest_findings"
                                                matInput></textarea>
                                        </mat-label>
                                    </div>
                                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" >
                                            <mat-label class="matlabel">Side effects<br>
                                                <select class="ipcss inpat_width" [(ngModel)]="anaest_effect" >
                                                    <option *ngFor="let effect of anaest_effects"
                                                        value="{{effect.side_effect_id}}">
                                                        {{effect.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="!update_btn">
                        <div class="cover_div">
                            <div class="header_lable">
                                Discharge details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Discharge date<br>
                                            <input type="date" class="ipcss inpat_width" [(ngModel)]="discharge_date"
                                                min="{{currentDate}}" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Discharge time<br></mat-label>
                                        <div class="row">
                                            <div class="col-3" style="padding-right:0;">
                                                <select type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="discharge_time1">
                                                    <option *ngFor="let hrs of dischage_hrs" value="{{hrs}}">{{hrs}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                                                : </div>
                                            <div class="col-3" style="padding-left:0;">
                                                <select type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="discharge_time2">
                                                    <option *ngFor="let min of dischage_min" value="{{min}}">{{min}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-5">
                                                <select type="date" class="ipcss inpat_width"
                                                    [(ngModel)]="discharge_time3">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div [hidden]="covid_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Discharge reason
                                            <select type="date" class="ipcss inpat_width" [(ngModel)]="disReason">
                                                <option value="Select" disabled>Select</option>
                                                <option value="Cured">Cured</option>
                                                <option value="Improved">Improved</option>
                                                <option value="Unchanged">Unchanged</option>
                                                <option value="AMA">AMA</option>
                                                <option value="Expired">Expired</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" [hidden]="update_btn">
                        <div class="row" style="margin-top: 18px;margin-left: 1px;">
                            <div class="col-12 tervys_heading"> Doctor visitings </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <div class="row" style="border: 1px solid #66b9eb;
                                            border-radius: 15px;
                                            padding: 13px 2px;">
                                    <div class="col-12" [hidden]="non_inpatient_flow">
                                        <mat-label class="matlabel">Doctor name<br>
                                            <input type="text" class="ipcss inpat_width" [(ngModel)]="visiting_doc_name"
                                                matInput readonly />
                                        </mat-label>
                                    </div>
                                    <div class="col-12" [hidden]="!non_inpatient_flow">
                                        <mat-label class="matlabel">Doctor name<br>
                                            <input type="text" class="ipcss inpat_width" maxlength="50"
                                                [(ngModel)]="visiting_doc_name" (keyup)="getVisitingDoc($event)"
                                                aria-label="Number" matInput [matAutocomplete]="auto3"
                                                [readonly]="readonlyFlag" />
                                            <mat-autocomplete #auto3="matAutocomplete">
                                                <mat-option id="optionfont" *ngFor="let item of visiting_doctor"
                                                    (click)="Select_visiting_doctor(item.id)" value="{{item.name}}">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">Billable</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="stroke1"
                                            ngDefaultControl [checked]="stroke1"
                                            (change)="changestroke($event.source.checked)">
                                            <span class="familytab" *ngIf="!stroke1"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="stroke1"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-12">
                                        <mat-label class="matlabel">Remarks
                                            <textarea class="ipcss inpat_width" maxlength="250"
                                                [(ngModel)]="visiting_remarks" matInput></textarea>
                                        </mat-label>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 my-auto">
                                <mat-label class="matlabel"></mat-label>
                                <img (click)="addVisiting()" src="../../../assets/ui_icons/arrow_green.svg"
                                    class="arrow_imageprescript" />
                            </div>
                            <!-- bill create -->
                            <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8">
                                <div class="dig_table_overflow">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-dynamic" id="tbl">
                                            <thead>
                                                <tr>
                                                    <th style="width: 30%;">Doctor Name</th>
                                                    <th style="width: 15%;">Visiting date</th>
                                                    <th style="width: 15%;">Visiting Time</th>
                                                    <th style="width: 10%;">Billable</th>
                                                    <th style="width: 30%;">Remarks</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let person of visitignArrray; let i = index">
                                                    <td style="font-size: 12px;text-align: left !important;">{{
                                                        person.visiting_doc_name }}</td>
                                                    <td style="font-size: 12px;">{{ person.date }}</td>
                                                    <td style="font-size: 12px;">{{ person.time }}</td>
                                                    <td style="font-size: 12px;">{{ person.billable }}</td>
                                                    <td style="font-size: 12px;text-align: left !important;">{{
                                                        person.remarks }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
    </div>
    <!-- print -->
    <div [hidden]="true">
        <div id="inpatientInfoPrint">
            <div>
                <table style="width: 100%;">
                    <tr>
                        <td style="text-align: center;">
                            <p style="font-size: 24px;"><strong>{{hospital_name}}</strong></p>
                            <p>{{hospitalAddress}} {{Hospital_location}}</p>
                            <p>Tel: {{hosp_tel}}</p>
                        </td>
                    </tr>
                </table>
            </div>
            <div style="margin-left: 50px;">
                <p style="width: 211px !important;
                            padding: 1px !important;
                            background: #eaeaec !important;
                            display: inline-flex !important;
                            position: relative !important;
                            padding-left: 5px !important;
                            border: 1px solid #b5b5b6;
                            top: 16px !important;"><strong>Personal information </strong></p>
            </div>
            <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
                <table style="margin-top: 5px; text-align: left !important;">
                    <tr>
                        <td class="align_left"><strong>UHID : </strong>{{inpatientId}}</td>
                        <td class="align_left"><strong>Inpatient-Id : </strong>{{inpatId}}</td>
                    </tr>
                    <tr style="background-color: #fff;">
                        <td class="align_left"><strong>Patient Name : </strong>{{clnt_saldesc}} {{patient_name}}</td>
                        <td class="align_left"><strong>Date :</strong> {{admission_date | date:
                            'dd-MM-yyyy'}}
                            &nbsp;&nbsp;<strong>Time :</strong> {{admission_time}}
                        </td>
                    </tr>
                    <tr>
                        <td class="align_left"><strong>Doctor :</strong> {{doctor_name}}</td>
                        <td class="align_left"><strong>Symptoms : </strong>{{clnt_symptom}}</td>
                    </tr>
                    <tr>
                        <td class="align_left"><strong>Id proof type :</strong> {{idProofNo}} /
                            {{idProofLabel}}</td>
                        <td class="align_left"><strong>Unit / Ward / Bed No : </strong> {{unit}} /
                            {{wardName}} / {{bed_no}}</td>
                    </tr>
                    <tr>
                        <td class="align_left"><strong>Discharge Date & Time :</strong> <span
                                *ngIF="disPrint != undfined">{{disPrint}} {{timePrint}}</span> </td>
                        <td class="align_left"><strong>MLC case : </strong>{{mlcCase}}
                            <span *ngIf="mlcCaseNoFlag"><strong>MLC case No: </strong> {{mlcCaseNo}} </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="align_left"><strong>Mode of Payment : </strong>{{modeOfPayment}}</td>
                        <td class="align_left"><strong>Admission / Discharge reason :</strong>
                            {{admDisReason}}</td>
                    </tr>
                    <tr>
                        <td class="align_left"><strong>Age : </strong>{{clnt_age}}</td>
                        <td class="align_left"><strong>Marital status :</strong> {{clnt_marital}}</td>
                    </tr>
                    <tr style="background-color: #fff;">
                        <td class="align_left"><strong>Gender: </strong>{{clnt_gender}}</td>
                        <td class="align_left"><strong>Blood group :</strong> {{clnt_blood}}</td>
                    </tr>
                    <tr>
                        <td class="align_left"><strong>Occupation : </strong>{{clnt_occupation}}</td>
                        <td class="align_left"><strong>Income : </strong>{{clnt_income}}</td>
                    </tr>
                    <tr>
                        <td class="align_left"><strong>Emergency contact:</strong> {{emer_contact}}</td>
                        <td class="align_left"><strong>Referred by : </strong>{{refer_txt}}</td>
                    </tr>
                    <tr>
                        <td class="align_left"><strong>Care taker:</strong> {{clnt_fg_name}}</td>
                        <td class="align_left"><strong>Address :</strong> {{hosp_addr}}, {{hosp_csz}}</td>
                    </tr>
                </table>
            </div>

            <div style="margin-left: 50px;" *ngIf="vitalsFlag">
                <p style="width: 211px !important;
                            padding: 1px !important;
                            background: #eaeaec !important;
                            display: inline-flex !important;
                            position: relative !important;
                            padding-left: 5px !important;
                            border: 1px solid #b5b5b6;
                            top: 16px !important;"><strong>Vitals </strong></p>
            </div>
            <div *ngIf="vitalsFlag" style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;">
                    <div *ngIf="this.height_txt == undefined && this.height_txt == ''" class="vitalLable"
                        style="display: inline-flex !important;width: 47%;padding: 0 12px;"><strong>Height:
                        </strong> {{height_txt}} {{hmeasure}} </div>
                    <div *ngIf="this.weight_txt == undefined && this.weight_txt == ''" class="vitalLable"
                        style="display: inline-flex !important;width: 47%;padding: 0 12px;"><strong>Weight :
                        </strong>{{weight_txt}} {{wmeasure}} </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;">
                    <div *ngIf="this.bmi_txt == undefined && this.bmi_txt == ''" class="vitalLable"
                        style="display: inline-flex !important;width: 47%;padding: 0 12px;"><strong>BMI :
                        </strong> {{bmi_txt}} </div>
                    <div *ngIf="this.temp_txt == undefined && this.temp_txt == ''" class="vitalLable"
                        style="display: inline-flex !important;width: 47%;padding: 0 12px;">
                        <strong>Temperature : </strong>{{temp_txt}}
                    </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="this.bp_txt == undefined && this.bp_txt == ''">
                    <div class="vitalLable" style="display: inline-flex !important;width: 47%;padding: 0 12px;">
                        <strong>Blood
                            pressure : </strong> {{bp_txt}} </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>COVID-19 symptoms : </strong> {{covid_type}}
                    </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Duration : </strong> {{covid_duration}}
                    </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Symptoms : </strong> {{coviedSytemmsText}}
                    </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Comorbities : </strong> {{comorb_desc}}
                    </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;" *ngIf="covid_flag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <table *ngIf="observList.length != 0" id="" class="table table-hover table-dynamic"
                            cellpadding="5" cellspacing="0"
                            style="border: 1px solid #000; width: 95%; margin: 10px auto;">
                            <tr>
                                <th>Temperature</th>
                                <th>Blood pressure</th>
                                <th>Saturation</th>
                                <th>pulse</th>
                                <th>Respiratory rate</th>
                                <th>RT-PCR result</th>
                            </tr>
                            <tr *ngFor="let observ of observList">
                                <td>{{observ.temp}}</td>
                                <td>{{observ.bp}}</td>
                                <td>{{observ.sop2}}</td>
                                <td>{{observ.pulse}}</td>
                                <td>{{observ.resprate}}</td>
                                <td>{{observ.rtpcr}}</td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
            <div *ngIf="observationFlag" style="margin-left: 50px;">
                <p style="width: 211px !important;
                            padding: 1px !important;
                            background: #eaeaec !important;
                            display: inline-flex !important;
                            position: relative !important;
                            padding-left: 5px !important;
                            border: 1px solid #b5b5b6;
                            top: 16px !important;"><strong>Observation </strong></p>
            </div>
            <div *ngIf="observationFlag" style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;"
                [hidden]="covid_flag">
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;">
                    <div *ngIf="this.chief_comp == undefined && this.chief_comp == ''" class="vitalLable"
                        style="display: inline-flex !important;width: 47%;padding: 0 12px;"><strong>Chief
                            complaints : </strong> {{chief_comp}}</div>
                    <div *ngIf="this.pres_ill == undefined && this.pres_ill == ''" class="vitalLable"
                        style="display: inline-flex !important;width: 47%;padding: 0 12px;"><strong>Present
                            illness : </strong>{{pres_ill}}</div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="this.med_hist == undefined && this.med_hist == ''">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Past
                            medical history : </strong> {{med_hist}} </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="this.family_hist == undefined && this.family_hist == ''">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Family
                            history : </strong> {{family_hist}} </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="this.physical_exam == undefined && this.physical_exam == ''">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Physical examination : </strong> {{physical_exam}}
                    </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="preHospFlag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Hospital details : </strong> {{pre_hospital_details}}
                    </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="allergyFlag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Alergy
                            details : </strong></div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="allergyFlag && drugDetailsFlag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Drug
                            Details : </strong> {{drug_details}} </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="allergyFlag && engDetailsFlag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Environmental Details : </strong> {{env_details}}
                    </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="allergyFlag && foodDetailsFlag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Food Details : </strong> {{food_details}} </div>
                </div>
                <div class="rowCover" style="display: inline-flex !important;width:100% !important;"
                    *ngIf="allergyFlag && foodDetailsFlag">
                    <div class="vitalLable" style="display: inline-flex !important;width: 100%;padding: 0 12px;">
                        <strong>Food Details : </strong> {{food_details}} </div>
                </div>
            </div>
        </div>
        <h3 class="heading">personaldetails</h3>

        <div #idprint id="idprint">
            <div class="container-fluid m-5">
              <div class="row">
                <div class="col-6"
                  style="width: 300px;border-style: solid;border-width: thin;
                    border-radius: 10px;border-color: rgb(139, 139, 251);
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                  <div class="justify-content-center mt-2" style="text-align: center">
                    <h1 style="font-size: 16px;letter-spacing: 4px;color: rgb(139, 139, 251);">
                      {{ patient_name }}
                    </h1>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-center mt-2" style="letter-spacing: 1px"> 
                    <!--align-items-center justify-content-center-->
                    <div>
                      <table>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            Inpatient Id
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ inpatId }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            UHID
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ inpatientId }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            Age/Gender
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ clnt_age }}/{{ clnt_gender }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            Mobile No
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ clnt_cont_number }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            D.O.A & Time
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ admission_date | date : "dd-MM-yyyy" }}
                            {{ admission_time_full }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="font-size: 11px;
                              font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            D.O.S & Time
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            :
                          </td>
                          <td style="font-size: 11px;font-family: Verdana, Geneva, Tahoma, sans-serif;">
                            {{ surgery_date | date : "dd-MM-yyyy" }}                   
                            {{ surgery_time_full }}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      </table>                     
                    </div>                     
                  </div>
                  <div style="margin: 15px" class="d-flex align-items-center justify-content-center">
                    <ngx-barcode class="barcode"
                      [bc-value]="inpatId"
                      [bc-display-value]="displayValue"
                      [bc-line-color]="lineColor"
                      [element-type]="elementType"
                      [format]="format"
                      [bc-width]="width"
                      [bc-height]="barcodeheight"
                      [bc-font-options]="fontOptions"
                      [bc-font]="font"
                      [bc-text-align]="textAlign"
                      [bc-text-position]="textPosition"
                      [bc-text-margin]="textMargin"
                      [bc-font-size]="fontSize"
                      [bc-background]="background"
                      [bc-margin]="margin"
                      [bc-margin-top]="marginTop"
                      [bc-margin-bottom]="marginBottom"
                      [bc-margin-left]="marginLeft"
                      [bc-margin-right]="marginRight">
                    </ngx-barcode>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    </mdb-card-body>
    </mdb-card> <br><br><br>
</div>
</div>