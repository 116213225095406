<app-header></app-header>
<div class="wcu-section pt-5 pb-5 mt-5-md" id="wcuSection">
    <div class="container">
       <div class="row justify-content-center">
          <div class="text-center">
             <h1 class="wcu-heading" style="font-size: 30px;">Our Solutions</h1>
             <p class="wcu-para">We leverage technology to be widely accessible and human support to provide each Tervys user <br>with
                personalised healthcare recommendations.</p>
          </div>

          <div class="col-12 col-md-4">
             <div class="card m-3">
                <img src="../../../assets/images/solutions/Hospital-management.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                   <h5 class="card-title">Hospital Management system</h5>
                   <p class="card-text wcu-para">Tervys clinic management system is a software solution that utilizes
                      artificial intelligence (AI) technologies to streamline and optimize the management of clinics
                      and other healthcare facilities. </p>
                   <a  class="btn btn-dark" (click)="hospManage()">Know More</a>
                </div>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="card m-3">
                <img src="../../assets/images/solutions/Diagnostic-management.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                   <h5 class="card-title">Diagnostic Management System</h5>
                   <p class="card-text wcu-para">Tervys AI diagnostic management system is a software solution that leverages
                      artificial intelligence (AI) technologies to assist healthcare providers in the diagnosis and
                      management of medical conditions.</p>
                   <a  class="btn btn-dark" (click)="diagnosis()">Know More</a>
                </div>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="card m-3">
                <img src="../../assets/images/solutions/Pharmacy-management.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                   <h5 class="card-title">Pharmacy Management system</h5>
                   <p class="card-text wcu-para">Tervys pharmacy management system is a software solution that uses artificial
                      intelligence (AI) technologies to optimize pharmacy operations and improve patient care.</p>
                   <a  class="btn btn-dark" (click)="pharmacyManage()">Know More</a>
                </div>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="card m-3">
                <img src="../../assets/images/solutions/Clinicaldecision.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                   <h5 class="card-title">Clinical decision system </h5>
                   <p class="card-text wcu-para">Tervys clinical decision system utilizes artificial intelligence (AI) algorithms to support medical professionals in the clinical decision-making process.</p>
                   <a class="btn btn-dark" (click)="clinicDecision()">Know More</a>
                </div>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="card m-3">
                <img src="../../assets/images/solutions/Nutrition-management.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                   <h5 class="card-title">Nutrition management system</h5>
                   <p class="card-text wcu-para">Tervys AI based nutrition management system is a computer-based system that
                      utilizes artificial intelligence (AI) algorithms to support individuals in managing their diet
                      and nutrition.</p>
                   <a (click)="nutritionManage()" class="btn btn-dark">Know More</a>
                </div>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="card m-3">
                <img src="../../assets/images/solutions/Predictive Analytics.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                   <h5 class="card-title">Healthcare Predictive Analytics</h5>
                   <p class="card-text wcu-para">
                      Predictive medicine refers to the use of advanced analytics and machine learning techniques to predict an individual's risk of developing a particular disease or condition.</p>
                   <a class="btn btn-dark" (click)="predictive()">Know More</a>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 
 <app-footer></app-footer>