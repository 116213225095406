import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { Helper_Class } from '../../helper_class';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Observable, observable, Subscription } from 'rxjs';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diag-prescription',
  templateUrl: './diag-prescription.component.html',
  styleUrls: ['./diag-prescription.component.css']
})
export class DiagPrescriptionComponent implements OnInit {
  @ViewChild('printnoheadsidebar') printnoheadsidebar: ElementRef;

  public diagPresFlag = "min";
  public adddiag: boolean = false;
  public city_list_data;
  public state_list_data;
  public country_list_data;
  public Search_Location: string;
  public Location_array = [];
  public Search_City: string
  public State_data = [];
  public Search_State: string;
  public Countrydata = [];
  public CurrentDatetime = null;
  public test_now: boolean;
  public book_app: any;
  public observation: string;
  public filt_city_desc;

  public blood_test = [];
  public urine_test_array = [];
  public feace_test_array = [];
  public scan_test_array = [];
  public xray_test_array = [];
  public ultra_test_array = [];

  public filt_city;
  public loc_id;
  public zipcode;
  public sercah_center: string;

  public filt_state;
  public filt_country;
  public obs_diag: string;
  public city_url = ipaddress.getIp + "gen/city";
  public state_url = ipaddress.getIp + "gen/state";
  public country_url = ipaddress.getIp + "gen/count";

  public pres_state_list_data;
  public filt_state_desc;

  public pres_country_list_data;
  public country_id;

  public perm_city_list_data;
  public city: any;
  public cityval = [];
  public perm_state_list_data;
  public perm_country_list_data;

  public address: boolean;
  public addr: any;
  public loc_name: string;
  public consultationlocation = [];
  public clocation: any[];
  public consultationcity;
  public consultationstate;
  public consultationcountry;
  public Urine_test = [];
  public Feace_test = [];
  public Xray_test = [];
  public Scan_test = [];
  public Ultra_test = [];

  public diag_detail_List;
  public location_search_items;
  public location_txt;
  public diag_list_address;
  public diag_address1;
  public diag_address2;
  public diag_cen_name;
  public obs_add_diag: any = [];
  public test_Show: boolean = false;
  public diag_cen_id;
  public diag_centre_id;
  public testtypename: any = [];
  public subtestname: any = [];
  public subtestuname: any = [];
  public subtestsname: any = [];
  public subtestxname: any = [];
  public subtestbname: any = [];
  public subtesturname: any = [];
  public subtestfname: any = [];
  public check_pres;
  public bloodtest: boolean = true;
  public ultratest: boolean = true;
  public scantest: boolean = true;
  public xraytest: boolean = true;
  public biopsytest: boolean = true;
  public urinetest: boolean = true;
  public faecestest: boolean = true;

  public diag_type;
  public diag_test_names = [];
  public newarray;
  public mainarray;
  public checked: boolean = false;

  public profile_image: any;
  public subtests: any[];
  public Thyroid: boolean = false;
  public Blood_test;

  public doc_reg_id: string;

  public msgflag: boolean = false;
  public notifyflag: boolean = false;

  public hospital_logo;
  public hospital_name;
  public hospital_details;

  public dct_hospital: string;
  public doct_name: string;
  public doc_qualif: string ="";
  public doct_location: string;
  public doct_address: string;
  public doct_city: string;
  public doct_state: string;
  public doct_clientname: string;
  public Medicare_name: string;
  public client_gender;
  public client_age;
  public Appoint_Date: string;
  public hospitall_clinic_id;
  public subtest_types = [];

  //table view presc
  public diag_view_list: boolean = false;
  public diag_pres_list = [];
  public url_txt: string;
  public send_client_reg_id: any = {};
  public diag_pres_list_data;
  public diag_pres_listdata: boolean;
  public header_footer_flag: boolean;

  Hospdetails: boolean;
  public test_check: Boolean = false;
  public send_diag_data;
  public diag_url_det: string;
  public pres_test_id;
  public testname_sub: string;
  public testname: string
  public urine_test_list = [];
  public scan_test_list = [];
  scan_test = [];
  public urine_test = [];
  public test = [];
  public app_date: string;
  public pres_id: string;
  public flag: string;
  public send_data;
  public diag_url_txt;
  public test_type = [];
  public diag_id;
  public diag_location;
  public temp_test_data = {};
  public diag_details;
  public diag_list = [];
  public toastOption;
  public toastMessage;
  public seacrhbtn: boolean;
  public bookbtn: boolean;
  public hospital_city;
  public hospital_state;
  public hospital_location;
  public flagtxt;
  public doct_address1 = [];
  public diag_city;
  public diag_state;
  public diag_zipcode;
  public diag_cntry;
  public diag_telpho;
  public patient_list;
  public userinfo: any;
  public test_flag: boolean = false;
  subscription: Subscription;
  subscription1:Subscription;
  public client_id;
  public relation;
  public home_care;
  public add_MP;
  public relation_id;

  public getRetdetails:any = [];
  public hosp_footer;
  public printf_flag: boolean;

  public areas = [
    { size: 30 },
    { size: 70 },
  ];
  public list_button_flag:boolean = false;
  public pritn_blood_flag: boolean = false;
  public bg_image: string;
  public print_view_flag:boolean = true;
  public licence_code;

  constructor(public http: Http, public toastr: ToastrService, public router: Router, 
    public messageservice: CasesheetService, public naviagationService:MenuViewService) {
    this.getTime();
  }

  getTime() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      var get_date = obj.current_date.split('-');
      this.Appoint_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
    }
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message == "max") {
        this.adddiag = false;
      }
      this.diagPresFlag = message;
      this.diag_view_list = false;
      this.list_button_flag = false;
    });

    this.subscription = this.messageservice.getDiagComponent().subscribe(message => {
        this.appdiagpresList();
    });

    this.Blood_test = [];
    this.testtypename = [];
    this.sercah_center = "";
    this.subtestname = [];
    var addr2;
    if(Doc_Helper.getAppFlow() == "client" ){
      this.patient_list = Helper_Class.getInfo();
      this.userinfo =  Helper_Class.getclient_hospitalDetails();
      this.add_MP = true;
      this.hospitall_clinic_id = this.userinfo.hptl_clinic_id;
      this.dct_hospital = this.userinfo.hptl_name;
      this.doct_name = this.userinfo.first_name + " " + this.userinfo.last_name;
      this.doct_location = this.userinfo.location;
      addr2 = this.userinfo.address2 == undefined ? "" : this.userinfo.address2;
      this.doct_address = this.userinfo.address1 + addr2;
      this.doct_city = this.userinfo.city + ", " + this.userinfo.state;
      this.doct_state = this.userinfo.country + ", " + this.userinfo.zipcode;
      this.doct_clientname = this.userinfo.client_name;
      this.Medicare_name = this.patient_list.Medicare_name;
      this.client_gender = this.patient_list.Gender_data;
      this.Appoint_Date = this.patient_list.Appoint_Date;
      this.client_age = this.patient_list.Age;
      this.check_pres = "0";
      if (this.doct_location != null && this.doct_location != undefined) {
        var location_name = this.doct_location;
        this.loc_name = location_name;
        //this.Get_City(this.loc_name);
      }
    }else{
      this.add_MP = false;
      this.patient_list = Doc_Helper.getClient_Info();
      
      this.userinfo = Helper_Class.getInfo();
      var hosp: any = Helper_Class.getHospital();
      this.hospitall_clinic_id = hosp[0].hptl_clinic_id;
      this.dct_hospital = this.patient_list.hptl_name;
      this.doct_name = this.userinfo.first_name + " " + this.userinfo.last_name;
      this.doct_location = this.patient_list.location;
      this.licence_code = this.userinfo.licence_code;

      if(this.userinfo != undefined && this.userinfo != null){
        if(this.userinfo.qualifications.length != 0){
          for(var i = 0 ; i < this.userinfo.qualifications.length; i++){
            if(this.doc_qualif != ""){
              this.doc_qualif += ","+ this.userinfo.qualifications[i];
            }else{
              this.doc_qualif = this.userinfo.qualifications[i];
            }
          }
        }  
      }

      addr2 = this.patient_list.address2 == undefined ? "" : this.patient_list.address2;
      this.doct_address = this.patient_list.address1 + addr2;
      this.doct_city = this.patient_list.city + ", " + this.patient_list.state;
      this.doct_state = this.patient_list.country + ", " + this.patient_list.zipcode;
      this.doct_clientname = this.patient_list.client_name;
      this.Medicare_name = this.patient_list.Medicare_name;
      this.client_gender = this.patient_list.Gender_data;
      this.Appoint_Date = this.patient_list.Appoint_Date;
      this.client_age = this.patient_list.Age_data;
      this.check_pres = "0";

      if (this.doct_location != null && this.doct_location != undefined) {
        var location_name = this.doct_location;
        this.loc_name = location_name;
       // this.Get_City(this.loc_name);
      }
      //list view
    }
    
    this.header_footer_flag = false;
    this.url_txt = ipaddress.getIp + "record/dpbyid";

    this.client_id = this.patient_list.Client_id;
    this.relation = this.patient_list.relationship_name;
    this.relation_id = this.patient_list.rel_id;
    this.home_care = "0";
    this.getRetdetails = Helper_Class.getRet();

    if (Doc_Helper.getHospFooter() != undefined) {
      this.hosp_footer = Doc_Helper.getHospFooter();
      this.printf_flag = false;
    }

    if (Doc_Helper.getHospital_bg_image() != undefined) {
      this.bg_image = Doc_Helper.getHospital_bg_image();
    }
    this.appdiagpresList();
  }

  spiltarea(e){
    if(e == 'default'){
      this.areas[0].size = 40;
      this.areas[1].size = 60;
      this.list_button_flag = false;
    }else if(e == 'details_view'){
      this.areas[0].size = 10;
      this.areas[1].size = 90;
      this.list_button_flag = true;
    }else{
      this.areas[0].size = 90;
      this.areas[1].size = 10;
      this.list_button_flag = false;
      this.print_view_flag = true;
    }
  }

  // Locationfilter(location) {
  //   this.loc_name = location.target.value.toString();
  //   this.consultationlocation = [];
  //   this.consultationstate = [];
  //   this.consultationcity = [];
  //   this.consultationcountry = [];
  //   this.bloodtest = true;
  //   this.ultratest = true;
  //   this.scantest = true;
  //   this.xraytest = true;
  //   this.biopsytest = true;
  //   this.urinetest = true;
  //   this.faecestest = true;
  //   this.filt_country = [];
  //   this.filt_state_desc = [];
  //   this.subtestbname = [];
  //   this.subtestfname = [];
  //   this.subtestname = [];
  //   this.subtestname = [];
  //   this.subtestsname = [];
  //   this.subtestuname = [];
  //   this.subtesturname = [];
  //   this.subtestxname = [];
  //   this.diag_detail_List = [];
  //   this.location_txt = [];
  //   if (this.loc_name !== "" && this.loc_name.length > 2) {
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     this.http.post(ipaddress.getIp + "gen/loc",
  //       JSON.stringify({
  //         loc_name: this.loc_name
  //       }),
  //       { headers: headers })
  //       .subscribe(
  //         response => {
  //           var obj = response.json();
  //           if (obj.locations != null && obj.locations != undefined) {
  //             this.location_search_items = obj.locations;
  //             for (var i = 0; i < this.location_search_items.length; i++) {
  //               this.location_txt.push(this.location_search_items[i]);
  //             }
  //             this.consultationlocation = this.location_txt.filter(function (this: any, el) {
  //               return el.toLowerCase().indexOf(this.loc_name.toLowerCase()) > -1;
  //             }.bind(this));
  //           }
  //         },
  //         error => {}
  //       )
  //   } else {
  //     this.consultationlocation = [];
  //     this.consultationcity = [];
  //     this.consultationstate = [];
  //     this.consultationcountry = [];
  //   }
  // }

  // select_location_item(loc_name) {
  //   this.loc_name = loc_name;
  //   this.consultationlocation = [];
  //   this.Get_City(this.loc_name);
  //   this.consultationstate = [];
  //   this.consultationcity = [];
  //   this.consultationcountry = [];
  //   this.bloodtest = true;
  //   this.ultratest = true;
  //   this.scantest = true;
  //   this.xraytest = true;
  //   this.biopsytest = true;
  //   this.urinetest = true;
  //   this.faecestest = true;
  //   this.subtestbname = [];
  //   this.subtestfname = [];
  //   this.subtestname = [];
  //   this.subtestname = [];
  //   this.subtestsname = [];
  //   this.subtestuname = [];
  //   this.subtesturname = [];
  //   this.subtestxname = [];
  //   this.diag_detail_List = [];
  //   this.Blood_test = [];
  // }

  // Get_City(data) {
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
  //     data => {
  //       var obj = data.json();
  //       if (obj.cities != null && obj.cities != undefined && obj.cities != "") {

  //         this.consultationcity = obj.cities;
  //         this.filt_city = this.consultationcity[0].city_id;
  //         this.filt_city_desc = this.consultationcity[0].city_desc;
  //         this.loc_id = this.consultationcity[0].location_id;
  //         this.zipcode = this.consultationcity[0].zipcode;
  //         this.City_change(this.consultationcity[0].city_id);

  //       }
  //     },
  //     error => {
        
  //     })
  // }

  // City_change(data) {
  //   if (data != null) {
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');

  //     this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
  //       data => {

  //         if (data.json().states != null && data.json().states != undefined && data.json().states != "") {
  //           this.consultationstate = data.json().states;

  //           this.filt_state = this.consultationstate[0].state_id;
  //           this.filt_state_desc = this.consultationstate[0].state_desc;

  //           this.State_change(this.consultationstate[0].state_id);
  //         }
  //       },
  //       error => {
  //       })
  //   } else {
  //     this.consultationstate = [];
  //     this.consultationcity = [];
  //     this.consultationcountry = [];
  //   }
  // }

  // State_change(data) {
  //   if (data != null) {
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');

  //     this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
  //       data => {

  //         if (data.json().countries != null && data.json().countries != undefined && data.json().countries != "") {
  //           this.consultationcountry = data.json().countries;
  //           this.country_id = this.consultationcountry[0].country_id;
  //           this.filt_country = this.consultationcountry[0].country_desc;
  //           this.SerCountry_Change(this.consultationcountry[0].country_desc, this.filt_state_desc, this.filt_city_desc, this.loc_name);
  //         }
  //       },
  //       error => {
          
  //       })
  //   } else {
  //     this.consultationstate = [];
  //     this.consultationcountry = [];
  //   }
  // }
  // //center
  // SerCountry_Change(countrytxt, statetxt, citytxt, loctxt) {
  //   this.diag_detail_List = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagdetbyname/',
  //     JSON.stringify({
  //       country: countrytxt,
  //       state: statetxt,
  //       city: citytxt,
  //       location: loctxt,
  //     }),
  //     { headers: headers })
  //     .subscribe(
  //       response => {
          
  //         var obj = response.json();;
  //         if (obj.diagnosis != null && obj.diagnosis != undefined && obj.diagnosis != "") {
  //           this.diag_detail_List = obj.diagnosis;
  //           for (var i = 0; i < obj.diagnosis.length; i++) {

  //             if (obj.diagnosis[i].diag_centre_name != null) {
  //               this.diag_cen_name = obj.diagnosis[i].diag_centre_name;
  //             }
  //             if (obj.diagnosis[i].diag_centre_id != null) {
  //               this.diag_centre_id = obj.diagnosis[i].diag_centre_id;
  //             }

  //             if (obj.diagnosis[i].address1 != null || obj.diagnosis[i].address2 != null) {
  //               this.diag_address1 = obj.diagnosis[i].address1;
  //               this.diag_address2 = obj.diagnosis[i].address2;
  //             }


  //             if (obj.diagnosis[i].location != null) {
  //               this.diag_location = obj.diagnosis[i].location;
  //             }

  //             if (obj.diagnosis[i].city != null) {
  //               this.diag_city = obj.diagnosis[i].city;
  //             }

  //             if (obj.diagnosis[i].state != null) {
  //               this.diag_state = obj.diagnosis[i].state;
  //             }

  //             if (obj.diagnosis[i].zipcode != null) {
  //               this.diag_zipcode = obj.diagnosis[i].zipcode;
  //             }

  //             if (obj.diagnosis[i].country != null) {
  //               this.diag_cntry = obj.diagnosis[i].country;

  //             }
  //             if (obj.diagnosis[i].telephone != null) {
  //               this.diag_telpho = obj.diagnosis[i].telephone;
  //             }
  //           }
  //         }
  //       },
  //       error => {

  //       }
  //     )
  // }

  // //Get diagnosis center
  // serch_centerchange(data) {
  //   this.obs_add_diag = undefined;
  //   var datae: string = "";
  //   datae = data.diag_centre_name;
  //   for (var i = 0; i < this.diag_detail_List.length; i++) {
  //     if (this.diag_detail_List[i].diag_centre_name == datae) {
  //       this.diag_cen_id = this.diag_detail_List[i].diag_centre_id;
  //       if (this.diag_detail_List[i].address1 != null && this.diag_detail_List[i].address1 != "") {
  //         this.diag_address1 = this.diag_detail_List[i].address1;
  //       }
  //       else {
  //         this.diag_address1 = this.diag_detail_List[i].address1;
  //       }
  //       if (this.diag_detail_List[i].address1 != null || this.diag_detail_List[i].address2 != null) {
  //         this.diag_address2 = this.diag_detail_List[i].address2;
  //       }
  //       else {
  //         this.diag_address2 = this.diag_detail_List[i].address2;
  //       }
  //       this.obs_add_diag = this.diag_address1 + ", " + this.diag_address2;
  //     }
  //     else {
  //     }
  //   }

  //   this.Blood_test = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/',
  //     JSON.stringify({
  //       diag_centre_id: this.diag_cen_id
  //     }),
  //     { headers: headers })
  //     .subscribe(
  //       response => {
          
  //         var obj = response.json();
  //         if (obj.diag_tests.length == 0) {
  //           this.test_flag = true;
  //         }
  //         else {
  //           this.test_flag = false;
  //         }
  //         this.diag_centre_id = this.diag_cen_id;

  //         this.Blood_test = obj.diag_tests;
  //         for (var k = 0; k < this.Blood_test.length; k++) {
  //           this.testtypename[k] = this.Blood_test[k].test__type_name;
  //           for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //               this.Blood_test[k].sub_tests[j] = {
  //                 test_id: this.Blood_test[k].sub_tests[j].test_id,
  //                 test_name: this.Blood_test[k].sub_tests[j].test_name,
  //                 checked: false                }
  //           }
  //         }
          
          
  //       },
  //       error => { }
  //     )
  // }

  // ChangeTestType(event, id, data, type) {
  //   if (event == true) {
  //     for (var k = 0; k < this.Blood_test.length; k++) {
  //       this.testtypename[k] = this.Blood_test[k].test__type_name;
  //       if(this.testtypename[k] == type ){
  //         for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //           if( this.Blood_test[k].sub_tests[j].test_id == id){
  //             this.Blood_test[k].sub_tests[j].checked = true;
  //           }
  //         }
  //       }
  //     }
  //     if (type == "Blood Test") {
  //       this.bloodtest = false;
  //       this.subtestname.push({
  //         test_id: id,
  //         test_name: data
  //       });
        
  //     } else if (type == "Scan") {
  //       this.scantest = false;
  //       this.subtestsname.push({
  //         test_id: id,
  //         test_name: data
  //       });

  //     } else if (type == "Ultra Sound") {
  //       this.ultratest = false;
  //       this.subtestuname.push({
  //         test_id: id,
  //         test_name: data
  //       });

  //     } else if (type == "X-ray") {
  //       this.xraytest = false;
  //       this.subtestxname.push({
  //         test_id: id,
  //         test_name: data
  //       });

  //     } else if (type == "Biopsy") {
  //       this.biopsytest = false;
  //       this.subtestbname.push({
  //         test_id: id,
  //         test_name: data
  //       });

  //     } else if (type == "Urine Test") {
  //       this.urinetest = false;
  //       this.subtesturname.push({
  //         test_id: id,
  //         test_name: data
  //       });
        
  //     } else if (type == "Faeces Test") {
  //       this.faecestest = false;
  //       this.subtestfname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //     }

  //   } else if (event == false) {
  //     for (var k = 0; k < this.Blood_test.length; k++) {
  //       this.testtypename[k] = this.Blood_test[k].test__type_name;
  //       if(this.testtypename[k] == type ){
  //         for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //           if( this.Blood_test[k].sub_tests[j].test_id == id){
  //             this.Blood_test[k].sub_tests[j].checked = false;
  //           }
            
  //         }
  //       }
        
  //     }
  //     for (var h = 0; h < this.subtestname.length; h++) {
  //       if (this.subtestname[h].test_id == id) {
  //         this.subtestname.splice(h, 1);
  //         this.checked = false;
  //         if (this.subtestname.length == 0) {
  //           this.bloodtest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestsname.length; h++) {
  //       if (this.subtestsname[h].test_id == id) {
  //         this.subtestsname.splice(h, 1);
  //         if (this.subtestsname.length == 0) {
  //           this.scantest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestuname.length; h++) {
  //       if (this.subtestuname[h].test_id == id) {
  //         this.subtestuname.splice(h, 1);
  //         if (this.subtestuname.length == 0) {
  //           this.ultratest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestxname.length; h++) {
  //       if (this.subtestxname[h].test_id == id) {
  //         this.subtestxname.splice(h, 1);
  //         if (this.subtestxname.length == 0) {
  //           this.xraytest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestbname.length; h++) {
  //       if (this.subtestbname[h].test_id == id) {
  //         this.subtestbname.splice(h, 1);
  //         if (this.subtestbname.length == 0) {
  //           this.biopsytest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtesturname.length; h++) {
  //       if (this.subtesturname[h].test_id == id) {
  //         this.subtesturname.splice(h, 1);
  //         if (this.subtesturname.length == 0) {
  //           this.urinetest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestfname.length; h++) {
  //       if (this.subtestfname[h].test_id == id) {
  //         this.subtestfname.splice(h, 1);
  //         if (this.subtestfname.length == 0) {
  //           this.faecestest = true;
  //         }
  //       }
  //     }
  //   }
    
  // }

  downloadPDF() {
    window.print();
  }

  // closeSelectedTest(type, id, subtype) {
  //     for (var k = 0; k < this.Blood_test.length; k++) {
  //       if (type == this.Blood_test[k].test__type_name) {
  //       for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //         if (this.Blood_test[k].sub_tests[j].test_id == id) {
  //           this.Blood_test[k].sub_tests[j].checked = false;
  //         }
  //       }
  //     }
  //   }
  //   if (type == "Blood Test") {
      

  //     for (var h = 0; h < this.subtestname.length; h++) {
  //       if (this.subtestname[h].test_id == id) {
  //         this.subtestname.splice(h, 1);
  //         if (this.subtestname.length == 0) {
  //           this.bloodtest = true;
  //         }
  //       }

  //     }
  //   }
  //   if (type == "Scan") {
  //     for (var h = 0; h < this.subtestsname.length; h++) {
  //       if (this.subtestsname[h].test_id == id) {
  //         this.subtestsname.splice(h, 1);
  //         if (this.subtestsname.length == 0) {
  //           this.scantest = true;
  //         }
  //       }
  //     }
  //   }
  //   if (type == "Ultra Sound") {
  //     for (var h = 0; h < this.subtestuname.length; h++) {
  //       if (this.subtestuname[h].test_id == id) {
  //         this.subtestuname.splice(h, 1);
  //         if (this.subtestuname.length == 0) {
  //           this.ultratest = true;
  //         }
  //       }
  //     }
  //   }
  //   if (type == "X-ray") {
  //     for (var h = 0; h < this.subtestxname.length; h++) {
  //       if (this.subtestxname[h].test_id == id) {
  //         this.subtestxname.splice(h, 1);
  //         if (this.subtestxname.length == 0) {
  //           this.xraytest = true;
  //         }
  //       }
  //     }
  //   }
  //   if (type == "Biopsy") {
  //     for (var h = 0; h < this.subtestbname.length; h++) {
  //       if (this.subtestbname[h].test_id == id) {
  //         this.subtestbname.splice(h, 1);
  //         if (this.subtestbname.length == 0) {
  //           this.biopsytest = true;
  //         }

  //       }
  //     }
  //   }
  //   if (type == "Urine Test") {
  //     for (var h = 0; h < this.subtesturname.length; h++) {
  //       if (this.subtesturname[h].test_id == id) {
  //         this.subtesturname.splice(h, 1);
  //         if (this.subtesturname.length == 0) {
  //           this.urinetest = true;
  //         }

  //       }
  //     }
  //   }
  //   if (type == "Faeces Test") {
  //     for (var h = 0; h < this.subtestfname.length; h++) {
  //       if (this.subtestfname[h].test_id == id) {
  //         this.subtestfname.splice(h, 1);
  //         if (this.subtestfname.length == 0) {
  //           this.faecestest = true;
  //         }
  //       }
  //     }
  //   }

  // }

  // Diagnosis_prescription_save() {
  //   var flag = true;
  //   if (this.loc_name == undefined || this.filt_city == undefined || this.filt_state == undefined || this.filt_country == undefined || this.sercah_center == undefined) {
  //     flag = false;
  //     this.toastr.error("Please enter mandatory fields");
  //   }
  //   if (flag == true && (this.subtestsname == undefined || this.subtestfname == undefined || this.subtestuname == undefined || this.subtesturname == undefined || this.subtestxname == undefined || this.subtestname == undefined || this.subtestbname == undefined)) {
  //     flag = false;
  //     this.toastr.error("Please select test type");
  //   }

  //   if(this.subtestuname.length != 0){
  //     flag=true;
  //   } else if(this.subtestfname.length != 0){
  //     flag=true;
  //   }else if(this.subtestname.length != 0){
  //     flag = true;
  //   }else if(this.subtestsname.length != 0){
  //     flag = true;
  //   }else if(this.subtestxname.length != 0){
  //     flag = true;
  //   }else if(this.subtesturname.length != 0){
  //     flag = true;
  //   }else if(this.subtestbname.length != 0){
  //     flag = true;
  //   }else{
  //     flag = false;
  //     this.toastr.error("Please select atleast one test to proceed");
  //   }

  //   // if (this.subtestuname.length == 0 || this.subtestfname.length == 0
  //   //   || this.subtestname.length == 0 || this.subtestsname.length == 0
  //   //   || this.subtestxname.length == 0 || this.subtesturname.length == 0) {

  //   //   flag = false;
  //   //   this.toastr.error("Please select atleast one test to proceed");
  //   // }

  //   if (flag == true) {
  //     if (this.subtestname.length != 0) {
  //       for (var i = 0; i < this.subtestname.length; i++) {
  //         this.blood_test.push(this.subtestname[i].test_id);
  //       }
  //     }
  //     if (this.subtesturname.length != 0) {
  //       for (var i = 0; i < this.subtesturname.length; i++) {
  //         this.urine_test_array.push(this.subtesturname[i].test_id);
  //       }
  //     }
  //     if (this.subtestfname.length != 0) {
  //       for (var i = 0; i < this.subtestfname.length; i++) {
  //         this.feace_test_array.push(this.subtestfname[i].test_id);
  //       }
  //     }
  //     if (this.subtestsname.length != 0) {
  //       for (var i = 0; i < this.subtestsname.length; i++) {
  //         this.scan_test_array.push(this.subtestsname[i].test_id);
  //       }
  //     }
  //     if (this.subtestxname.length != 0) {
  //       for (var i = 0; i < this.subtestxname.length; i++) {
  //         this.xray_test_array.push(this.subtestxname[i].test_id);
  //       }
  //     }
  //     if (this.subtestuname.length != 0) {
  //       for (var i = 0; i < this.subtesturname.length; i++) {
  //         this.ultra_test_array.push(this.subtesturname[i].test_id);
  //       }
  //     }
  //     if (this.obs_diag != undefined) {
  //       this.observation = this.obs_diag;
  //     }
  //     else {
  //       this.observation = "";
  //     }
  //     if (this.test_now == true) {
  //       this.book_app = "1";
  //     }
  //     else {
  //       this.book_app = "0";
  //     }

  //     var sen_pass;
  //     if(this.patient_list.sub_id != "" && this.patient_list.sub_id != undefined){
  //         sen_pass = JSON.stringify({
  //           appointment_id: this.patient_list.app_id,
  //           client_reg_id:this.client_id,
  //           relation_id: this.relation_id,
  //           sub_rel_id: this.patient_list.sub_id,
  //           date: this.CurrentDatetime,
  //           diagnosis: "diag",
  //           disease: "",
  //           comment: this.observation,
  //           diagnosis_id: this.diag_centre_id,
  //           spl_id:this.patient_list.spl_id,
  //           "Blood Test": this.blood_test,
  //           "Urine Test": this.urine_test_array,
  //           "Faeces Test": this.feace_test_array,
  //           "Scan": this.scan_test_array,
  //           "X-ray": this.xray_test_array,
  //           "Ultra Sound": this.ultra_test_array,
  //           "book_now": this.book_app
  //         });
  //     }else{
  //       sen_pass = JSON.stringify({
  //         appointment_id: this.patient_list.app_id,
  //         client_reg_id:this.client_id,
  //         relation_id: this.relation_id,
  //         date: this.CurrentDatetime,
  //         diagnosis: "diag",
  //         disease: "",
  //         comment: this.observation,
  //         diagnosis_id: this.diag_centre_id,
  //         spl_id:this.patient_list.spl_id,
  //         "Blood Test": this.blood_test,
  //         "Urine Test": this.urine_test_array,
  //         "Faeces Test": this.feace_test_array,
  //         "Scan": this.scan_test_array,
  //         "X-ray": this.xray_test_array,
  //         "Ultra Sound": this.ultra_test_array,
  //         "book_now": this.book_app
  //       });
  //     }

      

  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
  //       { headers: headers })
  //       .subscribe(
  //         response => {
  //           var obj = response.json();
  //           if (localStorage.getItem("HomeCare") != "0") {
  //             if (obj["pres_id"] != null) {
  //               Helper_Class.set_diag_pres_id(obj["pres_id"])
  //               this.toastr.success("Prescription saved successfully");
  //               this.flagtxt = "booking";

  //               this.send_data = {
  //                 pres_id: obj.pres_id,
  //                 flag: this.flagtxt
  //               }
  //               this.appdiagpresList();
  //               this.adddiag = false;

  //               // var dat = this.check_pres;
  //               // if (dat == "0") {
  //               //   this.router.navigateByUrl('/dashboard');
  //               //   this.check_pres = "1";
  //               // }
  //               // else {
  //               //   var seconds = 1;
  //               //   setInterval(function () {
  //               //     seconds--;

  //               //     // if (seconds == 0) {
  //               //     //   this.navCtrl.push(clientapplistPage);
  //               //     // }
  //               //   }, 500);
  //               // }

  //             }
  //             else {
  //               this.toastr.error("Unable to save prescription, please try again later");

  //             }
  //           }
  //           else {
  //             //$scope.diag_test_names = response.data.test_details;
  //             //for (var i = 0; i < $scope.diag_test_names.length; i++) {
  //             if (obj["pres_id"] != null) {
  //               this.toastr.error("Prescription saved successfully");
  //               var dat = this.check_pres;
  //               if (dat == "0") {
  //                 // this.navCtrl.push(MedicalPrescriptionTabPage);
  //                 this.check_pres = "1";
  //               }
  //               else {
  //                 var seconds = 1;
  //                 setInterval(function () {
  //                   seconds--;
  //                   // if (seconds == 0) {
  //                   //   this.navCtrl.push(clientapplistPage);
  //                   // }
  //                 }, 500);
  //               }
  //             }
  //             else {
  //               this.toastr.error('Unable to save prescription, please try again later');
  //             }

  //           }
  //         },
  //         error => {

  //         }
  //       )
  //   }
  // }

  // Get_diagnosis_address() {
  //   function StringBuilder(this: any, value) {
  //     this.strings = new Array();
  //     this.append(value);
  //   }

  //   StringBuilder.prototype.append = function (value) {
  //     if (value) {
  //       this.strings.push(value);
  //     }
  //   }

  //   StringBuilder.prototype.clear = function () {
  //     this.strings.length = 0;
  //   }

  //   StringBuilder.prototype.toString = function () {
  //     return this.strings.join("");
  //   }
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'adm/gfac',
  //     JSON.stringify({
  //       hptl_clinic_id: this.hospitall_clinic_id,
  //       type: "diagnosis"
  //     }),
  //     { headers: headers })
  //     .subscribe(
  //       response => {

          
  //         var obj = response.json();
  //         if (obj != null) {
  //           if (obj.location != undefined) {
  //             var Search_Location = obj.location;
  //           }
  //           if (obj.city != null) {
  //             if (this.city_list_data != undefined) {
  //               for (var i = 0; i < this.city_list_data.length; i++) {
  //                 if (this.city_list_data[i].city_desc == obj.city) {
  //                   this.Search_City = this.city_list_data[i];
  //                 }
  //               }
  //             }
  //           }
  //           if (obj.state != null) {
  //             if (this.state_list_data != undefined) {
  //               for (var i = 0; i < this.state_list_data.length; i++) {

  //                 if (this.state_list_data[i].state_desc == obj.state) {


  //                   this.Search_State = this.state_list_data[i];
  //                 }
  //               }
  //             }
  //           }
  //           if (obj.country != null) {
  //             if (this.country_list_data != undefined) {
  //               for (var i = 0; i < this.country_list_data.length; i++) {

  //                 if (this.country_list_data[i].country_desc == obj.country) {


  //                   this.filt_country = this.country_list_data[i];
  //                 }
  //               }
  //             }
  //           }
  //           if (obj.hptl_name != null) {
  //             if (this.diag_detail_List != undefined) {
  //               for (var i = 0; i < this.diag_detail_List.length; i++) {

  //                 if (this.diag_detail_List[i].diag_centre_name == obj.hptl_name) {


  //                   this.sercah_center = this.diag_detail_List[i];
  //                 }
  //               }
  //             }
  //           }


  //           if (obj.address1 != undefined) {
  //             var sb = new StringBuilder("");


  //             if (obj.address2 != null && obj.address2 != "") {
  //               sb.append(obj.address1 + "," + obj.address2);
  //             }
  //             else {
  //               sb.append(obj.address1);
  //             }

  //             sb.append("\n");
  //             sb.append(obj.location);
  //             sb.append("\n");
  //             sb.append(obj.city + "," + obj.state);
  //             sb.append("\n");
  //             sb.append(obj.country + "-" + obj.zipcode);
  //             sb.append("\n");
  //             sb.append(obj.telephone);

  //             this.obs_add_diag = sb.toString();
  //           }
  //         }
  //       },
  //       error => {

  //       }
  //     )
  // }

  addDiagPrescription() {
    this.print_view_flag = true;
    Doc_Helper.setDiagSave(false);
    Doc_Helper.setMedDiag(false);
    this.messageservice.sendDiagComponent("diag"); 
  }

  //list view codng
  appdiagpresList() {
    this.diag_pres_list = [];
    var headers = new Headers();
    var send_list;
    headers.append('Content-Type', 'application/json');
    if( this.patient_list.spl == "Dental"){ 
      this.home_care = "Dental";
      this.client_id = this.getRetdetails.case_hist_id;
    }
    
    if(this.patient_list.AppFlow == "Inpatient"){
      if(this.patient_list.sub_id != "" && this.patient_list.sub_id != undefined){
        send_list = JSON.stringify(
          {
            client_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            sub_rel_id: this.patient_list.sub_id,
            spl_id: this.patient_list.spl_id,
            home_care:this.home_care,
            is_inpat: true
  
          });
      }else{
        send_list = JSON.stringify(
          {
            client_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            spl_id: this.patient_list.spl_id,
            home_care:this.home_care,
            is_inpat: true
          });
      }
    }else{
      if(this.patient_list.sub_id != "" && this.patient_list.sub_id != undefined){
        send_list = JSON.stringify(
          {
            client_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            sub_rel_id: this.patient_list.sub_id,
            spl_id: this.patient_list.spl_id,
            home_care:this.home_care,
          });
      }else{
        send_list = JSON.stringify(
          {
            client_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            spl_id: this.patient_list.spl_id,
            home_care:this.home_care
          });
      }
    }
    
    this.http.post(this.url_txt, send_list, { headers: headers }).subscribe(
      response => {
        var data = response.json();
        this.diag_pres_list_data = [];

        if (data != null) {
          this.diag_pres_list_data = data.prescriptions;
          if (this.diag_pres_list_data != null) {
            for (var i = 0; i < this.diag_pres_list_data.length; i++) {
              var new_datalist = this.diag_pres_list_data[i];
              var Docname = "";
              var res_clientname = "";
              if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
              }else {
                Docname = new_datalist.first_name + " " + new_datalist.last_name;
              }

              var App_date = "";
              if (new_datalist.date != null) {
                App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
              }

              var diag_centre_name = "";
              this.diag_pres_list.push({
                pres_diag_id: this.diag_pres_list_data[i].pres_diag_id,
                doctorname: Docname,
                // clientname: res_clientname,
                date: App_date,
                diag_centre_name: this.diag_pres_list_data[i].diag_centre_name,
              });
            }
            this.spiltarea("default");
          }
          if (this.diag_pres_list.length == 0) {
            this.diag_pres_listdata = true;
          }
          else {
            this.diag_pres_listdata = false;
          }
        }
      },
      error => {
      })
  }

  Select_list(pres_diag_id) {
    this.print_view_flag = false;
    this.doct_address1 = [];
    this.test_check = false;
    this.diag_view_list = true;
    this.Hospdetails = false;
    this.pres_id = pres_diag_id;
    this.diag_url_txt = ipaddress.getIp + "prescription/dpresdet";
    this.diag_url_det = ipaddress.getIp + "diagnosiscontroller/diagdetails";
    this.flagtxt = "booking";

    this.send_data = {
      pres_id: this.pres_id,
      flag: this.flagtxt
    }

    this.seacrhbtn = true;
    this.bookbtn = true;
    this.getdiagdetail();
    this.naviagationService.filter('');
    this.spiltarea('details_view')
    this.naviagationService.filter('detail_view');
   
  }

  getdiagdetail() {
    this.test_type = [];
    var headers = new Headers;
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diag_url_txt, JSON.stringify(
      this.send_data
    ), { headers: headers }).subscribe(
      response => {
        var data = response.json();
        
        var doct_mname, address, country, telephone, hosp_address, h_telephone;
        this.print_view_flag = false;
        if (data.middle_name != null) {
          doct_mname = data.first_name + ' ' + data.middle_name + ' ' + data.last_name;
        }
        else {
          doct_mname = data.first_name + ' ' + data.last_name;

        }
        if (data.address2 != null && data.address2 != undefined) {
          address = data.address1 + ' ' + data.address2 + ', ';
        }
        else {
          if (data.address1 != null && data.address1 != undefined) {
            address = data.address1 + ', ';
          }
        }
        if (data.hosp_address2 != null && data.hosp_address2 != undefined) {
          hosp_address = data.hosp_address1 + ', ' + data.hosp_address2 + ', ';
        }
        else {
          if (data.hosp_address1 != null && data.hosp_address1 != undefined) {
            hosp_address = data.hosp_address1 + ', ';
          }
        }
        if (data.telephone != null && data.telephone != undefined) {
          telephone = data.telephone;
        }
        if (data.hosp_telephone != null && data.hosp_telephone != undefined) {
          h_telephone = data.hosp_telephone;
        }
        if ((data.pres_date) != undefined) {
          var dateSplit = (data.pres_date).split("-");
          this.app_date = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
        }

        if (data.hosp_location != null && data.hosp_location != undefined) {
          data.hosp_location;
        }
        if (data.diag_location != null && data.diag_location != undefined) {
          this.diag_location = data.diag_location;
        }
        if (data.diag_id != null && data.diag_id != undefined) {
          this.diag_id = data.diag_id;
          this.bookbtn = false;
        }
        if (data.hosp_city != null && data.hosp_city != undefined) {
          data.hosp_city;
        }
        if (data.hosp_zipcode != null && data.hosp_zipcode != undefined) {
          data.hosp_zipcode;
        }
        if (data.hosp_state != null && data.hosp_state != undefined) {
          data.hosp_state;
        }
        if (data.hosp_country != null && data.hosp_country != undefined) {
          data.hosp_country;
        }
        if (data.diag_name != null && data.diag_name != undefined) {
          data.diag_name;
        }
        if (data.tests != null && data.tests.length != 0) {
          this.temp_test_data = {};

          for (var i = 0; i < data.tests.length; i++) {
            var t_name = data.tests[i].replace("_", " ");
            t_name = t_name.charAt(0).toUpperCase() + t_name.substr(1).toLowerCase();
            this.temp_test_data = {
              test: t_name
            };

            if (data[data.tests[i]] != null) {
              this.test_check = true;
              this.testname_sub = "";
              this.testname = "";
              this.pres_test_id = "";
              for (var j = 0; j < data[data.tests[i]].length; j++) {
                if (j == data[data.tests[i]].length - 1 && i == data.tests[i].length - 1) {
                  this.testname = this.testname + data[data.tests[i]][j].test_name;
                } else {
                  this.testname = this.testname + data[data.tests[i]][j].test_name + ", ";
                }
                if (j == data[data.tests[i]].length - 1) {
                  this.testname_sub = this.testname_sub + data[data.tests[i]][j].test_name;
                  this.pres_test_id = this.pres_test_id + data[data.tests[i]][j].pres_test_id;
                } else {
                  this.testname_sub = this.testname_sub + data[data.tests[i]][j].test_name + ", ";
                  this.pres_test_id = this.pres_test_id + data[data.tests[i]][j].pres_test_id + ", ";
                }
              }
              this.test_type.push({
                test: t_name,
                subtest: this.testname_sub,
                pres_test_id: this.pres_test_id
              })
            } else {
              this.test_check = false;
            }
          }
        }
        else {

        }
        if (this.test_type != null && this.test_type.length != 0 && this.flag == "diagnosis") {
          this.seacrhbtn = false;
        }

        if (data.hosp_name != undefined) {
          this.hospital_name = data.hosp_name;
          this.Hospdetails = true;
        }
        if (data.hosp_city != undefined) {
          this.hospital_city = data.hosp_city + ' - ' + data.hosp_zipcode;
        }
        if (data.hosp_location != undefined) {
          this.hospital_location = data.hosp_location;
        }
        if (data.hosp_state != undefined) {
          this.hospital_state = data.hosp_state + ' - ' + data.hosp_country;
        }
        this.doct_address1.push({
          name: doct_mname,
          address1: address,
          location: this.diag_location,
          city: data.city + ' - ' + data.zipcode,
          state: data.state + ", " + data.country,
          telephone: telephone,
          hosp_name: this.hospital_name,
          hos_address: hosp_address,
          hos_location: this.hospital_location,
          hos_city: this.hospital_city,
          hos_state: this.hospital_state,
          hos_telephone: h_telephone,
          relation: data.relation_name,
          date: this.app_date,
          hos_center: data.diag_name,
          diag_id: this.diag_id,
        });
        this.getdiagdirectdetails(this.diag_location, this.diag_id);
      }, error => {

      }
    );
    
  }

  getdiagdirectdetails(diag_location, diag_id) {
    this.send_diag_data = {
      location: diag_location,
      diag_id: diag_id,
    }

    var headers = new Headers;
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diag_url_det, JSON.stringify(this.send_diag_data), { headers: headers }).subscribe(
      response => {
        var data = response.json();
        
        this.diag_list = [];
        var address, telephone, website, workday, available, time_available;

        if (data.address2 != null && data.address2 != undefined) {
          address = (data.address1) + ', ' + (data.address2 + ', ');
        } else {
          if (data.address1 != null && data.address1 != undefined) {
            address = (data.address1) + ', ';
          }
        }

        if (data.telephone != null && data.telephone != undefined) {
          telephone = data.telephone;
        }

        if (data.website != null && data.website != undefined) {
          website = data.website;
        } else {
          website = " - ";
        }

        if (data.work_days != null && data.work_days != undefined) {
          // workday = this.comm_Data.get_diabday(data.work_days);
        }

        if (workday != null && workday != undefined || data.available_from != null && data.available_from != undefined &&
          data.available_to != null && data.available_to != undefined) {

          // available = workday + ", " + this.comm_Data.get_Timeformate(data.available_from) + "- " +
          //   this.comm_Data.get_Timeformate(data.available_to)
        }

        var diag_tests_list;
        diag_tests_list = this.test_type;

        this.diag_list.push({
          center_id: data.diag_id,
          center_name: data.diag_name,
          city: data.city + "- " + data.zipcode,
          location: data.location,
          state: data.state + ", " + data.country,
          telephone: telephone,
          profile_image: ipaddress.Ip_with_img_address + data.profile_image,
          website: website,
          available: available,
          diag_tests_list: diag_tests_list,
          address: address
        })

      }, error => {

      }
    );
  }

  Back() {
    this.adddiag = false;
  }

  test1(){
    var test = "4";
    return test;
  }

  print_Viewprescription(){
    var footerimg = this.hosp_footer;
    var footerflag = this.printf_flag;
    // var backimg = this.bg_image;
    let printContents, popupWin;
    printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Prescription</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        .casesheet_container {
          width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        @media all {
          .page-break { display: none; }
          }
          
          @media print {
          .page-break { display: block; page-break-before: always; }
          }
        @media print {
          body{
            -webkit-print-color-adjust: exact;
            -moz-print-color-adjust: exact;
            -ms-print-color-adjust: exact;
            print-color-adjust: exact;
          }
          footer {
            position: fixed;
            bottom: 0;
          }
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
      <footer [hidden]=${footerflag}>
      <img src=${footerimg}>
    </footer>
    </html>`
    );
    popupWin.document.close();
    this.print_view_flag = true;
    this.diag_view_list = false;
    this.spiltarea('default');
  }
}
