<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Order</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="purchase_view()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12 d-flex flex-wrap" style="width: 100%;">
            <p class="nodata" *ngIf="orderArray.length == 0">No order(s) found</p>
            <div class="col-3 card-container-ph" *ngFor="let list of orderArray"
              (click)="view_details(list.po_id)">
              PO # {{list.po_id}} &nbsp;&nbsp; Date {{list.po_date}}
            </div>
            <!-- <table  mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead class="tableheading">
                <tr>
                  <th>Purchase order id</th>
                  <th>Date </th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td style="font-size: 12px;">{{list.po_id}}</td>
                  <td style="font-size: 12px;"> {{list.po_date}}</td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>