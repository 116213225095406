<div class="Details">
    <div class="header">
        <a *ngIf="!cardViewFlag" (click)="openRx()"><img data-bs-toggle="modal" data-bs-target="#noRxModal"
                src="../../../assets/ui_icons/buttons/No_Rx_button.svg" class="saveimgbtn" style="margin: 0;" /></a>
    </div>
    <div class="row" style="margin: 0px;">
        <div *ngIf="cardViewFlag" id="casesheet_slider_cover">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let user of cards;let i = index">
                    <div class="slide">
                        <div class="cu_slider_cover">
                            <div class="card_border1" (click)="viewRecord(user, i,'card_'+i)">
                                <div [ngSwitch]="user">
                                    <div *ngSwitchCase="'History'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/personal.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Medical Prescription'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/medical.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Habitual History'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/habitual.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Neurological'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/slider/complications.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Treatment Plan'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/slider/treatment_plan.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Diet Plan'">
                                        <img class="img-fluid" src="../../../assets/images/img_new/slider/diet_plan.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Clinical Exam'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/slider/clinical_examination.svg"
                                            alt="Card image cap">
                                    </div>
                                    <div *ngSwitchCase="'Appointments'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/slider/appointments.svg"
                                            alt="Card image cap">
                                    </div>
                                </div>
                            </div>
                            <a tabindex="0">
                                <div class="mask">
                                </div>
                            </a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div class="">
            <div class="col-12 container-fluid p-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" [id]="'cardindex_'+i"
                        *ngFor="let user of cards;let i = index">
                        <mdb-card class="mb-4 view card_border" [id]="'card_'+i" mdbwaveseffect style="height: 290px;">
                            <mdb-card-header class="text-center primary-color white-text">
                                <div [ngSwitch]="user">
                                    <div *ngSwitchCase="'History'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/personal.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Medical Prescription'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/medical.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Habitual History'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/habitual.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Neurological'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/complications.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Treatment Plan'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/treatment_plan.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Diet Plan'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/diet_plan.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Appointments'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/appointments.svg"
                                            class="details_card" alt="">
                                    </div>
                                    <div *ngSwitchCase="'Clinical Exam'">
                                        <img class="img-fluid"
                                            src="../../../assets/images/img_new/whiteicon/clinical_examination.svg"
                                            class="details_card" alt="">
                                    </div>
                                </div>
                                <h5 class="m-0" style="font-size: 14px; text-align: left;padding: 2px 0px 0px 32px;">{{
                                    user }}</h5>
                                <div *ngIf="cardViewFlag">
                                    <a *ngIf="!medSaveFlag" (click)="save()"><span class="cardsave"><img
                                                src="../../../assets/img/save.svg" style="width:25px" /></span> </a>
                                    <a (click)="openCard(userList.patient_id,user,i,'card_'+i,false)"
                                        class="white-text">
                                        <span class="closeicon"><img src="../../../assets/img/close_icon.png"
                                                alt="Card image cap" style="width:25px"> </span>
                                    </a>
                                </div>
                            </mdb-card-header>
                            <mdb-card-body>
                                <div *ngIf="user == 'History'">
                                    <app-physio-history></app-physio-history>
                                </div>
                                <div *ngIf="user == 'Medical Prescription'">
                                    <app-physio-med-prescription></app-physio-med-prescription>
                                </div>
                                <div *ngIf="user == 'Clinical Exam'">
                                    <app-physio-clinical></app-physio-clinical>
                                </div>
                                <div *ngIf="user == 'Diet Plan'">
                                    <app-physio-diet-plan></app-physio-diet-plan>
                                </div>
                                <div *ngIf="user == 'Appointments'">
                                    <app-physio-appointment-detailview></app-physio-appointment-detailview>
                                </div>
                                <div *ngIf="user == 'Habitual History'">
                                    <app-physio-habitual></app-physio-habitual>
                                </div>
                                <div *ngIf="user == 'Treatment Plan'">
                                    <app-physio-treatment-plan></app-physio-treatment-plan>
                                </div>
                                <div *ngIf="user == 'Neurological'">
                                    <app-physio-neurological></app-physio-neurological>
                                </div>
                            </mdb-card-body>
                            <a *ngIf="!cardViewFlag" (click)="openCard(userList.patient_id,user,i,'card_'+i,true)">
                                <div class="mask waves-effect waves-light" mdbWavesEffect></div>
                            </a>
                        </mdb-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="noRxModal" tabindex="-1"
        aria-labelledby="noRxModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content" style="border-radius: 20px !important;top: 166px;">
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 d-flex align-items-end">
                                <p class="noRx_content">Are you sure of closing this appointment without any
                                    prescription?</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: none !important;">
                    <div class="closeoption"><span (click)="closeAppointment('yes')" class="noRx_yes_no">Yes</span>
                    </div>
                    <div class="closeoption"><span (click)="closeAppointment('no')" class="noRx_yes_no">No</span> </div>
                </div>
            </div>
        </div>
    </div>
</div>