import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
// import { ipaddress.getIp, Ip_with_img_address, ipaddress.country_code } from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { TreatmentService } from '../service';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Date_Formate } from '../../../assets/js/common';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';


@Component({
  selector: 'app-materialorder',
  templateUrl: './materialorder.component.html',
  styleUrls: ['./materialorder.component.css']
})
export class MaterialorderComponent implements OnInit {

  dentalmaterialorderflag = 'min';
  public Temp_str;
  tomorrow = new Date();
  subscription: Subscription;
  public description_hidden: boolean;
  public Temp_str1;
  public Material_data_array = [];
  public send_Material_data_array = [];
  public header_footer_flag;
  public temp_select_array = [];
  public material_array = [];
  public mary_txt;
  public canti_txt;
  public post_txt;
  public gingval_txt;
  public shoulder_txt;
  public tube_checkbox;
  public post_check;
  public onlay_check;
  public inlay_check;
  public metal_check;
  public ceram_check;
  public digital_txt;
  public opp_yes;
  public opp_toothyes;
  public implant_screw;
  public red_wax;
  public red_date;
  public white_tax;
  public white_date;
  public bisque_date;
  public bisque;
  public metal_tax;
  public metal_date;
  public die_yes;
  public coping_individual;
  public splinted_cpoing;
  public lingual_metal;
  public proximal_broad;
  public pro_point;
  public textureno;
  public textlow;
  public texthigh;
  public followadjust;
  public glazelow;
  public glazeregular;
  public glazehigh;

  public trans_regular;
  public trans_low;
  public trann_high;
  public snitary;
  public fullridge
  public modified;
  public bullet;
  public ovate;
  public valreg;
  public vallow;
  public valhigh;
  public occno;
  public occulight;
  public occmedium;
  public occdark;
  public rct_yes;
  public rct_data;
  public occu_metal;
  public occu_adjust;
  public occu_reduction;
  public stains;
  public whitespot;
  public enclose_bite;
  public enclose_articulator;
  public enclose_shade;
  public enclose_preop;
  public enclose_temporary;
  public enclose_addition;
  public enclose_oldwork;
  public enclose_tray;
  public noof_abutment;
  public noof_screw;
  public immpression_replica;
  public impress_coping;
  public catable_abdument;
  public matimp_others;
  public noof_temp;
  shade_txt: string;
  public digital_data;
  cervical_txt: string;
  public middle_txt;
  public incisal_txt;
  public send_reddate;
  public send_whitedate;
  public send_metaldate;
  public send_bisqdate;
  public dietpre;
  public margin_preparation;
  public copy_des;
  public proximaltxt;
  public texture_data;
  public glaze_data;
  public regular_data;
  public snitary_data;
  public value_data;
  public occulusal_data;
  //public rct_data;
  public occ_island;
  public staineffect_data;
  public enclose_data;
  public main_note;
  // public material_array;
  public material_data_image = [];
  public pres_id;
  public diag_id;
  public opp_no;
  public rct_no;
  public die_no;
  public Material_Upper_DataArray = [];
  public Material_Lower_DataArray = [];
  Material_Upper_Image: any;
  Material_Lower_Image: any;
  public print_div: boolean;
  public material_hidden: boolean;
  public doctor_name: string;
  public materialflag: boolean = false;

  public ReqDate;
  materialType: string;
  notetxt: string;
  public temp_select_array1 = [];

  patent_name: string;
  gender_name: string;
  client_age: string;
  doctor_place: string;
  clinic_name: string;
  order_date: string;
  public client:boolean = false;
  public Maincheck = [];
  public curr_date;
  public Appointment_Date;
  public get_date;

  public currentDate;
  public currentTime;
  public currentYear

  constructor(public http: Http, public toastr: ToastrService, public messageservice: TreatmentService, public messageservice1: CasesheetService,
    public dropdown_dialog: MatDialog,public masterData:MasterCSHelperService) {
    this.doctor_place = Helper_Class.getHospitalInfo().location;
    this.clinic_name = Helper_Class.getHospitalInfo().hosp_name;

    this.patent_name = Doc_Helper.getClient_Info().client_name;
    this.gender_name = Doc_Helper.getClient_Info().Gender_data;
    this.client_age = Doc_Helper.getClient_Info().Age_data;
    if (this.client_age == 'undefined') {
      this.client_age = "";
    }

    this.material_hidden = true;
    this.print_div = true;
    this.doctor_name = Helper_Class.getProfilename();

    this.Material_Upper_DataArray.push(""); // 0
    this.Material_Upper_DataArray.push(""); // 1
    this.Material_Upper_DataArray.push(""); // 2
    this.Material_Upper_DataArray.push(""); // 3
    this.Material_Upper_DataArray.push(""); // 4
    this.Material_Upper_DataArray.push(""); // 5
    this.Material_Upper_DataArray.push(""); // 6
    this.Material_Upper_DataArray.push(""); // 7
    this.Material_Upper_DataArray.push(""); // 8
    this.Material_Upper_Image = "../../../assets/img/material_icons/dental_upper.png";
    this.Material_Lower_Image = "../../../assets/img/material_icons/dental_lower.png";

    this.enclose_oldwork == false;
    this.enclose_articulator = false;
    this.enclose_shade = false;
    this.enclose_preop = false;
    this.enclose_temporary = false;
    this.enclose_addition = false;
    this.enclose_tray = false;
    this.enclose_bite = false;
  }

  async ngOnInit(){
    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.dentalmaterialorderflag = message;
      }
    });
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }
    
    this.getCurrentDate();
    this.doctor_place = Helper_Class.getHospitalInfo().location;
    this.clinic_name = Helper_Class.getHospitalInfo().hosp_name;

    this.patent_name = Doc_Helper.getClient_Info().client_name;
    this.gender_name = Doc_Helper.getClient_Info().Gender_data;
    this.client_age = Doc_Helper.getClient_Info().Age_data;
    if (this.client_age == 'undefined') {
      this.client_age = "";
    }            

    this.material_hidden = true;
    this.print_div = true;
    this.doctor_name = Helper_Class.getProfilename();

    this.Material_Upper_DataArray.push(""); // 0
    this.Material_Upper_DataArray.push(""); // 1
    this.Material_Upper_DataArray.push(""); // 2
    this.Material_Upper_DataArray.push(""); // 3
    this.Material_Upper_DataArray.push(""); // 4
    this.Material_Upper_DataArray.push(""); // 5
    this.Material_Upper_DataArray.push(""); // 6
    this.Material_Upper_DataArray.push(""); // 7
    this.Material_Upper_DataArray.push(""); // 8
    this.Material_Upper_Image = "../../../assets/img/material_icons/dental_upper.png";
    this.Material_Lower_Image = "../../../assets/img/material_icons/dental_lower.png";

    this.enclose_oldwork == false;
    this.enclose_articulator = false;
    this.enclose_shade = false;
    this.enclose_preop = false;
    this.enclose_temporary = false;
    this.enclose_addition = false;
    this.enclose_tray = false;
    this.enclose_bite = false;

    if(masterCSData_Helper.getMasterMaterialData() == undefined){
      await this.masterData.getMaterialData();
      this.get_Material_data_array();
    }else{
      this.get_Material_data_array();
    }
  }

  getCurrentDate() {
    var get_date;
    var obj = Master_Helper.getMasterCurrentDate();
    
    if (obj.current_date != null) {
      this.curr_date = obj.current_date;
      this.currentDate = Date_Formate((this.curr_date));
      this.get_date = obj.current_date.split('-');
      var currentDate = this.get_date[2] + "-" + this.get_date[1] + "-" + this.get_date[0]
      this.currentYear = this.get_date[0];
      this.currentTime = obj.current_time;
      this.currentDate = obj.current_date;
      this.ReqDate = this.curr_date;
      this.red_date = this.curr_date;
      this.white_date = this.curr_date;
      this.metal_date = this.curr_date;
      this.bisque_date = this.curr_date;
      // this.get_doc_apps(this.user_id);

    }
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  material_Upper_Data_click(datapoint) {
    this.Temp_str = datapoint;

    if (datapoint == "1") {
      if (this.Material_Upper_DataArray[0] == "") {
        this.Material_Upper_DataArray[0] = "1";
      } else {
        this.Material_Upper_DataArray[0] = "";
      }
    } else if (datapoint == "2") {
      if (this.Material_Upper_DataArray[1] == "") {
        this.Material_Upper_DataArray[1] = "2";
      } else {
        this.Material_Upper_DataArray[1] = "";
      }
    } else if (datapoint == "3") {
      if (this.Material_Upper_DataArray[2] == "") {
        this.Material_Upper_DataArray[2] = "3";
      } else {
        this.Material_Upper_DataArray[2] = "";
      }
    } else if (datapoint == "4") {
      if (this.Material_Upper_DataArray[3] == "") {
        this.Material_Upper_DataArray[3] = "4";
      } else {
        this.Material_Upper_DataArray[3] = "";
      }
    } else if (datapoint == "5") {
      if (this.Material_Upper_DataArray[4] == "") {
        this.Material_Upper_DataArray[4] = "5";
      } else {
        this.Material_Upper_DataArray[4] = "";
      }
    } else if (datapoint == "6") {
      if (this.Material_Upper_DataArray[5] == "") {
        this.Material_Upper_DataArray[5] = "6";
      } else {
        this.Material_Upper_DataArray[5] = "";
      }
    } else if (datapoint == "7") {
      if (this.Material_Upper_DataArray[6] == "") {
        this.Material_Upper_DataArray[6] = "7";
      } else {
        this.Material_Upper_DataArray[6] = "";
      }
    } else if (datapoint == "8") {
      if (this.Material_Upper_DataArray[7] == "") {
        this.Material_Upper_DataArray[7] = "8";
      } else {
        this.Material_Upper_DataArray[7] = "";
      }
    } else if (datapoint == "9") {
      if (this.Material_Upper_DataArray[8] == "") {
        this.Material_Upper_DataArray[8] = "9";
      } else {
        this.Material_Upper_DataArray[8] = "";
      }
    }

    var Total_Value = this.Material_Upper_DataArray[0] + this.Material_Upper_DataArray[1] + this.Material_Upper_DataArray[2] +
    this.Material_Upper_DataArray[3] + this.Material_Upper_DataArray[4] + this.Material_Upper_DataArray[5] +
    this.Material_Upper_DataArray[6] + this.Material_Upper_DataArray[7] + this.Material_Upper_DataArray[8];

    this.Set_Upper_Image(Total_Value);
    this.send_data();
  }

  Set_Upper_Image(Total_Value) {
    if (Total_Value == "") {
      this.Material_Upper_Image = "assets/imgs/Dental_accordian_icons/dental_upper.png";
    } else {
      if (Total_Value == "1") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper1.png";
      } else if (Total_Value == "2") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper2.png";
      } else if (Total_Value == "3") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper3.png";
      } else if (Total_Value == "4") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper4.png";
      } else if (Total_Value == "5") {
        // $scope.Material_Upper_Image = "../../Images/teeth_images/lower1.png";
      } else if (Total_Value == "6") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper5.png";
      } else if (Total_Value == "7") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper6.png";
      } else if (Total_Value == "8") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper7.png";
      } else if (Total_Value == "9") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images//upper8.png";
      } else if (Total_Value == "12" || Total_Value == "13" || Total_Value == "23" || Total_Value == "123") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper9.png";
      } else if (Total_Value == "45" || Total_Value == "46" || Total_Value == "56" || Total_Value == "456") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images//upper10.png";
      } else if (Total_Value == "78" || Total_Value == "79" || Total_Value == "89" || Total_Value == "789") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper11.png";
      } else if (Total_Value == "14" || Total_Value == "17" || Total_Value == "47" || Total_Value == "147") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images//upper12.png";
      } else if (Total_Value == "25" || Total_Value == "28" || Total_Value == "58" || Total_Value == "258") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper13.png";
      } else if (Total_Value == "36" || Total_Value == "39" || Total_Value == "69" || Total_Value == "369") {
        this.Material_Upper_Image = "../../../assets/imgs/teeth_images/upper14.png";
      } else {
        this.Material_Upper_DataArray[0] = "";
        this.Material_Upper_DataArray[1] = "";
        this.Material_Upper_DataArray[2] = "";
        this.Material_Upper_DataArray[3] = "";
        this.Material_Upper_DataArray[4] = "";
        this.Material_Upper_DataArray[5] = "";
        this.Material_Upper_DataArray[6] = "";
        this.Material_Upper_DataArray[7] = "";
        this.Material_Upper_DataArray[8] = "";

        this.material_Upper_Data_click(this.Temp_str);
      }
    }
  }

  material_Lower_Data_click(datapoint) {
    this.Temp_str1 = datapoint;

    if (datapoint == "1") {
      this.Material_Lower_DataArray = [];
      if (this.Material_Lower_DataArray[0] == "") {
        this.Material_Lower_DataArray[0] = "1";

      } else {
        this.Material_Lower_DataArray[0] = "";
      }

    } else if (datapoint == "2") {
      if (this.Material_Lower_DataArray[1] == "") {
        this.Material_Lower_DataArray[1] = "2";

      } else {
        this.Material_Lower_DataArray[1] = "";
      }

    } else if (datapoint == "3") {
      if (this.Material_Lower_DataArray[2] == "") {
        this.Material_Lower_DataArray[2] = "3";

      } else {
        this.Material_Lower_DataArray[2] = "";
      }

    } else if (datapoint == "4") {
      if (this.Material_Lower_DataArray[3] == "") {
        this.Material_Lower_DataArray[3] = "4";

      } else {
        this.Material_Lower_DataArray[3] = "";
      }

    } else if (datapoint == "5") {
      if (this.Material_Lower_DataArray[4] == "") {
        this.Material_Lower_DataArray[4] = "5";

      } else {
        this.Material_Lower_DataArray[4] = "";
      }

    } else if (datapoint == "6") {
      if (this.Material_Lower_DataArray[5] == "") {
        this.Material_Lower_DataArray[5] = "6";

      } else {
        this.Material_Lower_DataArray[5] = "";
      }

    } else if (datapoint == "7") {
      if (this.Material_Lower_DataArray[6] == "") {
        this.Material_Lower_DataArray[6] = "7";

      } else {
        this.Material_Lower_DataArray[6] = "";
      }

    } else if (datapoint == "8") {
      if (this.Material_Lower_DataArray[7] == "") {
        this.Material_Lower_DataArray[7] = "8";

      } else {
        this.Material_Lower_DataArray[7] = "";
      }

    } else if (datapoint == "9") {
      if (this.Material_Lower_DataArray[8] == "") {
        this.Material_Lower_DataArray[8] = "9";

      } else {
        this.Material_Lower_DataArray[8] = "";
      }
    }

    var Total_Value = this.Material_Lower_DataArray[0] + this.Material_Lower_DataArray[1] + this.Material_Lower_DataArray[2] +
      this.Material_Lower_DataArray[3] + this.Material_Lower_DataArray[4] + this.Material_Lower_DataArray[5] +
      this.Material_Lower_DataArray[6] + this.Material_Lower_DataArray[7] + this.Material_Lower_DataArray[8];

    this.Set_Lower_Image(Total_Value);
    this.send_data();
  }

  Set_Lower_Image(Total_Value) {
    if (Total_Value == "") {
      this.Material_Lower_Image = "../../../assets/img/Dental_accordian_icons/dental_lower.png";

    } else {


      if (Total_Value == "1") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower1.png";

      } else if (Total_Value == "2") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower2.png";

      } else if (Total_Value == "3") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower3.png";

      } else if (Total_Value == "4") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower4.png";

      } else if (Total_Value == "5") {
        // $scope.Material_Upper_Image = "../../Images/teeth_images/lower1.png";

      } else if (Total_Value == "6") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower5.png";

      } else if (Total_Value == "7") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower6.png";

      } else if (Total_Value == "8") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower7.png";

      } else if (Total_Value == "9") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower8.png";

      } else if (Total_Value == "12" || Total_Value == "23" || Total_Value == "13" || Total_Value == "123") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower9.png";

      } else if (Total_Value == "45" || Total_Value == "46" || Total_Value == "56" || Total_Value == "456") {
        this.Material_Lower_Image = "../../../assets/img/lower10.png";

      } else if (Total_Value == "78" || Total_Value == "79" || Total_Value == "89" || Total_Value == "789") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower11.png";

      } else if (Total_Value == "14" || Total_Value == "17" || Total_Value == "47" || Total_Value == "147") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower12.png";

      } else if (Total_Value == "25" || Total_Value == "28" || Total_Value == "58" || Total_Value == "258") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower13.png";

      } else if (Total_Value == "36" || Total_Value == "39" || Total_Value == "69" || Total_Value == "369") {
        this.Material_Lower_Image = "../../../assets/img/teeth_images/lower14.png";

      } else {
        this.Material_Lower_DataArray[0] = "";
        this.Material_Lower_DataArray[1] = "";
        this.Material_Lower_DataArray[2] = "";
        this.Material_Lower_DataArray[3] = "";
        this.Material_Lower_DataArray[4] = "";
        this.Material_Lower_DataArray[5] = "";
        this.Material_Lower_DataArray[6] = "";
        this.Material_Lower_DataArray[7] = "";
        this.Material_Lower_DataArray[8] = "";

        this.material_Lower_Data_click(this.Temp_str1);
      }
    }
  }
  refresh_upperclick() {
    this.Material_Upper_DataArray.push("");
    this.Material_Upper_Image = "../../../assets/img/Dental_accordian_icons/dental_upper.png";
    this.send_data();
  }

  refresh_lowerclick() {
    this.Material_Lower_DataArray.push("");
    this.Material_Lower_Image = "../../../assets/img/Dental_accordian_icons/dental_lower.png";
    this.send_data();
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   this.send_data();
  // }

  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }
  send_data() {
    var gen_hist = null, tube_data = "0", post_data = "0", onlay_data = "0", inlay_data = "0", ceram_data = "0", metal_data = "0", opp_data = "0", implant_data = "0", digital_data = "0",
      dietpre = "0";
    //material data
    if (this.tube_checkbox == true) {
      
      tube_data = "1";
      


    }

    if (this.post_check == true) {
      post_data = "1";
    }

    if (this.onlay_check == true) {
      onlay_data = "1";
    }

    if (this.inlay_check == true) {
      inlay_data = "1";
    }

    if (this.ceram_check == true) {
      ceram_data = "1";
    }

    if (this.metal_check == true) {
      metal_data = "1";
    }

    if (this.opp_yes == true) {
      opp_data = "1";
    }

    if (this.implant_screw == true) {
      implant_data = "1";
    }

    if (this.digital_txt == true) {
      digital_data = "1";
    }

    if (this.red_wax == true && this.red_date != undefined) {
      var red_datetxt = this.red_date.split('-');
      this.send_reddate = red_datetxt[2] + "-" + red_datetxt[1] + "-" + red_datetxt[0];
    }

    if (this.white_tax == true && this.white_date != undefined) {
      var white_datetxt = this.white_date.split('-');
      this.send_whitedate = white_datetxt[2] + "-" + white_datetxt[1] + "-" + white_datetxt[0];
    }

    if (this.metal_tax == true && this.metal_date != undefined) {
      var metal_datetxt = this.metal_date.split('-');
      this.send_metaldate = metal_datetxt[2] + "-" + metal_datetxt[1] + "-" + metal_datetxt[0];
    }

    if (this.bisque == true && this.bisque_date != undefined) {
      var bisq_datetxt = this.bisque_date.split('-');
      this.send_bisqdate = bisq_datetxt[2] + "-" + bisq_datetxt[1] + "-" + bisq_datetxt[0];
    }

    if (this.die_yes == true) {
      dietpre = "1";
    }

    if (this.coping_individual == true) {
      this.copy_des = "1";

    } else if (this.splinted_cpoing == true) {
      this.copy_des = "2";

    } else if (this.lingual_metal == true) {
      this.copy_des = "3";
    }

    if (this.proximal_broad == true) {
      this.proximaltxt = "Broad";

    } else if (this.pro_point == true) {
      this.proximaltxt = "Point";
    }

    if (this.textureno == true) {
      this.texture_data = "No";

    } else if (this.textlow == true) {
      this.texture_data = "Low";

    } else if (this.texthigh == true) {
      this.texture_data = "High";

    } else if (this.followadjust == true) {
      this.texture_data = "Follow adjacent tooth texture";
    }

    if (this.glazelow == true) {
      this.glaze_data = "Low";

    } else if (this.glazeregular == true) {
      this.glaze_data = "Regular";

    } else if (this.glazehigh == true) {
      this.glaze_data = "High";
    }

    if (this.trans_regular == true) {
      this.regular_data = "Regular";

    } else if (this.trans_low == true) {
      this.regular_data = "Low";

    } else if (this.trann_high == true) {
      this.regular_data = "High";
    }

    if (this.snitary == true) {
      this.snitary_data = "Sanitary";

    } else if (this.fullridge == true) {
      this.snitary_data = "Full Ridge";

    } else if (this.modified == true) {
      this.snitary_data = "Modified";

    } else if (this.bullet == true) {
      this.snitary_data = "Bullet";

    } else if (this.ovate == true) {
      this.snitary_data = "Ovate";
    }

    if (this.valreg == true) {
      this.value_data = "Low";

    } else if (this.vallow == true) {
      this.value_data = "Regular";

    } else if (this.valhigh == true) {
      this.value_data = "High";
    }

    if (this.occno == true) {
      this.occulusal_data = "No";

    } else if (this.occulight == true) {
      this.occulusal_data = "Light";

    } else if (this.occmedium == true) {
      this.occulusal_data = "Medium";

    } else if (this.occdark == true) {
      this.occulusal_data = "Dark";
    }

    if (this.rct_yes == true) {
      this.rct_data = "1";

    } else {
      this.rct_data = "0";
    }

    if (this.occu_metal == true) {
      this.occ_island = "Metal island";

    } else if (this.occu_adjust == true) {
      this.occ_island = "Adjust Opposing";

    } else if (this.occu_reduction == true) {
      this.occ_island = "Reduction coping (chargeable)";
    }

    if (this.stains == true) {
      this.staineffect_data = "Enamel Cracks";

    } else if (this.whitespot == true) {
      this.staineffect_data = "White spots";
    }

    if (this.enclose_bite == true) {

      this.enclose_data = "Bite";
      


    } else if (this.enclose_articulator == true) {
      this.enclose_data = "Articulator";

    } else if (this.enclose_shade == true) {
      this.enclose_data = "Shade tooth/Tab";

    } else if (this.enclose_preop == true) {
      this.enclose_data = "Pre-op models";

    } else if (this.enclose_temporary == true) {
      this.enclose_data = "Model-with-temporary";

    } else if (this.enclose_addition == true) {
      this.enclose_data = "Additional Model";

    } else if (this.enclose_oldwork == true) {
      this.enclose_data = "Old work(Adjust / Remarks)";

    } else if (this.enclose_tray == true) {
      this.enclose_data = "Tray enclosed";
    }

    for (var i = 0; i < this.Material_Upper_DataArray.length; i++) {
      if (this.Material_Upper_DataArray[i] != "") {
        this.material_data_image.push("U" + this.Material_Upper_DataArray[i]);
      }
    }

    for (var i = 0; i < this.Material_Lower_DataArray.length; i++) {
      
      

      if (this.Material_Lower_DataArray[i] != "") {
        this.material_data_image = [];
        this.material_data_image.push("L" + this.Material_Lower_DataArray[i]);
      }
    }

    gen_hist = {
      tube: tube_data,
      post_core: post_data,
      onlay: onlay_data,
      inlay: inlay_data,
      ceramic_coating: ceram_data,
      full_metal_rest: metal_data,
      opposing_tooth: opp_data,
      implant_screw_driver: implant_data,
      abutment: this.noof_abutment,
      screw: this.noof_screw,

      impression_replica: this.immpression_replica,
      impression_coping: this.impress_coping,
      castable_abutment: this.catable_abdument,
      den_mat_others: this.matimp_others,
      temporary_abutment: this.noof_temp,
      shade: this.shade_txt,
      digital_photo: this.digital_data,
      cervical: this.cervical_txt,
      middle: this.middle_txt,
      incisal: this.incisal_txt,
      red_wax: this.red_date,
      white_wax: this.white_date,
      metal: this.metal_date,
      bisque: this.bisque_date,
      die_preparation: this.dietpre,
      margin_prep: this.margin_preparation,
      coping_design: this.copy_des,
      proximal_contact: this.proximaltxt,
      mat_texure: this.texture_data,
      glaze: this.glaze_data,
      translucency: this.regular_data,

      pontic: this.snitary_data,
      value: this.value_data,
      occl_staining: this.occulusal_data,
      rct: this.rct_data,
      occlusal_clearance: this.occ_island,
      stains_effect: this.staineffect_data,

      enclosures: this.enclose_data,
      mat_notes: this.main_note,
      material_type: this.temp_select_array,
      shade_area: this.material_data_image,
    }

    Doc_Helper.Set_material_data(gen_hist);
    
  }
  print_area() {
    var enclose = "0", opp_tooth = "0", rct = "0", occ_clear = "0", die_prepare = "0", cop_design = "0", phon_disgn = "0", proximal_contact = "0", trans_txt = "0",
      texture_txt = "0", glaze_txt = "0", value_txt = "0", occ_stain_txt = "0", stain_effect_txt = "0";

    if (this.stains == true) {
      stain_effect_txt = "1";

    } else if (this.whitespot == true) {
      stain_effect_txt = "2";
    }

    if (this.occno == true) {
      occ_stain_txt = "1";

    } else if (this.occulight == true) {
      occ_stain_txt = "2";

    } else if (this.occmedium == true) {
      occ_stain_txt = "3";

    } else if (this.occdark == true) {
      occ_stain_txt = "4";
    }

    if (this.valreg == true) {
      value_txt = "1";

    } else if (this.vallow == true) {
      value_txt = "2";

    } else if (this.valhigh == true) {
      value_txt = "3";
    }

    if (this.glazeregular == true) {
      glaze_txt = "1";

    } else if (this.glazelow == true) {
      glaze_txt = "2";

    } else if (this.glazehigh == true) {
      glaze_txt = "3";
    }

    if (this.textureno == true) {
      texture_txt = "1";

    } else if (this.textlow == true) {
      texture_txt = "2";

    } else if (this.texthigh == true) {
      texture_txt = "3";

    } else if (this.followadjust == true) {
      texture_txt = "4";
    }

    if (this.trans_regular == true) {
      trans_txt = "1";

    } else if (this.trans_low == true) {
      trans_txt = "2";

    } else if (this.trann_high == true) {
      trans_txt = "3";
    }

    if (this.proximal_broad == true) {
      proximal_contact = "1";

    } else if (this.pro_point == true) {
      proximal_contact = "2";
    }

    if (this.snitary == true) {
      phon_disgn = "1";

    } else if (this.fullridge == true) {
      phon_disgn = "2";

    } else if (this.modified == true) {
      phon_disgn = "3";

    } else if (this.bullet == true) {
      phon_disgn = "4";

    } else if (this.ovate == true) {
      phon_disgn = "5";
    }

    if (this.coping_individual == true) {
      cop_design = "1";

    } else if (this.splinted_cpoing == true) {
      cop_design = "2";

    } else if (this.lingual_metal == true) {
      cop_design = "3";
    }

    if (this.die_yes == true) {
      die_prepare = "1";

    } else if (this.die_no == true) {
      die_prepare = "2";
    }

    if (this.occu_metal == true) {
      occ_clear = "1";

    } else if (this.occu_adjust == true) {
      occ_clear = "2";

    } else if (this.occu_reduction == true) {
      occ_clear = "3";
    }

    if (this.enclose_bite == true) {
      enclose = "1";

    } else if (this.enclose_articulator == true) {
      enclose = "2";

    } else if (this.enclose_shade == true) {
      enclose = "3";

    } else if (this.enclose_preop == true) {
      enclose = "4";

    } else if (this.enclose_temporary == true) {
      enclose = "5";

    } else if (this.enclose_addition == true) {
      enclose = "6";

    } else if (this.enclose_oldwork == true) {
      enclose = "7";

    } else if (this.enclose_tray == true) {
      enclose = "8";
    }

    if (this.opp_yes == true) {
      opp_tooth = "1";
    }

    if (this.opp_no == true) {
      opp_tooth = "2";
    }

    if (this.rct_yes == true) {
      rct = "1";
    }

    if (this.rct_no == true) {
      rct = "2";
    }

    var digital_data = "0", implant_data = "0", bisque_data = "0";
    if (this.digital_txt == true) {
      digital_data = "1";
    }

    if (this.implant_screw == true) {
      implant_data = "1";
    }

    if (this.bisque == true) {
      bisque_data = "1";
    }
    // let myModal = this.modalCtrl.create("DentalPrintViewPage", {
    //   occu_metal: this.occu_metal,
    //   orderdate: this.order_date,
    //   ReqDate: this.ReqDate,
    //   upperteeth: this.Material_Upper_DataArray,
    //   lowerteeth: this.Material_Lower_DataArray,
    //   material_array: this.material_array,
    //   red_wax: this.red_date,
    //   white_wax: this.white_date,
    //   metal_Date: this.metal_date,
    //   bisque_date: this.bisque_date,

    //   bisque: bisque_data,
    //   immpression: this.immpression_replica,
    //   impress_copy: this.impress_coping,
    //   noof_abut: this.noof_abutment,
    //   noof_screw: this.noof_screw,
    //   catable_abdut: this.catable_abdument,
    //   noof_temp: this.noof_temp,
    //   matimp_others: this.matimp_others,

    //   implant: implant_data,
    //   digitalphoto: digital_data,
    //   incisal: this.incisal_txt,
    //   tube: this.tube_checkbox,
    //   post_Core: this.post_check,
    //   onlay: this.onlay_check,
    //   inlay: this.inlay_check,
    //   rest_coat: this.ceram_check,
    //   metal: this.metal_check,
    //   opp_rest: opp_tooth,
    //   rct_data: rct,
    //   occ_clear_data: occ_clear,
    //   enclose_data: enclose,
    //   cervical_data: this.cervical_txt,
    //   middle_data: this.middle_txt,
    //   shade_data: this.shade_txt,
    //   die_pre_data: die_prepare,
    //   copy_data: cop_design,
    //   pontic_data: phon_disgn,
    //   proximal_data: proximal_contact,
    //   trans_data: trans_txt,
    //   texture_data: texture_txt,
    //   glaze_data: glaze_txt,
    //   value_data: value_txt,
    //   occ_stain_data: occ_stain_txt,
    //   stains_data: stain_effect_txt,
    //   opp_teethyes: this.opp_toothyes,
    //   opp_teethno: this.opp_no,
    //   main_note: this.main_note
    // });
    // myModal.present();
  }

  material_popup() {
    // let alert_data = this.alert_ctrl.create({
    //   title: 'Material order',
    //   cssClass: 'alertCustomCss',
    //   inputs: this.Material_data_array,
    //   buttons: [
    //     {
    //       cssClass: 'alert_done_btn',
    //       handler: data => {
    //         
    //         this.materialChange(data);
    //       }
    //     },
    //     {
    //       cssClass: 'alert_cancel_btn',
    //       role: 'cancel',
    //       handler: data => {
    //         
    //       }
    //     }
    //   ],
    // });
    // alert_data.present();
  }

  materialChange(selectedValue) {
    this.materialType = "";
    
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.temp_select_array = selectedValue;

    if (this.temp_select_array.length != 0) {
      var n = 0;

      for (var j = 0; j < this.temp_select_array.length; j++) {
        for (var i = 0; i < this.Material_data_array.length; i++) {
          if (this.temp_select_array[j] == this.Material_data_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }

            selected_data.append(this.Material_data_array[i].label);
          }
        }
      }
      this.materialType = selected_data.toString();
      
    }

  }
  get_Material_data_array() {
    var obj = masterCSData_Helper.getMasterMaterialData();
    if(obj != undefined){
      for (var i = 0; i < obj.dental_materials.length; i++) {
        this.Material_data_array.push({
          type: 'checkbox',
          value: obj.dental_materials[i].mat_type_id,
          label: obj.dental_materials[i].description,
          checked: false
        });
      }
    }
  }

  mat_click() {
    var mary_data = "no", canti_data = "no", post_data = "no", gingiva_data = "no", shoulder_data = "no";

    if (this.mary_txt == true) {
      mary_data = "yes";
    }

    if (this.canti_txt == true) {
      canti_data = "yes";
    }

    if (this.post_txt == true) {
      post_data = "yes";
    }

    if (this.gingval_txt == true) {
      gingiva_data = "yes";
    }

    if (this.shoulder_txt == true) {
      shoulder_data = "yes";
    }

    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    if (this.temp_select_array.length == 0) {
      this.toastr.error(Message_data.sltMaterialord);

    } else {
      var mat_order = "";

      var sb = new StringBuilder("")
      for (var i = 0; i < this.temp_select_array.length; i++) {
        for (var j = 0; j < this.Material_data_array.length; j++) {
          if (this.temp_select_array[i] == this.Material_data_array[j].value) {
            if (mat_order == "") {
              mat_order = this.Material_data_array[j].value;

            } else {
              mat_order = mat_order + "," + this.Material_data_array[j].value;
            }
            sb.append(this.Material_data_array[j].label + ', ');
          }
        }
      }

      this.material_array.push({
        materialdata: sb.toString(),
        materialiddata: this.temp_select_array,
        maryland: mary_data,
        gum_colour: gingiva_data,
        shoulder_procelain: shoulder_data,
        cantilever: canti_data,
        post_crown: post_data
      })
      this.mary_txt = false;
      this.canti_txt = false;
      this.post_txt = false;
      this.gingval_txt = false;
      this.shoulder_txt = false;
      this.temp_select_array = [];
      this.materialType = "";
    }
  }

  material_delete(mat_order) {
    for (var i = 0; i < this.material_array.length; i++) {
      if (mat_order == this.material_array[i].materialdata) {
        this.material_array.splice(i, 1);
        break;
      }
    }
    this.send_data();
  }

  matdata_change(select) {
    this.temp_select_array = select;
  }

  Opposing_yes_fun() {
    if (this.opp_yes == true) {
      this.opp_no = false;
    }
  }

  Opposing_no_fun() {
    if (this.opp_no == true) {
      this.opp_yes = false;
    }
  }

  occ_clear_metal_fun() {
    if (this.occu_metal == true) {
      this.occu_adjust = false;
      this.occu_reduction = false;
    }
  }

  occ_clear_adjust_fun() {
    if (this.occu_adjust == true) {
      this.occu_metal = false;
      this.occu_reduction = false;
    }
  }

  occ_clear_red_coping_fun() {
    if (this.occu_reduction == true) {
      this.occu_adjust = false;
      this.occu_metal = false;
    }
  }

  rct_yes_fun() {
    if (this.rct_yes == true) {
      this.rct_no = false;
    }
  }

  rct_no_fun() {
    if (this.rct_no == true) {
      this.rct_yes = false;
    }
  }

  enclosures_bite_fun(e) {
    if (this.enclose_bite == true) {

      this.enclose_articulator = false;
      this.enclose_shade = false;
      this.enclose_preop = false;
      this.enclose_temporary = false;
      this.enclose_addition = false;
      this.enclose_oldwork = false;
      this.enclose_tray = false;
    }
  }

  enclosures_arti_fun() {
    if (this.enclose_articulator == true) {
      this.enclose_shade = false;
      this.enclose_preop = false;
      this.enclose_temporary = false;
      this.enclose_addition = false;
      this.enclose_oldwork = false;
      this.enclose_tray = false;
      this.enclose_bite = false;
    }
  }

  enclosures_shade_fun() {
    if (this.enclose_shade == true) {
      this.enclose_articulator = false;
      this.enclose_preop = false;
      this.enclose_temporary = false;
      this.enclose_addition = false;
      this.enclose_oldwork = false;
      this.enclose_tray = false;
      this.enclose_bite = false;
    }
  }

  enclosures_pre_op_fun() {
    if (this.enclose_preop == true) {
      this.enclose_articulator = false;
      this.enclose_shade = false;
      this.enclose_temporary = false;
      this.enclose_addition = false;
      this.enclose_oldwork = false;
      this.enclose_tray = false;
      this.enclose_bite = false;
    }
  }

  enclosures_mode_temp_fun() {
    if (this.enclose_temporary == true) {
      this.enclose_articulator = false;
      this.enclose_shade = false;
      this.enclose_preop = false;

      this.enclose_addition = false;
      this.enclose_oldwork = false;
      this.enclose_tray = false;
      this.enclose_bite = false;
    }
  }

  enclosures_add_mode_fun() {
    if (this.enclose_addition == true) {
      this.enclose_articulator = false;
      this.enclose_shade = false;
      this.enclose_preop = false;
      this.enclose_temporary = false;

      this.enclose_oldwork = false;
      this.enclose_tray = false;
      this.enclose_bite = false;
    }
  }

  enclosures_old_work_fun() {
    if (this.enclose_oldwork == true) {
      this.enclose_articulator = false;
      this.enclose_shade = false;
      this.enclose_preop = false;
      this.enclose_temporary = false;
      this.enclose_addition = false;
      this.enclose_tray = false;
      this.enclose_bite = false;
    }
  }

  enclosures_tray_fun() {
    if (this.enclose_tray == true) {
      this.enclose_articulator = false;
      this.enclose_shade = false;
      this.enclose_preop = false;
      this.enclose_temporary = false;
      this.enclose_addition = false;
      this.enclose_oldwork = false;
      this.enclose_bite = false;
    }
  }

  die_yes_fun() {
    if (this.die_yes == true) {
      this.die_no = false;
    }
  }

  die_no_fun() {
    if (this.die_no == true) {
      this.die_yes = false;
    }
  }

  copy_indi_fun() {
    if (this.coping_individual == true) {
      this.splinted_cpoing = false;
      this.lingual_metal = false;
    }
  }

  copy_spl_fun() {
    if (this.splinted_cpoing == true) {
      this.coping_individual = false;
      this.lingual_metal = false;
    }
  }

  copy_ling_fun() {
    if (this.lingual_metal == true) {
      this.splinted_cpoing = false;
      this.coping_individual = false;
    }
  }

  sant_fun() {
    if (this.snitary == true) {
      this.fullridge = false;
      this.modified = false;
      this.bullet = false;
      this.ovate = false;
    }
  }

  ridge_fun() {
    if (this.fullridge == true) {

      this.modified = false;
      this.bullet = false;
      this.ovate = false;
      this.snitary = false;
    }
  }

  modi_fun() {
    if (this.modified == true) {
      this.fullridge = false;
      this.bullet = false;
      this.ovate = false;
      this.snitary = false;
    }
  }

  bullet_fun() {
    if (this.bullet == true) {
      this.fullridge = false;
      this.modified = false;
      this.ovate = false;
      this.snitary = false;
    }
  }

  ovate_fun() {
    if (this.ovate == true) {
      this.fullridge = false;
      this.modified = false;
      this.bullet = false;
      this.snitary = false;
    }
  }

  Proxi_board_fun() {
    if (this.proximal_broad == true) {
      this.pro_point = false;
    }
  }

  Proxi_point_fun() {
    if (this.pro_point == true) {
      this.proximal_broad = false;
    }
  }

  Trans_regular_fun() {
    if (this.trans_regular = true) {
      this.trans_low = false;
      this.trann_high = false;
    }
  }

  Trans_low_fun() {
    if (this.trans_low = true) {
      this.trans_regular = false;
      this.trann_high = false;
    }
  }

  Trans_high_fun() {
    if (this.trann_high = true) {
      this.trans_low = false;
      this.trans_regular = false;
    }
  }

  Texture_no_fun() {
    if (this.textureno == true) {
      this.textlow = false;
      this.texthigh = false;
      this.followadjust = false
    }
  }

  Texture_low_fun() {
    if (this.textlow == true) {
      this.textureno = false;
      this.texthigh = false;
      this.followadjust = false
    }
  }

  Texture_High_fun() {
    if (this.texthigh == true) {
      this.textlow = false;
      this.textureno = false;
      this.followadjust = false
    }
  }

  Texture_adj_fun() {
    if (this.followadjust == true) {
      this.textureno = false;
      this.texthigh = false;
      this.textlow = false
    }
  }

  Glaze_reg_fun() {
    if (this.glazeregular == true) {
      this.glazelow = false;
      this.glazehigh = false;
    }
  }

  Glaze_low_fun() {
    if (this.glazelow == true) {
      this.glazeregular = false;
      this.glazehigh = false;
    }
  }

  Glaze_high_fun() {
    if (this.glazehigh == true) {
      this.glazelow = false;
      this.glazeregular = false;
    }
  }

  Value_reg_fun() {
    if (this.valreg == true) {
      this.vallow = false;
      this.valhigh = false;

    }
  }

  Value_low_fun() {
    if (this.vallow == true) {

      this.valhigh = false;
      this.valreg = false;
    }
  }

  Value_high_fun() {
    if (this.valhigh == true) {
      this.vallow = false;

      this.valreg = false;
    }
  }

  Occu_no_fun() {
    if (this.occno == true) {
      this.occulight = false;
      this.occmedium = false;
      this.occdark = false;
    }
  }

  Occu_light_fun() {
    if (this.occulight == true) {
      this.occmedium = false;
      this.occdark = false;
      this.occno = false;
    }
  }

  Occu_medium_fun() {
    if (this.occmedium == true) {
      this.occulight = false;
      this.occdark = false;
      this.occno = false;
    }
  }

  Occu_dark_fun() {
    if (this.occdark == true) {
      this.occulight = false;
      this.occmedium = false;
      this.occno = false;
    }
  }

  Stains_crack_fun() {
    if (this.stains == true) {
      this.whitespot = false;
    }
  }

  Stains_white_fun() {
    if (this.whitespot == true) {
      this.stains = false;
    }
  }
  materialpop(data, id) {
    this.temp_select_array1.push(id)
    this.materialChange(this.temp_select_array1)
  }

  closematerial() {
    this.materialflag = false;
  }

  material() {
    this.materialflag = true;
    for (var j = 0; j < this.Material_data_array.length; j++) {
      this.Material_data_array[j].checked = false;
    }

    if (this.temp_select_array != undefined && this.temp_select_array.length != 0) {
      for (var i = 0; i < this.temp_select_array.length; i++) {
        for (var j = 0; j < this.Material_data_array.length; j++) {
          if (this.temp_select_array[i] == this.Material_data_array[j].value) {
            this.Material_data_array[j].checked = true;
          }
        }
      }
    }
  }

  OnDateMatePicker(e) {

    this.ReqDate = e;
  }
  OnDateMatePicker1(e) {

    this.white_date = e;
  }
  OnDateMatePicker2(e) {

    this.red_date = e;
  }
  OnDateMatePicker3(e) {

    this.metal_date = e;
    
  }
  OnDateMatePicker4(e) {

    this.bisque_date = e;
  }
  mainconcernpopup() {
    this.Material_data_array
      for (var j = 0; j < this.Material_data_array.length; j++) {
        this.Material_data_array[j].checked = false;
      }

      if (this.Maincheck != undefined && this.Maincheck.length != 0) {
        for (var i = 0; i < this.Maincheck.length; i++) {
          for (var j = 0; j < this.Material_data_array.length; j++) {
            if (this.Maincheck[i] == this.Material_data_array[j].value) {
              this.Material_data_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
        width: '600px',
        height: '400px',
        panelClass: 'drop-down',
        data: {
          title: "Concerns",
          main_array: this.Material_data_array,
          selected_list: this.Maincheck,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.main_click(result);
        }
      });
   
  }
  main_click(selected) {
    this.materialType = "";
    
    function StringBuilder(this:any , value)   {
      
       this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Maincheck = selected;
    this.temp_select_array = selected;
    if (this.Maincheck.length != 0) {
      var n = 0;
      

      for (var j = 0; j < this.Maincheck.length; j++) {
        
        
        for (var i = 0; i < this.Material_data_array.length; i++) {
          
          
          if (this.Maincheck[j] == this.Material_data_array[i].value) {
            
            
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Material_data_array[i].label);
          }
        }
      }
      

      this.materialType = selected_data.toString();
      
    }
  }
}
