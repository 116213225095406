import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import {Message_data} from '../../../assets/js/Message_data';

import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {
  public cancel_checkbox
  public hosp_list = [];
  public Nurse_New_List = [];
  public send_nurse_list = [];
  public hospital_list: string;
  public location_list: string;
  public hosp_id = null;
  public nodapp: boolean;
  public listshow:boolean;
  public header_footer_flag: boolean;
  public userinfo;
  public ipaddress;
  
//mdbTable
// dtOptions: DataTables.Settings = {};
persons: any = [];
masterSelected: boolean = false;
checklist:any;
checkedList:any
  constructor(public toastr: ToastrService,public http: Http,public router:Router) {
  }

  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();
    this.send_nurse_list = [];
    this.nodapp = true; // hide
    this.listshow=true;
    this.get_hosp_list();
  }

  remove_duplicates(arr) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i]] = true;
    }
    arr = [];
    for (let key in obj) {
      arr.push(key);
    }
    return arr;
  }

  set_list(data) {
    this.Nurse_New_List = [];
    var hptl_id1 = null, loc_id1 = null;

    if (this.hosp_list.length != 0) {
      if (data == "0" || this.hospital_list == undefined) {
        for (var i = 0; i < this.hosp_list.length; i++) {
          if (i == 0) {
            hptl_id1 = this.hosp_list[i].hosp_id;
            loc_id1 = this.hosp_list[i].location;
            break;
          }
        }

      } else {
        for (var i = 0; i < this.hosp_list.length; i++) {
          if (this.hospital_list == this.hosp_list[i].hosp_name && this.location_list == this.hosp_list[i].location) {
            hptl_id1 = this.hosp_list[i].hosp_id;
            loc_id1 = this.hosp_list[i].location;
            break;
          }
        }
      }
    }

    var data = null;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/nurbyhospid/',
      JSON.stringify({
        doc_reg_id: this.userinfo.user_id,
        hptl_id: hptl_id1,
        location: loc_id1
      }),
      { headers: headers })
      .subscribe(
        response => {
          var data =response.json();
          if (data.nurses != null) {
            for (var i = 0; i < data.nurses.length; i++) {
              var Doctors_Name = "";
              var doctor = false;
              for (var j = 0; j < data.nurses[i].doctors.length; j++) {
                if (data.nurses[i].doctors[j].doc_reg_id == this.userinfo.user_id) {
                  this.hosp_id = hptl_id1;
                  var nur_id = data.nurses[i].nurse_reg_id;

                  if (this.send_nurse_list.length != 0) {
                    var flag = true;
                    if (flag == true) {
                      this.send_nurse_list.push(data.nurses[i].nurse_reg_id);
                    }
                  } else {
                    this.send_nurse_list.push(data.nurses[i].nurse_reg_id);
                  }
                  doctor = true;

                  if (Doctors_Name == "") {
                    Doctors_Name = "your";
                  } else {
                    Doctors_Name = " Dr. " + Doctors_Name + " & your";
                  }
                } else {
                  if (Doctors_Name == "") {
                    if(data.nurses[i].doctors[j].middle_name != undefined){
                      Doctors_Name = " Dr. " + data.nurses[i].doctors[j].first_name + " " + data.nurses[i].doctors[j].middle_name + " " + data.nurses[i].doctors[j].last_name;
                    } else {
                      Doctors_Name = " Dr. " + data.nurses[i].doctors[j].first_name + " " + data.nurses[i].doctors[j].last_name;
                    }                 
                  } else {
                    if(data.nurses[i].doctors[j].middle_name != undefined){
                      Doctors_Name = Doctors_Name + ", " + " Dr. " + data.nurses[i].doctors[j].first_name + " " + data.nurses[i].doctors[j].middle_name + " " + data.nurses[i].doctors[j].last_name;

                    } else {
                      Doctors_Name = Doctors_Name + ", " + " Dr. " + data.nurses[i].doctors[j].first_name + " " + data.nurses[i].doctors[j].last_name;
                    }
                  }
                }
              }

              if (data.nurses[i].middle_name != undefined) {
                var nurse_name = data.nurses[i].first_name + " " + data.nurses[i].middle_name + " " + data.nurses[i].last_name;
              } else {
                nurse_name = data.nurses[i].first_name + " " + data.nurses[i].last_name;
              }

              if(Doctors_Name !=""){
                this.Nurse_New_List.push({
                  Nurse_ID: data.nurses[i].nurse_reg_id,
                  Nurse_Name: nurse_name,
                  Doctor_Names: "handles " + Doctors_Name+" appointments",
                  nurse_check: doctor,
                  hptl_id: hptl_id1,
                  });

              } else {
                this.Nurse_New_List.push({
                  Nurse_ID: data.nurses[i].nurse_reg_id,
                  Nurse_Name: nurse_name,
                  Doctor_Names:"",
                  nurse_check: doctor,
                  hptl_id: hptl_id1,
                });
              }
            }

            if (this.Nurse_New_List.length == 0) {
              this.nodapp = false; // show
              this.listshow=true; //hide
            } else {
              this.nodapp = true; // hide
              this.listshow=false; //show
            }
          } else {
            this.toastr.error(Message_data.getNetworkMessage())
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  get_hosp_list() {
    var obj = Master_Helper.getMasterHospitalList();
    if (obj.hospitals != null) {
      for (var i = 0; i < obj.hospitals.length; i++) {
        this.hosp_list.push({
          hosp_id: obj.hospitals[i].hosp_id,
          hosp_name: obj.hospitals[i].hosp_name,
          location: obj.hospitals[i].location,
        })
        let filteredNames = this.remove_duplicates(this.hosp_list);
      }
      this.hospital_list = this.hosp_list[0].hosp_name;
      this.location_list = this.hosp_list[0].location;
    }else{
      this.toastr.error(Message_data.getNetworkMessage());
    }
    this.set_list("0");
  }

  permission() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/docnurperm',
      JSON.stringify({
        doc_reg_id: this.userinfo.user_id,
        nurse_reg_id: this.send_nurse_list,
        hptl_clinic_id: this.hosp_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj =response.json();
          if (obj.key != null) {
            if (obj.key == "1") {
              this.toastr.success(obj.result);
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  filter_click() {
    this.set_list("1");
  }

  multi_cancel_App(hptl_id, nur_id,check,e) {
    if (check== true) {
      this.hosp_id = hptl_id;
      this.send_nurse_list.push(nur_id)
    } else {
      for (var i = 0; i < this.send_nurse_list.length; i++) {
        if (nur_id == this.send_nurse_list[i]) {
          this.send_nurse_list.splice(this.send_nurse_list[i].indexOf(nur_id), 1);
          break;
        }
      }
    }
  }
}
