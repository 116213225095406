import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import {Message_data} from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-recept-bill-edit',
  templateUrl: './recept-bill-edit.component.html',
  styleUrls: ['./recept-bill-edit.component.css']
})
export class ReceptBillEditComponent implements OnInit {
  public docRegId;
  public billType: string;
  public amount;
  public finalAmount;
  public inGST: boolean;
  public concession;
  public sendConcession;
  public amountAftCon;
  public gstAmount;
  public advanceBill;
  public appointmentDate;
  public billDetailedView:any = [];
  public sendBillAppointId;
  public hospitalName;
  public billTreatmentPlanList:any = [];
  public gstRetriveList = [];
  public billAddList:any = [];
  public paymentsarray:any=[];
  public gstDataList:any = [];
  public chargeName;
  public estimateId:any = [];
  public remainingBalance;
  public cgst;
  public sgst;
  public clientRegId;
  public relationId;
  public subRelId;
  public ChargeId;
  public docId: string;
  public billId: string;
  public splName: string;
  public payTypeList = [];
  public insurerList =[];
  public payType: string;
  public insurerId: string;
  public insurFlag:boolean;
  public getBillEdit;

  //Get hospital detials
  public patientName: string;
  public prevBillId;
  public balanceFlag: boolean;
  public amountCollected: boolean;
  public prevbalanceFlag: boolean;
  public prevbalance;
  public totalCollected;
   
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public transactionFlag:boolean;
  public transactionId;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag:boolean;
  public bankName;
  public serviceType = [];
  public inpatientFlag: boolean = false;
  public quantity = 1;
  public discount = "0.00";
  public paidBill = "0.00";
  public updateFlag:boolean = true;
  public updater;
  public finyear:string;
  private sno:number=0;
  public tamt:number=0;
  public paytypreq: boolean=false;
  public payType1;
  public amountval:number;
  private userInfo;

  constructor(public toastr: ToastrService, public http: Http,
    public frontservice: MenuViewService, public adminservice:MenuViewService){
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.finalAmount = "0";
    this.gstAmount = "0";
    this.advanceBill = "0.00";
    this.concession = 0;
    this.inGST = false;
    //this.payType = "Cash";
  }

  ngOnInit(): void {
    this.updateFlag = true;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if(Helper_Class.getIsAdmin() != undefined){
      this.updater = "Admin";
    }else{
      this.updater = "Reception";
    }
    this.userInfo = Helper_Class.getInfo();
    if(this.userInfo.bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }
    this.getBillEdit = FrontDesk_Helper.getreceptbilledit();
    this.billId =  this.getBillEdit.bill_view_id;
    this.docId = this.getBillEdit.doc_reg_id;
    this.finyear = this.getBillEdit.fin_year;
    this.inpatientFlag = this.getBillEdit.is_inaptient == "yes" ? true : false;
    this.docRegId = this.getBillEdit.doc_reg_id;
    this.splName = Helper_Class.getspecalization_frontdesk();
    this.Get_current_date();
    this.gstRetriveList = [];
    this.billAddList = [];
    this.Get_Gst_Data();
    this.Get_Bill_Type();
    this.estimateId = [];
    this.prevbalanceFlag = true;
    this.balanceFlag = true;
    this.getBillDetails();
    this.getPaymentType();
    this.getInsurers();
  }
  
  Bill_add_click() {//Bill add table
    if (this.billType == undefined) {
      this.toastr.error(Message_data.sltServiceType);
    } else if (this.amount == undefined || this.amount == '') 
      this.toastr.error(Message_data.enterAmt);
    else if (this.quantity == undefined) 
      this.toastr.error(Message_data.enterQty);
    else {
      if (this.finalAmount == "0") {
        this.inGST == false;
      }

      var flag = true;

      for (var i = 0; i < this.billAddList.length; i++) {
        if(this.billAddList[i].status != '2'){
          if (this.billAddList[i].biill_type == this.billType && this.billAddList[i].paytype == this.payType) {
            flag = false;
            break;
          }
        }
      }

      if (flag == true) {
        if (this.chargeName.length != 0) {
          for (var i = 0; i < this.chargeName.length; i++) {
            if (this.billType == this.chargeName[i].description) {
              this.ChargeId = this.chargeName[i].charge_id;
            }
          }
        }
        var paydesc;
        if (this.payTypeList.length != 0) {
          for (var i = 0; i < this.payTypeList.length; i++) {
            if (this.payType == this.payTypeList[i].pay_id) {
              paydesc = this.payTypeList[i].pay_desc;
            }
          }
        }

        this.sendConcession = this.concession == "" ? 0 : this.concession;
        this.sno+=1;
        this.billAddList.push({
          sno:this.sno.toString(),
          biill_type: this.billType,
          amount_txt: parseFloat(this.amount).toFixed(2),
          concession: parseFloat(this.sendConcession).toFixed(2),
          fee: parseFloat(this.amountAftCon).toFixed(2),
          insurer:this.insurerId,
          charge_type: this.ChargeId,
          pay_type:paydesc,
          paytype:this.payType,
          status: "1",
          quantity:this.quantity,
          fin_year:this.finyear,
        });
        this.billType = undefined;
        this.amount = '';
        this.concession = 0;
        this.amountAftCon = '';
        this.quantity = 1;
      }

      if (this.finalAmount == "0") {
        this.inGST == false;
      }
      this.Calculate_gst("0", "0");
    }
  }

  consolidatedBilling(){
    var inpatientBill = {
        app_id : this.getBillEdit.app_id,
        flow: "inpatien-edit",
        bill_id:this.billId,
        updater_type : "front-desk",
    }
    Helper_Class.setInpatientBillEdit(inpatientBill);
    Helper_Class.setInpatientBillCreate("front-desk");
    this.frontservice.sendMessage("ipatientBillCreate");
  }

  Delete_bill(type, amount,bill) {//Delete bill
    if (this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if(this.billAddList[i].status != '2'){
          if(this.billAddList[i].bill_details_id == bill.bill_details_id){
            if (this.billAddList[i].biill_type == type && this.billAddList[i].amount_txt == amount) {
              if(this.billAddList[i].bill_details_id != undefined){
                bill.status = "2";
                  this.Calculate_gst("0", "0");
                  break;

              } else {
                this.billAddList.splice(i, 1);
              }
            }
          }
        }
      }
    }
    this.sno=0;
    for (var i = 0; i < this.billAddList.length; i++) {
      if (this.billAddList[i].status != '2') {
        this.sno +=1;
        this.billAddList[i].sno=this.sno;
      }
    }

    if (this.finalAmount == "0") {
      this.inGST == false;
    }

    this.Calculate_gst("0", "0");
  }

  Get_Bill_Type() {//Get bill type
    var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      JSON.stringify({
        hptl_clinic_id: Helper_Class.getHospitalId(),
        provider: type,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.chargeName = obj.charges;
          
        },
        error => {
         this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  charge_change(charge){
    this.billType = charge;
    this.serviceType = [];
      for (var i = 0; i < this.chargeName.length; i++) {
        if (this.chargeName[i].description == this.billType) {
          this.amount = this.chargeName[i].amount;
          this.amountAftCon = this.chargeName[i].amount;
        }
      }  
  }

  getPaymentType() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.payTypeList = obj.payments;
          //this.payType = this.payTypeList[0].pay_id;
        },
        error => {
         this.toastr.error(Message_data.defaultErr);
        });
  }

  pay_change(){
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3")
      this.insurFlag = false;
    else if(this.payType == "4")
      this.transactionFlag = false;
    else if(this.payType == "2"){
      this.transactionFlag = false;
      this.cardFlag = false;
    }else if(this.payType == "5")
      this.chequeFlag = false;
    else 
      this.insurFlag = true;
  }

  getInsurers() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  Get_current_date() {//Get current date
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if (obj.current_date != null) {
            var get_date = obj.current_date.split('-');
            this.appointmentDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
          }
        },
        error => {
         this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getBillDetails() {
    var send;
    var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
    if(Helper_Class.getIsAdmin() != undefined){
      send = {
        doc_reg_id: this.docId,
        billing_id: this.billId,
        app_type:type,
        bill_to:"edit",
        fin_year: this.finyear,
      }
    }else{
      send = {
        doc_reg_id: this.docId,
        billing_id: this.billId,
        spl_name: this.splName,
        app_type:type,
        bill_to:"edit",
        fin_year: this.finyear,
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/',
      JSON.stringify(send),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.hospitalName=obj.hptl_name;
          var saldata;
          saldata = obj.salutation != undefined ? obj.salutation : "";

          if (obj.middle_name != null && obj.middle_name != undefined) {
            this.patientName = saldata+" "+encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
          } else {
            this.patientName = saldata+" "+encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
          }
          
          if (obj.advance != null && obj.advance != undefined){
            this.advanceBill = (obj.adv_balance != null && obj.adv_balance != undefined) ? (parseFloat(obj.advance) + parseFloat(obj.adv_balance)).toFixed(2) : parseFloat(obj.advance).toFixed(2);
          
          }else{
            this.advanceBill = (obj.adv_balance != null && obj.adv_balance != undefined) ? (parseFloat(obj.adv_balance)).toFixed(2) : "0.00";
          }
            
          //this.advanceBill = (parseInt(obj.advance)+parseInt(obj.adv_balance)).toFixed(2);
          this.clientRegId = obj.client_reg_id;
          this.finalAmount= encrypt_decript.Decript(obj.bill_amount);
          this.paidBill = obj.paid_amount != undefined ? obj.paid_amount : "0.00";
          if(obj.cgst != undefined){
            this.inGST= true;
            this.include_gst_click(this.inGST);
          }
          this.discount = obj.round_off !=  undefined ? obj.round_off : "0.00";
          
          if (obj.estimates != null) {
            var rowcount=1;
            for (var i = 0; i < obj.estimates.length; i++) {
              var checkflag;
              if (this.billId == obj.estimates[i].bill_id){
                checkflag = true;
                this.estimateId.push(obj.estimates[i].den_treatment_plan_id);
              } else {
                checkflag = false;
              }
              
              this.billTreatmentPlanList.push({
                treatment_desc: obj.estimates[i].treatment_desc,
                estimate: obj.estimates[i].estimate,
                den_treatment_plan_id: obj.estimates[i].den_treatment_plan_id,
                rowid:rowcount,
                checked:checkflag,
              });
              rowcount++;
            }
          }

          if (obj.bills != null) {
            for(var i=0;i<obj.bills.length;i++){            
              var perce = obj.bills[i].concession / 100;
              var amt = obj.bills[i].fee;
              var amt_cons = this.amountAftCon;
              var net_pay = parseFloat(amt) * parseFloat(perce.toString());
              var tot = amt - net_pay;
              if (isNaN(tot)) {
                tot = 0;
              }
              //this.amountAftCon = Math.round(tot);
              var lineitem = (parseFloat(obj.bills[i].fee)/(100- parseFloat(obj.bills[i].concession))*100);
              var quantity = 1;
              if(obj.bills[i].quantity != undefined){
                quantity = obj.bills[i].quantity;
              }
              var concession = obj.bills[i].concession != undefined && obj.bills[i].concession != null && obj.bills[i].concession != "" ?  obj.bills[i].concession : "0";
              var fee = obj.bills[i].fee != undefined && obj.bills[i].fee != null && obj.bills[i].fee != "" ?   obj.bills[i].fee : "0.00";
              var lineitem = (parseFloat(fee)/(100 - parseInt(concession))*100);
              this.sno=parseInt(obj.bills[i].index);
              this.billAddList.push({
                sno:this.sno,
                biill_type: obj.bills[i].charge_desc,
                amount_txt: parseFloat(lineitem.toString()).toFixed(2),
                concession: concession,
                fee: fee,
                charge_type: obj.bills[i].charge_type,
                paytype:obj.bills[i].pay_id,
                pay_type:obj.bills[i].pay_type,
                status:"0",
                quantity:quantity,
                bill_details_id:obj.bills[i].bill_details_id,
                fin_year:obj.bills[i].fin_year,
              });
            }
            
            if(obj.cgst != undefined){
              this.inGST= true;
              this.include_gst_click(this.inGST);
            }

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            }

            var tot_amt = "0";
            if (obj.bills != null && obj.bills.length != 0) {
              for (var i = 0; i < obj.bills.length; i++) {
                if (obj.bills[i].fee != null) {
                  tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                }
              }
            }

            if (obj.estimates != null) {
              if (obj.estimates.length != 0) {
                for (var i = 0; i < obj.estimates.length; i++) {
                  if (obj.estimates[i].estimate != null) {
                    tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                  }
                }
              }
            }

            var docname = "";
            if(obj.dr_middle_name != undefined)
              docname = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            else
              docname = obj.dr_first_name + " " + obj.dr_last_name;

            this.billDetailedView = {
              client_reg_id:obj.client_reg_id,
              doc_app_id:obj.appointment_id,
              relation_id:obj.relation_id != undefined ? obj.relation_id : "1",
              sub_rel_id:obj.sub_rel_id != undefined ? obj.sub_rel_id : undefined,
              docid_name: docname,
              doc_reg_id:obj.doc_reg_id,
            }
            
            if(obj.payments != undefined){
              for(var i=0;i<obj.payments.length;i++){
                this.paymentsarray.push({
                  pay_id: obj.payments[i].pay_id,
                  pay_desc:obj.payments[i].pay_desc,
                  amount:obj.payments[i].amount
                })
              }
            }
            this.addRound('minus');
          } else {
            this.toastr.error(Message_data.getNetworkMessage())
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage())
        });
  }
 
  Appointmnetid_change() {
    this.billTreatmentPlanList = [];
    if (this.billDetailedView.length != 0) {
        if (this.billDetailedView.docid_name == this.docRegId) {
          this.sendBillAppointId = this.billDetailedView.doc_app_id;
        }
    }

    this.Get_Bill_Type();
    var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getappdet',
      JSON.stringify({
        appointment_id: this.sendBillAppointId,
        doc_reg_id: localStorage.getItem("user_id"),
        type: type,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.hospitalName = obj.hosp_name;
          if (obj.middle_name !=  undefined) {
            this.patientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
          } else {
            this.patientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
          }     

          if (obj.phases != null) {
            for (var i = 0; i < obj.phases.length; i++) {
              this.billTreatmentPlanList.push({
                treatment_desc: obj.phases[i].treatment_desc,
                estimate: obj.phases[i].estimate,
                den_treatment_plan_id: obj.phases[i].den_treatment_plan_id,
              });
            }
          }

          if (obj.advance != null && obj.advance != undefined){
            this.advanceBill = (obj.adv_balance != null && obj.adv_balance != undefined) ? (parseFloat(obj.advance) + parseFloat(obj.adv_balance)).toFixed(2) : parseFloat(obj.advance).toFixed(2);
          
          }else{
            this.advanceBill = (obj.adv_balance != null && obj.adv_balance != undefined) ? (parseFloat(obj.adv_balance)).toFixed(2) : "0.00";
          }

          if (obj.prev_bill_id != null)
            this.prevBillId = obj.prev_bill_id;

          if (obj.balance != "0") {
            this.amountCollected = true;
            this.balanceFlag = false;
            this.prevbalanceFlag = false;
            this.prevbalance = parseFloat(obj.balance).toFixed();
            this.finalAmount = parseFloat(obj.balance).toFixed();
            this.totalCollected = parseFloat(obj.balance).toFixed();
          }
        },
        error => {});
  }

  amount_change() {// concession calculation
    var perce = null;
    perce = this.concession / 100;
    var amt = this.amount;
    if(this.quantity > 1){
      amt = amt * this.quantity;
    }
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAftCon = Math.round(tot);
  }

  amount_change_edit(bill) {
    this.finalAmount=0;
    for(var i=0;i<this.billAddList.length;i++) {
      if(bill.biill_type == this.billAddList[i].biill_type) {
        var perce = null;
        perce = bill.concession / 100;
        var amount;
        if(this.billAddList[i].quantity > 1){
          amount = parseFloat(bill.amount_txt) * this.billAddList[i].quantity;
        }
        var disamt = parseFloat(amount) * parseFloat(perce.toString());
        this.billAddList[i].fee = parseFloat(amount) - disamt;
        if (isNaN(this.billAddList[i].fee)) {
          this.billAddList[i].fee = 0;
        }
      }
      this.finalAmount +=parseFloat(this.billAddList[i].fee)
      this.remainingBalance = parseFloat(this.finalAmount)- (parseFloat(this.discount)+parseFloat(this.advanceBill)+parseFloat(this.paidBill))
      if(parseFloat(this.remainingBalance)<0){
        this.remainingBalance="0.00";
      }
    }
  }

  Get_Gst_Data() { //Get gst
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/tax/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.gstRetriveList = obj.taxes;
        },
        error => {
         this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  include_gst_click(check) {// Include GST
    this.Calculate_gst("0", "0");
  }

  Calculate_gst(palnid, planmodal) {
    this.finalAmount = "0";
    this.gstAmount = "0";
    this.totalCollected = "0";
    this.gstDataList = [];

    if (this.billTreatmentPlanList.length != 0) {
      for (var i = 0; i < this.billTreatmentPlanList.length; i++) {
        for (var j = 0; j < this.estimateId.length; j++) {
          if (this.estimateId[j] == this.billTreatmentPlanList[i].den_treatment_plan_id) {
            this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billTreatmentPlanList[i].estimate)).toFixed(2);
            this.gstAmount = (parseFloat(this.gstAmount) + parseFloat(this.billTreatmentPlanList[i].estimate)).toFixed(2);

            if (parseFloat(this.advanceBill) < parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.totalCollected = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill)).toFixed(2);
              this.remainingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill)).toFixed(2);
            }

            if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.remainingBalance = (parseFloat(this.advanceBill) - parseFloat(this.finalAmount)).toFixed(2);
            }

            this.amountCollected = true;

            if (this.totalCollected == "0") 
              this.amountCollected = false;

            if (this.remainingBalance == "0")
              this.balanceFlag = true;
            break;
          }
        }
      }
    }

    for (var i = 0; i < this.billAddList.length; i++) {
      if(this.billAddList[i].status != '2'){
        this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billAddList[i].fee)).toFixed(2);
        this.gstAmount = (parseFloat(this.gstAmount) + parseFloat(this.billAddList[i].fee)).toFixed(2);
        if (parseFloat(this.paidBill) < parseFloat(this.finalAmount)) 
          this.remainingBalance = (parseFloat(this.finalAmount) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill))).toFixed(2);

        if (this.totalCollected == "0") 
          this.amountCollected = false;

        if (this.remainingBalance == "0")
          this.balanceFlag = true;
      }
      console.log("balance 123 "+this.remainingBalance)
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetriveList.length; i++) {
        this.gstDataList.push({
          Gst_Descrip: this.gstRetriveList[i].tax_desc + " " + this.gstRetriveList[i].tax+"%",
          Gst_amount: (parseFloat(this.gstAmount) * (parseFloat(this.gstRetriveList[i].tax) / 100)).toFixed(2)
        });
        this.finalAmount = (parseFloat(this.finalAmount) + (parseFloat(this.gstAmount) * (parseFloat(this.gstRetriveList[i].tax) / 100)));
      }
      this.finalAmount = parseFloat(this.finalAmount).toFixed(2);
    }

    if (this.prevbalance != undefined) 
      this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.prevbalance)).toFixed(2);

    //amount to be collected
    this.remainingBalance = (parseFloat(this.finalAmount) - (parseFloat(this.advanceBill) + parseFloat(this.paidBill))).toFixed(2);
    console.log("balance "+this.remainingBalance)
    if (this.estimateId.length == 0 && this.billAddList.length == 0) {
      this.inGST = false;
      this.gstDataList = [];
      this.advanceBill = "0"
      this.finalAmount = "0";
      this.gstAmount = 0;
      this.totalCollected = "0";
      this.remainingBalance = "0";
    }

    if (parseFloat(this.finalAmount)<parseFloat(this.advanceBill)) 
      this.remainingBalance = "0";
  }

  //add the bill details
  advance_change() {
    if (this.advanceBill != undefined && this.advanceBill != "")
      this.remainingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill)).toFixed(2);
    else
      this.remainingBalance = this.finalAmount;

    if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount))
      this.remainingBalance = "0";
  }

  backClicked() {
    if(Helper_Class.getIsAdmin() != undefined)
      this.adminservice.sendMessage("bill_list");
    else
      this.frontservice.sendMessage('frontdeskbilling');
  }       

  bill_click(planid, planmodal, rowid){
    if(this.estimateId.length !=0){
      if(this.estimateId.indexOf(planid) >-1){
        for(var i=0;i<this.estimateId.length;i++){
          if(this.estimateId[i] == planid){
            this.estimateId.splice(i,1);
            this.Calculate_gst("0", "0");
          }
        }   
      } else {
        this.estimateId.push(planid)
        this.Calculate_gst("0", "0");
      }
    } else {
      this.estimateId.push(planid);
      this.Calculate_gst("0", "0");
    }
    
    for (var i = 0; i < this.billTreatmentPlanList.length; i++) {
      for (var j = 0; j < this.estimateId.length; j++) {
        if (this.estimateId[j] == this.billTreatmentPlanList[i].den_treatment_plan_id){
          this.billTreatmentPlanList[i].checked=true;
        }
      }
    }
  }

  //Bill save
  Bill_update() {
    var flag = true;

    if (this.billAddList.length == 0 && this.estimateId.length == 0) {
     this.toastr.error(Message_data.addbill);
      flag = false;
    }

    if (flag == true) {
      this.updateFlag = false;
      if (this.billDetailedView.length != 0) {
        if (this.docRegId == this.billDetailedView.docid_name) {
          this.clientRegId = this.billDetailedView.client_reg_id;
          this.relationId = this.billDetailedView.relation_id;
          if (this.billDetailedView.sub_rel_id != undefined) {
            this.subRelId = this.billDetailedView.sub_rel_id;
          }
        }
        this.sendBillAppointId = this.billDetailedView.doc_app_id;
      }

      if (this.inGST == true && this.gstRetriveList.length != 0) {
        this.cgst = this.gstRetriveList[0].tax;
        this.sgst = this.gstRetriveList[1].tax;
      }

      var balance = parseInt(this.remainingBalance) < 0 ? "0.00" : this.remainingBalance.toString();
      var paid_flag;
      if( this.finalAmount == this.remainingBalance ){
        paid_flag = "Un paid";
      }else if (this.finalAmount != this.remainingBalance && (this.remainingBalance != "0.00")){
        paid_flag = "Partially paid";
      }else if( this.remainingBalance == "0.00" || this.remainingBalance == "0" || this.remainingBalance == 0.00 || this.remainingBalance == 0 ){
        paid_flag = "Fully paid";
      }
      var advance;
      var totalAdvance;
      if( parseFloat(this.finalAmount) == (parseFloat(this.paidBill) + parseFloat(this.advanceBill))){
        advance = "0.00";
        totalAdvance = parseFloat(this.advanceBill).toFixed(2);

      }else if( parseFloat(this.finalAmount) < parseFloat(this.advanceBill) ){
        advance = ((parseFloat(this.advanceBill) - parseFloat(this.finalAmount))).toFixed(2);
        totalAdvance = (parseFloat(this.advanceBill) - parseFloat(advance)).toFixed(2);

      }else{
        advance = "0.00";
        totalAdvance = (parseFloat(this.advanceBill)).toFixed(2);
      }
      var send_datavalue;
      var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
      send_datavalue =  {
        bill_id: this.billId,
        app_type: type,
        doc_reg_id: this.docId,
        bill_amount: encrypt_decript.Encript(this.finalAmount.toString()).toString(),
        paid_amount:this.paidBill,
        paid_flag : paid_flag,
        created_by: this.docId,
        country: ipaddress.country_code,  
        advance_balance: advance,
        advance: totalAdvance,
        //advance: this.advanceBill,
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
        balance: balance,
        cgst: this.cgst,
        sgst: this.sgst,
        appointment_id : this.sendBillAppointId,
        prev_bal: this.prevbalance,
        prev_bill_id: this.prevBillId,
        hptl_clinic_id:Helper_Class.getHospitalId(),
        round_off : this.discount,
        updater_type: this.updater,
        card_no : this.cardNumber,
        card_holder_name : this.cardHolderName,
        transaction_no : this.transactionId,
        bank_name: this.bankName,
        estimates: this.estimateId,
        fin_year:this.finyear,
        bills: this.billAddList,
        payments:this.paymentsarray,
      };

      if (this.subRelId != null && this.subRelId != undefined && this.subRelId != "" && this.subRelId.length != 0)
        send_datavalue.sub_rel_id = this.subRelId;

      localStorage.setItem("sent_bill", this.finalAmount);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/ubill/', JSON.stringify(send_datavalue),      
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj["result"] != null) {
              if (obj["result"] == "Bill updated successful") {
                this.updateFlag = false;
                this.toastr.success(Message_data.billUpdSuccess);
                if(Helper_Class.getIsAdmin() != undefined)
                  this.adminservice.sendMessage("bill_list");
                else
                  this.frontservice.sendMessage('frontdeskbilling');
              } else {
                this.updateFlag = true;
               this.toastr.error(Message_data.unableToUpdBill);;
              }
            }
          },
          error => {
            this.updateFlag = true;
           this.toastr.error(Message_data.getNetworkMessage());
          });
    }else{
      this.updateFlag = true;
    }
  }

  getServiceType(serviceType){
    this.serviceType = [];
    if(serviceType.length >= 3){
      if(this.chargeName.length != 0){
        if(this.chargeName.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()))){
          var chg = this.chargeName.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()));
          for(var i= 0;i < chg.length; i++){
            this.serviceType.push(chg[i].description);
          }
        }else{
          this.serviceType = [];
        }
      }
    }
  }

  addRound(data) {
    this.discount = this.discount == "" ? "0.00" : this.discount;
    if (data == "puls")
      this.remainingBalance = ((parseFloat(this.finalAmount) + parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill))).toFixed(2);
    else
      this.remainingBalance = ((parseFloat(this.finalAmount) - parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill))).toFixed(2);
  }

  paid_change() {
    this.paidBill = this.paidBill == "" ? "0.00" : this.paidBill;
    this.remainingBalance = ((parseFloat(this.finalAmount) - parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill))).toFixed(2);
  }

  addPaymentDetails(){
    this.tamt+=this.amountval;

    if(this.tamt > parseInt(this.finalAmount)){
      this.toastr.error(Message_data.getGreaterAmount());
      this.tamt -=this.amountval;
    } else {
      for(var i=0;i<this.payTypeList.length;i++){
        if(this.payType1 == this.payTypeList[i].pay_id){
          this.paymentsarray.push({
            pay_id:this.payTypeList[i].pay_id,
            pay_desc:this.payTypeList[i].pay_desc,
            amount:this.amountval.toFixed(2)
          })
          this.amountval=0;
        }
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=parseInt(data.amount);
    
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.tamt -=this.paymentsarray[i].amount;
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.tamt -=this.paymentsarray[i].amount;
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
