import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Date_Formate } from '../../../assets/js/common.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $:any;
@Component({
  selector: 'app-bill-refund-create',
  templateUrl: './bill-refund-create.component.html',
  styleUrls: ['./bill-refund-create.component.scss']
})
export class BillRefundCreateComponent implements OnInit {
  public filterType: string;
  public fromDate: string;
  public toDate: string;
  public dateFlag: boolean;
  public clientFlag: boolean;
  public billFlag: boolean;
  public billListFlag: boolean;
  public saveFlag: boolean;

  public clientFilterList = [];
  public filteredClientList = [];
  public clientList = [];
  public clntName: string;
  public middleName: string;
  public lastName: string;
  public clientId: string;
  public relationId: string;
  public subRelId: string;
  public billingId: string;
  public fName: string;
  public mName: string;
  public lName: string;
  public billAmount: string;
  public refundType: string;
  public refundAmount: number;
  public hptlClinicId: string;
  
  public billList = [];
  public billDetails = [];
  public refundList = [];
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public getDate;
  public hospId;
  public userId;
  public billRemarks;
  public billtype;
  private diagcenterid;
  private fin_year;

  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  public messageservice:MenuViewService,public dialog:MatDialog) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.diagcenterid = Helper_Class.getInfo().diag_center_id;
    this.userId = Helper_Class.getInfo().user_id;
    this.filterType = "Client";
    this.billtype="Hospital";
    this.filterTypeChange();
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      {country: ipaddress.country_code},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          //City_change
          this.currentDate = obj.current_date;
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.fromDate = this.currentDate;
          this.toDate = this.currentDate;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  filterTypeChange(){
    if(this.filterType =="Bill number"){
      this.dateFlag = true;
      this.clientFlag=true;
      this.billFlag= false;
      this.billListFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount=0;
      this.saveFlag=false;
      this.billList=[];

    } else if(this.filterType =="Date"){
      this.dateFlag = false;
      this.clientFlag=true;
      this.billFlag= true;
      this.billListFlag=true;
      this.saveFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount=0;
      this.billList=[];
      this.getCurrentDate();

    } else if(this.filterType =="Client"){
      this.dateFlag = true;
      this.clientFlag=false;
      this.billFlag= true;
      this.billListFlag=true;
      this.saveFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount=0;
      this.billList=[];
    }
  }

  clientFilter() {
    this.clientFilterList = [];
    this.clntName = this.clntName.toLocaleUpperCase();
    this.clientList = [];
    this.middleName = "";
    this.lastName = "";
    var clntname = encrypt_decript.Encript(this.clntName).toString();

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbyadm/', { client_name: clntname},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse( JSON.stringify(response));
          
          if (obj.clients != null) {
            this.clientList = [];
            this.clientFilterList = obj.clients;

            for (var i = 0; i < this.clientFilterList.length; i++) { 
              var lName = this.clientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].last_name) : "";
              var mName = this.clientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].middle_name) : "";
              this.clientList.push({
                name: encrypt_decript.Decript(this.clientFilterList[i].first_name) + " " + lName,
                first_name:encrypt_decript.Decript(this.clientFilterList[i].first_name),
                last_name:lName,
                client_id: this.clientFilterList[i].client_reg_id,
                relation_id: this.clientFilterList[i].relation_id,
                sub_rel_id:this.clientFilterList[i].sub_rel_id,
              });
            }
            this.filteredClientList = this.clientList;
            // this.filteredClientList = this.clientList.filter(function (this:any,el) {
            //   return el.name.indexOf(this.clnt_name_txt.toLowerCase()) > -1;
            // }.bind(this));
          }
        },
        error => {});
  }

  selectClient(data) {
    this.clntName = data.first_name;
    this.clientId=undefined;
    this.relationId=undefined;
    this.subRelId=undefined;
    for (var i = 0; i < this.clientFilterList.length; i++) {
      if(this.clientFilterList[i].client_reg_id == data.client_id && this.clientFilterList[i].relation_id == data.relation_id){
        this.middleName = this.clientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].middle_name) : "";
        this.lastName = this.clientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].last_name) : "";
        this.clientId=this.clientFilterList[i].client_reg_id;
        this.relationId= this.clientFilterList[i].relation_id;
        if(this.clientFilterList[i].sub_rel_id != undefined && this.clientFilterList[i].sub_rel_id != null){
          this.subRelId = this.clientFilterList[i].sub_rel_id;
        }
      }
    }
    this.clientList = [];
  }

  searchBills(){
    var btype;
    if(this.billtype=="Hospital"){
      btype ="doctor";
    } else {
      btype ="diagnosis"
    }
    
    if(this.filterType == "Bill number"){
      this.getBillDetailsById();
      this.saveFlag = false;  
    } else {
      var fromdate;
      var todate;
      var fieldvalue;
      if (this.fromDate != undefined){
        fromdate=Date_Formate(this.fromDate);
        todate=Date_Formate(this.toDate);
      }
     
      if(this.filterType == "Date"){
        fieldvalue = {
            center_id: this.hospId,
            from_date: Date_Formate(fromdate) ,
            to_date: Date_Formate(todate),
            app_type: btype
          }
      } else if(this.filterType =="Client"){
        fieldvalue = {
          center_id: this.hospId,
          first_name: this.clntName,
          last_name: this.lastName,
          app_type: btype
        }
      }
      if(this.billtype =="Diagnosis"){
        fieldvalue.provider_id =this.diagcenterid;
        fieldvalue.center_id = this.diagcenterid;
      }
      // OLD BILL bill/gabill/ 
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/getbills/', {fieldvalue},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("refund details "+JSON.stringify(obj))
            if(obj.bills != undefined && obj.bills != null){
              this.billList = obj.bills;
              this.billListFlag = false;
            }
          },
          error => {});
    }
  }

  getBillDetails(){
    for(var i=0;i<this.billList.length;i++){
      if(this.billList[i].bill_id == this.billingId){
        this.fin_year= this.billList[i].fin_year;
      }
    }
    this.getBillDetailsById();
  }

  getBillDetailsById(){
    var headers = new HttpHeaders();
    if(this.billtype =="Hospital"){
      var senddata = {
        hptl_clinic_id: this.hospId,
        billing_id: this.billingId,
        app_type: "doctor",
        fin_year:this.fin_year,
      }

    } else {
      var senddata = {
        hptl_clinic_id: this.diagcenterid,
        billing_id: this.billingId,
        app_type: "diagnosis",
        fin_year:this.fin_year,
      }
    }
    
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbillhosp/', senddata, { headers: headers })
      .subscribe(
        response => {
          
          var obj = JSON.parse(JSON.stringify(response));
          this.fName = obj.first_name != undefined ? encrypt_decript.Decript(obj.first_name) : "";
          this.mName= obj.middle_name != undefined ? encrypt_decript.Decript(obj.middle_name) : "";
          this.lName= obj.last_name != undefined ? encrypt_decript.Decript(obj.last_name) : "";
          this.billAmount = obj.bill_amount != undefined ?  encrypt_decript.Decript(obj.bill_amount) : "";
          if(obj.bills != undefined){   
            this.billDetails=[];
            if(this.billtype=="Hospital"){
              for(var i=0;i<obj.bills.length;i++){
                this.billDetails.push({
                  charge_type:obj.bills[i].charge_type,
                  charge_desc:obj.bills[i].charge_desc,
                  amount_txt:obj.bills[i].amount_txt,
                  concession:obj.bills[i].concession,
                  fee:obj.bills[i].fee,
                })
              }
            } else if(this.billtype=="Diagnosis"){
              for(var i=0;i<obj.bills.length;i++){
                this.billDetails.push({
                  charge_type:obj.bills[i].test_id,
                  charge_desc:obj.bills[i].test_name,
                  amount_txt:obj.bills[i].fee,
                  concession:obj.bills[i].concession,
                  fee:obj.bills[i].fee,
                })
              }
            }
          }
        },
        error => {});

    for(var i=0;i<this.billList.length;i++){
      if(this.billList[i].bill_id == this.billingId){
        this.saveFlag=false;
        
      }
    }
  }

  calculateRefund(value){
    var flag: boolean;
    if(this.refundList.length !=0){
      for(var i=0;i<this.refundList.length;i++){
        if(this.refundList[i].charge_type == value){
          this.refundAmount = this.refundAmount - this.refundList[i].amount;
          this.refundList.splice(i,1);
          flag = true;
        }
      }
    } 

    if(flag != true){
      console.log(JSON.stringify(this.billDetails))
      for(var i=0;i<this.billDetails.length;i++){
        if(this.billDetails[i].charge_type == value){
          this.refundList.push(this.billDetails[i].charge_type);
          this.refundAmount = this.refundAmount + Number.parseInt(this.billDetails[i].fee);
        }
      }
    }  

    this.refundType = (Number.parseInt(this.billAmount) - this.refundAmount !=0) ? "partial" : "full payment";   
  }

  save(){
    var saveflag = true 
    saveflag = this.billRemarks == "" ? false : true; 
    var provid;
    var btype;
    if(this.billtype=="Hospital"){
      btype ="doctor";
      provid=this.hospId
    } else {
      btype ="diagnosis";
      provid = this.diagcenterid;
    }
    if(saveflag == true){

      var headers = new HttpHeaders();
      var sendData = {
        hptl_clinic_id: provid,
        created_by: this.userId,
        bill_id: this.billingId,
        charge_type: this.refundList,
        refund_amount: this.refundAmount,
        refund_type: this.refundType,
        remarks: this.billRemarks,
        country:"IN",
        bill_type: btype,
        fin_year:this.fin_year
      }
      
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/sref',sendData,
        { headers: headers })
        .subscribe(
          response => {
            this.saveFlag = true;  
            this.toastr.success(Message_data.refundSavedSuccess);
            this.messageservice.sendMessage("refund");
          });
    }else{
      this.saveFlag = false;  
      this.toastr.error(Message_data.enterRemarks)
    }
  }

  back(){
    this.messageservice.sendMessage("refund");
  }
}
