<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" >
        <div class="headerCover">
          <div class="headerTilte app_list_header" >
            <h5 class="m-0" class="mainHeadingStyle">Doctor schedule</h5>
          </div>
          <div class="headerButtons">
            <a (click)="openSchedulePopup()"><img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo"></a>
          </div>
        </div>
       
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <p class="nodata" *ngIf="doctor_schdule_date.length == 0">No schedule(s) found</p>
            <table *ngIf="doctor_schdule_date.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable schedulelist_table">
              <thead>
                <tr>
                  <th class="edit">Reason</th>
                  <th class="edit">Date </th>
                  <th class="edit">Time</th>
                  <th class="edit"></th>
                  <!-- <th class="edit">Status</th>
                  <th class="edit">Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of doctor_schdule_date" class="tdvalues">
                  <td style="text-align: left;">{{person.reason}}</td>
                  <td >{{person.from_date_d}} to {{person.to_date_d}}</td>
                  <td>{{person.from_time_data}} </td>
                 <td > <img class="schedule_img" (click)="Doctor_schedule_delete(person.reason,person.from_date,person.to_date)"
                  src="../../../assets/ui_icons/buttons/trash_icon.svg"  class="editDelicon" /></td>
                  </tr>
              </tbody>
            </table>          
          </div>
        </div>      
      </mdb-card-body>
    </mdb-card>
  </div>
</div>