import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMatrialModule } from './angular-matrial.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomeCommonModule } from './common-module/common-module.module';
import { NgImageSliderModule } from 'ng-image-slider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { DoctorRoutingModule } from './Doctor_module/Doctor-Routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { DataTablesModule } from 'angular-datatables';
import { CommonModule, DatePipe } from '@angular/common';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FrontDeskRoutingModule } from './FrontDesk_module/FrontDesk-Routing.module';
import { PharmacyRoutingModule } from './pharma/Pharmacy-Routing.module';
import { LoaderComponent } from './common-module/loader/loader.component';
import { DiagnosisRoutingModule } from './Diagnosis_module/Diagnosis-Routing.module';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClientRoutingModule } from './client/Client-Routing.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { QuillModule } from 'ngx-quill';
import { NurseRoutingModule } from './Nurse_module/Nurse-Routing.module';
import { PhysioRoutingModule } from './Physio_module/Physio-Routing.module';
import { ToastrModule } from 'ngx-toastr';
import { AdminRoutingModule } from './Admin_module/Admin-Routing.module';
import { DietRoutingModule } from './Diet_module/Diet-Routing.module';
import { ReportsRoutingModule } from './reports/reports-routing.module';
import { ServerApi } from './server-api';
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CustomeCommonModule,
    DataTablesModule,
    AngularMatrialModule,
    NgImageSliderModule,
    AppRoutingModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    BrowserAnimationsModule,
    DoctorRoutingModule,
    AdminRoutingModule,
    FrontDeskRoutingModule,
    ClientRoutingModule,
    DietRoutingModule,
    ReportsRoutingModule,
    MatDialogModule,
    DataTablesModule,
    // CommonModule,
    HttpModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    NgbModule,
    PharmacyRoutingModule,
    DiagnosisRoutingModule,
    NgxSliderModule,
    NurseRoutingModule,
    PhysioRoutingModule,
    AdminRoutingModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'
    }),
    QuillModule.forRoot(),
    BackButtonDisableModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })


  ],
  exports: [
    AngularMatrialModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    LoaderComponent,
    DoctorRoutingModule,
    QuillModule,

  ],
  providers: [DatePipe, ServerApi],
  bootstrap: [AppComponent]
})
export class AppModule { }
