<div class="container">
  <div class="row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Casesheet view</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg"  class="saveimgbtn_inpatinfo" (click)="backClicked()" />
              <img class="" src="../../../assets/img/printer.svg" style="width:25px; height:25px; margin: 0 0 0 7px;"  (click)="print_area()">
            </div>
          </div>
         
        </mdb-card-header>
        <mdb-card-body>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="content_show">
                  <table id="table_header" class="d-flex justify-content-center">
                    <tr>
                      <td class="firsttd_style"></td>
                      <td style="text-align: left;">
                        <p class="hosp_details"><strong>{{hosp_name}}</strong></p>
                        <p class="hosp_details_addr">{{hosp_addr}} {{hosp_csz}}</p>
                        <p class="hosp_details_addr">Tel: {{hosp_tel}}</p>
                      </td>
                      <td class="threetd_style">
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                      </td>
                    </tr>
                  </table>
                </div>
                <p class="tervys_heading col-12 header_style" style="margin-top:0px !important">Personal details</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <p><b>Patient ID: </b>{{patient_id}}</p>
                      <p><b>Name : </b>{{patient_name}}</p>
                      <p><b>Age : </b>{{age}}</p>
                      <p><b>Gender : </b>{{gender}}</p>
                      <p><b>Current occupation : </b>{{current_occup}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <p><b>Doctor: </b>{{doctor_name}} {{doc_qualif}}</p>
                      <p><b>Disease: </b>{{disease}} </p>
                      <p><b>Symptoms: </b>{{symptoms}} </p>
                      <p><b>Stress factors: </b>{{stress_factor}} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="spl_name == 'Cardiology'">
              <div class="col-12">

                <p class="tervys_heading col-12 header_style">Vitals</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Height: {{height}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Weight: {{weight}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-1">
                      <p>BMI: {{bmi}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Body type: {{body_type}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Body nature: {{body_nature}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Heart sound: {{heart_sound}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <p>Diatory habits: {{diet_habits}}</p>
                    </div>
                  </div>
                  <br />
                  <div class="row" [hidden]="bp_flag">
                    <div>
                      <p><b>Blood pressure:</b></p>
                      <p>Right arm: {{bp_right}}, Left arm: {{bp_left}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style">Chest pain</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Pain location: {{pain_location}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Pain character: {{weight}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Radiation: {{bmi}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Duration: {{body_type}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Aggravating factor: {{body_nature}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Relieving factor: {{heart_sound}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Pain intensity: {{body_nature}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Pain cause: {{heart_sound}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style">Breathing dificulties</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Trigers: {{breath_trigers}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Onset: {{breath_onset}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Cold sweats: {{breath_cold_sweat}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Diziness: {{breath_diziness}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Vomiting: {{breath_vomiting}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Palpitation: {{breath_palpitation}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Palpitation type: {{breath_palp_type}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Cough: {{breath_cough}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Mucus: {{breath_mucus}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">Past medical history</p>
                <div class="box_style" [hidden]="medhist_flag">
                  <div class="row">
                    <div>
                      <p>{{med_hist}}</p>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div class="row" *ngIf="spl_name == 'Diabetes'">
              <div class="col-12">
                <p class="tervys_heading col-12 header_style">Vitals</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Height: {{height}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Weight: {{weight}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>BMI: {{bmi}}</p>
                    </div>
                  </div>
                </div>
                <p class="tervys_heading col-12 header_style">Main concerns</p>
                <div class="box_style" [hidden]="mainconc_flag">
                  <div class="row">
                    <div>
                      <p>{{main_concerns}}</p>
                    </div>
                  </div>
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">Past medical history</p>
                <div class="box_style" [hidden]="medhist_flag">
                  <div class="row">
                    <div>
                      <p>{{med_hist}}</p>
                    </div>
                  </div>
                </div>
                <p class="tervys_heading col-12 header_style">Recommended excercises</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Excercise: {{rec_excercise}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Duration: {{rec_dur}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Frequency: {{rec_freq}}</p>
                    </div>
                  </div>
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="comorb_flag">Comorbidities</p>
                <div class="box_style" [hidden]="comorb_flag">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2" [hidden]="cardio_flag">
                      <p>Cardiological: {{cardiodis}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2" [hidden]="mobility_flag">
                      <p>Mobility: {{mobility}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2" [hidden]="nephro_flag">
                      <p>Nephrological: {{neprhodis}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2" [hidden]="neuro_flag">
                      <p>Neurological: {{neurodis}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2" [hidden]="retino_flag">
                      <p>Retino issues: {{retinodis}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2" [hidden]="skin_flag">
                      <p>Dermatological: {{skindis}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2" [hidden]="hearing_flag">
                      <p>Hearing issues: {{hearingdis}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2" [hidden]="foot_flag">
                      <p>Foot issues: {{footdis}}</p>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div class="row" *ngIf="spl_name == 'ENT'">
              <p class="tervys_heading col-12 header_style">Vitals</p>
              <div class="box_style">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                    <p>Height: {{height}}</p>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                    <p>Weight: {{weight}}</p>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                    <p>BMI: {{bmi}}</p>
                  </div>
                </div>
              </div>
              <p class="tervys_heading col-12 header_style" [hidden]="mainconc_flag">Main concerns</p>
              <div class="box_style" [hidden]="mainconc_flag">
                <div class="row">
                  <div class="col-12">
                    <p>{{main_concerns}}</p>
                  </div>
                </div>
              </div>
              <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">Past medical history</p>
              <div class="box_style" [hidden]="medhist_flag">
                <div class="row">
                  <div class="col-12">
                    <p>{{med_hist}}</p>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div class="row" *ngIf="spl_name == 'General' || spl_name == 'General surgery'">
              <div class="col-12">

                <p class="tervys_heading col-12 header_style">Vitals</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Height: {{height}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Weight: {{weight}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-1">
                      <p>BMI: {{bmi}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Body type: {{body_type}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Body nature: {{body_nature}}</p>
                    </div>
                  </div>
                  <br />
                  <div class="row" [hidden]="bp_flag">
                    <div class="col-12">
                      <p><b>Blood pressure:</b></p>
                      <p>Right arm: {{bp_right}}, Left arm: {{bp_left}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="mainconc_flag">Main concerns</p>
                <div class="box_style" [hidden]="mainconc_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{main_concerns}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">Past medical history</p>
                <div class="box_style" [hidden]="medhist_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{med_hist}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">surgeries</p>
                <div class="box_style" [hidden]="medhist_flag">
                  <div class="row">
                    <div class="col-12">
                      <p><b>Surgery:</b> {{surgery}}</p>
                      <p><b>Procedure:</b> {{surgery_proc}}</p>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div class="row" *ngIf="spl_name == 'Gynecology'">
              <div class="col-12">
                <p class="tervys_heading col-12 header_style">Vitals</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Height: {{height}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Weight: {{weight}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>BMI: {{bmi}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Body type: {{body_type}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Body nature: {{body_nature}}</p>
                    </div>
                  </div>
                  <br />
                  <div class="row" [hidden]="bp_flag">
                    <div class="col-12">
                      <p><b>Blood pressure:</b></p>
                      <p>Blood pressure: {{bp_right}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style">Menstrual</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Stage: {{mens_stage}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Periods: {{mens_periods}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Last menstrual period: {{last_mens_period}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Cycle length: {{mens_cycle_length}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Menstrual pain: {{mens_pain}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Pain frequency: {{mens_pain_freq}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Pain timing: {{mens_pain_time}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <p>Pain character: {{mens_pain_char}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="mainconc_flag">Main concerns</p>
                <div class="box_style" [hidden]="mainconc_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{main_concerns}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">Past medical history</p>
                <div class="box_style" [hidden]="medhist_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{med_hist}}</p>
                    </div>
                  </div>
                  <br />
                </div>
              </div>

            </div>
            <div class="row" *ngIf="others">
              <div class="col-12">
                <p class="tervys_heading col-12 header_style">Vitals</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Height: {{height}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Weight: {{weight}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>BMI: {{bmi}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Body type: {{body_type}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Body nature: {{body_nature}}</p>
                    </div>
                  </div>
                  <br />
                  <div class="row" [hidden]="bp_flag">
                    <div class="col-12">
                      <p><b>Blood pressure:</b></p>
                      <p>Blood pressure: {{bp_right}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="mainconc_flag">Main concerns</p>
                <div class="box_style" [hidden]="mainconc_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{main_concerns}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">Past medical history</p>
                <div class="box_style" [hidden]="medhist_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{med_hist}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">surgeries</p>
                <div class="box_style" [hidden]="medhist_flag">
                  <div class="row">
                    <div class="col-12">
                      <p><b>Surgery:</b> {{surgery}}</p>
                      <p><b>Procedure:</b> {{surgery_proc}}</p>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div class="row" *ngIf="spl_name == 'Pediatrics'">
              <div class="col-12">
                <p class="tervys_heading col-12 header_style">Clinical</p>
                <div class="box_style">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Complications: {{ped_complic}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Mother's age at birth: {{mother_age}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Delivery type: {{delivery_type}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Gestational age: {{gestational_age}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Allergies: {{allergies}}</p>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2">
                      <p>Feeding habit: {{feeding_habit}}</p>
                    </div>
                  </div>
                  <br/>
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="mainconc_flag">Main concerns</p>
                <div class="box_style" [hidden]="mainconc_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{main_concerns}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style" [hidden]="medhist_flag">Past medical history</p>
                <div class="box_style" [hidden]="medhist_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{med_hist}}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <p class="tervys_heading col-12 header_style">Maternal illness</p>
                <div class="box_style" [hidden]="materill_flag">
                  <div class="row">
                    <div class="col-12">
                      <p>{{maternal_illness}}</p>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div class="row" *ngIf="druglist.length !=0">
              <p class="tervys_heading col-12 header_style" *ngIf="druglist.length !=0">Medicines</p>
              <div class="dig_table_overflow" *ngIf="druglist.length">
                <div class="table-responsive" *ngIf="druglist.length" style="padding: 0;">
                  <table id="card_tbl" class="table table-nowrap table-sm">
                    <thead class="thvalues">
                      <tr style="height: 30px;">
                        <th>Medicine</th>
                        <th>Dosage</th>
                        <th>Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="height: 30px;" *ngFor="let medicine of druglist">
                        <td style="font-size: 12px;" class="txtalign">{{medicine.medicine_name}}</td>
                        <td style="font-size: 12px;" class="txtalignright">{{medicine.medicine_dosage}}</td>
                        <td style="font-size: 12px;" class="txtalignright">{{medicine.medicine_intakedays+" -
                          "+medicine.medicine_food}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>

        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>


<!-- print -->
<div [hidden]="true">
  <div *ngIf="spl_name == 'Cardiology'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'Diabetes'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'ENT'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'General' || spl_name == 'General surgery'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'Gynecology'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="others" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p >{{hosp_addr}}   {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>
      
      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</p>
        
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}}  -  {{product.medicine_food}}</td>     
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'Gynecology'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p >{{hosp_addr}}   {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>
      
      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</p>
        
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}}  -  {{product.medicine_food}}</td>     
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>