<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white " style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Tests</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
              class="saveimgbtn_inpatinfo " />
            <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn" (click)="saveTest('Save')"
              class="saveimgbtn_inpatinfo" />
            <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="update_btn"
              (click)="saveTest('update')" class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>

        <div class="row" style="margin-bottom: 20px;">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Test name
              <input class="ipcss widthappt" required [(ngModel)]="Diag_TestName" required #matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Test category<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="diag_test_categ">
                <option *ngFor="let testcat of test_category_data" required value={{testcat.description}}>
                  {{testcat.description}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Disease category<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="diag_dis_categ" required>
                <option [ngValue]="undefined" selected>Select</option>
                <option *ngFor="let cateogry of dis_category_data" value={{cateogry.description}}>
                  {{cateogry.description}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Test type<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="Diag_test_subtype" (change)="subTypeChange()" required>
                <option *ngFor="let test of Subtype_data" value={{test.test_type_name}}>{{test.test_type_name}}
                </option>
              </select>
            </mat-label>
          </div>
          <div [hidden]="unithidden" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Unit of measure
              <input class="ipcss " [(ngModel)]="Diag_Uof" #matInput [required]="main_test_uom_flag"/>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Cost
              <input class="ipcss " [(ngModel)]="Diag_test_cost" #matInput />
            </mat-label>
          </div>
          <div [hidden]="addTest_flag" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom my-auto">
            <mat-label class="matlabel">
              <mat-checkbox color="primary" (change)="addSubtest($event.checked)" [ngModel]="addSubtest_checked"
                labelPosition="before"></mat-checkbox>
              Would you like to add subtest?
            </mat-label>
          </div>
        </div>
        <div class="row" [hidden]="diagsubhidden">
          <div class="col-12 col-sm-2 col-md-3 col-lg-3 col-xl-3 select_bottom"></div>
          <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 add_test_cover">
            <div class="row" [hidden]="diagsubhidden">
              <div [hidden]="subtest_flag" class="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8"
                style="margin-bottom: 20px;">
                <mat-label class="matlabel">Subtest name
                  <input class="ipcss " required [(ngModel)]="Diag_Subtest_name" #matInput />
                </mat-label>
              </div>
              <div [hidden]="subtest_flag" class="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4">
                <mat-label class="matlabel">UOM
                  <input class="ipcss " required [(ngModel)]="diagSubUOM" #matInput />
                </mat-label>
              </div>
            </div>

            <div *ngIf="ref_flag">
              <div class="row">
                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <mat-label class="mat-label">Referrence range</mat-label>
                </div>
              </div>
              <div class="row">
                <div [hidden]="subtest_flag" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel">Male
                    <input class="ipcss " [(ngModel)]="male_ref" #matInput />
                  </mat-label>
                </div>
                <div [hidden]="subtest_flag" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel">Female
                    <input class="ipcss " [(ngModel)]="female_ref" #matInput />
                  </mat-label>
                </div>
                <div [hidden]="subtest_flag" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel">Child
                    <input class="ipcss " [(ngModel)]="child_ref" #matInput />
                  </mat-label>
                </div>
                <div [hidden]="subtest_flag" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel">New born
                    <input class="ipcss " [(ngModel)]="newborn_ref" #matInput />
                  </mat-label>
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;" *ngIf="!ref_flag">
              <div class="col-12" >
                <mat-label class="mat-label">Referrence value <span style="color: red;font-size: 20px;">*</span></mat-label>
                <quill-editor [(ngModel)]="range" [style]="editorStyle" [modules]="modules" 
                  (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                  (onSelectionChanged)="onSelectionChanged($event)" (onContentChanged)="onContentChanged($event)">
                </quill-editor>
              </div>
            </div>
            <div [hidden]="addTest_flag" class="row" style="margin-top: 10px;">
              <div class="col-12">
                <mat-label class="mat-label">Test method</mat-label>
                <quill-editor [(ngModel)]="test_method" [style]="editorStyle" [modules]="modules"
                  (onEditorCreated)="editorCreated1($event)" placeholder="Enter Text"
                  (onSelectionChanged)="onSelectionChanged1($event)" (onContentChanged)="onContentChanged1($event)">
                </quill-editor>
              </div>
            </div>
            <div class="row" style="margin-top: 10px;" [hidden]="diagsubhidden">
              <div class="col-12">
                <img *ngIf="!main_test_uom_flag" src="../../../assets/ui_icons/buttons/add_button.svg" (click)="test_add()"
                  class="saveimgbtn_inpatinfo" style="float: right;" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 10px;"
              *ngIf='Diag_subtest_list_data.length != 0 && !main_test_uom_flag'>
              <div class="table-responsive">
                <table *ngIf="Diag_subtest_list_data.length" class="table table-hover table-dynamic" id="card_tbl">
                  <thead>
                    <tr>
                      <th>Sub test name</th>
                      <th>Test method</th>
                      <th class="extra_action">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let Subtest of Diag_subtest_list_data">
                        <td *ngIf="Subtest.status == 1" style="text-align: left;">{{Subtest.sub_test_name}}</td>
                        <td *ngIf="Subtest.status == 1"><div class="test_method" [innerHtml]="Subtest.test_method"></div></td>
                        <td *ngIf="Subtest.status == 1">
                          <img src="../../../assets/img/select_icon.png" class="del_img_class"
                            (click)="Subtest_Select_Click(Subtest.sub_test_name)" />
                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="del_img_class"
                            (click)="Subtest_Delete_Click(Subtest.sub_test_name)" />
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br><br><br>
            </div>
          </div>
          <div class="col-12 col-sm-2 col-md-3 col-lg-3 col-xl-3 select_bottom"></div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>