<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Patient list</h5>
          </div>
          <div class="headerButtons">
            <a style="float: right;" (click)="create_patient()">
              <img src="../../../assets/ui_icons/buttons/new_button.svg" class="imgbtn">
            </a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" *ngIf="hospitalFlag">
            <mat-label class="matlabel">
              <img src='../../../assets/ui_icons/Bill_list/Hospital_icon.svg' width="20px" height="auto" />&nbsp;
              Hospital
              <select required class="ipcss" [(ngModel)]="hospital_id" (change)="changePatientType('self')"
                style="height: 28px;">
                <option *ngFor="let hosp of hospital_array" value={{hosp.hptl_clinic_id}}>{{hosp.hptl_name}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12" style="margin-bottom: 15px;margin-top: 5px;">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 col-sm-6 col-xl-4 select_bottom">
                <div class="row">
                  <div class="col-12">
                    <div class="switch-field" *ngIf="ynopt1 != undefined">
                      <input type="radio" id="radio-three" (click)="change_type(1,'yes')" />
                      <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                        <div class="tab_cover">
                          <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Self.svg" class="iocinStyle"
                              alt=""></div>
                          <div class="lableStyle">Self</div>
                        </div>
                      </label>
                      <input type="radio" id="radio-four" (click)="change_type(1,'no')" />
                      <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                        <div class="tab_cover">
                          <div class="tab_icon_cover"><img src="../../../assets/ui_icons/others.svg" class="iocinStyle"
                              alt=""></div>
                          <div class="lableStyle">Others</div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="nodata" *ngIf="patientList.length == 0">No appointment(s) found</p>
        <table *ngIf="patientList.length" mdbTable datatable [dtOptions]="dtOptions"
          class="table table-nowrap table-sm dataTable">
          <thead>
            <tr>
              <th>Image</th>
              <th>Inpatient id</th>
              <th style="text-align: left !important;">Patient name</th>
              <th>Admitted on</th>
              <th>Admitted time</th>
              <th>Ward</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let person of patientList; let i = index"
              (click)='view_patient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name,person.relation_id,person.relationship_name)'>
              <td><img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
              <td style="font-size: 12px;">{{ person.hosp_inp_id }}</td>
              <td style="font-size: 12px;text-align: left !important;">{{ person.patient_name }}</td>
              <td style="font-size: 12px;">{{ person.admit_date }}</td>
              <td style="font-size: 12px;">{{ person.admit_time }}</td>
              <td style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.ward_text }}</td>
            </tr>
          </tbody>
        </table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>