<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white ">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Assignment details</h5>
                        </div>
                        <div class="headerButtons">
                            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel()"
                                width="85px" style="margin-right: 9px;" />
                            <img src="../../../assets/ui_icons/buttons/confirm_button.svg" [hidden]="confirmFlag"
                                class="history_btn" (click)="confirmAppointment()" />
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div>
                        <app-loader></app-loader>
                    </div>
                    <div class="row">
                        <div class="cover_div">
                            <div class="header_lable">
                                patient details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Appointment for
                                            <input type="text" class="ipcss" [(ngModel)]="relationType" readonly
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">First name
                                            <input type="text" class="ipcss" [(ngModel)]="firstName" readonly
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Middle name
                                            <input type="text" class="ipcss" [(ngModel)]="middleName" readonly
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Last name
                                            <input type="text" class="ipcss" [(ngModel)]="lastName" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Age
                                            <input type="text" class="ipcss" [(ngModel)]="age" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Gender
                                            <input type="text" class="ipcss" [(ngModel)]="gender" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Client Address
                                            <textarea class="ipcss" [(ngModel)]="clientAddress" readonly
                                                matInput></textarea>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cover_div" style="margin-top: 15px;">
                            <div class="header_lable">
                                Assignment & Medical details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Dotor name
                                            <input type="text" class="ipcss" [(ngModel)]="doctorName" readonly
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Start date
                                            <input type="text" class="ipcss" [(ngModel)]="startDate" readonly
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">End date
                                            <input type="text" class="ipcss" [(ngModel)]="endDate" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">No of sittings
                                            <input type="text" class="ipcss" [(ngModel)]="noOfSitting" readonly
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Fees per sitting
                                            <input type="text" class="ipcss" [(ngModel)]="fees" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Prescription
                                            <textarea class="ipcss" [(ngModel)]="prescriptionDetails" readonly
                                                matInput> </textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Comments
                                            <textarea class="ipcss" [(ngModel)]="comments" readonly matInput></textarea>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                        tabindex="-1" aria-labelledby="loginmodel_header" aria-hidden="true">
                        <div class="modal-dialog" style="width: 25%;top: 20%;">
                            <div class="modal-content">
                                <div class="modal-header"
                                    style="padding:0px;background: linear-gradient(to right, #51b0e7, #6fbdec, #8acaf1, #a3d7f6, #bbe4fc);">
                                    <div style="width: 100%;color: #fff;">
                                        <h3 class="modal-title" id="loginmodel_header">Resone for cancel</h3>
                                    </div>
                                    <button type="button" class="model_close" data-bs-dismiss="modal"
                                        aria-label="Close"><img src="../../../assets/img/close_icon.png"
                                            alt="Card image cap" style="width:20px"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row" style="text-align: center;">
                                        <div class="col-12">
                                            <mat-label class="matlabel">Reason for cancelling appointment
                                                <textarea class="ipcss" [(ngModel)]="reasonForCancelAppt"></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <img src="../../../assets/ui_icons/buttons/cancel_button.svg"
                                        (click)="cancelModel()" width="85px" style="margin-right: 9px;" />
                                    <img src="../../../assets/ui_icons/buttons/submit_button.svg"
                                        (click)="submitCancel()" width="85px" style="margin-right: 9px;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>