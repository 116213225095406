import { Component, OnInit, Input ,HostListener} from '@angular/core';
import {Helper_Class} from '../../helper_class';
// import {ipaddress.getIp,ipaddress.Ip_with_img_address,ipaddress.country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers, JsonpModule } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { DentalService, TreatmentService } from '../service';  
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-dentalplantab',
  templateUrl: './dentalplantab.component.html',
  styleUrls: ['./dentalplantab.component.css']
})
export class DentalplantabComponent implements OnInit {
  public DATE;
  public dentalPlanFlag = 'min';
 public minflag:boolean=true;
  subscription:Subscription;
  public assign_doc_reg_id: boolean;
  public treat_spl_array = [];
  public treat_array = [];
  public treat_images = [];
  public loader: boolean;
  public tretemen_plan_array = [];
  public treat_doneplan = [];
  public med_dept_ref_array = [];

  public Treateplan_Desc;
  public estimate_txt;
  public Treatedon_Desc;
  public Treatedon_Procedure;
  public invest_details;
  public charge_txt;
  public diagnosis_txt;
  public med_dept_ref;
  public results_txt;
  public note_txt;
  public refer_data_hide: boolean;
  public med_hide: boolean;
  public med_view_pre_hide: boolean;
  public view_hide: boolean;
  public header_footer_flag;
  public doc_name_array = [];
  public doc_name;
  public spl_text;
  public denspl: string;
  public den_treatment_id: string;
  public treat_phase: string;

  public invest_hidden: boolean;
  public med_hidden: boolean;
  public results_hidden: boolean;
  public diagnosis_hidden: boolean;
  public notes_hidden: boolean;
  public imagediv: boolean;
  public imagedisp: boolean;
  public estimate_hidden:boolean;
  public treat_desc_hidden: boolean;
  public procedure_hidden: boolean;
  public charges_hidden: boolean;
  public treatment_hidden: boolean;
  public treat_plan_hidden: boolean;
  public treattype_hidden: boolean;
  public treatplandesc_hidden: boolean;
  public phase_hidden: boolean;

  public treatplandesc_req: boolean;
  public estimate_req: boolean;
  public treatdesc_req: boolean;
  public procedure_req: boolean;
  public charges_req: boolean;
  public clientinfo;
  public userinfo;
  public getdata;
  public hospitalinfo;
  
  constructor(public http:Http,public toastr:ToastrService,public messageservice:TreatmentService,public messageservice1:CasesheetService,
    public dentalservice:DentalService,public masterData:MasterCSHelperService)   {
    this.minflag=true;
   this.invest_hidden = true;
    this.med_hidden = true;
    this.results_hidden = true;
    this.diagnosis_hidden = true;
    this.notes_hidden = true;
    this.estimate_hidden = true;
    this.treat_desc_hidden = true;
    this.procedure_hidden = true;
    this.charges_hidden = true;
    this.treatment_hidden = true;
    this.treat_plan_hidden = true;
    this.treattype_hidden = true;
    this.treatplandesc_hidden=true;
    this.phase_hidden =true;

    this.treatplandesc_req=false;
    this.estimate_req=false;
    this.treatdesc_req=false;
    this.procedure_req=false;
    this.charges_req=false;   
    this.refer_data_hide = true;
    this.med_hide = true;
    this.med_view_pre_hide = true;
    this.view_hide = true;
    this.imagediv = true;
    this.imagedisp = false;

    if(this.den_treatment_id ==null || this.den_treatment_id == undefined){
      this.imagediv = true;
    }
   }

  async ngOnInit(){ 
    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.dentalPlanFlag = message;
      }
    });

    if(masterCSData_Helper.getMasterMedicationDeptReferral() == undefined){
      await this.masterData.getMedicationDeptReferral();
      this.get_med_dept_ref_array();
    }else{
      this.get_med_dept_ref_array();
    }

    this.clientinfo=Doc_Helper.getClient_Info();
    this.userinfo=Helper_Class.getInfo();
    this.hospitalinfo=Helper_Class.getHospital()
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
        if(message !=undefined){
          this.denspl=message.spl_id;
          this.getTreatmentsBySpl(this.denspl);
         } else {
          var la = [];
        }
    });
   
   this.get_treat_spl_array();
      var getdenret2=Helper_Class.Get_den_ret2();
    if (getdenret2 != undefined) {
      if (getdenret2.treatment_plan != null) {
        this.tretemen_plan_array = [];
        for (var i = 0; i < getdenret2.treatment_plan.length; i++) {
          this.tretemen_plan_array.push({
            phase: getdenret2.treatment_plan[i].phase,
            treatment_desc: getdenret2.treatment_plan[i].treatment_desc,
            estimate: getdenret2.treatment_plan[i].estimate,
            add_new: "0",
          });
        }
      }
     
      if (getdenret2.prognosis != null) {
        for (var i = 0; i < getdenret2.prognosis.length; i++) {
          var get_date = getdenret2.prognosis[i].date.split('-');
          var docname;
          if(getdenret2.prognosis[i].middle_name != undefined && getdenret2.prognosis[i].middle_name !='undefined'){
            docname =getdenret2.prognosis[i].first_name + " " + getdenret2.prognosis[i].middle_name + " " + getdenret2.prognosis[i].last_name;

          } else {
            docname = getdenret2.prognosis[i].first_name + " " + getdenret2.prognosis[i].last_name
          }
          this.treat_doneplan.push({
            Date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
            Doct_Name: docname,
            treat_desc: getdenret2.prognosis[i].treat_desc,
            procedure: getdenret2.prognosis[i].procedure,
            charges: getdenret2.prognosis[i].charges,
            add_new: "0",
          });
        }

      }

      if (getdenret2["investigation"] != undefined) {
        this.invest_details = getdenret2["investigation"];
      }

      if (getdenret2["diagnosis"] != undefined) {
        this.diagnosis_txt = getdenret2["diagnosis"];
      }

      if (getdenret2["results"] != undefined) {
        this.results_txt = getdenret2["results"];
      }

      if (getdenret2["notes"] != undefined) {
        this.note_txt = getdenret2["notes"];
      }
      
      if (getdenret2["den_treat_id"] != undefined) {
        this.den_treatment_id = getdenret2["den_treat_id"];
        this.getTreatmentVideos(this.den_treatment_id);
      }
      this.loader = true;

    } else {
      this.loader = true;
    }
    var datafields=Helper_Class.getHopitalfieldsRet()
    if (datafields != undefined) {
      for (var i = 0; i < datafields.length; i++) {
        if (datafields[i].page_name == "DentalTreatmentplan") {
          if (datafields[i].fields != undefined) {
            for (var j = 0; j < datafields[i].fields.length; j++) {
              if (datafields[i].fields[j].field_name == "Investigation details") {
                this.invest_hidden = false;
              }

              if (datafields[i].fields[j].field_name == "Dept referral") {
                this.med_hidden = false;
              }
              
              if (datafields[i].fields[j].field_name == "Results") {
                this.results_hidden = false;
              }
              
              if (datafields[i].fields[j].field_name == "Diagnosis") {
                this.diagnosis_hidden = false;
              }
              
              if (datafields[i].fields[j].field_name == "Notes") {
                this.notes_hidden = false;
              }
              if (datafields[i].fields[j].field_name == "Treat video") {
                this.imagedisp=true;
                this.imagediv = false;
              }

              if (datafields[i].fields[j].field_name == "Estimate") {
                this.estimate_hidden = false;
                this.estimate_req=true;
              }

              if (datafields[i].fields[j].field_name == "Treatment description") {
                this.treat_desc_hidden = false;
                this.treatdesc_req= true;
              }

              if (datafields[i].fields[j].field_name == "Procedure") {
                this.procedure_hidden = false;
                this.procedure_req= true;
              }

              if (datafields[i].fields[j].field_name == "Charges") {
                this.charges_hidden = false;
                this.charges_req=true;
              }

              if (datafields[i].fields[j].field_name == "Treatment") {
                this.treatment_hidden = false;
              }
              
              if (datafields[i].fields[j].field_name == "Treatment plan") {
                this.treat_plan_hidden = false;
              }
              
              if (datafields[i].fields[j].field_name == "Treatmenttype") {
                this.treattype_hidden = false;
              }

              if (datafields[i].fields[j].field_name == "Treatment plan description") {
                this.treatplandesc_hidden = false;
                this.treatplandesc_req= true;
              }

              if (datafields[i].fields[j].field_name == "Treatment phase") {
                this.phase_hidden = false;
              }
            }
          }
        }
      }
    }
    if(Doc_Helper.Get_den_per_hist() != undefined){
      this.denspl= Doc_Helper.Get_den_per_hist().den_spl_id;
      this.getTreatmentsBySpl( this.denspl);
    }
    this.getTreatmentVideos(this.den_treatment_id);

  }

  playvideo(video){
    // let RelationModal = this.modalCtrl.create('VideopopupPage', { videoname: video });
    // RelationModal.present();

    // RelationModal.onDidDismiss(data => {
      
    // })
  }
  Dental_Retrieval_1() {
   

      
    if(this.clientinfo.sub_id.length == 0){
      var send_data=JSON.stringify({
        doc_reg_id:this.userinfo.user_id,
        client_reg_id:this.clientinfo.Client_id,
        relation_id: this.clientinfo.rel_id,
        country:"IN",
      })
    }
    else{
      send_data=JSON.stringify({
        doc_reg_id:this.userinfo.user_id,
        client_reg_id:this.clientinfo.Client_id,
        relation_id: this.clientinfo.rel_id,
        sub_rel_id:this.clientinfo.sub_id,
        country:"IN",
      })
    }
  

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dental/perdet/', send_data,
      { headers: headers })
      .subscribe(
        response => {
         
         // this.loader = true;
          var obj = response.json();
          Doc_Helper.Set_den_ret1(obj);

        
          
          //this.Dental_Retrieval_2();
        },
        error => {

        }
      )
  }

  

  
  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   this.send_data();
  // }
  
  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }

  

send_data() {

  var treat_done = [], treatment_array = [], gen_hist = null, diag_pres_id = undefined, med_pres_id = undefined;

  if (this.tretemen_plan_array.length != 0) {
    for (var i = 0; i < this.tretemen_plan_array.length; i++) {
      if (this.tretemen_plan_array[i].add_new == "1") {
        treatment_array.push({
          phase:this.tretemen_plan_array[i].phase,
          treatment_desc: this.tretemen_plan_array[i].treatment_desc,
          estimate: this.tretemen_plan_array[i].estimate,
        });
      }
    }
  }
  var charge_date1 = undefined, treat_desc1 = undefined, procedure1 = undefined, charges1 = undefined;
  if (this.treat_doneplan.length != 0) {
    for (var i = 0; i < this.treat_doneplan.length; i++) {
      if (this.treat_doneplan[i].add_new == "1") {
        charge_date1 = this.treat_doneplan[i].Date;
        treat_desc1 = this.treat_doneplan[i].treat_desc;
        procedure1 = this.treat_doneplan[i].procedure;
        charges1 = this.treat_doneplan[i].charges;
      }
    }
  }

  if (Helper_Class.get_med_pres_id() != undefined && Helper_Class.get_med_pres_id() != null) {
    med_pres_id = Helper_Class.get_med_pres_id();
  }

  if (Helper_Class.get_diag_pres_id() != undefined && Helper_Class.get_diag_pres_id() != null) {
    diag_pres_id = Helper_Class.get_diag_pres_id();
  }

  if (treat_done.length == 0) {
    treat_done = undefined;
  }

  if (treatment_array.length == 0) {
    treatment_array = undefined;
  }

  if (this.invest_hidden == false && (this.invest_details == undefined || this.invest_details == "")) {
    this.invest_details = undefined;
  }

  gen_hist = {
    charge_date: charge_date1,
    treat_desc: treat_desc1,
    procedure: procedure1,
    charges: charges1,
    treatment_plan: treatment_array,
    investigation: this.invest_details,
    diagnosis: this.diagnosis_txt,
    results: this.results_txt,
    notes: this.note_txt,
    pres_drug_id: med_pres_id,
    pres_diag_id: diag_pres_id,
    den_treat_id:this.den_treatment_id,
  }
  Doc_Helper.Set_den_treat_plan(gen_hist);
  

}

   //treatment specialities
   get_treat_spl_array() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dental/docdenspl', JSON.stringify({
      doc_reg_id: this.userinfo.user_id
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          
          this.treat_spl_array = obj.den_spl;
          this.getTreatmentsBySpl( this.denspl);
        },
        error => {
          
        }
      )
  }

  med_dept_ref_chage() {
    if (this.med_dept_ref == "Medication") {
      this.med_hide = false;
      this.med_view_pre_hide = false;
      this.refer_data_hide = true;
      
    } else if (this.med_dept_ref == "Dept referral") {
      this.refer_data_hide = false ;
      this.med_view_pre_hide = true;

    } else if (this.med_dept_ref == "Medication / Dept referral") {
      this.refer_data_hide = false;
      this.med_hide = false;
      this.med_view_pre_hide = false;

    } else if (this.med_dept_ref == "Medication / Assign") {
      this.refer_data_hide = false;
      this.med_hide = false;
      this.med_view_pre_hide = false;

    } else if (this.med_dept_ref == "Assign") {
      this.refer_data_hide = false;
      this.med_view_pre_hide = true;

    } else  if (this.med_dept_ref == "Select") {
      this.med_hide = true;
      this.med_view_pre_hide = true;
      this.refer_data_hide = true;
    }
  }
 
  treat_plan_des() {
    var flag=true;
    if(this.treatplandesc_req == true && (this.Treateplan_Desc == "" || this.Treateplan_Desc == undefined)){
      flag=false;

    } else if(this.estimate_req ==true && (this.estimate_txt == "" || this.estimate_txt == undefined)){
      flag=false;
    }
    if (flag==false) {
      // let toast = this.toastCtrl.create({
      //   message: "Enter mandatory fields",
      //   duration: 2000,
      //   position: 'middle',
      // });
      // toast.present();

    } else {
      this.tretemen_plan_array.push({
        phase:this.treat_phase,
        treatment_desc: this.Treateplan_Desc,
        estimate: this.estimate_txt,
        add_new: "1",
      });
      this.Treateplan_Desc = ""
      this.estimate_txt = ""
    }
    this.send_data();
  }

  treate_delete(data, data1) {
    for (var i = 0; i < this.tretemen_plan_array.length; i++) {
      if (data == this.tretemen_plan_array[i].treatment_desc && data1 == this.tretemen_plan_array[i].estimate) {
        this.tretemen_plan_array.splice(i, 1);
        break;
      }
    }
    this.send_data()
  }

  treat_click() {
    var tflag = true;
    if(this.treatdesc_req == true &&(this.Treatedon_Desc == "" || this.Treatedon_Desc == undefined)){
      tflag = false;

    } else if(this.procedure_req ==true && (this.Treatedon_Procedure == "" || this.Treatedon_Procedure == undefined)){
      tflag = false;

    } else  if (this.charges_req == true && (this.charge_txt == "" || this.charge_txt == undefined)){
      tflag=false;
    }

    if (tflag == false) {
      this.toastr.error(Message_data.mandatory);
    

    } else {
      var today = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
      var get_date = today.split('/');

      var docname;
    //var getdoctorname=Helper_Class.getProfilename();
      if(this.userinfo.middle_name != 'undefined'){
        docname=this.userinfo.first_name + " " +this.userinfo.middle_name + " " + this.userinfo.last_name;

      } else {
        docname=this.userinfo.first_name+ " " + this.userinfo.last_name;
      }

      this.treat_doneplan.push({
        Doct_Name: docname,
        Date: get_date[0] + '-' + get_date[1] + '-' + get_date[2],
        treat_desc: this.Treatedon_Desc,
        procedure: this.Treatedon_Procedure,
        charges: this.charge_txt,
        add_new: "1",
      });

      this.Treatedon_Desc = "";
      this.Treatedon_Procedure = "";
      this.charge_txt = "";
    }
    this.send_data();
  }

  treatedone_delete(data, data1, data2) {
    for (var i = 0; i < this.treat_doneplan.length; i++) {
      if (this.treat_doneplan[i].treat_desc == data && this.treat_doneplan[i].procedure == data1 && this.treat_doneplan[i].charges == data2 && this.treat_doneplan[i].add_new.toString() != "0") {
        this.Treatedon_Desc = this.treat_doneplan[i].treatedone_des;
        this.Treatedon_Procedure = this.treat_doneplan[i].procedure;
        this.charge_txt = this.treat_doneplan[i].charge;
        this.treat_doneplan.splice(i, 1);
      }
    }
    this.send_data();
  }


  widthchange(e){
    var flag2="minimize";
    if(flag2== e){
      this.minflag=true;
    }
    else if(flag2 != e){
      this.minflag=false;
    }
  }
//master data
  //Medication/Dept referral
  get_med_dept_ref_array() {
    var obj = masterCSData_Helper.getMasterMedicationDeptReferral();
    if(obj != undefined)
      this.med_dept_ref_array = obj.med_refs;
  }

  getTreatmentsBySpl(e) {
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dental/dts', JSON.stringify({
      den_spl_id: this.denspl
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          this.treat_array = obj.den_treat;
          var testvalue: boolean;
          if(obj != undefined && obj.den_treat != undefined){
            for(var i=0;i<obj.den_treat.length;i++){
              if(obj.den_treat[i].den_spl_treat_id != this.den_treatment_id){
                testvalue = true;
              }else {
                testvalue= false;
               // getTreatmentVideos(this.den_treatment_id)
                break;
              }
            }
          }
          
          if(testvalue == true){
            this.den_treatment_id = undefined;
            this.imagediv=true;
          }
        },
        error => {
          
        }
      )
  }

  getTreatmentVideos(e){
    this.den_treatment_id=e
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dental/durl', JSON.stringify({
      den_treat_id: this.den_treatment_id
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          

          if(obj.den_videos != undefined || obj.den_videos != null){
            if(this.imagedisp == true){
              this.imagediv = false;
            }
            
            this.treat_images=[];
            for (var i = 0; i < obj.den_videos.length; i++) {
              this.treat_images.push({
                image_path:ipaddress.Ip_with_img_address+"/"+obj.den_videos[i].image_path,
                video_path: ipaddress.Ip_with_img_address+"/"+obj.den_videos[i].video_path
              })
            }
            
          }
        },
        error => {
          
        }
      )
  }

  spl_search(e) {
    //array reintiate(for data clearing purpose)
    this.doc_name_array = [];
    var img = document.getElementById("refer_first") as HTMLImageElement;
    img.src = "assets/imgs/default.jpg";
    this.doc_name=e.target.value.toString();
    if (this.med_dept_ref == "Assign" || this.med_dept_ref == "Medication / Assign") {
      //$scope.doc_reg_id_data = null;

      if (this.doc_name.length > 2) {

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'dental/docbyspl/', JSON.stringify({
          hptl_clinic_id:this.hospitalinfo.hptl_clinic_id,
          spl_name: this.spl_text,
          doc_name: this.doc_name,
          doc_reg_id: this.userinfo.user_id,
        }),
          { headers: headers })
          .subscribe(
            response => {

              var obj = JSON.parse(response["_body"]);
              
              this.doc_name_array = obj.doc_spl;
              this.assign_doc_reg_id = true;
            },
            error => {

            }
          )
      }

    } else {
      if (this.doc_name.length > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'dental/docbyspl/', JSON.stringify({
          spl_name: this.spl_text,
          doc_name: this.doc_name,
          doc_reg_id: this.userinfo.user_id,
        }),
          { headers: headers })
          .subscribe(
            response => {
              var obj =response.json()
              
              this.doc_name_array = obj.doc_spl;
              this.assign_doc_reg_id = false;
            },
            error => {

            }
          )
      }
    }
  }

  doc_name_select(doc_id) {
    var ref_spl_id = undefined;
    for (var i = 0; i < this.doc_name_array.length; i++) {
      if(this.doc_name_array[i].middle_name != undefined ){
      if (this.doc_name_array[i].doc_reg_id == doc_id) {
        this.doc_name = this.doc_name_array[i].first_name + " " + this.doc_name_array[i].middle_name + " " + this.doc_name_array[i].last_name;
        var img = document.getElementById("refer_first") as HTMLImageElement;
        img.src = ipaddress.Ip_with_img_address + this.doc_name_array[i].profile_image;
        this.doc_name_array = [];
        //private ref_spl_id;
      }
      else{
        this.doc_name = this.doc_name_array[i].first_name + " " + this.doc_name_array[i].last_name;
        var img = document.getElementById("refer_first") as HTMLImageElement;
        img.src = ipaddress.Ip_with_img_address + this.doc_name_array[i].profile_image;
        this.doc_name_array = [];
        //this.ref_doc_reg_id = doc_id;
      }
        for (var j = 0; j < this.treat_spl_array.length; j++) {
          if (this.spl_text == this.treat_spl_array[j].description) {
            ref_spl_id = this.treat_spl_array[j].den_spl_id;
          }
        }

        if (this.clientinfo.sub_id != null) {
          var sub_rel = this.clientinfo.sub_id;

        } else {
          sub_rel = undefined;
        }

        var send_data = null;
        if (this.assign_doc_reg_id == true) {
          if (Helper_Class.Get_client_view_app_data()["age"] != "") {
            var age_txt = Helper_Class.Get_client_view_app_data()["age"];

          } else {
            age_txt = undefined;
          }

          if (Helper_Class.Get_client_view_app_data()["guardian"] != undefined) {
            var gur_txt = Helper_Class.Get_client_view_app_data()["guardian"];
          
          } else {
            gur_txt = undefined;
          }

          if (Helper_Class.Get_client_view_app_data()["occupation"] != undefined) {
            var occ_txt = Helper_Class.Get_client_view_app_data()["occupation"];
          
          } else {
            occ_txt = undefined;
          }

          if (Helper_Class.Get_client_view_app_data()["income"] != undefined) {
            var income_txt = Helper_Class.Get_client_view_app_data()["income"];
          
          } else {
            income_txt = undefined;
          }

          send_data = {
            assn_doc_reg_id: doc_id,
            ref_type: "2",
            app_type: Helper_Class.Get_client_view_app_data()["app_type"],
            first_name: Helper_Class.Get_client_view_app_data()["first_name"],
            middle_name: Helper_Class.Get_client_view_app_data()["middle_name"],
            last_name: Helper_Class.Get_client_view_app_data()["last_name"],
            age: age_txt,
            gender: Helper_Class.Get_client_view_app_data()["gender"],
            mem_mobile: Helper_Class.Get_client_view_app_data()["mobile"],
            hptl_clinic_id: this.hospitalinfo.hptl_clinic_id,
            guardian: gur_txt,
            occupation: occ_txt,
            income: income_txt,
            medicare_id: Helper_Class.Get_client_view_app_data()["medicare_id"],
            specialization_id: Helper_Class.Get_client_view_app_data()["specialization_id"],
            country: ipaddress.country_code,
            den_treat_id:this.den_treatment_id
          }

        } else {
          // send assign_ref_data
          send_data = {
            ref_doc_reg_id: doc_id,
            ref_den_spl_id: ref_spl_id,
          }
        }
        Doc_Helper.Set_dental_reff_data(send_data);
        
        break;
      }
    }
  }
}

