import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Observable, Observer, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../common-module/change-password/change-password.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { Diet_Helper } from '../../../app/Diet_module/Diet_Helper';
import { MenuViewService } from './menu-view.service';
import { MasterHelperService } from '../../../app/Doctor_module/MasterHelper.service';
import { MasterCSHelperService } from '../../../app/Doctor_module/casesheet/masterCSData.service';
import { Doc_Helper } from '../../../app/Doctor_module/Doc_Helper';
import { Master_Helper } from '../../../app/Doctor_module/Master_Helper';
import { TermsConditionComponent } from '../../../app/Doctor_module/terms-condition/terms-condition.component';
import { FrontDesk_Helper } from '../../../app/FrontDesk_module/FrontDesk_Helper';
import { Admin_Helper } from '../../../app/Admin_module/Admin_Helper';
import { Diagnosis_Helper } from '../../../app/Diagnosis_module/Diagnosis_Helper';
import { Physio_Helper } from '../../../app/Physio_module/Physio_Helper';
import { VoicerecognitionService } from '../../../app/voicerecognition.service';

declare var $: any;
declare var webkitSpeechRecognition:any

@Component({
  selector: 'app-menu-view',
  templateUrl: './menu-view.component.html',
  styleUrls: ['./menu-view.component.scss']
})
export class MenuViewComponent implements OnInit {
  recognition= new webkitSpeechRecognition()
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;
  @ViewChild('scrol_traget') public myScrollContainer: ElementRef;
  subscription: Subscription;

  public open_flag: boolean = true;
  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public profileImage;
  public firstName: string;
  public lastName: string;

  public messages;
  public msgflag: boolean = false;

  public notifications;
  public notifyflag: boolean = false;
  public settings: boolean = false;
  //Doctor menu
  public docInpatSubmenuFlag: boolean = false;
  public minimized_asied: boolean = false;
  public selectedMenu;
  public docAppointmentListFlag: boolean = true;
  public docBillingFlag: boolean = true;
  public docSecondopinionFlag: boolean = true;
  public docNursePermssionFlag: boolean = true;
  public docDashboardFlag: boolean = true;
  public dashboardBasicFlag: boolean = true;
  public docHomecareFlag: boolean = true;
  public docCaseSheetFlag: boolean = true;
  public docCaseSummaryFlag: boolean = true;
  public docWalkinFlag: boolean = true;
  public docInpatientFlag: boolean = true;
  public docMisReport: boolean = true;
  public docReferenceVideoFlag: boolean = true;
  public docReportsFlag: boolean = false;
  public consolidatedFlag: boolean = true;
  public notificationFlag: boolean = false;
  public displayPage;
  public notificationCount;
  public docScheduleSubmenuFlag: boolean = false;

  //front-desk
  public permissionSubmenuFlag: boolean = false;
  public reportsSubmenuFlag: boolean = false;
  public minimizedAsied: boolean = false;
  public inpatientFlag: boolean = false;
  public manageAppointmentFlag: boolean = true;
  public viewPrescritionFlag: boolean = true;
  public billingChargesFlag: boolean = true;
  public billCreateFlag: boolean = true;
  public billEditFlag: boolean = true;
  public advanceFlag: boolean = true;
  public patientListFlag: boolean = true;
  public wardMasterFlag: boolean = true;
  public refund: boolean = true;
  public consolidatedBillingFlag: boolean = true
  public inpatientMenuFlag: boolean = true;
  public consultantFlag: boolean = true;
  public billingFlag: boolean = false;
  public permissionsFlag: boolean = false;
  public reportsFlag: boolean = false;
  public hospBillsReportFlag: boolean = true;
  public diagBillsReportFlag: boolean = true;
  public inpatReportFlag: boolean = true;
  public reference_video: boolean = true
  //Admin
  public salesReportFlag: boolean = true;
  public stockReportFlag: boolean = true;
  public stockEditFlag: boolean = true;
  public docBillEditFlag: boolean = true;
  public diagBillEditFlag: boolean = true;
  public salesReportGstFlag: boolean = true;
  public stockReceivableFlag: boolean = true;
  public schdForDrugInspFlag: boolean = true;
  public inwardReportFlag: boolean = true;
  public adminInpatReportFlag: boolean = true;
  public salesForDrugInspFlag: boolean = true;
  public adminHospBillsReportFlag: boolean = true;
  public adminDiagBillsReportFlag: boolean = true;
  public adminConsolidatedFlag: boolean = true;

  //diagnosis
  public testSubMenuFlag: boolean = false;
  public outsubFlag: boolean = false;
  public dashSubMenuFlag: boolean = false;
  public minimizedAsiedFlag: boolean = false;
  public diagAppointmentFlag: boolean;
  public diagBillFlag: boolean;
  public reportFlag: boolean;
  public sourceFlag: boolean;
  public repAppFlag: boolean;
  public dashFlag: boolean;
  public menuAppFlag: boolean;
  public diagMenuReportsFlag: boolean;
  public user: boolean;
  public test: boolean;
  //public package: boolean;
  public nepAppFlag: boolean;
  public diabFlag: boolean;
  public userHidden: boolean;

  //nurse
  public appointmentData: boolean;

  //physio
  public eventIconFlag: boolean;
  public homecareFlag: boolean;
  public isAdmin: boolean;
  public walkinFlag: boolean;

  public currentDate;
  public loginType;
  public sendType;
  public packageCreateFlag: boolean = false;
  public doctor_referral: boolean;
  public leadMgmtFlag:boolean;
  public partialPaymentFlag: boolean = false;
  public insuranceBillFlag: boolean = false;
  public salesreportdoctorReportFlag: boolean = true;
  public Bill_refundflag: boolean = true;
  public billDueFlag: boolean = true;
  public bedOccupancyFlag: boolean = true;
  public abcAnalysisFlag: boolean = true;
  public purchaseOrderFlag: boolean = true;
  public productFlag: boolean = true;
  public productReceivablesFlag: boolean = true;
  public labtestFlag: boolean = true;
  public consolidatedreportflag:boolean=true;
  public notvistedpatreportflag:boolean=true;

  public menu_bar_keys ={"in patient":'patient',
  "home":"home","patients":'patientsinnermenu'
  ,"dashboard":'dashboardinnermenu',
  "consolidatedbills":'consolidatedbillsinnermenu',"appointments":'appointment_list',"walkin":'walkin',"permissions":'permission',
  "walk in":'walkin',"wapking":"walkin",
  "secondopinion":'secondopinion',"billings":'billing',"building":"billing","buildings":"billing","2nd opinion":'secondopinion',"second opinion":'secondopinion'
  ,"homecare":'homecare',"schedule":'schedule',"mycalender":'mycalenderinnermenu',"home care":'homecare',"permission":'permissionsmenu'
  ,"billing":'billing',
  "doctor":'doctorinnermenu',"viewschedule":'viewscheduleinnermenu',"notification":'notification',"notifications":'notification',
  "help":'help',"referencevideo":'reference_video',"misreports":'reportsMenu',"reference video":'reference_video',
  "salesreports":'salesreports',"stockreports":'stockreportsinnermenu',"mis report":"reportsMenu","mis reports":"reportsMenu",
  "receivablereports":'receivablereportsinnermenu'
  }
  public client_side_bar ={"appointment":"appointment_menu_bar_client" ,"doctor":"doctorinnermenu",
  "diagnosis":"diagnosisinnermenu","physio":"physioinnermenu","dietician":"dieticianinnermenu",
  "second opinion":"sec_opi_smenu","vitals measurments":"Vitals_mes_menu"
  ,"lab reports":"labrepmenu","know your health":"yourhealthmenu"
  ,"activities":"activity_inner_mennu","activity":"activity_inner_mennu","diet":"diet_inner_mennu",
  "mindfulness":"mind_inner_mennu","ehr":"ehrmenu","health data":"health_inner_mennu","post covid":"post_inner_mennu","documents":"documents_inner_mennu",
  "pharmacy":"Pharma_menu_client","current":"current_inner_menu","completed":"completed_inner_menu","medicine":"medicinemenu","relation":"relationmenu","bill list":"bill_menu_client"
  ,"reading":"reading_menu","readings":"reading_menu","settings":"settings_menu","features":"feature_innermenu",
  "health packages":"healtpackages_menu","health package":"healtpackages_menu",
  "notifications":"Notification_menu","notification":"Notification_menu"}

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: MenuViewService, public dialog: MatDialog, public masterData: MasterHelperService, public masterCSdata: MasterCSHelperService
    ,public speechRecognition:VoicerecognitionService) {
    this.masterData.callMasterData();
    this.masterCSdata.clearValue();
    FrontDesk_Helper.setBillFlag(undefined);
    Admin_Helper.setDiagBill(undefined);
    Diet_Helper.setDietFlow(undefined);
    this.appointments("app");
    // this.menuNav("appointment");
  }

  check_scroll() {
    this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  }

  speechRecognitionvalue(){
    this.subscription = this.speechRecognition.getMessage().subscribe(message => {
      if (message ) {
        console.log("menu"+message)
        if(message in this.menu_bar_keys){
          $("#"+this.menu_bar_keys[message]).click();
        }
      }
      })
  }

  ngOnInit() {
    // this.init();
    // this.start();
    this.loginType = Helper_Class.getLoginType();
    this.speechRecognitionvalue()
    console.log("loginType" + this.loginType);
    this.getCurrentDate();
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
        if (this.loginType == "Admin") {
          this.menuNavAdmin(message, "");
        } else {
          this.menuNav(message);
        }
      }
    });
    this.messageservice.listen().subscribe((m: any) => {
      if (m == "detail_view") {
        //this.check_scroll();
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.firstName = this.userInfo.first_name;
    this.lastName = this.userInfo.last_name;
    this.profileImage = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.docInpatSubmenuFlag = false;
    this.minimized_asied = false;
    this.docScheduleSubmenuFlag = false;

    // dynamic menu display
    if (this.loginType == "doctor" || this.loginType == "consultant") {
      this.hospitalName = Doc_Helper.getHospital_name();
      localStorage.setItem("hospital_name", this.hospitalName);
      this.hospitalLogo = Doc_Helper.getHospital_logo();
      this.sendType = "doctor";
      var Moduleidlist;
      if (Doc_Helper.getModuleList() != null) {
        Moduleidlist = Doc_Helper.getModuleList();
        this.docAppointmentListFlag = true;
        this.docBillingFlag = true;
        this.docSecondopinionFlag = true;
        this.docNursePermssionFlag = true;
        this.docDashboardFlag = true;
        this.dashboardBasicFlag = true;
        this.docHomecareFlag = true;
        this.docCaseSheetFlag = true;
        this.docCaseSummaryFlag = true;
        this.docWalkinFlag = true;
        this.docInpatientFlag = true;
        this.docMisReport = true;
        this.docReferenceVideoFlag = true;
        this.viewPrescritionFlag = false;
        
        Doc_Helper.setCasesheet_flag("0");
        Doc_Helper.setCasesumm_flag("0");

        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i] == "15") {
            this.docAppointmentListFlag = false;
          } else if (Moduleidlist[i] == "17") {
            this.docBillingFlag = false;
          } else if (Moduleidlist[i] == "20") {
            if(this.userInfo.second_opinion =="1"){
              this.docSecondopinionFlag = false;
            }
           
          } else if (Moduleidlist[i] == "18") {
            this.docNursePermssionFlag = false;
          } else if (Moduleidlist[i] == "13") {
            this.docDashboardFlag = false;
          } else if (Moduleidlist[i] == "12") {
            this.dashboardBasicFlag = false;
          } else if (Moduleidlist[i] == "6") {
            if(this.userInfo.home_care =="1"){
              this.docHomecareFlag = false;
            }
            
          } else if (Moduleidlist[i] == "29") {
            this.docCaseSheetFlag = false;
            Doc_Helper.setCasesheet_flag("1");
          } else if (Moduleidlist[i] == "30") {
            this.docCaseSummaryFlag = false;
            Doc_Helper.setCasesumm_flag("1");
          } else if (Moduleidlist[i] == "37") {
            this.docWalkinFlag = false;
          } else if (Moduleidlist[i] == "38") {
            this.docInpatientFlag = false;
          } else if (Moduleidlist[i] == "45") {
            this.docMisReport = false;
          } else if (Moduleidlist[i] == "46") {
            this.docReferenceVideoFlag = false;
          } else if (Moduleidlist[i] == "77") {
            this.consolidatedFlag = false;
          }
        }
      }
      this.getNotificationList();
    } else if (this.loginType == "front-desk") {
      this.hospitalName = this.userInfo.hptl_name;
      this.hospitalLogo = ipaddress.Ip_with_img_address + this.userInfo.hptl_logo;
      var Moduleidlist;
      this.sendType = "front-desk";
      if (Helper_Class.getmodulelist() != null) {
        Moduleidlist = Helper_Class.getmodulelist();
        this.manageAppointmentFlag = true;
        this.viewPrescritionFlag = true;
        this.billingChargesFlag = true;
        this.billCreateFlag = true;
        this.billEditFlag = true;
        this.advanceFlag = true;
        this.patientListFlag = true;
        this.wardMasterFlag = true;
        this.refund = true;
        this.consultantFlag = true;
        this.inpatientMenuFlag = true;
        this.permissionsFlag = true;
        this.hospBillsReportFlag = true;
        this.diagBillsReportFlag = true;
        this.inpatReportFlag = true;
        this.notvistedpatreportflag=false;
        this.consolidatedreportflag=false;

        var bill: any = {};
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i] == "15") {
            this.manageAppointmentFlag = false;

          } else if (Moduleidlist[i] == "38") {
            this.inpatientMenuFlag = false;

          } else if (Moduleidlist[i] == "44") {
            this.consultantFlag = false;

          } else if (Moduleidlist[i] == "52") {
            this.viewPrescritionFlag = false;

          } else if (Moduleidlist[i] == "53") {
            this.billingChargesFlag = false;

          } else if (Moduleidlist[i] == "56") {
            this.advanceFlag = false;

          } else if (Moduleidlist[i] == "57") {
            this.refund = false;

          } else if (Moduleidlist[i] == "58") {
            this.patientListFlag = false;

          } else if (Moduleidlist[i] == "59") {
            this.wardMasterFlag = false;

          } else if (Moduleidlist[i] == "60") {
            this.permissionsFlag = false;

          } else if (Moduleidlist[i] == "77") {
            this.consolidatedBillingFlag = false;

          } else if (Moduleidlist[i] == "65") {
            this.hospBillsReportFlag = false;

          } else if (Moduleidlist[i] == "66") {
            this.diagBillsReportFlag = false;
            
          } else if (Moduleidlist[i] == "68") {
            this.inpatReportFlag = false;

          } else if (Moduleidlist[i] == "28") { // Bill View
            Helper_Class.setBillView(null);
            Helper_Class.setBillView("1");
            
          } else if (Moduleidlist[i] == "54") { // Bill Create
            this.billCreateFlag = false;
            bill.create = Moduleidlist[i];
            FrontDesk_Helper.setBillFlag(bill);
            Helper_Class.setBillCreate(null);
            Helper_Class.setBillCreate("1");

          } else if (Moduleidlist[i] == "55") { // Bill Edit
            this.billEditFlag = false;
            bill.edit = Moduleidlist[i];
            FrontDesk_Helper.setBillFlag(bill);
            Helper_Class.setBillEdit(null);
            Helper_Class.setBillEdit("1");

          } else if (Moduleidlist[i] == "85") { // Bill Print
            Helper_Class.setBillPrint(null);
            Helper_Class.setBillPrint("1");

          } else if (Moduleidlist[i] == "101") { // Bill Print
            Helper_Class.setBillDelete(null);
            Helper_Class.setBillDelete("1");

          } else if (Moduleidlist[i] == "46") {
            this.reference_video = false;

          }else if (Moduleidlist[i] == "109") {
            this.salesreportdoctorReportFlag = false;

          } else if (Moduleidlist[i] == "110") {
            this.Bill_refundflag = false;

          } else if (Moduleidlist[i] == "111") {
            this.billDueFlag = false;

          } else if (Moduleidlist[i] == "112") {
            this.bedOccupancyFlag = false;

          } else if (Moduleidlist[i] == "113") {
            this.abcAnalysisFlag = false;

          } else if (Moduleidlist[i] == "114") {
            this.labtestFlag = false;

          } else if (Moduleidlist[i] == "115") {
            this.productReceivablesFlag = false;
          }
        }

        if (this.hospBillsReportFlag == true && this.diagBillsReportFlag == true && this.inpatReportFlag == true) {
          this.reportsFlag = true;
        }
      }
    } else if (this.loginType == "Admin") {
      this.hospitalName = this.userInfo.hptl_name;
      this.hospitalLogo = ipaddress.Ip_with_img_address + this.userInfo.hptl_logo;
      var Moduleidlist;
      this.sendType = "admin";
      if (Helper_Class.getmodulelist() != null) {
        Moduleidlist = Helper_Class.getmodulelist();
        this.salesReportFlag = true;
        this.stockEditFlag = true;
        this.stockReportFlag = true;
        this.docBillEditFlag = true;
        this.diagBillEditFlag = true;
        this.salesReportGstFlag = true;
        this.stockReceivableFlag = true;
        this.schdForDrugInspFlag = true;
        this.inwardReportFlag = true;
        this.inpatReportFlag = true;
        this.salesForDrugInspFlag = true;
        this.hospBillsReportFlag = true;
        this.diagBillsReportFlag = true;
        this.notvistedpatreportflag=false;
        this.consolidatedreportflag=false;
        
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i] == "47") {
            this.salesReportFlag = false;
          } else if (Moduleidlist[i] == "49") {
            this.stockEditFlag = false;
          } else if (Moduleidlist[i] == "48") {
            this.stockReportFlag = false;
          } else if (Moduleidlist[i] == "50") {
            this.docBillEditFlag = false;
          } else if (Moduleidlist[i] == "51") {
            this.diagBillEditFlag = false;
            Admin_Helper.setDiagBill(false);
          } else if (Moduleidlist[i] == "61") {
            this.salesReportGstFlag = false;
          } else if (Moduleidlist[i] == "62") {
            this.stockReceivableFlag = false;
          } else if (Moduleidlist[i] == "62") {
            this.stockReceivableFlag = false;
          } else if (Moduleidlist[i] == "63") {
            this.schdForDrugInspFlag = false;
          } else if (Moduleidlist[i] == "64") {
            this.salesForDrugInspFlag = false;
          } else if (Moduleidlist[i] == "65") {
            this.hospBillsReportFlag = false;
          } else if (Moduleidlist[i] == "66") {
            this.diagBillsReportFlag = false;
          } else if (Moduleidlist[i] == "67") {
            this.inwardReportFlag = false;
          } else if (Moduleidlist[i] == "68") {
            this.inpatReportFlag = false;
          } else if (Moduleidlist[i] == "77") {
            this.adminConsolidatedFlag = false;
          } else if (Moduleidlist[i] == "28") { // Bill View
            Helper_Class.setBillView(null);
            Helper_Class.setBillView("1");
          } else if (Moduleidlist[i] == "54") { // Bill Create
            Helper_Class.setBillCreate(null);
            Helper_Class.setBillCreate("1");
          } else if (Moduleidlist[i] == "55") { // Bill Edit
            Helper_Class.setBillEdit(null);
            Helper_Class.setBillEdit("1");
          } else if (Moduleidlist[i] == "85") { // Bill Print
            Helper_Class.setBillPrint(null);
            Helper_Class.setBillPrint("1");
          } else if (Moduleidlist[i] == "100") { // package create
            this.packageCreateFlag = true
          }
        }
      }

    } else if (this.loginType == "diagnosis") {
      this.sendType = "diagnosis";
      if (Helper_Class.getmodulelist() != null) {
        Moduleidlist = Helper_Class.getmodulelist();
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i] == "28") { // Bill View
            this.diagBillFlag = false;
            Helper_Class.setBillView(null);
            Helper_Class.setBillView("1");
          } else if (Moduleidlist[i] == "54") { // Bill Create
            Helper_Class.setBillCreate(null);
            Helper_Class.setBillCreate("1");
          } else if (Moduleidlist[i] == "55") { // Bill Edit
            Helper_Class.setBillEdit(null);
            Helper_Class.setBillEdit("1");
          } else if (Moduleidlist[i] == "85") { // Bill Print
            Helper_Class.setBillPrint(null);
            Helper_Class.setBillPrint("1");
          } else if (Moduleidlist[i] == "46") {
            this.reference_video = false;
          }
        }
      }
      if (Diagnosis_Helper.getUserID_Type().user_type == "Chief pathologist" || Diagnosis_Helper.getUserID_Type().user_type == "Pathologist") {
        this.diagAppointmentFlag = true;
        this.diagBillFlag = true;
        this.reportFlag = true;
        this.sourceFlag=true;
        this.repAppFlag = false;
        this.dashFlag = true;
        this.menuAppFlag = true;
        this.diagMenuReportsFlag = true;
        this.test = true;

        this.nepAppFlag = true;
        this.diabFlag = true;
        this.userHidden = true;
        this.menuNav("report_approval");
      } else if (Diagnosis_Helper.getUserID_Type().user_type == "Chief lab technician" || Diagnosis_Helper.getUserID_Type().user_type == "Lab technician") {
        this.diagAppointmentFlag = false;
        this.diagBillFlag = true;
        this.reportFlag = false;
        this.sourceFlag=false;
        this.repAppFlag = true;
        this.dashFlag = true;
        this.menuAppFlag = false;
        this.repAppFlag = true;
        this.diagMenuReportsFlag = false;
        this.test = true;
        this.nepAppFlag = false;
        this.diabFlag = false;
        this.userHidden = true;

      } else if (Diagnosis_Helper.getUserID_Type().user_type == "front-desk") {
        this.menuNav("diagbilldetailview");
        
      } else {
        this.diagAppointmentFlag = false;
        this.diagBillFlag = false;
        this.reportFlag = false;
        this.sourceFlag=false;
        this.repAppFlag = false;
        this.dashFlag = false;
        this.menuAppFlag = false;
        this.repAppFlag = false;
        this.diagMenuReportsFlag = false;
        this.test = false;
        this.nepAppFlag = false;
        this.diabFlag = false;
        this.userHidden = false;
      }

    } else if (this.loginType == "nurse") {
      this.sendType = "nurse";
      this.hospitalName = this.userInfo.hospitals[0].hptl_name;
      this.hospitalLogo = ipaddress.Ip_with_img_address + this.userInfo.hospitals[0].logo;
    } else if (this.loginType == "physio") {
      var Moduleidlist;
      this.sendType = "physio";
      if (Physio_Helper.getModuleList() != null) {
        Moduleidlist = Physio_Helper.getModuleList();
        this.homecareFlag = true;
        this.eventIconFlag = true;
        this.isAdmin = true;
        this.walkinFlag = false;
        if (this.userInfo.admin == "1") {
          this.isAdmin = false;
        }
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i] == "35") {
            this.homecareFlag = false;
          } else if (Moduleidlist[i] == "21") {
            this.eventIconFlag = false;
          } else if (Moduleidlist[i] == "46") {
            this.reference_video = false;
          }
        }
      }
    }
  }

  appointments(data) {
    if (data == "home")
      Physio_Helper.setHomecare("1");
    else
      Physio_Helper.setHomecare("0");
  }

  setAppoinmentHelper(e) {
    if (e == 'appinment') {
      Doc_Helper.setAppDate(this.currentDate);
    } else if (e == "billing" || e == "ipbilling") {
      Doc_Helper.setBillFromDate(this.currentDate);
      Doc_Helper.setBillToDate(this.currentDate);
      if(e == "billing"){
        Doc_Helper.setIpCreateBill("opbil");
      } else {
        Doc_Helper.setIpCreateBill("ipbill");
      }
    } else if (e == "homecare") {
      Doc_Helper.setHomecareDate(this.currentDate);
    } else if (e == "doctor_availability") {
      Doc_Helper.setDocAvailabitiyDate(this.currentDate);
    }
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.currentDate = obj.current_date;
    }
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  getNotificationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/nc',
      {
        type: this.sendType,
        user_id: this.userInfo.user_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj.notifications != null) {
            if (obj.notifications.length != 0) {
              this.notificationCount = obj.notifications;
              this.notificationFlag = true;
            }
            else {
              this.notificationCount = "0";
            }
          }
        },
        error => { });
  }

  open_setting() {
    this.settings = !this.settings;
  }

  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: this.sendType,
        user: this.userInfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },
        error => { });
  }

  menuNav(page) {
    if (this.loginType == "doctor" || this.loginType == "consultant") {
      this.sendType = "doctor";
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.docInpatSubmenuFlag = false;
      this.docScheduleSubmenuFlag = false;
      this.docReportsFlag = false;
      Doc_Helper.setImageFlag(false);
      switch (page_nmae) {
        case 'home':
          this.displayPage = "dashboard";
          break;
        case 'patient':
          this.docInpatSubmenuFlag = !this.docInpatSubmenuFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'in_patient': this.docInpatSubmenuFlag = false; this.displayPage = page; break;
        case 'patient_bill': this.docInpatSubmenuFlag = false; break;
        case 'patient_dashboard': this.docInpatSubmenuFlag = false; this.displayPage = page; break;
        case 'appointment_list': this.displayPage = page; break;
        case 'walkin': this.displayPage = page; break;
        case 'permission': this.displayPage = page; break;
        case 'billing': Doc_Helper.setIpCreateBill("opbill"); this.displayPage = page; break;
        case 'secondopinion': this.displayPage = page; break;
        case 'homecare': this.displayPage = page; break;
        case 'schedule':
          this.docScheduleSubmenuFlag = !this.docScheduleSubmenuFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'calender': this.displayPage = page; break;
        case 'doctor_availability': this.displayPage = page; break;
        case 'manage_schedule': this.displayPage = page; break;
        case 'notification': this.displayPage = page; break;
        case 'inPatientViewInfo': this.displayPage = page; break;
        case 'cashSheetPersonal': this.displayPage = page; break;
        case 'billcreate': this.displayPage = page; break;
        case 'billdetail': this.displayPage = page; break;
        case 'billedit': this.displayPage = page; break;
        case 'inPatientDS': this.displayPage = page; break;
        case 'inpatient_Discharge_view': this.displayPage = page; break;
        case 'inpatient_guno_Discharge_view': this.displayPage = page; break;
        case 'billlist': this.displayPage = page; break;
        case 'help': this.displayPage = page; break;
        case 'casesheet': this.displayPage = page; break;
        case 'secondopinion_casesheet': this.displayPage = page; break;
        case 'profile_update': this.displayPage = page; break;
        case 'medprescription': this.displayPage = page; break;
        case 'ModalPage': this.displayPage = page; break;
        case 'casesheet_list': this.displayPage = page; break;
        case 'cardio_casesheet_view': this.displayPage = page; break;
        case 'appointment_view': this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
        case 'viewIpatient': this.displayPage = page; break;
        case 'reportsMenu':
          this.docReportsFlag = !this.docReportsFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'salseReport': this.displayPage = page; break;
        case 'receivablesReport': this.displayPage = page; break;
        case 'stockReport': this.displayPage = page; break;
        case 'inpatientbilling': this.displayPage = page; break;
        case 'ipbilling':
          Doc_Helper.setIpCreateBill("ipbill");
          this.displayPage = page; break;
        case 'ipbillcreate': this.displayPage = page; break;
        case 'ipcard': this.displayPage = page; break;
        case 'consolidatedBills': this.displayPage = page; break;
        case 'docappcreate': this.displayPage = page; break;
        case 'Sales_report_pharma':this.displayPage = page; break;
        case 'diag_bill_reports':this.displayPage = page; break;
        case 'hosp_bill_reports':this.displayPage = page; break;
        case 'docpresnocase':this.displayPage = page; break;
        case 'prescriptionlist': this.displayPage = page; break;
        case 'receptviewprescription': this.displayPage = page; break;
        case 'editpres': this.displayPage = page; break;
        case 'viewpres': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "doctor");
      
    } else if (this.loginType == "front-desk") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.reportsSubmenuFlag = false;
      this.billingFlag = false;
      this.inpatientFlag = false;
      this.permissionSubmenuFlag = false;

      switch (page_nmae) {
          case 'home':
          this.displayPage = "home";
          break;
        case 'manageappointments': this.displayPage = page; break;
        case 'appcreate': this.displayPage = page; break;
        case 'receptionistdoctordetailview': this.displayPage = page; break;
        case 'receptviewprescription': this.displayPage = page; break;
        case 'prescriptionlist': this.displayPage = page; break;
        case 'frontdeskbilling':
          var send = {
            flow: "front-desk",
            id: this.userInfo.user_id
          }
          Helper_Class.setadminBillingFlow(send);
          this.displayPage = page; break;
        case 'receptbillcreate': this.displayPage = page; break;
        case 'receptbilledit': this.displayPage = page; break;
        case 'receptbilldetail': this.displayPage = page; break;
        case 'registration': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'nurse_ward': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'nurse_patient': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'nurse_doctor': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'permission':
          this.permissionSubmenuFlag = !this.permissionSubmenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          }
          break;
        case 'billing':
          this.billingFlag = !this.billingFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'advances': this.displayPage = page; break;
        case 'bills': this.displayPage = page; break;
        case 'billingDiagDetailsView': this.displayPage = page; break;
        case 'billingDetailsView': this.displayPage = page; break;
        case 'charges': this.displayPage = page; break;
        case 'refund': this.displayPage = page; break;
        case 'createRefund': this.displayPage = page; break;
        case 'inpatient':
          this.inpatientFlag = !this.inpatientFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'dischargeList': this.displayPage = page; break;
        case 'Patients': this.displayPage = page; break;
        case 'inPatientCreate': this.displayPage = page; break;
        case 'consultant': this.displayPage = page; break;
        case 'diaBillCreate': this.displayPage = page; break;
        case 'diaAppDetail': this.displayPage = page; break;
        case 'diaAppCreate': this.displayPage = page; break;
        case 'wardmaster': this.displayPage = page; break;
        case 'OTmaster': this.displayPage = page; break;
        case 'OTBooking': this.displayPage = page; break;
        case 'viewIpatient': this.displayPage = page; break;
        case 'diaBillingDetailsView': this.displayPage = page; break;
        case 'ipatientBillCreate': this.displayPage = page; break;
        case 'frontConsolidatedBills': this.displayPage = page; break;
        case 'dietappointments': Diet_Helper.setAppFlow(undefined); this.displayPage = page; break;
        case 'dietappointmentsdetailed': this.displayPage = page; break;
        case 'dietappointmentscreate': this.displayPage = page; break;
        case 'reports': this.reportsSubmenuFlag = !this.reportsSubmenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'hosp_bill_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'diag_bill_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'inpat_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
        case 'listPackage': this.displayPage = page; break;
        case 'createPackage': this.displayPage = page; break;
        case 'inpdischargeview': this.displayPage = page; break;
        case 'inPatientDS': this.displayPage = page; break;
        case 'docref': this.displayPage = page; break;
        case 'docrefcreate': this.displayPage = page; break;
        case 'medprescription': this.displayPage = page; break;
        case 'leadmgmt': this.displayPage = page; break;
        case 'partialPayments' : this.displayPage = page; break;
        case 'insuranceBill': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'sales_report_doctor': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bill_refund': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bill_due': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bed_occupancy': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'abc_analysis': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'purchase_order': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'product': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'product_receivables': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'lab_test_wise': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'notvisited_report':this.reportsSubmenuFlag = false;this.displayPage = page; break;
        case 'consolidat_report':this.reportsSubmenuFlag = false;this.displayPage = page; break;
        case 'appedit' :this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "front");
      
    } else if (this.loginType == "diagnosis") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.testSubMenuFlag = false;
      this.outsubFlag=false;
      this.dashSubMenuFlag = false;
      this.sendType = "diagnosis";
      switch (page_nmae) {
        case 'diagnosis_home': this.displayPage = page; break;
        case 'appointment': this.displayPage = page; break;
        case 'tests': this.displayPage = page; break;
        case 'report_upload':
          this.testSubMenuFlag = !this.testSubMenuFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        case 'pending': this.testSubMenuFlag = false; this.displayPage = page; break;
        case 'rejected': this.testSubMenuFlag = false; this.displayPage = page; break;
        case 'completed': this.testSubMenuFlag = false; this.displayPage = page; break;
        case 'report_upload_edit': this.displayPage = page; break;
        case 'report_upload_view': this.displayPage = page; break;
        case 'report_approval': this.displayPage = page; break;
        case 'nephrology_readings': this.displayPage = page; break;
        case 'diabetics_readings': this.displayPage = page; break;
        //case 'billing': this.displayPage = page; break;
        case 'billing':
          this.billingFlag = !this.billingFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'billinglist': this.displayPage = page; break;
        case 'users': this.displayPage = page; break;
        case 'help': this.displayPage = page; break;
        case 'diagnosisview': this.displayPage = page; break;
        case 'nephrologyreadingviewpage': this.displayPage = page; break;
        case 'users_view': this.displayPage = page; break;
        case 'report_approval_detailed': this.displayPage = page; break;
        case 'diagtestadd': this.displayPage = page; break;
        case 'diagbilldetailview': this.displayPage = page; break;
        case 'diagbillcreate': this.displayPage = page; break;
        case 'diag_app_create': this.displayPage = page; break;
        case 'dashboard': this.dashSubMenuFlag = !this.dashSubMenuFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        case 'patient_list': this.dashSubMenuFlag = false; this.displayPage = page; break;
        case 'reports': this.dashSubMenuFlag = false; this.displayPage = page; break;
        case 'billedit': this.displayPage = page; break;
        case 'diagPackageCreate' :this.displayPage = page; break;
        case 'appedit' :this.displayPage = page; break;
        case 'samplecollect' :this.displayPage = page; break;
        case 'outsource':
          this.outsubFlag = !this.outsubFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        case 'out-centre-list': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-centre': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-reports': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-payments': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-test-map': this.displayPage = page; break;
        case 'partialPayments' : this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "diag");
    } else if (this.loginType == "nurse") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      switch (page_nmae) {
        case 'profile': this.displayPage = page; break;
        case 'appointment': this.displayPage = page; break;
        case 'appoinmentDetailView': this.displayPage = page; break;
        case 'assignList': this.displayPage = page; break;
        case 'assignDetailView': this.displayPage = page; break;
        case 'doctorAppList': this.displayPage = page; break;
        case 'doctorList': this.displayPage = page; break;
        case 'inpatient': this.displayPage = page; break;
        case 'inPatientViewInfo': this.displayPage = page; break;
        case 'medicine_tracker': this.displayPage = page; break;
        case 'DoctorAppDetail': this.displayPage = page; break;
        case 'viewIpatient': this.displayPage = page; break;
        case 'casesheet': this.displayPage = page; break;
        case 'casesheet_list': this.displayPage = page; break;
        case 'cardio_casesheet_view': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "nurse");
    } else if (this.loginType == "physio") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      switch (page_nmae) {
        case 'appointment': this.displayPage = page; break;
        case 'casesheet': this.displayPage = page; break;
        case 'assignmentList': this.displayPage = page; break;
        case 'assignmentDetailPage': this.displayPage = page; break;
        case 'therapies': this.displayPage = page; break;
        case 'userList': this.displayPage = page; break;
        case 'userViewPage': this.displayPage = page; break;
        case 'Home': this.displayPage = page; break;
        case 'billing': this.displayPage = page; break;
        case 'walkin': this.displayPage = page; break;
        case 'billdetails': this.displayPage = page; break;
        case 'billCreate': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "diag");
    } else if (this.loginType == "dietician") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.sendType = "dietician";
      switch (page_nmae) {
        case 'profile': this.displayPage = page; break;
        case 'manageappointments': this.displayPage = page; break;
        case 'dietPlan': this.displayPage = page; break;
        case 'dietPlancard': this.displayPage = page; break;
        case 'appointment': this.displayPage = page; break;
        case 'appt_create': this.displayPage = page; break;
        case 'casesheet': this.displayPage = page; break;
        case 'notification': this.displayPage = page; break;
        case 'diet_dashboard': this.displayPage = page; break;
        case 'patientList':
          Helper_Class.setDiagReadingList(null);
          Helper_Class.setDiagReadingList("dietList");
          this.displayPage = page; break;
        case 'patientTracking': this.displayPage = page; break;
        case 'billList':
          var setFlow = {
            flow: 'dietcian',
            user_id: this.userInfo.user_id
          }
          Helper_Class.setBillingFlow(null);
          Helper_Class.setBillingFlow(setFlow);
          this.displayPage = page;
          break;
        case 'billCreate': this.displayPage = page; break;
        case 'billdetail': this.displayPage = page; break;
        case 'readings':
          Helper_Class.setDiagReadingList(null);
          Helper_Class.setDiagReadingList("readings");
          this.displayPage = page;
          break;
        case 'diabReadings': this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "diag");
    }
  }
  public submenuFlag1: boolean = false;
  public submenu_flag: boolean = false;
  menuNavAdmin(page, type) {
    let page_name: string = page;
    this.selectedMenu = page;
    Helper_Class.setReportType(type);
    switch (page_name) {
      case 'sales_report':
        this.displayPage = "sales_report";
        break;
      case 'stock_report': this.displayPage = page; break;
      case 'stock_edit': this.displayPage = page; break;
      case 'bill_list': this.displayPage = page; break;
      case 'bill_edit': this.displayPage = page; break;
      case 'salesGst': this.displayPage = page; break;
      case 'stockReceivable': this.displayPage = page; break;
      case 'gsd': this.displayPage = page; break;
      case 'recreceivableReport': this.displayPage = page; break;
      case 'SDI': this.displayPage = page; break;
      case 'HBR': this.displayPage = page; break;
      case 'DBR': this.displayPage = page; break;
      case 'IR': this.displayPage = page; break;
      case 'IPR': this.displayPage = page; break;
      case 'consolidatedBills': this.displayPage = page; break;
      case 'billingList':
        this.submenu_flag = !this.submenu_flag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'billingEdit': this.displayPage = page; break;
      case 'billingCreate': this.displayPage = page; break;
      case 'inPatientCreate': this.displayPage = page; break;
      case 'billingDetailsView': this.displayPage = page; break;
      case 'diagBillingList': this.displayPage = page; break;
      case 'diagBillingView': this.displayPage = page; break;
      case 'diagBillingEdit': this.displayPage = page; break;
      case 'pharmaBillingList': this.displayPage = page; break;
      case 'pharmaBillingView': this.displayPage = page; break;
      case 'createPackage': this.displayPage = page; break;
      case 'reports':
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'clientPackageList': this.displayPage = page; break;
      case 'listPackage': this.displayPage = page; break;
      case 'createUserPackage': this.displayPage = page; break;
      case 'adminReceptbillcreate': this.displayPage = page; break;
      case 'diagbillcreate': this.displayPage = page; break;
      case 'leadmgmt': this.displayPage = page; break;
      case 'notvisited_reportadmin':this.displayPage = page;break;
      case 'consolidat_reportadmin':this.displayPage = page;break;
      case 'authorize_list':this.displayPage = page;break;
    }
  }

  minimizing() {
    this.minimized_asied = !this.minimized_asied;
    if (this.minimized_asied == true) {
      this.asied_width.nativeElement.setAttribute('style', 'width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 12px 0 0 3px;}');
      this.docInpatSubmenuFlag = false;
    } else {
      this.asied_width.nativeElement.setAttribute('style', 'width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 17px 0 0 14px;}');
    }
  }

  onResize(event) {
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimized_asied = false;
  }

  openMenumodel() {
    this.minimized_asied = false;
    customjs.openMobileMenuFun();
  }

  menuModelAction(action) {
    if (action == 'profile_update' || action == 'diagnosis_home') {
      if (this.loginType == "Admin") {
        this.menuNavAdmin(action, "");
      } else if(Helper_Class.getInfo().user_type == 'Chief pathologist' || Helper_Class.getInfo().user_type == 'Pathologist'
        || Helper_Class.getInfo().user_type =='Chief lab technician' || Helper_Class.getInfo().user_type =='Lab technician'){
          this.menuNav('users_view');
          
      } else if(this.loginType == "front-desk") {
        //do nothing
      } else {
        this.menuNav(action);
      }

    } else if (action == 'changePassword') {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: { type: this.sendType }
      });

    } else if (action == 'terms_conditions') {
      const dialogRef = this.dialog.open(TermsConditionComponent, {
        width: '50%',
        height: '500px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result["key"] == 1) {
        } else {
          this.toastr.error(Message_data.defaultErr);
        }
      });

    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: this.sendType,
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut);
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => { });
    }
    $('#exampleModal').modal('hide');
  }

  setDoctor(e) {
    FrontDesk_Helper.setDate(undefined);
    if (e == "prescriptionlist") {
      FrontDesk_Helper.setDocPrescription(undefined);
      FrontDesk_Helper.setDate(this.currentDate);
    } else if (e == "manageappointments") {
      FrontDesk_Helper.setdoctor(undefined);
      FrontDesk_Helper.setDietician(undefined);
      FrontDesk_Helper.setDate(this.currentDate);
    }
  }

  setFrontDeskAppoinmentHelper(e) {
    if (e == "billing") {
      FrontDesk_Helper.setBillFromDate(this.currentDate);
      FrontDesk_Helper.setBillToDate(this.currentDate);
    }
  }

  setAppFlow(data){
    Helper_Class.setPartialUser(data);
  }
  
  setReport(reports) {
    Helper_Class.setReportType(undefined);
    Helper_Class.setReportType(reports);
  }

  //admin
  setAdminAppoinmentHelper(e) {
    if (e == "billing") {
      FrontDesk_Helper.setBillFromDate(this.currentDate);
      FrontDesk_Helper.setBillToDate(this.currentDate);
      var bill = {
        edit: "55"
      }
      FrontDesk_Helper.setBillFlag(bill);
    }
  }
  isStoppedSpeechRecog = false;
  public text='';
  tempWords: any;
  Name: string;
  // text:string;
  arr=[]

  init() {
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e: { results: Iterable<unknown> | ArrayLike<unknown>; }) => {
      const transcript = Array.from(e.results)
        .map((result:any) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript.toLowerCase().trim();
      console.log("transcript"+transcript);
    });
  }
  
  start() {
    const constraints = { audio: true }; // Constraints object for getUserMedia()
  
    this.isStoppedSpeechRecog = false;
  
    // Request access to user's microphone
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        // Enumerate available devices
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            const audioDevices = devices.filter((device) => device.kind === 'audioinput');
            if (audioDevices.length > 0) {
              const selectedDeviceId = audioDevices[0].deviceId; // Select the first available microphone
              this.startRecognition(selectedDeviceId); // Start recognition with the selected microphone
            } else {
              console.error('No audio input devices found.');
            }
          })
          .catch((error) => {
            console.error('Error enumerating devices:', error);
          });
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  }
  
  startRecognition(deviceId: string) {
    const recognition = new webkitSpeechRecognition();
    recognition.interimResults = true;
    recognition.lang = 'en-US';
    recognition.start();
    console.log("Speech recognition started")
  
    recognition.addEventListener('end', (condition: any) => {
      if (this.isStoppedSpeechRecog) {
        recognition.stop();
        console.log("End speech recognition");
      } else {
        this.wordConcat();
        console.log("wordConcat");
        this.startRecognition(deviceId); // Restart recognition with the selected microphone
      }
    });
  
    recognition.addEventListener('result', (e: { results: Iterable<unknown> | ArrayLike<unknown>; }) => {
      const transcript = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript.toLowerCase().trim();
      console.log("transcript" + transcript);
    });
  }

  wordConcat() {
    console.log("wordConcat===="+this.tempWords);
    if (this.tempWords!=undefined&&this.tempWords!="") {
     this.text = this.tempWords
     this.speechRecognition.sendMessage(this.tempWords)
    // this.speechRecognition.getMessage()
      console.log(" this.speechRecognition.getMessage()" ,  this.speechRecognition.getMessage());
 
     if (this.text==="login") {
       this.router.navigateByUrl("loginpage")
     }
     if (this.text==="privacy policy") {
       this.router.navigateByUrl("privacy_policy")
     }
     if (this.text==="registration") {
       this.router.navigateByUrl("registration")
     }
    
    
    }
   
  
     this.tempWords = '';
   }

   stopregpp(){
   
    this.isStoppedSpeechRecog = true;
   
    // this.recognition.stop();
    this.speechRecognition.stopMessages()
    console.log("Speech recognition stoped")
   }

}
