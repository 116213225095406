<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Doctor list</h5>
                        </div>
                        <div class="headerButtons">
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div>
                        <app-loader></app-loader>
                    </div>
                    <p class="nodata" *ngIf="doctorArray.length == 0">No Doctor(s) found</p>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2" *ngFor="let doctor of doctorArray">
                            <div class="doctorCover">
                                <div class="doctorImgDiv">
                                    <img src="{{doctor.Prof_image}}" class="profile_image">
                                </div>
                                <div>
                                    <p class="app_names"> {{doctor.Doctor_Name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>