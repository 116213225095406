import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers, JSONPBackend } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharma-discount-mst',
  templateUrl: './pharma-discount-mst.component.html',
  styleUrls: ['./pharma-discount-mst.component.scss']
})
export class PharmaDiscountMstComponent implements OnInit {
  public salVol: string = null;
  public salVolDisc: string = null;
  public transVol: string = null;
  public transVolDisc: string = null;
  public regsalVol: string = null;
  public regsalVolDisc: string = null;
  public discSalesVolArray = [];
  public discTransVolArray = [];
  public discRegsalesVol = [];
  public userInfo;
  public pharmacistID;
  public pharmacyID;
  public saveFlag: boolean = true;
  constructor(public messageservice: PharmacyService, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
  }
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.discountRetrieval();
  }
  addSalesVolume() {
    if (this.salVol != undefined && this.salVolDisc != undefined) {
      if (this.discSalesVolArray.length != 0) {
        for (var i = 0; i < this.discSalesVolArray.length; i++) {
          if (this.discSalesVolArray[i].volume == this.salVol && this.discSalesVolArray[i].discount == this.salVolDisc) {
            this.toastr.error(Message_data.discAlreadyExist);
            break;
          } else {
            this.discSalesVolArray.push({
              volume: this.salVol,
              discount: this.salVolDisc,
            });
            break;
          }
        }
      } else {
        this.discSalesVolArray.push({
          volume: this.salVol,
          discount: this.salVolDisc,
        });
      }
      this.salVolDisc = null;
      this.salVol = null;
    }
  }
  editSalesVolume(volume, discount) {
    for (var i = 0; i < this.discSalesVolArray.length; i++) {
      if (this.discSalesVolArray[i].volume == volume && this.discSalesVolArray[i].discount == discount) {
        this.salVol = this.discSalesVolArray[i].volume;
        this.salVolDisc = this.discSalesVolArray[i].discount;
        this.discSalesVolArray.splice(i, 1);
      }
    }
  }
  deleteSalesVolume(volume, discount) {
    for (var i = 0; i < this.discSalesVolArray.length; i++) {
      if (this.discSalesVolArray[i].volume == volume && this.discSalesVolArray[i].discount == discount) {
        this.discSalesVolArray.splice(i, 1);
      }
    }
  }
  addTransVolume() {
    if (this.transVol != null && this.transVolDisc != null) {
      if (this.discTransVolArray.length != 0) {
        for (var i = 0; i < this.discTransVolArray.length; i++) {
          if (this.discTransVolArray[i].volume == this.transVol && this.discTransVolArray[i].discount == this.transVolDisc) {
            this.toastr.error(Message_data.discAlreadyExist);
            break;
          } else {
            this.discTransVolArray.push({
              volume: this.transVol,
              discount: this.transVolDisc,
            });
            break;
          }
        }
      } else {
        this.discTransVolArray.push({
          volume: this.transVol,
          discount: this.transVolDisc,
        });
      }
      this.transVol = null;
      this.transVolDisc = null;
    }
  }
  editTransVolume(volume, discount) {
    for (var i = 0; i < this.discTransVolArray.length; i++) {
      if (this.discTransVolArray[i].volume == volume && this.discTransVolArray[i].discount == discount) {
        this.transVol = this.discTransVolArray[i].volume;
        this.transVolDisc = this.discTransVolArray[i].discount;
        this.discTransVolArray.splice(i, 1);
      }
    }
  }
  deleteTransVolume(volume, discount) {
    for (var i = 0; i < this.discTransVolArray.length; i++) {
      if (this.discTransVolArray[i].volume == volume && this.discTransVolArray[i].discount == discount) {
        this.discTransVolArray.splice(i, 1);
      }
    }
  }
  addRegSalesVolume() {
    if (this.regsalVol != null && this.regsalVolDisc != null) {
      if (this.discRegsalesVol.length != 0) {
        for (var i = 0; i < this.discRegsalesVol.length; i++) {
          if (this.discRegsalesVol[i].volume == this.regsalVol && this.discRegsalesVol[i].discount == this.regsalVolDisc) {
            this.toastr.error(Message_data.discAlreadyExist);
            break;
          } else {
            this.discRegsalesVol.push({
              volume: this.regsalVol,
              discount: this.regsalVolDisc,
            });
            break;
          }
        }
      } else {
        this.discRegsalesVol.push({
          volume: this.regsalVol,
          discount: this.regsalVolDisc,
        });
      }
      this.regsalVol = null;
      this.regsalVolDisc = null;
    }
  }
  editRegSalesVolume(volume, discount) {
    for (var i = 0; i < this.discRegsalesVol.length; i++) {
      if (this.discRegsalesVol[i].volume == volume && this.discRegsalesVol[i].discount == discount) {
        this.regsalVol = this.discRegsalesVol[i].volume;
        this.regsalVolDisc = this.discRegsalesVol[i].discount;
        this.discRegsalesVol.splice(i, 1);
      }
    }
  }
  deleteRegSalesVolume(volume, discount) {
    for (var i = 0; i < this.discRegsalesVol.length; i++) {
      if (this.discRegsalesVol[i].volume == volume && this.discRegsalesVol[i].discount == discount) {
        this.discRegsalesVol.splice(i, 1);
      }
    }
  }
  sendDiscount() {
    if (this.discSalesVolArray.length != 0 || this.discTransVolArray.length != 0 || this.discRegsalesVol.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/sdis/", JSON.stringify({
        sales_vol: this.discSalesVolArray,
        trans_vol: this.discTransVolArray,
        regsal_vol: this.discRegsalesVol,
        pharma_id: this.pharmacyID,
      }), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          if (obj != undefined && obj.key == "1") {
            this.saveFlag = false;
            this.toastr.success(Message_data.dataSavedSuccess);
        //    this.messageservice.sendMessage("pharmacyhome");
          } else {
            this.saveFlag = true;
            this.toastr.error(Message_data.notSavedSuccess);
          }
        }, error => { this.saveFlag = true; });
    } else {
      this.saveFlag = true;
      this.toastr.error(Message_data.addDiscount);
    }
  }
  discountRetrieval() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpdis/",
      JSON.stringify({
        pharma_id: this.pharmacyID,
        imei: Helper_Class.getIPAddress()
      }), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          if (obj.sales_vol != null) {
            this.discSalesVolArray = obj.sales_vol;
          }
          if (obj.trans_vol != null) {
            this.discTransVolArray = obj.trans_vol;
          }
          if (obj.regsal_vol != null) {
            this.discRegsalesVol = obj.regsal_vol;
          }
        }
      )
  }
}
