import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Time_Formate } from '../../../assets/js/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Helper_Class} from '../../helper_class';
import { ConvertTimeformat, session_based_time_fun, Date_Formate } from '../../../assets/js/common';
import { PopupDoctorScheduleListComponent } from '../popup-doctor-schedule-list/popup-doctor-schedule-list.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-doctor-schedule-list-page',
  templateUrl: './doctor-schedule-list-page.component.html',
  styleUrls: ['./doctor-schedule-list-page.component.css'],
  
})
export class DoctorScheduleListPageComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public doctor_schdule_date:any = [];
  public ret_ftime;
  public ret_ttime;
  public userinfo:any;
  public user_id:any;

  constructor(public dialog: MatDialog,public  http:Http,public  router:Router, public  toastr:ToastrService) {
  }

  ngOnInit(): void {
    this.dtOptions = {
      pageLength: 25,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "columnDefs": [
        { "orderable": false, "targets": [1,2,3] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: ""
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.Get_retrivel_schedule();
  }

  backClicked() {
    this.router.navigateByUrl("/schedule")
  }

  openSchedulePopup(){
    const dialogRef = this.dialog.open(PopupDoctorScheduleListComponent, {
      width: '650px',
      height: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.Get_retrivel_schedule();
    });
  }

  //Get retrivel doctor schedule
  Get_retrivel_schedule() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/gdocavail/',
      JSON.stringify({
        doc_reg_id:this.userinfo.user_id,
        type: "doctor",
      }),
      { headers: headers })
      .subscribe(
        response => {
          
          this.doctor_schdule_date = [];
          var obj = response.json()

          if (obj.doc_avail != null) {
            for (var i = 0; i < obj.doc_avail.length; i++) {
              if (obj.doc_avail[i].from_time != null) {
                this.ret_ftime = Time_Formate(obj.doc_avail[i].from_time);
                this.ret_ttime = Time_Formate(obj.doc_avail[i].to_time);
                this.doctor_schdule_date.push({
                  from_date: obj.doc_avail[i].from_date,
                  to_date: obj.doc_avail[i].to_date,
                  from_date_d: Date_Formate(obj.doc_avail[i].from_date),
                  to_date_d: Date_Formate(obj.doc_avail[i].to_date),
                  session: obj.doc_avail[i].session,
                  from_time: obj.doc_avail[i].from_time,
                  from_time_data: this.ret_ftime + " to " + this.ret_ttime,
                  to_time: obj.doc_avail[i].to_time,
                  reason: obj.doc_avail[i].reason,
                  full_day: obj.doc_avail[i].full_day,
                  doc_reg_id: this.userinfo.user_id
                });

              } else {
                this.doctor_schdule_date.push({
                  Index: obj.doc_avail[i].availability_id,
                  from_date: obj.doc_avail[i].from_date,
                  to_date: obj.doc_avail[i].to_date,
                  from_date_d: Date_Formate(obj.doc_avail[i].from_date),
                  to_date_d: Date_Formate(obj.doc_avail[i].to_date),
                  session: obj.doc_avail[i].session,
                  reason: obj.doc_avail[i].reason,
                  full_day: obj.doc_avail[i].full_day,
                  doc_reg_id:this.userinfo.user_id
                });
              }
            }
          }
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        });
  }

  Doctor_schedule_delete(reasontxt, fromdate, todate) {
    for (var i = 0; i < this.doctor_schdule_date.length; i++) {
      if (this.doctor_schdule_date[i].reason == reasontxt && this.doctor_schdule_date[i].from_date == fromdate && this.doctor_schdule_date[i].to_date == todate){
        this.doctor_schdule_date.splice(i, 1);
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'usercontroller/updocavail/',
        JSON.stringify({
          doc_reg_id:this.userinfo.user_id,
          doc_avail: this.doctor_schdule_date
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json()
            if (obj["key"] != null) {
              if (obj["key"] == "1") {
                this.toastr.success(Message_data.dataUpdSuccess);
              }
            }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          });
      }
    }
  }
}
