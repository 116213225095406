<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white" style="border-bottom: 0 !important;">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Stock Returns</h5>
                        </div>
                        <div class="headerButtons">
                            <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" (click)="saveOrder()" />
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <p class="nodata" *ngIf="stockData.length == 0">No product(s) found</p>
                    <table *ngIf="stockData.length" mdbTable datatable [dtOptions]="dtOptions"
                        class="table table-nowrap table-sm dataTable">
                        <thead>
                            <tr>
                                <th style="width:16%;">Medicine</th>
                                <th style="width:16%;">Generic name</th>
                                <!-- <th style="width:10%;">UOM</th> -->
                                <th style="width:10%;">Supplier</th>
                                <th style="width:9%;">Batch</th>
                                <th style="width:10%;">Expiry date</th>
                                <th style="width:12%;">Return qty</th>
                                <th style="width: 3%;">
                                    <img *ngIf="masterSelected == false" (click)="checkUncheckAll('select')"  src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"  width="15px" />
                                    <img *ngIf="masterSelected == true" (click)="checkUncheckAll('unselect')" src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="15px" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let stock of stockData">
                                <td class="align_left">{{stock.medicine}}</td>
                                <td class="align_left">{{stock.genericName}}</td>
                                <!-- <td class="align_left">{{stock.uomDesc}}</td> -->
                                <td class="align_left">{{stock.mfgName}}</td>
                                <td class="align_left">{{stock.batchNo}}</td>
                                <td> {{stock.exp_date}} </td>
                                <td > 
                                    {{stock.qty_onhand}}
                                    <!-- <input type="text" class="ipcss" style="text-align:right;"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                                        required [(ngModel)]="stock.qty_onhand" (keyup)="quantity(stock)" #matInput /> -->
                                </td>
                                <td>
                                    <img *ngIf="stock.checked == false" (click)="isAllSelected(stock,'select')" src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                        width="15px" />
                                    <img *ngIf="stock.checked == true" (click)="isAllSelected(stock,'unselect')"  src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                        width="15px" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br><br><br>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>