import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../app/providers/common-data.service';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharma-inventory-upload',
  templateUrl: './pharma-inventory-upload.component.html',
  styleUrls: ['./pharma-inventory-upload.component.css']
})
export class PharmaInventoryUploadComponent implements OnInit {
  @ViewChild('excelreader') excelreader: ElementRef;
  public inventory = [];
  public locationArray: any;
  public inventoryColumnArray = [];
  public realignColumnsArray = [];
  public productArray = [];
  public columnArray = []
  public pharmacyID: string;
  public pharmaFacID: string;
  public pharColumn: string;
  public manufacturer: string;
  public mfgID: string;
  public invoiceNO: string;
  public pharmacyLocation: any;
  public poNO: string;
  public data_flag: boolean;
  public target;
  public userInfo;
  public userID;
  public pharmacistID;
  constructor(public messageservice: PharmacyService, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.pharmaFacID = Helper_Class.get_inventory_upload().pharma_fac_id;
    this.data_flag = true;
  }
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userID = this.userInfo.user_id;
    this.pharmacistID = this.userID;
    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.getPharmacy();
    this.productOrder();
    this.inventoryColumnArray.push(
      "Product_name",
      "Batch_no",
      "Package",
      "UOM",
      "Quantity",
      "Free_qty",
      "Rate",
      "MRP",
      "Tax",
      "Discount",
      "Mfg_date",
      "Received_date",
      "Expiry_date",
      "Amount",
    )
  }
  columnOrder(data) {
    if (!this.realignColumnsArray.includes(data)) {
      this.realignColumnsArray.push(data)
      this.data_flag = false;
    }
  }
  getPharmacy() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/",
      {
        pharmacist_id: this.pharmacistID,
        pharmacy_id: this.pharmacyID
      },
      { headers: headers }).subscribe(
        data => {
          var respdata = data.json();
          if (respdata.pharma_locations != null) {
            this.locationArray = respdata.pharma_locations;
          }
        },
        error => { });
  }
  productOrder() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpos/",
      {
        pharmacist_id: this.pharmacistID
      },
      { headers: headers }).subscribe(
        data => {
          if (data.json().purchase_orders != null) {
            this.productArray = data.json().purchase_orders;
          }
        },
        error => { });
  }
  public suppID;
  getManufacturer() {
    for (var i = 0; i < this.productArray.length; i++) {
      if (this.productArray[i].purchase_order_id == this.poNO) {
        this.manufacturer = this.productArray[i].supp_name;
        this.mfgID = this.productArray[i].mfg_id;
        this.suppID = this.productArray[i].supp_id;
      }
    }
  }
  readExcel(event): void {
    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      this.toastr.error(Message_data.selectOneFile);
    }
    var array = {};
    var array1 = []
    var colarray = [];
    var colarray1 = [];
    var rowcountvalue = this.realignColumnsArray;
    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then(function (data) {
      workbook.xlsx.load(data)
        .then(function () {
          const worksheet = workbook.getWorksheet('Sheet1');
          worksheet.eachRow(function (row, rowNumber) {
            if (rowNumber != 1) {
              colarray = [];
              for (var i = 0; i < rowcountvalue.length; i++) {
                var name = rowcountvalue[i];
                if (name == "Received_date") {
                  var date = new Date(row.getCell(i + 1).value)
                  var dateval = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
                  array[name] = dateval;
                  colarray.push(dateval)
                } else {
                  array[name] = row.getCell(i + 1).value;
                  colarray.push(row.getCell(i + 1).value)
                }
              }
              colarray1.push(colarray);
              array1.push(array);
              array = {};
            }
          });
        });
    });
    this.columnArray = colarray1;
    this.inventory = array1;
  }
  saveData() {
    if (this.inventory.length != 0) {
      var send_data = {
        invoice_no: this.invoiceNO,
        purchase_order_id: this.poNO,
        country: "IN",
        mfg_id: this.mfgID,
        supp_id: this.suppID,
        facility_id: this.pharmacyLocation,
        product_details: this.inventory,
        pharmacy_id: this.pharmacyID,
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/upprodex/", send_data, { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if (obj != undefined && obj.key == "1") {
            this.toastr.success(Message_data.saveSuccess);
            this.messageservice.sendMessage("pharmacyhome");
          } else {
            this.toastr.error(Message_data.unableToSave);
          }
        })
    } else {
      this.toastr.error(Message_data.addInventory)
    }
  }
  clear_data() {
    this.realignColumnsArray = [];
    this.inventory = [];
    this.columnArray = [];
    this.excelreader.nativeElement.value = "";
  }
  back_Nav() {
    this.messageservice.sendMessage("inventory_receivables");
  }
}
