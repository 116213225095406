import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-bill-edit',
  templateUrl: './bill-edit.component.html',
  styleUrls: ['./bill-edit.component.css']
})
export class BillEditComponent implements OnInit {
  public appoint_txt;
  public charge_type: string;
  public hptl_clinic_id: string;
  public nodataapp: boolean;
  public bill_palnche;
  public session: string;
  public addbtn_flag: boolean;
  public biill_type!: string | null;
  public amount_txt;
  public final_amount;
  public inGST: boolean;

  public concession_txt;
  public con_txt;
  public amount_aftcon;
  public table_div: boolean;
  public final_div: boolean;
  public gst_amount_txt;
  public Advance_bill;
  public CurrentDatetime;
  public Appoint_Date;
  public billing_createlsit: any = [];
  public Docidname;
  public Bill_appoint_id;
  public hospital_name;
  public billden_plan: any = [];
  public gst_retrive_list: any = [];
  public billaddlist: any = [];
  public pay_desc;
  public pay_id
  public gst_data_list: any = [];
  public chk_treat_arr = [];
  public charge_name;
  public estimate_id: any = [];
  public pay_type: string;
  public insurer_id: string;
  public insur_flag: boolean;

  public remaing_billbal;
  public addvancetxt;
  public totalcolletxt;
  public cent_gst: any;
  public sta_gst: any;
  public doc_client_reg_id;
  public doc_relation_id;
  public doc_sub_rel_id;
  public Charge_id;
  public header_footer_flag: boolean;
  public docid !: string | null;
  public bill_id: string
  public splname!: string | null;
  public msgflag: boolean = false;
  public profile_image: string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;
  public paytypelist: any = [];
  public insurerlist: any = [];
  public notifications;
  public notifyflag: boolean = false;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  //Get hospital detials
  public patient_name!: string | null;
  public prev_bill_id;
  public balance_show: boolean;
  public amount_collected: boolean;
  public previous_balshow: boolean;
  public balance_data;
  public total_collected;
  public doctor_profile_img!: string | null;
  public userinfo: any;
  public user_id: any;
  public hospital_details;
  public client_name;
  //mdbTable
  dtOptions: DataTables.Settings = {};;
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public tran_flag: boolean;
  public Transaction_id;
  public card_flag: boolean;
  public cardNumber;
  public cardHolderName;
  public cheque_flag: boolean;
  public bankName;
  public serviceType = [];
  public inpatientFlag: boolean = false;
  public quantity = 1;
  public show_upd_btn: boolean = true;
  public paytypreq: boolean;
  public paid_bill = "0.00";
  public finyear: string;
  public rowid: number = 0;
  public payType1:string;
  public amountval:number;
  public paymentsarray:any=[];
  
  constructor(public toastr: ToastrService, public http: Http, public router: Router, public messageservice: MenuViewService,
    public masterData: MasterHelperService) {
    this.insur_flag = true;
    this.tran_flag = true;
    this.card_flag = true;
    this.cheque_flag = true;
    this.nodataapp = true;
    this.insur_flag = true;
    this.pay_type = "Cash";
    this.final_amount = "0";
    this.gst_amount_txt = "0";
    this.Advance_bill = "0.00";
    this.concession_txt = 0;
    this.gst_retrive_list = [];
    this.billaddlist = [];
    this.estimate_id = [];
    this.previous_balshow = true;
    this.balance_show = true;
  }

  ngOnInit(): void {
    this.show_upd_btn = true;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      //"order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [1, 2, 3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    this.bill_id = Doc_Helper.getdoctorbillno();
    this.finyear = Doc_Helper.getreceptbilledit().fin_year;
    this.userinfo = Helper_Class.getInfo();
    if(this.userinfo.hospitals[0].bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }

    this.docid = this.userinfo.user_id;
    this.hospital_name = Helper_Class.getHospitalInfo().hosp_name;
    var hospitaldet = Helper_Class.getHospital();
    this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    this.inpatientFlag = Doc_Helper.getreceptbilledit().is_inaptient == "yes" ? true : false;
    this.appoint_txt = Doc_Helper.getreceptbilledit().doc_reg_id;
    this.hospital_details = Helper_Class.getHospitalInfo();
    this.doctor_profile_img = this.userinfo.profile_image;
    this.first_name = Helper_Class.getProfilename()
    this.hospital_name = this.hospital_details.hosp_name;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.hospital_details.hptl_logo;
    this.client_name = Doc_Helper.getClient_name();
    this.getPaymentType();
    this.patient_name = this.client_name;
    this.getInsurers();
  }

  consolidatedBilling() {
    var inpatientBill = {
      app_id: Doc_Helper.getreceptbilledit().app_id,
      flow: "inpatien-edit"
    }
    Helper_Class.setInpatientBillEdit(inpatientBill);
    Helper_Class.setInpatientBillCreate("doctor");
    this.messageservice.sendMessage("ipbillcreate");
  }

  getBillDetails() {
    var spl_name = "";
    this.rowid =0;
    var send_data;
    if (Helper_Class.getSpecializations() != undefined) {
      var spl = Helper_Class.getSpecializations();
      for (var i = 0; i < spl.length; i++) {
        if (spl[i].spl_name == "Dental") {
          spl_name = "Dental";
          send_data = {
            doc_reg_id: this.docid,
            billing_id: this.bill_id,
            spl_name: spl_name,
            app_type: "doctor",
            fin_year: this.finyear,
          }
        } else {
          send_data = {
            doc_reg_id: this.docid,
            billing_id: this.bill_id,
            app_type: "doctor",
            fin_year: this.finyear,
          }
        }
      }
    } else {
      send_data = {
        doc_reg_id: this.docid,
        billing_id: this.bill_id,
        app_type: "doctor",
        fin_year: this.finyear,
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.hospital_name = obj.hptl_name;
          var saldata = obj.salutation != undefined ? obj.salutation : "";

          if (obj.middle_name != null && obj.middle_name != undefined) {
            this.patient_name = saldata + " " + encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
          } else {
            this.patient_name = saldata + " " + encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
          }

          this.Advance_bill = obj.advance;
          this.final_amount = encrypt_decript.Decript(obj.bill_amount);
          if (obj.prev_balance != null) {
            this.previous_balshow = false;
            this.balance_data = parseFloat(obj.prev_balance).toFixed(2);
          } else {
            this.previous_balshow = true;
          }
          this.finyear= obj.fin_year;
          if (obj.bills != null) {
            for (var i = 0; i < obj.bills.length; i++) {
              var quantity = 1;
              if (obj.bills[i].quantity != undefined) {
                quantity = obj.bills[i].quantity;
              }
              this.rowid = obj.bills[i].index;
              this.billaddlist.push({
                sno:obj.bills[i].index,
                biill_type: obj.bills[i].charge_desc,
                amount_txt: parseFloat(obj.bills[i].amount_txt).toFixed(2),
                concession: parseFloat(obj.bills[i].concession).toFixed(2),
                fee: parseFloat(obj.bills[i].fee).toFixed(2),
                charge_type: obj.bills[i].charge_type,
                pay_type: obj.bills[i].pay_type,
                paytype: obj.bills[i].pay_id,
                status: "0",
                quantity: obj.bills[i].quantity,
                bill_details_id: obj.bills[i].bill_details_id,
                fin_year:obj.bills[i].fin_year,
              });
            }

            if (obj.cgst != undefined) {
              this.inGST = true;
              this.include_gst_click(this.inGST);
            }

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            }

            var tot_amt = "0";
            if (obj.bills != null && obj.bills.length != 0) {
              for (var i = 0; i < obj.bills.length; i++) {
                if (obj.bills[i].fee != null) {
                  tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                }
              }
            }

            if (obj.estimates != null) {
              if (obj.estimates.length != 0) {
                for (var i = 0; i < obj.estimates.length; i++) {
                  if (obj.estimates[i].estimate != null) {
                    tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                  }
                }
              }
            }
            this.paid_bill = obj.paid_amount != undefined ? obj.paid_amount : "0.00";

            var docname = "";
            if (obj.dr_middle_name != undefined)
              docname = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            else
              docname = obj.dr_first_name + " " + obj.dr_last_name;

            this.billing_createlsit = {
              client_reg_id: obj.client_reg_id,
              doc_app_id: obj.appointment_id,
              relation_id: obj.relation_id != undefined ? obj.relation_id : "1",
              sub_rel_id: obj.sub_rel_id != undefined ? obj.sub_rel_id : undefined,
              docid_name: docname,
              doc_reg_id: obj.doc_reg_id,
            }
          }
          if(obj.payments != undefined){
            this.paymentsarray = obj.payments;
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr)
        });
  }

  backClicked() {
    this.messageservice.sendMessage("billing");
  }

  //Bill add table
  Bill_add_click() {
    if (this.biill_type == undefined) {
      this.toastr.error(Message_data.sltServiceType);
    } else if (this.amount_txt == undefined || this.amount_txt == '') {
      this.toastr.error(Message_data.enterAmt);
    } else if (this.quantity == undefined) {
      this.toastr.error(Message_data.enterQty);
    } else {
      if (this.final_amount == "0") {
        this.inGST == false;
      }
      this.table_div = true;
      this.final_div = true;
      var flag = true;

      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].status != '2') {
            if (this.billaddlist[i].biill_type == this.biill_type && this.billaddlist[i].paytype == this.pay_type) {
              flag = false;
              break;
            }
          }
        }
      }

      if (flag == true) {
        if (this.charge_name.length != 0) {
          for (var i = 0; i < this.charge_name.length; i++) {
            if (this.biill_type == this.charge_name[i].description) {
              this.Charge_id = this.charge_name[i].charge_id;
            }
          }
        }
        var paydesc;
        if (this.paytypelist.length != 0) {
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.pay_type == this.paytypelist[i].pay_id) {
              paydesc = this.paytypelist[i].pay_desc;
            }
          }
        }

        this.con_txt = this.concession_txt == "" ? 0 : this.concession_txt;
        this.rowid= parseInt(this.rowid.toString())+1;
        this.billaddlist.push({
          sno:this.rowid,
          biill_type: this.biill_type,
          amount_txt: parseFloat(this.amount_txt).toFixed(2),
          concession: parseFloat(this.con_txt).toFixed(2),
          fee: parseFloat(this.amount_aftcon).toFixed(2),
          insurer: this.insurer_id,
          charge_type: this.Charge_id,
          pay_type: paydesc,
          paytype: this.pay_type,
          quantity: this.quantity,
          status: "1",
          fin_year:this.finyear,
        });

        this.biill_type = undefined;
        this.amount_txt = '';
        this.concession_txt = 0;
        this.amount_aftcon = '';
        this.quantity = 1;
      }

      if (this.final_amount == "0") {
        this.inGST == false;
      }
      this.Calculate_gst("0", "0");
    }
  }

  //Delete bill
  Delete_bill(type, amount, bill) {
    if (this.billaddlist.length != 0) {
      this.rowid=0;
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].status != '2') {
          if (this.billaddlist[i].bill_details_id == bill.bill_details_id) {
            if(this.billaddlist[i].bill_details_id != undefined){
              bill.status = "2";
                this.Calculate_gst("0", "0");
                break;

            } else {
              this.billaddlist.splice(i, 1);
            }
          }
        }
      }

      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].status != '2') {
          this.rowid +=1;
          this.billaddlist[i].sno=this.rowid;
        }
      }
    }
    
    if (this.billaddlist.length == 0) {
      this.table_div = false;
      this.final_div = false;
    } else {
      this.table_div = true;
    }

    if (this.final_amount == "0") {
      this.inGST == false;
    }
    this.Calculate_gst("0", "0");
  }

  //Get bill type
  Get_Bill_Type() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype', JSON.stringify({
      hptl_clinic_id: this.hptl_clinic_id,
      provider: "doctor",
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          this.charge_name = obj.charges;
          //this.biill_type = this.charge_name[0].description;
          //this.charge_change(this.biill_type);
          this.getBillDetails();
        },
        error => { });
  }

  //Get current date
  Get_current_date() {
    var obj = Master_Helper.getMasterCurrentDate();

    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      var get_date = obj.current_date.split('-');
      this.Appoint_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
    }
    this.Get_Gst_Data();
  }

  //Get payment type
  async getPaymentType() {
    if (Master_Helper.getMasterPaymentType() == undefined) {
      await this.masterData.getPaymentType();
    }
    var obj = Master_Helper.getMasterPaymentType();
    if (obj != undefined) {
      this.paytypelist = obj.payments;
      this.pay_type = obj.payments[0].pay_id;
    }
    this.Get_current_date();
  }

  pay_change(type) {
    this.insur_flag = true;
    this.tran_flag = true;
    this.card_flag = true;
    this.cheque_flag = true;
    if (this.pay_type == "3") {
      this.insur_flag = false;
    } else if (this.pay_type == "4") {
      this.tran_flag = false;
    } else if (this.pay_type == "2") {
      this.tran_flag = false;
      this.card_flag = false;
    } else if (this.pay_type == "5") {
      this.cheque_flag = false;
    } else {
      this.insur_flag = true;
    }
  }

  //Get payment type
  async getInsurers() {
    if (Master_Helper.getMasterInsurers() == undefined) {
      await this.masterData.getInsurers()
    }
    var obj = Master_Helper.getMasterInsurers();
    if (obj != undefined)
      this.insurerlist = obj.insurers;
  }

  charge_change(charge) {
    this.biill_type = charge;
    this.serviceType = [];
    for (var i = 0; i < this.charge_name.length; i++) {
      if (this.charge_name[i].description == charge) {
        this.amount_txt = this.charge_name[i].amount;
        this.amount_aftcon = this.charge_name[i].amount;
      }
    }
    this.addbtn_flag = false;
  }

  Appointmnetid_change() {
    this.billden_plan = [];
    if (this.billing_createlsit.length != 0) {
      if (this.billing_createlsit.doc_reg_id == this.appoint_txt) {
        this.Bill_appoint_id = this.billing_createlsit.doc_app_id;
      }
    }

    this.Get_Bill_Type();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getappdet',
      {
        appointment_id: this.Bill_appoint_id,
        doc_reg_id: this.docid
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.hospital_name = obj.hosp_name;
          if (obj.phases != null) {
            for (var i = 0; i < obj.phases.length; i++) {
              this.billden_plan.push({
                treatment_desc: obj.phases[i].treatment_desc,
                estimate: obj.phases[i].estimate,
                den_treatment_plan_id: obj.phases[i].den_treatment_plan_id,
              });
            }
          }

          if (obj.advance != null) {
            this.Advance_bill = obj.advance.toFixed();
          }

          if (obj.prev_bill_id != null) {
            this.prev_bill_id = obj.prev_bill_id;
          }

          if (obj.balance != "0") {
            this.amount_collected = true;
            this.balance_show = false;
            this.previous_balshow = false;
            this.balance_data = parseFloat(obj.balance).toFixed();
            this.final_amount = parseFloat(obj.balance).toFixed();
            this.total_collected = parseFloat(obj.balance).toFixed();
          }
        },
        error => { }
      )
  }

  // concession calculation
  amount_change() {
    var perce !: number | null;
    perce = this.concession_txt / 100;
    var amt = this.amount_txt;
    if (this.quantity > 1) {
      amt = amt * this.quantity;
    }

    var amt_cons = this.amount_aftcon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amount_aftcon = Math.round(tot);
  }

  //Get gst
  async Get_Gst_Data() {
    if (Master_Helper.getMasterGSTData() == undefined) {
      await this.masterData.getGstData();
    }
    var obj = Master_Helper.getMasterGSTData();
    if (obj != undefined)
      this.gst_retrive_list = obj.taxes;
    this.Get_Bill_Type();
  }

  // Include GST
  include_gst_click(check) {
    this.Calculate_gst("0", "0");
  }

  Calculate_gst(palnid, planmodal) {
    this.final_amount = "0";
    this.gst_amount_txt = "0";
    this.total_collected = "0";
    this.gst_data_list = [];

    if (this.billden_plan.length != 0) {
      for (var i = 0; i < this.billden_plan.length; i++) {
        for (var j = 0; j < this.estimate_id.length; j++) {
          if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id) {
            this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.billden_plan[i].estimate)).toFixed(2);
            this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.billden_plan[i].estimate)).toFixed(2);

            if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.total_collected = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
              this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
            }

            if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.remaing_billbal = (parseFloat(this.Advance_bill) - parseFloat(this.final_amount)).toFixed(2);
            }

            this.amount_collected = true;

            if (this.total_collected == "0") {
              this.amount_collected = false;
            }

            if (this.remaing_billbal == "0") {
              this.balance_show = true;
            }
            break;
          }
        }
      }
    }

    for (var i = 0; i < this.billaddlist.length; i++) {
      if (this.billaddlist[i].status != '2') {
        this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.billaddlist[i].fee)).toFixed(2);
        this.gst_amount_txt = (parseFloat(this.gst_amount_txt) + parseFloat(this.billaddlist[i].fee)).toFixed(2);

        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (parseFloat(this.Advance_bill) - parseFloat(this.final_amount)).toFixed(2);
          this.total_collected = "0";
        }

        if (this.total_collected == "0") {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == "0") {
          this.balance_show = true;
        }
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.gst_data_list.push({
          Gst_Descrip: this.gst_retrive_list[i].tax_desc + " " + this.gst_retrive_list[i].tax + "%",
          Gst_amount: (parseFloat(this.gst_amount_txt) * (parseFloat(this.gst_retrive_list[i].tax) / 100)).toFixed(2)
        });
        this.final_amount = (parseFloat(this.final_amount) + (parseFloat(this.gst_amount_txt) * (parseFloat(this.gst_retrive_list[i].tax) / 100)));
      }
      this.final_amount = parseFloat(this.final_amount).toFixed(2);
    }

    if (this.balance_data != undefined) {
      this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.balance_data)).toFixed(2);
    }

    //amount to be collected
    this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);

    if (this.estimate_id.length == 0 && this.billaddlist.length == 0) {
      this.inGST = false;
      this.gst_data_list = [];
      this.Advance_bill = "0"
      this.final_amount = "0";
      this.gst_amount_txt = 0;
      this.total_collected = "0";
      this.remaing_billbal = "0";
    }

    if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
      this.remaing_billbal = "0";
    }
  }

  //add the bill details
  advance_change() {
    if (this.Advance_bill != undefined && this.Advance_bill != "") {
      this.remaing_billbal = (parseFloat(this.final_amount) - parseFloat(this.Advance_bill)).toFixed(2);
    } else {
      this.remaing_billbal = this.final_amount;
    }

    if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
      this.remaing_billbal = "0";
    }
  }

  //Bill click
  bill_click(palnid, planmodal, index) {
    if (planmodal == true) {// Check or Uncheck
      for (var i = 0; i < this.billden_plan.length; i++) {
        if (this.billden_plan[i].den_treatment_plan_id == palnid) {
          this.estimate_id.push(palnid);
          break;
        }
      }

    } else {
      for (var i = 0; i < this.billden_plan.length; i++) {
        for (var j = 0; j < this.estimate_id.length; j++) {
          if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id && index == i) {
            this.estimate_id.splice(j, 1);
            break;
          }
        }
      }
    }
    this.Calculate_gst("0", "0");
  }

  //Bill save
  Bill_update() {
    var flag = true;

    if (this.billaddlist.length == 0 && this.estimate_id.length == 0) {
      this.toastr.error(Message_data.addbill);
      flag = false;
    }

    if (flag == true) {
      this.show_upd_btn = false;
      if (this.billing_createlsit.length != 0) {
        if (this.appoint_txt == this.billing_createlsit.doc_reg_id) {
          this.doc_client_reg_id = this.billing_createlsit.client_reg_id;
          this.doc_relation_id = this.billing_createlsit.relation_id;
          if (this.billing_createlsit.sub_rel_id != undefined) {
            this.doc_sub_rel_id = this.billing_createlsit.sub_rel_id;
          }
          this.Bill_appoint_id = this.billing_createlsit.doc_app_id;
        }
      }

      this.addvancetxt = this.Advance_bill != undefined ? this.Advance_bill : "0";
      this.totalcolletxt = this.total_collected != undefined ? this.total_collected : "0";

      if (this.inGST == true && this.gst_retrive_list.length != 0) {
        this.cent_gst = this.gst_retrive_list[0].tax;
        this.sta_gst = this.gst_retrive_list[1].tax;
      }

      var paid_flag;
      if (this.final_amount == this.remaing_billbal) {
        paid_flag = "Un paid";
      } else if (this.final_amount != this.remaing_billbal && (this.remaing_billbal != "0.00")) {
        paid_flag = "Partially paid";
      } else if (this.remaing_billbal == "0.00" || this.remaing_billbal == "0" || this.remaing_billbal == 0.00 || this.remaing_billbal == 0) {
        paid_flag = "Fully paid";
      }

      localStorage.setItem("sent_bill", this.final_amount);
      localStorage.setItem("billid", this.bill_id);
      var send_data = null;
      send_data = {
        appointment_id: (this.Bill_appoint_id).toString(),
        bill_id: this.bill_id,
        app_type: "doctor",
        doc_reg_id: this.docid,
        hptl_clinic_id: this.hptl_clinic_id,
        bill_amount: encrypt_decript.Encript(localStorage.getItem("sent_bill")).toString(),
        paid_amount: this.paid_bill,
        paid_flag: paid_flag,
        created_by: this.docid,
        bills: this.billaddlist,
        country: ipaddress.country_code,
        estimates: this.estimate_id,
        client_reg_id: this.doc_client_reg_id,
        relation_id: this.doc_relation_id,
        advance: this.addvancetxt,
        balance: this.remaing_billbal,
        cgst: this.cent_gst,
        sgst: this.sta_gst,
        prev_bal: this.balance_data,
        prev_bill_id: this.prev_bill_id,
        updater_type: "doctor",
        card_no: this.cardNumber,
        card_holder_name: this.cardHolderName,
        transaction_no: this.Transaction_id,
        bank_name: this.bankName,
        payments:this.paymentsarray,
        fin_year:this.finyear,
      }
      if (this.doc_sub_rel_id != null && this.doc_sub_rel_id != undefined && this.doc_sub_rel_id != "" && this.doc_sub_rel_id.length != 0)
        send_data.sub_rel_id = this.doc_sub_rel_id;

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/ubill/',
        send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj["result"] != null) {
              if (obj["result"] == "Bill updated successful") {
                this.show_upd_btn = false;
                this.toastr.success(Message_data.billUpdSuccess);
                this.messageservice.sendMessage('billing');
              } else {
                this.toastr.error(Message_data.unableToUpdBill);;
              }
            } else {
              this.show_upd_btn = true;
            }
          },
          error => {
            this.show_upd_btn = true;
          }
        )
    } else {
      this.show_upd_btn = true;
    }
  }

  getServiceType(serviceType) {
    this.serviceType = [];
    if (serviceType.length >= 3) {
      if (this.charge_name.length != 0) {
        if (this.charge_name.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()))) {
          var chg = this.charge_name.filter(o => o.description.toLowerCase().startsWith(serviceType.toLowerCase()));
          for (var i = 0; i < chg.length; i++) {
            this.serviceType.push(chg[i].description);
          }
        } else {
          this.serviceType = [];
        }
      }
    } else { }
  }

  paid_change() {
    this.paid_bill = this.paid_bill == "" ? "0.00" : this.paid_bill;
    this.remaing_billbal = ((parseFloat(this.final_amount)) - (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))).toFixed(2);
  }

  addPaymentDetails(){
    for(var i=0;i<this.paytypelist.length;i++){
      if(this.payType1 == this.paytypelist[i].pay_id){
        this.paymentsarray.push({
          pay_id:this.paytypelist[i].pay_id,
          pay_desc:this.paytypelist[i].pay_desc,
          amount:this.amountval.toFixed(2)
        })
        this.amountval=0;
      }
    }
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=data.amount;
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
