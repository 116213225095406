import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers, JSONPBackend } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-pharma-rackbin',
  templateUrl: './pharma-rackbin.component.html',
  styleUrls: ['./pharma-rackbin.component.scss']
})
export class PharmaRackbinComponent implements OnInit {
  public racks: string;
  public bins: string;

  public rackid:string="0";
  public binid: string="0";
  
  public saveFlag: boolean = true;
  public userInfo;
  public pharmacistID;
  public pharmacyID;

  public racksArray:any=[];
  public binsArray:any=[];
  public rackBinsArray:any=[];

  constructor(public messageservice: PharmacyService, public gservice: CommonDataService, 
    public http: Http, public routes: ActivatedRoute, public router: Router, 
    public toastr: ToastrService) { 


  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.getRackBinDetails();
  }

  getRackBinDetails(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/grbd/", JSON.stringify({
        pharma_id: this.pharmacyID,
      }), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          if(obj.racks != undefined && obj.racks.length !=0){
            this.racksArray = obj.racks;
          }

          if(obj.bins != undefined && obj.bins.length !=0){
            this.binsArray = obj.bins;
          }
        });
  }

  saveData(){
    if (this.racksArray.length != 0 || this.binsArray.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/srbd/", JSON.stringify({
        racks: this.racksArray,
        bins: this.binsArray,
        pharma_id: this.pharmacyID,
      }), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          if (obj != undefined && obj.key == "1") {
            this.saveFlag = false;
            this.toastr.success(Message_data.dataSavedSuccess);

          } else {
            this.saveFlag = true;
            this.toastr.error(Message_data.notSavedSuccess);
          }
        }, error => { this.saveFlag = true; });

    } else {
      this.saveFlag = true;
      this.toastr.error(Message_data.addDiscount);
    }
  }

  addRacks(){
    if (this.racks != undefined) {
      if (this.racksArray.length != 0) {
        var existflag=false;
        for (var i = 0; i < this.racksArray.length; i++) {
          if (this.racksArray[i].rackname == this.racks) {
            this.toastr.error("Rack already exists");
            break;
          } else {
            this.racksArray.push({
              rackid:this.rackid,
              rackname: this.racks,
              status:"1",
            });
            break;
          }
        }
      } else {
        this.racksArray.push({
          rackid:this.rackid,
          rackname: this.racks,
          status:"1",
        });
      }
      this.racks = null;
    }
  }

  editRackDetails(rackname){
    for (var i = 0; i < this.racksArray.length; i++) {
      if (this.racksArray[i].rackname == rackname) {
        this.racks = this.racksArray[i].rackname;
        this.rackid=this.racksArray[i].rackid;
        this.racksArray.splice(i, 1);
      }
    }
  }

  deleteRackDetails(rackname){
    for (var i = 0; i < this.racksArray.length; i++) {
      if (this.racksArray[i].rackname == rackname) {
        this.racksArray[i].status="0";
      }
    }
  }

  addBins(){
    if (this.bins != undefined) {
      if (this.binsArray.length != 0) {
        
        for (var i = 0; i < this.binsArray.length; i++) {
          if (this.binsArray[i].binname == this.bins) {
            this.toastr.error("Bin already exists");
            break;

          } else {
            this.binsArray.push({
              binid:this.binid,
              binname: this.bins,
              status:"1",
            });
            break;
          }
        }
      } else {
        this.binsArray.push({
          binid:this.binid,
          binname: this.bins,
          status:"1",
        });
      }
      this.bins = null;
    }
  }

  editBins(binname){
    for (var i = 0; i < this.binsArray.length; i++) {
      if (this.binsArray[i].binname == binname) {
        this.binsArray[i].status="0";
      }
    }
  }

  deleteBins(binname){
    for (var i = 0; i < this.binsArray.length; i++) {
      if (this.binsArray[i].binname == binname) {
        this.bins = this.binsArray[i].binname;
        this.binsArray.splice(i, 1);
      }
    }
  }
}
