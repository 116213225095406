import { Component,ViewChild, OnInit,HostListener,ViewEncapsulation,ElementRef,OnDestroy, Injector } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-second-opinion',
  templateUrl: './second-opinion.component.html',
  styleUrls: ['./second-opinion.component.css']
})
export class SecondOpinionComponent implements OnInit {
  //mdbTable
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist:any;
  checkedList:any

  public userinfo:any;
  public user_id:any;
  public first_name:string;
  public last_name:string;
  public submenu_flag:boolean =false;
  public submenu_flag1:boolean =false;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public C_age;
  public C_gender;
  public C_image;
  public profile_image:string;
  public messages;
  public msgflag:boolean=false;
  public notifications;
  public notifyflag:boolean=false;
  public cardio_name;

  //pending
  public secon_op_newlist = [];
  public secon_op_newlist1 = [];

  public nodapp: boolean;
  public nodapp1: boolean;

  public header_footer_flag: boolean;
  public loader: boolean;
  public textalign;
  public width;
  public height;
  public doctor_profile_img:string;
  public CurrentDatetime;

  constructor(private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public messageservice:MenuViewService) { 
    this.getCurrentDate();
  }

  ngOnInit(): void {
    this.dtOptions = {
      //pagingType: 'full_numbers',
      pageLength: 25,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.userinfo= Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    
    this.get_app_pending_list(this.CurrentDatetime);
    this.get_app_compl_list(this.CurrentDatetime);   
  }

  getCurrentDate(){
    var obj = Master_Helper.getMasterCurrentDate();
    
    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      Helper_Class.setKey(obj.provider+obj.clientid+obj.messageid);
      Helper_Class.setIV(obj.random);
    }
  }

  get_app_pending_list(date) {
    var sen_data = JSON.stringify({
      country: ipaddress.country_code.toString(),
      type: "doctor",
      reg_id: this.user_id,
      date: date,
      flag: "pending",
      imei: Helper_Class.getIPAddress(),
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'secopn/opnlist/',sen_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj.opinions != null) {
              this.loader = true;
              var status_txt = null;
              for (var i = 0; i < obj.opinions.length; i++) {
                var gender,location;
                if (obj.opinions[i].is_cancelled != null) {
                  status_txt = "Cancelled";
                } else {
                  if (obj.opinions[i].status == "1") {
                    status_txt = "Waiting for review";
                  } else {
                    status_txt = "Review completed";
                  }

                  if (obj.opinions[i].from_time != null) {
                    status_txt = "Time alloted";
                  }
                }

                if (obj.opinions[i].mem_middle_name != null) {
                  var m_name = encrypt_decript.Decript(obj.opinions[i].mem_middle_name).toString()
                } else {
                  m_name = "";
                }

                if (obj.opinions[i].mem_first_name != null) {
                  this.first_name= encrypt_decript.Decript(obj.opinions[i].mem_first_name).toString() + " " + m_name + " " + encrypt_decript.Decript(obj.opinions[i].mem_last_name).toString();
                }

                if (obj.opinions[i].gender != null) {
                  gender= encrypt_decript.Decript(obj.opinions[i].gender).toString();
                }

                if (obj.opinions[i].location != null) {
                  location= encrypt_decript.Decript(obj.opinions[i].location).toString();
                }

                var get_date = obj.opinions[i].created_date.split('-');
                this.secon_op_newlist.push({
                  opin_id: obj.opinions[i].opinion_id,
                  mem_name: encrypt_decript.Decript(obj.opinions[i].mem_first_name).toString() + " " + m_name + " " + encrypt_decript.Decript(obj.opinions[i].mem_last_name).toString(),
                  date_second: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
                  name:encrypt_decript.Decript(obj.opinions[i].mem_first_name).toString() + " " + m_name + " " + encrypt_decript.Decript(obj.opinions[i].mem_last_name).toString(),
                  status: status_txt,
                  age:obj.opinions[i].age,
                  gender:gender,
                  location:location,
                });
              }
              this.nodapp = this.secon_op_newlist.length == 0 ? true : false;
          } else {
              this.loader = true;
              this.toastr.error(Message_data.defaultErr);
            }
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        }
      )
  } 

  view_click(opn_id,name,age,gender) {
    Doc_Helper.setOpnid(opn_id);
    Doc_Helper.setClient_name(name);
    Doc_Helper.setClient_age(age);
    Doc_Helper.setClient_gender(gender);

    this.GetDetails();
  }

  GetDetails(){
    var sen_data =  JSON.stringify({
      country: ipaddress.country_code.toString(),
      opinion_id: Doc_Helper.getOpnid(),
      doc_reg_id: this.user_id,
    });
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'secopn/opnbyid/', sen_data,
      { headers: headers })
      .subscribe(
        response => {
          
          var obj = response.json();
          if(obj != null){
            Helper_Class.setOpnRet(obj);
          }
          this.messageservice.sendMessage("secondopinion_casesheet");
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  get_app_compl_list(date) {
    var sen_comp_data =  JSON.stringify({
      country: ipaddress.country_code.toString(),
      type: "doctor",
      reg_id: this.user_id,
      date: date,
      flag: "completed",
      imei: Helper_Class.getIPAddress(),
    })
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'secopn/opnlist/', sen_comp_data ,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj.opinions != null) {
              var status_txt = null;
              for (var i = 0; i < obj.opinions.length; i++) {
                if (obj.opinions[i].is_cancelled != null) {
                  status_txt = "Cancelled";
                } else {
                  if (obj.opinions[i].status == "1") {
                    status_txt = "Waiting for review";
                  } else {
                    status_txt = "Review completed";
                  }

                  if (obj.opinions[i].from_time != null) {
                    status_txt = "Time alloted";
                 }
                }

                if (obj.opinions[i].mem_middle_name != null) {
                  var m_name = encrypt_decript.Decript(obj.opinions[i].mem_middle_name).toString()
                }
                else {
                  m_name = "";
                }
                var get_date = obj.opinions[i].created_date.split('-');
                this.secon_op_newlist1.push({
                  opin_id: obj.opinions[i].opinion_id,
                  mem_name: encrypt_decript.Decript(obj.opinions[i].mem_first_name).toString() + " " + m_name + " " + encrypt_decript.Decript(obj.opinions[i].mem_last_name).toString(),
                  date_second: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
                  name:encrypt_decript.Decript(obj.opinions[i].mem_first_name).toString() + " " + m_name + " " + encrypt_decript.Decript(obj.opinions[i].mem_last_name).toString(),
                  status: status_txt,
                  age:obj.opinions[i].age,
                  gender:encrypt_decript.Decript(obj.opinions[i].gender).toString()
                });
              }
              this.nodapp1 = this.secon_op_newlist1.length == 0 ? false : true;
            }
            else{
              this.loader=true;
              this.toastr.error(Message_data.defaultErr);
            }
        },
        error => {
          this.loader=true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }
}
