<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill List</h5>
          </div>
          <div class="headerButtons">
            <!-- *ngIf="printFlag" -->
            <img  class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="navBillCreate()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body> 
        <div class="row">
          <div class="col-12">
            <div class="switch-field" *ngIf="ynopt1 != undefined">
              <input type="radio" id="radio-three" (click)="change_type(1,'yes')" />
              <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                <div class="tab_cover">
                  <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Outpatient.svg" class="iocinStyle"
                      alt=""></div>
                  <div class="lableStyle">Out-patient</div>
                </div>
              </label>
              <input type="radio" id="radio-four" (click)="change_type(1,'no')" />
              <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                <div class="tab_cover">
                  <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Inpatient.svg" class="iocinStyle"
                      alt=""></div>
                  <div class="lableStyle">In-patient</div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 select_bottom" *ngIf="DiagLocationArray.length > 1">
            <mat-label class="matlabel">Diagnosis center<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="location">
                <option *ngFor="let loc of DiagLocationArray" value={{loc.view_data}}>{{loc.view_data}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
            <mat-label class="matlabel">From<br>
              <input type="date" class="ipcss_date widthbilllist datecss" (change)="OnDateChangedfrom(fromDate)"
                [(ngModel)]="fromDate" #matInput max="{{currentDate}}">
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
            <mat-label class="matlabel">To<br>
              <input type="date" class="ipcss_date" id="appt_date" (change)="OnDateChangedto(toDate)"
                [(ngModel)]="toDate" max="{{currentDate}}" matInput>
            </mat-label>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <img src="../../../assets/ui_icons/buttons/search_button.svg" style="margin-top: 21px;"
              class="saveimgbtn_inpatinfo" (click)="filterClick()" />
          </div>
          <div class="col-12" *ngIf="billListArray.length == 0">
            <p class="nodata">No bill(s) Found</p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="billListArray.length !=0"
            style="margin-top: 10px;">
            <table *ngIf="billListArray.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable billlisttable">
              <thead>
                <tr>
                  <th class="delete">Bill no</th>
                  <th>Date</th>
                  <th>Client name</th>
                  <th>Amount</th>
                  <th *ngIf="billEditFlag"></th>
                  <!-- <th class="delete">&nbsp;</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of billListArray; let i = index" (click)="viewBills(person.bill_id,person.fin_year)">
                  <td style="font-size: 12px;text-align: center;">{{person.bill_id}}
                  </td>
                  <td style="font-size: 12px;text-align: center;">{{person.date}}</td>
                  <td style="font-size: 12px;text-align: left !important;">
                    {{person.client_name}}</td>
                  <td style="font-size: 12px;text-align: right;">{{person.amount}}</td>
                  <td *ngIf="billEditFlag" style="font-size: 12px;text-align: center;" (click)="editBill(person.bill_id, person.doc_app_id,person.fin_year,person)"><img src="../../../assets/img/edit.png" class="billing_edit" alt=""></td>
                </tr>
              </tbody>
            </table>
            <br><br><br>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>