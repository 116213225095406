import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Observable, observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DietpopupwindowComponent } from '../dietpopupwindow/dietpopupwindow.component';
import { DietplanEditComponent } from '../dietplan-edit/dietplan-edit.component';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DietplanhistorypopupComponent } from '../dietplanhistorypopup/dietplanhistorypopup.component';
import { HabitualService } from '../service/habitualservice';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { dietDisease } from '../service/dite-disease.service';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-card-dietplan',
  templateUrl: './card-dietplan.component.html',
  styleUrls: ['./card-dietplan.component.css']
})
export class CardDietplanComponent implements OnInit {
  public dietPlanFlag = "min";
  public foot_cate = [];
  public mor_foot_type = [];
  public mor_subfoot_type = [];
  public cal_txt: string;
  public tot_cal: string;

  subscription: Subscription;
  public Calc_Number: number;
  public Calc_Quantity: number;
  public Calc_Weight: number;
  public Calc_Energy: number;
  public Calc_Measure: number;
  public diet_mrng_arry = [];

  //morn non-menu
  public mor_catxt: string;
  public mor_tpetxt: string;
  public mor_food_subtxt: string;
  public mor_measure: any;
  public mor_weight;
  public mor_enegry;
  public mor_food_qty: string;

  //breakfast  non-menu
  public break_catxt: string;
  public break_tpetxt: string;
  public break_subtxt: string = "";
  public Break_Quantity: any;
  public Break_Measure: any;
  public Break_Weight;
  public Break_Energy;
  public breakfast_table: boolean;
  public Sga_overalltxt: string;
  public break_foot_type = [];
  public break_subfoot_type = [];
  public dietbreakfastadd = [];

  // midday  non-menu 
  public midday_catxt: string;
  public midday_tpetxt: string;
  public midday_subtxt: string = "";
  public midday_Quantity: string;
  public midday_Measure;
  public midday_Weight;
  public midday_Energy;
  public midday_table: boolean;
  public midday_foot_type = [];
  public midday_subfoot_type = [];
  public dietmiddayadd = [];

  // lunch  non-menu
  public lunch_catxt: string;
  public lunch_tpetxt: string;
  public lunch_subtxt: string = "";
  public lunch_Quantity: string;
  public lunch_Measure;
  public lunch_Weight;
  public lunch_Energy;
  public lunch_table: boolean;
  public lunch_foot_type = [];
  public lunch_subfoot_type = [];
  public dietlunchadd = [];
  public lunch_Weight_Energy;

  // eve  non-menu
  public eve_catxt: string;
  public eve_tpetxt: string;
  public eve_subtxt: string = "";
  public eve_Quantity: any;
  public eve_Measure: any;
  public eve_Weight;
  public eve_Energy;
  public eve_table: boolean;
  public eve_foot_type = [];
  public eve_subfoot_type = [];
  public dieteveadd = [];

  // dinner  non-menu
  public dinner_catxt: string;
  public dinner_tpetxt: string;
  public dinner_subtxt: string = "";
  public dinner_Quantity: any;
  public dinner_Measure: any;
  public dinner_Weight;
  public dinner_Energy;
  public dinner_table: boolean;
  public dinner_foot_type = [];
  public dinner_subfoot_type = [];
  public dietdinneradd = [];

  // night  non-menu
  public night_catxt: string;
  public night_tpetxt: string;
  public night_subtxt: string = "";
  public night_Quantity: string;
  public night_Measure;
  public night_Weight;
  public night_Energy;
  public night_table: boolean;
  public night_foot_type = [];
  public night_subfoot_type = [];
  public dietnightadd = [];

  public dietmoradd: any = [];
  public mor_food_cond: string = "";
  public breakfast_cond: string = "";
  public midday_cond: string = "";
  public lunch_cond: string = "";
  public eve_cond: string = "";
  public dinner_cond: string = "";
  public night_cond: string = "";
  public morning_index: number;
  public col_dtaa;

  public morning_table: boolean;
  public break_table: boolean;

  //textarea
  public dietmorning: string;
  public dietbreakfast: string;
  public dietmidday: string;
  public dietlunch: string;
  public dietevening: string;
  public dietdinner: string;
  public dietnight: string;

  public userinfo: any;
  public user_id: any;
  public rel_id = "1";
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public client_reg_id;
  public relation_id;
  public sub_rel_id;
  public patient_list;
  public opd;
  public client:boolean = false;

  //Diet plan retrieval
  public dplan_mor_flag;
  public diet_plan_all = "0";
  public data_bmr;
  public exercise_routine;

  //morn menu
  public mor_show_menu:boolean = false;
  public mor_show_non_menu:boolean = true;
  public morning_option;
  public menu_mor_foodType = [];
  public menu_mor_tpetxt;
  public menu_mor_subfoodType = [];
  public mor_energy = undefined;
  public mor_protein = undefined;
  public mor_fat = undefined;
  public mor_vitA = undefined;
  public mor_fiber = undefined;
  public mor_carbohydrates = undefined;
  public mor_food_image;
  public menu_mor_cond;
  public menu_morn_weight;
  public morn_class;

  //bf  menu
  public bf_show_menu:boolean = false;
  public bf_show_non_menu:boolean = true;
  public breakfast_option;
  public menu_bf_foodType = [];
  public menu_bf_tpetxt;
  public menu_bf_subfoodType = [];
  public bf_energy = undefined;
  public bf_protein = undefined;
  public bf_fat = undefined;
  public bf_vitA = undefined;
  public bf_fiber = undefined;
  public bf_carbohydrates = undefined;
  public bf_food_image;
  public menu_bf_cond;
  public menu_bf_weight;
  public bf_class;

  //mid_day  menu
  public mid_day_show_menu:boolean = false;
  public mid_day_show_non_menu:boolean = true;
  public mid_day_option;
  public menu_mid_day_foodType = [];
  public menu_mid_day_tpetxt;
  public menu_mid_day_subfoodType = [];
  public mid_day_energy = undefined;
  public mid_day_protein = undefined;
  public mid_day_fat = undefined;
  public mid_day_vitA = undefined;
  public mid_day_fiber = undefined;
  public mid_day_carbohydrates = undefined;
  public mid_day_food_image;
  public menu_mid_day_cond;
  public menu_mid_day_weight;
  public mid_day_class;

  //lunch  menu
  public lunch_show_menu:boolean = false;
  public lunch_show_non_menu:boolean = true;
  public lunch_option;
  public menu_lunch_foodType = [];
  public menu_lunch_tpetxt;
  public menu_lunch_subfoodType = [];
  public lunch_energy = undefined;
  public lunch_protein = undefined;
  public lunch_fat = undefined;
  public lunch_vitA = undefined;
  public lunch_fiber = undefined;
  public lunch_carbohydrates = undefined;
  public lunch_food_image;
  public menu_lunch_cond;
  public menu_lunch_weight;
  public lunch_class;

  //evening  menu
  public eve_show_menu:boolean = false;
  public eve_show_non_menu:boolean = true;
  public eve_option;
  public menu_eve_foodType = [];
  public menu_eve_tpetxt;
  public menu_eve_subfoodType = [];
  public eve_energy = undefined;
  public eve_protein = undefined;
  public eve_fat = undefined;
  public eve_vitA = undefined;
  public eve_fiber = undefined;
  public eve_carbohydrates = undefined;
  public eve_food_image;
  public menu_eve_cond;
  public menu_eve_weight;
  public eve_class;

  //dinner  menu
  public dinner_show_menu:boolean = false;
  public dinner_show_non_menu:boolean = true;
  public dinner_option;
  public menu_dinner_foodType = [];
  public menu_dinner_tpetxt;
  public menu_dinner_subfoodType = [];
  public dinner_energy = undefined;
  public dinner_protein = undefined;
  public dinner_fat = undefined;
  public dinner_vitA = undefined;
  public dinner_fiber = undefined;
  public dinner_carbohydrates = undefined;
  public dinner_food_image;
  public menu_dinner_cond;
  public menu_dinner_weight;
  public dinner_class;

  //night  menu
  public night_show_menu:boolean = false;
  public night_show_non_menu:boolean = true;
  public night_option;
  public menu_night_foodType = [];
  public menu_night_tpetxt;
  public menu_night_subfoodType = [];
  public night_energy = undefined;
  public night_protein = undefined;
  public night_fat = undefined;
  public night_vitA = undefined;
  public night_fiber = undefined;
  public night_carbohydrates = undefined;
  public night_food_image;
  public menu_night_cond;
  public menu_night_weight;
  public night_class;
  public days =["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  public day ="Sunday";
  public diseaseType;
  public splId;
  public splName;
  constructor(public habitualservice: HabitualService, public messageservice: CasesheetService, public dialog: MatDialog, public http: Http, 
    public router: Router, public route: ActivatedRoute, public toastr: ToastrService,public masterCSdata:MasterCSHelperService, public dietDisease: dietDisease) {
    this.col_dtaa = "0";
    this.cal_txt = "0";
    this.tot_cal = "0";
  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.dietPlanFlag = message;
      }
    });

    this.subscription = this.habitualservice.getMessage().subscribe(message => {
      if (message) {
        this.cal_txt = message;
        this.get_casesheet_data(undefined);
        this.KcalCalories(this.cal_txt)
      }
    });
    this.subscription = this.dietDisease.getDisease().subscribe(message => {
      if (message) {
        this.diseaseType = message
        this.get_casesheet_data(this.diseaseType);
      
      }
    });

    if (Doc_Helper.getAppFlow() == "client") {      
      this.patient_list = Helper_Class.getInfo();
      this.userinfo = Helper_Class.getclient_hospitalDetails();
      this.user_id = this.userinfo.user_id;
      this.client = true;
    } else {
      this.patient_list = Doc_Helper.getClient_Info();
      this.userinfo = Helper_Class.getInfo();
      this.user_id = this.userinfo.user_id;
      this.client = false;
    }

    this.hospital_details = Helper_Class.getHospitalInfo();
    this.hospital_name = this.hospital_details.hosp_name;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.hospital_details.hptl_logo;
    if(masterCSData_Helper.getMasterFoodType() == undefined){
      await this.masterCSdata.getFoodType();
      this.get_foot_categ();
    }else{
      this.get_foot_categ();
    }
    this.get_casesheet_data(undefined);
    this.selectMenu("Morning","menu",true);
    this.selectMenu("Breakfast","menu",true);
    this.selectMenu("Mid-day","menu",true);
    this.selectMenu("Lunch","menu",true);
    this.selectMenu("Evening","menu",true);
    this.selectMenu("Dinner","menu",true);
    this.selectMenu("Night","menu",true);
    if(masterCSData_Helper.getMasterFoodMenu() == undefined){
      await this.masterCSdata.getFoodMenu();
      this.getMenu();
    }else{
      this.getMenu();
    }
    var spl = this.patient_list.spl;
    this.getSpecilizationType(spl);
  }
  getSpecilizationType(splName) {//Get specilization
    var obj = masterCSData_Helper.getMasterSpecilizationType();
    
    for (var i = 0; i < obj.specialization.length; i++) {
      if (obj.specialization[i].spl_name == splName) {
        this.splId = obj.specialization[i].spl_id;
        this.splName = obj.specialization[i].spl_name
      }
    }
  }
  get_foot_categ() {
    var obj = masterCSData_Helper.getMasterFoodType();
    this.foot_cate = obj.food_categories;
    this.ColoriesRequiredFun();
  }

  openDietPlan() {
    const dialogRef = this.dialog.open(DietpopupwindowComponent, {
      width: '70%',
      height: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.Sga_overalltxt = result;
    });
  }

  dietplanhistory(e) {
    const dialogRef1 = this.dialog.open(DietplanhistorypopupComponent, {
      width: '75%',
      height: '500px',
      data: e
    });

    dialogRef1.afterClosed().subscribe(result => {
    });
  }

  food_catchange(session) {
    if (session == "Morning") {
      this.mor_measure = "";
      this.mor_weight = "";
      this.mor_enegry = "";
      this.mor_food_qty = "---Select---";
      this.mor_subfoot_type = [];
      document.getElementById("mornCategory").style.borderColor = "#2ca8fe";
    } else if (session == "breakfast") {
      this.Break_Quantity = "";
      this.Break_Measure = "";
      this.Break_Weight = "";
      this.Break_Energy = "";
      this.break_subfoot_type = [];
      document.getElementById("bfCategory").style.borderColor = "#2ca8fe";
    } else if (session == "midday") {
      this.midday_Quantity = "";
      this.midday_Measure = "";
      this.midday_Weight = "";
      this.midday_Energy = "";
      this.midday_subfoot_type = [];
      document.getElementById("middayCategory").style.borderColor = "#2ca8fe";
    } else if (session == "lunch") {
      this.lunch_Quantity = "";
      this.lunch_Measure = "";
      this.lunch_Weight = "";
      this.lunch_Energy = "";
      this.lunch_subfoot_type = [];
      document.getElementById("lunchCategory").style.borderColor = "#2ca8fe";
    } else if (session == "eve") {
      this.eve_Quantity = "";
      this.eve_Measure = "";
      this.eve_Weight = "";
      this.eve_Energy = "";
      this.eve_subfoot_type = [];
      document.getElementById("eveCategory").style.borderColor = "#2ca8fe";
    } else if (session == "dinner") {
      this.dinner_Quantity = "";
      this.dinner_Measure = "";
      this.dinner_Weight = "";
      this.dinner_Energy = "";
      this.dinner_subfoot_type = [];
      document.getElementById("dinnerCategory").style.borderColor = "#2ca8fe";
    } else if (session == "night") {
      this.night_Quantity = "";
      this.night_Measure = "";
      this.night_Weight = "";
      this.night_Energy = "";
      this.night_subfoot_type = [];
      document.getElementById("nightCategory").style.borderColor = "#2ca8fe";
    }

    var type_id = null;
    for (var i = 0; i < this.foot_cate.length; i++) {
      if (session == "Morning") {
        if (this.mor_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
      } else if (session == "breakfast") {
        if (this.break_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
      } else if (session == "midday") {
        if (this.midday_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
      } else if (session == "lunch") {
        if (this.lunch_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
      } else if (session == "eve") {
        if (this.eve_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
      } else if (session == "dinner") {
        if (this.dinner_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
      } else if (session == "night") {
        if (this.night_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/ftypes',
      JSON.stringify({
        food_category: type_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (session == "Morning") {
            this.mor_foot_type = obj.food_types;
          } else if (session == "breakfast") {
            this.break_foot_type = obj.food_types;
          } else if (session == "midday") {
            this.midday_foot_type = obj.food_types;
          } else if (session == "lunch") {
            this.lunch_foot_type = obj.food_types;
          } else if (session == "eve") {
            this.eve_foot_type = obj.food_types;
          } else if (session == "dinner") {
            this.dinner_foot_type = obj.food_types;
          } else if (session == "night") {
            this.night_foot_type = obj.food_types;
          }
        },
        error => {});
  }

  send_data() {
    Helper_Class.setDiet(null);
    var sga_send = null;
    var sub_rel = null;
    var tcal_to_txt = null;

    tcal_to_txt = this.tot_cal != "" && this.tot_cal != null && this.tot_cal != undefined ? this.tot_cal : "0";
    var get_ret = this.patient_list.spl == "Gynecology" ? Helper_Class.getgynaRet() : Helper_Class.getRet();
    var get_life_sty_id = get_ret != undefined && get_ret.life_style_id != null ? get_ret.life_style_id : undefined;
    var flag_data = false;

    if (this.dietmoradd.length != 0) {
      this.diet_mrng_arry = [];
      for (var i = 0; i < this.dietmoradd.length; i++) {
        this.diet_mrng_arry.push({
          food_item_id: this.dietmoradd[i].food_item_id,
          quantity: this.dietmoradd[i].quantity,
          energy: this.dietmoradd[i].energy,
          cond: this.dietmoradd[i].cond,
          day:this.dietmoradd[i].day,
          is_doctor: "1",
        });
      }
      flag_data = true;
    } else {
      if (this.dplan_mor_flag == "1") {
        this.diet_mrng_arry.push({
          flag: "No"
        });
      }
    }

    var break_fast_arrydata = [];
    if (this.dietbreakfastadd.length != 0) {
      for (var i = 0; i < this.dietbreakfastadd.length; i++) {
        break_fast_arrydata.push({
          food_item_id: this.dietbreakfastadd[i].food_item_id,
          quantity: this.dietbreakfastadd[i].quantity,
          energy: this.dietbreakfastadd[i].energy,
          cond: this.dietbreakfastadd[i].cond,
          day:this.dietbreakfastadd[i].day,
          is_doctor: "1",
        });
        flag_data = true;
      }
    } 

    var mid_day_arrydata = [];
    if (this.dietmiddayadd.length != 0) {
      for (var i = 0; i < this.dietmiddayadd.length; i++) {
        mid_day_arrydata.push({
          food_item_id: this.dietmiddayadd[i].food_item_id,
          quantity: this.dietmiddayadd[i].quantity,
          energy: this.dietmiddayadd[i].energy,
          cond: this.dietmiddayadd[i].cond,
          day:this.dietmiddayadd[i].day,
          is_doctor: "1",
        });
        flag_data = true;
      }

    }

    var lunch_arrydata = [];
    if (this.dietlunchadd.length != 0) {
      for (var i = 0; i < this.dietlunchadd.length; i++) {
        lunch_arrydata.push({
          food_item_id: this.dietlunchadd[i].food_item_id,
          quantity: this.dietlunchadd[i].quantity,
          energy: this.dietlunchadd[i].energy,
          cond: this.dietlunchadd[i].cond,
          day:this.dietlunchadd[i].day,
          is_doctor: "1",
        });
        flag_data = true;
      }
    } 

    var evening_arrydata = [];
    if (this.dieteveadd.length != 0) {
      for (var i = 0; i < this.dieteveadd.length; i++) {
        evening_arrydata.push({
          food_item_id: this.dieteveadd[i].food_item_id,
          quantity: this.dieteveadd[i].quantity,
          energy: this.dieteveadd[i].energy,
          cond: this.dieteveadd[i].cond,
          day:this.dieteveadd[i].day,
          is_doctor: "1",
        });
        flag_data = true;
      }
    } 

    var dinner_arrydata = [];
    if (this.dietdinneradd.length != 0) {
      for (var i = 0; i < this.dietdinneradd.length; i++) {
        dinner_arrydata.push({
          food_item_id: this.dietdinneradd[i].food_item_id,
          quantity: this.dietdinneradd[i].quantity,
          energy: this.dietdinneradd[i].energy,
          cond: this.dietdinneradd[i].cond,
          day:this.dietdinneradd[i].day,
          is_doctor: "1",
        });
        flag_data = true;
      }
    } 

    var night_arrydata = [];
    if (this.dietnightadd.length != 0) {
      for (var i = 0; i < this.dietnightadd.length; i++) {
        night_arrydata.push({
          food_item_id: this.dietnightadd[i].food_item_id,
          quantity: this.dietnightadd[i].quantity,
          energy: this.dietnightadd[i].energy,
          cond: this.dietnightadd[i].cond,
          day:this.dietnightadd[i].day,
          is_doctor: "1",
        });
      }
    } 

    if(this.patient_list.sub_id != null && this.patient_list.sub_id != undefined && this.patient_list.sub_id != "") {
      sub_rel = this.patient_list.sub_id;
      sga_send = {
        is_doctor: "1",
        overall_rating: this.Sga_overalltxt,
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        sub_rel_id: sub_rel,
        country: "IN",
        cal_required: this.cal_txt,
        cal_intake: tcal_to_txt,
        life_style_id: get_life_sty_id,
        dplan_morning: this.diet_mrng_arry,
        dplan_breakfast: break_fast_arrydata,
        dplan_midday: mid_day_arrydata,
        dplan_lunch: lunch_arrydata,
        dplan_evening: evening_arrydata,
        dplan_dinner: dinner_arrydata,
        dplan_night: night_arrydata,
        diet_plan: this.diet_plan_all,
        
      }
    }else {
      sga_send = {
        is_doctor: "1",
        overall_rating: this.Sga_overalltxt,
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        country: "IN",
        cal_required: this.cal_txt,
        cal_intake: tcal_to_txt,
        life_style_id: get_life_sty_id,
        dplan_morning: this.diet_mrng_arry,
        dplan_breakfast: break_fast_arrydata,
        dplan_midday: mid_day_arrydata,
        dplan_lunch: lunch_arrydata,
        dplan_evening: evening_arrydata, 
        dplan_dinner: dinner_arrydata,
        dplan_night: night_arrydata,
        diet_plan: this.diet_plan_all,
        
      }
    }
    Helper_nephro_casesheet.diet_plan_chk_falg = "1";
    Helper_Class.setDietplan(sga_send);
    Helper_Class.setDiet(sga_send);
  }

  // get sub food type
  food_typechange(food, session) {
    this.Calculation_Quantity("type", food, session);
    if (session == "Morning") {
      this.mor_measure = "";
      this.mor_weight = "";
      this.mor_enegry = "";
      this.mor_food_qty = "";
      this.mor_food_subtxt = "";
      document.getElementById("mornfoodtype").style.borderColor = "#2ca8fe";
    } else if (session == "breakfast") {
      this.Break_Measure = "";
      this.Break_Weight = "";
      this.Break_Energy = "";
      this.Break_Quantity = "";
      this.break_subtxt = "";
      document.getElementById("bffoodtype").style.borderColor = "#2ca8fe";
    } else if (session == "midday") {
      this.midday_Measure = "";
      this.midday_Weight = "";
      this.midday_Energy = "";
      this.midday_Quantity = "";
      this.midday_subtxt = "";
      document.getElementById("middayfoodtype").style.borderColor = "#2ca8fe";
    } else if (session == "lunch") {
      this.lunch_Measure = "";
      this.lunch_Weight = "";
      this.lunch_Energy = "";
      this.lunch_Quantity = "";
      this.lunch_subtxt = "";
      document.getElementById("lunchfoodtype").style.borderColor = "#2ca8fe";
    } else if (session == "eve") {
      this.eve_Measure = "";
      this.eve_Weight = "";
      this.eve_Energy = "";
      this.eve_Quantity = "";
      this.eve_subtxt = "";
      document.getElementById("evefoodtype").style.borderColor = "#2ca8fe";
    } else if (session == "dinner") {
      this.dinner_Measure = "";
      this.dinner_Weight = "";
      this.dinner_Energy = "";
      this.dinner_Quantity = "";
      this.dinner_subtxt = "";
      document.getElementById("dinnerfoodtype").style.borderColor = "#2ca8fe";
    } else if (session == "night") {
      this.night_Measure = "";
      this.night_Weight = "";
      this.night_Energy = "";
      this.night_Quantity = "";
      this.night_subtxt = "";
      document.getElementById("nightfoodtype").style.borderColor = "#2ca8fe";
    }

    var type_id = null, food_ty = null;
    for (var i = 0; i < this.foot_cate.length; i++) {
      if (session == "Morning") {
        if (this.mor_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
        food_ty = this.mor_tpetxt;
      } else if (session == "breakfast") {
        if (this.break_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
        food_ty = this.break_tpetxt;
      } else if (session == "midday") {
        if (this.midday_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
        food_ty = this.midday_tpetxt;
      } else if (session == "lunch") {
        if (this.lunch_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
        food_ty = this.lunch_tpetxt;
      } else if (session == "eve") {
        if (this.eve_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
        food_ty = this.eve_tpetxt;
      } else if (session == "dinner") {
        if (this.dinner_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
        food_ty = this.dinner_tpetxt;
      } else if (session == "night") {
        if (this.night_catxt == this.foot_cate[i].description) {
          type_id = this.foot_cate[i].type_id;
        }
        food_ty = this.night_tpetxt;
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/fstypes',
      JSON.stringify({
        food_category: type_id,
        food_type: food_ty
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (session == "Morning") {
            this.mor_subfoot_type = obj.food_subtypes;
          } else if (session == "breakfast") {
            this.break_subfoot_type = obj.food_subtypes;
          } else if (session == "midday") {
            this.midday_subfoot_type = obj.food_subtypes;
          } else if (session == "lunch") {
            this.lunch_subfoot_type = obj.food_subtypes;
          } else if (session == "eve") {
            this.eve_subfoot_type = obj.food_subtypes;
          } else if (session == "dinner") {
            this.dinner_subfoot_type = obj.food_subtypes;
          } else if (session == "night") {
            this.night_subfoot_type = obj.food_subtypes;
          }
        },
        error => {}
      )
  }

  // set qty,measure,weight
  food_subtypes(foodsub, session) {
    this.Calculation_Quantity("subtype", foodsub, session);
    if (session == "Morning") {
      for (var i = 0; i < this.mor_subfoot_type.length; i++) {
        if (this.mor_food_subtxt == this.mor_subfoot_type[i].description) {
          this.mor_food_qty = this.mor_subfoot_type[i].quantity;
          this.mor_measure = this.mor_subfoot_type[i].measure;
          this.mor_weight = this.mor_subfoot_type[i].weight;
          this.mor_enegry = this.mor_subfoot_type[i].energy;
        }
      }
      document.getElementById("mornsubfoodtype").style.borderColor = "#2ca8fe";
      document.getElementById("mornQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "breakfast") {
      for (var i = 0; i < this.break_subfoot_type.length; i++) {
        if (this.break_subtxt == this.break_subfoot_type[i].description) {
          this.Break_Quantity = this.break_subfoot_type[i].quantity;
          this.Break_Measure = this.break_subfoot_type[i].measure;
          this.Break_Weight = this.break_subfoot_type[i].weight;
          this.Break_Energy = this.break_subfoot_type[i].energy;
        }
      }
      document.getElementById("bfsubfoodtype").style.borderColor = "#2ca8fe";
      document.getElementById("bfQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "midday") {
      for (var i = 0; i < this.midday_subfoot_type.length; i++) {
        if (this.midday_subtxt == this.midday_subfoot_type[i].description) {
          this.midday_Quantity = this.midday_subfoot_type[i].quantity;
          this.midday_Measure = this.midday_subfoot_type[i].measure;
          this.midday_Weight = this.midday_subfoot_type[i].weight;
          this.midday_Energy = this.midday_subfoot_type[i].energy;
        }
      }
      document.getElementById("middaysubfoodtype").style.borderColor = "#2ca8fe";
      document.getElementById("middayQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "lunch") {
      for (var i = 0; i < this.lunch_subfoot_type.length; i++) {
        if (this.lunch_subtxt == this.lunch_subfoot_type[i].description) {
          this.lunch_Quantity = this.lunch_subfoot_type[i].quantity;
          this.lunch_Measure = this.lunch_subfoot_type[i].measure;
          this.lunch_Weight = this.lunch_subfoot_type[i].weight;
          this.lunch_Energy = this.lunch_subfoot_type[i].energy;
        }
      }
      document.getElementById("lunchsubfoodtype").style.borderColor = "#2ca8fe";
      document.getElementById("lunchQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "eve") {
      for (var i = 0; i < this.eve_subfoot_type.length; i++) {
        if (this.eve_subtxt == this.eve_subfoot_type[i].description) {
          this.eve_Quantity = this.eve_subfoot_type[i].quantity;
          this.eve_Measure = this.eve_subfoot_type[i].measure;
          this.eve_Weight = this.eve_subfoot_type[i].weight;
          this.eve_Energy = this.eve_subfoot_type[i].energy;
        }
      }
      document.getElementById("evesubfoodtype").style.borderColor = "#2ca8fe";
      document.getElementById("eveQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "dinner") {
      for (var i = 0; i < this.dinner_subfoot_type.length; i++) {
        if (this.dinner_subtxt == this.dinner_subfoot_type[i].description) {
          this.dinner_Quantity = this.dinner_subfoot_type[i].quantity;
          this.dinner_Measure = this.dinner_subfoot_type[i].measure;
          this.dinner_Weight = this.dinner_subfoot_type[i].weight;
          this.dinner_Energy = this.dinner_subfoot_type[i].energy;
        }
      }
      document.getElementById("dinnersubfoodtype").style.borderColor = "#2ca8fe";
      document.getElementById("dinnerQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "night") {
      for (var i = 0; i < this.night_subfoot_type.length; i++) {
        if (this.night_subtxt == this.night_subfoot_type[i].description) {
          this.night_Quantity = this.night_subfoot_type[i].quantity;
          this.night_Measure = this.night_subfoot_type[i].measure;
          this.night_Weight = this.night_subfoot_type[i].weight;
          this.night_Energy = this.night_subfoot_type[i].energy;
        }
      }
      document.getElementById("nightsubfoodtype").style.borderColor = "#2ca8fe";
      document.getElementById("nightQuantity").style.borderColor = "#2ca8fe";
    }
  }

  Quantity_OnChange(quantity, session) {
    var Actual_Quantity = quantity;

    if (quantity == "1/8") {
      Actual_Quantity = 0.125;
    } else if (quantity == "1/4") {
      Actual_Quantity = 0.25;
    } else if (quantity == "1/3") {
      Actual_Quantity = 0.33;
    } else if (quantity == "1/2") {
      Actual_Quantity = 0.5;
    } else if (quantity == "3/4") {
      Actual_Quantity = 0.75;
    } else if (quantity == "11/2") {
      Actual_Quantity = 1.5;
    } else if (quantity == "21/2") {
      Actual_Quantity = 2.5;
    } else if (quantity == "21/8") {
      Actual_Quantity = 2.125;
    } else if (quantity == "91/2") {
      Actual_Quantity = 9.5;
    } else if (quantity == "2/3") {
      Actual_Quantity = 0.67;
    } else if (quantity == "41/4") {
      Actual_Quantity = 4.25;
    }

    if (session == "Morning") {
      this.mor_measure = this.Calc_Measure;
      this.mor_weight = Math.round(this.Calc_Weight * parseFloat(Actual_Quantity));
      this.mor_enegry = Math.round(this.Calc_Energy * parseFloat(Actual_Quantity));
      document.getElementById("mornQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "breakfast") {
      this.Break_Measure = this.Calc_Measure;
      this.Break_Weight = Math.round(this.Calc_Weight * parseFloat(Actual_Quantity));
      this.Break_Energy = Math.round(this.Calc_Energy * parseFloat(Actual_Quantity));
      document.getElementById("bfQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "midday") {
      this.midday_Measure = this.Calc_Measure;
      this.midday_Weight = Math.round(this.Calc_Weight * parseFloat(Actual_Quantity));
      this.midday_Energy = Math.round(this.Calc_Energy * parseFloat(Actual_Quantity));
      document.getElementById("middayQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "lunch") {
      this.lunch_Measure = this.Calc_Measure;
      this.lunch_Weight = Math.round(this.Calc_Weight * parseFloat(Actual_Quantity));
      this.lunch_Weight_Energy = Math.round(this.Calc_Energy * parseFloat(Actual_Quantity));
      document.getElementById("lunchQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "eve") {
      this.eve_Measure = this.Calc_Measure;
      this.eve_Weight = Math.round(this.Calc_Weight * parseFloat(Actual_Quantity));
      this.eve_Energy = Math.round(this.Calc_Energy * parseFloat(Actual_Quantity));
      document.getElementById("eveQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "dinner") {
      this.dinner_Measure = this.Calc_Measure;
      this.dinner_Weight = Math.round(this.Calc_Weight * parseFloat(Actual_Quantity));
      this.dinner_Energy = Math.round(this.Calc_Energy * parseFloat(Actual_Quantity));
      document.getElementById("dinnerQuantity").style.borderColor = "#2ca8fe";
    } else if (session == "night") {
      this.night_Measure = this.Calc_Measure;
      this.night_Weight = Math.round(this.Calc_Weight * parseFloat(Actual_Quantity));
      this.night_Energy = Math.round(this.Calc_Energy * parseFloat(Actual_Quantity));
      document.getElementById("nightQuantity").style.borderColor = "#2ca8fe";
    }
  }

  //=======================================================
  set_col_data() {
    var col_dtaa = this.col_dtaa;
    this.tot_cal = col_dtaa;
    this.KcalCalories(this.cal_txt);
  }

  KcalCalories(cal_txt) {
    this.cal_txt = cal_txt;
    var Cal_req = this.cal_txt;
    var Cal_req_00 = parseInt(Cal_req);
    var Cal_max = Cal_req_00 + 500;
    var Tot_Cal = this.tot_cal;
    var Tot_Cal_00 = 0;

    if (Tot_Cal != "") {
      Tot_Cal_00 = parseInt(Tot_Cal);
    }
  }

  KcalCalories_data(cal_txt, tot_cal) {
    var Cal_req_00 = parseInt(cal_txt);
    var Cal_max = Cal_req_00 + 500;
    var Tot_Cal = tot_cal;
    var Tot_Cal_00 = 0;

    if (Tot_Cal != "") {
      Tot_Cal_00 = parseInt(Tot_Cal);
    }

    if (cal_txt != "") {
      if (Cal_req_00 >= Tot_Cal_00) {
      }
      else {
        Cal_req_00 = parseInt("0");
      }
    }
  }

  //=====================calculation qty ==================
  Calculation_Quantity(type, data, session) {
    var quantity = null, measure = null, weight = null, energy = null;
    if (session == "Morning") {
      if (type == "subtype") {
        for (var i = 0; i < this.mor_subfoot_type.length; i++) {
          if (data == this.mor_subfoot_type[i].description) {
            quantity = this.mor_subfoot_type[i].quantity;
            measure = this.mor_subfoot_type[i].measure;
            weight = this.mor_subfoot_type[i].weight;
            energy = this.mor_subfoot_type[i].energy;
          }
        }
      } else if (type == "type") {
        for (var i = 0; i < this.mor_foot_type.length; i++) {
          if (data == this.mor_foot_type[i].type) {
            quantity = "";
            measure = "";
            weight = "";
            energy = "";
          }
        }
      }
    } else if (session == "breakfast") {
      if (type == "subtype") {
        for (var i = 0; i < this.break_subfoot_type.length; i++) {
          if (data == this.break_subfoot_type[i].description) {
            quantity = this.break_subfoot_type[i].quantity;
            measure = this.break_subfoot_type[i].measure;
            weight = this.break_subfoot_type[i].weight;
            energy = this.break_subfoot_type[i].energy;
          }
        }
      } else if (type == "type") {
        for (var i = 0; i < this.break_foot_type.length; i++) {
          if (data == this.break_foot_type[i].type) {
            quantity = "";
            measure = "";
            weight = "";
            energy = "";
          }
        }
      }
    } else if (session == "midday") {
      if (type == "subtype") {
        for (var i = 0; i < this.midday_subfoot_type.length; i++) {
          if (data == this.midday_subfoot_type[i].description) {
            quantity = this.midday_subfoot_type[i].quantity;
            measure = this.midday_subfoot_type[i].measure;
            weight = this.midday_subfoot_type[i].weight;
            energy = this.midday_subfoot_type[i].energy;
          }
        }
      } else if (type == "type") {
        for (var i = 0; i < this.midday_foot_type.length; i++) {
          if (data == this.midday_foot_type[i].type) {
            quantity = "";
            measure = "";
            weight = "";
            energy = "";
          }
        }
      }
    } else if (session == "lunch") {
      if (type == "subtype") {
        for (var i = 0; i < this.lunch_subfoot_type.length; i++) {
          if (data == this.lunch_subfoot_type[i].description) {
            quantity = this.lunch_subfoot_type[i].quantity;
            measure = this.lunch_subfoot_type[i].measure;
            weight = this.lunch_subfoot_type[i].weight;
            energy = this.lunch_subfoot_type[i].energy;
          }
        }
      } else if (type == "type") {
        for (var i = 0; i < this.lunch_foot_type.length; i++) {
          if (data == this.lunch_foot_type[i].type) {
            quantity = "";
            measure = "";
            weight = "";
            energy = "";
          }
        }
      }
    } else if (session == "eve") {
      if (type == "subtype") {
        for (var i = 0; i < this.eve_subfoot_type.length; i++) {
          if (data == this.eve_subfoot_type[i].description) {
            quantity = this.eve_subfoot_type[i].quantity;
            measure = this.eve_subfoot_type[i].measure;
            weight = this.eve_subfoot_type[i].weight;
            energy = this.eve_subfoot_type[i].energy;
          }
        }
      } else if (type == "type") {
        for (var i = 0; i < this.eve_foot_type.length; i++) {
          if (data == this.eve_foot_type[i].type) {
            quantity = "";
            measure = "";
            weight = "";
            energy = "";
          }
        }
      }
    } else if (session == "dinner") {
      if (type == "subtype") {
        for (var i = 0; i < this.dinner_subfoot_type.length; i++) {
          if (data == this.dinner_subfoot_type[i].description) {
            quantity = this.dinner_subfoot_type[i].quantity;
            measure = this.dinner_subfoot_type[i].measure;
            weight = this.dinner_subfoot_type[i].weight;
            energy = this.dinner_subfoot_type[i].energy;
          }
        }
      } else if (type == "type") {
        for (var i = 0; i < this.dinner_foot_type.length; i++) {
          if (data == this.dinner_foot_type[i].type) {
            quantity = "";
            measure = "";
            weight = "";
            energy = "";
          }
        }
      }
    } else if (session == "night") {
      if (type == "subtype") {
        for (var i = 0; i < this.night_subfoot_type.length; i++) {
          if (data == this.night_subfoot_type[i].description) {
            quantity = this.night_subfoot_type[i].quantity;
            measure = this.night_subfoot_type[i].measure;
            weight = this.night_subfoot_type[i].weight;
            energy = this.night_subfoot_type[i].energy;
          }
        }
      } else if (type == "type") {
        for (var i = 0; i < this.night_foot_type.length; i++) {
          if (data == this.night_foot_type[i].type) {
            quantity = "";
            measure = "";
            weight = "";
            energy = "";
          }
        }
      }
    }

    if (quantity == "1" || quantity == "2" || quantity == "3" || quantity == "4" || quantity == "5" ||
      quantity == "6" || quantity == "7" || quantity == "8" || quantity == "9" || quantity == "10" ||
      quantity == "11" || quantity == "12" || quantity == "13" || quantity == "14" || quantity == "15" ||
      quantity == "16" || quantity == "17" || quantity == "20" || quantity == "21" || quantity == "22" ||
      quantity == "24" || quantity == "26" || quantity == "28" || quantity == "30" || quantity == "32" ||
      quantity == "40" || quantity == "42" || quantity == "48" || quantity == "60") {
      this.Calc_Number = 1 / parseFloat(quantity);
    } else if (quantity == "1/8") {
      this.Calc_Number = 8;
    } else if (quantity == "1/4") {
      this.Calc_Number = 4;
    } else if (quantity == "1/3") {
      this.Calc_Number = 3;
    } else if (quantity == "1/2") {
      this.Calc_Number = 2;
    } else if (quantity == "3/4") {
      this.Calc_Number = 1.34;
    } else if (quantity == "11/2") {
      this.Calc_Number = 0.67;
    } else if (quantity == "21/2") {
      this.Calc_Number = 0.4;
    } else if (quantity == "21/8") {
      this.Calc_Number = 0.47;
    } else if (quantity == "91/2") {
      this.Calc_Number = 0.105;
    } else if (quantity == "2/3") {
      this.Calc_Number = 1.5;
    } else if (quantity == "41/4") {
      this.Calc_Number = 0.235;
    }

    this.Calc_Quantity = 1;
    this.Calc_Measure = measure;
    this.Calc_Weight = parseInt((weight * this.Calc_Number).toFixed(2).toString());
    this.Calc_Energy = parseInt((energy * this.Calc_Number).toFixed(2).toString());
  }

  //===============  add tables===============
  // add morning click
  diet_morclick() {
    if (this.mor_catxt == undefined || this.mor_tpetxt == undefined) {
      if (this.mor_catxt == undefined) {
        this.toastr.info("Please enter Category")
      } else if (this.mor_tpetxt == undefined) {
        this.toastr.info("Please enter Food type")
      }
    } else {
      this.morning_index = this.dietmoradd.length;
      this.morning_table = false;
      var flag = true;
      if (this.dietmoradd.length != 0) {
        for (var i = 0; i < this.dietmoradd.length; i++) {
          if(this.dietmoradd[i].cond != "or" ){
            if (this.mor_food_subtxt != "") {
              if (this.dietmoradd[i].description == this.mor_food_subtxt) {
                flag = false;
                this.mor_food_cond = "";
                this.mor_food_qty = "";
                this.mor_measure = "";
                this.mor_food_subtxt = "";
                this.toastr.error(Message_data.alrdyExist);
                break;
              }
            }else if (this.dietmoradd[i].food_sub_type == this.mor_tpetxt) {
              flag = false;
              this.mor_food_cond = "";
              this.mor_food_qty = "";
              this.mor_measure = "";
              this.mor_tpetxt = "";
              this.toastr.error(Message_data.alrdyExist);
              break;
            }
          }
        }
      }

      if (flag == true) {
        for (var i = 0; i < this.mor_subfoot_type.length; i++) {
          if (this.mor_food_subtxt == this.mor_subfoot_type[i].description) {
            var food_item_id = this.mor_subfoot_type[i].food_item_id;
          }
        }

        this.dietmoradd.push({
          category: this.mor_catxt,
          food_sub_type: this.mor_tpetxt,
          description: this.mor_food_subtxt,
          quantity: this.mor_food_qty,
          measure: this.mor_measure,
          weight: this.mor_weight,
          energy: this.mor_enegry,
          food_item_id: food_item_id,
          cond: this.mor_food_cond,
          day:this.day,
        });

        if (this.mor_measure != "item(s)") {
          if (this.mor_food_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.mor_food_cond != undefined && this.mor_food_cond == "or") {
            if (this.dietmorning != undefined) {
              this.dietmorning += this.mor_food_qty + " " + this.mor_measure + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt + " " + "\n" + this.mor_food_cond + "\n"
            } else {
              this.dietmorning = +this.mor_food_qty + " " + this.mor_measure + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt + " " + "\n" + this.mor_food_cond + "\n"
            }
          } else if (this.mor_food_cond != "or" && this.mor_food_cond != "Select" && this.mor_food_cond != undefined) {
            if (this.dietmorning != undefined) {
              this.dietmorning += this.mor_food_qty + " " + this.mor_measure + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt + " " + this.mor_food_cond + " "
            } else {
              this.dietmorning = this.mor_food_qty + " " + this.mor_measure + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt + " " + this.mor_food_cond + " "
            }
          } else {
            if (this.dietmorning != undefined) {
              this.dietmorning += this.mor_food_qty + " " + this.mor_measure + " " + this.mor_tpetxt + " - " + this.mor_food_subtxt
            } else {
              this.dietmorning = this.mor_food_qty + " " + this.mor_measure + " " + this.mor_tpetxt + " - " + this.mor_food_subtxt
            }
          }
        } else {
          if (this.mor_food_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
  
          if (this.mor_food_cond != undefined && this.mor_food_cond == "or") {
            if (this.dietmorning != undefined) {
              this.dietmorning += this.mor_food_qty + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt + " " + "\n" + this.mor_food_cond + "\n";
            } else {
              this.dietmorning = this.mor_food_qty + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt + " " + "\n" + this.mor_food_cond + "\n";
            }
          } else if (this.mor_food_cond != "or" && this.mor_food_cond != "Select" && this.mor_food_cond != undefined) {
            if (this.dietmorning != undefined) {
              this.dietmorning += this.mor_food_qty + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt + " " + this.mor_food_cond + " ";
            } else {
              this.dietmorning = this.mor_food_qty + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt + " " + this.mor_food_cond + " ";
            }
          } else {
            if (this.dietmorning != undefined) {
              this.dietmorning += this.mor_food_qty + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt
            } else {
              this.dietmorning = this.mor_food_qty + " " + this.mor_tpetxt + nohypen + this.mor_food_subtxt
            }
          }
        }
        
        if (this.dietmoradd.length == 0) {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.mor_enegry);
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.mor_enegry);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        } else {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.mor_enegry);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.mor_enegry);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        }

        //Resets the form 
        this.mor_catxt = undefined;
        this.mor_tpetxt = undefined;
        this.mor_food_subtxt = undefined;
        this.mor_food_qty = undefined;
        this.mor_measure = '';
        this.mor_weight = '';
        this.mor_enegry = '';
      }
    }
  }

  morning_snackdelete(data) {
    for (var i = 0; i < this.dietmoradd.length; i++) {
      if (this.dietmoradd[i].description == data) {
        var a = parseFloat(this.col_dtaa) - parseFloat(this.dietmoradd[i].energy);
        this.col_dtaa = a
        this.set_col_data();
        this.dietmoradd.splice(i, 1);
        break;
      }
    }
    this.morning_table = this.dietmoradd.length != "0" ? false : true;
  }

  diet_breakfastclick() {
    if (this.break_catxt == undefined || this.break_tpetxt == undefined) {
      if (this.break_catxt == undefined) {
        this.toastr.info("Please enter Category")
      } else if (this.break_tpetxt == undefined) {
        this.toastr.info("Please enter Food Type")
      }
    } else {
      this.breakfast_table = false;
      var flag = true;

      if (this.dietbreakfastadd.length != 0) {
        for (var i = 0; i < this.dietbreakfastadd.length; i++) {
          if(this.dietbreakfastadd[i].cond != "or" ){
            if (this.break_subtxt != "") {
              if (this.dietbreakfastadd[i].description == this.break_subtxt) {
                flag = false;
                this.breakfast_cond = "";
                this.Break_Quantity = "";
                this.Break_Measure = "";
                this.break_subtxt = "";
                this.toastr.error(Message_data.alrdyExist);
                break;
              }
            } else if (this.dietbreakfastadd[i].food_sub_type == this.break_tpetxt) {
              flag = false;
              this.breakfast_cond = "";
              this.Break_Quantity = "";
              this.Break_Measure = "";
              this.break_tpetxt = "";
              this.toastr.error(Message_data.alrdyExist);
              break;
            } else { }
          }
        }
      }

      if (flag == true) {
        for (var i = 0; i < this.break_subfoot_type.length; i++) {
          if (this.break_subtxt == this.break_subfoot_type[i].description) {
            var food_item_id = this.break_subfoot_type[i].food_item_id;
          }
        }

        this.dietbreakfastadd.push({
          category: this.break_catxt,
          food_sub_type: this.break_tpetxt,
          description: this.break_subtxt,
          quantity: this.Break_Quantity,
          measure: this.Break_Measure,
          weight: this.Break_Weight,
          energy: this.Break_Energy,
          food_item_id: food_item_id,
          cond: this.breakfast_cond,
          day:this.day,
        });

        if (this.Break_Measure != "item(s)") {
          if (this.break_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.breakfast_cond != undefined && this.breakfast_cond == "or") {
            if (this.dietbreakfast != undefined) {
              this.dietbreakfast += this.Break_Quantity + " " + this.Break_Measure + " " + this.break_tpetxt + nohypen + this.break_subtxt + " " + "\n" + this.breakfast_cond + "\n";
            } else {
              this.dietbreakfast = this.Break_Quantity + " " + this.Break_Measure + " " + this.break_tpetxt + nohypen + this.break_subtxt + " " + "\n" + this.breakfast_cond + "\n";
            }
          } else if (this.breakfast_cond != "or" && this.breakfast_cond != "Select" && this.breakfast_cond != undefined) {
            if (this.break_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
            if (this.dietbreakfast != undefined) {
              this.dietbreakfast += this.Break_Quantity + " " + this.Break_Measure + " " + this.break_tpetxt + nohypen + this.break_subtxt + " " + this.breakfast_cond + " ";
            } else {
              this.dietbreakfast = this.Break_Quantity + " " + this.Break_Measure + " " + this.break_tpetxt + nohypen + this.break_subtxt + " " + this.breakfast_cond + " ";
            }
          } else {
            if (this.break_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
            if (this.dietbreakfast != undefined) {
              this.dietbreakfast += this.Break_Quantity + " " + this.Break_Measure + " " + this.break_tpetxt + nohypen + this.break_subtxt + " ";
            } else {
              this.dietbreakfast = this.Break_Quantity + " " + this.Break_Measure + " " + this.break_tpetxt + nohypen + this.break_subtxt + " ";
            }
          }
        } else {
          if (this.breakfast_cond != undefined && this.breakfast_cond == "or") {
            if (this.break_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
            if (this.dietbreakfast != undefined) {
              this.dietbreakfast += this.Break_Quantity + " " + this.break_tpetxt + nohypen + this.break_subtxt + " " + "\n" + this.breakfast_cond + "\n";
            } else {
              this.dietbreakfast = this.Break_Quantity + " " + this.break_tpetxt + nohypen + this.break_subtxt + " " + "\n" + this.breakfast_cond + "\n";
            }
          } else if (this.breakfast_cond != "or" && this.breakfast_cond != "Select" && this.breakfast_cond != undefined) {
            if (this.break_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
            if (this.dietbreakfast != undefined) {
              this.dietbreakfast += this.Break_Quantity + " " + this.break_tpetxt + nohypen + this.break_subtxt + " " + this.breakfast_cond + " ";
            } else {
              this.dietbreakfast = this.Break_Quantity + " " + this.break_tpetxt + nohypen + this.break_subtxt + " " + this.breakfast_cond + " ";
            }
  
          } else {
            if (this.break_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
            if (this.dietbreakfast != undefined) {
              this.dietbreakfast += this.Break_Quantity + " " + this.break_tpetxt + nohypen + this.break_subtxt + " ";
            } else {
              this.dietbreakfast = this.Break_Quantity + " " + this.break_tpetxt + nohypen + this.break_subtxt + " ";
            }
          }
        }

        if (this.dietbreakfastadd.length == 0) {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.Break_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.Break_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        } else {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.Break_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.Break_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        }

        //Resets the form 
        this.break_catxt = "";
        this.break_tpetxt = "";
        this.break_subtxt = "";
        this.Break_Quantity = "";
        this.Break_Measure = '';
        this.Break_Weight = '';
        this.Break_Energy = '';
      }
    }
  }

  // midday addd
  diet_middayclick() {
    if (this.midday_catxt == undefined || this.midday_tpetxt == undefined) {
      if (this.midday_catxt == undefined) {
        this.toastr.info("Please enter Category")
      } else if (this.midday_tpetxt == undefined) {
        this.toastr.info("Please enter Food Type")
      }
    } else {
      this.midday_table = false;
      var flag = true;

      if (this.dietmiddayadd.length != 0) {
        for (var i = 0; i < this.dietmiddayadd.length; i++) {
          if(this.dietmiddayadd[i].cond != "or" ){
            if (this.midday_subtxt != "") {
              if (this.dietmiddayadd[i].description == this.midday_subtxt) {
                flag = false;
                this.midday_cond = "";
                this.midday_Quantity = "";
                this.midday_Measure = "";
                this.midday_subtxt = "";
                this.toastr.error(Message_data.alrdyExist);
              }
            }else if (this.dietmiddayadd[i].food_sub_type == this.midday_tpetxt) {
              flag = false;
              this.midday_cond = "";
              this.midday_Quantity = "";
              this.midday_Measure = "";
              this.midday_tpetxt = "";
              this.toastr.error(Message_data.alrdyExist);
            } else { }
          }
        }
      }

      if (flag == true) {
        for (var i = 0; i < this.midday_subfoot_type.length; i++) {
          if (this.midday_subtxt == this.midday_subfoot_type[i].description) {
            var food_item_id = this.midday_subfoot_type[i].food_item_id;
          }
        }

        this.dietmiddayadd.push({
          category: this.midday_catxt,
          food_sub_type: this.midday_tpetxt,
          description: this.midday_subtxt,
          quantity: this.midday_Quantity,
          measure: this.midday_Measure,
          weight: this.midday_Weight,
          energy: this.midday_Energy,
          food_item_id: food_item_id,
          cond: this.midday_cond,
          day:this.day,
        });

        if (this.midday_Measure != "item(s)") {
          if (this.midday_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.midday_cond != undefined && this.midday_cond == "or") {
            if (this.dietmidday != undefined) {
              this.dietmidday += this.midday_Quantity + " " + this.midday_Measure + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " " + "\n" + this.midday_cond + "\n"
            } else {
              this.dietmidday = this.midday_Quantity + " " + this.midday_Measure + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " " + "\n" + this.midday_cond + "\n"
            }
          } else if (this.midday_cond != "or" && this.midday_cond != "Select" && this.midday_cond != undefined) {
            if (this.dietmidday != undefined) {
              this.dietmidday += this.midday_Quantity + " " + this.midday_Measure + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " " + this.midday_cond + " "
            } else {
              this.dietmidday = this.midday_Quantity + " " + this.midday_Measure + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " " + this.midday_cond + " "
            }
          } else {
            if (this.dietmidday != undefined) {
              this.dietmidday += this.midday_Quantity + " " + this.midday_Measure + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " "
            } else {
              this.dietmidday = this.midday_Quantity + " " + this.midday_Measure + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " "
            }
          }
        } else {
          if (this.midday_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.midday_cond != undefined && this.midday_cond == "or") {
            if (this.dietmidday != undefined) {
              this.dietmidday += this.midday_Quantity + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " " + "\n" + this.midday_cond + "\n"
            } else {
              this.dietmidday = this.midday_Quantity + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " " + "\n" + this.midday_cond + "\n"
            }
          } else if (this.midday_cond != "or" && this.midday_cond != "Select" && this.midday_cond != undefined) {
            if (this.dietmidday != undefined) {
              this.dietmidday += this.midday_Quantity + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " " + this.midday_cond + " "
            } else {
              this.dietmidday = this.midday_Quantity + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " " + this.midday_cond + " "
            }
          } else {
            if (this.dietmidday != undefined) {
              this.dietmidday += this.midday_Quantity + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " "
            } else {
              this.dietmidday = this.midday_Quantity + " " + this.midday_tpetxt + nohypen + this.midday_subtxt + " "
            }
          }
        }

        if (this.dietmiddayadd.length == 0) {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.midday_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.midday_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        } else {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.midday_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.midday_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        }

        //Resets the form 
        this.midday_catxt = "";
        this.midday_tpetxt = "";
        this.midday_subtxt = "";
        this.midday_Quantity = "";
        this.midday_Measure = '';
        this.midday_Weight = '';
        this.midday_Energy = '';
      }
    }
  }

  // lunch data
  diet_lunchclick() {
    if (this.lunch_catxt == undefined || this.lunch_tpetxt == undefined) {
      if (this.lunch_catxt == undefined) {
        this.toastr.info("Please enter Category")
      } else if (this.lunch_tpetxt == undefined) {
        this.toastr.info("Please enter Food Type")
      }
    } else {
      this.lunch_table = false;
      var flag = true;

      if (this.dietlunchadd.length != 0) {
        for (var i = 0; i < this.dietlunchadd.length; i++) {
          if(this.dietlunchadd[i].cond != "or" ){
            if (this.lunch_subtxt != "") {
              if (this.dietlunchadd[i].description == this.lunch_subtxt) {
                this.lunch_cond = "";
                this.lunch_Quantity = "";
                this.lunch_Measure = "";
                this.lunch_subtxt = "";
                this.toastr.error(Message_data.alrdyExist);
                break;
              }
            } else if (this.dietlunchadd[i].food_sub_type == this.lunch_tpetxt) {
              this.lunch_cond = "";
              this.lunch_Quantity = "";
              this.lunch_Measure = "";
              this.lunch_tpetxt = "";
              this.toastr.error(Message_data.alrdyExist);
              break;
            } else { }
          }
        }
      }

      if (flag == true) {
        for (var i = 0; i < this.lunch_subfoot_type.length; i++) {
          if (this.lunch_subtxt == this.lunch_subfoot_type[i].description) {
            var food_item_id = this.lunch_subfoot_type[i].food_item_id;
          }
        }

        this.dietlunchadd.push({
          category: this.lunch_catxt,
          food_sub_type: this.lunch_tpetxt,
          description: this.lunch_subtxt,
          quantity: this.lunch_Quantity,
          measure: this.lunch_Measure,
          weight: this.lunch_Weight,
          energy: this.lunch_Energy,
          food_item_id: food_item_id,
          cond: this.lunch_cond,
          day:this.day,
        });

        if (this.lunch_Measure != "item(s)") {
          if (this.lunch_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.lunch_cond != undefined && this.lunch_cond == "or") {
            if (this.dietlunch != undefined) {
              this.dietlunch += this.lunch_Quantity + " " + this.lunch_Measure + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " " + "\n" + this.lunch_cond + "\n"
            } else {
              this.dietlunch = this.lunch_Quantity + " " + this.lunch_Measure + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " " + "\n" + this.lunch_cond + "\n"
            }
  
          } else if (this.lunch_cond != "or" && this.lunch_cond != "Select" && this.lunch_cond != undefined) {
            if (this.dietlunch != undefined) {
              this.dietlunch += this.lunch_Quantity + " " + this.lunch_Measure + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " " + "" + this.lunch_cond + " "
            } else {
              this.dietlunch = this.lunch_Quantity + " " + this.lunch_Measure + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " " + "" + this.lunch_cond + " "
            }
  
          } else {
            if (this.dietlunch != undefined) {
              this.dietlunch += this.lunch_Quantity + " " + this.lunch_Measure + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " "
            } else {
              this.dietlunch = this.lunch_Quantity + " " + this.lunch_Measure + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " "
            }
          }
  
        } else {
          if (this.lunch_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
  
          if (this.lunch_cond != undefined && this.lunch_cond == "or") {
            if (this.dietlunch != undefined) {
              this.dietlunch += this.lunch_Quantity + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " " + "\n" + this.lunch_cond + "\n";
            } else {
              this.dietlunch = this.lunch_Quantity + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " " + "\n" + this.lunch_cond + "\n";
            }
  
          } else if (this.lunch_cond != "or" && this.lunch_cond != "Select" && this.lunch_cond != undefined) {
            if (this.dietlunch != undefined) {
              this.dietlunch += this.lunch_Quantity + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " " + this.lunch_cond + " ";
            } else {
              this.dietlunch = this.lunch_Quantity + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " " + this.lunch_cond + " ";
            }
  
          } else {
            if (this.dietlunch != undefined) {
              this.dietlunch += this.lunch_Quantity + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " "
            } else {
              this.dietlunch = this.lunch_Quantity + " " + this.lunch_tpetxt + nohypen + this.lunch_subtxt + " "
            }
          }
        }
  
        if (this.dietlunchadd.length == 0) {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.lunch_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.lunch_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        } else {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.lunch_Energy);
            this.set_col_data();

          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.lunch_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        }

        //Resets the form 
        this.lunch_catxt = "";
        this.lunch_tpetxt = "";
        this.lunch_subtxt = "";
        this.lunch_Quantity = "";
        this.lunch_Measure = '';
        this.lunch_Weight = '';
        this.lunch_Energy = '';
      }
    }
  }
  // eve data
  diet_eveclick() {
    if (this.eve_catxt == undefined || this.eve_tpetxt == undefined) {
      if (this.eve_catxt == undefined) {
        this.toastr.info("Please enter Category")
      } else if (this.eve_tpetxt == undefined) {
        this.toastr.info("Please enter Food Type")
      }
    } else {
      this.eve_table = false;
      var flag = true;

      if (this.dieteveadd.length != 0) {
        for (var i = 0; i < this.dieteveadd.length; i++) {
          if(this.dieteveadd[i].cond != "or" ){
            if (this.eve_subtxt != "") {
              if (this.dieteveadd[i].description == this.eve_subtxt) {
                this.eve_cond = "";
                this.eve_Quantity = "";
                this.eve_Measure = "";
                this.eve_subtxt = "";
                this.toastr.error(Message_data.alrdyExist);
                break;
              }
            } else if (this.dieteveadd[i].food_sub_type == this.eve_tpetxt) {
              this.eve_cond = "";
              this.eve_Quantity = "";
              this.eve_Measure = "";
              this.eve_tpetxt = "";
              this.toastr.error(Message_data.alrdyExist);
              break;
            }
          }
        }
      }

      if (flag == true) {
        for (var i = 0; i < this.eve_subfoot_type.length; i++) {
          if (this.eve_subtxt == this.eve_subfoot_type[i].description) {
            var food_item_id = this.eve_subfoot_type[i].food_item_id;
          }
        }

        this.dieteveadd.push({
          category: this.eve_catxt,
          food_sub_type: this.eve_tpetxt,
          description: this.eve_subtxt,
          quantity: this.eve_Quantity,
          measure: this.eve_Measure,
          weight: this.eve_Weight,
          energy: this.eve_Energy,
          food_item_id: food_item_id,
          cond: this.eve_cond,
          day:this.day,
        });

        if (this.eve_Measure != "item(s)") {
          if (this.eve_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.eve_cond != undefined && this.eve_cond == "or") {
            if (this.dietevening != undefined) {
              this.dietevening += this.eve_Quantity + " " + this.eve_Measure + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " " + "\n" + this.eve_cond + "\n "
            } else {
              this.dietevening = this.eve_Quantity + " " + this.eve_Measure + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " " + "\n" + this.eve_cond + "\n "
            }
          } else if (this.eve_cond != "or" && this.eve_cond != "Select" && this.eve_cond != undefined) {
            if (this.dietevening != undefined) {
              this.dietevening += this.eve_Quantity + " " + this.eve_Measure + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " " + this.eve_cond + " ";
            } else {
              this.dietevening = this.eve_Quantity + " " + this.eve_Measure + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " " + this.eve_cond + " ";
            }
          } else {
            if (this.dietevening != undefined) {
              this.dietevening += this.eve_Quantity + " " + this.eve_Measure + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " "
            } else {
              this.dietevening = this.eve_Quantity + " " + this.eve_Measure + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " "
            }
          }
        } else {
          if (this.eve_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.eve_cond != undefined && this.eve_cond == "or") {
            if (this.dietevening != undefined) {
              this.dietevening += this.eve_Quantity + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " " + "\n" + this.eve_cond + "\n";
            } else {
              this.dietevening = this.eve_Quantity + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " " + "\n" + this.eve_cond + "\n";
            }
          } else if (this.eve_cond != "or" && this.eve_cond != "Select" && this.eve_cond != undefined) {
            if (this.dietevening != undefined) {
              this.dietevening += this.eve_Quantity + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " " + this.eve_cond + " ";
            } else {
              this.dietevening = this.eve_Quantity + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " " + this.eve_cond + " ";
            }
          } else {
            if (this.dietevening != undefined) {
              this.dietevening += this.eve_Quantity + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " "
            } else {
              this.dietevening = this.eve_Quantity + " " + this.eve_tpetxt + nohypen + this.eve_subtxt + " "
            }
          }
        }

        if (this.dieteveadd.length == 0) {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.eve_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.eve_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        } else {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.eve_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.eve_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        }

        //Resets the form 
        this.eve_catxt = "";
        this.eve_tpetxt = "";
        this.eve_subtxt = "";
        this.eve_Quantity = "";
        this.eve_Measure = '';
        this.eve_Weight = '';
        this.eve_Energy = '';
      }
    }
  }

  // dinner data
  diet_dinnerclick() {
    if (this.dinner_catxt == undefined || this.dinner_tpetxt == undefined) {
      if (this.dinner_catxt == undefined) {
        this.toastr.info("Please enter Category")
      } else if (this.dinner_tpetxt == undefined) {
        this.toastr.info("Please enter Food Type")
      }
    } else {
      this.dinner_table = false;
      var flag = true;

      if (this.dietdinneradd.length != 0) {
        for (var i = 0; i < this.dietdinneradd.length; i++) {
          if(this.dietdinneradd[i].cond != "or" ){
            if (this.dinner_subtxt != "") {
              if (this.dietdinneradd[i].description == this.dinner_subtxt) {
                flag = false;
                this.dinner_cond = "";
                this.dinner_Quantity = "";
                this.dinner_Measure = "";
                this.dinner_subtxt = "";
                this.toastr.error(Message_data.alrdyExist);
                break;
              }
            } else if (this.dietdinneradd[i].food_sub_type == this.dinner_tpetxt) {
              flag = false;
              this.dinner_cond = "";
              this.dinner_Quantity = "";
              this.dinner_Measure = "";
              this.dinner_tpetxt = "";
              this.toastr.error(Message_data.alrdyExist);
              break;
            } else { }
          }
        }
      }

      if (flag == true) {
        for (var i = 0; i < this.dinner_subfoot_type.length; i++) {
          if (this.dinner_subtxt == this.dinner_subfoot_type[i].description) {
            var food_item_id = this.dinner_subfoot_type[i].food_item_id;
          }
        }

        this.dietdinneradd.push({
          category: this.dinner_catxt,
          food_sub_type: this.dinner_tpetxt,
          description: this.dinner_subtxt,
          quantity: this.dinner_Quantity,
          measure: this.dinner_Measure,
          weight: this.dinner_Weight,
          energy: this.dinner_Energy,
          food_item_id: food_item_id,
          cond: this.dinner_cond,
          day:this.day,
        });

        if (this.dinner_Measure != "item(s)") {
          if (this.dinner_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.dinner_cond != undefined && this.dinner_cond == "or") {
            if (this.dietdinner != undefined) {
              this.dietdinner += this.dinner_Quantity + " " + this.dinner_Measure + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " " + "\n" + this.dinner_cond + "\n"
            } else {
              this.dietdinner = this.dinner_Quantity + " " + this.dinner_Measure + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " " + "\n" + this.dinner_cond + "\n"
            }
          } else if (this.dinner_cond != "or" && this.dinner_cond != "Select" && this.dinner_cond != undefined) {
            if (this.dietdinner != undefined) {
              this.dietdinner += this.dinner_Quantity + " " + this.dinner_Measure + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " " + this.dinner_cond + " "
            } else {
              this.dietdinner = this.dinner_Quantity + " " + this.dinner_Measure + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " " + this.dinner_cond + " "
            }
          } else {
            if (this.dietdinner != undefined) {
              this.dietdinner += this.dinner_Quantity + " " + this.dinner_Measure + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " "
            } else {
              this.dietdinner = this.dinner_Quantity + " " + this.dinner_Measure + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " "
            }
          }
        } else {
          if (this.dinner_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.dinner_cond != undefined && this.dinner_cond == "or") {
            if (this.dietdinner != undefined) {
              this.dietdinner += this.dinner_Quantity + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " " + "\n" + this.dinner_cond + "\n"
            } else {
              this.dietdinner = this.dinner_Quantity + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " " + "\n" + this.dinner_cond + "\n"
            }
          } else if (this.dinner_cond != "or" && this.dinner_cond != "Select" && this.dinner_cond != undefined) {
            if (this.dietdinner != undefined) {
              this.dietdinner += this.dinner_Quantity + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " " + this.dinner_cond + " "
            } else {
              this.dietdinner += this.dinner_Quantity + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " " + this.dinner_cond + " "
            }
          } else {
            if (this.dietdinner != undefined) {
              this.dietdinner += this.dinner_Quantity + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " "
            } else {
              this.dietdinner = this.dinner_Quantity + " " + this.dinner_tpetxt + nohypen + this.dinner_subtxt + " "
            }
          }
        }

        if (this.dietdinneradd.length == 0) {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.dinner_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.dinner_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        } else {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.dinner_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.dinner_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        }

        //Resets the form 
        this.dinner_catxt = "";
        this.dinner_tpetxt = "";
        this.dinner_subtxt = "";
        this.dinner_Quantity = "";
        this.dinner_Measure = '';
        this.dinner_Weight = '';
        this.dinner_Energy = '';
      }
    }
  }

  // night data
  diet_nightclick() {
    if (this.night_catxt == undefined || this.night_tpetxt == undefined) {
      if (this.mor_catxt == undefined) {
        this.toastr.info("Please enter Category")
      } else if (this.mor_tpetxt == undefined) {
        this.toastr.info("Please enter Food Type")
      }
    } else {
      this.night_table = false;
      var flag = true;

      if (this.dietnightadd.length != 0) {
        for (var i = 0; i < this.dietnightadd.length; i++) {
          if(this.dietnightadd[i].cond != "or" ){
            if (this.night_subtxt != "") {
              if (this.dietnightadd[i].description == this.night_subtxt) {
                flag = false;
                this.night_cond = "";
                this.night_Quantity = "";
                this.night_Measure = "";
                this.night_subtxt = "";
                this.toastr.error('already exists')
                break;
              }
            } else if (this.dietnightadd[i].food_sub_type == this.night_tpetxt) {
              flag = false;
              this.night_cond = "";
              this.night_Quantity = "";
              this.night_Measure = "";
              this.night_tpetxt = "";
              this.toastr.error('already exists')
              break;
            } 
          }
        }
      }

      if (flag == true) {
        for (var i = 0; i < this.night_subfoot_type.length; i++) {
          if (this.night_subtxt == this.night_subfoot_type[i].description) {
            var food_item_id = this.night_subfoot_type[i].food_item_id;
          }
        }

        this.dietnightadd.push({
          category: this.night_catxt,
          food_sub_type: this.night_tpetxt,
          description: this.night_subtxt,
          quantity: this.night_Quantity,
          measure: this.night_Measure,
          weight: this.night_Weight,
          energy: this.night_Energy,
          food_item_id: food_item_id,
          cond: this.night_cond,
          day:this.day,
        });

        if (this.night_Measure != "item(s)") {
          if (this.night_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.night_cond != undefined && this.night_cond == "or") {
            if (this.dietnight != undefined) {
              this.dietnight += this.night_Quantity + " " + this.night_Measure + " " + this.night_tpetxt + nohypen + this.night_subtxt + " " + "\n" + this.night_cond + "\n"
            } else {
              this.dietnight = this.night_Quantity + " " + this.night_Measure + " " + this.night_tpetxt + nohypen + this.night_subtxt + " " + "\n" + this.night_cond + "\n"
            }
          } else if (this.night_cond != "or" && this.night_cond != "Select" && this.night_cond != undefined) {
            if (this.dietnight != undefined) {
              this.dietnight += this.night_Quantity + " " + this.night_Measure + " " + this.night_tpetxt + nohypen + this.night_subtxt + " " + " " + this.night_cond + " "
            } else {
              this.dietnight = this.night_Quantity + " " + this.night_Measure + " " + this.night_tpetxt + nohypen + this.night_subtxt + " " + " " + this.night_cond + " "
            }
          } else {
            if (this.dietnight != undefined) {
              this.dietnight += this.night_Quantity + " " + this.night_Measure + " " + this.night_tpetxt + nohypen + this.night_subtxt + " "
            } else {
              this.dietnight = this.night_Quantity + " " + this.night_Measure + " " + this.night_tpetxt + nohypen + this.night_subtxt + " "
            }
          }
        } else {
          if (this.night_subtxt == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (this.night_cond != undefined && this.night_cond == "or") {
            if (this.dietnight != undefined) {
              this.dietnight += "\n" + this.night_cond + "\n" + this.night_Quantity + " " + this.night_tpetxt + nohypen + this.night_subtxt + " "
            } else {
              this.dietnight = "\n" + this.night_cond + "\n" + this.night_Quantity + " " + this.night_tpetxt + nohypen + this.night_subtxt + " "
            }
  
          } else if (this.night_cond != "or" && this.night_cond != "Select" && this.night_cond != undefined) {
            if (this.dietnight != undefined) {
              this.dietnight += this.night_cond + " " + this.night_Quantity + " " + this.night_tpetxt + nohypen + this.night_subtxt + " "
            } else {
              this.dietnight = this.night_cond + " " + this.night_Quantity + " " + this.night_tpetxt + nohypen + this.night_subtxt + " "
            }
  
          } else {
            if (this.dietnight != undefined) {
              this.dietnight += this.night_Quantity + " " + this.night_tpetxt + nohypen + this.night_subtxt + " "
            } else {
              this.dietnight = this.night_Quantity + " " + this.night_tpetxt + nohypen + this.night_subtxt + " "
            }
          }
        }

        if (this.dietnightadd.length == 0) {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.night_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.night_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        } else {
          if (this.col_dtaa == "0") {
            this.col_dtaa = parseInt(this.night_Energy);
            this.set_col_data();
          } else {
            var tot = parseFloat(this.col_dtaa) + parseFloat(this.night_Energy);
            this.col_dtaa = tot.toString();
            this.set_col_data();
          }
        }

        //Resets the form 
        this.night_catxt = "";
        this.night_tpetxt = "";
        this.night_subtxt = "";
        this.night_Quantity = "";
        this.night_Measure = '';
        this.night_Weight = '';
        this.night_Energy = '';
      }
    }
  }

  get_casesheet_data(cal_txt) {
    var sen_pass;
    var cal_txt = cal_txt;
    if (this.patient_list.sub_id != null && this.patient_list.sub_id != undefined &&
      this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0) {
      sen_pass = JSON.stringify({
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        sub_rel_id: this.patient_list.sub_id,
        country: "IN",
        disease : cal_txt,
        spl_id : this.splId,
      })
    }else {
      sen_pass = JSON.stringify({
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        country: "IN",
        disease : cal_txt,
        spl_id : this.splId,
      })
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/dplan',
      sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          var family = Helper_Class.getRet();
          this.tot_cal = obj.cal_intake != null ? obj.cal_intake : "0";
          this.Sga_overalltxt = obj.sga_overall_rating != null ? obj.sga_overall_rating : "";

          if (obj.dplan_morning != null && obj.dplan_morning.length != 0) {
            if (obj.dplan_morning != null) {
              this.dietmorning = "";
              this.dietmoradd = [];
              for (var i = 0; i < obj.dplan_morning.length; i++) {
                var dayMorning = obj.dplan_morning[i].day != undefined ? obj.dplan_morning[i].day : " "
                this.dietmoradd.push({
                  category: obj.dplan_morning[i].category,
                  food_sub_type: obj.dplan_morning[i].food_sub_type,
                  description: obj.dplan_morning[i].description,
                  quantity: obj.dplan_morning[i].quantity,
                  measure: obj.dplan_morning[i].measure,
                  weight: obj.dplan_morning[i].weight,
                  energy: obj.dplan_morning[i].energy,
                  food_item_id: obj.dplan_morning[i].food_item_id,
                  cond: obj.dplan_morning[i].cond,
                  day:dayMorning,
                });

               if(obj.dplan_morning[i].day == this.day){
                  var diet:any;
                  var condition = obj.dplan_morning[i].cond != undefined ? obj.dplan_morning[i].cond : " ";
                  var food_subtype = obj.dplan_morning[i].food_sub_type != undefined ? obj.dplan_morning[i].food_sub_type : "";
                  var foodtype = obj.dplan_morning[i].description != undefined ? obj.dplan_morning[i].description : "";
                  var quan = obj.dplan_morning[i].quantity != undefined ? obj.dplan_morning[i].quantity : "";
                  var measures = obj.dplan_morning[i].measures != undefined ? obj.dplan_morning[i].measures : "";

                  if (measures != "item(s)") {
                    if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                    if (condition != undefined && condition == "or") {
                      if (this.dietmorning != undefined) {
                        this.dietmorning += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                      } else {
                        this.dietmorning = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                      }
                    } else if (condition != "or" && condition != "Select" && condition != undefined) {
                      if (this.dietmorning != undefined) {
                        this.dietmorning += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                      } else {
                        this.dietmorning = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                      }
                    } else {
                      if (this.dietmorning != undefined) {
                        this.dietmorning += quan + " " + measures + " " + foodtype + " - " + food_subtype
                      } else {
                        this.dietmorning = quan + " " + measures + " " + foodtype + " - " + food_subtype
                      }
                    }
                  } else {
                    if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                    if (condition != undefined && condition == "or") {
                      if (this.dietmorning != undefined) {
                        this.dietmorning += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                      } else {
                        this.dietmorning = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                      }
                    } else if (condition != "or" && condition != "Select" && condition != undefined) {
                      if (this.dietmorning != undefined) {
                        this.dietmorning += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                      } else {
                        this.dietmorning = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                      }
                    } else {
                      if (this.dietmorning != undefined) {
                        this.dietmorning += quan + " " + foodtype + nohypen + food_subtype
                      } else {
                        this.dietmorning = quan + " " + foodtype + nohypen + food_subtype
                      }
                    }
                  }
                }else{
                  this.emptyDay("Morning");
                }
              }
              for (var i = 0; i < this.dietmoradd.length; i++) {
                if (this.dietmoradd.length == 0) {
                  if (this.col_dtaa == "0") {
                    this.col_dtaa = this.dietmoradd[i].energy;
                    this.set_col_data();
                  } else {
                    var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietmoradd[i].energy);
                    this.col_dtaa = tot;
                    this.set_col_data();
                  }
                } else {
                  if (this.col_dtaa == "0") {
                    this.col_dtaa = this.dietmoradd[i].energy;
                    this.set_col_data();
                  } else {
                    var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietmoradd[i].energy);
                    this.col_dtaa = tot;
                    this.set_col_data();
                  }
                }
              }
            }
          } 

          if (obj.dplan_breakfast != null && obj.dplan_breakfast.length != 0) {
            this.break_table = false;
            this.diet_plan_all = "1";
            this.dietbreakfast = "";
            for (var i = 0; i < obj.dplan_breakfast.length; i++) {
              this.dietbreakfastadd.push({
                category: obj.dplan_breakfast[i].category,
                food_sub_type: obj.dplan_breakfast[i].food_sub_type,
                description: obj.dplan_breakfast[i].description,
                quantity: obj.dplan_breakfast[i].quantity,
                measure: obj.dplan_breakfast[i].measure,
                weight: obj.dplan_breakfast[i].weight,
                energy: obj.dplan_breakfast[i].energy,
                food_item_id: obj.dplan_breakfast[i].food_item_id,
                cond: obj.dplan_breakfast[i].cond,
                day:obj.dplan_breakfast[i].day,
              });

              if(obj.dplan_breakfast[i].day == this.day){
                var condition_breakfast = obj.dplan_breakfast[i].cond != undefined ? obj.dplan_breakfast[i].cond : "";
                var breakfast_foodsubtype = obj.dplan_breakfast[i].food_sub_type != undefined ? obj.dplan_breakfast[i].food_sub_type : "";
                var breakfast_quan = obj.dplan_breakfast[i].quantity != undefined ? obj.dplan_breakfast[i].quantity : "";
                var breakfast_measures = obj.dplan_breakfast[i].measures != undefined ? obj.dplan_breakfast[i].measures : "";

                if (breakfast_measures != "item(s)") {
                  if (breakfast_foodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (condition_breakfast != undefined && condition_breakfast == "or") {
                    if (this.dietbreakfast != undefined) {
                      this.dietbreakfast += breakfast_quan + " " + breakfast_measures + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " " + "\n" + condition_breakfast + "\n";
                    } else {
                      this.dietbreakfast = breakfast_quan + " " + breakfast_measures + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " " + "\n" + condition_breakfast + "\n";
                    }
                  } else if (condition_breakfast != "or" && condition_breakfast != "Select" && condition_breakfast != undefined) {
                    if (breakfast_foodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                    if (this.dietbreakfast != undefined) {
                      this.dietbreakfast += breakfast_quan + " " + breakfast_measures + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " " + condition_breakfast + " ";
                    } else {
                      this.dietbreakfast = breakfast_quan + " " + breakfast_measures + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " " + condition_breakfast + " ";
                    }
                  } else {
                    if (breakfast_foodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                    if (this.dietbreakfast != undefined) {
                      this.dietbreakfast += breakfast_quan + " " + breakfast_measures + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " ";
                    } else {
                      this.dietbreakfast = breakfast_quan + " " + breakfast_measures + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " ";
                    }
                  }
                } else {
                  if (condition_breakfast != undefined && condition_breakfast == "or") {
                    if (breakfast_foodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                    if (this.dietbreakfast != undefined) {
                      this.dietbreakfast += breakfast_quan + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " " + "\n" + condition_breakfast + "\n";
                    } else {
                      this.dietbreakfast = breakfast_quan + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " " + "\n" + condition_breakfast + "\n";
                    }
                  } else if (condition_breakfast != "or" && condition_breakfast != "Select" && condition_breakfast != undefined) {
                    if (breakfast_foodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                    if (this.dietbreakfast != undefined) {
                      this.dietbreakfast += breakfast_quan + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " " + condition_breakfast + " ";
                    } else {
                      this.dietbreakfast = breakfast_quan + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " " + condition_breakfast + " ";
                    }
                  } else {
                    if (breakfast_foodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                    if (this.dietbreakfast != undefined) {
                      this.dietbreakfast += breakfast_quan + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " ";
                    } else {
                      this.dietbreakfast = breakfast_quan + " " + obj.dplan_breakfast[i].description + nohypen + breakfast_foodsubtype + " ";
                    }
                  }
                }
              }else{
                this.emptyDay("Breakfast");
              }
            }

            for (var i = 0; i < this.dietbreakfastadd.length; i++) {
              if (this.dietbreakfastadd.length == 0) {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietbreakfastadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietbreakfastadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              } else {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietbreakfastadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietbreakfastadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              }
            }
          }

          //Mid day
          if (obj.dplan_midday != null && obj.dplan_midday.length != 0) {
            this.midday_table = false;
            this.dietmidday = "";

            for (var i = 0; i < obj.dplan_midday.length; i++) {
              this.diet_plan_all = "1";
              this.dietmiddayadd.push({
                category: obj.dplan_midday[i].category,
                food_sub_type: obj.dplan_midday[i].food_sub_type,
                description: obj.dplan_midday[i].description,
                quantity: obj.dplan_midday[i].quantity,
                measure: obj.dplan_midday[i].measure,
                weight: obj.dplan_midday[i].weight,
                energy: obj.dplan_midday[i].energy,
                food_item_id: obj.dplan_midday[i].food_item_id,
                cond: obj.dplan_midday[i].cond,
                day:obj.dplan_midday[i].day,
              });

              if(obj.dplan_midday[i].day == this.day){
                var condition_midday = obj.dplan_midday[i].cond != undefined ? obj.dplan_midday[i].cond : "";
                var middayfoodsubtype = obj.dplan_midday[i].food_sub_type != undefined ? obj.dplan_midday[i].food_sub_type : "";
                var middayquan = obj.dplan_midday[i].quantity != undefined ? obj.dplan_midday[i].quantity : "";
                var middaymeasure = obj.dplan_midday[i].measures != undefined ? obj.dplan_midday[i].measures : "";

                if (middaymeasure != "item(s)") {
                  if (middayfoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (condition_midday != undefined && condition_midday == "or") {
                    if (this.dietmidday != undefined) {
                      this.dietmidday += middayquan + " " + middaymeasure + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " " + "\n" + condition_midday + "\n"
                    } else {
                      this.dietmidday = middayquan + " " + middaymeasure + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " " + "\n" + condition_midday + "\n"
                    }
                  } else if (this.midday_cond != "or" && this.midday_cond != "Select" && this.midday_cond != undefined) {
                    if (this.dietmidday != undefined) {
                      this.dietmidday += middayquan + " " + middaymeasure + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " " + condition_midday + " "
                    } else {
                      this.dietmidday = middayquan + " " + middaymeasure + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " " + condition_midday + " "
                    }
                  } else {
                    if (this.dietmidday != undefined) {
                      this.dietmidday += middayquan + " " + middaymeasure + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " "
                    } else {
                      this.dietmidday = middayquan + " " + middaymeasure + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " "
                    }
                  }
                } else {
                  if (middayfoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (condition_midday != undefined && condition_midday == "or") {
                    if (this.dietmidday != undefined) {
                      this.dietmidday += middayquan + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " " + "\n" + condition_midday + "\n"
                    } else {
                      this.dietmidday = middayquan + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " " + "\n" + condition_midday + "\n";
                    }
                  } else if (condition_midday != "or" && condition_midday != "Select" && condition_midday != undefined) {
                    if (this.dietmidday != undefined) {
                      this.dietmidday += middayquan + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " " + condition_midday + " "
                    } else {
                      this.dietmidday = middayquan + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " " + condition_midday + " "
                    }
                  } else {
                    if (this.dietmidday != undefined) {
                      this.dietmidday += middayquan + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " "
                    } else {
                      this.dietmidday = middayquan + " " + obj.dplan_midday[i].description + nohypen + middayfoodsubtype + " "
                    }
                  }
                }
              }else{
                this.emptyDay("Mid-day");
              }
            }

            for (var i = 0; i < this.dietmiddayadd.length; i++) {
              if (this.dietmiddayadd.length == 0) {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietmiddayadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietmiddayadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              } else {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietmiddayadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietmiddayadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              }
            }
          }

          //Lunch
          if (obj.dplan_lunch != null && obj.dplan_lunch.length != 0) {
            this.dietlunch = "";
            for (var i = 0; i < obj.dplan_lunch.length; i++) {
              this.diet_plan_all = "1";
              this.lunch_table = false;

              this.dietlunchadd.push({
                category: obj.dplan_lunch[i].category,
                food_sub_type: obj.dplan_lunch[i].food_sub_type,
                description: obj.dplan_lunch[i].description,
                quantity: obj.dplan_lunch[i].quantity,
                measure: obj.dplan_lunch[i].measure,
                weight: obj.dplan_lunch[i].weight,
                energy: obj.dplan_lunch[i].energy,
                food_item_id: obj.dplan_lunch[i].food_item_id,
                cond: obj.dplan_lunch[i].cond,
                day:obj.dplan_lunch[i].day,
              });

              if(obj.dplan_lunch[i].day == this.day){
                var lunchcond = obj.dplan_lunch[i].cond != undefined ? obj.dplan_lunch[i].cond : "";
                var lunchfoodsubtype = obj.dplan_lunch[i].food_sub_type != undefined ? obj.dplan_lunch[i].food_sub_type : "";
                var lunchquan = obj.dplan_lunch[i].quantity != undefined ? obj.dplan_lunch[i].quantity : "";
                var lunchmeasure = obj.dplan_lunch[i].measures != undefined ? obj.dplan_lunch[i].measures : "";

                if (lunchmeasure != "item(s)") {
                  if (lunchfoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (lunchcond != undefined && lunchcond == "or") {
                    if (this.dietlunch != undefined) {
                      this.dietlunch += lunchquan + " " + lunchmeasure + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " " + "\n" + lunchcond + "\n"
                    } else {
                      this.dietlunch = lunchquan + " " + lunchmeasure + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " " + "\n" + lunchcond + "\n"
                    }
                  } else if (lunchcond != "or" && lunchcond != "Select" && lunchcond != undefined) {
                    if (this.dietlunch != undefined) {
                      this.dietlunch += lunchquan + " " + lunchmeasure + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " " + lunchcond + " "
                    } else {
                      this.dietlunch = lunchquan + " " + lunchmeasure + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " " + lunchcond + " "
                    }
                  } else {
                    if (this.dietlunch != undefined) {
                      this.dietlunch += lunchquan + " " + lunchmeasure + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " "
                    } else {
                      this.dietlunch = lunchquan + " " + lunchmeasure + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " "
                    }
                  }
                } else {
                  if (lunchfoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (lunchcond != undefined && lunchcond == "or") {
                    if (this.dietlunch != undefined) {
                      this.dietlunch += lunchquan + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " " + "\n" + lunchcond + "\n"
                    } else {
                      this.dietlunch = lunchquan + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " " + "\n" + lunchcond + "\n"
                    }
                  } else if (lunchcond != "or" && lunchcond != "Select" && lunchcond != undefined) {
                    if (this.dietlunch != undefined) {
                      this.dietlunch += lunchquan + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " " + lunchcond + " "
                    } else {
                      this.dietlunch = lunchquan + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " " + lunchcond + " "
                    }
                  } else {
                    if (this.dietlunch != undefined) {
                      this.dietlunch += lunchquan + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " "
                    } else {
                      this.dietlunch = lunchquan + " " + obj.dplan_lunch[i].description + nohypen + lunchfoodsubtype + " "
                    }
                  }
                }
              }else{
                this.emptyDay("Lunch");
              }
            }

            for (var i = 0; i < this.dietlunchadd.length; i++) {
              if (this.dietlunchadd.length == 0) {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietlunchadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietlunchadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              } else {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietlunchadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietlunchadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              }
            }
          }

          //Evening nacks
          if (obj.dplan_evening != null && obj.dplan_evening.length != 0) {
            this.diet_plan_all = "1";
            this.dietevening = "";

            for (var i = 0; i < obj.dplan_evening.length; i++) {
              this.dieteveadd.push({
                category: obj.dplan_evening[i].category,
                food_sub_type: obj.dplan_evening[i].food_sub_type,
                description: obj.dplan_evening[i].description,
                quantity: obj.dplan_evening[i].quantity,
                measure: obj.dplan_evening[i].measure,
                weight: obj.dplan_evening[i].weight,
                energy: obj.dplan_evening[i].energy,
                food_item_id: obj.dplan_evening[i].food_item_id,
                cond: obj.dplan_evening[i].cond,
                day:obj.dplan_evening[i].day,
              });

              if(obj.dplan_evening[i].day == this.day){
                var evecond = obj.dplan_evening[i].cond != undefined ? obj.dplan_evening[i].cond : "";
                var evefoodsubtype = obj.dplan_evening[i].food_sub_type != undefined ? obj.dplan_evening[i].food_sub_type : "";
                var evequan = obj.dplan_evening[i].quantity != undefined ? obj.dplan_evening[i].quantity : "";
                var evemeasure = obj.dplan_evening[i].measures != undefined ? obj.dplan_evening[i].measures : "";

                if (evemeasure != "item(s)") {
                  if (evefoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (evecond != undefined && evecond == "or") {
                    if (this.dietevening != undefined) {
                      this.dietevening += evequan + " " + evemeasure + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " " + "\n" + evecond + "\n "
                    } else {
                      this.dietevening = evequan + " " + evemeasure + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " " + "\n" + evecond + "\n "
                    }
                  } else if (evecond != "or" && evecond != "Select" && evecond != undefined) {
                    if (this.dietevening != undefined) {
                      this.dietevening += evequan + " " + evemeasure + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " " + evecond + " "
                    } else {
                      this.dietevening = evequan + " " + evemeasure + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " " + evecond + " "
                    }
                  } else {
                    if (this.dietevening != undefined) {
                      this.dietevening += evequan + " " + evemeasure + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " "
                    } else {
                      this.dietevening = evequan + " " + evemeasure + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " "
                    }
                  }
                } else {
                  if (evefoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (evecond != undefined && evecond == "or") {
                    if (this.dietevening != undefined) {
                      this.dietevening += evequan + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " " + "\n" + evecond + "\n";
                    } else {
                      this.dietevening = evequan + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " " + "\n" + evecond + "\n";
                    }
                  } else if (evecond != "or" && evecond != "Select" && evecond != undefined) {
                    if (this.dietevening != undefined) {
                      this.dietevening += evequan + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " " + evecond + " "
                    } else {
                      this.dietevening = evequan + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " " + evecond + " "
                    }
                  } else {
                    if (this.dietevening != undefined) {
                      this.dietevening += evequan + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " "
                    } else {
                      this.dietevening = evequan + " " + obj.dplan_evening[i].description + nohypen + evefoodsubtype + " "
                    }
                  }
                }
              }else{
                this.emptyDay("Evening");
              }
            }

            for (var i = 0; i < this.dieteveadd.length; i++) {
              if (this.dieteveadd.length == 0) {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dieteveadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dieteveadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              } else {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dieteveadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dieteveadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              }
            }
          } 

          //Dinner nacks
          if (obj.dplan_dinner != null && obj.dplan_dinner.length != 0) {
            this.diet_plan_all = "1";
            this.dietdinner = "";
            for (var i = 0; i < obj.dplan_dinner.length; i++) {
              this.dinner_table = false;
              this.dietdinneradd.push({
                category: obj.dplan_dinner[i].category,
                food_sub_type: obj.dplan_dinner[i].food_sub_type,
                description: obj.dplan_dinner[i].description,
                quantity: obj.dplan_dinner[i].quantity,
                measure: obj.dplan_dinner[i].measure,
                weight: obj.dplan_dinner[i].weight,
                energy: obj.dplan_dinner[i].energy,
                food_item_id: obj.dplan_dinner[i].food_item_id,
                cond: obj.dplan_dinner[i].cond,
                day:obj.dplan_dinner[i].day,
              });

              if(obj.dplan_dinner[i].day == this.day){
                var dinnercond = obj.dplan_dinner[i].cond != undefined ? obj.dplan_dinner[i].cond : "";
                var dinnerfoodsubtype = obj.dplan_dinner[i].food_sub_type != undefined ? obj.dplan_dinner[i].food_sub_type : "";
                var dinnerquan = obj.dplan_dinner[i].quantity != undefined ? obj.dplan_dinner[i].quantity : "";
                var dinnermeasure = obj.dplan_dinner[i].measures != undefined ? obj.dplan_dinner[i].measures : "";

                if (dinnermeasure != "item(s)") {
                  if (dinnerfoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (dinnercond != undefined && dinnercond == "or") {
                    if (this.dietdinner != undefined) {
                      this.dietdinner += dinnerquan + " " + dinnermeasure + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " " + "\n" + dinnercond + "\n"
                    } else {
                      this.dietdinner = dinnerquan + " " + dinnermeasure + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " " + "\n" + dinnercond + "\n"
                    }
                  } else if (dinnercond != "or" && dinnercond != "Select" && dinnercond != undefined) {
                    if (this.dietdinner != undefined) {
                      this.dietdinner += dinnerquan + " " + dinnermeasure + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " " + dinnercond + " "
                    } else {
                      this.dietdinner = dinnerquan + " " + dinnermeasure + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " " + dinnercond + " "
                    }
                  } else {
                    if (this.dietdinner != undefined) {
                      this.dietdinner += dinnerquan + " " + dinnermeasure + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " "
                    } else {
                      this.dietdinner = dinnerquan + " " + dinnermeasure + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " "
                    }
                  }
                } else {
                  if (dinnerfoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (dinnercond != undefined && dinnercond == "or") {
                    if (this.dietdinner != undefined) {
                      this.dietdinner += dinnerquan + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " " + "\n" + dinnercond + "\n"
                    } else {
                      this.dietdinner = dinnerquan + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " " + "\n" + dinnercond + "\n"
                    }
                  } else if (dinnercond != "or" && dinnercond != "Select" && dinnercond != undefined) {
                    if (this.dietdinner != undefined) {
                      this.dietdinner += dinnerquan + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " " + dinnercond + " "
                    } else {
                      this.dietdinner += dinnerquan + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " " + dinnercond + " "
                    }
                  } else {
                    if (this.dietdinner != undefined) {
                      this.dietdinner += dinnerquan + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " "
                    } else {
                      this.dietdinner = dinnerquan + " " + obj.dplan_dinner[i].description + nohypen + dinnerfoodsubtype + " "
                    }
                  }
                }
              }else{
                this.emptyDay("Dinner");
              }
            }

            for (var i = 0; i < this.dietdinneradd.length; i++) {
              if (this.dietdinneradd.length == 0) {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietdinneradd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietdinneradd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              } else {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietdinneradd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietdinneradd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              }
            }
          }

          //Night
          if (obj.dplan_night != null && obj.dplan_night.length != 0) {
            //   this.dplan_night_flag = "1";
            this.diet_plan_all = "1";
            // this.diet_night_flag=true;
            this.dietnight = "";
            for (var i = 0; i < obj.dplan_night.length; i++) {
              this.night_table = false;

              this.dietnightadd.push({
                category: obj.dplan_night[i].category,
                food_sub_type: obj.dplan_night[i].food_sub_type,
                description: obj.dplan_night[i].description,
                quantity: obj.dplan_night[i].quantity,
                measure: obj.dplan_night[i].measure,
                weight: obj.dplan_night[i].weight,
                energy: obj.dplan_night[i].energy,
                food_item_id: obj.dplan_night[i].food_item_id,
                cond: obj.dplan_night[i].cond,
                day:obj.dplan_night[i].day,
              });

              if(obj.dplan_night[i].day == this.day){
                var nightcond = obj.dplan_night[i].cond != undefined ? obj.dplan_night[i].cond : "";
                var nightfoodsubtype = obj.dplan_night[i].food_sub_type != undefined ? obj.dplan_night[i].food_sub_type : "";
                var nightquan = obj.dplan_night[i].quantity != undefined ? obj.dplan_night[i].quantity : "";
                var nightmeasure = obj.dplan_night[i].measures != undefined ? obj.dplan_night[i].measures : "";

                if (nightmeasure != "item(s)") {
                  if (nightfoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (nightcond != undefined && nightcond == "or") {
                    if (this.dietnight != undefined) {
                      this.dietnight += nightquan + " " + nightmeasure + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " " + "\n" + nightcond + "\n"
                    } else {
                      this.dietnight = nightquan + " " + nightmeasure + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " " + "\n" + nightcond + "\n"
                    }
                  } else if (nightcond != "or" && nightcond != "Select" && nightcond != undefined) {
                    if (this.dietnight != undefined) {
                      this.dietnight += nightquan + " " + nightmeasure + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " " + nightcond + " "
                    } else {
                      this.dietnight = nightquan + " " + nightmeasure + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " " + nightcond + " "
                    }
                  } else {
                    if (this.dietnight != undefined) {
                      this.dietnight += nightquan + " " + nightmeasure + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " "
                    } else {
                      this.dietnight = nightquan + " " + nightmeasure + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " "
                    }
                  }
                } else {
                  if (nightfoodsubtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (nightcond != undefined && nightcond == "or") {
                    if (this.dietnight != undefined) {
                      this.dietnight += nightquan + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " " + "\n" + nightcond + "\n"
                    } else {
                      this.dietnight = nightquan + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " " + "\n" + nightcond + "\n"
                    }
                  } else if (this.night_cond != "or" && this.night_cond != "Select" && this.night_cond != undefined) {
                    if (this.dietnight != undefined) {
                      this.dietnight += nightquan + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " " + nightcond + " "
                    } else {
                      this.dietnight = nightquan + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " " + nightcond + " "
                    }
                  } else {
                    if (this.dietnight != undefined) {
                      this.dietnight += nightquan + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " "
                    } else {
                      this.dietnight = nightquan + " " + obj.dplan_night[i].description + nohypen + nightfoodsubtype + " "
                    }
                  }
                }
              }else{
                this.emptyDay("Night");
              }
            }

            for (var i = 0; i < this.dietnightadd.length; i++) {
              if (this.dietnightadd.length == 0) {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietnightadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietnightadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              } else {
                if (this.col_dtaa == "0") {
                  this.col_dtaa = this.dietnightadd[i].energy;
                  this.set_col_data();
                } else {
                  var tot = parseFloat(this.col_dtaa) + parseFloat(this.dietnightadd[i].energy);
                  this.col_dtaa = tot;
                  this.set_col_data();
                }
              }
            }
          }
          this.send_data();
        },
        error => {}
      )
  }

  ColoriesRequiredFun() {
    if(Helper_Class.gethabitual() != undefined && Doc_Helper.getVitals().bmr != undefined) {
      if(Helper_Class.gethabitual().exercise_routine_des == "Light") {
        var get_txt = Doc_Helper.getVitals().bmr * 1.375;
        this.cal_txt = Math.round(get_txt).toString();
      }else if(Helper_Class.gethabitual().exercise_routine_des == "Moderate") {
        var get_txt = Doc_Helper.getVitals().bmr * 1.55;
        this.cal_txt = Math.round(get_txt).toString();
      }else if(Helper_Class.gethabitual().exercise_routine_des == "Heavy") {
        var get_txt = Doc_Helper.getVitals().bmr * 1.725;
        this.cal_txt = Math.round(get_txt).toString();
      }else if(Helper_Class.gethabitual().exercise_routine_des == "Very Heavy") {
        var get_txt = Doc_Helper.getVitals().bmr * 1.9;
        this.cal_txt = Math.round(get_txt).toString();
      }else{
        var get_txt = parseFloat(Doc_Helper.getVitals().bmr) * 1.375;
        this.cal_txt = Math.round(get_txt).toString();
      }
    }
  }

  clear_content(data) {
    if (data == "mor") {
      this.dietmorning = "";
      var morncal = 0;
      for (var i = 0; i < this.dietmoradd.length; i++) {
        if(this.day == this.dietmoradd[i].day){
          morncal = morncal + parseInt(this.dietmoradd[i].energy);
          this.dietmoradd.splice(i);
        }
      }
      var totcalval = 0;
      totcalval = parseInt(this.tot_cal) - morncal;
      this.tot_cal = totcalval.toString();
    } else if (data == "break") {
      this.dietbreakfast = "";
      var cal = 0;
      for (var i = 0; i < this.dietbreakfastadd.length; i++) {
        if(this.day == this.dietbreakfastadd[i].day){
          cal = cal + parseInt(this.dietbreakfastadd[i].energy);
          this.dietbreakfastadd.splice(i,1);
        }
      }
      var totcalval = 0;
      totcalval = parseInt(this.tot_cal) - cal;
      this.tot_cal = totcalval.toString();
    //  this.dietbreakfastadd = [];
    } else if (data == "midday") {
      this.dietmidday = "";
      var cal = 0;
      for (var i = 0; i < this.dietmiddayadd.length; i++) {
        if(this.day == this.dietmiddayadd[i].day){
          cal = cal + parseInt(this.dietmiddayadd[i].energy);
          this.dietmiddayadd.splice(i,1);
        }
      }
      var totcalval = 0;
      totcalval = parseInt(this.tot_cal) - cal;
      this.tot_cal = totcalval.toString();
     // this.dietmiddayadd = [];
    } else if (data == "lunch") {
      this.dietlunch = "";
      var cal = 0;
      for (var i = 0; i < this.dietlunchadd.length; i++) {
        if(this.day == this.dietlunchadd[i].day){
          cal = cal + parseInt(this.dietlunchadd[i].energy);
          this.dietlunchadd.splice(i,1);
        }
      }
      var totcalval = 0;
      totcalval = parseInt(this.tot_cal) - cal;
      this.tot_cal = totcalval.toString();
      //this.dietlunchadd = [];
    } else if (data == "eve") {
      this.dietevening = "";
      var cal = 0;
      for (var i = 0; i < this.dieteveadd.length; i++) {
        if(this.day == this.dieteveadd[i].day){
          cal = cal + parseInt(this.dieteveadd[i].energy);
          this.dieteveadd.splice(i,1);
        }
      }
      var totcalval = 0;
      totcalval = parseInt(this.tot_cal) - cal;
      this.tot_cal = totcalval.toString();
     // this.dieteveadd = [];
    } else if (data == "dinner") {
      this.dietdinner = "";
      var cal = 0;
      for (var i = 0; i < this.dietdinneradd.length; i++) {
        if(this.day == this.dietdinneradd[i].day){
          cal = cal + parseInt(this.dietdinneradd[i].energy);
          this.dietdinneradd.splice(i,1);
        }
      }
      var totcalval = 0;
      totcalval = parseInt(this.tot_cal) - cal;
      this.tot_cal = totcalval.toString();
     // this.dietdinneradd = [];
    } else if (data == "night") {
      this.dietnight = "";
      var cal = 0;
      for (var i = 0; i < this.dietnightadd.length; i++) {
        if(this.day == this.dietnightadd[i].day){
          cal = cal + parseInt(this.dietnightadd[i].energy);
          this.dietnightadd.splice(i,1);
        }
      }
      var totcalval = 0;
      totcalval = parseInt(this.tot_cal) - cal;
      this.tot_cal = totcalval.toString();
     // this.dietnightadd = [];
    }
    this.send_data();
  }

  addDietData(value, array,day) {
    var dietarray = array;
    var dispstring: string;
    for (var i = 0; i < dietarray.length; i++) {
      if(day == dietarray[i].day){
        if (dietarray[i].measure != "item(s)") {
          if (dietarray[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
          if (dietarray[i].cond != undefined && dietarray[i].cond == "or") {
            if (dispstring != undefined) {
              dispstring += dietarray[i].quantity + " " + dietarray[i].measure + "  " + dietarray[i].description + nohypen + dietarray[i].food_sub_type + " " + "\n" + dietarray[i].cond + "\n";
            } else {
              dispstring = dietarray[i].quantity + " " + dietarray[i].measure + "  " + dietarray[i].description + nohypen + dietarray[i].food_sub_type + " " + "\n" + dietarray[i].cond + "\n";
            }
          } else if (dietarray[i].cond != "or" && dietarray[i].cond != "Select" && dietarray[i].cond != undefined) {
            if (dispstring != undefined) {
              dispstring += dietarray[i].quantity + " " + dietarray[i].measure + " " + dietarray[i].description + nohypen + dietarray[i].food_sub_type + " " + dietarray[i].cond + " " ;
            } else {
              dispstring = dietarray[i].quantity + " " + dietarray[i].measure + " " + dietarray[i].description + nohypen + dietarray[i].food_sub_type + " " + dietarray[i].cond + " " ;
            }
          } else {
            if (dispstring != undefined) {
              dispstring += dietarray[i].quantity + " " + dietarray[i].measure + " " + dietarray[i].description + " " + dietarray[i].food_sub_type;
            } else {
              dispstring = dietarray[i].quantity + " " + dietarray[i].measure + " " + dietarray[i].description + " " + dietarray[i].food_sub_type;
            }
          }
        } else {
          if (dietarray[i].cond != undefined && dietarray[i].cond == "or") {
            if (dispstring != undefined) {
              dispstring += dietarray[i].quantity + " " + dietarray[i].description + nohypen + dietarray[i].food_sub_type + " " + "\n" + dietarray[i].cond + "\n";
            } else {
              dispstring = dietarray[i].quantity + " " + dietarray[i].description + nohypen + dietarray[i].food_sub_type + " " + "\n" + dietarray[i].cond + "\n";
            }
          } else if (dietarray[i].cond != "or" && dietarray[i].cond != "Select" && dietarray[i].cond != undefined) {
            if (dispstring != undefined) {
              dispstring += dietarray[i].quantity + " " + dietarray[i].description + nohypen + dietarray[i].food_sub_type + " " + dietarray[i].cond + " " ;
            } else {
              dispstring = dietarray[i].quantity + " " + dietarray[i].description + nohypen + dietarray[i].food_sub_type + " " + dietarray[i].cond + " " ;
            }
          } else {
            if (dispstring != undefined) {
              dispstring += dietarray[i].quantity + " " + dietarray[i].food_sub_type + nohypen + dietarray[i].description;
            } else {
              dispstring = dietarray[i].quantity + " " + dietarray[i].food_sub_type + nohypen + dietarray[i].description;
            }
          }
        }
      }
    }

    if (value == "mor") {
      this.dietmorning = dispstring;
      localStorage.setItem("morning_diet", this.dietmorning);

    } else if (value == "break") {
      this.dietbreakfast = dispstring;
      localStorage.setItem("break_diet", this.dietbreakfast);

    } else if (value == "midday") {
      this.dietmidday = dispstring;
      localStorage.setItem("midday_diet", this.dietmidday);
    } else if (value == "lunch") {
      this.dietlunch = dispstring;
      localStorage.setItem("lunch_diet", this.dietlunch);

    } else if (value == "eve") {
      this.dietevening = dispstring;
      localStorage.setItem("eve_diet", this.dietevening);

    } else if (value == "dinner") {
      this.dietdinner = dispstring;
      localStorage.setItem("dinner_diet", this.dietdinner);

    } else if (value == "night") {
      this.dietnight = dispstring;
      localStorage.setItem("night_diet", this.dietnight);
    }
  }

  //========================== add tables =========================
  edit_content(value) {
    var dietarray = [];
    if (value == "mor") {
      dietarray = this.dietmoradd;
    } else if (value == "midday") {
      dietarray = this.dietmiddayadd;
    } else if (value == "lunch") {
      dietarray = this.dietlunchadd;
    } else if (value == "break") {
      dietarray = this.dietbreakfastadd;
    } else if (value == "eve") {
      dietarray = this.dieteveadd;
    } else if (value == "dinner") {
      dietarray = this.dietdinneradd;
    } else if (value == "night") {
      dietarray = this.dietnightadd;
    }

    const dialogRef = this.dialog.open(DietplanEditComponent, {
      width: '70%',
      height: '500px',
      data: { dietlist: dietarray, cal_data: this.col_dtaa,day:this.day }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (value == "mor") {
          this.dietmoradd = [];
          this.dietmoradd = result.dietlist;
          this.addDietData('mor', result.dietlist,this.day);
        } else if (value == "midday") {
          this.dietmiddayadd = [];
          this.dietmiddayadd = result.dietlist;
          this.addDietData('midday', result.dietlist,this.day);
        } else if (value == "lunch") {
          this.dietlunchadd = [];
          this.dietlunchadd = result.dietlist;
          this.addDietData('lunch', result.dietlist,this.day);
        } else if (value == "break") {
          this.dietbreakfastadd = [];
          this.dietbreakfastadd = result.dietlist;
          this.addDietData('break', result.dietlist,this.day);
        } else if (value == "eve") {
          this.dieteveadd = [];
          this.dieteveadd = result.dietlist;
          this.addDietData('eve', result.dietlist,this.day);
        } else if (value == "dinner") {
          this.dietdinneradd = [];
          this.dietdinneradd = result.dietlist;
          this.addDietData('dinner', result.dietlist,this.day);
        } else if (value == "night") {
          this.dietnightadd = [];
          this.dietnightadd = result.dietlist;
          this.addDietData('night', result.dietlist,this.day);
        }
        this.col_dtaa = result.cal_data;
        this.set_col_data();
      }
    });
    this.send_data();
  }

  getMenu(){
    this.menu_mor_foodType = [];
    this.menu_bf_foodType = [];
    this.menu_mid_day_foodType = [];
    this.menu_lunch_foodType = [];
    this.menu_eve_foodType = [];
    this.menu_dinner_foodType = [];
    this.menu_night_foodType = [];

    var obj = masterCSData_Helper.getMasterFoodMenu();

    if(obj.menu_items != undefined && obj.menu_items != null && obj.menu_items != ""){
      var data = obj.menu_items;
      var menu = [];
      for(var i = 0; i < data.length; i++){
        menu.push({
          name : data[i].name,
          food_menu_id : data[i].food_menu_id,
          image : ipaddress.Ip_with_img_address + data[i].image,
          menu_items : data[i].menu_items,
        })
      }
      this.menu_mor_foodType = menu;
      this.menu_bf_foodType = menu;
      this.menu_mid_day_foodType = menu;
      this.menu_lunch_foodType = menu;
      this.menu_eve_foodType = menu;
      this.menu_dinner_foodType = menu;
      this.menu_night_foodType = menu;

      this.menu_mor_tpetxt = data[0].food_menu_id;
      this.menu_bf_tpetxt = data[0].food_menu_id;
      this.menu_mid_day_tpetxt = data[0].food_menu_id;
      this.menu_lunch_tpetxt = data[0].food_menu_id;
      this.menu_eve_tpetxt = data[0].food_menu_id;
      this.menu_dinner_tpetxt = data[0].food_menu_id;
      this.menu_night_tpetxt = data[0].food_menu_id;

      this.menu_change_food_type("Morning",data[0].food_menu_id);
      this.menu_change_food_type("Breakfast",data[0].food_menu_id);
      this.menu_change_food_type("Mid-day",data[0].food_menu_id);
      this.menu_change_food_type("Lunch",data[0].food_menu_id);
      this.menu_change_food_type("Evening",data[0].food_menu_id);
      this.menu_change_food_type("Dinner",data[0].food_menu_id);
      this.menu_change_food_type("Night",data[0].food_menu_id);
    }
  }

  selectMenu(id,data,flag){
    switch (id) {
      case 'Morning': 
        if(data == "menu"){
          this.mor_show_menu = flag == true ? true : false;
          this.mor_show_non_menu = this.mor_show_menu == true ? false : true;
          this.morning_option = data;
          this.morn_class = "col-12";
        }
        if(data == "non-menu"){
          this.mor_show_non_menu = flag == true ? true : false;
          this.mor_show_menu = this.mor_show_non_menu == true ? false : true;
          this.morning_option = data;
          this.morn_class = "col-6";
        }
        break;
      case 'Breakfast': 
        if(data == "menu"){
          this.bf_show_menu = flag == true ? true : false;
          this.bf_show_non_menu = this.bf_show_menu == true ? false : true;
          this.breakfast_option = data;
          this.bf_class = "col-12";
        }
        if(data == "non-menu"){
          this.bf_show_non_menu = flag == true ? true : false;
          this.bf_show_menu = this.bf_show_non_menu == true ? false : true;
          this.breakfast_option = data;
          this.bf_class = "col-6";
        }
        break;
      case 'Mid-day': 
        if(data == "menu"){
          this.mid_day_show_menu = flag == true ? true : false;
          this.mid_day_show_non_menu = this.mid_day_show_menu == true ? false : true;
          this.mid_day_option = data;
          this.mid_day_class = "col-12";
        }
        if(data == "non-menu"){
          this.mid_day_show_non_menu = flag == true ? true : false;
          this.mid_day_show_menu = this.mid_day_show_non_menu == true ? false : true;
          this.mid_day_option = data;
          this.mid_day_class = "col-6";
        }
        break;
      case 'Lunch': 
        if(data == "menu"){
          this.lunch_show_menu = flag == true ? true : false;
          this.lunch_show_non_menu = this.lunch_show_menu == true ? false : true;
          this.lunch_option = data;
          this.lunch_class = "col-12";
        }
        if(data == "non-menu"){
          this.lunch_show_non_menu = flag == true ? true : false;
          this.lunch_show_menu = this.lunch_show_non_menu == true ? false : true;
          this.lunch_option = data;
          this.lunch_class = "col-6";
        }
        break;
      case 'Evening': 
        if(data == "menu"){
          this.eve_show_menu = flag == true ? true : false;
          this.eve_show_non_menu = this.eve_show_menu == true ? false : true;
          this.eve_option = data;
          this.eve_class = "col-12";
        }
        if(data == "non-menu"){
          this.eve_show_non_menu = flag == true ? true : false;
          this.eve_show_menu = this.eve_show_non_menu == true ? false : true;
          this.eve_option = data;
          this.eve_class = "col-6";
        }
        break;
      case 'Dinner': 
        if(data == "menu"){
          this.dinner_show_menu = flag == true ? true : false;
          this.dinner_show_non_menu = this.dinner_show_menu == true ? false : true;
          this.dinner_option = data;
          this.dinner_class = "col-12";
        }
        if(data == "non-menu"){
          this.dinner_show_non_menu = flag == true ? true : false;
          this.dinner_show_menu = this.dinner_show_non_menu == true ? false : true;
          this.dinner_option = data;
          this.dinner_class = "col-6";
        }
        break;
      case 'Night': 
        if(data == "menu"){
          this.night_show_menu = flag == true ? true : false;
          this.night_show_non_menu = this.night_show_menu == true ? false : true;
          this.night_option = data;
          this.night_class = "col-12";
        }
        if(data == "non-menu"){
          this.night_show_non_menu = flag == true ? true : false;
          this.night_show_menu = this.night_show_non_menu == true ? false : true;
          this.night_option = data;
          this.night_class = "col-6";
        }
        break;
    }
  }

  menu_change_food_type(id,food_id){
    switch (id) {
      case 'Morning': 
        this.menu_mor_subfoodType =[];
        this.mor_energy = undefined;
        this.mor_protein = undefined;
        this.mor_carbohydrates = undefined;
        this.mor_vitA = undefined;
        this.mor_fiber = undefined;
        this.mor_fat = undefined;
        for(var i = 0; i < this.menu_mor_foodType.length; i++){
          if(this.menu_mor_foodType[i].food_menu_id == food_id){
            var submenu = this.menu_mor_foodType[i].menu_items;
            for(var j = 0; j < submenu.length ; j++){
              this.menu_mor_subfoodType.push({
                main_food:this.menu_mor_foodType[i].name,
                main_food_menu_id:this.menu_mor_foodType[i].main_food_menu_id,
                food_img:this.menu_mor_foodType[i].image,
                food_item_id:submenu[j].food_item_id,
                food_item	:	submenu[j].food_item,
                food_type_id	:	submenu[j].food_type_id,
                food_sub_type	:	submenu[j].food_sub_type,
                quantity	:	submenu[j].quantity,
                measure	:	submenu[j].measure,
                weight	:	submenu[j].weight,
                energy	:	submenu[j].energy,
                proteins	:	submenu[j].proteins,
                carbohydrates	:	submenu[j].carbohydrates,
                fiber	:	submenu[j].fiber,
                fat	:	submenu[j].fat,
                vitaminA	:	submenu[j].vitaminA,
                checked:true,
              });
              this.mor_energy = this.addData(this.mor_energy,submenu[j].energy);
              this.mor_protein = this.addData(this.mor_protein,submenu[j].proteins);
              this.mor_carbohydrates = this.addData(this.mor_carbohydrates,submenu[j].carbohydrates);
              this.mor_vitA = this.addData(this.mor_vitA,submenu[j].vitA);
              this.mor_fiber = this.addData(this.mor_fiber,submenu[j].fiber);
              this.mor_fat = this.addData(this.mor_fat,submenu[j].fat);
            }
            this.mor_food_image = this.menu_mor_foodType[i].image;
          }
        }
        break;
      case 'Breakfast': 
        this.menu_bf_subfoodType =[];
        this.bf_energy = undefined;
        this.bf_protein = undefined;
        this.bf_carbohydrates = undefined;
        this.bf_vitA = undefined;
        this.bf_fiber = undefined;
        this.bf_fat = undefined;
        for(var i = 0; i < this.menu_bf_foodType.length; i++){
          if(this.menu_bf_foodType[i].food_menu_id == food_id){
            var submenu = this.menu_bf_foodType[i].menu_items;
            for(var j = 0; j < submenu.length ; j++){
              this.menu_bf_subfoodType.push({
                main_food:this.menu_bf_foodType[i].name,
                main_food_menu_id:this.menu_bf_foodType[i].main_food_menu_id,
                food_img:this.menu_bf_foodType[i].image,
                food_item_id:submenu[j].food_item_id,
                food_item	:	submenu[j].food_item,
                food_type_id	:	submenu[j].food_type_id,
                food_sub_type	:	submenu[j].food_sub_type,
                quantity	:	submenu[j].quantity,
                measure	:	submenu[j].measure,
                weight	:	submenu[j].weight,
                energy	:	submenu[j].energy,
                proteins	:	submenu[j].proteins,
                carbohydrates	:	submenu[j].carbohydrates,
                fiber	:	submenu[j].fiber,
                fat	:	submenu[j].fat,
                vitaminA	:	submenu[j].vitaminA,
                checked:true,
              });
              this.bf_energy = this.addData(this.bf_energy,submenu[j].energy);
              this.bf_protein = this.addData(this.bf_protein,submenu[j].proteins);
              this.bf_carbohydrates = this.addData(this.bf_carbohydrates,submenu[j].carbohydrates);
              this.bf_vitA = this.addData(this.bf_vitA,submenu[j].vitA);
              this.bf_fiber = this.addData(this.bf_fiber,submenu[j].fiber);
              this.bf_fat = this.addData(this.bf_fat,submenu[j].fat);
            }
            this.bf_food_image = this.menu_bf_foodType[i].image;
          }
        }
        break;
      case 'Mid-day': 
        this.menu_mid_day_subfoodType =[];
        this.mid_day_energy = undefined;
        this.mid_day_protein = undefined;
        this.mid_day_carbohydrates = undefined;
        this.mid_day_vitA = undefined;
        this.mid_day_fiber = undefined;
        this.mid_day_fat = undefined;
        for(var i = 0; i < this.menu_mid_day_foodType.length; i++){
          if(this.menu_mid_day_foodType[i].food_menu_id == food_id){
            var submenu = this.menu_mid_day_foodType[i].menu_items;
            for(var j = 0; j < submenu.length ; j++){
              this.menu_mid_day_subfoodType.push({
                main_food:this.menu_mid_day_foodType[i].name,
                main_food_menu_id:this.menu_mid_day_foodType[i].main_food_menu_id,
                food_img:this.menu_mid_day_foodType[i].image,
                food_item_id:submenu[j].food_item_id,
                food_item	:	submenu[j].food_item,
                food_type_id	:	submenu[j].food_type_id,
                food_sub_type	:	submenu[j].food_sub_type,
                quantity	:	submenu[j].quantity,
                measure	:	submenu[j].measure,
                weight	:	submenu[j].weight,
                energy	:	submenu[j].energy,
                proteins	:	submenu[j].proteins,
                carbohydrates	:	submenu[j].carbohydrates,
                fiber	:	submenu[j].fiber,
                fat	:	submenu[j].fat,
                vitaminA	:	submenu[j].vitaminA,
                checked:true,
              });
              this.mid_day_energy = this.addData(this.mid_day_energy,submenu[j].energy);
              this.mid_day_protein = this.addData(this.mid_day_protein,submenu[j].proteins);
              this.mid_day_carbohydrates = this.addData(this.mid_day_carbohydrates,submenu[j].carbohydrates);
              this.mid_day_vitA = this.addData(this.mid_day_vitA,submenu[j].vitA);
              this.mid_day_fiber = this.addData(this.mid_day_fiber,submenu[j].fiber);
              this.mid_day_fat = this.addData(this.mid_day_fat,submenu[j].fat);
            }
            this.mid_day_food_image = this.menu_mid_day_foodType[i].image;
          }
        }
        break;
      case 'Lunch': 
        this.menu_lunch_subfoodType =[];
        this.lunch_energy = undefined;
        this.lunch_protein = undefined;
        this.lunch_carbohydrates = undefined;
        this.lunch_vitA = undefined;
        this.lunch_fiber = undefined;
        this.lunch_fat = undefined;
        for(var i = 0; i < this.menu_lunch_foodType.length; i++){
          if(this.menu_lunch_foodType[i].food_menu_id == food_id){
            var submenu = this.menu_lunch_foodType[i].menu_items;
            for(var j = 0; j < submenu.length ; j++){
              this.menu_lunch_subfoodType.push({
                main_food:this.menu_lunch_foodType[i].name,
                main_food_menu_id:this.menu_lunch_foodType[i].main_food_menu_id,
                food_img:this.menu_lunch_foodType[i].image,
                food_item_id:submenu[j].food_item_id,
                food_item	:	submenu[j].food_item,
                food_type_id	:	submenu[j].food_type_id,
                food_sub_type	:	submenu[j].food_sub_type,
                quantity	:	submenu[j].quantity,
                measure	:	submenu[j].measure,
                weight	:	submenu[j].weight,
                energy	:	submenu[j].energy,
                proteins	:	submenu[j].proteins,
                carbohydrates	:	submenu[j].carbohydrates,
                fiber	:	submenu[j].fiber,
                fat	:	submenu[j].fat,
                vitaminA	:	submenu[j].vitaminA,
                checked:true,
              });
              this.lunch_energy = this.addData(this.lunch_energy,submenu[j].energy);
              this.lunch_protein = this.addData(this.lunch_protein,submenu[j].proteins);
              this.lunch_carbohydrates = this.addData(this.lunch_carbohydrates,submenu[j].carbohydrates);
              this.lunch_vitA = this.addData(this.lunch_vitA,submenu[j].vitA);
              this.lunch_fiber = this.addData(this.lunch_fiber,submenu[j].fiber);
              this.lunch_fat = this.addData(this.lunch_fat,submenu[j].fat);
            }
            this.lunch_food_image = this.menu_lunch_foodType[i].image;
          }
        }
        break;
      case 'Evening': 
        this.menu_eve_subfoodType =[];
        this.eve_energy = undefined;
        this.eve_protein = undefined;
        this.eve_carbohydrates = undefined;
        this.eve_vitA = undefined;
        this.eve_fiber = undefined;
        this.eve_fat = undefined;
        for(var i = 0; i < this.menu_eve_foodType.length; i++){
          if(this.menu_eve_foodType[i].food_menu_id == food_id){
            var submenu = this.menu_eve_foodType[i].menu_items;
            for(var j = 0; j < submenu.length ; j++){
              this.menu_eve_subfoodType.push({
                main_food:this.menu_eve_foodType[i].name,
                main_food_menu_id:this.menu_eve_foodType[i].main_food_menu_id,
                food_img:this.menu_eve_foodType[i].image,
                food_item_id:submenu[j].food_item_id,
                food_item	:	submenu[j].food_item,
                food_type_id	:	submenu[j].food_type_id,
                food_sub_type	:	submenu[j].food_sub_type,
                quantity	:	submenu[j].quantity,
                measure	:	submenu[j].measure,
                weight	:	submenu[j].weight,
                energy	:	submenu[j].energy,
                proteins	:	submenu[j].proteins,
                carbohydrates	:	submenu[j].carbohydrates,
                fiber	:	submenu[j].fiber,
                fat	:	submenu[j].fat,
                vitaminA	:	submenu[j].vitaminA,
                checked:true,
              });
              this.eve_energy = this.addData(this.eve_energy,submenu[j].energy);
              this.eve_protein = this.addData(this.eve_protein,submenu[j].proteins);
              this.eve_carbohydrates = this.addData(this.eve_carbohydrates,submenu[j].carbohydrates);
              this.eve_vitA = this.addData(this.eve_vitA,submenu[j].vitA);
              this.eve_fiber = this.addData(this.eve_fiber,submenu[j].fiber);
              this.eve_fat = this.addData(this.eve_fat,submenu[j].fat);
            }
            this.eve_food_image = this.menu_eve_foodType[i].image;
          }
        }
        break;
      case 'Dinner': 
        this.menu_dinner_subfoodType =[];
        this.dinner_energy = undefined;
        this.dinner_protein = undefined;
        this.dinner_carbohydrates = undefined;
        this.dinner_vitA = undefined;
        this.dinner_fiber = undefined;
        this.dinner_fat = undefined;
        for(var i = 0; i < this.menu_dinner_foodType.length; i++){
          if(this.menu_dinner_foodType[i].food_menu_id == food_id){
            var submenu = this.menu_dinner_foodType[i].menu_items;
            for(var j = 0; j < submenu.length ; j++){
              this.menu_dinner_subfoodType.push({
                main_food:this.menu_dinner_foodType[i].name,
                main_food_menu_id:this.menu_dinner_foodType[i].main_food_menu_id,
                food_img:this.menu_dinner_foodType[i].image,
                food_item_id:submenu[j].food_item_id,
                food_item	:	submenu[j].food_item,
                food_type_id	:	submenu[j].food_type_id,
                food_sub_type	:	submenu[j].food_sub_type,
                quantity	:	submenu[j].quantity,
                measure	:	submenu[j].measure,
                weight	:	submenu[j].weight,
                energy	:	submenu[j].energy,
                proteins	:	submenu[j].proteins,
                carbohydrates	:	submenu[j].carbohydrates,
                fiber	:	submenu[j].fiber,
                fat	:	submenu[j].fat,
                vitaminA	:	submenu[j].vitaminA,
                checked:true,
              });
              this.dinner_energy = this.addData(this.dinner_energy,submenu[j].energy);
              this.dinner_protein = this.addData(this.dinner_protein,submenu[j].proteins);
              this.dinner_carbohydrates = this.addData(this.dinner_carbohydrates,submenu[j].carbohydrates);
              this.dinner_vitA = this.addData(this.dinner_vitA,submenu[j].vitA);
              this.dinner_fiber = this.addData(this.dinner_fiber,submenu[j].fiber);
              this.dinner_fat = this.addData(this.dinner_fat,submenu[j].fat);
            }
            this.dinner_food_image = this.menu_dinner_foodType[i].image;
          }
        }
        break;
      case 'Night': 
        this.menu_night_subfoodType =[];
        this.night_energy = undefined;
        this.night_protein = undefined;
        this.night_carbohydrates = undefined;
        this.night_vitA = undefined;
        this.night_fiber = undefined;
        this.night_fat = undefined;
        for(var i = 0; i < this.menu_night_foodType.length; i++){
          if(this.menu_night_foodType[i].food_menu_id == food_id){
            var submenu = this.menu_night_foodType[i].menu_items;
            for(var j = 0; j < submenu.length ; j++){
              this.menu_night_subfoodType.push({
                main_food:this.menu_night_foodType[i].name,
                main_food_menu_id:this.menu_night_foodType[i].main_food_menu_id,
                food_img:this.menu_night_foodType[i].image,
                food_item_id:submenu[j].food_item_id,
                food_item	:	submenu[j].food_item,
                food_type_id	:	submenu[j].food_type_id,
                food_sub_type	:	submenu[j].food_sub_type,
                quantity	:	submenu[j].quantity,
                measure	:	submenu[j].measure,
                weight	:	submenu[j].weight,
                energy	:	submenu[j].energy,
                proteins	:	submenu[j].proteins,
                carbohydrates	:	submenu[j].carbohydrates,
                fiber	:	submenu[j].fiber,
                fat	:	submenu[j].fat,
                vitaminA	:	submenu[j].vitaminA,
                checked:true,
              });
              this.night_energy = this.addData(this.night_energy,submenu[j].energy);
              this.night_protein = this.addData(this.night_protein,submenu[j].proteins);
              this.night_carbohydrates = this.addData(this.night_carbohydrates,submenu[j].carbohydrates);
              this.night_vitA = this.addData(this.night_vitA,submenu[j].vitA);
              this.night_fiber = this.addData(this.night_fiber,submenu[j].fiber);
              this.night_fat = this.addData(this.night_fat,submenu[j].fat);
            }
            this.night_food_image = this.menu_night_foodType[i].image;
          }
        }
        break;
    }
  }

  menu_change_sub_food_type(id,subfood,flag){
    switch (id) {
      case 'Morning': 
        for(var j = 0; j < this.menu_mor_subfoodType.length ; j++){
          if(this.menu_mor_subfoodType[j].food_item_id == subfood.food_item_id){
            if(flag == false){
              this.menu_mor_subfoodType [j].checked = flag;
              this.mor_energy = this.deleteData(this.mor_energy,this.menu_mor_subfoodType[j].energy);
              this.mor_protein = this.deleteData(this.mor_protein,this.menu_mor_subfoodType[j].proteins);
              this.mor_carbohydrates = this.deleteData(this.mor_carbohydrates,this.menu_mor_subfoodType[j].carbohydrates);
              this.mor_vitA = this.deleteData(this.mor_vitA,this.menu_mor_subfoodType[j].vitA);
              this.mor_fiber = this.deleteData(this.mor_fiber,this.menu_mor_subfoodType[j].fiber);
              this.mor_fat = this.deleteData(this.mor_fat,this.menu_mor_subfoodType[j].fat);
            }else{
              this.menu_mor_subfoodType [j].checked = flag;
              this.mor_energy = this.addData(this.mor_energy,this.menu_mor_subfoodType[j].energy);
              this.mor_protein = this.addData(this.mor_protein,this.menu_mor_subfoodType[j].proteins);
              this.mor_carbohydrates = this.addData(this.mor_carbohydrates,this.menu_mor_subfoodType[j].carbohydrates);
              this.mor_vitA = this.addData(this.mor_vitA,this.menu_mor_subfoodType[j].vitA);
              this.mor_fiber = this.addData(this.mor_fiber,this.menu_mor_subfoodType[j].fiber);
              this.mor_fat = this.addData(this.mor_fat,this.menu_mor_subfoodType[j].fat);
            }
          }
    
        }
        break;
      case 'Breakfast': 
        for(var j = 0; j < this.menu_bf_subfoodType.length ; j++){
          if(this.menu_bf_subfoodType[j].food_item_id == subfood.food_item_id){
            if(flag == false){
              this.menu_bf_subfoodType [j].checked = flag;
              this.bf_energy = this.deleteData(this.bf_energy,this.menu_bf_subfoodType[j].energy);
              this.bf_protein = this.deleteData(this.bf_protein,this.menu_bf_subfoodType[j].proteins);
              this.bf_carbohydrates = this.deleteData(this.bf_carbohydrates,this.menu_bf_subfoodType[j].carbohydrates);
              this.bf_vitA = this.deleteData(this.bf_vitA,this.menu_bf_subfoodType[j].vitA);
              this.bf_fiber = this.deleteData(this.bf_fiber,this.menu_bf_subfoodType[j].fiber);
              this.bf_fat = this.deleteData(this.bf_fat,this.menu_bf_subfoodType[j].fat);
            }else{
              this.menu_bf_subfoodType [j].checked = flag;
              this.bf_energy = this.addData(this.bf_energy,this.menu_bf_subfoodType[j].energy);
              this.bf_protein = this.addData(this.bf_protein,this.menu_bf_subfoodType[j].proteins);
              this.bf_carbohydrates = this.addData(this.bf_carbohydrates,this.menu_bf_subfoodType[j].carbohydrates);
              this.bf_vitA = this.addData(this.bf_vitA,this.menu_bf_subfoodType[j].vitA);
              this.bf_fiber = this.addData(this.bf_fiber,this.menu_bf_subfoodType[j].fiber);
              this.bf_fat = this.addData(this.bf_fat,this.menu_bf_subfoodType[j].fat);
            }
          }
        }
        break;
      case 'Mid-day': 
        for(var j = 0; j < this.menu_mid_day_subfoodType.length ; j++){
          if(this.menu_mid_day_subfoodType[j].food_item_id == subfood.food_item_id){
            if(flag == false){
              this.menu_mid_day_subfoodType [j].checked = flag;
              this.mid_day_energy = this.deleteData(this.mid_day_energy,this.menu_mid_day_subfoodType[j].energy);
              this.mid_day_protein = this.deleteData(this.mid_day_protein,this.menu_mid_day_subfoodType[j].proteins);
              this.mid_day_carbohydrates = this.deleteData(this.mid_day_carbohydrates,this.menu_mid_day_subfoodType[j].carbohydrates);
              this.mid_day_vitA = this.deleteData(this.mid_day_vitA,this.menu_mid_day_subfoodType[j].vitA);
              this.mid_day_fiber = this.deleteData(this.mid_day_fiber,this.menu_mid_day_subfoodType[j].fiber);
              this.mid_day_fat = this.deleteData(this.mid_day_fat,this.menu_mid_day_subfoodType[j].fat);
            }else{
              this.menu_mid_day_subfoodType [j].checked = flag;
              this.mid_day_energy = this.addData(this.mid_day_energy,this.menu_mid_day_subfoodType[j].energy);
              this.mid_day_protein = this.addData(this.mid_day_protein,this.menu_mid_day_subfoodType[j].proteins);
              this.mid_day_carbohydrates = this.addData(this.mid_day_carbohydrates,this.menu_mid_day_subfoodType[j].carbohydrates);
              this.mid_day_vitA = this.addData(this.mid_day_vitA,this.menu_mid_day_subfoodType[j].vitA);
              this.mid_day_fiber = this.addData(this.mid_day_fiber,this.menu_mid_day_subfoodType[j].fiber);
              this.mid_day_fat = this.addData(this.mid_day_fat,this.menu_mid_day_subfoodType[j].fat);
            }
          }
    
        }
        break;
      case 'Lunch': 
        for(var j = 0; j < this.menu_lunch_subfoodType.length ; j++){
          if(this.menu_lunch_subfoodType[j].food_item_id == subfood.food_item_id){
            if(flag == false){
              this.menu_lunch_subfoodType [j].checked = flag;
              this.lunch_energy = this.deleteData(this.lunch_energy,this.menu_lunch_subfoodType[j].energy);
              this.lunch_protein = this.deleteData(this.lunch_protein,this.menu_lunch_subfoodType[j].proteins);
              this.lunch_carbohydrates = this.deleteData(this.lunch_carbohydrates,this.menu_lunch_subfoodType[j].carbohydrates);
              this.lunch_vitA = this.deleteData(this.lunch_vitA,this.menu_lunch_subfoodType[j].vitA);
              this.lunch_fiber = this.deleteData(this.lunch_fiber,this.menu_lunch_subfoodType[j].fiber);
              this.lunch_fat = this.deleteData(this.lunch_fat,this.menu_lunch_subfoodType[j].fat);
            }else{
              this.menu_lunch_subfoodType [j].checked = flag;
              this.lunch_energy = this.addData(this.lunch_energy,this.menu_lunch_subfoodType[j].energy);
              this.lunch_protein = this.addData(this.lunch_protein,this.menu_lunch_subfoodType[j].proteins);
              this.lunch_carbohydrates = this.addData(this.lunch_carbohydrates,this.menu_lunch_subfoodType[j].carbohydrates);
              this.lunch_vitA = this.addData(this.lunch_vitA,this.menu_lunch_subfoodType[j].vitA);
              this.lunch_fiber = this.addData(this.lunch_fiber,this.menu_lunch_subfoodType[j].fiber);
              this.lunch_fat = this.addData(this.lunch_fat,this.menu_lunch_subfoodType[j].fat);
            }
          }
    
        }
        break;
      case 'Evening': 
        for(var j = 0; j < this.menu_eve_subfoodType.length ; j++){
          if(this.menu_eve_subfoodType[j].food_item_id == subfood.food_item_id){
            if(flag == false){
              this.menu_eve_subfoodType [j].checked = flag;
              this.eve_energy = this.deleteData(this.eve_energy,this.menu_eve_subfoodType[j].energy);
              this.eve_protein = this.deleteData(this.eve_protein,this.menu_eve_subfoodType[j].proteins);
              this.eve_carbohydrates = this.deleteData(this.eve_carbohydrates,this.menu_eve_subfoodType[j].carbohydrates);
              this.eve_vitA = this.deleteData(this.eve_vitA,this.menu_eve_subfoodType[j].vitA);
              this.eve_fiber = this.deleteData(this.eve_fiber,this.menu_eve_subfoodType[j].fiber);
              this.eve_fat = this.deleteData(this.eve_fat,this.menu_eve_subfoodType[j].fat);
            }else{
              this.menu_eve_subfoodType [j].checked = flag;
              this.eve_energy = this.addData(this.eve_energy,this.menu_eve_subfoodType[j].energy);
              this.eve_protein = this.addData(this.eve_protein,this.menu_eve_subfoodType[j].proteins);
              this.eve_carbohydrates = this.addData(this.eve_carbohydrates,this.menu_eve_subfoodType[j].carbohydrates);
              this.eve_vitA = this.addData(this.eve_vitA,this.menu_eve_subfoodType[j].vitA);
              this.eve_fiber = this.addData(this.eve_fiber,this.menu_eve_subfoodType[j].fiber);
              this.eve_fat = this.addData(this.eve_fat,this.menu_eve_subfoodType[j].fat);
            }
          }
    
        }
        break;
      case 'Dinner': 
        for(var j = 0; j < this.menu_dinner_subfoodType.length ; j++){
          if(this.menu_dinner_subfoodType[j].food_item_id == subfood.food_item_id){
            if(flag == false){
              this.menu_dinner_subfoodType [j].checked = flag;
              this.dinner_energy = this.deleteData(this.dinner_energy,this.menu_dinner_subfoodType[j].energy);
              this.dinner_protein = this.deleteData(this.dinner_protein,this.menu_dinner_subfoodType[j].proteins);
              this.dinner_carbohydrates = this.deleteData(this.dinner_carbohydrates,this.menu_dinner_subfoodType[j].carbohydrates);
              this.dinner_vitA = this.deleteData(this.dinner_vitA,this.menu_dinner_subfoodType[j].vitA);
              this.dinner_fiber = this.deleteData(this.dinner_fiber,this.menu_dinner_subfoodType[j].fiber);
              this.dinner_fat = this.deleteData(this.dinner_fat,this.menu_dinner_subfoodType[j].fat);
            }else{
              this.menu_dinner_subfoodType [j].checked = flag;
              this.dinner_energy = this.addData(this.dinner_energy,this.menu_dinner_subfoodType[j].energy);
              this.dinner_protein = this.addData(this.dinner_protein,this.menu_dinner_subfoodType[j].proteins);
              this.dinner_carbohydrates = this.addData(this.dinner_carbohydrates,this.menu_dinner_subfoodType[j].carbohydrates);
              this.dinner_vitA = this.addData(this.dinner_vitA,this.menu_dinner_subfoodType[j].vitA);
              this.dinner_fiber = this.addData(this.dinner_fiber,this.menu_dinner_subfoodType[j].fiber);
              this.dinner_fat = this.addData(this.dinner_fat,this.menu_dinner_subfoodType[j].fat);
            }
          }
    
        }
        break;
      case 'Night': 
        for(var j = 0; j < this.menu_night_subfoodType.length ; j++){
          if(this.menu_night_subfoodType[j].food_item_id == subfood.food_item_id){
            if(flag == false){
              this.menu_night_subfoodType [j].checked = flag;
              this.night_energy = this.deleteData(this.night_energy,this.menu_night_subfoodType[j].energy);
              this.night_protein = this.deleteData(this.night_protein,this.menu_night_subfoodType[j].proteins);
              this.night_carbohydrates = this.deleteData(this.night_carbohydrates,this.menu_night_subfoodType[j].carbohydrates);
              this.night_vitA = this.deleteData(this.night_vitA,this.menu_night_subfoodType[j].carbohydrates);
              this.night_fiber = this.deleteData(this.night_fiber,this.menu_night_subfoodType[j].carbohydrates);
              this.night_fat = this.deleteData(this.night_fat,this.menu_night_subfoodType[j].carbohydrates);
            }else{
              this.menu_night_subfoodType [j].checked = flag;
              this.night_energy = this.addData(this.night_energy,this.menu_night_subfoodType[j].energy);
              this.night_protein = this.addData(this.night_protein,this.menu_night_subfoodType[j].proteins);
              this.night_carbohydrates = this.addData(this.night_carbohydrates,this.menu_night_subfoodType[j].carbohydrates);
              this.night_vitA = this.addData(this.night_vitA,this.menu_night_subfoodType[j].vitA);
              this.night_fiber = this.addData(this.night_fiber,this.menu_night_subfoodType[j].fiber);
              this.night_fat = this.addData(this.night_fat,this.menu_night_subfoodType[j].fat);
            }
          }
    
        }
        break;
    }
  }

  addData(value,data){
    if(value != undefined && data != undefined){
      value = parseInt(value) + parseInt(data);
    }else{
      value = parseInt(data);
    }
    return value;
  }

  deleteData(value,data){
    if(value != undefined && data != undefined){
      value = parseInt(value) - parseInt(data);
    }else{
      value = parseInt(data);
    }
    return value;
  }

  menu_diet_Add(id){
    switch (id) {
      case 'Morning': 
        var flag = true;
        if (this.dietmoradd.length != 0) {
          for (var i = 0; i < this.dietmoradd.length; i++) {
            for(var j = 0; j < this.menu_mor_subfoodType.length; j++){
              if (this.dietmoradd[i].food_sub_type == this.menu_mor_subfoodType[j].food_item) {
                if(this.dietmoradd[i].day == this.day){
                  for(var k = (this.dietmoradd.length - 1); k < this.dietmoradd.length; k++){
                    if(this.dietmoradd[k].cond != "or"){
                      this.menu_mor_subfoodType[j].checked = false;
                      this.toastr.error(this.menu_mor_subfoodType[j].food_item + "already exits");
                    }
                  }
                }
              }
            }
          }
        }
    
        if(flag == true){
          for(var i = 0; i < this.menu_mor_subfoodType.length; i++){
            if(this.menu_mor_subfoodType[i].checked == true){
              this.dietmoradd.push({
                category: this.menu_mor_subfoodType[i].main_food,
                food_sub_type: this.menu_mor_subfoodType[i].food_item,
                description: this.menu_mor_subfoodType[i].food_sub_type,
                quantity: this.menu_mor_subfoodType[i].quantity,
                measure: this.menu_mor_subfoodType[i].measure,
                weight: this.menu_mor_subfoodType[i].weight,
                energy: this.menu_mor_subfoodType[i].energy,
                food_item_id: this.menu_mor_subfoodType[i].food_item_id,
                cond: "",
                day:this.day,
              });
      
              if (this.menu_mor_subfoodType[i].measure != "item(s)") {
                if (this.menu_mor_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (this.dietmorning != undefined) {
                    this.dietmorning += this.menu_mor_subfoodType[i].quantity + " " + this.menu_mor_subfoodType[i].measure + " "  + nohypen + this.menu_mor_subfoodType[i].food_item + nohypen + this.menu_mor_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietmorning =+ this.menu_mor_subfoodType[i].quantity + " " + this.menu_mor_subfoodType[i].measure + " " + nohypen + this.menu_mor_subfoodType[i].food_item + nohypen + this.menu_mor_subfoodType[i].food_sub_type + ", ";
                  }
              }else {
                  if (this.menu_mor_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
            
                  if (this.dietmorning != undefined) {
                    this.dietmorning += this.menu_mor_subfoodType[i].quantity + " " + nohypen + this.menu_mor_subfoodType[i].food_item + nohypen +this.menu_mor_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietmorning = this.menu_mor_subfoodType[i].quantity + " " + nohypen + this.menu_mor_subfoodType[i].food_item + nohypen + this.menu_mor_subfoodType[i].food_sub_type + ", ";
                  }
              }
            }
          }
      
          if (this.menu_mor_cond != undefined && this.menu_mor_cond == "or") {
            this.dietmorning = this.dietmorning +"\n" + this.menu_mor_cond + "\n";
          }else if (this.menu_mor_cond != "or" && this.menu_mor_cond != "Select" && this.menu_mor_cond != undefined) {
            this.dietmorning = this.dietmorning + " " + this.menu_mor_cond + " ";
          }
      
          for(var i = (this.dietmoradd.length - 1); i < this.dietmoradd.length; i++){
            this.dietmoradd[i].cond = this.menu_mor_cond;
          }

          if (this.dietmoradd.length != 0) {
            if (this.col_dtaa == "0") {
              this.col_dtaa = parseInt(this.mor_energy);
              this.set_col_data();
            } else {
              var tot = parseFloat(this.col_dtaa) + parseFloat(this.mor_energy);
              this.col_dtaa = tot.toString();
              this.set_col_data();
            }
          }
        }
        break;
      case 'Breakfast': 
        var flag = true;
        if (this.dietbreakfastadd.length != 0) {
          for (var i = 0; i < this.dietbreakfastadd.length; i++) {
            for(var j = 0; j < this.menu_bf_subfoodType.length; j++){
              if (this.dietbreakfastadd[i].food_sub_type == this.menu_bf_subfoodType[j].food_item) {
                if(this.dietbreakfastadd[i].day == this.day){
                  for(var k = (this.dietbreakfastadd.length - 1); k < this.dietbreakfastadd.length; k++){
                    if(this.dietbreakfastadd[k].cond != "or"){
                      this.menu_bf_subfoodType[j].checked = false;
                      this.toastr.error(this.menu_bf_subfoodType[j].food_item + "already exits");
                    }
                  }
                }
              }
            }
          }
        }
    
        if(flag == true){
          for(var i = 0; i < this.menu_bf_subfoodType.length; i++){
            if(this.menu_bf_subfoodType[i].checked == true){
              this.dietbreakfastadd.push({
                category: this.menu_bf_subfoodType[i].main_food,
                food_sub_type: this.menu_bf_subfoodType[i].food_item,
                description: this.menu_bf_subfoodType[i].food_sub_type,
                quantity: this.menu_bf_subfoodType[i].quantity,
                measure: this.menu_bf_subfoodType[i].measure,
                weight: this.menu_bf_subfoodType[i].weight,
                energy: this.menu_bf_subfoodType[i].energy,
                food_item_id: this.menu_bf_subfoodType[i].food_item_id,
                cond: "",
                day:this.day,
              });
    
              if (this.menu_bf_subfoodType[i].measure != "item(s)") {
                if (this.menu_bf_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += this.menu_bf_subfoodType[i].quantity + " " + this.menu_bf_subfoodType[i].measure + " "  + nohypen + this.menu_bf_subfoodType[i].food_item + nohypen + this.menu_bf_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietbreakfast =+ this.menu_bf_subfoodType[i].quantity + " " + this.menu_bf_subfoodType[i].measure + " " + nohypen + this.menu_bf_subfoodType[i].food_item + nohypen + this.menu_bf_subfoodType[i].food_sub_type + ", ";
                  }
        
              }else {
                  if (this.menu_bf_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
            
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += this.menu_bf_subfoodType[i].quantity + " " + nohypen + this.menu_bf_subfoodType[i].food_item + nohypen +this.menu_bf_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietbreakfast = this.menu_bf_subfoodType[i].quantity + " " + nohypen + this.menu_bf_subfoodType[i].food_item + nohypen + this.menu_bf_subfoodType[i].food_sub_type + ", ";
                  }
              }
            }
          }
    
          if (this.menu_bf_cond != undefined && this.menu_bf_cond == "or") {
            this.dietbreakfast = this.dietbreakfast +"\n" + this.menu_bf_cond + "\n";
          }else if (this.menu_bf_cond != "or" && this.menu_bf_cond != "Select" && this.menu_bf_cond != undefined) {
            this.dietbreakfast = this.dietbreakfast + " " + this.menu_bf_cond + " ";
          }
    
          for(var i = (this.dietbreakfastadd.length - 1); i < this.dietbreakfastadd.length; i++){
            this.dietbreakfastadd[i].cond = this.menu_bf_cond;
          }

          if (this.dietbreakfastadd.length != 0) {
            if (this.col_dtaa == "0") {
              this.col_dtaa = parseInt(this.bf_energy);
              this.set_col_data();
            } else {
              var tot = parseFloat(this.col_dtaa) + parseFloat(this.bf_energy);
              this.col_dtaa = tot.toString();
              this.set_col_data();
            }
          }
        }
        break;
      case 'Mid-day': 
        var flag = true;
        if (this.dietmiddayadd.length != 0) {
          for (var i = 0; i < this.dietmiddayadd.length; i++) {
            for(var j = 0; j < this.menu_mid_day_subfoodType.length; j++){
              if (this.dietmiddayadd[i].food_sub_type == this.menu_mid_day_subfoodType[j].food_item) {
                if(this.dietmiddayadd[i].day == this.day){
                  for(var k = (this.dietmiddayadd.length - 1); k < this.dietmiddayadd.length; k++){
                  if(this.dietmiddayadd[k].cond != "or"){
                    this.menu_mid_day_subfoodType[j].checked = false;
                    this.toastr.error(this.menu_mid_day_subfoodType[j].food_item + "already exits");   
                  }
                  }
                }
              }
            }
          }
        }
    
        if(flag == true){
          for(var i = 0; i < this.menu_mid_day_subfoodType.length; i++){
            if(this.menu_mid_day_subfoodType[i].checked == true){
              this.dietmiddayadd.push({
                category: this.menu_mid_day_subfoodType[i].main_food,
                food_sub_type: this.menu_mid_day_subfoodType[i].food_item,
                description: this.menu_mid_day_subfoodType[i].food_sub_type,
                quantity: this.menu_mid_day_subfoodType[i].quantity,
                measure: this.menu_mid_day_subfoodType[i].measure,
                weight: this.menu_mid_day_subfoodType[i].weight,
                energy: this.menu_mid_day_subfoodType[i].energy,
                food_item_id: this.menu_mid_day_subfoodType[i].food_item_id,
                cond: "",
                day:this.day,
              });
    
              if (this.menu_mid_day_subfoodType[i].measure != "item(s)") {
                if (this.menu_mid_day_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (this.dietmidday != undefined) {
                    this.dietmidday += this.menu_mid_day_subfoodType[i].quantity + " " + this.menu_mid_day_subfoodType[i].measure + " "  + nohypen + this.menu_mid_day_subfoodType[i].food_item + nohypen + this.menu_mid_day_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietmidday =+ this.menu_mid_day_subfoodType[i].quantity + " " + this.menu_mid_day_subfoodType[i].measure + " " + nohypen + this.menu_mid_day_subfoodType[i].food_item + nohypen + this.menu_mid_day_subfoodType[i].food_sub_type + ", ";
                  }
        
              }else {
                  if (this.menu_mid_day_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
            
                  if (this.dietmidday != undefined) {
                    this.dietmidday += this.menu_mid_day_subfoodType[i].quantity + " " + nohypen + this.menu_mid_day_subfoodType[i].food_item + nohypen +this.menu_mid_day_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietmidday = this.menu_mid_day_subfoodType[i].quantity + " " + nohypen + this.menu_mid_day_subfoodType[i].food_item + nohypen + this.menu_mid_day_subfoodType[i].food_sub_type + ", ";
                  }
              }
            }
          }
    
          if (this.menu_mid_day_cond != undefined && this.menu_mid_day_cond == "or") {
            this.dietmidday = this.dietmidday +"\n" + this.menu_mid_day_cond + "\n";
          }else if (this.menu_mid_day_cond != "or" && this.menu_mid_day_cond != "Select" && this.menu_mid_day_cond != undefined) {
            this.dietmidday = this.dietmidday + " " + this.menu_mid_day_cond + " ";
          }
    
          for(var i = (this.dietmiddayadd.length - 1); i < this.dietmiddayadd.length; i++){
            this.dietmiddayadd[i].cond = this.menu_mid_day_cond;
          }

          if (this.dietmiddayadd.length != 0) {
            if (this.col_dtaa == "0") {
              this.col_dtaa = parseInt(this.mid_day_energy);
              this.set_col_data();
            } else {
              var tot = parseFloat(this.col_dtaa) + parseFloat(this.mid_day_energy);
              this.col_dtaa = tot.toString();
              this.set_col_data();
            }
          }
        }
        break;
      case 'Lunch': 
        var flag = true;
        if (this.dietlunchadd.length != 0) {
          for (var i = 0; i < this.dietlunchadd.length; i++) {
            for(var j = 0; j < this.menu_lunch_subfoodType.length; j++){
              if (this.dietlunchadd[i].food_sub_type == this.menu_lunch_subfoodType[j].food_item) {
                if(this.dietlunchadd[i].day == this.day){
                  for(var k = (this.dietlunchadd.length - 1); k < this.dietlunchadd.length; k++){
                    if(this.dietlunchadd[k].cond != "or"){
                      this.menu_lunch_subfoodType[j].checked = false;
                      this.toastr.error(this.menu_lunch_subfoodType[j].food_item + "already exits");     
                    }
                  }
                }
              }
            }
          }
        }
    
        if(flag == true){
          for(var i = 0; i < this.menu_lunch_subfoodType.length; i++){
            if(this.menu_lunch_subfoodType[i].checked == true){
              this.dietlunchadd.push({
                category: this.menu_lunch_subfoodType[i].main_food,
                food_sub_type: this.menu_lunch_subfoodType[i].food_item,
                description: this.menu_lunch_subfoodType[i].food_sub_type,
                quantity: this.menu_lunch_subfoodType[i].quantity,
                measure: this.menu_lunch_subfoodType[i].measure,
                weight: this.menu_lunch_subfoodType[i].weight,
                energy: this.menu_lunch_subfoodType[i].energy,
                food_item_id: this.menu_lunch_subfoodType[i].food_item_id,
                cond: "",
                day:this.day,
              });
    
              if (this.menu_lunch_subfoodType[i].measure != "item(s)") {
                if (this.menu_lunch_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (this.dietlunch != undefined) {
                    this.dietlunch += this.menu_lunch_subfoodType[i].quantity + " " + this.menu_lunch_subfoodType[i].measure + " "  + nohypen + this.menu_lunch_subfoodType[i].food_item + nohypen + this.menu_lunch_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietlunch =+ this.menu_lunch_subfoodType[i].quantity + " " + this.menu_lunch_subfoodType[i].measure + " " + nohypen + this.menu_lunch_subfoodType[i].food_item + nohypen + this.menu_lunch_subfoodType[i].food_sub_type + ", ";
                  }
        
              }else {
                  if (this.menu_lunch_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
            
                  if (this.dietlunch != undefined) {
                    this.dietlunch += this.menu_lunch_subfoodType[i].quantity + " " + nohypen + this.menu_lunch_subfoodType[i].food_item + nohypen +this.menu_lunch_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietlunch = this.menu_lunch_subfoodType[i].quantity + " " + nohypen + this.menu_lunch_subfoodType[i].food_item + nohypen + this.menu_lunch_subfoodType[i].food_sub_type + ", ";
                  }
              }
            }
          }
    
          if (this.menu_lunch_cond != undefined && this.menu_lunch_cond == "or") {
            this.dietlunch = this.dietlunch +"\n" + this.menu_lunch_cond + "\n";
          }else if (this.menu_lunch_cond != "or" && this.menu_lunch_cond != "Select" && this.menu_lunch_cond != undefined) {
            this.dietlunch = this.dietlunch + " " + this.menu_lunch_cond + " ";
          }
    
          for(var i = (this.dietlunchadd.length - 1); i < this.dietlunchadd.length; i++){
            this.dietlunchadd[i].cond = this.menu_lunch_cond;
          }

          if (this.dietlunchadd.length != 0) {
            if (this.col_dtaa == "0") {
              this.col_dtaa = parseInt(this.lunch_energy);
              this.set_col_data();
            } else {
              var tot = parseFloat(this.col_dtaa) + parseFloat(this.lunch_energy);
              this.col_dtaa = tot.toString();
              this.set_col_data();
            }
          }
        }
        break;
      case 'Evening': 
        var flag = true;
        if (this.dieteveadd.length != 0) {
          for (var i = 0; i < this.dieteveadd.length; i++) {
            for(var j = 0; j < this.menu_eve_subfoodType.length; j++){
              if (this.dieteveadd[i].food_sub_type == this.menu_eve_subfoodType[j].food_item) {
                if(this.dieteveadd[i].day == this.day){
                  for(var k = (this.dieteveadd.length - 1); k < this.dieteveadd.length; k++){
                    if(this.dieteveadd[k].cond != "or"){
                      this.menu_eve_subfoodType[j].checked = false;
                      this.toastr.error(this.menu_eve_subfoodType[j].food_item + "already exits");    
                    }
                  }
                }
              }
            }
          }
        }
    
        if(flag == true){
          for(var i = 0; i < this.menu_eve_subfoodType.length; i++){
            if(this.menu_eve_subfoodType[i].checked == true){
              this.dieteveadd.push({
                category: this.menu_eve_subfoodType[i].main_food,
                food_sub_type: this.menu_eve_subfoodType[i].food_item,
                description: this.menu_eve_subfoodType[i].food_sub_type,
                quantity: this.menu_eve_subfoodType[i].quantity,
                measure: this.menu_eve_subfoodType[i].measure,
                weight: this.menu_eve_subfoodType[i].weight,
                energy: this.menu_eve_subfoodType[i].energy,
                food_item_id: this.menu_eve_subfoodType[i].food_item_id,
                cond: "",
                day:this.day,
              });
      
              if (this.menu_eve_subfoodType[i].measure != "item(s)") {
                if (this.menu_eve_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (this.dietevening != undefined) {
                    this.dietevening += this.menu_eve_subfoodType[i].quantity + " " + this.menu_eve_subfoodType[i].measure + " "  + nohypen + this.menu_eve_subfoodType[i].food_item + nohypen + this.menu_eve_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietevening =+ this.menu_eve_subfoodType[i].quantity + " " + this.menu_eve_subfoodType[i].measure + " " + nohypen + this.menu_eve_subfoodType[i].food_item + nohypen + this.menu_eve_subfoodType[i].food_sub_type + ", ";
                  }
        
              }else {
                  if (this.menu_eve_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
            
                  if (this.dietevening != undefined) {
                    this.dietevening += this.menu_eve_subfoodType[i].quantity + " " + nohypen + this.menu_eve_subfoodType[i].food_item + nohypen +this.menu_eve_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietevening = this.menu_eve_subfoodType[i].quantity + " " + nohypen + this.menu_eve_subfoodType[i].food_item + nohypen + this.menu_eve_subfoodType[i].food_sub_type + ", ";
                  }
              }
            }
          }
      
          if (this.menu_eve_cond != undefined && this.menu_eve_cond == "or") {
            this.dietevening = this.dietevening +"\n" + this.menu_eve_cond + "\n";
          }else if (this.menu_eve_cond != "or" && this.menu_eve_cond != "Select" && this.menu_eve_cond != undefined) {
            this.dietevening = this.dietevening + " " + this.menu_eve_cond + " ";
          }
      
          for(var i = (this.dieteveadd.length - 1); i < this.dieteveadd.length; i++){
            this.dieteveadd[i].cond = this.menu_eve_cond;
          }

          if (this.dieteveadd.length != 0) {
            if (this.col_dtaa == "0") {
              this.col_dtaa = parseInt(this.eve_energy);
              this.set_col_data();
            } else {
              var tot = parseFloat(this.col_dtaa) + parseFloat(this.eve_energy);
              this.col_dtaa = tot.toString();
              this.set_col_data();
            }
          }
        }
        break;
      case 'Dinner': 
        var flag = true;
        if (this.dietdinneradd.length != 0) {
          for (var i = 0; i < this.dietdinneradd.length; i++) {
            for(var j = 0; j < this.menu_dinner_subfoodType.length; j++){
              if (this.dietdinneradd[i].food_sub_type == this.menu_dinner_subfoodType[j].food_item) {
                if(this.dietdinneradd[i].day == this.day){
                  for(var k = (this.dietdinneradd.length - 1); k < this.dietdinneradd.length; k++){
                    if(this.dietdinneradd[k].cond != "or"){
                      this.menu_dinner_subfoodType[j].checked = false;
                      this.toastr.error(this.menu_dinner_subfoodType[j].food_item + "already exits");    
                    }
                  }
                }
              }
            }
          }
        }
    
        if(flag == true){
          for(var i = 0; i < this.menu_dinner_subfoodType.length; i++){
            if(this.menu_dinner_subfoodType[i].checked == true){
              this.dietdinneradd.push({
                category: this.menu_dinner_subfoodType[i].main_food,
                food_sub_type: this.menu_dinner_subfoodType[i].food_item,
                description: this.menu_dinner_subfoodType[i].food_sub_type,
                quantity: this.menu_dinner_subfoodType[i].quantity,
                measure: this.menu_dinner_subfoodType[i].measure,
                weight: this.menu_dinner_subfoodType[i].weight,
                energy: this.menu_dinner_subfoodType[i].energy,
                food_item_id: this.menu_dinner_subfoodType[i].food_item_id,
                cond: "",
                day:this.day,
              });
    
              if (this.menu_dinner_subfoodType[i].measure != "item(s)") {
                if (this.menu_dinner_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (this.dietdinner != undefined) {
                    this.dietdinner += this.menu_dinner_subfoodType[i].quantity + " " + this.menu_dinner_subfoodType[i].measure + " "  + nohypen + this.menu_dinner_subfoodType[i].food_item + nohypen + this.menu_dinner_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietdinner =+ this.menu_dinner_subfoodType[i].quantity + " " + this.menu_dinner_subfoodType[i].measure + " " + nohypen + this.menu_dinner_subfoodType[i].food_item + nohypen + this.menu_dinner_subfoodType[i].food_sub_type + ", ";
                  }
        
              }else {
                  if (this.menu_dinner_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
            
                  if (this.dietdinner != undefined) {
                    this.dietdinner += this.menu_dinner_subfoodType[i].quantity + " " + nohypen + this.menu_dinner_subfoodType[i].food_item + nohypen +this.menu_dinner_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietdinner = this.menu_dinner_subfoodType[i].quantity + " " + nohypen + this.menu_dinner_subfoodType[i].food_item + nohypen + this.menu_dinner_subfoodType[i].food_sub_type + ", ";
                  }
              }
            }
          }
    
          if (this.menu_dinner_cond != undefined && this.menu_dinner_cond == "or") {
            this.dietdinner = this.dietdinner +"\n" + this.menu_dinner_cond + "\n";
          }else if (this.menu_dinner_cond != "or" && this.menu_dinner_cond != "Select" && this.menu_dinner_cond != undefined) {
            this.dietdinner = this.dietdinner + " " + this.menu_dinner_cond + " ";
          }
    
          for(var i = (this.dietdinneradd.length - 1); i < this.dietdinneradd.length; i++){
            this.dietdinneradd[i].cond = this.menu_dinner_cond;
          }

          if (this.dietdinneradd.length != 0) {
            if (this.col_dtaa == "0") {
              this.col_dtaa = parseInt(this.dinner_energy);
              this.set_col_data();
            } else {
              var tot = parseFloat(this.col_dtaa) + parseFloat(this.dinner_energy);
              this.col_dtaa = tot.toString();
              this.set_col_data();
            }
          }
        }
        break;
      case 'Night': 
        var flag = true;
        if (this.dietnightadd.length != 0) {
          for (var i = 0; i < this.dietnightadd.length; i++) {
            for(var j = 0; j < this.menu_night_subfoodType.length; j++){
              if (this.dietnightadd[i].food_sub_type == this.menu_night_subfoodType[j].food_item) {
                if(this.dietnightadd[i].day == this.day){
                  for(var k = (this.dietnightadd.length - 1); k < this.dietnightadd.length; k++){
                    if(this.dietnightadd[k].cond != "or"){
                      this.menu_night_subfoodType[j].checked = false;
                      this.toastr.error(this.menu_night_subfoodType[j].food_item + "already exits");   
                    }
                  }
                }
              }
            }
          }
        }
    
        if(flag == true){
          for(var i = 0; i < this.menu_night_subfoodType.length; i++){
            if(this.menu_night_subfoodType[i].checked == true){
              this.dietnightadd.push({
                category: this.menu_night_subfoodType[i].main_food,
                food_sub_type: this.menu_night_subfoodType[i].food_item,
                description: this.menu_night_subfoodType[i].food_sub_type,
                quantity: this.menu_night_subfoodType[i].quantity,
                measure: this.menu_night_subfoodType[i].measure,
                weight: this.menu_night_subfoodType[i].weight,
                energy: this.menu_night_subfoodType[i].energy,
                food_item_id: this.menu_night_subfoodType[i].food_item_id,
                cond: "",
                day:this.day,
              });
    
              if (this.menu_night_subfoodType[i].measure != "item(s)") {
                if (this.menu_night_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
                  if (this.dietnight != undefined) {
                    this.dietnight += this.menu_night_subfoodType[i].quantity + " " + this.menu_night_subfoodType[i].measure + " "  + nohypen + this.menu_night_subfoodType[i].food_item + nohypen + this.menu_night_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietnight =+ this.menu_night_subfoodType[i].quantity + " " + this.menu_night_subfoodType[i].measure + " " + nohypen + this.menu_night_subfoodType[i].food_item + nohypen + this.menu_night_subfoodType[i].food_sub_type + ", ";
                  }
        
              }else {
                  if (this.menu_night_subfoodType[i].food_sub_type == "") { var nohypen = " "; } else { nohypen = " - "; }
            
                  if (this.dietnight != undefined) {
                    this.dietnight += this.menu_night_subfoodType[i].quantity + " " + nohypen + this.menu_night_subfoodType[i].food_item + nohypen +this.menu_night_subfoodType[i].food_sub_type + ", ";
                  } else {
                    this.dietnight = this.menu_night_subfoodType[i].quantity + " " + nohypen + this.menu_night_subfoodType[i].food_item + nohypen + this.menu_night_subfoodType[i].food_sub_type + ", ";
                  }
              }
            }
          }
    
          if (this.menu_night_cond != undefined && this.menu_night_cond == "or") {
            this.dietnight = this.dietnight +"\n" + this.menu_night_cond + "\n";
          }else if (this.menu_night_cond != "or" && this.menu_night_cond != "Select" && this.menu_night_cond != undefined) {
            this.dietnight = this.dietnight + " " + this.menu_night_cond + " ";
          }
    
          for(var i = (this.dietnightadd.length - 1); i < this.dietnightadd.length; i++){
            this.dietnightadd[i].cond = this.menu_night_cond;
          }

          if (this.dietnightadd.length != 0) {
            if (this.col_dtaa == "0") {
              this.col_dtaa = parseInt(this.night_energy);
              this.set_col_data();
            } else {
              var tot = parseFloat(this.col_dtaa) + parseFloat(this.night_energy);
              this.col_dtaa = tot.toString();
              this.set_col_data();
            }
          }
        }
        break;
    }
    this.send_data();
  }

  selectDay(day,session){
    this.day = day;

    switch(session){
      case 'Morning':
        if(this.dietmoradd.length != 0){
          this.dietmorning = "";
          for(var i = 0; i < this.dietmoradd.length; i++){
            if(day == this.dietmoradd[i].day){
              var condition = this.dietmoradd[i].cond != undefined ? this.dietmoradd[i].cond : "";
              var food_subtype = this.dietmoradd[i].food_sub_type != undefined ? this.dietmoradd[i].food_sub_type : "";
              var foodtype = this.dietmoradd[i].description != undefined ? this.dietmoradd[i].description : "";
              var quan = this.dietmoradd[i].quantity != undefined ? this.dietmoradd[i].quantity : "";
              var measures = this.dietmoradd[i].measures != undefined ? this.dietmoradd[i].measures : "";
    
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietmorning = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietmorning = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietmorning = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietmorning = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietmorning = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietmorning = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }else{
              this.emptyDay(session);
            }
          }
        }
        break;
      case 'Breakfast':
        if(this.dietbreakfastadd.length != 0){
          this.dietbreakfast = "";
          for(var i = 0; i < this.dietbreakfastadd.length; i++){
            if(day == this.dietbreakfastadd[i].day){
              var condition = this.dietbreakfastadd[i].cond != undefined ? this.dietbreakfastadd[i].cond : "";
              var food_subtype = this.dietbreakfastadd[i].food_sub_type != undefined ? this.dietbreakfastadd[i].food_sub_type : "";
              var foodtype = this.dietbreakfastadd[i].description != undefined ? this.dietbreakfastadd[i].description : "";
              var quan = this.dietbreakfastadd[i].quantity != undefined ? this.dietbreakfastadd[i].quantity : "";
              var measures = this.dietbreakfastadd[i].measures != undefined ? this.dietbreakfastadd[i].measures : "";
    
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietbreakfast = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietbreakfast = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietbreakfast = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietbreakfast = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietbreakfast = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietbreakfast = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }else{
              this.emptyDay(session);
            }
          }
        }
        break;
      case 'Mid-day':
        if(this.dietmiddayadd.length != 0){
          this.dietmidday = "";
          for(var i = 0; i < this.dietmiddayadd.length; i++){
            if(day == this.dietmiddayadd[i].day){
              var condition = this.dietmiddayadd[i].cond != undefined ? this.dietmiddayadd[i].cond : "";
              var food_subtype = this.dietmiddayadd[i].food_sub_type != undefined ? this.dietmiddayadd[i].food_sub_type : "";
              var foodtype = this.dietmiddayadd[i].description != undefined ? this.dietmiddayadd[i].description : "";
              var quan = this.dietmiddayadd[i].quantity != undefined ? this.dietmiddayadd[i].quantity : "";
              var measures = this.dietmiddayadd[i].measures != undefined ? this.dietmiddayadd[i].measures : "";
    
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietmidday = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietmidday = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietmidday = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietmidday = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietmidday = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietmidday = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }else{
              this.emptyDay(session);
            }
          }
        }
        break;
      case 'Lunch':
        if(this.dietlunchadd.length != 0){
          this.dietlunch = "";
          for(var i = 0; i < this.dietlunchadd.length; i++){
            if(day == this.dietlunchadd[i].day){
              var condition = this.dietlunchadd[i].cond != undefined ? this.dietlunchadd[i].cond : "";
              var food_subtype = this.dietlunchadd[i].food_sub_type != undefined ? this.dietlunchadd[i].food_sub_type : "";
              var foodtype = this.dietlunchadd[i].description != undefined ? this.dietlunchadd[i].description : "";
              var quan = this.dietlunchadd[i].quantity != undefined ? this.dietlunchadd[i].quantity : "";
              var measures = this.dietlunchadd[i].measures != undefined ? this.dietlunchadd[i].measures : "";
    
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietlunch = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietlunch = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietlunch = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietlunch = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietlunch = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietlunch = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }else{
              this.emptyDay(session);
            }
          }
        }
        break;
      case 'Evening':
        if(this.dieteveadd.length != 0){
          this.dietevening = "";
          for(var i = 0; i < this.dieteveadd.length; i++){
            if(day == this.dieteveadd[i].day){
              var condition = this.dieteveadd[i].cond != undefined ? this.dieteveadd[i].cond : "";
              var food_subtype = this.dieteveadd[i].food_sub_type != undefined ? this.dieteveadd[i].food_sub_type : "";
              var foodtype = this.dieteveadd[i].description != undefined ? this.dieteveadd[i].description : "";
              var quan = this.dieteveadd[i].quantity != undefined ? this.dieteveadd[i].quantity : "";
              var measures = this.dieteveadd[i].measures != undefined ? this.dieteveadd[i].measures : "";
    
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietevening = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietevening = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietevening = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietevening = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietevening = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietevening = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }else{
              this.emptyDay(session);
            }
          }
        }
        break;
      case 'Dinner':
        if(this.dietdinneradd.length != 0){
          this.dietdinner = "";
          for(var i = 0; i < this.dietdinneradd.length; i++){
            if(day == this.dietdinneradd[i].day){
              var condition = this.dietdinneradd[i].cond != undefined ? this.dietdinneradd[i].cond : "";
              var food_subtype = this.dietdinneradd[i].food_sub_type != undefined ? this.dietdinneradd[i].food_sub_type : "";
              var foodtype = this.dietdinneradd[i].description != undefined ? this.dietdinneradd[i].description : "";
              var quan = this.dietdinneradd[i].quantity != undefined ? this.dietdinneradd[i].quantity : "";
              var measures = this.dietdinneradd[i].measures != undefined ? this.dietdinneradd[i].measures : "";
    
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietdinner = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietdinner = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietdinner = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietdinner = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietdinner = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietdinner = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }else{
              this.emptyDay(session);
            }
          }
        }
        break;
      case 'Night':
        if(this.dietnightadd.length != 0){
          this.dietnight = "";
          for(var i = 0; i < this.dietnightadd.length; i++){
            if(day == this.dietnightadd[i].day){
              var condition = this.dietnightadd[i].cond != undefined ? this.dietnightadd[i].cond : "";
              var food_subtype = this.dietnightadd[i].food_sub_type != undefined ? this.dietnightadd[i].food_sub_type : "";
              var foodtype = this.dietnightadd[i].description != undefined ? this.dietnightadd[i].description : "";
              var quan = this.dietnightadd[i].quantity != undefined ? this.dietnightadd[i].quantity : "";
              var measures = this.dietnightadd[i].measures != undefined ? this.dietnightadd[i].measures : "";
    
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietnight = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietnight = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietnight = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietnight = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietnight = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietnight = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }else{
              this.emptyDay(session);
            }
          }
        }
        break;
    }
  }
  emptyDay(session){

    switch(session){
      case 'Morning':
        if(this.dietmoradd.length != 0){
          this.dietmorning = "";
          for(var i = 0; i < this.dietmoradd.length; i++){
          //  if(day == this.dietmoradd[i].day){
              var condition = this.dietmoradd[i].cond != undefined ? this.dietmoradd[i].cond : ",";
              var food_subtype = this.dietmoradd[i].food_sub_type != undefined ? this.dietmoradd[i].food_sub_type : "";
              var foodtype = this.dietmoradd[i].description != undefined ? this.dietmoradd[i].description : "";
              var quan = this.dietmoradd[i].quantity != undefined ? this.dietmoradd[i].quantity : "";
              var measures = this.dietmoradd[i].measures != undefined ? this.dietmoradd[i].measures : "";
              this.dietmoradd[i].day = "Sunday";
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietmorning = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietmorning = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietmorning = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietmorning = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietmorning = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietmorning != undefined) {
                    this.dietmorning += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietmorning = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }
          //}
        }
        break;
      case 'Breakfast':
        if(this.dietbreakfastadd.length != 0){
          this.dietbreakfast = "";
          for(var i = 0; i < this.dietbreakfastadd.length; i++){
           // if(day == this.dietbreakfastadd[i].day){
              var condition = this.dietbreakfastadd[i].cond != undefined ? this.dietbreakfastadd[i].cond : ",";
              var food_subtype = this.dietbreakfastadd[i].food_sub_type != undefined ? this.dietbreakfastadd[i].food_sub_type : "";
              var foodtype = this.dietbreakfastadd[i].description != undefined ? this.dietbreakfastadd[i].description : "";
              var quan = this.dietbreakfastadd[i].quantity != undefined ? this.dietbreakfastadd[i].quantity : "";
              var measures = this.dietbreakfastadd[i].measures != undefined ? this.dietbreakfastadd[i].measures : "";
              this.dietbreakfastadd[i].day = "Sunday";
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietbreakfast = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietbreakfast = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietbreakfast = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietbreakfast = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietbreakfast = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietbreakfast != undefined) {
                    this.dietbreakfast += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietbreakfast = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
           // }
          }
        }
        break;
      case 'Mid-day':
        if(this.dietmiddayadd.length != 0){
          this.dietmidday = "";
          for(var i = 0; i < this.dietmiddayadd.length; i++){
           // if(day == this.dietmiddayadd[i].day){
              var condition = this.dietmiddayadd[i].cond != undefined ? this.dietmiddayadd[i].cond : ",";
              var food_subtype = this.dietmiddayadd[i].food_sub_type != undefined ? this.dietmiddayadd[i].food_sub_type : "";
              var foodtype = this.dietmiddayadd[i].description != undefined ? this.dietmiddayadd[i].description : "";
              var quan = this.dietmiddayadd[i].quantity != undefined ? this.dietmiddayadd[i].quantity : "";
              var measures = this.dietmiddayadd[i].measures != undefined ? this.dietmiddayadd[i].measures : "";
              this.dietmiddayadd[i].day = "Sunday";
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietmidday = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietmidday = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietmidday = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietmidday = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietmidday = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietmidday != undefined) {
                    this.dietmidday += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietmidday = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
           // }
          }
        }
        break;
      case 'Lunch':
        if(this.dietlunchadd.length != 0){
          this.dietlunch = "";
          for(var i = 0; i < this.dietlunchadd.length; i++){
            //if(day == this.dietlunchadd[i].day){
              var condition = this.dietlunchadd[i].cond != undefined ? this.dietlunchadd[i].cond : ",";
              var food_subtype = this.dietlunchadd[i].food_sub_type != undefined ? this.dietlunchadd[i].food_sub_type : "";
              var foodtype = this.dietlunchadd[i].description != undefined ? this.dietlunchadd[i].description : "";
              var quan = this.dietlunchadd[i].quantity != undefined ? this.dietlunchadd[i].quantity : "";
              var measures = this.dietlunchadd[i].measures != undefined ? this.dietlunchadd[i].measures : "";
              this.dietlunchadd[i].day = "Sunday";
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietlunch = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietlunch = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietlunch = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietlunch = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietlunch = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietlunch != undefined) {
                    this.dietlunch += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietlunch = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
           // }
          }
        }
        break;
      case 'Evening':
        if(this.dieteveadd.length != 0){
          this.dietevening = "";
          for(var i = 0; i < this.dieteveadd.length; i++){
         //   if(day == this.dieteveadd[i].day){
              var condition = this.dieteveadd[i].cond != undefined ? this.dieteveadd[i].cond : ",";
              var food_subtype = this.dieteveadd[i].food_sub_type != undefined ? this.dieteveadd[i].food_sub_type : "";
              var foodtype = this.dieteveadd[i].description != undefined ? this.dieteveadd[i].description : "";
              var quan = this.dieteveadd[i].quantity != undefined ? this.dieteveadd[i].quantity : "";
              var measures = this.dieteveadd[i].measures != undefined ? this.dieteveadd[i].measures : "";
              this.dieteveadd[i].day = "Sunday";
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietevening = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietevening = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietevening = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietevening = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietevening = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietevening != undefined) {
                    this.dietevening += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietevening = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            //}
          }
        }
        break;
      case 'Dinner':
        if(this.dietdinneradd.length != 0){
          this.dietdinner = "";
          for(var i = 0; i < this.dietdinneradd.length; i++){
           // if(day == this.dietdinneradd[i].day){
              var condition = this.dietdinneradd[i].cond != undefined ? this.dietdinneradd[i].cond : ",";
              var food_subtype = this.dietdinneradd[i].food_sub_type != undefined ? this.dietdinneradd[i].food_sub_type : "";
              var foodtype = this.dietdinneradd[i].description != undefined ? this.dietdinneradd[i].description : "";
              var quan = this.dietdinneradd[i].quantity != undefined ? this.dietdinneradd[i].quantity : "";
              var measures = this.dietdinneradd[i].measures != undefined ? this.dietdinneradd[i].measures : "";
              this.dietdinneradd[i].day = "Sunday";
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietdinner = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietdinner = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietdinner = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietdinner = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietdinner = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietdinner != undefined) {
                    this.dietdinner += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietdinner = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
           // }
          }
        }
        break;
      case 'Night':
        if(this.dietnightadd.length != 0){
          this.dietnight = "";
          for(var i = 0; i < this.dietnightadd.length; i++){
           // if(day == this.dietnightadd[i].day){
              var condition = this.dietnightadd[i].cond != undefined ? this.dietnightadd[i].cond : ",";
              var food_subtype = this.dietnightadd[i].food_sub_type != undefined ? this.dietnightadd[i].food_sub_type : "";
              var foodtype = this.dietnightadd[i].description != undefined ? this.dietnightadd[i].description : "";
              var quan = this.dietnightadd[i].quantity != undefined ? this.dietnightadd[i].quantity : "";
              var measures = this.dietnightadd[i].measures != undefined ? this.dietnightadd[i].measures : "";
              this.dietnightadd[i].day = "Sunday";
              if (measures != "item(s)") {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  } else {
                    this.dietnight = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n"
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  } else {
                    this.dietnight = quan + " " + measures + " " + foodtype + nohypen + food_subtype + " " + condition + " "
                  }
                } else {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + measures + " " + foodtype + " - " + food_subtype
                  } else {
                    this.dietnight = quan + " " + measures + " " + foodtype + " - " + food_subtype
                  }
                }
              } else {
                if (food_subtype == "") { var nohypen = " "; } else { nohypen = " - "; }
                if (condition != undefined && condition == "or") {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  } else {
                    this.dietnight = quan + " " + foodtype + nohypen + food_subtype + " " + "\n" + condition + "\n";
                  }
                } else if (condition != "or" && condition != "Select" && condition != undefined) {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + foodtype + nohypen + food_subtype + nohypen + food_subtype + " " + condition + " ";
                  } else {
                    this.dietnight = quan + " " + foodtype + nohypen + food_subtype + " " + condition + " ";
                  }
                } else {
                  if (this.dietnight != undefined) {
                    this.dietnight += quan + " " + foodtype + nohypen + food_subtype
                  } else {
                    this.dietnight = quan + " " + foodtype + nohypen + food_subtype
                  }
                }
              }
            }
         // }
        }
        break;
    }
  }
}
