import { Component, OnInit, HostListener, ElementRef, ViewChild, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Observable, Observer, Subscription } from 'rxjs';
import { PharmacyService } from './pharmacy.service'
import { ChangePasswordComponent } from 'src/app/common-module/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { ipaddress } from '../../ipaddress';
declare var $: any;
@Component({
  selector: 'app-pharmacy',
  templateUrl: './pharmacy.component.html',
  styleUrls: ['./pharmacy.component.scss']
})
export class PharmacyComponent implements OnInit {
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;

  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public hospitalDetails;
  public profileImage;
  public firstName: string;
  public lastName: string;
  public messages;
  public msgFlag: boolean = false;
  public settings: boolean = false;
  public submenuFlag: boolean = false;
  public submenuFlag1: boolean = false;
  public reportSubmenuFlag: boolean = false;
  public showSubMenu;
  public minimizedAsied: boolean = false;
  public selectedMenu;
  public manageOrders: boolean = true;
  public salesForDrugInsp: boolean = true;
  public inwardReport: boolean = true;
  public manufacturer: boolean = true;
  public suppliers: boolean = true;
  public suppliersProduct: boolean = true;
  public discount: boolean = true;
  public productMaster: boolean = true;
  public purchaseRequest: boolean = true;
  public purchaseOrder: boolean = true;
  public inventoryReceivables: boolean = true;
  public stockEdit: boolean = true;
  public inwardFlag: boolean = true;
  public displayPage;
  public pharmacyID;
  public reference_video: boolean = true;
  public interdeptFlag: boolean=false;
  public consolidatedFlag:boolean=false;
  subscription: Subscription;

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: PharmacyService, public dialog: MatDialog) {
    pharmacy_helper.setProductMst(undefined);
  }
  ngOnInit(): void {
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
        this.menuNav(message);
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.firstName = this.userInfo.first_name;
    this.lastName = this.userInfo.last_name;
    this.profileImage = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.hospitalName = this.userInfo.hospitals[0].hptl_name;
    this.hospitalLogo = ipaddress.Ip_with_img_address + this.userInfo.hospitals[0].logo;
    this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    this.submenuFlag = false;
    this.submenuFlag1 = false;
    this.reportSubmenuFlag= false;
    this.minimizedAsied = false;
    var module_list;
    var Moduleidlist;
    var inwardFlag
    if (pharmacy_helper.getModuleList() != null) {
      module_list = pharmacy_helper.getModuleList();
      this.manageOrders = true;
      this.manufacturer = true;
      this.suppliers = true;
      this.suppliersProduct = true;
      this.discount = true;
      this.productMaster = true;
      this.purchaseRequest = true;
      this.purchaseOrder = true;
      this.inventoryReceivables = true;
      this.manufacturer = true;
      for (var i = 0; i < module_list.length; i++) {
        if (module_list[i] == "25") {
          this.manageOrders = false;
        } else if (module_list[i] == "69") {
          this.manufacturer = false;
        } else if (module_list[i] == "70") {
          this.suppliers = false;
        } else if (module_list[i] == "71") {
          this.suppliersProduct = false;
        } else if (module_list[i] == "72") {
          this.discount = false;
        } else if (module_list[i] == "73") {
          this.productMaster = false;
        } else if (module_list[i] == "74") {
          this.purchaseRequest = false;
        } else if (module_list[i] == "75") {
          this.purchaseOrder = false;
        } else if (module_list[i] == "76") {
          this.inventoryReceivables = false;
        } else if (module_list[i] == "28") { // Bill View
          Helper_Class.setBillView(null);
          Helper_Class.setBillView("1");
        } else if (module_list[i] == "54") { // Bill Create
          Helper_Class.setBillCreate(null);
          Helper_Class.setBillCreate("1");
        } else if (module_list[i] == "55") { // Bill Edit
          Helper_Class.setBillEdit(null);
          Helper_Class.setBillEdit("1");
        } else if (module_list[i] == "85") { // Bill Print
          Helper_Class.setBillPrint(null);
          Helper_Class.setBillPrint("1");
        } else if (module_list[i] == "46") {
          this.reference_video = false;
        }else if (module_list[i] == "49") { // stock edit
          this.stockEdit = false;
        }else if (module_list[i] == "98") { // inward edit
          this.inwardFlag = false;
        }
      }
    }
    
  }
  open_setting() {
    this.settings = !this.settings;
  }
  MenuModelAction(action) {
    if (action == 'pharmacyhome') {
      this.menuNav(action);
    } else if (action == 'changePassword') {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {}
      });
    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "doctor",
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success("Logout successfully" + name)
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => { });
    }
    $('#exampleModal').modal('hide');
  }
  menuNav(page) {
    document.getElementById("pharma_overlay").style.overflow = "auto";
    let page_nmae: string = page;
    this.selectedMenu = page;
    this.submenuFlag = false;
    this.submenuFlag1 = false;
    this.reportSubmenuFlag = false;
    switch (page_nmae) {
      case 'pharmacyhome':
        this.displayPage = "pharmacyhome";
        break;
      case 'patient':
        this.submenuFlag = !this.submenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case 'stockmanagment': this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case 'pharmacysupplier': this.submenuFlag1 = false; this.displayPage = page; break;
      case 'discounts': this.submenuFlag1 = false; this.displayPage = page; break;
      case 'product': this.submenuFlag1 = false; this.displayPage = page; break;
      case 'pharmacyrequest': this.submenuFlag1 = false; this.displayPage = page; break;
      case 'pharmacyresponse': this.submenuFlag1 = false; this.displayPage = page; break;
      case 'inventory_upload': this.submenuFlag1 = false; this.displayPage = page; break;
      case 'inventory_receivables': this.submenuFlag1 = false; this.displayPage = page; break;
      case 'stockStatusAdmin':this.submenuFlag1 = false; this.displayPage = page; break;
      case 'reports': this.reportSubmenuFlag = !this.reportSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case 'stock_status':this.reportSubmenuFlag = false; this.displayPage = page; break;
      case 'sales_report':this.reportSubmenuFlag = false; this.displayPage = page; break;
      case 'receivables_report':this.reportSubmenuFlag = false; this.displayPage = page; break;
      case 'productReturnsReport':this.reportSubmenuFlag = false; 
          Helper_Class.setReportType(null);
          var sendData = {
            flow : "phrama",
            report_type : "returnsReport",
            pharmacyID : this.pharmacyID,
          }
          Helper_Class.setReportType(sendData);
          this.displayPage = page; break;
      case 'ordercreate': this.displayPage = page; break;
      case 'referral': this.displayPage = page; break;
      case 'filterpage': this.displayPage = page; break;
      case 'help': this.displayPage = page; break;
      case 'pharmacycurentinformation': this.submenuFlag = false; this.displayPage = page; break;
      case 'pharmacypastinformation': this.submenuFlag = false; this.displayPage = page; break;
      case 'pharmacymoduleorderdetailview': this.submenuFlag = false; this.displayPage = page; break;
      case 'orderview': this.submenuFlag = false; this.displayPage = page; break;
      case 'inward': this.displayPage = page; break;
      case 'ipWriteRx': this.displayPage = page; break;
      case 'manufacturer': this.displayPage = page; break;
      case 'supplierProduct': this.displayPage = page; break;
      case 'productReturns': this.displayPage = page; break;
      case 'interdept': this.displayPage = page; break;
      case 'interdeptlist': this.displayPage = page; break;
      case 'supppay': this.displayPage = page; break;
      case 'rackbin': this.displayPage = page; break;
      //this.displayPage = page; break;
      case 'reference_video': this.displayPage = page; break;
      case 'consolidate': this.displayPage = page; break;
    }
    customjs.SelectMenuFun(this.selectedMenu, "pharmacy");
  }
  setValue() {
    pharmacy_helper.setManufacturer(false);
    pharmacy_helper.setSupplier(false);
  }
  minimizing() {
    this.minimizedAsied = !this.minimizedAsied;
    if (this.minimizedAsied == true) {
      this.asied_width.nativeElement.setAttribute('style', 'width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 12px 0 0 3px;}');
      this.submenuFlag = false;
    } else {
      this.asied_width.nativeElement.setAttribute('style', 'width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 17px 0 0 14px;}');
    }
    this.submenuFlag1 = false;
  }
  onResize(event) {
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimizedAsied = false;
  }
  openMenumodel() {
    this.minimizedAsied = false;
    customjs.openMobileMenuFun();
  }
}
