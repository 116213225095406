import { Component, OnInit,HostListener } from '@angular/core';
import { Helper_Class } from '../../helper_class';
// import {ipaddress.getIp,Ip_with_img_address} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { TreatmentService } from '../service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer,Subscription } from 'rxjs';
import * as $ from 'jquery/dist/jquery.min.js';
import { DentalService } from '../service';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-dental-spl-birth-hist-pane',
  templateUrl: './dental-spl-birth-hist-pane.component.html',
  styleUrls: ['./dental-spl-birth-hist-pane.component.css']
})
export class DentalSplBirthHistPaneComponent implements OnInit {
  public birth_du_gest_req: boolean;
  public gen_hist;
  public ynoption:boolean=false;
public Get_client_view_app_data;
  public ynopt12:boolean=false;
 public subscription:Subscription;
  public birth_bre_du_req: boolean;
  public birth_bre_frq_req: boolean;
  public birth_bot_du_req: boolean;
  public birth_bot_frq_req: boolean;
  public birth_type_nipple_req: boolean;
  public birth_mot_age_req: boolean;
  public birth_parity_times_req: boolean;
  public n: number = 1;

  public birth_wh_born_req: boolean;
  public birth_baby_fed_req: boolean;
  public birth_reason_req: boolean;
  public child_hood_dis: string;
  public child_wtxt1;

  public child_wtxt;
public breast_fed_dur_txt;
public weaned_out_txt;
public type_nipple_txt;
public child_hood_dis_array = [];
public get_spl_name;
public panelOpenState16:boolean;

public Birth_Hist_Pane: boolean;
  public Birth_First_Div: boolean;
  public Post_Natal_div: boolean;
  public birthTab:boolean;
  public breast_fed_freq_txt;

  public birth_dur_ges;
  public birth_mother_age;
  public birth_parity_times;
  public birth_mate_ill;
  public birth_wh_born;
  public birth_res;
  public birth_dif_deli;
  public birth_dis;
  public birth_apg_score;
  public birth_int_score;
  public birth_baby_fed;
  public birth_reason;
 
  public birth_hidden: boolean;
  public bottle_fed_dur_txt;
  public bottle_fed_freq_txt;
  
  public diet_details_array = [];

  public client:boolean = false;
  constructor(public http: Http,public toastr:ToastrService,public messageservice:TreatmentService,public dentalservice:DentalService,
    public masterData:MasterCSHelperService) { 
    this.Birth_First_Div=true;
        this.Birth_Hist_Pane = true;
        this.birthTab=false; 
        this.birth_hidden = true;       
        this.birth_du_gest_req = false;
        this.birth_mot_age_req = false;
        this.birth_parity_times_req = false;
        this.birth_wh_born_req = false;
        this.birth_baby_fed_req = false;
        this.birth_reason_req = false;
        //Pediatrics
        // birth
        this.birth_bre_du_req = false;
        this.birth_bre_frq_req = false;
        this.birth_bot_du_req = false;
        this.birth_bot_frq_req = false;
        this.birth_type_nipple_req = false;
        }
      
  async ngOnInit() {
    Helper_Class.Set_den_spl_birth(null);
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client =  false;
    }

    

    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        
        // var data=message.spl
        //this.get_spl_name=message.spl;
        Doc_Helper.Set_dent_spl(message.spl);
        
        this.speciality_change(message.spl);
        //this.send_data(data);
      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });
    this.Birth_change();
    if(masterCSData_Helper.getMasterChildhoodDisease() == undefined){
      await this.masterData.getChildhoodDisease();
      this.get_child_hood_dis_array();
    }else{
      this.get_child_hood_dis_array();
    }
    this.RetrivalFunction();

  }
  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();

  }
 
  @HostListener('window:keyup', ['$event'])
  onkeyup(event: any): void {
    this.send_data();
  }
  
  RetrivalFunction() {
    // retival
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        
       // var data = message.spl
        this.speciality_change( message.spl);
        Doc_Helper.Set_dent_spl(message.spl)
        

      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });
    // this.get_spl_name = this.messageservice.getMessage();

    var getret1=Helper_Class.Get_den_ret1() 
    var getret2=Helper_Class.Get_den_ret2()
    
    if (getret1 != undefined) {
    }
   if (getret2 != undefined) {
     if (getret2.weaned_out != null) {
        this.weaned_out_txt = getret2.weaned_out;
      }
    
      if (getret2.type_nipple != null) {
        this.type_nipple_txt = getret2.type_nipple;
      }
      if (getret2.bottle_fed_dur != null) {
        this.bottle_fed_dur_txt = getret2.bottle_fed_dur;
      }
      if (getret2.bottle_fed_freq != null) {
        this.bottle_fed_freq_txt = getret2.bottle_fed_freq;
      }
      if (getret2.breast_fed_dur != null) {
        this.breast_fed_dur_txt = getret2.breast_fed_dur;
      }

      if (getret2.breast_fed_freq != null) {
        this.breast_fed_freq_txt = getret2.breast_fed_freq;
      }
         //birth
      if (getret2.gestation_duration != null) {
        this.birth_dur_ges = getret2.gestation_duration;
      }
      if (getret2.mot_age_deli != null) {
        this.birth_mother_age = getret2.mot_age_deli;
      }
      if (getret2.parity_times != null) {
        this.birth_parity_times = getret2.parity_times;
      }
      if (getret2.maternal_insults != null) {
        this.birth_mate_ill = getret2.maternal_insults;
      }
      if (getret2.where_born != null) {
        this.birth_wh_born = getret2.where_born;
      }
      if (getret2.diff_delivery != null) {
        this.birth_dif_deli = getret2.diff_delivery;
      }
      if (getret2.resuscitation != null) {
        this.birth_res = getret2.resuscitation;
      }
      if (getret2.int_care_req != null) {
        this.birth_int_score = getret2.int_care_req;
      }
      if (getret2.diseases != null) {
        this.birth_dis = getret2.diseases;
      }
      if (getret2.apgar_score != null) {
        this.birth_apg_score = getret2.apgar_score;
      }
      if (getret2.child_health_cond != null) {
        this.child_wtxt1=true;
        this.child_wtxt = getret2.child_health_cond;
      
      }
      if (getret2.reason != null) {
        this.birth_reason = getret2.reason;
      }
      if (getret2.baby_fed != null) {
        this.birth_baby_fed = getret2.baby_fed;
      }
      if (getret2.diet_history != null) {
        for (var i = 0; i < getret2.diet_history.length; i++) {
          this.diet_details_array.push({
            day: "",
            session: getret2.diet_history[i].session,
            type_food: getret2.diet_history[i].type_food,
            quantity: getret2.diet_history[i].quantity,
            met_prepare: getret2.diet_history[i].met_prepare,
            qty_sugar: getret2.diet_history[i].qty_sugar,
            data: true
          })
        }
      }
      // Gnathic
      if (getret2.gnathic != null) {
        for (var i = 0; i < getret2.gnathic.length; i++) {
          if (getret2.gnathic[i].position_id == "1") {
          
          }
          else {
            //this.gnathic_shape_left = getret2.gnathic[i].shape;
          

          }
        }
      }
  

      if (getret2.curve_spce != null) {
      //  this.gnathic_curve = getret2.curve_spce;
      }
     }
    else {
     // this.loader = true;
    }
   
  }

  
  speciality_change(e){
    this.get_spl_name = "";
    this.get_spl_name =Doc_Helper.get_dent_spl();
     if (this.get_spl_name == "Orthodontics") {   
      this.birthTab=false;
      this.Birth_First_Div=true;
      this.Post_Natal_div=false; 
    
      this.birth_hidden = false;   
      this.Get_client_view_app_data=Helper_Class.Get_client_view_app_data();
     
    }   
   
  }
  Birth_change() {
    var get_spl_name=Doc_Helper.get_dent_spl();

    if (get_spl_name == "Orthodontics") {
      if ((this.breast_fed_dur_txt != undefined && this.breast_fed_dur_txt.length != 0) || (this.breast_fed_freq_txt != undefined && this.breast_fed_freq_txt.length != 0) ||
        (this.weaned_out_txt != undefined && this.weaned_out_txt.length != 0) || (this.child_hood_dis != undefined && this.child_hood_dis.length != 0) ||
        this.type_nipple_txt != undefined) {


        this.birth_bre_du_req = true;
        this.birth_bre_frq_req = true;
        this.birth_bot_du_req = true;
        this.birth_bot_frq_req = true;
        this.birth_type_nipple_req = true;
      }
      else {
        this.birth_bre_du_req = false;
        this.birth_bre_frq_req = false;
        this.birth_bot_du_req = false;
        this.birth_bot_frq_req = false;
        this.birth_type_nipple_req = false;
      }

    }
    else if (get_spl_name== "Pediatrics") {
      this.Birth_First_Div=false;
      if ((this.breast_fed_dur_txt != undefined && this.breast_fed_dur_txt.length != 0) || (this.breast_fed_freq_txt != undefined && this.breast_fed_freq_txt.length != 0) ||
 
        (this.weaned_out_txt != undefined && this.weaned_out_txt.length != 0) || (this.child_hood_dis != undefined && this.child_hood_dis.length != 0) ||
        this.type_nipple_txt != undefined || this.child_wtxt == "yes" ) {

        this.birth_du_gest_req = true;
        this.birth_mot_age_req = true;
        this.birth_parity_times_req = true;
        this.birth_wh_born_req = true;
        this.birth_baby_fed_req = true;
        this.birth_reason_req = true;
        this.birth_bre_du_req = true;
        this.birth_bre_frq_req = true;
        this.birth_bot_du_req = true;
        this.birth_bot_frq_req = true;
        this.birth_type_nipple_req = true;
      }
      else {
        this.birth_du_gest_req = false;
        this.birth_mot_age_req = false;
        this.birth_parity_times_req = false;
        this.birth_wh_born_req = false;
        this.birth_baby_fed_req = false;
        this.birth_reason_req = false;
        this.birth_bre_du_req = false;
        this.birth_bre_frq_req = false;
        this.birth_bot_du_req = false;
        this.birth_bot_frq_req = false;
        this.birth_type_nipple_req = false;
      }

    }
  
  }
   //Get Childhood disease

  get_child_hood_dis_array() {
    var obj = masterCSData_Helper.getMasterChildhoodDisease();
    this.child_hood_dis_array = obj.childhood;
    setTimeout(() => {
      this.n = this.n + 10;
    }, 1000);
  }

  option(d){
    this.ynoption=!this.ynoption;
    if(this.ynoption==true){
      switch(d){
        case 12:{this.ynopt12=true;this.child_wtxt="yes";break;}
      }
    }
    if(this.ynoption==false){
      switch(d){
         case 12:{this.ynopt12=false;this.child_wtxt="no";break;}

      }

    }
    

  }


   getColor(v){
    if(v=="Yes"){
      return "green";
    }
    if(v="No"){
      return "red";
    }
  }

  send_data() {
    this.get_spl_name =Doc_Helper.get_dent_spl();
    

    var endo_treat = "no", child_visit = "no", diet_flag_arry = "no", ortho_treat = "no", any_req = "no", area_spec_dis = undefined, bleed_injury = "no", serious_operation = "no";

   
    // if (this.diet_details_array.length != 0) {
    //   diet_flag_arry = "yes";
    // }
    // general history spl
    //var this.gen_hist = null;

   
     if (this.get_spl_name == "Periodontics") {

      this.gen_hist = {
       // den_was_flag: denwa_flg,
        int_oral_flag: intr_flg,
        //supra_flag: super_tx,
        //tee_exam_pub_flag: teet_exampubflg,
       // teeth_exam_flag: texam_flg,
       // gingiva_flag: ging_flg,
        //gin_exam_flag: gin_exfig,
       // overbite_flag: over_flg,
        //medi_hist_dis_flag: med_flg,
        // perio specific disease
       
       
      }
    }
    else if (this.get_spl_name == "Orthodontics") {

      var post_ex = "no", pre_txt = "no", birth_flag = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      var  hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      if (this.breast_fed_dur_txt != undefined || this.breast_fed_freq_txt != undefined || this.bottle_fed_dur_txt != undefined || this.bottle_fed_freq_txt != undefined ||
        this.weaned_out_txt != undefined || this.child_hood_dis != undefined || this.type_nipple_txt != undefined) {
        birth_flag = "yes";
      }


      this.gen_hist = {
        overbite_flag: over_biteflg,
        //bite_condition_flag: bite_conflg,
       // resp_flag: resp_flgtxt,
        habit_exists: hab_txt,

        post_exists: post_ex,
        pre_exists: pre_txt,

        //fam_exists: fam_flg,

        int_oral_flag: intr_flg,
       // oral_hygeine_flag: oral_falg,
        birth_exists: birth_flag,

        physical_status_flag: physical_status_flag,
        TMJ_option: tmj_fun,
        pain_condition: ho_pain,
        sounds_cond: ho_sound,
        tend_palp_cond: tmj_trend,
        muscle_tend_palp: mus_trend,

        analysis_required: any_req,

        //birth
        breast_fed_dur: this.breast_fed_dur_txt,
        breast_fed_freq: this.breast_fed_freq_txt,
        bottle_fed_dur: this.bottle_fed_dur_txt,
        bottle_fed_freq: this.bottle_fed_freq_txt,
        weaned_out: this.weaned_out_txt,
        den_child_disease_id: this.child_hood_dis,
        //den_ging_colour: this.ging_col,
        type_nipple: this.type_nipple_txt,
        //general
      //  hsitory_previous: this.his_ortho,
        ortho_treat: ortho_treat,
        //natal
       
      }
    


    }else if (this.get_spl_name == "Pediatrics") {
      var over_biteflg = "no", hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", ped_histflg = "no", over_flg = "no", soft_flag = "no", oral_habit = "Yes", type_habit_id = 0, child_went_home = "no";
       if (this.breast_fed_dur_txt != undefined || this.breast_fed_freq_txt != undefined || this.bottle_fed_dur_txt != undefined || this.bottle_fed_freq_txt != undefined ||
        this.weaned_out_txt != undefined || this.child_hood_dis != undefined || this.type_nipple_txt != undefined) {
        birth_flag = "yes";
      }
      if (this.child_wtxt == "yes") {
        
        child_went_home = "yes";
      } 
        this.gen_hist = {
        int_oral_flag: intr_flg,
      //  overbite_flag: over_biteflg,
       // ped_oth_flag: ped_flag,
        habit_exists: hab_txt,
        post_exists: post_ex, pre_exists: pre_txt,
        //birth_exists: birth_flag,
       // ped_per_hist_flag: ped_histflg,
        ort_eval_exists: over_flg,  softisoth_flag: soft_flag,
        analysis_required: any_req,
        // ort_eval_exists: this.orat_evalflg
        //birth
        breast_fed_dur: this.breast_fed_dur_txt,
        breast_fed_freq: this.breast_fed_freq_txt,
        bottle_fed_dur: this.bottle_fed_dur_txt,
        bottle_fed_freq: this.bottle_fed_freq_txt,
        weaned_out: this.weaned_out_txt,
        den_child_disease_id: this.child_hood_dis,
        //den_ging_colour: this.ging_col,
        type_nipple: this.type_nipple_txt,
        gestation_duration: this.birth_dur_ges,
        mot_age_deli: this.birth_mother_age,
        parity_times: this.birth_parity_times,
        maternal_insults: this.birth_mate_ill,
        where_born: this.birth_wh_born,
        diffi_deliv: this.birth_dif_deli,
        resuscitation: this.birth_res,
        int_care_req: this.birth_int_score,
        diseases: this.birth_dis,
        apgar_score: this.birth_apg_score,
        child_health_cond: child_went_home,
        reason: this.birth_reason,
        baby_fed: this.birth_baby_fed, 
          den_habit_type_id: type_habit_id,
        //diet_details: this.diet_details_array,
        diet_flag: diet_flag_arry,
        // ped_per_hist_flag:ped_histflg,
        diet_analysis_flag: diet_nalsis_flg,
        oral_habit_flag: oral_habit,
     }

    }
    else if (this.get_spl_name == "Prosthodontics") {
      var resp_flgtxt = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no";

      var intr_flg = "no"
      this.gen_hist = {
        int_oral_flag: intr_flg,
        //oral_hygeine_flag: oral_falg,
       // resp_flag: resp_flgtxt,

      
        TMJ_option: tmj_fun,
        pain_condition: ho_pain,
        sounds_cond: ho_sound,
        tend_palp_cond: tmj_trend,
        muscle_tend_palp: mus_trend,

      }
    }
    else if (this.get_spl_name == "Public health") {
      var hab_txt = "no", post_ex = "no", diet_nalsis_flg = "no", vital_sign_flag = "no", soft_flag = "no", gin_exfig = "no", ging_flg = "no", texam_flg = "no", teet_exampubflg = "no";


     



      var intr_flg = "no"

     

      this.gen_hist = {
       // den_was_flag: denwa_flg,
        int_oral_flag: intr_flg,
        habit_exists: hab_txt,
        post_exists: post_ex,

        diet_analysis_flag: diet_nalsis_flg,
        tee_exam_pub_flag: teet_exampubflg,
    

        teeth_exam_flag: texam_flg,
       // gingiva_flag: ging_flg,
       // gin_exam_flag: gin_exfig,
        vital_sign_flag: vital_sign_flag,
        softisoth_flag: soft_flag,
        //diet_details: this.diet_details_array,
        diet_flag: diet_flag_arry,

     
      }
    }
    Helper_Class.Set_den_spl_birth(this.gen_hist);
    
  }
  changechildwenthome(e) {
 
    this.child_wtxt1  = e;
    if(e == false){
      //this.ill_operation_txt=true;
      this.child_wtxt="no";
      document.getElementById("child_wtxt1").style.background = "red";
      
     // this.ho_sound_text = false;
    }
    else{
     // this.ill_operation_txt=false;
      this.child_wtxt="yes";
      document.getElementById("child_wtxt1").style.background = "green";
     
    
     // this.ho_sound_text = true;
    }
  
  }
}
