import { Component, OnInit, HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { MatDialog } from '@angular/material/dialog';
import { NephroHistoryComponent } from '../nephro-history/nephro-history.component';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-nephrology-physical',
  templateUrl: './nephrology-physical.component.html',
  styleUrls: ['./nephrology-physical.component.css']
})
export class NephrologyPhysicalComponent implements OnInit {
  public nephphysicalFlag ="min";
  subscription:Subscription;
  public minflag:boolean=true;

  public dutarion_symplist = [];
  public skin_type_array = [];
  public send_skin_type_array = [];
  public hearing_type_array = [];
  public send_hearing_type_array = [];
  public Abdomen_type_array = [];
  public send_Abdomen_type_array = [];
  public Eye_type_array = [];
  public send_Eye_type_array = [];
  public Cardio_type_array = [];
  public send_Cardio_type_array = [];
  public Respiratory_type_array = [];
  public send_Respiratory_type_array = [];
  public Causes_type_array = [];
  public send_Causes_type_array = [];
  public Medical_type_array = [];
  public send_Medical_type_array = [];
  public Complications_type_array = [];
  public send_Complications_type_array = [];
  public skin_disable: boolean;
  public skin_dur_desc;
  public hear_disable: boolean;
  public hearing_duration;
  public abd_disable: boolean;
  public abdomen_duration;
  public eye_disable: boolean;
  public eye_duration;
  public cardio_disable: boolean;
  public cardio_duration;
  public res_disable: boolean;
  public personal_hist: boolean;
  public resp_duration;
  public current_occ_txt;
  public phy_act_txt;
  public skin_type;
  public hearing_type;
  public Abdomen_type;
  public Eye_type;
  public Cardio_type;
  public Respiratory_type;
  public neph_other_txt;
  public Causes_type;
  public Medical_type;
  public Complications_type;
  public neph_stress_fact;
  public gender;
  public age_txt;
  public life_style_id;
  public header_footer_flag: boolean;
  public ismobile:boolean;

  public Comple_hidden: boolean;
  public Causes_dis: boolean;
  public Resp_hidden: boolean;
  public Cardio_hidden: boolean;
  public Eye_hidden: boolean;
  public Abdomen_hidden: boolean;
  public hearing_hidden: boolean;
  public skin_hidden: boolean;
  public stress_hidden: boolean;
  public physical_hidden: boolean;
  public pastmeical_hidden: boolean;
  public symp_others: boolean;
  public age_hidden: boolean;
  public Gender_hidden: boolean;
  public currnetoccupation_hidden: boolean;
  public agefield: boolean;

  public skinflag:string;
  public hearflag:string;
  public abdomenflag:string;
  public eyeflag:string;
  public cardioflag:string;
  public respflag:string;

  public skinpopup_flag:boolean=false;
  public skinvalue_type_array = [];
  public hearingpopup_flag:boolean=false;
  public hearingvalue_type_array = [];
  public abdomenpopup_flag:boolean=false;
  public abdomenvalue_type_array = [];
  public eyepopup_flag:boolean=false;
  public eyevalue_type_array = [];
  public cardiopopup_flag:boolean=false;
  public cardiovalue_type_array = [];
  public resppopup_flag:boolean=false;
  public respvalue_type_array = [];
  public diseasepopup_flag:boolean=false;
  public diseasevalue_type_array = [];
  public medhistpopup_flag:boolean=false;
  public medhistvalue_type_array = [];
  public complicationpopup_flag:boolean=false;
  public complicationvalue_type_array = [];

  public patient_list;
  public userinfo;
  public user_id;
  public patient_name;

  public client:boolean = false;

  constructor(public dialog:MatDialog,public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,
    private router:Router,public toastr:ToastrService,public masterCSdata:MasterCSHelperService) { 
    this.skin_disable = true;
    this.hear_disable = true;
    this.abd_disable = true;
    this.eye_disable = true;
    this.cardio_disable = true;
    this.res_disable = true;
    this.personal_hist = true;
    this.agefield=true;

    this.Comple_hidden = true;
    this.Causes_dis = true;
    this.Resp_hidden = true;
    this.Cardio_hidden = true;
    this.Eye_hidden = true;
    this.Abdomen_hidden = true;
    this.hearing_hidden = true;
    this.skin_hidden = true;
    this.stress_hidden = true;
    this.physical_hidden = true;
    this.pastmeical_hidden = true;
    this.symp_others = true;
    this.age_hidden = true;
    this.Gender_hidden = true;
    this.currnetoccupation_hidden = true;
  }

  async ngOnInit(){
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.nephphysicalFlag = message;
      }
    });
    
    if(Doc_Helper.getAppFlow() == "client" ){
      this.patient_list = Helper_Class.getInfo();
      this.client = true;

    }else{
      this.userinfo = Helper_Class.getInfo();
      this.user_id = this.userinfo.user_id;
      this.client = false;
      this.patient_list = Doc_Helper.getClient_Info();
    }
    
    this.patient_name = this.patient_list.client_name;
    this.phy_act_txt = "None";

    if (this.patient_list.Gender_data != "" || this.patient_list.Gender_data != undefined) {
      this.gender = this.patient_list.Gender_data;
    }
    if (this.patient_list.Age_data != "" || this.patient_list.Age_data != undefined) {
      this.age_txt =this.patient_list.Age_data;
    }

    if(this.age_txt ==undefined && this.age_txt ==""){
      this.agefield=false;
    }

    // show/hide hospital fields
    var getHopitalfieldsRet= Helper_Class.getHopitalfieldsRet();
    if (getHopitalfieldsRet != undefined) {
      for (var i = 0; i < getHopitalfieldsRet.length; i++) {
        if (getHopitalfieldsRet[i].page_name == "Physical") {
          if (getHopitalfieldsRet[i].fields != undefined) {

            if (getHopitalfieldsRet[i].fields != undefined) {
              for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
                if (getHopitalfieldsRet[i].fields[j].field_name == "Current occupation") {
                  this.currnetoccupation_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Gender") {
                  this.Gender_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Age") {
                  this.age_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Past medical history") {
                  this.pastmeical_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Physical activities") {
                  this.physical_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Stress factors") {
                  this.stress_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Skin") {
                  this.skin_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Hearing") {
                  this.hearing_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Abdomen") {
                  this.Abdomen_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "eye") {
                  this.Eye_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Cardiovascular") {
                  this.Cardio_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Respiratory / Pulmonary") {
                  this.Resp_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Causes of disease") {
                  this.Causes_dis = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Complications") {
                  this.Comple_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Others") {
                  this.symp_others = false;
                }
              }
            }
          }
        }
      }
    }

    if(masterCSData_Helper.getMasterSkin() == undefined){
      await this.masterCSdata.getSkin();
      this.Get_skin_type_array();
    }else{
      this.Get_skin_type_array();
    }
  }
  
  skin_click(selected) {// Skin popup 
    this.skin_type = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_skin_type_array = selected;

    if (this.send_skin_type_array.length != 0) {
      this.skin_disable = false;
      var n = 0;

      for (var j = 0; j < this.send_skin_type_array.length; j++) {
        for (var i = 0; i < this.skin_type_array.length; i++) {
          if (this.send_skin_type_array[j] == this.skin_type_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.skin_type_array[i].label);
          }
        }
      }
      this.skin_type = selected_data.toString();

    } else {
      this.skin_dur_desc = undefined;
      this.skin_disable = true;
    }
  }

  async Get_skin_type_array() {// Get skin master data
    this.skin_type_array = [];
    var obj = masterCSData_Helper.getMasterSkin();
    if (obj != undefined) {
      if (obj.skin_diseases != null) {
        for (var i = 0; i < obj.skin_diseases.length; i++) {
          this.skin_type_array.push({
            type: 'checkbox',
            value: obj.skin_diseases[i].skin_dis_id,
            label: obj.skin_diseases[i].description,
            checked: false
          });
        }
      }
    }
    Doc_Helper.setSkin_types(this.skin_type_array);
    if(masterCSData_Helper.getMasterHearing() == undefined){
      await this.masterCSdata.getHearing();
      this.Get_hearing_type_array();
    }else{
      this.Get_hearing_type_array();
    }  
  }

  skin_popup() {
    if(this.client == false){
      for (var j = 0; j < this.skin_type_array.length; j++) {
        this.skin_type_array[j].checked = false;
      }

      if (this.send_skin_type_array != undefined && this.send_skin_type_array.length != 0) {
        for (var i = 0; i < this.send_skin_type_array.length; i++) {
          for (var j = 0; j < this.skin_type_array.length; j++) {
            if (this.send_skin_type_array[i] == this.skin_type_array[j].value) {
              this.skin_type_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Skin",
          main_array: this.skin_type_array,
          selected_list:this.send_skin_type_array,
        } 
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.skin_click(result);
          }
      });
    }
  }

  hearing_popup() {
    if(this.client == false){
      for (var j = 0; j < this.hearing_type_array.length; j++) {
        this.hearing_type_array[j].checked = false;
      }

      if (this.send_hearing_type_array != undefined && this.send_hearing_type_array.length != 0) {
        for (var i = 0; i < this.send_hearing_type_array.length; i++) {
          for (var j = 0; j < this.hearing_type_array.length; j++) {
            if (this.send_hearing_type_array[i] == this.hearing_type_array[j].value) {
              this.hearing_type_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Hearing",
          main_array: this.hearing_type_array,
          selected_list:this.send_hearing_type_array,
        } 
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.hearing_click(result);
          }
      });
    }
  }

  hearing_click(selected) {
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");

    this.hearing_type = "";
    this.send_hearing_type_array = selected;
    if (this.send_hearing_type_array.length != 0) {
      this.hear_disable = false;
      var n = 0;

      for (var j = 0; j < this.send_hearing_type_array.length; j++) {
        for (var i = 0; i < this.hearing_type_array.length; i++) {
          if (this.send_hearing_type_array[j] == this.hearing_type_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.hearing_type_array[i].label);
          }
        }
      }
      this.hearing_type = selected_data.toString();

    } else {
      this.hearing_duration = undefined;
      this.hear_disable = true;
    }
  }

  async Get_hearing_type_array() {
    this.hearing_type_array = [];
    var obj = masterCSData_Helper.getMasterHearing();
    if (obj != undefined) {
      if (obj.hearing_diseases != null) {
        for (var i = 0; i < obj.hearing_diseases.length; i++) {
          this.hearing_type_array.push({
            type: 'checkbox',
            value: obj.hearing_diseases[i].diab_hearing_id,
            label: obj.hearing_diseases[i].description,
            checked: false
          });
        }
        Doc_Helper.setHearing_types(this.skin_type_array);
      }
    }
    if(masterCSData_Helper.getMasterAbdomenType() == undefined){
      await this.masterCSdata.getAbdomenType();
      this.Get_Abdomen_type_array();
    }else{
      this.Get_Abdomen_type_array();
    }  
  }

  Abdomen_popup() {
    if(this.client == false){
      for (var j = 0; j < this.Abdomen_type_array.length; j++) {
        this.Abdomen_type_array[j].checked = false;
      }

      if (this.send_Abdomen_type_array != undefined && this.send_Abdomen_type_array.length != 0) {
        for (var i = 0; i < this.send_Abdomen_type_array.length; i++) {
          for (var j = 0; j < this.Abdomen_type_array.length; j++) {
            if (this.send_Abdomen_type_array[i] == this.Abdomen_type_array[j].value) {
              this.Abdomen_type_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Abdomen",
          main_array: this.Abdomen_type_array,
          selected_list:this.send_Abdomen_type_array,
        } 
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.Abdomen_click(result);
          }
      });
    }
  }

  Abdomen_click(selected) {
    this.Abdomen_type = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_Abdomen_type_array = selected;
    if (this.send_Abdomen_type_array.length != 0) {
      this.abd_disable = false;
      var n = 0;

      for (var j = 0; j < this.send_Abdomen_type_array.length; j++) {
        for (var i = 0; i < this.Abdomen_type_array.length; i++) {
          if (this.send_Abdomen_type_array[j] == this.Abdomen_type_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }

            selected_data.append(this.Abdomen_type_array[i].label);
          }
        }
      }
      this.Abdomen_type = selected_data.toString();

    } else {
      this.abdomen_duration = undefined;
      this.abd_disable = true;
    }
  }
 
  async Get_Abdomen_type_array() { // Get Abdomen master data
    this.Abdomen_type_array = [];
    var obj = masterCSData_Helper.getMasterAbdomenType();
    if (obj != undefined) {
      if (obj.abdomen_diseases != null) {
        for (var i = 0; i < obj.abdomen_diseases.length; i++) {
          this.Abdomen_type_array.push({
            type: 'checkbox',
            value: obj.abdomen_diseases[i].abdomen_dis_id,
            label: obj.abdomen_diseases[i].description,
            checked: false
          });
        }
        Doc_Helper.setAbdomen_types(this.Abdomen_type_array);
      }
    }
    if(masterCSData_Helper.getMasterRetino() == undefined){
      await this.masterCSdata.getRetinopathy();
      this.Get_Eye_type_array();
    }else{
      this.Get_Eye_type_array();
    }
  }

  Eye_popup() {
    if(this.client == false){
      for (var j = 0; j < this.Eye_type_array.length; j++) {
        this.Eye_type_array[j].checked = false;
      }

      if (this.send_Eye_type_array != undefined && this.send_Eye_type_array.length != 0) {
        for (var i = 0; i < this.send_Eye_type_array.length; i++) {
          for (var j = 0; j < this.Eye_type_array.length; j++) {
            if (this.send_Eye_type_array[i] == this.Eye_type_array[j].value) {
              this.Eye_type_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Eye",
          main_array: this.Eye_type_array,
          selected_list:this.send_Eye_type_array,
        } 
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.Eye_click(result);
          }
      });
    }
  }

  Eye_click(selected) {
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Eye_type = "";
    this.send_Eye_type_array = selected;
    if (this.send_Eye_type_array.length != 0) {
      this.eye_disable = false;
      var n = 0;

      for (var j = 0; j < this.send_Eye_type_array.length; j++) {
        for (var i = 0; i < this.Eye_type_array.length; i++) {
          if (this.send_Eye_type_array[j] == this.Eye_type_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Eye_type_array[i].label);
          }
        }
      }
      this.Eye_type = selected_data.toString();

    } else {
      this.eye_duration = undefined;
      this.eye_disable = true;
    }
  }

  async Get_Eye_type_array() {// Get eye master data
    this.Eye_type_array = [];
    var obj = masterCSData_Helper.getMasterRetino();
    if (obj != undefined) {
      if (obj.eye_diseases != null) {
        for (var i = 0; i < obj.eye_diseases.length; i++) {
          this.Eye_type_array.push({
            type: 'checkbox',
            value: obj.eye_diseases[i].eye_dis_id,
            label: obj.eye_diseases[i].description,
            checked: false
          });
        }
        Doc_Helper.setEye_types(this.Eye_type_array);
      }
    }
    if(masterCSData_Helper.getMasterCardiovasular() == undefined){
      await this.masterCSdata.getCardiovascular();
      this.Get_Cardio_type_array();
    }else{
      this.Get_Cardio_type_array();
    }
  }

  Cardio_popup() {
    this.cardiopopup_flag = true;
    for (var j = 0; j < this.Cardio_type_array.length; j++) {
      this.Cardio_type_array[j].checked = false;
    }

    if (this.send_Cardio_type_array != undefined && this.send_Cardio_type_array.length != 0) {
      for (var i = 0; i < this.send_Cardio_type_array.length; i++) {
        for (var j = 0; j < this.Cardio_type_array.length; j++) {
          if (this.send_Cardio_type_array[i] == this.Cardio_type_array[j].value) {
            this.Cardio_type_array[j].checked = true;
          }
        }
      }
    }

    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Cardiovascular",
        main_array: this.Cardio_type_array,
        selected_list:this.send_Cardio_type_array,
      } 
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.Cardio_click(result);
        }
    });
  }

  Cardio_click(selected) {
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    this.Cardio_type = "";
    var selected_data = new StringBuilder("");

    this.send_Cardio_type_array = selected;
    if (this.send_Cardio_type_array.length != 0) {
      this.cardio_disable = false;
      var n = 0;

      for (var j = 0; j < this.send_Cardio_type_array.length; j++) {
        for (var i = 0; i < this.Cardio_type_array.length; i++) {
          if (this.send_Cardio_type_array[j] == this.Cardio_type_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.Cardio_type_array[i].label);
          }
        }
      }

      this.Cardio_type = selected_data.toString();

    } else {
      this.cardio_duration = undefined;
      this.cardio_disable = true;
    }
  }

  async Get_Cardio_type_array() {// Get cardio master data
    this.Cardio_type_array = [];
    var obj = masterCSData_Helper.getMasterCardiovasular();
    if (obj != undefined) {
      if (obj.cardio_diseases != null) {
        for (var i = 0; i < obj.cardio_diseases.length; i++) {
          this.Cardio_type_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.cardio_diseases[i].cardio_id,
            label: obj.cardio_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterRespiratoryType() == undefined){
      await this.masterCSdata.getRespiratoryType();
      this.Get_Respiratory_type_array();
    }else{
      this.Get_Respiratory_type_array();
    }
  }

  Respiratory_popup() {
    if(this.client == false){
      for (var j = 0; j < this.Respiratory_type_array.length; j++) {
        this.Respiratory_type_array[j].checked = false;
      }

      if (this.send_Respiratory_type_array != undefined && this.send_Respiratory_type_array.length != 0) {
        for (var i = 0; i < this.send_Respiratory_type_array.length; i++) {
          for (var j = 0; j < this.Respiratory_type_array.length; j++) {
            if (this.send_Respiratory_type_array[i] == this.Respiratory_type_array[j].value) {
              this.Respiratory_type_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Respiratory / Pulmonary",
          main_array: this.Respiratory_type_array,
          selected_list:this.send_Respiratory_type_array,
        } 
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.Respiratory_click(result);
          }
      });
    }
  }

  Respiratory_click(selected) {
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Respiratory_type = "";
    this.send_Respiratory_type_array = selected;
    if (this.send_Respiratory_type_array.length != 0) {
      this.res_disable = false;

      var n = 0;

      for (var j = 0; j < this.send_Respiratory_type_array.length; j++) {
        for (var i = 0; i < this.Respiratory_type_array.length; i++) {

          if (this.send_Respiratory_type_array[j] == this.Respiratory_type_array[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            
            selected_data.append(this.Respiratory_type_array[i].label);

          }

        }
      }
      this.Respiratory_type = selected_data.toString();

    } else {
      this.resp_duration = undefined;
      this.res_disable = true;
    }
  }

  async Get_Respiratory_type_array() {// Get Respiratory master data
    this.Respiratory_type_array = [];
    var obj = masterCSData_Helper.getMasterRespiratoryType();
    if (obj != undefined) {
      if (obj.respiratory_diseases != null) {
        
        //this.Respiratory_type_array = obj.respiratory_diseases;
        for (var i = 0; i < obj.respiratory_diseases.length; i++) {
          this.Respiratory_type_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.respiratory_diseases[i].pulmonary_dis_id,
            label: obj.respiratory_diseases[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterCausesType() == undefined){
      await this.masterCSdata.getCausesType();
      this.Get_Causes_type_array();
    }else{
      this.Get_Causes_type_array();
    }
  }

  Causes_popup() {
    if(this.client == false){
      for (var j = 0; j < this.Causes_type_array.length; j++) {
        this.Causes_type_array[j].checked = false;
      }
      if (this.send_Causes_type_array != undefined && this.send_Causes_type_array.length != 0) {
        for (var i = 0; i < this.send_Causes_type_array.length; i++) {
          for (var j = 0; j < this.Causes_type_array.length; j++) {
            if (this.send_Causes_type_array[i] == this.Causes_type_array[j].value) {
              this.Causes_type_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Causes Of Disease",
          main_array: this.Causes_type_array,
          selected_list:this.send_Causes_type_array,
        } 
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.Causes_click(result);
          }
      });
    }
  }
  
  Causes_click(selected) {
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");


    this.send_Causes_type_array = selected;

    this.Causes_type = "";
    if (this.send_Causes_type_array.length != 0) {

      var n = 0;

      for (var j = 0; j < this.send_Causes_type_array.length; j++) {
        for (var i = 0; i < this.Causes_type_array.length; i++) {

          if (this.send_Causes_type_array[j] == this.Causes_type_array[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            
            selected_data.append(this.Causes_type_array[i].label);

          }

        }
      }
      this.Causes_type = selected_data.toString();

    }

  }

  async Get_Causes_type_array() {// Get causes master data
    this.Causes_type_array = [];
    var obj = masterCSData_Helper.getMasterCausesType();
    if (obj != undefined) {
      if (obj.neprho_dis_causes != null) {
        for (var i = 0; i < obj.neprho_dis_causes.length; i++) {
          this.Causes_type_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.neprho_dis_causes[i].nephro_dis_causes_id,
            label: obj.neprho_dis_causes[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterComplicationsType() == undefined){
      await this.masterCSdata.getComplicationsType();
      this.Get_Complications_type_array();
    }else{
      this.Get_Complications_type_array();
    }
  }

  Medical_popup() {
    if(this.client == false){
      for (var j = 0; j < this.Medical_type_array.length; j++) {
        this.Medical_type_array[j].checked = false;
      }

      if (this.send_Medical_type_array != undefined && this.send_Medical_type_array.length != 0) {
        for (var i = 0; i < this.send_Medical_type_array.length; i++) {
          for (var j = 0; j < this.Medical_type_array.length; j++) {
            if (this.send_Medical_type_array[i] == this.Medical_type_array[j].value) {
              this.Medical_type_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dialog.open(DropdownModelpageComponent, {
        width: '500px',
        height: '400px',
        data:{
          title: "Medical History",
          main_array: this.Medical_type_array,
          selected_list:this.send_Medical_type_array,
        } 
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result != undefined){
          this.Medical_click(result);
          }
      });
    }
  }

  Medical_click(selected) {
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Medical_type = "";


    this.send_Medical_type_array = selected;

    if (this.send_Medical_type_array.length != 0) {

      var n = 0;

      for (var j = 0; j < this.send_Medical_type_array.length; j++) {
        for (var i = 0; i < this.Medical_type_array.length; i++) {

          if (this.send_Medical_type_array[j] == this.Medical_type_array[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            
            selected_data.append(this.Medical_type_array[i].label);

          }

        }
      }
      

      this.Medical_type = selected_data.toString();

    }
  }

  Get_Medical_type_array() {//Get medical master data
    this.Medical_type_array = [];
    var obj = masterCSData_Helper.getMasterMedicalHistory();
    if (obj != undefined) {
      if (obj.med_hist != null) {
        for (var i = 0; i < obj.med_hist.length; i++) {
          this.Medical_type_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.med_hist[i].med_hist_id,
            label: obj.med_hist[i].description,
            checked: false
          });
          if(obj.med_hist[i].med_hist_id == 32){
            this.Medical_type = obj.med_hist[i].description;
          }
        }
      }
    }
    this.send_Medical_type_array = [];
    if (Helper_Class.getAsg() != undefined) {
      for (var j = 0; j < Helper_Class.getAsg().medical_history.length; j++) {
        this.send_Medical_type_array.push(Helper_Class.getAsg().medical_history[j]);
      }
      this.Medical_click(this.send_Medical_type_array);
    }
    this.retrieveCaseHistData();
  }

  Complications_popup() {
    this.complicationpopup_flag = true;
    for (var j = 0; j < this.Complications_type_array.length; j++) {
      this.Complications_type_array[j].checked = false;
    }


    if (this.send_Complications_type_array != undefined && this.send_Complications_type_array.length != 0) {
      for (var i = 0; i < this.send_Complications_type_array.length; i++) {
        for (var j = 0; j < this.Complications_type_array.length; j++) {
          if (this.send_Complications_type_array[i] == this.Complications_type_array[j].value) {
            this.Complications_type_array[j].checked = true;
          }
        }
      }
    }

    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Complications",
        main_array: this.Complications_type_array,
        selected_list:this.send_Complications_type_array,
      } 
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.Complications_click(result);
        }
    });
  }

  Complications_click(selected) {
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    this.Complications_type = "";
    var selected_data = new StringBuilder("");
    this.send_Complications_type_array = selected;

    if (this.send_Complications_type_array.length != 0) {
      var n = 0;
      for (var j = 0; j < this.send_Complications_type_array.length; j++) {
        for (var i = 0; i < this.Complications_type_array.length; i++) {
          if (this.send_Complications_type_array[j] == this.Complications_type_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            
            selected_data.append(this.Complications_type_array[i].label);
          }
        }
      }
      this.Complications_type = selected_data.toString();
    }
  }

  async Get_Complications_type_array() {//Get Complications master data
    this.Complications_type_array = [];
    var obj = masterCSData_Helper.getMasterComplicationsType();
    if (obj != undefined) {
      if (obj.neprho_complications != null) {
        for (var i = 0; i < obj.neprho_complications.length; i++) {
          this.Complications_type_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.neprho_complications[i].nephro_complication_id,
            label: obj.neprho_complications[i].description,
            checked: false
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterDurationSymptom() == undefined){
      await this.masterCSdata.getDurationSymptom();
      this.Get_duration_symptiom();
    }else{
      this.Get_duration_symptiom();
    }
  }

  async Get_duration_symptiom() {
    this.dutarion_symplist = [];
    var obj = masterCSData_Helper.getMasterDurationSymptom();
    if (obj != undefined) {
      if (obj.neprho_symp_duration != undefined) {
        this.dutarion_symplist = obj.neprho_symp_duration;
      }
    }
    if(masterCSData_Helper.getMasterMedicalHistory() == undefined){
      await this.masterCSdata.getMedicalHistory();
      this.Get_Medical_type_array();        
    }else{
      this.Get_Medical_type_array();        
    }
  }

  send_data() {
    if (this.Gender_hidden == false) {
      Helper_nephro_casesheet.neph_gender = this.gender;
    } else {
      Helper_nephro_casesheet.neph_gender = "";
    }

    if (this.age_txt != undefined) {
      Helper_nephro_casesheet.neph_age = this.age_txt;
    } else {
      Helper_nephro_casesheet.neph_age = "0";
    }

    if (this.currnetoccupation_hidden == false) {
      Helper_nephro_casesheet.neph_occ = this.current_occ_txt;
    } else {
      Helper_nephro_casesheet.neph_occ = null;
    }

    var casuse_flag = "no", med_hist_flag = "no", comp_flag = "no";
    if (this.send_Causes_type_array.length != 0) {
      casuse_flag = "yes";
    }

    if (this.send_Medical_type_array.length != 0) {
      med_hist_flag = "yes";
    }

    if (this.send_Complications_type_array.length != 0) {
      comp_flag = "yes";
    }
    
    if (this.send_skin_type_array.length != 0) {
      this.skinflag = "yes";
    }else{
      this.skinflag = "no";
    }
    if (this.send_hearing_type_array.length != 0) {
      this.hearflag = "yes";
    }else{
      this.hearflag = "no";
    }
    if (this.send_Abdomen_type_array.length != 0) {
      this.abdomenflag = "yes";
    }else{
      this.abdomenflag = "no";
    }
    if (this.send_Eye_type_array.length != 0) {
      this.eyeflag = "yes";
    }else{
      this.eyeflag = "no";
    }
    if (this.send_Cardio_type_array.length != 0) {
      this.cardioflag = "yes";
    }else{
      this.cardioflag = "no";
    }
    if (this.send_Respiratory_type_array.length != 0) {
      this.respflag = "yes";
    }else{
      this.respflag = "no";
    }
    
    Helper_nephro_casesheet.neph_phy_act = this.phy_act_txt;
    Helper_nephro_casesheet.skin_array = this.send_skin_type_array;
    Helper_nephro_casesheet.skin_du_array = this.skin_dur_desc;
    Helper_nephro_casesheet.skin_flag = this.skinflag;

    Helper_nephro_casesheet.hear_array = this.send_hearing_type_array;
    Helper_nephro_casesheet.hear_du_array = this.hearing_duration;
    Helper_nephro_casesheet.hear_flag = this.hearflag;

    Helper_nephro_casesheet.abd_array = this.send_Abdomen_type_array;
    Helper_nephro_casesheet.abd_du_array = this.abdomen_duration;
    Helper_nephro_casesheet.abdomen_flag = this.abdomenflag;
    
    Helper_nephro_casesheet.eye_array = this.send_Eye_type_array;
    Helper_nephro_casesheet.eye_du_array = this.eye_duration;
    Helper_nephro_casesheet.eye_flag = this.eyeflag;

    Helper_nephro_casesheet.cardio_array = this.send_Cardio_type_array;
    Helper_nephro_casesheet.cardio_du_array = this.cardio_duration;
    Helper_nephro_casesheet.cardio_flag = this.cardioflag;

    Helper_nephro_casesheet.res_array = this.send_Respiratory_type_array;
    Helper_nephro_casesheet.res_du_array = this.resp_duration;
    Helper_nephro_casesheet.resp_flag = this.respflag;

    Helper_nephro_casesheet.neph_other = this.neph_other_txt;
    
    Helper_nephro_casesheet.cau_dis_array = this.send_Causes_type_array;
    Helper_nephro_casesheet.disease_cause_flag = casuse_flag;

    Helper_nephro_casesheet.med_hist_array = this.send_Medical_type_array;
    Helper_nephro_casesheet.medical_history_flag = med_hist_flag;

    Helper_nephro_casesheet.complications_array = this.send_Complications_type_array;
    Helper_nephro_casesheet.comp_history_flag = comp_flag;

    Helper_nephro_casesheet.neph_stress = this.neph_stress_fact;
  }
  
  personal_hist_click() {// Personal history popup
    const dialogRef1 = this.dialog.open(NephroHistoryComponent, {
      width: '700px',
      height: '500px',
      data:{hist_type:"personal"}
    });
  
    dialogRef1.afterClosed().subscribe(result => {});
  }

  personal_history(neph_case_hist_id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'neph/conchist/', JSON.stringify({
      case_hist_id: neph_case_hist_id,
      country: ipaddress.country_code
    })
      ,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj.complication_history != null && obj.complication_history.length != 0) {
            this.personal_hist = false;
            Helper_nephro_casesheet.personal_hist_array = obj.complication_history;
          }
        },
        error => {});
  }

  // retrival case sheet information
  retrieveCaseHistData() {
    var obj = Helper_nephro_casesheet.nephro_get_ret;
    if (obj.case_hist_id != undefined) {
      this.personal_history(obj.case_hist_id);
    }

    if (obj.curr_occupation != undefined) {
      this.current_occ_txt = obj.curr_occupation;
    }

    if(obj.age != undefined ){
      this.age_txt = obj.age;
      if(this.age_txt =="0"){
        this.age_txt="<1";
      }
    } else if(Helper_Class.getClntAge() =="0"){
      this.age_txt="<1";
    } else {
      this.age_txt = Helper_Class.getClntAge();
    }
    if(this.age_txt == undefined){
      this.age_txt="";
      this.agefield=false;
    }

    this.send_skin_type_array = [];
    if (obj.skin_concerns != null) {
      for (var j = 0; j < obj.skin_concerns.length; j++) {
        this.send_skin_type_array.push(obj.skin_concerns[j].skin_concern_id);
        this.skinvalue_type_array=this.send_skin_type_array;
      }
      this.skin_click(this.send_skin_type_array);
    }

    if (obj.skin_symptom_dur != undefined) {
      this.skin_dur_desc = obj.skin_symptom_dur;
    }
    this.send_hearing_type_array = [];

    if (obj.hearing_concerns != null) {
      for (var j = 0; j < obj.hearing_concerns.length; j++) {
        this.send_hearing_type_array.push(obj.hearing_concerns[j].hear_concern_id);
        this.hearingvalue_type_array = this.send_hearing_type_array;
      }
      this.hearing_click(this.send_hearing_type_array);
    }

    if (obj.hear_symptom_dur != undefined) {
      this.hearing_duration = obj.hear_symptom_dur;
    }

    this.send_Abdomen_type_array = [];
    if (obj.abdomen_concerns != null) {
      for (var j = 0; j < obj.abdomen_concerns.length; j++) {
        this.send_Abdomen_type_array.push(obj.abdomen_concerns[j].abdomen_concern_id);
        this.abdomenvalue_type_array = this.send_Abdomen_type_array;
      }
      this.Abdomen_click(this.send_Abdomen_type_array);
    }
    if (obj.abdomen_symptom_dur != undefined) {
      this.abdomen_duration = obj.abdomen_symptom_dur;
    }

    this.send_Eye_type_array = [];
    if (obj.retino_concerns != null) {
      for (var j = 0; j < obj.retino_concerns.length; j++) {
        this.send_Eye_type_array.push(obj.retino_concerns[j].retino_concern_id);
        this.eyevalue_type_array=this.send_Eye_type_array;
      }
      this.Eye_click(this.send_Eye_type_array);
    }

    if (obj.retino_symptom_dur != undefined) {
      this.eye_duration = obj.retino_symptom_dur;
    }

    this.send_Cardio_type_array = [];
    if (obj.cardio_concerns != null) {
      for (var j = 0; j < obj.cardio_concerns.length; j++) {
        this.send_Cardio_type_array.push(obj.cardio_concerns[j].cardio_concern_id);
      }
      this.Cardio_click(this.send_Cardio_type_array);
    }
    if (obj.cardio_symption_dur != undefined) {
      this.cardio_duration = obj.cardio_symption_dur;
    }

    this.send_Respiratory_type_array = [];
    if (obj.respiratory_concerns != null) {
      for (var j = 0; j < obj.respiratory_concerns.length; j++) {
        this.send_Respiratory_type_array.push(obj.respiratory_concerns[j].resp_concern_id);
        this.respvalue_type_array = this.send_Respiratory_type_array;
      }
      this.Respiratory_click(this.send_Respiratory_type_array);
    }
    if (obj.resp_symptom_dur != undefined) {
      this.resp_duration = obj.resp_symptom_dur;
    }

    if (obj.others != undefined) {
      this.neph_other_txt = obj.others;
    }
    if (obj.stress_factor != undefined) {
      this.neph_stress_fact = obj.stress_factor;
    }

    if (obj.complications != null) {
      this.send_Complications_type_array = [];
      for (var j = 0; j < obj.complications.length; j++) {
        this.send_Complications_type_array.push(obj.complications[j].nephro_dis_id);
        this.complicationvalue_type_array = this.send_Complications_type_array;
      }
      this.Complications_click(this.send_Complications_type_array);
    }

    if (obj.medical_history != null) {
      this.send_Medical_type_array = [];
      for (var j = 0; j < obj.medical_history.length; j++) {
        this.send_Medical_type_array.push(obj.medical_history[j].med_hist_id);
      }
      this.Medical_click(this.send_Medical_type_array);
    }

    if (obj.disease_causes != null) {
      this.send_Causes_type_array = [];
      for (var j = 0; j < obj.disease_causes.length; j++) {
        this.send_Causes_type_array.push(obj.disease_causes[j].dis_causes_id);
        this.diseasevalue_type_array = this.send_Causes_type_array;
      }
      this.Causes_click(this.send_Causes_type_array);
    }
    if (Helper_nephro_casesheet.diet_habits != undefined) {
         obj.diet_habits = Helper_nephro_casesheet.diet_habits;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.diabetics != undefined) {
            obj.neph_diab = Helper_nephro_casesheet.nephro_get_ret.diabetics
    }

    if (Helper_nephro_casesheet.nephro_get_ret.heart_attack != undefined) {
            obj.neph_heart_attack = Helper_nephro_casesheet.nephro_get_ret.heart_attack;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.stroke != undefined) {
            obj.neph_stroke = Helper_nephro_casesheet.nephro_get_ret.stroke;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.arterial != undefined) {
            obj.neph_arterial = Helper_nephro_casesheet.nephro_get_ret.arterial;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.endocrine != undefined) {
      obj.neph_endo = Helper_nephro_casesheet.nephro_get_ret.endocrine;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.autoimmune != undefined) {
      obj.neph_auto = Helper_nephro_casesheet.nephro_get_ret.autoimmune;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.hypertension != undefined) {
      obj.neph_hyper = Helper_nephro_casesheet.nephro_get_ret.hypertension;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.hemophilia != undefined) {
      obj.neph_hemo = Helper_nephro_casesheet.nephro_get_ret.hemophilia;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.tuberculosis != undefined) {
      obj.neph_tuber = Helper_nephro_casesheet.nephro_get_ret.tuberculosis;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.hiv != undefined) {
      obj.neph_hiv = Helper_nephro_casesheet.nephro_get_ret.hiv;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.hepatitis != undefined) {
      obj.neph_hep = Helper_nephro_casesheet.nephro_get_ret.hepatitis;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.glaucoma != undefined) {
      obj.neph_glacoma = Helper_nephro_casesheet.nephro_get_ret.glaucoma;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.fam_others != undefined) {
      obj.neph_family_other = Helper_nephro_casesheet.nephro_get_ret.fam_others;
    }

          // bmi & vitals
    if (Helper_nephro_casesheet.nephro_get_ret.height != undefined) {
      obj.neph_ht = Helper_nephro_casesheet.nephro_get_ret.height;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.height_measure != undefined) {
      obj.neph_ht_measure = Helper_nephro_casesheet.nephro_get_ret.height_measure;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.weight != undefined) {
      obj.neph_wt = Helper_nephro_casesheet.nephro_get_ret.weight;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.weight_measure != undefined) {
      obj.neph_wt_measure = Helper_nephro_casesheet.nephro_get_ret.weight_measure;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.blood_pressure_sit != undefined) {
      obj.blood_pressure_sit = Helper_nephro_casesheet.nephro_get_ret.blood_pressure_sit;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.heart_rate_sit != undefined) {
      obj.heart_beat_sit = Helper_nephro_casesheet.nephro_get_ret.heart_rate_sit;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.respiratory_rate_sit != undefined) {
      obj.res_rate_sit = Helper_nephro_casesheet.nephro_get_ret.respiratory_rate_sit;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.blood_pressure_sit != undefined) {
      obj.blood_pressure_stand = Helper_nephro_casesheet.nephro_get_ret.blood_pressure_sit;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.heart_rate_stand != undefined) {
      obj.heart_beat_stand = Helper_nephro_casesheet.nephro_get_ret.heart_rate_stand;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.respiratory_rate_stand != undefined) {
      obj.res_rate_stand = Helper_nephro_casesheet.nephro_get_ret.respiratory_rate_stand;
    }
  }
}
