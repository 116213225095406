<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white " style="padding:7px 20px;">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Sample collection</h5>
            </div>
            
            <div class="headerButtons" style="width: 50px; text-align: left;">
              <mat-label class="matlabel">Date:&nbsp; </mat-label>
            </div>
            <div class="headerButtons" style="width: 50px;">
              <input type="date" class="ipcss" (change)="getSampleList(sampledate)" 
              [(ngModel)]="sampledate" #matInput>
            </div>
            <div class="headerButtons" style="width: 50px;">
              <mat-label class="matlabel"> or&nbsp;&nbsp;&nbsp; </mat-label>
            </div>
            <div class="headerButtons" style="width: 50px;">
              <mat-label class="matlabel"> Duration:&nbsp; </mat-label>
            </div>
            <div class="headerButtons" style="width: 50px;">
              <select [(ngModel)]="filtduration" class="ipcss" (change)="getSampleList(sampledate)">
                <option value="select">Select</option>
                <option value="7">1 week</option>
                <option value="30">1 month</option>
                <option value="91">3 months</option>
                <option value="183">6 months</option>
                <option value="365">1 year</option>
              </select>
            </div>
              
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn" (click)="saveSamples()"
                class="saveimgbtn_inpatinfo" />
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <div class="col-12">
              <p class="nodata" [hidden]='sampleList.length!=0'>No test(s) found</p>
              <table *ngIf="sampleList.length" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th>MR no</th>
                    <th>Patient Id</th>
                    <th>Patient name </th>
                    <th>Test </th>
                    <th>Date of collection </th>
                    <th>Time of collection </th>
                    <th style="width: 5%;">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of sampleList">
                    <td style="text-align: left;">{{ list.diagtestid}}</td>
                    <td> {{list.patient_id}}</td>
                    <td>{{list.pat_name}}</td>
                    <td>{{list.test_name}}</td>
                    <td>{{list.collected_date}}</td>
                    <td>{{list.collect_time}}</td>
                    <td style="font-size: 12px;width: 5%;">
                      <mdb-checkbox [(ngModel)]="list.isSelected" name="list.Clnt_Name"
                        value="{{list.Clnt_Name}}"
                        [disabled]="list.disableflag"></mdb-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
