import { Component, OnInit, HostListener, ViewEncapsulation, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardioPopupHistoryComponent } from '../cardio-popup-history/cardio-popup-history.component';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-clinical-exam',
  templateUrl: './clinical-exam.component.html',
  styleUrls: ['./clinical-exam.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ClinicalExamComponent implements OnInit {

  public clinicalFlag = "min";
  public tcchestpain;
  public tcanysym;
  public persoanldata: any;
  public femoralrate_required;
  carotwcont_required;
  subscription: Subscription;
  public medical_Check = [];
  public medical_history_array = [];
  public medical_history_array1 = [];
  public cpainloc_array = [];
  public cpaincar_array = [];
  public cpainrad_array = [];
  public cpainagrel_array = [];
  public cpainasympt_array = [];
  public cpaincause_array = [];
  public btrig_array = [];
  public bonset_array = [];
  public palp_array = [];
  public mucus_array = [];
  public client_reg_id;
  public relation_id;
  public sub_rel_id;
  public spl_id;
  public url;
  public medhistflag: string;
  public feelstress_label: string;
  public stress_label: string;
  public medhist_label: string;
  public symptom_label: string;
  public hdisorder_label: string;
  public treat_label: string;
  public hosp_label: string;
  public hospdet_label: string;
  public pain_label: string;
  public location_label: string;
  public character_label: string;
  public radiation_label: string;
  public duration_label: string;
  public aggravat_label: string;
  public relieve_label: string;
  public assosymp_label: string;
  public intensity_label: string;
  public paincause_label: string;
  public breathlessness_label: string;
  public triggers_label: string;
  public onset_label: string;
  public coldsweats_label: string;
  public dizziness_label: string;
  public vomiting_label: string;
  public palpitation_label: string;
  public palptype_label: string;
  public cough_label: string;
  public mucus_label: string;
  public rightarm_label: string;
  public leftarm_label: string;
  public murmurs_label: string;
  public murmursgrade_label: string;
  public fasting_label: string;
  public prandial_label: string;
  public eyes_label: string;
  public nails_label: string;
  public liptongue_label: string;
  public neck_label: string;
  public face_label: string;
  public precordium_label: string;
  public hand_arm_label: string;
  public abdomen_label: string;
  public lowerlimb_label: string;
  public caprefill_label: string;
  public panelOpenState4: boolean;
  public panelOpenState3: boolean;
  public panelOpenState2: boolean;
  public panelOpenState1: boolean;
  public feelstress: string;
  public hdisorder: string;
  public hosp: string;
  public murmurs: string;
  public pain: string;
  public breathlessness: string;
  public coldsweats: string;
  public vomiting: string;
  public palpitation: string;
  public cough: string;
  public stress: string;
  public medhist: string;
  public symptom: string;
  public treat: string;
  public hospdet: string;
  public location: string;
  public character: string;
  public radiation: string;
  public duration: string;
  public aggravat: string;
  public relieve: string;
  public assosymp: string;
  public intensity: string;
  public paincause: string;
  public triggers: string;
  public onset: string;
  public dizziness: string;
  public palptype: string;
  public mucus: string;
  public rightarm: string;
  public rightarm1: string;
  public leftarm: string;
  public leftarm1: string;
  public murmursgrade: string;
  public fasting: string;
  public prandial: string;
  public eyes: string;
  public nails: string;
  public liptongue: string;
  public neck: string;
  public face: string;
  public precordium: string;
  public hand_arm: string;
  public abdomen: string;
  public lowerlimb: string;
  public caprefill: string;
  public hsound_label: string;
  public hsound: string;

  public radrate: string;
  public radrhythmn: string;
  public radvolume: string;
  public radwtense: string;
  public radwcont: string;
  public bracrate: string;
  public bracrhythmn: string;
  public bracvolume: string;
  public bracwtense: string;
  public bracwcont: string;
  public carotrate: string;
  public carotrhythmn: string;
  public carotvolume: string;
  public carotwtense: string;
  public carotwcont: string;
  public femoralrate: string;
  public femoralrhythmn: string;
  public femoralvolume: string;
  public femoralwtense: string;
  public femoralwcont: string;
  public poplitrate: string;
  public poplitrhythmn: string;
  public poplitvolume: string;
  public poplitwtense: string;
  public poplitwcont: string;
  public postrate: string;
  public postrhythmn: string;
  public postvolume: string;
  public postwtense: string;
  public postwcont: string;
  public dorsalrate: string;
  public dorsalrhythmn: string;
  public dorsalvolume: string;
  public dorsalwtense: string;
  public dorsalwcont: string;
  public abdomrate: string;
  public abdomrhythmn: string;
  public abdomvolume: string;
  public abdomwtense: string;
  public abdomwcont: string;

  public rate_label: string;
  public rhythmn_label: string;
  public volume_label: string;
  public wavetense_label: string;
  public wavecont_label: string;

  public feelstress_hidden: boolean;
  public stress_hidden: boolean;
  public medhist_hidden: boolean;
  public symptom_hidden: boolean;
  public hdisorder_hidden: boolean;
  public treat_hidden: boolean;
  public hosp_hidden: boolean;
  public hospdet_hidden: boolean;
  public pain_hidden: boolean;
  public location_hidden: boolean;
  public character_hidden: boolean;
  public radiation_hidden: boolean;
  public duration_hidden: boolean;
  public aggravat_hidden: boolean;
  public relieve_hidden: boolean;
  public assosymp_hidden: boolean;
  public intensity_hidden: boolean;
  public paincause_hidden: boolean;
  public breathlessness_hidden: boolean;
  public triggers_hidden: boolean;
  public onset_hidden: boolean;
  public coldsweats_hidden: boolean;
  public dizziness_hidden: boolean;
  public vomiting_hidden: boolean;
  public palpitation_hidden: boolean;
  public palptype_hidden: boolean;
  public cough_hidden: boolean;
  public mucus_hidden: boolean;
  public rightarm_hidden: boolean;
  public leftarm_hidden: boolean;
  public murmurs_hidden: boolean;
  public murmursgrade_hidden: boolean;
  public fasting_hidden: boolean;
  public prandial_hidden: boolean;
  public eyes_hidden: boolean;
  public nails_hidden: boolean;
  public liptongue_hidden: boolean;
  public neck_hidden: boolean;
  public face_hidden: boolean;
  public precordium_hidden: boolean;
  public hand_arm_hidden: boolean;
  public abdomen_hidden: boolean;
  public lowerlimb_hidden: boolean;
  public caprefill_hidden: boolean;
  public painaccor: boolean;
  public breathaccor: boolean;
  public physicalaccor: boolean;
  public stress_flag: boolean;
  public hdis_flag: boolean;
  public prevhosp_flag: boolean;
  public murmur_flag: boolean;
  public abdomwtense_required: boolean;
  public abdomwcont_required: boolean;
  public abdomvol_required: boolean;
  public abdomrhythymn_required: boolean;
  public abdomrate_required: boolean;
  public dorsalwcont_required: boolean;
  public dorsalwtense_required: boolean;
  public dorsalvol_required: boolean;
  public dorsalrhythymn_required: boolean;
  public radrate_required: boolean;
  public radwcont_required: boolean;
  public radrhythymn_required: boolean;
  public radvol_required: boolean;
  public radwtense_required: boolean;
  public bracrate_required: boolean;
  public bracrhythymn_required: boolean;
  public bracvol_required: boolean;
  public bracwtense_required: boolean;
  public bracwcont_required: boolean;
  public carotrate_required: boolean;
  public carotrhythymn_required: boolean;
  public carotvol_required: boolean;
  public carotwtense_required: boolean;
  public tcanypain;
  public tcdisorder;
  public pain_flag: boolean;
  public paincause_required: boolean;
  public intensity_required: boolean;
  public assosymp_required: boolean;
  public relieve_required: boolean;
  public aggravat_required: boolean;
  public dur_required: boolean;
  public rad_required: boolean;
  public char_required: boolean;
  public trig_flag: boolean;
  public loc_required: boolean;
  public trig_required: boolean;
  public onset_required: boolean;
  public coldsweat_required: boolean;
  public dizziness_required: boolean;
  public vomiting_required: boolean;
  public palpitation_required: boolean;
  public palptype_required: boolean;
  public cough_required: boolean;
  public mucus_required: boolean;
  public hdisorder_flag: boolean;
  public feelstress_flag: boolean;
  public hosp_flag: boolean;
  public header_footer_flag: boolean;
  public physical_flag: boolean;
  public disable_flag: boolean;
  public pulseaccor: boolean;
  public pulse_flag: boolean;
  public dorsalrate_required: boolean;
  public postwcont_required: boolean;
  public postwtense_required: boolean;
  public postvol_required: boolean;
  public postrhythymn_required: boolean;
  public postrate_required: boolean;
  public poplitwcont_required: boolean;

  public clinical_list;
  public poplitwtense_required;
  public poplitvol_required;
  public poplitrhythymn_required;
  public poplitrate_required;
  public femoralwcont_required;
  public femoralwtense_required;
  public femoralvol_required;
  femoralrhythymn_required;
  public medhist_flag: boolean = false;
  public medhist_value_array = [];

  public family;
  public clinicalflag: boolean;
  public tcanypain1;
  public tcanypain2;

  public tcanypain3;
  public case_hist_id;
  public life_style_id;

  public client: boolean;
  public person_hist1;
  public clinicalhist;

  constructor(public dropdown_dialog: MatDialog, public dialog: MatDialog, public messageservice: CasesheetService, public http: Http,
    public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public masterCSdata: MasterCSHelperService) {
    this.feelstress_hidden = true;

    this.stress_hidden = true;
    this.clinicalflag = true;

    this.medhist_hidden = true;
    this.symptom_hidden = true;
    this.hdisorder_hidden = true;
    this.treat_hidden = true;
    this.hosp_hidden = true;
    this.hospdet_hidden = true;
    this.pain_hidden = true;
    this.location_hidden = true;
    this.character_hidden = true;
    this.radiation_hidden = true;
    this.duration_hidden = true;
    this.aggravat_hidden = true;
    this.relieve_hidden = true;
    this.assosymp_hidden = true;
    this.intensity_hidden = true;
    this.paincause_hidden = true;
    this.breathlessness_hidden = true;
    this.triggers_hidden = true;
    this.onset_hidden = true;
    this.coldsweats_hidden = true;
    this.dizziness_hidden = true;
    this.vomiting_hidden = true;
    this.palpitation_hidden = true;
    this.palptype_hidden = true;
    this.cough_hidden = true;
    this.mucus_hidden = true;
    this.rightarm_hidden = true;
    this.leftarm_hidden = true;
    this.murmurs_hidden = true;
    this.murmursgrade_hidden = true;
    this.fasting_hidden = true;
    this.prandial_hidden = true;
    this.eyes_hidden = true;
    this.nails_hidden = true;
    this.liptongue_hidden = true;
    this.neck_hidden = true;
    this.face_hidden = true;
    this.precordium_hidden = true;
    this.hand_arm_hidden = true;
    this.abdomen_hidden = true;
    this.lowerlimb_hidden = true;
    this.caprefill_hidden = true;
    this.painaccor = true;
    this.breathaccor = true;
    this.physicalaccor = true;
    this.stress_flag = true;
    this.hdis_flag = true;
    this.prevhosp_flag = true;
    this.murmur_flag = true;
    this.pain_flag = true;
    this.paincause_required = true;
    this.intensity_required = true;
    this.assosymp_required = true;
    this.relieve_required = true;
    this.aggravat_required = true;
    this.dur_required = true;
    this.rad_required = true;
    this.char_required = true;
    this.loc_required = true;
    this.trig_flag = true;
    this.trig_required = true;
    this.onset_required = true;
    this.coldsweat_required = true;
    this.dizziness_required = true;
    this.vomiting_required = true;
    this.palpitation_required = true;
    this.palptype_required = true;
    this.cough_required = true;
    this.mucus_required = true;
    this.hdisorder_flag = false;
    this.feelstress_flag = false;
    this.hosp_flag = false;
    this.physical_flag = false;
    this.disable_flag = false;

    this.abdomwcont_required = false;

    this.feelstress_hidden = false;
    this.stress_hidden = false;
    this.medhist_hidden = false;
    this.symptom_hidden = false;
    this.hdisorder_hidden = false;
    this.treat_hidden = false;
    this.hosp_hidden = false;
    this.hospdet_hidden = false;
    this.pain_hidden = false;
    this.location_hidden = false;
    this.character_hidden = false;
    this.radiation_hidden = false;
    this.duration_hidden = false;
    this.aggravat_hidden = false;
    this.relieve_hidden = false;
    this.assosymp_hidden = false;
    this.intensity_hidden = false;
    this.paincause_hidden = false;
    this.breathlessness_hidden = false;
    this.triggers_hidden = false;
    this.onset_hidden = false;
    this.coldsweats_hidden = false;
    this.dizziness_hidden = false;
    this.vomiting_hidden = false;
    this.palpitation_hidden = false;
    this.palptype_hidden = false;
    this.cough_hidden = false;
    this.mucus_hidden = false;
    this.rightarm_hidden = false;
    this.leftarm_hidden = false;
    this.murmurs_hidden = false;
    this.murmursgrade_hidden = false;
    this.fasting_hidden = false;
    this.prandial_hidden = false;
    this.eyes_hidden = false;
    this.nails_hidden = false;
    this.liptongue_hidden = false;
    this.neck_hidden = false;
    this.face_hidden = false;
    this.precordium_hidden = false;
    this.hand_arm_hidden = false;
    this.abdomen_hidden = false;
    this.lowerlimb_hidden = false;
    this.caprefill_hidden = false;

    this.feelstress_label = "Do you feel stressed";
    this.stress_label = "Stress factors";
    this.symptom_label = "Symptoms";
    this.medhist_label = "Past medical history";
    this.hdisorder_label = "Previous heart disorders";
    this.treat_label = "Treatment";
    this.hosp_label = "Previous hospitalization";
    this.hospdet_label = "Hospitalization details";
    this.murmurs_label = "Murmurs";
    this.hsound_label = "Heart sound";
    this.murmursgrade_label = "Murmur grade";
    this.rightarm_label = "Right arm";
    this.leftarm_label = "Left arm";
    this.fasting_label = "Fasting";
    this.prandial_label = "Postprandial";
    this.pain_label = "Do you feel pain in the chest";
    this.location_label = "Location";
    this.character_label = "Character of pain";
    this.radiation_label = "Radiation";
    this.duration_label = "Duration";
    this.aggravat_label = "Aggravating factors";
    this.relieve_label = "Relieving factors";
    this.assosymp_label = "Associated Symptoms";
    this.intensity_label = "Intensity of pain";
    this.paincause_label = "Cause of pain";
    this.breathlessness_label = "Do you feel breathlessness";
    this.triggers_label = "Triggers";
    this.onset_label = "Onset";
    this.coldsweats_label = "Coldsweats";
    this.dizziness_label = "Dizziness";
    this.vomiting_label = "Vomiting";
    this.palpitation_label = "Palpitation";
    this.palptype_label = "Palpitation type";
    this.cough_label = "Cough";
    this.mucus_label = "Mucus";
    this.eyes_label = "Eyes";
    this.nails_label = "Nails";
    this.liptongue_label = "Lip and tongue";
    this.neck_label = "Neck";
    this.face_label = "Face";
    this.precordium_label = "Precordium";
    this.hand_arm_label = "Hand and arms";
    this.abdomen_label = "Abdomen";
    this.lowerlimb_label = "Lower limbs;";
    this.caprefill_label = "Capillary refill time";
    this.rate_label = "Rate";
    this.rhythmn_label = "Rhythm";
    this.volume_label = "Volume (amplitude)";
    this.wavetense_label = "Wave tension";
    this.wavecont_label = "Wave contour";

    this.trig_flag = true;
    this.physical_flag = true;
    this.pain_flag = true;
    this.disable_flag = true;
    this.pulse_flag = true;
  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.clinicalFlag = message;
      }
    });
    if (Doc_Helper.getAppFlow() == "client") {
      this.persoanldata = Helper_Class.getInfo();
      this.client = true;
    } else {
      this.persoanldata = Doc_Helper.getClient_Info();
      this.client = false;
    }
    this.client_reg_id = this.persoanldata.client_id;
    this.relation_id = this.persoanldata.relation_id;
    this.sub_rel_id = this.persoanldata.sub_id,
      this.spl_id = this.persoanldata.spl_id;
    if (Helper_Class.getRet() != undefined) {
      if (Helper_Class.getRet().case_hist_id != null) {
        this.case_hist_id = Helper_Class.getRet().case_hist_id;
      }
      if (Helper_Class.getRet().life_style_id != null) {
        this.life_style_id = Helper_Class.getRet().life_style_id;
      }
    }
    if (masterCSData_Helper.getMasterMedicalHistory() == undefined) {
      await this.masterCSdata.getMedicalHistory();
      this.getMedicalHistoryMstDetails();
    } else {
      this.getMedicalHistoryMstDetails();
    }

    if (masterCSData_Helper.getMasterChestpainLocationDetails() == undefined) {
      await this.masterCSdata.getChestpainLocationDetails();
      this.getChestpainLocationDetails();
    } else {
      this.getChestpainLocationDetails();
    }
  }


  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }

  //Get past medical history
  getMedicalHistoryMstDetails() {
    this.medical_history_array = [];
    var obj = masterCSData_Helper.getMasterMedicalHistory();
    if (obj != undefined) {
      if (obj.med_hist != null) {
        for (var i = 0; i < obj.med_hist.length; i++) {
          this.medical_history_array.push({
            type: 'checkbox',
            value: obj.med_hist[i].med_hist_id,
            label: obj.med_hist[i].description,
            checked: false
          });
          if (obj.med_hist[i].med_hist_id == 32) {
            this.medhist = obj.med_hist[i].description;
          }
        }
        this.display_data();
      }
    }

  }

  medical_popup() {
    if (this.client == false) {
      for (var j = 0; j < this.medical_history_array.length; j++) {
        this.medical_history_array[j].checked = false;
      }

      if (this.medical_Check != undefined && this.medical_Check.length != 0) {
        for (var i = 0; i < this.medical_Check.length; i++) {
          for (var j = 0; j < this.medical_history_array.length; j++) {
            if (this.medical_Check[i] == this.medical_history_array[j].value) {
              this.medical_history_array[j].checked = true;
            }
          }
        }
      }

      const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
        width: '600px',
        height: '400px',
        panelClass: 'drop-down',
        data: {
          title: "Concerns",
          main_array: this.medical_history_array,
          selected_list: this.medical_Check,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.meddiab_click(result);
        }
      });
    }
  }

  display_data() {
    if (Helper_Class.getRet() != undefined) {
      this.family = Helper_Class.getRet();
    }

    if (this.family != undefined) {
      this.feelstress = this.family.stressed;
      this.stress = this.family.stress_factor;

      if (this.family.stressed == "Yes") {
        this.feelstress_flag = true;
        this.stress_flag = false;
      } else {
        this.feelstress_flag = false;
      }

      if (this.family.prev_hosp_visit == "Yes") {
        this.hosp_flag = true;
        this.prevhosp_flag = false;
      } else {
        this.hosp_flag = false;
      }

      if (this.family.murmurs == "Yes") {
        this.murmur_flag = false;
      }

      if (this.family.medical_history != null
        && this.family.medical_history != undefined
        && this.family.medical_history.length != 0) {
        for (var i = 0; i < this.family.medical_history.length; i++) {
          this.medical_Check.push(this.family.medical_history[i].med_hist_id)
          this.medical_history_array1.push(this.medical_Check)
        }
        this.meddiab_click(this.medical_Check);
      }
      if (this.family.prev_heart_dis == "Yes") {
        this.hdisorder_flag = true;
        this.hdis_flag = false;
      } else {
        this.hdisorder_flag = false;
      }
      this.symptom = this.family.symptoms;
      this.hdisorder = this.family.prev_heart_dis;
      this.treat = this.family.treatment;
      this.hosp = this.family.prev_hosp_visit;
      this.hospdet = this.family.hosp_visit_det;
      this.rightarm = this.family.bp_right_arm_dia;
      this.rightarm1 = this.family.bp_right_arm_sys;
      this.leftarm = this.family.bp_left_arm_dia;
      this.leftarm1 = this.family.bp_left_arm_sys;
      this.hsound = this.family.hsound;
      this.murmurs = this.family.murmurs;
      this.murmursgrade = this.family.murmur_grade;
      this.fasting = this.family.glucose_fasting;
      this.prandial = this.family.glucose_postprandial;

      this.radrate = this.family.radrate;
      this.radrhythmn = this.family.radrhythmn;
      this.radvolume = this.family.radvolume;
      this.radwtense = this.family.radwtense;
      this.radwcont = this.family.radwcont;
      this.bracrate = this.family.bracrate;
      this.bracrhythmn = this.family.bracrhythmn;
      this.bracvolume = this.family.bracvolume;
      this.bracwtense = this.family.bracwtense;
      this.bracwcont = this.family.bracwcont;
      this.carotrate = this.family.carotrate;
      this.carotrhythmn = this.family.carotrhythmn;
      this.carotvolume = this.family.carotvolume;
      this.carotwtense = this.family.carotwtense;
      this.carotwcont = this.family.carotwcont;
      this.femoralrate = this.family.femoralrate;
      this.femoralrhythmn = this.family.femoralrhythmn;
      this.femoralvolume = this.family.femoralvolume;
      this.femoralwtense = this.family.femoralwtense;
      this.femoralwcont = this.family.femoralwcont;
      this.poplitrate = this.family.poplitrate;
      this.poplitrhythmn = this.family.poplitrhythmn;
      this.poplitvolume = this.family.poplitvolume;
      this.poplitwtense = this.family.poplitwtense;
      this.poplitwcont = this.family.poplitwcont;
      this.postrate = this.family.postrate;
      this.postrhythmn = this.family.postrhythmn;
      this.postvolume = this.family.postvolume;
      this.postwtense = this.family.postwtense;
      this.postwcont = this.family.postwcont;
      this.dorsalrate = this.family.dorsalrate;
      this.dorsalrhythmn = this.family.dorsalrhythmn;
      this.dorsalvolume = this.family.dorsalvolume;
      this.dorsalwtense = this.family.dorsalwtense;
      this.dorsalwcont = this.family.dorsalwcont;
      this.abdomrate = this.family.abdomrate;
      this.abdomrhythmn = this.family.abdomrhythmn;
      this.abdomvolume = this.family.abdomvolume;
      this.abdomwtense = this.family.abdomwtense;
      this.abdomwcont = this.family.abdomwcont;
      this.pain = this.family.chest_pain;

      this.location = this.family.pain_location;
      this.character = this.family.pain_character;
      this.radiation = this.family.pain_radiation;
      this.duration = this.family.pain_duration;
      this.aggravat = this.family.pain_aggr_factor;
      this.relieve = this.family.pain_rel_factor;
      this.assosymp = this.family.pain_asso_symptom;
      this.intensity = this.family.pain_intensity;
      this.paincause = this.family.pain_cause;
      if (this.pain != null && this.pain != undefined) {
        this.tcchestpain = "#2ca8fe";
      }
      this.breathlessness = this.family.breathlessness;
      this.triggers = this.family.breath_triggers;
      this.onset = this.family.breath_onset;
      this.coldsweats = this.family.breath_cold_sweat;
      this.dizziness = this.family.breath_dizziness;
      this.vomiting = this.family.breath_vomiting;
      this.palpitation = this.family.breath_palpitation;
      this.palptype = this.family.breath_palp_type;
      this.cough = this.family.breath_cough;
      this.mucus = this.family.breath_mucus;
      this.eyes = this.family.phy_eye;
      this.nails = this.family.phy_nails;
      this.liptongue = this.family.phy_lip_tongue;
      this.neck = this.family.phy_neck;
      this.face = this.family.phy_face;
      this.precordium = this.family.phy_precordium;
      this.hand_arm = this.family.phy_hand_arm;
      this.abdomen = this.family.phy_abdomen;
      this.lowerlimb = this.family.phy_lower_limb;
      this.caprefill = this.family.phy_cap_refill;
      this.pain_change();
      this.breath_change();
    }
    this.send_data();
  }

  //Get chest pain locations
  async getChestpainLocationDetails() {
    this.cpainloc_array = [];
    var obj = masterCSData_Helper.getMasterChestpainLocationDetails();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_locations.length; i++) {
        this.cpainloc_array.push({
          location_id: obj.pain_locations[i].location_id,
          location_desc: obj.pain_locations[i].location_desc,
        })
      }
    }
    if (masterCSData_Helper.getMasterChestpainCharacters() == undefined) {
      await this.masterCSdata.getChestpainCharacters();
      this.getChestpainCharacters();
    } else {
      this.getChestpainCharacters();
    }
  }

  //Get chest pain characters
  async getChestpainCharacters() {
    this.cpaincar_array = [];
    var obj = masterCSData_Helper.getMasterChestpainCharacters();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_characters.length; i++) {
        this.cpaincar_array.push({
          character_id: obj.pain_characters[i].character_id,
          character_desc: obj.pain_characters[i].character_desc,
        })
      }
    }
    if (masterCSData_Helper.getMasterChestpainRadiations() == undefined) {
      await this.masterCSdata.getChestpainRadiations();
      this.getChestpainRadiations();
    } else {
      this.getChestpainRadiations();
    }
  }

  //Get chest pain radiation
  async getChestpainRadiations() {
    this.cpainrad_array = [];
    var obj = masterCSData_Helper.getMasterChestpainRadiations();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_radiations.length; i++) {
        this.cpainrad_array.push({
          radiation_id: obj.pain_radiations[i].radiation_id,
          radiation_desc: obj.pain_radiations[i].radiation_desc,
        })
      }
    }
    if (masterCSData_Helper.getMasterChestpainAgrelFactors() == undefined) {
      await this.masterCSdata.getChestpainAgrelFactors();
      this.getChestpainAgrelFactors();
    } else {
      this.getChestpainAgrelFactors();
    }
  }

  //Get chest pain agrav releaf factors
  async getChestpainAgrelFactors() {
    this.cpainagrel_array = [];
    var obj = masterCSData_Helper.getMasterChestpainAgrelFactors();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_agrels.length; i++) {
        this.cpainagrel_array.push({
          agrrelfact_id: obj.pain_agrels[i].agrrelfact_id,
          agrrelfact_desc: obj.pain_agrels[i].agrrelfact_desc,
        });
      }
      this.aggravat = this.cpainagrel_array[0].agrrelfact_desc;
      this.relieve = this.cpainagrel_array[0].agrrelfact_desc;
    }
    if (masterCSData_Helper.getMasterChestpainAssociatedSymptoms() == undefined) {
      await this.masterCSdata.getChestpainAssociatedSymptoms();
      this.getChestpainAssociatedSymptoms();
    } else {
      this.getChestpainAssociatedSymptoms();
    }
  }

  //Get chest pain associated symptoms
  async getChestpainAssociatedSymptoms() {
    this.cpainasympt_array = [];
    var obj = masterCSData_Helper.getMasterChestpainAssociatedSymptoms();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_asympts.length; i++) {
        this.cpainasympt_array.push({
          asssympt_id: obj.pain_asympts[i].asssympt_id,
          asssympt_desc: obj.pain_asympts[i].asssympt_desc,
        })
      }
      this.assosymp = this.cpainasympt_array[0].asssympt_desc;
    }
    if (masterCSData_Helper.getMasterChestpainCauses() == undefined) {
      await this.masterCSdata.getChestpainCauses();
      this.getChestpainCauses();
    } else {
      this.getChestpainCauses();
    }
  }

  //Get chest pain causes
  async getChestpainCauses() {
    this.cpaincause_array = [];
    var obj = masterCSData_Helper.getMasterChestpainCauses();
    if (obj != undefined) {
      for (var i = 0; i < obj.pain_causes.length; i++) {
        this.cpaincause_array.push({
          cause_id: obj.pain_causes[i].cause_id,
          cause_desc: obj.pain_causes[i].cause_desc,
        })
      }
      this.paincause = this.cpaincause_array[0].cause_desc;
    }
    if (masterCSData_Helper.getMasterBreathingTriggers() == undefined) {
      await this.masterCSdata.getBreathingTriggers();
      this.getBreathingTriggers();
    } else {
      this.getBreathingTriggers();
    }
  }

  //Get breathing triggers
  async getBreathingTriggers() {
    this.btrig_array = [];
    var obj = masterCSData_Helper.getMasterBreathingTriggers();
    if (obj != undefined) {
      for (var i = 0; i < obj.breath_triggers.length; i++) {
        this.btrig_array.push({
          triggers_id: obj.breath_triggers[i].triggers_id,
          triggers_desc: obj.breath_triggers[i].triggers_desc,
        })
      }
      this.triggers = this.btrig_array[0].triggers_desc;
    }
    if (masterCSData_Helper.getMasterBreathingOnsets() == undefined) {
      await this.masterCSdata.getBreathingOnsets();
      this.getBreathingOnsets();
    } else {
      this.getBreathingOnsets();
    }
  }

  //Get breathing onsets
  async getBreathingOnsets() {
    this.bonset_array = [];
    var obj = masterCSData_Helper.getMasterBreathingOnsets();
    if (obj != undefined) {
      for (var i = 0; i < obj.breath_onsets.length; i++) {
        this.bonset_array.push({
          onset_id: obj.breath_onsets[i].onset_id,
          onset_desc: obj.breath_onsets[i].onset_desc,
        })
      }
      this.onset = this.bonset_array[0].onset_desc;
    }
    if (masterCSData_Helper.getMasterPalpitationTypes() == undefined) {
      await this.masterCSdata.getPalpitationTypes();
      this.getPalpitationTypes();
    } else {
      this.getPalpitationTypes();
    }
  }

  //Get palpitation types
  async getPalpitationTypes() {
    this.palp_array = [];
    var obj = masterCSData_Helper.getMasterPalpitationTypes();
    if (obj != undefined) {
      for (var i = 0; i < obj.palp_types.length; i++) {
        this.palp_array.push({
          palp_type_id: obj.palp_types[i].palp_type_id,
          palp_type_desc: obj.palp_types[i].palp_type_desc,
        })
      }
      //this.palp_array = obj.palp_types;
      this.palptype = this.palp_array[0].palp_type_desc;
    }
    if (masterCSData_Helper.getMasterMucusTypes() == undefined) {
      await this.masterCSdata.getMucusTypes();
      this.getMucusTypes();
    } else {
      this.getMucusTypes();
    }
  }

  //Get mucus types
  getMucusTypes() {
    this.mucus_array = [];
    var obj = masterCSData_Helper.getMasterMucusTypes();
    if (obj != undefined) {
      for (var i = 0; i < obj.mucus_types.length; i++) {
        this.mucus_array.push({
          mucus_id: obj.mucus_types[i].mucus_id,
          mucus_desc: obj.mucus_types[i].mucus_desc,
        })
      }
      this.mucus = this.mucus_array[0].mucus_desc;
    }
  }

  meddiab_click(selected) {
    this.medhist = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    if (selected != "" && selected != null) {
      this.medical_Check = selected;
    }

    if (this.medical_Check.length != 0) {
      var n = 0;
      for (var j = 0; j < this.medical_Check.length; j++) {
        for (var i = 0; i < this.medical_history_array.length; i++) {
          if (this.medical_Check[j] == this.medical_history_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.medical_history_array[i].label);
          }
        }
      }
      this.medhist = selected_data.toString();
    }
  }

  stress_change(e) {
    if (e == "Yes") {
      this.stress_flag = false;
    } else {
      this.stress_flag = true;
      this.stress = "";
      this.symptom = "";
    }
  }

  hdisorder_change() {
    if (this.hdisorder == "Yes") {
      this.hdis_flag = false;
    } else {
      this.hdis_flag = true;
      this.treat = "";
    }
  }

  SelectClinicallist() { }

  prevhosp_change() {
    if (this.hosp == "Yes") {
      this.prevhosp_flag = false;
    } else {
      this.prevhosp_flag = true;
      this.hospdet = "";
    }
  }

  murmur_change(e) {
    if (e != "No") {
      this.murmur_flag = false;
    } else {
      this.murmur_flag = true;
      this.murmursgrade = null;
    }
  }

  pain_change() {
    if (this.pain == "Yes") {
      this.pain_flag = false;
      this.paincause_required = true;
      this.intensity_required = true;
      this.assosymp_required = true;
      this.relieve_required = true;
      this.aggravat_required = true;
      this.dur_required = true;
      this.rad_required = true;
      this.char_required = true;
      this.loc_required = true;
    } else {
      this.pain_flag = true;
      this.paincause = null;
      this.intensity = null;
      this.assosymp = null;
      this.relieve = null;
      this.aggravat = null;
      this.duration = null;
      this.radiation = null;
      this.character = null;
      this.location = null;
      this.paincause_required = false;
      this.intensity_required = false;
      this.assosymp_required = false;
      this.relieve_required = false;
      this.aggravat_required = false;
      this.dur_required = false;
      this.rad_required = false;
      this.char_required = false;
      this.loc_required = false;
    }
  }

  breath_change() {
    if (this.breathlessness == "Yes") {
      this.trig_flag = false;
      this.trig_required = true;
      this.onset_required = true;
      this.coldsweat_required = true;
      this.dizziness_required = true;
      this.vomiting_required = true;
      this.palpitation_required = true;
      this.palptype_required = true;
      this.cough_required = true;
      this.mucus_required = true;
    } else {
      this.trig_flag = true;
      this.triggers = null;
      this.onset = null;
      this.coldsweats = null;
      this.dizziness = null;
      this.vomiting = null;
      this.palpitation = null;
      this.palptype = null;
      this.cough = null;
      this.mucus = null;
      this.trig_required = false;
      this.onset_required = false;
      this.coldsweat_required = false;
      this.dizziness_required = false;
      this.vomiting_required = false;
      this.palpitation_required = false;
      this.palptype_required = false;
      this.cough_required = false;
      this.mucus_required = false;
    }
  }

  send_data() {
    var medical_flag = this.medical_Check.length != 0 ? "yes" : "no";

    var clinical_data = {
      stressed: this.feelstress,
      stress_factor: this.stress,
      medical_history: this.medical_Check,
      medical_history_flag: medical_flag,
      symptoms: this.symptom,
      prev_heart_dis: this.hdisorder,
      treatment: this.treat,
      prev_hosp_visit: this.hosp,
      hosp_visit_det: this.hospdet,
      bp_right_arm_dia: this.rightarm,
      bp_right_arm_sys: this.rightarm1,
      bp_left_arm_dia: this.leftarm,
      bp_left_arm_sys: this.leftarm1,
      hsound: this.hsound,
      murmurs: this.murmurs,
      murmur_grade: this.murmursgrade,
      glucose_fast: this.fasting,
      glucose_postprand: this.prandial,

      chest_pain: this.pain,
      pain_location: this.location,
      pain_character: this.character,
      pain_radiation: this.radiation,
      pain_duration: this.duration,
      pain_aggr_factor: this.aggravat,
      pain_rel_factor: this.relieve,
      pain_asso_symptom: this.assosymp,
      pain_intensity: this.intensity,
      pain_cause: this.paincause,

      breathlessness: this.breathlessness,
      breath_trigger: this.triggers,
      breath_onset: this.onset,
      breath_cold_sweat: this.coldsweats,
      breath_dizziness: this.dizziness,
      breath_vomiting: this.vomiting,
      breath_palpitation: this.palpitation,
      breath_palp_type: this.palptype,
      breath_cough: this.cough,
      breath_mucus: this.mucus,

      radrate: this.radrate,
      radrhythmn: this.radrhythmn,
      radvolume: this.radvolume,
      radwtense: this.radwtense,
      radwcont: this.radwcont,
      bracrate: this.bracrate,
      bracrhythmn: this.bracrhythmn,
      bracvolume: this.bracvolume,
      bracwtense: this.bracwtense,
      bracwcont: this.bracwcont,
      carotrate: this.carotrate,
      carotrhythmn: this.carotrhythmn,
      carotvolume: this.carotvolume,
      carotwtense: this.carotwtense,
      carotwcont: this.carotwcont,
      femoralrate: this.femoralrate,
      femoralrhythmn: this.femoralrhythmn,
      femoralvolume: this.femoralvolume,
      femoralwtense: this.femoralwtense,
      femoralwcont: this.femoralwcont,
      poplitrate: this.poplitrate,
      poplitrhythmn: this.poplitrhythmn,
      poplitvolume: this.poplitvolume,
      poplitwtense: this.poplitwtense,
      poplitwcont: this.poplitwcont,
      postrate: this.postrate,
      postrhythmn: this.postrhythmn,
      postvolume: this.postvolume,
      postwtense: this.postwtense,
      postwcont: this.postwcont,
      dorsalrate: this.dorsalrate,
      dorsalrhythmn: this.dorsalrhythmn,
      dorsalvolume: this.dorsalvolume,
      dorsalwtense: this.dorsalwtense,
      dorsalwcont: this.dorsalwcont,
      abdomrate: this.abdomrate,
      abdomrhythmn: this.abdomrhythmn,
      abdomvolume: this.abdomvolume,
      abdomwtense: this.abdomwtense,
      abdomwcont: this.abdomwcont,

      phy_eye: this.eyes,
      phy_nails: this.nails,
      phy_lip_tongue: this.liptongue,
      phy_neck: this.neck,
      phy_face: this.face,
      phy_precordium: this.precordium,
      phy_hand_arm: this.hand_arm,
      phy_abdomen: this.abdomen,
      phy_lower_limb: this.lowerlimb,
      phy_cap_refill: this.caprefill,
    }
    Helper_Class.setclinicalexam(clinical_data);
  }

  murmur_change1() {
    if (this.murmursgrade != null && this.murmursgrade != undefined) {
      this.tcanypain3 = "#2ca8fe";
    }
  }

  history1() {
    this.person_hist1 = "clinical_hist"
    const dialogRef = this.dialog.open(CardioPopupHistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.person_hist1,
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  pain_history() {
    this.clinicalhist = "pain_hist";
    const dialogRef = this.dialog.open(CardioPopupHistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.clinicalhist,
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  breath_history() {
    this.clinicalhist = "breath_hist";
    const dialogRef = this.dialog.open(CardioPopupHistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.clinicalhist,
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  phy_history() {
    this.clinicalhist = "phy_hist";
    const dialogRef = this.dialog.open(CardioPopupHistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.clinicalhist,
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }
}
