<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white" >
          <div class="headerCover">
            <div class="headerTilte app_list_header" >
              <h5 class="m-0" class="mainHeadingStyle">Patient list</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/discharged.svg" (click)="closeInpatient()" width="85px" />

              <a style="float: right;margin-left: 5px;" (click)="create()" [hidden]="create_flag">
                <img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo">
              </a>
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom">
                <mat-label class="matlabel">Doctor<br>
                  <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)"  [(ngModel)]="doctor">
                    <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
                    </option>
                  </select>
                </mat-label>
              </div>
          </div>
          <br/>
          <p class="nodata" *ngIf="patientList.length == 0">No appointment(s) found</p>
          <table *ngIf="patientList.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
            <thead>
              <tr>
                <th>Image</th>
                <th>Inpatient id</th>
                <th style="text-align: left !important;">Patient name</th>
                <th>Speciality</th>
                <th>Admitted on</th>     
                <!-- <th >Admitted time</th> -->
                <th >Surgery date</th>
                <th>Ward</th>
                <th style="width: 5%;">
                  <mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1"
                    (change)="checkUncheckAll($event)"></mdb-checkbox>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of patientList; let i = index" >
                <td (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality)'><img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                <td (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality)' style="font-size: 12px;">{{ person.hosp_inp_id }}</td>
                <td (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality)' style="font-size: 12px;text-align: left !important;">{{ person.patient_name }}</td>
                <td (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality)' style="font-size: 12px;text-align: left !important;">{{ person.speciality }}</td>
                <td (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality)' style="font-size: 12px;">{{ person.admit_date }}</td>
                <!-- <td style="font-size: 12px;">{{ person.admit_time }}</td> -->
                <td (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality)' style="font-size: 12px;">{{ person.surgery_date }}</td>
                <td (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality)' style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.ward_text }}</td>
                <td style="font-size: 12px;width: 5%;">
                  <mdb-checkbox [default]="true" [(ngModel)]="person.checked" name="list_name"
                    value="{{person.inpatient_id}}" (change)="isInpatSelected(person)" [disabled]="person.disflag"></mdb-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <br><br><br>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>