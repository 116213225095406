import { Component,ViewChild, OnInit,HostListener,ViewEncapsulation,ElementRef,OnDestroy, Injector, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
// import {ipaddress.getIp,Ip_with_img_address,ipaddress.country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../assets/js/Message_data';
import { GynecologyService } from '../service/gyna_obsservice';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-general-personal',
  templateUrl: './general-personal.component.html',
  styleUrls: ['./general-personal.component.css']
})
export class GeneralPersonalComponent implements OnInit {
  public person_hist;
  public generalPersonalFlag ="min";
  public flag1:boolean;
  public case_hist_id: string;
  public life_style_id: string;
  public maincheck = [];
  public main_concern_array = [];
  public Quantity_array=[];
  public bodyttype_array = [];
  public Religious_belief_array=[];
  public Main_concern_array=[];
  public Maincheck=[];
  public medical_history_array = [];
  public Medical_history_array=[]
  public Medical_Check=[];
  public Religious_check=[];

  public past_medical;
  public religious_belief;
  public diet_habits: string;
  public feelstress: string;
  //public stress_factortxt: string;
  public name: string;
  public age: any;
  public current_occupation: string;
  public gender: string;
  public height_txt: any;
  public height_measure: string;
  public weight_txt: any;
  public weight_measure: string;
  public txt_bmi: any;
  public txt_bmr: any;
  public exer_run: string;
  public cal_txt: any;
  public body_type: string;
  public body_nature: string;
  public rweight_txt: string;
  public anymed_txt: string;
  public med_txt: string;
  public hosp: string;

  public stress_flag: boolean;
  public prevhosp_flag: boolean;
  public hosp_flag:boolean;
  public feelstress_flag:boolean;
  public currnetoccupation_hidden:boolean;
  public obervation_hidden:boolean;
  
  public relbelflag: string;
  public medhistflag: string;

  public name_label: string;
  public age_label: string;
  public current_label: string;
  public gender_label: string;
  public height_label: string;
  public height_measurelabel: string;
  public weight_label: string;
  public weight_measurelabel: string;
  public bmi_label: string;
  public bmr_label: string;
  public dietary_label: string;

  public bodytype_label: string;
  public bnature_label: string;
  public rweight_label: string;
  public anymed_label: string;
  public med_label: string;
  public brancial_label: string;
  public radial_label: string;
  public carotid_label: string;
  public femoral_label: string;
  public popliteal_label: string;
  public abdom_aorta: string;
  public branch_rhythm: string;
  public radial_rhythm: string;
  public carotid_rhythm: string;
  public femoral_rhythm: string;
  public popliteal_rhythm: string;
  public posterior_rhythm: string;
  public dorsalis_rhythm: string;
  public occupation_txt: string;
  public alcholhist: string;
  public family_hidden:boolean;
  public family_label:string;
  public Maincon_label:string;
  public Past_medicallabel:string;
  public Religious_label: string;
  public stress: string;
  public symptom: string;
  public feelstress_label: string;
  public stress_label: string;
  public symptom_label: string;
  public treat_label: string;
  public maincon_txt: string;

  public hosp_label: string;
  public hospdet_label: string;
  public rightarm_label: string;
  public leftarm_label: string;
  public fasting_label: string;
  public prandial_label: string;
  public hospdet: string;
  public treat: string;
  public rightarm: string;
  public rightarm1: string;
  public leftarm: string;
  public leftarm1: string;
  public prandial: string;
  public fasting: string;

  public name_hidden: boolean;
  public age_hidden: boolean;
  public curoccup_hidden: boolean;
  public Gender_hidden: boolean;
  public height_hidden: boolean;
  public weight_hidden: boolean;
  public bmi_hidden: boolean;
  public bmr_hidden: boolean;
  public dietary_hidden: boolean;
  public alchol_hidden: boolean;
  public smoke_hidden: boolean;
  public gutka_hidden: boolean;
  public exercise_hidden: boolean;
  public bodytype_hidden: boolean;
  public bnature_hidden: boolean;
  public rweight_hidden: boolean;
  public anymed_hidden: boolean;
  public med_hidden: boolean;
  public medhist_hidden: boolean;
  public symptom_hidden: boolean;
  public hdisorder_hidden: boolean;
  public treat_hidden: boolean;
  public hosp_hidden: boolean;
  public hospdet_hidden: boolean;
  public rightarm_hidden:boolean;
  public leftarm_hidden:boolean
  public fasting_hidden: boolean;
  public prandial_hidden:boolean;
  public maincon_hidden:boolean;
  public medhist_label: boolean;
  public religious_hidden: boolean;
  public feelstress_hidden: boolean;
  public stress_hidden: boolean;

  public loader: boolean;
  public med_flag: boolean;
  public historytab: boolean;
  public header_footer_flag: boolean;
  public agefield: boolean;

  public send_data_retriev;
  subscription:Subscription;
  public personaldata;
  public userinfo;

  public tcanymed;
  public stressid;
  public hospid;
  public obj1;
  public client:boolean = false;
  public user_id;

  constructor(public bmiservice:BmiandbmrService,public dialog:MatDialog,public msgservice:GynecologyService,
    public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,private router:Router,
    public toastr:ToastrService,public masterCSdata:MasterCSHelperService) { 
    this.flag1=true;
    this.name_hidden = false;
    this.age_hidden = true;
    this.curoccup_hidden = true;
    this.Gender_hidden = true;
    this.height_hidden = true;
    this.weight_hidden = true;
    this.bmi_hidden = true;
    this.bmr_hidden = true;
    this.dietary_hidden = true;

    this.bodytype_hidden = true;
    this.bnature_hidden = true;
    this.rweight_hidden = true;
    this.anymed_hidden = true;
    this.med_hidden = true;
   
    this.med_flag = true;
    this.historytab=true;
    this.agefield=true;

    this.stress_flag=true;
    this.prevhosp_flag = true;

    this.height_measure = "cms";
    this.weight_measure = "kgs";
    this.tcanymed = "rgb(250, 40, 2)";
    this.stressid = "rgb(250, 40, 2)";
    this.hospid = "rgb(250, 40, 2)";
  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.generalPersonalFlag = message;
      }
    });

    this.subscription = this.bmiservice.getMessage().subscribe(message =>{
      if(message != undefined){
        if(message.flag == "vitals"){
          if(message.bodynatute != undefined){
            this.body_nature = message.bodynatute;
          }
        }
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.personaldata = Helper_Class.getInfo();
      this.client = true;
      this.stress_flag=true;
    }else{
      this.personaldata = Doc_Helper.getClient_Info();
      this.userinfo=Helper_Class.getInfo();
      this.client = false;
    }

    this.user_id = Helper_Class.getInpatientFlow() == "nurse" ? this.personaldata.user_id : this.userinfo.user_id;

    if(this.personaldata.Age_data != 'undefined' || this.personaldata.Age_data != undefined || this.personaldata.Age_data != ''){
      this.age = this.personaldata.Age_data;
    } else {
      this.age="";
      this.agefield=false;
    }

    this.txt_bmr = undefined;
    this.txt_bmi = undefined;
    this.gender = this.personaldata.Gender_data;
    this.name = this.personaldata.client_name;

    if (this.personaldata.current_occupation != null && this.personaldata.current_occupation !="" && this.personaldata.current_occupation != undefined) {
      this.current_occupation = encrypt_decript.Decript(this.personaldata.current_occupation);
    }

    if(this.personaldata.height != null){
      this.height_txt = this.personaldata.height;
      this.height_measure = this.personaldata.height_measure;
      this.weight_txt = this.personaldata.weight;
      this.weight_measure = this.personaldata.weight_measure;
      this.txt_bmi =this.personaldata.bmi;
      this.txt_bmr = this.personaldata.bmr;
      this.calories_data();
    }
    if(masterCSData_Helper.getMasterQunatity() == undefined){
      await this.masterCSdata.getQuantity();
      this.Get_quantity();
    }else{
      this.Get_quantity();
    }
    this.getBodyTypes();
    this.obj1 = Helper_Class.getRet();   
    if(masterCSData_Helper.getMasterMedicalHistory() == undefined){
      await this.masterCSdata.getMedicalHistory();
      this.getMedicalHistory();
    }else{
      this.getMedicalHistory();
    }
    if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
      await this.masterCSdata.getReligiousOtherBeliefs();
      this.getReligiousBeliefs();
    }else{
      this.getReligiousBeliefs();
    }

    //height_measure
    for (var i = 0; i < Helper_Class.getHopitalfieldsRet().length; i++) {
      if (Helper_Class.getHopitalfieldsRet()[i].page_name == "General Personal") {
        if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
          if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
            for (var j = 0; j < Helper_Class.getHopitalfieldsRet()[i].fields.length; j++) {
              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Current occupation") {
                this.current_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.curoccup_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Name") {
                this.name_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.name_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Age") {
                this.age_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.age_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Gender") {
                this.gender_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.Gender_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Height") {
                this.height_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.height_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Height measure") {
                this.height_measurelabel = "Measure";
                this.height_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Weight") {
                this.weight_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.weight_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Weight measure") {
                this.weight_measurelabel = "Measure";
                this.weight_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "BMI") {
                this.bmi_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bmi_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "BMR") {
                this.bmr_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bmr_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Dietary habits") {
                this.dietary_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.dietary_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Family Support") {
                this.family_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.family_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Body type") {
                this.bodytype_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bodytype_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Body nature") {
                this.bnature_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bnature_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Recent weight gain") {
                this.rweight_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.rweight_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Taking any medication") {
                this.anymed_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.anymed_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Medicines") {
                this.med_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.med_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Main concenrns") {
                this.Maincon_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.maincon_hidden = false;
              }
              
              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Past medical history") {
                this.medhist_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.medhist_hidden = false;
              }
              
              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Religious or other beliefs") {
                this.Religious_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.religious_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Do you feel stressed") {
                this.feelstress_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name + "?";
                this.feelstress_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Stress factors") {
                this.stress_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.stress_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Symptoms") {
                this.symptom_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.symptom_hidden = false;
              }
              
              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Treatment") {
                this.treat_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.treat_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Previous hospitalization") {
                this.hosp_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.hosp_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Hospitalization details") {
                this.hospdet_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.hospdet_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Right arm") {
                this.rightarm_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.rightarm_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Left arm") {
                this.leftarm_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.leftarm_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Fasting") {
                this.fasting_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.fasting_hidden = false;
              }

              if (Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name == "Postprandial") {
                this.prandial_label = Helper_Class.getHopitalfieldsRet()[i].fields[j].field_name;
                this.prandial_hidden = false;
              }
            }
          }
        }
      }
    }
    this.getCasesheetDetails() ;
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    if(this.client == false){
      this.send_data();
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    if(this.client == false){
      this.send_data();
    }
  }

  med_change(){
    this.tcanymed = "#2ca8fe";
    if(this.anymed_txt =="Yes"){
      this.med_flag=false;
    } else {
      this.med_flag=true;
      this.med_txt="";
    }
  }
  
  send_data(){
    var gen_hist = null, case_hist_id = null;

    case_hist_id = this.case_hist_id != null ? this.case_hist_id : undefined;

    var lif_stl_id = undefined;
    if (this.life_style_id != null) {
      lif_stl_id = this.life_style_id;
    }

    if (this.current_occupation != undefined) {
      this.occupation_txt = encrypt_decript.Encript(this.current_occupation.trim()).toString();
    } else {
      this.occupation_txt = null;
    }
   
    
    if(this.personaldata.sub_id != null && this.personaldata.sub_id !=undefined && this.personaldata.sub_id !="" && this.personaldata.sub_id.length != 0){
      var sub_id=this.personaldata.sub_id;
    }
    gen_hist = {
      country: ipaddress.country_code,
      case_hist_id: case_hist_id,
      current_occupation: this.occupation_txt,
      life_style_id: lif_stl_id,
      app_id: this.personaldata.app_id,
      doc_reg_id:this.user_id,
      client_reg_id:this.personaldata.Client_id,
      relation_id:this.personaldata.rel_id,
      sub_rel_id:sub_id,
      // height: this.height_txt,
      // height_measure: this.height_measure,
      // weight: this.weight_txt,
      // weight_measure: this.weight_measure,
      // bmi: this.txt_bmi,
      // bmr: this.txt_bmr,
      gender: this.gender,
      age: this.age,
      body_nature:this.body_nature,
      body_type: this.body_type,
      medication: this.anymed_txt,
      medicines: this.med_txt,
      diet_habits: this.diet_habits,
      
      stressed: this.feelstress,
      stress_factor: this.stress,
      symptoms: this.symptom,
      main_concern: this.maincon_txt,
      medical_history: this.past_medical,
      medical_history_flag:this.medhistflag,
      rel_belief: this.religious_belief,
      rel_belief_flag: this.relbelflag,
      prev_hosp_visit: this.hosp,
      hosp_visit_det: this.hospdet,
      treatment:this.treat,

      bp_right_arm_sys: this.rightarm,
      bp_right_arm_dia: this.rightarm1,
      // bp_left_arm_sys: this.leftarm,
      // bp_left_arm_dia: this.leftarm1,
      
      glucose_fast: this.fasting,
      glucose_postprand: this.prandial,
    }  
     // }


      Helper_Class.setPersonalHist(gen_hist);
      Doc_Helper.setClient_age(this.age);
      Doc_Helper.setClient_gender(this.gender);
      
  }
  
  getBodyTypes() {
    this.bodyttype_array = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    
    this.http.post(ipaddress.getIp.toString() + 'cardio/btypes',{gender: this.gender},
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if(obj != null){
            this.bodyttype_array = obj.body_types;
            this.body_type = this.bodyttype_array[0].bodytype_name;
          }
        },
        error => {
           this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  //Get quantity data
  Get_quantity() {
    this.Quantity_array = [];
    var obj = masterCSData_Helper.getMasterQunatity();
    if(obj != undefined)
      this.Quantity_array = obj.mixing_quantity;
  }

  // //Get bmi and bmr and calories data
  calories_data() {
    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.txt_bmr = "";
      this.txt_bmi = "";
    } else {
      if (this.height_txt != undefined) {
        if (this.height_measure == "cms") {
          this.height_txt = this.height_txt.replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt.replace('/[^-0-9\.]/g', '');
          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.toastr.error(Message_data.heightMSValue)
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.height_measure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.weight_measure == "kgs" && this.height_measure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "kgs" && this.height_measure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "lbs" && this.height_measure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "lbs" && this.height_measure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      }

      var SelectedText1 = this.exer_run;
      if (SelectedText1 == "Light") {
        var get_txt = this.txt_bmr * 1.375;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Moderate") {
        var get_txt = this.txt_bmr * 1.55;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Heavy") {
        var get_txt = this.txt_bmr * 1.725;
        this.cal_txt = Math.round(get_txt);
      } else if (SelectedText1 == "Very Heavy") {
        var get_txt = this.txt_bmr * 1.9;
        this.cal_txt = Math.round(get_txt);
      } else {
        var get_txt = parseFloat(this.txt_bmr) * 1.375;
        this.cal_txt = Math.round(get_txt);
      }

      if(this.txt_bmi<18.5){
        this.body_nature="Under weight";
      } else if(this.txt_bmi>=18.5 && this.txt_bmi<=24.9){
        this.body_nature="Normal weight";
      } else if(this.txt_bmi>=25 && this.txt_bmi<=29.9){
        this.body_nature="Over weight";
      } else{
        this.body_nature="Obase";
      }
    }   
  }
  
  getCasesheetDetails() {//retrieve cardio details
    var obj = Helper_Class.getRet();   

    if (obj !=null && obj.case_hist_id != null) {
      this.name = this.personaldata.client_name != undefined ? this.personaldata.client_name : obj.cardio_name;
      this.gender = this.personaldata.Gender_data != undefined ? this.personaldata.Gender_data : obj.gender;
      this.current_occupation = obj.current_occupation != null || obj.current_occupation !="" ? encrypt_decript.Decript(obj.current_occupation) : encrypt_decript.Decript(this.personaldata.current_occupation);
      if(obj.age){
        this.age = obj.age
      }
      
      if(this.age == undefined){
        this.agefield=false;
      }
      
      if (obj.case_hist_id != null) {
        this.case_hist_id = obj.case_hist_id;
      }

      if (obj.life_style_id != null) {
        this.life_style_id = obj.life_style_id;
      }

      //Height
      if (obj.height != null) {
        this.height_txt = obj.height;
        this.height_measure = obj.height_measure;
      }

      //Weight
      if (obj.weight != null) {
        this.weight_txt = obj.weight;
        this.weight_measure = obj.weight_measure;
      }

      if (obj.bp_right_arm_dia != null || obj.bp_right_arm_sys != null) {
        var bp = obj.bp_right_arm_dia + "/" + obj.bp_right_arm_sys;
        this.rightarm = obj.bp_right_arm_dia;
        this.rightarm1 = obj.bp_right_arm_sys;
      }

      //Bmi and mnr
      if (obj.bmi != null) {
        this.txt_bmi = obj.bmi;
        this.txt_bmr = obj.bmr;
        this.body_nature = obj.body_nature;
      }

      if(obj.body_type != "" || obj.body_type != null){
        this.body_type = obj.body_type;
      }
      if(obj.medication != null || obj.medication != undefined){
        this.anymed_txt = obj.medication;
        this.tcanymed = "#2ca8fe";
      }
      if(obj.medicines != null || obj.medicines != undefined){
        this.med_txt = obj.medicines;
      }

      //Diet habits
      if (obj.diet_habits != null) {
        this.diet_habits = obj.diet_habits;
      }

      if(obj.stressed != null || obj.stressed != undefined){
        this.feelstress = obj.stressed;
        this.stressid = "#2ca8fe";
      }
      this.stress = obj.stress_factor;
      this.stress_flag = obj.stressed =="Yes" ? false : true;
      this.prevhosp_flag = obj.prev_hosp_visit =="Yes"  && this.client == false ? false : true;
      this.symptom = obj.symptoms;
      this.treat = obj.treatment;
      if(obj.prev_hosp_visit != null || obj.prev_hosp_visit != undefined){
        this.hosp = obj.prev_hosp_visit;
        this.hospid = "#2ca8fe";
      }
      this.hospdet = obj.hosp_visit_det;
      this.leftarm = obj.bp_left_arm_dia;
      this.leftarm1 = obj.bp_left_arm_sys;
      this.fasting = obj.glucose_fasting;
      this.prandial = obj.glucose_postprandial;
      this.maincon_txt = obj.main_concerns;
      this.past_medical = obj.medical_history;
    }else {
      if(this.personaldata.client_name != undefined){
        this.name=this.personaldata.client_name;
      }
      
      if(this.personaldata.Gender_data != undefined){
        this.gender = this.personaldata.Gender_data;
      } 

      if (this.personaldata.current_occupation != null || this.personaldata.current_occupation !="") {
        this.current_occupation = encrypt_decript.Decript(this.personaldata.current_occupation);
      }
    }
  }
  
  getMedicalHistory() {
    var obj = masterCSData_Helper.getMasterMedicalHistory();
    if(obj != undefined){
      if (obj.med_hist != null) {
        for (var i = 0; i < obj.med_hist.length; i++) {
          this.Medical_history_array.push({
            type: 'checkbox',
            value: obj.med_hist[i].med_hist_id,
            label: obj.med_hist[i].description,
            checked: false
          });
          if(obj.med_hist[i].med_hist_id == 32){
            this.past_medical = obj.med_hist[i].description;
          }
        }
      }
    }
  }
  
  getReligiousBeliefs() {//Get Religious or other beliefs
    this.Religious_belief_array = [];
    var obj = masterCSData_Helper.getMasterReligiousOtherBeliefs();
    if(obj != undefined){
      if (obj.religious_beliefs != null) {
        for (var i = 0; i < obj.religious_beliefs.length; i++) {
          this.Religious_belief_array.push({
            type: 'checkbox',
            value: obj.religious_beliefs[i].religious_belief_id,
            label: obj.religious_beliefs[i].description,
            checked: false
          });
        }
      }
    }
  }
  
  stress_change(){
    this.stressid = "#2ca8fe";
    if(this.feelstress =="Yes"){
      this.stress_flag = false;
    }else{
      this.stress_flag=true;
      this.stress="";
      this.symptom="";
    }
  }
  
  enableHosp(){
    this.hospid = "#2ca8fe";
    this.prevhosp_flag = this.hosp =="Yes" ? false : true;
  }

  getMoreInformation(): string {
    return 'Example: \n  Morning:Two idly with sambar/chutney and a cup of coffee \n After noon:Cup of rice with dal curry and 1/2 cup of vegtables \n Evening:Cup of tea with 3 biscuits \n Night:3 Chapati with vegetables gravy'; // \n represent break line here
  }

  getMoreInformation1(): string {
    return 'Based on Harris-Benedict\n Calculation';
  }

  getMoreInformation2(): string {
    return 'If measure is “cms” then height should be less than “300cms”.\n If measure is “inches” then height should be less than “100 inches”.';
  }

  isReadonly() {
    return true;
  }
    
  history(){
    this.person_hist="concern_hist"
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
        Diab_case_hist_id:  this.case_hist_id ,
        Life_style_id: this.life_style_id,
        History: this.person_hist,
    }
    });
  
    dialogRef.afterClosed().subscribe(result => {});
  }

}
