<div *ngIf="pediaClinicalFlag == 'min'">
    <div class="row">
        <div [hidden]="head_hidden" class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <mat-label class="matlabel" [hidden]="head_hidden">Head<br>
                <textarea class="ipcss obsttxtareaheight" myInput rows="1" value="None" [(ngModel)]="head"></textarea>
            </mat-label>
        </div>
        <div [hidden]="vision_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="vision_hidden">Vision<br>
                <textarea class="ipcss obsttxtareaheight" myInput rows="1" value="None" [(ngModel)]="vision"></textarea>
            </mat-label>
        </div>
        <div [hidden]="hearning_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="hearning_hidden">Hearing<br>
                <textarea class="ipcss obsttxtareaheight" myInput rows="1" value="None"
                    [(ngModel)]="hearing" ></textarea>
            </mat-label>
        </div>
    </div>
</div>

<div *ngIf="pediaClinicalFlag == 'max'">
    <div class="row">
        <div class="col-6">
            <div class="cover_div" style="padding-bottom: 63px;">
                <div class="header_lable">
                    Head
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="head_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="head_hidden">Head<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1"
                                    value="None" [(ngModel)]="head" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="vision_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="vision_hidden">Vision<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1"
                                    value="None" [(ngModel)]="vision" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="hearning_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="hearning_hidden">Hearing<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1"
                                    value="None" [(ngModel)]="hearing" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="nose_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="nose_hidden">Nose<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1"
                                    value="None" [(ngModel)]="nose" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="mouth_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="mouth_hidden">Mouth & throat<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1"
                                    value="None" [(ngModel)]="mouth" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="circum_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="circum_hidden">Head circumference<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1"
                                    value="None" [(ngModel)]="circumference" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class=" col-12 ">
                            <a [hidden]="client" style="float: right;"><img
                                    src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo"
                                    (click)="Head_history_click()" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="cardio_head" class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Cardiovascular
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="chest_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="chest_hidden">Chest (Cardiovascular)<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1" value="None"
                                    [(ngModel)]="chest" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="abdominal_examhidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="abdominal_examhidden">Abdominal examination<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1" value="None"
                                    [(ngModel)]="abdominal" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="genita_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="genita_hidden">Genitalia<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1" value="None"
                                    [(ngModel)]="genitalia" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="extremi_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="extremi_hidden">Extremities<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1" value="None"
                                    [(ngModel)]="extremities" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="neuro_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="neuro_hidden">Neurologic<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1" value="None"
                                    [(ngModel)]="neurologic" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="skin_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="skin_hidden">Skin<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1" value="None"
                                    [(ngModel)]="skin" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="general_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="general_hidden">General appearance<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" myInput rows="1" value="None"
                                    [(ngModel)]="gen_appearnce" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class=" col-6 my-auto">
                            <a [hidden]="client" style="float: right;"><img src="../../../assets/ui_icons/buttons/History_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="Cardio_history_click()" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-9 col-lg-5 col-xl-3" style="float: right;width: max-content;">
        <!-- future <a (click)="Head_history_click()"><img src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_ds"/></a> -->

        <!-- <a (click)="Head_history_click()"><button id="iconbtn" mat-raised-button class="borderdiv" ><mat-icon id="maticon">baby_changing_station</mat-icon>
                <button id="AddBtn" class="Nomargin" mat-raised-button >History</button>  
        </button></a> -->
    </div>
</div>