<div class="row" style="width:100%;margin:0 auto;" *ngIf="forCasesheet">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Diet plan</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="saveFlag" style="width: 85px !important;"
                            src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                        <div class="diet_lable_cover">
                            <mat-label class="dite_input">
                                Caloried required
                                <span style="float: right;padding: 0 10px 0 0;">{{caloriesRequired}}</span>
                            </mat-label>
                            <div class="progress progress-xxs">
                                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77"
                                    aria-valuemin="0" aria-valuemax="100"
                                    [ngStyle]="{'border-bottom':'1px solid green','width':''}"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                        <div class="diet_lable_cover">
                            <mat-label class="dite_input">
                                Total calories(Kcal)
                                <span style="float: right;padding: 0 10px 0 0;">{{totalCalories}}</span>
                            </mat-label>
                            <div id="progress"></div>
                        </div>
                    </div>
                    <div class="col-9 col-sm-11 col-md-5 col-lg-3 col-xl-3" style="margin: 5px 0;">
                        <div class="diet_lable_cover">
                            <mat-label class="dite_input" id="sga">
                                SGA score
                                <span style="float: right;padding: 0 10px 0 0;">{{sgaOoverallScore}}</span>
                            </mat-label>
                            <div class="progress progress-xxs">
                                <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23"
                                    aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width':''}"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 col-sm-1 col-md-1 col-lg-1 col-xl-1" style="text-align: center;">
                        <img src="../assets/global/images/settings-icon.png" (click)="openDietPlan()" class=""
                            style="position: relative;top: 8px;" width="22px" height="22px" />
                    </div>
                    <div class="dite_content_cover">
                        <div class="row">
                            <div class="col-12">
                                <div class="switch-field" *ngIf="ynopt1 != undefined">
                                    <input type="radio" id="radio-three" (click)="changeType(1,'yes')"
                                        [disabled]="!currentDiet" />
                                    <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                                        <div class="tab_cover">
                                            <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Self.svg"
                                                    class="iocinStyle" alt=""></div>
                                            <div class="lableStyle">Current</div>
                                        </div>
                                    </label>
                                    <input type="radio" id="radio-four" (click)="changeType(1,'no')"
                                        [disabled]="!templateDiet" />
                                    <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                                        <div class="tab_cover">
                                            <div class="tab_icon_cover"><img src="../../../assets/ui_icons/others.svg"
                                                    class="iocinStyle" alt=""></div>
                                            <div class="lableStyle">Template</div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation" *ngFor="let person of displayArray; let i = index">
                                <button class="nav-link" id="{{person.day}}-tab" data-bs-toggle="tab"
                                    data-bs-target="#{{person.day}}" type="button" role="tab"
                                    aria-controls="{{person.day}}" aria-selected="true"
                                    (click)="changeDay(person.day)">{{person.dayLable}}</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show" *ngFor="let person1 of displayArray; let i = index"
                                id="{{person1.day}}" role="tabpanel" aria-labelledby="{{person1.day}}-tab"
                                style="height: 565px; overflow-y: auto;  overflow-x: hidden;">
                                <div class="row" *ngFor="let session of person1.session;">
                                    <div class="col-8 col-sm-7 col-md-12 col-lg-4 col-xl-4">
                                        <div class="card cardCover">
                                            <div class="card-header cardHeader">
                                                {{session.lableName}}
                                                <span style="float: right;">
                                                    {{session.energy}} Kcal
                                                </span>
                                            </div>
                                            <div class="card-body">
                                                <div *ngIf="session.type == 'Morning'">
                                                    <mat-label class="matlabel">Add food item <br>
                                                        <input type="text" class="ipcss mainDishInput"
                                                            [(ngModel)]="morningFoods" name="morningFoods"
                                                            (keyup)="changeFoodType(morningFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoMorning" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoMorning="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="selctedFood(person1.day,food)"
                                                                *ngFor="let food of morningFoodItem"
                                                                [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-label>
                                                </div>
                                                <div *ngIf="session.type == 'Breakfast'">
                                                    <mat-label class="matlabel">Add food item</mat-label><br>
                                                    <input type="text" class="ipcss mainDishInput"
                                                        [(ngModel)]="BreakfastFoods" name="BreakfastFoods"
                                                        (keyup)="changeFoodType(BreakfastFoods, session.type)"
                                                        type="text" aria-label="Number" matInput
                                                        [matAutocomplete]="autoBreakfast" />
                                                    <div style="display: inline-block;">
                                                        <img *ngIf="session.mainDish == true"
                                                            src="../../../assets/ui_icons/main_course_green_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                        <img *ngIf="session.mainDish == false"
                                                            (click)="mainDish(session,person1.day)"
                                                            src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                    </div>
                                                    <mat-autocomplete #autoBreakfast="matAutocomplete">
                                                        <mat-option id="optionfont"
                                                            (click)="selctedFood(person1.day,food)"
                                                            *ngFor="let food of breadfastFoodItem"
                                                            [value]="food.description">
                                                            {{food.displayContent}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                                <div *ngIf="session.type == 'Mid-day'">
                                                    <mat-label class="matlabel">Add food item</mat-label><br>
                                                    <input type="text" class="ipcss mainDishInput"
                                                        [(ngModel)]="MidDayFoods" name="MidDayFoods"
                                                        (keyup)="changeFoodType(MidDayFoods, session.type)" type="text"
                                                        aria-label="Number" matInput [matAutocomplete]="autoMidDay" />
                                                    <div style="display: inline-block;">
                                                        <img *ngIf="session.mainDish == true"
                                                            src="../../../assets/ui_icons/main_course_green_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                        <img *ngIf="session.mainDish == false"
                                                            (click)="mainDish(session,person1.day)"
                                                            src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                    </div>
                                                    <mat-autocomplete #autoMidDay="matAutocomplete">
                                                        <mat-option id="optionfont"
                                                            (click)="selctedFood(person1.day,food)"
                                                            *ngFor="let food of midDayFoodItem"
                                                            [value]="food.description">
                                                            {{food.displayContent}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                                <div *ngIf="session.type == 'Lunch'">
                                                    <mat-label class="matlabel">Add food item</mat-label><br>
                                                    <input type="text" class="ipcss mainDishInput"
                                                        [(ngModel)]="LunchFoods" name="LunchFoods"
                                                        (keyup)="changeFoodType(LunchFoods, session.type)" type="text"
                                                        aria-label="Number" matInput
                                                        [matAutocomplete]="autoLunchFoods" />
                                                    <div style="display: inline-block;">
                                                        <img *ngIf="session.mainDish == true"
                                                            src="../../../assets/ui_icons/main_course_green_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                        <img *ngIf="session.mainDish == false"
                                                            (click)="mainDish(session,person1.day)"
                                                            src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                    </div>
                                                    <mat-autocomplete #autoLunchFoods="matAutocomplete">
                                                        <mat-option id="optionfont"  (click)="selctedFood(person1.day,food)"  *ngFor="let food of lunchFoodItem" [value]="food.description">
                                                            {{food.displayContent}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                                <div *ngIf="session.type == 'Evening'">
                                                    <mat-label class="matlabel">Add food item</mat-label><br>
                                                    <input type="text" class="ipcss mainDishInput" [(ngModel)]="EveningFoods" name="EveningFoods" (keyup)="changeFoodType(EveningFoods, session.type)" type="text" aria-label="Number" matInput [matAutocomplete]="autoEveningFoods" />
                                                    <div style="display: inline-block;">
                                                        <img *ngIf="session.mainDish == true" src="../../../assets/ui_icons/main_course_green_icon.png" height="20px" alt="" class="mainDishImg">
                                                        <img *ngIf="session.mainDish == false" (click)="mainDish(session,person1.day)" src="../../../assets/ui_icons/main_course_blue_icon.png"  height="20px" alt="" class="mainDishImg">
                                                    </div>
                                                    <mat-autocomplete #autoEveningFoods="matAutocomplete">
                                                        <mat-option id="optionfont" (click)="selctedFood(person1.day,food)" *ngFor="let food of eveningFoodItem" [value]="food.description">
                                                            {{food.displayContent}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                                <div *ngIf="session.type == 'Dinner'">
                                                    <mat-label class="matlabel">Add food item</mat-label><br>
                                                    <input type="text" class="ipcss mainDishInput"
                                                        [(ngModel)]="DinnerFoods" name="DinnerFoods"
                                                        (keyup)="changeFoodType(DinnerFoods, session.type)" type="text"
                                                        aria-label="Number" matInput
                                                        [matAutocomplete]="autoDinnerFoods" />
                                                    <div style="display: inline-block;">
                                                        <img *ngIf="session.mainDish == true"
                                                            src="../../../assets/ui_icons/main_course_green_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                        <img *ngIf="session.mainDish == false"
                                                            (click)="mainDish(session,person1.day)"
                                                            src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                    </div>
                                                    <mat-autocomplete #autoDinnerFoods="matAutocomplete">
                                                        <mat-option id="optionfont"
                                                            (click)="selctedFood(person1.day,food)"
                                                            *ngFor="let food of dinnerFoodItem"
                                                            [value]="food.description">
                                                            {{food.displayContent}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                                <div *ngIf="session.type == 'Night'">
                                                    <mat-label class="matlabel">Add food item</mat-label><br>
                                                    <input type="text" class="ipcss mainDishInput"
                                                        [(ngModel)]="NightFoods" name="NightFoods"
                                                        (keyup)="changeFoodType(NightFoods, session.type)" type="text"
                                                        aria-label="Number" matInput
                                                        [matAutocomplete]="autoNightFoods" />
                                                    <div style="display: inline-block;">
                                                        <img *ngIf="session.mainDish == true"
                                                            src="../../../assets/ui_icons/main_course_green_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                        <img *ngIf="session.mainDish == false"
                                                            (click)="mainDish(session,person1.day)"
                                                            src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                            height="20px" alt="" class="mainDishImg">
                                                    </div>
                                                    <mat-autocomplete #autoNightFoods="matAutocomplete">
                                                        <mat-option id="optionfont"
                                                            (click)="selctedFood(person1.day,food)"
                                                            *ngFor="let food of nightFoodItem"
                                                            [value]="food.description">
                                                            {{food.displayContent}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                                <div class="foodItemCover">
                                                    <div *ngFor="let foodList of session.foodItems"
                                                        style="display: flex;margin-bottom: 5px;">
                                                        <span>
                                                            <img *ngIf="foodList.checked == false"
                                                                (click)="isSelected(foodList,'add')"
                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                style="cursor: pointer;" width="15px" />
                                                            <img *ngIf="foodList.checked == true"
                                                                (click)="isSelected(foodList,'remove')"
                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                style="cursor: pointer;" width="15px" />
                                                        </span>
                                                        <div class="foodLable"> {{ foodList.description }} </div>
                                                        <div class="foodInputCover"> <input type="text"
                                                                (change)="changeQuantity(foodList.cals_weight,foodList.day,foodList,session.type)"
                                                                [(ngModel)]="foodList.cals_weight"
                                                                class="foodMesureInput" [disabled]="!foodList.checked"
                                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                            <div class="foodMeasure">{{foodList.wightMesure}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 ">
                                        <div class="row foodItemsCover">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0">
                                                <div class="plateCover" style="--aspect-ratio: 1 / 1;">
                                                    <div id="{{person1.day}}_{{session.type}}" class="main"
                                                        [innerHTML]="session.plateContent">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 p-0 my-auto"
                                                style="height:fit-content">
                                                <div class="foodValues">
                                                    <div style="width: 584px;">
                                                        <div class="foodItems"> <span> Energy (Kcal)</span> <br>
                                                            <span>{{session.energy}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Protien (gm)</span> <br>
                                                            <span>{{session.Protien}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Calcium (mg)</span> <br>
                                                            <span>{{session.Ca}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Iron (mg)</span> <br>
                                                            <span>{{session.Fe}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Fiber (mg)</span> <br>
                                                            <span>{{session.fiber}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Vit A (mg)</span> <br>
                                                            <span>{{session.vaitamina}}</span>
                                                        </div>
                                                        <hr />
                                                        <div class="foodItems"> <span> Fat (gm)</span> <br>
                                                            <span>{{session.Fat}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> H<sub>2</sub>O (gm)</span> <br>
                                                            <span>{{session.h2o}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Folic (mcg)</span> <br>
                                                            <span>{{session.Folic}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Carbos (mg)</span> <br>
                                                            <span>{{session.Carbohydrates}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Vit C (mg)</span> <br>
                                                            <span>{{session.Vitaminc}}</span>
                                                        </div>
                                                        <div class="foodItems"> <span> Vit B12 (mg)</span> <br>
                                                            <span>{{session.vitaminb12}}</span>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>
<div *ngIf="!forCasesheet">
    <div class="row" style="width:100%;margin:0 auto;" *ngIf="dietPlanFlag == 'max'">
        <a (click)="save()"><span class="cardsave"><img src="../../../assets/img/save.svg" style="width:20px"
                    style="width:25px" /></span> </a>
        <div class="col-12">
            <mdb-card>
                <mdb-card-header class="bg-white">
                    <div class="">
                        <div class=" ">
                        </div>
                        <div class="">
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input">
                                    Caloried required
                                    <span style="float: right;padding: 0 10px 0 0;">{{caloriesRequired}}</span>
                                </mat-label>
                                <div class="progress progress-xxs">
                                    <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77"
                                        aria-valuemin="0" aria-valuemax="100"
                                        [ngStyle]="{'border-bottom':'1px solid green','width':''}"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input">
                                    Total calories(Kcal)
                                    <span style="float: right;padding: 0 10px 0 0;">{{totalCalories}}</span>
                                </mat-label>
                                <div id="progress"></div>
                            </div>
                        </div>
                        <div class="col-9 col-sm-11 col-md-5 col-lg-3 col-xl-3" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input" id="sga">
                                    SGA score
                                    <span style="float: right;padding: 0 10px 0 0;">{{sgaOoverallScore}}</span>
                                </mat-label>
                                <div class="progress progress-xxs">
                                    <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23"
                                        aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width':''}"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-sm-1 col-md-1 col-lg-1 col-xl-1" style="text-align: center;">
                            <img src="../assets/global/images/settings-icon.png" (click)="openDietPlan()" class=""
                                style="position: relative;top: 8px;" width="22px" height="22px" />
                        </div>
                        <div class="dite_content_cover">
                            <div class="row">
                                <div class="col-12">
                                    <div class="switch-field" *ngIf="ynopt1 != undefined">
                                        <input type="radio" id="radio-three" (click)="changeType(1,'yes')"
                                            [disabled]="!currentDiet" />
                                        <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                                            <div class="tab_cover" style="display: inline-block;">
                                                <div class="tab_icon_cover" style="display: inline-block;"><img
                                                        src="../../../assets/ui_icons/Self.svg" class="iocinStyle"
                                                        alt=""></div>
                                                <div class="lableStyle">Current</div>
                                            </div>
                                        </label>
                                        <input type="radio" id="radio-four" (click)="changeType(1,'no')"
                                            [disabled]="!templateDiet" />
                                        <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                                            <div class="tab_cover" style="display: inline-block;">
                                                <div class="tab_icon_cover" style="display: inline-block;"><img
                                                        src="../../../assets/ui_icons/others.svg" class="iocinStyle"
                                                        alt=""></div>
                                                <div class="lableStyle">Template </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation"
                                    *ngFor="let person of displayArray; let i = index">
                                    <button class="nav-link" id="{{person.day}}-tab" data-bs-toggle="tab"
                                        data-bs-target="#{{person.day}}" type="button" role="tab"
                                        aria-controls="{{person.day}}" aria-selected="true"
                                        (click)="changeDay(person.day)">{{person.dayLable}}</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show" *ngFor="let person1 of displayArray; let i = index"
                                    id="{{person1.day}}" role="tabpanel" aria-labelledby="{{person1.day}}-tab"
                                    style="height: 565px; overflow-y: auto;  overflow-x: hidden;">
                                    <div class="row" *ngFor="let session of person1.session;">
                                        <div class="col-8 col-sm-7 col-md-12 col-lg-4 col-xl-4">
                                            <div class="card cardCover">
                                                <div class="card-header cardHeader">
                                                    {{session.lableName}}
                                                    <span style="float: right;">
                                                        {{session.energy}} Kcal
                                                    </span>
                                                </div>
                                                <div class="card-body">
                                                    <div *ngIf="session.type == 'Morning'">
                                                        <mat-label class="matlabel">Add food item <br>
                                                            <input type="text" class="ipcss mainDishInput"
                                                                [(ngModel)]="morningFoods" name="morningFoods"
                                                                (keyup)="changeFoodType(morningFoods, session.type)"
                                                                type="text" aria-label="Number" matInput
                                                                [matAutocomplete]="autoMorning" />
                                                            <div style="display: inline-block;">
                                                                <img *ngIf="session.mainDish == true"
                                                                    src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                    height="20px" alt="" class="mainDishImg">
                                                                <img *ngIf="session.mainDish == false"
                                                                    (click)="mainDish(session,person1.day)"
                                                                    src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                    height="20px" alt="" class="mainDishImg">
                                                            </div>
                                                            <mat-autocomplete #autoMorning="matAutocomplete">
                                                                <mat-option id="optionfont"
                                                                    (click)="selctedFood(person1.day,food)"
                                                                    *ngFor="let food of morningFoodItem"
                                                                    [value]="food.description">
                                                                    {{food.displayContent}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </mat-label>
                                                    </div>
                                                    <div *ngIf="session.type == 'Breakfast'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput"
                                                            [(ngModel)]="BreakfastFoods" name="BreakfastFoods"
                                                            (keyup)="changeFoodType(BreakfastFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoBreakfast" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoBreakfast="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="selctedFood(person1.day,food)"
                                                                *ngFor="let food of breadfastFoodItem"
                                                                [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Mid-day'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput"
                                                            [(ngModel)]="MidDayFoods" name="MidDayFoods"
                                                            (keyup)="changeFoodType(MidDayFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoMidDay" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoMidDay="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="selctedFood(person1.day,food)"
                                                                *ngFor="let food of midDayFoodItem"
                                                                [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Lunch'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput"
                                                            [(ngModel)]="LunchFoods" name="LunchFoods"
                                                            (keyup)="changeFoodType(LunchFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoLunchFoods" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoLunchFoods="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="selctedFood(person1.day,food)"
                                                                *ngFor="let food of lunchFoodItem"
                                                                [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Evening'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput"
                                                            [(ngModel)]="EveningFoods" name="EveningFoods"
                                                            (keyup)="changeFoodType(EveningFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoEveningFoods" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoEveningFoods="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="selctedFood(person1.day,food)"
                                                                *ngFor="let food of eveningFoodItem"
                                                                [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Dinner'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput"
                                                            [(ngModel)]="DinnerFoods" name="DinnerFoods"
                                                            (keyup)="changeFoodType(DinnerFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoDinnerFoods" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoDinnerFoods="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="selctedFood(person1.day,food)"
                                                                *ngFor="let food of dinnerFoodItem"
                                                                [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div *ngIf="session.type == 'Night'">
                                                        <mat-label class="matlabel">Add food item</mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput"
                                                            [(ngModel)]="NightFoods" name="NightFoods"
                                                            (keyup)="changeFoodType(NightFoods, session.type)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoNightFoods" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoNightFoods="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="selctedFood(person1.day,food)"
                                                                *ngFor="let food of nightFoodItem"
                                                                [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div class="foodItemCover">
                                                        <div *ngFor="let foodList of session.foodItems"
                                                            style="display: flex;margin-bottom: 5px;">
                                                            <span>
                                                                <img *ngIf="foodList.checked == false"
                                                                    (click)="isSelected(foodList,'add')"
                                                                    src="../../../assets/ui_icons/yellow_box.svg"
                                                                    style="cursor: pointer;" width="15px" />
                                                                <img *ngIf="foodList.checked == true"
                                                                    (click)="isSelected(foodList,'remove')"
                                                                    src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                    style="cursor: pointer;" width="15px" />
                                                            </span>
                                                            <div class="foodLable"> {{ foodList.description }} </div>
                                                            <div class="foodInputCover"> <input type="text"
                                                                    (change)="changeQuantity(foodList.cals_weight,foodList.day,foodList,session.type)"
                                                                    [(ngModel)]="foodList.cals_weight"
                                                                    class="foodMesureInput"
                                                                    [disabled]="!foodList.checked"
                                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                <div class="foodMeasure">{{foodList.wightMesure}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 ">
                                            <div class="row foodItemsCover">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0">
                                                    <div class="plateCover">
                                                        <div id="{{person1.day}}_{{session.type}}" class="main"
                                                            [innerHTML]="session.plateContent">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 p-0 my-auto"
                                                    style="height:fit-content">
                                                    <div class="foodValues">
                                                        <div style="width: 584px;">
                                                            <div class="foodItems"> <span> Energy (Kcal)</span> <br>
                                                                <span>{{session.energy}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Protien (gm)</span> <br>
                                                                <span>{{session.Protien}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Calcium (mg)</span> <br>
                                                                <span>{{session.Ca}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Iron (mg)</span> <br>
                                                                <span>{{session.Fe}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Fiber (mg)</span> <br>
                                                                <span>{{session.fiber}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Vit A (mg)</span> <br>
                                                                <span>{{session.vaitamina}}</span>
                                                            </div>
                                                            <hr />
                                                            <div class="foodItems"> <span> Fat (gm)</span> <br>
                                                                <span>{{session.Fat}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> H<sub>2</sub>O (gm)</span>
                                                                <br>
                                                                <span>{{session.h2o}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Folic (mcg)</span> <br>
                                                                <span>{{session.Folic}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Carbos (mg)</span> <br>
                                                                <span>{{session.Carbohydrates}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Vit C (mg)</span> <br>
                                                                <span>{{session.Vitaminc}}</span>
                                                            </div>
                                                            <div class="foodItems"> <span> Vit B12 (mg)</span> <br>
                                                                <span>{{session.vitaminb12}}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
    <div *ngIf="dietPlanFlag == 'min'">
        <div class="details row">
            <div class="col-12" style="margin: 5px 0;">
                <div class="diet_lable_cover">
                    <mat-label class="dite_input">
                        Caloried required
                        <span style="float: right;padding: 0 10px 0 0;">{{caloriesRequired}}</span>
                    </mat-label>
                </div>
            </div>
            <div class="col-12" style="margin: 5px 0;">
                <div class="diet_lable_cover">
                    <mat-label class="dite_input">
                        Total calories(Kcal)
                        <span style="float: right;padding: 0 10px 0 0;">{{totalCalories}}</span>
                    </mat-label>
                </div>
            </div>
            <div class="col-12" style="margin: 5px 0;">
                <div class="diet_lable_cover">
                    <mat-label class="dite_input" id="sga">
                        SGA score
                        <span style="float: right;padding: 0 10px 0 0;">{{sgaOoverallScore}}</span>
                    </mat-label>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="titleStyle">
                    <h5 class="modal-title" id="staticBackdropLabel">Diet Conformation</h5>
                    <span class="closeicon" data-bs-dismiss="modal" aria-label="Close" style="float: right;"><img
                            src="../../../assets/img/close_icon.png" alt="Card image cap" style="width:19px"> </span>
                </div>
                <div class="modal-body">
                    Whould you like to copy {{previous_day}} diet plan to other days?
                </div>
                <div class="modal-footer">
                    <img src="../../../assets/ui_icons/buttons/No_button.svg" alt="Card image cap"
                        style="width:85px; height: 25px;" (click)="assingData('no')">
                    <img src="../../../assets/ui_icons/buttons/yes_button.svg" alt="Card image cap"
                        style="width:85px; height: 25px;" (click)="assingData('yes')">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="pdfModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog " style="width: 950px; max-width: 950px;">
        <div class="modal-content">
            <div class="modal-body">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8" style="margin-top: 10px;">
                    <iframe class="controls" [src]="pdfURL" width="900px" height="700px" frameborder="0"
                        webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>