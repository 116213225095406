import { Component, OnInit,ElementRef,Input, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Date_Formate } from '../../../assets/js/common.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';

@Component({
  selector: 'app-partial-payment',
  templateUrl: './partial-payment.component.html',
  styleUrls: ['./partial-payment.component.scss']
})
export class PartialPaymentComponent implements OnInit {
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('dischargesummary') dischargesummary: ElementRef;
  @ViewChild('printheader') printheader: ElementRef;
  @ViewChild('billprint') printsection: ElementRef;

  public filterType: string;
  public fromDate: string;
  public toDate: string;
  public dateFlag: boolean;
  public clientFlag: boolean;
  public billFlag: boolean;
  public billListFlag: boolean;
  public saveFlag: boolean;

  public clientFilterList = [];
  public filteredClientList = [];
  public clientList = [];
  public clntName: string;
  public middleName: string;
  public lastName: string;
  public clientId: string;
  public relationId: string;
  public subRelId: string;
  public billingId: string;
  public fName: string;
  public mName: string;
  public lName: string;
  public billAmount: string;
  public refundType: string;
  public refundAmount: number;
  public hptlClinicId: string;
  
  public billList = [];
  public billDetails = [];
  public refundList = [];
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public getDate;
  public hospId;
  public userId;
  public billRemarks;
  public clnt_name;
  public clnt_age;
  public clnt_mobile;
  public patdet_flag: boolean=true;
  public paid_flag:boolean=true;
  public showarray: boolean=true;
  public parpayarray:any=[];
  public paymentArray:any=[];
  private closeResult;
  public print_template;
  public hospital_logo;
  public printlogostyle;
  public printFlag:boolean = false;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public loader;
  public hosp_name;
  public printbutton:boolean=false;
  public nodata: boolean=true;
  public usertype:string;
  public provtype;
  public provtypeArray:any=[];

  constructor(public gservice:CommonDataService,public http:HttpClient,
    public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  
    public messageservice:MenuViewService,private modalService: NgbModal) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.usertype = Helper_Class.getPartialUser();
  
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;

    this.userId = Helper_Class.getInfo().user_id;
    if(this.usertype =="front-desk"){
      this.provtypeArray.push("Hospital");
      if(Helper_Class.getInfo().diag_centre_id != undefined){
        this.provtypeArray.push("Diagnosis");
      }
      this.provtype = this.provtypeArray[0];

    } else {
      this.provtypeArray.push("Diagnosis");
      this.provtype = this.provtypeArray[0];
    }
    this.filterType = "Name";
    this.filterTypeChange();
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;
    this.print_template = Helper_Class.getInfo().bill_print_template;
    this.printlogostyle=Helper_Class.getInfo().bill_print_logo_style;
    this.getPaymentType();
    this.getRetrieveData()
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      {country: ipaddress.country_code},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          //City_change
          this.currentDate = obj.current_date;
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.fromDate = this.currentDate;
          this.toDate = this.currentDate;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  filterTypeChange(){
    if(this.filterType =="Bill number"){
      this.dateFlag = true;
      this.clientFlag=true;
      this.billFlag= false;
      this.billListFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount=0;
      this.saveFlag=false;
      this.billList=[];

    } else if(this.filterType =="UHID"){
      this.dateFlag = true;
      this.clientFlag=true;
      this.billFlag= false;
      this.billListFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount=0;
      this.saveFlag=false;
      this.billList=[];

    } else if(this.filterType =="Mobile"){
      this.dateFlag = true;
      this.clientFlag=true;
      this.billFlag= false;
      this.billListFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount=0;
      this.saveFlag=false;
      this.billList=[];

    } else if(this.filterType =="Name"){
      this.dateFlag = true;
      this.clientFlag=false;
      this.billFlag= true;
      this.billListFlag=true;
      this.saveFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount=0;
      this.billList=[];

    } else if(this.filterType =="Date"){
      this.dateFlag = false;
      this.clientFlag=true;
      this.billFlag= true;
      this.billListFlag=true;
      this.saveFlag=true;
      this.fName="";
      this.mName="";
      this.lName=""
      this.billingId="0";
      this.refundAmount=0;
      this.billList=[];
      this.getCurrentDate();

    }
  }

  clientFilter() {
    this.clientFilterList = [];
    this.clntName = this.clntName.toLocaleUpperCase();
    this.clientList = [];
    this.middleName = "";
    this.lastName = "";
    var clntname = encrypt_decript.Encript(this.clntName).toString();

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbyadm/', { client_name: clntname},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse( JSON.stringify(response));
          
          if (obj.clients != null) {
            this.clientList = [];
            this.clientFilterList = obj.clients;

            for (var i = 0; i < this.clientFilterList.length; i++) { 
              var lName = this.clientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].last_name) : "";
              var mName = this.clientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].middle_name) : "";
              this.clientList.push({
                name: encrypt_decript.Decript(this.clientFilterList[i].first_name) + " " + lName,
                first_name:this.clientFilterList[i].first_name,
                last_name:this.clientFilterList[i].last_name,
                client_id: this.clientFilterList[i].client_reg_id,
                relation_id: this.clientFilterList[i].relation_id,
                sub_rel_id:this.clientFilterList[i].sub_rel_id,
              });
            }
            this.filteredClientList = this.clientList;
            // this.filteredClientList = this.clientList.filter(function (this:any,el) {
            //   return el.name.indexOf(this.clnt_name_txt.toLowerCase()) > -1;
            // }.bind(this));
          }
        },
        error => {});
  }

  selectClient(data) {
    this.clntName = encrypt_decript.Decript(data.first_name);
    this.clientId=undefined;
    this.relationId=undefined;
    this.subRelId=undefined;
    for (var i = 0; i < this.clientFilterList.length; i++) {
      if(this.clientFilterList[i].client_reg_id == data.client_id && this.clientFilterList[i].relation_id == data.relation_id){
        this.middleName = this.clientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].middle_name) : "";
        this.lastName = this.clientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].last_name) : "";
        this.clientId=this.clientFilterList[i].client_reg_id;
        this.relationId= this.clientFilterList[i].relation_id;
        if(this.clientFilterList[i].sub_rel_id != undefined && this.clientFilterList[i].sub_rel_id != null){
          this.subRelId = this.clientFilterList[i].sub_rel_id;
        }
      }
    }
    this.clientList = [];
  }

  getBillDetails(){
    this.billList=[];
    var headers = new HttpHeaders();
    var apptype, hospid;
    if(this.provtype =="Hospital"){
      apptype = "doctor",
      hospid= this.hospId
    } else if(this.provtype =="Diagnosis"){
      apptype = "diagnosis";
      hospid= Helper_Class.getInfo().diag_centre_id;
    } else {
      apptype = "pharma";
      hospid= Helper_Class.getInfo().pharma_id;
    }
    var senddata = {
      hptl_clinic_id: hospid,
      billing_id: this.billingId,
      app_type: apptype,
    }
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbillhosp/', senddata, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if(obj.first_name != undefined){
            this.patdet_flag=false;
            if(obj.middle_name != undefined){
              this.clnt_name=encrypt_decript.Decript(obj.first_name)+" "+encrypt_decript.Decript(obj.middle_name)+" "+encrypt_decript.Decript(obj.last_name);

            } else {
              this.clnt_name=encrypt_decript.Decript(obj.first_name)+" "+encrypt_decript.Decript(obj.last_name);
            }
          }
          this.clnt_mobile = encrypt_decript.Decript(obj.mobile);
          this.clnt_age = encrypt_decript.Decript(obj.age);
          var dateval = obj.created_date.split("-");
         
          if(obj.paid_flag =="Fully paid"){
            this.paid_flag= true;
          } else {
            this.paid_flag= false;
          }
          var showlist;
          if(obj.partial_payments != undefined){
            showlist= true;
          } else {
            showlist= false;
          }
          this.billList.push({
            billno:this.billingId,
            date:dateval[2]+"-"+dateval[1]+"-"+dateval[0],
            billamount:encrypt_decript.Decript(obj.bill_amount),
            paid_amt: obj.paid_amount,
            balance:obj.balance,
            payamount:"0.00",
            payflag:obj.paid_flag,
            paidflag:this.paid_flag,
            fin_year:obj.fin_year,
            partial_payments:obj.partial_payments,
            showimage: showlist,
            remarks:obj.remarks,
          })
        })
  }

  public remarks="";
  searchBills(){
    this.billList=[];
    var apptype,hospid;

    if(this.provtype =="Hospital"){
      apptype = "doctor",
      hospid= this.hospId
    } else if(this.provtype =="Diagnosis"){
      apptype = "diagnosis";
      if(this.usertype =="front-desk"){
        hospid= Helper_Class.getInfo().diag_centre_id;
      } else {
        hospid=Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      }
     
    } else {
      apptype = "pharma";
      hospid= Helper_Class.getInfo().pharma_id;
    }
    
    if(this.filterType == "Bill number"){
      this.getBillDetails();
      this.saveFlag = false;  
      this.patdet_flag=false;

    } else {
      this.patdet_flag=true;
      var fromdate;
      var todate;
      var fieldvalue;
      if (this.fromDate != undefined){
        fromdate=Date_Formate(this.fromDate);
        todate=Date_Formate(this.toDate);
      }
     
      if(this.filterType == "Date"){
        fieldvalue = {
            center_id: hospid,
            from_date: Date_Formate(fromdate),
            to_date: Date_Formate(todate),
            app_type: apptype,
            provtype:this.provtype,
            provider_id:"All"
          }

      } else if(this.filterType =="Name"){
        fieldvalue = {
          center_id: hospid,
          first_name: this.clntName,
          last_name: this.lastName,
          app_type: apptype,
          provtype:this.provtype,
          provider_id:"All"
        }

      } else if(this.filterType =="UHID"){
        fieldvalue = {
          center_id: hospid,
          app_type: apptype,
          patient_id:this.billingId,
          provtype:this.provtype,
          provider_id:"All"
        }

      } else if(this.filterType =="Mobile"){
        fieldvalue = {
          center_id: hospid,
          app_type: apptype,
          mobile:this.billingId,
          provtype:this.provtype,
          provider_id:"All"
        }
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/getbills/', {fieldvalue},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("PARTIAL PAYMENTS "+JSON.stringify(obj))
            if(obj.bills != undefined && obj.bills.length !=0){
              this.billListFlag = false;
              this.nodata =true;
              for(var i=0;i<obj.bills.length;i++){
                if(obj.bills[i].paid_flag!= undefined && obj.bills[i].paid_flag =="Fully paid"){
                  this.paid_flag= true;
                } else {
                  if(parseFloat(obj.bills[i].paid_amount) >= parseFloat(encrypt_decript.Decript(obj.bills[i].amount))){
                    this.paid_flag= true;
                  } else {
                    this.paid_flag= false;
                  }
                }
                var showlist;
                if(obj.bills[i].partial_payments != undefined){
                  showlist= true;
                } else {
                  showlist= false;
                }

                this.remarks="";
                console.log("remaks ....",obj.bills[i].partial_payments)
                var objpartial=obj.bills[i].partial_payments
                if(objpartial != undefined){
                  for(var j=0; j < objpartial.length; j++){
                    if(objpartial[j].remarks != undefined || null){
                      this.remarks += objpartial[j].remarks+","
                    }
                  }
                }

                var balval = parseFloat(encrypt_decript.Decript(obj.bills[i].amount))-parseFloat(obj.bills[i].paid_amount);
                var dateval = obj.bills[i].date.split("-");
                var pname;
                if(obj.bills[i].middle_name !=undefined){
                  pname = encrypt_decript.Decript(obj.bills[i].first_name)+" "+encrypt_decript.Decript(obj.bills[i].middle_name)+" "+encrypt_decript.Decript(obj.bills[i].last_name);
                } else {
                  pname = encrypt_decript.Decript(obj.bills[i].first_name)+" "+encrypt_decript.Decript(obj.bills[i].last_name);
                }
                this.billList.push({
                  pname:pname,
                  billno:obj.bills[i].bill_id,
                  date:dateval[2]+"-"+dateval[1]+"-"+dateval[0],
                  billamount:encrypt_decript.Decript(obj.bills[i].amount),
                  paid_amt: obj.bills[i].paid_amount,
                  balance:balval,
                  payamount:"0.00",
                  payflag:obj.bills[i].paid_flag,
                  paidflag:this.paid_flag,
                  fin_year:obj.bills[i].fin_year,
                  partial_payments:obj.bills[i].partial_payments,
                  showimage: showlist,
                  patremarks:this.remarks
                })
                this.printbutton=true;
              }
            } else {
              this.nodata=false;
            }
          },
          error => {});
    }
  }

  save(){
    var apptype,hospid;
    if(this.provtype =="Hospital"){
      apptype = "doctor",
      hospid= this.hospId
    } else if(this.provtype =="Diagnosis"){
      apptype = "diagnosis";
      if(this.usertype =="front-desk"){
        hospid= Helper_Class.getInfo().diag_centre_id;
      } else {
        hospid=Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      }
     
    } else {
      apptype = "pharma";
      hospid= Helper_Class.getInfo().pharma_id;
    }

    var bpayments=[];
    for(var i=0;i<this.billList.length;i++){
      var paamt = parseInt(this.billList[i].paid_amt);
      var pamt = parseInt(this.billList[i].payamount);
      var bamt = parseInt(this.billList[i].billamount);
      var tamt = paamt+pamt;
      
      var balance=  bamt-tamt; 
      if(balance ==0){
        var pflag="Fully paid";
      } else {
        pflag = "Partially paid";
      }
      
      bpayments.push({
        billing_id:this.billList[i].billno,
        hptl_clinic_id:hospid,
        fin_year: this.billList[i].fin_year,
        paid_amount:tamt,
        balance: balance,
        paid_flag:pflag,
        payment: this.billList[i].payamount,
        pay_type:this.billList[i].pay_id,
        remarks:this.billList[i].remarks,
      })
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/spbp/', {bills: bpayments,country:"IN",apptype:apptype,},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if(obj.key != 0){
            this.toastr.success(Message_data.saveSuccess);
            this.billList=[];
            this.patdet_flag= true;
            this.billingId="";
          }
        });
  }

  showBillHistory(content:any,billno){
    this.parpayarray =[];
    for(var i=0;i<this.billList.length;i++){
      if(this.billList[i].billno == billno){
        this.parpayarray = this.billList[i].partial_payments;
      }
    }
    this.showarray=!this.showarray;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRetrieveData() {
    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
    }
    console.log("get retirval data",send)
     var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
        
          var values = JSON.parse( JSON.stringify(response));
          console.log("obj" + JSON.stringify(values));
          if (values != undefined || values != null) {

             this.hosp_name = values.hptl_name;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
          
            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }


          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  print() {
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {

      printContents = document.getElementById('printbanner').innerHTML;
    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; 

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }

    // let printContents, popupWin;
    // if (this.print_template != undefined && this.print_template == "banner") {
    //   printContents = document.getElementById('banner').innerHTML;
      
    // } else if (this.print_template != undefined && this.print_template == "noheader") {
    //   printContents = document.getElementById('noheader').innerHTML;

    // } else if (this.print_template != undefined && this.print_template == "logowithnamenopayconsqty") {
    //   printContents = document.getElementById('logowithnamenopayconsqty').innerHTML;

    // } else {
    //   printContents = document.getElementById('logowithname').innerHTML;
    // }

    // printContents = this.printlogowithname.nativeElement.innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <head>
        <title>Partial Payment</title>
        <link rel="stylesheet" media="screen" href="">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">

        <style>
          
        @page { size: landscape || potrait; } 
        @media print and (orientation: landscape) {
            .table {
              width: 100%;
            }
          }
        
          /* Portrait Styles */
          @media print and (orientation: portrait) {
            .table {
              width: auto;
            }
          }
        th{
            padding:0%;
          }
          .table{
            padding:5% !important;
            border: 1px solid #c7c7e9bf !important;
            border-radius: 10px !important;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
            font-family:Segoe UI;
            font-size:12px; 
          }
          </style>
      </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">
        
        <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
        ${printContents}
        </table>
        
        </body>
      </html>`
    );
    popupWin.document.close();
  }

  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paymentArray = obj.payments;

        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
}
