<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white ">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header" >
                          <h5 class="m-0" class="mainHeadingStyle">Casesheet</h5>
                        </div>
                        <div class="headerButtons">
                            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backFunction()" width="85px" />
                        </div>
                      </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div [hidden]="nodapp" class="nodata">No casesheet(s) found<br /><br /> <a
                            (click)="createCasesheet()" style="text-decoration: none;color: #fff;cursor: pointer;">Click
                            here to create</a></div>

                    <div class="dig_table_overflow">
                        <div class="table-responsive">
                            <table class="table table-hover table-dynamic" id="card_tbl">
                                <tr *ngFor="let caselist of casesheet_list">
                                    <td style="width: 85%;text-align: left;">{{caselist.case_list_data}}</td>
                                    <td style="width: 15%;">
                                        <img src="../../../assets/img/images.png" width="40px" height="auto"
                                            (click)="viewCasesheet(caselist)" />
                                        <img src="../../../assets/img/edit.png" width="35px" height="auto"
                                            (click)="casesheetDetails(caselist)" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>