<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">OT Booking</h5>
               </div>
               <div class="headerButtons p-2">
                  <!-- *ngIf="!showCalendarViewFlag" (click)="calendarView()" -->
                  <div *ngIf="!showCreateDetailFlag" class="radio-inputs mx-2">
                     <label class="radio">
                        <input type="radio" name="radio" value="Table" (change)="changeView($event)">
                        <span class="name">Tabular View</span>
                     </label>
                     <label class="radio">
                        <input type="radio" name="radio" value="Calendar" (change)="changeView($event)"
                           [checked]="true">
                        <span class="name">Calendar View</span>
                     </label>
                  </div>
                  <!-- <button *ngIf="!showCalendarViewFlag" (click)="calendarView()" class="button-86"
                     role="button">Calendar View</button> -->
                  <img *ngIf="!showCreateDetailFlag" class="saveimgbtn_inpatinfo"
                     src="../../../assets/ui_icons/buttons/new_button.svg" (click)="createBooking()"
                     style="margin-left: 9px ;" />
                  <img *ngIf="showCreateDetailFlag" class="saveimgbtn_inpatinfo"
                     src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="goBack()"
                     style="margin-left: 9px ;" />
                  <img *ngIf="showCreateDetailFlag" src="../../../assets/ui_icons/buttons/save_button.svg"
                     class="saveimgbtn_inpatinfo" (click)="saveDetails('add')" />

                  <!-- <img *ngIf="showCreateDetailFlag && updateFlag"
                            src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
                            (click)="saveDetails('update')" /> -->
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="row" *ngIf="otListFlag">
               <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <p class="nodata" *ngIf="otDataList.length == 0">No OT(s) found</p>
                  <table *ngIf="otDataList.length" mdbTable datatable [dtOptions]="dtOptions"
                     class="mt-4 mb-4 table table-nowrap table-sm dataTable billlisttable" style="width: 95%;">
                     <thead>
                        <tr>
                           <th>Profile Image</th>
                           <th>Patient name</th>
                           <th>Operation Theatre Name</th>
                           <th>Booking Date</th>
                           <th>From Time</th>
                           <th>To Time</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let ot of otDataList; let i = index" (click)="getOtList(ot)">
                           <td><img src="{{ot.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                                 height="40px" width="40px" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                           <td style="font-size: 14px; text-align: left;">{{ ot.patFirstName }}</td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.ot_name }}</td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.booking_date }}</td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.from_time}}</td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.to_time}}</td>
                        </tr>
                     </tbody>
                  </table>
                  <br><br><br>
               </div>
            </div>
            <div class="row justify-content-center" *ngIf="showCreateDetailFlag">
               <div class="col-12">
                  <div class="conatiner-fluid">
                     <div class="row d-flex justify-content-center align-items-center p-3">
                        <div class="col-3 d-flex justify-content-center">
                           <img class="profile-pic" src="../../../assets/img/default.jpg" width="120px" />
                        </div>
                        <div class="col-9">
                           <div class="row">
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-2">
                                 <mat-label class="matlabel label-style">Patient Name
                                    <input type="text" class="ipcss inputStyle2" [(ngModel)]="patName" matInput
                                       [matAutocomplete]="auto1" (keyup)="getInpatients($event)" />
                                    <mat-autocomplete #auto1="matAutocomplete">
                                       <mat-option id="optionfont" *ngFor="let inpatient of inpatientList"
                                          (click)="selectInpatients(inpatient)" value="{{inpatient.inpatient_id}}">
                                          {{inpatient.name}}
                                       </mat-option>
                                    </mat-autocomplete>
                                 </mat-label>
                              </div>
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-2">
                                 <mat-label class="matlabel label-style">Operation Theatre<br>
                                    <mat-select [(ngModel)]="otId" class="ipcss inputStyle2" required>
                                       <mat-option value="select">Select</mat-option>
                                       <mat-option *ngFor="let otl of otList" value="{{otl.ot_id}}">{{otl.ot_name}}
                                       </mat-option>
                                    </mat-select>
                                 </mat-label>
                              </div>
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-2">
                                 <mat-label class="matlabel label-style">Date Of Booking
                                    <input type="date" class="ipcss inputStyle2" [(ngModel)]="surgery_date" matInput />
                                 </mat-label>
                              </div>
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-4 m-2">
                                 <mat-label class="matlabel label-style">Start time<br></mat-label>
                                 <div class="row">
                                    <div class="col-3" style="padding-right:0;">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="start_time1">
                                          <option *ngFor="let hrs of surgery_hrs" value="{{hrs}}">{{hrs}}
                                          </option>
                                       </select>
                                    </div>
                                    <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                                       : </div>
                                    <div class="col-3" style="padding-left:0;">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="start_time2">
                                          <option *ngFor="let min of surgery_min" value="{{min}}">{{min}}
                                          </option>
                                       </select>
                                    </div>
                                    <div class="col-5">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="start_time3">
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-4 m-2">
                                 <mat-label class="matlabel label-style">End time<br></mat-label>
                                 <div class="row">
                                    <div class="col-3" style="padding-right:0;">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="end_time1">
                                          <option *ngFor="let hrs of surgery_hrs" value="{{hrs}}">{{hrs}}
                                          </option>
                                       </select>
                                    </div>
                                    <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                                       : </div>
                                    <div class="col-3" style="padding-left:0;">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="end_time2">
                                          <option *ngFor="let min of surgery_min" value="{{min}}">{{min}}
                                          </option>
                                       </select>
                                    </div>
                                    <div class="col-5">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="end_time3">
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-12 card-container mt-3">
                  <div class="conatiner-fluid">
                     <div class="row">
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="label-style">Specialization<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="spl_id">
                                 <mat-option Selected>Select</mat-option>
                                 <mat-option *ngFor="let spl of spl_array" (click)="Change_Specialization(spl.spl_id)"
                                    value="{{spl.spl_id}}">
                                    {{spl.spl_name}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <label class="label-style" for="Surgery">Surgery</label>
                           <select class="ipcss inputStyle2 inpat_width" id="Surgery" name="Surgery"
                              [(ngModel)]="surgery_id">
                              <option Selected>Select</option>
                              <option *ngFor="let surgery of surgery_list" value="{{surgery.surgery_id}}">
                                 {{surgery.surgery_name}}
                              </option>
                           </select>
                        </div>

                        <div class="col-6 col-sm-12 col-md-4 col-lg-3 col-xl-4 col-xxl-4 m-2">
                           <mat-label class="label-style">Surgeons<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="surg_doc" multiple>
                                 <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                                    {{doctor.docname}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="label-style">Anesthetist<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="anaest_doc" multiple>
                                 <mat-option *ngFor="let doctor of anaest_doctors" value="{{doctor.doc_reg_id}}">
                                    {{doctor.name}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="label-style">Anesthesia Type<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="anaesttype">
                                 <mat-option Selected>Select</mat-option>
                                 <mat-option *ngFor="let type of anaest_types" value="{{type.type_id}}">
                                    {{type.description}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <!-- <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                    <mat-label>Specialization<br>
                                        <mat-select class="ipcss inpat_width" [(ngModel)]="spl_id">
                                            <mat-option Selected>Select</mat-option>
                                            <mat-option *ngFor="let spl of spl_array"
                                            (click)="Change_Specialization(spl.spl_id)" value="{{spl.spl_id}}">
                                            {{spl.spl_name}}</mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                
                                <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                    <label for="Surgery">Surgery</label>
                                    <select class="ipcss inpat_width" id="Surgery" name="Surgery"
                                        [(ngModel)]="surgery_id">
                                        <option Selected>Select</option>
                                        <option *ngFor="let surgery of surgery_list" value="{{surgery.surgery_id}}">
                                            {{surgery.surgery_name}}
                                        </option>
                                    </select>
                                </div> -->

                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="label-style">Consultants<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="consultant" multiple>
                                 <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                                    {{doctor.docname}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <div class="col-12" style="margin-top: 10px;">
                           <input class="mr-2" type="checkbox" id="surgery" name="vehicle1" value="true"
                              [(ngModel)]="isPatUpd">
                           <label class="mr-3" for="surgery"> is patient updated about the procedure and
                              process of the surgery</label><br>
                        </div>
                     </div>
                  </div>
               </div>

               <mat-accordion class="mataccordion mt-3">
                  <mat-expansion-panel class="expandpanel">
                     <mat-expansion-panel-header class="exppanel width">
                        <mat-panel-title class="title">
                           Surgery Equipments Required
                        </mat-panel-title>
                     </mat-expansion-panel-header>
                     <hr class="hr">
                     <div class="conatiner-fluid">
                        <div class="row">
                           <div class="col-12 card-container1 mt-2">
                              <p class="typeheading m-2">Anaesthesia</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Thiosol</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Neorof</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Tropin</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Pyrolate</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Myopyrolate</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Neovac</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Atracil</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Efipress</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Lox 2%</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Jelly</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Lox c Adr</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Anawin 0.25</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">0.5-Anawin Heavy</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Sevoflurane</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Isoflurane</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Trapic</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Cort-S</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Mezolam</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Dextomid(50mg)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Dextomid(100mg)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Dexa</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Avil</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Emeset</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Port. Chloride</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Lasix</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Dobutrex</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Adrenalin</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Nor Adrenalin</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Cardarone</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Fortwin</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Metolar</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Phenergan</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Mitroplus</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Befaloc</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Heparin</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Glycin</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Metrogyl</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">SodiumBicarbonate</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">CalciumGluconate</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-5 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">Analegesics</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg- col-xl-3 m-1">
                                       <mat-label class="label-style">Tramadol 50mg</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-1">
                                       <mat-label class="label-style">DiclofenacSodium</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-1">
                                       <mat-label class="label-style">Paracetamol</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-6 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">Suppositories</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg- col-xl-3 m-1">
                                       <mat-label class="label-style">Paracetamol</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-1">
                                       <mat-label class="label-style">Diclofenac</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-1">
                                       <mat-label class="label-style">Dulcollex</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">IV Fluids</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">DNS</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">RL</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">NS 1L</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">3L</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">500ml</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">100ml</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">3% Dextrose</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">25% Dextrose</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">SYRINGES / VENFLOW / SV SET</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">1ML</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">2.5ML</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">5ML</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">10ML</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">20ML</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">50ML</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">16g</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">18g</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">20g</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">22g</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">24g</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-4 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">LEVER LOCK SYRINGES</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-1">
                                       <mat-label class="label-style">5ML</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-1">
                                       <mat-label class="label-style">10ML</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-7 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">EPIDURAL SET</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-1">
                                       <mat-label class="label-style">25</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-1">
                                       <mat-label class="label-style">26</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-1">
                                       <mat-label class="label-style">27</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">28</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">S.V.SET</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">20</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">21</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">22</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">23</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">24</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Double Lumen</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Triple Lumen</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-8 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">GLOVES(Surgicare)</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-1 col-xl-1 m-1">
                                       <mat-label class="label-style">6</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">6.5</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-1 col-xl-1 m-1">
                                       <mat-label class="label-style">7</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">7.5</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-1 col-xl-1 m-1">
                                       <mat-label class="label-style">8</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">8.5</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-3 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">GAMEX</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-1">
                                       <mat-label class="label-style">6</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-1">
                                       <mat-label class="label-style">7</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">ANTIBIOTICS</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Garamycin</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Zostum</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Amikacin</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Taxim</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">SUTURE MATERIAL</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Prolene-1(40mm/30mm)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">2-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">3-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">4-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">5-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">6-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">7-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Merslik 2-0/3-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ethilon 2-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ethilon 3-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Loop Ethilon (0)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Vicryl 1</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Vicryl 2-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Vicryl 3-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Chromic 1</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Chromic 2-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Chromic 3-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Monocryl 2-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Monocryl 3-0</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Plain 3-0(With Needle)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Plain 2-0(Without Needle)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Sutupak 1</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Sutupak 2</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Sutupak 3</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Umbilical Tape</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">LT Clip(300/400)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">SURGICAL DRAPES</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ortrho Pack</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">General Pack</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Surgeon Gown</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Lithotomy Pack</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Pateint Gown</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Mini Pack</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Plastic Apron</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">GAUZE PACK</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">10*10</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">7.5*7.5</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">PMO Line</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Rose Elastic</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Cotton Role</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Rosidol (10cm/12cm)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">True Fix</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Dynaplast</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Roller Bandage(4/6)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Crepe Bandage(6"/4") Jelonet</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Bactigrass</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Abdominal Pad</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ganjee Pad</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">IMPLANT</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">DJ Stent</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Mesh (6*11) (15*15) (30*30)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Balloon</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Stent</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Stapler</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Grafts</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Guide Wire</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Plate</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Screws</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">SURGICAL BLADES</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">AB Gel</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Chest Drain 20</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Chest Drain 24</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Chest Drain 26</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Romova 14</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Romova 16</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Romova 18</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Romova 20</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Surgicel</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>

                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">MISCELLANEOUS</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Surgeon Cap&Mask</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">O,Mask</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Nebulizer Mask</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Surgical Spirit</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Betadine</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Scrub</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Micropore 1</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Micropore 2</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Micropore 3</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Tincture</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Disposable Gloves</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">IV Set</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Hydrogen Peroxide</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Cetzyme</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">CATHETERS</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Foley's Catheter 16</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Foley's Catheter 18</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Foley's Catheter 20</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Silicone Catheter 16</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Silicone Catheter 18</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Silicone Catheter 20</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ryles Tube 14</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ryles Tube 16</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ryles Tube 18</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ryles Tube 20</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Suction Catheter</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Ryles Tube 16</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Urobag</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Vacuum</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Suction Catheter(Tur Set)</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">Infant Feeding Tube</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">ET Tube 6</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">ET Tube 6.5</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">ET Tube 7</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">ET Tube 7.5</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">ET Tube 8</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 m-1">
                                       <mat-label class="label-style">ET Tube 8.5</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-7 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">ENERGY DEVICE</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-1">
                                       <mat-label class="label-style">Harmonic</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-1">
                                       <mat-label class="label-style">Diathermy</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-1">
                                       <mat-label class="label-style">Earth Pad</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-4 card-container1 mt-3 mx-1">
                              <p class="typeheading m-2">CONTRAST</p>
                              <div class="conatiner-fluid">
                                 <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-1">
                                       <mat-label class="label-style">Omni Paque</mat-label>
                                       <input type="text" class="ipcss inputStyle2 inpat_width" matInput />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </mat-expansion-panel>
               </mat-accordion>
               <div class="col-12" style="margin-top: 10px;">
                  <div class="conatiner-fluid">
                     <div class="row">
                        <div class="col-6">
                           <div class="ql-container">
                              <div class="col-12">
                                 <mat-label class="matlabel"><b class="label_bold">OT Notes</b></mat-label>
                              </div>
                              <div class="col-12">

                                 <quill-editor id="editor1" [(ngModel)]="otNotes"
                                    [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                                    placeholder="Enter Text" (onContentChanged)="changeOTNotes($event)">
                                 </quill-editor>
                                 <div>
                                    <quill-view-html hidden id="viewhtml1" style="overflow: hidden;"
                                       [content]="otNotes"></quill-view-html>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ql-container">
                              <div class="col-12">
                                 <mat-label class="matlabel"><b class="label_bold">Anaestesia
                                       procedure</b></mat-label>
                              </div>
                              <div class="col-12">
                                 <quill-editor id="editor2" [(ngModel)]="ansProc"
                                    [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                                    placeholder="Enter Text" (onContentChanged)="changeAnaeste($event)"></quill-editor>
                                 <div>
                                    <quill-view-html hidden id="viewhtml1" [content]="ansProc"></quill-view-html>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ql-container">
                              <div class="col-12">
                                 <mat-label class="matlabel"><b class="label_bold">Surgery
                                       procedure</b></mat-label>
                              </div>
                              <div class="col-12">
                                 <quill-editor id="editor3" [(ngModel)]="surgeryProc"
                                    [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                                    placeholder="Enter Text"></quill-editor>
                                 <div>
                                    <quill-view-html hidden id="viewhtml1" [content]="surgeryProc"></quill-view-html>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ql-container">
                              <div class="col-12">
                                 <mat-label class="matlabel"><b class="label_bold">Surgery
                                       outcome</b></mat-label>
                              </div>
                              <div class="col-12">
                                 <quill-editor id="editor4" [(ngModel)]="surgeryOut"
                                    [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                                    placeholder="Enter Text"></quill-editor>
                                 <div>
                                    <!-- Preview -->
                                    <quill-view-html hidden id="viewhtml1" [content]="surgeryOut"></quill-view-html>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row justify-content-center" *ngIf="showCalendarViewFlag">
               <div class="col-10">
                  <full-calendar [events]="eventList" [options]="calendarOptions"></full-calendar>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card> <br><br><br>
   </div>
</div>