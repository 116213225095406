<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Inpatient list</h5>
                    </div>
                    <div class="headerButtons">
                        <!-- <a><img (click)="save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                                class="saveimgbtn_inpatinfo"></a> -->
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 13px;margin:0;">
                    <div class="header_lable">
                        Ward
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-6 col-sm-12 col-md-6 col-xl-6 my-auto">
                                <mat-label class="matlabel">Select by
                                    <mat-radio-group class="dietradio" labelPosition="before"
                                        aria-label="Select an option" (ngModelChange)="selectBy($event)" color="primary"
                                        [(ngModel)]="selectType">
                                        <mat-radio-button style="width: 70px;" name="apptype" value="Ward">Ward
                                        </mat-radio-button>
                                        <mat-radio-button style="width: 70px;" name="apptype" value="Patient">
                                            &nbsp;&nbsp;Patient
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="hideWardFlag"
                                style="display: inline-block;">
                                <div style="display:inline-block;width: 20%;">
                                    <mat-label class="matlabel">Ward :</mat-label>
                                </div>
                                <div style="display:inline-block;width: 50%;">
                                    <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="ward"
                                        (ngModelChange)="changeByWard($event)">
                                        <option *ngFor="let ward of wardArray" value="{{ward.ward_id}}">
                                            {{ward.ward_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Select type
                                    <mat-select class="ipcss" [(ngModel)]="type">
                                        <mat-option value="Nurse">Nurse</mat-option>
                                        <mat-option value="Physio">Physio</mat-option>
                                    </mat-select>
                                </mat-label>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="nodata" *ngIf="patientList.length == 0">No patient(s) found</p>
                        <div *ngIf="patientList.length != 0" class="dig_table_overflow">
                            <div class="table-responsive">
                                <table class="card_tbl table table-hover table-dynamic">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let person of patientList; let i = index"
                                            (click)="viewPatient(person)">
                                            <td><img src="{{person.profile_image}}"
                                                    onerror="this.src='../../../assets/img/default.jpg';" height="40px"
                                                    width="40px" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                                            <td style="font-size: 12px;text-align: left;">{{ person.patient_name }}</td>
                                            <td style="font-size: 12px;">{{ person.mobile}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>