import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress.js';
import { ToastrService } from 'ngx-toastr';
import { Http, Headers, Jsonp } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
// import { Time_Formate_Session, ,Time_Formate,get_Date_Formate } from '../../../assets/js/common.js';

@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.css']
})
export class CancelAppointmentComponent implements OnInit {

  public canceltxt: string;

  constructor(public dialogRef: MatDialogRef<CancelAppointmentComponent>, public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router,public messageservice: MenuViewService) { 
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }

  cancelApps() {
    // if(this.canceltxt==undefined && this.canceltxt=="")
    // {

    //   this.toastr.error("Enter reason for cancellation")
    // }
    if (this.canceltxt == "" && this.canceltxt == null) {
      this.toastr.error(Message_data.appcancelreason);
      return false;
    }
    else if (this.canceltxt.length > 150) {
      this.toastr.error(Message_data.maxlen150);
      return false;
    }
    else {
      var cancel_list = [];
      cancel_list.push(
        localStorage.getItem("app_id")
      );
     
        var doc_reg_id = Helper_Class.getOpnRet().doc_reg_id;
        var opinion_id = Doc_Helper.getOpnid()
      var headers = new Headers();

      headers.append('Content-Type', 'application/json');
     
      this.http.post(ipaddress.getIp.toString() + 'secopn/canopn/',
        JSON.stringify({
          doc_reg_id:doc_reg_id ,
          opinion_id: opinion_id,
          reason: this.canceltxt
        }),
        { headers: headers })
        .subscribe(
          response => {

            

            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.messageservice.sendMessage("secondopinion");
              }

            }

          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    }
  }

}
