import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Message_data } from '../../../assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { FormControl } from '@angular/forms';
import { Observable, observable, Subscription } from 'rxjs';
import { Date_Formate, ConvertTimeformat } from '../../../assets/js/common';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-general-surgery',
  templateUrl: './general-surgery.component.html',
  styleUrls: ['./general-surgery.component.css']
})
export class GeneralSurgeryComponent implements OnInit {
  
  public current_date;
  public current_year;
  public current_time;
  public currentDate;
  public CurrentDatetime;
  public curr
  public url_txt
  public generalsurgFlag = "min";
  public surgerylist = [];
  public feelpainarray = [];
  public surgnoteList = [];

  public surgery: string;
  public sur_procedure: string;
  public feel_pain: string;
  public severity: string;
  public treatment: string;
  public side_effect: string;
  public rehab_require: string;
  public rehab_procedure: string;
  public next_data;
  public surg_note: string;

  public surproc_hidden: boolean;
  public surgery_hidden: boolean;
  public pain_hidden: boolean;
  public severity_hidden: boolean;
  public treat_hidden: boolean;
  public sideeffect_hidden: boolean;
  public rehab_hidden: boolean;
  public next_hidden: boolean;
  tomorrow = new Date();
  subscription: Subscription;
  public flag1: boolean;
  public doctor_name;

  public postsurgery_table: boolean;
  public surgery_table: boolean;

  public surgery_type;
  public sug_investigation;
  public image;
  public imagePath;
  public sendImge;
  public surgeryNewFlag: boolean = false;
  public getSugeryDetails;

  public viewImgesFlag: boolean = false

  public implant_name;
  public implant_make;
  public implant_company;
  public implant_others;
  public implant_stock;
  public implant_materialList = [];
  public implant_companyList = [];
  public implant_company_id;
  public implant_make_id;
  public personaData;
  public userinfo;
  public walkin;
  public isDraw:boolean = false;
  public anesthesia = "Select";

  constructor(public messageservice: CasesheetService, public dialog: MatDialog, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.flag1 = true;
    this.next_hidden = false;
    this.surgery_hidden = false;
    this.surproc_hidden = false;
    this.pain_hidden = false;
    this.severity_hidden = false;
    this.sideeffect_hidden = false;
    this.treat_hidden = false;
    this.rehab_hidden = false;
    this.feel_pain = "Yes";
    this.severity = "Severe";
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.generalsurgFlag = message;
      }
    });

    this.personaData = Doc_Helper.getClient_Info();
    this.userinfo = Helper_Class.getInfo();
    if(this.userinfo.first_name != undefined){
      if(this.userinfo.middle_name != undefined && this.userinfo.middle_name != null && this.userinfo.middle_name != ""){
        this.doctor_name = this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name;
      }else{
        this.doctor_name = this.userinfo.first_name + " " + this.userinfo.last_name;
      }
    }
    this.getSurgeries();
    this.appGetdate1();
    this.changetab('post_surgery');
  }

  getSurgeries() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/medproc', JSON.stringify({ spl_id: this.personaData.spl_id }),
      { headers: headers })
      .subscribe(
        response => {
          this.surgerylist = [];
          var obj = response.json();
          
          if (obj.med_procedures != null) {
            this.surgerylist = obj.med_procedures;
          }
          this.getImplantMaterial();

        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getImplantMaterial(){
    var send_data = { spl_id: this.personaData.spl_id };
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'other/im', JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          this.implant_materialList = [];
          var obj = response.json();
          
          this.implant_materialList = obj.implant_materials;
          this.getImplantCompany();
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  selectImplantMaterial(e){
    this.implant_make_id = e;
  }

  getImplantCompany(){
    var send_data = { spl_id: this.personaData.spl_id };
    

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'other/imc', JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          this.implant_companyList = [];
          var obj = response.json();
          
          this.implant_companyList = obj.company_details;
          this.retrieveSurgeryData();

        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  selectImplantCompany(e){
    this.implant_company_id = e;
  }

  addFeelPain() {
    var flag = true;

    if (this.treatment == undefined || this.treatment == "") {
      this.toastr.error(Message_data.addTreatmentDet);
    } else {
      var today = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
      var get_date = today.split('/');
      this.feelpainarray.push({
        pain: this.feel_pain,
        severity: this.severity,
        treatment: this.treatment,
        side_effect: this.side_effect,
        date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
        add_new: "1"
      });

      this.feel_pain = "Yes";
      this.severity = "Severe";
      this.treatment = "";
      this.side_effect = "";
    }
  }

  edit_feelpaint(data) {
    if (data.add_new == "1") {
      this.feel_pain = data.pain;
      this.severity = data.severity;
      this.treatment = data.treatment;
      this.side_effect = data.side_effect;

      for (var i = 0; i < this.feelpainarray.length; i++) {
        if (this.feelpainarray[i].add_new == data.add_new) {
          this.feelpainarray.splice(i);
        }
      }
    }
    this.send_data();
  }

  ChangeMatePicker(date_value) {
    var apt = new FormControl(new Date(date_value));
    return apt;
  }

  getDateMatePicker(e) {
    var month = ((e.value.getMonth() + 1) < 10 ? '0' : '') + (e.value.getMonth() + 1);
    var day = (e.value.getDate() < 10 ? '0' : '') + e.value.getDate();
    var apt = e.value.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  next_dateclick(date) {
    this.next_data = date;
    // let search_model = this.modalCtrl.create('CalendarPage',{flow:"future"});
    // search_model.present();

    // search_model.onDidDismiss(
    //   data => {
    //     if (data != null) {
    //       this.next_data = data;
    //     }
    //   });
  }

  addsurgnote() {
    var today = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    var get_date = today.split('/');
    if (this.surg_note != undefined && this.surg_note != "") {
      this.surgnoteList.push({
        surg_note: this.surg_note,
        Doct_Name: this.doctor_name,
        Date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
        add_new: "1"
      });
    }
    this.surg_note = "";
    this.send_data();
  }

  edit_surgnote(data) {
    if (data.add_new == "1") {
      this.surg_note = data.remarks_data;
      for (var i = 0; i < this.surgnoteList.length; i++) {
        if (this.surgnoteList[i].add_new == data.add_new) {
          this.surgnoteList.splice(i);
        }
      }
    }
    this.send_data();
  }

  send_data() {
    // Helper_Class.setClinicalHist(null);
    var pain_data = [];
    if (this.feelpainarray != undefined) {
      for (var i = 0; i < this.feelpainarray.length; i++) {
        if (this.feelpainarray[i].add_new == "1") {
          pain_data.push({
            pain: this.feelpainarray[i].pain,
            severity: this.feelpainarray[i].severity,
            treatment: this.feelpainarray[i].treatment,
            side_effect: this.feelpainarray[i].side_effect,
          })
          break;
        }
      }
    }

    if (this.next_data != undefined) {
      var nxt_vis = (this.next_data);
    }

    var surg_note = undefined;
    if (this.surgnoteList != undefined) {
      for (var i = 0; i < this.surgnoteList.length; i++) {
        if (this.surgnoteList[i].add_new == "1") {
          surg_note = this.surgnoteList[i].surg_note;
          break;
        }
      }
    }

    if(Helper_Class.getRet() != undefined){
        var sugeryDetails = {
          surgery: this.surgery,
          surgery_type: this.surgery_type,
          surgery_inv: this.sug_investigation,
          surgery_proc: this.sur_procedure,
          surgery_image: this.sendImge,
          updateImage: this.surgeryNewFlag,
        }
        Doc_Helper.setSugeryDetails(sugeryDetails);
    }else{
        var sugeryDetails = {
          surgery: this.surgery,
          surgery_type: this.surgery_type,
          surgery_inv: this.sug_investigation,
          surgery_proc: this.sur_procedure,
          surgery_image: this.sendImge,
          updateImage: this.surgeryNewFlag,
        }
        Doc_Helper.setSugeryDetails(sugeryDetails);
    }

    var surgery_plan = {
      surgery: this.surgery,
      surg_proc: this.sur_procedure,
      pain_factor: pain_data,
      rehab: this.rehab_require,
      rehab_proc: this.rehab_procedure,
      sur_next_visit: nxt_vis,
      surg_notes: surg_note,
      implant_name:this.implant_name,
      implant_make:this.implant_make_id,
      implant_company:this.implant_company_id,
      implant_others:this.implant_others,
      stock_avail:this.implant_stock,
      anaestitia_proc:this.anesthesia
    }
    //Helper_Class.setClinicalHist(surgery_plan);
    Helper_Class.setSurgicalHist(surgery_plan);
    
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }

  retrieveSurgeryData() {
    
    this.getSugeryDetails = Doc_Helper.getSugeryDetails();
    var obj;
    if(Helper_Class.getRet() != undefined){
      obj = Helper_Class.getRet();
    }
    if (this.getSugeryDetails != undefined) {
      if (this.getSugeryDetails.flow == "walkin") {
        this.walkin = "walkin";
      }else{
        this.walkin = "";
      }
    }
    if (this.walkin == "walkin") {
        if (this.getSugeryDetails.surgery != undefined) {
          this.surgery = this.getSugeryDetails.surgery;

        }
        if (this.getSugeryDetails.surgery_type != undefined) {
          this.surgery_type = this.getSugeryDetails.surgery_type;
        }
        if (this.getSugeryDetails.surg_proc != undefined) {
          this.sur_procedure = this.getSugeryDetails.surg_proc;
        }
        if (this.getSugeryDetails.surgery_inv != undefined) {
          this.sug_investigation = this.getSugeryDetails.surgery_inv;
        }
        if (this.getSugeryDetails.sugImageString != undefined) {
          this.image = this.getSugeryDetails.sugImageString;
          this.viewImgesFlag = true
        }
    }
    else {
      if (obj != undefined) {
          if (obj.surgery != undefined) {
            this.surgery = obj.surgery;
          }
          if (obj.surgery_type != undefined) {
            this.surgery_type = obj.surgery_type;
          }
          if (obj.surg_proc != undefined) {
            this.sur_procedure = obj.surg_proc;
          }
          if (obj.surgery_inv != undefined) {
            this.sug_investigation = obj.surgery_inv;
          }
          if (obj.surgery_image != undefined && obj.surgery_image != "") {
            this.image = ipaddress.Ip_with_img_address + obj.surgery_image;
            this.viewImgesFlag = true
          }
      }
    }

    if (obj != undefined) {
      if (obj.pain_factor != undefined) {
        this.feelpainarray = obj.pain_factor;
      }
      if (obj.rehab != undefined) {
        this.rehab_require = obj.rehab;
      }
      if (obj.rehab_proc != undefined) {
        this.rehab_procedure = obj.rehab_proc;
      }

      if (obj.sur_next_visit != undefined) {
        //  var ndate = this.ChangeMatePicker(obj.sur_next_visit);
        this.next_data = obj.sur_next_visit;
        // var ndate = obj.sur_next_visit.split("-");
        // this.next_data = ndate[2]+"-"+ndate[1]+"-"+ndate[0];
      }

      if (obj.sur_notes != undefined && obj.sur_notes != null) {
        for (var j = 0; j < obj.sur_notes.length; j++) {
          var sdate = obj.sur_notes[j].date.split("-");
          this.surgnoteList.push({
            surg_note: obj.sur_notes[j].sur_notes,
            Date: sdate[2] + "-" + sdate[1] + "-" + sdate[0],
            add_new: "0"
          })
        }
      }
      if (obj.implant_name != undefined) {
        this.implant_name = obj.implant_name;
      }
      if (obj.anaestitia_proc != undefined) {
        this.anesthesia = obj.anaestitia_proc;
      }
      if (obj.implant_make != undefined) {
        for(var i = 0; i < this.implant_materialList.length; i++){
          if(obj.implant_make == this.implant_materialList[i].material_id){
            this.implant_make = this.implant_materialList[i].material_name;
          }
        }
      }
      if (obj.implant_company != undefined) {
        for(var i = 0; i < this.implant_companyList.length; i++){
          if(obj.implant_company == this.implant_companyList[i].company_id){
            this.implant_company = this.implant_companyList[i].company_name;
          }
        }
      }
      if (obj.implant_others != undefined) {
        this.implant_others = obj.implant_others;
      }
      if (obj.stock_avail != undefined) {
        this.implant_stock = obj.stock_avail;
      }
    }
    // this.loadCss();
    // this.loadScript();
  }

  Date_click(data, value) {
    if (data != null) {

      this[value] = data;
      this.app_Date_Change(this.getDateMatePicker(data));

    }
  }

  appGetdate1() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            // this.curr_date = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.current_date = Date_Formate(obj.current_date);
            var current_date1 = obj.current_date;
            this.current_time = obj.current_time;
            this.current_year = obj.current_date.split('-')[0];
            this.next_data = (current_date1);
            this.CurrentDatetime = obj.current_date;
            this.currentDate = (obj.current_date)
            // this.next_txt = obj.current_date;
            //this.Appoint_Date =  this.CurrentDatetime;
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  app_Date_Change(Appointment_Date) {
    //this.time_List = []; // clear app time list

    if (this.current_date != Appointment_Date) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      var get_date = Appointment_Date.split("-");
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      //var get_sel_day_num = this.gservice.get_Day_num(dayName);

      var Available_flag_1 = false;
    }

  }

  OnDateMatePicker(e) {
    this.next_data = e;
    //this.get_doc_apps(this.user_id);
  }

  changetab(e) {
    if (e == "post_surgery") {
      this.postsurgery_table = true;
      this.surgery_table = false;
    }
    else {
      this.postsurgery_table = false;
      this.surgery_table = true;
    }
  }
  // canvase image function 

   loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/dist/all.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);

  }

  loadCss() {
    $("<link/>", {
      rel: "stylesheet",
      type: "text/css",
      href: "assets/dist/all.css"
    }).appendTo("mdb-card");
  }

  savesend_data() {

    if ((document.getElementById("theimage")) != null) {
      var img_str = (document.getElementById("theimage") as HTMLImageElement).src;
    }

    this.imagePath = img_str;
    this.sendImge
    var img_path = "";
    if (img_str != null) {
      img_path = (img_str.toString()).split(',')[1];
      
      this.surgeryNewFlag = true;
      // upd_url = "1";
    }
    else {
      img_path = "";
    }
    this.sendImge = img_path;
    this.surgeryNewFlag = false;
    //upload document
  }

  drawImage(e){
    if(this.isDraw == true){
      this.loadCss();
      this.loadScript();
    }
  }
}
