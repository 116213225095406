import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Day_Return, Session_Return, Time_Formate_Session } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { DatePipe, Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Http, Headers } from '@angular/http';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { FrontDeskService } from 'src/app/FrontDesk_module/front-desk/front-desk.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientCancelAppointmentComponent } from 'src/app/client/client-cancel-appointment/client-cancel-appointment.component';
import { masterCSData_Helper } from 'src/app/Doctor_module/casesheet/masterCSData_Helper';
import { MasterCSHelperService } from 'src/app/Doctor_module/casesheet/masterCSData.service';
import moment from 'moment';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;
@Component({
  selector: 'app-diet-appointments-detailed-view',
  templateUrl: './diet-appointments-detailed-view.component.html',
  styleUrls: ['./diet-appointments-detailed-view.component.scss']
})
export class DietAppointmentsDetailedViewComponent implements OnInit {

  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  isReadonly()  {
      var check = this.tempUser == true ?  false : true; 
      return check;
    }
  public tempUser:boolean = false;
  public CurrentDatetime;
  public Reassign_Btn: boolean;
  public Reassign_Div: boolean;
  public Docmidname;
  public Doctor_List_Data = [];
  public Templist = [];
  public SessionTemplist = [];
  public TokenorTime;
  public Timesplit;
  public From_time;
  public To_time;
  public AssgDoctor_Conslist = [];
  public Non_avail_Data;
  public Visiting_Session_Data = [];
  public TypeorTime;
  public Unavailable_Data;
  public Doctor_Consult_Data = [];
  public App_Type_Token;
  public App_Type_Time;
  public Book_Now_View;
  public session;
  public Unavailable: boolean;
  public send_array = [];
  public appointmenttime;
  public Temptime;
  public doctor;
  public doct_id;
  public Res_Send_Data;
  public app_button;
  public current_time;
  public video_button;

  public client_reg_id: string;
  public clnt_id: string;
  public clnt_name: string;
  public doc_name: string;
  public clnt_contactno: string;
  public app_date: string;
  public app_type: string;
  public app_session: string;

  public hosp_name: string;
  public hosp_address: string;
  public hosp_location: string;
  public hosp_city: string;
  public hosp_state: string;
  public print_template: string;
  public hptl_pres_logo: string;
  public hosp_logo: string;
  public pat_info: string;
  public refer_txt: string;

  public From_txt;
  public From_Split;
  public To_txt;
  public To_Split;
  public Appointment_Date;
  public curr_datetime;
  public curr_get_ftime;
  public Time_Data = [];
  public Available_flag;
  public Current_Date;
  public Now_Split;
  public Avail_flag;
  public Current_Time;
  public Hospital_name;
  public current_date;
  public Available_Session_flag: boolean;
  public Available_Div: boolean;
  public NonAvailable_Div: boolean;
  public get_view;
  public userinfo;
  public currentDate;
    //Get patient details
  public f_name;
  public m_name;
  public l_name;
  public Client_reg;
  public relation_id_data;
  public sub_rel_iddata;
  public rel_type;
  public age_txt;
  public gen_txt;
  public bld_txt;
  public gur_txt;
  public inc_txt;
  public occ_txt;
  public cont_txt;
  public sym_txt;
  public treat_type;
  public spl_txt;
  public hosp_info;
  public app_info;
  public spl_id;
 
  // RESHDULE
  public data_for_doctdetail;
  public Dayofweek;
  public reschedule_Div:boolean;
  public Normal_reschedule_div:boolean =  false;
  public Normal_App_Date;
  public send_data_doc_avail;
  public Resched_AppType;
  public app_Type_Token;
  public TimeorToken_Div;
  public visit_HomeSession;
  public visit_Session;
  public normal_doctor_data = [];
  public homecare_doctor_data = [];

  public DoctorAvail_Data = [];
  public visiting_Session_Data = [];
  public home_Session_Data = [];

  public Appointment_Time;
  public reschedule_reason: string;
  public dietType;
  public get_speclization_arry:any = [];
  public speclization_txt;
  public newsymptoms:any = [];
  public hosp_country;
  public appointmentDate;
  public userId;
  public hospitalId;
  public hosp_address2;
  public hospZipcode;
  public appTime;
  public clntSal;
  public clntSalList:any = [];
  public clntDob;
  public ageDisabledFlag:boolean = false ;
  public dobDisabledFlag:boolean;
  public cancelResone;
  public subscribeApp;
  constructor(public dialog:MatDialog,public datepipe: DatePipe,public gservice: CommonDataService, public http: Http, public toastr: ToastrService, public location: Location, public messageService: MenuViewService,public masterCSdata: MasterCSHelperService) {
    this.Reassign_Btn = true;
    this.Book_Now_View = true;
    this.app_button = true;
    this.video_button = true;
    this.Reassign_Div = true;
    this.Unavailable = true;
    this.App_Type_Token = true;
    this.App_Type_Time = true;
    this.reschedule_Div = false
  }

  async ngOnInit(){
    this.userinfo = Helper_Class.getInfo();
    this.print_template = Helper_Class.getInfo().hospitals[0].print_template;
    this.get_view = FrontDesk_Helper.getreceptionistcreatepage();
    await this.masterCSdata.getSpecilizationType();
    this.clntCountryId = this.userinfo.hospitals[0].country;
    this.clntStateId = this.userinfo.hospitals[0].state;
    this.clntCityId = this.userinfo.hospitals[0].city;
    this.clntLocationId = this.userinfo.hospitals[0].location;
    this.getCurrentDate();
    console.log("this.get_view" + JSON.stringify(this.get_view));
    this.getSalutations();
    if( this.get_view.user_type == "temp" ){
      this.tempUser = true;
      this.userId =  this.get_view.user_details.diet_reg_id;
      this.getSpecilizationType(null);
      this.rel_type = this.get_view.user_details.relation;
      this.f_name = encrypt_decript.Decript(this.get_view.user_details.first_name);
      this.m_name = (this.get_view.user_details.middle_name != "" &&  this.get_view.user_details.middle_name != null) ?  encrypt_decript.Decript(this.get_view.user_details.middle_name) : "";
      this.l_name = (this.get_view.user_details.last_name != "" &&  this.get_view.user_details.last_name != null) ?  encrypt_decript.Decript(this.get_view.user_details.last_name) : "";
      this.l_name = (this.get_view.user_details.last_name != "" &&  this.get_view.user_details.last_name != null) ?  encrypt_decript.Decript(this.get_view.user_details.last_name) : "";
      this.age_txt = this.get_view.user_details.loc_age;
      this.cont_txt = this.get_view.user_details.contact;
      this.dietType =  this.get_view.user_details.visit_purpose;
      this.sym_txt = this.get_view.user_details.symptoms;
      this.visit_Session = this.get_view.user_details.session;
     function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
      StringBuilder.prototype.append = function (value) {
        if (value) {
          this.strings.push(value);
        }
      }

      StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
      }

      StringBuilder.prototype.toString = function () {
        return this.strings.join("");
      }
          var Appoint_data = new StringBuilder("");
            Appoint_data.append("Appointment Type : Tempory");
            Appoint_data.append("\n");
            this.app_type = "temp"
            var date_txt = new Date(this.get_view.user_details.app_date);
            this.Dayofweek = date_txt.getDay() + 1;
            var get_date = this.get_view.user_details.app_date.split('-');
            this.app_date = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            Appoint_data.append("Date : " + get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
            Appoint_data.append("\n");
            Appoint_data.append("Session : " + this.get_view.user_details.session);
            Appoint_data.append("\n");
            this.app_session = this.get_view.user_details.session;
           
            if (this.get_view.user_details.token != null) {
              Appoint_data.append("Token no : " + this.get_view.user_details.token);
              Appoint_data.append("\n");
            } else {
              var Temptimesplit = this.get_view.user_details.f_time;
              var offTime = Temptimesplit.split(':');
              offTime[0] = offTime[0] % 12;
              var output = offTime.join(':');
              var get_time = output.split(":");
              if (get_time[0] == "0") {
                output = "12" + ":" + get_time[1];
              }

              Appoint_data.append("Time : " +
                Time_Formate_Session(output, this.get_view.user_details.session));
              Appoint_data.append("\n");
            }
            this.app_info = Appoint_data.toString();

            if (this.get_view.user_details.hosp_address2 != null && this.get_view.user_details.hosp_address2 != "") {
              this.hosp_address = this.get_view.user_details.hosp_address1 + " " + this.get_view.user_details.hosp_address2;
            } else {
              this.hosp_address = this.get_view.user_details.hosp_address1;
            }
            this.appTime = this.get_view.user_details.time != undefined ? this.dataCovert(this.get_view.user_details.time) : "" ;
            this.hosp_location = this.get_view.user_details.hosp_location;
            this.hosp_city = this.get_view.user_details.hosp_city + " - " + this.get_view.user_details.hosp_zipcode;
            this.hosp_state = this.get_view.user_details.hosp_state;
            this.hospZipcode =  this.get_view.user_details.hosp_zipcode;
            this.appointmentDate = this.get_view.user_details.app_date;
            this.hosp_name = this.get_view.user_details.hosp_name;
            this.hosp_address = this.get_view.user_details.hosp_address1;
            this.hosp_address2 = this.get_view.user_details.hosp_address2;
            var sb = new StringBuilder("");
            if (this.get_view.user_details.hosp_name != null) {
              sb.append(this.get_view.user_details.hosp_name);
              sb.append("\n");
              sb.append(this.hosp_location);
              sb.append("\n");
              sb.append(this.hosp_city);
              sb.append("\n");
              this.hosp_info = sb.toString();
            }
            this.clntSal = this.get_view.user_details.salutation;
            this.changeSalutation(this.get_view.user_details.salutation);
            this.getPackagesFun();
    }else{
      this.get_app();
    }
    this.getCountries("0");
    
  }
  selectDob(e){
    var l = e.split("-");
    this.clntDob = e;
    if(e != null){
      this.ageDisabledFlag = true;
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/ddif", JSON.stringify({
      dobdate:  Date_Formate(this.clntDob),
      curdate:  Date_Formate(this.currentDate),
      spl:this.spl_id,
    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if(obj !=undefined){
          this.age_txt=obj.value;
        }
      })
  }
  changeAge(){
    if(this.age_txt != ""){
      this.dobDisabledFlag = true;
      this.clntDob = "";
    }else{
      this.dobDisabledFlag = false;
    }
  }
  getCurrentDate() {//get current date
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            this.current_time = obj.current_time;
            this.currentDate = obj.current_date;
            this.Normal_App_Date = this.CurrentDatetime;
            var get_date = obj.current_date.split('-');
            this.Appointment_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
          }
        },error => {});
  }

  getSalutations(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(ipaddress.getIp + "gen/sal", { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if(obj !=undefined){
            for(var i=0;i<obj.salutations.length;i++){
              this.clntSalList.push({
                sal_id:obj.salutations[i].salutation_id,
                sal_desc:obj.salutations[i].salutation_desc,
              });
            }
           
            
          }
        })
  }
  changeSalutation(e){
    if(e == "1"|| e == "6")
      this.gen_txt = "Male";
    else if(e == "2" || e == "3" || e == "4" || e == "7")
      this.gen_txt = "Female";
    else if(e == "5")
      this.gen_txt = "Transgender";
    else{
      this.gen_txt = "";
    }
  }
  get_app() {
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var send_data = {
      diet_app_id: this.get_view.doc_app_id,
      type: "dietician",
      home_care: "0",
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp/',JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()

          if (obj != null && obj.specialization_id != null) {
            this.spl_id = obj.specialization_id;
          }

          if (obj.first_name != null) {
            this.clnt_id = obj.patient_id;
            this.doct_id = obj.doc_id;
            this.client_reg_id = obj.client_reg_id;
            this.f_name = encrypt_decript.Decript(obj.first_name);
            this.healthPackageID = obj.package_name
            this.subscribeFlag = obj.subscribe_app == "1" ? true : false;
            this.subscribeApp = obj.subscribe_app == "1" ? "Yes" : "No";
            if (obj.middle_name != null && obj.middle_name != undefined) {
              this.m_name = encrypt_decript.Decript(obj.middle_name);
            }
            this.l_name = encrypt_decript.Decript(obj.last_name);

            if (obj.middle_name != null && obj.middle_name != undefined) {
              localStorage.setItem("client_name", obj.first_name + " " + obj.middle_name + " " + obj.last_name);
              this.clnt_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
            } else {
              localStorage.setItem("client_name", obj.first_name + " " + obj.last_name);
              this.clnt_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }

            this.Client_reg = obj.client_reg_id;
            this.relation_id_data = obj.relation_id;

            if (obj.sub_rel_id != null) {
              this.sub_rel_iddata = obj.sub_rel_id;
            }

            this.rel_type = obj.relation;
            this.age_txt = obj.age;
            this.gen_txt = encrypt_decript.Decript(obj.gender);

            if (obj.blood_desc != null) {
              this.bld_txt = obj.blood_desc;
            }

            if (obj.guardian != null) {
              this.gur_txt = encrypt_decript.Decript(obj.guardian);
            }

            if (obj.income != null) {
              this.inc_txt = encrypt_decript.Decript(obj.income);
            }

            if (obj.occupation != null) {
              this.occ_txt = encrypt_decript.Decript(obj.occupation);
            }

            this.dietType = obj.visit_purpose;

            this.cont_txt = encrypt_decript.Decript(obj.mobile);
            if (obj.symptoms != null) {
              this.sym_txt = obj.symptoms;
            }

            this.treat_type = obj.medicare_name;
            if (obj.spl_id != undefined) {
               this.getSpecilizationType(obj.spl_id)
            }
            this.refer_txt = obj.referred_by != undefined ? obj.referred_by : "";
            // if (obj.ref_by != undefined) {
            //   this.refer_txt = obj.ref_by;
            // }
           
            this.doc_name = obj.dr_middle_name != undefined && obj.dr_middle_name != null && obj.dr_middle_name != '' ? obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name : obj.dr_first_name + " " + obj.dr_last_name;
            this.hosp_name = obj.hosp_name;
            this.hosp_logo = ipaddress.Ip_with_img_address + obj.hptl_logo;
            if (obj.hptl_pres_logo != undefined) {
              this.hptl_pres_logo = ipaddress.Ip_with_img_address + obj.hptl_pres_logo;
            }

            if (obj.address2 != null && obj.address2 != "") {
              this.hosp_address = obj.address1 + " " + obj.address2;
            } else {
              this.hosp_address = obj.address1;
            }

            this.hosp_location = obj.location;
            this.hosp_city = obj.city + " - " + obj.zipcode;
            this.hosp_state = obj.state + " , " + obj.country;
             
            var sb = new StringBuilder("")
            if (obj.hosp_name != null) {
              sb.append(obj.hosp_name);
              sb.append("\n");
              sb.append(this.hosp_location);
              sb.append("\n");
              sb.append(this.hosp_city);
              sb.append("\n");
              this.hosp_info = sb.toString();
            }

            var clntsb = new StringBuilder("")
            var address;
            if (obj.rel_address2 != undefined && encrypt_decript.Decript(obj.rel_address2).toString() != "") {
              address = encrypt_decript.Decript(obj.rel_address1).toString() + " " + encrypt_decript.Decript(obj.rel_address2).toString();
            } else {
              address = encrypt_decript.Decript(obj.rel_address1).toString();
            }

            if (obj.rel_address1 != undefined) {
              clntsb.append(address);
              clntsb.append("\n");
              clntsb.append(obj.rel_location);
              clntsb.append("\n");
              clntsb.append(obj.rel_city);
              clntsb.append("\n");
              clntsb.append(obj.rel_state);
              clntsb.append("\n");
              this.pat_info = clntsb.toString();
            }
            
            var Appoint_data = new StringBuilder("");
            if (obj.app_type == "1") {
              Appoint_data.append("Appointment Type : New");
              Appoint_data.append("\n");
              this.app_type = "New";
            } else {
              Appoint_data.append("Appointment Type : Follow-ups");
              Appoint_data.append("\n");
              this.app_type = "Follow-up";
            }
            var date_txt = new Date(obj.date);
            this.Dayofweek = date_txt.getDay() + 1;
            var get_date = obj.date.split('-');
            this.app_date = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            Appoint_data.append("Date : " + get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
            Appoint_data.append("\n");
            Appoint_data.append("Session : " + encrypt_decript.Decript(obj.session).toString());
            Appoint_data.append("\n");
            this.app_session = encrypt_decript.Decript(obj.session).toString();
           
            if (obj.token != null) {
              Appoint_data.append("Token no : " + obj.token);
              Appoint_data.append("\n");
            } else {
              var Temptimesplit = obj.f_time;
              var offTime = Temptimesplit.split(':');
              offTime[0] = offTime[0] % 12;
              var output = offTime.join(':');
              var get_time = output.split(":");
              if (get_time[0] == "0") {
                output = "12" + ":" + get_time[1];
              }

              Appoint_data.append("Time : " +
                Time_Formate_Session(output, encrypt_decript.Decript(obj.session)));
              Appoint_data.append("\n");
            }

            this.app_info = Appoint_data.toString();
            if (obj.status == "0") {
              this.toastr.error(Message_data.completedApptCantedit);
            } else {
              this.Reassign_Btn = false;
              this.app_button = false;
              this.video_button = false;
              this.reschedule_Div =  true;
            }
          } else {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  reassignClick() {//re-asign
    this.Reassign_Div = false;
    this.getCurrentDate();
    this.getDoctors();
    this.Reassign_Btn = true;
    this.reschedule_Div = false;
  }

  backClicked() {//back
    this.messageService.sendMessage("manageappointments");
  }

  getDoctors() {//Get doctors
    var ipaddress1 = Helper_Class.getIPAddress();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/docbyrep',
      JSON.stringify({
        country: ipaddress.country_code,
        spl_id: this.spl_id,
        reg_id: this.userinfo.user_id,
        imei: ipaddress1,
        type: "dietician",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.doctors.length; i++) {
            this.Docmidname = "";
            if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
              this.Docmidname = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
            } else {
              this.Docmidname = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
            }

            this.Non_avail_Data = [];
            if (obj.doctors[i].non_avail != null) {
              this.Non_avail_Data = obj.doctors[i].non_avail;
            }

            this.Doctor_List_Data.push({
              Doc_Name: this.Docmidname,
              Doc_ID: obj.doctors[i].prov_id
            });

            this.Templist = [];
            this.SessionTemplist = [];
            this.TokenorTime = "";

            if (obj.doctors[i].consultation != undefined) {
              for (var j = 0; j < obj.doctors[i].consultation.length; j++) {
                this.TokenorTime = obj.doctors[i].consultation[j].token_count == "0" ? "time" : "token";
                this.Timesplit = new Array();
                this.Timesplit = obj.doctors[i].consultation[j].available_from.split(':');
                this.Timesplit = new Array();
                this.Timesplit = obj.doctors[i].consultation[j].available_to.split(':');

                this.Templist.push({
                  Workday: Day_Return(obj.doctors[i].consultation[j].work_days),
                  Session: Session_Return(obj.doctors[i].consultation[j].day_session),
                  Avail_from: this.gservice.get_Timeformate(obj.doctors[i].consultation[j].available_from),
                  Avail_to: this.gservice.get_Timeformate(obj.doctors[i].consultation[j].available_to),
                  Time_Duration: obj.doctors[i].consultation[j].time_duration
                });

                this.SessionTemplist.push(Session_Return(obj.doctors[i].consultation[j].day_session));
              }
            }
            this.AssgDoctor_Conslist.push({
              Doc_ID: obj.doctors[i].prov_id,
              Cosultation: this.Templist,
              Tokentime: this.TokenorTime,
              Sessionlist: this.SessionTemplist,
              NonAvail: this.Non_avail_Data,
            });
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  assign_DoctorChange(doctor) {//doctor change
    this.Doctor_Consult_Data = [];
    this.Visiting_Session_Data = [];
    this.Unavailable_Data = [];
    this.App_Type_Token = true;
    this.App_Type_Time = true;
    this.Book_Now_View = false;

    if (doctor != null) {
      for (var i = 0; i < this.Doctor_List_Data.length; i++) {
        if (this.Doctor_List_Data[i].Doc_Name == this.doctor) {
          this.doct_id = this.Doctor_List_Data[i].Doc_ID;
        }
      }

      for (var i = 0; i < this.AssgDoctor_Conslist.length; i++) {
        if (this.doct_id == this.AssgDoctor_Conslist[i].Doc_ID) {
          this.Doctor_Consult_Data = this.AssgDoctor_Conslist[i].Cosultation;
          this.TypeorTime = this.AssgDoctor_Conslist[i].Tokentime;
          this.Unavailable_Data = this.AssgDoctor_Conslist[i].NonAvail;

          if (this.AssgDoctor_Conslist[i].Sessionlist.indexOf("Morning") != -1) 
            this.Visiting_Session_Data.push({ description: "Morning", id: "1" });

          if (this.AssgDoctor_Conslist[i].Sessionlist.indexOf("Afternoon") != -1)
            this.Visiting_Session_Data.push({ description: "Afternoon", id: "2" });

          if (this.AssgDoctor_Conslist[i].Sessionlist.indexOf("Evening") != -1) 
            this.Visiting_Session_Data.push({ description: "Evening", id: "3" });
          break;
        }
      }
    }
  }

  reschedule_App() {// RESCHDEULE
    this.reschedule_Div = false;
    this.data_for_doctdetail = {
        day: this.Dayofweek,
        hptl_id: this.userinfo.hptl_clinic_id,
        doc_id: this.doct_id,
        is_dietician:"1",
    };
    this.get_normal_doct_data();
    this.Normal_reschedule_div = true;
  }

  get_normal_doct_data() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/getweekschedule/", JSON.stringify(this.data_for_doctdetail), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (data.json().weekschedule != null && data.json().weekschedule.length != 0) {
          for (var k = 0; k < data.json().weekschedule.length; k++) {
          this.home_Session_Data.push(this.gservice.get_Session(data.json().weekschedule[k].day_session));

            this.normal_doctor_data.push({
              hospital: this.hosp_name,
            workdays: this.gservice.get_Day(data.json().weekschedule[k].work_days),
            available_from: this.gservice.get_Timeformate(data.json().weekschedule[k].available_from),
            available_to: this.gservice.get_Timeformate(data.json().weekschedule[k].available_to),
            day_session: this.gservice.get_Session(data.json().weekschedule[k].day_session),
              token_count: data.json().weekschedule[k].token_count,
            });
          }
          this.Get_Doctor_Avail(this.Normal_App_Date);
        }
      },
      error => {});
  }

  Get_Doctor_Avail(current_date) {
    this.Unavailable = false;
    var today = new Date(current_date);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.send_data_doc_avail = {
      day: today.getDay() + 1,
      hptl_id: this.userinfo.hptl_clinic_id,
      doc_id: this.doct_id,
      country: ipaddress.country_code
    }

    this.http.post(ipaddress.getIp + "appointment/getdoctime/", JSON.stringify(this.send_data_doc_avail), { headers: headers }).subscribe(
      data => {
        if (data.json().non_available != null) {
          this.Unavailable_Data = data.json().non_available;
        }

        if (data.json().available != null) {
          if (data.json().available != null && data.json().available[0] != null) {
            this.DoctorAvail_Data = data.json().available;
            this.home_Session_Data = [];

            for (var i = 0; i < data.json().available.length; i++) {
              this.home_Session_Data.push(this.gservice.get_Session(data.json().available[i].day_session));
            }

            if (this.DoctorAvail_Data[0].token_count != "0") {
              this.Resched_AppType = "type";
              this.app_Type_Token = true;
            } else {
              this.Resched_AppType = "time";
              this.app_Type_Token = false;
            }
            this.Session_Add();
            this.Available_function1(current_date, this.current_time);
          } else {
            this.Available_Div = false;
            this.NonAvailable_Div = true;
          }
        }
      },
      error => {});
  }

  Available_function1(curtdate, curttime) {
    this.Available_flag = false;

    for (var i = 0; i < this.DoctorAvail_Data.length; i++) {
      var Cur_timesplit = curttime.split(':');
      var Current_time = new Date();
      Current_time.setHours(Cur_timesplit[0]);
      Current_time.setMinutes(Cur_timesplit[1]);
      var Avtotimesplit = this.DoctorAvail_Data[i].available_to.split(':');
      var Retrieved_time = new Date();
      Retrieved_time.setHours(Avtotimesplit[0]);
      Retrieved_time.setMinutes(Avtotimesplit[1]);

      if (curtdate == this.current_date) {
        if (Current_time < Retrieved_time) {
          this.Available_flag = true;
        }
      } else {
        this.Available_flag = true;
      }
    }

    if (this.Available_flag == false) {
      this.Available_Div = false;
      this.NonAvailable_Div = true;
    } else {
      this.Available_Div = true;
      this.NonAvailable_Div = false;
      this.visit_Session_Change(this.visit_Session, curtdate);
    }
  }

  visit_Session_Change(session, appdate) {
    this.Unavailable = false;
    if (session != null) {
      this.TimeorToken_Div = true;
      if (this.Resched_AppType == "time") {
        for (var i = 0; i < this.DoctorAvail_Data.length; i++) {
          if (session == this.gservice.get_Session(this.DoctorAvail_Data[i].day_session)) {
            var From_Split = this.DoctorAvail_Data[i].available_from.split(':');
            var To_Split = this.DoctorAvail_Data[i].available_to.split(':');
            var fromday = new Date();
            var parts = appdate.split('-');
            var curr_datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.current_time);
            var curr_get_ftime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.DoctorAvail_Data[i].available_from);
            fromday.setHours(From_Split[0]);
            fromday.setMinutes(From_Split[1]);
            var today = new Date();
            today.setHours(To_Split[0]);
            today.setMinutes(To_Split[1]);
            this.Time_Data = [];
            var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
            var chk_time = this.datepipe.transform(fromday, 'hh:mm');
            var end_time = this.datepipe.transform(today, 'hh:mm');
            this.Available_flag = this.check_not_available(appdate, session.description, chk_time, end_time);
            
            if (this.Available_flag == true && this.current_date == this.datepipe.transform(appdate, 'dd-MM-yyyy') && curr_get_ftime > curr_datetime) {
              this.Time_Data.push(New_time1);
            } else {
              if (this.Available_flag == true && this.current_date != this.datepipe.transform(appdate, 'dd-MM-yyyy')) {
                this.Time_Data.push(New_time1);
              }
            }

            while (fromday < today) {
              var Day_Now = new Date();
              if (this.current_date == this.datepipe.transform(appdate, 'dd-MM-yyyy')) {
                var Now_Split = this.current_time.split(':');
                Day_Now.setHours(Now_Split[0]);
                Day_Now.setMinutes(Now_Split[1]);
              } else {
                Day_Now.setHours(From_Split[0]);
                Day_Now.setMinutes(From_Split[1]);
              }
              fromday.setMinutes(fromday.getMinutes() + parseInt(this.DoctorAvail_Data[i].time_duration));
              var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
              var Avail_flag = this.check_not_available(appdate, session.description, chk_time1, end_time);
              if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
                var New_time = this.datepipe.transform(fromday, 'hh:mm a');
                if (New_time != this.gservice.get_Timeformate(this.DoctorAvail_Data[i].available_to)) {
                  this.Time_Data.push(New_time);
                }
              }
            }
            break;
          }
        }
      }
    } else {
      this.TimeorToken_Div = false;
    }
  }

  check_not_available(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);

    if (this.Unavailable_Data.length != 0) {
      for (var i = 0; i < this.Unavailable_Data.length; i++) {
        if (this.Unavailable_Data[i].full_day != null) {
          var Start_Date = new Date(this.Unavailable_Data[i].from_date);
          var End_Date = new Date(this.Unavailable_Data[i].to_date);
          if (check_date >= Start_Date && check_date <= End_Date) {
            Available_flag = false;
            break;
          }
        } else {
          if (sess != null) {
            var From_Split = this.Unavailable_Data[i].from_time.split(':');
            var To_Split = this.Unavailable_Data[i].to_time.split(':');

            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);

            var t2 = new Date(check_date); // t_time
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);

            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess))

            var get_time1 = this.ConvertTimeformat("24", get_f_time);
            var get_time2 = this.ConvertTimeformat("24", get_t_time);

            // get available date
            var From_Split1 = get_time1.split(':');
            var To_Split1 = get_time2.split(':');

            var get_f_time1:any = get_time1.split(':');
            var get_f_time2:any = get_time2.split(':');

            var f_time1 = new Date(check_date);
            f_time1.setHours(get_f_time1[0]);
            f_time1.setMinutes(get_f_time1[1]);

            var get_chk_date = this.datepipe.transform(check_date, 'dd-MM-yyyy');
            var get_f_date = this.datepipe.transform(this.Unavailable_Data[i].from_date, 'dd-MM-yyyy');
            var get_t_date = this.datepipe.transform(this.Unavailable_Data[i].from_date, 'dd-MM-yyyy');

            if (get_chk_date.toString() == get_f_date.toString() && sess == this.Unavailable_Data[i].day_session || get_chk_date.toString() == get_t_date.toString() && sess == this.Unavailable_Data[i].day_session) {
              if (f_time1 > t1 && f_time1 < t2) {
                Available_flag = false;
              }
              if (f_time1 == t1 && f_time1 <= t2) {
                Available_flag = false;
              }
              if (f_time1 >= t1 && f_time1 == t2) {
                Available_flag = false;
              }
              if (f_time1 == t1 && f_time1 == t2) {
                Available_flag = false;
              }
            }

            if (get_chk_date.toString() == get_f_date.toString() && get_chk_date.toString() == get_t_date.toString() && sess == this.Unavailable_Data[i].day_session) {
              if (f_time1 > t1 && f_time1 < t2) {
                Available_flag = false;
              }
              if (f_time1 == t1 && f_time1 < t2) {
                Available_flag = false;
              }
              if (f_time1 > t1 && f_time1 == t2) {
                Available_flag = false;
              }
              if (f_time1 == t1 && f_time1 == t2) {
                Available_flag = false;
              }
            }
          }
        }
      }
    }
    return Available_flag;
  }

  Reschedule_Book() {
    if (this.reschedule_reason != undefined && this.reschedule_reason.length != 0) {
      var gettime = this.current_time.split(':');
      var appDate = (this.Normal_App_Date);
      var appdatenew = this.Normal_App_Date;
      var mrg_flag = "", aft_flag = "", eve_flag = "";
      if (gettime[0] < 12) {
        mrg_flag = "Morning";
        aft_flag = "Afternoon";
        eve_flag = "Evening";
      } else if (gettime[0] >= 12 && gettime[0] <= 16) {
        mrg_flag = "";
        aft_flag = "Afternoon";
        eve_flag = "Evening";
      } else if (gettime[0] >= 16 && gettime[0] <= 24) {
        mrg_flag = "";
        aft_flag = "";
        eve_flag = "Evening";
      }

      if (this.current_date == appdatenew) {
        if (this.app_session == "Morning" && this.visit_Session == mrg_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else if (this.app_session == "Afternoon" && this.visit_Session == aft_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else if (this.app_session == "Evening" && this.visit_Session == eve_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else {
          this.book_app();
        }
      } else {
        this.book_app();
      }
    } else {
      this.toastr.error(Message_data.reasonForReschedule);
    }
  }

  book_app() {
    var valid_flag = false;
    var Date_splitter = (this.Normal_App_Date).split('-');
    var send_data;
    var url;

    if (this.Resched_AppType == "type") {// Token Appointment
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      url = "appointment/doctoken/";
      send_data = {
        hospital_id: this.userinfo.hptl_clinic_id,
        doc_reg_id: this.doct_id,
        date: this.Normal_App_Date,
        session: encrypt_decript.Encript(this.visit_Session).toString()
      }
    
    } else {// Time Appointment
      url = "appointment/getappexist/";
      if (this.Appointment_Time == undefined) {
        this.toastr.error(Message_data.sltTime);

        valid_flag = true;

      } else {
        var Temptime = this.Appointment_Time.split(' ');
        send_data = {
          type: "doctor",
          doc_reg_id:  this.doct_id,
          appointment_date: this.gservice.get_Date_Formate((this.Normal_App_Date)),
          session: encrypt_decript.Encript(this.visit_Session).toString(),
          from_time: Temptime[0] + ":00"
        }
      }
    }

    if (valid_flag == false) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(ipaddress.getIp + url,
        JSON.stringify(
          send_data
        ), { headers: headers }).subscribe(
          data => {
            

            if (data.json().result != null) {
              if (this.Resched_AppType == "type") {// Token
                if (data.json().result != "true") {
                  this.toastr.error(Message_data.sryApptClosedForSession);
                  valid_flag = false;
                }
                else {
                  this.Reschedule_Booking();
                }
              }
              else // Time
              {
                if (data.json().result != true) {
                  this.Reschedule_Booking();
                }
                else {
                  this.toastr.error(Message_data.sryAlreadyBooked);
                  valid_flag = false;
                }
              }
            }
          },
          error => {});
    }
  }

  Reschedule_Booking() {
    var Date_splitter = (this.Normal_App_Date).split('-');
  
    if (this.Resched_AppType == "type") {
      var Res_Send_Data = JSON.stringify({
        hospital_id: this.userinfo.hptl_clinic_id,
        doc_reg_id: this.doct_id,
        date: this.Normal_App_Date,
      //  date: Date_Formate(Date_splitter[2] + "-" + Date_splitter[1] + "-" + Date_splitter[0]),
        session: encrypt_decript.Encript(this.visit_Session).toString(),
        doc_app_id: this.get_view.doc_app_id,
        token: true,
        reschedule_reason: this.reschedule_reason
      })

    } else {
      var Temptime = this.Appointment_Time.split(' ');

      var Res_Send_Data = JSON.stringify({
        hospital_id: this.userinfo.hptl_clinic_id,
        doc_reg_id: this.doct_id,
        date: Date_Formate(Date_splitter[2] + "-" + Date_splitter[1] + "-" + Date_splitter[0]),
        session: encrypt_decript.Encript(this.visit_Session).toString(),
        doc_app_id: this.get_view.doc_app_id,
        token: false,
        f_time: Temptime[0] + ":00",
        reschedule_reason: this.reschedule_reason
      })
    }

    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "appointment/reschedule/", Res_Send_Data
      , { headers: headers }).subscribe(
        data => {
          if (data.json().result != "Can't reschedule more then three times") {
            this.toastr.success(data.json().result);
            this.messageService.sendMessage("manageappointments");
          } else {
            this.toastr.error(data.json().result);
            this.messageService.sendMessage("manageappointments");
          }
        },
        error => {
          
        }
      )
  }

  Session_Add() {
    this.visiting_Session_Data = [];
    var Current_Time_Split = this.current_time.split(':');
    var appDate = (this.Normal_App_Date).split("-");
    var appdatenew = appDate[2] + "-" + appDate[1] + "-" + appDate[0];

    if (this.current_date == appdatenew) {
      if (Current_Time_Split[0] < 12) {
        if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Morning") == true) {
          this.visiting_Session_Data.push({
            description: "Morning",
            id: "1"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Afternoon") == true) {
          this.visiting_Session_Data.push({
            description: "Afternoon",
            id: "2"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Evening") == true) {
          this.visiting_Session_Data.push({
            description: "Evening",
            id: "3"
          });
        }
      } else if (Current_Time_Split[0] >= 12 && Current_Time_Split[0] < 16) {
        if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Afternoon") == true) {
          this.visiting_Session_Data.push({
            description: "Afternoon",
            id: "2"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Evening") == true) {
          this.visiting_Session_Data.push({
            description: "Evening",
            id: "3"
          });
        }
      } else {
        if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Evening") == true) {
          this.visiting_Session_Data.push({
            description: "Evening",
            id: "3"
          });
        }
      }
    } else {
      if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Morning") == true) {
        this.visiting_Session_Data.push({
          description: "Morning",
          id: "1"
        });
      }
      if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Afternoon") == true) {
        this.visiting_Session_Data.push({
          description: "Afternoon",
          id: "2"
        });
      }
      if (this.gservice.get_Array_Contains_string(this.home_Session_Data, "Evening") == true) {
        this.visiting_Session_Data.push({
          description: "Evening",
          id: "3"
        });
      }
    }

    if (this.visiting_Session_Data.length != 0) {
      this.visit_Session = this.visiting_Session_Data[0].description;
      this.visit_HomeSession = this.visiting_Session_Data[0].description;
    }
  }

  OnDateMatePicker(e) {
    this.Normal_App_Date = e;
    var date_txt = new Date(this.Normal_App_Date);
    this.Dayofweek = date_txt.getDay() + 1;
    this.data_for_doctdetail = {
      day: this.Dayofweek,
      hptl_id: this.userinfo.hptl_clinic_id,
      doc_id: this.doct_id
    };
    this.get_normal_doct_data();
  }

  sessionChange() {
    if (this.TypeorTime == "time") {
      for (var i = 0; i < this.Doctor_Consult_Data.length; i++) {
        if (this.session == this.Doctor_Consult_Data[i].Session) {
          var From_Split = this.Doctor_Consult_Data[i].Avail_from.split(':');
          var To_Split = this.Doctor_Consult_Data[i].Avail_to.split(':');
          var fromday = new Date();
          fromday.setHours(this.From_Split[0]);
          fromday.setMinutes(this.From_Split[1]);
          var today = new Date();
          today.setHours(this.To_Split[0]);
          today.setMinutes(this.To_Split[1]);
          var parts = this.Appointment_Date.split('-');
          this.curr_datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.Current_Time);
          this.curr_get_ftime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.Doctor_Consult_Data[i].Avail_from);

          this.Time_Data = [];
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');

          this.Available_flag = this.Check_Availabilty(this.Appointment_Date, this.session, chk_time, end_time);

          if (this.Available_flag == true && this.Current_Date == this.Appointment_Date && this.curr_get_ftime > this.curr_datetime) {
            this.Time_Data.push(New_time1);
          } else {
            if (this.Available_flag == true && this.Current_Date != this.Appointment_Date) {
              this.Time_Data.push(New_time1);
            }
          }

          while (fromday < today) {
            var Day_Now = new Date();
            if (this.Current_Date == this.Appointment_Date) {
              var Now_Split = this.Current_Time.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(this.From_Split[0]);
              Day_Now.setMinutes(this.From_Split[1]);
            }

            fromday.setMinutes(fromday.getMinutes() + parseInt(this.Doctor_Consult_Data[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.Check_Availabilty(this.Appointment_Date, this.session, chk_time1, end_time);

            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.Doctor_Consult_Data[i].Avail_to)) {
                this.Time_Data.push(New_time);
              }
            }
          }
        }
      }
    }
  }

  ConvertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
  }

  Check_Availabilty(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(get_sel_date);

    if (this.Unavailable_Data.length != 0) {
      for (var i = 0; i < this.Unavailable_Data.length; i++) {
        if (this.Unavailable_Data[i].full_day != null) {
          if (check_date >= new Date(this.Unavailable_Data[i].from_date) && check_date <= new Date(this.Unavailable_Data[i].to_date)) {
            Available_flag = false;
            break;
          }

        } else {
          if (sess != null) {
            // get unavialable date
            var From_Split = this.Unavailable_Data[i].from_time.split(':');
            var To_Split = this.Unavailable_Data[i].to_time.split(':');

            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);

            var t2 = new Date(check_date); // t_time
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);

            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess));

            var get_time1 = this.ConvertTimeformat("24", get_f_time);
            var get_time2 = this.ConvertTimeformat("24", get_t_time);

            // get available date
            var From_Split1 = get_time1.split(':');
            var To_Split1 = get_time2.split(':');

            var get_f_time1 = get_time1.split(':');
            var get_f_time2 = get_time2.split(':');

            var f_time1 = new Date(check_date);
            f_time1.setHours(parseInt(get_f_time1[0]));
            f_time1.setMinutes(parseInt(get_f_time1[1]));

            var get_chk_date = this.gservice.get_Date_Formate(check_date);
            var get_f_date = this.gservice.get_Date_Formate(this.Unavailable_Data[i].from_date);
            var get_t_date = this.gservice.get_Date_Formate(this.Unavailable_Data[i].from_date);

            if (get_chk_date.toString() == get_f_date.toString() && sess == this.Unavailable_Data[i].session || get_chk_date.toString() == get_t_date.toString() && sess == this.Unavailable_Data[i].session) {
              if (f_time1 > t1 && f_time1 < t2) 
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 <= t2)) 
                Available_flag = false;

              if ((f_time1 >= t1 && f_time1 == t2))
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }

            if (get_chk_date.toString() == get_f_date.toString() && get_chk_date.toString() == get_t_date.toString() && sess == this.Unavailable_Data[i].session) {
              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 < t2))
                Available_flag = false;

              if ((f_time1 > t1 && f_time1 == t2))
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }
          }
        }
      }
    }
    return Available_flag;
  }

  app_Date_Change(appdate) {//appointment date change
    this.Time_Data = []; // clear app time list

    if (this.Current_Date != appdate) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      var result = new Date(appdate);
      var dayName = gsDayNames[result.getDay()];
      var get_sel_day_num = this.gservice.get_Day_num(dayName);
      var Available_flag_1 = false;

      for (var i = 0; i < this.Doctor_Consult_Data.length; i++) {
        if (this.Doctor_Consult_Data[i].day_num == "8" || (get_sel_day_num != 1 && this.Doctor_Consult_Data[i].day_num == "9")) {
          Available_flag_1 = true;
        } else {
          if (((new Date(appdate)).getDay() + 1) == this.Doctor_Consult_Data[i].day_num) {
            Available_flag_1 = true;
          }
        }
      }

      this.Unavailable = Available_flag_1 == false ? false : true;
    }
  }

  Searchbtn_Click() {
    if (this.session != null && this.session != undefined) {
      if (this.TypeorTime == "time") {
        this.App_Type_Token = true;
        this.App_Type_Time = false;
        this.sessionChange();
        this.Available_function(this.Appointment_Date, this.Current_Time);
      } else {
        this.App_Type_Token = false;
        this.App_Type_Time = true;
        this.Available_function(this.Appointment_Date, this.Current_Time);
      }
    } else {
      this.toastr.error(Message_data.sltSession);
    }
  }

  Available_function(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = curtdate.split('-');
    var get_sel_date = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[get_sel_date.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);

    for (var i = 0; i < this.Doctor_Consult_Data.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.Doctor_Consult_Data[i].day);

      if (this.Doctor_Consult_Data[i].hospital == this.Hospital_name) {
        var Cur_timesplit = [];
        if (curttime != undefined) {
          Cur_timesplit = curttime.split(':');
          var Curre_time = new Date(get_sel_date);
          Curre_time.setHours(Cur_timesplit[0]);
          Curre_time.setMinutes(Cur_timesplit[1]);
        }

        var Avtotimesplit = [];
        Avtotimesplit = this.Doctor_Consult_Data[i].Avail_to.split(':');
        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);

        if (this.Doctor_Consult_Data[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.current_date == this.gservice.get_Date_Formate(curtdate)) {
            if (Retrivel_time > Curre_time) {
              Available_flag = true;
            }
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.Doctor_Consult_Data[i].day_num) {
            if (this.current_date == this.gservice.get_Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.current_date == this.gservice.get_Date_Formate(curtdate)) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }

    Available_flag = this.Check_Availabilty(curtdate, null, null, null);
    if (this.Available_flag == false || this.Available_Session_flag == false) // Doctor Unavailable
    {
      this.Book_Now_View = true;
      this.Available_Div = true;
      this.NonAvailable_Div = false;
    } else {
      this.Book_Now_View = false;
      this.Available_Div = false;
      this.NonAvailable_Div = true;
    }
  }

  cancel_click() {//cancel appointment
    $("#staticBackdrop").modal("show");
    // const dialogRef = this.dialog.open(ClientCancelAppointmentComponent, {
    //   width: '50%',
    //   height: '300px',
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result != null) {
    //     if (result != undefined && result != undefined) {
    //       var sentdata = {
    //         reason: result,
    //         diet_app_id: this.get_view.doc_app_id,
    //       }
    //       var headers = new Headers();
    //       headers.append('Content-Type', 'application/json');
    //       this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp', JSON.stringify(sentdata), { headers: headers }).subscribe(
    //         data => {
    //           var obj = JSON.parse(data["_body"]);
    //           if (obj.key != null) {
    //             if (obj.key == "1") {
    //               this.toastr.success(Message_data.appcancel);
    //               this.messageService.sendMessage("manageappointments");
    //             }
    //           }else
    //             this.toastr.error(Message_data.apptNotCancelled);
    //         },error => {
    //           this.toastr.error(Message_data.getNetworkMessage());
    //         });
    //     }
    //   }
    // });
  }
  sendCancel(){
    if (this.cancelResone != null) {
          if (this.cancelResone != undefined && this.cancelResone != undefined) {
            var sentdata = {
              reason: this.cancelResone,
              diet_app_id: this.get_view.doc_app_id,
            }
            $("#staticBackdrop").modal("hide");
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp', JSON.stringify(sentdata), { headers: headers }).subscribe(
              data => {
                var obj = JSON.parse(data["_body"]);
                if (obj.key != null) {
                  if (obj.key == "1") {
                    $("#staticBackdrop").modal("show");
                    this.toastr.success(Message_data.appcancel);
                    this.messageService.sendMessage("manageappointments");
                  }
                }else
                  this.toastr.error(Message_data.apptNotCancelled);
              },error => {
                this.toastr.error(Message_data.getNetworkMessage());
              });
          }
        }
     
  }
  bookNow() {//appointment book
    var flag = true;

    if (this.session != null && this.session != undefined) {
      var get_date = Date_Formate(this.Appointment_Date);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.current_time);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
      

      if (this.session == cuur_mor_sess || this.session == cuur_afetr_sess ||
        this.session == cuur_eve_sess) {
        if (this.get_view.Appointment_Date == this.Appointment_Date && this.get_view.session == this.session) {
          this.toastr.error(Message_data.bookAnotherSession);
        } else {
          if (this.TypeorTime == "time") // Time Based
          {
            if (this.appointmenttime == undefined || this.appointmenttime == null) {
              this.toastr.error(Message_data.sltTime);
            } else {
              this.Temptime = this.appointmenttime.split(' ');
              for (var i = 0; i < this.Doctor_List_Data.length; i++) {
                if (this.Doctor_List_Data[i].Doc_Name == this.doctor) {
                  this.doct_id = this.Doctor_List_Data[i].Doc_ID;
                }
              }

              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + 'appointment/getappexist',
                JSON.stringify({
                  doc_reg_id: this.doct_id,
                  appointment_date: Date_Formate(this.Appointment_Date),
                  session: encrypt_decript.Encript(this.session).toString(),
                  from_time: this.Temptime[0] + ":00",
                  type: "doctor"
                }),
                { headers: headers })
                .subscribe(
                  response => {
                    var obj = JSON.parse(response["_body"]);
                    if (obj.result != false) {
                      this.toastr.error(Message_data.sryAlreadyBooked);
                    } else {
                      this.appBooking();
                    }
                  },
                  error => {
                    this.toastr.error(Message_data.getNetworkMessage());
                  });
            }
          } else {// Token Based
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'appointment/doctoken',
              JSON.stringify({
                hospital_id: this.userinfo.hptl_clinic_id,
                doc_reg_id: this.doct_id,
                date: Date_Formate(this.Appointment_Date),
                session: encrypt_decript.Encript(this.session).toString(),
              }),
              { headers: headers })
              .subscribe(
                response => {
                  var obj = JSON.parse(response["_body"]);
                  if (obj.result != "true") {
                    flag = false;
                  } else {
                    this.appBooking();
                  }
                },
                error => {
                  this.toastr.error(Message_data.getNetworkMessage());
                });
          }
        }
      } else {
        this.toastr.error(Message_data.sltValidSession);
      }
    } else {
      this.toastr.error(Message_data.sltSession);
    }
  }

  appBooking() {
    if (this.TypeorTime != "time") {
      this.Res_Send_Data = JSON.stringify({
        hptl_id: this.userinfo.hptl_clinic_id,
        doc_reg_id: this.doct_id,
        appointment_date: this.Appointment_Date,
        day_session: encrypt_decript.Encript(this.session).toString(),
        appointment_id: this.get_view.doc_app_id,
        token: true,
        appointment_time: "1"
      })
    } else {
      this.Temptime = this.appointmenttime.split(' ');
      this.Res_Send_Data = JSON.stringify({
        hptl_id: this.get_view.hptl_clinic_id,
        doc_reg_id: this.doct_id,
        appointment_date: this.Appointment_Date,
        day_session: encrypt_decript.Encript(this.session).toString(),
        appointment_id: this.get_view.doc_app_id,
        token: false,
        appointment_time: this.Temptime[0] + ":00"
      })
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/asnapp', this.Res_Send_Data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj != "Can't reschedule more then three times") {
            this.toastr.success(obj.result);
          } else {
            this.toastr.error(obj.result);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  print_app() {
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML;
    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML;
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
      <title>Prescription</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      .casesheet_container {
        width: 211px;
        padding: 1px;
        background: #277196;
        display: inline-flex;
        position: relative;
        padding-left: 5px;
        top: 21px;
        color: #FFFFFF;
      }
      .border_style{
        border:solid 1px;
        border-color: #488aff;
      }
      .width_style{
        margin-top:4px;
        width:600px;
      }
      p{
        margin:0;
      }
      
      </style>
    </head>
    <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
  </html>`
    );
    popupWin.document.close();
  }
  async getSpecilizationType(spl_id) {//Get specilization
    
    var obj = masterCSData_Helper.getMasterSpecilizationType();
    this.get_speclization_arry = [];
    if(obj != undefined){
      for (var i = 0; i < obj.specialization.length; i++) {
        if (obj.specialization[i].spl_name != "-") {
          this.specializationList.push({
            spl_id: obj.specialization[i].spl_id,
            spl_name: obj.specialization[i].spl_name
          });
          if( obj.specialization[i].spl_id == spl_id ){
              this.speclization_txt = obj.specialization[i].spl_name;
              break;   
          }
        }
      }
    }
  }
public clntAddress1;
public clntAddress2;
public clntCountry;
public clntCountryList:any = [];
public clntState;
public clntStateList:any = [];
public clntCity;
public clntCityList:any = [];
public clntLocation;
public clntLocationList:any = [];
public clntZipcode;
public locationUrl = ipaddress.getIp + "usercontroller/loc";
public locByNameUrl = ipaddress.getIp + "usercontroller/locbyname";
public cityUrl = ipaddress.getIp + "usercontroller/city";
public stateUrl = ipaddress.getIp + "usercontroller/state";
public countryUrl = ipaddress.getIp + "usercontroller/count";
public relationUrl = ipaddress.getIp.toString()+"gen/getrel";
public clntCountryId;
public clntStateId;
public clntCityId;
public clntLocationId;
public specializationList:any = [];
public healthPackageID:any = [];
public subscribeFlag:boolean = false;
public healthPackage:any = []
  getCountries(flag) {
    this.clntCountryList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.clntCountry = this.clntCountryList[c].description;
              this.clntCountryId = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      })
  }

  getStates(country, flag){
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl,JSON.stringify({country_id : country }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().states != null) {
          this.clntStateList = data.json().states;
          for (var i = 0; i < this.clntStateList.length; i++) {
            if (this.clntStateList[i].state_id == this.clntStateId) {
              this.clntStateId = this.clntStateList[i].state_id;
              this.clntState = this.clntStateList[i].description;
              this.getCities(this.clntStateId, flag);
              break;
            }
          }
        }
      })
  }

  changeState(clnt_state, flag){
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].description == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.clntState = this.clntStateList[i].description;
        this.clntCityList=[];
        this.clntCityId=undefined;
        this.clntLocationId=undefined;
        this.clntLocation="";
        this.clntZipcode="";
        this.getCities(this.clntStateId, flag);
      }
    }
  }

  getCities(state, flag){
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl,JSON.stringify({state_id : state }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.clntCityList = data.json().cities;
          for (var i = 0; i < this.clntCityList.length; i++) {
            if (this.clntCityList[i].district_id == this.clntCityId) {
              this.clntCityId = this.clntCityList[i].district_id;
              this.clntCity = this.clntCityList[i].description;
              this.getLocations(this.clntCityId);
              break;
            } else {
              this.clntCity = this.clntCityList[0].description;
            }
          }
        }
      })
  }

  changeCity(clnt_city, flag){
    this.clntLocationList=[];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].description == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.clntCity = this.clntCityList[i].description;
        this.clntLocationId=undefined;
        this.clntLocation="";
        this.clntZipcode="";
      }
    }
  }

  getLocations(city){
    this.clntLocationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl,JSON.stringify({city_id : city }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().locations != null && data.json().locations.length != 0) {
          this.clntLocationList = data.json().locations;
          for (var i = 0; i < this.clntLocationList.length; i++) {
            if (this.clntLocationList[i].location_id == this.clntLocationId) {
              this.clntLocationId = this.clntLocationList[i].location_id;
              this.clntLocation = this.clntLocationList[i].description;
              this.clntZipcode=this.clntLocationList[i].pincode;
              this.clntLocationList=[];
              break;
            }
          }
        }
      })
  }

  changeLocation(locat_desc) {
    this.clntLocationList =[];
    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locByNameUrl, JSON.stringify({
        name: locat_desc,
        city_id: this.clntCityId,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location){
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.clntLocation = this.clntLocationList[i].description;
        this.clntZipcode=this.clntLocationList[i].pincode;
      }
    }
    this.clntLocationList = [];
  }
  changeCountry(clnt_country, flag){
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        this.clntCountry = this.clntCountryList[c].description;
        this.clntCountryId = this.clntCountryList[c].country_id;
        this.clntStateList=[];
        this.clntCityList=[];
        this.clntLocationId = undefined;
        this.clntLocation="";
        this.clntZipcode="";
        this.getStates(this.clntCountryId, flag);
      }
    }
  }
 
  updateUser(){
    var create_flag=true; var dob;
    if(this.cont_txt.length != 10){
      create_flag = false;
    }
    if(this.cont_txt==undefined || this.f_name ==undefined || this.l_name == undefined 
      || this.gen_txt==undefined || this.clntLocation == null || this.clntLocation == "" || this.clntAddress1 == null || this.clntAddress1 == "" || this.clntAddress1 == undefined){
        create_flag = false;
      }
      
      if(create_flag == false ){
        if(this.cont_txt.length != 10){
          this.toastr.error(Message_data.validMobileNo);
        }else{
          this.toastr.error(Message_data.mandatory);
        }
      } else {
        var patientName = null;
        if (this.m_name != undefined && this.m_name != null) {
          if (this.m_name != "") {
            patientName = this.f_name.trim() + " " + this.m_name.trim() + " " + this.l_name.trim();
          } else {
            patientName = this.f_name.trim() + " " + this.l_name.trim();
          }
        } else {
          patientName = this.f_name.trim() + " " + this.l_name.trim();
        }
       
        var midname;
        if(this.m_name !=undefined){
          midname= encrypt_decript.Encript(this.m_name.trim()).toString();
        } else {
          midname="";
        }
        var occupation;
        if(this.occ_txt !=undefined){
          occupation = encrypt_decript.Encript(this.occ_txt.trim()).toString();
        }
        var addr1;
        if(this.clntAddress1 != null && this.clntAddress1 != undefined){
          addr1 = encrypt_decript.Encript(this.clntAddress1).toString()
        } else {
          addr1 = null;
        }
        var addr2;
        if(this.clntAddress2 != null && this.clntAddress2 != undefined){
          addr2 = encrypt_decript.Encript(this.clntAddress2).toString()
        } else {
          addr2 = null;
        }
        
        var curr_datetime = new Date(this.currentDate + " " + this.current_time);
        // var curr_session;
        // if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        //   curr_session = "Morning";
        // }
        // if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        //   curr_session = "Afternoon";
        // }
        // if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
        //   curr_session = "Evening";
        // }
        var new_symptoms = [];
        if(this.sym_txt != undefined && this.sym_txt != ""){
          if (this.sym_txt.indexOf(',') > -1) { 
            var dataval = this.sym_txt.trim().split(",");
            for(var i = 0; i < dataval.length; i++){
              if(this.newsymptoms.length != 0){
                for(var j= 0;j< this.newsymptoms.length; j++){
                  if(this.newsymptoms[j] != dataval[i]){
                    new_symptoms.push(dataval[i]);
                  }
                }
              }
            }
          }else{
            if(this.newsymptoms.length != 0){
              for(var j= 0;j< this.newsymptoms.length; j++){
                if(this.newsymptoms[j] != this.sym_txt.trim()){
                  new_symptoms.push(this.sym_txt.trim());
                }
              }
            }else{
              new_symptoms.push(this.sym_txt.trim());
            }
          }
        }
        var address:any =[];
        address.push({
          address1:addr1,
          address2:addr2,
          location:this.clntLocationId,
          city:this.clntCityId,
          state:this.clntStateId,
          country:this.clntCountryId,
          zipcode:encrypt_decript.Encript(this.clntZipcode).toString(),
          type:"pres"
        });
        var save_data;
       
        address = (addr1 != undefined && addr1 != null) ? address : undefined;
        var sub = this.tempUser == true ? "1" : "0";
        save_data = {
           prov_id: this.userId,
          // client_id:this.clientRegId,
          // relation_id: this.selectedRelationId,
          // salutation: this.clntSal,
          first_name:encrypt_decript.Encript(this.f_name.trim()).toString(),
          middle_name:midname,
          last_name: encrypt_decript.Encript(this.l_name.trim()).toString(),
          age: this.age_txt,
          dob: dob,
          gender: encrypt_decript.Encript(this.gen_txt).toString(),
          mem_mobile: encrypt_decript.Encript(this.cont_txt).toString(),
          hptl_clinic_id:this.userinfo.hptl_clinic_id,
          date: this.current_date,
          symptoms:this.sym_txt,
          newsymptoms:new_symptoms,
          ref_doc : this.refer_txt,
          spl_id :this.speclization_txt,
          occupation: occupation,
          citizenship:encrypt_decript.Encript("Indian").toString(),
          nationality:encrypt_decript.Encript("Indian").toString(),
           address:address,
          hosp_name: this.hosp_name,
          location:this.hosp_location,
          address1:this.hosp_address,
          address2:this.hosp_address2,
          city: this.hosp_city,
          state:this.hosp_state,
          country:this.hosp_country,
          zipcode:this.hospZipcode,
          provider: "dietician",
          app_date:this.appointmentDate,
          app_time:this.appTime,
          session:encrypt_decript.Encript(this.visit_Session).toString(),
          visit_purpose:this.dietType,
          temp_app_id : this.get_view.user_details.doc_app_id,
          package_id : this.healthPackageID,
          subscribe_app : sub,
        };
        console.log("get Send Data" + JSON.stringify(save_data))
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'appointment/duapp', JSON.stringify(save_data),
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              if(obj.status != 0){
                this.toastr.success(Message_data.apptSavedSuccess);
                this.messageService.sendMessage("manageappointments");
              }
            }); 
      }
  }
  dataCovert(date){
    const number = moment(date, ["h:mm A"]).format("HH:mm");
    console.log(number);
    return number
  }
  getPackagesFun(){
    var send_data = {
      type: "dietician",
      centre_id : this.userinfo.hptl_clinic_id
    }
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "adm/ghps", send_data, { headers: headers }).subscribe(
        data => {
          this.healthPackage = data.json().health_packages;
          console.log(" get dier " + JSON.stringify(this.healthPackage))
        },
        error => {
          this.toastr.error("unable to get package details");
        }
      )
  }
  selectSubscribe(action) {
    if (action == "yes") {
      this.subscribeFlag = true;
    } else {
      this.subscribeFlag = false;
    }
  }
}
