import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;
@Component({
  selector: 'app-nurse-assign-view',
  templateUrl: './nurse-assign-view.component.html',
  styleUrls: ['./nurse-assign-view.component.scss']
})
export class NurseAssignViewComponent implements OnInit {
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public relationType: string;
  public age: string;
  public gender: string;
  public bloodGroup: string;
  public guardian: string;
  public income: string;
  public occupation: string;
  public mobile: string;
  public symtome: string;
  public treatmentType: string;
  public spl: string;
  public hospIformation: string;
  public address: string;
  public clientRegID: string;
  public relationID: string;
  public subRelationID: string;
  public currentDatetime: string;
  public casesummeryFlag: Boolean;
  public homecare: string;
  public confirmFlag: boolean;
  public loader: boolean;
  public appID: any;
  public doctorName: string;
  public startDate: string;
  public endDate: string;
  public noOfSitting: string;
  public fees: string;
  public presInfo: string;
  public comments: string;
  public sendClientRegID;
  public curentDateURL;
  public reasonContent;
  constructor(public http: Http, public toastr: ToastrService, public messageService: MenuViewService) { }
  ngOnInit(): void {
    this.confirmFlag = true;
    this.casesummeryFlag = false;
    this.curentDateURL = ipaddress.getIp + 'adm/curdate';
    this.appID = Nurse_Helper.getAssignInfo().app_id;
    this.homecare = Nurse_Helper.getAssignInfo().home_care;
    this.currendDateData();
  }
  currendDateData() {
    this.sendClientRegID = {
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.curentDateURL, JSON.stringify(this.sendClientRegID), { headers: headers }).subscribe(
      data => {
        this.curentDateURL = data.json().result;
        if (data.json().current_date != null) {
          this.currentDatetime = data.json().current_date;
          this.get_app();
        }
        else {
        }
      }
    )
  }
  get_app() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'hcare/getasgdet/',
      JSON.stringify({
        assign_id: this.appID,
        type: "nurse",
        home_care: this.homecare,
        country: ipaddress.country_code,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.client_first_name != null) {
            this.loader = true;
            this.firstName = encrypt_decript.Decript(obj.client_first_name);
            if (obj.client_middle_name != undefined || obj.client_middle_name != null) {
              this.middleName = encrypt_decript.Decript(obj.client_middle_name);
            }
            this.lastName = encrypt_decript.Decript(obj.client_last_name);
            if (obj.dob != null) {
              localStorage.setItem("Case_Clnt_dob", obj.dob);
            }
            var stdate = obj.start_date.split('-');
            this.startDate = stdate[2] + "-" + stdate[1] + "-" + stdate[0];
            var eddate = obj.end_date.split('-');
            this.endDate = eddate[2] + "-" + eddate[1] + "-" + eddate[0];
            this.noOfSitting = obj.no_of_sittings;
            this.fees = obj.fee_per_sitting;
            this.comments = obj.comments;
            this.relationType = obj.relation;
            this.age = obj.age;
            this.gender = encrypt_decript.Decript(obj.gender);
            localStorage.setItem("Age_data", this.age);
            if (obj.blood_desc != null) {
              this.bloodGroup = obj.blood_desc;
            }
            if (obj.guardian != null) {
              this.guardian = encrypt_decript.Decript(obj.guardian);
              this.income = encrypt_decript.Decript(obj.income);
            }
            if (obj.guardian != null) {
              this.income = encrypt_decript.Decript(obj.income);
            }
            if (obj.occupation != null) {
              this.occupation = encrypt_decript.Decript(obj.occupation);
            }
            if (obj.mobile != undefined || obj.mobile != null) {
              this.mobile = encrypt_decript.Decript(obj.mobile);
            }
            if (obj.symptoms != null) {
              this.symtome = encrypt_decript.Decript(obj.symptoms);
            }
            Helper_Class.Set_client_view_app_data(obj);
            if (obj.doc_middle_name != null && obj.doc_middle_name != undefined) {
              this.doctorName = obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
            } else {
              this.doctorName = obj.doc_first_name + " " + obj.doc_last_name;
            }
            var address1, location, city_zip, stacount;
            location = obj.location_desc;
            if (obj.address2 != null && obj.address2 != "") {
              address1 = encrypt_decript.Decript(obj.address1) + " " + encrypt_decript.Decript(obj.address2);
            } else {
              address1 = encrypt_decript.Decript(obj.address1);
            }
            city_zip = obj.city_desc + " - " + encrypt_decript.Decript(obj.zipcode);
            stacount = obj.state_desc + " , " + obj.country_desc;
            this.address = address1 + "\n" + location + "\n" + city_zip + "\n" + stacount;
            for (var j = 0; j < obj.drug_list.length; j++) {
              this.presInfo = obj.drug_list[j].drug_name + "  " + obj.drug_list[j].morning + "-" + obj.drug_list[j].afternoon + "-" + obj.drug_list[j].evening + "   " + obj.drug_list[j].time_duration + " " + obj.drug_list[j].drug_intake + "   " + obj.drug_list[j].days + " " + "days" + "\n";
            }
          }
          else {
            this.loader = true;
            this.toastr.error(Message_data.getNetworkMessage());
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
  cancelModel() {
    $("#staticBackdrop").modal('hide');
  }
  cancel_click() {
    $("#staticBackdrop").modal('show');
  }
  submitCancel() {
    if (this.reasonContent.toString().trim() == "" && this.reasonContent.length == 0) {
      this.toastr.error(Message_data.appcancelreason);
    }
    else if (this.reasonContent.length > 150) {
      this.toastr.error(Message_data.maxlen150);
    }
    else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          type: "nurse",
          np_app_id: this.appID.app_id,
          reason: this.reasonContent
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.messageService.sendMessage("assignList");
              }
            }
            $("#staticBackdrop").modal('hide');
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
            $("#staticBackdrop").modal('hide');
          }
        )
    }
  }
  Appointment_confirm() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/confapp',
      JSON.stringify({
        type: "doctor",
        app_id: this.appID,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null) {
            if (obj.key == "1") {
              this.toastr.success(Message_data.appconf);
              this.messageService.sendMessage("assignList");
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
}
