<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill Details</h5>
          </div>
          <div class="headerButtons">
          <a><img src="../../../assets/ui_icons/buttons/History_Bill_icon.svg" class="saveimgbtn_inpatinfo" (click)="history()"/></a>
          <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" /></a>
              <a style="margin:0 5px;" *ngIf="printFlag" (click)="print()"><img src="../../../assets/img/printer.svg"
                class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div *ngFor="let billprint of billingDetailList">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                   Hospital details
                </div>
                <div class="content_cover">
                  <div [hidden]="hospAddress">
                    <table>
                      <tr>
                        <td class="text_align"><b>{{billprint.hptl_name}}</b></td>
                      </tr>
                      <tr>
                        <td class="changefont text_align">{{billprint.address}}</td>
                      </tr>
                      <tr>
                        <td class="changefont text_align">{{billprint.location}}{{billprint.city}}</td>
                      </tr>
                      <tr>
                        <td class="changefont text_align">{{billprint.state}}</td>
                      </tr>
                      <tr>
                        <td class="changefont text_align"> <img class="phone_img" src="../../../assets/img/phone.png"
                            width="20px" height="20px" />
                          {{billprint.telephone}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
            </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                   Bill details
                </div>
                <div class="content_cover">
                  <table>
                    <tr>
                      <th class="text_align">Bill date : </th>
                      <td>
                        {{billprint.created_date}}
                      </td>
                    </tr>
                    <tr>
                      <th style="text-align: left !important;">Bill number : </th>
                      <td style="text-align: left;">
                        {{billId}}
                      </td>
                    </tr>
                    <tr [hidden]="mainBalanceFlag">
                      <th class="text_align">Bill Amount (र) :</th>
                      <td>
                        {{billAmount}}
                      </td>
                    </tr>
                  </table>
                </div>
            </div>
            </div>
          </div>
          <div class="row chwidth">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                   Patient details
                </div>
                <div class="content_cover">
                  <table>
                    <tr>
                      <td>Patient name</td>
                      <td>: {{billprint.client_name}}</td>
                    </tr>
                    <tr>
                      <td class="thvalue text_align">Age/Gender</td>
                      <td style="text-align: left;">: {{billprint.age}} / {{billprint.gender}}</td>
                    </tr>
                    <tr>
                      <td class="thvalue text_align">Mobile</td>
                      <td style="text-align: left;">: {{billprint.mobile}}</td>
                    </tr>
                    <tr>
                      <td class="thvalue text_align">UHID</td>
                      <td style="text-align: left;">: {{billprint.uhid}}</td>
                    </tr>
                    <tr *ngIf="surgery != undefined">
                      <td class="thvalue text_align">Surgery</td>
                      <td style="text-align: left;">: {{surgery}}</td>
                    </tr>
                  </table>
                </div>
            </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="subheadingvalue"><b></b></p>
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                  Address details
                </div>
                <div class="content_cover">
                  <table>
                    <tr>
                      <td class="thvalue text_align">{{billprint.caddress}}</td>
                    </tr>
                    <tr>
                      <td class="thvalue text_align">{{billprint.clocation}}{{ billprint.clientcity}}</td>
                    </tr>
                    <tr>
                      <td class="thvalue text_align">{{billprint.clestare}}</td>
                    </tr>
                    <tr>
                      <td class="thvalue text_align">{{billprint.ccountry}}</td>
                    </tr>
                  </table>
                </div>
            </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" [hidden]="billingPrintViewFlag">
              <p  *ngIf="billingPrintView.length !=0"
                class="tervys_heading" style="font-weight: 600;">Bill particulars</p>
            </div>
            <div class="table-responsive dig_table_overflow" >
              <table id="card_tbl" *ngIf="billingPrintView.length" class="table table-nowrap table-sm">
                <thead class="thvalues">
                  <tr style="height: 30px;">
                    <th>Sno</th>
                    <th>Type</th>
                    <th [hidden]="paytypreq">Payment type</th>
                    <th>Fee</th>
                    <th [hidden]="bill_qty_flag">Quantity</th>
                    <th [hidden]="bill_cons_flag">Concession (%)</th>
                    <th [hidden]="bill_cons_flag">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 30px;" *ngFor="let person of billingPrintView">
                    <td class="billlistleft">{{person.sno}}</td>
                    <td class="billlistleft">{{person.bill_type}}</td>
                    <td class="billlistleft" [hidden]="paytypreq">{{person.pay_type}}</td>
                    <td class="billlistright">{{person.amount_txt}}</td>
                    <td class="billlistright" [hidden]="bill_qty_flag">{{person.quantity}}</td>
                    <td class="billlistright" [hidden]="bill_cons_flag">{{person.concession}}</td>
                    <td class="billlistright" [hidden]="bill_cons_flag">{{person.fee}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div [hidden]="treatementPlanFlag">
            <p class="casesheet_para">Treatement plan</p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="treatementPlanFlag">
            <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th class="tbl_txt_center">Estimate</th>
                  <th class="tbl_txt_center">Select</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of estimatePrintData let i = index">
                  <td class="billlistleft">{{person.treatment_desc}}</td>
                  <td class="billlistright">{{person.estimate}}</td>
                </tr>
              </tbody>
            </table>
            <br><br><br>
          </div>
          <div class="row">
            <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-9">
              <div class="col-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <table class="table table-hover table-dynamic" style="margin-top: 20px;">
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 200px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 200px !important;">{{pay.amount}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-3">
              <div class="row ">
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="totalFlag">
                  <mat-label class="matlabel ">Total र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="totalFlag">
                  <span class="billlistright">{{totalBill}}</span>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="cgstFlag">
                  <mat-label class="matlabel ">CGST र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="cgstFlag">
                  <span class="billlistright">{{cgst}} </span>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="sgstFlag">
                  <mat-label class="matlabel ">SGST र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="sgstFlag">
                  <span class="billlistright">{{sgst}} </span>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="prevBalanceFlag">
                  <mat-label class="matlabel"> Previous balance र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="prevBalanceFlag">
                  <span class="billlistright">{{prevBalance}}</span>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="totalFlag">
                  <mat-label class="matlabel "> Bill amount र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="totalFlag">
                  <span class="">{{billAmount}} </span>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="totalFlag">
                  <mat-label class="matlabel "> Discount र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="totalFlag">
                  <span class="">{{discount}} </span>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="advanceFlag">
                  <mat-label class="matlabel">Advance र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="advanceFlag">
                  <span class="">{{billprint.advance}} </span>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" >
                  <mat-label class="matlabel">Paid र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" >
                  <span class="">{{paid}} </span>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight">
                  <mat-label class="matlabel">Balance र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright">
                  <span class="">{{billprint.balance}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="true">
          <div #billprint id="billprint">
            <div class="bill_border">
              <div *ngFor="let billprint of billingDetailList">
                <!-- Doctor details -->
                <div>
                  <table style="width: 100%;">
                      <tr>
                        <td style="display: flex;justify-content: center;">
                          <div>
                            <img alt="image" src={{hospitalLogo}} width="100px" height="100px" style="vertical-align: middle;">
                          </div>
                          <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                            <span><b>{{billprint.hptl_name}}</b>,</span><br/>
                            <span *ngIf="billprint.address != ''">{{billprint.address}}</span><br/>
                            <span>{{billprint.location}}</span> <span>{{billprint.city}}</span><br/>
                            <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img src="../../../assets/img/phone.png" width="15px" height="15px" />{{billprint.telephone}}.</span><br>
                          </div>
                        </td>
                      </tr>
                  </table>
                </div>
                <table style="margin-left: 50px;width: 100%;">
                  <tr>
                    <td colspan="2">
                      <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                          <br />
                          <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                          <br />
                          <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                          <br *ngIf="billprint.inpatid != undefined" />
                          <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                          <br />
                          <span><strong> UHID : </strong>{{billprint.uhid}}</span>
                          <br />
                          <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                          <span><strong>Bill number : </strong>{{billId}} </span>
                        </div>
                      </div>
                    </td>
                    <td style="width: 50%;vertical-align:top">
                      <div class="row">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress != undefined">
                          <span><strong>Address</strong></span>
                          <br />
                          <span> {{billprint.caddress}}</span>
                          <br />
                          <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <br />
                          <span>{{billprint.clestare}}</span>
                          <br />
                          <span>{{billprint.ccountry}}</span>
                        </div>
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress == undefined">
                          <span><strong>Address</strong></span>
                          <br />
                          <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <br />
                          <span>{{billprint.clestare}}</span>
                          <br />
                          <span>{{billprint.ccountry}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <!-- Bill particulars -->
                <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 800px;">
                  <h3 class="heading">Bill particulars</h3>
                  <div class="row">
                    <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                      <tr>
                        <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Particulars</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_pay_flag"><b>Payment type</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Fee</b></th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                            (%)</b>
                        </th>
                        <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"> <b>Amount</b></th>
                      </tr>
                      <tr *ngFor="let billprinttable of billingPrintView">
                        <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                        data-th="sno">{{billprinttable.sno}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type">{{billprinttable.bill_type}}</td>
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type" [hidden]="bill_pay_flag">{{billprinttable.pay_type}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Fee" [hidden]="bill_cons_flag">{{billprinttable.amount_txt}}</td>
                          <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                          data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                        <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Amount" class="fee_txt" >{{billprinttable.fee}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <br />
                <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                  <h3 class="heading">Bill particulars</h3>
                  <div class="main_class row">
                    <table style="border: 1px solid black;border-collapse: collapse;">
                      <tr>
                        <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                        <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                      </tr>
                      <tr *ngFor="let estimate of estimatePrintData">
                        <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                          data-th="Plan">{{estimate.treatment_desc}}</td>
                        <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                          data-th="Estimate">{{estimate.estimate}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- Total calculation table -->
                <div style="width: 100%;">
                  <table>
                    <tr>
                      <td style="vertical-align: top; width: 68%;">
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                          <tr *ngFor="let pay of paymentsarray">
                            <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                            <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                          </tr>
                        </table>
                      </td>
                      <td style="width: 32%;">
                        <table  style="float: right;margin-left: 40px !important;">
                          <tr [hidden]="totalFlag">
                            <!-- <td style="width: 140px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;">Total (र) </th>
                            <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                              {{totalBill}}</td>
                          </tr>
                          <tr [hidden]="cgstFlag">
                            <!-- <td style="width: 140px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;"><strong>Central GST</strong> % </th>
                            <td style="text-align: right; width: 100px;" data-th="Central GST"
                              class="fee_txt">
                              {{cgst}}</td>
                          </tr>
                          <tr [hidden]="sgstFlag">
                            <!-- <td style="width: 75px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;"><strong>State GST</strong> % </th>
                            <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                              {{sgst}}</td>
                          </tr>
                          <tr [hidden]="prevBalanceFlag">
                            <!-- <td style="width: 50px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;">Previous balance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                              class="fee_txt">{{prevBalance}}</td>
                          </tr>
                          <tr [hidden]="totalFlag">
                            <!-- <td style="width: 50px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;">Bill amount (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Bill amount"
                              class="fee_txt">
                              {{billAmount}}</td>
                          </tr>
                          <tr [hidden]="discountFlag">
                            <th style="text-align: left;">Discount (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{discount}}</td>
                          </tr>
                          <tr [hidden]="advanceFlag">
                            <!-- <td style="width: 75cm;background-color: #ffffff"></td> -->
                            <th style="text-align: left;">Advance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{billprint.advance}}</td>
                          </tr>
                          <tr [hidden]="advanceFlag">
                            <!-- <td style="width: 50px;background-color: #ffffff"></td> -->
                            <th style="text-align: left;">Paid (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{paid}}</td>
                          </tr>
                          <tr>
                            <!-- <td style="width: 25px;background-color: #ffffff;"></td> -->
                            <th style="text-align: left;">Balance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                              {{billprint.balance}}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div #noheader id="noheader">
            <table style="border:'0';width:100%">  
              <thead>  
               <tr>  
                <th style="width:100%;height: 100px;"></th>  
              </tr>  
             </thead>  
             <tfoot> 
              <tr>  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;height: 100px;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
             <tbody>  
               <tr>  
                 <td width="100%">  
                  <div class="bill_border">
                    <div *ngFor="let billprint of billingDetailList">
                      <!-- Doctor details -->
                      <table style="margin-left: 50px;width: 100%;margin-top: 200px;">
                        <tr>
                          <td colspan="2">
                            <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 50%;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                                <br />
                                <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                                <br />
                                <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                                <br *ngIf="billprint.inpatid != undefined" />
                                <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                                <br />
                                <span><strong> UHID : </strong>{{billprint.uhid}}</span>
                                <br />
                                <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                                <span><strong>Bill number : </strong>{{billId}} </span>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%;vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress != undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span> {{billprint.caddress}}</span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>
                                <br />
                                <span>{{billprint.ccountry}}</span>
                              </div>
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress == undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>
                                <br />
                                <span>{{billprint.ccountry}}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <!-- Bill particulars -->
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 95%;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                            <tr>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                                  (%)</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Amount</b></th>
                            </tr>
                            <tr *ngFor="let billprinttable of billingPrintView">
                              <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="sno">{{billprinttable.sno}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.bill_type}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee">{{billprinttable.amount_txt}}</td>
                                <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Amount" class="fee_txt" [hidden]="bill_cons_flag">{{billprinttable.fee}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <br />
                      <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="main_class row">
                          <table style="border: 1px solid black;border-collapse: collapse;">
                            <tr>
                              <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                              <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                            </tr>
                            <tr *ngFor="let estimate of estimatePrintData">
                              <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Plan">{{estimate.treatment_desc}}</td>
                              <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Estimate">{{estimate.estimate}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- Total calculation table -->
                      <div style="width: 95.5%;">
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td style="width: 32%;">
                              <table  style="float: right;margin-left: 40px !important;">
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBill}}</td>
                                </tr>
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>
                                <tr [hidden]="prevBalanceFlag">
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="discountFlag">
                                  <th style="text-align: left;">Discount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{discount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{billprint.advance}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                 </td>
               </tr>
             </tbody>
            </table>
          </div>
          <div #banner id="banner">
            <table style="border:'0';width:100%">  
              <thead>  
               <tr>  
                <th style="width:100%;height: 100px;">
                  <div>
                    <table style="width: 100%;">
                        <tr>
                          <td style="display: flex;justify-content: center; ">
                            <div>
                              <img alt="image" src={{hospitalLogo}} style={{printlogostyle}}>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                          </td>
                        </tr>
                    </table>
                  </div>
                </th>  
              </tr>  
             </thead>  
             <tfoot> 
              <tr>  
               <td width="100%">  
                <table style="border:'0';width:100%">  
                  <tr>  
                    <td style="width:100%;height: 100px;">&nbsp;</td>  
                 </tr>  
               </table>  
             </tfoot> 
             <tbody>  
               <tr>  
                 <td width="100%">  
                  <div class="bill_border">
                    <div *ngFor="let billprint of billingDetailList">
                      <!-- Doctor details -->
                      <table style="margin-left: 50px;width: 100%;">
                        <tr>
                          <td style="width: 50%;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                                <br />
                                <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                                <br />
                                <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                                <br *ngIf="billprint.inpatid != undefined" />
                                <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                                <br />
                                <span><strong> UHID : </strong>{{billprint.uhid}}</span>
                                <br />
                                <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                                <span><strong>Bill number : </strong>{{billId}} </span><br/>
                                <span *ngIf="surgery != undefined"><strong>Surgery : </strong>{{surgery}} </span>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%;vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress != undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span> {{billprint.caddress}}</span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>
                                <br />
                                <span>{{billprint.ccountry}}</span>
                              </div>
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress == undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>
                                <br />
                                <span>{{billprint.ccountry}}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <!-- Bill particulars -->
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 95%;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                            <tr>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                                  (%)</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Amount</b></th>
                            </tr>
                            <tr *ngFor="let billprinttable of billingPrintView">
                              <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="sno">{{billprinttable.sno}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.bill_type}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee">{{billprinttable.amount_txt}}</td>
                                <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Amount" class="fee_txt" [hidden]="bill_cons_flag">{{billprinttable.fee}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <br />
                      <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="main_class row">
                          <table style="border: 1px solid black;border-collapse: collapse;">
                            <tr>
                              <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                              <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                            </tr>
                            <tr *ngFor="let estimate of estimatePrintData">
                              <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Plan">{{estimate.treatment_desc}}</td>
                              <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Estimate">{{estimate.estimate}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- Total calculation table -->
                      <div style="width: 95.5%;">
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 66%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td style="width: 34%;">
                              <table  style="float: right;margin-left: 40px !important;">
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBill}}</td>
                                </tr>
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>
                                <tr [hidden]="prevBalanceFlag">
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="discountFlag">
                                  <th style="text-align: left;">Discount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{discount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{billprint.advance}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table><br/><br/><br/>
                      </div>
                    </div>
                    <br/>
                    <br/><br/>
                  </div>
                  <br/><br/>
                  <!-- <table style="width: 100%;">
                    <tr>
                      <td>
                        <p style="text-align: center;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                        <br />
                      </td>
                    </tr>
                  </table> -->
                 </td>
               </tr>
             </tbody>
            </table>
          </div>
          <div #logowithname id="logowithname">
            <div class="bill_border">
              <div *ngFor="let billprint of billingDetailList">
                <table style="border:'0';width:100%">  
                  <thead>  
                   <tr>  
                    <th style="width:100%;height: 100px;">
                      <table style="width: 100%;">
                        <tr>
                          <td style="display: flex;justify-content: center;">
                            <div>
                              <img alt="image" src={{hospitalLogo}} width="100px" height="100px" style="vertical-align: middle;">
                            </div>
                            <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                              <span><b>{{billprint.hptl_name}}</b>,</span><br/>
                              <span *ngIf="billprint.address != ''">{{billprint.address}}</span><br/>
                              <span>{{billprint.location}}</span> <span>{{billprint.city}}</span><br/>
                              <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img src="../../../assets/img/phone.png" width="15px" height="15px" />{{billprint.telephone}}.</span><br>
                            </div>
                          </td>
                        </tr>
                    </table>
                    </th>  
                  </tr>  
                 </thead>  
                 <tfoot> 
                  <tr>  
                   <td width="100%">  
                    <table style="border:'0';width:100%">  
                      <tr>  
                        <td style="width:100%;height: 100px;">&nbsp;</td>  
                     </tr>  
                   </table>  
                 </tfoot> 
                 <tbody>  
                   <tr>  
                     <td width="100%">  
                      <!-- Doctor details -->
                      <table style="margin-left: 50px;width: 100%;">
                        <tr>
                          <td colspan="2">
                            <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 50%;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                                <br />
                                <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                                <br />
                                <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                                <br *ngIf="billprint.inpatid != undefined" />
                                <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                                <br />
                                <span><strong> UHID : </strong>{{billprint.uhid}}</span>
                                <br />
                                <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                                <span><strong>Bill number : </strong>{{billId}} </span>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%;vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress != undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span> {{billprint.caddress}}</span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>
                                <br />
                                <span>{{billprint.ccountry}}</span>
                              </div>
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress == undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>
                                <br />
                                <span>{{billprint.ccountry}}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <!-- Bill particulars -->
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 95%;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                            <tr>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                                  (%)</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Amount</b></th>
                            </tr>
                            <tr *ngFor="let billprinttable of billingPrintView">
                              <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="sno">{{billprinttable.sno}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.bill_type}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee">{{billprinttable.amount_txt}}</td>
                                <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Amount" class="fee_txt" [hidden]="bill_cons_flag">{{billprinttable.fee}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <br />
                      <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="main_class row">
                          <table style="border: 1px solid black;border-collapse: collapse;">
                            <tr>
                              <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                              <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                            </tr>
                            <tr *ngFor="let estimate of estimatePrintData">
                              <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Plan">{{estimate.treatment_desc}}</td>
                              <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Estimate">{{estimate.estimate}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- Total calculation table -->
                      <div style="width: 95.5%;">
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td style="width: 32%;">
                              <table  style="float: right;margin-left: 40px !important;">
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBill}}</td>
                                </tr>
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>
                                <tr [hidden]="prevBalanceFlag">
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="discountFlag">
                                  <th style="text-align: left;">Discount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{discount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{billprint.advance}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table><br/><br/><br/>
                      </div>
                     </td>
                   </tr>
                 </tbody>
                </table>
                
              </div>
              <br/>
              <br/><br/>
            </div>
            <br/><br/>
            <!-- <table style="width: 100%;">
              <tr>
                <td>
                  <p style="text-align: center;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                  <br />
                </td>
              </tr>
            </table> -->
          </div>

          <div #logowithname id="logowithnamenopayconsqty">
            <div class="bill_border">
              <div *ngFor="let billprint of billingDetailList">
                <table style="border:'0';width:100%">  
                  <thead>  
                   <tr>  
                    <th style="width:100%;height: 100px;">
                      <table style="width: 100%;">
                        <tr>
                          <td style="display: flex;justify-content: center;">
                            <div>
                              <img alt="image" src={{hospitalLogo}} width="100px" height="100px" style="vertical-align: middle;">
                            </div>
                            <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                              <span><b>{{billprint.hptl_name}}</b>,</span><br/>
                              <span *ngIf="billprint.address != ''">{{billprint.address}}</span><br/>
                              <span>{{billprint.location}}</span> <span>{{billprint.city}}</span><br/>
                              <span>{{billprint.state}}</span><span *ngIf="billprint.telephone != undefined"><img src="../../../assets/img/phone.png" width="15px" height="15px" />{{billprint.telephone}}.</span><br>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </th>  
                  </tr>  
                 </thead>  
                 <tfoot> 
                  <tr>  
                   <td width="100%">  
                    <table style="border:'0';width:100%">  
                      <tr>  
                        <td style="width:100%;height: 100px;">&nbsp;</td>  
                     </tr>  
                   </table>  
                 </tfoot> 
                 <tbody>  
                   <tr>  
                     <td width="100%">  
                      <!-- Doctor details -->
                      <table style="margin-left: 50px;width: 100%;">
                        <tr>
                          <td colspan="2">
                            <span style="text-align: center !important;font-size: 20px !important;">{{bill_head}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 50%;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                                <br />
                                <span><strong>Age/Gender : </strong>{{billprint.age}} / {{billprint.gender}}</span>
                                <br />
                                <span><strong> Mobile : </strong>{{billprint.mobile}}</span>
                                <br *ngIf="billprint.inpatid != undefined" />
                                    <span *ngIf="billprint.inpatid != undefined"><strong> Inpatient no : </strong>{{billprint.inpatid}}</span>
                                <br />
                                <span><strong> UHID : </strong>{{billprint.uhid}}</span>
                                <br />
                                <span><strong>Bill date : </strong>{{billprint.created_date}} </span><br/>
                                <span><strong>Bill number : </strong>{{billId}} </span>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%; vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress != undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span> {{billprint.caddress}}</span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>
                                <br />
                                <span>{{billprint.ccountry}}</span>
                              </div>
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" *ngIf="this.clientAddress == undefined">
                                <span><strong>Address</strong></span>
                                <br />
                                <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                                <br />
                                <span>{{billprint.clestare}}</span>
                                <br />
                                <span>{{billprint.ccountry}}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <!-- Bill particulars -->
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 50px;margin-right: 10px; width: 95%;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 50px;">
                            <tr>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_qty_flag"><b>Quantity</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Concession
                                  (%)</b>
                              </th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="bill_cons_flag"><b>Amount</b></th>
                            </tr>
                            <tr *ngFor="let billprinttable of billingPrintView">
                              <td style="width: 50px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                              data-th="sno">{{billprinttable.sno}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type">{{billprinttable.bill_type}}</td>
                              <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Fee">{{billprinttable.amount_txt}}</td>
                                <td style="width: 200px; text-align: center;border: 1px solid black;border-collapse: collapse;"
                                data-th="Type" [hidden]="bill_qty_flag">{{billprinttable.quantity}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Concession" [hidden]="bill_cons_flag">{{billprinttable.concession}}</td>
                              <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Amount" class="fee_txt" [hidden]="bill_cons_flag">{{billprinttable.fee}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <br />
                      <div [hidden]="treatementPlanFlag" style="margin-left: 50px; width: 800px;margin-right: 50px;">
                        <h3 class="heading">Bill particulars</h3>
                        <div class="main_class row">
                          <table style="border: 1px solid black;border-collapse: collapse;">
                            <tr>
                              <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                              <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                            </tr>
                            <tr *ngFor="let estimate of estimatePrintData">
                              <td style="width: 350px; text-align: right;border: 1px solid black;border-collapse: collapse;"
                                data-th="Plan">{{estimate.treatment_desc}}</td>
                              <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                data-th="Estimate">{{estimate.estimate}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- Total calculation table -->
                      <div style="width: 95.5%;">
                        <table>
                          <tr>
                            <td style="vertical-align: top; width: 68%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 150px !important;vertical-align: top;">{{pay.amount}}</td>
                                </tr>
                              </table>
                            </td>
                            <td style="width: 32%;">
                              <table  style="float: right;margin-left: 40px !important;">
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBill}}</td>
                                </tr>
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>
                                <tr [hidden]="prevBalanceFlag">
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>
                                <tr [hidden]="totalFlag">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="discountFlag">
                                  <th style="text-align: left;">Discount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{discount}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{billprint.advance}}</td>
                                </tr>
                                <tr [hidden]="advanceFlag">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table><br/><br/><br/>
                      </div>
                     </td>
                   </tr>
                 </tbody>
                </table>
              </div>
              <br/>
              <br/><br/>
            </div>
            <br/><br/>
            <!-- <table style="width: 100%;">
              <tr>
                <td>
                  <p style="text-align: center;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                  <br />
                </td>
              </tr>
            </table> -->
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>