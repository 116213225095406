<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Create advances</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="icd_content">
  <div class="Details" style="padding:10px 32px; min-width: 520px; width: 800px;">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 select_bottom">
        <!-- (click)="location_selected(location) -->
        <mat-label class="matlabel">Client Name<br>
          <input type="text" class="ipcss" [(ngModel)]="clientName" name="loc_name" (keyup)="getAppointments($event)"
            type="text" aria-label="Number" matInput [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option id="optionfont" *ngFor="let location of appointmentListArray"
              (click)="changeAppointment(location.client_reg_id,location.relation_id,location.sub_rel_id,location.doc_app_id,location)"
              [value]="location.docid_name">
              {{location.docid_name}}
            </mat-option>
          </mat-autocomplete>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 select_bottom">
        <mat-label class="matlabel">Payment type<br>
          <select class="ipcss " [(ngModel)]="payType" (change)="changePayType()" disableOptionCentering>
            <option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
          </select>
        </mat-label>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
        <mat-label class="matlabel">Card No <br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="cardreqflag">
        </mat-label>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
        <mat-label class="matlabel">Holder name<br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
        </mat-label>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="transactionFlag">
        <mat-label class="matlabel">Transaction ID<br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" [required]="transreqflag"/>
        </mat-label>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
        <mat-label class="matlabel">Cheque No <br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="chequereqflag"/>
        </mat-label>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
        <mat-label class="matlabel">Bank Name <br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" [required]="chequereqflag"/>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="advanceFlag">
        <mat-label class="matlabel">Advance<br>
          <input matInput class="ipcss widthbilllist" type="text" required [(ngModel)]="advance"
            style="margin-bottom: 10px;" maxlength="9"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-1 my-auto select_bottom button_style">
        <img src="../../../assets/ui_icons/buttons/next_button.svg" style="width: 25px; height: 25px;" alt=""
          (click)="addAdvance()">
      </div>
      <div class="col-12">
        <p *ngIf="advanceArray.length == 0" class="nodata"> No advance found </p>
        <div class="dig_table_overflow" *ngIf="advanceArray.length != 0">
          <table style="width:100%;" class="card_tbl table-dynamic">
            <thead>
              <tr>
                <th> Si.No </th>
                <th> Payment type </th>
                <th> Advance </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advance of advanceArray; let i = index">
                <td> {{ i + 1 }} </td>
                <td> {{ advance.pay_desc }} </td>
                <td> {{ advance.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
  <div [hidden]="true">
    <div #logowithname id="logowithname">
      <div class="bill_border">
        <table style="width: 100%;">
          <tr>
            <td style="display: flex;justify-content: center;">
              <div>
                <img alt="image" src={{hospitalLogo}} width="100px" height="100px" style="vertical-align: middle;">
              </div>
              <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                <span><b>{{hospitalName}}</b>,</span><br />
                <span *ngIf="hospitalAddr != ''">{{hospitalAddr}}</span><br *ngIf="hospitalAddr != ''" />
                <span>{{hospitalLocation}},</span> <span>{{hospitalCity}},</span><br />
                <span>{{hospitalState}},</span><span>{{hospitalCountry}},</span><span
                  *ngIf="hospitalPhNo != undefined"><img src="../../../assets/img/phone.png" width="15px"
                    height="15px" />{{hospitalPhNo}}.</span><br>
              </div>
            </td>
          </tr>
        </table>
        <br />
        <table style="margin-left: 75px;width: 100%;">
          <tr>
            <td style="width: 50%;">
              <h3 class="heading">Patient details</h3>
              <div>
                <p style="margin: 0;"><strong>Patient name : </strong>{{clntFirstName}} <span
                    *ngIf="clntMiddleName != ''">{{clntMiddleName}}</span> {{clntLastName}}</p>
                <p style="margin: 0;"><strong> UHID : </strong>{{patientId}}</p>
                <p style="margin: 0;">
                  <span *ngIf="clntAge != ''"><strong> Age : </strong>{{clntAge}}</span>
                  <span *ngIf="clntGender != ''"><strong> Gender : </strong>{{clntGender}}</span>
                </p>
              </div>
            </td>
            <td style="width: 50%;">
              <div class="amount_duecol-12">
                <span><strong><br />Address</strong></span>
                <br />
                <span> {{clntAddress1}} </span>
                <br />
                <span>{{clntLocation}}{{ clntCity}}</span>
                <br />
                <span>{{clntState}}</span>
                <br />
                <span>{{clntCountry}}</span>
              </div>
            </td>
          </tr>
        </table>
        <table style="margin-left: 75px; margin-top: 50px;border: 1px solid gray;width: 70%;border-collapse: collapse; text-align:center;">
          <thead>
            <tr style="border: 1px solid gray;border-collapse: collapse; text-align:center; ">
              <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Si.No </th>
              <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Payment type </th>
              <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Advance </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let advance of advanceArray; let i = index" style="border: 1px solid gray;border-collapse: collapse; text-align:center; ">
              <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ i + 1 }} </td>
              <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ advance.pay_desc }} </td>
              <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ advance.amount }}</td>
            </tr>
            <tr style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> <td colspan="2" style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Total amount </td > <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{totalAdvance}} </td> </tr>
          </tbody>
        </table>
        <br />
        <br /><br />
      </div>
      <br />
    </div>
    <div #noheader id="noheader">
      <div class="bill_border">
        <table style="width: 100%;height: 250px;">
          <tr>
          </tr>
        </table>
        <br />
        <table style="margin-left: 75px;width: 100%;">
          <tr>
            <td style="width: 50%;">
              <h3 class="heading">Patient details</h3>
              <div>
                <p style="margin: 0;"><strong>Patient name : </strong>{{clntFirstName}} <span
                    *ngIf="clntMiddleName != ''">{{clntMiddleName}}</span> {{clntLastName}}</p>
                <p style="margin: 0;"><strong> UHID : </strong>{{patientId}}</p>
                <p style="margin: 0;">
                  <span *ngIf="clntAge != ''"><strong> Age : </strong>{{clntAge}}</span>
                  <span *ngIf="clntGender != ''"><strong> Gender : </strong>{{clntGender}}</span>
                </p>
              </div>
            </td>
            <td style="width: 50%;">
              <div class="amount_duecol-12">
                <span><strong><br />Address</strong></span>
                <br />
                <span> {{clntAddress1}} </span><span *ngIf="clntAddress2 != ''">, {{clntAddress2}}</span>
                <br />
                <span>{{clntLocation}}{{ clntCity}}</span>
                <br />
                <span>{{clntState}}</span>
                <br />
                <span>{{clntCountry}}</span>
              </div>
            </td>
          </tr>
        </table>
        <table style="margin-left: 75px; margin-top: 50px;border: 1px solid gray;width: 70%;border-collapse: collapse; text-align:center;">
          <thead>
            <tr style="border: 1px solid gray;border-collapse: collapse; text-align:center; ">
              <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Si.No </th>
              <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Payment type </th>
              <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Advance </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let advance of advanceArray; let i = index" style="border: 1px solid gray;border-collapse: collapse; text-align:center; ">
              <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ i + 1 }} </td>
              <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ advance.pay_desc }} </td>
              <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ advance.amount }}</td>
            </tr>
            <tr style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> <td colspan="2" style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Total amount </td > <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{totalAdvance}} </td> </tr>
          </tbody>
        </table>
        <br />
        <br /><br />
      </div>
      <br />
    </div>
    <div #printbanner id="printbanner">
      <table style="margin-left:10px;margin-right: 10px;">
        <tr>
          <td>
            <img alt="image" src={{hospitalLogo}} [style]="preslogo_style" >
          </td>
        </tr>
      </table>
      <br /><br />
      <table style="margin-left: 75px;width: 100%;">
        <tr>
          <td style="width: 50%;">
            <h3 class="heading">Patient details</h3>
            <div>
              <p style="margin: 0;"><strong>Patient name : </strong>{{clntFirstName}} <span
                  *ngIf="clntMiddleName != ''">{{clntMiddleName}}</span> {{clntLastName}}</p>
              <p style="margin: 0;"><strong> UHID : </strong>{{patientId}}</p>
              <p style="margin: 0;">
                <span *ngIf="clntAge != ''"><strong> Age : </strong>{{clntAge}}</span>
                <span *ngIf="clntGender != ''"><strong> Gender : </strong>{{clntGender}}</span>
              </p>
            </div>
          </td>
          <td style="width: 50%;">
            <div class="amount_duecol-12">
              <span><strong><br />Address</strong></span>
              <br />
              <span> {{clntAddress1}} </span><span *ngIf="clntAddress2 != ''">, {{clntAddress2}}</span>
              <br />
              <span>{{clntLocation}}{{ clntCity}}</span>
              <br />
              <span>{{clntState}}</span>
              <br />
              <span>{{clntCountry}}</span>
            </div>
          </td>
        </tr>
      </table>
      <table style="margin-left: 75px; margin-top: 50px;border: 1px solid gray;width: 70%;border-collapse: collapse; text-align:center;">
        <thead>
          <tr style="border: 1px solid gray;border-collapse: collapse; text-align:center; ">
            <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Si.No </th>
            <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Payment type </th>
            <th style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Advance </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let advance of advanceArray; let i = index" style="border: 1px solid gray;border-collapse: collapse; text-align:center; ">
            <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ i + 1 }} </td>
            <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ advance.pay_desc }} </td>
            <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{ advance.amount }}</td>
          </tr>
          <tr style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> <td colspan="2" style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> Total amount </td > <td style="border: 1px solid gray;border-collapse: collapse; text-align:center; "> {{totalAdvance}} </td> </tr>
        </tbody>
      </table>
      <br />
      <br /><br />
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button id="add_btn" mat-button *ngIf="advanceFlag">
    <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" class="addimgbtn_icd" />
  </button>
</div>