import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Helper_Class } from 'src/app/helper_class';
// import { DoctorViewComponent } from './doctor-view.component';

@Injectable({
  providedIn: 'root'
})
export class MenuViewService {

    private subject = new Subject<any>();
    constructor(public router:Router){}

    sendMessage(message: string) {
        this.subject.next(message);
       // if(Helper_Class.getLoginType() == "doctor" || Helper_Class.getLoginType() == "consultant"){
            this.router.navigateByUrl("/menuView");
        //}
        message == null;
    }

    clearMessages() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        
        return this.subject.asObservable();
    }
    // for medical and diagnosis prescription scroll fuction
    private _listners = new Subject<any>();

    listen(): Observable<any> {
       return this._listners.asObservable();
    }
    filter(filterBy: string) {
       this._listners.next(filterBy);
    }
}
